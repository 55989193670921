import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ItemViewInList from '../../inventory/includes/ItemViewInList';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import DateService from '../../../services/DateService';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';

class SalesAllocationDetailModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                      =       {
           detail_loading            :       false,
           allocationDetail                  :       null,
           allocationTransactionID           :       null,
           
        };
        this.allocationDetailUrl       =    INVENTORY_API_BASE_URL_2 + '/quotation/detail';
        this.itemDetailsRef             =   React.createRef(); 

    }

    componentDidMount() {
       
        if(this.props && this.props.allocationID){
            this.getAllocationDetail(this.props.allocationID)
        }
        if(this.props && this.props.allocationDetail){
            this.setState({allocationDetail : this.props.allocationDetail})
        }
        
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.allocationID !== this.props.allocationID){
            this.getAllocationDetail(nextProps.allocationID)
         }
         if(nextProps.allocationDetail !== this.props.allocationDetail){
             this.setState({allocationDetail : nextProps.allocationDetail})
         }
        
     }

    getAllocationDetail        =       (transaction_id)      =>      {
        this.setState({detail_loading : true})
        HttpAPICall.withAthorization('GET', this.allocationDetailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                allocationDetail             :   respData,
                allocationTransactionID      :   respData.transaction_id, 
            });
            
        }).then(() => this.setState({detail_loading : false}));      
    }
    
    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }
   
    formatAddress(address) {
        return <>
            {address.address1 ? address.address1 + ", " : ""}  {address.address2 ? address.address2 + ", " : ""}
            {address.landmark ? address.landmark + ", " : ""} {address.city ? address.city + ". " : ""}<br />
            Location : <b>{address.state_name ? address.state_name + ", " : ""}  {address.district_name ? address.district_name + "& " : ""}  {address.pincode ? address.pincode + "." : ""} <br /></b>
            GSTIN   :   <b>{address.gstin ? address.gstin : "-"}</b>
        </>
    }
    
    reqDetailJsx        =       ()      =>      {
        let allocation = this.state.allocationDetail;
        let purchase_summary                    =   [];
        if (allocation && allocation.customerAddress && allocation.customerAddress.state_id == allocation.warehouse_state_id) {
             
            purchase_summary.push({ key: 'CGST ', value: "As applicable" });
            purchase_summary.push({ key: 'SGST ', value: "As applicable" });
        } else {
            purchase_summary.push({ key: 'IGST ', value: "As applicable" });
        }

        let sub_total_amount                =      allocation && allocation.items &&  allocation.items.length > 0 && allocation.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);

        let tcs_amount                          =  allocation &&  Number(allocation.tcs_rate_vc && allocation.tcs_rate_vc >= 0
            ? allocation && allocation.tcs_type == "Percentage" ? (sub_total_amount*allocation.tcs_rate_vc/100) :  Number(allocation.tcs_rate_vc)
            : 0);
        return (<Ax>

            {this.state.detail_loading ? <Loader />
                : <div className="modal-body ">
                    {this.state.allocationDetail
                        ? <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{allocation.transaction_id}</td>
                                        <td scope="col">{DateService.dateTimeFormat(allocation.transaction_date, 'DD-MMM-YYYY')}</td>
                                        <td scope="col" className="align-middle">
                                            {allocation.status ? allocation.status.name : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Warehouse</td>
                                        <td colSpan={2}>{allocation.warehouse ? <div><b>{allocation.warehouse.name} ({allocation.warehouse.code})</b> <br />
                                            {allocation.warehouse_address ?
                                                <div className='pt-1'>Address : {allocation.warehouse_address.address1 ? allocation.warehouse_address.address1 + ", " : ""}
                                                    {allocation.warehouse_address.address2 ? allocation.warehouse_address.address2 + ", " : ""}
                                                    {allocation.warehouse_address.landmark ? allocation.warehouse_address.landmark + ", " : ""}
                                                    {allocation.warehouse_address.city ? allocation.warehouse_address.city + ". " : "-"} <br />
                                                    Location : <b>{allocation.warehouse_address.state_name}</b> <br />
                                                    GSTIN  : {allocation.warehouse_address.gstin ? allocation.warehouse_address.gstin : "-"} <br />
                                                </div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} >Customer</td>
                                        <td colSpan={2} >{allocation.customer ? <div>{allocation.customer.name} ({allocation.customer.code}) <br />
                                           {(allocation.customerAddress || allocation.customerProject) &&
                                            <div className='py-1'>
                                                Project : {allocation.customerProject ? <span><b>{allocation.customerProject.project_name} ({allocation.customerProject.project_code}) </b></span> : "-"} <br/>
                                                Address :  {allocation.customerProject ? this.formatAddress(allocation.customerProject ) : this.formatAddress(allocation.customerAddress)} </div> }
                                            
                                            </div>: "-"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td scope="col">Priority & Validity Date</td>
                                        <td style={{ width: "33%" }} className="text-capitalize">{allocation.priority ?? "-"}</td>
                                        <td style={{ width: "34%" }}>{DateService.dateTimeFormat(allocation.validity_date, 'DD-MMM-YYYYY') ?? "-"}</td>

                                    </tr>
                                    <tr>
                                        <td scope="col">Deal</td>
                                        <td scope="col" colSpan={2}>{allocation && allocation.deal_data  ? allocation.deal_data.header : ""}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Tag Users</td>
                                        <td scope="col" colSpan={2}>{allocation && allocation.users && allocation.users.length > 0 ? allocation.users.map(u => <div>{u.full_name}</div>) : ""}</td>
                                    </tr>
                                    {allocation.quote_enable_authorized_signatory && allocation.quote_enable_authorized_signatory == 'Y' && <tr>
                                        <td scope="col">Authorized Signatory</td>
                                        <td style={{ width: "33%" }} className="text-capitalize" colSpan={2}>{allocation.authorized_signatory && allocation.authorized_signatory.full_name ? allocation.authorized_signatory.full_name : "-"}</td>

                                    </tr>}
                                    <tr>
                                        <td scope="col">Currency</td>
                                        <td colSpan={2}><b>{allocation.currency}</b> {allocation.currency !== allocation.base_currency ? <span>(1 {allocation?.currency} : <NumberFormatter number={allocation?.conversion_rate} precision={2} /> {allocation?.base_currency})</span> : null}</td>

                                    </tr>
                                    {allocation.additional_attribute_data && allocation.additional_attribute_data.length > 0
                                        ? allocation.additional_attribute_data.map((attr) => {
                                            return (<tr key={attr.key}>
                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                <td scope="col" colSpan={2}>{attr.value_display ? attr.value_display : "-"}</td>
                                            </tr>)
                                        }) : null}
                                </tbody>
                            </table>

                            <table className="table table-bordered table-responsive bg-white my-3 table-sm" id="poTable">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ "width": '5%' }} className="text-center">
                                            <input type='checkbox' onChange={this.props.checkAllItem} id="all_check_allocation" />
                                        </th>
                                        <th style={{ width: "39%" }}>Item Details</th>
                                        <th style={{ width: "12%" }} className="text-end">Quantity</th>
                                        <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                        <th style={{ width: "8%" }} className="text-end">Discount</th>
                                        <th style={{ width: "8%" }} className="text-end">Tax</th>
                                        <th style={{ width: "8%" }} className="text-end">Amount</th>
                                        {/* <th style={{ width: "8%" }} className="text-center">Pending</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        allocation && allocation.items && allocation.items.length > 0 ? allocation.items?.map((i, k) => {
                                            return (<tr>
                                                <td className='text-center'>
                                                    {k + 1}
                                                    <br /><br />
                                                    <input
                                                        type='checkbox'
                                                        name="foo"
                                                        value={i?.item_profile?.id}
                                                        className="allocation_item_id_checkbox"
                                                        data-itemData={i?.item_profile}
                                                    />

                                                </td>

                                                <td>{i.item_profile ?
                                                    <div className='row'>
                                                        <div className='col-sm-10'>
                                                            <ItemViewInList view_hsn={true} item={i.item_profile} remark={i.remark} due_date={i.due_date_display} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                                        </div>

                                                    </div>
                                                    : null}</td>

                                                <td className='text-end'>{i.qty}
                                                    {i.item_profile.measuring_unit && i.item_profile.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item_profile?.measuring_unit?.name}</span> : null}

                                                </td>
                                                <td className='text-end'>{i.rate_vc}</td>
                                                <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? allocation.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : 0}</td>
                                                <td className='text-end'>{allocation  && allocation.warehouse_address && allocation.warehouse_address.gstin ? <div>{i.gst} %</div> : "NA"}
                                                    {allocation && allocation.warehouse_address && allocation.warehouse_address.gstin && i.item ? <span className="text-helping input-group-text text-end pr0">{allocation.customerAddress && allocation.customerAddress.state_name == allocation.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                                </td>
                                                <td className='text-end'>
                                                    <CurrencyFormatter currencyCode={allocation.currency} amount={parseFloat(i.item_with_discount).toFixed(2)} />
                                                </td>
                                            </tr>)

                                        }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="row align-items-center mb-5">
                                <div className="col-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{allocation.notes ?? "-"}</div>

                                </div>
                                <div className="col-6">
                                    <table className="table  table-borderless">
                                        <tbody>
                                            <tr className="subtotal_bg_color">
                                                <th>Sub-Total ({allocation.currency ? allocation.currency : "INR"})</th>
                                                <td className='float-end'>{allocation && allocation.items && allocation.items.length > 0 && <CurrencyFormatter currencyCode={allocation.currency} amount={allocation.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />}</td>
                                                {/* <td className='float-end'>{allocation.total_amount_vc ? (parseFloat(allocation.total_amount_vc) - (parseFloat(allocation.adjustment_amount_vc ?? 0.0) + parseFloat(allocation.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                            </tr>
                                            {allocation && allocation.tcs_rate_vc && allocation.tcs_rate_vc > 0 ? <tr className="subtotal_bg_color">
                                                <th>TCS ({allocation.tcs_type == "Fixed" ? allocation.currency : null} {allocation.tcs_rate_vc} {allocation.tcs_type == "Percentage" ? "%" : null} )</th>
                                                <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>
                                                {/* <td className='float-end'>{allocation.total_amount_vc ? (parseFloat(allocation.total_amount_vc) - (parseFloat(allocation.adjustment_amount_vc ?? 0.0) + parseFloat(allocation.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                            </tr> : null}
                                            {allocation.tax_slabs && allocation.tax_slabs.length > 0
                                                ? (allocation.tax_slabs.map((ps, k) => {
                                                    return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                }))
                                                : null
                                            }

                                            <tr className="subtotal_bg_color">
                                                <th>Adjustment</th>
                                                <td className='float-end'>{allocation.adjustment_amount_vc ?? 0}</td>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Final Total ({allocation.currency ? allocation.currency : "INR"})</th>
                                                <td className='float-end'>{allocation.total_amount_vc ? <CurrencyFormatter currencyCode={allocation.currency} amount={allocation.total_amount_vc} /> : 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab_content_wrapper content_heading">Terms & Condition</div>
                            <table className="table table-hover table-bordered bg-white my-3">
                                <thead className='table-secondary'>
                                    <tr>
                                        <th className='text-center' style={{ width: "10%" }}>S.No</th>
                                        <th className='text-start' style={{ width: "25%" }}>Name</th>
                                        <th className='text-start' style={{ width: "65%" }}>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        allocation.tnc && allocation.tnc.length > 0 ? allocation.tnc.map((tc, k) => {
                                            return (<tr key={k}>
                                                <td className='text-center'>{k + 1}</td>
                                                <td>{tc.name}</td>
                                                <td>{tc.text}</td>
                                            </tr>);
                                        })
                                            : <tr ><td className='text-center' colSpan={3}>No Record</td></tr>
                                    }

                                </tbody>
                            </table>

                            <hr />

                        </div>
                        : null}
                </div>}
                <ItemDetailsModal ref={this.itemDetailsRef}  /> 
        </Ax>)
    }
   

    render() {
        return (<div>{this.reqDetailJsx()}</div>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SalesAllocationDetailModal);