import React from 'react';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import AssetMiniDetail from '../../includes/AssetMiniDetail';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import moment from 'moment';
import WarehouseAddress from '../includes/WarehouseAddress';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemSearchModal from '../../items/itemSearchModal';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';


class WarehouseIndentAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initIndentFormState        =   {
            transaction_date            :   moment(new Date(),'YYYY-MM-DD').toDate(),
            warehouse_id                :   '',
            status                      :   '',
            title                       :   '',
            indent_for                  :   '',
            workstation_id              :   '',
            asset_id                    :   '',
            employee_id                 :   '',
            users                       :   [],
        }

        this.state                     =     {
            saveFormSubmitting              :     false,
            storeIndentItemRows             :     [{item : null}],
            addStoreIndentForm              :     {...this.initIndentFormState},
            selectedAsset                   :     null,
            searchedWorkstation             :     null,
            searchedEmployee                :     null,
            warehouseDetailLoading          :     false,
            warehouseDetail                 :     null,
            warehouseSites                  :     [],
            allWarehouses                   :     [],
            storeIndentFormDataLoaded       :     false,
            allStatus                       :     [],
            allIndentOptions                :     [],
            allWorkstations                 :     [],
            allGstSlabs                     :     [],
            itemRowNumber                   :      0  ,
            formData                        :     '',
            indentDetailData                :      null,
            form_loading                    :      false,
            allUsers                        :       [],
            searchedWorkstation             :       null,
            showItemSearchModal             :       false,
            showItemAddModal                :       false,
            showItemEditModal               :       false
        };
        this.itemSearchRef          =   React.createRef();
        this.addItemModalRef        =   React.createRef();
        this.editItemModalRef       =   React.createRef();
        this.itemDetailsRef                 =        React.createRef();

        this.formDataUrl            =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/add_form_data';
        this.addUrl                 =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent';
        this.warehouseUrl           =   ISC_API_BASE_URL_2  + '/warehouse';
        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item';
    }

    componentDidMount() {
        this.initilaizeFormData(this.props);
        this.viewItemModal             =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.all_warehouses !== this.props.all_warehouses) {
            this.initilaizeFormData(nextProps);
        }
    }

    initilaizeFormData                  =   (pr)  =>  {
        let sites                       =   this.props.iam_group_sites;
         if(!this.state.storeIndentFormDataLoaded) {
            this.setState({form_loading : true})
            HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
                 let formData = response.data
                    this.setState({
                        storeIndentFormDataLoaded   :  true ,
                        formData                    :   formData ? formData : null,
                        showAddItem                     :       false,
                         showEditItem                    :       false,
                        showSearchedItem                :       false,
                        allWorkstations             :   formData && formData.workstations ?  formData.workstations.map((ws) => {  let site    =   sites.find(gs => gs.id == ws.site_id); return {value: ws.id, label:`${ws.workstation_name} `, workstation : ws} }) : [],
                        allIndentOptions            :   [{value:'asset',label:'Asset'},{value:'workstation',label:'Workstation'},{value:'employee',label:'Employee'}],
                        allStatus                   :   formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        allWarehouses               :   pr.all_warehouses && pr.all_warehouses.length > 0 ? pr.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_id}; }) : [],
                        allUsers                     :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                    }, () => {
                        
                        if(pr.location && pr.location.state && pr.location.state.indentDetailData){
                            
                            let indentData          =       pr.location.state.indentDetailData;
                            let indentItemRows      =       pr.location.state.indentDetailData.items;
                            let updateFormState = [];
                            if (indentData.additional_attributes_data) {
                                indentData.additional_attributes_data.map((i, k) => {
                                    let name = 'additional_attributes_' + i.key;

                                    updateFormState[name] = i.value
                                });

                            }
                            
                            this.setState({
                                addStoreIndentForm              :     {...this.initIndentFormState,
                                    transaction_date            :       indentData.transaction_date,
                                    transaction_id              :       indentData.transaction_id,
                                    warehouse_id                :       indentData.warehouse_id,
                                    status                      :       indentData.status_data ? indentData.status_data.id : '',
                                    title                       :       indentData.title,
                                    indent_for                  :       indentData.indent_for,
                                    asset_id                    :       indentData.assetProfile ? indentData.assetProfile.asset_id : null,
                                    workstation_id              :       indentData.workstation ? indentData.workstation.id : null,
                                    employee_id                 :       indentData.employee ? indentData.employee.enc_id : null,
                                    users                       :       indentData.usersList && indentData.usersList.length > 0 ? indentData.usersList.map(users => users.enc_id) : [],
                                    ...updateFormState
                                },
                                
                                indentDetailData                 :       indentData
                            },() => {
                                let assetProfile        =   indentData.assetProfile;
                                if(assetProfile) {
                                    this.setState({selectedAsset : {...assetProfile, label : `${assetProfile.name} (${assetProfile.asset_code})` }});
                                }
    
                                let employeeProfile        =   indentData.employee;
                                if(employeeProfile) {
                                    this.setState({searchedEmployee : {value : employeeProfile.enc_id, label : `${employeeProfile.display_full_name}` }});
                                }
                                let workstation = indentData.workstation;
                                if (workstation) {
                                    this.setState({ searchedWorkstation: { value: workstation.id, label: workstation.workstation_name } });
                                }
                                this.getWarehouseDetail(indentData.warehouse_id)
                                indentItemRows.forEach((consumedItem, key) => {
                                
                                    
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.itemData,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.pending_qty, consumedItem.rate,false);
                                });
                                this.addNewItemRow();
                            })
                        }
                    });
            }).then(() => {
                this.setState({form_loading : false});
            });
        }
    }

    onWarehouseChange                   =   (selectedOption) =>  {
        this.setState({
            selectedAsset               :   null,
            searchedWorkstation         :   null,
            searchedEmployee            :   null,
        
            
            addStoreIndentForm             :   {
                ...this.state.addStoreIndentForm,
                workstation_id          :   '',
                asset_id                :   '',
                employee_id             :   '',
                users           :       [],
            },
            // storeIndentItemRows         :   [...this.iniitstoreIndentItemRows]
        }, () => {
            this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
        });
    }
    
    getWarehouseDetail                  =   (warehouse_id)  =>  {
        if (warehouse_id) {
            this.setState({warehouseDetailLoading : true, warehouseDetail : null, warehouseSites : []});
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                let sites = respData.data && respData.data.sites.length > 0 ? respData.data.sites.map(st => st.id) : []
                this.setState({
                    warehouseDetail         :   respData.data,
                    warehouseSites          :   respData.data.sites,
                    allUsers                :   this.state.allUsers && this.state.allUsers.length > 0 && sites && sites.length > 0 ?  this.state.allUsers.filter(us =>  us.site_ids.some(value => sites.includes(value))) : this.state.allUsers
                });
               
            }).then(() => {
                this.setState({warehouseDetailLoading : false});
            });
        } else {
            this.setState({warehouseDetail: null})
        }
    }

    onItemSelect                =           (selectedItem,key,qty = 1, price = null,addRow= true,edit=false)          =>      {
       
        if(selectedItem) {
            let itemdata                    =   selectedItem.itemdata;
            let storeIndentItemRows     =   this.state.storeIndentItemRows;
           
            if(this.state.storeIndentItemRows.find((req_item) => req_item.item && req_item.item.id == itemdata.id) && edit == false){
                toast.error('This Item is already available in this  Warehouse Indent, please update the item', {position: toast.POSITION.TOP_RIGHT })
            } else{
                let row                         =   {...storeIndentItemRows[key], item : itemdata , qty : qty ? qty : this.state?.storeIndentItemRows[key]?.qty ? this.state?.storeIndentItemRows[key]?.qty : 0  , rate : price ? price : itemdata.purchase_price ? itemdata.purchase_price : 0, gst :   selectedItem.gst ? selectedItem.gst : itemdata.gst,remark:selectedItem.remark ? selectedItem.remark : ''};
                storeIndentItemRows[key]            =   row;
                this.setState({storeIndentItemRows : [...storeIndentItemRows]}, () => {
                    if(!this.state.storeIndentItemRows.find(ir => !ir.item)) {
                        if(addRow == true){
                            setTimeout(() => {this.addNewItemRow()}, 1000);
                        }
                    }
                    
                });
            }
            
        }
    }

    addNewItemRow                           =   ()  =>  {
        this.setState({storeIndentItemRows : [...this.state.storeIndentItemRows, {item : null}] });
    }

    removeItemRow                       =   (k) =>  {
        let storeIndentItemRows             =   this.state.storeIndentItemRows;
        if(storeIndentItemRows.length > 1) {
            storeIndentItemRows.splice(k, 1);
            this.setState({storeIndentItemRows : storeIndentItemRows});
        }
    }

    itemSearchModalInit             =       ()          =>      {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows                 =   this.state.storeIndentItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
        
    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.storeIndentItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.storeIndentItemRows[k] , item  : itemdata};
        this.setState({storeIndentItemRows : allRows});
    }

     //********************EDIT ITEM DETAILS ******************************* */

     editItemModalInit          =       (data,k)           =>      {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal,itemSearchedDetails : data,itemRowNumber:k }), () => {
            if (this.state.showItemEditModal) {
                let allRows                 =   this.state.storeIndentItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if(this.editItemModalRef.current){
                    this.editItemModalRef.current.itemEditModalInit(data); 
                }
                
            }
        });
        
    }

    submitEditItem          =       ()      =>      {
    
        HttpAPICall.withAthorization('GET', this.itemUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,1,null,false,true)
               });
        })
       
    }

    addItemModalInit          =       ()           =>      {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows                 =   this.state.storeIndentItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }

                if(this.addItemModalRef.current){
                    this.addItemModalRef.current.itemAddModalInit(); 
                }
               
            }
        });
        
    }

      //****************************CLOSE CONSUMPTION *********************/
      closeEvent              =           ()      =>      {
        
            this.props.history.push({pathname: `/warehouse_indent_list`})
        
    }

   
    addItemsfromSearch          =       (selectedItems)     =>  {
        let storeIndentItemRows = selectedItems.map((pu_item, k) => {

            return {
                item                        :          {...pu_item.item,isAddRemark : pu_item.remark ? true : false},
                remark                      :           '',
                gst                         :           pu_item.gst,
            };
        });
        let allRows = this.state.storeIndentItemRows ?  this.state.storeIndentItemRows.filter(i => i.item ? true : false) : [];
        let indentItemRows = allRows.concat(storeIndentItemRows);
        indentItemRows.forEach((consumedItem, key) => {
            this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem?.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,false,false);
        });
        
    
    }

    itemViewModalInit                       =   (item,k)  =>  {
        if(item){
            this.itemDetailsRef.current.modalInit(item);
            this.setState({itemRowNumber : k})
        }
        //this.setState({ itemSearchedDetails        :    data,itemRowNumber : k})
        
    }

    addStoreIndentFormSubmitEvent       =       (e,status = 0)     =>      {
        e.preventDefault();
        let items                   =        [...this.state.storeIndentItemRows];
        let additional_attributes = {};
        this.state.formData.additional_attributes.forEach((i, k) => {
            let name = 'additional_attributes_' + i.key;
            additional_attributes[i.key] = this.state.addStoreIndentForm[name] ? this.state.addStoreIndentForm[name] : '';
        });
        
        let itemsLists              =        items.filter(i => i.item ? true : false).map(i => {
                                                 return {...i, id : i.item.id, item : null,qty : i.qty ? i.qty : 0,remark : i.remark ? i.remark : ''};
                                                });
        let addStoreIndentForm       =      {...this.state.addStoreIndentForm , transaction_date : moment(this.state.addStoreIndentForm.transaction_date,'YYYY-MM-DD').format('YYYY-MM-DD'),status : status == 1 ? status : this.state.addStoreIndentForm.status }                          

        let showErrors                  =   [];
        let formData                        =  { ...addStoreIndentForm,items : itemsLists};

        if(formData.transaction_date == '') {
            showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
        }
        
        if(formData.warehouse_id == '') {
            showErrors                      =   [...showErrors , 'Please select Warehouse.'];
        }

        if(formData.status == '') {
            showErrors                      =   [...showErrors , 'Please select Status.'];
        }
     
        if(itemsLists.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and their qty.'];
        } else if(items.find(i => parseFloat(i.qty) <= 0)) {
            showErrors                      =   [...showErrors , 'Item quantity must be more than 0.'];
        }
        
       
        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            if (this.state.indentDetailData) {
                this.setState({ saveFormSubmitting: true })
                HttpAPICall.withAthorization('PUT', this.addUrl, this.props.access_token, {}, { ...formData, additional_attributes: additional_attributes }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/warehouse_indent_list`,state: {indentId : response.data.transaction_id } })
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                this.setState({ saveFormSubmitting: true })
                HttpAPICall.withAthorization('POST', this.addUrl, this.props.access_token, {}, { ...formData, additional_attributes: additional_attributes }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/warehouse_indent_list` })
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
            
            
           
        }
    }


    storeIndentItemJsx          =       ()          =>      {
        return (<div className="my-3 row">
            <div className="col-12">
                <table className="table table-bordered table-responsive bg-white">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" >Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.storeIndentItemRows.map((i, k) => {
                            let tdRows = (i.item) ? (<Ax>
                                <td className="text-start">
                                    <div className="">
                                        <div className=" d-flex justify-content-between">
                                            <div><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>{i.item.name}</span></div>
                                            <div>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu cursor_pointer">
                                                    <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>View Details</li>
                                                    {this.props?.permissions?.permissions?.includes("isc-item-edit") && <li className="dropdown-item" onClick={() => {this.setState({showItemEditModal : false},() => { this.editItemModalInit(i.item,k) })}}>Edit Item</li>}
                                                    <li className="dropdown-item" onClick={() => { this.addRemark(i.item, k) }}>Add Remark</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                        <div className="form-text">
                                            <b>Mfr: </b>{i.item.manufacturer ? i.item.manufacturer.name : '-'} (<b>MPN:</b> {i.item.manufacturer_part_no ? i.item.manufacturer_part_no : '-'})
                                        </div>
                                        {i.item?.tags?.length > 0
                                            ? (i.item?.tags.map((t, k) => <span key={k} className="badge bg-secondary">{t.name}</span>))
                                            : null
                                        }
                                    </div>
                                    {i.item && i.item.isAddRemark && i.item.isAddRemark == true
                                        ? <input
                                            name="remark"
                                            type="text"
                                            value={this.state.storeIndentItemRows[k].remark}
                                            onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'storeIndentItemRows')}
                                            className="form-control mt10"
                                            autoComplete="off"
                                            placeholder="Item Remarks"
                                        />

                                        : null}

                                </td>
                                <td className="req_table">
                                    <TapInputNumber
                                        name="qty"
                                        value={this.state.storeIndentItemRows[k].qty}
                                        onChange={(newValue) => {
                                            const updatedRows = [...this.state.storeIndentItemRows];
                                            updatedRows[k].qty = newValue; // Update the quantity in the corresponding row
                                            this.setState({ storeIndentItemRows: updatedRows });
                                          }}
                                        placeholder="Qty"
                                        required={true}
                                    />
                                    <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span>
                                </td>
                                <td className='text-end'>
                                    {i.item && i.item.purchase_price ? i.item.purchase_price : 0}
                                </td>
                               
                                <td className="text-end">
                                    {(this.state.storeIndentItemRows[k].qty * i?.item?.purchase_price).toFixed(2)}
                                </td>
                            </Ax>)
                                : (<Ax>
                                    <td>
                                        <InputItemSearch
                                            changeEvent={(selectedOption) => {
                                                this.onItemSelect(selectedOption, k);
                                            }}
                                            only_active_item='Y'
                                            
                                        />
                                    </td>
                                    <td colSpan="3" className="text-center align-middle" >-</td>
                                </Ax>);
                            return (<tr key={k}>
                                <td className="align-middle">{k + 1}</td>
                                {tdRows}
                                <td className="align-middle">
                                    <a href="#" onClick={() => this.removeItemRow(k)}>
                                        <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                    </a>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-12 text-end">
                {this.state.addStoreIndentForm && this.state.addStoreIndentForm.warehouse_id ?
                    <a role="button" onClick={() => {this.setState({showItemSearchModal : false},() => this.itemSearchModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                    : null}
                {this.props?.permissions?.permissions?.includes("isc-item-add") && <a role="button" onClick={() => {this.setState({showItemAddModal : false},() => this.addItemModalInit())}}  className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                <a role="button" onClick={() => this.addNewItemRow()} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
            </div>
        </div>)
    }


    saveStoreIndentFormJsx                  =   ()  =>  {
        let item_sub_total          =   0;
        let item_total_tax          =   0;
        this.state.storeIndentItemRows.map((i,k) => {
            if(i.item) {
                
                let total               =   parseFloat(this.state.storeIndentItemRows[k].qty * i?.item?.purchase_price);
               
                item_total_tax          =  total;
            }
        });
        let transactionDate = this.state.addStoreIndentForm && this.state.addStoreIndentForm.transaction_date ?  moment(this.state.addStoreIndentForm.transaction_date).format('YYYY-MM-DD 00:00:00') : null;
        return (<form className="bg-white p-3" onSubmit={this.addStoreIndentFormSubmitEvent}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Indent</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addStoreIndentForm.transaction_date
                                ? moment(this.state.addStoreIndentForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'addStoreIndentForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        disabled={this.state.indentDetailData ? true : false}
                        placeholderText={`Please Enter Date of Transaction`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="transaction_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allWarehouses.find(m => m.value == this.state.addStoreIndentForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'status');
                        }}
                        options={this.state.allStatus}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.indentDetailData ? true :false}
                        value={this.state.allStatus.find(s => s.value == this.state.addStoreIndentForm.status)}
                        placeholder="Select Status"
                    />
                </div>

            </div>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Title</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="title"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addStoreIndentForm.title}
                        onChange={(e) => this.formInputHandler(e, 'addStoreIndentForm')}
                        placeholder="Enter Title"
                    />
                </div>
            </div>

            <div className="row my-3">
                <div className="col-sm-3">
                    <label className="form-label">Indent For</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'indent_for');
                        }}
                        options={this.state.allIndentOptions}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allIndentOptions.find(s => s.value == this.state.addStoreIndentForm.indent_for)}
                        placeholder="Select Indent For"
                    />
                </div>
                <div className="col-sm-4">
                    {this.state.addStoreIndentForm.indent_for == "asset"
                        ? <Ax>
                            <InputAssetSearch
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'asset_id');
                                    this.setState({ selectedAsset: selectedOption ? { ...selectedOption.asset, label: selectedOption.display_label } : null });
                                }}
                                placeholder="Search Asset"
                                search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                filter={true}
                                required={true}
                                value={this.state.selectedAsset ? { ...this.state.selectedAsset } : null}
                            />

                            {this.state.selectedAsset
                                ?
                                <AssetMiniDetail assetDetail={this.state.selectedAsset} />

                                : null
                            }
                        </Ax>
                        : this.state.addStoreIndentForm.indent_for == "workstation"
                            ? <Ax>
                                 <InputWorkstationSearch
                                    placeholder="Please Select Workstation"
                                    changeEvent={(selectedOption) => {
                                        this.setState({
                                            searchedWorkstation : selectedOption ? selectedOption : null,
                                            addStoreIndentForm : { ...this.state.addStoreIndentForm, workstation_id: selectedOption ? selectedOption.value : '' }
                                        })

                                    }}
                                    menuPlacement="top"
                                    search_site_id={this.state.warehouseSites.map(wk => wk.id)}
                                    value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                                />

                                {/* <TapSelect
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'workstation_id');
                                        this.setState({ searchedWorkstation: selectedOption ? selectedOption : null });
                                    }}
                                    options={this.state.allWorkstations.filter(s => this.state.warehouseSites.map((w) => w.id).includes(s.workstation.site_id))}
                                    isSearchable={true}
                                    isClearable={true}
                                    required={true}
                                    value={this.state.allWorkstations.find(m => m.value == this.state.addStoreIndentForm.workstation_id)}
                                    placeholder="Please Select Workstation"
                                /> */}

                            </Ax>
                            : this.state.addStoreIndentForm.indent_for == "employee"
                                ? <Ax>
                                    {this.props.permissions.group_modules.includes("hrm")
                                        ?
                                        <InputEmployeeSearch
                                            placeholder="Please Select Employee"
                                            search_site_ids={this.state.warehouseSites.map((w) => w.id)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'employee_id');
                                                this.setState({ searchedEmployee: selectedOption ? selectedOption : null });
                                            }}
                                            transaction_date={transactionDate ? transactionDate : null}
                                            value={this.state.searchedEmployee ? this.state.searchedEmployee : null}
                                        />

                                        : null}
                                </Ax> : null
                    }
                </div>
            </div>
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label ">Tag Users</label>
                </div>
                <div className="col-4">
                <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'users')}
                        options={this.state.allUsers}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allUsers.filter(t => this.state.addStoreIndentForm.users.includes(t.value))}
                        placeholder="Please Select User"
                    />
                </div>
               
            </div>

            {
                this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0
                    ? (
                        <AdditionalAttributeForm
                    additionalAttributes={this.state.formData.additional_attributes}
                    formState={this.state.addStoreIndentForm}
                    onAttributeChange={(value,attributeName) => {
                        this.setState(() => ({
                            addStoreIndentForm: {
                            ...this.state.addStoreIndentForm,
                            [attributeName]: value
                        }
                    }))}
                } 
                />
                    )
                    : null
            }
            {this.storeIndentItemJsx()}
            <div className="row align-items-center mb-5">
                <div className="col-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addStoreIndentForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addStoreIndentForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                    <table className="table  table-borderless">
                        <tbody>
                            <tr className="total_bg_color"><th>Total</th><td className="text-end">{(item_sub_total + item_total_tax).toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary ms-2" onClick={(e) => { this.addStoreIndentFormSubmitEvent(e, 1); }}>
                    Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>

                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-primary ms-2" onClick={(e) => { this.addStoreIndentFormSubmitEvent(e); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                                  =   ()  =>  {
        return (<ApplicationLayout>
             <Helmet><title>Warehouse Indent - Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.indentDetailData ? "Edit" : "New"} Warehouse Indent {this.state.indentDetailData ? <span> of Transaction ID : {this.state.indentDetailData?.transaction_id}</span> : null}</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.form_loading ? <Loader/> : this.saveStoreIndentFormJsx()}
                    </div>
                </div>
            </div>
           
           {this.state.showItemAddModal &&<AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            <ItemDetailsModal ref={this.itemDetailsRef} /> 
            {this.state.showItemSearchModal && (<ItemSearchModal ref={this.itemSearchRef}
                afterSelectingItems={(selectedItems) => {
                    let allRows = this.state.storeIndentItemRows ?  this.state.storeIndentItemRows.filter(i => i.item ? true : false) : [];
                    let items = allRows.concat(selectedItems)
                     this.setState({storeIndentItemRows :  items})
                }}
               
                />)}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
    };
};

export default connect(mapStateToProps)(WarehouseIndentAdd);