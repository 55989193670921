import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import {number} from "prop-types";
import swal from "sweetalert";
import axios from "axios";
import TapApiUrls from "../../../../services/TapApiUrls";
import CreatableSelect from 'react-select/creatable';
import Status from "../../../../components/ui/Status";

class ActivityList extends AppBaseComponent {

    constructor(props) {
        super(props);

        
        this.initaddActivityForm      =   {
            name            :       '',
            id              :       '',
            units           :       [],
            is_global       :       'N'
        };

        this.state                              =   {
            activity_loading                :      false,
            activity_list                   :      null,
            activityAddForm                 :     {...this.initaddActivityForm},
            editFormSubmiting                   :      false,
            activity_form_loading           :       [],
            measuring_unit_list             :       []
        };
    }

    componentDidMount() {
        this.getActivityList();
        this.addActivityForm        =        new Modal(document.getElementById('addActivityFormModal'), {keyboard: false, backdrop :false});
       
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.configurationChanged !== this.props.configurationChanged) {
            this.getActivityList();
         }     
    }

    //functions for general configuration
    getActivityList              =    ()           =>     {
        this.setState({activity_loading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/activity_name_list', this.props.access_token , {}, {}, (resp) => {     
         this.setState({activity_list  : resp.data})
        }).then(() => this.setState({activity_loading: false}));
    }

    getActivityNameFormData             =    ()           =>     {
        this.setState({activity_form_loading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/activity_name_form_data', this.props.access_token , {}, {}, (resp) => {     
           this.setState({measuring_unit_list  : resp.data && resp.data.length > 0 ? resp.data.map(mu => {return({value:mu.measuring_unit,label:mu.measuring_unit})})  : []})
        }).then(() => this.setState({activity_form_loading: false}));
    }

    addActivityModalInit     =       (data)      =>      {
        this.addActivityForm.show();
        this.getActivityNameFormData();
        if(data){
            this.setState({
                activityAddForm     :   {
                    id      :   data.id,
                    name    :   data.activity_name,
                    is_global   :   data.is_global ? data.is_global : 'N',
                    units   :   data.production_units && data.production_units.length > 0 ? data.production_units.map(pu => {return{...pu,label : pu.measuring_unit,value:pu.measuring_unit}}) : []
                }
            })
        }else{
            this.setState({activityAddForm : {...this.initaddActivityForm}})
         }
    }

    handleMeasuringUnitChange                  =       (selectedOption)             =>       {
        this.setState({activityAddForm : {...this.state.activityAddForm,units:selectedOption}})

    }

    handleMeasuringUnitCreate                 =        (inputText)            =>       {
        const newValue = { value: inputText.toLowerCase(), label: inputText};
        this.setState({measuring_unit_list : [...this.state.measuring_unit_list,newValue]});
        let obj = {};
        obj["value"] = newValue.value;
        obj["label"] = newValue.label;
        this.state.activityAddForm?.units?.push(obj);
    }

    submitActivityFormHandler        =    (e)          =>     {
        e.preventDefault();
       
       if(this.state.activityAddForm && this.state.activityAddForm.id){
            this.setState({editFormSubmiting : true});
            let frmData = {...this.state.activityAddForm,units : this.state.activityAddForm.units.map(st=> st.value)}
            HttpAPICall.withAthorization('PUT',TapApiUrls.IAM + '/activity_name', this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addActivityForm.hide();
                this.getActivityList();
                this.setState({activityAddForm:{...this.initaddActivityForm}})
            }).then(() => this.setState({editFormSubmiting: false}));
       }else{
            this.setState({editFormSubmiting : true});
            let frmData = {...this.state.activityAddForm,units : this.state.activityAddForm.units.map(st=> st.value)}
            
            HttpAPICall.withAthorization('POST',TapApiUrls.IAM + '/activity_name', this.props.access_token, {...frmData} , {} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addActivityForm.hide();
                this.getActivityList();
                this.setState({activityAddForm:{...this.initaddActivityForm}})
            }).then(() => this.setState({editFormSubmiting: false}));
       }
    }

    changeActivityStatus                =    (id,status)  =>     {
        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/activity_name_change_status', this.props.access_token, {},{id:id} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getActivityList();
                    }).then(() => {

                    });
                }
            });
    }

    addActivityFormModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="addActivityFormModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.activityAddForm && this.state.activityAddForm.id ? "Edit" : "Add"} Activity</h5>
                        </div>
                        <form onSubmit={this.submitActivityFormHandler}>
                            <div className="modal-body">
                                <div className="my-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Name </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            value={this.state.activityAddForm.name}
                                            autoComplete="off"
                                            required={true}
                                            disabled={this.state.activityAddForm.is_global == 'Y' ? true : false}
                                            onChange={(e) => this.formInputHandler(e, 'activityAddForm')}
                                        />
                                    </div>
                                </div>
                                <div className="my-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">UOM </label>
                                    </div>
                                    <div className="col-9">
                                        <CreatableSelect
                                            isMulti
                                            required={true}
                                            value={this.state.activityAddForm.units}
                                            options={this.state.measuring_unit_list}
                                            onChange={(selectedItem) => this.handleMeasuringUnitChange(selectedItem)}
                                            onCreateOption={(inputText, callback) => this.handleMeasuringUnitCreate(inputText, callback)}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =     ()           =>     {
       
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">Activity Name List</span>
                <div className="text-end "><button className="btn btn-primary" onClick={() => this.addActivityModalInit()}>Add </button></div>
            </div>
            <table className="table table-hover table-bordered my-4 ">
                <thead className="table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "10%" }}>S.No</th>
                        <th scope="col">Activity Name</th>
                        <th scope="col" style={{ width: "35%" }} >UOM</th>
                        <th scope="col" style={{ width: "15%" }} >Status</th>
                        <th scope="col" style={{ width: "10%" }} >Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.activity_loading
                        ? <tr className="text-center"><td colSpan={4}><Loader /></td></tr>
                        : this.state.activity_list && this.state.activity_list.length > 0
                            ? this.state.activity_list.map((mt, index) => {
                                return (<tr className="text-center">
                                    <td >{index + 1}</td>
                                    <td className="text-start">{mt.activity_name ? mt.activity_name : ""} {mt.is_global == 'Y' ? <sup className="form-text">*</sup> : null}</td>
                                    <td className="text-start">{mt.production_units && mt.production_units.length > 0 ? mt.production_units.map(pu => { return (pu.measuring_unit) }).join(', ') : "-"}</td>
                                    <td><Status color={mt.status_data.color_code}>{mt.status_data.status}</Status></td>
                                    <td>

                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#"
                                            role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                         <li className="dropdown-item" onClick={() => this.addActivityModalInit(mt)} >Edit</li>
                                            <li className="dropdown-item" onClick={() => this.changeActivityStatus(mt.id, mt.status)}>{mt.status == 1 ? "Deactivate" : "Activate"}</li>
                                        </ul>
                                    </td>

                                </tr>)
                            })
                            : <tr className="text-center"><td colSpan={4}>No Records</td></tr>}

                </tbody>
            </table>
            <div className="form-text my-2">* Global Activity</div>
            {this.addActivityFormModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(ActivityList);