import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";

import Ax from "../../../components/hoc/Ax";

import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';

import ApplicationLayout from '../../../layouts/ApplicationLayout';

import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from '../../../services/TapIcon';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import ProductivityView from './ProductivityView';
import AssetCard from '../../includes/assetCard/AssetCard';
import moment from 'moment';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from '../../../components/ui/TapSelect';
import download from '../../../services/DownloadFile';
import swal from 'sweetalert';


class ProductivityList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.productivitySearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_activities'             :    [],
            'search_units'                   :    [],
            'search_material_ids'           :    [],
            'search_attachment_ids'         :    [],
            'site_group_ids'                :    [],
            'search_asset_ids'              :   [],
            'asset_group_ids'               :    [],   
            'search_asset_type_ids'         :   [],
            'search_site_ids'               :   [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'activity_transaction_delay'     :   '',
           'search_counterlog_transaction_id'         :   '',
        };

        this.productivitySearchFormLabel         =   {
            'site_group_ids'                 :   'Site Groups : ',
            'search_asset_ids'               :   'Asset : ',
            'search_activities'              :   'Activity : ',
            'search_attachment_ids'          :   'Attachment : ',
            'search_units'                   :    'Production Unit : ',
            'search_material_ids'           :    'Material : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            'search_asset_type_ids'          :   'Asset Type : ',
            'search_site_ids'                :   'Site : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'activity_transaction_delay'     :   'Transaction Delay : ',
            'search_counterlog_transaction_id'   :   'Counterlog Transaction ID : ',
        };

        this.state                         =     {  
            minimizeTable               :         false,   
            listing_loading             :         false,
            listing_tbl_page            :         1,
            productivity_listing        :         [],
            listingMeta                 :         null,
            totalListingCount           :         0,
            productivityData            :          null,
            viewDataLoading             :          false,
            access_token                :          '',
            all_periods                 :          [],
            iam_asset_types             :          [],
            iam_group_sites             :          [],
            productivitySearchForm      :          {...this.productivitySearchInitState},
            formSearchedElems           :          [],
            productivitySearchParams    :          null,
            allTransactionDelay         :          [],
            searchedAssets              :           [],
            allActivitesList            :           [],
            allProductionUnitList       :           [],
            allMaterialList             :           [],
            allSiteGroupsList           :           [],
            file_downloading            :           false,
            allAssetTypesGroupsList         :   [],
            allAttachmentLists          :       []
        };

    }
    
    
    componentDidMount() {
        this.setState({access_token : this.props.access_token,listing_loading:true})
        setTimeout(() => {
            this.searchFormDataInit(this.props);
        },1000)
       
        this.productivitySearchModal         =    new Modal(document.getElementById('productivitySearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.access_token !== this.props.access_token) {
            this.setState({access_token : nextProps.access_token})      
        }
        // if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
        //      this.searchFormDataInit(nextProps)
        // } 
            
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                     =   {page:page,need_asset_profile : 'Y',...this.state.productivitySearchParams};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/productivity_list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                productivity_listing    :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
           });
        }).then(() => this.setState({listing_loading: false}));
    }

    viewProductivityDetail           =       (id)        =>      {
        this.setState({minimizeTable : true})
        // if(this.state.productivityData && this.state.productivityData.productivity_id !== id){
            this.getProductivityDetail(id)
        // }
         
    }

    closeView           =       ()        =>      {
        this.setState({minimizeTable : false})
    }

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/productivity_list_form-data', this.props.access_token, {})
            .then((response) => {
               let respData = response.data
              
              
                this.setState({
                    iam_asset_types         :       props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                    iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                    all_periods             :       all_periods,
                    allTransactionDelay     :       response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : [],
                    allActivitesList        :       respData && respData.activities ? respData.activities.map((td) => { return {value: td.id, label: td.activity_name,production_units : td.production_units}}) : [],
                    allSiteGroupsList       :       props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    allProductionUnitList   :       respData && respData.activities ? respData.activities.map((td) => { return {value: td.id, label: td.activity_name,production_units : td.production_units}}) : [],
                    allMaterialList         :       respData && respData.materials && respData.materials.length > 0 ? respData.materials.map(a => { return ({ value: a.id, label: a.material_name }) }) : [],
                    allAttachmentLists      :       respData && respData.attachments && respData.attachments.length > 0 ? respData.attachments.map(a => { return ({ value: a.id, label: a.name }) }) : [],
                    allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                },() => {
                    let allProductionUnits = [];
                    let activityListContainingProduction = this.state.allActivitesList && this.state.allActivitesList.length > 0 && this.state.allActivitesList.filter(p => { return (p.production_units && p.production_units.length > 0 ? true : false) });
                    let allProductionUnitList = activityListContainingProduction && activityListContainingProduction.length > 0 && allProductionUnits.concat(activityListContainingProduction.flatMap((d) => { return d.production_units.map(p => { return ({ value: p.id, label: p.measuring_unit }) }) }))
                    const key = 'value';
                    const UniqueProductionUnitByKey = allProductionUnitList && allProductionUnitList.length > 0 && [...new Map(allProductionUnitList.map(item => [item[key], item])).values()];
                    this.setState({allProductionUnitList : UniqueProductionUnitByKey});
                    let ParamObject                 =       new URLSearchParams(this.props.location.search);
                    if(ParamObject.get('search_site_ids') || ParamObject.get('entry_date_range') || ParamObject.get('activity_transaction_delay') ) {
                        let updateFilterForm = {'entry_date_range' : ParamObject.get('entry_date_range'),'activity_transaction_delay' : ParamObject.get('activity_transaction_delay') };
                      
                      if(ParamObject.get('search_site_ids')){
                           updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                       }
    
                       setTimeout(() => {
                           this.setState({productivitySearchForm : {...this.state.productivitySearchForm,...updateFilterForm}},
                           function(){
                                this.productivitySearchFormSubmit();
                               });
                       }, 1)
                     
                    }else{
                        this.loadListingTblData(1);
                    }
                })
                   
            }) 
    }

    getProductivityDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/productivity/' + id, this.state.access_token ? this.state.access_token : this.props.access_token, {need_counterlog : 'Y',need_site : 'Y',need_user : 'Y'}, {}, (response) => {
            
            this.setState({
                productivityData: {...response.data.data,counterlog : {...response.data.data.counterlog,site: response.data.data.site}}
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    productivitySearchModalInit        =       ()      =>      {
        this.productivitySearchModal.show()
    }

    productivitySearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems           :   [],
            productivitySearchParams    :   null,
            searchedAssets              :   [],
            productivitySearchForm      :   {...this.productivitySearchInitState}
        }, () => {
            this.productivitySearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    productivitySearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.productivitySearchForm);
        let searchedElems               =   [];
        let productivitySearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.productivitySearchFormLabel[key];
            let value                       =   this.state.productivitySearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                productivitySearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activities') {
                        show_val            =  this.state.allActivitesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_attachment_ids') {
                        show_val            =  this.state.allAttachmentLists.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_units') {
                        show_val            =  this.state.allProductionUnitList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_material_ids') {
                        show_val            =  this.state.allMaterialList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.productivitySearchForm && this.state.productivitySearchForm.search_transaction_id ? this.state.productivitySearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_counterlog_transaction_id') {
                        show_val            =  this.state.productivitySearchForm && this.state.productivitySearchForm.search_counterlog_transaction_id ? this.state.productivitySearchForm.search_counterlog_transaction_id : '';
                    }
                    if(key == 'activity_transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.productivitySearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.productivitySearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.productivitySearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.productivitySearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            productivitySearchParams      :       productivitySearchParams
        }, () => {
            this.productivitySearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    productivityDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.productivitySearchParams};
        if(this.state.productivitySearchParams !== null){
            this.productivityDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.productivityDownloadStart(params);
                }
            });
        }
    }
    

    productivityDownloadStart              =    (params)     =>         {
        this.setState({ file_downloading: true});
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/productivity_list', this.props.access_token,{download : "download",...params},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({file_downloading: false}));
    } 
     //***********************productivity JSX****************

    productivityListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Productivity List</h3>
                <div className="text-end mt15">
                    <button type="button"  disabled={this.state.listing_loading} onClick={this.productivitySearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <button type="button" className="btn btn-secondary"
                        disabled={this.state.file_downloading || this.state.listing_loading}
                        onClick={this.productivityDownloadHandler} >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.file_downloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                  
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3 className="text-capitalize">Productivity  : {this.state.productivityData && this.state.productivityData.productivity_id ? this.state.productivityData.productivity_id : '-'}  </h3>
                            <div className="text-end mt15">

                                <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="productivity-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.productivityListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4 bg-white ">{this.state.viewDataLoading ? <Loader /> : <div className='tab_content_header' style={{ height: "80vh" }}>{this.productivityViewJsx()}</div>}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    productivityListingTableJsx            =   ()   =>   {
       
        return (<Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.productivitySearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Productivity</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "10%" }} className='text-center'>Date</th>
                            <th scope="col" style={{ width: "12%" }}> Transaction ID</th>
                            <th scope="col" style={{ width: "15%" }}>Asset</th>
                            <th scope="col" style={{ width: "15%" }}>Asset Type</th>
                            <th scope="col" style={{ width: "15%" }}>Activity Type</th>
                            <th scope="col" style={{ width: "15%" }}>Net Productivity</th>
                            <th scope="col" style={{ width: "8%" }}>Material</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.productivity_listing.length > 0
                            ? (this.state.productivity_listing.map((productivity, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} >
                                                        <div className="text-capitalize link-primary"><a href="#" onClick={() => this.viewProductivityDetail(productivity.productivity_id)}>{productivity.productivity_id ? productivity.productivity_id : "-"}</a></div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{productivity.activityData  ? productivity.activityData.activity_name : "-"}</small>
                                                            <small className="float-end">{productivity.production ? productivity.production : null}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                               <td className='text-center' >{productivity.activity_date_display ? productivity.activity_date_display : "-"}</td>
                                               <td className='link-primary cursor_pointer' onClick={() => this.viewProductivityDetail(productivity.productivity_id)} >{productivity.productivity_id ? productivity.productivity_id : "-"}</td>
                                               <td>{productivity.asset  ? productivity.asset.name : "-"}</td>
                                               <td>{productivity.asset  ? productivity.asset.asset_type_name : "-"}</td>
                                               <td>{productivity.activityData  ? productivity.activityData.activity_name : "-"}</td>
                                               <td>{productivity.production ? productivity.production : null} {productivity.production_unit ? <span>({productivity.production_unit})</span> : null}</td>
                                               <td>{productivity.materialData  ? productivity.materialData.material_name : "-"}</td>
                                                <td className="text-center">
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewProductivityDetail(productivity.productivity_id)} >View</a></li>

                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    productivityViewJsx           =       ()      =>      {
        return (<Ax>
            <div className='row'>
                <div className='col-sm-9'>
                    <ProductivityView productivityData={this.state.productivityData} />
                </div>
                <div className='col-sm-3'>
                    {this.state.productivityData && this.state.productivityData.asset_id
                        ? <AssetCard assetId={this.state.productivityData.asset_id} />
                        : null}
                </div>
            </div>

        </Ax>)
    }

    productivitySearchModalJsx         =   ()  =>  {
        return (
            <div className="modal fade" id="productivitySearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="productivitySearchModalLabel">Productivity Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.productivitySearchFormSubmit} id="productivitySearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.productivitySearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "productivitySearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                <label className="col-sm-2 col-form-label col-form-label-sm text-end">Counterlog Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_counterlog_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.productivitySearchForm.search_counterlog_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "productivitySearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Activity Name</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allActivitesList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_activities')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allActivitesList.filter(s => this.state.productivitySearchForm.search_activities.includes(s.value))}
                                            placeholder="Select Activity"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Production Unit</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allProductionUnitList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_units')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allProductionUnitList.filter(s => this.state.productivitySearchForm.search_units.includes(s.value))}
                                            placeholder="Select Production Unit"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Material</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allMaterialList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_material_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allMaterialList.filter(s => this.state.productivitySearchForm.search_material_ids.includes(s.value))}
                                            placeholder="Select Material"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Attachment</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allAttachmentLists}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_attachment_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAttachmentLists.filter(s => this.state.productivitySearchForm.search_attachment_ids.includes(s.value))}
                                            placeholder="Select Attachments"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                              
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type Group</label>
                                    <div className="col-sm-10">
                                    <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    productivitySearchForm: {
                                                        ...this.state.productivitySearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.productivitySearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.productivitySearchForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Group</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allSiteGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    productivitySearchForm: {
                                                        ...this.state.productivitySearchForm,
                                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allSiteGroupsList.filter(s => this.state.productivitySearchForm.site_group_ids.includes(s.value))}
                                            placeholder="Select Site Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Executed Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.productivitySearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Executed Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'activity_transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.productivitySearchForm.activity_transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.productivitySearchForm.entry_date_range}
                                            startDate={this.state.productivitySearchForm.entry_date_range_start}
                                            endDate={this.state.productivitySearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    productivitySearchForm
                                                        : {
                                                        ...this.state.productivitySearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.productivitySearchForm.date_range}
                                            startDate={this.state.productivitySearchForm.date_range_start}
                                            endDate={this.state.productivitySearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    productivitySearchForm
                                                        : {
                                                        ...this.state.productivitySearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Productivity Listing</title></Helmet>
            {this.productivityListjsx()}
            {this.productivitySearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [], 
    };  
};

export default connect(mapStateToProps)(ProductivityList);