import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import AdditonalAttribute from './AdditonalAttribute';
import GeneralSetting from './GeneralSetting';
import Workflow from './Workflow';

class RentalConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general"
                                type="button" role="tab" aria-controls="general" aria-selected="true">General Settings
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="attribute-tab" data-bs-toggle="tab" data-bs-target="#attribute"
                                type="button" role="tab" aria-controls="attribute" aria-selected="true">Additional Attribute
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane  show active" id="general" role="tabpanel"
                            aria-labelledby="general-tab">
                            <div className="tab_content_header">
                               <GeneralSetting/>
                            </div>
                        </div>
                        <div className="tab-pane" id="workflow" role="tabpanel"
                            aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <Workflow/>
                            </div>
                        </div>
                        <div className="tab-pane" id="attribute" role="tabpanel"
                            aria-labelledby="attribute-tab">
                            <div className="tab_content_header">
                               <AdditonalAttribute/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(RentalConfiguration);