import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplaySearchCriteria} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetDataScoreReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportUrl                      =   IAM_API_BASE_URL_2 + '/report/asset_data_score';
        this.reportFilterFormInitialState   =   {
            search_asset_ids                    :   [],
            site_group_ids                      :   [],
            search_site_ids                     :   [],
            asset_group_ids                     :   [],
            search_asset_type_ids               :   [],
            search_asset_matrix_ids             :   [],
            search_manufacturer_ids             :   [],
            search_models                       :   [],
        };
        
        this.reportFilterFormLabel          =   {
            search_asset_ids                    :   'Assets : ',
            site_group_ids                      :   'Site Groups : ',
            search_site_ids                     :   'Sites : ',
            asset_group_ids                     :   'Asset Type Groups : ',
            search_asset_type_ids               :   'Asset Type : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            search_models                       :   'Models : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedAssets                      :   [],
            listingData                         :   [],
            showTblData                         :   false,
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],
            iam_manufactures                    :   [],
            iam_models                          :   [], 
            customChecksheetList                :   [],
            assetData                           :   null,
            allSiteGroupsList                   :   [],
            allAssetTypesGroupsList             :   [],
            reportData                          :   null
        };
    }
    
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {

        const report_name   =   'asset_data_score';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            access_token                :   props.access_token,
            formDataLoaded              :   true,
            iam_asset_types             :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_models                  :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
            iam_user_sites              :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_manufactures            :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
            allSiteGroupsList           :   props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(sg => { return ({ value: sg.id, label: sg.name, linked_sites: sg.site_ids }) }) : [],
            allAssetTypesGroupsList     :   props.asset_type_groups && props.asset_type_groups.length > 0 ? props.asset_type_groups.map(ag => {  return ({ value: ag.id, label: ag.name, linked_asset_types: ag.asset_type_ids }) }) : [],
        }, () => {
            this.setState({formDataLoaded  :   false}, () => {
                this.reportFilterhandler();
            });
        });
 
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData,
                reportDownloadBtn           :   true,
                showTblData                 :   true,
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    
    reportDownloadHandler                   =   (type)  =>  {

        this.setState({ reportDownloading: true});

        let params      =   { action : 'download', type, ...this.state.submittedReportFilterForm}

        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Asset Data Score Report</h3>
                <div className="text-end mt15">
                   {this.state.reportDownloadBtn ? (
                    <>
                        <button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N' || !this.state.reportData} type="button" className="btn btn-primary" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link className="dropdown-item" role="button" onClick={() => this.reportDownloadHandler('asset')}>Asset wise {this.state.reportData?.name}</Link></li>
                            <li><Link className="dropdown-item" role="button" onClick={() => this.reportDownloadHandler('site')}>Site wise {this.state.reportData?.name}</Link></li>
                        </ul>
                    </>
                    ) : null} 

                    {/* <button disabled={this.state.reportDownloading} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>  */}


                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({
                                    searchedAssets: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.reportFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.showTblData ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        const listingData = this.state.listingData;
        let totalActive = 0;
        let totalActiveScore = 0;
        let totalIdle = 0;
        let totalIdleScore = 0;
        let totalBreakdown = 0;
        let totalBreakdownScore = 0;
        let totalUnhealthy = 0;
        let totalUnhealthyScore = 0;
        let totalAssets = 0;
        let totalAssetsScore = 0;

        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }}></th>
                        <th scope="col" style={{ width: "20%" }}></th>
                        <th scope="col" colSpan={2} style={{ width: "15%" }}>Active</th>
                        <th scope="col" colSpan={2} style={{ width: "15%" }}>Idle</th>
                        <th scope="col" colSpan={2} style={{ width: "15%" }}>Breakdown</th>
                        <th scope="col" colSpan={2} style={{ width: "15%" }}>Unhealthy</th>
                        <th scope="col" colSpan={2} style={{ width: "15%" }}>Total</th>
                    </tr>
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }} >S.No</th>  
                        <th scope="col" style={{ width: "20%" }} >Site Name</th>                 
                        <th scope="col" style={{ width: "7.5%" }} >Assets</th>                 
                        <th scope="col" style={{ width: "7.5%" }} >Score</th>                 
                        <th scope="col" style={{ width: "7.5%" }} >Assets</th>                 
                        <th scope="col" style={{ width: "7.5%" }} >Score</th>
                        <th scope="col" style={{ width: "7.5%" }} >Assets</th>                 
                        <th scope="col" style={{ width: "7.5%" }} >Score</th>    
                        <th scope="col" style={{ width: "7.5%" }} >Assets</th>                 
                        <th scope="col" style={{ width: "7.5%" }} >Score</th>  
                        <th scope="col" style={{ width: "7.5%" }} >Assets</th>                 
                        <th scope="col" style={{ width: "7.5%" }} >Score</th>               
                    </tr>
                </thead>
                <tbody>
                {listingData.length > 0 
                    ? 
                    <>
                    {
                        listingData.map((data, i) => {
                            totalActive += parseFloat(data.active_asset);
                            totalActiveScore += parseFloat(data.active_asset_score);
                            totalIdle += parseFloat(data.idle_assets);
                            totalIdleScore += parseFloat(data.idle_assets_score);
                            totalBreakdown += parseFloat(data.breakdown_assets);
                            totalBreakdownScore += parseFloat(data.breakdown_assets_score);
                            totalUnhealthy += parseFloat(data.in_shop);
                            totalUnhealthyScore += parseFloat(data.in_shop_score);
                            totalAssets += parseFloat(data.no_of_asset);
                            totalAssetsScore += parseFloat(data.no_of_asset_score);
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data.site_name}</td>
                                    <td className='text-end'>{data.active_asset}</td>
                                    <td className='text-end'>{data.active_asset_score}</td>
                                    <td className='text-end'>{data.idle_assets}</td>
                                    <td className='text-end'>{data.idle_assets_score}</td>
                                    <td className='text-end'>{data.breakdown_assets}</td>
                                    <td className='text-end'>{data.breakdown_assets_score}</td>
                                    <td className='text-end'>{data.in_shop}</td>
                                    <td className='text-end'>{data.in_shop_score}</td>
                                    <td className='text-end'>{data.no_of_asset}</td>
                                    <td className='text-end'>{data.no_of_asset_score}</td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <th className='text-end' colSpan={2}>Total</th>
                        <th className='text-end'>{totalActive?.toFixed(2)}</th>
                        <th className='text-end'>{totalActiveScore?.toFixed(2)}</th>
                        <th className='text-end'>{totalIdle?.toFixed(2)}</th>
                        <th className='text-end'>{totalIdleScore?.toFixed(2)}</th>
                        <th className='text-end'>{totalBreakdown?.toFixed(2)}</th>
                        <th className='text-end'>{totalBreakdownScore?.toFixed(2)}</th>
                        <th className='text-end'>{totalUnhealthy?.toFixed(2)}</th>
                        <th className='text-end'>{totalUnhealthyScore?.toFixed(2)}</th>
                        <th className='text-end'>{totalAssets?.toFixed(2)}</th>
                        <th className='text-end'>{totalAssetsScore?.toFixed(2)}</th>
                    </tr>
                    </>
                    :
                    <tr className='text-center'><td  colSpan={12}>No Record Found</td></tr>
                }
                </tbody>
            </table>
        </Ax>);
    }
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
             <Helmet><title>Asset Data Score - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? <Loader />
                            : (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        asset_type_groups       :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetDataScoreReport);
