import {connect} from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from "../../../services/HttpAPICall";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import {Modal} from "bootstrap";
import {toast} from "react-toastify";

class TransactionAgeing extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                  =   {
            transaction_loading                 :   false,
            transaction_list                    :   {},
            transactionData                     :   {},
            transactionUpdateFormSubmitting     :   false,
        };
    }
    
    
    componentDidMount                           =   ()  => {
        this.transactionUpdateModal                 =   new Modal(document.getElementById('transactionUpdateModal'), {keyboard: false, backdrop :false});
        this.loadTransactionSetting();
    }
    
    loadTransactionSetting                      =   ()  =>  {
        this.setState({transaction_loading : true},() => {
            //Load Group Transaction Delay Setting (Delay days and DashBoard Visibilty) :-
            HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/group/transaction_setting', this.props.access_token, {} , {}, (response) => {
                this.setState({
                    transaction_list     :    response.data.data
                });
            }).then(() => {
                this.setState({transaction_loading: false});
            });
        });
    }
    
    transactionAgingEditHandler                 =   (transaction)  =>  {
        this.setState({transactionData : {...this.state.transaction_list.find(tl => tl.transaction == transaction ), transaction : transaction} });
        this.transactionUpdateModal.show();
    }
    
    transactionUpdateFormSubmit                 =   (event) =>  {
        event.preventDefault();
        this.setState({transactionUpdateFormSubmitting : true});
        
        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_IAM_API_SERVER + '/group/transaction_setting', this.props.access_token, {} , this.state.transactionData, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.transactionUpdateModal.hide();
            this.loadTransactionSetting();
        }).then(() => this.setState({transactionUpdateFormSubmitting: false}));
    }
    
    transactionUpdateModalJsx                   =   ()  =>  {
        let transactionData                         =   this.state.transactionData;
        return (
            <div className="modal fade" id="transactionUpdateModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit Transaction Delay</h5>
                            <button type="button" disabled={this.state.transactionUpdateFormSubmitting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.transactionUpdateFormSubmit}>
                            <div className="row my-3 align-items-center">
                                <div className="col-sm-4 text-end">
                                    <label className="col-form-label">Transaction Type</label>
                                </div>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control" disabled={true} value={transactionData ? transactionData.transaction_type : ''} />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <div className="col-sm-4 text-end">
                                    <label className="col-form-label">Maximum Delayed Allowed</label>
                                </div>
                                <div className="col-sm-5">
                                    <input
                                        name="delay"
                                        type="number" 
                                        className="form-control"
                                        step="1"
                                        pattern="[0-9]+"
                                        max={999}
                                        min={0}
                                        onChange={(e) => this.formInputHandler(e,'transactionData')}
                                        value={this.state.transactionData.delay ? this.state.transactionData.delay : ''}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <span className="form-text">Maximum Allowed Period in days</span>
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <div className="col-sm-4 text-end">
                                    <label className="col-form-label">Visibility in Dashboard</label>
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-auto">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="visibility" 
                                                    value="Y"
                                                    onChange={(e) => this.formInputHandler(e,"transactionData")}
                                                    checked={this.state.transactionData.visibility === "Y"}/> Yes
                                            </label>
                                        </div>
                                        <div className="col-auto">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="visibility"
                                                    value="N"
                                                    onChange={(e) => this.formInputHandler(e,"transactionData")}
                                                    checked={this.state.transactionData.visibility === "N"} /> No 
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.transactionUpdateFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.transactionUpdateFormSubmitting} className="btn btn-primary">Save {this.state.transactionUpdateFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : null} </button>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    
    render                                      =   ()  =>  {
        return (<Ax>
            <div className="col-md-12 bg-white">
                <table className="table table-fixed table-hover table-bordered align-middle">
                    <thead>
                        <tr className="table-secondary text-center">
                            <th className="text-start" style={{width: "20%"}}>Transaction Type</th>
                            <th style={{width :"40%"}}>Maximum delay allowed <br/>(in Days)</th>
                            <th style={{width: "20%"}}>Visibility in Dashboard </th>
                            <th style={{width: "15%"}} >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.transaction_loading 
                        ? (<tr><td colSpan="4"><Loader/></td></tr>)
                        : ( this.state.transaction_list.length > 0 
                                ? (this.state.transaction_list.map((transactionData) => {
                                    return <tr className="text-center" >
                                        <td className="text-start" >{transactionData.transaction_type}</td>
                                        <td>{transactionData.delay}</td>
                                        <td>{transactionData.visibility =='Y' 
                                                ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} /> 
                                                : <tapIcon.FontAwesomeIcon color="red" icon={tapIcon.faTimes} />}
                                        </td>
                                        <td className="p0 text-center">
                                            <span className="dropdown">
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    { this.props.permissions.includes('iam-configuration-transaction-setting')
                                                        ? <li><a onClick={(e) => this.transactionAgingEditHandler(transactionData.transaction)} className="dropdown-item" role="button">Edit</a></li>
                                                        : <li><a className="dropdown-item text-muted" title="You do not have permission to perform this action">Edit</a></li>
                                                    }
                                                </ul>
                                            </span>
                                        </td>
                                    </tr>;
                                }))
                                : <tr> <td colSpan="4">No Records to Show</td></tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            {this.transactionUpdateModalJsx()}
        </Ax>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions
    }
}

export default connect(mapStateToProps)(TransactionAgeing);