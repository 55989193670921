import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';
import InventoryTransferDetail from './InventoryTransferDetail';

class TransferItemWiseModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                            =         {
            transferDetailList                      :         [],
            transferDetailLoading                   :         false,
            itemName                          :         '',
            itemData                          :         null,
            warehouse_id                    :         null,
            listing_tbl_page                  :          1,
            listingMeta                       :          null,
            totalListingCount                 :         0,
            indent_transaction_id              :         '',
            transactionId                       :       '',
            warehouse_from_id                   :       '',
            status_id                           :       ''
        };

        this.inventoryTransferReportUrl     =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-inventory-tranfer';
       
    }
    
    
    
    componentDidMount(){
        this.viewHistoryModal             =   new Modal(document.getElementById('viewHistoryModal'), {keyboard: false, backdrop :false});
        this.viewTransactionModal         =   new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (data,warehouse_id,indent_transaction_id='',warehouse_from_id='',status_id='')  =>  {
        this.viewHistoryModal.show();
        
        if(data){
            this.setState({
                itemData            :        data,
                warehouse_id        :        warehouse_id ? warehouse_id : null,
                indent_transaction_id    :       indent_transaction_id,
                warehouse_from_id       :       warehouse_from_id,
                status_id               :       status_id,
            },() => this.fetchTransferHistoryData())
        }
    }

    fetchTransferHistoryData     =       (page = 1)      =>      {
        this.setState({transferDetailLoading : true, transferDetailList : [],listing_tbl_page: page});
        let obj = {};
        if(this.state.indent_transaction_id){
            obj['indent_in_transit']  = 'Y';
            obj['search_indent_transaction_id']  = this.state.indent_transaction_id;

        }

        if(this.state.warehouse_from_id){
            obj['search_warehouse_from_ids']  = [this.state.warehouse_from_id];
            obj['search_status_ids']  = [this.state.status_id];
        }

        let params =  {
                    search_warehouse_ids        :    this.state.warehouse_id !== undefined && this.state.warehouse_id !== null ? [this.state.warehouse_id] : [],
                    search_item_ids             :    this.state.itemData ? [this.state.itemData.id] : null,action                      :       "data",
                    ...obj,
                    page                        :    page}
                    
        HttpAPICall.withAthorization('GET', this.inventoryTransferReportUrl, this.props.access_token, {...params}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                transferDetailList          :    respData.data,
                itemName              :    this.state.itemData ? this.state.itemData.name : '',
                listingMeta           :     respData.meta,
                totalListingCount     :     respData.total,
            })
        
        }).then(() => {
            this.setState({transferDetailLoading : false});
        });
    }

    transferDetailModalInit          =       (transactionId)           =>      {
        this.setState({
             transactionId :   transactionId
        })  
        this.viewTransactionModal.show()
     }

    transferHistoryModalJsx                     =   ()  =>  {

        return (
            <div className="modal fade" id="viewHistoryModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addItemModalLabel">Item Wise Inventory Transfer</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    {this.state.indent_transaction_id && <tr>
                                        <td style={{ width: "15%" }}> Indent  </td>
                                        <th>{this.state.indent_transaction_id ? this.state.indent_transaction_id : ""}</th>
                                    </tr>}
                                    {this.state.warehouse_id && <tr>
                                        <td style={{ width: "15%" }}> Warehouse  </td>
                                        <th>{this.state.transferDetailList && this.state.transferDetailList.length > 0 && this.state.transferDetailList[0]?.warehouse_name ? `${this.state.transferDetailList[0]?.warehouse_name}` : "-"}</th>
                                    </tr>}
                                    {this.state.warehouse_from_id && <tr>
                                        <td style={{ width: "15%" }}> Warehouse From </td>
                                        <th>{this.state.transferDetailList && this.state.transferDetailList.length > 0 && this.state.transferDetailList[0]?.warehouse_from ? `${this.state.transferDetailList[0]?.warehouse_from?.name}` : "-"}</th>
                                    </tr>}
                                    <tr>
                                        <td style={{ width: "15%" }}> Item  </td>
                                        <th>{this.state.itemName ? this.state.itemName : "-"}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body ">
                            {!this.state.transferDetailLoading ?
                                <table className="table table-bordered  bg-white ">
                                    <thead className="table-secondary">
                                        <tr className="text-end">
                                            <th scope="col" style={{ width: "12%" }} className="text-start">Transaction ID</th>
                                            <th scope="col" style={{ width: "12%" }} className="text-center">Transaction Date</th>
                                            <th scope="col" style={{ width: "20%" }} className="text-start" >Warehouse To</th>
                                            {this.state.indent_transaction_id && <th scope="col" style={{ width: "20%" }} className="text-start" >Warehouse From</th>}
                                            <th scope="col" style={{ width: "9%" }}>Total Qty</th>
                                            <th scope="col" style={{ width: "9%" }}>Unit Rate</th>
                                            <th scope="col" style={{ width: "9%" }}>Amount</th>
                                            <th scope="col" style={{ width: "9%" }} className='text-center'>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.transferDetailList && this.state.transferDetailList.length > 0
                                            ? (this.state.transferDetailList.map((rowData, k) => {
                                                //   return rowData.items.map((rq, rk) => {
                                                return (<tr key={k}>
                                                    <td>{rowData.transaction_id}</td>
                                                    <td>{rowData.transaction_date_display}</td>
                                                   <td className='text-capitalize'>{rowData.warehouse_to ? rowData.warehouse_to.name : ""}</td>
                                                   {this.state.indent_transaction_id &&   <td className='text-capitalize'>{rowData.warehouse_from ? rowData.warehouse_from.name : ""}</td>}
                                                    <td className='text-end'>{rowData.qty}</td>
                                                    <td className='text-end'>{rowData.rate}</td>
                                                    <td className='text-end'>{rowData.qty * rowData.rate}</td>
                                                    <td className='text-center'><Status color={rowData.status_color}>{rowData.status_text}</Status></td>

                                                </tr>);
                                                //   })
                                            }))
                                            : (<tr><td className="text-center" colSpan={10}>No Record Found</td></tr>)
                                        }
                                    </tbody>
                                </table>
                                : <Loader />}
                            <DisplayListPagination
                                meta={this.state.listingMeta}
                                onPageChange={(e) => this.fetchTransferHistoryData(e.selected + 1)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    transactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {this.state.transactionId ? this.state.transactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                          <InventoryTransferDetail inventoryId={this.state.transactionId}/>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (<Ax>
            {this.transferHistoryModalJsx()}
            {this.transactionDetailsModalJsx()}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TransferItemWiseModal);
