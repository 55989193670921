import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon"
import TapSelect from '../../../../components/ui/TapSelect';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import TapIcon from '../../../../services/TapIcon';
import TapApiUrls from '../../../../services/TapApiUrls';
import Ax from "../../../../components/hoc/Ax";
import swal from 'sweetalert';
import AdditionalAttribute from '../../AdditionalAttribute';

class AddtionalAttribute extends AppBaseComponent {

    render() {

        return (
            <section>
                 <AdditionalAttribute category="asset_rental"/>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        iam_asset_types         :        state.app && state.app.asset_type ? state.app.asset_type : [],
    };
};


export default connect(mapStateToProps)(AddtionalAttribute);