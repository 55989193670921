import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon"
import TapSelect from '../../../../components/ui/TapSelect';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import swal from 'sweetalert';
import Status from '../../../../components/ui/Status';
import { DisplayListPagination } from '../../../../components/TapUi';
import GeneralSetting from './GeneralSetting';
import { Helmet } from 'react-helmet';
import InputFuelReceiverSearch from '../../../includes/ui/InputFuelReceiverSearch';

class FuelReceiverConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);
          
            this.initAddReceiverForm    =   {
                name                        :   '',
                status                      :   '',
                id                          :   ''
            }
            this.initMergeReceiverForm  =   {
                primary_receiver              :   '',
                merge_receiver                :   '',
            }
          this.state                    =   {
            list_loading                    :   false,
            listingData                     :   [],
            saveFormSubmitting              :   false,
            addReceiverForm                 :   {...this.initAddReceiverForm},
            editFormSubmiting               :   false,
            mergeReceiverForm               :   {...this.initMergeReceiverForm},
            mergedToReceiverData            :   null,
            listingMeta                     :   null,
            primaryReceiverData             :   null
        };
        this.fuelReceiverUrl            =   IAM_API_BASE_URL_2;
    }

    componentDidMount() {
       this.getFuelReceiverConfigurationListing(1);
       this.addReceiverModal            =   new Modal(document.getElementById('addReceiverModal'), {keyboard: false, backdrop :false});
       this.mergeReceiverModal            =   new Modal(document.getElementById('mergeReceiverModal'), {keyboard: false, backdrop :false});
    }

    getFuelReceiverConfigurationListing     =   (page = 1)  =>  {
        this.setState({list_loading : true})
        HttpAPICall.withAthorization('GET', `${this.fuelReceiverUrl}/fuel_receiver_list`, this.props.access_token , {page : page}, {}, (resp) => {
            let respData = resp.data
            this.setState({
                listingData  : respData.data && respData.data.length > 0 ? resp.data.data.map(dt => {return({...dt,value:dt.enc_id,label:dt.name})}) : [],
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            })
        }).then(() => this.setState({list_loading: false}));
    }

    addReceiverModalInit            =   (sg)    =>  {
        this.addReceiverModal.show();
        if(sg){
            this.setState({
                addReceiverForm     :   {...this.initAddReceiverForm,
                    id              :   sg.enc_id,
                    name            :   sg.name,
                    status          :   sg.status.status
                }
            })
        }else{
            this.setState({
                addReceiverForm     :   {...this.initAddReceiverForm}
            })
        }
    }

    mergeReceiverModalInit          =   (sg)    =>  {
        this.mergeReceiverModal.show();
        if(sg){
            this.setState({
                mergeReceiverForm   :   {
                    primary_receiver    :  sg.enc_id,
                    merge_receiver      :  ''
                },
                mergedToReceiverData    :   null,
                primaryReceiverData     :   sg
            })
        }
       
    }

    changeStatus                =    (id,status)  =>     {
        swal({
            title: "Change Status",
            text: "Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', `${this.fuelReceiverUrl}/fuel_receiver_status/${id}`, this.props.access_token, {},{status : status == 1 ? 2 : 1} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getFuelReceiverConfigurationListing();
                    }).then(() => {

                    });
                }
            });
    }

    submitAddReceiverForm           =   (e) =>  {
        e.preventDefault();
    
        if(this.state.addReceiverForm && this.state.addReceiverForm.id){
            this.setState({ editFormSubmiting: true });
            HttpAPICall.withAthorization('PUT', `${this.fuelReceiverUrl}/fuel_receiver_edit/${this.state.addReceiverForm.id}`, this.props.access_token, {}, { ...this.state.addReceiverForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addReceiverModal.hide();
                this.getFuelReceiverConfigurationListing();
            }).then(() => this.setState({ editFormSubmiting: false }));
        }else{
            this.setState({ editFormSubmiting: true });
            HttpAPICall.withAthorization('POST', `${this.fuelReceiverUrl}/fuel_receiver_add`, this.props.access_token, {}, { ...this.state.addReceiverForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addReceiverModal.hide();
                this.getFuelReceiverConfigurationListing();
            }).then(() => this.setState({ editFormSubmiting: false }));
        }
    }

    submitMergeReceiverForm       =    (e)          =>     {
        e.preventDefault();
        this.setState({ editFormSubmiting: true });
        HttpAPICall.withAthorization('PUT', `${this.fuelReceiverUrl}/fuel_receiver_merge`, this.props.access_token, {}, { ...this.state.mergeReceiverForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.mergeReceiverModal.hide();
            this.getFuelReceiverConfigurationListing();
        }).then(() => this.setState({ editFormSubmiting: false }));
        
    }

    addReceiverModalJsx         =   ()  =>  {
        return (
            <div className="modal fade" id="addReceiverModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.addReceiverForm && this.state.addReceiverForm.id ? "Edit" : "Add"} Fuel Receiver</h5>
                        </div>
                        <form onSubmit={this.submitAddReceiverForm}>
                            <div className="modal-body p-3">
                            
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-2"><label className="form-label require">Name</label></div>
                                    <div className="col-md-10">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addReceiverForm.name}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addReceiverForm")}
                                            placeholder="Please enter Fuel Receiver Name"
                                            required={true}
                                        />
                                    </div>

                                </div>
                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    mergeReceiverModalJsx           =   ()  =>  {
    return (
        <div className="modal fade" id="mergeReceiverModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addAssetModalLabel">Merge Fuel Receiver</h5>
                    </div>
                    <form onSubmit={this.submitMergeReceiverForm}>
                        <div className="modal-body p-3">

                            <div className="row align-items-center mb-2 mt-2 px-2">
                                <div className="col-md-3"><label className="form-label require">Receiver</label></div>
                                <div className="col-md-9">
                                    <TapSelect
                                        options={this.state.listingData}
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'mergeReceiverForm', 'primary_receiver', () => {
                                                this.setState({ primaryReceiverData: selectedOption ? selectedOption : null })
                                            });

                                        }}
                                        isSearchable={true}
                                        value={this.state.listingData.find(s => this.state.mergeReceiverForm.primary_receiver == s.value)}
                                        isClearable={true}
                                        placeholder="Select Receiver"
                                        autoCompelete="off"
                                        required={true}
                                        autoFocus={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        isDisabled={true}
                                    />
                                </div>

                            </div>
                            <div className="row align-items-center mb-2 mt-2 px-2">
                                <div className="col-md-3"><label className="form-label require">Merge Receiver</label></div>
                                <div className="col-md-9">
                                    <InputFuelReceiverSearch
                                        required={true}
                                        placeholder="Search Fuel Receiver"
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'mergeReceiverForm', 'merge_receiver', () => {
                                                this.setState({ mergedToReceiverData: selectedOption ? selectedOption : null })
                                            });
                                        }}
                                        value={this.state.mergedToReceiverData ? this.state.mergedToReceiverData : null}
                                        containerHeight="33px"
                                        fontSize="93%"
                                    />
                                </div>
                                {this.state.mergedToReceiverData && this.state.primaryReceiverData && <ol className='mt-3 form-text'>
                                    <li>After merging only <b>{this.state.primaryReceiverData.label}</b> will appear and <b>{this.state.mergedToReceiverData.label}</b> will be deleted. <br /></li>
                                    <li>All the transactions of <b>{this.state.mergedToReceiverData.label}</b> will be shifted to <b>{this.state.primaryReceiverData.label}</b>.</li>
                                </ol>}

                            </div>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

    render() {
      
        return (<section className="item_view_container bg-white">
            <Helmet><title>Assets Configuration : Fuel Receiver</title></Helmet>
            <ToastContainer />
            <ul className="nav nav-tabs" id="myTab">
                <li className="nav-item" role="downtimeTab">
                    <button className="nav-link active" id="setting-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_1" aria-controls="tab_1" role="tab" aria-selected="true">Workflow</button>
                </li>
                <li className="nav-item" role="downtimeTab">
                    <button className="nav-link" id="additional-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_2" aria-controls="tab_2" role="tab" aria-selected="false">Setting</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane show active" id="tab_1" aria-labelledby="setting-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Fuel Receiver</span>
                            <div className="text-end "><button className="btn btn-primary" onClick={() => this.addReceiverModalInit()}>Add</button></div>
                        </div>
                        <table className="table table-hover table-bordered my-4 table-sm ">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                                    <th scope="col" className="text-start">Name</th>
                                    <th scope="col" style={{ width: "14%" }}>Status</th>
                                    <th scope="col" style={{ width: "14%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.list_loading ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                    : (this.state.listingData && this.state.listingData.length > 0
                                        ? (this.state.listingData.map((sg, k) => {
                                            return (<tr key={k} className="text-center">
                                                <td>{this.state.listingMeta ? this.state.listingMeta.from + k : k}</td>
                                                <td className="text-start">{sg.name ? sg.name : "-"}</td>
                                                <td>{sg.status && <Status color={sg.status.status_color_code}>{sg.status.status_text}</Status>}</td>
                                                <td> <span className="dropdown">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.addReceiverModalInit(sg)}>Edit</a></li>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.changeStatus(sg.enc_id, sg.status.status)}>Change Status</a></li>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.mergeReceiverModalInit(sg)}>Merge Receiver</a></li>
                                                    </ul>
                                                </span></td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="5" className="text-center">No Records</td></tr>))}
                            </tbody>
                        </table>

                        <div className='mb-3'>
                            <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.getFuelReceiverConfigurationListing(e.selected + 1)} />
                        </div>
                    </div>

                </div>
                <div className="tab-pane" id="tab_2" aria-labelledby="additional-tab">
                    <div className="tab_content_header">
                        <GeneralSetting />
                    </div>
                </div>
            </div>
            {this.addReceiverModalJsx()}
            {this.mergeReceiverModalJsx()}
        </section>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
    };
};


export default connect(mapStateToProps)(FuelReceiverConfiguration);