import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import uuid from 'react-uuid';

class Document extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.addDocumentFormInit    =   {
            type                        :   '',
            number                      :   '',
            base64_document             :   '',
            document_name               :   '',
            file                        :   null
        };
        
        this.state                  =   {
            access_token                :   '',
            formDataLoading             :   '',
            documentTypes               :   [],
            counterlog                  :   null,
            assetData                   :   null,
            shift                       :   null,
            transaction_id              :   '',
            date                        :   '',
            other                       :   'N',
            addDocumentForm             :   {...this.addDocumentFormInit},
            addDocumentFormSubmitting   :   false
        }
        this.uploadDocumentModalId      =   uuid();

        this.documentTypeUrl            =   ORG_API_BASE_URL_2 + '/setting/doc_types';
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);
        this.uploadDocumentModal        =   new Modal(document.getElementById(this.uploadDocumentModalId), {keyboard: false, backdrop: false}); 
    }
    
    componentWillReceiveProps(nextProps) {
        this.initalizeComponent(nextProps);
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({access_token : pr.access_token});
    }
    
    loadDocTypesFromApi                 =   ()  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', this.documentTypeUrl, this.state.access_token , {}, {}, (response) => {
            this.setState({documentTypes : response.data.map(s => {return({value : s , label : s})})});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }
    
    uploadDocumentModalInit             =   (shiftDetail, counterlog, assetData = null)  =>  {
        this.uploadDocumentModal.show();
        if(this.state.documentTypes.length == 0) {
            this.loadDocTypesFromApi();
        }
        this.setState({
            assetData           :   assetData,
            shift               :   shiftDetail,
            counterlog          :   counterlog,
            date                :   counterlog ? counterlog.feed_date : '',
            transaction_id      :   counterlog ? counterlog.transaction_id : '',
            addDocumentForm     :   {...this.addDocumentFormInit}
        });
        if(document.getElementById("uploadShiftDocumentForm")) {
            document.getElementById("uploadShiftDocumentForm").reset();
        }
   }
   
    handleFileChange                    =   async (e) => {
        let file                            =   e.target.files[0];
        if(file) {
            let filename                    =   file.name;
            let base64_document             =   await this.convertBase64(file);
            this.setState({addDocumentForm : { ...this.state.addDocumentForm ,base64_document : base64_document,document_name:filename}})
        }
    }

    convertBase64                       =   (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    
    submitDocumentForm                  =   (e) =>  {
        e.preventDefault()
        this.setState({addDocumentFormSubmitting : true});
        HttpAPICall.withAthorization('POST', TapApiUrls.APP_URL + '/asset/shift_counter_log/document/' + this.state.transaction_id , this.props.access_token, null, {...this.state.addDocumentForm}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.uploadDocumentModal.hide();
            if(this.props.afterSaveDocument) {
                let asset_id            =   this.state.assetData ? this.state.assetData.asset_id : null;
                this.props.afterSaveDocument(this.state.date, asset_id);
            }
            if(this.state.other == 'Y') {
                this.setState({documentTypes : []});
            }
            this.setState({
                addDocumentForm         :   {...this.addDocumentFormInit},
            });
        }).then(() => this.setState({addDocumentFormSubmitting: false}))
    }
    
    deleteDocument                      =   (transaction_id, doc_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', TapApiUrls.APP_URL + '/asset/shift_counter_log/document/' + transaction_id + '/' + doc_id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteDocument) {
                        this.props.afterDeleteDocument()
                    }
                }).then(() => { });
            }
        });
    }
    
    uploadDocumentModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id={this.uploadDocumentModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>Upload Shift Document for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addDocumentFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                        {this.state.formDataLoading 
                         ?  <Loader />
                         :  <form className={['p-2',].join(' ')} onSubmit={this.submitDocumentForm} id="uploadShiftDocumentForm">
                                <div className="row align-items-center">
                                    <label className="col-sm-3 col-form-label require">Type</label>
                                    <div className="col-sm-7">
                                        {this.state.other == "Y"
                                        ? <input
                                            name="type"
                                            type="text"
                                            value={this.state.addDocumentForm.type}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                            placeholder="Please enter Type Name"
                                            required={true}
                                            // autoFocus={true}
                                        />
                                        : <TapSelect
                                            options={this.state.documentTypes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addDocumentForm', 'type')}
                                            value={this.state.documentTypes.find(u => this.state.addDocumentForm.type == u.value)}
                                            isClearable={true}
                                            placeholder="Select Document Type"
                                            autoCompelete="off"
                                            required={true}
                                            autoFocus={true}
                                        />}
                                    </div>
                                    <div className="col-sm-2">
                                        <input
                                            name="other"
                                            type="checkbox"
                                            value={this.state.other}
                                            onChange={(e) => { this.setState({ other: this.state.other == 'N' ? 'Y' : 'N' }) }}
                                            checked={this.state.other == 'Y'}
                                            className="form-check-input"
                                            id="other"
                                        />
                                        <label className="form-check-label mx-2" htmlFor="other">New </label>
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <label className="col-sm-3 col-form-label">Number</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="number"
                                            type="text"
                                            value={this.state.addDocumentForm.number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                            placeholder="Please enter Document Number"
                                        />
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <label className="col-sm-3 col-form-label require">Document</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="file"
                                            type="file"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => {this.handleFileChange(e)}}
                                            placeholder="Please upload document"
                                            value={this.state.addDocumentForm.file}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </form>
                        }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" disabled={this.state.addDocumentFormSubmitting} data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.addDocumentFormSubmitting} form="uploadShiftDocumentForm">Update {this.state.addDocumentFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return this.uploadDocumentModalJsx()
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(Document);

