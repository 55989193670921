import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import {connect} from "react-redux";
import Ax from "../../../components/hoc/Ax";
import AssetCard from "../../includes/assetCard/AssetCard";
import {Link} from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import {Modal} from "bootstrap";
import {toast} from "react-toastify";
import swal from "sweetalert";
import errorValidation from "../../../services/ErrorValidation";
import { Helmet } from 'react-helmet';
import TapSelect from '../../../components/ui/TapSelect';
import TapApiUrls from '../../../services/TapApiUrls';
import TapIcon from '../../../services/TapIcon';

class EmployeeSiteTransition extends AppBaseComponent {

    constructor(props) {
        super(props);
      
        this.state                  =        {
        }
    }

  
    render() {
   
        return (
            <Ax>
                <Helmet><title>Employee Site Transition</title></Helmet>
                <table className="table table-bordered bg-white align-items-center  table-sm table-hover my-3" id="settingsTable">
                    <thead className="table-secondary">
                        <tr className="text-center">
                            <th scope="col" style={{ width: "5%" }} >S.No</th>
                            <th scope="col" style={{ width: "25%" }}>From</th>
                            <th scope="col" style={{ width: "25%" }}>To</th>
                            <th scope="col" style={{ width: "25%" }}>Site </th>
                            <th scope="col" style={{ width: "15%" }} >No of Days</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-center">
                            <td>1</td>
                            <td>29-Mar-2022 05:30 AM</td>
                            <td>Till date</td>
                            <td>Default Site</td>
                            <td>379 Days</td>
                            <td>

                                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                <ul className="dropdown-menu">
                                    <li>  <a className='dropdown-item'>View</a>   </li>
                                </ul></td>
                        </tr>

                    </tbody>
                </table>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app && state.app.acl_info ? state.app.acl_info : null
    }
}

export default connect(mapStateToProps)(EmployeeSiteTransition);
