import * as actionTypes from './../actions/actionTypes';
import { updateObject } from './../utility';

const initialState = {
    access_token    :   null,
    token_type      :   null,
    mobile          :   null,
    error           :   null,
    loading         :   false
};

/*Submit Login*/
const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
}

/*Api Success Responce*/
const authSuccess = (state, action) => {
    return updateObject(state, {
        access_token: action.access_token,
        token_type: action.token_type,
        error: null,
        loading: false
    });
}

/*Api Resonce a erroe*/
const authFail = (state, action) => {
    return updateObject(state, {
        error           :   action.response,
        loading         :   false,
        access_token    :   null,
        token_type      :   null,
    });
}

/*On Logout User*/
const authLogoutReducer = (state, action) => {
    return updateObject(state, {
        access_token: null,
        token_type: null,
        error: null,
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START         :   return authStart(state, action);  break;
        case actionTypes.AUTH_SUCCESS       :   return authSuccess(state, action);  break;
        case actionTypes.AUTH_FAIL          :   return authFail(state, action);  break;
        case actionTypes.AUTH_LOGOUT        :   return authLogoutReducer(state, action);  break;
        default:
            return state;
    }
};

export default reducer;