import React from 'react';
import axios from 'axios';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import moment from 'moment';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputItemSearch from '../../includes/ui/InputItemSearch';


class ItemVoucherAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initVoucherForm            =       {
            transaction_date                :        '',
            warehouse_id                    :        null,
            site_id                         :        '',
            voucher_number                  :       '',
            voucher_date                    :       '',
            notes                           :       '',
            consumped_item_id               :       null,
            Produced_item_id               :       null,

        }

        this.state                        =       {
            saveFormSubmitting            :       false,
            itemConsumedRows              :       [{item : null}],
            itemProducedRows             :       [{item : null}],
            addVoucherForm                :       {...this.initConsumptionForm},
            searchedConsumedItem          :        null,
            searchedProducedItem         :        null,
            warehouseDetails              :        null,
            itemCurrentStock              :       []
        };

        this.itemCurrentStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';

    }

    componentDidMount() {
        this.viewItemModal              =     new Modal(document.getElementById('searchItemModal'), {keyboard: false, backdrop :false});
        this.addNewItemModal            =    new Modal(document.getElementById('addItemModal'), {keyboard: false, backdrop :false});
    }

      //********************GET WAREHOUSE DETAILS API ******************************* */
      getWarehouseDetails             =       (selectedOption)        =>          {
        if (selectedOption) {
            let id = selectedOption.value ? selectedOption.value : selectedOption
        
            HttpAPICall.withAthorization('GET', TapApiUrls.WAREHOUSE_URL + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    warehouseDetails        :       respData.data,
                     
                });
                
            })
        } else {
            this.setState({
                warehouseDetails: null,
            })
        }
        
    }

    onWarehouseChange           = (selectedOption)          =>          {
        this.setState({
                searchedSiteName                    :       selectedOption.site ? selectedOption.site.site_name : "-",
                searchedAsset                       :       null,
                searchedWorkstation                 :       null,
                addVoucherForm                  :       {
                                                           ...this.state.addVoucherForm, 
                    site_id                         :       selectedOption.site_id ? selectedOption.site_id : null, 
                    warehouse_id                    :       selectedOption ? selectedOption.value : null,
                    voucher_number                  :       '',
                    voucher_date                    :       '',
                    notes                           :       ''
                },
        });
    }

     //********************GET CONSUMED ITEM DETAILS ******************************* */

     onConsumedItemSelect                =           (selectedItem,k)          =>      {
        if(selectedItem && selectedItem.value) {
            HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + selectedItem.value, this.props.access_token,
            { interchangeable_items_current_stock: "N", warehouse_id: this.state.addVoucherForm.warehouse_id }, {}, (resp) => {
                if(resp.data.current_stock.length > 0){
                    if(resp.data.current_stock.length == 1){
                        let item = selectedItem.itemdata;
                        let allRows = this.state.itemConsumedRows;
                        allRows[k] = {
                            item               :           item,
                            id                 :           selectedItem.id ? selectedItem.id : null,
                            qty                :           resp.data.current_stock ? 1 : 0,
                            store_qty          :           resp.data.current_stock ? resp.data.current_stock[0].current_qty : 0,
                            unit_rate          :           item.consumption_price ? item.consumption_price : resp.data.current_stock[0].current_unit_rate,
                            batch_number       :           resp.data.current_stock ? resp.data.current_stock[0].batch_number : 0,
                            expiry_date        :           resp.data.current_stock ? resp.data.current_stock[0].expiry_date : 0
                        };
                        this.setState({ itemConsumedRows: allRows, });
                        this.addNewConsumedItemRow();  
                    }else{
                        let item = selectedItem.itemdata;
                        let allRows = this.state.itemConsumedRows;
                        allRows[k] = {
                            item               :           item,
                            id                 :           selectedItem.id ? selectedItem.id : null,
                            qty                :           resp.data.current_stock ? 1 : 0,
                            store_qty          :           resp.data.current_stock ? resp.data.current_stock[0].current_qty : 0,
                            unit_rate          :           item.consumption_price ? item.consumption_price : resp.data.current_stock[0].current_unit_rate,
                            batch_number       :           resp.data.current_stock ? resp.data.current_stock[0].batch_number : 0,
                            expiry_date        :           resp.data.current_stock ? resp.data.current_stock[0].expiry_date : 0,
                            allStock           :           resp.data.current_stock.map(cs => {
                                                                                                return {
                                                                                                    value: cs.current_qty,
                                                                                                    label: (<Ax>
                                                                                                        <b>Batch Number : </b> {cs.batch_number} <br />
                                                                                                        <b>Expiry Date : </b> {cs.expiry_date ? cs.expiry_date_display : ''}<br />
                                                                                                        <b>Current Qty : </b> {cs.current_qty}</Ax>),
                                                                                                    stock: cs,
                                                                                                    item_id: cs.item_id,
                                                                                                    display_label: `${cs.batch_number} & ${cs.expiry_date} `
                                                                                                }
                                                                                            })

                        };
                        this.setState({ itemConsumedRows: allRows, });
                        this.addNewConsumedItemRow();  
                    }
                }else{
                    // no stock available
                    let item = selectedItem.itemdata;
                    let allRows = this.state.itemConsumedRows;
                    allRows[k] = {
                        item               :           item,
                        id                 :           selectedItem.id ? selectedItem.id : null,
                        qty                :           0,
                        store_qty          :           "No Stock Available",
                        unit_rate               :           0,
                        batch_number                :     '',
                        expiry_date            :        '',
                    };
                 this.setState({ itemConsumedRows : allRows, });
                 this.addNewConsumedItemRow();
                    
                }
            })
           
        }
    }

    addNewConsumedItemRow                           =   ()  =>  {
        this.setState({itemConsumedRows : [...this.state.itemConsumedRows, {item : null}] });
    }

    onConsumedItemInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val ;
        if(name == 'select_batch'){
            if(selectedOption && selectedOption.value) {
                val                         =   selectedOption.value;
            }
        }else {
            val = e.target.type == "number" ? parseFloat(e.target.value) : e.target.value;;
        }
        let allRows                 =   this.state.itemConsumedRows;
        allRows[k]                  =   {...this.state.itemConsumedRows[k] , [name] : val};
        this.setState({itemConsumedRows : allRows});
    }

    removeConsumedItemRow                           =   (k) =>  {
        let allRows                 =   this.state.itemConsumedRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({itemConsumedRows : allRows});
        }
    }

    onBatchInputChange             =       (selectedOption,k)      =>      {
        if(selectedOption && selectedOption.value){
          let allRows = this.state.itemConsumedRows;
          let stock = selectedOption.stock

          allRows[k] = {
              ...allRows[k],
              qty                :           stock.current_qty ? 1 : 0,
              unit_rate          :           stock.current_unit_rate ? stock.current_unit_rate : 0,
              item_id            :           selectedOption.item_id,
              batch_number       :           stock.batch_number ? stock.batch_number : '',
              expiry_date        :           stock.expiry_date ? stock.expiry_date : '',
              store_qty          :           stock.current_qty ? stock.current_qty : 0,
              stock_id           :           null
          };
          this.setState({ itemConsumedRows : allRows });
          
        }
  }

    //********************GET Produced ITEM DETAILS ******************************* */

    onProducedItemSelect                =           (selectedItem,k)          =>      {
        if(selectedItem && selectedItem.value) {
            let item                    =   selectedItem.itemdata;
            let allRows                 =   this.state.itemProducedRows;
            allRows[k]                  =   {
                item                        :   item,
                id                          :   selectedItem.id  ? selectedItem.id  : null,
                qty                         :   selectedItem.qty ? selectedItem.qty : 0,
                unit_rate                   :   item.purchase_price ? item.purchase_price : selectedItem.unit_rate,
                remark                      :   selectedItem.remark ? selectedItem.remark:'', 
                batch_number                :     '',
                expiry_date                 :      '',
            };
            this.setState({itemProducedRows : allRows});
            this.addNewProducedItemRow();
        }
    }

    addNewProducedItemRow                           =   ()  =>  {
        this.setState({itemProducedRows : [...this.state.itemProducedRows, {item : null}] });
    }

    onProducedItemInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val ;
        if(name == 'select_batch'){
            if(selectedOption && selectedOption.value) {
                val                         =   selectedOption.value;
            }
        }else if(name == 'expiry_date'){
            val                         =    moment(selectedOption).format('YYYY-MM-DD')
        }else {
            val = e.target.type == "number" ? parseFloat(e.target.value) : e.target.value;;
        }
        let allRows                 =   this.state.itemProducedRows;
        allRows[k]                  =   {...this.state.itemProducedRows[k] , [name] : val};
        this.setState({itemProducedRows : allRows});
    }

    removeProducedItemRow                           =   (k) =>  {
        let allRows                 =   this.state.itemProducedRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({itemProducedRows : allRows});
        }
    }

    addItemVoucherFormSubmitEvent           =           (e)         =>      {
        e.preventDefault()
      
    }

    saveItemVoucherFormJsx                  =   ()  =>  {
        let sub_total_amount        =   0;
        let total_amount            =   0;
        let total_consumed_amount   =   0;
        let total_produced_amount   =   0;
        let difference_amount       =   0

        this.state.itemConsumedRows.forEach((item,key) => {
            if(item && item.item) {
                    let item_amount       =   parseFloat(item.qty * item.unit_rate);
                    total_consumed_amount     +=  item_amount;
                
            }
        });

        this.state.itemProducedRows.forEach((item,key) => {
            if(item && item.item) {
                    let item_amount       =   parseFloat(item.qty * item.unit_rate);
                    total_produced_amount     +=  item_amount;
                
            }
        });
        difference_amount =   total_produced_amount - total_consumed_amount;


        return (<form className="bg-white p-3" onSubmit={this.addItemVoucherFormSubmitEvent}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addVoucherForm.transaction_date
                                ? moment(this.state.addVoucherForm.transaction_date).toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => {
                            this.setState({
                                addVoucherForm: { ...this.state.addVoucherForm, transaction_date: moment(value).format('YYYY-MM-DD') }
                            });
                        }}
                        maxDate={moment().toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Date of Transaction`}

                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addVoucherForm', 'warehouse_id');
                            this.onWarehouseChange(selectedOption)
                            this.getWarehouseDetails(selectedOption)
                        }}
                        options={this.props.all_warehouses}
                        isSearchable={true}
                        required={true}
                        value={this.props.all_warehouses.find(m => m.value == this.state.addVoucherForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>
            </div>
            {this.state.warehouseDetails ?
                <div className="row align-items-center mt1 pb-2">
                    <div className="offset-3 col-sm-8">
                        <label className='form-label'>Address : {this.state.warehouseDetails.address1 ? this.state.warehouseDetails.address1 + ", " : ""}
                            {this.state.warehouseDetails.address2 ? this.state.warehouseDetails.address2 + ", " : ""}
                            {this.state.warehouseDetails.landmark ? this.state.warehouseDetails.landmark + ", " : ""}
                            {this.state.warehouseDetails.city ? this.state.warehouseDetails.city + ". " : "-"}</label><br />
                        <label className='form-label'>Source of Supply : {this.state.warehouseDetails && this.state.warehouseDetails.state ? <span>{this.state.warehouseDetails.state.name}</span> : "-"}</label> <br />
                        <label className='form-label'>GSTIN : {this.state.warehouseDetails && this.state.warehouseDetails.gstin ? <span>{this.state.warehouseDetails.gstin}</span> : "-"}</label> <br />
                        <label className='form-label'>Site Name : {this.state.warehouseDetails && this.state.warehouseDetails.site ? <span>{this.state.warehouseDetails.site.site_name}</span> : "-"}</label> <br />
                    </div>

                </div>
                : null}

            <div className="row align-items-center my-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label">Voucher Number & Date</label>
                </div>
                <div className="col-4">
                    <input
                        name="voucher_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addVoucherForm.voucher_number}
                        onChange={(e) => this.formInputHandler(e, 'addVoucherForm')}
                        placeholder="Enter Voucher Number"
                    />
                </div>
                <div className="col-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addVoucherForm.voucher_date
                                ? moment(this.state.addVoucherForm.voucher_date).toDate()
                                : false
                        }
                        name="voucher_date"
                        onChange={(value, event) => {
                            this.setState({
                                addVoucherForm: { ...this.state.addVoucherForm, voucher_date: moment(value).format('YYYY-MM-DD') }
                            });
                        }}
                        maxDate={moment().toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Enter Voucher Date`}
                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="consumption_date" className="add_calender-icon" />
                </div>
            </div>

            <div className="tab_content_wrapper content_heading">Item Consumed </div>
            <div className="my-3 row">
                <div className="col-sm-12">
                    <table className="table table-bordered table-responsive bg-white ">
                        <thead className="table-secondary">
                            <tr>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" className="text-center" style={{ width: "33%" }}>Item Details</th>
                                {this.props && this.props.isc_configuration?.track_batch_expiry == "Y" ?
                                    <th scope="col" className="text-center" style={{ width: "21%" }}>Batch Number <br /> Expiry Date</th>
                                    : null}
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Quantity</th>
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Unit Rate</th>
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Amount</th>
                                <th scope="col" className="" style={{ width: "5%" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.itemConsumedRows.map((i, k) => {
                                let tdRows = (i.item) ? (<Ax>
                                    <td className="text-start">
                                        <div className="">
                                            <div className=" d-flex justify-content-between">
                                                <div><span className="fw-bold">{i.item.name}</span></div>
                                                <div>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item" onClick={this.itemViewModalInit}>View Details</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-text"><b>Item Code :</b>{i.item.item_code}</div>
                                            <div className="form-text"><b>Manufacturer :</b> {i.item?.manufacturer?.name ?? '-'}</div>
                                            <div className="form-text"><b>manufacturer Part #:</b> {i.item?.manufacturer_part_no ?? '-'}</div>
                                            {i.item?.tags?.length > 0
                                                ? (i.item?.tags.map((t, k) => <span key={k} className="badge bg-secondary">{t.name}</span>))
                                                : null
                                            }
                                        </div>
                                        <input
                                            name="remark"
                                            type="text"
                                            value={this.state.itemConsumedRows[k].remark}
                                            onChange={(e) => this.onConsumedItemInputChange(e, 'remark', k)}
                                            className="form-control mt10"
                                            autoComplete="off"
                                            placeholder="Item Remarks"
                                        />
                                    </td>
                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? <td>
                                            {this.state.itemConsumedRows[k].allStock ?
                                                <TapSelect
                                                changeEvent={(selectedOption, e) => { this.onBatchInputChange(selectedOption, k); }}
                                                options={this.state.itemConsumedRows[k].allStock}
                                                isSearchable={false}

                                                value={this.state.itemConsumedRows[k].allStock.find(s => s.value == this.state?.itemConsumedRows[k]?.select_batch)}
                                                placeholder="Change"
                                            />
                                            : null}
                
                                        <div className='py-1'>
                                            <b >Batch Number : </b> {this.state.itemConsumedRows[k].batch_number??"-"} <br />
                                            <b>Expiry Date  : </b> {this.state.itemConsumedRows[k].expiry_date??"-"} <br />
                                        </div>
                                    </td> : null}
                                    <td className="req_table">
                                        <input
                                            name="qty"
                                            type="number"
                                            value={this.state.itemConsumedRows[k].qty}
                                            onChange={(e) => this.onConsumedItemInputChange(e, 'qty', k)}
                                            className="form-control text-end"
                                            autoComplete="off"
                                            placeholder="Qty"
                                            required={true}
                                            min={1}
                                            step=".01"
                                        />
                                        <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span>
                                        <small className={"my-1"}>
                                            <b >Stock In Hand : </b> {this.state.itemConsumedRows[k]?.store_qty ?? "-"} <br />
                                        </small>
                                    </td>
                                    <td>
                                        <input
                                            name="unit_rate"
                                            type="number"
                                            value={this.state.itemConsumedRows[k].unit_rate}
                                            onChange={(e) => this.onConsumedItemInputChange(e, 'unit_rate', k)}
                                            className="form-control text-end"
                                            autoComplete="off"
                                            placeholder="Unit Rate"
                                            required={true}
                                            min={1}
                                            step=".01"
                                        />
                                    </td>
                                    <td className="text-end">
                                        {(this.state.itemConsumedRows[k].qty * this.state.itemConsumedRows[k].unit_rate).toFixed(2)}
                                    </td>
                                </Ax>)
                                    : (<Ax>
                                        <td>
                                        <InputItemSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addVoucherForm', 'consumed_item_id');
                                                    this.onConsumedItemSelect(selectedOption, k);
                                                }}
                                                value={this.state.searchedConsumedItem ? { ...this.state.searchedConsumedItem, label: this.state.searchedConsumedItem.display_label } : null}
                                            />
                                        </td>
                                        <td  colSpan={this.props.isc_configuration?.track_batch_expiry == "Y" ? "4" : "3"}  className="text-center align-middle" >-</td>
                                    </Ax>);
                                return (<tr className="" key={k}>
                                    <td className="text-center align-middle">{k + 1}</td>
                                    {tdRows}
                                    <td className="text-center align-middle">
                                        <a href="#" onClick={() => this.removeConsumedItemRow(k)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-12 text-end">
                    <a role="button" onClick={this.addNewConsumedItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                </div>
            </div>
            <div className="tab_content_wrapper content_heading">Item Produced </div>
            <div className="my-3 row">
                <div className="col-sm-12">
                    <table className="table table-bordered table-responsive bg-white ">
                        <thead className="table-secondary">
                            <tr>
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" className="text-center" style={{ width: "33%" }}>Item Details</th>
                                {this.props && this.props.isc_configuration?.track_batch_expiry == "Y" ?
                                    <th scope="col" className="text-center" style={{ width: "21%" }}>Batch Number <br /> Expiry Date</th>
                                    : null}
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Quantity</th>
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Unit Rate</th>
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Amount</th>
                                <th scope="col" className="" style={{ width: "5%" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.itemProducedRows.map((i, k) => {
                                let tdRows = (i.item) ? (<Ax>
                                    <td className="text-start">
                                        <div className="">
                                            <div className=" d-flex justify-content-between">
                                                <div><span className="fw-bold">{i.item.name}</span></div>
                                                <div>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item" onClick={this.itemViewModalInit}>View Details</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-text"><b>Item Code :</b>{i.item.item_code}</div>
                                            <div className="form-text"><b>Manufacturer :</b> {i.item?.manufacturer?.name ?? '-'}</div>
                                            <div className="form-text"><b>manufacturer Part #:</b> {i.item?.manufacturer_part_no ?? '-'}</div>
                                            {i.item?.tags?.length > 0
                                                ? (i.item?.tags.map((t, k) => <span key={k} className="badge bg-secondary">{t.name}</span>))
                                                : null
                                            }
                                        </div>
                                        <input
                                            name="remark"
                                            type="text"
                                            value={this.state.itemProducedRows[k].remark}
                                            onChange={(e) => this.onProducedItemInputChange(e, 'remark', k)}
                                            className="form-control mt10"
                                            autoComplete="off"
                                            placeholder="Item Remarks"
                                        />
                                    </td>
                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? <td>
                                    <input
                                            name="batch_number"
                                            type="text"
                                            value={this.state.itemProducedRows[k].batch_number}
                                            onChange={(e) => this.onProducedItemInputChange(e, 'batch_number', k)}
                                            className="form-control "
                                            autoComplete="off"
                                            placeholder="Batch Number"
                                        /><br />
                                        <DatePicker
                                            selected={
                                                this.state.itemProducedRows[k].expiry_date
                                                    ? moment(this.state.itemProducedRows[k].expiry_date).toDate()
                                                    : false
                                            }
                                            name="expiry_date"
                                            onChange={(selectedOption, e) => this.onProducedItemInputChange(e, 'expiry_date', k, selectedOption)}
                                            dateFormat="dd-MMM-yyyy"
                                            className={"form-control"}
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete="off"
                                            scrollMonthYearDropdown
                                            placeholderText={`Please Enter Date`}

                                        />
                                    </td> : null}
                                    <td className="req_table">
                                        <input
                                            name="qty"
                                            type="number"
                                            value={this.state.itemProducedRows[k].qty}
                                            onChange={(e) => this.onProducedItemInputChange(e, 'qty', k)}
                                            className="form-control text-end"
                                            autoComplete="off"
                                            placeholder="Qty"
                                            required={true}
                                            min={1}
                                            step=".01"
                                        />
                                        <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span>
                                    </td>
                                    <td>
                                        <input
                                            name="unit_rate"
                                            type="number"
                                            value={this.state.itemProducedRows[k].unit_rate}
                                            onChange={(e) => this.onProducedItemInputChange(e, 'unit_rate', k)}
                                            className="form-control text-end"
                                            autoComplete="off"
                                            placeholder="Unit Rate"
                                            required={true}
                                            min={1}
                                            step=".01"
                                        />
                                    </td>
                                    <td className="text-end">
                                        {(this.state.itemProducedRows[k].qty * this.state.itemProducedRows[k].unit_rate).toFixed(2)}
                                    </td>
                                </Ax>)
                                    : (<Ax>
                                        <td>
                                        <InputItemSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addVoucherForm', 'Produced_item_id');
                                                    this.onProducedItemSelect(selectedOption, k);
                                                }}
                                                value={this.state.searchedProducedItem ? { ...this.state.searchedProducedItem, label: this.state.searchedProducedItem.display_label } : null}
                                            />
                                        </td>
                                        <td colSpan={this.props.isc_configuration?.track_batch_expiry == "Y" ? "4" : "3"}  className="text-center align-middle" >-</td>
                                    </Ax>);
                                return (<tr className="align-middle" key={k}>
                                    <td className="text-center align-middle">{k + 1}</td>
                                    {tdRows}
                                    <td className="text-center align-middle">
                                        <a href="#" onClick={() => this.removeProducedItemRow(k)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-12 text-end">
                    <a role="button" onClick={this.addNewProducedItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                </div>
            </div>
            <div className="row align-items-center mb-5">
                <div className="col-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addVoucherForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addVoucherForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                    <table className="table  table-borderless">
                        <tbody>
                        <tr className="subtotal_bg_color">
                                <th>Amount Item -  Consumed</th>
                                <td className='float-end'>{total_consumed_amount.toFixed(2)}</td>
                            </tr>
                            <tr className="subtotal_bg_color">
                                <th>Amount Item -  Produced</th>
                                <td className='float-end'>{total_produced_amount.toFixed(2)}</td>
                            </tr>
                            <tr className="subtotal_bg_color">
                                <th>Difference</th>
                                <td className={total_produced_amount > total_consumed_amount ? "float-end text-success" : "float-end text-danger"}>{difference_amount.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.props.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary mx-2">
                    Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    itemViewModalInit                       =   ()  =>  {
        //Initialize Search Form :-
        this.viewItemModal.show();
    }

    itemDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="searchItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Item Details</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        {/* <div className="modal-body">
                            <div className="tab_content_header">
                                <div className="tab_content_wrapper"><span className="content_heading">Basic Details</span></div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                    <tr>
                                        <td className="details-label" style={{"width": "34%"}}>Item Category </td>
                                        <td className="details-name" style={{"width": "66%"}}>{this.state?.itemConsumedRows[0]?.item?.category_display}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Name</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Code</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.item_code}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Description</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.description !== null ?
                                            this.state?.itemConsumedRows[0]?.item?.description  : "-"
                                        }</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Unit of Measurement</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.measuring_unit.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Manufacturer</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.manufacturer?.name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Manufacturer Part Number (MPN)</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.manufacturer_part_no ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Item Tags</td>
                                        <td className="details-name" >
                                            {
                                                this.state?.itemConsumedRows[0]?.item !== null && this.state?.itemConsumedRows[0]?.item.tags?.length > 0
                                                    ? (this.state?.itemConsumedRows[0]?.item.tags.map((item,k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                    : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >HSN / SAC Code</td>
                                        <td className="details-name" >{this.state?.itemConsumedRows[0]?.item?.hsn_code ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Generate Scrap on Replacement</td>
                                        <td className="details-name" >{this.state?.itemConsumedRows[0]?.is_scrapble == 'N' ? 'NO' : 'Yes' ?? '-'}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper"><span className="content_heading">Pricing</span></div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                    <tr>
                                        <td className="details-label" style={{"width": "34%"}}>itemVoucher Price</td>
                                        <td className="details-name" style={{"width": "66%"}}>{this.state?.itemConsumedRows[0]?.item?.itemVoucher_price ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Sale / Consumption Price</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.consumption_price ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Scrap Price</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.scrap_price ?? ''}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper"><span className="content_heading">Taxation</span></div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                    <tr>
                                        <td className="details-label" style={{"width": "34%"}}>Tax Status </td>
                                        <td className="details-name" style={{"width": "66%"}}>{this.state?.itemConsumedRows[0]?.item?.is_taxable == 'Y' ? 'Taxable' : 'Non - Taxable'} </td></tr>
                                    <tr>
                                        <td className="details-label">IGST Percentage</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.igst ?? 0}%</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">CGST Percentage</td>
                                        <td className="details-name">{this.state?.itemConsumedRows[0]?.item?.cgst ?? 0}%</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">SGST Percentage</td>
                                    </tr>
                                    </tbody>
                                </table>
                                {
                                    this.state.item_additional_attributes && this.state.item_additional_attributes.length > 0
                                        ? (
                                            <Ax>
                                                <div className="tab_content_wrapper">
                                                    <div>
                                                        <span className="content_heading">Additinal Attribute</span>
                                                    </div>
                                                </div>
                                                <table className="table table-hover table-borderless">
                                                    <tbody>
                                                    {this.state.item_additional_attributes.map((a, k) => (<tr key={k}>
                                                        <td className="details-label" style={{"width": "34%"}}>{a.name}</td>
                                                        <td className="details-name" style={{"width": "66%"}}>
                                                            {this.state.itemConsumedRows[0]?.item?.additional_attribute?.[a.key] ?? '-'}
                                                        </td>
                                                    </tr>))}
                                                    </tbody>
                                                </table>
                                            </Ax>
                                        )
                                        : null
                                }
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }

    addNewItemModalInit                       =   ()  =>  {
        //Initialize Search Form :-
        this.addNewItemModal.show();
    }



    addNewConsumedItemRow                           =   ()  =>  {
        this.setState({itemConsumedRows : [...this.state.itemConsumedRows, {item : null}] });
    }


    onItemChange                            =   (selectedItem, k)  => {
        if(selectedItem.value && selectedItem.value > 0) {
            let item                    =   selectedItem.itemdata;
            let allRows                 =   this.state.itemConsumedRows;
            allRows[k]                  =   {
                item                        :   item,
                qty                         :   selectedItem.qty ? selectedItem.qty : 0,
                unit_rate                   :   selectedItem.unit_rate ? selectedItem.unit_rate : item.itemVoucher_price,
                remark                      :   selectedItem.remark ? selectedItem.remark:'',
                gst                         :   selectedItem ? selectedItem.gst : item.gst,
                qty_sts_id                  :   selectedItem ? selectedItem.qty_sts_id : ''
            };
            this.setState({itemConsumedRows : allRows});
            this.addNewConsumedItemRow();
        }
    }

    onItemInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val                             =   '';
        if(name == 'gst') {
            if(selectedOption && selectedOption.value) {
                val                         =   selectedOption.value;
            }
        } else {
            val = e.target.value;
        }
        let allRows                 =   this.state.itemConsumedRows;
        allRows[k]                  =   {...this.state.itemConsumedRows[k] , [name] : val};
        this.setState({itemConsumedRows : allRows});
    }

    removeConsumedItemRow                           =   (k) =>  {
        let allRows                 =   this.state.itemConsumedRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({itemConsumedRows : allRows});
        }
    }

    render                                  =   ()  =>  {
        return (<Ax>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>Add Inventory Voucher</h3>
                    <div className="text-end mt15">
                        <button onClick={this.props.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.saveItemVoucherFormJsx()}
                    </div>
                </div>
            </div>
            {this.itemDetailsModalJsx()}
            
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : []
    };
};

export default connect(mapStateToProps)(ItemVoucherAdd);