import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import React from "react";
import TapApiUrls from "../../../services/TapApiUrls";
import * as actions from "../../../store/actions/index"
import moment from "moment";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";
import CurrencyFormatter from "../../../services/CurrencyFormatter";

class WalletSummary extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                      =   {
            loading                     :      false,
            data                        :       null,
            remark_day                  :       0,
            remark_time                 :       '',
            all_periods                 :       [],
            filterForm                  :       {
                period                  :       'current_month'
            }
        }
        this.updateMyDashboardRef      =   React.createRef()
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props); 
        if (localStorage.getItem('walletWidget')) {
            let walletData = JSON.parse(localStorage.getItem('walletWidget'));
            if (walletData) {
                let lastRefreshTime = moment(walletData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('walletWidget')
                    setTimeout(() => {
                        this.getWalletListing()
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 14));
                    
                } else {
                    this.setState({
                        data            :      walletData.data,
                        remark_time     :      walletData.time,  
                })

                }
            }else {
                setTimeout(() => {
                    this.getWalletListing()
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 14));
            }  
        } else {
            setTimeout(() => {
                this.getWalletListing()
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 14));
        }       
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeFormFilter(nextProps);
        }
    }
    
    initilaizeFormFilter                =   (props)  =>  {
        this.setState({
            all_periods             :   props.all_periods ? props.all_periods.map((s) => { return {value: s.key, label: `${s.display}`}}) : [],
        },() => {
           
            
        });
    }

    getWalletListing                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {...this.state.filterForm, reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET',TapApiUrls.IAM + '/group/wallet_summary_widget_data', this.props.access_token, params, {} , (response) => {
           this.setState({data : response.data.data,remark_time : response.data.time})
            if (this.state.filterForm.period == "current_month") {
                localStorage.setItem('walletWidget',JSON.stringify(response.data));
            }
            if (reload !== false) {
                localStorage.setItem('walletWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"wallet_summary",name:"Wallet Summary"})
    }
    
    render                      =   ()  =>  {
        let wobj = {'date_range' : this.state.filterForm.period}
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("wallet_summary" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "wallet_summary")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Wallet Summary</h6>
                    </div>
                    <div className="col-3">

                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.all_periods}
                            value={this.state.all_periods.find(r => r.value === this.state.filterForm.period)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'period', () => this.getWalletListing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.getWalletListing(true)}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">

                    <div className="col-12">
                        <table className="table table-hover table-bordered table-borderless bg-white my-2">
                            <thead className="table-secondary">
                                <tr className="text-end">
                                    <th style={{ width: "25%" }} className="text-start" >Wallet Name</th>
                                    <th style={{ width: "18%" }}>Opening Balance</th>
                                    <th style={{ width: "15%" }}>Total Credit</th>
                                    <th style={{ width: "15%" }}>Total Debit</th>
                                    <th style={{ width: "15%" }}>Closing Balance</th>
                                    <th style={{ width: "12%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? (<tr><td colSpan="6"><Loader /></td></tr>)
                                    : (this.state.data && this.state.data.length > 0
                                        ? (this.state.data.map((w, index) => {
                                            let obj = {'wallet_id' : w.wallet.id ,'date_range' : this.state.filterForm.period}
                                            return (<tr key={index} className="text-end">
                                                <th className="text-capitalize text-start">{w.wallet && w.wallet.wallet_name ? w.wallet.wallet_name : "-"}</th>
                                                <td>{w.balance && w.balance.opening_balance ?  <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseInt(w.balance.opening_balance)}/> : "0"}</td>
                                                <td>{w.balance && w.balance.total_cr ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseInt(w.balance.total_cr)}/>  : "0"}</td>
                                                <td>{w.balance && w.balance.total_dr ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseInt(w.balance.total_dr)}/>  : "0"}</td>
                                                <td>{w.balance && w.balance.closing_balance ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseInt(w.balance.closing_balance)}/> : "0"}</td>
                                                <td className="link-primary text-center">
                                                <Link to={{ pathname: "/report/wallet_ledger", search: "?" + new URLSearchParams(obj).toString() }}>View Ledger</Link></td>
                                            </tr>)
                                        }))
                                        : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
 
                    <div className="col-sm-12 text-muted fs11">
                        Report as of {this.state.remark_time}
                        <div className="float-end link-primary">
                        <Link to={{ pathname: "/report/wallet_summary", search: "?" + new URLSearchParams(wobj).toString() }}>View Detailed Report</Link>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(WalletSummary);