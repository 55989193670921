import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import OverTimeRuleAdd from "./OverTimeRuleAdd";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import Status from "../../../components/ui/Status";
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi";
import swal from "sweetalert";
import axios from "axios";
import errorValidation from "../../../services/ErrorValidation";
import {toast} from "react-toastify";
import {Modal} from "bootstrap";
import { Helmet } from 'react-helmet';


class OverTimeRuleList extends AppBaseComponent {

    constructor() {
        super();
        this.otRuleSearchFormInitialState         =   {
            'search_name'               :   {'label' : 'Overtime Name  : ', 'value' : ''},
            'search_reference_number'   :   {'label' : 'Overtime Reference Number : ', 'value' : ''},

        };

        this.state                      =  {
            addOTScreen              :       false,
            updateOTScreen           :       false,
            otFormDataLoaded         :      false,
            rosterList               :      [],
            otRuleSearchForm         :     {...this.otRuleSearchFormInitialState},
            isoDays                  :      [],
            otRuleList               :      [],
            otRuleData               :      [],
            itemSearchedElems               :   [],
            listing_tbl_page         :       1,
            listing_loading          :       false,
            listingData              :       [],
            listingMeta              :       null,
        };
    }

    openAddOtScreen       =         ()      =>  {
        this.setState({addOTScreen : true});
        
        //Get Data For Add OverTime 
        if(!this.state.otFormDataLoaded) {
            HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/overtime/rule_form_data', this.props.access_token, null, null, (response) => {
                this.setState({
                    otFormDataLoaded    :   true,
                    rosterList          :   response.data.rosters,
                    isoDays             :   response.data.days
                });
            });
        }
    }

    loadListingTblData                  =   (page = 1)  =>  {
        let search                      =   [];
        Object.keys(this.state.otRuleSearchForm).map((k) => {
            search[k]                   =   this.state.otRuleSearchForm[k].value;
        });
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page: page, per_page: 50,...search};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/overtime/rule', this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                     :   respData.data,
                listingMeta                     :   {
                    from : respData.meta.from,
                    to : respData.meta.to,
                    total : respData.meta.total,
                    last_page : respData.meta.last_page,
                    current_page : respData.meta.current_page
                },

            });
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    componentDidMount() {
        this.loadListingTblData(1);
        //Asset Search Modal :-
        this.searchoTRuleModal           =   new Modal(document.getElementById('searchoTRuleModal'), {keyboard: false, backdrop :false});

    }

    closeAddView         =         ()      =>  {
        this.setState({addOTScreen : false})
        this.setState({updateOTScreen : false})
    }

    //function to convert sec to hr,mm
    toHHMM(sec) {
        var sec_num = parseInt(sec, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes
    }

    //function to delete document
    deleteOTRule(id){
        swal({
            title: "Delete Overtime Rule",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    this.deletedOTRuleSuccess(id)
                }
            });

    }

    deletedOTRuleSuccess(id){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_IAM_API_SERVER + '/overtime/rule/' + id
        }).then((response) => {
            swal({title: "Deleted!",
                text: "Overtime Rule Deleted Successfully",
                icon: "success",});
            this.loadListingTblData(this.state.listing_tbl_page)
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Delete Overtime Rule.');
        })
    }

    updateOTRuleScreenInit                =   (event, item_id)  =>  {
        //Get OT Details :-
        let otRuleData                    =   this.state.listingData.find((i) => i.id == item_id);
        if(otRuleData) {
            this.setState({updateOTScreen : true, otRuleData : otRuleData});
            //Get Data For Add OverTime
            if(!this.state.otFormDataLoaded) {
                HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/overtime/rule_form_data', this.props.access_token, null, null, (response) => {
                    this.setState({
                        otFormDataLoaded    :   true,
                        rosterList          :   response.data.rosters,
                        isoDays             :   response.data.days
                    });
                });
            }
        } else {
            toast.error('Unable to Update Overtime Rule.', {position: toast.POSITION.TOP_RIGHT});
        }
    }

    afterAddNewOtRuleSubmit               =   (event) =>  {
        event.preventDefault();
        //Item is Added Successfully Need to refresh and close Add form
        this.setState({addOTScreen : false, updateOTScreen : false});
        //Load Add Item Form Data :-
        this.loadListingTblData(1);
    }

    otRuleSearchModalInit                =   ()  =>  this.searchoTRuleModal.show({focus : true})

    searchFormInputHandler              =   (e)  =>  {
        if(e.target && e.target.name) {
            this.setState({otRuleSearchForm : {...this.state.otRuleSearchForm, [e.target.name] : { ...this.state.otRuleSearchForm[e.target.name], value: e.target.value} }});
        }
    }

    otRuleSearchFormSubmit                =   (event)  =>  {
        event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.otRuleSearchForm);
        let searchedElems               =   [];
        serachFormKeys.map((key) => {
            let elem                    =   this.state.otRuleSearchForm[key];
            if(elem.value && elem.value.length > 0) {
                searchedElems.push(elem.label + elem.value);
            }
        });

        //Generate Html :-
        this.setState({itemSearchedElems : searchedElems});

        this.loadListingTblData(1);
        this.searchoTRuleModal.hide();
    }

    otRuleSearchClear                     =   ()  =>  {
        this.setState({
            otRuleSearchForm      :   this.otRuleSearchFormInitialState,
            itemSearchedElems   :   []
        }, function() {
            this.loadListingTblData(1)
        });
    }

    oTRuleSearchModalJsx                 =   ()  =>  {
        return (
            <div className="modal fade" id="searchoTRuleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Overtime Rule Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="assetSearchForm" onSubmit={this.otRuleSearchFormSubmit}>
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">OT Rule Name</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_name"
                                            type="text"
                                            value={this.state.otRuleSearchForm.search_name.value}
                                            onChange={this.searchFormInputHandler}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter OT Rule Name"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Reference Number</label>
                                    <div className="col-sm-3">
                                        <input
                                            name="search_reference_number"
                                            type="text"
                                            value={this.state.otRuleSearchForm.search_reference_number.value}
                                            onChange={this.searchFormInputHandler}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Reference Number"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    otRuleListjsx                        =   ()  =>  {
        return (<Ax><div className="page_title row m0">
            <div className= "col-12">
                <h3>Overtime Rule List</h3>
                <div className="text-end mt15">
                    <button type="button" onClick={this.otRuleSearchModalInit} className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action"/>
                    </button>
                    <button disabled={!this.props.permissions.includes('iam-asset-overtime-rule-add')} title={!this.props.permissions.includes('iam-asset-overtime-rule-add')? 'You do not have permission to perform this action' : ''} style={{"pointerEvents" : "all"}} type="button" className="btn btn-secondary" onClick={this.openAddOtScreen}>Create Overtime Rule</button>
                </div>
            </div>
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl col-12'].join(' ')} >{this.otRuleTableJsx()}</div>
                </div>
            </div>
        </Ax>);
    }

    otRuleTableJsx                    =   ()  =>  {
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.itemSearchedElems} onClearFilter={this.otRuleSearchClear} />
                <div className="table-responsive">
                    <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                        <thead className="table-secondary">
                         <tr>
                                <th scope="col" style={{width:"5%"}} className="text-center">S.No</th>
                                <th scope="col" style={{width:"20%"}} >OT Rule Name</th>
                                <th scope="col" style={{width:"14%"}}>Reference Number</th>
                                <th scope="col" style={{width:"13%"}}>Roster Name</th>
                                <th scope="col" style={{width:"15%"}}>OT Based on Hours</th>
                                <th scope="col" style={{width:"20%"}}>OT Based on Days</th>
                                <th scope="col" style={{width:"20%"}}>OT for Specific Dates</th>
                                <th scope="col" style={{width:"5%"}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.listing_loading
                            ? <tr><td className="text-center" colSpan="9"><Loader /></td></tr>
                            : (this.state.listingData.length > 0
                                ? this.state.listingData.map((item, index) => this.listingTableTrJsx(item, index))
                                : this.listingTableTrJsx(null) )
                        }
                        </tbody>
                    </table>
                </div>
                <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
            </Ax>
        )

    }

    listingTableTrJsx                   =   (item, index=0)  => {
        return (
            item
                ? (<tr key={index}>
                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                        <td className="text-capitalize ">{item.name}</td>
                        <td>{item.reference_number}</td>
                        <td>{item.shift_rules !== null ? item.shift_rules.roster.roster_name : "-"} </td>
                        <td >{item.shift_rules !== null ?
                            item.shift_rules.shifts.map((value,index,arr) =>
                            <div>{this.toHHMM(value.working_second)}{index !== (arr.length-1) ? ', ' : ''} <br/></div>) : "-"}</td>
                        <td>{item.week_days_display.length > 0 ? item.week_days_display.map((value,index,arr) =>
                            <div>{value}{index !== (arr.length-1) ? ', ' : ''} <br/></div>) : "-"}</td>
                        <td>{item.specific_dates_display.length > 0 ? item.specific_dates_display.map((value,index,arr) =>
                            <div>{value}{index !== (arr.length-1) ? ', ' : ''} <br/></div>) : "-"}</td>
                       {/* <td><Status color={item.status_color}>{item.status_text}</Status></td>*/}
                        <td className="p0 text-center">
                    <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        { this.props.permissions.includes('iam-asset-overtime-rule-edit')
                            ? <li><a className="dropdown-item" role="button" onClick={(e) => this.updateOTRuleScreenInit(e,item?.id)}>Edit</a></li>
                            : <li><a className="dropdown-item text-muted" title="You do not have permission to perform this action">Edit</a></li>
                        }
                        { this.props.permissions.includes('iam-asset-overtime-rule-delete')
                            ? <li><a className="dropdown-item" role="button" onClick={(e) => this.deleteOTRule(e,item?.reference_number)}>Delete</a></li>
                            : <li><a className="dropdown-item text-muted" title="You do not have permission to perform this action">Delete</a></li>
                        }
                        </ul>
                    </span>
                        </td>

                    </tr>
                ) : (<tr key={index}>
                    <td className="text-center" colSpan={9}>No Record Found</td>
                </tr>)
        )
    }

    getPageScreen                       =   ()  =>  {
        let pageScreen = null;
        if(this.state.addOTScreen) {
            pageScreen  =   <OverTimeRuleAdd rosterList={this.state.rosterList} afterAddNewOtRuleSubmit = {this.afterAddNewOtRuleSubmit} isoDays={this.state.isoDays} otFormDataLoaded={this.state.otFormDataLoaded} closeEvent={this.closeAddView.bind(this)}/>
        }else if(this.state.updateOTScreen){
            pageScreen  =   <OverTimeRuleAdd rosterList={this.state.rosterList} afterAddNewOtRuleSubmit = {this.afterAddNewOtRuleSubmit} isoDays={this.state.isoDays} otFormDataLoaded={this.state.otFormDataLoaded} closeEvent={this.closeAddView.bind(this)} otRuleData={this.state.otRuleData}/>
        }else{
            pageScreen  = this.otRuleListjsx();
        }
        return pageScreen;
    }

    render = () => {
        return (<ApplicationLayout>
              <Helmet><title>OverTime Rule List</title></Helmet>
            {this.getPageScreen()}
            {this.oTRuleSearchModalJsx()}
        </ApplicationLayout>);
    }
}
    
const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :   state.app.acl_info.permissions
    };
};

export default connect(mapStateToProps)(OverTimeRuleList);