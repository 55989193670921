import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import { ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import Ax from "../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import TapSelect from "../../../components/ui/TapSelect";

class CurrencyConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigForm      =   {
            currency                :   ''
        };

        this.state                              =   {
            generalConfigloading                :      false,
            general_config_list                 :      null,
            generalConfigForm                   :     {...this.initGeneralConfigForm},
            editFormSubmiting                   :      false,
            currency_list_loading               :      false,
            currencyList                        :       []
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';
        this.currencyListUrl     =   ORG_API_BASE_URL_2  + '/currency_list';

    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
   }

    getGeneralSettingListing = () => {
        let params = {
            keys : [ 'currency','multiple_currency','group_currencies'],
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ general_config_list: resp.data.data })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    editGeneralConfigurationInit = () => {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
              }
              
            this.setState({generalConfigForm})
        }
        this.getCurrencyList()
    }

    getCurrencyList = () => {
        this.setState({ currency_list_loading: true });
        HttpAPICall.withAthorization('GET', this.currencyListUrl, this.props.access_token, {}, {}, (resp) => {
         let respData = resp.data;
         this.setState({
            currencyList        :   respData && respData.length > 0 ? respData.map(cl => {return({value : cl.code,label:`${cl.currency} ${cl.code ? `(${cl.code})` : null}`})})  :[]
         })
        }).then(() => this.setState({ currency_list_loading: false }));
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...this.state.generalConfigForm} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    editGeneralConfigurationModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-5"><label className="form-label">Allow Transactions in Multiple Currency</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="multiple_currency"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.multiple_currency == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="multiple_currency"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm",() => {
                                                    this.tapSelectChange([], 'generalConfigForm', 'group_currencies')
                                                })}
                                                checked={this.state.generalConfigForm.multiple_currency == "N"} /> No
                                        </label>
                                    </div>
                                </div>

                                {this.state.generalConfigForm && this.state.generalConfigForm.multiple_currency && this.state.generalConfigForm.multiple_currency == 'Y' &&
                                    <div className="row align-items-center mb-2 mt-2 px-2">
                                        <div className="col-md-5"><label className="form-label">Allowed Currencies</label></div>
                                        <div className="col-md-7">
                                            <TapSelect
                                                options={this.state.currencyList}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'generalConfigForm', 'group_currencies')}
                                                value={this.state.currencyList.filter(u => this.state.generalConfigForm?.group_currencies?.includes(u.value))}
                                                isSearchable={true}
                                                isMulti={true}
                                                isClearable={true}
                                                placeholder="Select Currency"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
  }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
        return (<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                    <th scope="col" style={{ width: "15%" }} >Action</th>
                </tr>
            </thead>
            <tbody>
                <tr className="text-center">
                    <td>1</td>
                    <td className="text-start">Default Currency</td>
                    <td>{data && data.default_currency  ? data.default_currency.code : ''}</td>
                    <td></td>
                </tr>
                <tr className="text-center">
                    <td>2</td>
                    <td className="text-start">Allow Transactions in Multiple Currency</td>
                    <td>{data && data.multiple_currency && data.multiple_currency == 'Y' ? "Yes" : "No"}</td>
                    <td><tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                                         role="button" aria-expanded="false"/>
                                <ul className="dropdown-menu" style={{cursor:"pointer"}} >
                                    <li className="dropdown-item" onClick={this.editGeneralConfigurationInit}>Edit</li>
                                </ul>
                         </td>
                </tr>
                {data && data.group_currencies && data.group_currencies.length > 0 && 
                  <tr className="text-center">
                  <td>3</td>
                  <td className="text-start">Allowed Currencies</td>
                  <td>{data && data.group_currencies && data.group_currencies.length > 0 ? data.group_currencies.map(cr => {return(cr)}).join(', ')  : "-"}</td>
              </tr>}
           
            </tbody>
        </table>)
    }

    render                                  =     ()           =>     {
        return (<Ax>
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">

                   <div className="p-2 mx-2">
                   <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                    </div>
                    {this.state.generalConfigloading ? <Loader />
                        : this.generalConfigurationJsx()}
                    {this.editGeneralConfigurationModalJsx()}
                    </div>
                </div>
            </section>
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(CurrencyConfiguration);