import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import {number} from "prop-types";
import swal from "sweetalert";
import axios from "axios";

class General extends AppBaseComponent {

    constructor(props) {
        super(props);

        
        this.initGeneralConfigFormData      =   {
            // use_global_material                 :    'N',
            runtime_material_creation           :    'N',
        };

        this.state                              =   {
            generalConfigloading                :      false,
            general_config_list                 :      null,
            generalConfigFormData               :     {...this.initGeneralConfigFormData},
            editFormSubmiting                   :      false,
        };
    }

    componentDidMount() {
        this.getGeneralSettingListing();

        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
       
    }

    //functions for general configuration
      getGeneralSettingListing              =    ()           =>     {
        this.setState({generalConfigloading : true})
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/material/config', this.props.access_token , {}, {}, (resp) => {
          
         this.setState({general_config_list  : resp.data})
        }).then(() => this.setState({generalConfigloading: false}));
    }

    editGeneralConfigurationInit          =    ()           =>     {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigFormData = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigFormData[name] = val;
                });
              }
              
            this.setState({generalConfigFormData})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT',process.env.REACT_APP_IAM_API_SERVER + '/material/config', this.props.access_token, {} , {...this.state.generalConfigFormData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
          return (<table className="table table-hover table-bordered my-4 ">
              <thead className="table-secondary">
                  <tr className="text-center">
                      <th scope="col" style={{ width: "5%" }}>S.No</th>
                      <th scope="col" className="text-start">Configuration</th>
                      <th scope="col" style={{ width: "25%" }} >Value</th>
                  </tr>
              </thead>
              <tbody>
                  {/* <tr className="text-center">
                      <td>1</td>
                      <td className="text-start"> Show Global Material Name </td>
                      <td>{data && data.use_global_material && data.use_global_material == 'Y' ? "Yes" : "No"}</td>
                  </tr> */}
                  <tr className="text-center">
                      <td>1</td>
                      <td className="text-start"> Allow creation of material from transaction </td>
                      <td>{data && data.runtime_material_creation && data.runtime_material_creation == 'Y' ? "Yes" : "No"}</td>
                  </tr>
              </tbody>
          </table>)
      }

      editGeneralConfigurationModalJsx      =    ()           =>     {
          return (
              <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                  <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                          </div>
                          <form onSubmit={this.submitGeneralConfigurationForm}>
                              <div className="modal-body p-3">
                                  {/* <div className="row align-items-center mb-2 mt-2 px-2">
                                      <div className="col-md-9"><label className="form-label">Show Global Material Name</label></div>
                                      <div className="col-md-1">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="use_global_material"
                                                  value="Y"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.use_global_material == "Y"}
                                              /> Yes
                                          </label>
                                      </div>
                                      <div className="col-md-2">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="use_global_material"
                                                  value="N"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.use_global_material == "N"} /> No
                                          </label>
                                      </div>
                                  </div> */}
                                
                                  <div className="row align-items-center mb-2 mt-2 px-2">
                                      <div className="col-md-9"><label className="form-label">Allow creation of material from transaction</label></div>
                                      <div className="col-md-1">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="runtime_material_creation"
                                                  value="Y"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.runtime_material_creation == "Y"}
                                              /> Yes
                                          </label>
                                      </div>
                                      <div className="col-md-2">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="runtime_material_creation"
                                                  value="N"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.runtime_material_creation == "N"} /> No
                                          </label>
                                      </div>
                                  </div>
                            
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                  <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          );
    }
   

  
    render                                  =     ()           =>     {
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                <div className="text-end "><button className="btn btn-primary" onClick={this.editGeneralConfigurationInit}>Edit </button></div>
            </div>
            {this.state.generalConfigloading ? <Loader />
                : this.generalConfigurationJsx()}

            {this.editGeneralConfigurationModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(General);