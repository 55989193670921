import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import AssetCard from "../../includes/assetCard/AssetCard";
import { Link } from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import errorValidation from "../../../services/ErrorValidation";
import { Helmet } from "react-helmet";
import TapSelect from "../../../components/ui/TapSelect";
import TapApiUrls from "../../../services/TapApiUrls";
import TapIcon from "../../../services/TapIcon";

class EmployeeAddress extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const employeeData = this.props.employeeData;

    return (
      <Ax>
        <div className="tab_content_wrapper">
          <span className="content_heading">Present Address</span>
        </div>
        <table className="table table-hover table-bordered table-responsive bg-white my-2 ">
          <tbody>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Address 1
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.address1}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Address 2
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.address2}
              </td>
            </tr>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Landmark
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.landmark}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                City
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.city}
              </td>
            </tr>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Country
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.country}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                State
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.state}
              </td>
            </tr>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                District
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.district}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Postal Code
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.present_address &&
                  employeeData.present_address?.pincode}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="tab_content_wrapper">
          <span className="content_heading">Permanent Address</span>
        </div>
        <table className="table table-hover table-bordered table-responsive bg-white my-2 ">
          <tbody>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Address 1
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.address1}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Address 2
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.address2}
              </td>
            </tr>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Landmark
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.landmark}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                City
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.city}
              </td>
            </tr>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Country
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.country}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                State
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.state}
              </td>
            </tr>
            <tr>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                District
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.district}
              </td>
              <td className="fs14 fw-normal" style={{ width: "15%" }}>
                Postal Code
              </td>
              <td className="fs14 fw500" style={{ width: "35%" }}>
                {employeeData &&
                  employeeData.permanent_address &&
                  employeeData.permanent_address?.pincode}
              </td>
            </tr>
          </tbody>
        </table>
      </Ax>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions: state.app && state.app.acl_info ? state.app.acl_info : null,
  };
};

export default connect(mapStateToProps)(EmployeeAddress);
