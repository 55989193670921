import React from 'react';
import Ax from "../../components/hoc/Ax";
import {connect} from "react-redux";


class Permission extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return(<Ax>
            test
           {/* <button disabled={!this.props.permissions.includes(this.props.Key)}
            title={!this.props.permissions.includes(this.props.Key) ? 'You do not have permission to perform this action' : ''} style={{"pointer-events" : "all"}}
            type="button" onClick={() => this.props.clickFunction()}>{this.props.children}</button>*/}
        </Ax>)
    }
}

const mapStateToProps = state => {
    return {
        permissions             :   state.app.acl_info.permissions
    };
};

export default connect(mapStateToProps)(Permission);

