import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import TapSelect from "../../../components/ui/TapSelect";
import { Helmet } from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import swal from 'sweetalert';

class FuelReceiver extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/fuel-receiver';
        this.stationUrl         =   IAM_API_BASE_URL_2 + '/station';

        this.reportFilterFormInitialState   =   {
            search_fuel_station_names           :   [],
            search_fuel_receiver_types          :   [],
            search_fuel_types                   :   [],
            search_display_id                   :   '',
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
        };
        
        this.reportFilterFormLabel          =   {
             search_fuel_station_names      :       'Fuel Station Name : ',
            search_fuel_receiver_types      :       'Fuel Receiver Type : ',
            search_fuel_types               :       'Fuel Station Type : ',
            search_display_id               :       'Transaction ID : ',
            date_range                      :       'Date Range : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState, date_range : 'current_month'},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null,  
            all_periods                         :   [],
            allFuelStationTypes                 :    [],
            allFuelTypes                        :    [],
            allFuelStationList                  :    [],
            allFuelTypeReceiverList             :    [],
            fuelDetailData                      :    null,
            reportData                          :   null,                  
        };

    }

    componentDidMount() {
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props);
        }, 1000)
        this.viewTransactionModal                =          new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
    }
    
    // componentWillReceiveProps(nextProps){
    //     if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
    //         this.initilaizeAppDataToFilterForm(nextProps);
    //     }
        
    // }
    
    initilaizeAppDataToFilterForm           =   (pr)  =>  {
        
        const report_name   =   'fuel_receiver_report';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })


        let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;

        HttpAPICall.withAthorization('GET', this.reportUrl,
            this.props.access_token, {action:"formData"}, null, (response) => {
                this.setState({
                    access_token                :   pr.access_token,
                    formDataLoaded              :   true,
                    all_periods                 :   all_periods ,
                    allFuelTypeReceiverList       :   response.data && response.data.fuel_receiver && response.data.fuel_receiver.length > 0 ? response.data.fuel_receiver.map((s) => { return { value: s.id, label: s.name }; }) : [],
                    allFuelStationList          :   response.data && response.data.fuel_station && response.data.fuel_station.length > 0 ? response.data.fuel_station.map((s) => { return { value: s.id, label: s.fuelstation_name }; }) : [],
                    allFuelTypes                :   response.data && response.data.fuel_type && response.data.fuel_type.length > 0 ? response.data.fuel_type.map((s) => { return { value: s.id, label: s.fuel_type }; }) : [],
                }, () => {
                        this.reportFilterFormSubmit()
                });
            });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
       
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
       
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_display_id') {
                        show_val            =  this.state.reportFilterForm.search_display_id ? this.state.reportFilterForm.search_display_id : '';
                    }
                    if(key == 'search_fuel_station_names') {
                        show_val            =  this.state.allFuelStationList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_fuel_types') {
                        show_val            =  this.state.allFuelTypes.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_fuel_receiver_types') {
                        show_val            =  this.state.allFuelTypeReceiverList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let data = {
            ...this.state.submittedReportFilterForm,
            date_range_end : this.state.submittedReportFilterForm.date_range_end  !== "Invalid date" ? this.state.submittedReportFilterForm.date_range_end : null,
            date_range_start : this.state.submittedReportFilterForm.date_range_start  !== "Invalid date" ? this.state.submittedReportFilterForm.date_range_start : null,
        }
        let params                          =   {page:page, ...data,action:"data"};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownloadHandler                 =   ()  =>  {
        
        swal({ title: "Download",
        text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
        icon: "warning",
        buttons: ["Cancel", "Okay"],
        }).then(willDownload => {
            if (willDownload) {
                let data = {
                    ...this.state.submittedReportFilterForm,
                    date_range : this.state.submittedReportFilterForm.date_range ? this.state.submittedReportFilterForm.date_range : "current_month",
                    date_range_end : this.state.submittedReportFilterForm.date_range_end  !== "Invalid date" ? this.state.submittedReportFilterForm.date_range_end : null,
                    date_range_start : this.state.submittedReportFilterForm.date_range_start  !== "Invalid date" ? this.state.submittedReportFilterForm.date_range_start : null,
                }
                    this.setState({ reportDownloading: true});
                    HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...data, action : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
                
            }
        });
        
    }

    
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    
    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :           [],
            reportFilterForm                :           {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :           {...this.reportFilterFormInitialState},
        }, () => {
            this.loadListingTblData(1);
        });
    }

    viewTransactionInit             =       (fuel_id = null)      =>      {
        this.getFuelDetail(fuel_id)
        this.viewTransactionModal.show()
         
    }

    getFuelDetail           =       (id)        =>  {
        this.setState({ view_loading: true});
        HttpAPICall.withAthorization('GET', this.stationUrl + '/ledger_detail/'  + id, this.props.access_token, {} , {} , (response) => 
          this.setState({fuelDetailData : response.data})
        ).then(() => this.setState({ view_loading : false}));
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faPallet} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">

                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse " >
               <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
               <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                    <div className="col-sm-6">
                        <input
                            name="search_display_id"
                            type="text"
                            value={this.state.reportFilterForm.search_display_id}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Please enter Transaction ID"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Fuel Type</label>
                    <div className="col-sm-6">
                    <TapSelect
                            options={this.state.allFuelTypes}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_fuel_types');
                            }}
                            isSearchable={true}
                            isMulti={true}
                            value={this.state.allFuelTypes.filter(s => this.state.reportFilterForm.search_fuel_types.includes(s.value))}
                            placeholder="Select Fuel Type"
                        />
                        
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Fuel Receiver</label>
                    <div className="col-sm-6">
                    <TapSelect
                            options={this.state.allFuelTypeReceiverList}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm','search_fuel_receiver_types');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allFuelTypeReceiverList.filter(s => this.state.reportFilterForm.search_fuel_receiver_types.includes(s.value))}
                            placeholder="Select Fuel Receiver"
                        />
                        
                    </div>
                </div>
             
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Fuel Station Name</label>
                    <div className="col-sm-6">
                       <TapSelect
                            options={this.state.allFuelStationList}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_fuel_station_names');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allFuelStationList.filter(s => this.state.reportFilterForm.search_fuel_station_names.includes(s.value))}
                            placeholder="Select Fuel Station"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods              =       {this.state.all_periods ? this.state.all_periods : []}
                            value                =       {this.state.reportFilterForm.date_range}
                            startDate            =       {this.state.reportFilterForm.date_range_start}
                            endDate              =       {this.state.reportFilterForm.date_range_end}
                            onSelectPeriod       =       {(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range          :       period,
                                        date_range_start    :       startDate ? startDate : null,
                                        date_range_end      :       endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4" >Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
       
        return (<div className="bg-white">
            { this.state.listing_loading ? <Loader /> : null }
           { !this.state.listing_loading && this.state.reportDownloadBtn == true
                ? this.reportTableJsx() : null
            }
       
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr className="text-center">
                    <th style={{ width: "5%" }}>S.No</th>
                        <th style={{ width: "12%" }}>Date of Transaction</th>
                        <th style={{ width: "15%" }}>Transaction ID</th>
                        <th style={{ width: "20%" }}>Receiver Name</th>
                        <th style={{ width: "20%" }}>Fuel Station</th>
                        <th style={{ width: "10%" }} className='text-center'>Fuel Type</th>
                        <th scope="col" style={{ width: "10%" }} className="text-end">Quantity</th>
                        <th scope="col" style={{ width: "10%" }} className="text-end">Unit</th>
                        <th style={{ width: "10%" }} className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {                          
                            return (<tr key={index} className='text-center'>
                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td>{data.date_of_transation ? data.date_of_transation : ""}</td>
                                    <td><a className={"link-primary cursor_pointer"} role="button" onClick={() => this.viewTransactionInit(data.id)}>{data.display_id ? data.display_id : ""}</a></td>
                                    <td  className="text-start">{data.receiver_name ? data.receiver_name : ""}</td>
                                    <td  className="text-start">{data.station_name ? data.station_name : ""}</td>
                                    <td>{data.fuel_type ? data.fuel_type : ""}</td>
                                    <td  className="text-end">{data.quantity ? data.quantity : ""}</td>
                                    <td  className="text-end">{data.unit_rate ? data.unit_rate : ""}</td>
                                    <td  className="text-end">{data.amount ? data.amount : ""}</td>
                                </tr>)
                        }))
                        : <tr><td className="text-center" colSpan={8}>No Record Found</td></tr>
                    }
                </tbody>
            </table>
        </Ax>);
    }

    viewTransactionJsx          =       ()      =>      {
        return (<div className="modal fade" id="viewTransactionModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchFundTransferModalLabel">
                            Issue  Fuel       
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        {
                            this.fuelDetailJsx()
                        }
                    </div>
                </div>
            </div>
        </div>)

    }

    fuelDetailJsx       =       ()      =>      {
        let fuelDetail = this.state.fuelDetailData;
        let fuel_density_qty = 0;
        if(fuelDetail){
          fuel_density_qty = (1000 / Number(fuelDetail.fuel_density)) * (Number(fuelDetail.tanker_loaded_weight) - Number(fuelDetail.tanker_empty_weight));
        }
        return (<Ax>
            {this.state.view_loading
                ? <Loader />
                :
                fuelDetail ?
                    <Ax>
                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                            <tbody>
                                <tr>
                                    <td style={{ width: "25%" }}>Date of Transaction</td>
                                    <th>{fuelDetail.transaction_date_display ? fuelDetail.transaction_date_display : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Fuel Station {fuelDetail.transfer_type == "OUT" && fuelDetail.transaction_type == 'fuel_transfer' ? "from" : fuelDetail.transfer_type == "IN" && fuelDetail.transaction_type == 'fuel_transfer' ? 'to' : ""}</td>
                                    <th>{fuelDetail.station && fuelDetail.station.fuelstation_name ? fuelDetail.station.fuelstation_name : "-"}</th>
                                </tr>

                                <tr>
                                    <td style={{ width: "25%" }}>Fuel Type</td>
                                    <th>{fuelDetail.fuel_type_name ? fuelDetail.fuel_type_name : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Quantity</td>
                                    <th>{fuelDetail.fuel_qty ? fuelDetail.fuel_qty + ' ' + fuelDetail.fuel_type_unit : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Unit</td>
                                    <th>{fuelDetail.rate ? fuelDetail.rate : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Amount</td>
                                    <th>{fuelDetail.amount ? '₹' + fuelDetail.amount : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Transaction Type</td>
                                    <th>{fuelDetail.transaction_type_display ? fuelDetail.transaction_type_display : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>
                                        {fuelDetail.transaction_type == "fuel_added"
                                            ? "Received from (Fuel Vendor)" : fuelDetail.transaction_type == "fuel_to_other"
                                                ? "Fuel Receiver" : null}</td>
                                    <th>{fuelDetail.details ? fuelDetail.details : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Reference Number</td>
                                    <th>{fuelDetail.reference_num ? fuelDetail.reference_num : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Remarks</td>
                                    <th>{fuelDetail.remarks ? fuelDetail.remarks : "-"}</th>
                                </tr>
                            </tbody>
                        </table>
                        {fuelDetail.transaction_type == "fuel_added" && fuelDetail.calculate_by_density == 'Y'
                            ? <Ax>
                                <div className="tab_content_wrapper mt-2">
                                    <span className="content_heading"> Calculate Fuel Quantity based on Density</span>
                                </div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "25%" }}>Tanker Loaded Weight</td>
                                            <th>{fuelDetail.tanker_loaded_weight ? fuelDetail.tanker_loaded_weight : "-"} Kg</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Tanker Empty Weight</td>
                                            <th>{fuelDetail.tanker_empty_weight ? fuelDetail.tanker_empty_weight : "-"} Kg</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Fuel Weight</td>
                                            <th>{fuelDetail.fuel_weight ? fuelDetail.fuel_weight : "-"} Kg</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Fuel Density</td>
                                            <th>{fuelDetail.fuel_density ? fuelDetail.fuel_density : "-"} (kg/m3)</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Fuel Qty according to Density & Weight</td>
                                            <th>{fuel_density_qty ? parseFloat(fuel_density_qty).toFixed(3) : "0"} </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </Ax>
                            : null}
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Transaction Detail </span>
                        </div>
                        <table className="table mt-2 table-hover table-bordered  table-responsive">
                            <tbody>
                                <tr>
                                    <td style={{ width: "25%" }}>Transaction ID</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.transaction_id ? fuelDetail.transaction_details.transaction_id : ""}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Transaction Date</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.date_of_transaction ? fuelDetail.transaction_details.date_of_transaction : ""}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Entry Created By</td>
                                    <th >{fuelDetail.transaction_details && fuelDetail.transaction_details.created_by && fuelDetail.transaction_details.created_by}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Created On</td>
                                    <th >{fuelDetail.transaction_details && fuelDetail.transaction_details.created_on_display && fuelDetail.transaction_details.created_on_display}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Last Updated By</td>
                                    <th >{fuelDetail.transaction_details && fuelDetail.transaction_details.last_updated_by && fuelDetail.transaction_details.last_updated_by}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Last Updated On</td>
                                    <th >{fuelDetail.transaction_details && fuelDetail.transaction_details.last_updated_on_display && fuelDetail.transaction_details.last_updated_on_display}</th>
                                </tr>
                            </tbody>
                        </table>
                    </Ax>
                    : null}
        </Ax>)
      }

    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Fuel Receiver wise Fuel Issue Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.reportSearchClear} />
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                            : <Loader />
                        }
                        {this.state.reportDownloadBtn == true
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                        {this.viewTransactionJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        fuel_stations           :   state.app && state.app.fuel_stations ? state.app.fuel_stations : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(FuelReceiver);