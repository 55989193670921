import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from "../../../components/ui/Status";
import ScrapItemAdd from "./ScrapItemAdd";

class ScrapItemList extends React.Component {

    constructor(props) {
        super(props);

        this.state                         =     {
            scrapItemAddScreenView          :     false,
            scrapItemUpdateScreenView       :     false,
            minimizeTable                   :    false,
        };
    }

    componentDidMount() {

    }

    openAddScrapItemScreen       =      ()      =>     {
        this.setState({scrapItemAddScreenView:true})
    }

    addScrapItemScreenClose      =      ()       =>     {
        this.setState({scrapItemAddScreenView:false})
    }

    viewScrapItemDetail          =      ()        =>     {
        this.setState({minimizeTable  :   true})
    }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    scrapItemListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Scrap Item List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button"  className="btn btn-primary" onClick={this.openAddScrapItemScreen}>Add Scrap Item</button> : null}
                    <button type="button"  className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action"/>
                    </button>
                    <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {this.state.minimizeTable   ? <li><a className="dropdown-item"  href="#">Add Scrap Item</a></li>:null}
                                </ul>
                            </span>
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Scrap Item : </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.scrapItemListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4 pageTbl"><div className="bg-white p-2 " style={{height:"140%"}}>{this.scrapItemViewDetails()}</div></div> : null }
                </div>
            </div>
        </Ax>);
    }

    scrapItemListingTableJsx            =   ()   =>   {
        return(<Ax>
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                { this.state.minimizeTable
                    ? (<tr>
                        <th scope="col" style={{width:"15%"}}>Transaction ID (Date)</th>
                    </tr> )
                    :   ( <tr className="text-center">
                        <th scope="col" style={{width:"5%"}}>S.No</th>
                        <th scope="col" style={{width:"10%"}}>Date</th>
                        <th scope="col" style={{width:"12%"}}> Transaction ID</th>
                        <th scope="col" style={{width:"12%"}}>Site Name</th>
                        <th scope="col" style={{width:"14%"}}>Asset Name (Code)</th>
                        <th scope="col" style={{width:"12%"}}>Scrap Transaction Type</th>
                        <th scope="col" style={{width:"8%"}}>Quantity</th>
                        <th scope="col" style={{width:"8%"}}>Amount</th>
                        <th scope="col" style={{width:"8%"}}>Status</th>
                        <th scope="col" style={{width:"5%"}}>Action</th>
                    </tr> )
                }
                </thead>
                <tbody>
                { this.state.minimizeTable
                    ? (<tr>
                        <td scope="col" style={{width:"15%"}}>1234 (16-11-2021)</td>
                    </tr> )
                    :( <tr className="text-center">
                        <td>1</td>
                        <td>16-11-2021</td>
                        <td><a role="button"  className="link-primary" onClick={this.viewScrapItemDetail}>1234555</a></td>
                        <td>Gurgaon</td>
                        <td>123 (Mnb)</td>
                        <td>Manual</td>
                        <td>10</td>
                        <td>2</td>
                        <td><Status>Active</Status></td>
                        <td>
                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                               alt="action-icon"
                                               className="img-fluid pr10 pl10"
                                               data-bs-toggle="dropdown" href="#"
                                               role="button" aria-expanded="false"/>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item">
                                    Edit
                                </li>
                            </ul>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </Ax>)
    }

    scrapItemViewDetails                =   ()   =>   {
        return(<Ax>
            <table className="table table-bordered bg-white">
                <thead className="table-secondary">
                <tr>
                    <th style={{width:"33%"}}>ID</th>
                    <th style={{width:"33%"}}>Date</th>
                    <th style={{width:"33%"}}>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td scope="col">123448383</td>
                    <td scope="col">16-11-2021</td>
                    <td scope="col" className="align-middle">Status</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-hover table-bordered bg-white mb-0 pb-0">
                <tbody>
                <tr>
                    <td style={{width:"31.5%"}}>Site Name</td>
                    <td style={{width:"64%"}}>Site Name</td>
                </tr>
                <tr>
                    <td >Warehouse Name</td>
                    <td >Gurgaon</td>
                </tr>
                <tr>
                    <td>Scrap Transaction Type</td>
                    <td>Manually</td>
                </tr>
                <tr>
                    <td >Asset Name (Code)</td>
                    <td >Asset Name (Code)</td>
                </tr>
                </tbody>
            </table>
                <table className="table table-hover table-bordered bg-white mt-0 pt-0 mb-3">
                    <tbody>
                    <tr>
                        <td style={{width:"33%"}}>Item Consumption ID & Date</td>
                        <td style={{width:"33%"}}>235252</td>
                        <td style={{width:"34%"}}>16-11-2021</td>
                    </tr>
                    </tbody>
                </table>

            <table className="table table-hover table-bordered table-responsive bg-white">
                <thead className="table-secondary">
                <tr>
                    <th style={{"width" : '5%'}} className="text-center"><input type="checkbox" /></th>
                    <th style={{width:"42%"}}>Item Details</th>
                    <th style={{width:"10%"}} className="text-center">Quantity</th>
                    <th style={{width:"15%"}} className="text-center">Unit Rate</th>
                    <th style={{width:"8%"}} className="text-center">Tax</th>
                    <th style={{width:"15%"}} className="text-center">Amount</th>
                    <th style={{width:"5%"}}></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="text-center"><input type="checkbox" /></td>
                    <td>Item Name
                        <div className="form-text">Item Code : <b>Code</b></div>
                        <div className="form-text">Manufacturer : <b>Manufacturer</b></div>
                        <div className="form-text">MPN : <b>MPN</b></div>
                        <div className="form-text">Remark : <b>Remarks</b></div>
                    </td>
                    <td className="text-end">
                        2 <span className="text-helping input-group-text text-end pr0">Nos</span>
                    </td>
                    <td className="text-end">Nos</td>
                    <td className="text-end">5 % </td>
                    <td className="text-end">23400</td>
                    <td className="text-end">   <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                                   alt="action-icon"
                                                                   className="img-fluid pr10 pl10"
                                                                   data-bs-toggle="dropdown" href="#"
                                                                   role="button" aria-expanded="false"/>
                        <ul className="dropdown-menu">
                            <li className="dropdown-item">
                                Edit
                            </li>
                        </ul></td>
                </tr>
                </tbody>
            </table>
            <table className="table table-borderless">
                <tbody>
                <tr><td style={{width:"60%"}}>Notes : </td><th className="subtotal_bg_color">Sub Total</th><td className="subtotal_bg_color text-end">20</td></tr>
                <tr><td style={{width:"60%"}} className="fw-bold">This is note</td><th className="subtotal_bg_color">GST</th><td className="subtotal_bg_color text-end">30</td></tr>
                <tr><td style={{width:"60%"}}></td><th className="total_bg_color">Total</th><td className="total_bg_color text-end">50</td></tr>
                </tbody>
            </table>
            <div className="tab_content_wrapper content_heading">Transaction Details</div>
            <table className="table table-hover table-bordered bg-white mb-3 mt-3">
                <tbody>
                <tr>
                    <td style={{width:"31.5%"}}>Transaction Created On</td>
                    <td style={{width:"64%"}}>12-11-2021</td>
                </tr>
                <tr>
                    <td >Transaction Created By</td>
                    <td >created</td>
                </tr>
                <tr>
                    <td>Last Edited On</td>
                    <td>16-11-2021</td>
                </tr>
                <tr>
                    <td>Last Edited By</td>
                    <td>Swarnima</td>
                </tr>
                </tbody>
            </table>

        </Ax>)
    }

    getPageScreen                       =   ()  =>  {
        let pageScreen = null;
        if(this.state.scrapItemAddScreenView) {
            pageScreen  =  <ScrapItemAdd closeEvent={this.addScrapItemScreenClose}/>
        } else if(this.state.scrapItemUpdateScreenView) {
            pageScreen  =  "update"
        } else {
            pageScreen  = this.scrapItemListjsx();
        }
        return pageScreen;
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
            {this.getPageScreen()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(ScrapItemList);