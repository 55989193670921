import React from 'react';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import axios from 'axios';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import Loader from '../../../components/ui/Loader/Loader';
import tapIcon from "../../../services/TapIcon";
import AppBaseComponent from '../../../components/AppBaseComponent';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import swal from "sweetalert";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';

class ItemAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.addItemFormInitialState         =     {
            id                              :     null,
            category                        :     'goods',
            name                            :     '',
            item_code                       :     '',
            description                     :     '',
            measuring_unit_id               :     '',
            manufacturer_id                 :     '',
            tags                            :     [],
            manufacturer_part_no            :     '',
            hsn_code                        :     '',
            is_scrapble                     :     'N',
            purchase_price                  :     '',
            consumption_price               :     '',
            purchase_currency               :     'INR',
            consumption_currency            :     'INR',
            scrap_price                     :     '',
            is_taxable                      :     'Y',
            igst                            :      '',
            gst                             :      '',
            new_measuring_unit              :      '',
            new_manufacturer_name           :      '',
            new_tags                        :      ''
        };
        
        this.state                           =      {
            formData                         :      null,
            addItemForm                      :      {...this.addItemFormInitialState},
            measuringUnits                   :      [],
            manufacturers                    :      [],
            itemTags                         :      [],
            itemIGST                         :      [],
            itemGST                          :      [],
            measuring_unit_id                :      null,
            manufacturer_id                  :      null,
            tags                             :      null,
            igst                             :      null,
            gst                              :      null,
            itemAddFormSubmitting            :      false,
            itemFormDataLoaded               :      false,
            warehouseList                    :      [],
            additional_attribute             :      [],
            allCategory                      :      [],
            editItem                         :      false,
            addNewMeasuringUnit              :      'N',
            addNewManufacturer               :      'N',
            addNewTags                       :      'N',
            openingBalanceWarehouseRows      :      [{warehouse : null}],
            allCurrencyList                  :      []
        };

        this.itemAddFormDataUrl =   INVENTORY_API_BASE_URL_2 + '/item/add_form_data';
        this.itemUrl            =   INVENTORY_API_BASE_URL_2 + '/item';

    }
    
    componentDidMount() {
        this.initilaizeFormData()

        if(this.props.location && this.props.location.state && this.props.location.state.id){
            this.updateItemForm(this.props.location.state.id)
        }
        
    }

    initilaizeFormData                  =   () =>  {

        if (!this.state.itemFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.itemAddFormDataUrl, this.props.access_token, null, null, (response) => {
                let formData = response.data
                this.setState({
                    itemFormDataLoaded          :            true,
                    formData                    :            formData ? formData : [],
                    allCategory                 :            formData?.category.map((mou) => { return { index: mou.index, item: mou.item } }) ?? [],
                    measuringUnits              :            formData ? formData.measurement_units.map((mou) => { return { value: mou.id, label: mou.name } }) : [],
                    manufacturers               :            formData ? formData.manufacturers.map((m) => { return { value: m.id, label: m.name } }) : [],
                    itemTags                    :            formData ? formData.tags.map((t) => { return { value: t.name, label: t.name } }) : [],
                    warehouseList               :            formData ? formData.warehouses.map((t) => { return { value: t.id, label: t.name } }) : [],
                    itemIGST                    :            formData ? formData.igst.map((t) => { return { value: t, label: `IGST ${t}%` } }) : [],
                    itemGST                     :            formData ? formData.gst.map((t) => { return { value: t, label: `GST ${t}%` } }) : [],
                    allCurrencyList             :            formData && formData.currency_list && formData.currency_list.length > 0 ? formData.currency_list.map(cl => {return({value : cl.code,label:cl.code})}) : [],
                },() => {

                });
            });
        }
    
    }

    updateItemForm              =           (id)      =>      {
         
        HttpAPICall.withAthorization('GET',  this.itemUrl + '/' + id, this.props.access_token,{}, {}, (resp) => {
            let updateFormState = [];
            
            if (resp.data.data.additional_attribute) {
                Object.keys(resp.data.data.additional_attribute).map((k) => {
                    let name =  'additional_attributes_' + k;
                    updateFormState[name] = resp.data.data.additional_attribute[k]
                });
            }
            
            this.setState({
                addItemForm         :  {
                    ...this.addItemFormInitialState,
                    id                      :    this.props.location && this.props.location.state && this.props.location.state.clone ? null : resp.data.data.id,
                    category                :    resp.data.data.category ? resp.data.data.category : null,
                    name                    :   resp.data.data.name,
                    item_code               :   resp.data.data.item_code,
                    description             :   resp.data.data.description,
                    manufacturer_part_no    :   resp.data.data.manufacturer_part_no,
                    measuring_unit_id       :   resp.data.data.measuring_unit ? resp.data.data.measuring_unit.id : null,
                    manufacturer_id         :   resp.data.data.manufacturer ? resp.data.data?.manufacturer?.id : null,
                    hsn_code                :   resp.data.data.hsn_code,
                    is_scrapble             :   resp.data.data.is_scrapble,
                    purchase_price          :   resp.data.data.purchase_price,
                    consumption_price       :   resp.data.data.consumption_price,
                    purchase_currency          :   resp.data.data.purchase_currency,
                    consumption_currency       :   resp.data.data.consumption_currency,
                    scrap_price             :   resp.data.data.scrap_price,
                    is_taxable              :   resp.data.data.is_taxable,
                    tags                    :   resp.data.data.tags ? resp.data.data.tags.map((t) => t.name) : [],
                    igst                    :   resp.data.data?.igst ? resp.data.data?.igst : null,
                    gst                     :   resp.data.data.gst ? resp.data.data?.gst :null,
                    ...updateFormState
                },editItem :  this.props.location && this.props.location.state && this.props.location.state.clone ? false : true
            });
              
        }) 
    }

    addWarehouseRow                           =   ()  =>  {
        this.setState({openingBalanceWarehouseRows : [...this.state.openingBalanceWarehouseRows, {warehouse : null}] });
    }

    onWarehouseChange                            =   (selectedWarehouse, k)  => {
        if(selectedWarehouse.value && selectedWarehouse.value > 0) {
            let item                    =   selectedWarehouse;
            let allRows                 =   this.state.openingBalanceWarehouseRows;
            allRows[k]                  =   {
                warehouse                   :   item, 
                qty                         :   selectedWarehouse.qty ? selectedWarehouse.qty : 0,
                rate                   :   selectedWarehouse.rate ? selectedWarehouse.rate : 0, 
            };
            this.setState({openingBalanceWarehouseRows : allRows});
            this.addWarehouseRow();
        }
    }

    onWarehouseInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val                             =   '';
        if(name === 'expiry_date') {
            val                         =   selectedOption ?  moment(selectedOption).format('YYYY-MM-DD') : null
        } else {
            val = e.target.value;
        }
        let allRows                 =   this.state.openingBalanceWarehouseRows;
        allRows[k]                  =   {...this.state.openingBalanceWarehouseRows[k] , [name] : val};
        this.setState({openingBalanceWarehouseRows : allRows});
    }

    removeWarehouseRow                =     (k)     =>  {
        let allRows                 =   this.state.openingBalanceWarehouseRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({openingBalanceWarehouseRows : allRows});
        }
    }

    closeEvent          =       ()          =>      {
        if(this.state.editItem){
            let obj = {asset_name : this.state.addItemForm.name ,item_category : this.state.addItemForm.category}
            this.props.history.push({ pathname: `/item_list/details/${this.state.addItemForm.id}`, search: "?" + new URLSearchParams(obj).toString()})
        }else{
            this.props.history.push('/item_list') 
        }
    }
    
    addItemFormSubmitEvent                 =   (event)  =>  {
        event.preventDefault();

        let additional_attributes = {};
        this.state.formData.additional_attributes.forEach((i, k) => {
            let name = 'additional_attributes_' + i.key;
            additional_attributes[i.key] = this.state.addItemForm[name] ? this.state.addItemForm[name] : '';
        });
       
        if (this.state.addItemForm.manufacturer_part_no && !this.state.editItem) {
            let params = { per_page: 50, exact_manufacturer_part_no: this.state.addItemForm.manufacturer_part_no }
            HttpAPICall.withAthorization('GET', this.itemUrl, this.props.access_token, { ...params }, {}, (resp) => {
                if (resp.data.data.length > 0) {
                    let title = `An item  ${resp.data.data[0].name} (${resp.data.data[0].item_code}) and Manufacturer ${resp.data.data[0].manufacturer ? resp.data.data[0].manufacturer.name : "-"} is available with same MPN ${resp.data.data[0].manufacturer_part_no}. Do you want to add this item?`
                    swal({
                        title: "MPN Already Exists!",
                        text : title,
                        icon: "warning",
                        // dangerMode: true,
                        buttons: ["No", "Yes"],
                    })
                        .then(willContinue => {
                            if (willContinue) {
                                this.setState({ itemAddFormSubmitting: true });

                                let warehouse = [...this.state.openingBalanceWarehouseRows];

                                let warehouseData = warehouse.filter(i => i.warehouse !== null).map(i => {
                                    return { ...i, warehouse: i.warehouse.value }
                                });
                                let stockValidation = [];
                                let stockValidationError = false;

                                let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token };
                                if (warehouseData) {
                                    warehouseData.forEach(item => {
                                        let combinationKey = item.warehouse + item.rate + item.batch_number + item.expiry_date;
                                        if (stockValidation.includes(combinationKey)) {
                                            stockValidationError = true;
                                        }
                                        return stockValidation.push(combinationKey);
                                    })
                                }

                                if (stockValidationError) {
                                    toast.error("Opening Stock should not contain dublicate values", { position: toast.POSITION.TOP_RIGHT });
                                } else {
                                    axios({
                                        method: 'POST',
                                        headers: headers,
                                        data: { ...this.state.addItemForm, opening_stock: warehouseData, additional_attributes: additional_attributes,tags : this.state.addNewTags == "Y"  ? [this.state.addItemForm.new_tags] : this.state.addItemForm.tags},
                                        url: this.itemUrl
                                    }).then((response) => {
                                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                                        this.props.history.push('/item_list')
                                    }).catch((err) => {
                                      
                                        toast.error(err?.response?.data?.message ?? 'Unable to Add Item.', { position: toast.POSITION.TOP_RIGHT });
                                    }).then(() => {
                                        this.setState({ itemAddFormSubmitting: false });
                                    });
                                }
                            }
                        })
                } else {
                    this.setState({ itemAddFormSubmitting: true });

                    let warehouse = [...this.state.openingBalanceWarehouseRows];

                    let warehouseData = warehouse.filter(i => i.warehouse !== null).map(i => {
                        return { ...i, warehouse: i.warehouse.value }
                    });
                    let stockValidation = [];
                    let stockValidationError = false;

                    let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token };
                    if (warehouseData) {
                        warehouseData.forEach(item => {
                            let combinationKey = item.warehouse + item.rate + item.batch_number + item.expiry_date;
                            if (stockValidation.includes(combinationKey)) {
                                stockValidationError = true;
                            }
                            return stockValidation.push(combinationKey);
                        })
                    }

                    if (stockValidationError) {
                        toast.error("Opening Stock should not contain dublicate values", { position: toast.POSITION.TOP_RIGHT });
                    } else {
                        axios({
                            method: 'POST',
                            headers: headers,
                            data: { ...this.state.addItemForm, opening_stock: warehouseData, additional_attributes: additional_attributes,tags : this.state.addNewTags == "Y"  ? [this.state.addItemForm.new_tags] : this.state.addItemForm.tags},
                            url: this.itemUrl
                        }).then((response) => {
                            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                            this.props.history.push('/item_list')
                        }).catch((err) => {
                            toast.error(err?.response?.data?.message ?? 'Unable to Add Item.', { position: toast.POSITION.TOP_RIGHT });
                        }).then(() => {
                            this.setState({ itemAddFormSubmitting: false });
                        });
                    }

                }
            })
        } else {
            this.setState({ itemAddFormSubmitting: true });

            let warehouse = [...this.state.openingBalanceWarehouseRows];

            let warehouseData = warehouse.filter(i => i.warehouse !== null).map(i => {
                return { ...i, warehouse: i.warehouse.value }
            });
            let stockValidation = [];
            let stockValidationError = false;

            let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token };

            if (this.state.editItem) {
                axios({
                    method: 'PUT',
                    headers: headers,
                    params: { opening_stock: 'Y' },
                    data: { ...this.state.addItemForm, additional_attributes: additional_attributes,tags : this.state.addNewTags == "Y"  ? [this.state.addItemForm.new_tags] : this.state.addItemForm.tags },
                    url: this.itemUrl
                }).then((response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    let obj = {asset_name : this.state.addItemForm.name ,item_category : this.state.addItemForm.category}
                    this.props.history.push({ pathname: `/item_list/details/${this.state.addItemForm.id}`, search: "?" + new URLSearchParams(obj).toString()})
                }).catch((err) => {
                    toast.error(err?.response?.data?.message ?? 'Unable to Update Item.', { position: toast.POSITION.TOP_RIGHT });
                }).then(() => {
                    this.setState({ itemAddFormSubmitting: false });
                });
            } else {
                if (warehouseData) {
                    warehouseData.forEach(item => {
                        let combinationKey = item.warehouse + item.rate + item.batch_number + item.expiry_date;
                        if (stockValidation.includes(combinationKey)) {
                            stockValidationError = true;
                        }
                        return stockValidation.push(combinationKey);
                    })
                }

                if (stockValidationError) {
                    toast.error("Opening Stock should not contain dublicate values", { position: toast.POSITION.TOP_RIGHT });
                } else {
                   
                    axios({
                        method: 'POST',
                        headers: headers,
                        data: { ...this.state.addItemForm, opening_stock: warehouseData, additional_attributes: additional_attributes },
                        url: this.itemUrl
                    }).then((response) => {
                         toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.props.history.push('/item_list')
                    }).catch((err) => {
                        toast.error(err?.response?.data?.message ?? 'Unable to Add Item.', { position: toast.POSITION.TOP_RIGHT });
                    }).then(() => {
                        this.setState({ itemAddFormSubmitting: false });
                    });
                }

            }
        }
    }
     
    render() {
     
        return (
            <ApplicationLayout>
                <Helmet><title>Add Item</title></Helmet>
                <div className="page_title row m0">
                    <div className="col-12">
                        <h3>{this.state.editItem ? 'Update Item' : 'Add New Item'}</h3>
                        <div className="text-end mt15">
                            <button onClick={this.closeEvent} disabled={this.state.itemAddFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="page_containt row">
                        <div className="pageTbl col-12">
                            {this.state.itemFormDataLoaded ?
                                <form className="bg-white p-3" onSubmit={this.addItemFormSubmitEvent} id="addItemForm">
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Basic Details</span>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label require">Category</label></div>
                                        <div className="col-4">
                                            {
                                                this.state.formData && this.state.allCategory
                                                    ? (this.state.allCategory.map((cat, k) => {

                                                        return (
                                                            <div key={cat.index} className="form-check form-check-inline">
                                                                <input
                                                                    name="category"
                                                                    type="radio"
                                                                    value={cat.index}
                                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                                    checked={this.state.addItemForm.category == cat.index}
                                                                    className="form-check-input"
                                                                    required={true}
                                                                    id={cat.index}
                                                                />
                                                                <label className="form-check-label" htmlFor={cat.index}>{cat.item}</label>
                                                            </div>);
                                                    }))
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3">
                                            <label htmlFor="name" className="form-label require">Name</label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                name="name"
                                                type="text"
                                                value={this.state.addItemForm.name}
                                                onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                className="form-control"
                                                required={true}
                                                autoComplete="off"
                                                placeholder="Enter Item Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label htmlFor="item_code" className="form-label ">Item Code</label></div>
                                        <div className="col-4">
                                            <input
                                                name="item_code"
                                                type="text"
                                                value={this.state.addItemForm.item_code}
                                                onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Enter Item Code"
                                            />
                                        </div>
                                        <div className="col-5">
                                            <span className="form-text">An item code is a unique representation of a product or service provided by you to a customer.</span>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label htmlFor="description" className="form-label">Description</label></div>
                                        <div className="col-4">
                                            <textarea
                                                name="description"
                                                value={this.state.addItemForm.description}
                                                onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                className="form-control"
                                                style={{ height: "80px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Units of Measurement (UOM)</label></div>
                                        <div className="col-4">
                                            {this.state.addNewMeasuringUnit == "Y"
                                                ? <input
                                                    name="new_measuring_unit"
                                                    type="text"
                                                    value={this.state.addItemForm.new_measuring_unit}
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Enter Unit of Measurement"
                                                />
                                                : <TapSelect
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'measuring_unit_id')}
                                                    options={this.state.measuringUnits}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.measuringUnits.find(m => m.value == this.state.addItemForm.measuring_unit_id)}
                                                    placeholder="Please Select Units of Measurement"
                                                />}
                                        </div>
                                       {this.props.permissions.includes('isc-item-uom-add') && <div className="col-sm-5">
                                            <input
                                                name="addNewMeasuringUnit"
                                                type="checkbox"
                                                value={this.state.addNewMeasuringUnit}
                                                onChange={(e) => {

                                                    this.setState({
                                                        addNewMeasuringUnit: this.state.addNewMeasuringUnit == 'N' ? 'Y' : 'N',
                                                        addItemForm: this.state.addNewMeasuringUnit == 'N' ? { ...this.state.addItemForm, measuring_unit_id: '' } : { ...this.state.addItemForm, new_measuring_unit: '' }
                                                    })
                                                }}
                                                checked={this.state.addNewMeasuringUnit == 'Y'}
                                                className="form-check-input"
                                                id="addNewMeasuringUnit"

                                            />
                                            <label className="form-check-label mx-2 text-sm" htmlFor="addNewMeasuringUnit">Add New Unit of Measurement </label><br />
                                            {/* <span className="form-text">A unit of measurement defines in which units you count item in stock.</span> */}
                                        </div>}

                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Manufacturer</label></div>
                                        <div className="col-4">
                                            {this.state.addNewManufacturer == "Y"
                                                ? <input
                                                    name="new_manufacturer_name"
                                                    type="text"
                                                    value={this.state.addItemForm.new_manufacturer_name}
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Enter New Manufacturer"
                                                />
                                                : <TapSelect
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'manufacturer_id')}
                                                    options={this.state.manufacturers}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.manufacturers.find(m => m.value == this.state.addItemForm.manufacturer_id)}
                                                    placeholder="Please Select"
                                                />}
                                        </div>
                                        {this.props.permissions.includes('isc-item-manufacturer-add') && <div className="col-sm-5">
                                            <input
                                                name="addNewManufacturer"
                                                type="checkbox"
                                                value={this.state.addNewManufacturer}
                                                onChange={(e) => {

                                                    this.setState({
                                                        addNewManufacturer: this.state.addNewManufacturer == 'N' ? 'Y' : 'N',
                                                        addItemForm: this.state.addNewManufacturer == 'N' ? { ...this.state.addItemForm, manufacturer_id: '' } : { ...this.state.addItemForm, new_manufacturer_name: '' }
                                                    })
                                                }}
                                                checked={this.state.addNewManufacturer == 'Y'}
                                                className="form-check-input"
                                                id="addNewManufacturer"

                                            />
                                            <label className="form-check-label mx-2 text-sm" htmlFor="addNewManufacturer">Add New Manufacturer </label>
                                        </div>}

                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Manufacturer Part Number (MPN)</label></div>
                                        <div className="col-4">
                                            <input
                                                name="manufacturer_part_no"
                                                type="text"
                                                value={this.state.addItemForm.manufacturer_part_no}
                                                onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Manufacturer Part Number"
                                            />
                                        </div>
                                        <div className="col-5">
                                            <span className="form-text">MPN is a unique number that is issued by manufacturers to identify individual products.</span>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Tags</label></div>
                                        <div className="col-4">
                                            {this.state.addNewTags == "Y"
                                                ? <input
                                                    name="new_tags"
                                                    type="text"
                                                    value={this.state.addItemForm.new_tags}
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Enter Tag Name"
                                                />
                                                : <TapSelect
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'tags')}
                                                    options={this.state.itemTags}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.itemTags.filter(t => this.state.addItemForm.tags.includes(t.value))}
                                                    placeholder="Please Select Tag"
                                                    isMulti={true}
                                                />}
                                        </div>
                                        {this.props.permissions.includes('isc-item-tag-add') && <div className="col-sm-5">
                                            <input
                                                name="addNewTags"
                                                type="checkbox"
                                                value={this.state.addNewTags}
                                                onChange={(e) => {
                                                    this.setState({
                                                        addNewTags: this.state.addNewTags == 'N' ? 'Y' : 'N',
                                                        addItemForm: { ...this.state.addItemForm, tags: [] }
                                                    })
                                                }}
                                                checked={this.state.addNewTags == 'Y'}
                                                className="form-check-input"
                                                id="addNewTags"
                                            />
                                            <label className="form-check-label mx-2 text-sm" htmlFor="addNewTags">Add New Tags </label>
                                        </div>}
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">HSN / SAC Code</label></div>
                                        <div className="col-4">
                                            <input
                                                name="hsn_code"
                                                type="text"
                                                value={this.state.addItemForm.hsn_code}
                                                onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="HSN / SAC Code"
                                            />
                                        </div>
                                        <div className="col-5">
                                            <span className="form-text">Harmonized System of Nomenclature (HSN) / Services Accounting Code (SAC)</span>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Generate Scrap on Replacement</label></div>
                                        <div className="col-4">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="is_scrapble"
                                                    type="radio"
                                                    value="Y"
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    checked={this.state.addItemForm.is_scrapble === "Y"}
                                                    className="form-check-input"
                                                    required={true}
                                                    disabled={this.state.addItemForm.category == "services" ? true : false}
                                                    id="is_scrapble_y"
                                                />
                                                <label className="form-check-label" htmlFor="is_scrapble_y">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="is_scrapble"
                                                    type="radio"
                                                    value="N"
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    checked={this.state.addItemForm.is_scrapble === "N"}
                                                    className="form-check-input"
                                                    required={true}
                                                    disabled={this.state.addItemForm.category == "services" ? true : false}
                                                    id="is_scrapble_N"
                                                />
                                                <label className="form-check-label" htmlFor="is_scrapble_N">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Pricing</span>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Purchase Price</label></div>
                                        <div className="col-1">
                                            <TapSelect
                                                options={this.state.allCurrencyList}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'purchase_currency')}
                                                value={this.state.allCurrencyList.find(u => this.state.addItemForm?.purchase_currency == u.value)}
                                                isSearchable={true}
                                                placeholder="Select Currency"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <input
                                                name="purchase_price"
                                                type="number"
                                                step="0.01"

                                                value={this.state.addItemForm.purchase_price}
                                                onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Purchase Price"
                                                pattern="[0-9.]+"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Sale / Consumption Price</label></div>
                                        <div className="col-1">
                                            <TapSelect
                                                options={this.state.allCurrencyList}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'consumption_currency')}
                                                value={this.state.allCurrencyList.find(u => this.state.addItemForm?.consumption_currency == u.value)}
                                                isSearchable={true}
                                               
                                                placeholder="Select Currency"
                                            />
                                        </div>
                                        <div className="col-3">
                                            <input
                                                name="consumption_price"
                                                type="number"
                                                value={this.state.addItemForm.consumption_price}
                                                onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Sale / Consumption Price"

                                            />
                                        </div>
                                    </div>
                                    {this.state.addItemForm.is_scrapble == 'Y'
                                        ? <div className="row align-items-center mb-3 mt-3">
                                            <div className="col-3"><label className="form-label">Scrap Price</label></div>
                                            <div className="col-4">
                                                <input
                                                    name="scrap_price"
                                                    type="number"
                                                    value={this.state.addItemForm.scrap_price}
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Scrap Price"
                                                    pattern="[0-9.]+"
                                                />
                                            </div>
                                        </div>
                                        : null}
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Taxation</span>
                                    </div>
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-3"><label className="form-label">Tax applicable</label></div>
                                        <div className="col-4">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="is_taxable"
                                                    type="radio"
                                                    value="Y"
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    checked={this.state.addItemForm.is_taxable === "Y"}
                                                    className="form-check-input"
                                                    required={true}
                                                    id="is_taxable_y"
                                                />
                                                <label className="form-check-label" htmlFor="is_taxable_y">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name="is_taxable"
                                                    type="radio"
                                                    value="N"
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    checked={this.state.addItemForm.is_taxable === "N"}
                                                    className="form-check-input"
                                                    required={true}
                                                    id="is_taxable_N"
                                                />
                                                <label className="form-check-label" htmlFor="is_taxable_N">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.addItemForm.is_taxable == 'Y'
                                        ? (<Ax>
                                            <div className="row align-items-center mb-3 mt-3">
                                                <div className="col-3"><label className="form-label">GST</label></div>
                                                <div className="col-4">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'gst')}
                                                        options={this.state.itemGST}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={this.state.itemGST.find(g => g.value == this.state.addItemForm.gst)}
                                                        placeholder="Please Select GST"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-3 mt-3">
                                                <div className="col-3"><label className="form-label">IGST</label></div>
                                                <div className="col-4">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'igst')}
                                                        options={this.state.itemIGST}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={this.state.itemIGST.find(m => m.value == this.state.addItemForm.igst)}
                                                        placeholder="Please Select IGST"
                                                    />
                                                </div>
                                            </div>
                                            {this.state?.formData?.group_isc_configuration?.activation_date && this.state.editItem == false && this.state.addItemForm.category === "goods" ?
                                                (<Ax><div className="tab_content_wrapper">
                                                    <span className="content_heading">Opening Stock</span>
                                                </div>
                                                    <div className="row align-items-center mb-3 mt-3">
                                                        <div className="col-6"><label className="form-label">
                                                            Date of Activation(Opening Balance) as of {moment(this.state?.formData?.group_isc_configuration?.activation_date).format('DD-MMM-YYYY')}</label></div>

                                                    </div>
                                                    <div className="row align-items-center my-3">
                                                        <div className="col-12">
                                                            <table className="table table-bordered table-responsive bg-white">
                                                                <thead className="table-secondary">
                                                                    <tr>
                                                                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                                                                        <th scope="col" className="text-start" style={{ width: "25%" }}>Warehouse</th>
                                                                        <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                                                                        <th scope="col" className="text-center" style={{ width: "15%" }}>Unit Rate</th>
                                                                        <th scope="col" className="text-center" style={{ width: "15%" }}>Amount</th>
                                                                        {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? (<Ax>
                                                                            <th scope="col" className="text-center" style={{ width: "15%" }}>Batch Number</th>
                                                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Expiry Date</th>
                                                                        </Ax>) : null}
                                                                        <th scope="col" className="" style={{ width: "5%" }}></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.openingBalanceWarehouseRows.map((i, k) => {
                                                                        let tdRows = (i.warehouse) ? (<Ax>
                                                                            <td className="text-start">
                                                                                <span className="fw-bold">{i.warehouse.label}</span>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    name="qty"
                                                                                    type="number"
                                                                                    value={this.state.openingBalanceWarehouseRows[k].qty}
                                                                                    onChange={(e) => this.onWarehouseInputChange(e, 'qty', k)}
                                                                                    className="form-control text-end"
                                                                                    autoComplete="off"
                                                                                    placeholder="Qty"
                                                                                    required={true}
                                                                                    min={1}
                                                                                    step=".01"
                                                                                />

                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    name="rate"
                                                                                    type="number"
                                                                                    value={this.state.openingBalanceWarehouseRows[k].rate}
                                                                                    onChange={(e) => this.onWarehouseInputChange(e, 'rate', k)}
                                                                                    className="form-control text-end"
                                                                                    autoComplete="off"
                                                                                    placeholder="Unit Rate"
                                                                                    required={true}
                                                                                    min={1}
                                                                                    step=".01"
                                                                                />
                                                                            </td>
                                                                            <td className="text-end">
                                                                                {(this.state.openingBalanceWarehouseRows[k].qty * this.state.openingBalanceWarehouseRows[k].rate).toFixed(2)}
                                                                            </td>
                                                                            {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? (<Ax>
                                                                                <td className='text-start'>
                                                                                    <input
                                                                                        name="batch_number"
                                                                                        type="text"
                                                                                        value={this.state.openingBalanceWarehouseRows[k].batch_number}
                                                                                        onChange={(e) => this.onWarehouseInputChange(e, 'batch_number', k)}
                                                                                        className="form-control text-end"
                                                                                        autoComplete="off"
                                                                                        placeholder="Batch Number"
                                                                                    />
                                                                                </td>
                                                                                <td>  <DatePicker
                                                                                    selected={
                                                                                        this.state.openingBalanceWarehouseRows[k].expiry_date
                                                                                            ? moment(this.state.openingBalanceWarehouseRows[k].expiry_date).toDate()
                                                                                            : false
                                                                                    }
                                                                                    name="expiry_date"
                                                                                    onChange={(selectedOption, e) => this.onWarehouseInputChange(e, 'expiry_date', k, selectedOption)}
                                                                                    dateFormat="dd-MMM-yyyy"
                                                                                    className={"form-control"}
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    autoComplete="off"
                                                                                    scrollMonthYearDropdown
                                                                                    placeholderText={`Please Enter Date`}

                                                                                /></td>
                                                                            </Ax>) : null}
                                                                        </Ax>)
                                                                            : (<Ax>
                                                                                <td className='text-start'>
                                                                                    <TapSelect
                                                                                        options={this.state.warehouseList}
                                                                                        isSearchable={true}
                                                                                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addItemForm', 'warehouse'); this.onWarehouseChange(selectedOption, k) }}
                                                                                        placeholder={"Search Warehouse"}
                                                                                    />
                                                                                </td>
                                                                                {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? <td colSpan="5" className="text-center align-middle" >-</td>
                                                                                    : <td colSpan="3" className="text-center align-middle" >-</td>}
                                                                            </Ax>);
                                                                        return (<tr className="text-center align-middle" key={k}>
                                                                            <td className="text-center align-middle">{k + 1}</td>
                                                                            {tdRows}
                                                                            <td className="text-center align-middle">
                                                                                <a href="#" onClick={() => this.removeWarehouseRow(k)}>
                                                                                    <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                                                                </a>
                                                                            </td>
                                                                        </tr>);
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="col-12 text-end">
                                                            <a role="button" className="link-primary" onClick={() => this.addWarehouseRow()}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                                                        </div>
                                                    </div> </Ax>)
                                                : null}
                                        </Ax>)
                                        : null
                                    }
                                    {this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0 && <AdditionalAttributeForm
                                        additionalAttributes={this.state.formData.additional_attributes}
                                        formState={this.state.addItemForm}
                                        onAttributeChange={(value, attributeName) => {
                                            this.setState(() => ({
                                                addItemForm: {
                                                    ...this.state.addItemForm,
                                                    [attributeName]: value
                                                }
                                            }))
                                        }
                                        }
                                    />}
                                    {/* {
                                        this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0
                                            ? (
                                                <Ax>
                                                    <div className="tab_content_wrapper"><span className="content_heading">Additional Attributes</span></div>
                                                    {
                                                        this.state.formData.additional_attributes.map((i, k) => {
                                                            let name = 'additional_attributes_' + i.key;
                                                            let input = null;
                                                            if (i.type == 'text') {
                                                                input = <input
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.addItemForm[name] ? this.state.addItemForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    value={this.state.addItemForm[name] ? this.state.addItemForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                                    className="form-control"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.addItemForm[name]
                                                                            ? moment(this.state.addItemForm[name]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addItemForm')}
                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'dropdown') {
                                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', name)}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    value={options.find(o => o.value == this.state.addItemForm[name])}
                                                                    placeholder={`Please Select ${i.name}`}
                                                                />
                                                            }

                                                            return (<div key={k} className="row align-items-center mb-3 mt-3">
                                                                <div className="col-3"><label className="form-label">{i.name}</label></div>
                                                                <div className="col-4">{input}</div>
                                                                <div className="col-5">
                                                                    <span className="form-text"></span>
                                                                </div>
                                                            </div>);
                                                        })
                                                    }
                                                </Ax>
                                            )
                                            : null
                                    } */}
                                    <hr />
                                    <div className="col-12 text-end">
                                        <button id="addItemCancelButton" type="button" disabled={this.state.itemAddFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary">Cancel</button>
                                        <button id="addItemSubmitButton" type="submit" disabled={this.state.itemAddFormSubmitting ? true : false} className="btn btn-primary mx-3">
                                            Submit {this.state.itemAddFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                        </button>
                                    </div>
                                </form>
                                : <Loader />}
                        </div>
                    </div>
                </div>
            </ApplicationLayout>
        );
    }
}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(ItemAdd);