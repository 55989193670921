import React from 'react';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import {Link} from "react-router-dom";

class AssetHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }



    render() {
        return (
            <Ax>
                <div className="row asset">
                    <div className="col-5 pl0 ml0"><h3 className="mx-5">{this.props.assetName}</h3></div>
                    <div className=" col-7 text-end mt15">
                        <Link to="/asset_counterlog"> <button type="button" className="btn btn-primary">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faTachometerAlt} className="img-fluid mr3" alt="item-action"/>
                            Counter Log</button></Link>
                        <button type="button" className="btn btn-secondary"
                                data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faPlay} className="img-fluid mr3"/>Actions</button>
                        <ul className="dropdown-menu">
                            {/*<li className="dropdown-item">Log Expenses</li>*/}
                            <li><Link to="/asset_service_checksheet" className="dropdown-item">Service Checksheet</Link></li>

                        </ul>
                        <button type="button" className="btn btn-secondary"  data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faHistory} className="img-fluid mr3" alt="item-action"/>
                            History</button>
                        <ul className="dropdown-menu">
                            <li><Link to="/asset_activity" className="dropdown-item">Asset Activity</Link></li>
                            <li><Link to="/feedin_list" className="dropdown-item">Feed-In</Link></li>
                            <li><Link to="/service_checksheet_history" className="dropdown-item">Service Checksheet</Link></li>
                            <li><Link to="/quickticket" className="dropdown-item">Ticket</Link></li>
                        </ul>
                        <button type="button" className="btn btn-secondary">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faFileExcel} className="img-fluid mr3" alt="item-action"/>
                            Report</button>
                        <button  type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/></button>
                        <button onClick={this.props.close} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/></button>
                    </div>
                </div>
            </Ax>
        )
    }
}





export default AssetHeader;