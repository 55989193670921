import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import tapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import * as actions from '../../../store/actions/index';
import InputUserSearch from '../../includes/ui/InputUserSearch';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import { Helmet } from 'react-helmet';

class SiteAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initSiteForm               =          {
            name                        :          '',
            code                        :          '',
            user_ids                    :          [],
            link_all_users              :          'N'
        }

        this.state                      =   {
            access_token                :   '',
            addSiteForm                 :         {...this.initSiteForm},
            saveFormSubmitting          :         false,
            siteDetail                  :          null,
            additional_attributes       :       [],
            form_data_loading           :       false,
        }
       
    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.addSiteModal              =       new Modal(document.getElementById('addSiteModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    addSiteInit         =   (data = null)  =>  {
        this.addSiteModal.show()
         this.setState({ addSiteForm : { ...this.initSiteForm },siteDetail : null },() => {
            this.getSiteFormData()
            if(data){
                let updateFormState             =   [];
                if(data.additional_attribute_data && data.additional_attribute_data.length > 0) {
                    data.additional_attribute_data.map((dt) => {
                        let name                        =   `additional_attributes_${dt.key}`;
                        updateFormState[name]           =   dt.value
                    });
                }
                this.setState({
                    addSiteForm : {
                        id      :       data.id,
                        name    :       data.site_name,
                        code    :       data.site_code,
                        ...updateFormState
                    },
                    siteDetail  :       data
                })
            }
        })
    }

    getSiteFormData         =       ()      =>      {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/site/formdata',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
           
            this.setState({
                additional_attributes   :   respData && respData.additional_attributes && respData.additional_attributes.length > 0 ? respData.additional_attributes  : [], 
             })
        }).then(() => this.setState({form_data_loading: false}));
    }
   
    submitAddSiteForm        =       (e)      =>  {
        e.preventDefault();
        let additional_attributes = {};
        if(this.state.additional_attributes && this.state.additional_attributes.length > 0){
            this.state.additional_attributes.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addSiteForm[name] ? this.state.addSiteForm[name] : '';
            });
        }
      
        let frmData     =       {
            ...this.state.addSiteForm,
            user_ids                :       this.state.addSiteForm.user_ids && this.state.addSiteForm.user_ids.length > 0 ? this.state.addSiteForm.user_ids.map(st => st.value) : [],
            additional_attributes   :       additional_attributes
        }
        
        if(this.state.siteDetail){
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/site/edit', this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addSiteModal.hide()
                if(this.props.refreshList){
                   this.props.refreshList()
                }
                this.setState({ addSiteForm : { ...this.initSiteForm } })
                this.props.applicationInitLoading(this.props.access_token);
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }else{
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/site/add', this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addSiteModal.hide()
                if(this.props.refreshList){
                    this.props.refreshList()
                 }
                this.setState({ addSiteForm : { ...this.initSiteForm } })
                this.props.applicationInitLoading(this.props.access_token);
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
       
    }

    handleAttributeChange = (value, attributeName) => {
      this.setState(prevState => ({
            addSiteForm: {
                ...this.state.addSiteForm,
                [attributeName]: value
            }
        }));
    }

    addSiteModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="addSiteModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">{this.state.siteDetail ? "Edit" : "Add"} Site</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="addSiteForm" onSubmit={this.submitAddSiteForm}>
                            <div className='modal-body'>
                                {this.state.form_data_loading ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center mb-3">
                                            <label className="col-sm-3 col-form-label col-form-label-sm require">Site Name</label>
                                            <div className="col-sm-7">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    value={this.state.addSiteForm.name}
                                                    onChange={(e) => this.formInputHandler(e, 'addSiteForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Enter Site Name"
                                                    required={true}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>

                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <label className="col-sm-3 col-form-label col-form-label-sm require">Site Code</label>
                                            <div className="col-sm-7">
                                                <input
                                                    name="code"
                                                    type="text"
                                                    value={this.state.addSiteForm.code}
                                                    onChange={(e) => this.formInputHandler(e, 'addSiteForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Enter Site Code"
                                                    required={true}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>
                                       {!this.state.siteDetail && <div className="row align-items-center my-3">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">Link Users</label>
                                            <div className="col-sm-7">
                                                <InputUserSearch
                                                    changeEvent={(selectedOption) => {
                                                        this.setState({
                                                            addSiteForm: { ...this.state.addSiteForm, user_ids: selectedOption.length > 0 ? selectedOption : [] },
                                                        });
                                                    }}
                                                    isMulti={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    disabled={this.state.addSiteForm.link_all_users == 'Y'}
                                                    value={this.state.addSiteForm && this.state.addSiteForm.user_ids && this.state.addSiteForm.user_ids.length > 0 ? this.state.addSiteForm.user_ids.map(a => { a.label = a.label; return a; }) : []}
                                                />
                                            </div>
                                            <div className='col-sm-2'>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        name="link_all_users"
                                                        type="checkbox"
                                                        value={this.state.addSiteForm.link_all_users}
                                                        onChange={(e) => {
                                                            if (e.target.value && e.target.value == 'N') {
                                                                this.setState({
                                                                    addSiteForm: { ...this.state.addSiteForm, user_ids: [], link_all_users: 'Y' },
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    addSiteForm: { ...this.state.addSiteForm, link_all_users: 'N' },
                                                                })
                                                            }

                                                        }}
                                                        className="form-check-input"
                                                        id="link_all_users"
                                                        checked={this.state.addSiteForm.link_all_users == 'Y'}
                                                    />
                                                    <label className="form-check-label" htmlFor="link_all_users">All Users</label>
                                                </div>
                                            </div>

                                        </div>}
                                        <AdditionalAttributeForm
                    additionalAttributes={this.state.additional_attributes}
                    formState={this.state.addSiteForm}
                    onAttributeChange={this.handleAttributeChange} 
                />
                                        {/* {this.state.additional_attributes && this.state.additional_attributes.length > 0
                                            ? (
                                                <Ax>

                                                    {
                                                        this.state.additional_attributes.map((i, k) => {
                                                            let name = 'additional_attributes_' + i.key;

                                                            let input = null;
                                                            if (i.type == 'text') {
                                                                input = <input
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.addSiteForm[name] ? this.state.addSiteForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addSiteForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    value={this.state.addSiteForm[name] ? this.state.addSiteForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addSiteForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.addSiteForm[name]
                                                                            ? moment(this.state.addSiteForm[name]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addSiteForm')}
                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'dropdown') {
                                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addSiteForm', name)}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    value={options.find(o => o.value == this.state.addSiteForm[name])}
                                                                    placeholder={`Please Select ${i.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                />
                                                            }

                                                            return (<div key={k} className="row align-items-center mb-3">
                                                                <label className="col-sm-3 col-form-label col-form-label-sm">{i.name}</label>
                                                                <div className="col-sm-7">{input}</div>

                                                            </div>);
                                                        })
                                                    }
                                                </Ax>
                                            )
                                            : null
                                        } */}

                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting}>Submit {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    
    render                              =   ()  =>  this.addSiteModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token,false)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SiteAdd);