import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import TapSelect from "../../../../components/ui/TapSelect";
import {toast} from "react-toastify";
import React from "react";
import swal from "sweetalert";
import { IAM_API_BASE_URL_2 } from "../../../../services/TapApiUrls";

class Workflow extends AppBaseComponent {

    constructor(props) {
        super(props);

          this.initTicketStatusFormData        =           {
              status                           :           "",
              role_ids                         :           [],
          }
        this.state                              =         {
            loading                             :          false,
            status_listing                      :          [],
            editFormLoading                     :          false,
            editFormSubmiting                   :          false,
            allRoles                            :          [],
            ticketStatusFormData                :          {...this.initTicketStatusFormData}
           /* sitesLinkedUsersData               :          [],
           ,*/
        };

        this.ticketStatusUrl    =   IAM_API_BASE_URL_2 + '/ticket/status';
        this.ticketRoleStatusUrl=   IAM_API_BASE_URL_2 + '/ticket/role_status';

    }

    componentDidMount() {
        this.getStatusListing()
        this.editTicketStatusModal              =        new Modal(document.getElementById('editTicketStatusModal'), {keyboard: false, backdrop :false});

    }

    //functions for tab auto-Linking
    getStatusListing       =      ()   =>     {
        this.setState({loading : true})
        HttpAPICall.withAthorization('GET', this.ticketStatusUrl, this.props.access_token , {}, {}, (resp) => {
            this.setState({status_listing  : resp.data})
        }).then(() => this.setState({loading: false}));
    }

    editTicketStatus       =       (key)    =>     {
        let ticketStatusData            =   this.state.status_listing.find(s => s.key == key);
        if(ticketStatusData) {
            this.getTicketStatusFormData(ticketStatusData);
        }
    }

    getTicketStatusFormData         =      (ticketStatusData)       => {
        this.editTicketStatusModal.show();
        this.setState({
            editFormLoading             :   true,
            roles                       :   [],
            ticketStatusFormData         :   {
                ...this.state.ticketStatusFormData,
                status                   :   ticketStatusData.name,
                status_key               :   ticketStatusData.key,
                role_ids               :   ticketStatusData.roles.map(u => u.id)
            }
        });
        HttpAPICall.withAthorization('GET', this.ticketStatusUrl + '/form_data', this.props.access_token , {}, {}, (response) => {
            let roles               =   response.data.roles.map(u => {
                return { value: u.id, label: u.role};
            });
            this.setState({ allRoles : roles});
        }, (error) => {

        }).then(() => {
            this.setState({editFormLoading : false});
        });
    }

    ticketStatusFormModalJsx      =         ()      =>   {
        let ticketStatusFormData               =   this.state.ticketStatusFormData;
        return(<div className="modal fade" id="editTicketStatusModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addAssetModalLabel">Edit Ticket Status</h5>
                    </div>
                    <form onSubmit={this.submitUpdateTicketStatus}>
                        {this.state.editFormLoading
                            ? <Loader />
                            : (<Ax>
                        <div className="modal-body">
                            <div className="row my-3 align-items-center">
                                <div className="col-sm-3 text-end">
                                    <label className="col-form-label require">Status Name</label>
                                </div>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control" disabled={true} value={ticketStatusFormData ? ticketStatusFormData.status : ''} />
                                </div>
                            </div>
                            <div className="row my-3 align-items-center">
                                <div className="col-sm-3 text-end">
                                    <label className="col-form-label require">Roles</label>
                                </div>
                                <div className="col-sm-5">
                                    <TapSelect
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        placeholder="Select Roles"
                                        options={this.state.allRoles}
                                        value={this.state.allRoles.filter(su => ticketStatusFormData.role_ids.includes(su.value))}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketStatusFormData', 'role_ids')}
                                    />
                                </div>
                                <div className="col-sm-4 form-text">
                                    Only selected roles can change the status of Ticket to this status
                                </div>
                            </div>
                        </div>
                            </Ax>)}
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.editFormSubmiting} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" disabled={this.state.editFormSubmiting} className="btn btn-primary">Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : null}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>)
    }

    submitUpdateTicketStatus      =       (e)      =>    {
        e.preventDefault()
        this.setState({editFormSubmiting : true});
        let frmData = {
         status : this.state.ticketStatusFormData.status_key,
         role_ids : this.state.ticketStatusFormData.role_ids
        }
        HttpAPICall.withAthorization('POST', this.ticketRoleStatusUrl, this.props.access_token, {} , {...frmData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editTicketStatusModal.hide();
            this.getStatusListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    changeDefault                =    (key)  =>     {
        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/ticket/configuration/default_status' , this.props.access_token, {},{status : key} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getStatusListing()
                    }).then(() => {

                    });
                }
            });
    }


    render                                  =   ()  =>  {
        return (<Ax>
            <div className="tab_content_wrapper mt-2 mb-2"><span className="content_heading">Status for Ticket</span></div>
            <table className="table table-hover table-bordered my-3 ">
                <thead>
                <tr className="table-secondary">
                    <th scope="col" style={{width: "16%"}}>Status</th>
                    <th scope="col">Roles</th>
                    <th scope="col" style={{width: "15%"}}  className="text-center" >Default Status</th>
                    <th scope="col" style={{width: "10%"}}  className="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                { this.state.loading
                    ? (<tr><td colSpan="5"><Loader/></td></tr>)
                    : (this.state.status_listing.length > 0
                            ? (this.state.status_listing.map((s , k) => {
                                return (<tr key={k}>
                                    <td scope="row">{s.name}</td>
                                    <td scope="row" className="text-capitalize">{s.roles.length > 0 ? s.roles.map(r => r.role).join(", ") : "-"}</td>
                                    <td scope="row" className="text-center">
                                        {s.is_default == "Y"
                                            ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} />
                                            : ""}
                                    </td>
                                    <td scope="row" className="text-center">
                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"/>
                                        <ul className="dropdown-menu" style={{cursor:"pointer"}}>
                                            <li className="dropdown-item" onClick={() => this.editTicketStatus(s.key)}>
                                                Edit
                                            </li>
                                            {s.can_default == "Y" ? <li>
                                                <a role="button" className={s.is_default == "Y" ? "disabled dropdown-item" : "dropdown-item"} onClick={() => this.changeDefault(s.key)}>Set as Default</a>
                                            </li> : null}
                                        </ul>
                                    </td>
                                </tr>);
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                    )
                }
                </tbody>
            </table>
            {this.ticketStatusFormModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(Workflow);