import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ORG_API_BASE_URL_2, SCHEDULE_API_BASE_URL_2 } from '../../services/TapApiUrls';
import ApplicationLayout from "../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Loader from '../../components/ui/Loader/Loader';
import Ax from '../../components/hoc/Ax';
import reportStyles from "./Report.module.css"
import HttpAPICall from '../../services/HttpAPICall';
import * as actions from "../../store/actions/index"
import ReportMenu from './includes/ReportMenu';
import ReportHeader from './includes/ReportHeader';
import { DisplayListPagination } from '../../components/TapUi';
import ReportSchedulerModal from './includes/ReportSchedulerModal';
import TapIcon from '../../services/TapIcon';


const ScheduleReport                    =   forwardRef((props, ref) => {

    const dispatch                          =   useDispatch();
    const access_token                      =   useSelector(state => state.auth.access_token);
    const reportSchedularModalRef           =   useRef(null);

    let initListData                        =   {
        loading                                 :   false,
        items                                   :   [],
        meta                                    :   null,
        page                                    :   1,
    };

    const [itemData, setItemData]           =   useState(initListData);


    let itemListUrl                         =   SCHEDULE_API_BASE_URL_2 + "/notification/list"; 

    const loadItemsData             =   (page = 1)  =>  {
        setItemData(pd => ({...pd, loading : true, page : page }));
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, {page:page}, {}, (response) => {
            let respData                =   response.data;
            let meta                    =   {
                from                        :   respData.from,
                to                          :   respData.to,
                total                       :   respData.total,
                last_page                   :   respData.last_page,
                per_page                    :   respData.per_page,
                current_page                :   respData.current_page
            };
            setItemData(pd => ({...pd, items : respData.data, meta : meta }));
        }).then(() => {
            setItemData(pd => ({...pd, loading : false}));
        });
    }

    useEffect(() => {
        loadItemsData();
    }, []);

    const reportScheduleHandler         =   (report_key) => {
        if (reportSchedularModalRef.current) {
            reportSchedularModalRef.current.initModalHandler(report_key);
        }
    }

    const itemListTableJsx        =   ()  =>  {
        return <>
            <table className="table table-bordered align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr className='text-center'>
                        <th style={{ width: "5%" }}>S.No </th>
                        <th style={{ width: "15%" }} className="text-center">Report Name</th>
                        <th style={{ width: "15%" }} className="text-center">Notification Type </th>
                        <th style={{ width: "15%" }} className="text-center">Criteria</th>
                        <th style={{ width: "10%" }} className="text-center">Frequency </th>
                        <th style={{ width: "30%" }} className="text-center">Date & Time </th>
                        <th style={{ width: "10%" }} className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {itemData.loading 
                    ?   <tr><td className='text-center' colSpan={7}><Loader /></td></tr>
                    :   (itemData.items && itemData.items.length > 0 
                        ?   itemData.items.map((item,key) => <tr key={key}>
                                <td>{itemData.meta ? itemData.meta.from + key : key }</td>
                                <td>{item.report ? item.report.name : ''}</td>
                                <td>{item.schedule_data ?.types.join(', ')}</td>
                                <td>-</td>
                                <td>{item.schedule_data ?.frequency}</td>
                                <td>
                                    <div>{item.schedule_data.notification_time}</div>
                                    <div className='text-xs fst-italic'>({item.schedule_data.frequency_data_diaplay})</div>
                                </td>
                                <td>
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                        <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><button onClick={() => {
                                            reportScheduleHandler(item.report.key)
                                        }} className="btn-link dropdown-item" role="button">Manage</button></li>
                                   </ul>
                                </td>
                            </tr>)
                        :   <tr><td className='text-center' colSpan={7}>No Record</td></tr>
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination meta={itemData.meta} onPageChange={(e) => loadItemsData(e.selected + 1)} /> 
        </>
    }

    return  <ApplicationLayout>
        <ReportHeader title="Scheduled Request - Report" name="Reports" />
        <div className="container-fluid">
            <div className='row mt-1 px5'>
                <div className='col-sm-12 bg-white px-0'>
                    <ReportMenu page="schedule_report" />
                    <div className="tab_content_header"> { itemListTableJsx()}</div>
                </div>
            </div>
        </div>
        <ReportSchedulerModal ref={reportSchedularModalRef} afterSucess={() => {
            loadItemsData()
        }} />
    </ApplicationLayout>

});

export default ScheduleReport;