import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import TapIcon from '../../services/TapIcon';
import Loader from '../../components/ui/Loader/Loader';
import { Modal } from "bootstrap";
import { Link } from 'react-router-dom';
import uuid from "react-uuid";
import Ax from '../../components/hoc/Ax';

class TagComponent extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.tagInitForm            =   {
            name                        :   '',
            type                        :   '',
            id                          :   ''
        }
        this.state                  =   {
            edit                        :   false,
            listing_loading             :   false,
            tags                        :   [],
            tagForm                     :   {...this.tagInitForm},
            submittingForm              :   false,
        }
        this.TagUrlList             =   IAM_API_BASE_URL_2 + '/' + 'sub_assembly_tag/list';
        this.TagUrlAdd              =   IAM_API_BASE_URL_2 + '/' + 'sub_assembly_tag/add';
        this.TagUrlEdit             =   IAM_API_BASE_URL_2 + '/' + 'sub_assembly_tag/edit';
        this.TagUrlDelete           =   IAM_API_BASE_URL_2 + '/' + 'sub_assembly_tag/delete/__id__';
        this.tagModalId             =   uuid();
    }

    componentDidMount() {
        this.initTagApp();
        this.tagModal               =   new Modal(document.getElementById(this.tagModalId), { keyboard: false, backdrop: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.type !== this.props.type) {
            this.initTagApp()
        }
    }

    initTagApp                  =   (props)  =>  {
        this.getTags();
    }

    getTags                     =   () => {
        this.setState({ listing_loading: true, tags : [] })
        HttpAPICall.withAthorization('GET', this.TagUrlList, this.props.access_token, { type: this.props.type }, {}, (resp) => {
            this.setState({ tags: resp.data });
        }).then(() => this.setState({ listing_loading: false }));
    }

    addTagModalInit             =   () => {
        this.tagModal.show();
        this.setState({tagForm : {...this.tagInitForm, type : this.props.type}});
    }

    editModelInit               =   (tag_id)  =>  {
        let tag                     =   this.state.tags.find(t => t.id == tag_id);
        if(tag) {
            this.setState({tagForm : {...this.tagInitForm, id : tag.id, name : tag.name, type : tag.type}});
            this.tagModal.show();
        }
    }

    submitForm                  =   (e) => {
        e.preventDefault();
        this.setState({ submittingForm: true });
        if(this.state.tagForm.id == '') {
            HttpAPICall.withAthorization('POST', this.TagUrlAdd, this.props.access_token, {}, this.state.tagForm, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.tagModal.hide();
                this.getTags();
            }).then(() => this.setState({ submittingForm: false }));
        } else {
            HttpAPICall.withAthorization('PUT', this.TagUrlEdit, this.props.access_token, {}, this.state.tagForm, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.tagModal.hide();
                this.getTags();
            }).then(() => this.setState({ submittingForm: false }));
        }
    }

    tagFormModalJsx             =   () => {
        return <div className="modal fade" id={this.tagModalId} tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.state.edit ? "Edit" : "Add"} Tag</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={this.state.submittingForm} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submitForm} id={this.tagModalId+"Form"} method="post">
                            <div className="row align-items-center my-3">
                                <div className="col-sm-3">
                                    <label className="form-label require">Tag Name</label>
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => { this.formInputHandler(e, 'tagForm') }}
                                        required={true}
                                        value={this.state.tagForm.name}
                                        placeholder={`Please Enter Tag Name`}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" disabled={this.state.submittingForm} data-bs-dismiss="modal">Close</button>
                        <button type="submit" form={this.tagModalId+"Form"} disabled={this.state.submittingForm} className="btn btn-primary">Save {this.state.submittingForm && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    deleteTag                   = (tag_id) => {
        let tag                     =   this.state.tags.find(t => t.id == tag_id);
        if(tag) {
            swal({
                title: "DELETE",
                text: "This action cannot be reversed, Are you sure you want to proceed?",
                icon: "warning",
                dangerMode: true,
                buttons: ["No", "Yes"],
            }).then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.TagUrlDelete.replace('__id__',tag_id), this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getTags()
                    }).then(() => {});
                }
            });
        }
    }

    render                      = () => {
        return <Ax>
            <div className="tab_content_wrapper my-2">
                <span className="content_heading">Tags</span>
                <button className="btn btn-primary" onClick={this.addTagModalInit}>Add</button>
            </div>
            <table className="table table-hover table-bordered my-3 table-sm ">
                <thead className="table-secondary">
                    <tr>
                        <th scope="col" style={{ width: "10%" }} className="text-center">S.No</th>
                        <th scope="col" className="text-start">Name</th>
                        <th scope="col" style={{ width: "10%" }} className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading && <tr><td colSpan="3"><Loader /></td></tr>}
                    {this.state.tags.map((item, k) => <tr key={k}>
                        <td className="text-center" >{k + 1}</td>
                        <td className="text-capitalize ">{item.name}</td>
                        <td className="text-center">
                            <button type="button" className="btn btn-link" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="action-icon" />
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link role="button" className='dropdown-item cursor_pointer' style={{ "pointerEvents": "all" }} onClick={() => this.editModelInit(item.id)}>Edit</Link>
                                    <Link role="button" className='dropdown-item cursor_pointer' style={{ "pointerEvents": "all" }} onClick={() => this.deleteTag(item.id)}>Delete</Link>
                                </li>
                            </ul>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            {this.tagFormModalJsx()}
        </Ax>
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(TagComponent);