import React from "react";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { Helmet } from "react-helmet";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import moment from "moment";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";
import Status from "../../../components/ui/Status";
import { Link } from "react-router-dom";
import EmployeeDocument from "./EmployeeDocument";
import DatePicker from "react-datepicker";
import TapIcon from "../../../services/TapIcon";
import DateService from "../../../services/DateService";
import EmployeeWorkingModal from "./EmployeeWorkingModal";
import EmployeeEducationDetailList from "./EmployeeEducationDetailList";
import EmployeeCurrentWorkingModal from "./EmployeeCurrentWorkingModal";

class EmployeeWorkingDetailList extends AppBaseComponent {
  constructor() {
    super();

    this.state = {
      emp_id: "",
      list_loading: false,
      viewDataLoading: false,
      pre_working_list: [],
      employeeData: null,
    };

    this.workingModalRef = React.createRef();
    this.currentWorkingModalRef = React.createRef();

    this.emp_working_url = HRM_API_BASE_URL_2 + '/employee_working/:replace_id'; 
    this.emp_deatail_url = HRM_API_BASE_URL_2 + '/employee/detail/:replace_id';
    this.emp_working_delete_url = HRM_API_BASE_URL_2 + '/employee_working/delete/:replace_id';     

  }

  componentDidMount() {
    this.initialDataLoading(this.props);
  }

  initialDataLoading = (pr) => {
    this.setState({ emp_id: pr.emp_id });
    this.currentWorkingDetail(pr.emp_id);
    this.preWorkingList(pr.emp_id);
  };

  preWorkingList = (emp_id) => {
    this.setState({ list_loading: true });
    HttpAPICall.withAthorization(
      "GET",this.emp_working_url.replace(':replace_id', emp_id), this.props.access_token,
      {}, {}, (response) => {
        this.setState({
          pre_working_list: response.data,
        });
      }
    ).then(() => this.setState({ list_loading: false }));
  };

  currentWorkingDetail = (id) => {
    this.setState({ viewDataLoading: true });
    HttpAPICall.withAthorization(
      "GET", this.emp_deatail_url.replace(':replace_id', id), this.state.access_token
        ? this.state.access_token : this.props.access_token,
      { currentDetail: "currentDetail" },{}, (resp) => {
        let respData = resp.data;
        this.setState({
          employeeData: respData.data,
        });
      }
    ).then(() => this.setState({ viewDataLoading: false }));
  };

  handleWorkingDetail = (type, id = null) => {
    this.workingModalRef.current.modalHandle(type, id);
  };

  deletePreDetail = (id) => {
    swal({
      title: "Delete",
      text: "This action cannot be reversed, Are you sure you want to proceed?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Yes"],
    }).then((willDelete) => {
      if (willDelete) {
        HttpAPICall.withAthorization("DELETE", this.emp_working_delete_url.replace(':replace_id', id), this.props.access_token, {}, {}, (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.preWorkingList(this.state.emp_id);
          }
        );
      }
    });
  };

  handleCurrentWorking = () => {
    this.currentWorkingModalRef.current.modalHandle();
  };

  render() {
    const employeeData = this.state.employeeData;
    return (
      <Ax>
        <div className="tab_content_wrapper mt-2">
          <span className="content_heading">Current Working Details</span>
          <button
            type="button"
            onClick={() => this.handleCurrentWorking()}
            className="btn btn-light btn-sm shadow-sm"
          >
            <TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} alt="item-action" />
          </button>
        </div>
        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
          <tbody>
            {this.state.viewDataLoading ? (
              <tr className="text-center">
                <td colSpan={2}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Employee Code
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.employee_code
                      ? employeeData.employee_code
                      : "-"}
                  </td>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Employee Card Number
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.emp_card_num
                      ? employeeData.emp_card_num
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Corporate Email
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.corporate_email
                      ? employeeData.corporate_email
                      : "-"}
                  </td>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Date of Join
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.doj
                      ? DateService.dateTimeFormat(
                          employeeData.doj,
                          "DD-MMM-YYYY"
                        )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Probation Period
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.probation_period
                      ? DateService.dateTimeFormat(
                          employeeData.probation_period,
                          "DD-MMM-YYYY"
                        )
                      : "-"}
                  </td>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Reporting Manager
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.reporting_manager
                      ? employeeData.reporting_manager.display_full_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Employee Type
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.employee_type
                      ? employeeData.employee_type.name
                      : "-"}
                  </td>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Designation
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.designation
                      ? employeeData.designation.name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Department
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.division
                      ? employeeData.division.name
                      : "-"}
                  </td>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Work Phone Number
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData && employeeData.work_phone_number
                      ? employeeData.work_phone_number
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Tags
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData &&
                    employeeData.tags &&
                    employeeData.tags.length > 0
                      ? employeeData.tags.map((tag) => tag).join(", ")
                      : "-"}
                  </td>
                  <td className="fs14 fw-normal" style={{ width: "25%" }}>
                    Asset Type
                  </td>
                  <td className="fs14 fw500" style={{ width: "25%" }}>
                    {employeeData &&
                    employeeData.asset_types &&
                    employeeData.asset_types.length > 0
                      ? employeeData.asset_types
                          .map((at) => at.asset_type_name)
                          .join(", ")
                      : "-"}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>

        <div className="tab_content_wrapper mt-3">
          <span className="content_heading">Previous Working Details</span>
          <div className="text-end">
            <button
              type="button"
              onClick={() => this.handleWorkingDetail("Add")}
              className="btn btn-light btn-sm shadow-sm"
            >
              <TapIcon.FontAwesomeIcon
                icon={TapIcon.faPlus}
                alt="item-action"
              />
            </button>
          </div>
        </div>
        <table
          className="table table-bordered bg-white align-items-center  table-sm table-hover my-3"
          id="settingsTable"
        >
          <thead className="table-secondary">
            <tr className="text-center">
              <th scope="col" style={{ width: "5%" }}>
                S.No
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Company Name
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Job Title
              </th>
              <th scope="col" style={{ width: "12%" }}>
                From Date
              </th>
              <th scope="col" style={{ width: "12%" }}>
                To Date
              </th>
              <th scope="col" style={{ width: "21%" }}>
                Job Description
              </th>
              <th scope="col" style={{ width: "5%" }}>
                Relevant
              </th>
              <th scope="col" style={{ width: "5%" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.list_loading ? (
              <tr className="text-center">
                <td colSpan={8}>
                  <Loader />
                </td>
              </tr>
            ) : this.state.pre_working_list.length > 0 ? (
              this.state.pre_working_list.map((data, i) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{i + 1}</td>
                    <td>{data.company_name}</td>
                    <td>{data.job_title ? data.job_title : ""}</td>
                    <td>
                      {data.from_date
                        ? DateService.dateTimeFormat(
                            data.from_date,
                            "DD-MMM-YYYY"
                          )
                        : ""}
                    </td>
                    <td>
                      {data.to_date
                        ? DateService.dateTimeFormat(
                            data.to_date,
                            "DD-MMM-YYYY"
                          )
                        : ""}
                    </td>
                    <td>{data.job_description ? data.job_description : ""}</td>
                    <td className="text-center">
                      {data.relevant === "Y" ? "Yes" : "No"}
                    </td>
                    <td className="text-center">
                      <TapIcon.imageIcon
                        icon={TapIcon.ListActionIcon}
                        alt="action-icon"
                        className="img-fluid px-3"
                        data-bs-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-expanded="false"
                      />
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() =>
                              this.handleWorkingDetail("Edit", data.id)
                            }
                          >
                            Edit
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={() => this.deletePreDetail(data.id)}
                          >
                            Delete
                          </Link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={8}>No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
        {this.state.emp_id && (
          <div className="mb-3">
            <EmployeeEducationDetailList emp_id={this.state.emp_id} />
          </div>
        )}
        <EmployeeWorkingModal
          ref={this.workingModalRef}
          emp_id={this.state.emp_id}
          listLoading={() => this.preWorkingList(this.state.emp_id)}
        />
        <EmployeeCurrentWorkingModal
          ref={this.currentWorkingModalRef}
          employeeData={this.state.employeeData}
          currentWorkingDetail={() =>
            this.currentWorkingDetail(this.state.emp_id)
          }
        />
      </Ax>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps, null, null)(EmployeeWorkingDetailList);
