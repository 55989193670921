import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import HttpAPICall from '../../../services/HttpAPICall';
import { ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import parse from 'html-react-parser';
import { DisplayListPagination } from "../../../components/TapUi";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import dashboardStyles from "../Dashboard.module.css"
import FeedbackModal from "../../../layouts/modal/FeedbackModal";

class ReleasesList extends AppBaseComponent {

    constructor() {
        super();

        this.state                      =   {
            minimizeTable                   :     false,
            listing_tbl_page                :     1,
            listing_loading                 :     false,
            totalListingCount               :     0,
            listingMeta                     :     null,
            release_listing                 :     [],
            show                            :     false,  
        };

        this.releaseUrl     =   ORG_API_BASE_URL_2  + '/release';

    }


    componentDidMount() {

        this.loadListingTblData(1)

    }


    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page,per_page:50});
        let params                      =   {page:page,per_page:25,platform:"web",can_published:"Y"};
        HttpAPICall.withAthorization('GET',  this.releaseUrl, this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                release_listing        :           respData.data,
                listingMeta            :           respData.meta,
                totalListingCount      :           respData.total,
            });
           

        }).then(() => this.setState({listing_loading: false}));
    }

    addFeedbackModalInit                 =   ()  =>  {
        this.setState({show : true})
    }

    closeFeedback =  () => {
        this.setState({show : false})
    }
  
    render                      =   ()  =>  {
        
       
        return (<Ax>
           <ApplicationLayout>
                <Helmet><title>New Releases</title></Helmet>
                <div className="row m-0 bg-white">
                    <div className="col-sm-12 p-0">
                        <ul className="nav nav-tabs d-flex" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/my_dashboard')}  className={`nav-link`} id="mydashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="mydashboard" aria-selected="true">My Dashboard </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/dashboard')} className={`nav-link `} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard </button>
                            </li>


                            <li className="nav-item ms-auto" role="presentation">
                                <button onClick={() => this.props.history.push('/new_releases')}
                                    className={`nav-link active`} id="release-tab" data-bs-toggle="tab" data-bs-target="#release" type="button" role="tab" aria-controls="release" aria-selected="true">New Releases</button>
                            </li>
                            <li className="nav-item " role="presentation">
                                <button onClick={() => this.props.history.push('/help/all_tutorials')}
                                    className={`nav-link`} id="help-tab" data-bs-toggle="tab" data-bs-target="#help" type="button" role="tab" aria-controls="help" aria-selected="true">Help & Tutorial </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.addFeedbackModalInit()}
                                    className={`nav-link`} id="feedback-tab" data-bs-toggle="tab" data-bs-target="#feedback" type="button" role="tab" aria-controls="feedback" aria-selected="true">FeedBack</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button onClick={() => this.props.history.push('/training_list')}
                                    className={`nav-link`} id="training-tab" data-bs-toggle="tab" data-bs-target="#training" type="button" role="tab" aria-controls="training" aria-selected="true">Schedule a Training</button>
                            </li>

                        </ul>
                    </div>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane  show active" id="home" role="tabpanel" aria-labelledby="dashboard-tab">
                        <div className="container-fluid p4" >
                            <div className={dashboardStyles.tab_content_header}>
                                <div className="row">
                                    <div className="col-md-8">
                                        {this.state.listing_loading ? <div className="text-center"><Loader /></div>
                                            : this.state.release_listing && this.state.release_listing.length > 0 ?
                                                this.state.release_listing.map((rl, key) => {
                                                    const dateTimeParts = rl.upgrade_time_display ? rl.upgrade_time_display.split(' ') : ['']; // Split date and time
                                                    const tillDatePart = dateTimeParts[0]; // Extract the date part
                                                    return (
                                                        <Ax>
                                                            <div className="card mt-3" key={key}>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-8">
                                                                            <h6 className="fw-bold">{rl.title}</h6>
                                                                        </div>
                                                                        <div className="col-sm-4 text-muted  text-end">
                                                                            {rl.upgrade_time_display ? <span>{tillDatePart.split(" ")}</span> : ""}
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <div className="">
                                                                                {rl.description ? parse(rl.description) : "-"}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Ax>
                                                    )
                                                }) : <div className="card my-3">
                                                    <div className="card-body text-center">
                                                        No New Releases
                                                    </div>
                                                </div>}
                                        <div className="my-2">
                                            {this.state.release_listing && this.state.release_listing.length > 0 && <DisplayListPagination
                                                meta={this.state.listingMeta}
                                                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                                            />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.show === true ? <FeedbackModal close={this.closeFeedback} /> : null}
            </ApplicationLayout>
           
        </Ax>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ReleasesList);
