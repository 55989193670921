import React from 'react';

const TapColorCodes = () => {
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';

        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    };

    return getRandomColor();
};

export default TapColorCodes;
