import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import { Link } from 'react-router-dom';
import Loader from "../../../components/ui/Loader/Loader";
import Status from '../../../components/ui/Status';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ReceivedQtyDetail extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            access_token                :   '',
            receiviedDataLoading        :   false,
            receiviedList               :   [],
        }
        this.purchaseItemUsedAllocationUrl  =   INVENTORY_API_BASE_URL_2 + '/purchase_item_used_by_allocaton';
    }
    
    componentDidMount               =   ()  =>  {
        this.receivedQtyModal            =   new Modal(document.getElementById('receivedQtyModal'), {keyboard: false, backdrop :false});
        this.initScreen(this.props);
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen              =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }
    
    receiviedQtyModalInit   =   (req_item_id,po_item_id)  =>  {
        this.setState({receiviedDataLoading : true, receiviedList : []});
        let params          =   {
            req_item_id     :   req_item_id ? req_item_id : null,
            po_item_id      :   po_item_id ? po_item_id : null
        }
        HttpAPICall.withAthorization('GET', this.purchaseItemUsedAllocationUrl, this.state.access_token, params, null, (response) => {
            this.setState({receiviedList : response.data});
        }, (err) => {
        }).then(() => {
            this.setState({receiviedDataLoading : false});
        });
        this.receivedQtyModal.show()
    }
   
    receivedQtyModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="receivedQtyModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="receivedQtyLabel">Received Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {this.state.receiviedDataLoading
                                ? <Loader />
                                : <table className="table table-bordered table-responsive bg-white table-fixed ">
                                    <thead className="table-secondary">
                                        <tr className="text-end">
                                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Transaction ID</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Date of Transaction</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">PO ID</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Requistion ID</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-start" >Bill Number</th>
                                            <th scope="col" style={{ width: "18%" }} className="text-start">Vendor Name</th>
                                            <th scope="col" style={{ width: "7%" }}>Quantity</th>
                                            <th scope="col" style={{ width: "7%" }}>Unit Rate</th>
                                            <th scope="col" style={{ width: "8%" }}>Amount </th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Status </th>
                                            <th scope="col" style={{ width: "5%" }}>Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.receiviedList.length > 0
                                            ? (this.state.receiviedList.map((receivedData, k) => {

                                                return <tr key={k} className='text-end'>
                                                    <td className='text-center'>{k + 1}</td>
                                                    <td className='text-center'>{receivedData.purchase.transaction_id}</td>
                                                    <td className='text-center'>{receivedData.purchase.transaction_date_display}</td>
                                                    <td className='text-center'>{receivedData.po_transaction_id}</td>
                                                    <td className='text-center'>{receivedData.requisition_transaction_id !== ""  ? receivedData.requisition_transaction_id : "ADDL"}</td>
                                                    <td className='text-center'>{receivedData.purchase.purchase_bill_number ?? "-"}</td>
                                                    <td className='text-start'>{receivedData.purchase && receivedData.purchase.vendor ? receivedData.purchase.vendor.name : ''}</td>
                                                    <td>{receivedData.qty ?? 0}</td>
                                                    <td>{receivedData.rate ?? 0}</td>
                                                    <td>{receivedData.qty ? receivedData.qty * receivedData.rate : 0}</td>
                                                    <td><Status color={receivedData?.purchase?.status_color}>{receivedData.purchase?.status_text}</Status></td>
                                                    <td>
                                                        <Link target="_blank" to={{ pathname: "/purchase_list", search: "?" + new URLSearchParams({ search_purchase_id: receivedData.purchase.transaction_id }).toString() }}> View </Link>
                                                    </td>
                                                </tr>;
                                            }))
                                            : <tr className='text-center'><td colSpan="10" >No records</td></tr>
                                        }
                                    </tbody>
                                </table>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return (<Ax>
            {this.receivedQtyModalJsx()}
        </Ax>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReceivedQtyDetail);