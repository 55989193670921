import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import ScheduleTrainingForm from './ScheduleTrainingForm';
import FeedbackModal from '../../../layouts/modal/FeedbackModal';

class ScheduleTrainingListing extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initCommentForm            =       {
            training_id             :            '',
            comment                 :           ''
        }

        this.trainingSearchInitState         =   {
            'search_topic_ids'                 :   [],
            'search_status'                 :   [],
            'search_request_mode'           :   '',
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
        };

        this.trainingSearchFormLabel         =   {
            'search_topic_ids'                 :   'Topics : ',
            'search_status'                 :   'Status : ',
            'search_request_mode'           :   'Mode of Training : ',
            'date_range'                    :   'Training  Period : ',
        };
        
        this.state                      =     {
            minimizeTable                   :     false,
            listing_tbl_page                :     1,
            listing_loading                 :     false,
            totalListingCount               :     0,
            listingMeta                     :     null,
           training_listing              :     [],
           trainingData                 :       null,
           view_loading                 :       false,
           addCommentForm               :       {...this.initCommentForm},
           trainingSearchForm              :       {...this.trainingSearchInitState},
           submittedTrainingSearchForm      :       {...this.trainingSearchInitState},
           formSearchedElems               :       [],
           formDataLoaded                   :       false,
           allStatus                        :       [],
           allTopicsList                    :       [],
           allModes                         :       [{value:"online",label:"Online"},{value:"offline",label:"Offline"}],
           saveFormSubmitting               :       false,
           all_periods                      :       []  ,
           show                            :     false,     
        };

        this.TrainingFormRef                        =   React.createRef();

        this.trainingFormDataUrl    =   ORG_API_BASE_URL_2  + '/training/form_data';
        this.trainingUrl            =   ORG_API_BASE_URL_2  + '/training';
        this.trainingCommentUrl     =   ORG_API_BASE_URL_2  + '/training/comment';
         
    }
    
    
    componentDidMount() {
    
        this.loadListingTblData(1);
        this.addTrainingFormInit();
        this.initializeData(this.props)
       this.addCommentModal         =   new Modal(document.getElementById('addCommentModal'), {keyboard: false, backdrop :false});  
       this.trainingSearchModal     =    new Modal(document.getElementById('trainingSearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
         }     
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            this.setState({
                all_periods             :        all_periods ,
                })
        }
    }


    loadListingTblData                 =   (page = 1,)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page,per_page:50});
        let params                      =   {page:page,...this.state.submittedTrainingSearchForm};
        HttpAPICall.withAthorization('GET',  this.trainingUrl, this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                training_listing        :           respData.data,
                listingMeta         :           respData.meta,
                totalListingCount       :           respData.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }
    
    
    closeViewDetails       =    ()      =>      {
        this.setState({minimizeTable :false})
    }

    addFeedbackModalInit                 =   ()  =>  {
        this.setState({show : true})
    }

    closeFeedback =  () => {
        this.setState({show : false})
    }

    addTrainingFormInit       =       ()      =>      {
        this.TrainingFormRef.current.trainingFormModalInit()
    }

    viewTrainingData        =       (id)        =>      {
        this.setState({
            minimizeTable : true
        })

        this.getTrainingDetail(id)
    }

    getTrainingDetail           =       (id)      =>      {
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', this.trainingUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            
            this.setState({
                trainingData        :   respData.data
            });

        }).then(() => this.setState({ view_loading: false }));
    }

    addCommentModalInit     =       ()      =>      {
        this.addCommentModal.show();
        this.setState({addCommentForm : {...this.initCommentForm,training_id:this.state?.trainingData?.id}})
    }

    submitCommentForm      =       (e)         =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('POST', this.trainingCommentUrl, this.props.access_token, {}, { ...this.state.addCommentForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.setState({addCommentForm:{...this.initCommentForm}})
           this.addCommentModal.hide();
           this.getTrainingDetail(this.state?.trainingData?.id)
        }).then(() => this.setState({ saveFormSubmitting: false }));

    }

    trainingSearchModalInit         =       ()      =>      {
        this.trainingSearchModal.show()
        if(!this.state.formDataLoaded){
            this.getTrainingSearchFormData()
        }
    }

    getTrainingSearchFormData        =       ()      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.trainingFormDataUrl,
        this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                formDataLoaded              :   true,
                allStatus                   :   respData && respData.status &&  respData.status.length > 0  ? respData.status.map((s) => { return {value: s.key, label: `${s.status}`}}) : [],
                allTopicsList               :   respData && respData.topics && respData.topics.length > 0 ? respData.topics.map(tp => {return({value:tp.id,label:tp.name})}) : []
            })
        }).then(() => this.setState({form_data_loading: false}));
    }

    trainingSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.trainingSearchForm);
        let searchedElems               =   [];
        let trainingSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.trainingSearchFormLabel[key];
            let value                       =   this.state.trainingSearchForm[key];
            if(value && value.toString().length > 0) {
                trainingSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                   
                     if(key == 'search_topic_ids') {
                        show_val            =   this.state.allTopicsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_request_mode') {
                        show_val            =   this.state.allModes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.allStatus.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'date_range') {
                        let  start_range = moment(this.state.trainingSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.trainingSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedTrainingSearchForm         :   {...this.state.trainingSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.trainingSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    trainingSearchClear         =       ()     =>      {
        this.setState({
            trainingSearchForm                :   {...this.trainingSearchInitState},
            submittedTrainingSearchForm       :   {...this.trainingSearchInitState},
            formSearchedElems             :   []
        }, () => {
            this.trainingSearchModal.hide();
            this.loadListingTblData(1)
        });
    }
    

     //***********************fuelStation JSX****************

    trainingListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Training List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>  <button type="button" className="btn btn-primary" onClick={() => this.addTrainingFormInit()}>Schedule a Training</button>
                            <button type="button" disabled={this.state.listing_loading} onClick={this.trainingSearchModalInit} className="btn btn-secondary" >
                                <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </Ax>
                        : null
                    }
                  
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Training Detail</h3>
                            <div className="text-end mt15">
                            <button type="button" className="btn btn-secondary" onClick={() => this.addCommentModalInit()}>Add Comment</button>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }


        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.trainingListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.trainingViewDetails()}</div></div> : null}

                </div>
            </div>
        </Ax>);
    }

    trainingListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" >
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.trainingSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Training</th>
                        </tr>)
                        : (
                            <tr>
                                <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                                <th scope="col" style={{ width: "20%" }}>Topic</th>
                                <th scope="col" style={{ width: "15%" }}>Mode of Training</th>
                                <th scope="col" style={{ width: "15%" }}>Mobile</th>
                                <th scope="col" style={{ width: "15%" }}>Training Date</th>
                                <th scope="col" style={{ width: "8%" }} className='text-center'>Status</th>
                                <th scope="col" style={{ width: "8%" }} className='text-center'>Action</th>

                            </tr>)}
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.training_listing.length > 0
                            ? (this.state.training_listing.map((training, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewTrainingData(training.id)}>{training.topicsData && training.topicsData.length > 0 ? training.topicsData.map((td, k) => { return (td.name) }).join(', ') : "-"} </a>
                                                        <div className="mt-1">
                                                            <small> <span>{training.mobile ? training.mobile : "-"}</span> </small>
                                                            <small className="float-end"><Status color={training.status_color}>{training.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewTrainingData(training.id)}>{training.topicsData && training.topicsData.length > 0 ? training.topicsData.map((td, k) => { return (td.name) }).join(', ') : "-"}</td>
                                                <td className='text-capitalize'>{training.training_mode ? training.training_mode : ""}</td>
                                                <td>{training.mobile ? training.mobile : "-"}</td>
                                                <td>{training.training_date_display ? training.training_date_display : <span>{training.preferred_date_display} <sup>*</sup></span>}</td>
                                                <td><Status color={training.status_color}>{training.status_text}</Status></td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewTrainingData(training.id)}>View</a></li>
                                                    </ul>
                                                </td>

                                            </Ax>)}

                                </tr>)
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>

            </table>
            <div className='text-sm p-2'>*Preferred Training Date</div>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />

        </div>)
    }

    trainingViewDetails       =       ()      =>      {
        let detail = this.state.trainingData;
        return (<div className="tab_content_header">
            {detail
                ? <Ax>
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Requested Training Details</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                        <tbody>
                            <tr>
                                <td style={{ width: "26%" }} >Topic</td>
                                <th>{detail.topicsData && detail.topicsData.length > 0 ? detail.topicsData.map((td, k) => { return (td.name) }).join(', ') : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Mode of Training</td>
                                <th className='text-capitalize'>{detail.training_mode ? detail.training_mode : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Mobile</td>
                                <th>{detail.mobile ? detail.mobile : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }}>Date of Training</td>
                                <th>{detail.preferred_date_display ? detail.preferred_date_display : "-"}</th>

                            </tr>
                            
                            <tr>
                                <td style={{ width: "26%" }} >Notes</td>
                                <th>{detail.notes ? detail.notes : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >Status</td>
                                <th>{detail.status ? <Status color={detail.status_color}>{detail.status_text}</Status> : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >User Name</td>
                                <th>{detail.created_by ? <span>{detail?.created_by?.full_name}</span> : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }} >User Number</td>
                                <th>{detail.created_by ? <span>{detail?.created_by?.mobile}</span> : "-"}</th>

                            </tr>

                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Training Details</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                        <tbody>
                           
                            <tr>
                                <td style={{ width: "26%" }}>Trainers</td>
                                <th>{detail.trainersData && detail.trainersData.length > 0 ? detail.trainersData.map(st => {return(st.name)}).join(', ') : "-"}</th>

                            </tr>
                            <tr>
                                <td style={{ width: "26%" }}>Training Date</td>
                                <th>{detail.training_date_display ? detail.training_date_display : "-"}</th>

                            </tr>

                        </tbody>
                    </table>
                    { detail.comments && detail.comments.length > 0 && <Ax>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Comments</span></div>
                        
                        </Ax>}
                   
                        <div className="my-2">
                            {
                                detail.comments && detail.comments.length > 0 ? detail.comments.map((c, k) => {
                                    return (<Ax key={k}><div className="row">
                                        {c.comment ? <div className='col-sm-8'>{c.userData ? c.userData.full_name : "Team TappetBox"} added Comment <br/> {c.comment ? c.comment : ""} </div> : <div className='col-sm-8'>-</div>}
                                        <div className="col-sm-4 text-end">
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                            <span> {c.created_at_display}</span>
                                        </div>
                                        <div className="col-sm-8 my-1">{c.notes}</div>
                                    </div><hr /></Ax>);
                                })
                                    : null
                            }
                        </div>
                </Ax>
                : null}
        </div>)
    }
   
    addCommentModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"addCommentModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Add Comment</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.submitCommentForm} id={"addCommentForm"}>
                           
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Comment </label>
                                <div className="col-sm-9">
                                    <textarea
                                        name="comment"
                                        value={this.state.addCommentForm.comment}
                                        onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                        className="form-control"
                                        style={{ height: "100px" }}
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"addCommentForm"}>Submit{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    trainingSearchModalJsx           =   ()  =>  {
        return <div className="modal fade" id={"trainingSearchModal"} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Search</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.trainingSearchFormSubmit} id={"trainingSearchForm"}>
                           
                            <div className="row align-items-center">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Topics</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.allTopicsList}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_topic_ids')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allTopicsList.filter(s => this.state.trainingSearchForm.search_topic_ids.includes(s.value))}
                                        placeholder="Select Topics"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Status</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.allStatus}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_status')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allStatus.filter(s => this.state.trainingSearchForm.search_status.includes(s.value))}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-2 col-form-label col-form-label-sm ">Mode of Training</label>
                                <div className="col-sm-10">
                                    <TapSelect
                                        options={this.state.allModes}
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'trainingSearchForm', 'search_request_mode')}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        value={this.state.allModes.filter(s => this.state.trainingSearchForm.search_request_mode.includes(s.value))}
                                        placeholder="Select Mode of Training"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row py-2 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Training  Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.trainingSearchForm.date_range}
                                            startDate={this.state.trainingSearchForm.date_range_start}
                                            endDate={this.state.trainingSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({trainingSearchForm
                                                    : {
                                                        ...this.state.trainingSearchForm, 
                                                        date_range: period, 
                                                        date_range_start: startDate ? startDate : null, 
                                                        date_range_end: endDate ? endDate : null
                                                      }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={"trainingSearchForm"}>Search{this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    render                              =   ()  =>  {
    
        return (<ApplicationLayout>
        <Helmet><title>Training Listing</title></Helmet>
            <div className="row m-0 bg-white">
                <div className="col-sm-12 p-0">
                    <ul className="nav nav-tabs d-flex" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                            <button onClick={() => this.props.history.push('/my_dashboard')} className={`nav-link`} id="mydashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="mydashboard" aria-selected="true">My Dashboard </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => this.props.history.push('/dashboard')} className={`nav-link `} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard </button>
                        </li>


                        <li className="nav-item ms-auto" role="presentation">
                            <button onClick={() => this.props.history.push('/new_releases')}
                                className={`nav-link`} id="release-tab" data-bs-toggle="tab" data-bs-target="#release" type="button" role="tab" aria-controls="release" aria-selected="true">New Releases</button>
                        </li>
                        <li className="nav-item " role="presentation">
                            <button onClick={() => this.props.history.push('/help/all_tutorials')}
                                className={`nav-link`} id="help-tab" data-bs-toggle="tab" data-bs-target="#help" type="button" role="tab" aria-controls="help" aria-selected="true">Help & Tutorial </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => this.addFeedbackModalInit()}
                                className={`nav-link`} id="feedback-tab" data-bs-toggle="tab" data-bs-target="#feedback" type="button" role="tab" aria-controls="feedback" aria-selected="true">FeedBack</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => this.props.history.push('/training_list')}
                                className={`nav-link active`} id="training-tab" data-bs-toggle="tab" data-bs-target="#training" type="button" role="tab" aria-controls="training" aria-selected="true">Schedule a Training</button>
                        </li>

                    </ul>
                </div>
            </div>
             {this.trainingListjsx()} 
             {this.addCommentModalJsx()}
             {this.trainingSearchModalJsx()}
             <ScheduleTrainingForm ref={this.TrainingFormRef}afterSubmit={() => this.loadListingTblData(1)}/>
             {this.state.show === true ? <FeedbackModal close={this.closeFeedback} /> : null}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
    };
};

export default connect(mapStateToProps)(ScheduleTrainingListing);