import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import {connect} from "react-redux";
import HttpAPICall from "../../../services/HttpAPICall";
import Ax from "../../../components/hoc/Ax";
import AssetHeader from "../../includes/assetHeader/AssetHeader";
import AssetCard from "../../includes/assetCard/AssetCard";
import {Link} from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import {ToastContainer} from "react-toastify";
import AssetAttributeList from "./AssetAttributeList";
import {Modal} from "bootstrap";
import TapSelect from "../../../components/ui/TapSelect";
import Status from "../../../components/ui/Status";

class PurchaseConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                  =        {

        }
    }

    componentDidMount() {
        this.editTransactionId        =        new Modal(document.getElementById('editTransactionIdModal'), {keyboard: false, backdrop :false});
    }

    editTransactionIdInit       =      ()    =>     {
        this.editTransactionId.show()
    }

    editTransactionIdModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="editTransactionIdModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Transaction ID</h5>
                        </div>
                        <form>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Transaction </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">ID Type </label>
                                    </div>
                                    <div className="col-3">
                                        <input
                                            name="type"
                                            type="radio"
                                            value={"manual"}
                                            className="form-check-input"
                                            required={true}
                                        />
                                        <label className="form-check-label mx-2">Manual</label>
                                    </div>
                                    <div className="col-6">
                                        <input
                                            name="type"
                                            type="radio"
                                            value={"system_generate"}
                                            className="form-check-input"
                                            required={true}
                                        />
                                        <label className="form-check-label mx-2">Auto - Increment</label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">ID Prefix </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="prefix"
                                            type="text"
                                            className="form-control"
                                            placeholder={"Enter prefix"}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Numbering</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="numbering"
                                            type="numbers"
                                            className="form-control"
                                            placeholder={"Enter stating numbers"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <section className="page_containt " style={{top:"1px"}}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general"
                                    type="button" role="tab" aria-controls="general" aria-selected="true">General Settings
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane  show active" id="general" role="tabpanel"
                             aria-labelledby="general-tab">
                            <div className="tab_content_header">
                                <table className="table table-hover table-bordered ">
                                    <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" style={{width:"10%"}}>S.No</th>
                                        <th scope="col" className="text-start">Configuration</th>
                                        <th scope="col"  style={{width:"20%"}} >Value</th>
                                        <th scope="col" style={{width:"10%"}}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="text-center">
                                        <td scope="row">1</td>
                                        <td scope="row" className="text-start">Allow Transaction Entry on Backdate</td>
                                        <td scope="row">Yes</td>
                                        <td scope="row">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                               alt="action-icon"
                                                               className="img-fluid pr10 pl10"
                                                               data-bs-toggle="dropdown" href="#"
                                                               role="button" aria-expanded="false"/>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    Edit
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr className="text-center">
                                        <td scope="row">2</td>
                                        <td scope="row" className="text-start">Allow Transaction Entry Without Bill number & Date</td>
                                        <td scope="row">Yes</td>
                                        <td scope="row">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                               alt="action-icon"
                                                               className="img-fluid pr10 pl10"
                                                               data-bs-toggle="dropdown" href="#"
                                                               role="button" aria-expanded="false"/>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    Edit
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                 </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-2 mb-3"><span className="content_heading">Transaction ID  Settings </span>
                                </div>
                                <table className="table table-hover table-bordered ">
                                    <thead className="table-secondary">
                                    <tr>
                                            <th scope="col" style={{width: "10%"}}>Sr.No</th>
                                            <th scope="col" style={{width: "20%"}}>Transaction</th>
                                            <th scope="col" style={{width: "20%"}}>ID Type</th>
                                            <th scope="col" style={{width: "20%"}}>ID Prefix</th>
                                            <th scope="col" style={{width: "20%"}} >Numbering</th>
                                            <th scope="col" style={{width: "10%"}} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Purchase</td>
                                        <td>System Generate</td>
                                        <td>SSN/REQ/21-22/</td>
                                        <td>0001</td>
                                        <td className="text-center">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                               alt="action-icon"
                                                               className="img-fluid pr10 pl10"
                                                               data-bs-toggle="dropdown" href="#"
                                                               role="button" aria-expanded="false"/>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item" onClick={this.editTransactionIdInit}>
                                                    Edit
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {this.editTransactionIdModalJsx()}
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
    }
}

export default connect(mapStateToProps)(PurchaseConfiguration);
