import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import { Modal } from 'bootstrap';
import axios from 'axios';
import moment from "moment";
import { Helmet } from 'react-helmet';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapSelect from "../../../components/ui/TapSelect";
import DownloadFile from "../../../services/DownloadFile";
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import {DisplaySearchCriteria} from "../../../components/TapUi/index";
import { Link } from 'react-router-dom';


class OpeningBalanceList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.openingBalanceSearchFormInitialState     =   {
            'search_warehouse_ids'               :       [],
            'search_item_ids'                    :       [],
        }

        this.openingBalanceSearchFormLabel       =   {
            'search_warehouse_ids'               :       'Search Warehouse : ',
            'search_item_ids'                    :       'Search Items : ',
        }

        this.state                         =     {
            openingBalanceAddScreenView          :      false,
            openingBalanceUpdateScreenView       :      false,
            minimizeTable                        :      false,
            listing_tbl_page                     :      1,
            listing_loading                      :      false,
            listingMeta                          :      null,
            listingData                          :      [],
            form_loading                         :      false,
            filterFormData                       :      [],
            allWarehouse                         :      [],
            openingBalanceSearchForm             :      {...this.openingBalanceFormInitialState},
            openingBalanceSearchedElems          :      [],
            openingBalanceSearchParams           :      {},
            searchedItem                         :      []  ,
            itemId                               :      null,
            itemData                             :      [] ,
            openingBalanceBulkUploadFormSubmit   :      false,
            permissions                          :      []
        };

        this.itemOpeningStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_opening_stock';

    }

    componentDidMount() {
        this.loadListingTblData(1);
        this.openingBalanceSearchFormData()
        this.openingBalanceBulkUploadModal                =   new Modal(document.getElementById('openingBalanceBulkUploadModal'), {keyboard: false, backdrop :false});
        this.searchOpeningBalanceModal                    =   new Modal(document.getElementById('searchOpeningBalanceModal'), {keyboard: false, backdrop :false});
    }

    loadListingTblData                  =   (page = 1)  =>  {
        let searchParm                  =   {...this.state.openingBalanceSearchParams};
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params= {page : page, per_page : 50,...searchParm};
        HttpAPICall.withAthorization('GET', this.itemOpeningStockUrl, this.props.access_token , {...params}, {}, (resp) => {
           this.setState({
                            listingData        :   resp.data.data,
                            listingMeta      :   {
                                from         :  resp.data.from,
                                to           :  resp.data.to,
                                total        :  resp.data.total,
                                last_page    : resp.data.last_page,
                                current_page : resp.data.current_page
                            },
                             totalListingCount :  resp.data.total
                         })
        }).then(() => this.setState({listing_loading: false}))
    }
    
    openingBalanceSearchFormData         =   ()        =>   {
        this.setState({form_loading: true});
        HttpAPICall.withAthorization('GET', this.itemOpeningStockUrl + '/filterform' , this.props.access_token, {}, {}, (resp) => {
        this.setState({
                      filterFormData   :    resp.data,
                      allWarehouse     :    resp.data.warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }})
                    })
        }).then(() => {this.setState({form_loading: false})})  
    }

    openingBalanceSearchFormSubmit                =   (event)  =>  {
        event.preventDefault();
        //Get All Keys :-
        let serachFormKeys                        =   Object.keys(this.state.openingBalanceSearchForm);
        let searchedElems                         =   [];
        let openingBalanceSearchedElems           =   {};
        let openingBalanceSearchParams            =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.openingBalanceSearchFormLabel[key];
            let value                       =   this.state.openingBalanceSearchForm[key];
            if(value && value.length > 0) {
                openingBalanceSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.allWarehouse.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }

                    searchedElems.push(label + show_val);
                }
            }
        });
        //Generate Html :-
        this.setState({
            openingBalanceSearchedElems : searchedElems, 
            openingBalanceSearchParams : openingBalanceSearchParams
        }, () => {
        this.loadListingTblData(1);
        this.searchOpeningBalanceModal.hide();
        });
    }

    openingBalanceSearchClear             =   ()  =>  {
        this.setState({
            openingBalanceSearchForm      :   {...this.openingBalanceFormInitialState},
            openingBalanceSearchedElems   :   [],
            openingBalanceSearchParams    :   null,
            searchedItem                  :   []
            }, function() {
                this.loadListingTblData(1)
        });
    }

    openAddOpeningBalanceScreen       =      ()      =>     {
        // this.setState({openingBalanceAddScreenView:true})
        this.props.history.push('/item_opening_balance/add') 
    }

    addOpeningBalanceScreenClose      =      ()       =>     {
        this.setState({openingBalanceAddScreenView:false})
    }

    updateOpeningBalance            =       (id)      =>      {
        let itemData = this.state.listingData.find(i => i.item.id == id)
        this.props.history.push({pathname: `/item_opening_balance/update`, state: {data : itemData.item }})
    }

    closeAddScreen                    =      ()              =>         {
        this.setState({openingBalanceAddScreenView : false})
        this.loadListingTblData(1)
    }

    closeUpdateScreen                    =      ()              =>         {
        this.setState({openingBalanceUpdateScreenView : false})
        this.loadListingTblData(1)
    }

    openingBalanceSearchModalInit                 =   ()  =>  {
        //Initialize Search Form :-
        this.searchOpeningBalanceModal.show({focus : true});
    }

     /**
     * Bulk Upload :-
     */
    openingBalanceBulkUploadModalInit             =   ()  =>  {
        document.getElementById('openingBalanceFileSelector').value = null;
        //Initialize Search Form :-
        this.openingBalanceBulkUploadModal.show();
    }

    downloadSampleFile                  =   ()      => {
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/download_sample_excel_item_opening_stock', this.props.access_token,
        {} , {} , (response) => 
        {   DownloadFile.file(response.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
            this.openingBalanceBulkUploadModal.hide();
        })
    }

    openingBalanceBulkUploadFormSubmit            =   (event) => {
        event.preventDefault();
        this.setState({openingBalanceBulkUploadFormSubmit : true});
        const data          =   new FormData(document.getElementById('openingBalanceBulkUploadForm'));
        let headers         =   { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data','Authorization' : 'Bearer ' + this.props.access_token };
        axios.post(this.itemOpeningStockUrl + '/update_by_excel', data, { headers: headers })
        .then((response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.openingBalanceBulkUploadModal.hide();
            //Load Add Item Form Data :-
            //this.loadListingTblData(1);
            DownloadFile.file(response.data.generated_file.file_path);
            setTimeout(() => {
                this.loadListingTblData(1);
            },1000)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Add Opening Balance.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({openingBalanceBulkUploadFormSubmit : false});
        });
    }

    openingBalanceListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Opening Stock</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button"  className="btn btn-primary" disabled={!this.props.permissions.includes('isc-item-opening-balance')} onClick={this.openAddOpeningBalanceScreen}>Add</button> : null}
                    <button type="button"  className="btn btn-secondary" onClick={this.openingBalanceSearchModalInit}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action"/>
                    </button>
                    <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.excelDownloading == true}>
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {this.state.minimizeTable && this.props.permissions.includes('isc-item-opening-balance') ? <li><a className="dropdown-item"  href="#">Add</a></li> : null}
                            <li><a className="dropdown-item" onClick={this.openingBalanceBulkUploadModalInit} role="button" >Bulk Upload</a></li>
                        </ul>
                    </span>
                 
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Inventory Opening Balance : </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.openingBalanceListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{height:"100%"}}>{this.openingBalanceViewDetails()}</div></div> : null }
                </div>
            </div>
        </Ax>);
    }

    openingBalanceListingTableJsx                =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.openingBalanceSearchedElems} onClearFilter={this.openingBalanceSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Inventory Opening Balance</th>
                        </tr>)
                        : (<tr className='text-end'>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                            <th scope="col" style={{ width: "14%" }} className='text-start'>Item Name</th>
                            <th scope="col" style={{ width: "12%" }} className='text-start'>Item Code</th>
                            <th scope="col" style={{ width: "12%" }} className='text-start'>Manufacturer (MPN)</th>
                            <th scope="col" style={{ width: "14%" }} className='text-start'>Warehouse</th>
                            {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                <Ax>
                                    <th scope="col" style={{ width: "8%" }} className='text-start'>Batch Number</th>
                                    <th scope="col" style={{ width: "8%" }} className='text-center'>Expiry Date</th>
                                </Ax>)
                                : null}
                            <th scope="col" style={{ width: "7%" }} > Quantity</th>
                            <th scope="col" style={{ width: "7%" }}>Unit Rate</th>
                            <th scope="col" style={{ width: "8%" }}>Amount</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.listingData.length > 0
                            ? (this.state.listingData.map((opening_stock, k) => {
                                return (<tr key={k} className="text-end">
                                    <td className="text-center">{k + 1}</td>
                                    <td className="text-start text-capitalize">{opening_stock.item ? opening_stock.item.name : "-"} </td>
                                    <td className="text-start text-capitalize">{opening_stock.item ? opening_stock.item.item_code : "-"}</td>
                                    <td className='text-start'>{opening_stock.item.manufacturer ? opening_stock.item.manufacturer.name : "-"} <br />
                                        {opening_stock.item.manufacturer_part_no ? <small> MPN :  {opening_stock.item.manufacturer_part_no}</small> : ""}
                                    </td>
                                    <td className="text-start">{opening_stock.warehouse ? opening_stock.warehouse.name : "-"}</td>
                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                        <Ax>
                                            <td className='text-start'>{opening_stock.batch_number ? opening_stock.batch_number : "-"}</td>
                                            <td className='text-center'>{opening_stock.expiry_date ? moment(opening_stock.expiry_date).format("DD-MMM-YYYY") : "-"}</td>

                                        </Ax>) : null}
                                    <td >{opening_stock.qty ? opening_stock.qty : "0"}</td>
                                    <td>{opening_stock.rate ? opening_stock.rate : "0"}</td>
                                    <td>{opening_stock.qty ? (opening_stock.qty * opening_stock.rate).toFixed(2) : 0}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <Link role="button"
                                                    onClick={() => {
                                                        if (this.props?.permissions?.includes('isc-item-opening-balance')) {
                                                            return this.updateOpeningBalance(opening_stock.item.id)
                                                        }
                                                    }}
                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-opening-balance') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props?.permissions?.includes('isc-item-opening-balance') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Update</Link>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>);
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    openingBalanceSearchModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="searchOpeningBalanceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchOpeningBalanceModalLabel">Item Opening Stock Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.form_loading ? <Loader />
                            : <form onSubmit={this.openingBalanceSearchFormSubmit} id="openingBalanceSearchForm">
                                <div className="modal-body">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end"> Item Name</label>
                                        <div className='col-sm-10'>
                                            <InputItemSearch
                                                placeholder="Search Item"
                                                isMulti={true}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'openingBalanceSearchForm', 'search_item_ids');
                                                    this.setState({
                                                        searchedItem: selectedOption ? selectedOption : null
                                                    }, () => {
                                                    });
                                                }}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end">Warehouse</label>
                                        <div className='col-sm-10'>
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'openingBalanceSearchForm', 'search_warehouse_ids')}
                                                options={this.state.allWarehouse}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allWarehouse.filter(t => this.state.openingBalanceSearchForm?.search_warehouse_ids?.includes(t.value))}
                                                placeholder="Please Select Warehouse"
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Search</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
            );
    }

    openingBalanceBulkUploadModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="openingBalanceBulkUploadModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Upload Opening Balance</h5>
                            <button type="button" disabled={this.state.openingBalanceBulkUploadFormSubmit} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.openingBalanceBulkUploadFormSubmit} id="openingBalanceBulkUploadForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            id="openingBalanceFileSelector"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <div><a type="button" className='float-left' href='#' onClick={this.downloadSampleFile}>Download Sample File</a></div>
                                <div>
                                    <button type="button" disabled={this.state.openingBalanceBulkUploadFormSubmit} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.openingBalanceBulkUploadFormSubmit} className="btn btn-primary">
                                        Submit {this.state.openingBalanceBulkUploadFormSubmit ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Opening Balance Listing</title></Helmet>
            {this.openingBalanceListjsx()}
            {this.openingBalanceSearchModalJsx()}
            {this.openingBalanceBulkUploadModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        isc_configuration   :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions         :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(OpeningBalanceList);