import React from 'react';
import { connect } from 'react-redux';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import axios from "axios";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import AssetCard from "../../includes/assetCard/AssetCard";
import errorValidation from "../../../services/ErrorValidation";
import TapSelect from "../../../components/ui/TapSelect";
import {Modal} from "bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import Status from "../../../components/ui/Status";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import AssetActivityView from './AssetActivityView';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';


class  AssetActivityTransactionListing extends AppBaseComponent {
    
    constructor() {
        super();

        this.activitySearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_activities'             :    [],
            'site_group_ids'                :       [],
            'search_activity_type'          :   '',
            // 'search_operators'              :   [],
            'search_asset_ids'              :   [], 
            'asset_group_ids'               :    [], 
            'search_asset_type_ids'         :   [],
            'search_site_ids'               :   [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'activity_transaction_delay'     :   ''
        };

        this.activitySearchFormLabel         =   {
            'site_group_ids'            :      'Site Groups : ',
            'search_asset_ids'               :   'Asset : ',
            'search_activities'            :   'Activity : ',
            'search_activity_type'           :   'Activity Type : ',
            // 'search_operators'               :    'Operators : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            'search_asset_type_ids'          :   'Asset Type : ',
            'search_site_ids'                :   'Site : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'activity_transaction_delay'     :   'Transaction Delay : '
        };

        this.state                      =  {
            assetCardDetails            :         [],
            activityListing             :         [],
            activityData                :         [],
            listingMeta                 :         null,
            listing_loading             :         false,
            listing_tbl_page            :         1,
            card_loading                :         false,
            totalListingCount           :         0,
            view_loading                :         false,
            activitySearchParams        :          null,
            iam_asset_types             :          [],
            iam_group_sites             :          [],
            all_periods                 :          [],
            activitySearchForm          :          {...this.activitySearchInitState},
            formSearchedElems           :          [],
            activitySearchParams        :          null,
            file_downloading            :          false,
            allTransactionDelay         :          [],
            searchedAssets              :           [],
            searchedEmployees           :           [],
            allActivitesList            :           [],
            allActivityTypeList         :           [],
            allSiteGroupsList           :           [],
            allAssetTypesGroupsList         :   []
        }
    }

    componentDidMount() {
        this.setState({access_token : this.props.access_token,listing_loading:true})
        setTimeout(() => {
            this.searchFormDataInit(this.props);
        },1000)
      
        this.activitySearchModal         =    new Modal(document.getElementById('activitySearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        // if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
        //     this.searchFormDataInit(nextProps)
        // }      
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }


    getAssetActivityListing(page){
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,need_asset_profile : 'Y',need_counterlog : 'Y',...this.state.activitySearchParams};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/activity_list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                activityListing           :           respData.data,
                listingMeta                :          {
                    from                        :   respData.meta.from,
                    to                          :   respData.meta.to,
                    total                       :   respData.meta.total,
                    last_page                   :   respData.meta.last_page,
                    per_page                    :   respData.meta.per_page,
                    current_page                :   respData.meta.current_page
                },
                totalListingCount          :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    getActivityData(id){
        this.setState({ view_loading: true })
        let params = {need_asset_full_profile : 'N',need_counterlog : 'Y',need_site : 'Y',need_shift:'Y'}
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/activity/' + id, this.props.access_token, { ...params }, {}, (response) => {
            let respData = response.data
            let assetId = respData.data.asset_id
            this.setState({
                activityData        : respData.data,
            })
            if(respData.data.asset_id){
               
                 if(this.state.assetId !== assetId){
                    this.getAssetCard(respData.data.asset_id)
                 }else{
                    this.setState({view_loading : false,assetId : assetId })
                }
            }
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        })
        
    }

    getAssetCard                        =      (id)            =>       {
        let Id = id ? id : this.state.assetId;
        this.setState({card_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/asset/' + Id, this.props.access_token, {}, {}, (resp) => {
            this.setState({assetCardDetails : resp.data.data,assetId : id})
        }).then(() => this.setState({card_loading: false,view_loading : false}));
    }
    
    activityDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.activitySearchParams};
        if(this.state.activitySearchParams !== null){
            this.activityDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.activityDownloadStart(params);
                }
            });
        }
    }
    

    activityDownloadStart              =    (params)     =>         {
        this.setState({ file_downloading: true});
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/activity_list', this.props.access_token,{download : "download",...params},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({file_downloading: false}));
    } 

    viewActivity(id){
        this.setState({minimizeTable:true})
        this.getActivityData(id)
    }

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/activity_list_form-data', this.props.access_token, {})
            .then((response) => {
               let respData = response.data
                this.setState({
                    iam_asset_types         :       props.iam_asset_types ? props.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                    iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                    all_periods             :       all_periods,
                    allTransactionDelay     :       response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : [],
                    allActivitesList        :       respData && respData.activities ? respData.activities.map((td) => { return {value: td.id, label: td.activity_name}}) : [],
                    allActivityTypeList     :       respData && respData.activity_type ? respData.activity_type.map((td) => { return {value: td.key, label: td.name}}) : [],
                    allSiteGroupsList       :       respData && respData.site_groups && respData.site_groups.length > 0 ? respData.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                },() => {
                    let ParamObject                 =       new URLSearchParams(this.props.location.search);
                    if(ParamObject.get('search_site_ids') || ParamObject.get('entry_date_range') || ParamObject.get('activity_transaction_delay') ) {
                        let updateFilterForm = {'entry_date_range' : ParamObject.get('entry_date_range'),'activity_transaction_delay' : ParamObject.get('activity_transaction_delay') };
                      
                      if(ParamObject.get('search_site_ids')){
                           updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                       }
    
                       setTimeout(() => {
                           this.setState({activitySearchForm : {...this.state.activitySearchForm,...updateFilterForm}},
                           function(){
                                this.activitySearchFormSubmit();
                               });
                       }, 1)
                     
                    }else{
                        this.getAssetActivityListing(this.state.page);
                    }
                })
                   
            }) 
    }

    activitySearchModalInit        =       ()      =>      {
        this.activitySearchModal.show()
    }

    activitySearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems       :   [],
            activitySearchParams    :   null,
            searchedAssets           :   [],
            searchedEmployees       :      [],
            activitySearchForm      :   {...this.activitySearchInitState}
        }, () => {
            this.activitySearchModal.hide();
            this.getAssetActivityListing(1)
        });
    }

    activitySearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.activitySearchForm);
        let searchedElems               =   [];
        let activitySearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.activitySearchFormLabel[key];
            let value                       =   this.state.activitySearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                activitySearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activities') {
                        show_val            =  this.state.allActivitesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activity_type') {
                        show_val            =  this.state.allActivityTypeList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_operators') {
                        show_val            =   this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.activitySearchForm && this.state.activitySearchForm.search_transaction_id ? this.state.activitySearchForm.search_transaction_id : '';
                    }
                    if(key == 'activity_transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.activitySearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.activitySearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.activitySearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.activitySearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            activitySearchParams      :       activitySearchParams
        }, () => {
            this.activitySearchModal.hide();
            this.getAssetActivityListing(1)
        });
    }

    activityListjsx     =    ()   =>   {
        return (<Ax>
            <div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Asset Activity List</h3>
                <div className="text-end mt15">
                    <button type="button"  disabled={this.state.listing_loading} onClick={this.activitySearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <button type="button" className="btn btn-secondary"
                        disabled={this.state.file_downloading || this.state.listing_loading}
                        onClick={this.activityDownloadHandler} >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.file_downloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>{this.state?.activityData?.activity_name}</h3>
                            <div className="text-end mt15">
                                <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.activityListingTable()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.activityViewJsx()}</div> : null }
                </div>
            </div>
        </Ax>);
    }

    activityListingTable                   =   ()  =>  {
        let details = this.state.activityListing
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.activitySearchClear} />
                <table className="table table-bordered bg-white table-hover table-fixed table-sm">
                    <thead className="table-secondary">
                        {this.state.minimizeTable
                            ? (<tr>
                                <th scope="col" style={{ width: "20%" }}>Activity</th>
                            </tr>)
                            : (<tr>
                                <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                <th scope="col" style={{ width: "10%" }}>Transaction Date</th>
                                <th scope="col" style={{ width: "13%" }}>Activity Name</th>
                                <th scope="col" style={{ width: "16%" }}>Asset Name </th>
                                <th scope="col" style={{ width: "16%" }}>Asset Type </th>
                                <th scope="col" style={{ width: "12%" }}>No. of Trips/Sessions</th>
                                <th scope="col" style={{ width: "13%" }}>Workstation (From-To)</th>
                                <th scope="col" style={{ width: "10%" }}>Counterlog Reading</th>
                                <th scope="col" style={{ width: "5%" }}>Action</th>
                            </tr>)
                        }

                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? (<tr><td colSpan="10"><Loader /></td></tr>)
                            : (this.state.activityListing && this.state.activityListing.length > 0
                                ? (this.state.activityListing.map((item, index) => {
                                    return (<tr key={index} >
                                        {
                                            this.state.minimizeTable
                                                ? (
                                                    <Ax>
                                                        <td style={{ width: "25%" }} onClick={() => this.viewActivity(item.asset_activity_id)} >
                                                            <div className="text-capitalize link-primary cursor_pointer">{item.activity_name ? item.activity_name : '-'}</div>
                                                            <div className="mt-1">
                                                                <small className="text-capitalize ">{item.activity_count ? <span>{item.activity_count} ({item.activity_type_name})</span> : '-'} </small>
                                                                <small className="float-end">{item.counterlog && item.counterlog.feed_date_display ? item.counterlog.feed_date_display : "-"}</small>
                                                            </div>
                                                        </td>
                                                    </Ax>
                                                )
                                                : (<Ax>
                                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                    <td className='text-center' title={item.asset_activity_id}>{item.counterlog && item.counterlog.feed_date_display ? item.counterlog.feed_date_display : "-"}</td>
                                                    <td className="text-capitalize link-primary cursor_pointer" onClick={() => this.viewActivity(item.asset_activity_id)}>
                                                        {item.activity_name ? item.activity_name : "-"}</td>
                                                    <td>{item.asset && item.asset.name ? item.asset.name : "-"}</td>
                                                    <td>{item.asset && item.asset.asset_type_name ? item.asset.asset_type_name : "-"}</td>
                                                    <td>{item.activity_count ? <span>{item.activity_count} ({item.activity_type_name})</span> : '-'}</td>
                                                    <td>{item.workstation_from_name} - {item.workstation_to_name}</td>
                                                    <td>{item.counterlog && item.counterlog.display
                                                        ? <span className="text-start">{item.counterlog.display.filter(s => s.label !== "Date" ? true : false).map((item, index) => {
                                                            return (<div className="mt-1">{item.label + ': ' + item.reading}</div>)
                                                        })}</span>
                                                        : "No Record"}</td>
                                                    <td className="text-center">
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />

                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" role="button" onClick={() => this.viewActivity(item.asset_activity_id)}  >View</a></li>

                                                        </ul>
                                                    </td>
                                                </Ax>
                                                )}
                                    </tr>)
                                }))
                                : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                            )
                        }

                    </tbody>

                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.getAssetActivityListing(e.selected + 1)}
                />
            </Ax>
        );
    }

    activityViewJsx                      =       ()             =>        {
        let details = this.state.activityData;
        return (<div className="tab_content_header" style={{height : "81vh"}}>
            {this.state.view_loading ? <Loader/>
            : <div className="row">
            <div className="col-9 pr-1">
                {this.state.activityData ? <AssetActivityView activityData = {this.state.activityData} /> : null}
            </div>
            <div  className="col-3 pl0  ">
              
                {details &&  !details.asset_id  ? null  : <div> {this.state.card_loading ? <Loader/> : 
                     <AssetCard assetCardDetails={this.state.assetCardDetails} refreshAssetDetail={() => this.getAssetCard()} />}</div>}
            </div>
        </div>}
        </div>)
    }

    activitySearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="activitySearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="activitySearchModalLabel">Activity Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.activitySearchFormSubmit} id="activitySearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.activitySearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "activitySearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Activity Name</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allActivitesList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_activities')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allActivitesList.filter(s => this.state.activitySearchForm.search_activities.includes(s.value))}
                                            placeholder="Select Activity"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Activity type</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allActivityTypeList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_activity_type')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allActivityTypeList.find(s => this.state.activitySearchForm.search_activity_type == s.value)}
                                            placeholder="Select Activity Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                {/* <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Operators/Users</label>
                                    <div className="col-sm-10">
                                        <InputEmployeeSearch
                                            changeEvent={(selectedOption) => {

                                                this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_operators');
                                                this.setState({
                                                    activitySearchForm: { ...this.state.activitySearchForm, search_operators: selectedOption.length > 0 ? selectedOption.map(o => o.enc_id) : [] },
                                                    searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                });
                                            }}
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            placeholder="Select Ooperators/Users"
                                            value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                        />
                                    </div>
                                </div> */}
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type Group</label>
                                    <div className="col-sm-10">
                                    <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    activitySearchForm: {
                                                        ...this.state.activitySearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.activitySearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.activitySearchForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Group</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allSiteGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    activitySearchForm: {
                                                        ...this.state.activitySearchForm,
                                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allSiteGroupsList.filter(s => this.state.activitySearchForm.site_group_ids.includes(s.value))}
                                            placeholder="Select Site Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Executed Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activitySearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.activitySearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Executed Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'activitySearchForm', 'activity_transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.activitySearchForm.activity_transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.activitySearchForm.entry_date_range}
                                            startDate={this.state.activitySearchForm.entry_date_range_start}
                                            endDate={this.state.activitySearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    activitySearchForm
                                                        : {
                                                        ...this.state.activitySearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.activitySearchForm.date_range}
                                            startDate={this.state.activitySearchForm.date_range_start}
                                            endDate={this.state.activitySearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    activitySearchForm
                                                        : {
                                                        ...this.state.activitySearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render = () => {
        return (<ApplicationLayout>
         <Helmet><title>Asset Activity List</title></Helmet>
            {this.activityListjsx()}
            {this.activitySearchModalJsx()}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],     
    };
};

export default connect(mapStateToProps)(AssetActivityTransactionListing);