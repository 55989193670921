import React from 'react';
import Ax from '../../components/hoc/Ax';
import {connect} from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import tapIcon from "../../services/TapIcon"

class ApplicationSidebar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state      =   {
            isMinimizeSidebar   :   false,
            opensubmenu_id      :   null,
        };
         
        this.sidebarMenu           =   [
            { to: '/my_dashboard', name: 'My Dashboard', icon: tapIcon.faHome, activate_links: ['/my_dashboard'], can_access: false, can_display: true },
            {
                to: null, name: 'Assets', icon: tapIcon.faCarAlt,
                activate_links: ['/asset/:tab/:id', '/assets/logsheet/:id', '/assets/service_checksheet/:id', 
                '/assets/execution_history/:id', '/assets/invoice_calculation/:id', '/assets/ticket/:id', '/assets/settings/:id'],
                can_access: false, can_display: true,
                sub_menus: [
                    //                    {to : '/inventory_asset_list', name : 'Inventory Assets',can_access : false,can_display : true},
                    { to: '/measurable_asset', name: 'Measurable Assets', can_access: false, can_display: true },
                    { to: '/non_measurable_asset', name: 'Non Measurable Assets', can_access: false, can_display: true },
                    //    {to : '/weekly_counterlog', name : 'Weekly Logsheet',can_access : false,can_display : true},
                    { to: '/daily_counterlog', name: 'Daily Logsheet', can_access: false, can_display: true },
                ]
            },
            {
                to: '/report', name: 'Reports', icon: tapIcon.faChartBar,
                activate_links: ['/reports','/report/shiftwise_asset_running_with_ot','/report/asset_performance_matrix','/report/item_wise_requisition','/report/item_wise_consumption',
                '/report/asset_status','/report/daily_status_report','/report/service_checksheet_running_status','/report/item_wise_purchase_order','/report/item_wise_purchase','/report/inventory_forecasting_report',
                '/report/wallet_ledger','/report/wallet_ledger','/report/wallet_summary','/report/asset_invoice_calculator','/report/item_stock_transaction_ledger','/report/item_closing_stock'], 
                can_access: false, can_display: true
            }, ,
            {
                to: null, name: 'Transactions', icon: tapIcon.faCogs, activate_links: [], can_access: false, can_display: true,
                sub_menus: [
                    { to: '/asset_activity_transaction', name: 'Asset Activity', can_access: false, can_display: true },
                    // { to: '/counterlog_history_list', name: 'Counterlog', can_access: false, can_display: true },
                    { to: '/downtime_list', name: 'Downtime', can_access: false, can_display: true },
                    { to: '/engagement_time_list', name: 'Engagement Time', can_access: false, can_display: true },
                    { to: '/fuel_feedin_list', name: 'Fuel FeedIn', can_access: false, can_display: true },
                      { to: '/productivity_list', name: 'Productivity', can_access: false, can_display: true },
                    { to: '/service_checksheet_execution_history', name: 'Service Checksheet', can_access: false, can_display: true },
                    { to: '/task_transaction_list', name: 'Task', can_access: false, can_display:  !this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") ? false :  true },
                    { to: '/ticket', name: 'Ticket', can_access: false, can_display: true },
                ]
            },
        //    {to : null, name : 'Mobilization' , icon : tapIcon.faWarehouse, activate_links : [],
        //        sub_menus : [
        //            {to : '/site_transaction_history', name : 'Site Transaction'},

        //        ]
        //    },
            {to : null, name : 'Inventory Module' , icon : tapIcon.faThLarge,
             activate_links : ['/item_list/add_item','/item_demand_add','/purchase_return_add','/inventory_adjustment_add','/logistic_add','/warehouse_indent_add','/indent_fulfillment','/purchase_list','/purchase_list/:id?','/requisition_list/add','/purchase_order/add','/purchase_list/add','/allocation_list/add','/consumption_list/add','/inventory_transfer_list/add', 'purchase_return_list', 'purchase_return_add'],
             can_access : !this.props.permissions.group_modules.includes("isc") ? true : false,can_display : true,
                sub_menus : [
                    {to : '/item_list', name : 'Item ',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-item-view') ? false : true},
                    {to : '/warehouse_indent_list', name : 'Warehouse Indent',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-warehouse-indent-view') ? false : true},
                    {to : '/requisition_list', name : 'Purchase Requisition ',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-purchase-requisition-view') ? false : true},
                    {to : '/purchase_order_list', name : 'Purchase Order ',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-po-view') ? false : true},
                    {to : '/purchase_list', name : 'Purchase',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-purchase-view') ? false : true},
                    {to : '/purchase_return_list', name : 'Purchase Return',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-purchase-return-view') ? false : true},
                    {to : '/item_demand_list', name : 'Item Demand',can_access : false,can_display : !this.props.permissions.permissions.includes('isc_item_demand_view') ? false : true},
                    {to : '/allocation_list', name : 'Allocation',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-allocation-view') ? false : true},
                    {to : '/consumption_list', name : 'Consumption',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-consumption-view') ? false : true},
                    {to : '/inventory_transfer_list', name : 'Inventory Transfer',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-inventory_transfer-view') ? false : true},
                    {to : '/inventory_adjustment_list', name : 'Inventory Adjustment',can_access : false,can_display : !this.props.permissions.permissions.includes('isc-inventory-adjustment-view') ? false : true},
                    {to : '/logistic_list', name : 'Logistic',can_access : false, can_display : this.props.permissions.permissions.includes('logistic_view') ? true : false},
                    //  {to : '/itemwise_scrap_list', name : 'Itemwise Scrap ',can_access : false,},
                //    {to : '/item_voucher_list', name : 'Item Voucher',can_access : false},
                    //  {to : '/scrap_item_list', name : 'Scrap Voucher',can_access : false},
                ]
            },
            {to : null, name : 'Sales CRM' , icon : tapIcon.faThLarge,
                activate_links : ['/quotation_list','/quotation_add','/customer_project_list','/customer_project_add','/deals','/deals/add','/sales_order_list','/sales_allocation_list','/sales_invoice_list'],
                can_access : true,can_display : true,
                   sub_menus : [
                    {to : '/customer_project_list', name : 'Customer Project ',can_access : false,can_display :this.props.permissions.permissions.includes('customer-project-view') ? true : false},
                    {to : '/deals', name : 'Deals ',can_access : false,can_display :this.props.permissions.permissions.includes('deal_view') ? true : false},  
                    {to : '/quotation_list', name : 'Quotation  ',can_access : false,can_display :this.props.permissions.permissions.includes('quotation-view') ? true : false},
                    {to : '/sales_order_list', name : 'Sales Order  ',can_access : false,can_display :this.props.permissions.permissions.includes('quotation-view') ? true : false},
                    {to : '/sales_allocation_list', name : 'Sales Allocation  ',can_access : false,can_display :this.props.permissions.permissions.includes('quotation-view') ? true : false},
                    // {to : '/sales_invoice_list', name : 'Sales Invoice  ',can_access : false, can_display :this.props.permissions.permissions.includes('quotation-view') ? true : false},
                   ]
               },
            {to : null, name : 'HR Module' , icon : tapIcon.faUser, activate_links : ['/hrm/daily_attendance', '/employee_asset_list','/employee_list'],can_access : !this.props.permissions.group_modules.includes("hrm") ? true : false,can_display : true,
            sub_menus : [

                {to : '/hrm/daily_attendance' , name : 'Daily Attendance',can_access : false,can_display : true},
                {to : '/employee_list' , name : 'Employee',can_access : false,can_display : true},
                {to : '/employee_asset_list' , name : 'Employee Asset',can_access : false,can_display : true},
                   
            ]
        },
            {to : null, name : 'Accounts Module' , icon : tapIcon.faFileInvoiceDollar, activate_links : ['/employee_add'],can_access : false,can_display : true,
            sub_menus : [
                {to : '/composite_expense' , name : 'Composite Expense',can_access : false,can_display : true},
                {to : '/expense_list' , name : 'Expense',can_access : false,can_display : true},
                {to : '/fund_transfer', name : 'Fund Transfer',can_access : false,can_display : !this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet") ? false :  true},
                {to : '/invoice_calculation_list', name : 'Invoice Calculation',can_access : false,can_display : true},
                {to : '/wallet', name : 'Wallet',can_access : !this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet") ? true : false ,can_display : true},
            ]
        },
        {to : null, name : 'Project Mgmt' , icon : tapIcon.faProjectDiagram, activate_links : ['/project_structure','/project','/project/:id'], can_access: false, can_display:  false,
            sub_menus : [
                {to : '/project_structure' , name : 'Project Template',can_access : false,can_display : true},
                {to : '/project' , name : 'Project',can_access : false,can_display : true},
            ]
        },
        {to : null, name : 'Site Productivity' , icon : tapIcon.faUser, activate_links : ['/site_productivity_configuration'],can_access : !this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-site-productivity") ? true : false,can_display : true,
            sub_menus : [
                
                {to : '/site_productivity_configuration' , name : 'Configuration',can_access : false,can_display : true},
                {to : '/site_productivity' , name : 'Site Productivity',can_access : false,can_display : true},
                   
            ]
        },
       
            {to : null, name : 'Group Data' , icon : tapIcon.faWarehouse,
             activate_links : ['/fuelstation_add','/user_add','/vendor/add'],can_access : false,can_display : true,
                sub_menus : [
                    {to : '/customer_list', name : 'Customer',can_access : false,can_display : true},
                    {to : '/document_list', name : 'Document',can_access : false,can_display : true},
                    {to : '/fuelstation_list', name : 'Fuel Station',can_access : false,can_display : true},
                    {to : '/sites', name : 'Sites',can_access : false,can_display : true},
                    {to : '/sub_assembly', name : 'Sub Assembly',can_access : false,can_display : true},
                    {to : '/user_list', name : 'User',can_access : false,can_display : true},
                    {to : '/vendor', name : 'Vendor',can_access : false,can_display : !this.props.permissions.permissions.includes('vendor-view') ? false : true},
                    {to : '/warehouse', name : 'Warehouse',can_access : false,can_display : !this.props.permissions.group_modules.includes("isc") ? false : true}, 
                    {to : '/workstation_list', name : 'Workstation',can_access : false,can_display : true},
                ]
            },
            {to : null, name : 'Integration' , icon : tapIcon.faExchangeAlt,
            activate_links : ['/report/hg_task_sap_report','/master_data_integration','/master_data_integration_log'],can_access : false,can_display : true,
               sub_menus : [
                   {to : '/iot_integration', name : 'IOT Integration', can_access : false, can_display :  !this.props.permissions.group_sub_modules.find(s => s.sub_module == "iot") ? false : true},
                   {to : '/report/hg_task_sap_report', name : 'Task SAP Report',can_access : false,can_display : !this.props.permissions.group_sub_modules.find(s => s.sub_module == "hg_sap") ? false : true},
                  {to : '/master_data_integration', name : 'New Assets',can_access : false,can_display : !this.props.permissions.group_sub_modules.find(s => s.sub_module == "hg_sap") ? false : true},
                  {to : '/master_data_integration_log', name : 'Master Integration Log',can_access : false,can_display :  !this.props.permissions.group_sub_modules.find(s => s.sub_module == "hg_sap") ? false : true},
                  {to : '/supplier_vendor_list', name : 'Supplier Vendor List',can_access : false,can_display :  !this.props.permissions.group_sub_modules.find(s => s.sub_module == "hg_sap") ? false : true}
                ]
           },
            
            {to : null, name : 'Resources' , icon : tapIcon.faChartBar, activate_links : [],can_access : false,can_display : true,
                sub_menus : [
                      {to : '/asset_matrix', name : 'Asset Matrix',can_access : false,can_display : true},
                      {to : '/asset-invoice-rules', name : 'Asset Invoice Linking',can_access : false,can_display : true},
                       {to : '/asset_roster_list', name : 'Asset Roster Linking',can_access : false,can_display : true},
                      // {to : '/employee_roster_linking', name : 'Employee Roster Linking',can_access : false,can_display : true},
                      {to : '/invoice_rule', name : 'Invoice Rule',can_access : false,can_display : true},
                    {to : '/ot_rule_list', name : 'Overtime Rule',can_access : false,can_display : true},
                    {to : '/roster_list', name : 'Roster ',can_access : false,can_display : true},
                ]
            },
            {to : null, name : 'Setting' , icon : tapIcon.faCogs, activate_links : ['/sales_configuration/:config','/general_configuration/:config','/inventory_configuration/:config','/configuration/:config'],can_access : false,can_display : true,
                sub_menus : [
                    {to : '/general_configuration/accounting_code', name : 'General',can_access : false,can_display :  !this.props.permissions.permissions.includes('group-configuration') ? false : true},
                    {to : '/configuration/activity', name : 'Configuration (Assets)',can_access : false,can_display : !this.props.permissions.permissions.includes('iam-configuration') ? false : true},
                    {to : '/inventory_configuration/warehouse', name : 'Configuration (Inventory)',can_access : false,can_display : !this.props.permissions.group_modules.includes("isc") || !this.props.permissions.permissions.includes('isc-configuration') ? false : true},
                    {to : '/hrm_configuration/employee_type', name : 'Configuration (HRM)',can_access : false,can_display : true},
                    {to : '/sales_configuration/allocation', name : 'Configuration (Sales)',can_access : false,can_display :  !this.props.permissions.group_modules.includes('scrm') ? false : true},
                    {to : '/item_opening_balance', name : 'Item Opening Balance ',can_access : false,can_display : !this.props.permissions.group_modules.includes("isc") ? false : true},
                    {to : '/roles', name : 'Roles',can_access : false,can_display : true},
                ]
            },
           
        ] 
    }
    
    showHideSubMenu                 =   (submenu_id = null)  =>  {
        
        if(this.state.opensubmenu_id == submenu_id) {
            this.setState({'opensubmenu_id' : null});
        } else {
            this.setState({'opensubmenu_id' : submenu_id});
            if(document.getElementById('wrapper').classList.contains('min_sidebar')) {
                document.getElementById('wrapper').classList.remove('min_sidebar');
            }
        }
    }
    
    showSubMenuLinks            =   (submenu_id = null)  =>  {
        this.setState({'opensubmenu_id' : submenu_id});
    }
    

    sidebarCollapse                 =   ()  =>  {
        let isMinimize              =   document.getElementById('wrapper').classList.contains('min_sidebar');
        if(isMinimize) {
            document.getElementById('wrapper').classList.remove('min_sidebar');
        } else {
            document.getElementById('wrapper').classList.add('min_sidebar');
        }
        this.setState({isMinimizeSidebar : !isMinimize});
    }
    
    render () {
        let wallet_permission = this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet");
        let atrendance_permission = this.props.permissions.group_sub_modules.find(s => s.sub_module == "hrm_employee_attendance");
        return (
            <div className="sidebar">
                <button className="collapse_sidebar_btn" onClick={this.sidebarCollapse}>
                    {this.state.isMinimizeSidebar
                        ? <tapIcon.FontAwesomeIcon icon={tapIcon.faAngleRight} className="icon" />
                        : <tapIcon.FontAwesomeIcon icon={tapIcon.faAngleLeft} className="icon" />
                    }
                </button>
                <ul className="sidebar-menu">
                    {
                        this.sidebarMenu.map((m, k) => {

                            let activate_links = m.activate_links ? m.activate_links : [];
                            if (m.name == "Inventory Module" && !this.props.permissions.group_modules.includes("isc")) {
                                 m.can_access = "true"; 
                                m.to = '/access_denied/inventory';

                            }
                            if (m.name == "HR Module" && !this.props.permissions.group_modules.includes("hrm")) {
                                m.can_access = "true"; 
                               m.to = '/access_denied/human_resource';

                           }
                            if (m.name == "Site Productivity" && !this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-site-productivity")){
                                m.can_access = "true";
                                m.to = '/access_denied/site_productivity';

                            }
                            if (m.name == "Integration" && m.sub_menus && m.sub_menus.length > 0 && !m.sub_menus.find(submenu => submenu.can_display == true)){
                                m.can_access = "true";
                                m.to = '/access_denied/integration';

                            }
                            if (m.name == "Sales CRM" && m.sub_menus && m.sub_menus.length > 0 && !m.sub_menus.find(submenu => submenu.can_display == true)){
                                m.can_access = "true";
                                m.to = '/access_denied/sales_crm';

                            }

                            if (m.name == "Project Mgmt" && !this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-project-management")){
                                m.can_access = "true";
                                m.to = '/access_denied/project';
                            }

                            if (m.sub_menus && m.sub_menus.length > 0) {
                                if (m.name == "Accounts Module" && !wallet_permission) {
                                    let name = m.sub_menus.find(sm => sm.name == "Wallet");
                                    name.can_access = "true"
                                    name.to = '/access_denied/wallet'
                                }
                                if (m.name == "HR Module" && !atrendance_permission) {
                                    let name = m.sub_menus.find(sm => sm.name == "Daily Attendance");
                                    name.can_access = "true"
                                    name.to = '/access_denied/daily_attendance'
                                }
                                if (m.name == "Setting" && this.props.permissions && this.props.permissions.permissions &&  !this.props.permissions.permissions.includes('iam-user-role-view')) {
                                    let name = m.sub_menus.find(sm => sm.name == "Roles");
                                    name.can_access = "true"
                                    name.to = '/'
                                }
                               
                                if (m.name == "Group Data" && this.props.permissions && this.props.permissions.permissions &&  !this.props.permissions.permissions.includes('grp-user-view')) {
                                    let name = m.sub_menus.find(sm => sm.name == "User");
                                     name.can_access = "true"
                                    name.to = '/'
                                }
                                m.sub_menus.forEach(sm => activate_links.push(sm.to));
                            }
                            
                            return (<li key={k} className={['item', activate_links.includes(this.props.match.path) ? 'active' : ''].join(' ')}>
                                {m.to 
                                    ? (<Link to={m.to} className="menu-btn" onClick={() => this.showHideSubMenu(k)}>
                                        <tapIcon.FontAwesomeIcon icon={m.icon} className="icon" /> <span>{m.name} {m.can_access == "true" ?
                                            <tapIcon.FontAwesomeIcon className="submenu_icon" style={{ top: "10px" }} icon={tapIcon.faLock} />
                                            : null}</span>
                                    </Link>)
                                    : (<Ax>
                                        <Link to="#" className="menu-btn" onClick={() => this.showHideSubMenu(k)}>
                                            <tapIcon.FontAwesomeIcon icon={m.icon} className="icon" /> <span>{m.name}</span>

                                            <tapIcon.FontAwesomeIcon className="submenu_icon" icon={(this.state.opensubmenu_id == k || activate_links.includes(this.props.match.path)) ? tapIcon.faAngleDown : tapIcon.faAngleLeft} />
                                        </Link>
                                        <div className={['sub_menu', (this.state.opensubmenu_id === k || activate_links.includes(this.props.match.path)) ? 'open' : ''].join(' ')}>
                                            {m.sub_menus.map((sm, sk) => sm.can_display ? <Link key={sk} to={sm.to} onClick={() => this.showSubMenuLinks(k)} className={this.props.match.path == sm.to ? 'active' : ''}> <span>{sm.name}  {sm.can_access == "true" ?
                                            <tapIcon.FontAwesomeIcon style={{
                                                position: "absolute",
                                                right: "20px",
                                                top : "10px",
                                                color: "#fff",
                                            }} icon={tapIcon.faLock} />
                                                : null}</span> </Link> : null)}
                                        </div>
                                    </Ax>)
                                }
                            </li>);
                        })
                    }
                </ul>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        loading             :           state.auth.loading,
        error               :           state.auth.error,
        isAuthenticated     :           state.auth.access_token !== null,
        permissions         :           state.app.acl_info
    }
}

export default connect(mapStateToProps, null)(withRouter(props => <ApplicationSidebar {...props}/>));
