import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import TapSelect from '../../../components/ui/TapSelect';
import { Helmet } from 'react-helmet';
import { Modal } from 'bootstrap';
import { Link } from 'react-router-dom';
import download from '../../../services/DownloadFile'
import moment from 'moment';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ConsumptionHistoryModal from '../consumption/ConsumptionHistoryModal';
import POHistoryModal from './POHistoryModal';
import ItemTransactionLedger from '../includes/ItemTransactionLedger';
import RequisitionDetailModal from '../requisition/RequisitionDetailModal';
import VendorDetailModal from '../../groupData/vendor/VendorDetailModal';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import LogisticViewModal from '../logistic/LogisticViewModal';
import TapInputNumber from '../../../components/ui/TapInputNumber';

class PurchaseOrderList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initPOSearchForm      =       {
            tagged_user_filter              :   '',
            search_transaction_id           :   '',
            search_vendor_ids               :   [],
            search_warehouse_ids            :   [],
            search_purchase_order_type      :   '',
            date_range                      :   null,
            date_range_start                :   null,
            date_range_end                  :   null,
            search_item_ids                 :   [],
            search_item_tags                :   [],
            search_status_ids               :   [],
            search_priority                 :   '',
            search_approved_aging           :   '',
            search_voucher_aging            :   '',
            search_release_aging            :   '',
            tagged_user_ids                 :   [],
        }

        this.poSearchFormLabel       =       {
            tagged_user_filter              :   'User Tagging : ',
            search_transaction_id           :   'Transaction ID : ',
            search_warehouse_ids            :   'Warehouses : ',
            search_purchase_order_type      :   'Purchase Order Type : ',
            date_range                      :   'Date Range : ',
            search_item_ids                 :   'Items : ',
            search_item_tags                :   'Item Tags : ',
            search_status_ids               :   'Search Status : ',
            search_priority                 :   'Search Priority : ',
            search_vendor_ids               :   'Vendor : ',
            search_approved_aging           :   'Approval Ageing : ',
            search_voucher_aging            :   'Voucher Ageing : ',
            search_release_aging            :   'Released Ageing : ',
            tagged_user_ids                 :   'Tagged Users : ',
        }

        this.updateCommentFormInit            =        {
            status                            :         '',
            notes                             :         '',
        }

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                         =     {
            minimizeTable                       :   false,
            listing_loading                     :   false,
            listing_tbl_page                    :   1,
            po_listing                          :   [],
            listingMeta                         :   null,
            totalListingCount                   :   0,
            allItemTags                         :   [],
            poSearchForm                        :   {...this.initPOSearchForm},
            submittedPOFilterForm               :   {...this.initPOSearchForm},
            addCommentForm                      :   {...this.updateCommentFormInit},
            cancelledItemForm                   :   {...this.cancelItemFormInit},
            poSearchFormDataLoaded              :   false,
            all_warehouses                      :   [],
            all_periods                         :   [],
            searchedItem                        :   [],
            formSearchedElems                   :   [],
            searchedVendor                      :   [],
            poTransactionId                     :   null,
            detail_loading                      :   false,
            poDetail                            :   null,
            allStatusOptions                    :   [],
            allPriorityOptions                  :   [],
            allAllowedStatus                    :   [],
            addCommentFormSubmitting            :   false,
            pdfDownloading                      :   false,
            cancelledItemList                   :   [],
            cancel_item_loading                 :   false,
            disableCancelItem                   :   true,
            cancelItemFormSubmitting            :   false,
            itemDetails                         :   null,
            allPOTypes                          :   [],
            userFilters                         :   [
                {value  :   'tagged_me',        label : 'My Tagged PO'},
                {value  :   'created_by_me',    label : 'PO Created By Me'},
            ],
            allUsers                            :   [],
        };
        this.myRef                      =   React.createRef();
        this.itemDetailsRef             =   React.createRef();  
        this.consumptionHistoryRef      =   React.createRef();
        this.poHistoryRef               =   React.createRef();
        this.transactionLedgerRef       =   React.createRef();
        this.ReqDetailModalRef          =   React.createRef();
        this.VendorDetailModalRef       =   React.createRef();
        this.LogisticViewModalRef       =   React.createRef();

        this.purchaseOrderUrl           =   INVENTORY_API_BASE_URL_2 + '/purchase_order';
        this.searchFormDataUrl          =   this.purchaseOrderUrl + '/search_form_data';
        this.poReportUrl                =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase-order';
    }

    componentDidMount() {

        this.getPOSearchFormData();

        let ParamObject                    =       new URLSearchParams(this.props.location.search);
        if(ParamObject.get('search_status_ids') || ParamObject.get('search_voucher_ageing') || ParamObject.get('search_approval_ageing') || this.props.location && this.props.location.state && this.props.location.state.vendorPOData ){
            this.initializeData(this.props)
        }else{
            this.loadListingTblData(1);
            this.initializeData(this.props)
        }

        if(ParamObject.get('id') ){
            let id = ParamObject.get('id')
            this.viewPODetail(id)
        }
        this.poSearchModal                 =        new Modal(document.getElementById('poSearchModal'), {keyboard: false, backdrop :false});
        this.itemReqDetailModal            =        new Modal(document.getElementById('itemReqDetailModal'), {keyboard: false, backdrop :false});
        this.cancelledItemModal            =        new Modal(document.getElementById('cancelledItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
         }     
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            this.setState({
                all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                all_periods             :        all_periods ,
                allItemTags             :        pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
            });
        }
     
        if(pr.location && pr.location.state && pr.location.state.poId){
             this.viewPODetail(pr.location.state.poId)
         }

         if(pr.location && pr.location.state && pr.location.state.vendorPOData){
            let vendorPOData    =   pr.location.state.vendorPOData;
            let vendor         =   pr.location.state.vendorPOData.vendor;
            let updateFilterForm = {};
            let warehouse        =  pr.all_warehouses.find(us => us.id == vendorPOData.warehouse_id);
                if (warehouse) {
                    updateFilterForm['search_warehouse_ids'] = [warehouse.id];
                }
                updateFilterForm['search_vendor_ids'] = [vendor.id];
                updateFilterForm['date_range'] = vendorPOData.period;
                setTimeout(() => {
                    this.setState({
                        poSearchForm  : { ...this.state.poSearchForm, ...updateFilterForm},  
                        searchedVendor : [{value : vendor.id,label:vendor.name,display_label : vendor.name} ]                   
                    },
                        function () {
                            this.submitPOSearchForm();
                        });
                }, 1000)
        }

         let ParamObject                 =       new URLSearchParams(pr.location.search);
           if(ParamObject.get('search_voucher_ageing') || ParamObject.get('search_approval_ageing')|| ParamObject.get('search_released_ageing') ){
            let  status_ids  =  ParamObject.get('search_status_ids').split(',').map(Number);
            let updateFilterForm        =   {
                    search_voucher_aging : ParamObject.get('search_voucher_ageing') ?  ParamObject.get('search_voucher_ageing') : '',
                    search_approved_aging : ParamObject.get('search_approval_ageing') ?  ParamObject.get('search_approval_ageing') : '',
                    search_release_aging : ParamObject.get('search_released_ageing') ?  ParamObject.get('search_released_ageing') : '',
                    search_status_ids : status_ids
                };
                if (ParamObject.get('search_warehouse_ids')) {
                    let warehouse        =  pr.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                    if (warehouse) {
                        updateFilterForm['search_warehouse_ids'] = [warehouse.id];
                    }
                }
                if (ParamObject.get('tagged_user')) {
                    updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                }

                
                setTimeout(() => {
                    this.setState({
                        poSearchForm  : { ...this.state.poSearchForm, ...updateFilterForm},                      
                    },
                        function () {
                            this.submitPOSearchForm();
                        });
                }, 1000)
            }else if(ParamObject.get('search_status_ids') || ParamObject.get('search_warehouse_ids')){
                let  status_ids  =  ParamObject.get('search_status_ids').split(',').map(Number);
                let updateFilterForm = {search_status_ids : status_ids}
                if(ParamObject.get('search_warehouse_ids')) {
                 let warehouse                =   pr.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                 if(warehouse) {
                     updateFilterForm['search_warehouse_ids']         =   [warehouse.id];
                 }
 
              }
 
                 if (ParamObject.get('tagged_user')) {
                     updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                 }
                 setTimeout(() => {
                     this.setState({
                         poSearchForm  : { ...this.state.poSearchForm, ...updateFilterForm},                      
                     },
                         function () {
                             this.submitPOSearchForm();
                         });
                 }, 1000)
            }
    }

    openAddPurchaseOrderScreen       =      ()      =>     {
        this.props.history.push('/purchase_order/add')
    }

     //************************GET PO LISTING**************** */
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {...this.state.submittedPOFilterForm, page:page};
        HttpAPICall.withAthorization('GET', this.purchaseOrderUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                po_listing          :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    submitPOSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.poSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.poSearchFormLabel[key];
            let value                       =   this.state.poSearchForm[key];
           
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
           if(label) {
                let show_val            =   value;
                if(key == 'tagged_user_filter') {
                    show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_transaction_id') {
                    show_val            =   this.state.poSearchForm ? this.state.poSearchForm.search_transaction_id : '';
                }
                if(key == 'search_vendor_ids') {
                    show_val            =   this.state.searchedVendor ? this.state.searchedVendor.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_warehouse_ids') {
                    show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_item_ids') {
                    show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_item_tags') {
                    show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_status_ids') {
                    show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_priority') {
                    show_val            =   this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                }
                if(key == 'search_purchase_order_type') {
                    show_val            =   this.state.allPOTypes.filter((s) => value.includes(s.value)).map(s => s.label);
                }
                if(key == 'search_approved_aging') {
                    show_val            =   this.state.poSearchForm && this.state.poSearchForm.search_approved_aging ? this.state.poSearchForm.search_approved_aging  : '';
                }
                if(key == 'search_voucher_aging') {
                    show_val            =   this.state.poSearchForm && this.state.poSearchForm.search_voucher_aging ? this.state.poSearchForm.search_voucher_aging  : '';
                }
               
                if(key == 'search_release_aging') {
                    show_val            =   this.state.poSearchForm && this.state.poSearchForm.search_release_aging ? this.state.poSearchForm.search_release_aging  : '';
                }
                if(key == 'tagged_user_ids') {
                    show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'date_range') {
                    let  start_range         =      moment(this.state.poSearchForm.date_range_start).format('DD-MMM-YYYY');
                    let end_range            =      moment(this.state.poSearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedPOFilterForm       :   {...this.state.poSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.poSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    poSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems           :       [],
            poSearchForm                :       {...this.initPOSearchForm},
            submittedPOFilterForm       :       {...this.initPOSearchForm},
            searchedItem                :       [],
            searchedVendor              :       []
        }, () => {
            this.loadListingTblData(1);
            this.poSearchModal.hide();
        });
    }

    poClear                  =        ()             =>        {
        // this.setState({
        //     formSearchedElems           :       [],
        //     poSearchForm                :       {...this.initPOSearchForm},
        //     submittedPOFilterForm       :       {...this.initPOSearchForm},
        //     searchedItem                :       [],
        //     searchedVendor              :       []
        // }, () => {
        //     this.poSearchModal.hide();
        // });
    }

    //************************REQUISTION SEARCH FORM **************** */
    poSearchModalInit               =       ()             =>        {
        this.poSearchModal.show();
        this.getPOSearchFormData();
       
    }

    getPOSearchFormData             =       ()              =>      {
        if(!this.state.poSearchFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.searchFormDataUrl, this.props.access_token, null, null, (response) => {
             let formData = response.data
                this.setState({
                    poSearchFormDataLoaded  :   true,
                    allStatusOptions        :   formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allPriorityOptions      :   formData && formData.priorities && formData.priorities.length > 0 ? formData.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                    allUsers                :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                    allPOTypes                :   formData && formData.purchase_order_type && formData.purchase_order_type.length > 0 ? formData.purchase_order_type.map((s) => { return {value: s.key, label: s.name}; }) : [],
                });
            });
        }
    }

    openRequistionDetails       =       ()      =>      {
        this.itemReqDetailModal.show()
     }
 
     viewPODetail          =      (transaction_id)        =>     {
         this.setState({ minimizeTable: true })
         if(transaction_id !== this.state.poTransactionId){
            this.getPODetail(transaction_id)
         }
     }

     getPODetail        =       (transaction_id)      =>      {
        this.setState({detail_loading : true})
        HttpAPICall.withAthorization('GET',  this.purchaseOrderUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            
            this.setState({
                poDetail             :   respData.data,
                poTransactionId      :   respData.data.transaction_id, 
                allAllowedStatus     :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                addCommentForm       :   {...this.updateCommentFormInit , status : respData.data.status}
            });
            
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({detail_loading : false}));      
    }
 
    editPO              =       ()      =>      {
        this.props.history.push({ pathname: `/purchase_order/add`, state: { poData: this.state.poDetail } })
    }

    clonePO              =       ()      =>      {
        this.props.history.push({ pathname: `/purchase_order/add`, state: { clonePODetail: this.state.poDetail } })
    }

    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    //***********************DOWNLOAD PDF **************************** */
    downloadPdfHandler                  =   (transaction_id, use_for = 'internal')  =>  {
        this.setState({ pdfDownloading: true});
        HttpAPICall.withAthorization('GET',  this.purchaseOrderUrl + '/' + transaction_id, this.props.access_token,{download : "download", use_for : use_for},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({pdfDownloading: false}));
    }
    
    reportDownloadApiHandler            =   (transaction_id)  =>  {
        this.setState({ pdfDownloading: true});
        HttpAPICall.withAthorization('GET', this.poReportUrl, this.props.access_token,{search_transaction_id : transaction_id, download : 'download'},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({pdfDownloading: false}));
    }
    
    closeViewDetails           =      ()      =>    {
         this.setState({minimizeTable  :  false})
         this.props.history.push({ pathname: `/purchase_order_list`})
     }
 
     updateComment           =           ()          =>      {
         this.myRef.current.scrollIntoView()
     }

     //*************************SUBMIT COMMENT FORM ********************** */
    submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
             ...this.state.addCommentForm,
             transaction_id : this.state.poTransactionId
           }
           HttpAPICall.withAthorization('PUT', this.purchaseOrderUrl + '/comment', this.props.access_token, {}, { ...frmData }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.getPODetail(this.state.poTransactionId);
               this.loadListingTblData(1)
               this.setState({addCommentForm : {...this.updateCommentFormInit}})
           }).then(() => this.setState({ addCommentFormSubmitting : false }));
    }

    //*************************CANCEL ITEM PO ***** ********************** */
    checkAllCancelItem() {
        let poItemCollections      =   document.getElementsByClassName('po_item_id_checkbox')
        if(poItemCollections && poItemCollections.length > 0) {
            for (let i = 0; i < poItemCollections.length; i++) {
                poItemCollections[i].checked = document.getElementById('all_check_po').checked;
            }
        }
       
    }

    cancelPOItem         =       (transaction_id)          =>      {
        this.setState({cancel_item_loading : true})
        
        let cancelledItemList = this.state.cancelledItemList;
        var showErrorMessage = false;
        let poItemCollections      =   document.getElementsByClassName('po_item_id_checkbox');
        if(poItemCollections && poItemCollections.length > 0) {
            for (let i = 0; i < poItemCollections.length; i++) {
                if(poItemCollections[i].checked) {
                    
                    let itemId = poItemCollections[i].value;
                    let data = this.state.poDetail.items.find(i => i.item.id == itemId);
                    
                    let row                         =   {...cancelledItemList[i],...data };
                    cancelledItemList[i]            =   row;
                    let list = cancelledItemList.filter(Boolean)
                    this.setState({cancelledItemList   :   [...list],
                                   cancelledItemForm   :   {...this.state.cancelItemFormInit,
                                   notes : ''}}
                                   ,() => this.cancelledItemModal.show() , 
                                      
                                          this.setState({cancel_item_loading : false}))
                }
            }
        }

        if(this.state.cancelledItemList.length == 0 && !showErrorMessage){
            toast.error('Please select an Item for Cancellation', { position: toast.POSITION.TOP_RIGHT });
        }

    }

    changeCancelledItemHandler      =       (value,key1,key2,name)       =>      {
        let cancelledItemList        =      [...this.state.cancelledItemList];
        
        if (cancelledItemList[key1].req_allocation[key2]) {
            cancelledItemList[key1]["req_allocation"][key2][name] = value;
        }else{
            cancelledItemList[key1].new_cancelled_qty = value
        }

        this.setState({cancelledItemList : cancelledItemList})
    
    }

    viewConsumptionModalInit                       =   (data,k)  =>  {
        if (this.state.poDetail) {

            let poDetail = this.state.poDetail;

            if (data) {
                this.consumptionHistoryRef.current.modalInit(data, null, poDetail.asset_id)
            }
        }
         
    }

    viewPOHistoryModalInit                       =   (data,k)  =>  {
        if (this.state.poDetail) {

            let poDetail = this.state.poDetail;

            if (data) {
                this.poHistoryRef.current.modalInit(data, poDetail.warehouse_id)
            }
        }
         
    }

    
    viewTransactionLedgerModalInit               =   (data,k)  =>  {
        if (this.state.poDetail) {

            let poDetail = this.state.poDetail;
            if (data) {
                this.transactionLedgerRef.current.modalInit(data, poDetail.warehouse_id,poDetail.warehouse)
            }
        }
         
    }

    submitCancelItemForm            =       (e)      =>      {
        e.preventDefault()
        
        let items                   =        [...this.state.cancelledItemList];
        let itemsLists              =        items.map(i => {
        let new_cancelled_qty       =         i.new_cancelled_qty ? i.new_cancelled_qty : 0; 
        let total_req_qty           =         i.req_allocation  && i.req_allocation.length > 0 ?  parseFloat(i.req_allocation.reduce((s, b) => s + parseFloat(b.new_cancelled_qty? b.new_cancelled_qty : 0) , 0))  : 0;                                  
                                                    return {
                                                        po_item_id      :   i.po_item_id,
                                                        transaction_id  :   i.transaction_id,
                                                        qty             :    parseFloat(new_cancelled_qty) + total_req_qty ,
                                                        req_allocation  :   i.req_allocation && i.req_allocation.length > 0 ? i.req_allocation.map((r,i) => {return{qty : parseFloat(r.new_cancelled_qty) , req_item_id : r.req_item_id}}) : [],
                                                    }}); 
        
         let formData                =          {items : itemsLists,notes : this.state.cancelledItemForm.notes}
       
          this.setState({ cancelItemFormSubmitting: true })
          HttpAPICall.withAthorization('PUT', this.purchaseOrderUrl + '/cancel_item', this.props.access_token, {}, { ...formData }, (response) => {
              toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
              this.cancelledItemModal.hide()
              this.getPODetail(this.state.poTransactionId);
          }).then(() => this.setState({ cancelItemFormSubmitting: false }));
    }

    addPurchaseHandler                  =   ()  =>  {
        let po_item_ids                     =   [];
        let pendingQtyList                  =   [];
        let poItemCollections      =   document.getElementsByClassName('po_item_id_checkbox');
        if(poItemCollections && poItemCollections.length > 0) {
            for (let i = 0; i < poItemCollections.length; i++) {
                if(poItemCollections[i].checked) {
                   
                    po_item_ids.push(poItemCollections[i].getAttribute("data-poItemId"));
                    pendingQtyList.push(poItemCollections[i].getAttribute("data-pendingQty"));
                  
                }
            }
        }
        
        if (po_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(pendingQtyList.includes('0')) {
            toast.error('Every item must have pendning qty more than 0', { position: toast.POSITION.TOP_RIGHT });
        } else {
            let poStateData                    =   {   
                warehouse_id                :   this.state.poDetail && this.state.poDetail.warehouse_id,
                vendor_id                   :  this.state.poDetail && this.state.poDetail.vendor ? this.state.poDetail.vendor.id : '',
                vendor_name                 :   this.state.poDetail && this.state.poDetail.vendor ? this.state.poDetail.vendor.name : '',
                po_item_ids                 :   po_item_ids,
                currency                    :   this.state.poDetail && this.state.poDetail.currency ? this.state.poDetail.currency : "INR",
                conversion_rate             :   this.state.poDetail && this.state.poDetail.conversion_rate ? this.state.poDetail.conversion_rate : 1,
                items                       :   this.state.poDetail && this.state.poDetail.items && this.state.poDetail.items.length > 0 ? this.state.poDetail.items.map(s =>  s?.item?.id) : [],
            };

            this.props.history.push({ pathname: `/purchase_list/add`, state: { addPurchaseFromPO : poStateData } });
        }
    }

    reqDetailModalInit          =       (req_item_id)           =>      {
        this.ReqDetailModalRef.current.modalReqInit(req_item_id);  
    }

    vendorDetailModalInit       =       (id)        =>      {
        this.VendorDetailModalRef.current.modalInit(id)
    }

    reqDetailModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="itemReqDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemReqDetailModalLabel">PO Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-hover table-bordered my-3 ">
                                <thead className='table-secondary'>
                                    <tr className='text-end'>
                                        <th scope="col" style={{ width: "5%" }} className='text-center'>Sr.No</th>
                                        <th scope="col" style={{ width: "15%" }} className='text-center'>Transaction ID</th>
                                        <th scope="col" style={{ width: "17%" }} className='text-center'>Date of Transaction</th>
                                        <th style={{ width: "15%" }}>Req Qty</th>
                                        <th style={{ width: "12%" }}>PO Qty</th>
                                        <th style={{ width: "12%" }}>Received</th>
                                        <th style={{ width: "12%" }}>Cancelled</th>
                                        <th style={{ width: "12%" }}>Pending</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   <tr className='text-end'>
                                       <td className='text-center'>1</td>
                                       <td className='text-center'>REQ-33</td>
                                       <td className='text-center'>01-Feb-2022</td>
                                       <td>10</td>
                                       <td>10</td>
                                       <td>10</td>
                                       <td>10</td>
                                       <td>10</td>
                                   </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //***********************CANCEL ITEM JSX****************
    cancelledItemModalJsx               =        ()             =>        {
        
        return (
            <div className="modal fade" id="cancelledItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Cancel Item </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white  table-sm">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" style={{ width: "28%" }} className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "9%" }} className="text-center">Requisition</th>
                                                    <th scope="col" style={{ width: "9%" }}>Qty</th>
                                                    <th scope="col" style={{ width: "9%" }}>Received</th>
                                                    <th scope="col" style={{ width: "9%" }}>Cancelled</th>
                                                    <th scope="col" style={{ width: "9%" }}>Pending</th>
                                                    <th scope="col" style={{ width: "9%" }}>New Cancelled </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.length > 0
                                                    ? (this.state.cancelledItemList.map((i, k) => {
                                                        let req_allocation = []
                                                        
                                                        req_allocation = i.additional_qty > 0 ? [...i.req_allocation, { requisition_transaction_id: "ADDL", qty: i.additional_qty ,pending_qty : Number(i.pending_qty) - i.req_allocation?.map(s => Number(s.unreceived_qty)).reduce((a, b) => a + b, 0)}] : i.req_allocation;
                                                        return (
                                                            <Ax>

                                                                {req_allocation && req_allocation.length > 0 ?
                                                                    req_allocation.map((req, key) => {
                                                                        
                                                                       let pending_qty =  req && req.unreceived_qty ? req.unreceived_qty : req.pending_qty;
                                                                       
                                                                        return (
                                                                            <tr>
                                                                                {key == 0 ?
                                                                                    <Ax>
                                                                                        <td rowSpan={req_allocation?.length > 1 ? req_allocation.length : 1} className="align-middle ">{k + 1}</td>
                                                                                        <td  rowSpan={req_allocation?.length > 1 ? req_allocation.length : 1}  className="align-middle ">{i.item ? <div><span className="fw-bold" rowSpan={2}>{i.item.name}</span><br />
                                                                                            <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                                                                            <div className="form-text"><b>Mfr: </b>{i.item?.manufacturer?.name ?? '-'} (MPN : {i.item?.manufacturer_part_no ?? "-"} )</div>
                                                                                            <div className="form-text"> {
                                                                                                i.item?.tags?.length > 0
                                                                                                    ? (i.item?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                                                    : '-'
                                                                                            }</div>
                                                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                                                        </div>
                                                                                            : null}</td>
                                                                                    </Ax>
                                                                                    : null} 

                                                                                <td className="text-center">{req.requisition_transaction_id} </td>
                                                                                <td className="text-end"> {req.qty}</td>
                                                                                <td className="text-end"> {i.received_qty}</td>
                                                                                {key == 0 && <td className="text-end" rowSpan={req_allocation?.length > 1 ? req_allocation.length : 1}> {i.cancelled_qty}</td>}
                                                                                <td className="text-end">{pending_qty??0}</td>
                                                                                <td className="text-end"> <TapInputNumber 
                                                                                     onChange={(newValue) => {
                                                                                        this.changeCancelledItemHandler(newValue,k,key,'new_cancelled_qty')
                                                                                    }}
                                                                                    name="new_cancelled_qty"
                                                                                    value={req?.new_cancelled_qty}
                                                                                    className="form-control text-end"
                                                                                    placeholder="Cancelled Qty"
                                                                                    required={true}
                                                                                    max={pending_qty??0}
                                                                                
                                                                                /></td>
                                                                            </tr> )
                                                                    })
                                                                    : <tr className='text-center'><td colSpan={8}>No Record</td></tr>}
                                                            </Ax> )  })
                                                    ) : null
                                                }


                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                   >Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

    purchaseOrderListJsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Purchase Order</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button"   disabled={!this.props.permissions.includes('isc-po-add')} className="btn btn-primary" onClick={this.openAddPurchaseOrderScreen}>New PO</button> : null}
                    {(!this.state.minimizeTable) ? <button type="button" className="btn btn-secondary" onClick={this.poSearchModalInit} disabled={this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button> : null}
                    {this.state.minimizeTable && this.props.permissions.includes('isc-po-add') ?
                        <span className="dropdown">
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {this.state.minimizeTable ? <li><a className="dropdown-item" href="#" onClick={this.openAddPurchaseOrderScreen}>Add </a></li> : null}
                            </ul>
                        </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>{this.state.poDetail && this.state.poDetail.title ? this.state.poDetail.title : ""} </h3>
                            <div className="text-end mt15">
                             <button 
                                type="button"
                                className="btn btn-secondary" 
                                disabled={this.state.detail_loading || this.state?.poDetail?.status == 6 || this.state?.poDetail?.status == 7||  this.state?.poDetail?.status == 5} 
                                onClick={() => this.updateComment()} >Update Status </button>
                                 <button 
                                type="button"
                                className="btn btn-secondary" 
                                disabled={(this.state.detail_loading) || (this.state?.poDetail?.status !== 4 && this.state?.poDetail?.status !== 5) || !this.props.permissions.includes('isc-purchase-add')} 
                                onClick={() => this.addPurchaseHandler()} >Add to Purchase </button>
                            <button 
                                disabled={this.state.pdfDownloading || this.state.detail_loading}
                                type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.pdfDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : ''}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <Link className="dropdown-item" disabled={this.state.detail_loading} onClick={() => this.downloadPdfHandler(this.state.poTransactionId, 'internal')}>PDF for Internal Use</Link>
                                </li>
                                <li>
                                    <Link className={['dropdown-item', (this.state.detail_loading || this.state?.poDetail?.status < 4 || this.state?.poDetail?.status == 6) ? 'disabled' : ''].join(' ')} href="#"  onClick={() => this.downloadPdfHandler(this.state.poTransactionId, 'vendor')}>PDF for Vendor</Link>
                                </li>
                                <li>
                                    <Link className={['dropdown-item', (this.state.detail_loading || this.state?.poDetail?.status < 4) ? 'disabled' : ''].join(' ')} href="#"  onClick={() => this.reportDownloadApiHandler(this.state.poTransactionId)}>Item-Wise Report</Link>
                                </li>
                            </ul>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a href='#' className={['dropdown-item', (this.state.detail_loading || this.state.poDetail?.can_edit == "N" || !this.props.permissions.includes('isc-po-edit')) ? 'disabled' : ''].join(' ')} onClick={() => this.editPO(this.state.poTransactionId)}>Edit</a>
                                </li>
                                <li>
                                    <a href='#' className={['dropdown-item', (this.state.detail_loading) ? 'disabled' : ''].join(' ')} onClick={() => this.clonePO()}>Clone</a>
                                </li>
                                <li>
                                    <a 
                                        href='#' 
                                        className={['dropdown-item', (this.state.detail_loading) || !this.props.permissions.includes('isc-po-cancel_item') ? 'disabled' : ''].join(' ')} 
                                        onClick={() => {
                                            this.setState({cancelledItemList : []}, () => {
                                                this.cancelPOItem(this.state.poTransactionId)
                                            })}}
                                        >Cancel Item</a>
                                </li>
                           </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.purchaseOrderListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4 pageTbl bg-white">{this.purchaseOrderViewDetails()}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    purchaseOrderListingJsx            =   ()   =>   {
        return(<Ax>
          <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.poSearchClear} />
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                { this.state.minimizeTable
                    ? (<tr>
                        <th scope="col" style={{width:"15%"}}>Transaction ID (Date)</th>
                    </tr> )
                    :   ( <tr className="text-center">
                        <th scope="col" style={{width:"5%"}}>S.No</th>
                        <th scope="col" style={{width:"12%"}}>PO Number</th>
                        <th scope="col" style={{width:"12%"}}> Date</th>
                        <th scope="col" style={{width:"18%"}}>Warehouse</th>
                        <th scope="col" style={{width:"18%"}}>Vendor Name</th>
                        <th scope="col" style={{width:"6%"}}>Quantity</th>
                        <th scope="col" style={{width:"6%"}}>Currency</th>
                        <th scope="col" style={{width:"10%"}} className="text-end">Amount</th>
                        <th scope="col" style={{width:"10%"}}>Status</th>
                        <th scope="col" style={{width:"5%"}}>Action</th>
                    </tr> )
                }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="9"><Loader /></td></tr>)
                        : (this.state.po_listing.length > 0
                            ? (this.state.po_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <a role="button" className="link-primary" onClick={() => this.viewPODetail(item.transaction_id)}>{item.transaction_id}</a>
                                                        <br /><small>{item.transaction_date_display}</small>
                                                        <small style={{ float: 'right' }}>{item.status_data ? item.status_data.name : ''}</small>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                                <td><a role="button" className="link-primary text-center" onClick={() => this.viewPODetail(item.transaction_id)}>{item.transaction_id}</a></td>
                                                <td>{item.transaction_date_display}</td>
                                                <td>{item.warehouse ? item.warehouse.name : '-'}</td>
                                                <td className='text-capitalize'>{item.vendor ? <div>{item.vendor.name}</div>
                                                    : "-"}</td>

                                                <td className="text-end">{parseFloat(item.total_qty)}</td>
                                                <td className="text-center">{item.currency ? item.currency : "INR"}</td>
                                                <td className="text-end"><CurrencyFormatter currencyCode={item.currency ? item.currency : "INR"} amount={parseFloat(item.total_amount_vc ? item.total_amount_vc : 0).toFixed(2)} />
                                                    
                                                    </td>
                                                <td className='text-center'>{item.status_data ? item.status_data.name : '-'}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button">View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }


    logisticDetailModal = (val) => {
        this.LogisticViewModalRef.current.showModalInit(val);
    }

    purchaseOrderViewDetails                =   ()   =>   {
        let purchaseOrder = this.state.poDetail;
        let purchase_summary                    =   [];
        if (purchaseOrder && purchaseOrder.vendor_state == purchaseOrder.warehouse_state) {
             
            purchase_summary.push({ key: 'CGST ', value: "As applicable" });
            purchase_summary.push({ key: 'SGST ', value: "As applicable" });
        } else {
            purchase_summary.push({ key: 'IGST ', value: "As applicable" });
        }

        let sub_total_amount                =      purchaseOrder && purchaseOrder.items &&  purchaseOrder.items.length > 0 && purchaseOrder.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);

        let tcs_amount                          =  purchaseOrder &&  Number(purchaseOrder.tcs_rate && purchaseOrder.tcs_rate >= 0
            ? purchaseOrder && purchaseOrder.tcs_type == "Percentage" ? (sub_total_amount*purchaseOrder.tcs_rate/100) :  Number(purchaseOrder.tcs_rate)
            : 0);

        return (<div className='p-2'>
            {!this.state.detail_loading
                ? (<Ax>
                    {this.state.poDetail
                        ? <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{purchaseOrder.transaction_id}</td>
                                        <td scope="col">{purchaseOrder.transaction_date_display}</td>
                                        <td scope="col" className="align-middle">
                                            {purchaseOrder.status_data ? purchaseOrder.status_data.name : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                <tr>
                                            <td style={{ width: "33%" }} >Purchase Order Type</td>
                                            <td colSpan={2}>{purchaseOrder.purchase_order_type_name ? purchaseOrder.purchase_order_type_name :  ""}
                                            </td>
                                        </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} rowSpan={2} >Warehouse (Delivery Address)</td>
                                        <td colSpan={2}>{purchaseOrder.warehouse ?
                                            <Ax>
                                                <div><b>{purchaseOrder.warehouse.name} ({purchaseOrder.warehouse.code})</b> <br />
                                                    Organization : {purchaseOrder.warehouse && purchaseOrder.warehouse.organization ? purchaseOrder?.warehouse?.organization?.name : this.props?.orgInfoData?.group_name}
                                                </div>


                                            </Ax>
                                            : null}
                                        </td>


                                    </tr>
                                    <tr>
                                        <td>
                                            <div><b>Bill To Address</b></div>
                                            {purchaseOrder.warehouse ? <div>
                                                {purchaseOrder.warehouse_address ?

                                                    <div className='pt-1'>{purchaseOrder.warehouse_address.address1 ? <span>{purchaseOrder.warehouse_address.address1}, <br /></span> : ""}
                                                        {purchaseOrder.warehouse_address.address2 ? <span>{purchaseOrder.warehouse_address.address2}, <br /></span> : ""}
                                                        {purchaseOrder.warehouse_address.landmark ? <span>{purchaseOrder.warehouse_address.landmark}, <br /></span> : ""}
                                                        {purchaseOrder.warehouse_address.city ? <span>{purchaseOrder.warehouse_address.city}, <br /></span> : ""}
                                                        {purchaseOrder.warehouse_address && purchaseOrder.warehouse_address.state_name ? <span> State : <b>{purchaseOrder.warehouse_address.state_name}</b> <br /></span> : ""}
                                                        {purchaseOrder.warehouse_address && purchaseOrder.warehouse_address.gstin ? <span>GSTIN  : <b>{purchaseOrder.warehouse_address.gstin}</b> <br /></span> : ""}

                                                    </div> : "-"}
                                            </div> : "-"} <br />
                                        </td>

                                        <td>
                                            <div><b>Ship To Address</b></div>
                                            {purchaseOrder.warehouse_shipping_address ?
                                                <div className='pt-1'> {purchaseOrder.warehouse_shipping_address.address1 ? <span>{purchaseOrder.warehouse_shipping_address.address1}, <br /></span> : ""}
                                                    {purchaseOrder.warehouse_shipping_address.address2 ? <span>{purchaseOrder.warehouse_shipping_address.address2}, <br /></span> : ""}
                                                    {purchaseOrder.warehouse_shipping_address.landmark ? <span>{purchaseOrder.warehouse_shipping_address.landmark}, <br /></span> : ""}
                                                    {purchaseOrder.warehouse_shipping_address.city ? <span>{purchaseOrder.warehouse_shipping_address.city}, <br /></span> : ""}
                                                    {purchaseOrder.warehouse_shipping_address && purchaseOrder.warehouse_shipping_address.state_name ? <span> State : <b>{purchaseOrder.warehouse_shipping_address.state_name}</b> <br /></span> : ""}
                                                    {purchaseOrder.warehouse_shipping_address && purchaseOrder.warehouse_shipping_address.gstin ? <span>GSTIN  : <b>{purchaseOrder.warehouse_shipping_address.gstin}</b> <br /></span> : ""}

                                                </div>
                                                :
                                                <div className='pt-1'>
                                                    {purchaseOrder.warehouse_address ?
                                                        <div className='pt-1'>{purchaseOrder.warehouse_address.address1 ? <span>{purchaseOrder.warehouse_address.address1}, <br /></span> : ""}
                                                            {purchaseOrder.warehouse_address.address2 ? <span>{purchaseOrder.warehouse_address.address2}, <br /></span> : ""}
                                                            {purchaseOrder.warehouse_address.landmark ? <span>{purchaseOrder.warehouse_address.landmark}, <br /></span> : ""}
                                                            {purchaseOrder.warehouse_address.city ? <span>{purchaseOrder.warehouse_address.city}, <br /></span> : ""}
                                                            {purchaseOrder.warehouse_address && purchaseOrder.warehouse_address.state_name ? <span> State : <b>{purchaseOrder.warehouse_address.state_name}</b> <br /></span> : ""}
                                                            {purchaseOrder.warehouse_address && purchaseOrder.warehouse_address.gstin ? <span>GSTIN  : <b>{purchaseOrder.warehouse_address.gstin}</b> <br /></span> : ""}

                                                        </div> : "-"}
                                                </div>}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} >Vendor</td>
                                        <td colSpan={2} >{purchaseOrder.vendor ? <div><a className='link-primary cursor_pointer' onClick={() => this.vendorDetailModalInit(purchaseOrder?.vendor?.id)}>{purchaseOrder.vendor.name} ({purchaseOrder.vendor.code})</a> <br />
                                            {purchaseOrder.vendor_address ?
                                                <div className='py-1'>Address : {purchaseOrder.vendor_address.address1 ? purchaseOrder.vendor_address.address1 + ", " : ""}
                                                    {purchaseOrder.vendor_address.address2 ? purchaseOrder.vendor_address.address2 + ", " : ""}
                                                    {purchaseOrder.vendor_address.landmark ? purchaseOrder.vendor_address.landmark + ", " : ""}
                                                    {purchaseOrder.vendor_address.city ? purchaseOrder.vendor_address.city + ". " : "-"} <br />
                                                    Source of Supply : <b>{purchaseOrder.vendor_address.state_name} ({purchaseOrder.vendor_address.country_name}) </b> <br />
                                                    GSTIN  : {purchaseOrder.vendor_gstin ?? "-"}</div> : "-"}</div> : "-"}
                                            {purchaseOrder.po_display_vendor_contact && purchaseOrder.po_display_vendor_contact == 'Y' && purchaseOrder.vendor_contact && <div className='py-1'>
                                                Name : {purchaseOrder.vendor_contact.name} | Contact No. : {purchaseOrder.vendor_contact.mobile} | Email : {purchaseOrder.vendor_contact.email}
                                            </div>}
                                        </td>
                                    </tr>
                                    {purchaseOrder.po_allow_po_for && purchaseOrder.po_allow_po_for == 'Y' && <Ax>
                                        <tr>
                                            <td style={{ width: "33%" }} >Purchase Order For</td>
                                            <td colSpan={2}>{purchaseOrder.po_for && purchaseOrder.po_for == "asset" ? "Asset " : purchaseOrder.po_for && purchaseOrder.po_for == "workstation" ? "Workstation " : purchaseOrder.po_for && purchaseOrder.po_for == "employee" ? "Employee " : ""}
                                                {purchaseOrder.po_for == "asset" && purchaseOrder.assetProfile ? <span>({purchaseOrder.assetProfile.name})</span> : purchaseOrder.po_for == "workstation" && purchaseOrder.workstation ? <span>({purchaseOrder.workstation.workstation_name})</span> : purchaseOrder.po_for == "employee" && purchaseOrder.employee ? <span>({purchaseOrder.employee.name})</span> : ""}
                                            </td>
                                        </tr>

                                    </Ax>}
                                    <tr>
                                        <td scope="col">Priority & Delivery Date</td>
                                        <td style={{ width: "33%" }} className="text-capitalize">{purchaseOrder.priority ?? "-"}</td>
                                        <td style={{ width: "34%" }}>{purchaseOrder.expected_delivery_date_display ?? "-"}</td>

                                    </tr>
                                    <tr>
                                        <td scope="col">Quotation Number & Date</td>
                                        <td style={{ width: "33%" }} className="text-capitalize">{purchaseOrder.quotation_name ?? "-"}</td>
                                        <td style={{ width: "34%" }}>{purchaseOrder.quotation_date ?? "-"}</td>

                                    </tr>
                                    <tr>
                                        <td scope="col">Tag Users</td>
                                        <td scope="col">{purchaseOrder && purchaseOrder.usersList && purchaseOrder.usersList.length > 0 ? purchaseOrder.usersList.map(u => <div>{u.full_name}</div>) : ""}</td>
                                    </tr>
                                    {purchaseOrder.po_enable_authorized_signatory && purchaseOrder.po_enable_authorized_signatory == 'Y' && <tr>
                                        <td scope="col">Authorized Signatory</td>
                                        <td style={{ width: "33%" }} className="text-capitalize" colSpan={2}>{purchaseOrder.authorized_signatory && purchaseOrder.authorized_signatory.full_name ? purchaseOrder.authorized_signatory.full_name : "-"}</td>

                                    </tr>}
                                    <tr>
                                        <td scope="col">Currency</td>
                                        <td colSpan={2}><b>{purchaseOrder.currency}</b> {purchaseOrder.currency !== purchaseOrder.base_currency ? <span>(1 {purchaseOrder?.currency} : <NumberFormatter number={purchaseOrder?.conversion_rate} precision={2} /> {purchaseOrder?.base_currency})</span> : null}</td>

                                    </tr>
                                    {purchaseOrder.additional_attributes_data && purchaseOrder.additional_attributes_data.length > 0
                                        ? purchaseOrder.additional_attributes_data.map((attr) => {
                                            return (<tr key={attr.key}>
                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                <td scope="col" colSpan={2}>{attr.value_display ? attr.value_display : "-"}</td>
                                            </tr>)
                                        }) : null}

                                    <tr>
                                        <td scope="col">Logistic Details</td>
                                        <td colSpan={2}>
                                            {
                                                (purchaseOrder.logistic_tansaction_ids && purchaseOrder.logistic_tansaction_ids.length > 0) &&
                                                purchaseOrder.logistic_tansaction_ids.map((val, i) => {
                                                    return <Link key={i} onClick={() => this.logisticDetailModal(val)}>{val} {(i !== purchaseOrder.logistic_tansaction_ids.length -1) ? ', ' : ''}</Link>;
                                                })
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table table-bordered table-responsive bg-white my-3 table-sm" id="poTable">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ "width": '5%' }} className="text-center">
                                            <input
                                             type='checkbox' 
                                             onChange={this.checkAllCancelItem} 
                                             name="chk[]"
                                             disabled={purchaseOrder && purchaseOrder.items && purchaseOrder.items.length > 0 && purchaseOrder.items.some((i) => i.pending_qty == 0) ? true : false}
                                             id="all_check_po" />
                                        </th>
                                        <th style={{ width: "39%" }}>Item Details</th>
                                        <th style={{ width: "12%" }}>Requisition</th>
                                        <th style={{ width: "12%" }} className="text-end">Quantity</th>
                                        <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                        <th style={{ width: "8%" }} className="text-end">Discount</th>
                                        <th style={{ width: "8%" }} className="text-end">Tax</th>
                                        <th style={{ width: "8%" }} className="text-end">Amount</th>
                                        {/* <th style={{ width: "8%" }} className="text-center">Pending</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        purchaseOrder && purchaseOrder.items && purchaseOrder.items.length > 0 ? purchaseOrder.items?.map((i, k) => {
                                            return (<tr>
                                                <td className='text-center'>
                                                    {k + 1} <br /> <br />
                                                    <input
                                                        type='checkbox'
                                                        name="foo"
                                                        className="po_item_id_checkbox"
                                                        value={i.item.id}
                                                        data-poItemId={i.po_item_id}
                                                        data-pendingQty={i.pending_qty}
                                                        disabled={i.pending_qty == 0}
                                                    />

                                                </td>

                                                <td>{i.item ?
                                                    <div className='row'>
                                                        <div className='col-sm-10'>
                                                            <ItemViewInList view_hsn={true} item={i.item} remark={i.remark} due_date={i.due_date_display} viewItemModal={() => this.viewItemModalInit(i.item)} />
                                                        </div>
                                                        <div className='col-sm-2 text-end'>
                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu">
                                                                <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.item, k)}>Consumption History</a></li>
                                                                <li><a href="#" className="dropdown-item" onClick={() => this.viewPOHistoryModalInit(i.item, k)}>PO History</a></li>
                                                                <li><a href="#" className="dropdown-item" onClick={() => this.viewTransactionLedgerModalInit(i.item, k)}>Item Transaction Ledger</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : null}</td>
                                                <td className="text-center ">
                                                    {i.req_allocation && i.req_allocation.length > 0
                                                        ? (<Ax>
                                                            {i.req_allocation.map((r, k) => {
                                                                return <div className='my-1 link-primary cursor_pointer' onClick={() => this.reqDetailModalInit(r.requisition_transaction_id)}>{r.requisition_transaction_id}: {r.qty}</div>
                                                            })}
                                                        </Ax>)
                                                        : null
                                                    }
                                                    {(i.additional_qty > 0 ? <div className='my-1'>ADDL.: {i.additional_qty}</div> : null)}
                                                </td>
                                                <td className='text-end'>{i.qty}
                                                    {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}
                                                    <div className='my-1'>Pending : {i.pending_qty}</div>
                                                    {i.cancelled_qty && i.cancelled_qty > 0 ? <div className='my-1'>Cancelled : {i.cancelled_qty}</div> : null}
                                                </td>
                                                <td className='text-end'>{i.rate_vc}</td>
                                                <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? purchaseOrder.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : 0}</td>
                                                <td className='text-end'>{purchaseOrder && purchaseOrder.vendor_gstin && purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'N' && purchaseOrder.gst_not_applicable && purchaseOrder.gst_not_applicable == 'N' ? <div>{i.gst} %</div> : (purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'Y') ? "-" : "NA"}
                                                    {purchaseOrder && purchaseOrder.vendor_gstin && i.item && purchaseOrder.gst_not_applicable == 'N' ? <span className="text-helping input-group-text text-end pr0">{purchaseOrder.vendor_address && purchaseOrder.vendor_address.state_name == purchaseOrder.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                                </td>
                                                <td className='text-end'>
                                                <CurrencyFormatter currencyCode={purchaseOrder.currency} amount= {parseFloat(i.item_with_discount).toFixed(2)}/>
                                                   </td>
                                            </tr>)

                                        }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="row align-items-center mb-5">
                                <div className="col-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{purchaseOrder.notes ?? "-"}</div>

                                </div>
                                <div className="col-6">
                                    <table className="table  table-borderless">
                                        <tbody>
                                            <tr className="subtotal_bg_color">
                                                <th>Sub-Total ({purchaseOrder.currency ? purchaseOrder.currency : "INR"})</th>
                                                <td className='float-end'>{purchaseOrder && purchaseOrder.items && purchaseOrder.items.length > 0 && <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={purchaseOrder.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)}/>}</td>
                                                {/* <td className='float-end'>{purchaseOrder.total_amount_vc ? (parseFloat(purchaseOrder.total_amount_vc) - (parseFloat(purchaseOrder.adjustment_amount_vc ?? 0.0) + parseFloat(purchaseOrder.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                            </tr>
                                            {purchaseOrder && purchaseOrder.tcs_rate_vc && purchaseOrder.tcs_rate_vc > 0 ? <tr className="subtotal_bg_color">
                                                <th>TCS ({purchaseOrder.tcs_type == "Fixed" ? purchaseOrder.currency : null} {purchaseOrder.tcs_rate_vc} {purchaseOrder.tcs_type == "Percentage" ? "%" : null} )</th>
                                                <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>
                                                {/* <td className='float-end'>{purchaseOrder.total_amount_vc ? (parseFloat(purchaseOrder.total_amount_vc) - (parseFloat(purchaseOrder.adjustment_amount_vc ?? 0.0) + parseFloat(purchaseOrder.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                            </tr> : null}
                                            {purchaseOrder.tax_slabs && purchaseOrder.tax_slabs.length > 0 && purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'N'
                                                ? (purchaseOrder.tax_slabs.map((ps, k) => {
                                                    return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                }))
                                                : null
                                            }
                                            {purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'Y' &&
                                                purchase_summary.map(ps => {
                                                    return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                })}
                                            <tr className="subtotal_bg_color">
                                                <th>Adjustment</th>
                                                <td className='float-end'>{purchaseOrder.adjustment_amount_vc ?? 0}</td>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Final Total ({purchaseOrder.currency ? purchaseOrder.currency : "INR"})</th>
                                                <td className='float-end'>{purchaseOrder.total_amount_vc ? <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={ purchaseOrder.total_amount_vc}/> : 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab_content_wrapper content_heading">Terms & Condition</div>
                            <table className="table table-hover table-bordered bg-white my-3">
                                <thead className='table-secondary'>
                                    <tr>
                                        <th className='text-center' style={{ width: "10%" }}>S.No</th>
                                        <th className='text-start' style={{ width: "25%" }}>Name</th>
                                        <th className='text-start' style={{ width: "65%" }}>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        purchaseOrder.tnc && purchaseOrder.tnc.length > 0 ? purchaseOrder.tnc.map((tc, k) => {
                                            return (<tr key={k}>
                                                <td className='text-center'>{k + 1}</td>
                                                <td>{tc.name}</td>
                                                <td>{tc.text}</td>
                                            </tr>);
                                        })
                                            : <tr ><td className='text-center' colSpan={3}>No Record</td></tr>
                                    }

                                </tbody>
                            </table>

                            <hr />
                            {purchaseOrder.status == 5 || purchaseOrder.status == 6
                                ? null
                                : <div>
                                    <div className="tab_content_wrapper" ref={this.myRef}>
                                        <span className="content_heading">Comments & History</span>
                                    </div>
                                    {this.state.allAllowedStatus
                                        ? <form onSubmit={this.submitUpdateCommentForm}>
                                            <div className="my-3 row">
                                                <label className="col-sm-3 col-form-label">Change status to</label>
                                                <div className="col-sm-5">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                                        }}
                                                        value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                                        options={this.state.allAllowedStatus}
                                                        isSearchable={false}
                                                        isDisabled={this.state.poDetail?.change_status == "N" || !this.props.permissions.includes('isc-po-status-change')}
                                                        placeholder="Please Select Status"
                                                    />
                                                </div>
                                            </div>
                                            <div className="my-2">
                                                <label className="form-label">Comments</label>
                                                <textarea
                                                    name="notes"
                                                    value={this.state.addCommentForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                                    className="form-control"
                                                    placeholder="Please Add a comment"
                                                    required
                                                    style={{ height: "100px" }}
                                                />
                                            </div>
                                            <div className="col-12 text-end">
                                                <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                                    Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                                </button>
                                            </div>
                                        </form>
                                        : null}
                                </div>}
                            <hr />
                            <div className="my-1">
                                {
                                    purchaseOrder.comments && purchaseOrder.comments.length > 0 ? purchaseOrder.comments.map((c, k) => {
                                        return (<Ax key={k}><div className="row">
                                            {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                            <div className="col-sm-4 text-end">
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {c.created_at_display}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div><hr /></Ax>);
                                    })
                                        : null
                                }
                            </div>
                        </div>
                        : null}
                </Ax>)
                : <Loader />}

        </div>)
    }

     //***********************SEARCH REQUISTION JSX****************
     poSearchModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="poSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">PO Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.poClear}></button>
                        </div>
                        <form id="poSearchForm" onSubmit={this.submitPOSearchForm}>
                            <div className="modal-body">
                                {!this.state.poSearchFormDataLoaded ? <Loader />
                                    : <Ax>
                                        <div className="row my-3 align-items-center">
                                           
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">PO Number</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="search_transaction_id"
                                                    type="text"
                                                    value={this.state.poSearchForm.search_transaction_id}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "poSearchForm")}
                                                    placeholder="Please enter Transaction ID"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">PO Type</label>
                                            <div className="col-sm-4">
                                            <TapSelect
                                                    options={this.state.allPOTypes}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'poSearchForm', 'search_purchase_order_type')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.allPOTypes.find(s => this.state.poSearchForm.search_purchase_order_type == s.value)}
                                                    placeholder="Select PO Type"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Status</label>
                                            <div className="col-sm-4">
                                                <TapSelect
                                                    options={this.state.allStatusOptions}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'poSearchForm', 'search_status_ids')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}

                                                    value={this.state.allStatusOptions.filter(s => this.state.poSearchForm.search_status_ids.includes(s.value))}
                                                    placeholder="Select Status"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Priority</label>
                                            <div className="col-sm-4">
                                                <TapSelect
                                                    options={this.state.allPriorityOptions}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'poSearchForm', 'search_priority')}
                                                    value={this.state.allPriorityOptions.find(u => u.value == this.state.poSearchForm.search_priority)}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="Select Priority"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>


                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Warehouse</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.all_warehouses}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'poSearchForm', 'search_warehouse_ids')}
                                                    value={this.state.all_warehouses.filter(u => this.state.poSearchForm?.search_warehouse_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Warehouses"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Vendor</label>
                                            <div className="col-sm-10">
                                                <InputVendorSearch
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'poSearchForm', 'search_vendor_ids');
                                                        this.setState({ searchedVendor: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                    }}
                                                    isMulti={true}
                                                    value={this.state.searchedVendor.length > 0 ? this.state.searchedVendor.map(a => { a.label = a.display_label; return a; }) : []}
                                                    containerHeight="30px"
                                                    fontSize="93%"

                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                            <div className="col-sm-10">
                                                <InputItemSearch
                                                    placeholder="Search Item"
                                                    isMulti={true}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'poSearchForm', 'search_item_ids');
                                                        this.setState({
                                                            searchedItem: selectedOption ? selectedOption : null
                                                        }, () => { });
                                                    }}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Item Tags</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allItemTags}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'poSearchForm', 'search_item_tags')}
                                                    value={this.state.allItemTags.filter(u => this.state.poSearchForm?.search_item_tags?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Item Tags"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Tag Category</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="All"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    options={this.state.userFilters}
                                                    value={this.state.userFilters.find(r => r.value === this.state.poSearchForm.tagged_user_filter)}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'poSearchForm', 'tagged_user_filter')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Tagged User</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    placeholder="Tagged User"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    options={this.state.allUsers}
                                                    value={this.state.allUsers.filter(s => this.state.poSearchForm.tagged_user_ids.includes(s.value))}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'poSearchForm', 'tagged_user_ids')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Voucher Ageing</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_voucher_aging"
                                                    type="text"
                                                    value={this.state.poSearchForm.search_voucher_aging}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "poSearchForm")}
                                                    placeholder="Example. 0-5 for a range or 4 for Voucher Ageing"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Approval Ageing</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_approved_aging"
                                                    type="text"
                                                    value={this.state.poSearchForm.search_approved_aging}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "poSearchForm")}
                                                    placeholder="Example. 0-5 for a range or 4 for Approval Ageing"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>
                                        <div className="row my-3 align-items-center">
                                            
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Released Ageing</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_release_aging"
                                                    type="text"
                                                    value={this.state.poSearchForm.search_release_aging}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "poSearchForm")}
                                                    placeholder="Example. 0-5 for a range or 4 for Released Ageing"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                            <div className="col-sm-10">
                                                <PeriodSelectionWithCustom
                                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                                    value={this.state.poSearchForm.date_range}
                                                    startDate={this.state.poSearchForm.date_range_start}
                                                    endDate={this.state.poSearchForm.date_range_end}
                                                    onSelectPeriod={(period, startDate, endDate) => {
                                                        this.setState({
                                                            poSearchForm
                                                                : {
                                                                ...this.state.poSearchForm,
                                                                date_range: period,
                                                                date_range_start: startDate ? startDate : null,
                                                                date_range_end: endDate ? endDate : null
                                                            }
                                                        });
                                                    }}
                                                    className="form-control-sm"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.poClear}>Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Purchase Order Listing</title></Helmet>
            {this.purchaseOrderListJsx()}
            {this.reqDetailModalJsx()}
            {this.poSearchModalJsx()}
            {this.cancelledItemModalJsx()}
            <ItemDetailsModal ref={this.itemDetailsRef}  /> 
            <ConsumptionHistoryModal ref={this.consumptionHistoryRef}/>
            <POHistoryModal ref={this.poHistoryRef}/>
            <ItemTransactionLedger ref={this.transactionLedgerRef}/>
            <RequisitionDetailModal parentProps={this.props} ref={this.ReqDetailModalRef}/>
            <VendorDetailModal ref={this.VendorDetailModalRef}/>
            <LogisticViewModal ref={this.LogisticViewModalRef}/>
        </ApplicationLayout>);
    }

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        orgInfoData             :   state.app.group_info ? state.app.group_info : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PurchaseOrderList);