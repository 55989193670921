import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import HttpAPICall from '../../../services/HttpAPICall';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from '../../../components/ui/TapSelect';
import {PeriodSelectionWithCustom, DisplaySearchCriteria } from '../../../components/TapUi/index';
import download from "../../../services/DownloadFile";
import { Collapse } from 'bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class ShiftwiseAssetRunningWithOT extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            asset_id                            :   '',
            ot_rule                             :   '',
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
        };
        
        this.reportSearchFormLabel          =   {
            asset_id                            :   'Assets : ',
            ot_rule                             :   'OverTime Rule : ',
            date_range                          :   'Date Range : '
        }
        
        this.state                          =   {
            formDataLoaded                      :   false,
            downloadReportBtn                   :   false,
            reportDownloading                   :   false,
            filterFormData                      :   null,
            displaySearchFilter                 :   true,
            searchedAsset                       :   null,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            formSearchedElems                   :   [],
            assetData                           :   null,
            totalData                           :   null,
            reportData                          :   null,
        };
    }
    
    componentDidMount() {
        //Load Invoice Calculation Form Data :-
        this.filterFormDataInit();
    }
    
    filterFormDataInit                      =   ()  =>  {

        const report_name   =   'shiftwise_asset_running_with_ot';
        const reportData    =   this.props?.report_listing.length > 0 ? this.props.report_listing.filter(d => d.key === report_name) : [];

        let url                                 =   process.env.REACT_APP_IAM_API_SERVER + '/report/shift_asset_running_with_ot/form_data';
        HttpAPICall.withAthorization('GET', url, this.props.access_token, null, null, (response) => {
            this.setState({
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                formDataLoaded              :   true,
                filterFormData              :   response.data
            });
            let ParamObject                 =   new URLSearchParams(this.props.location.search);
            
            if(ParamObject.get('asset_id')) {
                let updateFilterForm        =   {date_range : "current_month",asset_id : ParamObject.get('asset_id')};
                this.setState({reportFilterForm :   {...this.state.reportFilterForm, ...updateFilterForm},searchedAsset : {value : ParamObject.get('asset_id'), display_label : `${ParamObject.get('asset_name')} (${ParamObject.get('asset_code')})`}});
            }
            this.reportFilterhandler();
        });
    }
    
    reportFilterhandler                     =   ()  =>  {
        new Collapse(document.getElementById('reportFilterFormDiv'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });  
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    reportFilterFormSubmit                  =   (event)  =>  {
        event.preventDefault();
        this.reportFilterhandler();
        let serachFormKeys              =   Object.keys(this.state.reportFilterForm);
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportSearchFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'asset_id') {
                        show_val            =   this.state.searchedAsset ? this.state.searchedAsset.display_label : '';
                    }
                    if(key == 'ot_rule' && this.state.filterFormData) {
                        let ot_rule         =   this.state.filterFormData.over_time_rules.find(r => r.id == value);
                        show_val            =   ot_rule ? `${ot_rule.name} (${ot_rule.reference_number})` : '';
                    }
                    if(key == 'date_range') {
                        show_val            = this.state.filterFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({ 
            submittedReportFilterForm   :   {...this.state.reportFilterForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.loadListingTblData();
        });
    }
    
    loadListingTblData                      =   ()  =>  {
        this.setState({
            listing_loading: true, 
            listingData : [],
            assetData : null,
            totalData : null,
            downloadReportBtn : false,
            reportDownloading:false
        });
        let params                      =   {...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/report/shift_asset_running_with_ot', this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                     :   respData.data,
                assetData                       :   respData.asset,
                totalData                       :   respData.total,
                downloadReportBtn               :   true
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   (type='xlsx')  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/report/shift_asset_running_with_ot?type=' + type, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faBusinessTime} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    { this.state.downloadReportBtn ? (<div className="btn-group" role="group">
                            <button id="btnDownloadDropDown" type="button" disabled={this.state.reportDownloading} className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="btnDownloadDropDown">
                                <li><Link className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')} href="#" onClick={() => this.reportDownloadHandler('xlsx')} >Download as Excel</Link></li>
                                <li><Link className="dropdown-item" href="#" onClick={() => this.reportDownloadHandler('pdf')} >Download as PDF</Link></li>
                            </ul>
                       
                        </div>) : null }
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">
                            Change Filter
                    </button>
                   <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button> 
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        let over_time_rules                 =   this.state.filterFormData && this.state.filterFormData.over_time_rules 
                                                    ? this.state.filterFormData.over_time_rules.map((r) => {
                                                            return {value: r.id, label: `${r.name} (${r.reference_number})`}})
                                                    : [];
        return (<div id="reportFilterFormDiv" className="bg-white collapse" aria-expanded="true" >
            <form onSubmit={this.reportFilterFormSubmit} className={['px-3' ,'filter_form'].join(' ')}>
                <div className="row pt-3">
                    <label className="col-md-3 col-form-label text-end require">Asset</label>
                    <div className="col-md-6">
                        <InputAssetSearch
                            required={true}
                            clAttribute="Y"
                            clMeters={['hour_meter']}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'asset_id');
                                this.setState({
                                    searchedAsset : selectedOption ? selectedOption : null} , () => {
                                });
                            }}
                            value={this.state.searchedAsset ? {...this.state.searchedAsset, label : this.state.searchedAsset.display_label} : null}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-md-3 col-form-label text-end require">OverTime Rule</label>
                    <div className="col-md-6">
                        <TapSelect
                            isClearable={true}
                            placeholder="Select OverTime Rule"
                            required={true}
                            isSearchable={true}
                            options={over_time_rules}
                            value={over_time_rules.find(r => r.value ===  this.state.reportFilterForm.ot_rule  )}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'ot_rule')}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-md-3 col-form-label text-end require">Date Range</label>
                    <div className="col-md-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.filterFormData ? this.state.filterFormData.periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm    :   {...this.state.reportFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate}
                                });
                            }}
                            menuPlacement="bottom"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <div className="col-sm-12 text-muted fs12"># This report can be generated only for Measurable Assets with Hour Meter counterlog.</div>
                    <hr/>
                </div>
            </form>
        </div>);
    }
    
    reportDisplayJsx                        =   ()  =>  {
        return (<div className="bg-white">
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}   />
            { this.state.listing_loading ? <Loader /> : null }
            { this.state.assetData && !this.state.listing_loading
                ? this.reportTableJsx()
                : null
            }
        </div>);
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr> <th className="text-center">Date</th>
                        <th>Day</th>
                        <th>Shift Name</th>
                        <th>Start Time</th>
                        {this.state.assetData.odo_meter == 'yes' ? <th className="text-center">Total KM Running</th> : null}
                        {this.state.assetData.operation_cycle == 'yes' ? <th className="text-center">Total OpC Running</th> : null}
                        <th className="text-center">Shift Hours</th>
                        <th className="text-center">Threshold Limit</th>
                        {this.state.assetData.hour_meter == 'yes' ? <th className="text-center">Total HrM Running</th> : null}
                        <th className="text-center">Work Hours</th>
                        <th className="text-center">O.T.</th>
                        <th className="text-center">Downtime</th>
                        <th className="text-center">Working Status</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.listingData && this.state.listingData.length > 0 
                    ? <Ax> {
                        (this.state.listingData.map((dateData) => { return ( dateData.shift.map(siftData => { return (<tr>
                                    <td>{dateData.date_display}</td>
                                    <td>{dateData.day}</td>
                                    <td>{siftData.shift_name}</td>
                                    <td>{siftData.shift_start}</td>
                                    {this.state.assetData.odo_meter == 'yes' ? <td className="text-center">{siftData.running_km}</td> : null}
                                    {this.state.assetData.operation_cycle == 'yes' ? <td className="text-center">{siftData.running_opc}</td> : null}
                                    <td className="text-center">{siftData.shift_time}</td>
                                    <td className="text-center">{siftData.threshold_limit}</td>
                                    {this.state.assetData.hour_meter == 'yes' ? <td className="text-center">{siftData.running_hrm}</td> : null}
                                    <td className="text-center">{siftData.working_hours}</td>
                                    <td className="text-center">{siftData.over_time}</td>
                                    <td className="text-center">{siftData.downtime}</td>
                                    <td className="text-center">{siftData.working_status}</td>
                                </tr>);
                            }))
                        }))
                        }
                        <tr className="table-light">
                            <th colSpan="4">Total</th>
                            {this.state.assetData.odo_meter == 'yes'
                                ? <th className="text-center"> { this.state.totalData ? this.state.totalData.running_km : '' }</th>
                                : null }
                            { this.state.assetData.operation_cycle == 'yes'
                                ? <th className="text-center"> { this.state.totalData ? this.state.totalData.running_opc : '' }</th>
                                : null }
                            <th className="text-center">-</th>
                            <th className="text-center">{ this.state.totalData ? this.state.totalData.threshold_limit : '' }</th>
                            {this.state.assetData.hour_meter == 'yes'
                                ? <th className="text-center">{ this.state.totalData ? this.state.totalData.running_hmr : '' }</th>
                                : null }
                            <th className="text-center">{ this.state.totalData ? this.state.totalData.work_hours : '' }</th>
                            <th className="text-center">{ this.state.totalData ? this.state.totalData.overtime : '' }</th>
                            <th className="text-center">{ this.state.totalData ? this.state.totalData.downtime : '' }</th>
                            <th className="text-center">-</th>
                        </tr>
                        </Ax>
                    : <tr><td className="text-center" colSpan={9}>No Record Found</td></tr>
                }
                </tbody>
            </table>
        </Ax>)
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout> 
         <Helmet><title>Shiftwise Asset Running With OT - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                    {this.state.formDataLoaded
                    ? (<Ax>
                            {this.reportFilterFormJsx()}
                            {this.reportDisplayJsx()}
                        </Ax>)
                        : <Loader  />
                    }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ShiftwiseAssetRunningWithOT);