import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import tapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import AppBaseComponent from '../../../components/AppBaseComponent';

class AddVendorModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.addVendorFormInitialState =     { 
            name                        :       '',
            code                        :       '',
            notes                       :       '',
            state_id                    :       null,
            gstin                       :       '',
            address1                    :       '',
            label                       :       '',
            address2                    :       '',
            city                        :       '',
            landmark                    :       '',
            district_name               :       null,
            pincode                     :       null,
            country_id                  :       99,
            domain                      :       [],
            authorization               :       '',
            operations_state            :       [],
            operations_district         :       []
        }

        this.initContactForm        =       {
            name            :       '',
            email           :       '',
            mobile          :       '',
            primary         :   'Y'
        }

        this.state                      =    {
            vendorAddFormSubmitting     :    false,
            addVendorForm               :    {...this.addVendorFormInitialState},
            allStateList                :    [],
            allCountryList              :    [],
            allDomainList               :    [],
            allDistrictList             :    [],
            allPincodeList              :    [],
            vendorFormDataLoaded        :    false,
            vendorStateListLoaded       :    false,
            editVendor                  :    false,
            vendorData                  :    null,
            contacts                    :    [{name:'',email:'',mobile:'',primary:'Y'}],
            addNewDistrict              :    false,
            addNewPinCode               :    false,
            loading_form                :    false,
            addContactForm              :    {...this.initContactForm}
        };

        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.vendorUrl          =   INVENTORY_API_BASE_URL_2 + '/vendor';
    }
    
  
    componentDidMount(){
        this.initilaizeFormData()
        this.addVendorModal                  =   new Modal(document.getElementById('addVendorModal'), {keyboard: false, backdrop :false});
    }

    vendorAddModalInit      =   (editData)  =>  {  
        this.addVendorModal.show()
        
        if(editData){
            this.setState({
                addVendorForm         :  {
                    ...this.addVendorFormInitialState,
                    id                          : editData.id,
                    name                        :  editData.name,
                    code                        :  editData.code ? editData.code : "-",
                    notes                       :  editData.notes ? editData.notes : "-",
                    domain                      :  editData.addressList ? editData.domain : [],
                    authorization               :  editData.addressList ? editData.authorization : [],
                    operations_state            :  editData.operations_state ? editData.operations_state : [],
                    operations_district         :  editData.operations_district ? editData.operations_district : [],
                },
                    // contacts                    :  editData.contacts ? editData.contacts : this.state.contacts,
                    vendorData                  :  editData,
                    editVendor                  :  true
                
            }, () => {
               if(editData && editData.addressList && editData.addressList.length > 0 ){
                 this.stateChangeHandler(editData.addressList[0].state_id)
               }
            });
        }else{
            this.setState({
                addVendorForm : {...this.addVendorFormInitialState},
                editVendor                  :  false,
                addNewDistrict             :       false,
                 addNewPinCode             :       false
            })
        }
       
    }

    initilaizeFormData      =       ()      =>      {
        this.setState({loading_form: true})
        // if(!this.state.vendorFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.vendorUrl + '/add_form_data', this.props.access_token, null, null, (response) => {
                let respData = response.data
                this.setState({
                    vendorFormDataLoaded       :       true,
                    allCountryList            :       respData && respData.country ?  respData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allDomainList              :       respData && respData.domain ? respData.domain.map((s) => { return {value: s, label: s}}) : []                  

                },() => {
                    if(this.state.addVendorForm && this.state.addVendorForm.country_id){
                        this.countryChangeHandler(this.state.addVendorForm.country_id)
                    }
                });
            }).then(() => this.setState({ loading_form: false }));
    }

   

    vendorModalClose                    =   ()  =>  {
        this.props.closeEvent();
    }

    countryChangeHandler              =        (selected_country)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    allOperationsStateList      :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addVendorForm               :    {...this.state.addVendorForm,state_id: '',district_name : '',pincode:''},
                    addNewDistrict              :   false ,
                    addNewPinCode               :   false 
                });
            });
        }
        
    }

    //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
    stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList             :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addVendorForm               :    {...this.state.addVendorForm,pincode:'',pincode_name:''}
                });
            });
        }
    }

      //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
      districtChangeHandler              =        (selected_district)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            let params = {district_name : selectedDistrict,state_id:this.state.addVendorForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList              :   response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                    addNewPinCode               :   response.data && response.data.length > 0 ? false : true,
                });
            });
        }
    }


    //submit add Vendor Form
    addVendorFormSubmit            =       (e)      =>  {
       e.preventDefault()
       const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        let errors                  =   [];
        if(this.state.addVendorForm.gstin && this.state.addVendorForm.gstin.trim() != '' && !pattern.test(this.state.addVendorForm.gstin.trim())) {
            errors                  =   [...errors, 'Please enter valid GST No.'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            toast.error(errorMsg, {position: toast.POSITION.TOP_RIGHT});
        } else {
            this.setState({vendorAddFormSubmitting : true })
            let frmData;
         
            frmData = { ...this.state.addVendorForm, contacts: this.state.contacts[0]?.name !== "" ? this.state.contacts : [] }
             
            
            if (this.state.editVendor == true) {
                HttpAPICall.withAthorization('PUT', TapApiUrls.VENDOR_URL, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.setState({
                        addVendorForm: { ...this.addVendorFormInitialState,addNewDistrict: false,addNewPinCode :false },

                    })
                    if(this.props.afterEditSubmit){
                         this.getVendorDetails(this.state.vendorData.id)
                    }
                    this.addVendorModal.hide()
                }).then(() => this.setState({ vendorAddFormSubmitting: false }));
            } else {
                HttpAPICall.withAthorization('POST', TapApiUrls.VENDOR_URL, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.setState({
                        addVendorForm: { ...this.addVendorFormInitialState,addNewDistrict: false,addNewPinCode :false  },

                    })
                    this.addVendorModal.hide()
                }).then(() => this.setState({ vendorAddFormSubmitting: false }));
            }
            
        }
    }

    getVendorDetails        =       (id)        =>  {
        HttpAPICall.withAthorization('GET', TapApiUrls.VENDOR_URL, this.props.access_token, {}, { }, (response) => {
           let vendor = response.data.data.find(data => data.id == this.state.vendorData.id);
         
          if(vendor){
            let searchedVendor = {
                label               :   (<Ax>
                                         {vendor.name} ({vendor.code}) <br/>
                                        </Ax>),
                value               :   vendor.id,
                display_label       :   `${vendor.name} (${vendor.code})`,
                vendor              :   vendor
            };
    
             this.props.afterEditSubmit(searchedVendor)
          }

        }).then(() => this.setState({ vendorAddFormSubmitting: false }));
    }

    

    //*********************************ITEM ADD JSX*********************** */
    vendorAddModalJsx                     =   ()  =>  {
     
        return (
            <div className="modal fade" id="addVendorModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addVendorModalLabel">{!this.state.editVendor ? "Add" : "Edit"} Vendor</h5>
                        </div>
                        {this.state.vendorFormDataLoaded ?
                            <div className="modal-body ">
                               {this.state.loading_form ? <Loader/>
                               : <form onSubmit={this.addVendorFormSubmit} id="addVendorForm" method="post" encType="multipart/form-data">
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Details</span>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label require">Vendor Name</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <input
                                                name="name"
                                                type="text"
                                                className="form-control"
                                                required={true}
                                                value={this.state.addVendorForm.name}
                                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Enter Vendor Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label ">Code</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <input
                                                name="code"
                                                type="text"
                                                className="form-control"
                                                value={this.state.addVendorForm.code}
                                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                placeholder="Enter Vendor Code"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label ">Notes</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <textarea
                                                name="notes"
                                                className="form-control"
                                                value={this.state.addVendorForm.notes}
                                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                autoComplete="off"
                                                maxLength="250"
                                                style={{ height: "60px" }}
                                                placeholder="Enter Note"
                                            />
                                        </div>
                                    </div>
                                    {this.state.editVendor == false ?
                                        <Ax>
                                            <div className="tab_content_wrapper">
                                                <span className="content_heading">Address</span>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label require">Label</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="label"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addVendorForm.label}
                                                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Address Label"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Address Line 1</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="address1"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addVendorForm.address1}
                                                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Address Line 1"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Address Line 2</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="address2"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addVendorForm.address2}
                                                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Address Line 2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Landmark</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="landmark"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addVendorForm.landmark}
                                                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter Landmark"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">City</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="city"
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.addVendorForm.city}
                                                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                        autoComplete="off"
                                                        maxLength="250"
                                                        placeholder="Please Enter City"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Country</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addVendorForm', 'country_id');
                                                            this.countryChangeHandler(selectedOption)
                                                        }
                                                        }
                                                        options={this.state.allCountryList}
                                                        isSearchable={true}
                                                        isClearable={false}
                                                        value={this.state.allCountryList.find(m => m.value == this.state.addVendorForm.country_id)}
                                                        placeholder="Please Select Country"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label require">State (Source of Supply)</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                                        options={this.state.allStateList}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={this.state.allStateList.find(m => m.value == this.state.addVendorForm.state_id)}
                                                        placeholder="Please Select State"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">District</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    {this.state.addNewDistrict
                                                        ? <input
                                                            name="district_name"
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            value={this.state.addVendorForm.district_name}
                                                            onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                            maxLength="20"
                                                            placeholder="Please Enter District"
                                                        />
                                                        : <TapSelect
                                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                                            options={this.state.allDistrictList}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={this.state.allDistrictList.find(m => m.value == this.state.addVendorForm.district_name)}
                                                            placeholder="Please Select District"
                                                        />}
                                                </div>
                                                <div className="col-sm-2">
                                                    <input
                                                        name="addNewDistrict"
                                                        type="checkbox"
                                                        value={this.state.addNewDistrict}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewDistrict: !this.state.addNewDistrict,
                                                                addVendorForm: { ...this.state.addVendorForm, district_id: '', pincode: '' }
                                                            })
                                                        }}
                                                        checked={this.state.addNewDistrict}
                                                        className="form-check-input"
                                                        id="newDistrict"

                                                    />
                                                    <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">PIN Code</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    {this.state.addNewPinCode
                                                        ? <input
                                                            name="pincode"
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            value={this.state.addVendorForm.pincode}
                                                            onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                            maxLength="20"
                                                            placeholder="Please Enter PinCode"
                                                        />
                                                        : <TapSelect
                                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'pincode') }}
                                                            options={this.state.allPincodeList}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            value={this.state.allPincodeList.find(m => m.value == this.state.addVendorForm.pincode)}
                                                            placeholder="Please Select PIN Code"
                                                        />}
                                                </div>
                                                <div className="col-sm-2">
                                                    <input
                                                        name="addNewPinCode"
                                                        type="checkbox"
                                                        value={this.state.addNewPinCode}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewPinCode: !this.state.addNewPinCode,
                                                                addVendorForm: { ...this.state.addVendorForm, pincode: '' }
                                                            })
                                                        }}
                                                        checked={this.state.addNewPinCode}
                                                        className="form-check-input"
                                                        id="newPinCode"

                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">GSTIN</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="gstin"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addVendorForm.gstin}
                                                        onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                        maxLength="20"
                                                        placeholder="Please Enter GSTIN Number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="tab_content_wrapper">
                                                <span className="content_heading">Primary Contact Details </span>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Contact Person Name</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.contacts[0].name}
                                                        onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                                        maxLength="200"
                                                        placeholder="Please Enter Person Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label">Email ID</label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="email"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        maxLength="200"
                                                        required={this.state.contacts[0].name !== "" ? true : false}
                                                        value={this.state.contacts[0].email}
                                                        onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                                        placeholder="Please Enter Person Email ID"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                                                </div>
                                                <div className="col-sm-7">
                                                    <input
                                                        name="mobile"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.contacts[0].mobile}
                                                        required={this.state.contacts[0].name !== "" ? true : false}
                                                        onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                                        placeholder="Please Enter Person Mobile Number"
                                                        pattern="\d*"
                                                        max="20"
                                                    />
                                                </div>
                                            </div>

                                        </Ax>
                                        : null}

                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Domain </span>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Vendor Domain</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addVendorForm', 'domain')}
                                                options={this.state.allDomainList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select Vendor Domain"
                                                value={this.state.allDomainList.filter(t => this.state.addVendorForm.domain.includes(t.value))}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Authorization</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <input
                                                name="authorization"
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addVendorForm.authorization}
                                                onChange={(e) => this.formInputHandler(e, 'addVendorForm')}
                                                placeholder="Please Enter Authorization"
                                            />
                                        </div>
                                    </div>
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Area of Operations</span>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">State</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'operations_state'); this.stateChangeHandler(selectedOption) }}
                                                options={this.state.allStateList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select States"
                                                menuPlacement='top'
                                                value={this.state.allStateList.filter(t => this.state.addVendorForm.operations_state.includes(t.value))}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">District</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addVendorForm', 'operations_district') }}
                                                options={this.state.allDistrictList}
                                                isSearchable={true}
                                                isClearable={true}
                                                menuPlacement='top'
                                                placeholder="Please Select Districts"
                                                value={this.state.allDistrictList.filter(t => this.state.addVendorForm.operations_district.includes(t.value))}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>

                                </form>}
                            </div>

                            : <Loader />}
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.vendorAddFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        addVendorForm: { ...this.addVendorFormInitialState },

                                    })
                                }}
                            >Cancel</button>
                            <button type="submit" disabled={this.state.vendorAddFormSubmitting} className="btn btn-primary" form="addVendorForm">
                                Submit {this.state.vendorAddFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render() {
        return (<Ax>{this.vendorAddModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(AddVendorModal);