import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls from '../../../services/TapApiUrls';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AllocationRelease extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                            =         {
            cancelledItemList                 :       [],
            cancel_item_loading               :       false,
            cancelItemFormSubmitting          :       false,
            cancelledItemForm                 :       {...this.cancelItemFormInit},
        };
        this.releaseUrl                       =        INVENTORY_API_BASE_URL_2 + '/allocation/release';
    }
    
    
    
    componentDidMount(){
        this.releasedItemModal                            =   new Modal(document.getElementById('ReleasedItemModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (cancelItemList,transaction_id)  =>  {
       
        this.setState({
            cancelledItemList          :       cancelItemList,
        },() => this.releasedItemModal.show())
    }

    submitCancelItemForm            =       (e)      =>      {
        e.preventDefault()
        
        let items                   =        [...this.state.cancelledItemList];
          
        let itemsLists              =        items.map(i => {
                                              
                                                 return {allocation_item_id : i.id, release_qty : parseInt(i.new_released_qty),transaction_id : i.transaction_id};
                                                });
                                               
        let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists]}
        
          this.setState({ cancelItemFormSubmitting: true })
          HttpAPICall.withAthorization('PUT', this.releaseUrl, this.props.access_token, {}, { ...formData }, (response) => {
              toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
              this.releasedItemModal.hide()
              this.setState({cancelledItemForm         :       {...this.cancelItemFormInit}})
              this.props.refreshList()
          }).then(() => this.setState({ cancelItemFormSubmitting: false }));
      }
    
    
     //***********************CANCEL ITEM JSX****************
     releasedItemModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="ReleasedItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Released Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "12%" }}>Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Released Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Pending Qty</th>

                                                    <th scope="col" style={{ width: "12%" }}>Released</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="text-end" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td className='text-start'>{i.itemData ? <div><span className="fw-bold">{i.itemData.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.itemData.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.itemData?.manufacturer?.name ?? '-'} (MPN : {i.itemData?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.itemData?.tags?.length > 0
                                                                    ? (i.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td>{i.qty ? i.qty : 0}</td>
                                                        <td>{i.released_qty ? i.released_qty : 0}</td>
                                                        <td>{i.pending_qty ? i.pending_qty : 0}</td>

                                                        <td className="text-end"> <input name="new_released_qty"
                                                            type="number"
                                                            value={this.state.cancelledItemList[k]?.new_released_qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'cancelledItemList')}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Released Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                        /></td>




                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (<Ax>{this.releasedItemModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AllocationRelease);
