
import React from 'react';
import Workflow from './Workflow';
import AdditionalAttribute from '../../AdditionalAttribute';


const AllocationConfiguration = () => {
    return (
        <>
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link  active" id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">WorkFlow
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="attribute-tab" data-bs-toggle="tab" data-bs-target="#attribute"
                                type="button" role="tab" aria-controls="attribute" aria-selected="true">Addititonal Attribute
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane show active" id="workflow" role="tabpanel"
                            aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <Workflow />
                            </div>
                        </div>
                        <div className="tab-pane" id="attribute" role="tabpanel"
                            aria-labelledby="attribute-tab">
                            <div className="tab_content_header">
                                <AdditionalAttribute category="sales_allocation" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllocationConfiguration;
