import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import ReactPaginate  from 'react-paginate';
import download from '../../../services/DownloadFile';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import swal from "sweetalert";
import moment from "moment";
import InvoiceCalulationView from '../../transactions/invoiceCalculation/InvoiceCalulationView';
import AssetHeader from '../includes/AssetHeader';
import ChangeAssetStatus from '../includes/ChangeAssetStatus';
import InactivateAsset from '../includes/InactivateAsset';
import { Helmet } from 'react-helmet';


class InvoiceCalculation extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.invoiceSearchFormInitialState         =   {
            'search_asset_type'             :   '',
            'search_site'                   :   '',
            'search_invoice_rule'           :   '',
            'search_transaction_id'         :   null,
            'transaction_period_range'      :   '',
            'transaction_period_range_start':   null,
            'transaction_period_range_end'  :   null,

        };

        this.invoiceSearchFormLabel       =   {
            'search_asset_type'             :   'Asset Type : ',
            'search_site'                   :   'Site : ',
            'search_invoice_rule'           :   'Invoice Rule :',
            'search_transaction_id'         :   'Invoice Number : ',
            'transaction_period_range'      :   'Transaction Period : '
        };

        this.state                          =       {
            access_token                    :       '',
            assetDetail                     :       null,
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            listingData                     :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            asset_loading                   :       false,
            assetId                         :       null,
            invoiceCalculationId            :        '',
            view_loading                    :       false,
            showSaveInvoiceForm             :       false,
            invoiceSearchForm               :    {...this.invoiceSearchFormInitialState},
            formSearchedElems               :   [],
            searchedAsset                   :   null,
            assetSearchParams               :   null,
            all_periods                     :       [],
            iam_asset_types                 :       [],
            iam_user_sites                  :       []
        }
      
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.searchInvoiceModal           =   new Modal(document.getElementById('searchInvoiceModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;

        this.setState({assetId              :       pr.match?.params?.id,
            iam_asset_types         :   pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            all_periods             :   all_periods ? all_periods.map((s) => { return {key : s.key, display: s.display}}) : [],
           },() => {
                
                this.getAsssetDetails(pr.match?.params?.id)
                this.loadListingTblData(1,pr.match?.params?.id)
            })
    }

  
    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    //************************GET invoiceCalculation LISTING**************** */
    loadListingTblData                 =   (page = 1,id)  =>         {

        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page: page,search_asset_id : id,...this.state.assetSearchParams};
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/invoice/saved_calculation', this.props.access_token, params)
            .then((response) => {
                let respData                =   response.data;
               if(respData.data){
                this.setState({
                    listingData                     :   respData.data,
                    listingMeta                     :   respData.meta,
                    totalListingCount               :   respData.meta.total,

                });
               }
            }).then(() => {
            this.setState({listing_loading: false});
        });
    }

  
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeInvoiceCalculation                    =      ()              =>         {
        this.setState({minimizeTable:false})
    }

    assetSearchModalInit                =   ()  =>  {
        this.searchInvoiceModal.show({focus : true})
    }

    invoiceSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.invoiceSearchForm);
        let searchedElems               =   [];
        let assetSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.invoiceSearchFormLabel[key];
            let value                       =   this.state.invoiceSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_id') {
                        show_val            =   this.state.searchedAsset ? this.state.searchedAsset.display_label : '';
                    }
                    if(key == 'search_asset_type') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'transaction_period_range') {
                       let  start_range = moment(this.state.invoiceSearchForm.transaction_period_range_start,'YYYY-MM-DD').format('DD-MMM-YYYY');
                       let end_range = moment(this.state.invoiceSearchForm.transaction_period_range_end,'YYYY-MM-DD').format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + show_val);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems : searchedElems,
            assetSearchParams : assetSearchParams
        }, () => {
            this.loadListingTblData(1,this.state.assetId);
            this.searchInvoiceModal.hide();
        });
    }

    inCalcualtionClear                     =   ()  =>  {
        this.setState({
            formSearchedElems     :   [],
            assetSearchParams     :   null,
            searchedAsset         :   null,
            invoiceSearchForm     :   {...this.invoiceSearchFormInitialState}
        }, () => {
            this.loadListingTblData(1);
            this.searchInvoiceModal.hide();
        });
    }
  
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}/>
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {    
        return (<div>
            <div id="assetCard" className="collapse pr-1 mt-1" aria-expanded="true" >
                {this.state.asset_loading ? <div className='text-center pt-1'><Loader /></div>
                    : <AssetDetailHorizontalCard parentProps={this.props} assetDetail={this.state.assetDetail} />}
            </div>
        </div>)
    }

    showInvoiceView         =       (id)        =>      {
        this.setState({ minimizeTable: true, view_loading: true })
        setTimeout(() => {
            this.setState({
                invoiceCalculationId: id
            }, () => this.setState({ view_loading: false }))
        }, 1000)
       
    }

    viewLinkJsx                         =   (transaction_id)  =>  {
        return( <a role="button" 
        onClick={() => { if(this.props.permissions.includes('iam-asset-invoice-calculation-view'))  { 
                return this.showInvoiceView(transaction_id) 
            } }} 
        className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-view') ? '' : 'disabled'].join(' ')} 
        title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''} 
        style={{"pointerEvents" : "all"}}
    >View</a>)
    }
    
    deleteLinkJsx                       =   (transaction_id)  =>  {
        return <a role="button" 
            onClick={() => { if(this.props.permissions.includes('iam-asset-invoice-calculation-delete'))  { 
                    return this.deleteInvoiceCalculation(transaction_id) 
                } }} 
            className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? '' : 'disabled'].join(' ')} 
            title={!this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? 'You do not have permission to perform this action' : ''} 
            style={{"pointerEvents" : "all"}}
        >Delete</a>;
    }

    editLinkJsx                       =   (transaction_id)  =>  {
        return (<a role="button"
            onClick={() => {
                if (this.props.permissions.includes('iam-asset-invoice-calculation-edit')) {
                    return this.showInvoiceCalculationForm(transaction_id)
                }
            }}
            className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-edit') ? '' : 'disabled'].join(' ')}
            title={!this.props.permissions.includes('iam-asset-invoice-calculation-edit') ? 'You do not have permission to perform this action' : ''}
            style={{ "pointerEvents": "all" }}
        >Edit</a>)
    }

    showInvoiceCalculationForm              =   (id)  =>  {
        if(id == this.state.invoiceCalculationId){
            this.setState({showSaveInvoiceForm : true})
        }     
    }

    cancelEdit                  =         ()       =>  {
        this.setState({showSaveInvoiceForm : false})
    }

    downloadInvoiceHandler                          =   (id)  =>  {
        this.setState({ inovoiceDownloading: true});
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation_download/' + id, this.props.access_token,{},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({inovoiceDownloading: false}));
    }

      //function to delete invoice
      deleteInvoiceCalculation          =     (id)         => {
        swal({
            title: "Delete Invoice Calculation",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation/' + id, this.props.access_token,{},{},(resp) => {
                    toast.success(resp.data.msg, {position: toast.POSITION.TOP_RIGHT});
                   this.setState({minimizeTable : false})
                }).then(() => this.loadListingTblData(1,this.state.assetId));
            }
        });
    }



    invoiceCalculationListJsx            =       ()      =>    {

        return (<Ax>
            <div style={{ paddingTop: "4px" }}>
                <div className="page_title row m0" style={{ height: "45px" }}>
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3 style={{ paddingTop: "13px" }}>Invoice Calculation</h3>
                    </div>
                    {!this.state.minimizeTable
                        ? <div className='col-sm-2 '>
                            <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                        </div> : null}
                    {!this.state.minimizeTable
                        ? <div className='col-sm-4 text-end mt7 '>
                        <button type="button" onClick={this.assetSearchModalInit} className="btn btn-secondary">
                            <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                        </div> : null}
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <div className="col-sm-3 tap_border_left">
                                        <h3 style={{ paddingTop: "13px" }}>Invoice Calculation : {this.state.invoiceCalculationId.slice(0, 10)}{this.state.invoiceCalculationId.length > 10 ? '...' : ''}  </h3>
                                    </div>
                                    <div className='col-sm-2 '>
                                        <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                    </div>
                                    <div className='col-sm-4 text-end mt7'>
                                        {this.state.showSaveInvoiceForm == false
                                            ? <span className="dropdown">
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                                    <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">

                                                    {this.state.showSaveInvoiceForm == false ? <li>{this.editLinkJsx(this.state.invoiceCalculationId)}</li> : null}
                                                    <li>{this.deleteLinkJsx(this.state.invoiceCalculationId)}</li>
                                                </ul>
                                            </span>
                                            : null
                                        }
                                        <button disabled={this.state.inovoiceDownloading || !this.props.permissions.includes('iam-asset-invoice-calculation-view')} onClick={() => this.downloadInvoiceHandler(this.state.invoiceCalculationId)} type="button" className="btn btn-secondary" title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''} style={{ "pointerEvents": "all" }}><TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} /> Download {this.state.inovoiceDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                        </button>
                                        <button onClick={this.closeInvoiceCalculation} type="button" className="btn btn-secondary">
                                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </Ax>
                            )
                            : null
                    }
                </div>
            </div>
            <div className="container-fluid pl0 pr13">
                <div className="page_containt row">
                    <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.invoiceCalculationTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0"><div className="bg-white p-2" style={{ maxHeight: "69vh", overflowY: "scroll" }}>{this.state.view_loading ? <Loader /> : this.invoiceCalculationViewDetailJsx()}</div> </div> : null}
                </div>
            </div>
        </Ax>);
    }

    invoiceCalculationTableJsx                   =   ()  =>  {
        return (
            <Ax>
                 <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.inCalcualtionClear}  /> 
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered bg-white align-items-center  table-sm table-hover table-fixed mb-0" id="assetInvoiceCalculationTable">

                        <thead className="table-secondary" >
                            {this.state.minimizeTable
                                ? (<tr><th scope="col">Invoice</th></tr>)
                                : (<tr>
                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                    <th scope="col" style={{ width: "13%" }}>Invoice Number</th>
                                    <th scope="col" style={{ width: "20%" }}>Site</th>
                                    <th scope="col" style={{ width: "25%" }}>Invoice Rule</th>
                                    <th scope="col" style={{ width: "20%" }}>Period  (From - To)</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-end">Amount</th>
                                    <th scope="col" style={{ width: "5%" }}>Action</th></tr>)}
                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? <tr><td className="text-center" colSpan="9"><Loader /></td></tr>
                                : (this.state.listingData.length > 0
                                    ? this.state.listingData.map((item, index) => this.listingTableTrJsx(item, index))
                                    : this.listingTableTrJsx(null))
                            }
                        </tbody>
                    </table>
                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1, this.state.assetId)}
                    />
                </div>
            </Ax>
        );
    }

    listingTableTrJsx                               =   (item, index=0)  =>  {
        return (
            item
                ? (<tr key={index}>
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <td onClick={() => { this.showInvoiceView(item.transaction_id) }}>
                                        <a role="button" className="link-primary" >{item.transaction_id}</a> <br />
                                        <small>Period : {item.period_start_display} - {item.period_end_display}</small> <br />
                                    </td>
                                </Ax>
                            )
                            : (<Ax>
                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                <td className="text-truncate" style={{ maxWidth: "calc( 10 * 1vw )" }}>
                                    <button disabled={!this.props.permissions.includes('iam-asset-invoice-calculation-view') || this.state.showSaveInvoiceForm} title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''} style={{ pointerEvents: "all" }} type="button" onClick={() => this.showInvoiceView(item.transaction_id)} className="btn btn-link">{item.transaction_id}</button>
                                </td>
                                <td>
                                    {item.site ? item.site.site_name : ''}<br />
                                    {item.site ? <small>Code : {item.site.site_code}</small> : ''}
                                </td>
                                <td>{item.include_rule.name} <br /> <small>Number : {item.include_rule.rule_number}</small></td>
                                <td>{item.period_start_display} - {item.period_end_display}</td>
                                <td className="text-end">Rs.{item.total_amount}</td>
                                <td className="p0 text-center">
                                    <span className="dropdown">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                {/* <Permission key={'iam-asset-invoice-calculation-view'}>View</Permission>*/}
                                                <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-asset-invoice-calculation-view')) {
                                                            return this.showInvoiceView(item.transaction_id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-view') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >View</a>
                                            </li>
                                            <li>
                                                <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-asset-invoice-calculation-delete')) {
                                                            return this.deleteInvoiceCalculation(item.transaction_id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete</a>
                                            </li>
                                        </ul>
                                    </span>
                                </td>
                            </Ax>)}
                </tr>)
                : (<tr key={index}><td className="text-center" colSpan={9}>No Record Found</td></tr>)
        );
    }

    invoiceCalculationViewDetailJsx                =   ()   =>   {
        return (<div>
          {this.state.view_loading ? <div className='text-center'><Loader/></div>
          :  this.state.invoiceCalculationId ?  <InvoiceCalulationView Id={this.state.invoiceCalculationId} showSaveInvoiceForm = {this.state.showSaveInvoiceForm} cancelEdit={this.cancelEdit}/> : null}
        </div>)
    }

    invoiceSearchJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="searchInvoiceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Invoice Calculation Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.invoiceSearchFormSubmit} id="invoiceSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Name</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_site')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_user_sites.filter(s => this.state.invoiceSearchForm.search_site.includes(s.value))}
                                            placeholder="Select Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Invoice Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            value={this.state.invoiceSearchForm.search_transaction_id}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'invoiceSearchForm')}
                                            placeholder="Invoice Number"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Invoice Rule</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="invoice_rule"
                                            value={this.state.invoiceSearchForm.invoice_rule}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'invoiceSearchForm')}
                                            placeholder="Invoice Rule"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                               
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods && this.state.all_periods !== undefined ? this.state.all_periods : []}
                                            value={this.state.invoiceSearchForm.transaction_period_range}
                                            startDate={this.state.invoiceSearchForm.transaction_period_range_start}
                                            endDate={this.state.invoiceSearchForm.transaction_period_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    invoiceSearchForm: { ...this.state.invoiceSearchForm, transaction_period_range: period, transaction_period_range_start: startDate ? startDate : null, transaction_period_range_end: endDate ? endDate : null }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - Invoice Calculation List</title></Helmet>
            {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.invoiceCalculationListJsx()}
            {this.invoiceSearchJsx()}
          
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(InvoiceCalculation);