import React, { Component } from 'react';
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

class TapAnalogClock extends Component {
  constructor(props) {
    super(props);

    this.state = {
        selectedTime: props.initialValue || '',
        showTimeKeeper: false,
    };
  }


  handleTimeChange = (value) => {
    // Format the selected time
    //const formattedTime = value ? value.format('hh:mm A') : '';
  
    // Pass the selected time to the parent component
    this.props.onTimeChange(value);
  };
 
  render() {
        return (
            <div className='multi-datepicker'>

                <DatePicker
                 value={this.props.initialValue}
                    //value={this.state.selectedTime}
                   hideSeconds={true}
                    format="hh:mm A"
                    className="datepicker-input"
                    disableDayPicker={true}
                    plugins={[
                        <TimePicker position="bottom" hStep={1} mStep={1}  use12Hours  // Set use12Hours to display "AM" or "PM"
                        hideSeconds={true}
                        secondStep={null}
                        format="hh:mm A"/>,
                    ]}
                   
                    onChange={this.handleTimeChange}
                />
            </div>)
    }
}

export default TapAnalogClock;
