
import React, { useRef, useState, useEffect } from "react";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { toast } from 'react-toastify';
import UpdateAttendenceModal from "./UpdateAttendenceModal";
import Loader from "../../../components/ui/Loader/Loader";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import HttpAPICall from "../../../services/HttpAPICall";
import TapApiUrls from "../../../services/TapApiUrls";
import EmployeeAttendenceDetailModal from "./EmployeeAttendenceDetailModal";
import { DisplayListPagination } from '../../../components/TapUi';
import TapIcon from "../../../services/TapIcon";
const DailyEmployeeAttendence = () => {
    const [loading, setLoading] = useState(false);
    const [listing_tbl_page, setListing_tbl_page] = useState(1);
    const [employee_listing, setEmployee_listing] = useState([]);
    const [date, setDate] = useState(new Date());
    const access_token = useSelector(state => state.auth.access_token);
    const [rosterMeta, setRosterMeta] = useState(null)
    const [listingMeta, setListingMeta] = useState(null)
    const [totalListingCount, setTotalListingCount] = useState(0)
    const updateAttendenceModalRef = useRef(null);
    const employeedetailModalRef = useRef(null);
    const params = useParams()
    const employeeAttendenceDetailModalRef = useRef();
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [checkInOut, setCheckInOut] = useState("CheckIn")

    function checkinoutstate() {
        setCheckInOut(checkInOut)
    }
    useEffect(() => {
        loadListingTblData();
    }, []);
    const handleShowModal = () => {
        employeeAttendenceDetailModalRef.current.show();
    };
    const afterSubmitAttendenceHandler = (date, empData) => {
        let itemList = [...employee_listing];

        // Iterate through each employee in empData
        empData.forEach(emp => {
            let empIndex = itemList.findIndex(i => i.enc_id === emp.enc_id);

            // Update loading status for the current employee
            itemList[empIndex] = { ...itemList[empIndex], loading: true };
        });
        setEmployee_listing({ itemList })
        let empIds = empData.map(emp => emp.enc_id);
        let params = {
            page: 1,
            per_page: 50,
            search_employee_ids: empIds,
            date: moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        };

        // HttpAPICall.withAthorization('GET', TapApiUrls.HRM_URL + '/attendance/list', access_token, params, {}, (resp) => {
        //     if (resp.data && resp.data.data) {
        //         resp.data.data.forEach(updatedEmp => {
        //             let empIndex = itemList.findIndex(i => i.enc_id === updatedEmp.enc_id);
        //             itemList[empIndex] = updatedEmp;
        //         });
        //         setEmployee_listing({ itemList })
        //     }
        // });
    }

    //     const goToPreviousDateHandler = () => {
    //         swal({
    //             title: "Shift not Started",
    //             text: "Shift for the current date still not started. Navigating to the previous day.",
    //             icon: "warning",
    //             dangerMode: true,
    //             showCancelButton: false,
    //             showConfirmButton: true,
    //             buttons: "Ok",
    //         }).then(willNavigate => {
    //             if (willNavigate) {
    //                 dateChangeHandler('prev')
    //             }
    //         });
    //     }


    //     const loadListingTblData = (page = 1) => {
    //         setLoading(true);
    //         setListing_tbl_page(page)
    //         // let params = { ...submittedCounterlogFilterForm, page: page, per_page: 50, date: date ? moment(date).format('YYYY-MM-DD') : '' };
    //    HttpAPICall.withAthorization('GET', TapApiUrls.HRM_URL + '/attendance/list', access_token, params, {}, (resp) => {
    //             let respData = resp.data;
    //             if (respData.rosterMeta && respData.rosterMeta.any_shift_started && respData.rosterMeta.any_shift_started == 'N') {
    //                 goToPreviousDateHandler()
    //             } else {
    //                 setEmployee_listing(respData.data)
    //                 setListingMeta(respData.data)
    //                 setRosterMeta(respData.rosterMeta)
    //                 setTotalListingCount(respData.meta.total)
    //             }

    //         }).then(() => setLoading(false));
    //     }


    //     const updateEmployeeAttendenceModalInit = (employee) => {
    //         let date = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    //         updateAttendenceModalRef.current.updateAttendenceInit(date, employee);
    //     }
    const loadListingTblData = () => {
        setLoading(true)
        const dummyData = [
            { enc_id: '1', name: 'Gunjan', check_in: '10:00 AM', check_out: '7:00 PM', status: 'Present', note: "any note" },
            { enc_id: '2', name: 'Sanjay', check_in: '10:00 AM', check_out: '6:00 PM', status: 'Present', note: "any note" },
            { enc_id: '3', name: 'Vinay', check_in: '9:00 AM', check_out: '7:00 PM', status: 'Present', note: "any note" },
        ];
        setEmployee_listing(dummyData);
        setLoading(false)
    };

    const checkAllItem = (event) => {
        const isChecked = event.target.checked;
        const employeeCollections = document.getElementsByClassName('employee_id_checkbox');
        for (let i = 0; i < employeeCollections.length; i++) {
            employeeCollections[i].checked = isChecked;
        }
    };

    const handleDateChange = (direction) => {
        const newDate = direction === 'prev' ? moment(date).subtract(1, 'days') : moment(date).add(1, 'days');
        setDate(newDate.toDate(), () => loadListingTblData());
    };
    const refreshAttendance = () => {
        loadListingTblData();
    };

    const updateMultipleAttendanceInit = (type) => {
        if (type == "CheckIn") {
            setCheckInOut(type)
        }
        else if (type == "CheckOut") {
            setCheckInOut(type)
        }
        const employeeCollections = document.getElementsByClassName('employee_id_checkbox');
        const selectedEmployees = Array.from(employeeCollections).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
        if (selectedEmployees.length === 0) {
            toast.error('Please select at least one employee', { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        // updateAttendenceModalRef.current.updateAttendenceInit(moment(date).format('YYYY-MM-DD'), selectedEmployees);
        handleShowModal()
    };


    const attendenceTableJsx = () => {
        return (
            <Ax>
                <table className="table table-bordered bg-white table-fixed table-hover mb-2">
                    <thead className='table-secondary text-center'>
                        <tr>
                            <th style={{ width: "5%" }}>
                                <input type='checkbox' onChange={checkAllItem} id="all_check_employee" />
                            </th>
                            <th style={{ width: "18%" }}>Employee Name</th>
                            <th style={{ width: "15%" }}>Shift</th>
                            <th style={{ width: "15%" }}>Site Name</th>
                            <th style={{ width: "13%" }}>Attendance Status</th>
                            <th style={{ width: "10%" }}>Check In</th>
                            <th style={{ width: "10%" }}>Check Out</th>
                            <th style={{ width: "10%" }}>Analysis</th>
                        </tr>
                    </thead>

                    <tbody>
                        {loading ? (
                            <tr><td className='text-center' colSpan={8}><Loader /></td></tr>
                        ) : (
                            employee_listing.length > 0 ? employee_listing.map((item) => (
                                <tr className='text-center' key={item.enc_id}>
                                    <td className="va_middle">
                                        <input type='checkbox' className="employee_id_checkbox" value={item.enc_id} />
                                    </td>
                                    <td className="va_middle text-start">
                                        <Link to="/employee_list">{item.name}</Link>
                                        <br />
                                        <span>Emp_code:AA899</span>
                                    </td>

                                    <td className='text-center'>
                                        {/* {item.roster_working_data && item.roster_working_data.shifts && item.roster_working_data.shifts.length > 0
    //                                                 ? item.roster_working_data.shifts.map((sf, key) => { return (<Ax>{sf.shift_name} <br /> <span className="text-sm">{sf.shift_start} - {sf.shift_end}</span></Ax>) })
    //                                                 : "-"} */}
                                        Morning<br /><span className="text-sm">08:00 AM</span></td>
                                    <td>Gurgaon</td>
                                    <td>

                                        <Link to={`/employee_daily_attendence/1`}
                                            className="mt-1 link-primary cursor_pointer">
                                            {/* {
    //                                             item.attendance
    //                                                 ? <div className="mt-1 link-primary cursor_pointer">
    //                                                     {item.attendance.status ? <Status color={item.attendance.status.color_code}>{item.attendance.status.name}</Status> : ""}
    //                                                 </div>
    //                                                 : <a role="button" className='link-primary' onClick={() => onClick={updateMultipleAttendanceInit}(item)}>No Record</a>} */}
                                            {item.status}</Link>
                                    </td>
                                    {/* {item.attendance && <Ax>
    //                                             <td>{item.attendance.check_in ? item.attendance.check_in.substring(0, 10) === item.attendance.date.substring(0, 10) ? (
    //                                                 // If equal, display only the time
    //                                                 new Date(item.attendance.display_check_in).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    //                                             ) : (
    //                                                 // If not equal, display the full check-in time
    //                                                 item.attendance.display_check_in
    //                                             ) : ""} </td>
    //                                             <td>{item.attendance.check_out ? item.attendance.check_out.substring(0, 10) === item.attendance.date.substring(0, 10) ? (
    //                                                 // If equal, display only the time
    //                                                 new Date(item.attendance.display_check_out).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    //                                             ) : (
    //                                                 // If not equal, display the full check-in time
    //                                                 item.attendance.display_check_out
    //                                             ) : ""} </td>
    //                                             <td>{item.attendance.note ? item.attendance.note : ""} </td>
    //                                         </Ax>} */}
                                    <td>{item.check_in}</td>
                                    <td>{item.check_out}</td>
                                    <td>8 Hours</td>
                                </tr>
                            )) : (
                                <tr><td className='text-center' colSpan={8}>No Records</td></tr>
                            )
                        )}
                    </tbody>
                </table>
                <DisplayListPagination
                    meta={listingMeta}
                    onPageChange={(e) => loadListingTblData(e.selected + 1)}
                />
            </Ax>
        );
    }
    const dailyAttendenceListJsx = () => {
        return (
            <Ax>
                <div className="page_title row m0">
                    <div className="col">
                        <h3>Daily Employee Attendance</h3>
                    </div>
                    <div className="col-sm-3 mt15">
                        <div className=" justify-content-center attendence_input">
                            <button className="btn btn-secondary px-3 me-1" type="button" disabled={loading} onClick={() => handleDateChange('prev')}>
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowLeft} />
                            </button>
                            <div className="add_calender_section">
                                <DatePicker
                                    selected={date}
                                    // onChange={(value, event) => { formDateStateHandler(value, 'date', 'YYYY-MM-DD', () => loadListingTblData()) }}
                                    onChange={(date) => setDate(date)}
                                    dateFormat="dd-MMM-yyyy"
                                    className="form-control form-control-sm"
                                    showMonthDropdown
                                    showYearDropdown
                                    autoComplete="off"
                                    scrollMonthYearDropdown
                                    required={true}
                                    maxDate={new Date()}
                                    disabled={loading}
                                    placeholderText={`Please Enter Date `}
                                />
                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "6px" }} />
                            </div>
                            <button className="btn btn-secondary px-3 " type="button" disabled={loading} onClick={() => handleDateChange('next')}>
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowRight} />
                            </button>
                        </div>
                    </div>
                    <div className="col text-end mt15">
                        <button type="button" className="btn btn-secondary" onClick={refreshAttendance} disabled={loading}>
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className={[loading ? "fa-spin" : ''].join(' ')} color="white" />
                        </button>
                        {/* <button type="button" className="btn btn-secondary cursor_pointer" onClick={updateMultipleAttendanceInit} disabled={loading}>
                            Update Attendance
                        </button> */}
                        <span className="dropdown">
                            <button
                                type="button"
                                id="actionMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="btn btn-secondary"
                                disabled={loading}>
                                {/* <TapIcon.FontAwesomeIcon icon={TapIcon.faPencilAlt} /> */}
                                Update Attendence
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                <li><Link className="dropdown-item" role="button" onClick={() => updateMultipleAttendanceInit("CheckIn")}>Check-In</Link> </li>
                                <li><Link className="dropdown-item" role="button" onClick={() => updateMultipleAttendanceInit("CheckOut")}>Check-Out</Link> </li>
                            </ul>
                        </span>
                        {/* <span className="dropdown">
                            <button
                                type="button"
                                id="actionMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="btn btn-secondary"
                                disabled={loading}>
                                <tapIcon.imageIcon
                                    icon={tapIcon.ActionIcon}
                                    className="img-fluid"
                                    alt="item-action"
                                />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                                <li><Link className="dropdown-item" role="button" >Upload</Link> </li>
                            </ul>
                        </span> */}
                    </div>
                </div>
                <div className="container-fluid pl5">
                    <div className="page_containt row">
                        <div className={['pageTbl', 'col-12'].join(' ')}>
                            {attendenceTableJsx()}
                        </div>
                    </div>
                </div>
            </Ax>
        );
    };

    return (
        <>
            <ApplicationLayout>
                <Helmet><title>Daily Attendance</title></Helmet>
                {dailyAttendenceListJsx()}
                <UpdateAttendenceModal ref={updateAttendenceModalRef}
                    afterSubmitAttendence={(date, empData) => { afterSubmitAttendenceHandler(date, empData) }}
                />
                <EmployeeAttendenceDetailModal ref={employeeAttendenceDetailModalRef} checkInOut={checkInOut} />
                {/* <EmployeeAttendenceDetailModal ref={employeedetailModalRef} employeeData={selectedEmployee} /> */}
            </ApplicationLayout>
        </>
    );
}

export default DailyEmployeeAttendence;
