import React from "react";
import ApplicationLayout from "../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import Ax from "../../components/hoc/Ax";
import { connect } from "react-redux";
import HttpAPICall from "../../services/HttpAPICall";
import TapApiUrls from "../../services/TapApiUrls";
import Loader from "../../components/ui/Loader/Loader";
import Status from "../../components/ui/Status";
import TapIcon from "../../services/TapIcon";
import { DisplayListPagination } from "../../components/TapUi";
import TapSelect from "../../components/ui/TapSelect";
import AppBaseComponent from "../../components/AppBaseComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

class IOTProviderVendor extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                  =   {
            listing_loading             :   false,
            iot_provider_vendor         :   [],
            listingMeta                 :   null,
            statusData                  :   [],
            iotProvider                 :   [],
        };

        this.iotVendorUrl      =   `${TapApiUrls.ORGANIZATION_URL}/iot_vendor`;

    }

    componentDidMount               =   ()  =>  {
        this.loadingListingTblData(1);
    };

    loadingListingTblData           =   (page = 1)  =>  {

        this.setState({listing_loading : true});
        const params                =   {page:page};

        HttpAPICall.withAthorization('GET',  `${this.iotVendorUrl}/list`, this.props.access_token, params, {}, (resp) => { 
        
            const respData          =   resp.data;
            this.setState({
                iot_provider_vendor     :   respData.data,
                listingMeta             :   respData.meta
            });

        }).then(() => this.setState({listing_loading: false}));

    };

    reIntegration                   =   (iot_provider_id)  =>  {

        HttpAPICall.withAthorization('PUT',  `${this.iotVendorUrl}/re-integrate/${iot_provider_id}`, this.props.access_token, {}, {}, (resp) => { 
            toast.success(resp.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.loadingListingTblData(1);
        });
    }

    deActivate                      =   (iot_provider_id)  =>  {

        HttpAPICall.withAthorization('PUT',  `${this.iotVendorUrl}/deactivate/${iot_provider_id}`, this.props.access_token, {}, {}, (resp) => { 
            toast.success(resp.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.loadingListingTblData(1);
        });
    }

    iotProviderVendorJsx            =   ()  =>  {
        return (
                <Ax>
                    <div className="page_title row m0">
                        <div className="col-12">
                            <h3 className="mx-1">IoT Integration</h3>
                        </div>
                    </div>
                    <div col-9 tap_border_left>
                    <div className="container-fluid p15">
                        <div className="page_containt row py-1 px-2">
                            <div className="tab_content_header bg-white">
                                {
                                    this.state.listing_loading 
                                    ?
                                    <div className="p-2">
                                        <Loader/>
                                    </div>
                                    :
                                    this.state.iot_provider_vendor.length > 0
                                    ?
                                    this.state.iot_provider_vendor.map((data, i) => {
                                        return (
                                            <div className="card my-3" key={i}>
                                                <div className="card-header">
                                                    <div className='row align-items-center'>
                                                        <div className='col-sm-7'><b>{data.name}</b></div>
                                                        <div className=' col-sm-5 text-end'>
                                                            {
                                                                (data.provider_vender && data.provider_vender.status && data.provider_vender.status.key === 1)
                                                                ?
                                                                <Link to={`iot_integration/${data.name.toLowerCase()}/${data.id}`} className="btn  btn-secondary mx-1 disabled" style={{padding: "15px"}}>Integrate
                                                                </Link>
                                                                :
                                                                <Link to={`iot_integration/${data.name.toLowerCase()}/${data.id}`} className="btn  btn-primary mx-1" style={{padding: "15px"}} >Integrate
                                                                </Link>
                                                            }
                                                            
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn ">
                                                                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                {
                                                                    <Ax>
                                                                        {
                                                                            (data.provider_vender && data.provider_vender?.status)
                                                                            ?
                                                                            <li>
                                                                                <Link className="dropdown-item" role="button" onClick={() => this.reIntegration(data.id)}>ReIntegrate
                                                                                </Link>
                                                                            </li>
                                                                            :
                                                                            <li>
                                                                                <Link className="dropdown-item disabled" role="button">ReIntegrate
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                        {
                                                                            (data.provider_vender && data.provider_vender.status && data.provider_vender.status.key === 1)
                                                                            ?
                                                                            <li>
                                                                                <Link className="dropdown-item" role="button" onClick={() => this.deActivate(data.id)}>DeActivate
                                                                                </Link>
                                                                            </li>
                                                                            :
                                                                            <li>
                                                                                <Link className="dropdown-item disabled" role="button">DeActivate
                                                                                </Link>
                                                                            </li>
                                                                        }
                                                                    </Ax>
                                                                } 
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <table className="table mb-0 table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td className="details-label" style={{width: "20%"}}>
                                                                    <b>IoT Provider :</b>
                                                                </td>
                                                                <td className="details-label" style={{width: "55%"}}>
                                                                    {data.name}  
                                                                </td>
                                                                <td className="text-center" style={{width: "25%", "vertical-align" : "middle", "text-align" : "center"}} rowspan="3">
                                                                    <img src={data.logo} alt={data.name}height="52"/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="details-label" style={{width: "20%"}}>
                                                                    <b>Username :</b>
                                                                </td>
                                                                <td className="details-label" style={{width: "55%"}}>
                                                                    { 
                                                                        data.provider_vender && data.provider_vender.provider_credential.length > 0 && data.provider_vender.provider_credential.map(data => data.key=== 'username' ? data.value : '')
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="details-label" style={{width: "20%"}}>
                                                                    <b>Status :</b>
                                                                </td>
                                                                <td className="details-label" style={{width: "55%"}}>
                                                                    {
                                                                        data.provider_vender 
                                                                        ?
                                                                            <Status color={data.provider_vender.status.color_code}>{data.provider_vender.status.status}</Status>
                                                                        :
                                                                        <Status color="#b0b2b1">Inactive</Status>
                                                                    }
                                                                    
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : 
                                    <div className="text-center p-3">No Records</div>
                                }  
                                {
                                    (!this.state.listing_loading) && 
                                    <DisplayListPagination meta={this.state.listingMeta}
                                    onPageChange={(e) => this.loadingListingTblData(e.selected + 1)}
                                />
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                </Ax>
            );

    };


    render() {
        return <ApplicationLayout>
                    <Helmet><title>IoT Integration</title></Helmet>
                    {this.iotProviderVendorJsx()}
                </ApplicationLayout>
    }

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};

export default connect(mapStateToProps)(IOTProviderVendor);