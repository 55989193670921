import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import {connect} from "react-redux";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import {Modal} from "bootstrap";
import {toast} from "react-toastify";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { Helmet } from 'react-helmet';
import TapSelect from '../../../components/ui/TapSelect';
import { HRM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import swal from "sweetalert";
import { DisplayListPagination } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';

class EmployeeDocument extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.addEmployeeDocumentInitial     =   {
            compliance_type_id      :         "",
            number                  :         "",
            issue_date              :           "",
            expiry_date             :           "",
            validity_date           :           "",
           
        }

        this.addDocumentInitial     =   {
            name                    :         "",
            number                  :         "",
            file                    :         null,
            file_name               :         null,
            base64_document         :         null
        }

        this.state                  =        {
            asset_id                :        null,
            listing_loading         :        false,
            addEmployeeDocumentForm   :       {...this.addEmployeeDocumentInitial},
            complianceTypes           :       [],
            formDataLoaded          :       false,
            formDataLoading         :       false,
            selectedComplianceData  :       null,
            saveFormSubmitting      :       false,
            employee_id             :       "",
            employee_listing        :       [],
            listingMeta             :       null,
            totalListingCount        :      0,
            other                   :           'N',
            addDocumentForm    :       {...this.addDocumentInitial},
            documentTypes           :       [],
            documentFormDataLoaded :  false,
            complianceData          :       null,
            detail_loading          :       false,
            editCompliance          :       false,
        }

        this.compliance_list_url    =   HRM_API_BASE_URL_2 + '/compliance/list';
        this.compliance_form_data_url =   HRM_API_BASE_URL_2 + '/compliance/form_data';
        this.compliance_edit_url    =   HRM_API_BASE_URL_2 + '/compliance/edit';
        this.compliance_add_url = HRM_API_BASE_URL_2 + '/compliance/add';
        this.compliance_delete_url = HRM_API_BASE_URL_2 + '/compliance/delete';
        this.compliance_doc_delete_url = HRM_API_BASE_URL_2 + '/compliance/delete_document';
        this.compliance_add_document = HRM_API_BASE_URL_2 + '/compliance/add_document';
        this.compliance_detail_document = HRM_API_BASE_URL_2 + '/compliance/detail';

        this.hrmDocTypessUrl        =   ORG_API_BASE_URL_2  + '/setting/hrm_doc_types';

    }

    componentDidMount() {
        this.initilaizeFilterFormData(this.props);
        this.loadComplianceTypesFromApi();
        this.addDocumentModal                 =        new Modal(document.getElementById('addDocumentModal'), {keyboard: false, backdrop :false});
        this.addEmployeeDocumentModal         =        new Modal(document.getElementById('addComplianceModal'), {keyboard: false, backdrop :false});
        this.viewEmployeeDetailModal          =        new Modal(document.getElementById('viewEmployeeDetailModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.employee_id !== this.props.employee_id) {
            this.initilaizeFilterFormData(nextProps);
        }      
    }

    initilaizeFilterFormData    =   (pr)        =>  {
        this.setState({
            employee_id :   pr.employee_id ? pr.employee_id : ""
        },() => {
            this.loadListingTblData(1);
        })
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,search_employee_id:this.state.employee_id,need_documents:'Y'};
        HttpAPICall.withAthorization('GET',  this.compliance_list_url, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;  
            this.setState({
                employee_listing         :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }
   
    addComplianceModalInit                 =   (data=null)  =>  {
        //Initialize Search Form :-
        // if(!this.state.formDataLoaded){
           
       // }
        this.addEmployeeDocumentModal.show();
        if(data){
            let updateFormState = [];
            if (data.additionalAttributes  && data.additionalAttributes.length > 0) {
                data.additionalAttributes.map((i,index) => {
                   
                    let name =  'additional_attributes_' + i.key;
                    updateFormState[name] = i.value
                });
            }
            this.viewEmployeeDetailModal.hide();
            let selectedComplianceData = this.state.complianceTypes.find(cm => cm.value ==  data?.compliance_type?.id);
            this.setState({
                addEmployeeDocumentForm : {...this.addEmployeeDocumentInitial,
                    compliance_type_id      :         data.compliance_type ? data.compliance_type.id : "",
                    number                  :         data.number ? data.number : "",
                    issue_date              :          data.issue_date ? data.issue_date : "",
                    expiry_date             :          data.expiry_date ? data.expiry_date : "",
                    validity_date           :          data.validity_date ? data.validity_date : "",
                    employee_compliance_id  :          data.id,
                     ...updateFormState
                },
                editCompliance          :       true,
                 selectedComplianceData : selectedComplianceData && selectedComplianceData.data ? selectedComplianceData.data : null
            },() => {
               
            })
        }else{
            this.setState({
                addEmployeeDocumentForm : {...this.addEmployeeDocumentInitial},
                selectedComplianceData      :   null,
                editCompliance          :       false
            })
        }
        
    }

    loadComplianceTypesFromApi                 =   ()  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', this.compliance_form_data_url, this.props.access_token , {}, {}, (response) => {
            let respData = response.data
            this.setState({
                complianceTypes   :   respData && respData.compliance_types && respData.compliance_types.length > 0 ? respData.compliance_types.map(s => {return({value : s.id , label : s.type,data:s})}) : [],
                formDataLoaded    :   true,
            },() => {
                
            });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }


    submitEmployeeDocumentHandler                  =   (e) =>  {
        e.preventDefault() 
       
        this.setState({ saveFormSubmitting: true });
        let additional_attributes = {};
        if(this.state.selectedComplianceData && this.state.selectedComplianceData.additionalAttr_details && this.state.selectedComplianceData.additionalAttr_details.length > 0 ){
            this.state.selectedComplianceData.additionalAttr_details.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addEmployeeDocumentForm[name] ? this.state.addEmployeeDocumentForm[name] : '';
            })
        } 
        let formData ={
            ...this.state.addEmployeeDocumentForm,
            additional_attributes   :   additional_attributes,
            employee_id : this.state.employee_id ? this.state.employee_id : ""
        }
        if (this.state.editCompliance) {
            HttpAPICall.withAthorization('PUT', this.compliance_edit_url, this.props.access_token, null, { ...formData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addEmployeeDocumentModal.hide();
                this.loadListingTblData(1);
                
                this.setState({
                    addEmployeeDocumentForm: { ...this.addEmployeeDocumentInitial },
                    selectedComplianceData: null
                })
                this.loadListingTblData(1)
            }).then(() => this.setState({ saveFormSubmitting: false }))
        } else {
            HttpAPICall.withAthorization('POST', this.compliance_add_url, this.props.access_token, null, { ...formData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addEmployeeDocumentModal.hide();
                this.setState({
                    addEmployeeDocumentForm: { ...this.addEmployeeDocumentInitial },
                    selectedComplianceData: null
                })
                this.loadListingTblData(1)
            }).then(() => this.setState({ saveFormSubmitting: false }))
        }
         
    }

    addDocumentModalInit                 =   (comp)  =>  {
        //Initialize Search Form :-
        this.addDocumentModal.show();
        let form = document.getElementById("addDocumentModalForm");
        form.reset();
        this.setState({
            other           : 'N',
            addDocumentForm :   {...this.state.addDocumentInitial,
                employee_compliance_id  :   comp.id,
                compliance_name     :       comp && comp.compliance_type ?  comp.compliance_type.type : ""
            }})
        if(!this.state.documentFormDataLoaded){
            this.loadDocTypesFromApi()
        }
    }

    loadDocTypesFromApi                 =   ()  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', this.hrmDocTypessUrl, this.props.access_token , {}, {}, (response) => {
            this.setState({
                documentTypes   :   response.data.map(s => {return({value : s , label : s})}),
                documentFormDataLoaded  :   true,
            });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }

    handleFileChange = async  (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64 = await this.convertBase64(file);
        this.setState({addDocumentForm: { ...this.state.addDocumentForm,base64_document : base64,file_name:filename}})
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    submitAddDocumentForm               =      (event)    =>   {
        event.preventDefault()
        let frmData = {
                employee_compliance_id          :   this.state.addDocumentForm.employee_compliance_id,
                name              :   this.state.addDocumentForm.name,
                number            :   this.state.addDocumentForm.number,
                file_name         :   this.state.addDocumentForm.file_name,
                base64_document   :   this.state.addDocumentForm.base64_document
            };

        HttpAPICall.withAthorization('POST', this.compliance_add_document,
            this.props.access_token, null, {...frmData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addDocumentModal.hide();
                this.loadListingTblData(1)
                this.setState({ 
                    addDocumentForm    :       {...this.addDocumentInitial},
                    documentFormDataLoaded         :       false,         
                })  
        });
    }

   
    deleteEmployeeDocument                   =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.compliance_delete_url + '/' + id, this.props.access_token, {},{} ,(response) => {
                        this.loadListingTblData(1);
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    }).then(() => {

                    });
                }
            });
    }

    deleteComplianceDocument                   =   (id,compliance_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.compliance_doc_delete_url + '/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.loadListingTblData(1);
                        if(compliance_id){
                            this.viewDetailInit(compliance_id)
                        }
                    }).then(() => {

                    });
                }
            });
    }

    viewDetailInit          =       (id)            =>      {
        this.viewEmployeeDetailModal.show();
        if(id){
            this.getComplianceAllDetail(id)
        }
    }

    getComplianceAllDetail                 =   (id)  =>         {
        this.setState({detail_loading : true});
        let params                      =   {unmasked_detail:'Y',need_documents:'Y'};
        HttpAPICall.withAthorization('GET',  this.compliance_detail_document + '/' + id, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;  
            this.setState({
                complianceData         :           respData.data,
                
            });
        }).then(() => this.setState({detail_loading : false}));
    }

    //function for document modal
    addComplianceModalJsx                  =   ()  =>  {
        
        return (
            <div className="modal fade" id="addComplianceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addComplianceModalLabel">{this.state.editCompliance ? "Edit" : "Add"} Employee Compliance</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.formDataLoading ? <Loader />
                            : <form id="addComplianceModalForm" onSubmit={this.submitEmployeeDocumentHandler}>
                                <div className="modal-body">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <label className="form-label form-lable-sm require">Compliance Type</label>
                                        </div>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                options={this.state.complianceTypes}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addEmployeeDocumentForm', 'compliance_type_id')
                                                    this.setState({
                                                        selectedComplianceData: selectedOption ? selectedOption.data : null
                                                    })
                                                }}
                                                value={this.state.complianceTypes.find(u => this.state.addEmployeeDocumentForm.compliance_type_id == u.value)}
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder="Select Document Type"
                                                autoCompelete="off"
                                                required={true}
                                                autoFocus={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-3">
                                            <label className="form-label form-lable-sm require">Number</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="number"
                                                type="text"
                                                value={this.state.addEmployeeDocumentForm.number}
                                                onChange={(e) => this.formInputHandler(e, "addEmployeeDocumentForm")}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                required={true}
                                                placeholder="Enter Document Number"
                                            />
                                        </div>
                                    </div>
                                    {this.state.selectedComplianceData && <Ax>

                                        {this.state.selectedComplianceData.date_of_issue == 'Y' &&
                                            <div className="row mt-3">
                                                <div className="col-sm-3">
                                                    <label className="form-label form-lable-sm">Date of Issue</label>
                                                </div>
                                                <div className="col-sm-7 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.addEmployeeDocumentForm.issue_date
                                                                ? moment(this.state.addEmployeeDocumentForm.issue_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        name="issue_date"
                                                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                        onChange={(value, event) => this.formDateHandler('issue_date', value, 'addEmployeeDocumentForm')}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control form-control-sm"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        //required={true}
                                                        placeholderText={`Please Enter Date of Issue`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                                </div>
                                            </div>}
                                        {this.state.selectedComplianceData.date_of_validity == 'Y' &&
                                            <div className="row mt-3">
                                                <div className="col-sm-3">
                                                    <label className="form-label form-lable-sm require">Due Date</label>
                                                </div>
                                                <div className="col-sm-7 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.addEmployeeDocumentForm.validity_date
                                                                ? moment(this.state.addEmployeeDocumentForm.validity_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        name="validity_date"
                                                        onChange={(value, event) => this.formDateHandler('validity_date', value, 'addEmployeeDocumentForm')}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control form-control-sm"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        required={true}
                                                        placeholderText={`Please Enter Due Date`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                                </div>
                                            </div>}
                                        {this.state.selectedComplianceData.date_of_expiry == 'Y' &&
                                            <div className="row mt-3">
                                                <div className="col-sm-3">
                                                    <label className="form-label form-lable-sm require">Date of Expiry</label>
                                                </div>
                                                <div className="col-sm-7 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.addEmployeeDocumentForm.expiry_date
                                                                ? moment(this.state.addEmployeeDocumentForm.expiry_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        name="validity_date"
                                                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                        onChange={(value, event) => this.formDateHandler('expiry_date', value, 'addEmployeeDocumentForm')}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control form-control-sm"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        required={true}
                                                        placeholderText={`Please Enter Date of Expiry`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                                </div>
                                            </div>}
                                        {this.state.selectedComplianceData && this.state.selectedComplianceData.additionalAttr_details && this.state.selectedComplianceData.additionalAttr_details.length > 0
                                            ? (
                                                <Ax>

                                                    {
                                                        this.state.selectedComplianceData.additionalAttr_details.map((i, k) => {
                                                            let name = 'additional_attributes_' + i.key;
                                                            let input = null;
                                                            if (i.type == 'text') {
                                                                input = <input
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.addEmployeeDocumentForm[name] ? this.state.addEmployeeDocumentForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addEmployeeDocumentForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    id={i.formula_key}
                                                                    value={this.state.addEmployeeDocumentForm[name] ? this.state.addEmployeeDocumentForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addEmployeeDocumentForm', (e) => this.calulateProductivityFormula(e))}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.addEmployeeDocumentForm[name]
                                                                            ? moment(this.state.addEmployeeDocumentForm[name]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addEmployeeDocumentForm')}
                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${i.name}`}

                                                                />
                                                            } else if (i.type == 'dropdown') {
                                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addEmployeeDocumentForm', name)}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                    value={options.find(o => o.value == this.state.addEmployeeDocumentForm[name])}
                                                                    placeholder={`Please Select ${i.name}`}
                                                                />
                                                            }

                                                            return (<div key={k} className="row align-items-center my-3">
                                                                <div className="col-sm-3"><label className="form-label form-lable-sm ">{i.name}</label></div>
                                                                <div className="col-sm-7">{input}</div>

                                                            </div>);
                                                        })
                                                    }
                                                </Ax>
                                            )
                                            : null
                                        }
                                        {/* <div className="row mt-3">
                                            <div className="col-sm-3">
                                                <label className="form-label">Notes</label>
                                            </div>
                                            <div className="col-md-7">
                                                <textarea
                                                    name="notes"
                                                    value={this.state.addEmployeeDocumentForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, "addEmployeeDocumentForm")}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="Enter Notes"
                                                />
                                            </div>

                                        </div>
                                        */}
                                    </Ax>}

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="addComplianceModalForm">Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        );
    }

    addDocumentModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addDocumentModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add Document for {this.state?.addDocumentForm?.compliance_name}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.formDataLoading ? <Loader />
                            : <form onSubmit={this.submitAddDocumentForm} id="addDocumentModalForm">
                                <div className="modal-body">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document Type</label>
                                        </div>
                                        <div className="col-sm-7">
                                            {this.state.other == "Y"
                                                ? <input
                                                    name="name"
                                                    type="text"
                                                    value={this.state.addDocumentForm.name}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                                    placeholder="Please enter Type Name"
                                                    required={true}
                                                // autoFocus={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.documentTypes}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addDocumentForm', 'name')}
                                                    value={this.state.documentTypes.find(u => this.state.addDocumentForm.name == u.value)}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Select Document Type"
                                                    autoCompelete="off"
                                                    required={true}
                                                    autoFocus={true}
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="other"
                                                type="checkbox"
                                                value={this.state.other}
                                                onChange={(e) => { this.setState({ other: this.state.other == 'N' ? 'Y' : 'N' }) }}
                                                checked={this.state.other == 'Y'}
                                                className="form-check-input"
                                                id="other"
                                            />
                                            <label className="form-check-label mx-2" htmlFor="other">New </label>
                                        </div>
                                        {/* <div className="col-md-8">
                                   <input
                                       name="name"
                                       type="text"
                                       value={this.state.addDocumentForm.name}
                                       onChange={(e) => this.formInputHandler(e,"addDocumentForm")}
                                       className="form-control"
                                       autoComplete="off"
                                       placeholder="Enter Document Name"
                                   />
                               </div> */}

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-3">
                                            <label className="form-label">Document Number</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="number"
                                                type="text"
                                                value={this.state.addDocumentForm.number}
                                                onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Enter Document Number"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="file"
                                                type="file"
                                                value={this.state.addDocumentForm.file}
                                                onChange={(e) => {
                                                    this.formInputHandler(e, "addDocumentForm");
                                                    this.handleFileChange(e)
                                                }}
                                                className="form-control"
                                                autoComplete="off"
                                                required={true}
                                                placeholder="Upload Document"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        );
    }

    employeeAllDetailModalJsx                  =   ()  =>  {
        let comp = this.state.complianceData;
        return (
            <div className="modal fade" id="viewEmployeeDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">View Detail of {comp && comp.compliance_type ? comp.compliance_type.type : ""}</h5>
                            <button type="button" disabled={!this.props.permissions.includes('hrm_compliance_edit')} className="btn btn-primary" onClick={() => this.addComplianceModalInit(comp)}>Edit</button>
                        </div>
                        <div className="modal-body">
                            {this.state.detail_loading ? <div className='text-center'><Loader /></div>
                                : <Ax>
                                    {comp && <Ax>  <table className="table table-hover  mb-0 table-bordered">
                                        <tbody>
                                            {comp.compliance_type && comp.compliance_type.date_of_due && <tr>
                                                <td style={{ "width": "15%" }}>Status</td>
                                                <th colSpan={3}>{comp.statusData ? <Status color={comp.statusData.status_color_code}>{comp.statusData.status_text}</Status> : null}</th>
                                            </tr>}
                                            <tr>
                                                <td style={{ "width": "15%" }}>Number</td>
                                                <th style={{ "width": "35%" }}>{comp.number ? comp.number : ""}</th>
                                                <td style={{ "width": "15%" }}>Date of Issue</td>
                                                <th style={{ "width": "35%" }}>{comp.issue_date ? comp.issue_date_display : ""}</th>

                                            </tr>

                                            <tr>
                                                {comp.validity_date && <Ax>
                                                    <td style={{ "width": "15%" }}>Due Date</td>
                                                    <th colSpan={comp.expiry_date ? 1 : 3}>{comp.validity_date ? comp.validity_date_display : ""}</th>
                                                </Ax>}
                                                {comp.expiry_date &&
                                                    <Ax>
                                                        <td style={{ "width": "15%" }}>Date of Expiry</td>
                                                        <th colSpan={comp.validity_date ? 1 : 3}>{comp.expiry_date ? comp.expiry_date_display : ""}</th>
                                                    </Ax>
                                                }
                                            </tr>
                                            {comp && comp.additionalAttributes && comp.additionalAttributes.length > 0
                                                ? comp.additionalAttributes.map((attr) => {
                                                    return (<tr key={attr.key}>
                                                        <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                        <th scope="col" colSpan={3}>{attr.value_display ? attr.value_display : "-"}</th>
                                                    </tr>)

                                                }) : null}
                                            <tr>
                                                <th colSpan={4}>Documents</th>
                                            </tr>


                                        </tbody>
                                    </table>


                                        <table className="table table-bordered  table-hover">
                                            <tbody>
                                                {comp && comp.documents && comp.documents.length > 0 ? comp.documents.map((item, index) => {
                                                    return <tr>
                                                        <td scope="col" className='text-center' style={{ "width": "15%" }}>{index + 1}</td>
                                                        <td scope="col" className="text-capitalize">{item.name} {item.number && <span>({item.number})</span>}</td>
                                                        <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                                            <a role="button"
                                                                target='_blank'
                                                                href={item.document}
                                                                //className={[this.props?.permissions?.permissions?.includes('hrm_compliance_doc_view') ? '' : 'disabled'].join(' ')}
                                                                //title={!this.props?.permissions?.permissions?.includes('hrm_compliance_doc_view') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            > <tapIcon.FontAwesomeIcon icon={tapIcon.faEye} /></a>

                                                        </td>
                                                        <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                                            <a role="button"
                                                                target='_blank'
                                                                href={item.download_link}
                                                                // className={[this.props?.permissions?.permissions?.includes('iam-asset-doc-download') ? '' : 'disabled'].join(' ')}
                                                                // title={!this.props?.permissions?.permissions?.includes('iam-asset-doc-download') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            ><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /></a>

                                                        </td>
                                                        <td className="text-center" style={{ "width": "10%" }}>
                                                            <a role="button"
                                                                onClick={() => {
                                                                    // if (this.props.permissions.permissions.includes('hrm_compliance_doc_deletee')) {
                                                                    return this.deleteComplianceDocument(item.doc_id, comp.id)
                                                                    //}
                                                                }}
                                                                // className={[this.props.permissions.permissions.includes('hrm_compliance_doc_deletee') ? '' : 'disabled'].join(' ')}
                                                                // title={!this.props.permissions.permissions.includes('hrm_compliance_doc_deletee') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            ><tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt} /></a>

                                                        </td>
                                                    </tr>
                                                }) : <tr>
                                                    <td className="text-center" colSpan="5">No  Documents</td>
                                                </tr>}
                                            </tbody> </table>
                                    </Ax>}
                                </Ax>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render() {
   
        return (
            <Ax>
                <Helmet><title>Employee Compliance</title></Helmet>
                <div className="tab_content_header">
                    <div className="tab_content_wrapper"><span className="content_heading">Compliance</span>
                        <div className="float-end">
                            <button disabled={!this.props.permissions.includes('hrm_compliance_add')} className="btn btn-secondary " onClick={() => this.addComplianceModalInit()}>Add</button>
                        </div>
                    </div>
                    {this.state.listing_loading ? <Loader />
                        : this.state.employee_listing && this.state.employee_listing.length > 0
                            ? this.state.employee_listing.map((comp, key) => {
                                return (
                                    <div className="card my-3">
                                        <div className="card-header">
                                            <div className='row align-items-center'>
                                                <div className='col-sm-7'>{comp.compliance_type ? comp.compliance_type.type : ""}</div>
                                                <div className=' col-sm-5 text-end'>
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn ">
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} className="img-fluid" alt="item-action" />
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li>
                                                            <a role="button" href
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('hrm_compliance_doc_add')) {
                                                                        return this.addDocumentModalInit(comp);
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('hrm_compliance_doc_add') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('hrm_compliance_doc_add') ? 'You dont have permission for this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Add Document</a>
                                                        </li>
                                                        <li>
                                                            <a role="button" href
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('hrm_compliance_view_unmasked')) {
                                                                        return this.viewDetailInit(comp.id);
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('hrm_compliance_view_unmasked') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('hrm_compliance_view_unmasked') ? 'You dont have permission for this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >View Imp Detail</a>
                                                        </li>
                                                        <li>
                                                            <a role="button" href
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('hrm_compliance_delete')) {
                                                                        return this.deleteEmployeeDocument(comp.id)
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('hrm_compliance_delete') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.includes('hrm_compliance_delete') ? 'You dont have permission for this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Delete</a>
                                                        </li>
                                                       
                                                    </ul>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-body">
                                            <table className="table table-hover  mb-0 table-bordered">
                                                <tbody>
                                                    {comp.compliance_type && comp.compliance_type.date_of_due && <tr>
                                                        <td style={{ "width": "15%" }}>Status</td>
                                                        <th colSpan={3}>{comp.statusData ? <Status color={comp.statusData.status_color_code}>{comp.statusData.status_text}</Status> : null}</th>
                                                    </tr>}
                                                    <tr>
                                                        <td style={{ "width": "15%" }}>Number</td>
                                                        <th style={{ "width": "35%" }}>{comp.number ? comp.number : ""}</th>
                                                        <td style={{ "width": "15%" }}>Date of Issue</td>
                                                        <th style={{ "width": "35%" }}>{comp.issue_date ? comp.issue_date_display : ""}</th>
                                                    </tr>
                                                    <tr>
                                                        {comp.validity_date && <Ax>
                                                            <td style={{ "width": "15%" }}>Due Date</td>
                                                            <th colSpan={comp.expiry_date ? 1 : 3}>{comp.validity_date ? comp.validity_date_display : ""}</th>
                                                        </Ax>}
                                                        {comp.expiry_date && <Ax>
                                                            <td style={{ "width": "15%" }}>Date of Expiry</td>
                                                            <th colSpan={comp.validity_date ? 1 : 3}>{comp.expiry_date ? comp.expiry_date_display : ""}</th>
                                                        </Ax>}
                                                    </tr>
                                                    {comp && comp.additionalAttributes && comp.additionalAttributes.length > 0
                                                        ? comp.additionalAttributes.map((attr) => {
                                                            return (<tr key={attr.key}>
                                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                                <th scope="col" colSpan={3}>{attr.value_display ? attr.value_display : "-"}</th>
                                                            </tr>)

                                                        }) : null}
                                                    <tr>
                                                        <th colSpan={4}>Documents</th>
                                                    </tr>


                                                </tbody>
                                            </table>


                                            <table className="table table-bordered  table-hover">
                                                <tbody>
                                                    {comp && comp.documents && comp.documents.length > 0 ? comp.documents.map((item, index) => {
                                                        return <tr>
                                                            <td scope="col" className='text-center' style={{ "width": "15%" }}>{index + 1}</td>
                                                            <td scope="col" className="text-capitalize">{item.name} {item.number  && <span>({item.number})</span>}</td>
                                                           <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                                                <a role="button"
                                                                    target='_blank'
                                                                    href={item.document}
                                                                    className={[this.props?.permissions.includes('hrm_compliance_doc_view') ? '' : 'disabled-link'].join(' ')}
                                                                    title={!this.props?.permissions.includes('hrm_compliance_doc_view') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                > <tapIcon.FontAwesomeIcon icon={tapIcon.faEye}  /></a>

                                                            </td>
                                                            <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                                                <a role="button"
                                                                    target='_blank'
                                                                    href={item.download_link}
                                                                   className={[this.props?.permissions.includes('hrm_compliance_doc_download') ? '' : 'disabled-link'].join(' ')}
                                                                   title={!this.props?.permissions.includes('hrm_compliance_doc_download') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                ><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload}  /></a>

                                                            </td>
                                                            <td className="text-center" style={{ "width": "10%" }}>
                                                                <a role="button"
                                                                    onClick={() => {
                                                                         if (this.props.permissions.includes('hrm_compliance_doc_delete')) {
                                                                            return this.deleteComplianceDocument(item.doc_id)
                                                                        }
                                                                    }}
                                                                    className={[this.props.permissions.includes('hrm_compliance_doc_delete') ? '' : 'disabled-link'].join(' ')}
                                                                    title={!this.props.permissions.includes('hrm_compliance_doc_delete') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                ><tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt}  /></a>

                                                            </td>
                                                        </tr>
                                                    }) : <tr>
                                                        <td className="text-center" colSpan="5">No  Documents</td>
                                                    </tr>}
                                                </tbody> </table>
                                        </div>
                                    </div>
                                )
                            })
                            : <div className="card my-2">

                                <div className="card-body">
                                    <div className='text-center'>No Compliance Added</div>
                                </div>
                            </div>}
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                    />
                </div>
                {this.addComplianceModalJsx()}
                {this.addDocumentModalJsx()}
                {this.employeeAllDetailModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :    state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    }
}

export default connect(mapStateToProps)(EmployeeDocument);
