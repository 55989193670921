import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Ax from "../../components/hoc/Ax";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapIcon from '../../services/TapIcon';
import Loader from '../../components/ui/Loader/Loader';
import {Modal} from "bootstrap";
import TapSelect from '../../components/ui/TapSelect';

class StartProjectModal extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initialProjectStructure =  {
            name                        :   '',
            notes                       :   ''
        }

        this.initialSearch = {
            search_by_name : '',
            search_status_id : '',   
        }

        this.initialProjectStartForm = {
            site_id : '',
            notes : '',
            structure_id : '',
            name : ''
        }

        this.searchFormLabel = {
            'search_by_name' : 'Name : ',
            'search_status_id' : 'Status : ',
        };

        this.state                  =   {
            minimizeTable               :   false,
            listing_loading             :   false,
            formSubmitting              :   false,
            modalLoading                :   false,
            totalListingCount           :   0,
            listingMeta                 :   null,
            listingData                 :   [],
            configurationData           :   null,
            addProjectStructureForm     :   {...this.initialProjectStructure},
            projectDetailData           :   null,
            projectStructureId          :   '',
            status                      :   [{label : 'Active', value: 1}, {label : 'Inactive', value: 2}],
            searchFormData              :   {...this.initialSearch},
            submittedSearchFormData     :   {...this.initialSearch},
            formSearchedElems           :   [],
            startProjectForm            :   {...this.initialProjectStartForm},
            structures                  :   [],
            siteList                    :   [],
        }

        this.projectStructureUrl    =   IAM_API_BASE_URL_2 + '/project_structure';
        this.projectUrl             =   IAM_API_BASE_URL_2 + '/project';
        this.detailDataLoad         =   React.createRef();
    }

    componentDidMount() { 
        this.startProject = new Modal(document.getElementById('startProject'), {keyboard: false, backdrop :false}); 
    }



    showModal = () => {
        this.startProject.show();
        this.setState({projectStructureId : this.props?.projectStructureId});
        this.setState({modalLoading : true});
        const sites = this.props.group_sites;
        HttpAPICall.withAthorization('GET', this.projectUrl + '/form_data',
            this.props.access_token, null, null, (response) => {
                const structures = response.data.structures;
                this.setState({
                    structures : structures && structures.length > 0 ? structures.map(s => { return {label : s.name, value : s.id} }) : [],
                    startProjectForm : {...this.state.startProjectForm, structure_id : this.state.projectStructureId},
                    siteList :  sites && sites.length > 0 ? sites.map(site => { return {label : site.site_name, value : site.id} }) : []
                })
        }).then(() => this.setState({modalLoading: false}));
    }

    startProjectInit = () => {
        this.startProject.show();
        this.setState({modalLoading : true});
        const sites = this.props.group_sites;
        HttpAPICall.withAthorization('GET', this.projectUrl + '/form_data',
            this.props.access_token, null, null, (response) => {
                const structures = response.data.structures;
                this.setState({
                    structures : structures && structures.length > 0 ? structures.map(s => { return {label : s.name, value : s.id} }) : [],
                    startProjectForm : {...this.state.startProjectForm, structure_id : this.state.projectStructureId},
                    siteList :  sites && sites.length > 0 ? sites.map(site => { return {label : site.site_name, value : site.id} }) : []
                })
        }).then(() => this.setState({modalLoading: false}));

    }

    startFormSubmit = (e) => {
        e.preventDefault();
        const data = this.state.startProjectForm;
        this.setState({
            formSubmitting : true
        })
        HttpAPICall.withAthorization('POST', this.projectUrl + '/start',
            this.props.access_token, null, data, (response) => {
                this.startProject.hide();
                toast.success(response.data?.msg, {position: toast.POSITION.TOP_RIGHT})
                this.setState({
                    startProjectForm : this.initialProjectStartForm
                }, () => {
                    this.props?.projectStructureId && this.props.viewProjectDetails(this.state.projectStructureId)
                    this.props?.loadListingTblData();
                })
        }).then(() => this.setState({formSubmitting: false}));
    }

    render() {
        return (
            <div className="modal fade" id="startProject" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Start A Project</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" disabled={this.state.formSubmitting}></button>
                    </div>
                    {
                        this.state.modalLoading 
                        ? <Loader/>
                        :
                        <Ax>
                        <div className="modal-body">
                            <form id="startProjectForm" onSubmit={this.startFormSubmit}>
                                <div className="row mb-3">
                                    <div className='col-sm-4 mb-2'>
                                        <label className="col-form-label">Project Template</label>
                                    </div>
                                    <div className="col-sm-7 mb-2">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'startProjectForm', 'structure_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isDisabled={this.state.projectStructureId ? true : false}
                                            placeholder="Select Project Template"
                                            options={this.state.structures}
                                            value={this.state.structures.find(s => s.value === this.state.startProjectForm.structure_id)}
                                        />
                                    </div>
                                    <div className='col-sm-4 mb-2'>
                                        <label className="col-form-label require">Site</label>
                                    </div>
                                    <div className="col-sm-7 mb-2">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'startProjectForm', 'site_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Site"
                                            options={this.state.siteList}
                                            value={this.state.siteList.find(s => s.value === this.state.startProjectForm.site_id)}
                                            required
                                        />
                                    </div>
                                    <div className='col-sm-4 mb-2'>
                                        <label className="col-form-label require">Name</label>
                                    </div>
                                    <div className="col-sm-7 mb-2">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.startProjectForm.name}
                                            onChange={(e) => this.formInputHandler(e, 'startProjectForm')}
                                            placeholder="Enter Name"
                                            required
                                        />
                                    </div>
                                     
                                    <div className='col-sm-4 mb-2'>
                                        <label className="col-form-label">Notes</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <textarea
                                            name="notes"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.startProjectForm.notes}
                                            onChange={(e) => this.formInputHandler(e, 'startProjectForm')}
                                            placeholder="Enter Notes..."
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.formSubmitting}>Cancel</button>
                            <button type="submit" form='startProjectForm' className="btn btn-primary" disabled={this.state.formSubmitting} >Start {this.state.formSubmitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                        </div>
                        </Ax>
                    }
                </div>
            </div>
            </div>
        )
    }

}



const mapStateToProps = state => {
    return {
        access_token    :   state.auth.access_token,
        permissions     :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        group_sites     :   state.app.group_sites && state.app.group_sites.length > 0 ? state.app.group_sites : [] 
    };
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(StartProjectModal);




