import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';



class WorkstationAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initAddWorkstationForm           =        {
            name                      :        '',
            code                      :        '',
            site_id                   :        '',
            remarks                   :        '',
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addWorkstationForm         :       {...this.initAddWorkstationForm},
            iam_user_sites             :       [],
            workstationDetail           :       null,
        };

        this.workstationUrl     =   IAM_API_BASE_URL_2 + '/workstation';
    }

    componentDidMount() {
        this.initalizeFormData(this.props)
    }

    componentWillReceiveProps(nextProps){

        if (nextProps.iam_user_sites !== this.props.iam_user_sites) {
             this.initalizeFormData(nextProps)
        }
             
    }

    initalizeFormData       =   (props)        =>      {
        this.setState({
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [],
        },() => {
            if(props.location && props.location.state && props.location.state.workstationData) {
                      
                let detail       =   props.location.state.workstationData;
               
                setTimeout(() => {
                    this.setState({
                        workstationDetail          :   detail,
                        addWorkstationForm         :   {
                            ...this.initAddWorkstationForm,
                            id                     :   detail.id ? detail.id : '' , 
                            name                    :   detail.name ? detail.name : '',
                            code                    :   detail.code ? detail.code : '',
                            remarks                :    detail.remarks ? detail.remarks : '',
                            site_id                 :   detail.site ? detail.site.id : ''
                        },
                        
                    });
                 
                },500)
               
            }
        })
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/workstation_list` })

    }

    submitAddWorkstationForm        =       (e)     =>      {
        e && e.preventDefault()
        let formData = {
            ...this.state.addWorkstationForm,
         }

         if(this.state.workstationDetail){
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', this.workstationUrl + '/edit', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ addWorkstationForm: { ...this.initAddWorkstationForm } })
                this.props.history.push({ pathname: `/workstation_list`,state:{workstationId : this.state.workstationDetail.id}})
            }).then(() => this.setState({ saveFormSubmitting: false }));
         }else{
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', this.workstationUrl + '/add', this.props.access_token, {}, { ...formData }, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.setState({ addWorkstationForm: { ...this.initAddWorkstationForm } })
                this.props.history.push({ pathname: `/workstation_list`})
            }).then(() => this.setState({ saveFormSubmitting: false }));
         }
      
          
       
    }


    saveWorkstationFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddWorkstationForm} id="saveWorkstationForm">
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">WorkStation Name</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                         name="name"
                         type="text"
                        value={this.state.addWorkstationForm.name}
                         className="form-control"
                         autoComplete="off"
                         required={true}
                         onChange={(e) => this.formInputHandler(e, "addWorkstationForm")}
                         placeholder="Please enter Workstation Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">Code</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                         name="code"
                         type="text"
                        value={this.state.addWorkstationForm.code}
                         className="form-control"
                         autoComplete="off"
                         required={true}
                         onChange={(e) => this.formInputHandler(e, "addWorkstationForm")}
                         placeholder="Please enter Code"
                    />
                </div>
            </div>
            
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Linked Site</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addWorkstationForm', 'site_id')}
                        options={this.state.iam_user_sites}
                        isSearchable={true}
                        isClearable={true}
                        required={ true}
                        
                        value={this.state.iam_user_sites.find(s => this.state.addWorkstationForm.site_id == s.value)}
                        placeholder={"Please Select Sites"}
                    />
                
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Remarks</label>
                </div>
                <div className="col-sm-4 ">
                <textarea
                         name="remarks"
                        value={this.state.addWorkstationForm.remarks}
                         className="form-control"
                         autoComplete="off"
                         onChange={(e) => this.formInputHandler(e, "addWorkstationForm")}
                         placeholder="Please enter remark"
                    />
                
                </div>
            </div>
           
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Workstation Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.workstationDetail ? "Edit" : "Add"} WorkStation</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                    {
                        this.saveWorkstationFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
        iam_user_sites      :       state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(WorkstationAdd);