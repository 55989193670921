import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import "./ItemsRequests.css"

class ItemRequests extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <ApplicationLayout>
                Item Requests
            </ApplicationLayout>
        )
    }
}



export default ItemRequests;