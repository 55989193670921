import React, { Component } from 'react';

class TapInputNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      prevValue: props.value || ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({value : nextProps.value,prevValue : nextProps.value})
    }
  }

  handleChange = (e) => {
    // Ensure only numeric values are entered
    const value = e.target.value.replace(/[^\d.-]/g, '');

    // Update state and trigger parent onChange if provided
    this.setState({ value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  };

  handleKeyDown = (e) => {
    // Prevent increment/decrement on arrow keys
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  };

  handleWheel = (e) => {
    // Prevent scrolling to change value
    e.preventDefault();
  };

  render() {
    const { value } = this.state;
 
    return (
      <input
        type="text" // Use text type to prevent native number input controls
        value={value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onWheel={this.handleWheel}
        className={this.props.className ? this.props.className : "form-control text-end"}
        autoComplete="off"
        name={this.props.name || ''}
        min={this.props.min || 1}
        max={this.props.max || 1000000}
        step=".01"
        required={this.props.required || false}
        placeholder={this.props.placeholder || ''}
        disabled={this.props.disabled || false}
      />
    );
  }
}

export default TapInputNumber;
