import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import TapApiUrls from "../../../services/TapApiUrls";
import { toast } from "react-toastify";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";

class AssetQuickNavigation extends AppBaseComponent {

    screenOptions                   =   [
          {value:"logsheet",label:"Logsheet"},
          {value:"dashboard",label:"Dashboard"},
          {value:"details",label:"Details"},
          {value:"compliance",label:"Compliance"},
          {value:"document",label:"Document"},
          {value:"rental",label:"Commercial"},
          {value:"service_checksheet",label:"Service Checksheet"},
          {value:"invoice_calculation",label:"Invoice Calculation"},
          {value:"allocation",label:"Item Allocation"},
          {value:"consumption",label:"Item Consumption"},
          {value:"task",label:"Task"},
          {value:"ticket",label:"Ticket"},
    ];

    constructor(props) {
        super(props);

        this.state                      =     {
            selectedAsset                       :       null,
            selectedScreen                      :       null,
        }
    }

    componentDidMount(){
        if(localStorage.getItem('selectedItemScreenValue') !== null){
         
            this.setState({selectedScreen : {value : localStorage.getItem('selectedItemScreenValue'),label : localStorage.getItem('selectedItemScreenLabel')}})
        }
    }
   
    onScreenChange = selectedOptions => {
        this.setState({
            selectedScreen:selectedOptions
        })
    }


    onNavigationSubmit              =   (event) =>  {
        event.preventDefault()
      
        localStorage.setItem('selectedScreenValue', this.state.selectedScreen.value);
        localStorage.setItem('selectedScreenLabel', this.state.selectedScreen.label);
        let assetId   =  this.state.searchedAsset.asset.asset_id;
        let obj={'search_asset_id' : this.state.searchedAsset.asset.asset_id ,  'asset_name' : `${this.state?.searchedAsset?.asset?.name} (${this.state?.searchedAsset?.asset?.asset_code})`}
       
        if(this.props){
            if(this.state.selectedScreen.value == 'dashboard'){
                this.props.props.history.push(`/asset/dashboard/${assetId}`)
            }else if(this.state.selectedScreen.value == 'details') {
                this.props.props.history.push(`/asset/details/${assetId}`)
            }else if(this.state.selectedScreen.value == 'compliance') {
                this.props.props.history.push(`/asset/compliance/${assetId}`)
            }else if(this.state.selectedScreen.value == 'document') {
                this.props.props.history.push(`/asset/document/${assetId}`)
            }else if(this.state.selectedScreen.value == 'rental') {
                this.props.props.history.push(`/asset/rental/${assetId}`)
            }else if(this.state.selectedScreen.value == 'service_checksheet') {
                this.props.props.history.push(`/assets/service_checksheet/${assetId}`)
            }else if(this.state.selectedScreen.value == 'ticket') {
                this.props.props.history.push({ pathname: `/assets/ticket/${assetId}`})
            }else if(this.state.selectedScreen.value == 'logsheet') {
                this.props.props.history.push({ pathname: `/assets/logsheet/${assetId}`})
            }else if(this.state.selectedScreen.value == 'invoice_calculation') {
                this.props.props.history.push({ pathname: `/assets/invoice_calculation/${assetId}`})
            }else if(this.state.selectedScreen.value == 'task') {
                this.props.props.history.push({ pathname: `/assets/task/${assetId}`})
            }else if(this.state.selectedScreen.value == 'allocation') {
                this.props.props.history.push({ pathname: `/assets/allocation/${assetId}`})
            }else if(this.state.selectedScreen.value == 'consumption') {
                this.props.props.history.push({ pathname: `/assets/consumption/${assetId}`})
            }else{
                this.props.props.history.push(`/asset/dashboard/${assetId}`)
            }
        }else{
            toast.error("Something went Wrong", { position: toast.POSITION.TOP_RIGHT });
        }
       
    }

    render                      =   ()  =>  {
       
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold primary_color">QUICK NAVIGATION - ASSET</h6>
                    </div>
                </div>
                <form onSubmit={this.onNavigationSubmit}>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <InputAssetSearch
                                required={true}
                                placeholder="Search Asset"
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'searchDashboardAsset', 'search_asset_id');
                                    this.setState({
                                        searchedAsset: selectedOption ? selectedOption : null
                                    }, () => {
                                    });
                                }}
                                value={this.state.searchedAsset ? { ...this.state.searchedAsset, label: this.state.searchedAsset.display_label } : null}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-9 pr0">
                            <TapSelect
                                isSearchable={true}
                                isClearable={false}
                                changeEvent={(selectedOption) => this.onScreenChange(selectedOption)}
                                value={this.state?.selectedScreen}
                                placeholder="Please Select "
                                options={this.screenOptions}
                                required={true}
                            />
                        </div>
                        <div className="col-sm-3 text-end">
                            <button type="submit" className="btn btn-primary" style={{ height: "38px" }}>Go</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(AssetQuickNavigation);