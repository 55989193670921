import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';



class FuelReceive extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.fuelStationInitState       =        {
            transaction_date            :       moment(new Date()).format('YYYY-MM-DD'),
            station_id                   :       '',
            fuel_type                   :       '',
            fuel_qty                    :       '',
            unit_rate                   :       '',
            update_fuel_price           :       'Y',
            amount                      :       '',
            transfer_from               :        '',
            transfer_from_other         :       '',
            reference_num               :        '',
            remarks                     :        '',
            tanker_loaded_weight               :       '',
            tanker_empty_weight                :       '',
            fuel_density                :       '',
            fuel_weight                 :       '',
            fuel_density_qty            :       '',
            calculate_by_density        :       'N'
        }

        this.state                    =        {
            saveFormSubmitting          :       false,
            form_data_loading           :      false,
            allFuelStationList          :      [],
            allVendorList               :      [],
            allFuelTypeList             :      [],
            receiveFuelStationForm      :       {...this.fuelStationInitState},
            vendorNew                   :      'N',
            receiveFuelStationData      :       null,
            runtime_fuel_vendor_creation    :   'N'
        };
        this.logRecieveFuelModalId  =   uuid();
        this.stationUrl             =   IAM_API_BASE_URL_2 + '/station';

    }

    componentDidMount() {
        this.uploadFuelReceiveModal         =   new Modal(document.getElementById(this.logRecieveFuelModalId), {keyboard: false, backdrop :false});  
    }

    initalizeFormData       =       (station_id,data=null,fuel_type = null)      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.stationUrl + '/fuel_receive_form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
            this.setState({
                receiveFuelStationForm         :   {...this.fuelStationInitState},
                runtime_fuel_vendor_creation   :   respData && respData.runtime_fuel_vendor_creation ? respData.runtime_fuel_vendor_creation : 'N',
                allFuelStationList             :   respData && respData.stations &&  respData.stations.length > 0  ? respData.stations.map((s) => { return {value: s.id, label: `${s.fuelstation_name}`,fuel_types : s.fuels_prices}}) : [],
                allVendorList                  :   respData && respData.vendors && respData.vendors.length > 0 ? respData.vendors.map((s) => { return {value: s.id, label: `${s.name}`}}) : [],
             },() => {
                if(station_id && data == null){
                    let selectedFuelType  = this.state.allFuelStationList && this.state.allFuelStationList.length > 0 ? this.state.allFuelStationList.find(fs => {return(fs.value == station_id)}) : null;
                    this.setState({
                        allFuelTypeList     :       selectedFuelType && selectedFuelType.fuel_types && selectedFuelType.fuel_types.length > 0 ? selectedFuelType.fuel_types.map(s => {return({value:s.fueltype_id,label : s.fuel_type,fuel_price:s.price })}) : [],
                    },() => {
                        
                        let selected_fuel_station = this.state.allFuelStationList && this.state.allFuelStationList.length > 0 ? this.state.allFuelStationList.find(ft => ft.value == station_id) : null;
                        let selected_fuel_price  =  selected_fuel_station && selected_fuel_station.fuel_types && selected_fuel_station.fuel_types.length > 0 ? selected_fuel_station.fuel_types.find(ft => ft.fueltype_id == fuel_type) : null;
                        
                        this.setState({
                            receiveFuelStationForm      :       {
                                ...this.fuelStationInitState,
                                station_id                  :       station_id,
                                update_fuel_price           :       'Y',
                                calculate_by_density        :       'N',
                                fuel_type                   :       fuel_type ? fuel_type : '',
                                unit_rate                   :       selected_fuel_price && selected_fuel_price.price ? selected_fuel_price.price : "",
                            },
                            vendorNew           :      'N',
                            receiveFuelStationData  :   null,
                           
                        })
                    })     
                }

                if(data){
                    let selectedFuelType  = this.state.allFuelStationList && this.state.allFuelStationList.length > 0 ? this.state.allFuelStationList.find(fs => {return(fs.value == data.fuel_station_id)}) : null;
                    let fuel_density_qty = 0;
                    if (data.tanker_loaded_weight && data.tanker_loaded_weight > 0 && data.tanker_empty_weight && data.tanker_empty_weight > 0 && data.fuel_density && data.fuel_density > 0) {
                        fuel_density_qty = (1000 / Number(data.fuel_density)) * (Number(data.tanker_loaded_weight) - Number(data.tanker_empty_weight));
                    }
                     this.setState({
                        newReceiver           :      'N',
                        allFuelTypeList     :       selectedFuelType && selectedFuelType.fuel_types && selectedFuelType.fuel_types.length > 0 ? selectedFuelType.fuel_types.map(s => {return({value:s.fueltype_id,label : s.fuel_type,fuel_price:s.price })}) : [],
                    },() => {
                        
                        this.setState({
                            receiveFuelStationForm       :       {
                                transaction_date            :       data.transaction_date_Ymd,
                                station_id                  :       data.fuel_station_id,
                                fuel_type                   :       data.fuel_type_id,
                                fuel_qty                    :       data.fuel_qty,
                                unit_rate                   :       data.rate,
                                update_fuel_price          :        'Y',
                                amount                      :       data.amount,
                                transfer_from               :        data.transfer_from,
                                transfer_from_other         :       '',
                                reference_num               :        data.reference_num ? data.reference_num : '',
                                remarks                     :        data.remarks,
                                tanker_loaded_weight        :       data.calculate_by_density == 'Y' && data.tanker_loaded_weight ? data.tanker_loaded_weight : '',
                                tanker_empty_weight         :       data.calculate_by_density == 'Y' && data.tanker_empty_weight ? data.tanker_empty_weight : '',
                                fuel_density                :       data.calculate_by_density == 'Y' && data.fuel_density ? data.fuel_density : '',
                                fuel_weight                 :      data.calculate_by_density == 'Y' && data.fuel_weight ? data.fuel_weight : '',
                                calculate_by_density        :       data.calculate_by_density ? data.calculate_by_density : 'N',
                                ledger_id                   :       data.id,
                                fuel_density_qty            :      fuel_density_qty > 0 ? parseFloat(fuel_density_qty).toFixed(2) : 0
                            },
                            receiveFuelStationData            :       data
                        })
                    })     
                    
                }
             })
        }).then(() => this.setState({form_data_loading: false}));
    }

    fuelReceiveModalInit         =   (station_id,fuel_type = null)  =>  {
        this.uploadFuelReceiveModal.show()
        this.initalizeFormData(station_id,null,fuel_type)
       
    }

    editReceivedFuelModalInit         =   (station_id,data = null)  =>  {
        this.uploadFuelReceiveModal.show()
        this.initalizeFormData(station_id,data)
    }

    getSelectedStationFuelType      =   (selectedOption)        =>      {
       
        if(selectedOption && selectedOption.length > 0){
            this.setState({
                allFuelTypeList : selectedOption && selectedOption.length > 0 ? selectedOption.map(s => {return({value:s.fueltype_id,label : s.fuel_type,fuel_price : s.price })}) : [],
                receiveFuelStationForm      :       {...this.state.receiveFuelStationForm,unit_rate : '',fuel_type:''}
            });
        }else{
            this.setState({allFuelTypeList : []})
        }
    }

    submitAddFuelStationForm    =   (e)     =>      {
        e.preventDefault();

         if(this.state.receiveFuelStationData){
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('PUT', this.stationUrl + '/fuel_receive' , this.props.access_token, {}, {...this.state.receiveFuelStationForm,amount: parseFloat(this.state.receiveFuelStationForm.amount).toFixed(2)}, (resp) => {
                this.afterSubmitReceiveFuelHandler(resp);
           }).then(() => this.setState({ saveFormSubmitting: false }));
         }else{
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('POST', this.stationUrl + '/fuel_receive' , this.props.access_token, {}, {...this.state.receiveFuelStationForm,amount: parseFloat(this.state.receiveFuelStationForm.amount).toFixed(2)}, (resp) => {
                this.afterSubmitReceiveFuelHandler(resp);
           }).then(() => this.setState({ saveFormSubmitting: false }));
         }  
       
    }

    afterSubmitReceiveFuelHandler  =   (response)  =>  {
        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
        if(this.props.afterSubmitReceiveFuel){
            this.props.afterSubmitReceiveFuel();
            this.setState({
                receiveFuelStationForm : {...this.fuelStationInitState},
                receiveFuelStationData      :       null
            })
        }
        this.uploadFuelReceiveModal.hide();
    }

    saveFuelStationFormJsx         =        ()       =>      {
        let fuel_variance = 0 ;
        if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.fuel_qty && this.state.receiveFuelStationForm.fuel_qty > 0 && this.state.receiveFuelStationForm.fuel_density_qty && this.state.receiveFuelStationForm.fuel_density_qty > 0) {
            let baseqty = this.state.receiveFuelStationForm.fuel_qty
            fuel_variance = ((baseqty - this.state.receiveFuelStationForm.fuel_density_qty) / ((baseqty))) * 100;
        }

        return (<form className="bg-white" onSubmit={this.submitAddFuelStationForm} id="saveFuelStationForm">
            {this.state.form_data_loading
                ? <Loader />
                : <Ax>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Date of Fuel Receive</label>

                        <div className="col-sm-7  add_calender_section">
                            <DatePicker
                                selected={
                                    this.state.receiveFuelStationForm.transaction_date
                                        ? moment(this.state.receiveFuelStationForm.transaction_date, 'YYYY-MM-DD').toDate()
                                        : false
                                }
                                name="transaction_date"
                                onChange={(value, event) => this.formDateHandler('transaction_date', value, 'receiveFuelStationForm')}
                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                dateFormat="dd-MMM-yyyy"
                                className={"form-control form-control-sm"}
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                required={true}
                                placeholderText={`Please Enter Date of  Fuel Receive`}

                            />

                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                        </div>

                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Fuel Station</label>
                        <div className="col-sm-7 ">
                            <TapSelect
                                options={this.state.allFuelStationList}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'receiveFuelStationForm', 'station_id', () => this.getSelectedStationFuelType(selectedOption?.fuel_types));
                                }}
                                isSearchable={true}
                                value={this.state.allFuelStationList.find(s => this.state.receiveFuelStationForm.station_id == s.value)}
                                isClearable={true}
                                placeholder="Select Fuel Station"
                                autoCompelete="off"
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Fuel Type</label>

                        <div className="col-sm-7 ">
                            <TapSelect
                                options={this.state.allFuelTypeList}
                                changeEvent={(selectedOption) => {
                                     this.tapSelectChange(selectedOption, 'receiveFuelStationForm', 'fuel_type',() => {
                                        this.setState({
                                            receiveFuelStationForm      :   {
                                                ...this.state.receiveFuelStationForm,
                                                unit_rate       :       selectedOption && selectedOption.fuel_price ? selectedOption.fuel_price : ''
                                            } 
                                        })
                                     });
                                  
                                }}
                                isSearchable={true}
                                value={this.state.allFuelTypeList.find(s => this.state.receiveFuelStationForm.fuel_type == s.value)}
                                isClearable={true}
                                placeholder="Select Fuel Type"
                                autoCompelete="off"
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>


                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Quantity</label>
                        <div className="col-sm-3 ">
                            <input
                                name="fuel_qty"
                                value={this.state.receiveFuelStationForm.fuel_qty}
                                onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                className="form-control form-control-sm"
                                type="number"
                                step=".01"
                                autoComplete="off"
                                placeholder="Enter Fuel Quantity"
                                required={true}
                                onKeyUp={(e) => {
                                    if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.fuel_qty > 0 && this.state.receiveFuelStationForm.unit_rate && this.state.receiveFuelStationForm.unit_rate > 0) {
                                        let amount = this.state.receiveFuelStationForm.unit_rate * this.state.receiveFuelStationForm.fuel_qty;
                                        this.setState({ receiveFuelStationForm: { ...this.state.receiveFuelStationForm, amount: parseFloat(amount).toFixed(2) } })
                                    }
                                }}
                            />
                        </div>
                        <label className="col-sm-1 col-form-label col-form-label-sm require">Unit Rate</label>
                        <div className="col-sm-3 ">
                            <input
                                name="unit_rate"
                                value={this.state.receiveFuelStationForm.unit_rate}
                                onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                className="form-control form-control-sm"
                                type="number"
                                autoComplete="off"
                                step=".01"
                                placeholder="Enter Fuel Rate"
                                required={true}
                                onKeyUp={(e) => {
                                    if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.fuel_qty > 0 && this.state.receiveFuelStationForm.unit_rate && this.state.receiveFuelStationForm.unit_rate > 0) {
                                        let amount = this.state.receiveFuelStationForm.unit_rate * this.state.receiveFuelStationForm.fuel_qty;
                                        this.setState({ receiveFuelStationForm: { ...this.state.receiveFuelStationForm, amount: parseFloat(amount).toFixed(2) } })
                                    }
                                }}
                            />
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="update_fuel_price"
                                type="checkbox"
                                value={this.state.receiveFuelStationForm.update_fuel_price}
                                onChange={(e) => {
                                    this.setState({
                                        receiveFuelStationForm: this.state.receiveFuelStationForm.update_fuel_price == 'N' ? { ...this.state.receiveFuelStationForm, update_fuel_price: 'Y' } : { ...this.state.receiveFuelStationForm, update_fuel_price: 'N' }
                                    })
                                }}
                                checked={this.state.receiveFuelStationForm.update_fuel_price == 'Y'}
                                className="form-check-input"
                                id="update_fuel_price"
                                step=".01"
                            />
                            <label className="form-check-label text-sm mx-2" htmlFor="update_fuel_price">Update Fuel Price </label>
                        </div>

                    </div>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Amount</label>

                        <div className="col-sm-7 ">
                            <input
                                name="amount"
                                value={this.state.receiveFuelStationForm.amount}
                                onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                className="form-control form-control-sm"
                                type="number"
                                required={true}
                                autoComplete="off"
                                step=".01"
                                placeholder="Enter Fuel Amount"
                                onKeyUp={(e) => {
                                    if (this.state.receiveFuelStationForm.amount && this.state.receiveFuelStationForm.amount > 0 && this.state.receiveFuelStationForm.unit_rate && this.state.receiveFuelStationForm.unit_rate > 0) {
                                        let quantity = this.state.receiveFuelStationForm.amount / this.state.receiveFuelStationForm.unit_rate;
                                        this.setState({ receiveFuelStationForm: { ...this.state.receiveFuelStationForm, fuel_qty: parseFloat(quantity).toFixed(2) } })
                                    }
                                }}
                            />

                        </div>



                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Received from (Fuel Vendor)</label>

                        <div className="col-sm-7 ">
                            {this.state.vendorNew == "Y"
                                ? <input
                                    name="transfer_from_other"
                                    type="text"
                                    value={this.state.receiveFuelStationForm.transfer_from_other}
                                    className="form-control form-control-sm"
                                    autoComplete="off"
                                    onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm")}
                                    placeholder="Please enter Received Vendor"
                                    required={true}
                                />
                                :
                                <TapSelect
                                    options={this.state.allVendorList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'receiveFuelStationForm', 'transfer_from');

                                    }}
                                    isSearchable={true}
                                    value={this.state.allVendorList.find(s => this.state.receiveFuelStationForm.transfer_from == s.value)}
                                    isClearable={true}
                                    placeholder="Select Received from "
                                    autoCompelete="off"
                                    containerHeight="30px"
                                    fontSize="93%"
                                    required={true}
                                />}

                        </div>
                     {this.state.runtime_fuel_vendor_creation == 'Y' &&   <div className="col-sm-2">
                            <input
                                name="vendorNew"
                                type="checkbox"
                                value={this.state.vendorNew}
                                onChange={(e) => {
                                    this.setState({
                                        vendorNew: this.state.vendorNew == 'N' ? 'Y' : 'N',
                                        receiveFuelStationForm: this.state.vendorNew == 'N' ? { ...this.state.receiveFuelStationForm, transfer_from_other: '' } : { ...this.state.receiveFuelStationForm }
                                    })
                                }}
                                checked={this.state.vendorNew == 'Y'}
                                className="form-check-input"
                                id="vendorNew"
                            />
                            <label className="form-check-label text-sm mx-2" htmlFor="vendorNew">Add New </label>
                        </div>}
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Reference Number</label>

                        <div className="col-sm-7 ">
                            <input
                                name="reference_num"
                                type="text"
                                value={this.state.receiveFuelStationForm.reference_num}
                                className="form-control form-control-sm"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm")}
                                placeholder="Please enter Reference Number"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Remarks</label>

                        <div className="col-sm-7 ">
                            <textarea
                                name="remarks"
                                placeholder='Enter remarks'
                                className='form-control'
                                value={this.state.receiveFuelStationForm.remarks}
                                onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm")}
                                style={{ height: "65px", width: "100%" }}
                            />
                        </div>
                    </div>


                    {this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.fuel_type == 3
                        ? null : <div className="tab_content_wrapper d-flex justify-content-start align-items-center mb-2">

                            <span className="form-check-label fw-bold " htmlFor="calculate_by_density">Calculate Fuel Quantity based on Density </span>
                            <div className="form-check mx-3 mt-2">

                                <input
                                    name="calculate_by_density"
                                    type="checkbox"
                                    value={this.state.receiveFuelStationForm.calculate_by_density}
                                    onChange={(e) => {
                                        this.setState({
                                            receiveFuelStationForm: this.state.receiveFuelStationForm.calculate_by_density == 'N' ? { ...this.state.receiveFuelStationForm, calculate_by_density: 'Y' } : { ...this.state.receiveFuelStationForm, calculate_by_density: 'N' }
                                        })
                                    }}
                                    checked={this.state.receiveFuelStationForm.calculate_by_density == 'Y'}
                                    className="form-check-input"
                                    id="calculate_by_density"

                                />

                            </div>

                        </div>}
                    {this.state.receiveFuelStationForm.calculate_by_density == "N"
                        ? null
                        : <Ax>

                            <div className="row align-items-center my-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Tanker Loaded weight</label>

                                <div className="col-sm-7 ">
                                    <input
                                        name="tanker_loaded_weight"
                                        value={this.state.receiveFuelStationForm.tanker_loaded_weight}
                                        onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                        className="form-control form-control-sm"
                                        type="number"
                                        autoComplete="off"
                                        placeholder="Enter Tanker Loaded weight"
                                        onKeyUp={(e) => {
                                            let fuel_weight = 0, fuel_density_qty = 0;
                                            if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.tanker_loaded_weight > 0 && this.state.receiveFuelStationForm.tanker_empty_weight && this.state.receiveFuelStationForm.tanker_empty_weight > 0) {
                                                fuel_weight = Number(this.state.receiveFuelStationForm.tanker_loaded_weight) - Number(this.state.receiveFuelStationForm.tanker_empty_weight);

                                            }
                                            if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.tanker_loaded_weight > 0 && this.state.receiveFuelStationForm.tanker_empty_weight && this.state.receiveFuelStationForm.tanker_empty_weight > 0 && this.state.receiveFuelStationForm.fuel_density && this.state.receiveFuelStationForm.fuel_density > 0) {
                                                fuel_density_qty = (1000 / Number(this.state.receiveFuelStationForm.fuel_density)) * (Number(this.state.receiveFuelStationForm.tanker_loaded_weight) - Number(this.state.receiveFuelStationForm.tanker_empty_weight));
                                            }
                                            this.setState({
                                                receiveFuelStationForm: {
                                                    ...this.state.receiveFuelStationForm,
                                                    fuel_density_qty: parseFloat(fuel_density_qty).toFixed(3),
                                                    fuel_weight: parseFloat(fuel_weight).toFixed(3)
                                                }
                                            })
                                        }}
                                    />

                                </div>

                            </div>
                            <div className="row align-items-center my-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Tanker Empty weight</label>
                                <div className="col-sm-7 ">
                                    <input
                                        name="tanker_empty_weight"
                                        value={this.state.receiveFuelStationForm.tanker_empty_weight}
                                        onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                        className="form-control form-control-sm"
                                        type="number"
                                        autoComplete="off"
                                        placeholder="Enter Tanker Empty weight"
                                        onKeyUp={(e) => {
                                            let fuel_weight = 0, fuel_density_qty = 0;
                                            if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.tanker_loaded_weight > 0 && this.state.receiveFuelStationForm.tanker_empty_weight && this.state.receiveFuelStationForm.tanker_empty_weight > 0) {
                                                fuel_weight = Number(this.state.receiveFuelStationForm.tanker_loaded_weight) - Number(this.state.receiveFuelStationForm.tanker_empty_weight);

                                            }
                                            if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.tanker_loaded_weight > 0 && this.state.receiveFuelStationForm.tanker_empty_weight && this.state.receiveFuelStationForm.tanker_empty_weight > 0 && this.state.receiveFuelStationForm.fuel_density && this.state.receiveFuelStationForm.fuel_density > 0) {
                                                fuel_density_qty = (1000 / Number(this.state.receiveFuelStationForm.fuel_density)) * (Number(this.state.receiveFuelStationForm.tanker_loaded_weight) - Number(this.state.receiveFuelStationForm.tanker_empty_weight));
                                            }
                                            this.setState({
                                                receiveFuelStationForm: {
                                                    ...this.state.receiveFuelStationForm,
                                                    fuel_density_qty: parseFloat(fuel_density_qty).toFixed(3),
                                                    fuel_weight: parseFloat(fuel_weight).toFixed(3)
                                                }
                                            })
                                        }}
                                        min={0}
                                        max={Number(this.state.receiveFuelStationForm.tanker_loaded_weight)}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Fuel Weight</label>

                                <div className="col-sm-3 ">
                                    <input
                                        name="fuel_weight"
                                        value={this.state.receiveFuelStationForm.fuel_weight}
                                        onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                        className="form-control form-control-sm"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Enter Fuel Weight"
                                        disabled="true"

                                    />
                                </div>
                                <label className="col-sm-1 col-form-label col-form-label-sm">Fuel Density</label>
                                <div className="col-sm-3 ">
                                    <input
                                        name="fuel_density"
                                        value={this.state.receiveFuelStationForm.fuel_density}
                                        onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                        className="form-control form-control-sm"
                                        type="number"
                                        min="0"
                                        autoComplete="off"
                                        placeholder="Enter Fuel Density"
                                        onKeyUp={(e) => {
                                            if (this.state.receiveFuelStationForm && this.state.receiveFuelStationForm.tanker_loaded_weight > 0 && this.state.receiveFuelStationForm.tanker_empty_weight && this.state.receiveFuelStationForm.tanker_empty_weight > 0 && this.state.receiveFuelStationForm.fuel_density && this.state.receiveFuelStationForm.fuel_density > 0) {
                                                let fuel_density_qty = (1000 / Number(this.state.receiveFuelStationForm.fuel_density)) * (Number(this.state.receiveFuelStationForm.tanker_loaded_weight) - Number(this.state.receiveFuelStationForm.tanker_empty_weight));
                                                this.setState({ receiveFuelStationForm: { ...this.state.receiveFuelStationForm, fuel_density_qty: parseFloat(fuel_density_qty).toFixed(3) } })
                                            }
                                        }}
                                    />
                                </div>
                                <div className=' col-sm-2 form-text text-sm'>
                                    <ul className='p0'>
                                    <li>Petrol : 710-770 kg/m3</li>
                                    <li>Diesel : 800-860 kg/m3</li>
                                    </ul>
                                </div>

                            </div>

                            <div className="row align-items-center my-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm ">Fuel Qty according to Density & Weight</label>

                                <div className="col-sm-7 ">
                                    <input
                                        name="fuel_density_qty"
                                        value={this.state.receiveFuelStationForm.fuel_density_qty}
                                        onChange={(e) => this.formInputHandler(e, "receiveFuelStationForm",null,true)}
                                        className="form-control form-control-sm"
                                        type="text"
                                        autoComplete="off"
                                        disabled="true"
                                        placeholder="Enter Fuel Qty according to Density & Weight"
                                    />

                                </div>
                              <div className=' col-sm-2 form-text text-sm'>
                                   Fuel Variance : <span className={ fuel_variance > 0 ? 'text-success' : 'text-danger'}>{parseFloat(fuel_variance).toFixed(2)} %</span> 
                                   </div>
                                

                            </div>
                        </Ax>}

                    <div className="modal-footer">
                        <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary mx-3" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>

                </Ax>
            }
        </form>);
    }


    render                    =        ()        =>     {
        return (<div className="modal fade" id={this.logRecieveFuelModalId} tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>{this.state.receiveFuelStationData ? "Edit" : "Add" } Fuel Receive</span>
                                
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div className="modal-body">
                        {this.saveFuelStationFormJsx()}

                    </div>
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(FuelReceive);