import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";
import DowntimeView from '../../../transactions/downtime/DowntimeView';
import AdditionalAttributeForm from '../../../includes/ui/AdditionalAttributeForm';


class Downtime extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.formDataUrl            =   TapApiUrls.IAM + '/downtime/form_data';

        this.downtimeFormInit       =   {
           status                   :   '',
           total_hour               :   '',
           downtime_seconds         :   '',
           sub_section              :   '',
           section                  :   '',
           remarks                  :   '',
           full_shift               :   'N',
           defect_codes              :   [],
           start_time               :   '',
           end_time                 :   '',
        }

        this.state                  =   {
            access_token             :      '',
            formDataLoading          :      false,
            allSections            :       [],
            allSubSections         :       [],
            downtimeDetail          :      null,
            downtimeShiftDetail     :      null,
            alldowntimeTypes        :      [],
            addDowntimeForm         :       {...this.downtimeFormInit},
            saveFormSubmitting      :       false,
            prevDowntimeData        :       null,
            copy_reading             :       'N',
            setting                 :       null,
            allDefectCodes          :       [],
            allAdditonalAttribute   :       [],
            editDowntime            :       false,
            downtime_transaction_id  :      '',
            viewDataLoading          :      false,
            downtimeViewData          :     null,
        }
        this.logDowntimeModalId      =   uuid();
        this.viewDowntimeModalId    =   uuid();
    }

    componentDidMount               =   ()  =>  {
        this.initScreen(this.props);
        this.viewDowntimeModal              =   new Modal(document.getElementById(this.viewDowntimeModalId), {keyboard: false, backdrop: false}); 
        this.uploadDowntimeModal            =   new Modal(document.getElementById(this.logDowntimeModalId), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    uploadDowntimeModalInit               =   (shiftDetail, counterlog, assetData = null,downtime_id = '')  =>  {
        this.uploadDowntimeModal.show()
        this.setState({addDowntimeForm : {...this.downtimeFormInit},copy_reading:'N'},() => {
          
                this.loadDowntimeDetailFromApi(counterlog && counterlog.transaction_id,downtime_id);
            
            this.setState({
                assetData           :   assetData,
                shift               :   shiftDetail,
                counterlog          :   counterlog,
                date                :   counterlog ? counterlog.feed_date : '',
                transaction_id      :   counterlog ? counterlog.transaction_id : '',
                editDowntime        :   downtime_id ? true : false,
                downtime_transaction_id :   downtime_id ? downtime_id : ''
            },() => {
                
            });
            if(document.getElementById(`uploadDowntimeForm`)) {
                document.getElementById(`uploadDowntimeForm`).reset();
            }
        })
        
    }

    loadDowntimeDetailFromApi                 =   (id,downtime_id='')  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', this.formDataUrl , this.state.access_token , {counterlog_transaction_id : id,include_prev_downtime:'Y'}, {}, (response) => {
        
            this.setState({
              downtimeDetail        :   JSON.stringify(response.data.downtime) !== JSON.stringify({}) ? response.data.downtime : null,
              allAdditonalAttribute :   response.data && response.data.additional_attributes && response.data.additional_attributes.length > 0 ? response.data.additional_attributes : [],
              downtimeShiftDetail   :   response.data && response.data.shift ? response.data.shift : null,
              setting               :   response.data && response.data.setting ? response.data.setting : null,
              allDefectCodes        :   response.data && response.data.defect_codes &&  response.data.defect_codes.length > 0 ? response.data.defect_codes.map((dt,k) => {return({value:dt.id,label:dt.name})}) : [],
              alldowntimeTypes      :   response.data && response.data.downtime_types &&  response.data.downtime_types.length > 0 ? response.data.downtime_types.map((dt,k) => {return({value:dt.id,label:dt.name})}) : [],
              allSections           :   response.data && response.data.sections && response.data.sections.length > 0 ? response.data.sections.map(section => {return({value : section.id,label : section.component,subcomponents : section.subsections})}) : [],
            prevDowntimeData        :   response.data.prev_downtime && JSON.stringify(response.data.prev_downtime) !== JSON.stringify({}) ? response.data.prev_downtime : null,
            }, () => {
                if(downtime_id){
                    this.getDowntimeDetail(downtime_id)
                }
        
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
   }

   getDowntimeDetail                 =   (downtime_id='')  =>  {
    let total_hours = "00:00";
    this.setState({viewDataLoading: true})
    HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/downtime/detail' , this.state.access_token , {transaction_id : downtime_id,need_section:'Y',need_defect_codes:'Y'}, {}, (response) => {
        let respData = response.data;
        let updateFormState = [];
        if (respData.data && respData.data.additional_attributes_data && respData.data.additional_attributes_data.length > 0) {
            respData.data.additional_attributes_data.map((i,k) => {
                let name = 'additional_attributes_' + i.key;
                updateFormState[name] = i.value
            });
        }
        if(respData && respData.data && respData.data.downtime_seconds){
            let seconds = respData.data.downtime_seconds;
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            total_hours = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        }
        let subSection = respData.data && respData.data.section_data ? this.state.allSections.find(s => respData.data.section_data.id == s.value) : null;
        this.setState({
            downtimeViewData : respData.data,
            
            allSubSections: subSection && subSection.subcomponents && subSection.subcomponents.length > 0 ? subSection.subcomponents.map((s, i) => { return ({ value: s.id, label: s.subcomponent }) }) : [],
            addDowntimeForm     : {
                ...this.downtimeFormInit,
                ...updateFormState,
                full_shift    :   this.state.downtimeShiftDetail && this.state.downtimeShiftDetail.seconds && respData.data && respData.data.downtime_seconds && this.state.downtimeShiftDetail.seconds == respData.data.downtime_seconds ? 'Y' : 'N',
                downtime_seconds : respData.data && respData.data.downtime_seconds ? respData.data.downtime_seconds : 0 ,
                total_hour      :  moment(respData.data.feed_datetime).startOf('day').seconds(respData.data.downtime_seconds).toDate(),
                transaction_id  : respData.data && respData.data.transaction_id ? respData.data.transaction_id : '',
                downtime_type   :   respData.data && respData.data.downtime_type_id ? respData.data.downtime_type_id : '',
                start_time      :   respData.data && respData.data.start_time ? moment(respData.data.start_time,"hh:mm a").toDate() : '',
                end_time      :   respData.data && respData.data.start_time ? moment(respData.data.end_time,"hh:mm a").toDate() : '',
                remarks         :  respData.data && respData.data.remarks ? respData.data.remarks : '',
                section      :   respData.data && respData.data.section_data ? respData.data.section_data.id: '',
                sub_section   :  respData.data && respData.data.sub_section_data ? respData.data.sub_section_data.id: '',
                defect_codes    :   respData.data && respData.data.defect_code_data && respData.data.defect_code_data.length > 0 ? respData.data.defect_code_data.map(dc => dc.id) : [],
            }
        },() => {
           
        })
        
    }, (error) => {
        toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
    }).then(() => this.setState({viewDataLoading: false}))
}
    
    initScreen                     =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    submitDowntimeHandler       =       (e)      =>      {
        e.preventDefault()
         this.setState({saveFormSubmitting : true});
        let additional_attributes = {};
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
            this.state.allAdditonalAttribute.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addDowntimeForm[name] ? this.state.addDowntimeForm[name] : '';
            })
        } 
        
        let downtime_seconds =  this.state.addDowntimeForm.total_hour  ? moment(this.state.addDowntimeForm.total_hour,'HH:mm').format('HH:mm') : "00:00";
        var timeParts = downtime_seconds.split(":");
        var hours = parseInt(timeParts[0], 10);
        var minutes = parseInt(timeParts[1], 10);

        // Convert hours and minutes to seconds
        var totalSeconds = (hours * 3600) + (minutes * 60);
        let formData = {
            ...this.state.addDowntimeForm,
            additional_attributes : additional_attributes,
            full_shift : this.state.addDowntimeForm.full_shift ? this.state.addDowntimeForm.full_shift : "N",
            counterlog_transaction_id : this.state.counterlog && this.state.counterlog.transaction_id ? this.state.counterlog.transaction_id : '',
            //downtime_seconds      : this.state.downtimeShiftDetail && this.state.downtimeShiftDetail.shift_time == "24:00" ? this.state.downtimeShiftDetail.seconds : totalSeconds,
            start_time             : this.state.addDowntimeForm.start_time  ? moment(this.state.addDowntimeForm.start_time,'HH:mm:ss').format('HH:mm:ss') : "",
            end_time             : this.state.addDowntimeForm.end_time  ? moment(this.state.addDowntimeForm.end_time,'HH:mm:ss').format('HH:mm:ss') : "",
        }
        
        if(this.state.editDowntime){
            HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/downtime/edit' , this.props.access_token, null, {...formData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.uploadDowntimeModal.hide();
                if(this.props.afterSaveDowntime) {
                    let asset_id            =   this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveDowntime(this.state.date, asset_id);
                }
                this.setState({
                    addDowntimeForm         :   {...this.addDowntimeFormInit},
                });
            }).then(() => this.setState({saveFormSubmitting: false}))
        }else{
            HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/downtime/add' , this.props.access_token, null, {...formData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.uploadDowntimeModal.hide();
                if(this.props.afterSaveDowntime) {
                    let asset_id            =   this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveDowntime(this.state.date, asset_id);
                }
                this.setState({
                    addDowntimeForm         :   {...this.addDowntimeFormInit},
                });
            }).then(() => this.setState({saveFormSubmitting: false}))
        }
       
        //}).then(() => this.setState({saveFormSubmitting: false}))
    }

    deleteDowntimeInit        =   (transaction_id,id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', TapApiUrls.IAM + '/downtime/delete' , this.props.access_token, {counterlog_transaction_id : transaction_id,transaction_id : id},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteDowntime) {
                        this.props.afterDeleteDowntime()
                    }
                }).then(() => { });
            }
        });
    }

     copyPreviousHandler                 =   ()  =>  {
        if(this.state.copy_reading == "Y" && this.state.prevDowntimeData) {
           
            let previousData         =          this.state.prevDowntimeData;
            let subSection          =           previousData && previousData.section_data  ? this.state.allSections.find(s => previousData.section_data.id == s.value) : null;
            let updateFormState = [];
            if (previousData && previousData.additional_attributes_data && previousData.additional_attributes_data.length > 0) {
                previousData.additional_attributes_data.map((i,k) => {
                    let name = 'additional_attributes_' + i.key;
                    updateFormState[name] = i.value
                });
            }
            this.setState({
                allSubSections: subSection && subSection.subcomponents && subSection.subcomponents.length > 0 ? subSection.subcomponents.map((s, i) => { return ({ value: s.id, label: s.subcomponent }) }) : [],
                addDowntimeForm : {
                ...this.state.addDowntimeForm,
                full_shift    :   this.state.downtimeShiftDetail && this.state.downtimeShiftDetail.seconds && previousData && previousData.downtime_seconds && this.state.downtimeShiftDetail.seconds == previousData.downtime_seconds ? 'Y' : 'N',
                downtime_seconds : previousData && previousData.downtime_seconds ? previousData.downtime_seconds : 0 ,
                total_hour      :  moment(previousData.feed_datetime).startOf('day').seconds(previousData.downtime_seconds).toDate(),
                downtime_type   :   previousData && previousData.downtime_type_id ? previousData.downtime_type_id : '',
                start_time      :   previousData && previousData.start_time ? moment(previousData.start_time,"hh:mm a").toDate() : '',
                end_time      :   previousData && previousData.start_time ? moment(previousData.end_time,"hh:mm a").toDate() : '',
                remarks         :  previousData && previousData.remarks ? previousData.remarks : '',
                section      :   previousData && previousData.section_data ? previousData.section_data.id: '',
                sub_section   :  previousData && previousData.sub_section_data ? previousData.sub_section_data.id: '',
                defect_codes    :   previousData && previousData.defect_code_data && previousData.defect_code_data.length > 0 ? previousData.defect_code_data.map(dc => dc.id) : [],
               ...updateFormState
            }});   
        }else{
            this.setState({
                addDowntimeForm          :           { ...this.addDowntimeFormInit},
                allSubSections          :               []
            })
        }
    }

    viewDowntimeDetailModalInit         =       (shiftDetail, counterlog, assetData = null,downtime_id='')      =>      {
        this.viewDowntimeModal.show()
        this.setState({downtimeViewData : null , viewDataLoading : true}
            , () => {
                this.getDowntimeDetail(downtime_id)
                this.setState({
                    assetData               :   assetData,
                    shift                   :   shiftDetail,
                    counterlog              :   counterlog,
                    date                    :   counterlog ? counterlog.feed_date : '',
                    transaction_id          :   counterlog ? counterlog.transaction_id : '',
                    downtimeViewData        :   null,
                });
         })
    }

    getTimeDiffernce = () => {
        if (this.state.addDowntimeForm.start_time && this.state.addDowntimeForm.end_time) {
            const startTime = moment(this.state.addDowntimeForm.start_time);
            const endTime = moment(this.state.addDowntimeForm.end_time);
            
            // Check if shift detail indicates that the shift spans across two days
            const shiftSpansTwoDays = this.state.downtimeShiftDetail && this.state.downtimeShiftDetail.same_day === 'N';
    
            // If the shift spans two days and the end time is before the start time, adjust the end time to the next day
            if (shiftSpansTwoDays && endTime.isBefore(startTime)) {
                endTime.add(1, 'day');
            }
    
            // If the shift spans two days and the start time is after 00:00, adjust the start time to the previous day
            if (shiftSpansTwoDays && startTime.hour() === 0 && startTime.minute() === 0) {
                startTime.subtract(1, 'day');
            }
    
            // Calculate the difference in seconds
            const differenceInSeconds = endTime.diff(startTime, 'seconds');
    
            // Update the state with the time difference
            this.setState({
                addDowntimeForm: {
                    ...this.state.addDowntimeForm,
                    downtime_seconds: differenceInSeconds
                }
            });
        }
    }

    uploadDowntimeModalJsx                  =   ()  =>  {
        let setting         =   this.state.setting ? this.state.setting : null;
        let shift_time      =   this.state.downtimeShiftDetail ? this.state.downtimeShiftDetail.shift_time : '00:00';
        let shift_seconds   =   this.state.downtimeShiftDetail ? this.state.downtimeShiftDetail.seconds : 0;
       

        // Initialize min_time and max_time for both start_time and end_time
        let max_time_start = moment(new Date(), "hh:mm a").toDate();
        let min_time_start = moment("00:05", "HH:mm").startOf('day').toDate();
        let max_time_end = moment(new Date(), "hh:mm a").toDate();
        let min_time_end = moment("00:05", "HH:mm").startOf('day').toDate();

        if (this.state.downtimeShiftDetail) {
            
            if (this.state.downtimeShiftDetail.shift_start === this.state.downtimeShiftDetail.shift_end) {
                // For 24-hour shift, remove min and max time filters
                min_time_start = null;
                max_time_start = null;
                min_time_end = null;
                max_time_end = null;
            } else {
                // For non-24-hour shift, adjust min_time and max_time for both start_time and end_time
                min_time_start = moment(this.state.downtimeShiftDetail.shift_start, "hh:mm a").toDate();
                max_time_start = moment(this.state.downtimeShiftDetail.shift_end, "hh:mm a").toDate();
                min_time_end = min_time_start;
                max_time_end = max_time_start;
            }
        }

        return (
            <div className="modal fade" id={this.logDowntimeModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Log Downtime for Downtime : {this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                    {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}</h5>

                                    <button type="button" className="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className='p-1 bg-light border-bottom'>
                                <table className='table table-sm mb-0 table-borderless text-sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                            <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-body">
                                {this.state.formDataLoading
                                    ? <Loader />
                                    :
                                    <form id={`uploadDowntimeForm`} onSubmit={this.submitDowntimeHandler}>
                                        <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Type</label>
                                            <div className="col-sm-7">
                                                <TapSelect
                                                    options={this.state.alldowntimeTypes}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addDowntimeForm', 'downtime_type');
                                                    }}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.alldowntimeTypes.find(s => this.state.addDowntimeForm.downtime_type == s.value)}
                                                    placeholder="Please enter Downtime Type"
                                                    containerHeight="30px"
                                                    fontSize="93%"

                                                />
                                            </div>
                                        </div>
                                        <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Duration</label>
                                            <div className="col-sm-7">
                                                {this.state.addDowntimeForm.full_shift && this.state.addDowntimeForm.full_shift == 'Y'
                                                    ? <input
                                                        // name="total_hour"
                                                        className="form-control form-control-sm"
                                                        placeholder="Duration"
                                                        disabled={true}
                                                        autoComplete="off"
                                                        value={this.state.downtimeShiftDetail && this.state.downtimeShiftDetail.shift_time ? this.state.downtimeShiftDetail.shift_time : ""}
                                                    />
                                                    : <DatePicker
                                                        onChange={(value, event) => {
                                                            //this.formDateHandler('downtime_seconds',moment.duration(moment(value).format('HH:mm:00')).asSeconds() , 'addDowntimeForm');
                                                            this.setState({ addDowntimeForm: { ...this.state.addDowntimeForm, downtime_seconds: moment.duration(moment(value).format('HH:mm:00')).asSeconds()} });
                                                        }}
                                                        selected={this.state.addDowntimeForm.downtime_seconds > 0 
                                                            ? new Date(new Date().setHours(0, 0, this.state.addDowntimeForm.downtime_seconds, 0))
                                                            : new Date(new Date().setHours(0, 0, 0, 0))}
                                                        //selected={this.state.addDowntimeForm?.total_hour ? this.state.addDowntimeForm.total_hour : ''}
                                                        className="form-control form-control-sm"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeFormat="HH:mm"
                                                        timeIntervals={5}
                                                        timeCaption="Hours"
                                                        dateFormat="HH:mm"
                                                        autoComplete={"off"}
                                                        placeholderText={`Select Limit`}
                                                        minTime={new Date(new Date().setHours(0, 1, 0, 0))}
                                                        maxTime={new Date(new Date().setHours(0, 0, shift_seconds, 0))}
                                                        disabled={(this.state.addDowntimeForm && this.state.addDowntimeForm.full_shift == "Y") || (setting && setting.enable_start_end_time && setting.enable_start_end_time == 'Y') ? true : false}
                                                    />}
                                            </div>
                                            <div className='col-sm-2'>
                                                <input
                                                    name="full_shift"
                                                    type="checkbox"
                                                    value="Y"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            addDowntimeForm: {
                                                                ...this.state.addDowntimeForm,
                                                                downtime_seconds: this.state.downtimeShiftDetail ? this.state.downtimeShiftDetail.seconds  : 0,
                                                               // total_hour: this.state.downtimeShiftDetail ? moment(this.state.downtimeShiftDetail.shift_time, "hh:mm a").toDate() : new Date(),
                                                                full_shift: (this.state.addDowntimeForm.full_shift ? this.state.addDowntimeForm.full_shift : 'N') == 'Y' ? 'N' : 'Y',
                                                                start_time: this.state.downtimeShiftDetail ? moment(this.state.downtimeShiftDetail.shift_start, "hh:mm a").toDate() : '',
                                                                end_time: this.state.downtimeShiftDetail ? moment(this.state.downtimeShiftDetail.shift_end, "hh:mm a").toDate() : '',
                                                            }
                                                        });
                                                    }}
                                                    className="form-check-input"
                                                    id="full_Shift"
                                                    checked={this.state.addDowntimeForm.full_shift === "Y"}
                                                />
                                                <label className="form-check-label mx-2" htmlFor="full_Shift">Full Shift</label>
                                            </div>
                                        </div>
                                        {setting && setting.enable_start_end_time && setting.enable_start_end_time == 'Y' && <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Start & End Time</label>
                                            <div className="col-sm-7">
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <DatePicker
                                                            onChange={(value, event) => {
                                                                this.setState({ addDowntimeForm: { ...this.state.addDowntimeForm, start_time: value } },() => {
                                                                    this.getTimeDiffernce();
                                                                });
                                                            }}
                                                            selected={this.state.addDowntimeForm?.start_time ? this.state.addDowntimeForm.start_time : ''}
                                                            className="form-control form-control-sm"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeFormat="HH:mm"
                                                            timeIntervals={5}
                                                            timeCaption="Hours"
                                                            dateFormat="HH:mm a"
                                                            autoComplete={"off"}
                                                            placeholderText={`Start Time`}
                                                            minTime={min_time_start}
                                                            maxTime={max_time_start}
                                                            disabled={this.state.addDowntimeForm && this.state.addDowntimeForm.full_shift == "Y" ? true : false}
                                                        />
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <DatePicker
                                                            onChange={(value, event) => {
                                                                this.setState({ addDowntimeForm: { ...this.state.addDowntimeForm, end_time: value } },() => {
                                                                    this.getTimeDiffernce();
                                                                });
                                                            }}
                                                            selected={this.state.addDowntimeForm?.end_time ? this.state.addDowntimeForm.end_time : ''}
                                                            className="form-control form-control-sm"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeFormat="HH:mm"
                                                            timeIntervals={5}
                                                            timeCaption="Hours"
                                                            dateFormat="HH:mm a"
                                                            autoComplete={"off"}
                                                            placeholderText={`End Time`}
                                                            minTime={min_time_end}
                                                            maxTime={max_time_end}
                                                            disabled={this.state.addDowntimeForm && this.state.addDowntimeForm.full_shift == "Y" ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                       
                                        {setting && setting.defect_code_downtime && setting.defect_code_downtime == 'Y' &&
                                            <div className="row my-2 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm ">Defect Code</label>
                                                <div className="col-sm-7">
                                                    <TapSelect
                                                        options={this.state.allDefectCodes}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addDowntimeForm', 'defect_codes');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isMulti={true}
                                                        value={this.state.allDefectCodes.filter(s => this.state.addDowntimeForm?.defect_codes?.includes(s.value))}
                                                        placeholder="Please enter Defect Codes"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                </div>

                                            </div>
                                        }
                                        <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Section</label>
                                            <div className="col-sm-7">
                                                <TapSelect
                                                    options={this.state.allSections}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addDowntimeForm', 'section');
                                                        let subSection = selectedOption && selectedOption.value ? this.state.allSections.find(s => selectedOption.value == s.value) : null
                                                        this.setState({
                                                            allSubSections: subSection && subSection.subcomponents && subSection.subcomponents.length > 0 ? subSection.subcomponents.map((s, i) => { return ({ value: s.id, label: s.subcomponent }) }) : []
                                                        })
                                                    }}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.allSections.find(s => this.state.addDowntimeForm.section == s.value)}
                                                    placeholder="Please enter Section Name"
                                                    containerHeight="30px"
                                                    fontSize="93%"

                                                />
                                            </div>

                                        </div>
                                        {setting && setting.display_sub_section && setting.display_sub_section == 'Y' &&
                                            <div className="row my-2 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm ">Sub-Section</label>
                                                <div className="col-sm-7">
                                                    <TapSelect
                                                        options={this.state.allSubSections}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addDowntimeForm', 'sub_section');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={this.state.allSubSections.find(s => this.state.addDowntimeForm.sub_section == s.value)}
                                                        placeholder="Please enter Sub Section Name"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                </div>

                                            </div>
                                        }
                                        {
                                            this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0
                                                ? (
                                                    <AdditionalAttributeForm
                                                        additionalAttributes={this.state.allAdditonalAttribute}
                                                        formState={this.state.addDowntimeForm}
                                                        onAttributeChange={(value, attributeName) => {
                                                            this.setState(() => ({
                                                                addDowntimeForm: {
                                                                    ...this.state.addDowntimeForm,
                                                                    [attributeName]: value
                                                                }
                                                            }))
                                                        }
                                                        }
                                                        formSmall={true}
                                                        colSpan={"col-sm-7"}
                                                    />
                                                )
                                                : null
                                        }
                                        {/* {
                                            this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0
                                                ? (
                                                    <Ax>

                                                        {
                                                            this.state.allAdditonalAttribute.map((i, k) => {
                                                                let name = 'additional_attributes_' + i.key;
                                                                let input = null;
                                                                if (i.type == 'text') {
                                                                    input = <input
                                                                        name={name}
                                                                        type="text"
                                                                        value={this.state.addDowntimeForm[name] ? this.state.addDowntimeForm[name] : ''}
                                                                        onChange={(e) => this.formInputHandler(e, 'addDowntimeForm')}
                                                                        className="form-control form-control-sm"
                                                                        autoComplete="off"
                                                                        placeholder={`Please enter ${i.name}`}
                                                                    />
                                                                } else if (i.type == 'numeric') {
                                                                    input = <input
                                                                        name={name}
                                                                        type="number"
                                                                        id={i.formula_key}
                                                                        value={this.state.addDowntimeForm[name] ? this.state.addDowntimeForm[name] : ''}
                                                                        onChange={(e) => this.formInputHandler(e, 'addDowntimeForm')}
                                                                        className="form-control form-control-sm"
                                                                        autoComplete="off"
                                                                        placeholder={`Please enter ${i.name}`}
                                                                    />
                                                                } else if (i.type == 'date') {
                                                                    input = <DatePicker
                                                                        selected={
                                                                            this.state.addDowntimeForm[name]
                                                                                ? moment(this.state.addDowntimeForm[name]).toDate()
                                                                                : false
                                                                        }
                                                                        name={name}
                                                                        onChange={(value, event) => this.formDateHandler(name, value, 'addDowntimeForm')}
                                                                        dateFormat="dd-MMM-yyyy"
                                                                        className={"form-control form-control-sm"}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        autoComplete='off'
                                                                        scrollMonthYearDropdown
                                                                        placeholderText={`Please Enter ${i.name}`}

                                                                    />
                                                                } else if (i.type == 'dropdown') {
                                                                    let options = i.options.map((t) => { return { value: t, label: t } });
                                                                    input = <TapSelect
                                                                        menuPlacement="top"
                                                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addDowntimeForm', name)}
                                                                        options={options}
                                                                        isSearchable={true}
                                                                        isClearable={true}
                                                                        value={options.find(o => o.value == this.state.addDowntimeForm[name])}
                                                                        placeholder={`Please Select ${i.name}`}
                                                                        containerHeight="30px"
                                                                        fontSize="93%"
                                                                    />
                                                                }

                                                                return (<div key={k} className="row align-items-center mb-3 mt-3">
                                                                    <div className="col-sm-3 col-form-label-sm col-form-label">{i.name}</div>
                                                                    <div className="col-sm-7">{input}</div>

                                                                </div>);
                                                            })
                                                        }
                                                    </Ax>
                                                )
                                                : null
                                        } */}
                                        <div className="row my-2 align-items-center">
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Remarks</label>
                                            <div className="col-sm-7">
                                                <textarea
                                                    name="remarks"
                                                    className="form-control"
                                                    placeholder="Enter Remarks"
                                                    onChange={(e) => this.formInputHandler(e, 'addDowntimeForm')}
                                                    style={{ height: "80px", fontSize: "93%" }}
                                                    autoComplete="off"

                                                    value={this.state.addDowntimeForm.remarks}
                                                />
                                            </div>

                                        </div>
                                    </form>}

                            </div>
                            <div className="modal-footer d-flex justify-content-between row">
                                <div className="col text-start">
                                    <input
                                        name="copy_reading"
                                        type="checkbox"
                                        value={this.state.copy_reading}
                                        onChange={(e) => {
                                            this.setState({ copy_reading: this.state.copy_reading == 'N' ? 'Y' : 'N' }, () => this.copyPreviousHandler())
                                        }}
                                        checked={this.state.copy_reading == 'Y'}
                                        className="form-check-input"
                                        id="copy_readingProduct"
                                        disabled={this.state.prevDowntimeData ? false : true}
                                    />
                                    <label className="form-check-label mx-2" htmlFor="copy_readingProduct">Copy Reading from Previous Downtime </label>
                                </div>
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={`uploadDowntimeForm`}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    SecondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }   

    viewDowntimeModalJsx              =   ()  =>  {
       let downtime = this.state.downtimeViewData; 
        return (
            <div className="modal fade" id={this.viewDowntimeModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>View Downtime for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addProductivityFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            {this.state.viewDataLoading ? <Loader />
                                : <Ax>
                                   <DowntimeView downtimeViewData = {downtime}/>
                                </Ax>}

                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">

                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" >Close</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return (<Ax>
            {this.uploadDowntimeModalJsx()}
            {this.viewDowntimeModalJsx()}
        </Ax>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(Downtime);