import React from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import { Link } from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import UpdateAttendenceModal from './UpdateAttendenceModal';
import EmployeeDetailHorizontalCard from '../employee/EmployeeDetailHorizontalCard';

class MonthlyAttendence extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            listing_loading: false,
            employee_loading: false,
            employeeDetail: null,
            employeeId: '',
            current_page: 1,
            // employee_listing: [],
            listingMeta: null,
            start_date: null,
            end_date: null,
            selectDateRange: { from: "", to: "" },
            saveFormSubmitting: false,
            employee_listing: [
                { enc_id: '1', name: 'Gunjan', check_in: '10:00 AM', check_out: '7:00 PM', status: 'Present', note: "any note" },
                { enc_id: '2', name: 'Sanjay', check_in: '10:00 AM', check_out: '6:00 PM', status: 'Present', note: "any note" },
                { enc_id: '3', name: 'Vinay', check_in: '9:00 AM', check_out: '7:00 PM', status: 'Present', note: "any note" },
            ]
        }
        this.updateAttendenceModalRef = React.createRef();

    }

    componentDidMount = () => {
        this.initalizeData(this.props);
        this.selectDateRangeModal = new Modal(document.getElementById('selectDateRangeModal'), { keyboard: false, backdrop: false });
    }


    initalizeData = (pr) => {
        let employee_id = pr.match?.params?.id;
        if (employee_id) {
            this.setState({ employeeId: employee_id }, () => {
                this.getEmployeeDetail(employee_id);
                this.loadListingTblData(this.state.current_page, employee_id);
            });
        }
    }
    getEmployeeDetail = (employee_id = null) => {
        let id = employee_id ? employee_id : this.state.employeeId;
        this.setState({ employee_loading: true });
        HttpAPICall.withAthorization('GET', TapApiUrls.HRM_URL + '/employee/detail/' + id, this.props.access_token, {}, {}, (response) => {
            this.setState({ employeeDetail: response.data.data });
        }).then(() => { this.setState({ employee_loading: false }) })
    }
    paginationHandler = (type) => {
        let new_page_number = type == "prev" ? this.state.listingMeta.current_page - 1 : this.state.listingMeta.current_page + 1;
        this.loadListingTblData(new_page_number, this.state.employeeId);
    }
    refreshLogsheet = () => this.loadListingTblData(this.state.current_page, this.state.employeeId, this.state.start_date, this.state.end_date)
    loadListingTblData = (page = null, employee_id, date_range_start, date_range_end) => {
        this.setState({ listing_loading: true, current_page: page });
        let params = {
            page: page,
            date_range_start: page ? null : date_range_start,
            date_range_end: page ? null : date_range_end
        };
        HttpAPICall.withAthorization('GET', TapApiUrls.HRM_URL + '/month_wise_employee_shift/' + employee_id, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                listing_loading: false,
                employee_listing: respData.data,
                listingMeta: respData.meta,

            });
        }).then(() => this.setState({ listing_loading: false }));
    }
    employeeCardHandler = () => {
        new Collapse(document.getElementById('employeeCard'));
        if (document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    selectDateRangeModalInit = () => {
        this.selectDateRangeModal.show()
        this.setState({ selectDateRange: { from: '', to: '' } })
    }
    tapDateChange = (selectedOption) => {
        if (selectedOption && selectedOption.length > 0) {
            this.setState({
                selectDateRange: {
                    ...this.state.selectDateRange,
                    from: selectedOption[0] ? selectedOption[0] : '',
                    to: selectedOption[1] ? selectedOption[1] : '',
                }
            });
        }
    }
    submitSearchDateRangeHandler = (e) => {
        e.preventDefault();
        let start_date = this.state.selectDateRange && moment(this.state.selectDateRange.from, 'YYYY-MM-DD').format("YYYY-MM-DD");
        let end_date = this.state.selectDateRange && moment(this.state.selectDateRange.to, 'YYYY-MM-DD').format("YYYY-MM-DD");
        this.setState({ start_date: start_date, end_date: end_date });
        this.loadListingTblData(null, this.state.employeeId, start_date, end_date)
        this.selectDateRangeModal.hide();
    }

    updateEmployeeAttendenceModalInit = (data) => {
        //let date                            =   moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        let employeeData = this.state.employeeDetail;
        this.updateAttendenceModalRef.current.updateAttendenceInit(data.date, { ...employeeData, attendance: data.attendance });
    }
    closeLogsheet = () => {
        this.props.showmonthlyattendence();
    }

    afterSubmitAttendenceHandler = (date) => {

        let itemList = [...this.state.employee_listing];
        let dateIndex = itemList.findIndex(i => i.date == date);
        itemList[dateIndex] = { ...this.state.employee_listing[dateIndex], isLoading: true };
        this.setState({ employee_listing: itemList });

        let params = { page: null, date_range_start: date, date_range_end: date };
        HttpAPICall.withAthorization('GET', TapApiUrls.HRM_URL + '/month_wise_employee_shift/' + this.state.employeeId, this.props.access_token, params, {}, (resp) => {
            if (resp.data && resp.data.data) {
                itemList[dateIndex] = resp.data.data[0];
                this.setState({ employee_listing: itemList });
            }
        });
    }

    pageTitleBarJsx = () => {
        return <div className="page_title row m0">
            <div className="col-12 employee_collapse">
                <h3>{this.state.employeeDetail ? this.state.employeeDetail.display_full_name : ""}</h3>

            </div>
        </div>
    }

    logSheetSubHeadingJsx = () => {
        return <div className="mt-1">
            <div className="bg-white row m0 text-decoration-none d-flex align-items-center justify-content-between">
                <div className="col-sm-5 py-2">
                    <h5 className="fs-6 m-0">Monthly Attendence : {this.state.listingMeta ? <span>{this.state.listingMeta.from} - {this.state.listingMeta.to}</span> : null}</h5>
                </div>
                <div className='col-sm-2 text-center align-self-start'>
                    <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.employeeCardHandler}> Employee Card</button>
                </div>
                <div className='col-sm-5 text-end my-2'>
                    <button type="button" className="btn btn-secondary ms-1" disabled={this.state.listing_loading || this.state.listingMeta && this.state.listingMeta.current_page == this.state.listingMeta.last_page} onClick={(e) => this.paginationHandler('next')}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faArrowLeft} className="mx-1" />
                    </button>
                    <button type="button" className="btn btn-secondary ms-1" onClick={this.selectDateRangeModalInit} disabled={this.state.listing_loading}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faCalendarAlt} className="mx-2 cursor_pointer" color="white" />
                    </button>
                    <button type="button" className="btn btn-secondary ms-1" disabled={this.state.listing_loading || this.state.listingMeta && this.state.listingMeta.current_page == 1} onClick={(e) => this.paginationHandler('prev')}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faArrowRight} className="mx-1" />
                    </button>
                    <span className="dropdown ms-1"
                    // onClick={() => this.updateEmployeeAttendenceModalInit(s)}
                    >
                        <button
                            type="button"
                            id="actionMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            className="btn btn-secondary"
                            disabled={this.state.listing_loading}>
                            <TapIcon.imageIcon
                                icon={TapIcon.ActionIcon}
                                className="img-fluid"
                                alt="item-action"
                            />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                            <li><Link className="dropdown-item" role="button" >Upload</Link> </li>
                        </ul>
                    </span>
                    <button type="button" className="btn btn-secondary ms-1" onClick={this.refreshLogsheet} disabled={this.state.listing_loading}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className={['cursor_pointer', 'img-fluid', this.state.listing_loading ? "fa-spin" : ''].join(' ')} color="white" />
                    </button>
                    <button type="button" className="btn btn-secondary ms-1" onClick={this.closeLogsheet} disabled={this.state.listing_loading}>
                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className=" cursor_pointer img-fluid" color="white" />
                    </button>
                </div>
            </div>
        </div>
    }

    employeeListJsx = () => {

        return <Ax>
            <div className='table-responsive pb-5' style={{ maxHeight: "74vh" }}>
                <table className="table table-bordered bg-white table-fixed table-hover mb-2" id="employeeLogsheetTable">
                    <thead className='table-secondary text-center'>

                        <th style={{ width: "18%" }}>Date</th>
                        <th style={{ width: "15%" }}>Shift</th>
                        <th style={{ width: "13%" }}>Attendence Status</th>
                        <th style={{ width: "15%" }}>Check In</th>
                        <th style={{ width: "15%" }}>Check Out</th>
                        <th style={{ width: "15%" }}>Details</th>
                    </thead>
                    <tbody>
                        {!this.state.listing_loading
                            ?
                            this.state.employee_listing && this.state.employee_listing.length > 0 ? this.state.employee_listing.map((s, i) => {
                                return (

                                    <tr className='text-center '>
                                        <td className="va_middle">
                                            {/* {s.date_display} */}
                                            10-nov-2024
                                        </td>

                                        <td className='text-center'>
                                            {/* {s.roster_working_data && s.roster_working_data.shifts && s.roster_working_data.shifts.length > 0
                                            ? s.roster_working_data.shifts.map((sf, key) => { return (<Ax>{sf.shift_name} <br /> <span className="text-sm">{sf.shift_start} - {sf.shift_end}</span></Ax>) })
                                            : "-"} */}
                                            Morning
                                        </td>
                                        <td>
                                            <Link to={`/employee_daily_attendence/${s.id}`}>Present</Link>
                                            {/* {
                                        s.attendance
                                            ? <div className="mt-1 link-primary cursor_pointer">
                                                {s.attendance.status ? <Status color={s.attendance.status.color_code}>{s.attendance.status.name}</Status> : ""}
                                            </div>

                                            :
                                            <a role="button" className='link-primary' onClick={() => this.updateEmployeeAttendenceModalInit(s)}>No Record</a>} */}
                                        </td>
                                        {/* {s.attendance && <Ax> */}
                                        <td>
                                            {/* {s.attendance.check_in ? s.attendance.check_in.substring(0, 10) === s.attendance.date.substring(0, 10) ? (
                                            // If equal, display only the time
                                            new Date(s.attendance.display_check_in).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                        ) : (
                                            // If not equal, display the full check-in time
                                            s.attendance.display_check_in
                                        ) : ""} </td>
                                        <td>{s.attendance.check_out ? s.attendance.check_out.substring(0, 10) === s.attendance.date.substring(0, 10) ? (
                                            // If equal, display only the time
                                            new Date(s.attendance.display_check_out).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                        ) : (
                                            // If not equal, display the full check-in time
                                            s.attendance.display_check_out
                                        ) : ""} */}
                                            12:00 AM
                                        </td>
                                        <td>8:00 PM</td>
                                        <td>
                                            8 hours
                                            {/* {s.attendance.note ? s.attendance.note : ""} */}
                                        </td>
                                        {/* </Ax> */}
                                        {/* } */}


                                    </tr>
                                )
                            }
                            )
                                : <tr><td className='text-center' colSpan={6}>No Record</td></tr>
                            : <tr><td className='text-center' colSpan={6}><Loader /></td></tr>
                        }

                    </tbody>
                </table>
            </div>

        </Ax>
    }

    selectDateRangeModalJsx = () => {
        return (
            <div className="modal fade" id="selectDateRangeModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Select Attendence Date Range</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-1" id="searchDate" onSubmit={this.submitSearchDateRangeHandler}>
                                <div className='row my-2'>
                                    <div className="row align-items-center mb-3 mt-2">
                                        <div className='col-sm-5'><label className="form-label ">Attendence Date Range</label> </div>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selectsRange={true}
                                                monthsShown={2}
                                                dateFormat="dd-MMM-yyyy"
                                                className={'form-control'}
                                                autoComplete="off"
                                                name="date"
                                                startDate={this.state.selectDateRange.from}
                                                endDate={this.state.selectDateRange.to}
                                                onChange={(value, event) => this.tapDateChange(value)}
                                                maxDate={moment().toDate()}
                                                minDate={new Date(this.state.listingMeta && this.state.listingMeta.first_date)}
                                            />
                                            <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" form='searchDate'>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    employeeDetailCardJsx = () => {
        let details = this.state.employeeDetail;
        return (
            <div className="container-fluid p-0">
                {this.state.employee_loading
                    ? <div className='text-center'> <Loader /></div>
                    : <table className="table table-borderless va_middle mb-1 bg-white">
                        {details
                            ? <tbody>
                                <tr>
                                    <td style={{ width: "25%" }}>
                                        Employee Name : <br />
                                        <Link to={{ pathname: '/employee_list', state: { id: details.enc_id } }} className="link-primary cursor_pointer"> <b>{details.name ?? "-"}</b></Link>
                                    </td>
                                    <td style={{ width: "25%" }}>Email ID : <br /><b>{details.email ? details.email : "-"}</b></td>
                                    <td style={{ width: "25%" }}>Asset Types : <br /><b>{details.asset_types && details.asset_types.length > 0 ? details.asset_types.map(at => at.asset_type_name).join(', ') : "-"}</b>
                                    </td>
                                    <td style={{ width: "25%" }}>Site : <br />
                                        <b>{details.site ? details.site.site_name : "-"}</b>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Employee Code : <br /><b>{details.employee_code ?? "-"}</b></td>
                                    <td>Mobile : <br /><b>{details.mobile ? details.mobile : "-"}</b></td>
                                    <td>Tags : <br /><b>{details.tags && details.tags.length > 0 ? details.tags.map(st => st).join(', ') : "-"}</b></td>
                                    <td>Status :  <Status color={details?.status_color_code} className="mt10"><b>{details?.status_text}</b></Status> </td>
                                </tr>


                            </tbody>
                            : null
                        }
                    </table>}
            </div>
        )
    }


    render = () => {
        return (
            <>
                {/* {this.pageTitleBarJsx()} */}
                <div id="employeeCard" className="collapse pr-1 mt-1" aria-expanded="true" >
                    {/* {this.employeeDetailCardJsx()} */}
                    <EmployeeDetailHorizontalCard employeeData={this.props.employeeData} employee_loading={this.state.employee_loading} />
                </div>

                {this.logSheetSubHeadingJsx()}
                <div className="container-fluid pl0 pr13">
                    <div className="page_containt row"><div className="pl16 col-12 pr0">{this.employeeListJsx()}</div></div>
                </div>

                {this.selectDateRangeModalJsx()}
                <UpdateAttendenceModal ref={this.updateAttendenceModalRef} afterSubmitAttendence={(date, empData) => { this.afterSubmitAttendenceHandler(date, empData) }} />


            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        item_tags: state.app && state.app.item_tags ? state.app.item_tags : [],
        iam_asset_types: state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites: state.app && state.app.user_sites ? state.app.user_sites : [],
        group_permission: state.app.acl_info,
        permissions: state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(MonthlyAttendence);