import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import ReactPaginate  from 'react-paginate';
import download from '../../../services/DownloadFile';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import swal from "sweetalert";
import moment from "moment";
import ChangeAssetStatus from '../includes/ChangeAssetStatus';
import AssetHeader from '../includes/AssetHeader';
import InactivateAsset from '../includes/InactivateAsset';
import { Helmet } from 'react-helmet';
import ExpenseView from '../counterlog/ShiftActivityModals/expense/ExpenseView';


class ExpenseHistory extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.expenseSearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_expense_heads'          :   [],
            'search_wallet_ids'             :   [],
            'search_amount'                 :   '',
            'search_payee'                  :   '',
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'transaction_delay'            :   ''
        };

        this.expenseSearchFormLabel         =   {
            'search_expense_heads'           :   'Expense Head : ',
            'search_wallet_ids'              :   'Wallet : ',
            'search_amount'                  :   'Amount : ',
            'search_payee'                   :   'Payee : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'transaction_delay'             :   'Transaction Delay : '
        };

        this.state                         =     {  
            minimizeTable                   :       false,   
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            expense_listing                 :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            expenseData                     :       null,
            viewDataLoading                 :       false,
            access_token                    :       '',
            all_periods                     :        [],
            searchedAssets                  :        [],
            iam_asset_types                 :        [],
            iam_group_sites                 :        [],
            all_periods                     :        [],
            expenseSearchForm               :         {...this.expenseSearchInitState},
            formSearchedElems               :         [],
            expenseSearchParams             :         null,
            file_downloading                :         false,
            allTransactionDelay             :         [],
            allPayee                        :         [],
            allExpenseHead                  :         [],
            allWalletList                   :         []
        };

    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.expenseSearchModal         =    new Modal(document.getElementById('expenseSearchModal'), {keyboard: false, backdrop :false});

    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        let all_periods             =              [...pr.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/expense/list_form_data', this.props.access_token, {})
        .then((response) => {
    
            this.setState({
                assetId              :       pr.match?.params?.id,  
                iam_asset_types         :       pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return { value: at.id, label: at.asset_type_name } }) : [],
                iam_group_sites         :       pr.iam_group_sites ? pr.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
                all_periods             :       all_periods,
                allTransactionDelay     :       response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : [],
                allExpenseHead          :       response.data && response.data.heads ? response.data.heads.map((td) => { return {value: td.id, label: td.expense_head}}) : [],
                allWalletList           :       response.data && response.data.wallets ? response.data.wallets.map((td) => { return {value: td.id, label: td.wallet_name}}) : [],
                allPayee                :       [{value:'vendor',label:'Vendors'},{value:'employee',label:'Employees'},{value:'other',label:'Other'}]
            },() => {
                
                this.loadListingTblData(1,pr.match?.params?.id);
                this.getAsssetDetails(pr.match?.params?.id)
                let ParamObject                 =       new URLSearchParams(this.props.location.search);
              
                if(ParamObject.get('search_site_ids') || ParamObject.get('entry_date_range') || ParamObject.get('transaction_delay') ) {
                     let updateFilterForm = {'entry_date_range' : ParamObject.get('entry_date_range'),'transaction_delay' : ParamObject.get('transaction_delay') };
                   
                   if(ParamObject.get('search_site_ids')){
                        updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_ids')]
                    }
 
                    setTimeout(() => {
                        this.setState({expenseSearchForm : {...this.state.expenseSearchForm,...updateFilterForm}},
                        function(){
                             this.expenseSearchFormSubmit();
                            });
                    }, 1)
                  
                     }
            });
        })
    }

  
    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    loadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,search_asset_ids : [id],need_asset_profile : 'Y',...this.state.expenseSearchParams};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/expense/list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                expense_listing         :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    expenseSearchModalInit        =       ()      =>      {
        this.expenseSearchModal.show()
    }

    expenseSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems       :          [],
            expenseSearchParams     :         null,
            searchedAssets           :         [],
            expenseSearchForm       :        {...this.expenseSearchInitState}
        }, () => {
            this.expenseSearchModal.hide();
            this.loadListingTblData(1,this.state.assetId)
        });
    }

    expenseSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.expenseSearchForm);
        let searchedElems               =   [];
        let expenseSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.expenseSearchFormLabel[key];
            let value                       =   this.state.expenseSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                expenseSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_amount') {
                        show_val            =   this.state.expenseSearchForm && this.state.expenseSearchForm.search_amount ? this.state.expenseSearchForm.search_amount : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.expenseSearchForm && this.state.expenseSearchForm.search_transaction_id ? this.state.expenseSearchForm.search_transaction_id : '';
                    }
                    if(key == 'transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_expense_heads') {
                        show_val            =   this.state.allExpenseHead.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_wallet_ids') {
                        show_val            =   this.state.allWalletList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_payee') {
                        show_val            =   this.state.allPayee.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.expenseSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.expenseSearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.expenseSearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.expenseSearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            expenseSearchParams      :       expenseSearchParams
        }, () => {
            this.expenseSearchModal.hide();
            this.loadListingTblData(1,this.state.assetId)
        });
    }

    viewExpenseDetail           =       (id)        =>      {
        this.setState({minimizeTable : true,viewDataLoading  :true})
        this.getExpenseDetail(id)
    }

    closeView           =       ()        =>      {
        this.setState({minimizeTable : false})
    }
    

    getExpenseDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/expense/detail/' + id, this.state.access_token ? this.state.access_token : this.props.access_token, {need_asset_profile : 'Y',need_site:'Y'}, {}, (response) => {
            this.setState({
                expenseData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    expenseDownloadHandler              =    ()     =>         {
        let params                          =   {...this.state.expenseSearchParams};
        if(this.state.expenseSearchParams !== null){
            this.expenseDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.expenseDownloadStart(params);
                }
            });
        }
    }

    expenseDownloadStart              =    (params)     =>         {
        this.setState({ file_downloading: true});
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/expense/list', this.props.access_token,{download : "download",...params},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({file_downloading: false}));
    }
    
   
  
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}/>
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {    
        return (<div>
            <div id="assetCard" className="collapse pr-1 mt-1" aria-expanded="true" >
                {this.state.asset_loading ? <div className='text-center pt-1'><Loader /></div>
                    : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail}/>}
            </div>
        </div>)
    }

   expenseHistoryListJsx            =       ()      =>    {

       return (<Ax>
           <div style={{ paddingTop: "4px" }}>
               <div className="page_title row m0" style={{ height: "45px" }}>
                   <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                       <h3 style={{ paddingTop: "13px" }}>Expense History</h3>
                   </div>
                   {!this.state.minimizeTable
                       ? <div className='col-sm-2 '>
                           <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                       </div> : null}

                   {!this.state.minimizeTable
                       ? <div className='col-sm-4 text-end mt7 '>
                           <button type="button" onClick={this.expenseSearchModalInit} className="btn btn-secondary" >
                               <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                           </button>
                           <button type="button" className="btn btn-secondary"
                               disabled={this.state.file_downloading || this.state.listing_loading}
                               onClick={this.expenseDownloadHandler} >
                               <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                               {this.state.file_downloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                           </button>
                       </div> : null}
                   {
                       this.state.minimizeTable
                           ? (
                               <Ax>
                                   <div className="col-sm-3 tap_border_left">
                                       <h3 style={{ paddingTop: "13px" }}>Expense : {this.state.expenseData && this.state.expenseData.transaction_id ? this.state.expenseData.transaction_id : '-'}</h3>
                                   </div>
                                   <div className='col-sm-2 '>
                                       <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                   </div>
                                   <div className='col-sm-4 text-end mt7'>
                                           <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                               <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                           </button>
                                   </div>
                               </Ax>
                           )
                           : null
                   }
               </div>
           </div>
           <div className="container-fluid pl0 pr13">
               <div className="page_containt row">
                   <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.expenseListingTableJsx()}</div>
                   {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0"><div className="bg-white p-2" style={{ maxHeight: "69vh", overflowY: "scroll" }}>{this.state.viewDataLoading ? <Loader /> : this.expenseViewDetailJsx()}</div> </div> : null}
               </div>
           </div>
       </Ax>);
    }

    expenseListingTableJsx                   =   ()  =>  {
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.expenseSearchClear} />
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered table-fixed  bg-white table-sm">
                        <thead className="table-secondary" >
                            {this.state.minimizeTable
                                ? (<tr>
                                    <th scope="col" style={{ width: "15%" }}>Expense</th>
                                </tr>)
                                : (<tr>
                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                    <th scope="col" style={{ width: "10%" }} className='text-center'>Date</th>
                                    <th scope="col" style={{ width: "13%" }}> Transaction ID</th>
                                    <th scope="col" style={{ width: "15%" }}>Expense Head</th>
                                    <th scope="col" style={{ width: "15%" }}>Description</th>
                                    <th scope="col" style={{ width: "15%" }}>Wallet</th>
                                    <th scope="col" style={{ width: "15%" }}>Payee Name</th>
                                    <th scope="col" style={{ width: "9%" }} className='text-end'>Amount</th>
                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                </tr>)
                            }
                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                : (this.state.expense_listing.length > 0
                                    ? (this.state.expense_listing.map((expense, index) => {
                                        return (<tr key={index} >
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }} onClick={() => this.viewExpenseDetail(expense.transaction_id)} >
                                                                <div className="text-capitalize link-primary cursor_pointer">{expense.transaction_id ? expense.transaction_id : "-"}</div>
                                                                <div className="mt-1">
                                                                    <small className="text-capitalize ">{expense.expense_head_name ? expense.expense_head_name : '-'} </small>
                                                                    <small className="float-end">₹ {expense.amount ? expense.amount : '-'}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    : (<Ax>
                                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                        <td className='text-center' >{expense.expense_date_display ? expense.expense_date_display : "-"}</td>
                                                        <td className='link-primary cursor_pointer' onClick={() => this.viewExpenseDetail(expense.transaction_id)} >{expense.transaction_id ? expense.transaction_id : "-"}</td>
                                                        <td>{expense.expense_head_name ? expense.expense_head_name : '-'}</td>
                                                        <td>{expense.details ? expense.details : '-'}</td>
                                                        <td>{expense.walletData && expense.walletData.wallet_name ? expense.walletData.wallet_name : '-'}</td>
                                                        <td>{expense.payee == "employee" && expense.employeeData ? <div><small className='form-text'>Employee : </small> {expense.employeeData.display_full_name}</div>
                                                            : expense.payee == "vendor" && expense.vendorData ? <div><small className='form-text'>Vendor: </small>{expense.vendorData.name}</div> : expense.payee_name ? expense.payee_name : "-"}  </td>
                                                        <td className='text-end'>₹ {expense.amount ? expense.amount : '-'}</td>
                                                        <td className="text-center">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewExpenseDetail(expense.transaction_id)}  >View</a></li>

                                                            </ul>
                                                        </td>
                                                    </Ax>
                                                    )}
                                        </tr>)
                                    }))
                                    : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                )
                            }

                        </tbody>
                    </table>
                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1, this.state.assetId)}
                    />
                </div>
            </Ax>
        );
    }


    expenseViewDetailJsx                =   ()   =>   {
        return (<div>
            {this.state.viewDataLoading ? <div className='text-center'><Loader /></div>
                : this.state.expenseData ? <ExpenseView expenseData={this.state.expenseData} /> : null}
        </div>)
    }

    expenseSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="expenseSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="expenseSearchModalLabel">Expense Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.expenseSearchFormSubmit} id="expenseSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.expenseSearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "expenseSearchForm")}
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Expense Head</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allExpenseHead}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_expense_heads')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allExpenseHead.filter(s => this.state.expenseSearchForm.search_expense_heads.includes(s.value))}
                                            placeholder="Select Expense Head"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Expense Amount</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_amount"
                                            className="form-control form-control-sm"
                                            placeholder=" Example. 0-5 for a range or 4 for specific Expense Amount"
                                            autoComplete="off"
                                            value={this.state.expenseSearchForm.search_amount}
                                            onChange={(e) => this.formInputHandler(e, "expenseSearchForm")}
                                        />
                                    </div>
                                </div>
                               
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.expenseSearchForm.transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Wallet</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allWalletList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_wallet_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allWalletList.filter(s => this.state.expenseSearchForm.search_wallet_ids.includes(s.value))}
                                            placeholder="Select Wallet"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Payee</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allPayee}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_payee')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allPayee.filter(s => this.state.expenseSearchForm.search_payee.includes(s.value))}
                                            placeholder="Select Payee"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.expenseSearchForm.entry_date_range}
                                            startDate={this.state.expenseSearchForm.entry_date_range_start}
                                            endDate={this.state.expenseSearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    expenseSearchForm
                                                        : {
                                                        ...this.state.expenseSearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.expenseSearchForm.date_range}
                                            startDate={this.state.expenseSearchForm.date_range_start}
                                            endDate={this.state.expenseSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    expenseSearchForm
                                                        : {
                                                        ...this.state.expenseSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - Expense History</title></Helmet>
            {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.expenseHistoryListJsx()}
            {this.expenseSearchModalJsx()}
            <ChangeAssetStatus ref={this.ChangeAssetStatusModalRef} afterChangeStatus={this.getAsssetDetails} />
            <InactivateAsset ref={this.InactivateAssetModalRef} afterChangeStatus={this.getAsssetDetails} />
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app.acl_info.permissions,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ExpenseHistory);