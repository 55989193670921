import React from 'react';

class NumberFormatter extends React.Component {
  formatNumber = (number, precision) => {
    const parsedNumber = parseFloat(number);
    if (!isNaN(parsedNumber)) {
      return parsedNumber.toFixed(precision);
    }
    return '';
  };

  render() {
    const { number, precision } = this.props;
    const formattedNumber = this.formatNumber(number, precision);

    return formattedNumber;
  }
}

export default NumberFormatter;
