import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import Ax from '../../../components/hoc/Ax';
import ReactSelectStyle from '../../../components/ui/ReactSelectStyle';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class InputItemSearch extends React.Component {
    
    constructor(props) {
        super(props);
        this.state              =   {
            selectedItem            :   null,
            timer                   :   false
        };
        this.cancelToken                =   null;
        this.apiTimeout              =   null;
    }
    
    onInputChange                   =   (selectedOption)  =>  {
        this.props.changeEvent(selectedOption);
        
    }
    
    displayStringSearch             =   (item)  =>  {
        return item.map(item => {
            return {
                label               :   (<Ax>
                                            <b>Item : </b> {item.name} <small> ({item.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {item.manufacturer ? item.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {item.manufacturer_part_no}</small></Ax>),
                value               :   item.id ,
                display_label       :   `${item.name} (${item.item_code})`,
                itemdata            :   item
            };
        });
    };

    loadingOptions                  =   (inputText,callback)  =>  {
//        setTimeout(
//            () => this.setState({ position: 1 }),
//            3000
//        );
        if(inputText.length > 2) {
            clearTimeout(this.apiTimeout)
            if (this.cancelToken) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }
            this.cancelToken = axios.CancelToken.source();

            let params = {search_by_text: inputText, per_page: 50};
            
            if(this.props.only_stocked_item) {
                params      =   {...params , only_stocked_item : this.props.only_stocked_item};
            }
            if(this.props.stock_warehouse_ids) {
                params      =   {...params , stock_warehouse_ids : this.props.stock_warehouse_ids};
            }
            if(this.props.indent_exclude_warehouse_id) {
                params      =   {...params , indent_exclude_warehouse_id : this.props.indent_exclude_warehouse_id};
            }
            if(this.props.indent_warehouse_id) {
                params      =   {...params , indent_warehouse_id : this.props.indent_warehouse_id};
            }
            if(this.props.needIndent && this.props.needIndent == 'Y') {
                params      =   {...params , need_indent : 'Y'};
            }
            if(this.props.search_category && this.props.search_category == 'services') {
                params      =   {...params , search_category : 'services'};
            }
            if(this.props.only_active_item && this.props.only_active_item == 'Y') {
                params      =   {...params , only_active_item : 'Y'};
            }

            if(this.props.current_stock && this.props.current_stock == 'Y') {
                params      =   {...params , current_stock : 'Y'};
            }
            if(this.props.interchangeable_current_stock && this.props.interchangeable_current_stock == 'Y') {
                params      =   {...params , interchangeable_current_stock : 'Y'};
            }
            if(this.props.warehouse_id) {
                params      =   {...params , warehouse_id : this.props.warehouse_id};
            }
            
            this.apiTimeout  = setTimeout(() => {this.getLoadingOptions(params,callback)},1000);
        }
    }

    getLoadingOptions         =        (params,callback)            =>         {
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
            params : params,
            url: INVENTORY_API_BASE_URL_2 + '/item',
            cancelToken : this.cancelToken.token
        })
            .then((response) =>  {
                //for filtering service item list
                if(this.props.filter === true){
                    let goodsItemList = response.data.data.filter(i => i.category !== "services")
                    callback(this.displayStringSearch(goodsItemList))
                }else{
                    callback(this.displayStringSearch(response.data.data))
                }
            }).catch((err) => {
                if (axios.isCancel(err)) {
                  
                } else {
                    ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
                }
            })
    }
    
    render() {
          return (<Ax>
            <AsyncSelect
                styles          =   {ReactSelectStyle(this.props)}
                menuPlacement  =    {this.props.menuPlacement || 'bottom'}
                placeholder     =   {this.props.placeholder || "Search Item by Name, Code & MPN" }
                loadingMessage  =   {(input) => {
                    if(input.inputValue && input.inputValue.length <=2 ) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'Searching...';
                    }
                }}
                noOptionsMessage  =   {(input) => {
                    if(input.inputValue.length == 0) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'No Item found';
                    }
                }}
                minimumInput    =   {5}
                complete        =   {true}
                onChange        =   {this.onInputChange}
                isClearable     =   {true}
                loadOptions     =   {this.loadingOptions}
                value           =   {this.props.value}
                isMulti         =   {this.props.isMulti || false}
                required        =   {true}
                isDisabled      =   {this.props.disabled || false}
            />
            <input
                name=''
                tabIndex={-1}
                required={this.props.required || false}
                value={this.props.isMulti ? (this.props.value.length > 0 ? this.props.value[0].label : [].join(' ')) : (this.props.value ? this.props.value.label : '')}
                onChange={() => {} }
                style={{
                    opacity: 0,
                    width: 0,
                    height: 0,
                    padding: 0,
                    margin: 0,
                    display: 'table'
                  }}
            />        
            </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(InputItemSearch);