import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";

class RaisePurchaseOrder extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            access_token                :   '',
            poListLoading               :   false,
            poList                      :   [],
            warehouse_id                :   '',
            req_item_ids                :   [],
            selected_purchase_order     :   ''
        }

        this.warehousePendingPOUrl  =   INVENTORY_API_BASE_URL_2 + '/warehouse/pending_purchase_order';

    }
    
    componentDidMount               =   ()  =>  {
        this.existingPOModal            =   new Modal(document.getElementById('existingPOModal'), {keyboard: false, backdrop :false});
        this.initScreen(this.props);
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                      =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }
    
    choosePoModalInit               =   (req_item_ids, warehouse_id)  =>  {
        this.setState({
            req_item_ids            :   req_item_ids,
            warehouse_id            :   warehouse_id
        }, () => {
            this.getPurchaseOrderList();
        });
    }
    
    getPurchaseOrderList                =   ()  =>  {
        this.setState({poListLoading : true, poList : []});
        HttpAPICall.withAthorization('GET', this.warehousePendingPOUrl + '/' + this.state.warehouse_id, this.state.access_token, null, null, (response) => {
            let poList      =   response.data.data;
            this.setState({poList : poList}, () => {
                if(poList.length == 0) {
                    this.goToAddPurchaseOrderScreen();
                }
            });
        }, (err) => {}).then(() => {
            this.setState({poListLoading : false});
        });
        this.existingPOModal.show()
    }
    
    goToAddPurchaseOrderScreen          =   (e=null)  =>  {
        if (e) { e.preventDefault(); }
        
        let stateData                    =   {   
            warehouse_id                :   this.state.warehouse_id,
            req_item_ids                :   this.state.req_item_ids,
            selected_purchase_order     :   this.state.selected_purchase_order
        };
        this.props.parentProps.history.push({ pathname: `/purchase_order/add`, state: { reqItemsforPO : stateData } });
    
        
    }
    
   
    existingPOModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="existingPOModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Item With Existing PO</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        
                            <div className="modal-body p-0">
                            <form onSubmit={this.goToAddPurchaseOrderScreen} id="raisePOForm">
                                {this.state.poListLoading
                                    ? <Loader />
                                    : <table className="table table-bordered bg-white ">
                                        <thead className="table-secondary">
                                            <tr className="text-center">
                                                <th scope="col" style={{ width: "10%" }} ></th>
                                                <th scope="col" style={{ width: "20%" }}>PO Number</th>
                                                <th scope="col" style={{ width: "20%" }}>Transaction Date</th>
                                                <th scope="col" className="text-start">Vendor</th>
                                                <th scope="col" style={{ width: "15%" }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {this.state.poList.length > 0
                                                ? (this.state.poList.map((po_item, k) => {
                                                    return <tr key={k} className='text-center'>
                                                        <td>
                                                            <input 
                                                                type="radio"
                                                                name="poQty"
                                                                value={po_item.transaction_id} 
                                                                id={"po_" + po_item.transaction_id} 
                                                                checked={this.state.selected_purchase_order === po_item.transaction_id}
                                                                onChange={(e) => this.setState({selected_purchase_order : e.target.value})}
                                                            />
                                                        </td>
                                                        <td>
                                                            <label className="form-check-label" htmlFor={"po_" + po_item.transaction_id}>
                                                                {po_item.transaction_id}
                                                            </label>
                                                        </td>
                                                        <td>{po_item.transaction_date_display}</td>
                                                        <td className='text-start text-capitalize'>{po_item.vendor ? po_item.vendor.name : '-'}</td>
                                                        <td>{po_item.status_data ? po_item.status_data.name : "-"}</td>
                                                    </tr>;
                                                }))
                                                : <tr className='text-end'><td colSpan="5">No recods</td></tr>
                                            }
                                            <tr>
                                                <td>
                                                    <input 
                                                        type="radio" 
                                                        name="poQty" 
                                                        value='' 
                                                        id="po_new" 
                                                        checked={this.state.selected_purchase_order === ''}
                                                        onChange={(e) => this.setState({selected_purchase_order : e.target.value})}
                                                    />
                                                </td>
                                                <td colSpan="4" className="text-start">
                                                    <label className="form-check-label" htmlFor="po_new">
                                                        New Purchase Order
                                                    </label>
                                                </td></tr>
                                        </tbody>
                                    </table>}
                                    </form>
                            </div>
                            <div className="modal-footer">
                                <button disabled={this.state.poListLoading} type="submit" className="btn btn-primary" form="raisePOForm">Add to PO</button>
                            </div>
                      
                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return (<Ax>
            {this.existingPOModalJsx()}
        </Ax>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RaisePurchaseOrder);