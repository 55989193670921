import React from 'react';
import { Helmet } from 'react-helmet';
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import SiteAttributeList from '../configuration/SiteAttributeList';
import WalletConfiguration from '../configuration/WalletConfiguration';
import "../MasterData.css";
import Designation from './Designation';
import DocumentTypes from './DocumentTypes';
import OrganizationProfile from './OrganizationProfile';
import SiteGrouping from './SiteGrouping';
import Holiday from './Holiday';
import CurrencyConfiguration from './CurrencyConfiguration';
import MyDashboard from './myDashboard/MyDashboard';
import AccountingCode from './AccountingCode';
import SiteProductivity from './SiteProductivity';
import Workstation from './Workstation';
import HrmDocumentTypes from './HrmDocumentTypes';
import IscDocumentTypes from './IscDocumentTypes';

class ConfigurationGeneral extends React.Component {

    constructor(props) {
        super(props);
        this.state               =            {
            configScreen         :           "accounting_code",
            add                  :            false
        }
        this.handleClose            =        this.handleClose.bind(this)
    }

    handleAdd        =      ()     =>   {
        this.setState({add:true})
    }

    handleClose     =      ()     =>    {
        this.setState({add : false})
    }

    pageTitleBarJsx                    =    (configTitle)   =>          {
        let title =  ""
        if(configTitle === "site"){
           title = "Additional Attribute - Site"
        }else if(configTitle === "wallet"){
            title = "Wallet"
        }else if(configTitle === "organization"){
            title = "Organization Profile"
        }else if(configTitle === "site_grouping"){
            title = "Site Grouping"
        }else if(configTitle === "site_productivity"){
            title = "Site Productivity"
        }else if(configTitle === "document_types"){
            title = "Document Types"
        }else if(configTitle === "designations"){
            title = "Designation"
        }else if(configTitle === "holiday"){
            title = "Holiday"
        }else if(configTitle === "currency"){
            title = "Currency"
        }else if(configTitle === "my_dashboard"){
            title = "My Dashboard"
        }else if(configTitle === "accounting_code"){
            title = "Accounting Code"
        }else if(configTitle === "workstation"){
            title = "Workstation"
        }else if(configTitle === "hrm_document_types"){
            title = "HRM - Document Types"
        }else if(configTitle === "isc_document_types"){
            title = "Inventory - Document Types"
        }else{
            title = "Additional Attribute - Site"
        }
        return (<Ax><div className="page_title row m0">
            <div className="col-3">
                <h3>Configuration</h3>
            </div>
            <div className="col-9 tap_border_left">
                <h3>{title}</h3>
                {
                    configTitle === "site"
                        ? <div className="text-end mt15">
                            <button
                                className="btn btn-primary" onClick={() => this.handleAdd()}>
                                Add
                            </button>
                        </div> : null
                }
            </div>
        </div>
        </Ax>)
    }

    loadConfigContentJsx                   =   (configName)  =>           {
        switch (configName) {
            case 'site':
                return <SiteAttributeList openForm={this.state.add} closeForm={this.handleClose}/>;
            case 'wallet':
                return <WalletConfiguration/>
            case 'organization':
                 return <OrganizationProfile/>
            case 'document_types':
                 return <DocumentTypes/>
            case 'site_grouping':
                return <SiteGrouping/>
            case 'site_productivity':
                return <SiteProductivity/>
            case 'designations':
                return <Designation/>
            case 'holiday':
                return <Holiday/>
            case 'currency':
                return <CurrencyConfiguration/>
            case 'my_dashboard':
                return <MyDashboard/>
            case 'accounting_code':
                return <AccountingCode/>
            case 'workstation':
                return <Workstation/>
            case 'hrm_document_types':
                return <HrmDocumentTypes/>
            case 'isc_document_types':
                return <IscDocumentTypes/>
            default:
                return <SiteAttributeList openForm={this.state.add} closeForm={this.handleClose}/>;
        }
    }

    //function to get configure table
    configureListTable                     =   (configRow)   =>  {
        let wallet_permission = this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-wallet")
        let site_productivity_permission = this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-site-productivity")
        return (<div style={{height:"81vh"}} className="bg-white">
            <table className="table table-hover table-bordered bg-white" >
                <tbody className="table-body">
                <tr className={configRow == "accounting_code" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/accounting_code`} role={"button"} className="link">Accounting Code</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "site" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/site`} role={"button"} className="link">Additional Attribute - Site</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "currency" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/currency`} role={"button"} className="link">Currency</Link>
                        </td>
                    </tr>
                   <tr className={configRow == "designations" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/designations`} role={"button"} className="link">Designation</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "document_types" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/document_types`} role={"button"} className="link">Document Types</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "hrm_document_types" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/hrm_document_types`} role={"button"} className="link">HRM - Document Types</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "hrm_document_types" ? "selected_config" : "white"}>
                        <td scope="row">
                
                            <Link to={`/general_configuration/isc_document_types`} role={"button"} className="link">Inventory - Document Types</Link>
                        </td>
                    </tr>
                    {/* <tr className={configRow == "holiday" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/holiday`} role={"button"} className="link">Holiday</Link>
                        </td>
                    </tr> */}
                    <tr className={configRow == "my_dashboard" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/my_dashboard`} role={"button"} className="link">My Dashboard</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "organization" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/organization`} role={"button"} className="link">Organization Profile</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "site_grouping" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/site_grouping`} role={"button"} className="link">Site Grouping</Link>
                        </td>
                    </tr>
                   {site_productivity_permission &&  <tr className={configRow == "site_productivity" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/general_configuration/site_productivity`} role={"button"} className="link">Site Productivity</Link>
                        </td>
                    </tr>}
                    {wallet_permission ? <tr className={configRow == "wallet" ? "selected_config" : "white"}>
                        <td className="master_menu_items">
                            <Link to={`/general_configuration/wallet`} role={"button"} className="link">Wallet</Link></td>
                    </tr> : null}
                    <tr className={configRow == "workstation" ? "selected_config" : "white"}>
                        <td className="master_menu_items">
                            <Link to={`/general_configuration/workstation`} role={"button"} className="link">Workstation</Link></td>
                    </tr>
                   

                </tbody>
            </table>
        </div>)
    }


    render() {
        return <ApplicationLayout>
              <Helmet><title>General Configuration</title></Helmet>
            {this.pageTitleBarJsx(this.props.match.params.config)}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl col-3'].join(' ')} >{this.configureListTable(this.props.match.params.config)}</div>
                    <div className="col-9 pl0 pr4">{this.loadConfigContentJsx(this.props.match.params.config)}</div>
            </div>
            </div>
        </ApplicationLayout>
    }
}

const mapStateToProps = state => {
    return {
        permissions             :   state.app.acl_info.permissions,
        group_permission       :   state.app.acl_info
    };
};

export default connect(mapStateToProps)(ConfigurationGeneral);