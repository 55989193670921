import React from 'react';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';



class FuelStationAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initFuelStationAdd     =           {
            name                     :   "",
            address                  :   "",
            station_type             :   "",
            site_ids                 :   [],
            fuel_types               :   [],
            remarks                  :   ""
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            form_data_loading          :       false,
            allFuelTypeList            :       [],
            allStationTypeList         :       [],
            iam_user_sites             :       [],
            addFuelStationForm         :       {...this.initFuelStationAdd},
            fuelStationData            :       null
        };

        this.fuelStationUrl         =   IAM_API_BASE_URL_2 + '/fuel_station';
    }

    componentDidMount() {
        this.initalizeFormData(this.props);
    }

    componentWillReceiveProps(nextProps){

        if (nextProps.location.state !== this.props.location.state) {
            this.initalizeFormData(nextProps)
        }
             
    }

    initalizeFormData       =       (pr)      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.fuelStationUrl + '/add_form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
            this.setState({
                iam_user_sites          :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [], 
                allFuelTypeList         :   respData && respData.fuel_types &&  respData.fuel_types.length > 0  ? respData.fuel_types.map((s) => { return {value: s.id, label: `${s.fuel_type}`}}) : [],
                allStationTypeList      :   respData && respData.station_types   &&  respData.station_types.length > 0  ? respData.station_types.map((s) => { return {value: s.key, label: `${s.name}`}}) : [],
             },() => {
               
                if(pr.location && pr.location.state && pr.location.state.editFuelStationData){
                        let fuelStationData = pr.location.state.editFuelStationData;
                        let all_sites = fuelStationData.sites && fuelStationData.sites.length > 0 &&  this.state.iam_user_sites && this.state.iam_user_sites.length > 0 &&  fuelStationData.sites.length == this.state.iam_user_sites.length ? true : false;
                       
                        this.setState({
                             addFuelStationForm     :   {
                                ...this.initFuelStationAdd,
                                name                     :   fuelStationData.fuelstation_name,
                                address                  :   fuelStationData.address,
                                station_type             :   fuelStationData.fuelstation_type,
                                all_sites                :   all_sites ? all_sites : false,
                                site_ids                 :   fuelStationData.sites && fuelStationData.sites.length > 0 && all_sites == false ? fuelStationData.sites.map(s => s.id) : [],
                                fuel_types               :   fuelStationData.fuels_prices && fuelStationData.fuels_prices.length > 0 ? fuelStationData.fuels_prices.map(f => f.fueltype_id) : [] ,
                                remarks                  :   fuelStationData.remarks,
                                id                      :    fuelStationData.id
                             },
                             fuelStationData            :   fuelStationData
                        })
                }
             })
        }).then(() => this.setState({form_data_loading: false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/fuelstation_list` })

    }

    onSelectingAllSites             =           (e,key)             =>      {
        let checkedSite = document.getElementById('all_sites').checked
        if(checkedSite == true) {
            this.setState({
                 addFuelStationForm : {...this.state.addFuelStationForm,
                   all_sites  :  true,
                   site_ids  :   [],
            }})
        }else{
           this.setState({
               addFuelStationForm : {...this.state.addFuelStationForm,
                 all_sites  :  false,
                 site_ids  :   []
          }})
        }
   }

   submitAddFuelStationForm     =   (e)     =>      {
        e.preventDefault();
        let formData = {
            ...this.state.addFuelStationForm,
            site_ids    :  this.state.addFuelStationForm.all_sites == true &&  this.state.iam_user_sites && this.state.iam_user_sites.length > 0 ? this.state.iam_user_sites.map(s => {return(s.value)}) : this.state.addFuelStationForm.site_ids,
        }

       if (this.state.fuelStationData) {
           this.setState({ saveFormSubmitting: true })
           HttpAPICall.withAthorization('PUT', this.fuelStationUrl + '/edit', this.props.access_token, {}, { ...formData }, (resp) => {
               toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.props.history.push({ pathname: `/fuelstation_list`,state: {editId : this.state.addFuelStationForm.id }})
           }).then(() => this.setState({ saveFormSubmitting: false }));
       } else {
           this.setState({ saveFormSubmitting: true })
           HttpAPICall.withAthorization('POST', this.fuelStationUrl + '/add', this.props.access_token, {}, { ...formData }, (resp) => {
               toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.setState({ addFuelStationForm: { ...this.initFuelStationAdd } })
               this.props.history.push({ pathname: `/fuelstation_list`})
           }).then(() => this.setState({ saveFormSubmitting: false }));
       }
   }

    saveFuelStationFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddFuelStationForm} id="saveFuelStationForm">
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Fuel Station Name</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="name"
                        type="text"
                        value={this.state.addFuelStationForm.name}
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addFuelStationForm")}
                        placeholder="Please enter Fuel Station Name"

                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Fuel Station Type</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect
                        options={this.state.allStationTypeList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addFuelStationForm', 'station_type');

                        }}
                        isSearchable={true}
                        value={this.state.allStationTypeList.find(s => this.state.addFuelStationForm.station_type == s.value)}
                        isClearable={true}
                        placeholder="Select Fuel Station type"
                        autoCompelete="off"
                        required={true}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Address</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="address"
                        type="text"
                        value={this.state.addFuelStationForm.address}
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addFuelStationForm")}
                        placeholder="Please enter Address"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Linked Sites</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addFuelStationForm', 'site_ids')}
                        options={this.state.iam_user_sites}
                        isSearchable={true}
                        isClearable={true}
                        required={this.state.addFuelStationForm.all_sites == true ? false : true}
                        isMulti={true}
                        isDisabled={this.state.addFuelStationForm.all_sites == true ? true : false}
                        value={this.state.iam_user_sites.filter(s => this.state.addFuelStationForm.site_ids.includes(s.value))}
                        placeholder={this.state.addFuelStationForm.all_sites == true ? "All Sites" : "Please Select Sites"}
                    />
                </div>
                <div className='col-sm-2'>
                    <div className="form-check form-check-inline">
                        <input
                            name="all_sites"
                            type="checkbox"
                            value={true}
                            onChange={(e) => { this.onSelectingAllSites(e, 'all_sites') }}
                            className="form-check-input"
                            id="all_sites"
                            checked={this.state.addFuelStationForm.all_sites == true}
                        />
                        <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                    </div>
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Fuel Type</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addFuelStationForm', 'fuel_types')}
                        options={this.state.allFuelTypeList}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isMulti={true}
                        value={this.state.allFuelTypeList.filter(s => this.state.addFuelStationForm.fuel_types.includes(s.value))}
                        placeholder={ "Please Select Fuel Type"}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Remarks</label>
                </div>
                <div className="col-sm-4 ">
                    <textarea
                        name="remarks"
                        placeholder='Enter remarks'
                        className='form-control'
                        value={this.state.addFuelStationForm.remarks}
                        onChange={(e) => this.formInputHandler(e, "addFuelStationForm")}
                        style={{ height: "65px", width: "100%" }}
                    />
                </div>
            </div>

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Fuel Station Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.fuelStationData ? 'Edit' :  'Add' } Fuel Station</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {this.state.form_data_loading ? <Loader/> :
                        this.saveFuelStationFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
        iam_user_sites      :       state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(FuelStationAdd);