import React from 'react';
import {connect} from "react-redux";
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import { ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import UpdateMyDashboardForm from './UpdateMyDashboardForm';

class MyDashboard extends AppBaseComponent {

    constructor(props) {
        super(props);

          this.state                =          {
            list_loading            :         false,
            listingData             :         null,
            
        };
        this.updateMyDashboardRef   =   React.createRef();

        this.widgetKeysFormDataUrl  =   ORG_API_BASE_URL_2  +   '/widget_keys/form_data';

    }

    componentDidMount() {
       this.loadListingTblData();
    }

    loadListingTblData = () => {
        this.setState({ list_loading: true });
        HttpAPICall.withAthorization('GET', this.widgetKeysFormDataUrl, this.props.access_token, {}, {}, (resp) => {
            this.setState({ listingData: resp.data });
        }).then(() => this.setState({ list_loading: false }));
    }

    updateDashboardDetailInit = (widgetData=null,dashboardData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,dashboardData)
    }

    render() {
        
        return (
            <section className="page_containt" style={{ padding: "0px 12px" ,  top: "1px"}}>
                <div className=" row pageTbl bg-white p-2">
                    <div className="col-sm-12">
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">My Dashboard</span> </div>
                        <table className="table table-hover table-bordered my-4 table-sm ">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "10%" }}>S.No</th>
                                    <th scope="col" className="text-start">Widget</th>
                                    <th scope="col"  style={{ width: "15%" }}>Status</th>
                                    <th scope="col"  style={{ width: "10%" }}>Sequence</th>
                                    <th scope="col"   style={{ width: "10%" }}>Action</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.list_loading ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                    : (this.state.listingData && this.state.listingData.keys &&  this.state.listingData.keys.length > 0
                                        ? (this.state.listingData.keys.map((sg, k) => {
                                            let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return(sg.key == st.key)}) ? this.props.widget_keys.find(st => {return(sg.key == st.key)})  : null;
                                            return (<tr key={k} className="text-center">
                                                <td>{k + 1}</td>
                                                <td className="text-start">{sg.name ? sg.name : "-"}</td>
                                                <td>{widgetData ? "Pinned" : "Unpinned"}</td>
                                                <td>{widgetData ? widgetData.ordo : ""}</td>
                                                <td className="p0 text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />

                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.updateDashboardDetailInit(widgetData,sg)}>Update</a></li>
                                                    </ul>
                                                </td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="5" className="text-center">No Records</td></tr>))}
                                       
                            </tbody>
                        </table>
                    </div>
                </div>
               <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
                    afterFormSubmit={() => this.loadListingTblData()}
               />
            </section>
        );
    }
}

const mapStateToProps = state => {
    
    return {
        access_token            :        state.auth.access_token,
        widget_keys             :        state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

export default connect(mapStateToProps)(MyDashboard);