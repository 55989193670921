import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


const ReportHeader     = forwardRef((props, ref) => { 
    return  <>
        <Helmet><title>{props.title}</title></Helmet>
        <div className=" page_title m0 row ">
            <div className='col-sm-12'>
                <div className='row '>
                    <div className='col-sm-4'>
                        <h3 className='mb-0'>{props.name}</h3>
                    </div>
                    <div className='col-sm-8'>
                        <div className='text-end d-flex gap-2 align-items-center justify-content-end mt15'>
                            {props.rightTopHeadingJsx ? props.rightTopHeadingJsx : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
});

export default ReportHeader;