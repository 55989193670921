import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import moment from 'moment';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import { Helmet } from 'react-helmet';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import ConsumptionDetailsModal from '../../inventory/consumption/ConsumptionDetailsModal';
import SalesInvoiceDetail from './SalesInvoiceDetail';
import DateService from '../../../services/DateService';


class SalesInVoiceList extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.initInvoiceSearchForm = {
            search_transaction_id: '',
            date_range: null,
            date_range_start: null,
            date_range_end: null,
            search_warehouse_ids: [],
            search_asset_ids: [],
            search_workstation_ids: [],
            search_voucher_number: '',
            search_item_ids: [],
            search_employee_ids: [],
        }

        this.invoiceSearchFormLabel = {
            search_transaction_id: 'Transaction ID : ',
            date_range: 'Date Range : ',
            search_warehouse_ids: 'Warehouses : ',
            search_asset_ids: 'Assets : ',
            search_workstation_ids: 'Workstations : ',
            search_voucher_number: 'Voucher Number : ',
            search_item_ids: 'Items : ',
            search_employee_ids: 'Employees : ',
        }

        this.updateCommentFormInit = {
            status: '',
            notes: '',
        }

        this.state = {
            invoiceAddScreenView: false,
            invoiceUpdateScreenView: false,
            minimizeTable: false,
            invoiceFormDataLoaded: false,
            invoiceAddFormData: null,
            listing_loading: false,
            listing_tbl_page: 1,
            invoice_listing: [],
            listingMeta: null,
            totalListingCount: 0,
            view_loading: false,
            invoiceViewData: null,
            invoiceTransactionId: '',
            isc_configuration: {},
            pdfDownloading: false,
            reportDownloading: false,
            searchedAssets: [],
            searchedItem: [],
            invoiceSearchForm: { ...this.initInvoiceSearchForm },
            submittedinvoiceFilterForm: { ...this.initInvoiceSearchForm },
            formSearchedElems: [],
            all_periods: [],
            invoiceSearchFormDataLoaded: false,
            openingUpdateScreen: false,
            searchedEmployees: [],
            addCommentForm: { ...this.updateCommentFormInit },
            allAllowedStatus: [],
            addCommentFormSubmitting: false,
            searchedWorkstation: null,

        };
        this.myRef = React.createRef();
        this.FormData_URL = INVENTORY_API_BASE_URL_2 + '/sales/invoice_form_data'
        this.INVOICE_URL = INVENTORY_API_BASE_URL_2 + '/sales/invoice';
        this.INVOICE_Details_URL = INVENTORY_API_BASE_URL_2 + '/sales/invoice/details';
        // this.listUrl = INVENTORY_API_BASE_URL_2 + '/consumptions';
    }

    componentDidMount() {

        let ParamObject = new URLSearchParams(this.props.location.search);
        this.initializeData(this.props)

        //****************After Add and update ***************************/
        if (this.props.location && this.props.location.state && this.props.location.state.transaction_id) {
            this.viewInvoiceDetail(this.props.location.state.transaction_id)
        }
        if (this.props.location && this.props.location.state && this.props.location.state.invoiceData) {
            this.setState({
                minimizeTable: true,
                invoiceViewData: this.props.location.state.invoiceData,
                invoiceTransactionId: this.props.location.state.invoiceData.transaction_id
            })
        }

        if (ParamObject.get('search_id')) {
            let id = ParamObject.get('search_id')
            this.viewInvoiceDetail(id)
        }

        this.invoiceSearchModal = new Modal(document.getElementById('invoiceSearchModal'), { keyboard: false, backdrop: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
        }
    }

    initializeData = (pr) => {
        if (pr) {
            let all_periods = [...pr.all_periods, { key: 'custom', display: 'Custom Date Range' }];
            this.setState({
                all_warehouses: pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` } }) : [],
                all_periods: all_periods,
                isc_configuration: pr.isc_configuration
            }, () => {
                let ParamObject = new URLSearchParams(this.props.location.search);

                if (ParamObject.get('date_range_start')) {
                    let updateFilterForm = {
                        date_range_start: ParamObject.get('date_range_start'),
                        date_range_end: ParamObject.get('date_range_end'),
                        date_range: "custom"
                    };


                    if (ParamObject.get('search_warehouse_ids')) {
                        let warehouse = this.props.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                        if (warehouse) {
                            updateFilterForm['search_warehouse_ids'] = [warehouse.id];
                        }
                    }

                    setTimeout(() => {
                        this.setState({
                            invoiceSearchForm:
                                { ...this.state.invoiceSearchForm, ...updateFilterForm }
                        }, () => {
                            this.submitinvoiceSearchForm()
                        });
                    }, 1000)

                } else {
                    this.loadListingTblData(1);
                }

            });
        }
    }

    //************************GET Invoice LISTING**************** */
    loadListingTblData = (page = 1) => {
        this.setState({ listing_loading: true, listing_tbl_page: page });
        let params = { page: page, ...this.state.submittedinvoiceFilterForm };
        HttpAPICall.withAthorization('GET', this.INVOICE_URL + '/list', this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                invoice_listing: respData.data,
                listingMeta: respData.meta,
                // totalListingCount: respData.meta.total,
            });
        }).then(() => this.setState({ listing_loading: false }));
    }


    //************************InVoice SEARCH **********************/
    invoiceSearchModalInit = () => {
        this.invoiceSearchModal.show();
        let all_periods = [...this.props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        if (!this.state.invoiceSearchFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.FormData_URL, this.props.access_token, null, null, (response) => {
                this.setState({
                    invoiceSearchFormDataLoaded: true,
                });
            });
        }
    }

    submitinvoiceSearchForm = (e) => {
        e && e.preventDefault()
        let serachFormKeys = Object.keys(this.state.invoiceSearchForm);

        let searchedElems = [];
        let searchParams = {};
        serachFormKeys.map((key) => {

            let label = this.invoiceSearchFormLabel[key];
            let value = this.state.invoiceSearchForm[key];

            if (value && value.length > 0) {
                searchParams[key] = value;
                if (label) {
                    let show_val = value;
                    if (key == 'search_workstation_ids') {
                        show_val = this.state.searchedWorkstation ? this.state.searchedWorkstation.label : '';
                    }
                    if (key == 'search_warehouse_ids') {
                        show_val = this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_transaction_id') {
                        show_val = this.state.invoiceSearchForm ? this.state.invoiceSearchForm.search_transaction_id : '';
                    }
                    if (key == 'search_voucher_number') {
                        show_val = this.state.invoiceSearchForm ? this.state.invoiceSearchForm.search_voucher_number : '';
                    }
                    if (key == 'search_item_ids') {
                        show_val = this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }

                    if (key == 'search_asset_ids') {
                        show_val = this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'search_employee_ids') {
                        show_val = this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if (key == 'date_range') {
                        let start_range = moment(this.state.invoiceSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.invoiceSearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }

                    searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedinvoiceFilterForm: { ...this.state.invoiceSearchForm },
            formSearchedElems: searchedElems
        }, () => {
            this.invoiceSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    invoiceSearchClear = () => {
        this.setState({
            formSearchedElems: [],
            searchedAssets: [],
            searchedEmployees: [],
            searchedItem: [],
            searchedWorkstation: null,
            invoiceSearchForm: { ...this.initInvoiceSearchForm },
            submittedinvoiceFilterForm: { ...this.initInvoiceSearchForm }
        }, () => {
            this.loadListingTblData(1);
            this.props.history.push('/sales_invoice_list')
            this.invoiceSearchModal.hide();
        });
    }

    invoiceClear = () => {
        this.setState({
            formSearchedElems: [],
            searchedAssets: [],
            searchedItem: [],
            searchedEmployees: [],
            searchedWorkstation: null,
            invoiceSearchForm: { ...this.initInvoiceSearchForm },
            submittedinvoiceFilterForm: { ...this.initInvoiceSearchForm }
        }, () => {
            this.invoiceSearchModal.hide();
            this.props.history.push('/sales_invoice_list')
        });
    }


    openAddInvoiceScreen = () => {
        this.props.history.push('/sales_invoice_list/add')
    }

    cloneInvoiceScreenInit = () => {
        this.props.history.push({ pathname: `/sales_invoice_list/add`, state: { invoiceData: this.state.invoiceViewData, clone: 'yes' } })
    }


    viewInvoiceDetail = (transaction_id) => {
        this.setState({ minimizeTable: true })
        this.getInvoiceViewDetails(transaction_id)

    }

    //************************GET Invoice DETAILS**************** */
    getInvoiceViewDetails = (transaction_id) => {
        // if(transaction_id !== this.state.invoiceTransactionId){
        this.setState({ view_loading: true })
        HttpAPICall.withAthorization('GET', this.INVOICE_Details_URL + '/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                invoiceViewData: respData,
                invoiceTransactionId: respData.transaction_id,
                allAllowedStatus: respData && respData.change_status_list && respData.change_status_list.length > 0 ? respData.change_status_list.map((s) => { return { value: s.id, label: s.name }; }) : [],
                addCommentForm: { ...this.updateCommentFormInit, status: respData.status.id }
            });
           
        }, (err) => {
            if (err) {
                this.setState({ minimizeTable: false })
            }
        }).then(() => this.setState({ view_loading: false }));
        // }
    }

    closeViewDetails = () => {
        this.setState({ minimizeTable: false })
    }

    //***********************DOWNLOAD PDF **************************** */
    downloadPdfHandler = (transaction_id) => {
        this.setState({ pdfDownloading: true });
        HttpAPICall.withAthorization('GET', this.INVOICE_URL + '/' + transaction_id, this.props.access_token, { download: "download" }, {}, (response) => {
            download.file(response.data.file_path);
        })
            .then(() => this.setState({ pdfDownloading: false }));
    }

    //***********************DELETE  **************************** */
    deleteInvoice = (transaction_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.INVOICE_URL + '/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({ minimizeTable: false })
                        this.loadListingTblData(1)
                    }).then(() => {

                    });
                }
            });
    }


    //***********************DOWNLOAD EXCEL **************************** */
    invoiceDownloadHandler = () => {
        let params = { ...this.state.submittedinvoiceFilterForm };
        if (JSON.stringify(this.state.submittedinvoiceFilterForm) !== JSON.stringify(this.initInvoiceSearchForm)) {
            this.invoiceDownloadStart(params);
        } else {
            swal({
                title: "Download",
                text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
                icon: "warning",
                buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = { ...params, date_range: "current_quarter" }
                    this.invoiceDownloadStart(params);
                }
            });
        }
    }

    invoiceDownloadStart = (params) => {
        this.setState({ reportDownloading: true });
        HttpAPICall.withAthorization('GET', TapApiUrls.INVENTORY + '/report/item-wise-consumption', this.props.access_token, { ...params, download: 'download' }, {}, (response) => {
            let respData = response.data
            let file_path = respData && respData.file_path ? respData && respData.file_path : '';
            let message = respData && respData.msg ? respData && respData.msg : '';
            let navigation = respData && respData.navigation ? respData && respData.navigation : '';
            download.file(file_path, message, navigation, this.props);
        }).then(() => this.setState({ reportDownloading: false }));
    }


    //***********************OPEN UPDATE Invoice SCREEN****************
    openUpdateInvoiceScreen = (id) => {

        this.setState({ openingUpdateScreen: true });

        if (this.state.invoiceViewData && this.state.invoiceViewData.transaction_id == id) {
            this.setState({ openingUpdateScreen: false });
            this.props.history.push({ pathname: `/sales_invoice_list/update`, state: { invoiceData: this.state.invoiceViewData } })
        } else {
            HttpAPICall.withAthorization('GET', this.INVOICE_URL + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.props.history.push({ pathname: `/sales_invoice_list/update`, state: { invoiceData: respData } })
                this.setState({ openingUpdateScreen: false });
            });
        }
    }

    updateComment = () => {
        this.myRef.current.scrollIntoView()
    }

    submitUpdateCommentForm = (e) => {
        e.preventDefault()
        this.setState({ addCommentFormSubmitting: true })
        let frmData = {
            ...this.state.addCommentForm,
            transaction_id: this.state.invoiceTransactionId
        }
        HttpAPICall.withAthorization('PUT', this.INVOICE_URL + '/comment_add', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getInvoiceViewDetails(this.state.invoiceTransactionId);
            this.loadListingTblData(1)
            this.setState({ addCommentForm: { ...this.updateCommentFormInit } })
        }).then(() => this.setState({ addCommentFormSubmitting: false }));
    }


    invoiceListjsx = () => {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3> Sales InVoice</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax> <button type="button" className="btn btn-primary"
                            // disabled={!this.props.permissions.includes('isc-invoice-add')}
                            onClick={this.openAddInvoiceScreen}>New Sales InVoice</button>
                            {/* <button type="button" className="btn btn-secondary"
                                disabled={this.state.reportDownloading || this.state.listing_loading}
                                onClick={this.invoiceDownloadHandler} >
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button> */}
                        </Ax>
                        : null
                    }
                    <button type="button" className="btn btn-secondary" onClick={this.invoiceSearchModalInit} disabled={this.state.reportDownloading || this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable
                        // && this.props.permissions.includes('isc-invoice-add')
                        ? <span className="dropdown">
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="#" onClick={this.openAddInvoiceScreen}>New InVoice</a></li>
                            </ul>
                        </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>InVoice : {this.state.invoiceTransactionId}</h3>
                            <div className="text-end mt15">
                                <button disabled={this.state.invoiceViewData?.transaction_type == "purchase_consumption" ? true : false || this.state.view_loading} title={this.state.invoiceViewData?.transaction_type == "purchase_consumption" ? 'This consumption is logged along with purchase. Hence it can only be edited from purchase voucher.' : ''} style={{ "pointer-events": "all" }}
                                    type="button" className="btn btn-secondary" onClick={() => this.updateComment()} >Update Status </button>
                                <button disabled={this.state.invoiceViewData?.transaction_type == "purchase_consumption" ? true : false || this.state.view_loading ? true : false || this.state.invoiceViewData?.can_edit == 'N' ? true : false || this.props.permissions.includes('isc-consumption-edit') ? false : true} title={this.state.invoiceViewData?.transaction_type == "purchase_consumption" ? 'This consumption is logged along with purchase. Hence it can only be edited from purchase voucher.' : ''} style={{ "pointer-events": "all" }}
                                    type="button" onClick={() => this.openUpdateInvoiceScreen(this.state.invoiceTransactionId)} className="btn btn-secondary">Edit {this.state.openingUpdateScreen ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>

                                {/* <button type="button" className="btn btn-secondary" disabled={this.state.pdfDownloading || this.state.view_loading} onClick={() => this.downloadPdfHandler(this.state.invoiceTransactionId)}>
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />{this.state.pdfDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button> */}
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a role="button" href
                                            onClick={() => {
                                                if (this.state.invoiceViewData?.transaction_type !== "purchase_consumption") {
                                                    return this.cloneInvoiceScreenInit();
                                                }
                                            }}
                                            className={['dropdown-item', this.state.invoiceViewData?.transaction_type !== "purchase_consumption" ? '' : 'disabled'].join(' ')}
                                            title={this.state.invoiceViewData?.transaction_type == "purchase_consumption" ? 'This consumption is logged along with purchase. Hence it can only be cloned from purchase voucher.' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Clone</a>
                                    </li>
                                    <li>
                                        <a role="button" href
                                            onClick={() => {
                                                if (this.state.invoiceViewData?.transaction_type !== "purchase_consumption" || this.props.permissions.includes('isc-consumption-delete')) {
                                                    return this.deleteInvoice(this.state.invoiceTransactionId);
                                                }
                                            }}
                                            className={['dropdown-item', this.state.invoiceViewData?.transaction_type !== "purchase_consumption" || this.props.permissions.includes('isc-consumption-delete') ? '' : 'disabled'].join(' ')}
                                            title={this.state.invoiceViewData?.transaction_type == "purchase_consumption" || !this.props.permissions.includes('isc-consumption-delete') ? 'This consumption is logged along with purchase. Hence it can only be edited from purchase voucher.' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Delete</a>
                                    </li>

                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.invoiceListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.invoiceViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    invoiceListingTableJsx = () => {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.invoiceSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>InVoice</th>
                        </tr>)
                        : (<tr className='text-center'>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "10%" }} className='text-start'> Transaction ID</th>
                            <th scope="col" style={{ width: "10%" }}>Date</th>
                            <th scope="col" style={{ width: "15%" }} className='text-start'>Warehouse</th>
                            <th scope="col" style={{ width: "25%" }} className='text-start'>InVoice For</th>
                            <th scope="col" style={{ width: "10%" }}>Total Quantity</th>
                            <th scope="col" style={{ width: "10%" }} className='text-end'>Amount</th>
                            <th scope="col" style={{ width: "10%" }} >Status</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.invoice_listing.length > 0
                            ? (this.state.invoice_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewInvoiceDetail(item.transaction_id)}>{item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : "-"} </a>
                                                        <div className="mt-1">
                                                            <small> <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>{item.warehouse?.name ?? "-"}</span> </small>
                                                            <small className="float-end">{item.status ? item.status.name : "-"}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{index+1}</td>
                                                <td><a role="button" className="link-primary" onClick={() => this.viewInvoiceDetail(item.transaction_id)}>{item.transaction_id ? item.transaction_id : "-"}</a></td>
                                                <td className="text-center"> {item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : "-"}</td>
                                                <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                    : "-"}</td>
                                                <td className='text-capitalize'>
                                                    {item.invoice_for === 'asset' && <div><small className='form-text'>Asset: </small>{item?.assetProfile?.name}</div>}

                                                    {item.invoice_for === 'workstation' && <div><small className='form-text'>Workstation: </small>{item?.workstation?.workstation_name}</div>}

                                                    {item.invoice_for === 'employee' && <div><small className='form-text'>Employee: </small>{item?.employee?.name}</div>}

                                                    {item.invoice_for === 'sub_assembly' && <div><small className='form-text'>Sub Assembly: </small>{item?.subAssembly?.name}</div>}
                                                </td>
                                                <td className='text-center'>{item?.total_qty ? item?.total_qty : 0}</td>
                                                <td className='text-end'>{item?.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(item?.total_amount).toFixed(2)} /> : 0}</td>
                                                <td className='text-center'>{item.status ? item.status.name : "-"}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewInvoiceDetail(item.transaction_id)}>View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    invoiceViewDetails = () => {
        let invoice = this.state.invoiceViewData;
        return (<div className="tab_content_header">
            <SalesInvoiceDetail invoiceData={this.state.invoiceViewData} />
            <hr />
            {invoice && invoice.transaction_type !== 'purchase_consumption'
                ? <Ax>
                    <div>
                        <div className="tab_content_wrapper" ref={this.myRef}>
                            <span className="content_heading">Comments & History</span>
                        </div>
                        <form onSubmit={this.submitUpdateCommentForm}>
                            <div className="my-3 row">
                                <label className="col-sm-3 col-form-label">Change status to</label>
                                <div className="col-sm-5">
                                    <TapSelect
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                        }}
                                        value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                        options={this.state.allAllowedStatus}
                                        isSearchable={false}
                                        isDisabled={!this.props.permissions.includes('isc-consumption-status-change')}
                                        placeholder="Please Select Status"
                                    />
                                </div>
                            </div>
                            <div className="my-2">
                                <label className="form-label">Comments</label>
                                <textarea
                                    name="notes"
                                    value={this.state.addCommentForm.notes}
                                    onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                    className="form-control"
                                    placeholder="Please Add a comment"
                                    required
                                    style={{ height: "100px" }}
                                />
                            </div>
                            <div className="col-12 text-end">
                                <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                        <hr />
                        <div className="my-1">
                            {
                                invoice.comments && invoice.comments.length > 0 ? invoice.comments.map((c, k) => {
                                    return (<Ax key={k}><div className="row">
                                        {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                        <div className="col-sm-4 text-end">
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                            <span> {c.created_at_display}</span>
                                        </div>
                                        <div className="col-sm-8 my-1">{c.notes}</div>
                                    </div><hr /></Ax>);
                                })
                                    : null
                            }
                        </div>

                    </div>
                </Ax>
                : null}
        </div>)
    }

    //***********************SEARCH invoice JSX****************
    invoiceSearchModalJsx = () => {

        return (
            <div className="modal fade" id="invoiceSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">InVoice Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="invoiceSearchForm" onSubmit={this.submitinvoiceSearchForm}>
                            {!this.state.invoiceSearchFormDataLoaded ? <Loader />
                                : <div className="modal-body">
                                    <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_transaction_id"
                                                type="text"
                                                value={this.state.invoiceSearchForm.search_transaction_id}
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "invoiceSearchForm")}
                                                placeholder="Please enter Transaction ID"
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm  ">Voucher Number</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_voucher_number"
                                                type="text"
                                                value={this.state.invoiceSearchForm.search_voucher_number}
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "invoiceSearchForm")}
                                                placeholder="Please enter Voucher Number"
                                                style={{ height: "30px", fontSize: "93%" }}
                                            />
                                        </div>


                                    </div>
                                    <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Warehouse</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                options={this.state.all_warehouses}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_warehouse_ids')}
                                                value={this.state.all_warehouses.filter(u => this.state.invoiceSearchForm?.search_warehouse_ids?.includes(u.value))}
                                                isSearchable={true}
                                                isMulti={true}
                                                isClearable={true}
                                                placeholder="Select Warehouses"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Asset</label>
                                        <div className="col-sm-10">
                                            <InputAssetSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_asset_ids');
                                                    this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                isMulti={true}
                                                menuPlacement="top"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>

                                    </div>
                                    <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Workstation</label>
                                        <div className="col-sm-10">
                                            <InputWorkstationSearch
                                                placeholder="Search Workstation"
                                                changeEvent={(selectedOption) => {
                                                    this.setState({
                                                        searchedWorkstation: selectedOption ? selectedOption : '',
                                                        invoiceSearchForm: { ...this.state.invoiceSearchForm, search_workstation_ids: selectedOption ? [selectedOption.value] : [] }
                                                    });
                                                    // this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_workstation_ids')
                                                }}
                                                menuPlacement="top"
                                                isMulti={false}
                                                value={this.state.searchedWorkstation && this.state.searchedWorkstation}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label">Employees</label>
                                        <div className="col-sm-10">
                                            <InputEmployeeSearch
                                                changeEvent={(selectedOption) => {

                                                    this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_employee_ids');
                                                    this.setState({
                                                        invoiceSearchForm: { ...this.state.invoiceSearchForm, search_employee_ids: selectedOption.length > 0 ? selectedOption.map(o => o.enc_id) : [] },
                                                        searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                    });
                                                }}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                placeholder="Select Employee"
                                                value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                        <div className="col-sm-10">
                                            <InputItemSearch
                                                placeholder="Search Item"
                                                isMulti={true}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_item_ids');
                                                    this.setState({
                                                        searchedItem: selectedOption ? selectedOption : null
                                                    }, () => { });
                                                }}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>

                                    </div>
                                    <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                        <div className="col-sm-10">
                                            <PeriodSelectionWithCustom
                                                periods={this.state.all_periods ? this.state.all_periods : []}
                                                value={this.state.invoiceSearchForm.date_range}
                                                startDate={this.state.invoiceSearchForm.date_range_start}
                                                endDate={this.state.invoiceSearchForm.date_range_end}
                                                onSelectPeriod={(period, startDate, endDate) => {
                                                    this.setState({
                                                        invoiceSearchForm
                                                            : {
                                                            ...this.state.invoiceSearchForm,
                                                            date_range: period,
                                                            date_range_start: startDate ? startDate : null,
                                                            date_range_end: endDate ? endDate : null
                                                        }
                                                    });
                                                }}
                                                className="form-control-sm"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.invoiceClear}>Clear</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>InVoice Listing</title></Helmet>
            {this.invoiceListjsx()}
            {this.invoiceSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_periods: state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses: state.app && state.app.warehouses ? state.app.warehouses : [],
        default_currency: state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(SalesInVoiceList);