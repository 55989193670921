import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import { DisplaySearchCriteria, PeriodSelectionWithCustom } from "../../../components/TapUi";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import HttpAPICall from "../../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import TapIcon from "../../../services/TapIcon";
import DownloadFile from "../../../services/DownloadFile";
import { Collapse } from "bootstrap";
import moment from "moment";
import TapSelect from "../../../components/ui/TapSelect";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";

class SiteWiseProductivityReport extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
            site_group_ids                      :   [],
            search_site_ids                     :   [],
            asset_group_ids                     :   [],
            search_asset_type_ids               :   [],
            search_asset_matrix_ids             :   [],
            search_activities                   :   [],
            search_units                        :   [],
            search_material_ids                 :   [],
            search_attachment_ids               :   [],
            date_range                          :   'current_month',
            date_range_start                    :   '',
            date_range_end                      :   '',
        }

        this.reportFilterFormLabel          =   {
            site_group_ids                      :   'Site Groups : ',
            search_site_ids                     :   'Sites : ',
            asset_group_ids                     :   'Asset Type Groups : ',
            search_asset_type_ids               :   'Asset Type : ',
            search_asset_matrix_ids             :   'Asset Matrix : ',
            search_activities                   :   'Activities : ',
            search_units                        :   'Units : ',
            search_material_ids                 :   'Materials : ',
            search_attachment_ids               :   'Attachment : ',
            date_range                          :   'Date Range : ',
            
        };

        this.state                          =   {
            downloadReportBtn                   :   false,
            reportDownloading                   :   false,
            listing_loading                     :   false,
            show_element                        :   false,
            filter_loading                      :   false,
            listingData                         :   [],
            filterFormData                      :   null,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listingMeta                         :   null,
            manufacturers                       :   [],
            iam_user_sites                      :   [],
            searchedAssets                      :   [],
            allSiteGroupsList                   :   [],
            productivityKeysList                :   [],
            siteListing                         :   [],
            allAssetTypesGroupsList             :   [],
            activities                          :   [],
            units                               :   [],
            materials                           :   [],
            attachments                         :   [],
            iam_asset_matrix                    :   [],
            iam_asset_types                     :   [],
            currentPage                         :    1,
            keysPerPage                         :   10,
            reportData                          :   null,                  

        };
        this.productivityUrl                  =   IAM_API_BASE_URL_2 + '/report/productivity-report';

    }

    componentDidMount   ()  {
        this.setState({
            filter_loading         :   true,
        })
        setTimeout(() => {
            this.initializeData(this.props);  
        }, 1000); 
    }

    initializeData  =   (pr)  =>  {
       
        const report_name   =   'site_wise_productivity_report';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })

        HttpAPICall.withAthorization('GET', this.productivityUrl, this.props.access_token, {action:"formData"}, {}, (response) => {
            let respData = response.data;
            this.setState({
                iam_user_sites      :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                iam_asset_types         :   pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                iam_asset_matrix        :   pr.iam_asset_matrix ? pr.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
                allAssetTypesGroupsList :   pr.asset_groups && pr.asset_groups.length > 0 ? pr.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                allPeriods              :   pr.all_periods,
                formDataLoaded          : true,
                activities              : respData.activity_name ? respData.activity_name.map((s) => { return {value: s.id, label: s.activity_name}}) : [],
                units                   : respData.uom ? respData.uom.map((s) => { return {value: s.id, label: s.measuring_unit}}) : [],
                materials               : respData.materials ? respData.materials.map((s) => { return {value: s.id, label: s.material_name}}) : [],
                attachments             : respData.attachments ? respData.attachments.map((s) => { return {value: s.id, label: s.name}}) : [],
                allSiteGroupsList       :   pr.site_groups && pr.site_groups.length > 0 ? pr.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                reportFilterForm        :   {...this.state.reportFilterForm, date_range: 'current_month'},
            }, ()=>{
                this.setState({
                    filter_loading : false, 
                });
                this.reportFilterhandler();
            });
         }, (err) => {});
        
    }

    loadListData    =   (page = 1)  =>  {
        this.setState({
            listing_loading             :   true,
            // listingData                 :   [],
            // listingMeta                 :   null,
             reportDownloading           :   false
        });
        let params                      =   {page:page, ...this.state.submittedReportFilterForm, per_page : 25, action:"site_wise_data"};
        HttpAPICall.withAthorization('GET', this.productivityUrl, this.props.access_token, params, {}, (res) => {
            let respData                =   res.data;
            this.setState({
                listingData                 :   respData,
                //show_element                :   true,
                productivityKeysList        :   respData.production_keys,
                siteListing                 :   respData.sites  && respData.sites.length > 0 ? respData.sites : [] ,
               
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownload  =   (row='')  =>  {
        this.setState({
            reportDownloading   :   true,
        });
        const params    =   {action : 'site_wise_download',row : row,...this.state.submittedReportFilterForm}
        HttpAPICall.withAthorization('GET', this.productivityUrl, this.props.access_token, params , {} , (res) => 
        DownloadFile.file(res.data.file_path))
        .then(() => this.setState({reportDownloading: false}));
    }

    

    pageTitleBar            =   ()  =>  {
        return (
            <div className="page_title row m0">
                <div className="col-sm-12">
                    <h3>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faCubes} /> Site Wise Productivity Report 
                    </h3>
                    <div className="text-end mt15">
                        <button type="button"  className="btn btn-secondary" onClick={this.reportFilterhandler}>Change Filter</button>
                        {this.state.show_element && <Ax>
                            <button type="button" id='actionMenuButton' disabled={this.state.listing_loading || this.state.reportDownloading} data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary ms-1">
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                        {this.state.reportDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')}  onClick={() => this.reportDownload('site')}>Excel Download (Row: Site, Column: Activity)</Link></li>
                        <li><Link className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')} onClick={() => this.reportDownload('activity')}>Excel Download (Row: Activity, Column: Site)</Link></li>
                    </ul></Ax>}
                        
                        <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    reportFilterFormSubmit  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            =   this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activities') {
                        show_val            =   this.state.activities.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_units') {
                        show_val            =   this.state.units.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_material_ids') {
                        show_val            =   this.state.materials.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_attachment_ids') {
                        show_val            =   this.state.attachments.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'date_range') {
                        let  start_range        =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range           =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY');
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`;
                        show_val                =     this.state.allPeriods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems,
            show_element                        :   true,
        }, () => {
            this.loadListData(1);
        });
    }

    reportFilterhandler     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    handleClickNext = (page = 1) => {
          let {currentPage} = this.state;
          this.setState({currentPage : page})
      };
    
      

    reportFilterFormJsx     =   ()  =>  {
        return (
                <div id="reportFilterForm" className="bg-white collapse" >
                    <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                       
                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end">Site Group</label>
                        <div className="col-sm-6 align-items-center">
                            <TapSelect
                                options={this.state.allSiteGroupsList}
                                changeEvent={(selectedOption) => {

                                    this.setState({
                                        reportFilterForm: {
                                            ...this.state.reportFilterForm,
                                            site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                            search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                        }
                                    })
                                }}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                                placeholder="Select Site Group"
                            />
                        </div>
                    </div>
                        <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end">Site Name</label>
                            <div className="col-sm-6">
                                <TapSelect
                                    options={this.state.iam_user_sites}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm?.search_site_ids?.includes(s.value))}
                                    placeholder="Select Sites"
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_matrix_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.reportFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Activities</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.activities}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_activities')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.activities.filter(s => this.state.reportFilterForm.search_activities.includes(s.value))}
                            placeholder="Select Activities"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Units</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.units}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_units')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.units.filter(s => this.state.reportFilterForm.search_units.includes(s.value))}
                            placeholder="Select Units"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Materials</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.materials}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_material_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.materials.filter(s => this.state.reportFilterForm.search_material_ids.includes(s.value))}
                            placeholder="Select Materials"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Attachments</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.attachments}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_attachment_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.attachments.filter(s => this.state.reportFilterForm.search_attachment_ids.includes(s.value))}
                            placeholder="Select Attachments"
                        />
                    </div>
                </div>

                        <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end">Select Period <span className="text-danger">*</span></label>
                            <div className="col-sm-6">
                                <PeriodSelectionWithCustom
                                    periods={this.state.allPeriods ? this.state.allPeriods : []}
                                    value={this.state.reportFilterForm.date_range}
                                    startDate={this.state.reportFilterForm.date_range_start}
                                    endDate={this.state.reportFilterForm.date_range_end}
                                    onSelectPeriod={(period, startDate, endDate) => {
                                        this.setState({
                                            reportFilterForm
                                                : {
                                                ...this.state.reportFilterForm,
                                                date_range: period,
                                                date_range_start: startDate ? startDate : null,
                                                date_range_end: endDate ? endDate : null
                                            }
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                        </div>  
                        <div className="row">
                            <div className="pb-3 col-9 text-end">
                                <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                            </div>
                            <hr />
                        </div>
                    </form>
                </div>
        );
    }

    productivityReportJsx  =   ()  =>  {
        
        const { currentPage, keysPerPage } = this.state;
        const startIndex = (currentPage - 1) * keysPerPage;
        const endIndex = startIndex + keysPerPage;
        const visibleKeys = this.state.productivityKeysList.slice(startIndex, endIndex);
        return (<Ax>
          
            <table className="table table-bordered table-sm align-middle bg-white table-fixed" id="SiteWiseProductivityTable">
                <thead className="table-secondary">
                </thead>
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: "5%" }}>S No.</th>
                        <th className="text-center" style={{ width: "15%" }}>Site Name</th>
                        <th className="text-center" style={{ width: "15%" }}>Site Code</th>
                        {visibleKeys && visibleKeys.length > 0
                            && visibleKeys.map((header, key) => { return (<th scope="col" key={key} >{header.prod_display}</th>) })}
                    </tr>
                    <tr>
                        <th className="text-center" style={{ width: "5%" }} colSpan={3}></th>
                        {visibleKeys && visibleKeys.length > 0
                            && visibleKeys.map((header, key) => { return (<th scope="col" key={key} >{header.unit}</th>) })}
                    </tr>
                </thead>
                <tbody>
                    {this.state.siteListing && this.state.siteListing.length > 0 ? this.state.siteListing.map((siteData, k) => {
                        return (<tr>
                            <td className="text-center">{ k + 1}</td>
                            <td >{siteData.site ? siteData.site : ""}</td>
                            <td>{siteData.site_code ? siteData.site_code : ""}</td>
                            {visibleKeys && visibleKeys.length > 0
                                && visibleKeys.map((header, key) => {
                                    let production = siteData && siteData.productions && siteData.productions.length > 0 ? siteData.productions.find(production => { return production.key == header.key }) : null;
                                    return (<td scope="col" className="text-center" key={key} >{production && production.total_production ? production.total_production : ""}</td>)
                                })}
                        </tr>)
                    }) : <tr className="text-center">
                        <td colSpan={6}>No Record</td>
                    </tr>}

                </tbody>
            </table>
        </Ax>
        )
    }

    closeReport         =   ()  =>  {
        this.props.history.push('/report')
    }

    render  =   ()  =>  {
        const { currentPage, keysPerPage,productivityKeysList } = this.state;
        const startIndex = (currentPage - 1) * keysPerPage;
        const endIndex = startIndex + keysPerPage;
        const visibleKeys = this.state.productivityKeysList.slice(startIndex, endIndex);
        let keysLength = productivityKeysList && productivityKeysList.length > 0 ? productivityKeysList.length / keysPerPage : 1
       
        return (
            <ApplicationLayout> 
                <Helmet><title>Site wise Productivity - Report</title></Helmet>
                {this.pageTitleBar()}
                <div className="container-fluid pl5">
                    <div className="page_containt row">
                    {this.state.filter_loading && <Loader/>}
                        <div id="pageTbl" className="pageTbl col-12">
                            {this.reportFilterFormJsx()}
                            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} /> 
                            {this.state.listing_loading ? <Loader/> : this.state.siteListing && this.state.siteListing.length > 0 && this.productivityReportJsx()}
                           {this.state.productivityKeysList && this.state.productivityKeysList.length > 0 && <div className="row" style={{ zIndex: 999 }}>
                                
                                <div className="col">
                                     <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        pageCount={keysLength}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={0}
                                        forcePage={(parseInt(currentPage) - 1)}
                                        onPageChange={(e) => this.handleClickNext(e.selected + 1)}
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination justify-content-end"
                                        pageClassName="page-item"
                                        nextClassName="page-item"
                                        previousClassName="page-item"
                                        pageLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        activeClassName="active"
                                    /> 
                                </div>
                            </div>}
                            
                          
                        </div>
                    </div>
                </div>
            </ApplicationLayout>
        );
    };

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SiteWiseProductivityReport);