import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import TapApiUrls, { ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import Ax from "../../../components/hoc/Ax";
import {toast} from "react-toastify";
import Status from "../../../components/ui/Status";
import React from "react";


class Attachement extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigForm      =   {
            runtime_attachment_creation   :       'N',
        };

        this.state                              =   {
            generalConfigloading                :      false,
            general_config_list                 :      null,
            generalConfigForm                   :     {...this.initGeneralConfigForm},
            editFormSubmiting                   :      false,
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';
    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
   }

    getGeneralSettingListing = () => {
        let params = {
            keys : [ 'runtime_attachment_creation'],
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ general_config_list: resp.data.data })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    editGeneralConfigurationInit = () => {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
              }
              
            this.setState({generalConfigForm})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...this.state.generalConfigForm} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    editGeneralConfigurationModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                            
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Allow creation of Attachment from transaction</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="runtime_attachment_creation"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.runtime_attachment_creation == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="runtime_attachment_creation"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.runtime_attachment_creation == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                              
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
  }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
        return (<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                </tr>
            </thead>
            <tbody>
                
                <tr className="text-center">
                    <td>1</td>
                    <td className="text-start"> Allow creation of Attachment from transaction</td>
                    <td>{data && data.runtime_attachment_creation && data.runtime_attachment_creation == 'Y' ? "Yes" : "No"}</td>
                </tr>
              
           
            </tbody>
        </table>)
    }

    render                                  =     ()           =>     {
        return (<section className="page_containt " style={{ top: "1px" }}>
            <div className="pageTbl bg-white">
                <div className="p-2">
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                        <div className="text-end "><button className="btn btn-primary" disabled={!this.props.permissions.includes('isc-configuration-edit')} onClick={this.editGeneralConfigurationInit}>Edit </button></div>
                    </div>
                    {this.state.generalConfigloading ? <Loader />
                        : this.generalConfigurationJsx()}
                    {this.editGeneralConfigurationModalJsx()}
                </div>

            </div>
        </section>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(Attachement);