import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from "../../../components/ui/Status";
import ItemwiseScrapAdd from "./ItewiseScrapAdd";


class ItemwiseScrapList extends React.Component {

    constructor(props) {
        super(props);

        this.state                         =     {
            itemwiseScrapAddScreenView          :     false,
            itemwiseScrapUpdateScreenView       :     false,
            minimizeTable                   :    false,
        };
    }

    componentDidMount() {

    }

    openAddItemwiseScrapScreen       =      ()      =>     {
        this.setState({itemwiseScrapAddScreenView:true})
    }

    addItemwiseScrapScreenClose      =      ()       =>     {
        this.setState({itemwiseScrapAddScreenView:false})
    }

    viewItemwiseScrapDetail          =      ()        =>     {
        this.setState({minimizeTable  :   true})
    }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    itemwiseScrapListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Itemwise Scrap</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button"  className="btn btn-primary" onClick={this.openAddItemwiseScrapScreen}>Dispose Scrap</button> : null}
                    <button type="button"  className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action"/>
                    </button>
                    <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {this.state.minimizeTable   ? <li><a className="dropdown-item"  href="#">Dispose Scrap</a></li>:null}
                                </ul>
                            </span>
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Itemwise Scrap : </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.itemwiseScrapListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4 pageTbl bg-white"><div className=" p-2 ">{this.itemwiseScrapViewDetails()}</div></div> : null }
                </div>
            </div>
        </Ax>);
    }

    itemwiseScrapListingTableJsx            =   ()   =>   {
        return(<Ax>
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                { this.state.minimizeTable
                    ? (<tr>
                        <th scope="col" style={{width:"15%"}}>Transaction ID (Date)</th>
                    </tr> )
                    :   ( <tr className="text-center">
                        <th scope="col" style={{width:"5%"}}><input type="checkbox"/></th>
                        <th scope="col" style={{width:"10%"}}>Date</th>
                        <th scope="col" style={{width:"10%"}}>Transaction ID</th>
                        <th scope="col" style={{width:"20%"}}> Warehouse</th>
                        <th scope="col" style={{width:"20%"}}>Item Name</th>
                        <th scope="col" style={{width:"10%"}}>Code</th>
                        <th scope="col" style={{width:"10%"}}>Quantity</th>
                        <th scope="col" style={{width:"10%"}}>Status</th>
                        <th scope="col" style={{width:"5%"}}>Action</th>
                    </tr> )
                }
                </thead>
                <tbody>
                { this.state.minimizeTable
                    ? (<tr>
                        <td scope="col" style={{width:"15%"}}>1234 (16-11-2021)</td>
                    </tr> )
                    :( <tr className="text-center">
                        <td><input type="checkbox"/></td>
                        <td>16-11-2021</td>
                        <td><a role="button"  className="link-primary">123444</a></td>
                        <td>Gurgaon</td>
                        <td>123</td>
                        <td>Mnb</td>
                        <td>300</td>
                        <td><Status>Inhand</Status></td>
                        <td>
                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                               alt="action-icon"
                                               className="img-fluid pr10 pl10"
                                               data-bs-toggle="dropdown" href="#"
                                               role="button" aria-expanded="false"/>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item">
                                    Edit
                                </li>
                            </ul>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </Ax>)
    }

    itemwiseScrapViewDetails                =   ()   =>   {
        return(<Ax>


        </Ax>)
    }

    getPageScreen                       =   ()  =>  {
        let pageScreen = null;
        if(this.state.itemwiseScrapAddScreenView) {
            pageScreen  = <ItemwiseScrapAdd closeEvent={this.addItemwiseScrapScreenClose}/>
        } else if(this.state.itemwiseScrapUpdateScreenView) {
            pageScreen  =  "update"
        } else {
            pageScreen  = this.itemwiseScrapListjsx();
        }
        return pageScreen;
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
            {this.getPageScreen()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(ItemwiseScrapList);