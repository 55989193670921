import React from 'react';
import moment from 'moment';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import WarehouseAddress from '../includes/WarehouseAddress';
import { Helmet } from 'react-helmet';
import ItemSearchModal from '../../items/itemSearchModal';
import { Link } from 'react-router-dom';
import ItemViewInList from '../includes/ItemViewInList';
import NumberFormatter from '../../../services/NumberFormater';
import ChangeWarehouseAddressModal from './ChangeWarehouseAddressModal';
import TapInputNumber from '../../../components/ui/TapInputNumber';


class InventoryTransferAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
         
        this.initITForm             =   {
            transaction_id              :   '',
            transaction_date            :   moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD'),
            warehouse_from              :   '',
            warehouse_to                :   '',
            invoice_number              :   '',
            invoice_date                :   '',
            eway_number                 :   '',
            eway_date                   :   '',
            document_number             :   '',
            document_date               :   '',
            transporter_number          :   '',
            notes                       :   '',
            status                      :   '',
            adjustment_amount           :   0.0,
            vechicle_registration_no    :   '',
            no_of_boxes                 :   '',
            weight                      :   '',
            unit_of_weight              :   '',
            fullfill_purchase_qty       :   'N',
            date_of_dispatch            :   '',
            date_of_receive             :   '',
        }

        this.iniitTransferItemRows   =   [{
            item                            :   null,
            stock_id                        :   null,
            remark                          :   '',
            amount                          :   0,
            qty                             :   0,
            stock_qty                       :   0,
            free_qty                        :   0,
            rate                            :   0,
            current_stocks                  :   [],
            selected_stock                  :   null,
            stock_loading                   :   false,
            allApiCallsCompleted            :   false,
        }];

        this.state                              =     {
            saveFormSubmitting                  :     false,
            inventoryTransferItemRows           :     [...this.iniitTransferItemRows],
            addInventoryTransferForm            :     {...this.initITForm},
            allDispatchingWarehouses            :     [],
            allReceivingWarehouses              :     [],
            allStatus                           :     [],
            allGstSlabs                         :     [],
            itemSearchedDetails                 :     null,
            itemRowNumber                       :      0,
            isc_configuration                   :     {},
            track_batch_expiry                  :    'N',
            negative_stock                      :     'N',
            formDataLoaded                      :     false,
            dispatchingWarehouseDetail          :     null,
            dispatchingWarehouseDetailLoading   :     false,
            receivingWarehouseDetail            :     null,
            receivingWarehouseDetailLoading     :     false,
            gstTypeName                         :     'GST',
            inventoryData                       :     null,
            userStatus                          :     [],
            selectedItemData                    :      null,
            selectedItemStocks                  :      [],
            selectedItemRow                     :       0,
            approved_indent_listing             :       false,
            indent_listing                      :       [],
            indnetListingMeta                   :       null,
            totalIndentListingCount             :       0,
            indentDetail                        :       null,
            indentTransId                       :       '',
            indent_detail_loading               :       false,
            only_stocked_item               :    'Y',
            allWeightUnits                  :       [{value:"gm",label:"Gram"},{value:"kg",label:"KilloGram"},{value:"tonne",label:"Tonne"}],
            indent_transaction_id           :           '',
            editInventoryTransfer           :       false,
            loading_item                    :       false,
            listingLoaded                   :       false,
            buttonDisabled                  :       true,
            sameDispatchWarehouseAddress    :   null,
            selectDispatchAddress           :   null,
            sameReceivingWarehouseAddress   :   null,
            selectReceivingAddress          :   null,
            dispatchAddressModalHandle      :   false,
            receivingAddressModalHandle     :   false,
            showItemSearchModal             :       false,
            showItemAddModal                :       false,
            showItemEditModal               :       false
        }
        this.addItemModalRef            =   React.createRef();
        this.editItemModalRef           =   React.createRef();
        this.itemSearchRef              =   React.createRef();
        this.changeWarehouseAddressRef  =   React.createRef();
        this.itemDetailsRef             =   React.createRef();

        this.warehouseIndentItemWiseUrl     =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/item_wise';
        this.warehouseIndentItemDetailUrl   =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/item_detail';
        this.detailUrl                      =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/detail';
        this.poReportUrl                    =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase-order';
        this.inventoryTransferUrl           =   INVENTORY_API_BASE_URL_2 + '/inventory_transfer';
        this.warehouseUrl                   =   ISC_API_BASE_URL_2  + '/warehouse';
        this.vendorUrl                      =   INVENTORY_API_BASE_URL_2 + '/vendor';
        this.itemUrl                        =   INVENTORY_API_BASE_URL_2 + '/item';
        this.itemsUrl                       =   INVENTORY_API_BASE_URL_2 + '/items';
        this.itemCurrentStockUrl            =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';

    }

    componentDidMount() {
        this.initilaizeFormData(this.props);
        
        this.viewItemModal             =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
        this.selectItemBatchModal      =   new Modal(document.getElementById('selectItemBatchModal'), {keyboard: false, backdrop :false});
        this.pickIndentItemModal      =   new Modal(document.getElementById('pickIndentItemModal'), {keyboard: false, backdrop :false});
        this.viewIndentDetailModal      =   new Modal(document.getElementById('viewIndentDetailModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(this.props.location && this.props.location.state){
            if(nextProps.all_warehouses !== this.props.all_warehouses) {
                this.initilaizeFormData(nextProps);
            }
        }        
    }
    
    initilaizeFormData              =   (props)  =>  {
        if(!this.state.formDataLoaded) {
            HttpAPICall.withAthorization('GET', this.inventoryTransferUrl + '/add_form_data', this.props.access_token, null, null, (response) => {
               let formData = response.data;
                this.setState({
                    formDataLoaded                    :    formData ? true : false,
                    allDispatchingWarehouses          :    props.all_warehouses ? props.all_warehouses.map((s) => { return { value: s.id, label: `${s.name} (${s.code})`, site_id: s.site_id }; }) : [],
                    allReceivingWarehouses            :    formData && formData.warehouses ? formData.warehouses.map((s) => { return { value: s.id, label: `${s.name} (${s.code})`, site_id: s.site_id }; }) : [],
                    isc_configuration                 :    props.isc_configuration,
                    track_batch_expiry                :    props.isc_configuration && props.isc_configuration.track_batch_expiry ? props.isc_configuration.track_batch_expiry : 'N',
                    negative_stock                    :    props.isc_configuration && props.isc_configuration.negative_stock ? props.isc_configuration.negative_stock : 'N',
                    allGstSlabs                       :    formData && formData.gst_slabs ? formData.gst_slabs.map((s) => { return {value: s, label: s +'%' }}) : [],
                    allStatus                         :    formData && formData.status ? formData.status.map((s) => { return {value: s.key, label: s.status }}) : [],
                },() => {
                    this.setState({buttonDisabled : false})
                    if(props.location && props.location.state && props.location.state.inventoryTransferData){
                       
                        let inventoryData                =       props.location.state.inventoryTransferData;
                        
                        let inventoryTransferItems       =      inventoryData.transfer_items.map((demo) => { return demo.warehouse_indents.map((date) => ({ ...date,item_id : demo.item_profile.id,items : date.items.map((item) => ({...item,item_profile : demo.item_profile,stock_qty:demo.qty})) }));
                        }).flat()
                        this.setState({
                            inventoryDetail             :   inventoryData,
                            buttonDisabled              :   true,
                            editInventoryTransfer       :   true,
                            loading_item                :   true,
                            selectDispatchAddress       :   inventoryData.dispatching_address ? inventoryData.dispatching_address : null,
                            selectReceivingAddress      :   inventoryData.receiving_address ? inventoryData.receiving_address : null,
                            addInventoryTransferForm    :     {
                                transaction_id                     :    inventoryData.transaction_id ,
                                transaction_date                   :    inventoryData.transaction_date,
                                warehouse_from                     :    inventoryData.warehouse_from,
                                warehouse_to                       :    inventoryData.warehouse_to,
                                invoice_number                     :    inventoryData.invoice_number ? inventoryData.invoice_number : '',
                                invoice_date                       :    inventoryData.invoice_date ? inventoryData.invoice_date : '',
                                eway_number                        :    inventoryData.eway_number ? inventoryData.eway_number : '',
                                eway_date                          :    inventoryData.eway_date ? inventoryData.eway_date : '',
                                document_number                    :    inventoryData.document_number ? inventoryData.document_number : '',
                                document_date                      :    inventoryData.document_date ? inventoryData.document_date : '',
                                transporter_number                 :    inventoryData.transporter_number ? inventoryData.transporter_number : '',
                                notes                              :    inventoryData.notes ? inventoryData.notes : '',
                                status                             :    inventoryData.status ? inventoryData.status : '',
                                adjustment_amount                  :    inventoryData.adjustment_amount? inventoryData.adjustment_amount : '',
                                vechicle_registration_no           :    inventoryData.vechicle_registration_no ? inventoryData.vechicle_registration_no : '',
                                no_of_boxes                        :    inventoryData.no_of_boxes ? inventoryData.no_of_boxes : '',
                                weight                             :    inventoryData.weight ? inventoryData.weight : '',
                                unit_of_weight                     :    inventoryData.unit_of_weight ? inventoryData.unit_of_weight : '',
                                fullfill_purchase_qty              :    inventoryData.fullfill_purchase_qty ? inventoryData.fullfill_purchase_qty : 'N'
                            },  inventoryData                      :    inventoryData,
                                userStatus                         :    inventoryData.userStatus && inventoryData.userStatus.length ? inventoryData.userStatus : [],
                        }, () => {

                            this.getDispatchingWarehouseDetail(inventoryData.warehouse_from);
                            this.getReceivingWarehouseDetail(inventoryData.warehouse_to);
                            this.getItemDetailsWithIndent(inventoryTransferItems)
                        
                              
                            //Remanage Status of Inventory Transfer :-
                            this.filterAllStatusOptions();
                        });
                       
                    }
                    if(props.location && props.location.state && props.location.state.addInventoryFromPurchase) {
                       
                        let inventoryData       =   props.location.state.addInventoryFromPurchase;
                      
                        let inventoryTransferItems           =   inventoryData.items;
                        setTimeout(() => {
                            this.setState({
                                inventoryData       :   null,
                                buttonDisabled       : true,
                                addInventoryTransferForm         :   {
                                    ...this.iniitTransferItemRows,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_from           :   inventoryData.warehouse_id,
                                   
                                }
                            }, () => {
                                this.getDispatchingWarehouseDetail(inventoryData.warehouse_from);
                                inventoryTransferItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                            this.addNewItemRow()
                        },500)
                      
                    }
                    if(props.location && props.location.state && props.location.state.addInventoryFromPurchaseReport) {
                       
                        let inventoryData       =   props.location.state.addInventoryFromPurchaseReport;
                      
                        let inventoryTransferItems           =   inventoryData.items;
                        this.setState({buttonDisabled : true})
                        setTimeout(() => {
                            this.setState({
                                inventoryData       :   null,
                                addInventoryTransferForm         :   {
                                    ...this.iniitTransferItemRows,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_from           :   inventoryData.warehouse_id,
                                   
                                }
                            }, () => {
                                this.getDispatchingWarehouseDetail(inventoryData.warehouse_from);
                                inventoryTransferItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                            this.addNewItemRow()
                        },500)
                       
                    }
                    if(props.location && props.location.state && props.location.state.addInventoryFromIndent){
                       
                        let inventoryData                =       props.location.state.addInventoryFromIndent;
                        let inventoryTransferItems       =       inventoryData.items;
                        
                        this.setState({
                           
                            addInventoryTransferForm    :     {
                               ...this.state.addInventoryTransferForm,
                                warehouse_from                     :    inventoryData.warehouse_from,
                                warehouse_to                       :    inventoryData.warehouse_to,
                                
                            },buttonDisabled : true  }, () => {
                            this.getDispatchingWarehouseDetail(inventoryData.warehouse_from);
                            this.getReceivingWarehouseDetail(inventoryData.warehouse_to);
                            this.getMultipleItemDetail(inventoryTransferItems,true)
                           
                           
                            // //Remanage Status of Inventory Transfer :-
                            // this.filterAllStatusOptions();
                        });
                    }
                    if(props.location && props.location.state && props.location.state.transferPurchaseFromIndent){
                       
                        let inventoryData                =       props.location.state.transferPurchaseFromIndent;
                        
                        this.setState({
                            indent_transaction_id       :  inventoryData.transactionId ? inventoryData.transactionId : '',
                            buttonDisabled               :  true,
                            addInventoryTransferForm    :     {
                               ...this.state.addInventoryTransferForm,
                               warehouse_to                       :    inventoryData.warehouse_id,
                               fullfill_purchase_qty              :     'Y',
                                
                            },  }, () => {
                            this.getReceivingWarehouseDetail(inventoryData.warehouse_id);
                            this.getItemWisePurchaseList(inventoryData.transactionId)
                            
                        });
                    }
                });
            });
        }
    
    }

    getItemWisePurchaseList             =       (indent_transaction_id)     =>      {
        if (indent_transaction_id) {
          
            HttpAPICall.withAthorization('GET', this.poReportUrl, this.props.access_token, {indent_transaction_id: indent_transaction_id}, {}, (resp) => {
                let respData = resp.data;
                let filteredDispatchingWarehouses      =   [];
                if(this.state.allDispatchingWarehouses && this.state.allDispatchingWarehouses.length > 0 && respData && respData.data && respData.data.length > 0){
                    filteredDispatchingWarehouses = this.state.allDispatchingWarehouses.filter(warehouse =>
                        respData.data.some(item => item.warehouse_id === warehouse.value)
                    );
                  
                }else{
                    filteredDispatchingWarehouses    =   []
                }

                if(filteredDispatchingWarehouses &&  filteredDispatchingWarehouses.length > 0){
                    this.setState({
                        allDispatchingWarehouses : filteredDispatchingWarehouses.filter(
                         warehouse => warehouse.value !== Number(this.state.addInventoryTransferForm.warehouse_to)
                   )},() => {
                      
                        
                   })
                }else{
                    this.setState({allDispatchingWarehouses : []})
                }
              
            })
        } 
    }

   
    
    getItemDetailsWithIndent            =   (inventoryTransferItems)        =>      {
        this.setState({ buttonDisabled: true });
        HttpAPICall.withAthorization('GET', this.itemsUrl, this.props.access_token, {item_ids : inventoryTransferItems.map(i => i.item_id),need_indent : 'Y'}, {}, (resp) => {
            let respData                        =       resp.data;
            
            let inventoryTransferItemRows       =       this.state.inventoryTransferItemRows;
           
            inventoryTransferItems.forEach((itemRow,key) => {
              
                let itemkey                 =   inventoryTransferItemRows.length > 1? inventoryTransferItemRows.length + key : key;
                let indentList              =   respData.data.find(i => i.id == itemRow.item_id);
                let selectedIndentId        =   indentList.indent_item_list &&   indentList.indent_item_list.length > 0 ? indentList.indent_item_list.find(i => i.transaction_id == itemRow.indent_transaction_id )    : null;   
                let pendingQty              =   0;
                let selectedIndentDetail    =   null;
                let available_indent_list   =   [];
                if (selectedIndentId && selectedIndentId.pending_qty && this.state.addInventoryTransferForm.fullfill_purchase_qty == 'N') {
                    pendingQty = selectedIndentId && selectedIndentId.pending_qty ? selectedIndentId.pending_qty + itemRow.items.map(s => s.qty).reduce((a, b) => a + b, 0) : 0;
                } else if (selectedIndentId && selectedIndentId.purchase_qty && this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y') {
                    pendingQty = selectedIndentId && selectedIndentId.purchase_qty ? selectedIndentId.purchase_qty + itemRow.items.map(s => s.qty).reduce((a, b) => a + b, 0) : 0;;
                }

                if(!selectedIndentId && itemRow.indent_transaction_id){
                   
                    
                    //api to get indent detail of Item with 0 pending qty
                    HttpAPICall.withAthorization('GET', this.warehouseIndentItemDetailUrl, this.props.access_token, {item_id : itemRow.item_id,transaction_id : itemRow.indent_transaction_id}, {}, (resp) => {
                        let respData                        =       resp.data;
                        
                        selectedIndentDetail        =       respData.data;
                        available_indent_list       =   [{value: itemRow.indent_transaction_id,label:itemRow.indent_transaction_id,selectedIndentId : respData.data}]
                        pendingQty                  =   itemRow.items.map(s => s.qty).reduce((a, b) => a + b, 0)
                     })
                   
                }else{
                  
                    available_indent_list       =       indentList.indent_item_list &&   indentList.indent_item_list.length > 0 ? indentList.indent_item_list.map(list => {return({value:list.transaction_id,label:list.transaction_id,selectedIndentId : list})}) : [];
                    selectedIndentDetail        =       selectedIndentId;
                    pendingQty                  =       pendingQty
                    
                 
                }

              
         
                setTimeout(() => {
                    
                    let row                  =      {...inventoryTransferItemRows[itemkey],
                       item                 :       indentList,
                       all_available_indent :       available_indent_list,
                       indent_id            :        itemRow.indent_transaction_id,
                       indnet_qty           :        selectedIndentDetail && selectedIndentDetail.indent_qty ? selectedIndentDetail.indent_qty : 0, 
                       pending_qty          :         pendingQty,
                       remark               :            itemRow.items && itemRow.items.length > 0 ? itemRow.items[0].remark : '', 
                       transaction_date     :          selectedIndentDetail && selectedIndentDetail.transaction_date_display ? selectedIndentDetail.transaction_date_display : 0,
                       rate                 :         itemRow.items && itemRow.items.length > 0 ? itemRow.items[0].rate : 0, 
                       stock_qty            :         itemRow.items && itemRow.items.length > 0 ? itemRow.items.map(s => s.qty).reduce((a, b) => a + b, 0) : 0,
                       qty                  :         itemRow.items && itemRow.items.length > 0 ? itemRow.items.map(s => s.qty).reduce((a, b) => a + b, 0) : 0,
                       gst                  :         itemRow.items && itemRow.items.length > 0 ? itemRow.items[0].gst : 0,
                       selected_batch_list  :         itemRow.items && itemRow.items.length > 0 ? itemRow.items.map(st => {return({batch_number : st.batch_number,expiry_date : st.expiry_date,qty:parseFloat(st.qty),expiry_date_display:st.expiry_date_display})}) : [],  
                   };
                       inventoryTransferItemRows[itemkey]            =   row;
                       let allInventoryTransferItemRows        =       inventoryTransferItemRows.filter(i => i.item ? true : false)
                       
                       this.setState({inventoryTransferItemRows : [...allInventoryTransferItemRows],loading_item:false})
                       if(indentList.category == 'goods') {
                           //load Inventory Level of this item at given warehouse for this key :-
                           this.getItemInventoryLevel(indentList.id, key);
                       }
                }, 5000);

            })
         
         })
    }

    
    filterAllStatusOptions                  =   ()  =>  {
        let allStatus                    =   this.state.allStatus;
        if(this.state.userStatus.length > 0) {
            allStatus                    =   allStatus.filter(allStatus =>  this.state.userStatus.find(ns => ns.key == allStatus.value) ? true : false );
            this.setState({
                allStatus        :   allStatus
            });
        }
    }

    onDispatchingWarehouseChange                   =   (selectedOption) =>  {
        this.setState({
            inventoryTransferItemRows         :   [...this.iniitTransferItemRows]
        }, () => {
            this.getDispatchingWarehouseDetail(selectedOption ? selectedOption.value : null);
        });
    }

    onReceivingWarehouseChange                   =   (selectedOption) =>  {
        // this.setState({
        //     inventoryTransferItemRows         :   [...this.iniitTransferItemRows]
        // }, () => {
            this.getReceivingWarehouseDetail(selectedOption ? selectedOption.value : null); 
        // });
       
    }

     //********************GET WAREHOUSE DETAILS API ******************************* */
     getDispatchingWarehouseDetail             =       (selectedOption)        =>          {
    
        if(selectedOption){
         let  id = selectedOption.value ? selectedOption.value : selectedOption
         this.setState({dispatchingWarehouseDetail : null,dispatchingWarehouseDetailLoading : true})
            HttpAPICall.withAthorization('GET',  this.warehouseUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                if(this.state.selectDispatchAddress && this.state.selectReceivingAddress) {
                    if(this.state.selectDispatchAddress?.state_id === this.state.selectReceivingAddress?.state_id){
                        this.setState({gstTypeName : 'GST'})
                    }else{
                        this.setState({gstTypeName : 'IGST'}) 
                    }
                }else {
                    if(this.state.receivingWarehouseDetail?.state_id === respData.data.state_id){
                        this.setState({gstTypeName : 'GST'})
                    }else{
                        this.setState({gstTypeName : 'IGST'}) 
                    }
                }
                this.setState({
                    dispatchingWarehouseDetail      :   this.state.selectDispatchAddress ? this.state.selectDispatchAddress : respData.data,
                    sameDispatchWarehouseAddress    :   respData.data
            }); }).then(() => this.setState({ dispatchingWarehouseDetailLoading : false })); 
        }else{
            this.setState({dispatchingWarehouseDetail    :      null,})
            
        }
    }

    getReceivingWarehouseDetail             =       (selectedOption)        =>          {
    
        if(selectedOption){
         let  id = selectedOption.value ? selectedOption.value : selectedOption
         this.setState({receivingWarehouseDetail : null,receivingWarehouseDetailLoading : true})
            HttpAPICall.withAthorization('GET',  this.warehouseUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                if(this.state.selectDispatchAddress && this.state.selectReceivingAddress) {
                    if(this.state.selectDispatchAddress?.state_id === this.state.selectReceivingAddress?.state_id){
                        this.setState({gstTypeName : 'GST'})
                    }else{
                        this.setState({gstTypeName : 'IGST'}) 
                    }
                }else {
                    if(this.state.dispatchingWarehouseDetail?.state_id === respData.data.state_id){
                        this.setState({gstTypeName : 'GST'})
                    }else{
                        this.setState({gstTypeName : 'IGST'}) 
                    }
                }
                
                this.setState({
                    receivingWarehouseDetail        :   this.state.selectReceivingAddress ? this.state.selectReceivingAddress : respData.data,
                    sameReceivingWarehouseAddress   :   respData.data,
            }); }).then(() => this.setState({ receivingWarehouseDetailLoading : false })); 
        }else{
            this.setState({receivingWarehouseDetail    :      null,})
            
        }
    }
   
    addNewItemRow                           =   ()  =>  {
        this.setState({inventoryTransferItemRows : [...this.state.inventoryTransferItemRows, {item : null}] });
    }

    removeItemRow                           =   (k) =>  {
        let allRows                 =   this.state.inventoryTransferItemRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({inventoryTransferItemRows : allRows});
        }
    }

    itemViewModalInit                       =   (item,k)  =>  {
        if(item){
            this.itemDetailsRef.current.modalInit(item);
            this.setState({itemRowNumber : k})
        }

    }

    itemSearchModalInit             =       ()          =>      {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows                 =   this.state.inventoryTransferItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
        
    }

    editItemModalInit          =       (data,k)           =>      {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal,itemSearchedDetails:data,itemRowNumber:k }), () => {
            if (this.state.showItemEditModal) {
                let allRows                 =   this.state.inventoryTransferItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if(this.editItemModalRef.current){
                    this.editItemModalRef.current.itemEditModalInit(data); 
                }
                
            }
        });
        
    }

    addItemModalInit          =       ()           =>      {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows                 =   this.state.inventoryTransferItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }

                if(this.addItemModalRef.current){
                    this.addItemModalRef.current.itemAddModalInit(); 
                }
            
            }
        });
        
    }

    //****************************CLOSE INVENTORY *********************/
    closeEvent              =           ()      =>      {

        this.props.history.push({pathname: `/inventory_transfer_list`})   
    }
    

    submitEditItem          =       ()      =>      {
        HttpAPICall.withAthorization('GET', this.itemUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    {...this.state.itemSearchedDetails,...response.data.data}
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,this.state.inventoryTransferItemRows[this.state.itemRowNumber].qty)
               });
        })
       
    }

    getMultipleItemDetail           =       (itemData,autofill_qty=false)        =>      {
       
        let item_ids                    =       itemData.map(i => {return(i.item_id)})

        HttpAPICall.withAthorization('GET', this.itemsUrl, this.props.access_token, {item_ids : item_ids,need_indent : 'Y'}, {}, (resp) => {
            let respData                        =       resp.data;
            let inventoryTransferItemRows       =       this.state.inventoryTransferItemRows;
            let allInventoryTransferItemRows    =       []
           
            itemData.forEach((itemRow,key) => {
                let itemkey                 =   inventoryTransferItemRows.length > 1? inventoryTransferItemRows.length + key : key;
                let indentList              =   respData.data.find(i => i.id == itemRow.item_id);
                let selectedIndentId        =   indentList.indent_item_list &&   indentList.indent_item_list.length > 0 ? indentList.indent_item_list.find(i => i.transaction_id == itemRow.indent_id )    : null;   
             
               let selectedItemData         =   itemData && itemData.length > 0 ? itemData.find(id => id.item_id == itemRow.item_id) : null;
                let row     =   {...inventoryTransferItemRows[itemkey],
                                    item                 :       indentList,
                                    stock_loading          :       true,
                                    all_available_indent :       indentList.indent_item_list &&   indentList.indent_item_list.length > 0 ? indentList.indent_item_list.map(list => {return({value:list.transaction_id,label:list.transaction_id,selectedIndentId : list})}) : [] ,
                                    indent_id            :        selectedIndentId && selectedIndentId?.transaction_id ? selectedIndentId?.transaction_id : '',
                                    indnet_qty           :        selectedIndentId && selectedIndentId.indent_qty ? selectedIndentId.indent_qty : 0, 
                                    pending_qty          :        selectedIndentId && selectedIndentId.pending_qty ? selectedIndentId.pending_qty : 0, 
                                    transaction_date     :          selectedIndentId && selectedIndentId.transaction_date_display ? selectedIndentId.transaction_date_display : 0,
                                    rate                 :        indentList.purchase_price ? indentList.purchase_price : 0, 
                                    // qty                  :       autofill_qty == true &&  selectedIndentId && selectedIndentId.pending_qty ? selectedIndentId.pending_qty : 0,  
                                    qty                 :           autofill_qty == true && selectedItemData && selectedItemData.fullfill_qty ?  selectedItemData.fullfill_qty : 0
                                };
                inventoryTransferItemRows[itemkey]            =   row;
                allInventoryTransferItemRows        =       inventoryTransferItemRows.filter(i => i.item ? true : false)
               
                this.setState({ inventoryTransferItemRows: allInventoryTransferItemRows })
                // if (indentList.category == 'goods') {
                //     //load Inventory Level of this item at given warehouse for this key :-
                //     this.getItemInventoryLevel(indentList.id, itemkey);
                // }
            })

            setTimeout(() => {
                allInventoryTransferItemRows.forEach((it,key) => {
                    if (it.item.category == 'goods') {
                        //load Inventory Level of this item at given warehouse for this key :-
                        this.getItemInventoryLevel(it.item.id, key);
                    }
                })
            },1000)
         
         })
    }


    selectItemBatchModalInit        =       (data,current_stocks,indnet_qty = 0,itemRow=0,batchlist = [])          =>      {
        this.selectItemBatchModal.show();
        if(document.getElementById("selectItemBatchForm")) {
            let form = document.getElementById("selectItemBatchForm")
            form.reset();
        }
          
           let current_stock_list = [];
           if(this.state.inventoryData){
              
            
             current_stock_list =        current_stocks.map(test => {
                                            if(batchlist && batchlist.length >0 && batchlist.find(o2 => test.stock.batch_number == o2.batch_number && test.stock.expiry_date == o2.expiry_date)){
                                            let selected_batch = batchlist.find(o2 => test.stock.batch_number == o2.batch_number && test.stock.expiry_date == o2.expiry_date);
                                            
                                                return({...test,new_qty : selected_batch.qty,stock_qty: selected_batch.qty })
                                            }else{
                                                return({...test,new_qty : test.stock.free_qty > indnet_qty ? indnet_qty : test.stock.free_qty , stock_qty :  test.stock.free_qty > indnet_qty ? indnet_qty : test.stock.free_qty })
                                            }
                                        })
            

            }else{
                current_stock_list      =    current_stocks.map(test => {  return({...test,new_qty : test.stock.free_qty > indnet_qty ? indnet_qty : test.stock.free_qty  }) })
            }

            this.setState({
                selectedItemData    :   data,
                selectedItemRow     :   itemRow,
                selectedItemStocks  :  current_stock_list
            })
        
       
       
    }

   
    onItemSelect                        =   (selectedOption, key, qty = 0, consumption_price = null , pre_selected_stock =  null,addRow = true)  =>  {
        if(this.state.addInventoryTransferForm.warehouse_from){
            if(selectedOption) {
                let inventoryTransferItemRows         =   this.state.inventoryTransferItemRows;
               
                let itemdata                    =   selectedOption.itemdata;
                
                if(!consumption_price) {
                    consumption_price           =   itemdata && itemdata.purchase_price ? itemdata.purchase_price : 0;
                }
                let row                         =   {...inventoryTransferItemRows[key], item : itemdata ,allApiCallsCompleted: itemdata.category !== 'goods' ? true : false, qty : qty , rate : consumption_price,all_available_indent :       itemdata.indent_item_list &&   itemdata.indent_item_list.length > 0 ? itemdata.indent_item_list.map(list => {return({value:list.transaction_id,label:list.transaction_id,selectedIndentId : list})}) : [] };
                inventoryTransferItemRows[key]            =   row;
                this.setState({inventoryTransferItemRows : [...inventoryTransferItemRows]}, () => {
                    if(this.state.inventoryData && this.state.inventoryData.status && this.state.inventoryData.status !== 36){
                        if(!this.state.inventoryTransferItemRows.find(ir => !ir.item)) {
                            if(addRow == true){
                                setTimeout(() => {this.addNewItemRow()}, 1000);
                            }
                        }
                    }else{
                        if(!this.state.inventoryTransferItemRows.find(ir => !ir.item)) {
                            if(addRow == true){
                                setTimeout(() => {this.addNewItemRow()}, 1000);
                            }
                        }
                    }

                    if(itemdata.category == 'goods') {
                        //load Inventory Level of this item at given warehouse for this key :-
                        this.getItemInventoryLevel(itemdata.id, key, pre_selected_stock,selectedOption?.batch_number , selectedOption?.expiry_date_display);
                    }
                });
            }
        }else{
            toast.error('Please select Dispatching and Receiving Warehouse ', { position: toast.POSITION.TOP_RIGHT })
        }
        
    }
    
    getItemInventoryLevel               =   (item_id, key, pre_selected_stock = null,batch_number = null , expiry_date = null)  =>  {

        let inventoryTransferItemRows         =   [...this.state.inventoryTransferItemRows];
      
        if(inventoryTransferItemRows[key] && inventoryTransferItemRows[key]) {
           
            inventoryTransferItemRows[key]        =   {...inventoryTransferItemRows[key] , stock_loading : true};
            this.setState({inventoryTransferItemRows : [...inventoryTransferItemRows]});
            
            let params                          =   {interchangeable_items_current_stock: "N", warehouse_id: this.state.addInventoryTransferForm.warehouse_from};
            HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + item_id, this.props.access_token, params, {}, (resp) => {
               
                let current_stocks              =   resp.data.current_stock.map(cs => {
                    let batch_number_display    =  cs.batch_number ? cs.batch_number : batch_number ? batch_number : null;
                    let expiry_date_display     =  cs.expiry_date_display ? cs.expiry_date_display : expiry_date ? expiry_date : null
                                                        return {
                                                            value : cs.qty,
                                                            label : (<Ax><b>Batch Number : </b> {batch_number_display} <br />
                                                                <b>Expiry Date : </b> {expiry_date_display}<br />
                                                                <b>Current Qty : </b> {cs.qty} <br/>
                                                                <b>Free Qty : </b> {cs.free_qty}</Ax>),
                                                            stock : cs,
                                                            display_label : `${batch_number_display} & ${expiry_date_display} `,
                                                            batch_number  :  cs.batch_number ? cs.batch_number : batch_number ? batch_number : null,
                                                            expiry_date   : cs.expiry_date_display ? cs.expiry_date_display : expiry_date ? expiry_date : null
                                                        }
                                                    });
              
                let selected_current_stocks     =   [];
                let qty_without_batch = 0;
                let freeqty_without_batch = 0;
                if (current_stocks && current_stocks.length == 1) {
                    let selected_current_stock_data      =  current_stocks[0];
                   
                    if (selected_current_stock_data && !selected_current_stock_data.batch_number && !selected_current_stock_data.expiry_date) {
                        selected_current_stocks         =       [];
                        qty_without_batch               =       selected_current_stock_data.stock && selected_current_stock_data.stock.qty ? selected_current_stock_data.stock.qty : 0;
                        freeqty_without_batch           =       selected_current_stock_data.stock && selected_current_stock_data.stock.free_qty ? selected_current_stock_data.stock.free_qty : 0;
                    } else {
                        selected_current_stocks         =       current_stocks;
                        qty_without_batch               =        0;
                        freeqty_without_batch           =        0;
                    }
                } else {
                    selected_current_stocks             =       current_stocks;
                    qty_without_batch                   =       0;
                    freeqty_without_batch               =       0;
                }
    
    
                let inventoryTransferItemRows         =   this.state.inventoryTransferItemRows;
                inventoryTransferItemRows[key]        =   {
                        ...inventoryTransferItemRows[key],
                        stock_loading : false,
                        current_stocks : selected_current_stocks,
                        qty_without_batch : qty_without_batch,
                        freeqty_without_batch : freeqty_without_batch,
                        allApiCallsCompleted: true
                };
    
                let selectedStock               =   current_stocks.length > 0 ? current_stocks[0] : null;
                let findSelectedStockKey        =   null;
                if(pre_selected_stock) {
                    let preSelectedStock            =   current_stocks.find(cs => {
                                                            return cs.stock.batch_number == pre_selected_stock.batch_number 
                                                                        && cs.stock.expiry_date == pre_selected_stock.expiry_date;
                                                        });
                    if(!preSelectedStock) {
                       
                        selectedStock           =  {
                            stock_id                :   pre_selected_stock.stock_id,
                            batch_number            :   pre_selected_stock.batch_number,
                            expiry_date             :   pre_selected_stock.expiry_date
                        };
                    } else {
                        let cs = preSelectedStock.stock;
                        let qty             =   cs.qty;
                        let free_qty        =   cs.free_qty;
                        
                        selectedStock           =   {...preSelectedStock , stock_id : pre_selected_stock.stock_id, stock : {
                                    ...cs,
                                    qty         :   qty,
                                    free_qty    :   free_qty
                            }};
                    }
                }
    
                this.setState({ inventoryTransferItemRows: [...inventoryTransferItemRows] }, () => {
                    this.handleButtonEnable();
                });
            }).then(() => { });
        }
    };
    
    handleButtonEnable = () => {
        const allApiCallsCompleted = this.state.inventoryTransferItemRows.filter(i => i.item ? true : false).every(item => item.allApiCallsCompleted);
        this.setState({ buttonDisabled: !allApiCallsCompleted });
    };
      

    checkAllBatchList                          =   ()  =>  {
        let allBatchCheckboxCollections      =   document.getElementsByClassName('batch_expiry_checkbox');
        if(allBatchCheckboxCollections && allBatchCheckboxCollections.length > 0) {
            for (let i = 0; i < allBatchCheckboxCollections.length; i++) {
                allBatchCheckboxCollections[i].checked = document.getElementById('all_batch_list_check').checked;
            }
        }
    }

    submitBatchFormSubmit           =       (e)      =>      {
        e.preventDefault();
        let  allBatchCheckboxCollections      =    document.getElementsByClassName('batch_expiry_checkbox');
        let selectedStockList       =       [];
  
        if( allBatchCheckboxCollections &&  allBatchCheckboxCollections.length > 0) {
            for (let i = 0; i <  allBatchCheckboxCollections.length; i++) {
                if( allBatchCheckboxCollections[i].checked) {
                    let batch_number = allBatchCheckboxCollections[i].value ? allBatchCheckboxCollections[i].value : null ;
                    let expiry_date  =  allBatchCheckboxCollections[i].getAttribute('data-expiry_date');
                    let expiry_date_value = expiry_date ? expiry_date : null;
                    let stockData = this.state.selectedItemStocks.find(st => {
                        let stock_batch_number = st.stock.batch_number ? st.stock.batch_number : null;
                        let stock_expiry_date   =   st.stock.expiry_date ? st.stock.expiry_date : null;
                     
                        return stock_batch_number == batch_number && stock_expiry_date == expiry_date_value
                    })
                  
                    selectedStockList.push(stockData);
                   
                }
            }
        }
      
      
        if(selectedStockList && selectedStockList.length == 0){
            toast.error('Please select atleasts once Item ', { position: toast.POSITION.TOP_RIGHT })
        }else{
           
            if(!selectedStockList.includes(undefined)){
                let total_qty = selectedStockList && selectedStockList.length > 0 ? selectedStockList.reduce((sum, a) => sum + parseFloat(a?.new_qty ?? 0), 0) : [];

                let inventoryTransferItemRows = this.state.inventoryTransferItemRows;

                if (inventoryTransferItemRows[this.state.selectedItemRow]?.indent_id && total_qty > inventoryTransferItemRows[this.state.selectedItemRow]?.pending_qty) {
                    toast.error('Total Quantity should not be greater than Pending Qty ', { position: toast.POSITION.TOP_RIGHT })
                } else {
                    inventoryTransferItemRows[this.state.selectedItemRow] = {
                        ...inventoryTransferItemRows[this.state.selectedItemRow],
                        qty: selectedStockList && selectedStockList.length > 0 ? selectedStockList.reduce((sum, a) => sum + parseFloat(a?.new_qty ?? 0), 0) : [],
                        selected_batch_list: selectedStockList && selectedStockList.length > 0 ? selectedStockList.map(st => { return ({ batch_number: st.stock.batch_number, expiry_date: st.stock.expiry_date, qty: parseFloat(st.new_qty), expiry_date_display: st?.stock?.expiry_date_display }) }) : []
                    };
                   
                    this.setState({ inventoryTransferItemRows: inventoryTransferItemRows });
                    this.selectItemBatchModal.hide();

                }
            }
             
             
         }
    }

    //****************Pick Item from Indent*********************** */
    pickIndentItemModalInit         =       ()          =>      {
        this.pickIndentItemModal.show();
        if (!this.state.listingLoaded) {
            this.getAllApprovedIndentItemList();
        }
    }

    getAllApprovedIndentItemList           =       (page = 1)        =>      {
        this.setState({approved_indent_listing : true})
        let obj = {};
        if(this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y'){
            obj = {min_purchase_qty : 0.01,search_transaction_id : this.state.indent_transaction_id}
        }else{
            obj = {min_pending_qty : 0.01}
        }

        let params      =  {
            search_dispatching_warehouse : this.state.addInventoryTransferForm.warehouse_from ,
            search_warehouse_id : this.state.addInventoryTransferForm.warehouse_to,
            ...obj,
            page:page
        }
        HttpAPICall.withAthorization('GET',  this.warehouseIndentItemWiseUrl, this.props.access_token, {...params}, {}, (response) => {
            let respData            =       response.data;
            
            this.setState({
                indent_listing      :       [...this.state.indent_listing, ...respData.data],
                indnetListingMeta         :       {
                        from            :           respData.from,
                        to               :          respData.to,
                        total            :          respData.total,
                        last_page        :          respData.last_page,
                        current_page    :           respData.current_page
                    ,
                },
                totalIndentListingCount   :       respData.total,
                listingLoaded              :       true
        },() => {
           
            if(this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y' && this.state.indent_listing && this.state.indent_listing.length > 0){
              
                let item_ids = [],item_data_ids=[];

                item_data_ids   =   this.state.indent_listing.map(it => it.item.id);
                item_ids        =   this.state.indent_listing.map(it => {return({indent_id: it.transaction_id ,item_id:it.item.id})});

                this.getItemDetailsForEachIndent(item_data_ids, item_ids)
            }
        });
        }).then(() => this.setState({ approved_indent_listing :false}));
    }

    checkAllIndentItemList() {
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox')
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_indent_list_check').checked;
            }
        }
    }

    submitPickIndentFormSubmit          =   (e)         =>      {
        e.preventDefault();
        let item_ids                           =    [];
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    item_ids.push({
                        indent_id       :        indentItemForCheckbox[i].value,
                        item_id         :        indentItemForCheckbox[i].getAttribute("data-itemId"),
                    })
                }
            }
        }
        
        if (item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        }else {
            let item_data_ids                    =       item_ids.map(i => {return(i.item_id)})
            this.getItemDetailsForEachIndent(item_data_ids,item_ids)
          
            this.pickIndentItemModal.hide();
            //this.addNewItemRow()
           
        }
    }

    getItemDetailsForEachIndent     =       (item_data_ids = [],item_ids=[])      =>      {

       let params = {item_ids : item_data_ids,need_indent : 'Y'}; 
       let obj = {};
       if(this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y'){
         obj['min_purchase_qty'] = 0.01;
       }
        HttpAPICall.withAthorization('GET', this.itemsUrl, this.props.access_token, {...params,...obj}, {}, (resp) => {
            let respData                        =       resp.data;
            let inventoryTransferItemRows       =       this.state.inventoryTransferItemRows;
            let inventory_itemRow               =       this.state.inventoryTransferItemRows.filter(i => i.item ? true  :false);;
            
            item_ids.forEach((itemRow,key) => {
                let itemkey                 =   this.state.inventoryTransferItemRows.length > 1? inventory_itemRow.length + key : key;
                let indentList              =   respData.data.find(i => i.id == itemRow.item_id);
               
                let selectedIndentId        =   indentList.indent_item_list &&   indentList.indent_item_list.length > 0 ? indentList.indent_item_list.find(i => i.transaction_id == itemRow.indent_id )    : null;   
               
                let pending_qty             =   0;
                if(selectedIndentId && selectedIndentId.pending_qty && this.state.addInventoryTransferForm.fullfill_purchase_qty == 'N'){
                    pending_qty =   selectedIndentId.pending_qty;
                }else if(selectedIndentId && selectedIndentId.purchase_qty && this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y'){
                    pending_qty =   selectedIndentId.purchase_qty;
                }

                let row     =   {...inventoryTransferItemRows[itemkey],
                                    item                 :       indentList,
                                    all_available_indent :       indentList.indent_item_list &&   indentList.indent_item_list.length > 0 ? indentList.indent_item_list.map(list => {return({value:list.transaction_id,label:list.transaction_id,selectedIndentId : selectedIndentId})}) : [] ,
                                    indent_id            :        selectedIndentId && selectedIndentId?.transaction_id ? selectedIndentId?.transaction_id : '',
                                    indnet_qty           :        selectedIndentId && selectedIndentId.indent_qty ? selectedIndentId.indent_qty : 0, 
                                    pending_qty          :        pending_qty,
                                    transaction_date     :          selectedIndentId && selectedIndentId.transaction_date_display ? selectedIndentId.transaction_date_display : 0,
                                    rate                 :        indentList.purchase_price ? indentList.purchase_price : 0, 
                                    qty                  :        pending_qty     
                                };
                inventoryTransferItemRows[itemkey]            =   row;
                let allInventoryTransferItemRows        =       inventoryTransferItemRows.filter(i => i.item ? true : false)
                const uniqueInventoryTransferRows = allInventoryTransferItemRows.filter((item, index, self) => {
                    const pos = self.findIndex((i) => i?.indent_id === item?.indent_id && i.item.id === item.item.id);
                    return pos === index;
                });
                this.setState({ inventoryTransferItemRows: uniqueInventoryTransferRows })
                if (indentList.category == 'goods') {
                    //load Inventory Level of this item at given warehouse for this key :-
                    this.getItemInventoryLevel(indentList.id, itemkey);
                }
            })
             
    
     
        })
    }
    
    
    onBatchChange                       =   (selectedOption, key)  =>  {
      
      
        if(selectedOption && selectedOption.stock) {
            let selected_stock              =   selectedOption.stock;
           
            let inventoryTransferItemRows         =   this.state.inventoryTransferItemRows;
            inventoryTransferItemRows[key]        =   {
                ...inventoryTransferItemRows[key], 
                selected_stock  :   selected_stock,
                stock_qty       :   selected_stock.qty,
                free_qty        :   selected_stock.free_qty,
                stock_id        :   selectedOption.stock_id ? selectedOption.stock_id : null,
            };
            this.setState({inventoryTransferItemRows : [...inventoryTransferItemRows]});
        }

        if(selectedOption && selectedOption.batch_number || selectedOption && selectedOption.expiry_date){
            let inventoryTransferItemRows         =   this.state.inventoryTransferItemRows;
            inventoryTransferItemRows[key]        =   {
                ...inventoryTransferItemRows[key], 
                batch_number    :   selectedOption.batch_number ? selectedOption.batch_number : null,
                expiry_date     :   selectedOption.expiry_date ? selectedOption.expiry_date : null,
                stock_id        :   selectedOption.stock_id ? selectedOption.stock_id : null,
            };
            this.setState({inventoryTransferItemRows : [...inventoryTransferItemRows]});
        }
    }

    addItemsfromSearch          =       (selectedItems)     =>  {
       
        let allRows = this.state.inventoryTransferItemRows ?  this.state.inventoryTransferItemRows.filter(i => i.item ? true : false) : [];
        let inventoryTransferItems = allRows.concat(selectedItems);
        inventoryTransferItems.forEach((itemData, key) => {
            let pre_selected_stock = {batch_number : '', expiry_date : '' , qty : itemData?.item?.qty, stock_id : null};
            this.onItemSelect({...itemData , itemdata : itemData?.item}, key ,itemData?.qty, itemData?.rate,pre_selected_stock,false);
        });
          
        
    
    }

    loadMoreList                    =   ()  =>  {
        if(this.state.indnetListingMeta && this.state.indnetListingMeta.current_page < this.state.indnetListingMeta.last_page) {
            // this.setState({listingLoaded : false},() => {
                this.getAllApprovedIndentItemList(this.state.indnetListingMeta.current_page + 1);
            //})
            
        }
    }
    
    addInventoryTransferFormSubmitHandler       =   (e)  =>  e.preventDefault()

    addInventoryTransferFormSubmitEvent         =   (e,status = 0)         =>      {
        e.preventDefault();
        if(!this.state.saveFormSubmitting) {
            let items                           =        [...this.state.inventoryTransferItemRows];
            let itemsLists                      =        items.filter(i => i?.item ? true : false).map(i => {
                if( i.selected_batch_list && i.selected_batch_list.length >= 1 && this.state.track_batch_expiry == "Y" ){
                    return(i.selected_batch_list.map(list => {
                        
                        return{
                        
                        id                  :   i.item.id,
                        indent_transaction_id    :   i.indent_id ? i.indent_id : '',
                        batch_number             :   list.batch_number ? list.batch_number :'',
                        expiry_date              :   list.expiry_date ? list.expiry_date : "",
                        qty                      :   parseFloat(list.qty),
                        rate                     :   parseFloat(i.rate),
                        stock_id                 :   i.stock_id ? i.stock_id : '',
                        remark                   :   i.remark,
                        gst                      :   i.gst
                    }})) 
                }else{
                    return {id                  :   i.item.id,
                        indent_transaction_id    :   i.indent_id ? i.indent_id : '',
                        batch_number             :    '',
                        expiry_date              :   '',
                        gst                      :   i.gst,
                        qty                      :   parseFloat(i.qty),
                        rate                     :   parseFloat(i.rate),
                        stock_id                 :   i.stock_id ? i.stock_id : '',
                        remark                   :   i.remark};
                    }
                }
            )                                  
                                                            
            let allFinalItemLists        =    itemsLists.flat();                                                      
            
            //to check combinatio of Item and Batch Number,Expiry Data                                                
            let allItemLists                    =            [];
            let dublicateItemExists             =            false;                                                  
            if(allFinalItemLists.length > 0){
                allFinalItemLists.forEach(item => {

                    let combinationKey          =           item.id  + item.batch_number + item.expiry_date + item.indent_transaction_id;
                    if (allItemLists.includes(combinationKey)) {
                        dublicateItemExists = true;
                    }
                    return allItemLists.push(combinationKey); 
                })
            }                                                

            let showErrors                      =   [];

            let addInventoryTransferForm        =     {...this.state.addInventoryTransferForm,
                                                        adjustment_amount   :   parseFloat(this.state.addInventoryTransferForm.adjustment_amount)}; 
            let formData                        =     {...addInventoryTransferForm,
                                                      status        :       status ? status  : 0,
                                                      items         :       allFinalItemLists};

            if(formData.transaction_date == '') {
                showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
            }

            if(formData.warehouse_from == '') {
                showErrors                      =   [...showErrors , 'Please select Dispatching Warehouse.'];
            }

            if(formData.warehouse_to == '') {
                showErrors                      =   [...showErrors , 'Please select Receiving Warehouse.'];
            }
            if(!formData.status && formData.status === '') {
                showErrors                      =   [...showErrors , 'Please select Status.'];
            }

            if(itemsLists.length == 0) {
                showErrors                      =   [...showErrors , 'Please select Item and their  qty.'];
            }else if(itemsLists.find(i => parseFloat(i.qty) <= 0)) {
                showErrors                      =   [...showErrors , 'Every Item\'s  qty must be more than 0.'];
            } else if(dublicateItemExists == true) {
                showErrors                      =   [...showErrors , 'Inventory Transfer should not contain duplicate Item'];
            }

            if(showErrors.length > 0) {
                toast.error(showErrors[0], {position: toast.POSITION.TOP_RIGHT});
            } else {
                const dispatching_address_id    =   this.state.selectDispatchAddress ? this.state.selectDispatchAddress.id : null;
                const dispatching_address       =   this.state.selectDispatchAddress ? this.state.selectDispatchAddress : null;
                const receiving_address_id      =   this.state.selectReceivingAddress ? this.state.selectReceivingAddress.id : null;
                const receiving_address         =   this.state.selectReceivingAddress ? this.state.selectReceivingAddress : null;

                // const warehouse_from            =   this.state.selectDispatchAddress ? null : formData.warehouse_from; 
                // const warehouse_to              =   this.state.selectReceivingAddress ? null : formData.warehouse_to;

                formData                        =   {...formData,  dispatching_address_id, dispatching_address, receiving_address_id, receiving_address};
                this.setState({ saveFormSubmitting: true }, () => {
                    if(this.state.inventoryData){
                        HttpAPICall.withAthorization('PUT', this.inventoryTransferUrl, this.props.access_token, {}, formData, (response) => {
                            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT })
                            this.props.history.push({pathname: `/inventory_transfer_list`, state: {inventoryId : response.data.transaction_id }})
                        }).then(() => this.setState({ saveFormSubmitting: false })); 
                    }else{
                        HttpAPICall.withAthorization('POST', this.inventoryTransferUrl, this.props.access_token, {}, formData, (response) => {
                            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT })
                            this.props.history.push({pathname: `/inventory_transfer_list`, state: {inventoryId : response.data.transaction_id }})
                        }).then(() => this.setState({ saveFormSubmitting: false })); 
                    }
                });
            }
         }
    }

    //*****************SHOW INDENT DETAIl***************** */

    viewIndentDetailModalInit           =       (id)        =>      {
        if(id){
            this.getIndentDetail(id)
            this.viewIndentDetailModal.show()
        }
        
    }

    getIndentDetail        =       (transaction_id)      =>      {
    
        this.setState({indent_detail_loading : true})
        HttpAPICall.withAthorization('GET', this.detailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                indentDetail    :   respData.data,
                indentTransId   :   respData.data.transaction_id,
           });
        
        },(err) => {
            
        }).then(() => this.setState({indent_detail_loading : false}));
   
    }


    changeWarehouseAddressModal =   (warehouse_id, type)  =>  {
        if(type === 'dispatch') {
            this.setState({
                dispatchAddressModalHandle : true,
                receivingAddressModalHandle: false,
            }, () => {
                this.changeWarehouseAddressRef.current.handleModal(warehouse_id);
            });
        }else {
            this.setState({
                dispatchAddressModalHandle : false,
                receivingAddressModalHandle: true,
            }, () => {
                this.changeWarehouseAddressRef.current.handleModal(warehouse_id);
            });
        }
    }

    saveInventoryTransferFormJsx             =   ()  =>  {
        let sub_total_amount                    =   0;
        let gst_total_amount                    =   0;
        let gst_slab                            =   [];
        let total_amount                        =   0;
        let inventory_summary                    =   [];
        let adjustment_amount                   =   parseFloat(this.state.addInventoryTransferForm.adjustment_amount);
        
        this.state.inventoryTransferItemRows.forEach((item,key) => {
            if(item && item.item) {
                let item_amount             =   parseFloat(item.qty * item.rate);
                let discount_amout          =   0;
                let item_with_discount      =   item_amount - discount_amout;
                let tax_amount              =   0;
                
                if(item.gst && item.gst > 0 && this.state.dispatchingWarehouseDetail
                                && this.state.dispatchingWarehouseDetail.gstin) {
                    tax_amount              =   item_with_discount * item.gst / 100;
                    if(!gst_slab[item.gst]) {
                        gst_slab[item.gst]  =   [];
                    }
                    gst_slab[item.gst].push(tax_amount);
                }
                let item_final_amount       =   item_with_discount + tax_amount;
                sub_total_amount           +=   item_with_discount;
                gst_total_amount           +=   tax_amount;
                total_amount               +=   item_final_amount; 
            }
        });
      

        if(gst_total_amount > 0) {
            inventory_summary.push({key : 'Sub Total' , value : sub_total_amount.toFixed(2)});
            gst_slab.forEach((tax_amounts, gst) => {
                let total_tax = tax_amounts.reduce((sum, a) => sum + a, 0);
                if(this.state.dispatchingWarehouseDetail && this.state.receivingWarehouseDetail && this.state.dispatchingWarehouseDetail.state_id !== this.state.receivingWarehouseDetail.state_id) {
                    inventory_summary.push({key : 'IGST ' + gst + '%', value : total_tax.toFixed(2)});
                } else {
                    let tax = gst / 2;
                    inventory_summary.push({key : 'CGST ' + tax + '%', value : (total_tax/2).toFixed(2)});
                    inventory_summary.push({key : 'SGST ' + tax + '%', value : (total_tax/2).toFixed(2)});
                }
            });
        }

        let final_total_amount=Number(total_amount);
        if(adjustment_amount){
            final_total_amount = Number(total_amount) + Number(adjustment_amount) ;
        }
      
        return (<form className="bg-white p-3" onSubmit={this.addInventoryTransferFormSubmitHandler}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.addInventoryTransferForm.transaction_date ? moment(this.state.addInventoryTransferForm.transaction_date, 'YYYY-MM-DD').toDate() : false}
                        name="transaction_date,"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'addInventoryTransferForm')}
                        dateFormat="dd-MMM-yyyy"
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText="Please Enter Date of Transaction"
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label className="form-label require">Dispatching Warehouse</label>
                </div>
                <div className="col-sm-6">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addInventoryTransferForm', 'warehouse_from',() => {
                                if(this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y' && selectedOption){
                                    this.getAllApprovedIndentItemList();
                                }
                            });
                            this.onDispatchingWarehouseChange(selectedOption);
                        }}
                        options={this.state.allDispatchingWarehouses.filter(dw => dw.value !== this.state.addInventoryTransferForm.warehouse_to)}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.inventoryData && this.state.inventoryData.status && this.state.inventoryData.status == 36 ||
                            this.state.inventoryData && this.state.inventoryData.status && this.state.inventoryData.status == 35}
                        value={this.state.allDispatchingWarehouses.find(m => m.value == this.state.addInventoryTransferForm.warehouse_from)}
                        placeholder="Please Select Dispatching Warehouse"
                    />
                </div>
                <div className="col-sm-3">
                    {this.state.addInventoryTransferForm.warehouse_from ?
                        <Ax><button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary mx-2 pl0 pr0" style={{ height: "38px" }}>
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                            <ul className="dropdown-menu dropdown-menu-end cursor_pointer" aria-labelledby="dropdownMenuButton1">
                                <li><Link className="dropdown-item" onClick={() => this.changeWarehouseAddressModal(this.state.addInventoryTransferForm.warehouse_from, 'dispatch')}>Change Address</Link></li>
                            </ul></Ax>
                        : null}
                </div>
            </div>
            {this.state.dispatchingWarehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.dispatchingWarehouseDetail} /></div>
                </div>
                : (this.state.dispatchingWarehouseDetailLoading ? <Loader /> : null)
            }
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Receiving Warehouse</label>
                </div>
                <div className="col-sm-6">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addInventoryTransferForm', 'warehouse_to');
                            this.onReceivingWarehouseChange(selectedOption);
                        }}
                        options={this.state.allReceivingWarehouses.filter(rw => rw.value !== this.state.addInventoryTransferForm.warehouse_from)}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.inventoryData && this.state.inventoryData.status && this.state.inventoryData.status == 36}
                        value={this.state.allReceivingWarehouses.find(m => m.value == this.state.addInventoryTransferForm.warehouse_to)}
                        placeholder="Please Select Receiving Warehouse"
                    />
                </div>
                <div className="col-sm-3">
                    {this.state.addInventoryTransferForm.warehouse_to ?
                        <Ax><button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary mx-2 pl0 pr0" style={{ height: "38px" }}>
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                            <ul className="dropdown-menu dropdown-menu-end cursor_pointer" aria-labelledby="dropdownMenuButton1">
                                <li><Link className="dropdown-item" onClick={() => this.changeWarehouseAddressModal(this.state.addInventoryTransferForm.warehouse_to, 'receiving')}>Change Address</Link></li>
                            </ul></Ax>
                        : null}
                </div>
            </div>
            {this.state.receivingWarehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.receivingWarehouseDetail} /></div>
                </div>
                : (this.state.receivingWarehouseDetailLoading ? <Loader /> : null)
            }
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addInventoryTransferForm', 'status',() => {
                                this.setState({
                                    addInventoryTransferForm : {...this.state.addInventoryTransferForm,date_of_dispatch : '',date_of_receive : ''}
                                })
                            });
                        }}
                        options={this.state.allStatus}
                        required={true}
                        isDisabled={this.state.inventoryData}
                        value={this.state.allStatus.find(m => m.value == this.state.addInventoryTransferForm.status)}
                        placeholder="Please Select Status"
                    />
                </div>

            </div>
            {(this.state.addInventoryTransferForm.status == 35 || this.state.addInventoryTransferForm.status == 36) && !this.state.inventoryData && <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Dispatch</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.addInventoryTransferForm.date_of_dispatch ? moment(this.state.addInventoryTransferForm.date_of_dispatch, 'YYYY-MM-DD').toDate() : false}
                        name="date_of_dispatch"
                        onChange={(value, event) => this.formDateHandler('date_of_dispatch', value, 'addInventoryTransferForm')}
                        dateFormat="dd-MMM-yyyy"
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText="Please Enter Date of Dispatch"
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>}
            {this.state.addInventoryTransferForm.status == 36  && !this.state.inventoryData && <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Receipt</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.addInventoryTransferForm.date_of_receive ? moment(this.state.addInventoryTransferForm.date_of_receive, 'YYYY-MM-DD').toDate() : false}
                        name="date_of_receive"
                        onChange={(value, event) => this.formDateHandler('date_of_receive', value, 'addInventoryTransferForm')}
                        dateFormat="dd-MMM-yyyy"
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        minDate={moment(this.state.addInventoryTransferForm.date_of_dispatch, 'YYYY-MM-DD').toDate()}
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText="Please Enter Date of Receipt"
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>}
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Invoice Number & Date </label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="invoice_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addInventoryTransferForm.invoice_number}
                        onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                        placeholder="Enter Invoice/Document Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addInventoryTransferForm.invoice_date
                                ? moment(this.state.addInventoryTransferForm.invoice_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="invoice_date"
                        onChange={(value, event) => this.formDateHandler('invoice_date', value, 'addInventoryTransferForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Invoice/Document Date`}

                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            {this.state.addInventoryTransferForm.fullfill_purchase_qty == 'N' && <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Item Indent</label>
                </div>
                <div className="col-sm-9">
                    <button
                        type="button"
                        disabled={this.state.addInventoryTransferForm.warehouse_from && this.state.addInventoryTransferForm.warehouse_to ? false : true}
                        className="btn btn-link text-decoration-none"
                        onClick={() => this.pickIndentItemModalInit()}
                    ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} />  Pick Item from Approved Indent</button>
                </div>

            </div>}


            {this.inventoryTransferItemJsx()}
           
            <div className="row align-items-center mb-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addInventoryTransferForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    {/* <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label> */}
                    <table className="table  table-borderless mt-4">
                        <tbody>
                            {inventory_summary && inventory_summary.length > 0
                                ? (inventory_summary.map((it, k) => {
                                    return <tr className="subtotal_bg_color"><th>{it.key}</th><td className="text-end">{it.value}</td></tr>
                                }))
                                : null
                            }
                            {this.state.dispatchingWarehouseDetail && this.state.dispatchingWarehouseDetail.gstin ? null
                                : <tr className="subtotal_bg_color">
                                    <th>GST</th>
                                    <td className='float-end'>Not Applicable</td>
                                </tr>}
                            <tr className="subtotal_bg_color">
                                <th>Adjustment</th>
                                <td><input
                                    name="adjustment_amount"
                                    type="number"
                                    value={this.state.addInventoryTransferForm.adjustment_amount}
                                    onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                                    className="form-control text-end"
                                    autoComplete="off"
                                    placeholder="Adjustment"
                                /></td>
                            </tr>

                            <tr className="subtotal_bg_color">
                                <th>Final Total</th>
                                <td className='float-end'>{parseFloat(final_total_amount).toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {this.state.inventoryData ? null
                : <div className='mb-5'>
                    <div className="tab_content_wrapper my-1">
                        <span className="content_heading">Dispatch Details</span>

                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label ">Eway Bill Number & Date </label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="eway_number"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addInventoryTransferForm.eway_number}
                                onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                                placeholder="Enter Eway Number"
                            />
                        </div>
                        <div className="col-sm-4 add_calender_section">
                            <DatePicker
                                selected={
                                    this.state.addInventoryTransferForm.eway_date
                                        ? moment(this.state.addInventoryTransferForm.eway_date, 'YYYY-MM-DD').toDate()
                                        : false
                                }
                                name="eway_date"
                                onChange={(value, event) => this.formDateHandler('eway_date', value, 'addInventoryTransferForm')}
                                dateFormat="dd-MMM-yyyy"
                                className={"form-control"}
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                placeholderText={`Please Enter Eway Date`}

                            />
                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label ">Docket Number & Date </label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="document_number"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addInventoryTransferForm.document_number}
                                onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                                placeholder="Enter Docket Number"
                            />
                        </div>
                        <div className="col-sm-4 add_calender_section">
                            <DatePicker
                                selected={
                                    this.state.addInventoryTransferForm.document_date
                                        ? moment(this.state.addInventoryTransferForm.document_date, 'YYYY-MM-DD').toDate()
                                        : false
                                }
                                name="document_date"
                                onChange={(value, event) => this.formDateHandler('document_date', value, 'addInventoryTransferForm')}
                                dateFormat="dd-MMM-yyyy"
                                className={"form-control"}
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                placeholderText={`Please Enter Docket Date`}

                            />
                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label ">Transporter Name & Vehicle No.  </label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="transporter_number"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addInventoryTransferForm.transporter_number}
                                onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                                placeholder="Enter Transporter Number"
                            />
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="vechicle_registration_no"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addInventoryTransferForm.vechicle_registration_no}
                                onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                                placeholder="Enter vehicle Registration No"
                            />
                        </div>

                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label ">Number of Boxes & Weight  </label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="no_of_boxes"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addInventoryTransferForm.no_of_boxes}
                                onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                                placeholder="Enter Number of boxes"
                            />
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="weight"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addInventoryTransferForm.weight}
                                onChange={(e) => this.formInputHandler(e, 'addInventoryTransferForm')}
                                placeholder="Enter Weight"
                            />
                        </div>
                        <div className="col-sm-2">
                            <TapSelect
                                options={this.state.allWeightUnits}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addInventoryTransferForm', 'unit_of_weight')}
                                value={this.state.allWeightUnits.find(u => this.state.addInventoryTransferForm?.unit_of_weight == u.value)}
                                isSearchable={true}
                                isClearable={true}
                                placeholder="Unit"
                                menuPlacement="top"
                            />
                        </div>

                    </div>
                </div>}
            {!this.state.buttonDisabled && <div className="col-12 text-end fixed_footer">
                <button type="button" className="btn btn-secondary ms-2" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={this.closeEvent}>Cancel</button>
                <Ax>
                    <button type="button" className="btn btn-secondary ms-2" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={(e) => { this.addInventoryTransferFormSubmitEvent(e, 0); }}>
                        Save as Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    <button type="button" className="btn btn-primary ms-2" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={(e) => { this.addInventoryTransferFormSubmitEvent(e, this.state?.addInventoryTransferForm?.status); }}>
                        Save  {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                </Ax>
            </div>}
        </form>);
    }

    inventoryTransferItemJsx            =       ()      =>      {
        return (<div className="my-3 row">
            <div className="col-sm-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" style={{ width: "30%" }}>Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "14%" }}>Indent</th>
                            {this.state.track_batch_expiry == "Y" ?
                                (<th scope="col" className="text-center" style={{ width: "14%" }}>Batch Number & <br /> Expiry Date</th>)
                                : null}
                            <th scope="col" className="text-center" style={{ width: "11%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "9%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "7%" }}>GST</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "7%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                       {this.state.loading_item ? <tr>
                            <td className='text-center' colSpan={8}><Loader/></td>
                       </tr> : this.state.inventoryTransferItemRows.map((itemRow, itemKey) => {
                           let free_qty = itemRow && itemRow.current_stocks && itemRow.current_stocks.length > 0 ? itemRow.current_stocks.reduce((sum, a) => sum + Number(a.stock.free_qty), 0) : 0;
                           let pending_qty = itemRow && itemRow.pending_qty ? itemRow.pending_qty : 0;
                           let max_qty      =   free_qty >= pending_qty ? pending_qty : free_qty;
                           let item = itemRow && itemRow.item;
                           
                            return <tr className="" key={itemKey}>
                                <td className="text-center align-middle">{itemKey + 1}</td>
                                {(item
                                    ? <Ax>
                                        <td>
                                            <div className="row">
                                                <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>{item.name}</span></div>
                                                <div className='col-sm-2 text-end'>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>View Details</li>
                                                        {this.props?.permissions?.includes('isc-item-edit') &&  <li className="dropdown-item" onClick={() => {this.setState({showItemEditModal : false},() => {this.editItemModalInit(item,itemKey)})  }}>Edit Item</li>}

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-text"><b>Code: </b>{itemRow.item.item_code}</div>
                                            <div className="form-text">
                                                <b>Mfr: </b>{itemRow.item.manufacturer ? itemRow.item.manufacturer.name : '-'} (<b>MPN:</b> {itemRow.item.manufacturer_part_no ? itemRow.item.manufacturer_part_no : '-'})
                                            </div>

                                            <input
                                                name="remark"
                                                type="text"
                                                value={this.state.inventoryTransferItemRows[itemKey].remark}
                                                onChange={(e) =>  this.dynamicInputHandlerByKey(e, itemKey, 'inventoryTransferItemRows')}
                                                className="form-control mt10"
                                                autoComplete="off"
                                                placeholder="Item Remarks"
                                            />

                                        </td>
                                        {
                                            itemRow.stock_loading && item.category == "goods"
                                                ? <td colSpan={this.state.track_batch_expiry == 'Y' ? 4 : 3}><Loader /></td>
                                                : (<Ax>
                                                    <td>
                                                        {itemRow && itemRow.all_available_indent && itemRow.all_available_indent.length > 0 ?
                                                            <Ax>
                                                                <TapSelect
                                                                    changeEvent={(selectedOption, e) => {  
                                                                        let allRows =   this.state.inventoryTransferItemRows;
                                                                        allRows[itemKey]                =   {...this.state.inventoryTransferItemRows[itemKey] ,
                                                                        indent_id       :   selectedOption?.value,
                                                                        indnet_qty      :   selectedOption?.selectedIndentId?.indent_qty,
                                                                        pending_qty     :   selectedOption?.selectedIndentId?.pending_qty,
                                                                        transaction_date  :   selectedOption?.selectedIndentId?.transaction_date_display
                                                                        };
                                                                        this.setState({inventoryTransferItemRows : allRows})
                                                                    }}
                                                                    options={itemRow.all_available_indent}
                                                                    isSearchable={false}
                                                                    placeholder="Indent"
                                                                    isClearable={true}
                                                                    isDisabled={this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y'}
                                                                    value={itemRow.all_available_indent.find(s => s.value == this.state.inventoryTransferItemRows[itemKey].indent_id)}
                                                                />
                                                                {itemRow && itemRow.indnet_qty && itemRow.indnet_qty > 0
                                                                    ? <div className='py-1'>
                                                                         Date : <b> {itemRow?.transaction_date ? itemRow?.transaction_date : "-"}</b> <tapIcon.FontAwesomeIcon icon={tapIcon.faEye} onClick={() => this.viewIndentDetailModalInit(itemRow?.indent_id)} className="text-end" color="#0052CC" /> <br />
                                                                        Indent Qty : <b> {itemRow?.indnet_qty ? itemRow?.indnet_qty : "-"}</b> <br />
                                                                        {this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y' ? "Purchase Qty" : "Pending Qty"} : <b> {itemRow?.pending_qty ? itemRow?.pending_qty : 0}</b> <br />

                                                                    </div>
                                                                    : null}
                                                            </Ax>
                                                            : <div className='text-center'>-</div>}
                                                    </td>
                                                    {this.state.track_batch_expiry == "Y"
                                                        ? <td className='text-center'>
                                                            <button
                                                                type="button"
                                                                onClick={() => this.selectItemBatchModalInit(itemRow.item, itemRow.current_stocks, itemRow.pending_qty, itemKey,this?.state.inventoryTransferItemRows[itemKey]?.selected_batch_list)}
                                                                className="btn btn-link text-decoration-none"
                                                                disabled={itemRow && itemRow.current_stocks && itemRow.current_stocks.length > 0 ? false : true}
                                                            >+ Select</button>
                                                            {this?.state.inventoryTransferItemRows[itemKey]?.selected_batch_list?.map((rl, k) => {
                                                                
                                                                return rl.qty > 0 ? <div key={k} className='text-center my-1'>{rl.batch_number && rl.expiry_date ? <span>{rl.batch_number} & {rl.expiry_date_display} : </span> : rl.batch_number && !rl.expiry_date ? <span>{rl.batch_number} : </span> : !rl.batch_number && rl.expiry_date ? <span>{rl.expiry_date_display} : </span> : <span>BNA <sup>#</sup></span>}  <b>{rl.qty}</b></div> : null
                                                            })}
                                                           
                                                        </td> : null
                                                    }
                                                    <td>
                                                        <input
                                                            name="qty"
                                                            type="number"
                                                            value={itemRow.qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(e, itemKey, 'inventoryTransferItemRows',() => {
                                                                let inventoryTransferItemRows = this.state.inventoryTransferItemRows;
                                                                
                                                                let selectedBatchList = inventoryTransferItemRows[itemKey].selected_batch_list || [];
                                                              
                                                                  let row                  =      {...inventoryTransferItemRows[itemKey],
                                                                    selected_batch_list  :        selectedBatchList.map(batchItem => {return { ...batchItem, qty: parseFloat(e?.target?.value) };})
                                                                };
                                                                inventoryTransferItemRows[itemKey]            =   row;
                                                                let allInventoryTransferItemRows        =       inventoryTransferItemRows.filter(i => i.item ? true : false)
                                                                
                                                                this.setState({ inventoryTransferItemRows: allInventoryTransferItemRows })
                                                                
                                                            })}
                                                            className="form-control text-end"
                                                            placeholder="Qty"
                                                            required={true}
                                                            min={.01}
                                                             max={max_qty}
                                                            disabled={this.state.track_batch_expiry == 'Y' && itemRow && itemRow.current_stocks && itemRow.current_stocks.length > 0 ? true : false}
                                                            step=".01"
                                                        />
                                                        <span className="text-helping input-group-text text-end pr0">{item && item.measuring_unit ? item.measuring_unit.name : ''}</span>
                                                       
                                                      <div>
                                                        
                                                            {item.category == "services" ? null :
                                                                itemRow.current_stocks && itemRow.current_stocks.length > 0 ?
                                                                <small className={['my-1', (itemRow.qty > (itemRow.current_stocks && itemRow.current_stocks.length > 0 ? itemRow.current_stocks.reduce((sum, a) => sum + Number(a.free_qty), 0) : 0)) ? 'text-danger' : ''].join(' ')}>
                                                                        Stock In Hand: <b>{itemRow.current_stocks && itemRow.current_stocks.length > 0 ? itemRow.current_stocks.reduce((sum, a) => sum + Number(a.stock.qty), 0) : itemRow.qty_without_batch && itemRow.qty_without_batch > 0 && this.state.inventoryData && itemRow.stock_qty ? itemRow.qty_without_batch + Number(itemRow.stock_qty) : itemRow.qty_without_batch && itemRow.qty_without_batch > 0 ? itemRow.qty_without_batch : "Nil"} </b> <br />
                                                                        Free Stock: {this.state.inventoryData ? <b> {itemRow.current_stocks && itemRow.current_stocks.length > 0 && itemRow && itemRow.stock_qty ? (free_qty + Number(itemRow?.stock_qty)) : itemRow.freeqty_without_batch && itemRow.freeqty_without_batch > 0 && itemRow.stock_qty ? itemRow.freeqty_without_batch + Number(itemRow.stock_qty) : "Nil"} </b>
                                                                            : <b> {itemRow.current_stocks && itemRow.current_stocks.length > 0 ? itemRow.current_stocks.reduce((sum, a) => sum + Number(a.stock.free_qty), 0) : itemRow.freeqty_without_batch && itemRow.freeqty_without_batch > 0 ? itemRow.freeqty_without_batch : "Nil"} </b>} <br />
                                                                    </small>
                                                                    : itemRow.freeqty_without_batch && itemRow.freeqty_without_batch > 0 ?
                                                                    <small className={['my-1', item.category !== 'services' && (itemRow.freeqty_without_batch < itemRow.qty) && 'text-danger'].filter(Boolean).join(' ')}>
                                                                            Stock In Hand: <b>{itemRow.current_stocks && itemRow.current_stocks.length > 0 ? itemRow.current_stocks.reduce((sum, a) => sum + Number(a.stock.qty), 0) : itemRow.qty_without_batch && itemRow.qty_without_batch > 0 && this.state.inventoryData && itemRow.stock_qty ? itemRow.qty_without_batch + Number(itemRow.stock_qty) : itemRow.qty_without_batch && itemRow.qty_without_batch > 0 ? itemRow.qty_without_batch : "Nil"}  </b> <br />
                                                                            Free Stock: {this.state.inventoryData ? <b> {itemRow.current_stocks && itemRow.current_stocks.length > 0 && itemRow && itemRow.stock_qty ? (free_qty + Number(itemRow?.stock_qty)) : itemRow.freeqty_without_batch && itemRow.freeqty_without_batch > 0 && itemRow.stock_qty ? itemRow.freeqty_without_batch + Number(itemRow.stock_qty) : "Nil"} </b>
                                                                                : <b> {itemRow.current_stocks && itemRow.current_stocks.length > 0 ? itemRow.current_stocks.reduce((sum, a) => sum + Number(a.stock.free_qty), 0) : itemRow.freeqty_without_batch && itemRow.freeqty_without_batch > 0 ? itemRow.freeqty_without_batch : "Nil"} </b>} <br />
                                                                        </small> : <small className={['my-1 text-danger']}>
                                                                            Stock In Hand: <b>Nil</b> <br />
                                                                            Free Stock: <b>  Nil </b> <br />
                                                                        </small>
                                                            }
                                                      </div>
                                                        
                                                    </td>
                                                    <td>
                                                        <TapInputNumber
                                                            name="rate"
                                                            value={itemRow.rate}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'inventoryTransferItemRows',null,'rate',e)}
                                                            placeholder="Unit Rate"
                                                            required={true}
                                                            min={0}
                                                        />
                                                    </td>
                                                    <td className='text-end rightSelectText'>
                                                        <TapSelect
                                                            changeEvent={(selectedOption, e) => {
                                                                this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'gst', itemKey, 'inventoryTransferItemRows')
                                                            }}
                                                            options={this.state.allGstSlabs}
                                                            isSearchable={false}
                                                            value={this.state.allGstSlabs.find(s => s.value == this.state.inventoryTransferItemRows[itemKey].gst)}
                                                            placeholder="GST"
                                                        />
                                                        {this.state.dispatchingWarehouseDetail && this.state.dispatchingWarehouseDetail.gstin
                                                            ? item ? <span className="text-helping input-group-text text-end pr0">{this.state.gstTypeName}</span> : null
                                                            : <div className='text-center'>NA <sup className='text-danger'>#</sup></div>}
                                                    </td>
                                                    <td className="pt-4 text-end">{(itemRow.rate * itemRow.qty).toFixed(2)} </td>
                                                </Ax>)}
                                    </Ax>
                                    : <Ax>
                                        <td className="align-middle">
                                            <InputItemSearch
                                                needIndent={'Y'}
                                                changeEvent={(selectedOption) => {
                                                    this.onItemSelect(selectedOption, itemKey);
                                                }}
                                                only_stocked_item={this.state.only_stocked_item}
                                                only_active_item='Y'
                                                indent_exclude_warehouse_id={this.state.addInventoryTransferForm.warehouse_from}
                                                indent_warehouse_id={this.state.addInventoryTransferForm.warehouse_to}
                                                stock_warehouse_ids={[this.state.addInventoryTransferForm.warehouse_from]}
                                            />
                                        </td>
                                        <td colSpan={this.state.track_batch_expiry == 'Y' ? 6 : 5}></td>
                                    </Ax>
                                )}
                                <td className="text-center align-middle">
                                    {this.state.inventoryTransferItemRows.length > 1
                                        ? <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>
                                        : null
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table> 
                
            </div>
            <div className="col-sm-12 ">
            <div className='text-xs form-text'>#BNA : Batch Not Available</div>
            {this.state.addInventoryTransferForm.fullfill_purchase_qty == 'N' &&  <div className='text-end'>
                {this.state.addInventoryTransferForm && this.state.addInventoryTransferForm.warehouse_from && this.state.addInventoryTransferForm.warehouse_to ?
                    <a role="button" onClick={() => {this.setState({showItemSearchModal : false},() => this.itemSearchModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                    : null}
              {this.props?.permissions?.includes('isc-item-add') && <a role="button" onClick={() => {this.setState({showItemAddModal : false},() => this.addItemModalInit())}} className="link-primary ml3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item </a>}
                <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                </div>}
            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={this.state.only_stocked_item == "Y" ? "N" : "Y"}
                                checked={this.state.only_stocked_item == "Y"}
                                onChange={(e) => { this.setState({ only_stocked_item: e.target.value, }); }}
                                id="onlyStockedItem"
                            />
                            <label className="form-check-label" htmlFor="onlyStockedItem">
                                Show only those items that are available in the selected warehouse
                            </label>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>)
    }

    selectItemBatchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="selectItemBatchModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="selectItemBatchModalLabel">Select Batch Number & Expiry Date</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Item Name (Code)   </td>
                                        <th>{this.state.selectedItemData ? `${this.state.selectedItemData.name} (${this.state.selectedItemData.item_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Dispatching Warehouse   </td>
                                        <th>{this.state.dispatchingWarehouseDetail ? `${this.state.dispatchingWarehouseDetail.name}` : "-"}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.submitBatchFormSubmit} id="selectItemBatchForm">
                            <div className="modal-body">
                                <table className="table table-bordered table-fixed  bg-white table-sm">
                                    <thead className="table-secondary" >
                                        <tr>
                                            <th scope="col" style={{ width: "5%" }} className="text-center">
                                                <input type="checkbox"  onChange={this.checkAllBatchList}  id="all_batch_list_check"  /></th>
                                            <th scope="col" style={{ width: "15%" }}>Batch Number</th>
                                            <th scope="col" style={{ width: "13%" }}> Expiry date</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-end">Qty</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-end">Allocated</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-end">On-hold</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-end">InTransit</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-end">Free Stock</th>
                                            <th scope="col" style={{ width: "10%" }}></th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {this.state.selectedItemStocks && this.state.selectedItemStocks.length > 0
                                            ? this.state.selectedItemStocks.map((stockList, i) => {
                                              
                                               let on_hold = this.state.inventoryData && stockList.stock && stockList.stock.on_hold ? Number(stockList?.stock?.on_hold) - Number(stockList.stock_qty) : stockList?.stock?.on_hold;
                                               let free_qty = this.state.inventoryData && stockList.stock && stockList.stock.free_qty ? Number(stockList?.stock?.free_qty) + Number(stockList.stock_qty) : stockList?.stock?.free_qty;
                                                return (<tr>

                                                    <td className='text-center'>
                                                        <input
                                                            type="checkbox"
                                                            className="batch_expiry_checkbox"
                                                            name="batch_number"
                                                            value={stockList.stock.batch_number}
                                                            data-expiry_date={stockList.stock.expiry_date}
                                                            data-amount={stockList.stock.amount}
                                                            
                                                        />
                                                    </td>
                                                    <td>{stockList.stock && stockList.stock.batch_number ? stockList.stock.batch_number : "-"} </td>
                                                    <td>{stockList.stock && stockList.stock.expiry_date_display ? stockList.stock.expiry_date_display : "-"} </td>
                                                    <td className="text-end">{stockList.stock && stockList.stock.total_qty ? stockList.stock.total_qty : "0"} </td>
                                                    <td className="text-end">{stockList.stock && stockList.stock.allocated_qty ? stockList.stock.allocated_qty : "0"} </td>
                                                    <td className="text-end">{on_hold ? on_hold : "0"} </td>
                                                    <td className="text-end">{stockList.stock && stockList.stock.intransit ? stockList.stock.intransit : "0"} </td>
                                                    <td className="text-end">{free_qty ? free_qty : "0"} </td>
                                                    <td>
                                                        <TapInputNumber
                                                            className='form-control form-control-sm text-end'
                                                            placeholder='Qty'
                                                            name="new_qty"
                                                            value={stockList.new_qty}
                                                            onChange={(e) => {
                                                                this.state.selectedItemStocks[i] = { ...stockList, new_qty: e };
                                                                this.dynamicInputHandlerByKeyNameVal(e, 'new_qty', i, 'selectedItemStocks')
                                                            }}
                                                            max={this.state.inventoryData ? (stockList.stock.free_qty + stockList.stock.qty)  : stockList.stock.free_qty}
                                                             step=".01" />

                                                    </td>

                                                </tr>)
                                            })
                                            : <tr><td className='text-center' colSpan={8}>No Record</td></tr>}

                                    </tbody>
                                </table>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    pickIndentItemModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="pickIndentItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="pickIndentItemModalLabel">Pick Item from Approved Indent</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Receiving Warehouse   </td>
                                        <th>{this.state.receivingWarehouseDetail ? `${this.state.receivingWarehouseDetail.name}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Dispatching Warehouse   </td>
                                        <th>{this.state.dispatchingWarehouseDetail ? `${this.state.dispatchingWarehouseDetail.name}` : "-"}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.submitPickIndentFormSubmit} id="submitPickIndentForm">
                            <div className="modal-body">
                                <table className="table table-bordered table-sm align-middle bg-white my-2">
                                    <thead className="align-middle table-secondary">
                                        <tr>
                                            <th className="text-center" style={{ width: "5%" }}><input type="checkbox" onChange={this.checkAllIndentItemList} id="all_indent_list_check" /></th>
                                            <th className="text-center" style={{ width: "10%" }}>Date of Transaction</th>
                                            <th className="text-center" style={{ width: "10%" }}>Transaction ID</th>
                                            <th style={{ width: "20%" }}>Item Name</th>
                                            <th style={{ width: "15%" }}>Item Code </th>
                                            <th className='text-end' style={{ width: "8%" }}>Pending {this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y' ? "Purchase" : null} Indent</th>
                                            <th className='text-end' style={{ width: "8%" }}>Freestock</th>
                                            {/* <th className='text-end' style={{ width: "8%" }}>Fullfill Qty</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {this.state.approved_indent_listing
                                            ? (<tr><td colSpan="9" className="text-center"><Loader /></td></tr>)
                                            : */}
                                            {this.state.indent_listing && this.state.indent_listing.length > 0
                                                ? (this.state.indent_listing.map((rowData, k) => {
                                                    return (<tr key={k}>
                                                        <td className='text-center'>
                                                            <input
                                                                type='checkbox'
                                                                name="indent"
                                                                value={rowData.transaction_id}
                                                                data-itemId={rowData?.item?.id}
                                                                // checked={this.state.inventoryTransferItemRows.find(element => {
                                                                //     if (element?.indent_id == rowData.transaction_id && element?.item?.id == rowData?.item?.id) {
                                                                //         return true;
                                                                //     } else {
                                                                //         return false;
                                                                //     }
                                                                // })}
                                                               
                                                                className="indent_list_id_checkbox"
                                                                disabled={this.state.inventoryTransferItemRows.find(element => {
                                                                    if (element?.indent_id == rowData.transaction_id && element?.item?.id == rowData?.item?.id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                })}
                                                            />
                                                        </td>
                                                        <td className='text-center' >{rowData.transaction_date_display ? rowData.transaction_date_display : "-"}</td>
                                                        <td className='text-center'>{rowData.transaction_id ? rowData.transaction_id : "-"}</td>
                                                        <td>{rowData.item && rowData.item.name ? rowData.item.name : "-"}</td>
                                                        <td>{rowData.item && rowData.item.item_code ? rowData.item.item_code : "-"}</td>
                                                        <td className='text-end'>{rowData.indent_qty && this.state.addInventoryTransferForm.fullfill_purchase_qty == 'N'  ? <NumberFormatter number={rowData.indent_qty} precision={2} />   :  rowData.purchase_qty ? <NumberFormatter number={rowData.purchase_qty} precision={2} /> :  0}</td>
                                                        <td className='text-end'>{rowData.dispatching_warehouse_free_stock ? <NumberFormatter number={rowData.dispatching_warehouse_free_stock} precision={2} />  : 0}</td>
                                                        {/* <td>
                                                            <input name="dispatching_fullfill_qty"
                                                                type="number"
                                                                value={this.state.indent_listing[k]?.dispatching_fullfill_qty}
                                                                onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'dispatching_fullfill_qty')}
                                                                className="form-control text-end"
                                                                autoComplete="off"
                                                                placeholder="FullFill Qty"
                                                            // max={i.pending_qty}
                                                            />
                                                        </td> */}

                                                    </tr>);
                                                })
                                                )
                                                : !this.state.approved_indent_listing ?  (<tr><td className="text-center" colSpan={8}>No Record Found</td></tr>) : null
                                        }

                                    </tbody>
                                </table>
                                   {this.state.approved_indent_listing
                                            ? (<div className="text-center"><Loader /></div>)
                                            :null}

                                <div className='text-end mt-1 mb-5'>
                                    {this.state.indnetListingMeta && this.state.indnetListingMeta.current_page < this.state.indnetListingMeta.last_page
                                        ? <button type="button" className="btn btn-secondary me-3 " style={{ lineHeight: "6.3px!important", height: "27px" }} onClick={() => this.loadMoreList()} >Load More</button>
                                        : null}
                                </div>
                                {/* <DisplayListPagination
                                    meta={this.state.indnetListingMeta}
                                    onPageChange={(e) => this.getAllApprovedIndentItemList(e.selected + 1)}
                                /> */}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    viewIndentDetailModalJsx                             =   ()  =>  {
        let indent = this.state.indentDetail;
        return (
            <div className="modal fade" id="viewIndentDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="viewIndentDetailModalLabel">Indent : {this.state.indentTransId ? this.state.indentTransId  : "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {!this.state.indent_detail_loading
                                ? (<Ax>
                                    {indent
                                        ?
                                        <div>
                                            <table className="table table-bordered bg-white" >
                                                <thead className="table-secondary">
                                                    <tr>
                                                        <th style={{ width: "33%" }}>ID</th>
                                                        <th style={{ width: "33%" }}>Date</th>
                                                        <th style={{ width: "33%" }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{indent.transaction_id ? indent.transaction_id : "-"}</td>
                                                        <td scope="col">{indent.transaction_date_display ? indent.transaction_date_display : "-"}</td>
                                                        <td scope="col" className="align-middle">
                                                            {indent.status_data ? indent.status_data.name : ''}
                                                        </td>  </tr>
                                                </tbody>
                                            </table>
                                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "33%" }} >Warehouse</td>
                                                        <td colSpan={2}>{indent.warehouse_detail ? <div><b>{indent.warehouse_detail.name} ({indent.warehouse_detail.code})</b> <br />
                                                            {indent.warehouse_detail ?
                                                                <div className='pt-1'>Address : {indent.warehouse_detail.address1 ? indent.warehouse_detail.address1 + ", " : ""}
                                                                    {indent.warehouse_detail.address2 ? indent.warehouse_detail.address2 + ", " : ""}
                                                                    {indent.warehouse_detail.landmark ? indent.warehouse_detail.landmark + ", " : ""}
                                                                    {indent.warehouse_detail.city ? indent.warehouse_detail.city + ". " : "-"} <br />
                                                                    Destination of Supply : <b>{indent.warehouse_detail.state_name}</b> <br />
                                                                    GSTIN  : {indent.warehouse_detail.gstin ? indent.warehouse_detail.gstin : "-"} <br />
                                                                </div> : "-"}</div> : "-"} <br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col">Title</td>
                                                        <th scope="col">{indent.title ? indent.title : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }} >Indent For</td>
                                                        <th>{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "-"} </th>
                                                    </tr>
                                                    {indent.indent_for ?
                                                        <tr>
                                                            <td style={{ width: "33%" }} >{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "null"}</td>
                                                            <th>
                                                                {indent.indent_for == "asset" && indent.assetProfile ? indent.assetProfile.name : indent.indent_for == "workstation" && indent.workstation ? indent.workstation.workstation_name : indent.indent_for == "employee" && indent.employee ? indent.employee.name : "-"}
                                                            </th>
                                                        </tr> : null}
                                                    {indent.additional_attributes_data && indent.additional_attributes_data.length > 0
                                                        ? indent.additional_attributes_data.map((attr) => {
                                                            return (<tr key={attr.key}>
                                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                                <th scope="col">{attr.value ? attr.value : "-"}</th>
                                                            </tr>)
                                                        }) : null}
                                                </tbody>
                                            </table>
                                            <table className="table  table-sm table-bordered table-responsive bg-white my-3" id="dataTable">
                                                <thead className="table-secondary">
                                                    <tr className="text-end">
                                                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllItem} id="all_check_indent" /></th>
                                                        <th scope="col" className="text-center" style={{ width: "31%" }}>Item Details</th>
                                                        <th scope="col" className="text-center" style={{ width: "8%" }}>Indent Qty</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Fullfilled</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pur. Req</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>PO</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Purchase</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>In Transit </th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Cancelled  </th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pending </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        indent && indent?.items && indent?.items?.length > 0 ? indent?.items?.map((i, k) => {
                                                            return (<tr key={k} className="text-end">
                                                                <td className='text-center'>
                                                                    <input
                                                                        type='checkbox'
                                                                        name="foo"
                                                                        value={i?.itemData?.id}
                                                                        data-pendingQty={i.pending_qty}
                                                                        className="indent_item_id_checkbox"
                                                                        data-warehouseId={indent.warehouse_id}
                                                                        data-itemData={i?.itemData}
                                                                        data-transactionId={indent.transaction_id}
                                                                        data-statusLevel={indent.status_data && indent.status_data.level}
                                                                        data-statusIds={indent.status_data && indent.status_data.id}
                                                                    />
                                                                </td>
                                                                <td className="text-start">{i.itemData ? <div className='row'>
                                                                    <div className='col-sm-9'>
                                                                        <ItemViewInList item={i.itemData} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.itemData)} />
                                                                    </div>

                                                                </div> : null}</td>
                                                                <td>{i.indent_qty ? i.indent_qty : 0}</td>
                                                                <td>{i.fullfilled_qty ? i.fullfilled_qty : 0}</td>
                                                                <td>{i.req_qty ? i.req_qty : 0}</td>
                                                                <td>{i.po_qty ? i.po_qty : 0}</td>
                                                                <td>{i.purchase_qty ? i.purchase_qty : 0}</td>
                                                                <td>{i.transit_qty ? i.transit_qty : 0}</td>
                                                                <td>{i.cancelled_qty ? i.cancelled_qty : 0}</td>
                                                                <td>{i.pending_qty ? i.pending_qty : 0}</td>
                                                            </tr>);


                                                        })
                                                            : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                            <div className="row align-items-center ">
                                                <div className="col-sm-6">
                                                    <label htmlFor="notes" className="form-label">Notes</label>
                                                    <div>{indent.notes ? indent.notes : "-"}</div>

                                                </div>
                                                <div className=" col-sm-6">

                                                    <table className="table  table-borderless">
                                                        <tbody>

                                                            <tr className="subtotal_bg_color">
                                                                <th>Total Quantity</th>
                                                                <td className='float-end'>{indent.total_qty ? indent.total_qty : 0} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                </Ax>)
                                : <Loader />}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


    render              =             ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Inventory Transfer Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-sm-12">
                    <h3>{this.state.inventoryData ? "Edit" : "New"} {this.state.addInventoryTransferForm.fullfill_purchase_qty == 'Y' && "Purchase"} Inventory Transfer {this.state.inventoryData ? <span> of Transaction ID : {this.state.inventoryData.transaction_id}</span> : null}</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-sm-12 pl4">
                        {this.saveInventoryTransferFormJsx()}
                    </div>
                </div>
            </div>
            {this.selectItemBatchModalJsx()}
            {this.pickIndentItemModalJsx()}
            {this.viewIndentDetailModalJsx()}
            <ItemDetailsModal ref={this.itemDetailsRef} /> 
            {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            {this.state.showItemSearchModal && <ItemSearchModal ref={this.itemSearchRef}
                            afterSelectingItems={(selectedItems) => {
                                this.addItemsfromSearch(selectedItems);
                            }}
                        />}
            {
                this.state.dispatchAddressModalHandle &&
                <ChangeWarehouseAddressModal ref={this.changeWarehouseAddressRef} warehouseDetail={(address, type) => this.handleDispatchWarehouseAddress(address, type)} sameWarehouseAddress={this.state.sameDispatchWarehouseAddress} selecteSameAddress={this.state.selectDispatchAddress ? false : true} selectedShippingAddress={this.state.selectDispatchAddress}/>
            }
            {
                this.state.receivingAddressModalHandle && 
                <ChangeWarehouseAddressModal ref={this.changeWarehouseAddressRef} warehouseDetail={(address, type) => this.handleReceivingWarehouseAddress(address, type)} sameWarehouseAddress={this.state.sameReceivingWarehouseAddress} selecteSameAddress={this.state.selectReceivingAddress ? false : true} selectedShippingAddress={this.state.selectReceivingAddress}/>
            }
        </ApplicationLayout>);
    }

    handleDispatchWarehouseAddress = (address, type) => {
        if(type === 'same') {
            this.setState({ selectDispatchAddress : null, dispatchingWarehouseDetail : this.state.sameDispatchWarehouseAddress });
        }else {
            this.setState({ selectDispatchAddress : address, dispatchingWarehouseDetail : address });
        }
    }

    handleReceivingWarehouseAddress = (address, type) => {
        if(type === 'same') {
            this.setState({ selectReceivingAddress : null, receivingWarehouseDetail : this.state.sameReceivingWarehouseAddress }, () => {
                if(this.state.receivingWarehouseDetail?.state_id === this.state.dispatchingWarehouseDetail?.state_id){
                    this.setState({gstTypeName : 'GST'})
                }else{
                    this.setState({gstTypeName : 'IGST'}) 
                }
            });
        }else {
            this.setState({ selectReceivingAddress : address, receivingWarehouseDetail : address }, () => {
                if(this.state.receivingWarehouseDetail?.state_id === this.state.dispatchingWarehouseDetail?.state_id){
                    this.setState({gstTypeName : 'GST'})
                }else{
                    this.setState({gstTypeName : 'IGST'}) 
                }
            });
        }
    }

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions              :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(InventoryTransferAdd);