import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import { Modal } from 'bootstrap';
import TapIcon from '../../../../services/TapIcon';
import TapSelect from "../../../../components/ui/TapSelect";
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import Ax from '../../../../components/hoc/Ax';
import { toast } from 'react-toastify';


class Workflow extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state              =       {
            saveFormSubmitting      :       false,
            allWorkflowList         :       [],
            workflowLoading         :       false,
            selectedTabId           :       '',
            activity_form_loading   :       false,
            allActivitiesList       :       [],
            allSelectedActivityList :       [],
            updateActivityForm      :       {workflow_id : '',asset_activity_ids : []},
            workflowDetailLoaded    :       false,
            workflowDetail          :       null,
            workflow_detail_loading :       false,
            selectedWorkflowSteps   :       null,
            role_detail_loading     :       false,
            allRoleList             :       [],
            allAdditionalAttributes :       [],
            updatePermissionForm    :       {workflow_id : '',step_id : '',accessibilities : []},
            updateAttributesForm    :       {workflow_id : '',step_id : '',additional_attribute_keys : []}
        }
            this.task_workflow_url  =    IAM_API_BASE_URL + '/activity_task/workflow';
            this.task_detail_url    =   IAM_API_BASE_URL + '/activity_task/workflow_detail/' ; 
            this.task_formdata_url  =   IAM_API_BASE_URL + '/activity_task/workflow_activity/formdata';
            this.task_url           =    IAM_API_BASE_URL + '/activity_task/workflow_activity';
            this.task_step_role     =   IAM_API_BASE_URL + '/activity_task/workflow_step_role';
            this.task_additional_url    =   IAM_API_BASE_URL + '/activity_task/workflow_step_additinal_attributes'
    }

    componentDidMount() {
        this.getWorflowListing();
        this.getWorkflowFormData()
        this.initializeData(this.props)
        this.updateActivityModal         =    new Modal(document.getElementById('updateActivityModal'), {keyboard: false, backdrop :false});
        this.editPermissionModal         =    new Modal(document.getElementById('editPermissionModal'), {keyboard: false, backdrop :false});
        this.linkAttributeModal          =    new Modal(document.getElementById('linkAttributeModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.attributes_lists !== this.props.attributes_lists) {
            this.initializeData(nextProps)
         }     
    }

    initializeData          =       (props)         =>      {
        if(props){
            this.setState({
                 allAdditionalAttributes     :       props.attributes_lists && props.attributes_lists.length > 0 ? props.attributes_lists.map(at => {return({value:at.key,label:at.name})} )  : [],
            })
           
        }
    }

    getWorflowListing              =    ()           =>     {
        this.setState({workflowLoading : true})
        HttpAPICall.withAthorization('GET', this.task_workflow_url, this.props.access_token , {}, {}, (resp) => {
          let respData = resp.data;
          this.setState({
            allWorkflowList         :       respData,
            selectedTabId           :       respData && respData.length > 0 ? respData[0].id  : ''
          },() => {
             if(respData && respData.length > 0){
                this.getWorkflowDetail(respData[0].id)
             }
          })
        }).then(() => this.setState({workflowLoading : false}));
    }

    showSelectedTab         =       (id)        =>      {
        this.setState({
            selectedTabId           :       id,
        },() => {
            if(id){
                 this.getWorkflowDetail(id)
               
            }
        })
    }

    getWorkflowDetail           =       (id)        =>      {
        this.setState({ workflow_detail_loading: true })
        HttpAPICall.withAthorization('GET',this.task_detail_url  + id, this.props.access_token, {}, {}, (resp) => {
            
            let respData = resp.data;
            this.setState({
                workflowDetail          :       respData,         
            },() => {
               
            })
        }).then(() => this.setState({ workflow_detail_loading : false }));
    }

    updateActivityModalInit     =       (selected_workflow_data)      =>      {
       if(selected_workflow_data){
            let activities          =       selected_workflow_data.activities && selected_workflow_data.activities.length > 0 ? selected_workflow_data.activities.map(act => {return(act.id)})  : []
          
            let allSelectedActivities = [];
            let activitity_list = this.state.allWorkflowList && this.state.allWorkflowList.length > 0 && this.state.allWorkflowList.filter(p => { return (p.activities && p.activities.length > 0 ? true : false) });
            let allActivityList = activitity_list && activitity_list.length > 0 && allSelectedActivities.concat(activitity_list.flatMap((d) => { return d.activities.map(p => { return ({ value: p.id, label: p.name }) }) }))
            const key = 'value';
            const UniqueActivityByKey = allActivityList && allActivityList.length > 0 && [...new Map(allActivityList.map(item => [item[key], item])).values()];
            
            this.setState({
                updateActivityForm      :       {
                    workflow_id             :       selected_workflow_data.id,
                    asset_activity_ids          :       activities
                },
                allSelectedActivityList     :       UniqueActivityByKey && UniqueActivityByKey.length > 0 ? UniqueActivityByKey.map(uq => uq.value)  : []
            })
         }
        this.updateActivityModal.show();
    }

   
    getWorkflowFormData             =    ()           =>     {
        this.setState({activity_form_loading : true})
        HttpAPICall.withAthorization('GET', this.task_formdata_url, this.props.access_token , {}, {}, (resp) => {
          let respData = resp.data;
         
          this.setState({
            allActivitiesList       :       respData.activities && respData.activities.length > 0 ? respData.activities.map(act => {return({value:act.id,label:act.name})}) : [],
            allRoleList          :       respData.roles && respData.roles.length > 0 ? respData.roles.map(st => {return({value:st.id,label:st.role})})  : [],      
        },() => {
             
          })
        }).then(() => this.setState({activity_form_loading : false}));
    }

    submitUpdateActivityForm        =       (e)         =>      {
        e.preventDefault();
        
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.task_url, this.props.access_token, {}, { ...this.state.updateActivityForm}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.updateActivityModal.hide();
            this.getWorflowListing()
            this.setState({
                updateActivityForm      :  {workflow_id : '',asset_activity_ids : []},
            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    editPermissionModalInit         =       (stepData,workflow_id)          =>      {
        this.editPermissionModal.show();
        
        if(stepData){
            this.setState({
                selectedWorkflowSteps       :       stepData,
                updatePermissionForm         :       {workflow_id : workflow_id,step_id: stepData.id,accessibilities:stepData.actions.map(act => {return({action : act.action,roles:act.roles && act.roles.length > 0 ? act.roles.map(rol => rol.id) : []})})}
            })
        }
    }

    submitUpdatePermissionForm      =       (e)         =>          {
        e.preventDefault();
       
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.task_step_role, this.props.access_token, {}, { ...this.state.updatePermissionForm}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.editPermissionModal.hide();
            this.getWorkflowDetail(this.state.workflowDetail?.id)
            this.setState({
                updatePermissionForm    :       {workflow_id : '',step_id : '',accessibilities : []}
            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    linkAttributeModalInit         =       (stepData,workflow_id)          =>      {
        this.linkAttributeModal.show();
      
        if(stepData){
            let additional_attributes           =   stepData.fields && stepData.fields.length > 0 ? stepData.fields.filter(fi => fi.is_additional_attribute == 'Y') :  [];
            let additional_attribute_keys       =   additional_attributes && additional_attributes.length > 0 ? additional_attributes.map(list => list.key)   : [];
            
            this.setState({
                selectedWorkflowSteps       :       stepData,
                updateAttributesForm        :       {workflow_id : workflow_id,step_id: stepData.id,additional_attribute_keys : additional_attribute_keys}
            })
        }
    }

    submitUpdateAttributesForm      =       (e)         =>          {
        e.preventDefault();
       
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.task_additional_url, this.props.access_token, {}, { ...this.state.updateAttributesForm}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkAttributeModal.hide();
            this.getWorkflowDetail(this.state.workflowDetail?.id)
            this.setState({
                updateAttributesForm    :      {workflow_id : '',step_id : '',additional_attribute_keys : []}
            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    
    render() {
       
        return (<section>
            {this.state.workflowLoading ? <Loader />
                : <Ax>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {this.state.allWorkflowList && this.state.allWorkflowList.length > 0
                            ? this.state.allWorkflowList.map(wl => {
                                return (<li className="nav-item" role="presentation">
                                    <button className={['nav-link', this.state.selectedTabId == wl.id ? 'active' : ''].join(' ')} id={`${wl.name}-tab`} data-bs-toggle="tab" data-bs-target={`#b${wl.id}`}
                                        type="button" role="tab" aria-controls={`b${wl.id}`} aria-selected="true" onClick={() => this.showSelectedTab(wl.id)}>{wl.name}
                                    </button>
                                </li>)
                            })
                            : null}

                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {this.state.allWorkflowList && this.state.allWorkflowList.length > 0
                            ? this.state.allWorkflowList.map(wf => {
                                return (
                                    <Ax>
                                        <div className={['tab-pane', this.state.selectedTabId == wf.id ? 'show active' : ''].join(' ')} id={`b${wf.id}`} role="tabpanel" aria-labelledby={`${wf.name}-tab`}>
                                            <div className="tab_content_header">
                                                <div className='page_containt'>
                                                    <div>
                                                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Asset Activity</span>
                                                            <div className="text-end "><button className="btn btn-primary" onClick={() => this.updateActivityModalInit(wf)}>Update </button></div>
                                                        </div>
                                                        <table className="table table-hover table-bordered my-3 ">

                                                            <tbody>
                                                                <tr>
                                                                    <th style={{ width: "20%" }}>Activity Name</th>
                                                                    {wf.activities && wf.activities.length > 0
                                                                        ? <td>{wf.activities.map(act => { return (act.name) }).join(', ')}</td>
                                                                        : <td>No Activity Linked</td>}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Workflow Permissions</span>
                                                        </div>
                                                        <table className="table table-hover table-bordered my-3 mb-5">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "15%" }}>Steps</th>
                                                                    <th style={{ width: "20%" }}>Fields</th>
                                                                    <th style={{ width: "15%" }}>Add</th>
                                                                    <th style={{ width: "15%" }}>Edit</th>
                                                                    <th style={{ width: "15%" }}>Delete</th>
                                                                    <th style={{ width: "15%" }}>Cancel</th>
                                                                    <th style={{ width: "5%" }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.workflow_detail_loading ? <tr><td colSpan={7} className="text-center"><Loader /></td></tr>
                                                                    : this.state.workflowDetail && this.state.workflowDetail.id && this.state.workflowDetail.id == this.state.selectedTabId
                                                                   
                                                                        ? this.state.workflowDetail.steps.map((step, index) => {
                                                                            let add_action = step.actions && step.actions.length > 0 ? step.actions.find(act => act.action == 'add') : null;
                                                                            let edit_action = step.actions && step.actions.length > 0 ? step.actions.find(act => act.action == 'edit') : null;
                                                                            let delete_action = step.actions && step.actions.length > 0 ? step.actions.find(act => act.action == 'delete') : null;
                                                                            let cancel_action = step.actions && step.actions.length > 0 ? step.actions.find(act => act.action == 'cancel') : null;
                                                                            return (<tr>
                                                                                <td>{step.name ? step.name : "-"}</td>
                                                                                <td>{step.fields && step.fields.length > 0 ? <ol className='mb-0 pl-1'>{step.fields.sort((a, b) => a.order_no - b.order_no).map(fi => { return (<li className='my-1'>{fi.field_name} {fi.is_additional_attribute && fi.is_additional_attribute == 'Y' ? <sup>#</sup> : null} </li>) })}</ol> : "-"}</td>
                                                                                <td>{add_action && add_action.roles && add_action.roles.length > 0 ? add_action.roles.map(rl => { return (rl.role) }).join(', ') : "-"}</td>
                                                                                <td>{edit_action && edit_action.roles && edit_action.roles.length > 0 ? edit_action.roles.map(rl => { return (rl.role) }).join(', ') : "-"}</td>
                                                                                <td>{delete_action && delete_action.roles && delete_action.roles.length > 0 ? delete_action.roles.map(rl => { return (rl.role) }).join(', ') : "-"}</td>
                                                                                <td>{cancel_action && cancel_action.roles && cancel_action.roles.length > 0 ? cancel_action.roles.map(rl => { return (rl.role) }).join(', ') : "-"}</td>
                                                                                <td className="text-center">
                                                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                                                    </button>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                        <li><a className="dropdown-item" role="button" onClick={() => this.editPermissionModalInit(step, this.state.workflowDetail?.id)}>Edit Permissions</a></li>
                                                                                        <li><a className="dropdown-item" role="button" onClick={() => this.linkAttributeModalInit(step, this.state.workflowDetail?.id)}>Link Additional Attributes</a></li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>)
                                                                        })
                                                                        : <tr><td colSpan={7} className="text-center">No Record</td></tr>}
                                                            </tbody>
                                                        </table>
                                                        <div className='form-text text-sm'> <sup>#</sup>Additional Attributes</div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </Ax>
                                )
                            })
                            : null}

                    </div>

                </Ax>}

            {this.updateActivityModalJsx()}
            {this.editPermissionModalJsx()}
            {this.linkAttributeModalJsx()}
        </section>
        )
    }

    linkAttributeModalJsx          =       ()      =>      {
      
        return (
            <div className="modal fade" id="linkAttributeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Link Additional Attributes</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Worklfow  </td>
                                        <th>{this.state.workflowDetail && this.state.workflowDetail.name ? this.state.workflowDetail.name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Step </td><th>{this.state.selectedWorkflowSteps && this.state.selectedWorkflowSteps.name ? `${this.state.selectedWorkflowSteps.name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form id="updateAttributesForm" onSubmit={this.submitUpdateAttributesForm}>
                            <div className="modal-body">

                                <div className="row mt-3 align-items-center">
                                    <label className="col-sm-2 col-form-label-sm text-capitalize " >Link Attributes</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            changeEvent={(selectedOption, e) => {
                                                this.tapSelectChange(selectedOption, 'updateAttributesForm', 'additional_attribute_keys');
                                            }}
                                            options={this.state.allAdditionalAttributes}
                                            isSearchable={true}
                                            isClearable={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.allAdditionalAttributes.filter(s => this.state.updateAttributesForm.additional_attribute_keys.includes(s.value))}
                                            isMulti={true}
                                            placeholder="Select" />
                                    </div>
                                </div>
                                <div className="row align-items-center">

                                    <div className="offset-2 col-sm-10 form-text">
                                        <sup>#</sup>Additional Attributes can be linked with one step at a time
                                    </div>
                                </div>




                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    editPermissionModalJsx          =       ()      =>      {
      
        return (
            <div className="modal fade" id="editPermissionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Update Permissions</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Worklfow  </td>
                                        <th>{this.state.workflowDetail && this.state.workflowDetail.name ? this.state.workflowDetail.name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Step </td><th>{this.state.selectedWorkflowSteps && this.state.selectedWorkflowSteps.name ? `${this.state.selectedWorkflowSteps.name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form id="updatePermissionForm" onSubmit={this.submitUpdatePermissionForm}>
                           {this.state.activity_form_loading ? <Loader/> :  <div className="modal-body">
                                {this.state.updatePermissionForm && this.state.updatePermissionForm.accessibilities && this.state.updatePermissionForm?.accessibilities?.length > 0
                                    ? this.state.updatePermissionForm?.accessibilities?.map((act,index) => {
                                        return (
                                            <div className="row my-3 align-items-center">
                                                <label className="col-sm-2 col-form-label-sm text-capitalize " >{act.action ? act.action : "-"}</label>
                                                <div className="col-sm-10">
                                                    <TapSelect
                                                        changeEvent={(selectedOption, e) => {
                                                            let roles = selectedOption && selectedOption.length > 0 ? selectedOption.map(st => st.value) : []
                                                            this.dynamicInputHandlerTwoLevelByKeyNameVal(roles,'roles', index, 'accessibilities', 'updatePermissionForm')

                                                        }}
                                                        options={this.state.allRoleList}

                                                        isSearchable={true}
                                                        isClearable={true}
                                                        
                                                        value={this.state.allRoleList.filter(s => this.state.updatePermissionForm?.accessibilities[index]?.roles?.includes(s.value))}
                                                        isMulti={true}
                                                        placeholder="Select" />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null}
                             


                            </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    updateActivityModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="updateActivityModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Update Asset Activity</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="organizationUpdateForm" onSubmit={this.submitUpdateActivityForm}>
                            <div className="modal-body">
                                {this.state.activity_form_loading ? <Loader />
                                    : <div className="row my-3 align-items-center">
                                        <label className="col-sm-3 col-form-label " >Activity Name</label>
                                        <div className="col-sm-9">
                                            <TapSelect
                                                options={this.state.allActivitiesList.filter(act => !this.state.allSelectedActivityList.includes(act.value))}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'updateActivityForm', 'asset_activity_ids')}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.allActivitiesList.filter(s => this.state.updateActivityForm.asset_activity_ids.includes(s.value))}
                                                placeholder="Select Activity" />
                                        </div>

                                    </div>}


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(Workflow);