import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import {number} from "prop-types";
import swal from "sweetalert";
import axios from "axios";
import { ORG_API_BASE_URL_2 } from "../../../../services/TapApiUrls";

class General extends AppBaseComponent {

    constructor(props) {
        super(props);

        
        this.initGeneralConfigFormData      =   {
            use_global_activity                 :    'N',
            runtime_activity_creation           :    'N',
            runtime_productivity_unit_creation  :    'N'
        };

        this.state                              =   {
            generalConfigloading                :      false,
            general_config_list                 :      null,
            generalConfigFormData               :     {...this.initGeneralConfigFormData},
            editFormSubmiting                   :      false,
            currentKey                          :       ''
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';
    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
       
    }

    //functions for general configuration
      getGeneralSettingListing              =    ()           =>     {
        this.setState({generalConfigloading : true})
        let params = {
            keys : [ 'use_global_activity','runtime_activity_creation','runtime_productivity_unit_creation'],
        }
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token , {...params}, {}, (resp) => {
         this.setState({general_config_list  : resp.data.data})
        }).then(() => this.setState({generalConfigloading: false}));
    }

    editGeneralConfigurationInit          =    (key = '')           =>     {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigFormData = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigFormData[name] = val;
                });
              }
              
            this.setState({generalConfigFormData  : generalConfigFormData,currentKey : key})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
       this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , this.state.generalConfigFormData, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
            if(this.props.afterSubmitConfiguration){
                this.props.afterSubmitConfiguration();
            }
            
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    generalConfigurationJsx               =    ()           =>     {
       
        let data = this.state.general_config_list;
          return (<table className="table table-hover table-bordered my-4 ">
              <thead className="table-secondary">
                  <tr className="text-center">
                      <th scope="col" style={{ width: "5%" }}>S.No</th>
                      <th scope="col" className="text-start">Configuration</th>
                      <th scope="col" style={{ width: "25%" }} >Value</th>
                      <th scope="col" style={{ width: "10%" }} >Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr className="text-center">
                      <td>1</td>
                      <td className="text-start"> Show Global Activity Name </td>
                      <td>{data && data.use_global_activity && data.use_global_activity == 'Y' ? "Yes" : "No"}</td>
                      <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('use_global_activity')}>Edit</a></li>
                          </ul>
                      </span></td>
                  </tr>
                  <tr className="text-center">
                      <td>2</td>
                      <td className="text-start"> Allow creation of Activity Name from transaction </td>
                      <td>{data && data.runtime_activity_creation && data.runtime_activity_creation == 'Y' ? "Yes" : "No"}</td>
                      <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('runtime_activity_creation')}>Edit</a></li>
                          </ul>
                      </span></td>
                  </tr>
                  <tr className="text-center">
                      <td>3</td>
                      <td className="text-start"> Allow creation of Production Unit from transaction </td>
                      <td>{data && data.runtime_productivity_unit_creation && data.runtime_productivity_unit_creation == 'Y' ? "Yes" : "No"}</td>
                      <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('runtime_productivity_unit_creation')}>Edit</a></li>
                          </ul>
                      </span></td>
                  </tr>
              </tbody>
          </table>)
      }

    editGeneralConfigurationModalJsx      =    ()           =>     {
          
        return (
              <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                  <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                          </div>
                          <form onSubmit={this.submitGeneralConfigurationForm}>
                              <div className="modal-body p-3">
                                 {this.state.currentKey == 'use_global_activity' &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                      <div className="col-md-9"><label className="form-label">Show Global Activity Name</label></div>
                                      <div className="col-md-1">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="use_global_activity"
                                                  value="Y"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.use_global_activity == "Y"}
                                              /> Yes
                                          </label>
                                      </div>
                                      <div className="col-md-2">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="use_global_activity"
                                                  value="N"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.use_global_activity == "N"} /> No
                                          </label>
                                      </div>
                                  </div>}
                                
                                  {this.state.currentKey == 'runtime_activity_creation' &&     <div className="row align-items-center mb-2 mt-2 px-2">
                                      <div className="col-md-9"><label className="form-label">Allow creation of Activity Name from transaction</label></div>
                                      <div className="col-md-1">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="runtime_activity_creation"
                                                  value="Y"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.runtime_activity_creation == "Y"}
                                              /> Yes
                                          </label>
                                      </div>
                                      <div className="col-md-2">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="runtime_activity_creation"
                                                  value="N"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.runtime_activity_creation == "N"} /> No
                                          </label>
                                      </div>
                                  </div>}
                                  {this.state.currentKey == 'runtime_productivity_unit_creation' &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                      <div className="col-md-9"><label className="form-label">Allow creation of Production Unit from transaction</label></div>
                                      <div className="col-md-1">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="runtime_productivity_unit_creation"
                                                  value="Y"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.runtime_productivity_unit_creation == "Y"}
                                              /> Yes
                                          </label>
                                      </div>
                                      <div className="col-md-2">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="runtime_productivity_unit_creation"
                                                  value="N"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.runtime_productivity_unit_creation == "N"} /> No
                                          </label>
                                      </div>
                                  </div>}
                            
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                  <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          );
    }
   

  
    render                                  =     ()           =>     {
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                {/* <div className="text-end "><button className="btn btn-primary" onClick={this.editGeneralConfigurationInit}>Edit </button></div> */}
            </div>
            {this.state.generalConfigloading ? <Loader />
                : this.generalConfigurationJsx()}

            {this.editGeneralConfigurationModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(General);