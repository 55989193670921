import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon"
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import TapIcon from '../../../services/TapIcon';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import swal from 'sweetalert';
import Status from "../../../components/ui/Status"

class AccountingCode extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.initAddForm         =               {
            name                :               "",
            code                :               "",
            description         :               "",
            status              :               ""
        }   
          this.state                =          {
            list_loading            :         false,
            listingData             :         null,
            saveFormSubmitting      :         false,
            edit                    :          false,
            addCodeForm             :         {...this.initAddForm},
            enable_account_code     :           'N',
            allStatusList           :           [],
            formDataLoaded          :           false
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';
        this.accountCodeFormDataUrl =   ORG_API_BASE_URL_2 + '/account_code/form_data';
        this.accountCodeUrl =   ORG_API_BASE_URL_2 + '/account_code'; 



    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.accountingCodeModal         =    new Modal(document.getElementById('accountingCodeModal'), {keyboard: false, backdrop :false});
    }

    getGeneralSettingListing = () => {
        let params = {
            keys : ['enable_account_code'],
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            let respData = resp.data
            this.setState({ enable_account_code : respData && respData.data && respData.data.enable_account_code ? respData.data.enable_account_code : 'N' },() => {
                if(this.state.enable_account_code == 'Y'){
                    this.getCodeListing();
                }
            })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    activateAccountingInit                      =   ()  =>  {
        swal({
            title: this.state.enable_account_code == "Y" ? "Disable Accounting Code" : "Enable Accounting Code",
            text: "Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {enable_account_code : this.state.enable_account_code == 'N' ? 'Y' : 'N'},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.getGeneralSettingListing();
                    this.getCodeListing();
                }).then(() => { });
            }
        });
    }

    getCodeListing             =         ()    =>  {
        this.setState({list_loading : true})
        HttpAPICall.withAthorization('GET', this.accountCodeUrl , this.props.access_token , {}, {}, (resp) => {
            this.setState({listingData  : resp.data.data})
        }).then(() => this.setState({list_loading: false}));
    }

    addAcountingModalInit         =       (data=null)      =>      {
        this.accountingCodeModal.show();
        if(!this.state.formDataLoaded){
            this.getAccountCodeFormData();
        }

        if(data){
            this.setState({
                addCodeForm     :   {
                   ...data 
                },
                edit        :       true
            })
        }else{
            this.setState({addCodeForm : {...this.initAddForm},edit:false})
        }
    }
    
    getAccountCodeFormData             =         ()    =>  {
        this.setState({form_loading : true})
        HttpAPICall.withAthorization('GET', this.accountCodeFormDataUrl, this.props.access_token , {}, {}, (resp) => {
            let respData = resp.data
            this.setState({
                formDataLoaded  :       true,
                allStatusList    :      respData && respData.status && respData.status.length > 0 ? respData.status.map((st) => {return({value : st.key,label:st.status})}) : [],
            })
        }).then(() => this.setState({form_loading: false}));
    }

    submitAddGroupForm      =   (e)     =>      {
        e.preventDefault()
        if(this.state.edit){
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('PUT', this.accountCodeUrl, this.props.access_token, null, {...this.state.addCodeForm}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.accountingCodeModal.hide();
                this.getCodeListing();
          }).then(() => this.setState({saveFormSubmitting: false}))
        }else{
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('POST', this.accountCodeUrl, this.props.access_token, null, {...this.state.addCodeForm}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.accountingCodeModal.hide();
                this.getCodeListing();
          }).then(() => this.setState({saveFormSubmitting: false}))
        }
    }

    accountingCodeAddModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="accountingCodeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">{this.state.edit ? "Edit" : "Add"} Accounting Code</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="accountingUpdateForm" onSubmit={this.submitAddGroupForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addCodeForm.name}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            required={true}
                                            onChange={(e) => this.formInputHandler(e, "addCodeForm")}
                                            placeholder="Please enter Name"

                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Code</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="code"
                                            type="text"
                                            value={this.state.addCodeForm.code}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            required={true}
                                            disabled={this.state.edit == true ? true : false}
                                            onChange={(e) => this.formInputHandler(e, "addCodeForm")}
                                            placeholder="Please enter Code"

                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require" >Status</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allStatusList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addCodeForm', 'status')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allStatusList.find(s => this.state.addCodeForm.status == s.value)}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Description</label>
                                    <div className="col-sm-9">
                                        <textarea
                                            name="description"
                                            value={this.state.addCodeForm.description}
                                            onChange={(e) => this.formInputHandler(e, 'addCodeForm')}
                                            className="form-control"
                                            placeholder="Please Add a Description"
                                            style={{ height: "100px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        return (<section className="page_containt " style={{ top: "1px" }}>
            <div className="pageTbl bg-white">
                <div className='p-2'>
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">General Setting</span></div>
                    <table className="table table-hover table-bordered table-sm my-4 ">
                        <thead className="table-secondary">
                            <tr className="text-center">
                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                <th scope="col" className="text-start">Configuration</th>
                                <th scope="col" style={{ width: "25%" }} >Value</th>
                                <th scope="col" style={{ width: "10%" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td>1</td>
                                <td className="text-start">Enable Accounting Code</td>
                                <td>{this.state.enable_account_code && this.state.enable_account_code == 'Y' ? "Yes" : "No"}</td>
                                <td> <span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" role="button" onClick={() => this.activateAccountingInit()}>{this.state.enable_account_code == 'Y' ? "Disable" : "Enable"}</a></li>
                                    </ul>
                                </span></td>
                            </tr>
                        </tbody>
                    </table>

                    {this.state.enable_account_code == 'Y' && <Ax>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Accounting Code</span>
                            <div className="text-end "><button className="btn btn-primary" onClick={() => this.addAcountingModalInit()}>Add</button></div>
                        </div>

                        <table className="table table-hover table-bordered my-4 table-sm ">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "10%" }}>S.No</th>
                                    <th scope="col" className="text-start" style={{ width: "20%" }}>Name</th>
                                    <th scope="col" className="text-start" style={{ width: "20%" }}>Code</th>
                                    <th scope="col" className="text-start">Description</th>
                                    <th scope="col" style={{ width: "12%" }}>Status</th>
                                    <th scope="col" style={{ width: "10%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.list_loading ? (<tr><td colSpan="6"><Loader /></td></tr>)
                                    : (this.state.listingData && this.state.listingData.length > 0
                                        ? (this.state.listingData.map((sg, k) => {
                                            return (<tr key={k} className="text-center">
                                                <td>{k + 1}</td>
                                                <td className="text-start">{sg.name ? sg.name : "-"}</td>
                                                <td className="text-start">{sg.code ? sg.code : ""}</td>
                                                <td className="text-start">{sg.description ? sg.description : ""}</td>
                                                <td><Status color={sg.status_color_code}>{sg.status_text ? sg.status_text : ""}</Status></td>

                                                <td> <span className="dropdown">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.addAcountingModalInit(sg)}>Edit</a></li>

                                                    </ul>
                                                </span></td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="6" className="text-center">No Records</td></tr>))}
                            </tbody>
                        </table>

                    </Ax>}

                </div>
            </div>
            {this.accountingCodeAddModalJsx()}
        </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        allStatusList          :        state.app && state.app.user_sites ? state.app.user_sites : []
    };
};


export default connect(mapStateToProps)(AccountingCode);