import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";

class FuelBalance extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.fuelBalanceFormInit    =   {
            fuel_balance                :   '',
            remarks                     :   '',
            transaction_id              :   ''
        }

        this.state                  =   {
            access_token                :   '',
            transaction_id              :   '',
            date                        :   '',
            fuelBalanceLoading          :   false,
            fuelBalanceData             :   null,
            counterlog                  :   null,
            assetData                   :   null,
            shift                       :   null,
            addFuelBalanceForm          :   {...this.fuelBalanceFormInit},
            saveFormSubmitting          :   false,
        }
        this.uploadFuelBalanceModalId   =   uuid();
        this.updateFuelBalanceFormId    =   this.uploadFuelBalanceModalId + 'Form';
    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.uploadFuelBalanceModal         =   new Modal(document.getElementById(this.uploadFuelBalanceModalId), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    fuelOpeningBalanceModalInit         =   (shiftDetail, counterlog, assetData = null, fuelBalanceData = null)  =>  {
        this.uploadFuelBalanceModal.show()
        this.setState({
            assetData               :   assetData,
            fuelBalanceData         :   null,
            assetData               :   assetData,
            shift                   :   shiftDetail,
            counterlog              :   counterlog,
            date                    :   counterlog ? counterlog.feed_date : '',
            transaction_id          :   counterlog ? counterlog.transaction_id : '',
            addFuelBalanceForm      :   {...this.fuelBalanceFormInit, transaction_id : counterlog ? counterlog.transaction_id : ''}
        }, () => {
            if(!fuelBalanceData) {
                this.getFuelBalancefromApi();
            } else {
                this.setState({ addFuelBalanceForm : {
                    ...this.state.addFuelBalanceForm,
                        fuel_balance            :   fuelBalanceData.closing_qty,
                        remarks                 :   fuelBalanceData.remarks,
                        transaction_id          :   this.state.transaction_id
                }});
            }
        });
    }
   
    getFuelBalancefromApi               =   ()  =>  {
        this.setState({fuelBalanceLoading: true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/fuel_balance/' + this.state.transaction_id, this.state.access_token , {}, {}, (response) => {
            let fuelBalanceData             =   response.data;
           
            this.setState({
                addFuelBalanceForm  :   {
                    ...this.state.addFuelBalanceForm, 
                    fuel_balance            :   fuelBalanceData && fuelBalanceData.closing_qty ? fuelBalanceData.closing_qty : '',
                    remarks                 :   fuelBalanceData && fuelBalanceData.remarks ? fuelBalanceData.remarks : '',
                    transaction_id          :   fuelBalanceData && fuelBalanceData.transaction_id ? fuelBalanceData.transaction_id : this.state.transaction_id
                }
            });
        }).then(() => this.setState({fuelBalanceLoading: false}))
    }
      
    submitFuelBalanceForm               =   (e) =>  {
        e.preventDefault()
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/counterlog/fuel_balance', this.props.access_token, null, this.state.addFuelBalanceForm, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.uploadFuelBalanceModal.hide();
            if(this.props.afterSaveFuelBalance) {
                let asset_id            =   this.state.assetData ? this.state.assetData.asset_id : null;
                this.props.afterSaveFuelBalance(this.state.date, asset_id);
            }
        }).then(() => this.setState({saveFormSubmitting: false}))
    }
    
    deleteFuelOpeningBalanceInit        =   (transaction_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', TapApiUrls.IAM + '/counterlog/fuel_balance/' + transaction_id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteFuelBalance) {
                        this.props.afterDeleteFuelBalance()
                    }
                }).then(() => { });
            }
        });
    }
    
    updateFuelBalanceModalJsx           =   ()  =>  {
        return <div className="modal fade" id={this.uploadFuelBalanceModalId} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            <span>Log Fuel Balance for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                            {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                        </h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-borderless text-sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "26%" }}>Asset Name (Code)</td>
                                    <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                </tr>
                                <tr>
                                    <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.state.fuelBalanceLoading 
                     ? <Loader />
                     : <Ax><div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.submitFuelBalanceForm} id={this.updateFuelBalanceFormId}>
                            <div className="row align-items-center">
                                <label className="col-sm-3 col-form-label  require">Fuel Opening Balance </label>
                                <div className="col-sm-9">
                                    <input
                                        name="fuel_balance"
                                        type="number"
                                        value={this.state.addFuelBalanceForm.fuel_balance}
                                        className="form-control"
                                        autoComplete="off"
                                        required={true}
                                        autoFocus={true}
                                        step="0.01"
                                        onChange={(e) => this.formInputHandler(e, "addFuelBalanceForm")}
                                        placeholder="Please enter Opening Fuel Quantity"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <label className="col-sm-3 col-form-label">Remarks </label>
                                <div className="col-sm-9">
                                    <textarea
                                        name="remarks"
                                        value={this.state.addFuelBalanceForm.remarks}
                                        onChange={(e) => this.formInputHandler(e, 'addFuelBalanceForm')}
                                        className="form-control"
                                        style={{ height: "80px" }}

                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={this.updateFuelBalanceFormId}>Update {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                    </Ax>
                }
                </div>
            </div>
        </div>
    }
    
    render                              =   ()  =>  this.updateFuelBalanceModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(FuelBalance);