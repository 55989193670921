import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { Modal } from 'bootstrap';
import ItemViewInList from '../includes/ItemViewInList';
import IndentDetailModal from '../warehouseIndent/IndentDetailModal';
import CurrencyFormatter from '../../../services/CurrencyFormatter';

class RequisitionDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =       {
           req_item_id                  :       null,
           requisition_loading          :       false,
           requisitionDetail            :       null,
           requisitionTransId           :       null,
        };
        this.indentDetailRef        =        React.createRef();
    }   

    componentDidMount               =   ()  =>  {
        this.requisitionDetailModal            =   new Modal(document.getElementById('requisitionDetailModal'), {keyboard: false, backdrop :false});
        this.initScreen(this.props);
    }

    modalReqInit               =   (transaction_id)  =>  {
        this.requisitionDetailModal.show()
        this.getRequisitionDetail(transaction_id)     
    }

    getRequisitionDetail        =       (transaction_id)      =>      {
       
        this.setState({requisition_loading : true})
        HttpAPICall.withAthorization('GET',  TapApiUrls.REQUISITON_URL + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                requisitionDetail    :   respData.data,
                requisitionTransId   :   respData.data.transaction_id,
            });    
        }).then(() => this.setState({requisition_loading : false}));      
    }

    
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                      =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    viewIndentDetailModalInit                    =   (transaction_id)  =>  { 
         this.indentDetailRef.current.modalIndentInit(transaction_id)    
    }
    
    reqDetailJsx        =       ()      =>      {
        let requisition = this.state.requisitionDetail
        return (<div className="modal fade" id="requisitionDetailModal" tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">Purchase Requisition Detail : {this.state.requisitionTransId ?? "-"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    {this.state.requisition_loading ? <Loader />
                        : <div className="modal-body ">
                            {this.state.requisitionDetail
                                ? <div>
                                    <table className="table table-bordered bg-white" >
                                        <thead className="table-secondary">
                                            <tr>
                                                <th style={{ width: "33%" }}>ID</th>
                                                <th style={{ width: "33%" }}>Date</th>
                                                <th style={{ width: "33%" }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="col">{requisition.transaction_id}</td>
                                                <td scope="col">{requisition.transaction_date_display}</td>
                                                <td scope="col" className="align-middle">
                                                    {requisition.status_data ? requisition.status_data.name : ''}
                                                </td>  </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "33%" }} >Warehouse</td>
                                                <td colSpan={2}>{requisition.warehouse ? <div><b>{requisition.warehouse.name} ({requisition.warehouse.code})</b> <br />
                                                    {requisition.warehouse ?
                                                        <div className='pt-1'>Address : {requisition.warehouse.address1 ? requisition.warehouse.address1 + ", " : ""}
                                                            {requisition.warehouse.address2 ? requisition.warehouse.address2 + ", " : ""}
                                                            {requisition.warehouse.landmark ? requisition.warehouse.landmark + ", " : ""}
                                                            {requisition.warehouse.city ? requisition.warehouse.city + ". " : "-"} <br />
                                                            Destination of Supply : <b>{requisition.warehouse.state_name}</b> <br />
                                                            GSTIN  : {requisition?.warehouse?.gstin ?? "-"} <br />
                                                            Site  : {requisition.site ? requisition.site.site_name : "-"}</div> : "-"}</div> : "-"} <br />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="col">Title</td>
                                                <td scope="col">{requisition.title ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <td scope="col">Priority</td>
                                                <td scope="col" className='text-capitalize'>{requisition.priority ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <td scope="col">Due Date</td>
                                                <td scope="col">{requisition.due_date_display ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <td scope="col">Tag Users</td>
                                                <td scope="col">{requisition.usersList.map(u => <div>{u.full_name}</div>)}</td>
                                            </tr>
                                            <tr>
                                                <td scope="col">Tag Asset</td>
                                                <td scope="col">
                                                    {requisition.assetData ? requisition.assetData.name : ''}
                                                    {requisition.assetData ? <small className="form-text"> ({requisition.assetData.asset_code})</small> : ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="col">Indent</td>
                                                <th scope="col" className='link-primary cursor_pointer'>{requisition.warehouse_indent ? <span onClick={() => this.viewIndentDetailModalInit(requisition.warehouse_indent.transaction_id)}>{requisition.warehouse_indent.transaction_id} ({requisition.warehouse_indent.transaction_date_display})</span> : "-"}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-hover table-bordered table-responsive bg-white my-3" id="dataTable">
                                        <thead className="table-secondary">
                                            <tr className="text-end">
                                                <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllList} id="all_check_requisition" /></th>
                                                <th scope="col" style={{ width: "35%" }} className="text-start">Item Details</th>
                                                <th scope="col" style={{ width: "10%" }}>Req. Qty</th>
                                                <th scope="col" style={{ width: "10%" }}>PO Qty</th>
                                                <th scope="col" style={{ width: "10%" }}>Received</th>
                                                <th scope="col" style={{ width: "10%" }}>Cancelled </th>
                                                <th scope="col" style={{ width: "10%" }}>Pending </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                requisition && requisition?.items && requisition?.items?.length > 0 ? requisition?.items?.map((i, k) => {
                                                    return (<tr key={k}>
                                                        <td> <input type='checkbox'
                                                            className="req_item_id_checkbox"
                                                            name="foo"
                                                            value={i.req_item_id}
                                                            data-warehouseId={requisition.warehouse_id}
                                                            data-statusLevel={requisition.status_data && requisition.status_data.level}
                                                            data-pendingQty={i.pending_qty}
                                                            data-itemid={i.item.id}
                                                        /></td>

                                                        <td>
                                                            {i.item ? <ItemViewInList item={i.item} remark={i.remark} /> : null}
                                                            <div className="fs12 pt-1"><b></b>{i.qty} {i.item?.measuring_unit?.name ?? ""} @ {i.currency} {i.rate} ({i.currency}.{i.item_amount}) </div>
                                                        </td>
                                                        <td className="text-end">{i.qty}</td>
                                                        <td className="text-end">{i.current_po_qty}</td>
                                                        <td className="text-end">{i.received_qty}</td>
                                                        <td className="text-end">{i.cancelled_qty}</td>
                                                        <td className="text-end">{i.pending_qty}</td>
                                                    </tr>);
                                                })
                                                    : <tr ><td className='text-center' colSpan={7}>No Record</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="row align-items-center ">
                                        <div className="col-sm-6">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <div>{requisition.notes ?? "-"}</div>

                                        </div>
                                        <div className=" col-sm-6">

                                            <table className="table  table-borderless">
                                                <tbody>
                                                    <tr className="subtotal_bg_color">
                                                        <th>Sub-Total</th>
                                                        <td className='float-end'>
                                                            <CurrencyFormatter currencyCode={this.props.default_currency} amount={requisition.total_amount ? (requisition.total_amount - requisition.total_tax).toFixed(2) : 0.00} />
                                                        </td>
                                                    </tr>
                                                    <tr className="subtotal_bg_color">
                                                        <th>Total Tax</th>
                                                        <td className='float-end'>
                                                            <CurrencyFormatter currencyCode={this.props.default_currency} amount={requisition.total_tax ?? 0} />
                                                        </td>
                                                    </tr>
                                                    <tr className="subtotal_bg_color">
                                                        <th>Final Total</th>
                                                        <td className='float-end'>
                                                            <CurrencyFormatter currencyCode={this.props.default_currency} amount={requisition.total_amount ? requisition.total_amount : 0} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                : null}
                        </div>}

                </div>
            </div>
        </div>)
    }
   

    render() {
        return (<div>
            {this.reqDetailJsx()}
            <IndentDetailModal ref={this.indentDetailRef}/>
        </div>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RequisitionDetail);