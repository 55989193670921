import React from 'react';

function CurrencyFormatter(props) {
    const { currencyCode, amount } = props;
    const final_amount = Number(amount)
    const localeMapping = {
        USD: 'en-US',
        CAD: 'en-CA',
        EUR: 'en-EU',
        INR: 'en-IN',
        AUD: 'en-AU',
        ZAR: 'af-ZA', // South African Rand
        ETB: 'am-ET', // Ethiopian Birr
        AED: 'ar-AE', // United Arab Emirates Dirham
        AZN: 'az-Latn-AZ', // Azerbaijani Manat
        BYN: 'be-BY', // Belarusian Ruble
        BHD: 'ar-BH', //  Bahraini Dinar (BHD) 
        BND: 'ms-BN', //  Brunei Dollar (BND) 
        BSD: 'en-BS', //   Bahamian Dollar (BSD)
        BRL: 'pt-BR', //  Brazilian Real (BRL)  
        BGN: 'bg-BG', // Bulgarian Lev
        BDT: 'bn-BD', // Bangladeshi Taka
        BAM: 'bs-Latn-BA', // Bosnia and Herzegovina Convertible Mark
        EUR_SPAIN: 'ca-ES', // Euro (Spain)
        CZK: 'cs-CZ', // Czech Koruna,
        CHF: 'fr-CH', // Swiss Franc (CHF) 
        CNY: 'zh-CN', //  Chinese Yuan (CNY)
        DKK: 'da-DK', // Danish Krone
        EUR_GERMANY: 'de-DE', // Euro (Germany)
        EUR_GREECE: 'el-GR', // Euro (Greece)
        // Add more currency codes and their corresponding locales as needed
    };

    // Get the locale based on the currency code
    const locale = localeMapping[currencyCode] || 'en-IN'; // Default to 'en-US' if not found

    const formattedAmount = final_amount.toLocaleString(locale, {
        currency: currencyCode,
        // style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits : 2
    });

    return formattedAmount;

}

export default CurrencyFormatter;
