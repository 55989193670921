import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import React from "react";
import TapApiUrls from "../../../services/TapApiUrls";
import * as actions from "../../../store/actions/index"
import moment from "moment";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";
import CurrencyFormatter from "../../../services/CurrencyFormatter";

class StockMovementWarehouseWidget extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.key                        =   'stock_movement_widget';
        this.widgetName                 =   'Inventory Movement Widget';

        this.state                      =   {
            loading                         :   false,
            data                            :   null,
            remark_day                      :   0,
            remark_time                     :   '',
            all_periods                     :   [],
            filterForm                      :   { date_range: 'current_month', action : 'widget'}
        }
        this.stockMovementUrl           =   TapApiUrls.INVENTORY + '/report/stock-movement-warehouse-wise';
        this.updateMyDashboardRef       =   React.createRef()
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props); 
        if (localStorage.getItem('stockMovementWarehouseWidget')) {
            let stockMovement = JSON.parse(localStorage.getItem('stockMovementWarehouseWidget'));
            if (stockMovement) {
                let lastRefreshTime = moment(stockMovement.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('stockMovementWarehouseWidget')
                    setTimeout(() => {
                        this.stockMovementWarehouseList()
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 14));
                    
                } else {
                    this.setState({
                        data            :      stockMovement.data,
                        remark_time     :      stockMovement.time,  
                    })
                }
            }else {
                setTimeout(() => {
                    this.stockMovementWarehouseList()
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 14));
            }  
        } else {
            setTimeout(() => {
                this.stockMovementWarehouseList()
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 14));
        }       
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeFormFilter(nextProps);
        }
    }
    
    initilaizeFormFilter                =   (props)  =>  {
        this.setState({
            all_periods             :   props.all_periods ? props.all_periods.map((s) => { return {value: s.key, label: `${s.display}`}}) : [],
        },() => {
           
            
        });
    }

    stockMovementWarehouseList          =   (reload=false)  =>  {
        this.setState({loading: true});
        let params                      =   {...this.state.filterForm ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET', this.stockMovementUrl, this.props.access_token, params, {} , (response) => {
            this.setState({data : response.data.data,remark_time : response.data.time})
            if (this.state.filterForm.date_range == "current_month") {
                localStorage.setItem('stockMovementWarehouseWidget',JSON.stringify(response.data));
            }
            if (reload !== false) {
                localStorage.setItem('stockMovementWarehouseWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key: this.key, name: this.widgetName})
    }

    render                      =   ()  =>  {
        let wobj = {'date_range' : this.state.filterForm.date_range };
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return(this.key == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == this.key)})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5"><h6 className="fw-bold primary_color">{this.widgetName}</h6></div>
                    <div className="col-3">
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.all_periods}
                            value={this.state.all_periods.find(r => r.value === this.state.filterForm.date_range)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'date_range' , () => this.stockMovementWarehouseList())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.stockMovementWarehouseList('reload')}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">

                    <div className="col-12">
                        <table className="table table-hover table-bordered table-borderless bg-white my-2">
                            <thead className="table-secondary">
                                <tr className="text-end">
                                    <th style={{ width: "25%" }} className="text-start" >Warehouse</th>
                                    <th style={{ width: "12%" }} className="text-center">Warehouse Code</th>
                                    <th style={{ width: "11%" }} className="text-center">Opening</th>
                                    <th style={{ width: "10%" }}>Received</th>
                                    <th style={{ width: "10%" }}>Issued</th>
                                    <th style={{ width: "10%" }}>Balance</th>
                                    <th style={{ width: "10%" }}>Total Amount</th>
                                    <th style={{ width: "12%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.loading
                                    ? 
                                    (<tr><td colSpan="8"><Loader /></td></tr>)
                                    :
                                    (
                                        this.state.data && this.state.data.length > 0 
                                        ?
                                        this.state.data.map((w, key) => {
                                            let obj =   {warehouse_id: w.warehouse_id, date_range: this.state.filterForm.date_range};
                                            return (
                                              <Ax>
                                                <tr key={key}>
                                                  <td>{w.name}</td>
                                                  <td>{w.code}</td>
                                                  <td className="text-end">
                                                    {w.opening_qty}
                                                  </td>
                                                  <td className="text-end">
                                                    {w.receive_qty}
                                                  </td>
                                                  <td className="text-end">
                                                    {w.issue_qty}
                                                  </td>
                                                  <td className="text-end">
                                                    {w.balance_qty}
                                                  </td>
                                                  <td className="text-end">
                                                  <CurrencyFormatter currencyCode={this.props.default_currency} amount={w.balance_amount}/>
                                                    
                                                  </td>
                                                  <td className="text-center">
                                                    <Link
                                                      to={{
                                                        pathname: `/report/stock_movement_item_wise`,
                                                        search: "?" + new URLSearchParams(obj).toString(),
                                                      }}
                                                      target="_blank" 
                                                    >
                                                      View Detail
                                                    </Link>
                                                  </td>
                                                </tr>
                                              </Ax>
                                            );
                                        })
                                        :
                                        <tr><td className="text-center" colSpan={8}>No Record Found</td></tr>
                                    )
                                }   
                            </tbody>
                        </table>
                    </div>
 
                    <div className="col-sm-12 text-muted fs11">
                    Report as of {this.state.remark_time}
                    <div className="float-end link-primary">
                         <Link target="_blank" to={{ pathname: "/report/stock_movement_warehouse_wise", search: "?" + new URLSearchParams(wobj).toString() }}>View Detailed Report</Link>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(StockMovementWarehouseWidget);