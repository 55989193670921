import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import swal from "sweetalert";
import {DisplayListPagination} from '../../../components/TapUi';
import CompositeExpenseDetail from './CompositeExpenseDetail';
import { Helmet } from 'react-helmet';

class CompositeExpenseList extends React.Component {

    constructor(props) {
        super(props);

        this.state                         =     {
            minimizeTable                 :       false,
            expense_listing               :       [],
            expense_view_data             :       [],
            listingMeta                   :        null,
            listing_tbl_page              :        1,
            totalListingCount             :        0,
            listing_loading               :        false,
            detail_loading                :        false,
            compositeExpenseDetail        :        []
        };
    }

    componentDidMount() {
        let ParamObject                 =       new URLSearchParams(this.props.location.search);
       if(ParamObject.get('search_compoiste_id')){
           this.viewCompositeExpenseDetail(ParamObject.get('search_compoiste_id'))
       }
       this.loadListingTblData(1)
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:25};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_API_SERVER + '/common_expense', this.props.access_token, params, {}, (resp) => {
           this.setState({
            expense_listing: resp.data.data,
            listingMeta: resp.data.meta,
            totalListingCount: resp.data.meta.total,
           })
        }).then(() => this.setState({listing_loading: false}));
    }

    viewCompositeExpenseDetail          =      (id)        =>     {
        this.setState({minimizeTable  :   true})
        this.setState({detail_loading: true});
          HttpAPICall.withAthorization('GET',  process.env.REACT_APP_API_SERVER + '/common_expense/' + id, this.props.access_token, {}, {}, (resp) => {
           this.setState({compositeExpenseDetail : resp.data.data})
        }).then(() => this.setState({detail_loading: false}));
    }

    deleteExpense                     =        (id)         =>    {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_API_SERVER + '/common_expense/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                       this.loadListingTblData(1)
                       this.setState({minimizeTable:false})
                    }).then(() => {

                        });
                }
            });
    }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
        let ParamObject                 =       new URLSearchParams(this.props.location.search);
        if(ParamObject.get('search_compoiste_id')){
            this.props.history.push('/composite_expense')
        }
    }

    compositeExpenseListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Composite Expense</h3>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Composite Expense : {this.state?.compositeExpenseDetail?.common_expense_id} </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary" onClick={() => this.deleteExpense(this.state.compositeExpenseDetail?.common_expense_id)}>
                                    Delete
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.compositeExpenseListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{height:"100%"}}>{this.compositeExpenseViewDetails()}</div></div> : null }
                </div>
            </div>
        </Ax>);
    }

    compositeExpenseListingTableJsx            =   ()   =>   {
        return(<Ax>
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                { this.state.minimizeTable
                    ? (<tr>
                        <th scope="col" style={{width:"15%"}}>Composite Expense</th>
                    </tr> )
                    :   ( <tr>
                        <th scope="col" style={{width:"5%"}} className="text-center">S.No</th>
                        <th scope="col" style={{width:"12%"}} className="text-center">Date of Transaction</th>
                        <th scope="col" style={{width:"13%"}}>Transaction ID</th>
                        <th scope="col" style={{width:"20%"}}>Expense Header </th>
                        <th scope="col" style={{width:"20%"}}>Detail</th>
                        <th scope="col" style={{width:"15%"}}>Wallet</th>
                        <th scope="col" style={{width:"10%"}} className="text-end">Expense Amount</th>
                        <th scope="col" style={{width:"5%"}}>Action</th>
                    </tr> )
                }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.expense_listing.length > 0
                            ? (this.state.expense_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewCompositeExpenseDetail(item?.common_expense_id)}>
                                                        <div className="text-capitalize link-primary">{item?.expense_head}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{item?.common_expense_id} </small>
                                                            <small className="float-end">{item?.amount ? item?.amount : "-"}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className="text-center">{item?.activity_date_display}</td>
                                                <td><a role="button" className="link-primary text-capitalize" onClick={() => this.viewCompositeExpenseDetail(item?.common_expense_id)}>{item?.common_expense_id}</a></td>
                                                <td>{item?.expense_head ? item?.expense_head : "-"}</td>
                                                <td>{item?.expense_details ? item?.expense_details : "-"}</td>
                                                <td className="text-capitalize">{item?.wallet.id !== null ? item?.wallet?.wallet_name : "-"}</td>
                                                <td className="text-end">{item?.amount ? item?.amount : "-"}</td>
                                                 <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewCompositeExpenseDetail(item?.common_expense_id)}>View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
        </Ax>)
    }

    compositeExpenseViewDetails = () => {

        return (<div>
            {this.state.detail_loading === false ? (
                <CompositeExpenseDetail compositeExpenseData={this.state.compositeExpenseDetail}/>)
                : <Loader />}

        </div>)
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
              <Helmet><title>Composite Expense List</title></Helmet>
           {this.compositeExpenseListjsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(CompositeExpenseList);