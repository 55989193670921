import React from 'react';

import {connect} from "react-redux";
import HttpAPICall from "../../../services/HttpAPICall";
import Ax from "../../../components/hoc/Ax";

import Loader from "../../../components/ui/Loader/Loader";
import Status from "../../../components/ui/Status"

import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import { Link } from 'react-router-dom';

class ItemDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state                  =        {
            itemData              :         [],
        }
    }

    id              =   this.props.itemId;

    componentDidMount() {
       if(this.props && this.props.itemId){
            this.getItemDetails(this.props.itemId)
       }
       if(this.props && this.props.itemData){
           this.setState({itemData : this.props.itemData})
       }
    }

    getItemDetails              =       (id)          =>      {
        this.setState({details_loading: true});
        HttpAPICall.withAthorization('GET',  INVENTORY_API_BASE_URL_2 + '/item/' + id, this.props.access_token, {opening_stock : "Y"}, {}, (resp) => {
          this.setState({ itemData         :    resp.data.data})
        }).then(() => {this.setState({details_loading: false})}) 
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.itemId !== this.props.itemId){
            this.getItemDetails(nextProps.itemId)
        }
        if(nextProps.itemData !== this.props.itemData){
            this.setState({itemData : nextProps.itemData})
        }
        if(nextProps.refreshDetail == true){
            this.getItemDetails(nextProps.itemId)
        }
        // if(nextProps.changeStatus == true){
        //     this.changeItemStatusHandle();
        // }
        
    }

    // changeItemStatusHandle     =       ()      =>      {
     
    //     let frmData = {
    //         status  :   this.state.itemData && this.state.itemData.status == 1 ? 2 : 1,
    //         id      :   this.state.itemId
    //     }
    //     //  this.setState({refreshDetail : false},() => {
    //         swal({
    //             title: "Change Status",
    //             text: "This action cannot be reversed, Are you sure you want to proceed?",
    //             icon: "warning",
    //             // dangerMode: true,
    //             buttons: ["No", "Yes"],
    //         })
    //             .then(willChangeStatus => {
    //                 if (willChangeStatus) {
    //                     HttpAPICall.withAthorization('PUT', INVENTORY_API_BASE_URL_2 + '/item/change_status', this.props.access_token, {},{...frmData} ,(response) => {
    //                          toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
    //                         this.getItemDetails(this.state.itemId)
    //                         if(this.props.afterUpdateItemSubmit){
    //                             this.props.afterUpdateItemSubmit(null,this.state.itemId)
    //                         }
    //                     }).then(() => {
    
    //                     });
    //                 }
    //             });
    //    // })
        
    // }

    render() {

        return (<div className="tab-content" id="myTabContent">

            <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                {this.state.details_loading ? <Loader />
                    : <div className="tab_content_header">
                        {this.state.itemData ? <div>
                            <div className="tab_content_wrapper"><span className="content_heading">Basic Details</span></div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td className="details-label" style={{ "width": "34%" }}>Item Category </td>
                                        <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData?.category_display}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Name</td>
                                        <td className="details-name">{this.state.itemData?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Code</td>
                                        <td className="details-name">{this.state.itemData?.item_code ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Status</td>
                                        <td className="details-name">{this.state.itemData && this.state.itemData.status ? <Status color={this.state.itemData.status_color}>{this.state.itemData.status_text}</Status> : ""}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="details-label">Item Description</td>
                                        <td className="details-name">{this.state.itemData?.description ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Unit of Measurement</td>
                                        <td className="details-name">{this.state.itemData?.measuring_unit?.name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Manufacturer</td>
                                        <td className="details-name">{this.state.itemData?.manufacturer?.name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Manufacturer Part Number (MPN)</td>
                                        <td className="details-name">{this.state.itemData?.manufacturer_part_no ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Item Tags</td>
                                        <td className="details-name" >
                                            {
                                                this.state.itemData?.tags?.length > 0
                                                    ? (this.state.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                    : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >HSN / SAC Code</td>
                                        <td className="details-name" >{this.state.itemData?.hsn_code ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Generate Scrap on Replacement</td>
                                        <td className="details-name" >{this.state.itemData?.is_scrapble == 'N' ? 'NO' : 'Yes' ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Created On</td>
                                        <td className="details-name" >{this.state.itemData?.created_at_display ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Created By</td>
                                        <td className="details-name" >{this.state.itemData && this.state.itemData.created_user ? this.state.itemData.created_user.full_name  : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Updated On</td>
                                        <td className="details-name" >{this.state.itemData?.updated_at_display ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Updated By</td>
                                        <td className="details-name" >{this.state.itemData && this.state.itemData.updated_user ? this.state.itemData.updated_user.full_name  : '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper"><span className="content_heading">Pricing</span></div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                
                                    <tr>
                                        <td className="details-label" style={{ "width": "34%" }}>Purchase Price</td>
                                        <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData.purchase_currency && this.state.itemData?.purchase_currency} {this.state.itemData?.purchase_price ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Sale / Consumption Price</td>
                                        <td className="details-name">{this.state.itemData.consumption_currency && this.state.itemData?.consumption_currency} {this.state.itemData?.consumption_price ?? ''}</td>
                                    </tr>
                                    {this.state.itemData?.is_scrapble === "Y" ? <tr>
                                        <td className="details-label">Scrap Price</td>
                                        <td className="details-name">{this.state.itemData?.scrap_price ?? ''}</td>
                                    </tr> : null}
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper"><span className="content_heading">Taxation</span></div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td className="details-label" style={{ "width": "34%" }}>Tax Status </td>
                                        <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData?.is_taxable == 'Y' ? 'Taxable' : 'Non - Taxable'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">IGST Percentage</td>
                                        <td className="details-name">{this.state.itemData?.igst ?? 0}%</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">CGST Percentage</td>
                                        <td className="details-name">{this.state.itemData?.cgst ?? 0}%</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">SGST Percentage</td>
                                        <td className="details-name">{this.state.itemData?.sgst ?? 0}%</td>
                                    </tr>
                                </tbody>
                            </table>

                            {
                                this.state.itemData.additional_attribute_data && this.state.itemData.additional_attribute_data.length > 0
                                    ? (
                                        <Ax>
                                            <div className="tab_content_wrapper">
                                                <div>
                                                    <span className="content_heading">Additional Attribute</span>
                                                </div>
                                            </div>
                                            <table className="table table-hover table-borderless">
                                                <tbody>
                                                    {this.state.itemData.additional_attribute_data.map((a, k) => (<tr key={k}>
                                                        <td className="details-label text-capitalize" style={{ "width": "34%" }}>{a.name}</td>
                                                        <td className="details-name" style={{ "width": "66%" }}>
                                                            {a.value ? a.value : "-"}
                                                        </td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                        </Ax>
                                    )
                                    : null
                            }
                            {this.state.itemData && this.state.itemData.category === "services" ? null
                                : (<Ax>
                                    <div className="tab_content_wrapper"><span className="content_heading">Opening Balance</span></div>
                                    <table className="table table-hover table-bordered my-2">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                                <th scope="col" className="text-start" style={{ width: "25%" }}>Warehouse</th>
                                                <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                                                <th scope="col" className="text-center" style={{ width: "15%" }}>Unit Rate</th>
                                                <th scope="col" className="text-end" style={{ width: "15%" }}>Amount</th>
                                                {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                    <Ax>
                                                        <th scope="col" className="text-start" style={{ width: "15%" }}>Batch Number</th>
                                                        <th scope="col" className="text-center" style={{ width: "10%" }}>Expiry Date</th>
                                                    </Ax>)
                                                    : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.itemData && this.state.itemData.opening_stock && this.state.itemData.opening_stock.length > 0 ?
                                                this.state.itemData.opening_stock.map((a, k) => (<tr key={k} className='text-center'>
                                                    <td>{k + 1}</td>
                                                    <td className="text-start">{a.warehouse.name}</td>
                                                    <td>{a.qty}</td>
                                                    <td >{a.rate}</td>
                                                    <td className="text-end">{a.qty * a.rate}</td>
                                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                        <Ax>
                                                            <td className='text-start'>{a.batch_number ?? "-"}</td>
                                                            <td>{a.expiry_date ? moment(a.expiry_date).format("DD-MMM-YYYY") : "-"}</td>
                                                        </Ax>)
                                                        : null}
                                                </tr>))
                                                : <tr><td colSpan={7} className='text-center'>No Record</td> </tr>}
                                        </tbody>
                                    </table>
                                </Ax>)}
                        </div> : null}

                    </div>}
            </div>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        isc_configuration       :        state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    }
}

export default connect(mapStateToProps)(ItemDetails);
