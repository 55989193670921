import React from "react";
import AppBaseComponent from "../../../../components/AppBaseComponent";
import { connect } from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import EmpType from "./EmpType";
import EmpDesignation from "./EmpDesignation";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import TagComponent from "../../TagComponent";
import EmployeeDivision from "./EmployeeDivision";

class EmployeeTypeDesignation extends AppBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      divisionShow: false,
    };
  }

  render() {
    return (
      <section className="item_view_container bg-white">
        <Helmet>
          <title>HRM Configuration : Employee</title>
        </Helmet>
        <ToastContainer />
        <ul className="nav nav-tabs" id="myTab">
          <li className="nav-item" role="downtimeTab">
            <button
              className="nav-link active"
              id="type-desig"
              type="button"
              data-bs-toggle="tab"
              data-bs-target="#typeDesi"
              aria-controls="typeDesi"
              role="tab"
              aria-selected="false"
            >
              Type & Designation
            </button>
          </li>
          <li className="nav-item" role="downtimeTab">
            <button
              className="nav-link"
              id="division-tab"
              type="button"
              data-bs-toggle="tab"
              data-bs-target="#division"
              aria-controls="division"
              role="tab"
              aria-selected="true"
              onClick={() => this.setState({ divisionShow: true })}
            >
              Department
            </button>
          </li>
          <li className="nav-item" role="downtimeTab">
            <button
              className="nav-link"
              id="tags-tab"
              type="button"
              data-bs-toggle="tab"
              data-bs-target="#tags"
              aria-controls="tags"
              role="tab"
              aria-selected="false"
              onClick={() => this.setState({ tagShow: true })}
            >
              Tags
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane show active"
            id="typeDesi"
            aria-labelledby="type-desig"
          >
            <div className="tab_content_header">
              <EmpType />
              <EmpDesignation />
            </div>
          </div>

          <div
            className="tab-pane"
            id="division"
            aria-labelledby="division-tab"
          >
            <div className="tab_content_header">
              {this.state.divisionShow && <EmployeeDivision />}
            </div>
          </div>
          <div className="tab-pane" id="tags" aria-labelledby="tags-tab">
            <div className="tab_content_header">
              {this.state.tagShow && <TagComponent type="employee" />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
  };
};

export default connect(mapStateToProps)(EmployeeTypeDesignation);
