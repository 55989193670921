import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';

class WarehouseAddress extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initAddressForm               =        {
            label                          :       "Ship To Address",
            gstin                          :       '',
            address1                       :       '',
            address2                       :       '',
            city                           :       '',
            landmark                       :       '',
            district_id                    :       null,
            pincode                        :       null,
            country_id                     :       99,
        }

        this.state                  =   {
            access_token                :   '',
            allCountryList                  :    [{value: 99, label: "India"}],
            allStateList                    :       [],
            allDistrictList                 :       [],
            allPincodeList                  :       [],
            editAddressDetails              :       false,
            addAddressForm                  :       {...this.initAddressForm},
            warehouseAddressFormDataLoaded  :       false,
            addAddressFormSubmitting        :       false
        };

        this.stateListUrl   =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl   =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl   =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.pincodeListUrl   =   ORG_API_BASE_URL_2  +   '/pincode_list';
        
    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.addAddressModal                  =   new Modal(document.getElementById('addWarehouseAddressModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    addAddressModalInit         =   (warehouse_id,data = null)  =>  {
        this.addAddressModal.show()
   
        this.setState({
            warehouse_id        :       warehouse_id,
            addAddressForm      :      data ? {...data} : {...this.initAddressForm},
            editAddressDetails      :     data ? true : false
        },() => {
            if(data){
                this.stateChangeHandler(data.state_id)
                if(data.district_id){
                    this.districtChangeHandler(data.district_id)
                }
            }
        })

        if(!this.state.warehouseAddressFormDataLoaded){
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, null, null, (response) => {
                this.setState({
                warehouseAddressFormDataLoaded   :    true,
                allStateList                     :    response.data.map((s) => { return {value: s.id, label: s.name}; }),
               });
           });
       }
    }
   
      //********************FUNCTION TO GET DISTRICT LIST ON SELECTING STATE************
      stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList  :  response.data.map((s) => { return {value: s.id, label: s.name}; })
                });
            });
        }
    }

    //*******************FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT*********************
    districtChangeHandler              =        (selected_district)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            HttpAPICall.withAthorization('GET', this.pincodeListUrl + '/' + selectedDistrict, this.props.access_token, null, null, (response) => {
                this.setState({
                    allPincodeList  :  response.data.map((s) => { return {value: s.pincode, label: s.pincode}; })
                });
            });
        }
    }

    addAddressFormSubmit            =       (e)      =>      {   
        e.preventDefault();
         let frmData = { ...this.state.addAddressForm, warehouse_id:this.state.warehouse_id }
         
         this.setState({ addAddressFormSubmitting: true })
         if (this.state.editAddressDetails) {
             HttpAPICall.withAthorization('PUT', INVENTORY_API_BASE_URL_2 + '/warehouse/shipping_address', this.props.access_token, {}, { ...frmData }, (response) => {
                 toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                 this.addAddressModal.hide();
                 if(this.props.afterAddAddressSubmit){
                    this.props.afterAddAddressSubmit()
                 }
                
                 this.setState({ addAddressForm: { ...this.initAddressForm } })
             }).then(() => this.setState({ addAddressFormSubmitting: false }));
         } else {
             HttpAPICall.withAthorization('POST', INVENTORY_API_BASE_URL_2 + '/warehouse/shipping_address', this.props.access_token, {}, { ...frmData }, (response) => {
                 toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                 this.addAddressModal.hide();
                 if(this.props.afterAddAddressSubmit){
                    this.props.afterAddAddressSubmit()
                 }
                 this.setState({ addAddressForm: { ...this.initAddressForm } })
             }).then(() => this.setState({ addAddressFormSubmitting: false }));
         }
     }

     addAddressModalJsx              =   ()  =>  {
         return (
             <div className="modal fade" id="addWarehouseAddressModal" tabIndex="-1">
                 <div className="modal-dialog modal-lg modal-dialog-scrollable">
                     <div className="modal-content  ">
                         <div className="modal-header">
                             <h5 className="modal-title" id="addContactModalLabel">{this.state.editAddressDetails ? " Edit" : "Add"} Address</h5>
                         </div>
                         {this.state.warehouseAddressFormDataLoaded ?
                             <div className="modal-body ">
                                <form onSubmit={this.addAddressFormSubmit} id="addWarehouseAddressForm">

                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label require">Label</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <input
                                             name="label"
                                             type="text"
                                             className="form-control"
                                             value={this.state.addAddressForm.label}
                                             onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                             autoComplete="off"
                                             maxLength="250"
                                             required={true}
                                             placeholder="Please Enter Address Label"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">Address Line 1</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <input
                                             name="address1"
                                             type="text"
                                             className="form-control"
                                             value={this.state.addAddressForm.address1}
                                             onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                             autoComplete="off"
                                             maxLength="250"
                                             placeholder="Please Enter Address Line 1"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">Address Line 2</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <input
                                             name="address2"
                                             type="text"
                                             className="form-control"
                                             value={this.state.addAddressForm.address2}
                                             onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                             autoComplete="off"
                                             maxLength="250"
                                             placeholder="Please Enter Address Line 2"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">Landmark</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <input
                                             name="landmark"
                                             type="text"
                                             className="form-control"
                                             value={this.state.addAddressForm.landmark}
                                             onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                             autoComplete="off"
                                             maxLength="250"
                                             placeholder="Please Enter Landmark"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">City</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <input
                                             name="city"
                                             type="text"
                                             className="form-control"
                                             value={this.state.addAddressForm.city}
                                             onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                             autoComplete="off"
                                             maxLength="250"
                                             placeholder="Please Enter City"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">Country</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <TapSelect
                                             changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addAddressForm', 'country_id')}
                                             options={this.state.allCountryList}
                                             isSearchable={false}
                                             isClearable={false}
                                             value={this.state.allCountryList.find(m => m.value == this.state.addAddressForm.country_id)}
                                             placeholder="Please Select Country"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label require">State</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <TapSelect
                                             changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                             options={this.state.allStateList}
                                             isSearchable={true}
                                             isClearable={true}
                                             required={true}
                                             value={this.state.allStateList.find(m => m.value == this.state.addAddressForm.state_id)}
                                             placeholder="Please Select State"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">District</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <TapSelect
                                             changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'district_id'); this.districtChangeHandler(selectedOption) }}
                                             options={this.state.allDistrictList}
                                             isSearchable={true}
                                             isClearable={true}
                                             value={this.state.allDistrictList.find(m => m.value == this.state.addAddressForm.district_id)}
                                             placeholder="Please Select District"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">PIN Code</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <TapSelect
                                             changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'pincode') }}
                                             options={this.state.allPincodeList}
                                             isSearchable={true}
                                             isClearable={true}
                                             value={this.state.allPincodeList.find(m => m.value == this.state.addAddressForm.pincode)}
                                             placeholder="Please Select PIN Code"
                                         />
                                     </div>
                                 </div>
                                 <div className="row align-items-center my-3">
                                     <div className="col-sm-3">
                                         <label htmlFor="site_id" className="form-label">GSTIN</label>
                                     </div>
                                     <div className="col-sm-9">
                                         <input
                                             name="gstin"
                                             type="text"
                                             className="form-control"
                                             autoComplete="off"
                                             value={this.state.addAddressForm.gstin}
                                             onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                             maxLength="20"
                                             placeholder="Please Enter GSTIN Number"
                                         />
                                     </div>
                                 </div>

                             </form>
                             </div>

                             : <Loader />}
                         <div className="modal-footer">
                             <button type="button" disabled={this.state.addAddressFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addAddressForm: { ...this.initAddressForm } }) }}>Close</button>
                             <button type="submit" disabled={this.state.addAddressFormSubmitting} className="btn btn-primary" form="addWarehouseAddressForm">
                                 Submit {this.state.addAddressFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                             </button>
                         </div>
                     </div>
                 </div>
             </div>
         );
     }
    
    render                              =   ()  =>  this.addAddressModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(WarehouseAddress);