import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import React from 'react';
import swal from "sweetalert";
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import DateService from '../../../services/DateService';
import QuotationDetailModal from '../../salesCrm/quotation/QuotationDetailModal';
import { Collapse, Modal } from 'bootstrap';

class ItemWiseQuotationReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
             search_transaction_id               :   '',
           search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_status_ids                   :   [],
            search_priority                     :   '',
            search_manufacturer_ids             :   [],
            search_customer_ids                   :   [],
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
            tagged_user_ids                     :   [],
        };
        
        this.reportFilterFormLabel          =   {
             search_transaction_id               :   'Quotation ID : ',
           search_warehouse_ids                :   'Warehouse : ',
            search_item_ids                     :   'Items : ',
            search_item_tags                    :   'Item Tags : ',
            search_status_ids                   :   'Status : ',
            search_customer_ids                   :   'Customer : ',
            search_priority                     :   'Search Priority : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            date_range                          :   'Date Range : ',
            tagged_user_ids                   :     'Tagged Users : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            allItemTags                         :   [],
            all_periods                         :   [],
            searchedCustomer                      :   [],
            allStatusOptions                    :   [],
            allPriorityOptions                  :   [],
            manufactures                        :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null, 
            searchedAssets                      :   [],
            searchedEmployees                   :   [], 
            workstations                        :   [],
            allUsers                            :   [],  
            download_access                     :   false,   
            reportData                          :   null,  
            transactionId                        :   null                
        };
         this.formDataUrl    =   INVENTORY_API_BASE_URL_2 + '/report/item_wise_quotation/form_data';
        this.reportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item_wise_quotation';

    }

    componentDidMount(){
        setTimeout(() => {
            this.initializeData(this.props)
        },1500)
        this.viewTransactionModal              =     new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
    }

    initializeData     =      (pr)      =>      {
        const report_name   =   pr?.location?.pathname ? pr.location.pathname.split('/')[2] : '';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];

        if(pr){
            let all_periods         =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            HttpAPICall.withAthorization('GET', this.formDataUrl, pr.access_token, null, null, (response) => {
                this.setState({
                    reportData                  :   reportData.length > 0 ? reportData[0] : null,
                    download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                    reportFilterFormData        :   {...response.data},
                    all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    formDataLoaded              :   true,
                    all_periods                 :   all_periods ,
                    manufactures                :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                    allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name,level : s.level}; }) : [],
                    allPriorityOptions          :   response.data && response.data.priorities && response.data.priorities.length > 0 ? response.data.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                    allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allUsers                    :   response.data && response.data.users ? response.data.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                }, () => {

                   
                    this.reportFilterhandler();  
                   
                });
            }, (err) => {
                
            });
            
    
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'tagged_user_filter') {
                        show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_customer_ids') {
                        show_val            =   this.state.searchedCustomer ? this.state.searchedCustomer.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_transaction_id : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_priority') {
                        show_val            =   this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'tagged_user_ids') {
                        show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                            let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                            show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   ()  =>  {
        let formData                            =   {...this.state.submittedReportFilterForm};
        if(formData.search_transaction_id != '' || formData.date_range != '') {
            this.reportDownloadApiCall(formData);
        } else if((JSON.stringify(formData) === JSON.stringify(this.reportFilterFormInitialState)) || formData.date_range == '') {
            swal({ 
                title: "Download", icon: "warning", buttons: ["Cancel", "Okay"],
                text: "The download will be for Current Quarter, in case you want a different Period then apply the criteria before downloading.",
            }).then(willDownload => {
                if (willDownload) { this.reportDownloadApiCall({...formData, date_range: "current_quarter"}); }
            });
        }
    }
    
    reportDownloadApiCall                   =   (formData)  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...formData, download : 'download'} , {} , (response) => DownloadFile.file(null,response.data.navgation,response.data.msg,this.props)).then(() => this.setState({reportDownloading: false}));
    }

        
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    getQuotationDetailInit          =       (transaction_id)        =>      {
        this.setState({transactionId : transaction_id},
            () => this.viewTransactionModal.show()) 
    }

    
    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Item Wise Quotation Report</h3>
                <div className="text-end mt15">
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.props.permissions.includes('isc-po-view') &&   <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                    </button>}
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={this.reportDownloadHandler}>Download</Link></li>
                    </ul>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
            
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                    <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Tagged User</label>
                    <div className="col-sm-6">
                        <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Tagged User"
                            options={this.state.allUsers}
                            value={this.state.allUsers.filter(s => this.state.reportFilterForm.tagged_user_ids.includes(s.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_ids')}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.all_warehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Customer</label>
                    <div className="col-sm-6">
                        <InputCustomerSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_customer_ids');
                                this.setState({ searchedCustomer: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedCustomer && this.state.searchedCustomer?.length > 0 ? this.state.searchedCustomer.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Quotation Transaction ID</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Please enter Transaction ID"
                        />
                    </div>
                </div>
            
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Priority</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allPriorityOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_priority')}
                            value={this.state.allPriorityOptions.find(u => u.value == this.state.reportFilterForm.search_priority)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Priority"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range: period,
                                        date_range_start: startDate ? startDate : null,
                                        date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className="text-center" style={{ width: "5%" }}>S.No</th>
                        <th className='text-center' style={{ width: "9%" }}>Transaction ID</th>
                        <th className='text-center' style={{ width:"7%" }}>Transaction Date</th>
                        <th className="text-center" style={{ width: "15%" }}>Warehouse</th>
                        <th className="text-center" style={{ width: "15%" }}>Customer</th>
                        <th className="text-center" style={{ width: "17%" }}>Item Name</th>
                        <th className='text-center' scope="col" style={{ width: "8%" }}>Status</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Currency</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }}  >Qty</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Unit Rate</th>
                        <th className='text-end' scope="col" style={{ width: "8%" }} >Amount</th>
                       
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((rowData, k) => {
                          
                                return (<tr key={k}>
                                    <td className='text-center'>
                                       {this.state.listingMeta ? this.state.listingMeta.from + k : k}
                                    </td>
                                    <td><a hred="#" onClick={() => this.getQuotationDetailInit(rowData.transaction_id)} role="button" className='link-primary cursor_pointer'>{rowData.transaction_id}</a></td>
                                    <td>{rowData.transaction_date ? DateService.dateTimeFormat(rowData.transaction_date,'DD-MMM-YYYY') : ""}</td>
                                    <td>{rowData.warehouse_name ?  rowData.warehouse_name : ''}</td>
                                    <td className='text-capitalize'>{rowData.customer_name ? rowData.customer_name : ""}</td>
                                    <td>{rowData.item_name} <br />
                                        <small className='form-text'><b>Code : </b> {rowData.item_code}</small></td>
                                    <td className='text-center'>{rowData.status ? rowData.status : ''}</td>
                                    <td className='text-end'>{rowData.currency}</td>
                                   <td className='text-end'>{rowData.qty}</td>
                                    <td className='text-end'>{rowData.rate_vc}</td>
                                    <td className='text-end'>{rowData.total_amount_vc}</td>
                                </tr>);
                           
                        }))
                        : (<tr><td className="text-center" colSpan={10}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }

    transactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {this.state.transactionId ? this.state.transactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                          <QuotationDetailModal quotationID={this.state.transactionId}/>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>ItemWise Quotation - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
            {this.transactionDetailsModalJsx()}
            
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []        
    };
};

export default connect(mapStateToProps)(ItemWiseQuotationReport);