import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from "../../../components/TapUi";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import HttpAPICall from "../../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import TapIcon from "../../../services/TapIcon";
import DownloadFile from "../../../services/DownloadFile";
import { Collapse } from "bootstrap";
import moment from "moment";
import TapSelect from "../../../components/ui/TapSelect";

class AssetWiseFuelProductionReport extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
            site_group_ids                      :   [],
            search_site_ids                     :   [],
            asset_group_ids                     :   [],
            search_asset_type_ids               :   [],
            search_asset_matrix_ids             :   [],
            search_activities                   :   [],
            search_units                        :   [],
            search_material_ids                 :   [],
            search_attachment_ids               :   [],
            date_range                          :   'current_month',
            date_range_start                    :   '',
            date_range_end                      :   '',
        }

        this.reportFilterFormLabel          =   {
            site_group_ids                      :   'Site Groups : ',
            search_site_ids                     :   'Sites : ',
            asset_group_ids                     :   'Asset Type Groups : ',
            search_asset_type_ids               :   'Asset Type : ',
            search_asset_matrix_ids             :   'Asset Matrix : ',
            search_activities                   :   'Activities : ',
            search_units                        :   'Units : ',
            search_material_ids                 :   'Materials : ',
            search_attachment_ids               :   'Attachment : ',
            date_range                          :   'Date Range : ',
            
        };

        this.state                          =   {
            downloadReportBtn                   :   false,
            reportDownloading                   :   false,
            listing_loading                     :   false,
            show_element                        :   false,
            listingData                         :   [],
            filterFormData                      :   null,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listingMeta                         :   null,
            manufacturers                       :   [],
            iam_user_sites                      :   [],
            searchedAssets                      :   [],
            allSiteGroupsList                   :   [],
            productivityKeysList                :   [],
            allAssetTypesGroupsList             :   [],
            activities                          :   [],
            units                               :   [],
            materials                           :   [],
            attachments                         :   [],
            iam_asset_matrix                    :   [],
            iam_asset_types                     :   [],
            currentPage                         :    1,
            keysPerPage                         :   10,
            maxFuelProductivity                 :   null,
            reportData                          :   null,                  
        };
        this.productivityUrl                  =   IAM_API_BASE_URL_2 + `/report/productivity-report`;
    }

    componentDidMount   ()  {
        this.initializeData(this.props);  
    }

    initializeData  =   (pr)  =>  {

        const report_name   =   'asset_wise_fuel_productivity_report';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })

       
        HttpAPICall.withAthorization('GET', this.productivityUrl, this.props.access_token, {action:"formData"}, {}, (response) => {
            let respData = response.data;
            this.setState({
                iam_user_sites      :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                iam_asset_types         :   pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                iam_asset_matrix        :   pr.iam_asset_matrix ? pr.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
                allAssetTypesGroupsList :   pr.asset_groups && pr.asset_groups.length > 0 ? pr.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                allPeriods              :   pr.all_periods,
                formDataLoaded          : true,
                activities              : respData.activity_name ? respData.activity_name.map((s) => { return {value: s.id, label: s.activity_name}}) : [],
                units                   : respData.uom ? respData.uom.map((s) => { return {value: s.id, label: s.measuring_unit}}) : [],
                materials               : respData.materials ? respData.materials.map((s) => { return {value: s.id, label: s.material_name}}) : [],
                attachments             : respData.attachments ? respData.attachments.map((s) => { return {value: s.id, label: s.name}}) : [],
                allSiteGroupsList       :   pr.site_groups && pr.site_groups.length > 0 ? pr.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            }, ()=>{
                this.setState({
                    reportFilterForm        :   {...this.state.reportFilterForm, date_range: 'current_month'},
                }, () => {
                    this.reportFilterFormSubmit();
                })
            });
         }, (err) => {});
        
    }

    loadListData    =   (page = 1)  =>  {
        this.setState({
            listing_loading             :   true,
            reportDownloading           :   false
        });
        let params                      =   {page:page, ...this.state.submittedReportFilterForm, per_page : 25, action:"asset_wise_fuel_production_data"};
        HttpAPICall.withAthorization('GET', this.productivityUrl, this.props.access_token, params, {}, (res) => {
            let respData                =   res.data;
            let maxProd                 =   (Math.max(...respData.data.map(d => d.fuel_per_unit_productivity))) * 1.1;
            maxProd                     =   maxProd.toFixed(2);       
            this.setState({
                listingData                 :   respData.data,
                maxFuelProductivity         :   maxProd,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownload  =   (row='')  =>  {
        this.setState({
            reportDownloading   :   true,
        });
        const params    =   {action : 'asset_wise_fuel_production_download', ...this.state.reportFilterForm}
        HttpAPICall.withAthorization('GET', this.productivityUrl, this.props.access_token, params , {} , (res) => 
        DownloadFile.file(res.data.file_path))
        .then(() => this.setState({reportDownloading: false}));
    }

    

    pageTitleBar            =   ()  =>  {
        return (
            <div className="page_title row m0">
                <div className="col-sm-12">
                    <h3>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faCubes} /> Asset Wise Fuel Productivity Report 
                    </h3>
                    <div className="text-end mt15">
                        <button type="button"  className="btn btn-secondary" onClick={this.reportFilterhandler}>Change Filter</button>
                        <button disabled={this.state.listing_loading || this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownload} className="btn btn-secondary">
                            <TapIcon.FontAwesomeIcon icon=   {TapIcon.faDownload} />{this.state.reportDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin"/>) : ''}
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    reportFilterFormSubmit  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            =   this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activities') {
                        show_val            =   this.state.activities.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_units') {
                        show_val            =   this.state.units.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_material_ids') {
                        show_val            =   this.state.materials.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_attachment_ids') {
                        show_val            =   this.state.attachments.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'date_range') {
                        let  start_range        =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range           =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY');
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`;
                        show_val                =     this.state.allPeriods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems,
            show_element                        :   true,
        }, () => {
            this.loadListData(1);
        });
    }

    reportFilterhandler     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    handleClickNext = (page = 1) => {
          let {currentPage} = this.state;
          this.setState({currentPage : page})
      };
    
      

    reportFilterFormJsx     =   ()  =>  {
        return (
                <div id="reportFilterForm" className="bg-white collapse" >
                    <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end">Site Group</label>
                        <div className="col-sm-6 align-items-center">
                            <TapSelect
                                options={this.state.allSiteGroupsList}
                                changeEvent={(selectedOption) => {

                                    this.setState({
                                        reportFilterForm: {
                                            ...this.state.reportFilterForm,
                                            site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                            search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                        }
                                    })
                                }}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                                placeholder="Select Site Group"
                            />
                        </div>
                    </div>
                        <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end">Site Name</label>
                            <div className="col-sm-6">
                                <TapSelect
                                    options={this.state.iam_user_sites}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm?.search_site_ids?.includes(s.value))}
                                    placeholder="Select Sites"
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_matrix_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.reportFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Activities</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.activities}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_activities')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.activities.filter(s => this.state.reportFilterForm.search_activities.includes(s.value))}
                            placeholder="Select Activities"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Units</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.units}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_units')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.units.filter(s => this.state.reportFilterForm.search_units.includes(s.value))}
                            placeholder="Select Units"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Materials</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.materials}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_material_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.materials.filter(s => this.state.reportFilterForm.search_material_ids.includes(s.value))}
                            placeholder="Select Materials"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Attachments</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.attachments}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_attachment_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.attachments.filter(s => this.state.reportFilterForm.search_attachment_ids.includes(s.value))}
                            placeholder="Select Attachments"
                        />
                    </div>
                </div>

                        <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end">Select Period <span className="text-danger">*</span></label>
                            <div className="col-sm-6">
                                <PeriodSelectionWithCustom
                                    periods={this.state.allPeriods ? this.state.allPeriods : []}
                                    value={this.state.reportFilterForm.date_range}
                                    startDate={this.state.reportFilterForm.date_range_start}
                                    endDate={this.state.reportFilterForm.date_range_end}
                                    onSelectPeriod={(period, startDate, endDate) => {
                                        this.setState({
                                            reportFilterForm
                                                : {
                                                ...this.state.reportFilterForm,
                                                date_range: period,
                                                date_range_start: startDate ? startDate : null,
                                                date_range_end: endDate ? endDate : null
                                            }
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                        </div>  
                        <div className="row">
                            <div className="pb-3 col-9 text-end">
                                <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                            </div>
                            <hr />
                        </div>
                    </form>
                </div>
        );
    }

    productivityReportJsx  =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-sm align-middle bg-white">
                <thead>
                    <tr>
                        <th style={{ width: "5%" }}>S No.</th>
                        <th style={{ width: "30%" }}>Asset</th>
                        <th style={{ width: "15%" }}>Site</th>
                        <th style={{ width: "10%" }}>Productivity</th>
                        <th style={{ width: "10%" }}>Fuel</th>
                        <th style={{ width: "10%" }}>Fuel per Unit Productivity</th>
                        <th style={{ width: "20%" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0 ? this.state.listingData.map((data, k) => {
                        return (
                            <tr>
                                <td>{ k + 1}</td>
                                <td>{ data.name} {data.asset_code ? `(${data.asset_code})`: ''}</td>
                                <td>{ data.site}</td>
                                <td className="text-end">{ data.productivity}</td>
                                <td className="text-end">{ data.fuel_feedin}</td>
                                <td className="text-end">{ data.fuel_per_unit_productivity === 0 ? '' : data.fuel_per_unit_productivity}</td>
                                <td className='text-center'>
                                    {
                                        data.fuel_per_unit_productivity === 0 
                                        ?
                                        ''
                                        :
                                        <div>
                                            <div className="progress">
                                                <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${this.progressBarHandle(data.fuel_per_unit_productivity)}%`, backgroundColor: "#228B22" }}
                                                    aria-valuemin="0" aria-valuemax="100">
                                                </div>
                                            </div>
                                            {
                                            // <small className='form-text'>{this.progressBarHandle(data.fuel_per_unit_productivity)}%</small>
                                            }
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    }) : <tr className="text-center">
                        <td colSpan={6}>No Record</td>
                    </tr>}
                </tbody>
            </table>
        </Ax>
        )
    }

    progressBarHandle   =   (fuel_per_unit_productivity)  =>  {
        if(fuel_per_unit_productivity == 0) {
            return 0;
        }
        return (fuel_per_unit_productivity*100)/this.state.maxFuelProductivity;
    }

    closeReport         =   ()  =>  {
        this.props.history.push('/report')
    }

    render  =   ()  =>  {
        return (
            <ApplicationLayout> 
                <Helmet><title>Asset wise Fuel Productivity - Report</title></Helmet>
                {this.pageTitleBar()}
                <div className="container-fluid pl5">
                    <div className="page_containt row">
                        <div id="pageTbl" className="pageTbl col-12">
                            {this.reportFilterFormJsx()}
                            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} /> 
                            {this.state.listing_loading ? <Loader/> : this.state.listingData.length > 0 && this.productivityReportJsx()}
                            { this.state.listingMeta
                                ?
                                <DisplayListPagination  meta={this.state.listingMeta} onPageChange={(e) => this.loadListData(e.selected + 1)}/>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </ApplicationLayout>
        );
    };

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetWiseFuelProductionReport);