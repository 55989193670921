import React from 'react';
import AdditionalAttribute from '../../AdditionalAttribute';
import GeneralSetting from './GeneralSetting';

export default class FuelFeedInSetting extends React.Component {

 
    render() {
        return (
            <section className="item_view_container bg-white">
           
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active " id="additional-tab" data-bs-toggle="tab" data-bs-target="#additional"
                        type="button" role="tab" aria-controls="additional" aria-selected="true">Additional Attribute
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link  " id="setting-tab" data-bs-toggle="tab" data-bs-target="#setting"
                        type="button" role="tab" aria-controls="setting" aria-selected="true">General Setting
                    </button>
                </li>
                
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane show active " id="additional" role="tabpanel" aria-labelledby="additional-tab">
                    <div className="tab_content_header">
                    <AdditionalAttribute category="fuel_feedin"/>
                       
                    </div>
                </div>
                <div className="tab-pane " id="setting" role="tabpanel" aria-labelledby="setting-tab">
                    <div className="tab_content_header">
                    <GeneralSetting/>
                    </div>
                </div>
               
            </div>
           
        </section>
        );
    }
}