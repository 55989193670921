import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from '@createnl/grouped-checkboxes';

class NotificationList extends AppBaseComponent {

    constructor(props) {
        super(props);
        

        this.state                      =     {
            minimizeTable                :     false,
            listing_tbl_page             :     1,
            listing_loading              :     false,
            totalListingCount            :     0,
            listingMeta                  :     null,
           notification_listing          :     [],
           selectedNotificationData      :     null,
           saveFormSubmitting           :       false
        }
         
    }
    
    
    componentDidMount() {
        this.loadListingTblData(1);
        this.updateNotificationModal            =        new Modal(document.getElementById('updateNotificationModal'), {keyboard: false, backdrop :false});
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.APP_URL + '/group/notifications', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                notification_listing          :       respData.data,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

   
    updateNotificationModalInit         =       (data = null)    =>      {
        this.updateNotificationModal.show()
        if(document.getElementById("updateNotificationForm")) {
            let form = document.getElementById("updateNotificationForm")
            form.reset();
        }
        
        this.setState({
            selectedNotificationData  :  data,
        })
    }

    checkAllDaysList        =       (id)        =>  {
        let roleDaysCheckboxCollections      =   document.getElementsByClassName(id)
        if(roleDaysCheckboxCollections && roleDaysCheckboxCollections.length > 0) {
            for (let i = 0; i < roleDaysCheckboxCollections.length; i++) {
                roleDaysCheckboxCollections[i].checked = document.getElementById(id).checked;
            }
        }
    }

    submitNotificationForm      =       (e)      =>      {
        e.preventDefault();
       
        let roleData = [];
        if(this.state.selectedNotificationData && this.state.selectedNotificationData.roles && this.state.selectedNotificationData.roles.length > 0){
            this.state.selectedNotificationData.roles.map(element => {
                let days = []
                let roleDaysCheckboxCollections = document.getElementsByClassName(element.id);
                if (roleDaysCheckboxCollections && roleDaysCheckboxCollections.length > 0) {
                    for (let i = 0; i < roleDaysCheckboxCollections.length; i++) {
                        if (roleDaysCheckboxCollections[i].checked) {
                           days.push(roleDaysCheckboxCollections[i].value);  
                        }
                    }
                }
                roleData.push({role_id : element.id,days : days})
            });
        }else{
            roleData = []
        }
       

         let frmData = {id : this.state.selectedNotificationData?.id ,roles : roleData}

        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('POST', TapApiUrls.APP_URL + '/group/notifications', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.updateNotificationModal.hide();
           this.loadListingTblData(1)
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    notificationListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Group Notification Setting </h3>
                <div className="text-end mt15">
                    {/* <button type="button" disabled={this.state.listing_loading} onClick={this.documentSearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button> */}

                </div>
            </div>
            
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.notificationListingTableJsx()}</div>
                 
                </div>
            </div>
        </Ax>);
    }

    notificationListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" style={{ height: "100%" }}>
        
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr>
                        <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                        <th scope="col" style={{ width: "25%" }}>Notification</th>
                        <th scope="col" style={{ width: "15%" }} className="text-center">Notification Type</th>
                        <th scope="col" style={{ width: "32%" }}>Role</th>
                        <th scope="col" style={{ width: "15%" }} className="text-center">Status</th>
                        <th scope="col" style={{ width: "8%" }} className='text-center'>Action</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.listing_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.notification_listing.length > 0
                            ? (this.state.notification_listing.map((notification, index) => {
                               
                                return (<tr key={index} >

                                    <td className="text-center">{index + 1}</td>
                                     <td>{notification.key} <br/> {notification.name}</td>
                                     <td className="text-center text-capitalize">{notification.type}</td>
                                     <td>{notification.roles && notification.roles.length > 0 ? notification.roles.map(nt => {return(nt.role)}).join(', ') : "-"}</td>
                                    <td className="text-center"><Status color={notification.status_color_code}>{notification.status_text}</Status></td>

                                    <td className="text-center">

                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu cursor_pointer">
                                            <li> <a className='dropdown-item' onClick={() => this.updateNotificationModalInit(notification)}>Update</a></li>
                                        </ul>
                                    </td>

                                </tr>)
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
           
        </div>)
    }


    updateNotificationModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="updateNotificationModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateNotificationModalLabel">Update Notification For : </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitNotificationForm} id="updateNotificationForm">
                            <div className="modal-body">
                                <table className="table table-bordered table-fixed  bg-white table-sm">
                                    <thead className="table-secondary" >
                                        <tr>
                                            <th style={{ "width": "5%" }}>#</th>
                                            <th style={{ "width": "25%" }}>Role</th>
                                            <th className="text-center">ALL</th>
                                            <td className="text-center">Mon</td>
                                            <td className="text-center">Tues</td>
                                            <td className="text-center">Wed</td>
                                            <td className="text-center">Thur</td>
                                            <td className="text-center">Fri</td>
                                            <td className="text-center">Sat</td>
                                            <td className="text-center">Sun</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.selectedNotificationData && this.state.selectedNotificationData.roles && this.state.selectedNotificationData.roles.length > 0
                                            ? (this.state.selectedNotificationData.roles.map((rol, index) => {

                                                return (<tr key={index} >

                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{rol.role ? rol.role : "-"}</td>
                                                    <td className='text-center'><input type='checkbox' onChange={() => this.checkAllDaysList(rol.id)} id={rol.id} /></td>
                                                    <td className='text-center'> <input type='checkbox' className={`${rol.id}`} name="foo" value={"1"} checked={rol.notification_frequency && rol.notification_frequency.length > 0 && rol.notification_frequency.filter(freq => freq.isoday == "1") ? true : false} /></td>
                                                    <td className='text-center'> <input type='checkbox' className={`${rol.id}`} name="foo" value={"2"} checked={rol.notification_frequency && rol.notification_frequency.length > 0 && rol.notification_frequency.filter(freq => freq.isoday == "2") ? true : false} /></td>
                                                    <td className='text-center'> <input type='checkbox' className={`${rol.id}`} name="foo" value={"3"} checked={rol.notification_frequency && rol.notification_frequency.length > 0 && rol.notification_frequency.filter(freq => freq.isoday == "3") ? true : false} /></td>
                                                    <td className='text-center'> <input type='checkbox' className={`${rol.id}`} name="foo" value={"4"} checked={rol.notification_frequency && rol.notification_frequency.length > 0 && rol.notification_frequency.filter(freq => freq.isoday == "4") ? true : false} /></td>
                                                    <td className='text-center'> <input type='checkbox' className={`${rol.id}`} name="foo" value={"5"} checked={rol.notification_frequency && rol.notification_frequency.length > 0 && rol.notification_frequency.filter(freq => freq.isoday == "5") ? true : false} /></td>
                                                    <td className='text-center'> <input type='checkbox' className={`${rol.id}`} name="foo" value={"6"} checked={rol.notification_frequency && rol.notification_frequency.length > 0 && rol.notification_frequency.filter(freq => freq.isoday == "6") ? true : false} /></td>
                                                    <td className='text-center'> <input type='checkbox' className={`${rol.id}`} name="foo" value={"7"} checked={rol.notification_frequency && rol.notification_frequency.length > 0 && rol.notification_frequency.filter(freq => freq.isoday == "7") ? true : false} /></td>
                                                </tr>)
                                            }))
                                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                                        }


                                    </tbody>
                                </table>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"  disabled={this.state.saveFormSubmitting ? true : false} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary"  disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

     
    render                              =   ()  =>  {
      
        return (<ApplicationLayout>
        <Helmet><title>Group Notification Listing</title></Helmet>
             {this.notificationListjsx()} 
             {this.updateNotificationModalJsx()}
            
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(NotificationList);