import React from 'react';
import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Tabs from "../../../layouts/applicationTabLayout/ApplicationTabLayout";
import TabPane from "../../includes/applicationTabPane/ApplicationTabPane";
import Loader from "../../../components/ui/Loader/Loader";
import { Helmet } from 'react-helmet';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import TapSelect from '../../../components/ui/TapSelect';
import SiteAdd from './SiteAdd';
import Status from '../../../components/ui/Status';
import InputUserSearch from '../../includes/ui/InputUserSearch';
import swal from 'sweetalert';


class SiteList extends AppBaseComponent {
    constructor() {
        super();

    
        this.siteSearchInitState         =   {
            'search_name'                :   '',
            'search_code'                :   '',
        };

        this.siteSearchFormLabel         =   {
            'search_name'               :   'Site Name : ',
            'search_code'               :   'Site Code : ',
        };

        this.initChangeStatusForm       =          {
            id                          :          '',
            status                      :          '',
            reason                      :          ''
        }

        this.state = {
            siteAddScreenView           :         false,
            minimizeTable               :         false,
            siteUpdateScreenView        :         null,
            siteList                    :         [],
            listingMeta                 :         null,
            page                        :          1,
            listing_loading             :         false,
            totalListingCount           :          0,
            changeStatusForm            :         {...this.initChangeStatusForm},
            siteDetail                  :         null,
            siteFilterForm              :       {...this.siteSearchInitState},
            submitSiteFilterForm        :       {...this.siteSearchInitState},
            formSearchedElems           :       [],
            selectedSite                :       '',
            AllStatusList               :       [{value:1,label:"Active"},{value:2,label:"Inactive"},{value:3,label:"Dormant"}],
            saveFormSubmitting          :       false,
            linkUserForm                :       {user_ids   :   []},
            linkedUsersListLoaded       :       false,
            linkedUsersList             :       [],
            linkedUserIds               :       [],
            linked_users_loading        :       false
        }
        this.addSiteRef                 =   React.createRef();

        this.usersUrl =   ORG_API_BASE_URL_2  + '/users';
        this.siteUsersLinkUrl   =   ORG_API_BASE_URL_2 + '/user/site-users-link'
        this.siteUsersDeLinkUrl =   ORG_API_BASE_URL_2 + '/user/site-users-delink'

    }

    //function to close
    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    //function to close screen
    addNewSiteScreenClose               =   ()  =>  {
        this.setState({siteAddScreenView : false, siteUpdateScreenView : false});
    }


    //function to open
    viewSiteDetail                      =      ()  =>    {
//        this.setState({minimizeTable : true});
    }

    addNewSiteScreenOpen               =   ()  =>  {
        this.setState({siteAddScreenView : true});
    }

    //function to get List
    getSiteListing      =       (page = 1)      =>  {
        this.setState({listing_loading: true, listing_tbl_page: page});
         let params                      =   {page:page,...this.state.submitSiteFilterForm};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/site/list', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;

            this.setState({
                siteList                       :       resp.data.data,
                listingMeta                    :       resp.data.meta,
                totalListingCount              :       resp.data.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
      
    }

    componentDidMount() {
      this.getSiteListing(this.state.page)
      this.siteSearchModal           =       new Modal(document.getElementById('siteSearchModal'), {keyboard: false, backdrop :false});
      this.changeStatusModal         =       new Modal(document.getElementById('changeStatusModal'), {keyboard: false, backdrop :false});
      this.linkUserModal            =       new Modal(document.getElementById('linkUserModal'), {keyboard: false, backdrop :false});
    }

    addSiteModalInit       =       (data = null)      =>  {
        this.addSiteRef.current.addSiteInit(data);
    }

    siteSearchModalInit     =       ()      =>      {
        this.siteSearchModal.show()
    }

    changeStatusModalInit     =       (id)      =>      {
        this.changeStatusModal.show()
        this.setState({ changeStatusForm : { ...this.initChangeStatusForm,id : id } })
    }

    siteSearchFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        
        let serachFormKeys                  =   Object.keys(this.state.siteFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};

        serachFormKeys.map((key)        =>  {
            let label                       =   this.siteSearchFormLabel[key];
            let value                       =   this.state.siteFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                  
                    if(key == 'search_name') {
                        show_val            =  this.state.siteFilterForm.search_name;
                    }

                    if(key == 'search_code') {
                        show_val            =  this.state.siteFilterForm.search_code;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submitSiteFilterForm           :   {...this.state.siteFilterForm},
            formSearchedElems              :   searchedElems
        }, () => {
            this.siteSearchModal.hide()
           this.getSiteListing(1);
        });
    }

    siteSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems           :       [],
            siteFilterForm              :       {...this.siteSearchInitState},
            submitSiteFilterForm        :       {...this.siteSearchInitState},
        }, () => {
            this.siteSearchModal.hide()
            this.getSiteListing(1);
        });
    }

    viewSiteDetails             =       (data)      =>      {
        
        if(data){
            this.setState({
                minimizeTable          :    true,
                siteDetail             :    data,
                linkedUsersListLoaded  :  false
            },() => this.getLinkedUsersList(data.id) );
            
        }
        
       
    }

    
    submitChangeStatusForm        =       (e)      =>  {
        e.preventDefault();

        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/site/status', this.props.access_token, {}, { ...this.state.changeStatusForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.changeStatusModal.hide()
            this.getSiteListing(1);
            this.setState({ changeStatusForm            :         {...this.initChangeStatusForm}})
        }).then(() => this.setState({ saveFormSubmitting: false }));
        
    }

    getLinkedUsersList      =       (id)        =>      {
       
        if (!this.state.linkedUsersListLoaded) {
            this.setState({ linked_users_loading: true })
            HttpAPICall.withAthorization('GET', this.usersUrl, this.props.access_token, {search_site_ids : [id] }, {}, (resp) => {
                let respData = resp.data;

                this.setState({
                    linkedUsersList        :       respData.data,
                    linkedUserIds          :       respData.data.map(s => s.id),
                    linkedUsersListLoaded  :       true,
                });

            }).then(() => this.setState({ linked_users_loading: false }));
        }
        
    }

    checkAllList() {
        let siteCollections      =   document.getElementsByClassName('user_id_checkbox')
        if(siteCollections && siteCollections.length > 0) {
            for (let i = 0; i < siteCollections.length; i++) {
                siteCollections[i].checked = document.getElementById('all_check_user').checked;
            }
        }
    }

    linkUserSiteHandler     =   ()        =>      {
        this.linkUserModal.show()
        
    }

    submitLinkUserForm      =       (e)     =>      {
        e.preventDefault();
        let frmData = {
            site_id         :       this.state.siteDetail && this.state.siteDetail.id? this.state.siteDetail.id : '',
            user_ids        :       this.state.linkUserForm && this.state.linkUserForm.user_ids && this.state.linkUserForm.user_ids.length > 0 ? this.state.linkUserForm.user_ids.map(op => {return(op.value)}) : [],
       }
        this.setState({saveFormSubmitting: true,linkedUsersListLoaded : false})
        HttpAPICall.withAthorization('PUT', this.siteUsersLinkUrl, this.props.access_token, {}, {...frmData}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getSiteListing(1)
            this.getLinkedUsersList(this.state.siteDetail?.id)
            this.linkUserModal.hide()
            this.setState({selectedSite : '',linkUserForm : {user_ids : []},})
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    delinkUserSiteHandler     =       ()      =>  {
        
        let usercollections     =   document.getElementsByClassName('user_id_checkbox');
        let selectedUsers                  =   [];
       
        if(usercollections && usercollections.length > 0) {
            for (let i = 0; i < usercollections.length; i++) {
                if(usercollections[i].checked) {
                   let site_id         =       usercollections[i].value;
                    selectedUsers.push(site_id)    
                }
            }
        }


        if(selectedUsers.length == 0){
            toast.error('Please select atleast one Site', { position: toast.POSITION.TOP_RIGHT });
        }else{
            this.setState({linkedUsersListLoaded : false})
            swal({
                title: "Delink",
                text: "This action cannot be reversed, Are you sure you want to proceed?",
                icon: "warning",
                dangerMode: true,
                buttons: ["No", "Yes"],
            }).then(willDelete => {
                if (willDelete) {
                    let frmData = {
                        user_ids        :       selectedUsers,
                        site_id         :       this.state.siteDetail && this.state.siteDetail.id? this.state.siteDetail.id : '',
                    }
                    HttpAPICall.withAthorization('PUT', this.siteUsersDeLinkUrl, this.props.access_token, {},{...frmData} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getSiteListing(1)
                        this.getLinkedUsersList(this.state.siteDetail?.id)
                        this.setState({linkedUsersListLoaded : false})
                    }).then(() => { });
                }
            });
        }
    }


    //function for role header
    siteListjsx                         =   ()  =>  {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Site List</h3>
                <div className="text-end mt15">
                 {!this.state.minimizeTable
                 ? <Ax>
                        <Button className={'btn btn-primary'} onClick={() => this.addSiteModalInit()} disabled={this.props.permissions && this.props.permissions.includes('iam-site-add') ? false : true} >
                        New Site
                    </Button>
                   
                    <Button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={this.siteSearchModalInit}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action"/>
                    </Button>
                 </Ax> : null}
                  
                </div>
               
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3> Site : {this.state.siteDetail ? this.state.siteDetail.site_name : "-"}</h3>
                            <div className="text-end mt15">
                                <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" /></button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.siteListingTable()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.siteViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }


    //handle pagination
    handlePageClick = (e) => {
        this.setState({page: e.selected + 1})
        this.state.page = e.selected + 1;
        this.getSiteListing(this.state.page);
    }
    //function for role listing
    siteListingTable                   =   ()  =>  {
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.siteSearchClear} />
                <table className="table table-bordered table-fixed  bg-white table-sm">
                    <thead className="table-secondary">
                        {this.state.minimizeTable
                            ? (<tr>
                                <th scope="col" style={{ width: "20%" }} className="text-center">Name</th>
                            </tr>)
                            : (<tr>
                                <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                <th scope="col" style={{ width: "15%" }} className="text-center">Name</th>
                                <th scope="col" style={{ width: "15%" }} className="text-center">Code</th>
                                <th scope="col" style={{ width: "11%" }} className="text-center">Warehouse</th>
                                <th scope="col" style={{ width: "17%" }} className="text-center">Users</th>
                                <th scope="col" style={{ width: "11%" }} className="text-center">Measurable Asset</th>
                                <th scope="col" style={{ width: "15%" }} className="text-center">Non Measurable Asset</th>
                                <th scope="col" style={{ width: "11%" }} className="text-center">Status</th>
                                <th scope="col" style={{ width: "5%", zIndex: 9999 }} className="text-center">Action</th>
                            </tr>)
                        }
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                            : this.state.siteList.map((item, index) => {
                                return <tr>
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>

                                                    <td className='link-primary cursor_pointer'>
                                                        <a role="button" onClick={() => this.viewSiteDetails(item)}>{item.site_name}</a>
                                                    </td>
                                                </Ax>
                                            )
                                            : (
                                                <Ax>
                                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                    <td className='link-primary cursor_pointer'><a role="button" onClick={() => this.viewSiteDetails(item)}>{item.site_name}</a></td>
                                                    <td className="text-center text-capitalize">{item.site_code}</td>
                                                    <td className="text-center "><Link to={{ pathname: "/warehouse", search: "?" + new URLSearchParams({ search_site_ids: item.id }).toString() }}>{item.warehouse_count}</Link></td>
                                                    <td className="text-center">
                                                        <Link to={{ pathname: "/user_list", search: "?" + new URLSearchParams({ search_site_ids: item.id }).toString() }}>{item.user_count}</Link>
                                                    </td>
                                                    <td className="text-center"><Link to={{ pathname: "/measurable_asset", search: "?" + new URLSearchParams({ 'site[]': item.id }).toString() }}>{item?.measurable_count}</Link></td>
                                                    <td className="text-center"><Link to={{ pathname: "/non_measurable_asset", search: "?" + new URLSearchParams({ 'site[]': item.id }).toString() }}>{item.non_measurable_count}</Link></td>
                                                    <td className="text-center"><tapIcon.FontAwesomeIcon icon={tapIcon.faCircle} color={item.status_color_code} />
                                                        <span className="ml5">{item.status_text}</span></td>
                                                    <td>
                                                        <span className="dropdown">
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                  
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li>
                                                                    <a role="button"
                                                                        onClick={() => {
                                                                            if (this.props.permissions.includes('iam-site-edit')) {
                                                                                return this.addSiteModalInit(item)
                                                                            }
                                                                        }}
                                                                        className={['dropdown-item', this.props.permissions.includes('iam-site-edit') ? '' : 'disabled'].join(' ')}
                                                                        title={!this.props.permissions.includes('iam-site-edit') ? 'You do not have permission to perform this action' : ''}
                                                                        style={{ "pointerEvents": "all" }}
                                                                    >Edit</a>
                                                                </li>
                                                                <li>
                                                                    <a role="button"
                                                                        onClick={() => {
                                                                            if (this.props.permissions.includes('iam-site-status-change')) {
                                                                                return this.changeStatusModalInit(item.id)
                                                                            }
                                                                        }}
                                                                        className={['dropdown-item', this.props.permissions.includes('iam-site-status-change') ? '' : 'disabled'].join(' ')}
                                                                        title={!this.props.permissions.includes('iam-site-status-change') ? 'You do not have permission to perform this action' : ''}
                                                                        style={{ "pointerEvents": "all" }}
                                                                    >Change Status</a>
                                                                </li>

                                                            </ul>
                                                        </span>
                                                    </td>
                                                </Ax>
                                            )
                                    }

                                </tr>
                            })}
                    </tbody>
                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.getSiteListing(e.selected + 1)}
                />
            </Ax>
        );
    }

    //function for role view
    roleDetailJsx                       =   ()  =>  {
        return (<section className="item_view_container">
            <Tabs>
                <TabPane name="Details" key="1">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Basic Details</span></div>
                        <table className="table table-hover table-borderless mt-2">
                            <tbody>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Role Name </td>
                                <td className="details-name" style={{"width": "66%"}}>Administrator</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Reportee Role </td>
                                <td className="details-name" style={{"width": "66%"}}>NA</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>
                                    Transaction Lock Duration</td>
                                <td className="details-name" style={{"width": "66%"}}>15 Days</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Visibility </td>
                                <td className="details-name" style={{"width": "66%"}}>Site Wise</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </TabPane>
                <TabPane name="Permission" key="2">
                    <div className="tab_content_header">
                        <table className="table table-bordered bg-white">
                            <thead className="table-secondary">
                            <tr>
                                <th scope="col" style={{width:"30%"}}>Assets</th>
                                <th scope="col" style={{width:"10%"}} className="text-center">Full Access</th>
                                <th scope="col" style={{width:"10%"}} className="text-center">View</th>
                                <th scope="col" style={{width:"10%"}} className="text-center">Add</th>
                                <th scope="col" style={{width:"10%"}} className="text-center" >Edit</th>
                                <th scope="col" style={{width:"10%"}} className="text-center">Delete</th>
                                <th scope="col" style={{width:"20%"}} className="text-center">More Permissions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <Ax>
                                    <td>Asset</td>
                                    <td className="text-center"><input type="checkbox"/></td>
                                    <td className="text-center"><input type="checkbox"/></td>
                                    <td className="text-center"><input type="checkbox"/></td>
                                    <td className="text-center"><input type="checkbox"/></td>
                                    <td className="text-center"><input type="checkbox"/></td>
                                    <td className="text-center">
                                    <span className="dropdown">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button">Edit Role</a></li>
                                        </ul>
                                    </span>
                                    </td>
                                </Ax>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </TabPane>
            </Tabs>
        </section>);
    }

    
    siteSearchModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="siteSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Search Site</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="searchSiteForm" onSubmit={this.siteSearchFormSubmit}>
                            <div className='modal-body'>
                                <div className="row align-items-center mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Site Name</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="search_name"
                                            type="text"
                                            value={this.state.siteFilterForm.search_name}
                                            onChange={(e) => this.formInputHandler(e, 'siteFilterForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Site Name"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Site Code</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="search_code"
                                            type="text"
                                            value={this.state.siteFilterForm.search_code}
                                            onChange={(e) => this.formInputHandler(e, 'siteFilterForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Site Code"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting}>Submit {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    changeStatusModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="changeStatusModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Change Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="changeStatusForm" onSubmit={this.submitChangeStatusForm}>
                            <div className='modal-body'>
                                <div className="row align-items-center mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Status</label>
                                    <div className="col-sm-7">
                                    <TapSelect
                                            options={this.state.AllStatusList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'changeStatusForm', 'status')}
                                            value={this.state.AllStatusList.find(u => this.state.changeStatusForm.status == u.value)}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            required={true}
                                        />
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Reason</label>
                                    <div className="col-sm-7">
                                        <textarea
                                            name="reason"
                                            value={this.state.changeStatusForm.reason}
                                            onChange={(e) => this.formInputHandler(e, 'changeStatusForm')}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Reason"
                                            required={true}
                                            style={{ height: "80px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting}>Submit {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    siteViewDetails                =   ()   =>   {
       let detail = this.state.siteDetail
        return (<section className="page_containt " style={{ top: "-2px" }}>
            <div className="pageTbl bg-white">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                            type="button" role="tab" aria-controls="details" aria-selected="true">Details
                        </button>
                    </li>
                    {this.props.permissions && this.props.permissions.includes('iam-site-user-linking')
                        ? <li className="nav-item" role="presentation">
                            <button className="nav-link " id="linking-tab" data-bs-toggle="tab" data-bs-target="#linking"
                                type="button" role="tab" aria-controls="linking" aria-selected="true" onClick={() => this.getLinkedUsersList(this.state.siteDetail?.id)}>User Linking
                            </button>
                        </li> : null}

                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane  show active" id="details" role="tabpanel"
                        aria-labelledby="details-tab">
                        <div className="tab_content_header">
                            <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>Site Name</td>
                                        <th>{this.state.siteDetail ? this.state.siteDetail.site_name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Site Code</td>
                                        <th>{this.state.siteDetail ? this.state.siteDetail.site_name : "-"}</th>


                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Status</td>
                                        <th>{this.state.siteDetail ? <Status color={this.state.siteDetail.status_color_code}>{this.state.siteDetail.status_text}</Status> : "-"}</th>
                                    </tr>
                                    {this.state.siteDetail && this.state.siteDetail.additional_attribute_data && this.state.siteDetail.additional_attribute_data.map((a, k) => (<tr key={k}>
                                        <td className="details-label text-capitalize" style={{ width: "25%" }}>{a.name}</td>
                                        <td className="details-name">
                                            {a.value_display ? a.value_display : "-"}
                                        </td>
                                    </tr>))}

                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="tab-pane" id="linking" role="tabpanel" aria-labelledby="linking-tab">
                        <div className="tab_content_wrapper my-2">
                            <span className="content_heading">Users</span>
                            <div className="text-end me-0">
                                <button className="btn btn-secondary mx-2" onClick={this.delinkUserSiteHandler}>DeLink Users</button>
                                <button className="btn btn-primary" onClick={() => this.linkUserSiteHandler()}>Link Users</button>
                            </div>
                        </div>
                        <table className=" table table-hover table-bordered  table-responsive bg-white my-2 table-sm ">
                            <thead className="table-secondary">
                                <tr >
                                    <th style={{ "width": '10%' }} className="text-center">
                                        <input type='checkbox' id="all_check_user" onChange={this.checkAllList} disabled={this.state.linkedUsersList && this.state.linkedUsersList.length > 0 && this.state.linkedUsersList.filter(e => e.can_edit == 'N').length > 0 ? true : false} /></th>
                                    <th>User Name</th>
                                    <th style={{ "width": '20%' }}>Role</th>
                                    <th style={{ "width": '20%' }}>Email</th>
                                    <th style={{ "width": '20%' }}>Phone Number</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.linked_users_loading
                                    ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                    : (this.state.linkedUsersList && this.state.linkedUsersList.length > 0
                                        ? (this.state.linkedUsersList.map((t, k) => {
                                            return (<tr key={k}>
                                                <td className='text-center'>
                                                    <input
                                                        type='checkbox'
                                                        className="user_id_checkbox"
                                                        name="user"
                                                        value={t.id}
                                                        disabled={t.can_edit == 'Y' ? false : true}
                                                    />
                                                </td>
                                                <td>{t.full_name}</td>
                                                <td>{t.role && t.role.role ? t.role.role : "- "}</td>
                                                <td>{t.email ? t.email : " -"}</td>
                                                <td>{t.mobile}</td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                                    )
                                }
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
        </section>)
    }

    linkUserModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="linkUserModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Link User</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}>Site Name   </td>
                                        <th>{this.state.siteDetail ? this.state.siteDetail.site_name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Site Code </td><th>{this.state.siteDetail ? this.state.siteDetail.site_code : "-"}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <form id="linkUserForm" onSubmit={this.submitLinkUserForm}>
                            <div className='modal-body'>

                                <div className="row align-items-center mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">User</label>
                                    <div className="col-sm-7">
                                    <InputUserSearch
                                        changeEvent={(selectedOption) => {
                                            this.setState({
                                                linkUserForm: { ...this.state.linkUserForm, user_ids    : selectedOption.length > 0 ? selectedOption : [] },
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        exclude_user_ids={this.state.linkedUserIds}
                                        value={this.state.linkUserForm && this.state.linkUserForm.user_ids   && this.state.linkUserForm.user_ids.length > 0 ? this.state.linkUserForm.user_ids.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting}>Submit {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        

        return (
            <ApplicationLayout>
                <ToastContainer />
                <Helmet><title>Site List</title></Helmet>
                {this.siteListjsx()}
                
                {this.siteSearchModalJsx()}
                {this.changeStatusModalJsx()}
                {this.linkUserModalJsx()}
                <SiteAdd ref={this.addSiteRef} refreshList={() => this.getSiteListing(1)}/>
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(SiteList);
