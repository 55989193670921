import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import Ax from "../../../components/hoc/Ax";
import { Link } from 'react-router-dom';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { toast } from 'react-toastify';
import moment from 'moment';

class DowntimeView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                      =             {
            downtimeViewData            :            null, 
            viewDataLoading             :           false,       
        }
       
    }

    componentDidMount() {
           this.intializeData(this.props) 
           if(this.props.downtimeId){
               this.getDowntimeDetail(this.props.downtimeId,this.props.counterlog)
           }   
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.downtimeViewData != nextProps.downtimeViewData) {
            this.intializeData(nextProps);
        }
        if(this.props.downtimeId !== nextProps.downtimeId){
            this.getDowntimeDetail(nextProps.downtimeId,nextProps.counterlog)
        }
    }

    intializeData       =       (pr)      =>      {
        this.setState({access_token : pr.access_token,downtimeViewData : pr.downtimeViewData})
    }

    getDowntimeDetail                 =   (downtime_id='')  =>  {
        let total_hours = "00:00";
        this.setState({viewDataLoading: true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/downtime/detail' , this.state.access_token , {transaction_id : downtime_id,need_section:'Y',need_defect_codes:'Y'}, {}, (response) => {
            let respData = response.data;
           this.setState({
                downtimeViewData : respData.data,  
            },() => {
               
            })
            
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({viewDataLoading: false}))
    }

     SecondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }   

    render                              =   ()  =>  {
        let downtime = this.state.downtimeViewData;
    
        return (<Ax>
            {this.state.viewDataLoading ? <Loader />
                : <Ax>
                    <div className="tab_content_wrapper"> <span className="content_heading">Downtime Detail</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-1 ">
                        {downtime && <tbody>
                            <tr>
                                <td style={{ width: "25%" }}>Transaction ID</td>
                                <th>{downtime.transaction_id ? downtime.transaction_id : ''} </th>
                            </tr>
                            <tr>
                                <td style={{ width: "25%" }}>Downtime Type</td>
                                <th>{downtime.downtime_type ? downtime.downtime_type.name : ''} </th>
                            </tr>
                            <tr>
                                <td style={{ width: "25%" }}>Duration</td>
                                <th>{downtime.downtime_seconds ? this.SecondsToHHMM(downtime.downtime_seconds) : ''} </th>
                            </tr>
                            <tr>
                                <td style={{ width: "25%" }}>Section</td>
                                <th>{downtime.section_data ? downtime.section_data.component : ''} </th>
                            </tr>
                            {downtime.sub_section_data && <tr>
                                <td style={{ width: "25%" }}>Sub Section</td>
                                <th>{downtime.sub_section_data ? downtime.sub_section_data.subcomponent : ''} </th>
                            </tr>}
                            {downtime.start_time && <tr>
                                <td style={{ width: "25%" }}>Start Time</td>
                                <th>{downtime.start_time ? moment(downtime.start_time, 'HH:mm:ss').format('HH:mm A') : ''} </th>
                            </tr>}
                            {downtime.end_time && <tr>
                                <td style={{ width: "25%" }}>End Time</td>
                                <th>{downtime.end_time ? moment(downtime.end_time, 'HH:mm:ss').format('HH:mm A') : ''} </th>
                            </tr>}
                            {downtime.defect_code_data && downtime.defect_code_data.length > 0 && <tr>
                                <td style={{ width: "25%" }}>Defect Codes</td>
                                <th>{downtime.defect_code_data.map(dc => dc.name).join(', ')} </th>
                            </tr>}

                            {downtime.additional_attributes_data && downtime.additional_attributes_data.length > 0 ?
                                downtime.additional_attributes_data.map((attribute, index) => {
                                    return (<tr>
                                        <td style={{ width: "25%" }}>{attribute.name}</td>
                                        <th>{attribute.value_display ? attribute.value_display : "-"}</th>
                                    </tr>)
                                })
                                : null}
                            <tr>
                                <td style={{ width: "25%" }}>Remarks</td>
                                <th>{downtime.remarks ? downtime.remarks : ''} </th>
                            </tr>
                        </tbody>}
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Counterlog  Detail</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "25%" }}>Site</td>
                                <th>{downtime && downtime.site ? downtime.site.site_name : '-'}   </th>
                            </tr>
                            {downtime && downtime.meter_reading && downtime.meter_reading.length > 0 && downtime.meter_reading.map(mr => {
                                return (
                                    <tr>
                                        <td style={{ "width": "25%" }}>{mr.label}</td>
                                        <th>{mr.reading} {mr.unit}   </th>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Transaction  Detail</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "25%" }}>Transaction ID</td>
                                <th>{downtime && downtime.transaction_id ? downtime.transaction_id : '-'}   </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Transaction</td>
                                <th>{downtime && downtime.feed_datetime ? downtime.feed_datetime : "-"}   </th>
                            </tr>
                            <tr>
                                <td>Executed By</td>
                                <th>{downtime && downtime.createdBy ? downtime.createdBy.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Entry</td>
                                <th >{downtime && downtime.created_at ? downtime.created_at : '-'}   </th>
                            </tr>
                            <tr>
                                <td>Updated By</td>
                                <th>{downtime && downtime.updatedBy ? downtime.updatedBy.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Date of Update</td>
                                <th>{downtime && downtime.updated_at ? downtime.updated_at : '-'}   </th>
                            </tr>

                        </tbody>
                    </table>
                </Ax>}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};

export default connect(mapStateToProps)(DowntimeView);