import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import Status from '../../../components/ui/Status';

class Vendor extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                  =   {
            searchedVendor              :   this.props.searchedVendor,
            disabledPerformAction       :   props.disabledPerformAction && props.disabledPerformAction == true ? true : false,
            labelDisable                :   props?.labelDisable ? props?.labelDisable : false, 
        };
       
       
    }
    
    componentDidMount(){
        this.addVendorModal                 =   new Modal(document.getElementById('addVendorModal'), {keyboard: false, backdrop :false});
        this.vendorDetailsModal             =   new Modal(document.getElementById('vendorDetailsModal'), {keyboard: false, backdrop :false});
        this.vendorAddressModal             =   new Modal(document.getElementById('vendorAddressModal'), {keyboard: false, backdrop :false});
    
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.searchedVendor !== this.props.searchedVendor) {
           this.setState({
               searchedVendor : nextProps.searchedVendor
           })
        }
        
    }

    viewVendorDetailsInit                   =   ()  =>  {
        this.vendorDetailsModal.show()
        this.props.getVendorDetails()
    }

    addVendorModalInit                      =   ()  =>  {
        if(this.addVendorModal) {
            this.addVendorModal.show();
        }
    }
    
    viewVendorAddressInit                   =   ()  =>  {
        this.vendorAddressModal.show()
        this.props.getVendorDetails()
    }

    viewVendorAddressModalClose                     =   ()  =>  {
        this.vendorAddressModal.hide()
    }

    //*********************************VENDOR DETAIL JSX*************************
    viewVendorDetailsModalJsx              =   ()  =>  {
        let  vendorDetails = this.props.vendorDetails
        return (
            <div className="modal fade" id="vendorDetailsModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="vendorDetailsModalLabel">View Vendor</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {!this.props.details_loading ?
                            vendorDetails ? <div className="modal-body">
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Details</span>
                                </div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Vendor Name</td>
                                            <th style={{ "width": "66%" }}>{vendorDetails.name ? vendorDetails.name : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Code</td>
                                            <th style={{ "width": "66%" }}>{vendorDetails.code ? vendorDetails.code : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Status</td>
                                            <th style={{ "width": "66%" }}>{vendorDetails.status ? <Status color={vendorDetails.status_color}>{vendorDetails.status_text}</Status> : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Notes</td>
                                            <th style={{ "width": "66%" }}>{vendorDetails.notes ? vendorDetails.notes : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper align-items-center">
                                    <span className="content_heading">Contact Details </span>
                                </div>
                                <table className="table table-hover table-bordered my-3 table-sm">
                                    <thead className="table-secondary" >
                                        <tr>
                                            <th style={{ width: "5%" }} className='text-center'>S.No</th>
                                            <th style={{ width: "25%" }}>Name</th>
                                            <th style={{ width: "30%" }}>Email ID</th>
                                            <th style={{ width: "20%" }} className='text-center'>Mobile Number</th>
                                            <th className='text-center' style={{ width: "15%" }}>Primary Contact</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vendorDetails && vendorDetails.contacts && vendorDetails.contacts.length > 0
                                            ? vendorDetails.contacts.map((c, i) => {
                                                return (<tr>
                                                    <td className='text-center'>{i + 1}</td>
                                                    <td>{c.name}</td>
                                                    <td> {c.email ? c.email : '-'}</td>
                                                    <td className='text-center'> {c.mobile ? c.mobile : '-'}</td>
                                                    <td className='text-center'> {c.is_primary == "Y" ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} /> : ""}</td>
                                                </tr>)
                                            })
                                            : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Domain</span>
                                </div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Vendor Domain</td>
                                            <th style={{ "width": "66%" }}>{vendorDetails.domain && vendorDetails.domain.length > 0 ? (vendorDetails.domain.map((domain, k) => <span key={k} className="badge bg-secondary">{domain}</span>))
                                                : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>Authorization</td>
                                            <th style={{ "width": "66%" }}>{vendorDetails.authorization ? vendorDetails.authorization : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Area of Operations</span>
                                </div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ "width": "34%" }}>States</td>
                                            <th style={{ "width": "66%" }} className='fs13'>{vendorDetails.operations_state_list && vendorDetails.operations_state_list.length > 0 ? (vendorDetails.operations_state_list.map((state, k) => state.name).join(', '))
                                                : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "34%" }}>District</td>
                                            <th style={{ "width": "66%" }} className='fs13'>{vendorDetails.operations_district_list && vendorDetails.operations_district_list.length > 0 ? (vendorDetails.operations_district_list.map((state, k) => state.name).join(', '))
                                                : "-"}</th>
                                        </tr>

                                    </tbody>
                                </table>
                            </div> : null
                            : <Loader />}
                    </div>
                </div>
            </div>
        );
    }
   
    //*********************************VENDOR ADDRESS JSX*************************
    viewVendorAddressModalJsx               =   ()  =>  {
        return (
            <div className="modal fade" id="vendorAddressModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="vendorDetailsModalLabel">Vendor Address Lists</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {!this.props.vendor_details_loading ?
                            this.props.vendorAddressList ? <div className="modal-body">
                                <table className="table table-hover table-bordered">
                                    <tbody>
                                        {this.props.vendorAddressList.length > 0 ? this.props.vendorAddressList.map((a, i) => {
                                            return (<tr>

                                                <td style={{ width: "80%" }}>
                                                    <div className='py-1'>Address : <b>{a.address1 ? a.address1 + ", " : ""}
                                                        {a.address2 ? a.address2 + ", " : ""}
                                                        {a.landmark ? a.landmark + ", " : ""}
                                                        {a.city ? a.city + ". " : "-"}</b></div>
                                                    <div className='py-1'>District, State & Pincode : <b>{a.district_name ? a.district_name + ", " : "- , "}
                                                        {a.state_name ? a.state_name : " - "}
                                                        {a.pincode ? " & " + a.pincode : ""}</b></div>
                                                    <div className='py-1'>GSTIN : <b>{a.gstin ? a.gstin : "- "}</b></div>
                                                </td>
                                                <td className='text-center' style={{ width: "10%" }}>
                                                    {this.props.selectedVendorAdress && (a.id == this.props.selectedVendorAdress.id) ?
                                                        <button type="button" className='btn btn-secondary'>Selected</button>
                                                        : <button type='button' className='btn btn-primary' onClick={() => { this.props.changeAddressHandler(a.id); this.viewVendorAddressModalClose() }}>Select</button>}
                                                </td>
                                            </tr>)
                                        })
                                            : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                    </tbody>
                                </table>
                            </div> : null
                            : <Loader />}
                    </div>
                </div>
            </div>
        );
    }

    addVenodrModalInit          =       ()           =>      {
        this.props.vendorRef.current.vendorAddModalInit();  
    }

    editVenodrModalInit          =       ()           =>      {
        this.props.vendorRef.current.vendorAddModalInit(this.props.vendorDetails);  
    }


    render() {
        return (<Ax>
            <div className={`row align-items-center ${this.state.labelDisable ? "" : "my-3"}`}>
                {
                    this.state.labelDisable 
                    ? 
                    ''
                    :
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Vendor</label>
                    </div>
                }
                <div className={`${this.state.labelDisable ? "col-sm-12" : "col-sm-6"}`}>
                    <InputVendorSearch
                        required={true}
                        placeholder="Search Vendor"
                        changeEvent={(selectedOption) => {
                            this.props.onChange(selectedOption);
                            this.setState({
                                searchedVendor: selectedOption ? selectedOption : null,
                            }, () => this.props.getVendorDetails(selectedOption ? selectedOption.value : selectedOption));
                        }}
                        disabled={this.props.disabled}
                        only_active="Y"
                        value={this.state.searchedVendor ? { ...this.state.searchedVendor, label: this.state.searchedVendor.display_label } : null}
                    />
                </div>
                {this.state.disabledPerformAction == false && !this.state.labelDisable && <div className="col-sm-3">
                    {this.state.searchedVendor ?
                        <Ax>
                            <button type="button" className='btn btn-primary pl0 pr0 ' style={{ height: "38px" }} onClick={this.viewVendorDetailsInit}>
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEye} className='img-fluid' />
                            </button>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary mx-2 pl0 pr0" style={{ height: "38px" }}>
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end cursor_pointer" aria-labelledby="dropdownMenuButton1">
                            {this.props.permissions.includes('vendor-manage-address') &&  <li><a className="dropdown-item" onClick={this.viewVendorAddressInit}>Change Address</a></li>}
                            {this.props.permissions.includes('vendor-edit') &&   <li><a className="dropdown-item" onClick={this.editVenodrModalInit}>Edit Vendor</a></li>}
                            </ul>
                        </Ax>
                        : null}
                    {
                    this.props.permissions.includes('vendor-add') &&  <a role="button" onClick={this.addVenodrModalInit} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Vendor</a>
                    }
                </div>}

                {this.viewVendorAddressModalJsx()}
                {this.viewVendorDetailsModalJsx()}
            </div>
            {this.props.vendorDetails ?
                <div className="row align-items-center mt1 pb-2 fs13">
                    {!this.props.vendor_details_loading
                        ? <div className={`${this.state.labelDisable ? "" : "offset-3"} col-sm-8`}>
                            <label className='form-label'>Address : {this.props.selectedVendorAdress.address1 ? this.props.selectedVendorAdress.address1 + ", " : ""}
                                {this.props.selectedVendorAdress.address2 ? this.props.selectedVendorAdress.address2 + ", " : ""}
                                {this.props.selectedVendorAdress.landmark ? this.props.selectedVendorAdress.landmark + ", " : ""}
                                {this.props.selectedVendorAdress.city ? this.props.selectedVendorAdress.city + ". " : "-"}</label><br />
                            <label className='form-label'>Source of Supply : {this.props.selectedVendorAdress.district_name ? this.props.selectedVendorAdress.district_name + ", " : "- , "}
                                {this.props.selectedVendorAdress.state_name ? this.props.selectedVendorAdress.state_name : " - "}
                                {this.props.selectedVendorAdress.pincode ? " & " + this.props.selectedVendorAdress.pincode : ""}</label> <br />
                            <label className='form-label'>GSTIN : {this.props.selectedVendorAdress ? this.props.selectedVendorAdress.gstin : "-"}</label>
                        </div>
                        : <Loader />}
                </div> : null}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [] ,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(Vendor);