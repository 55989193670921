import React, { useState, useEffect, useRef } from 'react';
import tapIcon from "../../../services/TapIcon";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "bootstrap";
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { useSelector } from 'react-redux';
import Loader from '../../../components/ui/Loader/Loader';
import swal from "sweetalert";

const AssetsMatrixCompliance = () => {
    const configComplianceUrl = `${IAM_API_BASE_URL_2}/matrix_config_compliance`;
    const access_token = useSelector(state => state.auth.access_token);
    const [complianceData, setComplianceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ id: '', name: '', notes: '' });
    const addComplianceModalRef = useRef(null);
    const [saveFormSubmitting, setSaveFormSubmitting] = useState(false);
    useEffect(() => {
        addComplianceModalRef.current = new Modal(document.getElementById('addComplianceModal'), { keyboard: false, backdrop: false });
        fetchComplianceData();
    }, []);

    const fetchComplianceData = () => {
        setLoading(true);
        HttpAPICall.withAthorization('GET', `${configComplianceUrl}/list`, access_token, {}, {}, (response) => {
            const data = response.data;
            setComplianceData(data.data);
        }).then(() => {
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching compliance data:", error);
            setLoading(false);
        });
    };

    const addComplianceModalInit = () => {
        setFormData({ id: '', name: '', notes: '' }); // Reset form data
        addComplianceModalRef.current.show();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleEditClick = (item) => {
        setFormData({ id: item.id, name: item.name, notes: item.notes });
        addComplianceModalRef.current.show();
    };

    const handleDeleteClick = (id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed. Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then((willDelete) => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', `${configComplianceUrl}/delete/${id}`, access_token, {}, {}, (response) => {
                    toast.success("Compliance deleted successfully!");
                    fetchComplianceData(); // Refresh the compliance data
                }).catch((error) => {
                    console.error("Error deleting compliance:", error);
                    toast.error("Failed to delete compliance.");
                });
            }
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSaveFormSubmitting(true);
        const complianceUrl = formData.id ? `${configComplianceUrl}/edit` : `${configComplianceUrl}/add`;
        const method = formData.id ? 'PUT' : 'POST';

        HttpAPICall.withAthorization(method, complianceUrl, access_token, {}, formData, (response) => {
            toast.success(formData.id ? "Compliance updated successfully!" : "Compliance added successfully!");
            fetchComplianceData(); // Refresh the compliance data
            addComplianceModalRef.current.hide(); // Hide the modal
        }).then(() => setSaveFormSubmitting(false)).catch((error) => {
            console.error("Error saving compliance:", error);
            toast.error(formData.id ? "Failed to update compliance." : "Failed to add compliance.");
            setSaveFormSubmitting(false);
        });
    };

    const addComplianceModalJsx = () => {
        return (
            <div className="modal fade" id="addComplianceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{formData.id ? "Edit Compliance" : "Add Compliance"}</h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label require">Compliance Name</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Compliance Name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="notes" className="form-label">Notes</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            name="notes"
                                            className="form-control"
                                            placeholder="Leave a note"
                                            value={formData.notes}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                                <button type="submit" disabled={saveFormSubmitting} className="btn btn-primary">
                                    {formData.id ? "Update" : "Save"} {saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section className="item_view_container bg-white">
            <ToastContainer />
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="Compliance-tab" data-bs-toggle="tab" data-bs-target="#Compliance"
                        type="button" role="tab" aria-controls="Compliance" aria-selected="true">Compliance
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane show active" id="Compliance" role="tabpanel"
                    aria-labelledby="Compliance-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mb-3">
                            <div>
                                <span className="content_heading">Compliance</span>
                            </div>
                            <div>
                                <button
                                    className="btn btn-primary" onClick={addComplianceModalInit}>
                                    Add
                                </button>
                            </div>
                        </div>
                        {loading ? (
                            <div><Loader /></div>
                        ) : (
                            <table className="table table-hover table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="table-head-row" style={{ width: "10%" }}> S. No</th>
                                        <th scope="col" className="table-head-row" style={{ width: "30%" }}>Compliance Name</th>
                                        <th scope="col" className="table-head-row" style={{ width: "55%" }}>Notes</th>
                                        <th scope="col" className="table-head-row" style={{ width: "14%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {complianceData.length > 0 ? complianceData.map((item, index) => (
                                        <tr key={item.id}>
                                            <td scope="row">{index + 1}</td>
                                            <td scope="row">{item.name}</td>
                                            <td scope="row">{item.notes}</td>
                                            <td scope="row">
                                                <div>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                        alt="action-icon"
                                                        className="img-fluid pr10 pl10"
                                                        data-bs-toggle="dropdown" href="#"
                                                        role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item" onClick={() => handleEditClick(item)}>Edit</li>
                                                        <li className="dropdown-item" onClick={() => handleDeleteClick(item.id)}>Delete</li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr><td className='text-center' colSpan={4}>No Record Found</td></tr>}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            {addComplianceModalJsx()}
        </section>
    );
};

export default AssetsMatrixCompliance;
