import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import download from '../../../services/DownloadFile';
import moment from 'moment';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AllocationDetail from '../../inventory/allocation/AllocationDetail';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import TapIcon from '../../../services/TapIcon';

class AssetAllocation extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.initAllocationSearchForm         =       {
            search_transaction_id           :       '',
            date_range                       :       '',
            date_range_start                 :       null,
            date_range_end                   :       null,
            search_warehouse_ids            :       [],
            search_workstation_ids          :       [],
            search_voucher_number           :       '',
            search_item_ids                 :       [],
            search_employee_ids                 :       [],
        }

        this.allocationSearchFormLabel     =       {
            search_transaction_id           :       'Transaction ID : ',
            date_range                      :       'Date Range : ',
            search_warehouse_ids            :       'Warehouses : ',
            search_workstation_ids          :       'Workstations : ',
            search_voucher_number           :       'Voucher Number : ',
            search_item_ids                 :       'Items : ',
            search_employee_ids                 :   'Employees : ',
        }

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                           =     {
            allocationAddScreenView          :        false,
            allocationUpdateScreenView       :       false,
            minimizeTable                     :       false,
            allocationFormDataLoaded         :       false,
            allocationAddFormData            :       null,
            listing_loading                   :       false,
            listing_tbl_page                  :       1,
            allocation_listing               :       [],
            listingMeta                       :       null,
            totalListingCount                 :       0,
            view_loading                      :       false,
            allocationViewData               :       null,
            allocationTransactionId            :   '',
            isc_configuration                 :       {},
            pdfDownloading                    :       false,
            reportDownloading                 :      false,
            searchedAssets                    :        [],
            searchedItem                      :        [],
            allocationSearchForm             :       {...this.initAllocationSearchForm},
            submittedAllocationFilterForm    :       {...this.initAllocationSearchForm},
            addCommentForm                      :       {...this.updateCommentFormInit},
            allAllowedStatus                    :       [],
            formSearchedElems                 :        [],
            allWorkstations                   :        [],
            all_periods                        :        [],
            allocationSearchFormDataLoaded   :         false,
            openingUpdateScreen               :         false,
            searchedEmployees                   :   [],
            cancelledItemList                   :       [],
            cancelItemFormSubmitting             :       false,
            cancelledItemForm                 :       {...this.cancelItemFormInit},
            assetId                             :       '',
            assetDetail                         :       null,
            listingData                         :       []
        };
        this.myRef                         =   React.createRef();
        this.itemDetailsRef                =    React.createRef();
        this.CONSUMPTION_FORMDATA_URL       =    INVENTORY_API_BASE_URL_2 + '/consumption/add_form_data'
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
        this.allocationSearchModal                         =   new Modal(document.getElementById('allocationSearchModal'), {keyboard: false, backdrop :false});
        this.releasedItemModal                            =   new Modal(document.getElementById('releasedItemModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
        let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        this.setState({
                assetId                 :       pr.match?.params?.id,
                all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                all_periods             :        all_periods ,
                isc_configuration       :        pr.isc_configuration
            },() => {
                    
            this.getAsssetDetails(pr.match?.params?.id)
            this.loadListingTblData(1,pr.match?.params?.id)
            // this.searchFormDataInit(pr)
        })
    }
    

    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    loadListingTblData(page,id){
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedAllocationFilterForm,search_asset_ids : [id ? id : this.state.assetId],need_item_profile : "Y",for_report : 'N'};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_INVENTORY_API_SERVER + '/report/item-wise-allocation-order', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount           :       respData.total
            });
        }).then(() => this.setState({listing_loading: false}));
    }

    allocationSearchModalInit               =       ()             =>        {
        this.allocationSearchModal.show();
        let all_periods                 =   [...this.props.all_periods , {key : 'custom', display : 'Custom Date Range'}];
        if(!this.state.allocationSearchFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.CONSUMPTION_FORMDATA_URL, this.props.access_token, null, null, (response) => {
                this.setState({
                    allocationSearchFormDataLoaded    :    true,
                    allWorkstations                    :    response.data.workstations.map((s) => { return {value: s.id, label: s.workstation_name}})
                });
            });
        }
    }

    submitAllocationSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.allocationSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.allocationSearchFormLabel[key];
            let value                       =   this.state.allocationSearchForm[key];
           
            if(value && value.length > 0) {
                searchParams[key]      =   value;
           if(label) {
                    let show_val            =   value;
                    if(key == 'search_workstation_ids') {
                        show_val            =  this.state.allWorkstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.allocationSearchForm ? this.state.allocationSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_voucher_number') {
                        show_val            =   this.state.allocationSearchForm ? this.state.allocationSearchForm.search_voucher_number : '';
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }

                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'date_range') {
                        let  start_range = moment(this.state.allocationSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.allocationSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedAllocationFilterForm   :   {...this.state.allocationSearchForm},
            formSearchedElems               :   searchedElems
        }, () => {
            this.allocationSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    allocationSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedAssets                  :   [],
            searchedEmployees               :   [],
            searchedItem                    :   [],
            allocationSearchForm            :   {...this.initAllocationSearchForm},
            submittedAllocationFilterForm     :   {...this.initAllocationSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.allocationSearchModal.hide();
        });
    }

    allocationClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedAssets                  :   [],
            searchedItem                    :   [],
            searchedEmployees               :   [],
            allocationSearchForm            :   {...this.initAllocationSearchForm},
            submittedAllocationFilterForm     :   {...this.initAllocationSearchForm}
        }, () => {
            this.allocationSearchModal.hide();
        });
    }

   
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    viewAllocationDetail          =      (id)        =>     {
        this.setState({minimizeTable  :   true})
        this.getAllocationViewDetails(id)
       
    }

    //************************GET allocation DETAILS**************** */
    getAllocationViewDetails             =    (transaction_id)        =>  {
       
         this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET',  TapApiUrls.INVENTORY + '/allocation/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 allocationViewData          :   respData.data,
                 allocationTransactionId     :   respData.data.transaction_id,
                 allAllowedStatus            :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                 addCommentForm              :   {...this.updateCommentFormInit , status : respData.data.status}
             });
            
         },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
        
     }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    checkAllItem() {
        let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox')
        if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                allocationItemForCheckbox[i].checked = document.getElementById('all_check_allocation').checked;
            }
        }
    }

   releasedItemModalInit       =       ()      =>      {
            this.setState({cancelledItemList : []} , () => {
            let cancelledItemList          =        this.state.cancelledItemList;
            var showErrorMessage                =   false;
            let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox');
            if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
                for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                    if(allocationItemForCheckbox[i].checked) {
                        let itemId = allocationItemForCheckbox[i].value;
                        let data = this.state.allocationViewData.items.find(i => i.id == itemId);
                    
                        let row                         =   {...cancelledItemList[i],...data };
                        cancelledItemList[i]            =   row;
                        let list = cancelledItemList.filter(Boolean)
                        this.setState({cancelledItemList   :   [...list],
                                    cancelledItemForm   :   {...this.cancelItemFormInit,
                                    notes : ''}}
                                    ,() => this.releasedItemModal.show())
                    
                    }
                }
            }
            if(this.state.cancelledItemList && this.state.cancelledItemList.length == 0 && !showErrorMessage){
                toast.error('Please select an Item for Cancellation', { position: toast.POSITION.TOP_RIGHT });
            }
       })
        
   }

   addToConsumptionModalInit       =       ()      =>      {
        // this.setState({cancelledItemList : []} , () => {
        let itemList          =        [];
        var showErrorMessage                =   false;
        let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox');
        if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                if(allocationItemForCheckbox[i].checked) {
                    let itemId = allocationItemForCheckbox[i].value;
                    let data = this.state.allocationViewData.items.find(i => i.id == itemId);
                    
                    itemList.push({...data,allocation_item_id : data.id})
                    
                }
            }
        }
        if(itemList && itemList.length == 0 && !showErrorMessage){
            toast.error('Please select an Item', { position: toast.POSITION.TOP_RIGHT });
        }else if(itemList.find(i => Number(i.pending_qty) <= 0)) {
            toast.error('Every Item\'s Pending qty must be more than 0.', { position: toast.POSITION.TOP_RIGHT });
        }else{
           
            let consumptionData         =       {...this.state.allocationViewData,items : itemList}
           
           this.props.history.push({ pathname: `/consumption_list/add`, state: {addConsumptionFromAllocation: consumptionData } })
        }
   
}

   updateComment           =           ()          =>      {
    // this.myRef.current.scrollIntoView()
   }

   viewItemModalInit                    =   (item)  =>  { 
       if (item) {
           this.itemDetailsRef.current.modalInit(item)
       }
   }

   editAllocation       =       ()      =>      {
        this.props.history.push({pathname: `/allocation_list/add`, state: {allocationData : this.state.allocationViewData }})
   }

   submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
            ...this.state.addCommentForm,
            transaction_id : this.state.allocationTransactionId
        }
        HttpAPICall.withAthorization('PUT', TapApiUrls.INVENTORY + '/allocation/comment_add', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getAllocationViewDetails(this.state.allocationTransactionId);
            this.loadListingTblData(1)
            this.setState({addCommentForm : {...this.updateCommentFormInit}})
        }).then(() => this.setState({ addCommentFormSubmitting : false }));
   }

   submitCancelItemForm            =       (e)      =>      {
    e.preventDefault()
    
    let items                   =        [...this.state.cancelledItemList];
      
    let itemsLists              =        items.map(i => {
                                          
                                             return {allocation_item_id : i.id, release_qty : parseInt(i.new_released_qty),transaction_id : i.transaction_id};
                                            });
                                           
    let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists]}
    
      this.setState({ cancelItemFormSubmitting: true })
      HttpAPICall.withAthorization('PUT', TapApiUrls.INVENTORY + '/allocation/release', this.props.access_token, {}, { ...formData }, (response) => {
          toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
          this.releasedItemModal.hide()
          this.setState({cancelledItemForm         :       {...this.cancelItemFormInit}})
          this.getAllocationViewDetails(this.state.allocationTransactionId)
      }).then(() => this.setState({ cancelItemFormSubmitting: false }));
  }

  //***********************DELETE  **************************** */
    deleteAllocation = () => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', TapApiUrls.INVENTORY + '/allocation/' + this.state?.allocationViewData?.transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({ minimizeTable: false })
                        this.loadListingTblData(1)
                    }).then(() => {

                    });
                }
            });
    }


   
   
    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader  assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props} />
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {
        
        return (<div> 
            <div id="assetCard" className="collapse pr-1 mt-1"  aria-expanded="true" >
            {this.state.asset_loading ? <div className='text-center pt-1'><Loader/></div>
            : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />}
        </div>
        </div>)
    }

    allocationListJsx            =       ()      =>    {

        return (<Ax>
            <div style={{ paddingTop: "4px" }}>
                <div className="page_title row m0" style={{ height: "45px" }}>
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3 style={{ paddingTop: "13px" }}>Allocation List</h3>
                    </div>
                    {!this.state.minimizeTable
                        ? <div className='col-sm-2 '>
                            <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                        </div> : null}
                    {!this.state.minimizeTable
                        ? <div className='col-sm-4 text-end mt7 '>
                            <button type="button" className="btn btn-secondary" onClick={this.allocationSearchModalInit} disabled={this.state.reportDownloading || this.state.listing_loading} >
                                <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>

                        </div> : null}
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <div className="col-sm-3 tap_border_left">
                                        <h3 className="text-capitalize" style={{ paddingTop: "12px" }}>Allocation : {this.state.allocationTransactionId}</h3>
                                    </div>
                                    <div className='col-sm-2 '>
                                        <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                    </div>
                                    <div className='col-sm-4 text-end mt7'>
                                        <button type="button" className="btn btn-secondary" onClick={() => this.updateComment()} >Update Status </button>
                                        <button type="button" disabled={this.state.allocationViewData?.can_edit == false ? true : false} onClick={() => this.editAllocation()} className="btn btn-secondary">Edit </button>
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                            <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a role="button" href

                                                    className={['dropdown-item'].join(' ')}
                                                    onClick={() => this.releasedItemModalInit()}

                                                >Release Item</a>
                                            </li>
                                            <li>
                                                <a role="button"

                                                    className={['dropdown-item'].join(' ')}
                                                    onClick={() => this.addToConsumptionModalInit()}

                                                >Consume Item</a>
                                            </li>
                                            <li>
                                                <a role="button" href
                                                    onClick={() => {
                                                        if (this.state?.allocationViewData?.can_delete == "Y") {
                                                            return this.deleteAllocation();
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.state?.allocationViewData?.can_delete == "Y" ? '' : 'disabled'].join(' ')}
                                                    title={this.state?.allocationViewData?.can_delete == "N" ? 'This action cannot be performed' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete</a>
                                            </li>
                                        </ul>
                                        <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                        </button>
                                    </div>
                                </Ax>
                            )
                            : null
                    }
                </div>
            </div>
            <div className="container-fluid pl0 pr13">
                <div className="page_containt row">
                    <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.allocationTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0 pageTbl pb-5"><div className=" bg-white p-2" >{this.state.view_loading ? <Loader /> : this.allocationViewDetailJsx()}</div> </div> : null}
                </div>
            </div>
        </Ax>);
    }

    allocationTableJsx                   =   ()  =>  {
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.allocationSearchClear} />
                <div className='table-responsive' style={{ maxHeight: this.state.formSearchedElems && this.state.formSearchedElems.length > 0 ? "59vh" : "69vh" }}>
                    <table className="table table-bordered table-fixed  bg-white table-sm">
                        <thead className="table-secondary" >
                            {this.state.minimizeTable
                                ? (<tr>
                                    <th scope="col" style={{ width: "15%" }}>Allocation</th>
                                </tr>)
                                : (<tr className='text-center'>
                                    <th className="text-center" style={{ width: "22%" }}>Item Name</th>
                                    <th className="text-center" style={{ width: "22%" }}>Item Code</th>
                                    <th  style={{ width: "9%" }}>Transaction ID</th>
                                    <th  style={{ width: "9%" }}>Date</th>
                                    <th className="text-center" style={{ width: "14%" }}>Warehouse</th>
                                    <th className="text-center" scope="col" style={{ width: "7%" }} > Status</th>
                                    <th scope="col" style={{ width: "6%" }} > Qty</th>
                                    <th scope="col" style={{ width: "6%" }} > Pending</th>
                                    <th scope="col" style={{ width: "5%" }}>Action</th>
                                </tr>)
                            }
                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                : (this.state.listingData && this.state.listingData.length > 0
                                    ? (this.state.listingData.map((rowData, k) => {

                                        return (<tr>
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }}>
                                                                <a href="#" className="text-capitalize link-primary" onClick={() => this.viewAllocationDetail(rowData.transaction_id)}>{rowData.transaction_id} </a>
                                                                <div className="mt-1">
                                                                    <small> <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>{rowData.warehouse_name}</span> </small>
                                                                    <small className="float-end">{rowData.status ? rowData.status.name : "-"}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    : (<Ax>

                                                        <td>{rowData.item_name}</td>
                                                        <td>{rowData.item_code}</td>
                                                        <td className='text-center'><a role="button" className="link-primary" onClick={() => this.viewAllocationDetail(rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                                        <td className='text-center'>{rowData.transaction_date_display}</td>
                                                        <td>{rowData.warehouse_name}</td>

                                                        <td className="text-center">{rowData.status ? rowData.status.name : "-"}</td>
                                                        <td className="text-end">{rowData.qty}</td>
                                                        <td className="text-end">{rowData.pending_qty ? rowData.pending_qty : 0}</td>
                                                        <td className="text-center">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewAllocationDetail(rowData.transaction_id)}>View</a></li>
                                                            </ul>
                                                        </td>
                                                    </Ax>)}

                                        </tr>);
                                    })
                                    )
                                    : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                                )}
                        </tbody>
                        {/* <tbody>
                            {this.state.listing_loading
                                ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                : (this.state.allocation_listing.length > 0
                                    ? (this.state.allocation_listing.map((item, index) => {
                                      
                                        return (<tr key={index} >
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }}>
                                                                <a href="#" className="text-capitalize link-primary" onClick={() => this.viewAllocationDetail(item.transaction_id)}>{item.transaction_id ? <div>{item.transaction_id} ({item.transaction_date_display}) </div> : "-"}  </a>
                                                                <div className="mt-1">
                                                                    <small> <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>{item.warehouse?.name ?? "-"}</span> </small>
                                                                    <small className="float-end">{item.status_data ? item.status_data.name : "-"}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    : (<Ax>
                                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                        <td className='text-center'>{item.transaction_date_display}</td>
                                                        <td className='text-center'><a role="button" className="link-primary" onClick={() => this.viewAllocationDetail(item.transaction_id)}>{item.transaction_id ? item.transaction_id : "-"}</a></td>
                                                        <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                            : "-"}</td>
                                                       
                                                        <td className='text-center'>{item.total_qty ? item.total_qty : 0}</td>
                                                        <td className='text-end'>{item.total_pending_qty ? item.total_pending_qty : 0}</td>
                                                        <td className='text-center'>{item.status_data ? item.status_data.name : "-"}</td>
                                                        <td className="text-center">
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item" role="button" onClick={() => this.viewAllocationDetail(item.transaction_id)}>View</a></li>
                                                            </ul>
                                                        </td>
                                                    </Ax>
                                                    )}
                                        </tr>)
                                    }))
                                    : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                )
                            }
                        </tbody> */}
                    </table>

                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                    />
                </div>

            </Ax>
        );
    }

    allocationViewDetailJsx                =   ()   =>   {
        let allocationData = this.state.allocationViewData;
        return (<div>
            <div className="row mr0">
                <div className="col-sm-12 pr0 pb-4">
                    <AllocationDetail allocationData={this.state.allocationViewData} checkAllItem={this.checkAllItem} />
                    <hr />
                    <div>
                        <div className="tab_content_wrapper" ref={this.myRef}>
                            <span className="content_heading">Comments & History</span>
                        </div>
                        <form onSubmit={this.submitUpdateCommentForm}>
                            <div className="my-3 row">
                                <label className="col-sm-3 col-form-label">Change status to</label>
                                <div className="col-sm-5">
                                    <TapSelect
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                        }}
                                        value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                        options={this.state.allAllowedStatus}
                                        isSearchable={false}
                                        // isDisabled={this.state.poDetail?.change_status == "N"}
                                        placeholder="Please Select Status"
                                    />
                                </div>
                            </div>
                            <div className="my-2">
                                <label className="form-label">Comments</label>
                                <textarea
                                    name="notes"
                                    value={this.state.addCommentForm.notes}
                                    onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                    className="form-control"
                                    placeholder="Please Add a comment"
                                    required
                                    style={{ height: "100px" }}
                                />
                            </div>
                            <div className="col-12 text-end">
                                <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Update {this.state.addCommentFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                        <hr />
                        <div className="my-1">
                            {
                                allocationData.comments && allocationData.comments.length > 0 ? allocationData.comments.map((c, k) => {
                                    return (<Ax key={k}><div className="row">
                                        {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                        <div className="col-sm-4 text-end">
                                            <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                            <span> {c.created_at_display}</span>
                                        </div>
                                        <div className="col-sm-8 my-1">{c.notes}</div>
                                    </div><hr /></Ax>);
                                })
                                    : null
                            }
                        </div>

                    </div>
                </div>

            </div>

        </div>)
    }

    releasedItemModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="releasedItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Released Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "12%" }}>Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Released Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Pending Qty</th>

                                                    <th scope="col" style={{ width: "12%" }}>Released</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="text-end" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td className='text-start'>{i.itemData ? <div><span className="fw-bold">{i.itemData.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.itemData.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.itemData?.manufacturer?.name ?? '-'} (MPN : {i.itemData?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.itemData?.tags?.length > 0
                                                                    ? (i.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td>{i.qty ? i.qty : 0}</td>
                                                        <td>{i.released_qty ? i.released_qty : 0}</td>
                                                        <td>{i.pending_qty ? i.pending_qty : 0}</td>

                                                        <td className="text-end"> <input name="new_released_qty"
                                                            type="number"
                                                            value={this.state.cancelledItemList[k]?.new_released_qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'cancelledItemList')}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Released Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                            step=".01"
                                                        /></td>




                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    //***********************SEARCH allocation JSX****************
    allocationSearchModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="allocationSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Allocation Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="allocationSearchForm" onSubmit={this.submitAllocationSearchForm}>
                           {!this.state.allocationSearchFormDataLoaded ? <Loader/>
                            :  <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_transaction_id"
                                            type="text"
                                            value={this.state.allocationSearchForm.search_transaction_id}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "allocationSearchForm")}
                                            placeholder="Please enter Transaction ID"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    </div>
                                    <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Voucher Number</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_voucher_number"
                                            type="text"
                                            value={this.state.allocationSearchForm.search_voucher_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "allocationSearchForm")}
                                            placeholder="Please enter Voucher Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Warehouse</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.all_warehouses}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_warehouse_ids')}
                                            value={this.state.all_warehouses.filter(u => this.state.allocationSearchForm?.search_warehouse_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Warehouses"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Workstation</label>
                                    <div className="col-sm-10">
                                   {this.state.allWorkstations ? 
                                    <TapSelect
                                            options={this.state.allWorkstations}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_workstation_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allWorkstations.filter(s => this.state.allocationSearchForm.search_workstation_ids.includes(s.value))}
                                            placeholder="Select Workstations"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    : null}
                                  </div>
                                </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label">Employees</label>
                                        <div className="col-sm-10">
                                            <InputEmployeeSearch
                                                changeEvent={(selectedOption) => {
                                                
                                                    this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_employee_ids');
                                                    this.setState({
                                                         allocationSearchForm: { ...this.state.allocationSearchForm, search_employee_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                                        searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                    });
                                                }}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                placeholder="Select Employee"
                                                value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                            />
                                        </div>
                                    </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                        <div className="col-sm-10">
                                            <InputItemSearch
                                                placeholder="Search Item"
                                                isMulti={true}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_item_ids');
                                                    this.setState({
                                                        searchedItem: selectedOption ? selectedOption : null
                                                    }, () => { });
                                                }}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.allocationSearchForm.date_range}
                                            startDate={this.state.allocationSearchForm.date_range_start}
                                            endDate={this.state.allocationSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({allocationSearchForm
                                                    : {
                                                        ...this.state.allocationSearchForm, 
                                                        date_range: period, 
                                                        date_range_start: startDate ? startDate : null, 
                                                        date_range_end: endDate ? endDate : null
                                                      }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={this.allocationClear}>Clear</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

   
    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset - Allocation List</title></Helmet>
             {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.allocationListJsx()}
            {this.allocationSearchModalJsx()}
            {/* {this.fuelFeedInSearchModalJsx()} */}
            {this.releasedItemModalJsx()}
            <ItemDetailsModal ref={this.itemDetailsRef} />
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetAllocation);