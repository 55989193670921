import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';



class CustomerAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.addCustomerFormInitialState    =   { 
            name                                :   '',
            code                                :   '',
            notes                               :   '',
            state_id                            :   null,
            gstin                               :   '',
            address1                            :   '',
            label                               :   '',
            address2                            :   '',
            city                                :   '',
            landmark                            :   '',
            district_name                       :   '',
            pincode                             :   null,
            country_id                          :   99,
            authorization                       :   '',
            operations_state                    :   [],
            operations_district                 :   []
        }

        this.state                          =   {
            saveFormSubmitting                  :   false,
            addCustomerForm                     :   {...this.addCustomerFormInitialState},
            allStateList                        :   [],
            allCountryList                      :   [],
            allDistrictList                     :   [],
            allPincodeList                      :   [],
            allOperationsDistrictList           :   [],
            allOperationsStateList              :   [],
            customerFormDataLoaded              :   false,
            editCustomer                        :   false,
            contacts                            :   [{name:'',email:'',mobile:'',primary:'Y'}],
            addNewDistrict                      :   false,
            addNewPinCode                       :   false
        };

        this.customerURL        =   INVENTORY_API_BASE_URL_2 + '/customer';   
        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';

    }

    componentDidMount() {
        this.initilaizeFormData()
    }
    
    initilaizeFormData                  =   () =>  {
        this.setState({customerFormDataLoaded : true})
        const headers                         =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + this.props.access_token};
        Promise.all([
            axios({ method: 'GET', headers: headers, url: this.customerURL + '/add_form_data'}),
        ])
        .then((results) => {
            let formData = results[0].data;
            this.setState({
                // allStateList             :   results[1].data ? results[1].data.map((s) => { return {value: s.id, label: s.name}; }) : [],
               // allOperationsStateList    :   results[1].data ? results[1].data.map((s) => { return {value: s.id, label: s.name}; }) : [],
                allCountryList                  :       formData && formData.country ?  formData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],                 
         
            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.customerData) {
                     let customerData   =   this.props.location.state.customerData
                    this.setState({
                        addCustomerForm         :  {
                            ...this.addCustomerFormInitialState,
                            id                          :   customerData.id,
                            name                        :   customerData.name,
                            code                        :   customerData.code ? customerData.code : "",
                            notes                       :   customerData.notes ? customerData.notes : "",
                            authorization               :   customerData.addressList ? customerData.authorization : [],
                            operations_state            :   customerData.operations_state ? customerData.operations_state : [],
                            operations_district         :   customerData.operations_district ? customerData.operations_district : [],
                        },
                            contacts                    :   customerData.contacts ? customerData.contacts : this.state.contacts,
                            editCustomer                :   true
                        
                    }, () => {
                        this.stateChangeHandler(customerData.addressList[0].state_id)
                    });
                } else {
                    this.setState({ editCustomer: false })
                }

                if(this.state.addCustomerForm && this.state.addCustomerForm.country_id){
                    this.countryChangeHandler(this.state.addCustomerForm.country_id)
                }

            });
        }).then(() => { this.setState({ customerFormDataLoaded: false }) })
    }

    //FUNCTION TO GET STATE LIST ON SELECTING COUNTRY
    countryChangeHandler              =        (selected_country)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    allOperationsStateList      :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addCustomerForm             :    {...this.state.addCustomerForm,state_id: '',district_name : '',pincode:''},
                    addNewDistrict              :   false ,
                    addNewPinCode               :   false 
                });
            });
        }
        
    }

    //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
    stateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList             :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addCustomerForm             :    {...this.state.addCustomerForm,pincode:'',pincode_name:''}
                });
            });
        }
    }

    operationstateChangeHandler              =        (selected_state)    =>  {
        if (selected_state) {
            let params;
            if(selected_state.length > 0){
                let state_ids = selected_state.map(i => i.value)
                params = {state_ids : state_ids}
            }else{
                params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            }
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allOperationsDistrictList  :  response.data.map((s) => { return {value: s.id, label: s.name}; })
                });
            });
        }
    }

      //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
      districtChangeHandler              =        (selected_district)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            
            let params = {district_name : selectedDistrict,state_id:this.state.addCustomerForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList              :   response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                    addNewPinCode               :   response.data && response.data.length > 0 ? false : true,
                });
            });
        }
    }

    //****************************CLOSE Customer *********************/
    closeEvent              =           ()      =>      {
        if(this.state.editCustomer){
            this.props.history.push({pathname: `/customer_list`, state: {customerData : this.props.location.state.customerData }})
        }else{
            this.props.history.push({pathname: `/customer_list`})
        }
    }

    //submit add Customer Form
    addCustomerFormSubmitEvent            =       (e)      =>  {
       e.preventDefault()
       const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        let errors                  =   [];
        if(this.state.addCustomerForm.gstin && this.state.addCustomerForm.gstin.trim() != '' && !pattern.test(this.state.addCustomerForm.gstin.trim())) {
            errors                  =   [...errors, 'Please enter valid GST No.'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            toast.error(errorMsg, {position: toast.POSITION.TOP_RIGHT});
        } else {
            this.setState({ saveFormSubmitting: true })
            let frmData;
            if (this.state.editCustomer) {
                frmData = { ...this.state.addCustomerForm }
            } else {
                frmData = { ...this.state.addCustomerForm, contacts: this.state.contacts }
            }
            if (this.state.editCustomer) {
                HttpAPICall.withAthorization('PUT', this.customerURL, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/customer_list`, state: {customerData : this.props.location.state.customerData }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                HttpAPICall.withAthorization('POST', this.customerURL, this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/customer_list`})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }

    saveCustomerFormJsx                  =   ()  =>  {
        return (<form className="bg-white p-3" onSubmit={this.addCustomerFormSubmitEvent}>
            <div className="tab_content_wrapper">
                <span className="content_heading">Details</span>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Customer Name</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="name"
                        type="text"
                        className="form-control"
                        required={true}
                        value={this.state.addCustomerForm.name}
                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                        autoComplete="off"
                        maxLength="250"
                        placeholder="Enter Customer Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Code</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="code"
                        type="text"
                        className="form-control"
                        value={this.state.addCustomerForm.code}
                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                        autoComplete="off"
                        maxLength="250"
                        placeholder="Enter Customer Code"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Notes</label>
                </div>
                <div className="col-sm-4">
                    <textarea
                        name="notes"
                        className="form-control"
                        value={this.state.addCustomerForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                        autoComplete="off"
                        maxLength="250"
                        style={{ height: "60px" }}
                        placeholder="Enter Note"
                    />
                </div>
            </div>
            {this.state.editCustomer ? null
                : (<Ax> <div className="tab_content_wrapper">
                    <span className="content_heading">Address</span>
                </div>
                <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label require">Label</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="label"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.label}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Label"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 1</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="address1"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.address1}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 1"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Address Line 2</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="address2"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.address2}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Address Line 2"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Landmark</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="landmark"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.landmark}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter Landmark"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">City</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="city"
                                type="text"
                                className="form-control"
                                value={this.state.addCustomerForm.city}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                autoComplete="off"
                                maxLength="250"
                                placeholder="Please Enter City"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Country</label>
                        </div>
                        <div className="col-sm-4">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addCustomerForm', 'country_id');
                                    this.countryChangeHandler(selectedOption)
                                 }
                                }
                                options={this.state.allCountryList}
                                isSearchable={true}
                                isClearable={false}
                                value={this.state.allCountryList.find(m => m.value == this.state.addCustomerForm.country_id)}
                                placeholder="Please Select Country"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label require">State (Source of Supply)</label>
                        </div>
                        <div className="col-sm-4">
                            <TapSelect
                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                options={this.state.allStateList}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.allStateList.find(m => m.value == this.state.addCustomerForm.state_id)}
                                placeholder="Please Select State"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">District</label>
                        </div>
                        <div className="col-sm-4">
                            {this.state.addNewDistrict
                                ? <input
                                    name="district_name"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addCustomerForm.district_name}
                                    onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                    maxLength="20"
                                    placeholder="Please Enter District"
                                />
                                : <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                    options={this.state.allDistrictList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allDistrictList.find(m => m.value == this.state.addCustomerForm.district_name)}
                                    placeholder="Please Select District"
                                />}
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewDistrict"
                                type="checkbox"
                                value={this.state.addNewDistrict}
                                onChange={(e) => {
                                    this.setState({ 
                                        addNewDistrict: !this.state.addNewDistrict,
                                        addCustomerForm   :   {...this.state.addCustomerForm,district_id: '',pincode:''}
                                     })
                                }}
                                checked={this.state.addNewDistrict}
                                className="form-check-input"
                                id="newDistrict"

                            />
                            <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">PIN Code</label>
                        </div>
                        <div className="col-sm-4">
                            {this.state.addNewPinCode
                                ? <input
                                    name="pincode"
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addCustomerForm.pincode}
                                    onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                    maxLength="20"
                                    placeholder="Please Enter PinCode"
                                />
                                : <TapSelect
                                    changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addCustomerForm', 'pincode') }}
                                    options={this.state.allPincodeList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allPincodeList.find(m => m.value == this.state.addCustomerForm.pincode)}
                                    placeholder="Please Select PIN Code"
                                />}
                        </div>
                        <div className="col-sm-2">
                            <input
                                name="addNewPinCode"
                                type="checkbox"
                                value={this.state.addNewPinCode}
                                onChange={(e) => {
                                    this.setState({ 
                                        addNewPinCode: !this.state.addNewPinCode ,
                                        addCustomerForm   :   {...this.state.addCustomerForm,pincode:''}
                                    })
                                }}
                                checked={this.state.addNewPinCode}
                                className="form-check-input"
                                id="newPinCode"

                            />
                            <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">GSTIN</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="gstin"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addCustomerForm.gstin}
                                onChange={(e) => this.formInputHandler(e, 'addCustomerForm')}
                                maxLength="20"
                                placeholder="Please Enter GSTIN Number"
                            />
                        </div>
                    </div>
                    <div className="tab_content_wrapper">
                        <span className="content_heading">Primary Contact Details </span>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Contact Person Name</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="name"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.contacts[0].name}
                                onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                maxLength="200"
                                placeholder="Please Enter Person Name"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Email ID</label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="email"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                maxLength="200"
                                required={this.state.contacts[0].name !== "" ? true : false}
                                value={this.state.contacts[0].email}
                                onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                placeholder="Please Enter Person Email ID"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                        </div>
                        <div className="col-sm-4">
                            <input
                                name="mobile"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.contacts[0].mobile}
                                required={this.state.contacts[0].name !== "" ? true : false}
                                onChange={(e) => this.dynamicInputHandlerByKey(e, 0, 'contacts')}
                                placeholder="Please Enter Person Mobile Number"
                                pattern="\d*"
                                max="20"
                            />
                        </div>
                    </div>
                </Ax>
            )}

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
          <Helmet><title>Customer Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.props.location && this.props.location.state && this.props.location.state.customerData  ? "Edit" : "Add"} Customer</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {!this.state.customerFormDataLoaded ? this.saveCustomerFormJsx()
                        : <Loader/> }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(CustomerAdd);