import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import TapApiUrls from '../../../services/TapApiUrls';


class AssetActivityView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                        =             {
            activityId                    :            '',
            activityData                  :            null,
            counterlog                    :            null,
        }
    }

    componentDidMount() {
        if(this.props.activityId){
           this.intializeData(this.props)
        }
        if(this.props.counterlog){
            this.setState({counterlog : this.props.counterlog})
        }

        if(this.props.activityData) {
            this.setState({
                activityData    :   this.props.activityData,
                counterlog      :   this.props.activityData && this.props.activityData.counterlog ? this.props.activityData.counterlog : null
            })
         }  
    
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.activityId !== this.props.activityId) {
            this.intializeData(nextProps)
         } 
         if(this.props.counterlog !== nextProps.counterlog){
            this.setState({counterlog : nextProps.counterlog})
        }  
        if(nextProps.activityData !== this.props.activityData) {
            this.setState({
                activityData    :   nextProps.activityData,
                counterlog      :   nextProps.activityData && nextProps.activityData.counterlog ? nextProps.activityData.counterlog : null
            })
         }   
    }

    intializeData       =       (pr)      =>      {
        this.setState({activityId : pr.activityId,access_token : pr.access_token},() => {
            this.getActivityDetail(pr.activityId)  
        })
    }

   getActivityDetail                 =   (id)  =>  {
       this.setState({ viewDataLoading: true })
       HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/activity/' + id, this.state.access_token, {}, {}, (response) => {
           this.setState({
               activityData: response.data.data
           })
       }, (error) => {
           toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
       }).then(() => this.setState({ viewDataLoading: false }))
    }

    assetActivityViewJsx                  =       ()             =>        {
        let activityData = this.state.activityData;
      
        return (<div>
            {activityData ?
                <Ax>
                    <div className="tab_content_wrapper">  <span className="content_heading">Activity Details</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "34%" }}>Activity Date</td>
                                <th style={{ "width": "66%" }}>{activityData.activity_date_display ? activityData.activity_date_display.substr(0, activityData.activity_date_display.indexOf(' ')) : '-'}   </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Activity Type</td>
                                <th style={{ "width": "66%" }}>{activityData.activity_type_name ? activityData.activity_type_name : "-"}   </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>No. of Session or Trip</td>
                                <th style={{ "width": "66%" }}>{activityData.activity_count ? activityData.activity_count : "-"}   </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Activity Name</td>
                                <th style={{ "width": "66%" }}>{activityData.activity_name ? activityData.activity_name : "-"}   </th>
                            </tr>

                        </tbody>
                    </table>
                    {activityData && activityData.shift ?
                        <Ax>
                            <div className="tab_content_wrapper fw-bold my-2">Shift Details</div>
                            <table className="table my-2 table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Roster Name</td>
                                        <th style={{ "width": "66%" }}>{activityData.shift.roster_name ? activityData.shift.roster_name : ''} </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Shift Name</td>
                                        <th style={{ "width": "66%" }}>{activityData.shift.shift_name ? activityData.shift.shift_name : ''}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Shift Start Time</td>
                                        <th style={{ "width": "66%" }}>{activityData.shift.shift_start ? activityData.shift.shift_start : ''}  </th>
                                    </tr>

                                </tbody>
                            </table>
                        </Ax>
                        : null}
                    {this.state.counterlog ? <div className="tab_content_wrapper">  <span className="content_heading">Counterlog Details</span></div> : null}
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            {this.state.counterlog && this.state.counterlog.display && this.state.counterlog.display.length > 0
                                ? this.state.counterlog.display.map(counterlog => {
                                    return (<tr>
                                        <td style={{ "width": "34%" }}>{counterlog.label}</td>
                                        <th style={{ "width": "66%" }}>{counterlog.reading} {counterlog.unit}  </th>
                                    </tr>)
                                }) : (<tr>
                                    <td style={{ "width": "34%" }}>Counterlog</td>
                                    <th style={{ "width": "66%" }}>NA </th>
                                </tr>)}
                            <tr>
                                <td style={{ "width": "34%" }}>Site</td>
                                <th style={{ "width": "66%" }}>{this.state.counterlog && this.state.counterlog.site && this.state.counterlog.site.site_name ? this.state.counterlog.site.site_name : this.state.activityData.site && this.state.activityData.site.site_name ? this.state.activityData.site.site_name : "-"}  </th>
                            </tr>
                        </tbody>
                    </table>


                    <div className="tab_content_wrapper"> <span className="content_heading">Activity Measurement</span></div>
                    <table className="table my-2 table-hover table-bordered table-sm">
                        <thead className="table-secondary">
                            <tr className="text-center">
                                <th scope="col" style={{ width: "25%" }}>Activity Measurement Unit</th>
                                <th scope="col" style={{ width: "25%" }}>Starting Reading</th>
                                <th scope="col" style={{ width: "25%" }}>Stopping Reading	</th>
                                <th scope="col" style={{ width: "25%" }}>Total Running</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td className="text-start">Hour Meter</td>
                                <td>{activityData.start_hrm ? activityData.start_hrm : '-'}</td>
                                <td>{activityData.close_hrm ? activityData.close_hrm : '-'}</td>
                                <td>{activityData.running_hrm ? activityData.running_hrm : '-'}</td>
                            </tr>
                            <tr className="text-center">
                                <td className="text-start">Odometer</td>
                                <td>{activityData.start_km ? activityData.start_km : '-'}</td>
                                <td>{activityData.close_km ? activityData.close_km : '-'}</td>
                                <td>{activityData.running_km ? activityData.running_km : '-'}</td>
                            </tr>
                            <tr className="text-center">
                                <td className="text-start">Operation Cycle</td>
                                <td>{activityData.start_opc ? activityData.start_opc : '-'}</td>
                                <td>{activityData.close_opc ? activityData.close_opc : '-'}</td>
                                <td>{activityData.running_opc ? activityData.running_opc : '-'}</td>

                            </tr>
                            <tr className="text-center">
                                <td className="text-start">Time</td>
                                <td>{activityData.time_start ? activityData.time_start : "-"}</td>
                                <td>{activityData.time_close ? activityData.time_close : "-"}</td>
                                <td>{activityData.total_time ? activityData.total_time : "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Productivity</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "34%" }}>Net Productivity</td>
                                <th className="activityData-name" style={{ "width": "66%" }}>{activityData.productivity && activityData.productivity.production ?
                                    `${activityData.productivity.production} ${activityData.productivity.production_unit ? activityData.productivity.production_unit : ''}` : 'NA'} </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Material Name</td>
                                <th style={{ "width": "66%" }}>{activityData.material_name ? activityData.material_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Attachment</td>
                                <th style={{ "width": "66%" }}>{activityData.attachment_name ? activityData.attachment_name : "-"}</th>
                            </tr>
                            {activityData.productivity && activityData.productivity.additional_attribute_list && activityData.productivity.additional_attribute_list.length > 0 ?
                                        activityData.productivity.additional_attribute_list.map((attribute, index) => {
                                            return (<tr>
                                                <td style={{ width: "25%" }}>{attribute.name}</td>
                                                <th>{attribute.value_display ? attribute.value_display : "-"}</th>
                                            </tr>)
                                        })
                                        : null}
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Logistics / Movement</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "34%" }}>Site Name</td>
                                <th style={{ "width": "66%" }}>{activityData.site && activityData.site.site_name ? activityData.site.site_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>From Location (Workstation)</td>
                                <th style={{ "width": "66%" }}>{activityData.workstation_from_name ? activityData.workstation_from_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>To Location (Workstation)</td>
                                <th style={{ "width": "66%" }}>{activityData.workstation_to_name ? activityData.workstation_to_name : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Customer Name</td>
                                <th style={{ "width": "66%" }}>{activityData.customer ? activityData.customer : 'NA'}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Operator</td>
                                <th style={{ "width": "66%" }}>{activityData.operator_name ? activityData.operator_name : 'NA'} </th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Remarks</td>
                                <th style={{ "width": "66%" }}>{activityData.remarks_view ? activityData.remarks_view : "-"}</th>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Transaction Details</span></div>
                    <table className="table my-2 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ "width": "34%" }}>Transaction ID</td>
                                <th style={{ "width": "66%" }}>{activityData.asset_activity_id ? activityData.asset_activity_id : "-"}</th>
                            </tr>
                            <tr>
                                <td>Transaction Date</td>
                                <th>{activityData.activity_date_display ? activityData.activity_date_display.substr(0, activityData.activity_date_display.indexOf(' ')) : '-'}</th>
                            </tr>
                            <tr>
                                <td>Executed By</td>
                                <th>{activityData.user ? activityData.user.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td>Date of Entry</td>
                                <th>{activityData.created_at_display ? activityData.created_at_display : "-"}</th>
                            </tr>
                        </tbody>
                    </table>
                </Ax>
                : null}
        </div>)
    }


    render                              =   ()  =>  {

        return (<Ax>
            {this.state.viewDataLoading
                ? <Loader />
                : this.assetActivityViewJsx()
            }

        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(AssetActivityView);