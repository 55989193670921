import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import ItemTags from './ItemTags';
import AdditionalAttribute from '../../AdditionalAttribute';


class ItemConfiguration extends AppBaseComponent {


    render() {
        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link  active" id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Item Tags
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="tagging-tab" data-bs-toggle="tab" data-bs-target="#tagging"
                                type="button" role="tab" aria-controls="tagging" aria-selected="true">Additional Attribute
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                    
                        <div className="tab-pane show active" id="workflow" role="tabpanel"
                            aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <ItemTags />
                            </div>
                        </div>
                        <div className="tab-pane" id="tagging" role="tabpanel"
                            aria-labelledby="tagging-tab">
                            <div className="tab_content_header">
                               <AdditionalAttribute category="item"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(ItemConfiguration);