import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ItemViewInList from '../../inventory/includes/ItemViewInList';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import DateService from '../../../services/DateService';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';

class SalesOrderDetailModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                      =       {
           detail_loading            :       false,
           orderDetail                  :       null,
           orderTransactionID           :       null,
           
        };
        this.orderDetailUrl       =    INVENTORY_API_BASE_URL_2 + '/quotation/detail';
        this.itemDetailsRef             =   React.createRef(); 

    }

    componentDidMount() {
       
        if(this.props && this.props.orderID){
            this.getOrderDetail(this.props.orderID)
        }
        if(this.props && this.props.orderDetail){
            this.setState({orderDetail : this.props.orderDetail})
        }
        
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.orderID !== this.props.orderID){
            this.getOrderDetail(nextProps.orderID)
         }
         if(nextProps.orderDetail !== this.props.orderDetail){
             this.setState({orderDetail : nextProps.orderDetail})
         }
        
     }

    getOrderDetail        =       (transaction_id)      =>      {
        this.setState({detail_loading : true})
        HttpAPICall.withAthorization('GET', this.orderDetailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                orderDetail             :   respData,
                orderTransactionID      :   respData.transaction_id, 
            });
            
        }).then(() => this.setState({detail_loading : false}));      
    }
    
    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }
   
    formatAddress(address) {
        return <>
            {address.address1 ? address.address1 + ", " : ""}  {address.address2 ? address.address2 + ", " : ""}
            {address.landmark ? address.landmark + ", " : ""} {address.city ? address.city + ". " : ""}<br />
            Location : <b>{address.state_name ? address.state_name + ", " : ""}  {address.district_name ? address.district_name + "& " : ""}  {address.pincode ? address.pincode + "." : ""} <br /></b>
            GSTIN   :   <b>{address.gstin ? address.gstin : "-"}</b>
        </>
    }
    
    reqDetailJsx        =       ()      =>      {
        let order = this.state.orderDetail;
        let purchase_summary                    =   [];
        if (order && order.customerAddress && order.customerAddress.state_id == order.warehouse_state_id) {
             
            purchase_summary.push({ key: 'CGST ', value: "As applicable" });
            purchase_summary.push({ key: 'SGST ', value: "As applicable" });
        } else {
            purchase_summary.push({ key: 'IGST ', value: "As applicable" });
        }

        let sub_total_amount                =      order && order.items &&  order.items.length > 0 && order.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);

        let tcs_amount                          =  order &&  Number(order.tcs_rate_vc && order.tcs_rate_vc >= 0
            ? order && order.tcs_type == "Percentage" ? (sub_total_amount*order.tcs_rate_vc/100) :  Number(order.tcs_rate_vc)
            : 0);
        return (<Ax>

            {this.state.detail_loading ? <Loader />
                : <div className="modal-body ">
                    {this.state.orderDetail
                        ? <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{order.transaction_id}</td>
                                        <td scope="col">{DateService.dateTimeFormat(order.transaction_date, 'DD-MMM-YYYY')}</td>
                                        <td scope="col" className="align-middle">
                                            {order.status ? order.status.name : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Warehouse</td>
                                        <td colSpan={2}>{order.warehouse ? <div><b>{order.warehouse.name} ({order.warehouse.code})</b> <br />
                                            {order.warehouse_address ?
                                                <div className='pt-1'>Address : {order.warehouse_address.address1 ? order.warehouse_address.address1 + ", " : ""}
                                                    {order.warehouse_address.address2 ? order.warehouse_address.address2 + ", " : ""}
                                                    {order.warehouse_address.landmark ? order.warehouse_address.landmark + ", " : ""}
                                                    {order.warehouse_address.city ? order.warehouse_address.city + ". " : "-"} <br />
                                                    Location : <b>{order.warehouse_address.state_name}</b> <br />
                                                    GSTIN  : {order.warehouse_address.gstin ? order.warehouse_address.gstin : "-"} <br />
                                                </div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} >Customer</td>
                                        <td colSpan={2} >{order.customer ? <div>{order.customer.name} ({order.customer.code}) <br />
                                           {(order.customerAddress || order.customerProject) &&
                                            <div className='py-1'>
                                                Project : {order.customerProject ? <span><b>{order.customerProject.project_name} ({order.customerProject.project_code}) </b></span> : "-"} <br/>
                                                Address :  {order.customerProject ? this.formatAddress(order.customerProject ) : this.formatAddress(order.customerAddress)} </div> }
                                            
                                            </div>: "-"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td scope="col">Priority & Validity Date</td>
                                        <td style={{ width: "33%" }} className="text-capitalize">{order.priority ?? "-"}</td>
                                        <td style={{ width: "34%" }}>{DateService.dateTimeFormat(order.validity_date, 'DD-MMM-YYYYY') ?? "-"}</td>

                                    </tr>
                                    <tr>
                                        <td scope="col">Deal</td>
                                        <td scope="col" colSpan={2}>{order && order.deal_data  ? order.deal_data.header : ""}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Tag Users</td>
                                        <td scope="col" colSpan={2}>{order && order.users && order.users.length > 0 ? order.users.map(u => <div>{u.full_name}</div>) : ""}</td>
                                    </tr>
                                    {order.quote_enable_authorized_signatory && order.quote_enable_authorized_signatory == 'Y' && <tr>
                                        <td scope="col">Authorized Signatory</td>
                                        <td style={{ width: "33%" }} className="text-capitalize" colSpan={2}>{order.authorized_signatory && order.authorized_signatory.full_name ? order.authorized_signatory.full_name : "-"}</td>

                                    </tr>}
                                    <tr>
                                        <td scope="col">Currency</td>
                                        <td colSpan={2}><b>{order.currency}</b> {order.currency !== order.base_currency ? <span>(1 {order?.currency} : <NumberFormatter number={order?.conversion_rate} precision={2} /> {order?.base_currency})</span> : null}</td>

                                    </tr>
                                    {order.additional_attribute_data && order.additional_attribute_data.length > 0
                                        ? order.additional_attribute_data.map((attr) => {
                                            return (<tr key={attr.key}>
                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                <td scope="col" colSpan={2}>{attr.value_display ? attr.value_display : "-"}</td>
                                            </tr>)
                                        }) : null}
                                </tbody>
                            </table>

                            <table className="table table-bordered table-responsive bg-white my-3 table-sm" id="poTable">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ "width": '5%' }} className="text-center">
                                            <input type='checkbox' onChange={this.props.checkAllItem} id="all_check_order" />
                                        </th>
                                        <th style={{ width: "39%" }}>Item Details</th>
                                        <th style={{ width: "12%" }} className="text-end">Quantity</th>
                                        <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                        <th style={{ width: "8%" }} className="text-end">Discount</th>
                                        <th style={{ width: "8%" }} className="text-end">Tax</th>
                                        <th style={{ width: "8%" }} className="text-end">Amount</th>
                                        {/* <th style={{ width: "8%" }} className="text-center">Pending</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order && order.items && order.items.length > 0 ? order.items?.map((i, k) => {
                                            return (<tr>
                                                <td className='text-center'>
                                                    {k + 1}
                                                    <br /><br />
                                                    <input
                                                        type='checkbox'
                                                        name="foo"
                                                        value={i?.item_profile?.id}
                                                        className="order_item_id_checkbox"
                                                        data-itemData={i?.item_profile}
                                                    />

                                                </td>

                                                <td>{i.item_profile ?
                                                    <div className='row'>
                                                        <div className='col-sm-10'>
                                                            <ItemViewInList view_hsn={true} item={i.item_profile} remark={i.remark} due_date={i.due_date_display} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                                        </div>

                                                    </div>
                                                    : null}</td>

                                                <td className='text-end'>{i.qty}
                                                    {i.item_profile.measuring_unit && i.item_profile.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item_profile?.measuring_unit?.name}</span> : null}

                                                </td>
                                                <td className='text-end'>{i.rate_vc}</td>
                                                <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? order.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : 0}</td>
                                                <td className='text-end'>{order  && order.warehouse_address && order.warehouse_address.gstin ? <div>{i.gst} %</div> : "NA"}
                                                    {order && order.warehouse_address && order.warehouse_address.gstin && i.item ? <span className="text-helping input-group-text text-end pr0">{order.customerAddress && order.customerAddress.state_name == order.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                                </td>
                                                <td className='text-end'>
                                                    <CurrencyFormatter currencyCode={order.currency} amount={parseFloat(i.item_with_discount).toFixed(2)} />
                                                </td>
                                            </tr>)

                                        }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="row align-items-center mb-5">
                                <div className="col-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{order.notes ?? "-"}</div>

                                </div>
                                <div className="col-6">
                                    <table className="table  table-borderless">
                                        <tbody>
                                            <tr className="subtotal_bg_color">
                                                <th>Sub-Total ({order.currency ? order.currency : "INR"})</th>
                                                <td className='float-end'>{order && order.items && order.items.length > 0 && <CurrencyFormatter currencyCode={order.currency} amount={order.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />}</td>
                                                {/* <td className='float-end'>{order.total_amount_vc ? (parseFloat(order.total_amount_vc) - (parseFloat(order.adjustment_amount_vc ?? 0.0) + parseFloat(order.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                            </tr>
                                            {order && order.tcs_rate_vc && order.tcs_rate_vc > 0 ? <tr className="subtotal_bg_color">
                                                <th>TCS ({order.tcs_type == "Fixed" ? order.currency : null} {order.tcs_rate_vc} {order.tcs_type == "Percentage" ? "%" : null} )</th>
                                                <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>
                                                {/* <td className='float-end'>{order.total_amount_vc ? (parseFloat(order.total_amount_vc) - (parseFloat(order.adjustment_amount_vc ?? 0.0) + parseFloat(order.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                            </tr> : null}
                                            {order.tax_slabs && order.tax_slabs.length > 0
                                                ? (order.tax_slabs.map((ps, k) => {
                                                    return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                }))
                                                : null
                                            }

                                            <tr className="subtotal_bg_color">
                                                <th>Adjustment</th>
                                                <td className='float-end'>{order.adjustment_amount_vc ?? 0}</td>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Final Total ({order.currency ? order.currency : "INR"})</th>
                                                <td className='float-end'>{order.total_amount_vc ? <CurrencyFormatter currencyCode={order.currency} amount={order.total_amount_vc} /> : 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab_content_wrapper content_heading">Terms & Condition</div>
                            <table className="table table-hover table-bordered bg-white my-3">
                                <thead className='table-secondary'>
                                    <tr>
                                        <th className='text-center' style={{ width: "10%" }}>S.No</th>
                                        <th className='text-start' style={{ width: "25%" }}>Name</th>
                                        <th className='text-start' style={{ width: "65%" }}>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order.tnc && order.tnc.length > 0 ? order.tnc.map((tc, k) => {
                                            return (<tr key={k}>
                                                <td className='text-center'>{k + 1}</td>
                                                <td>{tc.name}</td>
                                                <td>{tc.text}</td>
                                            </tr>);
                                        })
                                            : <tr ><td className='text-center' colSpan={3}>No Record</td></tr>
                                    }

                                </tbody>
                            </table>

                            <hr />

                        </div>
                        : null}
                </div>}
                <ItemDetailsModal ref={this.itemDetailsRef}  /> 
        </Ax>)
    }
   

    render() {
        return (<div>{this.reqDetailJsx()}</div>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SalesOrderDetailModal);