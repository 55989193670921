import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';

class WorkstationList extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.workstationSearchInitState         =   {
            'search_name'                 :   '',
            'search_site_ids'               :   [],
        };

        this.workstationSearchFormLabel         =   {
            'search_name'             :   'Workstation Name : ',
            'search_site_ids'            :   'Site : ',
        };

        this.state                      =     {
            minimizeTable                   :     false,
            listing_tbl_page                :     1,
            listing_loading                 :     false,
            totalListingCount               :     0,
            listingMeta                     :     null,
           workstation_listing              :     [],
           iam_user_sites                   :       [],
           workstationSearchForm             :     {...this.workstationSearchInitState},
           submittedWorkstationSearchForm   :     {...this.workstationSearchInitState},
           formSearchedElems                :     [],
           worksationDetail                 :       null,
           actionPerformed                  :       false
           
        };
        this.workstationUrl         =   IAM_API_BASE_URL_2 + '/workstation/list';
        this.workstationDetailUrl   =   IAM_API_BASE_URL_2 + '/workstation/detail';
        this.workstationStatusUrl   =   IAM_API_BASE_URL_2 + '/workstation/status';
         
    }
    
    
    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.workstationId) {
            this.loadListingTblData(1,this.props.location.state.workstationId)
            this.setState({minimizeTable :true})
        }else{
            this.loadListingTblData(1)
        }
      
       this.initalizeFormData(this.props)
       this.workstationSearchModal      =   new Modal(document.getElementById('workstationSearchModal'), {keyboard: false, backdrop: false});
     }

     componentWillReceiveProps(nextProps){

        if (nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initalizeFormData(nextProps)
        }
             
    }

    initalizeFormData       =       (pr)        =>      {
       
        this.setState({
            iam_user_sites          :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [], 
        })
      
    }

    workstationSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.workstationSearchForm);
        let searchedElems               =   [];
        let WorkstationSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.workstationSearchFormLabel[key];
            let value                       =   this.state.workstationSearchForm[key];
            if(value && value.toString().length > 0) {
                WorkstationSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_name') {
                        show_val            =   this.state.workstationSearchForm.search_name;
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedWorkstationSearchForm         :   {...this.state.workstationSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.workstationSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    loadListingTblData                 =   (page = 1,id=null)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page,per_page:50,view_loading  :true});
        let params                      =   {page:page,need_site:'Y',...this.state.submittedWorkstationSearchForm};
        HttpAPICall.withAthorization('GET', this.workstationUrl, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                workstation_listing        :           respData.data,
                worksationDetail            :           respData.data && respData.data.length > 0 && id ? respData.data.find(wt => wt.id == id) : null,
                
                    listingMeta         :           respData.meta,
                
                totalListingCount       :           respData.total,
        });
        }).then(() => this.setState({listing_loading: false,view_loading  :false}));
    }


    workstationSearchModalInit     =       ()      =>      {
        this.workstationSearchModal.show()
    }

    workstationSearchClear         =       ()     =>      {
        this.setState({
            workstationSearchForm            :   {...this.workstationSearchInitState},
            submittedWorkstationSearchForm   :   {...this.workstationSearchInitState},
            formSearchedElems             :   [],
        }, () => {
            this.workstationSearchModal.hide();
            this.loadListingTblData(1);
        });
    }

    addWorkstationInit          =   ()          =>      {
        this.props.history.push({ pathname: `/workstation_add` })
    }

    viewWorkstation     =       (detail)        =>      {
       this.setState({minimizeTable :true, actionPerformed     :       false,view_loading:true});
       setTimeout(() => {
        this.setState({view_loading : false,worksationDetail : detail})
       }, 1000);
   
    }

    getWorkstationDetail    =   (id)    =>  {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET', this.workstationDetailUrl, this.props.access_token, {id:id}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                worksationDetail        :       respData.data,
               
            })
           
        },(err) => {
           if(err){
               this.setState({minimizeTable : false})
           }
       }).then(() => this.setState({view_loading: false}));
    }
    
    
    closeViewDetails       =    ()      =>      {
        this.setState({minimizeTable :false})
        if(this.state.actionPerformed){
            this.loadListingTblData(this.state.listing_tbl_page)
        }
    }

    changeStatus        =   (id,status)      =>      {
      
        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', this.workstationStatusUrl, this.props.access_token, {} ,{id:id,status:status == 1  ? 2 : 1},(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.loadListingTblData(this.state.listing_tbl_page,id)
                        this.setState({actionPerformed : true})
                    }).then(() => {

                    });
                }
            });
    }

    editWorkstaion      =       ()      =>      {
        this.props.history.push({pathname: `/workstation_add`, state: {workstationData : this.state.worksationDetail }})
    }


     //***********************fuelStation JSX****************

    workstationListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Workstation</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>  <button type="button" className="btn btn-primary" disabled={!this.props.permissions.includes('iam-workstation-add') ? true : false} onClick={() => this.addWorkstationInit()}>Add WorkStation </button>

                        </Ax>
                        : null
                    }
                    <button type="button" disabled={this.state.listing_loading} onClick={this.workstationSearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable ? <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>   <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-workstation-add')) {
                                        return this.addWorkstationInit()
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-workstation-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-workstation-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Add</a></li>

                        </ul>

                    </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Workstation : {this.state.worksationDetail ? this.state.worksationDetail.name : "-"}</h3>
                            <div className="text-end mt15">
                                <span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li>   <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('iam-workstation-edit')) {
                                                    return this.editWorkstaion()
                                                }
                                            }}
                                            className={['dropdown-item', this.props.permissions.includes('iam-workstation-edit') ? '' : 'disabled'].join(' ')}
                                            title={!this.props.permissions.includes('iam-workstation-edit') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Edit</a></li>

                                        <li><a className="dropdown-item" href="#" onClick={() => this.changeStatus(this.state.worksationDetail?.id, this.state.worksationDetail?.status)}>{this.state.worksationDetail && this.state.worksationDetail.status == 1 ? "Deactivate" : "Activate"}</a></li>
                                    </ul>

                                </span>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }


        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.workstationListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.workstaionViewDetails()}</div></div> : null}

                </div>
            </div>
        </Ax>);
    }

    workstationListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" >
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.workstationSearchClear}   /> 
         
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Workstation</th>
                        </tr>)
                        : (
                            <tr>
                                <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                                <th scope="col" style={{ width: "20%" }}>WorkStation Name</th>
                                <th scope="col" style={{ width: "20%" }}>Code</th>
                                <th scope="col" style={{ width: "20%" }}>Linked Sites</th>
                                <th scope="col" style={{ width: "15%" }}>Status</th>
                                <th scope="col" style={{ width: "8%" }}  className='text-center'>Action</th>

                            </tr>)}
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.workstation_listing.length > 0
                            ? (this.state.workstation_listing.map((workstation, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewWorkstation(workstation)}>{workstation.name ? workstation.name : "-"} </a>
                                                        <div className="mt-1">
                                                            <small> <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>{workstation.code ? workstation.code : "-"}</span> </small>
                                                            <small className="float-end"><Status color={workstation.status_color}>{workstation.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewWorkstation(workstation)}>{workstation.name ? workstation.name : "-"}</td>
                                                <td className='text-capitalize'>{workstation.code ? workstation.code : "-"}</td>
                                                <td>{workstation.site ? workstation.site.site_name : "-"}</td>
                                                <td><Status color={workstation.status_color}>{workstation.status_text}</Status></td>
                                                <td className="text-center">
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewWorkstation(workstation)}>View</a></li>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.changeStatus(workstation.id,workstation.status)}>{workstation &&workstation.status == 1 ? "Deactivate" : "Activate"}</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>)}

                                </tr>)
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>

            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
           
        </div>)
    }

    workstaionViewDetails       =       ()      =>      {
        let detail = this.state.worksationDetail
        return(<div className="tab_content_header">
            {detail
            ?  <table className="table table-hover table-bordered  table-responsive bg-white my-2">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }} >WorkStation Name</td>
                    <th>{detail.name ? detail.name : "-"}</th>
                    
                </tr>
                <tr>
                    <td style={{ width: "26%" }} >WorkStation Code</td>
                    <th>{detail.code ? detail.code : "-"}</th>
                    
                </tr>
                <tr>
                    <td style={{ width: "26%" }} >Linked Site</td>
                    <th>{detail.site ? detail.site.site_name : "-"}</th>
                    
                </tr>
                <tr>
                    <td style={{ width: "26%" }} >Remarks</td>
                    <th>{detail.remarks ? detail.remarks : "-"}</th>
                    
                </tr>
                <tr>
                    <td style={{ width: "26%" }} >Status</td>
                    <th>{detail.status ? <Status color={detail.status_color}>{detail.status_text}</Status> : "-"}</th>
                    
                </tr>
              
            </tbody>
        </table>
         : null}
        </div>)
    }
   
    workstationSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="workstationSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="WorkstationSearchModalLabel">Workstation Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.workstationSearchFormSubmit} id="WorkstationSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Workstation Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="search_name"
                                            className="form-control form-control-sm"
                                            placeholder="Enter Workstation Name"
                                            autoComplete="off"
                                            value={this.state.workstationSearchForm.search_name}
                                            onChange={(e) => this.formInputHandler(e, "workstationSearchForm")}
                                        />
                                    </div>

                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Sites</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'workstationSearchForm', 'search_site_ids');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={this.state.iam_user_sites.filter(s => this.state.workstationSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Sites"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                        />
                                    </div>

                                </div>
                               

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

     
    render                              =   ()  =>  {
    
        return (<ApplicationLayout>
        <Helmet><title>Workstation Listing</title></Helmet>
             {this.workstationListjsx()} 
             {this.workstationSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(WorkstationList);