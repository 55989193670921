import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import { DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import { Link } from 'react-router-dom';


class AssetWiseScsReport  extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.cancelToken        =   null;
        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/asset_wise_scs_status_count';
        this.formDataUrl        =   IAM_API_BASE_URL_2 + '/report/asset-status/form_data';

        this.reportFilterFormInitialState   =   {
            site_group_ids                          :       [],
            search_site_ids                         :   [],
            'asset_group_ids'                       :    [],
            search_asset_type_ids                   :   [],
            search_asset_matrix_ids                 :   [],
            search_manufacturer_ids                 :   [],
            search_models                           :   [],
            search_asset_ids                        :   [],
            search_asset_category                   :   'Y',
            search_status                           :   [],
        };
        
        this.reportFilterFormLabel          =   {
            site_group_ids                          :   'Site Groups : ',
            search_site_ids                         :   'Sites : ',
            'asset_group_ids'                       :   'Asset Type Groups : ',
            search_asset_type_ids                   :   'Asset Type : ',
            search_asset_matrix_ids                 :   'Asset Matrix : ',
            search_manufacturer_ids                 :   'Manufacturer : ',
            search_models                           :   'Models : ',
            search_asset_ids                        :   'Assets : ',
            search_asset_category                   :   'Asset Category : ',
            search_status                           :   'Asset Status : ',
        };
         
        
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null,
            iam_asset_types                         :   [],
            iam_user_sites                          :   [],
            iam_asset_matrix                        :   [],
            iam_manufactures                        :   [],
            iam_models                              :   [],
            iam_asset_category                      :   [],
            formDataLoaded                          :   false,
            filterFormData                          :   null,
            searchedAssets                          :   [],
            iam_status_list                         :   [],
            allSiteGroupsList                       :   [],
            allAssetTypesGroupsList                 :   [],
            reportData                          :   null,                  
      };

    }
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
    }
    
    // componentWillReceiveProps(nextProps){
    //     if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
    //         this.initilaizeAppDataToFilterForm(nextProps);
    //     }
    // }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {
         const report_name   =   'asset_wise_scs_report';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            listing_loading         :   true,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
            iam_manufactures        :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
            iam_models              :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
            iam_status_list         :   props.status_list && props.status_list.length > 0 ? props.status_list.map((s) => { return {value: s.key, label: s.name}}) : [],
            allAssetTypesGroupsList  :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        },() => {this.filterFormDataInit()});
    }
    
    filterFormDataInit                      =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
            let respData = response.data;
            this.setState({
                formDataLoaded              :   true,
                iam_asset_category          :   response.data.asset_category.map(ac => { return {value: ac.key, label: ac.name}}),
                allSiteGroupsList         :       respData && respData.site_groups && respData.site_groups.length > 0 ? respData.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            });
            
            
            this.reportFilterFormSubmit();
        }, (err) => { });
    }
    
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.iam_asset_category.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.iam_status_list.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1,reload=false)    =>  {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
       
        let params                      =   {page:page,get_asset_profile : "Y",...this.state.submittedReportFilterForm,reload : reload ? 'reload' : false };
        
        if (this.cancelToken) {
            this.cancelToken.cancel("Operation canceled due to new request.")
        }
        this.cancelToken                =   axios.CancelToken.source();
        axios({
            method          :   'GET',
            headers         :   { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params          :   params,
            url             :   this.reportUrl,
            cancelToken     :   this.cancelToken.token
        }).then((response) => {
            let respData                =   response.data.data;

            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount               :   respData.total,
                time                            :   response.data.time ? response.data.time :''
            },() => {
                this.setState({listing_loading: false});
            });
           
        }).catch((err) => {
            if (axios.isCancel(err)) {
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        }).then(() => {
            // this.setState({listing_loading: false});
        });
    }

    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   ()  =>  {
        new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Asset Wise SCS Report</h3>
                <div className="text-end mt15">

                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button disabled={this.state.listing_loading || this.state.reportDownloading} type="button" onClick={() => this.loadListingTblData(1, true)} className="btn btn-secondary"><tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} />{this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  => {
        return (<div id="reportFilterForm" className="bg-white collapse " aria-expanded="true">
             <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_matrix_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.reportFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="top"
                            value={this.state.iam_manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            menuPlacement="top"
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_category}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_category')}
                            value={this.state.iam_asset_category.find(r => r.value ===  this.state.reportFilterForm.search_asset_category  )}
                            placeholder="ALL"
                            menuPlacement="top"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_status_list}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_status_list.filter(s => this.state.reportFilterForm.search_status.includes(s.value))}
                            placeholder="Select Status"
                            menuPlacement="top"
                        />
                    </div>
                </div>
                
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr/>
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        
        return (<div className="bg-white">
           
          
               {this.reportTableJsx()}
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered bg-white align-items-center  table-sm table-hover" id="rowDataReportTable">
                <thead>
                    <tr>
                        <th scope="col" style={{ width: "17%" }}>Asset Name</th>
                        <th scope="col" style={{ width: "17%" }}>Site Name</th>
                        <th scope="col" style={{ width: "20%" }}>Asset Type</th>
                        <th scope="col" style={{ width: "9%" }} className='text-center'>Overdue</th>
                        <th scope="col" style={{ width: "9%" }} className='text-center'>Due Soon</th>
                        <th scope="col" style={{ width: "9%" }} className='text-center'>Within Limits</th>
                        <th scope="col" style={{ width: "9%" }} className='text-center'>Being Tracked</th>
                        <th scope="col" style={{ width: "9%" }} className='text-center'>Not Yet Activated</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading ? (<tr><td className="text-center" colSpan={9}><Loader /></td></tr>)
                        : this.state.listingData && this.state.listingData.length > 0
                            ? (<Ax> {this.state.listingData.map((rowData, k) => {

                                return (
                                    <tr key={k}>
                                        <td>{rowData.name ? rowData.name : ""}</td>
                                        <td>{rowData.site ? rowData.site : ""}</td>
                                        <td>{rowData.asset_type ? rowData.asset_type : ""}</td>
                                        <td className='text-center'>
                                            <Link target="_blank" to={{ pathname: "/report/service_checksheet_running_status", search: "?" + new URLSearchParams({ scs_status: 'over_due', search_asset_ids: rowData.asset_id, asset_name: rowData.name }).toString() }}>
                                                {rowData.over_due ? rowData.over_due : "0"}
                                            </Link>
                                        </td>
                                        <td className='text-center'>
                                            <Link target="_blank" to={{ pathname: "/report/service_checksheet_running_status", search: "?" + new URLSearchParams({ scs_status: 'due_soon', search_asset_ids: rowData.asset_id, asset_name: rowData.name }).toString() }}>
                                                {rowData.due_soon ? rowData.due_soon : "0"}
                                            </Link>
                                        </td>
                                        <td className='text-center'>
                                            <Link target="_blank" to={{ pathname: "/report/service_checksheet_running_status", search: "?" + new URLSearchParams({ scs_status: 'in_limit', search_asset_ids: rowData.asset_id, asset_name: rowData.name }).toString() }}>
                                                {rowData.in_limit ? rowData.in_limit : "0"}
                                            </Link>

                                        </td>
                                        <td className='text-center'>
                                           
                                            {rowData.being_tracked ? rowData.being_tracked : "0"}</td>
                                        <td className='text-center'>{rowData.not_active_scs ? rowData.not_active_scs : "0"}</td>
                                    </tr>)
                            })
                            }</Ax>)
                            : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet>Asset Wise SCS  - Report</Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {!this.state.listing_loading && this.state.time && <div style={{backgroundColor:"white"}} className='px-3 py-1 text-end'>
                                    <small className='form-text '>Report is based on Service Checksheet Data at ( {this.state.time} )</small>
                                </div>}
                        {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} /> 
                                {this.reportListingDisplayJsx()}
                            
                        <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                    </div>
                </div>
            </div>
           
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        status_list             :   state.app && state.app.status_list ? state.app.status_list : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetWiseScsReport);