import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import ApplicationLayout from "../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Loader from '../../components/ui/Loader/Loader';
import Ax from '../../components/hoc/Ax';
import reportStyles from "./Report.module.css"
import HttpAPICall from '../../services/HttpAPICall';
import * as actions from "../../store/actions/index"
import ReportMenu from './includes/ReportMenu';
import ReportHeader from './includes/ReportHeader';


const ReportList     = forwardRef((props, ref) => {


    const dispatch                          =   useDispatch();
    const access_token                      =   useSelector(state => state.auth.access_token);
    const report_listing                    =   useSelector(state => state.app.report_listing ? state.app.report_listing : []);

    let report_url                          =   ORG_API_BASE_URL_2 + '/reports';


    const [loading, setLoading]             =   useState(false);
    const [reports, setReport]              =   useState([]);


    const getReportListing                  =   ()  => {
        if(report_listing && report_listing.length > 0) {
            generateReports(report_listing);
        } else {
            setLoading(true);
            HttpAPICall.withAthorization('GET', report_url, access_token, {}, {}, (resp) => {
                generateReports(resp.data);
                dispatch(actions.reportListingInitilization());
                dispatch(actions.reportListingDataLoadSuccessfully(resp.data));
            }).then(() => {
                setLoading(0);
            });
        }
    }


    const generateReports                   =   (report_listing)  =>  {
        let sectionReports                      =   [];
        report_listing.filter(r => r.view_access === 'Y').forEach(r => {
            if(!sectionReports[r.section]) {
                sectionReports[r.section]       =   [];
            }
            sectionReports[r.section].push(r);
        });
        setReport(pd => sectionReports);
    }


    useEffect(() => {
        getReportListing();
    }, []);

    return  <ApplicationLayout>
        <ReportHeader title="Report Home" name="Reports" />
        <div className="container-fluid">
            <div className='row mt-1 px5'>
                <div className='col-sm-12 bg-white px-0'>
                    <ReportMenu page="report" />
                    <div className="tab_content_header">
                        {loading ? <Loader />
                        : Object.entries(reports).map((rs, rsi) => {
                            return <Ax key={rsi}>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <div className="tab_content_wrapper content_heading">{rs[0]}</div>
                                    </div>
                                </div>
                                <div className="row my-2">
                                    {rs[1].map((r, i) => <div className="col-sm-4" key={i}>
                                        <div className={[reportStyles.card_container, 'card mb-3'].join(' ')}>
                                            <div className={[reportStyles.card_body, 'card-body'].join(' ')}>
                                                <div className="row">
                                                    <Link className={[reportStyles.card_heading, 'lh-base', 'col-sm-12'].join(' ')}
                                                        to={{ pathname: "/report/" + r.key }}>
                                                        {r.name}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </Ax>
                        })}
                    </div>
                </div>
            </div>
        </div>
    </ApplicationLayout>

});

export default ReportList;