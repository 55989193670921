import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import * as actions from '../../../store/actions/index'
import Status from '../../../components/ui/Status';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import ChangeInvoiceRule from './ChangeInvoiceRule';


 class InvoiceAssetLinking extends AppBaseComponent {

    constructor() {
        super();

        
        this.state = {
            listing_loading                  :          false,
            listing_tbl_page                 :          1,
            listingMeta                      :          null,
            asset_invoice_listing            :          [],
            totalListingCount                :          0,
        }

        this.linkAssetRef                   =           React.createRef();
    }

    componentDidMount(){  
        this.loadListingTblData(1)
    }


     //************************GET consumption LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
         this.setState({listing_loading: true, listing_tbl_page: page});
         let params                      =   {page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/invoice_rule_asset_wise', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;

            this.setState({
                asset_invoice_listing          :       respData.data,
                listingMeta                    :       respData.meta,
                totalListingCount              :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
       
    }

    linkInvoiceRuleInit         =       (asset_id,invoice_rule)        =>      {
        this.linkAssetRef.current.modalInit(asset_id,invoice_rule);
    }

    invoiceAssetListJsx            =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={"col-12"}>
                <h3>Asset Invoice Rule Linking</h3>
                <div className="text-end mt15">
                
                </div>
            </div>
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', 'col-12'].join(' ')} >{this.invoiceAssetTableJsx()}</div>
                  
                </div>
            </div>
        </Ax>);
    }

    removeLink              =       (asset_id)      =>      {
        swal({
            title: "Delink Invoice Rule",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/invoice_rule_unlink/asset', this.props.access_token, {asset_id : asset_id},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.loadListingTblData(this.state.listing_tbl_page)
                    }).then(() => {

                    });
                }
            });
    }

    gotoAssetProfile            =      (asset_id)   =>  {
        this.props.history.push(`/asset/dashboard/${asset_id}`)
    }

    invoiceAssetTableJsx            =   ()   =>   {
      
        return (<Ax>
            <table className="table table-bordered bg-white ">
                <thead className="table-secondary">

                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                        <th scope="col" style={{ width: "17%" }} className="text-start">Asset Name</th>
                        <th scope="col" style={{ width: "16%" }} className="text-start">Asset Code</th>
                        <th scope="col" style={{ width: "16%" }}>Asset Type</th>
                        <th scope="col" style={{ width: "16%" }}>Site</th>
                        <th scope="col" style={{ width: "25%" }}>Linked Invoice Rule</th>
                        <th scope="col" style={{ width: "5%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.asset_invoice_listing.length > 0
                            ? (this.state.asset_invoice_listing.map((item, index) => {
                                return (<tr key={index} >

                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td>{item.name ? item.name : "-"}</td>
                                    <td>{item.asset_code ? item.asset_code : "-"}</td>
                                    <td>{item.asset_type_name ? item.asset_type_name : "-"}</td>
                                    <td>{item.site ? <span>{item.site} ({item.site_code})</span> : "-"}</td>
                                    <td className='text-center'>{item.invoice_rule ? <div>{item.invoice_rule.name} ({item.invoice_rule.rule_number}) <br/> <span>{'\u20B9'} {item.invoice_rule.rate}</span> / {item.invoice_rule.days} Days</div>  : `NA` }</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => this.linkInvoiceRuleInit(item.asset_id,item.invoice_rule ? item.invoice_rule : null)}>Change Invoice Rule</a></li>
                                            <li><a className={item.invoice_rule == null ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={item.invoice_rule == null ? true : false} onClick={() => this.removeLink(item.asset_id,item.invoice_rule ? item.invoice_rule.id : null)}>Remove Invoice Rule</a></li>
                                            <li><a className={item.can_access == false ? "dropdown-item disabled" : "dropdown-item"} role="button" onClick={() => this.gotoAssetProfile(item.asset_id)}>View Asset Details</a></li>
                                        </ul>
                                    </td>

                                </tr>)
                            }))
                            : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    render() {
       
        return (
            <ApplicationLayout>
                 <Helmet><title>Asset Invoice Rule Linking</title></Helmet>
                {this.invoiceAssetListJsx()}
                <ChangeInvoiceRule ref={this.linkAssetRef} getAssetInvoiceListing={() => this.loadListingTblData(this.state.listing_tbl_page)}/>
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(InvoiceAssetLinking);

