import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import HttpAPICall from '../../../services/HttpAPICall';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import Ax from '../../../components/hoc/Ax';
import ReactSelectStyle from '../../../components/ui/ReactSelectStyle';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class InputWorkstationSearch extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                  =   {
            selectedWorkstation         :   null,
            timer                       :   false,
            defaultOptions              :   [],
            
        };
        this.cancelToken            =   null;
        this.apiTimeout             =   null;
    }
    
    initParams                          =   (props)  =>  {
        this.setState({
            params                      :   {
                search_site_ids                 :   props.search_site_id && Array.isArray(props.search_site_id) ? props.search_site_id : (props.search_site_id ? [props.search_site_id] : ''),
             }
        }, () => {
            
        });
    }
    
    componentDidMount() {
        this.initParams(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.search_site_id !== this.props.search_site_id) {
            this.initParams(nextProps);
        }
    }

    onInputChange                   =   (selectedOption)  =>  {
        this.props.changeEvent(selectedOption);
    }
    
    displayStringSearch             =   (workstation)  =>  {
        return workstation.map(workstation => {
            return {
                label               :   workstation.name,
                value               :   workstation.id,
                workstation
            };
        });
    };
    
    loadingOptions                  =   (inputText,callback)  =>  {
        if(inputText.length > 2) {
            clearTimeout(this.apiTimeout)
            if (this.cancelToken) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }
            this.cancelToken = axios.CancelToken.source();

            let params          = {...this.state.params, search_by_keywords: inputText, per_page: 50};
            
            this.apiTimeout  = setTimeout(() => {this.getLoadingOptions(params,callback)},1000);
        }
    }

    getLoadingOptions         =        (params,callback)            =>         {
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params: params,
            url: IAM_API_BASE_URL_2 + '/workstation/list',
            cancelToken: this.cancelToken.token
        })
        .then((response) => {
            callback(this.displayStringSearch(response.data.data))
        }).catch((err) => {
            if (axios.isCancel(err)) {
              
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        });
    }

    
    render() {
          return (<Ax>
            <AsyncSelect
                styles          =   {ReactSelectStyle(this.props)}
                menuPlacement   =   {this.props.menuPlacement || "bottom"}
                placeholder     =   {this.props.placeholder || "Search Workstation" }
                loadingMessage  =   {(input) => {
                    if(input.inputValue && input.inputValue.length <=2 ) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'Searching...';
                    }
                }}
                noOptionsMessage  =   {(input) => {
                    if(input.inputValue.length == 0) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'No User found';
                    }
                }}
                minimumInput    =   {5}
                complete        =   {true}
                onChange        =   {this.onInputChange}
                isClearable     =   {true}
                loadOptions     =   {this.loadingOptions}
                value           =   {this.props.value}
                isMulti         =   {this.props.isMulti || false}
                required        =   {true}
                isDisabled      =   {this.props.disabled || false}
            />
            <input
                name=''
                tabIndex={-1}
                required={this.props.required || false}
                value={this.props.isMulti ? (this.props.value.length > 0 ? this.props.value[0].label : [].join(' ')) : (this.props.value ? this.props.value.label : '')}
                onChange={() => {} }
                style={{
                    opacity: 0,
                    width: 0,
                    height: 0,
                    padding: 0,
                    margin: 0,
                    display: 'table'
                  }}
            />        
            </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(InputWorkstationSearch);