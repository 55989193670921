import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import AppBaseComponent from '../../components/AppBaseComponent';
import HttpAPICall from "../../services/HttpAPICall";
import tapIcon from "../../services/TapIcon";
import Ax from "../../components/hoc/Ax"
import React from "react";
import { INVENTORY_API_BASE_URL_2 } from "../../services/TapApiUrls";
import InputItemSearch from '../includes/ui/InputItemSearch';
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import InterchangeableItemModal from "./InterchangeableItemModal";


class InterchangeableItem extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initInterchangeable    =               {
            item_id_2               :               null,
            notes                   :               ''
        }
        this.state                  =           {
            itemId                  :               this.props.item_enc_id,
            itemName                :               this.props.itemName,
            listing_tbl_page        :               1,
            listingData             :               [],
            listingMeta             :               [],
            totalListingCount       :               0,
            listing_loading         :               false,
            searchedItem            :               null,
            editFormSubmiting       :               false,
            edit                    :               false,
            addInterchangeableForm  :               {...this.initInterchangeable},
            notes                   :               '',
            id                      :               ''
        }

        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item';
        
    }

    componentDidMount() {
        this.loadListingTblData(this.state.listigetInterchangeableListingng_tbl_page,this.props.item_enc_id);
        this.linkInterchangeableModal           =   new Modal(document.getElementById('linkInterchangeableModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.item_enc_id !== this.props.item_enc_id){
            this.loadListingTblData(1,nextProps.item_enc_id);
            this.setState({itemId : nextProps.item_enc_id,itemName : nextProps.itemName})
        }
    }

    loadListingTblData                  =   (page = 1,id)  =>  {
        this.setState({ listing_loading: true, listing_tbl_page: page });
        HttpAPICall.withAthorization('GET', this.itemUrl + '/interchangeable_item/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                listingData     : respData.data,
                listingMeta     : respData.meta,
                totalListingCount: respData.meta.total,
                notes               :   respData.notes,
                id                  :   respData.id,
            })
        }).then(() => this.setState({ listing_loading: false }))
    }
    
    closeModal                              =   ()  =>  {
        this.setState({addInterchangeableForm :  {...this.initInterchangeable},searchedItem : null})
    }

    linkInterchangeableInit                 =   ()  =>  {
        this.setState({edit:false})
        this.linkInterchangeableModal.show();
    }

    changeTab       =       (key)       =>   {
        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
        let itemName,itemCategory;
        if(ParamObject.get('asset_name')){
           itemName = ParamObject.get('asset_name')
        }
        if(ParamObject.get('item_category')){
            itemCategory = ParamObject.get('item_category')
         }
        let obj = {asset_name : itemName,item_category : itemCategory}

        this.props.props.history.push({ pathname: `/item_list/${key}/${this.state.itemId}`, search: "?" + new URLSearchParams(obj).toString()})
    }

    editInterchangeable                 =       (id)      =>  {
      this.setState({edit : true})
      this.linkInterchangeableModal.show();
     
      this.setState({
          addInterchangeableForm   :   {
              id                   :    this.state.id,
              notes                :    this.state.notes
          },
         
      })
    }
  
    deLinkItem                          =       (id)         =>   {
        swal({
            title: "DeLink Item",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    let frmData = {
                        item_id_1 : this.state.itemId,
                        item_id_2 : id
                    }
                    HttpAPICall.withAthorization('PUT', this.itemUrl + '/unlink_interchangeable_item', this.props.access_token, {},{...frmData} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                       this.loadListingTblData(1,this.state.itemId)
                    }).then(() => {

                        });
                }
            });
    }
    
    submitlinkItemForm                  =       (e)      =>  {
        e.preventDefault();
        this.setState({ editFormSubmiting: true })
        if(this.state.itemId == this.state.addInterchangeableForm.item_id_2){
            toast.error("Both Items cannot be same,Please Select another item for Interchangeable",{position: toast.POSITION.TOP_RIGHT});
            this.setState({ editFormSubmiting: false })
        }else{
            if(this.state.edit === true){
                let frmData = {
                    ...this.state.addInterchangeableForm
                }
                HttpAPICall.withAthorization('PUT', this.itemUrl + '/note_interchangeable_item', this.props.access_token, {}, { ...frmData}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.linkInterchangeableModal.hide()
                    this.loadListingTblData(1,this.state.itemId)
                    this.setState({addInterchangeableForm : {...this.initInterchangeable},searchedItem : null})
                }).then(() => this.setState({ editFormSubmiting: false }));  
            }else{
                let frmData = {
                    item_id_1 : this.state.itemId,
                    ...this.state.addInterchangeableForm
                }
                HttpAPICall.withAthorization('POST', this.itemUrl + '/add_interchangeable_item', this.props.access_token, {}, { ...frmData}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.linkInterchangeableModal.hide()
                    this.loadListingTblData(1,this.state.itemId)
                    this.setState({addInterchangeableForm : {...this.initInterchangeable},searchedItem : null})
                }).then(() => this.setState({ editFormSubmiting: false }));  
            }
        }
    }

    linkInterchangeableModalJsx       =    ()       =>  {
        return (
            <div className="modal fade" id="linkInterchangeableModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Link Interchangeable with Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitlinkItemForm} id="addModalForm">
                            <div className="modal-body">
                               {this.state.edit == false && <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Item Search</label>
                                    </div>
                                    <div className="col-md-8">
                                        <InputItemSearch
                                            required={true}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addInterchangeableForm', 'item_id_2');
                                                this.setState({
                                                    searchedItem: selectedOption ? selectedOption : null
                                                }, () => {
                                                });
                                            }}
                                            disabled = {this.state.edit === true ? true : false}
                                            value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                                        />
                                    </div>

                                </div>}
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Notes</label>
                                    </div>
                                    <div className="col-md-8">
                                        <textarea
                                            name="notes"
                                            className="form-control"
                                            value={this.state.addInterchangeableForm.notes}
                                            onChange={(e) => this.formInputHandler(e, 'addInterchangeableForm')}
                                            style={{ height: "80px" }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal" onClick={this.closeModal}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =     ()           =>     {
        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
       
        let itemCategory;
        if(ParamObject.get('item_category')){
           itemCategory = ParamObject.get('item_category')
        }
    
        return (<Ax>
            <Helmet><title>Interchangeable Item </title></Helmet>
            <section className="item_view_container bg-white">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/details/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab('details')}>
                            <button className="nav-link " id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                                type="button" role="tab" aria-controls="details" aria-selected="true">Details
                            </button>
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/interchangeable/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab('interchangeable')}>
                            <button className="nav-link active" id="interchangeable-tab" data-bs-toggle="tab" data-bs-target="#interchangeable"
                                type="button" role="tab" aria-controls="interchangeable" aria-selected="false">Interchangeable Items
                            </button>
                        </Link>

                    </li>
                    {itemCategory !== "services" && this.props.category !== "services" ?
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/inventory/${this.state.itemId}`} role={"button"} onClick={() => this.changeTab('inventory')}>
                                <button className="nav-link" id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory"
                                    type="button" role="tab" aria-controls="inventory" aria-selected="false">Inventory Levels
                                </button>
                            </Link>
                        </li> : null}
                    {itemCategory !== "services" && this.props.category !== "services" ?
                        <li className="nav-item" role="presentation">
                            <Link to={`/item_list/configuration/${this.state.itemId}`} onClick={() => this.changeTab('configuration')} role={"button"}>
                                <button className="nav-link" id="config-tab" data-bs-toggle="tab" data-bs-target="#config"
                                    type="button" role="tab" aria-controls="config" aria-selected="false">
                                    Configuration
                                </button>
                            </Link>
                        </li> 
                    : null }
                </ul>
                <div className="tab-content" id="myTabContent"></div>
                <InterchangeableItemModal item_enc_id={this.state.itemId}/>
            </section>
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(InterchangeableItem);