import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import TapSelect from '../../../components/ui/TapSelect';
import { Helmet } from 'react-helmet';
import { Modal } from 'bootstrap';
import { Link } from 'react-router-dom';
import download from '../../../services/DownloadFile'
import moment from 'moment';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ItemViewInList from '../../inventory/includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import DateService from '../../../services/DateService';
//import Sales OrderDetailModal from './Sales OrderDetailModal';
import InputDealSearch from '../includes/InputDealSearch';
import CustomerProjectSearch from '../includes/CustomerProjectSearch';
import SalesOrderDetail from './SalesOrderDetail';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import TapIcon from '../../../services/TapIcon';
class SalesOrderList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initQuoteSearchForm = {
            search_transaction_id: '',
            search_customer_ids: [],
            search_customer_project_ids: [],
            search_warehouse_ids: [],
            date_range: null,
            date_range_start: null,
            date_range_end: null,
            search_item_ids: [],
            search_item_tags: [],
            search_status_ids: [],
            search_priority: '',
            tagged_user_ids: [],
            search_deal_ids: [],
        }

        this.quoteSearchFormLabel = {
            search_transaction_id: 'Transaction ID : ',
            search_warehouse_ids: 'Warehouses : ',
            date_range: 'Date Range : ',
            search_item_ids: 'Items : ',
            search_item_tags: 'Item Tags : ',
            search_status_ids: 'Search Status : ',
            search_priority: 'Search Priority : ',
            search_customer_ids: 'Customer : ',
            search_customer_project_ids: 'Customer Project : ',
            tagged_user_ids: 'Tagged Users : ',
            search_deal_ids: 'Deals : ',
        }

        this.updateCommentFormInit = {
            status: '',
            notes: '',
        }

        this.cancelItemFormInit = {
            notes: '',
        }

        this.state = {
            minimizeTable: false,
            listing_loading: false,
            listing_tbl_page: 1,
            quote_listing: [],
            listingMeta: null,
            totalListingCount: 0,
            allItemTags: [],
            quoteSearchForm: { ...this.initQuoteSearchForm },
            submittedPOFilterForm: { ...this.initQuoteSearchForm },
            addCommentForm: { ...this.updateCommentFormInit },
            cancelledItemForm: { ...this.cancelItemFormInit },
            quoteSearchFormDataLoaded: false,
            all_warehouses: [],
            all_periods: [],
            searchedItem: [],
            formSearchedElems: [],
            searchedCustomer: [],
            salesOrderTransId: null,
            detail_loading: false,
            salesOrderDetail: null,
            allStatusOptions: [],
            allPriorityOptions: [],
            allAllowedStatus: [],
            addCommentFormSubmitting: false,
            pdfDownloading: false,
            cancelledItemList: [],
            cancel_item_loading: false,
            disableCancelItem: true,
            cancelItemFormSubmitting: false,
            itemDetails: null,
            allUsers: [],
            searchedDeals: [],
            searchedCustomerProject: []
        };
        this.cancelItemFormInit = {
            notes: '',
        }

        this.state = {
            cancelledItemList: [],
            cancel_item_loading: false,
            cancelItemFormSubmitting: false,
            cancelledItemForm: { ...this.cancelItemFormInit },
            warehouse_indent: null
        };
        this.requisitionUrl = INVENTORY_API_BASE_URL_2 + '/sales_order';
        this.myRef = React.createRef();
        this.itemDetailsRef = React.createRef();
        this.consumptionHistoryRef = React.createRef();
        this.poHistoryRef = React.createRef();
        this.transactionLedgerRef = React.createRef();
        this.ReqDetailModalRef = React.createRef();
        this.VendorDetailModalRef = React.createRef();
        this.LogisticViewModalRef = React.createRef();

        this.salesOrderListUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/list';
        this.salesOrderDetailUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/detail';
        this.searchFormDataUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/form_data';
        this.salesOrderUrl = INVENTORY_API_BASE_URL_2 + '/sales_order';
    }

    componentDidMount() {

        this.getQuoteSearchFormData();

        let ParamObject = new URLSearchParams(this.props.location.search);

        this.loadListingTblData(1);
        this.initializeData(this.props)
        if (this.props.location && this.props.location.state && this.props.location.state.salesId) {
            this.viewSalesOrderDetail(this.props.location.state.salesId)
        }

        this.cancelItemModal = new Modal(document.getElementById('cancelItemModal'), { keyboard: false, backdrop: false });
        this.quoteSearchModal = new Modal(document.getElementById('quoteSearchModal'), { keyboard: false, backdrop: false });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
        }
    }

    initializeData = (pr) => {
        if (pr) {
            let all_periods = [...pr.all_periods, { key: 'custom', display: 'Custom Date Range' }];
            this.setState({
                all_warehouses: pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` } }) : [],
                all_periods: all_periods,
                allItemTags: pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return { value: s.id, label: s.name }; }) : [],
            });
        }

        if (pr.location && pr.location.state && pr.location.state.quoteId) {
            this.viewSalesOrderDetail(pr.location.state.quoteId)
        }

    }
    cancelItemModalInit = () => {
        this.setState({ cancelledItemList: [] }, () => {
            let cancelledItemList = this.state.cancelledItemList;
            var showErrorMessage = false;
            let allocationItemForCheckbox = document.getElementsByClassName('allocation_item_id_checkbox');
            if (allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
                for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                    if (allocationItemForCheckbox[i].checked) {
                        let itemId = allocationItemForCheckbox[i].value;
                        let data = this.state.salesOrderDetail.items.find(i => i.item_id == itemId);
                        let row = { ...cancelledItemList[i], ...data };
                        cancelledItemList[i] = row;
                        let list = cancelledItemList.filter(Boolean)
                        this.setState({
                            cancelledItemList: [...list],
                            cancelledItemForm: {
                                ...this.cancelItemFormInit,
                                notes: ''
                            }
                        }
                            , () => this.cancelItemModal.show())

                    }
                }
            }
            if (this.state.cancelledItemList && this.state.cancelledItemList.length == 0 && !showErrorMessage) {
                toast.error('Please select an Item for Cancellation', { position: toast.POSITION.TOP_RIGHT });
            }
        })

    }
    submitCancelItemForm = (e) => {
        e.preventDefault()

        let items = [...this.state.cancelledItemList];

        let itemsLists = items.map(i => {

            return { cancel_qty: parseInt(i.new_released_qty), so_item_id: i.so_item_id };
        });

        let formData = { ...this.state.cancelledItemForm, items: [...itemsLists], transaction_id: this.state.salesOrderTransId }

        this.setState({ cancelItemFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', INVENTORY_API_BASE_URL_2 + '/sales_order/item_cancel', this.props.access_token, {}, { ...formData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.cancelItemModal.hide()
            this.setState({ cancelledItemForm: { ...this.cancelItemFormInit } })
            this.state.salesOrderTransId(this.state.salesOrderTransId, true)
        }).then(() => this.setState({ cancelItemFormSubmitting: false }));
    }
    cancelItemModalJsx = () => {

        return (
            <div className="modal fade" id="cancelItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Cancel Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "12%" }}>Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Cancelled Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Pending Qty</th>

                                                    <th scope="col" style={{ width: "12%" }}>Cancel</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="text-end" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td className='text-start'>{i.item_profile ? <div><span className="fw-bold">{i.item_profile.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.item_profile.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.item_profile?.manufacturer?.name ?? '-'} (MPN : {i.item_profile?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.item_profile?.tags?.length > 0
                                                                    ? (i.item_profile?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td>{i.qty ? i.qty : 0}</td>
                                                        <td>{i.released_qty ? i.released_qty : 0}</td>
                                                        <td>{i.pending_qty ? i.pending_qty : 0}</td>

                                                        <td className="text-end">
                                                            <TapInputNumber
                                                                name="new_released_qty"
                                                                value={this.state.cancelledItemList[k]?.new_released_qty}
                                                                onChange={(e) => this.dynamicInputHandlerByKey(null, k, 'cancelledItemList', null, "new_released_qty", e)}
                                                                placeholder="Cancel Qty"
                                                                required={true}
                                                                max={i.pending_qty}
                                                            /></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    openAddPurchaseOrderScreen = () => {
        this.props.history.push('/sales_order_add')
    }

    //************************GET PO LISTING**************** */
    loadListingTblData = (page = 1) => {
        this.setState({ listing_loading: true, listing_tbl_page: page });
        let params = { ...this.state.submittedPOFilterForm, page: page };
        HttpAPICall.withAthorization('GET', this.salesOrderListUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                quote_listing: respData.data,
                listingMeta: {
                    from: respData.from,
                    to: respData.to,
                    total: respData.total,
                    last_page: respData.last_page,
                    per_page: respData.per_page,
                    current_page: respData.current_page
                },
                totalListingCount: respData.total,
            });
        }).then(() => this.setState({ listing_loading: false }));
    }

    submitPOSearchForm = (e) => {
        e && e.preventDefault()
        let serachFormKeys = Object.keys(this.state.quoteSearchForm);

        let searchedElems = [];
        let searchParams = {};
        serachFormKeys.map((key) => {

            let label = this.quoteSearchFormLabel[key];
            let value = this.state.quoteSearchForm[key];

            if (value && value.toString().length > 0) {
                searchParams[key] = value;
                if (label) {
                    let show_val = value;
                    if (key == 'search_transaction_id') {
                        show_val = this.state.quoteSearchForm ? this.state.quoteSearchForm.search_transaction_id : '';
                    }
                    if (key == 'search_customer_ids') {
                        show_val = this.state.searchedCustomer ? this.state.searchedCustomer.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'search_customer_project_ids') {
                        show_val = this.state.searchedCustomerProject ? this.state.searchedCustomerProject.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'search_deal_ids') {
                        show_val = this.state.searchedDeals ? this.state.searchedDeals.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'search_warehouse_ids') {
                        show_val = this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_item_ids') {
                        show_val = this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if (key == 'search_item_tags') {
                        show_val = this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_status_ids') {
                        show_val = this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'search_priority') {
                        show_val = this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                    }

                    if (key == 'tagged_user_ids') {
                        show_val = this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if (key == 'date_range') {
                        let start_range = moment(this.state.quoteSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.quoteSearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedPOFilterForm: { ...this.state.quoteSearchForm },
            formSearchedElems: searchedElems
        }, () => {
            this.quoteSearchModal.hide()
            this.loadListingTblData(1);
        });
        console.log(this.state.submittedPOFilterForm,"uiui")
        console.log(searchedElems ,"tyrew")
    }

    poSearchClear = () => {
        this.setState({
            formSearchedElems: [],
            quoteSearchForm: { ...this.initQuoteSearchForm },
            submittedPOFilterForm: { ...this.initQuoteSearchForm },
            searchedItem: [],
            searchedCustomer: [],
            searchedCustomerProject: [],
            searchedDeals: []
        }, () => {
            this.loadListingTblData(1);
            this.quoteSearchModal.hide();
        });
    }

    poClear = () => {
        // this.setState({
        //     formSearchedElems           :       [],
        //     quoteSearchForm                :       {...this.initQuoteSearchForm},
        //     submittedPOFilterForm       :       {...this.initQuoteSearchForm},
        //     searchedItem                :       [],
        //     searchedCustomer              :       []
        // }, () => {
        //     this.quoteSearchModal.hide();
        // });
    }

    //************************REQUISTION SEARCH FORM **************** */
    quoteSearchModalInit = () => {
        this.quoteSearchModal.show();
        this.getQuoteSearchFormData();

    }

    getQuoteSearchFormData = () => {
        if (!this.state.quoteSearchFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.searchFormDataUrl, this.props.access_token, null, null, (response) => {
                let formData = response.data
                this.setState({
                    quoteSearchFormDataLoaded: true,
                    allStatusOptions: formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return { value: s.id, label: s.name }; }) : [],
                    allPriorityOptions: formData && formData.priorities && formData.priorities.length > 0 ? formData.priorities.map((s) => { return { value: s.key, label: s.name }; }) : [],
                    allUsers: formData && formData.users ? formData.users.map((s) => { return { value: s.id, label: s.full_name, site_ids: s.linked_sites }; }) : [],
                    allPOTypes: formData && formData.purchase_order_type && formData.purchase_order_type.length > 0 ? formData.purchase_order_type.map((s) => { return { value: s.key, label: s.name }; }) : [],
                });
            });
        }
    }


    viewSalesOrderDetail = (transaction_id) => {
        this.setState({ minimizeTable: true })
        if (transaction_id !== this.state.salesOrderTransId) {
            this.getSalesOrderDetail(transaction_id)
        }
    }

    getSalesOrderDetail = (transaction_id) => {
        this.setState({ detail_loading: true })
        HttpAPICall.withAthorization('GET', this.salesOrderDetailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                salesOrderDetail: respData,
                salesOrderTransId: respData.transaction_id,
                allAllowedStatus: respData && respData.change_status_list && respData.change_status_list.length > 0 ? respData.change_status_list.map((s) => { return { value: s.id, label: s.name }; }) : [],
                addCommentForm: { ...this.updateCommentFormInit, status: respData.status.id }
            });

        }, (err) => {
            if (err) {
                this.setState({ minimizeTable: false })
            }
        }).then(() => this.setState({ detail_loading: false }));
    }

    editPO = () => {
        this.props.history.push({ pathname: `/sales_order_add`, state: { salesOrderData: this.state.salesOrderDetail } })
    }

    clonePO = () => {
        this.props.history.push({ pathname: `/sales_order_add`, state: { cloneQuoteDetail: this.state.salesOrderDetail } })
    }

    // viewItemModalInit = (item) => {
    //     if (item) {
    //         this.itemDetailsRef.current.modalInit(item)
    //     }
    // }

    //***********************DOWNLOAD PDF **************************** */
    downloadPdfHandler = (transaction_id, use_for = 'internal') => {
        this.setState({ pdfDownloading: true });
        HttpAPICall.withAthorization('GET', this.salesOrderUrl + '/detail/' + transaction_id, this.props.access_token, { download: "download", use_for: use_for }, {}, (response) => {
            download.file(response.data.file_path);
        })
            .then(() => this.setState({ pdfDownloading: false }));
    }

    closeViewDetails = () => {
        this.setState({ minimizeTable: false })
        this.props.history.push({ pathname: `/sales_order_list` })
    }

    updateComment = () => {
        this.myRef.current.scrollIntoView()
    }

    //*************************SUBMIT COMMENT FORM ********************** */
    submitUpdateCommentForm = (e) => {
        e.preventDefault()
        this.setState({ addCommentFormSubmitting: true })
        let frmData = {
            ...this.state.addCommentForm,
            transaction_id: this.state.salesOrderTransId
        }
        HttpAPICall.withAthorization('PUT', this.salesOrderUrl + '/comment_add', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getSalesOrderDetail(this.state.salesOrderTransId);
            this.loadListingTblData(1)
            this.setState({ addCommentForm: { ...this.updateCommentFormInit } })
        }).then(() => this.setState({ addCommentFormSubmitting: false }));
    }


    SalesOrderListJsx = () => {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Sales Order</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button" className="btn btn-primary" onClick={this.openAddPurchaseOrderScreen}>New Sales Order</button> : null}
                    {(!this.state.minimizeTable) ? <button type="button" className="btn btn-secondary" onClick={this.quoteSearchModalInit} disabled={this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button> : null}
                    {this.state.minimizeTable && this.props.permissions.includes('salesOrder-add') ?
                        <span className="dropdown">
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {this.state.minimizeTable ? <li><a className="dropdown-item" href="#" onClick={this.openAddPurchaseOrderScreen}>Add </a></li> : null}
                            </ul>
                        </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Sales Order ID : {this.state.salesOrderTransId}</h3>
                            <div className="text-end mt15">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    disabled={this.state.detail_loading}
                                    onClick={() => this.updateComment()} >Update Status </button>

                                {/* <button
                                    disabled={this.state.pdfDownloading || this.state.detail_loading}
                                    type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                    {this.state.pdfDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : ''}
                                </button> */}
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <Link className="dropdown-item" disabled={this.state.detail_loading} onClick={() => this.downloadPdfHandler(this.state.salesOrderTransId, 'internal')}>PDF for Internal Use</Link>
                                    </li>
                                    <li>
                                        <Link className={['dropdown-item', (this.state.detail_loading) ? 'disabled' : ''].join(' ')} href="#" onClick={() => this.downloadPdfHandler(this.state.salesOrderTransId, 'customer')}>PDF for Customer</Link>
                                    </li>

                                </ul>
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading || !this.props.permissions.includes('salesOrder-edit')) ? '' : ''].join(' ')} onClick={() => this.editPO(this.state.salesOrderTransId)}>Edit</a>
                                    </li>
                                    <li>
                                        <a href='#' className={['dropdown-item', (this.state.detail_loading || !this.props.permissions.includes('salesOrder-add')) ? '' : ''].join(' ')} onClick={() => this.clonePO()}>Clone</a>
                                    </li>
                                    <li>
                                        <a className='dropdown-item'
                                            // className={!this.props.permissions.includes('salesOrder-cancel') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={!this.props.permissions.includes('salesOrder-cancel') ? true : false} href="#" onClick={() => this.cancelItemModalInit(this.state.salesOrderTransId)}
                                            onClick={() => this.cancelItemModalInit()}>
                                            Cancel Item</a>
                                    </li>
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.SalesOrderListingJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4 pageTbl bg-white">{this.salesOrderViewDetails()}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    SalesOrderListingJsx = () => {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.poSearchClear} />
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Transaction ID (Date)</th>
                        </tr>)
                        : (<tr className="text-center">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "12%" }}>Sales Order ID</th>
                            <th scope="col" style={{ width: "12%" }}> Date</th>
                            <th scope="col" style={{ width: "15%" }}>Warehouse</th>
                            <th scope="col" style={{ width: "15%" }}>Customer</th>
                            <th scope="col" style={{ width: "6%" }}>Currency</th>
                            <th scope="col" style={{ width: "10%" }} className="text-end">Amount</th>
                            <th scope="col" style={{ width: "10%" }}>Status</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="9"><Loader /></td></tr>)
                        : (this.state.quote_listing?.length > 0
                            ? (this.state.quote_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <a role="button" className="link-primary" onClick={() => this.viewSalesOrderDetail(item.transaction_id)}>{item.transaction_id}</a>
                                                        <br /><small>{item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : ""}</small>
                                                        <small style={{ float: 'right' }}>{item.status ? item.status.name : '-'}</small>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                                <td><a role="button" className="link-primary text-center" onClick={() => this.viewSalesOrderDetail(item.transaction_id)}>{item.transaction_id}</a></td>
                                                <td>{item.transaction_date ? DateService.dateTimeFormat(item.transaction_date, 'DD-MMM-YYYY') : ""}</td>
                                                <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                    : "-"}</td>
                                                <td className='text-capitalize'>{item.customer ? <div>{item.customer.name}</div>
                                                    : "-"}</td>

                                                <td className="text-center">{item.currency ? item.currency : "INR"}</td>
                                                <td className="text-end"><CurrencyFormatter currencyCode={item.currency ? item.currency : "INR"} amount={parseFloat(item.total_amount_vc ? item.total_amount_vc : 0).toFixed(2)} />

                                                </td>
                                                <td className='text-center'>{item.status ? item.status.name : '-'}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button">View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }
    checkAllItem() {
        let allocationItemForCheckbox = document.getElementsByClassName('allocation_item_id_checkbox')
        if (allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                allocationItemForCheckbox[i].checked = document.getElementById('all_check_allocation').checked;
            }
        }
    }
    cancelledItemModalJsx = () => {

        return (
            <div className="modal fade" id="cancelledItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Cancel Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ cancelledItemList: [] }) }}></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" style={{ width: "28%" }} className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "9%" }} className="text-center">Indent </th>
                                                    <th scope="col" style={{ width: "9%" }}>Req. Qty</th>
                                                    <th scope="col" style={{ width: "9%" }}>PO Qty</th>

                                                    <th scope="col" style={{ width: "9%" }}>Received</th>
                                                    <th scope="col" style={{ width: "9%" }}>Cancelled </th>
                                                    <th scope="col" style={{ width: "9%" }}>Pending </th>
                                                    <th scope="col" style={{ width: "9%" }}>New Cancelled </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td>{i.item ? <div><span className="fw-bold">{i.item.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.item?.manufacturer?.name ?? '-'} (MPN : {i.item?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.item?.tags?.length > 0
                                                                    ? (i.item?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td className='text-center'>{i.indent_data ? <span>{this.state.warehouse_indent?.transaction_id} : {i.indent_data.indent_need_qty}</span> : "-"}</td>
                                                        <td className='text-end'>{i.qty ?? "-"}</td>

                                                        <td className="text-end">{i.current_po_qty ?? "-"}</td>
                                                        {/* <td className="text-end">{i.intransit_qty ?? "-"}</td> */}
                                                        <td className="text-end">{i.received_qty ?? "-"}</td>
                                                        <td className="text-end">{i.cancelled_qty ?? "-"}</td>
                                                        <td className='text-end' >
                                                            {i.pending_qty ?? "-"}</td>
                                                        <td className="text-end">
                                                            <TapInputNumber
                                                                name="new_cancelled_qty"
                                                                value={this.state.cancelledItemList[k]?.new_cancelled_qty}
                                                                onChange={(newValue) => {
                                                                    const updatedRows = [...this.state.cancelledItemList];
                                                                    updatedRows[k].new_cancelled_qty = newValue; // Update the quantity in the corresponding row
                                                                    this.setState({ cancelledItemList: updatedRows });
                                                                }}
                                                                placeholder="Cancelled Qty"
                                                                required={true}
                                                                max={i.pending_qty}
                                                            />
                                                        </td>




                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    salesOrderViewDetails = () => {
        let salesOrder = this.state.salesOrderDetail
        return (<div className='p-2'>
            {!this.state.detail_loading
                ? (<Ax>
                    {this.state.salesOrderDetail
                        ? <div>
                            <SalesOrderDetail salesOrderDetail={this.state.salesOrderDetail} checkAllItem={this.checkAllItem} />
                            <hr />
                            <div>
                                <div className="tab_content_wrapper" ref={this.myRef}>
                                    <span className="content_heading">Comments & History</span>
                                </div>
                                {this.state.allAllowedStatus
                                    ? <form onSubmit={this.submitUpdateCommentForm}>
                                        <div className="my-3 row">
                                            <label className="col-sm-3 col-form-label">Change status to</label>
                                            <div className="col-sm-5">
                                                <TapSelect
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                                    }}
                                                    value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                                    options={this.state.allAllowedStatus}
                                                    isSearchable={false}
                                                    isDisabled={this.state.salesOrderDetail?.change_status == "N" || !this.props.permissions.includes('isc-po-status-change')}
                                                    placeholder="Please Select Status"
                                                />
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <label className="form-label">Comments</label>
                                            <textarea
                                                name="notes"
                                                value={this.state.addCommentForm.notes}
                                                onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                                className="form-control"
                                                placeholder="Please Add a comment"
                                                required
                                                style={{ height: "100px" }}
                                            />
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                                Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                            </button>
                                        </div>
                                    </form>
                                    : null}
                            </div>
                            <hr />
                            <div className="my-1">
                                {
                                    salesOrder.comments && salesOrder.comments.length > 0 ? salesOrder.comments.map((c, k) => {
                                        return (<Ax key={k}><div className="row">
                                            {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                            <div className="col-sm-4 text-end">
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {c.created_at ? DateService.dateTimeFormat(c.created_at, 'DD-MMM-YYYY') : ''}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div><hr /></Ax>);
                                    })
                                        : null
                                }
                            </div>
                        </div>
                        : null}
                </Ax>)
                : <Loader />}

        </div>)
    }

    //***********************SEARCH REQUISTION JSX****************
    quoteSearchModalJsx = () => {
        return (
            <div className="modal fade" id="quoteSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Sales Order Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.poClear}></button>
                        </div>
                        <form id="quoteSearchForm" onSubmit={this.submitPOSearchForm}>
                            <div className="modal-body">
                                {!this.state.quoteSearchFormDataLoaded ? <Loader />
                                    : <Ax>
                                        <div className="row my-3 align-items-center">

                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Sales Order Number</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_transaction_id"
                                                    type="text"
                                                    value={this.state.quoteSearchForm?.search_transaction_id}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "quoteSearchForm")}
                                                    placeholder="Please enter Transaction ID"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Status</label>
                                            <div className="col-sm-4">
                                                <TapSelect
                                                    options={this.state.allStatusOptions}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_status_ids')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}

                                                    value={this.state.allStatusOptions.filter(s => this.state.quoteSearchForm?.search_status_ids?.includes(s.value))}
                                                    placeholder="Select Status"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Priority</label>
                                            <div className="col-sm-4">
                                                <TapSelect
                                                    options={this.state.allPriorityOptions}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_priority')}
                                                    value={this.state.allPriorityOptions.find(u => u.value == this.state.quoteSearchForm?.search_priority)}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="Select Priority"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>


                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Warehouse</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.all_warehouses}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_warehouse_ids')}
                                                    value={this.state.all_warehouses.filter(u => this.state.quoteSearchForm?.search_warehouse_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Warehouses"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Customer</label>
                                            <div className="col-sm-10">
                                                <InputCustomerSearch
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_customer_ids');
                                                        this.setState({ searchedCustomer: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                    }}
                                                    isMulti={true}
                                                    value={this.state?.searchedCustomer?.length > 0 ? this.state.searchedCustomer.map(a => { a.label = a.display_label; return a; }) : []}
                                                    containerHeight="30px"
                                                    fontSize="93%"

                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Customer Project</label>
                                            <div className="col-sm-10">
                                                <CustomerProjectSearch
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_customer_project_ids');
                                                        this.setState({ searchedCustomerProject: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                    }}
                                                    isMulti={true}
                                                    value={this.state?.searchedCustomerProject?.length > 0 ? this.state.searchedCustomerProject.map(a => { a.label = a.display_label; return a; }) : []}
                                                    containerHeight="30px"
                                                    fontSize="93%"

                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Deals</label>
                                            <div className="col-sm-10">
                                                <InputDealSearch
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_deal_ids');
                                                        this.setState({ searchedDeals: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                    }}
                                                    isMulti={true}
                                                    value={this.state?.searchedDeals?.length > 0 ? this.state.searchedDeals.map(a => { a.label = a.display_label; return a; }) : []}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    showSearch={false}

                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                            <div className="col-sm-10">
                                                <InputItemSearch
                                                    placeholder="Search Item"
                                                    isMulti={true}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_item_ids');
                                                        this.setState({
                                                            searchedItem: selectedOption ? selectedOption : null
                                                        }, () => { });
                                                    }}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state?.searchedItem?.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Item Tags</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allItemTags}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'quoteSearchForm', 'search_item_tags')}
                                                    value={this.state?.allItemTags.filter(u => this.state.quoteSearchForm?.search_item_tags?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Item Tags"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Tagged User</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    placeholder="Tagged User"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    options={this.state.allUsers}
                                                    value={this.state.allUsers.filter(s => this.state.quoteSearchForm?.tagged_user_ids?.includes(s.value))}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'quoteSearchForm', 'tagged_user_ids')}
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                            <div className="col-sm-10">
                                                <PeriodSelectionWithCustom
                                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                                    value={this.state.quoteSearchForm?.date_range}
                                                    startDate={this.state.quoteSearchForm?.date_range_start}
                                                    endDate={this.state.quoteSearchForm?.date_range_end}
                                                    onSelectPeriod={(period, startDate, endDate) => {
                                                        this.setState({
                                                            quoteSearchForm
                                                                : {
                                                                ...this.state.quoteSearchForm,
                                                                date_range: period,
                                                                date_range_start: startDate ? startDate : null,
                                                                date_range_end: endDate ? endDate : null
                                                            }
                                                        });
                                                    }}
                                                    className="form-control-sm"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.poClear}>Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Sales Order Listing</title></Helmet>
            {this.SalesOrderListJsx()}
            {this.quoteSearchModalJsx()}
            {this.cancelItemModalJsx()}
        </ApplicationLayout>);
    }

}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        all_periods: state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses: state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags: state.app && state.app.item_tags ? state.app.item_tags : [],
        orgInfoData: state.app.group_info ? state.app.group_info : null,
        default_currency: state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SalesOrderList);