import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Collapse, Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { Link } from 'react-router-dom';
import TapSelect from '../../../components/ui/TapSelect';
import { DisplayListPagination, PeriodSelectionWithCustom } from '../../../components/TapUi';
import moment from "moment";
import PurchaseDetail from '../purchase/PurchaseDetail';
import InventoryTransferDetail from '../inventoryTransfer/InventoryTransferDetail';
import ConsumptionDetailsModal from '../consumption/ConsumptionDetailsModal';
import InventoryAdjustmentDetail from '../inventoryAdjustment/InventoryAdjustmentDetail';
import PurchaseReturnDetail from '../purchaseReturn/PurchaseReturnDetail';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemTransactionLedger extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
            warehouse_id                        :   '',
            item_id                             :   '',
            transaction_type                    :   [],
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
        };
        
        this.reportFilterFormLabel          =   {
            warehouse_id                        :   'Search Warehouse : ',
            item_id                             :   'Search Item : ',
            date_range                          :   'Date Range : ',
            transaction_type                    :   'Search Transaction Type : ',
        };

        this.state                  =   {
            listing_loading                     :   true,
            listingData                         :   [],
            listingMeta                         :   null,
            summaryData                         :   null,
            summary_loading                     :   true,
            all_periods                         :   [],
            allStockTransactions                :   [],
            formDataLoaded                      :   false,
            itemData                            :   null,
            warehouseDetail                     :   null,
            transactionId                       :    '',
            transanctionType                    :    '',
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            showTransactionType                 :   false
        };
        
    }
    
    modalInit                       =   (itemData,warehouse_id,warehouseDetail,showTransactionType=false)  =>  {
        let pr = this.props;
        this.setState({
            listing_loading             :   true,
            summary_loading             :   true,
        });
        if(pr){
            let url                     =         process.env.REACT_APP_INVENTORY_API_SERVER + '/report/item-stock-ledger/form_data';
            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            
            HttpAPICall.withAthorization('GET', url, pr.access_token, null, null, (response) => {
          
                this.setState({
                    all_periods                 :   all_periods ,
                    itemData                    :   itemData,
                    warehouseDetail             :   warehouseDetail,
                    showTransactionType         :   showTransactionType,
                    allStockTransactions        :   response.data && response.data.stock_transactions && response.data.stock_transactions.length > 0 ? response.data.stock_transactions.map((m) => { return { value: m.key, label: `${m.name}` }}) : [],
                },() => {
                    let updateFilterForm        =        {date_range : showTransactionType ? "trailing_twelve_months" : "current_year"};
            
                    setTimeout(() => {
                        this.setState({
                            reportFilterForm               :        { ...this.state.reportFilterForm, ...updateFilterForm },
        
                        }, () => {
                            this.reportFilterFormSubmit(null) 
                        })
                    }, 1000)
                   
                }) ; 
            }, (err) => {

            });
        }
       
        this.itemTransactionLedgerModal.show();
    }

    componentDidMount(){
        this.itemTransactionLedgerModal                  =        new Modal(document.getElementById('itemTransactionLedgerModal'), {keyboard: false, backdrop :false});   
        this.viewTransactionModal                        =     new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
    }

    reportFilterFormSubmit                  =   (event=null,loading=false)  =>  {
        event && event.preventDefault();
        if(loading == true){
            this.setState({listing_loading : true,summary_loading : true})
        }
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'transaction_type') {
                        show_val            =   this.state.allStockTransactions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm,warehouse_id : this.state.warehouseDetail.id,item_id : this.state.itemData.id},
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page=1) => {
        this.setState({    
            listingData                 :   [],
            listingMeta                 :   null,
            summaryData                 :   null,
        });

        let transactionSummaryUrl        =   INVENTORY_API_BASE_URL_2 + '/report/item-stock-ledger/summary';
        let transactionListingUrl        =   INVENTORY_API_BASE_URL_2 + '/report/item-stock-ledger/list';
        let params                       =   {page:page,...this.state.submittedReportFilterForm};   

        //Api to call Summary 
        HttpAPICall.withAthorization('GET', transactionSummaryUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                summaryData                 :   respData,
            });
        }).then(() => {
            this.setState({summary_loading: false});
        });

         //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', transactionListingUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;

            this.setState({
                listingData                 :   respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
           
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    loadListingOnPageClick                  =   (page=1) => {
        this.setState({
            listing_loading             :   true,
        });
        let transactionListingUrl        =   process.env.REACT_APP_INVENTORY_API_SERVER + '/report/item-stock-ledger/list'
        let params                       =   {page:page ,...this.state.submittedReportFilterForm};   
         //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', transactionListingUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;

            this.setState({
                listingData                 :   respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
           
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    viewTransactionModalInit                =   (transaction_type,transaction_id)       =>      {
        this.setState({transanctionType : transaction_type,transactionId : transaction_id},
         () => this.viewTransactionModal.show())
     }
    
    //*********************************ITEM Transaction JSX*********************** */
    itemTransactionLedgerModalJsx                     =   ()  =>  {
    
        return (
            <div className="modal fade" id="itemTransactionLedgerModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemTransactionLedgerModalLabel">Item Transactions Ledger</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>

                        <div className="modal-body ">

                            {this.state.summary_loading
                                ? <Loader /> : <div className='p-1 bg-light border-bottom'>
                                    <div className='row align-items-center'>
                                        <div className='col-sm-7'>
                                            <form onSubmit={(e) => this.reportFilterFormSubmit(e,true)}>
                                                <table className='table table-sm mb-0 table-borderless text-sm'>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "20%" }}> Item :  </td>
                                                            <th>{this.state.summaryData && this.state.itemData ? <span>{this.state.itemData.name} ({this.state.itemData.item_code})</span> : "-"}</th>
                                                        </tr>
                                                        <tr>
                                                            <td> Warehouse :  </td>
                                                            <th>{this.state.summaryData && this.state.warehouseDetail ? <span>{this.state.warehouseDetail.name} ({this.state.warehouseDetail.code})</span> : "-"}</th>
                                                        </tr>

                                                       {!this.state.showTransactionType && <tr className=''>
                                                            <td >Transaction Type :  </td>
                                                            <th>
                                                                <TapSelect
                                                                    options={this.state.allStockTransactions}
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'transaction_type')}
                                                                    value={this.state.allStockTransactions.filter(u => this.state.reportFilterForm?.transaction_type?.includes(u.value))}
                                                                    isSearchable={true}
                                                                    isMulti={true}
                                                                    isClearable={true}
                                                                    placeholder="Select Transaction Type"
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                    menuPlacement="bottom"
                                                                />
                                                            </th>
                                                        </tr>}
                                                        <tr className=''>
                                                            <td> Period :  </td>
                                                            <th className='row'>
                                                               <div className='col-sm-10 pr0 mr0'>
                                                               <PeriodSelectionWithCustom
                                                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                                                    value={this.state.reportFilterForm.date_range}
                                                                    startDate={this.state.reportFilterForm.date_range_start}
                                                                    endDate={this.state.reportFilterForm.date_range_end}
                                                                    required={true}
                                                                    onSelectPeriod={(period, startDate, endDate) => {
                                                                        this.setState({
                                                                            reportFilterForm
                                                                                : {
                                                                                ...this.state.reportFilterForm,
                                                                                date_range: period,
                                                                                date_range_start: startDate ? startDate : null,
                                                                                date_range_end: endDate ? endDate : null
                                                                            }
                                                                        });
                                                                    }}
                                                                    className="form-control-sm"
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                    menuPlacement="bottom"

                                                                />
                                                               </div>
                                                                <div className='col-sm-2 text-end'><button type="submit" className='btn btn-sm btn-primary'>Go</button></div>
                                                            </th>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </form>
                                        </div>
                                        <div className='col-sm-5'>
                                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "20%" }} className='text-end'> Opening Stock :	   </td>
                                                        <th className='text-end' style={{ width: "20%" }}> {this.state.summaryData && this.state.summaryData.opening_stock ? this.state.summaryData.opening_stock : 0}</th>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-end'>   Total Received  :	   </td>
                                                        <th className='text-end'>{this.state.summaryData && this.state.summaryData.total_received ? this.state.summaryData.total_received : 0}</th>
                                                    </tr>
                                                    <tr className=''>

                                                        <td className='text-end'> Total Issued :	   </td>
                                                        <th className='text-end'>{this.state.summaryData && this.state.summaryData.total_issued ? this.state.summaryData.total_issued : 0}</th>
                                                    </tr>
                                                    <tr className=''>

                                                        <td className='text-end'> Closing Stock :   </td>
                                                        <th className='text-end'>{this.state.summaryData && this.state.summaryData.closing_stock ? this.state.summaryData.closing_stock : 0}</th>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}
                            <table className="table table-bordered  table-sm align-middle bg-white my-2">
                                <thead className="align-middle table-secondary">
                                    <tr className='text-start'>
                                        <th className="text-center" style={{ width: "5%" }}>S.No</th>
                                        <th className='text-center' style={{ width: "10%" }}>Date of Transaction</th>
                                        <th className='text-center' style={{ width: "10%" }}>Transaction Type</th>
                                        <th className='text-center' style={{ width: "10%" }}>Transaction ID</th>
                                        <th style={{ width: "30%" }}>Asset/Vendor/Workstation/Warehouse</th>
                                        <th className='text-center' scope="col" style={{ width: "9%" }}>Status</th>
                                        <th scope="col" className='text-end' style={{ width: "9%" }}>Received</th>
                                        <th scope="col" className='text-end' style={{ width: "9%" }}>Issued</th>


                                    </tr>
                                </thead>
                                {this.state.listing_loading
                                    ? (<tr><td colSpan="8"><Loader /></td></tr>)
                                    : <tbody>
                                        {this.state.listingData.length > 0
                                            ? (this.state.listingData.map((rowData, k) => {
                                                let pathname = rowData.transaction_type == "purchase" || rowData.transaction_type == "purchase_consumption" ? "/purchase_list" : rowData.transaction_type == "consumption" ? "/consumption_list" : "/inventory_transfer_list";

                                                return (<tr key={rowData.transaction_id}>
                                                    <td className='text-center'>{this.state.listingMeta.from + k}</td>
                                                    <td className='text-center'>{rowData.transaction_date_display ?? "-"}</td>
                                                    <td className='text-center'>{rowData.transaction_type_display ?? "-"}</td>
                                                    <td className='text-center'>
                                                        {rowData.transaction_id ?? "-"}</td>
                                                    <td>
                                                        <small className='form-text'>
                                                            <b>{rowData.transaction_type === "purchase" || rowData.transaction_type === 'purchase_return' ? "Vendor" : rowData.transaction_type === "inventory_transfer" || rowData.transaction_type === "opening_stock" || rowData.transaction_type === 'inventory_adjustment' ? "Warehouse" : rowData.asset_name ? "Asset" : rowData.employee_name ? "Employee" : "Workstation"} : </b>
                                                        </small>
                                                        {rowData.transaction_type === "purchase" || rowData.transaction_type === 'purchase_return' ? rowData.vendor_name ?? "-" : rowData.transaction_type === "inventory_transfer" || rowData.transaction_type === "opening_stock" || rowData.transaction_type === 'inventory_adjustment' ? rowData.warehouse_name ?? "-" : rowData.asset_name ? rowData.asset_name : rowData.employee_name ? rowData.employee_name : rowData.workstation_name}
                                                        <div className='mt-1'> {rowData.transaction_type === "purchase_consumption" ? <Ax><small className='form-text'><b>Vendor : </b> </small> {rowData.vendor_name ?? "-"}</Ax> : null}</div>

                                                    </td>

                                                    <td className='text-center'>{rowData.status ?? "-"}</td>
                                                    <td className='text-end'>{rowData.received && rowData.received !== 0 ? rowData.received : ""}</td>
                                                    <td className='text-end'>{rowData.issued && rowData.issued !== 0 ? rowData.issued : ""}</td>


                                                </tr>);

                                            }))
                                            : (<tr><td className="text-center" colSpan={10}>No Record Found</td></tr>)
                                        }
                                    </tbody>}
                            </table>
                            {this.state.listingData && this.state.listingData.length > 0
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingOnPageClick(e.selected + 1)} />
                            : null
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    transactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {this.state.transactionId ? this.state.transactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                        {this.state.transanctionType  == "purchase" 
                             ? <PurchaseDetail purchaseId={this.state.transactionId}/>
                             : this.state.transanctionType == "inventory_transfer" 
                             ? <InventoryTransferDetail inventoryId={this.state.transactionId}/>
                             : this.state.transanctionType == "inventory_adjustment" 
                             ? <InventoryAdjustmentDetail adjustmentId={this.state.transactionId}/>
                              : this.state.transanctionType == "purchase_return" 
                              ? <PurchaseReturnDetail purchaseReturnId={this.state.transactionId}/> :
                                <ConsumptionDetailsModal consumptionId={this.state.transactionId}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render() {
        return (<Ax>{this.itemTransactionLedgerModalJsx()}
          {this.transactionDetailsModalJsx()}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemTransactionLedger);
