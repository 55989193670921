import React, {createRef} from 'react';

import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import {Link} from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import axios from "axios";
import {toast} from "react-toastify";
import errorValidation from "../../../services/ErrorValidation";
import AssetHeader from "../../includes/assetHeader/AssetHeader";
import AssetHorizontalCard from "../../includes/assetHorizontalCard/AssetHorizontalCard";

class FeedInList extends React.Component {

    measurableAssetId = localStorage.getItem('MeasurableAsset')
    constructor() {
        super();

        this.state = {
            minimizeTable               :         false,
            serviceAddScreenView        :         false,
            serviceUpdateScreenView     :         false,
            scrollDown                  :         false,
            goto_top_visible            :         false,
            scrollTop                   :         0,
            assetCardDetails             :        [],
            feedInListing                :          [],
            listingMeta                 :         [],
            listing_loading             :        false,
            page                        :          1,
        }
       /* this.scrolltoServiceChecksheet  =        createRef();
        this.scrollToTop                =        createRef();
        this.myRef                      =       React.createRef()*/
        this.showScrollButton           =       this.showScrollButton.bind(this)
    }

    getAssetCard(id){

        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/asset/full_detail/' + id
        }).then((response) => {
            this.setState({assetCardDetails: response.data.data})
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        })
    }

    getFeedInListing(page){
        this.setState({listing_loading: true})
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            params:{feed_in_asset_id : [this.measurableAssetId],page: page},
            url: process.env.REACT_APP_API_SERVER + '/fuel_feed_in'
        }).then((response) => {
            this.setState({feedInListing: response.data.data,listingMeta : response.data.meta})
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

   /* onScroll = () => {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myRef.current.scrollTop
        this.setState({
            scrollTop: scrollTop
        })
        if(scrollTop > 300){
            this.setState({scrollDown:true})
        }else{
            this.setState({scrollDown:false})
        }
    }*/
    showScrollButton(){
        this.setState({scrollDown:true})
    }
    hideScrollButton(){
        this.setState({scrollDown:false})
    }

    componentDidMount() {
        this.getAssetCard(this.measurableAssetId)
        this.getFeedInListing(this.state.page)
    }

    viewFeedIn(id){
        this.setState({minimizeTable:true})
        /*const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            params : {scs_execution_id : id},
            url: process.env.REACT_APP_API_SERVER + '/asset/execution_detail'
        }).then((response) => {
            this.setState({viewHistoryDetails: response.data.scs,viewExecutionInterval:response.data.execution_interval})
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        })*/
    }

    //handle pagination
    handlePageClick = (e) => {
        this.setState({page: e.selected + 1})
        this.state.page = e.selected + 1;
        this.getFeedInListing(this.state.page);
    }


    closeViewDetails(){
        this.setState({minimizeTable:false})
    }
    //function to close
    closeMinimizeTable                  =   ()  =>  {
        this.props.history.push('/measurable_asset')
        localStorage.removeItem('MeasurableAsset');
        localStorage.removeItem('MeasurableAssetName');
    }

    linkToAssetProfile                  =   ()  =>  {
        this.props.history.push(`/measurable_asset_view?id=${this.measurableAssetId}`)
    }


    feedInListjsx    =   ()  =>  {
        const details = this.state.assetCardDetails
        return (<Ax>
            <div className="row asset_title m0">
                <div className="col-12 tap_border_left">
                    <AssetHeader assetName={details?.asset_name}  close={this.closeMinimizeTable} parentProps={this.props}/>
                </div>
                {this.state.scrollDown === true ? <div className="container-fluid pl5 p4">
                    <div className="bg-white asset_card_container asset_card_scroll_container" style={{marginTop : "12px"}}  >
                        <AssetHorizontalCard assetDetails={this.state.assetCardDetails}/>
                    </div>
                </div> : null }
                <div className="container-fluid pl5 pt0 ">
                    <div className="  " style={{marginTop : this.state.scrollDown === true ? "2px" : "14px"}}>
                        {this.state.scrollDown === false ? <div className="asset_card_container">
                                <div className="asset_card_tag">
                                    <button type="button" className="btn_action_collapse btn-collapse"   onClick={this.showScrollButton.bind(this)}  >
                                        Asset Card
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretDown} className="img-fluid ml5"   alt="item-action" />
                                    </button>
                                </div>
                            </div>:
                            <div className="asset_card_tag_up">
                                <button type="button" className="btn_action_collapse btn-collapse"   data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={this.hideScrollButton.bind(this)} >
                                    Asset Card
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretUp} className="img-fluid ml5"   alt="item-action" />
                                </button>
                            </div>}
                        <div className="asset_title_header row " style={{marginTop : this.state.scrollDown === true ? "2px" : "14px"}}>
                        <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                            {
                                this.state.minimizeTable ?
                                    <div className="row ">
                                        <div className="col-8"><h3>Fuel FeedIn List</h3></div>
                                        <div className=" col-4   text-end  ">
                                            <button type="button" id='actionMenuButton' className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                              {/*  <li><a className="dropdown-item" role="button"  onClick={this.activityExceldownload}>
                                                    Download
                                                </a></li>*/}
                                            </ul>
                                        </div>

                                    </div> :
                                    <div className="row ">
                                        <div className="col-5"><h3>Fuel FeedIn List</h3></div>
                                        <div className=" col-7 text-end ">
                                            <button type="button" className="btn btn-secondary">
                                                <tapIcon.FontAwesomeIcon icon={tapIcon.SearchIcon} className="img-fluid mr3" alt="item-action"/>
                                                Search</button>
                                            <button type="button" id='actionMenuButton' className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                {/*<li><a className="dropdown-item" role="button"  onClick={this.activityExceldownload}>
                                                    Download
                                                </a></li>*/}
                                            </ul>
                                            <button  type="button" className="btn btn-secondary"  onClick={this.linkToAssetProfile}>
                                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/></button>
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            this.state.minimizeTable
                                ? (
                                    <div className="col-9 tap_border_left">
                                        <h3>name</h3>
                                        <div className="text-end">
                                            <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                            </button>
                                        </div>
                                    </div>
                                )
                                : null
                        }
                    </div>
                    </div>
                    <div className="action_containt row">
                        <div   style={{height:this.state.scrollDown === true ? "49vh" : "75vh"}} className={['action_Tbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.feedInListingTable()}</div>
                        { this.state.minimizeTable ? <div className="col-9 pr0 pl0">View</div> : null }
                    </div>
                </div>
            </div>
        </Ax>);
    }

    feedInListingTable                   =   ()  =>  {
     // let feedIn      =     this.state.feedInListing;
        return (
            <Ax>
                <table className="table table-bordered bg-white table-hover table-fixed">
                                <thead className="table-secondary">
                                { this.state.minimizeTable
                                    ? (<tr>
                                        <th scope="col" style={{width:"20%"}}>Transaction ID (Date , Executed Qty)</th>
                                    </tr> )
                                    :   ( <tr>
                                        <th scope="col" style={{width:"5%"}} className="text-center">S.No</th>
                                        <th scope="col" style={{width:"15%"}}>Transaction ID</th>
                                        <th scope="col" style={{width:"10%"}}>Transaction Date</th>
                                        <th scope="col" style={{width:"10%"}}>Site</th>
                                        <th scope="col" style={{width:"10%"}}>Fuel Type</th>
                                        <th scope="col" style={{width:"10%"}}>Executed Qty</th>
                                        <th scope="col" style={{width:"10%"}}>Amount</th>
                                        <th scope="col" style={{width:"15%"}}>Counterlog Reading</th>
                                        <th scope="col" style={{width:"10%"}}>Status</th>
                                        <th scope="col" style={{width:"5%"}}>Action</th>
                                    </tr> )
                                }

                                </thead>
                                <tbody className={ this.state.minimizeTable ? "minimize_body" : "" } >
                                { this.state.listing_loading
                                    ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                                    : this.state.feedInListing.map((item,index) => {
                                        return<tr key={index}>
                                            {
                                                this.state.minimizeTable
                                                    ?   (
                                                        <Ax>
                                                            <td style={{width:"25%"}}><a role="button" className="link-primary"  >
                                                                {item?.feedin_id}</a>
                                                                <br/><small>{item?.feed_datetime}</small>
                                                                <br/><small>{item?.executed_qty_display}</small>
                                                            </td>

                                                        </Ax>
                                                    )
                                                    :   (<Ax>
                                                            <td>{index + 1}</td>
                                                            <td><a role="button" className="link-primary">{item?.feedin_id}</a></td>
                                                            <td>{item?.feed_datetime}</td>
                                                            <td>{item?.feed_site}</td>
                                                            <td>{item?.fuel_type}</td>
                                                            <td className="text-end">{item?.executed_qty_display}</td>
                                                            <td className="text-end">{item?.executed_amount}</td>
                                                            <td> {item?.hour_reading ? item.hour_reading  : '' } <br/>
                                                                {item?.odo_reading  ?  item.odo_reading : ''}<br/>
                                                                {item?.opc_reading  ?  item.opc_reading : ''}<br/>
                                                            </td>
                                                            <td> <tapIcon.FontAwesomeIcon icon={tapIcon.faCircle}  style={{color:`${item?.status_color_code}`}} className="mr2" />
                                                                {item?.status_name}</td>
                                                            <td>
                                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a className="dropdown-item" role="button" >Edit Entry</a></li>
                                                                </ul>
                                                            </td>

                                                        </Ax>
                                                    )}

                                        </tr>})}
                                </tbody>
                            </table>
                <div className="row m0">
                                <div className="col-md-6">{ this.state.listing_loading ?  null : <span>{this.state.listingMeta ?
                                    `Showing ${this.state.listingMeta.from||0} to ${this.state.listingMeta.to||0} of
                     ${this.state.listingMeta.total} entries` : ``}</span>}</div>
                                <div className="col-md-6">
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        pageCount={this.state.listingMeta?.last_page ?? 0}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={0}
                                        onPageActive={this.state.listingMeta?.current_page ?? 1}
                                        onPageChange={(e) => this.handlePageClick(e)}
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination justify-content-end"
                                        pageClassName="page-item"
                                        nextClassName="page-item"
                                        previousClassName="page-item"
                                        pageLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        activeClassName="active"
                                    />
                                </div>
                            </div>
            </Ax>
        );
    }

    feedInViewJsx = () => {
         return (<div className={[this.state.minimizeTable ? 'col-9 pl0 tap_border_left' : 'view_data']}>
                        <div className="asset_title  sticky_asset">
                            <div className="row ">
                                <div className="col-8"><h4>View</h4></div>
                                <div className=" col-4 text-end mt15 ">
                                    <button  type="button" className="btn btn-secondary " >
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/></button>
                                    <button  type="button" className="btn btn-secondary"  style={{marginRight:"50px"}} onClick={() => this.closeViewDetails()}>
                                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/></button>
                                </div>
                            </div>
                        </div>
                        <section className="item_view_container mt-2 bg-white">
                            <ul className="nav nav-tabs nav_sticky" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home"
                                            type="button" role="tab" aria-controls="dashboard" aria-selected="true">Details
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane show active" id="home" role="tabpanel"
                                     aria-labelledby="dashboard-tab">
                                    <div className="tab_content_header">
                                     {/*   <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Service CheckSheet Details</span></div>*/}
                                       {/* <table className="table mt-2 table-hover table-borderless">
                                            <tbody>
                                            <tr>
                                                <td className="details-label" style={{"width": "34%"}}>Service CheckSheet Name</td>
                                                <td className="details-name" style={{"width": "66%"}}>{viewHistory?.settingData?.alias_name ? viewHistory?.settingData?.alias_name : viewHistory?.settingData?.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">SCS Description</td>
                                                <td className="details-name">{viewHistory?.settingData?.description ? viewHistory?.settingData?.description : '-'}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Section</td>
                                                <td className="details-name">{viewHistory?.settingData?.component}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">SubSection</td>
                                                <td className="details-name">{viewHistory?.settingData?.subcomponent}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Parameter</td>
                                                <td className="details-name">{viewHistory?.enable_parameter == "Y" && viewHistory?.parameter ? viewHistory?.parameter?.type_display : '-' }
                                                    {viewHistory?.enable_parameter == "Y" && viewHistory?.parameter && viewHistory?.parameter?.critical_level ? <div><br/> Critical Level : {viewHistory?.parameter?.critical_level} </div> : '' }</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Executed Parameter</td>
                                                <td className="details-name">{viewHistory?.parameter && viewHistory?.parameter?.execute_display && viewHistory?.parameter?.execute_display.text ? <span> <tapIcon.FontAwesomeIcon icon={tapIcon.faCircle} style={{color:`${viewHistory?.parameter?.execute_display.color}`}} />
                                                        {viewHistory?.parameter?.execute_display.text}</span>
                                                    : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Counter Log</td>
                                                <td className="details-name"> {viewHistory?.counterlog_display !== null ? viewHistory?.counterlog_display?.map((item,index) =>{return(<div className="mt-1"> {item} <br/></div>)})
                                                    :  "-"}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Service CheckSheet Execution Interval</span></div>
                                        <table className="table table-hover table-bordered mt-2">
                                            <tbody>
                                            <tr>
                                                <td className="details-label" style={{"width": "11"}}></td>
                                                <td className="details-label" style={{"width": "26%"}}>Execution Interval</td>
                                                <td className="details-label" style={{"width": "26%"}}>Recommended Interval</td>
                                                <td className="details-label" style={{"width": "11%"}}>Deviation</td>
                                                <td className="details-label" style={{"width": "26%"}}>Execution Percentage</td>

                                            </tr>
                                            {viewExecution && viewExecution.map((exl,index) => {
                                                return( <tr>
                                                    <td className="details-label" style={{"width": "11"}}>{exl.meter}</td>
                                                    <td className="details-label" style={{"width": "26%"}}>{exl.execution_interval}</td>
                                                    <td className="details-label" style={{"width": "26%"}}>{exl.recommended_interval}</td>
                                                    <td className="details-label" style={{"width": "11%"}}>{exl.execution_interval - exl.recommended_interval}</td>
                                                    <td className="details-label" style={{"width": "26%"}}>{exl.recommended_interval > 0 ? ((exl.execution_interval * 100 /exl.recommended_interval).toFixed(2)) : ''}</td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Transaction Details</span></div>
                                        <table className="table table-hover table-borderless">
                                            <tbody>
                                            <tr>
                                                <td className="details-label" style={{"width": "34%"}}>Transaction ID </td>
                                                <td className="details-name" style={{"width": "66%"}}>{viewHistory.transaction_id}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Transaction Date	</td>
                                                <td className="details-name">{viewHistory.feed_datetime_display}</td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Executed by</td>
                                                <td className="details-name">{viewHistory?.user?.first_name} {viewHistory?.user?.last_name}</td>
                                            </tr>
                                            </tbody>
                                        </table>*/}
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>)
    }

    render() {
        let pageScreen = null;
        if(this.state.serviceAddScreenView) {
            pageScreen  =  "Add"
        } else if(this.state.serviceUpdateScreenView) {
            pageScreen  =   "Update"
        } else {
            pageScreen  = this.feedInListjsx()
        }
        return (
            <ApplicationLayout>
                {pageScreen}
            </ApplicationLayout>
        )
    }
}





export default FeedInList;