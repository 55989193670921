import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapInputNumber from '../../../components/ui/TapInputNumber';

class RequisitionCancel extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                            =         {
            cancelledItemList                 :       [],
            cancel_item_loading               :       false,
            cancelItemFormSubmitting          :       false,
            cancelledItemForm                 :       {...this.cancelItemFormInit},
            warehouse_indent                  :       null
        };
        this.requisitionUrl             =   INVENTORY_API_BASE_URL_2 + '/requisition';
    }
    
    
    
    componentDidMount(){
        this.cancelledItemModal                     =   new Modal(document.getElementById('cancelledItemModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (cancelItemList,warehouse_indent = null)  =>  {
       
        this.setState({
            cancelledItemList          :       cancelItemList,
            warehouse_indent        :          warehouse_indent
        },() => this.cancelledItemModal.show())
    }

    submitCancelItemForm            =       (e)      =>      {
        e.preventDefault()
        
        let items                   =        [...this.state.cancelledItemList];
          
        let itemsLists              =        items.map(i => {
                                               
                                                 return { req_item_id : i.req_item_id,cancelled_qty : parseFloat(i.new_cancelled_qty),transaction_id : this.props.requisitionTransId};
                                                });
         let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists]}
          this.setState({ cancelItemFormSubmitting: true })
          HttpAPICall.withAthorization('PUT', this.requisitionUrl + '/cancel_item', this.props.access_token, {}, { ...formData }, (response) => {
              toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
              this.cancelledItemModal.hide()
              this.setState({cancelledItemForm         :       {...this.cancelItemFormInit}})
              this.props.getRequisitionDetail(this.props.requisitionTransId)
          }).then(() => this.setState({ cancelItemFormSubmitting: false }));
      }
    
    
     //***********************CANCEL ITEM JSX****************
     cancelledItemModalJsx               =        ()             =>        {
      
        return (
            <div className="modal fade" id="cancelledItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Cancel Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={() => { this.setState({ cancelledItemList: [] }) }}></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" style={{ width: "28%" }} className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "9%" }} className="text-center">Indent </th>
                                                    <th scope="col" style={{ width: "9%" }}>Req. Qty</th>
                                                    <th scope="col" style={{ width: "9%" }}>PO Qty</th>

                                                    <th scope="col" style={{ width: "9%" }}>Received</th>
                                                    <th scope="col" style={{ width: "9%" }}>Cancelled </th>
                                                    <th scope="col" style={{ width: "9%" }}>Pending </th>
                                                    <th scope="col" style={{ width: "9%" }}>New Cancelled </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td>{i.item ? <div><span className="fw-bold">{i.item.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.item?.manufacturer?.name ?? '-'} (MPN : {i.item?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.item?.tags?.length > 0
                                                                    ? (i.item?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td className='text-center'>{i.indent_data ? <span>{this.state.warehouse_indent?.transaction_id} : {i.indent_data.indent_need_qty}</span> : "-"}</td>
                                                        <td className='text-end'>{i.qty ?? "-"}</td>

                                                        <td className="text-end">{i.current_po_qty ?? "-"}</td>
                                                        {/* <td className="text-end">{i.intransit_qty ?? "-"}</td> */}
                                                        <td className="text-end">{i.received_qty ?? "-"}</td>
                                                        <td className="text-end">{i.cancelled_qty ?? "-"}</td>
                                                        <td className='text-end' >
                                                            {i.pending_qty ?? "-"}</td>
                                                        <td className="text-end">
                                                        <TapInputNumber
                                                            name="new_cancelled_qty"
                                                            value={this.state.cancelledItemList[k]?.new_cancelled_qty}
                                                            onChange={(newValue) => {
                                                                const updatedRows = [...this.state.cancelledItemList];
                                                                updatedRows[k].new_cancelled_qty = newValue; // Update the quantity in the corresponding row
                                                                this.setState({ cancelledItemList: updatedRows });
                                                            }}
                                                            placeholder="Cancelled Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                        />
                                                            </td>




                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (<Ax>{this.cancelledItemModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RequisitionCancel);
