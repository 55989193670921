import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from '../../../../components/hoc/Ax';
import * as actions from '../../../../store/actions/index';
import TapIcon from '../../../../services/TapIcon';
import download from '../../../../services/DownloadFile';
import { toast } from 'react-toastify';
import axios from 'axios';

class ItemWhConfigModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                      =   {
            access_token                :   '',
            saveFormSubmitting          :   false,
            addConfigForm              :   [],
        }
        this.itemWhConfigUrl              =   INVENTORY_API_BASE_URL_2 + '/item_config/download_sample_file';
        this.bulkUploadUrl                =   INVENTORY_API_BASE_URL_2 + '/item_config/excel_bulk';
    }

    componentDidMount                   =   ()  =>  {
        this.bulkConfigModal             =   new Modal(document.getElementById('bulkUploadModal'), {keyboard: false, backdrop :false});
    }

    showModal                           =   ()  =>  {
        this.bulkConfigModal.show();
    }

    bulkUploadFormSubmit                =   (e) =>  {
        e.preventDefault();
        this.setState({saveFormSubmitting : true});
        const data          =   new FormData(document.getElementById('itemWhBulkUploadForm'));
        const headers       =   { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data','Authorization' : 'Bearer ' + this.props.access_token };

        axios.post(this.bulkUploadUrl, data, { headers: headers })
        .then((res) => {
            toast.success(res.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.bulkConfigModal.hide();
            this.props.afterExcelUpload();
            this.setState({saveFormSubmitting : false});
            document.getElementById('itemWhBulkUploadForm').reset();
        }).catch(err=> this.setState({saveFormSubmitting : false}))
    }  

    downloadSampleFile                  =   (e) => {
        HttpAPICall.withAthorization('GET', this.itemWhConfigUrl, this.props.access_token,
        {} , {} , (res) => 
        {   download.file(res.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
            this.bulkConfigModal.hide();
        })
    }
    
    render                          =   ()  =>  {
        return (
          <Ax>
            <div className="modal fade" id="bulkUploadModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="itemWhBulkUploadModalLabel">Item Warehouse Config Bulk Upload</h5>
                    <button
                      type="button"
                      disabled={this.state.saveFormSubmitting}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <form
                    onSubmit={this.bulkUploadFormSubmit}
                    id="itemWhBulkUploadForm"
                    method="post"
                    encType="multipart/form-data"
                  >
                    <div className="modal-body">
                      <div className="row align-items-center mb-3 mt-3">
                        <div className="col-12">
                          <input
                            name="document"
                            id="addUserFileSelector"
                            type="file"
                            className="form-control"
                            required={true}
                          />
                        </div>
                        <div className="col-12 mt-2 form-text">
                          # Excel Sheet with Extention xls,xlm,xlsx,ods Allowed
                        </div>
                        <div className="col-12 mt-2 form-text">
                          # Miximum 100 MB Size of Excel Sheet Allowed
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                      <div>
                        <a
                          type="button"
                          className="float-left"
                          href="#"
                          onClick={this.downloadSampleFile}
                        >
                          Download Sample File
                        </a>
                      </div>
                      <div>
                        <button
                          type="button"
                          disabled={this.state.saveFormSubmitting}
                          className="btn btn-secondary mx-2"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          disabled={this.state.saveFormSubmitting}
                          className="btn btn-primary"
                        >
                          Submit{" "}
                          {this.state.saveFormSubmitting ? (
                            <TapIcon.FontAwesomeIcon
                              icon={TapIcon.faSyncAlt}
                              className="fa-spin"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Ax>
        );
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token,false)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ItemWhConfigModal);