import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import CreatableSelect from "react-select/creatable";
import TapIcon from "../../../services/TapIcon";

class EmployeeAdd extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.initAddEmployeeForm = {
      fname: "",
      lname: "",
      mobile: "",
      email: "",
      site_id: "",
      employee_code: "",
      tags: [],
      asset_type_ids: [],
      site_since_date: "",
    };
    this.state = {
      saveFormSubmitting: false,
      addEmployeeForm: { ...this.initAddEmployeeForm },
      iam_user_sites: [],
      iam_asset_types: [],
      allTags: [],
      employeeData: null,
    };

    this.emp_add_form_data_url = HRM_API_BASE_URL_2 + '/employee/add_form_data'; 
    this.emp_add_url = HRM_API_BASE_URL_2 + '/employee/add'; 

  }

  componentDidMount() {
    this.initalizeFormData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.iam_user_sites !== this.props.iam_user_sites) {
      this.initalizeFormData(nextProps);
    }
  }

  initalizeFormData = (pr) => {
    this.setState({ form_data_loading: true });
    HttpAPICall.withAthorization("GET", this.emp_add_form_data_url, this.props.access_token,
      null, null, (response) => {
        let respData = response.data;
        this.setState(
          {
            iam_user_sites: pr.iam_user_sites
              ? pr.iam_user_sites.map((s) => {
                  return {
                    value: s.id,
                    label: `${s.site_name} (${s.site_code})`,
                  };
                })
              : [],
            iam_asset_types:
              respData.asset_types && respData.asset_types.length > 0
                ? respData.asset_types.map((at) => {
                    return { value: at.id, label: at.asset_type_name };
                  })
                : [],
            allTags:
              respData.tags && respData.tags.length > 0
                ? respData.tags.map((tag) => {
                    return { value: tag, label: tag };
                  })
                : [],
          },
          () => {}
        );
      }
    ).then(() => this.setState({ form_data_loading: false }));
  };

  closeEvent = () => {
    this.props.history.push({ pathname: `/employee_list` });
  };

  handleTagCreate = (inputText) => {
    const newValue = { value: inputText, label: inputText };
    this.setState({ allTags: [...this.state.allTags, newValue] });
    let obj = {};
    obj["value"] = newValue.value;
    obj["label"] = newValue.label;

    this.state.addEmployeeForm.tags.push(obj);
  };

  submitAddEmployeeForm = (e) => {
    e.preventDefault();

    let frmData = {
      ...this.state.addEmployeeForm,
      tags:
        this.state.addEmployeeForm.tags &&
        this.state.addEmployeeForm.tags.length > 0
          ? this.state.addEmployeeForm.tags.map((tp) => tp.value)
          : [],
    };

    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization("POST", this.emp_add_url, this.props.access_token,
      {}, { ...frmData }, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ addEmployeeForm: { ...this.initAddemployeeForm } });
        this.props.history.push("/employee_list");
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  saveEmployeeFormJsx = () => {
    return (
      <form
        className="bg-white p-3"
        onSubmit={this.submitAddEmployeeForm}
        id="saveEmployeeForm"
      >
        <div className="tab_content_wrapper">
          <span className="content_heading">Detail</span>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="fname" className="form-label require">
              First Name
            </label>
          </div>
          <div className="col-sm-4 ">
            <input
              name="fname"
              type="text"
              value={this.state.addEmployeeForm.fname}
              className="form-control"
              autoComplete="off"
              required={true}
              onChange={(e) => this.formInputHandler(e, "addEmployeeForm")}
              placeholder="Please enter First Name"
            />
          </div>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="lname" className="form-label">
              Last Name
            </label>
          </div>
          <div className="col-sm-4 ">
            <input
              name="lname"
              type="text"
              value={this.state.addEmployeeForm.lname}
              className="form-control"
              autoComplete="off"
              onChange={(e) => this.formInputHandler(e, "addEmployeeForm")}
              placeholder="Please enter Last Name"
            />
          </div>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="employee_code" className="form-label">
              Employee Code
            </label>
          </div>
          <div className="col-sm-4 ">
            <input
              name="employee_code"
              type="text"
              value={this.state.addEmployeeForm.employee_code}
              className="form-control"
              autoComplete="off"
              onChange={(e) => this.formInputHandler(e, "addEmployeeForm")}
              placeholder="Please enter Employee Code"
            />
          </div>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label">
              Mobile Number
            </label>
          </div>
          <div className="col-sm-4 ">
            <input
              name="mobile"
              type="number"
              value={this.state.addEmployeeForm.mobile}
              className="form-control"
              autoComplete="off"
              onChange={(e) => this.formInputHandler(e, "addEmployeeForm")}
              placeholder="Please enter Mobile Number"
            />
          </div>
        </div>

        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label">
              Email
            </label>
          </div>
          <div className="col-sm-4 ">
            <input
              name="email"
              type="email"
              value={this.state.addEmployeeForm.email}
              className="form-control"
              autoComplete="off"
              onChange={(e) => this.formInputHandler(e, "addEmployeeForm")}
              placeholder="Please enter Email"
            />
          </div>
        </div>

        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label">
              Tags
            </label>
          </div>
          <div className="col-sm-4 ">
            <CreatableSelect
              isMulti
              value={this.state.addEmployeeForm.tags}
              options={this.state.allTags}
              onChange={(selectedOption) =>
                this.setState({
                  addEmployeeForm: {
                    ...this.state.addEmployeeForm,
                    tags: selectedOption,
                  },
                })
              }
              onCreateOption={(inputText, callback) =>
                this.handleTagCreate(inputText, callback)
              }
            />
            {/* <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addEmployeeForm', 'tags')}
                        options={this.state.allTags}
                        isSearchable={true}
                        isClearable={true}
                        menuPlacement="top"
                        isMulti={true}
                        value={this.state.allTags.filter(s => this.state.addEmployeeForm?.tags?.includes(s.value))}
                        placeholder={ "Please Select Tags"}
                    /> */}
          </div>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label">
              Asset Types
            </label>
          </div>
          <div className="col-sm-4 ">
            <TapSelect
              options={this.state.iam_asset_types}
              changeEvent={(selectedOption) =>
                this.tapSelectChange(
                  selectedOption,
                  "addEmployeeForm",
                  "asset_type_ids"
                )
              }
              isSearchable={true}
              isClearable={true}
              isMulti={true}
              value={this.state.iam_asset_types.filter((s) =>
                this.state.addEmployeeForm?.asset_type_ids?.includes(s.value)
              )}
              placeholder="Select Asset Type"
            />
          </div>
        </div>

        <div className="tab_content_wrapper">
          <span className="content_heading">Site Transition</span>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require">
              Site
            </label>
          </div>
          <div className="col-sm-4 ">
            <TapSelect
              changeEvent={(selectedOption) =>
                this.tapSelectChange(
                  selectedOption,
                  "addEmployeeForm",
                  "site_id"
                )
              }
              options={this.state.iam_user_sites}
              isSearchable={true}
              isClearable={true}
              required={true}
              menuPlacement="top"
              value={this.state.iam_user_sites.find(
                (s) => this.state.addEmployeeForm.site_id == s.value
              )}
              placeholder={"Please Select Site"}
            />
          </div>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-sm-3">
            <label htmlFor="site_id" className="form-label require">
              Date of Transfer
            </label>
          </div>
          <div className="col-sm-4 add_calender_section">
            <DatePicker
              selected={
                this.state.addEmployeeForm.site_since_date
                  ? moment(this.state.addEmployeeForm.site_since_date).toDate()
                  : false
              }
              name="site_since_date"
              onChange={(value, event) => {
                this.formDateHandler(
                  "site_since_date",
                  value,
                  "addEmployeeForm",
                  "YYYY-MM-DD HH:mm:ss"
                );
              }}
              dateFormat="dd-MMM-yyyy hh:mm a"
              className={"form-control"}
              showMonthDropdown
              showYearDropdown
              showTimeSelect
              timeIntervals={5}
              timeCaption="Time"
              autoComplete="off"
              // popperPlacement="right-start"
              scrollMonthYearDropdown
              required={true}
              maxDate={new Date()}
              placeholderText={`Please Enter Date of Transfer`}
            />
            <TapIcon.imageIcon
              icon={TapIcon.CalenderIcon}
              alt="requisition_date"
              className="add_calender-icon"
            />
          </div>
        </div>

        <div className="col-12 text-end fixed_footer">
          <button
            type="button"
            disabled={this.state.saveFormSubmitting ? true : false}
            onClick={this.closeEvent}
            className="btn btn-secondary mx-3"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={this.state.saveFormSubmitting ? true : false}
            className="btn btn-primary mx-2"
          >
            Save{" "}
            {this.state.saveFormSubmitting ? (
              <tapIcon.FontAwesomeIcon
                icon={tapIcon.faSyncAlt}
                className="fa-spin"
              />
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Employee Add</title>
        </Helmet>
        <div className="page_title row m0">
          <div className="col-12">
            <h3>Add New Employee</h3>
            <div className="text-end mt15">
              <button
                onClick={this.closeEvent}
                disabled={this.state.saveFormSubmitting ? true : false}
                type="button"
                className="btn btn-secondary"
              >
                <tapIcon.imageIcon
                  icon={tapIcon.CloseIcon}
                  className="img-fluid"
                  alt="Close  Employee"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="page_containt row pl2">
            <div className="pageTbl col-12 pl4">
              {this.state.form_data_loading ? (
                <Loader />
              ) : (
                this.saveEmployeeFormJsx()
              )}
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
  };
};

export default connect(mapStateToProps)(EmployeeAdd);
