import moment from 'moment';
import React from 'react';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';

function CounterlogExecuteMeterForm({ assetDetail, executeForms, onInputChange, showDateTime = true, showCustomDateTime = false, dateLabel, disableInput = false, shiftDetail = null }) {
    const meterCounterlogMapping = {
        hour_meter: 'hours_abs',
        hour_meter2: 'hours_abs2',
        hour_meter3: 'hours_abs3',
        hour_meter4: 'hours_abs4',
        odo_meter: 'odometer_abs',
        odo_meter2: 'odometer_abs2',
        odo_meter3: 'odometer_abs3',
        operation_cycle: 'cycle_abs',
        operation_cycle2: 'cycle_abs2'
    };

    let max_time = new Date();
    let min_time = new Date();

    if (shiftDetail) {
        if (shiftDetail.shift_start === shiftDetail.shift_end) {
            // Shift starts and ends at the same time, set max_time to the end of the day
            max_time.setHours(23, 59, 0, 0);
            
        } else {
            // Set max_time based on shift_end
            max_time = moment(shiftDetail.shift_end, "hh:mm a").toDate();
            
        }

        if (shiftDetail.shift_start) {
            // Set min_time based on shift_start
            const [hours, minutes] = shiftDetail.shift_start.toUpperCase().replace(".", "").split(" ")[0].split(":");
            min_time.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        }
    }

    return (
        <div className='counterlog'>
            <div className="tab_content_wrapper">
                <span className="content_heading">Counterlog</span>
            </div>
            {showCustomDateTime && <div className="row my-3 ">
                <label className="col-sm-3 col-form-label col-form-label-sm">{dateLabel}</label>
                <div className="col-sm-4">
                    <DatePicker
                        selected={
                            executeForms.date_time
                                ? moment(executeForms.date_time).toDate()
                                : null
                        }
                        name="date_time"
                        onChange={(value, event) => {
                            onInputChange('date_time', value)
                        }}
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Due Date`}
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        required={true}
                        popperPlacement="right-start"
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                    />
                    {/* <TapIcon.imageIcon icon={TapIcon.CalenderIcon}
                        alt="requisition_date"
                        className="add_calender-icon" /> */}
                </div>
                <label className="col-sm-5 form-text ">
                    {assetDetail && assetDetail.latest_counterlog
                        ? <div>Latest Reading : {assetDetail?.latest_counterlog?.feed_date_time_display}</div>
                        : null}
                </label>
            </div>}
            {Object.keys(assetDetail).map((key, index) => {
                if ((key.startsWith('hour_meter') && assetDetail[key] === 'yes') || (key.startsWith('odo_meter') && assetDetail[key] === 'yes') || (key.startsWith('operation_cycle') && assetDetail[key] === 'yes')) {
                    const label = assetDetail.labels[key];
                    const inputName = key && key.startsWith('operation_cycle') ? key : key.replace('_', '');
                    const latestReadingKey = meterCounterlogMapping[key];
                    return (
                        <div key={key} className="row my-3 align-items-center">
                            <label className="col-sm-3 col-form-label col-form-label-sm">{label}</label>
                            <div className="col-sm-4">
                                <input
                                    name={inputName}
                                    type="number"
                                    value={executeForms[inputName]}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder={`Input in ${label}`}
                                    onChange={(e) => onInputChange(inputName, e.target.value)}
                                    style={{ height: "30px", fontSize: "93%" }}
                                    required={true}
                                    disabled={disableInput}
                                />
                            </div>
                            {/* Assuming latestCounterlog contains relevant data for each meter */}
                            <label className="col-sm-5 form-text">
                                {assetDetail && assetDetail.latest_counterlog && assetDetail.latest_counterlog[latestReadingKey]
                                    ? <div>Latest Reading : {assetDetail.latest_counterlog[latestReadingKey]}</div>
                                    : <div>No Counterlog</div>}
                            </label>
                        </div>
                    );
                }
                return null; // Render nothing for non-hour meter, odometer, and operation cycle properties
            })}
            {showDateTime && <div className="row my-3 ">
                <label className="col-sm-3 col-form-label col-form-label-sm">Counterlog Date</label>
                <div className="col-sm-4">
                    <DatePicker
                        selected={
                            executeForms.date_time
                                ? moment(executeForms.date_time).toDate()
                                : null
                        }
                        name="date_time"
                        onChange={(value, event) => {
                            onInputChange('date_time', moment(value).format('YYYY-MM-DD HH:mm:ss '))
                        }}
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Due Date`}
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        required={true}
                        popperPlacement="right-start"
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        disabled={disableInput}
                        minDate={shiftDetail ? moment(shiftDetail.feed_datetime, 'YYYY-MM-DD').toDate() : moment(new Date(), 'YYYY-MM-DD').toDate()}
                        maxDate={shiftDetail ? moment(shiftDetail.feed_datetime, 'YYYY-MM-DD').toDate() : moment(new Date(), 'YYYY-MM-DD').toDate()}
                        minTime={min_time}
                        maxTime={max_time}
                    />
                    {/* <TapIcon.imageIcon icon={TapIcon.CalenderIcon}
                        alt="requisition_date"
                        is
                        className="add_calender-icon" /> */}
                </div>
                <label className="col-sm-5 form-text ">
                    {assetDetail && assetDetail.latest_counterlog
                        ? <div>Latest Reading : {assetDetail?.latest_counterlog?.feed_date_time_display}</div>
                        : null}
                </label>
            </div>}
        </div>
    );
}

export default CounterlogExecuteMeterForm;
