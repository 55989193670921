import React from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import uuid from "react-uuid";
import { Modal } from "bootstrap";
import Ax from "../../../../components/hoc/Ax";
import AdditionalAttribute from '../../AdditionalAttribute';
import {ToastContainer, toast} from 'react-toastify';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import TapApiUrls from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import Loader from '../../../../components/ui/Loader/Loader';
import TapIcon from '../../../../services/TapIcon';
import swal from 'sweetalert';
import {SketchPicker} from 'react-color';
import Status from '../../../../components/ui/Status';

class SubAssemblyDowntimeTab extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.formDataInit   =   {
            name                            :   '',
            color_code                      :   '#2A974E',
        }
        this.state                      =   {
            form_loaded                     :   false,
            formData                        :   {...this.formDataInit},
            edit                            :   false,
            addStatusFormSubmitting         :   false,

            loading                         :   false,
            dataList                        :   [],
            displayColorPicker              :   false,
            allColorCodes                   :   [],

        }

        this.subAssemblyUrl        =   TapApiUrls.IAM + '/' + 'sub_assembly_' + this.props.url; 

        this.modalId          =   uuid();
    }

    componentDidMount               =   ()  =>  {

        this.modal         =    new Modal(document.getElementById(this.modalId), {keyboard: false, backdrop :false});

        this.getList();
    }

    getList = () => {
        this.setState({ loading : true});
        HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/list' , this.props.access_token,{},{},(response) => {
            this.setState({dataList: response.data})
         }).then(() => this.setState({loading : false}));
    }

    modalInit = (edit = false, name = null, id = null, color_code = null) => {

        if(edit) {
            this.setState({
                formData : {...this.state.formData, name, id, color_code}
            });
        }
        this.setState({edit}, () => {
            this.modal.show();
        })
    }

    submitForm  =   (e) => {
        e.preventDefault()

        let frmData = {...this.state.formData}

        this.setState({addStatusFormSubmitting: true})
           
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', this.subAssemblyUrl + '/edit', this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.modal.hide();
                this.getList()
                this.setState({formData  :  {...this.formDataInit}})
            }).then(() => this.setState({addStatusFormSubmitting: false}))
        }else{
            HttpAPICall.withAthorization('POST', this.subAssemblyUrl + '/add', this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.modal.hide();
                this.getList();
                this.setState({formData  :  {...this.formDataInit}})
            }).then(() => this.setState({addStatusFormSubmitting: false}))
        }
    }

    deleteStatus    =   (id) => {
        swal({
            title: "DELETE",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE',this.subAssemblyUrl + '/delete/' + id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getList()
                     }).then(() => {

                    });
                }
            });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    handleColorChange   =   (colorHex)  =>  {
        if(colorHex){
            this.setState({formData : {...this.state.formData, color_code : colorHex}})
        }
    }

    modalJsx              =   ()  =>  {
        const {type}    =   {...this.props};
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <div className="modal fade" id={this.modalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalLabel">{this.state.edit ? "Edit" : "Add"} {type}</h5>
                        </div>
                        <form onSubmit={this.submitForm} id="addForm" method="post">
                            <div className="modal-body">
                                {this.state.form_loaded ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">{type} Name</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => { this.formInputHandler(e, 'formData') }}
                                                    required={true}
                                                    value={this.state.formData.name}
                                                    placeholder={`Please Enter ${type} Name`}
                                                />
                                            </div>
                                        </div>
                                        {
                                            type === 'Status'   &&
                                            <Ax>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-3">
                                                    <label htmlFor="transaction Type" className="form-label require">Color Code</label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="input-group input-group-sm">
                                                        <span className="input-group-text px-4 me-3"
                                                        onClick={this.handleClick}
                                                        style={{ background: this.state.formData.color_code }}>
                                                        </span>
                                                        <input
                                                            name="color_code"
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            onChange={(e) => { this.formInputHandler(e, 'formData') }}
                                                            required={true}
                                                            value={this.state.formData.color_code}
                                                            placeholder={`Please Enter Color Code`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <button type="button" onClick={this.handleClick} className="btn btn-link text-sm">Pick Color</button>
                                                    {
                                                        this.state.displayColorPicker 
                                                        ? 
                                                        <div style={popover}>
                                                            <div style={cover} onClick={this.handleClose} />
                                                            <SketchPicker
                                                            color={this.state.formData.color_code}
                                                            // presetColors={this.state.allColorCodes}
                                                            onChangeComplete={(color) => this.handleColorChange(color.hex)} />
                                                        </div> 
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            </Ax>
                                        }
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addStatusFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ formData: { ...this.formDataInit } }) }}>Close</button>
                                <button type="submit" disabled={this.state.addStatusFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addStatusFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }



    render                          =   ()  =>  {
        const {type}    =   {...this.props};
        return (
            <Ax>
                <div className="tab_content_wrapper mb-2">
                    <span className="content_heading">{type}</span>
                    <div className="float-end"><button className="btn btn-primary" onClick={() => this.modalInit(false)}>Add {type}</button></div>
                </div>
                <table className="table table-hover table-bordered">
                    <thead className="table-secondary">
                        <tr className="text-center">
                            <th style={{ width: "5%" }}>S.No</th>
                            <th className="text-start">Name</th>
                            {
                                type === "Status" && 
                                <th className="text-center" style={{ width: "20%" }}>Color Code</th>
                            }
                            <th style={{ width: "10%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.loading 
                    ?   <tr><td className="text-center" colSpan="4"><Loader /></td></tr>
                    :
                    this.state.dataList && this.state.dataList.length > 0 && this.state.dataList.map((data, i) => {
                        return (
                            <tr>
                                <td className="text-center">{i+1}</td>
                                <td className="text-start">{data.name}</td>
                                {
                                    type === "Status" && 
                                    <td className="text-center">
                                    <Status color={data.color_code ? data.color_code : '-'}></Status>
                                    </td>
                                }
                                <td className="text-center">
                                    <button type="button" className="btn btn-light" data-bs-toggle="dropdown" role="button" aria-expanded="false" disabled={data.master_id ? false : true}>
                                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} className="img-fluid" alt="action-icon" />
                                     </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link role="button" className='dropdown-item cursor_pointer' style={{ "pointerEvents": "all" }} onClick={() => this.modalInit(true, data.name, data.id, data?.color_code)}>Edit</Link>
                                            <Link role="button" className='dropdown-item cursor_pointer' style={{ "pointerEvents": "all" }} onClick={() => this.deleteStatus(data.id)}>Delete</Link>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
                {this.modalJsx()} 
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(SubAssemblyDowntimeTab);