import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import TapSelect from "../../../../components/ui/TapSelect";
import {toast} from "react-toastify";
import { IAM_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
 
class AutoLinking extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initAutoLinkingFormData    =   {
            site_id                         :   '',
            site_name                       :   '',
            user_ids                        :   [],
            status                          :   1
        };
        
        this.state                              =   {
            loading                                 :   false,
            editFormLoading                         :   false,
            editFormSubmiting                       :   false,
            sitesLinkedUsersData                    :   [],
            autoLinkingFormData                     :   {...this.initAutoLinkingFormData},
            siteUsers                               :   []
        };
    }
    
    componentDidMount() {
        this.getAutoLinkingListing();
        this.editAutoLinking            =        new Modal(document.getElementById('addAutoLinkingModal'), {keyboard: false, backdrop :false});
    }
    
    //functions for tab auto-Linking
    getAutoLinkingListing       =      ()   =>     {
        this.setState({loading : true})
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/ticket/configuration/auto_linked_user', this.props.access_token , {}, {}, (resp) => {
            this.setState({sitesLinkedUsersData  : resp.data.data})
        }).then(() => this.setState({loading: false}));
    }
    
    editSiteAutoLinkingInit             =   (tap_site_id)  =>  {
        let autoLinkingData                 =   this.state.sitesLinkedUsersData.find(al => al.tap_site_id == tap_site_id );
        if(autoLinkingData) {
            this.editSiteAutoLinkingFormLoad(autoLinkingData);
        }
    }
    
    editSiteAutoLinkingFormLoad             =   (autoLinkingData)  =>  {
        this.editAutoLinking.show();
        this.setState({
            editFormLoading             :   true,
            siteUsers                   :   [],
            autoLinkingFormData         :   {
                ...this.state.autoLinkingFormData,
                site_id                     :   autoLinkingData.site_id,
                site_name                   :   autoLinkingData.site_name,
                status                      :   autoLinkingData.status,
                user_ids                    :   autoLinkingData.linked_users.map(u => u.tap_id)
            }
        });
        let url                             =   IAM_API_BASE_URL_2 + '/ticket/configuration/auto_linking_form_data/' + autoLinkingData.site_id;
        HttpAPICall.withAthorization('GET', url, this.props.access_token , {}, {}, (response) => {
            let users               =   response.data.users.map(u => {
                                            return { value: u.tap_id, label: u.full_name, id : u.id};
                                        });
            this.setState({ siteUsers  : users});
        }, (error) => {
            
        }).then(() => {
            this.setState({editFormLoading : false});
        });
    }
    
    autoLinkingFormSubmit                   =   (event) =>  {
        event.preventDefault();
        this.setState({editFormSubmiting : true});
        
        let data = {
            ...this.state.autoLinkingFormData,
            user_ids : this.state.autoLinkingFormData.user_ids
                            .filter(tap_id => this.state.siteUsers.find(su => su.value == tap_id))
                            .map(tap_id => (this.state.siteUsers.find(su => su.value == tap_id)).id)
        } ;
        
        
        HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/ticket/configuration/auto_linking', this.props.access_token, {} , data , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editAutoLinking.hide();
            this.getAutoLinkingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }
    
    editAutoLinkingModalJsx                 =   ()  =>  {
        let autoLinkingFormData                 =   this.state.autoLinkingFormData;
        return (
            <div className="modal fade" id="addAutoLinkingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit Auto Linking</h5>
                            <button type="button" disabled={this.state.editFormSubmiting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.autoLinkingFormSubmit}>
                            {this.state.editFormLoading 
                                ? <Loader />
                                : (<Ax>
                                    <div className="modal-body">
                                            <div className="row my-3 align-items-center">
                                        <div className="col-sm-3 text-end">
                                            <label className="col-form-label">Site Name</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" disabled={true} value={autoLinkingFormData ? autoLinkingFormData.site_name : ''} />
                                        </div>
                                    </div>
                                    <div className="row my-3 align-items-center">
                                        <div className="col-sm-3 text-end">
                                            <label className="col-form-label">User Name</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                placeholder="Select User"
                                                options={this.state.siteUsers}
                                                value={this.state.siteUsers.filter(su => autoLinkingFormData.user_ids.includes(su.value))}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'autoLinkingFormData', 'user_ids')}
                                            />
                                        </div>
                                        <div className="col-sm-4 text-end">
                                            <label className="form-text">when we create a ticket tagged {autoLinkingFormData ? autoLinkingFormData.site_name : ''} Site,  These user will be auto linked with the ticket.</label>
                                        </div>
                                    </div>
                                    <div className="row my-3 align-items-center">
                                    <div className="col-sm-3 text-end">
                                        <label className="col-form-label">Status</label>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="row">
                                            <div className="col-auto">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="status" value="1"
                                                        onChange={(e) => this.formInputHandler(e,"autoLinkingFormData")}
                                                        checked={this.state.autoLinkingFormData.status == 1}/> Active
                                                </label>
                                            </div>
                                            <div className="col-auto">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="status"
                                                        value="2"
                                                        onChange={(e) => this.formInputHandler(e,"autoLinkingFormData")}
                                                        checked={this.state.autoLinkingFormData.status == "2"} /> InActive 
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" disabled={this.state.editFormSubmiting} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.editFormSubmiting} className="btn btn-primary">Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : null}</button>
                                    </div>
                                </Ax>)
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    } 
    
    render                                  =   ()  =>  {
        return (<Ax>
        <table className="table table-hover table-bordered ">
            <thead>
                <tr className="table-secondary">
                    <th scope="col" style={{width: "5%"}} className="text-center">S.No</th>
                    <th scope="col" style={{width: "20%"}}>Site</th>
                    <th scope="col" style={{width: "45%"}}>User Name</th>
                    <th scope="col" style={{width: "5%"}} className="text-center">Status</th>
                    <th scope="col" style={{width: "5%"}} className="text-center">Action</th>
                </tr>
            </thead>
            <tbody>
                { this.state.loading
                    ? (<tr><td colSpan="5"><Loader/></td></tr>)
                    : (this.state.sitesLinkedUsersData.length > 0
                        ? (this.state.sitesLinkedUsersData.map((s , k) => {
                            return (<tr key={k}>
                                <td scope="row" className="text-center">{k + 1}</td>
                                <td scope="row" className="text-capitalize">{s.site_name}<br/><small>Code: {s.site_code}</small></td>
                                <td scope="row">{s.linked_users.length > 0 ? s.linked_users.map((i,k)=> i.full_name).join(', ') : <div className="text-center">-</div>}</td>
                                <td scope="row" className="text-center">
                                    {s.status == 1
                                        ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} />
                                        : <tapIcon.FontAwesomeIcon color="red" icon={tapIcon.faTimes} />}
                                </td>
                                <td scope="row" className="text-center">
                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"/>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item" onClick={() => this.editSiteAutoLinkingInit(s.tap_site_id)}>
                                            Edit
                                        </li>
                                    </ul>
                                </td>
                            </tr>);
                        }))
                        : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                     )
                }
            </tbody>
        </table>
        {this.editAutoLinkingModalJsx()}
        </Ax>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(AutoLinking);