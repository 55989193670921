import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import { ORG_API_BASE_URL, ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import moment from "moment";
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class AssetStatus extends AppBaseComponent {


    constructor(props) {
        super(props);

        this.state                      =     {
            widget_asset_status_data            :        [],
            widget_asset_status_time            :        '',
            widget_asset_status_current_site    :        null,
            widget_asset_status_loading         :        false,
            iam_asset_types                    :         [],
            iam_user_sites                      :        [],
            assetStatusForm                    :   {filter_site_id : '',search_asset_type:''},
        }
        this.updateMyDashboardRef       =   React.createRef();

        this.groupCacheClearUrl         =   ORG_API_BASE_URL_2  + '/group_cache_clear';
        this.api1GroupCacheClearUrl     =   ORG_API_BASE_URL  + '/group_cache_clear';
        
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('assetStatusWidget')) {
            let assetWidgetData = JSON.parse(localStorage.getItem('assetStatusWidget'));
            if (assetWidgetData) {
                let lastRefreshTime = moment(assetWidgetData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('assetStatusWidget')
                    setTimeout(() => {
                        this.getDashboardDetails()
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 1));
                } else {
                    this.setState({
                        widget_asset_status_data: assetWidgetData.data,
                        widget_asset_status_time: assetWidgetData.time

                    })
                }

            }else {
                setTimeout(() => {
                    this.getDashboardDetails()
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 1));
            }
        } else {
            setTimeout(() => {
                this.getDashboardDetails()
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 1));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }
    
      
    initilaizeFormFilter                =   (props)  =>  {
        this.setState({
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_types          :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
        },() => {
            if(props.dashboard_filter_site_id || props.clearDashboardFilter == "yes"){
                   
                this.setState({
                    assetStatusForm    :       {
                        filter_site_id          :       props.dashboard_filter_site_id,
                        search_asset_type       :       ''
                    }
                },() => this.getDashboardDetails())
            }
        });
    }
    
    getDashboardDetails                 =   (site_id = null,reload = false)  =>  {
        this.setState({widget_asset_status_loading: true});
        let params                          =   {...this.state.assetStatusForm};
        HttpAPICall.withAthorization('GET',process.env.REACT_APP_IAM_API_SERVER + '/group_dashboard_data', this.props.access_token, params , {} , (response) => {
            this.setState({
                widget_asset_status_data    : response.data.data,
                widget_asset_status_time    :  response.data.time
            });
            if(site_id == null){
                localStorage.setItem('assetStatusWidget',JSON.stringify(response.data));
            }
            if(reload == true){
                localStorage.setItem('assetStatusWidget',JSON.stringify(response.data));
            }
           
        }).then(() => {
            this.setState({widget_asset_status_loading: false});
        });
    }

    groupCacheClearHandler            =       ()          =>      {
        this.setState({widget_asset_status_loading: true});

        HttpAPICall.withAthorization('PUT', this.api1GroupCacheClearUrl, this.props.access_token, {}, {} , (response) => {
            // if(response.data){
            //     this.getDashboardDetails(null,true);
            // }
        }).then(() => {
            this.setState({widget_asset_status_loading: false});
        });

        HttpAPICall.withAthorization('PUT', this.groupCacheClearUrl, this.props.access_token, {}, {} , (response) => {
            if(response.data){
                this.getDashboardDetails(null,true);
            }
        }).then(() => {
            this.setState({widget_asset_status_loading: false});
        });

    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"assets",name:"Asset Status"})
    }

    getStatusTablejsx                         =   (data)                          =>  {
        let trBody                             =    null;
        if(data && data.length > 1) {
            trBody =    data.map((d,k) => {
                let obj = {'status[]' : d.key};
                if(this.state.assetStatusForm && this.state.assetStatusForm.filter_site_id) {
                    obj['site[]'] = this.state.assetStatusForm.filter_site_id ;
                }
                if(this.state.assetStatusForm && this.state.assetStatusForm.search_asset_type) {
                    obj['asset_type[]'] = this.state.assetStatusForm.search_asset_type ;
                }
                return <tr key={k} className="text-center">
                <th className="text-start"><tapIcon.FontAwesomeIcon icon={tapIcon.faCircle} color={d.color_code}/> {d.name}</th>
                <td>
                    <Link target="_blank" to={{pathname: "/measurable_asset", search: "?" + new URLSearchParams(obj).toString()}}>
                        {d.measurable_count}
                    </Link>
                </td>
                <td>
                    <Link target="_blank" to={{pathname: "/non_measurable_asset", search: "?" + new URLSearchParams(obj).toString()}}>
                        {d.non_measurable_count}
                    </Link>
                </td>
            </tr>;
            });
        }
        return trBody;
    }
   
    render                      =   ()  =>  {
        let obj = {};
        if(this.state.assetStatusForm && this.state.assetStatusForm.filter_site_id) {
            obj['site[]'] = this.state.assetStatusForm.filter_site_id ;
        }
        if(this.state.assetStatusForm && this.state.assetStatusForm.search_asset_type) {
            obj['asset_type[]'] = this.state.assetStatusForm.search_asset_type ;
        }
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("assets" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "assets")})  : null;
        return (<div className="row mt-3">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-5">
                                <h6 className="fw-bold primary_color">ASSETS</h6>
                            </div>
                            <div className="col-3">
                                <TapSelect
                                    options={this.state.iam_asset_types}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder="All Asset Types"
                                    containerHeight="33px"
                                    fontSize="93%"
                                    value={this.state.iam_asset_types.find(r => r.value === this.state.assetStatusForm.search_asset_type)}
                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'assetStatusForm', 'search_asset_type', () => this.getDashboardDetails())}
                                />
                            </div>
                            <div className="col-3 p0">
                                <TapSelect
                                    options={this.state.iam_user_sites}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder="All Sites"
                                    containerHeight="33px"
                                    fontSize="93%"
                                    value={this.state.iam_user_sites.find(r => r.value === this.state.assetStatusForm.filter_site_id)}
                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'assetStatusForm', 'filter_site_id', () => this.getDashboardDetails())}
                                />

                            </div>
                            <div className="col-1 pl0 ml0 text-center">
                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.groupCacheClearHandler()}>Refresh</li>
                                    <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>

                                </ul>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <table className="table table-hover table-bordered table-borderless bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th style={{ width: "50%" }} className="text-center"></th>
                                            <th style={{ width: "25%" }} className="text-center">Measurable</th>
                                            <th style={{ width: "25%" }} className="text-center">Non Measurable</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.widget_asset_status_loading
                                            ? <tr><td className="text-center" colSpan="3"><Loader /></td></tr>
                                            : (<Ax>
                                                {this.getStatusTablejsx(this.state.widget_asset_status_data)}
                                                <tr>
                                                    <th className="text-center">Total</th>
                                                    <td className="text-center"> <Link target="_blank" to={{ pathname: "/measurable_asset", search: "?" + new URLSearchParams(obj).toString() }}>{this.state.widget_asset_status_data.reduce((sum, a) => sum + a.measurable_count, 0)}</Link></td>
                                                    <td className="text-center"><Link target="_blank" to={{ pathname: "/non_measurable_asset", search: "?" + new URLSearchParams(obj).toString() }}>{this.state.widget_asset_status_data.reduce((sum, a) => sum + a.non_measurable_count, 0)}</Link></td>
                                                </tr>
                                            </Ax>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-6 p0">
                                {this.state.widget_asset_status_loading
                                    ? <Loader />
                                    : <Chart
                                        chartType="PieChart"
                                        data={[['Status Name', 'Asset Count']].concat(this.state.widget_asset_status_data.map((d) => { return [d.name, d.count]; }))}
                                        options={{
                                            legend: { position: 'labeled', labeledValueText: 'both' },
                                            pieSliceText: 'none',
                                            slices: this.state.widget_asset_status_data.map((d) => { return { color: d.color_code } }),
                                            chartArea: { width: '90%', height: '90%' },
                                        }}
                                        rootProps={{ 'data-testid': '1' }} />
                                }
                            </div>
                            <div className="col-sm-12 text-muted fs11">
                                Report as of {this.state.widget_asset_status_time ? this.state.widget_asset_status_time : "-"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
        firebase_app_version     :   state.app && state.app.firebase_app_version ? state.app.firebase_app_version : 1,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(AssetStatus);