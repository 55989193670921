import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';

class AssetAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.initAddAssetForm           =       {
            // asset_matrix_unique_id      :       '',
            site_id                     :       '',
            name                        :       '',
            code                        :       '',
            engine_number               :       '',
            chasis_number               :       '',
            machine_no                  :       '',
            registration_number         :       '',
            purchase_date               :       '',
            purchase_amount             :       '',
            owner_category              :       '',
            owner_id                    :       '',
            roster_id                   :       '',
            auxiliary_engine_no         :       ''
        }
        
        
        this.state                      =   {
            assetMatrixId                :      '',
            detail_loading               :     false,
            form_loading                 :     false,
            assetMatrixDetail            :     null,
            allShiftList                 :     [],
            allOwnerCategoryList         :      [],
            allOwnersList                :      [],
            iam_user_sites               :      [],
            matrix_unique_id             :      '',
            successMessage               :      null,
            showSuccessMessage           :      false,
            addAssetForm                 :      {...this.initAddAssetForm},
            routedFrom                   :      '',
            search_by_keywords           :      '',
            sapIntegration               :      false
        }
      
    }
    
    componentDidMount() {
        this.initalizeData(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id || this.props.iam_user_sites !== nextProps.iam_user_sites){
            this.initalizeData(nextProps)
        }
    }

    initalizeData       =       (pr)        =>      {
      
        this.setState({
             assetMatrixId              :       pr.match?.params?.id,
             showSuccessMessage         :       false,
             iam_user_sites              :       pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`,site_code : s.site_code}}) : [],
             matrix_unique_id           :       pr.location && pr.location.state && pr.location.state.matrixId ? pr.location.state.matrixId : '',
             routedFrom                 :       pr.location && pr.location.state && pr.location.state.routedFrom ? pr.location.state.routedFrom : 'Asset Matrix Search',
             search_by_keywords         :        pr.location && pr.location.state && pr.location.state.search_by_keywords ? pr.location.state.search_by_keywords : '',
            },() => {
                let data = null
                if(pr.location && pr.location.state && pr.location.state.sapData) {
                     data = pr.location.state.sapData;
                }
             
            this.getAsssetMatrixDetails(pr.match?.params?.id)
            this.addAssetFormData(data)
        })
    }

    getAsssetMatrixDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetMatrixId
        this.setState({detail_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset_matrix/' + Id, this.props.access_token , {get_fuel_type : 'Y'}, {}, (response) => {
            this.setState({
                assetMatrixDetail       : response.data.data,
                matrix_unique_id        : response.data.data.asset_matrix_unique_id 
            });
          
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({detail_loading: false})})
    }

    addAssetFormData                =   (data = null)  =>  {
       
        this.setState({form_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset_add_form_data', this.props.access_token , {}, {}, (response) => {
            let respData = response.data;
            let rosters =   [];
          
            this.setState({
                allOwnerCategoryList        :       respData && respData.ownership_category && respData.ownership_category.length > 0 ? respData.ownership_category.map(oc => {return({value:oc.key,label : oc.name})}) :[],
                allOwnersList               :       respData && respData.owners && respData.owners.length > 0 ? respData.owners.map(oc => {return({value:oc.id,label : oc.name})}) :[],
                allShiftList                :      respData && respData.rosters && respData.rosters.length > 0 ? respData.rosters.map(oc => {return({value:oc.id,label : `${oc.roster_name} (Shifts : ${oc.shifts.length})`,shift_no:oc.no_of_shift})}) : [],
          },() => {
                if(data){
                    let sapData = data && data.data ? data.data : null;
                    let shiftData = this.state.allShiftList && this.state.allShiftList.length > 0 ? this.state.allShiftList.find(sh => sh.shift_no && sh.shift_no == 1) : null;
                    let siteData = this.state.iam_user_sites && this.state.iam_user_sites.length > 0 ? this.state.iam_user_sites.find(sh => sh.site_code && sh.site_code == sapData.IWERK) : null;
                    this.setState({
                        addAssetForm                 :      {
                            ...this.initAddAssetForm,
                            save_from_hg_sap_master     :       'Y',
                            hg_sap_master_data_id       :       data.id,
                            site_id                     :       siteData ? siteData.value : "",
                            name                        :       sapData && sapData.EQUNR ? sapData.EQUNR : "" ,
                            code                        :       sapData && sapData.EQUNR ? sapData.EQUNR : "" ,
                            engine_number               :        sapData && sapData.ENGINE_SNR ? sapData.ENGINE_SNR : "" ,
                            chasis_number               :        sapData && sapData.CHASSIS_NUM ? sapData.CHASSIS_NUM : "" ,
                            machine_no                  :       '',
                            registration_number         :       sapData && sapData.LICENSE_NUM ? sapData.LICENSE_NUM : "" ,
                            purchase_date               :       '',
                            purchase_amount             :       '',
                            owner_category              :       '',
                            owner_id                    :       '',
                            roster_id                   :       shiftData ? shiftData.value : '' ,
                            auxiliary_engine_no         :       ''
                        },
                        sapIntegration          :   data ? true : false
                    })
                }
          })
            
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({form_loading: false})})
    }

   

     //****************************CLOSE CONSUMPTION *********************/
    closeEvent              =           ()      =>      {
       if(this.state.routedFrom && this.state.routedFrom == 'Asset Matrix List'){
            this.props.history.push({ pathname: `/asset_matrix`, state: { search_by_keywords:this.state.search_by_keywords} })
        }else if(this.state.routedFrom && this.state.routedFrom == 'Asset Matrix View'){
            this.props.history.push({ pathname: `/asset_matrix/details/` + this.state.assetMatrixId })
        }else if(this.state.routedFrom && this.state.routedFrom == 'Asset Matrix SAP Search'){
            this.props.history.push({ pathname: `/master_data_integration` })
        }else{
          
            this.props.history.push({ pathname: `/asset_matrix/add_asset`, state: { search_by_keywords:this.state.search_by_keywords} })
        }
    
        
    }

    addAssetFormSubmitHandler       =       (e)     =>      {
        e.preventDefault();
        let frmData = {...this.state.addAssetForm,asset_matrix_unique_id : this.state.matrix_unique_id}
        
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/asset_add', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.setState({
                addAssetForm            :       {...this.initAddAssetForm},
                showSuccessMessage      :       true,
                successMessage          :       response.data

            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    goToAssetList           =       ()          =>      {
        if(this.state.successMessage && this.state.successMessage.asset_id){
            this.props.history.push(`/asset/dashboard/${this.state.successMessage.asset_id}`)
        }
    }

  
    pageTitlejsx                        =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>New Asset</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    
    saveAssetFormJsx              =   ()  =>  {
      
        return (<form className="bg-white p-3" onSubmit={this.addAssetFormSubmitHandler}>
            <div className='row'> 
            {this.state.showSuccessMessage == true && this.state.successMessage ?
                    <div className="col-sm-6">
                    
                        <div className="alert alert-success">
                            {this.state?.successMessage?.msg} <Link onClick={() => this.goToAssetList()}>View</Link>
                        </div>

                    </div>
                     : null} 
            </div>
            <div className="row align-items-center mb-2">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Asset Name</label><br />
                    <input
                        name="name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        required="true"
                        placeholder='Enter Asset Name'
                        value={this.state.addAssetForm.name}
                        disabled={this.state.sapIntegration ? true : false}
                        onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                    />

                </div>
                <div className="col-sm-3 ">
                    <label htmlFor="site_id" className="form-label require">Asset Code</label> <br />
                    <input
                        name="code"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        required="true"
                        placeholder='Enter Asset Code'
                        value={this.state.addAssetForm.code}
                        disabled={this.state.sapIntegration ? true : false}
                        onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                    />

                </div>
              
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Shift Roster</label> <br />
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAssetForm', 'roster_id') }}
                        options={this.state.allShiftList}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allShiftList.find(m => m.value == this.state.addAssetForm.roster_id)}
                        placeholder="Please Select Shift"
                        isDisabled={this.state.sapIntegration ? true : false}
                    />

                </div>
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Site</label><br />
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAssetForm', 'site_id') }}
                        options={this.state.iam_user_sites}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.iam_user_sites.find(m => m.value == this.state.addAssetForm.site_id)}
                        placeholder="Please Select All Sites"
                        isDisabled={this.state.sapIntegration ? true : false}
                    />

                </div>
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Date of Purchase</label><br />

                    <DatePicker
                        selected={
                            this.state.addAssetForm.purchase_date
                                ? moment(this.state.addAssetForm.purchase_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="purchase_date"
                        onChange={(value, event) => this.formDateHandler('purchase_date', value, 'addAssetForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={` Date of Purchase`}

                    />


                </div>
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Purchase Price</label><br />
                    <input
                        name="purchase_amount"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder='Enter Purchase Price'
                        value={this.state.addAssetForm.purchase_amount}
                        onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                    />
                </div>

            </div>


            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Ownership Category</label><br />
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAssetForm', 'owner_category') }}
                        options={this.state.allOwnerCategoryList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allOwnerCategoryList.find(m => m.value == this.state.addAssetForm.owner_category)}
                        placeholder="Select Ownership Category"
                    />

                </div>
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Asset Owner</label><br />
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAssetForm', 'owner_id') }}
                        options={this.state.allOwnersList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allOwnersList.find(m => m.value == this.state.addAssetForm.owner_id)}
                        placeholder="Select Owner"
                    />
                </div>
                {/* <div className="col-sm-3">
                  //Add Owner Name
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAssetForm', 'owner_name') }}
                        options={this.state.allOwnersList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allOwnersList.find(m => m.value == this.state.addAssetForm.owner_name)}
                        placeholder="Select Owner"
                    />
                </div> */}
                <div className="col-sm-3 ">
                    <label htmlFor="site_id" className="form-label">Machine  Number</label> <br />
                    <input
                        name="machine_no"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        placeholder='Enter Machine Number'
                        value={this.state.addAssetForm.machine_no}
                        onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                    />

                </div>
                {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.mvr && this.state.assetMatrixDetail.assetType.mvr == 'yes'
                    ? <Ax>
                        <div className="col-sm-3 ">
                            <label htmlFor="site_id" className="form-label">MVR Number</label> <br />
                            <input
                                name="registration_number"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder='Enter MVR'
                                value={this.state.addAssetForm.registration_number}
                                disabled={this.state.sapIntegration ? true : false}
                                onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                            />
                        </div>
                    </Ax>
                    : null}

            </div>

            <div className="row align-items-center my-3">
                {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.engine_number && this.state.assetMatrixDetail.assetType.engine_number == 'yes'
                    ? <Ax>
                        <div className="col-sm-3 ">
                            <label htmlFor="site_id" className="form-label">Engine Number</label> <br />
                            <input
                                name="engine_number"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder='Enter Engine Number'
                                value={this.state.addAssetForm.engine_number}
                                disabled={this.state.sapIntegration ? true : false}
                                onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                            />
                        </div>
                    </Ax>
                    : null}
                {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.chasis_number && this.state.assetMatrixDetail.assetType.chasis_number == 'yes'
                    ? <Ax>
                        <div className="col-sm-3 ">
                            <label htmlFor="site_id" className="form-label">Chasis Number</label> <br />
                            <input
                                name="chasis_number"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder='Enter Chasis Number'
                                value={this.state.addAssetForm.chasis_number}
                                disabled={this.state.sapIntegration ? true : false}
                                onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                            />
                        </div>
                    </Ax>
                    : null}
                {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.slave_engine && this.state.assetMatrixDetail.assetType.slave_engine == 'yes'
                    ? <Ax>
                        <div className="col-sm-3 ">
                            <label htmlFor="site_id" className="form-label">Auxiliary Engine Number</label> <br />
                            <input
                                name="auxiliary_engine_no"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder='Enter Auxiliary Engine Number'
                                value={this.state.addAssetForm.auxiliary_engine_no}
                                disabled={this.state.sapIntegration ? true : false}
                                onChange={(e) => this.formInputHandler(e, 'addAssetForm')}
                            />
                        </div>
                    </Ax>
                    : null}

            </div>

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>

                <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary ms-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>


        </form>);
    }

    assetMatrixCardJsx          =       ()          =>      {
     
        let detail = this.state.assetMatrixDetail;
        return (<div className="container-fluid p-0">
            {this.state.detail_loading
                ? <div className='text-center'> <Loader /></div>
                :
                <table className="table table-borderless table-sm va_middle mb-1 bg-white">
                    {detail ?
                        <Ax>
                            <tbody>
                                <tr>
                                    <td rowSpan="4" style={{ width: '25%' }}>
                                        <img src={detail.image} className="img-fluid card-image" />
                                    </td>
                                    <td style={{ width: "25%" }}> Asset Matrix ID :  <b>{detail.asset_matrix_unique_id ? detail.asset_matrix_unique_id : "-"}</b>  </td>
                                    <td style={{ width: "25%" }}> Model Name :  <b>{detail.model_detail && detail.model_detail.model ? detail.model_detail.model : "-"}</b>  </td>
                                    <td style={{ width: "25%" }}> Fuel Type (Unit) :  <b>{detail.fuelType && detail.fuelType.fuel_type ? detail.fuelType.fuel_type : "-"} {detail.fuelType && detail.fuelType.unit ? <span>({detail.fuelType.unit})</span> : null}</b>  </td>
                                </tr>
                                <tr>
                                    <td>Asset Type :  <b>{detail.assetType && detail.assetType.asset_type_name ? detail.assetType.asset_type_name : "-"}</b>  </td>
                                    <td > Variant  :  <b>{detail.variant ? detail.variant : "-"}</b>  </td>
                                    <td> Fuel Tank Capacity :  <b>{detail.fuel_tank_capacity ? detail.fuel_tank_capacity : "-"}</b>  </td>
                                </tr>
                                <tr>
                                    <td>Asset Capacity :  <b>{detail.capacity ? detail.capacity : "-"}</b>  </td>
                                    <td > Manufacturer  :  <b>{detail.manufacturer && detail.manufacturer.manufacturer ? detail.manufacturer.manufacturer : "-"}</b>  </td>
                                    <td >Electrical System :  <b>{detail.electrical_system ? detail.electrical_system : "-"}</b>  </td>
                                </tr>
                                <tr>
                                    <td >Hour Meter (HMR) :  <b className='text-capitalize'>{detail.assetType && detail.assetType.hour_meter ? detail.assetType.hour_meter : "-"}</b>  </td>
                                    <td >Odo Meter (KM)   :  <b className='text-capitalize'>{detail.assetType && detail.assetType.odo_meter ? detail.assetType.odo_meter : "-"}</b>  </td>
                                    <td > Operation Cycle (OpC) :  <b className='text-capitalize'>{detail.assetType && detail.assetType.operation_cycle ? detail.assetType.operation_cycle : "-"}</b>  </td>
                                </tr>

                            </tbody>
                        </Ax>
                        : null}

                </table>}


        </div>)
    }

   
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Add Asset</title></Helmet>
            {this.pageTitlejsx()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                    {this.assetMatrixCardJsx()}
                    {this.saveAssetFormJsx()}
                    </div>
                </div>
            </div>
           
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        iam_group_sites         :   state.app && state.app.user_sites ? state.app.group_sites : [],
        permissions             :   state.app.acl_info,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(AssetAdd);