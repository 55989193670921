import React from 'react';
import {connect} from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import AdditionalAttribute from '../AdditionalAttribute';

class SiteProductivity extends AppBaseComponent {

    constructor(props) {
        super(props);
        
    }


    render() {

        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                <AdditionalAttribute category="site_productivity"/>
            </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        iam_user_sites          :        state.app && state.app.user_sites ? state.app.user_sites : []
    };
};


export default connect(mapStateToProps)(SiteProductivity);