import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';

class IntegrationLog extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                         =     {
            listing_loading             :         false,
            listing_tbl_page            :         1,
            integration_listing        :         [],
            listingMeta                 :         null,
            totalListingCount           :         0,
        };
      
    }
    
    
    componentDidMount() {
        this.loadListingTblData(1)
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                     =   {page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/hg_sap/master_data', this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                integration_listing    :           respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount       :           respData.total,
           });
        }).then(() => this.setState({listing_loading: false}));
    }


    masterDataListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Master Data Integration Log</h3>
                <div className="text-end mt15">
                    
    
                </div>
            </div>
           
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.masterListingTableJsx()}</div>
                    {/* {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.purchaseViewDetails()}</div></div> : null} */}
                </div>
            </div>
        </Ax>);
    }

    masterListingTableJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                        <th scope="col" style={{ width: "3%" }}>S.No</th>
                        {this.state.integration_listing && this.state.integration_listing.length > 0 && (
                            this.state.integration_listing[0].detail &&
                            this.state.integration_listing[0].detail.length > 0 &&
                            this.state.integration_listing[0].detail.map((data, dataIndex) => (
                                <th key={dataIndex} scope="col" style={{ width: "8%" }}>{data[2]}</th>
                            ))
                        )}
                        <th scope="col" style={{ width: "6%" }}>Batch No</th>
                        <th scope="col" style={{ width: "15%" }}>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="11"><Loader /></td></tr>)
                        : (this.state.integration_listing.length > 0
                            ? (this.state.integration_listing.map((data, index) => {

                                return (
                                    <tr key={index} >
                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                        {data.detail.map(dt => {
                                            return (
                                                <td className="text-center">{dt[1]}</td>
                                            )
                                        })}
                                        <td>
                                            {data.batch ? data.batch : ""}
                                        </td>
                                        <td className='text-sm'>{data.msg ? data.msg : ""}</td>
                                        
                                    </tr>
                                )

                            }))
                            : (<tr><td colSpan="11" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />

        </Ax>)
    }

   
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Master Data Integration Log</title></Helmet>
            {this.masterDataListjsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(IntegrationLog);