import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';



class FuelTransfer extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.fuelStationInitState       =        {
            transaction_date            :        moment(new Date()).format('YYYY-MM-DD'),
            station_id                   :       '',
            fuel_type                   :       '',
            fuel_qty                    :       '',
            unit_rate                   :       '',
            amount                      :       '',
            transfer_to                 :        '',
            reference_num               :        '',
            remarks                     :        '',
        }

        this.state                      =   {
            saveFormSubmitting          :   false,
            form_data_loading           :   false,
            fuelStationFromList         :   [],
            fuelStationTransferList     :   [],
            allReceiversList            :   [],
            allFuelTypeList             :   [],
            fuelTransfterForm           :   {...this.fuelStationInitState},
            newReceiver                 :   'N',
            fuelTransferStationData     :   null,
            stationToFuelTypeList       :   []
        };
        this.logTransferFuelModalId =   uuid();
        this.stationUrl             =   IAM_API_BASE_URL_2 + '/station';

    }

    componentDidMount() {
        this.uploadFuelTransferModal         =   new Modal(document.getElementById(this.logTransferFuelModalId), {keyboard: false, backdrop :false});  
    }

    initalizeFormData       =       (station_id,data = null,fuel_type = null)      =>  {
       
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.stationUrl + '/fuel_transfer_form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
           
            this.setState({
                fuelTransfterForm                :   {...this.fuelStationInitState},
                fuelStationFromList              :   respData && respData.stations &&  respData.stations.length > 0  ? respData.stations.map((s) => { return {value: s.id, label: `${s.fuelstation_name}`,fuel_types : s.fuels_prices && s.fuels_prices.length > 0  ?  s.fuels_prices.map(s => {return({value:s.fueltype_id,label : s.fuel_type,fuel_price : s.price })}) : []}}) : [],
                fuelStationTransferList          :   respData && respData.stations &&  respData.stations.length > 0  ? respData.stations.map((s) => { return {value: s.id, label: `${s.fuelstation_name}`,fuel_types : s.fuels_prices && s.fuels_prices.length > 0  ?  s.fuels_prices.map(s => {return({value:s.fueltype_id,label : s.fuel_type})}) : []}}) : [],
             },() => {
                if(station_id && data == null){
                   
                    let selectedFuelType  = this.state.fuelStationFromList && this.state.fuelStationFromList.length > 0 ? this.state.fuelStationFromList.find(fs => {return(fs.value == station_id)}) : null;
                   
                    this.setState({
                        allFuelTypeList     :       selectedFuelType && selectedFuelType.fuel_types && selectedFuelType.fuel_types.length > 0 ? selectedFuelType.fuel_types : [],
                    },() => {
                        
                        this.setState({
                            fuelTransfterForm       :        {
                                ...this.fuelStationInitState,
                                station_id          :       station_id,
                                fuel_type           :       fuel_type ? fuel_type : ''
                            },
                            fuelTransferStationData :   null,

                        })
                    })
                }
                    

                if(data){
                    let stationId = data.transfer_type == 'IN' ? data.transfer_from : data.fuel_station_id;
                    let selectedFuelType  = this.state.fuelStationFromList && this.state.fuelStationFromList.length > 0 ? this.state.fuelStationFromList.find(fs => {return(fs.value == stationId)}) : null;
                    
                     this.setState({
                        allFuelTypeList     :       selectedFuelType && selectedFuelType.fuel_types && selectedFuelType.fuel_types.length > 0 ? selectedFuelType.fuel_types : [],
                    },() => {
                        this.setState({
                            fuelTransfterForm              :       {
                                station_id                  :       data.transfer_type == 'IN' ? data.transfer_from : data.fuel_station_id,
                                transaction_date            :       data.transaction_date_Ymd,
                                fuel_type                   :       data.fuel_type_id,
                                fuel_qty                    :       data.fuel_qty,
                                unit_rate                   :       data.rate,
                                amount                      :       data.amount,
                                transfer_to                 :        data.transfer_type == 'IN' ? data.fuel_station_id : data.transfer_to,
                                reference_num               :        data.reference_num,
                                remarks                     :        data.remarks,
                                ledger_id                   :        data.id
                            },
                            fuelTransferStationData            :       data
                        })
                    })     
                    
                }
             })
        }).then(() => this.setState({form_data_loading: false}));
    }

    transferFuelModalInit         =   (station_id,fuel_type = null)  =>  {
        this.uploadFuelTransferModal.show()
        this.initalizeFormData(station_id,null,fuel_type)
    }

    editFuelTransferModalInit       =       (station_id,data=null) => {
        this.uploadFuelTransferModal.show()
        this.initalizeFormData(station_id,data)
    }

    getSelectedStationFuelType      =   (selectedOption)        =>      {
       
        if(selectedOption){
            this.setState({
                allFuelTypeList : selectedOption && selectedOption.length > 0 ? selectedOption.map(s => {return({value:s.value,label : s.label,fuel_price : s.fuel_price })}) : [],
                fuelTransfterForm       :       {
                    ...this.state.fuelTransfterForm,
                    fuel_type       :       '',
                    transfer_to     :       '',
                    unit_rate        :      ''
                }
            });
        }else{
            this.setState({
                allFuelTypeList : []
            })
        }
    }

    getStationToFuelType         =       (selectedOption)        =>      {
        if(selectedOption){
            this.setState({
                stationToFuelTypeList : selectedOption && selectedOption.length > 0 ? selectedOption.map(s => {return(s.value)}) : [],
            });
        }else{
            this.setState({
                stationToFuelTypeList : []
            })
        }
    }

    submitIssueFuelStationForm    =   (e)     =>      {
        e.preventDefault();
     
        if(this.state.fuelTransferStationData){
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('PUT', this.stationUrl + '/fuel_transfer' , this.props.access_token, {}, {...this.state.fuelTransfterForm ,amount: parseFloat(this.state.fuelTransfterForm.amount).toFixed(2)}, (resp) => {
                this.afterSubmitFuelTransferHandler(resp);
           }).then(() => this.setState({ saveFormSubmitting: false }));
        }else{
            this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('POST', this.stationUrl + '/fuel_transfer' , this.props.access_token, {}, {...this.state.fuelTransfterForm ,amount: parseFloat(this.state.fuelTransfterForm.amount).toFixed(2)}, (resp) => {
                this.afterSubmitFuelTransferHandler(resp);
           }).then(() => this.setState({ saveFormSubmitting: false }));
        }   
       
    }

    afterSubmitFuelTransferHandler  =   (response)  =>  {
        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
        if(this.props.afterSubmitFuelTransferHandler){
            this.props.afterSubmitFuelTransferHandler();
            this.setState({
                fuelTransfterForm              :      {...this.fuelStationInitState,
                fuelTransferStationData         :       null
             }})
        }
        this.uploadFuelTransferModal.hide();
    }

    saveFuelStationFormJsx         =        ()       =>      {
     
        return (<form className="bg-white" onSubmit={this.submitIssueFuelStationForm} id="saveTransferFuelStationForm">
            {this.state.form_data_loading
                ? <Loader />
                : <Ax>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Date of Fuel Issue</label>

                        <div className="col-sm-7  add_calender_section">
                            <DatePicker
                                selected={
                                    this.state.fuelTransfterForm.transaction_date
                                        ? moment(this.state.fuelTransfterForm.transaction_date, 'YYYY-MM-DD').toDate()
                                        : false
                                }
                                name="transaction_date"
                                onChange={(value, event) => this.formDateHandler('transaction_date', value, 'fuelTransfterForm')}
                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                dateFormat="dd-MMM-yyyy"
                                className={"form-control form-control-sm"}
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                required={true}
                                placeholderText={`Please Enter Date of  Fuel Receive`}

                            />

                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                        </div>

                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Fuel Station From</label>
                        <div className="col-sm-7 ">
                            <TapSelect
                                options={this.state.fuelStationFromList.filter(dw => dw.value !== this.state.fuelTransfterForm.transfer_to)}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'fuelTransfterForm', 'station_id', () => this.getSelectedStationFuelType(selectedOption?.fuel_types));
                                }}
                                isSearchable={true}
                                value={this.state.fuelStationFromList.find(s => this.state.fuelTransfterForm.station_id == s.value)}
                                isClearable={true}
                                placeholder="Select Fuel Station"
                                autoCompelete="off"
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Fuel Station To</label>
                        <div className="col-sm-7 ">
                            <TapSelect
                                options={this.state.fuelStationTransferList.filter(dw => dw.value !== Number(this.state.fuelTransfterForm.station_id))}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'fuelTransfterForm', 'transfer_to', this.getStationToFuelType(selectedOption?.fuel_types));
                                }}
                                isSearchable={true}
                                value={this.state.fuelStationTransferList.find(s => this.state.fuelTransfterForm.transfer_to == s.value)}
                                isClearable={true}
                                placeholder="Select Fuel Station"
                                autoCompelete="off"
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Fuel Type</label>

                        <div className="col-sm-7 ">
                            <TapSelect
                                options={this.state.allFuelTypeList}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'fuelTransfterForm', 'fuel_type',() => {
                                        this.setState({
                                            fuelTransfterForm      :   {
                                                ...this.state.fuelTransfterForm,
                                                unit_rate       :       selectedOption && selectedOption.fuel_price ? selectedOption.fuel_price : ''
                                            } 
                                        })
                                     });
                                
                                }}
                                isSearchable={true}
                                value={this.state.allFuelTypeList.find(s => this.state.fuelTransfterForm.fuel_type == s.value)}
                                isClearable={true}
                                placeholder="Select Fuel Type"
                                autoCompelete="off"
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>


                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Quantity</label>

                        <div className="col-sm-3 ">
                            <input
                                name="fuel_qty"
                                value={this.state.fuelTransfterForm.fuel_qty}
                                onChange={(e) => this.formInputHandler(e, "fuelTransfterForm", null, true)}
                                className="form-control form-control-sm"
                                type="number"
                                autoComplete="off"
                                placeholder="Enter Fuel Quantity"
                                required={true}
                                onKeyUp={(e) => {
                                    if (this.state.fuelTransfterForm && this.state.fuelTransfterForm.fuel_qty > 0 && this.state.fuelTransfterForm.unit_rate && this.state.fuelTransfterForm.unit_rate > 0) {
                                        let amount = this.state.fuelTransfterForm.unit_rate * this.state.fuelTransfterForm.fuel_qty;
                                        this.setState({ fuelTransfterForm: { ...this.state.fuelTransfterForm, amount: parseFloat(amount).toFixed(2) } })
                                    }
                                }}
                            />
                        </div>
                        <label className="col-sm-1 col-form-label col-form-label-sm require">Unit Rate</label>
                        <div className="col-sm-3 ">
                            <input
                                name="unit_rate"
                                value={this.state.fuelTransfterForm.unit_rate}
                                onChange={(e) => this.formInputHandler(e, "fuelTransfterForm", null, true)}
                                className="form-control form-control-sm"
                                type="number"
                                autoComplete="off"
                                placeholder="Enter Fuel Rate"
                                required={true}
                                onKeyUp={(e) => {
                                    if (this.state.fuelTransfterForm && this.state.fuelTransfterForm.fuel_qty > 0 && this.state.fuelTransfterForm.unit_rate && this.state.fuelTransfterForm.unit_rate > 0) {
                                        let amount = this.state.fuelTransfterForm.unit_rate * this.state.fuelTransfterForm.fuel_qty;
                                        this.setState({ fuelTransfterForm: { ...this.state.fuelTransfterForm, amount: parseFloat(amount).toFixed(2) } })
                                    }
                                }}
                            />
                        </div>

                    </div>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Amount</label>

                        <div className="col-sm-7 ">
                            <input
                                name="amount"
                                value={this.state.fuelTransfterForm.amount}
                                onChange={(e) => this.formInputHandler(e, "fuelTransfterForm", null, true)}
                                className="form-control form-control-sm"
                                type="number"
                                required={true}
                                autoComplete="off"
                                placeholder="Enter Fuel Amount"
                                onKeyUp={(e) => {
                                    if (this.state.fuelTransfterForm.amount && this.state.fuelTransfterForm.amount > 0 && this.state.fuelTransfterForm.unit_rate && this.state.fuelTransfterForm.unit_rate > 0) {
                                        let quantity = this.state.fuelTransfterForm.amount / this.state.fuelTransfterForm.unit_rate;
                                        this.setState({ fuelTransfterForm: { ...this.state.fuelTransfterForm, fuel_qty: parseFloat(quantity).toFixed(2) } })
                                    }
                                }}
                            />

                        </div>



                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Reference Number</label>

                        <div className="col-sm-7 ">
                            <input
                                name="reference_num"
                                type="text"
                                value={this.state.fuelTransfterForm.reference_num}
                                className="form-control form-control-sm"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, "fuelTransfterForm")}
                                placeholder="Please enter Reference Number"
                            />
                        </div>
                    </div>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Remarks</label>

                        <div className="col-sm-7 ">
                            <textarea
                                name="remarks"
                                placeholder='Enter remarks'
                                className='form-control'
                                value={this.state.fuelTransfterForm.remarks}
                                onChange={(e) => this.formInputHandler(e, "fuelTransfterForm")}
                                style={{ height: "65px", width: "100%" }}
                            />
                        </div>
                    </div>


                    <div className="modal-footer">
                        <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary mx-3" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>

                </Ax>
            }
        </form>);
    }


    render                    =        ()        =>     {
        return (<div className="modal fade" id={this.logTransferFuelModalId} tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            <span>{this.state.fuelTransferStationData ? "Edit" : "Add"} Fuel Transfer</span>

                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {this.saveFuelStationFormJsx()}

                    </div>
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(FuelTransfer);