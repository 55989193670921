import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";

class ScheduleTrainingForm extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initAddTraingForm    =   {
            topics              :   [],
            mobile              :  "",
            notes               :   "",
            preferred_date      :   "",
            request_mode        :   "online"
        }

        this.state                  =   {
            access_token                :   '',
            addTrainingForm             :   {...this.initAddTraingForm},
            form_loading                :    false,
            allTopics                   :   [],
            saveFormSubmitting          :   false,
        }
        this.uploadTrainingModalId  =   uuid();
        this.updateTrainingFormId   =   this.uploadTrainingModalId + 'Form';

        this.trainingFormDataUrl    =   ORG_API_BASE_URL_2  + '/training/form_data';
        this.trainingUrl            =   ORG_API_BASE_URL_2  + '/training';

    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.uploadTrainingModal         =   new Modal(document.getElementById(this.uploadTrainingModalId), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    trainingFormModalInit         =   ()  =>  {
        this.uploadTrainingModal.show();
        this.loadFormDataFromApi();
        this.setState({addTrainingForm : {...this.initAddTraingForm}})
    }

    loadFormDataFromApi         =       ()      =>      {
        this.setState({form_loading: true})
        HttpAPICall.withAthorization('GET', this.trainingFormDataUrl, this.props.access_token , {}, {}, (response) => {
            let respData            =   response.data;
             
            this.setState({
               allTopics           :        respData.topics && respData.topics.length > 0 ? respData.topics.map(tp => {return({value:tp.id,label:tp.name})}) : []
            });
        }).then(() => this.setState({form_loading: false}))
    
    }
   
    submitScheduleTrainingForm      =       (e)         =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        HttpAPICall.withAthorization('POST', this.trainingUrl, this.props.access_token, {}, { ...this.state.addTrainingForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.setState({addTrainingForm:{...this.initAddTraingForm}})
           this.uploadTrainingModal.hide();
            if (this.props.afterSubmit) {
                this.props.afterSubmit();
            }
        }).then(() => this.setState({ saveFormSubmitting: false }));

    }
    
    updateTrainingModalJsx           =   ()  =>  {
        return <div className="modal fade" id={this.uploadTrainingModalId} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">Schedule a Training</h5>
                        <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.submitScheduleTrainingForm} id={this.updateTrainingFormId}>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm  require">Topics</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTrainingForm', 'topics')}
                                        options={this.state.allTopics}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={this.state.allTopics.filter(t => this.state.addTrainingForm.topics.includes(t.value))}
                                        placeholder="Please Select Topics"
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm  require">Mode of Training</label>
                                <div className="col-sm-9">
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="request_mode"
                                            type="radio"
                                            value="online"
                                            onChange={(e) => {
                                                this.formInputHandler(e, 'addTrainingForm');
                                            }}
                                            checked={this.state.addTrainingForm.request_mode === "online"}
                                            className="form-check-input"
                                            required={true}
                                            id="addTrainingFormonline"
                                        />
                                        <label className="form-check-label text-sm" htmlFor="addTrainingFormonline">Online  (Through Google Meet, Zoom Call, etc)</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            name="request_mode"
                                            type="radio"
                                            value="offline"
                                            onChange={(e) => {
                                                this.formInputHandler(e, 'addTrainingForm');
                                            }}
                                            checked={this.state.addTrainingForm.request_mode === "offline"}
                                            className="form-check-input"
                                            required={true}
                                            id="addTrainingForm_offline"
                                        />
                                        <label className="form-check-label text-sm" htmlFor="addTrainingForm_offline">Offline  (By visiting your office)</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Mobile </label>
                                <div className="col-sm-9">
                                    <input
                                        name="mobile"
                                        type="number"
                                        value={this.state.addTrainingForm.mobile}
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        autoFocus={true}
                                        onChange={(e) => this.formInputHandler(e, "addTrainingForm")}
                                        placeholder="Please enter Mobile"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm  require">Tentative Date of Training </label>
                                <div className="col-sm-9 add_calender_section">
                                    <DatePicker
                                        selected={
                                            this.state.addTrainingForm.preferred_date
                                                ? moment(this.state.addTrainingForm.preferred_date, 'YYYY-MM-DD').toDate()
                                                : false
                                        }
                                        name="preferred_date"
                                        onChange={(value, event) => this.formDateHandler('preferred_date', value, 'addTrainingForm')}
                                        minDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        placeholderText={`Tentative Date of Training`}

                                    />

                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                                </div>
                            </div>
                            <div className="row align-items-center py-2">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Notes </label>
                                <div className="col-sm-9">
                                    <textarea
                                        name="notes"
                                        value={this.state.addTrainingForm.notes}
                                        onChange={(e) => this.formInputHandler(e, 'addTrainingForm')}
                                        className="form-control"
                                        style={{ height: "100px" }}
                                    />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={this.updateTrainingFormId}>Submit{this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                </div>
            </div>
        </div>
    }
    
    render                              =   ()  =>  this.updateTrainingModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(ScheduleTrainingForm);