import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

class ChangeAssetStatus extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.changeStatusFormInit      =       {
            status                     :        '',
            reason                     :        '',
            date_time                  :        ''
        }
        
        this.state                  =   {
            name                        :   '',
            access_token                :   '',
            assetFormDataLoading        :   false,
            assetFormDataLoaded         :   false,
            asset_id                    :   '',
            allStatusList               :   [],
            assetDetail                  :   null,
            changeStatusForm            :    {...this.changeStatusFormInit},
            changeStatusFormSubmitting  :   false
        }
        this.changeStatusModalId      =   uuid();
    }

    
    componentDidMount               =   ()  =>  {
        this.initScreen(this.props);
        this.changeStatusModal            =   new Modal( document.getElementById(this.changeStatusModalId), {keyboard: false, backdrop :false}); 
    }
    
    componentWillReceiveProps(nextProps) {
//        if(nextProps != this.props) {
            this.initScreen(nextProps);
//        }
    }
    
    initScreen                     =   (props)  =>  {
        this.setState({access_token : props.access_token});
        
        this.setState({name : props.name});  
    
    }

    changeStatusModalInit               =   (asset_id)  =>  {
        this.getFormData(asset_id)
        this.changeStatusModal.show()
        let form = document.getElementById(`${this.changeStatusModalId}Form`)
        form.reset();
   }

    getFormData               =       (asset_id)             =>        {
        if(asset_id !== this.state.asset_id) {
            this.setState({assetFormDataLoading : true})
            HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset_change_status_form_data', this.props.access_token, {asset_id : asset_id}, null, (response) => {
                this.setState({
                    assetFormDataLoaded     :   true,
                    asset_id                :   asset_id,
                    allStatusList           :   response.data.asset_status_list && response.data.asset_status_list.length > 0 ? response.data.asset_status_list.map(s => {return({value: s.key,label : s.name})}) : [],
                    assetDetail             :   response.data && response.data.asset ? response.data.asset : null,
                    changeStatusForm        :   {   
                                                    ...this.changeStatusFormInit,
                                                    date_time : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
                                                    asset_id  : asset_id,
                                                }
                },() => {
                    
                });
            }).then(() => this.setState({assetFormDataLoading: false}));
    }
    }

    submitSettingsForm              =       (e)         =>      {
        e.preventDefault();
        let frmData = {...this.state.changeStatusForm,date_time : moment(this.state.changeStatusForm.date_time).format('YYYY-MM-DD HH:mm:ss')};
        this.setState({changeStatusFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/asset_change_status' , this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.changeStatusModal.hide();
            this.props.afterChangeStatus()
            this.setState({changeStatusForm : {...this.state.changeStatusForm,status : '' ,reason:'' },asset_id : ''})
        }).then(() => this.setState({ changeStatusFormSubmitting : false }));

    }
   
    changeStatusModalJsx                  =   ()  =>  {
       
        return (
            <div className="modal fade" id={this.changeStatusModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Change Asset Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {this.state.assetFormDataLoading ? <Loader />
                                : <form className={['p-2',].join(' ')} onSubmit={this.submitSettingsForm} id={`${this.changeStatusModalId}Form`}>
                                    <div className="row my-2">
                                        <label className="col-sm-4 col-form-label  require">Asset Status</label>
                                        <div className="col-sm-8">
                                            <TapSelect
                                                options={this.state.allStatusList}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'changeStatusForm', 'status')}
                                                value={this.state.allStatusList.find(u => this.state.changeStatusForm.status == u.value)}
                                                isClearable={true}
                                                placeholder="Select Asset Status"
                                                autoCompelete="off"
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <label className="col-sm-4 col-form-label  require">Date of Status Change</label>
                                        <div className="col-sm-8 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.changeStatusForm.date_time
                                                        ? moment(this.state.changeStatusForm.date_time).toDate()
                                                        : false
                                                }
                                                name="date_time"
                                                onChange={(value, event) => {   this.formDateHandler('date_time', value, 'changeStatusForm','YYYY-MM-DD HH:mm:ss')}}
                                                dateFormat="dd-MMM-yyyy hh:mm a"
                                                className={"form-control"}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                timeIntervals={5}
                                                timeCaption="Time"
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                required={true}
                                                placeholderText={`Please Enter Date of Transaction`}
                                            />
                                            <TapIcon.imageIcon icon={TapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <label className="col-sm-4 col-form-label  require">Reason</label>
                                        <div className="col-sm-8">
                                            <textarea
                                                name="reason"
                                                value={this.state.changeStatusForm.reason}
                                                onChange={(e) => this.formInputHandler(e, 'changeStatusForm')}
                                                className="form-control"
                                                style={{ height: "100px" }}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <label className="col-sm-4 col-form-label"></label>
                                        <div className="col-sm-8 ">
                                            <div>
                                                <TapIcon.FontAwesomeIcon icon={TapIcon.faInfoCircle} />
                                                <span className=' mx-2'>Asset is in {this.state.assetDetail && this.state.assetDetail.status_text} status since {this.state.assetDetail && this.state.assetDetail.status_change_date_display} with reason :: {this.state.assetDetail && this.state.assetDetail.reason_for_status}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.assetDetail && this.state.assetDetail.can_access == false ? <div className='text-end'>
                                            <span className='form-text text-sm mx-2 text-danger'>You don't have permisson to change Status of this asset</span>
                                        </div>
                                            : null}
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => { this.setState({ changeStatusForm: { ...this.state.changeStatusForm, status: '', reason: '' } }) }}>Cancel</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.changeStatusFormSubmitting || this.state.assetDetail?.can_access == false} form={`${this.changeStatusModalId}Form`}>Update {this.state.changeStatusFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
    
    render (){
        return (<Ax>
            {this.changeStatusModalJsx()}
        </Ax>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(ChangeAssetStatus);