import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import tapIcon from '../../../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Ax from "../../../../../components/hoc/Ax";

class RunningStatusViewModal  extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            access_token                :   '',
            scsRunningData              :   null,
            assetData                   :   null
        }
    }
    
    componentDidMount               =   ()  =>  {
        this.runningStatusModal                =   new Modal(document.getElementById('runningStatusModal'), {keyboard: false, backdrop :true});
        
    }
   
    runningStatusModalInit               =   (runningData)  =>  {
       
        this.setState({
            scsRunningData             :   runningData,
            assetData                   :   runningData && runningData.asset_profile ? runningData.asset_profile : null   
        },() => {
            this.runningStatusModal.show()
        });
    }
    
    closeModal      =   ()  => {
       this.runningStatusModal.hide()
    }

    runningStatusModalJsx                  =   ()  =>  {
    
        return (
            <div className="modal fade" id="runningStatusModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkItemModalLabel">{this.state.scsRunningData && this.state.scsRunningData.alias_name ? this.state.scsRunningData.alias_name : "-"}</h5>
                            <button type="button" className="btn-close" onClick={this.closeModal} aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body ">
                            <table className="table table-bordered align-items-center  table-sm table-hover">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th className="text-center" style={{ width: "10%" }}>CounterLog</th>
                                        <th className="text-center" style={{ width: "7%" }}>Interval</th>
                                        <th className="text-center" style={{ width: "11%" }}>Last CS Done</th>
                                        <th className="text-center" style={{ width: "11%" }}>Next CS Due</th>
                                        <th className="text-center" style={{ width: "13%" }}>Latest Counter Log</th>
                                        <th className="text-center" style={{ width: "10%" }}>Total Running</th>
                                        <th className="text-center" style={{ width: "13%" }}>Running %</th>
                                        <th className="text-center" style={{ width: "10%" }}>Remaining %</th>
                                        <th className="text-center" style={{ width: "14%" }}>Expected Due Date</th>
                                    </tr>
                                </thead>
                                <tbody  >
                                    {this.state.scsRunningData && this.state.scsRunningData.interval && this.state.scsRunningData.interval.length > 0 ? this.state.scsRunningData.interval.map((data, index) => {
                                        let remain_display = "Overdue";
                                        if((data.latest_cl && data.last_service) && Number(data.running_percentage) <=100){
                                           let remaining_percetage  =   100 - data.running_percentage;
                                           remain_display = parseFloat(remaining_percetage).toFixed(2)
                                        }

                                        return <tr key={index}>
                                            <td>{data.label} ({data.unit})</td>
                                            <td className='text-end'>{data.interval && data.status ==  'Y' ? data.interval : "Disabled"}</td>
                                            <td className='text-end'>{data.last_service ? <span>{data.last_service} </span> : "-" }</td>
                                            <td className='text-end'>{data.next_service && data.status == 'Y' ? <span>{data.next_service} </span> : "-"} </td>
                                            <td className='text-end'>{data.latest_cl} </td>
                                            <td className='text-end'>{data.status == 'Y' ? ((data.latest_cl && data.last_service) ?  <span>{data.total_running} {data.key == "days_check" &&  <sup>*</sup>}</span>  : "NA")    : "-" }</td>
                                            <td className='text-end'>{data.status == 'Y' ? ((data.latest_cl && data.last_service) ? <div className="progress">
                                                <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${data.running_percentage}%`, backgroundColor: `${data.bar_bg_color}` }}
                                                    aria-valuemin="0" aria-valuemax="100">
                                                </div>
                                            </div> 
                                             : null)  : ""} 
                                                <div className="text-center">{data.next_service && data.last_service && data.status == 'Y' ? data.running_percentage + '%' : "NA"} </div>
                                            </td>
                                            <td className='text-center'>{data.status == 'Y' ? ((data.latest_cl && data.last_service) ? <span>{remain_display} {remain_display !== "Overdue" ? "%" : ""}</span> : "NA" ) : "-"}</td>
                                            <td className='text-center' >{data.status == 'Y' && data.next_due_date ? <span>{data.next_due_date} {data.running_percentage > 100 ? <sup>#</sup>: null}</span> : "-"}</td>

                                        </tr>
                                    }) : null}
                                </tbody>

                            </table>
                            {this.state.scsRunningData && this.state.scsRunningData.parameter && this.state.scsRunningData.parameter.execute_display && this.state.scsRunningData.parameter.execute_display.text ?
                                <div className="mt-2"><b>Parameter Value on Last CS Execution Date : </b>
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faCircle} style={{ color: `${this.state.scsRunningData.parameter.execute_display.color}` }} />
                                    {this.state.scsRunningData.parameter.execute_display.text}</div> : null}


                            <div className="text-end ">
                                <div className="small"> * Total Running is Calculated from Last CS Done & Current Date</div>
                                <div className="small"> # Overdue since this date</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<Ax>
            {this.runningStatusModalJsx()}
        </Ax>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RunningStatusViewModal);