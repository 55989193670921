import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import { DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import { HRM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';

class EmployeeWorkingReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            'employee_id'            :   [],
            date_range               :   'current_month',
            date_range_start         :   '',
            date_range_end           :   '',
        };
        
        this.reportFilterFormLabel          =   {
            'employee_id'              :   'Employee : ',
            date_range                 :   'Date Range : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedAssets                      :   [],
            listingData                         :   [],
            listingMeta                         :   null,
            iam_user_sites                      :   [],
            searchedEmployees                   :   [],
            all_periods                         :   []
        };

         this.reportUrl                 =       HRM_API_BASE_URL_2 + '/report/employee-working-logsheet';

    }
    
    
    componentDidMount() {
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props);
        }, 1000);
      
    }
    
    
    initilaizeAppDataToFilterForm           =   (pr)  =>  {
        HttpAPICall.withAthorization('GET',this.reportUrl, this.props.access_token, {action:"formData"}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                access_token         :       pr.access_token,
                formDataLoaded      :           true,
                all_periods       :       respData && respData.periods && respData.periods.length > 0 ? respData.periods : [],
              
            },() => {
                this.reportFilterhandler();

            });
           
        })
       
           
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'employee_id') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.label : '';
                    }
                    if(key == 'date_range') {
                        let  start_range        =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range           =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY');
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`;
                        show_val                =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                   
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm,action:"data"};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData,
                reportDownloadBtn           :   true,
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, { action : 'download',...this.state.submittedReportFilterForm} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems                  :       [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            searchedEmployees                       :       [],
        }, () => {
            this.loadListingTblData(1);
        });
    }

    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Employee Working Report</h3>
                <div className="text-end mt15">
                  
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Employee</label>
                    <div className="col-sm-6">
                        <InputEmployeeSearch
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: { ...this.state.reportFilterForm, employee_id: selectedOption && selectedOption.value ? selectedOption.value : "" },
                                    searchedEmployees: selectedOption  ? selectedOption : ""
                                });
                            }}
                            placeholder="Select Employee"
                            value={this.state.searchedEmployees ? this.state.searchedEmployees : ""}
                        />
                    </div>
                </div>
                <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end require">Select Period</label>
                            <div className="col-sm-6">
                                <PeriodSelectionWithCustom
                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                    value={this.state.reportFilterForm.date_range}
                                    startDate={this.state.reportFilterForm.date_range_start}
                                    endDate={this.state.reportFilterForm.date_range_end}
                                    onSelectPeriod={(period, startDate, endDate) => {
                                        this.setState({
                                            reportFilterForm
                                                : {
                                                ...this.state.reportFilterForm,
                                                date_range: period,
                                                date_range_start: startDate ? startDate : null,
                                                date_range_end: endDate ? endDate : null
                                            }
                                        });
                                    }}
                                    menuPlacement="bottom"
                                    required={true}
                                />
                            </div>
                        </div>  

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
        
            { this.state.listing_loading
                ? <Loader />
                : (this.state.reportDownloadBtn == true ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
     
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "12%" }}>Date</th>
                        <th scope="col" style={{ width: "10%" }} className="text-start">Shift</th>
                        <th scope="col" style={{ width: "27%" }} className="text-start">Asset Name (Code)</th>
                        <th scope="col" style={{ width: "12%" }} className="text-start">Counterlog Reading</th>
                        <th scope="col" style={{ width: "12%" }}>Total Running in Shift</th>
                        <th scope="col" style={{ width: "27%" }}>Productivity</th>
                    </tr>
                </thead>
                <tbody>
                  

                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {
                            return (
                                data && data.shifts && data.shifts.length > 0 ? data.shifts.map((sh, key) => {
                                    return (
                                        <tr key={index + key}>
                                            <td className='text-center'>{data.date_display ? data.date_display : " "}</td>
                                            <td>{sh.shift_name}</td>
                                            <td>{sh.asset_name} <div> {sh.asset_code && <span className='form-text text-sm'> Code : {sh.asset_code}</span>}</div></td>
                                            <td>{sh.counterlog && sh.counterlog.length > 0 ? sh.counterlog.map(cl => { return (<div className='my-1'>{cl.label} : {cl.start}</div>) }) : "NA"}</td>
                                            <td>{sh.counterlog && sh.counterlog.length > 0 ? sh.counterlog.map(cl => { return (<div className='my-1'>{cl.label} : {cl.running ? cl.running : 0}</div>) }) : "NA"}</td>
                                            <td>{sh.production && sh.production.length > 0 ? sh.production.map(pr => { return (<div className='my-1'>{pr}</div>) }) : "NA"}</td>
                                        </tr>
                                    )
                                }
                                

                                )
                                :
                                 <tr key={index+1}>
                                   <td className='text-center'>{data.date_display ? data.date_display : " " }</td>
                                   <td colSpan={6}></td>
                                </tr>
                            )

                        }))
                        : <tr><td className="text-center" colSpan={7}>No Record Found</td></tr>
                    } 

                </tbody>
            </table>
        </Ax>);
    }

  
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
             <Helmet><title>Employee Wise Compliance - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.reportSearchClear}  />
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                            : <Loader />
                        }

                        {/* {this.state.reportDownloadBtn == true
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        } */}
                    </div>
                </div>
            </div>
          
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
    };
};

export default connect(mapStateToProps)(EmployeeWorkingReport);