import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import * as actions from '../../../store/actions/index'
import Status from '../../../components/ui/Status';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';


 class InvoiceRuleList extends AppBaseComponent {

    constructor() {
        super();

        
        this.state = {
            minimizeTable                    :          false,
            listing_loading                  :          false,
            listing_tbl_page                 :          1,
            listingMeta                      :          null,
            invoice_listing                  :          [],
            totalListingCount                :          0,
            invoiceId                        :          '',
            detail_loading                   :          false,
            invoiceDetail                    :          null,
            invoiceName                      :          '',
            changeStatusForm                :           {
                status                       :          '',
                current_status              :           '',
                rule_number                 :           '',
                name                        :           '',
                id                          :           ''
            },
            status_form_loading              :          false,
            allStatus                        :          [{value:2,label : "Inactive"},{value:1,label:"Active"}]
        }
    }

    componentDidMount(){  
        this.loadListingTblData(1)
        if(this.props.location && this.props.location.state && this.props.location.state.invoiceId){
            this.viewDetail(this.props.location.state.invoiceId)
        }
        this.invoiceStatushModal              =       new Modal(document.getElementById('invoiceStatushModal'), {keyboard: false, backdrop :false});
    }


     //************************GET consumption LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
         this.setState({listing_loading: true, listing_tbl_page: page});
         let params                      =   {page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/invoice_rule', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                invoice_listing          :       respData.data,
                listingMeta              :       respData.meta,
                totalListingCount        :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
       
    }

    viewDetail      =   (transaction_id)        =>      {
        this.setState({minimizeTable : true})
        if(transaction_id !== this.state.invoiceId){
            this.getInvoiceDetail(transaction_id)
         }
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable : false})
    }

    getInvoiceDetail        =       (transaction_id)      =>      {
        this.setState({detail_loading : true})
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/invoice_rule/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                invoiceDetail       :       respData.data,
                invoiceId           :       respData.data.id,
                invoiceName         :       respData.data.name,
            })
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({detail_loading : false}));      
    }

    changeStatusModalInit       =   (data)      =>      {
        
         this.invoiceStatushModal.show()
         this.setState({status_form_loading : true})
         if(data){
             
            this.setState({changeStatusForm : {
                current_status : data.status,
                status         : data.status == 1 ? 2 : 1,
                name           : data.name,
                rule_number    : data.rule_number,
                status_color   :    data.status_color,
                status_text   :  data.status_text,
                id            :   data.id
            }},() => this.setState({status_form_loading : false}))
         }
        
    }

    editInvoice         =       ()      =>      {
        this.props.history.push({ pathname: `/invoice_rule_add`, state: { invoiceData: this.state.invoiceDetail } })
    }

    submitChangeStatusForm      =       (e)     =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true });
        let formData = {id : this.state.changeStatusForm.id,status : this.state.changeStatusForm.status}
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/invoice_rule/change_status', this.props.access_token, {}, {...formData}, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.invoiceStatushModal.hide()
           this.loadListingTblData(this.state.listing_tbl_page)
           if(this.state.minimizeTable == true){
            this.getInvoiceDetail(this.state.invoiceId)
           }
            this.setState({
                changeStatusForm        :       {
                    status              :       '',
                    current_status      :       '',
                    rule_number         :       '',
                    name                :       '',
                    id                 :        ''
                },
            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
            
    }

    invoiceRuleListJsx            =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Invoice Rule</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <Link to="/invoice_rule_add">
                        <button type="button" className="btn btn-primary">
                            New Invoice Rule
                        </button>
                    </Link> : null}

                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Invoice Rule : {this.state.invoiceName ? this.state.invoiceName : " "} </h3>
                            <div className="text-end mt15">
                                {this.state.invoiceDetail && this.state.invoiceDetail.can_update == "Y" 
                                ?  <button type="button" className="btn btn-secondary" disabled={this.state.detail_loading ? true : false} onClick={() => this.changeStatusModalInit(this.state.invoiceDetail)}>
                                    Change Status
                                </button>
                                :null}
                                {this.state.invoiceDetail && this.state.invoiceDetail.can_update == "Y" 
                                ?  <button type="button" className="btn btn-secondary" disabled={this.state.detail_loading ? true : false} onClick={this.editInvoice}>
                                    Edit
                                </button>
                                :null}
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }


        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.invoiceRuleTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4 pageTbl bg-white">{this.invoiceViewJsx()}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    invoiceRuleTableJsx            =   ()   =>   {
      
        return (<Ax>

            <table className="table table-bordered bg-white ">
                <thead className="table-secondary">
                { this.state.minimizeTable
                    ? (<tr>
                        <th scope="col" style={{width:"15%"}}>Invoice Rule</th>
                    </tr> )
                    :   ( 
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                        <th scope="col" style={{ width: "30%" }} className="text-start">Name</th>
                        <th scope="col" style={{ width: "30%" }} className="text-start">Asset Type</th>
                        <th scope="col" style={{ width: "15%" }}>Basic Rate / Days</th>
                        <th scope="col" style={{ width: "12%" }}>Linked with Assets</th>
                        <th scope="col" style={{ width: "13%" }}>Status</th>
                        <th scope="col" style={{ width: "5%" }}>Action</th>
                    </tr>)}
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.invoice_listing.length > 0
                            ? (this.state.invoice_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <a role="button" className="link-primary" onClick={() => this.viewDetail(item.id)}>{item.name}</a>
                                                        <br /><small>{item.rule_number}</small>
                                                        <small style={{ float: 'right' }}><Status color={item.status_color}>{item.status_text}</Status></small>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                                <td><a role="button" className="link-primary text-center" onClick={() => this.viewDetail(item.id)}>{item.name}</a></td>
                                                <td>{item.asset_type ? item.asset_type.asset_type_name : "-"}</td>
                                                <td className='text-center'><span>{item.rate ? item.rate : ''}</span> / {item.days} Days</td>
                                                <td className='text-center'>{item.linked_asset_count ? item.linked_asset_count : "-"}</td>
                                                <td className='text-center'><Status color={item.status_color}>{item.status_text}</Status></td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.changeStatusModalInit(item)}>Change Status</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    invoiceViewJsx              =       ()      =>      {
        let invoice = this.state.invoiceDetail
        return (<div className='p-2'>
            {this.state.detail_loading ? <Loader />
                : <Ax>
                    {!this.state.invoiceDetail ? null
                        : <div>
                            <div className="tab_content_wrapper my-2" >
                                <span className="content_heading">Basic Details </span>
                            </div>
                            <table className="table table-hover table-bordered bg-white mb-0" style={{ marginLeft: "10px" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "30%" }} >Invoice Rule Name</td>
                                        <th>{invoice.name ? invoice.name : "-"} </th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%" }} >Rule Number</td>
                                        <th>{invoice.rule_number ? invoice.rule_number : "-"} </th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%" }} >Asset Type</td>
                                        <th>{invoice.asset_type ? invoice.asset_type.asset_type_name : "-"} </th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%%" }} >Status</td>
                                        <th>{invoice.status ? <Status color={invoice.status_color}>{invoice.status_text}</Status> : "-"} </th>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper my-2" >
                                <span className="content_heading">Rate </span>
                            </div>
                            <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "30%" }} >Basic Rate</td>
                                        <th style={{ width: "20%" }}>{invoice.rate ? invoice.rate : "-"} </th>
                                        <td style={{ width: "10%" }} >Days</td>
                                        <td style={{ width: "50%" }}><b>{invoice.days ? invoice.days : "-"}</b>

                                            <span className='text-sm fw-100 float-end'>Adjust Billable Days as per Calendar Month : <b>{invoice.month_calculation_applicable == "yes" ? "Yes" : "No"}</b> </span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                <tbody>
                                    <tr>
                                        {invoice.asset_type && invoice.asset_type.hour_meter == "yes"
                                            ? <Ax>
                                                <td style={{ width: "30%" }} >Hours</td>
                                                <th colSpan={invoice.asset_type && invoice.asset_type.odo_meter == "no" ? 3 : 1}>{invoice.hours ? invoice.hours : "-"} </th>
                                            </Ax>
                                            : null}
                                        {invoice.asset_type && invoice.asset_type.odo_meter == "yes"
                                            ? <Ax>
                                                <td style={{ width: "30%" }} >KM</td>
                                                <th colSpan={invoice.asset_type && invoice.asset_type.hour_meter == "no" ? 3 : 1}>{invoice.km ? invoice.km : "-"} </th>
                                            </Ax>
                                            : null}

                                    </tr>
                                    <tr>
                                        {invoice.asset_type && invoice.asset_type.operation_cycle == "yes"
                                            ? <Ax>
                                                <td style={{ width: "30%" }}>Operation Cycle</td>
                                                <th colSpan={3}>{invoice.opc ? invoice.opc : "-"} </th>
                                            </Ax>
                                            : null}

                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper my-2" >
                                <span className="content_heading">Additions </span>
                            </div>
                            <div className='counterlog bg-light' style={{ padding: "10px" }}>
                                <div className="tab_content_wrapper mb-2" >
                                    <span className="content_heading">Rate  per Day</span>
                                </div>
                                <table className="table table-hover table-bordered  bg-white mb-0 " style={{ marginLeft: "10px" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "30%" }} >Day Threshold Limit</td>
                                            <th style={{ width: "20%" }} >{invoice.day_threshold_limlit ? invoice.day_threshold_limlit : "-"} </th>
                                            <td style={{ width: "30%" }} >Rate For Excess</td>
                                            <th  >{invoice.rate_day_excess ? invoice.rate_day_excess : "-"} </th>
                                        </tr>
                                    </tbody>
                                </table>
                                {invoice.hr_excess_applicable == "yes" && invoice.asset_type && invoice.asset_type.hour_meter == "yes"
                                    ? <Ax>
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Rate per Hour</span>
                                        </div>
                                        <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }} >Hour Threshold Limit</td>
                                                    <th style={{ width: "20%" }}>{invoice.hour_threshold_limlit ? invoice.hour_threshold_limlit : "-"} </th>
                                                    <td style={{ width: "30%" }} >Rate For Excess</td>
                                                    <th>{invoice.rate_hour_excess ? invoice.rate_hour_excess : "-"} </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Ax>
                                    : null}
                                {invoice.km_excess_applicable == "yes" && invoice.asset_type && invoice.asset_type.odo_meter == "yes"
                                    ? <Ax>
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Rate per KM</span>
                                        </div>
                                        <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }} >KM Threshold Limit</td>
                                                    <th style={{ width: "20%" }}>{invoice.km_threshold_limlit ? invoice.km_threshold_limlit : "-"} </th>
                                                    <td style={{ width: "30%" }} >Rate For Excess</td>
                                                    <th>{invoice.rate_km_excess ? invoice.rate_km_excess : "-"} </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Ax>
                                    : null}
                                {invoice.opc_excess_applicable == "yes" && invoice.asset_type && invoice.asset_type.operation_cycle == "yes"
                                    ? <Ax>
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Rate per Operation Cycle</span>
                                        </div>
                                        <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }} >Operation Cycle Threshold Limit</td>
                                                    <th style={{ width: "20%" }}>{invoice.opc_threshold_limlit ? invoice.opc_threshold_limlit : "-"} </th>
                                                    <td style={{ width: "30%" }} >Rate For Excess</td>
                                                    <th>{invoice.rate_opc_excess ? invoice.rate_opc_excess : "-"} </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Ax>
                                    : null}
                                {invoice.idle_days_applicable == "yes"
                                    ? <Ax>
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Maintenance Days / Idle Days</span>
                                        </div>
                                        <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }} >Minimum Idle Days</td>
                                                    <th style={{ width: "20%" }}>{invoice.idle_days_limlit ? invoice.idle_days_limlit : "-"} </th>
                                                    <td style={{ width: "30%" }} >Rate For Excess</td>
                                                    <th>{invoice.rate_idle_days ? invoice.rate_idle_days : "-"} </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Ax>
                                    : null}
                                {invoice.weekly_off_applicable == "yes"
                                    ? <Ax>
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Weekly Off</span>
                                        </div>
                                        <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }} >Days of the Week</td>
                                                    <th style={{ width: "20%" }}>{invoice.weekly_off_day && invoice.weekly_off_day.length > 0 ? invoice.weekly_off_day.map(w => { return (moment().day(w).format('dddd')) }).join(', ') : "-"} </th>
                                                    <td style={{ width: "30%" }} >Calculation Criteria</td>
                                                    <th className='text-capitalize'>{invoice.weekly_off_criteria ? invoice.weekly_off_criteria : "-"} </th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%" }} >Rate for Each {invoice.weekly_off_criteria ? <span className='text-capitalize'>{invoice.weekly_off_criteria}</span> : null} Off</td>
                                                    <th style={{ width: "20%" }}>{invoice.rate_weekly_off ? invoice.rate_weekly_off : "-"}  </th>
                                                    <td style={{ width: "30%" }} >Adjusting {invoice.weekly_off_criteria ? <span className='text-capitalize'>{invoice.weekly_off_criteria}</span> : null} off against other idle days</td>
                                                    <td>{invoice.weekly_off_idle_days_adjust == "yes" ? "Yes" : "No"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Ax>
                                    : null}
                                {invoice.productivity_applicable == "yes"
                                    ? <Ax>
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Productivity</span>
                                        </div>
                                        <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th className='text-center' style={{ width: "10%" }} >S.No</th>
                                                    <th style={{ width: "25%" }}>Activity Name</th>
                                                    <th style={{ width: "15%" }}>Material</th>
                                                    <th style={{ width: "15%" }}>Measuring Unit</th>
                                                    <th style={{ width: "15%" }}>Attachment</th>
                                                    <th style={{ width: "20%" }}>Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.productivity_meter && invoice.productivity_meter.length > 0 ? invoice.productivity_meter.map((p, k) => {
                                                    return (<tr>
                                                        <td className='text-center' style={{ width: "10%" }}>{k + 1}</td>
                                                        <td>{p.activity_display ? p.activity_display : "-"}</td>
                                                        <td>{p.material_display ? p.material_display : "-"}</td>
                                                        <td>{p.unit_display ? p.unit_display : "-"}</td>
                                                        <td>{p.attachment_display ? p.attachment_display : "-"}</td>
                                                        <td>{p.rate}</td>
                                                    </tr>
                                                    )
                                                }) : "-"}
                                            </tbody>
                                        </table>
                                    </Ax>
                                    : null}
                                {invoice.othercharges_applicable == "yes"
                                    ? <Ax>
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Other Charges</span>
                                        </div>
                                        <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th className='text-center' style={{ width: "10%" }} >S.No</th>
                                                    <th>Name</th>
                                                    <th style={{ width: "20%" }}>Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice.other_charges && invoice.other_charges.length > 0 ? invoice.other_charges.map((p, k) => {
                                                    return (<tr>
                                                        <td className='text-center' style={{ width: "10%" }}>{k + 1}</td>
                                                        <td>{p.name}</td>
                                                        <td>{p.charge}</td>
                                                    </tr>
                                                    )
                                                }) : "-"}
                                            </tbody>
                                        </table>
                                    </Ax>
                                    : null}
                            </div>
                            <div className="tab_content_wrapper my-2" >
                                <span className="content_heading">Deductions </span>
                            </div>
                            <div className='counterlog bg-light' style={{ padding: "10px" }}>
                                {invoice.bd_excess_applicable == "yes" || (invoice.fuel_consuption_applicable == "yes" && invoice.asset_type && invoice.asset_type.hour_meter == "yes") || (invoice.fuel_economy_applicable == "yes" && invoice.asset_type && invoice.asset_type.odo_meter == "yes")
                                    ? <div>
                                        {invoice.bd_excess_applicable == "yes"
                                            ? <Ax>
                                                <div className="tab_content_wrapper my-2" >
                                                    <span className="content_heading">Downtime</span>
                                                </div>
                                                <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width: "30%"}} >Type of Downtime</td>
                                                            <th style={{width:"20%"}} className="text-capitalize">{invoice.type_downtime_display}</th>
                                                            <td style={{width: "30%"}} >Threshold Limit (Hours)</td>
                                                            <th>{invoice.downtime_threshold_limlit ? invoice.downtime_threshold_limlit : "-"} </th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "30%" }} >Calculation Criteria</td>
                                                            <th style={{ width: "20%" }}>{invoice.downtime_calculation_criteria ? invoice.downtime_calculation_criteria == "fixed" ? "Fixed" : "Based on Calender Month" : "-"} </th>
                                                            <td style={{ width: "30%" }} >Rate For Excess</td>
                                                            <th>{invoice.downtime_calculation_criteria && invoice.downtime_calculation_criteria == "fixed" ? invoice.rate_downtime_excess : "Automatic"}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Ax>
                                            : null}
                                        {invoice.fuel_consuption_applicable == "yes" && invoice.asset_type && invoice.asset_type.hour_meter == "yes"
                                            ? <Ax>
                                                <div className="tab_content_wrapper my-2" >
                                                    <span className="content_heading">Approved Fuel Consumption Limit (Litre/Hours)</span>
                                                </div>
                                                <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "30%" }} >Approved Fuel Consumption Limit (Litre/Hours)</td>
                                                            <th style={{ width: "20%" }}>{invoice.fuel_consuption_limlit ? invoice.fuel_consuption_limlit : "-"} </th>
                                                            <td style={{ width: "30%" }} >Fuel Rate for Calculation</td>
                                                            <th>{invoice.fuel_rate ? invoice.fuel_rate : "-"} </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Ax>
                                            : null}
                                        {invoice.fuel_economy_applicable == "yes" && invoice.asset_type && invoice.asset_type.odo_meter == "yes"
                                            ? <Ax>
                                                <div className="tab_content_wrapper my-2" >
                                                    <span className="content_heading">Approved Fuel Economy Limit (Km/Litre)</span>
                                                </div>
                                                <table className="table table-hover table-bordered  bg-white mb-0" style={{ marginLeft: "10px" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "30%" }} >Approved Fuel Economy Limit (Km/Litre)</td>
                                                            <th style={{ width: "20%" }}>{invoice.fuel_economy_limlit ? invoice.fuel_economy_limlit : "-"} </th>
                                                            <td style={{ width: "30%" }} >Fuel Rate for Calculation</td>
                                                            <th>{invoice.fuel_economy_rate ? invoice.fuel_economy_rate : "-"} </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Ax>
                                            : null}
                                    </div>
                                    : <div className='text-center fw500'>No Deductions</div>}


                            </div>
                        </div>}
                </Ax>}

        </div>)
    }

    //***********************SEARCH assetMatrix JSX****************
    invoiceChangeStatusModalJsx               =        ()             =>        {
     
        return (
            <div className="modal fade" id="invoiceStatushModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="invoiceStatushModalLabel">Change Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="assetMatrixSearchForm" onSubmit={this.submitChangeStatusForm}>
                            {this.state.status_form_loading ? <Loader/>
                            : <div className='modal-body'>
                                <div className="row align-items-center mb-3">

                                    <label className="col-sm-3 col-form-label col-form-label-sm ">Invoice Rule</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.changeStatusForm.name}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3">

                                    <label className="col-sm-3 col-form-label col-form-label-sm ">Invoice Rule Number</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.changeStatusForm.rule_number}
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3">

                                    <label className="col-sm-3 col-form-label col-form-label-sm ">Current Status</label>
                                    <div className="col-sm-9">
                                        <Status color={this.state.changeStatusForm.status_color}>{this.state.changeStatusForm.status_text}</Status>
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3">

                                    <label className="col-sm-3 col-form-label col-form-label-sm require ">New Status</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.allStatus.filter(s => this.state.changeStatusForm.current_status !== s.value)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'changeStatusForm', 'status');
                                            }}

                                            value={this.state.allStatus.find(s => this.state.changeStatusForm.status == s.value)}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>

                            </div>}
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                                <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary">Save  {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

    render() {
       
        return (
            <ApplicationLayout>
                 <Helmet><title>Invoice Rule List</title></Helmet>
                {this.invoiceRuleListJsx()}
                {this.invoiceChangeStatusModalJsx()}
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(InvoiceRuleList);

