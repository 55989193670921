import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Workflow from './Workflow';
import AdditionalAttribute from '../../AdditionalAttribute';
import ProjectType from './ProjectType';


class ProjectConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);


        this.state                          =        {
           enableTransactionId                 :       false,
        } 
    }

    render() {

        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active " id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                            </button>
                        </li>
                       
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="project-tab" data-bs-toggle="tab" data-bs-target="#project"
                                type="button" role="tab" aria-controls="project" aria-selected="true">Project Type
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="attribute-tab" data-bs-toggle="tab" data-bs-target="#attribute"
                                type="button" role="tab" aria-controls="attribute" aria-selected="true">Addititonal Attribute
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">

                       
                        <div className="tab-pane show active" id="workflow" role="tabpanel"  aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <Workflow/>
                            </div>
                        </div>
                        
                        <div className="tab-pane" id="attribute" role="tabpanel"
                            aria-labelledby="attribute-tab">
                            <div className="tab_content_header">
                                <AdditionalAttribute category="customer_project"/>
                            </div>
                        </div>
                        <div className="tab-pane" id="project" role="tabpanel"
                            aria-labelledby="project-tab">
                            <div className="tab_content_header">
                                <ProjectType/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(ProjectConfiguration);