import React from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import axios from 'axios';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from "../../../components/ui/Status"
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import TapSelect from '../../../components/ui/TapSelect';
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import download from '../../../services/DownloadFile';
import PurchaseOrderDetailModal from '../../inventory/purchaseOrder/PurchaseOrderDetailModal';
import moment from 'moment';
import PurchaseDetail from '../../inventory/purchase/PurchaseDetail';

class VendorList extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initContactForm               =      {
            name                           :      "",
            email                          :      "",
            mobile                         :      ""
        }

        this.initAddressForm               =        {
            label                          :       "Primary Address",
            gstin                          :       '',
            address1                       :       '',
            address2                       :       '',
            city                           :       '',
            landmark                       :       '',
            district_name                  :       null,
            pincode                        :       null,
            country_id                     :       99,
        }

        this.initVendorSearchForm               =        {
           search_name                  :           '',
           search_code                  :           '',
           search_state_ids             :           [],
           search_gstin                 :           '',
           search_mobile                :           '',
           search_email                 :           '',
           search_domains               :           [],
           search_status                :           []
        }

        this.vendorSearchFormLabel         =   {
            search_name                  :           'Vendor Name : ',
           search_code                  :           'Vendor code : ',
           search_state_ids             :           'Location (Source of Supply) : ',
           search_gstin                 :           'GSTIN : ',
           search_mobile                :           'Phone Number : ',
           search_email                 :           'Email : ',
           search_domains               :           'Domains : ',
           search_status               :           'Status : '
        };

        this.initChangeStatusInit       =       {
            id                          :       '',
            status                      :       ''
        }

        this.state                          =     {
            vendorAddScreenView             :     false,
            vendorUpdateScreenView          :     false,
            minimizeTable                   :     false,
            vendorStateListLoaded           :     false,
            vendorFormDataLoaded            :     false,
            vendorAddFormData               :     [],
            stateList                       :     [],
            listing_loading                 :     false,
            details_loading                 :     false,
            listing_tbl_page                :     1,
            vendor_listing                  :     [],
            listingMeta                     :     null,
            totalListingCount               :       0,
            vendorDetails                   :     [],
            vendorViewDetails               :       [],
            addContactFormSubmitting        :       false,
            vendorBulkUploadFormSubmiting   :   false,
            addContactForm                  :      {...this.initContactForm},
            editContactDetails              :       false,
            allStateList                    :       [],
            allCountryList                  :       [],
            allDistrictList                 :       [],
            allPincodeList                  :       [],
            editAddressDetails              :       false,
            addAddressForm                  :       {...this.initAddressForm},
            vendorAddressFormDataLoaded     :       false,
            addAddressFormSubmitting        :       false,
            vendorSearchForm                :       {...this.initVendorSearchForm},
            searchFormDataLoaded            :       false,
            allDomainList                   :       [],
            allSearchStatesList             :       [],
            search_form_loading             :       false,
            submittedVendorSearchForm       :      {...this.initVendorSearchForm},
            formSearchedElems               :       [],
            reportDownloading               :       false,
            po_listing                      :       [],
            po_listing_loading              :       false,
            polistingMeta                   :       null,
            pototalListingCount             :       0,
            purchase_listing                :       [],
            purchase_listing_loading        :       false,
            purchaselistingMeta             :       null,
            purchasetotalListingCount       :       0,
            purchaseTransactionId           :       '',
            addNewDistrict                  :       false,
            addNewPinCode                   :      false,
            loading_address                 :       true,
            allStatusList                   :       [],
            changeStatusForm                :       {...this.initChangeStatusInit},
            saveFormSubmitting              :       false,
            //statusFormDataLoaded            
        };
        this.PODetailModalRef                 =   React.createRef();

        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.vendorAddByExcel   =   INVENTORY_API_BASE_URL_2  +   '/vendor_add_by_excel';
        this.purchaseOrderUrl   =   INVENTORY_API_BASE_URL_2 + '/purchase_order';

    }

    componentDidMount() {
      this.loadListingTblData(1);
        this.loadSearchFormData();
      this.searchVendorModal                  =   new Modal(document.getElementById('searchVendorModal'), {keyboard: false, backdrop :false});
      this.addContactModal                  =   new Modal(document.getElementById('addContactModal'), {keyboard: false, backdrop :false});
      this.addAddressModal                  =   new Modal(document.getElementById('addAddressModal'), {keyboard: false, backdrop :false});
      this.vendorBulkUploadModal            =   new Modal(document.getElementById('vendorBulkUploadModal'), {keyboard: false, backdrop :false});
      this.purchaseDetailsModal             =   new Modal(document.getElementById('viewPurchaseModal'), {keyboard: false, backdrop :false});
      this.changeStatusModal             =   new Modal(document.getElementById('changeStatusModal'), {keyboard: false, backdrop :false});
      //****************After Add and update ***************************/
        if(this.props.location && this.props.location.state && this.props.location.state.vendorId){
            this.viewVendorDetail(this.props.location.state.vendorId)
        }
        if(this.props.location && this.props.location.state && this.props.location.state.vendorData){
            this.setState({minimizeTable : true})
            this.viewVendorDetail(this.props.location.state.vendorData.id)
        }
    }

    //***********************GET VENDOR LISTING************************
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedVendorSearchForm};
        HttpAPICall.withAthorization('GET',  TapApiUrls.VENDOR_URL, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                vendor_listing    : respData.data,
                listingMeta       : respData.meta,
                totalListingCount : respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    loadSearchFormData      =       ()      =>      {
        if(!this.state.vendorFormDataLoaded){
            this.setState({search_form_loading : true})
            HttpAPICall.withAthorization('GET', TapApiUrls.INVENTORY + '/vendor_form_data', this.props.access_token, null, null, (response) => {
                let respData = response.data;

                this.setState({
                    vendorFormDataLoaded            :       true,
                    allDomainList                   :       respData && respData.domain && respData.domain.length > 0 ? respData.domain.map((s) => { return {value: s, label: s}}) : [],
                    allSearchStatesList             :       respData && respData.states && respData.states.length > 0 ? respData.states.map((s) => { return {value: s.id, label: s.name}; }) : [], 
                    allStatusList                   :        respData && respData.status && respData.status.length > 0 ? respData.status.map((s) => { return {value: s.key, label: s.status}; }) : [], 
                });
            }).then(() => this.setState({search_form_loading : false}));
        }
    }

    //***********************GET VENDOR FORM DATA****************
    vendorAddFormDataInit                 =   ()  =>  {
        
         if(!this.state.vendorAddressFormDataLoaded) {
            HttpAPICall.withAthorization('GET', TapApiUrls.VENDOR_URL + '/add_form_data', this.props.access_token, null, null, (response) => {
               let respData =  response.data;
                this.setState({
                    vendorAddressFormDataLoaded    :    true,
                    allCountryList                 :      respData && respData.country ?  respData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    vendorAddFormData              :    response.data,
                },() => {
                    if(this.state.addAddressForm && this.state.addAddressForm.country_id && !this.state.editAddressDetails){
                        this.countryChangeHandler(this.state.addAddressForm.country_id)
                    }
                });
            });
        }
       
    }

      //FUNCTION TO GET STATE LIST ON SELECTING COUNTRY
      countryChangeHandler              =        (selected_country,edit= false)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addAddressForm               :   edit == false ?  {...this.state.addAddressForm,state_id: '',district_name : '',pincode:''} : {...this.state.addAddressForm},
                    addNewDistrict              :   edit == false ? false : this.state.addNewDistrict ,
                    addNewPinCode               :    edit == false ? false : this.state.addNewPinCode ,
                });
            });
        }
        
    }

    //***************GET PARTICULAR VENDOR DETAILS**************
    getVendorDetails            =       (id)      =>      {
        this.vendorAddFormDataInit()
        HttpAPICall.withAthorization('GET', TapApiUrls.VENDOR_URL + '/' + id, this.props.access_token, null, null, (response) => {
            this.setState({
                vendorDetails           :    response.data.data,
                vendorUpdateScreenView  :   true
            });
        });
    }
    
   //****************OPEN  ADD VENDOR SCREEN*******************
    openAddVendorScreen       =      ()      =>     {
        this.props.history.push('/vendor/add')
    }

    //*********************OPEN  UPDATE VENDOR SCREEN********************
    openUpdateVendorScreen       =      (id)      =>     {
        if(this.state.vendorViewDetails && this.state.vendorViewDetails.id == id) {
            this.props.history.push({pathname: `/vendor/update`, state: {vendorData : this.state.vendorViewDetails }})
        } else {
            HttpAPICall.withAthorization('GET', TapApiUrls.VENDOR_URL + '/' + id , this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.props.history.push({pathname: `/vendor/update`, state: {vendorData : respData.data }})   
            });
        }
    }

     //*********************CLOSE UPDATE VENDOR SCREEN*************************
     updateVendorScreenClose      =      ()       =>     {
        this.setState({vendorUpdateScreenView:false})
    }

    //*******************AFTER ADD VENDOR SUBMIT FUNCTION********************
    afterAddNewVendorSubmit               =   (event) =>  {
        event.preventDefault();
        //Item is Added Successfully Need to refresh and close Add form
        this.setState({vendorAddScreenView : false, vendorUpdateScreenView : false,vendorDetails : []});
        //Load Vendor Listing :-
        this.loadListingTblData(1);
    }

    //******************VIEW VENDOR DETAILS***********************************
    viewVendorDetail          =      (id)        =>     {
        this.setState({ minimizeTable           :   true})
        this.setState({details_loading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.VENDOR_URL + '/' + id, this.props.access_token, null, null, (response) => {
            this.setState({
                vendorViewDetails       :    response.data.data,  
            });
        }).then(() => this.setState({details_loading: false}))
        this.poloadListingTblData(1,id)
        this.purchaseloadListingTblData(1,id)
    }

    //**************************CLOSE VIEW VENDOR DETAILS*********************
    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    //*****************************ADD CONTACT MODAL INIT*******************//
    addContactInit          =       ()      =>      {
        this.addContactModal.show()
    }

    //******************************EDIT CONTACT FORM************************
    editContact             =      (id)        =>     {
        if(id){
            let contactDetails = this.state.vendorViewDetails.contacts.find(i => i.id == id)
            this.setState({addContactForm : {...contactDetails},editContactDetails : true})
            this.addContactModal.show()
        }
    }

    //******************************SET PRIMARY CONTACT FORM***********************
    setPrimaryContact           =       (id)    =>      {
        HttpAPICall.withAthorization('PUT',  TapApiUrls.VENDOR_URL + '/contact_primary/' + this.state?.vendorViewDetails?.id + '/' + id, this.props.access_token, {}, {}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.viewVendorDetail(this.state.vendorViewDetails.id)
        }) 
    }

    //**************************DELETE PRIMARY CONTACT FORM**********************
    deletePrimaryContact           =       (id)    =>      {
        HttpAPICall.withAthorization('DELETE',  TapApiUrls.VENDOR_URL + '/contact_remove/' + this.state?.vendorViewDetails?.id + '/' + id, this.props.access_token, {}, {}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.viewVendorDetail(this.state.vendorViewDetails.id)
        }) 
    }

    //***********************ADD CONTACT FORM SUBMIT****************************
    addContactFormSubmit        =       (e)      =>      {
       e.preventDefault()
   
       if(this.state.addContactForm.name == '' && this.state.addContactForm.email == '' && this.state.addContactForm.mobile == ''){
        toast.error('Please fill the form', { position: toast.POSITION.TOP_RIGHT });
       }else{
        this.setState({addContactFormSubmitting : true});
        if(this.state.editContactDetails){
            HttpAPICall.withAthorization('PUT',  TapApiUrls.VENDOR_URL + '/contact_edit/' + this.state?.vendorViewDetails?.id + '/' + this.state.addContactForm.id, this.props.access_token, {}, {...this.state.addContactForm}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addContactModal.hide()
                this.viewVendorDetail(this.state.vendorViewDetails.id)
                this.setState({addContactForm : {...this.initContactForm}})
            }).then(() => this.setState({addContactFormSubmitting: false}));
        }else{
            let frmData = {
                ...this.state.addContactForm,
                vendor_id : this.state.vendorViewDetails ? this.state.vendorViewDetails.id : null
            };
            HttpAPICall.withAthorization('POST',  TapApiUrls.VENDOR_URL + '/contact_add', this.props.access_token, {}, {...frmData}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addContactModal.hide()
                this.viewVendorDetail(this.state.vendorViewDetails.id)
                this.setState({addContactForm : {...this.initContactForm}})
            }).then(() => this.setState({addContactFormSubmitting: false}));
        }
       }
       
    }

    //**************************ADD VENDOR ADDRESS SECTION*************************
    addAddressInit             =      ()        =>      {
        this.addAddressModal.show()
        this.setState({
             editAddressDetails            :         false,
            addNewDistrict                 :        false,
            addNewPinCode                  :        false,
            loading_address                :        true,
             addAddressForm                 :   {...this.initAddressForm}
        })
        this.vendorAddFormDataInit()
        setTimeout(() => this.setState({loading_address : false}),2000)
   
    }

    getAddFormData          =       ()      =>      {

    }

    editVendorAddress           =       (id)      =>      {
      this.setState({loading_address :  true})
        if(id){
            let addressDetails = this.state.vendorViewDetails.addressList.find(i => i.id == id);
            this.vendorAddFormDataInit()
             this.countryChangeHandler(addressDetails.country_id,true)
            this.stateChangeHandler(addressDetails.state_id,true)
            if(addressDetails.district_id){
                this.districtChangeHandler(addressDetails.district_name,addressDetails.state_id)
            }
            this.setState({addAddressForm : {...addressDetails},editAddressDetails : true})
            this.addAddressModal.show()
            setTimeout(() => this.setState({loading_address : false}),2000)
        }
       
    }

    //********************FUNCTION TO GET DISTRICT LIST ON SELECTING STATE************
    stateChangeHandler              =        (selected_state,edit=false)    =>  {
        if (selected_state) {
            let params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList  :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addAddressForm              :   edit == false ?  {...this.state.addAddressForm,pincode:'',pincode_name:''} : {...this.state.addAddressForm}
                });
            });
        }
    }

    //*******************FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT*********************
    districtChangeHandler              =        (selected_district,state_id)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            let params = {district_name : selectedDistrict,state_id:state_id ? state_id : this.state.addAddressForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList  :  response.data.map((s) => { return {value: s.pincode, label: s.pincode}; })
                });
            });
        }
    }

    //*************************DELETE VENDOR ADDRESS************************ */
    deleteVendorAddress              =           (id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', TapApiUrls.VENDOR_URL + '/address/' + this.state?.vendorViewDetails?.id + '/' + id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.addAddressModal.hide();
                        this.viewVendorDetail(this.state.vendorViewDetails.id)

                    }).then(() => {

                    });
                }
            });
    }


    //*********************************SUBMIT ADDRESS FORM****************** */
     addAddressFormSubmit            =       (e)      =>      {   
        e.preventDefault()
         let frmData = { ...this.state.addAddressForm, vendor_id: this.state?.vendorViewDetails?.id }
         this.setState({ addAddressFormSubmitting: true })
         if (this.state.editAddressDetails) {
             HttpAPICall.withAthorization('PUT', TapApiUrls.VENDOR_URL + '/address', this.props.access_token, {}, { ...frmData }, (response) => {
                 toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                 this.addAddressModal.hide();
                 this.viewVendorDetail(this.state.vendorViewDetails.id)
                 this.setState({ addAddressForm: { ...this.initAddressForm } })
             }).then(() => this.setState({ addAddressFormSubmitting: false }));
         } else {
             HttpAPICall.withAthorization('POST', TapApiUrls.VENDOR_URL + '/address', this.props.access_token, {}, { ...frmData }, (response) => {
                 toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                 this.addAddressModal.hide();
                 this.viewVendorDetail(this.state.vendorViewDetails.id)
                 this.setState({ addAddressForm: { ...this.initAddressForm } })
             }).then(() => this.setState({ addAddressFormSubmitting: false }));
         }
     }
     
    /**
     * Bulk Upload :-
     */
    vendorBulkUploadModalInit               =   ()  =>  {
        document.getElementById('addVendorFileSelector').value = null;
        //Initialize Search Form :-
        this.vendorBulkUploadModal.show();
    }
    
    downloadSampleFile                  =   ()      => {
        HttpAPICall.withAthorization('GET', TapApiUrls.INVENTORY + '/vendor_download_sample_add_excel', this.props.access_token,
        {} , {} , (response) => 
        {   DownloadFile.file(response.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
            this.vendorBulkUploadModal.hide();
        })
    }
    
    vendorBulkUploadFormSubmit            =   (event) => {
        event.preventDefault();
        this.setState({vendorBulkUploadFormSubmiting : true});
        const data          =   new FormData(document.getElementById('vendorBulkUploadForm'));
        let headers         =   { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data','Authorization' : 'Bearer ' + this.props.access_token };
        axios.post(this.vendorAddByExcel, data, { headers: headers })
        .then((response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.vendorBulkUploadModal.hide();
            //Load Add Item Form Data :-
            DownloadFile.file(response.data.generated_file.file_path);
            setTimeout(() => {
                this.loadListingTblData(1);
            },1000)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to upload vendor Add File.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({vendorBulkUploadFormSubmiting : false});
        });
    }

    vendorSearchFormInit        =       ()  => {
       
       
        this.searchVendorModal.show()
    }

    vendorSearchFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
       
        let serachFormKeys                  =   Object.keys(this.state.vendorSearchForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};

        serachFormKeys.map((key)        =>  {
            let label                       =   this.vendorSearchFormLabel[key];
            let value                       =   this.state.vendorSearchForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_name') {
                        show_val            =   this.state.vendorSearchForm.search_name;
                    }
                    if(key == 'search_code') {
                        show_val            =   this.state.vendorSearchForm.search_code;
                    }
                    if(key == 'search_state_ids') {
                        show_val            =   this.state.allSearchStatesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.allStatusList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                   
                    if(key == 'search_domains') {
                        show_val            =   this.state.allDomainList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_gstin') {
                        show_val            =   this.state.vendorSearchForm.search_gstin;
                    }
                    if(key == 'search_mobile') {
                        show_val            =   this.state.vendorSearchForm.search_mobile;
                    }
                    if(key == 'search_email') {
                        show_val            =   this.state.vendorSearchForm.search_email;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedVendorSearchForm           :   {...this.state.vendorSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
           this.searchVendorModal.hide();
        });
    }

    vendorSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems             :   [],
            vendorSearchForm              :    {...this.initVendorSearchForm},
            submittedVendorSearchForm     :    {...this.initVendorSearchForm},
        }, () => {
            this.loadListingTblData(1);
        });
    }

    downloadVendorWiseReport              =    ()     =>         {
        this.setState({ reportDownloading: true});

        let params                      =   {...this.state.submittedVendorSearchForm, download : "download"};
        HttpAPICall.withAthorization('GET',  TapApiUrls.INVENTORY + '/report/vendor-wise-purchase-order-item' , this.props.access_token,params,{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({reportDownloading: false}));
    }

    downloadVendorReport              =    ()     =>         {
        this.setState({ reportDownloading: true});
        let params                      =   {...this.state.submittedVendorSearchForm, download : "download"};
        HttpAPICall.withAthorization('GET',  TapApiUrls.VENDOR_URL , this.props.access_token,params,{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({reportDownloading: false}));
    }

    //******PO LISTING******************** */
    poloadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({po_listing_loading: true});
        let params                      =   {search_vendor_ids : [id], page:page};
        HttpAPICall.withAthorization('GET', this.purchaseOrderUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                po_listing            :       respData.data,
                polistingMeta         :       respData.meta,
                pototalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({po_listing_loading: false}));
    }

    viewPODetail          =       (id)           =>      {
        this.PODetailModalRef.current.modalPOInit(id);  
    }

    //*********************PURCHASE LISTING****************** */
    purchaseloadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({purchase_listing_loading: true, listing_tbl_page: page});
        let params                      =   {search_vendor_ids : [id],page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.INVENTORY + '/purchases', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                purchase_listing    :       respData.data,
                purchaselistingMeta         :       respData.meta,
                purchasetotalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({purchase_listing_loading: false}));
    }

    viewPurchaseDetail          =       (id)        =>      {
        this.setState({purchaseTransactionId : id},() => this.purchaseDetailsModal.show())
    }

    changeStatusInit        =       ()      =>      {
        this.changeStatusModal.show();
        let detail = this.state.vendorViewDetails ? this.state.vendorViewDetails : null;
        this.setState({
            changeStatusForm    :       {
                status          :       detail && detail.status ? detail.status : '',
                id              :       detail && detail.id ? detail.id : ""
            }
        })
    }

    vendorListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Vendor</h3>
                <div className="text-end mt15">
                    {this.state.minimizeTable
                        ? <Ax>
                            <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                  
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {this.props.permissions.includes('vendor-add') && <Ax>
                                        <li><Link className="dropdown-item" onClick={this.openAddVendorScreen}>Add Vendor</Link></li>
                                        <li><Link className="dropdown-item" onClick={this.vendorBulkUploadModalInit}>Bulk Upload</Link></li>
                                    </Ax>}
                                </ul>
                            </span>


                        </Ax>
                        : <Ax>
                            <button type="button" className="btn btn-primary" disabled={!this.props.permissions.includes('vendor-add')} onClick={this.openAddVendorScreen}>Add </button>
                            {!this.state.minimizeTable
                                ? <Ax><span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.excelDownloading == true || this.state.reportDownloading == true}>
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {this.props.permissions.includes('vendor-add') && <li><Link className="dropdown-item" onClick={this.vendorBulkUploadModalInit} role="button" >Bulk Upload</Link></li>}
                                        <li><Link className="dropdown-item" disabled={this.state.reportDownloading} onClick={this.downloadVendorWiseReport}>Vendor Wise PO Report Download</Link></li>
                                        <li><Link className="dropdown-item"  disabled={this.state.reportDownloading} onClick={this.downloadVendorReport}>Download</Link></li>
                                    </ul>
                                </span>
                                    <button type="button" onClick={this.vendorSearchFormInit} className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                                    </button></Ax> : null}
                        </Ax>
                    }
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Vendor : {this.state.vendorViewDetails && this.state.vendorViewDetails.name ? this.state.vendorViewDetails.name : "-"} </h3>
                            <div className="text-end mt15">
                            <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faChartBar} className=" mr3" />
                                    Report</button>
                                <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>           
                                <li><Link className="dropdown-item" target="_blank"  to={{ pathname: `/report/item_wise_purchase`, search: "?" + new URLSearchParams({ search_vendor_ids : this.state.vendorViewDetails?.id,vendor_name : this.state.vendorViewDetails?.name }).toString() }}>Item wise Purchase</Link></li>
                                {this.props.permissions.includes('isc-po-view') &&  <li><Link className="dropdown-item" target="_blank" to={{ pathname: `/report/item_wise_purchase_order`, search: "?" + new URLSearchParams({ search_vendor_ids : this.state.vendorViewDetails?.id,vendor_name : this.state.vendorViewDetails?.name }).toString() }}>Item wise Purchase Order</Link></li>}
                                   </ul>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{cursor : "pointer"}}>
                                {this.props.permissions.includes('vendor-manage-contact') && <li><a className="dropdown-item" onClick={this.addContactInit}>Add Contact</a></li>}
                                {this.props.permissions.includes('vendor-edit') &&    <li><a className="dropdown-item" onClick={() => this.openUpdateVendorScreen(this.state.vendorViewDetails?.id)}>Edit Vendor</a></li>}
                                {this.props.permissions.includes('vendor-manage-address') &&  <li><a className="dropdown-item" onClick={this.addAddressInit}>Add Address</a></li>}
                                {this.props.permissions.includes('vendor-change-status') &&    <li><a className="dropdown-item" onClick={() => this.changeStatusInit()}>Change Status</a></li>}
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.vendorListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4">{this.vendorViewDetails()}</div> : null }
                </div>
            </div>
        </Ax>);
    }

    vendorListingTableJsx            =   ()   =>   {
        return(<Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.vendorSearchClear}   /> 
            <table className="table table-bordered table-fixed  bg-white  table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Vendor</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "16%" }}>Vendor Name</th>
                            <th scope="col" style={{ width: "16%" }}> Code</th>
                            <th scope="col" style={{ width: "18%" }}>Location (Source of Supply)</th>
                            <th scope="col" style={{ width: "15%" }}>Email ID</th>
                            <th scope="col" style={{ width: "12%" }} className='text-center'>Phone Number</th>
                            <th scope="col" style={{ width: "12%" }} className='text-center'>Status</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.vendor_listing.length > 0
                            ? (this.state.vendor_listing.map((vendor, index) => {
                                return (<tr key={vendor.id} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewVendorDetail(vendor.id)}>
                                                        <div className="text-capitalize link-primary cursor_pointer">{vendor?.name}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">Code : {vendor?.code??"-"} </small>
                                                            <small className="float-end"><Status color={vendor.status_color}>{vendor.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-capitalize link-primary cursor_pointer'  onClick={() => this.viewVendorDetail(vendor.id)}>{vendor.name}</td>
                                                <td className='text-capitalize'>{vendor.code ? vendor.code : "-"}</td>
                                                <td>{vendor.addressList.length > 0 ? vendor.addressList.map( (value) => value.state_name).filter( (value, index, _arr) => _arr.indexOf(value) == index).join(', ') 
                                                       : "-"}</td>
                                                <td>{vendor.primary_contact !== null ? vendor.primary_contact.email??"-" : "-"}</td>
                                                <td className='text-center'>{vendor.primary_contact !== null ? vendor.primary_contact.mobile??"-" : "-"}</td>
                                                <td className="text-center"><Status color={vendor.status_color}>{vendor.status_text}</Status></td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewVendorDetail(vendor.id)} >View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
        </Ax>)
    }

    vendorViewDetails                =   ()   =>   {
        let vendorDetails = this.state.vendorViewDetails;
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="address-tab" data-bs-toggle="tab" data-bs-target="#address"
                        type="button" role="tab" aria-controls="address" aria-selected="false">Address
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="po-tab" data-bs-toggle="tab" data-bs-target="#po"
                        type="button" role="tab" aria-controls="po" aria-selected="false">Purchase Order
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="purchase-tab" data-bs-toggle="tab" data-bs-target="#purchase"
                        type="button" role="tab" aria-controls="purchase" aria-selected="false">Purchase
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.details_loading ? <Loader />
                        : (<div className="tab_content_header">
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Details</span>
                            </div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Vendor Name</td>
                                        <th style={{ "width": "66%" }}>{vendorDetails.name ? vendorDetails.name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Code</td>
                                        <th style={{ "width": "66%" }}>{vendorDetails.code ? vendorDetails.code : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Status</td>
                                        <th style={{ "width": "66%" }}>{vendorDetails.status ? <Status color={vendorDetails.status_color}>{vendorDetails.status_text}</Status> : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Notes</td>
                                        <th style={{ "width": "66%" }}>{vendorDetails.notes ? vendorDetails.notes : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper align-items-center">
                                <span className="content_heading">Contact Details </span>
                            </div>
                            <table className="table table-hover table-bordered my-3 table-sm">
                                <thead className="table-secondary" >
                                    <tr>
                                        <th style={{ width: "5%" }} className='text-center'>S.No</th>
                                        <th style={{ width: "25%" }}>Name</th>
                                        <th style={{ width: "30%" }}>Email ID</th>
                                        <th style={{ width: "20%" }} className='text-center'>Mobile Number</th>
                                        <th className='text-center' style={{ width: "15%" }}>Primary Contact</th>
                                        <th style={{ width: "5%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendorDetails?.contacts?.length > 0 ? vendorDetails?.contacts?.map((c, i) => {
                                        return (<tr>
                                            <td className='text-center'>{i + 1}</td>
                                            <td>{c.name}</td>
                                            <td> {c.email ?? "-"}</td>
                                            <td className='text-center'> {c.mobile ?? "-"}</td>
                                            <td className='text-center'> {c.is_primary == "Y" ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} /> : ""}</td>
                                            <td className="text-center">
                                                {this.props.permissions.includes('vendor-manage-contact') && <Ax>
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.editContact(c.id)}>Edit</a></li>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.setPrimaryContact(c.id)}>Set to Primary</a></li>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.deletePrimaryContact(c.id)}>Delete</a></li>
                                                    </ul>
                                                </Ax>}  
                                            </td>
                                        </tr>)
                                    })
                                        : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Domain</span>
                            </div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Vendor Domain</td>
                                        <th style={{ "width": "66%" }}>{vendorDetails.domain && vendorDetails.domain.length > 0 ? (vendorDetails.domain.map((domain, k) => <span key={k} className="badge bg-secondary">{domain}</span>))
                                            : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Authorization</td>
                                        <th style={{ "width": "66%" }}>{vendorDetails.authorization ? vendorDetails.authorization : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Area of Operations</span>
                            </div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "34%" }}>States</td>
                                        <th style={{ "width": "66%" }} className='fs13'>{vendorDetails.operations_state_list && vendorDetails.operations_state_list.length > 0 ? (vendorDetails.operations_state_list.map((state, k) => state.name).join(', '))
                                            : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>District</td>
                                        <th style={{ "width": "66%" }} className='fs13'>{vendorDetails.operations_district_list && vendorDetails.operations_district_list.length > 0 ? (vendorDetails.operations_district_list.map((state, k) => state.name).join(', '))
                                            : "-"}</th>
                                    </tr>

                                </tbody>
                            </table>


                        </div>)}
                </div>
                <div className="tab-pane" id="address" role="tabpanel" aria-labelledby="address-tab">
                    {this.state.details_loading ? <Loader />
                        : (<div className="tab_content_header">
                            {vendorDetails && vendorDetails.addressList && vendorDetails.addressList.length > 0
                                ? vendorDetails.addressList.map((a, i) => {
                                    return (
                                        <div className="card my-2">
                                            <div className="card-header row align-items-center">
                                                <div className='col-sm-7'>{a.label ? a.label : "-"}</div>
                                                {this.props.permissions.includes('vendor-manage-address') &&  <div className=' col-sm-5 text-end'>
                                                    <button className='btn btn-primary' role="button" onClick={() => this.editVendorAddress(a.id)} > <tapIcon.FontAwesomeIcon icon={tapIcon.faEdit} /></button>
                                                   <button className='btn btn-primary mx-2' role="button"  onClick={() => this.deleteVendorAddress(a.id)} disabled={vendorDetails.addressList.length <= 1 ? true : false}> <tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt}/></button> 
                                                </div>}
                                            </div>
                                            <div className="card-body">
                                                <div className='py-1'>Address : <b>{a.address1 ? a.address1 + ", " : ""}
                                                    {a.address2 ? a.address2 + ", " : ""}
                                                    {a.landmark ? a.landmark + ", " : ""}
                                                    {a.city ? a.city + ". " : "-"}</b></div>
                                                <div className='py-1'>District, State & Pincode : <b>{a.district_name ? a.district_name + ", " : "- , "}
                                                    {a.state_name ? a.state_name : " - "}
                                                    {a.pincode ? " & " + a.pincode : ""}</b></div>
                                                <div className='py-1'>GSTIN : <b>{a.gstin ? a.gstin : "- "}</b></div>
                                            </div>
                                        </div>
                                    )
                                }) : null}
                        </div>)}
                </div>
                <div className="tab-pane" id="po" role="tabpanel" aria-labelledby="po-tab">
                    <div className="tab_content_header">
                        <table className="table table-bordered table-sm bg-white">
                            <thead className="table-secondary" >
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                                    <th scope="col" style={{ width: "12%" }}>PO Number</th>
                                    <th scope="col" style={{ width: "12%" }}> Date</th>
                                    <th scope="col" style={{ width: "18%" }}>Warehouse</th>
                                    <th scope="col" style={{ width: "10%" }}>Total Quantity</th>
                                    <th scope="col" style={{ width: "10%" }} className="text-end">Amount</th>
                                    <th scope="col" style={{ width: "15%" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.po_listing_loading
                                    ? (<tr><td colSpan="9"><Loader /></td></tr>)
                                    : (this.state.po_listing.length > 0
                                        ? (this.state.po_listing.map((item, index) => {
                                            return (<tr key={index} >

                                                <td className='text-center'>{this.state.polistingMeta ? this.state.polistingMeta.from + index : index} </td>
                                                <td><a role="button" className="link-primary text-center" onClick={() => this.viewPODetail(item.transaction_id)}>{item.transaction_id}</a></td>
                                                <td>{item.transaction_date_display}</td>
                                                <td>{item.warehouse ? item.warehouse.name : '-'}</td>
                                                <td className="text-end">{parseInt(item.total_qty)}</td>
                                                <td className="text-end">{parseFloat(item.total_amount ? item.total_amount : 0).toFixed(2)}</td>
                                                <td className='text-center'>{item.status_data ? item.status_data.name : '-'}</td>
                                               

                                            </tr>)
                                        }))
                                        : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                    )
                                }
                            </tbody>
                        </table>
                        <DisplayListPagination
                            meta={this.state.polistingMeta}
                            onPageChange={(e) => this.poloadListingTblData(e.selected + 1)}
                        />
                    </div>
                </div>
                <div className="tab-pane" id="purchase" role="tabpanel" aria-labelledby="purchase-tab">
                    <div className="tab_content_header">
                        <table className="table table-bordered   bg-white table-sm">
                            <thead className="table-secondary" >
                                <tr>
                                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                                    <th scope="col" style={{ width: "10%" }} className='text-center'>Date</th>
                                    <th scope="col" style={{ width: "10%" }}> Transaction ID</th>
                                    <th scope="col" style={{ width: "12%" }}>Bill Number</th>
                                    <th scope="col" style={{ width: "17%" }}>Warehouse</th>
                                    <th scope="col" style={{ width: "8%" }} className='text-center'>Total Quantity</th>
                                    <th scope="col" style={{ width: "8%" }} className='text-end'>Amount</th>
                                    <th scope="col" style={{ width: "15%" }} className='text-center'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.purchase_listing_loading
                                    ? (<tr><td colSpan="9"><Loader /></td></tr>)
                                    : (this.state.purchase_listing.length > 0
                                        ? (this.state.purchase_listing.map((item, index) => {
                                            return (<tr key={index} >

                                                <td className="text-center">{this.state.purchaselistingMeta ? this.state.purchaselistingMeta.from + index : index}</td>
                                                <td className='text-center'>{item.transaction_date ? moment(item.transaction_date).format("DD-MMM-YYYY") : "-"}</td>
                                                <td><a role="button" className="link-primary" onClick={() => this.viewPurchaseDetail(item.transaction_id)}>{item.transaction_id ? item.transaction_id : "-"}</a></td>
                                                <td>{item.purchase_bill_number ? item.purchase_bill_number : "-"}</td>
                                                <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                    : "-"}</td>

                                                <td className='text-center'>{item.total_qty ? item.total_qty : 0}</td>
                                                <td className='text-end'>{item.total_amount ? parseFloat(item.total_amount).toFixed(2) : 0}</td>
                                                <td className='text-center'>{item.status_data ? item.status_data.name : "-"}</td>


                                            </tr>)
                                        }))
                                        : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                    )
                                }
                            </tbody>
                        </table>
                        <DisplayListPagination
                            meta={this.state.purchaselistingMeta}
                            onPageChange={(e) => this.purchaseloadListingTblData(e.selected + 1)}
                        />
                    </div>
                </div>
            </div>
        </section>)
    }

    //*********************************ADD CONTACT JSX*************************
    addContactModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="addContactModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">{this.state.editContactDetails ? " Edit" : "Add"} Contact</h5>
                        </div>
                        <form onSubmit={this.addContactFormSubmit} id="addContactForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Person Name</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addContactForm.name}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            maxLength="200"
                                           
                                            placeholder="Please Enter Person Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Email ID</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            autoComplete="off"
                                            maxLength="200"
                                           
                                            value={this.state.addContactForm.email}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            placeholder="Please Enter Person Email ID"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="mobile"
                                            type="number"
                                            className="form-control"
                                            autoComplete="off"
                                          
                                            value={this.state.addContactForm.mobile}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            placeholder="Please Enter Person Mobile Number"
                                            pattern="[0-9.]+"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addContactFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={()=>{this.setState({addContactForm : {...this.initContactForm}})}}>Close</button>
                                <button type="submit" disabled={this.state.addContactFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addContactFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );
    }

   
     //*********************************ADD CONTACT JSX*************************
     addAddressModalJsx              =   ()  =>  {
       
        return (
            <div className="modal fade" id="addAddressModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">{this.state.editAddressDetails ? " Edit" : "Add"} Address</h5>
                        </div>
                        {this.state.vendorAddressFormDataLoaded ?
                            <div className="modal-body "><form onSubmit={this.addAddressFormSubmit} id="addAddressForm" method="post" encType="multipart/form-data">
                                {this.state.loading_address ? <Loader/>
                                : 
                             <div>
                                   <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label require">Label</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="label"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.label}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            required={true}
                                            placeholder="Please Enter Address Label"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Address Line 1</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="address1"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.address1}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter Address Line 1"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Address Line 2</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="address2"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.address2}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter Address Line 2"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Landmark</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="landmark"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.landmark}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter Landmark"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">City</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="city"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.city}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter City"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Country</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addAddressForm', 'country_id');
                                                this.countryChangeHandler(selectedOption)
                                            }
                                            }
                                            options={this.state.allCountryList}
                                            isSearchable={false}
                                            isClearable={false}
                                            value={this.state.allCountryList.find(m => m.value == this.state.addAddressForm.country_id)}
                                            placeholder="Please Select Country"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label require">State (Source of Supply)</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                            options={this.state.allStateList}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            value={this.state.allStateList.find(m => m.value == this.state.addAddressForm.state_id)}
                                            placeholder="Please Select State"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">District</label>
                                    </div>
                                    <div className="col-sm-7">
                                        {this.state.addNewDistrict
                                            ? <input
                                                name="district_name"
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addAddressForm.district_name}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                maxLength="20"
                                                placeholder="Please Enter District"
                                            /> : <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                                options={this.state.allDistrictList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allDistrictList.find(m => m.value == this.state.addAddressForm.district_name)}
                                                placeholder="Please Select District"
                                            />}
                                    </div>
                                    <div className="col-sm-2">
                                        <input
                                            name="addNewDistrict"
                                            type="checkbox"
                                            value={this.state.addNewDistrict}
                                            onChange={(e) => {
                                                this.setState({
                                                    addNewDistrict: !this.state.addNewDistrict,
                                                    addAddressForm: { ...this.state.addAddressForm, district_id: '', pincode: '' }
                                                })
                                            }}
                                            checked={this.state.addNewDistrict}
                                            className="form-check-input"
                                            id="newDistrict"

                                        />
                                        <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">PIN Code</label>
                                    </div>
                                    <div className="col-sm-7">
                                        {this.state.addNewPinCode
                                            ? <input
                                                name="pincode"
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addAddressForm.pincode}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                maxLength="20"
                                                placeholder="Please Enter PinCode"
                                            />
                                            : <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'pincode') }}
                                                options={this.state.allPincodeList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allPincodeList.find(m => m.value == this.state.addAddressForm.pincode)}
                                                placeholder="Please Select PIN Code"
                                            />}
                                    </div>
                                    <div className="col-sm-2">
                                        <input
                                            name="addNewPinCode"
                                            type="checkbox"
                                            value={this.state.addNewPinCode}
                                            onChange={(e) => {
                                                this.setState({
                                                    addNewPinCode: !this.state.addNewPinCode,
                                                    addAddressForm: { ...this.state.addAddressForm, pincode: '' }
                                                })
                                            }}
                                            checked={this.state.addNewPinCode}
                                            className="form-check-input"
                                            id="newPinCode"

                                        />
                                        <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">GSTIN</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="gstin"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addAddressForm.gstin}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            maxLength="20"
                                            placeholder="Please Enter GSTIN Number"
                                        />
                                    </div>
                                </div>
                             </div>}

                            </form>
                            </div>

                            : <Loader />}
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.addAddressFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addAddressForm: { ...this.initAddressForm } }) }}>Close</button>
                            <button type="submit" disabled={this.state.addAddressFormSubmitting} className="btn btn-primary" form="addAddressForm">
                                Submit {this.state.addAddressFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
    
    vendorBulkUploadModalJsx            =   ()  =>  {
        return (
            <div className="modal fade" id="vendorBulkUploadModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Add Vendor</h5>
                            <button type="button" disabled={this.state.vendorBulkUploadFormSubmiting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.vendorBulkUploadFormSubmit} id="vendorBulkUploadForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            id="addVendorFileSelector"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <div><a type="button" className='float-left' href='#' onClick={this.downloadSampleFile}>Download Sample File</a></div>
                                <div>
                                    <button type="button" disabled={this.state.vendorBulkUploadFormSubmiting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.vendorBulkUploadFormSubmiting} className="btn btn-primary">
                                        Submit {this.state.vendorBulkUploadFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    searchVendorModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="searchVendorModal" tabIndex="-1">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">Search Vendor</h5>
                        </div>
                        <form onSubmit={this.vendorSearchFormSubmit} id="searchVendorForm">
                            {this.state.search_form_loading ? <Loader />
                                : <div className="modal-body">
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Vendor Name</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_name"
                                                type="text"
                                                value={this.state.vendorSearchForm.search_name}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "vendorSearchForm")}
                                                placeholder="Please enter Vendor Name" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Vendor Code</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_code"
                                                type="text"
                                                value={this.state.vendorSearchForm.search_code}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "vendorSearchForm")}
                                                placeholder="Please enter Vendor Name" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Location (Source of Supply)</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'vendorSearchForm', 'search_state_ids')}
                                                options={this.state.allSearchStatesList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select States"
                                                value={this.state.allSearchStatesList.filter(t => this.state.vendorSearchForm.search_state_ids.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">GSTIN</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_gstin"
                                                type="text"
                                                value={this.state.vendorSearchForm.search_gstin}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "vendorSearchForm")}
                                                placeholder="Please enter GSTIN" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Phone Number</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_mobile"
                                                type="number"
                                                value={this.state.vendorSearchForm.search_mobile}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "vendorSearchForm")}
                                                placeholder="Please enter Phone Number" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Email</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_email"
                                                type="email"
                                                value={this.state.vendorSearchForm.search_email}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "vendorSearchForm")}
                                                placeholder="Please enter Email" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Domain</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'vendorSearchForm', 'search_domains')}
                                                options={this.state.allDomainList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select Vendor Domain"
                                                value={this.state.allDomainList.filter(t => this.state.vendorSearchForm.search_domains.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Status</label>
                                        <div className="col-sm-10">
                                        <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'vendorSearchForm', 'search_status')}
                                                options={this.state.allStatusList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select Vendor Status"
                                                value={this.state.allStatusList.filter(t => this.state.vendorSearchForm.search_status.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">
                                    Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );
    }

    submitChangeStatusForm          =       (e)      =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        
           HttpAPICall.withAthorization('PUT', TapApiUrls.VENDOR_URL + '/change_status', this.props.access_token, {}, { ...this.state.changeStatusForm }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.viewVendorDetail(this.state?.vendorViewDetails?.id);
               this.loadListingTblData(1);
               this.changeStatusModal.hide();
               this.setState({changeStatusForm : {...this.initChangeStatusInit}})
           }).then(() => this.setState({ saveFormSubmitting : false }));
    }

    purchaseDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewPurchaseModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {this.state.purchaseTransactionId ? this.state.purchaseTransactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            {this.state.purchaseTransactionId
                             ? <PurchaseDetail purchaseId={this.state.purchaseTransactionId}/>
                             : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeStatusModalJsx            =       ()      =>      {
        return( <div className="modal fade" id="changeStatusModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="changeStatusModalLabel">Change Status</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-borderless text-sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "26%" }}>Vendor Name</td>
                                    <th>{this.state.vendorViewDetails && this.state.vendorViewDetails.name ?this.state.vendorViewDetails.name  : ''}</th>
                                </tr>
                                <tr>
                                    <td>Vendor Code </td><th>{this.state.vendorViewDetails && this.state.vendorViewDetails.code ?this.state.vendorViewDetails.code  : ''}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                   
                    <form className="bg-white" onSubmit={this.submitChangeStatusForm} id="changeStatusForm">

                    <div className="my-3 row">
                        <label className="col-sm-3 col-form-label">Change status to</label>
                        <div className="col-sm-5">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'changeStatusForm', 'status');
                                }}
                                value={this.state.allStatusList.find(s => s.value == this.state.changeStatusForm.status)}
                                options={this.state.allStatusList}
                                isSearchable={false}
                                placeholder="Please Select Status"
                            />
                        </div>
                    </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            >Close  </button>
                           <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Update {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Vendor Listing</title></Helmet>
            {this.vendorListjsx()}
            {this.addContactModalJsx()}
            {this.addAddressModalJsx()}
            {this.vendorBulkUploadModalJsx()}
            {this.searchVendorModalJsx()}
            {this.purchaseDetailsModalJsx()}
            {this.changeStatusModalJsx()}
            <PurchaseOrderDetailModal parentProps={this.props} ref={this.PODetailModalRef}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions          :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [] ,
    };
};

export default connect(mapStateToProps)(VendorList);