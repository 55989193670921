
import React, { useState } from "react";
import { connect } from "react-redux";
import ApplicationLayout from "../../../layouts/ApplicationLayout";

import { Helmet } from "react-helmet";
import TapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
const AdditionalAttributeHistory = () => {


    const [minimizeTable, setMinimizeTable] = useState(false);
    const [viewLoading, setViewLoading] = useState(false);



    const AdditionalAttributeList = () => {
        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-bordered bg-white align-items-center table-sm table-hover mb-0">
                        <thead className="table-secondary">
                            <tr>
                                <th
                                    scope="col"
                                    style={{ width: "5%" }}
                                    rowSpan="2"
                                    className="text-center"
                                >
                                    S.No
                                </th>
                                <th scope="col" className="text-center">Attribute Name</th>
                                <th scope="col" className="text-center">Attribute ID</th>
                                <th scope="col" className="text-center">Date</th>
                                <th scope="col" className="text-center">Status</th>
                                <th scope="col" className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td className="text-center">
                                    1
                                </td>
                                <td className="text-center">
                                    <a role="button"
                                        className="link-primary" >gh
                                    </a>
                                </td>
                                <td className="text-center">jhui</td>
                                <td className="text-center">jhui</td>
                                <td className="text-center">
                                    jio
                                </td><td className="text-center">
                                    <button
                                        type="button"
                                        id="actionMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        className="btn"
                                    >
                                        <TapIcon.FontAwesomeIcon
                                            icon={TapIcon.faEllipsisV}
                                        />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" role="button"

                                        >View</a></li>
                                        <li><a className="dropdown-item" role="button"

                                        >Download</a></li>
                                    </ul>
                                </td>

                            </tr>


                        </tbody>
                    </table>
                </div>

            </div>
        );
    };
const  ticketListJsx = () => {
    return (
      <>
        <div style={{ paddingTop: "4px" }}>
          <div className="page_title row m0" style={{ height: "45px" }}>
            <div className="col-sm-6">
              <h3 style={{ paddingTop: "13px" }}>Ticket List</h3>
            </div>
        
              <div className="col-sm-2 ">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  role="button"
                 
                >
                  {" "}
                  Asset Card
                </button>
              </div>
      
          
              <div className="col-sm-4 text-end mt7 ">
                <button
                  type="button"
                  className="btn btn-secondary"
                >
                  <TapIcon.imageIcon
                    icon={TapIcon.SearchIcon}
                    className="img-fluid"
                    alt="item-action"
                  />
                </button>
              </div>
          </div>
        </div>
        <div className="container-fluid pl0 pr13">
          <div className="page_containt row">
            <div
              className={[
                "pl16",
                minimizeTable ? "col-3 mr0 pr0 wd26" : "col-12 pr0",
              ].join(" ")}
            >
              {AdditionalAttributeList()}
            </div>
            {minimizeTable ? (
              <div className="col-9 pr4 wd74 pl0 pageTbl pb-5">
                <div className=" bg-white p-2">
                  {viewLoading ? (
                    <Loader />
                  ) : (
                    this.ticketViewDetailJsx()
                  )}
                </div>{" "}
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };
    return (
        <ApplicationLayout>
            <Helmet>
                <title>Asset - Additional Attribute History</title>
            </Helmet>
            {AdditionalAttributeList()}
        </ApplicationLayout>
    );
};

export default connect()(AdditionalAttributeHistory);
