import React from 'react';

import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import {Modal} from "bootstrap";
import AddSiteTransition from "./AddSiteTransition";
import TapSelect from "../../../components/ui/TapSelect";
import DatePicker from "react-datepicker";
import Status from "../../../components/ui/Status";


class  SiteTransactionList extends React.Component {


    constructor() {
        super();
        this.state                      =  {
            minimizeTable               :       false,
            addSiteTransition           :       false
        };
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    viewDetails             =         () =>    {
        this.setState({minimizeTable : true});
    }


    openAddScreen(){
        this.setState({addSiteTransition:true})
    }

    componentDidMount() {
        this.assetViewModal          =        new Modal(document.getElementById('assetViewModal'), {keyboard: false, backdrop :false});
        this.updateCounterlogModal          =        new Modal(document.getElementById('updateCounterlogModal'), {keyboard: false, backdrop :false});
    }

    assetViewModalJsx                  =   ()  =>  {

        return (
            <div className="modal fade" id="assetViewModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-8 pr0">
                                    <table className="table table-hover table-borderless">
                                        <tbody>
                                        <tr>
                                            <td className="details-label" style={{"width": "30%"}}>Asset Name</td>
                                            <td className="details-name" style={{"width": "60%"}}>Asset Name</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label" style={{"width": "30%"}}> Asset Code</td>
                                            <td className="details-name" style={{"width": "60%"}}> Asset Code</td>

                                        </tr>
                                        <tr>
                                            <td className="details-label" style={{"width": "30%"}}> Asset Type</td>
                                            <td className="details-name" style={{"width": "60%"}}> Asset Type</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label" style={{"width": "30%"}}>  Asset Matrix Id </td>
                                            <td className="details-name" style={{"width": "60%"}}>Matrix Id</td>

                                        </tr>
                                        <tr>
                                            <td className="details-label" style={{"width": "30%"}}>Manufacturer </td>
                                            <td className="details-name" style={{"width": "60%"}}>Manufacturer</td>

                                        </tr>
                                        <tr>
                                            <td className="details-label" style={{"width": "30%"}}> Model  </td>
                                            <td className="details-name" style={{"width": "60%"}}> Model </td>

                                        </tr>
                                        <tr>
                                            <td className="details-label" style={{"width": "30%"}}> Site  </td>
                                            <td className="details-name" style={{"width": "60%"}}>Site </td>

                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-4 pl0 ">
                                    <table className="table table-hover table-borderless pb0 mb-0">
                                        <tbody>
                                        <tr>
                                            <td className="text-center">
                                                <img
                                                    src="https://tappetbox-cdn.s3.ap-southeast-1.amazonaws.com/media/profile/asset-matrix-profile/images_181.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIUVX5WROGIHTOKLQ%2F20211013%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20211013T100444Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=a8589406d49ead967821d97d5233f0b1b5b211e0add01f85ec5ad4640209a6a7"
                                                    className={`img-fluid`} style={{width:"100%"}} alt={"asset_card"}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-hover table-borderless">
                                        <tbody>
                                        <tr>
                                            <td className="details-label" style={{"width": "40%"}}>Asset Status</td>
                                            <td className="details-name" style={{"width": "60%"}}>
                                                <Status>Inactive</Status></td>
                                        </tr>
                                            <tr>
                                                <td className="details-label" style={{"width": "40%"}}> Last Counter Log Reading</td>
                                                <td className="details-name " style={{"width": "60%"}}> 6500.00 Hr<br/>
                                                    270000.00 Km<br/>
                                                    01-Sep-2021</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-card-footer">
                            <div>
                                <button  className="btn btn-primary">View Detail Profile</button>
                            </div>
                            <div>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    assetViewModalInit          =       ()      => {
        this.assetViewModal.show()
    }

    updateCounterlogModalJsx                  =   ()  =>  {

        return (
            <div className="modal fade" id="updateCounterlogModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Update Counter Log</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Hour Meter Reading</label></div>
                                    <div className="col-4">
                                       <div className="mb-2">
                                           <small className="form-text">Previous Year Reading</small>
                                       </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholder="Please Enter Previous Shift Reading"
                                        />
                                       <div>
                                           <small className="form-text">Latest Reading 200</small>
                                       </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="mb-2"> <small className="form-text mb-2">Counter log Reading</small></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Counter Log Reading"
                                        />
                                        <div className="p-2">
                                            <small className="form-text"></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Odometer Reading</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholder="Please Enter Previous Shift Reading"
                                        />
                                        <div>
                                            <small className="form-text">Latest Reading 200</small>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Counter Log Reading"
                                        />
                                        <div className="p-2">
                                            <small className="form-text"></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Operation Cycle Reading</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholder="Please Enter Previous Shift Reading"
                                        />
                                        <div>
                                            <small className="form-text">Latest Reading 200</small>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Counter Log Reading"
                                        />
                                        <div className="p-2">
                                            <small className="form-text"></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Counterlog Date</label></div>
                                    <div className="col-md-4 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholderText={`Please Enter Counterlog Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                    <div className="col-md-5 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholderText={`Please Enter Counterlog Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    updateCounterlogModalInit          =       ()      => {
        this.updateCounterlogModal.show()
    }

    siteTransactionListjsx                         =   ()  =>  {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                    <h3>Site Transition List</h3>
                    <div className="text-end mt15">
                        {(!this.state.minimizeTable) ? <button type="button" onClick={this.openAddScreen.bind(this)} className="btn btn-primary">Create Site Transition</button> : null}

                        {(this.state.minimizeTable) ? <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    {this.state.minimizeTable  ? <li><a className="dropdown-item" onClick={this.openAddScreen.bind(this)} href="#">Create Site Transition</a></li>:null}

                                </ul>
                            </span> : null }
                    </div>
                </div>
                {
                    this.state.minimizeTable
                        ? (
                            <div className="col-9 tap_border_left">
                                <h3>Site Name : </h3>
                                <div className="text-end mt15">

                                    <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                    </button>
                                </div>
                            </div>
                        )
                        : null
                }

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3 pr0 mr0' : 'col-12'].join(' ')} >{this.listingTableTrJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.transitionViewJsx()}</div> : null }
                </div>
            </div>
        </Ax>);
    }

    listingTableTrJsx                   =   ()  =>  {
        return (
            <Ax>
                <div className="">
                    <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                        <thead className="table-secondary">
                        { this.state.minimizeTable
                            ? (<tr>
                                <th scope="col" style={{width:"20%"}}>Site Transition</th>
                            </tr> )
                            :   ( <tr>
                                <th scope="col" style={{width:"5%"}} className="text-center">S.No</th>
                                <th scope="col" style={{width:"13%"}} >Asset Name(Code)</th>
                                <th scope="col" style={{width:"12%"}}>Asset Type</th>
                                <th style={{width:"13%"}}>Site Name</th>
                                <th  style={{width:"8%"}}>Date of Receipt</th>
                                <th  style={{width:"8%"}}>Tentative Release Date</th>
                                <th  style={{width:"8%"}}>Exit Date</th>
                                <th  style={{width:"10%"}}>Next Site</th>
                                <th  style={{width:"8%"}}>Date of Transition</th>
                                <th  style={{width:"10%"}}>Mobilization Status</th>
                                <th  style={{width:"5%"}}>Action</th>
                            </tr> )
                        }
                        </thead>
                        <tbody>
                        <tr>
                            { this.state.minimizeTable
                                ? (
                                    <td scope="col"  onClick={this.viewDetails}>Asset Name</td>
                                )
                                :   (<Ax>
                                        <td className="text-center">1</td>
                                        <td><a role="button" className="link-primary" onClick={this.assetViewModalInit}>Name</a></td>
                                        <td>code</td>
                                        <td>Gurgaon</td>
                                        <td>15-Aug-2021 <br/> 10:00 AM</td>
                                        <td>15-Aug-2021 </td>
                                        <td>15-Aug-2021 <br/> 10:00 AM</td>
                                        <td>Lucknow</td>
                                        <td>15-Aug-2021 <br/> 10:00 AM</td>
                                        <td>Status</td>
                                        <td className="p0 text-center">
                    <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" role="button" onClick={this.viewDetails}>View</a></li>
                        </ul>
                    </span>
                                        </td>
                                    </Ax>
                                )}


                        </tr>

                        </tbody>
                    </table>
                </div>

            </Ax>
        )

    }

    transitionViewJsx = () => {

        return (<section className="item_view_container">
            <div className="tab-content" id="myTabContent">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane show active" id="home" role="tabpanel"
                         aria-labelledby="dashboard-tab">
                        <div className="tab_content_sticky_header" style={{height:"80vh"}}>
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Mobilization Details</span></div>
                            <table className="table table-hover table-bordered mt-3">
                                <tbody>
                                <tr>
                                    <td className="details-label" style={{width:"20%"}}>Transaction ID</td>
                                    <td className="details-name" style={{width:"30%"}}>Transaction ID</td>
                                    <td className="details-label" style={{width:"20%"}} >Date</td>
                                    <td className="details-name" style={{width:"30%"}}>Date</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{width:"20%"}}>Status</td>
                                    <td className="details-name" colSpan={3}>Status</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Asset Details</span></div>
                            <table className="table table-hover table-bordered mt-3">
                                <tbody>
                                <tr>
                                    <td className="details-label" style={{width:"20%"}}>Asset Name</td>
                                    <td className="details-name" style={{width:"30%"}}>Asset Name</td>
                                    <td className="details-label" style={{width:"20%"}} >Asset Code</td>
                                    <td className="details-name" style={{width:"30%"}}>Asset Code</td>
                                </tr>
                                <tr>
                                    <td className="details-label" > Asset Type</td>
                                    <td className="details-name" > Asset Type</td>
                                    <td className="details-label" >Asset Matrix Id</td>
                                    <td className="details-name" >Asset Matrix Id</td>
                                </tr>
                                <tr>
                                    <td className="details-label" >Manufacturer</td>
                                    <td className="details-name" >Manufacturer</td>
                                    <td className="details-label" > Model </td>
                                    <td className="details-name" > Model </td>
                                </tr>
                                <tr>
                                    <td className="details-label" > Asset Status </td>
                                    <td className="details-name" > Asset Status </td>
                                    <td className="details-label" > Quantity </td>
                                    <td className="details-name" > Quantity </td>
                                </tr>
                               {/* <tr>
                                    <td className="details-label" >Last Counter Log Reading</td>
                                    <td className="details-name " colSpan={3}> 6500.00 Hr<br/>
                                        270000.00 Km<br/>
                                        01-Sep-2021</td>
                                </tr>*/}
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Dispatch Details</span></div>
                            <table className="table table-hover table-bordered mt-3 ">
                                <tbody>
                                <tr>
                                    <td className="details-label"  style={{width:"20%"}}>Dispatching Site</td>
                                    <td className="details-name"  style={{width:"30%"}} >Dispatching Site</td>
                                    <td className="details-label" style={{width:"20%"}}>Date of Exit </td>
                                    <td className="details-name"  style={{width:"30%"}}>Date of Exit</td>
                                </tr>
                                <tr>
                                    <td className="details-label"  style={{width:"20%"}}>Dispatch Quantity</td>
                                    <td className="details-name"  style={{width:"30%"}} >Dispatch Quantity</td>
                                    <td className="details-label" style={{width:"20%"}}>Counterlog Reading at Dispatch </td>
                                    <td className="details-name"  style={{width:"30%"}}>Counterlog Reading at Dispatch</td>
                                </tr>
                                <tr>
                                    <td className="details-label" > Invoice Number</td>
                                    <td className="details-name" > Invoice Number</td>
                                    <td className="details-label" >Invoice Date</td>
                                    <td className="details-name" >Invoice Date</td>
                                </tr>
                                <tr>
                                    <td className="details-label" >Amount</td>
                                    <td className="details-name " colSpan={3}>amount </td>
                                </tr>
                                <tr>
                                    <td className="details-label" >Docket Number</td>
                                    <td className="details-name" >Docket Number</td>
                                    <td className="details-label" > Docket Date </td>
                                    <td className="details-name" > Docket Date </td>
                                </tr>
                                <tr>
                                    <td className="details-label" >Transporter</td>
                                    <td className="details-name " colSpan={3}>Transporter </td>
                                </tr>
                                <tr>
                                    <td className="details-label" >E-Way Bill Number</td>
                                    <td className="details-name" >E-Way Bill Number</td>
                                    <td className="details-label" >E-Way  Bill Date </td>
                                    <td className="details-name" >E-Way  Bill Date</td>
                                </tr>
                                <tr>
                                    <td className="details-label" >Remarks</td>
                                    <td className="details-name " colSpan={3}>Remarks </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Document Attached </span></div>
                            <table className="table table-hover table-bordered  mt-2 ">
                           <tbody>
                            <tr>
                                <th >Name</th>
                                <th>Details</th>
                                <th style={{width:"20%"}}>No of Documents</th>
                                <th  >Status</th>
                            </tr>
                           <tr>
                               <td>Test</td>
                               <td>Test</td>
                               <td>2</td>
                               <td>
                                   <TapSelect
                                       isSearchable={true}
                                       isClearable={true}
                                       placeholder="Add Status"
                                       containerHeight="33px"
                                       fontSize="93%"
                                   />
                               </td>
                           </tr>
                           </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Attachments / Tools / Spares </span></div>
                            <table className="table table-hover table-bordered  mt-2 ">
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th style={{width:"20%"}}>Amount</th>
                                    <th>Status</th>
                                </tr>
                                <tr>
                                    <td>Test</td>
                                    <td>Test</td>
                                    <td>2</td>
                                    <td>
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Add Status"
                                            containerHeight="33px"
                                            fontSize="93%"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Receipt  Details</span></div>
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-4">
                                        <label className="form-label require">Received Qty.</label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Please Enter Qty"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="form-label require">Update Mobilization Status</label>
                                        <div className="mt-2">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Update Status"
                                                containerHeight="33px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-4">
                                        <label className="form-label require">Date of Receipt</label>
                                        <div className="mt-2 add_calender_section">
                                            <DatePicker
                                                name="status_date"
                                                dateFormat="dd-MMM-yyyy"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholderText={`Please Enter Received Date`}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                               alt         =      "requisition_date"
                                                               className   =      "add_calender-icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="form-label require">Tentative Release Date</label>
                                        <div className="mt-2 add_calender_section">
                                            <DatePicker
                                                name="status_date"
                                                dateFormat="dd-MMM-yyyy"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholderText={`Please Enter Exp. Release Date`}

                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                               alt         =      "requisition_date"
                                                               className   =      "add_calender-icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-8">
                                        <label className="form-label require">Update CounterLog</label>
                                        <div className="mt-2 add_calender_section " >
                                            <input
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Update CounterLog"
                                                onClick={this.updateCounterlogModalInit}
                                            />
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faTachometerAlt}
                                                               alt         =      "requisition_date"
                                                               className   =      "add_calender-icon"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-8">
                                        <label className="form-label require">Remarks</label>
                                        <div className="mt-2 ">
                                             <textarea
                                                 name="remarks"
                                                 className="form-control"
                                                 style={{height: "80px"}}
                                                 placeholder={"Please Enter Remarks"}
                                             />

                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                <div className="col-8 text-end mt-3">
                                    <button type="button" className="btn btn-secondary">Cancel</button>
                                    <button type="submit"  className="btn btn-primary mx-3">
                                        Submit
                                    </button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
    }


    getPageScreen                       =   ()  =>  {
        let pageScreen = null;
        if(this.state.addSiteTransition){
            pageScreen  = <AddSiteTransition/>
        }else {
            pageScreen  = this.siteTransactionListjsx();
        }
        return pageScreen;
    }

    render = () => {
        return (<ApplicationLayout>
            {this.getPageScreen()}
            {this.assetViewModalJsx()}
            {this.updateCounterlogModalJsx()}

        </ApplicationLayout>);
    }
}

export default SiteTransactionList ;