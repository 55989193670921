import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HttpAPICall from "../../../../services/HttpAPICall";
import { ORG_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
import { toast } from "react-toastify";

class GeneralSetting extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                              =   {
            generalConfigloading                :      false,
            general_config_list                 :      null,
            generalConfigFormData               :     {},
            editFormSubmiting                   :      false,
        };
        this.settingUrl         =   ORG_API_BASE_URL_2  + '/settings';
        this.settingEditUrl     =   ORG_API_BASE_URL_2  + '/setting_edit';

    }

    componentDidMount() {
        this.getGeneralSettingListing();

        this.editApprovalStatusModal=   new Modal(document.getElementById('editApprovalStatusModal'), {keyboard: false, backdrop :false});
       
    }

    //functions for general configuration
      getGeneralSettingListing              =    ()           =>     {
        let params = {
            keys : ['enable_logistic_approval']
        }
        this.setState({generalConfigloading : true})
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
         this.setState({general_config_list  : resp.data.data})
        }).then(() => this.setState({generalConfigloading: false}));
    }

    editApprovalStatusModalInit          =    ()           =>     {
        this.editApprovalStatusModal.show();
        if(this.state.general_config_list) {
            let generalConfigFormData = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigFormData[name] = val;
                });
              }
            this.setState({generalConfigFormData})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, null, this.state.generalConfigFormData, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editApprovalStatusModal.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
          return (<table className="table table-hover table-bordered my-4 ">
              <thead className="table-secondary">
                  <tr className="text-center">
                      <th scope="col" style={{ width: "5%" }}>S.No</th>
                      <th scope="col" className="text-start">Configuration</th>
                      <th scope="col" style={{ width: "25%" }} >Value</th>
                      <th scope="col" style={{ width: "10%" }} >Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr className="text-center">
                    <td>1</td>
                    <td className="text-start"> Enable Approval Process for Logistic Voucher</td>
                    <td>{data && data?.enable_logistic_approval === 'Y' ? 'Yes' : 'No'}</td>
                    <td> <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn" disabled={!(this.props.permissions && this.props.permissions.includes('logistic_config'))}>
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link className="dropdown-item" role="button" onClick={() => this.editApprovalStatusModalInit('enable_logistic_approval')}>Edit</Link></li>
                        </ul>
                        </span>
                    </td>
                  </tr>
              </tbody>
          </table>)
    }

    editApprovalStatusModalJsx   =    ()           =>     {
        return (
              <div className="modal fade" id="editApprovalStatusModal" tabIndex="-1">
                  <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addAssetModalLabel">Edit Approval Status</h5>
                          </div>
                          <form id="allowWorkflow" onSubmit={this.submitGeneralConfigurationForm}>
                              <div className="modal-body p-3">
                                  <div className="row align-items-center mb-2 mt-2 px-2">
                                      <div className="col-md-9"><label className="form-label">Approval Status Config</label></div>
                                      <div className="col-md-1">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="enable_logistic_approval"
                                                  value="Y"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.enable_logistic_approval === "Y"}
                                              /> Yes
                                          </label>
                                      </div>
                                      <div className="col-md-2">
                                          <label>
                                              <input
                                                  type="radio"
                                                  name="enable_logistic_approval"
                                                  value="N"
                                                  onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                  checked={this.state.generalConfigFormData.enable_logistic_approval === "N"} /> No
                                          </label>
                                      </div>
                                  </div>
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                  <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          );
    }

  
    render                                  =     ()           =>     {
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
            </div>
            {this.state.generalConfigloading ? <Loader />
                : this.generalConfigurationJsx()}

            {this.editApprovalStatusModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(GeneralSetting);