import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Modal } from 'bootstrap';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import DateService from '../../../services/DateService';
import PurchaseDetail from '../purchase/PurchaseDetail';


class purchaseReturnData extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            purchaseReturnData               :       null,
            view_loading                :       false,
            isc_configuration           :       null,
            detail_loading            :       false,
            itemDetails                 :       null,
            purchaseTransactionId       :       ''
        };
        this.itemDetailsRef             =         React.createRef();  
        this.purReturnUrl           =   INVENTORY_API_BASE_URL_2 + '/purchase_return';

     
    }

    componentDidMount() {
        if(this.props){
            this.setState({
                isc_configuration       :        this.props.isc_configuration
            })
        }
        if(this.props && this.props.purchaseReturnId){
            this.getPurchaseReturnDetail(this.props.purchaseReturnId)
        }
        if(this.props && this.props.purchaseReturnData){
            this.setState({purchaseReturnData: this.props.purchaseReturnData})
        }
        this.viewTransactionModal              =     new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});


     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.purchaseReturnId !== this.props.purchaseReturnId){
            this.getPurchaseReturnDetail(nextProps.purchaseReturnId)
         }
         if(nextProps.purchaseReturnData !== this.props.purchaseReturnData){
             this.setState({purchaseReturnData : nextProps.purchaseReturnData})
         }
         if(nextProps.isc_configuration !== this.props.isc_configuration){
            this.setState({
                isc_configuration       :        nextProps.isc_configuration
            })
        }
         
     }
    

     getPurchaseReturnDetail             =    (transaction_id)        =>  {
         
        this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET', this.purReturnUrl + '/detail/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 purchaseReturnData            : respData.data,
             });
            
         }).then(() => this.setState({view_loading: false}));
        
     }

     viewPurchaseModalInit                =   (transaction_id)       =>      {
        this.setState({purchaseTransactionId : transaction_id},
         () => this.viewTransactionModal.show())
    }

     viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    viewPurchaseModalInit                =   (transaction_id)       =>      {
        this.setState({purchaseTransactionId : transaction_id},
         () => this.viewTransactionModal.show())
    }
 
    transactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Purchase Transaction Id : {this.state.purchaseTransactionId ? this.state.purchaseTransactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                          <PurchaseDetail purchaseId={this.state.purchaseTransactionId}/>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    
    render() {
        let {purchaseReturnData}         =   {...this.state};
        let sub_total_amount                =      purchaseReturnData && purchaseReturnData.items &&  purchaseReturnData.items.length > 0 && purchaseReturnData.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);
 
        let tcs_amount                          =  purchaseReturnData &&  Number(purchaseReturnData.tcs_rate && purchaseReturnData.tcs_rate >= 0
            ? purchaseReturnData && purchaseReturnData.tcs_type == "Percentage" ? (sub_total_amount*purchaseReturnData.tcs_rate/100) :  Number(purchaseReturnData.tcs_rate)
            : 0);
        return (<div>
            {this.state.view_loading ? <Loader /> : purchaseReturnData && <Ax>
           
           <table className="table table-bordered bg-white">
               <thead className="table-secondary" >
                   <tr className='text-center'>
                       <th style={{ width: "34%" }}>Transaction ID</th>
                       <th style={{ width: "33%" }}>Date</th>
                       <th style={{ width: "33%" }}>Status</th>
                   </tr>
               </thead>
               <tbody>
                   <tr className='text-center'>
                       <td>{purchaseReturnData.transaction_id}</td>
                       <td>{DateService.dateTimeFormat(purchaseReturnData.transaction_date, 'DD-MMM-YYYY')}</td>
                       <td>{purchaseReturnData.status_data ? purchaseReturnData.status_data.name : ''}</td>
                   </tr>
               </tbody>
           </table>
           <div className="tab_content_wrapper" >
               <span className="content_heading">Transaction Detail</span>
           </div>
           <table className="table table-hover table-bordered mb-0 mt-3 ">
               <tbody>
                   <tr>
                       <td style={{ width: "35%" }} >Warehouse</td>
                       <td colSpan={2}>{purchaseReturnData.warehouse ? <div><b>{purchaseReturnData.warehouse.name} ({purchaseReturnData.warehouse.code})</b> <br />
                           {purchaseReturnData.warehouse_address ?
                               <div className='pt-1'>Address : {purchaseReturnData.warehouse_address.address1 ? purchaseReturnData.warehouse_address.address1 + ", " : ""}
                                   {purchaseReturnData.warehouse_address.address2 ? purchaseReturnData.warehouse_address.address2 + ", " : ""}
                                   {purchaseReturnData.warehouse_address.landmark ? purchaseReturnData.warehouse_address.landmark + ", " : ""}
                                   {purchaseReturnData.warehouse_address.city ? purchaseReturnData.warehouse_address.city + ". " : "-"} <br />
                                   Destination of Supply : <b>{purchaseReturnData.warehouse_address.state_name}</b> <br />
                                   GSTIN  : {purchaseReturnData.warehouse_gstin ?? "-"}</div> : "-"}</div> : "-"}</td>
                   </tr>
                   <tr>
                       <td style={{ width: "35%" }} >Vendor</td>
                       <td colSpan={2}>{purchaseReturnData.vendor ? <div><b>{purchaseReturnData.vendor.name} ({purchaseReturnData.vendor.code})</b> <br />
                           {purchaseReturnData.vendor_address ?
                               <div className='pt-1'>Address : {purchaseReturnData.vendor_address.address1 ? purchaseReturnData.vendor_address.address1 + ", " : ""}
                                   {purchaseReturnData.vendor_address.address2 ? purchaseReturnData.vendor_address.address2 + ", " : ""}
                                   {purchaseReturnData.vendor_address.landmark ? purchaseReturnData.vendor_address.landmark + ", " : ""}
                                   {purchaseReturnData.vendor_address.city ? purchaseReturnData.vendor_address.city + ". " : "-"} <br />
                                   Source of Supply : <b>{purchaseReturnData.vendor_address.state_name} ({purchaseReturnData.vendor_address.country_name})</b> <br />
                                   GSTIN  : {purchaseReturnData.vendor_gstin ?? "-"}</div> : "-"}</div> : "-"}
                       </td>
                   </tr>
                   <tr>
                       <td>Purchase ID</td>
                       <th colSpan={2}className='link-primary cursor_pointer' style={{ width: "30%" }} onClick={() => this.viewPurchaseModalInit(purchaseReturnData.purchase_transaction_id)}>{purchaseReturnData.purchase_transaction_id}</th>
                   </tr>
                   <tr>
                       <td>Bill Number & Date</td>
                       <td style={{ width: "30%" }}>{purchaseReturnData.bill_number ? purchaseReturnData.bill_number : "-"}</td>
                       <td style={{ width: "35%" }}>{purchaseReturnData.bill_date ? DateService.dateTimeFormat(purchaseReturnData.bill_date, 'DD-MM-YYYY') : "-"}</td>
                   </tr>
                   <tr>
                       <td>Eway Number & Date</td>
                       <td>{purchaseReturnData.e_way_bill_number ? purchaseReturnData.e_way_bill_number : "-"}</td>
                       <td>{purchaseReturnData.e_way_bill_date ? DateService.dateTimeFormat(purchaseReturnData.e_way_bill_date, 'DD-MM-YYYY') : "-"}</td>
                   </tr>
                   <tr>
                       <td>Currency</td>
                       <td colSpan={2}>{purchaseReturnData.currency ? purchaseReturnData.currency : "-"}</td>
                   </tr>
                   <tr>
                       <td>Wallet</td>
                       <td colSpan={2}>{purchaseReturnData.wallet ? purchaseReturnData.wallet.wallet_name : "-"}</td>
                   </tr>
                   {purchaseReturnData.additional_attributes_data && purchaseReturnData.additional_attributes_data.length > 0
                       ? purchaseReturnData.additional_attributes_data.map((attr) => {
                           return (<tr key={attr.key}>
                               <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                               <td scope="col" colSpan={2}>{attr.value_display ? attr.value_display : "-"}</td>
                           </tr>)
                       }) : null}
               </tbody>
           </table>
           <div className="tab_content_wrapper" >
               <span className="content_heading">Item Detail</span>
           </div>
           <table className="table table-bordered table-responsive bg-white mt-3">
               <thead className="table-secondary">
                   <tr className='text-center'>
                       <th scope="col" style={{ width: "2%" }}>#</th>
                       <th scope="col" className="text-start" style={{ width: "33%" }}>Item Details</th>
                       <th scope="col" className="text-center" style={{ width: "15%" }}>Allocation</th>
                       <th scope="col" className="text-center" style={{ width: "15%" }}>Qty</th>
                       <th scope="col" className="text-end" style={{ width: "11%" }}>Unit Rate</th>
                       <th scope="col" className="text-end" style={{ width: "10%" }}>Discount</th>
                       <th scope="col" className="text-end" style={{ width: "10%" }}>GST</th>
                       <th scope="col" className="text-end" style={{ width: "10%" }}>Amount</th>
                   </tr>
               </thead>
               <tbody>
                   {purchaseReturnData.items && purchaseReturnData.items.length > 0 && purchaseReturnData.items.map((i, k) => {

                       return <tr>
                           <td>{k + 1}</td>
                           <td>
                               <div className='row'>
                                   <div className='col-sm-12'>
                                       <ItemViewInList view_hsn={true} item={i.item} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.item)} />
                                   </div>
                                   <div className='col-sm-12'>
                                       {i && i.batch_list && i.batch_list.length == 1 && i.batch_list.every(be => !be.batch_number && !be.expiry_date) ? null :
                                           <Ax>
                                               <table className="table table-bordered my-2 table-sm text-sm ">
                                                   <tbody>
                                                       <tr>
                                                           <td scope="col" style={{ width: "5%" }}>S.No</td>
                                                           {this.props.isc_configuration?.track_batch_expiry === "Y" && <><td scope="col" className="text-center" style={{ width: "30%" }}>Batch No</td>
                                                               <td scope="col" className="text-center" style={{ width: "30%" }}>Expiry Date</td></>}
                                                           <td scope="col" className="text-center" style={{ width: "15%" }}>Return Qty</td>
                                                       </tr>
                                                       {i && i.batch_list && i.batch_list.length > 0 && i.batch_list.map((be, batchIndex) => (
                                                           <tr key={batchIndex}>
                                                               <td className="text-center">{batchIndex + 1}</td>
                                                               {this.props.isc_configuration?.track_batch_expiry === "Y" && <> <td>{be.batch_number ? be.batch_number : ""}</td>
                                                                   <td>{be.expiry_date ? DateService.dateTimeFormat(be.expiry_date, 'DD-MMM-YYYY') : ""}</td></>}
                                                               <td className="text-center">{be.qty ? Number(be.qty) : ""}</td>
                                                           </tr>
                                                       ))}
                                                   </tbody>
                                               </table>
                                           </Ax>
                                       }

                                   </div>
                               </div>


                           </td>
                           <td>{i.purchase_item_data && i.purchase_item_data.po_transaction_id ? i.purchase_item_data.po_transaction_id : i.purchase_item_data && i.purchase_item_data.requisition_transaction_id ? i.purchase_item_data.requisition_transaction_id : "UnAllocated"} : {i.qty}</td>
                           <td className='text-center'>{i.qty}</td>
                           <td className='text-center'>{i.rate_vc ? i.rate_vc : "0"}</td>
                           <td className='text-center'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? purchaseReturnData.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : ""}</td>
                           <td className='text-end'>{i.gst}</td>

                           <td className='text-end'>
                               <CurrencyFormatter currencyCode={purchaseReturnData.currency} amount={i.item_with_discount} />
                           </td>
                       </tr>
                   })}
               </tbody>
           </table>
           <div className="row align-items-center mb-5">
               <div className="col-6">
                   <label htmlFor="notes" className="form-label">Notes</label>
                   <div>{purchaseReturnData.notes ?? "-"}</div>

               </div>
               <div className="col-6">
                   <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                   <table className="table  table-borderless">
                       <tbody>
                           <tr className="subtotal_bg_color">
                               <th>Sub-Total ({purchaseReturnData.currency ? purchaseReturnData.currency : "INR"})</th>
                               <td className='float-end'>{purchaseReturnData && purchaseReturnData.items && purchaseReturnData.items.length > 0 &&
                                   <CurrencyFormatter currencyCode={purchaseReturnData.currency} amount={purchaseReturnData.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />
                               }
                               </td>
                               {/* <td className='float-end'>{purchaseReturnData.total_amount_vc ? parseFloat(purchaseReturnData.total_amount_vc - (Number(purchaseReturnData.adjustment_amount_vc) + Number(purchaseReturnData.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                           </tr>
                           {purchaseReturnData && purchaseReturnData.tcs_rate_vc && purchaseReturnData.tcs_rate_vc > 0 ? <tr className="subtotal_bg_color">
                               <th>TCS ({purchaseReturnData.tcs_type == "Fixed" ? purchaseReturnData.currency : null} {purchaseReturnData.tcs_rate_vc} {purchaseReturnData.tcs_type == "Percentage" ? "%" : null} )</th>
                               <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>

                           </tr> : ""}
                           {purchaseReturnData.tax_slabs && purchaseReturnData.tax_slabs.length > 0
                               ? (purchaseReturnData.tax_slabs.map((ps, k) => {
                                   return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                               }))
                               : null
                           }
                           <tr className="subtotal_bg_color">
                               <th>Adjustment</th>
                               <td className='float-end'>{purchaseReturnData.adjustment_amount_vc}</td>
                           </tr>
                           <tr className="subtotal_bg_color">
                               <th>Final Total ({purchaseReturnData.currency ? purchaseReturnData.currency : "INR"})</th>
                               <td className='float-end'>{purchaseReturnData.total_amount ? <CurrencyFormatter currencyCode={purchaseReturnData.currency} amount={parseFloat(purchaseReturnData.total_amount).toFixed(2)} /> : 0}</td>
                           </tr>

                       </tbody>
                   </table>
               </div>
           </div>

      
   </Ax>}
   {this.transactionDetailsModalJsx()}
   <ItemDetailsModal ref={this.itemDetailsRef} />
        </div>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(purchaseReturnData);