import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FuelReceive from './FuelReceive';
import FuelIssued from './FuelIssued';
import FuelTransfer from './FuelTransfer';

class FuelStationList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.fuelStationSearchInitState      =   {
            'search_site_ids'           :       [],
            'site_group_ids'            :       [],
            'search_name'               :       '',
            'search_fuel_types'         :       [],
            'search_station_types'      :       []
        };

        this.fuelStationSearchFormLabel         =   {
            'site_group_ids'            :      'Site Groups : ',
            'search_site_ids'           :      'Site : ',
            'search_name'               :       'Fuel Station Name : ',
            'search_fuel_types'         :       'Fuel Type : ',
            'search_station_types'      :       'Fuel Station Type : '
        };

        this.updateFuelPriceFormInit        =       {
            id                      :       '',
            fuel_types              :       []
        }

        this.state                      =     {
            minimizeTable                :     false,
            listing_tbl_page             :     1,
            listing_loading              :     false,
            stationListData              :     [],
            totalListingCount            :     0,
            listingMeta                  :     null,
            fuelStationForm              :    {...this.fuelStationSearchInitState},
            submittedfuelStationForm     :    {...this.fuelStationSearchInitState},
            formDataLoaded                  :   false,
            allSiteGroupsList           :       [],
            iam_user_sites              :       [],
            form_data_loading           :       false,
            formSearchedElems           :       [],
            allFuelTypeList            :       [],
            allStationTypeList         :       [],
            view_loading                :      false,
            fuelStationData             :       null,
            updateFuelPriceForm         :       {...this.updateFuelPriceFormInit}
        };
        this.logFuelReceiveRef      =   React.createRef();
        this.logFuelIssuedRef       =   React.createRef()
        this.logFuelTransferRef     =   React.createRef()
        
        this.fuelStationUrl         =   IAM_API_BASE_URL_2 + '/fuel_station';
        this.fuelStationListUrl     =   IAM_API_BASE_URL_2 + '/fuel_station/list';
    }
    
    
    componentDidMount() {
        this.loadListingTblData(1)
        this.fuelStationSearchModal           =   new Modal(document.getElementById('fuelStationSearchModal'), {keyboard: false, backdrop :false});
        this.updateFuelPriceModal           =   new Modal(document.getElementById('updateFuelPriceModal'), {keyboard: false, backdrop :false});
        if(this.props.location && this.props.location.state && this.props.location.state.editId){
            this.afterUpdateListing(1,this.props.location.state.editId)
            this.setState({minimizeTable : true})
        }
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});    
        let params                      =   {page:page,per_page:50,...this.state.submittedfuelStationForm};
        HttpAPICall.withAthorization('GET', this.fuelStationListUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;

                this.setState({
                    stationListData     : respData.data,
                    listingMeta         : respData.meta,
                    totalListingCount   : respData.meta.total,
            })
        }).then(() => this.setState({listing_loading: false}));
    }

    afterUpdateListing          =       (page=1,id=null)    =>      {
        this.setState({view_loading:true});
        let stationList                        =   [...this.state.stationListData];
        let stationIndex                       =   stationList.findIndex(i => i.id == id);
        stationList[stationIndex]                 =   {...this.state.stationListData[stationIndex],is_Loading : true};
        this.setState({stationListData : stationList})
        let params                          =   {page : page,search_ids : [id]};
        HttpAPICall.withAthorization('GET', this.fuelStationListUrl, this.props.access_token, params, {}, (resp) => {
          
            if(resp.data && resp.data.data) {
                stationList[stationIndex]         =   resp.data.data[0];
                this.setState({
                    stationListData     :   stationList,
                    fuelStationData     :   resp.data.data[0]
                });
            }
        }).then(() => this.setState({view_loading:false}));
    }

    addFuelStation          =       ()          =>   {
        this.props.history.push({ pathname: `/fuelstation_add` })
    }

    viewFuelStationDetail      =       (data)      =>      {
        this.setState({minimizeTable : true,view_loading    :   true})
        if(data){
            this.setState({fuelStationData : data})
            setTimeout(() => {
                this.setState({view_loading     :   false})
            },1500)
        }
    }

    fuelFeedInSearchForminit        =       ()      =>      {
        this.fuelStationSearchModal.show();
        if(!this.state.formDataLoaded){
            this.getfuelStationSearchFormData(this.props)
        }
    }

    fuelStationSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems   :   [],
            fuelStationForm              :    {...this.fuelStationSearchInitState},
            submittedfuelStationForm     :    {...this.fuelStationSearchInitState},
        }, () => {
            this.loadListingTblData(1);
        });
    }

    getfuelStationSearchFormData        =       (pr)      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.fuelStationUrl + '/add_form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                formDataLoaded              :   true,
                iam_user_sites              :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                allSiteGroupsList           :   respData && respData.site_groups && respData.site_groups.length > 0 ? respData.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                allFuelTypeList             :   respData && respData.fuel_types &&  respData.fuel_types.length > 0  ? respData.fuel_types.map((s) => { return {value: s.id, label: `${s.fuel_type}`}}) : [],
                allStationTypeList          :   respData && respData.station_types   &&  respData.station_types.length > 0  ? respData.station_types.map((s) => { return {value: s.key, label: `${s.name}`}}) : [],
            })
        }).then(() => this.setState({form_data_loading: false}));
    }

    fuelStationFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
       
        let serachFormKeys                  =   Object.keys(this.state.fuelStationForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
       
        serachFormKeys.map((key)        =>  {
            let label                       =   this.fuelStationSearchFormLabel[key];
            let value                       =   this.state.fuelStationForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                   
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'search_fuel_types') {
                        show_val            =   this.state.allFuelTypeList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_station_types') {
                        show_val            =   this.state.allStationTypeList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_name') {
                        show_val            =   this.state.fuelStationForm.search_name;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedfuelStationForm           :   {...this.state.fuelStationForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
           this.fuelStationSearchModal.hide();
        });
    }

    receiveModalInit        =       (id)        =>      {
        if(id){
            this.logFuelReceiveRef.current.fuelReceiveModalInit(id)
        }
    }

    fuelIssuedModalInit        =       (id)        =>      {
        if(id){
            this.logFuelIssuedRef.current.issuedFuelModalInit(id)
        }
    }

    fuelTransferModalInit        =       (id)        =>      {
        if(id){
            this.logFuelTransferRef.current.transferFuelModalInit(id)
        }
    }

    editFuelStation         =   (data)      =>      {
        this.props.history.push({ pathname: `/fuelstation_add`,  state: { editFuelStationData : data } });
    }

    closeViewDetails        =   ()      =>      {
        this.setState({minimizeTable : false})
    }

    updateFuelPriceModalInit        =   (data)      =>  {
        if(this.state.fuelStationData){
            this.updateFuelPriceModal.show()
            this.setState({
                updateFuelPriceForm     :       {
                    id              :       this.state.fuelStationData.id,
                    fuel_types      :       this.state.fuelStationData.fuels_prices && this.state.fuelStationData.fuels_prices.length > 0 ? this.state.fuelStationData.fuels_prices.map(fp => {return({previous_price : fp.price,price : fp.price,fueltype_id : fp.fueltype_id,fuel_type : fp.fuel_type})}) : []
                }
            })
        }
    }

    fuelStationUpdatePriceFormSubmit        =   (e)     =>      {
        e.preventDefault();
        let frmData     =   {...this.state.updateFuelPriceForm}
        this.setState({saveFormSubmitting: true})
            HttpAPICall.withAthorization('PUT', this.fuelStationUrl + '/price_change' , this.props.access_token, {}, {...frmData}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.afterUpdateListing(1,this.state.fuelStationData.id);
                this.setState({updateFuelPriceForm : {...this.updateFuelPriceFormInit}})
                this.updateFuelPriceModal.hide()
           }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    changeStatusHandler     =       (id)      =>      {
        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', this.fuelStationUrl + '/status/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.afterUpdateListing(1,id);
                    }).then(() => {

                    });
                }
            });
    }

     //***********************fuelStation JSX****************

    fuelStationListjsx            =       ()      =>    {
        let obj = {};
       if(this.state.fuelStationData){
        obj = {'fuel_station_id' : this.state.fuelStationData.id,'fuel_type_id' : this.state.fuelStationData.fuels_prices[0]?.fueltype_id ,'date_range' : 'current_month'};
       }
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Fuel Station </h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                            <button type="button" className="btn btn-primary" disabled={this.props.permissions && this.props.permissions.length > 0 && this.props.permissions.includes('iam-fuel-station-add') ? false : true} onClick={() => this.addFuelStation()}>Add Fuel Station </button>
                            <button type="button" onClick={this.fuelFeedInSearchForminit} className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>


                        </Ax>
                        : null
                    }

                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Fuel Station : {this.state.fuelStationData && this.state.fuelStationData.fuelstation_name ? this.state.fuelStationData.fuelstation_name : "-"} </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-primary" disabled={this.props.permissions && this.props.permissions.length > 0 && this.props.permissions.includes('iam-fuel-station-update') ? false : true} onClick={() => this.editFuelStation(this.state.fuelStationData)}>
                                    Edit
                                </button>
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ cursor: "pointer" }}>
                                    <li>   <a role="button"
                                        onClick={() => {
                                            if (this.props.permissions.includes('iam-fuel-station-fuel-receive')) {
                                                return this.receiveModalInit(this.state.fuelStationData?.id)
                                            }
                                        }}
                                        className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-receive') ? '' : 'disabled'].join(' ')}
                                        title={!this.props.permissions.includes('iam-fuel-station-fuel-receive') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Received Fuel</a></li>
                                    <li>   <a role="button"
                                        onClick={() => {
                                            if (this.props.permissions.includes('iam-fuel-station-fuel-issue')) {
                                                return this.fuelIssuedModalInit(this.state.fuelStationData?.id)
                                            }
                                        }}
                                        className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-issue') ? '' : 'disabled'].join(' ')}
                                        title={!this.props.permissions.includes('iam-fuel-station-fuel-issue') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Issue Fuel</a></li>
                                    <li>   <a role="button"
                                        onClick={() => {
                                            if (this.props.permissions.includes('iam-fuel-station-fuel-transfer')) {
                                                return this.fuelTransferModalInit(this.state.fuelStationData?.id)
                                            }
                                        }}
                                        className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-transfer') ? '' : 'disabled'].join(' ')}
                                        title={!this.props.permissions.includes('iam-fuel-station-fuel-transfer') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Fuel Transfer To Station</a></li>
                                     <li>   <a role="button"
                                        onClick={() => {
                                            if (this.props.permissions.includes('iam-fuel-station-update')) {
                                                return this.updateFuelPriceModalInit(this.state.fuelStationData)
                                            }
                                        }}
                                        className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-update') ? '' : 'disabled'].join(' ')}
                                        title={!this.props.permissions.includes('iam-fuel-station-update') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Update Fuel Price</a></li>
                                    <li>  <Link target="_blank" className='dropdown-item' to={{ pathname: "/report/fuel_station_ledger", search: "?" + new URLSearchParams(obj).toString() }}>Fuel Ledger</Link>   </li>
                                    
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.fuelStationListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.fuelStationViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    fuelStationListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" style={{ height: "100%" }}>
              <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.fuelStationSearchClear}   /> 
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Fuel Station</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                            <th scope="col" style={{ width: "25%" }}>Station Name</th>
                            <th scope="col" style={{ width: "10%" }} className="text-center">Station Type</th>
                            <th scope="col" style={{ width: "25%" }}> Linked Sites</th>
                            <th scope="col" style={{ width: "20%" }}>Fuel Type</th>
                            <th scope="col" style={{ width: "10%" }} className='text-center'>Status</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.stationListData && this.state.stationListData.length > 0
                            ? (this.state.stationListData.map((item, index) => {
                                let obj = {'fuel_station_id' : item.id,'fuel_type_id' : item?.fuels_prices[0]?.fueltype_id ,'date_range' : 'current_month'};
                                return (<tr key={index} >
                                     {item.is_Loading && item.is_Loading == true ? <td colSpan={7} className="text-center"><Loader /></td> 
                                    :
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <div className="text-capitalize link-primary cursor_pointer" onClick={() => this.viewFuelStationDetail(item)}>{item.fuelstation_name ? item.fuelstation_name : "-"}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{item.fuels_prices && item.fuels_prices.length > 0 ? item.fuels_prices.map(s => {return(s.fuel_type)}).join(', ') : "-"}</small>
                                                            <small className="float-end"><Status color={item.status_color}>{item.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td><a role="button" className="link-primary text-capitalize cursor_pointer" onClick={() => this.viewFuelStationDetail(item)} >{item.fuelstation_name ? item.fuelstation_name : "-"}</a></td>
                                                <td className="text-center">{item.fuelstation_type_display ? item.fuelstation_type_display : "-"}</td>
                                                <td>{item.sites && item.sites.length > 0 ? item.sites.map(s => {return(s.site_name)}).join(', ') : "-"}</td>
                                                <td>{item.fuels_prices && item.fuels_prices.length > 0 ? item.fuels_prices.map(s => {return(s.fuel_type)}).join(', ') : "-"}</td>
                                                <td className="text-center">{item.status_text ? <Status color={item.status_color}>{item.status_text}</Status> : "-"}</td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li><a className='dropdown-item' onClick={() => this.viewFuelStationDetail(item)}>View</a></li>
                                                        <li><a className='dropdown-item' onClick={() => this.changeStatusHandler(item.id)}>{item.status == '1' ? 'Deactivate' : 'Activate'}</a></li>
                                                        <li><Link target="_blank" className='dropdown-item' to={{ pathname: "/report/fuel_station_ledger", search: "?" + new URLSearchParams(obj).toString() }}>Fuel Ledger</Link></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
           
        </div>)
    }

    fuelStationViewDetails                =   ()   =>   {
       
        return (<section className="page_containt " style={{ top: "-2px" }}>
            <div className="pageTbl bg-white">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                            type="button" role="tab" aria-controls="details" aria-selected="true">Details
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link " id="linking-tab" data-bs-toggle="tab" data-bs-target="#linking"
                            type="button" role="tab" aria-controls="linking" aria-selected="true">Site Linking
                        </button>
                    </li>

                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane  show active" id="details" role="tabpanel"
                        aria-labelledby="details-tab">
                        {this.state.view_loading
                            ? <Loader />
                            : <div className="tab_content_header">
                                <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "25%" }}>Station Name</td>
                                            <th colSpan={2}>{this.state.fuelStationData && this.state.fuelStationData.fuelstation_name ? this.state.fuelStationData.fuelstation_name :  "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Status</td>
                                            <th colSpan={2}>{this.state.fuelStationData && this.state.fuelStationData.status ? <Status color={this.state.fuelStationData.status_color}>{this.state.fuelStationData.status_text}</Status> :  "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Station Type</td>
                                            <th colSpan={2}>{this.state.fuelStationData && this.state.fuelStationData.fuelstation_type_display ? this.state.fuelStationData.fuelstation_type_display :  "-"}</th>
                                        </tr>
                                       
                                        {this.state.fuelStationData && this.state.fuelStationData.fuels_prices ?
                                            this.state.fuelStationData.fuels_prices.map((fp,index) => {
                                                return (
                                                    <tr>
                                                        {index == 0 ? <td style={{ width: "25%" }} rowSpan={this.state.fuelStationData.fuels_prices.length} className="align-middle">Fuel Type & Price</td> : null}
                                                        <th style={{ width: "25%" }}>{fp.fuel_type}</th>
                                                        <th>{fp.price}</th>
                                                    </tr>
                                                )
                                            })
                                            : "-"}
                                            <tr>
                                            <td style={{ width: "25%" }}>Remarks</td>
                                            <th colSpan={2}>{this.state.fuelStationData && this.state.fuelStationData.remarks ? this.state.fuelStationData.remarks :  "-"}</th>
                                        </tr>


                                    </tbody>
                                </table>

                            </div>}
                    </div>
                    <div className="tab-pane" id="linking" role="tabpanel"
                        aria-labelledby="linking-tab">
                        <div className="tab_content_header">
                            <div className="tab_content_wrapper"><span className="content_heading">Sites</span>
                                {/* <div className="float-end"><button className="btn btn-primary">Link Sites</button></div> */}
                            </div>
                            <table className="table table-hover table-bordered table-responsive bg-white my-3 ">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th style={{ "width": '10%' }}>S.No</th>
                                        <th className="text-start">Site Name (Code)</th>
                                        {/* <th style={{ width: "10%" }}>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fuelStationData && this.state.fuelStationData.sites && this.state.fuelStationData.sites.length > 0 ?
                                        this.state.fuelStationData.sites.map((site, index) => {
                                            return (
                                                <tr>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td>{site.site_name}</td>
                                                    {/* <td>
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }}>
                                                            <li>
                                                                <a role={"button"} href="#" className="dropdown-item" >
                                                                    DeLink </a> </li>
                                                        </ul>
                                                    </td> */}
                                                </tr>
                                            )
                                        })
                                        : <tr><td colSpan={3} className="text-center">No Record</td></tr>}

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </section>)
    }

    fuelStationSearchModalJsx                             =   ()  =>  {

        return (
            <div className="modal fade" id="fuelStationSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="fuelStationSearchModalLabel">Fuel Station Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.fuelStationFormSubmit} id="fuelStationSearchForm">
                            <div className="modal-body">
                                {this.state.form_data_loading ? <Loader />
                                    : <Ax>
                                        {this.state.formDataLoaded
                                            ? <Ax>
                                                <div className="row mb-3">
                                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Group</label>
                                                    <div className="col-sm-10">
                                                        <TapSelect
                                                            options={this.state.allSiteGroupsList}
                                                            changeEvent={(selectedOption) => {

                                                                this.setState({
                                                                    fuelStationForm: {
                                                                        ...this.state.fuelStationForm,
                                                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                                    }
                                                                })
                                                            }}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={this.state.allSiteGroupsList.filter(s => this.state.fuelStationForm.site_group_ids.includes(s.value))}
                                                            placeholder="Select Site Group"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />
                                                    </div>

                                                </div>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site</label>
                                                    <div className="col-sm-10">
                                                        <TapSelect
                                                            options={this.state.iam_user_sites}
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(selectedOption, 'fuelStationForm', 'search_site_ids');
                                                            }}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            menuPlacement="bottom"
                                                            value={this.state.iam_user_sites.filter(s => this.state.fuelStationForm.search_site_ids.includes(s.value))}
                                                            placeholder="Select Sites"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Fuel Station Name</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            name="search_name"
                                                            type="text"
                                                            value={this.state.fuelStationForm.search_name}
                                                            className="form-control form-control-sm"
                                                            autoComplete="off"
                                                            onChange={(e) => this.formInputHandler(e, "fuelStationForm")}
                                                            placeholder="Please enter Fuel Station Name" />

                                                    </div>
                                                </div>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Fuel Station Types</label>
                                                    <div className="col-sm-10">
                                                        <TapSelect
                                                            options={this.state.allStationTypeList}
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(selectedOption, 'fuelStationForm', 'search_station_types');
                                                            }}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            menuPlacement="bottom"
                                                            value={this.state.allStationTypeList.filter(s => this.state.fuelStationForm.search_station_types.includes(s.value))}
                                                            placeholder="Select Fuel Station Type"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row my-2'>
                                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Fuel Type</label>
                                                    <div className="col-sm-10">
                                                        <TapSelect
                                                            options={this.state.allFuelTypeList}
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(selectedOption, 'fuelStationForm', 'search_fuel_types');
                                                            }}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            menuPlacement="bottom"
                                                            value={this.state.allFuelTypeList.filter(s => this.state.fuelStationForm.search_fuel_types.includes(s.value))}
                                                            placeholder="Select Fuel Type"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />
                                                    </div>
                                                </div>
                                            </Ax>
                                            : null}
                                    </Ax>}


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    fuelStationUpdatePriceModalJsx                             =   ()  =>  {

        return (
            <div className="modal fade" id="updateFuelPriceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="fuelStationSearchModalLabel">Fuel Station Update Price</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Fuel Station Name   </td>
                                        <th>{this.state.fuelStationData ? `${this.state.fuelStationData.fuelstation_name}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Station Type </td><th>{this.state.fuelStationData ? `${this.state.fuelStationData.fuelstation_type_display} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.fuelStationUpdatePriceFormSubmit} id="updateFuelPriceForm">
                            <div className="modal-body">
                                <div className='row my-1'>
                                    <div className='offset-3 col-sm-4'>
                                        <small className="form-text">Previous Fuel Price</small>
                                    </div>
                                    <div className='col-sm-5'>
                                        <small className="form-text">New Fuel Price</small>
                                    </div>
                                </div>

                                {this.state.updateFuelPriceForm && this.state.updateFuelPriceForm.fuel_types && this.state.updateFuelPriceForm.fuel_types.length > 0
                                    ? this.state.updateFuelPriceForm.fuel_types.map((fuel, index) => {
                                       return(<Ax>
                                         <div className='row my-2'>
                                            <label className="col-sm-3 col-form-label col-form-label-sm ">{fuel.fuel_type}</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="previous_price"
                                                    type="text"
                                                    value={fuel.previous_price}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    disabled={true}
                                                    placeholder="Previous Fuel Price" />

                                            </div>
                                            <div className="col-sm-4">
                                                <input
                                                    name="price"
                                                    type="number"
                                                    value={fuel.price}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.dynamicInputHandlerTwoLevelByKeyNameVal(e.target.value,'price', index, 'fuel_types', 'updateFuelPriceForm')}
                                                    placeholder="New Fuel Price" />

                                            </div>
                                        </div>
                                       </Ax>)

                                    }) : null}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Submit {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


     
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Fuel Station Listing</title></Helmet>
            {this.fuelStationListjsx()}
           {this.fuelStationSearchModalJsx()}
           {this.fuelStationUpdatePriceModalJsx()}
           <FuelReceive ref={this.logFuelReceiveRef} afterSubmitReceiveFuel={() => this.loadListingTblData(1)}/>
           <FuelIssued ref={this.logFuelIssuedRef} afterSubmitIssuedFuel={() => this.loadListingTblData(1)}/>
           <FuelTransfer ref={this.logFuelTransferRef} afterSubmitFuelTransferHandler={() => this.loadListingTblData(1)}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(FuelStationList);