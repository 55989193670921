import React from 'react';
import Ax from "../../../components/hoc/Ax";

export default (props) => <Ax>
    <label className='form-label'>Address : {props.warehouseDetail.address1 ? props.warehouseDetail.address1 + ", " : ""}
        {props.warehouseDetail.address2 ? props.warehouseDetail.address2 + ", " : ""}
        {props.warehouseDetail.landmark ? props.warehouseDetail.landmark + ", " : ""}
        {props.warehouseDetail.city ? props.warehouseDetail.city + ". " : "-"}</label><br />
    <label className='form-label'>Source of Supply : {props.warehouseDetail && props.warehouseDetail.state ? <span>{props.warehouseDetail.state.name}</span> : "-"}</label> <br />
    <label className='form-label'>GSTIN : {props.warehouseDetail && props.warehouseDetail.gstin ? <span>{props.warehouseDetail.gstin}</span> : "-"}</label>    
</Ax>;