import * as actionTypes from './actionTypes';
import axios from 'axios';
import HttpAPICall from '../../services/HttpAPICall';
import { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';



export const reportListingInitilization   = () => {
    return {
        type                :   actionTypes.REPORT_LISTING_DATA_INIT
    };
};



export const reportListingDataFetch   = (access_token) => {
    const report_url         =   ORG_API_BASE_URL_2 + '/reports';
    return dispatch => { 
        HttpAPICall.withAthorization('GET', report_url, access_token, {}, {}, (resp) => {
            dispatch(reportListingDataLoadSuccessfully(resp.data));
        });
    }
};


export const reportListingDataLoadSuccessfully   = (data) => {
    return {
        type                     :   actionTypes.REPORT_LISTING_DATA_SUCCESS,
        report_listing           :   data
    };
};





