import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import Ax from '../../../../../components/hoc/Ax';
import TapApiUrls, { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import uuid from 'react-uuid';
import HttpAPICall from '../../../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Loader from '../../../../../components/ui/Loader/Loader';
import TapIcon from '../../../../../services/TapIcon';
import TapSelect from '../../../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import TaskSavedStep from './TaskSavedStep';
import AssetService from '../../../../../services/AssetService';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InputEmployeeSearch from '../../../../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../../../../includes/ui/InputWorkstationSearch';
import InputSupplierVendorSearch from '../../../../includes/ui/InputSupplierVendorSearch';
import AdditionalAttributeForm from '../../../../includes/ui/AdditionalAttributeForm';


class TaskSaveModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.task_init_form_data_url        =   IAM_API_BASE_URL + '/counterlog/activity_task/initialize_form_data/__counterlog_txn_id';
        this.task_start_form_data_url       =   IAM_API_BASE_URL + '/counterlog/activity_task/start_form_data/__counterlog_txn_id/__activity_id';
        this.shift_task_detail_url          =   IAM_API_BASE_URL + '/counterlog/activity_task/detail';
        this.task_edit_form_data_url        =   IAM_API_BASE_URL + '/counterlog/activity_task_step_form_data';
        this.counterlog_shift_detail_url    =   IAM_API_BASE_URL + '/counterlog/shift_detail/__counterlog_txn_id';
        this.task_close_data_url            =   IAM_API_BASE_URL + '/counterlog/activity_task/close';
        this.task_start_data_url            =   IAM_API_BASE_URL + '/counterlog/activity_task/start';
        this.task_edit_url                  =   IAM_API_BASE_URL + '/counterlog/activity_task_step';
        

        this.taskSaveFormInit       =   {
            start_cl_date_time          :   new Date(),
            cl_date_time                :   '',
            end_cl_date_time            :   '',
            operator_ids                :   [],
            loading                     :   false,
            activity_id                 :   '',
            counterlog_transaction_id   :   '',
            step_id                     :   '',
            task_start_at               :   '',
            additional_attributes       :   null,
            production                  :   '',
            production_additional_attributes : null,
            task_close_at               :   '',
            production_unit             :   ''
        };

        this.initState = {
            heading                     :   'Add Task',
            asset                       :   null,
            site: null,
            shift: null,
            counterlog: null,
            loading_form_data: false,
            activities: [],
            one_task_in_progress            :   'N',
            task_start_initialized: false,
            selected_activity_id            :   '',
            selected_activity               :   null,
            current_step                    :   null,
            form_fields                     :   [],
            completed_steps                 :   [],
            taskData                        :   null,
            taskProceeding                  :   false,
            form_data                       :   null,
            taskFormFieldData               :   null,
            stepData                        :   null,
            higherlevel_completed_steps            :   [],
            editMode                        :   'N',
           

            new_workstation_from            :   false,
            new_workstation_to              :   false,
            new_workstation                 :   false,
            new_material                    :   false,
            new_attachement                 :   false,
            taskSaveForm                    :   {...this.taskSaveFormInit},
            searchedEmployee                :  [],
            addNewUnit                      :   'N',
            addNewWorkstation           :   'N',
            addNewWorkstationFrom       :   'N',
            addNewWorkstationTo         :   'N',
            taskEdited : false,
            copy_latest_reading_start       :   false,
            copy_latest_reading             :   false,
            copy_latest_reading_end         :   false,

            addNewMaterial                  :   'N',
            addNewAttachment                :   'N',
            edit_form_loading               :   false,

            addNewPouringLocation           :   'N',
            ncc_pouring_location                :   null,
            searchedPouringLocation         :   null,

            searchedHgVendor                :   null,

            isModal                         :   true

        };
        
        this.state                  =   {
            ...this.initState,
        }

        this.uploadTaskflowModalId   =   uuid();
        this.updateTaskflowFormId    =   this.uploadTaskflowModalId + 'Form';
        
       
    }

    componentDidMount                   =   ()  =>  {
        this.uploadTaskflowModal         =   new Modal(document.getElementById(this.uploadTaskflowModalId), {keyboard: false, backdrop :false}); 
    }


    uploadTaskModalInit         =   (asset, site, shift, counterlog, one_task_in_progress, transaction_id,isModal=true)      =>      {
        if(isModal){
            this.uploadTaskflowModal.show();
        }
        this.setState({...this.initState}, () => {
            setTimeout(() => {
                
                this.setState({
                    asset               :   asset       ||null,
                    site                :   site        ||  null,
                    shift               :   shift       ||  null,
                    counterlog          :   counterlog  ||  null,
                    one_task_in_progress:   one_task_in_progress && one_task_in_progress == 'Y' ? 'Y' : 'N',
                    transaction_id      :   transaction_id || null,
                    isModal             :   isModal ,
                   taskSaveForm         :   {...this.taskSaveFormInit, counterlog_transaction_id   :   counterlog && counterlog.transaction_id ? counterlog.transaction_id : ''}
                }, () => {
                  this.loadFormData();
                   
                });
            }, 100);
        });
    }

    loadFormData = () => {
        if (!this.state.counterlog) {
            this.uploadTaskflowModal.hide()
            // this.onModalClose();
        } else {
          
            if(this.state.shift) {
                if(this.state.one_task_in_progress == 'Y' || this.state.isModal == false) {
                    this.progressTaskInitialize(this.state.transaction_id ? this.state.transaction_id : null); 
                } else {
                    this.newTaskInitialize();
                }    
            } else {
                
                this.setState({ loading_form_data: true, task_start_initialized : true,  one_task_in_progress: "Y", heading: 'Update Task' });
                let url = this.counterlog_shift_detail_url.replace('__counterlog_txn_id', this.state.counterlog.transaction_id);
                HttpAPICall.withAthorization('GET', url, this.props.access_token, null, null, (response) => {
                    let res             =   response.data;
                    this.setState({
                        shift               :   res.shift ? {...res.shift, feed_datetime : this.state.counterlog.feed_datetime } : null
                    },() => {
                        this.progressTaskInitialize(this.state.transaction_id);
                       
                    });
                }).then(() => this.setState({ loading_form_data: false }));
            }
        }
    }

    progressTaskInitialize              =  (task_transaction_id = null) => {
      
        this.setState({ loading_form_data: true, one_task_in_progress: "Y", heading: 'Update Task' });
        let url         =   this.shift_task_detail_url;
        let params      =   { counterlog_transaction_id: this.state.counterlog.transaction_id };
        if(task_transaction_id) {
            params      =   { transaction_id: task_transaction_id };
        }
        HttpAPICall.withAthorization('GET', url, this.props.access_token, params, null, (response) => {
            let respData            =   response.data;
            let current_step        =   respData.next_step;
            let stepData            =   (current_step && respData.steps) ? respData.steps.find(s => s.id == current_step.id) : null;
            let form_fields         =   stepData ? stepData.fields : [];
            let taskData            =   respData.taskData;
          
            this.setState({
                current_step            :   current_step && current_step.can_submit == 'Y' ? current_step : null,
                form_fields             :   current_step && current_step.can_submit == 'Y'? form_fields : [],
                form_data               :   stepData ? stepData.form_data : null,
                task_start_initialized  :   true,
                completed_steps         :   current_step ? respData.steps.filter(s => s.level < current_step.level) : respData.steps,
                taskData                :   taskData,
                editMode    :   'N',
            });
            if(form_fields.find(ff => ff.field_key == 'counterlog_readings')) {
                // this.formInputHandler(taskData.task_start_at ? new Date(taskData.task_start_at) : new Date(), 'cl_date_time','taskSaveForm');
            }
            if(form_fields.find(ff => ff.field_key == 'closing_readings')) {
                // this.formInputHandler(taskData.task_start_at ? new Date(taskData.task_start_at) : new Date(), 'end_cl_date_time','taskSaveForm');
            }
            // this.scrollToCurrentForm();
        }).then(() => this.setState({ loading_form_data: false, }));
    }

    newTaskInitialize        = ()               => {
       
        this.setState({ loading_form_data: true, one_task_in_progress: "N", heading: 'Add Task' });
        let url = this.task_init_form_data_url.replace('__counterlog_txn_id', this.state.counterlog.transaction_id);
        HttpAPICall.withAthorization('GET', url, this.props.access_token, null, null, (response) => {
            let res = response.data;
            this.setState({
                activities                  :       res.activities ? res.activities.map((a) => { return { value: a.id, label: a.name } }) : [],
                task_start_initialized      :       false,
                selected_activity_id        :       '',
                selected_activity           :        null,
                current_step                :       null,
                completed_steps             :       [],
                form_fields                 :       [],
                editMode                    :       'N'
            });
        }).then(() => this.setState({ loading_form_data: false }));
    }

    taskProceedToStartHandler = () => {
       
        if (this.state.selected_activity_id == '') {
            toast.error('Please Select Activity', { position: toast.POSITION.TOP_RIGHT });
        } else {
            this.setState({ taskProceeding: true,saveFormSubmitting : true });
            let url = this.task_start_form_data_url
                .replace('__counterlog_txn_id', this.state.counterlog.transaction_id)
                .replace('__activity_id', this.state.selected_activity_id);
            HttpAPICall.withAthorization('GET', url, this.props.access_token, null, null, (response) => {
                let current_step = response.data.step;
                
                this.setState({
                    current_step        : current_step,
                    form_fields         : current_step.fields,
                    task_start_initialized  : true,
                    form_data               : current_step.form_data,
                    editMode                :   'N',
                   
                    selected_activity       : this.state.activities.find(a => a.value == this.state.selected_activity_id),
                   
                },() => {
                    if(this.state.form_data && this.state.form_data.new_task_start_on_prev_task_close == 'Y' && this.state.form_data.prev_task_end_counterlog){
                        let { prev_task_end_counterlog } = { ...this.state.form_data };
                        
                        let assetMeters = AssetService.avaialbleAssetMeters(this.state.asset);
                            let copyReading = {};
                            assetMeters.forEach((meter, mk) => {
                                let input_state_key = AssetService.getTaskStartingReadingInputKey(meter.key);
                                copyReading[input_state_key] = prev_task_end_counterlog[meter.abs_key];
                            });
                            this.setState({
                                taskSaveForm: { ...this.state.taskSaveForm, ...copyReading }
                            });
                    }
                });
                  
                
            }).then(() => this.setState({ taskProceeding: false,saveFormSubmitting : false }));
        }
    }

    calulateProductivityFormula         =   (linkedFormula)  =>  {
        let {production_additional_attributes} =   {...this.state.form_data};
        if(production_additional_attributes && production_additional_attributes.length> 0) {
            let list = production_additional_attributes.filter(key => key.type == "numeric"  ? true : false);
            list.forEach((paa, k) => {
                window[paa.key] = this.state.taskSaveForm && this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[paa.key] 
                                    ? parseFloat(this.state.taskSaveForm.production_additional_attributes[paa.key]) : '';
            });
            try {
                let net_productivity = linkedFormula && linkedFormula.formula ?  eval(linkedFormula.formula) : 0;
                this.setState({
                    taskSaveForm     :       {
                        ...this.state.taskSaveForm,
                        production                :       net_productivity ? parseFloat(net_productivity).toFixed(2) : this.state.taskSaveForm.production
                    }
                })
            } catch(e) {
                this.setState({
                    taskSaveForm     :       {
                        ...this.state.taskSaveForm,
                        production                :       0
                    }
                })
                
            }
        }
    }

    editTaskForm              =  (step,transaction_id) => {
       
        this.setState({ loading_form_data: true, one_task_in_progress: "Y", heading: 'Update Task' });

        let url         =   this.task_edit_form_data_url;
        let params      =   { transaction_id: transaction_id,step_id:step && step.id ? step.id : '' };
       
         HttpAPICall.withAthorization('GET', url, this.props.access_token, params, null, (response) => {
            let respData            =   response.data;
          
            let current_step        =   respData.step;
            let stepData            =   respData.step;
            let form_fields         =   stepData ? stepData.fields : [];
            let stepDetail          =   current_step ? this.state.completed_steps.find(s => s.level == current_step.level) : null;
            let detail              =  stepDetail && stepDetail.detail ? stepDetail.detail : null;
          
            this.setState({
                    current_step            :   current_step,
                    form_fields             :   form_fields,
                    form_data               :   stepData ? stepData.form_data : null,
                    stepData                :   stepData && stepData.detail ? stepData.detail : null,
                    task_start_initialized  :   true,
                    editMode                :   'Y',
                    completed_steps         :   current_step ? this.state.completed_steps.filter(s => s.level < current_step.level) : this.state.completed_steps,
                    higherlevel_completed_steps    :   current_step ? this.state.completed_steps.filter(s => s.level > current_step.level) : this.state.completed_steps,
                    selected_activity       :   this.state.taskData && this.state.taskData.activity ? {...this.state.taskData.activity,name:this.state.taskData.activity.activity_name} : null
            });
            
            
         
        })
        
    }

    initalizeEditForm       =       (current_step)    =>  {
        let stepDetail          =   current_step ? this.state.completed_steps.find(s => s.level == current_step.level) : null;
        let detail              =  stepDetail && stepDetail.detail ? stepDetail.detail : null;
       
        setTimeout(() => {
            let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.asset);
            let meterReading                =   {};
            let counterlogMeterReading      =   {};
            let closingReading              =   {};
            assetMeters.forEach((meter, mk) => {
                let input_state_key       =     AssetService.getTaskStartingReadingInputKey(meter.key);
                // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]};
              
                meterReading[input_state_key]  = detail[input_state_key];
            });
    
            assetMeters.forEach((meter, mk) => {
                let input_state_key       =     AssetService.getTaskCounterLogReadingInputKey(meter.key);
                // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]};
              
                counterlogMeterReading[input_state_key]  = detail[input_state_key];
            });
    
            assetMeters.forEach((meter, mk) => {
                let input_state_key       =     AssetService.getTaskClosingReadingInputKey(meter.key);
                // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]};
              
                closingReading[input_state_key]  = detail[input_state_key];
            });
    
            let additional_attribute_data = [];
                if (this.state.taskData && this.state.taskData.additional_attributes) {
                    Object.keys(this.state.taskData.additional_attributes).map((k) => {
                        const name = `additional_attributes_${k}`;
                        additional_attribute_data[name] = this.state.taskData.additional_attributes[k]
                    });
                }
            let productivity_additional_attribute_data = [];
            if (detail && detail.production_additional_attributes) {
                Object.keys(detail.production_additional_attributes).map((k) => {
                    
                    productivity_additional_attribute_data[k] = detail.production_additional_attributes[k]
                });
            }

           
            
            this.setState({
                loading_form_data       :   false,
                taskSaveForm : {
                    ...this.state.taskSaveForm,
                    ...meterReading,
                    ...counterlogMeterReading,
                    ...additional_attribute_data,
                    ...closingReading,
                    activity_count      :       detail.activity_count ? detail.activity_count : '' ,
                    activity_type       :       detail.activity_type ? detail.activity_type : '',
                    closing_remarks     :      detail.closing_remarks ? detail.closing_remarks : '',
                    task_close_at       :      this.state.taskData && this.state.taskData.task_close_at ? this.state.taskData.task_close_at : '',
                    task_start_at       :      this.state.taskData && this.state.taskData.task_start_at ? this.state.taskData.task_start_at : '',
                    start_cl_date_time  :       detail.start_cl_date_time ? detail.start_cl_date_time : this.state.taskSaveForm.start_cl_date_time,
                    operator_ids        :       detail.operators && detail.operators.length > 0 ? detail.operators.map(op => op.enc_id) : [],
                    workstation_from    :       detail.workstation_from  ? detail.workstation_from : "",
                    workstation_to      :       detail.workstation_to  ? detail.workstation_to : "",
                    workstation_id      :     detail.workstation_id  ? detail.workstation_id : "",
                    production          :      detail.production ? detail.production : "",
                    production_unit     :      detail.production_unit ? detail.production_unit : "",
                    material_id         :      detail.material_id  ? detail.material_id : "",
                    attachment_id       :      detail.attachment_id ? detail.attachment_id : "",
                    cl_date_time        :      detail.cl_date_time  ? detail.cl_date_time : "",
                    loaded_weight       :      detail.loaded_weight ? detail.loaded_weight : "",
                    unloaded_weight     :      detail.unloaded_weight ? detail.unloaded_weight : "",
                    loaded_weight_unit  :    detail.loaded_weight_unit ? detail.loaded_weight_unit : "",
                    unloaded_weight_unit   :    detail.unloaded_weight_unit ? detail.unloaded_weight_unit : "",
                    productivity_remark    :    detail.productivity_remark ? detail.productivity_remark : "",
                    //additional_attributes :    {...additional_attribute_data},
                    production_additional_attributes : {...productivity_additional_attribute_data},
                    end_cl_date_time    :       detail.end_cl_date_time ? detail.end_cl_date_time : "",
                    step_id             :       stepDetail && stepDetail.id ? stepDetail.id : '',
                    execution_by        :       detail.execution_by ? detail.execution_by : '',
                    supplier_vendor_id   :      detail.supplier_vendor_id ? detail.supplier_vendor_id : '',
                    ncc_gate_in_time        :   detail.ncc_gate_in_time ? detail.ncc_gate_in_time : '',
                    ncc_qc_pass            :     detail.ncc_qc_pass ? detail.ncc_qc_pass : '',
                    ncc_qc_time             :    detail.ncc_qc_time ? detail.ncc_qc_time : '',
                    ncc_pouring_location    :   detail.ncc_pouring_location ? detail.ncc_pouring_location : '',
                    ncc_pouring_qty          :      detail.ncc_pouring_qty ? detail.ncc_pouring_qty : '',
                    ncc_pouring_start_time      :   detail.ncc_pouring_start_time ? detail.ncc_pouring_start_time : '',
                    ncc_pouring_end_time      :   detail.ncc_pouring_end_time ? detail.ncc_pouring_end_time : '',
                    hg_area_of_work             :   detail.hg_area_of_work ? detail.hg_area_of_work : '',
                    hg_handle_by              :     detail.hg_handle_by ? detail.hg_handle_by : ''
                },
                selected_activity       :      this.state.taskData && this.state.taskData.activity ? {...this.state.taskData.activity,label:this.state?.selected_activity?.name} : null,
                selected_activity_id    :      this.state.taskData && this.state.taskData.activity_id ? this.state.taskData.activity_id : null, 
                searchedEmployee        :       detail.operators && detail.operators.length > 0 ? detail.operators.map(op => {return({...op,value:op.enc_id,label : op.display_full_name})})  : [],
                searchedWorkstationFrom   :       detail.workStationFrom ? [{...detail.workStationFrom, value : detail.workStationFrom.id, label : (detail.workStationFrom.name)}] : [],
                searchedWorkstationTo     :       detail.workStationTo ? [{...detail.workStationTo, value : detail.workStationTo.id, label : (detail.workStationTo.name)}] : [],
                searchedWorkstation       :       detail.workStation ? [{...detail.workStation, value : detail.workStation.id, label : (detail.workStation.name)}] : [],
                searchedPouringLocation     :     detail.ncc_pouring_workstation ? [{...detail.ncc_pouring_workstation, value : detail.ncc_pouring_workstation.id, label : (detail.ncc_pouring_workstation.name)}] : [],
                searchedHgVendor          :       detail.supplier_vendor ? {...detail.supplier_vendor, value : detail.supplier_vendor.id, label : (detail.supplier_vendor.name)} : null,
            })
        },2000)
        
   }

    submitTaskflowForm      =   (e)     =>      {
        e && e.preventDefault();
       
        let { selected_activity_id, form_fields, shift, asset,counterlog,one_task_in_progress } = { ...this.state };
        let form_field_keys                 =   form_fields && form_fields.length > 0 ?  form_fields.map(ff => ff.field_key) : [];
        let additional_attributes = {};
        if (form_fields && form_fields.length > 0) {
            form_fields.map((field, k) => {
                if (field.is_additional_attribute == 'Y' && field.additional_attribute) {
                    let data = field.additional_attribute;
                    let name = 'additional_attributes_' + data.key;
                    additional_attributes[data.key] = this.state.taskSaveForm[name] ? this.state.taskSaveForm[name] : '';
                }
            })
        }
        
         let taskSaveFormData            =   {...this.state.taskSaveForm,additional_attributes : additional_attributes, step_id : this.state.current_step ? this.state.current_step.id : ''};
        
         
         if(this.state.editMode == 'Y'){
           
            let taskEditFormData            =   {
                ...taskSaveFormData, 
                transaction_id              :   this.state?.taskData?.transaction_id ,
                counterlog_transaction_id: this.state.counterlog.transaction_id,
                 cl_date_time                :   this.state.taskSaveForm.cl_date_time ? moment(this.state.taskSaveForm.cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                end_cl_date_time            :  this.state.taskSaveForm.end_cl_date_time ?  moment(this.state.taskSaveForm.end_cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                 task_close_at                :  this.state.taskSaveForm.task_close_at ? moment(this.state.taskSaveForm.task_close_at).format('YYYY-MM-DD HH:mm:ss') : '',
                 task_start_at               :   this.state.taskSaveForm.task_start_at ? moment(this.state.taskSaveForm.task_start_at).format('YYYY-MM-DD HH:mm:ss') : '',
                 start_cl_date_time          :  this.state.taskSaveForm.start_cl_date_time ?  moment(this.state.taskSaveForm.start_cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
            };
             
            this.setState({saveFormSubmitting : true})
            HttpAPICall.withAthorization('PUT', this.task_edit_url, this.props.access_token, null, taskEditFormData, (response) => {
                let respData = response.data;
                if (this.props.afterSaveTask) {
                    this.props.afterSaveTask(shift.feed_datetime, asset.asset_id);
                }
                this.uploadTaskModalInit(asset, this.state.site, shift, counterlog, one_task_in_progress, this.state?.taskData?.transaction_id)
               this.setState({taskEdited : true})
              // if()
            }).then(() => {
                setTimeout(() => this.setState({saveFormSubmitting : false}), 1000);
            });
         }else{
            if(this.state.one_task_in_progress == 'Y') {
                let taskCloseFormData            =   {
                    ...taskSaveFormData, 
                    activity_id                 :   selected_activity_id,
                    transaction_id              :   this.state?.taskData?.transaction_id ,
                    counterlog_transaction_id: this.state.counterlog.transaction_id,
                    cl_date_time                :   this.state.taskSaveForm.cl_date_time ? moment(this.state.taskSaveForm.cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                    end_cl_date_time            :  this.state.taskSaveForm.end_cl_date_time ?  moment(this.state.taskSaveForm.end_cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                     task_close_at                :  this.state.taskSaveForm.task_close_at ? moment(this.state.taskSaveForm.task_close_at).format('YYYY-MM-DD HH:mm:ss') : '',
                     task_start_at               :   this.state.taskSaveForm.task_start_at ? moment(this.state.taskSaveForm.task_start_at).format('YYYY-MM-DD HH:mm:ss') : '',
                     start_cl_date_time          :  this.state.taskSaveForm.start_cl_date_time ?  moment(this.state.taskSaveForm.start_cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                };
                
               this.setState({saveFormSubmitting : true})
                HttpAPICall.withAthorization('PUT', this.task_close_data_url, this.props.access_token, null, taskCloseFormData, (response) => {
                    let respData = response.data;
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.uploadTaskflowModal.hide();
                    if (this.props.afterSaveTask) {
                        this.props.afterSaveTask(shift.feed_datetime, asset.asset_id);
                    }
                    if(this.props.updateForm){
                        this.uploadTaskModalInit(asset, this.state.site, shift, counterlog, one_task_in_progress, this.state?.taskData?.transaction_id)
                    }
                    this.setState({ taskSaveForm: { ...this.taskSaveFormInit } })
                   
                }).then(() => {
                    setTimeout(() => this.setState({saveFormSubmitting : false}), 1000);
                });
            } else {
                taskSaveFormData            =   {
                    ...taskSaveFormData, 
                    activity_id                 :   selected_activity_id,
                    counterlog_transaction_id: this.state.counterlog.transaction_id,
                    cl_date_time                :   this.state.taskSaveForm.cl_date_time ? moment(this.state.taskSaveForm.cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                    end_cl_date_time            :  this.state.taskSaveForm.end_cl_date_time ?  moment(this.state.taskSaveForm.end_cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                     task_close_at                :  this.state.taskSaveForm.task_close_at ? moment(this.state.taskSaveForm.task_close_at).format('YYYY-MM-DD HH:mm:ss') : '',
                     task_start_at               :   this.state.taskSaveForm.task_start_at ? moment(this.state.taskSaveForm.task_start_at).format('YYYY-MM-DD HH:mm:ss') : '',
                     start_cl_date_time          :  this.state.taskSaveForm.start_cl_date_time ?  moment(this.state.taskSaveForm.start_cl_date_time).format('YYYY-MM-DD HH:mm:ss') : '',
                };
                this.setState({saveFormSubmitting : true})
                
                HttpAPICall.withAthorization('POST', this.task_start_data_url, this.props.access_token, null, taskSaveFormData, (response) => {
                    
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.uploadTaskflowModal.hide();
                    if (this.props.afterSaveTask) {
                        this.props.afterSaveTask(shift.feed_datetime, asset.asset_id);
                    }
                    
                    this.setState({ taskSaveForm: { ...this.taskSaveFormInit } })
                   
                }).then(() => {
                    setTimeout(() => this.setState({saveFormSubmitting : false}), 1000);
                });
            }
         }
    }

    refreshScreen           =   ()      =>      {
        if(this.state.taskEdited && this.state.shift && this.state.asset){
            if (this.props.afterSaveTask) {
                this.props.afterSaveTask(this.state.shift.feed_datetime, this.state.asset.asset_id);
            }
        }
    }

    copyLatestToStartReadingHandler     =   ()  =>  {
        let {latest_counterlog}         =   {...this.state.form_data};
        this.setState({copy_latest_reading_start : !this.state.copy_latest_reading_start}, () => {
            if(this.state.copy_latest_reading_start) {
                let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.asset);
                let copyReading                =   {};
                assetMeters.forEach((meter, mk) => {
                    let input_state_key       =     AssetService.getTaskStartingReadingInputKey(meter.key);
                    // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]};

                    copyReading[input_state_key]  = latest_counterlog[meter.abs_key];
                });
                this.setState({
                    taskSaveForm        :   {...this.state.taskSaveForm, ...copyReading}
                });
            }
        });
    }

    copyLatestToCounterlogReadingHandler=   ()  =>  {
        let {latest_counterlog}         =   {...this.state.form_data};
        this.setState({copy_latest_reading : !this.state.copy_latest_reading}, () => {
            if(this.state.copy_latest_reading) {
                let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.asset);
                let copyReading                =   {};
                assetMeters.forEach((meter, mk) => {
                    let input_state_key       =     AssetService.getTaskCounterLogReadingInputKey(meter.key);
                    copyReading[input_state_key]  = latest_counterlog[meter.abs_key];
                });
                copyReading['cl_date_time']  = new Date(latest_counterlog.feed_datetime);
                this.setState({
                    taskSaveForm        :   {...this.state.taskSaveForm, ...copyReading}
                });
            }
        });
    }

    copyLatestToClosingReadingHandler=   ()  =>  {
        let {latest_counterlog}         =   {...this.state.form_data};
        this.setState({copy_latest_reading_end : !this.state.copy_latest_reading_end}, () => {
            if(this.state.copy_latest_reading_end) {
                let assetMeters                 =   AssetService.avaialbleAssetMeters(this.state.asset);
                let copyReading                =   {};
                assetMeters.forEach((meter, mk) => {
                    let input_state_key       =     AssetService.getTaskClosingReadingInputKey(meter.key);
                    copyReading[input_state_key]  = latest_counterlog[meter.abs_key];
                });
                copyReading['end_cl_date_time']  = new Date(latest_counterlog.feed_datetime);
                this.setState({
                    taskSaveForm        :   {...this.state.taskSaveForm, ...copyReading}
                });
            }
        });
    }

    initializeTaskJsx       =       ()      =>      {
        return (<div className="row ">

            <div className='col-sm-3'>
                <div className='fw-bold require col-form-label-sm'>Activity Name
                </div>
            </div>
            <div className='col-sm-7'>
                <TapSelect
                    options={this.state.activities}
                    changeEvent={(selectedOption) => {
                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'activity_id');
                        this.setState({
                            selected_activity_id : selectedOption ? selectedOption.value : '',
                            selected_activity   :   selectedOption ? selectedOption : null,
                        })
                    }}
                    isSearchable={true}
                    value={this.state.activities.find(s => this.state.taskSaveForm.activity_id == s.value)}
                    isClearable={true}
                    placeholder="Select Activity Name"
                    autoCompelete="off"
                    required={true}
                    containerHeight="30px"
                    fontSize="93%"
                    isDisabled={this.state.task_start_initialized == false ? false : true}
                />

            </div>

        </div>)
    }

    proceedTaskJsx      =   ()      =>      {
      
        return (<div>
            <TaskSavedStep
                completed_steps={this.state.completed_steps}
                taskData={this.state.taskData}
                asset={this.state.asset}
                editMode={this.state.editMode}
                onRequestClose={(action) => {
                    this.uploadTaskflowModal.hide();
                        if(action == 'delete' && this.props.afterDeleteTask ){
                            this.props.afterDeleteTask(this.state.shift.feed_datetime, this.state.asset.asset_id,true);
                        }else{
                            if (this.props.afterSaveTask) {
                                setTimeout(() => {this.props.afterSaveTask(this.state.shift.feed_datetime, this.state.asset.asset_id)},2000 )
                                
                            }
                        }
                       
                }}
                showCard={true}
                onModalRefresh={() => {
                    if(this.props.afterDeleteTask){
                        this.props.afterDeleteTask(this.state.shift.feed_datetime, this.state.asset.asset_id);
                    }else{
                        this.uploadTaskModalInit(this.state.asset, this.state.site, this.state.shift, this.state.counterlog, this.state.one_task_in_progress, this.state?.taskData?.transaction_id,this.props.isModal ? false : true);
                    }
                   setTimeout(() => {this.setState({taskEdited : true}) }, 2000)
                    
                  }
                }
                onEditTaskForm={(step, transaction_id) => { this.editTaskForm(step, transaction_id); this.initalizeEditForm(step) }}
            />
           {this.taskCurrentStepFormJsx()}
           {this.state.higherlevel_completed_steps && this.state.higherlevel_completed_steps.length > 0
           ?  <TaskSavedStep
                completed_steps={this.state.higherlevel_completed_steps}
                taskData={this.state.taskData}
                asset={this.state.asset}
                editMode={this.state.editMode}
                onRequestClose={(action) => {
                    this.uploadTaskflowModal.hide();
                    if(action == 'delete' && this.props.afterDeleteTask ){
                        this.props.afterDeleteTask(this.state.shift.feed_datetime, this.state.asset.asset_id,true);
                    }else{
                        if (this.props.afterSaveTask) {
                            setTimeout(() => {this.props.afterSaveTask(this.state.shift.feed_datetime, this.state.asset.asset_id)},2000 )
                            
                        }
                    }
                    
                }}
                showCard={false}
                onModalRefresh={() => {
                    if(this.props.afterDeleteTask){
                        this.props.afterDeleteTask(this.state.shift.feed_datetime, this.state.asset.asset_id);
                    }else{
                        this.uploadTaskModalInit(this.state.asset, this.state.site, this.state.shift, this.state.counterlog, this.state.one_task_in_progress, this.state?.taskData?.transaction_id,this.props.isModal ? false : true);
                    }
                    setTimeout(() => {this.setState({taskEdited : true}) }, 2000)
                }
                }
              
                onEditTaskForm={(step, transaction_id) => {this.setState({loading_form_data : true},() => {
                    this.editTaskForm(step, transaction_id); this.initalizeEditForm(step)
                }) }}
            /> : null}
        </div>)
    }


    taskCurrentStepFormJsx              = () => {
        let { selected_activity, form_fields,completed_steps } = { ...this.state };
        form_fields.sort((a,b) => a.order_no - b.order_no);
        let form_field_keys             =   form_fields && form_fields.length > 0 ?  form_fields.map(f => f.field_key) : [];
        
        return this.state.current_step && <div>
            {this.state.current_step.level == 1 &&
                <div className="row ">

                    <div className='col-sm-3'>
                        <div className='fw-bold require col-form-label-sm'>Activity Name
                        </div>
                    </div>
                    <div className='col-sm-7'>
                        <input
                            // name="selected_activity"
                            type="text"
                            value={selected_activity.label}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            disabled={true}
                            placeholder="Please enter Activity"
                        />

                    </div ></div>}
            <div className="tab_content_wrapper fw-bold my-2">  {this.state.current_step.name}</div>
            {form_fields.map((field, k) => {
                return <Ax key={k}>
                    {field.field_key == 'starting_readings' && this.startingReadingsJsx()}
                    {field.field_key == 'task_start_time' && !form_field_keys.includes('starting_readings') && this.taskStartingTimeJsx()}
                    {field.field_key == 'operator' && this.operatorJsx()}
                    {field.field_key == 'productivity' && this.productivityJsx()}
                    {field.field_key == 'attachment' && this.attachmentJsx()}
                    {field.field_key == 'material' && this.materialJsx()}
                    {field.field_key == 'workstation_from' && this.workstationFromJsx()}
                    {field.field_key == 'workstation_to' && this.workstationToJsx()}
                    {field.field_key == 'workstation' && this.workstationJsx()}
                    {field.field_key == 'hg_execution_by' && this.hgInfraExecutionJsx()}
                    {field.field_key == 'loaded_weight' && this.loadedWeightJsx()}
                    {field.field_key == 'unloaded_weight' && this.unloadedWeightJsx()}
                    {field.field_key == 'productivity_remark' && this.productivityRemarkJsx()}
                    {field.field_key == 'counterlog_readings' && this.counterlogReadingsJsx()}
                    {field.field_key == 'closing_remarks' && this.closingRemarkJsx()}
                    {field.field_key == 'activity_count' && this.activityCountJsx()}
                    {field.field_key == 'ncc_gate_in_time' && this.gateInTimeJsx()}
                    {field.field_key == 'ncc_qc_pass' && this.qualityCheckJsx()}
                    {field.field_key == 'ncc_qc_time' && this.qualityCheckTimeJsx()}
                    {field.field_key == 'ncc_pouring_location' && this.pouringLocationJsx()}
                    {field.field_key == 'ncc_pouring_qty' && this.pouringQtyJsx()}
                    {field.field_key == 'ncc_pouring_time' && this.pouringTimeJsx()}
                    {field.field_key == 'hg_handle_by_area_of_work' && this.handleByJsx()}
                    {field.field_key == 'closing_readings' && this.closingReadingsJsx()}
                    {field.field_key == 'task_complete_time' && !form_field_keys.includes('closing_readings') && this.taskCompleteTimeJsx()}
                    {field.is_additional_attribute == 'Y' && field.additional_attribute && this.additionalAttributeFields(field.additional_attribute)}
                </Ax>
            })}

           
        </div>
    }

    startingReadingsJsx             =       ()      =>  {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog,new_task_start_on_prev_task_close, prev_task_end_counterlog}         =   {...this.state.form_data};
       
        let max_time = new Date();
        let min_time = new Date();

        if (shift) {
            // Set max_time to 12:00 AM (midnight)
            if(shift && shift.shift_start == shift.shift_end){
                max_time.setHours(23, 59, 0, 0);
             }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
             }

            
            if (shift.shift_start) {
                // Set min_time based on the shift_start
                const [hours, minutes] = shift.shift_start
                .toUpperCase()
                .replace(".", "")
                .split(" ")[0]
                .split(":");
                min_time.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
            }
        }

       
        return(<div style={{border:"1px",borderStyle:"dashed",color:"#979C9F"}} className="my-2">
            <div className="row align-items-center my-2 p-2">
                <div className="col-sm-3" ><label className="form-label text-color">Starting Date & Time</label></div>
                <div className="col-md-7 ">
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.start_cl_date_time
                                ? moment(this.state.taskSaveForm.start_cl_date_time).toDate()
                                : false
                        }
                        name="start_cl_date_time"
                        onChange={(value, event) => { this.formDateHandler('start_cl_date_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Starting Date & Time`}
                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        minTime={min_time}
                        maxTime={max_time}
                    />
                </div>

            </div>
            {assetMeters && assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskStartingReadingInputKey(meter.key);
                return (<div className="row my-2 p-2 ">

                    <div className='col-sm-3'>
                        <div className='fw-bold col-form-label-sm text-color' >{meter.label}</div>
                    </div>
                    <div className='col-sm-7'>
                        <input
                            name={input_state_key}
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'taskSaveForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.taskSaveForm[input_state_key]}
                            disabled={new_task_start_on_prev_task_close == 'Y' && prev_task_end_counterlog !== null}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />
                    </div>
                    <div className='offset-3 col-sm-9'>
                        {latest_counterlog

                            ? <span className="text-sm form-text">
                               Latest Reading {latest_counterlog[meter.abs_key]} @ {latest_counterlog.feed_date_time_display}
                            </span>
                            : null}
                    </div>
                </div>)
            })}

            <div className='row mb-1'>
                <div className='offset-3 col-sm-9'>
                    <input
                        name="copy_latest_reading_start"
                        type="checkbox"
                        value={this.state.copy_latest_reading_start}
                        onChange={(e) =>  this.copyLatestToStartReadingHandler()}
                        checked={this.state.copy_latest_reading_start == true}
                        className="form-check-input"
                        disabled={new_task_start_on_prev_task_close == 'Y' && prev_task_end_counterlog !== null}
                        id="copy_latest_reading_start"
                    />
                    <label className="form-check-label mx-2 text-color" htmlFor="copy_latest_reading_start">Copy Reading from Latest Counterlog </label>
                </div>
            </div>
                            
        </div>)
    }

    taskStartingTimeJsx         =       ()      =>      {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog}         =   {...this.state.form_data};
       
        // let max_time = new Date();
        // let min_time = new Date();

        // if (shift) {
        //     // Set max_time to 12:00 AM (midnight)
        //     if(shift && shift.shift_start == shift.shift_end){
        //         max_time.setHours(23, 59, 0, 0);
        //      }else{
        //         max_time  = moment(shift.shift_end, "hh:mm a").toDate()
        //      }

            
        //     if (shift.shift_start) {
        //         // Set min_time based on the shift_start
        //         const [hours, minutes] = shift.shift_start
        //         .toUpperCase()
        //         .replace(".", "")
        //         .split(" ")[0]
        //         .split(":");
        //         min_time.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        //     }
        // }

        let max_time = moment(new Date(), "hh:mm a").toDate();
        let min_time = moment("00:05", "HH:mm").startOf('day').toDate();
       
        if (shift) {
            
            if (shift.shift_start === shift.shift_end) {
                // For 24-hour shift, remove min and max time filters
                min_time = null;
                max_time = null;
            } else {
                // For non-24-hour shift, adjust min_time and max_time for both start_time and end_time
                min_time = moment(shift.shift_start, "hh:mm a").toDate();
                max_time = moment(shift.shift_end, "hh:mm a").toDate();
                
            }
        }
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Task Start Time</div>
            </div>
            <div className='col-sm-7'>
                <DatePicker
                    selected={
                        this.state.taskSaveForm.task_start_at
                            ? moment(this.state.taskSaveForm.task_start_at).toDate()
                            : false
                    }
                    name="task_start_at"
                    onChange={(value, event) => { this.formDateHandler('task_start_at', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                    dateFormat="dd-MMM-yyyy hh:mm a"
                    className={"form-control form-control-sm"}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    timeCaption="Time"
                    autoComplete="off"
                    scrollMonthYearDropdown
                    required={true}
                    placeholderText={`Please Enter Starting Date & Time`}
                     minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                     maxDate={moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate()}
                     minTime={min_time}
                     maxTime={max_time}
                />

            </div>

        </div>)
    }

    
    operatorJsx         =       ()      =>      {
       
        return (<div className="row ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Operator
                </div>
            </div>
            <div className='col-sm-7'>
                <InputEmployeeSearch
                    placeholder="Please Select Employee"
                    changeEvent={(selectedOption) => {
                        this.setState({ 
                            searchedEmployee: selectedOption && selectedOption.length > 0 ? selectedOption : [] 
                        });
                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'operator_ids')
                    }}
                    transaction_date={this.state.taskData && this.state.taskData.task_date ? this.state.taskData.task_date : this.state.counterlog && this.state.counterlog.feed_datetime ? this.state.counterlog.feed_datetime : null }
                    menuPlacement="top"
                    search_site_ids={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                    containerHeight="30px"
                    fontSize="93%"
                    isMulti={true}
                    value={this.state.searchedEmployee && this.state.searchedEmployee.length > 0 ? this.state.searchedEmployee.map(a => { a.label = a.label; return a; }) : []}
                />

            </div>

        </div>)
    }

    productivityJsx     =       ()      =>      {
        let {production_additional_attributes, apply_prodution_formula, production_formula,runtime_productivity_unit_creation} =   {...this.state.form_data};
        let production_units            =       this.state.form_data && this.state.form_data.production_units && this.state.form_data.production_units.length > 0 ? this.state.form_data.production_units.map(at => {return({value:at.measuring_unit,label:at.measuring_unit})})  : []
        let formulaApplicable       =   apply_prodution_formula == 'Y' ? 'Y' : 'N';
        let linkedFormula           =   production_formula && JSON.stringify(production_formula) !== JSON.stringify({}) 
                                            ? production_formula : null;

        let taskFormPaaState        =   this.state.taskSaveForm.production_additional_attributes;

        let linkedFormulaHavingMeter =  linkedFormula && linkedFormula.formula && (linkedFormula.formula.includes('running_hrm') || linkedFormula.formula.includes('running_km') || linkedFormula.formula.includes('running_opc')) ? true : false;

        let formula                 =   linkedFormula && linkedFormula.formula ? linkedFormula.formula : null;

        if(linkedFormula && linkedFormula.unit_name && (!this.state.taskSaveForm.production_unit  || linkedFormula.unit_name != this.state.taskSaveForm.production_unit)) {
            // this.formInputHandler(linkedFormula.unit_name, 'production_unit', 'taskSaveForm');
            this.setState({taskSaveForm :{...this.state.taskSaveForm,production_unit : linkedFormula.unit_name }})
            // setTimeout(() => this.formInputHandler(linkedFormula.unit_name, 'production_unit', 'taskSaveForm') ,500);
        }

        production_additional_attributes && production_additional_attributes.forEach(production => {
            if (formula && formula.includes(production.formula_key)) {
                formula = formula.replace(production.formula_key, production.name)
            }
        });
        
        return (<div>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Net Productivity</div>
                </div>
                <div className='col-sm-7'>
                    <input
                        name="production"
                        type="number"
                        autoFocus={true}
                        value={this.state.taskSaveForm.production}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Net Productivity"
                        disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                    />
                </div>
                {linkedFormulaHavingMeter == true && formulaApplicable == 'Y' ?
                    <div className='text-danger text-sm offset-3 col-sm-7'>The formula contain total running (hours/km/opc) hence it cannot be calculated on this screen</div>
                    : null}
                <div className='text-sm offset-3 col-sm-7 form-text '>{linkedFormula && linkedFormula.formula ? <span>Net Productivity = {formula ? formula : "-"}</span> : ""}</div>

            </div>
            <div className="row my-2">
                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Production Units</div>
                </div>
                <div className='col-sm-7'>
                    {this.state.addNewUnit == "Y"
                        ? <input
                            name="production_unit"
                            type="text"
                            value={this.state.taskSaveForm.production_unit}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                            placeholder="Please enter Productivity Unit"
                            disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                        />
                        :
                        <TapSelect
                            options={production_units}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'taskSaveForm', 'production_unit');
                            }}
                            isSearchable={true}
                            value={production_units.find(s => this.state.taskSaveForm.production_unit == s.value)}
                            isClearable={true}
                            placeholder="Select Productivity Unit"
                            autoCompelete="off"
                            containerHeight="30px"
                            fontSize="93%"
                            menuPlacement="top"
                            isDisabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                        />
                    }
                </div>
              {runtime_productivity_unit_creation == 'Y' &&  <div className="col-sm-2">
                    <input
                        name="addNewUnit"
                        type="checkbox"
                        value={this.state.addNewUnit}
                        onChange={(e) => {
                            this.setState({
                                addNewUnit: this.state.addNewUnit == 'N' ? 'Y' : 'N',
                                taskSaveForm: { ...this.state.taskSaveForm, unit: '' }
                            })
                        }}
                        checked={this.state.addNewUnit == 'Y'}
                        className="form-check-input"
                        id="addNewUnit"
                        disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                    //disabled={this.state.linkedProductivityFormula ? true : false}

                    />
                    <label className="form-check-label text-sm mx-2" htmlFor="addNewUnit">Add New </label>
                </div>}

            </div>
            {
                production_additional_attributes && production_additional_attributes.length > 0
                    ? (
                        <Ax>

                            {
                                production_additional_attributes.map((i, k) => {
                                  
                                    let name = i.name;
                                    let input = null;
                                    if (i.type == 'text') {
                                        input = <input
                                            name={name}
                                            type="text"
                                            id={i.formula_key}
                                            value={this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[i.key] ? this.state.taskSaveForm.production_additional_attributes[i.key] : '' }
                                            onChange={(e) => {
                                                this.setState({
                                                    taskSaveForm : {...this.state.taskSaveForm,production_additional_attributes : {...this.state.taskSaveForm.production_additional_attributes,[i.key] : e.target.value}}
                                                })
                                                
                                            }}
                                            
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder={`Please enter ${i.name}`}
                                        />
                                    } else if (i.type == 'numeric') {
                                        input = <input
                                            name={name}
                                            type="number"
                                            id={i.formula_key}
                                            value={this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[i.key] ? this.state.taskSaveForm.production_additional_attributes[i.key] : '' }
                                            onChange={(e) => {
                                                this.setState({
                                                    taskSaveForm : {...this.state.taskSaveForm,production_additional_attributes : {...this.state.taskSaveForm.production_additional_attributes,[i.key] : e.target.value}}
                                                },() => this.calulateProductivityFormula(linkedFormula) )
                                                
                                            }}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder={`Please enter ${i.name}`}
                                            pattern="[0-9.]+"
                                        />
                                    } else if (i.type == 'date') {
                                        input = <DatePicker
                                          selected={
                                            this.state.taskSaveForm.production_additional_attributes && this.state.taskSaveForm.production_additional_attributes[i.key]
                                                    ? moment(this.state.taskSaveForm.production_additional_attributes[i.key]).toDate()
                                                    : false
                                            }
                                            name={name}
                                            onChange={(value, event) => this.dynamicFormDateHandler(moment(value).format('YYYY-MM-DD'), i.key, 'production_additional_attributes',  'taskSaveForm')}
                                            dateFormat="dd-MMM-yyyy"
                                            className={"form-control form-control-sm"}
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete='off'
                                            scrollMonthYearDropdown
                                            placeholderText={`Please Enter ${i.name}`}

                                        />
                                    } else if (i.type == 'dropdown') {
                                        let options = i.options.map((t) => { return { value: t, label: t } });
                                        let data = this.state.taskSaveForm?.production_additional_attributes
                                        input = <TapSelect
                                            menuPlacement="top"
                                            changeEvent={(selectedOption) => this.dynamicInputHandlerByKeyVal(selectedOption.value, i.key, 'production_additional_attributes',  'taskSaveForm')}
                                            options={options}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={options.find(o => o.value ==  (data && data[i.key] ? data[i.key] : '') )}
                                            placeholder={`Please Select ${i.name}`}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    }

                                    return (<div key={k} className="row align-items-center my-2">
                                        <div className="col-sm-3 col-form-label-sm col-form-label fw-bold">{i.name}</div>
                                        <div className="col-sm-7">{input}</div>

                                    </div>);
                                })
                            }
                        </Ax>
                    )
                    : null
            }
        </div>)
    }

    attachmentJsx            =       ()      =>      {
        let {attachments}                   =   {...this.state.form_data};
        let attachemntList = attachments && attachments.length > 0 ? attachments.map(at => {return({value:at.id,label:at.name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Attachment</div>
                </div>
                <div className='col-sm-7'>
                    {this.state.addNewAttachment == "Y"
                        ? <input
                            name="attachment_name"
                            type="text"
                            value={this.state.taskSaveForm.attachment_name}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                            placeholder="Please enter New Attachment"
                        />
                        :
                        <TapSelect
                            options={attachemntList}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'taskSaveForm', 'attachment_id');
                            }}
                            isSearchable={true}
                            value={attachemntList.find(s => this.state.taskSaveForm.attachment_id == s.value)}
                            isClearable={true}
                            placeholder="Select Attchment"
                            autoCompelete="off"
                            menuPlacement={'top'}
                            containerHeight="30px"
                            fontSize="93%"
                        />
                    }
                </div>
                <div className="col-sm-2">
                    <input
                        name="addNewAttachment"
                        type="checkbox"
                        value={this.state.addNewAttachment}
                        onChange={(e) => {
                            this.setState({
                                addNewAttachment: this.state.addNewAttachment == 'N' ? 'Y' : 'N',
                                taskSaveForm: { ...this.state.taskSaveForm, attachment_id: '', attachment_name: '' },
                            })
                        }}
                        checked={this.state.addNewAttachment == 'Y'}
                        className="form-check-input"
                        id="addNewAttachment"

                    />
                    <label className="form-check-label text-sm mx-2" htmlFor="addNewAttachment">Add New </label>
                </div>


            </div>
           
        </Ax>)
    }

    materialJsx            =       (formData=null)      =>      {
        let {materials,runtime_material_creation}                     =   {...this.state.form_data};
        let materialList = materials && materials.length > 0 ? materials.map(at => {return({value:at.id,label:at.material_name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Material</div>
                </div>
                <div className='col-sm-7'>
                    {this.state.addNewMaterial == "Y"
                        ? <input
                            name="material_name"
                            type="text"
                            value={this.state.taskSaveForm.material_name}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                            placeholder="Please enter New Material"
                        />
                        :
                        <TapSelect
                            options={materialList}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'taskSaveForm', 'material_id');
                            }}
                            isSearchable={true}
                            value={materialList.find(s => this.state.taskSaveForm.material_id == s.value)}
                            isClearable={true}
                            placeholder="Select Material"
                            autoCompelete="off"
                            menuPlacement={'top'}
                            containerHeight="30px"
                            fontSize="93%"
                        />
                    }
                </div>
               {runtime_material_creation && runtime_material_creation == 'Y' && <div className="col-sm-2">
                    <input
                        name="addNewMaterial"
                        type="checkbox"
                        value={this.state.addNewMaterial}
                        onChange={(e) => {
                            this.setState({
                                addNewMaterial: this.state.addNewMaterial == 'N' ? 'Y' : 'N',
                                taskSaveForm: { ...this.state.taskSaveForm, material_id: '', material_name: '' },
                            })
                        }}
                        checked={this.state.addNewMaterial == 'Y'}
                        className="form-check-input"
                        id="addNewMaterial"

                    />
                    <label className="form-check-label text-sm mx-2" htmlFor="addNewMaterial">Add New </label>
                </div>}

            </div>

        </Ax>)
    }

    workstationFromJsx         =       ()      =>      {
        return (<div className="row my-1">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation From</div>
            </div>
            <div className='col-sm-7'>
                {this.state.addNewWorkstationFrom == "Y"
                    ? <input
                        name="workstation_from_name"
                        type="text"
                        value={this.state.taskSaveForm.workstation_from_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Workstation From"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstationFrom: selectedOption ? selectedOption : null,
                                taskSaveForm: { ...this.state.taskSaveForm, workstation_from: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstationFrom ? this.state.searchedWorkstationFrom : null}
                    />
                }
            </div>
            {this.props.group_permission.permissions.includes('iam-workstation-add') && <div className="col-sm-2">
                <input
                    name="addNewWorkstationFrom"
                    type="checkbox"
                    value={this.state.addNewWorkstationFrom}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstationFrom: this.state.addNewWorkstationFrom == 'N' ? 'Y' : 'N',
                            taskSaveForm: { ...this.state.taskSaveForm, workstation_from_name: '', workstation_from: '' },
                            searchedWorkstationFrom: null
                        })
                    }}
                    checked={this.state.addNewWorkstationFrom == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstationFrom"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationFrom">Add New </label>
            </div>}


        </div>)
    }

    workstationToJsx         =       ()      =>      {
        return (<div className="row my-1 ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation To</div>
            </div>

            <div className='col-sm-7'>
                {this.state.addNewWorkstationTo == "Y"
                    ? <input
                        name="workstation_to_name"
                        type="text"
                        value={this.state.taskSaveForm.workstation_to_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Workstation to"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstationTo: selectedOption ? selectedOption : null,
                                taskSaveForm: { ...this.state.taskSaveForm, workstation_to: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstationTo ? this.state.searchedWorkstationTo : null}
                    />
                }
            </div>
            {this.props.group_permission.permissions.includes('iam-workstation-add') && <div className="col-sm-2">
                <input
                    name="addNewWorkstationTo"
                    type="checkbox"
                    value={this.state.addNewWorkstationTo}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstationTo: this.state.addNewWorkstationTo == 'N' ? 'Y' : 'N',
                            taskSaveForm: { ...this.state.taskSaveForm, workstation_to_name: '', workstation_to: '' },
                            searchedWorkstationTo: null
                        })
                    }}
                    checked={this.state.addNewWorkstationTo == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstationTo"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationTo">Add New </label>
            </div>}

        </div>)
    }

    workstationJsx         =       ()      =>      {
        return (<div className="row my-1 ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation</div>
            </div>
            <div className='col-sm-7'>
                {this.state.addNewWorkstation == "Y"
                    ? <input
                        name="workstation_name"
                        type="text"
                        value={this.state.taskSaveForm.workstation_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Workstation"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstation: selectedOption ? selectedOption : null,
                                taskSaveForm: { ...this.state.taskSaveForm, workstation_id: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                    />
                }
            </div>
           {this.props.group_permission.permissions.includes('iam-workstation-add') && <div className="col-sm-2">
                <input
                    name="addNewWorkstation"
                    type="checkbox"
                    value={this.state.addNewWorkstation}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstation: this.state.addNewWorkstation == 'N' ? 'Y' : 'N',
                            taskSaveForm: { ...this.state.taskSaveForm, workstation_name: '', workstation_id: '' },
                            searchedWorkstation: null
                        })
                    }}
                    checked={this.state.addNewWorkstation == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstation"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstation">Add New </label>
            </div>}


        </div>)
    }

    pouringLocationJsx         =       ()      =>      {
        return (<div className="row my-1">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Pouring Location</div>
            </div>
            <div className='col-sm-7'>
                {this.state.addNewPouringLocation == "Y"
                    ? <input
                        name="ncc_pouring_location_name"
                        type="text"
                        value={this.state.taskSaveForm.ncc_pouring_location_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Pouring Location"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Pouring Location"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedPouringLocation: selectedOption ? selectedOption : null,
                                taskSaveForm: { ...this.state.taskSaveForm, ncc_pouring_location: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedPouringLocation ? this.state.searchedPouringLocation : null}
                    />
                }
            </div>
            {this.props.group_permission.permissions.includes('iam-workstation-add') && <div className="col-sm-2">
                <input
                    name="addNewPouringLocation"
                    type="checkbox"
                    value={this.state.addNewPouringLocation}
                    onChange={(e) => {
                        this.setState({
                            addNewPouringLocation: this.state.addNewPouringLocation == 'N' ? 'Y' : 'N',
                            taskSaveForm: { ...this.state.taskSaveForm, ncc_pouring_location: '',ncc_pouring_location_name : '' },
                            searchedPouringLocation: null
                        })
                    }}
                    checked={this.state.addNewPouringLocation == 'Y'}
                    className="form-check-input"
                    id="addNewPouringLocation"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewPouringLocation">Add New </label>
            </div>}


        </div>)
    }

    pouringTimeJsx         =       ()      =>      {
        let {shift,taskData,completed_steps}        = {...this.state}
        let max_time = moment(new Date(), "hh:mm a").toDate();
        let min_time = moment("00:05", "HH:mm").startOf('day').toDate();


        let targetStep = this.findStepWithKey(completed_steps, 'ncc_gate_in_time');
        let targetStepDetail = targetStep && targetStep.detail  ? targetStep.detail : null;

        let qualityStep = this.findStepWithKey(completed_steps, 'ncc_qc_time');
        let qualityStepDetail = qualityStep && qualityStep.detail  ? qualityStep.detail : null;

        if (qualityStepDetail && qualityStepDetail.ncc_qc_time) {
            min_time = moment(qualityStepDetail.ncc_qc_time, "YYYY-MM-DD HH:mm:ss").toDate();
            max_time = shift ? moment(shift.shift_end, "hh:mm a").toDate() : null;
        }else if (targetStepDetail && targetStepDetail.ncc_gate_in_time) {
            min_time = moment(targetStepDetail.ncc_gate_in_time, "YYYY-MM-DD HH:mm:ss").toDate();
            max_time = shift ? moment(shift.shift_end, "hh:mm a").toDate() : null;
        }else if (taskData && taskData.task_start_at) {
            min_time = moment(taskData.task_start_at, "YYYY-MM-DD HH:mm:ss").toDate();
            max_time = shift ? moment(shift.shift_end, "hh:mm a").toDate() : null;
        } else if (shift) {

            if (shift.shift_start === shift.shift_end) {
                // For 24-hour shift, remove min and max time filters
                min_time = null;
                max_time = null;
            } else {
                // For non-24-hour shift, adjust min_time and max_time for both start_time and end_time
                min_time = moment(shift.shift_start, "hh:mm a").toDate();
                max_time = moment(shift.shift_end, "hh:mm a").toDate();

            }
        }
      
        return (<Ax>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Pouring Start Date & Time</div>
                </div>
                <div className='col-sm-7'>
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.ncc_pouring_start_time
                                ? moment(this.state.taskSaveForm.ncc_pouring_start_time).toDate()
                                : false
                        }
                        name="ncc_pouring_start_time"
                        onChange={(value, event) => { this.formDateHandler('ncc_pouring_start_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Pouring Start Date & Time`}
                        minDate={taskData && taskData.task_start_at ? moment(new Date(taskData.task_start_at), 'YYYY-MM-DD').toDate() : this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate()}
                        minTime={min_time}
                        maxTime={max_time}
                   
                    />

                </div>

            </div>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Pouring End Date & Time</div>
                </div>
                <div className='col-sm-7'>
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.ncc_pouring_end_time
                                ? moment(this.state.taskSaveForm.ncc_pouring_end_time).toDate()
                                : false
                        }
                        name="ncc_pouring_end_time"
                        onChange={(value, event) => { this.formDateHandler('ncc_pouring_end_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Pouring End Date & Time`}
                        minDate={taskData && taskData.task_start_at ? moment(new Date(taskData.task_start_at), 'YYYY-MM-DD').toDate() : this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate()}
                        minTime={this.state.taskSaveForm.ncc_pouring_start_time ? this.state.taskSaveForm.ncc_pouring_start_time : min_time}
                        maxTime={max_time}

                    />

                </div>

            </div>
           
        </Ax>
        )
    }

    pouringQtyJsx         =       ()      =>      {
      
        return (<Ax>
            <div className="row my-2">
                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm '>Pouring Qty</div>
                </div>
                <div className='col-sm-7'>
                    <input
                        name="ncc_pouring_qty"
                        type="number"
                        value={this.state.taskSaveForm.ncc_pouring_qty}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter Pouring Qty"
                    />

                </div>

            </div>
        </Ax>
        )
    }

    qualityCheckJsx            =       (formData=null)      =>      {
        let {ncc_qc_pass}                     =   {...this.state.form_data};
       
        const ncc_pass = Object.entries(ncc_qc_pass).map(([key, value], index) => ({
            id: index + 1, 
            status: key    
        }));

        const formattedNccPass = ncc_pass.map(item => ({
            value: item.status,              
            label: ncc_qc_pass[item.status]  
        }));
        return (<Ax>
            <div className="row my-2 ">
                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Quality Check</div>
                </div>
                <div className='col-sm-7'>
                        <TapSelect
                            options={formattedNccPass}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'taskSaveForm', 'ncc_qc_pass');
                            }}
                            isSearchable={true}
                            value={formattedNccPass.find(s => this.state.taskSaveForm.ncc_qc_pass == s.value)}
                            isClearable={true}
                            placeholder="Select Quality Check"
                            autoCompelete="off"
                            menuPlacement={'top'}
                            containerHeight="30px"
                            fontSize="93%"
                        />
                    
                </div>
              
            </div>
        
        </Ax>)
    }

    findStepWithKey(steps, key) {
        for (const step of steps) {
          if (step.detail && step.detail.hasOwnProperty(key)) {
            return step;
          }
        }
        return null;
      }

    qualityCheckTimeJsx            =       ()      =>      {
        let {shift,taskData,completed_steps}                 =       {...this.state};
        let max_time = moment(new Date(), "hh:mm a").toDate();
        let min_time = moment("00:05", "HH:mm").startOf('day').toDate();


        let targetStep = this.findStepWithKey(completed_steps, 'ncc_gate_in_time');
        let targetStepDetail = targetStep && targetStep.detail  ? targetStep.detail : null;

        if (targetStepDetail && targetStepDetail.ncc_gate_in_time) {
            min_time = moment(targetStepDetail.ncc_gate_in_time, "YYYY-MM-DD HH:mm:ss").toDate();
            max_time = shift ? moment(shift.shift_end, "hh:mm a").toDate() : null;
        }else if (taskData && taskData.task_start_at) {
            min_time = moment(taskData.task_start_at, "YYYY-MM-DD HH:mm:ss").toDate();
            max_time = shift ? moment(shift.shift_end, "hh:mm a").toDate() : null;
        } else if (shift) {

            if (shift.shift_start === shift.shift_end) {
                // For 24-hour shift, remove min and max time filters
                min_time = null;
                max_time = null;
            } else {
                // For non-24-hour shift, adjust min_time and max_time for both start_time and end_time
                min_time = moment(shift.shift_start, "hh:mm a").toDate();
                max_time = moment(shift.shift_end, "hh:mm a").toDate();

            }
        }
        return (<Ax>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Quality Check Date & Time</div>
                </div>
                <div className='col-sm-7'>
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.ncc_qc_time
                                ? moment(this.state.taskSaveForm.ncc_qc_time).toDate()
                                : false
                        }
                        name="ncc_qc_time"
                        onChange={(value, event) => { this.formDateHandler('ncc_qc_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Quality Check Date & Time`}
                        minDate={taskData && taskData.task_start_at ? moment(new Date(taskData.task_start_at), 'YYYY-MM-DD').toDate() : this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate()}
                        minTime={min_time}
                        maxTime={max_time}
                    />

                </div>

            </div>

        </Ax>)
    }

    gateInTimeJsx         =       ()      =>      {
        let {shift,taskData}                 =       {...this.state};
        let max_time = moment(new Date(), "hh:mm a").toDate();
        let min_time = moment("00:05", "HH:mm").startOf('day').toDate();

        if (taskData && taskData.task_start_at) {
            min_time = moment(taskData.task_start_at, "YYYY-MM-DD HH:mm:ss").toDate();
            max_time = shift ? moment(shift.shift_end, "hh:mm a").toDate() : null;
        } else if (shift) {

            if (shift.shift_start === shift.shift_end) {
                // For 24-hour shift, remove min and max time filters
                min_time = null;
                max_time = null;
            } else {
                // For non-24-hour shift, adjust min_time and max_time for both start_time and end_time
                min_time = moment(shift.shift_start, "hh:mm a").toDate();
                max_time = moment(shift.shift_end, "hh:mm a").toDate();

            }
        }

        return (<Ax>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Gate-In Date & Time</div>
                </div>
                <div className='col-sm-7'>
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.ncc_gate_in_time
                                ? moment(this.state.taskSaveForm.ncc_gate_in_time).toDate()
                                : false
                        }
                        name="ncc_gate_in_time"
                        onChange={(value, event) => { this.formDateHandler('ncc_gate_in_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Gate-In Date & Time`}
                        minDate={taskData && taskData.task_start_at ? moment(new Date(taskData.task_start_at), 'YYYY-MM-DD').toDate() : this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate()}
                        minTime={min_time}
                        maxTime={max_time}
                    />

                </div>

            </div>
        </Ax>
        )
    }

    // handleByJsx            =       (formData=null)      =>      {
    //     let {ZOWNERSHIP,ownerships}                     =   {...this.state.form_data};
    //     let handleByOptions = [];
    //     let areaOfWorkOptions = [];

    //     handleByOptions = ownerships.map(ownership => {
    //         return { value: ownership.name, label: ownership.name };
    //     });

    //     let selectedOwnership = ownerships.find(ownership => ownership.name === ZOWNERSHIP);

    //    if (selectedOwnership) {
    //         areaOfWorkOptions = selectedOwnership.area_of_works.map(area => {
    //             return { value: area, label: area };
    //         });
    //     }

    //     if (!this.state.taskSaveForm.hg_handle_by) {
    //         this.setState(() => ({
    //             taskSaveForm: {
    //                 ...this.state.taskSaveForm,
    //                 hg_handle_by: ZOWNERSHIP
    //             }
    //         }));
    //     }

    //     return (<Ax>
    //         {ZOWNERSHIP === "STANDBY" && (
    //             <div className="row my-2">
    //                 <div className='col-sm-3'>
    //                     <div className='fw-bold col-form-label-sm'>Handle By</div>
    //                 </div>
    //                 <div className='col-sm-7'>
    //                     <TapSelect
    //                         options={handleByOptions}
    //                         changeEvent={(selectedOption) => {
    //                             this.tapSelectChange(selectedOption, 'taskSaveForm', 'hg_handle_by',() => {

    //                             });
    //                         }}
    //                         isSearchable={true}
    //                        // value={handleByOptions.find(s => s.value === (this.state.taskSaveForm.hg_handle_by || ZOWNERSHIP))}
    //                         value={handleByOptions.find(s => this.state.taskSaveForm.hg_handle_by === s.value)}
    //                         isClearable={true}
    //                         placeholder="Select Handle By"
    //                         autoComplete="off"
    //                         menuPlacement={'top'}
    //                         containerHeight="30px"
    //                         fontSize="93%"
    //                     />
    //                 </div>
    //             </div>
    //         )}

    //         <div className="row my-2">
    //             <div className='col-sm-3'>
    //                 <div className='fw-bold col-form-label-sm'>Area of Work</div>
    //             </div>
    //             <div className='col-sm-7'>
    //                 <TapSelect
    //                     options={areaOfWorkOptions}
    //                     changeEvent={(selectedOption) => {
    //                         this.tapSelectChange(selectedOption, 'taskSaveForm', 'hg_area_of_work');
    //                     }}
    //                     isSearchable={true}
    //                     value={areaOfWorkOptions.find(s => this.state.taskSaveForm.hg_area_of_work === s.value)}
    //                     isClearable={true}
    //                     placeholder="Select Area of Work"
    //                     autoComplete="off"
    //                     menuPlacement={'top'}
    //                     containerHeight="30px"
    //                     fontSize="93%"
    //                 />
    //             </div>
    //         </div>
    //     </Ax>)
    // }

    handleByJsx = (formData = null) => {
        let { ZOWNERSHIP, ownerships, handle_by_input, handle_by_val } = { ...this.state.form_data };
        let handleByOptions = [];
        let areaOfWorkOptions = [];
    
        // Prepare the list of ownership names for Handle By dropdown
        handleByOptions = handle_by_val.map(ownership => {
            return { value: ownership, label: ownership };
        });
    
        // If hg_handle_by is not set, default it to ZOWNERSHIP
        if (!this.state.taskSaveForm.hg_handle_by) {
            this.setState(() => ({
                taskSaveForm: {
                    ...this.state.taskSaveForm,
                    hg_handle_by: ZOWNERSHIP
                }
            }));
        }
    
        // Get the current selected handle by (from hg_handle_by or ZOWNERSHIP)
        let selectedHandleBy = this.state.taskSaveForm.hg_handle_by || ZOWNERSHIP;
        
        // Find ownership based on selected handle_by value
        let selectedOwnership = ownerships.find(ownership => ownership.name === selectedHandleBy);
    
        // If ownership is found, map its area_of_works to options for the Area of Work dropdown
        if (selectedOwnership) {
            areaOfWorkOptions = selectedOwnership.area_of_works.map(area => {
                return { value: area.key, label: area.value };
            });
        }
    
        return (
            <Ax>
                {/* Show "Handle By" dropdown if handle_by_input is 'Y' */}
                {handle_by_input == 'Y' && (
                    <div className="row my-2">
                        <div className='col-sm-3'>
                            <div className='fw-bold col-form-label-sm'>Handle By</div>
                        </div>
                        <div className='col-sm-7'>
                            <TapSelect
                                options={handleByOptions}
                                changeEvent={(selectedOption) => {
                                    const updateForm = (ownership) => {
                                        this.setState({
                                            taskSaveForm: {
                                                ...this.state.taskSaveForm,
                                                hg_area_of_work: null
                                            },
                                            areaOfWorkOptions: ownership 
                                                ? ownership.area_of_works.map(area => ({ value: area.key, label: area.value }))
                                                : []
                                        });
                                    };
                                
                                    if (selectedOption) {
                                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'hg_handle_by', () => {
                                            const selectedOwnership = ownerships.find(o => o.name === selectedOption.value);
                                            updateForm(selectedOwnership);
                                        });
                                    } else {
                                        this.tapSelectChange('', 'taskSaveForm', 'hg_handle_by', () => {
                                            const defaultOwnership = ownerships.find(o => o.name === '');
                                            updateForm(defaultOwnership);
                                        });
                                    }
                                }}
                                
                                isSearchable={true}
                                value={handleByOptions.find(s => s.value === this.state.taskSaveForm.hg_handle_by)} 
                                isClearable={true}
                                placeholder="Select Handle By"
                                autoComplete="off"
                                menuPlacement={'top'}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                )}
    
                {/* Area of Work dropdown based on selected ownership */}
                <div className="row my-2">
                    <div className='col-sm-3'>
                        <div className='fw-bold col-form-label-sm'>Area of Work</div>
                    </div>
                    <div className='col-sm-7'>
                        <TapSelect
                            options={areaOfWorkOptions} 
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'taskSaveForm', 'hg_area_of_work');
                            }}
                            isSearchable={true}
                            value={areaOfWorkOptions.find(s => s.value === this.state.taskSaveForm.hg_area_of_work)}
                            isClearable={true}
                            placeholder="Select Area of Work"
                            autoComplete="off"
                            menuPlacement={'top'}
                            containerHeight="30px"
                            fontSize="93%"
                        />
                    </div>
                </div>
            </Ax>
        );
    }
    

    loadedWeightJsx         =       ()      =>      {
 
        let {loaded_weight_units}           =   {...this.state.form_data};
        let unitList = loaded_weight_units && loaded_weight_units.length > 0 ? loaded_weight_units.map(at => {return({value:at,label:at})})  : []

        if (unitList && unitList.length > 0 && ((!this.state.taskSaveForm.loaded_weight_unit) && this.state.taskSaveForm.loaded_weight_unit !== '')) {
            this.setState({taskSaveForm : {...this.state.taskSaveForm,loaded_weight_unit : unitList[0].value}});
        }


        return (<div className="row my-1">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Loaded Weight</div>
            </div>

            <div className='col-sm-4'>
                <input
                    name="loaded_weight"
                    type="text"
                    value={this.state.taskSaveForm.loaded_weight}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Please enter Loaded Weight"
                />

            </div>
            <div className='col-sm-3'>
                <TapSelect
                    options={unitList}
                    changeEvent={(selectedOption) => {
                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'loaded_weight_unit');
                    }}
                    isSearchable={true}
                    value={unitList.find(s => this.state.taskSaveForm.loaded_weight_unit == s.value)}
                    isClearable={true}
                    placeholder="Unit"
                    autoCompelete="off"
                    menuPlacement={'top'}
                    containerHeight="30px"
                    fontSize="93%"
                />
            </div>

        </div>)
    }

    unloadedWeightJsx         =       ()      =>      {
        let {unloaded_weight_units}           =   {...this.state.form_data};
        let unitList = unloaded_weight_units && unloaded_weight_units.length > 0 ? unloaded_weight_units.map(at => {return({value:at,label:at})})  : []
        
        if (unitList && unitList.length > 0 && ((!this.state.taskSaveForm.unloaded_weight_unit) && this.state.taskSaveForm.unloaded_weight_unit !== '')) {
            this.setState({taskSaveForm : {...this.state.taskSaveForm,unloaded_weight_unit : unitList[0].value}});
        }

        return (<div className="row my-1">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Unloaded Weight</div>
            </div>

            <div className='col-sm-4'>
                <input
                    name="unloaded_weight"
                    type="text"
                    value={this.state.taskSaveForm.unloaded_weight}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Please enter Unloaded Weight"
                />

            </div>
            <div className='col-sm-3'>
                <TapSelect
                    options={unitList}
                    changeEvent={(selectedOption) => {
                        this.tapSelectChange(selectedOption, 'taskSaveForm', 'unloaded_weight_unit');
                    }}
                    isSearchable={true}
                    value={unitList.find(s => this.state.taskSaveForm.unloaded_weight_unit == s.value)}
                    isClearable={true}
                    placeholder="Unit"
                    autoCompelete="off"
                    menuPlacement={'top'}
                    containerHeight="30px"
                    fontSize="93%"
                />
            </div>

        </div>)
    }

    productivityRemarkJsx       =       ()      =>      {
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Remark</div>
            </div>
            <div className='col-sm-7'>
                <textarea
                    name="productivity_remark"
                    value={this.state.taskSaveForm.productivity_remark}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Remark"
                />

            </div>

        </div>)
    }

    counterlogReadingsJsx             =       ()      =>  {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog}         =   {...this.state.form_data};
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(shift){
            if(shift && shift.shift_start == shift.shift_end){
                max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
            }
        }
        return(<div style={{border:"1px",borderStyle:"dashed",color:"#979C9F"}} className="my-2">
          <div className="row align-items-center my-2 p-2">
                <div className="col-sm-3"><label className="form-label text-color">CounterLog Date & Time</label></div>
                <div className="col-md-7 ">
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.cl_date_time
                                ? moment(this.state.taskSaveForm.cl_date_time).toDate()
                                : false
                        }
                        name="cl_date_time"
                        onChange={(value, event) => { this.formDateHandler('cl_date_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Counterlog Date & Time`}
                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        minTime={moment(shift ? shift.shift_start : new Date(), "hh:mm a ").toDate()}
                        maxTime={max_time}
                    />
                </div>

            </div>
            {assetMeters && assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskCounterLogReadingInputKey(meter.key);
                return (<div className="row my-2 p-2 ">

                    <div className='col-sm-3'>
                        <div className='fw-bold col-form-label-sm text-color'>{meter.label}</div>
                    </div>
                    <div className='col-sm-7'>
                        <input
                            name={input_state_key}
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'taskSaveForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.taskSaveForm[input_state_key]}

                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />
                    </div>
                    
                </div>)
            })}
             <div className='row mb-1'>
                <div className='offset-3 col-sm-9'>
                    <input
                        name="copy_latest_reading"
                        type="checkbox"
                        value={this.state.copy_latest_reading}
                        onChange={(e) =>  this.copyLatestToCounterlogReadingHandler()}
                        checked={this.state.copy_latest_reading == true}
                        className="form-check-input"
                        id="copy_latest_reading"
                    />
                    <label className="form-check-label mx-2 text-color" htmlFor="copy_latest_reading">Copy Reading from Latest Counterlog </label>
                </div>
            </div>
        </div>)
    }

    closingReadingsJsx             =       ()      =>  {
        let {asset, site, shift, counterlog}        =   {...this.state};
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {latest_counterlog}         =   {...this.state.form_data};
        let max_time = new Date();
        let min_time = new Date();

        // if (shift) {
            
        //     if (shift.shift_start) {
        //         // Set min_time based on the shift_start
        //         const [hours, minutes] = shift.shift_start
        //         .toUpperCase()
        //         .replace(".", "")
        //         .split(" ")[0]
        //         .split(":");
        //         min_time.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        //     }
        // }
        // if(shift){
        //     if(shift && shift.shift_start == shift.shift_end){
        //         max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
        //     }else{
        //         max_time  = moment(shift.shift_end, "hh:mm a").toDate()
        //     }
        // }
        return(<div style={{border:"1px",borderStyle:"dashed",color:"#979C9F"}} className="my-2">
              <div className="row align-items-center my-2 p-2">
                <div className="col-sm-3"><label className="form-label text-color">Closing Date & Time</label></div>
                <div className="col-md-7 ">
                    <DatePicker
                        selected={
                            this.state.taskSaveForm.end_cl_date_time
                                ? moment(this.state.taskSaveForm.end_cl_date_time).toDate()
                                : false
                        }
                        name="end_cl_date_time"
                        onChange={(value, event) => { this.formDateHandler('end_cl_date_time', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Closing Date & Time`}
                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        // minTime={moment(shift.shift_start, "hh:mm A").toDate()} // Set the minimum time to shift.shift_start
                        // maxTime={moment().toDate()}
                    />
                </div>

            </div>
            {assetMeters && assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskClosingReadingInputKey(meter.key);
                return (<div className="row my-2 p-2">

                    <div className='col-sm-3'>
                        <div className='fw-bold col-form-label-sm text-color'>{meter.label}</div>
                    </div>
                    <div className='col-sm-7'>
                        <input
                            name={input_state_key}
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'taskSaveForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.taskSaveForm[input_state_key]}

                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />
                    </div>
                    
                </div>)
            })}
              <div className='row mb-1'>
                <div className='offset-3 col-sm-9'>
                    <input
                        name="copy_latest_reading_end"
                        type="checkbox"
                        value={this.state.copy_latest_reading_end}
                        onChange={(e) =>  this.copyLatestToClosingReadingHandler()}
                        checked={this.state.copy_latest_reading_end == true}
                        className="form-check-input"
                        id="copy_latest_reading_end"
                    />
                    <label className="form-check-label mx-2 text-color" htmlFor="copy_latest_reading_end">Copy Reading from Latest Counterlog </label>
                </div>
            </div>
           
        </div>)
    }

    closingRemarkJsx       =       ()      =>      {
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Closing Remark</div>
            </div>
            <div className='col-sm-7'>
                <textarea
                    name="closing_remarks"
                    value={this.state.taskSaveForm.closing_remarks}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                    placeholder="Remark"
                />

            </div>

        </div>)
    }

    activityCountJsx            =       ()      =>      {
        let {activity_type}             =   {...this.state.form_data};
        let activityType = activity_type && activity_type.length > 0 ? activity_type.map(at => {return({value:at.key,label:at.name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Activity Type</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={activityType}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'taskSaveForm', 'activity_type');
                        }}
                        isSearchable={true}
                        value={activityType.find(s => this.state.taskSaveForm.activity_type == s.value)}
                        isClearable={true}
                        placeholder="Select Activity"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm '>No. of Session or Trip</div>
                </div>
                <div className='col-sm-7'>
                    <input
                        name="activity_count"
                        type="number"
                        value={this.state.taskSaveForm.activity_count}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "taskSaveForm")}
                        placeholder="Please enter No. of Session/Trip"
                    />

                </div>

            </div>
        </Ax>)
    }

    hgInfraExecutionJsx         =       ()      =>      {
            let {execution_by}             =   {...this.state.form_data};
            let executionByList = execution_by && execution_by.length > 0 ? execution_by.map(at => {return({value:at.key,label:at.name})})  : []
        return (<Ax>
            <div className="row my-2 ">
                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm require'>Execution By</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={executionByList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'taskSaveForm', 'execution_by',() => {
                                this.setState({
                                    searchedHgVendor    :   null,
                                    taskSaveForm : {...this.state.taskSaveForm,searchedHgVendor : null,supplier_vendor_id : ''}
                                })
                                
                            });
                        }}
                        isSearchable={true}
                        value={executionByList.find(s => this.state.taskSaveForm.execution_by == s.value)}
                        isClearable={true}
                        placeholder="Select Execution By"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm require'>Supplier Vendor</div>
                </div>
                <div className='col-sm-7'>
                <InputSupplierVendorSearch
                    changeEvent={(selectedOption) => {
                        this.setState({
                            searchedHgVendor: selectedOption ? selectedOption : null,
                            taskSaveForm: { ...this.state.taskSaveForm, supplier_vendor_id: selectedOption ? selectedOption.value : '' }
                        })

                    }}
                    menuPlacement="top"
                    disabled={this.state.taskSaveForm && this.state.taskSaveForm.execution_by && this.state.taskSaveForm.execution_by == "INTERNAL" ? true : false}
                    // search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                    containerHeight="30px"
                    fontSize="93%"
                    value={this.state.searchedHgVendor ? this.state.searchedHgVendor : null}
                />

                </div>

            </div>
        </Ax>)
    }
    
    taskCompleteTimeJsx        =   ()      =>      {
        let {asset, site, shift, counterlog}        =   {...this.state};
    
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(shift){
            if(shift && shift.shift_start == shift.shift_end){
                max_time  = moment(shift.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(shift.shift_end, "hh:mm a").toDate()
            }
        }
        
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Task Close Time</div>
            </div>
            <div className='col-sm-7'>
                <DatePicker
                    selected={
                        this.state.taskSaveForm.task_close_at
                            ? moment(this.state.taskSaveForm.task_close_at).toDate()
                            : false
                    }
                    name="task_close_at"
                    onChange={(value, event) => { this.formDateHandler('task_close_at', value, 'taskSaveForm', 'YYYY-MM-DD HH:mm:ss') }}
                    dateFormat="dd-MMM-yyyy hh:mm a"
                    className={"form-control form-control-sm"}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    timeCaption="Time"
                    autoComplete="off"
                    scrollMonthYearDropdown
                    required={true}
                    placeholderText={`Please Enter Closing Date & Time`}
                    minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                    // minTime={moment(shift ? shift.shift_start : new Date(), "hh:mm a ").toDate()}
                    // maxTime={new Date().setHours(0, 0, 0, 0)}
                    maxDate={new Date()}
                />

            </div>

        </div>)
    }

    additionalAttributeFields               =   (additional_attribute_detail)  =>  {
        let options = [];
        let value = '';

        if (additional_attribute_detail && additional_attribute_detail.type == "dropdown") {
            options = additional_attribute_detail.options.map((t) => { return { value: t, label: t } });

            if (this.state.taskSaveForm && this.state.taskSaveForm.additional_attributes && this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key]) {
                value = this.state.taskSaveForm.additional_attributes[additional_attribute_detail.key];
            }
        }
       
        return <Ax>
            {additional_attribute_detail && <AdditionalAttributeForm
                additionalAttributes={[additional_attribute_detail]}
                formState={this.state.taskSaveForm}
                onAttributeChange={(value, attributeName) => {
                    this.setState(() => ({
                        taskSaveForm: {
                            ...this.state.taskSaveForm,
                           // additional_attributes : {
                                [attributeName]: value
                            //}
                           
                        }
                    }))
                }
                }
                formSmall={true}
                colSpan={"col-sm-7"}
            />}
           

        </Ax>
    }
        
    
    render                              =   ()  =>  {
        const { refreshScreen, } = this.props;
        const {isModal,asset, counterlog, shift, taskData,loading_form_data, task_start_initialized, saveFormSubmitting, current_step, nextStepDetail, copy_reading_counterlog } = this.state;
       
        if (isModal) {
            return (
                <div className="modal fade" id={this.uploadTaskflowModalId} tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="fs-6 m-0">
                                    <span>Log Task for : </span>
                                    {counterlog ? counterlog.feed_date_display : null}
                                    {shift ? <span className="ps-2">{shift.shift_name} ({shift.shift_start})</span> : null}
                                </h5>
                            </div>
                            <div className='p-1 bg-light border-bottom'>
                                <table className='table table-sm mb-0 table-borderless text-sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "26%" }}>Asset Name (Code)</td>
                                            <th>{asset ? `${asset.name} (${asset.asset_code})` : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td>Asset Type </td>
                                            <th>{asset ? `${asset.asset_type_name} ` : "-"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-body">
                                {loading_form_data ? <div><Loader /></div>
                                    : <form className='p-2' onSubmit={this.submitTaskflowForm} id={this.updateTaskflowFormId}>
                                        {task_start_initialized ? this.proceedTaskJsx() : this.initializeTaskJsx()}
                                    </form>}
                            </div>
                            <div className="modal-footer d-flex justify-content-between row">
                                <div className="col text-start">
                                    {/* Uncomment if needed */}
                                    {/* {includeStartingReadings == true ? <Ax> */}
                                    {/* <input */}
                                    {/*     name="copy_reading_counterlog" */}
                                    {/*     type="checkbox" */}
                                    {/*     value={copy_reading_counterlog} */}
                                    {/*     onChange={(e) => { */}
                                    {/*         this.setState({ copy_reading_counterlog: copy_reading_counterlog === 'N' ? 'Y' : 'N' }, () => this.copyPreviousHandler()) */}
                                    {/*     }} */}
                                    {/*     checked={copy_reading_counterlog === 'Y'} */}
                                    {/*     className="form-check-input" */}
                                    {/*     id="copy_reading_counterlog" */}
                                    {/* /> */}
                                    {/* <label className="form-check-label mx-2" htmlFor="copy_reading_counterlog">Copy Reading from Latest Counterlog </label> */}
                                    {/* </Ax> : null} */}
                                </div>
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={saveFormSubmitting} data-bs-dismiss="modal" onClick={this.refreshScreen}>Cancel</button>
                                    {current_step && task_start_initialized ?
                                        <button type="submit" className="btn btn-primary" disabled={saveFormSubmitting || nextStepDetail && nextStepDetail.can_submit && nextStepDetail.can_submit === 'N'} form={this.updateTaskflowFormId}>Update {saveFormSubmitting ? <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" /> : ''}</button>
                                        : null}
                                    {!current_step ?
                                        <button type="button" className="btn btn-primary"
                                         disabled={saveFormSubmitting || nextStepDetail && nextStepDetail.can_submit && nextStepDetail.can_submit === 'N'} 
                                         form={this.updateTaskflowFormId} onClick={this.taskProceedToStartHandler}>Proceed {saveFormSubmitting ? <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" /> : ''}</button>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        // Render plain JSX
        return (
            <div>
               
                <div className="p-2">
                    {loading_form_data ? <div><Loader /></div>
                        : <form onSubmit={this.submitTaskflowForm} id={this.updateTaskflowFormId}>
                            {task_start_initialized ? this.proceedTaskJsx() : this.initializeTaskJsx()}
                           {taskData && taskData.task_in_progress == 'Y'  && <div className="d-flex justify-content-between row">
                                <div className="col text-start">
                                  
                                </div>
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={saveFormSubmitting} onClick={refreshScreen}>Cancel</button>
                                    {current_step && task_start_initialized ?
                                        <button type="submit" className="btn btn-primary" disabled={saveFormSubmitting || nextStepDetail && nextStepDetail.can_submit && nextStepDetail.can_submit === 'N'} form={this.updateTaskflowFormId}>Update {saveFormSubmitting ? <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" /> : ''}</button>
                                        : null}
                                    {!current_step ?
                                        <button type="button" className="btn btn-primary" disabled={saveFormSubmitting || nextStepDetail && nextStepDetail.can_submit && nextStepDetail.can_submit === 'N'} form={this.updateTaskflowFormId} onClick={this.taskProceedToStartHandler}>Proceed {saveFormSubmitting ? <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" /> : ''}</button>
                                        : null}
                                </div>
                            </div>}
                        </form>}
                </div>
               
            </div>
        );
       
}
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        group_permission        :   state.app.acl_info
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TaskSaveModal);