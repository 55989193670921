import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import "../MasterData.css";
import EmployeeTypeDesignation from "./employeeTypeDesignation/EmployeeTypeDesignation";

class ConfigurationHrm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configScreen: "hrm",
      add: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.itemWhBulkRef = React.createRef();
  }

  handleAdd = () => {
    this.setState({ add: true });
  };

  handleClose = () => {
    this.setState({ add: false });
  };

  itemWhBulkUploadModal = (type) => {
    this.itemWhBulkRef.current.itemWhBulkUploadInit(type);
  };

  pageTitleBarJsx = (configTitle) => {
    let title = "";
    if (configTitle === "emp_type") {
      title = "Eployee Type";
    }

    return (
      <Ax>
        <div className="page_title row m0">
          <div className="col-3">
            <h3>Configuration</h3>
          </div>
          <div className={`col-9 tap_border_left`}>
            <h3>{title}</h3>
          </div>
        </div>
      </Ax>
    );
  };

  loadConfigContentJsx = (configName) => {
    switch (configName) {
      case "employee_type":
        return <EmployeeTypeDesignation />;
      default:
        return <EmployeeTypeDesignation />;
    }
  };

  //function to get configure table
  configureListTable = (configRow) => {
    return (
      <div style={{ height: "81vh" }} className="bg-white">
        <table className="table table-hover table-bordered bg-white">
          <tbody className="table-body">
            <tr className="white">
              <td scope="row">
                <Link
                  to={`/hrm_configuration/employee_type`}
                  role={"button"}
                  className="link"
                >
                  Employee Configuration
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>HRM Configuration</title>
        </Helmet>
        {this.pageTitleBarJsx(this.props.match.params.config)}
        <div className="container-fluid pl5">
          <div className="page_containt row">
            <div className={["pageTbl col-3"].join(" ")}>
              {this.configureListTable(this.props.match.params.config)}
            </div>
            <div className="col-9 pl0 pr4">
              {this.loadConfigContentJsx(this.props.match.params.config)}
            </div>
          </div>
        </div>
      </ApplicationLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps)(ConfigurationHrm);
