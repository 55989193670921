import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import Ax from "../../../../../components/hoc/Ax";
import { Helmet } from 'react-helmet';
import TapSelect from '../../../../../components/ui/TapSelect';
import TabNavigationAssetMatrix from '../TabnavigationAssetMatrix';
import HttpAPICall from '../../../../../services/HttpAPICall';
import Loader from '../../../../../components/ui/Loader/Loader';
import { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import { Modal } from "bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TapIcon from '../../../../../services/TapIcon';
import tapIcon from '../../../../../services/TapIcon';
import swal from "sweetalert";
import { useParams } from "react-router-dom";

const Compliance = ({ access_token }) => {

    const [other, setOther]                     =   useState('N');
    const [complianceData, setComplianceData]   =   useState([]);
    const [formDataOptions, setFormDataOptions] =   useState([]);
    const [loading, setLoading]                 =   useState(false);
    const [submitForm, setSubmitForm]           =   useState(false);
    const addComplianceModalRef                 =   useRef(null);

    const {id : matrixId}                       =   useParams();

    let formData ={ compliance_name: '', compliance_type_id: ''}
    const [addAssetComplianceForm, setAddAssetComplianceForm] = useState(formData);

    useEffect(() => {
        fetchComplianceData();
        fetchFormData();  
        addComplianceModalRef.current = new Modal(document.getElementById('exampleModal'), { keyboard: false, backdrop: true });
    }, [access_token]);
    // Fetch data
    const fetchComplianceData = () => {
        setLoading(true);
        const complianceUrl = `${IAM_API_BASE_URL_2}/matrix_compliance/list/${matrixId}`;
        HttpAPICall.withAthorization('GET', complianceUrl, access_token, {}, {}, (response) => {
            setComplianceData(response.data.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error("Failed to fetch compliance data.");
        });
    };
    // Fetch formdata
    const fetchFormData = () => {
        setLoading(true);
        const formDataUrl = `${IAM_API_BASE_URL_2}/matrix_compliance/form_data`; // Adjust URL as needed

        HttpAPICall.withAthorization('GET', formDataUrl, access_token, {}, {}, (response) => {
            const options = response.data?.compliances.map((opt) => ({value: opt.id, label: opt.name}));
            setFormDataOptions(options);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            toast.error("Failed to fetch form data.");
        });
    };
    //  Add data
    const addAssetCompliance = (e) => {
        e.preventDefault();
        setSubmitForm(true);
        const complianceUrl = `${IAM_API_BASE_URL_2}/matrix_compliance/add`;
        addAssetComplianceForm.asset_matrix_id = matrixId;
        HttpAPICall.withAthorization('POST', complianceUrl, access_token, {}, addAssetComplianceForm, (response) => {
            setAddAssetComplianceForm({ compliance_name: '', compliance_type_id: ''});
            fetchComplianceData()
            setOther('N');
            addComplianceModalRef.current.hide();
            toast.success(response.data.msg);
        })
        .then(res => setSubmitForm(pre => pre ? false : true))
        .catch((error) => {
            setSubmitForm(pre => pre ? false : true);
            toast.error("Failed to add compliance.");
        });
    };
 
    // Delete Compliance
    const deleteCompliance = (id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed. Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then((willDelete) => {
            if (willDelete) {
                const url = `${IAM_API_BASE_URL_2}/matrix_compliance/delete/${id}`;
                HttpAPICall.withAthorization('DELETE', url, access_token, {}, {}, (response) => {
                    toast.success("Attribute deleted successfully!");
                    fetchComplianceData(); 
                }).catch((error) => {
                    toast.error("Failed to delete attribute.");
                });
            }
        });
    };

    return (
        <Ax>
            <Helmet><title>Asset Matrix Details</title></Helmet>
            <ToastContainer />
            <div className="row ml1">
                <div className="col-12 pl0 bg-white ">
                    <TabNavigationAssetMatrix matrixId={matrixId} />
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            <div className="page_containt">
                                <div className="pageTbl p-2" style={{ height: "100%" }}>
                                    <div className="tab_content_wrapper mt-2">
                                        <span className="content_heading">Compliance</span>
                                        <button type="button" className="btn btn-primary" disabled={loading} onClick={() => addComplianceModalRef.current.show()}>
                                            Add Compliance
                                        </button>
                                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title fs-5" id="exampleModalLabel">Add Compliance</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-3">
                                                                <label className="form-label require">Name of Compliance </label>
                                                            </div>
                                                            <div className="col-sm-7">
                                                                {other === "Y"
                                                                    ? <input
                                                                        name="name"
                                                                        type="text"
                                                                        value={addAssetComplianceForm.compliance_name}
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        onChange={(e) => setAddAssetComplianceForm({ compliance_type_id : '', compliance_name: e.target.value })}
                                                                        compliance_type                placeholder="Please enter Compliance Name"
                                                                        required={true}
                                                                    />
                                                                    : <TapSelect
                                                                        options={formDataOptions}
                                                                        changeEvent={(selectedOption) => {
                                                                            setAddAssetComplianceForm({compliance_type_id: selectedOption.value, compliance_name: '' });
                                                                        }}
                                                                        value={formDataOptions.find(opt => opt.value === addAssetComplianceForm.compliance_type_id)}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        placeholder="Select Compliance Type"
                                                                        required={true}
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <input
                                                                    name="other"
                                                                    type="checkbox"
                                                                    onChange={() => setOther(other === 'N' ? 'Y' : 'N')}
                                                                    checked={other === 'Y'}
                                                                    className="form-check-input"
                                                                    id="other"
                                                                />
                                                                <label className="form-check-label mx-2" htmlFor="other">New </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" onClick={() => addComplianceModalRef.current.hide()}>Close</button>
                                                        <button type="button" disabled={submitForm} className="btn btn-primary" onClick={addAssetCompliance}>Save {submitForm ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading ? <Loader /> : (
                                        <table className="table table-hover table-bordered my-3 align-items-center ">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th scope="col" style={{ width: "10%" }}>S.NO</th>
                                                    <th scope="col" style={{ width: "80%" }}>Name</th>
                                                    <th scope="col" className='text-center' style={{ width: "10%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {complianceData.length > 0 ? complianceData.map((compliance, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{compliance.compliance_type?.name}</td>
                                                        <td className="text-center">
                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                                data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu">
                                                                <li onClick={() => deleteCompliance(compliance.id)} className="dropdown-item"  >Delete</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    
                                                )) : (
                                                    <tr>
                                                        <td colSpan="2" className="text-center">No Record Found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Ax>
    );
};


export default Compliance;
