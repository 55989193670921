import * as actionTypes from './actionTypes';
import axios from 'axios';
import { logout } from './auth';
import errorValidation from "../../services/ErrorValidation";
import HttpAPICall from "../../services/HttpAPICall";
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { iscAppDataLoading } from './isc';
import { iamAppDataLoading } from './asset';
import { reportListingDataFetch } from './dashboard';


export const requisionFormDataStart = () => {
    return {
        type                :   actionTypes.REQUISION_FORM_DATA_INIT
    };
};

export const requisionFormDataDone = (formData) => {
    return {
        type                :   actionTypes.REQUISION_FORM_DATA_LOAD,
        requisionFormData   :   formData
    };
};

export const appInitilizationStarting   = () => {
    return {
        type                :   actionTypes.APP_DATA_INIT
    };
};

export const appInitilizationDataLoad   = (user_info, group_info, acl_info) => {
    return {
        type                :   actionTypes.APP_DATA_SUCCESS,
        user_info           :   user_info,
        group_info          :   group_info,
        acl_info            :   acl_info
    };
};

export const appInitilizationError   = (msg) => {
    return {
        type                :   actionTypes.APP_DATA_FAIL,
        error_msg           :   msg
    };
};

export const fireBaseSystemConfigUpdate   = (data) => {
    return {
        type                        :   actionTypes.FIREBASE_APP_VERSION_UPDATE,
        firebase_app_version        :   data && data.version    ?   data.version : '',
        firebase_allowed_ip         :   data && data.allowed_ip ?   data.allowed_ip :   null,
        firebase_maintenance_mode   :   data && data.maintenance_mode ? data.maintenance_mode : 'N'
    };
};

export const userDataSuccess   = (user_info) => {
    return {
        type                :   actionTypes.USER_DATA_SUCCESS,
        user_info           :   user_info,
    };
};

export const userDataInit   = () => {
    return {
        type                :   actionTypes.USER_DATA_INIT
    };
};



export const applicationInitLoading         =   (access_token,reload = true)  =>  {
    return dispatch => {
        const headers   =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + access_token};
        if(reload == true){
            dispatch(appInitilizationStarting());
        }
        
        Promise.all([
            axios({ method: 'GET', headers: headers, url: ORG_API_BASE_URL_2 + '/user'}), 
            axios({ method: 'GET', headers: headers, url: ORG_API_BASE_URL_2 + '/info'}), 
            axios({ method: 'GET', headers: headers, url: ORG_API_BASE_URL_2 + '/user_permission'}), 
        ])
        .then((results) => {
            let user                =   results[0].data.data;
            let user_info           =   {
                mobile                  :   user.mobile,
                email                   :   user.email,
                image                   :   user.image,
                name                    :   user.full_name,
                id                      :   user.id,
                user_type               :   user.user_type,
                widget_keys             :   user.widget_keys,
            };
            let group               =   results[1].data.data;
            let group_info          =   {
                group_name              :   group.group_name,
                group_id                :   group.tap_master_id,
                image                   :   group.image,
                image_path              :   group.image_path,
                detail                  :   group.detail,
                activate_group_logo     :   group.activate_group_logo ? group.activate_group_logo : 'N'
            };
            
            let acl                 =   results[2].data;
            let acl_info            =   {
                group_modules           :   acl.group_modules,
                group_sub_modules       :   acl.group_sub_modules,
                user_modules            :   acl.user_modules,
                permissions             :   acl.permissions,
                user_sub_modules        :   acl.user_role_sub_modules
            };
           
            dispatch(iscAppDataLoading(user_info,group_info,acl_info));
            dispatch(iamAppDataLoading(user_info,group_info,acl_info));
            dispatch(reportListingDataFetch(access_token));
           
        }).catch(function (err) {
            errorValidation.apiErrorHandle(err, 'Application not initialize successfully.', false);
            dispatch(logout());
            dispatch(appInitilizationError('An Error Occured'));
        });
       
    };
    
}

export const updateUserDetail         =   ()  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
      
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_API_SERVER + '/org/user', access_token, {})
            .then((response) => {
               let respData = response.data;
               let user                =   respData.data;
               let user_info           =   {
                   mobile                  :   user.mobile,
                   email                   :   user.email,
                   image                   :   user.image,
                   name                    :   user.full_name,
                   id                      :   user.id,
                   user_type               :   user.user_type,
                   widget_keys             :   user.widget_keys,
               };
               
              dispatch(userDataSuccess(user_info));
            }).catch(function (err) {
                errorValidation.apiErrorHandle(err, 'Application not initialize successfully.', false);
                dispatch(logout());
                dispatch(appInitilizationError('An Error Occured'));
            });
    };
};

