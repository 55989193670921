import React from 'react';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../../components/TapUi';
import { Modal } from 'bootstrap';
import TapSelect from '../../../../components/ui/TapSelect';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import InputAssetSearch from '../../../includes/ui/InputAssetSearch';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import DateService from '../../../../services/DateService';
import Status from '../../../../components/ui/Status';
import swal from "sweetalert";
import { toast } from "react-toastify";
import axios from 'axios';


class TaskSAPReportList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.reportUrl                          =   IAM_API_BASE_URL_2 + '/task/sap_processing_data';
        
        this.reportFilterFormInitialState       =   {
            site_group_ids                          :   [],
            search_site_ids                         :   [],
            'asset_group_ids'                       :   [],
            search_asset_type_ids                   :   [],
            search_asset_ids                        :   [],
            search_activity_ids                     :   [],
            search_activity_codes                   :   [],
            search_material_ids                     :   [],
            search_status                           :   [],
            search_task_id                          :   '',
            search_transaction_type                 :   '',
            search_sap_id                           :   '',
            date_range                              :   '',
            date_range_start                        :   null,
            date_range_end                          :   null,
        };
        
        this.reportFilterFormLabel              =   {
            site_group_ids                          :   'Site Groups : ',
            search_site_ids                         :   'Sites : ',
            search_activity_ids                     :   'WBS : ',
            search_activity_codes                   :   'WBS Code  : ',
            search_material_ids                     :   'Material  : ',
            search_task_id                          :   'Transaction ID  : ',
            search_transaction_type                 :   'Transaction Type : ',
            search_sap_id                           :   'SAP ID  : ',
            'asset_group_ids'                       :   'Asset Type Groups : ',
            search_asset_type_ids                   :   'Asset Type : ',
            search_asset_ids                        :   'Assets : ',
            search_status                           :   'Status : ',
            date_range                              :   'Transaction Period : ',
        }
        
        this.state                              =   {
            iam_asset_types                         :   [],
            iam_user_sites                          :   [],
            formDataLoaded                          :   true,
            filterFormData                          :   null,
            searchedAssets                          :   [],
            reportFilterForm                        :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm               :   {...this.reportFilterFormInitialState},
            listing_loading                         :   false,
            downloadReportBtn                       :   false,
            formSearchedElems                       :   [],
            listingData                             :   [],
            listingMeta                             :   null,
            reportDownloadBtn                       :   false,
            reportDownloading                       :   false,
            all_Activities                          :   [],
            all_periods                             :   [],
            allActivitiesCodes                     :   [],
            allMaterials                            :   [],
            taskStatus                              :   [],
            allSiteGroupsList                       :   [],
            taskDetail                              :   null,
            view_loading                            :   false,
            resendTaskDetail                        :   null,
            task_detail_loading                     :   false,
            allAssetTypesGroupsList                 :    [],
            allTransactionTypes                     :       [{value:"task",label:"Task"},{value:"counterlog",label:"Counterlog"}],
            allStatus                               :       [],
            data_loading                            :     false,
            taskPushedDetail                        :      null
        };
       
    }

    componentDidMount() {
        this.setState({listing_loading : true})
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props);
        }, 1000);
        // this.reportFilterFormSubmit();
        // this.filterFormDataInit();
        this.taskSearchModal              =     new Modal(document.getElementById('taskSearchModal'), {keyboard: false, backdrop :false});
        this.taskDetailModal              =     new Modal(document.getElementById('taskDetailModal'), {keyboard: false, backdrop :false});
        this.taskPushedDataModal          =     new Modal(document.getElementById('taskPushedDataModal'), {keyboard: false, backdrop :false});
        //this.reportFilterhandler(true);
    }
    
    // componentWillReceiveProps(nextProps){
    //     if(nextProps.all_periods !== this.props.all_periods) {
    //         this.initilaizeAppDataToFilterForm(nextProps);
    //     }
    // }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        this.setState({
            all_periods             :       all_periods,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            allAssetTypesGroupsList :       props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        }, () => {
            this.filterFormDataInit()
         }
         );
    }
    
    filterFormDataInit                      =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.reportUrl + '_form_data', this.props.access_token, null, null, (response) => {
            let respData = response.data;
            this.setState({
                formDataLoaded              :   true,
                all_Activities              :   respData.activities && respData.activities.length > 0 ? respData.activities.map(ac => { return {value: ac.id, label: ac.name}}) : [],
                allSiteGroupsList           :    respData && respData.site_groups && respData.site_groups.length > 0 ? respData.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                allMaterials                :   respData.materials && respData.materials.length > 0 ? respData.materials.map(ac => { return {value: ac.id, label: ac.material_name}}) : [],
                allStatus                :   respData.status_list && respData.status_list.length > 0 ? respData.status_list.map(ac => { return {value: ac.key, label: ac.status}}) : [],
                allActivitiesCodes         :   respData.site_wbs_code  && respData.site_wbs_code.length > 0 ? respData.site_wbs_code.map(ac => { return {value: ac, label: ac}}) : [],
            },() => {
                let ParamObject                 =       new URLSearchParams(this.props.location.search);
              
                if(ParamObject.get('search_site_id') || ParamObject.get('period') || ParamObject.get('activity_id') ) {
                     let updateFilterForm = {'date_range' : ParamObject.get('period'),'search_activity_ids' : ParamObject.get('activity_id') ?  [Number(ParamObject.get('activity_id'))] : [] };
                   
                   if(ParamObject.get('search_site_id')){
                        updateFilterForm['search_site_ids']        =   [ParamObject.get('search_site_id')]
                    }
                    updateFilterForm['search_status']        =  [26];
                    
                    setTimeout(() => {
                        this.setState({reportFilterForm : {...this.state.reportFilterForm,...updateFilterForm}},
                        function(){
                             this.reportFilterFormSubmit();
                            });
                    }, 1000)
                  
                }else{
                    this.reportFilterFormSubmit();
                }
            
            });
           
        }, (err) => { });
    }
    
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        
        let serachFormKeys                      =   Object.keys(this.state.reportFilterForm);
        
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    if(key == 'search_activity_ids') {
                        show_val            = this.state.all_Activities.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }

                    if(key == 'search_activity_codes') {
                        show_val            = this.state.allActivitiesCodes.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_material_ids') {
                       
                        show_val            = this.state.allMaterials.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_type') {
                        show_val            = this.state.allTransactionTypes.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }

                    if(key == 'search_task_id') {
                        show_val            = this.state.reportFilterForm.search_task_id ? this.state.reportFilterForm.search_task_id : '';
                    }

                    if(key == 'search_sap_id') {
                        show_val            = this.state.reportFilterForm.search_sap_id ? this.state.reportFilterForm.search_sap_id : '';
                    }
                    if(key == 'search_status') {
                        show_val            = this.state.allStatus.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'date_range') {
                        let  start_range          =       moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =       moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }

                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListingTblData(1);
            this.taskSearchModal.hide();
        });
    }
    
    loadListingTblData                      =   (page =1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listingMeta             :   null,
            listing_tbl_page        :   page,
            reportDownloadBtn       :   false,
            reportDownloading       :   false
        });
        let params                          =   {page:page,...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
           
            this.setState({
                listingData                 :   respData && respData.data ? respData.data : [],
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                
            });
        }, (err) => {
            
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :           [],
            reportFilterForm                :           {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :           {...this.reportFilterFormInitialState},
            searchedAssets                  :           []
        }, () => {
            this.loadListingTblData(1);
        });
    }

    taskDetailModalInit         =       (data)      =>      {
      
        this.setState({
            taskDetail          :       data,
            minimizeTable       :       true,
            view_loading        :       true
        })
        setTimeout(() => {
            this.setState({view_loading : false})
       },1000)
    }

    taskPushedDataModalInit         =       (data)      =>      {
      
        if(data.push_data){
            this.setState({
                taskPushedDetail          :       data.push_data ? data.push_data : null,
                data_loading        :       true
            })
            setTimeout(() => {
                this.setState({data_loading : false})
           },1000)
           this.taskPushedDataModal.show()
        }else{
            this.setState({
                taskPushedDetail          :      null,
            }) 
        }
    }

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable : false})
    }

    taskSearchModalInit     =       ()      =>      {
        this.taskSearchModal.show();
    }

    resendTaskInit          =       (id)      =>        {
        swal({
            title: "Resend",
            text: "Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(proceed => {
                if (proceed) {
                    this.taskDetailModal.show();
                    this.setState({task_detail_loading  : true})
                    HttpAPICall.withAthorization('PUT', IAM_API_BASE_URL_2 + '/task/sap_processing_data_push', this.props.access_token, null, {transaction_id : id}, (response) => {
                    let respData = response.data;
                    this.setState({
                            resendTaskDetail      :       respData
                    },() => {
                        this.afterResendSubmit(id)
                    })
                    
                        
                    }).then(() => {
                        this.setState({task_detail_loading: false});
                    });
       
                }
            });
        
    }

    afterResendSubmit               =   (id) =>  {
       
        let itemList                        =   [...this.state.listingData];
        let dateIndex                       =   itemList.findIndex(i => i.transaction_id == id);
        itemList[dateIndex]                 =   {...this.state.listingData[dateIndex], isLoading : true};
        this.setState({listingData : itemList});
       
        let params                          =   {page : 1, search_transaction_id : id};
        HttpAPICall.withAthorization('GET',  this.reportUrl, this.props.access_token, params, {}, (resp) => {
            
            if(resp.data && resp.data.data) {
                itemList[dateIndex]         =   resp.data.data[0];
                this.setState({listingData : itemList,taskDetail:resp.data.data[0]});
            }
        });
    }
    
    taskListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Task SAP Report</h3>
                <div className="text-end mt15">
                    <button type="button" className="btn btn-secondary" onClick={this.taskSearchModalInit} disabled={this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>

                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Task ID  : {this.state?.taskDetail?.transaction_id} </h3>
                            <div className="text-end mt15">
                                {this.state.taskDetail && this.state.taskDetail.status && (this.state.taskDetail.status.key == 5 || this.state.taskDetail.status.key == 41) ?
                                  <button type="button" className="btn btn-primary btn-sm" onClick={() => this.resendTaskInit(this.state.taskDetail.transaction_id)}>Resend</button> : null}
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.taskListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.taskViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    taskListingTableJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.reportSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Task</th>
                        </tr>)
                        : (<tr className='text-center'>
                            <th style={{ width: "5%" }} >S.No</th>
                            <th style={{ width: "11%" }}>Transaction Date</th>
                            <th style={{ width: "10%" }}>Transaction ID</th>
                            <th style={{ width: "10%" }}>Transaction Type</th>
                            <th style={{ width: "10%" }}>SAP ID</th>
                            <th style={{ width: "16%" }}>Asset Name</th>
                            <th style={{ width: "16%" }}>WBS</th>
                            <th style={{ width: "10%" }}>Maintenance Plant </th>
                            <th style={{ width: "11%" }}>Status </th>
                            <th style={{ width: "8%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.listingData.length > 0
                            ? (this.state.listingData.map((data, index) => {
                                
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <a href="#" className="text-capitalize link-primary" onClick={() => this.taskDetailModalInit(data)}>
                                                                    {data && data.transaction_id ? data.transaction_id : "-"}
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <small className="float-end">{data && data.status ? <Status color={data.status.color_code}>{data.status.status}</Status> : "-"}</small>
                                                            </div>
                                                        </div>
                                                        <div className="mt-1">
                                                            <small> <span className="d-inline-block text-truncate" style={{ 'max-width': '200px' }}>{data && data.asset_name ? data.asset_name : "-"}</span> </small>
                                                            <small className="float-end">{data && data.date_display ? data.date_display : "-"}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                 {data && data.isLoading && data.isLoading == true ? <td colSpan={9} className="text-center"><Loader /></td>
                                                    : <Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-center'>{data && data.date_display ? data.date_display : "-"}</td>
                                                <td className='cursor_pointer link-primary' onClick={() => this.taskDetailModalInit(data)}>{data && data.transaction_id ? data.transaction_id : "-"}</td>
                                                <td className='text-center text-capitalize'>{data && data.transaction_type ? data.transaction_type : "-"}</td>
                                                <td>{data && data.sap_id ? data.sap_id : "-"}</td>
                                                <td>{data && data.asset_name ? data.asset_name : "-"}</td>
                                                <td>{data && data.wbs ? <span>{data.wbs} {data.wbs_code && <div className='text-sm form-text'>Code : {data.wbs_code}</div>}</span> : "-"}</td>
                                                <td>{data && data.maintenance_plant ? data.maintenance_plant : "-"}</td>
                                                <td>{data && data.status ? <Status color={data.status.color_code}>{data.status.status}</Status> : "-"}</td>
                                                <td className='text-center'>
                                                    {data && data.status && (data.status.key == 5 || data.status.key == 41) ? <button type="button" className="btn btn-primary btn-sm" onClick={() => this.resendTaskInit(data.transaction_id)}>Resend</button> : null}
                                                </td>
                                                </Ax>}
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    taskViewDetails                =   ()   =>   {
        let detail = this.state.taskDetail;
        return (<div className="tab_content_header">
            {detail &&
                <Ax>
                    <div className="tab_content_wrapper fw-bold my-2">Transaction Detail</div>
                    <table className="table table-hover table-bordered  table-responsive bg-white">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }}>Date of Task</td>
                                <th>{detail.date_display ? detail.date_display : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Transaction ID</td>
                                <th>{detail.transaction_id ? detail.transaction_id : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Transaction Type</td>
                                <th>{detail.transaction_type ? detail.transaction_type : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>SAP ID</td>
                                <th>{detail.sap_id ? detail.sap_id : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Activity Name</td>
                                <th>{detail.activity_name ? detail.activity_name : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>SAP Pushed Data</td>
                                <th className='link-primary cursor_pointer' onClick={() => this.taskPushedDataModalInit(detail)}>{detail.push_data ? "View" : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>SAP Response Code</td>
                                <th>{detail.response_code ? detail.response_code : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>SAP Response Message</td>
                                <th>{detail.response_msg ? detail.response_msg : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Status</td>
                                <th>{detail.status ? <Status color={detail.status.color_code}>{detail.status.status}</Status> : ""}</th>
                            </tr>

                        </tbody>
                    </table>
                    <div className="tab_content_wrapper fw-bold my-2">Asset</div>
                    <table className="table table-hover table-bordered  table-responsive bg-white">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }}>Asset Name</td>
                                <th>{detail.asset_name ? detail.asset_name : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Asset Code</td>
                                <th>{detail.asset_code ? detail.asset_code : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Measuring Point Hours</td>
                                <th>{detail.measuring_point_hr ? detail.measuring_point_hr : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Measuring Point KM</td>
                                <th>{detail.measuring_point_km ? detail.measuring_point_km : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Maintenance Plant</td>
                                <th>{detail.maintenance_plant ? detail.maintenance_plant : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Fuel Type</td>
                                <th>{detail.fuel_type ? detail.fuel_type : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Fuel Consume</td>
                                <th>{detail.fuel_consume ? detail.fuel_consume : ""}</th>
                            </tr>

                        </tbody>
                    </table>
                    <div className="tab_content_wrapper fw-bold my-2">Task Detail</div>
                    <table className="table table-hover table-bordered  table-responsive bg-white">
                        <tbody>

                            <tr>
                                <td style={{ width: "33%" }}>Shift</td>
                                <th>{detail.shift ? detail.shift : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>WBS Name</td>
                                <th>{detail.wbs ? detail.wbs : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>WBS Code</td>
                                <th>{detail.wbs_code ? detail.wbs_code : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Item (SD)</td>
                                <th>{detail.item_sd ? detail.item_sd : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Plan Hrs</td>
                                <th>{detail.plan_hr ? detail.plan_hr : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Start HR</td>
                                <th>{detail.start_hr ? detail.start_hr : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Close HR</td>
                                <th>{detail.close_hr ? detail.close_hr : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Total HR</td>
                                <th>{detail.total_hr ? detail.total_hr : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Plan KM</td>
                                <th>{detail.plan_km ? detail.plan_km : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Start KM</td>
                                <th>{detail.start_km ? detail.start_km : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Close KM</td>
                                <th>{detail.close_km ? detail.close_km : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Total KM</td>
                                <th>{detail.total_km ? detail.total_km : ""}</th>
                            </tr>

                            <tr>
                                <td style={{ width: "33%" }}>Working Hours</td>
                                <th>{detail.working_seconds ? DateService.convertSeconds(detail.working_seconds, ":") : ''}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Breakdown Hours</td>
                                <th>{detail.breakdown ? DateService.convertSeconds(detail.breakdown, ":") : ''}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Idle Hours</td>
                                <th>{detail.idle_seconds ? DateService.convertSeconds(detail.idle_seconds, ":") : ''}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Chainage From</td>
                                <th>{detail.chainage_from ? detail.chainage_from : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Execution By</td>
                                <th>{detail.execution_by ? detail.execution_by : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Supplier Vendor</td>
                                <th>{detail.supplier_vendor_code ?  detail.supplier_vendor_code : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Area of Work</td>
                                <th>{detail.area_of_work ? detail.area_of_work : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Location From</td>
                                <th>{detail.location_from ? detail.location_from : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Chainage To</td>
                                <th>{detail.chainage_to ? detail.chainage_to : ""}</th>
                            </tr>

                            <tr>
                                <td style={{ width: "33%" }}>Location To</td>
                                <th>{detail.location_to ? detail.location_to : ""}</th>
                            </tr>


                            <tr>
                                <td style={{ width: "33%" }}>Material</td>
                                <th>{detail.material_name ? detail.material_name : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Productivity</td>
                                <th>{detail.qty ? detail.qty : ""} {detail.uom ? detail.uom : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Trips</td>
                                <th>{detail.trips ? detail.trips : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Operators</td>
                                <th>{detail.operators ? detail.operators : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Remarks</td>
                                <th>{detail.remarks ? detail.remarks : ""}</th>
                            </tr>

                        </tbody>
                    </table>
                </Ax>

            }
        </div>)
    }

    taskSearchModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="taskSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Task SAP Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="taskSearchForm" onSubmit={this.reportFilterFormSubmit}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">

                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_task_id"
                                            type="text"
                                            value={this.state.reportFilterForm.search_task_id}
                                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder="Search Transaction ID"
                                        />
                                    </div>

                                    <label className="col-sm-2 col-form-label col-form-label-sm ">SAP ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_sap_id"
                                            type="text"
                                            value={this.state.reportFilterForm.search_sap_id}
                                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder="Search SAP ID"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Site Group</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allSiteGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    reportFilterForm: {
                                                        ...this.state.reportFilterForm,
                                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                                            placeholder="Select Site Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Execution Site</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Sites"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Type Group</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {
                                                this.setState({
                                                    reportFilterForm: {
                                                        ...this.state.reportFilterForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Type</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">

                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Name</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">WBS</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.all_Activities}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_activity_ids')}
                                            value={this.state.all_Activities.filter(r => this.state.reportFilterForm.search_activity_ids.includes(r.value))}
                                            placeholder="Select All WBS"
                                            // required={true}
                                            isMulti={true}
                                            isSearchable={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">WBS Code</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allActivitiesCodes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_activity_codes')}
                                            value={this.state.allActivitiesCodes.filter(r => this.state.reportFilterForm.search_activity_codes.includes(r.value))}
                                            placeholder="Select WBS Codes"
                                            // required={true}
                                            isMulti={true}
                                            isSearchable={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Material</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allMaterials}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_material_ids')}
                                            value={this.state.allMaterials.filter(r => this.state.reportFilterForm.search_material_ids.includes(r.value))}
                                            placeholder="Select Material"
                                            menuPlacement="top"
                                            // required={true}
                                            isMulti={true}
                                            isSearchable={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Type</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allTransactionTypes}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_transaction_type')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionTypes.find(s => this.state.reportFilterForm.search_transaction_type == s.value)}
                                            placeholder="Select Transaction Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">

                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Status</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allStatus}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allStatus.filter(s => this.state.reportFilterForm.search_status.includes(s.value))}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods}
                                            value={this.state.reportFilterForm.date_range}
                                            startDate={this.state.reportFilterForm.date_range_start}
                                            endDate={this.state.reportFilterForm.date_range_end}
                                            // required={true}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    reportFilterForm: { ...this.state.reportFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate }
                                                });
                                            }}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            className="form-control-sm"
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    taskDetailModalJsx               =        ()             =>        {
       let detail = this.state.resendTaskDetail;
        return (
            <div className="modal fade" id="taskDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Task SAP for {this.state?.taskDetail?.transaction_id}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                  
                        <div className="modal-body">
                            {this.state.task_detail_loading ? <div className='text-center'><Loader/></div> 
                             : detail && <Ax>
                                <table className="table table-hover table-bordered  table-responsive bg-white">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "33%" }}>Message</td>
                                            <th>{detail.msg ? detail.msg : ""}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "33%" }}>Request Send</td>
                                            <th> <pre>{JSON.stringify(detail.data, null, 2)}</pre></th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "33%" }}>Response Received</td>
                                            <th> <pre>{JSON.stringify(detail.response, null, 2)}</pre></th>
                                        </tr>
                                    </tbody>
                                </table>
                            </Ax>}

                        </div>
                           
                    </div>
                </div>
            </div>
        );
    }

    taskPushedDetailModalJsx               =        ()             =>        {
        let detail = this.state.taskPushedDetail;
         return (
             <div className="modal fade" id="taskPushedDataModal" tabIndex="-1">
                 <div className="modal-dialog modal-lg modal-dialog-scrollable">
                     <div className="modal-content">
                         <div className="modal-header">
                             <h5 className="modal-title" id="searchAssetModalLabel">Task SAP for {this.state?.taskDetail?.transaction_id}</h5>
                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                   
                         <div className="modal-body">
                             {this.state.data_loading ? <div className='text-center'><Loader/></div> 
                              : detail && <Ax>
                                <pre>{JSON.stringify(detail, null, 2)}</pre>

                             </Ax>}
 
                         </div>
                            
                     </div>
                 </div>
             </div>
         );
     }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
       <Helmet><title>HG Infra - Task SAP Report</title></Helmet>
           {this.taskListjsx()}
           {this.taskSearchModalJsx()}
           {this.taskDetailModalJsx()}
           {this.taskPushedDetailModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
    };
};

export default connect(mapStateToProps)(TaskSAPReportList);