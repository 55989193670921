import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import tapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";

class OpeningBalanceAddModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
      
        this.state                  =   {
            access_token                     :   '',
            openingBalanceWarehouseRows      :     [{warehouse : null}],
            itemId                          :       '',
            warehouseList                   :       [],
            openingBalanceAddFormSubmitting  :     false,
            editForm                         :     false,
            fetchingCurrentBalance          :   false,
            itemCurrentStock                :   [],
            itemName                        :   ''
        }
        this.addOpeningBalanceModalId   =   uuid();
        this.updateFuelBalanceFormId    =   this.addOpeningBalanceModalId + 'Form';
        this.itemOpeningStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_opening_stock';
        this.itemCurrentStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';
    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.addOpeningBalanceModal         =   new Modal(document.getElementById(this.addOpeningBalanceModalId), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    modalInit         =   (item_id='',itemName='')  =>  {
        this.addOpeningBalanceModal.show()
        if(item_id){
            this.setState({itemId : item_id,itemName : itemName})
            this.getOpeningStockData(item_id);
            this.getCurrentStockData(item_id);
        }
      
    }

    getOpeningStockData                 =          (id)   =>  {
        this.setState({form_loading: true});
        HttpAPICall.withAthorization('GET',  INVENTORY_API_BASE_URL_2 + '/item_opening_stock_form_data/' + id , this.props.access_token, {}, {}, (resp) => {
        this.setState({
                      formData         :    resp.data,
                      warehouseList    :    resp.data.warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
        openingBalanceWarehouseRows    :    resp.data.item_opening_stock.map((m) => 
                                            { return {warehouse : m.warehouse_id, opening_stock : m.qty, qty : m.qty, rate : m.rate,batch_number : m.batch_number ? m.batch_number : "", expiry_date:m.expiry_date ? m.expiry_date : ""}})
        })
        this.addWarehouseRow()
      }).then(() => {this.setState({form_loading: false})})
    }
    
    getCurrentStockData                 =          (id)   =>  {
        this.setState({fetchingCurrentBalance : true});
        HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
            this.setState({ itemCurrentStock : resp.data.current_stock });
        }).then(() => {this.setState({fetchingCurrentBalance: false})})
    }

    addWarehouseRow                           =   ()  =>  {
        this.setState({openingBalanceWarehouseRows : [...this.state.openingBalanceWarehouseRows, {warehouse : null}] });
    }

    onWarehouseChange                            =   (selectedWarehouse, k)  => {
        if(selectedWarehouse.value && selectedWarehouse.value > 0) {
            let item                    =   selectedWarehouse;
            let allRows                 =   this.state.openingBalanceWarehouseRows;
            allRows[k]                  =   {
                warehouse                   :   item, 
                qty                         :   selectedWarehouse.qty ? selectedWarehouse.qty : '',
                rate                        :   selectedWarehouse.rate ? selectedWarehouse.rate : '',
                batch_number                :   selectedWarehouse.batch_number ? selectedWarehouse.batch_number : '',
                expiry_date                 :   selectedWarehouse.expiry_date ? selectedWarehouse.expiry_date : '' 
            };
            this.setState({openingBalanceWarehouseRows : allRows});
            this.addWarehouseRow();
        }
    }

    onWarehouseInputChange                       =   (e, name, k, selectedOption = null)  =>  {
       
        let val                             =   '';
        if(name === 'expiry_date') {
            val                         =   selectedOption ?  moment(selectedOption).format('YYYY-MM-DD') : null
        } else {
            val = e.target.type && e.target.type == 'number' ? Number(e.target.value) : e.target.value;
        }
        let allRows                 =   this.state.openingBalanceWarehouseRows;
        allRows[k]                  =   {...this.state.openingBalanceWarehouseRows[k] , [name] : val};
        this.setState({openingBalanceWarehouseRows : allRows});
    }

    removeWarehouseRow                =     (k)     =>  {
        let allRows                 =   this.state.openingBalanceWarehouseRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({openingBalanceWarehouseRows : allRows});
        }
    }

    openingStockFormHandler                     =   (e) =>      {
        e.preventDefault()

        let warehouse = [...this.state.openingBalanceWarehouseRows];

        let warehouseData = warehouse.filter(i => i.warehouse !== null).map(i => {
            return { ...i, warehouse: i.warehouse.value ? i.warehouse.value : i.warehouse }
        });
        let stockValidation = [];
        let stockValidationError = false;
        if (warehouseData) {
           
            warehouseData.forEach(item => {
               
                let combinationKey = item.warehouse  + item.batch_number + item.expiry_date;
                
                if (stockValidation.includes(combinationKey)) {
                    stockValidationError = true;
                }

                return stockValidation.push(combinationKey);
            });
        }
        
        if (stockValidationError) {
            toast.error("Opening Stock should not contain duplicate values", { position: toast.POSITION.TOP_RIGHT });
        } else {
            //Check for Negative Stock :-
            let find_negative_stock     =   false;
            warehouseData.forEach(item => {
                
                let item_batch_number       =   item.batch_number == '' ? null : item.batch_number;
                let item_expiry_date        =   item.expiry_date == '' ? null : item.expiry_date;
                
                let currentStock            =   this.state.itemCurrentStock.find(cs => {
                                                    return cs.warehouse_id == item.warehouse && 
                                                            cs.batch_number == item_batch_number && 
                                                            cs.expiry_date == item_expiry_date;
                                                });
                if(currentStock && item.opening_stock) {
                    //New Current Stock =  Current Stock + New opening balance - previous opening balance
                    let new_current_stock   =   parseFloat(currentStock.free_qty) + parseFloat(item.qty) - parseFloat(item.opening_stock);
                    if(new_current_stock < 0) {
                        find_negative_stock =   true;
                    }
                }
            });
            if(find_negative_stock) {
                swal({ title: "Are you sure?", text: "Changing opening balance may impact your inventory levels, and Inventory may go below zero if it is consumed.",
                    icon: "warning", buttons: ["No", "Yes"] }).then(isConfirm => {
                        if (isConfirm) { this.submitOpeningStockForm(warehouseData); }
                });
            } else {
                this.submitOpeningStockForm(warehouseData);
            } 
        }
    }
    
    submitOpeningStockForm                      =   (warehouseData)  =>  {
        let frmData = {
            item_id  : this.state.itemId,
            opening_stock : warehouseData
        }
        
        this.setState({ openingBalanceAddFormSubmitting: true });
        HttpAPICall.withAthorization('PUT', this.itemOpeningStockUrl, this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.addOpeningBalanceModal.hide();
            if (this.props.afterSubmitForm) {
                this.props.afterSubmitForm()
            }
        }).then(() => this.setState({ openingBalanceAddFormSubmitting: false }));
    }
    
    addOpeningBalanceModalJsx           =   ()  =>  {
        return <div className="modal fade" id={this.addOpeningBalanceModalId} tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            Add Opening Balance for {this.state.itemName}
                        </h5>
                        <button type="button" className="btn-close" disabled={this.state.openingBalanceAddFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                  
                    <div className="modal-body">
                    {this.state.form_loading ? <Loader />
                       : <form className={['p-2',].join(' ')} onSubmit={this.openingStockFormHandler} id={this.updateFuelBalanceFormId}>
                        {this.state.itemId ? (<div className="row align-items-center">
                                <div className="col-12">
                                    <table className="table table-bordered table-responsive bg-white">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                                <th scope="col" className="text-center" style={{ width: "25%" }}>Warehouse</th>
                                                <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                                                <th scope="col" className="text-center" style={{ width: "15%" }}>Unit Rate</th>
                                                <th scope="col" className="text-center" style={{ width: "15%" }}>Amount</th>
                                                {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? (<Ax>
                                                    <th scope="col" className="text-center" style={{ width: "15%" }}>Batch Number</th>
                                                    <th scope="col" className="text-center" style={{ width: "10%" }}>Expiry Date</th>
                                                </Ax>) : null}
                                                <th scope="col" className="" style={{ width: "5%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.openingBalanceWarehouseRows.map((i, k) => {
                                                let tdRows = (i.warehouse) ? (<Ax>
                                                    <td className="text-start">
                                                        <span className="fw-bold">{!(i.warehouse.label) ? this.state.warehouseList.filter((s) => s.value === i.warehouse).map(s => s.label) : i.warehouse.label}</span>
                                                    </td>
                                                    <td>
                                                        <input
                                                            name="qty"
                                                            type="number"
                                                            value={this.state.openingBalanceWarehouseRows[k].qty}
                                                            onChange={(e) => this.onWarehouseInputChange(e, 'qty', k)}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Qty"
                                                            required={true}
                                                            min={1}
                                                            step=".01"
                                                        />

                                                    </td>
                                                    <td>
                                                        <input
                                                            name="rate"
                                                            type="number"
                                                            value={this.state.openingBalanceWarehouseRows[k].rate}
                                                            onChange={(e) => this.onWarehouseInputChange(e, 'rate', k)}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Unit Rate"
                                                            required={true}
                                                            step="0.01"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        {(this.state.openingBalanceWarehouseRows[k].qty * this.state.openingBalanceWarehouseRows[k].rate).toFixed(2)}
                                                    </td>
                                                    {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? (<Ax>
                                                        <td className='text-start'>
                                                            <input
                                                                name="batch_number"
                                                                type="text"
                                                                value={this.state.openingBalanceWarehouseRows[k].batch_number}
                                                                onChange={(e) => this.onWarehouseInputChange(e, 'batch_number', k)}
                                                                className="form-control text-end"
                                                                autoComplete="off"
                                                                placeholder="Batch Number"
                                                            />
                                                        </td>
                                                        <td>  <DatePicker
                                                            selected={
                                                                this.state.openingBalanceWarehouseRows[k].expiry_date
                                                                    ? moment(this.state.openingBalanceWarehouseRows[k].expiry_date).toDate()
                                                                    : false
                                                            }
                                                            name="expiry_date"
                                                            onChange={(selectedOption, e) => this.onWarehouseInputChange(e, 'expiry_date', k, selectedOption)}
                                                            dateFormat="dd-MMM-yyyy"
                                                            className={"form-control"}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            autoComplete="off"
                                                            scrollMonthYearDropdown
                                                            placeholderText={`Please Enter Date`}

                                                        /></td>
                                                    </Ax>) : null}
                                                </Ax>)
                                                    : (<Ax>
                                                        <td className='text-start'>
                                                            <TapSelect
                                                                options={this.state.warehouseList}
                                                                isSearchable={true}
                                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addOpeningStockForm', 'warehouse'); this.onWarehouseChange(selectedOption, k) }}
                                                                placeholder={"Search Warehouse"}
                                                            />
                                                        </td>
                                                        {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? <td colSpan="5" className="text-center align-middle" >-</td>
                                                            : <td colSpan="3" className="text-center align-middle" >-</td>}
                                                    </Ax>);
                                                return (<tr className="text-center align-middle" key={k}>
                                                    <td className="text-center align-middle">{k + 1}</td>
                                                    {tdRows}
                                                    <td className="text-center align-middle">
                                                        <a href="#" onClick={() => this.removeWarehouseRow(k)}>
                                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                                        </a>
                                                    </td>
                                                </tr>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-end">
                                    <a role="button" className="link-primary" onClick={() => this.addWarehouseRow()}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                                </div>
                            </div>) : null}
                        </form>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.openingBalanceAddFormSubmitting} form={this.updateFuelBalanceFormId}>Update {this.state.openingBalanceAddFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                   
                </div>
            </div>
        </div>
    }
    
    render                              =   ()  =>  this.addOpeningBalanceModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(OpeningBalanceAddModal);