import React from 'react';
import axios from 'axios';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';


class InvoiceRuleAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initInvoiceRuleForm            =           {
            name                            :           '',
            rule_number                     :           '',
            asset_type_id                   :           '',
            rate                            :           null,
            days                            :           null,
            hours                           :           null,
            odo_meter                       :           null,
            opc                             :           null,
            month_calculation_applicable    :          'no',
            day_threshold_limlit            :          null,
            rate_day_excess                 :          null,
            hour_threshold_limlit            :          null,
            rate_hour_excess                :          null,
            hr_excess_applicable            :           "yes",
            km_threshold_limlit              :          null,
            rate_km_excess                  :          null,
            km_excess_applicable            :           "yes",
            opc_threshold_limlit             :          null,
            rate_opc_excess                 :          null,
            opc_excess_applicable           :          "yes",
            bd_excess_applicable            :           "no",
            type_downtime                   :           'bd',
            downtime_threshold_limlit        :           '',
            downtime_calculation_criteria   :           'fixed',
            rate_downtime_excess            :           null,
            idle_days_applicable            :           "no",
            idle_days_limlit                 :           '',
            rate_idle_days                  :           null,
            weekly_off_applicable           :           "no",
            weekly_off_criteria             :           '',
            weekly_off_day                  :           [],
            rate_weekly_off                 :           null,
            weekly_off_idle_days_adjust     :           "no",
            fuel_consuption_applicable      :           "no",
            fuel_consuption_limlit           :           null,
            fuel_rate                       :           null,
            fuel_economy_applicable         :           "no",
            fuel_economy_limlit              :           null,
            fuel_economy_rate               :           null,
            productivity_applicable         :           "no",
            othercharges_applicable         :           "no"
        }

        this.state                       =          {
            saveFormSubmitting           :          false,
            addInvoiceRuleForm           :          {...this.initInvoiceRuleForm},
            formDataLoaded               :          false,
            allAssetTypes                :          [],
            allProductivityRows           :          [{activity : '',material: '',unit: '',attachment : '',rate:''}],
            selectedAssetType            :          null,
            nextStep                     :         false,
            allDowntimeTypes             :          [],
            calculationCriteriaType      :          [],
            allWeekDays                  :          [],
            allActivities                :          [],
            allProductionUnits           :          [],
            allAttachments               :          [],
            allMaterials                 :          [], 
            otherInvoiceCharges         :          [], 
            form_loading                :           false,
            invoiceEditDetail           :           null,
            allOtherChargesRows          :          [{name:'',charges:''}]          
        }
       
    }

    componentDidMount() {
         this.initilaizeFormData(this.props);
    }
     
    initilaizeFormData              =       (pr)        =>          {
        if(!this.state.formDataLoaded) {
            this.setState({form_loading : true})
            HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/invoice_rule_formdata', this.props.access_token, null, null, (response) => {
               let formData = response.data;
                
                this.setState({
                    formDataLoaded              :       formData ? true : false,
                    allAssetTypes               :       formData.asset_types && formData.asset_types.length > 0 ? formData.asset_types.map(a => {return({value : a.id , label : a.asset_type_name,asset_type : a})})  : [],
                    allDowntimeTypes            :   [...formData.downtime_types, {id: null,key:'all',name:"Total Downtime"}].map(d => { return {value:d.key, label : d.name}}),
                    calculationCriteriaType     :       [{value:"fixed",label: "Fixed"},{value:"month_wise",label : "Based on Calender Month"}],
                    allWeekDays                 :       [{value:"1", label:"Monday"},{value: "2", label:"Tuesday"},{value: "3", label:"Wednesday"},{value: "4", label:"Thursday"},{value: "5", label:"Friday"},{value: "6", label:"Saturday"},{value: "7", label:"Sunday"}],
                    weekCriteria                :       [{value:"day",label : "Days"},{value:"hour",label:"Hours"},{value:"km",label:"KM"},{value:"opc",label:"Operation Cycle"}],
                    allActivities               :       formData.activities && formData.activities.length > 0 ? formData.activities.map(a => {return({value : a.id,label : a.activity_name})}) : [],
                    allProductionUnits          :       formData.all_production_units && formData.all_production_units.length > 0 ? formData.all_production_units.map(a => {return({value : a.id,label : a.measuring_unit,activity_id : a.activity_id})}) : [],
                    allAttachments              :       formData.attachments && formData.attachments.length > 0 ? formData.attachments.map(a => {return({value : a.id,label : a.name})}) : [],
                    allMaterials                :       formData.materials && formData.materials.length > 0 ? formData.materials.map(a => {return({value : a.id,label : a.material_name})}) : [],
                    otherInvoiceCharges         :       formData.other_invoice_charges && formData.other_invoice_charges.length > 0 ? formData.other_invoice_charges.map(a => {return({value : a,label : a})}) : [],
                },() => {
                    if(pr.location && pr.location.state && pr.location.state.invoiceData){
                        let weekCriteria = [];
                        let invoiceData     =    pr.location.state.invoiceData;
                        let asset_type_meter = [{value : invoiceData.asset_type.hour_meter,label : "hour"},{value :  invoiceData.asset_type.odo_meter,label : "km"},{value :  invoiceData.asset_type.operation_cycle,label : "opc"}]
                        weekCriteria = this.state.weekCriteria.filter((elem) => !asset_type_meter.find(({ label,value }) => elem.value == label && value == "no"));
                     
                        this.setState({
                            addInvoiceRuleForm              :       {
                                                                ...this.initInvoiceRuleForm,
                            id : invoiceData.id,
                            name                            :      invoiceData.name ? invoiceData.name : '',
                            rule_number                     :      invoiceData.rule_number ? invoiceData.rule_number : '',
                            asset_type_id                   :      invoiceData.asset_type_id ? invoiceData.asset_type_id : '',
                            rate                            :      invoiceData.rate ? invoiceData.rate : '',
                            days                            :      invoiceData.days ? invoiceData.days : '',
                            hours                           :      invoiceData.hours ? invoiceData.hours : '',
                            odo_meter                       :      invoiceData.odo_meter ? invoiceData.odo_meter : '',
                            opc                             :      invoiceData.opc ? invoiceData.opc : '',
                            month_calculation_applicable    :      invoiceData.month_calculation_applicable ? invoiceData.month_calculation_applicable : '',
                            day_threshold_limlit            :      invoiceData.day_threshold_limlit ? invoiceData.day_threshold_limlit : 'no',
                            rate_day_excess                 :      invoiceData.rate_day_excess ? invoiceData.rate_day_excess :  0,
                            hour_threshold_limlit            :     invoiceData.hour_threshold_limlit ? invoiceData.hour_threshold_limlit :  0,
                            rate_hour_excess                :      invoiceData.rate_hour_excess ? invoiceData.rate_hour_excess :  0,
                            hr_excess_applicable            :      invoiceData.hr_excess_applicable ? invoiceData.hr_excess_applicable :  "no",
                            km_threshold_limlit              :     invoiceData.km_threshold_limlit ? invoiceData.km_threshold_limlit :   0,
                            rate_km_excess                  :      invoiceData.rate_km_excess ? invoiceData.rate_km_excess :   0,
                            km_excess_applicable            :      invoiceData.km_excess_applicable ? invoiceData.km_excess_applicable :  "no",
                            opc_threshold_limlit             :     invoiceData.opc_threshold_limlit ? invoiceData.opc_threshold_limlit :   0,
                            rate_opc_excess                 :      invoiceData.rate_opc_excess ? invoiceData.rate_opc_excess :   0,
                            opc_excess_applicable           :      invoiceData.opc_excess_applicable ? invoiceData.opc_excess_applicable :  "no",
                            bd_excess_applicable            :      invoiceData.bd_excess_applicable ? invoiceData.bd_excess_applicable :  "no",
                            type_downtime                   :      invoiceData.type_downtime ? invoiceData.type_downtime :    'bd',
                            downtime_threshold_limlit        :     invoiceData.downtime_threshold_limlit ? invoiceData.downtime_threshold_limlit :   '',
                            downtime_calculation_criteria   :      invoiceData.downtime_calculation_criteria ? invoiceData.downtime_calculation_criteria :  'fixed',
                            rate_downtime_excess            :      invoiceData.rate_downtime_excess ? invoiceData.rate_downtime_excess :    0,
                            idle_days_applicable            :      invoiceData.idle_days_applicable ? invoiceData.idle_days_applicable :  "no",
                            idle_days_limlit                 :     invoiceData.idle_days_limlit ? invoiceData.idle_days_limlit :  0,
                            rate_idle_days                  :      invoiceData.rate_idle_days ? invoiceData.rate_idle_days :  0,
                            weekly_off_applicable           :      invoiceData.weekly_off_applicable ? invoiceData.weekly_off_applicable :  "no",
                            weekly_off_criteria             :      invoiceData.weekly_off_criteria ? invoiceData.weekly_off_criteria :  "no",
                            weekly_off_day                  :      invoiceData.weekly_off_day ? invoiceData.weekly_off_day :   [],
                            rate_weekly_off                 :      invoiceData.rate_weekly_off ? invoiceData.rate_weekly_off :  0,
                            weekly_off_idle_days_adjust     :      invoiceData.weekly_off_idle_days_adjust ? invoiceData.weekly_off_idle_days_adjust :  "no",
                            fuel_consuption_applicable      :      invoiceData.fuel_consuption_applicable ? invoiceData.fuel_consuption_applicable :  "no",
                            fuel_consuption_limlit           :     invoiceData.fuel_consuption_limlit ? invoiceData.fuel_consuption_limlit :  0,
                            fuel_rate                       :      invoiceData.fuel_rate ? invoiceData.fuel_rate :  0,
                            fuel_economy_applicable         :      invoiceData.fuel_economy_applicable ? invoiceData.fuel_economy_applicable :  "no",
                            fuel_economy_limlit              :     invoiceData.fuel_economy_limlit ? invoiceData.fuel_economy_limlit :  0,
                            fuel_economy_rate               :      invoiceData.fuel_economy_rate ? invoiceData.fuel_economy_rate :  0,
                            productivity_applicable         :      invoiceData.productivity_applicable ? invoiceData.productivity_applicable :  "no",
                            othercharges_applicable         :      invoiceData.othercharges_applicable ? invoiceData.othercharges_applicable :  "no",
     
                            },
                            selectedAssetType         :      invoiceData.asset_type ? invoiceData.asset_type : '',
                            weekCriteria              :     weekCriteria,
                            nextStep                  :     true,
                            invoiceEditDetail         :     invoiceData,
                            allProductivityRows       :     invoiceData.productivity_applicable == "yes" && invoiceData.productivity_meter.length > 0 ? invoiceData.productivity_meter.map(pm => {return({activity : pm.activity,material: pm.material,unit: pm.unit,attachment : pm.attachment,rate:pm.rate})}) : [],
                            allOtherChargesRows       :     invoiceData.othercharges_applicable == "yes" && invoiceData.other_charges.length > 0 ? invoiceData.other_charges.map(oc => {return({name : oc.name,charges : oc.charge})}) : []
                        },()=> {
                            
                        })

                    }

                    this.setState({
                        form_loading : false,
                        // weekCriteria       :     [{value:"day",label : "Days"},{value:"hour",label:"Hours"},{value:"km",label:"KM"},{value:"opc",label:"Operation Cycle"}],
                    })
                }) 
            });
        }
    }

    //***********************ON ASSET TYPE CHANGE********************** */
    onAssetTypeChange               =       (selectedOption)            =>      {
        if(selectedOption && selectedOption.asset_type){
             let weekCriteria = [];
              let asset_type_meter = [{value : selectedOption.asset_type.hour_meter,label : "hour"},{value : selectedOption.asset_type.odo_meter,label : "km"},{value : selectedOption.asset_type.operation_cycle,label : "opc"}]
              weekCriteria = this.state.weekCriteria.filter((elem) => !asset_type_meter.find(({ label,value }) => elem.value == label && value == "no"));
              this.setState({selectedAssetType : selectedOption.asset_type,weekCriteria : weekCriteria})
           
        }
    }
  
    closeEvent      =       ()          =>      {
        if(!this.state.invoiceEditDetail){
            this.props.history.push('/invoice_rule')
        }else{
            this.props.history.push({ pathname: `/invoice_rule`, state: { invoiceId: this.state.invoiceEditDetail.id } })
        }
       
        
    }

    addNewProductivityRow                           =   ()  =>  {
        this.setState({allProductivityRows : [...this.state.allProductivityRows,{activity : '',material: '',unit: '',attachment : '',rate:''}]});
    }

    removeProductivityRow                           =   (k) =>  {
        let allRows                 =   this.state.allProductivityRows;
        
            allRows.splice(k, 1);
            this.setState({allProductivityRows : allRows});
        
    }

    addNewChargesRow                           =   ()  =>  {
        this.setState({allOtherChargesRows : [...this.state.allOtherChargesRows,{name : '',charges: ''}] });
    }

   
    removeChargesRow                           =   (k) =>  {
        let allRows                 =   this.state.allOtherChargesRows;
        
            allRows.splice(k, 1);
            this.setState({allOtherChargesRows : allRows});
        
    }

    addInvoiceRuleFormSubmitHandler         =       (e,saveForm=false)         =>      {
       e &&  e.preventDefault()
      let formData =  {...this.state.addInvoiceRuleForm,
                       productivity : this.state.allProductivityRows.filter(a => a.activity !== '' ? true : false),
                       other_chargers: this.state.allOtherChargesRows.filter(o => o.name !== '' ? true : false)}
     
        if (saveForm == true) {
           if(this.state.invoiceEditDetail){
            this.setState({ saveFormSubmitting : true });
            HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/invoice_rule', this.props.access_token, {}, { ...formData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.closeEvent()
            }).then(() => this.setState({ saveFormSubmitting : false }));
           }else{
            this.setState({ saveFormSubmitting : true });
            HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/invoice_rule', this.props.access_token, {}, { ...formData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.closeEvent()
            }).then(() => this.setState({ saveFormSubmitting : false }));
           }
        }
  
    }

    goToNextStep        =       (e)      =>      {
      e && e.preventDefault()
        let showErrors                  =   [];
        let addInvoiceRuleForm          =    this.state.addInvoiceRuleForm;
        
        if(addInvoiceRuleForm.name == '') {
            showErrors                      =   [...showErrors , 'Please enter Invoice Rule Name.'];
        }
        if(addInvoiceRuleForm.rule_number == '') {
            showErrors                      =   [...showErrors , 'Please enter Invoice Rule Number.'];
        }
        if(addInvoiceRuleForm.asset_type_id == '') {
            showErrors                      =   [...showErrors , 'Please select Asset Type.'];
        }
        if(addInvoiceRuleForm.rate == null) {
            showErrors                      =   [...showErrors , 'Please enter  Basic Rate.'];
        }
        if(addInvoiceRuleForm.days == null) {
            showErrors                      =   [...showErrors , 'Please enter  Days.'];
        }

        if(showErrors.length > 0) {
            toast.error(showErrors[0], {position: toast.POSITION.TOP_RIGHT});
        }else{
            this.setState({nextStep : true})
        }
    }

    dayRateChange       =   ()      =>      {
        let rate = this.state.addInvoiceRuleForm.rate;
        let days = this.state.addInvoiceRuleForm.days;
        let rate_day_excess = 0;
        if(days && rate > 0){
             rate_day_excess = rate/days;
        }
        this.setState({
            addInvoiceRuleForm          :   {...this.state.addInvoiceRuleForm,
                rate_day_excess         :   parseFloat(rate_day_excess).toFixed(2),
                day_threshold_limlit     :  days,
            }
        })
       
    }

    hourRateChange       =   ()      =>      {
        let rate = this.state.addInvoiceRuleForm.rate;
        let hours = this.state.addInvoiceRuleForm.hours;
        let rate_hour_excess = 0;
        if(hours && rate > 0){
             rate_hour_excess = rate/hours;
        }
        this.setState({
            addInvoiceRuleForm          :   {...this.state.addInvoiceRuleForm,
                rate_hour_excess         :   parseFloat(rate_hour_excess).toFixed(2),
                hour_threshold_limlit     :   parseFloat(hours).toFixed(2),
            }
        })
       
    }

    kmRateChange       =   ()      =>      {
        let rate = this.state.addInvoiceRuleForm.rate;
        let km = this.state.addInvoiceRuleForm.km;
        let rate_km_excess = 0;
        if(km && rate > 0){
             rate_km_excess = rate/km;
        }
        this.setState({
            addInvoiceRuleForm          :   {...this.state.addInvoiceRuleForm,
                rate_km_excess         :   parseFloat(rate_km_excess).toFixed(2),
                km_threshold_limlit     :   parseFloat(km).toFixed(2),
            }
        })
       
    }

    opcRateChange       =   ()      =>      {
        let rate = this.state.addInvoiceRuleForm.rate;
        let opc = this.state.addInvoiceRuleForm.opc;
        let rate_opc_excess = 0;
        if(opc && rate > 0){
             rate_opc_excess = rate/opc;
        }
        this.setState({
            addInvoiceRuleForm          :   {...this.state.addInvoiceRuleForm,
                rate_opc_excess         :   parseFloat(rate_opc_excess).toFixed(2),
                opc_threshold_limlit     :   parseFloat(opc).toFixed(2),
            }
        })
       
    }

     createOtherChargesHandler                 =        (inputText,key)            =>       {
        const newValue = { value: inputText.toLowerCase(), label: inputText};
        this.setState({otherInvoiceCharges : [...this.state.otherInvoiceCharges,newValue]});
        let obj = {};
        obj["value"] = newValue.value;
        obj["label"] = newValue.label;
        let allOtherChargesRows         =   this.state.allOtherChargesRows;
         allOtherChargesRows[key] = {
             ...allOtherChargesRows[key],
             name: newValue.value,
         };
         this.setState({allOtherChargesRows : allOtherChargesRows})
    }

    saveInvoiceRuleFormJsx             =   ()  =>  {
      
        return (<form className="bg-white p-3" onSubmit={this.addInvoiceRuleFormSubmitHandler} id="invoiceRuleForm">
            <div className="tab_content_wrapper" >
                <span className="content_heading">Basic Details </span>
            </div>
            <div className="row align-items-center my-2 form-required">
                <div className="col-sm-2">
                    <label className="form-label require">Invoice Rule Name</label>
                </div>
                <div className='col-sm-4'>
                    <input
                        name="name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addInvoiceRuleForm.name}
                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                        placeholder="Enter Rule Name"
                        required={true}
                        minlength={2}
                        maxlength={150}
                    />
                </div>

            </div>

            <div className="row align-items-center my-2">
                <div className="col-sm-2 ">
                    <label className="form-label require ">Rule Number</label>
                </div>
                <div className='col-sm-4'>
                    <input
                        name="rule_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addInvoiceRuleForm.rule_number}
                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                        placeholder="Enter Rule Number"
                        required={true}
                        minlength={2}
                        maxlength={150}
                    />
                </div>
                <div className="col-sm-2 text-center">
                    <label className="form-label require">Asset Type</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addInvoiceRuleForm', 'asset_type_id');
                            this.onAssetTypeChange(selectedOption);
                        }}
                        options={this.state.allAssetTypes}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.invoiceEditDetail ? true : false}
                        value={this.state.allAssetTypes.find(m => m.value == this.state.addInvoiceRuleForm.asset_type_id)}
                        placeholder="Please Select Asset Type"
                    />
                </div>
            </div>
            <div className="tab_content_wrapper" >
                <span className="content_heading">Rate </span>
            </div>
            <div className="row align-items-center my-2">
                <div className="col-sm-2">
                    <label className="form-label require">Basic Rate</label>
                </div>
                <div className='col-sm-4'>
                    <input
                        name="rate"
                        type="number"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addInvoiceRuleForm.rate}
                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                        placeholder="Enter Basic Rate"
                        required={true}
                    />
                </div>

                <div className="col-sm-2 text-center">
                    <label className="form-label require">Days</label>
                </div>
                <div className='col-sm-4'>
                    <input
                        name="days"
                        type="number"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addInvoiceRuleForm.days}
                        onChange={(e) =>
                            this.setState({
                                addInvoiceRuleForm: {
                                    ...this.state.addInvoiceRuleForm,
                                    days: e.target && e.target.value ? e.target.value : null,
                                    day_threshold_limlit: e.target && e.target.value ? e.target.value : null
                                }
                            }, () => this.dayRateChange())
                        }
                        placeholder="Enter Days"
                        required={true}
                        disabled={this.state.addInvoiceRuleForm.month_calculation_applicable == "yes"}
                    />

                </div>
            </div>
            <div className='row'>
                <div className='offset-8 col-sm-4 '>
                    <div className="form-check form-check-inline ">
                        <input
                            name="month_calculation_applicable"
                            type="checkbox"
                            value={this.state.addInvoiceRuleForm.month_calculation_applicable == "yes" ? "no" : "yes"}
                            onChange={(e) => {
                                // this.formInputHandler(e, 'addInvoiceRuleForm');
                                if (e.target.checked == true) {
                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, days: 31, month_calculation_applicable: "yes", day_threshold_limlit: 31 } }, () => this.dayRateChange())
                                } else {
                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, month_calculation_applicable: "no" } })
                                }
                            }}
                            className="form-check-input"
                            id="month_calculation_applicable"
                            checked={this.state.addInvoiceRuleForm.month_calculation_applicable == "yes"}
                        />
                        <label className="form-check-label" htmlFor="month_calculation_applicable">
                            Adjust Billable Days as per Calendar Month
                        </label>
                    </div>
                </div>
                <div className="row align-items-center mt-1 mb-3 pr0">
                    {this.state.selectedAssetType && this.state.selectedAssetType.hour_meter == "yes"
                        ? <Ax>
                            <div className="col-sm-2">
                                <label className="form-label require">Hours</label>
                            </div>
                            <div className='col-sm-4 pr0'>
                                <input
                                    name="hours"
                                    type="number"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addInvoiceRuleForm.hours}
                                    onChange={(e) =>
                                        this.setState({
                                            addInvoiceRuleForm: {
                                                ...this.state.addInvoiceRuleForm,
                                                hours: e.target && e.target.value ? e.target.value : null,
                                                hour_threshold_limlit: e.target && e.target.value ? e.target.value : null
                                            }
                                        }, () => this.hourRateChange())
                                    }

                                    placeholder="Enter Hours"
                                    required={true}
                                />
                            </div>
                        </Ax>
                        : null}
                    {this.state.selectedAssetType && this.state.selectedAssetType.odo_meter == "yes"
                        ? <Ax>
                            <div className="col-sm-2 text-center">
                                <label className="form-label require">KM</label>
                            </div>
                            <div className='col-sm-4'>
                                <input
                                    name="km"
                                    type="number"
                                    className="form-control"
                                    autoComplete="off"
                                    value={this.state.addInvoiceRuleForm.km}
                                    onChange={(e) =>
                                        this.setState({
                                            addInvoiceRuleForm: {
                                                ...this.state.addInvoiceRuleForm,
                                                km: e.target && e.target.value ? e.target.value : null,
                                                km_threshold_limlit: e.target && e.target.value ? e.target.value : null
                                            }
                                        }, () => this.kmRateChange())
                                    }
                                    placeholder="Enter KM"
                                    required={true}
                                />
                            </div>
                        </Ax>
                        : null}

                </div>

                {this.state.selectedAssetType && this.state.selectedAssetType.operation_cycle == "yes"
                    ? <div className="row align-items-center my-2">
                        <div className="col-sm-2">
                            <label className="form-label require">Operation Cycle</label>
                        </div>
                        <div className='col-sm-4'>
                            <input
                                name="opc"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                value={this.state.addInvoiceRuleForm.opc}
                                onChange={(e) =>
                                    this.setState({
                                        addInvoiceRuleForm: {
                                            ...this.state.addInvoiceRuleForm,
                                            opc: e.target && e.target.value ? e.target.value : null,
                                            opc_threshold_limlit: e.target && e.target.value ? e.target.value : null
                                        }
                                    }, () => this.opcRateChange())
                                }
                                placeholder="Enter Operation Cycle"
                                required={true}
                            />
                        </div>
                    </div>
                    : null}



            </div>

            {this.state.nextStep == true
                ? <div>
                    <div className="tab_content_wrapper" >
                        <span className="content_heading">Additions </span>
                    </div>
                    <div className='counterlog my-2 bg-light'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="tab_content_wrapper" >
                                    <span className="content_heading">Rate per Day </span>
                                </div>
                                <div className="row  my-2">

                                    <div className='col-sm-6'>
                                        <div>
                                            <label className="form-label require ">Day Threshold Limit</label>
                                        </div>
                                        <input
                                            name="day_threshold_limlit"
                                            type="number"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addInvoiceRuleForm.day_threshold_limlit}
                                            required={true}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <div>
                                            <label className="form-label require">Rate For Excess</label>
                                        </div>
                                        <input
                                            name="rate_day_excess"
                                            type="number"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addInvoiceRuleForm.rate_day_excess}
                                            required={true}
                                            onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                {this.state.selectedAssetType && this.state.selectedAssetType.hour_meter == "yes"
                                    ? <Ax>
                                        <hr />
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Rate per Hour </span>
                                            <div className="form-check form-switch">
                                                <input
                                                    name="hr_excess_applicablee"
                                                    className={this.state.addInvoiceRuleForm.hr_excess_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                                    type="checkbox"
                                                    value={this.state.addInvoiceRuleForm.hr_excess_applicable == "yes" ? "no" : "yes"}
                                                    onChange={(e) => {

                                                        if (e.target.checked == true) {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, hr_excess_applicable: "yes" } })
                                                        } else {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, hr_excess_applicable: "no" } })
                                                        }
                                                    }}
                                                    checked={this.state.addInvoiceRuleForm.hr_excess_applicable == "yes"}
                                                    id="ratePerHour" />
                                                <label className="form-check-label mx-1" for="ratePerHour">{this.state.addInvoiceRuleForm.hr_excess_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                            </div>
                                        </div>
                                        {this.state.addInvoiceRuleForm.hr_excess_applicable == "yes"
                                            ? <div className="row align-items-center my-2">

                                                <div className='col-sm-3'>
                                                    <div>
                                                        <label className="form-label">Hour Threshold Limit</label>
                                                    </div>
                                                    <input
                                                        name="hour_threshold_limlit"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.hour_threshold_limlit}
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className="col-sm-3">

                                                    <div>
                                                        <label className="form-label">Rate For Excess</label>
                                                    </div>
                                                    <input
                                                        name="rate_hour_excess"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.rate_hour_excess}
                                                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                    />
                                                </div>
                                            </div>
                                            : null}
                                    </Ax>
                                    : null}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                {this.state.selectedAssetType && this.state.selectedAssetType.odo_meter == "yes"
                                    ? <Ax>
                                        <hr />
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Rate per KM </span>
                                            <div className="form-check form-switch">
                                                <input
                                                    name="hr_excess_applicablee"
                                                    className={this.state.addInvoiceRuleForm.km_excess_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                                    type="checkbox"
                                                    value={this.state.addInvoiceRuleForm.km_excess_applicable == "yes" ? "no" : "yes"}
                                                    onChange={(e) => {

                                                        if (e.target.checked == true) {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, km_excess_applicable: "yes" } })
                                                        } else {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, km_excess_applicable: "no" } })
                                                        }
                                                    }}
                                                    checked={this.state.addInvoiceRuleForm.km_excess_applicable == "yes"}
                                                    id="ratePerKM" />
                                                <label className="form-check-label mx-1" for="ratePerKM">{this.state.addInvoiceRuleForm.km_excess_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                            </div>
                                        </div>
                                        {this.state.addInvoiceRuleForm.km_excess_applicable == "yes"
                                            ? <div className="row align-items-center my-2">

                                                <div className='col-sm-3'>
                                                    <div>
                                                        <label className="form-label ">KM Threshold Limit</label>
                                                    </div>
                                                    <input
                                                        name="km_threshold_limlit"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.km_threshold_limlit}
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className="col-sm-3">

                                                    <div>
                                                        <label className="form-label">Rate For Excess</label>
                                                    </div>
                                                    <input
                                                        name="rate_km_excess"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.rate_km_excess}
                                                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                    />
                                                </div>
                                            </div>
                                            : null}
                                    </Ax>
                                    : null}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                {this.state.selectedAssetType && this.state.selectedAssetType.operation_cycle == "yes"
                                    ? <Ax>
                                        <hr />
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Rate per Operation Cycle </span>
                                            <div className="form-check form-switch">
                                                <input
                                                    name="hr_excess_applicablee"
                                                    className={this.state.addInvoiceRuleForm.opc_excess_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                                    type="checkbox"
                                                    value={this.state.addInvoiceRuleForm.opc_excess_applicable == "yes" ? "no" : "yes"}
                                                    onChange={(e) => {

                                                        if (e.target.checked == true) {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, opc_excess_applicable: "yes" } })
                                                        } else {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, opc_excess_applicable: "no" } })
                                                        }
                                                    }}
                                                    checked={this.state.addInvoiceRuleForm.opc_excess_applicable == "yes"}
                                                    id="ratePerOperationCycle" />
                                                <label className="form-check-label mx-1" for="ratePerOperationCycle">{this.state.addInvoiceRuleForm.opc_excess_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                            </div>
                                        </div>
                                        {this.state.addInvoiceRuleForm.opc_excess_applicable == "yes"
                                            ? <div className="row align-items-center my-2">

                                                <div className='col-sm-3'>
                                                    <div>
                                                        <label className="form-label ">Operation Cycle Threshold Limit</label>
                                                    </div>
                                                    <input
                                                        name="opc_threshold_limlit"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.opc_threshold_limlit}
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className="col-sm-3">

                                                    <div>
                                                        <label className="form-label">Rate For Excess</label>
                                                    </div>
                                                    <input
                                                        name="rate_opc_excess"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.rate_opc_excess}
                                                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                    />
                                                </div>
                                            </div>
                                            : null}
                                    </Ax>
                                    : null}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>

                                <hr />
                                <div className="tab_content_wrapper" >
                                    <span className="content_heading">Maintenance Days / Idle Days </span>
                                    <div className="form-check form-switch">
                                        <input
                                            name="hr_excess_applicablee"
                                            className={this.state.addInvoiceRuleForm.idle_days_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                            type="checkbox"
                                            value={this.state.addInvoiceRuleForm.idle_days_applicable == "yes" ? "no" : "yes"}
                                            onChange={(e) => {

                                                if (e.target.checked == true) {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, idle_days_applicable: "yes", weekly_off_applicable: "no" } })
                                                } else {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, idle_days_applicable: "no" } })
                                                }
                                            }}
                                            checked={this.state.addInvoiceRuleForm.idle_days_applicable == "yes"}
                                            id="idleDays" />
                                        <label className="form-check-label mx-1" for="idleDays">{this.state.addInvoiceRuleForm.idle_days_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                    </div>
                                </div>
                                {this.state.addInvoiceRuleForm.idle_days_applicable == "yes"
                                    ? <div className="row align-items-center my-2">

                                        <div className='col-sm-3'>
                                            <div>
                                                <label className="form-label ">Minimum Idle Days</label>
                                            </div>
                                            <input
                                                name="idle_days_limlit"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addInvoiceRuleForm.idle_days_limlit}
                                                onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                            />
                                        </div>

                                        <div className="col-sm-3">

                                            <div>
                                                <label className="form-label">Rate For Excess</label>
                                            </div>
                                            <input
                                                name="rate_idle_days"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addInvoiceRuleForm.rate_idle_days}
                                                onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                            />
                                        </div>
                                    </div>
                                    : null}
                                <hr />
                                <div className="tab_content_wrapper" >
                                    <span className="content_heading">Weekly Off </span>
                                    <div className="form-check form-switch">
                                        <input
                                            name="hr_excess_applicablee"
                                            className={this.state.addInvoiceRuleForm.weekly_off_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                            type="checkbox"
                                            value={this.state.addInvoiceRuleForm.weekly_off_applicable == "yes" ? "no" : "yes"}
                                            onChange={(e) => {

                                                if (e.target.checked == true) {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, idle_days_applicable: "no", weekly_off_applicable: "yes" } })
                                                } else {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, weekly_off_applicable: "no" } })
                                                }
                                            }}
                                            checked={this.state.addInvoiceRuleForm.weekly_off_applicable == "yes"}
                                            id="weeklyOff" />
                                        <label className="form-check-label mx-1" for="weeklyOff">{this.state.addInvoiceRuleForm.weekly_off_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                    </div>
                                </div>
                                {this.state.addInvoiceRuleForm.weekly_off_applicable == "yes"
                                    ? <div className="row align-items-center my-2">

                                        <div className='col-sm-3'>
                                            <div>
                                                <label className="form-label ">Days of the Week</label>
                                            </div>
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addInvoiceRuleForm', 'weekly_off_day');
                                                }}
                                                options={this.state.allWeekDays}
                                                isMulti={true}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allWeekDays.filter(w => this.state.addInvoiceRuleForm.weekly_off_day.includes(w.value))}
                                                placeholder="Please Select Days of the Week"
                                            />
                                        </div>

                                        <div className="col-sm-3">

                                            <div>
                                                <label className="form-label">Calculation Criteria</label>
                                            </div>
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addInvoiceRuleForm', 'weekly_off_criteria');
                                                }}
                                                options={this.state.weekCriteria}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.weekCriteria.find(w => this.state.addInvoiceRuleForm.weekly_off_criteria == w.value)}
                                                placeholder="Please Select Criteria"
                                            />
                                        </div>
                                        <div className="col-sm-3">

                                            <div>
                                                <label className="form-label">Rate for Each Weekly Off</label>
                                            </div>
                                            <input
                                                name="rate_weekly_off"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addInvoiceRuleForm.rate_weekly_off}
                                                onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                            />
                                        </div>
                                        <div className='col-sm-3 pr0'>
                                            <div className="form-check form-check-inline ">
                                                <input
                                                    name="weekly_off_idle_days_adjust"
                                                    type="checkbox"
                                                    value={this.state.addInvoiceRuleForm.weekly_off_idle_days_adjust == "yes" ? "no" : "yes"}
                                                    onChange={(e) => {
                                                        // this.formInputHandler(e, 'addInvoiceRuleForm');
                                                        if (e.target.checked == true) {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, weekly_off_idle_days_adjust: "yes" } })
                                                        } else {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, weekly_off_idle_days_adjust: "no" } })
                                                        }
                                                    }}
                                                    className="form-check-input"
                                                    id="weekly_off_idle"
                                                    checked={this.state.addInvoiceRuleForm.weekly_off_idle_days_adjust == "yes"}
                                                />
                                                <label className="form-check-label" htmlFor="weekly_off_idle">
                                                    Adjusting Weekly off against other idle days
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                <hr />
                                <div className="tab_content_wrapper" >
                                    <span className="content_heading">Productivity </span>
                                    <div className="form-check form-switch">
                                        <input
                                            name="productivity_applicable"
                                            className={this.state.addInvoiceRuleForm.productivity_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                            type="checkbox"
                                            value={this.state.addInvoiceRuleForm.productivity_applicable == "yes" ? "no" : "yes"}
                                            onChange={(e) => {

                                                if (e.target.checked == true) {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, productivity_applicable: "yes", } })
                                                } else {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, productivity_applicable: "no" } })
                                                }
                                            }}
                                            checked={this.state.addInvoiceRuleForm.productivity_applicable == "yes"}
                                            id="productivityApp" />
                                        <label className="form-check-label mx-1" for="productivityApp">{this.state.addInvoiceRuleForm.productivity_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                    </div>
                                </div>
                                {this.state.addInvoiceRuleForm.productivity_applicable == "yes" && this.state.allProductivityRows.map((p, k) => {

                                    return (<Ax>
                                        <div className="row align-items-center my-2">

                                            <div className='col-sm-3'>
                                                <div>
                                                    <label className="form-label ">Activity type</label>
                                                </div>
                                                <TapSelect
                                                    changeEvent={(selectedOption, e) => this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'activity', k, 'allProductivityRows')}
                                                    options={this.state.allActivities}
                                                    isSearchable={false}
                                                    value={this.state.allActivities.find(s => s.value == this.state.allProductivityRows[k].activity)}
                                                    placeholder="Select Activity"
                                                />
                                            </div>

                                            <div className="col-sm-2">
                                                <div>
                                                    <label className="form-label ">Material</label>
                                                </div>
                                                <TapSelect
                                                    changeEvent={(selectedOption, e) => this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'material', k, 'allProductivityRows')}
                                                    options={this.state.allMaterials}
                                                    isSearchable={false}
                                                    value={this.state.allMaterials.find(s => s.value == this.state.allProductivityRows[k].material)}
                                                    placeholder="Select Material"
                                                />

                                            </div>
                                            <div className="col-sm-2">
                                                <div>
                                                    <label className="form-label ">Measuring Unit</label>
                                                </div>
                                                <TapSelect
                                                    changeEvent={(selectedOption, e) => this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'unit', k, 'allProductivityRows')}
                                                    options={this.state.allProductionUnits.filter(pu => pu.activity_id == this.state.allProductivityRows[k].activity)}
                                                    isSearchable={false}
                                                    value={this.state.allProductionUnits.find(s => s.value == this.state.allProductivityRows[k].unit)}
                                                    placeholder="Select Units"
                                                />


                                            </div>
                                            <div className='col-sm-2'>
                                                <div>
                                                    <label className="form-label ">Attachment</label>
                                                </div>
                                                <TapSelect
                                                    changeEvent={(selectedOption, e) => this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'attachment', k, 'allProductivityRows')}
                                                    options={this.state.allAttachments}
                                                    isSearchable={false}
                                                    value={this.state.allAttachments.find(s => s.value == this.state.allProductivityRows[k].attachment)}
                                                    placeholder="Select Attachment"
                                                />
                                            </div>
                                            <div className='col-sm-2'>
                                                <div>
                                                    <label className="form-label ">Rate</label>
                                                </div>
                                                <input
                                                    name="rate"
                                                    type="number"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.allProductivityRows[k].rate}
                                                    onChange={(e) => this.dynamicInputHandlerByKey(e,  k, 'allProductivityRows')}
                                                />
                                            </div>
                                            <div className='col-sm-1 text-center'>
                                                <div>
                                                    <label className="form-label "></label>
                                                </div>
                                                <button type="button" className="btn btn-secondary mt10" onClick={() => this.removeProductivityRow(k)}>
                                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                                                </button>
                                            </div>

                                        </div>

                                    </Ax>)
                                })}
                                {this.state.addInvoiceRuleForm.productivity_applicable == "yes"
                                    ? <div className="col-sm-12 text-end">

                                        <a role="button" onClick={this.addNewProductivityRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Productivity </a>
                                    </div>
                                    : null}
                                <hr />
                                <div className="tab_content_wrapper" >
                                    <span className="content_heading">Other Charges </span>
                                    <div className="form-check form-switch">
                                        <input
                                            name="othercharges_applicable"
                                            className={this.state.addInvoiceRuleForm.othercharges_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                            type="checkbox"
                                            value={this.state.addInvoiceRuleForm.othercharges_applicable == "yes" ? "no" : "yes"}
                                            onChange={(e) => {

                                                if (e.target.checked == true) {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, othercharges_applicable: "yes", } })
                                                } else {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, othercharges_applicable: "no" } })
                                                }
                                            }}
                                            checked={this.state.addInvoiceRuleForm.othercharges_applicable == "yes"}
                                            id="chargesApp" />
                                        <label className="form-check-label mx-1" for="chargesApp">{this.state.addInvoiceRuleForm.othercharges_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                    </div>
                                </div>
                                {this.state.addInvoiceRuleForm.othercharges_applicable == "yes" && this.state.allOtherChargesRows.map((p, k) => {

                                    return (<Ax>
                                        <div className="row align-items-center my-2">

                                            <div className='col-sm-3'>
                                                <div>
                                                    <label className="form-label ">Charges For</label>
                                                </div>
                                                <CreatableSelect

                                                    value={this.state.otherInvoiceCharges.find(s => s.value == this.state.allOtherChargesRows[k].name)}
                                                    options={this.state.otherInvoiceCharges}
                                                    onChange={(selectedOption) => this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'name', k, 'allOtherChargesRows')}
                                                    onCreateOption={(inputText, callback) => this.createOtherChargesHandler(inputText, k, callback)}
                                                />

                                            </div>

                                            <div className="col-sm-3">
                                                <div>
                                                    <label className="form-label ">Rate</label>
                                                </div>
                                                <input
                                                    name="charges"
                                                    type="number"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.allOtherChargesRows[k].charges}
                                                    onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'allOtherChargesRows')}
                                                />

                                            </div>

                                            <div className='col-sm-1 text-center'>
                                                <div>
                                                    <label className="form-label "></label>
                                                </div>
                                                <button type="button" className="btn btn-secondary mt10" onClick={() => this.removeChargesRow(k)}>
                                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                                                </button>
                                            </div>

                                        </div>

                                    </Ax>)
                                })}
                                {this.state.addInvoiceRuleForm.othercharges_applicable == "yes"
                                    ? <div className="col-sm-12 text-end">

                                        <a role="button" onClick={this.addNewChargesRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Other Charges </a>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="tab_content_wrapper" >
                        <span className="content_heading">Deductions</span>
                    </div>
                    <div className='counterlog my-2 bg-light'>
                        <div className='row'>
                            <div className='col-sm-12'>

                                <div className="tab_content_wrapper" >
                                    <span className="content_heading">Breakdown </span>
                                    <div className="form-check form-switch">
                                        <input
                                            name="bd_excess_applicable"
                                            className={this.state.addInvoiceRuleForm.bd_excess_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                            type="checkbox"
                                            value={this.state.addInvoiceRuleForm.bd_excess_applicable == "yes" ? "no" : "yes"}
                                            onChange={(e) => {

                                                if (e.target.checked == true) {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, bd_excess_applicable: "yes" } })
                                                } else {
                                                    this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, bd_excess_applicable: "no" } })
                                                }
                                            }}
                                            checked={this.state.addInvoiceRuleForm.bd_excess_applicable == "yes"}
                                            id="bdApplicable" />
                                        <label className="form-check-label mx-1" for="bdApplicable">{this.state.addInvoiceRuleForm.bd_excess_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                    </div>
                                </div>
                                {this.state.addInvoiceRuleForm.bd_excess_applicable == "yes"
                                    ? <div className="row align-items-center my-2">

                                        <div className='col-sm-3'>
                                            <div>
                                                <label className="form-label">Type of Downtime</label>
                                            </div>
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addInvoiceRuleForm', 'type_downtime');
                                                }}
                                                options={this.state.allDowntimeTypes}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allDowntimeTypes.find(m => m.value == this.state.addInvoiceRuleForm.type_downtime)}
                                                placeholder="Please Select"
                                            />
                                        </div>

                                        <div className="col-sm-3">

                                            <div>
                                                <label className="form-label">Threshold Limit (Hours)</label>
                                            </div>
                                            <input
                                                name="downtime_threshold_limlit"
                                                type="number"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addInvoiceRuleForm.downtime_threshold_limlit}
                                                onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                            />
                                        </div>
                                        <div className="col-sm-3">

                                            <div>
                                                <label className="form-label">Calculation Criteria</label>
                                            </div>
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addInvoiceRuleForm', 'downtime_calculation_criteria');
                                                }}
                                                options={this.state.calculationCriteriaType}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.calculationCriteriaType.find(m => m.value == this.state.addInvoiceRuleForm.downtime_calculation_criteria)}
                                                placeholder="Please Select"
                                            />
                                        </div>
                                        {this.state.addInvoiceRuleForm.downtime_calculation_criteria == "fixed"
                                            ? <div className="col-sm-3">

                                                <div>
                                                    <label className="form-label">Rate For Excess</label>
                                                </div>
                                                <input
                                                    name="rate_downtime_excess"
                                                    type="number"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.addInvoiceRuleForm.rate_downtime_excess}
                                                    onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                />
                                            </div>
                                            : null}
                                    </div>
                                    : null}
                                {this.state.selectedAssetType && this.state.selectedAssetType.hour_meter == "yes"
                                    ? <Ax>
                                        <hr />
                                        <div className="tab_content_wrapper my-2" >
                                            <span className="content_heading">Approved Fuel Consumption </span>
                                            <div className="form-check form-switch">
                                                <input
                                                    name="fuel_consuption_applicable"
                                                    className={this.state.addInvoiceRuleForm.fuel_consuption_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                                    type="checkbox"
                                                    value={this.state.addInvoiceRuleForm.fuel_consuption_applicable == "yes" ? "no" : "yes"}
                                                    onChange={(e) => {

                                                        if (e.target.checked == true) {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, fuel_consuption_applicable: "yes" } })
                                                        } else {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, fuel_consuption_applicable: "no" } })
                                                        }
                                                    }}
                                                    checked={this.state.addInvoiceRuleForm.fuel_consuption_applicable == "yes"}
                                                    id="fuelConsumptionApplicable" />
                                                <label className="form-check-label mx-1" for="fuelConsumptionApplicable">{this.state.addInvoiceRuleForm.fuel_consuption_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                            </div>

                                        </div>
                                        {this.state.addInvoiceRuleForm.fuel_consuption_applicable == "yes"
                                            ? <div className="row align-items-center my-2">

                                                <div className='col-sm-3'>
                                                    <div>
                                                        <label className="form-label ">Approved Fuel Consumption Limit (Litre/Hours)</label>
                                                    </div>
                                                    <input
                                                        name="fuel_consuption_limlit"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.fuel_consuption_limlit}
                                                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                    />
                                                </div>

                                                <div className="col-sm-3">

                                                    <div>
                                                        <label className="form-label">Fuel Rate for Calculation</label>
                                                    </div>
                                                    <input
                                                        name="fuel_rate"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.fuel_rate}
                                                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                    />
                                                </div>
                                            </div>
                                            : null}
                                    </Ax>
                                    : null}
                                {this.state.selectedAssetType && this.state.selectedAssetType.odo_meter == "yes"
                                    ? <Ax>
                                        <hr />
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Approved Fuel Economy</span>
                                            <div className="form-check form-switch">
                                                <input
                                                    name="fuel_consuption_applicable"
                                                    className={this.state.addInvoiceRuleForm.fuel_economy_applicable == "yes" ? 'btn-primary form-check-input' : 'btn-danger form-check-input'}
                                                    type="checkbox"
                                                    value={this.state.addInvoiceRuleForm.fuel_economy_applicable == "yes" ? "no" : "yes"}
                                                    onChange={(e) => {

                                                        if (e.target.checked == true) {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, fuel_economy_applicable: "yes" } })
                                                        } else {
                                                            this.setState({ addInvoiceRuleForm: { ...this.state.addInvoiceRuleForm, fuel_economy_applicable: "no" } })
                                                        }
                                                    }}
                                                    checked={this.state.addInvoiceRuleForm.fuel_economy_applicable == "yes"}
                                                    id="fuelEconomyApplicable" />
                                                <label className="form-check-label mx-1" for="fuelEconomyApplicable">{this.state.addInvoiceRuleForm.fuel_economy_applicable == "yes" ? "Applicable" : "Applicable"}</label>
                                            </div>

                                        </div>
                                        {this.state.addInvoiceRuleForm.fuel_economy_applicable == "yes"
                                            ? <div className="row align-items-center my-2">

                                                <div className='col-sm-3'>
                                                    <div>
                                                        <label className="form-label ">Approved Fuel Economy Limit (Km/Litre)</label>
                                                    </div>
                                                    <input
                                                        name="fuel_economy_limlit"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.fuel_economy_limlit}
                                                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                    />
                                                </div>

                                                <div className="col-sm-3">

                                                    <div>
                                                        <label className="form-label">Fuel Rate for Calculation</label>
                                                    </div>
                                                    <input
                                                        name="fuel_economy_rate"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.addInvoiceRuleForm.fuel_economy_rate}
                                                        onChange={(e) => this.formInputHandler(e, 'addInvoiceRuleForm')}
                                                    />
                                                </div>
                                            </div>
                                            : null}
                                    </Ax>
                                    : null}
                            </div>
                        </div>


                    </div>

                </div>
                : null}

            <div className="col-12 text-end fixed_footer">
                <button type="button" className="btn btn-secondary ms-2" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent}>Cancel</button>
                {this.state.nextStep == true ?
                    <button type="submit" className="btn btn-secondary ms-2" disabled={this.state.saveFormSubmitting} onClick={(e)=> this.addInvoiceRuleFormSubmitHandler(e,true)}>Save</button>
                    :
                    <button type="button" className="btn btn-primary ms-2" disabled={this.state.saveFormSubmitting} onClick={(e) => this.goToNextStep(e,false)}>Next</button>
                }
            </div>
        </form>);
    }


    render              =             ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Inventory Transfer Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-sm-12">
                    <h3>{this.state.invoiceEditDetail ? "Edit" : "New"} Invoice Rule {this.state.invoiceEditDetail ? ': ' + this.state.invoiceEditDetail.name : "New"}</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-sm-12 pl4">
                        {this.state.form_loading ? <Loader/>
                        : this.saveInvoiceRuleFormJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(InvoiceRuleAdd);