import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import { Modal } from 'bootstrap';
import TapIcon from '../../../../services/TapIcon';
import TapSelect from "../../../../components/ui/TapSelect";
import { ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import Loader from '../../../../components/ui/Loader/Loader';


class Setting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigForm      =   {
            ticket_expense                  :   'N',
            ticket_allocation               :   'N',
            ticket_consumption              :   'N',
            ticket_allow_tag                :   'N', 
            ticket_allow_employees          :   'N', 
            enable_ticket_mttr              :   'N',
            ticket_demand                   :   'N'
        };

        this.state                      =       {
            saveFormSubmitting          :       false,
            allAdditonalAttributes      :       [],
            generalConfigloading                :      false,
            general_config_list                 :      null,
            generalConfigForm                   :     {...this.initGeneralConfigForm},
            editFormSubmiting                   :      false,
            currentTaskKey                      :   ''
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';

    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
    }

    getGeneralSettingListing = () => {
        let params = {
            keys : [ 'ticket_expense','ticket_allocation','ticket_consumption', 'ticket_allow_tag','ticket_demand', 'ticket_allow_employees', 'enable_ticket_mttr'],
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ general_config_list: resp.data.data })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    editGeneralConfigurationInit = (key = '') => {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
              }
              
            this.setState({generalConfigForm : generalConfigForm,currentTaskKey : key})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...this.state.generalConfigForm} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    editMTTRConfigurationInit   =   (key = '') => {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
              }
              
            this.setState({generalConfigForm : generalConfigForm,currentTaskKey : key}, () => {
            })
        }
    }


    editGeneralConfigurationModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                            
                               {this.state.currentTaskKey  == "ticket_expense" && <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">Enable Expense</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_expense"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_expense == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_expense"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_expense == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                               {this.state.currentTaskKey == "ticket_allocation" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">Enable Allocation</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_allocation"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_allocation == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_allocation"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_allocation == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                                {this.state.currentTaskKey == "ticket_consumption" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">Enable Consumption</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_consumption"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_consumption == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_consumption"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_consumption == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                                {this.state.currentTaskKey == "ticket_allow_tag" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">Enable Workstation</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_allow_tag"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_allow_tag === "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_allow_tag"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_allow_tag == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                                {this.state.currentTaskKey == "enable_ticket_mttr" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">Enable MTTR</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="enable_ticket_mttr"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.enable_ticket_mttr === "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="enable_ticket_mttr"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.enable_ticket_mttr == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                                {this.state.currentTaskKey == "ticket_allow_employees" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">Enable Workstation</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_allow_employees"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_allow_employees === "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_allow_employees"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_allow_employees == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                                {this.state.currentTaskKey == "ticket_demand" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-6"><label className="form-label">Enable Item Demand</label></div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_demand"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_demand == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="ticket_demand"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.ticket_demand == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
  }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
        return (<table className="table table-hover table-bordered table-sm mt-2">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                    <th scope="col" style={{ width: "10%" }} >Action</th>
                </tr>
            </thead>
            <tbody>
                
                <tr className="text-center">
                    <td>1</td>
                    <td className="text-start">Enable Expense</td>
                    <td>{data && data.ticket_expense && data.ticket_expense == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('ticket_expense')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>2</td>
                    <td className="text-start">Enable Allocation</td>
                    <td>{data && data.ticket_allocation && data.ticket_allocation == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('ticket_allocation')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>3</td>
                    <td className="text-start">Enable Consumption</td>
                    <td>{data && data.ticket_consumption && data.ticket_consumption == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('ticket_consumption')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>4</td>
                    <td className="text-start">Enable Item Demand</td>
                    <td>{data && data.ticket_demand && data.ticket_demand == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('ticket_demand')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>5</td>
                    <td className="text-start">Enable Tags</td>
                    <td>{data && data.ticket_allow_tag && data.ticket_allow_tag == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('ticket_allow_tag')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>6</td>
                    <td className="text-start">Enable Employees</td>
                    <td>{data && data.ticket_allow_employees && data.ticket_allow_employees == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('ticket_allow_employees')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>           
                <tr className="text-center">
                    <td>7</td>
                    <td className="text-start">Enable MTTR</td>
                    <td>{data && data.enable_ticket_mttr && data.enable_ticket_mttr == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editMTTRConfigurationInit('enable_ticket_mttr')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>       
            </tbody>
        </table>)
    }

    render() {
      
        return (<div>

            <div className="p-1">
                <div className="tab_content_wrapper mt-2"><span className="content_heading">General Setting</span>
                </div>
                {this.state.generalConfigloading ? <Loader />
                    : this.generalConfigurationJsx()}
                {this.editGeneralConfigurationModalJsx()}
            </div>


            {this.editGeneralConfigurationModalJsx()}

        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(Setting);