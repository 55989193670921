// AdditionalAttributeForm.js

import React from 'react';
import TapSelect from '../../../components/ui/TapSelect';
import DatePicker from "react-datepicker";
import moment from 'moment';

class AdditionalAttributeForm extends React.Component {
    render() {
        const { additionalAttributes, formState, onAttributeChange,colSpan="col-sm-4", formSmall = false} = this.props;

        return (
            <>
                {additionalAttributes.map((attr, index) => (
                    <div key={index} className="row align-items-center my-1">
                        <label className={`col-sm-3 col-form-label col-form-label-sm text-capitalize ${attr.is_required === 'Y' ? 'require' : ''}`}>{attr.name}</label>
                        <div className={colSpan}>
                            {this.renderAttributeInput(attr, formState, onAttributeChange,formSmall)}
                        </div>
                    </div>
                ))}
            </>
        );
    }

    renderAttributeInput(attr, formState, onAttributeChange,formSmall) {
        const name = `additional_attributes_${attr.key}`;
        
        if (attr.type === 'text' || attr.type === 'numeric') {
            return (
                <input
                    name={name}
                    type={attr.type === 'text' ? 'text' : 'number'}
                    value={formState[name] || ''}
                    onChange={(e) => onAttributeChange(e.target.value, name)} // Use the onAttributeChange prop
                    className={formSmall ? "form-control form-control-sm" : "form-control"}
                    autoComplete="off"
                    placeholder={`Please enter ${attr.name}`}
                    required={attr.is_required=='Y'}
                />
            );
        } else if (attr.type === 'date') {
            return (
                <DatePicker
                    selected={formState[name] ? moment(formState[name]).toDate() : null}
                    name={name}
                    onChange={(value) => onAttributeChange(moment(value).format('YYYY-MM-DD'), name)} // Use the onAttributeChange prop
                    dateFormat="dd-MMM-yyyy"
                    className={formSmall ? "form-control form-control-sm" : "form-control"}
                    placeholderText={`Please Enter ${attr.name}`}
                    required={attr.is_required=='Y'}
                />
            );
        }else if (attr.type == 'datetime') {
            return(<DatePicker
                selected={formState[name] ? moment(formState[name]).toDate() : null}
                name={name}
                onChange={(value) => onAttributeChange(moment(value).format('YYYY-MM-DD HH:mm:ss'), name)}
                dateFormat="dd-MMM-yyyy hh:mm a"
                className={formSmall ? "form-control form-control-sm" : "form-control"}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
                timeIntervals={5}
                timeCaption="Time"
                autoComplete='off'
                scrollMonthYearDropdown
                placeholderText={`Please Enter ${attr.name}`}
                required={attr.is_required=='Y'}

            />)
        } else if (attr.type === 'dropdown') {
            const options = attr.options.map(option => ({ value: option, label: option }));
            
            return (
                <TapSelect
                    menuPlacement="top"
                    changeEvent={(selectedOption) => onAttributeChange(selectedOption ? selectedOption.value : null, name)} // Use the onAttributeChange prop
                    options={options}
                    isSearchable={true}
                    isClearable={true}
                    value={options.find(o => o.value == formState[name]) || null}
                    placeholder={`Please Select ${attr.name}`}
                    containerHeight={formSmall ? "30px" : undefined}
                    fontSize={formSmall ? "93%" : undefined}
                    required={attr.is_required=='Y'}
                />
            );
        }

        return null; // Return null for unsupported types
    }
}

export default AdditionalAttributeForm;
