import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import TapSelect from "../../../components/ui/TapSelect";
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Helmet } from 'react-helmet';

class WalletSumaryReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            search_wallet_ids                   :   [],
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
        };
        
        this.reportFilterFormLabel          =   {
            search_wallet_ids                   :   'Wallet : ',
            date_range                          :   'Date Range : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            allWallets                          :   [],
            all_periods                         :    [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null, 
            totalData                           :      0 , 
            reportCloseBtn                      :   false, 
            reportData                          :   null,                  
        };

        this.formDataUrl            =   IAM_API_BASE_URL_2 + '/report/wallet-summary/form_data';
        this.reportUrl              =   IAM_API_BASE_URL_2 + '/report/wallet-summary';
    }
    
    componentDidMount(){
       this.getWalletFormData()
    }
    
    getWalletFormData         =     ()   =>    {
        const report_name   =   'wallet_summary';
        const reportData    =   this.props?.report_listing.length > 0 ? this.props.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        });

        HttpAPICall.withAthorization('GET',  this.formDataUrl, this.props.access_token, {}, {}, (resp) => {
            this.setState({
                allWallets                  :           resp.data.wallets ? resp.data.wallets.map((wt) => { return {value: wt.id, label: wt.wallet_name}}) : [],
                all_periods                 :           resp.data.periods ? resp.data.periods : [],
                formDataLoaded              :           true
            })
            
           
            let ParamObject                 =   new URLSearchParams(this.props.location.search);
            
            if(ParamObject.get('date_range')) {
                let updateFilterForm           =   {date_range : ParamObject.get('date_range')};
                this.setState({reportFilterForm :   {...this.state.reportFilterForm, ...updateFilterForm}});
                this.reportFilterFormSubmit();
                this.reportFilterhandler(false)
            }else{
                this.reportFilterhandler()
            }
            
        })
    
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_wallet_ids') {
                        show_val            =   this.state.allWallets.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'date_range') {
                        show_val            = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({
            listing_loading: true, 
            listingData : [],
            totalData : null,
        });
        let params                      =   {page: page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                     :   respData.data,
                totalData                       :   respData.total,
                reportCloseBtn                  :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    

    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('walletForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    

    reportFitlerSearchClear                     =           ()           =>            {
        this.setState({
             formSearchedElems                  :     [],
             reportFilterForm                   :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
        }, () => {
            this.loadListingTblData(1);;
        });
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Wallet Summary</h3>
                <div className="text-end mt15">
                    {/* {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null} */}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button> 
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id='walletForm' className="bg-white collapse " aria-expanded="true" >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end ">Wallet</label>
                    <div className="col-sm-6">
                    <TapSelect
                            options={this.state.allWallets}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_wallet_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allWallets.filter(s => this.state.reportFilterForm.search_wallet_ids.includes(s.value))}
                            placeholder="Select Wallets"
                        />
                    </div>
                </div>
        
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require ">Select Period</label>
                    
                    <div className="col-md-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm    :   {...this.state.reportFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate}
                                });
                            }}
                            required={true}
                            menuPlacement='bottom'
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
         <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.reportFilterSearchClear} />
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {

        return (<Ax>
        
            <table className="table table-bordered   align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr className='text-end'>
                        <th className="text-center" style={{ width: "5%" }}>S.No</th>
                        <th className='text-start' style={{ width: "25%" }} >Wallet Name</th>
                        <th style={{ width: "15%" }}>Opening Balance</th>
                        <th style={{ width: "15%" }}>Total Debit</th>
                        <th style={{ width: "15%" }}>Total Credit</th>
                        <th style={{ width: "15%" }}>Closing Balance</th>
                        <th className='text-center' style={{ width: "10%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData.length > 0
                        ? (<Ax> {this.state.listingData.map((rowData, k) => {
                            let obj = { 'wallet_id': rowData.wallet.id, 'date_range': this.state.reportFilterForm.date_range }
                            return (<tr key={k} className="text-end">
                                <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + k : k}</td>
                                <td className='text-start'>{rowData.wallet && rowData.wallet.wallet_name ? rowData.wallet.wallet_name : "-"}</td>
                                <td>{rowData.balance && rowData.balance.opening_balance ? parseInt(rowData.balance.opening_balance) : "0"}</td>
                                <td>{rowData.balance && rowData.balance.total_cr ? parseInt(rowData.balance.total_cr) : "0"}</td>
                                <td>{rowData.balance && rowData.balance.total_dr ? parseInt(rowData.balance.total_dr) : "0"}</td>
                                <td>{rowData.balance && rowData.balance.closing_balance ? parseInt(rowData.balance.closing_balance) : "0"}</td>
                                <td className="link-primary text-center">
                                    <Link to={{ pathname: "/report/wallet_ledger", search: "?" + new URLSearchParams(obj).toString() }}>View Ledger</Link></td>
                            </tr>)
                        })
                        }</Ax>)
                        : (<tr><td className="text-center" colSpan={7}>No Record Found</td></tr>)
                    }


                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Wallet Summary - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                    {this.state.formDataLoaded
                    ? (<Ax>
                            {this.reportFilterFormJsx()}
                            {this.reportListingDisplayJsx()}
                        </Ax>)
                        : <Loader  />
                    }
                    {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(WalletSumaryReport);