import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { Modal } from 'bootstrap';
import ItemViewInList from '../includes/ItemViewInList';
import AppBaseComponent from '../../../components/AppBaseComponent';
import uuid from 'react-uuid';

class IndentDetailModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                      =       {
           indent_loading          :       false,
           indentDetail            :       null,
           indentTransId           :       null,
        };
        this.indentDetailModalId      =   uuid();
        this.warehouseIndentDetailUrl       =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/detail';

    }

    componentDidMount() {
        this.indentModal            =   new Modal(document.getElementById(this.indentDetailModalId), {keyboard: false, backdrop :false});
        // this.initScreen(this.props);
    }

   

//     componentWillReceiveProps(nextProps) {
//         this.initScreen(nextProps);
//    }
   
//    initScreen                      =   (props)  =>  {
//        this.setState({access_token : props.access_token});
//    }

    modalIndentInit               =   (transaction_id)  =>  {
        this.indentModal.show();
        this.getindentDetail(transaction_id)   ;  
    }

    getindentDetail        =       (transaction_id)      =>      {
       
        this.setState({indent_loading : true,indentDetail : null,indentTransId : ''})
        HttpAPICall.withAthorization('GET', this.warehouseIndentDetailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                indentDetail    :   respData.data,
                indentTransId   :   respData.data.transaction_id,
            }); 
            this.forceUpdate();   
        }).then(() => this.setState({indent_loading : false}));      
    }

    
    reqDetailJsx        =       ()      =>      {
        let indent = this.state.indentDetail;
    
        return (<div className="modal fade" id={this.indentDetailModalId} tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="viewIndentDetailModalLabel">Indent : {this.state.indentTransId ? this.state.indentTransId : "-"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        {!this.state.indent_loading
                            ? (<Ax>
                                {indent
                                    ?
                                    <div>
                                        <table className="table table-bordered bg-white" >
                                            <thead className="table-secondary">
                                                <tr>
                                                    <th style={{ width: "33%" }}>ID</th>
                                                    <th style={{ width: "33%" }}>Date</th>
                                                    <th style={{ width: "33%" }}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td scope="col">{this.state.indentDetail && this.state.indentDetail.transaction_id ? this.state.indentDetail.transaction_id : "-"}</td>
                                                    <td scope="col">{indent.transaction_date_display ? indent.transaction_date_display : "-"}</td>
                                                    <td scope="col" className="align-middle">
                                                        {indent.status_data ? indent.status_data.name : ''}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Warehouse</td>
                                                    <td colSpan={2}>{indent.warehouse_detail ? <div><b>{indent.warehouse_detail.name} ({indent.warehouse_detail.code})</b> <br />
                                                        {indent.warehouse_detail ?
                                                            <div className='pt-1'>Address : {indent.warehouse_detail.address1 ? indent.warehouse_detail.address1 + ", " : ""}
                                                                {indent.warehouse_detail.address2 ? indent.warehouse_detail.address2 + ", " : ""}
                                                                {indent.warehouse_detail.landmark ? indent.warehouse_detail.landmark + ", " : ""}
                                                                {indent.warehouse_detail.city ? indent.warehouse_detail.city + ". " : "-"} <br />
                                                                Destination of Supply : <b>{indent.warehouse_detail.state_name}</b> <br />
                                                                GSTIN  : {indent.warehouse_detail.gstin ? indent.warehouse_detail.gstin : "-"} <br />
                                                            </div> : "-"}</div> : "-"} <br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="col">Title</td>
                                                    <th scope="col">{indent.title ? indent.title : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Indent For</td>
                                                    <th>{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "-"} </th>
                                                </tr>
                                                {indent.indent_for ?
                                                    <tr>
                                                        <td style={{ width: "33%" }} >{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "null"}</td>
                                                        <th>
                                                            {indent.indent_for == "asset" && indent.assetProfile ? indent.assetProfile.name : indent.indent_for == "workstation" && indent.workstation ? indent.workstation.workstation_name : indent.indent_for == "employee" && indent.employee ? indent.employee.name : "-"}
                                                        </th>
                                                    </tr> : null}
                                                {indent.additional_attributes_data && indent.additional_attributes_data.length > 0
                                                    ? indent.additional_attributes_data.map((attr) => {
                                                        return (<tr key={attr.key}>
                                                            <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                            <th scope="col">{attr.value ? attr.value : "-"}</th>
                                                        </tr>)
                                                    }) : null}
                                            </tbody>
                                        </table>
                                        <table className="table  table-sm table-bordered table-responsive bg-white my-3" id="dataTable">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllItem} id="all_check_indent" /></th>
                                                    <th scope="col" className="text-center" style={{ width: "31%" }}>Item Details</th>
                                                    <th scope="col" className="text-center" style={{ width: "8%" }}>Indent Qty</th>
                                                    <th scope="col" className="text-end" style={{ width: "8%" }}>Fullfilled</th>
                                                    <th scope="col" className="text-end" style={{ width: "8%" }}>Pur. Req</th>
                                                    <th scope="col" className="text-end" style={{ width: "8%" }}>PO</th>
                                                    <th scope="col" className="text-end" style={{ width: "8%" }}>Purchase</th>
                                                    <th scope="col" className="text-end" style={{ width: "8%" }}>In Transit </th>
                                                    <th scope="col" className="text-end" style={{ width: "8%" }}>Cancelled  </th>
                                                    <th scope="col" className="text-end" style={{ width: "8%" }}>Pending </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    indent && indent?.items && indent?.items?.length > 0 ? indent?.items?.map((i, k) => {
                                                        return (<tr key={k} className="text-end">
                                                            <td className='text-center'>
                                                                <input
                                                                    type='checkbox'
                                                                    name="foo"
                                                                    value={i?.itemData?.id}
                                                                    data-pendingqty={i.pending_qty}
                                                                    className="indent_item_id_checkbox"
                                                                    data-warehouseid={indent.warehouse_id}
                                                                    data-itemdata={i?.itemData}
                                                                    data-transactionid={indent.transaction_id}
                                                                    data-statuslevel={indent.status_data && indent.status_data.level}
                                                                    data-statusids={indent.status_data && indent.status_data.id}
                                                                />
                                                            </td>
                                                            <td className="text-start">{i.itemData ? <div className='row'>
                                                                <div className='col-sm-9'>
                                                                    <ItemViewInList item={i.itemData} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.itemData)} />
                                                                </div>

                                                            </div> : null}</td>
                                                            <td>{i.indent_qty ? i.indent_qty : 0}</td>
                                                            <td>{i.fullfilled_qty ? i.fullfilled_qty : 0}</td>
                                                            <td>{i.req_qty ? i.req_qty : 0}</td>
                                                            <td>{i.po_qty ? i.po_qty : 0}</td>
                                                            <td>{i.purchase_qty ? i.purchase_qty : 0}</td>
                                                            <td>{i.transit_qty ? i.transit_qty : 0}</td>
                                                            <td>{i.cancelled_qty ? i.cancelled_qty : 0}</td>
                                                            <td>{i.pending_qty ? i.pending_qty : 0}</td>
                                                        </tr>);


                                                    })
                                                        : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                                                }

                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div>{indent.notes ? indent.notes : "-"}</div>

                                            </div>
                                            <div className=" col-sm-6">

                                                <table className="table  table-borderless">
                                                    <tbody>

                                                        <tr className="subtotal_bg_color">
                                                            <th>Total Quantity</th>
                                                            <td className='float-end'>{indent.total_qty ? indent.total_qty : 0} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                    : null}
                            </Ax>)
                            : <Loader />}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>

                </div>
            </div>
        </div>)
    }
   

    render() {
        return (<div>{this.reqDetailJsx()}</div>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(IndentDetailModal);