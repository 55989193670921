import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import moment from "moment";
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import * as actions from "../../../store/actions/index"
import UpdateMyDashboardForm from '../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm';

class ServiceChecksheet extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            iam_user_sites              :   [],
            iam_asset_types             :   [],
            scsForm                     :   {filter_site_id : '',search_asset_type:''},
            loading                     :   false,
            data                        :   [],
            remark_day                  :   0,
            remark_time                 :   '',
            not_active                  :   0,
        }
        this.updateMyDashboardRef      =   React.createRef();
    }


    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('scsWidget')) {
            let scsWidgetData = JSON.parse(localStorage.getItem('scsWidget'));
            if (scsWidgetData) {
                let lastRefreshTime     =   moment(scsWidgetData.time).format();
                let nextDay             =   moment(lastRefreshTime).add(1, 'days').format();
                lastRefreshTime         =   moment(lastRefreshTime).valueOf();
                nextDay                 =   moment(nextDay).valueOf();
                let latestDataTime      =   moment(nextDay - lastRefreshTime).valueOf();
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('scsWidget')
                    setTimeout(() => {
                        this.getDelayedTransactionDelayPeriod();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
                   
                } else {
                    this.setState({
                        data            :      scsWidgetData.data,
                        remark_time     :      scsWidgetData.time, 
                        remark_day      :      scsWidgetData.remark_aging,
                        not_active      :      scsWidgetData.not_active_scs

                    })
                }
            }else{
                setTimeout(() => {
                    this.getDelayedTransactionDelayPeriod();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
            }
        } else {
            setTimeout(() => {
                this.getDelayedTransactionDelayPeriod();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 2));
        }
    }

    getDelayedTransactionDelayPeriod            =   ()  =>  {
        this.setState({loading: true});
        setTimeout(() => {
            if(this.props.group_info && this.props.group_info.group_id == 'nccltd') {
                setTimeout(() => {
                    this.getSCSListing();
                }, 9000);
            } else {
                this.getSCSListing();
            }
        }, 2000);
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites ||  nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites           :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                iam_asset_types          :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            }, () => {  
                if(props.dashboard_filter_site_id || props.clearDashboardFilter == "yes"){
                   
                    this.setState({
                        scsForm    :       {
                            filter_site_id          :       props.dashboard_filter_site_id,
                            search_asset_type       :       ''
                        }
                    },() => this.getSCSListing())
                }
            });
        }
    }

    getSCSListing                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {...this.state.scsForm ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/group/asset_scs_status_data', this.props.access_token, params, {}, (response) => {
            
            this.setState({
                data            :       response.data.data,
                remark_time     :       response.data.time, 
                remark_day      :       response.data.remark_aging,
                not_active      :       response.data.not_active_scs
            })
  
            if(!this.state.scsForm.filter_site_id && !this.state.scsForm.search_asset_type){
                localStorage.setItem('scsWidget',JSON.stringify(response.data));
               
            }
            if(reload !== false){
                localStorage.setItem('scsWidget',JSON.stringify(response.data));
            }
            
        }).then(() => this.setState({ loading: false }));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"scs_running_status",name:"Service Checksheet"})
    }
  
    render                      =   ()  =>  {
        let allStatus           =   this.state.data  && this.state.data.map(st => st.key).join(',');
       
          
        let filterObj           =   {};
        // if(this.state.scsForm){
            if(this.state.scsForm.filter_site_id){
                filterObj           =   {...filterObj , 'search_site_ids' : this.state.scsForm.filter_site_id}
            }
            if(this.state.scsForm.search_asset_type){
                filterObj           =   {...filterObj , 'search_asset_type_ids' : this.state.scsForm.search_asset_type}
            }
        // }
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("scs_running_status" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "scs_running_status")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Service Checksheet</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Asset Types"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_asset_types.find(r => r.value === this.state.scsForm.search_asset_type)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'scsForm', 'search_asset_type', () => this.getSCSListing())}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r => r.value === this.state.scsForm.filter_site_id)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'scsForm', 'filter_site_id', () => this.getSCSListing()) }
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <Link className={['dropdown-item'].join(' ')} onClick={() => this.getSCSListing(true)}>Refresh</Link>
                            {/* <Link 
                                className={['dropdown-item', ((moment().format('YYYYMMDDHHmm') - moment(this.state.remark_time).format('YYYYMMDDHHmm')) > 60) ? '' : 'disabled'].join(' ')} 
                                title={((moment().format('YYYYMMDDHHmm') - moment(this.state.remark_time).format('YYYYMMDDHHmm')) > 60) ? '' : 'Refresh will be enable after 60 minutes'}
                                onClick={this.getSCSListing}>Refresh</Link> */}
                                <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "50%" }} className="text-center" >Status</th>
                                    <th className="text-center">No of SCS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? (<tr>
                                        <td className="text-center" colSpan="2"><Loader /></td>
                                    </tr>)
                                    : <Ax>
                                        {this.state.data && this.state.data.length > 0 && this.state.data.map((s, d) => {
                                            let obj = { ...filterObj, 'scs_status': s.key };
                                            return (<tr key={d + 1}>
                                                <th>{s.name}</th>
                                                <td className="text-center"><Link target="_blank" to={{ pathname: "/report/service_checksheet_running_status", search: "?" + new URLSearchParams(obj).toString() }}>{s.count}</Link></td>
                                            </tr>)
                                        })}
                                        <tr>
                                            <th>Total</th>
                                            <td className="text-center">
                                                <Link target="_blank" to={{ pathname: "/report/service_checksheet_running_status", search: "?" + new URLSearchParams({ ...filterObj, scs_status: allStatus }).toString() }}>
                                                    {this.state.data && this.state.data.length > 0 && this.state.data.map(s => s.count).reduce((a, b) => a + b, 0)}
                                                </Link></td>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Not Yet Active</th>
                                            <td  className="text-center">{this.state.not_active}</td>
                                        </tr>
                                    </Ax>}



                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p0">
                    { this.state.loading
                            ? <Loader />
                            : (this.state.data ? (<Chart
                                chartType="PieChart"
                                // loader={<Loader />}
                                data={[['', '']].concat(this.state.data?.map((d) =>
                                { return [d.name, d.count]; }))}
                                options={{
                                    legend: {position: 'labeled',labeledValueText: 'both'},
                                    pieSliceText: 'none',
                                    sliceVisibilityThreshold :0,
                                    slices: this.state.data?.map((d) => {return {color : d.color} }),
                                    chartArea: {width: '90%', height: '90%' },
                                }}
                                rootProps={{ 'data-testid': '1' }} />) : null)  
                        }
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                    Report as of {this.state.remark_time}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }
    
}


const mapStateToProps = state => {
    
    return {
        access_token            :   state.auth.access_token,
        group_info              :   state.app.group_info ? state.app.group_info : null,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token,false)),
       
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(ServiceChecksheet);
