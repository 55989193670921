import React from 'react';

import {connect} from "react-redux";
import HttpAPICall from "../../../services/HttpAPICall";
import Ax from "../../../components/hoc/Ax";

import Loader from "../../../components/ui/Loader/Loader";

import TapApiUrls from '../../../services/TapApiUrls';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ItemDetails from './ItemDetails';
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemDetailsTab extends React.Component {

    constructor(props) {
        super(props);
        this.state                  =        {
            item_id                 :        null,
            refreshDetail           :       false,
        }
    }

    id              =   this.props.item_enc_id;

    componentDidMount() {
        this.setState({item_id : this.props.item_enc_id,refreshDetail : this.props.refreshDetail})
        
        this.getItemDetails(this.props.item_enc_id)
    }

    componentWillReceiveProps(nextProps){
        
        if(nextProps.item_enc_id !== this.props.item_enc_id || nextProps.refreshDetail == true){
            this.setState({item_id : nextProps.item_enc_id,refreshDetail:nextProps.refreshDetail},() => {
                this.getItemDetails(nextProps.item_enc_id)
                
            });
           
        }
        
    }

    changeTab       =       (key)       =>   {
        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
        let itemName,itemCategory;
        if(ParamObject.get('asset_name')){
           itemName = ParamObject.get('asset_name')
        }
        if(ParamObject.get('item_category')){
            itemCategory = ParamObject.get('item_category')
         }
        let obj = {asset_name : itemName,item_category : itemCategory}
        this.props.props.history.push({ pathname: `/item_list/${key}/${this.state.item_id}`, search: "?" + new URLSearchParams(obj).toString()})
    }

    getItemDetails              =       (id)          =>      {
        this.setState({details_loading: true});
        HttpAPICall.withAthorization('GET',   INVENTORY_API_BASE_URL_2 + '/item/' + id, this.props.access_token, {opening_stock : "Y"}, {}, (resp) => {
          this.setState({ itemData         :    resp.data.data})
        }).then(() => {this.setState({details_loading: false})}) 
    }

    render() {

        let ParamObject                 =   new URLSearchParams(this.props.props.location.search);
       
        let itemCategory;
        if(ParamObject.get('item_category')){
           itemCategory = ParamObject.get('item_category')
        }
       
        return (<section className="item_view_container bg-white">
            <Helmet><title>Item Details</title></Helmet>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <Link to={`/item_list/details/${this.state.item_enc_id}`} role={"button"} onClick={() => this.changeTab('details')}>
                        <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                            type="button" role="tab" aria-controls="details" aria-selected="true">Details
                        </button>
                    </Link>
                </li>
                <li className="nav-item" role="presentation">
                    <Link to={`/item_list/interchangeable/${this.state.item_enc_id}`} role={"button"} onClick={() => this.changeTab('interchangeable')}>
                        <button className="nav-link" id="interchangeable-tab" data-bs-toggle="tab" data-bs-target="#interchangeable"
                            type="button" role="tab" aria-controls="interchangeable" aria-selected="false">Interchangeable Items
                        </button>
                    </Link>

                </li>
                {itemCategory !== "services" && this.props.category !== "services" ?
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/inventory/${this.state.item_enc_id}`} role={"button"} onClick={() => this.changeTab('inventory')}>
                            <button className="nav-link" id="inventory-tab" data-bs-toggle="tab" data-bs-target="#inventory"
                                type="button" role="tab" aria-controls="inventory" aria-selected="false">Inventory Levels
                            </button>
                        </Link>
                    </li> : null}
                {itemCategory !== "services" && this.props.category !== "services" ?    
                    <li className="nav-item" role="presentation">
                        <Link to={`/item_list/configuration/${this.state.item_enc_id}`} onClick={() => this.changeTab('configuration')} role={"button"}>
                            <button className="nav-link" id="config-tab" data-bs-toggle="tab" data-bs-target="#config"
                                type="button" role="tab" aria-controls="config" aria-selected="false">
                                Configuration
                            </button>
                        </Link>
                    </li>
                : null }
            </ul>
            <div className="tab-content" id="myTabContent">

                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                            {this.state.details_loading ? <Loader />
                                : <div className="tab_content_header">
                                    {this.state.itemData ? <div>
                                        <div className="tab_content_wrapper"><span className="content_heading">Basic Details</span></div>
                                        <table className="table table-hover table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="details-label" style={{ "width": "34%" }}>Item Category </td>
                                                    <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData?.category_display}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">Item Name</td>
                                                    <td className="details-name">{this.state.itemData?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">Item Code</td>
                                                    <td className="details-name">{this.state.itemData?.item_code ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">Item Status</td>
                                                    <td className="details-name">{this.state.itemData && this.state.itemData.status ? <Status color={this.state.itemData.status_color}>{this.state.itemData.status_text}</Status> : ""}</td>
                                                </tr>

                                                <tr>
                                                    <td className="details-label">Item Description</td>
                                                    <td className="details-name">{this.state.itemData?.description ?? ''}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">Unit of Measurement</td>
                                                    <td className="details-name">{this.state.itemData?.measuring_unit?.name ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">Manufacturer</td>
                                                    <td className="details-name">{this.state.itemData?.manufacturer?.name ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">Manufacturer Part Number (MPN)</td>
                                                    <td className="details-name">{this.state.itemData?.manufacturer_part_no ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label" >Item Tags</td>
                                                    <td className="details-name" >
                                                        {
                                                            this.state.itemData?.tags?.length > 0
                                                                ? (this.state.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label" >HSN / SAC Code</td>
                                                    <td className="details-name" >{this.state.itemData?.hsn_code ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label" >Generate Scrap on Replacement</td>
                                                    <td className="details-name" >{this.state.itemData?.is_scrapble == 'N' ? 'NO' : 'Yes' ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label" >Created On</td>
                                                    <td className="details-name" >{this.state.itemData?.created_at_display ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label" >Created By</td>
                                                    <td className="details-name" >{this.state.itemData && this.state.itemData.created_user ? this.state.itemData.created_user.full_name : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label" >Updated On</td>
                                                    <td className="details-name" >{this.state.itemData?.updated_at_display ?? '-'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label" >Updated By</td>
                                                    <td className="details-name" >{this.state.itemData && this.state.itemData.updated_user ? this.state.itemData.updated_user.full_name : '-'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper"><span className="content_heading">Pricing</span></div>
                                        <table className="table table-hover table-borderless">
                                            <tbody>

                                                <tr>
                                                    <td className="details-label" style={{ "width": "34%" }}>Purchase Price</td>
                                                    <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData.purchase_currency && this.state.itemData?.purchase_currency} {this.state.itemData?.purchase_price ?? ''}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">Sale / Consumption Price</td>
                                                    <td className="details-name">{this.state.itemData.consumption_currency && this.state.itemData?.consumption_currency} {this.state.itemData?.consumption_price ?? ''}</td>
                                                </tr>
                                                {this.state.itemData?.is_scrapble === "Y" ? <tr>
                                                    <td className="details-label">Scrap Price</td>
                                                    <td className="details-name">{this.state.itemData?.scrap_price ?? ''}</td>
                                                </tr> : null}
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper"><span className="content_heading">Taxation</span></div>
                                        <table className="table table-hover table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="details-label" style={{ "width": "34%" }}>Tax Status </td>
                                                    <td className="details-name" style={{ "width": "66%" }}>{this.state.itemData?.is_taxable == 'Y' ? 'Taxable' : 'Non - Taxable'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">IGST Percentage</td>
                                                    <td className="details-name">{this.state.itemData?.igst ?? 0}%</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">CGST Percentage</td>
                                                    <td className="details-name">{this.state.itemData?.cgst ?? 0}%</td>
                                                </tr>
                                                <tr>
                                                    <td className="details-label">SGST Percentage</td>
                                                    <td className="details-name">{this.state.itemData?.sgst ?? 0}%</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {
                                            this.state.itemData.additional_attribute_data && this.state.itemData.additional_attribute_data.length > 0
                                                ? (
                                                    <Ax>
                                                        <div className="tab_content_wrapper">
                                                            <div>
                                                                <span className="content_heading">Additional Attribute</span>
                                                            </div>
                                                        </div>
                                                        <table className="table table-hover table-borderless">
                                                            <tbody>
                                                                {this.state.itemData.additional_attribute_data.map((a, k) => (<tr key={k}>
                                                                    <td className="details-label text-capitalize" style={{ "width": "34%" }}>{a.name}</td>
                                                                    <td className="details-name" style={{ "width": "66%" }}>
                                                                        {a.value ? a.value : "-"}
                                                                    </td>
                                                                </tr>))}
                                                            </tbody>
                                                        </table>
                                                    </Ax>
                                                )
                                                : null
                                        }
                                        {this.state.itemData && this.state.itemData.category === "services" ? null
                                            : (<Ax>
                                                <div className="tab_content_wrapper"><span className="content_heading">Opening Balance</span></div>
                                                <table className="table table-hover table-bordered my-2">
                                                    <thead className="table-secondary">
                                                        <tr>
                                                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                                                            <th scope="col" className="text-start" style={{ width: "25%" }}>Warehouse</th>
                                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                                                            <th scope="col" className="text-center" style={{ width: "15%" }}>Unit Rate</th>
                                                            <th scope="col" className="text-end" style={{ width: "15%" }}>Amount</th>
                                                            {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                                <Ax>
                                                                    <th scope="col" className="text-start" style={{ width: "15%" }}>Batch Number</th>
                                                                    <th scope="col" className="text-center" style={{ width: "10%" }}>Expiry Date</th>
                                                                </Ax>)
                                                                : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.itemData && this.state.itemData.opening_stock && this.state.itemData.opening_stock.length > 0 ?
                                                            this.state.itemData.opening_stock.map((a, k) => (<tr key={k} className='text-center'>
                                                                <td>{k + 1}</td>
                                                                <td className="text-start">{a.warehouse.name}</td>
                                                                <td>{a.qty}</td>
                                                                <td >{a.rate}</td>
                                                                <td className="text-end">{a.qty * a.rate}</td>
                                                                {this.props.isc_configuration?.track_batch_expiry == "Y" ? (
                                                                    <Ax>
                                                                        <td className='text-start'>{a.batch_number ?? "-"}</td>
                                                                        <td>{a.expiry_date ? moment(a.expiry_date).format("DD-MMM-YYYY") : "-"}</td>
                                                                    </Ax>)
                                                                    : null}
                                                            </tr>))
                                                            : <tr><td colSpan={7} className='text-center'>No Record</td> </tr>}
                                                    </tbody>
                                                </table>
                                            </Ax>)}
                                    </div> : null}

                                </div>}
                        </div>
                    </div>
                    {/* <ItemDetails itemId = {this.state.item_id} refreshDetail={this.state.refreshDetail} changeStatus={this} /> */}
                </div>
            </div>

        </section>)
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        isc_configuration       :        state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    }
}

export default connect(mapStateToProps)(ItemDetailsTab);