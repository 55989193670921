import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from "../../../components/ui/Loader/Loader";
import {PeriodSelectionWithCustom,DisplayListPagination} from "../../../components/TapUi/index";
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from "../../../services/DownloadFile";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Modal } from 'bootstrap';
import FundTransferDetailModal from '../../transactions/fundTransfer/FundTransferDetailModal';
import PurchaseDetail from '../../inventory/purchase/PurchaseDetail';
import CompositeExpenseDetail from '../../transactions/compositeExpense/CompositeExpenseDetail';
import AssetExpenseDetails from '../../transactions/AssetExpenseDetails';
import { Helmet } from 'react-helmet';
import ExpenseView from '../../assets/counterlog/ShiftActivityModals/expense/ExpenseView';

class WalletLedgerList extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.walletLedgerFilterFormInitialState   =   {
            wallet_id                                     :   '',
            date_range                                    :   '',
            date_range_start                              :   '',
            date_range_end                                :   '',
         };

        this.state                         =       {
            showReport                     :       false,
            minimizeTable                  :       false,
            fundType                       :        "",
            form_loading                   :       false,
            listing_loading                :       false,
            balance_loading                :       false,
            walletLedgerFormData           :      {periods : [], all_wallets : []},
            walletLedgerListingData        :       [],
            walletLedgerWalletData         :       [],
            disableDownloadLdger           :       false,
            reportDownloading              :      false,
            listingMeta                    :        null,
            totalListingCount              :        0,
            transactionType                 :       '',
            transactionId                   :       '',
            walletLedgerFilterForm         :      {...this.walletLedgerFilterFormInitialState},
            reportData                          :   null,                  
        };

    }

    componentDidMount() {
      this.getWalletLedgerFormData();
      this.viewFundTransaferModal                =          new Modal(document.getElementById('viewFundTransaferModal'), {keyboard: false, backdrop :false});
    }

    getWalletLedgerFormData         =     ()   =>    {

        const report_name   =   'wallet_ledger';
        const reportData    =   this.props?.report_listing.length > 0 ? this.props.report_listing.filter(d => d.key === report_name) : [];
        
        this.setState({
            form_loading: true,
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        });
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/wallet/ledger/form_data', this.props.access_token, {}, {}, (resp) => {
            this.setState({
                walletLedgerFormData      :   resp.data
            });
            
            let ParamObject             =   new URLSearchParams(this.props.location.search);
     
            
      if(ParamObject.get('wallet_id')) {
          let period                =   resp.data.periods.find(p => p.key === ParamObject.get('date_range'));
          let startDate             =   moment(period.dateFromYmd).format('YYYY-MM-DD');
          let endDate               =   moment(period.dateToYmd).format('YYYY-MM-DD')
          let updateFilterForm      =   {wallet_id : ParamObject.get('wallet_id'), date_range : ParamObject.get('date_range'), date_range_start :  startDate,date_range_end : endDate };
          setTimeout(() => {this.setState({walletLedgerFilterForm :   {...this.state.walletLedgerFilterForm , ...updateFilterForm}},
            function(){
                this.reportFilterFormSubmit();
                });
        }, 1500)
        }
        }).then(() => this.setState({form_loading: false}));
    }

    openLedgerView                   =    (fundType)   =>    {
        this.setState({minimizeTable : true,fundType : fundType})
    }

    viewTransactionInit             =       (transaction_id,transaction_type)      =>      {
        this.setState({ transactionId: transaction_id ,transactionType : transaction_type}
            , () => {
                    this.viewFundTransaferModal.show()
            })
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faWallet} /> {this.state.showReport ? `Statement of Accounts (Ledger) for ${this.state?.walletLedgerWalletData?.wallet?.wallet_name} ` : "Wallet"}</h3>
                <div className="text-end mt15">
                    {this.state.showReport ?
                        <Ax>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <li><Link to={'/wallet/fund-transfer'} className="dropdown-item">Fund Transfer</Link></li>
                            </ul>

                            <button onClick={this.reportDownloadHandler} disabled={this.state.disableDownloadLdger || this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" className="btn btn-secondary">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} className="img-fluid" alt="item-action" /> {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>

                        </Ax> : null}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }

    reportFilterFormSubmit            =     (e = null)   =>   {
      e && e.preventDefault();
      this.setState({showReport: true,listing_loading:true,balance_loading : true, disableDownloadLdger : true });
      let params = {wallet_id : this.state.walletLedgerFilterForm.wallet_id, date_range: this.state.walletLedgerFilterForm.date_range};
      HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/wallet/ledger/balance', this.props.access_token, {...params}, {}, (resp) => {
         this.setState({walletLedgerWalletData : resp.data})
       }).then(() => this.setState({balance_loading: false}));
       HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/wallet/ledger/statement', this.props.access_token, {...params}, {}, (resp) => {
        this.setState({walletLedgerListingData : resp.data , disableDownloadLdger : false,
            listingMeta                     :   resp.data.meta,
            totalListingCount               :   resp.data.meta.total,})
      }).then(() => this.setState({listing_loading: false}));
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        let params = {wallet_id : this.state.walletLedgerFilterForm.wallet_id, date_range: this.state.walletLedgerFilterForm.date_range, download : 'download'};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/wallet/ledger/statement', this.props.access_token, {...params}, {}, (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    walletCardFormJsx = () => {
        let all_wallet = this.state.walletLedgerFormData && this.state.walletLedgerFormData.wallets 
                            ? this.state.walletLedgerFormData.wallets.map(w => { return {value: w.id, label: w.wallet_name}}) 
                            : [];
        return (<Ax>
            <div className="bg-white px-2">
                { this.state.form_loading 
                    ? <Loader/> 
                    : <form onSubmit={this.reportFilterFormSubmit} className={['py-2', 'filter_form'].join(' ')}>
                        <div className="row align-items-center">
                            <label className="col text-end require">Wallet Name</label>
                            <div className="col-sm-3">
                                <TapSelect
                                    menuPlacement='bottom'
                                    options={all_wallet}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'walletLedgerFilterForm', 'wallet_id');
                                    }}
                                    required={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={all_wallet.find(r => r.value === this.state.walletLedgerFilterForm.wallet_id)}
                                    placeholder="Select Wallet"
                                />
                            </div>
                            <label className="col text-end require">Date Range</label>
                            
                            <div className="col-sm-5">
                                <PeriodSelectionWithCustom
                                   required={true}
                                    periods={this.state.walletLedgerFormData && this.state.walletLedgerFormData.periods ? this.state.walletLedgerFormData.periods : []}
                                    value={this.state.walletLedgerFilterForm.date_range}
                                    startDate={this.state.walletLedgerFilterForm.date_range_start}
                                    endDate={this.state.walletLedgerFilterForm.date_range_end}
                                    onSelectPeriod={(period, startDate, endDate) => {
                                        this.setState({
                                            walletLedgerFilterForm: { ...this.state.walletLedgerFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate }
                                        });
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-3">Show</button>
                            </div>
                        </div>
                    </form>
                }
                { this.state.showReport ? <div className="pb-3 pt0">
                       {this.state.balance_loading ? <Loader/>
                        :  <Ax><hr style={{color : "#ccc"}} /><div className="row pt-2">
                        <div className="col">Opening Balance : <span className='fw500' >{this.state.walletLedgerWalletData.balnce && this.state.walletLedgerWalletData.balnce.opening_balance}</span> </div>
                        <div className="col text-center">Total Credit : <span className='fw500' >{this.state.walletLedgerWalletData.balnce && this.state.walletLedgerWalletData.balnce.total_cr}</span>  </div>
                        <div className="col text-center">Total Debit : <span className='fw500' >{this.state.walletLedgerWalletData.balnce && this.state.walletLedgerWalletData.balnce.total_dr}</span>  </div>
                        <div className="col text-end">Closing Balance : <span className='fw500' >{this.state.walletLedgerWalletData.balnce && this.state.walletLedgerWalletData.balnce.closing_balance}</span>  </div>
                    </div></Ax>}
                    </div> : null}
            </div>
            {this.state.showReport ? 
                <div>
                <div className=" row">
                    <div className={['',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.walletLedgerListingJsx()}</div>
                   
                </div>
            </div> : null}
        </Ax>)
    }

   
    walletLedgerListingJsx            =    ()    =>   {
        return (<div style={{overflowY : "auto", height:"65vh"}}>
           <table className="table table-bordered table-sm align-middle bg-white my-2">
                <thead className="align-middle table-secondary" style={{zIndex:'0'}}>
                {this.state.minimizeTable 
                    ? <Ax>
                        <th  style={{ width: "25%" }}>Transaction</th>
                        </Ax>
                    : <tr>
                        <th className="text-center" style={{width:"12%"}}>Date of Transaction</th>
                        <th className="text-center" style={{width:"11%"}}>Transaction Type</th>
                        <th style={{width:"13%"}}>Transaction ID</th>
                        <th style={{width:"18%"}}>Asset / Wallet / Vendor</th>
                        <th style={{width:"14%"}}>Expense Head</th>
                        <th style={{width:"16%"}}>Details</th>
                        <th className="text-center" style={{width:"8%"}}>Credit</th>
                        <th className="text-center" style={{width:"8%"}}>Debit</th>
                    </tr> }
                </thead>
                <tbody>
                {this.state.listing_loading
                        ? (<tr><td colSpan="9"><Loader /></td></tr>)
                        : (this.state.walletLedgerListingData && this.state.walletLedgerListingData.data && this.state.walletLedgerListingData.data.length > 0
                            ? (this.state.walletLedgerListingData.data.map((item, index) => {
                                
                                return (<tr key={index}>
                                    <td className='text-center'>{item?.activity_date}</td>
                                    <td className='text-center'>{item?.activity_display}</td>
                                    <td className='cursor_pointer'>
                                        {item.activity == 'ex' ? <a className='link-primary' onClick={() => this.viewTransactionInit(item?.transfer_id,item.activity)}>{item.transfer_id}</a> : ''}
                                        {item.activity == 'cx' ? <a className='link-primary' onClick={() => this.viewTransactionInit(item?.cx_transfer_id,item.activity)}>{item?.cx_transfer_id}</a>: ''}
                                        {item.activity == 'ft' ? <a className='link-primary' onClick={() => this.viewTransactionInit(item?.fund_activity_id,item.activity)}> {item?.fund_activity_id}</a>: ''}
                                        {item.activity == 'ob' ? item.transaction_id : ''}
                                        {item.activity == 'pi' ? <a className='link-primary' onClick={() => this.viewTransactionInit(item.transaction_id,item.activity)}>{item.transaction_id}</a> : ''}
                                    </td>
                                    <td>{item.action_to_name}</td>
                                    <td>{item.expense_head_display}</td>
                                    <td>{item.description}</td>
                                    <td className="text-end">{item.cr ? item.cr : ""}</td>
                                    <td className="text-end">{item.dr ? item.dr : ""}</td>
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
            
        </div>)
    }

    viewTransactionJsx          =       ()      =>      {
        return (<div className="modal fade" id="viewFundTransaferModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchFundTransferModalLabel">
                            {  this.state.transactionType == 'ft' ? "Fund Transfer : " 
                             : this.state.transactionType == 'pi' 
                             ? "Purchase : " 
                             : this.state.transactionType == 'cx' 
                             ? "Composite Expense : " 
                             : this.state.transactionType == 'ex' 
                             ? "Asset Expense : " 
                             :null}
                            {this.state.transactionId}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                         {this.state.transactionType == 'ft' 
                          ?  <FundTransferDetailModal fundTransferId={this.state.transactionId}/>
                          :  this.state.transactionType == 'pi' 
                          ?  <PurchaseDetail purchaseId={this.state.transactionId}/>
                          :   this.state.transactionType == 'cx' 
                          ?  <CompositeExpenseDetail compositeExpenseId={this.state.transactionId}/>
                          :  this.state.transactionType == 'ex' 
                          ?  <ExpenseView need_asset_profile="Y" expenseId={this.state.transactionId}/>
                          : null }
                    </div>
                </div>
            </div>
        </div>)

    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Wallet Ledger - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className=" pageTbl col-12 pb-2" style={{overflowY:"hidden",paddingRight:"4px"}} >
                    {this.walletCardFormJsx()}
                     </div>
                 </div>
             </div>
             {this.viewTransactionJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        all_periods         :   state.app && state.app.all_periods ? state.app.all_periods : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(WalletLedgerList);