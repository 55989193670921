import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../components/AppBaseComponent';
import HttpAPICall from '../../services/HttpAPICall';
import { HRM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../components/hoc/Ax";
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from '../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";
import InputEmployeeSearch from '../includes/ui/InputEmployeeSearch';

class EditLinkedOperators extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.linkOperatorsFormInit    =   {
            operators                   :       [],
            engineers                   :       [],
            supervisors                 :       []
        }

        this.state                  =   {
            access_token                :   '',
            transaction_id              :   '',
            date                        :   '',
            linkingFormDataLoading      :   false,
            linkOperatorsData           :   null,
            assetData                   :   null,
            addlinkOperatorsForm        :   {...this.linkOperatorsFormInit},
            saveFormSubmitting          :   false,
            filter_site_id              :   []
        }
        this.linkOperatorsModalId       =   uuid();
        this.linkOperatorsFormId        =   this.linkOperatorsModalId + 'Form';

        this.asset_employee_url         =   HRM_API_BASE_URL_2 + '/asset_employee';
        this.asset_employee_linking_url =   HRM_API_BASE_URL_2 + '/asset_employee_linking';

    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.linkOperatorsModal         =   new Modal(document.getElementById(this.linkOperatorsModalId), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    editLinkOperatorModalInit         =   (assetData,asset_id = null)  =>  {
        this.linkOperatorsModal.show()
        this.setState({linkingFormDataLoading: true})
        this.setState({
            assetData                   :       assetData,
            filter_site_id              :       assetData && assetData.site_id ? [assetData.site_id] : [],
            addlinkOperatorsForm: {
                ...this.linkOperatorsFormInit,
                operators               :       assetData && !asset_id && assetData.operators && assetData.operators.length > 0 ? assetData.operators.map(op => { return ({ value: op.enc_id, label: op.display_full_name }) }) : [],
                supervisors             :       assetData && !asset_id && assetData.supervisors && assetData.supervisors.length > 0 ? assetData.supervisors.map(op => { return ({ value: op.enc_id, label: op.display_full_name }) }) : [],
                engineers               :       assetData && !asset_id && assetData.engineers && assetData.engineers.length > 0 ? assetData.engineers.map(op => { return ({ value: op.enc_id, label: op.display_full_name }) }) : [],
            }
        },() => {
            if(asset_id){
                this.getLinkOperatorsFormData(asset_id)
            }else{
                setTimeout(() => {
                    this.setState({ linkingFormDataLoading : false });
                }, 1500)
            }
        })
    } 

    getLinkOperatorsFormData        =       (id)        =>      {
        if(id){
           
            HttpAPICall.withAthorization('GET', this.asset_employee_url + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    addlinkOperatorsForm: {
                        ...this.linkOperatorsFormInit,
                        operators               :       respData && respData.operators && respData.operators.length > 0 ? respData.operators.map(op => { return ({ value: op.enc_id, label: op.display_full_name }) }) : [],
                        supervisors             :       respData &&  respData.supervisors && respData.supervisors.length > 0 ? respData.supervisors.map(op => { return ({ value: op.enc_id, label: op.display_full_name }) }) : [],
                        engineers               :       respData && respData.engineers && respData.engineers.length > 0 ? respData.engineers.map(op => { return ({ value: op.enc_id, label: op.display_full_name }) }) : [],
                    }
                })
            }).then(() => this.setState({ linkingFormDataLoading : false })); 
        }
    }
   
    submitlinkOperatorsForm         =       (e)         =>      {
        e.preventDefault();
        let frmData = {
            asset_id        :       this.state.assetData && this.state.assetData.asset_id ? this.state.assetData.asset_id : '',
            operators       :       this.state.addlinkOperatorsForm && this.state.addlinkOperatorsForm.operators && this.state.addlinkOperatorsForm.operators.length > 0 ? this.state.addlinkOperatorsForm.operators.map(op => {return(op.value)}) : [],
            supervisors     :       this.state.addlinkOperatorsForm && this.state.addlinkOperatorsForm.supervisors && this.state.addlinkOperatorsForm.supervisors.length > 0 ? this.state.addlinkOperatorsForm.supervisors.map(op => {return(op.value)}) : [],
            engineers       :       this.state.addlinkOperatorsForm && this.state.addlinkOperatorsForm.engineers && this.state.addlinkOperatorsForm.engineers.length > 0 ? this.state.addlinkOperatorsForm.engineers.map(op => {return(op.value)}) : []
        }
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', this.asset_employee_linking_url, this.props.access_token, {}, {...frmData}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            if(this.props.afterSubmitForm){
                this.props.afterSubmitForm();
                this.setState({addlinkOperatorsForm : {...this.linkOperatorsFormInit}})
            }
            this.linkOperatorsModal.hide()
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }
    
    linkOperatorsModalJsx           =   ()  =>  {
        return <div className="modal fade" id={this.linkOperatorsModalId} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            <span>Change Operator,Supervisior & Engineer </span>
                        </h5>
                        <div className='text-end'>
                                    <h5 className="modal-title">Site : {this.state.assetData && this.state.assetData.site ? this.state.assetData.site : ''}</h5>
                                </div>
                        {/* <button type="button" className="btn-close" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button> */}
                    </div>
                    <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-borderless text-sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "26%" }}>Asset Name (Code)</td>
                                    <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                </tr>
                                <tr>
                                    <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.state.linkingFormDataLoading 
                     ? <Loader />
                     : <Ax>
                        <div className="modal-body">
                        <form className={['p-2',].join(' ')} onSubmit={this.submitlinkOperatorsForm} id={this.linkOperatorsFormId}>
                            <div className="row align-items-center my-2">
                                <label className="col-sm-3 col-form-label">Operator </label>
                                <div className="col-sm-7">
                                    <InputEmployeeSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addlinkOperatorsForm', 'operators');
                                            this.setState({
                                                addlinkOperatorsForm: { ...this.state.addlinkOperatorsForm, operators    : selectedOption.length > 0 ? selectedOption : [] },
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        search_site_ids={this.state.filter_site_id}
                                        placeholder="Select Operators"
                                        value={this.state.addlinkOperatorsForm && this.state.addlinkOperatorsForm.operators   && this.state.addlinkOperatorsForm.operators.length > 0 ? this.state.addlinkOperatorsForm.operators.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-2">
                                <label className="col-sm-3 col-form-label">Supervisior </label>
                                <div className="col-sm-7">
                                    <InputEmployeeSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addlinkOperatorsForm', 'supervisors');
                                            this.setState({
                                                addlinkOperatorsForm: { ...this.state.addlinkOperatorsForm, supervisors   : selectedOption.length > 0 ? selectedOption : [] },
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        fontSize="93%"
                                        search_site_ids={this.state.filter_site_id}
                                        placeholder="Select Supervisior"
                                        value={this.state.addlinkOperatorsForm && this.state.addlinkOperatorsForm.supervisors  && this.state.addlinkOperatorsForm.supervisors.length > 0 ? this.state.addlinkOperatorsForm.supervisors.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-2">
                                <label className="col-sm-3 col-form-label">Engineers </label>
                                <div className="col-sm-7">
                                    <InputEmployeeSearch
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addlinkOperatorsForm', 'engineers');
                                            this.setState({
                                                addlinkOperatorsForm: { ...this.state.addlinkOperatorsForm, engineers   : selectedOption.length > 0 ? selectedOption : [] },
                                            });
                                        }}
                                        isMulti={true}
                                        containerHeight="30px"
                                        search_site_ids={this.state.filter_site_id}
                                        fontSize="93%"
                                        placeholder="Select Engineers"
                                        value={this.state.addlinkOperatorsForm && this.state.addlinkOperatorsForm.engineers  && this.state.addlinkOperatorsForm.engineers.length > 0 ? this.state.addlinkOperatorsForm.engineers.map(a => { a.label = a.label; return a; }) : []}
                                    />
                                </div>
                            </div>


                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={this.linkOperatorsFormId}>Update {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                    </div>
                    </Ax>
                }
                </div>
            </div>
        </div>
    }
    
    render                              =   ()  =>  this.linkOperatorsModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(EditLinkedOperators);