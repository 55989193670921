import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";
import InputEmployeeSearch from '../../../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../../../includes/ui/InputWorkstationSearch';

class TaskFlow extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.taskflowFormInit    =   {
            activity_id             :      '',
            transaction_id          :      '',
            start_hours_abs2        :       '',
            start_hours_abs         :       '',
            start_hours_abs3        :       '',
            start_hours_abs4        :       '',
            start_odometer_abs      :       '',
            start_odometer_abs2     :       '',
            start_odometer_abs3     :       '',
            start_cycle_abs         :       '',
            start_cycle_abs2        :       '',
            workstation_id          :       '',
            workstation_name        :       '',
            start_cl_date_time      :       '',
            operator_ids            :       [],
            activity_type           :       '',
            activity_count          :       '',
            end_cl_date_time        :       '',
            end_hours_abs2          :       '',
            end_hours_abs           :       '',
            end_hours_abs3          :       '',
            end_hours_abs4          :       '',
            end_odometer_abs        :       '',
            end_odometer_abs2       :       '',
            end_odometer_abs3       :       '',
            end_cycle_abs           :       '',
            end_cycle_abs2          :       '',
            running_hours_abs2      :       '',
            running_hours_abs           :       '',
            running_hours_abs3          :       '',
            running_hours_abs4          :       '',
            running_odometer_abs        :       '',
            running_odometer_abs2       :       '',
            running_odometer_abs3       :       '',
            running_cycle_abs           :       '',
            running_cycle_abs2          :       '',
            production                  :       '',
            production_unit             :       '',
            productivity_remark        :       '',
            material_id                 :       '',
            attachment_id               :       '',
            unloaded_weight             :       '',
            loaded_weight               :       '',
            unloaded_weight_unit        :       'kilogram',
            loaded_weight_unit          :       'kilogram',
            workstation_from            :       '',
            workstation_to              :       '',
            workstation_from_name       :       '',
            workstation_to_name         :       '',
            closing_remark              :       '',
            cl_hours_abs2               :       '',
            cl_hours_abs                :       '',
            cl_hours_abs3               :       '',
            cl_hours_abs4               :       '',
            cl_odometer_abs             :       '',
            cl_odometer_abs2            :       '',
            cl_odometer_abs3            :       '',
            cl_cycle_abs                :       '',
            cl_cycle_abs2               :       '',
            cl_date_time                :       moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm:ss'),
            task_start_at               :       '',
            task_close_at               :       ''
        }
        
        this.state                  =   {
            access_token                :   '',
            transaction_id              :   '',
            date                        :   '',
            taskflowLoading             :   false,
            taskflowData                :   null,
            counterlog                  :   null,
            assetData                   :   null,
            shift                       :   null,
            addTaskflowForm             :   {...this.taskflowFormInit},
            saveFormSubmitting          :   false,
            allActivityList             :   [],
            allFieldList                :   [],
            allAttachmentList           :   [],
            latestCounterLog            :   null,
            formDataLoading             :    false,
            searchedEmployee            :   [],
            stepDetail                  :   null,
            searchedWorkstation         :   null,
            copy_reading_counterlog     :   'N',
            filterTime                  :   '',
            shiftDetail                 :   null,
            taskStartFormData           :   null,
            taskData                    :   null,
            taskDetailSteps             :   [],
            previousStepDetails         :   [], 
            nextStepFields              :   [],
            max_step_level              :   0,
            nextStepDetail              :   null,
            addNewUnit                  :   'N',
            productionAddtionalAttr     :   [],
            additional_attributes       :   [],
            taskDayDiffernce            :   '',
            taskHourDifference          :   '',
            weightCheckFields           :   [],
            searchedWorkstationFrom     :   null,
            searchedWorkstationTo       :   null,
            addNewWorkstation           :   'N',
            addNewWorkstationFrom       :   'N',
            addNewWorkstationTo         :   'N',
            includeStartingReadings     :   false,
            runtime_material_creation   :   'N'
        }
        this.uploadTaskflowModalId   =   uuid();
        this.updateTaskflowFormId    =   this.uploadTaskflowModalId + 'Form';
    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.uploadTaskflowModal         =   new Modal(document.getElementById(this.uploadTaskflowModalId), {keyboard: false, backdrop :false}); 
        this.closingDateTimeModal        =   new Modal(document.getElementById('closingDateTimeModal'), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    uploadTaskModalInit         =   (shiftDetail, counterlog, assetData = null,task_detail=[])  =>  {
        this.uploadTaskflowModal.show();
        
        this.setState({
            assetData               :   assetData,
            TaskflowData            :   null,
            assetData               :   assetData,
            shift                   :   shiftDetail,
            counterlog              :   counterlog,
            date                    :   counterlog ? counterlog.feed_date : '',
            transaction_id          :   counterlog ? counterlog.transaction_id : '',
            formDataLoading         :   true,
            searchedEmployee            :   [],
            stepDetail                  :   null,
            searchedWorkstation         :   null,
            copy_reading_counterlog     :   'N',
            allActivityList             :   [],
            allFieldList                :   [],
            allAttachmentList           :   [],
            latestCounterLog            :   null,
            shiftDetail                 :   null,
            taskStartFormData           :   null,
            taskData                    :   null,
            taskDetailSteps             :   [],
            previousStepDetails         :   [],
            nextStepFields              :   [],
            nextStepDetail              :   null,
            max_step_level              :   0,
            addNewUnit                  :   'N',
            addNewWorkstation           :   'N',
            addNewWorkstationFrom       :   'N',
            addNewWorkstationTo         :   'N',
            taskDayDiffernce            :   '',
            taskHourDifference          :   '',
            addTaskflowForm             :   {...this.taskflowFormInit},
            weightCheckFields           :   [],
            searchedWorkstationFrom     :   null,
            searchedWorkstationTo       :   null,
            initalizeTaskflow           :   false,
            includeStartingReadings     :   false
        }, () => {
           
            if(counterlog && counterlog.transaction_id && task_detail && task_detail.length == 0){
                // this.loadFormDataFromApi(counterlog.transaction_id,counterlog)
                this.initalizeTaskflowFormData(counterlog.transaction_id)
            }else if(counterlog && counterlog.transaction_id && task_detail && task_detail.length > 0 && !task_detail.find(dt => dt.task_in_progress == 'Y')){
                this.initalizeTaskflowFormData(counterlog.transaction_id)
                // this.loadFormDataFromApi(counterlog.transaction_id,counterlog)
            }else{
                this.loadStepDetailFromApi(counterlog.transaction_id)
            }
        });
    }

    initalizeTaskflowFormData          =       (counterlog_transaction_id)     =>      {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/activity_task/initialize_form_data/' + counterlog_transaction_id, this.state.access_token , {}, {}, (response) => {
          
           let respData = response.data;
           this.setState({
               allActivityList             :       respData && respData.activities && respData.activities.length > 0 ? respData.activities.map(a => { return ({ value: a.id, label: a.name}) }) : [],
               shiftDetail                 :       respData && respData.shift ? respData.shift : null,
               taskStartFormData           :       null,
               initalizeTaskflow           :       false,
            },() => {
                // this.setState({
                //     addTaskflowForm         :   {...this.taskflowFormInit,start_cl_date_time :  moment(new Date(counterlog.feed_datetime), 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm:ss')},
                // })
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
     }

    loadFormDataFromApi                 =   (activity_id)  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/activity_task/start_form_data/' + this.state.counterlog.transaction_id + '/' + activity_id, this.state.access_token , {}, {}, (response) => {
          
           let respData = response.data;
           let step   =  respData && respData.step ? respData.step : null;
           let field_keys                      =       step && step.fields && step.fields.length > 0  ? step.fields.map(fl => {return(fl.field_key)}) : [];
          
           this.setState({
               allFieldList                :       step && step.fields && step.fields.length > 0 ? step.fields : [],
               latestCounterLog            :       step && step.form_data && step.form_data.latest_counterlog ? step.form_data.latest_counterlog : null,
               stepDetail                  :       step ? step : null, 
               taskStartFormData           :       step && step.form_data ?  step.form_data   : null,
               initalizeTaskflow           :       true,
               includeStartingReadings     :       field_keys && field_keys.length > 0 && field_keys.includes('starting_readings') ? true :false
            },() => {
                this.setState({
                    addTaskflowForm         :   this.state.counterlog ? 
                                                {...this.taskflowFormInit,
                                                    start_cl_date_time :  moment(new Date(this.state.counterlog.feed_datetime), 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm:ss'),
                                                    activity_id : activity_id 
                                                } 
                                                    : {...this.taskflowFormInit},
                })

                
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }

    loadStepDetailFromApi                 =   (id,activityData = null)  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/activity_task/detail', this.state.access_token , {counterlog_transaction_id : id}, {}, (response) => {
          
           let respData             =       response.data;
           let nextStepFields       =       respData && respData.steps && respData.steps.length > 0 && respData.next_step ? respData.steps.filter(st => st.id == respData.next_step.id) : [];
           var max_step_level       =       respData && respData.steps && respData.steps.length > 0 ?   Math.max.apply(Math, respData.steps.map(st => st.level)) : 0;
            
           this.setState({
                taskData                :       respData && respData.taskData ? respData.taskData  :   null,
                previousStepDetails     :       respData && respData.steps && respData.steps.length > 0 ? respData.steps.filter(st => respData.next_step && st.id !== respData.next_step.id && st.level && st.level <= respData?.next_step?.level) : [],
                nextStepFields          :       nextStepFields,
                nextStepDetail          :       respData && respData.next_step ? respData.next_step : null,
                max_step_level          :       max_step_level,  
            },() => {
               
               let next_field_keys =  this.state.nextStepFields.map((element) => {
                    return {...element, fields: element.fields.filter((subElement) => subElement.field_key == 'productivity')}
                  })
                
               if(next_field_keys && next_field_keys.length >= 0){
                    
                      let productivity = next_field_keys && next_field_keys.length > 0 ? next_field_keys[0] : null;
                     
                    let form_data = productivity && productivity.form_data ? productivity.form_data : null;
                    if(form_data && form_data.production_formula && form_data.production_formula.unit_name){
                        this.setState({addTaskflowForm : {...this.state.addTaskflowForm,production_unit : form_data.production_formula.unit_name}})
                    } 
                    if(form_data && form_data.production_additional_attributes && form_data.production_additional_attributes.length > 0){
                        this.setState({
                            productionAddtionalAttr     :   form_data.production_additional_attributes,
                        },() => {
                            /**********To make Additioanl Atteibute  required */
                            if (form_data && form_data.production_formula && form_data.production_formula.formula && this.state.productionAddtionalAttr && this.state.productionAddtionalAttr.length > 0) {
                                let  list = this.state.productionAddtionalAttr.filter(key => key.type == "numeric"  ? true : false)
                               
                                list.forEach((i, k) => {
                                   
                                    setTimeout(() => {
                                        if (form_data && form_data.production_formula && form_data.production_formula.formula && form_data.production_formula.formula.includes(i.formula_key) && document.getElementById(i.formula_key)) {
                                           
                                            document.getElementById(i.formula_key).required = "required"
                                        }
                                    },1000)
                                })
                            }
                        })
                    }   
                }
            })
          
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }
    

    copyPreviousHandler                 =   ()  =>  {
        if(this.state.copy_reading_counterlog == "Y" && this.state.latestCounterLog) {
            this.setState({addTaskflowForm : {
                ...this.state.addTaskflowForm,
                start_hours_abs               :   this.state.latestCounterLog.hours_abs,
                start_hours_abs2              :   this.state.latestCounterLog.hours_abs2,
                start_hours_abs3              :   this.state.latestCounterLog.hours_abs3,
                start_hours_abs4              :   this.state.latestCounterLog.hours_abs4,
                start_odometer_abs            :   this.state.latestCounterLog.odometer_abs,
                start_odometer_abs2           :   this.state.latestCounterLog.odometer_abs2,
                start_odometer_abs3           :   this.state.latestCounterLog.odometer_abs3,
                start_cycle_abs               :   this.state.latestCounterLog.cycle_abs,
                start_cycle_abs2              :   this.state.latestCounterLog.cycle_abs2
            }});
        }
    }
    
    calulateProductivityFormula     =       (e,linkedFormula)      =>      {
        if(this.state.productionAddtionalAttr && this.state.productionAddtionalAttr.length > 0 ){
           let  list = this.state.productionAddtionalAttr.filter(key => key.type == "numeric"  ? true : false)
            list.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                window[i.formula_key] = this.state.addTaskflowForm[name] ? Number(this.state.addTaskflowForm[name]) : '';
               
                if (linkedFormula && linkedFormula.formula && linkedFormula?.formula?.includes(i.formula_key) && document.getElementById(i.formula_key)) {
                    document.getElementById(i.formula_key).required = "required"
                }
            })
            try {   
               let net_productivity = linkedFormula && linkedFormula.formula ?  eval(linkedFormula.formula) : '';
               this.setState({
                    addTaskflowForm     :       {
                        ...this.state.addTaskflowForm,
                        production                :       net_productivity ? parseFloat(net_productivity).toFixed(2) : this.state.addTaskflowForm.production
                    }
               })
            }catch(e){
               
            }
        }
    } 

    closingDateTimeModalInit        =       ()      =>      {
        this.closingDateTimeModal.show()
    }

    submitClosingDateTimeForm       =       (e)     =>      {
        e.preventDefault();
        let closing_date_display        =       this.state.addTaskflowForm && this.state.addTaskflowForm.end_cl_date_time ? moment(this.state.addTaskflowForm.end_cl_date_time).format('dd-MMM-YYYY') : '';
        let closing_time_display        =       this.state.addTaskflowForm && this.state.addTaskflowForm.end_cl_date_time ? moment(this.state.addTaskflowForm.end_cl_date_time).format('HH:mm AA') : '';
        
    }

    submitTaskflowForm          =       (e)     =>      {
        e.preventDefault();
       
       
        if(this.state.taskStartFormData){
            let additional_attributes = {};
            let additional_attributes_fields    =       this.state.allFieldList && this.state.allFieldList.length > 0 ? this.state.allFieldList.filter(fd => fd.is_additional_attribute == 'Y' ? true :false) : [];
            if(additional_attributes_fields && additional_attributes_fields.length > 0 ){
                additional_attributes_fields.forEach((i, k) => {
                    let name = 'additional_attributes_' + i.key;
                    additional_attributes[i.key] = this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : '';
                })
            } 
            let frmData = { ...this.state.addTaskflowForm, step_id: this.state.stepDetail?.id, additional_attributes: additional_attributes, counterlog_transaction_id: this.state.counterlog.transaction_id }
            this.setState({ saveFormSubmitting: true });
            HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/counterlog/activity_task/start', this.props.access_token, null, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadTaskflowModal.hide();
                if (this.props.afterSaveTask) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveTask(this.state.date, asset_id);
                }

                this.setState({ addTaskflowForm: { ...this.taskflowFormInit } })
            }).then(() => this.setState({ saveFormSubmitting: false }))
        }else{
            let additional_attributes = {},production_additional_attributes={};
            let additional_attributes_fields    =       this.state.nextStepFields && this.state.nextStepFields.length > 0 ? this.state.nextStepFields[0]?.fields?.filter(fd => fd?.is_additional_attribute == 'Y' ? true :false) : [];
           
            if(additional_attributes_fields && additional_attributes_fields.length > 0 ){
                additional_attributes_fields.forEach((i, k) => {
                    let name = 'additional_attributes_' + i.key;
                    additional_attributes[i.key] = this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : '';
                })
            }
            if(this.state.productionAddtionalAttr && this.state.productionAddtionalAttr.length > 0 ){
                this.state.productionAddtionalAttr.forEach((i, k) => {
                    let name = 'additional_attributes_' + i.key;
                    production_additional_attributes[i.key] = this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : '';
                })
            }
           
             let frmData = { 
                ...this.state.addTaskflowForm, 
                step_id                     :            this.state.nextStepDetail && this.state.nextStepDetail.id ? this.state.nextStepDetail.id  :null,
                transaction_id              :           this.state.taskData.transaction_id ,
                additional_attributes       :           additional_attributes,
                production_additional_attributes     :           production_additional_attributes
            }
            
            this.setState({ saveFormSubmitting: true });
            HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/counterlog/activity_task/close', this.props.access_token, null, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadTaskflowModal.hide();
                if (this.props.afterSaveTask) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveTask(this.state.date, asset_id);
                }

                this.setState({ addTaskflowForm: { ...this.taskflowFormInit } })
            }).then(() => this.setState({ saveFormSubmitting: false }))
        }
        
    }

    proceedTaskFlowForm         =       ()      =>      {
        if (this.state.addTaskflowForm && this.state.addTaskflowForm.activity_id) {
            this.loadFormDataFromApi(this.state.addTaskflowForm.activity_id)
        } else {
            toast.error('Please Select Activity', { position: toast.POSITION.TOP_RIGHT });
        }
    }

    showStepsJsx                    =       (field_keys = [],formData=null,additional_attribute_list=[],detail=null)      =>      {
        
        let formJsx         =       [];
        if(field_keys && field_keys.length > 0){
            if(field_keys.includes('starting_readings')){
                formJsx.push(this.taskStartingReadingJsx())
            }else if(!field_keys.includes('starting_readings') && field_keys.includes('task_start_time')){
                formJsx.push(this.taskStartTimeJsx())
            }else if(!field_keys.includes('closing_readings') && field_keys.includes('task_complete_time')){
                formJsx.push(this.taskCloseTimeJsx())
            }

            if(field_keys.includes('counterlog_readings')){
                formJsx.push(this.taskCounterlogReadingJsx())
            }

            if(field_keys.includes('operator')){
                formJsx.push(this.taskOperatorJsx())
            }
            if(field_keys.includes('workstation')){
                formJsx.push(this.taskWorkstationJsx())
            }
            if(field_keys.includes('activity_count')){
                formJsx.push(this.taskActivityCountJsx(formData))
            }
            if(field_keys.includes('productivity')){
               
                formJsx.push(this.taskProductivitySaveJsx(formData))
            }
            if(field_keys.includes('productivity_remark')){
                formJsx.push(this.taskProductivityRemarkJsx())
            }
            if(field_keys.includes('material')){
                formJsx.push(this.taskMaterialJsx(formData))
            }

            
            if(field_keys.includes('attachment')){
                formJsx.push(this.taskMaterialDischargeJsx(formData))
            }

           
            if(field_keys.includes('workstation_from')){
                formJsx.push(this.taskWorkstationFromJsx(formData))
            }
            if(field_keys.includes('workstation_to')){
                formJsx.push(this.taskWorkstationToJsx(formData))
            }
           
            if(field_keys.includes('closing_remarks')){
                formJsx.push(this.taskClosingRemarkJsx(formData))
            }
          
        }

        return formJsx;
    }

    showPreviousStepDetailJsx       =       (previous_steps = null,additional_attributes=[])       =>      {
        let formJsx         =       [];
       
        if(previous_steps && previous_steps.detail){
            
            let show_steps  =  previous_steps.fields && previous_steps.fields.length > 0 ? previous_steps.fields.map(step => step.field_key) : [];

            if(show_steps.includes('counterlog_readings')){
                formJsx.push(this.taskCounterlogViewJsx(previous_steps.detail))
            }
            if(show_steps.includes('operator')){
               
                formJsx.push(this.taskOperatorviewJsx(previous_steps.detail))
            }

            if(show_steps.includes('workstation')){
                formJsx.push(this.taskWorkstationViewJsx(previous_steps.detail))
            }
            
            if(this.state.taskData && this.state.taskData.additional_attributes && previous_steps.level == 1) {
                let additional_attribute = previous_steps.fields && previous_steps.fields.length > 0 && previous_steps.fields.filter(ad => this.state.taskData.additional_attributes.hasOwnProperty(ad.key));
                
                 if(additional_attribute && additional_attribute.length > 0){
                    additional_attribute.forEach(attr => {
                        let value      = this.state.taskData.additional_attribute_data.find(data => data.key == attr.key);
                        formJsx.push(this.showPreviousStepAdditionalAttrbJsx({...attr,value : value && value.value_display  ? value.value_display : ''}))
                    })
                 } 
                // if(additional_attribute){
                //     formJsx.push(this.showPreviousStepAdditionalAttrbJsx({...additional_attribute,value : this.state.taskData.additional_attributes[additional_attribute.key]}))
                // }
            }    
                  

            if(show_steps.includes('starting_readings') || show_steps.includes('closing_readings') ){
                formJsx.push(this.taskCounterlogReadingViewJsx(previous_steps,show_steps))
            }else if(!show_steps.includes('starting_readings') && show_steps.includes('task_start_time')){
                formJsx.push(this.taskStartTimeViewJsx())
            }else if(!show_steps.includes('closing_readings') && show_steps.includes('task_complete_time')){
                formJsx.push(this.taskCloseTimeViewJsx())
            }

           

            if(show_steps.includes('productivity')){
                formJsx.push(this.taskProductivityViewJsx(previous_steps.detail))
            }
            if(show_steps.includes('material')){
                formJsx.push(this.taskMaterialViewJsx(previous_steps.detail))
            }
           
            

            if(show_steps.includes('attachment')){
                formJsx.push(this.taskMatrialDischargeViewJsx(previous_steps.detail))
            }

            if(show_steps.includes('activity_count')){
                formJsx.push(this.taskActivityCountViewJsx(previous_steps.detail))
            }

            if(show_steps.includes('workstation_from')){
                formJsx.push(this.taskWorkstationFromViewJsx(previous_steps.detail))
            }
            if(show_steps.includes('workstation_to')){
                formJsx.push(this.taskWorkstationToViewJsx(previous_steps.detail))
            }
            if(show_steps.includes('closingremarks')){
                formJsx.push(this.taskClosingRemarksViewJsx(previous_steps.detail))
            }

            if(this.state.taskData && this.state.taskData.additional_attributes && previous_steps.level !== 1 ) {
                
                let additional_attribute = previous_steps.fields && previous_steps.fields.length > 0 && previous_steps.fields.filter(ad => this.state.taskData.additional_attributes.hasOwnProperty(ad.key));
                
                if(additional_attribute && additional_attribute.length > 0){
                    additional_attribute.forEach(attr => {
                        let value      = this.state.taskData.additional_attribute_data.find(data => data.key == attr.key);
                        formJsx.push(this.showPreviousStepAdditionalAttrbJsx({...attr,value : value && value.value_display  ? value.value_display : ''}))
                    })
                } 
            } 
            if(show_steps.includes('productivity_remark')){
                formJsx.push(this.taskProductivityRemarkViewJsx(previous_steps.detail))
            }
           
            

            
        }
        return formJsx;
    }

    showPreviousStepAdditionalAttrbJsx      =       (additional_attributes=null,previous_step)      =>      {
       
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
               
                        <tr>
                            <td style={{ width: "26%" }} className="text-capitalize">{additional_attributes && additional_attributes.field_name ? additional_attributes.field_name : "-" }</td>
                            <th>{additional_attributes && additional_attributes.value ? additional_attributes.value : "-" }</th>
                        </tr>
                  
            </tbody>
        </table>)
    }

    showAddtionalAttributesJsx      =       (keys = [])     =>      {
       
        return (<div>
            {keys && keys.length > 0 ?

                keys.map((i, k) => {
                    let name = 'additional_attributes_' + i.key;
                    let input = null;
                    if (i.additional_attribute.type == 'text') {
                        input = <input
                            name={name}
                            type="text"
                            value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            placeholder={`Please enter ${i.field_name}`}
                        />
                    } else if (i.additional_attribute.type == 'numeric') {
                        input = <input
                            name={name}
                            type="number"
                            id={i.formula_key}
                            value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            placeholder={`Please enter ${i.field_name}`}
                        />
                    } else if (i.additional_attribute.type == 'date') {
                        input = <DatePicker
                            selected={
                                this.state.addTaskflowForm[name]
                                    ? moment(this.state.addTaskflowForm[name]).toDate()
                                    : false
                            }
                            name={name}
                            onChange={(value, event) => this.formDateHandler(name, value, 'addTaskflowForm')}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control form-control-sm"}
                            showMonthDropdown
                            showYearDropdown
                            autoComplete='off'
                            scrollMonthYearDropdown
                            placeholderText={`Please Enter ${i.field_name}`}

                        />
                    } else if (i.additional_attribute.type == 'dropdown') {
                        let options = i.additional_attribute.options.map((t) => { return { value: t, label: t } });
                        input = <TapSelect
                            menuPlacement="top"
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTaskflowForm', name)}
                            options={options}
                            isSearchable={true}
                            isClearable={true}
                            value={options.find(o => o.value == this.state.addTaskflowForm[name])}
                            placeholder={`Please Select ${i.field_name}`}
                            containerHeight="30px"
                            fontSize="93%"
                        />
                    }else if (i.additional_attribute.type == 'datetime') {
                        input = <DatePicker
                            selected={
                                this.state.addTaskflowForm[name]
                                    ? moment(this.state.addTaskflowForm[name]).toDate()
                                    : false
                            }
                            name={name}
                            onChange={(value, event) => this.formDateHandler(name, value, 'addTaskflowForm', 'YYYY-MM-DD HH:mm:ss')}
                            dateFormat="dd-MMM-yyyy hh:mm a"
                            className={"form-control form-control-sm"}
                            showMonthDropdown
                            showYearDropdown
                            showTimeSelect
                            timeIntervals={5}
                            timeCaption="Time"
                            autoComplete='off'
                            scrollMonthYearDropdown
                            placeholderText={`Please Enter ${i.field_name}`}

                        />
                    }

                    return (<div key={k} className="row align-items-center my-2">
                        <div className="col-sm-3 col-form-label-sm col-form-label fw-bold text-capitalize">{i.field_name}</div>
                        <div className="col-sm-7">{input}</div>

                    </div>);
                })

                : null}
        </div>)
    }
 
    updateTaskflowModalJsx           =   ()  =>  {
        
        let field_keys                      =       this.state.allFieldList && this.state.allFieldList.length > 0 ? this.state.allFieldList.filter(fd => fd.is_additional_attribute == 'N' ? true :false).map(fl => {return(fl.field_key)}) : [];
        let additional_attributes_fields    =       this.state.allFieldList && this.state.allFieldList.length > 0 ? this.state.allFieldList.filter(fd => fd.is_additional_attribute == 'Y' ? true :false) : [];
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(this.state.shiftDetail){
            if(this.state.shiftDetail && this.state.shiftDetail.shift_start == this.state.shiftDetail.shift_end){
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").toDate()
            }
        }

        let next_step_fields     =       this.state.nextStepFields && this.state.nextStepFields.length > 0 ? this.state.nextStepFields.map(fl => {return(fl.fields.map(fd => fd.field_key))}).flat() : [];
        let previous_field_keys  =       this.state.previousStepDetails && this.state.previousStepDetails.length > 0 ? this.state.previousStepDetails.map(fl => {return(fl.fields.map(fd => fd.field_key))}).flat() : [];
        
        
       
        return <div className="modal fade" id={this.uploadTaskflowModalId} tabIndex="-1">
            <div className={['modal-dialog modal-lg', this.state.taskStartFormData || this.state.initalizeTaskflow == false ? '' : 'modal-dialog-scrollable'].join(' ')}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            <span>Log Task for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                            {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                        </h5>

                    </div>
                    <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-borderless text-sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "26%" }}>Asset Name (Code)</td>
                                    <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                </tr>
                                <tr>
                                    <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Ax>
                        <div className="modal-body">
                            {this.state.formDataLoading ? <Loader />
                                : <form className={['p-2',].join(' ')} onSubmit={this.submitTaskflowForm} id={this.updateTaskflowFormId}>
                                    {!this.state.taskData ? <div className="row ">

                                        <div className='col-sm-3'>
                                            <div className='fw-bold require col-form-label-sm'>Activity Name
                                            </div>
                                        </div>
                                        <div className='col-sm-7'>
                                            <TapSelect
                                                options={this.state.allActivityList}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addTaskflowForm', 'activity_id');
                                                }}
                                                isSearchable={true}
                                                value={this.state.allActivityList.find(s => this.state.addTaskflowForm.activity_id == s.value)}
                                                isClearable={true}
                                                placeholder="Select Activity Name"
                                                autoCompelete="off"
                                                required={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isDisabled={this.state.initalizeTaskflow == true ? true : false}
                                            />

                                        </div>

                                    </div> : null}
                                    {this.state.initalizeTaskflow == true
                                        ? <Ax>
                                            <div className="tab_content_wrapper fw-bold my-2">{this.state.stepDetail && this.state.stepDetail.name ? this.state.stepDetail.name : "-"}</div>
                                            {this.showStepsJsx(field_keys)}
                                            {this.showAddtionalAttributesJsx(additional_attributes_fields)}
                                        </Ax>
                                        : null}
                                    {this.state.taskData ?
                                        <Ax>
                                            <table className="table table-bordered bg-white">
                                                <thead className="table-secondary">
                                                    <tr>
                                                        <th style={{ width: "33%" }}>ID</th>
                                                        <th style={{ width: "33%" }}>Date</th>
                                                        <th style={{ width: "33%" }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{this.state.taskData.transaction_id ? this.state.taskData.transaction_id : "-"}</td>
                                                        <td scope="col">{this.state.taskData.task_start_at_display ? this.state.taskData.task_start_at_display : "-"}</td>
                                                        <td scope="col">{this.state.taskData.task_in_progress && this.state.taskData.task_in_progress == 'Y' ? "Inprogress" : "Closes"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Ax>
                                        : null}
                                    {this.state.previousStepDetails && this.state.previousStepDetails.length > 0
                                        ? this.state.previousStepDetails.map(ps => {
                                            let nextStepFields = this.state.nextStepFields && this.state.nextStepFields.length > 0 ? this.state.nextStepFields : null;
                                            let next_field_keys = nextStepFields && nextStepFields.fields && nextStepFields.fields.length > 0 ? nextStepFields.fields.filter(fd => fd.is_additional_attribute == 'N' ? true : false).map(fl => { return (fl.field_key) }) : [];
                                            let previous_field_keys = ps && ps.fields && ps.fields.length > 0 ? ps.fields.filter(fd => fd.is_additional_attribute == 'N' ? true : false).map(fl => { return (fl.field_key) }) : [];
                                            let name = ps.name;

                                            if (previous_field_keys && previous_field_keys.length == 1 && previous_field_keys.includes('loaded_weight')) {
                                                name = '';

                                            }
                                            if (previous_field_keys && previous_field_keys.length == 1 && previous_field_keys.includes('unloaded_weight')) {
                                                name = '';

                                            }
                                            return (<Ax>
                                                {/* {next_field_keys && next_field_keys.length > 0 && !next_field_keys.includes('loaded_weight') && next_field_keys.includes('unloaded_weight')
                                                    ? <Ax> */}
                                                {name ? <div className="tab_content_wrapper fw-bold my-2">{name}</div> : null}
                                                {ps.level == 1 ?
                                                    <table className="table mt-2 mb-0 table-hover table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "26%" }}>Activity Name</td>
                                                                <th>{this.state.taskData && this.state.taskData.activity && this.state.taskData.activity.activity_name ? this.state.taskData.activity.activity_name : "-"} </th>
                                                            </tr>


                                                        </tbody>
                                                    </table> : null}
                                                {this.showPreviousStepDetailJsx(ps)}
                                                {previous_field_keys && previous_field_keys.length > 0 && !previous_field_keys.includes('loaded_weight') && !previous_field_keys.includes('unloaded_weight') ? <table className="table mb-0 mt-0 table-hover table-bordered">
                                                    <tbody>

                                                        <tr>
                                                            <td className="text-start" style={{ width: "26%" }}>Entry By</td>
                                                            <th>{ps.detail && ps.detail.step_submit_user ? ps.detail.step_submit_user.full_name : "-"}</th>

                                                        </tr>
                                                        <tr>
                                                            <td className="text-start" style={{ width: "26%" }}>Entry Timestamp</td>
                                                            <th>{ps.detail && ps.detail.step_submit_at_display ? ps.detail.step_submit_at_display : "-"}</th>

                                                        </tr>
                                                    </tbody>
                                                </table> : null}
                                            </Ax>

                                            )
                                        })
                                        : null}
                                    {next_step_fields?.includes('loaded_weight') || next_step_fields?.includes('unloaded_weight')
                                        || previous_field_keys?.includes('loaded_weight') || previous_field_keys?.includes('unloaded_weight') ? this.taskWeightcheckAllJsx() : null}

                                    {this.state.nextStepDetail && this.state.nextStepDetail.can_submit && this.state.nextStepDetail.can_submit == 'Y' && this.state.nextStepFields && this.state.nextStepFields.length > 0
                                        ? this.state.nextStepFields.map(ps => {

                                            let next_field_keys = ps.fields && ps.fields.length > 0 ? ps.fields.filter(fd => fd.is_additional_attribute == 'N' ? true : false).map(fl => { return (fl.field_key) }) : [];
                                            let next_additional_attributes_fields = ps.fields && ps.fields.length > 0 ? ps.fields.filter(fd => fd.is_additional_attribute == 'Y' ? true : false) : [];
                                            let name = ps.name;


                                            if (next_field_keys && next_field_keys.length == 1 && next_field_keys.includes('closing_readings')) {
                                                name = '';
                                            }

                                            if (next_field_keys && next_field_keys.length == 1 && next_field_keys.includes('loaded_weight')) {
                                                name = '';

                                            }
                                            if (next_field_keys && next_field_keys.length == 1 && next_field_keys.includes('unloaded_weight')) {
                                                name = '';

                                            }
                                            return (<Ax>
                                                {/* {next_field_keys && next_field_keys.length > 0 && !next_field_keys.includes('loaded_weight') && next_field_keys.includes('unloaded_weight')
                                                    ? <Ax> */}
                                                {name ? <div className="tab_content_wrapper fw-bold my-2">{name}</div> : null}
                                                {this.showStepsJsx(next_field_keys, ps?.form_data, next_additional_attributes_fields)}
                                                {next_field_keys && next_field_keys.length > 0 && !next_field_keys.includes('loaded_weight') && !next_field_keys.includes('unloaded_weight') ? this.showAddtionalAttributesJsx(next_additional_attributes_fields) : null}
                                                {/* </Ax> : null} */}
                                            </Ax>)
                                        })
                                        : null}



                                </form>}
                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">
                                {this.state.includeStartingReadings == true ? <Ax>
                                    <input
                                        name="copy_reading_counterlog"
                                        type="checkbox"
                                        value={this.state.copy_reading_counterlog}
                                        onChange={(e) => {
                                            this.setState({ copy_reading_counterlog: this.state.copy_reading_counterlog == 'N' ? 'Y' : 'N' }, () => this.copyPreviousHandler())
                                        }}
                                        checked={this.state.copy_reading_counterlog == 'Y'}
                                        className="form-check-input"
                                        id="copy_reading_counterlog"
                                    />
                                    <label className="form-check-label mx-2" htmlFor="copy_reading_counterlog">Copy Reading from Latest Counterlog </label>
                                </Ax> : null}
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Cancel</button>
                                {this.state.initalizeTaskflow == true || this.state.taskData ?
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting || this.state.nextStepDetail && this.state.nextStepDetail.can_submit && this.state.nextStepDetail.can_submit == 'N'} form={this.updateTaskflowFormId}>Update {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                    : <button type="button" className="btn btn-primary" disabled={this.state.saveFormSubmitting || this.state.nextStepDetail && this.state.nextStepDetail.can_submit && this.state.nextStepDetail.can_submit == 'N'} form={this.updateTaskflowFormId} onClick={() => this.proceedTaskFlowForm()}>Proceed {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                }
                            </div>
                        </div>

                    </Ax>

                </div>
            </div>
        </div>
    }

    taskStartingReadingJsx        =   ()      =>      {
        let labels      =       this.state.assetData && this.state.assetData.labels ? this.state.assetData.labels : {};
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(this.state.shiftDetail){
            if(this.state.shiftDetail && this.state.shiftDetail.shift_start == this.state.shiftDetail.shift_end){
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").toDate()
            }
        }
        return (<div>
           
            <div className="row ">
                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Counterlog</div>
                </div>
                <div className='col-sm-3'>
                    <div className='fw-bold  col-form-label-sm'>Starting Reading</div>
                </div>
                <div className='col-sm-4'>
                    <div className='fw-bold  col-form-label-sm'>Latest Reading</div>
                </div>

            </div>
            <div className="row align-items-center my-2">
                <div className="col-sm-3"><label className="form-label">Starting Date & Time</label></div>
                <div className="col-md-3 ">
                    <DatePicker
                        selected={
                            this.state.addTaskflowForm.start_cl_date_time
                                ? moment(this.state.addTaskflowForm.start_cl_date_time).toDate()
                                : false
                        }
                        name="start_cl_date_time"
                        onChange={(value, event) => { this.formDateHandler('start_cl_date_time', value, 'addTaskflowForm', 'YYYY-MM-DD HH:mm:ss') }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Starting Date & Time`}
                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                        minTime={moment(this.state.shiftDetail ? this.state.shiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                        maxTime={max_time}
                    />
                </div>

            </div>
            {this.state.assetData && this.state.assetData.hour_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'} </label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_hours_abs"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.addTaskflowForm.start_hours_abs}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    <div className='col-sm-4'>
                        {this.state.latestCounterLog

                            ? <span className="text-sm form-text">
                                {this.state.latestCounterLog ? `${this.state.latestCounterLog.hours_abs} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                            </span>
                            : null}
                    </div>

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.hour_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_hours_abs2"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"
                            value={this.state.addTaskflowForm.start_hours_abs2}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />


                    </div>
                    {this.state.latestCounterLog ? <div className=" col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.hours_abs2} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div>
                        : null}

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.hour_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter3'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_hours_abs3"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"

                            value={this.state.addTaskflowForm.start_hours_abs3}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    {this.state.latestCounterLog ? <div className=" col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.hours_abs3} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div> : null}

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.hour_meter4 == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.hour_meter4 ? labels.hour_meter4 : 'Hour Meter 4'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_hours_abs4"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"

                            value={this.state.addTaskflowForm.start_hours_abs4}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    {this.state.latestCounterLog ? <div className="col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.hours_abs4} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div> : null}

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.odo_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.odo_meter ? labels.odo_meter : 'Odo Meter'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_odometer_abs"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"

                            value={this.state.addTaskflowForm.start_odometer_abs}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    {this.state.latestCounterLog ? <div className="col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.odometer_abs} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div> : null}

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.odo_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.odo_meter2 ? labels.odo_meter2 : 'Odo Meter 2'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_odometer_abs2"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"

                            value={this.state.addTaskflowForm.start_odometer_abs2}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    {this.state.latestCounterLog ? <div className="col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.odometer_abs2} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div> : null}

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.odo_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.odo_meter3 ? labels.odo_meter3 : 'Odo Meter 3'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_odometer_abs3"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"

                            value={this.state.addTaskflowForm.start_odometer_abs3}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    {this.state.latestCounterLog ? <div className="col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.odometer_abs3} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div> : null}

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.operation_cycle == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.operation_cycle ? labels.operation_cycle : 'Operation Cycle'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_cycle_abs"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"

                            value={this.state.addTaskflowForm.start_cycle_abs}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    {this.state.latestCounterLog ? <div className="col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.cycle_abs} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div> : null}

                </div>
                : null
            }
            {this.state.assetData && this.state.assetData.operation_cycle2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                ? <div className='row my-2 align-items-center'>
                    <div className='col-sm-3'><label className="form-label require">{labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation Cycle 2'}</label></div>
                    <div className='col-sm-3'>
                        <input
                            name="start_cycle_abs2"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                            placeholder="Please Enter Reading"

                            value={this.state.addTaskflowForm.start_cycle_abs2}
                            required={true}
                            style={{ height: "30px", fontSize: "93%" }}
                        />

                    </div>
                    {this.state.latestCounterLog ? <div className="col-sm-4 text-sm form-text">
                        {this.state.latestCounterLog ? `${this.state.latestCounterLog.cycle_abs2} @ ${this.state.latestCounterLog.feed_date_display}` : '-'}
                    </div> : null}

                </div>
                : null
            }
        </div>)
    }

    taskCounterlogReadingViewJsx        =       (taskDetail,show_steps)      =>      {
        let detail      =       taskDetail && taskDetail.detail  ? taskDetail.detail : null;
        let labels      =       this.state.assetData && this.state.assetData.labels ? this.state.assetData.labels : {};
        let max_time    =       moment(new Date(), "hh:mm a ").toDate();
       
        if(this.state.shiftDetail){
            if(this.state.shiftDetail && this.state.shiftDetail.shift_start == this.state.shiftDetail.shift_end){
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").toDate()
            }
        }
        let next_step_fields    =   this.state.nextStepFields && this.state.nextStepFields.length > 0 ? this.state.nextStepFields.map(nt => {return(nt.fields.map(fd => fd.field_key))}).flat() : [];
        
        let task_date_display       =   taskDetail && taskDetail.detail && taskDetail.detail.start_cl_date_time_display ? taskDetail.detail.start_cl_date_time_display.split(" ") : "";
        let task_end_display        =   taskDetail && taskDetail.detail && taskDetail.detail.end_cl_date_time_display  ? taskDetail.detail.end_cl_date_time_display.split(" ") : "";
       

        return (<div>
            <div className="tab_content_wrapper fw-bold my-2">Readings</div>

            <div className='row'>
                <div className='col-sm-12'>
                    <table className="table mt-2 mb-0 table-hover table-bordered table-sm">
                        <thead className="table-secondary">
                            <tr className="text-center">
                                <th scope="col" style={{ width: "26%" }}>Counterlog</th>
                                <th scope="col" style={{ width: "24%" }}>Task Starting</th>
                                <th scope="col" style={{ width: "24%" }}>Task Closing</th>
                                <th scope="col" style={{ width: "24%" }}>Total Running</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="text-center">
                                <td className="text-start">Date</td>
                                <th>{task_date_display && task_date_display.length > 0 ? task_date_display[0] : "-"}</th>
                                {/* {this.state.max_step_level == this.state.nextStepDetail?.level || show_steps && show_steps.includes('closing_readings') ?
                                 <td rowSpan={2} onClick={() => this.closingDateTimeModalInit()}>
                                    +
                                 </td>
                                : null} */}
                                {this.state.max_step_level == this.state.nextStepDetail?.level || show_steps && show_steps.includes('closing_readings') ?
                                    <td rowSpan={2}>
                                        <DatePicker
                                            selected={
                                                this.state.addTaskflowForm.end_cl_date_time
                                                    ? moment(this.state.addTaskflowForm.end_cl_date_time).toDate()
                                                    : false
                                            }
                                            name="end_cl_date_time"
                                            onChange={(value, event) => { 
                                                this.formDateHandler('end_cl_date_time', value, 'addTaskflowForm', 'YYYY-MM-DD HH:mm:ss',() => {
                                                    var start_cl_date_time = this.state.taskData && this.state.taskData.start_cl_date_time_display ? moment(this.state.taskData.start_cl_date_time) : "";//now
                                                    var end_cl_date_time = this.state.addTaskflowForm && this.state.addTaskflowForm.end_cl_date_time ? moment(this.state.addTaskflowForm.end_cl_date_time) : '';
                                                    let daydiffenence   =  start_cl_date_time && end_cl_date_time ? end_cl_date_time.diff(start_cl_date_time, 'days') : ''; 
                                                    let hourDiffernce   =   start_cl_date_time && end_cl_date_time ? end_cl_date_time.diff(start_cl_date_time, 'hours') : ''; 
                                                    this.setState({
                                                        taskDayDiffernce    :   daydiffenence,
                                                        taskHourDifference  :   hourDiffernce,
                                                    })
                                                   
                                                })
                                                
                                             }}
                                            dateFormat="dd-MMM-yyyy hh:mm a"
                                            className={"form-control form-control-sm"}
                                            showMonthDropdown
                                            showYearDropdown
                                            showTimeSelect
                                            timeIntervals={5}
                                            timeCaption="Time"
                                            autoComplete="off"
                                            scrollMonthYearDropdown
                                            required={true}
                                            placeholderText={` Closing Date & Time`}
                                            autoFocus={true}
                                            minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                                            maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(), 'YYYY-MM-DD').toDate() : ''}
                                        // minTime={moment(this.state.taskData && this.state. ? this.state.shiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                                        // maxTime={max_time}
                                        />
                                    </td> : null}
                                    <td>{this.state.taskDayDiffernce ? <span>{this.state.taskDayDiffernce} Days</span> : "-"}</td>
                                
                            </tr>
                            <tr className="text-center">
                                <td className="text-start">Time</td>
                                <th>{task_date_display && task_date_display.length > 0 ? task_date_display[1] + ' ' + task_date_display[2] : "-"}</th>
                                <td>{this.state.taskHourDifference ? <span>{this.state.taskHourDifference} Hours</span> : "-"}</td>
                            </tr>

                            {/* {show_steps && (show_steps.includes('starting_readings') || show_steps.includes('closing_readings'))
                                ? <Ax> */}
                            {this.state.assetData && this.state.assetData.hour_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'}</td>
                                    <th>{show_steps && show_steps.includes('starting_readings') && detail && detail.start_hours_abs ? detail.start_hours_abs : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_hours_abs"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_hours_abs}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_hours_abs && this.state.addTaskflowForm.end_hours_abs && this.state.addTaskflowForm.end_hours_abs > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_hours_abs).toFixed(2) - parseFloat(Number(detail.start_hours_abs)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_hours_abs: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_hours_abs"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_hours_abs && this.state.addTaskflowForm.running_hours_abs > 0 && detail && detail.start_hours_abs) {
                                                    let start_hrm = Number(detail.start_hours_abs); let running_hours_abs = Number(this.state.addTaskflowForm.running_hours_abs)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_hours_abs: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_hours_abs}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.hour_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'}</td>
                                    <th>{detail && detail.start_hours_abs2 ? detail.start_hours_abs2 : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_hours_abs2"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_hours_abs2}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_hours_abs2 && this.state.addTaskflowForm.end_hours_abs2 && this.state.addTaskflowForm.end_hours_abs2 > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_hours_abs2).toFixed(2) - parseFloat(Number(detail.start_hours_abs2)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_hours_abs2: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_hours_abs2"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_hours_abs2 && this.state.addTaskflowForm.running_hours_abs2 > 0 && detail && detail.start_hours_abs2) {
                                                    let start_hrm = Number(detail.start_hours_abs2); let running_hours_abs = Number(this.state.addTaskflowForm.running_hours_abs2)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_hours_abs2: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_hours_abs2}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.hour_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter 3'}</td>
                                    <th>{detail && detail.start_hours_abs3 ? detail.start_hours_abs3 : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_hours_abs3"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_hours_abs3}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_hours_abs3 && this.state.addTaskflowForm.end_hours_abs3 && this.state.addTaskflowForm.end_hours_abs3 > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_hours_abs3).toFixed(2) - parseFloat(Number(detail.start_hours_abs3)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_hours_abs3: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_hours_abs3"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_hours_abs3 && this.state.addTaskflowForm.running_hours_abs3 > 0 && detail && detail.start_hours_abs3) {
                                                    let start_hrm = Number(detail.start_hours_abs3); let running_hours_abs = Number(this.state.addTaskflowForm.running_hours_abs3)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_hours_abs3: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_hours_abs3}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.hour_meter4 == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.hour_meter4 ? labels.hour_meter4 : 'Hour Meter 4'}</td>
                                    <th>{detail && detail.start_hours_abs4 ? detail.start_hours_abs4 : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_hours_abs4"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_hours_abs4}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_hours_abs4 && this.state.addTaskflowForm.end_hours_abs4 && this.state.addTaskflowForm.end_hours_abs4 > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_hours_abs4).toFixed(2) - parseFloat(Number(detail.start_hours_abs4)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_hours_abs4: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_hours_abs4"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_hours_abs4 && this.state.addTaskflowForm.running_hours_abs4 > 0 && detail && detail.start_hours_abs4) {
                                                    let start_hrm = Number(detail.start_hours_abs4); let running_hours_abs = Number(this.state.addTaskflowForm.running_hours_abs4)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_hours_abs4: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_hours_abs4}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.odo_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.odo_meter ? labels.odo_meter : 'Odometer'}</td>
                                    <th>{detail && detail.start_odometer_abs ? detail.start_odometer_abs : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_odometer_abs"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_odometer_abs}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_odometer_abs && this.state.addTaskflowForm.end_odometer_abs && this.state.addTaskflowForm.end_odometer_abs > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_odometer_abs).toFixed(2) - parseFloat(Number(detail.start_odometer_abs)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_odometer_abs: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_odometer_abs"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_odometer_abs && this.state.addTaskflowForm.running_odometer_abs > 0 && detail && detail.start_odometer_abs) {
                                                    let start_hrm = Number(detail.start_odometer_abs); let running_hours_abs = Number(this.state.addTaskflowForm.running_odometer_abs)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_odometer_abs: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_odometer_abs}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.odo_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.odo_meter2 ? labels.odo_meter2 : 'Odometer 2'}</td>
                                    <th>{detail && detail.start_odometer_abs2 ? detail.start_odometer_abs2 : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_odometer_abs2"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_odometer_abs2}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_odometer_abs2 && this.state.addTaskflowForm.end_odometer_abs2 && this.state.addTaskflowForm.end_odometer_abs2 > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_odometer_abs2).toFixed(2) - parseFloat(Number(detail.start_odometer_abs2)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_odometer_abs2: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_odometer_abs2"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_odometer_abs2 && this.state.addTaskflowForm.running_odometer_abs2 > 0 && detail && detail.start_odometer_abs2) {
                                                    let start_hrm = Number(detail.start_odometer_abs2); let running_hours_abs = Number(this.state.addTaskflowForm.running_odometer_abs2)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_odometer_abs2: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_odometer_abs2}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.odo_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.odo_meter3 ? labels.odo_meter3 : 'Odometer 3'}</td>
                                    <th>{detail && detail.start_odometer_abs3 ? detail.start_odometer_abs3 : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_odometer_abs3"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_odometer_abs3}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_odometer_abs3 && this.state.addTaskflowForm.end_odometer_abs3 && this.state.addTaskflowForm.end_odometer_abs3 > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_odometer_abs3).toFixed(2) - parseFloat(Number(detail.start_odometer_abs3)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_odometer_abs3: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_odometer_abs3"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_odometer_abs3 && this.state.addTaskflowForm.running_odometer_abs3 > 0 && detail && detail.start_odometer_abs3) {
                                                    let start_hrm = Number(detail.start_odometer_abs3); let running_hours_abs = Number(this.state.addTaskflowForm.running_odometer_abs3)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_odometer_abs3: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_odometer_abs3}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.operation_cycle == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.operation_cycle ? labels.operation_cycle : 'Operation Cycle'}</td>
                                    <th>{detail && detail.start_cycle_abs ? detail.start_cycle_abs : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_cycle_abs"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_cycle_abs}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_cycle_abs && this.state.addTaskflowForm.end_cycle_abs && this.state.addTaskflowForm.end_cycle_abs > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_cycle_abs).toFixed(2) - parseFloat(Number(detail.start_cycle_abs)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_cycle_abs: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_cycle_abs"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_cycle_abs && this.state.addTaskflowForm.running_cycle_abs > 0 && detail && detail.start_cycle_abs) {
                                                    let start_hrm = Number(detail.start_cycle_abs); let running_hours_abs = Number(this.state.addTaskflowForm.running_cycle_abs)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_cycle_abs: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_cycle_abs}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {this.state.assetData && this.state.assetData.operation_cycle2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                                ?
                                <tr className="text-center">
                                    <td className="text-start">{labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation Cycle 2'}</td>
                                    <th>{detail && detail.start_cycle_abs2 ? detail.start_cycle_abs2 : "-"}</th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="end_cycle_abs2"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            placeholder="Please Enter Reading"
                                            value={this.state.addTaskflowForm.end_cycle_abs2}
                                            onKeyUp={(e) => {
                                                if (detail && detail.start_cycle_abs2 && this.state.addTaskflowForm.end_cycle_abs2 && this.state.addTaskflowForm.end_cycle_abs2 > 0) {
                                                    let running_hrm = parseFloat(this.state.addTaskflowForm.end_cycle_abs2).toFixed(2) - parseFloat(Number(detail.start_cycle_abs2)).toFixed(2);
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, running_cycle_abs2: parseFloat(running_hrm).toFixed(2) } })
                                                }
                                            }}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                    <th>{next_step_fields && next_step_fields.includes('closing_readings')
                                        ? <input
                                            name="running_cycle_abs2"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            onKeyUp={(e) => {
                                                if (this.state.addTaskflowForm.running_cycle_abs2 && this.state.addTaskflowForm.running_cycle_abs2 > 0 && detail && detail.start_cycle_abs2) {
                                                    let start_hrm = Number(detail.start_cycle_abs2); let running_hours_abs = Number(this.state.addTaskflowForm.running_cycle_abs2)
                                                    let end_hours_abs = start_hrm + running_hours_abs;
                                                    this.setState({ addTaskflowForm: { ...this.state.addTaskflowForm, end_cycle_abs2: parseFloat(end_hours_abs).toFixed(2) } })
                                                }
                                            }}
                                            placeholder="Total Running"
                                            value={this.state.addTaskflowForm.running_cycle_abs2}
                                            required={true}
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                        : null}
                                    </th>
                                </tr>
                                : null}
                            {/* </Ax>
                                : null} */}
                            {/* <tr className="text-center">
                                <td className="text-start">Entry By</td>
                                <th>{detail && detail.step_submit_user ? detail.step_submit_user.full_name : "-"}</th>

                            </tr>
                            <tr className="text-center">
                                <td className="text-start">Entry Timestamp</td>
                                <th>{detail && detail.step_submit_at_display ? detail.step_submit_at_display : "-"}</th>

                            </tr> */}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>)
    }

    taskOperatorJsx         =       ()      =>      {
        return (<div className="row ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Operator
                </div>
            </div>
            <div className='col-sm-7'>
                <InputEmployeeSearch
                    placeholder="Please Select Employee"
                    changeEvent={(selectedOption) => {
                        this.setState({ 
                            searchedEmployee: selectedOption && selectedOption.length > 0 ? selectedOption : [] 
                        });
                        this.tapSelectChange(selectedOption, 'addTaskflowForm', 'operator_ids')
                    }}
                    
                    menuPlacement="top"
                    search_site_ids={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                    containerHeight="30px"
                    fontSize="93%"
                    isMulti={true}
                    value={this.state.searchedEmployee && this.state.searchedEmployee.length > 0 ? this.state.searchedEmployee.map(a => { a.label = a.label; return a; }) : []}
                />

            </div>

        </div>)
    }

    taskOperatorviewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null;
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{  width: "26%" }}>Operator Name</td>
                    <th>{detail && detail.operators && detail.operators.length > 0 ? detail.operators.map(op => { return (op.name) }).join(', ') : null}</th>
                </tr>
            </tbody>
        </table>
        )
    }

    taskWorkstationJsx         =       ()      =>      {
        return (<div className="row ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation</div>
            </div>
            <div className='col-sm-7'>
                {this.state.addNewWorkstation == "Y" && this.props.group_permission.permissions.includes('iam-workstation-manage')
                    ? <input
                        name="workstation_name"
                        type="text"
                        value={this.state.addTaskflowForm.workstation_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                        placeholder="Please enter Workstation"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstation: selectedOption ? selectedOption : null,
                                addTaskflowForm: { ...this.state.addTaskflowForm, workstation_id: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                    />
                }
            </div>
            <div className="col-sm-2">
                <input
                    name="addNewWorkstation"
                    type="checkbox"
                    value={this.state.addNewWorkstation}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstation   :   this.state.addNewWorkstation == 'N' ? 'Y' : 'N',
                            addTaskflowForm     :   { ...this.state.addTaskflowForm, workstation_name: '',workstation_id:'' },
                            searchedWorkstation :   null
                        })
                    }}
                    checked={this.state.addNewWorkstation == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstation"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstation">Add New </label>
            </div>
            

        </div>)
    }

    taskWorkstationViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td  style={{  width: "26%" }}>Workstation</td>
                    <th>{detail && detail.workStation ? detail.workStation.name : "-"}</th>

                </tr>
            </tbody>
        </table>)
    }

    taskActivityCountJsx            =       (formData=null)      =>      {
        let activityType = formData && formData.activity_type && formData.activity_type.length > 0 ? formData.activity_type.map(at => {return({value:at.key,label:at.name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Activity Type</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={activityType}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addTaskflowForm', 'activity_type');
                        }}
                        isSearchable={true}
                        value={activityType.find(s => this.state.addTaskflowForm.activity_type == s.value)}
                        isClearable={true}
                        placeholder="Select Activity"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm '>No. of Session or Trip</div>
                </div>
                <div className='col-sm-7'>
                    <input
                        name="activity_count"
                        type="number"
                        value={this.state.addTaskflowForm.activity_count}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                        placeholder="Please enter No. of Session/Trip"
                    />

                </div>

            </div>
        </Ax>)
    }

    taskActivityCountViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }}>Activity Type </td>
                    <th>{detail && detail.activity_type_display ? detail.activity_type_display : "-"}</th>

                </tr>
                <tr>
                    <td style={{ width: "26%" }}>No. of Session or Trip </td>
                    <th>{detail && detail.activity_count ? detail.activity_count : "-"}</th>

                </tr>
               
            </tbody>
        </table>)
    }

    taskProductivitySaveJsx         =       (formData)      =>      {
        let production_units            =       formData && formData.production_units && formData.production_units.length > 0 ? formData.production_units.map(at => {return({value:at.measuring_unit,label:at.measuring_unit})})  : []
        let linkedFormula               =       formData && formData.production_formula && JSON.stringify(formData.production_formula) !== JSON.stringify({}) ? formData.production_formula : null;
        let formulaApplicable           =       formData && formData.apply_prodution_formula && formData.apply_prodution_formula == 'Y' ? 'Y' : 'N';
        let linkedFormulaHavingMeter    =       linkedFormula && linkedFormula.formula && linkedFormula?.formula?.includes('running_hrm') || linkedFormula?.formula?.includes('running_opc') || linkedFormula?.formula?.includes('running_km') ? true : false;
        let formula                     =       linkedFormula && linkedFormula.formula ? linkedFormula.formula : ""
                                                this.state.productionAddtionalAttr.forEach(production => {
                                                    if (formula && formula.includes(production.formula_key)) {
                                                        formula = formula.replace(production.formula_key, production.name)
                                                    }
                                                })

      return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm require'>Net Productivity</div>
                </div>
              <div className='col-sm-7'>
                  <input
                      name="production"
                      type="number"
                      autoFocus={true}
                      value={this.state.addTaskflowForm.production}
                      className="form-control form-control-sm"
                      autoComplete="off"
                      onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                      placeholder="Please enter Net Productivity"
                      required={true}
                      disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                  />

              </div>
              {linkedFormulaHavingMeter == true && formulaApplicable == 'Y' ?
                      <div className='text-danger text-sm offset-3 col-sm-7'>The formula contain total running (hours/km/opc) hence it cannot be calculated on this screen</div>
                      : null}
                  <div className='text-sm offset-3 col-sm-7 form-text '>{linkedFormula && linkedFormula.formula ? <span>Net Productivity = {formula ? formula : "-"}</span> : ""}</div>

            </div>
            <div className="row my-2">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm require'>Production Units</div>
                </div>
                <div className='col-sm-7'>
                    {this.state.addNewUnit == "Y"
                        ? <input
                            name="production_unit"
                            type="text"
                            value={this.state.addTaskflowForm.production_unit}
                            className="form-control form-control-sm"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                            placeholder="Please enter Productivity Unit"
                            required={true}
                            disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                        />
                        :
                        <TapSelect
                            options={production_units}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addTaskflowForm', 'production_unit');

                            }}
                            isSearchable={true}
                            value={production_units.find(s => this.state.addTaskflowForm.production_unit == s.value)}
                            isClearable={true}
                            placeholder="Select Productivity Unit"
                            autoCompelete="off"
                            required={true}
                            containerHeight="30px"
                            fontSize="93%"
                            menuPlacement="top"
                            isDisabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                        //isDisabled={this.state.linkedProductivityFormula ? true : false}
                        />
                    }
                </div>
                <div className="col-sm-2">
                    <input
                        name="addNewUnit"
                        type="checkbox"
                        value={this.state.addNewUnit}
                        onChange={(e) => {
                            this.setState({
                                addNewUnit: this.state.addNewUnit == 'N' ? 'Y' : 'N',
                                addTaskflowForm: { ...this.state.addTaskflowForm, unit: '' }
                            })
                        }}
                        checked={this.state.addNewUnit == 'Y'}
                        className="form-check-input"
                        id="addNewUnit"
                        disabled={formulaApplicable == 'Y' && linkedFormulaHavingMeter == false ? true : false}
                    //disabled={this.state.linkedProductivityFormula ? true : false}

                    />
                    <label className="form-check-label text-sm mx-2" htmlFor="addNewUnit">Add New </label>
                </div>

            </div>

            {
                this.state.productionAddtionalAttr && this.state.productionAddtionalAttr.length > 0
                    ? (
                        <Ax>

                            {
                                this.state.productionAddtionalAttr.map((i, k) => {
                                  
                                    let name = 'additional_attributes_' + i.key;
                                    let input = null;
                                    if (i.type == 'text') {
                                        input = <input
                                            name={name}
                                            type="text"
                                            id={i.formula_key}
                                            value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder={`Please enter ${i.name}`}
                                        />
                                    } else if (i.type == 'numeric') {
                                        input = <input
                                            name={name}
                                            type="number"
                                            id={i.formula_key}
                                            value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                                            onChange={(e) => this.formInputHandler(e, 'addTaskflowForm', (e) => this.calulateProductivityFormula(e, linkedFormula))}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder={`Please enter ${i.name}`}
                                        />
                                    } else if (i.type == 'date') {
                                        input = <DatePicker
                                            selected={
                                                this.state.addTaskflowForm[name]
                                                    ? moment(this.state.addTaskflowForm[name]).toDate()
                                                    : false
                                            }
                                            name={name}
                                            onChange={(value, event) => this.formDateHandler(name, value, 'addTaskflowForm')}
                                            dateFormat="dd-MMM-yyyy"
                                            className={"form-control form-control-sm"}
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete='off'
                                            scrollMonthYearDropdown
                                            placeholderText={`Please Enter ${i.name}`}

                                        />
                                    } else if (i.type == 'dropdown') {
                                        let options = i.options.map((t) => { return { value: t, label: t } });
                                        input = <TapSelect
                                            menuPlacement="top"
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTaskflowForm', name)}
                                            options={options}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={options.find(o => o.value == this.state.addTaskflowForm[name])}
                                            placeholder={`Please Select ${i.name}`}
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    }

                                    return (<div key={k} className="row align-items-center my-2">
                                        <div className="col-sm-3 col-form-label-sm col-form-label fw-bold">{i.name}</div>
                                        <div className="col-sm-7">{input}</div>

                                    </div>);
                                })
                            }
                        </Ax>
                    )
                    : null
            }

        </Ax>)
    }

    taskProductivityRemarkJsx       =       ()      =>      {
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Remark</div>
            </div>
            <div className='col-sm-7'>
                <textarea
                    name="productivity_remark"
                    value={this.state.addTaskflowForm.productivity_remark}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                    placeholder="Remark"
                />

            </div>

        </div>)
    }

    taskProductivityViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td  style={{  width: "26%" }}>Net Productivity </td>
                    <th>{detail && detail.production ? detail.production : "-"}</th>

                </tr>
                <tr>
                    <td  style={{  width: "26%" }}>Productivity Units </td>
                    <th>{detail && detail.production_unit ? detail.production_unit : "-"}</th>

                </tr>
                {detail && detail.production_all_aa && detail.production_all_aa.length > 0 ?
                    detail.production_all_aa.map((attribute, index) => {
                        return (<tr>
                            <td style={{ width: "25%" }}>{attribute.name}</td>
                            <th>{detail && detail.production_additional_attributes && detail.production_additional_attributes[attribute.key] ? detail.production_additional_attributes[attribute.key] : "-"}</th>
                        </tr>)
                    })
                    : null}
            </tbody>
        </table>)
    }

    taskProductivityRemarkViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }}>Remark </td>
                    <th>{detail && detail.productivity_remark ? detail.productivity_remark : "-"}</th>

                </tr>
             
            </tbody>
        </table>)
    }

    taskMaterialJsx            =       (formData=null)      =>      {
        let materialList = formData && formData.materials && formData.materials.length > 0 ? formData.materials.map(at => {return({value:at.id,label:at.material_name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Material</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={materialList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addTaskflowForm', 'material_id');
                        }}
                        isSearchable={true}
                        value={materialList.find(s => this.state.addTaskflowForm.material_id == s.value)}
                        isClearable={true}
                        placeholder="Select Material"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
           
        </Ax>)
    }

    taskMaterialViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }}>Material </td>
                    <th>{detail && detail.material_name ? detail.material_name : "-"}</th>

                </tr>
               
            </tbody>
        </table>)
    }

    taskWeightCheckOutJsx       =       (formData,keys=[])      =>      {
        let unitList = formData && formData.unloaded_weight_units && formData.unloaded_weight_units.length > 0 ? formData.unloaded_weight_units.map(at => {return({value:at,label:at})})  : []
        return (<div className='row'>
            <div className='col-sm-12'>
                <table className="table my-2 table-hover table-bordered table-sm">
                    <thead className="table-secondary">
                        <tr className="text-center">
                            <th scope="col" style={{ width: "26%" }}>Weight</th>
                            <th scope="col" style={{ width: "24%" }}>Out</th>
                            <th scope="col" style={{ width: "24%" }}>In</th>
                            <th scope="col" style={{ width: "24%" }}>Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Unloaded Weight</td>
                            <td><input
                                name="unloaded_weight"
                                type="number"
                                value={this.state.addTaskflowForm.unloaded_weight}
                                className="form-control form-control-sm"
                                autoComplete="off"
                                autoFocus={true}
                                onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                                placeholder="Unloaded Weight"
                            /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Unit</td>
                            <td>
                                <TapSelect
                                    options={unitList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addTaskflowForm', 'unloaded_weight_unit');
                                    }}
                                    isSearchable={true}
                                    value={unitList.find(s => this.state.addTaskflowForm.unloaded_weight_unit == s.value)}
                                    isClearable={true}
                                    placeholder="Unit"
                                    autoCompelete="off"
                                    menuPlacement={'top'}
                                    containerHeight="30px"
                                    fontSize="93%"
                                />
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        {/* {keys && keys.length > 0 ?

                            keys.map((i, k) => {
                                let name = 'additional_attributes_' + i.key;
                                let input = null;
                                if (i.additional_attribute.type == 'text') {
                                    input = <input
                                        name={name}
                                        type="text"
                                        value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                                        onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder={`Please enter ${i.field_name}`}
                                    />
                                } else if (i.additional_attribute.type == 'numeric') {
                                    input = <input
                                        name={name}
                                        type="number"
                                        id={i.formula_key}
                                        value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                                        onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder={`Please enter ${i.field_name}`}
                                        pattern="[0-9.]+"
                                    />
                                } else if (i.additional_attribute.type == 'date') {
                                    input = <DatePicker
                                        selected={
                                            this.state.addTaskflowForm[name]
                                                ? moment(this.state.addTaskflowForm[name]).toDate()
                                                : false
                                        }
                                        name={name}
                                        onChange={(value, event) => this.formDateHandler(name, value, 'addTaskflowForm')}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete='off'
                                        scrollMonthYearDropdown
                                        placeholderText={`Please Enter ${i.field_name}`}

                                    />
                                } else if (i.additional_attribute.type == 'dropdown') {
                                    let options = i.additional_attribute.options.map((t) => { return { value: t, label: t } });
                                    input = <TapSelect
                                        menuPlacement="top"
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTaskflowForm', name)}
                                        options={options}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={options.find(o => o.value == this.state.addTaskflowForm[name])}
                                        placeholder={`Please Select ${i.field_name}`}
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                }

                                return (<tr>
                                    <td>{i.field_name}</td>
                                    <th>{input}</th>
                                    <td></td>
                                    <td></td>

                                </tr>);
                            })

                            : null} */}
                    </tbody>
                </table></div>
        </div>)
    }

    taskMaterialDischargeJsx            =       (formData=null)      =>      {
        let attachemntList = formData && formData.attachments && formData.attachments.length > 0 ? formData.attachments.map(at => {return({value:at.id,label:at.name})})  : []
        return (<Ax>
            <div className="row my-2 ">

                <div className='col-sm-3'>
                    <div className='fw-bold col-form-label-sm'>Attachment</div>
                </div>
                <div className='col-sm-7'>
                    <TapSelect
                        options={attachemntList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addTaskflowForm', 'attachment_id');
                        }}
                        isSearchable={true}
                        value={attachemntList.find(s => this.state.addTaskflowForm.attachment_id == s.value)}
                        isClearable={true}
                        placeholder="Select Attchment"
                        autoCompelete="off"
                        menuPlacement={'top'}
                        containerHeight="30px"
                        fontSize="93%"
                    />

                </div>

            </div>
           
        </Ax>)
    }

    taskMatrialDischargeViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }}>Attachment </td>
                    <th>{detail && detail.attachment_name ? detail.attachment_name : "-"}</th>

                </tr>
                {/* <tr>
                    <td className="text-start">Entry By</td>
                    <th>{detail && detail.step_submit_user ? detail.step_submit_user.full_name : "-"}</th>

                </tr>
                <tr>
                    <td className="text-start">Entry Timestamp</td>
                    <th>{detail && detail.step_submit_at_display ? detail.step_submit_at_display : "-"}</th>

                </tr> */}
            </tbody>
        </table>)
    }


    taskWeightcheckAllJsx        =       (taskDetail,show_steps)      =>      {

        let unloadedDetail  =   this.state.previousStepDetails && this.state.previousStepDetails.find(ps => ps.fields.find(fs => fs.field_key == 'unloaded_weight'));
        let loadedDetail    =   this.state.previousStepDetails && this.state.previousStepDetails.find(ps => ps.fields.find(fs => fs.field_key == 'loaded_weight'));
        
        let unitList        =   this.state.nextStepFields && this.state.nextStepFields.find(ps => ps.fields.find(fs => fs.field_key == 'loaded_weight'));
        let loadedUnitList  =   unitList && unitList.form_data && unitList.form_data.loaded_weight_units && unitList.form_data.loaded_weight_units.length > 0 ? unitList.form_data.loaded_weight_units.map(at => {return({value:at,label:at})})  : [];
        
        let unloadedunitList  =   this.state.nextStepFields && this.state.nextStepFields.find(ps => ps.fields.find(fs => fs.field_key == 'unloaded_weight'));
        let unloadedUnitList  =   unloadedunitList && unloadedunitList.form_data && unloadedunitList.form_data.unloaded_weight_units && unloadedunitList.form_data.unloaded_weight_units.length > 0 ? unloadedunitList.form_data.unloaded_weight_units.map(at => {return({value:at,label:at})})  : [];
       
        let next_step_fields    =   this.state.nextStepFields && this.state.nextStepFields.length > 0 ? this.state.nextStepFields.map(nt => {return(nt.fields.map(fd => fd.field_key))}).flat() : [];
        
        return (<div>
            {this.state.nextStepDetail && this.state.nextStepDetail.can_submit && this.state.nextStepDetail.can_submit == 'Y' ? <Ax>
                <div className="tab_content_wrapper fw-bold my-2">Weight Check</div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <table className="table my-2 table-hover table-bordered table-sm">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "26%" }}>Weight</th>
                                    <th scope="col" style={{ width: "24%" }}>Loaded</th>
                                    <th scope="col" style={{ width: "24%" }}>Unloaded</th>
                                    <th scope="col" style={{ width: "24%" }}>Difference</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Weight</td>
                                    {next_step_fields && next_step_fields.length > 0 && next_step_fields.includes('loaded_weight')
                                        ? <th> <input
                                            name="loaded_weight"
                                            type="number"
                                            value={this.state.addTaskflowForm.loaded_weight}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                                            placeholder="Loaded Weight"
                                            required="true"
                                        /></th> : loadedDetail && loadedDetail.detail && loadedDetail.detail.loaded_weight ? <th className='text-capitalize text-center'>{loadedDetail.detail.loaded_weight}</th> : "-"}

                                    {next_step_fields && next_step_fields.length > 0 && next_step_fields.includes('unloaded_weight')
                                        ? <th><input
                                            name="unloaded_weight"
                                            type="number"
                                            value={this.state.addTaskflowForm.unloaded_weight}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                                            placeholder="Unloaded Weight"
                                            required="true"
                                        /></th> : unloadedDetail && unloadedDetail.detail && unloadedDetail.detail.unloaded_weight ? <th className='text-center'>{unloadedDetail.detail.unloaded_weight}</th> : "-"}
                                    {next_step_fields && next_step_fields.length > 0 && next_step_fields.includes('loaded_weight')
                                        ? <th className='text-center'>{unloadedDetail && unloadedDetail.detail && unloadedDetail.detail.unloaded_weight && this.state.addTaskflowForm.loaded_weight ? Number(this.state.addTaskflowForm.loaded_weight) - Number(unloadedDetail.detail.unloaded_weight) : ""}</th>
                                        : <th className='text-center'>{loadedDetail && loadedDetail.detail && loadedDetail.detail.loaded_weight && unloadedDetail && unloadedDetail.detail && unloadedDetail.detail.unloaded_weight ? Number(loadedDetail.detail.loaded_weight) - Number(unloadedDetail.detail.unloaded_weight) : ""}
                                        </th>}
                                </tr>


                                <tr>
                                    <td>Unit</td>
                                    {next_step_fields && next_step_fields.length > 0 && next_step_fields.includes('loaded_weight')
                                        ?
                                        <td>
                                            <TapSelect
                                                options={loadedUnitList}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addTaskflowForm', 'loaded_weight_unit');
                                                }}
                                                isSearchable={true}
                                                value={loadedUnitList.find(s => this.state.addTaskflowForm.loaded_weight_unit == s.value)}
                                                isClearable={true}
                                                placeholder="Unit"
                                                autoCompelete="off"
                                                menuPlacement={'top'}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                required="true"
                                            />
                                        </td> : loadedDetail && loadedDetail.detail && loadedDetail.detail.loaded_weight_unit ? <th className='text-capitalize text-center'>{loadedDetail.detail.loaded_weight_unit}</th> : "-"}

                                    {next_step_fields && next_step_fields.length > 0 && next_step_fields.includes('unloaded_weight')
                                        ? <td>
                                            <TapSelect
                                                options={unloadedUnitList}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addTaskflowForm', 'unloaded_weight_unit');
                                                }}
                                                isSearchable={true}
                                                value={unloadedUnitList.find(s => this.state.addTaskflowForm.unloaded_weight_unit == s.value)}
                                                isClearable={true}
                                                placeholder="Unit"
                                                autoCompelete="off"
                                                menuPlacement={'top'}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                required="true"
                                            />
                                        </td> : unloadedDetail && unloadedDetail.detail && unloadedDetail.detail.unloaded_weight_unit ? <th className='text-center'>{unloadedDetail.detail.unloaded_weight_unit}</th> : "-"}

                                    <th></th>
                                </tr>
                                <tr>
                                    <td className="text-start" style={{ width: "26%" }}>Entry By</td>
                                    <th>{loadedDetail && loadedDetail.detail && loadedDetail.detail.step_submit_user ? loadedDetail.detail.step_submit_user.full_name : "-"}</th>

                                    <th>{unloadedDetail && unloadedDetail.detail && unloadedDetail.detail.step_submit_user ? unloadedDetail.detail.step_submit_user.full_name : "-"}</th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td className="text-start" style={{ width: "26%" }}>Entry Timestamp</td>
                                    <th>{loadedDetail && loadedDetail.detail && loadedDetail.detail.step_submit_at_display ? loadedDetail.detail.step_submit_at_display : "-"}</th>

                                    <th>{unloadedDetail && unloadedDetail.detail && unloadedDetail.detail.step_submit_at_display ? unloadedDetail.detail.step_submit_at_display : "-"}</th>
                                    <th></th>
                                </tr>
                                {/* {keys && keys.length > 0 ?

                            keys.map((i, k) => {
                                let name = 'additional_attributes_' + i.key;
                                let input = null;
                                if (i.additional_attribute.type == 'text') {
                                    input = <input
                                        name={name}
                                        type="text"
                                        value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                                        onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder={`Please enter ${i.field_name}`}
                                    />
                                } else if (i.additional_attribute.type == 'numeric') {
                                    input = <input
                                        name={name}
                                        type="number"
                                        id={i.formula_key}
                                        value={this.state.addTaskflowForm[name] ? this.state.addTaskflowForm[name] : ''}
                                        onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                        className="form-control form-control-sm"
                                        autoComplete="off"
                                        placeholder={`Please enter ${i.field_name}`}
                                        pattern="[0-9.]+"
                                    />
                                } else if (i.additional_attribute.type == 'date') {
                                    input = <DatePicker
                                        selected={
                                            this.state.addTaskflowForm[name]
                                                ? moment(this.state.addTaskflowForm[name]).toDate()
                                                : false
                                        }
                                        name={name}
                                        onChange={(value, event) => this.formDateHandler(name, value, 'addTaskflowForm')}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete='off'
                                        scrollMonthYearDropdown
                                        placeholderText={`Please Enter ${i.field_name}`}

                                    />
                                } else if (i.additional_attribute.type == 'dropdown') {
                                    let options = i.additional_attribute.options.map((t) => { return { value: t, label: t } });
                                    input = <TapSelect
                                        menuPlacement="top"
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTaskflowForm', name)}
                                        options={options}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={options.find(o => o.value == this.state.addTaskflowForm[name])}
                                        placeholder={`Please Select ${i.field_name}`}
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                }

                                return (<tr>
                                    <td>{i.field_name}</td>
                                    <th>{input}</th>
                                    <td></td>
                                    <td></td>

                                </tr>);
                            })

                            : null} */}
                            </tbody>
                        </table></div>
                </div>
            </Ax> : null}
        </div>)
    }

    taskWorkstationFromJsx         =       ()      =>      {
        return (<div className="row ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation From</div>
            </div>
            <div className='col-sm-7'>
                {this.state.addNewWorkstationFrom == "Y" && this.props.group_permission.permissions.includes('iam-workstation-manage')
                    ? <input
                        name="workstation_from_name"
                        type="text"
                        value={this.state.addTaskflowForm.workstation_from_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                        placeholder="Please enter Workstation From"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstationFrom: selectedOption ? selectedOption : null,
                                addTaskflowForm: { ...this.state.addTaskflowForm, workstation_from: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstationFrom ? this.state.searchedWorkstationFrom : null}
                    />
                }
            </div>
            <div className="col-sm-2">
                <input
                    name="addNewWorkstationFrom"
                    type="checkbox"
                    value={this.state.addNewWorkstationFrom}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstationFrom: this.state.addNewWorkstationFrom == 'N' ? 'Y' : 'N',
                            addTaskflowForm: { ...this.state.addTaskflowForm, workstation_from_name: '', workstation_from: '' },
                            searchedWorkstationFrom: null
                        })
                    }}
                    checked={this.state.addNewWorkstationFrom == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstationFrom"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationFrom">Add New </label>
            </div>
          

        </div>)
    }

    taskWorkstationFromViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td  style={{  width: "26%" }}>Workstation From</td>
                    <th>{detail &&  detail.workStationFrom && detail.workStationFrom.name ? detail.workStationFrom.name : "-"}</th>

                </tr>
            </tbody>
        </table>)
    }

    taskWorkstationToJsx         =       ()      =>      {
        return (<div className="row ">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>WorkStation To</div>
            </div>

            <div className='col-sm-7'>
                {this.state.addNewWorkstationTo == "Y" && this.props.group_permission.permissions.includes('iam-workstation-manage')
                    ? <input
                        name="workstation_to_name"
                        type="text"
                        value={this.state.addTaskflowForm.workstation_to_name}
                        className="form-control form-control-sm"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                        placeholder="Please enter Workstation to"
                    />
                    :
                    <InputWorkstationSearch
                        placeholder="Please Select Workstation"
                        changeEvent={(selectedOption) => {
                            this.setState({
                                searchedWorkstationTo: selectedOption ? selectedOption : null,
                                addTaskflowForm: { ...this.state.addTaskflowForm, workstation_to: selectedOption ? selectedOption.value : '' }
                            })

                        }}
                        menuPlacement="top"
                        search_site_id={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : []}
                        containerHeight="30px"
                        fontSize="93%"
                        value={this.state.searchedWorkstationTo ? this.state.searchedWorkstationTo : null}
                    />
                }
            </div>
            <div className="col-sm-2">
                <input
                    name="addNewWorkstationTo"
                    type="checkbox"
                    value={this.state.addNewWorkstationTo}
                    onChange={(e) => {
                        this.setState({
                            addNewWorkstationTo: this.state.addNewWorkstationTo == 'N' ? 'Y' : 'N',
                            addTaskflowForm: { ...this.state.addTaskflowForm, workstation_to_name: '', workstation_to: '' },
                            searchedWorkstationTo: null
                        })
                    }}
                    checked={this.state.addNewWorkstationTo == 'Y'}
                    className="form-check-input"
                    id="addNewWorkstationTo"
                //disabled={this.state.linkedProductivityFormula ? true : false}

                />
                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationTo">Add New </label>
            </div>

        </div>)
    }

    taskWorkstationToViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null;
       
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td  style={{  width: "26%" }}>Workstation To</td>
                    <th>{detail &&  detail.workStationTo && detail.workStationTo.name ? detail.workStationTo.name : "-"}</th>

                </tr>
            </tbody>
        </table>)
    }
    
    taskClosingRemarkJsx       =       ()      =>      {
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Closing Remark</div>
            </div>
            <div className='col-sm-7'>
                <textarea
                    name="closing_remarks"
                    value={this.state.addTaskflowForm.closing_remarks}
                    className="form-control form-control-sm"
                    autoComplete="off"
                    onChange={(e) => this.formInputHandler(e, "addTaskflowForm")}
                    placeholder="Remark"
                />

            </div>

        </div>)
    }

    taskClosingRemarkViewJsx         =       (taskDetail)      =>      {
        let detail = taskDetail ? taskDetail : null
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }}>Remark </td>
                    <th>{detail && detail.closing_remarks ? detail.closing_remarks : "-"}</th>

                </tr>
             
            </tbody>
        </table>)
    }


    closingDateTimeJsx          =   ()      =>      {
        return (<div className="modal fade" id={'closingDateTimeModal'} tabIndex="-1">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="fs-6 m-0">
                            <span>Closing Date Time </span> 
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                   
                    <div className="modal-body">
                        <form onSubmit={this.submitClosingDateTimeForm} id="closingDateTimeForm">
                            <div className="row ">

                                <div className='col-sm-3'>
                                    <div className='fw-bold col-form-label-sm'>Date time
                                    </div>
                                </div>
                                <div className='col-sm-9'>
                                    <DatePicker
                                        selected={
                                            this.state.addTaskflowForm.end_cl_date_time
                                                ? moment(this.state.addTaskflowForm.end_cl_date_time).toDate()
                                                : false
                                        }
                                        name="end_cl_date_time"
                                        onChange={(value, event) => {
                                            this.formDateHandler('end_cl_date_time', value, 'addTaskflowForm', 'YYYY-MM-DD HH:mm:ss', () => {
                                                var start_cl_date_time = this.state.taskData && this.state.taskData.start_cl_date_time_display ? moment(this.state.taskData.start_cl_date_time) : "";//now
                                                var end_cl_date_time = this.state.addTaskflowForm && this.state.addTaskflowForm.end_cl_date_time ? moment(this.state.addTaskflowForm.end_cl_date_time) : '';
                                                let daydiffenence = start_cl_date_time && end_cl_date_time ? end_cl_date_time.diff(start_cl_date_time, 'days') : '';
                                                let hourDiffernce = start_cl_date_time && end_cl_date_time ? end_cl_date_time.diff(start_cl_date_time, 'hours') : '';
                                                this.setState({
                                                    taskDayDiffernce: daydiffenence,
                                                    taskHourDifference: hourDiffernce,
                                                })

                                            })

                                        }}
                                        dateFormat="dd-MMM-yyyy hh:mm a"
                                        className={"form-control form-control-sm"}
                                        showMonthDropdown
                                        showYearDropdown
                                        showTimeSelect
                                        timeIntervals={5}
                                        timeCaption="Time"
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        placeholderText={` Closing Date & Time`}
                                        minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                                        maxDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(), 'YYYY-MM-DD').toDate() : ''}
                                    // minTime={moment(this.state.taskData && this.state. ? this.state.shiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                                    // maxTime={max_time}
                                    />

                                </div>

                            </div>

                        </form>
                    </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Cancel</button>
                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={'closingDateTimeForm'}>Save</button>
                    </div>
                    
                </div>
            </div>
        </div>)
    }

    taskStartTimeJsx        =   ()      =>      {
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(this.state.shiftDetail){
            if(this.state.shiftDetail && this.state.shiftDetail.shift_start == this.state.shiftDetail.shift_end){
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").toDate()
            }
        }
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Task Start Time</div>
            </div>
            <div className='col-sm-7'>
                <DatePicker
                    selected={
                        this.state.addTaskflowForm.task_start_at
                            ? moment(this.state.addTaskflowForm.task_start_at).toDate()
                            : false
                    }
                    name="task_start_at"
                    onChange={(value, event) => { this.formDateHandler('task_start_at', value, 'addTaskflowForm', 'YYYY-MM-DD HH:mm:ss') }}
                    dateFormat="dd-MMM-yyyy hh:mm a"
                    className={"form-control form-control-sm"}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    timeCaption="Time"
                    autoComplete="off"
                    scrollMonthYearDropdown
                    required={true}
                    placeholderText={`Please Enter Starting Date & Time`}
                    minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                    maxDate={moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate()}
                    minTime={moment(this.state.shiftDetail ? this.state.shiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                    maxTime={max_time}
                />

            </div>

        </div>)
    }

    taskStartTimeViewJsx         =       ()      =>      {
       
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }}>Task Start Time </td>
                    <th>{this.state.taskData && this.state.taskData.task_start_at_display ? this.state.taskData.task_start_at_display : "-"}</th>

                </tr>
             
            </tbody>
        </table>)
    }

    taskCounterlogReadingJsx        =       ()          =>      {
        let labels      =       this.state.assetData && this.state.assetData.labels ? this.state.assetData.labels : {};
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(this.state.shiftDetail){
            if(this.state.shiftDetail && this.state.shiftDetail.shift_start == this.state.shiftDetail.shift_end){
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").toDate()
            }
        }

        return (<div className='row my-2'>
            <div className="col-sm-12">
                {this.state.assetData && this.state.assetData.hour_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require">{labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_hours_abs"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_hours_abs}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.hour_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require ">{labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'}</label></div>



                        <div className='col-sm-7'>
                            <input
                                name="cl_hours_abs2"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_hours_abs2}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>

                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.hour_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require">{labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter3'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_hours_abs3"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_hours_abs3}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.hour_meter4 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require">{labels && labels.hour_meter4 ? labels.hour_meter4 : 'Hour Meter 4'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_hours_abs4"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_hours_abs4}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.odo_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require">{labels && labels.odo_meter ? labels.odo_meter : 'Odo Meter'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_odometer_abs"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_odometer_abs}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.odo_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require ">{labels && labels.odo_meter2 ? labels.odo_meter2 : 'Odo Meter 2'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_odometer_abs2"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_odometer_abs2}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.odo_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require ">{labels && labels.odo_meter3 ? labels.odo_meter3 : 'Odo Meter 3'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_odometer_abs3"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_odometer_abs3}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.operation_cycle == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require ">{labels && labels.operation_cycle ? labels.operation_cycle : 'Operation Cycle'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_cycle_abs"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_cycle_abs}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                {this.state.assetData && this.state.assetData.operation_cycle2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ? <div className='row my-2'>
                        <div className='col-sm-3'><label className="form-label require">{labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation Cycle 2'}</label></div>

                        <div className='col-sm-7'>
                            <input
                                name="cl_cycle_abs2"
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, 'addTaskflowForm')}
                                placeholder="Please Enter Reading"
                                value={this.state.addTaskflowForm.cl_cycle_abs2}
                                style={{ height: "30px", fontSize: "93%" }}
                                required={true}
                            />
                        </div>
                    </div>
                    : null
                }
                <div className="row align-items-center my-2">
                    <div className="col-sm-3"><label className="form-label">Counterlog Date</label></div>

                    <div className="col-md-7 add_calender_section">
                        <DatePicker
                            selected={
                                this.state.addTaskflowForm.cl_date_time
                                    ? moment(this.state.addTaskflowForm.cl_date_time).toDate()
                                    : false
                            }
                            name="cl_date_time"
                            onChange={(value, event) => { this.formDateHandler('cl_date_time', value, 'addTaskflowForm', 'YYYY-MM-DD HH:mm:ss') }}
                            dateFormat="dd-MMM-yyyy hh:mm a"
                            className={"form-control form-control-sm"}
                            showMonthDropdown
                            showYearDropdown
                            showTimeSelect
                            timeIntervals={5}
                            timeCaption="Time"
                            autoComplete="off"
                            scrollMonthYearDropdown
                            required={true}
                            placeholderText={`Please Enter Closing Date & Time`}
                            minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                            // minTime={moment(this.state.shiftDetail ? this.state.shiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                            // maxTime={moment("11:55 P.M", "hh:mm a").toDate()}
                            maxDate={new Date()}
                        />
                        <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "5px" }} />
                    </div>
                </div>
            </div>
        </div>)
    }

    taskCounterlogViewJsx    =       (taskDetail)          =>      {
        let labels      =       this.state.assetData && this.state.assetData.labels ? this.state.assetData.labels : {};
        let detail       =      taskDetail ? taskDetail : null;
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                {this.state.assetData && this.state.assetData.hour_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.hour_meter ? labels.hour_meter : 'Hour Meter'} </td>
                        <th>{detail && detail.cl_hours_abs ? detail.cl_hours_abs : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.hour_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.hour_meter2 ? labels.hour_meter2 : 'Hour Meter 2'} </td>
                        <th>{detail && detail.cl_hours_abs2 ? detail.cl_hours_abs2 : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.hour_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.hour_meter3 ? labels.hour_meter3 : 'Hour Meter 3'} </td>
                        <th>{detail && detail.cl_hours_abs3 ? detail.cl_hours_abs3 : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.hour_meter4 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.hour_meter4 ? labels.hour_meter4 : 'Hour Meter 4'} </td>
                        <th>{detail && detail.cl_hours_abs4 ? detail.cl_hours_abs4 : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.odo_meter == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.odo_meter ? labels.odo_meter : 'Odo Meter'} </td>
                        <th>{detail && detail.cl_odometer_abs ? detail.cl_odometer_abs : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.odo_meter2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.odo_meter2 ? labels.odo_meter2 : 'Odo Meter 2'} </td>
                        <th>{detail && detail.cl_odometer_abs2 ? detail.cl_odometer_abs2 : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.odo_meter3 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.odo_meter3 ? labels.odo_meter3 : 'Odo Meter 3'} </td>
                        <th>{detail && detail.cl_odometer_abs3 ? detail.cl_odometer_abs3 : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.operation_cycle == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.operation_cycle ? labels.operation_cycle : 'Operation Cycle'} </td>
                        <th>{detail && detail.cl_cycle_abs ? detail.cl_cycle_abs : '-'}</th>

                    </tr>
                    : null}
                {this.state.assetData && this.state.assetData.operation_cycle2 == "yes" && this.state.assetData.cl_attribute == 'Y'
                    ?
                    <tr>
                        <td style={{ width: "26%" }}>{labels && labels.operation_cycle2 ? labels.operation_cycle2 : 'Operation Cycle 2'} </td>
                        <th>{detail && detail.cl_cycle_abs2 ? detail.cl_cycle_abs2 : '-'}</th>

                    </tr>
                    : null}
                     <tr>
                        <td style={{ width: "26%" }}>CounterLog Date Time </td>
                        <th>{detail && detail.cl_date_time ? detail.cl_date_time : '-'}</th>

                    </tr>
            </tbody>
        </table>)
    }

    taskCloseTimeJsx        =   ()      =>      {
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(this.state.shiftDetail){
            if(this.state.shiftDetail && this.state.shiftDetail.shift_start == this.state.shiftDetail.shift_end){
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(this.state.shiftDetail.shift_end, "hh:mm a").toDate()
            }
        }
        
        return (<div className="row my-2">

            <div className='col-sm-3'>
                <div className='fw-bold col-form-label-sm'>Task Close Time</div>
            </div>
            <div className='col-sm-7'>
                <DatePicker
                    selected={
                        this.state.addTaskflowForm.task_close_at
                            ? moment(this.state.addTaskflowForm.task_close_at).toDate()
                            : false
                    }
                    name="task_close_at"
                    onChange={(value, event) => { this.formDateHandler('task_close_at', value, 'addTaskflowForm', 'YYYY-MM-DD HH:mm:ss') }}
                    dateFormat="dd-MMM-yyyy hh:mm a"
                    className={"form-control form-control-sm"}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    timeIntervals={5}
                    timeCaption="Time"
                    autoComplete="off"
                    scrollMonthYearDropdown
                    required={true}
                    placeholderText={`Please Enter Closing Date & Time`}
                    minDate={this.state.counterlog && this.state.counterlog.feed_date ? moment(new Date(this.state.counterlog.feed_date), 'YYYY-MM-DD').toDate() : ''}
                    minTime={moment(this.state.shiftDetail ? this.state.shiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                    maxTime={new Date().setHours(0, 0, 0, 0)}
                    maxDate={new Date()}
                />

            </div>

        </div>)
    }

    taskCloseTimeViewJsx         =       ()      =>      {
       
        return (<table className="table mb-0 mt-0 table-hover table-bordered">
            <tbody>
                <tr>
                    <td style={{ width: "26%" }}>Task Close Time </td>
                    <th>{this.state.taskData && this.state.taskData.task_close_at_time_date_display ? this.state.taskData.task_close_at_time_date_display : "-"}</th>

                </tr>
             
            </tbody>
        </table>)
    }
        
    
    render                              =   ()  =>  {return(
        <Ax>
            {this.updateTaskflowModalJsx()}
            {this.closingDateTimeJsx()}
        </Ax>
    )}
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(TaskFlow);