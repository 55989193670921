import React from 'react';
import {connect} from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { Collapse, Modal } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import HttpAPICall from "../../../services/HttpAPICall";
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import Loader from "../../../components/ui/Loader/Loader";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class DateWiseAnalysisReport extends AppBaseComponent {

    constructor() {
        super();

        this.assetPerformanceFilterFormInitialState   =   {
            site_group_ids                                :   [],
            search_site_ids                               :   [],
            'asset_group_ids'                             :    [],
            search_asset_type_ids                         :   [],
            search_asset_matrix_ids                       :   [],
            search_manufacturer_ids                       :   [],
            search_models                                 :   [],
            search_variant                                :   [],
            search_asset_ids                              :   [],
            search_asset_type                             :   '',
            date_range                                    :   '',
            date_range_start                              :   '',
            date_range_end                                :   '',
            search_asset_category                         :   'Y',
            search_owner_id                               :   '',
            search_ownership_category                     :   '',
            
        };

        this.assetPerformanceSearchFormLabel        =    {
            site_group_ids                                :   'Site Groups : ',
            search_site_ids                               :   'Sites : ',
            'asset_group_ids'                             :   'Asset Type Groups : ',
            search_asset_type_ids                         :   'Asset Type : ',
            search_asset_matrix_ids                       :   'Asset Matrix : ',
            search_manufacturer_ids                       :   'Manufacturer : ',
            search_models                                 :   'Models : ',
            search_variant                                :   'Variants : ',
            search_asset_ids                              :   'Assets : ',
            search_asset_type                             :   'Asset Type : ',
            date_range                                    :   'Date Range :  ',
            search_asset_category                        :   'Asset Category : ',
            search_owner_id                               :   'Owner Name : ',
            search_ownership_category                     :   'Ownership Category : ',
        }

        this.initCustomDownloadState                     =       {
            asset_grouping                                :       'asset_type'
        }

        this.state                                   =   {
            showSaveAssetPerformanceBtn                  :   false,
            showDownloadAssetPerformanceBtn              :   false,
            assetPerformanceDownloading                  :   false,
            showSaveAssetPerformanceForm                 :   false,
            listing_loading                              :   false,
            listingData                                  :   [],
            listingMeta                                 :   null,
            iam_asset_types                              :   [],
            iam_user_sites                               :   [],
            iam_asset_matrix                             :   [],
            iam_manufactures                             :   [],
            iam_asset_variants                           :   [],
            iam_models                                   :   [],
            searchedAsset                                :   null,
            searchedAssets                               :   [],
            assetPerformanceFormData                     :   {periods : [], asset_category: []},
            selectedPeriod                               :   '',
            downloadReportBtn                            :   false,
            reportDownloading                            :   false,
            listing_tbl_page                             :   1,
            formSearchedElems                            :   [],
            allOwnershipCategory                         :   [],
            allOwnersList                                :   [],
            allSiteGroupsList                            :   [],
            customDownloadForm                           :   {...this.initCustomDownloadState},
            assetPerformanceFilterForm                   :   {...this.assetPerformanceFilterFormInitialState},
            submittedAssetPerformanceFilterForm          :   {...this.assetPerformanceFilterFormInitialState},
            allAssetTypesGroupsList                 :   [],
            customDownloadCriteria                       :       [
                {key : "asset_type" , label : "Asset Type",value : false},
                {key : "current_status" , label : "Current Status",value : false},
                {key : "ownership" , label : "Ownership & Owner Name",value : true},
                {key : "workstation" , label : "Workstation",value : false},
                {key : "counterlog_readings" , label : "Opening and Closing Counterlog Readings",value : false},
                {key : "running_per_day" , label : "Running per day",value : false},
                {key : "fuel_tank_balance" , label : "Opening and Closing Fuel Tank Balance",value : false},
                {key : "fuel_consumption" , label : "Fuel Consumption",value : false},
                {key : "expense" , label : "Expense",value : false},
                {key : "rental_expense" , label : "Commercial Expense",value : false},
                {key : "total_ctc" , label : "Total CTC",value : false},
                {key : "total_hours" , label : "Total Hours",value : false},
                {key : "downtime" , label : "Downtime",value : false},
                {key : "total_idle_hours" , label : "Total Idle Hours",value : false},
                {key : "availability" , label : "Availability / Uptime",value : false},
                {key : "utilization" , label : "Utilization based on 24 hours",value : false},
                {key : "trips_sessions" , label : "Trips / Sessions",value : false},
                {key : "productivity" , label : "Total Productivity",value : false},
                {key : "fuel_per_unit_productivity" , label : " Productivity per unit Fuel",value : false},
                {key : "cost_per_unit_productivity" , label : "Cost per Unit of Productivity",value : false},
                {key : "productivity_per_unit_running" , label : "Productivity per unit Running",value : false},
            ],
            reportData                          :   null,                 
        };

        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/date_wise_running_with_analysis';

    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        this.assetPerformanceFormDataInit()
        this.customDownloadModal            =   new Modal(document.getElementById('customDownloadModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    

     handleOnChangeCriteria = (event,  index) => {
        const values = [...this.state.customDownloadCriteria];
        values[index].value = event.target.checked;
        this.setState({customDownloadCriteria : values})
     };

    assetPerformanceFormDataInit            =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {action:'form_data'}, null, (response) => {
            this.setState({
                assetPerformanceFormData      :   response.data,
                formDataLoaded                :   true,
                allOwnershipCategory          :   response.data.ownership_category && response.data.ownership_category.length > 0 ? response.data.ownership_category.map(oc => {return({value:oc.key,label : oc.name})}) : [],
                allOwnersList                 :   response.data.owners && response.data.owners.length > 0 ? response.data.owners.map(oc => {return({value:oc.id,label : oc.name})}) : [],
            });
            this.reportFilterhandler();
        });
    }

    reportFilterhandler                     =   ()  =>  {
        new Collapse(document.getElementById('assetPerformanceFilterFormJsx'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    initilaizeFormFilter                    =   (props)  =>  {

        const report_name   =   'date_wise_running_with_analysis';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        
        
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            allSiteGroupsList       :   props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
            iam_manufactures        :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
            iam_asset_variants      :   props.iam_asset_variants ? props.iam_asset_variants.map((v) => { return {value: v, label: v}}) : [],
            iam_models              :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        });
    }

    reportPerformanceFilterFormSubmit       =   (event)  =>  {
        event.preventDefault()
        this.reportFilterhandler();
        let serachFormKeys                      =   Object.keys(this.state.assetPerformanceFilterForm);

        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.assetPerformanceSearchFormLabel[key];
            let value                       =   this.state.assetPerformanceFilterForm[key];
            
            if(value && value.toString().length > 0) {
            
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.assetPerformanceFormData.asset_category.filter((s) => value.includes(s.key)).map(s => s.name);
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_owner_id') {
                        show_val            = this.state.allOwnersList.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_ownership_category') {
                        show_val            = this.state.allOwnershipCategory.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        show_val            = this.state.assetPerformanceFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });

        this.setState({
            submittedAssetPerformanceFilterForm : {...this.state.assetPerformanceFilterForm},
            formSearchedElems                   :  searchedElems,
            assetSearchParams                   :  assetSearchParams
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listing_tbl_page        :   page,
            downloadReportBtn       :   false,
            reportDownloading       :   false
        });
        let submittedAssetPerformanceFilterForm   = this.state.submittedAssetPerformanceFilterForm;
        delete submittedAssetPerformanceFilterForm.site_group_ids;
        
        let params                          =   {page:page,...submittedAssetPerformanceFilterForm,action:"data"};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token,params,{},(response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                downloadReportBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownloadHandler                  =   ()  =>  {
        this.setState({ reportDownloading: true});
       HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedAssetPerformanceFilterForm, action : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

  
    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.downloadReportBtn ? (<Ax>
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-primary">
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                            {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')} onClick={this.reportDownloadHandler}>Download Detailed Report</Link></li>
                        </ul>
                    </Ax>) : null}

                   
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }

    assetPerformanceFilterFormJsx            =   ()  =>  {
        let asset_category = this.state.assetPerformanceFormData && this.state.assetPerformanceFormData.asset_category ?
            this.state.assetPerformanceFormData.asset_category.map(ac => { return {value: ac.key, label: ac.name}}) : [];
           
        return (<div id="assetPerformanceFilterFormJsx" className="bg-white" >
            <form onSubmit={this.reportPerformanceFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
            <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {
                               
                               this.setState({
                                 assetPerformanceFilterForm     :   {
                                     ...this.state.assetPerformanceFilterForm,
                                     site_group_ids             :   selectedOption &&  selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                     search_site_ids            :   selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                 }
                               })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.assetPerformanceFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.assetPerformanceFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    assetPerformanceFilterForm: {
                                        ...this.state.assetPerformanceFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.assetPerformanceFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.assetPerformanceFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_asset_matrix_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.assetPerformanceFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.assetPerformanceFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.assetPerformanceFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            search_asset_type={[this.state.assetPerformanceFilterForm.search_asset_type]}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            menuPlacement='top'
                            options={asset_category}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_asset_category');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            value={asset_category.find(r => r.value === this.state.assetPerformanceFilterForm.search_asset_category)}
                            placeholder="Select Asset Category"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Ownership</label>
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className='col-sm-5'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnershipCategory}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_ownership_category');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnershipCategory.find(r => r.value === this.state.assetPerformanceFilterForm.search_ownership_category)}
                                    placeholder="Select Ownership Category"
                                />
                            </div>
                            <div className='col-sm-7'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnersList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetPerformanceFilterForm', 'search_owner_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnersList.find(r => r.value === this.state.assetPerformanceFilterForm.search_owner_id)}
                                    placeholder="Select Owner"
                                />
                            </div>
                        </div>


                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Date Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.assetPerformanceFormData && this.state.assetPerformanceFormData.periods ? this.state.assetPerformanceFormData.periods : []}
                            value={this.state.assetPerformanceFilterForm.date_range}
                            startDate={this.state.assetPerformanceFilterForm.date_range_start}
                            endDate={this.state.assetPerformanceFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    assetPerformanceFilterForm: { ...this.state.assetPerformanceFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate }
                                });
                            }}
                            required={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }

    assetPerformanceDisplayJsx               =    ()  =>    {
        return (<div className="bg-white">
            { this.state.listing_loading ? <Loader /> : null }
           { !this.state.listing_loading && this.state.downloadReportBtn == true
                ? this.assetPerformanceTableJsx() : null
            }
        </div>)
    }

    assetPerformanceTableJsx                          =   ()  =>  {
        // let hour_reading              =  this.state.listingData.map((data) => { return (data.hour_meter)});
        // let hour_col = hour_reading.includes("yes");
        // let odo_meter_reading         =  this.state.listingData.map((data) => { return (data.odo_meter)});
        // let odo_col = odo_meter_reading.includes("yes");
        // let operation_cycle_reading   =  this.state.listingData.map((data) => { return (data.operation_cycle)})
        // let operation_col = operation_cycle_reading.includes("yes")

        return (<Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                <tr>
                    <th style={{width:"15%"}}>Asset Name </th>
                    <th style={{width:"15%"}}>Code</th>
                    <th style={{width:"15%"}}>Asset Type</th>
                    <th style={{width:"15%"}}>Site Name</th>
                     <th style={{width:"15%"}}>Running </th>  
                    <th style={{width:"15%"}}>Productivity</th>
                </tr>
                </thead>
                <tbody>
                {this.state.listingData && this.state.listingData.length > 0
                    ?   (this.state.listingData.map((data) => { return (
                        <tr>
                            <td className={"fw-bold"}>{data.name}</td>
                            <td>{data.asset_code}</td>
                            <td>{data.asset_type_name}</td>
                            <td>{data.site}</td>
                            <td>{data.running_cl && data.running_cl.length > 0 ? data.running_cl.map((item, index) => { return (<div className="mt-1"> {item.label} : {item.running??0}  {item.unit} <br /></div>)}) : []}</td>
                            <td>{data.productivity && data.productivity.length > 0 ? data.productivity.map((item, index) => { return (<div className="mt-1"> {item.total_production}  {item.unit} {item.material ? `(${item.material})` : ''} <br /></div>)}) : []}</td>
                        </tr>
                    )}))
                    : <tr><td className="text-center" colSpan={6}>No Record Found</td></tr>
                }
                </tbody>
            </table>

        </Ax>)
    }


    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Date Wise Running With Analysis  - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.assetPerformanceFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  />
                                {this.assetPerformanceDisplayJsx()}
                            </Ax>)
                            : <Loader  />
                        }
                        {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    </div>
                </div>
            </div>
          
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_asset_variants      :   state.app && state.app.asset_variants ? state.app.asset_variants : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(DateWiseAnalysisReport);