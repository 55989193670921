import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import {DisplayListPagination , DisplaySearchCriteria,PeriodSelectionWithCustom } from '../../../components/TapUi';
import {toast} from "react-toastify";
import swal from "sweetalert";
import download from "../../../services/DownloadFile";
import moment from "moment";
import Permission from "../../../components/ui/Permission";
import InvoiceCalulationView from './InvoiceCalulationView';
import { Helmet } from 'react-helmet';

class InvoiceCalculationList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.invoiceSearchFormInitialState         =   {
            'search_asset_id'               :   null,
            'asset_group_ids'               :    [],
            'search_asset_type_ids'             :   [],
            'search_site'                   :   '',
            'search_invoice_rule'           :   '',
            'search_transaction_id'         :   null,
            'transaction_period_range'      :   '',
            'transaction_period_range_start':   null,
            'transaction_period_range_end'  :   null,

        };

        this.invoiceSearchFormLabel       =   {
            'search_asset_id'               :   'Asset : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            'search_asset_type_ids'             :   'Asset Type : ',
            'search_site'                   :   'Site : ',
            'search_invoice_rule'           :   'Invoice Rule :',
            'search_transaction_id'         :   'Invoice Number : ',
            'transaction_period_range'      :   'Transaction Period : '
        };

        this.state                      =   {
            listing_tbl_page                :   1,
            listing_loading                 :   false,
            minimizeTable                   :   false,
            invoiceListDownloading          :   false,
            inovoiceDownloading             :   false,
            listingMeta                     :   null,
            listingData                     :   [],
            totalListingCount               :   0,
            invoiceSearchForm                 :   {...this.invoiceSearchFormInitialState},
            formSearchedElems               :   [],
            searchedAsset                   :   null,
            assetSearchParams               :   null,
            iam_asset_types                 :   [],
            iam_user_sites                  :   [],
            invoice_calculation_view        :   [],
            details_loading                 :   false,
            transactionId                   :   null,
            invoiceData                     :   [],
            showSaveInvoiceForm             :   false,
            calculationAdditionRows         :   [{notes : '', amount : ''}],
            calculationDeductionRows        :   [{notes : '', amount : ''}],
            assetPeriodSites                :   [],
            invoiceEditForm                 :   {
                site_id                      :  null
            },
            invoiceEditFormSubmittig        :    false,
            all_periods                     :    [],
            view_loading                    :    false,
            allAssetTypesGroupsList           :   [],
            redirectTransactionId           :   props.location && props.location.state && props.location.state.redirectTransactionId || null
        };
        this.showInvoiceCalculationForm      =    this.showInvoiceCalculationForm.bind(this)
    }

    componentDidMount() {
        //On  Initialize Get Item List From Server :-
       if(this.props.location.search){
         this.setState({listing_loading:true})
       }else{
        this.loadListingTblData(1);
       }
        //Asset Search Modal :-
        this.searchAssetModal           =   new Modal(document.getElementById('searchAssetModal'), {keyboard: false, backdrop :false});
        //Initialize Fiter Form data
        this.initilaizeFormFilter(this.props);
        if(this.state.redirectTransactionId) {
            this.showInvoiceView(this.state.redirectTransactionId);
            this.setState({redirectTransactionId : null});
            this.props.history.replace({ state: null });
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
            
        }
    }

    initilaizeFormFilter                =   (props)  =>  {
        
        let all_periods             =       [...props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
         this.setState({
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            all_periods             :    all_periods ? all_periods.map((s) => { return {key : s.key, display: s.display}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        });
        let ParamObject                 =       new URLSearchParams(this.props.location.search);
             let searchedAsset = null;
            if(ParamObject.get('search_asset_id')) {
                 let updateFilterForm = {};
               
               if(ParamObject.get('search_asset_id')){
                    updateFilterForm['search_asset_id']        =   ParamObject.get('search_asset_id')
                     searchedAsset = {value : ParamObject.get('search_asset_id')  ,display_label : ParamObject.get('asset_name') }
                }
                setTimeout(() => {
                    this.setState({invoiceSearchForm: {...this.state.invoiceSearchForm,...updateFilterForm},
                        searchedAsset : searchedAsset },
                    function(){
                         this.invoiceSearchFormSubmit();
                        });
                }, 1)
              
                 }
                
               
               
    }

    loadListingTblData                  =   (page = 1)  =>  {
       
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page: page, ...this.state.assetSearchParams};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/saved_calculation', this.props.access_token, params)
            .then((response) => {
                let respData                =   response.data;
               if(respData.data){
                this.setState({
                    listingData                     :   respData.data,
                    listingMeta                     :   respData.meta,
                    totalListingCount               :   respData.meta.total,

                });
               }
            }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    invoiceListDownloadHandler          =   ()  =>  {
        this.setState({invoiceListDownloading: true});
        
        if(this.state.assetSearchParams === null){
            swal({
                title: "Download",
                text: "The download will be for trailing three months period, in case you want a different criteria then please use search button before downloading.",
                icon: "warning",
                // dangerMode: true,
                buttons: ["Cancel", "Okay"],
            })
                .then(willDownload => {
                    if (willDownload) {
                        let params = {'transaction_period_range': "current_quarter",download : 'download'}
                      
                        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/saved_calculation', this.props.access_token, params, {} , (response) => {
                            download.file(response.data.file_path);
                        }).then(() => {
                            this.setState({invoiceListDownloading: false});
                        });}
                });
           
            }else{
                let params = {...this.state.assetSearchParams,download : 'download'}
            HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/saved_calculation', this.props.access_token, params, {} , (response) => {
               download.file(response.data.file_path);
           }).then(() => {
               this.setState({invoiceListDownloading: false});
           });
        }

    }

    //Initialize Search Form :-
    assetSearchModalInit                =   ()  =>  {
        this.searchAssetModal.show({focus : true})
    }

    invoiceSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.invoiceSearchForm);
        let searchedElems               =   [];
        let assetSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.invoiceSearchFormLabel[key];
            let value                       =   this.state.invoiceSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_id') {
                        show_val            =   this.state.searchedAsset ? this.state.searchedAsset.display_label : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'transaction_period_range') {
                       let  start_range = moment(this.state.invoiceSearchForm.transaction_period_range_start).format('DD-MMM-YYYY');
                       let end_range = moment(this.state.invoiceSearchForm.transaction_period_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val            =  this.props.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + show_val);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems : searchedElems,
            assetSearchParams : assetSearchParams
        }, () => {
            this.loadListingTblData(1);
            this.searchAssetModal.hide();
        });
    }

    itemSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems   :   [],
            assetSearchParams   :   null,
            searchedAsset       :   null,
            invoiceSearchForm     :   {...this.invoiceSearchFormInitialState}
        }, () => {
            this.loadListingTblData(1);
            this.searchAssetModal.hide();
            this.props.history.push('/invoice_calculation_list');
        });
    }

    showInvoiceView              =            (id)     =>  {
        this.setState({ minimizeTable: true, view_loading: true })
        setTimeout(() => {
            this.setState({
                transactionId: id
            }, () => this.setState({ view_loading: false }))
        }, 1000)
    }

    closeInvoiceView              =            ()     =>  {
        this.setState({minimizeTable    :   false})
    }

    //function to delete invoice
    deleteInvoiceCalculation          =     (id)         => {
        swal({
            title: "Delete Invoice Calculation",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation/' + id, this.props.access_token,{},{},(resp) => {
                    toast.success(resp.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.setState({showSaveInvoiceForm : false,invoiceEditFormSubmittig : false})
                    this.getInvoiceFullDetails(this.state.transactionId)
                }).then(() => this.loadListingTblData(this.state.listing_tbl_page));
            }
        });
    }

    showInvoiceCalculationForm              =   (id)  =>  {
        if(id == this.state.transactionId){
            this.setState({showSaveInvoiceForm : true})
        }       
    }

    cancelEdit                  =         ()       =>  {
        this.setState({showSaveInvoiceForm : false})
    }


    invoiceCalculationListingTableJsx                =   ()  =>  {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.itemSearchClear} />
            <div className="">
                <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                    <thead className="table-secondary" >
                    { this.state.minimizeTable
                        ? (<tr><th scope="col">Invoice</th></tr> )
                        :   (<tr>
                                <th scope="col" style={{width : "5%"}} className="text-center">S.No</th>
                                <th scope="col" style={{width : "20%"}}>Asset</th>
                                <th scope="col" style={{width : "10%"}}>Invoice Number</th>
                                <th scope="col" style={{width : "14%"}}>Site</th>
                                <th scope="col" style={{width : "22%"}}>Invoice Rule</th>
                                <th scope="col" style={{width : "16%"}}>Period  (From - To)</th>
                                <th scope="col" style={{width : "8%"}}>Amount</th>
                                <th scope="col" style={{width : "5%"}}>Action</th></tr>)}
                    </thead>
                    <tbody>
                    { this.state.listing_loading
                        ? <tr><td className="text-center" colSpan="9"><Loader /></td></tr>
                        : (this.state.listingData.length > 0
                            ? this.state.listingData.map((item, index) => this.listingTableTrJsx(item, index))
                            : this.listingTableTrJsx(null) )
                    }
                    </tbody>
                </table>
            </div>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
        </Ax>);
    }

    listingTableTrJsx                               =   (item, index=0)  =>  {
        return (
            item
                ? (<tr key={index}>
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <td onClick={() => { this.showInvoiceView(item.transaction_id) }}>
                                        <a role="button" className="link-primary" >{item.transaction_id}</a> <br />
                                        <small>Asset : <b>{item.asset.name} ({item.asset.asset_code})</b></small> <br />
                                        <small>Period : {item.period_start_display} - {item.period_end_display}</small> <br />
                                    </td>
                                </Ax>
                            )
                            : (<Ax>
                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                <td>{item.asset.name} <br /> <small>Code : {item.asset.asset_code}</small></td>
                                <td className="text-truncate" style={{ maxWidth: "calc( 10 * 1vw )" }}>
                                    <button disabled={!this.props.permissions.includes('iam-asset-invoice-calculation-view') || this.state.showSaveInvoiceForm} title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''} style={{ pointerEvents: "all" }} type="button" onClick={() => this.showInvoiceView(item.transaction_id)} className="btn btn-link">{item.transaction_id}</button>
                                </td>
                                <td>
                                    {item.site ? item.site.site_name : ''}<br />
                                    {item.site ? <small>Code : {item.site.site_code}</small> : ''}
                                </td>
                                <td>{item.include_rule.name} <br /> <small>Number : {item.include_rule.rule_number}</small></td>
                                <td>{item.period_start_display} - {item.period_end_display}</td>
                                <td className="text-end">Rs.{item.total_amount}</td>

                                <td className="p0 text-center">
                                    <span className="dropdown">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                {/* <Permission key={'iam-asset-invoice-calculation-view'}>View</Permission>*/}
                                                <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-asset-invoice-calculation-view')) {
                                                            return this.showInvoiceView(item.transaction_id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-view') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >View</a>
                                            </li>
                                            <li>
                                                <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-asset-invoice-calculation-delete')) {
                                                            return this.deleteInvoiceCalculation(item.transaction_id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete</a>
                                            </li>
                                        </ul>
                                    </span>
                                </td>
                            </Ax>)}
                </tr>)
                : (<tr key={index}><td className="text-center" colSpan={9}>No Record Found</td></tr>)
        );
    }

    invoiceCalculationSaveJsx                       =   ()  =>  {
       
        return (<div className="page_containt">
            <div className="pageTbl">
                {this.state.view_loading ? <div className='text-center'><Loader /></div>
                    : this.state.transactionId ? <InvoiceCalulationView Id={this.state.transactionId} showSaveInvoiceForm = {this.state.showSaveInvoiceForm} cancelEdit={this.cancelEdit} /> : null}
            </div>
        </div>)
    }
    
    downloadInvoiceHandler                          =   (id)  =>  {
        this.setState({ inovoiceDownloading: true});
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation_download/' + id, this.props.access_token,{},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({inovoiceDownloading: false}));
    }
    
    viewLinkJsx                         =   (transaction_id)  =>  {
        return( <a role="button" 
        onClick={() => { if(this.props.permissions.includes('iam-asset-invoice-calculation-view'))  { 
                return this.showInvoiceView(transaction_id) 
            } }} 
        className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-view') ? '' : 'disabled'].join(' ')} 
        title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''} 
        style={{"pointerEvents" : "all"}}
    >View</a>)
    }
    
    deleteLinkJsx                       =   (transaction_id)  =>  {
        return <a role="button" 
            onClick={() => { if(this.props.permissions.includes('iam-asset-invoice-calculation-delete'))  { 
                    return this.deleteInvoiceCalculation(transaction_id) 
                } }} 
            className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? '' : 'disabled'].join(' ')} 
            title={!this.props.permissions.includes('iam-asset-invoice-calculation-delete') ? 'You do not have permission to perform this action' : ''} 
            style={{"pointerEvents" : "all"}}
        >Delete</a>;
    }
    
    editLinkJsx                       =   (transaction_id)  =>  {
        return (<a role="button"
            onClick={() => {
                if (this.props.permissions.includes('iam-asset-invoice-calculation-edit')) {
                    return this.showInvoiceCalculationForm(transaction_id)
                }
            }}
            className={['dropdown-item', this.props.permissions.includes('iam-asset-invoice-calculation-edit') ? '' : 'disabled'].join(' ')}
            title={!this.props.permissions.includes('iam-asset-invoice-calculation-edit') ? 'You do not have permission to perform this action' : ''}
            style={{ "pointerEvents": "all" }}
        >Edit</a>)
    }

    invoiceCalculationListingJsx                    =   ()  =>  {
        return (<Ax>
            <div className="page_title row m0">
                <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                    <h3>{this.state.minimizeTable ? "Invoice Listing" : "Invoice Calculation List"}</h3>
                    <div className="text-end mt15">
                         {!this.state.minimizeTable ? 
                            <Link to={'/report/asset_invoice_calculator'}>  <button type="button" className="btn btn-primary"  >
                                        Add
                                    </button></Link> : null}
                        <button type="button" onClick={this.assetSearchModalInit} className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <button disabled={this.state.invoiceListDownloading} onClick={this.invoiceListDownloadHandler} type="button" className="btn btn-secondary">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                            {this.state.invoiceListDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>
                </div>
                {
                    this.state.minimizeTable
                        ? (
                            <div className="col-9 tap_border_left">
                                <h3>Invoice Number : {this.state.transactionId} </h3>
                                <div className="text-end mt15">
                                    {this.state.showSaveInvoiceForm == false
                                        ? <span className="dropdown">
                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                
                                                {this.state.showSaveInvoiceForm == false ? <li>{this.editLinkJsx(this.state.transactionId)}</li> : null}
                                                <li>{this.deleteLinkJsx(this.state.transactionId)}</li>
                                            </ul>
                                        </span>
                                        : null
                                    }
                                    <button disabled={this.state.inovoiceDownloading || !this.props.permissions.includes('iam-asset-invoice-calculation-view')} onClick={() => this.downloadInvoiceHandler(this.state.transactionId)} type="button" className="btn btn-secondary" title={!this.props.permissions.includes('iam-asset-invoice-calculation-view') ? 'You do not have permission to perform this action' : ''} style={{ "pointerEvents": "all" }}><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download {this.state.inovoiceDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                    <button onClick={this.closeInvoiceView} type="button" className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                    </button>
                                </div>
                            </div>
                        )
                        : null
                }
            </div>

            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3 ' : 'col-12'].join(' ')} >{this.invoiceCalculationListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.invoiceCalculationSaveJsx()}</div> : null}
                </div>
            </div>
        </Ax>);
    }
    
    assetSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="searchAssetModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Invoice Calculation Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.invoiceSearchFormSubmit} id="invoiceSearchForm">
                            <div className="modal-body">
                            <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type Group</label>
                                    <div className="col-sm-10">
                                    <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    invoiceSearchForm: {
                                                        ...this.state.invoiceSearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.invoiceSearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.invoiceSearchForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Name</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_site')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_user_sites.filter(s => this.state.invoiceSearchForm.search_site.includes(s.value))}
                                            placeholder="Select Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Invoice Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            value={this.state.invoiceSearchForm.search_transaction_id}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'invoiceSearchForm')}
                                            placeholder="Invoice Number"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Invoice Rule</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="invoice_rule"
                                            value={this.state.invoiceSearchForm.invoice_rule}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'invoiceSearchForm')}
                                            placeholder="Invoice Rule"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'invoiceSearchForm', 'search_asset_id');
                                                this.setState({ searchedAsset: selectedOption ? selectedOption : null });
                                            }}
                                            value={this.state.searchedAsset ? { ...this.state.searchedAsset, label: this.state.searchedAsset.display_label } : null}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods && this.state.all_periods !== undefined ? this.state.all_periods : []}
                                            value={this.state.invoiceSearchForm.transaction_period_range}
                                            startDate={this.state.invoiceSearchForm.transaction_period_range_start}
                                            endDate={this.state.invoiceSearchForm.transaction_period_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    invoiceSearchForm: { ...this.state.invoiceSearchForm, transaction_period_range: period, transaction_period_range_start: startDate ? startDate : null, transaction_period_range_end: endDate ? endDate : null }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
              <Helmet><title>Invoice Calculation List</title></Helmet>
            {this.invoiceCalculationListingJsx()}
            {this.assetSearchModalJsx()}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
    };
};

export default connect(mapStateToProps)(InvoiceCalculationList);