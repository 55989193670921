import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import { DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import RunningStatusViewModal from '../../assets/action/serviceChecksheet/runningStatus/RunningStatusViewModal';
import LinkedItemsViewModal from '../../assets/action/serviceChecksheet/runningStatus/LinkedItemsViewModal';
import { Modal } from 'bootstrap';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemsConsumptionHistory from '../../inventory/consumption/ItemsConsumptionHistory';

class ServiceChecksheetRunningStatus extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.cancelToken        =   null;
        this.reportUrl          =   IAM_API_BASE_URL + '/report/service_checksheet_running_status';

        this.reportFilterFormInitialState   =   {
            search_item_tags                  :       [],
            search_item_ids                   :       [],
            'asset_group_ids'                 :       [],
            search_asset_type_ids             :       [],
            search_asset_ids                  :       [],
            site_group_ids                    :       [],
            search_site_ids                   :       [],
            search_name                       :       '',
            search_scs_running_status          :       []
        };
        
        this.reportFilterFormLabel          =   {
            search_item_tags                :       'Item Tags : ',
            search_item_ids                 :       'Items : ', 
            'asset_group_ids'                :   'Asset Type Groups : ',
            search_asset_type_ids           :       'Asset Type : ',
            search_asset_ids                :       'Assets : ',
            search_site_ids                 :       'Sites : ',
            site_group_ids                  :       'Site Group : ', 
            search_name                     :       'Service Checksheet Name : ',
            search_scs_running_status       :       'Status : '
        };
         
        this.executeFormInit                =   {
            odometer                        :   '',
            hourmeter                       :   '',
            hourmeter2                      :   '' , 
            hourmeter3                      :   '' , 
            hourmeter4                      :   '' ,
            date_time                       :   '',
            odometer2                       :   '',
            odometer3                       :   '',
            operation_cycle                 :   '',
            operation_cycle2                :   '',
          }
  
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null,
            allItemTags                         :   [],
            manufactures                        :   [],
            allStatusOptions                    :   [],
            searchedAssets                      :   [],
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],
            linked_items                        :   [],
            selectedAssetForExecution           :   [],
            execution_loading                   :   false,
            asset_loading                       :   false,
            executeForm                         :   {...this.executeFormInit},
            executeFormSubmitting               :   false,
            totalListingCount                   :   0,
            time                                :   '',
            allSiteGroupsList                   :   [],
            allAssetTypesGroupsList             :   [],
            parameterOptions                    :       [{value : 'ok' , label : 'Checked and OK' },
                                                         {value : 'required_attention' , label : 'May Required Attention' },
                                                         {value : 'required_immediateattention' , label : 'Required Immediate Attention' }],
            reportData                          :   null,                  
        };

        this.RunningStatusModalRef      =        React.createRef();  
        this.LinkedItemsViewModalRef    =        React.createRef(); 
        this.itemDetailsRef                  =    React.createRef(); 
        this.itemsConsumptionHistoryRef      =    React.createRef();  
    }
    
    componentDidMount                   =   ()  =>  {
       
        this.initPageComponent(this.props);
        this.executeCounterlogModal            =   new Modal(document.getElementById('executeCounterlogModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps           =   (nextProps)  =>  {
        if(this.props.iam_user_sites != nextProps.iam_user_sites) {
            this.initPageComponent(nextProps);
        }
    }
    
    initPageComponent                       =   (pr)  =>  {
        
        const report_name   =   'service_checksheet_running_status';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        });

        //Initaolaize Data :-
        let Status                      =   [{value: 'over_due', label: 'OverDue'}, {value: 'due_soon', label: 'Due-Soon'}, {value: 'in_limit', label: 'Within Limits'}];
        this.setState({
            access_token                :   pr.access_token,
            formDataLoaded              :   false,
            listing_loading             :   true,
            iam_asset_types             :   pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites              :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],          
            allStatusOptions            :   Status,
            allSiteGroupsList           :   pr.site_groups && pr.site_groups.length > 0 ? pr.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            allAssetTypesGroupsList     :   pr.asset_groups && pr.asset_groups.length > 0 ? pr.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        }, () => {
            let search_scs_running_status              =   ['over_due','due_soon','in_limit'];
            let ParamObject             =   new URLSearchParams(this.props.location.search);
            let search_site_ids         =   [];
            let search_asset_type_ids   =   [];
            let search_asset_ids        =   [];
            let assetName               =   [];
            if(ParamObject.get('scs_status')) {
                search_scs_running_status              =   ParamObject.get('scs_status');
            }
            if(ParamObject.get('search_site_ids')) {
                let site                =   this.state.iam_user_sites.find(us => us.value == ParamObject.get('search_site_ids'));
                if(site) {
                    search_site_ids     =   [site.value];
                }
            }
            if(ParamObject.get('search_asset_type_ids')) {
                let assetType           =   this.state.iam_asset_types.find(us => us.value == ParamObject.get('search_asset_type_ids'));
                if(assetType) {
                    search_asset_type_ids=   [assetType.value];
                }
            }
            if(ParamObject.get('search_asset_ids')) {
                let assetId           =    ParamObject.get('search_asset_ids');
                let asset_name           =    ParamObject.get('asset_name');
                if(assetId) {
                    search_asset_ids    =   [assetId];
                    assetName           =   [{label:asset_name,display_label:asset_name}]
                }
            }
            this.setState({
                formDataLoaded          :   true, 
                reportFilterForm        :   {
                    ...this.state.reportFilterForm,
                    search_scs_running_status              :   search_scs_running_status,
                    search_site_ids         :   search_site_ids,
                    search_asset_type_ids   :   search_asset_type_ids,
                    search_asset_ids        :   search_asset_ids
                },
                searchedAssets          :   assetName
            }, () => {
               
                this.reportFilterFormSubmit();
            });
        });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                  
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_scs_running_status') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_name') {
                        show_val            =  this.state.reportFilterForm.search_name   ;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1,reload=false)    =>  {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
       
        let params                      =   {page:page,get_asset_profile : "Y",...this.state.submittedReportFilterForm,reload : reload ? 'reload' : false };
        
        if (this.cancelToken) {
            this.cancelToken.cancel("Operation canceled due to new request.")
        }
        this.cancelToken                =   axios.CancelToken.source();
        axios({
            method          :   'GET',
            headers         :   { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params          :   params,
            url             :   this.reportUrl,
            cancelToken     :   this.cancelToken.token
        }).then((response) => {
            let respData                =   response.data.data;

            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount               :   respData.total,
                time                            :   response.data.time ? response.data.time :''
            },() => {
                this.setState({listing_loading: false});
            });
            if(respData){
                let all_scs_ids       =   respData.data.map((scs,key) => scs.id);
                if(respData.data && respData.data.length > 0){
                   this.getAllLinkedItemsList(all_scs_ids)
                }
            }
        }).catch((err) => {
            if (axios.isCancel(err)) {
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        }).then(() => {
            // this.setState({listing_loading: false});
        });
    }

    getAllLinkedItemsList                   =   (all_ids)  =>   {
        let params                      =   {scs_setting_ids : all_ids};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/scs/asset/item_linked_scs_setting', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                linked_items        :      respData.data,
            })
        })
    }

    linkAssetHandler                        =   (id)    =>      {
        this.props.history.push('/asset/dashboard/' + id)
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   ()  =>  {
        new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    runningStatusModalInit                  =   (id)    =>  {
        let scsRunningData   = this.state.listingData.find(sc => sc.id == id)
        if(scsRunningData){
            this.RunningStatusModalRef.current.runningStatusModalInit(scsRunningData); 
        }
       
    }

    viewLinkedItemInit                      =   (id, name)  =>  {
        let linkedItemData   = this.state.linked_items.find(sc => sc.scs_setting_id == id)
        if(linkedItemData){
            this.LinkedItemsViewModalRef.current.linkedItemsModalInit(linkedItemData,name); 
        }
    }

    getAsssetDetails                        =   (id)  =>  {
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    executeSingleModalInit                  =   (id)    =>  {
        this.setState({ execution_loading: true, selectedAssetForExecution: [] })
        let selectedAssetForExecution       =       [];
        let newList                         =       this.state.listingData.find(scs => scs.id == id);

        selectedAssetForExecution.push(newList)
        this.getAsssetDetails(newList.asset_id)
        this.executeCounterlogModal.show()
        this.setState({ selectedAssetForExecution: selectedAssetForExecution, execution_loading: false })
              
    }
   
    submitExecuteFormHandler                =   (e) =>  {
        e.preventDefault();

        let scs_setting_ids     =       this.state.selectedAssetForExecution.map((s,k) => s.id)
       
        let parameter                 =         [];
        parameter                     =         this.state.selectedAssetForExecution.filter(s => s.enable_parameter == "Y");
      
        let executeForm                =       {...this.state.executeForm}
        let frmData                    =       {scs_setting_ids        :       scs_setting_ids ,
                                                ...executeForm,
                                                parameter                   :       parameter.length > 0
                                                                            ?       parameter.map((p,key) => {
                                                                                        if(p.execute_level){
                                                                                            return({scs_setting_id : p.id, execute_level : p.execute_level ? parseInt(p.execute_level) : null })
                                                                                        }else{
                                                                                            return({scs_setting_id : p.id,execute_val : p.execute_val ? p.execute_val : null})
                                                                                        }
                                                                                    })      
                                                                            :        []};
        this.setState({executeFormSubmitting: true})
        HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/scs/execute_with_reading' , this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.executeCounterlogModal.hide()
            this.loadListingTblData(1,true)
            this.setState({executeForm : {...this.executeFormInit}})
        }).then(() => this.setState({ executeFormSubmitting: false }));
    }

    executeCounterlogModalJsx               =   ()  =>  {
        return (
            <div className="modal fade" id="executeCounterlogModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Execute Service CheckSheet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">
                            <table className="table table-bordered bg-white align-items-center  table-sm table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                                        <th scope="col" style={{ width: "65%" }} >Service Checksheet Name</th>
                                        <th scope="col" style={{ width: "35%" }} >Parameter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.execution_loading
                                        ? <tr colSpan={2}><td className='text-center'><Loader /></td></tr>
                                        : this.state.selectedAssetForExecution && this.state.selectedAssetForExecution?.length > 0 && this.state.selectedAssetForExecution.map(((sa, key) => {

                                            return (<tr key={key + 1}>
                                                <td className='text-center'>{key + 1}</td>
                                                <td>{sa?.alias_name ? sa?.alias_name : "-"}</td>
                                                <td>{sa.enable_parameter == "Y"
                                                    ? <div>{sa.parameter && sa.parameter.type == "range"
                                                        ? <span>
                                                            <input
                                                                type="number"
                                                                className='form-control'
                                                                name={"execute_level"}
                                                                onChange={(e) => this.dynamicInputHandlerByKey(e, key, 'selectedAssetForExecution')}
                                                                value={this.state.selectedAssetForExecution[key]?.execute_level}
                                                                placeholder={`${sa.parameter.type_display ?? ""}`}
                                                                style={{ height: "30px", fontSize: "93%" }} />
                                                           </span>
                                                        : <TapSelect
                                                            changeEvent={(selectedOption) => {
                                                                let selectedAssetForExecution         =   this.state.selectedAssetForExecution;
                                                                selectedAssetForExecution[key]        =   {...selectedAssetForExecution[key] , execute_val : selectedOption ? selectedOption.value : ''};
                                                                this.setState({selectedAssetForExecution : [...selectedAssetForExecution]});
                                                                
                                                            }}
                                                            
                                                            options={this.state.parameterOptions}
                                                            isClearable={true}
                                                            value={this.state.parameterOptions.find(m => m.value == this.state.selectedAssetForExecution[key]?.execute_val)}
                                                            placeholder="Please Select Options"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />}</div>
                                                    : "-"}</td>
                                            </tr>)
                                        }))}
                                </tbody>
                            </table>
                            {this.state.asset_loading ? <Loader />
                                : <form onSubmit={this.submitExecuteFormHandler} id="executeForm">
                                    <div className='counterlog'>
                                        <div className="tab_content_wrapper">
                                            <span className="content_heading">Counterlog</span>
                                        </div>
                                        {this.state.assetDetail && this.state.assetDetail.hour_meter == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Hour Meter </label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="hourmeter"
                                                        type="number"
                                                        value={this.state.executeForm.hourmeter}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Input in Hour"
                                                        onChange={(e) => { this.formInputHandler(e, 'executeForm') }}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>
                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.hour_meter2 == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Hour Meter 2 </label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="hourmeter2"
                                                        type="number"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.executeForm.hourmeter2}
                                                        placeholder="Input in Hour"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs2}</div>
                                                        : <div>No Counterlog</div>}

                                                </label>


                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.hour_meter3 == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Hour Meter 3 </label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="hourmeter3"
                                                        type="number"
                                                        value={this.state.executeForm.hourmeter3}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Input in Hour"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs3}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>
                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.hour_meter4 == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Hour Meter 4 </label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="hourmeter4"
                                                        type="number"
                                                        value={this.state.executeForm.hourmeter4}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Input in Hour"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.hours_abs4}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>
                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.odo_meter == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Odo Meter</label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="odometer"
                                                        type="number"
                                                        value={this.state.executeForm.odometer}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Input in Kms"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>
                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.odo_meter2 == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Odo Meter 2</label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="odometer2"
                                                        type="number"
                                                        value={this.state.executeForm.odometer2}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Input in Kms"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs2}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>


                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.odo_meter3 == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">Odo Meter 3</label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="odometer3"
                                                        type="number"
                                                        value={this.state.executeForm.odometer3}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Input in Kms"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.odometer_abs3}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>


                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.operation_cycle == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">OPC</label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="operation_cycle"
                                                        type="number"
                                                        value={this.state.executeForm.operation_cycle}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Operation Cycle Reading"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.cycle_abs}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>
                                            </div>
                                            : null}
                                        {this.state.assetDetail && this.state.assetDetail.operation_cycle2 == "yes"
                                            ? <div className="row my-3 align-items-center">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  ">OPC 2</label>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="operation_cycle2"
                                                        type="number"
                                                        value={this.state.executeForm.operation_cycle2}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder="Operation Cycle Reading"
                                                        onChange={(e) => this.formInputHandler(e, 'executeForm')}
                                                        style={{ height: "30px", fontSize: "93%" }}
                                                    />
                                                </div>
                                                <label className="col-sm-5 form-text ">
                                                    {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                        ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.cycle_abs2}</div>
                                                        : <div>No Counterlog</div>}
                                                </label>
                                            </div>
                                            : null}

                                        <div className="row my-3 ">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  ">Counterlog Date</label>
                                            <div className="col-sm-4 add_calender_section">
                                                <DatePicker
                                                    selected={
                                                        this.state.executeForm.date_time
                                                            ? moment(this.state.executeForm.date_time).toDate()
                                                            : false
                                                    }
                                                    name="date_time"
                                                    onChange={(value, event) => {
                                                        this.formDateHandler('date_time', value, 'executeForm', 'YYYY-MM-DD HH:mm:ss')
                                                    }}
                                                    popperPlacement="right-start"
                                                    className={"form-control"}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    autoComplete="off"
                                                    scrollMonthYearDropdown
                                                    placeholderText={`Please Enter Due Date`}
                                                    showTimeSelect
                                                    timeIntervals={5}
                                                    timeCaption="Time"
                                                    dateFormat="dd-MMM-yyyy hh:mm a"
                                                    maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}

                                                />
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                    alt="requisition_date"
                                                    className="add_calender-icon" />
                                            </div>
                                            <label className="col-sm-5 form-text ">
                                                {this.state.assetDetail && this.state.assetDetail.latest_counterlog
                                                    ? <div>Latest Reading : {this.state.assetDetail?.latest_counterlog?.feed_date_time_display}</div>
                                                    : null}
                                            </label>
                                        </div>
                                    </div>
                                </form>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" disabled={this.state.executeFormSubmitting} data-bs-dismiss="modal" >Cancel</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.executeFormSubmitting} form="executeForm">Update {this.state.executeFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    viewItemModalInit                    =   (item,linkedQty)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item,linkedQty,true)
        }
    }

    viewItemConumptionHistoryInit       =       (id)          =>      {
        let item_ids = []
        if (this.state.linked_items && this.state.linked_items.length > 0) {
            item_ids = this.state.linked_items.filter(li => li.scs_setting_id == id).map((item, index) => {
                return (item.items?.length > 0 ? item.items.map((i, itemKey) => { return (i.item_id) }) : [])
            })
            this.itemsConsumptionHistoryRef.current.modalInit(item_ids.flat(),this.state.assetId)
        } 
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Service CheckSheet Running Status </h3>
                <div className='text-end mt15'>
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button disabled={this.state.listing_loading || this.state.reportDownloading} type="button" onClick={() => this.loadListingTblData(1, true)} className="btn btn-secondary"><tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} />{this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>

                </div>
            </div>

        </div>);
    }
    
    reportFilterFormJsx                     =   ()  => {
        return (<div id="reportFilterForm" className="bg-white collapse " aria-expanded="true">
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Service Checksheet Name/Alias Name</label>
                    <div className="col-sm-6">
                        <input
                            name="search_name"
                            type="text"
                            value={this.state.reportFilterForm.search_name}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Service Checksheet Name / Alias Name"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            menuPlacement="top"
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_scs_running_status')}
                            value={this.state.allStatusOptions.filter(u => this.state.reportFilterForm?.search_scs_running_status?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            menuPlacement="top"
                            placeholder="Select Status"
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4" onClick={this.reportFilterhandler}>Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            {/* { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? */}
                     {this.reportTableJsx()} 
                     {/* : null)
            } */}
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        
        return (<Ax>
            <table className="table table-bordered bg-white align-items-center  table-sm table-hover  mb-0" id="rowDataReportTable">
                <thead>
                    <tr>
                        <th scope="col" style={{ width: "20%" }}>Asset Name</th>
                        <th scope="col" style={{ width: "20%" }}>Service Checksheet Name</th>
                        <th scope="col" style={{ width: "19%" }}>Linked Items</th>
                        <th scope="col" style={{ width: "16%" }} className="text-start">Last CS Done</th>
                        <th scope="col" style={{ width: "10%" }}>Running Status</th>
                        <th scope="col" style={{ width: "8%" }} className="text-center">Due Date </th>
                        <th scope="col" style={{ width: "5%" }} className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading ? (<tr><td className="text-center" colSpan={8}><Loader/></td></tr>)
                        : this.state.listingData && this.state.listingData.length > 0
                        ? (<Ax> {this.state.listingData.map((rowData, k) => {
                            let item_ids = [];
                            if (this.state.linked_items && this.state.linked_items.length > 0) {
                                item_ids = this.state.linked_items.filter(li => li.scs_setting_id == rowData.id).map((item, index) => {
                                    return (item.items?.length > 0 ? item.items.map((i, itemKey) => { return (i.item_id) }) : [])
                                })
                            }
                            let item_all_ids = item_ids.flat()
                            return (
                                <tr key={k}>
                                    <td>{rowData.asset_profile ?
                                        <div>
                                            <Link target="_blank" to={{ pathname: `/assets/service_checksheet/${rowData?.asset_id}` }}> {rowData.asset_profile.name}</Link><br />
                                            <small>
                                                Code :  {rowData.asset_profile.asset_code ?? "-"} <br />
                                                Asset Type :  {rowData.asset_profile.asset_type_name ?? "-"}<br/>
                                                Site :  {rowData.asset_profile.site ?? "-"}
                                            </small>
                                        </div> : '-'}</td>
                                    <td><b><a role="button" className="link-primary " onClick={() => this.runningStatusModalInit(rowData.id)}>{rowData.alias_name ? rowData.alias_name : "-"}</a></b></td>
                                    <td>
                                        {this.state.linked_items && this.state.linked_items.length > 0
                                            ? this.state.linked_items.filter(li => li.scs_setting_id == rowData.id).map((item, index) => {

                                                return <Ax>{item.items?.length > 0 ? item.items.map((i, itemKey) => <div className='mt-1 link-primary cursor_pointer' onClick={() => this.viewItemModalInit(i.item, i.qty)}>{itemKey + 1}.{i.item.name} : {i.qty} {i.item && i.item.measuring_unit ? i.item.measuring_unit.name : ''} <br /><small className='form-text mt-1'> (Code : {i.item?.item_code})</small></div>) : " -"}<br /></Ax>

                                            })
                                            : "-"}
                                    </td>
                                    <td className="text-start"> {rowData.latest_execution_cl && rowData.latest_execution_cl.length > 0 ? rowData.latest_execution_cl.map((item, index) => { return (<div className="mt-1"> {item.label} : {item.reading}  {item.unit} <br /></div>) })
                                            : "-"}</td>
                                        <td className='text-center'>
                                            {rowData.latest_execution_id ?
                                                <div>
                                                    <div className="progress">
                                                        <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${rowData.max_running}%`, backgroundColor: `${rowData.bar_bg_color}` }}
                                                            aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                    <small className='form-text'>{rowData.max_running}%</small>
                                                </div>
                                                : <span>NYA <sup>#</sup></span>}

                                        </td>
                                        <td className='text-center'>{rowData.expected_due_date ?  rowData.expected_due_date : "-"}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => this.runningStatusModalInit(rowData.id)}>View</a></li>
                                            <li><a className="dropdown-item" role="button" onClick={() => this.viewLinkedItemInit(rowData.id, rowData.alias_name)}>View Linked Items</a></li>
                                            <li><a className={item_all_ids && item_all_ids.length == 0 ? "dropdown-item disabled" : "dropdown-item "} disabled={item_all_ids && item_all_ids.length == 0 ? true : false} role="button" onClick={() => this.viewItemConumptionHistoryInit(rowData.id)}>View Item Consumption History</a></li>
                                            <li> <Link className="dropdown-item" target="_blank" to={{ pathname: `/assets/execution_history/${rowData?.asset_id}`, search: "?" + new URLSearchParams({ search_scs_name: rowData.name ? rowData.name : rowData.alias_name }).toString() }}>History</Link></li>
                                            <li><a className="dropdown-item" role="button" onClick={() => this.executeSingleModalInit(rowData.id, rowData.name)}>Execute</a></li>
                                        </ul>
                                    </td>
                                </tr>)
                        })
                        }</Ax>)
                        : (<tr><td className="text-center" colSpan={8}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet>Service Checksheet Running Status - Report</Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.reportFilterFormJsx()}
                        {/* {this.state.formDataLoaded
                            ? (<Ax> */}
                                 {!this.state.listing_loading && this.state.time && <div style={{backgroundColor:"white"}} className='px-3 py-1 text-end'>
                                    <small className='form-text '>Report is based on Service Checksheet Data at ( {this.state.time} )</small>
                                </div>}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                               
                                {this.reportListingDisplayJsx()}
                            {/* </Ax>)
                            : <Loader /> */}
                        {/* } */}
                        <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                    </div>
                </div>
            </div>
            {this.executeCounterlogModalJsx()}
            <RunningStatusViewModal parentProps={this.props} ref={this.RunningStatusModalRef} />
            <LinkedItemsViewModal parentProps={this.props} ref={this.LinkedItemsViewModalRef} />
            <ItemDetailsModal ref={this.itemDetailsRef}  /> 
            <ItemsConsumptionHistory ref={this.itemsConsumptionHistoryRef}/>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ServiceChecksheetRunningStatus);