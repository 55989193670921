import React from 'react';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from "../../../components/AppBaseComponent";
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Modal } from 'bootstrap';

class ItemViewInList extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                  =   {
            item                        :   this.props.item,
            remark                      :   this.props.remark,
            due_date                    :   this.props.due_date,
            view_hsn                    :   this.props.view_hsn || false
        };
    }

    componentDidMount(){
          
       
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.item !== this.props.item) {
            this.setState({item : nextProps.item})
        }
    }
       
  
    
    render() {
        const {item, remark, view_hsn,due_date}        =   this.state;
        
        return item ? <Ax>
            <span className="fw-bold link-primary cursor_pointer" onClick={() => this.props && this.props.viewItemModal && this.props.viewItemModal()}>{item.name}</span><br />
            <div className="form-text">Code: <b>{item.item_code}</b></div>
            <div className="form-text">Mfr: <b>{item.manufacturer ? item.manufacturer.name :'-'}</b> (MPN: <b>{item.manufacturer_part_no}</b> )</div>
            {/* {item.hsn_code && view_hsn ? <div className="form-text">{item.category == "goods" ? "HSN " : "SAC "}: <b>{item.hsn_code}</b></div> : null} 
            {item && item.tags && item.tags.length > 0
                ? (item.tags.map( (t,k) => <span key={k} className="badge bg-secondary">{t.name}</span>))
                : null
            } */}
            {remark ? <div className="form-text">Remark: <b>{remark}</b></div> : null}
            {due_date ? <div className="form-text">Due Date: <b>{due_date}</b></div> : null}
           
        </Ax> : null ;
    }
}

export default ItemViewInList;