import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import Ax from "../../../../../components/hoc/Ax";
import { Helmet } from 'react-helmet';
import tapIcon from '../../../../../services/TapIcon';
import TapSelect from '../../../../../components/ui/TapSelect';
import TabNavigationAssetMatrix from '../TabnavigationAssetMatrix';
import HttpAPICall from '../../../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "bootstrap";
import Loader from '../../../../../components/ui/Loader/Loader';
import swal from "sweetalert";

const AdditionalAttributes = ({ matrix_enc_id, access_token }) => {
    const [matrixId, setMatrixId] = useState(matrix_enc_id);
    const [attributes, setAttributes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveFormSubmitting, setSaveFormSubmitting] = useState(false);
    const initialFormData = { asset_matrix_id: matrix_enc_id, name: '', type: '', notes: '' };
    const [formData, setFormData] = useState(initialFormData);
    const initialOption = ['Option 1', 'Option 2'];
    const [options, setOptions] = useState(initialOption);
    const [edit, setEdit] = useState(false);
    const addAttributeModalRef = useRef(null);
    const additionalAttrType = [
        { value: 'text', label: 'Text' },
        { value: 'numeric', label: 'Numeric' },
        { value: 'date', label: 'Date' },
        { value: 'datetime', label: 'Date Time' },
        { value: 'dropdown', label: 'Dropdown' }
    ];

    useEffect(() => {
        setMatrixId(matrix_enc_id);
        fetchAttributes();
    }, [matrix_enc_id]);

    useEffect(() => {
        addAttributeModalRef.current = new Modal(document.getElementById('addAttributeModal'), { keyboard: false, backdrop: 'static' });
    }, []);

    const fetchAttributes = () => {
        setLoading(true);
        const url = `${IAM_API_BASE_URL_2}/matrix/additional_attribute_list/${matrixId}`;
        HttpAPICall.withAthorization('GET', url, access_token, {}, {}, (response) => {
            setAttributes(response.data.data);
        }).then(() => {
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching attributes:", error);
            setLoading(false);
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
   
    const addAttributeModalInit = () => {
        setFormData(initialFormData);
        addAttributeModalRef.current.show(); // Show the modal
    };
    const handleEditClick = (item) => {
        setFormData({ id: item.id, name: item.name, type: item.type, notes: item.notes });
        setOptions(item.options || []); 
        setEdit(true);
        addAttributeModalRef.current.show(); // Show the modal
    };
    
    const deleteAttribute = (id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed. Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then((willDelete) => {
            if (willDelete) {
                const url = `${IAM_API_BASE_URL_2}/matrix/additional_attribute_delete/${matrixId}/${id}`;
                HttpAPICall.withAthorization('DELETE', url, access_token, {}, {}, (response) => {
                    toast.success("Attribute deleted successfully!");
                    fetchAttributes(); // Refresh the attributes
                }).catch((error) => {
                    console.error("Error deleting attribute:", error);
                    toast.error("Failed to delete attribute.");
                });
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSaveFormSubmitting(true);
        const url = formData.id ? `${IAM_API_BASE_URL_2}/matrix/additional_attribute_edit` : `${IAM_API_BASE_URL_2}/matrix/additional_attribute_add`;
        const method = formData.id ? 'PUT' : 'POST';
        formData.asset_matrix_id = matrixId;

        // Prepare form data
        const submitData = formData.type === "dropdown" ? { ...formData, options } : formData;
        HttpAPICall.withAthorization(method, url, access_token, {}, submitData, (response) => {
            toast.success(formData.id ? "Attribute updated successfully!" : "Attribute added successfully!");
            fetchAttributes(); // Refresh the attributes
            addAttributeModalRef.current.hide(); // Hide the modal
            setEdit(false);
            setOptions(initialOption);
        })
            .then(() => setSaveFormSubmitting(false))
            .catch((error) => {
                console.error("Error saving attribute:", error);
                toast.error(formData.id ? "Failed to update attribute." : "Failed to add attribute.");
                setSaveFormSubmitting(false);
            });
    };

    const handleOptionValueChange = (e, index) => {
        const newOptions = [...options];
        newOptions[index] = e.target.value;
        setOptions(newOptions);
    };

    const handleDeleteOption = (index) => () => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    const addOption = () => {
        setOptions([...options, '']); // Add a new empty option
    };

    const handleCloseModal = () => {
        setEdit(false);
        setFormData(initialFormData);
        setOptions(initialOption)
    }


    const addAttributeModalJsx = () => {
        return (
            <div className="modal fade" id="addAttributeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{formData?.id ? "Edit Attribute" : "Add Attribute"}</h5>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="mb-3 row align-items-center">
                                    <div className='col-md-3'>
                                        <label htmlFor="name" className="form-label require">Attribute Name</label>
                                    </div>
                                    <div className='col-md-9'>
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Attribute Name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <div className='col-md-3'>
                                        <label htmlFor="notes" className="form-label">Notes</label>
                                    </div>
                                    <div className='col-md-9'>
                                        <input
                                            type="text"
                                            name="notes"
                                            className="form-control"
                                            placeholder="Leave a note"
                                            value={formData.notes}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row align-items-center">
                                    <div className='col-md-3'>
                                        <label htmlFor="type" className="form-label require">Type</label>
                                    </div>
                                    <div className='col-md-9'>
                                        <TapSelect
                                            options={additionalAttrType}
                                            changeEvent={(selectedOption) => {
                                                if (selectedOption) {
                                                    setFormData(prev => ({ ...prev, type: selectedOption.value }));
                                                } else {
                                                    setFormData(prev => ({ ...prev, type: '' })); // Reset type if no selection
                                                }
                                            }}
                                            value={additionalAttrType.find(type => type.value === formData.type) || null}
                                            isClearable={true}
                                            placeholder="Select Attribute Type"
                                            isDisabled={edit === true ? true : false}
                                        />

                                    </div>
                                </div>
                                <div className="row align-items-center my-1">
                                    <div className="offset-3 col-sm-9">
                                        {formData.type == "dropdown" ?
                                            <div>
                                                {options.length >= 2 &&
                                                    options.map((option, index) => (
                                                        <>
                                                            <div className="row" key={index}>
                                                                <div className={options.length > 2 ? "col-sm-10" : "col-sm-12"}>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control my-2"
                                                                        value={option}
                                                                        placeholder="Please Enter Options"
                                                                        required={true}
                                                                        onChange={(e) => handleOptionValueChange(e, index)}
                                                                    />
                                                                </div>
                                                                {options.length > 2 ?
                                                                    <div className="col-sm-2"><button className="btn btn-secondary my-3"
                                                                        onClick={handleDeleteOption(index)}>X</button></div> : null}

                                                            </div>
                                                        </>
                                                    )
                                                    )}
                                                < div className="link-primary text-end cursor_pointer" onClick={addOption}>Add New Option</div>
                                            </div>
                                            : null
                                        }
                                    </div >
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCloseModal}>Close</button>
                                <button type="submit" disabled={saveFormSubmitting} className="btn btn-primary">
                                    {formData.id ? "Update" : "Save"} {saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
        );
    };

    return (
        <Ax>
            <Helmet><title>Asset Matrix Details</title></Helmet>
            <ToastContainer />
            <div className="row ml1">
                <div className="col-12 pl0 bg-white ">
                    <TabNavigationAssetMatrix matrixId={matrixId} />
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            <div className="page_containt">
                                <div className="pageTbl p-2" style={{ height: "100%" }}>
                                    <div className="tab_content_wrapper mt-2">
                                        <span className="content_heading">Additional Attributes</span>
                                        <button type="button" className="btn btn-primary" onClick={addAttributeModalInit}>
                                            Add
                                        </button>
                                        {addAttributeModalJsx()}
                                    </div>
                                    {loading ? (
                                        <div><Loader /></div>
                                    ) : (
                                        <table className="table table-hover table-bordered bg-white mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="table-head-row text-center" style={{ width: "5%" }}>Sr.No</th>
                                                    <th scope="col" className="table-head-row" style={{ width: "15%" }}>Name</th>
                                                    <th scope="col" className="table-head-row" style={{ width: "15%" }}>Type</th>
                                                    <th scope="col" className="table-head-row" style={{ width: "25%" }}>Notes</th>
                                                    <th scope="col" className="table-head-row text-center" style={{ width: "5%" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-body">
                                                {attributes.length > 0 ? attributes.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.type}</td>
                                                        <td>
                                                            {/* {item.type === 'dropdown' ? (
                                                                item.options && item.options.length > 0 ? (
                                                                    item.options.join(', ')
                                                                ) : (
                                                                    "No Options Available"
                                                                )
                                                            ) : ( */}
                                                               { item.notes}
                                                            {/* )} */}
                                                        </td>
                                                        <td className="text-center">
                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                                data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu">
                                                                <li className="dropdown-item" onClick={() => handleEditClick(item)}>Edit</li>
                                                                <li className="dropdown-item" onClick={() => deleteAttribute(item.id)}>Delete</li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )) : <tr><td colSpan="5" className='text-center'>No Records Found</td></tr>}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Ax>
    );
}

const mapStateToProps = state => ({
    access_token: state.auth.access_token,
});

export default connect(mapStateToProps)(AdditionalAttributes);

