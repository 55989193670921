import React from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import axios from 'axios';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from "../../../components/ui/Status"
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import TapSelect from '../../../components/ui/TapSelect';
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import download from '../../../services/DownloadFile';

class CustomerList extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initContactForm               =      {
            name                           :      "",
            email                          :      "",
            mobile                         :      ""
        }

        this.initAddressForm            =   {
            label                           :   "Primary Address",
            gstin                           :   '',
            address1                        :   '',
            address2                        :   '',
            city                            :   '',
            landmark                        :   '',
            district_name                   :   null,
            pincode                         :   null,
            country_id                      :   99,
            district_id                     :   '',
        }

        this.initCustomerSearchForm     =   {
           search_name                      :   '',
           search_code                      :   '',
           search_state_ids                 :   [],
           search_gstin                     :   '',
           search_mobile                    :   '',
           search_email                     :   '',
           search_status                    :   []
        }

        this.customerSearchFormLabel        =   {
            search_name                     :   'Customer Name : ',
            search_code                     :   'Customer code : ',
            search_state_ids                :   'Location (Source of Supply) : ',
            search_gstin                    :   'GSTIN : ',
            search_mobile                   :   'Phone Number : ',
            search_email                    :   'Email : ',
            search_status                   :   'Status : '
        };

        this.initChangeStatusInit       =   {
            id                              :   '',
            status                          :   ''
        }

        this.state                          =   {
            customerAddScreenView               :   false,
            customerUpdateScreenView            :   false,
            minimizeTable                       :   false,
            customerFormDataLoaded              :   false,
            customerAddFormData                 :   [],
            stateList                           :   [],
            listing_loading                     :   false,
            details_loading                     :   false,
            listing_tbl_page                    :   1,
            customer_listing                    :   [],
            listingMeta                         :   null,
            totalListingCount                   :   0,
            customerDetails                     :   [],
            customerViewDetails                 :   [],
            addContactFormSubmitting            :   false,
            customerBulkUploadFormSubmiting     :   false,
            addContactForm                      :   {...this.initContactForm},
            editContactDetails                  :   false,
            allStateList                        :   [],
            allCountryList                      :   [],
            allDistrictList                     :   [],
            allPincodeList                      :   [],
            editAddressDetails                  :   false,
            addAddressForm                      :   {...this.initAddressForm},
            customerAddressFormDataLoaded       :   false,
            addAddressFormSubmitting            :   false,
            customerSearchForm                  :   {...this.initCustomerSearchForm},
            searchFormDataLoaded                :   false,
            allSearchStatesList                 :   [],
            search_form_loading                 :   false,
            submittedCustomerSearchForm         :   {...this.initCustomerSearchForm},
            formSearchedElems                   :   [],
            reportDownloading                   :   false,
            addNewDistrict                      :   false,
            addNewPinCode                       :   false,
            loading_address                     :   true,
            allStatusList                       :   [],
            changeStatusForm                    :   {...this.initChangeStatusInit},
            saveFormSubmitting                  :   false,
            projectListingMeta                  :   null,
            project_listing                     :   [],
            project_listing_loading             :   false,
            project_listing_tbl_page            :   1
        };

        this.customerURL        =   INVENTORY_API_BASE_URL_2 + '/customer'; 
        this.customerProjectURL                =   INVENTORY_API_BASE_URL_2 + '/customer_project/list';  
        
        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        
    }

    componentDidMount() {
        this.loadListingTblData(1);
        this.loadSearchFormData();
        this.searchCustomerModal                  =   new Modal(document.getElementById('searchCustomerModal'), {keyboard: false, backdrop :false});
        this.addContactModal                  =   new Modal(document.getElementById('addContactModal'), {keyboard: false, backdrop :false});
        this.addAddressModal                  =   new Modal(document.getElementById('addAddressModal'), {keyboard: false, backdrop :false});
        this.customerBulkUploadModal            =   new Modal(document.getElementById('customerBulkUploadModal'), {keyboard: false, backdrop :false});
        this.changeStatusModal             =   new Modal(document.getElementById('changeStatusModal'), {keyboard: false, backdrop :false});
        //****************After Add and update ***************************/
            if(this.props.location && this.props.location.state && this.props.location.state.customerId){
                this.viewCustomerDetail(this.props.location.state.customerId)
            }
            if(this.props.location && this.props.location.state && this.props.location.state.customerData){
                this.setState({minimizeTable : true})
                this.viewCustomerDetail(this.props.location.state.customerData.id)
            }
    }

    //***********************GET CUSTOMER LISTING************************
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedCustomerSearchForm};
        HttpAPICall.withAthorization('GET',  this.customerURL, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                customer_listing    : respData.data,
                listingMeta         : respData.meta,
                totalListingCount   : respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    loadSearchFormData      =       ()      =>      {
        if(!this.state.customerFormDataLoaded){
            this.setState({search_form_loading : true})
            HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/customer_form_data', this.props.access_token, null, null, (response) => {
                let respData = response.data;

                this.setState({
                    customerFormDataLoaded          :       true,
                    allSearchStatesList             :       respData && respData.states && respData.states.length > 0 ? respData.states.map((s) => { return {value: s.id, label: s.name}; }) : [], 
                    allStatusList                   :        respData && respData.status && respData.status.length > 0 ? respData.status.map((s) => { return {value: s.key, label: s.status}; }) : [], 
                });
            }).then(() => this.setState({search_form_loading : false}));
        }
    }

    //***********************GET Customer FORM DATA****************
    customerAddFormDataInit                 =   ()  =>  {
        
         if(!this.state.customerAddressFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.customerURL + '/add_form_data', this.props.access_token, null, null, (response) => {
               let respData =  response.data;
                this.setState({
                    customerAddressFormDataLoaded  :    true,
                    allCountryList                 :      respData && respData.country ?  respData.country.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    customerAddFormData            :    response.data,
                },() => {
                    if(this.state.addAddressForm && this.state.addAddressForm.country_id && !this.state.editAddressDetails){
                        this.countryChangeHandler(this.state.addAddressForm.country_id)
                    }
                });
            });
        }
       
    }

      //FUNCTION TO GET STATE LIST ON SELECTING COUNTRY
      countryChangeHandler              =        (selected_country,edit= false)    =>  {
        if (selected_country) {
            let selectedCountry;
            if(selected_country.value){
                selectedCountry = selected_country.value;
            }else{
                selectedCountry = selected_country
            }
            HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token, {country_id : selectedCountry}, null, (response) => {
                this.setState({
                    allStateList                :   response.data && response.data.length > 0 ?  response.data.map((s) => { return {value: s.id, label: s.name}; })  : [],
                    addAddressForm               :   edit == false ?  {...this.state.addAddressForm,state_id: '',district_name : '',pincode:''} : {...this.state.addAddressForm},
                    addNewDistrict              :   edit == false ? false : this.state.addNewDistrict ,
                    addNewPinCode               :    edit == false ? false : this.state.addNewPinCode ,
                });
            });
        }
        
    }

    //***************GET PARTICULAR CUSTOMER DETAILS**************
    getCustomerDetails            =       (id)      =>      {
        this.customerAddFormDataInit()
        HttpAPICall.withAthorization('GET', this.customerURL + '/' + id, this.props.access_token, null, null, (response) => {
            this.setState({
                customerDetails             :    response.data.data,
                customerUpdateScreenView    :   true
            });
        });
    }
    
   //****************OPEN  ADD Customer SCREEN*******************
    openAddCustomerScreen       =      ()      =>     {
        this.props.history.push('/customer/add')
    }

    //*********************OPEN  UPDATE Customer SCREEN********************
    openUpdateCustomerScreen       =      (id)      =>     {
        if(this.state.customerViewDetails && this.state.customerViewDetails.id == id) {
            this.props.history.push({pathname: `/customer/update`, state: {customerData : this.state.customerViewDetails }})
        } else {
            HttpAPICall.withAthorization('GET', this.customerURL + '/' + id , this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.props.history.push({pathname: `/customer/update`, state: {customerData : respData.data }})   
            });
        }
    }

     //*********************CLOSE UPDATE CUSTOMER SCREEN*************************
     updateCustomerScreenClose      =      ()       =>     {
        this.setState({customerUpdateScreenView:false})
    }

    //*******************AFTER ADD Customer SUBMIT FUNCTION********************
    afterAddNewCustomerSubmit               =   (event) =>  {
        event.preventDefault();
        //Item is Added Successfully Need to refresh and close Add form
        this.setState({customerAddScreenView : false, customerUpdateScreenView : false,customerDetails : []});
        //Load Customer Listing :-
        this.loadListingTblData(1);
    }

    //******************VIEW CUSTOMER DETAILS***********************************
    viewCustomerDetail          =      (id)        =>     {
        this.setState({ minimizeTable           :   true})
        if(this.props.permissions.includes('customer-project-view')){
            this.loadProjectListingTblData(1,id)
        }
        this.setState({details_loading : true})
        HttpAPICall.withAthorization('GET', `${this.customerURL}/${id}`, this.props.access_token, null, null, (response) => {
            this.setState({
                customerViewDetails       :    response.data.data,  
            },() => {
                
            });
        }).then(() => this.setState({details_loading: false}))
    }

    //**************************CLOSE VIEW CUSTOMER DETAILS*********************
    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    //*****************************ADD CONTACT MODAL INIT*******************//
    addContactInit          =       ()      =>      {
        this.addContactModal.show()
    }

    //******************************EDIT CONTACT FORM************************
    editContact             =      (id)        =>     {
        if(id){
            let contactDetails = this.state.customerViewDetails.contacts.find(i => i.id == id)
            this.setState({addContactForm : {...contactDetails},editContactDetails : true})
            this.addContactModal.show()
        }
    }

    //******************************SET PRIMARY CONTACT FORM***********************
    setPrimaryContact           =       (id)    =>      {
        HttpAPICall.withAthorization('PUT',  this.customerURL + '/contact_primary/' + this.state?.customerViewDetails?.id + '/' + id, this.props.access_token, {}, {}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.viewCustomerDetail(this.state.customerViewDetails.id)
        }) 
    }

    //**************************DELETE PRIMARY CONTACT FORM**********************
    deletePrimaryContact           =       (id)    =>      {
        HttpAPICall.withAthorization('DELETE',  this.customerURL + '/contact_remove/' + this.state?.customerViewDetails?.id + '/' + id, this.props.access_token, {}, {}, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.viewCustomerDetail(this.state.customerViewDetails.id)
        }) 
    }

    //***********************ADD CONTACT FORM SUBMIT****************************
    addContactFormSubmit        =       (e)      =>      {
       e.preventDefault()
   
       if(this.state.addContactForm.name == '' && this.state.addContactForm.email == '' && this.state.addContactForm.mobile == ''){
        toast.error('Please fill the form', { position: toast.POSITION.TOP_RIGHT });
       }else{
        this.setState({addContactFormSubmitting : true});
        if(this.state.editContactDetails){
            HttpAPICall.withAthorization('PUT',  `${this.customerURL}/contact_edit/${this.state?.customerViewDetails?.id}/${this.state.addContactForm.id}`, this.props.access_token, {}, {...this.state.addContactForm}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addContactModal.hide()
                this.viewCustomerDetail(this.state.customerViewDetails.id)
                this.setState({addContactForm : {...this.initContactForm}})
            }).then(() => this.setState({addContactFormSubmitting: false, editContactDetails : false}));
        }else{
            let frmData = {
                ...this.state.addContactForm,
                customer_id : this.state.customerViewDetails ? this.state.customerViewDetails.id : null
            };
            HttpAPICall.withAthorization('POST',  `${this.customerURL}/contact_add`, this.props.access_token, {}, {...frmData}, (resp) => {
                toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addContactModal.hide()
                this.viewCustomerDetail(this.state.customerViewDetails.id)
                this.setState({addContactForm : {...this.initContactForm}})
            }).then(() => this.setState({addContactFormSubmitting: false}));
        }
       }
       
    }

    //**************************ADD Customer ADDRESS SECTION*************************
    addAddressInit             =      ()        =>      {
        this.addAddressModal.show()
        this.setState({
            editAddressDetails          :   false,
            addNewDistrict              :   false,
            addNewPinCode               :   false,
            loading_address             :   true,
            addAddressForm              :   {...this.initAddressForm}
        })
        this.customerAddFormDataInit()
        setTimeout(() => this.setState({loading_address : false}),2000)
   
    }

    getAddFormData          =       ()      =>      {

    }

    editCustomerAddress           =       (id)      =>      {
      this.setState({loading_address :  true})
        if(id){
            let addressDetails = this.state.customerViewDetails.addressList.find(i => i.id == id);
            this.customerAddFormDataInit()
             this.countryChangeHandler(addressDetails.country_id,true)
            this.stateChangeHandler(addressDetails.state_id,true)
            if(addressDetails.district_id){
                this.districtChangeHandler(addressDetails.district_name,addressDetails.state_id)
            }
            this.setState({addAddressForm : {...addressDetails},editAddressDetails : true})
            this.addAddressModal.show()
            setTimeout(() => this.setState({loading_address : false}),2000)
        }
       
    }

    //********************FUNCTION TO GET DISTRICT LIST ON SELECTING STATE************
    stateChangeHandler              =        (selected_state,edit=false)    =>  {
        if (selected_state) {
            let params = {state_ids : [selected_state.value ? selected_state.value : selected_state]}
            HttpAPICall.withAthorization('GET', this.districtListUrl, this.props.access_token, {...params}, null, (response) => {
                this.setState({
                    allDistrictList  :  response.data.map((s) => { return {value: s.name, label: s.name}; }),
                    addNewDistrict              :   response.data && response.data.length > 0 ? false : true,
                    addNewPinCode               :   response.data && response.data.length > 0 ?  false : true,
                    addAddressForm              :   edit == false ?  {...this.state.addAddressForm,pincode:'',pincode_name:''} : {...this.state.addAddressForm}
                });
            });
        }
    }

    //*******************FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT*********************
    districtChangeHandler              =        (selected_district,state_id)    =>  {
        if (selected_district) {
            let selectedDistrict;
            if(selected_district.value){
                selectedDistrict = selected_district.value;
            }else{
                selectedDistrict = selected_district
            }
            let params = {district_name : selectedDistrict,state_id:state_id ? state_id : this.state.addAddressForm.state_id}
            HttpAPICall.withAthorization('GET', this.pincodesUrl, this.props.access_token, params, null, (response) => {
                this.setState({
                    allPincodeList  :  response.data.map((s) => { return {value: s.pincode, label: s.pincode}; })
                });
            });
        }
    }

    //*************************DELETE CUSTOMER ADDRESS************************ */
    deleteCustomerAddress              =           (id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.customerURL + '/address/' + this.state?.customerViewDetails?.id + '/' + id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.addAddressModal.hide();
                        this.viewCustomerDetail(this.state.customerViewDetails.id)

                    }).then(() => {

                    });
                }
            });
    }


    //*********************************SUBMIT ADDRESS FORM****************** */
     addAddressFormSubmit            =       (e)      =>      {   
        e.preventDefault()
         let frmData = { ...this.state.addAddressForm, customer_id: this.state?.customerViewDetails?.id }
         this.setState({ addAddressFormSubmitting: true })
         if (this.state.editAddressDetails) {
             HttpAPICall.withAthorization('PUT', `${this.customerURL}/address`, this.props.access_token, {}, { ...frmData }, (response) => {
                 toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                 this.addAddressModal.hide();
                 this.viewCustomerDetail(this.state.customerViewDetails.id)
                 this.setState({ addAddressForm: { ...this.initAddressForm } })
             }).then(() => this.setState({ addAddressFormSubmitting: false }));
         } else {
             HttpAPICall.withAthorization('POST', `${this.customerURL}/address`, this.props.access_token, {}, { ...frmData }, (response) => {
                 toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                 this.addAddressModal.hide();
                 this.viewCustomerDetail(this.state.customerViewDetails.id)
                 this.setState({ addAddressForm: { ...this.initAddressForm } })
             }).then(() => this.setState({ addAddressFormSubmitting: false }));
         }
     }
     
    /**
     * Bulk Upload :-
     */
    customerBulkUploadModalInit               =   ()  =>  {
        document.getElementById('addCustomerFileSelector').value = null;
        //Initialize Search Form :-
        this.customerBulkUploadModal.show();
    }
    
    downloadSampleFile                  =   ()      => {
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/customer_download_sample_add_excel', this.props.access_token,
        {},{},(response) => 
        {   DownloadFile.file(response.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
            this.customerBulkUploadModal.hide();
        })
    }
    
    customerBulkUploadFormSubmit            =   (event) => {
        event.preventDefault();
        this.setState({customerBulkUploadFormSubmiting : true});
        const data          =   new FormData(document.getElementById('customerBulkUploadForm'));
        let headers         =   { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data','Authorization' : 'Bearer ' + this.props.access_token };
        axios.post(INVENTORY_API_BASE_URL_2 + '/customer_add_by_excel', data, { headers: headers })
        .then((response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.customerBulkUploadModal.hide();
            //Load Add Item Form Data :-
            DownloadFile.file(response.data.generated_file.file_path);
            setTimeout(() => {
                this.loadListingTblData(1);
            },1000)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to upload customer Add File.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({customerBulkUploadFormSubmiting : false});
        });
    }

    customerSearchFormInit        =       ()  => {
       
       
        this.searchCustomerModal.show()
    }

    customerSearchFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
       
        let serachFormKeys                  =   Object.keys(this.state.customerSearchForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};

        serachFormKeys.map((key)        =>  {
            let label                       =   this.customerSearchFormLabel[key];
            let value                       =   this.state.customerSearchForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_name') {
                        show_val            =   this.state.customerSearchForm.search_name;
                    }
                    if(key == 'search_code') {
                        show_val            =   this.state.customerSearchForm.search_code;
                    }
                    if(key == 'search_state_ids') {
                        show_val            =   this.state.allSearchStatesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.allStatusList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }

                    if(key == 'search_gstin') {
                        show_val            =   this.state.customerSearchForm.search_gstin;
                    }
                    if(key == 'search_mobile') {
                        show_val            =   this.state.customerSearchForm.search_mobile;
                    }
                    if(key == 'search_email') {
                        show_val            =   this.state.customerSearchForm.search_email;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedCustomerSearchForm         :   {...this.state.customerSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
           this.searchCustomerModal.hide();
        });
    }

    customerSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems               :   [],
            customerSearchForm              :   {...this.initCustomerSearchForm},
            submittedCustomerSearchForm     :   {...this.initCustomerSearchForm},
        }, () => {
            this.loadListingTblData(1);
        });
    }

    downloadCustomerReport              =    ()     =>         {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET',  this.customerURL , this.props.access_token,{download : "download"},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({reportDownloading: false}));
    }

    changeStatusInit        =       ()      =>      {
        this.changeStatusModal.show();
        let detail = this.state.customerViewDetails ? this.state.customerViewDetails : null;
        this.setState({
            changeStatusForm    :       {
                status          :       detail && detail.status ? detail.status : '',
                id              :       detail && detail.id ? detail.id : ""
            }
        })
    }

    loadProjectListingTblData                 =   (page = 1,id='')  =>         {
        this.setState({project_listing_loading: true, project_listing_tbl_page: page});
        let params                      =   {page:page,search_customer_ids : [id]};
        HttpAPICall.withAthorization('GET',  this.customerProjectURL, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                project_listing    : respData.data,
                projectListingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalProjectListingCount   : respData.total,
        });
        }).then(() => this.setState({project_listing_loading: false}));
    }

    addProjectModalInit         =       ()      =>      {
        this.props.history.push({pathname: `/customer_project_add`, state: {addProjectFromCustomer : this.state.customerViewDetails }})   
    }

    customerListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Customer</h3>
                <div className="text-end mt15">
                    {this.state.minimizeTable
                        ? <Ax>
                            <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                  
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    { //this.props.permissions.includes('customer-add') && 
                                    <Ax>
                                        <li><Link className="dropdown-item" onClick={this.openAddCustomerScreen}>Add Customer</Link></li>
                                        {
                                            // <li><Link className="dropdown-item" onClick={this.customerBulkUploadModalInit}>Bulk Upload</Link></li>
                                        }
                                    </Ax>
                                    }
                                </ul>
                            </span>

                        </Ax>
                        : <Ax>
                            <button type="button" className="btn btn-primary" onClick={this.openAddCustomerScreen}>Add </button>{
                                //     <Ax><span className="dropdown">
                                //     <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.excelDownloading == true || this.state.reportDownloading == true}>
                                //         <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                //         {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                                //     </button>
                                //     <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                //     {this.props.permissions.includes('customer-add') && <li><Link className="dropdown-item" onClick={this.customerBulkUploadModalInit} role="button" >Bulk Upload</Link></li>}
                                //         <li><Link className="dropdown-item"  disabled={this.state.reportDownloading} onClick={this.downloadCustomerReport}>Download</Link></li>
                                //     </ul>
                                // </span>
                                    <button type="button" onClick={this.customerSearchFormInit} className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    // </Ax> 
                                }
                        </Ax>
                    }
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Customer : {this.state.customerViewDetails && this.state.customerViewDetails.name ? this.state.customerViewDetails.name : "-"} </h3>
                            <div className="text-end mt15">
                            
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{cursor : "pointer"}}>
                                {
                                    // this.props.permissions.includes('customer-manage-contact') && 
                                    <li><a className="dropdown-item" onClick={this.addContactInit}>Add Contact</a></li>
                                }
                                {
                                    // this.props.permissions.includes('customer-edit') &&    
                                    <li><a className="dropdown-item" onClick={() => this.openUpdateCustomerScreen(this.state.customerViewDetails?.id)}>Edit Customer</a>
                                    </li>
                                }
                                {
                                    // this.props.permissions.includes('customer-manage-address') &&  
                                    <li><a className="dropdown-item" onClick={this.addAddressInit}>Add Address</a></li>
                                }
                                {
                                    // this.props.permissions.includes('customer-change-status') &&    
                                    <li><a className="dropdown-item" onClick={() => this.changeStatusInit()}>Change Status</a></li>
                                }
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.customerListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4">{this.customerViewDetails()}</div> : null }
                </div>
            </div>
        </Ax>);
    }

    customerListingTableJsx            =   ()   =>   {
        return(<Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.customerSearchClear}   /> 
            <table className="table table-bordered table-fixed  bg-white  table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Customer</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "16%" }}>Customer Name</th>
                            <th scope="col" style={{ width: "10%" }}> Code</th>
                            <th scope="col" style={{ width: "22%" }}>Location (Source of Supply)</th>
                            <th scope="col" style={{ width: "18%" }}>Email ID</th>
                            <th scope="col" style={{ width: "12%" }} className='text-center'>Phone Number</th>
                            <th scope="col" style={{ width: "12%" }} className='text-center'>Status</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.customer_listing.length > 0
                            ? (this.state.customer_listing.map((customer, index) => {
                                return (<tr key={customer.id} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewCustomerDetail(customer.id)}>
                                                        <div className="text-capitalize link-primary cursor_pointer">{customer?.name}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">Code : {customer?.code??"-"} </small>
                                                            <small className="float-end"><Status color={customer.status_color}>{customer.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-capitalize link-primary cursor_pointer'  onClick={() => this.viewCustomerDetail(customer.id)}>{customer.name}</td>
                                                <td className='text-capitalize'>{customer.code ? customer.code : "-"}</td>
                                                <td>{customer.addressList.length > 0 ? customer.addressList.map( (value) => value.state_name).filter( (value, index, _arr) => _arr.indexOf(value) == index).join(', ') 
                                                       : "-"}</td>
                                                <td>{customer.primary_contact !== null ? customer.primary_contact.email??"-" : "-"}</td>
                                                <td className='text-center'>{customer.primary_contact !== null ? customer.primary_contact.mobile??"-" : "-"}</td>
                                                <td className="text-center"><Status color={customer.status_color}>{customer.status_text}</Status></td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewCustomerDetail(customer.id)} >View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
        </Ax>)
    }

    customerViewDetails                =   ()   =>   {
        let customerDetails = this.state.customerViewDetails;
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="address-tab" data-bs-toggle="tab" data-bs-target="#address"
                        type="button" role="tab" aria-controls="address" aria-selected="false">Address
                    </button>
                </li>
               {this.props.permissions.includes('customer-project-view') &&
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="project-tab" data-bs-toggle="tab" data-bs-target="#project"
                        type="button" role="tab" aria-controls="project" aria-selected="false">Project
                    </button>
                </li>
                } 
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.details_loading ? <Loader />
                        : (<div className="tab_content_header">
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Details</span>
                            </div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Customer Name</td>
                                        <th style={{ "width": "66%" }}>{customerDetails.name ? customerDetails.name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Code</td>
                                        <th style={{ "width": "66%" }}>{customerDetails.code ? customerDetails.code : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Status</td>
                                        <th style={{ "width": "66%" }}>{customerDetails.status ? <Status color={customerDetails.status_color}>{customerDetails.status_text}</Status> : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "34%" }}>Notes</td>
                                        <th style={{ "width": "66%" }}>{customerDetails.notes ? customerDetails.notes : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper align-items-center">
                                <span className="content_heading">Contact Details </span>
                            </div>
                            <table className="table table-hover table-bordered my-3 table-sm">
                                <thead className="table-secondary" >
                                    <tr>
                                        <th style={{ width: "5%" }} className='text-center'>S.No</th>
                                        <th style={{ width: "30%" }}>Name</th>
                                        <th style={{ width: "35%" }}>Email ID</th>
                                        <th style={{ width: "25%" }} className='text-center'>Mobile Number</th>
                                        {
                                            //<th className='text-center' style={{ width: "15%" }}>Primary Contact</th>
                                        }
                                        <th style={{ width: "5%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerDetails?.contacts?.length > 0 ? customerDetails?.contacts?.map((c, i) => {
                                        return (<tr>
                                            <td className='text-center'>{i + 1}</td>
                                            <td>{c.name}</td>
                                            <td> {c.email ?? "-"}</td>
                                            <td className='text-center'> {c.mobile ?? "-"}</td>
                                            <td className="text-center">
                                                {
                                                    <Ax>
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.editContact(c.id)}>Edit</a></li>
                                                        <li><a className={`dropdown-item ${customerDetails.contacts.length === 1 ? "disabled" : ""}`} role="button" onClick={() => this.deletePrimaryContact(c.id)}>Delete</a></li>
                                                    </ul>
                                                    </Ax>
                                                }  
                                            </td>
                                        </tr>)
                                    })
                                        : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className="tab-pane" id="address" role="tabpanel" aria-labelledby="address-tab">
                    {this.state.details_loading ? <Loader />
                        : (<div className="tab_content_header">
                            {customerDetails && customerDetails.addressList && customerDetails.addressList.length > 0
                                ? customerDetails.addressList.map((a, i) => {
                                    return (
                                        <div className="card my-2">
                                            <div className="card-header">
                                                <div className="row align-items-center">
                                                    <div className='col-sm-5'>{a.label ? a.label : "-"}</div>
                                                    <div className=' col-sm-7 text-end'>
                                                        <button className='btn btn-primary' role="button" onClick={() => this.editCustomerAddress(a.id)} > <tapIcon.FontAwesomeIcon icon={tapIcon.faEdit} /></button>
                                                        <button className='btn btn-primary mx-2' role="button"  onClick={() => this.deleteCustomerAddress(a.id)} disabled={customerDetails.addressList.length <= 1 ? true : false}> <tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt}/></button> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className='py-1'>Address : <b>{a.address1 ? a.address1 + ", " : ""}
                                                    {a.address2 ? a.address2 + ", " : ""}
                                                    {a.landmark ? a.landmark + ", " : ""}
                                                    {a.city ? a.city + ". " : "-"}</b></div>
                                                <div className='py-1'>District, State & Pincode : <b>{a.district_name ? a.district_name + ", " : "- , "}
                                                    {a.state_name ? a.state_name : " - "}
                                                    {a.pincode ? " & " + a.pincode : ""}</b></div>
                                                <div className='py-1'>GSTIN : <b>{a.gstin ? a.gstin : "- "}</b></div>
                                            </div>
                                        </div>
                                    )
                                }) : null}
                        </div>)}
                </div>
                <div className="tab-pane" id="project" role="tabpanel" aria-labelledby="project-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper my-2">
                            <span className="content_heading">Customer Project</span>
                            <div className="float-end"><button className="btn btn-primary"  onClick={() => this.addProjectModalInit()}>Add </button></div>
                        </div>
                        <table className="table table-bordered table-fixed  bg-white  table-sm">
                            <thead className="table-secondary" >
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                                    <th scope="col" style={{ width: "14%" }}>Project Type</th>
                                    <th scope="col" style={{ width: "17%" }}>Project Name</th>
                                    <th scope="col" style={{ width: "17%" }}>Project Code</th>
                                    <th scope="col" style={{ width: "17%" }}>Customer</th>
                                    <th scope="col" style={{ width: "17%" }}>Location</th>
                                    <th scope="col" style={{ width: "8%" }} className='text-center'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.project_listing_loading
                                    ? (<tr><td colSpan="7"><Loader /></td></tr>)
                                    : (this.state.project_listing && this.state.project_listing.length > 0
                                        ? (this.state.project_listing.map((customer, index) => {
                                            return (<tr key={customer.id} >

                                                <td className="text-center">{this.state.projectListingMeta ? this.state.projectListingMeta.from + index : index}</td>
                                                <td >{customer.project_type ? customer.project_type.name : ''}</td>
                                                <td>{customer.project_name}</td>
                                                <td>{customer.project_code ? customer.project_code : ''}</td>
                                                <td>{customer.customer ? customer.customer.name : ''}</td>
                                                <td>{customer.address && customer.address.state_name ? customer.address.state_name : ''}</td>
                                                <td>{customer.status ? customer.status.name : ''}</td>

                                            </tr>)
                                        }))
                                        : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                                    )
                                }
                            </tbody>
                        </table>
                        <DisplayListPagination
                            meta={this.state.projectListingMeta}
                            onPageChange={(e) => this.loadProjectListingTblData(e.selected + 1,this.state.customerViewDetails?.id)}
                        />
                    </div>
                </div>
            </div>
        </section>)
    }

    //*********************************ADD CONTACT JSX*************************
    addContactModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="addContactModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">{this.state.editContactDetails ? " Edit" : "Add"} Contact</h5>
                        </div>
                        <form onSubmit={this.addContactFormSubmit} id="addContactForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Person Name</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addContactForm.name}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            maxLength="200"
                                           
                                            placeholder="Please Enter Person Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Email ID</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            autoComplete="off"
                                            maxLength="200"
                                           
                                            value={this.state.addContactForm.email}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            placeholder="Please Enter Person Email ID"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label"> Mobile Number </label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="mobile"
                                            type="number"
                                            className="form-control"
                                            autoComplete="off"
                                          
                                            value={this.state.addContactForm.mobile}
                                             onChange={(e) => this.formInputHandler(e, 'addContactForm')}
                                            placeholder="Please Enter Person Mobile Number"
                                            pattern="[0-9.]+"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addContactFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={()=>{this.setState({addContactForm : {...this.initContactForm}, editContactDetails : false})}}>Close</button>
                                <button type="submit" disabled={this.state.addContactFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addContactFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );
    }

   
     //*********************************ADD CONTACT JSX*************************
     addAddressModalJsx              =   ()  =>  {
       
        return (
            <div className="modal fade" id="addAddressModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">{this.state.editAddressDetails ? " Edit" : "Add"} Address</h5>
                        </div>
                        {this.state.customerAddressFormDataLoaded ?
                            <div className="modal-body "><form onSubmit={this.addAddressFormSubmit} id="addAddressForm" method="post" encType="multipart/form-data">
                                {this.state.loading_address ? <Loader/>
                                : 
                             <div>
                                   <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label require">Label</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="label"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.label}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            required={true}
                                            placeholder="Please Enter Address Label"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Address Line 1</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="address1"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.address1}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter Address Line 1"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Address Line 2</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="address2"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.address2}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter Address Line 2"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Landmark</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="landmark"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.landmark}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter Landmark"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">City</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="city"
                                            type="text"
                                            className="form-control"
                                            value={this.state.addAddressForm.city}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            autoComplete="off"
                                            maxLength="250"
                                            placeholder="Please Enter City"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">Country</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addAddressForm', 'country_id');
                                                this.countryChangeHandler(selectedOption)
                                            }
                                            }
                                            options={this.state.allCountryList}
                                            isSearchable={false}
                                            isClearable={false}
                                            value={this.state.allCountryList.find(m => m.value == this.state.addAddressForm.country_id)}
                                            placeholder="Please Select Country"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label require">State (Source of Supply)</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'state_id'); this.stateChangeHandler(selectedOption) }}
                                            options={this.state.allStateList}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            value={this.state.allStateList.find(m => m.value == this.state.addAddressForm.state_id)}
                                            placeholder="Please Select State"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">District</label>
                                    </div>
                                    <div className="col-sm-7">
                                        {this.state.addNewDistrict
                                            ? <input
                                                name="district_name"
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addAddressForm.district_name}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                maxLength="20"
                                                placeholder="Please Enter District"
                                            /> : <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'district_name'); this.districtChangeHandler(selectedOption) }}
                                                options={this.state.allDistrictList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allDistrictList.find(m => m.value == this.state.addAddressForm.district_name)}
                                                placeholder="Please Select District"
                                            />}
                                    </div>
                                    <div className="col-sm-2">
                                        <input
                                            name="addNewDistrict"
                                            type="checkbox"
                                            value={this.state.addNewDistrict}
                                            onChange={(e) => {
                                                this.setState({
                                                    addNewDistrict: !this.state.addNewDistrict,
                                                    addAddressForm: { ...this.state.addAddressForm, district_id: '', pincode: '' }
                                                })
                                            }}
                                            checked={this.state.addNewDistrict}
                                            className="form-check-input"
                                            id="newDistrict"

                                        />
                                        <label className="form-check-label mx-2 " htmlFor="newDistrict">Add New </label>
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">PIN Code</label>
                                    </div>
                                    <div className="col-sm-7">
                                        {this.state.addNewPinCode
                                            ? <input
                                                name="pincode"
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                value={this.state.addAddressForm.pincode}
                                                onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                                maxLength="20"
                                                placeholder="Please Enter PinCode"
                                            />
                                            : <TapSelect
                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addAddressForm', 'pincode') }}
                                                options={this.state.allPincodeList}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allPincodeList.find(m => m.value == this.state.addAddressForm.pincode)}
                                                placeholder="Please Select PIN Code"
                                            />}
                                    </div>
                                    <div className="col-sm-2">
                                        <input
                                            name="addNewPinCode"
                                            type="checkbox"
                                            value={this.state.addNewPinCode}
                                            onChange={(e) => {
                                                this.setState({
                                                    addNewPinCode: !this.state.addNewPinCode,
                                                    addAddressForm: { ...this.state.addAddressForm, pincode: '' }
                                                })
                                            }}
                                            checked={this.state.addNewPinCode}
                                            className="form-check-input"
                                            id="newPinCode"

                                        />
                                        <label className="form-check-label mx-2" htmlFor="newPinCode">Add New </label>
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="site_id" className="form-label">GSTIN</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="gstin"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addAddressForm.gstin}
                                            onChange={(e) => this.formInputHandler(e, 'addAddressForm')}
                                            maxLength="20"
                                            placeholder="Please Enter GSTIN Number"
                                        />
                                    </div>
                                </div>
                             </div>}

                            </form>
                            </div>

                            : <Loader />}
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.addAddressFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addAddressForm: { ...this.initAddressForm } }) }}>Close</button>
                            <button type="submit" disabled={this.state.addAddressFormSubmitting} className="btn btn-primary" form="addAddressForm">
                                Submit {this.state.addAddressFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
    
    customerBulkUploadModalJsx            =   ()  =>  {
        return (
            <div className="modal fade" id="customerBulkUploadModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Add Customer</h5>
                            <button type="button" disabled={this.state.customerBulkUploadFormSubmiting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.customerBulkUploadFormSubmit} id="customerBulkUploadForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            id="addCustomerFileSelector"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <div><a type="button" className='float-left' href='#' onClick={this.downloadSampleFile}>Download Sample File</a></div>
                                <div>
                                    <button type="button" disabled={this.state.customerBulkUploadFormSubmiting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.customerBulkUploadFormSubmiting} className="btn btn-primary">
                                        Submit {this.state.customerBulkUploadFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    searchCustomerModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="searchCustomerModal" tabIndex="-1">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">Search Customer</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.customerSearchFormSubmit} id="searchCustomerForm">
                            {this.state.search_form_loading ? <Loader />
                                : <div className="modal-body">
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Customer Name</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_name"
                                                type="text"
                                                value={this.state.customerSearchForm.search_name}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter Customer Name" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Customer Code</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_code"
                                                type="text"
                                                value={this.state.customerSearchForm.search_code}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter Customer Name" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Location (Source of Supply)</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'customerSearchForm', 'search_state_ids')}
                                                options={this.state.allSearchStatesList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select States"
                                                value={this.state.allSearchStatesList.filter(t => this.state.customerSearchForm.search_state_ids.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">GSTIN</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_gstin"
                                                type="text"
                                                value={this.state.customerSearchForm.search_gstin}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter GSTIN" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Phone Number</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_mobile"
                                                type="number"
                                                value={this.state.customerSearchForm.search_mobile}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter Phone Number" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Email</label>
                                        <div className="col-sm-10">
                                            <input
                                                name="search_email"
                                                type="email"
                                                value={this.state.customerSearchForm.search_email}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "customerSearchForm")}
                                                placeholder="Please enter Email" />
                                        </div>

                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm">Status</label>
                                        <div className="col-sm-10">
                                        <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'customerSearchForm', 'search_status')}
                                                options={this.state.allStatusList}
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Please Select Customer Status"
                                                value={this.state.allStatusList.filter(t => this.state.customerSearchForm.search_status.includes(t.value))}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">
                                    Search
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            );
    }

    submitChangeStatusForm          =       (e)      =>      {
        e.preventDefault();
        this.setState({saveFormSubmitting : true})
        
           HttpAPICall.withAthorization('PUT', `${this.customerURL}/change_status`, this.props.access_token, {}, { ...this.state.changeStatusForm }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.viewCustomerDetail(this.state?.customerViewDetails?.id);
               this.loadListingTblData(1);
               this.changeStatusModal.hide();
               this.setState({changeStatusForm : {...this.initChangeStatusInit}})
           }).then(() => this.setState({ saveFormSubmitting : false }));
    }


    changeStatusModalJsx            =       ()      =>      {
        return( <div className="modal fade" id="changeStatusModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="changeStatusModalLabel">Change Status</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className='p-1 bg-light border-bottom'>
                        <table className='table table-sm mb-0 table-borderless text-sm'>
                            <tbody>
                                <tr>
                                    <td style={{ width: "26%" }}>Customer Name</td>
                                    <th>{this.state.customerViewDetails && this.state.customerViewDetails.name ?this.state.customerViewDetails.name  : ''}</th>
                                </tr>
                                <tr>
                                    <td>Customer Code </td><th>{this.state.customerViewDetails && this.state.customerViewDetails.code ?this.state.customerViewDetails.code  : ''}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                   
                    <form className="bg-white" onSubmit={this.submitChangeStatusForm} id="changeStatusForm">

                    <div className="my-3 row">
                        <label className="col-sm-3 col-form-label">Change status to</label>
                        <div className="col-sm-5">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'changeStatusForm', 'status');
                                }}
                                value={this.state.allStatusList.find(s => s.value == this.state.changeStatusForm.status)}
                                options={this.state.allStatusList}
                                isSearchable={false}
                                placeholder="Please Select Status"
                            />
                        </div>
                    </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            >Close  </button>
                           <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Update {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Customer Listing</title></Helmet>
            {this.customerListjsx()}
            {this.addContactModalJsx()}
            {this.addAddressModalJsx()}
            {this.customerBulkUploadModalJsx()}
            {this.searchCustomerModalJsx()}
            {this.changeStatusModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions          :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [] ,
    };
};

export default connect(mapStateToProps)(CustomerList);