import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import { ORG_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import Status from "../../../../components/ui/Status";
import React from "react";
import {number} from "prop-types";
import swal from "sweetalert";
import axios from "axios";
import { Link } from 'react-router-dom';


class GeneralSettings extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigForm      =   {
            po_enable_tds                       :       'N',
            // po_enable_tcs                       :       'N',
            po_allow_po_for                     :       'Y',
            po_view_pending_qty                 :       'Y',
            po_enable_authorized_signatory      :       'Y',
            po_enable_item_duedate              :       'N',
            po_display_manufacturer_po_pdf      :       'N',
            po_allow_backdate                   :       'N',
            po_allow_edit_transaction_date      :       'Y'
        };

        this.state                              =   {
            generalConfigloading                :      false,
            general_config_list                 :      null,
            generalConfigForm                   :     {...this.initGeneralConfigForm},
            editFormSubmiting                   :      false,
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';

    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
   }

    getGeneralSettingListing = () => {
        let params = {
            keys : ['po_allow_edit_transaction_date','po_enable_tds' ,'po_allow_po_for','po_view_pending_qty','po_enable_authorized_signatory','po_enable_item_duedate','po_display_manufacturer_po_pdf','po_allow_backdate','po_display_vendor_contact'],
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ general_config_list: resp.data.data })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    editGeneralConfigurationInit = () => {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
              }
              
            this.setState({generalConfigForm})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...this.state.generalConfigForm} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    editGeneralConfigurationModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                            {/* <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Enable TCS</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_tcs"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_tcs == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_tcs"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_tcs == "N"} /> No
                                        </label>
                                    </div>
                                </div> */}
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Enable TDS</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_tds"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_tds == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_tds"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_tds == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                              
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Option to tag Asset/Workstation/Employee with Purchase Order</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_allow_po_for"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_allow_po_for == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_allow_po_for"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_allow_po_for == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Include Item Pending Quantity in PDF</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_view_pending_qty"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_view_pending_qty == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_view_pending_qty"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_view_pending_qty == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Change Title from Purchase Order to Work Order in case all the items are of 'Service' category'</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_title_work_order"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_title_work_order == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_title_work_order"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_title_work_order == "N"} /> No
                                        </label>
                                    </div>
                                </div> */}
                           
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Display Vendor Contact Details on Purchase Order</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_display_vendor_contact"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_display_vendor_contact == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_display_vendor_contact"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_display_vendor_contact == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Enable Authorized Signatory</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_authorized_signatory"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_authorized_signatory == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_authorized_signatory"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_authorized_signatory == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Option to add Delivery Due Date for individual Items</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_item_duedate"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_item_duedate == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_enable_item_duedate"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_enable_item_duedate == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Display manufacturer name in Purchase Order PDF</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_display_manufacturer_po_pdf"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_display_manufacturer_po_pdf == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_display_manufacturer_po_pdf"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_display_manufacturer_po_pdf == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Allow Purchase Order Creation on a Back date</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_allow_backdate"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_allow_backdate == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_allow_backdate"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_allow_backdate == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Allow changing the Transaction Date while editing PO</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_allow_edit_transaction_date"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_allow_edit_transaction_date == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="po_allow_edit_transaction_date"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.po_allow_edit_transaction_date == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                          
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
  }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
        return (<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                </tr>
            </thead>
            <tbody>
                {/* <tr className="text-center">
                    <td>1</td>
                    <td className="text-start">Enable TCS</td>
                    <td>{data && data.po_enable_tcs && data.po_enable_tcs == 'Y' ? "Yes" : "No"}</td>
                </tr> */}
                <tr className="text-center">
                    <td>1</td>
                    <td className="text-start">Enable TDS</td>
                    <td>{data && data.po_enable_tds && data.po_enable_tds == 'Y' ? "Yes" : "No"}</td>
                </tr>
                <tr className="text-center">
                    <td>2</td>
                    <td className="text-start">Option to tag Asset/Workstation/Employee with Purchase Order</td>
                    <td>{data && data.po_allow_po_for && data.po_allow_po_for == 'Y' ? "Yes" : "No"}</td>
                </tr>
                <tr className="text-center">
                    <td>3</td>
                    <td className="text-start">Include Item Pending Quantity in PDF</td>
                    <td>{data && data.po_view_pending_qty && data.po_view_pending_qty == 'Y' ? "Yes" : "No"}</td>
                </tr>
                {/* <tr className="text-center">
                    <td>4</td>
                    <td className="text-start">Change Title from Purchase Order to Work Order in case all the items are of 'Service' category'</td>
                    <td>{data && data.po_title_work_order && data.po_title_work_order == 'Y' ? "Yes" : "No"}</td>
                </tr> */}
                <tr className="text-center">
                    <td>4</td>
                    <td className="text-start">Display Vendor Contact Details on Purchase Order</td>
                    <td>{data && data.po_display_vendor_contact && data.po_display_vendor_contact == 'Y' ? "Yes" : "No"}</td>
                </tr>
                <tr className="text-center">
                    <td>5</td>
                    <td className="text-start">Enable Authorized Signatory</td>
                    <td>{data && data.po_enable_authorized_signatory && data.po_enable_authorized_signatory == 'Y' ? "Yes" : "No"}</td>
                </tr>
                <tr className="text-center">
                    <td>6</td>
                    <td className="text-start">Option to add Delivery Due Date for individual Items</td>
                    <td>{data && data.po_enable_item_duedate && data.po_enable_item_duedate == 'Y' ? "Yes" : "No"}</td>
                </tr>
                <tr className="text-center">
                    <td>7</td>
                    <td className="text-start">Display manufacturer name in Purchase Order PDF</td>
                    <td>{data && data.po_display_manufacturer_po_pdf && data.po_display_manufacturer_po_pdf == 'Y' ? "Yes" : "No"}</td>
                </tr>
    
                <tr className="text-center">
                    <td>8</td>
                    <td className="text-start">Allow Purchase Order Creation on a Back date</td>
                    <td>{data && data.po_allow_backdate && data.po_allow_backdate == 'Y' ? "Yes" : "No"}</td>
                </tr>
                <tr className="text-center">
                    <td>9</td>
                    <td className="text-start">Allow changing the Transaction Date while editing PO</td>
                    <td>{data && data.po_allow_edit_transaction_date && data.po_allow_edit_transaction_date == 'Y' ? "Yes" : "No"}</td>
                </tr>
           
            </tbody>
        </table>)
    }

    render                                  =     ()           =>     {
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                <div className="text-end "><button className="btn btn-primary" disabled={!this.props.permissions.includes('isc-configuration-edit')}  onClick={this.editGeneralConfigurationInit}>Edit </button></div>
            </div>
            {this.state.generalConfigloading ? <Loader />
                : this.generalConfigurationJsx()}
            {this.editGeneralConfigurationModalJsx()}
            
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(GeneralSettings);