import { Modal } from "bootstrap";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AppBaseComponent from "../../../components/AppBaseComponent";
import tapIcon from "../../../services/TapIcon";
import { Helmet } from "react-helmet";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import moment from "moment";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";
import Status from "../../../components/ui/Status";
import { Link } from "react-router-dom";
import EmployeeDocument from "./EmployeeDocument";
import DatePicker from "react-datepicker";
import TapIcon from "../../../services/TapIcon";
import DateService from "../../../services/DateService";

class EmployeeWorkingModal extends AppBaseComponent {
  constructor() {
    super();

    this.workingFormInit = {
      company_name: "",
      job_title: "",
      from_date: "",
      to_date: "",
      job_description: "",
      relevant: "",
      employee_id: "",
    };

    this.state = {
      emp_id: "",
      workingForm: { ...this.workingFormInit },
      loading_form_data: false,
      saveFormSubmitting: false,
      employeeData: null,
      type: "Add",
      working_id: "",
    };

    this.relevantData = [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ];

    this.emp_working_url =   HRM_API_BASE_URL_2 + '/employee_working';
    this.emp_working_detail_url =   HRM_API_BASE_URL_2 + '/employee_working/detail/:id';


  }

  componentDidMount() {
    this.workingModal = new Modal(document.getElementById("workingModal"), {
      keyboard: false,
      backdrop: false,
    });
  }

  modalHandle = (type, id = null) => {
    this.workingModal.show();
    this.setState({
      emp_id: this.props.emp_id,
      loading_form_data: true,
      type,
    });

    if (type === "Edit") {
      HttpAPICall.withAthorization("GET", this.emp_working_detail_url.replace(':id', id), this.props.access_token, {}, {}, (response) => {
          const data = response.data;
          this.setState({
            working_id: id,
            workingForm: {
              company_name: data.company_name,
              job_title: data.job_title ? data.job_title : "",
              from_date: data.from_date ? data.from_date : "",
              to_date: data.to_date ? data.to_date : "",
              job_description: data.job_description ? data.job_description : "",
              relevant: data.relevant,
            },
          });
        }
      ).then(() => this.setState({ loading_form_data: false }));
    } else {
      setTimeout(() => {
        this.setState({ loading_form_data: false });
      }, 200);
    }
  };

  submitWorkingForm = (e) => {
    e.preventDefault();
    let frmData = { ...this.state.workingForm, employee_id: this.state.emp_id };
    this.setState({ saveFormSubmitting: true });

    if (this.state.type === "Edit") {
      frmData = { ...frmData, id: this.state.working_id };
      HttpAPICall.withAthorization("PUT", this.emp_working_url, this.props.access_token,
        {}, { ...frmData }, (response) => {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.workingModal.hide();
          this.setState({ workingForm: { ...this.workingFormInit } });
          this.props?.listLoading();
        }
      ).then(() => this.setState({ saveFormSubmitting: false }));
    } else {
      HttpAPICall.withAthorization("POST", this.emp_working_url,
        this.props.access_token, {}, { ...frmData }, (response) => {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.workingModal.hide();
          this.setState({ workingForm: { ...this.workingFormInit } });
          this.props?.listLoading();
        }
      ).then(() => this.setState({ saveFormSubmitting: false }));
    }
  };

  render() {
    return (
      <div className="modal fade" id="workingModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editEmployeeModalLabel">
                {this.state.type} Working Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.loading_form_data ? (
              <Loader />
            ) : (
              <Ax>
                <div className="modal-body">
                  <form id="workingForm" onSubmit={this.submitWorkingForm}>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="company_name"
                          className="form-label require"
                        >
                          Company Name
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="company_name"
                          type="text"
                          value={this.state.workingForm.company_name}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          required={true}
                          onChange={(e) =>
                            this.formInputHandler(e, "workingForm")
                          }
                          placeholder="Please enter Company Name"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="job_title" className="form-label">
                          Job Title
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="job_title"
                          type="text"
                          value={this.state.workingForm.job_title}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "workingForm")
                          }
                          placeholder="Please enter Job Title"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="form_date"
                          className="form-label require"
                        >
                          From Date
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <DatePicker
                          selected={
                            this.state.workingForm.from_date
                              ? moment(
                                  this.state.workingForm.from_date,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : false
                          }
                          name="from_date"
                          onChange={(value, event) =>
                            this.formDateHandler(
                              "from_date",
                              value,
                              "workingForm"
                            )
                          }
                          dateFormat="dd-MMM-yyyy"
                          className={"form-control form-control-sm"}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                          scrollMonthYearDropdown
                          placeholderText={`From Date`}
                          required
                        />
                        {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="From Date" className="add_calender-icon" /> */}
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="to_date" className="form-label require">
                          To Date
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <DatePicker
                          selected={
                            this.state.workingForm.to_date
                              ? moment(
                                  this.state.workingForm.to_date,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : false
                          }
                          name="to_date"
                          onChange={(value, event) =>
                            this.formDateHandler(
                              "to_date",
                              value,
                              "workingForm"
                            )
                          }
                          dateFormat="dd-MMM-yyyy"
                          className={"form-control form-control-sm"}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                          maxDate={new Date()}
                          scrollMonthYearDropdown
                          placeholderText="To Date"
                          required
                        />
                        {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="To Date" className="add_calender-icon" /> */}
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="job_title" className="form-label">
                          Relevant
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.relevantData}
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "workingForm",
                              "relevant"
                            );
                          }}
                          isSearchable={true}
                          isClearable={true}
                          value={this.relevantData.find(
                            (r) => this.state.workingForm.relevant === r.value
                          )}
                          placeholder="Select Rekevant"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Job Description
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <textarea
                          className="form-control form-control-sm"
                          id="exampleFormControlTextarea1"
                          name="job_description"
                          rows="2"
                          onChange={(e) =>
                            this.formInputHandler(e, "workingForm")
                          }
                        >
                          {this.state.workingForm.job_description}
                        </textarea>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    form="workingForm"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-primary mx-2"
                  >
                    Save{" "}
                    {this.state.saveFormSubmitting ? (
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </Ax>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EmployeeWorkingModal
);
