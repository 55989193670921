import React from 'react';
import Ax from "../../../components/hoc/Ax";
import TapSelect from "../../../components/ui/TapSelect";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import axios from "axios";
import {toast} from "react-toastify";
import { Helmet } from 'react-helmet';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapApiUrls from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import TapIcon from '../../../services/TapIcon';

export default class AddRole extends React.Component {

    constructor(props) {
        super(props);
        this.addRoleFormInit     =    {
              name               :      "",
            locking_period       :     null,
            visibility            :    "site",
            locking_applicable    :    "Y"
        }
        this.state              =               {
            addRoleFormData      :         {...this.addRoleFormInit},
            selectedReportee     :         null,
            selectedVisibility   :         null,
            roleFormData         :         [],
            visibilityOpt        :         [{name:"site",display:"Site"},{name:"asset",display:"Asset"}]
        }
    }

    addRole =  (e) => {
        e.preventDefault()
        const access_token = localStorage.getItem('access_token');

        let  formData = {
           role          :    this.state.addRoleFormData.name,
           parent_role   :    this.state.selectedReportee.value,
           visibility    :    this.state.selectedVisibility.value,
           locking_applicable :  this.state.addRoleFormData.locking_applicable,
           transaction_locking:  this.state.addRoleFormData.locking_period
        };
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('POST', TapApiUrls.APP_URL + '/group/role', access_token, {}, { ...formData }, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.props.history.push('/roles');
        }).then(() => this.setState({ saveFormSubmitting: false }));
      
    }

    handleFormChange =     (e)  => {
        let addRoleFormData   = this.state.addRoleFormData
        addRoleFormData[e.target.name] = e.target.value
        this.setState({addRoleFormData})
    }

    getRoleFormData(){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/group/allroles'
        }).then((response) => {
            {this.setState({roleFormData: response.data.data})}
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Load Item.', {position: toast.POSITION.TOP_RIGHT});
        })
    }

    selectReportee = (selected)=>{
        {this.setState({selectedReportee:selected})}
    }
    selectVisbility = (selected)=>{
        {this.setState({selectedVisibility:selected})}
    }

    componentDidMount() {
        this.getRoleFormData()
    }

    closeEvent  =       ()      =>      {
        this.props.history.push('/roles')
    }

    render() {

        return (
            <ApplicationLayout>
                <Helmet><title>Role Add</title></Helmet>
                <div className="page_title row m0">
                    <div className="col-12">
                        <h3>Add Role</h3>
                        <div className="text-end mt15">
                            <button onClick={this.closeEvent}
                                type="button" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" />

                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="page_containt row">
                        <div className="pageTbl col-12">
                            <form className="bg-white p-3" onSubmit={this.addRole.bind(this)}>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Details</span>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3">
                                        <label htmlFor="inputPassword6" className="form-label">Role
                                            Name</label>
                                    </div>
                                    <div className="col-4">
                                        <input name="name"
                                            type="text"
                                            value={this.state.addRoleFormData.name}
                                            className="form-control"
                                            placeholder="Please Enter Role Name"
                                            autoComplete='off'
                                            onChange={(e) => this.handleFormChange(e)} />
                                    </div>
                                </div>
                                <div className="mt-3 row align-items-center mb-3">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Reportee Role</label>
                                    </div>
                                    <div className="col-4">

                                        <TapSelect
                                            options={this.state?.roleFormData?.map((s) => { return { value: s.id, label: s.role } })}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select Reportee Role"
                                            required={true}
                                            value={this.state.selectedReportee}
                                            changeEvent={(selectedOption) => {
                                                this.selectReportee(selectedOption);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3 row align-items-center mb-3">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Visibility</label>
                                    </div>
                                    <div className="col-4">

                                        <TapSelect
                                            options={this.state?.visibilityOpt?.map((s) => { return { value: s.name, label: s.display } })}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.selectedVisibility}
                                            placeholder="Please Select Visibility"
                                            changeEvent={(selectedOption) => {
                                                this.selectVisbility(selectedOption);
                                            }}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3 row align-items-center mb-3">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Locking
                                            Applicable</label>
                                    </div>
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <input
                                                    name="locking_applicable"
                                                    type="radio"
                                                    value={"Y"}
                                                    onChange={(e) => this.handleFormChange(e)}
                                                    className="form-check-input"
                                                    required={true}
                                                    checked={this.state.addRoleFormData.locking_applicable === "Y"}
                                                />
                                                <label className="form-check-label"
                                                    htmlFor={this.state.addRoleFormData.locking_applicable}>Yes</label>
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    name="locking_applicable"
                                                    type="radio"
                                                    value={"N"}
                                                    onChange={(e) => this.handleFormChange(e)}
                                                    className="form-check-input"
                                                    required={true}
                                                    checked={this.state.addRoleFormData.locking_applicable === "N"}
                                                />
                                                <label className="form-check-label"
                                                    htmlFor={this.state.addRoleFormData.locking_applicable}>No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-3">
                                        <label htmlFor="inputPassword6" className="form-label">Enter
                                            Transaction Lock Period (Days)</label>
                                    </div>
                                    <div className="col-4">
                                        <input name="locking_period"
                                            type="text"
                                            value={this.state.addRoleFormData.locking_period}
                                            className="form-control"
                                            disabled={this.state.addRoleFormData.locking_applicable === "N" ? true : false}
                                            placeholder="Please Enter Locking Period"
                                            autoComplete='off'
                                            onChange={(e) => this.handleFormChange(e)} />
                                    </div>
                                </div>
                                <div className="col-12 text-end fixed_footer">
                                    <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                                    <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-secondary ms-2">Save
                                    {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ApplicationLayout>
        );
    }
}
