import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import Button from '../../../components/ui/Button';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import DatePicker from 'react-datepicker';
import TapIcon from '../../../services/TapIcon';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';




 class EmployeeRosterListing extends AppBaseComponent {

    constructor() {
        super();

        this.initRosterSearchForm         =       {
            search_roster_ids             :       [],
            search_site_ids               :       [],
            'search_employee_ids'         :       [],
            search_shift_ids              :       [],
        }

        this.rosterSearchFormLabel        =       {
            search_roster_ids             :       'Linked Roster : ',
            search_site_ids               :       'Sites : ',
            'search_employee_ids'         :       'Employee : ',
            search_shift_ids              :       'Linked Shifts : ',
        }

        this.initRosterMappingForm          =       {
            transaction_date                 :       '',
            roster_id                       :       ''
        }

        this.state = {
            listing_loading               :        false,
            listing_tbl_page              :        1,
            listingMeta                   :        null,
            employee_roster_linking          :         [],
            totalListingCount             :         0,
            form_data_loading             :         false,
            formDataLoaded                :         false,
            rosterMappingForm               :       {...this.initRosterMappingForm},
            rosterFilterForm              :         {...this.initRosterSearchForm},
            submittedRosterFilterForm     :         {...this.initRosterSearchForm},
            formSearchedElems             :         [],
            formDataLoaded                :         false,
            saveFormSubmitting            :         false,
            mappingFormDataLoaded          :        false,
            all_roster_lists                   :       [],
            all_linked_shifts              :            [],
            searchedEmployees              :            [],
            allShiftsLists                 :            [],
            iam_user_sites                :         [],
            selectedEmployees               :       []
        }   
    }

    componentDidMount(){  
        this.initilaizeAppDataToFilterForm(this.props);
        this.loadListingTblData(1)
        this.getRosterMappingFormData();
        this.rostertMappingModal            =      new Modal(document.getElementById('rostertMappingModal'), {keyboard: false, backdrop :false});
        this.rosterSearchModal              =       new Modal(document.getElementById('rosterSearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }

    initilaizeAppDataToFilterForm           =   (props)  =>  {
        if(!this.state.formDataLoaded){    
                this.setState({
                    formDataLoaded          :   true,
                   iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                });            
        }
    }



     //************************GET consumption LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
         this.setState({listing_loading: true, listing_tbl_page: page});
         let params                      =   {page:page,...this.state.submittedRosterFilterForm};
        HttpAPICall.withAthorization('GET',  TapApiUrls.HRM_URL + '/employee_roster_shift_list', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                employee_roster_linking          :       respData.data,
                listingMeta                    :       respData.meta,
                totalListingCount              :       respData.meta.total,
            });
        }).then(() => this.setState({listing_loading: false}));
       
    }


    getRosterMappingFormData        =       ()      =>      {
        this.setState({form_data_loading: true});
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/asset/roster_listing', this.props.access_token,{}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                all_roster_lists        :    respData && respData.length > 0 ? respData.map(rs => {return({...rs,value:rs.id,label:rs.roster_name})}) : [],
                mappingFormDataLoaded   :    true
            },() => {
                let all_shifts = this.state.all_roster_lists && this.state.all_roster_lists.length > 0 ? this.state.all_roster_lists.reduce((acc, obj) => acc.concat(obj.shifts), []).map(shift => ({ value: shift.id, label: shift.shift_name })) : [];
                this.setState({allShiftsLists : all_shifts})
            });
        }).then(() => this.setState({form_data_loading: false}));
    }

    linkRosterModalInit                  =   (data)  =>  {
        if(data){
            this.setState({
                rosterMappingForm: { 
                    ...this.initRosterMappingForm,
                    employee_ids : [data.enc_id],
                    employee_name : data.display_full_name
                 },
                 selectedEmployees  :   [data]
            })
        }
        this.rostertMappingModal.show();
    }

    submitRosterHistoryForm      =       (e)     =>      {
        e.preventDefault();
       
        let frmData = { ...this.state.rosterMappingForm }
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', TapApiUrls.HRM_URL + '/employee_roster_shift_linking', this.props.access_token, {}, { ...frmData }, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.rostertMappingModal.hide();
            this.loadListingTblData(1)
            this.setState({
                rosterMappingForm: { ...this.initRosterMappingForm },
            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    submitRosterSearchForm          =   (event)  =>  {
        event && event.preventDefault()
        let serachFormKeys                      =   Object.keys(this.state.rosterFilterForm);
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.rosterSearchFormLabel[key];
            let value                       =   this.state.rosterFilterForm[key];
            if(value && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'search_shift_ids') {
                        show_val            =   this.state.allShiftsLists.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_roster_ids') {
                        show_val            = this.state.all_roster_lists.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedRosterFilterForm           :   {...this.state.rosterFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListingTblData(1);
            this.rosterSearchModal.hide()
        });
    }

    clearSearchForm         =       ()      =>      {
        this.setState({
            rosterFilterForm             :           {...this.initRosterSearchForm},
            formSearchedElems            :          [],
            submittedRosterFilterForm    :          {...this.initRosterSearchForm},
            searchedEmployees               :          []
        }, () => {
            this.loadListingTblData(1);
            this.rosterSearchModal.hide()
        })
    }

    rosterSearchModalInit       =       ()      =>  {
        this.rosterSearchModal.show()
    }

    checkAllList() {
        let rosterItemCollections      =   document.getElementsByClassName('employee_id_checkbox')
        if(rosterItemCollections && rosterItemCollections.length > 0) {
            for (let i = 0; i < rosterItemCollections.length; i++) {
                rosterItemCollections[i].checked = document.getElementById('all_check_employee').checked;
            }
        }
    }

    linktoRosterHandler                  =   ()  =>  {
    
        let employee_ids            =       [];
        let employee_names            =       [];
       
        let rosterItemCollections      =   document.getElementsByClassName('employee_id_checkbox');
        if(rosterItemCollections && rosterItemCollections.length > 0) {
            for (let i = 0; i < rosterItemCollections.length; i++) {
                if(rosterItemCollections[i].checked) {
                    employee_ids.push(rosterItemCollections[i].value);
                    let selectedEmployees = this.state.employee_roster_linking.find(data => data.enc_id == rosterItemCollections[i].value);
                    employee_names.push(selectedEmployees) 
                }
            }
        }

        if (employee_ids.length == 0) {
            toast.error('Please select atleast one Employee', { position: toast.POSITION.TOP_RIGHT });
        } else {
            let allSelectedEmployees         =    employee_ids.flat();
            let allSelectedEmployeesData         =    employee_names.flat();
            this.setState({
                rosterMappingForm: { 
                    ...this.initRosterMappingForm,
                    employee_ids : allSelectedEmployees,
                 },
                  selectedEmployees  :   allSelectedEmployeesData
            })
            this.rostertMappingModal.show()
        }
    }

    employeeRosterLinkingList            =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={"col-12"}>
                <h3>Employee Roster List</h3>
                <div className="text-end mt15">
                <button disabled={this.state.listing_loading} type="button" onClick={this.linktoRosterHandler} className="btn btn-primary"> Link Roster</button>
                    <Button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={this.rosterSearchModalInit}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} />
                    </Button>

                </div>
            </div>
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', 'col-12'].join(' ')} >{this.employeeRosterMappingJsx()}</div>
                  
                </div>
            </div>
        </Ax>);
    }

    rostertMappingModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="rostertMappingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Roster Mapping</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}>Employee</td>
                                        <th>{this.state.selectedEmployees && this.state.selectedEmployees.length > 0 ? this.state.selectedEmployees.map(em => {return(em.display_full_name)}).join(', ') : "-"}</th>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                        {this.state.form_data_loading ? <div className='text-center'><Loader /></div>
                            : <div className="modal-body">
                                <form onSubmit={this.submitRosterHistoryForm} id="addRosterHistoryForm">
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm require"> Activation Date</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.rosterMappingForm.transaction_date
                                                        ? moment(this.state.rosterMappingForm.transaction_date, 'YYYY-MM-DD').toDate()
                                                        : false
                                                }
                                                name="transaction_date"
                                                onChange={(value, event) => this.formDateHandler('transaction_date', value, 'rosterMappingForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Activation Date`}
                                                required={true}
                                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm require">Select Roster</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'rosterMappingForm', 'roster_id',() => {
                                                        let linkedShifts = selectedOption && selectedOption.shifts && selectedOption.shifts.length > 0 ? selectedOption.shifts.map((sh) => {return({value:sh.id,label:sh.shift_name})}) : []
                                                        this.setState({
                                                            all_linked_shifts   :   linkedShifts
                                                        })
                                                    });
                                                }}
                                                options={this.state.all_roster_lists}
                                                isSearchable={true}
                                                required={true}
                                                value={this.state.all_roster_lists.find(m => m.value == this.state.rosterMappingForm.roster_id)}
                                                placeholder="Please Select Roster"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm require">Shift</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'rosterMappingForm', 'shift_id');
                                                }}
                                                options={this.state.all_linked_shifts}
                                                isSearchable={true}
                                                required={true}
                                                value={this.state.all_linked_shifts.find(m => m.value == this.state.rosterMappingForm.shift_id)}
                                                placeholder="Please Select Shift"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <table className="table my-3 table-hover table-bordered">
                                        <thead className='bg-light'>
                                            <tr>
                                                <td style={{ width: "5%" }} className="text-center">S.No</td>

                                                <td style={{ width: "10%" }}>Roster ID</td>
                                                <td style={{ width: "25%" }}>Roster Name</td>
                                                <td style={{ width: "15%" }} className="text-center">No of Shifts</td>
                                                <td style={{ width: "20%" }}>Shift Name</td>
                                                <td style={{ width: "15%" }}>Shift Start Time</td>
                                                <td style={{ width: "10%" }}>Shift Hours</td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.all_roster_lists && this.state.all_roster_lists.length > 0
                                                ? this.state.all_roster_lists.map((roster, k) => {
                                                    return (<Ax>
                                                        {roster.shifts && roster.shifts.length > 0
                                                            ? roster.shifts.map((ros, key) => {
                                                                return (<tr>
                                                                    {key == 0 && <Ax>
                                                                        <td className="text-center" rowSpan={roster.no_of_shift}>{k + 1}</td>
                                                                        <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.tap_roster_id ? roster.tap_roster_id : "-"}</td>
                                                                        <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.roster_name ? roster.roster_name : "-"}</td>
                                                                        <td className="text-center" rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.no_of_shift ? roster.no_of_shift : "0"}</td>

                                                                    </Ax>}
                                                                    <td>{ros.shift_name}</td>
                                                                    <td>{ros.shift_start}</td>
                                                                    <td>{ros.shift_time}</td>
                                                                </tr>)
                                                            }) : null}
                                                    </Ax>)
                                                })
                                                : <tr><td colSpan={6} className="text-center">No Record</td></tr>}
                                        </tbody>


                                    </table>
                                </form>

                            </div>}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="addRosterHistoryForm">Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    employeeRosterMappingJsx            =   ()   =>   {
      
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.clearSearchForm} />
            <table className="table table-bordered bg-white ">
                <thead className="table-secondary">

                    <tr className="text-center">
                        <th className="text-center" style={{width:"5%"}}><input type='checkbox' id="all_check_employee" onChange={this.checkAllList} /></th> 
                        <th scope="col" style={{ width: "18%" }} className="text-start">Employee Name</th>
                        <th scope="col" style={{ width: "12%" }} className="text-start">Employee Code</th>
                        <th scope="col" style={{ width: "15%" }}>Site</th>
                        <th scope="col" style={{ width: "15%" }}>Linked Roster</th>
                        <th scope="col" style={{ width: "15%" }}>Shift Name</th>
                        <th scope="col" style={{ width: "15%" }}>Shift Time</th>
                        <th scope="col" style={{ width: "5%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.employee_roster_linking.length > 0
                            ? (this.state.employee_roster_linking.map((item, index) => {
                                return (<tr key={index} >

                                    <td className="text-center">
                                        <input type='checkbox'
                                            className="employee_id_checkbox"
                                            name="employee"
                                            value={item.enc_id}
                                            data-employeeName={item.display_full_name}
                                        />
                                    </td>
                                    <td><Link target="_blank" to={{ pathname: "/employee_list", search: "?" + new URLSearchParams({emp_id:item.enc_id}).toString() }}>{item.name}</Link></td>
                                    <td>{item.employee_code ? item.employee_code : ""}</td>
                                    <td>{item.site ? <span>{item.site.site_name}</span> : "-"}</td>
                                    <td>{item.roster ? item.roster.roster_name : "-"}</td>
                                    <td className="text-center">{item.current_working_shift ? item.current_working_shift.shift_name  : "-"}</td>
                                    <td className="text-center">{item.current_working_shift && item.current_working_shift.shift_start ? item.current_working_shift.shift_start : ""} - {item.current_working_shift && item.current_working_shift.shift_end ? item.current_working_shift.shift_end : ""}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className={ "dropdown-item"} role="button" onClick={() => this.linkRosterModalInit(item)}>Roster Mapping</a></li>
                                          
                                        </ul>
                                    </td>

                                </tr>)
                            }))
                            : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    rosterSearchModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="rosterSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Employee Roster Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="rosterSearchForm" onSubmit={this.submitRosterSearchForm}>
                            {this.state.form_data_loading ? <Loader />
                                : <div className='modal-body'>
                                    <div className="row align-items-center mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Employee Name</label>
                                        <div className="col-sm-10">
                                            <InputEmployeeSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_employee_ids');
                                                    this.setState({ searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                isMulti={true}
                                                style={{ height: "30px", fontSize: "93%" }}
                                                value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                            />
                                        </div>

                                    </div>

                                    <div className="row align-items-center mb-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Site</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                options={this.state.iam_user_sites}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_site_ids')}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.iam_user_sites.filter(s => this.state.rosterFilterForm.search_site_ids.includes(s.value))}
                                                placeholder="Select Sites"
                                            />
                                        </div> 

                                    </div>
                                    <div className="row align-items-center mb-3">

                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Linked Shifts</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                options={this.state.allShiftsLists}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_shift_ids')}
                                                value={this.state.allShiftsLists.filter(r => this.state.rosterFilterForm.search_shift_ids.includes(r.value))}
                                                placeholder="Select Linked Shifts"
                                                isMulti={true}
                                            />
                                        </div>

                                    </div>
                                    <div className="row align-items-center mb-3">

                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Linked Roster</label>
                                        <div className="col-sm-10">
                                            <TapSelect
                                                options={this.state.all_roster_lists}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_roster_ids')}
                                                value={this.state.all_roster_lists.filter(r => this.state.rosterFilterForm.search_roster_ids.includes(r.value))}
                                                placeholder="Select Linked Roster"
                                                isMulti={true}
                                            />
                                        </div>

                                    </div>

                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

    render() {
       
        return (
            <ApplicationLayout>
                 <Helmet><title>Employee Roster Linking - List</title></Helmet>
                {this.employeeRosterLinkingList()}
                {this.rosterSearchModalJsx()}
                {this.rostertMappingModalJsx()} 
              
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(EmployeeRosterListing);

