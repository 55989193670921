
const ReactSelectStyle          =       (props) =>  {
    return {container : (provided) => ({
        ...provided,
        ...(props.containerHeight && { minHeight: props.containerHeight }),
    }),
    option: (provided) => ({
        ...provided,
        borderBottom    :   '0px',
        margin          :   '0px',
        padding         :   '10px 16px',
        borderRadius    :   '0px',
        'opacity'       :   1,
        zIndex          :   999,
        ...(props.fontSize && { fontSize: props.fontSize }),
    }),
    control: (provided, { selectProps: { width, isDisabled }}) => ({
        ...provided,
        width           :   width,
        borderRadius    :   '0px',
        backgroundColor :   isDisabled ? '#e9ecef' : '#fff',
        border          :   '1px solid #ececec',
        padding         :   '0px',
        minHeight       :   props.containerHeight ? props.containerHeight : '40px',
        ...(props.containerHeight && { minHeight: props.containerHeight }),
        ...(props.fontSize && { fontSize: props.fontSize }),
    }),
    singleValue: (provided) => {
        return { 
            ...provided, 
            color           :   '#000'
        };
    },
    multiValue: (provided) => {
        return { 
            ...provided,
            display         :   'inline-flex'
        };
    },
    'dropdownIndicator': (provided) => {
        return {
            ...provided,
            padding                 :   '0px 5px'
        }
    },
    'clearIndicator': (provided) => {
        return {
            ...provided,
            padding                 :   '0px 5px'
        }
    },
    'valueContainer': (provided) => {
        return {
            ...provided,
            padding                 :   '0px 5px'
        }
    }};
};


export default ReactSelectStyle;

