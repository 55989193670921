import React from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import ReactPaginate from "react-paginate";
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon"
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from "../../components/ui/TapSelect";
import {Modal} from "bootstrap";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { DisplayListPagination } from '../../components/TapUi';
import { Link } from 'react-router-dom';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapIcon from '../../services/TapIcon';

class ReportPermission extends AppBaseComponent {
  
    constructor(props) {
        super(props);
        this.initPermissionForm     =   {
            role_id                 :   props.roleData ? props.roleData.id : '',
            view_report_ids         :   [],
            download_report_ids     :   []
        }
        
        this.state                  =   {
            listing_loading         :   false,
            reportList              :   [],
            addReportVisibiltyForm  :   {...this.initPermissionForm}
        }

        this.report_url         =   ORG_API_BASE_URL_2 + '/reports';
        this.add_permission_url =   ORG_API_BASE_URL_2 + '/add_reports_permission';
        this.role_report_permission_url =   ORG_API_BASE_URL_2 + '/reports_permission';
   }

   componentDidMount() {
        this.initilaizeData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.roleData !== this.props.roleData) {
            this.initilaizeData(nextProps);
        }
    }

    initilaizeData = (pr) => {
        if (pr && pr.roleData) {
            this.getReportList();
            this.roleReportPermission(pr);
        }
    }

    roleReportPermission =  (pr) => {
        HttpAPICall.withAthorization('GET',  this.role_report_permission_url + '/' + pr.roleData.id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data.data;
            this.setState({
                addReportVisibiltyForm  :   {
                    role_id             :   this.props.roleData.id,
                    view_report_ids     :   respData && respData.view_report_ids ? respData.view_report_ids : [],
                    download_report_ids :   respData && respData.download_report_ids ? respData.download_report_ids : []
                }
            })
        });
    }

    getReportList   =   ()    =>  {
        this.setState({listing_loading: true});
        HttpAPICall.withAthorization('GET',  this.report_url, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                reportList : respData && respData.length > 0 ? respData : [],
            })
        }).then(() => this.setState({listing_loading: false}));
    }

    handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        if(isChecked) {
            this.setState({
                addReportVisibiltyForm : {
                    ...this.state.addReportVisibiltyForm, 
                    [e.target.name] : [...this.state.addReportVisibiltyForm[e.target.name], parseInt(e.target.value)]
                }
            });
        }else {
            this.setState({
                addReportVisibiltyForm : {
                    ...this.state.addReportVisibiltyForm, 
                    [e.target.name] : [...this.state.addReportVisibiltyForm[e.target.name]].filter(val => val !== parseInt(e.target.value))
                }
            }, () => {
                if(e.target.name === 'view_report_ids') {
                    this.setState({
                        addReportVisibiltyForm : {
                        ...this.state.addReportVisibiltyForm, 
                        download_report_ids : [...this.state.addReportVisibiltyForm['download_report_ids']].filter(val => val !== parseInt(e.target.value))
                    }})
                }
            });
            
        }
      };

      saveReportRoleVisibiltyForm     =       (e)     =>      {
        e.preventDefault();
        
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.add_permission_url, this.props.access_token, {}, { ...this.state.addReportVisibiltyForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.setState({ addReportVisibiltyForm     :        {...this.initPermissionForm}});
            this.roleReportPermission(this.props);
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    render() {
       let {reportList}     =   this.state;
        return (<Ax>
            <Helmet><title>Role - Report Permission</title></Helmet>
            {this.state.listing_loading ? <div className='text-center'><Loader /></div>
                : 
                <>
                    <form onSubmit={this.saveReportRoleVisibiltyForm}>

                        <table className="table table-hover table-bordered my-4 table-sm">
                            <thead className="table-secondary">
                                <tr>
                                    <th style={{width: "20%"}}>Section</th>
                                    <th scope="col" style={{ width: "40%" }}>Reports</th>
                                    <th style={{ width: "20%" }} className='text-center'>View</th>
                                    <th style={{ width: "20%" }} className='text-center'>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                reportList.length > 0 
                                ? reportList.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.section}</td>
                                            <td>{data.name}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    value={data.id}
                                                    name='view_report_ids'
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.addReportVisibiltyForm.view_report_ids && this.state.addReportVisibiltyForm.view_report_ids.length > 0 && this.state.addReportVisibiltyForm.view_report_ids.includes(data.id) ? true : false} 
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    value={data.id}
                                                    name='download_report_ids'
                                                    disabled={this.state.addReportVisibiltyForm.view_report_ids && this.state.addReportVisibiltyForm.view_report_ids.length > 0 && this.state.addReportVisibiltyForm.view_report_ids.includes(data.id) ? false : true} 
                                                    onChange={this.handleCheckboxChange}
                                                    checked={this.state.addReportVisibiltyForm.download_report_ids && this.state.addReportVisibiltyForm.download_report_ids.length > 0 && this.state.addReportVisibiltyForm.download_report_ids.includes(data.id) ? true : false} 
                                                />
                                            </td>
                                        </tr>
                                    )})
                                : <tr><td className='text-center' colSpan={4}>No Record Found</td></tr>
                            }
                                
                            </tbody>
                        </table>
                        <div className="float-end">
                        {
                            reportList.length > 0 &&
                            <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mb-2">
                                Save  {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        }
                        </div>
                    </form>
                </>
            }
        </Ax>)
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps)(ReportPermission);