import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import TapSelect from "../../../components/ui/TapSelect";
import Status from '../../../components/ui/Status';
import { Helmet } from 'react-helmet';
import { HRM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';

class EmployeeWiseComplianceReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        

        this.reportFilterFormInitialState   =   {
            'search_employee_ids'            :   [],
            'search_site_ids'                :    [],
            'search_status_ids'              :   '',
            'search_compliance_type_ids'     :   '',
            'tags_search'                    :   [],
        };
        
        this.reportFilterFormLabel          =   {
            'search_employee_ids'              :   'Employee : ',
            'search_site_ids'                  :   'Sites : ',
            'search_status_ids'                   :   'Status : ',
            'tags_search'                     :   'Tags : ',
            'search_compliance_type_ids'     :   'Compliance Type : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedAssets                      :   [],
            listingData                         :   [],
            listingMeta                         :   null,
            iam_user_sites                      :   [],
            allEmployeeTags                     :   [],
            allStatus                           :   [],
            searchedEmployees                   :   [],
            allComplianceType                   :   []
        };

        this.complianceDueDateUrl      =   HRM_API_BASE_URL_2  +   '/report/compliance-due-date';

    }
    
    
    componentDidMount() {
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props);
        }, 1000);
      
    }
    
    
    initilaizeAppDataToFilterForm           =   (pr)  =>  {
        HttpAPICall.withAthorization('GET', this.complianceDueDateUrl, this.props.access_token, {action:"formData"}, {}, (resp) => {
            let respData = resp.data;
            // let status_obj  =   {value:"na",label:"Not Applicable"};
            let statusList   =  respData.status && respData.status.length > 0  ? respData.status.map((s) => { return {value: s.status, label: s.status_text}}) : [];
            this.setState({
                access_token         :       pr.access_token,
                formDataLoaded      :           true,
                iam_user_sites       :       pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                allEmployeeTags      :       respData.tags && respData.tags.length > 0  ? respData.tags.map((s) => { return {value: s.name, label: s.name}}) : [],
                allStatus            :      [...statusList],
                allComplianceType    :       respData.compliance_types && respData.compliance_types.length > 0  ? respData.compliance_types.map((s) => { return {value: s.id, label: s.type}}) : [],
               
            },() => {
                let status = [];
                    let ParamObject = new URLSearchParams(this.props.location.search);
                    let search_site_ids = [];
                    
                    if (ParamObject.get('status') || ParamObject.get('search')) {
                            if(ParamObject.get('search')){
                                status = this.state.allStatus.map(st => st.value)
                            }else{
                                if (ParamObject.get('status')) {
                                    status = ParamObject.get('status');
                                }
                            }

                            if (ParamObject.get('search_site_ids')) {
                                let site = this.state.iam_user_sites.find(us => us.value == ParamObject.get('search_site_ids'));
                                    search_site_ids = [site.value];
                                
                            }
                           
                            this.setState({
                             
                                reportFilterForm        :       {
                                    ...this.state.reportFilterForm,
                                    search_status_ids     :   ParamObject.get('search') ? status :  [status == "na" ? status : Number(status)],
                                    search_site_ids     :       search_site_ids,
                                }
                            }, () => {
    
                                this.reportFilterFormSubmit()
                            });
                        }else {
                            this.reportFilterFormSubmit()
                        }
                    
                

            });
           
        })
       
           
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                     if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'tags_search') {
                        show_val            =   this.state.allEmployeeTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatus.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_compliance_type_ids') {
                        show_val            =   this.state.allComplianceType.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                   
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm,need_employee : 'Y',action:"data"};
        HttpAPICall.withAthorization('GET', this.complianceDueDateUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                totalListingCount               :   respData.total,
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.complianceDueDateUrl, this.props.access_token, { action : 'download',...this.state.submittedReportFilterForm} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems                  :       [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            searchedEmployees                       :       [],
        }, () => {
            this.loadListingTblData(1);
        });
    }

    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Employee Compliance Report</h3>
                <div className="text-end mt15">
                  
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Employee</label>
                    <div className="col-sm-6">
                        <InputEmployeeSearch
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: { ...this.state.reportFilterForm, search_employee_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                    searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                });
                            }}
                            isMulti={true}
                            placeholder="Select Employee"
                            value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Compliance Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allComplianceType}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_compliance_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            menuPlacement="bottom"
                            value={this.state.allComplianceType.filter(s => this.state.reportFilterForm.search_compliance_type_ids.includes(s.value))}
                            placeholder="Select Compliance Type"
                            isMulti={true}
                        />
                    </div>

                </div>

                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            menuPlacement="bottom"
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                            isMulti={true}
                        />
                    </div>

                </div>

                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatus}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            menuPlacement="bottom"
                            value={this.state.allStatus.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                            isMulti={true}
                        />
                    </div>

                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allEmployeeTags}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'tags_search');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.allEmployeeTags.filter(s => this.state.reportFilterForm.tags_search.includes(s.value))}
                            placeholder="Select Tags"
                        />
                    </div>

                </div>


                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
        
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
     
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                        <th scope="col" style={{ width: "22%" }} className="text-start">Employee</th>
                        <th scope="col" style={{ width: "19%" }} className="text-start">Site Name</th>
                        <th scope="col" style={{ width: "19%" }} className="text-start">Compliance Name</th>
                        <th scope="col" style={{ width: "15%" }} className="text-start">Number</th>
                        <th scope="col" style={{ width: "10%" }}>Due Date</th>
                        <th scope="col" style={{ width: "10%" }}>Status</th>
                    </tr>
                </thead>
                <tbody>

                    {this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {

                            return (
                                <tr>
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td>{data.display_full_name ? data.display_full_name : ""}</td>
                                    <td>{data.site_name  ? data.site_name : ""}</td>
                                    <td>{data.compliance_type  ? data.compliance_type : ""}</td>
                                    <td>{data.number  ? data.number : ""}</td>
                                    <td className='text-center'>{data.display ? data.display : ""}  </td>
                                    <td className='text-center'>{data.status ? <Status color={data.status.status_color_code}>{data.status.status_text}</Status> : ""} </td>
                                </tr>
                            )
                        }))
                        : <tr><td className="text-center" colSpan={7}>No Record Found</td></tr>
                    }

                </tbody>
            </table>
        </Ax>);
    }

  
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
             <Helmet><title>Employee Wise Compliance - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.reportSearchClear}  />
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                            : <Loader />
                        }

                        {this.state.reportDownloadBtn == true
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
          
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
    };
};

export default connect(mapStateToProps)(EmployeeWiseComplianceReport);