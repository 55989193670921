import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import swal from "sweetalert";
import AppBaseComponent from "../../components/AppBaseComponent";
import ApplicationLayout from "../../layouts/ApplicationLayout";
import Ax from "../../components/hoc/Ax";
import TapIcon from "../../services/TapIcon";
import Loader from "../../components/ui/Loader/Loader";
import HttpAPICall from "../../services/HttpAPICall";
import {
  DisplayListPagination,
  DisplaySearchCriteria,
  PeriodSelectionWithCustom,
} from "../../components/TapUi";
import AssetCard from "../includes/assetCard/AssetCard";
import Status from "../../components/ui/Status";
import TapSelect from "../../components/ui/TapSelect";
import { Collapse, Modal } from "bootstrap";
import InputAssetSearch from "../includes/ui/InputAssetSearch";
import download from "../../services/DownloadFile";
import DownloadFile from "../../services/DownloadFile";
import InputSubAssemblySearch from "../includes/ui/InputSubAssemblySearch";
import InputWorkstationSearch from "../includes/ui/InputWorkstationSearch";
import InputEmployeeSearch from "../includes/ui/InputEmployeeSearch";
import TicketDetail from "../transactions/ticketTransaction/TicketDetail";
import TapApiUrls, {
  IAM_API_BASE_URL,
  IAM_API_BASE_URL_2,
} from "../../services/TapApiUrls";
import DateService from "../../services/DateService";
import TicketTransactionAdd from "../transactions/ticketTransaction/TicketTransactionAdd";
import TicketsBoardManagement from "../transactions/ticketTransaction/TicketsBoardManagement";
import ProjectDetailCard from "./ProjectDetailCard";

class ProjectTickets extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.ticketSearchFormInitialState = {
      search_ticket_type_id: "",
      search_ticket: "",
      "search_ticket_id  ": null,
      search_ticket_header: "",
      search_asset_id: null,
      search_site_ids: "",
      asset_group_ids: [],
      search_asset_type: "",
      search_tagged_users: [],
      search_ageing_days: "",
      closure_date_range: "",
      closure_date_range_start: "",
      closure_date_range_end: "",
      creation_date_range: "",
      creation_date_range_start: "",
      creation_date_range_end: "",
      due_date_range: "",
      due_date_range_start: "",
      due_date_range_end: "",
      remark_ageing_days: "",
      search_ticket_status: [],
      ticket_with_asset: "",
      search_tags: [],
      search_employees: [],
      search_workstation: "",
      search_sub_assembly: "",
      search_employee_id: "",
    };

    this.ticketSearchFormLabel = {
      search_ticket_type_id: "Ticket Type : ",
      search_ticket: "Ticket : ",
      search_ticket_id: "Ticket ID : ",
      search_ticket_header: "Ticket Header : ",
      search_asset_id: "Asset : ",
      search_site_ids: "Site : ",
      asset_group_ids: "Asset Type Groups : ",
      search_asset_type: "Asset Type : ",
      search_tagged_users: "Tagged Users : ",
      search_ageing_days: "Ageing Days : ",
      closure_date_range: "Completion Date Range : ",
      creation_date_range: "Creation Date Range : ",
      due_date_range: "Due Date Range : ",
      remark_ageing_days: "Remark Ageing Days : ",
      search_ticket_status: "Ticket Status : ",
      ticket_with_asset: "Ticket With Asset : ",
      search_tags: "Tags : ",
      search_employees: "Linked Employees : ",
      search_workstation: "Workstation : ",
      search_sub_assembly: "Sub Assembly : ",
      search_employee_id: "Employee : ",
    };

    this.initShareEmail = {
      to_emails: [],
      message: "",
      subject: "",
      attachment: "N",
    };

    this.state = {
      ticket_listing: [],
      ticket_view_data: [],
      listingMeta: null,
      listing_tbl_page: 1,
      totalListingCount: 0,
      listing_loading: false,
      view_loading: false,
      ticketAddScreenView: false,
      ticketUpdateScreenView: false,
      minimizeTable: false,
      ticketHeader: "",
      card_loading: false,
      asset_card_details: [],
      ticketSearchForm: { ...this.ticketSearchFormInitialState },
      submittedTicketFilterForm: { ...this.ticketSearchFormInitialState },
      allTicketType: [],
      searchedAsset: null,
      iam_asset_types: [],
      iam_user_sites: [],
      filterFormData: [],
      form_loading: false,
      formSearchedElems: [],
      allTaggedUsers: [],
      allStatusList: [],
      curent_activity_id: null,
      ticketDownloading: false,
      saveFormSubmitting: false,
      remark_form_loading: false,
      remarkFormData: [],
      allRemarkStatus: [],
      allAssetStatus: [],
      allDefectCode: [],
      addRemarkForm: { ...this.initAddRemarkForm },
      shareEmailForm: { ...this.initShareEmail },
      options: [],
      addDocumentForm: { ...this.initAddDocument },
      share_email_loading: false,
      reportDownloading: false,
      filterLoading: false,
      assetId: null,
      searchTicketOptions: [
        { value: "Y", label: "Yes" },
        { value: "N", label: "No" },
      ],
      allAssetTypesGroupsList: [],
      customDownloadCriteria: [
        {
          key: "display_ticket_expense",
          label: "Ticket Expense",
          value: true,
          setting_key: "ticket_expense",
        },
        {
          key: "display_ticket_allocation",
          label: "Ticket Allocation",
          value: true,
          setting_key: "ticket_allocation",
        },
        {
          key: "display_ticket_consumption",
          label: "Ticket Consumption",
          value: true,
          setting_key: "ticket_consumption",
        },
      ],
      ticketTags: [],
      ticket_allow_tag: "N",
      ticket_allow_employees: "N",
      searchedWorkstation: "",
      searchedSubAssembly: "",
      searchedEmployees: [],
      ticketEmployee: "",
      project_detail_loading: false,
      projectDetails: null,
      createTicket: false,
      ticketBoard: false,
      switchTicketBoard: "Switch to Ticket Board",
      showProjectCard: true,
    };
    this.setFilterOnNavigation = this.setFilterOnNavigation.bind(this);
    this.ChangeAssetStatusModalRef = React.createRef();
    this.ticketDetailRef = React.createRef();
    this.projectUrl = IAM_API_BASE_URL_2 + "/project/detail";
    this.ticketListUrl = IAM_API_BASE_URL_2 + "/ticket/list";
    this.ticketShareUrl = IAM_API_BASE_URL_2 + "/ticket/share";
    this.ticketDownloadUrl = IAM_API_BASE_URL_2 + "/ticket/download";
    this.ticketDetailUrl = IAM_API_BASE_URL_2 + "/ticket/detail";
  }

  componentDidMount() {
    if (document.getElementById("getHeight")) {
      this.setState({
        changeHeight: document.getElementById("getHeight").clientHeight + 60,
      });
    }

    if (this.props.location.search) {
      this.setState({ listing_loading: true });
    } else {
      this.loadListingTblData(1);
    }
    this.filterFormDataInit();
    if (this.props) {
      this.initalizeAppData(this.props);
    }

    this.customLogsheetDownload = new Modal(
      document.getElementById("customLogsheetDownload"),
      { keyboard: false, backdrop: false }
    );
    this.shareTicketModal = new Modal(
      document.getElementById("shareTicketModal"),
      { keyboard: false, backdrop: false }
    );
    this.ticketSearchModal = new Modal(
      document.getElementById("ticketSearchModal"),
      { keyboard: false, backdrop: false }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.iam_user_sites !== this.props.iam_user_sites) {
      this.initalizeAppData(nextProps);
    }
  }

  initalizeAppData = (props) => {
    this.setState({
      iam_asset_types: props.iam_asset_types
        ? props.iam_asset_types.map((at) => {
            return { value: at.id, label: at.asset_type_name };
          })
        : [],
      iam_user_sites: props.iam_user_sites
        ? props.iam_user_sites.map((s) => {
            return { value: s.id, label: `${s.site_name} (${s.site_code})` };
          })
        : [],
      allAssetTypesGroupsList:
        props.asset_groups && props.asset_groups.length > 0
          ? props.asset_groups.map((oc) => {
              return {
                value: oc.id,
                label: oc.name,
                linked_asset_types: oc.asset_type_ids,
              };
            })
          : [],
    });
  };

  setFilterOnNavigation() {
    let ParamObject = new URLSearchParams(this.props.location.search);
    let searchedAsset = null;
    if (
      ParamObject.get("search_ticket_status") ||
      ParamObject.getAll("search_site_ids[]").length > 0 ||
      ParamObject.get("search_ticket") ||
      ParamObject.get("remark_ageing_days") ||
      ParamObject.get("search_asset_id")
    ) {
      let updateFilterForm = {};

      if (ParamObject.get("search_ticket_status")) {
        let selectedStatus = this.state.allStatusList
          .filter((s) => ParamObject.get("search_ticket_status") == s.value)
          .map((s) => s.value);
        if (selectedStatus) {
          updateFilterForm["search_ticket_status"] = selectedStatus;
        }
      }
      if (ParamObject.getAll("search_site_ids[]").length > 0) {
        updateFilterForm["search_site_ids"] = new URLSearchParams(
          this.props.location.search
        ).getAll("search_site_ids[]");
      }
      if (ParamObject.get("search_ticket")) {
        updateFilterForm["search_ticket"] = ParamObject.get("search_ticket");
      }
      if (ParamObject.get("remark_ageing_days")) {
        updateFilterForm["remark_ageing_days"] =
          ParamObject.get("remark_ageing_days");
      }
      if (ParamObject.get("search_asset_id")) {
        updateFilterForm["search_asset_id"] =
          ParamObject.get("search_asset_id");
        searchedAsset = {
          value: ParamObject.get("search_asset_id"),
          display_label: ParamObject.get("asset_name"),
        };
      }

      setTimeout(() => {
        this.setState(
          {
            ticketSearchForm: {
              ...this.state.ticketSearchForm,
              ...updateFilterForm,
            },
            searchedAsset: searchedAsset,
          },
          function () {
            this.submitTicketSearchForm();
          }
        );
      }, 1);
    } else if (ParamObject.get("ticket_id")) {
      this.loadListingTblData(1);
      this.viewTicket(ParamObject.get("ticket_id"));
    }
  }

  //for add ticket screen
  openCreateTicketScreen = () => {
    this.setState({
      createTicket: !this.state.createTicket,
      ticketBoard: false,
    });
    // this.props.history.push('/ticket/add')
  };
  ticketBoardScreen = () => {
    this.setState({
      switchTicketBoard:
        this.state.switchTicketBoard === "Switch to Ticket List"
          ? "Switch to Ticket Board"
          : "Switch to Ticket List",
      createTicket: false,
      ticketBoard: !this.state.ticketBoard,
    });
  };

  afterAddTicketSubmit = () => {
    //Item is Added Successfully Need to refresh and close Add form
    this.setState({ ticketAddScreenView: false });
    //Load Add Item Form Data :-
    this.ticketSearchClear();
  };

  downloadTicketHandler = (activity_id) => {
    let ticketData = this.state.ticket_view_data;
    let setting = ticketData && ticketData.setting ? ticketData.setting : null;

    const customDownloadCriteria = this.state.customDownloadCriteria.map(
      (v) => {
        const key = v.setting_key;
        const settingValue = setting && setting[key] ? setting[key] : null;
        if (settingValue == "N") {
          return { ...v, value: false };
        } else {
          return v; // Keep the existing value if not 'N'
        }
      }
    );
    this.setState({
      customDownloadCriteria:
        customDownloadCriteria && customDownloadCriteria.length > 0
          ? customDownloadCriteria.filter((cd) => cd.value !== false)
          : [],
    });

    const containsN = setting
      ? Object.values(setting).some((value) => value.includes("Y"))
      : false;

    if (containsN) {
      this.customLogsheetDownload.show();
    } else {
      this.setState({ ticketDownloading: true });

      // Make a GET request to download the file using HttpAPICall
      HttpAPICall.withAthorization(
        "GET",
        this.ticketDownloadUrl + "/" + activity_id,
        this.props.access_token,
        {},
        {},
        (response) => {
          download.file(response.data.file_path);
        }
      ).then(() => this.setState({ ticketDownloading: false }));
    }
  };

  handleOnChangeCriteria = (event, index) => {
    const values = [...this.state.customDownloadCriteria];
    values[index].value = event.target.checked;
    this.setState({ customDownloadCriteria: values });
  };

  submitcustomDownloadForm = (e) => {
    e.preventDefault();
    let downloadCriteria = this.state.customDownloadCriteria.filter(
      (download) => download.value == true
    );
    let selectedColumn = [];
    if (downloadCriteria && downloadCriteria.length > 0) {
      selectedColumn = downloadCriteria.map((dc) => {
        return { [dc.key]: dc.value == true ? "Y" : "N" };
      });
    }

    this.setState({ file_downloading: true, saveFormSubmitting: true });
    let params = {
      download: "download",
      ...selectedColumn.reduce((acc, obj) => ({ ...acc, ...obj }), {}),
    };
    HttpAPICall.withAthorization(
      "GET",
      this.ticketDownloadUrl + "/" + this.state.ticket_view_data.activity_id,
      this.props.access_token,
      { ...params },
      {},
      (response) => {
        download.file(response.data.file_path);
        this.customLogsheetDownload.hide();
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  resetDownloadCriteriaForm = () => {
    this.setState({
      customDownloadCriteria: this.state.customDownloadCriteria.map((v) => ({
        ...v,
        value: true,
      })),
    });
  };

  closeTicketView = () => {
    this.setState({ ticketAddScreenView: false, minimizeTable: false });
  };

  changeAssetStatusModalInit = () => {
    this.ChangeAssetStatusModalRef.current.changeStatusModalInit(
      this.state.assetId
    );
  };

  projectDetail = (id) => {
    this.setState({ project_detail_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.projectUrl + "/" + id,
      this.props.access_token,
      null,
      null,
      (resp) => {
        this.setState({
          projectDetails: resp.data.data,
        });
      }
    ).then(() => this.setState({ project_detail_loading: false }));
  };

  loadListingTblData = (page = 1, ticket_id = null) => {
    let pr = this.props;
    this.setState({
      listing_loading: ticket_id ? false : true,
      listing_tbl_page: page,
    });
    let projectId = "";
    if (pr && pr.match && pr.match.params && pr.match.params.id) {
      projectId = pr.match.params.id;
      this.projectDetail(projectId);
    }
    let params = {
      ...this.state.submittedTicketFilterForm,
      search_project_id: projectId,
      page: page,
      orderby: "project",
    };
    HttpAPICall.withAthorization(
      "GET",
      this.ticketListUrl,
      this.props.access_token,
      params,
      {},
      (resp) => {
        let respData = resp.data;
        let updatedListing = ticket_id
          ? this.state.ticket_listing.map((item) =>
              item.activity_id === ticket_id
                ? respData.data.find(
                    (dataItem) => dataItem.activity_id === ticket_id
                  ) || item
                : item
            )
          : respData.data;
        this.setState({
          submittedTicketFilterForm: {
            ...this.state.submittedTicketFilterForm,
            search_project_id: projectId,
          },
          ticket_listing: updatedListing,
          listingMeta: respData.meta,
          totalListingCount: respData.meta.total,
        });
      }
    ).then(() => this.setState({ listing_loading: false }));
  };

  //function for filter form data
  filterFormDataInit = () => {
    this.setState({ form_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      IAM_API_BASE_URL_2 + "/ticket/list_form_data",
      this.props.access_token,
      {},
      {},
      (resp) => {
        this.setState({
          filterFormData: resp.data,
          allTicketType: resp.data.ticket_types?.map((s) => {
            return { value: s.id, label: s.name };
          }),
          allTaggedUsers: resp.data.users?.map((s) => {
            return { value: s.id, label: s.full_name };
          }),
          allStatusList: resp.data.status_list?.map((s) => {
            return { value: s.key, label: s.name };
          }),
          ticketTags:
            resp.data.tags && resp.data.tags.length > 0
              ? resp.data.tags.map((tag) => {
                  return { value: tag.name, label: tag.name };
                })
              : [],
          ticket_allow_tag:
            resp.data &&
            resp.data.setting &&
            resp.data.setting.ticket_allow_tag,
          ticket_allow_employees:
            resp.data &&
            resp.data.setting &&
            resp.data.setting.ticket_allow_employees,
        });
        if (this.state.filterLoading === false) {
          this.setFilterOnNavigation();
        }
      }
    ).then(() => {
      this.setState({ form_loading: false });
    });
  };

  ticketSearchClear = () => {
    this.setState(
      {
        formSearchedElems: [],
        searchedAsset: null,
        ticketSearchForm: { ...this.ticketSearchFormInitialState },
        submittedTicketFilterForm: { ...this.ticketSearchFormInitialState },
      },
      () => {
        this.loadListingTblData(1);
        this.ticketSearchModal.hide();
        const pr = this.props;
        if (pr && pr.match && pr.match.params && pr.match.params.id) {
          this.props.history.push("/project/" + pr.match.params.id);
        }
      }
    );
  };

  allTicketOptions = [
    { value: "my_ticket", label: "My Ticket (Created by me)" },
    { value: "my_tagged_tickets", label: " My Tagged Tickets" },
  ];

  ticketSearchModalJsx = () => {
    return (
      <div className="modal fade" id="ticketSearchModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchAssetModalLabel">
                Ticket Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.form_loading ? (
              <Loader />
            ) : (
              <form
                id="ticketSearchForm"
                onSubmit={this.submitTicketSearchForm}
              >
                <div className="modal-body">
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        placeholder="All Tickets"
                        containerHeight="30px"
                        fontSize="93%"
                        options={this.allTicketOptions}
                        value={this.allTicketOptions.find(
                          (r) =>
                            r.value ===
                            this.state.ticketSearchForm.search_ticket
                        )}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_ticket"
                          )
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket Type
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select Ticket Type"
                        containerHeight="30px"
                        fontSize="93%"
                        options={this.state.allTicketType}
                        value={this.state.allTicketType.find(
                          (r) =>
                            r.value ===
                            this.state.ticketSearchForm.search_ticket_type_id
                        )}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_ticket_type_id"
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm">
                      Ticket ID
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="search_ticket_id"
                        className="form-control form-control-sm"
                        placeholder="Ticket ID"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.search_ticket_id}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket Header
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        name="search_ticket_header"
                        className="form-control form-control-sm"
                        placeholder="Ticket Header"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.search_ticket_header}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm">
                      Ticket Status
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        options={this.state.allStatusList}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_ticket_status"
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allStatusList.filter((s) =>
                          this.state.ticketSearchForm.search_ticket_status.includes(
                            s.value
                          )
                        )}
                        placeholder="Select Ticket Status"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Asset
                    </label>
                    <div className="col-sm-4">
                      <InputAssetSearch
                        placeholder="Search Asset"
                        changeEvent={(selectedOption) => {
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_asset_id"
                          );
                          this.setState({
                            searchedAsset: selectedOption
                              ? selectedOption
                              : null,
                          });
                        }}
                        value={
                          this.state.searchedAsset
                            ? {
                                ...this.state.searchedAsset,
                                label: this.state.searchedAsset.display_label,
                              }
                            : null
                        }
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Site Name
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        options={this.state.iam_user_sites}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_site_ids"
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.iam_user_sites.filter((s) =>
                          this.state.ticketSearchForm.search_site_ids.includes(
                            s.value
                          )
                        )}
                        placeholder="Select Site"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm ">
                      Ticket With Asset
                    </label>
                    <div className="col-sm-4">
                      <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Ticket With Asset"
                        containerHeight="30px"
                        fontSize="93%"
                        options={this.state.searchTicketOptions}
                        value={this.state.searchTicketOptions.find(
                          (r) =>
                            r.value ===
                            this.state.ticketSearchForm.ticket_with_asset
                        )}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "ticket_with_asset"
                          )
                        }
                      />
                    </div>

                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Ticket Employee
                    </label>
                    <div className="col-sm-4 mt-3">
                      <InputEmployeeSearch
                        placeholder="Search Employee"
                        changeEvent={(selectedOption) => {
                          this.setState({
                            ticketEmployee: selectedOption
                              ? selectedOption
                              : "",
                          });
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_employee_id"
                          );
                        }}
                        menuPlacement="top"
                        value={
                          this.state.ticketEmployee && this.state.ticketEmployee
                        }
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Workstation
                    </label>
                    <div className="col-sm-4 mt-3">
                      <InputWorkstationSearch
                        placeholder="Search Workstation"
                        changeEvent={(selectedOption) => {
                          this.setState({
                            searchedWorkstation: selectedOption
                              ? selectedOption
                              : "",
                          });
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_workstation"
                          );
                        }}
                        menuPlacement="top"
                        isMulti={false}
                        value={
                          this.state.searchedWorkstation &&
                          this.state.searchedWorkstation
                        }
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Sub Assembly
                    </label>
                    <div className="col-sm-4 mt-3">
                      <InputSubAssemblySearch
                        placeholder="Search Sub Assembly"
                        changeEvent={(selectedOption) => {
                          this.setState({
                            searchedSubAssembly: selectedOption
                              ? selectedOption
                              : "",
                          });
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_sub_assembly"
                          );
                        }}
                        menuPlacement="top"
                        isMulti={false}
                        value={
                          this.state.searchedSubAssembly &&
                          this.state.searchedSubAssembly
                        }
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Asset Type Group
                    </label>
                    <div className="col-sm-4 mt-3">
                      <TapSelect
                        options={this.state.allAssetTypesGroupsList}
                        changeEvent={(selectedOption) => {
                          this.setState({
                            ticketSearchForm: {
                              ...this.state.ticketSearchForm,
                              asset_group_ids:
                                selectedOption && selectedOption.length > 0
                                  ? selectedOption.map((o) => o.value)
                                  : [],
                              search_asset_type:
                                selectedOption && selectedOption.length > 0
                                  ? selectedOption
                                      .map((s) => s.linked_asset_types)
                                      .flat()
                                  : [],
                            },
                          });
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allAssetTypesGroupsList.filter((s) =>
                          this.state.ticketSearchForm.asset_group_ids.includes(
                            s.value
                          )
                        )}
                        placeholder="Select Asset Type Group"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm  mt-3">
                      Asset Type
                    </label>
                    <div className="col-sm-4 mt-3">
                      <TapSelect
                        options={this.state.iam_asset_types}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_asset_type"
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.iam_asset_types.filter((s) =>
                          this.state.ticketSearchForm.search_asset_type.includes(
                            s.value
                          )
                        )}
                        placeholder="Select Asset Type"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Tagged User
                    </label>
                    <div className="col-sm-4 mt-3">
                      <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Select User"
                        containerHeight="30px"
                        fontSize="93%"
                        isMulti={true}
                        options={this.state.allTaggedUsers}
                        value={this.state.allTaggedUsers.filter((s) =>
                          this.state.ticketSearchForm.search_tagged_users.includes(
                            s.value
                          )
                        )}
                        changeEvent={(selectedOption) =>
                          this.tapSelectChange(
                            selectedOption,
                            "ticketSearchForm",
                            "search_tagged_users"
                          )
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Ticket Ageing
                    </label>
                    <div className="col-sm-4 mt-3">
                      <input
                        type="text"
                        name="search_ageing_days"
                        className="form-control form-control-sm"
                        placeholder="Example. 0-5 for a range or 4 for Ticket Aging"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.search_ageing_days}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Creation Date
                    </label>
                    <div className="col-sm-4 mt-3">
                      <PeriodSelectionWithCustom
                        periods={
                          this.state.filterFormData &&
                          this.state.filterFormData.periods !== undefined
                            ? this.state.filterFormData.periods
                            : []
                        }
                        required={false}
                        value={this.state.ticketSearchForm.creation_date_range}
                        startDate={
                          this.state.ticketSearchForm.creation_date_range_start
                        }
                        endDate={
                          this.state.ticketSearchForm.creation_date_range_end
                        }
                        onSelectPeriod={(period, startDate, endDate) => {
                          this.setState({
                            ticketSearchForm: {
                              ...this.state.ticketSearchForm,
                              creation_date_range: period,
                              creation_date_range_start: startDate,
                              creation_date_range_end: endDate,
                            },
                          });
                        }}
                        className="form-control-sm"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Completion Date
                    </label>
                    <div className="col-sm-4 mt-3">
                      <PeriodSelectionWithCustom
                        periods={
                          this.state.filterFormData &&
                          this.state.filterFormData.periods !== undefined
                            ? this.state.filterFormData.periods
                            : []
                        }
                        required={false}
                        value={this.state.ticketSearchForm.closure_date_range}
                        startDate={
                          this.state.ticketSearchForm.closure_date_range_start
                        }
                        endDate={
                          this.state.ticketSearchForm.closure_date_range_end
                        }
                        onSelectPeriod={(period, startDate, endDate) => {
                          this.setState({
                            ticketSearchForm: {
                              ...this.state.ticketSearchForm,
                              closure_date_range: period,
                              closure_date_range_start: startDate,
                              closure_date_range_end: endDate,
                            },
                          });
                        }}
                        className="form-control-sm"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm   mt-3">
                      Due Date
                    </label>
                    <div className="col-sm-4 mt-3">
                      <PeriodSelectionWithCustom
                        periods={
                          this.state.filterFormData &&
                          this.state.filterFormData.periods !== undefined
                            ? this.state.filterFormData.periods
                            : []
                        }
                        required={false}
                        value={this.state.ticketSearchForm.due_date_range}
                        startDate={
                          this.state.ticketSearchForm.due_date_range_start
                        }
                        endDate={this.state.ticketSearchForm.due_date_range_end}
                        onSelectPeriod={(period, startDate, endDate) => {
                          this.setState({
                            ticketSearchForm: {
                              ...this.state.ticketSearchForm,
                              due_date_range: period,
                              due_date_range_start: startDate,
                              due_date_range_end: endDate,
                            },
                          });
                        }}
                        className="form-control-sm"
                        containerHeight="30px"
                        fontSize="93%"
                      />
                    </div>
                    <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                      Remark Ageing
                    </label>
                    <div className="col-sm-4 mt-3">
                      <input
                        type="text"
                        name="remark_ageing_days"
                        className="form-control form-control-sm"
                        placeholder="Example. 0-5 for a range or 4 for Remark Aging"
                        autoComplete="off"
                        value={this.state.ticketSearchForm.remark_ageing_days}
                        onChange={(e) =>
                          this.formInputHandler(e, "ticketSearchForm")
                        }
                      />
                    </div>
                    {this.state.ticket_allow_tag == "Y" && (
                      <Ax>
                        <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                          Tags
                        </label>
                        <div className="col-sm-4 mt-3 mt-3">
                          <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Tags"
                            containerHeight="30px"
                            fontSize="93%"
                            isMulti={true}
                            options={this.state.ticketTags}
                            value={this.state.ticketTags.filter(
                              (s) =>
                                this.state.ticketSearchForm.search_tags &&
                                this.state.ticketSearchForm.search_tags.includes(
                                  s.value
                                )
                            )}
                            changeEvent={(selectedOption) =>
                              this.tapSelectChange(
                                selectedOption,
                                "ticketSearchForm",
                                "search_tags"
                              )
                            }
                          />
                        </div>
                      </Ax>
                    )}
                    {this.state.ticket_allow_employees == "Y" && (
                      <Ax>
                        <label className="col-sm-2 col-form-label col-form-label-sm mt-3">
                          Linked Employees
                        </label>
                        <div className="col-sm-4 mt-3">
                          <InputEmployeeSearch
                            placeholder="Search Employees"
                            changeEvent={(selectedOption) => {
                              this.setState({
                                searchedEmployees:
                                  selectedOption && selectedOption.length > 0
                                    ? selectedOption
                                    : [],
                              });
                              this.tapSelectChange(
                                selectedOption,
                                "ticketSearchForm",
                                "search_employees"
                              );
                            }}
                            menuPlacement="top"
                            isMulti={true}
                            value={
                              this.state.searchedEmployees &&
                              this.state.searchedEmployees.length > 0
                                ? this.state.searchedEmployees.map((a) => {
                                    a.label = a.label;
                                    return a;
                                  })
                                : []
                            }
                            containerHeight="30px"
                            fontSize="93%"
                          />
                        </div>
                      </Ax>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={this.ticketSearchClear}
                  >
                    Clear
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  ticketSearchModalInit = () => {
    this.ticketSearchModal.show();
    this.setState({ filterLoading: true });
  };

  submitTicketSearchForm = (e = null) => {
    e && e.preventDefault();
    let serachFormKeys = Object.keys(this.state.ticketSearchForm);

    let searchedElems = [];
    let searchParams = {};
    serachFormKeys.map((key) => {
      let label = this.ticketSearchFormLabel[key];
      let value = this.state.ticketSearchForm[key];
      if (value !== null && value.toString().length > 0) {
        searchParams[key] = value;
        if (label) {
          let show_val = value;
          if (key == "search_asset_id") {
            show_val = this.state.searchedAsset
              ? this.state.searchedAsset.display_label
              : "";
          }
          if (key == "search_sub_assembly") {
            show_val = this.state.searchedSubAssembly
              ? this.state.searchedSubAssembly.label
              : "";
          }
          if (key == "search_employee_id") {
            show_val = this.state.ticketEmployee
              ? this.state.ticketEmployee.label
              : "";
          }
          if (key == "search_workstation") {
            show_val = this.state.searchedWorkstation
              ? this.state.searchedWorkstation.label
              : "";
          }
          if (key == "search_employees") {
            show_val =
              this.state.searchedEmployees &&
              this.state.searchedEmployees.length > 0
                ? this.state.searchedEmployees
                    .map((emp, i) => emp.label)
                    .join(", ")
                : "";
          }
          if (key == "search_tags") {
            show_val = this.state.ticketTags
              .filter(
                (s) =>
                  this.state.ticketSearchForm.search_tags &&
                  this.state.ticketSearchForm.search_tags.includes(s.value)
              )
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_site_ids") {
            show_val = this.state.iam_user_sites
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ticket_type_id") {
            show_val = this.state.allTicketType
              .filter((s) => value === s.value)
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ticket_header") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.search_ticket_header
              : "";
          }
          if (key == "search_ticket_id") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.search_ticket_id
              : "";
          }
          if (key == "asset_group_ids") {
            show_val = this.state.allAssetTypesGroupsList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_asset_type") {
            show_val = this.state.iam_asset_types
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ageing_days") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.search_ageing_days
              : "";
          }
          if (key == "closure_date_range") {
            show_val = this.state.filterFormData.periods
              .filter((s) => value.includes(s.key))
              .map((s) => s.display_with_date);
          }
          if (key == "creation_date_range") {
            show_val = this.state.filterFormData.periods
              .filter((s) => value.includes(s.key))
              .map((s) => s.display_with_date);
          }
          if (key == "due_date_range") {
            show_val = this.state.filterFormData.periods
              .filter((s) => value.includes(s.key))
              .map((s) => s.display_with_date);
          }
          if (key == "remark_ageing_days") {
            show_val = this.state.ticketSearchForm
              ? this.state.ticketSearchForm.remark_ageing_days
              : "";
          }
          if (key == "search_tagged_users") {
            show_val = this.state.allTaggedUsers
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_ticket") {
            show_val = this.allTicketOptions
              .filter((s) => value.includes(s.value))
              .map((s) => s.label);
          }
          if (key == "search_ticket_status") {
            show_val = this.state.allStatusList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "ticket_with_asset") {
            show_val = this.state.searchTicketOptions
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }

          searchedElems.push(label + `<b>${show_val}</b>`);
        }
      }
    });
    this.setState(
      {
        submittedTicketFilterForm: { ...this.state.ticketSearchForm },
        formSearchedElems: searchedElems,
      },
      () => {
        this.ticketSearchModal.hide();
        this.loadListingTblData();
      }
    );
  };

  getAssetCard = (id) => {
    let Id = id ? id : this.state.assetId;
    this.setState({ card_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      process.env.REACT_APP_IAM_API_SERVER + "/asset/" + Id,
      this.props.access_token,
      {},
      {},
      (resp) => {
        this.setState({ asset_card_details: resp.data.data });
      }
    ).then(() => this.setState({ card_loading: false }));
  };

  getTicketViewData = (id) => {
    this.setState({ view_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.ticketDetailUrl + "/" + id,
      this.props.access_token,
      {
        need_total_expense: "Y",
        need_ticket_allocation: "Y",
        need_ticket_consumption: "Y",
        need_parent_ticket: "Y",
        need_child_tickets: "Y",
      },
      {},
      (resp) => {
        let respData = resp.data;
        this.setState(
          {
            ticket_view_data: respData.data,
            ticketHeader: respData.data.header,
            assetId:
              respData.data && respData.data.asset
                ? respData.data.asset.asset_id
                : null,
          },
          () => {}
        );
      }
    ).then(() => this.setState({ view_loading: false }));
  };

  viewTicket = (id) => {
    this.setState({
      minimizeTable: true,
      curent_activity_id: id,
      showProjectCard: false,
    });
    this.getTicketViewData(id);
  };

  afterSubmitEditTicketHandler = (id) => {
    this.loadListingTblData(1);
    this.viewTicket(this.state.curent_activity_id);
  };

  //jsx for share

  getShareEmailFormData = (id) => {
    this.setState({ share_email_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      this.ticketShareUrl + "/form_data/" + id,
      this.props.access_token,
      {},
      {},
      (resp) => {
        let options = resp.data.users
          .filter((s) => s.email !== "")
          .map((s) => {
            return { value: s.email, label: `${s.full_name} (${s.email})` };
          });
        this.setState({
          options: options,
          shareEmailForm: {
            ...this.state.shareEmailForm,
            subject: `Ticket - ${this.state.ticket_view_data.activity_id} - ${this.state.ticket_view_data.header} Status Update`,
          },
        });
      }
    ).then(() => this.setState({ share_email_loading: false }));
  };

  shareTicketModalInit = () => {
    this.shareTicketModal.show();
    this.getShareEmailFormData(this.state.ticket_view_data.activity_id);
  };

  handleEmailChange = (selectedOption) => {
    this.setState({
      shareEmailForm: {
        ...this.state.shareEmailForm,
        to_emails: selectedOption,
      },
    });
  };

  handleEmailCreate = (inputText) => {
    const newValue = { value: inputText.toLowerCase(), label: inputText };
    this.setState({ options: [...this.state.options, newValue] });
    let obj = {};
    obj["value"] = newValue.value;
    obj["label"] = newValue.label;
    this.state.shareEmailForm.to_emails.push(obj);
  };

  shareTicketModalJsx = () => {
    return (
      <div className="modal fade" id="shareTicketModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addAssetModalLabel">
                Share Ticket{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.share_email_loading ? (
              <Loader />
            ) : (
              <form id="addModalForm" onSubmit={this.submitShareDocument}>
                <div className="modal-body">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <label className="form-label require">To (Email)</label>
                    </div>
                    <div className="col-md-8">
                      <CreatableSelect
                        isMulti
                        required={true}
                        value={this.state.shareEmailForm.to_emails}
                        options={this.state.options}
                        onChange={(selectedItem) =>
                          this.handleEmailChange(selectedItem)
                        }
                        onCreateOption={(inputText, callback) =>
                          this.handleEmailCreate(inputText, callback)
                        }
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-md-4">
                      <label className="form-label require">Subject</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        name="subject"
                        type="text"
                        value={this.state.shareEmailForm.subject}
                        className="form-control text-capitalize"
                        autoComplete="off"
                        required={true}
                        onChange={(e) =>
                          this.formInputHandler(e, "shareEmailForm")
                        }
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                  <div className="row  mt-3">
                    <div className="col-md-4">
                      <label className="form-label require">Message</label>
                    </div>
                    <div className="col-md-8">
                      <textarea
                        name="message"
                        placeholder="Enter Message"
                        className="form-control"
                        value={this.state.shareEmailForm.message}
                        autoComplete="off"
                        required={true}
                        onChange={(e) =>
                          this.formInputHandler(e, "shareEmailForm")
                        }
                        style={{ height: "100px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <div className="mt-0">
                    <input
                      name="attachment"
                      type="checkbox"
                      value="N"
                      onChange={(e) => {
                        this.formInputHandler(e, "shareEmailForm");
                        this.setState({
                          shareEmailForm: {
                            ...this.state.shareEmailForm,
                            attachment:
                              this.state.shareEmailForm.attachment == "Y"
                                ? "N"
                                : "Y",
                          },
                        });
                      }}
                      className="form-check-input "
                    />
                    <label className="form-check-label mx-2">
                      Attach Documents of the Ticket with Email
                    </label>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary mx-2"
                      disabled={this.state.saveFormSubmitting ? true : false}
                      data-bs-dismiss="modal"
                      onClick={() => {
                        this.setState({
                          shareEmailForm: { ...this.initShareEmail },
                        });
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={this.state.saveFormSubmitting ? true : false}
                    >
                      Submit{" "}
                      {this.state.saveFormSubmitting ? (
                        <TapIcon.FontAwesomeIcon
                          icon={TapIcon.faSyncAlt}
                          className="fa-spin"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };

  submitShareDocument = (e) => {
    e.preventDefault();
    this.setState({ saveFormSubmitting: true });
    let frmData = {
      activity_id: this.state.ticket_view_data.activity_id,
      to_emails: this.state.shareEmailForm.to_emails.map((s) => s.value),
      subject: this.state.shareEmailForm.subject,
      message: this.state.shareEmailForm.message,
      attachment: this.state.shareEmailForm.attachment,
    };
    HttpAPICall.withAthorization(
      "POST",
      this.ticketShareUrl,
      this.props.access_token,
      {},
      { ...frmData },
      (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.shareTicketModal.hide();
        this.setState({ shareEmailForm: { ...this.initShareEmail } });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  //function for download
  ticketDownload = () => {
    if (
      JSON.stringify(this.state.submittedTicketFilterForm) !==
      JSON.stringify(this.ticketSearchFormInitialState)
    ) {
      this.setState({ reportDownloading: true });
      let params = { ...this.state.submittedTicketFilterForm };

      HttpAPICall.withAthorization(
        "GET",
        this.ticketListUrl,
        this.props.access_token,
        { ...params, download: "download" },
        {},
        (response) => DownloadFile.file(response.data.file_path)
      ).then(() => this.setState({ reportDownloading: false }));
    } else {
      swal({
        title: "Download",
        text: "The download will be for trailing three months period, in case you want a different criteria then please use search button before downloading.",
        icon: "warning",
        // dangerMode: true,
        buttons: ["Cancel", "Okay"],
      }).then((willDownload) => {
        if (willDownload) {
          this.setState({ reportDownloading: true });
          let params = { creation_date_range: "current_quarter" };
          HttpAPICall.withAthorization(
            "GET",
            this.ticketListUrl,
            this.props.access_token,
            { ...params, download: "download" },
            {},
            (response) => DownloadFile.file(response.data.file_path)
          ).then(() => this.setState({ reportDownloading: false }));
        }
      });
    }
  };

  addToAllocation = () => {
    let ticketData = this.state.ticket_view_data
      ? this.state.ticket_view_data
      : null;
    let asset = ticketData && ticketData.asset ? ticketData.asset : "";
    let site_id = ticketData && ticketData.site ? ticketData.site.id : "";
    let obj = {
      asset: asset,
      site_id: site_id,
      ticket_header: ticketData.header,
    };
    localStorage.setItem("selectedTicketToAdd", JSON.stringify(obj));
  };

  updateTicketModal = (id, addFormState = "detail") => {
    this.ticketDetailRef.current.updateTicketModalInit(id, addFormState);
  };

  addDocumentModal = (id) => {
    this.ticketDetailRef.current.addDocumentModalInit(id);
  };

  addRemarkModal = (id) => {
    this.ticketDetailRef.current.addRemarkInit(id);
  };

  ticketExpenseHandler = (id) => {
    this.ticketDetailRef.current.ticketExpenseHandle();
  };

  customDownloadFormJsx = () => {
    return (
      <div
        className="modal fade right"
        id="customLogsheetDownload"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchAssetModalLabel">
                Select Columns for Excel Download
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={this.submitcustomDownloadForm}
                id="addCustomDownloadForm"
              >
                <div className="tab_content_wrapper my-2">
                  <span className="content_heading">Select Columns </span>
                </div>
                <div className="row my-3">
                  {this.state.customDownloadCriteria &&
                    this.state.customDownloadCriteria.length > 0 &&
                    this.state.customDownloadCriteria.map((criteria, index) => {
                      return (
                        <Ax>
                          <div className="col-sm-1" key={index + 1}>
                            <input
                              name={criteria.key}
                              type="checkbox"
                              value={criteria.value}
                              checked={criteria.value == true ? true : false}
                              className="form-check-input"
                              id={criteria.key}
                              onChange={(e) =>
                                this.handleOnChangeCriteria(e, index)
                              }
                            />
                          </div>
                          <label
                            className="col-sm-5 col-form-label col-form-label-sm"
                            htmlFor={criteria.key}
                          >
                            {criteria.label}
                          </label>
                        </Ax>
                      );
                    })}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                disabled={this.state.saveFormSubmitting}
                onClick={this.resetDownloadCriteriaForm}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={this.state.saveFormSubmitting}
                form="addCustomDownloadForm"
              >
                Submit{" "}
                {this.state.saveFormSubmitting ? (
                  <TapIcon.FontAwesomeIcon
                    icon={TapIcon.faSyncAlt}
                    className="fa-spin"
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  closeProjectTicket = () => {
    this.props.history.push("/project");
  };

  refreshData = () => {
    const pr = this.props;
    this.loadListingTblData();
    if (pr && pr.match && pr.match.params && pr.match.params.id) {
      const projectId = pr.match.params.id;
      this.projectDetail(projectId);
    }
  };

  ticketTransactionListingJsx = () => {
    let ticketData = this.state.ticket_view_data
      ? this.state.ticket_view_data
      : null;
    let setting = ticketData && ticketData.setting ? ticketData.setting : null;
    let projectDetail = this.state.projectDetails;

    return (
      <Ax>
        <div className="page_title row m0">
          <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
            <div className="row">
              <div
                className={`${this.state.minimizeTable ? "col-12" : "col-6"}`}
              >
                <h3>Project : {projectDetail ? projectDetail.name : "-"}</h3>
              </div>
              {!this.state.minimizeTable && (
                <div className="col-6">
                  <div className="text-end mt15">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.ticketBoardScreen}
                    >
                      {this.state.switchTicketBoard}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.openCreateTicketScreen}
                    >
                      Create Ticket
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={this.state.listing_loading}
                      onClick={this.ticketSearchModalInit}
                    >
                      <TapIcon.imageIcon
                        icon={TapIcon.SearchIcon}
                        className="img-fluid"
                        alt="item-action"
                      />
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={
                        this.state.reportDownloading ||
                        this.state.listing_loading
                      }
                      onClick={this.ticketDownload}
                    >
                      <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                      {this.state.reportDownloading ? (
                        <TapIcon.FontAwesomeIcon
                          icon={TapIcon.faSyncAlt}
                          className="fa-spin"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={this.state.listing_loading}
                      onClick={this.refreshData}
                    >
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-refresh"
                      />
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={this.closeProjectTicket}
                    >
                      <TapIcon.imageIcon
                        icon={TapIcon.CloseIcon}
                        className="img-fluid"
                        alt="item-close"
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.minimizeTable ? (
            <div className="col-9 tap_border_left">
              <div className="row">
                <div className="col-6">
                  <h3 className="text-capitalize">
                    Ticket Header : {this.state.ticketHeader.slice(0, 40)}
                    {this.state.ticketHeader.length > 40 ? "..." : ""}{" "}
                  </h3>
                </div>
                <div className="col-6">
                  <div className="text-end mt15">
                    <button
                      type="button"
                      id="actionMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="btn btn-primary"
                    >
                      Update
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end "
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className={["dropdown-item"].join(", ")}
                          role="button"
                          onClick={() => this.addRemarkModal()}
                        >
                          Add Remark
                        </a>
                      </li>
                      <li>
                        <a
                          className={["dropdown-item"].join(", ")}
                          role="button"
                          onClick={() => this.addDocumentModal()}
                        >
                          Add Document
                        </a>
                      </li>
                      {setting &&
                        setting.ticket_expense &&
                        setting.ticket_expense == "Y" &&
                        this.state.ticket_view_data &&
                        (this.state.ticket_view_data.status !== 26 ||
                          this.state.ticket_view_data !== 10) && (
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={() => this.ticketExpenseHandler()}
                            >
                              Add Expense
                            </Link>
                          </li>
                        )}
                      {setting &&
                        setting.ticket_allocation &&
                        setting.ticket_allocation == "Y" &&
                        this.state.ticket_view_data &&
                        (this.state.ticket_view_data.status !== 26 ||
                          this.state.ticket_view_data !== 10) && (
                          <li>
                            {" "}
                            <Link
                              className="dropdown-item"
                              target="_blank"
                              role="button"
                              disabled={
                                !this.props.permissions.includes(
                                  "isc-allocation-add"
                                )
                              }
                              onClick={() => this.addToAllocation()}
                              to={{
                                pathname: "/allocation_list/add",
                                search:
                                  "?" +
                                  new URLSearchParams({
                                    ticketId: ticketData.activity_id
                                      ? ticketData.activity_id
                                      : "",
                                  }).toString(),
                              }}
                            >
                              Add Allocation
                            </Link>
                          </li>
                        )}
                      {setting &&
                        setting.ticket_consumption &&
                        setting.ticket_consumption == "Y" &&
                        this.state.ticket_view_data &&
                        (this.state.ticket_view_data.status !== 26 ||
                          this.state.ticket_view_data !== 10) && (
                          <li>
                            {" "}
                            <Link
                              className="dropdown-item"
                              target="_blank"
                              role="button"
                              disabled={
                                !this.props.permissions.includes(
                                  "isc-consumption-add"
                                )
                              }
                              onClick={() => this.addToAllocation()}
                              to={{
                                pathname: "/consumption_list/add",
                                search:
                                  "?" +
                                  new URLSearchParams({
                                    ticketId: ticketData.activity_id
                                      ? ticketData.activity_id
                                      : "",
                                  }).toString(),
                              }}
                            >
                              Add Consumption
                            </Link>
                          </li>
                        )}
                    </ul>
                    <button
                      type="button"
                      id="shareTicket"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="btn btn-secondary"
                    >
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faShareAlt}
                        className="img-fluid"
                        alt="item-action"
                      />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="shareTicket"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          role="button"
                          onClick={this.shareTicketModalInit}
                        >
                          Share by Email
                        </a>
                      </li>
                    </ul>

                    {/* {this.state.ticket_view_data && this.state.ticket_view_data.status !== 26  && this.state.ticket_view_data.status !== 10 && <Ax> */}
                    <button
                      type="button"
                      id="actionMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="btn btn-secondary"
                    >
                      <TapIcon.imageIcon
                        icon={TapIcon.ActionIcon}
                        className="img-fluid"
                        alt="item-action"
                      />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end "
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          className={[
                            ticketData?.can_edit == "N"
                              ? "disabled dropdown-item"
                              : "dropdown-item",
                          ].join(", ")}
                          role="button"
                          onClick={() =>
                            this.updateTicketModal(
                              ticketData?.activity_id,
                              "detail"
                            )
                          }
                        >
                          Edit Detail
                        </a>
                      </li>
                      <li>
                        <a
                          className={[
                            ticketData?.can_edit == "N"
                              ? "disabled dropdown-item"
                              : "dropdown-item",
                          ].join(", ")}
                          role="button"
                          onClick={() =>
                            this.updateTicketModal(
                              ticketData?.activity_id,
                              "tagging_detail"
                            )
                          }
                        >
                          Edit Tagging Detail
                        </a>
                      </li>
                      <li>
                        <a
                          className={[
                            ticketData?.can_edit == "N"
                              ? "disabled dropdown-item"
                              : "dropdown-item",
                          ].join(", ")}
                          role="button"
                          onClick={() =>
                            this.updateTicketModal(
                              ticketData?.activity_id,
                              "other_detail"
                            )
                          }
                        >
                          Edit Other Detail
                        </a>
                      </li>
                    </ul>
                    {/* </Ax>} */}
                    <button
                      disabled={this.state.ticketDownloading}
                      onClick={() =>
                        this.downloadTicketHandler(
                          this.state.curent_activity_id
                        )
                      }
                      type="button"
                      className="btn btn-secondary"
                    >
                      <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                      {this.state.ticketDownloading ? (
                        <TapIcon.FontAwesomeIcon
                          icon={TapIcon.faSyncAlt}
                          className="fa-spin"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      onClick={this.closeTicketView}
                      type="button"
                      className="btn btn-secondary"
                    >
                      <TapIcon.imageIcon
                        icon={TapIcon.CloseIcon}
                        className="img-fluid"
                        alt="item-close"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="container-fluid pl5">
          <div className="page_containt row">
            {this.state.createTicket && (
              <TicketTransactionAdd
                createFromProject={true}
                openCreateTicketScreen={this.openCreateTicketScreen}
                projectDetails={this.state.projectDetails}
                ticketList={() => this.loadListingTblData(1)}
                projectDetail={projectDetail}
                projectDetailJsx={this.projectDetailJsx}
              />
            )}
            {this.state.ticketBoard && (
              <TicketsBoardManagement
                createFromProject={true}
                projectId={projectDetail?.id}
                closeBoard={this.ticketBoardScreen}
              />
            )}
            {!this.state.createTicket && !this.state.ticketBoard && (
              <Ax>
                <div
                  className={[
                    "pageTbl",
                    this.state.minimizeTable ? "col-3 " : "col-12",
                  ].join(" ")}
                >
                  {projectDetail &&
                    !this.state.minimizeTable &&
                    this.projectDetailJsx(projectDetail)}
                  {this.ticketTransactionTableListJsx()}
                </div>
                {this.state.minimizeTable ? (
                  <div className="col-9 px-0 pageTbl">
                    <div className="bg-white">
                      {this.state.view_loading ? (
                        <Loader />
                      ) : (
                        <Ax>
                          {/* {projectDetail && this.projectDetailJsx(projectDetail)} */}
                          <TicketDetail
                            ticketData={this.state.ticket_view_data}
                            showOtherDetail="Y"
                            performAction="Y"
                            afterUpdate={(ticket_id) => {
                              this.loadListingTblData(
                                this.state.listing_tbl_page,
                                ticket_id
                              );
                            }}
                            listingUpdateWithProject={this.loadListingTblData}
                            ref={this.ticketDetailRef}
                            projectDetails={this.state.projectDetails}
                          />
                        </Ax>
                      )}
                    </div>
                  </div>
                ) : null}
              </Ax>
            )}
          </div>
        </div>
      </Ax>
    );
  };

  projectDetailJsx = (projectDetail) => {
    return (
      <table className="table table-borderless bg-white">
        <tbody>
          {this.state.project_detail_loading ? (
            <Loader />
          ) : (
            <ProjectDetailCard projectDetail={projectDetail} />
          )}
        </tbody>
      </table>
    );
  };

  ticketTransactionTableListJsx = () => {
    return (
      <Ax>
        <div className="">
          <DisplaySearchCriteria
            searchedElems={this.state.formSearchedElems}
            onClearFilter={this.ticketSearchClear}
          />
          <table className="table table-bordered bg-white table-hover table-fixed table-sm">
            <thead className="table-secondary">
              {this.state.minimizeTable ? (
                <tr>
                  <th scope="col" style={{ width: "20%" }}>
                    Ticket{" "}
                  </th>
                </tr>
              ) : (
                <Ax>
                  <tr>
                    <th
                      scope="col"
                      style={{ width: "5%" }}
                      className="text-center"
                    >
                      S.No
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "10%" }}
                    >
                      Creation Date
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "25%" }}
                    >
                      Header
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "10%" }}
                    >
                      Ticket ID
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "10%" }}
                    >
                      Ticket Type
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                      Ticket For
                    </th>
                    <th scope="col" style={{ width: "10%" }}>
                      Last Updated
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "10%" }}
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "5%" }}
                    >
                      Action
                    </th>
                  </tr>
                </Ax>
              )}
            </thead>
            <tbody>
              {this.state.listing_loading ? (
                <tr>
                  <td colSpan="10">
                    <Loader />
                  </td>
                </tr>
              ) : this.state.ticket_listing.length > 0 ? (
                this.state.ticket_listing.map((item, index) => {
                  let ticketFor =
                    item.ticket_for === "sub_assembly"
                      ? item.subAssembly && item.subAssembly.name
                      : item.ticket_for === "employee"
                      ? item.employee && item.employee.name
                      : item.ticket_for === "workstation"
                      ? item.workStation && item.workStation.name
                      : item.ticket_for === "site"
                      ? item.site && item.site.site_name
                      : item.ticket_for === "asset"
                      ? item.asset && item.asset.name
                      : "";
                  return (
                    <tr key={index}>
                      {this.state.minimizeTable ? (
                        <Ax>
                          <td style={{ width: "25%" }}>
                            <div className="text-capitalize">
                              <Link
                                onClick={() =>
                                  this.viewTicket(item.activity_id)
                                }
                                className="link-primary"
                              >
                                {item?.header}
                              </Link>
                              <small className="float-end">
                                {item.activity_id}
                              </small>
                            </div>
                            <div className="mt-1">
                              <small className="text-capitalize ">
                                {item?.ticket_type?.name}{" "}
                              </small>
                              <small className="float-end">
                                <Status color={item.status_color_code}>
                                  {item.status_text}
                                </Status>
                              </small>
                            </div>
                          </td>
                        </Ax>
                      ) : (
                        <Ax>
                          <td className="text-center">{item.sr_num}</td>
                          <td>{item.created_at_date}</td>
                          <td>
                            <a
                              role="button"
                              className="link-primary text-capitalize"
                              onClick={() => this.viewTicket(item.activity_id)}
                            >
                              {item.header}
                            </a>
                          </td>
                          <td className="text-center">
                            {item.activity_id ? item.activity_id : "-"}
                          </td>
                          <td className="text-center">
                            {item.ticket_type ? item.ticket_type.name : "-"}
                          </td>
                          <td className="text-capitalize">
                            {item.ticket_for_name && (
                              <span>
                                <small>{item.ticket_for_name}</small>:<br />
                                {ticketFor}
                              </span>
                            )}
                          </td>
                          <td>
                            {item.last_updated_on_display ?? "-"} <br />
                            {item.latest_remark ? (
                              <small>{item.latest_remark?.remarks}</small>
                            ) : null}
                          </td>
                          <td className="text-center">
                            <Status color={item.status_color_code}>
                              {item.status_text}
                            </Status>
                          </td>
                          <td className="text-center">
                            <TapIcon.imageIcon
                              icon={TapIcon.ListActionIcon}
                              alt="action-icon"
                              className="img-fluid px-3"
                              data-bs-toggle="dropdown"
                              href="#"
                              role="button"
                              aria-expanded="false"
                            />
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() =>
                                    this.viewTicket(item.activity_id)
                                  }
                                >
                                  View
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  role="button"
                                  onClick={() =>
                                    this.downloadTicketHandler(item.activity_id)
                                  }
                                >
                                  Download
                                </a>
                              </li>
                            </ul>
                          </td>
                        </Ax>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <DisplayListPagination
          meta={this.state.listingMeta}
          onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
        />
      </Ax>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Project Ticket Listing</title>
        </Helmet>
        {this.ticketTransactionListingJsx()}
        {this.shareTicketModalJsx()}
        {this.ticketSearchModalJsx()}
        {this.customDownloadFormJsx()}
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    asset_groups:
      state.app && state.app.asset_groups ? state.app.asset_groups : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ProjectTickets
);
