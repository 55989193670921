import React from 'react';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import Vendor from '../includes/Vendor';
import ItemViewInList from '../includes/ItemViewInList';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import TapInputNumber from '../../../components/ui/TapInputNumber';


class PurchaseReturnAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.purReturnUrl           =   INVENTORY_API_BASE_URL_2 + '/purchase_return';
        this.warehouseUrl           =   ISC_API_BASE_URL_2  + '/warehouse';
        this.vendorUrl              =   INVENTORY_API_BASE_URL_2 + '/vendor';

        this.initPurchaseForm           =       {
            transaction_date            :       new Date(),
            warehouse_id                :       null,
            vendor_id                   :       null,
            vendor_address_id           :       null,
            bill_number                 :       '',
            bill_date                   :       '',
            e_way_bill_date             :       '',
            e_way_bill_number           :       '',
            purchase_order_number       :       '',
            purchase_order_date         :       '',
            wallet_id                   :       '',
            notes                       :      '',
            purchase_transaction_id     :       '',
            status                      :       1,
            adjustment_amount           :       ''
        }

    

        this.state                     =     {
            saveFormSubmitting          :            false,
            purchaseItemRows            :            [{item : null}],
            addPurchaseForm             :            {...this.initPurchaseForm},
            consumptionForm             :            {...this.initConsumptionForm},
            isc_configuration           :            {},
            all_warehouses              :            [],
            warehouseDetail            :             null,
            searchedItem                :            null,
            searchedVendor              :            null,
            vendorDetails               :            null,
            purchaseFormDataLoaded      :            false,
            details_loading             :            false,
            allWalletList               :            [],
            allDiscountType             :            [],
            allGstSlabs                 :            [],
            warehouse_state_id          :            null,
            vendor_state_id             :            null,
            status                      :            31,
            validationError             :            false,
            item_loading                :            false,
            itemSearchedDetails         :            null,
            gstTypeName                 :            'GST',      
            editPurchase                :            false,
            purchaseData                :            null,
            searchedVendorAdressList    :            [],
            selectedVendorAdress        :            null,
            vendorAddressList           :            [],
            vendor_details_loading      :            false,
            selectedAsset               :            null,
            consumptionFormDataLoaded   :            false,
            warehouseSites              :            [],
            consumptionFormLoading      :            false,
            selectedWorkstation         :            null,
            allWorkstations             :            [],
            itemRowNumber               :            0,
            purchaseItemRowKey          :            0,
            allPendingPOList            :            [],
            pendingPOListLoaded         :            false,
            pending_item_loading        :            false,
            savePickItemSubmitting      :            false,
            allAllocatedItemIds         :            [],
            include_req_pending_items   :            'N',
            update_item_purchase_price  :             'Y',
            update_item_consumption_price  :          'Y',
            allPOIdsList                :            [],
            searchedEmployee            :             null,
            allStatusList               :             [],
            selectedBatchData           :              null,
            list_loading                :             false,
            purchase_listing            :             [],
            purchaseReturnData          :             null,
            purchaseData                :             null,
            additionalAttributes            :   []
        };
        this.addItemModalRef            =   React.createRef();
        this.editItemModalRef           =   React.createRef();
        this.addVendorModalRef          =   React.createRef();
        this.itemSearchRef              =   React.createRef();
        this.itemDetailsRef             =   React.createRef();
    }

    componentDidMount() {
        this.initilaizeFormData(this.props)
        this.viewItemModal                         =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
        this.pickPurchaseModal                   =   new Modal(document.getElementById('pickPurchaseModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(this.props.location && this.props.location.state){
            if(nextProps.location.state !== this.props.location.state) {
                this.initilaizeFormData(nextProps)
             }  
        }
             
    }

    //*******************INITIALIZE FORM DATA************************************** */
    initilaizeFormData                  =   (pr) =>  {
        if(!this.state.purchaseFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.purReturnUrl + '/form_data', this.props.access_token, null, null, (response) => {
               let formData = response.data;
                this.setState({
                    purchaseFormDataLoaded    :    formData ? true : false,
                    allWalletList             :    formData && formData.wallets ? formData.wallets.map((s) => { return {value: s.id, label: s.wallet_name}; }) : [],
                    allDiscountType           :    formData && formData.discount_type ?  formData.discount_type.map((s) => { return {value: s, label: s === "Fixed" ? "Rs" : "%"}; }) : [],
                    allGstSlabs               :    formData && formData.gst_slabs ? formData.gst_slabs.map((s) => { return {value: s, label: s +'%' }}) : [] ,
                    allStatusList            :    formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return {value: s.id, label: s.name }}) : [] ,                 
                    all_warehouses            :    this.props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                 isc_configuration            :    this.props.isc_configuration,
                 additionalAttributes        :   formData && formData.additional_attributes && formData.additional_attributes.length > 0 ?  formData.additional_attributes : [],      
                },() => {
                    // For Edit Purchase
                    if(pr.location && pr.location.state && pr.location.state.purchaseData){
                        let purchaseData = pr.location.state.purchaseData;
                        let data = purchaseData && purchaseData.purchaseDetail ? purchaseData.purchaseDetail : null
                        
                        let purchaseItemRows = pr.location.state.purchaseData.items.map((pu_item, k) => {
                            let totalBatchQty = 0;
                            let totalReturnQty = 0;
                            
                            // Calculate total batch quantity and total return quantity
                            pu_item.batch_list.forEach(batch => {
                                totalBatchQty += batch.qty;
                                totalReturnQty += Number(batch.purchase_return_qty);
                            });
                        
                            // Calculate quantity as total batch quantity minus total return quantity
                            let qty = totalBatchQty - totalReturnQty;
                            let discount_rate = pu_item.discount_rate_vc;
                            if (pu_item.discount_type === "Fixed") {
                                // Calculate discount per item
                                let discount_per_item = pu_item.qty > 0 ? discount_rate / pu_item.qty : 0; // Avoid division by zero
                                discount_rate = parseFloat(discount_per_item).toFixed(2);
                            }
                           
                            return {
                                item                        :          {...pu_item.item,isAddRemark :  false},
                                req_allocation_loading      :           false,
                                req_allocation              :           [],
                                batchReturnQty              :           [],
                                id                          :           pu_item.item.id,
                                qty                         :           qty < 0 ? 0 : qty,
                                rate                        :           pu_item.rate_vc,
                                remark                      :           "",
                                gst                         :           pu_item.gst,
                                discount_type               :           pu_item.discount_type,
                                discount_type_display       :           pu_item.discount_type && pu_item.discount_type == "Percentage" ? "%" : pu_item.discount_type && pu_item.discount_type == "Fixed" ? "Rs" : "",
                                discount_rate               :           discount_rate,
                                po_item_id                  :           pu_item.po_item_id,
                                purchase_number             :           pu_item.po_transaction_id ,
                                req_item_id                 :           pu_item.req_item_id,
                                requisition_transaction_id  :           pu_item.requisition_transaction_id,
                                selected_allocation         :           null,
                                purchase_item_key           :           pu_item.purchase_item_key ? pu_item.purchase_item_key : "",
                                max_qty                     :           qty < 0 ? 0 : qty,
                                total_allocation_qty        :           pu_item.qty,
                                batch_list                  :           pu_item.batch_list && pu_item.batch_list.length > 0 ? pu_item.batch_list.map(be => {return({...be,batch_qty:be.qty - Number(be.purchase_return_qty),stock_id:be.stock_id,return_qty : be.qty - Number(be.purchase_return_qty),max_qty:be.purchase_return_qty ? be.qty - Number(be.purchase_return_qty)  : 0})})  :   [],
                                stock_id                    :           pu_item.stock_id ? pu_item.stock_id : null,
                                disable_rate                :           pu_item.po_item_id && this.state.purchase_po_same_unit_rate == 'Y' ? 'Y' : 'N'
                            };
                        });

                        
                       this.setState({
                            purchaseItemRows                :           purchaseItemRows,
                           
                        }, () => {

                          //  get all Previous Allocation :-
                            // this.getAllocationQuantity(purchaseData.warehouse_id, purchaseData.vendor.id,purchaseItemRows);
                        });
                        this.setState({
                            addPurchaseForm            :       {
                                ...this.state.addPurchaseForm,
                                transaction_date            :       moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD'),
                                purchase_transaction_id     :       data.transaction_id,
                                warehouse_id                :       data.warehouse_id,
                                vendor_id                   :       data.vendor.id,
                                bill_number        :       data.purchase_bill_number,
                                bill_date          :       data.purchase_bill_date ? moment(data.purchase_bill_date,'YYYY-MM-DD').format('YYYY-MM-DD') : '',
                                e_way_bill_date             :       data.e_way_bill_date ? moment(data.e_way_bill_date,'YYYY-MM-DD').format('YYYY-MM-DD') : '',
                                e_way_bill_number           :       data.e_way_bill_number,
                                purchase_order_number       :       data.purchase_order_number,
                                purchase_order_date         :       data.purchase_order_date ? moment(data.e_way_bill_date,'YYYY-MM-DD').format('YYYY-MM-DD') : '',
                                wallet_id                   :      data.wallet ? data.wallet.id : null,
                                notes                       :       data.notes,
                                status                      :       1,
                               adjustment_amount           :       '',
                            },
                            searchedVendor                 :         {value : data.vendor.id  ,display_label : data.vendor.name },
                            warehouse_state_id             :        data.warehouse_state,
                            purchaseData                    :       null,
                            purchaseData                    :       data ? data : null,
                            vendorDetails                   :      {value : data.vendor.id  ,display_label : data.vendor.name },
                            selectedVendorAdress            :      data.vendor_address ? {...data.vendor_address , gstin : data.vendor_gstin,id:data.vendor_address_id} : null,
            
                        },() => {
                            this.getWarehouseDetails(data.warehouse_id)
                            this.getVendorDetails(data.vendor.id,true)
                           // this.handleVendorChange(this.state.searchedVendor,true)
                           
                        })
                    }

                    if(pr.location && pr.location.state && pr.location.state.purchaseReturnData){
                       let data = pr.location.state.purchaseReturnData;
                       let purchaseDetail = data.purchase && data.purchase.items && data.purchase.items.length > 0 ? data.purchase.items : null;
                       let updateFormState = [];
                       let addtionalAttribute  =  data["additional_attributes_data"];
                       let addtionalAttributeData =  addtionalAttribute && addtionalAttribute.length > 0 ? addtionalAttribute.map(at => {
                           return({key : at.key,value:at.value,name:at.name})
                       }) : [];

                       // addition_attributes
                       if(addtionalAttributeData.length > 0) {
                           addtionalAttributeData.map((k) => {
                               let name =  'additional_attributes_' + k.key; 
                               updateFormState[name] = k.value
                           });
                       }
                       let purchaseItemRows = data.items.map((pu_item, k) => {
                       
                           let selectedPurchaseDetail = purchaseDetail ? purchaseDetail.find(pd => {return(pd.purchase_item_key == pu_item.purchase_item_key)}) : null;
                           let selectedBatch = null;
                           if (selectedPurchaseDetail && selectedPurchaseDetail.batch_list && pu_item.batch_list && pu_item.batch_list.length > 0) {
                               for (let i = 0; i < selectedPurchaseDetail.batch_list.length; i++) {
                                   let batch = selectedPurchaseDetail.batch_list[i];
                                   selectedBatch = pu_item.batch_list.find(be => be.purchase_stock_id === batch.stock_id);
                                   if (selectedBatch) {
                                    break;
                                }
                               }
                           }

                           let discount_rate = selectedPurchaseDetail && selectedPurchaseDetail.discount_rate_vc ? selectedPurchaseDetail.discount_rate_vc : 0;
                           if (selectedPurchaseDetail && selectedPurchaseDetail.discount_type === "Fixed") {
                               // Calculate discount per item
                               let discount_per_item = selectedPurchaseDetail.qty > 0 ? discount_rate / selectedPurchaseDetail.qty : 0; // Avoid division by zero
                               discount_rate = parseFloat(discount_per_item).toFixed(2);
                           }

                           let totalBatchQty = 0;
                           let totalReturnQty = 0;
                           let totalPurchaseReturnQty   =   0;
                           // Calculate total batch quantity and total return quantity
                          if(selectedPurchaseDetail && selectedPurchaseDetail.batch_list && selectedPurchaseDetail.batch_list.length > 0){
                            selectedPurchaseDetail.batch_list.forEach(batch => {
                                totalBatchQty += batch.qty;
                                totalReturnQty += Number(batch.purchase_return_qty);
                            });
                          }

                          if(pu_item && pu_item.batch_list && pu_item.batch_list.length > 0){
                            pu_item.batch_list.forEach(batch => {
                               totalPurchaseReturnQty += Number(batch.qty);
                            });
                          }
                       
                           // Calculate quantity as total batch quantity minus total return quantity
                           let max_qty = (totalBatchQty - totalReturnQty) + totalPurchaseReturnQty;
                         
                          
                           return {
                                item                        :          {...pu_item.item,isAddRemark :  false},
                                req_allocation_loading      :           false,
                                req_allocation              :           [],
                                batchReturnQty              :           [],
                                id                          :           pu_item.item.id,
                                qty                         :           totalPurchaseReturnQty,
                                rate                        :           pu_item.rate_vc,
                                remark                      :           "",
                                gst                         :           pu_item.gst,
                                discount_type               :           pu_item.discount_type,
                                discount_type_display       :           pu_item.discount_type && pu_item.discount_type == "Percentage" ? "%" : pu_item.discount_type && pu_item.discount_type == "Fixed" ? "Rs" : "",
                                discount_rate               :           discount_rate,
                                purchase_item_key           :           pu_item.purchase_item_key ? pu_item.purchase_item_key : "",
                                po_item_id                  :           selectedPurchaseDetail && selectedPurchaseDetail.po_item_id ? selectedPurchaseDetail.po_item_id : "",
                                purchase_number             :           selectedPurchaseDetail && selectedPurchaseDetail.po_transaction_id ? selectedPurchaseDetail.po_transaction_id : '' ,
                                req_item_id                 :           selectedPurchaseDetail && selectedPurchaseDetail.req_item_id ? pu_item.req_item_id : '',
                                requisition_transaction_id  :           selectedPurchaseDetail && selectedPurchaseDetail.requisition_transaction_id ? selectedPurchaseDetail.requisition_transaction_id : '' ,
                                selected_allocation         :           null,
                                max_qty                     :           max_qty,
                                total_allocation_qty        :           totalBatchQty,
                                batch_list                  :           selectedPurchaseDetail && selectedPurchaseDetail.batch_list && selectedPurchaseDetail.batch_list.length > 0 ? selectedPurchaseDetail.batch_list.map(be => {
                                                                        let selectedReturnDetail = pu_item.batch_list && pu_item.batch_list.length > 0 
                                                                        ? pu_item.batch_list.find(batch => batch.purchase_stock_id == be.stock_id) 
                                                                        : null;
                                                                        let max_batch_qty = be.purchase_return_qty && selectedReturnDetail && pu_item.batch_list.find(batch => batch.purchase_stock_id == be.stock_id)  ? be.qty - Number(be.purchase_return_qty)  +  Number(selectedReturnDetail.qty) : 0;
                                                                        return({
                                                                            ...be,
                                                                            batch_qty:max_batch_qty,
                                                                            stock_id:be.stock_id,
                                                                            max_qty:max_batch_qty,
                                                                            return_qty :selectedReturnDetail && pu_item.batch_list.find(batch => batch.purchase_stock_id == be.stock_id) ? Number(selectedReturnDetail.qty) :  0,
                                                                        })})  :   [],
                                stock_id                    :           pu_item.stock_id ? pu_item.stock_id : null,
                                disable_rate                :           pu_item.po_item_id && this.state.purchase_po_same_unit_rate == 'Y' ? 'Y' : 'N'
                            };
                        });

                        
                       this.setState({
                            purchaseItemRows                :           purchaseItemRows,
                           
                        }, () => {

                          //  get all Previous Allocation :-
                            // this.getAllocationQuantity(purchaseData.warehouse_id, purchaseData.vendor.id,purchaseItemRows);
                        });
                        this.setState({
                            addPurchaseForm            :       {
                                ...updateFormState,
                                ...this.state.addPurchaseForm,
                                transaction_date            :       moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD'),
                                purchase_transaction_id     :       data.purchase_transaction_id,
                                transaction_id              :       data.transaction_id,
                                warehouse_id                :       data.warehouse ? data.warehouse.id : '',
                                vendor_id                   :       data.vendor.id,
                                bill_number        :       data.bill_number,
                                bill_date          :       data.bill_date ? moment(data.bill_date,'YYYY-MM-DD').format('YYYY-MM-DD') : '',
                                e_way_bill_date             :       data.e_way_bill_date ? moment(data.e_way_bill_date,'YYYY-MM-DD').format('YYYY-MM-DD') : '',
                                e_way_bill_number           :       data.e_way_bill_number,
                                purchase_order_number       :       data.purchase_order_number,
                                purchase_order_date         :       data.purchase_order_date ? moment(data.e_way_bill_date,'YYYY-MM-DD').format('YYYY-MM-DD') : '',
                                wallet_id                   :      data.wallet ? data.wallet.id : null,
                                notes                       :       data.notes,
                                status                      :       1,
                               adjustment_amount           :       data.adjustment_amount,
                            },
                            searchedVendor                 :         {value : data.vendor.id  ,display_label : data.vendor.name },
                            warehouse_state_id             :        data.warehouse_state,
                            purchaseReturnData                    :       data,
                            purchaseData                    :      data && data.purchase ? data.purchase : null,
                            vendorDetails                   :      {value : data.vendor.id  ,display_label : data.vendor.name },
                            selectedVendorAdress            :      data.vendor_address ? {...data.vendor_address , gstin : data.vendor_gstin,id:data.vendor_address_id} : null,
            
                        },() => {
                            this.getWarehouseDetails(data.warehouse.id)
                            this.getVendorDetails(data.vendor.id,true)
                           // this.handleVendorChange(this.state.searchedVendor,true)
                           
                        })
                    }

                    
                   
                });
            });
        }
    }

    //********************GET WAREHOUSE DETAILS API ******************************* */
    getWarehouseDetails             =       (selectedOption)        =>          {
    
        if(selectedOption){
         let  id = selectedOption.value ? selectedOption.value : selectedOption
         this.setState({warehouseDetail : null, warehouseSites : [],warehouse_state_id : null})
            HttpAPICall.withAthorization('GET',  this.warehouseUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                if(this.state.vendor_state_id === respData.data.state_id){
                    this.setState({gstTypeName : 'GST'})
                }else{
                    this.setState({gstTypeName : 'IGST'}) 
                }
                this.setState({
                    warehouseDetail          :      respData.data,
                    warehouse_state_id       :      respData.data && respData.data.state_id ? respData.data.state_id : null,
                    warehouseSites           :      respData.data.sites,
                    pendingPOListLoaded      :       false,
            }); }) 
        }else{
            this.setState({warehouseDetail    : null,})
            this.setState({gstTypeName :  this.state.selectedVendorAdress && this.state.warehouseDetail && this.state.selectedVendorAdress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST'});
            
        }
    }

    //********************GET VENDOR DETAILS ******************************* */
    getVendorDetails                        =   (id) =>  {
        if(id) {
            this.setState({vendor_details_loading : true})
            HttpAPICall.withAthorization('GET', this.vendorUrl + '/' + id, this.props.access_token, null, null, (response) => {
                let selectedVendorAdress   =   response.data.data && response.data.data.addressList ? response.data.data.addressList[0] : [];
                this.setState({
                    vendorDetails           :   response.data.data,
                    vendorAddressList       :   response.data.data.addressList,
                    selectedVendorAdress    :   selectedVendorAdress,
                    pendingPOListLoaded     :   false,
                });
            }).then(() => {
                this.setState({ vendor_details_loading: false });
                this.setState({gstTypeName :  this.state.selectedVendorAdress && this.state.warehouseDetail && this.state.selectedVendorAdress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST'});
            });
        }
    }


    //********************GET ITEM DETAILS ******************************* */

    onItemSelect                =           (selectedOption,key,edit = false,qty=1)          =>      {
        if(this.state.addPurchaseForm.warehouse_id && this.state.addPurchaseForm.vendor_id) {
            if(selectedOption && selectedOption.itemdata) {
                
                let item                =   selectedOption.itemdata;
                     let prefer_gst_key           =   this.state.vendor_state_id == this.state.warehouse_state_id ? 'gst' : 'igst';
                    let purchaseItemRows          =   this.state.purchaseItemRows;
                    purchaseItemRows[key]         =   {...purchaseItemRows[key],
                        item                    :   item,
                        qty                     :   qty,
                        rate                    :   item.purchase_price ? item.purchase_price : 0,
                        gst                     :   item[prefer_gst_key] ,
                        batch_number            :   item.batch_number ? item.batch_number : '',
                        expiry_date             :   item.expiry_date ? item.expiry_date : '',
                        discount_rate           :   0,
                        discount_type           :   "Percentage" 
                    };
                    this.setState({purchaseItemRows : purchaseItemRows}, () => { 
                        this.getPendingQuantity(item.id,key)
                        if(!this.state.purchaseItemRows.find(ir => !ir.item)) {
                            setTimeout(() => {this.addNewItemRow()}, 1000);
                        }
                    });
            }
        } else {
            toast.error('Please Select Warehouse & Vendor First', {position: toast.POSITION.TOP_RIGHT })
        }
    }

  
    itemViewModalInit                       =   (item,k)  =>  {
        if(item){
            this.itemDetailsRef.current.modalInit(item);
            this.setState({itemRowNumber : k})
        }

    } 

   
    //****************************CLOSE PURCHASE *********************/
    closeEvent              =           ()      =>      {
        if(this.state?.purchaseData){
            this.props.history.push({pathname: `/purchase_return_list`, state: {purchaseData : this.props.location?.state?.purchaseData }})
        }else{
            this.props.history.push({pathname: `/purchase_return_list`})
        }
    }

    handleReturnQtyChange = (e, itemKey, batchIndex) => {
        const { value } = e.target;
       
        let updatedPurchaseItemRows = [...this.state.purchaseItemRows];
        updatedPurchaseItemRows[itemKey].batch_list[batchIndex].return_qty = value;
        let totalReturnQtyForItem = 0;
        if(updatedPurchaseItemRows &&  updatedPurchaseItemRows[itemKey] && updatedPurchaseItemRows[itemKey].batch_list && updatedPurchaseItemRows[itemKey].batch_list.length > 0){
           totalReturnQtyForItem = updatedPurchaseItemRows[itemKey].batch_list.reduce((total, batchItem) => {
                const returnQty = batchItem && batchItem.return_qty ? parseFloat(batchItem.return_qty) : 0;
                return total + returnQty;
            }, 0);
        }
        
        updatedPurchaseItemRows[itemKey].qty = totalReturnQtyForItem;
      
        this.setState({
            purchaseItemRows: updatedPurchaseItemRows,
        });
    };
    
    pickPurchaseItemModalInit       =       ()      =>      {
        this.pickPurchaseModal.show();
        
    }

    checkAllItemList() {
        let purchaseItemforCheckbox      =   document.getElementsByClassName('purchase_item_id_checkbox')
        if(purchaseItemforCheckbox && purchaseItemforCheckbox.length > 0) {
            for (let i = 0; i < purchaseItemforCheckbox.length; i++) {
                purchaseItemforCheckbox[i].checked = document.getElementById('all_check_purchase').checked;
            }
        }
    }


    submitPickPurchaseFormSubmit          =   (e)         =>      {
        e.preventDefault();
        let item_ids                           =    [];
        let purchaseItemforCheckbox      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemforCheckbox && purchaseItemforCheckbox.length > 0) {
            for (let i = 0; i < purchaseItemforCheckbox.length; i++) {
                if(purchaseItemforCheckbox[i].checked) {
                    item_ids.push(Number(purchaseItemforCheckbox[i].value))
                    
                }
            }
        }

        if (item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        }else {
            let purchaseData = this.state.purchaseData
            let items  = purchaseData && purchaseData.items && purchaseData.items.length > 0 ? purchaseData.items.filter(pur => item_ids.includes(pur.purchase_item_key)) : []
            
            let purchaseItemRows = this.state.purchaseItemRows.slice(); // Copy the existing array to avoid mutation

            let newItemRows = items.map((pu_item, k) => {
                let totalBatchQty = 0;
                let totalReturnQty = 0;

                // Calculate total batch quantity and total return quantity
                pu_item.batch_list.forEach(batch => {
                    totalBatchQty += batch.qty;
                    totalReturnQty += Number(batch.purchase_return_qty);
                });

                // Calculate quantity as total batch quantity minus total return quantity
                let qty = totalBatchQty - totalReturnQty;
                let discount_rate = pu_item.discount_rate_vc;
                if (pu_item.discount_type === "Fixed") {
                    // Calculate discount per item
                    let discount_per_item = pu_item.qty > 0 ? discount_rate / pu_item.qty : 0; // Avoid division by zero
                    discount_rate = discount_per_item;
                }

                return {
                    item: { ...pu_item.item, isAddRemark: false },
                    req_allocation_loading: false,
                    req_allocation: [],
                    batchReturnQty: [],
                    id: pu_item.item.id,
                    qty: qty < 0 ? 0 : qty,
                    rate: pu_item.rate_vc,
                    remark: "",
                    gst: pu_item.gst,
                    discount_type: pu_item.discount_type,
                    discount_type_display: pu_item.discount_type && pu_item.discount_type == "Percentage" ? "%" : pu_item.discount_type && pu_item.discount_type == "Fixed" ? "Rs" : "",
                    discount_rate: discount_rate,
                    po_item_id: pu_item.po_item_id,
                    purchase_number: pu_item.po_transaction_id,
                    req_item_id: pu_item.req_item_id,
                    requisition_transaction_id: pu_item.requisition_transaction_id,
                    selected_allocation: null,
                    purchase_item_key: pu_item.purchase_item_key ? pu_item.purchase_item_key : "",
                    max_qty: qty < 0 ? 0 : qty,
                    total_allocation_qty    :  qty, 
                    batch_list: pu_item.batch_list && pu_item.batch_list.length > 0 ? pu_item.batch_list.map(be => {
                        return {
                            ...be,
                            batch_qty: be.qty - Number(be.purchase_return_qty),
                            stock_id: be.stock_id,
                            return_qty: be.qty - Number(be.purchase_return_qty),
                            max_qty: be.purchase_return_qty ? be.qty - Number(be.purchase_return_qty) : 0
                        }
                    }) : [],
                    stock_id: pu_item.stock_id ? pu_item.stock_id : null,
                    disable_rate: pu_item.po_item_id && this.state.purchase_po_same_unit_rate == 'Y' ? 'Y' : 'N'
                };
            });

            newItemRows.forEach(newItem => {
                // Check if the item already exists in purchaseItemRows
                let isItemPresent = purchaseItemRows.some(item => {
                    return item.purchase_item_key === newItem.purchase_item_key; 
                });

                // Add the item only if it's not already present
                if (!isItemPresent) {
                    purchaseItemRows.push(newItem);
                }
            });
            this.pickPurchaseModal.hide();
             this.setState({ purchaseItemRows: purchaseItemRows });

        }
        
       
    }

    removeItemRow                           =   (k) =>  {
        let allRows                 =   this.state.purchaseItemRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({purchaseItemRows : allRows},() =>{
               
            });
        }
    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.purchaseItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.purchaseItemRows[k] , item  : itemdata};
        this.setState({purchaseItemRows : allRows});
    }
  
    //**********************PURCHASE FORM  SUBMIT ***************************/
    addPurchaseFormSubmitEvent          =           (e)          =>      {
        e.preventDefault()
        let items                   =   [...this.state.purchaseItemRows];
    
        let itemsLists = items.filter(i => i.item ? true : false).flatMap(i => {
           
                            let batch_list = i && i.batch_list && i.batch_list.length > 0 ? i.batch_list : [];
                    
                            let batchData = batch_list && batch_list.length > 0
                                ? batch_list.map(bt => ({
                                    remark: i.remark ? i.remark : "",
                                    stock_id: bt.stock_id && bt.stock_id ? bt.stock_id : "",
                                    return_qty: Number(bt.return_qty),
                                    max_qty: bt.max_qty ? bt.max_qty : "",
                                }))
                                : [{
                                    remark: i.remark ? i.remark : "",
                                    stock_id: i && i.stock_id ? i.stock_id : "",
                                    return_qty: i.qty,
                                    // Add max_qty if it's available in your data structure
                                    max_qty: "",
                                }];
                    
                            return batchData;
                        });
     
        
         let showErrors                      =   [];  
         if(itemsLists.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and their  qty.'];
        }
        // else if(itemsLists.find(i => parseFloat(i.return_qty) <= 0)) {
        //     showErrors                      =   [...showErrors , 'Every Item\'s  qty must be more than 0.'];
        // }
        else if(itemsLists.find(i => parseFloat(i.return_qty) > parseFloat(i.max_qty))) {
            showErrors                      =   [...showErrors , 'Every Item\'s  qty cannot be more than Purchase Return Qty of this Item.'];
        } 
        
        if(showErrors.length > 0) {
            toast.error(showErrors[0], {position: toast.POSITION.TOP_RIGHT});
        } else {
            let additional_attributes = {};
            if(this.state.additionalAttributes && this.state.additionalAttributes.length > 0 ){
                this.state.additionalAttributes.forEach((data, i) => {
                    let name = 'additional_attributes_' + data.key;
                    additional_attributes[data.key] = this.state.addPurchaseForm[name] ? this.state.addPurchaseForm[name] : '';
                })
            } 
            let frmData = {
                ...this.state.addPurchaseForm,
                additional_attributes,
                items              :  itemsLists.filter(item => item.return_qty > 0),
                transaction_date   :  moment(this.state.addPurchaseForm.transaction_date,'YYYY-MM-DD').format('YYYY-MM-DD')
            }
        
        
            this.setState({saveFormSubmitting : true})
            
            if (this.state.purchaseReturnData) {
                HttpAPICall.withAthorization('PUT', this.purReturnUrl + '/edit', this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/purchase_return_list`, state: {purchaseReturnId : this.state.addPurchaseForm.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                HttpAPICall.withAthorization('POST', this.purReturnUrl + '/add', this.props.access_token, {}, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/purchase_return_list`, state: {purchaseReturnId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
       
    }
    
    
    //**********************ADD PURCHASE FORM JSX*******************/

    savePurchaseFormJsx                  =   ()  =>  {
        let wallet_permission = this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet")
        let sub_total_amount        =   0;
        let gst_total_amount        =   0;
        let gst_slab                =   [];
        let total_amount            =   0;
        let purchase_summary        =   [];
        let adjustment_amount       =   parseFloat(this.state.addPurchaseForm.adjustment_amount);

        this.state.purchaseItemRows.forEach((item,key) => {
       
            
            if(item && item.item) {
                let item_amount             =   parseFloat(item.qty * item.rate);
                let discount_amout          =   parseFloat(item.discount_rate && item.discount_rate > 0
                                                    ?  item.discount_type == "Percentage" ? (item_amount*item.discount_rate/100) : item.discount_rate
                                                    : 0);
                if(discount_amout > item_amount){
                   // toast.error("Discount cannot be greater then item amount", { position: toast.POSITION.TOP_RIGHT }); 
                }else{
                    let item_with_discount      =   item_amount - discount_amout;
                    let tax_amount              =   0;
                        if(item.gst && item.gst > 0 
                            && this.state.warehouse_state_id 
                            && this.state.selectedVendorAdress 
                            && this.state.selectedVendorAdress.gstin
                         ) {
                        tax_amount              =   item_with_discount * item.gst / 100;
                        if(!gst_slab[item.gst]) {
                            gst_slab[item.gst]  =   [];
                        }
                        gst_slab[item.gst].push(tax_amount);
                }
                        let item_final_amount       =   item_with_discount + tax_amount;
                        sub_total_amount           +=   item_with_discount;
                        gst_total_amount           +=   tax_amount;
                        total_amount               +=   item_final_amount;
                }
                
            }
        });
        
        if(gst_total_amount > 0) {
            purchase_summary.push({key : 'Sub Total' , value : sub_total_amount.toFixed(2)});
            gst_slab.forEach((tax_amounts, gst) => {
                let total_tax = tax_amounts.reduce((sum, a) => sum + a, 0);
                if(this.state.selectedVendorAdress && this.state.warehouseDetail && this.state.selectedVendorAdress.state_id !== this.state.warehouseDetail.state_id) {
                    purchase_summary.push({key : 'IGST ' + gst + '%', value : total_tax.toFixed(2)});
                } else {
                    let tax = gst / 2;
                    purchase_summary.push({key : 'CGST ' + tax + '%', value : (total_tax/2).toFixed(2)});
                    purchase_summary.push({key : 'SGST ' + tax + '%', value : (total_tax/2).toFixed(2)});
                }
            });
        }
       
        return (<form className="bg-white p-3" onSubmit={this.addPurchaseFormSubmitEvent}>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Return</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addPurchaseForm.transaction_date
                                ? moment(this.state.addPurchaseForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'addPurchaseForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        disabled={this.state.purchaseData && this.state.purchaseData.can_edit == "N" ? true : false}
                        placeholderText={`Please Enter Date of Return`}

                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center mt-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Return Warehouse Name</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addPurchaseForm', 'warehouse_id'); this.getWarehouseDetails(selectedOption) }}
                        options={this.state.all_warehouses}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={true}
                        value={this.state.all_warehouses.find(m => m.value == this.state.addPurchaseForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>

            </div>
            {this.state.warehouseDetail ?
                <div className="row align-items-center mt1 pb-2">
                    <div className="offset-3 col-sm-8">
                        <label className='form-label'>Address : {this.state.warehouseDetail.address1 ? this.state.warehouseDetail.address1 + ", " : ""}
                            {this.state.warehouseDetail.address2 ? this.state.warehouseDetail.address2 + ", " : ""}
                            {this.state.warehouseDetail.landmark ? this.state.warehouseDetail.landmark + ", " : ""}
                            {this.state.warehouseDetail.city ? this.state.warehouseDetail.city + ". " : "-"}</label><br />
                        <label className='form-label'>Destination of Supply : {this.state.warehouseDetail && this.state.warehouseDetail.state ? <span>{this.state.warehouseDetail.state.name}</span> : "-"}</label> <br />
                        <label className='form-label'>GSTIN : {this.state.warehouseDetail && this.state.warehouseDetail.gstin ? <span>{this.state.warehouseDetail.gstin}</span> : "-"}</label>
                    </div>

                </div>
                : null}

            <Vendor
                vendorDetails={this.state.vendorDetails}
                details_loading={this.state.details_loading}
                vendorAddressList={this.state.vendorAddressList}
                vendor_details_loading={this.state.vendor_details_loading}
                selectedVendorAdress={this.state.selectedVendorAdress}
                searchedVendor={this.state.searchedVendor}
                onChange={this.handleVendorChange}
                getVendorDetails={this.getVendorDetails}
                disabled={true}
                vendorRef={this.addVendorModalRef}
                disabledPerformAction={true}
                changeAddressHandler={this.changeAddressHandler}
            />
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require ">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addPurchaseForm', 'status');
                        }}
                        options={this.state.allStatusList}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allStatusList.find(s => s.value == this.state.addPurchaseForm.status)}
                        placeholder="Select Status"
                    />
                </div>
            </div>
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Bill Number & Date</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="bill_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addPurchaseForm.bill_number}
                        onChange={(e) => this.formInputHandler(e, 'addPurchaseForm')}
                        placeholder="Enter Bill Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addPurchaseForm.bill_date
                                ? moment(this.state.addPurchaseForm.bill_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="bill_date"
                        onChange={(value, event) => this.formDateHandler('bill_date', value, 'addPurchaseForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Bill Date`}

                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Eway Number & Date</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="e_way_bill_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addPurchaseForm.e_way_bill_number}
                        onChange={(e) => this.formInputHandler(e, 'addPurchaseForm')}
                        placeholder="Enter Eway Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addPurchaseForm.e_way_bill_date
                                ? moment(this.state.addPurchaseForm.e_way_bill_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="e_way_bill_date"
                        onChange={(value, event) => this.formDateHandler('e_way_bill_date', value, 'addPurchaseForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Eway Date`}

                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            {wallet_permission ? <div className="row align-items-center my-3 mt-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Return to Wallet</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        menuPlacement="top"
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addPurchaseForm', 'wallet_id') }}
                        options={this.state.allWalletList}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allWalletList.find(m => m.value == this.state.addPurchaseForm.wallet_id)}
                        placeholder="Please Select Wallet"
                    />
                </div>
            </div> : null}
            {this.state.additionalAttributes && this.state.additionalAttributes.length > 0 && 
            <AdditionalAttributeForm
                    additionalAttributes={this.state.additionalAttributes}
                    formState={this.state.addPurchaseForm}
                    onAttributeChange={(value,attributeName) => {
                        this.setState(() => ({
                            addPurchaseForm: {
                            ...this.state.addPurchaseForm,
                            [attributeName]: value
                        }
                    }))}
                } 
                />}
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Purchase Transaction ID</label>
                </div>
                <div className="col-sm-4">

                    <input
                        name="purchase_transaction_id"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addPurchaseForm.purchase_transaction_id}
                        onChange={(e) => this.formInputHandler(e, 'addPurchaseForm')}
                        placeholder="Enter Purchase Transaction ID"
                        disabled={true}
                    />
                </div>
                <div className="col-sm-5">
                    <button
                        type="button"
                       className="btn btn-link text-decoration-none"
                        onClick={() => this.pickPurchaseItemModalInit()}
                    ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} />  Pick Remaining Item from Purchase</button>
                </div>

            </div>
            {this.purchaseItemJsx()}
            
            <div className="row align-items-center mb-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addPurchaseForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addPurchaseForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                    <table className="table  table-borderless">
                        <tbody>
                            {purchase_summary && purchase_summary.length > 0
                                ? (purchase_summary.map((ps, k) => {
                                    return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                }))
                                : null
                            }
                            {this.state.selectedVendorAdress && this.state.selectedVendorAdress.gstin ? null
                                : <tr className="subtotal_bg_color">
                                    <th>GST</th>
                                    <td className='float-end'>Not Applicable</td>
                                </tr>}
                            <tr className="subtotal_bg_color">
                                <th>Adjustment</th>
                                <td><input
                                    name="adjustment_amount"
                                    type="number"
                                    value={this.state.addPurchaseForm.adjustment_amount}
                                    onChange={(e) => this.formInputHandler(e, 'addPurchaseForm')}
                                    className="form-control text-end"
                                    autoComplete="off"
                                    placeholder="Adjustment"
                                /></td>
                            </tr>

                            <tr className="subtotal_bg_color">
                                <th>Final Total</th>
                                <td className='float-end'>{adjustment_amount ? (total_amount + adjustment_amount).toFixed(2) : total_amount.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
            
                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-primary ms-2" onClick={(e) => { this.addPurchaseFormSubmitEvent(e, 1); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>

        </form>);
    }

    purchaseItemJsx             =       ()      =>      {
       
        return (<div className=" my-2 row">
            <div className="col-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" style={{ width: "33%" }}>Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Allocation</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "9%" }}>Discount</th>
                            <th scope="col" className="text-center" style={{ width: "9%" }}>GST</th>
                            <th scope="col" className="text-center" style={{ width: "9%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.purchaseItemRows.map((i, itemKey) => {

                            let item_with_discount = 0;
                            let item_final_amount = 0;
                            if (i.item) {
                                let item_amount = parseFloat(i.qty * i.rate);
                                let discount_amout = parseFloat(i.discount_rate && i.discount_rate > 0
                                    ? i.discount_type == "Percentage" ? (item_amount * i.discount_rate / 100) : i.discount_rate
                                    : 0);

                                item_with_discount = parseFloat(item_amount - discount_amout).toFixed(2);

                            }

                            let tdRows = (i.item) ? (<Ax>
                                <td className="text-start">
                                    <div className="">
                                        <div className="row">
                                            <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(i.item, itemKey); this.viewItemModalInit() }}>{i.item.name}</span></div>
                                            <div className='col-sm-2 text-end'>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu cursor_pointer">
                                                    <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item, itemKey); this.viewItemModalInit() }}>View Details</li>
                                                    <li className="dropdown-item" onClick={() => { this.addRemark(i.item, itemKey) }}>Add Remark</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                        {/* <div className="form-text"><b>Mfr: </b>{i.item?.manufacturer?.name ?? '-'} (MPN : {i.item?.manufacturer_part_no ?? "-"} )</div> */}

                                    </div>
                                    {/* {this.state.isc_configuration?.track_batch_expiry == "Y" && <div> */}
                                    {i && i.batch_list && i.batch_list.length > 0 && i.batch_list.some(item => item.batch_qty > 0) && <table className="table table-bordered bg-white my-2 table-sm ">

                                        <tbody>
                                            <tr>
                                                <td scope="col" style={{ width: "5%" }}>S.No</td>
                                                {this.state.isc_configuration?.track_batch_expiry == "Y" && <><td scope="col" className="text-center" style={{ width: "30%" }}>Batch No</td>
                                                    <td scope="col" className="text-center" style={{ width: "30%" }}>Expiry Date</td></>}
                                                <td scope="col" className="text-center" style={{ width: "15%" }}>Qty</td>
                                                <td scope="col" className="text-center" style={{ width: "15%" }}>Return Qty</td>
                                            </tr>
                                            {i && i.batch_list && i.batch_list.length > 0 && i.batch_list.map((be, batchIndex) => {
                                                return (
                                                    <tr>
                                                        <td className="text-center">{batchIndex + 1}</td>
                                                        {this.state.isc_configuration?.track_batch_expiry == "Y" && <><td>{be.batch_number ? be.batch_number : ""}</td>
                                                            <td>{be.expiry_date_display ? be.expiry_date_display : ""}</td></>}
                                                        <td className="text-center">{be.batch_qty ? be.batch_qty : "0"}</td>
                                                        <td className="text-center">
                                                            <input
                                                                name="return_qty"
                                                                type="text"
                                                                value={be.return_qty}
                                                                onChange={(e) => this.handleReturnQtyChange(e, itemKey, batchIndex)}
                                                                className="form-control form-control-sm"
                                                                placeholder="Return Qty"
                                                                autoComplete='off'
                                                            /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>}


                                    {/* </div>
                                    }    */}
                                    {i.item && i.item.isAddRemark && i.item.isAddRemark == true ?
                                        <input
                                            name="remark"
                                            type="text"
                                            value={this.state.purchaseItemRows[itemKey].remark}
                                            onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e.target.value, 'remark', itemKey, 'purchaseItemRows')}
                                            className="form-control mt10"
                                            autoComplete="off"
                                            placeholder="Item Remarks"
                                        />
                                        : null}


                                </td>
                                {this.state.purchaseItemRows[itemKey].req_allocation_loading
                                    ? (<td colSpan="5"><Loader /></td>)
                                    : <Ax>
                                        <td className="text-center">
                                            <div className='text-center my-1'> <span>{i.po_item_id ? i.purchase_number : i.req_item_id ? i.requisition_transaction_id : `UnAllocated`} : {i.total_allocation_qty ? i.total_allocation_qty : ''} </span></div>

                                        </td>
                                        <td className='pt0'>
                                            <TapInputNumber
                                                name="qty"
                                                value={this.state.purchaseItemRows[itemKey].qty}
                                                onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e, 'qty', itemKey, 'purchaseItemRows')}
                                                placeholder="Qty"
                                                required={true}
                                               disabled={true}
                                            />
                                            {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}
                                            {this.state.purchaseData && this.state.purchaseItemRows[itemKey].can_edit == "N" ?
                                                <div className='text-danger'>Item Consumed</div> : null}

                                        </td>
                                        <td className='pt0'>
                                            <TapInputNumber
                                                name="rate"
                                                value={this.state.purchaseItemRows[itemKey].rate}
                                                onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e, 'rate', itemKey, 'purchaseItemRows')}
                                                placeholder="Unit Rate"
                                                required={true}
                                                disabled={true}
                                            />
                                        </td>

                                        <td className='text-center rightSelectText'>
                                            <TapInputNumber
                                                name="discount_rate"
                                                value={this.state.purchaseItemRows[itemKey].discount_rate}
                                                onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e, 'discount_rate', itemKey, 'purchaseItemRows')}
                                                disabled={true}
                                                placeholder="Discount"
                                            />
                                            <input
                                                name="discount_type_display"
                                                type="text"
                                                value={this.state.purchaseItemRows[itemKey].discount_type_display}
                                                className="form-control text-end"
                                                autoComplete="off"
                                                disabled={true}
                                                placeholder="Type"
                                            />
                                        </td>
                                        {this.state.selectedVendorAdress && this.state.selectedVendorAdress.gstin
                                            ? <td className='text-end rightSelectText'>
                                                <input
                                                    name="gst"
                                                    type="text"
                                                    value={this.state.purchaseItemRows[itemKey].gst}
                                                    className="form-control text-end"
                                                    autoComplete="off"
                                                    disabled={true}
                                                    placeholder="GST"
                                                />
                                                {i.item ? <span className="text-helping input-group-text text-end pr0">{this.state.gstTypeName}</span> : null}
                                            </td>
                                            : <td className='text-center'>NA <sup className='text-danger'>#</sup></td>}

                                        <td className="text-end className='pt0'">{item_with_discount}</td>
                                    </Ax>}
                            </Ax>)
                                : (<Ax>
                                    <td>
                                        <InputItemSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addPurchaseForm', 'item_id');
                                                this.onItemSelect(selectedOption, itemKey)
                                            }}
                                            value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                                        />
                                    </td>
                                    <td colSpan={this.state.isc_configuration?.track_batch_expiry == "Y" ? "5" : "4"} className="text-center align-middle" >-</td>
                                </Ax>);
                            return (<tr key={itemKey}>
                                <td className="text-center align-middle">{itemKey + 1}</td>
                                {tdRows}
                                <td className="text-center align-middle">
                                    {this.state.purchaseData && this.state.purchaseItemRows[itemKey].can_edit == "N" ?
                                        null
                                        : <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>}
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
            <div className='col-sm-5 mt0'>
                {this.state.selectedVendorAdress && this.state.selectedVendorAdress.gstin
                    ? null
                    : <small className='text-danger'>#NA : GST Not Applicable to Vendor with No GSTIN</small>
                }
               
            </div>
            <div className="col-sm-7 text-end">
                          
            </div>

        </div>)
    }

    pickPurchaseModalJsx                             =   ()  =>  {
        let purchaseDetails = this.state.purchaseData;
        return (
            <div className="modal fade" id="pickPurchaseModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="pickPurchaseModalLabel">Pick Remaining Purchase Item </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                {purchaseDetails && <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Return Warehouse   </td>
                                        <th>{this.state.warehouseDetail ? <span>{this.state.warehouseDetail.name} {this.state.warehouseDetail.code && <span>({this.state.warehouseDetail.code})</span>}</span> : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Purchase Transaction ID   </td>
                                        <th>{this.state.addPurchaseForm && this.state.addPurchaseForm.purchase_transaction_id ? `${this.state.addPurchaseForm.purchase_transaction_id}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Vendor   </td>
                                        <th>{this.state.vendorDetails ? <span>{this.state.vendorDetails.name} {this.state.vendorDetails.code && <span>({this.state.vendorDetails.code})</span>}</span> : "-"}</th>
                                    </tr>

                                </tbody>}
                            </table>
                        </div>
                        <form onSubmit={this.submitPickPurchaseFormSubmit} id="submitPickPurchaseForm">
                            <div className="modal-body">
                                <table className="table table-bordered table-responsive bg-white mt-3">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "5%" }}><input type='checkbox' disabled id="all_check_purchase" onChange={this.checkAllItemList} /></th>
                                            <th scope="col" className="text-center" style={{ width: "30%" }}>Item Details</th>
                                            <th scope="col" className="text-end" style={{ width: "15%" }}>Max Return Quantity</th>
                                            <th scope="col" className="text-end" style={{ width: "11%" }}>Unit Rate</th>
                                            <th scope="col" className="text-end" style={{ width: "10%" }}>Discount</th>
                                            <th scope="col" className="text-end" style={{ width: "10%" }}>GST</th>
                                            <th scope="col" className="text-end" style={{ width: "10%" }}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            purchaseDetails && purchaseDetails.items && purchaseDetails.items.length > 0 ? purchaseDetails.items?.map((i, k) => {
                                                let item_with_discount = 0;
                                                let item_final_amount = 0;
                                                let item_amount = parseFloat(i.qty * i.rate_vc);
                                                let discount_amount = parseFloat(i.discount_rate_vc && i.discount_rate_rc > 0
                                                    ? i.discount_type == "Percentage" ? (item_amount * i.discount_rate_vc / 100) : i.discount_rate
                                                    : 0);
                                                item_with_discount = item_amount - discount_amount;
                                                let totalBatchQty = 0;
                                                let totalReturnQty = 0;

                                                // Calculate total batch quantity and total return quantity
                                                if (i && i.batch_list && i.batch_list.length > 0) {
                                                    i.batch_list.forEach(batch => {
                                                        totalBatchQty += batch.qty;
                                                        totalReturnQty += Number(batch.purchase_return_qty);
                                                    });
                                                }


                                                // Calculate quantity as total batch quantity minus total return quantity
                                                let max_qty = (totalBatchQty - totalReturnQty)

                                                return (<tr>
                                                    <td className='text-center'>
                                                        {k + 1} <br />
                                                        <input type='checkbox'
                                                            className="purchase_item_id_checkbox"
                                                            name="purchase"
                                                            value={i.purchase_item_key}
                                                            // data-warehouseId={purchaseDetails.warehouse_id}
                                                            // data-itemKey={i.purchase_item_key}
                                                            disabled={this.state.purchaseItemRows.find(element => {
                                                                if (element?.purchase_item_key == i?.purchase_item_key) {
                                                                    return true;
                                                                } else {
                                                                    return false;
                                                                }
                                                            })}
                                                        /></td>
                                                    <td>
                                                        <div className='row'>
                                                            <ItemViewInList view_hsn={true} item={i.item} />
                                                            <div className='col-sm-12'>
                                                                {this.props.isc_configuration?.track_batch_expiry == "Y" && i && i.batch_list && i.batch_list.length == 1 && i.batch_list.every(be => !be.batch_number && !be.expiry_date && Number(be.purchase_return_qty) == 0) ? null :
                                                                  this.props.isc_configuration?.track_batch_expiry == "Y"  ? (<Ax><table className="table table-bordered  my-2 table-sm text-sm ">

                                                                        <tbody>
                                                                            <tr>
                                                                                <td scope="col" style={{ width: "5%" }}>S.No</td>
                                                                                <td scope="col" className="text-center" style={{ width: "30%" }}>Batch No</td>
                                                                                <td scope="col" className="text-center" style={{ width: "30%" }}>Expiry Date</td>
                                                                                <td scope="col" className="text-center" style={{ width: "15%" }}>Qty</td>
                                                                                <td scope="col" className="text-center" style={{ width: "15%" }}>Return Qty</td>
                                                                            </tr>
                                                                            {i && i.batch_list && i.batch_list.length > 0 && i.batch_list.map((be, batchIndex) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td className="text-center">{batchIndex + 1}</td>
                                                                                        <td>{be.batch_number ? be.batch_number : ""}</td>
                                                                                        <td>{be.expiry_date_display ? be.expiry_date_display : ""}</td>
                                                                                        <td className="text-center">{be.qty ? be.qty : ""}</td>
                                                                                        <td className="text-center">{be.purchase_return_qty ? Number(be.purchase_return_qty) : ""}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                    </Ax>)
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </td>


                                                    <td className='text-end'>{max_qty}
                                                        {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}
                                                    </td>
                                                    <td className='text-end'>{i.rate_vc}</td>
                                                    <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? purchaseDetails.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : ""}</td>

                                                    <td className='text-end'>{purchaseDetails && purchaseDetails.vendor_gstin && purchaseDetails.gst_not_applicable && purchaseDetails.gst_not_applicable == 'N' ? <div>{i.gst} %</div> : "NA"}
                                                        {purchaseDetails && purchaseDetails.vendor_gstin && i.item && purchaseDetails.gst_not_applicable == 'N' ? <span className="text-helping input-group-text text-end pr0">{purchaseDetails.vendor_address && purchaseDetails.vendor_address.state_name == purchaseDetails.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                                    </td>

                                                    <td className='text-end'>
                                                        <CurrencyFormatter currencyCode={purchaseDetails.currency} amount={i.item_with_discount} />
                                                    </td>
                                                </tr>)

                                            }) : <tr ><td className='text-center' colSpan={7}>No Record</td></tr>
                                        }
                                    </tbody>
                                </table>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Add Purchase Return</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.purchaseReturnData ? "Edit" : "Add"} Purchase Return {this.state.purchaseReturnData ? <span> of Transaction ID : {this.state.purchaseReturnData.transaction_id}</span> : null}</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close purchase" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.purchaseFormDataLoaded ? this.savePurchaseFormJsx()
                            : <Loader />}
                        {this.pickPurchaseModalJsx()}
                        <ItemDetailsModal ref={this.itemDetailsRef} /> 

                      
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_group_sites         :   state.app && state.app.user_sites ? state.app.group_sites : [],
        permissions             :   state.app.acl_info,
    };
};

export default connect(mapStateToProps)(PurchaseReturnAdd);