import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import AutoLinking from "./AutoLinking";
import GeneralSetting from "./GeneralSetting";
import Workflow from "./Workflow";
import AdditionalAttribute from '../../AdditionalAttribute';
import TagComponent from '../../TagComponent';


class TicketConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            addition_attr_show  :   false,
            tags_show           :   false
        }
    }

    render() {
        return (
            <section className="item_view_container bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general"
                                    type="button" role="tab" aria-controls="general" aria-selected="true">General Settings
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                        <button className="nav-link " id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                        </button>
                    </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="tagging-tab" data-bs-toggle="tab" data-bs-target="#tagging"
                                    type="button" role="tab" aria-controls="tagging" aria-selected="true">Auto Linking
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="additional-attr-tab" data-bs-toggle="tab" data-bs-target="#additional-attr" type="button" role="tab" aria-controls="additional-attr" aria-selected="true" onClick={() => this.setState({addition_attr_show : true})}>Additional Attribute
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="tags-tab" data-bs-toggle="tab" data-bs-target="#tags" type="button" role="tab" aria-controls="tags" aria-selected="true" onClick={() => this.setState({tags_show : true})}>Tags
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane  show active" id="general" role="tabpanel"
                             aria-labelledby="general-tab">
                            <div className="tab_content_header">
                               <GeneralSetting/>
                            </div>
                        </div>
                        <div className="tab-pane" id="workflow" role="tabpanel"
                             aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                             <Workflow/>
                            </div>
                        </div>
                        <div className="tab-pane" id="tagging" role="tabpanel"
                             aria-labelledby="tagging-tab">
                            <div className="tab_content_header">
                                <AutoLinking />
                            </div>
                        </div>
                        <div className="tab-pane" id="additional-attr" role="tabpanel"
                            aria-labelledby="additional-attr-tab">
                            <div className="tab_content_header">
                            {
                                this.state.addition_attr_show &&
                                <AdditionalAttribute category="ticket"/>
                            }
                            </div>
                        </div>
                        <div className="tab-pane" id="tags" role="tabpanel" aria-labelledby="tags-tab">
                            <div className="tab_content_header"> { this.state.tags_show && <TagComponent type="ticket"/> } </div>
                        </div>
                    </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(TicketConfiguration);