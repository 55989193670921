import React from 'react';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import AppBaseComponent from '../../../components/AppBaseComponent';
import { HRM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import TapIcon from '../../../services/TapIcon';
import DateService from '../../../services/DateService';
import EmployeeFamilyModal from './EmployeeFamilyModal';

class EmployeeFamilyDetailList extends AppBaseComponent {
    constructor () {
        super();
        this.state  =   {
            emp_id : '',
            list_loading : false,
            education_list : [],
        }
        this.educationModalRef = React.createRef();

    this.emp_family_url = HRM_API_BASE_URL_2 + '/employee_family/:id';


    }

    componentDidMount () {
        this.initialDataLoading(this.props);
    }

    initialDataLoading = (pr) => {
        this.setState({emp_id : pr.emp_id});
        this.listLoading(pr.emp_id);
    }

    listLoading = (emp_id) => {
        this.setState({ list_loading: true })
        HttpAPICall.withAthorization('GET', this.emp_family_url.replace(':id', emp_id), this.props.access_token, {}, {}, (response) => {
            this.setState({
                education_list : response.data
            });
       }).then(() => this.setState({ list_loading: false }));
    }

    handleModal     =   (type, id = null)  => {
        this.educationModalRef.current.modalHandle(type, id);
    }      

    deleteDetail =   (id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.emp_family_url.replace(':id', id), this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.listLoading(this.state.emp_id);
                });
            }
        });
    }

    render () {
        return (
            <Ax>
                <div className="tab_content_wrapper">
                    <span className="content_heading">Family Details</span>
                    <div className="text-end">
                        <button type="button" onClick={() => this.handleModal('Add')} className="btn btn-secondary btn-sm" >
                            <TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} alt="item-action" />
                        </button>
                    </div>
                </div>
                <table className="table table-hover table-bordered  table-responsive bg-white mb-4 mt-2">
                    <thead className="table-secondary">
                        <tr className="text-center">
                            <th scope="col" style={{ width: "5%" }} >S.No</th>
                            <th scope="col" style={{ width: "30%" }}>Name</th>
                            <th scope="col" style={{ width: "30%" }} >Relationship</th>
                            <th scope="col" style={{ width: "30%" }}>Date of Birth</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.list_loading
                        ? <tr className='text-center'><td colSpan={5}><Loader/></td></tr>
                        : 
                        this.state.education_list.length > 0 
                        ?
                        this.state.education_list.map((data, i) => {
                            return (
                                <tr key={i}>
                                    <td className='text-center'>{i+1}</td>
                                    <td>{data.name}</td>
                                    <td className="text-center">{data.relationship}</td>
                                    <td className="text-center">{data.dob ? DateService.dateTimeFormat(data.dob, 'DD-MMM-YYYY') : ''}</td>
                                    <td className='text-center'>
                                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu">
                                            <li><Link className='dropdown-item' onClick={() => this.handleModal('Edit', data.id)}>Edit</Link></li>
                                            <li><Link className='dropdown-item' onClick={() => this.deleteDetail(data.id)}>Delete</Link></li>
                                        </ul>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        <tr className='text-center'>
                            <td colSpan={5}>No Record Found</td>
                        </tr>
                    }
                    </tbody>
                </table>
                <EmployeeFamilyModal ref={this.educationModalRef} emp_id={this.state.emp_id} listLoading={() => this.listLoading(this.state.emp_id)}/>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};


export default connect(mapStateToProps, null, null)(EmployeeFamilyDetailList);
