import React from 'react';
import moment from "moment";
import swal from "sweetalert";
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import HttpAPICall from '../../../services/HttpAPICall';
import { Link } from 'react-router-dom';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class OpeningBalanceAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                           =     {
            saveFormSubmitting               :     false,
            addOpeningBalanceForm            :     {
                item_id                      :     ''
            },
            openingBalanceWarehouseRows      :     [{warehouse : null}],
            searchedItem                     :      null,
            warehouseList                    :      [],
            formData                         :      [],
            form_loading                     :     false,
            openingBalanceAddFormSubmitting  :     false,
            editForm                         :     false,
            fetchingCurrentBalance          :   false,
            itemCurrentStock                :   []
        };

        this.itemOpeningStockUrl    =   INVENTORY_API_BASE_URL_2 + '/item_opening_stock';
    }

    componentDidMount() {
        if(this.props.location && this.props.location.state && this.props.location.state.data) {
            let data = this.props.location.state.data
            this.setState({editForm : true})
            let selectedOption =   {
             label               :   (<Ax>
                                         <b>Item Name : </b> {data.name}<br/>
                                         <small className='form-text'><b>Code : </b> ({data.item_code})</small>   <br/>
                                      </Ax>),
             value               :    data.id,
             display_label       :   `${data.name}`,
             itemdata            :    data
           };
           this.selectItemOnChange(selectedOption)
        }else{
            this.setState({editForm : false})
        }
      
    }

    selectItemOnChange                  =           (selectedOption)    =>  {
        if(selectedOption){
            this.setState({
                searchedItem: selectedOption 
            }, () => { 
                this.getOpeningStockData(selectedOption.value);
                this.getCurrentStockData(selectedOption.value);
            });
        }
    }

    closeEvent          =       ()      =>      {
        this.props.history.push('/item_opening_balance')
    }

    getOpeningStockData                 =          (id)   =>  {
        this.setState({form_loading: true});
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/item_opening_stock_form_data/' + id , this.props.access_token, {}, {}, (resp) => {
        this.setState({
                      formData         :    resp.data,
                      warehouseList    :    resp.data.warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
        openingBalanceWarehouseRows    :    resp.data.item_opening_stock.map((m) => 
                                            { return {warehouse : m.warehouse_id, opening_stock : m.qty, qty : m.qty, rate : m.rate,batch_number : m.batch_number ? m.batch_number : "", expiry_date:m.expiry_date ? m.expiry_date : ""}})
        })
        this.addWarehouseRow()
      }).then(() => {this.setState({form_loading: false})})
    }
    
    getCurrentStockData                 =          (id)   =>  {
        this.setState({fetchingCurrentBalance : true});
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/item_current_stock/' + id, this.props.access_token, {}, {}, (resp) => {
            this.setState({ itemCurrentStock : resp.data.current_stock });
        }).then(() => {this.setState({fetchingCurrentBalance: false})})
    }

    addWarehouseRow                           =   ()  =>  {
        this.setState({openingBalanceWarehouseRows : [...this.state.openingBalanceWarehouseRows, {warehouse : null}] });
    }

    onWarehouseChange                            =   (selectedWarehouse, k)  => {
        if(selectedWarehouse.value && selectedWarehouse.value > 0) {
            let item                    =   selectedWarehouse;
            let allRows                 =   this.state.openingBalanceWarehouseRows;
            allRows[k]                  =   {
                warehouse                   :   item, 
                qty                         :   selectedWarehouse.qty ? selectedWarehouse.qty : 0,
                rate                        :   selectedWarehouse.rate ? selectedWarehouse.rate : 0, 
            };
            this.setState({openingBalanceWarehouseRows : allRows});
            this.addWarehouseRow();
        }
    }

    onWarehouseInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val                             =   '';
        if(name === 'expiry_date') {
            val                         =   selectedOption ?  moment(selectedOption).format('YYYY-MM-DD') : null
        } else {
            val = e.target.value;
        }
        let allRows                 =   this.state.openingBalanceWarehouseRows;
        allRows[k]                  =   {...this.state.openingBalanceWarehouseRows[k] , [name] : val};
        this.setState({openingBalanceWarehouseRows : allRows});
    }

    removeWarehouseRow                =     (k)     =>  {
        let allRows                 =   this.state.openingBalanceWarehouseRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({openingBalanceWarehouseRows : allRows});
        }
    }

    resetForm                        =        ()        =>      {
        swal({
            title: "Reset",
            text: "All Filled Details will be removed",
            icon: "warning",
            // dangerMode: true,
            buttons: ["Cancel", "Okay"],
        })
            .then(willDownload => {
                if (willDownload) {
                    this.setState({searchedItem : null,openingBalanceWarehouseRows      :     [{warehouse : null}]})
                }
            });
        
      
    }

    openingStockFormHandler                     =   (e) =>      {
        e.preventDefault()

        let warehouse = [...this.state.openingBalanceWarehouseRows];

        let warehouseData = warehouse.filter(i => i.warehouse !== null).map(i => {
            return { ...i, warehouse: i.warehouse.value ? i.warehouse.value : i.warehouse }
        });
        let stockValidation = [];
        let stockValidationError = false;
        if (warehouseData) {
           
            warehouseData.forEach(item => {
               
                let combinationKey = item.warehouse  + item.batch_number + item.expiry_date;
                
                if (stockValidation.includes(combinationKey)) {
                    stockValidationError = true;
                }

                return stockValidation.push(combinationKey);
            });
        }
        
        if (stockValidationError) {
            toast.error("Opening Stock should not contain duplicate values", { position: toast.POSITION.TOP_RIGHT });
        } else {
            //Check for Negative Stock :-
            let find_negative_stock     =   false;
            warehouseData.forEach(item => {
                
                let item_batch_number       =   item.batch_number == '' ? null : item.batch_number;
                let item_expiry_date        =   item.expiry_date == '' ? null : item.expiry_date;
                
                let currentStock            =   this.state.itemCurrentStock.find(cs => {
                                                    return cs.warehouse_id == item.warehouse && 
                                                            cs.batch_number == item_batch_number && 
                                                            cs.expiry_date == item_expiry_date;
                                                });
                if(currentStock && item.opening_stock) {
                    //New Current Stock =  Current Stock + New opening balance - previous opening balance
                    let new_current_stock   =   parseFloat(currentStock.free_qty) + parseFloat(item.qty) - parseFloat(item.opening_stock);
                    if(new_current_stock < 0) {
                        find_negative_stock =   true;
                    }
                }
            });
            if(find_negative_stock) {
                swal({ title: "Are you sure?", text: "Changing opening balance may impact your inventory levels, and Inventory may go below zero if it is consumed.",
                    icon: "warning", buttons: ["No", "Yes"] }).then(isConfirm => {
                        if (isConfirm) { this.submitOpeningStockForm(warehouseData); }
                });
            } else {
                this.submitOpeningStockForm(warehouseData);
            } 
        }
    }
    
    submitOpeningStockForm                      =   (warehouseData)  =>  {
        let frmData = {
            item_id  : this.state.searchedItem.value,
            opening_stock : warehouseData
        }
        this.setState({ openingBalanceAddFormSubmitting: true });
        HttpAPICall.withAthorization('PUT', this.itemOpeningStockUrl, this.props.access_token, {} , {...frmData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.closeEvent()
        }).then(() => this.setState({openingBalanceAddFormSubmitting : false}));
    }
    

    saveOpeningBalanceFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.openingStockFormHandler}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Item Name</label>
                </div>
                <div className="col-sm-4 ">
                    {this.state.searchedItem ? <span>{this.state.searchedItem.itemdata.name}</span> :
                        <InputItemSearch
                            required={true}
                            placeholder="Search Item"
                            filter={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addOpeningBalanceForm', 'item_id');
                                this.selectItemOnChange(selectedOption)
                            }}
                            value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                        />}
                </div>
                {this.state.searchedItem ? <div className='col-sm-1'> <tapIcon.imageIcon icon={tapIcon.EditIcon} onClick={this.resetForm} /></div>
                    : null}
                <div className="col-sm-4 form-text">
                    Inventory Level is available only for Item Category (Goods)
                </div>
            </div>
            {this.state.searchedItem ? (<Ax>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Item Code</label>
                    </div>
                    <div className="col-sm-4 fw500">{this.state.searchedItem.itemdata.item_code} </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Manufacturer</label>
                    </div>
                    <div className="col-sm-4 fw500">{this.state.searchedItem.itemdata.manufacturer ? this.state.searchedItem.itemdata.manufacturer.name : "-"} </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Manufacturer Part #</label>
                    </div>
                    <div className="col-sm-4 fw500">{this.state.searchedItem.itemdata.manufacturer_part_no ?? "-"} </div>
                </div>
                {this.state.form_loading ? <Loader />
                    : (this.state.formData && this.state.formData.group_isc_configuration && !this.state.formData.group_isc_configuration.activation_date ? <div><Link to="/inventory_configuration/item">Fill Date of Activation(Opening Balance) from Inventory Settings</Link></div>
                        : (<Ax>
                            <div className="row align-items-center my-3">
                                <div className="col-sm-3">
                                    <label htmlFor="site_id" className="form-label">Please check the Opening Balance as of </label>
                                </div>
                                <div className="col-sm-4 fw500">{this.state.formData && this.state.formData.group_isc_configuration && this.state.formData.group_isc_configuration.activation_date ? moment(this.state.formData.group_isc_configuration.activation_date).format("DD-MMM-YYYY") : "-"} </div>
                            </div>
                            {this.state.searchedItem ? (<div className="row align-items-center my-3">
                                <div className="col-12">
                                    <table className="table table-bordered table-responsive bg-white">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" style={{ width: "5%" }}>S.No</th>
                                                <th scope="col" className="text-center" style={{ width: "25%" }}>Warehouse</th>
                                                <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                                                <th scope="col" className="text-center" style={{ width: "15%" }}>Unit Rate</th>
                                                <th scope="col" className="text-center" style={{ width: "15%" }}>Amount</th>
                                                {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? (<Ax>
                                                    <th scope="col" className="text-center" style={{ width: "15%" }}>Batch Number</th>
                                                    <th scope="col" className="text-center" style={{ width: "10%" }}>Expiry Date</th>
                                                </Ax>) : null}
                                                <th scope="col" className="" style={{ width: "5%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.openingBalanceWarehouseRows.map((i, k) => {
                                                let tdRows = (i.warehouse) ? (<Ax>
                                                    <td className="text-start">
                                                        <span className="fw-bold">{!(i.warehouse.label) ? this.state.warehouseList.filter((s) => s.value === i.warehouse).map(s => s.label) : i.warehouse.label}</span>
                                                    </td>
                                                    <td>
                                                        <input
                                                            name="qty"
                                                            type="number"
                                                            value={this.state.openingBalanceWarehouseRows[k].qty}
                                                            onChange={(e) => this.onWarehouseInputChange(e, 'qty', k)}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Qty"
                                                            required={true}
                                                            min={1}
                                                            step=".01"
                                                        />

                                                    </td>
                                                    <td>
                                                        <input
                                                            name="rate"
                                                            type="number"
                                                            value={this.state.openingBalanceWarehouseRows[k].rate}
                                                            onChange={(e) => this.onWarehouseInputChange(e, 'rate', k)}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Unit Rate"
                                                            required={true}
                                                            step="0.01"
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        {(this.state.openingBalanceWarehouseRows[k].qty * this.state.openingBalanceWarehouseRows[k].rate).toFixed(2)}
                                                    </td>
                                                    {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? (<Ax>
                                                        <td className='text-start'>
                                                            <input
                                                                name="batch_number"
                                                                type="text"
                                                                value={this.state.openingBalanceWarehouseRows[k].batch_number}
                                                                onChange={(e) => this.onWarehouseInputChange(e, 'batch_number', k)}
                                                                className="form-control text-end"
                                                                autoComplete="off"
                                                                placeholder="Batch Number"
                                                            />
                                                        </td>
                                                        <td>  <DatePicker
                                                            selected={
                                                                this.state.openingBalanceWarehouseRows[k].expiry_date
                                                                    ? moment(this.state.openingBalanceWarehouseRows[k].expiry_date).toDate()
                                                                    : false
                                                            }
                                                            name="expiry_date"
                                                            onChange={(selectedOption, e) => this.onWarehouseInputChange(e, 'expiry_date', k, selectedOption)}
                                                            dateFormat="dd-MMM-yyyy"
                                                            className={"form-control"}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            autoComplete="off"
                                                            scrollMonthYearDropdown
                                                            placeholderText={`Please Enter Date`}

                                                        /></td>
                                                    </Ax>) : null}
                                                </Ax>)
                                                    : (<Ax>
                                                        <td className='text-start'>
                                                            <TapSelect
                                                                options={this.state.warehouseList}
                                                                isSearchable={true}
                                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addOpeningStockForm', 'warehouse'); this.onWarehouseChange(selectedOption, k) }}
                                                                placeholder={"Search Warehouse"}
                                                            />
                                                        </td>
                                                        {this.state?.formData?.group_isc_configuration?.track_batch_expiry == "Y" ? <td colSpan="5" className="text-center align-middle" >-</td>
                                                            : <td colSpan="3" className="text-center align-middle" >-</td>}
                                                    </Ax>);
                                                return (<tr className="text-center align-middle" key={k}>
                                                    <td className="text-center align-middle">{k + 1}</td>
                                                    {tdRows}
                                                    <td className="text-center align-middle">
                                                        <a href="#" onClick={() => this.removeWarehouseRow(k)}>
                                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                                        </a>
                                                    </td>
                                                </tr>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 text-end">
                                    <a role="button" className="link-primary" onClick={() => this.addWarehouseRow()}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                                </div>
                            </div>) : null}
                            <div className="col-12 text-end fixed_footer">
                                <button type="button" disabled={this.state.openingBalanceAddFormSubmitting ? true : false} className="btn btn-secondary mx-3" onClick={this.closeEvent}>Cancel</button>
                                <button type="submit" disabled={this.state.openingBalanceAddFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                    Save {this.state.openingBalanceAddFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </Ax>))}
            </Ax>) : null}

        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Opening Balance - Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editForm ? "Update" : "Add"} Opening Stock</h3>
                    <div className="text-end mt15">
                         <button disabled={this.state.openingBalanceAddFormSubmitting ||  this.state.fetchingCurrentBalance ? true : false} type="button" className="btn btn-secondary" onClick={this.closeEvent}>
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {this.saveOpeningBalanceFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(OpeningBalanceAdd);