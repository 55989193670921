import React from 'react';
import { toast } from 'react-toastify';
import {connect} from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from '../../../services/HttpAPICall';
import Ax from "../../../components/hoc/Ax";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from '../../../components/ui/TapSelect';
import {PeriodSelectionWithCustom, DisplayListPagination , DisplaySearchCriteria } from '../../../components/TapUi/index';
import Loader from "../../../components/ui/Loader/Loader";
import download from "../../../services/DownloadFile";
import { Modal, Collapse } from 'bootstrap';
import swal from "sweetalert";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Helmet } from 'react-helmet';

class InvoiceCalculation extends AppBaseComponent {
    
    constructor() {
        super();
        
        this.reportFilterFormInitialState   =   {
            report_type                         :   'single',
            search_asset_id                     :   '',
            invoice_rule_id                     :   '',
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_site                         :   '',
            search_asset_type                   :   '',
            search_asset_ids                    :   ''
        };
        
        this.invoiceSaveFormInitData        =   {
            site_id                             :   '',
           
        }
        
        this.state                          =   {
            displaySearchFilter                 :   true,
            showSaveInvoiceBtn                  :   false,
            showDownloadInvoiceBtn              :   false,
            invoiceDownloading                  :   false,
            showSaveInvoiceForm                 :   false,
            invoiceCalculationSaveFormLoaded    :   false,
            searchedAsset                       :   null,
            searchedAssets                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            invoiceCalculationFormData          :   null,
            selectedPeriod                      :   '',
            listing_tbl_page                    :   1,
            calculationHeading                  :   null,
            listing_loading                     :   false,
            listingMeta                         :   null,
            listingData                         :   [],
            totalListingCount                   :   0,
            asset_total_invoice                 :   0,
            calculationAdditionRows             :   [{notes : '', amount : ''}],
            calculationDeductionRows            :   [{notes : '', amount : ''}],
            assetPeriodSites                    :   [],
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],
            invoiceSaveForm                     :   {...this.invoiceSaveFormInitData},
            invoiceSaveFormSubmittig            :   false,
            periods                             :   [],
            asset_type_id : null,
            reportData                          :   null,                  
        };

    }
    
    componentDidMount() {
        //Load Invoice Calculation Form Data :-

        const report_name   =   'asset_invoice_calculator';
        const reportData    =   this.props?.report_listing.length > 0 ? this.props.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        });
        this.invoiceCalculationFormDataInit();
        this.initilaizeFormFilter(this.props);
        this.reportFilterhandler();
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
        }
    }
    
    invoiceCalculationFormDataInit          =   ()  =>  {

        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation_form_data', this.props.access_token, null, null, (response) => {
            this.setState({
                invoiceCalculationFormData      :   response.data,
                periods                         :   response.data.periods
            },() => {
                let ParamObject                 =   new URLSearchParams(this.props.location.search);
              
            if(ParamObject.get('asset_id')) {
               let date =  this.state.periods.find((s) => "current_month".includes(s.key))
                 if(date){
                    let updateFilterForm        =  {date_range : date.key,date_range_start : date.dateFromYmd,date_range_end : date.dateToYmd ,search_asset_id : ParamObject.get('asset_id')};
                  
                    this.setState({reportFilterForm :   {...this.state.reportFilterForm, ...updateFilterForm},
                       
                                    searchedAsset         :   {value : ParamObject.get('asset_id'),
                                
                                display_label              :  `${ParamObject.get('asset_name')} (${ParamObject.get('asset_code')})`}},
                                () => this.setAssetInvoiceRulesOnNavigation(ParamObject.get('asset_type_id'),ParamObject.get('asset_tap_id')));
                 }
                
            }
            });
        });
    }
    
    initilaizeFormFilter                    =   (props)  =>  {
        this.setState({
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
        });
    }
    
    setAssetInvoiceRules                    =   ()  =>  {
        if(this.state.invoiceCalculationFormData && this.state.invoiceCalculationFormData.invoice_rules) {
            let allInvoiceRules                     =   this.state.invoiceCalculationFormData.invoice_rules;
            let assetInvoiceRules                   =   [];
            //get Selected Assset :-
            let asset                               =   this.state.searchedAsset ? this.state.searchedAsset.asset : null;
            if(asset) {
                assetInvoiceRules                   =   allInvoiceRules.filter((rule) => rule.asset_type_id  === asset.asset_type_id);
            }
            this.setState({
                invoiceCalculationFormData  :   {
                    ...this.state.invoiceCalculationFormData,
                    asset_invoice_rules :   assetInvoiceRules.map((rule) => {return {value: rule.id, label: rule.name}} )
                },
                reportFilterForm            :   {
                    ...this.state.reportFilterForm,
                    invoice_rule_id     :   assetInvoiceRules.find(rule => rule.linked_asset_ids.includes(asset.tap_id))
                                            ? assetInvoiceRules.find(rule => rule.linked_asset_ids.includes(asset.tap_id)).id
                                            : null
                }
            });
        }
    }

    setAssetInvoiceRulesOnNavigation                    =   (asset_type_id,asset_tap_id)  =>  {
     
        if(this.state.invoiceCalculationFormData && this.state.invoiceCalculationFormData.invoice_rules) {
           
            let allInvoiceRules                     =   this.state.invoiceCalculationFormData.invoice_rules;
            let assetInvoiceRules                   =   [];
            //get Selected Assset :-
            if(asset_type_id){
                assetInvoiceRules                   =   allInvoiceRules.filter((rule) => rule.asset_type_id == asset_type_id);
            }
    
            this.setState({
                invoiceCalculationFormData  :   {
                    ...this.state.invoiceCalculationFormData,
                    asset_invoice_rules :   assetInvoiceRules.map((rule) => {return {value: rule.id, label: rule.name}} )
                },
                reportFilterForm            :   {
                    ...this.state.reportFilterForm,
                    invoice_rule_id     :   assetInvoiceRules.find(rule => rule.linked_asset_ids.includes(asset_tap_id))
                                            ? assetInvoiceRules.find(rule => rule.linked_asset_ids.includes(asset_tap_id)).id
                                            : null
                }
            });
            
           
        }
    }
    
    reportFilterFormSubmit                  =   (event)  =>  {
        event.preventDefault();
        this.reportFilterhandler();
        this.loadListingTblData(1);
    }
    
    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({listing_loading: true, listing_tbl_page: page, listingData : [], showSaveInvoiceBtn : false, showDownloadInvoiceBtn : false, submittedReportFilterForm : {...this.state.reportFilterForm}});
        this.invoiceSaveFormReset();
        let params                      =   {page: page, ...this.state.reportFilterForm};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation', this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                     :   respData.data,
                calculationHeading              :   respData.heading,
                listingMeta                     :   {
                                                        from : respData.from, 
                                                        to : respData.to, 
                                                        total : respData.total,
                                                        last_page : respData.last_page,
                                                        current_page : respData.current_page
                                                    },
                totalListingCount               :   respData.total,
                asset_total_invoice             :   respData.data.length > 0 ?  respData.data[0]['31-total_invoice'] : 0,
                showSaveInvoiceBtn              :   (this.state.reportFilterForm.report_type == 'single'),
                showDownloadInvoiceBtn          :   true
            });
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    loadAssetSitesAtGivenPeriod             =   ()  =>  {
        let reportFormData                      =   this.state.submittedReportFilterForm;
        let params                              =   {
            asset_id                                :   reportFormData.search_asset_id,
            date_range                              :   reportFormData.date_range,
            date_range_end                          :   reportFormData.date_range_end,
            date_range_start                        :   reportFormData.date_range_start,
        };
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/site', this.props.access_token, params, {}, (response) => {
            this.setState({
                showSaveInvoiceForm                 :   true,
                invoiceCalculationSaveFormLoaded    :   true,
                assetPeriodSites                    :   [...response.data.data].map((s) => { 
                    return { value : s.id, label : s.site_name + '(' +s.site_code + ')' };
                })
            }, () => {
                if(this.state.assetPeriodSites.length == 1) {
                    this.tapSelectChange(this.state.assetPeriodSites[0], 'invoiceSaveForm', 'site_id');
                }
            });
            document.getElementById('pageTbl').scroll({ top: document.getElementById('pageTbl').scrollHeight, behavior: 'smooth' });
        });
    }
    
    showInvoiceCalculationForm              =   ()  =>  {
        this.setState({showSaveInvoiceForm : true , invoiceCalculationSaveFormLoaded : false });
        document.getElementById('pageTbl').scroll({ top: document.getElementById('pageTbl').scrollHeight, behavior: 'smooth' });
        this.loadAssetSitesAtGivenPeriod();
    }
    
    reportFilterhandler                     =   ()  =>  {
        new Collapse(document.getElementById('reportFilterFormDiv'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });  
        }
    }
    
    removeManualAdditonRow                  =   (k)  =>  {
        let allRows                 =   this.state.calculationAdditionRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({calculationAdditionRows : allRows});
        }
    }
    
    addNewCalculationAdditionRow            =   ()  =>  {
        this.setState({calculationAdditionRows : [...this.state.calculationAdditionRows, {notes : '', amount : ''}] });
    }
    
    removeManualDedctionRow                 =   (k)  =>  {
        let allRows                 =   this.state.calculationDeductionRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({calculationDeductionRows : allRows});
        }
    }
    
    addNewCalculationDeductionRow           =   ()  =>  {
        this.setState({calculationDeductionRows : [...this.state.calculationDeductionRows, {notes : '', amount : ''}] });
    }
    
    assetInvoiceCalculationFormSubmit       =   (event)  =>  {
        event.preventDefault();
        
        let additionRowErrors               =   this.state.calculationAdditionRows.map(a => (a.notes && !a.amount) || (!a.notes && a.amount) ? 1 : 0);
        let deductionRowErrors              =   this.state.calculationDeductionRows.map(a => (a.notes && !a.amount) || (!a.notes && a.amount) ? 1 : 0);
        
        if(additionRowErrors.includes(1)) {
            toast.error('Please enter both (Notes and Amount) are required for manual Addition');
        } else if(deductionRowErrors.includes(1)) {
            toast.error('Please enter both (Notes and Amount) are required for manual Deduction');
        } else {
             let invoiceSaveForm                 =   {
                ...this.state.invoiceSaveForm,
                ...this.state.submittedReportFilterForm,
                asset_id            :   this.state.submittedReportFilterForm.search_asset_id,
                addition            :   this.state.calculationAdditionRows.filter(a => a.notes && a.amount),
                deduction           :   this.state.calculationDeductionRows.filter(a => a.notes && a.amount)
            };
            this.setState({invoiceSaveFormSubmittig : true});
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation_save', this.props.access_token,{},invoiceSaveForm,(res) => {
                toast.success(res.data.msg, {position: toast.POSITION.TOP_RIGHT});
                swal({
                    title: res.data.msg,
                    icon: "success",
                    buttons: ['Generate a new Invoice', 'View Saved Invoice'],
                }).then((view) => {
                    if (view) {
                        this.props.history.push({
                          pathname: '/invoice_calculation_list',
                          state: { redirectTransactionId: invoiceSaveForm.transaction_id }
                        });
                    }
                });
                this.invoiceSaveFormReset();
            })
            .then(() => {
                this.setState({invoiceSaveFormSubmittig : false});
            });
        }
    }
    
    invoiceSaveFormReset                    =   ()  =>  {
        this.setState({
            invoiceSaveForm                     :   {...this.invoiceSaveFormInitData},
            showSaveInvoiceForm                 :   false,
            invoiceCalculationSaveFormLoaded    :   false,
            calculationAdditionRows             :   [{notes : '', amount : ''}],
            calculationDeductionRows            :   [{notes : '', amount : ''}],
        });
        document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
    }
    
    invoiceDownloadHandler                  =   ()  =>  {
        this.setState({invoiceDownloading: true});
        this.invoiceSaveFormReset();
        let params                      =   {download_report: 'download_report', ...this.state.reportFilterForm};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation', this.props.access_token, params, {} , (response) => download.file(response.data.file_path)).then(() => this.setState({invoiceDownloading: false}));
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} /> Invoice Calculation</h3>
                <div className="text-end mt15">
                    { this.state.showSaveInvoiceBtn
                        ? (<button disabled={!this.props.permissions.includes('iam-asset-invoice-calculation-save') || this.state.showSaveInvoiceForm} title={!this.props.permissions.includes('iam-asset-invoice-calculation-save') ? 'You do not have permission to perform this action' : ''} style={{"pointer-events" : "all"}} type="button" onClick={this.showInvoiceCalculationForm} className="btn btn-primary">Save Calculation</button>)
                        : null
                    }

                    {this.state.showDownloadInvoiceBtn ? (<button disabled={this.state.showSaveInvoiceForm || this.state.invoiceDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.invoiceDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.invoiceDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                   <button type="button" disabled={this.state.showSaveInvoiceForm} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button> 
                </div>
            </div>
        </div>);
    }
    
    resetFilterFormReportType               =   (val)  =>  {
        if(val === 'single') {
            this.setState({searchedAssets : [] , reportFilterForm : {...this.state.reportFilterForm , search_asset_type : '', search_asset_ids : '', report_type : 'single', 'search_site' : '' }});
        } else {
            this.setState({searchedAsset : null , reportFilterForm : {...this.state.reportFilterForm , search_asset_id : '', invoice_rule_id : '', report_type : 'multiple'}});
        }
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        let asset_invoice_rules                 =   this.state.invoiceCalculationFormData && this.state.invoiceCalculationFormData.asset_invoice_rules 
                                                        ? this.state.invoiceCalculationFormData.asset_invoice_rules 
                                                        : [];
                                                       
        return (<div id="reportFilterFormDiv" className="bg-white collapse" aria-expanded="true">
           <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Report Type</label>
                    <div className="col-sm-6">
                        <div className="form-check form-check-inline">
                            <input 
                                name="report_type"
                                type="radio"
                                value="single"
                                onChange={(e) => this.resetFilterFormReportType('single')}
                                checked={this.state.reportFilterForm.report_type === 'single'}
                                className="form-check-input"
                                required={true}
                                id="report_type_single"
                            />
                            <label className="form-check-label" htmlFor="report_type_single">Single Asset</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input 
                                name="report_type"
                                type="radio"
                                value="multiple"
                                onChange={(e) => this.resetFilterFormReportType('multiple')}
                                checked={this.state.reportFilterForm.report_type === 'multiple'}
                                className="form-check-input"
                                required={true}
                                id="report_type_multiple"
                            />
                            <label className="form-check-label" htmlFor="report_type_multiple">Multiple Asset</label>
                        </div>
                    </div>
                </div>
                { this.state.reportFilterForm.report_type === 'multiple'
                    ? (<Ax><div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end require">Asset type</label>
                        <div className="col-sm-6">
                             <TapSelect
                                options={this.state.iam_asset_types}
                                changeEvent={(selectedOption) => {
                                    this.setState({
                                        searchedAssets : [], reportFilterForm : {...this.state.reportFilterForm , search_asset_ids : [] } }, () => {
                                            this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type');
                                    });
                                }}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.iam_asset_types.find(s => this.state.reportFilterForm.search_asset_type == s.value)}
                                placeholder="Select Asset Type"
                            />
                        </div>
                    </div>
                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end">Asset</label>
                        <div className="col-sm-6">
                            <InputAssetSearch
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                    this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                                }}
                                search_asset_type={[this.state.reportFilterForm.search_asset_type]}
                                isMulti={true}
                                value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                            />
                        </div>
                    </div>
                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end">Site Name</label>
                        <div className="col-sm-6">
                             <TapSelect
                                options={this.state.iam_user_sites}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site')}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site.includes(s.value))}
                                placeholder="Select Site"
                            />
                        </div>
                    </div>
                    </Ax>)
                    : null
                }
                { this.state.reportFilterForm.report_type === 'single'
                    ? (<Ax><div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Asset</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_id');
                                this.setState({
                                    searchedAsset : selectedOption ? selectedOption : null} , () => {
                                    this.setAssetInvoiceRules();
                                });
                            }}
                            value={this.state.searchedAsset ? {...this.state.searchedAsset, label : this.state.searchedAsset.display_label} : null}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Invoice Rule</label>
                    <div className="col-sm-6">
                        <TapSelect
                            isClearable={true}
                            placeholder="Select Invoice Rule"
                            required={true}
                            options={asset_invoice_rules}
                            value={asset_invoice_rules.find(r => r.value ===  this.state.reportFilterForm.invoice_rule_id  )}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'invoice_rule_id')}
                        />
                    </div>
                </div></Ax>)
                    : null
                }
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Date Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.periods}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm    :   {...this.state.reportFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate}
                                });
                            }}
                            menuPlacement="bottom"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr/>
                </div>
            </form>
        </div>);
    }
    
    reportDisplayJsx                        =   ()  =>  {
        return (<div className="bg-white p-3">
            { this.state.listing_loading ? <Loader /> : null }
            
            { this.state.calculationHeading && !this.state.listing_loading
                ? <Ax>{this.reportTableJsx(this.state.calculationHeading , this.state.listingData)}
                   </Ax>
                : null
            }
            {this.state.calculationHeading ? <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            /> : null}
        </div>);
    }
    
    reportTableJsx                          =   (invoiceHeadings, invoiceData , invoiceMeta)  =>  {
        return (<Ax>
            <div className="">
                <table className="table table-bordered table-hover align-middle table-fixed bg-white">
                    <tbody>
                        {invoiceData.length > 0 
                            ? (Object.keys(invoiceHeadings).sort().map((heading_key,k) => {
                                    return (<tr key={k}>
                                        <th colSpan={['00-b','08-b','15-b', '24-b','30-b'].includes(heading_key) ? ((invoiceData.length * 5)+1) : 1} className={['table-light', (heading_key == '08-b') ? 'table-secondary' : ''].join(' ')} style={{width:"30%"}}>{invoiceHeadings[heading_key]?invoiceHeadings[heading_key]:''}</th>
                                        { (['00-b','07-b','08-b','15-b', '24-b','30-b'].includes(heading_key))
                                            ? null
                                            : (invoiceData.map((invoice ,kk) => {
                                                if(invoice.hasOwnProperty(heading_key)) {
                                                if(Array.isArray(invoice[heading_key])) {
                                                    return invoice[heading_key].map((invoiceRow, index) => {
                                                       
                                                        return <td key={kk+-+index} className={[ 'text-end' ,[0,1,2].includes(index) ? 'text-center': '' , ['Limit','Actual','Excess', 'Unit Rate','Amount'].includes(invoiceRow) ? 'bold': '' ].join(' ')}>{invoiceRow}</td>
                                                        if (invoiceRow !== '') {
                                                            return <td className={`text-end ${['Limit', 'Actual', 'Excess', 'Unit Rate', 'Amount'].includes(invoiceRow) ? 'bold' : ''} ${[0, 1, 2].includes(invoiceRow) ? 'text-center' : ''}`}>{invoiceRow}</td>
                                                        }else {
                                                            return <td key={kk+-+index} className="text-end"></td>
                                                        }
                                                    })
                                                } else {
                                                    return <td key={heading_key+k}  colSpan={5} className={[['23-subTotal_addition','29-subTotal_deduction','31-total_invoice'].includes(heading_key) ? 'bold': '' , ['081-period','09-basic_rate','10-days','11-days_actual','12-days_billable','13-day_rate','14-amount_basic','23-subTotal_addition','29-subTotal_deduction','31-total_invoice'].includes(heading_key) ? 'text-end': '' ].join(' ')} >{invoice[heading_key]}</td>
                                                }
                                            } else {
                                                return <td key={k} colSpan={5}></td>;
                                            }
                                        }))
                                    }
                                    </tr>);
                                })) 
                            : (<tr><td className="text-center">No Invoice rule linked with Asset.</td></tr>)
                        }
                    </tbody>
                </table>
                {this.state.showSaveInvoiceForm ? this.invoiceCalculationSaveJsx() : null}
            </div>
        </Ax>);
    }
    
    invoiceCalculationSaveJsx               =   ()  =>  {
        return (<Ax>
            { this.state.invoiceCalculationSaveFormLoaded
                ? (<form onSubmit={this.assetInvoiceCalculationFormSubmit}>
                    <table className="table table-hover align-middle table-bordered  bg-white">
                    <tbody>
                        <tr className="table-secondary"><th colSpan="4">Manual Addition</th></tr>
                        <tr className="table-light">
                            <td style={{width : "10%"}}>#</td>
                            <th style={{width : "40%"}}>Notes</th>
                            <th style={{width : "30%"}}>Amount</th>
                            <th style={{width : "20%"}}></th>
                        </tr>
                        {this.state.calculationAdditionRows.map((i,k) => {
                            return (<tr key={k}>
                                <td></td>
                                <td>
                                    <input 
                                        type="text" 
                                        name="notes"
                                        value={this.state.calculationAdditionRows[k].notes} 
                                        className="form-control form-control-sm"
                                        placeholder="Notes"
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationAdditionRows')}}
                                    />
                                </td>
                                <td>
                                    <input 
                                        type="number" 
                                        name="amount"
                                        value={this.state.calculationAdditionRows[k].amount} 
                                        className="form-control text-end form-control-sm" 
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationAdditionRows')}}
                                        placeholder="Amount"
                                        autoComplete="off"
                                        min={1}
                                        step=".01"
                                        maxLength={9}
                                    />
                                </td>
                                <th className="text-center">
                                    <a href="#" onClick={() => this.removeManualAdditonRow(k)}><tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" /></a>
                                </th>
                            </tr>);
                        })}
                        <tr><td className="text-end" colSpan="4">
                            <a role="button" onClick={this.addNewCalculationAdditionRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Addition Amount </a>
                        </td></tr>
                        <tr className="table-secondary"><th colSpan="4">Manual Dedction</th></tr>
                        <tr className="table-light">
                            <td style={{width : "10%"}}>#</td>
                            <th style={{width : "55%"}}>Notes</th>
                            <th style={{width : "15%"}}>Amount</th>
                            <th style={{width : "20%"}}></th>
                        </tr>
                        {this.state.calculationDeductionRows.map((i,k) => {
                            return (<tr key={k}>
                                <td></td>
                                <td>
                                    <input 
                                        type="text" 
                                        name="notes"
                                        value={this.state.calculationDeductionRows[k].notes} 
                                        className="form-control form-control-sm" 
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationDeductionRows')}}
                                        placeholder="Notes"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number" 
                                        name="amount"
                                        value={this.state.calculationDeductionRows[k].amount} 
                                        className="form-control text-end form-control-sm" 
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationDeductionRows')}}
                                        placeholder="Amount"
                                        autoComplete="off"
                                        min={1}
                                        step=".01"
                                        maxLength={9}
                                    />
                                </td>
                                <th className="text-center" style={{width : "10%"}}>
                                    <a href="#" onClick={() => this.removeManualDedctionRow(k)}> <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" /></a>
                                </th>
                            </tr>);
                        })}
                        <tr><td className="text-end" colSpan="4">
                            <a role="button" onClick={this.addNewCalculationDeductionRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Deduction Amount </a>
                        </td></tr>
                        <tr className="table-secondary text-end">
                            <th colSpan="4">Total Amount (Invoice Amount + Manual Addition - Manual Deduction) : {  
                                (parseFloat(this.state.asset_total_invoice)
                                + (parseFloat(this.state.calculationAdditionRows.reduce((sum, a) => sum + (parseFloat(a.amount? a.amount : 0)) , 0)))
                                - (parseFloat(this.state.calculationDeductionRows.reduce((s, b) => s + parseFloat(b.amount? b.amount : 0) , 0)))).toFixed(2)
                            }</th>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-hover align-middle table-bordered bg-white m0 p0">
                     <tbody>
                        <tr className="bg-white"><th colSpan="4"></th></tr>
                        <tr className="table-secondary"><th colSpan="4">Transaction Details</th></tr>
                        <tr className="">
                            <th style={{width:"10%"}}>Site</th>
                            <td style={{width:"30%"}}>
                                <TapSelect
                                    options={this.state.assetPeriodSites}
                                    menuPlacement="top"
                                    changeEvent={(selectedOption) => { 
                                        this.tapSelectChange(selectedOption, 'invoiceSaveForm', 'site_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    required={true}
                                    value={this.state.assetPeriodSites.find(s => s.value == this.state.invoiceSaveForm.site_id)}
                                    placeholder="Select Site"
                                    containerHeight="30px"
                                    fontSize="93%"
                                />
                            </td>
                           
                        </tr>
                        <tr>
                            <td className="text-end" colSpan="4">
                               <button type="button" onClick={this.invoiceSaveFormReset} className="btn btn-light mx-4 px-4">Cancel</button>
                               <button type="submit" disabled={this.state.invoiceSaveFormSubmittig} className="btn btn-primary  px-4">
                                    Submit {this.state.invoiceSaveFormSubmittig ? <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/> : null}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </form>)
                : (<Loader />)
            }
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Invoice Calculation - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        {this.reportFilterFormJsx()}
                        {this.reportDisplayJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(InvoiceCalculation);