import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import TapSelect from "../../../components/ui/TapSelect";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import { ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import Loader from "../../../components/ui/Loader/Loader";
import { Modal } from "bootstrap";
import swal from "sweetalert";
import { toast } from "react-toastify";
import * as actions from '../../../store/actions/index';


class UserLinkSites extends AppBaseComponent {

    constructor() {
        super();

        this.initLinkSiteForm                =       {
            all_sites                        :        false,
            site_ids                         :        []
        }
        this.state                      =   {
            iam_user_sites              :   [],
            userDetailData              :   null,
            saveLinkedSiteFormSubmitting    :     false,
            linkSiteForm                    :   {...this.initLinkSiteForm},
            linkedSites                     :       [],
            selectedSitesForDelink          :       [],
            linkedSitesList                 :       []
        };

        this.userSitesLinkUrl      =   ORG_API_BASE_URL_2  +   '/user/sites-link';
        this.userSitesDeLinkUrl    =   ORG_API_BASE_URL_2  +   '/user/sites-delink';     

        // user/sites-delink
    }


    componentDidMount() {
         this.initilaizeData(this.props);
         this.linkSitesModal                     =   new Modal(document.getElementById('linkSitesModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.userDetailData !== this.props.userDetailData || nextProps.linkedSitesList !== this.props.linkedSitesList) {
            this.initilaizeData(nextProps);
        }
    }

    initilaizeData               =   (pr)  =>  {
         this.setState({ 
            userDetailData      :   pr.userDetailData,
            linkedSitesList   :     pr.iam_user_sites && pr.iam_user_sites.length > 0 && pr.userDetailData && pr.userDetailData.site_ids && pr.userDetailData.site_ids.length > 0 ? pr.iam_user_sites.filter(site => pr.userDetailData.site_ids.includes(site.id) ? true : false )   : [],
            linkedSites         :   pr.userDetailData && pr.userDetailData.site_ids && pr.userDetailData.site_ids.length > 0 ?   pr.userDetailData.site_ids.map(s => s) : [],
            iam_user_sites      :   pr && pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [], 
        },() => {
           
        })
    }

    linkSiteModalInit                 =   ()  =>  {
        this.linkSitesModal.show();
    }

    checkAllList() {
        let siteCollections      =   document.getElementsByClassName('site_id_checkbox')
        if(siteCollections && siteCollections.length > 0) {
            for (let i = 0; i < siteCollections.length; i++) {
                siteCollections[i].checked = document.getElementById('all_check_site').checked;
            }
        }
    }

    delinkSiteHandler     =       ()      =>  {
        
        let siteCollections     =   document.getElementsByClassName('site_id_checkbox');
        let selectedSites                  =   [];
       
        if(siteCollections && siteCollections.length > 0) {
            for (let i = 0; i < siteCollections.length; i++) {
                if(siteCollections[i].checked) {
                   let site_id         =       siteCollections[i].value;
                    selectedSites.push(site_id)    
                }
            }
        }


        if(selectedSites.length == 0){
            toast.error('Please select atleast one Site', { position: toast.POSITION.TOP_RIGHT });
        }else{
            this.setState({linkedSitesListLoaded : false})
            swal({
                title: "Delink",
                text: "This action cannot be reversed, Are you sure you want to proceed?",
                icon: "warning",
                dangerMode: true,
                buttons: ["No", "Yes"],
            }).then(willDelete => {
                if (willDelete) {
                    let frmData = {user_id : this.state.userDetailData?.id , site_ids : selectedSites}
                    HttpAPICall.withAthorization('PUT', this.userSitesDeLinkUrl, this.props.access_token, {},{...frmData} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        if(this.state.userDetailData && this.props.afterLinkingSiteSubmit){
                            this.props.afterLinkingSiteSubmit()
                        }

                        if(this.state.userDetailData && this.state.userDetailData.id && this.props.user_id && this.state.userDetailData.id == this.props.user_id){
                            this.props.applicationInitLoading(this.props.access_token);
                        }
                        
                        
                    }).then(() => { });
                }
            });
        }
    }

    submitLinkSiteForm          =       (e)      =>      {
       e.preventDefault();
       let frmData ;

       if(this.state.linkSiteForm.all_sites == true){
           let site = this.state.iam_user_sites.filter(o  => !this.state.linkedSites.includes(o.value)).map(s => s.value);
           
            frmData = {
                site_ids      :   site,
                user_id            :   this.state.userDetailData?.id
            }
       }else{
            frmData = {
                site_ids      :   this.state.linkSiteForm.site_ids,
                user_id            :   this.state.userDetailData?.id
            }
       }
       
       this.setState({ saveLinkedSiteFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.userSitesLinkUrl, this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkSitesModal.hide();
            this.setState({ linkSiteForm: { ...this.initLinkSiteForm } })
            if (this.props && this.props.afterLinkingSiteSubmit) {
                this.props.afterLinkingSiteSubmit();
            }
        }).then(() => this.setState({ saveLinkedSiteFormSubmitting: false }));

    }

    onSelectingAllSites             =           (e,key)             =>      {
        let checkedSite = document.getElementById('all_sites').checked
            if(checkedSite == true) {
                this.setState({
                    linkSiteForm : {...this.state.linkSiteForm,
                    all_sites  :  true,
                    site_ids  :  []
                }})
            }else{
            this.setState({
                linkSiteForm : {...this.state.linkSiteForm,
                    all_sites  :  false,
                    site_ids  :   []
            }})
        }
    }

    render                      =   ()  =>  {
        let detail = this.state.userDetailData;
        return (<div>
            <div className="tab_content_wrapper"><span className="content_heading">Sites</span>
                <div className="float-end">
                    <button className="btn btn-secondary mx-2" onClick={this.delinkSiteHandler} disabled={detail && detail.user_type == 'g'  ? true : false}>DeLink Sites</button>
                    <button className="btn btn-primary" onClick={() => this.linkSiteModalInit()} disabled={detail && detail.user_type == 'g' ? true : false}>Link Sites</button>
                </div>
            </div>
            <table className=" table table-hover table-bordered  table-responsive bg-white my-2 table-sm ">
                <thead className="table-secondary">
                    <tr className="text-center">
                        <th style={{ "width": '10%' }}>
                            <input type='checkbox' id="all_check_site" onChange={this.checkAllList} /></th>
                        <th className="text-start">Site Name (Code)</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.linked_site_loading
                        ? (<tr><td colSpan="2"><Loader /></td></tr>)
                        : (this.state.linkedSitesList && this.state.linkedSitesList.length > 0
                            ? (this.state.linkedSitesList.map((t, k) => {
                                let user_sites = [];
                                user_sites = this.state.iam_user_sites && this.state.iam_user_sites.length > 0 ? this.state.iam_user_sites.map(site => site.value) : [];
                                return (<tr key={k}>
                                    <td className='text-center'>
                                        <input
                                            type='checkbox'
                                            className="site_id_checkbox"
                                            name="site"
                                            value={t.id}
                                            disabled={user_sites && user_sites.length > 0 && user_sites.includes(t.id) ? false : true}
                                        />
                                    </td>
                                    <td>{t.site_name}</td>

                                </tr>);
                            }))
                            : (<tr><td colSpan="2" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>

            </table>
            {this.linkSitesModalJsx()}
        </div>);
    }

    linkSitesModalJsx       =    ()       =>  {
        let sites = [];
        if(this.state.iam_user_sites && this.state.iam_user_sites.length > 0 && this.state.linkedSites && this.state.linkedSites.length > 0){
            sites = this.state.iam_user_sites.filter(o  => !this.state.linkedSites.includes(o.value));
        }

        return (
            <div className="modal fade" id="linkSitesModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkSitesModalLabel">Link Sites for : {this.state.userDetailData ? this.state.userDetailData.full_name : "-"} ({this.state.userDetailData && this.state.userDetailData.role ? this.state.userDetailData.role.role : "-"})</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {this.setState({ linkSiteForm   :   {...this.initLinkSiteForm}})}}></button>
                        </div>
                        <form onSubmit={this.submitLinkSiteForm} id="addModalForm">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label className="form-label">Link Sites</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'linkSiteForm', 'site_ids')}
                                            options={sites}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={this.state.linkSiteForm.all_sites == true ? false : true}
                                            isMulti={true}
                                            isDisabled={this.state.linkSiteForm.all_sites == true ? true : false}
                                            value={this.state.iam_user_sites.filter(s => this.state.linkSiteForm.site_ids.includes(s.value))}
                                            placeholder={this.state.linkSiteForm.all_sites == true ? "All Sites" : "Please Select Sites"}
                                        />
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="all_sites"
                                                type="checkbox"
                                                value={true}
                                                onChange={(e) => { this.onSelectingAllSites(e, 'all_sites') }}
                                                className="form-check-input"
                                                id="all_sites"
                                                checked={this.state.linkSiteForm.all_sites == true}
                                            />
                                            <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between  row">
                                <div className="col text-danger text-sm">
                                   {sites && sites.length == 0 && "All Sites are already Linked"}
                                </div>
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveLinkedSiteFormSubmitting || (sites && sites.length == 0)} data-bs-dismiss="modal" onClick={() => { this.setState({ linkSiteForm: { ...this.initLinkSiteForm } }) }}>Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveLinkedSiteFormSubmitting || (sites && sites.length == 0)} >Save {this.state.saveLinkedSiteFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],       
        user_id                 :   state.app.user_info?.id??'',
        permissions             :   state.app.acl_info
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading       :   (access_token)  =>   dispatch(actions.applicationInitLoading(access_token,false)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(UserLinkSites);