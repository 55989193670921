import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import { connect } from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Ax from '../../../../components/hoc/Ax';
import HttpAPICall from '../../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import TapSelect from '../../../../components/ui/TapSelect';
import { Link } from 'react-router-dom';

class Workflow extends AppBaseComponent {
    constructor(props) {
        super(props);

        this.initAddAllocationForm = {
            name: '',
            role_ids: [],
            notes: ''
        }

        this.state = {
            statusList: [],
            listing_loading: false,
            addAllocationForm: { ...this.initAddAllocationForm },
            allRoleList: [],
            addFormDataLoaded: false,
            addAllocationFormSubmitting: false,
            can_Edit: true,
            edit: false,
            form_loaded: false,
        }

        this.salesOrderAllocationFormDataUrl = INVENTORY_API_BASE_URL_2 + "/sales/allocation_status_form_data";
        this.salesOrderAllocationListUrl = INVENTORY_API_BASE_URL_2 + "/sales/allocation_status";
        this.salesOrderAllocationEditUrl = INVENTORY_API_BASE_URL_2 + "/sales/allocation_status";
        this.salesOrderAllocationAddUrl = INVENTORY_API_BASE_URL_2 + "/sales_order_status/add";
        this.salesOrderAllocationDeleteUrl = INVENTORY_API_BASE_URL_2 + "/sales_order_status/delete";

    }

    componentDidMount() {
        this.getAllocationListing()
        this.addAllocationModal = new Modal(document.getElementById('addAllocationModal'), { keyboard: false, backdrop: false });
    }

    getAllocationListing = () => {
        this.setState({ listing_loading: true });
        HttpAPICall.withAthorization('GET', this.salesOrderAllocationListUrl, this.props.access_token, {}, {}, (response) => {
            this.setState({ statusList: response.data.data })
        }).then(() => this.setState({ listing_loading: false }));
    }

    addFormDataInit = () => {
        if (!this.state.addFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.salesOrderAllocationFormDataUrl, this.props.access_token, null, null, (response) => {
                this.setState({
                    addFormDataLoaded: true,
                    allRoleList: response.data.data.roles.map((mou) => { return { value: mou.id, label: mou.role } }),
                });
            });
        }
    }

    addAllocationModalInit = () => {
        this.addAllocationModal.show()
        this.addFormDataInit()
        this.setState({ can_Edit: true, edit: false })
    }

    openEditAllocation = (id) => {
        this.addAllocationModal.show()

        this.setState({ form_loaded: true })
        HttpAPICall.withAthorization('GET', this.salesOrderAllocationFormDataUrl, this.props.access_token, null, null, (response) => {
            let allRoleList = response.data.data.roles.map((mou) => { return { value: mou.id, label: mou.role } })
            let allRole = [...allRoleList, { value: 47, label: "Administrator" }]
            this.setState({
                addFormDataLoaded: true,
                allRoleList: allRole
            }, () => {
                let statusList = this.state.statusList.find(function (transaction) {
                    return transaction.id == id;
                });

                let roles = statusList.roles.map((mou) => mou.id)
                this.setState({
                    addAllocationForm: {
                        name: statusList.name,
                        notes: statusList.notes === null ? "" : statusList.notes,
                        id: statusList.id,
                        role_ids: roles
                    },
                    can_Edit: statusList.can_edit,
                    edit: true

                })
            });
        }).then(() => this.setState({ form_loaded: false }))

    }

    submitAddAllocationForm = (e) => {
        e.preventDefault()
        let frmData = { ...this.state.addAllocationForm }

        this.setState({ addAllocationFormSubmitting: true })

        if (this.state.edit) {
            HttpAPICall.withAthorization('PUT', this.salesOrderAllocationEditUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addAllocationModal.hide();
                this.getAllocationListing()
                this.setState({ addAllocationForm: { ...this.initAddAllocationForm }, can_Edit: true })
            }).then(() => this.setState({ addAllocationFormSubmitting: false }))
        } else {
            HttpAPICall.withAthorization('POST', this.salesOrderAllocationAddUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addAllocationModal.hide();
                this.getAllocationListing()
                this.setState({ addAllocationForm: { ...this.initAddAllocationForm }, can_Edit: true })
            }).then(() => this.setState({ addAllocationFormSubmitting: false }))
        }
    }

    deleteAllocation = (id) => {
        swal({
            title: "DELETE",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    let statusList = this.state.statusList.find(function (group) {
                        return group.id == id;
                    });
                    HttpAPICall.withAthorization('DELETE', this.salesOrderAllocationDeleteUrl + '/' + id, this.props.access_token, {}, { statusList }, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getAllocationListing()
                    }).then(() => {

                    });
                }
            });
    }

    //*********************************EDIT SETTING JSX*************************
    addAllocationModalJsx = () => {
        return (
            <div className="modal fade" id="addAllocationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAllocationModalLabel">{this.state.edit ? "Edit" : "Add"} Allocation</h5>
                        </div>
                        <form onSubmit={this.submitAddAllocationForm} id="addAllocationForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                {this.state.form_loaded ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Allocation Name</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addAllocationForm') }}
                                                    value={this.state.addAllocationForm.name}
                                                    placeholder="Please Enter Allocation Name"
                                                />
                                            </div>
                                        </div>

                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label ">Notes</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="notes"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addAllocationForm') }}
                                                    value={this.state.addAllocationForm.notes}
                                                    placeholder="Please Enter Notes"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Role</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <TapSelect
                                                    options={this.state.allRoleList}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addAllocationForm', 'role_ids')}
                                                    value={this.state.allRoleList.filter(u => this.state.addAllocationForm?.role_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Roles"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addAllocationFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addAllocationForm: { ...this.initAddAllocationForm } }) }}>Close</button>
                                <button type="submit" disabled={this.state.addAllocationFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addAllocationFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }



    render() {
        return (
            <Ax>
                <div className="tab_content_wrapper">
                    <span className="content_heading">Status for Allocation <tapIcon.FontAwesomeIcon icon={tapIcon.faInfoCircle} /></span>
                    {/* <div className="float-end"><button className="btn btn-primary" disabled={!this.props.permissions.includes('isc-po-status-manage')} onClick={this.addAllocationModalInit}>Add Status</button></div> */}
                </div>
                <table className="table table-hover table-bordered my-3 ">
                    <thead>
                        <tr className='table-secondary'>
                            <th scope="col" style={{ width: "20%" }}>Allocation</th>
                            <th scope="col" style={{ width: "40%" }}>Roles</th>
                            <th scope="col" style={{ width: "40%" }} >Notes</th>
                        </tr>

                    </thead>
                    <tbody className="table-body">
                        {
                            this.state.listing_loading
                                ? <tr>
                                    <td className="text-center" colSpan="6"><Loader /></td>
                                </tr>
                                : (this.state.statusList?.length > 0 ? (this.state.statusList?.map((item, index) => {
                                    return <tr key={item.id}>
                                        <td scope="row">{item.name}</td>
                                        <td scope="row">{item.auto_generated === "Y" ? '' : item.roles?.map((mou) => mou.role).join(", ")}</td>
                                        <td scope="row">
                                            <div className="row">
                                                <div className="col-10">
                                                    {item.notes === null ? "-" : item.notes}
                                                </div>
                                                {item.auto_generated === "Y" ? null :
                                                    <div className="col-2 text-center">
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                            data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                                        />
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <Link role="button"
                                                                    onClick={() => {
                                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                                            return this.openEditAllocation(item.id)
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Edit</Link>
                                                            </li>
                                                            {item.can_delete === false ? null : <li>
                                                                <Link role="button"
                                                                    onClick={() => {
                                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                                            return this.deleteAllocation(item.id)
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Delete</Link>
                                                            </li>}

                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                })) : (
                                    <tr >
                                      <td className="text-center" colSpan={3}>
                                        No Record Found
                                      </td>
                                    </tr>
                                  ))
                        }
                    </tbody>
                </table>
                {this.addAllocationModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(Workflow);