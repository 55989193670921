import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import ApplicationLayout from "../../../../layouts/ApplicationLayout";
import {connect} from "react-redux";
import HttpAPICall from "../../../../services/HttpAPICall";
import Ax from "../../../../components/hoc/Ax";
import AssetHeader from "../../../includes/assetHeader/AssetHeader";
import AssetCard from "../../../includes/assetCard/AssetCard";
import {Link} from "react-router-dom";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import {toast, ToastContainer} from "react-toastify";
import AssetAttributeList from "../AssetAttributeList";
import {Modal} from "bootstrap";
import TapSelect from "../../../../components/ui/TapSelect";
import Status from "../../../../components/ui/Status";
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import CounterlogView from './CounterlogView';

class CounterlogConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initEditOperatorForm       =       {
            shift_operator_required     :       'N',
            shift_operator_track        :       'N'
        }

        this.initEditCounterlogForm       =       {
            enable_asset_meter_code           :       'N',
        }

        this.initGeneralConfigFormData      =   {
            previous_day_cl_required           :    'N',
        };

        this.state                  =        {
            operator_loading                 :   false,
            operator_list                    :   null,
            operatorData                     :   null,
            editOperatorForm                :   {...this.initEditOperatorForm},
            editFormSubmiting               :    false,
            operator_form_loading           :       false,
            editOperatorType                :     '',
            counterlog_loading              :     false,
            counterlog_list                 :     [],
            editCounterlogForm              :   {...this.initEditCounterlogForm},
            generalConfigFormData               :     {...this.initGeneralConfigFormData},
            generalConfigloading                :      false,
            general_config_list                 :      null,
        }

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';
    }

    componentDidMount() {
        this.getConfigurationListing();
        this.getGeneralSettingListing();

        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
        this.editTransactionId        =        new Modal(document.getElementById('editTransactionIdModal'), {keyboard: false, backdrop :false});
        this.editOperatorModal        =        new Modal(document.getElementById('editOperatorModal'), {keyboard: false, backdrop :false});
        this.editCounterlogModal        =        new Modal(document.getElementById('editCounterlogModal'), {keyboard: false, backdrop :false});
    }

    getConfigurationListing        =   ()  =>  {
        this.setState({operator_loading : true},() => {
            //Load Group Transaction Delay Setting (Delay days and DashBoard Visibilty) :-
            HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/config', this.props.access_token, {} , {}, (response) => {
                this.setState({
                    operator_list     :    response.data
                });
            }).then(() => {
                this.setState({operator_loading: false});
            });
        });
    }


    editTransactionIdInit       =      ()    =>     {
        this.editTransactionId.show()
    }

    editOperatorModalInit       =       (operator_type)      =>      {
        this.editOperatorModal.show()
       
        this.setState({
            editOperatorForm        : {...this.initEditOperatorForm},
            editOperatorType        :  '',
            operator_form_loading   : true
        },() => {
            this.setState({
                editOperatorForm        : {
                    shift_operator_required     :       this.state.operator_list && this.state.operator_list.shift_operator_required ? this.state.operator_list.shift_operator_required : 'N',
                    shift_operator_track        :       this.state.operator_list && this.state.operator_list.shift_operator_track ? this.state.operator_list.shift_operator_track : 'N',
                    enable_asset_meter_code        :       this.state.operator_list && this.state.operator_list.enable_asset_meter_code ? this.state.operator_list.enable_asset_meter_code : 'N',
                },
                editOperatorType        :  operator_type
            }) 
        })
        setTimeout(() => {
          this.setState({operator_form_loading : false})
        },1000)
    }

    submitOperatorHandler        =    (e)          =>     {
        e.preventDefault();
        let frmData = {
            ...this.state.editOperatorForm,
            shift_operator_required         :       this.state.editOperatorForm.shift_operator_track == "N" ? "N" : this.state.editOperatorForm.shift_operator_required}
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/counterlog/config', this.props.access_token, {...frmData} , {} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editOperatorModal.hide();
            this.getConfigurationListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    editCounterlogInit       =      ()    =>     {
        this.editCounterlogModal.show()
    }

    getGeneralSettingListing = () => {
        let params = {
            keys : [ 'previous_day_cl_required'],
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ general_config_list: resp.data.data })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    editGeneralConfigurationInit = () => {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
              }
              
            this.setState({generalConfigForm})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...this.state.generalConfigFormData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

   
    editOperatorModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="editOperatorModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update General Setting</h5>
                        </div>
                        <form onSubmit={this.submitOperatorHandler}>
                            {this.state.operator_form_loading ? <Loader />
                                : <div className="modal-body">
                                    {this.state.editOperatorType == "capture_operator"
                                        ? <div className="row align-items-center my-2">
                                            <label className="col-md-8 col-form-label col-form-label-sm">Capture Operator Name</label>
                                            <div className="col-md-2">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="shift_operator_track"
                                                        value="Y"
                                                        onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                        checked={this.state.editOperatorForm.shift_operator_track == "Y"}
                                                    /> Yes
                                                </label>
                                            </div>
                                            <div className="col-md-2">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="shift_operator_track"
                                                        value="N"
                                                        onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                        checked={this.state.editOperatorForm.shift_operator_track == "N"} /> No
                                                </label>
                                            </div>
                                        </div>
                                        : this.state.editOperatorType  == "capture_meter" ?
                                            <div className="row align-items-center my-2">
                                                <label className="col-md-8 col-form-label col-form-label-sm">Enable Asset Meter Code</label>
                                                <div className="col-md-2">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="enable_asset_meter_code"
                                                            value="Y"
                                                            onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                            checked={this.state.editOperatorForm.enable_asset_meter_code == "Y"}
                                                        /> Yes
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="enable_asset_meter_code"
                                                            value="N"
                                                            onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                            checked={this.state.editOperatorForm.enable_asset_meter_code == "N"} /> No
                                                    </label>
                                                </div>
                                            </div>
                                            : <div className="row align-items-center my-2">
                                                <label className="col-md-8 col-form-label col-form-label-sm">Operator Name will be mandatory</label>
                                                <div className="col-md-2">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="shift_operator_required"
                                                            value="Y"
                                                            onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                            checked={this.state.editOperatorForm.shift_operator_required == "Y"}
                                                        /> Yes
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="shift_operator_required"
                                                            value="N"
                                                            onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                            checked={this.state.editOperatorForm.shift_operator_required == "N"} /> No
                                                    </label>
                                                </div>
                                            </div>}
                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    editTransactionIdModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="editTransactionIdModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Transaction ID</h5>
                        </div>
                        <form>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Transaction </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">ID Type </label>
                                    </div>
                                    <div className="col-3">
                                        <input
                                            name="type"
                                            type="radio"
                                            value={"manual"}
                                            className="form-check-input"
                                            required={true}
                                        />
                                        <label className="form-check-label mx-2">Manual</label>
                                    </div>
                                    <div className="col-6">
                                        <input
                                            name="type"
                                            type="radio"
                                            value={"system_generate"}
                                            className="form-check-input"
                                            required={true}
                                        />
                                        <label className="form-check-label mx-2">Auto - Increment</label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">ID Prefix </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="prefix"
                                            type="text"
                                            className="form-control"
                                            placeholder={"Enter prefix"}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Numbering</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="numbering"
                                            type="numbers"
                                            className="form-control"
                                            placeholder={"Enter stating numbers"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    editCounterlogFormJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="editCounterlogModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Counterlog Configuration</h5>
                        </div>
                        <form onSubmit={this.submitOperatorHandler}>
                            {this.state.counterlog_form_loading ? <Loader />
                                : <div className="modal-body">
                                    <div className="row align-items-center my-2">
                                        <label className="col-md-8 col-form-label col-form-label-sm">Enable Asset Meter Code</label>
                                        <div className="col-md-2">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="enable_asset_meter_code"
                                                    value="Y"
                                                    onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                    checked={this.state.editCounterlogForm.enable_asset_meter_code == "Y"}
                                                /> Yes
                                            </label>
                                        </div>
                                        <div className="col-md-2">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="enable_asset_meter_code"
                                                    value="N"
                                                    onChange={(e) => this.formInputHandler(e, "editOperatorForm")}
                                                    checked={this.state.editCounterlogForm.enable_asset_meter_code == "N"} /> No
                                            </label>
                                        </div>
                                    </div>

                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    
    editGeneralConfigurationModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                            
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Prevent Counterlog entry, if previous day reading is not available</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="previous_day_cl_required"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.previous_day_cl_required == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="previous_day_cl_required"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.previous_day_cl_required == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                              
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
  }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
        return (<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "10%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                </tr>
            </thead>
            <tbody>
                
                <tr className="text-center">
                    <td>1</td>
                    <td className="text-start">Prevent Counterlog entry, if previous day reading is not available</td>
                    <td>{data && data.previous_day_cl_required && data.previous_day_cl_required == 'Y' ? "Yes" : "No"}</td>
                </tr>
               
            </tbody>
        </table>)
    }
    render() {
        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general"
                                type="button" role="tab" aria-controls="general" aria-selected="true">General Settings
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="counterlog_view-tab" data-bs-toggle="tab" data-bs-target="#counterlog_view"
                                type="button" role="tab" aria-controls="counterlog_view" aria-selected="true">Counterlog View
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane  show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                            <div className="tab_content_header">
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                                    <div className="text-end "><button className="btn btn-primary" onClick={this.editGeneralConfigurationInit}>Edit </button></div>
                                </div>
                                {this.state.generalConfigloading ? <Loader />
                                    : this.generalConfigurationJsx()}
                                <div className="tab_content_wrapper mt-2 mb-3"><span className="content_heading">Operator </span>  </div>
                                <table className="table table-hover table-bordered ">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "10%" }}>Sr.No</th>
                                            <th scope="col">Operator Configuration</th>
                                            <th scope="col" className='text-center'>Value</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.operator_loading ? <tr className='text-center'><td colSpan={4}><Loader /></td></tr>
                                            : this.state.operator_list
                                                ? <Ax>
                                                    <tr className='text-center'>
                                                        <td>1</td>
                                                        <td className='text-start'>Capture operator Name</td>
                                                        <td className='text-center'>{this.state.operator_list.shift_operator_track && this.state.operator_list.shift_operator_track == "Y" ? "Yes" : 'No'} </td>
                                                        <td scope="row">
                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu">
                                                                <li className="dropdown-item" onClick={() => this.editOperatorModalInit('capture_operator')}>
                                                                    Edit
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr className='text-center'>
                                                        <td>2</td>
                                                        <td className='text-start'>Operator Name will be mandatory</td>
                                                        <td>{this.state.operator_list.shift_operator_required && this.state.operator_list.shift_operator_required == "Y" ? "Yes" : 'No'} </td>
                                                        <td scope="row">
                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu">
                                                                <li><a className={this.state.operator_list?.shift_operator_track == 'N' ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={this.state.operator_list?.shift_operator_track == 'N' ? true : false} onClick={() => this.editOperatorModalInit('mandatory')}>Edit</a></li>

                                                            </ul>
                                                        </td>
                                                    </tr>

                                                </Ax>
                                                : <tr className='text-center'><td colSpan={4}>No Records</td></tr>}
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-2 mb-3"><span className="content_heading">Meter Setting</span>  </div>
                                <table className="table table-hover table-bordered ">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "10%" }}>Sr.No</th>
                                            <th scope="col">Meter Configuration</th>
                                            <th scope="col" className='text-center'>Value</th>
                                            <th scope="col" style={{ width: "10%" }} className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.operator_loading ? <tr className='text-center'><td colSpan={4}><Loader /></td></tr>
                                            : this.state.operator_list
                                                ? <tr className='text-center'>
                                                    <td>1</td>
                                                    <td className='text-start'>Enable Asset Meter Code</td>
                                                    <td className='text-center'>{this.state.operator_list.enable_asset_meter_code && this.state.operator_list.enable_asset_meter_code == "Y" ? "Yes" : 'No'} </td>
                                                    <td scope="row">
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu">
                                                            <li className="dropdown-item" onClick={() => this.editOperatorModalInit('capture_meter')}>
                                                                Edit
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr> : null}

                                    </tbody>
                                </table>
                                
                                
                                {this.editGeneralConfigurationModalJsx()}
                                {/* <table className="table table-hover table-bordered ">
                                    <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" style={{width:"10%"}}>S.No</th>
                                        <th scope="col" className="text-start">Configuration</th>
                                        <th scope="col"  style={{width:"20%"}} >Value</th>
                                        <th scope="col" style={{width:"10%"}}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="text-center">
                                        <td scope="row">1</td>
                                        <td scope="row" className="text-start">Allow Transaction Entry on Backdate</td>
                                        <td scope="row">Yes</td>
                                        <td scope="row">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                               alt="action-icon"
                                                               className="img-fluid pr10 pl10"
                                                               data-bs-toggle="dropdown" href="#"
                                                               role="button" aria-expanded="false"/>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    Edit
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr className="text-center">
                                        <td scope="row">2</td>
                                        <td scope="row" className="text-start">Allow Consumption for Negative Stock Item</td>
                                        <td scope="row">Yes</td>
                                        <td scope="row">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                               alt="action-icon"
                                                               className="img-fluid pr10 pl10"
                                                               data-bs-toggle="dropdown" href="#"
                                                               role="button" aria-expanded="false"/>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    Edit
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-2 mb-3"><span className="content_heading">Transaction ID  Settings </span>
                                </div>
                                <table className="table table-hover table-bordered ">
                                    <thead className="table-secondary">
                                    <tr>
                                        <th scope="col" style={{width: "10%"}}>Sr.No</th>
                                        <th scope="col" style={{width: "20%"}}>Transaction</th>
                                        <th scope="col" style={{width: "20%"}}>ID Type</th>
                                        <th scope="col" style={{width: "20%"}}>ID Prefix</th>
                                        <th scope="col" style={{width: "20%"}} >Numbering</th>
                                        <th scope="col" style={{width: "10%"}} className="text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Purchase</td>
                                        <td>System Generate</td>
                                        <td>SSN/REQ/21-22/</td>
                                        <td>0001</td>
                                        <td className="text-center">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                               alt="action-icon"
                                                               className="img-fluid pr10 pl10"
                                                               data-bs-toggle="dropdown" href="#"
                                                               role="button" aria-expanded="false"/>
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item" onClick={this.editTransactionIdInit}>
                                                    Edit
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table> */}
                            </div>
                        </div>
                        <div className="tab-pane" id="counterlog_view" role="tabpanel" aria-labelledby="counterlog_view-tab">
                            <div className="tab_content_header">
                                <CounterlogView/>
                                </div>
                        </div>
                    </div>
                </div>
                {this.editTransactionIdModalJsx()}
                {this.editOperatorModalJsx()}
                {this.editCounterlogFormJsx()}
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
    }
}

export default connect(mapStateToProps)(CounterlogConfiguration);
