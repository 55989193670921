import React from 'react';
import axios from 'axios';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { Modal } from 'bootstrap';
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';


class ItemwiseScrapAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                     =     {
            saveFormSubmitting         :     false,
            itemwiseScrapItemRows           :   [{item : null}],
            addItemwiseScrapForm            :     {}
        };

        this.itemUrl    =   INVENTORY_API_BASE_URL_2 + '/item';

    }

    componentDidMount() {
        this.viewItemModal              =     new Modal(document.getElementById('searchItemModal'), {keyboard: false, backdrop :false});
        this.addNewItemModal            =    new Modal(document.getElementById('addItemModal'), {keyboard: false, backdrop :false});
    }


    saveItemwiseScrapFormJsx                  =   ()  =>  {
        let item_sub_total          =   0;
        let item_total_tax          =   0;
        this.state.itemwiseScrapItemRows.map((i,k) => {
            if(i.item) {
                let gst                 =   this.state.itemwiseScrapItemRows[k].gst ? this.state.itemwiseScrapItemRows[k].gst : 0;
                let total               =   parseFloat(this.state.itemwiseScrapItemRows[k].qty * this.state.itemwiseScrapItemRows[k].unit_rate);
                item_sub_total          =   parseFloat(item_sub_total) + total;
                let item_tax            =   total * gst / 100 ;
                item_total_tax          =   item_tax + item_total_tax;
            }
        });

        return (<form className="bg-white p-3" onSubmit={this.addItemwiseScrapFormSubmitEvent}>

            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-4 add_calender_section">
                    <DatePicker
                        dateFormat="dd-MMM-yyyy"
                        className="form-control"
                        minDate={new Date()}
                        autoComplete="off"
                        placeholderText={`Select Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt = "requisition_date" className = "add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label require">Warehouse Name</label>
                </div>
                <div className="col-4">
                    <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                    />
                </div>
                <div className="col-4">
                    <input
                        name="warehouse_name"
                        type="text"
                        className="form-control"
                        required={true}
                        autoComplete="off"
                        placeholder="Site Name"
                    />
                </div>
            </div>
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label require">Invoice Number & Date</label>
                </div>
                <div className="col-4">
                    <input
                        name="bill_number"
                        type="text"
                        className="form-control"
                        required={true}
                        autoComplete="off"
                        placeholder="Enter Invoice Number"
                    />
                </div>
                <div className="col-4 add_calender_section">
                    <DatePicker
                        dateFormat="dd-MMM-yyyy"
                        className="form-control"
                        minDate={new Date()}
                        autoComplete="off"
                        placeholderText={`Select Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt = "requisition_date" className = "add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label require">Status</label>
                </div>
                <div className="col-4">
                    <TapSelect
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                    />
                </div>

            </div>

            <div className=" mt-3 row">
                <div className="col-12">
                    <table className="table table-bordered table-responsive bg-white table-fixed">
                        <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{width: "5%"}}>S.No</th>
                            <th scope="col" className="text-center" style={{width: "42%"}}>Item Details</th>
                            <th scope="col" className="text-center" style={{width: "12%"}}>Quantity</th>
                            <th scope="col" className="text-center" style={{width: "12%"}}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{width: "12%"}}>GST</th>
                            <th scope="col" className="text-center" style={{width: "12%"}}>Amount</th>
                            <th scope="col" className="" style={{width: "5%"}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.itemwiseScrapItemRows.map((i,k) => {
                            let tdRows      =   (i.item) ? (<Ax>
                                    <td className="text-start">
                                        <div className="">
                                            <div className=" d-flex justify-content-between">
                                                <div><span className="fw-bold">{i.item.name}</span></div>
                                                <div>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item" onClick={this.itemViewModalInit}>View Details</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-text"><b>Item Code :</b>{i.item.item_code}</div>
                                            <div className="form-text"><b>Manufacturer :</b> {i.item?.manufacturer?.name ?? '-'}</div>
                                            <div className="form-text"><b>manufacturer Part #:</b> {i.item?.manufacturer_part_no ?? '-'}</div>
                                            {i.item ?.tags?.length > 0
                                                ? (i.item?.tags.map((t,k) => <span key={k} className="badge bg-secondary">{t.name}</span>))
                                                : null
                                            }
                                        </div>
                                        <input
                                            name="remark"
                                            type="text"
                                            value={this.state.itemwiseScrapItemRows[k].remark}
                                            onChange={(e) => this.onItemInputChange(e,'remark', k)}
                                            className="form-control mt10"
                                            autoComplete="off"
                                            placeholder="Item Remarks"
                                        />
                                    </td>
                                    <td className="req_table">
                                        <input
                                            name="qty"
                                            type="number"
                                            value={this.state.itemwiseScrapItemRows[k].qty}
                                            onChange={(e) => this.onItemInputChange(e,'qty', k)}
                                            className="form-control text-end"
                                            autoComplete="off"
                                            placeholder="Qty"
                                            required={true}
                                            min={1}
                                            step=".01"
                                        />
                                        <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span>
                                    </td>
                                    <td>
                                        <input
                                            name="unit_rate"
                                            type="number"
                                            value={this.state.itemwiseScrapItemRows[k].unit_rate}
                                            onChange={(e) => this.onItemInputChange(e,'unit_rate', k)}
                                            className="form-control text-end"
                                            autoComplete="off"
                                            placeholder="Unit Rate"
                                            required={true}
                                            min={1}
                                            step=".01"
                                        />
                                    </td>
                                    <td>
                                        <TapSelect
                                            changeEvent={(selectedOption, e) => this.onItemInputChange(e, 'gst', k , selectedOption)}
                                            /*   options={gstOptions}*/
                                            isSearchable={false}
                                            /* value={gstOptions.find(s => parseInt(s.value) == parseInt(this.state.itemwiseScrapItemRows[k].gst))}*/
                                            placeholder="GST"
                                        />
                                    </td>
                                    <td className="text-end">
                                        {(this.state.itemwiseScrapItemRows[k].qty * this.state.itemwiseScrapItemRows[k].unit_rate).toFixed(2)}
                                    </td>
                                </Ax>)
                                : (<Ax>
                                    <td>
                                        <AsyncSelect
                                            menuPlacement   =   "bottom"
                                            styles          =   {this.customStyles}
                                            onChange        =   {(selectedItem) => this.onItemChange(selectedItem,k)}
                                            placeholder     =   {"Search Item by Name, Code & MPN"}
                                            loadOptions     =   {(inputText,callback) => this.loadingItem(inputText,callback)}
                                            minimumInput    =   {5}
                                            complete        =   {true}
                                        />
                                    </td>
                                    <td colSpan="4" className="text-center align-middle" >-</td>
                                </Ax>);
                            return (<tr className="text-center align-middle" key={k}>
                                <td className="text-center align-middle">{k+1}</td>
                                {tdRows}
                                <td className="text-center align-middle">
                                    <a href="#" onClick={() => this.removeItemRow(k)}>
                                        <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                    </a>
                                </td>
                            </tr>);
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="col-12 text-end">
                    <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    {/*
                    <a role="button" onClick={this.addNewItemModalInit} className="link-primary ml3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item </a>
*/}
                </div>
            </div>
            <div className="row align-items-center mb-5">
                <div className="col-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addItemwiseScrapForm.notes}
                        onChange={(e) => this.formInputHandler(e,'additemwiseScrapForm')}
                        className="form-control"
                        style={{height: "100px"}}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                    <table className="table  table-borderless">
                        <tbody>
                        <tr className="subtotal_bg_color"><th>Sub Total</th><td className="text-end">{item_sub_total.toFixed(2)}</td></tr>
                        <tr className="subtotal_bg_color"><th>GST</th><td className="text-end">{item_total_tax.toFixed(2)}</td></tr>
                        <tr className="total_bg_color"><th>Total</th><td className="text-end">{(item_sub_total + item_total_tax).toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.props.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary mx-2">
                    Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    itemViewModalInit                       =   ()  =>  {
        //Initialize Search Form :-
        this.viewItemModal.show();
    }

    itemDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="searchItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Item Details</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                <div className="tab_content_wrapper"><span className="content_heading">Basic Details</span></div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                    <tr>
                                        <td className="details-label" style={{"width": "34%"}}>Item Category </td>
                                        <td className="details-name" style={{"width": "66%"}}>{this.state?.itemwiseScrapItemRows[0]?.item?.category_display}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Name</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Code</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.item_code}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Item Description</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.description !== null ?
                                            this.state?.itemwiseScrapItemRows[0]?.item?.description  : "-"
                                        }</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Unit of Measurement</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.measuring_unit.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Manufacturer</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.manufacturer?.name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Manufacturer Part Number (MPN)</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.manufacturer_part_no ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Item Tags</td>
                                        <td className="details-name" >
                                            {
                                                this.state?.itemwiseScrapItemRows[0]?.item !== null && this.state?.itemwiseScrapItemRows[0]?.item.tags?.length > 0
                                                    ? (this.state?.itemwiseScrapItemRows[0]?.item.tags.map((item,k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                    : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >HSN / SAC Code</td>
                                        <td className="details-name" >{this.state?.itemwiseScrapItemRows[0]?.item?.hsn_code ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label" >Generate Scrap on Replacement</td>
                                        <td className="details-name" >{this.state?.itemwiseScrapItemRows[0]?.is_scrapble == 'N' ? 'NO' : 'Yes' ?? '-'}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper"><span className="content_heading">Pricing</span></div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                    <tr>
                                        <td className="details-label" style={{"width": "34%"}}>itemwiseScrap Price</td>
                                        <td className="details-name" style={{"width": "66%"}}>{this.state?.itemwiseScrapItemRows[0]?.item?.itemwiseScrap_price ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Sale / itemwiseScrap Price</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.itemwiseScrap_price ?? ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Scrap Price</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.scrap_price ?? ''}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper"><span className="content_heading">Taxation</span></div>
                                <table className="table table-hover table-borderless">
                                    <tbody>
                                    <tr>
                                        <td className="details-label" style={{"width": "34%"}}>Tax Status </td>
                                        <td className="details-name" style={{"width": "66%"}}>{this.state?.itemwiseScrapItemRows[0]?.item?.is_taxable == 'Y' ? 'Taxable' : 'Non - Taxable'} </td></tr>
                                    <tr>
                                        <td className="details-label">IGST Percentage</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.igst ?? 0}%</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">CGST Percentage</td>
                                        <td className="details-name">{this.state?.itemwiseScrapItemRows[0]?.item?.cgst ?? 0}%</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">SGST Percentage</td>
                                    </tr>
                                    </tbody>
                                </table>
                                {
                                    this.state.item_additional_attributes && this.state.item_additional_attributes.length > 0
                                        ? (
                                            <Ax>
                                                <div className="tab_content_wrapper">
                                                    <div>
                                                        <span className="content_heading">Additinal Attribute</span>
                                                    </div>
                                                </div>
                                                <table className="table table-hover table-borderless">
                                                    <tbody>
                                                    {this.state.item_additional_attributes.map((a, k) => (<tr key={k}>
                                                        <td className="details-label" style={{"width": "34%"}}>{a.name}</td>
                                                        <td className="details-name" style={{"width": "66%"}}>
                                                            {this.state.itemwiseScrapItemRows[0]?.item?.additional_attribute?.[a.key] ?? '-'}
                                                        </td>
                                                    </tr>))}
                                                    </tbody>
                                                </table>
                                            </Ax>
                                        )
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addNewItemModalInit                       =   ()  =>  {
        //Initialize Search Form :-
        this.addNewItemModal.show();
    }

    addItemModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="addItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Add New Item</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                        </div>
                    </div>
                </div>
            </div>
        );
    }


    addNewItemRow                           =   ()  =>  {
        this.setState({itemwiseScrapItemRows : [...this.state.itemwiseScrapItemRows, {item : null}] });
    }

    displayStringSearch                     =   (data)  =>  (data.map((i) => ({ label : (<div className="text-start">{i.name}<br/><small>(Code : {i.item_code}</small>)</div>)  ||  '', value : i.id || '', itemdata : i })));

    loadingItem                             =   (inputText,callback)  =>  {
        if(inputText.length > 2) {
            if(this.cancelToken) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }
            this.cancelToken                =   axios.CancelToken.source();
            axios({
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
                params : {term : inputText , per_page : 50},
                url: this.itemUrl,
                cancelToken : this.cancelToken.token
            }).then((response) => {
                callback(this.displayStringSearch(response.data.data))
            });
        }
    }

    onItemChange                            =   (selectedItem, k)  => {
        if(selectedItem.value && selectedItem.value > 0) {
            let item                    =   selectedItem.itemdata;
            let allRows                 =   this.state.itemwiseScrapItemRows;
            allRows[k]                  =   {
                item                        :   item,
                qty                         :   selectedItem.qty ? selectedItem.qty : 0,
                unit_rate                   :   selectedItem.unit_rate ? selectedItem.unit_rate : item.itemwiseScrap_price,
                remark                      :   selectedItem.remark ? selectedItem.remark:'',
                gst                         :   selectedItem ? selectedItem.gst : item.gst,
                qty_sts_id                  :   selectedItem ? selectedItem.qty_sts_id : ''
            };
            this.setState({itemwiseScrapItemRows : allRows});
            this.addNewItemRow();
        }
    }

    onItemInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val                             =   '';
        if(name == 'gst') {
            if(selectedOption && selectedOption.value) {
                val                         =   selectedOption.value;
            }
        } else {
            val = e.target.value;
        }
        let allRows                 =   this.state.itemwiseScrapItemRows;
        allRows[k]                  =   {...this.state.itemwiseScrapItemRows[k] , [name] : val};
        this.setState({itemwiseScrapItemRows : allRows});
    }

    removeItemRow                           =   (k) =>  {
        let allRows                 =   this.state.itemwiseScrapItemRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({itemwiseScrapItemRows : allRows});
        }
    }

    render                                  =   ()  =>  {
        return (<Ax>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>Dispose Scrap</h3>
                    <div className="text-end mt15">
                        <button onClick={this.props.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.saveItemwiseScrapFormJsx()}
                    </div>
                </div>
            </div>
            {this.itemDetailsModalJsx()}
            {this.addItemModalJsx()}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ItemwiseScrapAdd);