import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { toast } from 'react-toastify';
import swal from "sweetalert";


class InvoiceCalculationView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                        =             {
            invoiceCalcualtionId            :            null,
            details_loading                 :   false,
            invoiceData                     :   null,
            showSaveInvoiceForm             :   props.showInvoiceCalculationForm,
            calculationAdditionRows         :   [{notes : '', amount : ''}],
            calculationDeductionRows        :   [{notes : '', amount : ''}],
            assetPeriodSites                :   [],
            invoiceEditForm                 :   {
                site_id                     :  null
            },
            invoiceEditFormSubmittig        :    false,
            invoice_calculation_view        :   null,
            
        }
        this.myRef                         =       React.createRef()
    }

    componentDidMount() {
        if(this.props.Id){
            this.setState({invoiceCalcualtionId : this.props.Id},
                () => this.getInvoiceFullDetail(this.props.Id))
        }

    }

    componentWillReceiveProps(nextProps){
        if(nextProps.Id !== this.props.Id) {
            this.setState({invoiceCalcualtionId : nextProps.Id},
                () => this.getInvoiceFullDetail(nextProps.Id))
        } 
         
        if(nextProps.showSaveInvoiceForm !== this.props.showSaveInvoiceForm){
             this.setState({showSaveInvoiceForm : nextProps.showSaveInvoiceForm},() => {
                 this.showInvoiceCalculationForm()
             })
        }    
    }

    getInvoiceFullDetail         =        (id)        =>  {

        this.setState({details_loading: true})
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/invoice/saved_calculation/' + id, this.props.access_token,{},{},(response) => {
            
            let invoiceData = [];
            this.setState({invoice_calculation_view:response.data.data});
            invoiceData.push(response.data.data.invoice_calculation);
            this.setState({invoiceData,asset_total_invoice             :   response.data.data ?  response.data.data.invoice_amount : 0})

            //function to set edit data
            let calculationAdditionRows = []; let calculationDeductionRows = [];
            response.data.data.addition.forEach( pushAddititonRows);
            response.data.data.deduction.forEach(pushDeductionRows);

            function pushAddititonRows(item) {
                calculationAdditionRows.push(item)
            }
            function pushDeductionRows(item) {
                calculationDeductionRows.push(item)
            }

            this.setState({calculationAdditionRows,calculationDeductionRows, invoiceEditForm : {
                    site_id: response.data.data.site !== null ? response.data.data.site.tap_id : "",
            }})
        },(error) => {
         
        }).then(() => {
            this.setState({details_loading: false});
        });
    }


    showInvoiceCalculationForm              =   (id)  =>  {
       
        this.loadAssetSitesAtGivenPeriod();
        this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    loadAssetSitesAtGivenPeriod             =   ()  =>  {
        let reportFormData                      =   this.state.invoice_calculation_view;
        let period_end                          =   reportFormData.period_end.split(' ');
        let period_start                        =   reportFormData.period_start.split(' ')
        let params                              =   {
            asset_id                                :   reportFormData.asset.asset_id,
            date_range                              : "custom",
            date_range_end                          :   period_end[0],
            date_range_start                        :   period_start[0],
        };
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/site', this.props.access_token, params, {}, (response) => {
            this.setState({
                assetPeriodSites                    :   [...response.data.data].map((s) => {
                    return { value : s.tap_id,site_id : s.id, label : s.site_name + '(' +s.site_code + ')' };
                })
            }, () => {
                if(this.state.assetPeriodSites.length == 1) {
                    this.tapSelectChange(this.state.assetPeriodSites[0], 'invoiceSaveForm', 'site_id');
                }
            });
        });
    }

    //steps for edit invoice

    removeManualAdditonRow                  =   (k)  =>  {
        let allRows                 =   this.state.calculationAdditionRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({calculationAdditionRows : allRows});
        }
    }

    addNewCalculationAdditionRow            =   ()  =>  {
        this.setState({calculationAdditionRows : [...this.state.calculationAdditionRows, {notes : '', amount : ''}] });
    }

    removeManualDedctionRow                 =   (k)  =>  {
        let allRows                 =   this.state.calculationDeductionRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({calculationDeductionRows : allRows});
        }
    }

    addNewCalculationDeductionRow           =   ()  =>  {
        this.setState({calculationDeductionRows : [...this.state.calculationDeductionRows, {notes : '', amount : ''}] });
    }

    editInvoiceCalculationFormSubmit       =   (event)  =>  {
        
        event.preventDefault();
        let additionRowErrors               =   this.state.calculationAdditionRows.map(a => (a.notes && !a.amount) || (!a.notes && a.amount) ? 1 : 0);
        let deductionRowErrors              =   this.state.calculationDeductionRows.map(a => (a.notes && !a.amount) || (!a.notes && a.amount) ? 1 : 0);
        this.setState({invoiceEditFormSubmittig : true})
        if(additionRowErrors.includes(1)) {
            toast.error('Please enter both (Notes and Amount) are required for manual Addition');
        } else if(deductionRowErrors.includes(1)) {
            toast.error('Please enter both (Notes and Amount) are required for manual Deduction');
        } else {
            let site_id     =  this.state.assetPeriodSites.find(id => id.value == this.state.invoiceEditForm.site_id)
            let invoiceSaveForm                 =   {
                site_id             :  site_id.site_id,
                transaction_id      :   this.state.invoiceCalcualtionId,
                addition            :   this.state.calculationAdditionRows.filter(a => a.notes && a.amount),
                deduction           :   this.state.calculationDeductionRows.filter(a => a.notes && a.amount)
            };


         /*   this.setState({invoiceSaveFormSubmittig : true});*/
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_IAM_API_SERVER + '/invoice/calculation_save', this.props.access_token, {}, invoiceSaveForm, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.props.cancelEdit()
                this.getInvoiceFullDetail(this.state.invoiceCalcualtionId)
            })
                .then(() => {
                    this.setState({invoiceSaveFormSubmittig : false});
                });
        }
    }


    invoiceCalculationViewJsx                      =       ()             =>        {
        let invoiceHeadings             =   this.state.invoice_calculation_view?.invoice_keys;
        let invoiceCalculation          =   this.state.invoice_calculation_view;
        let invoiceData                 =   this.state.invoiceData;
     
        return (<div id="editViewInvoiceForm" >
            {this.state.details_loading
                ? <Loader /> : <Ax>
                    {this.state.invoice_calculation_view ?
                        <Ax>
                            <table className="table table-bordered table-hover table-fixed bg-white">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "30%" }} className="details-label">Invoice Number</td>
                                        <td colSpan="6" className="details-name text-end">{invoiceCalculation.transaction_id}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "30%" }} className="details-label">Site Name (Code)</td>
                                        <td colSpan="6" className="details-name text-end">
                                            {invoiceCalculation.site.site_name} ({invoiceCalculation.site.site_code})
                                        </td>
                                    </tr>
                                    <tr><td colSpan="7"></td></tr>
                                    {Object.keys(invoiceCalculation.invoice_keys).sort().map((heading_key, k) => {
                                        return (<tr key={k}>
                                            <th colSpan={['00-b', '08-b', '15-b', '24-b', '30-b'].includes(heading_key) ? ((invoiceData.length * 5) + 1) : 1} className={['table-light', (heading_key == '08-b') ? 'table-secondary' : ''].join(' ')} style={{ width: "30%" }}>{invoiceHeadings[heading_key] ? invoiceHeadings[heading_key] : ''}</th>
                                            {(['00-b', '07-b', '08-b', '15-b', '24-b', '30-b'].includes(heading_key))
                                                ? null
                                                : (invoiceData.map((invoice, kk) => {
                                                    if (invoice.hasOwnProperty(heading_key)) {
                                                        if (Array.isArray(invoice[heading_key])) {
                                                            return invoice[heading_key].map((invoiceRow, index) => {
                                                                return <td key={kk + -+index} className={['text-end', [0, 1, 2].includes(index) ? 'text-center' : '', ['Limit', 'Actual', 'Excess', 'Unit Rate', 'Amount'].includes(invoiceRow) ? 'bold' : ''].join(' ')}>{invoiceRow}</td>
                                                                if (invoiceRow !== '') {
                                                                    return <td className={`text-end ${['Limit', 'Actual', 'Excess', 'Unit Rate', 'Amount'].includes(invoiceRow) ? 'bold' : ''} ${[0, 1, 2].includes(invoiceRow) ? 'text-center' : ''}`}>{invoiceRow}</td>
                                                                } else {
                                                                    return <td key={kk + -+index} className="text-end"></td>
                                                                }
                                                            })
                                                        } else {
                                                            return <td key={heading_key + k} colSpan={5} className={[['23-subTotal_addition', '29-subTotal_deduction', '31-total_invoice'].includes(heading_key) ? 'bold' : '', ['081-period', '09-basic_rate', '10-days', '11-days_actual', '12-days_billable', '13-day_rate', '14-amount_basic', '23-subTotal_addition', '29-subTotal_deduction', '31-total_invoice'].includes(heading_key) ? 'text-end' : ''].join(' ')} >{invoice[heading_key]}</td>
                                                        }
                                                    } else {
                                                        return <td key={k} colSpan={5}></td>;
                                                    }
                                                }))
                                            }
                                        </tr>);
                                    })}
                                </tbody>
                            </table>
                            <div id="editForm" ref={this.myRef}> {this.state.showSaveInvoiceForm ? this.editInvoiceCalculationJsx() :
                                <table className="table table-hover align-middle table-bordered bg-white">
                                    <tbody>
                                        <tr className="table-secondary"><th colSpan="3">Manual Addition</th></tr>
                                        <tr className="table-light">
                                            <td style={{ width: "10%" }}>#</td>
                                            <th style={{ width: "60%" }}>Notes</th>
                                            <th style={{ width: "30%" }}>Amount</th>
                                        </tr>
                                        {invoiceCalculation.addition.length > 0 ? invoiceCalculation.addition.map((add, index) => {
                                            return (<tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{add.notes}</td>
                                                <td className="text-end">{add.amount}</td>
                                            </tr>);
                                        }) : <tr><td colSpan={3}>No Addition</td></tr>}
                                        <tr className="table-secondary"><th colSpan="3">Manual Deduction</th></tr>
                                        <tr className="table-light">
                                            <td>#</td>
                                            <th>Notes</th>
                                            <th>Amount</th>
                                        </tr>
                                        {invoiceCalculation.deduction.length > 0 ? invoiceCalculation.deduction.map((add, index) => {
                                            return (<tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{add.notes}</td>
                                                <td className="text-end">{add.amount}</td>
                                            </tr>);
                                        }) : <tr><td colSpan={3}>No Deduction</td></tr>}
                                        <tr className="table-secondary text-end">
                                            <th colSpan="3">Total Amount (Invoice Amount + Manual Addition - Manual Deduction) :  {invoiceCalculation.total_amount}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            </div>
                        </Ax>
                        : null}
                </Ax>}
        </div>)
    }

    editInvoiceCalculationJsx               =     () =>  {
        return (<Ax>
            <form onSubmit={this.editInvoiceCalculationFormSubmit} >
                <table className="table table-hover align-middle table-bordered bg-white" >
                    <tbody>
                        <tr className="table-secondary"><th colSpan="4">Manual Addition</th></tr>
                        <tr className="table-light">
                            <td style={{ width: "10%" }}>#</td>
                            <th style={{ width: "40%" }}>Notes</th>
                            <th style={{ width: "30%" }}>Amount</th>
                            <th style={{ width: "20%" }}></th>
                        </tr>
                        {this.state.calculationAdditionRows.map((i, k) => {
                            return (<tr key={k}>
                                <td></td>
                                <td>
                                    <input
                                        type="text"
                                        name="notes"
                                        value={this.state.calculationAdditionRows[k].notes}
                                        className="form-control form-control-sm"
                                        placeholder="Notes"
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationAdditionRows') }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name="amount"
                                        value={this.state.calculationAdditionRows[k].amount}
                                        className="form-control text-end form-control-sm"
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationAdditionRows') }}
                                        placeholder="Amount"
                                        autoComplete="off"
                                        min={1}
                                        step=".01"
                                        maxLength={9}
                                    />
                                </td>
                                <th className="text-center">
                                    <a href="#" onClick={() => this.removeManualAdditonRow(k)}><TapIcon.imageIcon icon={TapIcon.CloseCircleIcon} className="img-fluid" /></a>
                                </th>
                            </tr>);
                        })}
                        <tr><td className="text-end" colSpan="4">
                            <a role="button" onClick={this.addNewCalculationAdditionRow} className="link-primary"><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} /> Add Addition Amount </a>
                        </td></tr>
                        <tr className="table-secondary"><th colSpan="4">Manual Deduction</th></tr>
                        <tr className="table-light">
                            <td style={{ width: "10%" }}>#</td>
                            <th style={{ width: "55%" }}>Notes</th>
                            <th style={{ width: "15%" }}>Amount</th>
                            <th style={{ width: "20%" }}></th>
                        </tr>
                        {this.state.calculationDeductionRows.map((i, k) => {
                            return (<tr key={k}>
                                <td></td>
                                <td>
                                    <input
                                        type="text"
                                        name="notes"
                                        value={this.state.calculationDeductionRows[k].notes}
                                        className="form-control form-control-sm"
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationDeductionRows') }}
                                        placeholder="Notes"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name="amount"
                                        value={this.state.calculationDeductionRows[k].amount}
                                        className="form-control text-end form-control-sm"
                                        onChange={(e) => { this.dynamicInputHandlerByKey(e, k, 'calculationDeductionRows') }}
                                        placeholder="Amount"
                                        autoComplete="off"
                                        min={1}
                                        step=".01"
                                        maxLength={9}
                                    />
                                </td>
                                <th className="text-center" style={{ width: "10%" }}>
                                    <a href="#" onClick={() => this.removeManualDedctionRow(k)}> <TapIcon.imageIcon icon={TapIcon.CloseCircleIcon} className="img-fluid" /></a>
                                </th>
                            </tr>);
                        })}
                        <tr><td className="text-end" colSpan="4">
                            <a role="button" onClick={this.addNewCalculationDeductionRow} className="link-primary"><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} /> Add Deduction Amount </a>
                        </td></tr>
                        <tr className="table-secondary text-end">
                            <th colSpan="4">Total Amount (Invoice Amount + Manual Addition - Manual Deduction) : {
                                (parseFloat(this.state.asset_total_invoice)
                                    + (parseFloat(this.state.calculationAdditionRows.reduce((sum, a) => sum + (parseFloat(a.amount ? a.amount : 0)), 0)))
                                    - (parseFloat(this.state.calculationDeductionRows.reduce((s, b) => s + parseFloat(b.amount ? b.amount : 0), 0)))).toFixed(2)
                            }</th>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-hover align-middle table-bordered bg-white m0 p0">
                    <tbody>
                        <tr className="bg-white"><th colSpan="4"></th></tr>
                        <tr className="">
                            <th style={{ width: "10%" }}>Site </th>
                            <td style={{ width: "30%" }}>
                                <TapSelect
                                    options={this.state.assetPeriodSites}
                                    menuPlacement="top"
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'invoiceEditForm', 'site_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    required={true}
                                    value={this.state.assetPeriodSites.find(s => s.value == this.state.invoiceEditForm.site_id)}
                                    placeholder="Select Site"
                                    containerHeight="30px"
                                    fontSize="93%"
                                />
                            </td>

                        </tr>
                        <tr>
                            <td className="text-end" colSpan="4">
                                <button type="button" className="btn btn-light mx-4 px-4" onClick={this.props.cancelEdit}>Cancel</button>
                                <button type="submit" className="btn btn-primary  px-4" disabled={this.state.invoiceEditFormSubmittig}>
                                    Submit {this.state.invoiceEditFormSubmittig ? <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" /> : null}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </form>
        </Ax>)
    }

    render                              =   ()  =>  {

        return (<Ax>
          {this.invoiceCalculationViewJsx()}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : []
    };
};

export default connect(mapStateToProps)(InvoiceCalculationView);