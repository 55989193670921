import React from 'react';
import { Link } from 'react-router-dom';

const TapLink = ({ pathname, state, conditions,onClick, children }) => {
    const canEdit = conditions.canEdit;

    const handleClick = (event) => {
        if (canEdit == 'N') {
            event.preventDefault();
            return;
        }

        if (onClick) {
            onClick(event);
        }
    };

    return (
        <Link
            to={{
                pathname: canEdit == 'Y' ? pathname : '',
                state: canEdit == 'Y' ? state : undefined,
            }}
            className={[
                'dropdown-item',
                'cursor_pointer',
                canEdit == 'Y' ? '' : 'disabled',
            ].join(' ')}
            title={
                conditions.canShowTitle == 'N'
                    ? ''
                    : 'You do not have permission to perform this action'
            }
            style={{
                pointerEvents: "all",
            }}
            onClick={handleClick}
        >
            {children}
        </Link>
    );
};

export default TapLink;
