import React from 'react';
import {connect} from "react-redux";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import AppBaseComponent from '../../../components/AppBaseComponent';
import * as actions from '../../../store/actions/index'
import Helmet from 'react-helmet';
import axios from 'axios';
import DownloadFile from "../../../services/DownloadFile";
import { toast } from 'react-toastify';
import Chart from 'react-google-charts';


 class AssetMatrixSearch extends AppBaseComponent {

    constructor() {
        super();

        this.initMatrixSearchForm         =       {
            search_by_keywords                 :         ''
        }

        this.matrixSearchFormLabel     =       {
            search_by_keywords                 :         'Asset Matrix : '
        }

        this.state = {
            minimizeTable                    :          false,
            formSearchedElems                :          [],
            iam_asset_types                  :          [],
            iam_manufactures                 :          [],
            iam_models                       :          [],
            asset_counterlog_meter           :          [],
            asset_matrix_type                :          [],
            listing_loading                  :          false,
            listing_tbl_page                 :          1,
            matrix_listing                   :          [],
            listingMeta                      :          null,
            totalListingCount                :          0,
            asset_fuel_types                 :          [],
            formDataLoaded                   :          false,
            matrix_param                     :          {},
            matrix_listing_loaded            :          false,
            matrix_meta                      :           null,
            matrix_total_count               :            0,
            assetBulkUploadFormSubmiting     :          false,
            matrixSearchForm                 :          {...this.initMatrixSearchForm},
            submittedMatrixFilterForm        :          {...this.initMatrixSearchForm},
        }
    }

    componentDidMount(){  
       if(this.props && this.props.location && this.props.location.state && this.props.location.state.search_by_keywords){
          this.setState({
             matrixSearchForm                 :          {...this.initMatrixSearchForm,search_by_keywords :this.props.location.state.search_by_keywords },
             submittedMatrixFilterForm        :          {...this.initMatrixSearchForm,search_by_keywords :this.props.location.state.search_by_keywords},
          },() => this.submitMatrixSearchForm())
       }
        
    }

    submitMatrixSearchForm      =       (event)          =>      {
        event && event.preventDefault()
       
        let serachFormKeys                      =   Object.keys(this.state.matrixSearchForm);
    
        let searchedElems                       =   [];

        serachFormKeys.map((key)        =>  {
            let label                       =   this.matrixSearchFormLabel[key];
            let value                       =   this.state.matrixSearchForm[key];
            if(value && value.toString().length > 0) {
                
                if(label) {
                    let show_val            =   value;
                   
                    if(key == 'search_by_keywords') {
                        show_val            =   this.state.matrixSearchForm.search_by_keywords ? this.state.matrixSearchForm.search_by_keywords : '' ;
                    }
                    
                    searchedElems.push(label + show_val);
                }
            }
        });
        
        this.setState({
            submittedMatrixFilterForm           :    {...this.state.matrixSearchForm},
            formSearchedElems                   :     searchedElems,
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page,showTable : true});
        let params                      =   {...this.state.submittedMatrixFilterForm,page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/asset_matrix', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                matrix_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    clearSearchForm         =       ()      =>      {
        this.setState({
            matrixSearchForm                 :          {...this.initMatrixSearchForm},
            formSearchedElems                :          [],
            showTable                        :          false,
            submittedMatrixFilterForm        :          {...this.initMatrixSearchForm},
        }, () => {
           
        })
    }

    goToAddAsset                =               (id,asset_matrix_unique_id)          =>      {
        this.props.history.push({ pathname: `/add_asset/${id}`, state: { matrixId: asset_matrix_unique_id,search_by_keywords:this.state.matrixSearchForm?.search_by_keywords,routedFrom:'Asset Matrix Search' } })
      
    }

    closeEvent          =       ()      =>      {
        this.props.history.push('/measurable_asset')
    }


    assetMatrixSearchJsx            =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={"col-12"}>
                <h3>Search Asset Matrix </h3>
                {!this.state.minimizeTable
                    ? <div className="text-end mt15">
                       <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                       
                    </div>
                    : null}
            </div>

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', 'col-12'].join(' ')} >{this.assetMatrixTableJsx()}</div>
                </div>
            </div>
        </Ax>);
    }

    assetMatrixTableJsx            =   ()   =>   {
        return (<Ax>
            <div className="card">
                <div className="card-body">

                    <form id="matrixSearchForm" onSubmit={this.submitMatrixSearchForm}>
                        <div className="row  align-items-center">
                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Asset Matrix</label>
                            <div className="col-sm-8">
                                <input
                                    name="search_by_keywords"
                                    type="text"
                                    value={this.state.matrixSearchForm.search_by_keywords}
                                    className="form-control"
                                    autoComplete="off"
                                    onChange={(e) => this.formInputHandler(e, "matrixSearchForm")}
                                    placeholder="Search Asset Matrix"
                                    style={{ height: "30px", fontSize: "93%" }}
                                />
                            </div>
                            <div className='col-sm-2'>
                                <button type="submit" className='btn btn-primary'>Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.clearSearchForm} />
            {this.state.showTable == true ? <Ax>
                <table className="table table-bordered bg-white ">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "10%" }} className="text-center">Asset Matrix ID</th>
                            <th scope="col" style={{ width: "19%" }}>Asset Type</th>
                            <th scope="col" style={{ width: "20%" }}>Manufacturer</th>
                            <th scope="col" style={{ width: "11%" }}>Model</th>
                            <th scope="col" style={{ width: "11%" }}>Variant</th>
                            <th scope="col" style={{ width: "11%" }}>Capacity</th>
                            <th scope="col" style={{ width: "8%" }}>Fuel Type</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? (<tr><td colSpan="9"><Loader /></td></tr>)
                            : (this.state.matrix_listing.length > 0
                                ? (this.state.matrix_listing.map((item, index) => {
                                    return (<tr key={index} >

                                        <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                        <td className="text-center">  {item.asset_matrix_unique_id}   </td>
                                        <td>{item.assetType ? item.assetType.asset_type_name : "-"}</td>
                                        <td>{item.manufacturer ? item.manufacturer.manufacturer : "-"}</td>
                                        <td>{item.model_detail ? item.model_detail.model : "-"}</td>
                                        <td>{item.variant ? item.variant : "-"}</td>
                                        <td>{item.capacity ? item.capacity : "-"}</td>
                                        <td>{item.fuelType ? item.fuelType.fuel_type : "-"}</td>
                                        <td className='text-center'><button type="button" className='btn btn-primary' onClick={() => this.goToAddAsset(item.id,item.asset_matrix_unique_id)}>Add</button></td>

                                    </tr>)
                                }))
                                : (<tr><td colSpan="9" className="text-center">No Records</td></tr>)
                            )
                        }
                    </tbody>
                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                />
            </Ax> : null}
        </Ax>)
    }

    


    render() {
      
        return (
            <ApplicationLayout>
             <Helmet><title>Asset Matrix Search List</title></Helmet>
                {this.assetMatrixSearchJsx()}
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
   };
};

export default connect(mapStateToProps)(AssetMatrixSearch);

