import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import {Modal} from "bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import errorValidation from "../../services/ErrorValidation";

export default class FeedbackModal extends React.Component {

    constructor() {
        super();
        this.state = {
            feedback  : {
                stars                  :     '0',
                feedback_suggestion    :      "",
                feedback_comment       :      ""
            },
        }
        this. handleFeedbackChange   = this.handleFeedbackChange.bind(this);
        this.addFeedback             =  this.addFeedback.bind(this)

    }

    componentDidMount() {
        this.addFeedbackModal          =        new Modal(document.getElementById('addFeedbackModal'), {keyboard: false, backdrop :false});
        this.addFeedbackModal.show();
    }

    addFeedback(event){
        event.preventDefault();

        const access_token = localStorage.getItem('access_token');
        let frmData = {
            feeback_status      :  1,
            rating               : parseInt(this.state.feedback.stars),
            feedback_comment     : this.state.feedback.feedback_comment,
            feedback_suggestion  :  this.state.feedback.feedback_suggestion
        };
        axios({
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER_2 + '/feedback'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.addFeedbackModal.hide()
            this.props.close()
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.')
        });

    }

    close = () => {
        this.addFeedbackModal.hide()
    }

    feedbackmodalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addFeedbackModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Feedback</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.props.close()}></button>
                        </div>
                        <form  id="addModalForm" onSubmit={this.addFeedback}>
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label">Star Rating</label></div>
                                    <div className="col-8">
                                        <div className="rating">
                                            <label>
                                                <input type="radio" name="stars" value={1}
                                                       onChange={(e) => this.handleFeedbackChange(e)}
                                                />
                                                <span className="icon">★</span>
                                            </label>
                                            <label>
                                                <input type="radio" name="stars" value={2} onChange={(e) => this.handleFeedbackChange(e)}/>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                            </label>
                                            <label>
                                                <input type="radio" name="stars" value={3} onChange={(e) => this.handleFeedbackChange(e)}/>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                            </label>
                                            <label>
                                                <input type="radio" name="stars" value={4} onChange={(e) => this.handleFeedbackChange(e)}/>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                            </label>
                                            <label>
                                                <input type="radio" name="stars" value={5} onChange={(e) => this.handleFeedbackChange(e)}/>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                                <span className="icon">★</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label">What you like?</label></div>
                                    <div className="col-8">
                                        <textarea
                                            name="feedback_comment"
                                            // className="form-control"
                                            rows="3"
                                            style={{width:"100%",border: "1px solid #d0d0d0"}}
                                            value={this.state.feedback.feedback_comment}
                                            placeholder="Please share what you like the most"
                                            onChange={(e) => this.handleFeedbackChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-4"><label className="form-label">Improvement / Suggestions</label></div>
                                    <div className="col-8">
                                        <textarea
                                            name="feedback_suggestion"
                                            value={this.state.feedback.feedback_suggestion}
                                            rows="3"
                                            style={{width:"100%",border: "1px solid #d0d0d0"}}
                                            placeholder="Please share your suggestion for us to improve"
                                            onChange={(e) => this.handleFeedbackChange(e)}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="submit"   className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    handleFeedbackChange(e){
        let feedback = this.state.feedback
        feedback[e.target.name] = e.target.value
        this.setState({feedback})
    }

    render() {
        return (
            this.feedbackmodalJsx()
        )
    }
}
