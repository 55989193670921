import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls from '../../../services/TapApiUrls';
import { Link } from 'react-router-dom';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';

class ItemsConsumptionHistory extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                            =         {
            consumtionHistoryList             :         [],
            consumptionDetailLoading          :         false,
            asset_id                          :         null,
            workstation_id                    :         null,
            listing_tbl_page                  :          1,
            listingMeta                       :          null,
            totalListingCount                 :         0,
            all_item_ids                      :         []
        };
    }
    
    
    
    componentDidMount(){
        this.viewConsumptionHistoryModal             =   new Modal(document.getElementById('viewConsumptionHistoryModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (all_item_ids,asset_id)  =>  {
        this.viewConsumptionHistoryModal.show()
          
            this.setState({
            all_item_ids    :       all_item_ids && all_item_ids.length > 0 ? all_item_ids.map(i => i) : [],
            asset_id        :        asset_id ? asset_id : null
            },() => this.fetchConsumptionHistoryData())
          
    }

    fetchConsumptionHistoryData     =       (page = 1)      =>      {
        this.setState({consumptionDetailLoading : true, consumtionHistoryList : [],listing_tbl_page: page});
        let params =  {
                    search_item_ids             :    this.state.all_item_ids,
                    page                        :    page,
                    search_asset_ids            :    this.state.asset_id !== undefined && this.state.asset_id !== null ? [this.state.asset_id] : []}
                    
        HttpAPICall.withAthorization('GET', TapApiUrls.INVENTORY + '/report/item-wise-consumption', this.props.access_token, {...params}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                consumtionHistoryList :    respData.data,
                listingMeta           :     respData,
                totalListingCount     :     respData.total,
            },()=>  this.viewConsumptionHistoryModal.show())
        
        }).then(() => {
            this.setState({consumptionDetailLoading : false});
        });
    }

    
      consumptionHistoryModalJsx                     =   ()  =>  {
          return (
              <div className="modal fade" id="viewConsumptionHistoryModal" tabIndex="-1">
                  <div className="modal-dialog modal-xl modal-dialog-scrollable">
                      <div className="modal-content  ">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addItemModalLabel">Consumption History</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>

                          <div className="modal-body ">
                              {!this.state.consumptionDetailLoading ?
                                  <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                                      <thead className="align-middle table-secondary">
                                          <tr className="text-center">
                                              <th style={{ width: "10%" }}>Transaction ID</th>
                                              <th style={{ width: "10%" }}>Date</th>
                                              <th style={{ width: "14%" }}>Item Name</th>
                                              <th style={{ width: "14%" }}>Warehouse</th>
                                              <th style={{ width: "15%" }}>Asset Name</th>
                                              <th scope="col" style={{ width: "9%" }} > Status</th>
                                              <th scope="col" style={{ width: "8%" }} > Qty</th>
                                              <th scope="col" style={{ width: "8%" }} className="text-end" > Amount</th>
                                              <th scope="col" style={{ width: "10%" }} className="text-end" >Asset CounterLog</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {this.state.consumtionHistoryList.length > 0
                                              ? (this.state.consumtionHistoryList.map((rowData, k) => {
                                                  return rowData.stock_items.map((st, sk) => {
                                                      return (<tr>
                                                          <td className="text-center"><Link target='_blank' to={{ pathname: "/consumption_list", search: "?" + new URLSearchParams({ 'search_id': rowData.transaction_id }).toString() }}>{rowData.transaction_id}</Link></td>
                                                          <td className="text-center">{rowData.transaction_date_display}</td>
                                                          <td>{rowData.stock_items && rowData.stock_items.length > 0 ? rowData.stock_items[0].item_name : "-" }</td>
                                                          <td>{rowData.warehouse_name}</td>
                                                          <td>{rowData.assetProfile ? rowData.assetProfile.name : "-"}</td>
                                                          <td className="text-center"><Status color={rowData.status_color_code}>{rowData.status_text}</Status></td>
                                                          <td className="text-center">{st.qty}</td>
                                                          <td className="text-end">{st.amount}</td>
                                                          <td className="text-end">{rowData.counterlog && rowData.counterlog.display && rowData.counterlog.display.length > 0 ? rowData.counterlog.display.map(item => { return (<div className="mt-1">{item}</div>) }) : null}</td>
                                                      </tr>);
                                                  })
                                              }))
                                              : (<tr><td className="text-center" colSpan={6}>No Record Found</td></tr>)
                                          }
                                      </tbody>
                                  </table>
                                  : <Loader />}
                              <DisplayListPagination
                                  meta={this.state.listingMeta}
                                  onPageChange={(e) => this.fetchConsumptionHistoryData(e.selected + 1)}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          );
    }

    render() {
        return (<Ax>{this.consumptionHistoryModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemsConsumptionHistory);
