import React from 'react';
import style from './Index.module.css';

class DisplaySearchCriteria extends React.Component {
    
    constructor(props) {
        super(props);
        this.state              =   {
            searchedElems       :   [],
            onClearFilter       :   null,
        };
        this.initilaizeState(props);
    }
    
    componentWillReceiveProps(nextProps) {
        this.initilaizeState(nextProps);
    }
    
    initilaizeState         =   (props)  =>  {
        setTimeout(() => {
            this.setState({
                searchedElems           :   props.searchedElems || [],
                onClearFilter           :   props.onClearFilter || null
            });
        },1000)
        
    }
    
    render                  =   ()  =>  {
        return (
            this.state.searchedElems && this.state.searchedElems.length > 0
            ? (<div className={[style.filtered_search].join( )}>
                    {this.state.onClearFilter ?  <button type="button" onClick={this.state.onClearFilter} className={['btn', 'btn-link', style.filter_clear_link].join(' ')}>Clear</button>
                        : null}
                    <ul>
                        <li>Search Criteria</li>
                        {this.state.searchedElems.map((ise, k) => <li key={k} className='text-capitalize'><div dangerouslySetInnerHTML={{__html: ise.replace(/(<? *script)/gi, 'illegalscript')}} ></div></li>)}
                    </ul>
                </div>)
            : null
        );
    };
}

export default DisplaySearchCriteria;