import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls from '../../../services/TapApiUrls';
import TapSelect from '../../../components/ui/TapSelect';

class ChangeInvoiceRule extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state              =         {
            assetId                    :         null,
            invoiceRuleCurrentName     :         '',
            changeInvoiceRule          :          {
                invoice_rule_id        :          ''
            },
            form_loading               :         false,
            allInvoicesList            :         []
        };
    }
    
    
    
    componentDidMount(){
        this.linkAssetModal                     =   new Modal(document.getElementById('linkAssetModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (asset_id,invoice_rule,key = null)  =>  {
        this.linkAssetModal.show()
        this.setState({form_loading : true,changeInvoiceRule : {
            invoice_rule_id : ''
        }})
        this.getAssetFormData(asset_id,invoice_rule,key)
    }

    getAssetFormData        =       (asset_id,invoice_rule,key)      =>      {
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/invoice_rule_attach/formdata', this.props.access_token, {asset_id:asset_id}, null, (response) => {
            let formData = response.data;
           
            this.setState({
                assetId                     :       asset_id,
                invoiceRuleCurrentName      :       key !== undefined && key !== null ? key : invoice_rule ? `${invoice_rule.name} (${invoice_rule.rule_number})` : 'NA',
                allInvoicesList             :       formData && formData.length > 0 ? formData.map(s => {return({value : s.id,label : `${s.name} (${s.rule_number})`})}) : []
            })       
         }).then(() => this.setState({ form_loading : false }));
    }

    submitLinkInvoicForm        =   (e)      =>  {
        e.preventDefault();
        let formData = {
            ...this.state.changeInvoiceRule,
            asset_id : this.state.assetId
        }
        this.setState({ saveFormSubmitting: true });
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/invoice_rule_attach/asset', this.props.access_token, {}, { ...formData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkAssetModal.hide()
            this.props.getAssetInvoiceListing(this.props.page)
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }
    
     //***********************CANCEL ITEM JSX****************
     changeInvoiceRuleModalJsx               =        ()             =>        {
      
        return (
            <div className="modal fade" id="linkAssetModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Link Invoice Rule</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form onSubmit={this.submitLinkInvoicForm}>
                            <div className="modal-body">
                                {this.state.form_loading ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center mb-3">

                                            <label className="col-sm-3 col-form-label col-form-label-sm ">Current Invoice Rule</label>
                                            <div className="col-sm-9">
                                                <input
                                                    name="invoiceRuleCurrentName"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={true}
                                                    value={this.state.invoiceRuleCurrentName}
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>

                                        </div>
                                        <div className="row align-items-center mb-3">

                                            <label className="col-sm-3 col-form-label col-form-label-sm ">New Invoice Rule</label>
                                            <div className="col-sm-9">
                                                <TapSelect
                                                    options={this.state.allInvoicesList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'changeInvoiceRule', 'invoice_rule_id');
                                                    }}

                                                    value={this.state.allInvoicesList.find(s => this.state.changeInvoiceRule.invoice_rule_id == s.value)}
                                                    placeholder="Select Invoices"
                                                    isSearchable={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>

                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" >Close </button>
                                <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary">Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (<Ax>{this.changeInvoiceRuleModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ChangeInvoiceRule);
