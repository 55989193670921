import React from "react";
import AppBaseComponent from "../../components/AppBaseComponent";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import Ax from "../../components/hoc/Ax";
import TapIcon from "../../services/TapIcon";
import Status from "../../components/ui/Status";
import HttpAPICall from "../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../services/TapApiUrls";
import Loader from "../../components/ui/Loader/Loader";
import {Modal} from "bootstrap";
import TapSelect from '../../components/ui/TapSelect';
import { toast } from "react-toastify";
import swal from "sweetalert";

class ProjectStructureDetail extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initalAddTicket =  {
            parent_ticket_id    :   '',
            header              :   '',
            ticket_type_id             :   '',
            description         :   '',
            estimate_days       :   '',
        }

        this.state          =   {
            detail_loading      :   false,
            formSubmitting      :   false,
            isEdit              :   false,
            modal_loading       :   false,
            ticketDetailShow    :   false,
            addChild            :   false,
            detailsData         :   null,
            addTicketData       :   {...this.initalAddTicket},
            ticketType          :   [],
            ticketsFormData     :   [],
            ticketDetail        :   null,
            edit_ticket_id      :   null,
            ticketLevel         :   0,
            ticketId            :   null,

        }

        this.projectStructureUrl    =   IAM_API_BASE_URL_2 + '/project_structure'; 
    }
    
    componentDidMount() {
        this.projectDetailsData(this.props.projectStructureId);
        this.ticketModal  =   new Modal(document.getElementById('ticketModal'), {keyboard: false, backdrop :false}); 
        this.ticketDetailModal  =   new Modal(document.getElementById('ticketDetailModal'), {keyboard: false, backdrop :false}); 
    }

    projectDetailsData  =   (id)  =>  {
        if(id) {
            this.setState({detail_loading: true});  
            HttpAPICall.withAthorization('GET', this.projectStructureUrl + '/detail/' + id,
            this.props.access_token, null, null, (response) => {
                const detail = response.data;
                this.setState({
                    detailsData :   detail.data,
                }, () => {
                    this.props.projectDetail(detail.data)
                });
            }).then(() => this.setState({detail_loading: false}));
        }
    }

    ticketDelete = (id) => {
        swal({
            title: "Delete",
            text: "Are you sure want to delete this ticket?",
            icon: "warning",
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.projectStructureUrl + '/ticket/delete/' + id,
                    this.props.access_token, null, null, (response) => {
                    toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER});
                    this.projectDetailsData(this.state.detailsData.id);
                });
            }
        });
    }

    cardDetailJsx   =   ()  =>  {
        const details = this.state.detailsData;
        return (
            <table className='table table-bordered align-middle bg-white'>
                <tbody>
                    <tr className="mb-2">
                        <td style={{ width: "15%" }}>Name </td>
                        <th style={{ width: "40%" }}>{details?.name}</th>
                        <td className="text-end" style={{ width: "15%" }}>Status </td>
                        <th><Status color={details?.status?.color_code} style={{ width: "30%" }}>{details?.status?.status}</Status></th>
                    </tr>
                    <tr>
                        <td style={{ width: "15%" }}>No. of Tickets</td>
                        <th style={{ width: "40%" }}>{details?.count_tickets ? details.count_tickets : '-'}</th>
                        <td style={{ width: "15%" }}>Critical Path Days </td>
                        <th style={{ width: "30%" }}>{details?.critical_path_days}</th>
                    </tr>
                    <tr>
                        <td style={{ width: "15%" }}>Description </td>
                        <td colSpan={3}>{details?.notes ? details.notes : '-'}</td>
                    </tr>
                </tbody>
            </table>
        )
    }        

    tableDetailsJsx =   ()  =>  {
        const details = this.state.detailsData;
        return (
            <table className="table table-hover table-bordered table-responsive bg-white table-fixed">
                <thead className="table-secondary" >
                    <tr>
                        <th scope="col" style={{ width: "10%" }}>S.No.</th>
                        <th scope="col" style={{ width: "40%" }}>Header</th>
                        <th scope="col" style={{ width: "20%" }}>Type</th>
                        <th scope="col" style={{ width: "20%" }}>Estimate</th>
                        <th scope="col" style={{ width: "10%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    details && details.tickets && details.tickets.length > 0 
                    ? this.detailTableTr(details.tickets)
                    : <tr className="text-center"><td colSpan={7}>No Record Found</td></tr>
                    
                }
                </tbody>
            </table>
        )
    }

    detailTableTr = (tickets, parent_sr_num = null) => {
        return tickets.map((data, i) => {    
            let sr_num = (parent_sr_num ? (parent_sr_num + '.') : '') + (i+1)
            return <Ax key={sr_num}>
                <tr key={sr_num}>
                    <td>{sr_num}</td>
                    <td><Link to="#" onClick={() => this.ticketDetailModalInit(data.id)}>{data.header}</Link></td>
                    <td>{data.ticket_type ? data.ticket_type.name : '-'}</td>
                    <td>{data.estimate_days ? data.estimate_days : '-'}</td>
                    <td>
                        <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <Link role="button" href
                                    onClick={() => {
                                        if (this.props.permissions.includes('iam-project-template-ticket-manage')) {
                                            return this.ticketDetailModalInit(data.id);
                                        }
                                    }}
                                    className={['dropdown-item', this.props.permissions.includes('iam-project-template-ticket-manage') ? '' : 'disabled'].join(' ')}
                                    title={!this.props.permissions.includes('iam-project-template-ticket-manage') ? 'You dont have permission for this action' : ''}
                                    style={{ "pointerEvents": "all" }}
                                >View</Link>
                            </li>
                            <li>
                                <Link role="button" href
                                    onClick={() => {
                                        if (this.props.permissions.includes('iam-project-template-ticket-manage')) {
                                            return this.ticketEditModalInit(data.id);
                                        }
                                    }}
                                    className={['dropdown-item', this.props.permissions.includes('iam-project-template-ticket-manage') ? '' : 'disabled'].join(' ')}
                                    title={!this.props.permissions.includes('iam-project-template-ticket-manage') ? 'You dont have permission for this action' : ''}
                                    style={{ "pointerEvents": "all" }}
                                >Edit</Link>
                            </li>
                            <Link className="dropdown-item" role="button" onClick={() => this.ticketModalInit(data.id, 'addChild')}>Add a Child</Link>
                            <li>
                                <Link role="button" href
                                    onClick={() => {
                                        if (this.props.permissions.includes('iam-project-template-ticket-manage')) {
                                            return this.ticketDelete(data.id);
                                        }
                                    }}
                                    className={['dropdown-item', this.props.permissions.includes('iam-project-template-ticket-manage') ? '' : 'disabled'].join(' ')}
                                    title={!this.props.permissions.includes('iam-project-template-ticket-manage') ? 'You dont have permission for this action' : ''}
                                    style={{ "pointerEvents": "all" }}
                                >Delete</Link>
                            </li>
                           
                        </ul>
                    </td> 
                </tr>
                {this.detailTableTr(data.sub_tickets, sr_num)}
            </Ax> 
        })
    }

    ticketFormData = (parent_ticket_id = '', type = null) => {
        HttpAPICall.withAthorization('GET', this.projectStructureUrl + '/ticket/form_data',
        this.props.access_token, {structure_id : this.state.detailsData.id}, null, (response) => {
            const formData = response.data;
            this.setState({
                addTicketData : {...this.initalAddTicket, parent_ticket_id : parent_ticket_id}, 
                addChild : type === 'addChild' ? true : false,
                ticketsFormData : formData?.tickets && formData.tickets.length > 0 ? formData.tickets.map((t) => { return {label : t.sr_num + ' - ' + t.header, value : t.id}
                }) : [], 
                ticketType : formData.ticket_types && formData.ticket_types.length > 0 ? formData.ticket_types.map((tt) => { return {label : tt.name, value : tt.id}
                }) : [], 
            })
        })
    }

    ticketModalInit = (id = '', type = null) => {
        this.setState({modal_loading : true, formSubmitting: true, ticketId : null}, () => {
            this.ticketModal.show();
            this.ticketFormData(id, type);
            if(this.state.ticketsFormData.length === 0 || this.state.ticketType.length === 0) {
                setTimeout(() => {
                    this.setState({modal_loading: false, formSubmitting: false, 
                        addChild : type === 'addChild' ? true : false,
                        edit_ticket_id  : type === 'addChild' ? null : this.state.edit_ticket_id});
                }, 300);
            } else {
                this.setState({
                    modal_loading : true,
                    formSubmitting : true,
                    addTicketData : {...this.initalAddTicket, parent_ticket_id : id }, 
                    addChild : type === 'addChild' ? true : false,
                    edit_ticket_id  : type === 'addChild' ? null : this.state.edit_ticket_id
                }, () => {
                    setTimeout(() => {
                        this.setState({modal_loading: false, formSubmitting: false});
                    }, 300);
                });
    
            }

        })
    }   

    ticketEditModalInit = (id) => {
        this.ticketFormData();
        this.setState({
            formSubmitting : true,
            modal_loading : true,
            edit_ticket_id : id,
            ticketId : id,
            isEdit : true,
            addChild : false
        }, () => {
            this.ticketModal.show();
            // if(this.state.ticketsFormData.length === 0 || this.state.ticketType.length === 0) {
            // }
            setTimeout(() => {
                HttpAPICall.withAthorization('GET', this.projectStructureUrl + '/ticket/detail/' + id,
                this.props.access_token, null, null, (response) => {
                    const ticket = response.data.ticket;
                    this.setState({
                        addTicketData : {
                            ...this.state.addTicketData,  
                            ticket_type_id:ticket ? ticket.ticket_type_id : null,
                            parent_ticket_id : ticket.parent_ticket_id ? ticket.parent_ticket_id : '',
                            header : ticket.header ? ticket.header : '',
                            type : ticket.type ? ticket.type : '',
                            description : ticket.description ? ticket.description : '',
                            estimate_days : ticket.estimate_days ? ticket.estimate_days : '',
                        },
                        ticketLevel : ticket.level,
                    })
                }).then(() => this.setState({modal_loading: false, formSubmitting : false}));
            }, 1000);
        });
    }

    modalClose = () => {
        this.setState({
            addTicketData : this.initalAddTicket,
            isEdit : false,
        });
    }

    submitTicketForm = (e) => {
        e.preventDefault();
        const structure_id = this.state.detailsData.id;
        let data = { ...this.state.addTicketData, structure_id}
        this.setState({formSubmitting : true});
        const id = this.state.edit_ticket_id;
        if(id) {
            HttpAPICall.withAthorization('PUT', this.projectStructureUrl + '/ticket/edit',
                this.props.access_token, null, {...data, id}, (response) => {
                    this.ticketModal.hide();
                    toast.success(response.data?.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.projectDetailsData(structure_id);
                    this.setState({addTicketData : this.initalAddTicket, isEdit : false});
                }).then(() => this.setState({formSubmitting: false})
            );
        }else{
            HttpAPICall.withAthorization('POST', this.projectStructureUrl + '/ticket/add',
                this.props.access_token, null, data, (response) => {
                    this.ticketModal.hide();
                    toast.success(response.data?.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.projectDetailsData(structure_id);
                    this.setState({addTicketData : this.initalAddTicket})
                }).then(() => this.setState({formSubmitting: false})
            );
        }
    }    

    ticketModelJsx = () => {
        const ticketsFormData = this.state.ticketId ? this.state.ticketsFormData.filter(t => t.value !== this.state.ticketId) : this.state.ticketsFormData;
        const ticketType = this.state.ticketType;
        return (
            <div className="modal fade" id="ticketModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.isEdit ? 'Edit' : 'Add'} Ticket</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.modalClose}></button>
                        </div>
                        <div className="modal-body">
                        {
                            this.state.modal_loading 
                            ?
                            <Loader/>
                            :
                            <form id="ticketForm" onSubmit={this.submitTicketForm}>
                                <div className="row mb-3">
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Project Template</label>
                                    </div>
                                    <div className="col-sm-7 mb-3">
                                        <input
                                            className="form-control"
                                            value={this.state.detailsData?.name}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className={`col-form-label ${this.state.ticketLevel === 1 ? '' : (ticketsFormData.length > 0 ? 'require' : '')}`}>Parent Ticket</label>
                                    </div>
                                    <div className="col-sm-7 mb-3">
                                        <TapSelect
                                            options={ticketsFormData}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addTicketData', 'parent_ticket_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            // isDisabled={this.state.addChild || this.state.ticketLevel === 1 ? true : false}
                                            required={this.state.ticketLevel === 1 ? false : (ticketsFormData.length > 0 ? true : false)}
                                            placeholder="Select Parent Ticket"
                                            value={ticketsFormData.find((t) => {
                                                return this.state.addTicketData.parent_ticket_id === t.value 
                                            })}
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label require">Header</label>
                                    </div>
                                    <div className="col-sm-7 mb-3">
                                        <input
                                            name="header"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required
                                            value={this.state.addTicketData.header}
                                            onChange={(e) => this.formInputHandler(e, 'addTicketData')}
                                            placeholder="Enter Header"
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Type</label>
                                    </div>
                                    <div className="col-sm-7 mb-3">
                                        <TapSelect
                                            options={ticketType}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addTicketData', 'ticket_type_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Ticket Type"
                                            value={ticketType.find((tt) => {
                                                return this.state.addTicketData.ticket_type_id == tt.value 
                                            })}
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-sm-4 col-form-label">Duration Days</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="estimate_days"
                                            type="number"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addTicketData.estimate_days}
                                            onChange={(e) => this.formInputHandler(e, 'addTicketData')}
                                            placeholder="Enter Days"
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-sm-4 col-form-label">Description</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <textarea
                                            name="description"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.addTicketData.description}
                                            onChange={(e) => this.formInputHandler(e, 'addTicketData')}
                                            placeholder="Enter Notes..."
                                        />
                                    </div>
                                </div>
                            </form>
                        }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.formSubmitting} onClick={this.modalClose}>Cancel</button>
                            <button type="submit" form='ticketForm' className="btn btn-primary" disabled={this.state.formSubmitting} >Save {this.state.formSubmitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    ticketDetailModalInit = (id) => {
        this.ticketDetailModal.show();
        this.setState({ticketDetailShow : true})

        const params = {need_parent_ticket : 'Y', need_sub_tickets : 'Y'}

        HttpAPICall.withAthorization('GET', this.projectStructureUrl + '/ticket/detail/' + id,
        this.props.access_token, params, null, (response) => {
            const ticket = response.data;
            this.setState({
                ticketDetail :  ticket
            })
        }).then(() => this.setState({ticketDetailShow: false}));


    } 

    ticketDetailModalJsx = () => {
        const ticketDetail = this.state.ticketDetail ? this.state.ticketDetail.ticket : null;
        const subTickets = this.state.ticketDetail ? this.state.ticketDetail.sub_tickets : null;    
        const parentTicket = this.state.ticketDetail ? this.state.ticketDetail.parent_ticket : null; 
        return (
            <div className="modal fade" id="ticketDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Ticket Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <table className='table table-hover table-bordered table-responsive bg-white'>
                                <tbody> 
                                {
                                    this.state.ticketDetailShow 
                                    ? <Loader/>
                                    :
                                    <Ax>
                                        <tr>
                                            <td style={{ width: "30%" }}>Project Template</td>
                                            <th style={{ width: "70%" }}>{this.state.detailsData?.name}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "30%" }}>Header</td>
                                            <th style={{ width: "70%" }}>{ticketDetail && ticketDetail.header ? ticketDetail.header : '-'}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "30%" }}>Type</td>
                                            <th style={{ width: "70%" }}>{ticketDetail && ticketDetail.ticket_type ? ticketDetail.ticket_type : '-'}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "30%" }}>Duration Days</td>
                                            <th style={{ width: "70%" }}>{ticketDetail && ticketDetail.estimate_days ? ticketDetail.estimate_days : '-'}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "30%" }}>Description</td>
                                            <th style={{ width: "70%" }}>{ticketDetail && ticketDetail.description ? ticketDetail.description : '-'}</th>
                                        </tr>
                                    </Ax>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render()    {
        return (
            <div className='tab_content_header'>
                <div className="row">
                {
                    this.state.detail_loading 
                    ?
                    <div className='col-sm-12 text-center'>
                        <Loader/>
                    </div>
                    :
                    <Ax>
                        <div className='col-sm-12 mb-3'>
                            {this.cardDetailJsx()}
                        </div>
                        <div className='col-sm-12'>
                            {this.tableDetailsJsx()}                
                        </div>
                    </Ax>
                }
                </div>
                {this.ticketModelJsx()}
                {this.ticketDetailModalJsx()}
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        access_token    :   state.auth.access_token,
        permissions     :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(ProjectStructureDetail);
