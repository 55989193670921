import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class SupplierVendorList extends AppBaseComponent {

    constructor(props) {
        super(props);
      

        this.state                      =     {
            minimizeTable                   :     false,
            listing_tbl_page                :     1,
            listing_loading                 :     false,
            totalListingCount               :     0,
            listingMeta                     :     null,
           supplier_listing                 :     [],
           iam_user_sites                   :       [],
           formSearchedElems                :     [],
           worksationDetail                 :       null,
           actionPerformed                  :       false
           
        };
         
    }
    
    
    componentDidMount() {
        this.loadListingTblData(1)
     }

   
    loadListingTblData                 =   (page = 1,id=null)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page,per_page:50,view_loading  :true});
        let params                      =   {page:page};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/hg_sap/supplier_vendor/list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
           this.setState({
                supplier_listing        :           respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
               totalListingCount       :           respData.total,
        });
        }).then(() => this.setState({listing_loading: false,view_loading  :false}));
    }

     //***********************fuelStation JSX****************

    supplierListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Supplier Vendor</h3>
               
            </div>
           
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.supplierListingTableJsx()}</div>
                   
                </div>
            </div>
        </Ax>);
    }

    supplierListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" >
          
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    <tr>
                        <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                        <th scope="col" style={{ width: "25%" }}>Vendor Name</th>
                        <th scope="col" style={{ width: "40%" }}>Code</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="3"><Loader /></td></tr>)
                        : (this.state.supplier_listing.length > 0
                            ? (this.state.supplier_listing.map((vendor, index) => {
                                return (<tr key={index} >

                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td>{vendor.name}</td>
                                    <td>{vendor.code ? vendor.code : ""}</td>
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>

            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
           
        </div>)
    }


    render                              =   ()  =>  {
    
        return (<ApplicationLayout>
        <Helmet><title>Supplier Vendor Listing</title></Helmet>
             {this.supplierListjsx()} 
            
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(SupplierVendorList);