import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Ax from '../../../../components/hoc/Ax';
import { Modal } from 'bootstrap';
import TapSelect from '../../../../components/ui/TapSelect';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import AdditionalAttribute from '../../AdditionalAttribute';
import swal from "sweetalert";
import { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';



class ItemDemandConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAddStatusForm      =         {
            name                    :        '',
            role_ids                :        [],
            notes                   :        ''
        }

        this.state                  =        {
            statusList              :        [],
            listing_loading         :       false,
            addStatusForm           :       {...this.initAddStatusForm},
            allRoleList             :       [],
            addFormDataLoaded       :       false,
            addStatusFormSubmitting :       false,
            can_Edit                :       true,
            edit                    :       false,
            form_loaded             :       false,
            can_change_role         :       'N',
            rolesList               :       []
        }
        this.status_listing_url        =   INVENTORY_API_BASE_URL_2 + '/item_demand_status/list';
        this.status_formdata_url       =   INVENTORY_API_BASE_URL_2 + '/item_demand_status/form_data';
        this.status_add_url            =   INVENTORY_API_BASE_URL_2 + '/item_demand_status/add';
        this.status_edit_url           =   INVENTORY_API_BASE_URL_2 + '/item_demand_status/edit';
        this.status_delete_url         =   INVENTORY_API_BASE_URL_2 + '/item_demand_status/delete';
        
    }

    componentDidMount(){
        this.getStatusListing()
        this.addAdjustmentStatusModal            =        new Modal(document.getElementById('addAdjustmentStatusModal'), {keyboard: false, backdrop :false});
     }

     getStatusListing       =       ()      =>  {
        this.setState({ listing_loading : true});
        HttpAPICall.withAthorization('GET',  this.status_listing_url , this.props.access_token,{},{},(response) => {
            let respData = response.data;
            let roles = respData && respData.roles && respData.roles.length > 0 ? respData.roles : [];
            let status = respData && respData.data && respData.data.length > 0 ? respData.data : [];
            
            const roleMap = roles.reduce((map, role) => {
                map[role.id] = role.role;
                return map;
              }, {});
              
              // Map role names to data
              const updatedData = status.map(item => ({
                ...item,
                role_names: item.role_ids.map(id => roleMap[id])
              }));
            this.setState({
                statusList: updatedData,
                
            })
         }).then(() => this.setState({listing_loading : false}));
     }

     addFormDataInit        =       ()      =>      {
        if(!this.state.addFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.status_formdata_url, this.props.access_token, null, null, (response) => {
                this.setState({
                    addFormDataLoaded    :    true,
                    allRoleList          :    response.data.roles.map((mou) => { return {value: mou.id, label: mou.role}}),
                });
            });
        }
     }

     addStatusModalInit     =       (id)      =>      {
        this.addAdjustmentStatusModal.show()
        this.addFormDataInit()
       
        if(id){
            let statusList         =       this.state.statusList.find(function (transaction) {
                             return transaction.id == id;
                         });
        
                         let roles = statusList.role_ids.map((mou) => mou)
                         this.setState({
                             addStatusForm: {
                                 name           :       statusList.name,
                                 notes          :       statusList.notes === null ? "" : statusList.notes,
                                 id             :       statusList.id,
                                 role_ids       :       roles
                             },
                             can_Edit           :       statusList.can_edit,
                             edit               :       true,
        
                         })
        }else{
            this.setState({can_Edit : true,edit : false})
        }
     }
      

    
     submitAddStatusForm    =          (e)      =>      {
        e.preventDefault()
        let frmData = {...this.state.addStatusForm}

        this.setState({addStatusFormSubmitting: true})
           
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', this.status_edit_url, this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addAdjustmentStatusModal.hide();
                this.getStatusListing()
                this.setState({addStatusForm  :  {...this.initAddStatusForm},can_Edit : true})
            }).then(() => this.setState({addStatusFormSubmitting: false}))
        }else{
            HttpAPICall.withAthorization('POST', this.status_add_url, this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addAdjustmentStatusModal.hide();
                this.getStatusListing()
                this.setState({addStatusForm  :  {...this.initAddStatusForm},can_Edit : true})
            }).then(() => this.setState({addStatusFormSubmitting: false}))
        }
     }

     addAdjustmentStatusModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="addAdjustmentStatusModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addStatusModalLabel">{this.state.edit ? "Edit" : "Add"} Status</h5>
                        </div>
                        <form onSubmit={this.submitAddStatusForm} id="addStatusForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                {this.state.form_loaded ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Status Name</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addStatusForm') }}
                                                    value={this.state.addStatusForm.name}
                                                    placeholder="Please Enter Status Name"
                                                />
                                            </div>
                                        </div>

                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label ">Notes</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="notes"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addStatusForm') }}
                                                    value={this.state.addStatusForm.notes}
                                                    placeholder="Please Enter Notes"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Role</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <TapSelect
                                                    options={this.state.allRoleList}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addStatusForm', 'role_ids')}
                                                    value={this.state.allRoleList.filter(u => this.state.addStatusForm?.role_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                     isMulti={true}
                                                    isClearable={true}
                                                    //isDisabled={this.state.can_change_role == 'Y'}
                                                    placeholder="Select Roles"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addStatusFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addStatusForm: { ...this.initAddStatusForm } }) }}>Close</button>
                                <button type="submit" disabled={this.state.addStatusFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addStatusFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    deleteStatus       =       (id)      =>      {
        swal({
            title: "DELETE",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    let statusList = this.state.statusList.find(function (group) {
                        return group.id == id;
                    });
                    HttpAPICall.withAthorization('DELETE',this.status_delete_url + '/' + id, this.props.access_token, {}, {statusList}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getStatusListing()
                     }).then(() => {

                    });
                }
            });
     }

    render() {

        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">

                        <li className="nav-item" role="presentation">
                            <button className="nav-link active " id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="tagging-tab" data-bs-toggle="tab" data-bs-target="#tagging"
                                type="button" role="tab" aria-controls="tagging" aria-selected="true">Additional Attribute
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane show active" id="workflow" role="tabpanel" aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <Ax>
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Status for Item Demand </span>
                                        <div className="float-end"><button className="btn btn-primary" disabled={!this.props.permissions.includes('isc-po-status-manage')} onClick={() => this.addStatusModalInit()}>Add Status</button></div>
                                    </div>
                                    <table className="table table-hover table-bordered mt-3 mb-2 ">
                                        <thead>
                                            <tr className='table-secondary'>
                                                <th scope="col" style={{ width: "20%" }}>Status</th>
                                                <th scope="col" style={{ width: "40%" }}>Roles</th>
                                                <th scope="col" style={{ width: "40%" }} >Notes</th>
                                            </tr>

                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                this.state.listing_loading
                                                    ? <tr>
                                                        <td className="text-center" colSpan="6"><Loader /></td>
                                                    </tr>
                                                    :
                                                    (this.state.statusList.map((item, index) => {
                                                        return <tr key={item.id}>
                                                            <td scope="row" className={''}>{item.name}</td>
                                                            <td scope="row">{item.role_ids && item.role_ids.length > 0 ? item.role_names.map((mou) => mou).join(", ") : ""}</td>
                                                            <td scope="row">
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        {item.notes === null ? "-" : item.notes}
                                                                    </div>
                                                                    
                                                                        <div className="col-2 text-center">
                                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                                                data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                                                            />
                                                                            <ul className="dropdown-menu">
                                                                                <li>
                                                                                    <Link role="button"
                                                                                        onClick={() => {
                                                                                            if (this.props?.permissions?.includes('isc-inventory-adjustment-status-manage')) {
                                                                                                return this.addStatusModalInit(item.id)
                                                                                            }
                                                                                        }}
                                                                                        className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-inventory-adjustment-status-manage') ? '' : 'disabled'].join(' ')}
                                                                                        title={!this.props?.permissions?.includes('isc-inventory-adjustment-status-manage') ? 'You do not have permission to perform this action' : ''}
                                                                                        style={{ "pointerEvents": "all" }}
                                                                                    >Edit</Link>
                                                                                </li>
                                                                            {item.can_delete === false ? null : <li>
                                                                                <Link role="button"
                                                                                    onClick={() => {
                                                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                                                            return this.deleteStatus(item.id)
                                                                                        }
                                                                                    }}
                                                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                                                    style={{ "pointerEvents": "all" }}
                                                                                >Delete</Link>
                                                                            </li>}


                                                                            </ul>
                                                                        </div>
                                                                
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }))}
                                        </tbody>
                                    </table>
                                    {/* <div className='form-text text-danger'> <sup>*</sup>System Generated</div> */}
                                    {this.addAdjustmentStatusModalJsx()}
                                </Ax>
                            </div>
                        </div>
                        <div className="tab-pane" id="tagging" role="tabpanel" aria-labelledby="tagging-tab">
                            <div className="tab_content_header">
                               <AdditionalAttribute category="item_demand"/>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(ItemDemandConfiguration);