import React from 'react';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";

class AssetHorizontalCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }



    render() {
        const details = this.props.assetDetails
        return (
            <Ax>
                <div className="accordion" id="accordionExample" >
                    <div className="accordion-item">
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#accordionExample">
                            <table className="table table-borderless va_middle" id="" style={{backgroundColor: "#fff"}}>
                                <tbody>
                                <tr>
                                    <td  style={{width: "20%"}} rowSpan="3" className="p0 text-center">
                                        <img src={details?.image} className="img-fluid card-image"  alt={"image-card"}/>
                                    </td>
                                    <td style={{width: "25%"}}>Asset Name :<br/>{details?.can_access === true ? <a className="link-primary" onClick={this.linkToAssetProfile}>
                                        <b>{details.asset_name ?? "-"}</b></a> :  <a>
                                        <b>{details.asset_name ?? "-"}</b></a>}
                                    </td>
                                    <td style={{width: "25%"}}>Asset Type : <br/><b>{details.asset_type_name ?? "-"}</b></td>
                                    <td style={{width: "15%"}} rowSpan="3">Last Counter Log : <br/>
                                        {details.latest_counterlog?.display.length > 0 ? details.latest_counterlog?.display.map((item,index)=>  {return(<div className="bold">
                                            <div className="mt-2">{item}</div>
                                        </div>)}) :  "-"}
                                    </td>
                                    <td style={{width: "15%"}}>Site : <br/>
                                        <div className="bold">{details.site_name ?? "-"}</div></td>

                                </tr>
                                <tr>
                                    <td>Asset Code : <br/><b>{details.asset_nickname  ?? "-"}</b></td>
                                    <td>Asset Matrix Id: <br/><b>{details.asset_matrix_unique_id ?? "-"}</b>
                                    </td>
                                    <td style={{width: "15%"}}>Status : <br/><b> <tapIcon.FontAwesomeIcon className="mt10" icon={tapIcon.faCircle} color={this.state.assetCardDetails?.status_color}/>
                                        <span className="ml5"><b>{details.status_text ?? "-"}</b></span>
                                        <tapIcon.imageIcon icon={tapIcon.EditIcon} className="img-fluid float-end" alt="item-action" onClick={this.changeStatusModalInit}/>
                                    </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Model : <br/><b>{details.model ?? "-"}</b></td>
                                    <td>Manufacturer : <br/><b>{details.manufacturer ?? "-"}</b>
                                    </td><td>

                                </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </Ax>
        )
    }
}





export default AssetHorizontalCard;