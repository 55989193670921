import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import React from "react";
import TapApiUrls from "../../../services/TapApiUrls";
import * as actions from "../../../store/actions/index"
import moment from "moment";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";
import CurrencyFormatter from "../../../services/CurrencyFormatter";

class FuelSummary extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                      =   {
            loading                     :      false,
            data                        :       null,
            remark_day                  :       0,
            remark_time                 :       '',
            all_periods                 :       [],
            filterForm                  :       {
                date_range                  :       'current_month'
            }
        }
        this.updateMyDashboardRef      =   React.createRef()
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('fuelSummaryWidget')) {
            let fuelSummaryData = JSON.parse(localStorage.getItem('fuelSummaryWidget'));
            if (fuelSummaryData) {
                let lastRefreshTime = moment(fuelSummaryData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('fuelSummaryWidget')
                    setTimeout(() => {
                        this.startFuelSummaryAfterSomeTime()
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 15));
                    
                } else {
                    this.setState({
                        data            :      fuelSummaryData.data,
                        remark_time     :      fuelSummaryData.time,  
                    });
                }
            }else{
                setTimeout(() => {
                    this.startFuelSummaryAfterSomeTime()
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 15));
            }
        } else {
            setTimeout(() => {
                this.startFuelSummaryAfterSomeTime()
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 15));
        } 
    }

    startFuelSummaryAfterSomeTime               =   ()  =>  {
        this.setState({loading: true});
        setTimeout(() => this.getfuelSummary(), 13000);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeFormFilter(nextProps);
        }
    }
    
    initilaizeFormFilter                =   (props)  =>  {
        this.setState({
            all_periods             :   props.all_periods ? props.all_periods.map((s) => { return {value: s.key, label: `${s.display}`}}) : [],
        },() => {
          
        });
    }

    getfuelSummary                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {...this.state.filterForm, reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET',TapApiUrls.IAM + '/group/fuel_station_summary_widget_data', this.props.access_token, params, {} , (response) => {
           this.setState({data : response.data.data,remark_time : response.data.time})
            if (this.state.filterForm.date_range == "current_month") {
                localStorage.setItem('fuelSummaryWidget',JSON.stringify(response.data));
            }
            if (reload !== false) {
                localStorage.setItem('fuelSummaryWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"fuel_summary",name:"Fuel Summary"})
    }
    

    render                      =   ()  =>  {
        let wobj = {'date_range' : this.state.filterForm.date_range };
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("fuel_summary" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "fuel_summary")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Fuel Summary</h6>
                    </div>
                    <div className="col-3">

                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.all_periods}
                            value={this.state.all_periods.find(r => r.value === this.state.filterForm.date_range)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'date_range' , () => this.getfuelSummary())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.getfuelSummary('reload')}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">

                    <div className="col-12">
                        <table className="table table-hover table-bordered table-borderless bg-white my-2">
                            <thead className="table-secondary">
                                <tr className="text-end">
                                    <th style={{ width: "25%" }} className="text-start" >Fuel Station Name</th>
                                    <th style={{ width: "12%" }} className="text-center">Fuel Type</th>
                                    <th style={{ width: "11%" }} className="text-center">UOM</th>
                                    <th style={{ width: "10%" }}>Opening Balance</th>
                                    <th style={{ width: "10%" }}>Received</th>
                                    <th style={{ width: "10%" }}>Issued</th>
                                    <th style={{ width: "10%" }}>Closing Balance</th>
                                    <th style={{ width: "12%" }} className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.loading
                                    ? (<tr><td colSpan="8"><Loader /></td></tr>)
                                    :
                                    this.state.data &&  this.state.data.length > 0
                                    ? (this.state.data.map((data, index) => {
                                        return (<Ax>
                                            {data.fuel_types && data.fuel_types.length > 0
                                                ? data.fuel_types.map((fuelData, key) => {
                                                    let obj = {'fuel_station_id' : data.id,'fuel_type_id' : fuelData.id ,'date_range' : this.state.filterForm.date_range}
                                                    return (<tr className="text-center">
                                                        {key == 0 && <Ax>
                                                            <td className="text-start text-capitalize" rowSpan={data.fuel_types.length}>{data.fuelstation_name ? data.fuelstation_name : "-"}</td>
                                                        </Ax>}
                                                        <td>{fuelData.fuel_type ? fuelData.fuel_type : "-"}</td>
                                                        <td>{fuelData.unit ? fuelData.unit : "-"}</td>
                                                        <td className="text-end">{fuelData.balance && fuelData.balance.opening_balance ?   <CurrencyFormatter currencyCode={this.props.default_currency} amount={fuelData.balance.opening_balance}/> : "0"}</td>
                                                        <td className="text-end">{fuelData.balance && fuelData.balance.received ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={fuelData.balance.received}/>  : "0"}</td>
                                                        <td className="text-end" >{fuelData.balance && fuelData.balance.issued ?  <CurrencyFormatter currencyCode={this.props.default_currency} amount={fuelData.balance.issued}/>  : "0"}</td>
                                                        <td className="text-end">{fuelData.balance && fuelData.balance.closing_balance ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={fuelData.balance.closing_balance}/>  : "0"}</td>
                                                       
                                                        <td className="link-primary text-center">
                                                            <Link target="_blank" to={{ pathname: "/report/fuel_station_ledger", search: "?" + new URLSearchParams(obj).toString() }}>View Ledger</Link></td>
                                                       

                                                    </tr>)
                                                }) : null}
                                        </Ax>)
                                    }))
                                    : <tr><td className="text-center" colSpan={8}>No Record Found</td></tr>
                            }
                                
                                
                            </tbody>
                        </table>
                    </div>
 
                    <div className="col-sm-12 text-muted fs11">
                    Report as of {this.state.remark_time}
                    <div className="float-end link-primary">
                        <Link target="_blank" to={{ pathname: "/report/fuel_station_summary", search: "?" + new URLSearchParams(wobj).toString() }}>View Detailed Report</Link>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(FuelSummary);