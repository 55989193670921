import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Ax from '../../../../components/hoc/Ax';
import HttpAPICall from '../../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import TapSelect from '../../../../components/ui/TapSelect';
import { Link } from 'react-router-dom';

class Workflow extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAddStatusForm      =         {
            name                    :        '',
            role_ids                :        [],
            notes                   :        ''
        }

        this.state                  =        {
            statusList              :        [],
            listing_loading         :       false,
            addStatusForm           :       {...this.initAddStatusForm},
            allRoleList             :       [],
            addFormDataLoaded       :       false,
            addStatusFormSubmitting :       false,
            can_Edit                :       true,
            edit                    :       false,
            form_loaded             :       false,
        }

        this.salesOrderStatusFormDataUrl    =   INVENTORY_API_BASE_URL_2  + "/sales_order_status/form_data"; 
        this.salesOrderStatusListUrl    =   INVENTORY_API_BASE_URL_2  + "/sales_order_status/list";  
        this.salesOrderStatusEditUrl    =   INVENTORY_API_BASE_URL_2  + "/sales_order_status/update"; 
        this.salesOrderStatusAddUrl    =   INVENTORY_API_BASE_URL_2  + "/sales_order_status/add"; 
        this.salesOrderStatusDeleteUrl   =   INVENTORY_API_BASE_URL_2  + "/sales_order_status/delete";    
        
    }

    componentDidMount(){
        this.getStatusListing()
        this.addStatusModal            =        new Modal(document.getElementById('addStatusModal'), {keyboard: false, backdrop :false});
     }

     getStatusListing       =       ()      =>  {
        this.setState({ listing_loading : true});
        HttpAPICall.withAthorization('GET', this.salesOrderStatusListUrl, this.props.access_token,{},{},(response) => {
            this.setState({statusList: response.data.data})
         }).then(() => this.setState({listing_loading : false}));
     }

     addFormDataInit        =       ()      =>      {
        if(!this.state.addFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.salesOrderStatusFormDataUrl, this.props.access_token, null, null, (response) => {
                this.setState({
                    addFormDataLoaded    :    true,
                    allRoleList          :    response.data.data.roles.map((mou) => { return {value: mou.id, label: mou.role}}),
                });
            });
        }
     }

     addStatusModalInit     =       ()      =>      {
        this.addStatusModal.show()
        this.addFormDataInit()
        this.setState({can_Edit : true,edit : false})
     }
      
     openEditStatus         =       (id)      =>      {
        this.addStatusModal.show()
        
        this.setState({form_loaded : true})
         HttpAPICall.withAthorization('GET', this.salesOrderStatusFormDataUrl, this.props.access_token, null, null, (response) => {
             let allRoleList        =       response.data.data.roles.map((mou) => { return { value: mou.id, label: mou.role } })
             let allRole            =       [...allRoleList, { value: 47, label: "Administrator" }]
             this.setState({
                 addFormDataLoaded      :       true,
                 allRoleList            :       allRole
             }, () => {
                 let statusList         =       this.state.statusList.find(function (transaction) {
                     return transaction.id == id;
                 });

                 let roles = statusList.roles.map((mou) => mou.id)
                 this.setState({
                     addStatusForm: {
                         name           :       statusList.name,
                         notes          :       statusList.notes === null ? "" : statusList.notes,
                         id             :       statusList.id,
                         role_ids       :       roles
                     },
                     can_Edit           :       statusList.can_edit,
                     edit               :       true

                 })
             });
         }).then(() => this.setState({ form_loaded: false }))
       
     }

     submitAddStatusForm    =          (e)      =>      {
        e.preventDefault()
        let frmData = {...this.state.addStatusForm}

        this.setState({addStatusFormSubmitting: true})
           
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', this.salesOrderStatusEditUrl, this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addStatusModal.hide();
                this.getStatusListing()
                this.setState({addStatusForm  :  {...this.initAddStatusForm},can_Edit : true})
            }).then(() => this.setState({addStatusFormSubmitting: false}))
        }else{
            HttpAPICall.withAthorization('POST', this.salesOrderStatusAddUrl, this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addStatusModal.hide();
                this.getStatusListing()
                this.setState({addStatusForm  :  {...this.initAddStatusForm},can_Edit : true})
            }).then(() => this.setState({addStatusFormSubmitting: false}))
        }
     }

     deleteStatus       =       (id)      =>      {
        swal({
            title: "DELETE",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    let statusList = this.state.statusList.find(function (group) {
                        return group.id == id;
                    });
                    HttpAPICall.withAthorization('DELETE', this.salesOrderStatusDeleteUrl + '/' + id, this.props.access_token, {}, {statusList}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getStatusListing()
                     }).then(() => {

                    });
                }
            });
     }

     //*********************************EDIT SETTING JSX*************************
    addStatusModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="addStatusModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addStatusModalLabel">{this.state.edit ? "Edit" : "Add"} Status</h5>
                        </div>
                        <form onSubmit={this.submitAddStatusForm} id="addStatusForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                {this.state.form_loaded ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Status Name</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addStatusForm') }}
                                                    value={this.state.addStatusForm.name}
                                                    placeholder="Please Enter Status Name"
                                                />
                                            </div>
                                        </div>

                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label ">Notes</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="notes"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addStatusForm') }}
                                                    value={this.state.addStatusForm.notes}
                                                    placeholder="Please Enter Notes"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Role</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <TapSelect
                                                    options={this.state.allRoleList}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addStatusForm', 'role_ids')}
                                                    value={this.state.allRoleList.filter(u => this.state.addStatusForm?.role_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Roles"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addStatusFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addStatusForm: { ...this.initAddStatusForm } }) }}>Close</button>
                                <button type="submit" disabled={this.state.addStatusFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addStatusFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }



    render() {
        return (
            <Ax>
                <div className="tab_content_wrapper">
                    <span className="content_heading">Status for Sales Order <tapIcon.FontAwesomeIcon icon={tapIcon.faInfoCircle} /></span>
                    <div className="float-end"><button className="btn btn-primary" disabled={!this.props.permissions.includes('isc-po-status-manage')} onClick={this.addStatusModalInit}>Add Status</button></div>
                </div>
                <table className="table table-hover table-bordered my-3 ">
                    <thead>
                        <tr className='table-secondary'>
                            <th scope="col" style={{ width: "20%" }}>Status</th>
                            <th scope="col" style={{ width: "40%" }}>Roles</th>
                            <th scope="col" style={{ width: "40%" }} >Notes</th>
                        </tr>

                    </thead>
                    <tbody className="table-body">
                        {
                            this.state.listing_loading
                                ? <tr>
                                    <td className="text-center" colSpan="6"><Loader /></td>
                                </tr>
                                :
                                (this.state.statusList.map((item, index) => {
                                    return <tr key={item.id}>
                                        <td scope="row">{item.name}</td>
                                        <td scope="row">{item.auto_generated === "Y" ? '' : item.roles.map((mou) => mou.role).join(", ")}</td>
                                        <td scope="row">
                                            <div className="row">
                                                <div className="col-10">
                                                    {item.notes === null ? "-" : item.notes}
                                                </div>
                                                {item.auto_generated === "Y" ? null :
                                                    <div className="col-2 text-center">
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                            data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                                        />
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <Link role="button"
                                                                    onClick={() => {
                                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                                            return this.openEditStatus(item.id)
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Edit</Link>
                                                            </li>
                                                            {item.can_delete === false ? null : <li>
                                                                <Link role="button"
                                                                    onClick={() => {
                                                                        if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                                            return this.deleteStatus(item.id)
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Delete</Link>
                                                            </li>}

                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                }))}
                    </tbody>
                </table>
                {this.addStatusModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(Workflow);