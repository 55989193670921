import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import TapSelect from '../../../components/ui/TapSelect';
import * as actions from '../../../store/actions';
import tapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Loader from '../../../components/ui/Loader/Loader';
import { Helmet } from 'react-helmet';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { ISC_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class WarehouseAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.addItemFormInitialState         =   {
            id                              :   null,
            name                            :   '',
            code                            :   '',
            site_ids                        :   [],
            gstin                           :   '',
            country_id                      :   '',
            additional_attributes           :   [],
            all_sites                       :   false,
            organization_id                 :   null
        };
        
        this.state                  =   {
            formData                    :   null,
            addItemForm                 :   {...this.addItemFormInitialState},
            siteOptions                 :   [],
            countryOptions              :   [],
            statesOptions               :   [],
            pincodeOptions              :   [],
            districtOptions             :   [],
            itemAddFormSubmitting       :   false,
            warehouseDetail             :    null,
            warehouse_loading           :    false,
            allOrganizationList         :    []
        };

        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.pincodeListUrl     =   ORG_API_BASE_URL_2  +   '/pincode_list';
        this.warehouseUrl       =   ISC_API_BASE_URL_2  +   '/warehouse';
        
    }
    
    componentDidMount() {
        this.initilaizeFormData(this.props) 
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.orgInfoData !== this.props.orgInfoData) {
            this.initializeData(nextProps)
         }     
    }

    initilaizeFormData          =       (pr)          =>      {
       
        this.setState({warehouse_loading : true})
        const headers                         =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + this.props.access_token};
        Promise.all([
            axios({ method: 'GET', headers: headers, url: this.warehouseUrl + '/add_form_data'}), 
            axios({ method: 'GET', headers: headers, url: this.stateListUrl}), 
        ])
        .then((results) => {
            let formData = results[0].data.data
            this.setState({
                formData            :   formData,
                addItemForm         :   {...this.state.addItemForm, country_id : formData.country[0].id},
                siteOptions         :   formData.sites.map((s) => { return {value: s.id, label: s.site_name}; }),
                countryOptions      :   formData.country.map((c) => { return {value: c.id, label: c.name}; }),
                statesOptions       :   results[1].data.map((s) => { return {value: s.id, label: s.name}; }),
                allOrganizationList :  formData.group_organizations && formData.group_organizations.length > 0 ? formData.group_organizations.map(og => {return({value: og.id,label:og.name})}) : []
            },() => {
                let allOrganizationList = [...this.state.allOrganizationList,{id:null,label:pr.orgInfoData.group_name}];
                this.setState({allOrganizationList : allOrganizationList},() => {
                    if(this.props.location && this.props.location.state && this.props.location.state.warehouseData) {
                        let warehouseDetail       =   this.props.location.state.warehouseData;
                        
                        let updateFormState             =   [];
                        if(warehouseDetail.additional_attribute) {
                            Object.keys(warehouseDetail.additional_attribute).map((k) => {
                                let name                        =   `additional_attributes[${k}]`;
                                updateFormState[name]           =   warehouseDetail.additional_attribute[k]
                            });
                        }
    
                        this.setState({
                            warehouseDetail          :   warehouseDetail,
                            addItemForm         :  {
                                ...this.addItemFormInitialState,
                                id                      :   warehouseDetail.id,
                                name                    :   warehouseDetail.name,
                                code                    :   warehouseDetail.code,
                                site_ids                 :   warehouseDetail.site_ids,
                                gstin                   :   warehouseDetail.gstin,
                                email                   :   warehouseDetail.email,
                                phone1                  :   warehouseDetail.phone1,
                                phone2                  :   warehouseDetail.phone2,
                                address1                :   warehouseDetail.address1 || '',
                                address2                :   warehouseDetail.address2 || '',
                                city                    :   warehouseDetail.city || '',
                                landmark                :   warehouseDetail.landmark || '',
                                district_id             :   warehouseDetail.district_id,
                                state_id                :   warehouseDetail.state_id,
                                pincode                 :   warehouseDetail.pincode,
                                country_id              :   warehouseDetail.country_id,
                                organization_id         :   warehouseDetail.organization ? warehouseDetail.organization.id : null,
                                ...updateFormState
                            }
                        }, () => {
                            this.stateChangeHandler(warehouseDetail.state_id);
                            this.districtChangeHandler(warehouseDetail.district_id);
                        });
                    }
                })
               
                
            });
        }).then(() => {this.setState({warehouse_loading : false})})
       
    }
    
    formInputHandler                    =   (e, key = 'addItemForm')  =>  {
        if(key && e.target && e.target.name) {
            this.setState({ [key]: { ...this.state[key], [e.target.name] : e.target.value } });
        }
    }
    
    addItemFormSubmitEvent                 =   (event)  =>  {
        event.preventDefault();
        const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        let errors                  =   [];

        if (this.state.addItemForm.gstin && this.state.addItemForm.gstin.trim() != '' && !pattern.test(this.state.addItemForm.gstin.trim())) {
            errors = [...errors, 'Please enter valid GST No.'];
        }
        if (errors.length > 0) {
            let errorMsg = errors[0];
            toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
        } else {
            this.setState({ itemAddFormSubmitting: true });
            let headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token };
            if (this.state.warehouseDetail) {

                axios({
                    method: 'PUT', headers: headers, params: { ...this.state.addItemForm }, url: this.warehouseUrl
                }).then((response) => {
                    this.setState({ itemAddFormSubmitting: false });
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.iscAppDataInit(this.props.user_info, this.props.group_info, this.props.acl_info);
                    this.props.history.push({pathname: `/warehouse`, state: {warehouseId : this.state.warehouseDetail.id }})
                }).catch((err) => {
                    this.setState({ itemAddFormSubmitting: false });
                    toast.error(err?.response?.data?.message ?? 'Unable to Update Warehouse.', { position: toast.POSITION.TOP_RIGHT });
                });
            } else {
                let params;
                if (this.state.addItemForm.all_sites == false) {
                    params = { ...this.state.addItemForm }
                } else {
                    params = { ...this.state.addItemForm, site_ids: this.state.siteOptions.map(s => s.value) }
                }
               
                axios({
                    method: 'POST', headers: headers, params: { ...params }, url: this.warehouseUrl
                }).then((response) => {
                    this.setState({ itemAddFormSubmitting: false });
                    toast.success(response.data.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/warehouse`})
                    // this.props.afterItemSubmitEvent(event);
                    //ReCall ISC App Data :- 
                    this.props.iscAppDataInit(this.props.user_info, this.props.group_info, this.props.acl_info);
                }).catch((err) => {
                    this.setState({ itemAddFormSubmitting: false });
                    toast.error(err?.response?.data?.message ?? 'Unable to Add Warehouse.', { position: toast.POSITION.TOP_RIGHT });
                });
            }
            //this.props.onFormDataInit();
        }
    }
    
    tapSelectChange                     =   (selectedOption, form = null, formKey = null)  =>  {
        this.setState({
            [formKey]       :   selectedOption
        });
        if(form && formKey) {
            if(Array.isArray(selectedOption)) {
                this.setState({
                    [form]             :   {...this.state[form], [formKey] : selectedOption.length > 0 ? selectedOption.map(o => o.value) : []}
                });
            } else {
                this.setState({
                    [form]             :   {...this.state[form], [formKey] : selectedOption?.value??''}
                });
            }
        }
    }

    onSelectingAllSites             =           (e,key)             =>      {
         let checkedSite = document.getElementById('all_sites').checked
         if(checkedSite == true) {
             this.setState({
                  addItemForm : {...this.state.addItemForm,
                    all_sites  :  true,
                    site_ids  :  []
             }})
         }else{
            this.setState({
                addItemForm : {...this.state.addItemForm,
                  all_sites  :  false,
                  site_ids  :   []
           }})
         }
    }
    
    
    stateChangeHandler                      =   (state_id)  =>  {
        if(state_id) {
            axios({
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
               params:{state_ids:[state_id]},
                url: this.districtListUrl
            }).then((response) => {
                this.setState({
                    districtOptions : response.data.map((s) => { return {value: s.id, label: s.name}; }),
                });
            }).catch((err) => {
                toast.error(err?.response?.data?.message ?? 'Unable to Load District.', {position: toast.POSITION.TOP_RIGHT});
            });
        } else {
            this.setState({ districtOptions : [] });
        }
    }
    
    districtChangeHandler                      =   (district_id)  =>  {
        if(district_id) {
            axios({
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
                url: this.pincodeListUrl + '/' + district_id
            }).then((response) => {
                this.setState({
                    pincodeOptions : response.data.map((s) => { return {value: s.pincode, label: s.pincode}; }),
                });
            }).catch((err) => {
                toast.error(err?.response?.data?.message ?? 'Unable to Load Pincode.', {position: toast.POSITION.TOP_RIGHT});
            });
        } else {
            this.setState({ pincodeOptions : [] });
        }
    }

     //****************************CLOSE Warehouse *********************/
     closeEvent              =           ()      =>      {
        if(this.state.warehouseDetail){
            this.props.history.push({pathname: `/warehouse`, state: {warehouseData : this.state.warehouseDetail }})
        }else{
            this.props.history.push({pathname: `/warehouse`})
        }
    }

  
    render                                  =   ()  =>  {

    
        return (<ApplicationLayout>
              <Helmet><title>Warehouse Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.props.location && this.props.location.state && this.props.location.state.warehouseData ? 'Update' : 'Add New'} Warehouse</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.itemAddFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Warehouse" />
                        </button>
                        
                        {/* <button onClick={() => {
                            
                            //ReCall ISC App Data :- 
                            this.props.iscAppDataInit(this.props.user_info, this.props.group_info, this.props.acl_info);
                        }}>
                            Test
                        </button> */}
                        
                        
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row">
                    <div className="pageTbl col-12">
                        {this.state.warehouse_loading ? <Loader/> 
                         : <form className="bg-white p-3" onSubmit={this.addItemFormSubmitEvent}>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Basic Details</span>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3">
                                    <label htmlFor="name" className="form-label require">Warehouse Name</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        name="name"
                                        type="text"
                                        value={this.state.addItemForm.name}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        required={true}
                                        autoComplete="off"
                                        placeholder="Enter Warehouse Name"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Code</label></div>
                                <div className="col-4">
                                    <input
                                        name="code"
                                        type="text"
                                        value={this.state.addItemForm.code}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Enter Warehouse Code"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Organization</label></div>
                                <div className="col-4">
                                   <TapSelect
                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'organization_id')}
                                    options={this.state.allOrganizationList}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOrganizationList.find(s => this.state.addItemForm.organization_id == s.value)}
                                    placeholder={"Select Organization"}
                                    />
                                </div>
                            </div>

                            {this.state.warehouseDetail ?
                                null :
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3"><label className="form-label require">Site Name</label></div>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'site_ids')}
                                            options={this.state.siteOptions}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={this.state.addItemForm.all_sites == true ? false : true}
                                            isMulti={true}
                                            isDisabled={this.state.addItemForm.all_sites == true ? true : false}
                                            value={this.state.siteOptions.filter(s => this.state.addItemForm.site_ids.includes(s.value))}
                                            placeholder={this.state.addItemForm.all_sites == true ? "All Sites" : "Please Select Sites"}
                                        />
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="all_sites"
                                                type="checkbox"
                                                value={true}
                                                onChange={(e) => { this.onSelectingAllSites(e, 'all_sites') }}
                                                className="form-check-input"
                                                id="all_sites"
                                                checked={this.state.addItemForm.all_sites == true}
                                            />
                                            <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                                        </div>
                                    </div>
                                </div>}
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">GSTIN</label></div>
                                <div className="col-4">
                                    <input
                                        name="gstin"
                                        type="text"
                                        value={this.state.addItemForm.gstin}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Enter GST Number"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Phone Number 1</label></div>
                                <div className="col-4">
                                    <input
                                        name="phone1"
                                        type="number"
                                        value={this.state.addItemForm.phone1}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        minLength="10"
                                        maxLength="12"
                                        placeholder="Enter Phone Number 1"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Phone Number 2</label></div>
                                <div className="col-4">
                                    <input
                                        name="phone2"
                                        type="number"
                                        value={this.state.addItemForm.phone2}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Please Enter alternate Phone Number"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Email</label></div>
                                <div className="col-4">
                                    <input
                                        name="email"
                                        type="email"
                                        value={this.state.addItemForm.email}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Please Enter Email"
                                    />
                                </div>
                            </div>
                            <div className="tab_content_wrapper"><span className="content_heading">Address</span></div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Address 1</label></div>
                                <div className="col-4">
                                    <input
                                        name="address1"
                                        type="text"
                                        value={this.state.addItemForm.address1}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Please Enter Address 1"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Address 2</label></div>
                                <div className="col-4">
                                    <input
                                        name="address2"
                                        type="text"
                                        value={this.state.addItemForm.address2}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Please Enter Address 2"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Landmark</label></div>
                                <div className="col-4">
                                    <input
                                        name="landmark"
                                        type="text"
                                        value={this.state.addItemForm.landmark}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Please Enter Landmark"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">City</label></div>
                                <div className="col-4">
                                    <input
                                        name="city"
                                        type="text"
                                        value={this.state.addItemForm.city}
                                        onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Please Enter City"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">Country</label></div>
                                <div className="col-4">
                                    <TapSelect
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'country_id')}
                                        options={this.state.countryOptions}
                                        defaultValue={this.state.countryOptions[0]}
                                        isSearchable={true}
                                        isClearable={true}
                                        required={true}
                                        value={this.state.countryOptions.find(s => s.value == this.state.addItemForm.country_id)}
                                        placeholder="Please Select Country"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label require">State (Source of supply)</label></div>
                                <div className="col-4">
                                    <TapSelect
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addItemForm', 'state_id');
                                            this.stateChangeHandler(selectedOption?.value ?? null);
                                        }}
                                        options={this.state.statesOptions}
                                        isSearchable={true}
                                        isClearable={true}
                                        required={true}
                                        value={this.state.statesOptions.find(s => s.value == this.state.addItemForm.state_id)}
                                        placeholder="Please Select State"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">District</label></div>
                                <div className="col-4">
                                    <TapSelect
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addItemForm', 'district_id');
                                            this.districtChangeHandler(selectedOption?.value ?? null);
                                        }}
                                        options={this.state.districtOptions}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={this.state.districtOptions.find(s => s.value == this.state.addItemForm.district_id)}
                                        placeholder="Please Select District"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3 mt-3">
                                <div className="col-3"><label className="form-label">PIN Code</label></div>
                                <div className="col-4">
                                    <TapSelect
                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', 'pincode')}
                                        options={this.state.pincodeOptions}
                                        isSearchable={true}
                                        isClearable={true}
                                        value={this.state.pincodeOptions.find(s => s.value == this.state.addItemForm.pincode)}
                                        placeholder="Please Select PinCode"
                                    />
                                </div>
                            </div>
                            {this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0
                                ? (<Ax>
                                    <div className="tab_content_wrapper"><span className="content_heading">Additional Attributes</span></div>
                                    {
                                        this.state.formData.additional_attributes.map((i, k) => {
                                            let name = `additional_attributes[${i.key}]`;
                                            let input = null;
                                            if (i.type == 'text') {
                                                input = <input
                                                    name={name}
                                                    type="text"
                                                    value={this.state.addItemForm[name]}
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder={`Please enter ${i.name}`}
                                                />
                                            } else if (i.type == 'numeric') {
                                                input = <input
                                                    name={name}
                                                    type="number"
                                                    value={this.state.addItemForm[name]}
                                                    onChange={(e) => this.formInputHandler(e, 'addItemForm')}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder={`Please enter ${i.name}`}
                                                />
                                            } else if (i.type == 'date') {
                                                input = <DatePicker
                                                    selected={
                                                        this.state.addItemForm[name]
                                                            ? moment(this.state.addItemForm[name]).toDate()
                                                            : false
                                                    }
                                                    name={name}
                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addItemForm')}
                                                    autoComplete="off"
                                                    dateFormat="dd-MMM-yyyy"
                                                    className={"form-control"}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    scrollMonthYearDropdown
                                                    placeholderText={`Please Enter ${i.name}`}
                                                />
                                            } else if (i.type == 'dropdown') {
                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                input = <TapSelect
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addItemForm', name)}
                                                    options={options}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={options.find(o => o.value == this.state.addItemForm[name])}
                                                    placeholder={`Please Select ${i.name}`}
                                                />
                                            }

                                            return (<div key={k} className="row align-items-center mb-3 mt-3">
                                                <div className="col-3"><label className="form-label">{i.name}</label></div>
                                                <div className="col-4">{input}</div>
                                                <div className="col-5">
                                                    <span className="form-text"></span>
                                                </div>
                                            </div>);
                                        })
                                    }
                                </Ax>)
                                : null
                            }
                            <hr />
                            <div className="col-12 text-end">
                                <button type="button" disabled={this.state.itemAddFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary">Cancel</button>
                                <button type="submit" disabled={this.state.itemAddFormSubmitting ? true : false} className="btn btn-primary mx-3">
                                    Submit {this.state.itemAddFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        user_info           :   state.app.user_info,
        group_info          :   state.app.group_info,
        acl_info            :   state.app.acl_info,
        orgInfoData         :   state.app.group_info ? state.app.group_info : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFormDataInit          :   (data) => dispatch(actions.requisionFormDataStart()),
        iscAppDataInit          :   (user_info, group_info, acl_info) => dispatch(actions.iscAppDataLoading(user_info, group_info, acl_info))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseAdd);