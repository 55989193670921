import React from 'react';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';



class UserAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initAddUserForm           =        {
            first_name               :        '',
            last_name                :        '',
            mobile                   :        '',
            email                    :        '',
            group_role_id            :        '',
            password                 :        '',
            site_ids                 :        [],
            all_sites                 :        false,
            designation               :        '',
        }
        this.state                    =        {
            saveFormSubmitting         :       false,
            addUserForm                :       {...this.initAddUserForm},
            iam_user_sites             :       [],
            allRolesList               :       [],
            new_designation            :       'N',
            allDesignationList         :        []
        };

        this.userFormDataUrl    =   ORG_API_BASE_URL_2 + '/users_form_data'
        this.userAddUrl         =   ORG_API_BASE_URL_2  + '/user/add';

    }

    componentDidMount() {
       this.initalizeFormData(this.props)
    }

    componentWillReceiveProps(nextProps){

        if (nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initalizeFormData(nextProps)
        }
             
    }

    initalizeFormData       =       (pr)        =>      {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.userFormDataUrl,
        this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                iam_user_sites          :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [], 
                allRolesList            :   respData && respData.roles && respData.roles.length > 0 ? respData.roles.map(oc => { return ({ value: oc.id, label: oc.role, }) }) : [],
                allDesignationList      :   respData && respData.designations && respData.designations.length > 0 ? respData.designations.map(oc => { return ({ value: oc, label: oc, }) }) : [],
            })
        }).then(() => this.setState({form_data_loading: false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/user_list` })

    }

    onSelectingAllSites             =           (e,key)             =>      {
        let checkedSite = document.getElementById('all_sites').checked
        if(checkedSite == true) {
            this.setState({
                 addUserForm : {...this.state.addUserForm,
                   all_sites  :  true,
                   site_ids  :   [],
            }})
        }else{
           this.setState({
               addUserForm : {...this.state.addUserForm,
                 all_sites  :  false,
                 site_ids  :   []
          }})
        }
   }

    submitAddUserForm       =       (e)     =>      {
        e.preventDefault();
        let frmData = null
        if(this.state.addUserForm.all_sites == true){
            let site = this.state.iam_user_sites.map(s => s.value);
            
             frmData = {
                 ...this.state.addUserForm,
                 site_ids      :   site,
             }
        }else{
             frmData = {
                 ...this.state.addUserForm
             }
        }
        this.setState({ saveFormSubmitting: true })
         HttpAPICall.withAthorization('POST', this.userAddUrl, this.props.access_token, {}, { ...frmData }, (response) => {
             toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
             this.setState({ addUserForm: { ...this.initAddUserForm } })
             this.props.history.push('/user_list')
         }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    saveUserFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddUserForm} id="saveUserForm">
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="first_name" className="form-label require">First Name</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                         name="first_name"
                         type="text"
                         value={this.state.addUserForm.first_name}
                         className="form-control"
                         autoComplete="off"
                         required={true}
                         onChange={(e) => this.formInputHandler(e, "addUserForm")}
                         placeholder="Please enter First Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="last_name" className="form-label">Last Name</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="last_name"
                        type="text"
                        value={this.state.addUserForm.last_name}
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addUserForm")}
                        placeholder="Please enter Last Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Mobile Number</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                         name="mobile"
                         type="number"
                         value={this.state.addUserForm.mobile}
                         className="form-control"
                         autoComplete="off"
                         required={true}
                         onChange={(e) => this.formInputHandler(e, "addUserForm")}
                         placeholder="Please enter Mobile Number"
                    />
                </div>
                <div className='col-sm-5 form-text'>
                    Mobile number will be required as LoginID at Login
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Designation</label>
                </div>
                <div className="col-sm-4">
                    {this.state.new_designation == "Y"
                        ? <input
                            name="designation"
                            type="text"
                            value={this.state.addUserForm.designation}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addUserForm")}
                            placeholder="Please enter Designation"
                        // autoFocus={true}
                        />
                        : <TapSelect
                            options={this.state.allDesignationList}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addUserForm', 'designation')}
                            value={this.state.allDesignationList.find(u => this.state.addUserForm.designation == u.value)}
                            isClearable={true}
                            isSearchable={true}
                            placeholder="Select Designation"
                            autoCompelete="off"
                        />}
                </div>
                <div className="col-sm-5">
                    <input
                        name="new_designation"
                        type="checkbox"
                        value={this.state.new_designation}
                        onChange={(e) => { this.setState({ new_designation: this.state.new_designation == 'N' ? 'Y' : 'N' }) }}
                        checked={this.state.new_designation == 'Y'}
                        className="form-check-input"
                        id="new_designation"
                    />
                    <label className="form-check-label mx-2" htmlFor="new_designation">New </label>
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Email</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                         name="email"
                         type="email"
                         value={this.state.addUserForm.email}
                         className="form-control"
                         autoComplete="off"
                         onChange={(e) => this.formInputHandler(e, "addUserForm")}
                         placeholder="Please enter Email"
                    />
                </div>
            </div>
           
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Role</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect
                        options={this.state.allRolesList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addUserForm', 'group_role_id');
                        }}
                        isSearchable={true}
                        isClearable={true}
                        menuPlacement="bottom"
                        value={this.state.allRolesList.find(s => this.state.addUserForm.group_role_id == s.value)}
                        placeholder="Select Roles"
                        
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Password</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="password"
                        type="text"
                        value={this.state.addUserForm.password}
                        className="form-control"
                        autoComplete="off"
                        required={true}
                        onChange={(e) => this.formInputHandler(e, "addUserForm")}
                        placeholder="Please enter Password"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Sites</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addUserForm', 'site_ids')}
                        options={this.state.iam_user_sites}
                        isSearchable={true}
                        isClearable={true}
                        required={this.state.addUserForm.all_sites == true ? false : true}
                        isMulti={true}
                        menuPlacement="top"
                        isDisabled={this.state.addUserForm.all_sites == true ? true : false}
                        value={this.state.iam_user_sites.filter(s => this.state.addUserForm.site_ids.includes(s.value))}
                        placeholder={this.state.addUserForm.all_sites == true ? "All Sites" : "Please Select Sites"}
                    />
                </div>
                <div className='col-sm-2'>
                    <div className="form-check form-check-inline">
                        <input
                            name="all_sites"
                            type="checkbox"
                            value={true}
                            onChange={(e) => { this.onSelectingAllSites(e, 'all_sites') }}
                            className="form-check-input"
                            id="all_sites"
                            checked={this.state.addUserForm.all_sites == true}
                        />
                        <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                    </div>
                </div>
            </div>
           
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>User Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>Add New User</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {this.state.form_data_loading ? <Loader/> :
                        this.saveUserFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
        iam_user_sites      :       state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(UserAdd);