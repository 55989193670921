import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from "../../../components/ui/Status";
import TapSelect from '../../../components/ui/TapSelect';


class WeeklyCounterlogList extends React.Component {

    constructor(props) {
        super(props);

        this.state                         =     {
           
            minimizeTable                       :    false,
        };
        
    }

    componentDidMount() {

    }

    counterlogJsx           =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={"col-sm-12 "}>
                <div className='row'>
                    <div className='col-sm-2'>
                        <h3>Weekly Counterlog</h3>
                    </div>
                    <div className='col-sm-7 mt15 text-center '>
                        <div className='row '>
                            <div className=' col-sm-3 text-end'>
                                <button type="button" className="btn btn-outline-success">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowLeft} className="mx-1" />  Prev
                                </button>
                            </div>
                            <div className='  col-sm-5 add_calender_section' style={{top:"5px"}}>

                                <input placeholder='1-Feb-2022  - 1-Mar-2022'
                                    className='form-control' style={{ height: '30px' }} />
                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                    alt="requisition_date"
                                    className="add_calender-icon" />
                            </div>
                            <div className='col-sm-4 text-start'>
                                <button type="button" className="btn btn-outline-success">
                                    Next  <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowRight} className="mx-1" />
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className='col-sm-3 text-end mt15'>
                    <button type="button" className="btn btn-primary" >
                           Counterlog
                        </button>
                        <button type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className='dropdown-item'>Fuel</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.counterlogListJsx()}</div>

                </div>
            </div>
        </Ax>);
    }

    counterlogListJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered bg-white  table-fixed">
                <thead className="table-secondary text-center">
                    <tr>
                        <th scope="col" style={{ width: "20%" }}>Asset</th>
                        <th scope="col" style={{ width: "10%" }}>Shift</th>
                        <th scope="col" style={{ width: "10%" }}>Monday <br /> 01-Jan-2021</th>
                        <th scope="col" style={{ width: "10%" }}>Tuesday <br /> 02-Jan-2021</th>
                        <th scope="col" style={{ width: "10%" }}>Wednesday <br /> 03-Jan-2021</th>
                        <th scope="col" style={{ width: "10%" }}>Thursday <br /> 04-Jan-2021</th>
                        <th scope="col" style={{ width: "10%" }}>Friday <br /> 05-Jan-2021</th>
                        <th scope="col" style={{ width: "10%" }}>Saturday <br /> 06-Jan-2021</th>
                        <th scope="col" style={{ width: "10%" }}>Sunday <br /> 07-Jan-2021</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-center">
                        <td className="text-start " rowSpan={2}>123 (Mnb) </td>
                        <td>Day Shift</td>
                        <td className='hide-button'>
                            <a role="button" className="primary-color float-end " data-bs-toggle="dropdown" role="button" aria-expanded="false" >
                                <sup className='hide' >  <tapIcon.FontAwesomeIcon icon={tapIcon.faChevronCircleDown} className="fs14" /></sup>
                            </a>
                            <ul className="dropdown-menu  " style={{width : "470px"}}>
                                <li className='dropdown-item'>
                                    <table className='table table-borderless table-hover bg-white  '>
                                        <thead className='table-secondary'>
                                            <th style={{ width: "35%" }}>Transactions</th>
                                            <th style={{ width: "55%" }}>Value</th>
                                            <th >Action</th>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Fuel FeedIn</td>
                                                <th >50</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Expense</td>
                                                <th>INR 150</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Productivity</td>
                                                <th>1500 Cubic Meter <br /> 15 Tons</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Activity</td>
                                                <th>2 Trips <br /> 3 Sessions</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Downtime</td>
                                                <th>MD: 3 :15</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>SCS</td>
                                                <th>3 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Fuel Tank Balance</td>
                                                <th>55 Ltr</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Document</td>
                                                <th>5 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ul>

                            73500 Hr

                            <br />
                            237500 Km

                        </td>
                        <td>+</td>
                        <td>+</td>
                        <td>+</td>
                        <td>+</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr className="text-center">
                        <td>Night Shift</td>
                        <td className='hide-button'>
                            <a role="button" className="primary-color float-end " data-bs-toggle="dropdown" role="button" aria-expanded="false" >
                                <sup className='hide' >  <tapIcon.FontAwesomeIcon icon={tapIcon.faChevronCircleDown} className="fs14" /></sup>
                            </a>
                            <ul className="dropdown-menu  ">
                                <li className='dropdown-item'>
                                    <table className='table table-borderless  '>
                                        <thead className='table-secondary'>
                                            <th style={{ width: "35%" }}>Transactions</th>
                                            <th style={{ width: "45%" }}>Value</th>
                                            <th>Action</th>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Fuel FeedIn</td>
                                                <th >50</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Expense</td>
                                                <th>INR 150</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Productivity</td>
                                                <th>1500 Cubic Meter <br /> 15 Tons</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Activity</td>
                                                <th>2 Trips <br /> 3 Sessions</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Downtime</td>
                                                <th>MD: 3 :15</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>SCS</td>
                                                <th>3 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Fuel Tank Balance</td>
                                                <th>55 Ltr</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Document</td>
                                                <th>5 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ul>

                            45600 Hr

                            <br />
                            7500 Km

                        </td>
                        <td>+</td>
                        <td>+</td>
                        <td>+</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr className="text-center">
                        <td className="text-start " rowSpan={2}>Air filter </td>
                        <td>Day Shift</td>
                        <td className='hide-button'>
                            <a role="button" className="primary-color float-end " data-bs-toggle="dropdown" role="button" aria-expanded="false" >
                                <sup className='hide' >  <tapIcon.FontAwesomeIcon icon={tapIcon.faChevronCircleDown} className="fs14" /></sup>
                            </a>
                            <ul className="dropdown-menu  ">
                                <li className='dropdown-item'>
                                    <table className='table table-borderless  '>
                                        <thead className='table-secondary'>
                                            <th style={{ width: "35%" }}>Transactions</th>
                                            <th style={{ width: "45%" }}>Value</th>
                                            <th>Action</th>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Fuel FeedIn</td>
                                                <th >50</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Expense</td>
                                                <th>INR 150</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Productivity</td>
                                                <th>1500 Cubic Meter <br /> 15 Tons</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Activity</td>
                                                <th>2 Trips <br /> 3 Sessions</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Downtime</td>
                                                <th>MD: 3 :15</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>SCS</td>
                                                <th>3 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Fuel Tank Balance</td>
                                                <th>55 Ltr</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Document</td>
                                                <th>5 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ul>

                            9300 Hr

                            <br />
                            2300 Km

                        </td>
                        <td>+</td>
                        <td>+</td>
                        <td>+</td>
                        <td>+</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr className="text-center">
                        <td>Night Shift</td>
                        <td className='hide-button'>
                            <a role="button" className="primary-color float-end " data-bs-toggle="dropdown" role="button" aria-expanded="false" >
                                <sup className='hide' >  <tapIcon.FontAwesomeIcon icon={tapIcon.faChevronCircleDown} className="fs14" /></sup>
                            </a>
                            <ul className="dropdown-menu  ">
                                <li className='dropdown-item'>
                                    <table className='table table-borderless  '>
                                        <thead className='table-secondary'>
                                            <th style={{ width: "35%" }}>Transactions</th>
                                            <th style={{ width: "45%" }}>Value</th>
                                            <th>Action</th>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Fuel FeedIn</td>
                                                <th >50</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Expense</td>
                                                <th>INR 150</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Productivity</td>
                                                <th>1500 Cubic Meter <br /> 15 Tons</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Activity</td>
                                                <th>2 Trips <br /> 3 Sessions</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Downtime</td>
                                                <th>MD: 3 :15</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>SCS</td>
                                                <th>3 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Fuel Tank Balance</td>
                                                <th>55 Ltr</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                            <tr>
                                                <td>Document</td>
                                                <th>5 Nos</th>
                                                <td><tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate" color={"#45BEA4"} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ul>

                            735 Hr

                            <br />
                            2370 Km

                        </td>
                        <td>+</td>
                        <td>+</td>
                        <td>+</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
        </Ax>)
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
            {this.counterlogJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(WeeklyCounterlogList);