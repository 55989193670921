import React from 'react';
import Loader from "../../../components/ui/Loader/Loader";
import { connect, useSelector } from "react-redux";
import tapIcon from "../../../services/TapIcon";

const AssetmatrixDocuments = ({assetMatrixDocumentList ,listing_loading}) => {

    const permissions = useSelector(state => state.app.acl_info && state.app.acl_info.permissions ? state.app.acl_info.permissions : []);
    
    return (
        <>
            <div className="tab_content_wrapper mt-2">
                <span className="content_heading">Asset Matrix Documents</span>
            </div>
            {listing_loading ? (
                <div className="text-center"><Loader /></div>
            ) : (
                <table className="table table-bordered mt-3">
                    <thead className="table-secondary">
                        <tr>
                            <th style={{ width: "5%" }}>S.No</th>
                            <th style={{ width: "31%" }}>Document Name</th>
                            <th style={{ width: "31%" }}>Document Number</th>
                            <th style={{ width: "11%" }} className="text-center">View</th>
                            {/* <th style={{ width: "11%" }} className="text-center">Download</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {assetMatrixDocumentList.length > 0 ? assetMatrixDocumentList.map((doc, index) => (
                        <tr
                        key={doc.id}
                        >
                            <td>
                                {index + 1}
                            </td>
                            <td className="text-capitalize">
                                {doc.name}
                            </td>
                            <td className="text-capitalize">
                                {doc.number}
                            </td>
                            <td className="text-center">
                            <a role="button"
                                    target='_blank'
                                    href={doc.path}
                                    className={[permissions.includes('iam-asset-matrix-doc-view') ? '' : 'disabled'].join(' ')}
                                    title={!permissions.includes('iam-asset-matrix-doc-view') ? 'You do not have permission to perform this action' : ''}
                                    style={{ "pointerEvents": "all" }}
                                > <tapIcon.FontAwesomeIcon icon={tapIcon.faEye} /></a>
                            </td>
                        </tr>
                        )) : (
                             <tr>
                                 <td className="text-center" colSpan="4">No Asset Matrix Documents</td>
                             </tr>
                         )}
                    </tbody>
                </table>
            )}
        </>
    );
}

 
export default AssetmatrixDocuments;
