import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import swal from "sweetalert";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import { DisplayListPagination, PeriodSelectionWithCustom } from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import TapSelect from "../../../components/ui/TapSelect";
import { Modal } from 'bootstrap';
import { Helmet } from 'react-helmet';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import FuelFeedInView from '../../transactions/fuelFeedIn/FuelFeedInView';
import FuelIssued from '../../groupData/fuelStation/FuelIssued';
import React from 'react';
import FuelReceive from '../../groupData/fuelStation/FuelReceive';
import FuelTransfer from '../../groupData/fuelStation/FuelTransfer';
import { toast } from 'react-toastify';

class FuelStationLedger extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            fuel_station_id                 :       '',
            fuel_type                       :       '',
            date_range                      :       '',
            date_range_start                :       '',
            date_range_end                  :       '',
        };
        
        this.reportFilterFormLabel          =   {
            fuel_station_id                 :       'Fuel Station Name : ',
            fuel_type                       :       'Fuel Type : ',
            date_range                      :       'Date Range : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null,  
            all_periods                         :    [],
            allFuelTypes                        :    [],
            allFuelStationList                  :    [],
            summaryData                         :    null,
            transactionType                     :   '',
            transactionId                       :   '',
            summary_loading                     :   null,
            view_loading                        :   false,
            fuelDetailData                      :   null,
            reportData                          :   null,                  
        };
        this.logFuelReceiveRef          =   React.createRef();
        this.logFuelIssuedRef           =   React.createRef();
        this.logFuelTransferRef         =   React.createRef();

        this.formDataUrl        =   IAM_API_BASE_URL_2 + '/report/fuel_station_summary/form_data';
        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/fuel_station_ledger';
        this.fuelSummaryUrl     =   IAM_API_BASE_URL_2 + '/fuel_station/fuel_summary';
        this.stationUrl         =   IAM_API_BASE_URL_2 + '/station/ledger_detail';

    }
    
    componentDidMount(){
        this.initilaizeAppDataToFilterForm(this.props);
        this.viewTransactionModal                =          new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
   
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }


    initilaizeAppDataToFilterForm       =   (pr)  =>  {
       
          const report_name   =   'fuel_station_ledger';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })

         let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;

        HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, {include : ['fuel_station']}, null, (response) => {
                this.setState({
                    access_token                :   pr.access_token,
                    formDataLoaded              :   true,
                    all_periods                 :   pr.all_periods ,
                    allFuelStationList          :   response.data && response.data.fuel_stations ? response.data.fuel_stations.map((s) => { return {value: s.id, label: s.fuelstation_name,fuels : s.fuels}}) : [],
                    
                 }, () => {
                    let ParamObject                 =   new URLSearchParams(this.props.location.search);
                    if(ParamObject.get('date_range')) {
                        let period                =   all_periods.find(p => p.key == ParamObject.get('date_range'));
                        let startDate             =   period && period.dateFromYmd &&  moment(period.dateFromYmd).format('YYYY-MM-DD');
                        let endDate               =    period && period.dateToYmd && moment(period.dateToYmd).format('YYYY-MM-DD')
                        
                        let updateFilterForm      =   {
                            date_range                  :    ParamObject.get('date_range'), 
                            fuel_station_id    :    ParamObject.get('fuel_station_id'), 
                            fuel_type            :    ParamObject.get('fuel_type_id'), 
                            date_range_start            : ParamObject.get('date_range') && ParamObject.get('date_range') !== "custom" ?  startDate : ParamObject.get('date_range_start') ,
                            date_range_end          : ParamObject.get('date_range') && ParamObject.get('date_range') !== "custom" ?  endDate : ParamObject.get('date_range_end') ,
                        };
                        let selectedFuelStation = this.state.allFuelStationList && this.state.allFuelStationList.length > 0 ?  this.state.allFuelStationList.find(fs => {return(fs.value == ParamObject.get('fuel_station_id'))}) : null;
                        if(selectedFuelStation){
                            this.setState({
                                allFuelTypes  : selectedFuelStation && selectedFuelStation.fuels && selectedFuelStation.fuels.length > 0 ? selectedFuelStation.fuels.map((s) => { return { value: s.id, label: s.fuel_type }; }) : [],
                            });
                        }
                       
                        setTimeout(() => {
                            this.setState({ reportFilterForm: { ...this.state.reportFilterForm, ...updateFilterForm } },
                                function () {
                                    // this.reportFilterhandler()
                                    this.reportFilterFormSubmit()
                                });
                        }, 1000)
                     
                    }else{
                        this.reportFilterhandler()
                    }
                });
            });
    }
    
    reportFilterFormSubmit              =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'fuel_station_id') {
                        show_val            =   this.state.allFuelStationList.filter(s => s.value == value).map(s => s.label); 
                    }
                    if(key == 'fuel_type') {
                        show_val            =  this.state.allFuelTypes.filter(s => s.value == value).map(s => s.label); 
                    }
                  
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListingTblData(1);
        });
    
    }

    reportFilterhandler                 =   (collapse = true)  =>  {
        new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    loadListingTblData                  =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false,
            summaryData                 :   null,
            summary_loading             :   true,
        });
        let params                      =   {page:page, ...this.state.reportFilterForm};   

        //Api to call Summary 
        HttpAPICall.withAthorization('GET', this.fuelSummaryUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                summaryData                 :   respData,
                reportDownloadBtn           :   true,
            });
        }).then(() => {
            this.setState({summary_loading: false});
        });

        //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;

            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
           
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    loadListingOnPageClick                  =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false,
        });
        let params                       =   {page:page ,...this.state.reportFilterForm};   
         //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;

            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
           
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }


    closeReport                             =       ()      =>  {
      
        this.props.history.push('/report')
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.reportFilterForm,download : "download"} , {} , (response) => 
        DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    viewTransactionInit             =       (transaction_id,transaction_type,fuel_id = null)      =>      {
        if(transaction_id && transaction_type == 'fuelfeedin'){
            this.setState({ transactionId: transaction_id ,transactionType : transaction_type}
                , () => {
                        this.viewTransactionModal.show()
                })
        }else{
            this.getFuelDetail(fuel_id)
            this.setState({ transactionId: fuel_id ,transactionType : transaction_type}
                , () => {
                        this.viewTransactionModal.show()
                })
        }
    }

    editFuelModalInit      =       (id,transaction_type)    =>  {
        if(id){
            HttpAPICall.withAthorization('GET', this.stationUrl + '/'  + id, this.props.access_token, {} , {} , (response) =>{
                if(transaction_type == 'fuel_to_other' ){
                    this.logFuelIssuedRef.current.editIssuedFuelModalInit(id,response.data)
                }
                if(transaction_type == 'fuel_added' ){
                    this.logFuelReceiveRef.current.editReceivedFuelModalInit(id,response.data)
                }
                if(transaction_type == 'fuel_transfer' ){
                   this.logFuelTransferRef.current.editFuelTransferModalInit(id,response.data)
                }
            }  
          ).then(() => this.setState({ view_loading : false}));
        }
    }

    getFuelDetail           =       (id)        =>  {
        this.setState({ view_loading: true});
        HttpAPICall.withAthorization('GET', this.stationUrl + '/'  + id, this.props.access_token, {} , {} , (response) => 
          this.setState({fuelDetailData : response.data})
        ).then(() => this.setState({ view_loading : false}));
    }

    receiveModalInit        =       (id,fuel_type)        =>      {
        if(id){
            this.logFuelReceiveRef.current.fuelReceiveModalInit(id,fuel_type)
        }
    }

    fuelIssuedModalInit        =       (id,fuel_type)        =>      {
        if(id){
            this.logFuelIssuedRef.current.issuedFuelModalInit(id,fuel_type)
        }
    }

    fuelTransferModalInit        =       (id,fuel_type)        =>      {
        if(id){
            this.logFuelTransferRef.current.transferFuelModalInit(id,fuel_type)
        }
    }

    deleteFuelLedger            =       (id)        =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.stationUrl + '/' + id, this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.loadListingTblData(1)
                });
            }
        });
    }
    
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Fuel Station Ledger</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.reportDownloadBtn ? <Ax>

                        <button type="button" disabled={this.state.reportData?.download_access === 'N'} id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{ cursor: "pointer" }}>
                            <li>   <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-fuel-station-fuel-receive')) {
                                        return this.receiveModalInit(this.state.reportFilterForm?.fuel_station_id,this.state.reportFilterForm?.fuel_type)
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-receive') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-fuel-station-fuel-receive') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }} >Received Fuel</a></li>
                            <li>   <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-fuel-station-fuel-issue')) {
                                        return this.fuelIssuedModalInit(this.state.reportFilterForm?.fuel_station_id,this.state.reportFilterForm?.fuel_type)
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-issue') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-fuel-station-fuel-issue') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}  >Issue Fuel</a></li>
                            <li>   <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-fuel-station-fuel-transfer')) {
                                        return this.fuelTransferModalInit(this.state.reportFilterForm?.fuel_station_id,this.state.reportFilterForm?.fuel_type)
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-transfer') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('iam-fuel-station-fuel-transfer') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}    >Fuel Transfer To Station</a></li>
                                
                            
                        </ul>

                    </Ax> : null}

                    {/* <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button> */}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }

    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse" aria-expanded="true" >
            <div className={['p-3', 'filter_form'].join(' ')}>
                <form onSubmit={this.reportFilterFormSubmit} >

                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end  require">Fuel Station Name</label>
                        <div className="col-sm-6">
                            <TapSelect
                                options={this.state.allFuelStationList}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'fuel_station_id');
                                    this.setState({
                                        allFuelTypes: selectedOption && selectedOption.fuels && selectedOption.fuels.length > 0 ? selectedOption.fuels.map((s) => { return { value: s.id, label: s.fuel_type }; }) : [],
                                    });
                                }}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.allFuelStationList.find(s => this.state.reportFilterForm.fuel_station_id == s.value)}
                                placeholder="Select Fuel Station"
                            />
                        </div>
                    </div>
                    <div className="row my-3 align-items-center">
                        <label className="col-sm-3 col-form-label text-end ">Fuel Type</label>
                        <div className="col-sm-6">
                            <TapSelect
                                options={this.state.allFuelTypes}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'fuel_type');
                                }}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                value={this.state.allFuelTypes.find(s => this.state.reportFilterForm.fuel_type == s.value)}
                                placeholder="Select Fuel Type"
                            />
                        </div>
                    </div>
    
    
                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end require">Select Period</label>
                        <div className="col-sm-6">
                            <PeriodSelectionWithCustom
                                periods={this.state.all_periods ? this.state.all_periods : []}
                                value={this.state.reportFilterForm.date_range}
                                startDate={this.state.reportFilterForm.date_range_start}
                                endDate={this.state.reportFilterForm.date_range_end}
                                required={true}
                                onSelectPeriod={(period, startDate, endDate) => {
                                    this.setState({
                                        reportFilterForm
                                            : {
                                            ...this.state.reportFilterForm,
                                            date_range: period,
                                            date_range_start: startDate ? startDate : null,
                                            date_range_end: endDate ? endDate : null
                                        }
                                    });
                                }}
                                menuPlacement="bottom"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="pb-3 col-9 text-end">
                            <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                        </div>
                        <hr />
                    </div>

                </form>


            </div>

        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div >
            {this.state.summary_loading
                ? <Loader />
                : (this.state.summaryData ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
      
        return (<Ax>
            <div className="px-2 bg-white">

                <div className="pb0 pt0">
                    <div className='row'>
                        <div className='col-12'>
                            <table className='table table-borderless align-middle bg-white'>
                                <tbody>
                                        <tr>
                                            <td style={{ width: "10%" }}>Fuel Station : </td>
                                            <th style={{ width: "40%" }}>{this.state.summaryData && this.state.allFuelStationList && this.state.allFuelStationList.length > 0 ? this.state.allFuelStationList.filter((s) => this.state.reportFilterForm?.fuel_station_id == s.value).map(s => s.label) : '-'}</th>
                                            <td style={{ width: "20%" }} className='text-end'>Opening Balance : </td>
                                            <th style={{ width: "30%" }} className='text-end'>{this.state.summaryData && this.state.summaryData.opening_balance ? this.state.summaryData.opening_balance  : "0"} </th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "20%" }}>Fuel Type : </td>
                                            <th style={{ width: "30%" }}>{this.state.summaryData && this.state.allFuelTypes && this.state.allFuelTypes.length > 0 ? this.state.allFuelTypes.filter((s) => this.state.reportFilterForm?.fuel_type == s.value).map(s => s.label) : '-'}</th>
                                            <td style={{ width: "20%" }} className='text-end'>Total Received : </td>
                                            <th style={{ width: "30%" }} className='text-end'>{this.state.summaryData && this.state.summaryData.received ? this.state.summaryData.received  : "0"}   </th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "20%" }} > Period :</td>
                                            <th style={{ width: "30%" }}>{this.state.summaryData && this.state.summaryData.display_with_date ? this.state.summaryData.display_with_date : "-"}</th>
                                            <td style={{ width: "20%" }} className='text-end'>Total Issued : </td>
                                            <th style={{ width: "30%" }} className='text-end'>{this.state.summaryData && this.state.summaryData.issued ? this.state.summaryData.issued  : "0"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "20%" }} ></td>
                                            <th style={{ width: "30%" }} ></th>
                                            <td style={{ width: "20%" }} className='text-end'>Closing Balance : </td>
                                            <th style={{ width: "30%" }} className='text-end'>{this.state.summaryData && this.state.summaryData.closing_balance ? this.state.summaryData.closing_balance  : "0"}</th>
                                        </tr>
                                    </tbody>
                              
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {this.reportListJsx()}
        </Ax>);
    }
    
    reportListJsx                          =   ()  =>  {
       
        return (<Ax>
          
            <table className="table table-bordered  table-sm align-middle bg-white my-2 table-fixed">
                <thead className="align-middle table-secondary">
                    <tr className='text-start'>
                        <th className="text-center" style={{ width: "5%" }}>S.No</th>
                        <th className='text-center' style={{ width: "12%" }}>Date of Transaction</th>
                        <th className='text-center' style={{ width: "12%" }}>Transaction Type</th>
                        <th className='text-center' style={{ width: "12%" }}>Transaction ID</th>
                        <th style={{ width: "31%" }}>Details</th>
                        <th scope="col" className='text-end' style={{ width: "10%" }}>Received</th>
                        <th scope="col" className='text-end' style={{ width: "10%" }}>Issued</th>
                         <th scope="col" className='text-center' style={{ width: "5%" }}>Action</th> 
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {
                            return (
                                <tr className='text-center'>
                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td>{data.transaction_date_display ? data.transaction_date_display : "-"}</td>
                                    <td>{data.transaction_type_display ? data.transaction_type_display : "-"}</td>
                                    <td><a className={"link-primary cursor_pointer"} role="button" onClick={() => this.viewTransactionInit(data?.transaction_id, data?.transaction_type, data.id)}>{data.transaction_id ? data.transaction_id : "View"}</a></td>
                                    <td className='text-start'>{data.details ? data.details : "-"}</td>
                                    <td className='text-end'>{data.received ? data.received : "-"}</td>
                                    <td className='text-end'>{data.issued ? data.issued : "-"}</td>
                                    <td className="text-center"><tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu cursor_pointer">
                                            <li>  <a className='dropdown-item' onClick={() => this.viewTransactionInit(data?.transaction_id, data?.transaction_type, data.id)}>View</a>   </li>
                                            {data.transaction_type !== 'fuelfeedin' && data.transaction_type == "fuel_added" ?
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-fuel-station-fuel-receive-edit')) {
                                                            return this.editFuelModalInit(data.id, data?.transaction_type)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-receive-edit') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-fuel-station-fuel-receive-edit') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Edit Fuel Receive</a></li>
                                                : null}
                                            {data.transaction_type !== 'fuelfeedin' && data.transaction_type == "fuel_to_other" ?
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-fuel-station-fuel-issue-edit')) {
                                                            return this.editFuelModalInit(data.id, data?.transaction_type)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-issue-edit') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-fuel-station-fuel-issue-edit') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Edit Fuel Issue</a></li>
                                                : null}
                                            {data.transaction_type !== 'fuelfeedin' && data.transaction_type == "fuel_transfer" ?
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-fuel-station-fuel-transfer-edit')) {
                                                            return this.editFuelModalInit(data.id, data?.transaction_type)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-transfer-edit') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-fuel-station-fuel-transfer-edit') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Edit Fuel Transfer</a></li>
                                                : null}
                                            {data.transaction_type !== 'fuelfeedin' && data.transaction_type == "fuel_added" ?
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-fuel-station-fuel-receive-delete')) {
                                                            return this.deleteFuelLedger(data.id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-receive-delete') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-fuel-station-fuel-receive-delete') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete Fuel Receive</a></li>
                                                : null}

                                            {data.transaction_type !== 'fuelfeedin' && data.transaction_type == "fuel_to_other" ?
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-fuel-station-fuel-issue-delete')) {
                                                            return this.deleteFuelLedger(data.id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-issue-delete') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-fuel-station-fuel-issue-delete') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete Fuel Issue</a></li>
                                                : null}
                                            {data.transaction_type !== 'fuelfeedin' && data.transaction_type == "fuel_transfer" ?
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.includes('iam-fuel-station-fuel-transfer-delete')) {
                                                            return this.deleteFuelLedger(data.id)
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.includes('iam-fuel-station-fuel-transfer-delete') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.includes('iam-fuel-station-fuel-transfer-delete') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Delete Fuel Transfer</a></li>
                                                : null}

                                        </ul>
                                    </td>
                                </tr>
                            )
                        }))
                        : <tr><td className="text-center" colSpan={9}>No Record Found</td></tr>
                    }

                </tbody>
            </table>
        </Ax>);
    }

    viewTransactionJsx          =       ()      =>      {
        return (<div className="modal fade" id="viewTransactionModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchFundTransferModalLabel">
                            {this.state.transactionType == 'fuelfeedin' ? `FuelFeedIn : ${this.state.transactionId}`
                                : this.state.fuelDetailData && this.state.fuelDetailData.transaction_type == "fuel_added"
                                    ? "Receive Fuel" : this.state.fuelDetailData && this.state.fuelDetailData.transaction_type == "fuel_to_other"
                                        ? "Issue Fuel" : this.state.fuelDetailData && this.state.fuelDetailData.transaction_type == "fuel_transfer"
                                            ? "Fuel Transfer" : "-"}
                           
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                         {this.state.transactionType == 'fuelfeedin' 
                          ?  <FuelFeedInView fuelFeedInId={this.state.transactionId}/>
                          : this.fuelDetailJsx() }
                           
                    </div>
                </div>
            </div>
        </div>)

    }

    fuelDetailJsx       =       ()      =>      {
      let fuelDetail = this.state.fuelDetailData;
      let fuel_density_qty = 0;
      if(fuelDetail){
        fuel_density_qty = (1000 / Number(fuelDetail.fuel_density)) * (Number(fuelDetail.tanker_loaded_weight) - Number(fuelDetail.tanker_empty_weight));
      }
        return (<Ax>
            {this.state.view_loading
                ? <Loader />
                :
                fuelDetail ?
                    <Ax>
                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                            <tbody>
                                <tr>
                                    <td style={{ width: "25%" }}>Date of Transaction</td>
                                    <th>{fuelDetail.transaction_date_display ? fuelDetail.transaction_date_display : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Fuel Station {fuelDetail.transfer_type == "OUT" && fuelDetail.transaction_type == 'fuel_transfer' ? "from" : fuelDetail.transfer_type == "IN" && fuelDetail.transaction_type == 'fuel_transfer' ? 'to' : ""}</td>
                                    <th>{fuelDetail.station && fuelDetail.station.fuelstation_name ? fuelDetail.station.fuelstation_name : "-"}</th>
                                </tr>
                                {fuelDetail.transaction_type == 'fuel_transfer' ? <tr>
                                    <td style={{ width: "25%" }}>Fuel Station {fuelDetail.transfer_type == "OUT" && fuelDetail.transaction_type == 'fuel_transfer' ? "to" : fuelDetail.transfer_type == "IN" && fuelDetail.transaction_type == 'fuel_transfer' ? 'from' : ""}</td>
                                    <th>{fuelDetail.details ? fuelDetail.details : "-"}</th>
                                </tr> : null}
                               
                                <tr>
                                    <td style={{ width: "25%" }}>Fuel Type</td>
                                    <th>{fuelDetail.fuel_type_name ? fuelDetail.fuel_type_name : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Quantity</td>
                                    <th>{fuelDetail.fuel_qty ? fuelDetail.fuel_qty + ' ' + fuelDetail.fuel_type_unit : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Unit</td>
                                    <th>{fuelDetail.rate ? fuelDetail.rate : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Amount</td>
                                    <th>{fuelDetail.amount ? '₹' + fuelDetail.amount : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Transaction Type</td>
                                    <th>{fuelDetail.transaction_type_display ? fuelDetail.transaction_type_display : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>
                                        {fuelDetail.transaction_type == "fuel_added"
                                            ? "Received from (Fuel Vendor)" : fuelDetail.transaction_type == "fuel_to_other"
                                                ? "Fuel Receiver" : null}</td>
                                    <th>{fuelDetail.details ? fuelDetail.details : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Reference Number</td>
                                    <th>{fuelDetail.reference_num ? fuelDetail.reference_num : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Remarks</td>
                                    <th>{fuelDetail.remarks ? fuelDetail.remarks : "-"}</th>
                                </tr>
                            </tbody>
                        </table>
                        {fuelDetail.transaction_type == "fuel_added" && fuelDetail.calculate_by_density == 'Y'
                            ? <Ax>
                                <div className="tab_content_wrapper mt-2">
                                    <span className="content_heading"> Calculate Fuel Quantity based on Density</span>
                                </div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "25%" }}>Tanker Loaded Weight</td>
                                            <th>{fuelDetail.tanker_loaded_weight ? fuelDetail.tanker_loaded_weight : "-"} Kg</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Tanker Empty Weight</td>
                                            <th>{fuelDetail.tanker_empty_weight ? fuelDetail.tanker_empty_weight : "-"} Kg</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Fuel Weight</td>
                                            <th>{fuelDetail.fuel_weight ? fuelDetail.fuel_weight : "-"} Kg</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Fuel Density</td>
                                            <th>{fuelDetail.fuel_density ? fuelDetail.fuel_density : "-"} (kg/m3)</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "25%" }}>Fuel Qty according to Density & Weight</td>
                                            <th>{fuel_density_qty ? parseFloat(fuel_density_qty).toFixed(3) : "0"} </th>
                                        </tr>
                                    </tbody>
                                    </table>
                            </Ax>
                            : null}
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Transaction Detail </span>
                        </div>
                        <table className="table mt-2 table-hover table-bordered  table-responsive">
                            <tbody>
                                <tr>
                                    <td style={{ width: "25%" }}>Transaction ID</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.transaction_id ? fuelDetail.transaction_details.transaction_id : ""}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Transaction Date</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.date_of_transaction ? fuelDetail.transaction_details.date_of_transaction : ""}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Entry Created By</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.created_by && fuelDetail.transaction_details.created_by}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Created On</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.created_on_display && fuelDetail.transaction_details.created_on_display}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Last Updated By</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.last_updated_by && fuelDetail.transaction_details.last_updated_by}</th>
                                </tr>
                                <tr>
                                    <td style={{ width: "25%" }}>Last Updated On</td>
                                    <th>{fuelDetail.transaction_details && fuelDetail.transaction_details.last_updated_on_display && fuelDetail.transaction_details.last_updated_on_display}</th>
                                </tr>
                            </tbody>
                        </table>
                    </Ax>
                    : null}
        </Ax>)
    }
   
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Fuel Station Ledger - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.reportFilterFormJsx()}
                    {this.state.formDataLoaded
                            ? (<Ax>
                                  
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.reportDownloadBtn == true
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingOnPageClick(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
          {this.viewTransactionJsx()}
          <FuelIssued ref={this.logFuelIssuedRef} afterSubmitIssuedFuel={() => this.loadListingTblData(1)}/>
          <FuelReceive ref={this.logFuelReceiveRef} afterSubmitReceiveFuel={() => this.loadListingTblData(1)}/>
          <FuelTransfer ref={this.logFuelTransferRef} afterSubmitFuelTransferHandler={() => this.loadListingTblData(1)}/>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
        fuel_stations           :   state.app && state.app.fuel_stations ? state.app.fuel_stations : [],
         permissions             :   state.app.acl_info.permissions,
         report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(FuelStationLedger);