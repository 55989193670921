import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Helmet } from 'react-helmet';

class DateWiseFuelFeedIn extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportUrl                      =   IAM_API_BASE_URL_2 + '/report/date_wise_fuelfeedin';
        this.formDataUrl                    =   IAM_API_BASE_URL_2 + '/report/date_wise_fuelfeedin/form_data';
        this.fuelFeedInSummaryUrl           =   IAM_API_BASE_URL_2 + '/report/fuelfeedin_summary'

        this.reportFilterFormInitialState   =   {
            site_group_ids                  :       [],
            search_site_ids                 :       [],
            search_fuel_station_ids        :       [],
            search_fuel_type                :       '',
            'asset_group_ids'               :    [],
            search_asset_type_ids           :       [],
            search_manufacturer_ids         :       [],
            search_models                   :       [],
            search_asset_ids                :       [],
            date_range                      :       '',
            date_range_start                :       '',
            date_range_end                  :       '',
        };
        
        this.reportFilterFormLabel          =   {
            site_group_ids                  :       'Site Groups : ',
            search_site_ids                 :       'Transaction Sites : ', 
            search_fuel_station_ids        :       'Fuel Station Name : ',
            search_fuel_type                :       'Fuel Type : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            search_asset_type_ids           :       'Asset Type : ',
            search_manufacturer_ids         :       'Manufacturer : ',
            search_models                   :       'Models : ',
            search_asset_ids                :        'Assets : ',
            date_range                      :       'Date Range : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedAssets                      :   [],
            listingData                         :   [],
            listingMeta                         :   null, 
            iam_user_sites                      :   []  ,
            allfuelFeedInList                  :   [] ,
            iam_asset_types                     :   [],
            iam_manufactures                    :   [],
            iam_models                          :   [],
            allfuelFeedInTypes                 :   [],
            allSiteGroupsList                   :   [],
            allFuelTypes                        :   [],
            summaryData                         :   null,
            summary_loading                     :   false,
            allAssetTypesGroupsList         :   [],
            reportData                          :   null,                  
        };

    }
    
    componentDidMount(){
        this.initilaizeAppDataToFilterForm(this.props);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (pr)  =>  {
       
        const report_name   =   'date_wise_fuelfeedin';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })
        
       
        HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
                this.setState({
                    access_token                :   pr.access_token,
                    formDataLoaded              :   true,
                    allfuelFeedInTypes         :   response.data && response.data.station_types && response.data.station_types.length > 0 ? response.data.station_types.map((s) => { return { value: s.key, label: s.name }; }) : [],
                    allFuelTypes                :   response.data && response.data.fuel_types && response.data.fuel_types.length > 0 ? response.data.fuel_types.map((s) => { return { value: s.id, label: s.fuel_type }; }) : [],
                    allSiteGroupsList           :   response.data && response.data.site_groups && response.data.site_groups.length > 0 ? response.data.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    all_periods                 :   response.data && response.data.periods && response.data.periods.length > 0 ? response.data.periods : [],
                    iam_asset_types             :   pr.iam_asset_types ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                    iam_manufactures            :   pr.iam_manufactures ? pr.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
                    iam_models                  :   pr.iam_models ? pr.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
                    allfuelFeedInList          :   pr.fuel_stations ? pr.fuel_stations.map((s) => { return {value: s.id, label: s.fuelstation_name}}) : [],
                    iam_user_sites              :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                    allAssetTypesGroupsList :   pr.asset_groups && pr.asset_groups.length > 0 ? pr.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                }, () => {
                   
                        this.reportFilterhandler()
                   
                });
            });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
       
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
       
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                   
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                 
                    if(key == 'search_fuel_station_ids') {
                        show_val            =  this.state.allfuelFeedInList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_fuel_type') {
                        show_val            =  this.state.allFuelTypes.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    // if(key == 'search_fuel_station_type') {
                    //     show_val            =  this.state.allfuelFeedInTypes.filter((s) => value == s.value).map(s => s.label).join(', ');
                    // }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false,
            summaryData                 :   null,
            summary_loading             :   true,
        });
        let params                       =   {page:page ,...this.state.submittedReportFilterForm};   

        //Api to call Summary 
        HttpAPICall.withAthorization('GET', this.fuelFeedInSummaryUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                summaryData                 :   respData,
                reportDownloadBtn           :   true,
            });
        }).then(() => {
            this.setState({summary_loading: false});
        });

         //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;

            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
           
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    loadListingOnPageClick                  =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false,
        });

        let params                       =   {page:page ,...this.state.submittedReportFilterForm};   
         //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;

            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
           
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">

                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({
                                    searchedAssets: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                {/* <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.reportFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div> */}


                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Fuel Station Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allfuelFeedInList}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_fuel_station_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allfuelFeedInList.filter(s => this.state.reportFilterForm.search_fuel_station_ids.includes(s.value))}
                            placeholder="Select Fuel Station"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end"> Transaction Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Fuel Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allFuelTypes}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_fuel_type');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.allFuelTypes.find(s => this.state.reportFilterForm.search_fuel_type == s.value)}
                            placeholder="Select Fuel Type"
                            menuPlacement="top"
                        />

                    </div>
                </div>
                {/* <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Fuel Station Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allfuelFeedInTypes}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_fuel_station_type');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.allfuelFeedInTypes.find(s => this.state.reportFilterForm.search_fuel_station_type == s.value)}
                            placeholder="Select Fuel Station Type"
                            menuPlacement="top"
                        />

                    </div>
                </div> */}

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Date Range</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            required={true}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range: period,
                                        date_range_start: startDate ? startDate : null,
                                        date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
     
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            {this.state.summaryData && this.state.summaryData.data && this.state.summaryData.data.length > 0
                ?
                <div className='row p-3 shadow-lg'>
                    {this.state.summaryData.data.map((s, index) => {
                        return (
                            <Ax>
                                {index == 0 ? <div className='col-2 fw-bold'>
                                    Summary
                                </div> : null}
                                <div className='col-2'>
                                    Total {s.fuel_type} :  <span className='fw-bold'>{s.fuel_qty} {s.fuel_unit}</span>
                                </div>
                            </Ax>
                        )
                    })}

                </div>
                : null}
            <table className="table table-bordered table-fixed table-sm align-middle bg-white my-2">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                        <th scope="col" style={{ width: "20%" }}>Asset</th>
                        <th scope="col" style={{ width: "17%" }}>Asset Code</th>
                        <th scope="col" style={{ width: "20%" }}>Asset Type</th>
                        <th scope="col" style={{ width: "18%" }}>Current Site</th>
                        <th scope="col" style={{ width: "10%" }} className='text-center'>Fuel Type</th>
                        <th scope="col" style={{ width: "10%" }} className='text-center'>Total Fuel Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {
                            return (<Ax>
                                <tr>
                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td>{data.name ? data.name : "-"}</td>
                                    <td>{data.asset_code ? data.asset_code : "-"}</td>
                                    <td>{data.asset_type_name ? data.asset_type_name : "-"}</td>
                                    <td>{data.site ? data.site : "-"}</td>
                                    <td className='text-center'>{data.fuel_type && data.fuel_feed_in == "yes" ? data.fuel_type : "-"}</td>
                                    <td className='text-center'>{data.fuel_qty && data.fuel_feed_in == "yes" ? data.fuel_qty + ' ' + data.fuel_unit : "-"}</td>
                                </tr>
                            </Ax>)
                        }))
                        : <tr><td className="text-center" colSpan={7}>No Record Found</td></tr>
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Report - Date wise FuelFeed In</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                 <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.reportSearchClear} /> 
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                            : <Loader />
                        }

                        {this.state.reportDownloadBtn == true
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        fuel_stations           :   state.app && state.app.fuel_stations ? state.app.fuel_stations : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []    
    };
};

export default connect(mapStateToProps)(DateWiseFuelFeedIn);