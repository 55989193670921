import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import AssetCard from '../../includes/assetCard/AssetCard';
import Status from '../../../components/ui/Status';
import TicketDetail from './TicketDetail';
import tapIcon from "../../../services/TapIcon";
import TicketTransactionList from './TicketTransactionList';
import download from '../../../services/DownloadFile';


class TicketDetailModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            access_token                :   '',
            view_loading                :   false,
            ticket_view_data            :   null,
            ticketHeader                :   '',
            assetId                     :   '',
            card_loading                :   false,
            asset_card_details          :   null,
            ticketId                    :   '',
            ticketDownloading           :   false
        }
        this.ticketBoardRef         =   React.createRef();
        this.ticketDownloadUrl      =   IAM_API_BASE_URL_2 + '/ticket/download';
        this.ticketDetailUrl        =   IAM_API_BASE_URL_2 + '/ticket/detail';
    }
    
    componentDidMount               =   ()  =>  {
        this.ticketModalDetail            =   new Modal(document.getElementById('ticketModalDetail'), {keyboard: false, backdrop :false});
        // this.initScreen(this.props);
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                      =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }
    
    ticketModalInit               =   (ticket_id)  =>  {
        this.ticketModalDetail.show();
       this.getTicketViewData(ticket_id)
    }

    getTicketViewData                   =      (id)            =>        {
        this.setState({view_loading : true})
        let params = {
            need_total_expense: "Y",
            need_ticket_allocation: "Y",
            need_ticket_consumption: "Y"
        };
        if(this.props.projectId) {
            params.need_parent_ticket       =   "Y";
            params.need_child_tickets       =   "Y";
        }
        HttpAPICall.withAthorization('GET', this.ticketDetailUrl + '/' + id, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                ticket_view_data  : respData.data,
                ticketHeader      : respData.data.header,
                assetId           : respData.data && respData.data.asset ? respData.data.asset.asset_id : null
            });
            // if(respData.data.asset){
            //     this.getAssetCard(respData.data.asset.asset_id)
            // }
        }).then(() => this.setState({view_loading: false}));
    }

    getAssetCard                        =      (id)            =>       {
        let Id = id ? id : this.state.assetId;
        this.setState({card_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/asset/' + Id, this.props.access_token, {}, {}, (resp) => {
            this.setState({asset_card_details : resp.data.data})
        }).then(() => this.setState({card_loading: false}));
    }
    
    updateTicketModal = (id, detail) => {
        this.ticketBoardRef.current.updateTicketModalInit(id,detail);
    }

    downloadTicketHandler = (activity_id) => {
        this.setState({ ticketDownloading: true });
        HttpAPICall.withAthorization('GET', this.ticketDownloadUrl + '/' + activity_id, this.props.access_token, {}, {}, (response) => {
            download.file(response.data.file_path);
        }).then(() => this.setState({ ticketDownloading: false }));
    }

    render                              =   ()  =>  {
        let ticketData = this.state.ticket_view_data;
        return (<Ax>
            <div className="modal fade" id="ticketModalDetail" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div style={{backgroundColor : "#efeded"}}>
                            <div className='row m-2'>
                                <div className='col-md-7'>
                                    <h5 id="searchAssetModalLabel">Ticket Detail</h5>
                                </div>
                                <div className='col-md-5 text-end'>
                                    {/* <button type="button" id='shareTicket' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary mx-1">
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faShareAlt} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="shareTicket">
                                        <li><a className="dropdown-item" role="button">Share by Email</a></li>
                                    </ul> */}
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary mx-1" disabled={this.state.view_loading}>
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end " aria-labelledby="dropdownMenuButton1">
                                        <li><a className={[ticketData?.can_edit == "N" ? "disabled dropdown-item" : "dropdown-item"].join(', ')} role="button"
                                            onClick={() => this.updateTicketModal(ticketData?.activity_id,'detail')}>Edit Detail</a></li>
                                            <li><a className={[ticketData?.can_edit == "N" ? "disabled dropdown-item" : "dropdown-item"].join(', ')} role="button"
                                            onClick={() => this.updateTicketModal(ticketData?.activity_id,'tagging_detail')}>Edit Tagging Detail</a></li>
                                            <li><a className={[ticketData?.can_edit == "N" ? "disabled dropdown-item" : "dropdown-item"].join(', ')} role="button"
                                            onClick={() => this.updateTicketModal(ticketData?.activity_id,'other_detail')}>Edit Other Detail</a></li>
                                    </ul>
                                    <button onClick={() => this.downloadTicketHandler(ticketData?.activity_id)} disabled={this.state.ticketDownloading || this.state.view_loading} type="button" className="btn btn-secondary mx-1">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />{this.state.ticketDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                    <button type="button" className="btn btn-secondary mx-1" data-bs-dismiss="modal" aria-label="Close" disabled={this.state.view_loading}><tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body p-0">
                            <div className='px-3 pt-2'>
                                {this.state.view_loading ? <Loader/> : 
                            <TicketDetail ref={this.ticketBoardRef}  performAction='N' showOtherDetail='N'  ticketData = {ticketData}/>}
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </Ax>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(TicketDetailModal);