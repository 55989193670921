import React from 'react';
import { Helmet } from 'react-helmet';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import "../MasterData.css";
import AllocationConfiguration from './allocationConfiguration/AllocationConfiguration';
import ConsumptionConfiguration from './consumptionConfiguration/ConsumptionConfiguration';
import InventoryGeneralSettings from './InventoryGeneralSettings';
import ItemConfiguration from './itemConfiguration/ItemConfiguration';
import ManufacturerConfiguration from './ManufacturerConfiguration';
import PurchaseConfiguration from './purchaseConfiguration/PurchaseConfiguration';
import POConfiguration from './purchaseOrderConfiguration/POConfiguration';
import RequisitionConfiguration from './requisitionConfiguration/RequisitionConfiguration';
import TaxConfiguration from './TaxConfiguration';
import UOMConfiguration from './UOMConfiguration';
import WarehouseConfiguration from './WarehouseConfiguration';
import WarehouseIndent from './warehouseIndent/WarehouseIndent';
import AdjustmentConfiguration from './inventoryAdjustment/AdjustmentConfiguration';
import ItemWarehouseConfigList from './itemWhConfiguration/ItemWarehouseConfigList';
import TapIcon from '../../../services/TapIcon';
import LogisticConfiguration from './logisticConfiguration/LogisticConfiguration';
import Workflow from './purchaseReturnConfiguration/Workflow';
import ItemDemandConfiguration from './itemDemandConfiguration/ItemDemandConfiguration';

class ConfigurationInventory extends React.Component {

    constructor(props) {
        super(props);
        this.state                  =   {
            configScreen                :   "warehouse",
            add                         :   false,
            itemWhConfigDownloading     :   false,
        }
        this.handleClose            =   this.handleClose.bind(this);
        this.itemWhBulkRef          =   React.createRef();
    }

    handleAdd        =      ()     =>   {
        this.setState({add:true})
    }

    handleClose     =      ()     =>    {
        this.setState({add : false})
    }

    itemWhBulkUploadModal   =   (type)  =>  {
        this.itemWhBulkRef.current.itemWhBulkUploadInit(type);
    }

    pageTitleBarJsx                    =    (configTitle)   =>          {
        let title =  ""
        if(configTitle === "item"){
           title = "Item"
        }else if(configTitle === "warehouse"){
            title = "Additional Attribute - Warehouse"
        }else if(configTitle === "manufacturer"){
            title = "Manufacturer"
        }else if(configTitle === "tax_rates"){
            title = "Tax Rates"
        }else if(configTitle === "uom"){
            title = "Unit of Measurement"
        }else if(configTitle === "inventory"){
            title = "Inventory"
        }else if(configTitle === "requisition"){
            title = "Purchase Requisition"
        }else if(configTitle === "purchase_order"){
            title = "Purchase Order"
        }else if(configTitle === "warehouse_indent"){
            title = "Warehouse Indent"
        }else if(configTitle === "purchase"){
            title = "Purchase"
        }else if(configTitle === "allocation"){
            title = "Allocation"
        }else if(configTitle === "consumption"){
            title = "Consumption"
        }else if(configTitle === "inventory_adjustment"){
            title = "Inventory Adjustment"
        }else if(configTitle === "item_configuration"){
            title = "Item - Warehouse Configuration"
        }else if(configTitle === "logistic_configuration"){
            title = "Logistic";
        }else if(configTitle === "purchase_return"){
            title = "Purchase Return"
        }else if(configTitle === "item_demand"){
            title = "Item Demand"
        }else{
            title = "Item"
        }

        return (<Ax><div className="page_title row m0">
            <div className="col-3">
                <h3>Configuration</h3>
            </div>
            <div className={`${(configTitle === "item_configuration") ? 'col-5' : 'col-9'} tap_border_left`}>
                <h3>{title}</h3>
                {configTitle == "warehouse" || configTitle == "manufacturer" || configTitle == "uom"
                   
                    ? <div className="text-end mt15">
                        <button
                            className="btn btn-primary" 
                            disabled={
                                (configTitle == 'manufacturer' && !this.props.permissions.includes('isc-item-manufacturer-add')) ||
                                (configTitle == 'uom' && !this.props.permissions.includes('isc-item-uom-add')) ||
                                (configTitle == 'warehouse' && !this.props.permissions.includes('isc-configuration-edit'))
                            } 
                            onClick={() => this.handleAdd()}>
                            Add
                        </button>
                    </div>
                    : null
                }
            </div>
            {configTitle === "item_configuration" && 
            <div className="col-4 mt-3 text-end">
                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                    <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                    {this.state.itemWhConfigDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <Link 
                        className={['dropdown-item cursor_pointer']}
                        onClick={() => this.itemWhBulkUploadModal("upload")}
                        >
                        Bulk Upload
                        </Link>
                    </li>
                    <li>
                        <Link 
                        className={['dropdown-item cursor_pointer']}
                        onClick={() =>  this.itemWhBulkUploadModal("download")}
                        >
                        Download
                        </Link>
                    </li>
                </ul>
                <button 
                type="button" 
                className="btn btn-secondary"
                onClick={() => this.itemWhBulkUploadModal("filter")}
                >
                    <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                </button>
            </div>
            }
        </div>
        </Ax>)
    }

    loadConfigContentJsx                   =   (configName)  =>           {
        switch (configName) {
            case 'item':
                return <ItemConfiguration/>;
            case 'warehouse':
                return <WarehouseConfiguration openForm={this.state.add} closeForm={this.handleClose}/>;
            case 'manufacturer':
                 return <ManufacturerConfiguration openForm={this.state.add} closeForm={this.handleClose}/>;
            case 'tax_rates':
                return <TaxConfiguration openForm={this.state.add} closeForm={this.handleClose}/>;
            case 'uom':
                return <UOMConfiguration openForm={this.state.add} closeForm={this.handleClose}/>;
            case 'inventory':
                return <InventoryGeneralSettings/>;
            case 'requisition':
                return <RequisitionConfiguration/>;
            case 'purchase_order':
                return <POConfiguration/>;
            case 'warehouse_indent':
                return <WarehouseIndent/>;
            case 'purchase':
                return <PurchaseConfiguration/>;
            case 'allocation':
                return <AllocationConfiguration/>;
            case 'consumption':
                return <ConsumptionConfiguration/>;
            case 'inventory_adjustment':
                return <AdjustmentConfiguration/>;
            case 'purchase_return':
                return <Workflow/>;   
            case 'item_configuration':
                return <ItemWarehouseConfigList ref={this.itemWhBulkRef} afterDownloading={(data) => this.itemWhDownloading(data)}/>;
            case 'logistic_configuration':
                return <LogisticConfiguration/>;
                case 'item_demand':
                    return <ItemDemandConfiguration/>;
            default:
                return <ItemConfiguration/>;
        }
    }

    itemWhDownloading   =   (data)  => {
        this.setState({
            itemWhConfigDownloading : data
        });
    }

    //function to get configure table
    configureListTable                     =   (configRow)   =>  {
        return (<div style={{height:"81vh"}} className="bg-white">
            <table className="table table-hover table-bordered bg-white" >
                <tbody className="table-body">
                     <tr className={configRow == "warehouse" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/warehouse`} role={"button"} className="link">Additional Attribute - Warehouse</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "allocation" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/allocation`} role={"button"} className="link">Allocation</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "consumption" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/consumption`} role={"button"} className="link">Consumption</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "inventory" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/inventory`} role={"button"} className="link">Inventory</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "inventory_adjustment" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/inventory_adjustment`} role={"button"} className="link">Inventory Adjustment</Link>
                        </td>
                    </tr>
                    {this.props.permissions.includes('isc_item_demand_manage_status') &&  <tr className={configRow == "item_demand" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/item_demand`} role={"button"} className="link">Item Demand</Link>
                        </td>
                    </tr>}
                    <tr className={configRow == "item" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/item`} role={"button"} className="link">Item</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "item_configuration" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/item_configuration`} role={"button"} className="link">Item Configuration</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "item_demand" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/item_demand`} role={"button"} className="link">Item Demand</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "logistic_configuration" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/logistic_configuration`} role={"button"} className="link">Logistic Configuration</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "manufacturer" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/manufacturer`} role={"button"} className="link">Manufacturer</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "purchase" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/purchase`} role={"button"} className="link">Purchase</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "purchase_order" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/purchase_order`} role={"button"} className="link">Purchase Order</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "purchase_return" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/purchase_return`} role={"button"} className="link">Purchase Return</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "requisition" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/requisition`} role={"button"} className="link">Purchase Requisition</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "tax_rates" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/tax_rates`} role={"button"} className="link">Tax Rates</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "uom" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/uom`} role={"button"} className="link">Unit of Measurement</Link>
                        </td>
                    </tr>
                    <tr className={configRow == "uom" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/inventory_configuration/warehouse_indent`} role={"button"} className="link">Warehouse Indent</Link>
                        </td>
                    </tr>                   
                </tbody>
            </table>
        </div>)
    }


    render() {
        return <ApplicationLayout>
              <Helmet><title>Inventory Configuration</title></Helmet>
            {this.pageTitleBarJsx(this.props.match.params.config)}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl col-3'].join(' ')} >{this.configureListTable(this.props.match.params.config)}</div>
                    <div className="col-9 pl0 pr4">{this.loadConfigContentJsx(this.props.match.params.config)}</div>
            </div>
            </div>
        </ApplicationLayout>
    }
}

const mapStateToProps = state => {
    return {
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(ConfigurationInventory);