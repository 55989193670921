import { toast } from 'react-toastify';

//import Login from '../pages/auth/Login';
//import Login from '../pages/auth/Login';
//import LogOut from '../pages/auth/LogOut';

const apiErrorHandle = (err, defaultMsg = 'Unbale to Load', location_logout = true) => {
    if(err.response && err.response.status == 401) {
        toast.error('Login Session expired, Please login again.', {position: toast.POSITION.TOP_RIGHT});
        if(location_logout) {
            setTimeout(() => {
                window.location.href = "/logout";
            }, 3000);
        }
    } else {
        toast.error(err?.response?.data?.message ?? defaultMsg, {position: toast.POSITION.TOP_RIGHT});
    }
};


export default {
    apiErrorHandle
};

