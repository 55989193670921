import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import General from './General';
import MaterialList from './MaterialList';



class MaterialConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <section className="page_containt " style={{top:"1px"}}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general"
                                    type="button" role="tab" aria-controls="general" aria-selected="true">General Settings
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="material-tab" data-bs-toggle="tab" data-bs-target="#material"
                                    type="button" role="tab" aria-controls="material" aria-selected="true">Material
                            </button>
                        </li>
                        
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane  show active" id="general" role="tabpanel"
                             aria-labelledby="general-tab">
                            <div className="tab_content_header">
                             <General/>
                            </div>
                        </div>
                        <div className="tab-pane" id="material" role="tabpanel"
                             aria-labelledby="material-tab">
                            <div className="tab_content_header">
                             <MaterialList/>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(MaterialConfiguration);