import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon"
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import TapIcon from '../../../services/TapIcon';
import TapApiUrls from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import swal from 'sweetalert';
import * as actions from '../../../store/actions/index';

class SiteGrouping extends AppBaseComponent {

    constructor(props) {
        super(props);
          
        this.initAddForm         =               {
            name                :               "",
            site_ids            :               []
        }
          this.state                =          {
            list_loading            :         false,
            listingData             :         null,
            saveFormSubmitting      :         false,
            edit                    :          false,
            addSiteGroupForm        :         {...this.initAddForm},
            iam_user_sites          :         []
        };
    }

    componentDidMount() {
        this.getSiteListing()
        this.initalizeData(this.props)
        this.siteGroupAddModal         =    new Modal(document.getElementById('siteGroupAddModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(this.props.iam_user_sites !== this.state.iam_user_sites){
           this.initalizeData(nextProps)    
        }        
    }

    initalizeData       =       (pr)        =>  {
        this.setState({iam_user_sites : pr.iam_user_sites && pr.iam_user_sites.length > 0 ? pr.iam_user_sites.map(s => {return({value : s.id , label : `${s.site_name} (${s.site_code})`})}) : []})
    }

    getSiteListing             =         ()    =>  {
        this.setState({list_loading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/site/group', this.props.access_token , {}, {}, (resp) => {
            this.setState({listingData  : resp.data.data})
        }).then(() => this.setState({list_loading: false}));
    }

    siteModalInit       =       (id,edit = false)      =>      {
        this.siteGroupAddModal.show()
        if (id && edit == true) {
            let siteData = this.state.listingData && this.state.listingData.length > 0 ? this.state.listingData.find(i => i.id == id) : null;

            if (siteData) {
              
                this.setState({
                    addSiteGroupForm: {
                        id          :   id,
                        name        :   siteData.name,
                        site_ids    :   siteData.sites && siteData.sites.length > 0 ? siteData.sites.map(s => {return(s.id)}) : [],
                    },
                    edit   : true
                })
            }

        }else{
            this.setState({
                edit : false,
                addSiteGroupForm : {
                name : '',
                site_ids : []
            }})
        }
    }

    submitAddGroupForm      =       (e)      =>      {
        e.preventDefault()
        this.setState({saveFormSubmitting : true})
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/site/group' ,
            this.props.access_token, null, {...this.state.addSiteGroupForm}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.siteGroupAddModal.hide();
                this.getSiteListing()
                this.setState({addSiteGroupForm : {...this.initAddForm}})
           }).then(() => this.setState({saveFormSubmitting: false}))
        }else{
            HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/site/group' ,
            this.props.access_token, null, {...this.state.addSiteGroupForm}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.siteGroupAddModal.hide();
                this.getSiteListing()
                this.setState({addSiteGroupForm : {...this.initAddForm}})
          }).then(() => this.setState({saveFormSubmitting: false}))
        }
       
    }

    //***********************DELETE  **************************** */
    deleteSiteGroup              =           (id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {

                    HttpAPICall.withAthorization('DELETE', TapApiUrls.IAM + '/site/group/' + id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getSiteListing()
                    }).then(() => {

                    });
                }
            });
    }

    siteGroupAddModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="siteGroupAddModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">{this.state.edit ? "Edit" : "Add"} Site Group</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="organizationUpdateForm" onSubmit={this.submitAddGroupForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label " >Group Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addSiteGroupForm.name}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addSiteGroupForm")}
                                            placeholder="Please enter Group Name"

                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label " >Sites</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addSiteGroupForm', 'site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_user_sites.filter(s => this.state.addSiteGroupForm.site_ids.includes(s.value))}
                                            placeholder="Select Sites"

                                        />
                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        return (
            <section className="container-fluid" style={{ padding: "0px 12px" }}>
                <div className=" row bg-white p-2">
                    <div className="col-sm-12">
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Site Grouping</span>
                            <div className="text-end "><button className="btn btn-primary" onClick={this.siteModalInit}>Add</button></div>
                        </div>
                       <div className="page_containt">
                       <div className='pageTbl' style={{height : "90%"}}>
                        <table className="table table-hover table-bordered my-4 table-sm ">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "10%" }}>S.No</th>
                                    <th scope="col"  className="text-start" style={{ width: "20%" }}>Group Name</th>
                                    <th scope="col" className="text-start">Sites Name</th>
                                    <th scope="col" style={{ width: "10%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.list_loading ? (<tr><td colSpan="3"><Loader /></td></tr>)
                                    : (this.state.listingData && this.state.listingData.length > 0
                                        ? (this.state.listingData.map((sg, k) => {
                                            return (<tr key={k} className="text-center">
                                                <td>{k + 1}</td>
                                                <td className="text-start">{sg.name ? sg.name : "-"}</td>
                                                <td className="text-start">{sg.sites && sg.sites.length > 0 ? <ol className='horizontal pl16'>{sg.sites.map((sn, k) => { return (<li>{sn.site_name.slice(0,20)}{sn.site_name.length > 20 ? '...' : ''}</li>) })}</ol> : "-"}</td>
                                                <td> <span className="dropdown">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.siteModalInit(sg.id,true)}>Edit</a></li>
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.deleteSiteGroup(sg.id)}>Delete</a></li>
                                                    </ul>
                                                </span></td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="3" className="text-center">No Records</td></tr>))}
                            </tbody>
                        </table>
                        </div>
                       </div>
                        
                    </div>
                </div>
              {this.siteGroupAddModalJsx()}
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        iam_user_sites          :        state.app && state.app.user_sites ? state.app.user_sites : []
    };
};


export default connect(mapStateToProps)(SiteGrouping);