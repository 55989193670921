import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';



const EmpAttendanceDetailJsx     = forwardRef((props, ref) => {


    useEffect(() => {
        console.log(props.attendanceData);
    }, []);


    const closeSummaryHandler           =   ()  =>  {
        if(props.onClose) {
            props.onClose();
        }
    }

    const headerJsx                     =   ()  =>  {
        return <>
            <Helmet><title>Employee Attendance Summary</title></Helmet>
            <div className=" page_title m0 row ">
                <div className='col-sm-12'>
                    <div className='row '>
                        <div className='col-sm-4'>
                            <h3 className='mb-0'>Employee Attendance : {props.attendanceData ? props.attendanceData.date : ''}</h3>
                        </div>
                        <div className='col-sm-8'>
                            <div className='text-end d-flex gap-2 align-items-center justify-content-end mt15'>
                                <button type="button" className="btn btn-secondary ms0" onClick={closeSummaryHandler} >
                                    <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="cursor_pointer" color="white" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


 
    return <>
        {headerJsx()}
        <div>
            Vinay Sachan
        </div>
    </>
});

export default EmpAttendanceDetailJsx;