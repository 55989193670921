import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from "../../../components/TapUi";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import HttpAPICall from "../../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import TapIcon from "../../../services/TapIcon";
import DownloadFile from "../../../services/DownloadFile";
import { Collapse } from "bootstrap";
import moment from "moment";
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import InputItemSearch from "../../includes/ui/InputItemSearch";

class AssetWiseItemTrackingReport extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.reportFilterFormInitState  =   {
            search_asset_type_ids           :   [],
            search_asset_matrix_ids         :   [],
            search_iam_manufacturer_ids     :   [],
            search_asset_ids                :   [],
            search_owner_id                 :   '',
            search_ownership_category       :   '',
            site_group_ids                  :   [],
            search_site_ids                 :   [],
            search_item_ids                 :   [],
            search_item_tags                :   [],
            search_warehouse_ids            :   [],
            search_models                   :   [],
            date_range                      :   'current_month',
            date_range_start                :   '',
            date_range_end                  :   '',
            search_manufacturer_ids         :   [],
        }

        this.reportFilterFormLabel      =   {
            site_group_ids                  :   'Site Group',
            search_site_ids                 :   'Site',
            search_asset_ids                :   'Asset',
            search_asset_type_ids           :   'Asset Type',
            search_asset_matrix_ids         :   'Asset Matrix',
            search_iam_manufacturer_ids     :   'Asset Manufacturer',
            search_owner_id                 :   'Owner Name',
            search_ownership_category       :   'Ownership Category',
            search_item_ids                 :   'Items',
            search_item_tags                :   'Item Tags',
            search_warehouse_ids            :   'Warehouse',
            search_models                   :   'Asset Model',
            search_manufacturer_ids         :   'Item Manufacturer',
            date_range                      :   'Date Range',
        };

        this.state                      =   {
            formLoading                         :   false,
            reportDownloading                   :   false,
            data                                :   [],
            meta                                :   null,
            listLoading                         :   false,
            listingPage                         :   1,
            totalData                           :   0,
            viewLoading                         :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitState},
            formSearchedElems                   :   [],
            searchedAssets                      :   [],

            ownershipCategory                   :   [],
            ownersList                          :   [],
            allPeriods                          :   [],
            allSiteGroupsList                   :   [],
            userSites                           :   [],
            assetTypes                          :   [],
            assetMatrixs                        :   [],
            assetModels                         :   [],
            assetManufactures                   :   [],
            iscWarehouses                       :   [],
            itemTags                            :   [],
            iscManufactures                     :   [],
            searchedItems                       :   [],
            download_access                     :   false,
            reportData                          :   null
        };

        
        // reportData                          :   null,                  
        // const report_name   =   'item_closing_stock';
        // const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        // reportData                  :   reportData.length > 0 ? reportData[0] : null,
        // report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
         // className={['dropdown-item', this.state.reportData?.download_access === 'Y' ? '' : 'disabled'].join(' ')}

        this.reportUrl                      =   IAM_API_BASE_URL_2 + '/report/asset_wise_item_tracking';
    }

    componentDidMount() {
        this.setState({formLoading : true});
        setTimeout(() => this.initializeData(), 1000);
    }

    initializeData                  =   ()  =>  {

        const report_name   =   this.props?.location?.pathname ? this.props.location.pathname.split('/')[2] : '';
        const reportData    =   this.props?.report_listing.length > 0 ? this.props.report_listing.filter(d => d.key === report_name) : [];

        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {action:"formData"}, {}, (res) => {
            let {ownership_category, owners, isc_manufacturers} =   {...res.data};
            let {all_periods, site_groups, iam_user_sites, iam_asset_types, iam_asset_matrix, iam_models, iam_manufactures, iscWarehouses, item_tags} =   {...this.props};

            this.setState({
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                ownershipCategory       :   ownership_category && ownership_category.length > 0 
                                                ? ownership_category.map(oc => {return({value:oc.key,label : oc.name})}) 
                                                : [],
                ownersList              :   owners && owners.length > 0 ? owners.map(oc => {return({value:oc.id,label : oc.name})}) : [],
                iscManufactures         :   isc_manufacturers && isc_manufacturers.length > 0  
                                                ? isc_manufacturers.map((m) => { return {value: m.id, label: m.name}}) 
                                                : [],
                itemTags                :   item_tags && item_tags.length > 0 ? item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                allSiteGroupsList       :   site_groups && site_groups.length > 0 
                                                ? site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) 
                                                : [],
                allPeriods              :   all_periods,
                userSites               :   iam_user_sites 
                                                ? iam_user_sites.map((s) => { return {value: s.id, label: s.site_name + '('+s.site_code+')' }}) 
                                                : [],
                assetTypes              :   iam_asset_types ? iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                assetMatrixs            :   iam_asset_matrix ? iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
                assetModels             :   iam_models ? iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
                assetManufactures       :   iam_manufactures ? iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
                iscWarehouses           :   iscWarehouses 
                                                ? iscWarehouses.map((w) => { return {value: w.id, label: w.name + '('+w.code+')' }}) 
                                                : [],
                itemTags                :   item_tags && item_tags.length > 0 ? item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                reportFilterForm        :   {...this.state.reportFilterForm, date_range: 'trailing_twelve_months'}
            }, ()=>{
                this.setState({ formLoading : false },() => {
                    this.reportFilterFormSubmit();
                });
            });
        }, (err) => {});
    }

    reportFilterhandler             =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    reportFilterFormSubmit          =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);

        let serachFormKeys              =   Object.keys(this.state.reportFilterForm);
        let searchedElems               =   [];
        let itemSearchParams            =   {};
        serachFormKeys.map((key) => {
            let label                           =   this.reportFilterFormLabel[key];
            let value                           =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]           =   value;
                if(label) {
                    let show_val                =   value;
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.userSites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.assetTypes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.assetMatrixs.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_iam_manufacturer_ids') {
                        show_val            = this.state.assetManufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.assetModels.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_owner_id') {
                        show_val            = this.state.ownersList.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_ownership_category') {
                        show_val            = this.state.ownershipCategory.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItems ? this.state.searchedItems.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.itemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iscManufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.iscWarehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'date_range') {
                        let  start_range        =   moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range           =   moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                            let display_custom  =   `Custom Date Range (${start_range} - ${end_range})`
                            show_val            =   this.state.allPeriods.filter((s) => value.includes(s.key))
                                                        .map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b> : ${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }

    loadListingTblData              =   (page = 1)  =>  {
        this.setState({listLoading: true, listingPage: page});
        let params                      =   {page:page, ...this.state.submittedReportFilterForm, per_page : 25, action:"data"};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (resp) => {
            let respData                =   resp.data;
            this.setState({
                data                    :   respData.data,
                meta                    :   {
                    from                    :   respData.from,
                    to                      :   respData.to,
                    total                   :   respData.total,
                    last_page               :   respData.last_page,
                    per_page                :   respData.per_page,
                    current_page            :   respData.current_page
                }
            });
        }).then(() => this.setState({listLoading: false}));
    }

    reportDownloadHandler           =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, action : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    pageTitleBar                    =   ()  =>  {
        return <div className="page_title row m0">
            <div className="col-sm-12">
                <h3>{this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    <button type="button"  className="btn btn-secondary" onClick={this.reportFilterhandler}>Change Filter</button>
                    {this.state.meta &&
                        <button disabled={this.state.reportDownloading || !this.state.download_access} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary">
                            <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin"/>}
                        </button>
                    }
                    <button type="button" className="btn btn-secondary" onClick={() => this.props.history.push('/report')}>
                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>
    }

    reportFilterFormJsx             =   ()  =>  {
        return <div id="reportFilterForm" className="bg-white collapse" >
            <form onSubmit={this.reportFilterFormSubmit} className="p-3 filter_form">
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(so) => {
                                this.setState({
                                    reportFilterForm    :   {
                                        ...this.state.reportFilterForm,
                                        site_group_ids      :   so &&  so.length > 0 ? so.map(o => o.value) : [],
                                        search_site_ids     :   so && so.length > 0 ? so.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.userSites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.userSites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.assetTypes}
                            changeEvent={(so) => { this.tapSelectChange(so, 'reportFilterForm', 'search_asset_type_ids'); }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.assetTypes.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.assetMatrixs}
                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_matrix_ids'); }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.assetMatrixs.filter(s => this.state.reportFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.assetModels}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.assetModels.filter(s => this.state.reportFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.assetManufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_iam_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.assetManufactures.filter(s => this.state.reportFilterForm.search_iam_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Ownership</label>
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className='col-sm-5'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.ownershipCategory}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_ownership_category');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.ownershipCategory.find(r => r.value === this.state.reportFilterForm.search_ownership_category)}
                                    placeholder="Select Ownership Category"
                                />
                            </div>
                            <div className='col-sm-7'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.ownersList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_owner_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.ownersList.find(r => r.value === this.state.reportFilterForm.search_owner_id)}
                                    placeholder="Select Owner"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6 align-items-center">
                        <InputAssetSearch
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption ? selectedOption : null }, () => { });
                            }}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.itemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.itemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iscManufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iscManufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iscWarehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.iscWarehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                            menuPlacement="top"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                    <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItems: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItems && this.state.searchedItems.length > 0 ? this.state.searchedItems.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.allPeriods}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({ reportFilterForm : {
                                    ...this.state.reportFilterForm, 
                                    date_range: period, 
                                    date_range_start: startDate ? startDate : null, 
                                    date_range_end: endDate ? endDate : null 
                                } });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listLoading} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>
    }

    reportListJsx                   =   ()  =>  {
        return <Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th style={{ width: "16%" }}>Asset</th>
                        <th style={{ width: "12%" }}>Asset Code</th>
                        <th style={{ width: "12%" }}>Asset Type</th>
                        <th style={{ width: "18%" }}>Item Name</th>
                        <th style={{ width: "10%" }}>Item Code</th>
                        <th style={{ width: "6%" }}>UOM</th>
                        <th className="text-center" style={{ width: "6%" }}>PO Raised</th>
                        <th className="text-center" style={{ width: "8%" }}>Item Received again PO</th>
                        <th className="text-center" style={{ width: "6%" }}>Allocated</th>
                        <th className="text-center" style={{ width: "6%" }}>Consumed</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listLoading && <tr><td colSpan="8"><Loader /></td></tr> }
                    {!this.state.listLoading && this.state.data.length == 0 && <tr><td className="text-center" colSpan={8}>No Record Found</td></tr>}
                    {!this.state.listLoading && this.state.data.length > 0 && (this.state.data.map((asset,index) => {
                        return asset.items.map((item, ii) => {
                            return <tr className='' key={index}>
                                <td>{asset.name}</td>
                                <td>{asset.asset_code}</td>
                                <td>{asset.asset_type}</td>
                                <td>{item.item_name}</td>
                                <td>{item.item_code}</td>
                                <td>{item.uom}</td>
                                <th className="text-end">{item.po_raised}</th>
                                <th className="text-end">{item.purchased}</th>
                                <th className="text-end">{item.allocated_qty}</th>
                                <th className="text-end">{item.consume_qty}</th>
                            </tr>
                        })
                    }))}
                </tbody>
            </table>
            {this.state.meta && <DisplayListPagination keyName="Assets" meta={this.state.meta} onPageChange={e => this.loadListingTblData(e.selected + 1)} />}
        </Ax>
    }

    render                          =   ()  => {
        return <ApplicationLayout>
            <Helmet><title>{this.state.reportData?.name}</title></Helmet>
            {this.pageTitleBar()}
            <div className='container-fluid pl5'>
                <div className="page_containt row">
                    <div className="pageTbl col-sm-12">
                        {this.state.formLoading && <Loader/>}
                        {this.reportFilterFormJsx()}
                        <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                        {!this.state.formLoading && this.reportListJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>
    };

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_asset_variants      :   state.app && state.app.asset_variants ? state.app.asset_variants : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        iscWarehouses           :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetWiseItemTrackingReport);