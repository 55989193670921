import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import { Collapse, Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import React from 'react';
import PurchaseOrderDetailModal from '../../inventory/purchaseOrder/PurchaseOrderDetailModal';
import ReceivedQtyDetail from '../../inventory/includes/ReceivedQtyDetail';
import swal from "sweetalert";
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemWiseLeadTimeReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            tagged_user_filter                  :    '',
            search_transaction_id               :   '',
            search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_status_ids                   :   [],
            search_priority                     :   '',
            search_manufacturer_ids             :   [],
            search_vendor_ids                   :   [],
            date_range                          :   'current_quarter',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_workstation_ids              :   [],
            search_asset_ids                    :   [],
            search_employee_ids                 :   [],
            tagged_user_ids                     :   [],
        };
        
        this.reportFilterFormLabel          =   {
             tagged_user_filter                 :   'User Tagging : ',
            search_transaction_id               :   'PO Number : ',
            search_warehouse_ids                :   'Warehouse : ',
            search_workstation_ids              :   'WorkStation : ',
            search_asset_ids                    :   'Asset : ',
            search_item_ids                     :   'Items : ',
            search_item_tags                    :   'Item Tags : ',
            search_status_ids                   :   'Status : ',
            search_vendor_ids                   :   'Vendor : ',
            search_priority                     :   'Search Priority : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            date_range                          :   'Date Range : ',
            search_employee_ids                 :   'Employees : ',
            tagged_user_ids                   :     'Tagged Users : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            allItemTags                         :   [],
            all_periods                         :   [],
            searchedVendor                      :   [],
            allStatusOptions                    :   [],
            allPriorityOptions                  :   [],
            manufactures                        :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null, 
            searchedAssets                      :   [],
            searchedEmployees                   :   [], 
            workstations                        :   [],
            userFilters                         :   [
                {value  :   'tagged_me',        label : 'My Tagged PO'},
                {value  :   'created_by_me',    label : 'PO Created By Me'},
            ],
            allUsers                            :   []  ,
            selectedPOData                      :   null,
            download_access                     :   false,    
            reportData                          :   null                 
        };

        this.PODetailModalRef                 =   React.createRef();
        this.POReceivedQtyModalRef            =   React.createRef();

        this.reportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-po-lead-time';

    }

    componentDidMount(){
        setTimeout(() => {
            this.initializeData(this.props)
        },1500)
        this.viewTransactionModal              =     new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
    }

    initializeData     =      (pr)      =>      {
        const report_name   =   pr?.location?.pathname ? pr.location.pathname.split('/')[2] : '';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];

        if(pr){
            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            HttpAPICall.withAthorization('GET', this.reportUrl, pr.access_token, {action:"formData"}, null, (response) => {
                this.setState({
                    reportData                  :   reportData.length > 0 ? reportData[0] : null,
                    download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                    reportFilterFormData        :   {...response.data},
                    all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    formDataLoaded              :   true,
                    all_periods                 :   all_periods ,
                    manufactures                :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                    allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name,level : s.level}; }) : [],
                    allPriorityOptions          :   response.data && response.data.priorities && response.data.priorities.length > 0 ? response.data.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                    allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    workstations               :   response.data.workstations && response.data.workstations.length > 0 ?  response.data.workstations.map((w) => {return {value: w.id, label: `${w.workstation_name} (${w.workstation_nickname})`}}) : [],
                    allUsers                    :   response.data && response.data.users ? response.data.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                }, () => {
                    let status_ids = [];
                    let updateFilterForm = {};
                    let ParamObject = new URLSearchParams(pr.location.search);
                    if (ParamObject.get('search_status_ids') || ParamObject.get('search_item_ids') || ParamObject.get('search_vendor_ids') || ParamObject.get('tagged_user')) {
                        if (ParamObject.get('search_status_ids')) {
                            status_ids = ParamObject.get('search_status_ids').split(',').map(Number);

                            if (ParamObject.get('search_warehouse_ids')) {
                                let warehouse = this.state.all_warehouses.find(us => us.value == ParamObject.get('search_warehouse_ids'));
                                if (warehouse) {
                                    updateFilterForm['search_warehouse_ids'] = [warehouse.value];
                                }
                            }
                        }
                        if (ParamObject.get('search_item_ids')) {    
                            updateFilterForm['search_item_ids'] = [ParamObject.get('search_item_ids')];   
                        }
                        if (ParamObject.get('search_vendor_ids')) {    
                            updateFilterForm['search_vendor_ids']          =          [ParamObject.get('search_vendor_ids')]; 
                              
                        }
                        if (ParamObject.get('tagged_user')) {
                            updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                        }
                      
                    } else {
                        status_ids = this.state.allStatusOptions.filter(s => s.level <= 5).map(s => s.value)                  
                    } 
                    
                    setTimeout(() => {
                        this.setState({
                            reportFilterForm        :    { ...this.state.reportFilterForm, search_status_ids: status_ids,...updateFilterForm },
                            searchedVendor                 :       ParamObject.get('search_vendor_ids') ? [{value : ParamObject.get('search_vendor_ids'),display_label : ParamObject.get('vendor_name') }] : null,
                            searchedItem            :   ParamObject.get('search_item_ids') ? [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }] : []
                        }, () => {
                            this.reportFilterFormSubmit()
                        })
                    }, 1000)     
                   
                });
            }, (err) => {

            });
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'tagged_user_filter') {
                        show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_vendor_ids') {
                        show_val            =   this.state.searchedVendor ? this.state.searchedVendor.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_transaction_id : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_priority') {
                        show_val            =   this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_workstation_ids') {
                        show_val            =   this.state.workstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'tagged_user_ids') {
                        show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                            let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                            show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm,action:"data"};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   (action='download')  =>  {
        let formData                            =   {...this.state.submittedReportFilterForm};
        if(formData.search_transaction_id != '' || formData.date_range != '') {
            this.reportDownloadApiCall(formData,action);
        } else if((JSON.stringify(formData) === JSON.stringify(this.reportFilterFormInitialState)) || formData.date_range == '') {
            swal({ 
                title: "Download", icon: "warning", buttons: ["Cancel", "Okay"],
                text: "The download will be for Current Quarter, in case you want a different Period then apply the criteria before downloading.",
            }).then(willDownload => {
                if (willDownload) { this.reportDownloadApiCall({...formData, date_range: "current_quarter"},action); }
            });
        }
    }
    
    reportDownloadApiCall                   =   (formData,action)  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...formData, action : action} , {} , (response) => DownloadFile.file(null,response.data.navgation,response.data.msg,this.props)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    poDetailModalInit          =       (req_item_id)           =>      {
        this.PODetailModalRef.current.modalPOInit(req_item_id);  
    }

    poReceivedQtyModalInit          =       (po_item_id)           =>      {
        this.POReceivedQtyModalRef.current.receiviedQtyModalInit(null,po_item_id);  
    }

    viewMoreDetailHandle            =       (item)      =>      {
        this.setState({selectedPOData : item});
        this.viewTransactionModal.show()
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.state.reportDownloadBtn && <Ax> <button disabled={this.state.reportDownloading} type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                    </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={() => this.reportDownloadHandler('download')}>Download</Link></li>
                            <li><Link className="dropdown-item" onClick={() => this.reportDownloadHandler('download_with_purchase')}>PO - Purchase Analysis Report</Link></li>
                        </ul>
                    </Ax>}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
            <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Purchase Order</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.userFilters}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_filter')}
                            value={this.state.userFilters.find(u => u.value == this.state.reportFilterForm.tagged_user_filter)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All PO"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                    <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Tagged User</label>
                    <div className="col-sm-6">
                        <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Tagged User"
                            options={this.state.allUsers}
                            value={this.state.allUsers.filter(s => this.state.reportFilterForm.tagged_user_ids.includes(s.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_ids')}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.all_warehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Vendor</label>
                    <div className="col-sm-6">
                        <InputVendorSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_vendor_ids');
                                this.setState({ searchedVendor: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedVendor && this.state.searchedVendor?.length > 0 ? this.state.searchedVendor.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">PO Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Please enter Transaction ID"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Workstation</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_workstation_ids')}
                            options={this.state.workstations}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.workstations.filter(t => this.state.reportFilterForm.search_workstation_ids.includes(t.value))}
                            placeholder="Please Select Workstation"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Employees</label>
                    <div className="col-sm-6">
                        <InputEmployeeSearch
                            changeEvent={(selectedOption) => {
                                
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_employee_ids');
                                this.setState({
                                    reportFilterForm  : {...this.state.reportFilterForm,search_employee_ids : selectedOption.length > 0 ? selectedOption.map(o => o.value) : []},
                                    searchedEmployees : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Priority</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allPriorityOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_priority')}
                            value={this.state.allPriorityOptions.find(u => u.value == this.state.reportFilterForm.search_priority)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Priority"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range: period,
                                        date_range_start: startDate ? startDate : null,
                                        date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className='text-center' style={{ width: "9%" }}>PO Number</th>
                        <th className='text-center' style={{ width: "7%" }}>PO Date</th>
                        <th className="text-center" style={{ width: "16%" }}>Warehouse</th>
                        {/* <th className="text-center" style={{ width: "16%" }}>Vendor</th> */}
                        <th className="text-center" style={{ width: "18%" }}>Item Name</th>
                        <th className='text-center' scope="col" style={{ width: "8%" }}>Status</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }}  >PO Qty</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Received</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Cancelled</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Pending</th>
                        <th className='text-end' scope="col"  style={{ width: "5%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((rowData, k) => {
                            
                            return (<tr key={k}>
                               
                                <td onClick={() => this.poDetailModalInit(rowData.transaction_id)}><a hred="#" role="button" className='link-primary'>{rowData.transaction_id}</a></td>
                                <td>{rowData.transaction_date}</td>
                                <td>{rowData.warehouse_name}</td>
                                {/* <td className='text-capitalize'>{rowData.vendor_name}</td> */}
                                <td>{rowData.item_name} <br />
                                    <small className='form-text'><b>Code : </b> {rowData.item_code}</small></td>
                                <td className='text-center'>{rowData.status}</td>
                                <td className='text-end'>{rowData.qty}</td>
                                <td className='text-end' >{rowData.received_qty}</td>
                                <td className='text-end'>{rowData.cancelled_qty}</td>
                                <td className='text-end'>{rowData.pending_qty}</td>
                                <td className="text-center">
                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewMoreDetailHandle(rowData)} >View</a></li>
                                    </ul>
                                </td>
                            </tr>);

                        }))
                        : (<tr><td className="text-center" colSpan={10}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }

    transactionDetailsModalJsx                     =   ()  =>  {
       
        let data = this.state.selectedPOData
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {data && data.transaction_id ? data.transaction_id : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                           <table className='table table-sm mb-0 table-bordered table-hover  mb-2'>
                                    {data && <tbody>
                                        <tr>
                                            <td style={{ width: "15%" }}> Transaction ID   </td>
                                            <th style={{ width: "35%" }}>{data.transaction_id ? data.transaction_id : ""} </th>
                                            <td style={{ width: "15%" }}>Transaction Date </td>
                                            <th style={{ width: "35%" }}>{data.transaction_date ? data.transaction_date : ""}</th>
                                        </tr>
                                        <tr>
                                            <td > Item Name (Code)   </td>
                                            <th><span>{data.item_name} ({data.item_code})</span> </th>
                                            <td>Warehouse </td>
                                            <th>{data.warehouse_name ? data.warehouse_name : "-"}</th>
                                        </tr>
                                        {this.props.permissions.includes('vendor-view') && <tr>
                                            <td > Vendor Name   </td>
                                            <th>{data.vendor_name ? data.vendor_name : "-"}</th>
                                            <td>Vendor Code </td>
                                            <th>{data.vendor_code ? data.vendor_code : "-"}</th>
                                        </tr>}
                                        <tr>
                                            <td > Manufacturer   </td>
                                            <th>{data.manufacturer ? data.manufacturer : "-"}</th>
                                            <td>UOM </td>
                                            <th>{data.uom ? data.uom : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td >Item Tags  </td>
                                            <th>{data.tags && data.tags.length > 0  ? data.tags.map(tg => {return(tg.name)}).join(', ') : "-"}</th>
                                            <td>Priority </td>
                                            <th>{data.priority ? data.priority : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td > PO Qty  </td>
                                            <th>{data.qty}</th>
                                            <td>Received Qty</td>
                                            <th>{data.received_qty ? data.received_qty : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td > Cancelled Qty  </td>
                                            <th>{data.cancelled_qty ? data.cancelled_qty : "-"}</th>
                                            <td>Pending Qty</td>
                                            <th>{data.pending_qty ? data.pending_qty : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td >Delivery Due Date  </td>
                                            <th>{data.due_date ? data.due_date : "-"}</th>
                                            <td>Ageing from Delivery Due Date</td>
                                            <th>{data.due_period ? data.due_period : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td >PO Release Date  </td>
                                            <th>{data.release_date ? data.release_date : "-"}</th>
                                            <td>Ageing from PO Release Date</td>
                                            <th>{data.po_release_ageing ? data.po_release_ageing : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td >Status  </td>
                                            <th>{data.status ? data.status : ""}</th>
                                            <td>PO Tagged Users</td>
                                            <th>{data.po_tagged_users && data.po_tagged_users.length > 0 ? data.po_tagged_users.map(user => {return(user.full_name)}).join(', ') : "-"}</th>
                                        </tr>

                                    </tbody>}
                                </table>
                            <table className='table table-sm my-2 table-bordered'>
                                <thead className='table-secondary'>
                                  <tr className='text-center'>
                                  <th style={{width : "10%"}}>Warehouse Indent Trans. ID</th>
                                        <th style={{width : "10%"}}>Date of Indent Approval</th>
                                        <th style={{width : "10%"}}>Ageing from Indent Approval</th>
                                        <th style={{width : "15%"}}>Indent Tagged Users</th>
                                        <th style={{width : "10%"}}>Req Trans. ID</th>
                                        <th style={{width : "10%"}}>Req Qty</th>
                                        <th style={{width : "10%"}}>Date of PR Approval</th>
                                        <th style={{width : "10%"}}>Ageing from PR Approval</th>
                                        <th style={{width : "15%"}}>PR Tagged Users</th>
                                       
                                  </tr>
                                </thead>
                                <tbody>
                                    {data && data.pr_data && data.pr_data.length > 0 ? data.pr_data.filter(trans => trans.pr_transaction_id !== undefined && trans.pr_transaction_id !== null).map((pr,index) => {
                                        return(<tr key={index} className='text-center'>
                                            <td>{pr.wi_transaction_id ? pr.wi_transaction_id : ""}</td> 
                                           <td>{pr.wi_approval_date ? pr.wi_approval_date : ""}</td> 
                                           <td>{pr.wi_approval_ageing ? pr.wi_approval_ageing : ""}</td> 
                                           <td>{pr.wi_tagged_users && pr.wi_tagged_users.length > 0 ? pr.wi_tagged_users.map(tag => {return(tag.full_name)}).join(', ') : ""}</td> 
                                           <td>{pr.pr_transaction_id ? pr.pr_transaction_id : ""}</td> 
                                           <td>{pr.requisition_qty ? pr.requisition_qty : ""}</td> 
                                           <td>{pr.pr_approval_date ? pr.pr_approval_date : ""}</td> 
                                           <td>{pr.pr_approval_ageing ? pr.pr_approval_ageing : ""}</td> 
                                           <td>{pr.pr_tagged_users && pr.pr_tagged_users.length > 0 ? pr.pr_tagged_users.map(tag => {return(tag.full_name)}).join(', ') : ""}</td>          
                                        </tr>)
                                    }) : <tr><td className='text-center' colSpan={10}>No Record</td></tr>}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>ItemWise PO Lead Time - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
                {this.transactionDetailsModalJsx()}
            </div>
            <PurchaseOrderDetailModal parentProps={this.props} ref={this.PODetailModalRef}/>
            <ReceivedQtyDetail parentProps={this.props} ref={this.POReceivedQtyModalRef}/>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ItemWiseLeadTimeReport);