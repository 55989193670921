import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import Ax from "../../components/hoc/Ax";
import { Modal } from 'bootstrap';
import tapIcon from "../../services/TapIcon";
import HttpAPICall from '../../services/HttpAPICall';
import errorValidation from '../../services/ErrorValidation'
import TapSelect from "../../components/ui/TapSelect";
import { DisplayListPagination, DisplaySearchCriteria } from '../../components/TapUi';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../services/TapApiUrls';
import Status from '../../components/ui/Status';
import DownloadFile from "../../services/DownloadFile";
import download from '../../services/DownloadFile';
import AppBaseComponent from '../../components/AppBaseComponent';
import InterchangeableItem from './InterchangeableItem';
import ItemInventory from './ItemInventory';
import ItemDetailsTab from './itemDetails/ItemDetailsTab';
import { Helmet } from 'react-helmet';
import * as actions from '../../store/actions/index';
import { Link } from 'react-router-dom';
import swal from "sweetalert"
import OpeningBalanceAddModal from '../settings/itemOpeningBalance/OpeningBalanceAddModal';
import ItemWhConfig from './configuration/ItemWhConfig';


class ItemList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.itemSearchFormInitialState = {
            'search_name': '',
            'search_status': '',
            'search_item_code': '',
            'search_category': '',
            'search_manufacturer_id': '',
            'search_manufacturer_part_no': '',
            'search_gst_code': '',
            'search_hsn_code': '',
            'search_item_tags': [],
        };

        this.itemSearchFormLabel = {
            'search_name': 'Item Name : ',
            'search_item_code': 'Item Code : ',
            'search_status': 'Item Status : ',
            'search_category': 'Category : ',
            'search_manufacturer_id': 'Manufacturer : ',
            'search_manufacturer_part_no': 'Manufacturer Part No : ',
            'search_gst_code': 'GST : ',
            'search_hsn_code': 'HSN / SAC Code : ',
            'search_item_tags': 'Item Tags : ',
        };


        this.state = {
            listing_tbl_page: 1,
            listing_loading: false,
            listingMeta: null,
            listingData: [],
            item_additional_attributes: [],
            itemData: null,
            totalListingCount: 0,
            minimizeTable: false,
            itemSearchForm: { ...this.itemSearchFormInitialState },
            itemSearchedElems: [],
            itemSearchParams: null,
            itemAddScreenView: false,
            itemAddFormData: null,
            itemBulkUploadFormSubmitting: false,
            itemBulkUpdateFormSubmitting: false,
            itemUpdateScreenView: false,
            itemFormDataLoaded: false,
            filterFormData: [],
            form_loading: false,
            details_loading: false,
            allManufacturers: [],
            allTags: [],
            itemId: null,
            itemName: '',
            enc_item_id: props.match.params.id,
            tabScreen: this.props.match.params.tab,
            category: '',
            excelDownloading: false,
            minimize: false,
            itemDisplayName: '',
            refreshDetail: false,
            refreshInventoryLevel: false,
            itemStatus: '',
            changeStatus: false,
            allStatus: []
        };
        this.updateItemScreenInit = this.updateItemScreenInit.bind(this);
        this.openingBalanceRef = React.createRef();
        this.itemWhConfigRef = React.createRef();

        this.itemUrl = INVENTORY_API_BASE_URL_2 + '/item';
        this.itemSearchFormUrl = INVENTORY_API_BASE_URL_2 + '/item/search_form_data';
        this.itemAddFormDataUrl = INVENTORY_API_BASE_URL_2 + '/item/add_form_data';
    }

    componentDidMount() {
        //On  Initialize Get Item List From Server :-

        this.loadListingTblData(1);
        if (this.props.location.pathname !== "/item_list") {
            this.setState({ minimizeTable: true })
        }

        this.itemSearchFormDataInit()

        this.searchItemModal = new Modal(document.getElementById('searchItemModal'), { keyboard: false, backdrop: false });
        this.itemBulkUploadModal = new Modal(document.getElementById('itemBulkUploadModal'), { keyboard: false, backdrop: false });
        this.itemBulkUpdateModal = new Modal(document.getElementById('itemBulkUpdateModal'), { keyboard: false, backdrop: false });
        this.linkInterchangeableModal = new Modal(document.getElementById('linkInterchangeableModal'), { keyboard: false, backdrop: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.tab !== this.state.tabScreen || nextProps.match.params.id !== this.state.enc_item_id) {
            this.setState({ tabScreen: nextProps.match.params.tab, enc_item_id: nextProps.match.params.id })
            //           
        }
    }

    itemSearchFormDataInit = () => {
        this.setState({ form_loading: true });
        HttpAPICall.withAthorization('GET', this.itemSearchFormUrl, this.props.access_token, {}, {}, (resp) => {
            this.setState({
                filterFormData: resp.data,
                allManufacturers: resp.data.manufacturers.map((m) => { return { value: m.id, label: m.name } }),
                allTags: resp.data.tags.map((m) => { return { value: m.id, label: m.name } }),
                allStatus: resp.data.status.map((m) => { return { value: m.key, label: m.name } })
            })
        }).then(() => { this.setState({ form_loading: false }) })
    }

    itemAddFormDataInit = () => {
        if (!this.state.itemFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.itemAddFormDataUrl, this.props.access_token, null, null, (response) => {
                this.setState({
                    itemFormDataLoaded: true,
                    itemAddFormData: response.data,
                });
            });
        }
    }

    formInputHandler = (e, key = 'itemSearchForm') => {
        if (key && e.target && e.target.name) {
            this.setState({ [key]: { ...this.state[key], [e.target.name]: e.target.value } });
        }
        this.setState({ loginFormErrorMsg: null });
    }

    searchFormInputHandler = (e) => {
        if (e.target && e.target.name) {
            this.setState({ itemSearchForm: { ...this.state.itemSearchForm, [e.target.name]: { ...this.state.itemSearchForm[e.target.name], value: e.target.value } } });
        }
    }

    loadListingTblData = (page = 1) => {
        let searchParm = { ...this.props.itemParams };
        this.setState({ listing_loading: true, listing_tbl_page: page });
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params: { page: page, per_page: 50, ...searchParm },
            url: this.itemUrl
        }).then((response) => {
            let data = response.data;
            this.setState((prevStates, prevProps) => ({
                item_additional_attributes: data.additional_attributes,
                listingData: data.data,
                listingMeta: data.meta,
                totalListingCount: data.meta.total
            }));
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        }).then(() => {
            this.setState({ listing_loading: false });
        });
    }

    viewItemDetail = (item_id, item_name, category, item_code, item_status = 1) => {
        let obj = { asset_name: item_name, item_category: category }
        let itemDisplayName = `${item_name} (${item_code})`
        if (this.state.tabScreen !== undefined) {
            this.props.history.push({ pathname: `/item_list/${this.state.tabScreen}/${item_id}`, search: "?" + new URLSearchParams(obj).toString() })
        } else {
            this.props.history.push({ pathname: `/item_list/details/${item_id}`, search: "?" + new URLSearchParams(obj).toString() })
        }
        this.setState({ minimizeTable: true, itemId: item_id, itemName: item_name, category: category, itemDisplayName: itemDisplayName, refreshDetail: false, refreshInventoryLevel: false, itemStatus: item_status })
    }

    getItemDetails = (id) => {
        this.setState({ details_loading: true });
        HttpAPICall.withAthorization('GET', this.itemUrl + '/' + id, this.props.access_token, { opening_stock: "Y" }, {}, (resp) => {
            this.setState({ itemData: resp.data.data })
        }).then(() => { this.setState({ details_loading: false }) })
    }


    closeMinimizeTable = () => {
        this.setState({ minimizeTable: false });

    }

    itemSearchModalInit = () => {
        //Initialize Search Form :-
        this.searchItemModal.show({ focus: true });
    }

    linkInterchangeableInit = () => {
        this.linkInterchangeableModal.show();
    }

    addOpeningBalanceInit = (id, item_name) => {
        this.setState({
            refreshDetail: false
        })
        this.openingBalanceRef.current.modalInit(id, item_name)
    }


    addItemWhConfigModalInit = (data = null) => {
        this.itemWhConfigRef.current.updateItemWarehouseConfigInit();
    }

    linkInterchangeableModalJsx = () => {
        return (
            <div className="modal fade" id="linkInterchangeableModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Link Interchangeable Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.linkItemForm} id="addModalForm">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Item Search</label>
                                    </div>
                                    <div className="col-md-8">
                                        <TapSelect
                                            isClearable={true}
                                            placeholder="Search Item"
                                            required={true}
                                        />
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Notes</label>
                                    </div>
                                    <div className="col-md-8">
                                        <textarea
                                            name="description"
                                            className="form-control"
                                            style={{ height: "80px" }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    itemSearchFormSubmit = (event) => {
        event.preventDefault();
        //Get All Keys :-
        let serachFormKeys = Object.keys(this.state.itemSearchForm);
        let searchedElems = [];
        let itemSearchedElems = {};
        let itemSearchParams = {};
        serachFormKeys.map((key) => {
            let label = this.itemSearchFormLabel[key];
            let value = this.state.itemSearchForm[key];
            if (value && value.toString().length > 0) {
                itemSearchParams[key] = value;
                if (label) {
                    let show_val = value;

                    if (key == 'search_name') {
                        show_val = this.state.itemSearchForm.search_name;
                    }
                    if (key == 'search_item_code') {
                        show_val = this.state.itemSearchForm.search_item_code;
                    }
                    if (key == 'search_category') {
                        show_val = this.state.itemSearchForm.search_category;
                    }
                    if (key == 'search_manufacturer_id') {
                        show_val = this.state.allManufacturers.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if (key == 'search_status') {

                        show_val = this.state.allStatus.filter((s) => value == s.value).map(s => s.label);
                    }

                    if (key == 'search_gst_code') {
                        show_val = this.state.itemSearchForm.search_gst_code;
                    }
                    if (key == 'search_manufacturer_part_no') {
                        show_val = this.state.itemSearchForm.search_manufacturer_part_no;
                    }
                    if (key == 'search_item_tags') {
                        show_val = this.state.allTags.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if (key == 'search_hsn_code') {
                        show_val = this.state.itemSearchForm.search_hsn_code;
                    }

                    searchedElems.push(label + show_val);
                }
            }
        });

        //Generate Html :-
        this.props.onItemSearch(searchedElems, itemSearchParams);
        this.setState({
            itemSearchedElems: searchedElems,
            itemSearchParams: itemSearchParams
        }, () => {
            this.loadListingTblData(1);
            this.searchItemModal.hide();


        });
    }

    itemSearchClear = () => {
        this.props.onItemSearchClear();
        this.setState({
            itemSearchForm: this.itemSearchFormInitialState,
            itemSearchedElems: [],
            itemSearchParams: null,
        }, function () {
            this.loadListingTblData(1)

        });
    }

    addNewItemScreenInit = () => {
        this.props.history.push('/item_list/add_item')
    }

    updateItemScreenInit = (id) => {
        this.props.history.push({ pathname: `/item_list/update_item`, state: { id: id } })
    }

    cloneItemScreenInit = (id) => {
        this.props.history.push({ pathname: `/item_list/add_item`, state: { id: id, clone: 'yes' } })
    }

    addNewItemScreenClose = () => {
        this.setState({ itemAddScreenView: false, itemUpdateScreenView: false });
    }

    afterAddNewItemSubmit = (event) => {
        event.preventDefault();
        //Item is Added Successfully Need to refresh and close Add form
        this.setState({ itemAddScreenView: false, itemUpdateScreenView: false });
        //Load Add Item Form Data :-
        this.loadListingTblData(1);
    }

    afterUpdateItemSubmit = (event, id) => {
        event && event.preventDefault();

        let itemList = this.state.listingData;
        let updatedItemIndex = this.state.listingData.findIndex((i => i.id == id));
        itemList[updatedItemIndex] = null;

        this.setState({ itemAddScreenView: false, itemUpdateScreenView: false, listingData: itemList });
        //Get item Details :-
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            url: this.itemUrl + '/' + id
        }).then((response) => {
            let itemData = response.data.data;
            itemList[updatedItemIndex] = itemData;
            // this.setState({listingData : itemList});
            let obj = { asset_name: itemData.name, item_category: itemData.category }
            this.setState({
                listingData: itemList,
                minimizeTable: true,

                // itemStatus    :    itemData?.status,
            }, () => {
                // this.props.history.push({ pathname: `/item_list/details/${itemData.id}`, search: "?" + new URLSearchParams(obj).toString()})
            })
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Load Item.', { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {

        });
    }

    /**
     * Bulk Upload :-
     */
    itemBulkUploadModalInit = () => {
        //Initialize Search Form :-
        this.itemBulkUploadModal.show();
    }

    downloadSampleFile = () => {
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/download_sample_add_item_excel', this.props.access_token,
            {}, {}, (response) => {
            DownloadFile.file(response.data.file_path)
            toast.success('File Downloaded Successfully', { position: toast.POSITION.TOP_RIGHT })
            this.itemBulkUploadModal.hide();
        })
    }

    itemBulkUploadFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ itemBulkUploadFormSubmitting: true });
        const data = new FormData(document.getElementById('itemBulkUploadForm'));
        let headers = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + this.props.access_token };
        axios.post(this.itemUrl + '/excel_add', data, { headers: headers })
            .then((response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.itemBulkUploadModal.hide();
                //Load Add Item Form Data :-
                //this.loadListingTblData(1);
                DownloadFile.file(response.data.generated_file.file_path);
                setTimeout(() => {
                    this.loadListingTblData(1);
                }, 1000)
            }).catch((err) => {
                toast.error(err?.response?.data?.message ?? 'Unable to Add Item.', { position: toast.POSITION.TOP_RIGHT });
            }).then(() => {
                this.setState({ itemBulkUploadFormSubmitting: false });
            });
    }

    /**
    * Bulk Update :-
    */
    itemBulkUpdateModalInit = () => {
        //Initialize Search Form :-
        this.itemBulkUpdateModal.show();
    }


    itemBulkUpdateFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ itemBulkUpdateFormSubmitting: true });
        const data = new FormData(document.getElementById('itemBulkUpdateForm'));
        let headers = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + this.props.access_token };
        axios.post(this.itemUrl + '/excel_update', data, { headers: headers })
            .then((response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.itemBulkUpdateModal.hide();
                //Load Add Item Form Data :-
                this.loadListingTblData(1);
            }).catch((err) => {
                toast.error(err?.response?.data?.message ?? 'Unable to Update Item.', { position: toast.POSITION.TOP_RIGHT });
            }).then(() => {
                this.setState({ itemBulkUpdateFormSubmitting: false });
            });
    }

    //******************ITEM DOWNLOAD******************** */
    itemExcelDownload = () => {
        this.setState({ excelDownloading: true });
        let searchParm = { ...this.props.itemParams };
        let params = { page: this.state.listing_tbl_page, per_page: 50, ...searchParm, download: "download" };
        HttpAPICall.withAthorization('GET', this.itemUrl, this.props.access_token, params, {}, (response) => {
            let respData = response.data
            let file_path = respData && respData.file_path ? respData && respData.file_path : '';
            let message = respData && respData.msg ? respData && respData.msg : '';
            let navigation = respData && respData.navigation ? respData && respData.navigation : '';
            download.file(file_path, message, navigation, this.props);
        })
            .then(() => this.setState({ excelDownloading: false }));
    }

    refreshInventoryLevel = () => {
        this.setState({
            refreshInventoryLevel: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    refreshInventoryLevel: false
                })
            }, 1000)
        })
    }

    changeStatusHandle = () => {
        let itemData = this.state.listingData.find((i => i.id == this.state.enc_item_id));

        let frmData = {
            status: itemData && itemData.status == 1 ? 2 : 1,
            id: this.state.enc_item_id
        }
        this.setState({ refreshDetail: false }, () => {
            swal({
                title: "Change Status",
                text: "This action cannot be reversed, Are you sure you want to proceed?",
                icon: "warning",
                // dangerMode: true,
                buttons: ["No", "Yes"],
            })
                .then(willChangeStatus => {
                    if (willChangeStatus) {
                        HttpAPICall.withAthorization('PUT', this.itemUrl + '/change_status', this.props.access_token, {}, { ...frmData }, (response) => {
                            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                            this.setState({
                                refreshDetail: true,
                                itemStatus: itemData && itemData.status == 1 ? 2 : 1,
                            }, () => {

                            })
                            this.afterUpdateItemSubmit(null, this.state.enc_item_id)
                        }).then(() => {

                        });
                    }
                });
        })

    }

    loadItemTabContentJsx = () => {
        switch (this.state.tabScreen) {
            case 'details':
                return <ItemDetailsTab item_enc_id={this.state.enc_item_id} props={this.props} category={this.state.category} refreshDetail={this.state.refreshDetail} />
            case 'interchangeable':
                return <InterchangeableItem item_enc_id={this.state.enc_item_id} props={this.props} category={this.state.category} />
            case 'inventory':
                return <ItemInventory item_enc_id={this.state.enc_item_id} props={this.props} category={this.state.category} refreshInventoryLevel={this.state.refreshInventoryLevel} />
            case 'configuration':
                return <ItemWhConfig ref={this.itemWhConfigRef} item_enc_id={this.state.enc_item_id} props={this.props} category={this.state.category} />
            default:
                return;
        }
    }

    itemListjsx = () => {
        let ParamObject = new URLSearchParams(this.props.location.search);
        let itemName = this.state.itemName;
        if (ParamObject.get('asset_name')) {
            itemName = ParamObject.get('asset_name')
        }

        let { location } = this.props;
        let searchParams = '';
        if (location && location.search) {
            searchParams = new URLSearchParams(location.search);
        }

        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Item List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button" disabled={this.state.excelDownloading == true || !this.props?.permissions?.includes('isc-item-add')} onClick={this.addNewItemScreenInit} className="btn btn-primary">New Item</button> : null}
                    <button type="button" onClick={this.itemSearchModalInit} className="btn btn-secondary" disabled={this.state.excelDownloading == true}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.excelDownloading == true}>
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {this.state.minimizeTable ?
                                <li>
                                    <Link role="button"
                                        onClick={() => {
                                            if (this.props?.permissions?.includes('isc-item-add')) {
                                                return this.addNewItemScreenInit()
                                            }
                                        }}
                                        className={['dropdown-item', this.props?.permissions?.includes('isc-item-add') ? '' : 'disabled'].join(' ')}
                                        title={!this.props?.permissions?.includes('isc-item-add') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >New Item</Link>


                                </li> : null}
                            <li>
                                <Link role="button"
                                    onClick={() => {
                                        if (this.props?.permissions?.includes('isc-item-add')) {
                                            return this.itemBulkUploadModalInit()
                                        }
                                    }}
                                    className={['dropdown-item', this.props?.permissions?.includes('isc-item-add') ? '' : 'disabled'].join(' ')}
                                    title={!this.props?.permissions?.includes('isc-item-add') ? 'You do not have permission to perform this action' : ''}
                                    style={{ "pointerEvents": "all" }}
                                >Bulk Upload</Link>
                            </li>
                            <li>
                                <Link role="button"
                                    onClick={() => {
                                        if (this.props?.permissions?.includes('isc-item-edit')) {
                                            return this.itemBulkUpdateModalInit()
                                        }
                                    }}
                                    className={['dropdown-item', this.props?.permissions?.includes('isc-item-edit') ? '' : 'disabled'].join(' ')}
                                    title={!this.props?.permissions?.includes('isc-item-edit') ? 'You do not have permission to perform this action' : ''}
                                    style={{ "pointerEvents": "all" }}
                                >Bulk Update</Link>
                            </li>

                            <li><a className="dropdown-item" onClick={this.itemExcelDownload} role="button" >Download</a></li>
                        </ul>
                    </span>
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Item : {itemName ? itemName.slice(0, 25) : ''}{itemName && itemName.length > 30 ? '...' : ''}</h3>
                            <div className="text-end mt15">
                                {itemName !== ''
                                    ? <button onClick={() => this.updateItemScreenInit(this.state.enc_item_id)} type="button" className="btn btn-secondary" disabled={!this.props?.permissions?.includes('isc-item-edit')}>
                                        Edit Item</button>
                                    : null
                                }

                                <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faChartBar} className=" mr3" />
                                    Report</button>
                                <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                    <li><Link className="dropdown-item" to={{ pathname: `/report/item_wise_allocation`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Item wise Allocation</Link></li>
                                    <li><Link className="dropdown-item" to={{ pathname: `/report/item_wise_consumption`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Item wise Consumption</Link></li>
                                    <li><Link className="dropdown-item" to={{ pathname: `/report/item_wise_purchase`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Item wise Purchase</Link></li>
                                    {this.props.permissions.includes('isc-po-view') && <li><Link className="dropdown-item" to={{ pathname: `/report/item_wise_purchase_order`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Item wise Purchase Order</Link></li>}
                                    <li><Link className="dropdown-item" to={{ pathname: `/report/item_wise_requisition`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Item wise Purchase Requisition</Link></li>
                                    <li><Link className="dropdown-item" to={{ pathname: `/report/item_stock_transaction_ledger`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Item wise Transaction Ledger</Link></li>
                                    <li><Link className="dropdown-item" to={{ pathname: `/report/item_closing_stock`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Inventory Closing Stock</Link></li>
                                    <li><Link className="dropdown-item" to={{ pathname: `/report/inventory_forecasting_report`, search: "?" + new URLSearchParams({ search_item_ids: this.state.enc_item_id, item_name: itemName }).toString() }}>Inventory Forecasting Report</Link></li>
                                </ul>

                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item cursor_pointer" onClick={() => this.cloneItemScreenInit(this.state.enc_item_id)}>Clone</a></li>
                                    <li>
                                        <Link role="button"
                                            onClick={() => {
                                                if (this.props?.permissions?.includes('isc-item-opening-balance')) {
                                                    return this.addOpeningBalanceInit(this.state.enc_item_id, itemName)
                                                }
                                            }}
                                            className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-opening-balance') ? '' : 'disabled'].join(' ')}
                                            title={!this.props?.permissions?.includes('isc-item-opening-balance') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Add Opening Balance</Link>
                                    </li>
                                    <li>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('isc-item-change-status')) {
                                                    return this.changeStatusHandle()
                                                }
                                            }}
                                            className={['dropdown-item cursor_pointer', this.props.permissions.includes('isc-item-change-status') ? '' : 'disabled'].join(' ')}
                                            title={!this.props.permissions.includes('isc-item-change-status') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Change Item Status</a>
                                    </li>
                                    {this.state.tabScreen == 'configuration' &&
                                        <li>

                                            <Link
                                                to={{
                                                    pathname: location.pathname,
                                                    search: searchParams.toString(),
                                                }}
                                                onClick={this.addItemWhConfigModalInit}
                                                className={['dropdown-item cursor_pointer']}>
                                                Configuration
                                            </Link>
                                        </li>
                                    }

                                    {this.state.tabScreen == 'inventory' && <li><a className="dropdown-item cursor_pointer" onClick={() => { this.refreshInventoryLevel() }}>Refresh Inventory Level</a></li>}
                                </ul>

                                <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >
                        {this.groupListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.loadItemTabContentJsx()}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    groupListingTableJsx = () => {

        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.props.itemElems} onClearFilter={this.itemSearchClear} />
                <table className="table table-bordered table-fixed  bg-white table-sm">
                    <thead className="table-secondary" >
                        {this.state.minimizeTable
                            ? (<tr>
                                <th scope="col" style={{ width: "15%" }}>Item Name (Code)</th>
                            </tr>)
                            : (<tr>
                                <th scope="col" style={{ width: "5%" }}>#</th>
                                <th scope="col" style={{ width: "19%" }}>Item Name</th>
                                <th scope="col" style={{ width: "15%" }}>Item Code</th>
                                <th scope="col" style={{ width: "18%" }}>Manufacturer</th>
                                <th scope="col" style={{ width: "11%" }}>MPN</th>
                                <th scope="col" style={{ width: "9%" }}>UOM</th>
                                <th scope="col" style={{ width: "10%" }} className='text-center'>Status</th>
                                <th scope="col" style={{ width: "5%" }}>Action</th>
                            </tr>)
                        }
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                            : this.state.listingData.length > 0
                                ? this.state.listingData.map((item, index) => this.listingTableTrJsx(item, index))
                                : <tr><td className="text-center" colSpan="8">No Record Found</td></tr>}
                    </tbody>
                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                />
            </Ax>
        );
    }

    listingTableTrJsx = (item, index) => {
        if (item) {
            return (
                <tr key={index}>
                    {
                        this.state.minimizeTable
                            ? (
                                <Ax>
                                    <td>
                                        <a role="button" onClick={() => this.viewItemDetail(item.id, item.name, item.category, item?.item_code, item?.status)} className="link-primary">{item.name}</a>
                                        <br /><small>Code : {item.item_code}</small>
                                    </td>
                                </Ax>
                            )
                            : (
                                <Ax>
                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td><a role="button" onClick={() => this.viewItemDetail(item.id, item.name, item.category, item?.item_code, item?.status)} className="link-primary text-capitalize">{item.name}</a></td>
                                    <td className='text-capitalize'>{item.item_code}</td>
                                    <td className='text-capitalize'>{item.manufacturer ? item.manufacturer.name : '-'}</td>
                                    <td className='text-capitalize'>{item.manufacturer_part_no ? item.manufacturer_part_no : '-'}</td>
                                    <td>{item.measuring_unit ? item.measuring_unit.name : '-'}</td>
                                    <td className='text-center'><Status color={item.status_color}>{item.status_text}</Status></td>
                                    <td >
                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" onClick={() => this.updateItemScreenInit(item.id)} role="button">Edit Item</a></li>
                                        </ul>
                                    </td>
                                </Ax>
                            )
                    }

                </tr>
            );
        } else {
            return (
                <tr key={index}><td colSpan={this.state.minimizeTable ? 1 : 8}><Loader /></td></tr>
            );
        }
    }



    itemSearchModalJsx = () => {
        return (
            <div className="modal fade" id="searchItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Item Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.form_loading ? <Loader />
                            : <form onSubmit={this.itemSearchFormSubmit} id="itemSearchForm">
                                <div className="modal-body">
                                    <div className="row">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end"> Item Name</label>
                                        <div className='col-sm-4'>
                                            <input
                                                type="text"
                                                name="search_name"
                                                value={this.state.itemSearchForm.search_name}
                                                className="form-control form-control-sm"
                                                onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                placeholder="Item Name"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end"> Item Code</label>
                                        <div className='col-sm-4'>
                                            <input
                                                type="text"
                                                name="search_item_code"
                                                value={this.state.itemSearchForm.search_item_code}
                                                className="form-control form-control-sm"
                                                onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                placeholder="Item Code"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end"> Item Category</label>
                                        <div className='col-sm-4'>
                                            {
                                                this.state.filterFormData && this.state.filterFormData.category
                                                    ? (this.state.filterFormData.category.map((cat, k) => {
                                                        return (
                                                            <div key={cat.index} className="form-check form-check-inline my-1">
                                                                <input
                                                                    name="search_category"
                                                                    type="radio"
                                                                    value={cat.index}
                                                                    onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                                    checked={this.state.itemSearchForm.search_category === cat.index}
                                                                    className="form-check-input"
                                                                    id={cat.index}
                                                                />
                                                                <label className="form-check-label form-label-sm" htmlFor={cat.index}>{cat.item}</label>
                                                            </div>);
                                                    }))
                                                    : null
                                            }
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end">Item Tags</label>
                                        <div className='col-sm-4'>
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'itemSearchForm', 'search_item_tags')}
                                                options={this.state.allTags}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allTags.filter(t => this.state.itemSearchForm.search_item_tags.includes(t.value))}
                                                placeholder="Please Select Tag"
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end"> Manufacturer</label>
                                        <div className='col-sm-4'>
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'itemSearchForm', 'search_manufacturer_id')}
                                                options={this.state.allManufacturers}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allManufacturers.find(m => m.value == this.state.itemSearchForm.search_manufacturer_id)}
                                                placeholder="Please Select"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end">MPN</label>
                                        <div className='col-sm-4'>
                                            <input
                                                type="text"
                                                name="search_manufacturer_part_no"
                                                value={this.state.itemSearchForm.search_manufacturer_part_no}
                                                className="form-control form-control-sm"
                                                onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                placeholder="Search MPN"
                                                autoComplete="off"
                                            />
                                        </div>

                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end">HSN / SAC Code</label>
                                        <div className='col-sm-4'>
                                            <input
                                                type="text"
                                                name="search_hsn_code"
                                                value={this.state.itemSearchForm.search_hsn_code}
                                                className="form-control form-control-sm"
                                                onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                placeholder="Search HSN / SAC Code"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm text-end">Item Status</label>
                                        <div className='col-sm-4'>
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'itemSearchForm', 'search_status')}
                                                options={this.state.allStatus}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={this.state.allStatus.find(t => this.state.itemSearchForm.search_status == t.value)}
                                                placeholder="Please Select Status"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Search</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        );
    }

    itemBulkUploadModalJsx = () => {
        return (
            <div className="modal fade" id="itemBulkUploadModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Upload Item</h5>
                            <button type="button" disabled={this.state.itemBulkUploadFormSubmitting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.itemBulkUploadFormSubmit} id="itemBulkUploadForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <div><a type="button" className='float-left' href='#' onClick={this.downloadSampleFile}>Download Sample File</a></div>
                                <div>
                                    <button type="button" disabled={this.state.itemBulkUploadFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.itemBulkUploadFormSubmitting} className="btn btn-primary">
                                        Submit {this.state.itemBulkUploadFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    itemBulkUpdateModalJsx = () => {
        return (
            <div className="modal fade" id="itemBulkUpdateModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Update Item</h5>
                            <button type="button" disabled={this.state.itemBulkUpdateFormSubmitting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.itemBulkUpdateFormSubmit} id="itemBulkUpdateForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.itemBulkUpdateFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                <button type="submit" disabled={this.state.itemBulkUpdateFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.itemBulkUpdateFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Item Listing</title></Helmet>
            {this.itemListjsx()}
            {this.itemSearchModalJsx()}
            {this.itemBulkUploadModalJsx()}
            {this.linkInterchangeableModalJsx()}
            {this.itemBulkUpdateModalJsx()}
            <OpeningBalanceAddModal
                ref={this.openingBalanceRef}
                afterSubmitForm={() => {
                    this.setState({
                        refreshDetail: true
                    })
                }}
            />
            {/* <ItemWhConfigModel ref={this.addConfigRef} item_id={this.state.enc_item_id}/> */}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {

    return {
        access_token: state.auth.access_token,
        itemElems: state.app.search_elem && state.app.search_elem.length > 0 ? state.app.search_elem : [],
        itemParams: state.app.search_param,
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onItemSearch: (search_elem, search_param) => dispatch(actions.itemSearch(search_elem, search_param)),
        onItemSearchClear: () => dispatch(actions.itemSearchClear()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ItemList);