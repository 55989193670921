import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import moment from "moment";
import DatePicker from "react-datepicker";
import {Modal} from "bootstrap";
import Ax from "../../../components/hoc/Ax";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from "../../../services/HttpAPICall";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import {toast} from "react-toastify";
import React from "react";
import { Helmet } from "react-helmet";

 
class OverTimeRuleAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.addOTFormInitialState  =   {
            name                        :   "",
            reference_number            :   "",
            roster_id                   :   "",
            week_days                   :   [],
            specific_dates              :   []
        }
        this.state                  =   {
            addOTForm                   :   {...this.addOTFormInitialState},
            roster_name                 :   [],
            roster_days                 :   [],
            selectedShift               :   [],
            selectedDay                 :   [],
            selectedRosterShifts        :   [],
            selectedSpecificDate        :   "",
            specificDays                :   [],
            disableLimit                :   false,
            shiftId                     :   null,
            otFormDataLoaded            :   false,
            rosterAll                   :   [],
            rosterList                  :   [],
            isoDays                     :   [],
            otRuleFormSubmittig         :      false,
        };
    }



    componentDidMount() {
        this.initilaizeFormData(this.props);
        this.addSpecificDaysModal           =   new Modal(document.getElementById('addSpecificDays'), {keyboard: false, backdrop :false});
        if(this.props.otRuleData) {

            this.setState({
                addOTForm: {
                    ...this.addOTFormInitialState,
                    name: this.props.otRuleData.name,
                    reference_number: this.props.otRuleData.reference_number,
                    roster_id: this.props.otRuleData.shift_rules !== null ? this.props.otRuleData.shift_rules.roster_id : "",
                    week_days: this.props.otRuleData.week_days.length > 0 ? this.props.otRuleData.week_days : [],
                    specific_dates: this.props.otRuleData.specific_dates.length > 0 ? this.props.otRuleData.specific_dates : []
                }

            })

            const shift_data = this.props?.otRuleData?.shift_rules?.shifts.map(t1 => ({...t1, ...this.props?.otRuleData?.shift_rules?.roster.shifts.find(t2 => t2.id === t1.id)}))


            this.setState({
                selectedRosterShifts: this.props?.otRuleData?.shift_rules !== null ? shift_data.map(s => {
                    return {
                        shift_name: s.shift_name, shift_time: s.shift_time,
                        working_hr: moment("2015-01-01").startOf('day')
                            .seconds(s.working_second).toDate(), full_ot: s.full_ot,id:s.id
                    }
                }) : []
            })
        }

    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.otFormDataLoaded !== this.props.otFormDataLoaded) {
            this.initilaizeFormData(nextProps);
        }
    }
    
    initilaizeFormData                  =   (pr) =>  {
        setTimeout(() => {
            this.setState({
                otFormDataLoaded    :   pr.otFormDataLoaded,
                rosterAll           :   pr.rosterList,
                rosterList          :   pr.rosterList.map((r) => { return {value: r.id, label: r.roster_name};}),
                isoDays             :   pr.isoDays.map((r) => { return {value: r.iso_day, label: r.name}; })
            }, () => {
            });
        },100);
    }
    
    addSpecificDaysInit            =        ()       =>  {
        this.addSpecificDaysModal.show()
        this.setState({selectedSpecificDate:""})
    }
    
    deleteSpecificDays        =   (id)    =>  {
        let specific_dates                  =   this.state.addOTForm.specific_dates;
        if(specific_dates.length > 0) {
            specific_dates.splice(id, 1);
        }
        this.setState({addOTForm : {...this.state.addOTForm, specific_dates : specific_dates}});
    }
    
    onRosterChange                         =   (selectedOptions)   =>  {
        let selectedRoster                  =   this.state.rosterAll.find(r => r.id === selectedOptions.value);
        if(selectedRoster) {
            this.setState({selectedRosterShifts : selectedRoster.shifts.map(s => {return {...s, working_hr : '', full_ot: '' };} ) });
        }
    }
    
    addOTRuleHandler                    =   (event)  =>  {
        event.preventDefault();
        this.setState({otRuleFormSubmittig : true})
        let shifts = this.state.selectedRosterShifts
        let shiftsData = shifts.map((s,i) => {
            return {id : s.id, full_ot: s.full_ot,working_hr: s.working_hr !== '' ? moment(s.working_hr).format('HH:mm') : '' }
        })

        let frmData = {
            name                       :   this.state.addOTForm.name,
            reference_number           :   this.state.addOTForm.reference_number ,
            specific_dates             :   this.state.addOTForm.specific_dates,
            week_days                  :   this.state.addOTForm.week_days,
            roster_id                  :   this.state.addOTForm.roster_id,
            shifts                     :   shiftsData
        }
        if(this.state.addOTForm.specific_dates.length > 0  || this.state.addOTForm.week_days.length > 0 || shiftsData.length > 0){
           if(this.props.otRuleData){
               HttpAPICall.withAthorization('PUT', process.env.REACT_APP_IAM_API_SERVER + '/overtime/rule/' + this.props.otRuleData.id, this.props.access_token, null, {...frmData}, (response) => {
                   toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                   this.props.closeEvent()
                   this.props.afterAddNewOtRuleSubmit(event, this.props.otRuleData.id);
                   this.setState({otRuleFormSubmittig : false})
               });
           }else{
               HttpAPICall.withAthorization('POST', process.env.REACT_APP_IAM_API_SERVER + '/overtime/rule', this.props.access_token, null, {...frmData}, (response) => {
                   toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                   this.props.closeEvent()
                   this.props.afterAddNewOtRuleSubmit(event);
                   this.setState({otRuleFormSubmittig : false});
               });
           }
        }else{
            toast.error('Please Select Atleast One Overtime Limit', {position: toast.POSITION.TOP_RIGHT});
        }
    }


    filterTime  = (shift_time,time) => {
        const currentDate = shift_time;
        const selectedDate = new Date(time);
       
        return currentDate.getTime() < selectedDate.getTime();
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>{this.props.otRuleData ? 'Update New Overtime (OT) Rule' : 'Create New Overtime (OT) Rule'}</h3>
                <div className="text-end mt15">
                    <button onClick={this.props.closeEvent} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/>
                    </button>
                </div>
            </div>
        </div>);
    }
    
    addOTFormJsx                            =   ()  =>  {
        return (<form className="bg-white p-3" onSubmit={this.addOTRuleHandler}>
            <div className="row align-items-center my-3">
                <div className="col-3"><label className="form-label require">Overtime Rule Name</label></div>
                <div className="col-5">
                    <input
                        name="name"
                        required={true}
                        type="text"
                        value={this.state.addOTForm.name}
                        onChange={(e) => this.formInputHandler(e,'addOTForm')}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Please Enter Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-3"><label className="form-label require">Reference Number</label></div>
                <div className="col-5">
                    <input
                        name="reference_number"
                        required={true}
                        type="text"
                        value={this.state.addOTForm.reference_number}
                        onChange={(e) => this.formInputHandler(e,'addOTForm')}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Please Reference Number"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-3"><label className="form-label">Roster Name</label></div>
                <div className="col-5">
                    <TapSelect
                        placeholder="Select Shift Roster"
                        isClearable={true}
                        isSearchable={true}
                        options={this.state.rosterList}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addOTForm', 'roster_id');
                            this.onRosterChange(selectedOption);
                        }}
                        value={this.state.rosterList.find(t => this.state.addOTForm.roster_id === t.value)}
                    />
                </div>
                <div className="col-4">
                    <span className="form-text">Equipment working above Threshold Limit will be treated as Overtime</span>
                </div>
            </div>
            {this.state.selectedRosterShifts.length > 0 && <div className="row align-items-center my-3">
                <div className="col-3"></div>
                <div className="col-5">
                    <table className="table table-bordered table-hover align-middle  bg-white">
                        <thead className="table-secondary">
                            <tr>
                                <th scope="col" style={{width:"30%"}} >Shift Name</th>
                                <th scope="col" style={{width:"20%"}} className="text-center" >Hours</th>
                                <th scope="col" style={{width:"25%"}} className="text-center">Threshold Limit</th>
                                <th scope="col" style={{width:"25%"}} className="text-center">Mark Shift as OT</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.selectedRosterShifts.map((rs,i) => {
                            return (<tr key={i}>
                                <td>{rs.shift_name}</td>
                                <td className="text-center">{rs.shift_time}</td>
                                <td>

                                    <DatePicker
                                        onChange={(value, event) => {
                                            let allShift = this.state.selectedRosterShifts;
                                            allShift[i]  = {...rs , working_hr : value, full_ot: 'N'};
                                            this.setState({selectedRosterShifts : allShift});
                                        }}
                                        selected={rs.working_hr ? rs.working_hr : null}
                                        className="form-control"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeFormat="HH:mm"
                                        timeIntervals={5}
                                        disabled={rs.full_ot && rs.full_ot === "Y"}
                                        timeCaption="Hours"
                                        dateFormat="HH:mm"
                                        autoComplete={"off"}
                                        placeholderText={`Select Limit`}
                                        minTime={moment().startOf('day').toDate()}
                                        maxTime={moment(rs.shift_time, "HH:mm").toDate()}
                                    />
                                </td>
                                <td className="text-center">
                                     <input
                                        name="full_ot"
                                        type="checkbox"
                                        value="Y"
                                        onChange={(e) => {
                                            let allShift = this.state.selectedRosterShifts;
                                            allShift[i]  = {...rs , working_hr : '', full_ot : (rs.full_ot ? rs.full_ot : 'N') == 'Y' ? 'N' : 'Y' };
                                            this.setState({selectedRosterShifts : allShift});
                                        }}
                                        className="form-check-input"
                                        checked={rs.full_ot && rs.full_ot === "Y"}
                                    />
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>}
            <div className="row align-items-center my-3">
                <div className="col-3"><label className="form-label ">Pick Days of the week for Overtime</label></div>
                <div className="col-5">
                    <TapSelect
                        isClearable={true}
                        placeholder="Select Days"
                        isSearchable={true}
                        isMulti={true}
                        options={this.state.isoDays}
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addOTForm', 'week_days')}
                        value={this.state.isoDays.filter(t => this.state.addOTForm.week_days.includes(t.value))}
                    />
                </div>
                <div className="col-4">
                    <span className="form-text">Equipment working on Selected Days will be treated as Overtime</span>
                </div>
            </div>
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label className="form-label"> Pick Specific Dates for Overtime</label>
                </div>
                <div className="col-5 text-start">
                    <a role="button" className="link-primary text-decoration-underline" 
                        onClick={this.addSpecificDaysInit}
                    > +Add </a>
                </div>
                <div className="col-4">
                    <span className="form-text">Equipment working on Specific Days will be treated as Overtime</span>
                </div>
            </div>
            {this.state.addOTForm.specific_dates.length > 0 && <div className="row align-items-center mb-3 mt-3">
                        <div className="col-3"></div>
                        <div className="col-5">
                            <table className="table table-bordered table-hover">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" >Date</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.addOTForm.specific_dates && this.state.addOTForm.specific_dates.map((r,i) => {
                                return( <tr>
                                    <td>{moment(r).format('DD-MMM-YYYY')}</td>
                                    <td className="text-center" onClick={() => {this.deleteSpecificDays(i)}}>
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt} className="asset_link"/>
                                    </td>
                                </tr>)})}
                            </tbody>
                            </table>
                        </div>
                    </div>}
            <div className="row">
                <div className="col-5 offset-3 text-end">
                    <button type="button"  className="btn btn-secondary mr2 px-4"  onClick={this.props.closeEvent}>Cancel</button>
                    <button type="submit" className="btn btn-primary ml4 px-4" disabled={this.state.otRuleFormSubmittig}>Submit
                        {this.state.otRuleFormSubmittig ? <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/> : null}
                    </button>
                </div>
            </div>
        </form>);
    }
    
     addSpecificDaysModalJsx                =   ()  => {
        return (
            <div className="modal fade" id="addSpecificDays" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Add Specific Days for Overtime</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            let specific_dates  =   this.state.addOTForm.specific_dates;
                            specific_dates.push(moment(this.state.selectedSpecificDate).format('YYYY-MM-DD'));
                            this.setState({
                                addOTForm : {...this.state.addOTForm , specific_dates :  specific_dates},
                                selectedSpecificDate: ''
                            });
                            
                            this.addSpecificDaysModal.hide();
                        }} id="assetSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <div className="col-12 add_calender_section">
                                        <DatePicker 
                                            selected={this.state.selectedSpecificDate}
                                            onChange={(value, event) => {
                                                this.setState({selectedSpecificDate : value});
                                            }}
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            excludeDates={this.state.addOTForm.specific_dates.map(d => moment(d).toDate())}
                                            autoComplete="off"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollMonthYearDropdown
                                            required={true}
                                            placeholderText="Select Status Date"
                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="add_calender-icon" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>)
    }
    
    render() {

        return (<Ax>
              <Helmet><title>OverTime Rule Add</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        { this.state.otFormDataLoaded 
                            ? this.addOTFormJsx() 
                            : <Loader />
                        }
                    </div>
                </div>
            </div>
            {this.addSpecificDaysModalJsx()}
        </Ax>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(OverTimeRuleAdd);