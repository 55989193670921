import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import moment from 'moment';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import { Helmet } from 'react-helmet';
import InventoryAdjustmentDetail from './InventoryAdjustmentDetail';
import TapLink from '../../../services/TapLink';

class InventoryAdjustmentList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAdjustmentSearchForm      =       {
            search_like_transaction_id       :       '',
            search_item_batch_number              :       '',
            search_warehouse_ids             :       [],
            search_manufacturer_ids             :   [],
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
            search_item_ids                  :       [],
            search_item_tags                 :       [],
            search_status_ids                :       [],
        }

        this.adjustmentSearchFormLabel       =       {
            search_like_transaction_id      :   'Transaction ID : ',
            search_item_batch_number             :   'Item Batch Number : ',
            search_warehouse_ids            :   'Warehouses : ',
            date_range                      :   'Date Range : ',
            search_item_ids                 :   'Items : ',
            search_item_tags                :   'Item Tags : ',
            search_manufacturer_ids         :    'Manufacturer : ',
            search_status_ids               :   'Search Status : ',
        }

        this.updateCommentFormInit            =        {
            status                            :         '',
            notes                             :         '',
        }

        this.state                           =     {
            minimizeTable                     :       false,
            adjustmentFormDataLoaded         :       false,
            adjustmentAddFormData            :       null,
            listing_loading                   :       false,
            listing_tbl_page                  :       1,
            adjustment_listing               :       [],
            listingMeta                       :       null,
            totalListingCount                 :       0,
            view_loading                      :       false,
            adjustmentViewData               :       null,
            adjustmentTransactionId            :   '',
            addCommentForm                  :       {...this.updateCommentFormInit},
            allAllowedStatus                :       [],
            addCommentFormSubmitting        :       false,
            adjustmentSearchForm               :       {...this.initAdjustmentSearchForm},
            submittedAdjustmentFilterForm      :       {...this.initAdjustmentSearchForm},
            formSearchedElems                   :        [],
            all_periods                         :       [],
            searchedItems                       :       [],
            allStatusOptions                    :        [],
            allItemTags                         :        [],
            all_warehouses                      :       [],
            isc_configuration                   :       null,
            adjustmentSearchFormDataLoaded      :       false,
            manufacturers                       :       [],
            reportDownloading                   :       false,
        };
        this.myRef                         =   React.createRef();

        this.invAdjustmentUrl   =   INVENTORY_API_BASE_URL_2 + '/inventory_adjustment';
        this.reportUrl          =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-inventory-adjustment';

    }   

    componentDidMount() {
        this.initializeData(this.props);
        this.loadListingTblData(1);
        if(this.props.location && this.props.location.state && this.props.location.state.adjustmentId){
            this.viewAdjustmentDetail(this.props.location.state.adjustmentId)
         }
         this.adjustmentSearchModal                 =   new Modal(document.getElementById('adjustmentSearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
         }     
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            if(!this.state.adjustmentSearchFormDataLoaded) {
                HttpAPICall.withAthorization('GET', this.invAdjustmentUrl + '/list_form_data', this.props.access_token, null, null, (response) => {
                 let formData = response.data;
                 let all_periods             =       [...this.props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
                    this.setState({
                        adjustmentSearchFormDataLoaded         :       true,
                        all_warehouses                     :       pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                        all_periods                        :       all_periods ,
                        allStatusOptions                   :       formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        manufacturers                       :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                        allItemTags                        :        pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    });
                });
               
            }
           
        }
       
    }

     //************************GET adjustment LISTING**************** */
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedAdjustmentFilterForm};
        HttpAPICall.withAthorization('GET', this.invAdjustmentUrl + '/list', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                adjustment_listing    :       respData.data,
                listingMeta            :       respData.meta,
                totalListingCount      :       respData.meta.total,
        });
       }).then(() => this.setState({listing_loading: false}));
    }

    adjustmentSearchModalInit               =       ()             =>        {
        this.adjustmentSearchModal.show();
       
    }
    
    submitAdjustmentSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.adjustmentSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.adjustmentSearchFormLabel[key];
            let value                       =   this.state.adjustmentSearchForm[key];
            
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
           if(label) {
                let show_val            =   value;
                if(key == 'search_like_transaction_id') {
                    show_val            =   this.state.adjustmentSearchForm && this.state.adjustmentSearchForm.search_like_transaction_id ? this.state.adjustmentSearchForm.search_like_transaction_id : '';
                }
                if(key == 'search_item_batch_number') {
                    show_val            =   this.state.adjustmentSearchForm && this.state.adjustmentSearchForm.search_item_batch_number ? this.state.adjustmentSearchForm.search_item_batch_number  : '';
                }
                if(key == 'search_warehouse_ids') {
                    show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_item_ids') {
                    show_val            =   this.state.searchedItems ? this.state.searchedItems.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_item_tags') {
                    show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_status_ids') {
                    show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_manufacturer_ids') {
                    show_val            = this.state.manufacturers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }

                if(key == 'date_range') {
                    let  start_range         =      moment(this.state.adjustmentSearchForm.date_range_start).format('DD-MMM-YYYY');
                    let end_range            =      moment(this.state.adjustmentSearchForm.date_range_end).format('DD-MMM-YYYY')
                    let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                    show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                }
                searchedElems.push(label + `<b>${show_val}</b>`);
            }
        }
    });
        this.setState({
            submittedAdjustmentFilterForm   :   {...this.state.adjustmentSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.adjustmentSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    adjustmentSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems             :   [],
            adjustmentSearchForm              :   {...this.initAdjustmentSearchForm},
            submittedAdjustmentFilterForm     :   {...this.initAdjustmentSearchForm},
           
        }, () => {
            this.loadListingTblData(1);
            this.adjustmentSearchModal.hide();
        });
    }

    openAddAdjustmentScreen       =      ()      =>     {
        this.props.history.push('/inventory_adjustment_add')
    }

    cloneAdjustmentScreenInit             =       ()      =>      {
       this.props.history.push({pathname: `/inventory_adjustment_add`, state: { adjustmentViewData : this.state.adjustmentViewData  , clone : 'yes'}})
    }


    viewAdjustmentDetail          =      (id)        =>     {
        this.setState({minimizeTable  :   true})
        this.getAdjustmentViewDetails(id)
       
    }

    //************************GET adjustment DETAILS**************** */
    getAdjustmentViewDetails             =    (transaction_id)        =>  {
       
         this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET',  this.invAdjustmentUrl + '/detail/' + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             
             this.setState({
                 adjustmentViewData          :   respData.data,
                 adjustmentTransactionId     :   respData.data.transaction_id,
                 allAllowedStatus            :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                 addCommentForm              :   {...this.updateCommentFormInit , status : respData.data.status_data.id}
             });
            
         },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
        
     }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    updateComment           =           ()          =>      {
        this.myRef.current.scrollIntoView()
    }

    submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
             ...this.state.addCommentForm,
             transaction_id : this.state.adjustmentTransactionId
        }
        HttpAPICall.withAthorization('PUT', this.invAdjustmentUrl + '/comment', this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getAdjustmentViewDetails(this.state.adjustmentTransactionId);
            this.loadListingTblData(1)
            this.setState({addCommentForm : {...this.updateCommentFormInit}})
        }).then(() => this.setState({ addCommentFormSubmitting : false }));
    }

    editAdjustment              =       ()          =>      {
        if(this.state.adjustmentViewData && this.state.adjustmentViewData.transaction_id) {
            this.props.history.push({pathname: `/inventory_adjustment_add`, state: {adjustmentViewData : this.state.adjustmentViewData }})
        }
    }

    deleteAdjustment              =           (transaction_id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.invAdjustmentUrl + '/delete/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({minimizeTable : false})
                        this.loadListingTblData(1)
                     }).then(() => {

                    });
                }
            });
    }

     //***********************DOWNLOAD EXCEL **************************** */
     adjustmentDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.submittedAdjustmentFilterForm};
         if(JSON.stringify(this.state.submittedAdjustmentFilterForm) !== JSON.stringify(this.initAdjustmentSearchForm)){
             this.adjustmentDownloadStart(params);
         } else {
             swal({ title: "Download",
             text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
             icon: "warning",
             buttons: ["Cancel", "Okay"],
             }).then(willDownload => {
                 if (willDownload) {
                     params = {...params , date_range: "current_quarter"}
                     this.adjustmentDownloadStart(params);
                 }
             });
         }
     }
     
     adjustmentDownloadStart                   =   (params)  =>  {
         this.setState({reportDownloading : true});
         HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...params, action : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
     }

    alllocationListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Inventory Adjustment</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax> <button type="button" disabled={!this.props.permissions.includes('isc-inventory-adjustment-add')} className="btn btn-primary" onClick={this.openAddAdjustmentScreen}>New Adjustment</button>
                         <button type="button" className="btn btn-secondary" 
                                disabled={this.state.reportDownloading || this.state.listing_loading} 
                                onClick={this.adjustmentDownloadHandler} >
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}
                            </button>
                        </Ax>
                       
                        : null
                    }
                    <button type="button" className="btn btn-secondary" onClick={this.adjustmentSearchModalInit} disabled={this.state.reportDownloading || this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon}  className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable && this.props.permissions.includes('isc-inventory-adjustment-add') ? <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="#" onClick={this.openAddAdjustmentScreen}>New adjustment</a></li>
                        </ul>
                    </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Inventory Adjustment : {this.state.adjustmentTransactionId}</h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary" onClick={() => this.updateComment()} >Update Status </button>
                                {/* <button type="button" disabled={this.state?.adjustmentViewData?.can_edit == 'N'}  onClick={() => this.editAdjustment()} className="btn btn-secondary">Edit </button> */}
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <TapLink
                                            pathname={'/inventory_adjustment_add'}
                                            state={{ adjustmentViewData: this.state.adjustmentViewData }}
                                            conditions={{
                                                canEdit: this.state.adjustmentViewData?.can_edit == "Y" &&
                                                    this.props.permissions.includes('isc-inventory-adjustment-edit') ? 'Y' : 'N',
                                                canShowTitle: this.state?.adjustmentViewData?.can_edit == 'N' ||
                                                    !this.props.permissions.includes('isc-inventory-adjustment-edit') ? 'Y' : 'N',
                                            }}
                                        >
                                            Edit
                                        </TapLink>

                                    </li>
                                    <li>
                                        <a role="button" href
                                            onClick={() => {
                                                if (this.state?.adjustmentViewData?.can_delete == "Y" && this.props.permissions.includes('isc-inventory-adjustment-delete')) {
                                                    return this.deleteAdjustment(this.state?.adjustmentViewData?.transaction_id);
                                                }
                                            }}
                                            className={['dropdown-item', this.state?.adjustmentViewData?.can_delete == "Y" && this.props.permissions.includes('isc-inventory-adjustment-delete') ? '' : 'disabled'].join(' ')}
                                            title={this.state?.adjustmentViewData?.can_delete == "N" && !this.props.permissions.includes('isc-inventory-adjustment-delete') ? 'This action cannot be performed' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Delete</a>
                                    </li>
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.alllocationListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.adjustmentViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    alllocationListingTableJsx            =   ()   =>   {
        return(<Ax>
        <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.adjustmentSearchClear} /> 
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Inventory Adjustment</th>
                        </tr>)
                        : (<tr className='text-center'>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "10%" }}>Date</th>
                            <th scope="col" style={{ width: "10%" }}> Transaction ID</th>
                            <th scope="col" style={{ width: "25%" }} className='text-start'>Warehouse</th>
                            <th scope="col" style={{ width: "10%" }}>Total Qty Added</th>
                            <th scope="col" style={{ width: "10%" }}>Total Qty Subtracted</th>
                            <th scope="col" style={{ width: "10%" }} >Status</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="8"><Loader /></td></tr>)
                        : (this.state.adjustment_listing && this.state.adjustment_listing.length > 0
                            ? (this.state.adjustment_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewAdjustmentDetail(item.transaction_id)}>{item.transaction_id ? <div>{item.transaction_id} ({item.transaction_date_display}) </div> : "-"}  </a>
                                                        <div className="mt-1">
                                                            <small> <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>{item.warehouse?.name ?? "-"}</span> </small>
                                                            <small className="float-end">{item.status_data ? item.status_data.name : "-"}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-center'>{item.transaction_date_display}</td>
                                                <td className='text-center'><a role="button" className="link-primary" onClick={() => this.viewAdjustmentDetail(item.transaction_id)}>{item.transaction_id ? item.transaction_id : "-"}</a></td>
                                                <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                    : "-"}</td>
                                                
                                                <td className='text-center'>{item.qty_movement_in ? item.qty_movement_in : ""} </td>
                                                <td className='text-center'>{item.qty_movement_out ? item.qty_movement_out : ""}</td>
                                                <td className='text-center'>{item.status_data ? item.status_data.name : "-"}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewAdjustmentDetail(item.transaction_id)}>View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="8" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    adjustmentViewDetails                =   ()   =>   {
        let adjustmentData = this.state.adjustmentViewData;
        let total_add       =   0;
        let total_subtact   =   0;

        if (adjustmentData && adjustmentData.items && adjustmentData.items.length > 0) {
            adjustmentData.items.forEach((item, key) => {
                if (item && item.qty &&  !item.qty.includes('-')) {
                    total_add += Number(item.qty);
                }
                if (item && item.qty &&  item.qty.includes('-')) {
                    total_subtact += Number(item.qty);
                }
            });
        }
        return (<div className="tab_content_header">
            {!this.state.view_loading
                ? (<Ax>
                    {adjustmentData
                        ? <div>
                            <InventoryAdjustmentDetail adjustmentData={this.state.adjustmentViewData} />

                            <hr />

                            <div>
                                <div className="tab_content_wrapper" ref={this.myRef}>
                                    <span className="content_heading">Comments & History</span>
                                </div>
                                {this.state.allAllowedStatus
                                    ? <form onSubmit={this.submitUpdateCommentForm}>
                                        <div className="my-3 row">
                                            <label className="col-sm-3 col-form-label">Change status to</label>
                                            <div className="col-sm-5">
                                                <TapSelect
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                                    }}
                                                    value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                                    options={this.state.allAllowedStatus}
                                                    isSearchable={false}
                                                    isDisabled={adjustmentData.can_edit == "N" || !this.props.permissions.includes('isc-inventory-adjustment-status-change') ? true : false}
                                                    placeholder="Please Select Status"
                                                />
                                            </div>
                                        </div>
                                        <div className="my-2">
                                            <label className="form-label">Comments</label>
                                            <textarea
                                                name="notes"
                                                value={this.state.addCommentForm.notes}
                                                onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                                className="form-control"
                                                placeholder="Please Add a comment"
                                                required
                                                style={{ height: "100px" }}
                                            />
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                                Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                            </button>
                                        </div>
                                    </form>
                                    : null}
                            </div>
                            <hr />
                            <div className="my-1">
                                {
                                    adjustmentData.comments && adjustmentData.comments.length > 0 ? adjustmentData.comments.map((c, k) => {
                                        return (<Ax key={k}><div className="row">
                                            {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                            <div className="col-sm-4 text-end">
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {c.created_at_display}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div><hr /></Ax>);
                                    })
                                        : null
                                }
                            </div>
                        </div>
                        : null}
                </Ax>)
                : <Loader />}
        </div>)
    }

    adjustmentSearchModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="adjustmentSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Inventory Adjustment Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.adjustmentSearchClear}></button>
                        </div>
                        <form id="adjustmentSearchForm" onSubmit={this.submitAdjustmentSearchForm}>
                            <div className="modal-body">
                                {!this.state.adjustmentSearchFormDataLoaded ? <Loader />
                                    : <Ax>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_like_transaction_id"
                                                    type="text"
                                                    value={this.state.adjustmentSearchForm.search_like_transaction_id}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "adjustmentSearchForm")}
                                                    placeholder="Please enter Transaction ID"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Status</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allStatusOptions}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'adjustmentSearchForm', 'search_status_ids')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}

                                                    value={this.state.allStatusOptions.filter(s => this.state.adjustmentSearchForm.search_status_ids.includes(s.value))}
                                                    placeholder="Select Status"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Warehouse</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.all_warehouses}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'adjustmentSearchForm', 'search_warehouse_ids')}
                                                    value={this.state.all_warehouses.filter(u => this.state.adjustmentSearchForm?.search_warehouse_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Warehouses"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                            <div className="col-sm-10">
                                                <InputItemSearch
                                                    placeholder="Search Item"
                                                    isMulti={true}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'adjustmentSearchForm', 'search_item_ids');
                                                        this.setState({
                                                            searchedItems: selectedOption ? selectedOption : null
                                                        }, () => { });
                                                    }}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state.searchedItems.length > 0 ? this.state.searchedItems.map(a => { a.label = a.display_label; return a; }) : []}
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Item Tags</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allItemTags}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'adjustmentSearchForm', 'search_item_tags')}
                                                    value={this.state.allItemTags.filter(u => this.state.adjustmentSearchForm?.search_item_tags?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Item Tags"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                           
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Batch Number</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_item_batch_number"
                                                    type="text"
                                                    value={this.state.adjustmentSearchForm.search_item_batch_number}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "adjustmentSearchForm")}
                                                    placeholder="Please enter Item Batch Number"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Manufacturer</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.manufacturers}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'adjustmentSearchForm', 'search_manufacturer_ids')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}

                                                    value={this.state.manufacturers.filter(s => this.state.adjustmentSearchForm.search_manufacturer_ids.includes(s.value))}
                                                    placeholder="Select Manufacturer"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                            <div className="col-sm-10">
                                                <PeriodSelectionWithCustom
                                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                                    value={this.state.adjustmentSearchForm.date_range}
                                                    startDate={this.state.adjustmentSearchForm.date_range_start}
                                                    endDate={this.state.adjustmentSearchForm.date_range_end}
                                                    onSelectPeriod={(period, startDate, endDate) => {
                                                        this.setState({
                                                            adjustmentSearchForm
                                                                : {
                                                                ...this.state.adjustmentSearchForm,
                                                                date_range: period,
                                                                date_range_start: startDate ? startDate : null,
                                                                date_range_end: endDate ? endDate : null
                                                            }
                                                        });
                                                    }}
                                                    className="form-control-sm"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>

                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.indentClear}>Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

   
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Inventory Adjustment Listing</title></Helmet>
            {this.alllocationListjsx()}
            {this.adjustmentSearchModalJsx()}
        
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(InventoryAdjustmentList);