import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import { ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import { toast } from "react-toastify";
import * as actions from "../../../store/actions/index"

class ReportQuickNavigation extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initReportNavigation       =       {
            report_url                  :       "", 
        }
        this.state                      =     {
            report_listing              :     [],
            searchedReportForm          :     {...this.initReportNavigation}
        }

        this.report_url         =   ORG_API_BASE_URL_2 + '/reports';
    }

    componentDidMount()         {
        if(this.props.report_listing && this.props.report_listing.length > 0){
            this.setState({
                report_listing: this.props.report_listing.filter((r) => {
                    return r.view_access === 'Y';}).map(val => { return {value: val.key, label: val.name} })
            });
        }else{
        this.getReportListing()
        }
    }

    //****************************GET REPORT LISTING********************* */
    getReportListing            =           ()          =>      {
        HttpAPICall.withAthorization('GET', this.report_url, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                report_listing: respData && respData.length > 0 && respData.filter((r) => {
                    return r.view_access === 'Y';}).map(val => { return {value: val.key, label: val.name} }),
            });
            if(this.props){
                this.props.reportLisitingInit();
                this.props.reportLisitingLoaded(respData);
            }
            
        })
    }

    onNavigationSubmit          =       (e)     =>      {
        e.preventDefault();
        if(this.props){
            this.props.props.history.push('/report/' + this.state.searchedReportForm.report_url)
        }else{
            toast.error("Something went Wrong", { position: toast.POSITION.TOP_RIGHT });
        }
        
    }

    render                      =   ()  =>  {;
       
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h6 className="fw-bold primary_color">REPORTS</h6>
                    </div>
                </div>
                <form onSubmit={this.onNavigationSubmit}>
                    <div className="row my-3">
                        <div className="col-sm-9 pr0">
                        <TapSelect
                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'searchedReportForm', 'report_url') }}
                        options={this.state.report_listing}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.report_listing.length > 0 && this.state.report_listing.find(m => m.value == this.state.searchedReportForm.report_url)}
                        placeholder="Please Select Report"
                    />
                        </div>
                        <div className="col-sm-3 text-end">
                            <button type="submit" className="btn btn-primary" style={{ height: "38px" }}>Go</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        report_listing          :   state.app && state.app.report_listing ? state.app.report_listing : []
    };
};

const mapDispatchToProps = dispatch => {
    return {
        reportLisitingInit           :       ()          =>   dispatch(actions.reportListingInitilization()),
        reportLisitingLoaded         :       (data)      =>   dispatch(actions.reportListingDataLoadSuccessfully(data)),
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(ReportQuickNavigation);