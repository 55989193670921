import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserLinkSites from './UserLinkSites';
import UserLinkAssets from './UserLinkAssets';


class UserList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.userSearchInitState         =   {
            'search_name'                   :   '',
            'search_email'                  :   '',
            'search_mobile'                 :   '',
            'search_site_ids'               :    [],
            'search_status'                 :   [],
            'search_role_ids'               :   []
        };

        this.userSearchFormLabel         =   {
            'search_name'                :   ' Name : ',
            'search_email'               :   'Email : ',
            'search_mobile'              :   'Number : ',
            'search_site_ids'            :   'Sites : ',
            'search_status'              :   'Status : ',
            'search_role_ids'            :   'Roles : '
        };

        this.initEditUserForm           =        {
            first_name               :        '',
            last_name                :        '',
            remarks                  :        '',
            edit_action              :        'basic',
        }

        this.initDeactivateUserForm          =       {
            reason                      :       '',
            action                      :       ''
        }

        this.initChangePasswordForm     =       {
            password                    :       '',
            password_confirmation       :       ''
        }

        this.initEditImpDetailForm        =        {
            email                         :        '',
            mobile                        :        '',
            edit_action                   :        'important',
        }

        this.updateFormInit             =           {
            file                    :         "",
            base64_document         :         null,
            file_name               :         ""
        }

        this.initChangeDesignation        =           {
            designation                   :         ''
        }
       
        this.state                         =     {
            minimizeTable                   :       false,
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            user_listing                    :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            userSearchForm                  :       {...this.userSearchInitState},
            submittedUserSearchForm         :       {...this.userSearchInitState},
            formSearchedElems               :       [],
            iam_user_sites                  :       [],
            allRolesList                    :       [],
            allStatus                       :       [],
            editUserForm                    :       {...this.initEditUserForm},
            userDetailData                  :       null,
            view_loading                    :       false,
            formDataLoaded                  :       false,
            form_data_loading               :       false,
            linkedSitesList                 :       false,
            linked_site_loading             :       false,
            saveLinkedSiteFormSubmitting    :     false,
            linkSiteForm                    :   {...this.initLinkSiteForm},
            linkedSites                     :       [],
            selectedSitesForDelink          :       [],
            deactivateUserForm              :       {...this.initDeactivateUserForm},
            saveLinkedAssetFormSubmitting   :       false,
            searchedAssets                  :       [],
            linkedAssets                    :       [],
            linkedAssetsListLoaded          :       false,
            linkedAssetsList                :       [],
            linked_assets_loading           :       false,
            assetlistingMeta                :       null,
            change_roleform_loading         :       false,
            allAvailableRolesList           :       [],
            roleFormLoaded                  :       false,
            changeRoleForm                  :       {role_id : ''},
            showPassword                    :       false,
            changePasswordForm              :       {...this.initChangePasswordForm},
            editImpDetailForm               :       {...this.initEditImpDetailForm},
            saveDetailFormSubmitting        :       false,
            userBulkUploadFormSubmiting     :       false,
            updateProfileForm               :         {...this.updateFormInit},
            changeDesignationForm           :       {...this.initChangeDesignation},
            saveFormSubmitting              :       false,
            changeDesignationFormData       :       false,
            allDesignationList              :       [],
            change_designation_loading      :       false,
            new_designation                 :       'N'
        };

        this.designationsUrl        =   ORG_API_BASE_URL_2  +   '/setting/designations';
        this.userFormDataUrl        =   ORG_API_BASE_URL_2  +   '/users_form_data';
        this.usersUrl               =   ORG_API_BASE_URL_2  +   '/users';
        this.userDetailUrl          =   ORG_API_BASE_URL_2  +   '/user';
        this.userRoleChangeFormData =   ORG_API_BASE_URL_2  +   '/user_role_change_form_data'; 
        this.inactivateUrl          =   ORG_API_BASE_URL_2  +   '/user/inactivate';
        this.activateUrl            =   ORG_API_BASE_URL_2  +   '/user/activate';
        this.userAssetUrl           =   ORG_API_BASE_URL_2  +   '/user/assets';
        this.userRoleChangeUrl      =   ORG_API_BASE_URL_2  +   '/user_role_change';
        this.userEditUrl            =   ORG_API_BASE_URL_2  +   '/user/edit';
        this.userPassResetUrl       =   ORG_API_BASE_URL_2  +   '/user_password_reset';
        this.userImgEditUrl         =   ORG_API_BASE_URL_2  +   '/user/image_edit';

        this.downloadSampleFileUrl  =   ORG_API_BASE_URL_2  +   '/download_sample_add_user_excel';
        this.userExcelAddUrl        =   ORG_API_BASE_URL_2  +   '/user/excel_add';

    }
    
    
    componentDidMount() {
        this.initializeListData(this.props)
        // this.loadListingTblData(1);

        this.setState({ iam_user_sites: this.props && this.props.iam_user_sites ? this.props.iam_user_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [], })

        this.userSearchModal                    =    new Modal(document.getElementById('userSearchModal'), {keyboard: false, backdrop :false});
        this.editBasicDetailModal               =    new Modal(document.getElementById('editBasicDetailModal'), {keyboard: false, backdrop :false});   
        this.deactivateUserModal                  =   new Modal(document.getElementById('deactivateUserModal'), {keyboard: false, backdrop :false});
        this.changeRoleModal                  =   new Modal(document.getElementById('changeRoleModal'), {keyboard: false, backdrop :false});
        this.changePasswordModal                  =   new Modal(document.getElementById('changePasswordModal'), {keyboard: false, backdrop :false});
        this.editImpDetailModal               =    new Modal(document.getElementById('editImpDetailModal'), {keyboard: false, backdrop :false}); 
        this.userBulkUploadModal                =      new Modal(document.getElementById('userBulkUploadModal'), {keyboard: false, backdrop :false});
        this.profileUpdateModal                =      new Modal(document.getElementById('profileUpdateModal'), {keyboard: false, backdrop :false});
        this.changeDesignationModal            =      new Modal(document.getElementById('changeDesignationModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){

        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initializeListData(nextProps)    
        }
         
    }

    initializeListData          =       (pr)        =>      {
        if(pr){
            this.setState({
                iam_user_sites          :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [], 
            },() => {
                let ParamObject                 =   new URLSearchParams(this.props.location.search);
            
                if(ParamObject.get('search_site_ids')) {
                    
                    let updateFilterForm        =   {search_site_ids : [ParamObject.get('search_site_ids')]};
                    
                    this.setState({userSearchForm :   {...this.state.userSearchForm, ...updateFilterForm},
                                    },() => this.userSearchFormSubmit());
                }else if(ParamObject.get('user_id')){
                   
                    this.loadListingTblData(1);
                    this.viewDetail(ParamObject.get('user_id'))
                
                }else{
                    this.loadListingTblData(1);
                }
                        
            })
        }
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedUserSearchForm};
        HttpAPICall.withAthorization('GET',  this.usersUrl, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                user_listing            :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    addUser          =       ()          =>   {
        this.props.history.push({ pathname: `/user_add` })
    }

    viewDetail      =       (id)      =>      {
        this.setState({
            minimizeTable               :       true,
            linkedAssetsListLoaded      :       false,
            linkedAssetsList            :       []
        })

        if (id) {
            this.getUserDetails(id)
        }
    }

    getUserDetails      =       (id)        =>      {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET', this.userDetailUrl + '/' + id, this.props.access_token, {need_site_ids : 'Y'}, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                userDetailData    :  respData.data,
            });
        
        }).then(() => this.setState({view_loading: false}));
    }


    closeViewDetails    =   ()      =>      {
        this.setState({minimizeTable : false})
        this.props.history.push('/user_list')
    }

    userSearchModalInit        =       ()      =>      {
        this.userSearchModal.show()
        if(!this.state.formDataLoaded){
            this.getUserSearchFormData()
        }
    }

    editBasicDetailModalInit        =   ()      =>      {
        this.editBasicDetailModal.show();
        if(this.state.userDetailData){
            this.setState({
                editUserForm        :       {
                    first_name               :        this.state.userDetailData.first_name,
                    last_name                :        this.state.userDetailData.last_name,
                    remarks                  :        this.state.userDetailData.remarks,
                    edit_action              :        'basic',
                }
            })
        }
        
    }

    deactivateUserModalInit        =   (action = '')      =>      {
        this.deactivateUserModal.show();
        if(this.state.userDetailData){
            this.setState({
                deactivateUserForm      :       {action : action,status_reason : '' }
            })
        }
        
    }

    changeRoleModalInit             =       ()      =>      {
        this.changeRoleModal.show()
        this.setState({changeRoleForm    :       {role_id : ''}})
        if(!this.state.roleFormLoaded){
            this.getchangeRoleFormData()
        }
    }

    changeDesignationModalInit             =       ()      =>      {
        this.changeDesignationModal.show();
        if(this.state.userDetailData){
            this.setState({
               changeDesignationForm        :       {
                    designation                   :        this.state.userDetailData.designation,
                    edit_action                   :        'designation',
                    new_designation               :         'N'
                }
            })
        }
       // this.setState({changeRoleForm    :       {role_id : ''}})
        if(!this.state.changeDesignationFormData){
            this.getchangeDesignationFormData()
        }
    }

    getchangeDesignationFormData      =       (id)        =>      {
        this.setState({change_designation_loading : true})
        HttpAPICall.withAthorization('GET', this.designationsUrl, this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                changeDesignationFormData   :   true,
                allDesignationList         :   respData && respData.length > 0 ? respData.map(oc => { return ({ value: oc, label: oc, }) }) : [],
              
            })
        }).then(() => this.setState({change_designation_loading: false}));
    }

    changePasswordModalInit             =       ()      =>      {
        this.changePasswordModal.show()
        this.setState({changePasswordForm    :       {...this.initChangePasswordForm}})
    }
    
    editImpDetailModalInit        =   ()      =>      {
        this.editImpDetailModal.show();
        if(this.state.userDetailData){
            this.setState({
               editImpDetailForm        :       {
                    email                         :        this.state.userDetailData.email,
                    mobile                        :        this.state.userDetailData.mobile,
                    edit_action                   :        'important',
                }
            })
        }
    
    }

    getchangeRoleFormData      =       (id)        =>      {
        this.setState({change_roleform_loading : true})
        HttpAPICall.withAthorization('GET', this.userRoleChangeFormData,
        this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                roleFormLoaded              :   true,
                allAvailableRolesList       :   respData && respData.roles && respData.roles.length > 0 ? respData.roles.map(oc => { return ({ value: oc.id, label: oc.role, }) }) : [],
              
            })
        }).then(() => this.setState({change_roleform_loading: false}));
    }


    getUserSearchFormData        =       ()      =>  {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.userFormDataUrl,
        this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                formDataLoaded              :   true,
                allRolesList                :   respData && respData.roles && respData.roles.length > 0 ? respData.roles.map(oc => { return ({ value: oc.id, label: oc.role, }) }) : [],
                allStatus                   :   respData && respData.status &&  respData.status.length > 0  ? respData.status.map((s) => { return {value: s.key, label: `${s.name}`}}) : [],
            })
        }).then(() => this.setState({form_data_loading: false}));
    }

    userSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.userSearchForm);
        let searchedElems               =   [];
        let userSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.userSearchFormLabel[key];
            let value                       =   this.state.userSearchForm[key];
            if(value && value.toString().length > 0) {
                userSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_name') {
                        show_val            =   this.state.userSearchForm.search_name;
                    }
                    if(key == 'search_mobile') {
                        show_val            =   this.state.userSearchForm.search_mobile;
                    }
                    if(key == 'search_email') {
                        show_val            =   this.state.userSearchForm.search_email;
                    }
                     if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_role_ids') {
                        show_val            =   this.state.allRolesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.allStatus.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                   
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedUserSearchForm         :   {...this.state.userSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.userSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    userSearchClear         =       ()     =>      {
        this.setState({
            userSearchForm                :   {...this.userSearchInitState},
            submittedUserSearchForm       :   {...this.userSearchInitState},
            formSearchedElems             :   []
        }, () => {
            this.userSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    
    deactivateUserFormSubmit         =       (e)     =>      {
        e.preventDefault();
       if(this.state.deactivateUserForm && this.state.deactivateUserForm.action && this.state.deactivateUserForm.action == 'remove'){
           this.setState({ saveDeactivateFormSubmitting: true })
           HttpAPICall.withAthorization('PUT', this.inactivateUrl + '/' + this.state.userDetailData?.id, this.props.access_token, {}, { status_reason: this.state.deactivateUserForm.status_reason }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.deactivateUserModal.hide();
               this.setState({ deactivateUserForm: { ...this.initDeactivateUserForm } })
               this.getUserDetails(this.state.userDetailData?.id)
               this.loadListingTblData(1)
           }).then(() => this.setState({ saveDeactivateFormSubmitting: false }));
       }else{
            this.setState({ saveDeactivateFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', this.activateUrl + '/' + this.state.userDetailData?.id, this.props.access_token, {}, { status_reason: this.state.deactivateUserForm.status_reason }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.deactivateUserModal.hide();
                this.setState({ deactivateUserForm: { ...this.initDeactivateUserForm } })
                this.getUserDetails(this.state.userDetailData?.id)
                this.loadListingTblData(1)
            }).then(() => this.setState({ saveDeactivateFormSubmitting: false }));
       }

    }

    getLinkedAssetList      =       (id,page=1)        =>      {
       
        if (!this.state.linkedAssetsListLoaded) {
            this.setState({ linked_assets_loading: true })
            HttpAPICall.withAthorization('GET', this.userAssetUrl + '/' + id, this.props.access_token, { page: page }, {}, (resp) => {
                let respData = resp.data;

                this.setState({
                    linkedAssetsList        :       respData.data,
                    linkedAssets            :       respData.data.map(s => s.id),
                    linkedAssetsListLoaded  :       true,
                    assetlistingMeta        :       respData.meta
                });

            }).then(() => this.setState({ linked_assets_loading: false }));
        }
        
    }

    changeRoleFormSubmit        =       (e)     =>      {
        e.preventDefault();
        this.setState({ saveRoleFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.userRoleChangeUrl, this.props.access_token,{}, {user_id : this.state.userDetailData?.id,...this.state.changeRoleForm}, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.changeRoleModal.hide();
            this.setState({ changeRoleForm: {role_id : ''} });
           this.loadListingTblData(1);
          this.getUserDetails(this.state.userDetailData?.id)
        }).then(() => this.setState({ saveRoleFormSubmitting: false }));
    }

    changeDesignationFormSubmit        =       (e)     =>      {
        e.preventDefault();
        this.setState({ saveRoleFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.userEditUrl, this.props.access_token,{}, {user_id : this.state.userDetailData?.id,...this.state.changeDesignationForm}, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.changeDesignationModal.hide();
            this.setState({ changeDesignationForm: {...this.initChangeDesignation} ,new_designation:'N'});
           this.loadListingTblData(1);
          this.getUserDetails(this.state.userDetailData?.id)
        }).then(() => this.setState({ saveRoleFormSubmitting: false }));
    }

    changePasswordFormSubmit        =       (e)     =>      {
        e.preventDefault();
    
        if(this.state.changePasswordForm.password == this.state.changePasswordForm.password_confirmation){
            this.setState({ savePasswordFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', this.userPassResetUrl, this.props.access_token, {}, { user_id: this.state.userDetailData?.id, ...this.state.changePasswordForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.changePasswordModal.hide();
                this.setState({ changePasswordForm: { ...this.initChangePasswordForm } });
            }).then(() => this.setState({ savePasswordFormSubmitting: false }));
        }else{
            toast.error('The password confirmation does not match.', { position: toast.POSITION.TOP_RIGHT });
        }
        
    }

    editBasicDetaiFormSubmit        =   (e)     =>      {
        e.preventDefault();
        this.setState({ saveDetailFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.userEditUrl, this.props.access_token, {}, { user_id: this.state.userDetailData?.id, ...this.state.editUserForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.editBasicDetailModal.hide();
            this.getUserDetails(this.state.userDetailData?.id)
            this.loadListingTblData(1)
            this.setState({ editUserForm: { ...this.initEditUserForm } });
        }).then(() => this.setState({ saveDetailFormSubmitting: false }));
    }

    editImpDetaiFormSubmit        =   (e)     =>      {
        e.preventDefault();
        this.setState({ saveDetailFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.userEditUrl, this.props.access_token, {}, { user_id: this.state.userDetailData?.id, ...this.state.editImpDetailForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.editImpDetailModal.hide();
            this.getUserDetails(this.state.userDetailData?.id)
            this.loadListingTblData(1)
            this.setState({ editImpDetailForm: { ...this.initEditImpDetailForm } });
        }).then(() => this.setState({ saveDetailFormSubmitting: false }));
    }

    userBulkUploadModalInit               =   ()  =>  {
        document.getElementById('addUserFileSelector').value = null;
        
        this.userBulkUploadModal.show();
    }

    userBulkUploadFormSubmit            =   (event) => {
        event.preventDefault();
        this.setState({userBulkUploadFormSubmiting : true});
        const data          =   new FormData(document.getElementById('userBulkUploadForm'));
        let headers         =   { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data','Authorization' : 'Bearer ' + this.props.access_token };
        axios.post(this.userExcelAddUrl, data, { headers: headers })
        .then((response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.userBulkUploadModal.hide();
            //Load Add Item Form Data :-
            
            download.file(response.data.generated_file.file_path);
            setTimeout(() => {
                this.loadListingTblData(1);
            },1000)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to upload User Add File.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({userBulkUploadFormSubmiting : false});
        });
    }

    downloadSampleFile                  =   ()      => {
        HttpAPICall.withAthorization('GET', this.downloadSampleFileUrl, this.props.access_token,
        {} , {} , (response) => 
        {   download.file(response.data.file_path)
            toast.success('File Downloaded Successfully', {position: toast.POSITION.TOP_RIGHT})
            this.userBulkUploadModal.hide();
        })
    }

    submitUpdateForm        =       (e)     =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.userImgEditUrl, this.props.access_token, {}, { user_id: this.state.userDetailData?.id, ...this.state.updateProfileForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.profileUpdateModal.hide();
            this.getUserDetails(this.state.userDetailData?.id)
            this.setState({ updateProfileForm: { ...this.updateFormInit } });
        }).then(() => this.setState({ saveFormSubmitting : false }));
    }

    updateProfileInitHandler           =   (action)   =>  {
        this.profileUpdateModal.show();
        let form = document.getElementById(`profileUpdateForm`)
        form.reset();
        this.setState({
            updateProfileForm: {
                ...this.updateFormInit,
                
            }
        })
    }

    handleFileChange = async  (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64_document = await this.convertBase64(file);
        this.setState({updateProfileForm : { ...this.state.updateProfileForm ,base64_document : base64_document,file_name:filename}})
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    deleteFile          =       ()          =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('PUT', this.userImgEditUrl, this.props.access_token, {remove_image : "Y",user_id: this.state.userDetailData?.id },{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getUserDetails(this.state.userDetailData?.id)
                    }).then(() => {

                    });
                }
            });
    }

    checkEditPermission     =       (permission,action='')      =>  {
        let can_edit 		=	false ;
        let userDetail = this.state.userDetailData;
        if(this.props?.permissions?.permissions?.includes(permission)) {
            if(this.props.user_type == 'g') {
                can_edit  = this.state.userDetailData && this.state.userDetailData.user_type == 'g' &&  action ? false : true
                
            } else if(this.props.user_type  == 'gu') {
                can_edit 	= userDetail && userDetail.user_type && userDetail.user_type == 'g'  ? false : true;
            }
        }
        return can_edit;

        
    }

    
    //***********************User JSX****************

    userListjsx            =       ()      =>    {
      
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>User List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                            <button type="button" className="btn btn-primary" disabled={!this.props?.permissions?.permissions?.includes('grp-user-add')} onClick={() => this.addUser()}>Add User </button>
                            <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.excelDownloading == true}>
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><Link className="dropdown-item" onClick={this.userBulkUploadModalInit} role="button" >Bulk Upload</Link></li>
                                </ul>
                            </span>
                             <button type="button" disabled={this.state.listing_loading} onClick={this.userSearchModalInit} className="btn btn-secondary" >
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                        </button>
                       
                        </Ax>
                        : null
                    }
                   
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>User : {this.state.userDetailData && this.state.userDetailData.full_name ? this.state.userDetailData.full_name : "-"} </h3>
                            <div className="text-end mt15">
                                <span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li> <Link role="button"
                                            onClick={() => {
                                                if (this.checkEditPermission('grp-user-edit')) {
                                                    return this.editBasicDetailModalInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.checkEditPermission('grp-user-edit') ? '' : 'disabled'].join(' ')}
                                            title={this.checkEditPermission('grp-user-edit') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        >Edit Basic Details</Link>
                                        </li>

                                        <li> <Link role="button"
                                            onClick={() => {
                                                if (this.checkEditPermission('grp-user-edit')) {
                                                    return this.editImpDetailModalInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.checkEditPermission('grp-user-edit') ? '' : 'disabled'].join(' ')}
                                            title={this.checkEditPermission('grp-user-edit') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        >Edit Imp Details</Link>
                                        </li>
                                        <li> <Link role="button"
                                            onClick={() => {
                                                if (this.checkEditPermission('grp-user-role','role')) {
                                                    return this.changeRoleModalInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.checkEditPermission('grp-user-role','role') ? '' : 'disabled'].join(' ')}
                                            title={this.checkEditPermission('grp-user-role','role') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        >Change Role</Link>
                                        </li>
                                        <li> <Link role="button"
                                            onClick={() => {
                                                if (this.checkEditPermission('grp-user-edit')) {
                                                    return this.changeDesignationModalInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.checkEditPermission('grp-user-edit') ? '' : 'disabled'].join(' ')}
                                            title={this.checkEditPermission('grp-user-edit') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        >Change Designation</Link>
                                        </li>
                                        <li> <Link role="button"
                                            onClick={() => {
                                                if (this.checkEditPermission('grp-user-edit')) {
                                                    return this.changePasswordModalInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.checkEditPermission('grp-user-edit') ? '' : 'disabled'].join(' ')}
                                            title={this.checkEditPermission('grp-user-edit') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        >Change Password</Link>
                                        </li>

                                        {this.state.userDetailData && this.state.userDetailData.status && this.state.userDetailData.status !== '2' ?

                                            <li>
                                                <Link role="button"
                                                    onClick={() => {
                                                        if (this.checkEditPermission('grp-user-delete','remove')) {
                                                            return this.deactivateUserModalInit('remove')
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.checkEditPermission('grp-user-delete','remove') ? '' : 'disabled'].join(' ')}
                                                    title={this.checkEditPermission('grp-user-delete','remove') ? '' : 'You do not have permission to perform this action'}
                                                    style={{ "pointerEvents": "all" }}
                                                >Remove User</Link>


                                            </li>

                                            : <li>
                                                <Link role="button"
                                                    onClick={() => {
                                                        if (this.checkEditPermission('grp-user-add')) {
                                                            return this.deactivateUserModalInit('add')
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.checkEditPermission('grp-user-add') ? '' : 'disabled'].join(' ')}
                                                    title={this.checkEditPermission('grp-user-add') ? '' : 'You do not have permission to perform this action'}
                                                    style={{ "pointerEvents": "all" }}
                                                >Activate User</Link>

                                            </li>
                                        }
                                        <li>
                                            <Link role="button"
                                                onClick={() => {
                                                    if (this.checkEditPermission('grp-user-edit')) {
                                                        return this.updateProfileInitHandler()
                                                    }
                                                }}
                                                className={['dropdown-item', this.checkEditPermission('grp-user-edit') ? '' : 'disabled'].join(' ')}
                                                title={this.checkEditPermission('grp-user-edit') ? '' : 'You do not have permission to perform this action'}
                                                style={{ "pointerEvents": "all" }}
                                            >Edit Profile Photo</Link>

                                        </li>
                                        <li>
                                            <Link role="button"
                                                onClick={() => {
                                                    if (this.checkEditPermission('grp-user-edit')) {
                                                        return this.deleteFile()
                                                    }
                                                }}
                                                className={['dropdown-item', this.checkEditPermission('grp-user-edit') ? '' : 'disabled'].join(' ')}
                                                title={this.checkEditPermission('grp-user-edit') ? '' : 'You do not have permission to perform this action'}
                                                style={{ "pointerEvents": "all" }}
                                            >Delete Profile Photo</Link>


                                        </li>

                                    </ul>
                                </span>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.userListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.userViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    userListingTableJsx            =   ()   =>   {
        return (<div className="bg-white" style={{ height: "100%" }}>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.userSearchClear}   /> 
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>User</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                            <th scope="col" style={{ width: "16%" }}>Name</th>
                            <th scope="col" style={{ width: "13%" }}>Role</th>
                            <th scope="col" style={{ width: "13%" }}>Designation</th>
                            <th scope="col" style={{ width: "9%" }}>Mobile Number</th>
                            <th scope="col" style={{ width: "19%" }}>Email</th>
                            <th scope="col" style={{ width: "16%" }}> Linked Sites</th>
                            <th scope="col" style={{ width: "10%" }} className='text-center'>Status</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.user_listing.length > 0
                            ? (this.state.user_listing.map((user, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewDetail(user.id)} >
                                                        <div className="text-capitalize link-primary cursor_pointer">{user.full_name ? user.full_name : "-"}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{user.role && user.role.role ? user.role.role :   "-"} </small>
                                                            <small className="float-end"><Status color={user.status_color_code}>{user.status_text}</Status></small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewDetail(user.id)}>{user.full_name ? user.full_name : "-"}</td>
                                               <td>{user.role && user.role.role ? user.role.role :   "-"}</td>
                                               <td>{user.designation ? user.designation :   "-"}</td>
                                               <td>{user.mobile ? user.mobile : "-"}</td>
                                               <td>{user.email ? user.email : "-"}</td>
                                               <td>{user.sites_name_display ? user.sites_name_display : "-"}</td>
                                                <td><Status color={user.status_color_code}>{user.status_text}</Status></td>
                                                <td className="text-center">

                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li>  <a className='dropdown-item' onClick={() => this.viewDetail(user.id)}>View</a>   </li>
                                                     </ul></td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </div>)
    }

    userViewDetails                =   ()   =>   {
       let detail = this.state.userDetailData;
        return (<section className="page_containt " style={{ top: "-2px" }}>
            <div className="pageTbl bg-white">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                            type="button" role="tab" aria-controls="details" aria-selected="true">Details
                        </button>
                    </li>
                    {this.props && this.props.permissions && this.props.permissions.permissions && this.props.permissions.permissions.includes('iam-site-user-linking') && this.state?.userDetailData?.user_type !== 'g' 
                        ? <li className="nav-item" role="presentation">
                            <button className="nav-link " id="linking-tab" data-bs-toggle="tab" data-bs-target="#linking"
                                type="button" role="tab" aria-controls="linking" aria-selected="true">Site Linking
                            </button>
                        </li>

                        : null}
                    {detail && detail.role && detail.role.visibility == "asset" && this.props && this.props.permissions && this.props.permissions.permissions && this.props.permissions.permissions.includes('iam-asset-user-linking')
                        ? <li className="nav-item" role="presentation">
                            <button className="nav-link " id="assetlinking-tab" data-bs-toggle="tab" data-bs-target="#assetlinking"
                                type="button" role="tab" aria-controls="assetlinking" aria-selected="true" onClick={() => this.getLinkedAssetList(this.state.userDetailData?.id)}>Asset Linking
                            </button>
                        </li>
                        : null}

                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane  show active" id="details" role="tabpanel"
                        aria-labelledby="details-tab">

                        {this.state.view_loading ? <Loader />
                            : <div className="tab_content_header">
                                {/* <div className="tab_content_wrapper"> <span className="content_heading"> Detail</span></div> */}
                                {detail ?
                                    <div className='row'>
                                        <div className='col-sm-9'>
                                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Name</td>
                                                        <th style={{ width: "45%" }}>{detail.full_name ? detail.full_name : "-"}</th>

                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Status</td>
                                                        <th>{detail.status ? <Status color={detail.status_color_code}>{detail.status_text}</Status> : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Role</td>
                                                        <th>{detail.role && detail.role.role ? detail.role.role : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Role visibility</td>
                                                        <th className='text-capitalize'>{detail.role && detail.role.visibility ? detail.role.visibility : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Designation	</td>
                                                        <th>{detail.designation ? detail.designation : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Email ID	</td>
                                                        <th>{detail.email ? detail.email : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Mobile Number</td>
                                                        <th>{detail.mobile ? detail.mobile : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Remarks</td>
                                                        <th>{detail.user_detail && detail.user_detail.remarks ? detail.user_detail.remarks : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "25%" }}>Last Logged In</td>
                                                        <th>{detail.last_login && detail.last_login.last_login_display ? detail.last_login.last_login_display : "-"}</th>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='col-sm-3'>
                                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                                <tbody>
                                                    <tr>
                                                        <th className="text-center"><img src={detail?.image} className="img_fluid" alt="profile_img" style={{ height: "120px" }} /> </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name :  {detail.full_name ? detail.full_name : "-"} </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Status :  {detail.status ? <Status color={detail.status_color_code}>{detail.status_text}</Status> : "-"} </th>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    : null}
                            </div>}
                    </div>
                    <div className="tab-pane" id="linking" role="tabpanel" aria-labelledby="linking-tab">
                        <div className="tab_content_header">
                            <UserLinkSites
                                userDetailData={this.state.userDetailData}
                                afterLinkingSiteSubmit={() => {
                                    this.loadListingTblData(1); this.getUserDetails(this.state.userDetailData?.id)
                                }}
                            />
                        </div>
                    </div>
                    {detail && detail.role && detail.role.visibility == "asset"
                        ? <div className="tab-pane" id="assetlinking" role="tabpanel"
                            aria-labelledby="assetlinking-tab">
                            <div className="tab_content_header">
                                <UserLinkAssets
                                    userDetailData={this.state.userDetailData}
                                    linkedAssets ={this.state.linkedAssets}
                                    linkedAssetsList    =   {this.state.linkedAssetsList}
                                    assetlistingMeta    =   {this.state.assetlistingMeta}
                                    afterLinkingAssetSubmit={() => {
                                        this.setState({linkedAssetsListLoaded : false},() => {
                                            this.getLinkedAssetList(this.state.userDetailData?.id)
                                        })
                                       
                                    }}
                                    onPageChange={(page) => 
                                        this.setState({linkedAssetsListLoaded : false},() => {
                                        this.getLinkedAssetList(this.state.userDetailData?.id,page)
                                    })}
                                />
                            </div>
                        </div>
                        : null}

                </div>
            </div>
        </section>)
    }

    userSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="userSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="userSearchModalLabel">User Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.userSearchFormSubmit} id="userSearchForm">
                            {this.state.form_data_loading ? <Loader/>
                            : <div className="modal-body">
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        name="search_name"
                                        className="form-control form-control-sm"
                                        placeholder="Enter user Name"
                                        autoComplete="off"
                                        value={this.state.userSearchForm.search_name}
                                        onChange={(e) => this.formInputHandler(e, "userSearchForm")}
                                    />
                                </div>

                            </div>
                          
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Mobile Number</label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        name="search_mobile"
                                        className="form-control form-control-sm"
                                        placeholder="Enter user Number"
                                        autoComplete="off"
                                        value={this.state.userSearchForm.search_mobile}
                                        onChange={(e) => this.formInputHandler(e, "userSearchForm")}
                                    />
                                </div>

                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Email ID</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        name="search_email"
                                        className="form-control form-control-sm"
                                        placeholder="Enter User Email"
                                        autoComplete="off"
                                        value={this.state.userSearchForm.search_email}
                                        onChange={(e) => this.formInputHandler(e, "userSearchForm")}
                                    />
                                </div>

                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Site</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.iam_user_sites}
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'userSearchForm', 'search_site_ids');
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        menuPlacement="bottom"
                                        value={this.state.iam_user_sites.filter(s => this.state.userSearchForm.search_site_ids.includes(s.value))}
                                        placeholder="Select Sites"
                                        containerHeight="30px"
                                        fontSize="93%"
                                        isMulti={true}
                                    />
                                </div>

                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm"> Roles</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allRolesList}
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'userSearchForm', 'search_role_ids');
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        menuPlacement="bottom"
                                        value={this.state.allRolesList.filter(s => this.state.userSearchForm.search_role_ids.includes(s.value))}
                                        placeholder="Select Roles"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>

                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm">Status</label>
                                <div className="col-sm-9">
                                    <TapSelect
                                        options={this.state.allStatus}
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'userSearchForm', 'search_status');
                                        }}
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        menuPlacement="bottom"
                                        value={this.state.allStatus.filter(s => this.state.userSearchForm.search_status.includes(s.value))}
                                        placeholder="Select Status"
                                        containerHeight="30px"
                                        fontSize="93%"
                                    />
                                </div>

                            </div>


                        </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    editBasicDetailModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="editBasicDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editBasicDetailModalLabel">Edit Basic Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.editBasicDetaiFormSubmit} id="editBasicDetailForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">First Name</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="first_name"
                                            type="text"
                                            value={this.state.editUserForm.first_name}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "editUserForm")}
                                            placeholder="Please enter First Name"
                                            required={true}
                                            maxLength={200}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Last Name</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="last_name"
                                            type="text"
                                            value={this.state.editUserForm.last_name}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "editUserForm")}
                                            placeholder="Please enter Last Name"
                                            maxLength={200}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Remarks</label>
                                    <div className="col-sm-7">
                                        <textarea
                                            name="remarks"
                                            value={this.state.editUserForm.remarks}
                                            style={{ height: "80px" }}
                                            className="form-control"
                                            autoComplete="off"
                                            maxLength={500}
                                            onChange={(e) => this.formInputHandler(e, "editUserForm")}
                                            placeholder="Please enter Remarks"
                                        />
                                    </div>

                                </div>
                              
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveDetailFormSubmitting} data-bs-dismiss="modal" >Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveDetailFormSubmitting} >Save {this.state.saveDetailFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    deactivateUserModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="deactivateUserModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deactivateUserModalLabel">{this.state.deactivateUserForm && this.state.deactivateUserForm.action && this.state.deactivateUserForm.action == 'remove' ? 'Remove' : 'Activate'} User</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Name   </td>
                                        <th>{this.state.userDetailData ? `${this.state.userDetailData.full_name}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Role </td><th>{this.state.userDetailData && this.state.userDetailData.role ? `${this.state.userDetailData.role.role} ` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Status  </td>
                                        <th>{this.state.userDetailData ? <Status color={this.state.userDetailData.status_color_code}>{this.state.userDetailData.status_text}</Status> : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.deactivateUserFormSubmit} id="deactivateUserForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Reason</label>
                                    <div className="col-sm-9">
                                        <textarea
                                            name="status_reason"
                                            value={this.state.deactivateUserForm.status_reason}
                                            onChange={(e) => this.formInputHandler(e, 'deactivateUserForm')}
                                            className="form-control"
                                            style={{ height: "100px" }}
                                            required={true}
                                        />
                                    </div>

                                </div>
                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveDeactivateFormSubmitting} data-bs-dismiss="modal" onClick={() => {this.setState({ deactivateUserForm   :   {...this.initDeactivateUserForm}})}}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveDeactivateFormSubmitting} >Save {this.state.saveDeactivateFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    changeRoleModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="changeRoleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changeRoleModalLabel">Change Role</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Name   </td>
                                        <th>{this.state.userDetailData ? `${this.state.userDetailData.full_name}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Current Role </td><th>{this.state.userDetailData && this.state.userDetailData.role ? `${this.state.userDetailData.role.role} ` : "-"}</th>
                                    </tr>
                                    <tr>
                                 3       <td style={{ width: "26%" }}> Status  </td>
                                        <th>{this.state.userDetailData ? <Status color={this.state.userDetailData.status_color_code}>{this.state.userDetailData.status_text}</Status> : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.changeRoleFormSubmit} id="changeRoleForm">
                           {this.state.change_roleform_loading ? <Loader/>
                           :  <div className="modal-body">
                           <div className="row mb-3">
                               <label className="col-sm-3 col-form-label col-form-label-sm require">Role</label>
                               <div className="col-sm-9">
                                   <TapSelect
                                       options={this.state.allAvailableRolesList}
                                       changeEvent={(selectedOption) => {
                                           this.tapSelectChange(selectedOption, 'changeRoleForm', 'role_id');
                                       }}
                                       isSearchable={true}
                                       isClearable={true}
                                       menuPlacement="bottom"
                                       value={this.state.allAvailableRolesList.filter(s => this.state.changeRoleForm.role_id == s.value)}
                                       placeholder="Select Roles"
                                       containerHeight="30px"
                                       fontSize="93%"
                                   />
                               </div>

                           </div>
                          
                       </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveRoleFormSubmitting} data-bs-dismiss="modal" onClick={() => {this.setState({ changeRoleForm   :   {role_id : ''}})}}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveRoleFormSubmitting} >Save {this.state.saveRoleFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    changePasswordModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="changePasswordModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changePasswordModalLabel">Change Password</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Name   </td>
                                        <th>{this.state.userDetailData ? `${this.state.userDetailData.full_name}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Role </td><th>{this.state.userDetailData && this.state.userDetailData.role ? `${this.state.userDetailData.role.role} ` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Status  </td>
                                        <th>{this.state.userDetailData ? <Status color={this.state.userDetailData.status_color_code}>{this.state.userDetailData.status_text}</Status> : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.changePasswordFormSubmit} id="changePasswordForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">New Password</label>
                                    <div className="col-sm-6">
                                        <input
                                           type="password"
                                            name="password"
                                            value={this.state.changePasswordForm.password}
                                            onChange={(e) => this.formInputHandler(e, 'changePasswordForm')}
                                            className="form-control form-control-sm"
                                            required={true}
                                            autoComplete="off"
                                            minLength={4}
                                            maxLength={50}
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Confirm Password</label>
                                    <div className="col-sm-6">
                                        <input
                                           type={this.state.showPassword == true ? 'text' : 'password'}
                                            name="password_confirmation"
                                            value={this.state.changePasswordForm.password_confirmation}
                                            onChange={(e) => this.formInputHandler(e, 'changePasswordForm')}
                                            className="form-control form-control-sm"
                                            required={true}
                                            minLength={4}
                                            maxLength={50}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="showPassword"
                                                type="checkbox"
                                                value={true}
                                                 onChange={(e) => {
                                                      if(e.target && e.target.checked && e.target.checked == true ){
                                                        this.setState({showPassword : true})
                                                      }else{
                                                        this.setState({showPassword : false})
                                                      }
                                                  }}
                                                className="form-check-input"
                                                id="show_password"
                                            />
                                            <label className="form-check-label" htmlFor="show_password">Show Password</label>
                                        </div>
                                    </div>

                                </div>
                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.savePasswordFormSubmitting} data-bs-dismiss="modal" onClick={() => {this.setState({ deactivateUserForm   :   {...this.initDeactivateUserForm}})}}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.savePasswordFormSubmitting} >Save {this.state.savePasswordFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    editImpDetailModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="editImpDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editBasicDetailModalLabel">Edit Imp Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.editImpDetaiFormSubmit} id="editImpDetailForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Email</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="email"
                                            type="email"
                                            value={this.state.editImpDetailForm.email}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "editImpDetailForm")}
                                            placeholder="Please enter Email"
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm require">Phone Number</label>
                                    <div className="col-sm-7">
                                        <input
                                            name="mobile"
                                            type="number"
                                            value={this.state.editImpDetailForm.mobile}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            required={true}
                                            maxLength={10}
                                            onChange={(e) => this.formInputHandler(e, "editImpDetailForm")}
                                            placeholder="Please enter Phone Number"
                                        />
                                    </div>

                                </div>
                               
                              
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveDetailFormSubmitting} data-bs-dismiss="modal" >Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveDetailFormSubmitting} >Save {this.state.saveDetailFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    userBulkUploadModalJsx            =   ()  =>  {
        return (
            <div className="modal fade" id="userBulkUploadModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="itemBulkUploadModalLabel">Bulk Add User</h5>
                            <button type="button" disabled={this.state.userBulkUploadFormSubmiting} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.userBulkUploadFormSubmit} id="userBulkUploadForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-12">
                                        <input
                                            name="document"
                                            id="addUserFileSelector"
                                            type="file"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 mt-2 form-text"># Excel Sheet with Extention xls,xlm,xlsx,ods Allowed</div>
                                    <div className="col-12 mt-2 form-text"># Miximum 100 MB Size of Excel Sheet Allowed</div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <div><a type="button" className='float-left' href='#' onClick={this.downloadSampleFile}>Download Sample File</a></div>
                                <div>
                                    <button type="button" disabled={this.state.userBulkUploadFormSubmiting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.userBulkUploadFormSubmiting} className="btn btn-primary">
                                        Submit {this.state.userBulkUploadFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    profileUpdateModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="profileUpdateModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Update Profile</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="profileUpdateForm" onSubmit={this.submitUpdateForm}>
                            <div className="modal-body">

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label">Profile</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="file"
                                            type="file"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                this.handleFileChange(e)
                                            }}

                                            placeholder="Please upload file"

                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Update {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    changeDesignationModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="changeDesignationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changeDesignationModalLabel">Change Designation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Name   </td>
                                        <th>{this.state.userDetailData ? `${this.state.userDetailData.full_name}` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Current Designation</td><th>{this.state.userDetailData && this.state.userDetailData.designation ? `${this.state.userDetailData.designation} ` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}> Status  </td>
                                        <th>{this.state.userDetailData ? <Status color={this.state.userDetailData.status_color_code}>{this.state.userDetailData.status_text}</Status> : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <form onSubmit={this.changeDesignationFormSubmit} id="changeDesignationForm">
                            {this.state.change_designation_loading ? <Loader />
                                : <div className="modal-body">
                                    <div className="row align-items-center my-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label">Designation</label>
                                        </div>
                                        <div className="col-sm-7">
                                            {this.state.new_designation == "Y"
                                                ? <input
                                                    name="designation"
                                                    type="text"
                                                    value={this.state.changeDesignationForm.designation}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "changeDesignationForm")}
                                                    placeholder="Please enter Designation"
                                                // autoFocus={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.allDesignationList}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'changeDesignationForm', 'designation')}
                                                    value={this.state.allDesignationList.find(u => this.state.changeDesignationForm.designation == u.value)}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Select Designation"
                                                    autoCompelete="off"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="new_designation"
                                                type="checkbox"
                                                value={this.state.new_designation}
                                                onChange={(e) => { this.setState({ new_designation: this.state.new_designation == 'N' ? 'Y' : 'N' }) }}
                                                checked={this.state.new_designation == 'Y'}
                                                className="form-check-input"
                                                id="new_designation"
                                            />
                                            <label className="form-check-label mx-2" htmlFor="new_designation">New </label>
                                        </div>
                                    </div>

                                </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveRoleFormSubmitting} data-bs-dismiss="modal" onClick={() => { this.setState({ changeRoleForm: { role_id: '' } }) }}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveRoleFormSubmitting} >Save {this.state.saveRoleFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
     
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>User Listing</title></Helmet>
            {this.userListjsx()}
           {this.userSearchModalJsx()}
           {this.editBasicDetailModalJsx()} 
           {this.editImpDetailModalJsx()} 
           {this.deactivateUserModalJsx()}
           {this.changeRoleModalJsx()}
          {this.changePasswordModalJsx()}
          {this.userBulkUploadModalJsx()}
          {this.profileUpdateModalJsx()}
          {this.changeDesignationModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        user_id                 :   state.app.user_info?.id??'',
        permissions             :   state.app.acl_info,
        user_type               :   state.app.user_info && state.app.user_info.user_type ? state.app.user_info.user_type : '',
    };
};



export default connect(mapStateToProps)(UserList);