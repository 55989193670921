import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import React from "react";
import TapApiUrls from "../../../services/TapApiUrls";
import moment from "moment";
import * as actions from "../../../store/actions/index"

class AssetExpense extends AppBaseComponent {
 

    constructor(props) {
        super(props);

        this.state                      =        {
            loading                     :       false,
            data                        :       [],
            // filterForm                  :       {...this.initFilterForm},
            all_warehouses              :       [],
            remark_time                 :       '',   
        }
    }

    componentDidMount() {
        // this.initilaizeFormFilter(this.props);
        // if (localStorage.getItem('PUCWidget')) {
        //     let PUCData = JSON.parse(localStorage.getItem('PUCWidget'));
        //     if (PUCData) {
        //         let lastRefreshTime = moment(PUCData.time).format()
        //         let nextDay = moment(lastRefreshTime).add(1, 'days').format()
        //         lastRefreshTime = moment(lastRefreshTime).valueOf()
        //         nextDay = moment(nextDay).valueOf()

        //         let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
        //         if (latestDataTime > 86400000) {
        //             localStorage.removeItem('PUCWidget')
        //             this.getPucListing()
        //         } else {
        //             this.setState({
        //                 data            :      PUCData.data,
        //                 remark_time     :      PUCData.time, 
        //             })
        //         }

        //     }else{
        //         this.getPucListing()
        //     }
        // } else {
        //     this.getPucListing()
        // }
    }

    // componentWillReceiveProps(nextProps) {
    //     if(nextProps.all_warehouses !== this.props.all_warehouses || nextProps.dashboard_filter_warehouse_id !== this.props.dashboard_filter_warehouse_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
    //         this.initilaizeFormFilter(nextProps);
    //     }
    // }

  
    render                      =   ()  =>  {
       
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Asset Expense</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            isSearchable={true}
                            placeholder="Site"
                            containerHeight="33px"
                            fontSize="93%"
                        // value={this.options.find(m => m.value == this.state.filterForm.showType)}
                        // changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'showType',)}
                        // options={this.options}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            // options={this.state.all_warehouses}
                            isSearchable={true}
                            isClearable={true}
                            // value={this.state.all_warehouses.find(m => m.value == this.state.filterForm.warehouse_id)}
                            placeholder="Expense Head"
                            containerHeight="33px"
                            fontSize="93%"
                        //changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'warehouse_id', () => this.getPucListing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} >Refresh</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-12 pr0 mr0 text-end ">
                        <Chart
                            chartType="ColumnChart"
                            data={[['Month', `No of Entry `], ['Jan', 30], ['Feb', 40], ['Mar', 50], ['Apr', 60], ['May', 70], ['June', 40], ['July', 60], ['Aug', 60], ['Sept', 60], ['Oct', 90], ['Nov', 50], ['Dec', 60]]}
                            options={{
                                legend: { position: 'left', labeledValueText: 'both' },
                                targetAxisIndex: 1,
                                colors: ['#53A8E3', '#EC4E4E'],
                                chartArea: { right: 10, top: 20, width: "76%", height: "70%" }
                            }}
                            rootProps={{ 'data-testid': '1' }} />
                    </div>
                    <div className="col-12">
                        <table className="table table-hover table-bordered table-borderless bg-white my-2">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "22%" }} className="text-start" >Month</th>
                                    <th>Jan</th>
                                    <th>Feb</th>
                                    <th>Mar</th>
                                    <th>Apr</th>
                                    <th>May</th>
                                    <th>June</th>
                                    <th>July</th>
                                    <th>Aug</th>
                                    <th>Sept</th>
                                    <th>Oct</th>
                                    <th>Nov</th>
                                    <th>Dec</th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-center">
                                    <th className="text-start" >No of Entry</th>
                                    <td>20</td>
                                    <td>30</td>
                                    <td>50</td>
                                    <td>60</td>
                                    <td>70</td>
                                    <td>80</td>
                                    <td>90</td>
                                    <td>100</td>
                                    <td>120</td>
                                    <td>90</td>
                                    <td>130</td><td>190</td>


                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="col-sm-12 text-muted fs11">
                        Report as of
                    </div>
                </div>
            </div>
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
    };
};


export default connect(mapStateToProps)(AssetExpense);