import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import Status from "../../../../components/ui/Status";
import React from "react";
import {number} from "prop-types";
import swal from "sweetalert";
import axios from "axios";
import Setting from "./Setting";
import { IAM_API_BASE_URL_2 } from "../../../../services/TapApiUrls";

class GeneralSetting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigFormData      =   {
            asset_validation                :    'Y',
            max_doc                         :    '',
            remarks_ageing                  :    '',
            change_asset_status             :    'N',
            change_asset                    :    'N',
        };

        this.initTicketDefectFormData      =   {
            name                            :    '',
            description                     :    '',
        };

        this.initTicketTypeFormData         =   {
            name                            :    '',
            notes                           :    '',
            is_repair                       :   'N'
        };

        this.state                          =   {
            generalConfigloading                :   false,
            general_config_list                 :   false,
            editFormSubmiting                   :   false,
            generalConfigFormData               :   {...this.initGeneralConfigFormData},
            ticketDefectLoading                 :   false,
            ticket_Defect_list                  :   false,
            ticketDefectFormSubmiting           :   false,
            editingTicketDefectCode             :   false,
            ticketDefectFormData                :   {...this.initTicketDefectFormData},
            ticketTypeLoading                   :   false,
            ticket_type_list                    :   [],
            ticketTypeFormSubmiting             :   false,
            editingTicketType                   :   false,
            editingTicketTypeCode               :   false,
            ticketTypeFormData                  :   {...this.initTicketTypeFormData},
        };

        this.defectCodeListUrl  =   IAM_API_BASE_URL_2 + '/defect_codes';
        this.defectCodeUrl      =   IAM_API_BASE_URL_2 + '/defect_code';

        this.ticketTypesUrl     =   IAM_API_BASE_URL_2 + '/ticket_types';
        this.ticketTypeUrl      =   IAM_API_BASE_URL_2 + '/ticket_type';
        this.ticketGeneralUrl      =   IAM_API_BASE_URL_2 +'/ticket/configuration/general';

    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.getTicketDefectListing();
        this.getTicketTypeListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editAllSettingModal'), {keyboard: false, backdrop :false});
        this.editTicketDefectCode            =        new Modal(document.getElementById('editTicketDefectCodeModal'), {keyboard: false, backdrop :false});
        this.addTicketTypeModal              =        new Modal(document.getElementById('addTicketTypeModal'), {keyboard: false, backdrop :false});

    }

    //functions for general configuration
      getGeneralSettingListing              =    ()           =>     {
        this.setState({generalConfigloading : true})
        HttpAPICall.withAthorization('GET', this.ticketGeneralUrl, this.props.access_token , {}, {}, (resp) => {
            this.setState({general_config_list  : resp.data})
        }).then(() => this.setState({generalConfigloading: false}));
    }

      generalConfigurationJsx               =    ()           =>     {
        return(<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
            <tr className="text-center">
                <th scope="col" style={{width:"5%"}}>S.No</th>
                <th scope="col" className="text-start">Configuration</th>
                <th scope="col"  style={{width:"25%"}} >Value</th>
            </tr>
            </thead>
            <tbody>
            { this.state.generalConfigloading
                ? (<tr><td colSpan="3"><Loader/></td></tr>)
                : (this.state.general_config_list.length > 0
                        ? (this.state.general_config_list.map((g , k) => {
                            return (<tr key={k} className="text-center">
                                <td>{k+1}</td>
                                <td className="text-start">{g.configuration}</td>
                                <td>{g.display}</td>
                            </tr>);
                        }))
                        : (<tr><td colSpan="3" className="text-center">No Records</td></tr>)
                )
            }
            </tbody>
        </table>)}

      editGeneralConfigurationInit          =    ()           =>     {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigFormData = [];
            if (this.state.general_config_list) {
                this.state.general_config_list.map((k) => {
                    let name = `${k.key}`;
                    let value = `${k.value}`
                    generalConfigFormData[name] = value
                });
            }
            this.setState({generalConfigFormData})
        }

    }

    editAllSettingModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editAllSettingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Allow creation of Ticket without tagging an Asset</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="asset_validation"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e,"generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.asset_validation == "N"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="asset_validation"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e,"generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.asset_validation == "Y"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Maximum number of documents allowed to upload in a Ticket</label></div>
                                    <div className="col-md-3">
                                        <input
                                            name="max_doc"
                                            type="text"
                                            value={this.state.generalConfigFormData.max_doc}
                                            onChange={(e) => this.formInputHandler(e,'generalConfigFormData')}
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Allow Change Status of Asset with Ticket</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="change_asset_status"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e,"generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.change_asset_status == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="change_asset_status"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e,"generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.change_asset_status == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Remarks Ageing for Dashboard Widget</label></div>
                                    <div className="col-md-3">
                                        <input
                                            name="remarks_ageing"
                                            type="text"
                                            value={this.state.generalConfigFormData.remarks_ageing}
                                            onChange={(e) => this.formInputHandler(e,'generalConfigFormData')}
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Allow Change of Tagged Asset</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="change_asset"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e,"generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.change_asset == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="change_asset"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e,"generalConfigFormData")}
                                                checked={this.state.generalConfigFormData.change_asset == "N"} /> No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting}  data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

      submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.ticketGeneralUrl, this.props.access_token, {} , {...this.state.generalConfigFormData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    //function for ticket Defect code
      getTicketDefectListing                =    ()           =>     {
        this.setState({ticketDefectLoading : true})
        HttpAPICall.withAthorization('GET', this.defectCodeListUrl, this.props.access_token , {}, {}, (resp) => {
            this.setState({ticket_Defect_list  : resp.data.data})
        }).then(() => this.setState({ticketDefectLoading: false}));
    }

      ticketDefectCodeJsx                   =    ()           =>     {
        return(<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
            <tr className="text-center">
                <th scope="col" style={{width: "5%"}}>S.No</th>
                <th scope="col" style={{width: "20%"}}>Ticket Defect Code</th>
                <th scope="col" style={{width: "55%"}} className="text-start" >Description</th>
                <th scope="col" style={{width: "10%"}}>Status</th>
                <th scope="col" style={{width: "10%"}}>Action</th>
            </tr>
            </thead>
            <tbody>
            { this.state.ticketDefectLoading
                ? (<tr><td colSpan="5"><Loader/></td></tr>)
                : (this.state.ticket_Defect_list.length > 0
                        ? (this.state.ticket_Defect_list.map((t , k) => {
                            return (<tr key={k} className="text-center">
                                <td>{k+1}</td>
                                <td className="text-capitalize">{t.name}</td>
                                <td className="text-start">{t.description}</td>
                                <td><Status color={t.status_color_code}>{t.status_text}</Status></td>
                                <td>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#"
                                                         role="button" aria-expanded="false"/>
                                    <ul className="dropdown-menu" style={{cursor:"pointer"}} >
                                        <li className="dropdown-item"  onClick={() => this.ticketDefectCodeInit(t.id)}>Edit</li>
                                        <li className="dropdown-item" onClick={() => this.changeTicketCodeStatus(t.id,t.status)}>{t.status == 1 ? "Deactivate" : "Activate"}</li>
                                        <li className="dropdown-item" onClick={() => this.deleteTicketCode(t.id)}>Delete</li>
                                    </ul></td>
                            </tr>);
                        }))
                        : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                )
            }
            </tbody>
        </table>)}

      ticketDefectCodeInit                  =    (id)         =>     {
        this.editTicketDefectCode.show();
        if(typeof id == "number"){
            let ticketDefectCodeData = this.state.ticket_Defect_list.find(t => t.id == id)
            this.setState({editingTicketDefectCode : true,ticketDefectFormData : {
                name        : ticketDefectCodeData.name,
                description : ticketDefectCodeData.description,
                    id      : ticketDefectCodeData.id
            }})
        }else{
            this.setState({editingTicketDefectCode : false,ticketDefectFormData : {...this.initTicketDefectFormData}})
        }
    }

      submitTicketDefectCodeForm            =    (e)          =>     {
        e.preventDefault()
        this.setState({ticketDefectFormSubmiting : true});
        if(!this.state.editingTicketDefectCode){
            HttpAPICall.withAthorization('POST', this.defectCodeUrl, this.props.access_token, {} ,
                this.state.ticketDefectFormData , (response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.editTicketDefectCode.hide();
                    this.getTicketDefectListing();
                    this.setState({ticketDefectFormData   :   {...this.initTicketDefectFormData}})
                }).then(() => this.setState({ticketDefectFormSubmiting : false}));
        }else{
            HttpAPICall.withAthorization('PUT', this.defectCodeUrl, this.props.access_token, {} ,
                this.state.ticketDefectFormData , (response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.editTicketDefectCode.hide();
                    this.getTicketDefectListing();
                    this.setState({ticketDefectFormData   :   {...this.initTicketDefectFormData},editingTicketDefectCode : false})
                }).then(() => this.setState({ticketDefectFormSubmiting : false}));
        }
    }

      ticketDefectCodeModalJsx              =    ()           =>     {
        return (
            <div className="modal fade" id="editTicketDefectCodeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.editingTicketDefectCode ? "Edit" : "Add"} Ticket Defect Code</h5>
                        </div>
                        <form onSubmit={this.submitTicketDefectCodeForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <div className="col-sm-3 text-end">
                                        <label className="col-form-label require">Defect Code</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <input type="text" className="form-control text-capitalize" required={true} name="name"
                                               onChange={(e) => this.formInputHandler(e,"ticketDefectFormData")}
                                               value={this.state.ticketDefectFormData.name} />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <div className="col-sm-3 text-end">
                                        <label className="col-form-label">Description</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <textarea
                                            name="description"
                                            value={this.state.ticketDefectFormData.description}
                                            onChange={(e) => this.formInputHandler(e,'ticketDefectFormData')}
                                            className="form-control"
                                            style={{height: "80px"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.ticketDefectFormSubmiting}  data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.ticketDefectFormSubmiting} >Save {this.state.ticketDefectFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

      changeTicketCodeStatus                =    (id,status)  =>     {
        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', this.defectCodeUrl + '/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getTicketDefectListing()
                    }).then(() => {

                    });
                }
            });
    }

      deleteTicketCode                      =    (id)          =>   {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    axios({
                        method: 'DELETE',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
                        url: this.defectCodeUrl + '/' + id
                    }).then((response) => {
                        this.getTicketDefectListing()
                    }).catch((error) => {
                        if(error.response.data.status_code == 422){
                            swal({
                                title: "Delete",
                                text: "This Ticket Code is already linked with tickets,Do you still want to Delete It?",
                                icon: "warning",
                                // dangerMode: true,
                                buttons: ["No", "Yes"],
                            }).then(forceDelete => {
                                    if (forceDelete) {
                                        HttpAPICall.withAthorization('DELETE', this.defectCodeUrl + '/' + id, this.props.access_token, {force : "Y"},{} ,(response) => {
                                            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                                            this.getTicketDefectListing()
                                        }).then(() => {

                                        });
                                    }
                                });
                        }
                    }).then(() => {

                    });

                }
            });
    }

    //function for ticket type
      getTicketTypeListing                    =    ()           =>     {
        this.setState({ticketTypeLoading : true})
        HttpAPICall.withAthorization('GET', this.ticketTypesUrl, this.props.access_token , {}, {}, (resp) => {
            this.setState({ticket_type_list  : resp.data.data})
        }).then(() => this.setState({ticketTypeLoading: false}));
    }

     ticketTypeJsx                          =     ()          =>      {
        return(<table className="table table-hover table-bordered my-3 ">
            <thead>
            <tr className="table-secondary">
                <th scope="col" style={{width: "5%"}} className="text-center">S.No</th>
                <th scope="col" style={{width: "20%"}}>Ticket Type</th>
                <th scope="col" style={{width: "45%"}} >Notes</th>
                <th scope="col" style={{width: "10%"}} >Repair</th>
                <th scope="col" style={{width: "10%"}} className="text-center">Default Ticket Type</th>
                <th scope="col" style={{width: "10%"}} className="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            { this.state.ticketTypeLoading
                ? (<tr><td colSpan="3"><Loader/></td></tr>)
                : (this.state.ticket_type_list.length > 0
                        ? (this.state.ticket_type_list.map((t , k) => {
                            return (<tr key={k} className="text-start">
                                <td className="text-center">{k+1}</td>
                                <td className="text-capitalize">{t.name}</td>
                                <td>{t.notes ? t.notes :  "-"}</td>
                                <td className="text-center">{t.is_repair === 'Y'  ? 'Yes' :  'No'}</td>
                                <td className="text-center">   {t.is_default === 'Y'
                                    ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} />
                                    : ""}</td>
                                <td className="text-center">  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#"
                                                         role="button" aria-expanded="false"/>
                                    <ul className="dropdown-menu" style={{cursor:"pointer"}} >
                                        <li className="dropdown-item" onClick={() => {this.ticketTypeInit(t.name)}}>Edit</li>
                                        <li className={[t.is_default === 'Y' ? 'disabled' : '',  "dropdown-item"].join(" ")}  onClick={() => {this.setTicketAsDefault(t.id)}}>
                                          Set to Default
                                        </li>
                                        <li  ><a role={"button"} className={[t.is_default === 'Y' ? 'disabled' : '',  "dropdown-item"].join(" ")} onClick={() => {this.deleteTicketType(t.id)}}>Delete</a></li>
                                    </ul></td>
                            </tr>);
                        }))
                        : (<tr><td colSpan="3" className="text-center">No Records</td></tr>)
                )
            }
            </tbody>
        </table>)
     }

      ticketTypeInit                       =    (name)         =>     {
        this.addTicketTypeModal.show();
        if(typeof name == "string"){
              let ticketTypeData = this.state.ticket_type_list.find(t => t.name == name)
              this.setState({editingTicketType : true,ticketTypeFormData : {
                    id          :   ticketTypeData.id,
                    name        :   ticketTypeData.name,
                    notes       :   ticketTypeData.notes,
                    is_repair   :   ticketTypeData?.is_repair && ticketTypeData.is_repair == 'Y' ? 'Y' : 'N',
                    is_default  :   ticketTypeData.is_default
                  }})
          }else{
              this.setState({editingTicketType : false,ticketTypeFormData : {...this.initTicketTypeFormData}})
          }
     }

     ticketTypeModalJsx              =    ()           =>     {
        return (
            <div className="modal fade" id="addTicketTypeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.editingTicketType ? "Edit" : "Add"} Ticket Type</h5>
                        </div>
                        <form onSubmit={this.submitTicketTypeForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <div className="col-sm-3 text-end">
                                        <label className="col-form-label require">Ticket Type</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control text-capitalize" required={true} name="name"
                                               onChange={(e) => this.formInputHandler(e,"ticketTypeFormData")}
                                               value={this.state.ticketTypeFormData.name} />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <div className="col-sm-3 text-end">
                                        <label className="col-form-label">Notes</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <textarea
                                            name="notes"
                                            value={this.state.ticketTypeFormData.notes}
                                            onChange={(e) => this.formInputHandler(e,'ticketTypeFormData')}
                                            className="form-control"
                                            style={{height: "80px"}}
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                   
                                    <div className="offset-3 col-sm-6">
                                    <div className="form-check form-check-inline">
                                            <input
                                                name="is_repair"
                                                type="checkbox"
                                                value={this.state.ticketTypeFormData.is_repair}
                                                checked={this.state.ticketTypeFormData.is_repair == 'Y'}
                                                 onChange={(e) => {
                                                      if(e.target && e.target.checked && e.target.checked == true ){
                                                        this.setState({ticketTypeFormData : {...this.state.ticketTypeFormData,is_repair : 'Y'}})
                                                      }else{
                                                        this.setState({ticketTypeFormData : {...this.state.ticketTypeFormData,is_repair : 'N'}})
                                                      }
                                                }}
                                                className="form-check-input"
                                                id="is_repair"
                                            />
                                            <label className="form-check-label" htmlFor="is_repair">Ticket Type for Repair (Consider for MTTR/MTBF)</label>
                                    </div>
                                </div>
                                </div>

                                           
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.ticketTypeFormSubmiting}  data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.ticketTypeFormSubmiting} >Save {this.state.ticketTypeFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
     }

     submitTicketTypeForm            =    (e)          =>     {
        e.preventDefault()
        this.setState({ticketTypeFormSubmiting : true});

        if(!this.state.editingTicketType){
            HttpAPICall.withAthorization('POST', this.ticketTypeUrl, this.props.access_token, {} ,
                this.state.ticketTypeFormData , (response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.addTicketTypeModal.hide();
                    this.getTicketTypeListing();
                    this.setState({ticketTypeFormData   :   {...this.initTicketTypeFormData},editingTicketType:false})
                }).then(() => this.setState({ticketTypeFormSubmiting : false}));
        }else{
            HttpAPICall.withAthorization('PUT', this.ticketTypeUrl, this.props.access_token, {} ,
                this.state.ticketTypeFormData , (response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.addTicketTypeModal.hide();
                    this.getTicketTypeListing();
                    this.setState({ticketTypeFormData   :   {...this.initTicketTypeFormData,editingTicketType : false}})
                }).then(() => this.setState({ticketTypeFormSubmiting : false}));
        }
    }

    setTicketAsDefault               =      (id)            =>    {
        swal({
            title: "Set as Default",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', this.ticketTypeUrl + '/default/' + id, this.props.access_token, {},{is_default : 'Y'} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getTicketTypeListing()
                    }).then(() => {

                    });
                }
            });
    }

    deleteTicketType                  =    (id)          =>   {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.ticketTypeUrl + '/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getTicketTypeListing()
                    }).then(() => {

                    });
                }
            });
    }

    render                                  =     ()           =>     {
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">General Configuration</span>
                <div className="text-end "><button className="btn btn-primary" onClick={this.editGeneralConfigurationInit}>Edit </button></div>
            </div>
            {this.generalConfigurationJsx()}
            <Setting/>
            <div className="tab_content_wrapper my-3"><span className="content_heading">Ticket Defect Code</span>
                <div className="text-end "><button className="btn btn-primary" onClick={this.ticketDefectCodeInit}>Add </button></div>
            </div>
            {this.ticketDefectCodeJsx()}
            <div className="tab_content_wrapper my-3"><span className="content_heading">Ticket Type</span>
                <div className="text-end "><button className="btn btn-primary"  onClick={this.ticketTypeInit}>Add </button></div>
            </div>
            {this.ticketTypeJsx()}
            {this.editAllSettingModalJsx()}
            {this.ticketDefectCodeModalJsx()}
            {this.ticketTypeModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(GeneralSetting);