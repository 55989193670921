import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import TransactionSetting from './TransactionSetting';
import Workflow from './Workflow';
import TermCondition from './TermCondition';
import AdditionalAttribute from '../../AdditionalAttribute';
import GeneralSettings from './GeneralSettings';


class POConfiguration extends AppBaseComponent {


    render() {

        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link  active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                                type="button" role="tab" aria-controls="details" aria-selected="true">General
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="terms-tab" data-bs-toggle="tab" data-bs-target="#terms"
                                type="button" role="tab" aria-controls="terms" aria-selected="true">Terms & Condition
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="attribute-tab" data-bs-toggle="tab" data-bs-target="#attribute"
                                type="button" role="tab" aria-controls="attribute" aria-selected="true">Addititonal Attribute
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane show active" id="details" role="tabpanel"
                            aria-labelledby="details-tab">
                            <div className="tab_content_header">
                                <TransactionSetting/>
                                <GeneralSettings/>
                            </div>
                        </div>
                        <div className="tab-pane" id="workflow" role="tabpanel"  aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <Workflow/>
                            </div>
                        </div>
                        <div className="tab-pane" id="terms" role="tabpanel"
                            aria-labelledby="terms-tab">
                            <div className="tab_content_header">
                                <TermCondition/>
                            </div>
                        </div>
                        <div className="tab-pane" id="attribute" role="tabpanel"
                            aria-labelledby="attribute-tab">
                            <div className="tab_content_header">
                                <AdditionalAttribute category="isc_po"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(POConfiguration);