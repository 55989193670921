import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Ax from "../../components/hoc/Ax";
import HttpAPICall from '../../services/HttpAPICall';
import { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import AppBaseComponent from '../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import TapIcon from '../../services/TapIcon';
import Loader from '../../components/ui/Loader/Loader';
import {Link} from "react-router-dom";
import Status from '../../components/ui/Status';
import { DisplayListPagination, DisplaySearchCriteria } from '../../components/TapUi';
import ProjectStructureDetail from './ProjectStructureDetail';
import {Modal} from "bootstrap";
import TapSelect from '../../components/ui/TapSelect';
import TapHelper from '../../services/TapHelper';
import StartProjectModal from './StartProjectModal';
import swal from "sweetalert";

class ProjectStructure extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initialProjectStructure =  {
            name                        :   '',
            notes                       :   '',
            id                          :   ''
        }

        this.initialSearch = {
            search_by_name : '',
            search_status_id : '',   
        }

        this.initialProjectStartForm = {
            site_id : '',
            notes : '',
            starting_date : '',
            structure_id : '',
            name : ''
        }

        this.searchFormLabel = {
            'search_by_name' : 'Name : ',
            'search_status_id' : 'Status : ',
        };

        this.state                  =   {
            minimizeTable               :   false,
            listing_loading             :   false,
            formSubmitting              :   false,
            modalLoading                :   false,
            totalListingCount           :   0,
            listingMeta                 :   null,
            listingData                 :   [],
            configurationData           :   null,
            addProjectStructureForm     :   {...this.initialProjectStructure},
            projectDetailData           :   null,
            projectStructureId          :   '',
            status                      :   [{label : 'Active', value: 1}, {label : 'Inactive', value: 2}],
            searchFormData              :   {...this.initialSearch},
            submittedSearchFormData     :   {...this.initialSearch},
            formSearchedElems           :   [],
            startProjectForm            :   {...this.initialProjectStartForm},
            structures                  :   [],
            siteList                    :   [],
        }

        this.projectStructureUrl    =   IAM_API_BASE_URL_2 + '/project_structure';
        this.projectUrl             =   IAM_API_BASE_URL_2 + '/project';
        this.detailDataLoad         =   React.createRef();
        this.startProjectModalRef   =   React.createRef();
    }
    
    componentDidMount() {
        this.loadListingTblData();
        this.projectStructureSearchModal  =   new Modal(document.getElementById('projectStructureSearchModal'), {keyboard: false, backdrop :false}); 
        this.projectStructureCreateModal  =   new Modal(document.getElementById('projectStructureCreateModal'), {keyboard: false, backdrop :false}); 
        this.startProject = new Modal(document.getElementById('startProject'), {keyboard: false, backdrop :false}); 
    }

    loadListingTblData  =   (page = 1)  =>  {
        this.setState({listing_loading: true}); 
        let params  =   {page:page, ...this.state.submittedSearchFormData};
        HttpAPICall.withAthorization('GET', this.projectStructureUrl + '/list',
            this.props.access_token, params, null, (response) => {
                const projects = response.data;
                this.setState({
                    listingData :   projects.data,
                    listingMeta :   projects.meta,
                    totalListingCount : projects.meta.total
                });
        }).then(() => this.setState({listing_loading: false}));
    }
    
    viewProjectDetails  =   (id)    =>  {
        this.setState({ 
            minimizeTable: true, 
        }, () => {
            this.detailDataLoad.current.projectDetailsData(id);
        });
    }
    closeViewDetails           =      ()      =>    {
        this.setState({
            minimizeTable : false, 
            projectStructureId : '', 
            projectDetailData : null
        })
    }

    deleteProjectDetails            =   (id) => {
        if(this.props.permissions.includes('iam-project-template-delete')) {
            swal({
                title: "Delete", dangerMode: true, buttons: ["No", "Yes"], icon: "warning",
                text: "Are you sure want to delete this project template, This action cannot be reversed, Are you sure you want to proceed.",
            }).then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.projectStructureUrl + '/delete/' + id,
                        this.props.access_token, null, null, (response) => {
                            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                            this.loadListingTblData();
                    }).then(() => this.setState({modalLoading: false}));
                }
            });
        } else {
            toast.error('You are not authorized to delete template', {position: toast.POSITION.TOP_RIGHT})
        }
    }
    
    listDatajsx  =   ()  =>  {
        return (
            <Ax>
                <div className="page_title row m0">
                    <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                        <h3>Project Template</h3>
                    </div>
                    {
                        !this.state.minimizeTable &&
                        <div className='col-sm-6 text-end mt15'>
                            <button type="button"  disabled={!this.props.permissions.includes('iam-project-template-add')} className="btn btn-primary" onClick={this.createModalInit}>
                                Create Project Template
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={this.searchModalInit}>
                                <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </div>
                    }
                    {
                        this.state.minimizeTable
                        ? 
                        (
                            this.detailHeaderJsx()
                        )
                        : null
                    }
                </div>
                <div className="container-fluid pl5">
                    <div className="page_containt row">
                        <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.tableDataJsx()}</div>
                        {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white py-1" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : <ProjectStructureDetail  ref = {this.detailDataLoad} projectDetail = {this.projectDetail} projectStructureId={this.state.projectStructureId} />}</div></div> : null}
                    </div>
                </div>
        </Ax>);
    }

    projectDetail   =   (detail)  => {
        this.setState({
            projectDetailData : detail,
            projectStructureId : detail.id
        })
    }

    addTicketModal  =   ()  =>  {
        this.detailDataLoad.current.ticketModalInit('', 'addChild');
    }

    startProjectInit = () => {
        this.startProjectModalRef.current.showModal();
    }

    detailHeaderJsx =   ()  =>  {
        let projectDetailData   =   this.state.projectDetailData;
        return (
            <div className="col-9 tap_border_left">
                <h3>Project Template : {projectDetailData ? projectDetailData.name : ''}</h3>
                <div className="text-end mt15">
                    <button type="button" className="btn btn-primary" disabled={projectDetailData?.id && this.props.permissions.includes('iam-project-template-ticket-manage') ? false : true} onClick={this.addTicketModal}> 
                        Add Ticket
                    </button>
                     <button type="button" className="btn btn-secondary"  disabled={projectDetailData?.id && this.props.permissions.includes('iam-project-add') ? false : true} onClick={this.startProjectInit}>
                        Start Project
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails} disabled={projectDetailData?.id ? false : true}>
                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        )
    }

    tableDataJsx    =   ()   =>   {
        let listingData = this.state.listingData;
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.searchFormClear} />
            <table className="table table-hover table-bordered table-responsive bg-white">
                <thead className="table-secondary" >
                    {
                        this.state.minimizeTable
                            ? (<tr>
                                <th scope="col" style={{ width: "15%" }}>Project Structure</th>
                            </tr>)
                            : (
                                <tr>
                                    <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                                    <th scope="col" style={{ width: "30%" }}>Name</th>
                                    <th scope="col" style={{ width: "35%" }}>Notes</th>
                                    <th scope="col" style={{ width: "12%" }}>Status</th>
                                    <th scope="col" style={{ width: "10%" }}>No. of Ticket</th>
                                    <th scope="col" style={{ width: "8%" }}>Action</th>
                                </tr>
                            )
                    }
                </thead>
                <tbody>
                    {
                        this.state.listing_loading
                            ? <tr><td className='text-center' colSpan={6}><Loader /></td></tr>
                            :
                            listingData && listingData.length > 0
                                ? listingData.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            {
                                                this.state.minimizeTable
                                                    ? (
                                                        <Ax>
                                                            <td style={{ width: "25%" }}>
                                                                <div className="text-capitalize link-primary cursor_pointer"><Link onClick={() => this.viewProjectDetails(data.id)}>{data.name}</Link></div>
                                                                <div className="mt-1">
                                                                    <small className="text-capitalize ">No. of Tickets : {data.count_tickets} </small>
                                                                    <small className="float-end">{<Status color={data.status?.color_code}>{data.status?.status}</Status>}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    :
                                                    <Ax>
                                                        <td>{i + 1}</td>
                                                        <td><Link to="#" onClick={() => this.viewProjectDetails(data.id)}>{data.name}</Link></td>
                                                        <td>{data.notes ? TapHelper.textTruncate(data.notes, 500) : ''}</td>
                                                        <td className='text-center'><Status color={data.status?.color_code}>{data.status?.status}</Status></td>
                                                        <td className='text-end'>{data.count_tickets}</td>
                                                        <td className='text-center'>
                                                            <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                                <li>
                                                                    <Link role="button" href
                                                                        onClick={() => {
                                                                            if (this.props.permissions.includes('iam-project-template-edit')) {
                                                                                return this.editProjectStructure(data.id);
                                                                            }
                                                                        }}
                                                                        className={['dropdown-item', this.props.permissions.includes('iam-project-template-edit') ? '' : 'disabled'].join(' ')}
                                                                        title={!this.props.permissions.includes('iam-project-template-edit') ? 'You dont have permission for this action' : ''}
                                                                        style={{ "pointerEvents": "all" }}
                                                                    >Edit</Link>
                                                                </li>
                                                                <li>
                                                                    <Link role="button" href
                                                                        onClick={() => { this.viewProjectDetails(data.id) }}
                                                                        className={['dropdown-item'].join(' ')}
                                                                        style={{ "pointerEvents": "all" }}
                                                                    >View</Link>
                                                                </li>
                                                                <li>
                                                                    <Link role="button" href
                                                                        onClick={() => {
                                                                            if (this.props.permissions.includes('iam-project-template-delete')) {
                                                                                return this.deleteProjectDetails(data.id);
                                                                            }
                                                                        }}
                                                                        className={['dropdown-item', this.props.permissions.includes('iam-project-template-delete') ? '' : 'disabled'].join(' ')}
                                                                        title={!this.props.permissions.includes('iam-project-template-delete') ? 'You dont have permission for this action' : ''}
                                                                        style={{ "pointerEvents": "all" }}
                                                                    >Delete</Link>
                                                                </li>

                                                            </ul>
                                                        </td>
                                                    </Ax>
                                            }
                                        </tr>
                                    )
                                })
                                : <tr><td className='text-center' colSpan={6}>No Record Found</td></tr>
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>
        )
    }

    editProjectStructure     =   (id)  =>  {
        // addProjectStructureForm
        this.projectStructureCreateModal.show();
        this.setState({modalLoading: true});
        HttpAPICall.withAthorization('GET', this.projectStructureUrl + '/detail/' + id,
            this.props.access_token, null, null, (response) => {
                const detail = response.data.data;
            this.setState({
                addProjectStructureForm : { ...this.state.addProjectStructureForm, name : detail.name, notes : detail.notes, id }
            })
        }).then(() => this.setState({modalLoading: false}));

    }
    
    createModalInit     =   ()  =>  {
        this.projectStructureCreateModal.show();
    }

    submitCreateForm    =   (e)  =>  {
        e.preventDefault();

        this.setState({formSubmitting: true}); 
        let data  = this.state.addProjectStructureForm;
        if(data.id && data.id !== '') {
            HttpAPICall.withAthorization('PUT', this.projectStructureUrl + '/edit',
                this.props.access_token, null, data, (response) => {
                this.projectStructureCreateModal.hide();
                toast.success(response.data?.msg, {position: toast.POSITION.TOP_RIGHT})
                this.setState({
                    addProjectStructureForm : this.initialProjectStructure
                }, () => {
                    this.loadListingTblData();
                })
            }).then(() => this.setState({formSubmitting: false}));
        }else {
            HttpAPICall.withAthorization('POST', this.projectStructureUrl + '/add',
                this.props.access_token, null, data, (response) => {
                this.projectStructureCreateModal.hide();
                toast.success(response.data?.msg, {position: toast.POSITION.TOP_RIGHT})
                this.setState({
                    addProjectStructureForm : this.initialProjectStructure
                }, () => {
                    this.loadListingTblData();
                })
            }).then(() => this.setState({formSubmitting: false}));
        }
    }

    projectStructureCreateModalJsx  =   ()  =>  {
        return (
            <div className="modal fade" id="projectStructureCreateModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Project Template</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" disabled={this.state.formSubmitting}></button>
                        </div>
                        {
                            this.state.modalLoading
                            ?
                            <div className="modal-body">
                                <Loader/>
                            </div>
                            :
                            <Ax>
                                <div className="modal-body">
                                    <form id="createForm" onSubmit={this.submitCreateForm}>
                                        <div className="row mb-3">
                                            <label className="col-sm-4 col-form-label mb-2">Name</label>
                                            <div className="col-sm-7 mb-2">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.addProjectStructureForm.name}
                                                    onChange={(e) => this.formInputHandler(e, 'addProjectStructureForm')}
                                                    placeholder="Enter Name"
                                                />
                                            </div>
                                            <label className="col-sm-4 col-form-label">Notes</label>
                                            <div className="col-sm-7">
                                                <textarea
                                                    name="notes"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={this.state.addProjectStructureForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'addProjectStructureForm')}
                                                    placeholder="Enter Notes..."
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.formSubmitting}>Cancel</button>
                                    <button type="submit" form='createForm' className="btn btn-primary" disabled={this.state.formSubmitting} >Save {this.state.formSubmitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                                </div>
                            </Ax>
                        }
                    </div>
                </div>
            </div>
        );
    }

    searchModalInit     =   ()  =>  {
        this.projectStructureSearchModal.show();
    }

    searchFormSubmit    =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys = Object.keys(this.state.searchFormData);
        let searchedElems = [];
        let searchFormParams = {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.searchFormLabel[key];
            let value                       =   this.state.searchFormData[key];
            if(value && value.toString().length > 0) {
                searchFormParams[key] = value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_by_name') {
                        show_val            =   this.state.searchFormData.search_by_name;
                    }                   
                    if(key == 'search_status_id') {
                        show_val            =   this.state.status.filter((s) => value === s.value).map(a => a.label).join(', ');
                    }                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            searchFormData : {...this.state.searchFormData},
            submittedSearchFormData : {...this.state.searchFormData},
            formSearchedElems : searchedElems
        }, () => {
            this.projectStructureSearchModal.hide();
            this.loadListingTblData(1)
        });
    }
    searchFormClear = ()  =>  {
        this.setState({
            searchFormData : {...this.initialSearch},
            submittedSearchFormData : {...this.initialSearch},
            formSearchedElems               :   []
        }, () => {
            this.projectStructureSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    projectStructureSearchModalJsx  =   ()  =>  {
        return (
            <div className="modal fade" id="projectStructureSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Project Template Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id="searchForm" onSubmit={this.searchFormSubmit}>
                                <div className="row mb-3">
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-form-label">Project Template</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <input
                                            name="search_by_name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.searchFormData.search_by_name}
                                            onChange={(e) => this.formInputHandler(e, 'searchFormData')}
                                            placeholder="Enter Project Template"
                                        />
                                    </div>
                                    <div className="col-sm-4 mb-2">
                                        <label className="col-sm-4 col-form-label">Status</label>
                                    </div>
                                    <div className="col-sm-7 my-2">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'searchFormData', 'search_status_id');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Status"
                                            options={this.state.status}
                                            value={this.state.status.find((s) => s.value === this.state.searchFormData.search_status_id)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Clear</button>
                            <button type="submit" form='searchForm' className="btn btn-primary">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render  =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Project Template</title></Helmet>
            {this.listDatajsx()} 
            {this.projectStructureCreateModalJsx()}
            {this.projectStructureSearchModalJsx()}
            <StartProjectModal ref={this.startProjectModalRef} projectStructureId={this.state.projectStructureId} viewProjectDetails={(id) => this.viewProjectDetails(id)} loadListingTblData={this.loadListingTblData}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token    :   state.auth.access_token,
        permissions     :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        group_sites     :   state.app.group_sites && state.app.group_sites.length > 0 ? state.app.group_sites : [] 
    };
};

export default connect(mapStateToProps)(ProjectStructure);