
import React, { useEffect, useState, useRef } from "react";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import TapIcon from "../../../services/TapIcon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import { useParams } from "react-router-dom";
import HttpAPICall from "../../../services/HttpAPICall";
import TapApiUrls from "../../../services/TapApiUrls";
import { useSelector } from "react-redux";

import swal from "sweetalert";
import EmployeeAttendenceDetailModal from './EmployeeAttendenceDetailModal';

const EmployeeAttendenceDetail = () => {
    const access_token = useSelector(state => state.auth.access_token);
    const [loading, setLoading] = useState(false);
    const { employeeId } = useParams();
    const [employee_listing, setEmployee_listing] = useState(null);
    const [attendanceDetail, setAttendanceDetail] = useState(null);
    const employeeAttendenceDetailModalRef = useRef();

    useEffect(() => {
        setLoading(true);
        const dummyData = [
            { enc_id: '1', name: 'Gunjan', employee_code: 'E001', attendence_type: "CheckIn", shift_name: 'Morning', shift_start: '10:00 AM', check_in: '10:00 AM', check_out: '7:00 PM', total_hours: '8 hours', check_in_location: 'Office', check_out_location: 'Office', check_in_remark: '-', check_out_remark: '-', status: 'Leave', site_name: "-", shift_end_time: "-" },
        ];
        setEmployee_listing(dummyData);
        setLoading(false);
    }, []);
    const taskDelete = () => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            // if (willDelete) {
            //     HttpAPICall.withAthorization('DELETE', this.task_delete_url, this.props.access_token, { transaction_id: transaction_id }, {}, (response) => {
            //         toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            //         this.fetchShiftActivities({ activity_task: "Y", productivity: 'Y' });
            //         this.setState({ isActivityPerform: true });
            //     });
            // }
        });
    }
    const handleShowModal = (item) => {
        setAttendanceDetail(item)
        employeeAttendenceDetailModalRef.current.show();
    };

    const summaryHeadingJsx = () => {
        return (
            <div className="mt-1">
                <div className="bg-white row m0 text-decoration-none d-flex align-items-center">
                    <div className="col py-2">
                        <h5 className="fs-6 m-0">Employee Attendence : 01-Oct-2024</h5>
                    </div>
                    <div className='col text-end my-2'>
                        <button type="button" className="btn btn-primary ms-1" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            <TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} className="img-fluid mr3" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                            <li className='dropdown-item'> Log FuelFeedIn</li>
                        </ul>
                        <button type="button" className="btn btn-secondary ms-1" disabled={loading}>
                            <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className={['cursor_pointer', 'img-fluid', loading ? "fa-spin" : ''].join(' ')} color="white" />
                        </button>

                        <Link to="/employee_daily_attendence">
                            <button type="button" className="btn btn-secondary ms-1" disabled={loading}>
                                <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="cursor_pointer" color="white" />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    };
    const shiftReadingDetailJsx = () => {
        return (
            <div className="py-2">
                {loading
                    ? <Loader />
                    : <Ax>
                        {employee_listing && employee_listing.map(employee => (
                            <div key={employee.enc_id}>
                                <table className="table table-hover  bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ width: "60%" }}>
                                                Employee Details
                                            </td>
                                            <td className="fs14 fw500">
                                                {employee.name || "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                                Employee Code
                                            </td>
                                            <td className="fs14 fw500">
                                                {employee.employee_code || "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                                Shift Name
                                            </td>
                                            <td className="fs14 fw500">
                                                {employee.shift_name || "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                                Site Name
                                            </td>
                                            <td className="fs14 fw500">
                                                {employee.site_name || "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                                Shift Start Time
                                            </td>
                                            <td className="fs14 fw500">
                                                {employee.shift_start || "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                                Shift End Time
                                            </td>
                                            <td className="fs14 fw500">
                                                {employee.shift_end_time || "-"}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </Ax>
                }
            </div>
        );
    };
    const attendenceDetailsJsx = () => {
        return <div className="py-2 pe-3">
            <Ax>
                <div className="tab_content_wrapper fw-bold my-2">Attendence Details
                </div>
                {/* {attendence_detail_loading
                    ? <Loader />
                    : employee_listing && employee_listing.length > 0
                        ? <Ax> */}
                <table className=" my-3 table table-bordered table-hover">
                    <thead className="bg-light">
                        <tr>
                            <th style={{ width: "15%" }}>Attendence Type</th>
                            <th style={{ width: "15%" }}>Time</th>
                            <th style={{ width: "15%" }}>Remark</th>
                            <th style={{ width: "15%" }} >Location</th>
                            <th style={{ width: "10%" }} >Photo</th>
                            <th style={{ width: "20%" }} >Updated From</th>
                            <th style={{ width: "10%" }} className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employee_listing && employee_listing.length > 0
                            ? employee_listing.map((item, k) => {
                                return (
                                    <tr>
                                        <td>{item.attendence_type}</td>
                                        <td>10:00 AM</td>
                                        <td>No Remark</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>web</td>
                                        <td className="d-flex justify-content-center align-items-center">
                                            <button
                                                type="button"
                                                id="actionMenuButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                className="btn"
                                            >
                                                <TapIcon.FontAwesomeIcon
                                                    icon={TapIcon.faEllipsisV}
                                                />
                                            </button>

                                            <ul className="dropdown-menu dropdown-menu-end cursor_pointer">
                                                <li className='dropdown-item' onClick={() => handleShowModal(item)}>Edit </li>
                                                <li className='dropdown-item' onClick={() => taskDelete()}>Delete </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                            }) : <tr><td colSpan={7} className="text-center">No Record</td></tr>}
                    </tbody>
                </table>
                {/* </Ax>
                        : null
                }
               */}
            </Ax>
        </div>
    }
    const analysisJsx = () => {
        return (
            <>{loading
                ? <Loader />
                :
                <div className="pb-2 pe-3">
                    <Ax>
                        {employee_listing && employee_listing.map(employee => (<>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Analysis</span>
                            </div>
                            <table className="table table-hover table-bordered table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                            Status
                                        </td>
                                        <td className="fs14 fw500">
                                            {employee.status || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                            Total Working Hours
                                        </td>
                                        <td className="fs14 fw500">
                                            {employee.working_hours || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                            Total Shift Hours
                                        </td>
                                        <td className="fs14 fw500">
                                            {employee.total_hours || "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </>))}
                    </Ax>
                </div>
            }
            </>
        )
    }
    const leaveDetailJsx = () => {
        return (
            <>{loading
                ? <Loader />
                : <div className="py-2 pe-3">
                    <Ax>
                        {employee_listing && employee_listing.map(employee => (<>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Leave Details</span>
                            </div>
                            <table className="table table-hover table-bordered table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                            Type
                                        </td>
                                        <td className="fs14 fw500">
                                            {employee.type_leave || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                            Remarks
                                        </td>
                                        <td className="fs14 fw500">
                                            {employee.remarks || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                            Approved - By
                                        </td>
                                        <td className="fs14 fw500">
                                            {employee.approved_by || "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>))}
                    </Ax>
                </div>
            }
            </>
        )
    }
    const updateHistoryJsx = () => {

        return <div className="py-2 pe-3">
            <Ax>
                <div className="tab_content_wrapper fw-bold my-2">Update History</div>
                {/* {attendence_detail_loading
                    ? <Loader />
                    : employee_listing && employee_listing.length > 0
                        ? <Ax> */}
                <table className=" my-3 table table-bordered table-hover">
                    <thead className="bg-light">
                        <tr>
                            <th style={{ width: "15%" }}>Time Stamp</th>
                            <th style={{ width: "15%" }}>Updated By</th>
                            <th style={{ width: "15%" }}>Updated From</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {employee_listing && employee_listing.length > 0
                                        ? employee_listing.map((fuel, k) => {
                                            return ( */}
                        <tr>
                            <td>12-Nov-2024
                                <span className="mx-3">10:00 AM</span>
                                Check-In
                            </td>
                            <td>Gunjan</td>
                            <td>Web</td>
                        </tr>
                        {/* )
                                        }) : <tr><td colSpan={6} className="text-center">No Record</td></tr>} */}
                    </tbody>
                </table>

                {/* </Ax>
                        : null
                }
               */}

            </Ax>

        </div>
    }
    return (
        <ApplicationLayout>
            <Helmet> <title>Employee Attendence Details</title> </Helmet>
            {summaryHeadingJsx()}
            <div className="row mx-0 bg-white mt-1">
                <div className="col-sm-3  px-0 border-end pageTbl pb-5" style={{ minHeight: "90vh", overflowY: "auto", maxHeight: "90vh" }}>{shiftReadingDetailJsx()}</div>
                <div className="col-sm-9 pageTbl pb-5" style={{ minHeight: "90vh", overflowY: "auto", maxHeight: "90vh" }}>
                    {attendenceDetailsJsx()}
                    {analysisJsx()}
                    {leaveDetailJsx()}
                    {updateHistoryJsx()}
                </div>
            </div>
            <EmployeeAttendenceDetailModal ref={employeeAttendenceDetailModalRef} attendanceDetail={attendanceDetail} />
        </ApplicationLayout>
    );
};

export default EmployeeAttendenceDetail;
