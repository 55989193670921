import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import HttpAPICall from '../../../services/HttpAPICall';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import Ax from '../../../components/hoc/Ax';
import ReactSelectStyle from '../../../components/ui/ReactSelectStyle';
import { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class InputTicketSearch extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                  =   {
            selectedticket         :   null,
            timer                       :   false,
            defaultOptions              :   [],
            
        };
        this.cancelToken            =   null;
        this.apiTimeout             =   null;
    }
    
    
    
    componentDidMount() {
       this.initParams(this.props)
    }

    initParams          =   (props)  =>  {
        
        this.setState({
            params              :   {
                search_site_ids :   props.search_site_id && Array.isArray(props.search_site_id) ? props.search_site_id  :   (props.search_site_id ? [props.search_site_id] : ''),
                search_asset_id :   props.search_asset_id ? props.search_asset_id : '',
                ticket_with_asset   :   props.ticket_with_asset ? props.ticket_with_asset : '',
                search_project_id   :   props.search_project_id ? props.search_project_id : '',
                search_employee_id    :  props.search_employee_id ? props.search_employee_id : '',
                search_workstation    :  props.search_workstation ? props.search_workstation : '',
                search_sub_assembly    :  props.search_sub_assembly ? props.search_sub_assembly : '',
                search_exclude_ticket_status    :   props.search_exclude_ticket_status ? props.search_exclude_ticket_status   : []
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.search_site_id !== this.props.search_site_id 
            || nextProps.search_asset_id !== this.props.search_asset_id 
            || nextProps.ticket_with_asset !== this.props.ticket_with_asset
            || nextProps.search_employee_id !== this.props.search_employee_id
            || nextProps.search_workstation !== this.props.search_workstation
            || nextProps.search_sub_assembly !== this.props.search_sub_assembly
        ) {
            this.initParams(nextProps);
        }
    }

    onInputChange                   =   (selectedOption)  =>  {
        this.props.changeEvent(selectedOption);
    }
    
    displayStringSearch             =   (ticket)  =>  {
        return ticket.map(ticket => {
            return {
                label               :   (<Ax>{ticket.activity_id}  : {ticket.header} <br/>   </Ax>),
                value               :   ticket.activity_id,
                display_label       :   (<Ax>{ticket.activity_id}  : {ticket.header} <br/>   </Ax>),
                asset           :   ticket && ticket.asset ? ticket.asset : null,
                data            :   ticket ? ticket : null
            };
        });
    };
    
    loadingOptions                  =   (inputText,callback)  =>  {
        if(inputText.length > 2) {
            clearTimeout(this.apiTimeout)
            if (this.cancelToken) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }
            this.cancelToken = axios.CancelToken.source();

            let params          = {...this.state.params, search_by_text: inputText, per_page: 50};
            // search_exclude_ticket_status : [26,10]
            this.apiTimeout  = setTimeout(() => {this.getLoadingOptions(params,callback)},1000);
        }
    }

    getLoadingOptions         =        (params,callback)            =>         {
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params: params,
            url: IAM_API_BASE_URL_2 + '/ticket/list',
            cancelToken: this.cancelToken.token
        })
        .then((response) => {
            callback(this.displayStringSearch(response.data.data))
        }).catch((err) => {
            if (axios.isCancel(err)) {
              
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        });
    }

    
    render() {
          return (<Ax>
            <AsyncSelect
                styles          =   {ReactSelectStyle(this.props)}
                menuPlacement   =   {this.props.menuPlacement || "bottom"}
                placeholder     =   {this.props.placeholder || "Search Ticket" }
                loadingMessage  =   {(input) => {
                    if(input.inputValue && input.inputValue.length <=2 ) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'Searching...';
                    }
                }}
                noOptionsMessage  =   {(input) => {
                    if(input.inputValue.length == 0) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'No Ticket found';
                    }
                }}
                minimumInput    =   {5}
                complete        =   {true}
                onChange        =   {this.onInputChange}
                isClearable     =   {true}
                loadOptions     =   {this.loadingOptions}
                value           =   {this.props.value}
                isMulti         =   {this.props.isMulti || false}
                required        =   {true}
                isDisabled      =   {this.props.disabled || false}
            />
            <input
                name=''
                tabIndex={-1}
                required={this.props.required || false}
                value={this.props.isMulti ? (this.props.value.length > 0 ? this.props.value[0].label : [].join(' ')) : (this.props.value ? this.props.value.label : '')}
                onChange={() => {} }
                style={{
                    opacity: 0,
                    width: 0,
                    height: 0,
                    padding: 0,
                    margin: 0,
                    display: 'table'
                  }}
            />        
            </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(InputTicketSearch);