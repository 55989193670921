import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import Ax from "../../components/hoc/Ax";
import {connect} from "react-redux";
import AppBaseComponent from "../../components/AppBaseComponent";
import AssetHeader from "../includes/assetHeader/AssetHeader";
import axios from "axios";
import {toast} from "react-toastify";
import HttpAPICall from "../../services/HttpAPICall";
import tapIcon from "../../services/TapIcon";
import Loader from "../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import {Modal} from "bootstrap";
import TapSelect from "../../components/ui/TapSelect";
import TapIcon from '../../services/TapIcon';
import {Link} from "react-router-dom";



class AssetLogsheet extends AppBaseComponent {


    constructor() {
        super();

        this.state = {
            minimizeTable               :         false,
            assetCardDetails            :         [],
            addInputFuelDetails         :         false,
            addActivityDetails          :         false,
            addExpenseDetails           :         false,
            counterlogHeading           :          "",
            counterlogDataView          :          false,
            viewScsScreen               :          false,
            assetDetail                 :          null
        }

    }

    
    componentDidMount() {
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.assetDetail){
            this.setState({assetDetail : this.props.location.state.assetDetail })
        }
        this.updateCounterlogModal          =        new Modal(document.getElementById('updateCounterlogModal'), {keyboard: false, backdrop :false});
        this.addFuelFeedInModal             =        new Modal(document.getElementById('addFuelFeedInModal'), {keyboard: false, backdrop :false});
        this.logDowntimeModal               =        new Modal(document.getElementById('logDowntimeModal'), {keyboard: false, backdrop :false});
        this.logProductivityModal           =        new Modal(document.getElementById('logProductivityModal'), {keyboard: false, backdrop :false});
        this.logFuelModal                   =        new Modal(document.getElementById('logFuelModal'), {keyboard: false, backdrop :false});
        this.addAssetDocumentModal          =        new Modal(document.getElementById('addAssetModal'), {keyboard: false, backdrop :false});
    }

    getAssetCard(id){

        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/asset/full_detail/' + id
        }).then((response) => {
            this.setState({assetCardDetails: response.data.data})
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        })
    }

    minimizeTable(){
        this.setState({minimizeTable:true})
    }

    //function to close
    closeMinimizeTable                    =       ()       =>  {
        this.setState({minimizeTable:false,addInputFuelDetails:false, counterlogHeading : "",
            addExpenseDetails:false,addActivityDetails:false,viewScsScreen:false})
    }

    linkToAssetProfile                    =       ()       =>  {
        this.props.history.push(`/measurable_asset_view?id=${this.measurableAssetId}`)
    }

    inputFuelDetails                      =       ()       =>   {
        this.setState({minimizeTable:true,addInputFuelDetails:true,addActivityDetails : true,
            counterlogHeading : "Add Fuel FeedIn",addExpenseDetails:false,counterlogDataView: false,viewScsScreen:false})
        this.addFuelFeedInModal.hide()
    }

    viewCounterlogData                   =       ()        =>   {
        this.setState({minimizeTable:true,addInputFuelDetails:false,addActivityDetails : false,
            counterlogHeading : "Shift Summary for Counterlog Date (Shift Name)",addExpenseDetails:false,counterlogDataView: true,viewScsScreen:false})
    }

    viewScsScreen                       =         ()        =>   {
        this.setState({minimizeTable:true,addInputFuelDetails:false,addActivityDetails : false,
            counterlogHeading : "Service Checksheet ",addExpenseDetails:false,counterlogDataView: false,viewScsScreen:true})

    }

    showActivityLog                       =       ()       =>   {
        this.setState({minimizeTable:true,addInputFuelDetails:false,addActivityDetails : true, counterlogHeading : "Log Asset Activity",addExpenseDetails:false})
    }
    showExpenseLog                        =       ()       =>    {
        this.setState({minimizeTable:true,addInputFuelDetails:false,addActivityDetails : false, counterlogHeading : "Log Expense",addExpenseDetails:true})
    }

    updateCounterlogModalInit             =       ()       =>    {
        this.updateCounterlogModal.show()
    }

    addFuelFeedInModalInit               =        ()      =>    {
        this.addFuelFeedInModal.show()
    }

    logDowntimeModalInit                 =        ()     =>     {
        this.logDowntimeModal.show()
    }

    logProductivityModalInit              =  ()     =>  {
        this.logProductivityModal.show()
    }

    logFuelModalInit                     =   ()   =>  {
        this.logFuelModal.show()
    }

    addDocumentModalInit                 =   ()  =>  {
        //Initialize Search Form :-
        this.addAssetDocumentModal.show();
    }

    updateCounterlogModalJsx            =   ()  =>  {
        return (
            <div className="modal fade" id="updateCounterlogModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Update Counter Log</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Hour Meter Reading</label></div>
                                    <div className="col-4">
                                        <div className="mb-2">
                                            <small className="form-text">Previous Year Reading</small>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholder="Please Enter Previous Shift Reading"
                                        />
                                        <div>
                                            <small className="form-text">Latest Reading 200</small>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="mb-2"> <small className="form-text mb-2">Counter log Reading</small></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Counter Log Reading"
                                        />
                                        <div className="p-2">
                                            <small className="form-text"></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Odometer Reading</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholder="Please Enter Previous Shift Reading"
                                        />
                                        <div>
                                            <small className="form-text">Latest Reading 200</small>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Counter Log Reading"
                                        />
                                        <div className="p-2">
                                            <small className="form-text"></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Operation Cycle Reading</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholder="Please Enter Previous Shift Reading"
                                        />
                                        <div>
                                            <small className="form-text">Latest Reading 200</small>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Counter Log Reading"
                                        />
                                        <div className="p-2">
                                            <small className="form-text"></small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Counterlog Date</label></div>
                                    <div className="col-md-4 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            disabled={true}
                                            placeholderText={`Please Enter Counterlog Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                    <div className="col-md-5 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholderText={`Please Enter Counterlog Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addFuelFeedInModalJsx               =   ()  =>  {
        return (
            <div className="modal fade" id="addFuelFeedInModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel"> Log Direct Fuel Feed In</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Site Name</label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            placeholder="Select Site"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Fuel Station Name</label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            required={true}
                                            placeholder="Select Fuel Station"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Other Station Details</label></div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Other Station Details"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Quantity</label></div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Quantity"
                                        />
                                    </div>
                                   <div className="col-4">
                                       <span className="form-text">Quantity of HS Diesel in Litre</span>
                                   </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Unit Rates</label></div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Unit Rate"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <span className="form-text">Unit Rate in ₹/Litre</span>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Amount</label></div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Amount"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <span className="form-text">Amount in ₹</span>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="offset-3 col-5">
                                      <span className="link-primary" onClick={this.inputFuelDetails}>Input Other Details</span>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    logDowntimeModalJsx                 =   ()  =>  {
        return (
            <div className="modal fade" id="logDowntimeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel"> Log Downtime</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label ">Choose Downtime </label></div>
                                    <div className="col-6">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="breakdown"
                                                type="radio"
                                                value="single"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="report_type_single">Breakdown</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="maintenance_downtime"
                                                type="radio"
                                                value="single"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="report_type_multiple">Maintenance Downtime</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label ">Downtime Duration</label></div>
                                    <div className="col-5">
                                        <DatePicker
                                            className="form-control"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="Hours"
                                            /*filterTime={this.filterTime(moment(rs.shift_time).toformat(),rs.working_hr)}*/
                                            dateFormat="HH:mm"
                                            autoComplete={"off"}
                                            placeholderText={`Select Duration`}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="full_shift"
                                                type="checkbox"
                                                value="single"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="full_shift">
                                                Full Shift</label>
                                        </div>
                                       </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label ">Section </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Section"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label ">Sub - Section </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Sub Section"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label">Remarks</label></div>
                                    <div className="col-5">
                                        <textarea
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Remarks"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    logProductivityModalJsx             =   ()  =>  {
        return (
            <div className="modal fade" id="logProductivityModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel"> Log Productivity</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3">
                                    <div className="col-12">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="productivity"
                                                type="checkbox"
                                                value="single"
                                                className="form-check-input"
                                                required={true}
                                            />
                                            <label className="form-check-label" htmlFor="report_type_single">Pre-fill details from previous Productivity Entry</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Activity Name </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Activity Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Net Productivity</label></div>
                                    <div className="col-5">
                                        <input
                                            name="net_productivity"
                                            type="text"
                                            className="form-control"
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Productivity Units </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Productivity Unit"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label">Material Name </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Material Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label">Workstation </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Workstation"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label">Operator / User Name </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Operator"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label">Attachment </label></div>
                                    <div className="col-5">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Attachment"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label">Remarks </label></div>
                                    <div className="col-5">
                                       <textarea
                                           className="form-control"
                                           autoComplete="off"
                                           placeholder="Please Enter Remarks"
                                       />
                                    </div>
                                </div>
                                <div className="col-12 text-end modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit"  className="btn btn-primary mx-3">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    logFuelModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="logFuelModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel"> Log Opening Fuel Balance</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Fuel Opening Balance (Qty)</label></div>
                                    <div className="col-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Opening Fuel Balance"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <span className="form-text fs11">Opening fuel Quantity in fuel tank at the start of shift</span>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Remarks</label></div>
                                    <div className="col-5">
                                         <textarea
                                             name="description"
                                             className="form-control"
                                             style={{height: "80px"}}
                                         />
                                    </div>
                                    <div className="col-4">
                                        <span className="form-text fs11">Min : 2 & Max : 250</span>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addDocumentModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addAssetModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add Shift Document</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="addModalForm">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="form-label">Document Name</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Document Name"
                                        />
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Document Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            name="number"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Document Number"
                                        />
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Document</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            name="file"
                                            type="file"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Upload Document"
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    AssetCounterLogListjsx    =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <div className="text-end mt15">
                    <button type="button" className="btn btn-secondary" disabled={!this.state.assetDetail} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faPlay} className=" mr3" />
                        Action</button>
                    <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                        <li><Link className="dropdown-item" to={{ pathname: "/asset/service_checksheet", state: { assetDetail: this.state.assetDetail } }}>Service Checksheet</Link></li>
                    </ul>
                    <button type="button" className="btn btn-secondary" disabled={!this.state.assetDetail} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faHistory} className="img-fluid mr3" alt="item-action" />
                        History</button>
                    <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                        <li><Link className="dropdown-item" to={{ pathname: "/invoice_calculation_list", search: "?" + new URLSearchParams(obj).toString() }}>Invoices</Link></li>
                        <li><Link className="dropdown-item" to={{ pathname: "/ticket", search: "?" + new URLSearchParams(obj).toString() }}>Ticket</Link></li>
                    </ul>
                    {this.state?.assetDetail?.cl_attribute == "Y" ?
                        <Link to="/measurable_asset">  <button type="button" className="btn btn-secondary">
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-action" /></button>
                        </Link>
                        :
                        <Link to="/non_measurable_asset">  <button type="button" className="btn btn-secondary">
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-action" /></button>
                        </Link>
                    }
                </div>
            </div>
            
        </div>);
    }

    pageSecondaryTitleBarJsx        =       ()      =>      {
        return (<div style={{ marginTop: "4px" }}>
            <div className="row page_title m0  " style={{ height: "45px" }}>
                <div className="col-2"><h3 style={{ paddingTop: "13px" }}>Logsheet</h3></div>
                <div className='col-sm-7 mt7 text-center '>
                    <div className='row '>
                        <div className=' col-sm-3 text-end'>
                            <button type="button" className="btn btn-outline-success">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowLeft} className="mx-1" />  Prev
                            </button>
                        </div>
                        <div className='  col-sm-5 add_calender_section' style={{ top: "1px" }}>

                            <input placeholder='1-Feb-2022  - 1-Mar-2022'
                                className='form-control' style={{ height: '30px' }} />
                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                alt="requisition_date"  style={{ top: "5px" }}
                                className="add_calender-icon" />
                        </div>
                        <div className='col-sm-4 text-start'>
                            <button type="button" className="btn btn-outline-success">
                                Next  <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowRight} className="mx-1" />
                            </button>
                        </div>
                    </div>

                </div>
                <div className='col-sm-3 text-end mt7'>
                
                    <button type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className='dropdown-item'>Fuel</a></li>
                    </ul>
                </div>
            </div>

        </div>)
    }


    counterlogListingTable       =        ()   =>   {
        return( <Ax>
            { this.state.minimizeTable
                ? (
                    <Ax>
                       <div className=" bg-white">
                          <div className="p-3 mb-5">
                              <div className="tab_content_wrapper content_heading">Shift Details</div>
                              <table className=" mt-3 table table-bordered table-hover">
                                  <tr>
                                      <td style={{width : "50%"}} className="pt-2 pb-2 text-start">Roster Name</td>
                                      <td className="fw-bold ">Double Shift</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start">Shift Name</td>
                                      <td className="fw-bold">Day</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start">Shift Start Time</td>
                                      <td className="fw-bold">08:00 AM</td>
                                  </tr>
                              </table>
                              <div className="tab_content_wrapper content_heading">Counter Log Details</div>
                              <table className=" mt-3 table table-bordered table-hover">

                                  <tr>
                                      <td className="pt-2 pb-2 text-start" style={{width : "50%"}}  >Counterlog Date</td>
                                      <td className="fw-bold">01-Oct-2021</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start">Hour Meter Reading</td>
                                      <td className="fw-bold">7000.00 Hr</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start">Odometer Reading</td>
                                      <td className="fw-bold">270500.00 Km</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start">Operation Cycle</td>
                                      <td className="fw-bold">270500.00 Km</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start">Site Name</td>
                                      <td className="fw-bold">Gurgaon</td>
                                  </tr>

                                  <tr>
                                      <td className="pt-2 pb-2 text-start" >Remarks</td>
                                      <td className="fw-bold">dummy</td>
                                  </tr>
                              </table>
                              <div className="tab_content_wrapper content_heading">Transaction Analysis</div>
                              <table className=" mt-3 mb-3 table table-bordered table-hover">
                                  <tr>
                                      <td style={{width : "50%"}} className="pt-2 pb-2 text-start">Transaction ID</td>
                                      <td className="fw-bold ">RC-211028-13153391</td>
                                  </tr>
                                  <tr>
                                      <td style={{width : "50%"}} className="pt-2 pb-2 text-start">Date of Entry</td>
                                      <td className="fw-bold ">12-Oct-2021</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start">Transaction Entered By</td>
                                      <td className="fw-bold">Nishant maitre</td>
                                  </tr>
                                  <tr>
                                      <td className="pt-2 pb-2 text-start" >Delay in Entry</td>
                                      <td className="fw-bold">3 Days</td>
                                  </tr>
                              </table>
                          </div>
                       </div>
                    </Ax>
                )
                :
                (<table className="table table-bordered bg-white table-hover table-fixed">
                <thead className="table-secondary text-center">
                <tr>
                    <th scope="col" style={{width:"5%"}} className="text-center">Date</th>
                    <th scope="col" style={{width:"10%"}}>Shift</th>
                    <th scope="col" style={{width:"10%"}}>CouterLog</th>
                    <th scope="col" style={{width:"10%"}} className="text-center">Fuel FeedIn</th>
                    <th scope="col" style={{width:"10%"}}>Downtime</th>
                    <th scope="col" style={{width:"10%"}}>Productivity</th>
                    <th scope="col" style={{width:"10%"}}>Activity</th>
                    <th scope="col" style={{width:"10%"}}>SCS</th>
                    <th scope="col" style={{width:"10%"}}>Expense</th>
                    <th scope="col" style={{width:"10%"}}>Fuel Op.Bal.</th>
                    <th scope="col" style={{width:"5%"}}>Docs</th>
                </tr>
                </thead>
                <tbody>
                <tr className="text-center">
                    <td className="align-middle " rowSpan={2}>1st Oct</td>
                    <td>Day Shift</td>
                    <td className="link-primary" style={{cursor:"pointer"}} onClick={this.updateCounterlogModalInit}>No Record</td>
                    <td onClick={this.addFuelFeedInModalInit} style={{cursor:"pointer"}}>+</td>
                    <td onClick={this.logDowntimeModalInit} style={{cursor:"pointer"}}>+</td>
                    <td onClick={this.logProductivityModalInit} style={{cursor:"pointer"}}>+</td>
                    <td onClick={this.showActivityLog.bind(this)} style={{cursor:"pointer"}} >+</td>
                    <td onClick={this.viewScsScreen.bind(this)} style={{cursor:"pointer"}}>+</td>
                    <td onClick={this.showExpenseLog.bind(this)} style={{cursor:"pointer"}}>+</td>
                    <td onClick={this.logFuelModalInit} style={{cursor:"pointer"}}>+</td>
                    <td onClick={this.addDocumentModalInit}  style={{cursor:"pointer"}}>+</td>

                </tr>
                <tr className="text-center">
                    <td>Night Shift</td>
                    <td className="link-primary" onClick={this.viewCounterlogData} style={{cursor:"pointer"}} >7100.00 Hr <br/>27050100 Km</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                </tr>
                <tr className="text-center">
                    <td className="align-middle " rowSpan={2}>2nd Oct</td>
                    <td>Day Shift</td>
                    <td className="link-primary"  onClick={this.updateCounterlogModalInit}>No Record</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>

                </tr>
                <tr className="text-center">
                    <td>Night Shift</td>
                    <td className="link-primary" onClick={this.updateCounterlogModalInit}>No Record</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                    <td>+</td>
                </tr>
                </tbody>
            </table>) }

        </Ax>)
    }

    viewCounterlogJsx           =        ()     => {
       if(this.state.addInputFuelDetails) {
           return(
               <div className=" bg-white mr4">
                   <form className=" p-3 mb-5">

                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Fuel Station Name</label></div>
                           <div className="col-5">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Fuel Station"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Other Station Details</label></div>
                           <div className="col-5">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Other Station Details"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Quantity</label></div>
                           <div className="col-5">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Quantity"
                               />
                           </div>
                           <div className="col-4">
                               <span className="form-text">Quantity of HS Diesel in Litre</span>
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Unit Rates</label></div>
                           <div className="col-5">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Unit Rate"
                               />
                           </div>
                           <div className="col-4">
                               <span className="form-text">Unit Rate in ₹/Litre</span>
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Amount</label></div>
                           <div className="col-5">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Amount"
                               />
                           </div>
                           <div className="col-4">
                               <span className="form-text">Amount in ₹</span>
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label ">Fuel Station Ref Number</label></div>
                           <div className="col-5">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Fuel Reference Number"
                               />
                           </div></div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Fuel Tank Status </label></div>
                           <div className="col-5">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Tank Status"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label ">Attach Invoice</label></div>
                           <div className="col-5">
                               <input
                                   type="file"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Attach"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label ">Wallet</label></div>
                           <div className="col-5">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Wallet "
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label ">Notes</label></div>
                           <div className="col-5">
                               <textarea
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Leave Note"
                               />
                           </div>
                       </div>

                   </form>
               </div>
           )
       }else if(this.state.addActivityDetails){
           return(
               <div className=" bg-white mr4">
                   <form className=" p-3 mb-5">
                       <div className="tab_content_wrapper mt-2" >
                           <span className="content_heading">Activity Basic Details</span>
                           {/*<div className="float-end">
                               <button className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                           </div>*/}
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-4"><label className="form-label require">Activity Type</label></div>
                           <div className="col-4"><label className="form-label require">Activity Name</label>
                               <div className="form-check form-check-inline float-end">
                                   <input
                                       name="other"
                                       type="checkbox"
                                       value="single"
                                       className="form-check-input"
                                       required={true}
                                   />
                                   <label className="form-check-label fw-bold" htmlFor="other">
                                       Other</label>
                               </div>
                           </div>
                           <div className="col-4"><label className="form-label require">No. of Session or Trip</label></div>
                           <div className="col-4">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Activity Type"
                               />
                           </div>
                           <div className="col-4">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Activity Name"
                               />
                           </div>
                           <div className="col-4">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Trip/Sessions"
                               />
                           </div>
                       </div>
                       <div className="tab_content_wrapper mt-2" >
                           <span className="content_heading">Activity Measurement</span>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Measuring Unit</label></div>
                           <div className="col-3"><label className="form-label">Starting Reading</label></div>
                           <div className="col-3"><label className="form-label">Stopping Reading</label></div>
                           <div className="col-3"><label className="form-label">Total Running</label></div>
                           <div className="col-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Hour Meter"
                                   disabled
                               />
                           </div>
                           <div className="col-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Starting HMR"
                               />
                           </div>
                           <div className="col-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Stopping HMR"
                               />
                           </div>
                           <div className="col-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Total Running HMR"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="OdoMeter"
                                   disabled
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Starting KM"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Stopping KM"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Total Running KM"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Operation Cycle"
                                   disabled
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Starting OpC"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Stopping OpC"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Total Running OpC"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Time"
                                   disabled
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Starting Time"
                               />
                           </div>
                           <div className="col-3 mt-3">
                               <input
                                   type="number"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Closing Time"
                               />
                           </div>
                       </div>
                       <div className="tab_content_wrapper mt-2" >
                           <span className="content_heading">Productivity</span>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Net Productivity</label></div>
                           <div className="col-3"><label className="form-label">Productivity Units</label></div>
                           <div className="col-3"><label className="form-label">Material Name</label></div>
                           <div className="col-3"><label className="form-label">Attachment</label></div>
                           <div className="col-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Net Productivity"
                               />
                           </div>
                           <div className="col-3">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Unit/Create New"
                               />
                           </div>
                           <div className="col-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Select Material Name/Create New"
                               />
                           </div>
                           <div className="col-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Select Attachment/Create New"
                               />
                           </div>
                       </div>
                       <div className="tab_content_wrapper mt-2" >
                           <span className="content_heading">Logistics / Movement</span>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">From Location (Workstation) </label></div>
                           <div className="col-3"><label className="form-label">To Location (Workstation) </label></div>
                           <div className="col-3"><label className="form-label">Customer Name</label></div>
                           <div className="col-3"><label className="form-label">Operator</label>
                               <div className="form-check form-check-inline float-end">
                                   <input
                                       name="other"
                                       type="checkbox"
                                       value="single"
                                       className="form-check-input"
                                       required={true}
                                   />
                                   <label className="form-check-label fw-bold" htmlFor="other">
                                       Other</label>
                               </div>
                           </div>
                           <div className="col-3">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   placeholder="Select From Location"
                               />
                           </div>
                           <div className="col-3">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   placeholder="Select To Location"
                               />
                           </div>
                           <div className="col-3">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Customer Name"
                               />
                           </div>
                           <div className="col-3">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   placeholder="Select Operator Name"
                               />
                           </div>
                           <div className="col-12 mt-3">
                               <label className="form-label">Remarks </label>
                           </div>
                           <div className="col-12">
                           <textarea
                               name="description"
                               className="form-control"
                               style={{height: "80px"}}
                           />
                           </div>
                       </div>
                       <div className="modal-footer">
                           <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                           <button type="submit" className="btn btn-primary">Submit</button>
                       </div>
                   </form>
               </div>
           )
       }else if(this.state.addExpenseDetails){
           return(
               <div className=" bg-white mr4">
                   <form className=" p-3 mb-5">
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Profit Center Name</label></div>
                           <div className="col-6">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Profit Center"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Expense Head</label></div>
                           <div className="col-6">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Expense Head"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Expense Details</label></div>
                           <div className="col-6">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Expense Details"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Expense Amount in ₹</label></div>
                           <div className="col-6">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Expense Amount"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require">Payee</label></div>
                           <div className="col-6">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Payee"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Select Payee Name</label></div>
                           <div className="col-6">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Payee Name"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Reference Number</label></div>
                           <div className="col-6">
                               <input
                                   type="text"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Please Enter Ref Number"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Upload Expense Voucher</label></div>
                           <div className="col-6">
                               <input
                                   type="file"
                                   className="form-control"
                                   autoComplete="off"
                                   placeholder="Upload Expense Voucher"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Section</label></div>
                           <div className="col-6">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Section"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label">Sub - Section</label></div>
                           <div className="col-6">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Sub - Section"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require" >Effective Expense Date</label></div>
                           <div className="col-6">
                               <DatePicker
                                   dateFormat="dd-MMM-yyyy"
                                   className="form-control"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label require" >Wallet</label></div>
                           <div className="col-6">
                               <TapSelect
                                   isSearchable={true}
                                   isClearable={true}
                                   required={true}
                                   placeholder="Select Wallet"
                               />
                           </div>
                       </div>
                       <div className="row align-items-center mb-3 mt-3">
                           <div className="col-3"><label className="form-label" >Notes</label></div>
                           <div className="col-6">
                               <text
                                   className="form-control"

                               />
                           </div>
                       </div>
                       <div className="modal-footer">
                           <button type="button" className="btn btn-secondary" >Close</button>
                           <button type="submit" className="btn btn-primary">Submit</button>
                       </div>
                   </form>
               </div>
           )
       }else if(this.state.counterlogDataView){
           return(
               <div className=" bg-white mr4">
                   <div className=" p-3 mb-5">

                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Fuel Feed-In</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} />
                               </button>
                             {/*  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Add</a></li>
                               </ul>*/}
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                          <thead className="table-secondary">
                         <tr>
                             <th style={{width:"20%"}}>Transaction ID</th>
                             <th style={{width:"20%"}}>Site Name</th>
                             <th style={{width:"20%"}}>Fuel Quantity</th>
                             <th style={{width:"20%"}}>Amount</th>
                             <th style={{width:"10%"}}>Status</th>
                             <th style={{width:"10%"}}>Action</th>
                         </tr>
                          </thead>
                           <tbody>
                           <tr>
                             <td>FI-211112-14540630</td>
                               <td>	Delhi Metro</td>
                               <td>32.00</td>
                               <td>0.00</td>
                               <td>Active</td>
                               <td> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                                   <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                       <li><a className="dropdown-item" role="button">Edit</a></li>
                                       <li><a className="dropdown-item" role="button">Delete</a></li>
                                   </ul></td>
                           </tr>
                           </tbody>
                       </table>
                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Downtime</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Edit</a></li>
                                   <li><a className="dropdown-item" role="button">Delete</a></li>
                               </ul>
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                           <thead>
                           <tr>
                              <th style={{width:"20"}}>Type of Downtime</th>
                               <th style={{width:"10"}}>Total Hours of Downtime</th>
                               <th style={{width:"25"}}>Section</th>
                               <th style={{width:"25"}}>Sub-Section</th>
                               <th style={{width:"20"}}>Remarks</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr>
                             <td>Breakdown</td>
                             <td>70 Hr</td>
                             <td>Boom Assembly - Boom Placer CP</td>
                               <td>Boom Placer</td>
                               <td>Remarks</td>
                           </tr>
                           </tbody>

                       </table>
                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Productivity</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Add</a></li>
                               </ul>
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                           <thead className="table-secondary">
                           <tr>
                               <th style={{width:"20%"}}>Transaction ID</th>
                               <th style={{width:"20%"}}>Activity</th>
                               <th style={{width:"15%"}}>Material Name</th>
                               <th style={{width:"20%"}}>Net Productivity</th>
                               <th style={{width:"15%"}}>Attachment</th>
                               <th style={{width:"10%"}}>Action</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr>
                               <td>AP-211112-00081</td>
                               <td>Pumping</td>
                               <td>RMC M-30</td>
                               <td>234.00 Cubic Meter</td>
                               <td>Augar 1000 mm</td>
                               <td> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                                   <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                       <li><a className="dropdown-item" role="button">Edit</a></li>
                                       <li><a className="dropdown-item" role="button">Delete</a></li>
                                   </ul></td>
                           </tr>
                           </tbody>
                       </table>
                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Asset Activity</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Add</a></li>
                               </ul>
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                           <thead className="table-secondary">
                           <tr>
                               <th style={{width:"20%"}}>Transaction ID</th>
                               <th style={{width:"15%"}}>Activity Type</th>
                               <th style={{width:"15%"}}>Activity Name</th>
                               <th style={{width:"10%"}}>Trip/Session</th>
                               <th style={{width:"15%"}}>Productivity</th>
                               <th style={{width:"15%"}}>Workstation</th>
                               <th style={{width:"10%"}}>Action</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr>
                               <td>AP-211112-00081</td>
                               <td>Session</td>
                               <td>Pumping</td>
                               <td>02</td>
                               <td>20 Cubic Meter</td>
                               <td>Gurgaon to Delhi</td>
                               <td> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                                   <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                       <li><a className="dropdown-item" role="button">Edit</a></li>
                                       <li><a className="dropdown-item" role="button">Delete</a></li>
                                   </ul></td>
                           </tr>
                           </tbody>
                       </table>
                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Executed Checksheet</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Add</a></li>
                               </ul>
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                           <thead className="table-secondary">
                           <tr>
                               <th style={{width:"20%"}}>Transaction ID</th>
                               <th style={{width:"25%"}}>Checksheet Name</th>
                               <th style={{width:"25%"}}>Section <br/>Sub-Section</th>
                               <th style={{width:"20%"}}>Parameter</th>
                               <th style={{width:"10%"}}>Action</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr>
                               <td>AP-211112-00081</td>
                               <td>Pumping</td>
                               <td>RMC M-30</td>
                               <td>RMC M-30</td>
                               <td> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                                   <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                       <li><a className="dropdown-item" role="button">Edit</a></li>
                                       <li><a className="dropdown-item" role="button">Delete</a></li>
                                   </ul></td>
                           </tr>
                           </tbody>
                       </table>
                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Expense</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Add</a></li>
                               </ul>
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                           <thead className="table-secondary">
                           <tr>
                               <th style={{width:"20%"}}>Transaction ID</th>
                               <th style={{width:"20%"}}>Expense Head</th>
                               <th style={{width:"20%"}}>Expense Details</th>
                               <th style={{width:"15%"}}>Amount</th>
                               <th style={{width:"15%"}}>Note</th>
                               <th style={{width:"10%"}}>Action</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr>
                               <td>AP-211112-00081</td>
                               <td>Pumping</td>
                               <td>Details</td>
                               <td>RMC M-30</td>
                               <td>RMC M-30</td>
                               <td> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                                   <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                       <li><a className="dropdown-item" role="button">Edit</a></li>
                                       <li><a className="dropdown-item" role="button">Delete</a></li>
                                   </ul></td>
                           </tr>
                           </tbody>
                       </table>

                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Fuel Opening Balance</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Edit</a></li>
                                   <li><a className="dropdown-item" role="button">Delete</a></li>
                               </ul>
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                           <tr>
                               <td className="pt-2 pb-2 text-start" style={{width : "30%"}}  >Remaing Fuel		</td>
                               <td className="fw-bold">233.00</td>
                           </tr>
                           <tr>
                               <td className="pt-2 pb-2 text-start" >Remarks</td>
                               <td className="fw-bold">Remarks</td>
                           </tr>
                       </table>


                       <div className="tab_content_wrapper mt-2">
                           <span className="content_heading">Shift Document</span>
                           <div className="float-end">
                               <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Add</a></li>
                               </ul>
                           </div>
                       </div>
                       <table className=" mt-3 table table-bordered table-hover">
                           <thead className="table-secondary">
                           <tr>
                               <th style={{width:"30%"}}>Document Name (Number)</th>
                               <th style={{width:"30%"}}>View Document</th>
                               <th style={{width:"30%"}}>Location</th>
                               <th style={{width:"10%"}}>Action</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr>
                               <td>AP-211112-00081</td>
                               <td>View</td>
                               <td>RMC M-30</td>
                               <td> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                               </button>
                                   <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                       <li><a className="dropdown-item" role="button">Edit</a></li>
                                       <li><a className="dropdown-item" role="button">Delete</a></li>
                                   </ul></td>
                           </tr>
                           </tbody>
                       </table>


                   </div>
                   </div>
           )
       }else if(this.state.viewScsScreen){
           return ( <div className=" bg-white mr4">
               <div className=" p-3 mb-5">
                   <table className=" mt-3 table table-bordered table-hover">
                       <thead className="table-secondary">
                       <tr>
                           <th style={{width:"5%"}}>S.No</th>
                           <th style={{width:"25%"}}>Name</th>
                           <th style={{width:"20%"}}>Section (SubSection)</th>
                           <th style={{width:"20%"}}>Last CS Done</th>
                           <th style={{width:"20%"}}>Running Status</th>
                           <th style={{width:"10%"}}>Action</th>
                       </tr>
                       </thead>
                       <tbody>
                       <tr>
                           <td>1</td>
                           <td>Replace - Pilot Filter</td>
                           <td>Consumables (Filters)</td>
                           <td>3.00 Hr <br/>
                               27-May-2019</td>
                             <td> <div className="progress">
                               <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: `100%`, backgroundColor: `red`}}
                                    aria-valuemin="0" aria-valuemax="100">
                               </div>
                           </div>
                               <div className="mt-3 text-center">100 %</div></td>
                           <td> <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                               <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                           </button>
                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item" role="button">Edit</a></li>
                                   <li><a className="dropdown-item" role="button">Delete</a></li>
                               </ul></td>
                       </tr>
                       </tbody>
                   </table>
               </div>
           </div>)
       }
    }


    render() {
      
      
        return (
            <ApplicationLayout>
                {this.AssetCounterLogListjsx() }
                {this.pageSecondaryTitleBarJsx()}
                <div className="container-fluid pl5 pt0 ">
                    <div className="page_containt row ">
                        <div className={['pageTbl', this.state.minimizeTable ? 'col-4' : 'col-12'].join(' ')} >{this.counterlogListingTable()}</div>
                        {this.state.minimizeTable ? <div className="col-8 pr0 pl0 pageTbl">{this.viewCounterlogJsx()}</div> : null}
                    </div>
                </div>
                {this.updateCounterlogModalJsx()}
                {this.addFuelFeedInModalJsx()}
                {this.logDowntimeModalJsx()}
                {this.logProductivityModalJsx()}
                {this.logFuelModalJsx()}
                {this.addDocumentModalJsx()}
            </ApplicationLayout>
        )
    }
}


const mapStateToProps = state => {
    return {
        permissions        :   state.app.acl_info.permissions,
        access_token       :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(AssetLogsheet);