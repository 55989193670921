import axios from 'axios';
import { toast } from 'react-toastify';
import ErrorValidation from './ErrorValidation';

class HttpAPICall {
    
    /**
     * 
     * @param {type} method   :   GET, POST, PUT, DELETE
     * @param {type} url
     * @param {type} access_token
     * @param {type} params
     * @param {type} data
     * @param {type} thenCallbackFn
     * @param {type} catchCallbackFn
     * @returns {unresolved}
     */
    withAthorization(method, url, access_token, params = {}, data = {}, thenCallbackFn = null , catchCallbackFn = null,showToast = true) {
        const headers       =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + access_token};
        
        return axios({
            method: method, headers: headers, params: {...params,source:'app'}, data : data, url: url
        }).then((response) => {
            if(thenCallbackFn) {
                thenCallbackFn(response);
            }
            return response;
        }).catch((error) => {
            
            if (error.response) {
                let msg                 =   error?.response?.data?.message ?? 'Application not initialize successfully.';
                if (showToast == true) {
                    
                    // ErrorValidation.apiErrorHandle(error, 'Unable to Get / Save Application Data.');
                    if(error.response.status == 401) {
                        msg                 =   'Login Session expired, Please login again.';
                        toast.error(msg, {position: toast.POSITION.TOP_RIGHT});
                      
                        setTimeout(() => {
                            window.location.href = "/logout";
                        }, 3000);
                        
                    } else {
                       
                        toast.error(error?.response?.data?.message ?? msg, {position: toast.POSITION.TOP_RIGHT});
                    }
                }
                if (catchCallbackFn) {
                    catchCallbackFn(error);
                } else {
                   
                }
               
            } else if (error.request) {
                if(error.message === 'Network Error') {
                    toast.error('No Internet Connection, Please check you internet',{position: toast.POSITION.TOP_RIGHT});
                } else {
                    
                    toast.error(error && error.message ? error.message : 'The request was made but no response was received',{position: toast.POSITION.TOP_RIGHT});
                }
            } else {
               
                toast.error(error.message,{position: toast.POSITION.TOP_RIGHT});
            }
           
           
        });
    }
}

export default new HttpAPICall();