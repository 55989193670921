import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from "moment";
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import React from "react";
import TapApiUrls from "../../../services/TapApiUrls";
import NumberFormatter from "../../../services/NumberFormater";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class RequisitionWidget extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                      =   {
            loading                     :   false,
            data                        :   [],
            remark_day                  :   0,
            remark_time                 :   '',
            all_warehouses              :   [],
            user_filter                 :   [
                {value  :   'tagged_me',        label : 'My Tagged Requisition'},
                {value  :   'created_by_me',    label : 'Requisition Created By Me'},
            ],
            filterForm                  :   {
                filter_warehouse_id     :   '',
                tagged_user_filter      :   ''
            }
        }
        this.updateMyDashboardRef      =   React.createRef();
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('requistionWidget')) {
            let requistionData = JSON.parse(localStorage.getItem('requistionWidget'));
            if (requistionData) {
                let lastRefreshTime = moment(requistionData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('requistionWidget')
                    setTimeout(() => {
                        this.getRequisitionListing()
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 11));
                  
                } else {
                    this.setState({
                        data            :      requistionData.data,
                        remark_time     :      requistionData.time, 
                    })
                }

            }else{
                setTimeout(() => {
                    this.getRequisitionListing()
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 11));
            }
        } else {
            setTimeout(() => {
                this.getRequisitionListing()
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 11));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.all_warehouses !== this.props.all_warehouses || nextProps.dashboard_filter_warehouse_id !== this.props.dashboard_filter_warehouse_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter            =   (props) => {
        if(props.all_warehouses.length > 0) {
            this.setState({
                all_warehouses          :   props.all_warehouses
                                                ? props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) 
                                                : [],
            }, () => {
                
                 if(this.props.dashboard_filter_warehouse_id || props.clearDashboardFilter == "yes"){
                   
                    this.setState({
                        filterForm              :   {
                            filter_warehouse_id         :       props.dashboard_filter_warehouse_id,
                            tagged_user_filter          :        ''
                        },
                        
                    },() =>  this.getRequisitionListing())
                }
                
            });
        }
    }

    getRequisitionListing           =   (reload=false)  =>  {
        this.setState({loading: true});
        let params                      =   {...this.state.filterForm ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET', TapApiUrls.INVENTORY + '/requisition_status_widget_data', this.props.access_token, {...params}, {} , (response) => {
            this.setState({data : response.data.data,remark_time : response.data.time})
            if(!this.state.filterForm.filter_warehouse_id && !this.state.filterForm.tagged_user_filter){
                localStorage.setItem('requistionWidget',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('requistionWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"purchase_requisition",name:"Purchase Requisition"})
    }

    render                          =   ()  =>  {
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("purchase_requisition" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "purchase_requisition")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5"><h6 className="fw-bold primary_color">Purchase Requisition</h6></div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.user_filter}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.user_filter.find(m => m.value == this.state.filterForm.tagged_user_filter)}
                            placeholder="All Requisition"
                            containerHeight="33px"
                            fontSize="93%"
                            width="99%"
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'tagged_user_filter', () => this.getRequisitionListing())}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.all_warehouses}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.all_warehouses.find(m => m.value == this.state.filterForm.filter_warehouse_id)}
                            placeholder="All Warehouses"
                            containerHeight="33px"
                            fontSize="93%"
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'filter_warehouse_id', () => this.getRequisitionListing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.getRequisitionListing(true)}> Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                {this.state.loading
                    ? <Loader />
                    : (<Ax>
                        {this.state.data && this.state.data.length == 0
                            ? <div className="row mt-2 align-items-center">
                                <div className="col-sm-12 text-center">No Record</div>
                            </div>
                            : <div className="row mt-2 align-items-center">
                                <div className="col-sm-6">
                                    <table className="table table-hover table-bordered table-borderless bg-white my-2">
                                        <thead className="table-secondary">
                                            <tr className="text-end">
                                                <th style={{ width: "40%" }} className="text-start" >Status</th>
                                                <th style={{ width: "30%" }}>Transaction</th>
                                                <th style={{ width: "30%" }}>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loading
                                                ? (<tr>
                                                    <td className="text-center" colSpan="3"><Loader /></td>
                                                </tr>)
                                                : <Ax>
                                                    {this.state.data && this.state.data.length > 0 ?
                                                        this.state.data.map((st, key) => {
                                                            let obj = { 'search_status_ids': st?.id?.map(i => i) };
                                                            if (this.state.data && this.state.filterForm.filter_warehouse_id) {
                                                                obj['search_warehouse_ids'] = this.state.filterForm.filter_warehouse_id;
                                                            }
                                                            if (this.state.data && this.state.filterForm.tagged_user_filter != '') {
                                                                obj['tagged_user'] = this.state.filterForm.tagged_user_filter;
                                                            }
                                                            return (<tr>
                                                                <th>{st.name ? st.name : "-"}</th>
                                                                <td className="text-end"><Link target='_blank' to={{ pathname: "/requisition_list", search: "?" + new URLSearchParams(obj).toString() }}>{st.total_requstion ? st.total_requstion : 0}</Link></td>
                                                                <td className="text-end"><Link target='_blank' to={{ pathname: "/report/item_wise_requisition", search: "?" + new URLSearchParams(obj).toString() }}><NumberFormatter number={st.total_items} precision={0}/></Link></td>
                                                            </tr>)
                                                        })
                                                        : null}
                                                </Ax>
                                            }
                                        </tbody>
                                    </table>

                                </div>
                                <div className="col-6 p0">
                                    <div className="mt-3 mb-1">
                                        {this.state.loading
                                            ? <Loader />
                                            : (<Chart
                                                width={'100%'}
                                                height={'100%'}
                                                chartType="BarChart"
                                                loader={`<div>Loading Chart</div>`}
                                                data={[['Status', `Transaction`]].concat(this.state.data?.map((d) => { return [d.name, parseInt(d.total_requstion)]; }))}
                                                options={{
                                                    legend: { position: 'top' },
                                                    colors: ['#53a8e3'],
                                                    isStacked: true,
                                                    chartArea: { width: '100%', left: '80', height: '100%' }
                                                }}
                                                rootProps={{ 'data-testid': '1.5' }}
                                            />)}
                                    </div>
                                </div>
                                <div className="col-sm-12 text-muted fs11"> Report as of {this.state.remark_time}</div>
                            </div>
                        }
                    </Ax>)}
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }

}


const mapStateToProps = state => {
   
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(RequisitionWidget);