import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import {toast} from "react-toastify";
import React from "react";
import {number} from "prop-types";
import swal from "sweetalert";
import axios from "axios";
import TapApiUrls from "../../../../services/TapApiUrls";
import Status from "../../../../components/ui/Status";

class MaterialList extends AppBaseComponent {

    constructor(props) {
        super(props);

        
        this.initAddMaterialForm      =   {
            name            :       '',
            id              :       ''
        };

        this.state                              =   {
            material_loading                :      false,
            material_list                   :      null,
            materialAddForm                 :     {...this.initAddMaterialForm},
            editFormSubmiting                   :      false,
        };
    }

    componentDidMount() {
        this.getMaterlistList();
        this.addMaterialForm        =        new Modal(document.getElementById('addMaterialFormModal'), {keyboard: false, backdrop :false});
       
    }

    //functions for general configuration
      getMaterlistList              =    ()           =>     {
        this.setState({material_loading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/material_list', this.props.access_token , {}, {}, (resp) => {     
         this.setState({material_list  : resp.data})
        }).then(() => this.setState({material_loading: false}));
    }

    addMaterialFormInit     =       (id,name)      =>      {
        this.addMaterialForm.show()
        if(id){
            this.setState({
                materialAddForm     :   {
                    id      :   id,
                    name    :   name
                }
            })
        }else{
            this.setState({materialAddForm : {...this.initAddMaterialForm}})
        }
    }

    submitMaterialFormHandler        =    (e)          =>     {
        e.preventDefault();
       if(this.state.materialAddForm && this.state.materialAddForm.id){
            this.setState({editFormSubmiting : true});
            HttpAPICall.withAthorization('PUT',TapApiUrls.IAM + '/material_edit', this.props.access_token, {} , {...this.state.materialAddForm} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addMaterialForm.hide();
                this.getMaterlistList();
                this.setState({materialAddForm:{...this.initAddMaterialForm}})
            }).then(() => this.setState({editFormSubmiting: false}));
       }else{
            this.setState({editFormSubmiting : true});
            HttpAPICall.withAthorization('POST',TapApiUrls.IAM + '/material_add', this.props.access_token, {} , {...this.state.materialAddForm} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addMaterialForm.hide();
                this.getMaterlistList();
                this.setState({materialAddForm:{...this.initAddMaterialForm}})
            }).then(() => this.setState({editFormSubmiting: false}));
       }
    }

    changeMaterialStatus                =    (id,status)  =>     {
        swal({
            title: "Change Status",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/material_change_status', this.props.access_token, {},{id:id} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getMaterlistList();
                    }).then(() => {

                    });
                }
            });
    }

    addMaterialFormModalJsx      =    ()   =>      {
        return (
            <div className="modal fade" id="addMaterialFormModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.materialAddForm && this.state.materialAddForm.id ? "Edit" : "Add"} Material</h5>
                        </div>
                        <form onSubmit={this.submitMaterialFormHandler}>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Name </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={this.state.materialAddForm.name}
                                            autoComplete="off"
                                            required={true}
                                            onChange={(e) => this.formInputHandler(e,'materialAddForm')}
                                        />
                                    </div>
                                </div>
                              
                            </div>
                            <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                  <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                              </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =     ()           =>     {
       
        return (<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">Material List</span>
                <div className="text-end "><button className="btn btn-primary" onClick={() => this.addMaterialFormInit()}>Add </button></div>
            </div>
            <table className="table table-hover table-bordered my-4 ">
                <thead className="table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "10%" }}>S.No</th>
                        <th scope="col">Material</th>
                        <th scope="col" style={{ width: "15%" }} >Status</th>
                        <th scope="col" style={{ width: "10%" }} >Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.material_loading
                        ? <tr className="text-center"><td colSpan={4}><Loader /></td></tr>
                        : this.state.material_list && this.state.material_list.length > 0
                            ? this.state.material_list.map((mt, index) => {
                                return (<tr className="text-center">
                                    <td >{index + 1}</td>
                                    <td className="text-start">{mt.material_name}</td>
                                    <td><Status color={mt.status_data.color_code}>{mt.status_data.status}</Status></td>
                                    <td>  <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#"
                                        role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                            <li className="dropdown-item"  onClick={() => this.addMaterialFormInit(mt.id,mt.material_name)}>Edit</li>
                                         <li className="dropdown-item" onClick={() => this.changeMaterialStatus(mt.id,mt.status)}>{mt.status == 1 ? "Deactivate" : "Activate"}</li>
                                        </ul></td>
                                </tr>)
                            })
                            : <tr className="text-center"><td colSpan={4}>No Records</td></tr>}

                </tbody>
            </table>
            {this.addMaterialFormModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(MaterialList);