import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import TapApiUrls from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import {DisplayListPagination,PeriodSelectionWithCustom,DisplaySearchCriteria} from '../../../components/TapUi';
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapSelect from "../../../components/ui/TapSelect";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../components/AppBaseComponent';
import swal from "sweetalert";
import FundTransferDetailModal from './FundTransferDetailModal';
import { Helmet } from 'react-helmet';

class FundTransferList extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.fundTransferSearchFormIntState         =          {
            'date_range'                            :          '',
            'date_range_start'                      :          '',
            'date_range_end'                        :          '',
            'wallet_ids'                            :          [],
            'search_transaction_id'                 :          '',
        }

        this.fundTransferSearchFormLabel            =           {
            'date_range'                            :           'Date Range : ',
            'wallet_ids'                            :           'Wallets : ',
            'search_transaction_id'                 :           'Transaction ID : '       
        }

        this.state                                 =            {
            fundTransferAddScreenView              :            false,
            fundTransferUpdateScreenView           :            false,
            minimizeTable                          :            false,
            fundTransferList                       :            [],
            listing_loading                        :            false,
            form_loading                           :            false,
            listing_tbl_page                       :            1,
            listingMeta                            :           null,
            totalListingCount                      :           0,
            filterFormData                         :           [],
            allWalletTypes                         :           [],
            formSearchedElems                      :           [],
            viewData_loading                       :           false,
            fundTransferViewDetails                :           [],
            fundTransferSearchForm                 :           {...this.fundTransferSearchFormIntState},
            submittedFundTransferFilterForm        :           {...this.fundTransferSearchFormIntState},
          };
    }

    componentDidMount() {
        let ParamObject                 =       new URLSearchParams(this.props.location.search);
        if(ParamObject.get('search_fund_id')){
            this.viewFundTransferDetail(ParamObject.get('search_fund_id'))
        }
      this.loadListingTblData(1)
      this.filterFormDataInit()
      this.fundTransferSearchModal                =          new Modal(document.getElementById('fundTransferSearchModal'), {keyboard: false, backdrop :false});
    }
    
    loadListingTblData                            =         (page = 1)     =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        
        let params ;
        if(this.state.submittedFundTransferFilterForm.date_range !== ''){
           params                      =   {page:page,...this.state.submittedFundTransferFilterForm};
        }else{
            params                      =   {page:page,wallet_ids : this.state.submittedFundTransferFilterForm.wallet_ids,search_transaction_id : this.state.submittedFundTransferFilterForm.search_transaction_id};  
        }
        
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/wallet/fund_transfer_list', this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            let meta = {
                current_page       :    respData.current_page,
                from               :    respData.from,
                last_page          :    respData.last_page,
                path               :    respData.path,
                per_page           :    respData.per_page,
                to                 :    respData.to,
                total              :    respData.total,
            }
            this.setState({
                fundTransferList    :    respData.data,
                listingMeta         :    meta,
                totalListingCount   :    meta.total,
        });
     }).then(() => this.setState({listing_loading: false}));
    }

    filterFormDataInit                           =          ()            =>           {
        this.setState({form_loading: true});
           HttpAPICall.withAthorization('GET',  TapApiUrls.FUND_TRANSFER_FORM_DATA , this.props.access_token, {}, {}, (resp) => {
           this.setState({filterFormData : resp.data,
                         allWalletTypes  :    resp.data.wallets.map((s) => { return {value: s.id, label: s.wallet_name}})
           })
        }).then(() => {this.setState({form_loading: false})})
    }

    openAddFundTransferScreen                    =          ()            =>           {
      this.props.history.push({pathname: '/wallet/fund-transfer', state: { prevPath: this.props.location.pathname }})
    }

    editFundTransfer                            =         (id)           =>           {
        this.props.history.push({pathname: `/wallet/fund-transfer/${id}`, state: { prevPath: this.props.location.pathname,id : id }})
    }

    viewFundTransferDetail                      =          (id)          =>           {
        this.setState({viewData_loading: true,minimizeTable : true});
           HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/wallet/fund_transfer/' + id, this.props.access_token, {}, {}, (resp) => {
           this.setState({fundTransferViewDetails : resp.data})
        },(error) => {
            this.setState({minimizeTable : false})
            this.loadListingTblData(1)
        }).then(() => {this.setState({viewData_loading: false})})
    }

    closeViewDetails                            =           ()           =>            {
        this.setState({minimizeTable  :  false})
        let ParamObject                 =       new URLSearchParams(this.props.location.search);
        if(ParamObject.get('search_fund_id')){
            this.props.history.push('/fund_transfer')
        }
    }

    fundTransferModalInit                       =           ()           =>            {
        this.fundTransferSearchModal.show()
       
    }

    submitFundTransferSearchForm                =        (e = null)       =>           {
        e && e.preventDefault()
       let serachFormKeys              =   Object.keys(this.state.fundTransferSearchForm);
      
       let searchedElems               =   [];
       let searchParams                =   {};
       serachFormKeys.map((key)        =>  {
           let label                       =   this.fundTransferSearchFormLabel[key];
           let value                       =   this.state.fundTransferSearchForm[key];
           if(value  && value.toString().length > 0) {
               searchParams[key]      =   value;
          if(label) {
                   let show_val            =   value;
                   if(key == 'wallet_ids') {
                       show_val            =   this.state.allWalletTypes.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                   }
                   if(key == 'search_transaction_id') {
                    show_val            =      this.state.fundTransferSearchForm.search_transaction_id;
                  }
                   if(key == 'date_range') {
                    show_val            = this.state.filterFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
                }
                  
                 searchedElems.push(label + `<b>${show_val}</b>`);

               }
           }
       });
       this.setState({
           submittedFundTransferFilterForm   :   {...this.state.fundTransferSearchForm},
           formSearchedElems                :   searchedElems
       }, () => {
           this.fundTransferSearchModal.hide()
           this.loadListingTblData(1);
       });
   } 
   
    fundTransferSearchClear                     =           ()           =>            {
        this.setState({
             formSearchedElems                 :     [],
             fundTransferSearchForm           :     {...this.fundTransferSearchFormIntState },
             submittedFundTransferFilterForm  :     { ...this.fundTransferSearchFormIntState }
        }, () => {
            this.loadListingTblData(1);
            this.fundTransferSearchModal.hide();
            this.filterFormDataInit()
            this.props.history.push('/fund_transfer')
        });
    }
 
    deleteFundTransfer                          =          (id)          =>            {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_API_SERVER + '/wallet/fund_transfer/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.setState({minimizeTable:false})
                       this.loadListingTblData(1)
                      
                    }).then(() => {

                        });
                }
            });
    }

    fundTransferListjsx                         =           ()           =>             {

        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Fund Transfer</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <button type="button" onClick={() => {
                            if (this.props.permissions.includes('iam-wallet-fund-add')) {
                                return this.openAddFundTransferScreen();
                            }
                        }}
                            className="btn btn-primary" disabled={!this.props.permissions.includes('iam-wallet-fund-add') ? true : false}
                            title={!this.props.permissions.includes('iam-wallet-fund-add') ? 'You do not have permission to perform this action' : ''}
                            style={{ "pointerEvents": "all" }} >Add</button> : null}
                    <button type="button" className="btn btn-secondary" onClick={this.fundTransferModalInit}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable ? <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {this.state.minimizeTable ? <li>  <a role="button" href
                                onClick={() => {
                                    if (this.props.permissions.includes('iam-wallet-fund-add')) {
                                        return this.openAddFundTransferScreen;
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('iam-wallet-fund-add') ? '' : 'disabled',].join(' ')}
                                title={!this.props.permissions.includes('iam-wallet-fund-add') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Fund Transfer</a></li> : null}
                        </ul>
                    </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Fund Transfer : {this.state.fundTransferViewDetails?.activity_id} </h3>
                            <div className="text-end mt15">
                                <button type="button" onClick={() => {
                                    if (this.props.permissions.includes('iam-wallet-fund-edit')) {
                                        return this.editFundTransfer(this.state.fundTransferViewDetails?.activity_id);
                                    }
                                }}
                                    className="btn btn-secondary" disabled={!this.props.permissions.includes('iam-wallet-fund-edit') ? true : false}
                                    title={!this.props.permissions.includes('iam-wallet-fund-edit') ? 'You do not have permission to perform this action' : ''}
                                    style={{ "pointerEvents": "all" }} >Edit</button>

                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a role="button" href
                                        onClick={() => {
                                            if (this.props.permissions.includes('iam-wallet-fund-delete')) {
                                                return this.deleteFundTransfer(this.state.fundTransferViewDetails?.activity_id);
                                            }
                                        }}
                                        className={['dropdown-item', this.props.permissions.includes('iam-wallet-fund-delete') ? '' : 'disabled',].join(' ')}
                                        title={!this.props.permissions.includes('iam-wallet-fund-delete') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}>Delete</a></li>
                                </ul>

                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.fundTransferListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.fundTransferViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }
 
    fundTransferListingTableJsx                 =           ()            =>            {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.fundTransferSearchClear} />
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Fund Transfer</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                            <th scope="col" style={{ width: "12%" }} className='text-center'>Date of Transaction</th>
                            <th scope="col" style={{ width: "14%" }}>Transaction ID</th>
                            <th scope="col" style={{ width: "20%" }}>Wallet From</th>
                            <th scope="col" style={{ width: "20%" }}>Wallet To</th>
                            <th scope="col" style={{ width: "12%" }}>Reference No.</th>
                            <th scope="col" style={{ width: "12%" }} className='text-end'>Amount</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="8"><Loader /></td></tr>)
                        : (this.state.fundTransferList.length > 0
                            ? (this.state.fundTransferList.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <div className="text-capitalize link-primary" onClick={() => this.viewFundTransferDetail(item?.activity_id)}><small>{item?.wallet_from?.wallet_name} <span className='text-dark'>to</span>  {item?.wallet_to?.wallet_name}</small></div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{item?.transfer_date_display} </small>
                                                            <small className="float-end text-capitalize">{item?.amount} </small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-center'>{item?.transfer_date_display}</td>
                                                <td className='link-primary cursor-pointer' onClick={() => this.viewFundTransferDetail(item?.activity_id)}>{item?.activity_id}</td>
                                                <td className='text-capitalize'>{item?.wallet_from?.wallet_name}</td>
                                                <td className='text-capitalize'>{item?.wallet_to?.wallet_name}</td>
                                                <td>{item?.ref_no ?? "-"}</td>
                                                <td className='text-end'>{item?.amount}</td>
                                                <td className="text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                        alt="action-icon"
                                                        className="img-fluid mx-4"
                                                        data-bs-toggle="dropdown" href="#"
                                                        role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className='dropdown-item' onClick={() => this.viewFundTransferDetail(item?.activity_id)}>View</li>

                                                    </ul></td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    fundTransferViewDetails                      =          ()             =>           {
        return (<div className="page_containt">
            {this.state.viewData_loading ? <Loader/>
            :  <FundTransferDetailModal fundTransferData={this.state.fundTransferViewDetails}/>}
        </div>
        )
    }

    fundTransferSearchModalJsx                   =          ()             =>           {
       
        return (
            <div className="modal fade" id="fundTransferSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Fund Transfer Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.form_loading ? <Loader />
                            : <form id="fundTransferSearchForm" onSubmit={this.submitFundTransferSearchForm}>
                                <div className="modal-body">
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Wallet</label>
                                        <div className="col-sm-4">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                placeholder="Select Wallet"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isMulti={true}
                                                options={this.state.allWalletTypes}
                                                value={this.state.allWalletTypes.filter(s => this.state.fundTransferSearchForm.wallet_ids.includes(s.value))}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'fundTransferSearchForm', 'wallet_ids')}

                                            />
                                        </div>
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction ID</label>
                                        <div className="col-sm-4">
                                            <input
                                                name="search_transaction_id"
                                                type="text"
                                                value={this.state.fundTransferSearchForm.search_transaction_id}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "fundTransferSearchForm")}
                                                placeholder="Enter Transaction ID"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Date Range</label>
                                        <div className="col-sm-10">
                                            <PeriodSelectionWithCustom
                                                periods={this.state.filterFormData && this.state.filterFormData.periods !== undefined ? this.state.filterFormData.periods : []}
                                                required={false}
                                                value={this.state.fundTransferSearchForm.date_range}
                                                startDate={this.state.fundTransferSearchForm.date_range_start}
                                                endDate={this.state.fundTransferSearchForm.due_date_range_end}
                                                onSelectPeriod={(period, startDate, endDate) => {
                                                    this.setState({
                                                        fundTransferSearchForm: { ...this.state.fundTransferSearchForm, date_range: period, date_range_start: startDate, date_range_end: endDate }
                                                    });
                                                }}
                                                className="form-control-sm"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Search</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        );
    }

    render                                      =           ()              =>           {
        return (<ApplicationLayout>
              <Helmet><title>Fund Transfer List</title></Helmet>
            {this.fundTransferListjsx()}
            {this.fundTransferSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :   state.app.acl_info.permissions
    };
};

export default connect(mapStateToProps)(FundTransferList);