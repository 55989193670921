import React from 'react';
import AdditionalAttribute from '../AdditionalAttribute';

export default class AssetAttributeList extends React.Component {

 
    render() {

        return (
            <section>
                <AdditionalAttribute category="asset"/>
            </section>
        );
    }
}