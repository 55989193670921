import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import HttpAPICall from '../../../services/HttpAPICall';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import Ax from '../../../components/hoc/Ax';
import ReactSelectStyle from '../../../components/ui/ReactSelectStyle';

class InputAssetSearch extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                  =   {
            selectedAsset               :   null,
            timer                       :   false,
            defaultOptions              :   [],
            
        };
        this.cancelToken            =   null;
        this.apiTimeout             =   null;
    }
    
    initParams                          =   (props)  =>  {
        this.setState({
            params                      :   {
                cl_attribute                :   props.clAttribute,
                search_asset_type           :   props.search_asset_type,
                search_cl_meters            :   props.clMeters,
                search_site                 :   props.search_site_id && Array.isArray(props.search_site_id) ? props.search_site_id : (props.search_site_id ? [props.search_site_id] : '')
            }
        }, () => {
            if(this.props.loadDefaultOptions == true) {
                this.getDefaultOptions();
            }
        });
    }
    
    componentDidMount() {
        this.initParams(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.search_site_id !== this.props.search_site_id) {
            this.initParams(nextProps);
        }
    }

    onInputChange                   =   (selectedOption)  =>  {
        this.props.changeEvent(selectedOption);
    }
    
    displayStringSearch             =   (assets)  =>  {
        return assets.map(asset => {
            return {
                label               :   (<Ax>
                                            <b>Asset : </b> {asset.name} ({asset.asset_code}) <br/>
                                            <b>Asset Type : </b> {asset.asset_type_name}   <br/>
                                            <b>Manufacturer : </b> {asset.manufacturer_name} ({asset.model_name})   <br/>
                                            <b>Site : </b> {asset.site} ({asset.site_code})
                                        </Ax>),
                value               :   asset.asset_id,
                display_label       :   `${asset.name} (${asset.asset_code})`,
                asset               :   asset
            };
        });
    };
    
    loadingOptions                  =   (inputText,callback)  =>  {
        if(inputText.length > 2) {
            clearTimeout(this.apiTimeout)
            if (this.cancelToken) {
                this.cancelToken.cancel("Operation canceled due to new request.")
            }
            this.cancelToken = axios.CancelToken.source();

            let params          = {...this.state.params, search_by_text: inputText, per_page: 50};
            
            this.apiTimeout  = setTimeout(() => {this.getLoadingOptions(params,callback)},1000);
        }
    }

    getLoadingOptions         =        (params,callback)            =>         {
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params: params,
            url: process.env.REACT_APP_IAM_API_SERVER + '/assets',
            cancelToken: this.cancelToken.token
        })
        .then((response) => {
            callback(this.displayStringSearch(response.data.data))
        }).catch((err) => {
            if (axios.isCancel(err)) {
              
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        });
    }

    getDefaultOptions               =       ()              =>          {
        this.setState({defaultOptions : []});
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token },
            params: this.state.params,
            url: process.env.REACT_APP_IAM_API_SERVER + '/assets',
        })
        .then((response) => {
            this.setState({
                defaultOptions:     this.displayStringSearch(response.data.data)
            });
        }).catch((err) => {
            if (axios.isCancel(err)) {
               
            } else {
                ErrorValidation.apiErrorHandle(err, 'Unable to Get / Save Application Data.');
            }
        });
        
      
//        this.getLoadingOptions()
    }
    
    render() {
          return (<Ax>
            <AsyncSelect
                styles          =   {ReactSelectStyle(this.props)}
                menuPlacement   =   {this.props.menuPlacement || "bottom"}
                placeholder     =   {this.props.placeholder || "Search Asset by Name, Code, Asset Type & Manufacturer" }
                loadingMessage  =   {(input) => {
                    if(input.inputValue && input.inputValue.length <=2 ) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'Searching...';
                    }
                }}
                noOptionsMessage  =   {(input) => {
                    if(input.inputValue.length == 0) {
                        return 'Please enter 3 or more characters';
                    } else {
                        return 'No Asset found';
                    }
                }}
                minimumInput    =   {5}
                complete        =   {true}
                onChange        =   {this.onInputChange}
                isClearable     =   {this.props.isClearable || true}
                loadOptions     =   {this.loadingOptions}
                value           =   {this.props.value}
                isMulti         =   {this.props.isMulti || false}
                required        =   {true}
                isDisabled      =   {this.props.disabled || false}
                defaultOptions  =   {this.props.loadDefaultOptions == true ? this.state.defaultOptions  : null }
            />
            <input
                name=''
                tabIndex={-1}
                required={this.props.required || false}
                value={this.props.isMulti ? (this.props.value.length > 0 ? this.props.value[0].label : [].join(' ')) : (this.props.value ? this.props.value.label : '')}
                onChange={() => {} }
                style={{
                    opacity: 0,
                    width: 0,
                    height: 0,
                    padding: 0,
                    margin: 0,
                    display: 'table'
                  }}
            />        
            </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(InputAssetSearch);