import React from "react";
import { Modal } from "bootstrap";
import AppBaseComponent from "../../../components/AppBaseComponent";
import tapIcon from "../../../services/TapIcon";
import { connect } from "react-redux";
import HttpAPICall from "../../../services/HttpAPICall";
import { Link } from "react-router-dom";
import download from "../../../services/DownloadFile";
import { toast } from "react-toastify";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import axios from "axios";

class BulkEducationUpload extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      educationBulkUploadFormSubmitting: false,
    };

    this.emp_education_download_sample_url = HRM_API_BASE_URL_2 + '/employee_education_download_sample';
    this.emp_education_add_by_excel = HRM_API_BASE_URL_2 + '/employee_education_add_by_excel';

  }

  componentDidMount() {
    this.educationBulkUploadModal = new Modal(
      document.getElementById("educationBulkUploadModal"),
      { keyboard: false, backdrop: false }
    );
  }

  modalHandle = () => {
    this.educationBulkUploadModal.show();
  };

  downloadSampleFile = () => {
    HttpAPICall.withAthorization("GET", this.emp_education_download_sample_url, this.props.access_token, {}, {}, (response) => {
        download.file(response.data.file_path);
        toast.success(
          "Employee Education Sample Excel File Downloaded Successfully",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        this.educationBulkUploadModal.hide();
      }
    );
  };

  formSubmit = (e) => {
    e.preventDefault();
    this.setState({
      educationBulkUploadFormSubmitting: true,
    });
    const data = new FormData(
      document.getElementById("educationBulkUploadForm")
    );
    let headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + this.props.access_token,
    };
    axios
      .post(this.emp_education_add_by_excel, data, {
        headers: headers,
      })
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.educationBulkUploadModal.hide();
        //Load Add Item Form Data :-
        download.file(response.data.generated_file.file_path);
        setTimeout(() => {
          this.props.loadListingTblData();
        }, 1000);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
            "Unable to upload Education Add File.",
          { position: toast.POSITION.TOP_RIGHT }
        );
      })
      .then(() => {
        this.setState({ educationBulkUploadFormSubmitting: false });
      });
  };

  render() {
    return (
      <div className="modal fade" id="educationBulkUploadModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="educationBulkUploadModalLabel">
                Bulk Education Upload
              </h5>
              <button
                type="button"
                disabled={this.state.educationBulkUploadFormSubmitting}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={this.formSubmit}
              id="educationBulkUploadForm"
              method="post"
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="row align-items-center mb-3 mt-3">
                  <div className="col-12">
                    <input
                      name="document"
                      type="file"
                      className="form-control"
                      required={true}
                    />
                  </div>
                  <div className="col-12 mt-2 form-text">
                    # Excel Sheet with Extention xls,xlm,xlsx,ods Allowed
                  </div>
                  <div className="col-12 mt-2 form-text">
                    # Miximum 100 MB Size of Excel Sheet Allowed
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <div>
                  <Link
                    type="button"
                    className="float-left"
                    href="#"
                    onClick={this.downloadSampleFile}
                  >
                    Download Sample File
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={this.state.educationBulkUploadFormSubmitting}
                    className="btn btn-secondary mx-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    disabled={this.state.educationBulkUploadFormSubmitting}
                    className="btn btn-primary"
                  >
                    Submit{" "}
                    {this.state.educationBulkUploadFormSubmitting ? (
                      <tapIcon.FontAwesomeIcon
                        icon={tapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  BulkEducationUpload
);
