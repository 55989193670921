import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon";
import Loader from "../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
import TapSelect from '../../components/ui/TapSelect';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../components/TapUi';
import AppBaseComponent from '../../components/AppBaseComponent';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import Button from '../../components/ui/Button';
import InputAssetSearch from '../includes/ui/InputAssetSearch';
import DatePicker from 'react-datepicker';
import TapIcon from '../../services/TapIcon';




 class AssetRosterListing extends AppBaseComponent {

    constructor() {
        super();

        this.initRosterSearchForm         =       {
            search_roster_ids             :       [],
            search_site_ids               :       [],
            'asset_group_ids'               :    [],
            search_asset_type_ids         :       [],
            search_asset_category         :       '',
            search_asset_ids              :       []
        }

        this.rosterSearchFormLabel        =       {
            search_roster_ids             :       'Linked Roster : ',
            search_site_ids               :       'Sites : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            search_asset_type_ids         :       'Asset Type : ',
            search_asset_category         :       'Asset Category : ',
            search_asset_ids              :       'Assets : ',
        }

        this.initRosterMappingForm          =       {
            activation_date                 :       '',
            roster_id                       :       ''
        }

        this.state = {
            listing_loading               :        false,
            listing_tbl_page              :        1,
            listingMeta                   :        null,
            asset_roster_listing          :         [],
            totalListingCount             :         0,
            form_data_loading             :         false,
            formDataLoaded                :         false,
            searchedAssets                :         [],
            iam_asset_types               :         [],
            iam_user_sites                :         [],
            iam_asset_category            :         [],
            rosterFilterForm              :         {...this.initRosterSearchForm},
            submittedRosterFilterForm     :         {...this.initRosterSearchForm},
            rosterMappingForm               :       {...this.initRosterMappingForm},
            formSearchedElems             :         [],
            allRosterList                 :         [],
            formDataLoaded                :         false,
            saveFormSubmitting            :         false,
            all_roster_lists              :         [],
            mappingFormDataLoaded         :         false,
            allAssetTypesGroupsList         :   []
        }   
    }

    componentDidMount(){  
        this.initilaizeAppDataToFilterForm(this.props);
        this.loadListingTblData(1)
        this.rosterSearchModal              =       new Modal(document.getElementById('rosterSearchModal'), {keyboard: false, backdrop :false});
        this.rostertMappingModal            =      new Modal(document.getElementById('rostertMappingModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }

    initilaizeAppDataToFilterForm           =   (props)  =>  {
        if(!this.state.formDataLoaded){
            this.setState({form_data_loading : true})
            HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/asset/asset_with_roster_form_data' , this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data
                this.setState({
                    iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                    formDataLoaded          :   true,
                    iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                    iam_asset_category      :   respData && respData.data.asset_category && respData.data.asset_category.length > 0 ? respData.data.asset_category.map(ac => { return {value: ac.key, label: ac.name}}) : [],
                    allRosterList           :   respData && respData.data.rosters && respData.data.rosters.length > 0 ? respData.data.rosters.map(ac => { return {value: ac.id, label: ac.roster_name}}) : [],
                    allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                });
            }).then(() => this.setState({form_data_loading: false}));           
        }
    
    }

    rosterSearchModalInit       =       ()      =>  {
        this.rosterSearchModal.show()
    }

     //************************GET consumption LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
         this.setState({listing_loading: true, listing_tbl_page: page});
         let params                      =   {page:page,...this.state.submittedRosterFilterForm};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/asset/asset_with_roster', this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;

            this.setState({
                asset_roster_listing          :       respData.data,
                listingMeta                    :       respData.meta,
                totalListingCount              :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
       
    }

    submitRosterSearchForm          =   (event)  =>  {
        event && event.preventDefault()
        let serachFormKeys                      =   Object.keys(this.state.rosterFilterForm);
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.rosterSearchFormLabel[key];
            let value                       =   this.state.rosterFilterForm[key];
            if(value && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.iam_asset_category.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_roster_ids') {
                        show_val            = this.state.allRosterList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedRosterFilterForm           :   {...this.state.rosterFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListingTblData(1);
            this.rosterSearchModal.hide()
        });
    }

    clearSearchForm         =       ()      =>      {
        this.setState({
            rosterFilterForm             :           {...this.initRosterSearchForm},
            formSearchedElems            :          [],
            submittedRosterFilterForm    :          {...this.initRosterSearchForm},
            searchedAssets               :          []
        }, () => {
            this.loadListingTblData(1);
            this.rosterSearchModal.hide()
        })
    }

    checkAllList() {
        let rosterCheckboxCollections      =   document.getElementsByClassName('roster_id_checkbox')
        if(rosterCheckboxCollections && rosterCheckboxCollections.length > 0) {
            for (let i = 0; i < rosterCheckboxCollections.length; i++) {
                rosterCheckboxCollections[i].checked = document.getElementById('all_check_roster').checked;
            }
        }
    }

    getRosterMappingFormData        =       ()      =>      {
        this.setState({form_data_loading: true});
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/asset/roster_listing', this.props.access_token,{}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                all_roster_lists        :    respData,
                mappingFormDataLoaded   :    true
            });
        }).then(() => this.setState({form_data_loading: false}));
    }

    linkRosterModalInit                  =   (id)  =>  {
        this.setState({
            rosterMappingForm: { ...this.initRosterMappingForm },
        })
        this.rostertMappingModal.show()
        this.setState({ selectedAssetId: id })
        if (!this.state.mappingFormDataLoaded) {
            this.getRosterMappingFormData()
        }

    }

    submitRosterHistoryForm      =       (e)     =>      {
        e.preventDefault();
       
        let frmData = { ...this.state.rosterMappingForm, asset_id: this.state.selectedAssetId }
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/asset/roster_change', this.props.access_token, {}, { ...frmData }, (resp) => {
            toast.success(resp.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.rostertMappingModal.hide();
            this.loadListingTblData(1)
            this.setState({
                rosterMappingForm: { ...this.initRosterMappingForm },
            })
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    assetRosterListJsx            =       ()      =>    {

        return (<Ax><div className="page_title row m0">
            <div className={"col-12"}>
                <h3>Asset Roster List</h3>
                <div className="text-end mt15">
                  
                    <Button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={this.rosterSearchModalInit}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} />
                    </Button>
                </div>
            </div>
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', 'col-12'].join(' ')} >{this.assetRosterTableJsx()}</div>
                  
                </div>
            </div>
        </Ax>);
    }

    
    gotoAssetProfile            =      (asset_id)   =>  {
        this.props.history.push(`/assets/asset_setting/${asset_id}`)
    }

     //***********************SEARCH roster JSX****************
     rosterSearchModalJsx               =        ()             =>        {
         return (
             <div className="modal fade" id="rosterSearchModal" tabIndex="-1">
                 <div className="modal-dialog modal-lg">
                     <div className="modal-content">
                         <div className="modal-header">
                             <h5 className="modal-title" id="searchAssetModalLabel">Roster Search</h5>
                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                         </div>
                         <form id="rosterSearchForm" onSubmit={this.submitRosterSearchForm}>
                             {this.state.form_data_loading ? <Loader />
                                 : <div className='modal-body'>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Name</label>
                                         <div className="col-sm-10">
                                             <InputAssetSearch
                                                 changeEvent={(selectedOption) => {
                                                     this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_asset_ids');
                                                     this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                 }}
                                                 isMulti={true}

                                                 style={{ height: "30px", fontSize: "93%" }}
                                                 value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Type Group</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.allAssetTypesGroupsList}
                                                 changeEvent={(selectedOption) => {

                                                     this.setState({
                                                         rosterFilterForm: {
                                                             ...this.state.rosterFilterForm,
                                                             asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                             search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                         }
                                                     })
                                                 }}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 value={this.state.allAssetTypesGroupsList.filter(s => this.state.rosterFilterForm.asset_group_ids.includes(s.value))}
                                                 placeholder="Select Asset Type Group"
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                             />
                                         </div>


                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Type</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.iam_asset_types}
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_asset_type_ids')}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 value={this.state.iam_asset_types.filter(s => this.state.rosterFilterForm.search_asset_type_ids.includes(s.value))}
                                                 placeholder="Select Asset Type"
                                             />
                                         </div>


                                     </div>
                                     <div className="row align-items-center mb-3">
                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Site</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.iam_user_sites}
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_site_ids')}
                                                 isSearchable={true}
                                                 isClearable={true}
                                                 isMulti={true}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 value={this.state.iam_user_sites.filter(s => this.state.rosterFilterForm.search_site_ids.includes(s.value))}
                                                 placeholder="Select Sites"
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">

                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Asset Category</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.iam_asset_category}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_asset_category')}
                                                 value={this.state.iam_asset_category.find(r => r.value === this.state.rosterFilterForm.search_asset_category)}
                                                 placeholder="Select Asset Category"
                                             />
                                         </div>

                                     </div>
                                     <div className="row align-items-center mb-3">

                                         <label className="col-sm-2 col-form-label col-form-label-sm ">Linked Roster</label>
                                         <div className="col-sm-10">
                                             <TapSelect
                                                 options={this.state.allRosterList}
                                                 containerHeight="30px"
                                                 fontSize="93%"
                                                 changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rosterFilterForm', 'search_roster_ids')}
                                                 value={this.state.allRosterList.filter(r => this.state.rosterFilterForm.search_roster_ids.includes(r.value))}
                                                 placeholder="Select Linked Roster"
                                                 isMulti={true}
                                             />
                                         </div>

                                     </div>

                                 </div>}
                             <div className="modal-footer">
                                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                 <button type="submit" className="btn btn-primary">Search</button>
                             </div>
                         </form>
                     </div>
                 </div>
             </div>
         );
    }
    
    rostertMappingModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="rostertMappingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Roster Mapping</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.form_data_loading ? <div className='text-center'><Loader /></div>
                            : <div className="modal-body">
                                <form onSubmit={this.submitRosterHistoryForm} id="addRosterHistoryForm">
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm require"> Activation Date</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.rosterMappingForm.activation_date
                                                        ? moment(this.state.rosterMappingForm.activation_date, 'YYYY-MM-DD').toDate()
                                                        : false
                                                }
                                                name="activation_date"
                                                onChange={(value, event) => this.formDateHandler('activation_date', value, 'rosterMappingForm')}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Activation Date`}
                                                required={true}
                                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label col-form-label-sm require">Select Roster</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'rosterMappingForm', 'roster_id');
                                                }}
                                                options={this.state.allRosterList}
                                                isSearchable={true}
                                                required={true}
                                                value={this.state.allRosterList.find(m => m.value == this.state.rosterMappingForm.roster_id)}
                                                placeholder="Please Select Roster"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <table className="table my-3 table-hover table-bordered">
                                        <thead className='bg-light'>
                                            <tr>
                                                <td style={{ width: "5%" }} className="text-center">S.No</td>

                                                <td style={{ width: "10%" }}>Roster ID</td>
                                                <td style={{ width: "25%" }}>Roster Name</td>
                                                <td style={{ width: "15%" }} className="text-center">No of Shifts</td>
                                                <td style={{ width: "20%" }}>Shift Name</td>
                                                <td style={{ width: "15%" }}>Shift Start Time</td>
                                                <td style={{ width: "10%" }}>Shift Hours</td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.all_roster_lists && this.state.all_roster_lists.length > 0
                                                ? this.state.all_roster_lists.map((roster, k) => {
                                                    return (<Ax>
                                                        {roster.shifts && roster.shifts.length > 0
                                                            ? roster.shifts.map((ros, key) => {
                                                                return (<tr>
                                                                    {key == 0 && <Ax>
                                                                        <td className="text-center" rowSpan={roster.no_of_shift}>{k + 1}</td>
                                                                        <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.tap_roster_id ? roster.tap_roster_id : "-"}</td>
                                                                        <td rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.roster_name ? roster.roster_name : "-"}</td>
                                                                        <td className="text-center" rowSpan={roster.no_of_shift > 0 ? roster.no_of_shift : 1}>{roster.no_of_shift ? roster.no_of_shift : "0"}</td>

                                                                    </Ax>}
                                                                    <td>{ros.shift_name}</td>
                                                                    <td>{ros.shift_start}</td>
                                                                    <td>{ros.shift_time}</td>
                                                                </tr>)
                                                            }) : null}
                                                    </Ax>)
                                                })
                                                : <tr><td colSpan={6} className="text-center">No Record</td></tr>}
                                        </tbody>


                                    </table>
                                </form>

                            </div>}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="addRosterHistoryForm">Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    assetRosterTableJsx            =   ()   =>   {
      
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.clearSearchForm} />
            <table className="table table-bordered bg-white ">
                <thead className="table-secondary">

                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }} className="text-center" >S.No</th>
                        <th scope="col" style={{ width: "17%" }} className="text-start">Asset Name</th>
                        <th scope="col" style={{ width: "14%" }} className="text-start">Asset Code</th>
                        <th scope="col" style={{ width: "14%" }}>Asset Type</th>
                        <th scope="col" style={{ width: "14%" }}>Site</th>
                        <th scope="col" style={{ width: "20%" }}>Linked Roster</th>
                        <th scope="col" style={{ width: "10%" }}>No of Shifts</th>
                        <th scope="col" style={{ width: "5%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.asset_roster_listing.length > 0
                            ? (this.state.asset_roster_listing.map((item, index) => {
                                return (<tr key={index} >

                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>

                                    <td>{item.name ? item.name : "-"}</td>
                                    <td>{item.asset_code ? item.asset_code : "-"}</td>
                                    <td>{item.asset_type_name ? item.asset_type_name : "-"}</td>
                                    <td>{item.site ? <span>{item.site}</span> : "-"}</td>
                                    <td>{item.roster_detail ? item.roster_detail.roster_name : "-"}</td>
                                    <td className="text-center">{item.roster_detail ? <div>{item.roster_detail.no_of_shift}</div> : "0"}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className={!this.props.permissions.includes('iam-asset-roster-add')  ? "dropdown-item disabled" : "dropdown-item"} role="button" onClick={() => this.linkRosterModalInit(item.asset_id)}>Roster Mapping</a></li>
                                            <li><a className={item.can_access == false ? "dropdown-item disabled" : "dropdown-item"} role="button" onClick={() => this.gotoAssetProfile(item.asset_id)}>View Asset Settings</a></li>
                                        </ul>
                                    </td>

                                </tr>)
                            }))
                            : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    render() {
       
        return (
            <ApplicationLayout>
                 <Helmet><title>Asset Roster Linking - List</title></Helmet>
                {this.assetRosterListJsx()}
                {this.rosterSearchModalJsx()}
                {this.rostertMappingModalJsx()}
              
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
        asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],    
    };
};

export default connect(mapStateToProps)(AssetRosterListing);

