import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from "moment";
import {Chart} from "react-google-charts";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class CounterlogAgeingWidget extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            iam_user_sites                  :   [],
            loading                         :   false,
            data                            :   null,
            formData                        :   {filter_site_id : ''}
        }
        this.updateMyDashboardRef      =   React.createRef();
    }
    
    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('counterlogAgeingWidget')) {
            let counterlogAgeingData = JSON.parse(localStorage.getItem('counterlogAgeingWidget'));
            
            if (counterlogAgeingData) {
                let lastRefreshTime = moment(counterlogAgeingData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('counterlogAgeingWidget')
                    setTimeout(() => {
                        this.getCounterlogAgeing();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 7));
                    
                } else {
                    this.setState({
                        data            :      counterlogAgeingData, 
                    })
                }

            }else{
                setTimeout(() => {
                    this.getCounterlogAgeing();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 7));
            }
        } else {
            setTimeout(() => {
                this.getCounterlogAgeing();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 7));;
        }
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }
    
    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
          this.setState({
            iam_user_sites                  :   props.iam_user_sites 
                                                    ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) 
                                                    : [],
          }, () => {
           
             if(this.props.dashboard_filter_site_id || props.clearDashboardFilter == 'yes'){
                   
                this.setState({
                    formData         :       {
                        filter_site_id          :       props.dashboard_filter_site_id,
                    }
                },() =>  this.getCounterlogAgeing())
            }
            
        });
      }
    }
    
    getCounterlogAgeing                 =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {...this.state.formData ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET',process.env.REACT_APP_IAM_API_SERVER + '/group/counterlog_ageing_data', this.props.access_token, params, {} , (response) => {
            this.setState({data : response.data})
            if(!this.state.formData.filter_site_id){
                localStorage.setItem('counterlogAgeingWidget',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('counterlogAgeingWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"counterlog_ageing",name:"Counterlog Ageing"})
    }
   
    render                      =   ()  =>  {
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("counterlog_ageing" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "counterlog_ageing")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Counterlog Ageing</h6>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r => r.value ===  this.state.formData.filter_site_id)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'formData', 'filter_site_id', () => this.getCounterlogAgeing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"/>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{cursor:"pointer"}} onClick={() => this.getCounterlogAgeing(true)}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                            <tr className="text-center">
                                <th style={{width:"50%"}} >Ageing in Days</th>
                                <th>Asset Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.loading
                                ? (<tr>
                                    <td className="text-center" colSpan="2"><Loader/></td>
                                </tr>)
                                : this.state.data?.data?.map((d,k) => {
                                    let obj = {'ageing' : d.from + "-" + d.to};
                                    if(this.state.formData && this.state.formData.filter_site_id){
                                        obj['site_id']  = this.state.formData.filter_site_id;
                                    }
                                    return (<tr key={k} className="text-center">
                                        <td>{d.display}</td>
                                        <td><Link to={{ pathname: "/report/asset_status", search: "?" + new URLSearchParams(obj).toString() }} >{d.asset_count}</Link></td>
                                    </tr>)
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p0">
                        { this.state.loading
                            ? <Loader />
                            : (this.state.data ? (<Chart
                                chartType="PieChart"
                                // loader={<Loader />}
                                data={[['Ageing in Days', 'Asset Count']].concat(this.state.data?.data?.map((d) =>
                                { return [d.display, d.asset_count]; }))}
                                options={{
                                    legend: {position: 'labeled',labeledValueText: 'both'},
                                    pieSliceText: 'none',
                                    sliceVisibilityThreshold :0,
                                    slices: this.state.data?.data?.map((d) => {return {color : d.color} }),
                                    chartArea: {width: '90%', height: '90%' },
                                }}
                                rootProps={{ 'data-testid': '1' }} />) : null)  
                        }
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                        {this.state.data ? `*Either no roster is linked with ${this.state.data.no_roster_assets} assets or there is no counterlog entries for these assets,
Hence there are not part of this report` : null}<br/>
                        Report as of {this.state.data ? this.state.data.time : "-"}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

export default connect(mapStateToProps)(CounterlogAgeingWidget);