import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactDatePicker from 'react-datepicker';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { connect, useSelector } from 'react-redux';
import Loader from '../../../components/ui/Loader/Loader';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import { toast } from 'react-toastify';
import {useParams} from 'react-router-dom';

const AssetAdditionalAttribute = () => {

    const access_token                                          =   useSelector(state => state.auth.access_token);
    const [showModal, setShowModal]                             =   useState(false);
    const [additionalAttrFormData, setAdditionalAttrFormData]   =   useState({});
    const [editingAttribute, setEditingAttribute]               =   useState(false);
    const [loading, setLoading]                                 =   useState(true); // Loading state
    const [linkAdditionAttrList, setLinkAdditionAttrList]       =   useState([]);
    const [allAdditionalAttr, setAllAdditionalAttr]             =   useState([]);
    const [saveFormSubmitting, setSaveFormSubmitting]           =   useState(false);

    let asset_additional_attributes_url                         =   IAM_API_BASE_URL_2 + '/asset/additional_attributes/__replace_asset_id';   

    const { id : asset_id } =  useParams();

    useEffect(() => { 
        getAssetAdditionAttr();
    }, []);

    const getAssetAdditionAttr = () => {
        setLoading(true);
        HttpAPICall.withAthorization("GET", asset_additional_attributes_url.replace('__replace_asset_id', asset_id), access_token, {}, {}, (response) => {
            const data = response.data;
            setLoading(false);
            setAllAdditionalAttr(data);
            setLinkAdditionAttrList(data);
        })
    }

    const handleAdditionalAttrModalData = (data) => {
        let updateFormState = [];
        data.map((k) => {
            let name = 'additional_attributes_' + k.key;
            updateFormState[name] = k.value
        });
        setAdditionalAttrFormData(updateFormState)
        setLinkAdditionAttrList(data);
    }

    const handleShow = (key = null) => {
        if (key) {
            const data = allAdditionalAttr.filter(val => val.key === key);
            handleAdditionalAttrModalData(data);
            setEditingAttribute(true);
        } else {
            handleAdditionalAttrModalData(allAdditionalAttr);
            setEditingAttribute(false);
        }
        setShowModal(true);
    };


    const handleClose = () => {
        setShowModal(false);
    };
    const handleAdditionAttrModal = (e) => {
        e.preventDefault();
        setSaveFormSubmitting(true);
        let additional_attributes = {};

        allAdditionalAttr.forEach((i) => {
            let name = 'additional_attributes_' + i.key;
            if (additionalAttrFormData[name]) {
                additional_attributes[i.key] = additionalAttrFormData[name] ? additionalAttrFormData[name] : '';
            }
        });

        let formData = { asset_id, additional_attribute: additional_attributes };

        HttpAPICall.withAthorization("POST", `${IAM_API_BASE_URL_2}/asset/additional_attribute_link`, access_token, {}, formData, (response) => {
            getAssetAdditionAttr();
            toast.success(response.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setShowModal(false); 
            setSaveFormSubmitting(false);
            setEditingAttribute(false);
        }).then(() => setSaveFormSubmitting(false))
        .catch((error) => {
            setSaveFormSubmitting(false); 
            setEditingAttribute(false);
        });
    }

    const editAttributeModalJsx = () => (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="bg-white p-1">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Additional Attribute</h5>
                            <button type="button" className="btn-close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                            <form id='additional_attr' onSubmit={handleAdditionAttrModal}>
                                <AdditionalAttributeForm
                                    additionalAttributes={linkAdditionAttrList}
                                    formState={additionalAttrFormData}
                                    onAttributeChange={(value, attributeName) => {
                                        setAdditionalAttrFormData({
                                            ...additionalAttrFormData, [attributeName]: value
                                        });
                                    }}
                                    formSmall={false}
                                />
                                <div className="modal-footer">
                                    <button type="button" disabled={saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                    <button type="submit" disabled={saveFormSubmitting} className="btn btn-primary">
                                        Save {saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Ax>
                <Helmet><title>Asset Additional Attribute</title></Helmet>
                <div className="page_containt">
                    <div className="pageTbl p-3" style={{ height: "100%" }}>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Additional Attribute</span>
                            {!loading && allAdditionalAttr?.length > 0 ? <div className="float-end">
                                <tapIcon.imageIcon icon={tapIcon.EditIcon} className="img-fluid cursor_pointer" onClick={() => handleShow()} alt="item-action" />
                            </div> : ""}
                        </div>
                        <table className="table table-hover table-borderless mt-1">
                            <tbody>
                                {
                                    loading
                                        ? <Loader />
                                        :
                                        allAdditionalAttr?.length > 0
                                            ?
                                            allAdditionalAttr.map(attr => (
                                                <tr key={attr.key}>
                                                    <td style={{ width: "34%" }}>{attr.name}</td>
                                                    <td className="fs14 fw500" style={{ width: "59%" }}>{attr.value}</td>
                                                    <td style={{ width: "7%" }} className="text-center">
                                                        <button data-bs-toggle="dropdown" style={{ border: "none", backgroundColor: "transparent" }}><tapIcon.imageIcon icon={tapIcon.ListActionIcon} className="img-fluid mr3 cursor_pointer " href="#" alt="item-action" /></button>
                                                        <ul className="dropdown-menu">
                                                            <li className="dropdown-item" onClick={() => handleShow(attr.key)}>Edit</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr className='text-center'><td colSpan={2}>No Records Found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {showModal && editAttributeModalJsx()}
            </Ax>
        </>
    );
}

export default AssetAdditionalAttribute;