import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import swal from "sweetalert";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import CounterlogExecuteMeterForm from '../../assets/counterlog/CounterlogExecuteMeterForm';
import DateService from '../../../services/DateService';
import CounterlogModal from '../../assets/counterlog/CounterlogModal';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';

class LinkingModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.linkAssetFormInit               =   {
            sub_assembly_id                  :       "",
            counterlog_transaction_id       :       '',
            date_time                    :       '',
            linked_hour_meter            :       '',
            linked_odo_meter             :       '',
            linked_opc                   :       ''
         };  

        this.state                  =   {
            access_token                :   '',
            transaction_id              :   '',
            date                        :   '',
            assetDetail                 :   null,
            linkAssetForm                   :   {...this.linkAssetFormInit},
            linkedAsset                     :   null,
            assetDetail                     :   null,
            proceedForm                     :   false,
            enable_hour_meter               :   'N',
            enable_odo_meter               :   'N',
            enable_opc                     :   'N',
            currentActionType               :   'link',
            selectedRosterDetail            :   null,
            roster_loading                  :   false,
            selectedSubAssemblyDetail       :   null,
            site_id                         :    '',
            fromSubAssembly                 :    'Y',
            hourMeterPresent                :     false,
            odoMeterPresent                 :     false,
            operationCyclePresent           :     false,
            hourMeterList                   :      [],
            odoMeterList                   :       [],
            operationCycleList             :       []
        }
        this.linkAssetModalId   =   uuid();
        this.CounterLogModalRef         =       React.createRef();
        this.subAssemblyUrl         =   TapApiUrls.IAM + '/' + 'sub_assembly';
        this.linkAssetModalFormId    =   this.linkAssetModalId + 'Form';
    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);
        this.linkAssetModal         =   new Modal(document.getElementById(this.linkAssetModalId), {keyboard: false, backdrop :false});  
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }

    linkAssetModalInit = (subAssemblyDetail=null, action_type = 'link', asset_id = '',assetDetail=null,fromSubAssembly='Y',site_id='') => {
       this.linkAssetModal.show();
        let sub_assembly        =       subAssemblyDetail || null;
        let linkedAssetValue    =       null;
        let formValues          =       {
            ...this.linkAssetFormInit,
            sub_assembly_id      :       sub_assembly && sub_assembly.enc_id   || '',
            asset_id             :       action_type === 'delink' ? asset_id : asset_id ? asset_id : ''
        };
         linkedAssetValue = action_type === 'delink' &&  sub_assembly && sub_assembly.asset_profile ? {
            ...sub_assembly.asset_profile,
            display_label   :   `${sub_assembly.asset_profile.name}(${sub_assembly.asset_profile.asset_code})`
        }  : assetDetail ?  {
            ...assetDetail,
            value           :   assetDetail.asset_id,
            display_label   :   `${assetDetail.name}(${assetDetail.asset_code})`
        } :  null;
        this.setState({
            linkAssetForm       :   formValues,
            linkedAsset         :   linkedAssetValue,
            assetDetail         :   null,
            proceedForm         :   action_type === 'link',
            enable_hour_meter   :   'N',
            enable_odo_meter    :   'N',
            enable_opc          :   'N',
            site_id             :    fromSubAssembly == 'Y' ? '' : site_id,
            currentActionType   : action_type,
            subAssemblyDetail   :   subAssemblyDetail,
            fromSubAssembly     :   fromSubAssembly,
            selectedSubAssemblyDetail   :   subAssemblyDetail ? {...subAssemblyDetail,value:subAssemblyDetail.enc_id,display_label:subAssemblyDetail.name,label:subAssemblyDetail.name} : null
        }, action_type === 'delink' ? () => this.getAssetDetail(asset_id) : null);
        
    };
    

    getAssetDetail = () => {
        if (this.state.linkAssetForm && this.state.linkAssetForm.asset_id) {
            this.setState({ asset_loading: true, saveFormSubmitting: true,assetDetail : null });
    
            // Fetch asset detail
            HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + this.state.linkAssetForm.asset_id, this.props.access_token, {}, {}, (response) => {
                const assetDetail = response.data.data;
    
                // Meter logic
                let hourMeterPresent = false;
                let odoMeterPresent = false;
                let operationCyclePresent = false;
                let hourMeterList = [];
                let odoMeterList = [];
                let operationCycleList = [];
    
                if (assetDetail && assetDetail.labels) {
                    const checkMeter = (meter) => assetDetail[meter] === "yes";
                    const pushMeterList = (meter, meterLabel, list) => checkMeter(meter) && list.push({ value: meter, label: meterLabel });
    
                    const pushHourMeter = (meter) => {
                        const meterLabel = assetDetail.labels[meter];
                        pushMeterList(meter, meterLabel, hourMeterList);
                    };
    
                    const pushOdoMeter = (meter) => {
                        const meterLabel = assetDetail.labels[meter];
                        pushMeterList(meter, meterLabel, odoMeterList);
                    };
    
                    const pushOperationCycle = (meter) => {
                        const meterLabel = assetDetail.labels[meter];
                        pushMeterList(meter, meterLabel, operationCycleList);
                    };
    
                    hourMeterPresent = checkMeter('hour_meter') || checkMeter('hour_meter2') || checkMeter('hour_meter3') || checkMeter('hour_meter4');
                    odoMeterPresent = checkMeter('odo_meter') || checkMeter('odo_meter2') || checkMeter('odo_meter3');
                    operationCyclePresent = checkMeter('operation_cycle') || checkMeter('operation_cycle2');
    
                    pushHourMeter('hour_meter');
                    pushHourMeter('hour_meter2');
                    pushHourMeter('hour_meter3');
                    pushHourMeter('hour_meter4');
    
                    pushOdoMeter('odo_meter');
                    pushOdoMeter('odo_meter2');
                    pushOdoMeter('odo_meter3');
    
                    pushOperationCycle('operation_cycle');
                    pushOperationCycle('operation_cycle2');
                }
    
                // Enable and select single meter
                const enableAndSelectSingleMeter = (meterList, enableState, linkedState) => {
                    if (meterList.length === 1) {
                        this.setState({
                            [enableState]: 'Y',
                            linkAssetForm: {
                                ...this.state.linkAssetForm,
                                [linkedState]: meterList[0].value
                            }
                        });
                    }
                };
    
                enableAndSelectSingleMeter(hourMeterList, 'enable_hour_meter', 'linked_hour_meter');
                enableAndSelectSingleMeter(odoMeterList, 'enable_odo_meter', 'linked_odo_meter');
                enableAndSelectSingleMeter(operationCycleList, 'enable_opc', 'linked_opc');
    
                // Update state with asset detail and meter logic
                this.setState({ assetDetail, hourMeterPresent, odoMeterPresent, operationCyclePresent, hourMeterList, odoMeterList, operationCycleList, proceedForm: false }, () => {
                    //this.getShiftListing();
                });
            }, (error) => {
                toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
            }).then(() => {
                this.setState({ asset_loading: false, saveFormSubmitting: false });
            });
        } else {
            toast.error("Please select Asset First", { position: toast.POSITION.TOP_RIGHT });
        }
    };
    
      handleInputChange = (name, value) => {
          this.setState({
              linkAssetForm: { ...this.state.linkAssetForm, [name]: value }
          })
      };

    getShiftListing     =       (date_time)     =>      {
        this.setState({roster_loading:true});
        let params                      =   {
            date_range_start                :  date_time,
            date_range_end                  :  date_time 
        };
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/month_wise_asset_shifts/' + this.state?.assetDetail?.asset_id, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                selectedRosterDetail        :   respData.data,
            });
        }).then(() => this.setState({roster_loading: false}));
    }

    updateCounterlogModalInit           =   (shiftData, date) =>  {
        this.CounterLogModalRef.current.updateCounterlogInit(shiftData, date, this.state.linkedAsset ? this.state.linkedAsset : this.state.assetDetail ? this.state.assetDetail  :"")
    }
    
    afterUpdateCounterlogSubmit         =   (date, asset_id, refresh_asset_card = true) =>  {

        let itemList                        =   [...this.state.selectedRosterDetail];
        let dateIndex                       =   itemList.findIndex(i => i.date == date);
        itemList[dateIndex]                 =   {...this.state.selectedRosterDetail[dateIndex], isLoading : true};
        this.setState({selectedRosterDetail : itemList});
    
        let params                          =   {page : null, date_range_start : date, date_range_end : date};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/month_wise_asset_shifts/' + this.state.linkAssetForm.asset_id, this.props.access_token, params, {}, (resp) => {
            if(resp.data && resp.data.data) {
                itemList[dateIndex]         =   resp.data.data[0];
                this.setState({selectedRosterDetail : itemList});
            }
        });
    }
  
      submitLinkAssetForm = (e) => {
          e.preventDefault();
          //if(this.state.linkAssetForm.sub_assembly_id) {
             if(this.state.currentActionType == 'link'){
                this.setState({ saveFormSubmitting: true });
              const id        =   this.state.linkAssetForm.sub_assembly_id;
              const { linked_hour_meter, linked_odo_meter,linked_opc } = this.state.linkAssetForm;
  
              const link_meters = [linked_hour_meter, linked_odo_meter, linked_opc];
              const data      =   {
                                      ...this.state.linkAssetForm,
                                      sub_assembly_id : this.state.linkAssetForm.sub_assembly_id,
                                      asset_id        :  this.state.linkAssetForm.asset_id,
                                      date_time       :   DateService.dateTimeFormat(this.state.linkAssetForm.date_time,'YYYY-MM-DD HH:mm:ss'),
                                      link_meters     :  link_meters.filter(meter => meter ? true : false)
                                  }; 
              HttpAPICall.withAthorization('POST',  this.subAssemblyUrl + '/asset/link', this.props.access_token, null, data, (response) => {
                  toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                  this.props.afterSubmitForm(id, 'minimize');
                  //this.loadListingTblData();
                  this.linkAssetModal.hide();
              }).then(() => this.setState({ saveFormSubmitting: false }));
             }else{
              this.setState({ saveFormSubmitting: true });
              const id        =   this.state.linkAssetForm.sub_assembly_id;
              const { linked_hour_meter, linked_odo_meter,linked_opc } = this.state.linkAssetForm;
  
              const link_meters = [linked_hour_meter, linked_odo_meter, linked_opc];
              const data      =   {
                                      ...this.state.linkAssetForm,
                                      sub_assembly_id : this.state.linkAssetForm.sub_assembly_id,
                                      asset_id        :  this.state.linkAssetForm.asset_id,
                                      date_time       :   DateService.dateTimeFormat(this.state.linkAssetForm.date_time,'YYYY-MM-DD HH:mm:ss'),
                                      link_meters     :  link_meters.filter(meter => meter ? true : false)
                                  }; 
              HttpAPICall.withAthorization('POST',  this.subAssemblyUrl + '/asset/delink', this.props.access_token, null, data, (response) => {
                  toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                  this.props.afterSubmitForm(id, 'minimize');
                  this.linkAssetModal.hide();
              }).then(() => this.setState({ saveFormSubmitting: false,}));
          //}
        }
      };
    
   
    
    linkAssetModalJsx    =   ()  =>  {
        let {hourMeterPresent, odoMeterPresent, operationCyclePresent, hourMeterList, odoMeterList, operationCycleList,assetDetail} = this.state;
        return (
            <div className="modal fade" id={this.linkAssetModalId} tabIndex="-1">
                <div className={'modal-dialog modal-lg'}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changeSiteModalLabel">{this.state.currentActionType == "link" ? "Link" : "De - Link"} Sub Assembly</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.submitLinkAssetForm} id={this.linkAssetModalFormId}>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Sub Assembly</label>
                                    <div className="col-sm-9">
                                        <InputSubAssemblySearch
                                            required={true}
                                            placeholder="Search Sub Assembly"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'linkAssetForm', 'sub_assembly_id', () => {
                                                });
                                                this.setState({
                                                    selectedSubAssemblyDetail: selectedOption ? selectedOption : null
                                                }, () => {
                                                });
                                            }}
                                            disabled={this.state.fromSubAssembly == 'Y' ? true : false}
                                            search_site_id={this.state.site_id}
                                            search_is_linked="Y"
                                            value={this.state.selectedSubAssemblyDetail ? { ...this.state.selectedSubAssemblyDetail, label: this.state.selectedSubAssemblyDetail.display_label } : null}
                                        />

                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Asset</label>
                                    <div className="col-sm-9">
                                        <InputAssetSearch
                                            required={true}
                                            placeholder="Search Asset"
                                            changeEvent={(selectedOption) => {
                                                if (selectedOption) {
                                                    this.tapSelectChange(selectedOption, 'linkAssetForm', 'asset_id', () => {
                                                    });
                                                    this.setState({
                                                        linkedAsset: selectedOption ? { ...selectedOption, ...selectedOption.asset } : null
                                                    }, () => {
                                                        if (this.state.proceedForm == false) {
                                                            this.getAssetDetail()
                                                        }
                                                    });
                                                } else {
                                                    this.setState({
                                                        linkedAsset: null,
                                                        assetDetail: null,
                                                        linkAssetForm: { ...this.state.linkAssetForm, asset_id: '' }
                                                    }, () => {

                                                    });
                                                }
                                            }}
                                            disabled={this.state.currentActionType == 'delink' || this.state.fromSubAssembly == 'N' ? true : false}
                                            search_site_id={this.state.subAssemblyDetail && this.state.subAssemblyDetail.site ? this.state.subAssemblyDetail.site.id : ''}
                                            value={this.state.linkedAsset ? { ...this.state.linkedAsset, label: this.state.linkedAsset.display_label } : null}
                                        />
                                    </div>

                                </div>

                                {assetDetail &&
                                    <Ax>
                                        <div className="row mb-3">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">Date Time</label>
                                            <div className="col-sm-9 add_calender_section">
                                                <DatePicker
                                                    selected={
                                                        this.state.linkAssetForm.date_time ? moment(this.state.linkAssetForm.date_time, "YYYY-MM-DD").toDate() : false
                                                    }
                                                    name="date_time"
                                                    onChange={(value, event) => { this.formDateHandler("date_time", value, "linkAssetForm", 'YYYY-MM-DD', () => this.getShiftListing(this.state.linkAssetForm.date_time)) }}
                                                    maxDate={moment(new Date(), "YYYY-MM-DD").toDate()}
                                                    dateFormat="dd-MMM-yyyy"
                                                    className={"form-control form-control-sm"}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    autoComplete="off"
                                                    scrollMonthYearDropdown
                                                    required={true}
                                                    placeholderText={`Please Enter Date`}
                                                />
                                                <TapIcon.imageIcon
                                                    icon={TapIcon.CalenderIcon}
                                                    alt="purchase_date"
                                                    className="add_calender-icon"
                                                />
                                            </div>

                                        </div>
                                        {this.state.linkAssetForm.date_time && <div className='counterlog'>
                                            <table className="table table-bordered bg-white table-hover table-sm table-fixed mb-0" id="assetLogsheetTable">
                                                <thead className='table-secondary text-center'>
                                                    <th style={{ width: "5%" }}></th>
                                                    <th style={{ width: "15%" }}>Date</th>
                                                    <th style={{ width: "25%" }}>Shift</th>
                                                    <th style={{ width: "25%" }}>CounterLog</th>

                                                </thead>
                                                <tbody>
                                                    {!this.state.roster_loading
                                                        ?
                                                        this.state.selectedRosterDetail && this.state.selectedRosterDetail.length > 0 ? this.state.selectedRosterDetail.map((day, i) => {

                                                            let shifts = [];
                                                            if (day && day.roster && day.roster.shifts) {
                                                                shifts = day.roster.shifts;
                                                            }

                                                            return (<Ax>
                                                                {shifts.map((sf, key) => {
                                                                    let remarks = sf.shift_summary && sf.shift_summary.remark ? sf.shift_summary.remark : "";
                                                                    return (
                                                                        <tr className='text-center '>
                                                                            <td><input
                                                                                type="radio"
                                                                                checked={sf.counterlog && sf.counterlog.transaction_id && this.state.linkAssetForm.counterlog_transaction_id == sf.counterlog.transaction_id}
                                                                                onChange={() => this.setState({ linkAssetForm: { ...this.state.linkAssetForm, counterlog_transaction_id: sf.counterlog.transaction_id } })}
                                                                                disabled={sf.counterlog && sf.counterlog.transaction_id ? false : true} /></td>
                                                                            {key == 0
                                                                                ? <Ax>

                                                                                    <td className="align-middle" rowspan={day?.roster?.shifts?.length}>{day.date_display}</td>
                                                                                </Ax>
                                                                                : null
                                                                            }
                                                                            <td className='align-middle'>{sf.shift_name} <br /> {sf.shift_start_display}</td>
                                                                            {day.isLoading && day.isLoading == true ? <td colSpan={9} className="text-center"><Loader /></td>
                                                                                : <Ax>
                                                                                    <td className='align-middle'>{sf.counterlog && sf.counterlog.display
                                                                                        ? <span className="text-start">
                                                                                            {sf.counterlog && sf.counterlog.display && sf.counterlog.display.length == 1
                                                                                                ? <div className="mt-1 link-primary cursor_pointer text-center" onClick={() => this.viewShiftSummary(sf.counterlog.transaction_id)}>View</div> :
                                                                                                sf.counterlog.display
                                                                                                    .filter(s => !moment(s.reading, "DD-MMM-YYYY", true).isValid())
                                                                                                    .map((item, index) => {
                                                                                                        return <div className="mt-1 link-primary cursor_pointer" onClick={() => this.viewShiftSummary(sf.counterlog.transaction_id)}>
                                                                                                            {sf.counterlog.display && sf.counterlog.display.length == 2
                                                                                                                ? <div className="text-center">{item.reading} {item.unit}</div>
                                                                                                                : <span>{item.label + ': ' + item.reading}</span>
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                    )}
                                                                                        </span>
                                                                                        : <a role="button" className='link-primary' onClick={() => this.updateCounterlogModalInit(sf, day.date)}>No Record</a>}</td>

                                                                                </Ax>}
                                                                        </tr>
                                                                    )
                                                                })}

                                                            </Ax>)
                                                        })
                                                            : <tr><td className='text-center' colSpan={4}>No Record</td></tr>
                                                        : <tr><td className='text-center' colSpan={4}><Loader /></td></tr>}
                                                </tbody>
                                            </table>


                                        </div>}

                                        {this.state.currentActionType == "link" && <div className='counterlog'>
                                            <div className="tab_content_wrapper">
                                                <span className="content_heading">Link with Meter</span>
                                            </div>
                                            {hourMeterPresent && <div className="row my-3 ">
                                                <div className="col-sm-3">
                                                    <input
                                                        name="enable_hour_meter"
                                                        type="checkbox"
                                                        value={this.state.enable_hour_meter}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            this.setState({ 
                                                                enable_hour_meter: isChecked ? 'Y' : 'N',
                                                                linkAssetForm: { ...this.state.linkAssetForm, linked_hour_meter: isChecked ? '' : null } // Set linked_hour_meter to blank/null when checkbox is unchecked
                                                            });
                                                        }}
                                                        checked={this.state.enable_hour_meter == 'Y'}
                                                        className="form-check-input"
                                                        id="enable_hour_meter"
                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="enable_hour_meter">Enable <b>Hour Meter</b> </label>
                                                </div>
                                                 <div className="col-sm-4">
                                                    <TapSelect
                                                        options={hourMeterList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'linkAssetForm', 'linked_hour_meter');
                                                        }}
                                                        isSearchable={true}
                                                        value={hourMeterList.find(s => this.state.linkAssetForm.linked_hour_meter == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Hour Meter"
                                                        autoCompelete="off"
                                                        menuPlacement={'top'}
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                        isDisabled={this.state.enable_hour_meter == 'N'}
                                                    />
                                                </div>

                                            </div>}
                                            {odoMeterPresent && <div className="row my-3 ">
                                                <div className="col-sm-3">
                                                    <input
                                                        name="enable_odo_meter"
                                                        type="checkbox"
                                                        value={this.state.enable_odo_meter}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            this.setState({ 
                                                                enable_odo_meter: isChecked ? 'Y' : 'N',
                                                                linkAssetForm: { ...this.state.linkAssetForm, linked_odo_meter: isChecked ? '' : null } // Set linked_hour_meter to blank/null when checkbox is unchecked
                                                            });
                                                        }}
                                                        checked={this.state.enable_odo_meter == 'Y'}
                                                        className="form-check-input"
                                                        id="enable_odo_meter"
                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="enable_odo_meter">Enable <b>Odo Meter</b></label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <TapSelect
                                                        options={odoMeterList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'linkAssetForm', 'linked_odo_meter');
                                                        }}
                                                        isSearchable={true}
                                                        value={odoMeterList.find(s => this.state.linkAssetForm.linked_odo_meter == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Odo Meter"
                                                        autoCompelete="off"
                                                        menuPlacement={'top'}
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                        isDisabled={this.state.enable_odo_meter == 'N'}
                                                    />
                                                </div>

                                            </div>}
                                            {operationCyclePresent && <div className="row my-3 ">
                                                <div className="col-sm-3">
                                                    <input
                                                        name="enable_opc"
                                                        type="checkbox"
                                                        value={this.state.enable_opc}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            this.setState({ 
                                                                enable_opc: isChecked ? 'Y' : 'N',
                                                                linkAssetForm: { ...this.state.linkAssetForm, linked_opc: isChecked ? '' : null } // Set linked_hour_meter to blank/null when checkbox is unchecked
                                                            });
                                                        }}
                                                        checked={this.state.enable_opc == 'Y'}
                                                        className="form-check-input"
                                                        id="enable_opc"
                                                    />
                                                    <label className="form-check-label mx-2" htmlFor="enable_opc">Enable <b>Op. Cycle</b></label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <TapSelect
                                                        options={operationCycleList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'linkAssetForm', 'linked_opc');
                                                        }}
                                                        isSearchable={true}
                                                        value={operationCycleList.find(s => this.state.linkAssetForm.linked_opc == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Operation Cycle"
                                                        autoCompelete="off"
                                                        menuPlacement={'top'}
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                        isDisabled={this.state.enable_opc == 'N'}
                                                    />
                                                </div>

                                            </div>}

                                        </div>}
                                    </Ax>}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            {this.state.proceedForm ? <button onClick={this.getAssetDetail} disabled={this.state.saveFormSubmitting ? true : false} form={this.linkAssetModalFormId} className="btn btn-primary mx-2">
                                Proceed {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button> :
                                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} form={this.linkAssetModalFormId} className="btn btn-primary mx-2">
                                    {this.state.currentActionType == "link" ? "Link" : "Delink"} {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>}
                        </div>
                    </div>
                </div>
                <CounterlogModal ref={this.CounterLogModalRef} afterSubmitCounterlog={(date, asset_id, refresh) => { this.afterUpdateCounterlogSubmit(date, asset_id, refresh) }} />
            </div>
        );
    }
    
    render                              =   ()  =>  this.linkAssetModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(LinkingModal);