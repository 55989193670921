import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';



class WalletAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initWalletAddForm        =       {
            name                      :       '',
            type                      :       '',
            visibility                :       [],
            portal_name               :       '',
            acc_num                   :       '',
            notes                     :       '',
            opening_balance_date      :       null,
            opening_balance           :        null
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addWalletForm              :        {...this.initWalletAddForm},
            walletFormData             :        [],
            formDataLoading            :        false,
            allWalletTypes             :        [],
            allUsers                   :        [],
            editWallet                 :        false,
            first_transaction_date     :        moment().toDate()
           };
    }

    componentDidMount() {
        this.getWalletFormData()
    }

    getWalletFormData         =       ()       =>      {
        this.setState({ formDataLoading: true, });
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/wallet/form_data', this.props.access_token, {}, {}, (resp) => {
            this.setState({
                walletFormData: resp.data,
                allUsers: resp.data.users.map((s) => { return { value: s.id, label: s.full_name } }),
                allWalletTypes: resp.data.wallet_types.map((s) => { return { value: s.id, label: s.wallet_type } })
            }
                , () => {
                    if (this.props.location && this.props.location.state && this.props.location.state.walletData) {
                        let walletData = this.props.location.state.walletData
                        let selectedUsers = walletData.visible_for_users.length > 0 ? walletData.visible_for_users.map(u => u.id) : [];
                        this.setState({
                            addWalletForm               :   {
                                id                      :       walletData.id,
                                name                    :       walletData.wallet_name,
                                type                    :       walletData.wallet_type,
                                visibility              :       selectedUsers,
                                portal_name             :       walletData.portal_name,
                                acc_num                 :       walletData.acc_num,
                                notes                   :       walletData.notes,
                                opening_balance_date    :       walletData.opening_balance_date !== "" ? moment(walletData.opening_balance_date).format('YYYY-MM-DD') : null,
                                opening_balance         :       walletData.opening_balance
                            },
                            editWallet                  :       true,
                            first_transaction_date      :       walletData.first_transaction_date !== "" ? moment(walletData.first_transaction_date).toDate() : this.state.first_transaction_date
                        })
                    }
                })
        }).then(() => this.setState({ formDataLoading: false }));
    }

    closeEvent          =       ()          =>      {
        if(this.state.editWallet){
            this.props.history.push({pathname: `/wallet`, state: {walletId : this.state.addWalletForm.id }})
        }else{
            this.props.history.push({pathname: `/wallet`})
        }
    }

    submitAddWalletForm       =        (e)        =>    {
      e.preventDefault()
        if (this.state.editWallet) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_IAM_API_SERVER + '/wallet', this.props.access_token, {}, { ...this.state.addWalletForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push({pathname: `/wallet`, state: {walletId : this.state.addWalletForm.id }})
            }).then(() => this.setState({ saveFormSubmitting: false }));
        } else {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_IAM_API_SERVER + '/wallet', this.props.access_token, {}, { ...this.state.addWalletForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push({pathname: `/wallet`})
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }
    }

    saveWalletFormJsx         =        ()       =>      {
        return (<form className="bg-white p-3" onSubmit={this.submitAddWalletForm} id="saveWalletForm">
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Wallet Name</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="name"
                        type="text"
                        value={this.state.addWalletForm.name}
                        className="form-control"
                        required={true}
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e,"addWalletForm")}
                        placeholder="Enter Wallet Name"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Type</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect  options={this.state.allWalletTypes}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addWalletForm', 'type')}
                                value={this.state.allWalletTypes.find(a => a.value == this.state.addWalletForm.type)}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                placeholder="Please Select Ticket Type"
                                containerHeight="33px"
                                fontSize="93%" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Visibility</label>
                </div>
                <div className="col-sm-4 ">
                <TapSelect  options={this.state.allUsers}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addWalletForm', 'visibility')}
                                value={this.state.allUsers.filter(u => this.state.addWalletForm.visibility.includes(u.value))}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                isMulti={true}
                                placeholder="Please Select Users"
                                containerHeight="33px"
                                fontSize="93%" />
                </div>
                <div className="col-sm-5 form-text">
                    This Wallet will be visible to all the selected users.
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Bank / Online Portal Name</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="portal_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addWalletForm.portal_name}
                        onChange={(e) => this.formInputHandler(e,"addWalletForm")}
                        placeholder="Enter Bank/Online Portal Name"
                        
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Account Number</label>
                </div>
                <div className="col-sm-4 ">
                    <input
                        name="acc_num"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addWalletForm.acc_num}
                        onChange={(e) => this.formInputHandler(e,"addWalletForm")}
                        placeholder="Enter Account Number"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Notes</label>
                </div>
                <div className="col-sm-4 ">
                    <textarea
                        name="notes "
                        className="form-control"
                        value={this.state.addWalletForm.notes}
                        onChange={(e) => this.formInputHandler(e,"addWalletForm")}
                        style={{ height: "80px" }}
                    />
                </div>
            </div>
            <div className="tab_content_wrapper my-2">
                <span className="content_heading">Opening Balance</span>
            </div>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Date</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addWalletForm.opening_balance_date
                                ? moment(this.state.addWalletForm.opening_balance_date).toDate()
                                : false
                        }
                        name="opening_balance_date"
                        onChange={(value, event) => this.formDateHandler('opening_balance_date', value, 'addWalletForm')}
                        maxDate={this.state.first_transaction_date}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Due Date`}

                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                        alt="requisition_date"
                        className="add_calender-icon" />
                </div>
            </div>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Amount</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="opening_balance"
                        type="number"
                        className="form-control"
                        value={this.state.addWalletForm.opening_balance}
                        onChange={(e) => this.formInputHandler(e,"addWalletForm")}
                        autoComplete="off"
                        placeholder="Enter Amount"
                    />
                </div>
            </div>

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Wallet Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.props.location && this.props.location.state && this.props.location.state.walletData ? "Edit" : "Add"} Wallet</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       {this.state.formDataLoading ? <Loader/> :
                        this.saveWalletFormJsx()}
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
    };
};

export default connect(mapStateToProps)(WalletAdd);