import React from 'react';
import {connect} from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { Collapse, Modal } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import HttpAPICall from "../../../services/HttpAPICall";
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import Loader from "../../../components/ui/Loader/Loader";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetData extends AppBaseComponent {

    constructor() {
        super();


        this.assetDataFilterFormInitialState   =   {
            site_group_ids                                :   [],
            search_site_ids                               :   [],
            'asset_group_ids'                             :    [],
            search_asset_type_ids                         :   [],
            search_asset_matrix_ids                       :   [],
            search_manufacturer_ids                       :   [],
            search_models                                 :   [],
            search_variant                                :   [],
            search_asset_ids                              :   [],
            search_asset_type                             :   '',
            search_asset_category                         :   '',
            search_owner_id                               :   '',
            search_ownership_category                     :   '',
            
        };

        this.assetDataSearchFormLabel        =    {
            site_group_ids                                :   'Site Groups : ',
            search_site_ids                               :   'Sites : ',
            'asset_group_ids'                             :   'Asset Type Groups : ',
            search_asset_type_ids                         :   'Asset Type : ',
            search_asset_matrix_ids                       :   'Asset Matrix : ',
            search_manufacturer_ids                       :   'Manufacturer : ',
            search_models                                 :   'Models : ',
            search_variant                                :   'Variants : ',
            search_asset_ids                              :   'Assets : ',
            search_asset_type                             :   'Asset Type : ',
            search_asset_category                        :   'Asset Category : ',
            search_owner_id                               :   'Owner Name : ',
            search_ownership_category                     :   'Ownership Category : ',
        }

        this.state                                   =   {
            showSaveassetDataBtn                  :   false,
            showDownloadassetDataBtn              :   false,
            assetDataDownloading                  :   false,
            showSaveassetDataForm                 :   false,
            listing_loading                              :   false,
            listingData                                  :   [],
            listingMeta                                 :   null,
            iam_asset_types                              :   [],
            iam_user_sites                               :   [],
            iam_asset_matrix                             :   [],
            iam_manufactures                             :   [],
            iam_asset_variants                           :   [],
            iam_models                                   :   [],
            searchedAsset                                :   null,
            searchedAssets                               :   [],
            assetDataFormData                     :   {periods : [], asset_category: []},
            selectedPeriod                               :   '',
            downloadReportBtn                            :   false,
            reportDownloading                            :   false,
            listing_tbl_page                             :   1,
            formSearchedElems                            :   [],
            allOwnershipCategory                         :   [],
            allOwnersList                                :   [],
            allSiteGroupsList                            :   [],
            customDownloadForm                           :   {...this.initCustomDownloadState},
            assetDataFilterForm                   :   {...this.assetDataFilterFormInitialState},
            submittedassetDataFilterForm          :   {...this.assetDataFilterFormInitialState},
            allAssetTypesGroupsList         :   [],
            reportData                          :   null,                  
        };

        this.formDataUrl        =   IAM_API_BASE_URL_2 + '/report/asset-data/form_data';
        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/asset-data';

    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        // this.assetDataFormDataInit()
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    
    assetDataFormDataInit            =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
            this.setState({
                assetDataFormData             :   response.data,
                formDataLoaded                :   true,
                allOwnershipCategory          :   response.data.ownership_category && response.data.ownership_category.length > 0 ? response.data.ownership_category.map(oc => {return({value:oc.key,label : oc.name})}) : [],
                allOwnersList                 :   response.data.owners && response.data.owners.length > 0 ? response.data.owners.map(oc => {return({value:oc.id,label : oc.name})}) : [],
                allSiteGroupsList             :   response.data.site_groups  &&  response.data.site_groups.length > 0 ?  response.data.site_groups.map(oc => {return({value:oc.id,label : oc.name,linked_sites : oc.site_ids})}) : []
            },() => this.reportPerformanceFilterFormSubmit());
           
        });
    }

    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('assetDataFilterFormJsx'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    initilaizeFormFilter                    =   (props)  =>  {

        const report_name   =   'asset_data';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
            iam_manufactures        :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
            iam_asset_variants      :   props.iam_asset_variants ? props.iam_asset_variants.map((v) => { return {value: v, label: v}}) : [],
            iam_models              :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        },() => this.assetDataFormDataInit());
    }

    reportPerformanceFilterFormSubmit       =   (event)  =>  {
        event && event.preventDefault()
        event && this.reportFilterhandler(true);
        let serachFormKeys                      =   Object.keys(this.state.assetDataFilterForm);

        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.assetDataSearchFormLabel[key];
            let value                       =   this.state.assetDataFilterForm[key];
            
            if(value && value.toString().length > 0) {
            
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.assetDataFormData.asset_category.filter((s) => value.includes(s.key)).map(s => s.name);
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_owner_id') {
                        show_val            = this.state.allOwnersList.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_ownership_category') {
                        show_val            = this.state.allOwnershipCategory.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });

        this.setState({
            submittedassetDataFilterForm : {...this.state.assetDataFilterForm},
            formSearchedElems                   :  searchedElems,
            assetSearchParams                   :  assetSearchParams
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listing_tbl_page        :   page,
            downloadReportBtn       :   false,
            reportDownloading       :   false
        });
        let submittedassetDataFilterForm   = this.state.submittedassetDataFilterForm;
        delete submittedassetDataFilterForm.site_group_ids;
        
        let params                          =   {page:page,...submittedassetDataFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token,params,{},(response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                downloadReportBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownloadHandler                  =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedassetDataFilterForm, download : 'download'} , {} , (response) => {
            DownloadFile.file(response.data.file_path,response.data.msg,response.data.navigation,this.props)
        } ) .then(() => this.setState({reportDownloading: false}));
    }


    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} /> Asset Data</h3>
                <div className="text-end mt15">
                <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                {this.state.downloadReportBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}

                   
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }

    assetDataFilterFormJsx            =   ()  =>  {
        let asset_category = this.state.assetDataFormData && this.state.assetDataFormData.asset_category ?
            this.state.assetDataFormData.asset_category.map(ac => { return {value: ac.key, label: ac.name}}) : [];
           
        return (<div id="assetDataFilterFormJsx" className="bg-white collapse" >
            <form onSubmit={this.reportPerformanceFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
            <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {
                               
                               this.setState({
                                 assetDataFilterForm     :   {
                                     ...this.state.assetDataFilterForm,
                                     site_group_ids             :   selectedOption &&  selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                     search_site_ids            :   selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                 }
                               })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.assetDataFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.assetDataFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    assetDataFilterForm: {
                                        ...this.state.assetDataFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.assetDataFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.assetDataFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_asset_matrix_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.assetDataFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.assetDataFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.assetDataFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            search_asset_type={[this.state.assetDataFilterForm.search_asset_type]}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            menuPlacement='top'
                            options={asset_category}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_asset_category');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            value={asset_category.find(r => r.value === this.state.assetDataFilterForm.search_asset_category)}
                            placeholder="Select Asset Category"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Ownership</label>
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className='col-sm-5'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnershipCategory}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_ownership_category');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnershipCategory.find(r => r.value === this.state.assetDataFilterForm.search_ownership_category)}
                                    placeholder="Select Ownership Category"
                                />
                            </div>
                            <div className='col-sm-7'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnersList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetDataFilterForm', 'search_owner_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnersList.find(r => r.value === this.state.assetDataFilterForm.search_owner_id)}
                                    placeholder="Select Owner"
                                />
                            </div>
                        </div>


                    </div>
                </div>
                
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }

    assetDataDisplayJsx               =    ()  =>    {
        return (<div className="bg-white">
            { this.state.listing_loading ? <Loader /> : null }
           { !this.state.listing_loading && this.state.downloadReportBtn == true
                ? this.assetDataTableJsx() : null
            }
        </div>)
    }

    assetDataTableJsx                          =   ()  =>  {
   
        return (<Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                <tr>
                    <th style={{width:"15%"}}>Asset Name </th>
                    <th style={{width:"15%"}}>Code</th>
                    <th style={{width:"15%"}}>Asset Type</th>
                    <th style={{width:"12%"}}>Manufacturer (Model)</th>
                    <th style={{width:"12%"}}>Current Site</th>
                    <th style={{width:"10%"}}>Meter Applicable</th>
                    <th style={{width:"8%"}} className="text-center">Status</th>
                    <th style={{width:"8%"}} className="text-center">Documents</th>
                    <th style={{width:"8%"}} className="text-center">Tracked Compliances</th>
                    
                   
                </tr>
                </thead>
                <tbody>
                 
                {this.state.listingData &&  this.state.listingData.length > 0
                    ?   (this.state.listingData.map((data) => { return (
                        <tr>
                            <td className={"fw-bold"}>{data.name}</td>
                            <td>{data.asset_code}</td>
                            <td>{data.asset_type}</td>
                            <td>{data.manufacturer_name ? data.manufacturer_name : "-"} {data.model_name ? <span>({data.model_name})</span> : null}</td>
                            <td>{data.site ? data.site : "-"}</td>
                            <td>{data.available_meters && data.available_meters.length > 0 ? data.available_meters.map(m => {return(<div>{ m.label}</div>)}) : "NA"}</td>
                            <td className="text-center">{data.status ? <Status color={data.status.status_color_code}>{data.status.status_text}</Status> : null}</td>
                            <td className="text-center">{data.document_count ? data.document_count : "0"}</td>
                            <td className="text-center">{data.tracked_compliances ? data.tracked_compliances : "0"}</td>
                        </tr>
                    )}))
                    : <tr><td className="text-center" colSpan={7}>No Record Found</td></tr>
                }
                </tbody>
            </table>

        </Ax>)
    }

  

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Asset Data - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.assetDataFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}   />
                                {this.assetDataDisplayJsx()}
                            </Ax>)
                            : <Loader  />
                        }
                        {this.state.listingMeta  
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    </div>
                </div>
            </div>
            
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_asset_variants      :   state.app && state.app.asset_variants ? state.app.asset_variants : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetData);