import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse ,  Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import React from 'react';
import download from '../../../services/DownloadFile';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';


class ItemWiseFreeStock extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
            search_item_ids                     :   '',
            search_manufacturer_ids             :   '', 
            search_item_tags                         :   [],
            stock_warehouse_ids                 :   [],
            stock_batch_number                  :   '',
            only_stocked_item                   :   'Y',

        };
        
        this.reportFilterFormLabel          =   {
            search_item_ids                     :   'Items : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            search_item_tags                         :   'Tags : ',
            stock_warehouse_ids                 :   'Warehouses : ',
            stock_batch_number                  :   'Batch Number : ',
            only_stocked_item                   :   'Ignore Items with Zero Balance : ',

        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            allItemTags                         :   [],
            manufactures                        :   [],
            all_warehouses                      :   [],
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null,
            manufactures                        :   [],
            isc_configuration                   :   {},
            download_access                     :   false,   
            reportData                          :   null                  
        };

        this.reportUrl        =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-current-stock';
        
    }

    componentDidMount(){
        setTimeout(() => {
            this.initializeData(this.props);  
        }, 1000); 
       
    }


    initializeData     =      (pr)      =>      {
        
        const report_name   =   pr?.location?.pathname ? pr.location.pathname.split('/')[2] : '';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];

        if(pr){
            HttpAPICall.withAthorization('GET', this.reportUrl, pr.access_token, {action:"formData"}, null, (response) => {
                this.setState({
                    reportData                  :   reportData.length > 0 ? reportData[0] : null,
                    download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                    reportFilterFormData        :   {...response.data},
                    formDataLoaded              :   true,
                    all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    manufactures                :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                    allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                }, () => {
                    this.loadListingTblData(1);
                });
            }, (err) => {
            });
        }
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];               

            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'stock_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }                   
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'only_stocked_item') {
                        show_val            =   this.state.reportFilterForm.only_stocked_item == "Y" ? "Yes" : "No";
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm,action:"data"};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    
    reportDownloadHandler             =    ()     =>         {
        this.setState({ excelDownloading: true});
        let params              =           { ...this.state.submittedReportFilterForm,action : 'download'};
        HttpAPICall.withAthorization('GET',  this.reportUrl , this.props.access_token,params,{},(response) => {
          let respData = response.data
           let file_path = respData && respData.file_path ? respData && respData.file_path : '';
           let message  = respData && respData.msg ? respData && respData.msg : '';
           let navigation  = respData && respData.navigation ? respData && respData.navigation : '';
            download.file(file_path,message,navigation,this.props);
        })
        .then(() => this.setState({excelDownloading : false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.push('/report');
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name} </h3>
                <div className="text-end mt15">
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={this.reportDownloadHandler}>Download</Link></li>
                    </ul>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                                placeholder="Search Item"
                                isMulti={true}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                    this.setState({
                                        searchedItem: selectedOption ? selectedOption : null
                                    }, () => { });
                                }}
                                value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                                options={this.state.allItemTags}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                                value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                                isSearchable={true}
                                isMulti={true}
                                isClearable={true}
                                placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                                options={this.state.manufactures}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                                }}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                                placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Ignore Items with Zero Balance</label>
                    <div className="col-sm-1">
                        <label>
                            <input
                                type="radio"
                                name="only_stocked_item"
                                value="Y"
                                onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                                checked={this.state.reportFilterForm.only_stocked_item == "Y"}
                            /> Yes
                        </label>
                    </div>
                    <div className="col-sm-2">
                        <label>
                            <input
                                type="radio"
                                name="only_stocked_item"
                                value="N"
                                onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                                checked={this.state.reportFilterForm.only_stocked_item == "N"} /> No
                        </label>
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'stock_warehouse_ids')}
                            value={this.state.all_warehouses.filter(u => this.state.reportFilterForm?.stock_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                {this.state.isc_configuration && this.state.isc_configuration.track_batch_expiry === "N" ? null : <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Batch Number</label>
                    <div className="col-sm-6">
                        <input
                            name="stock_batch_number"
                            type="text"
                            value={this.state.reportFilterForm.stock_batch_number}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Batch Number"
                        />
                    </div>
                </div>}    
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        let track_batch_expiry  =    this.state.isc_configuration && this.state.isc_configuration.track_batch_expiry === "N" ? 'N' : 'Y';
        return <Ax>
            <table className="table table-bordered table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary sticky-top">
                    <tr>
                        <th className='text-center' style={{ width: "18%"}}>Item</th>
                        <th className='text-center' style={{ width: "10%" }}>Item Code</th>
                        <th className='text-center' style={{ width: "10%" }}>Manufacturer</th>
                        <th className="text-center" style={{ width: "14%" }}>Warehouse</th>
                        
                        { track_batch_expiry === "Y" && <Ax>
                            <th className="text-center" scope="col" style={{ width: "7%" }}>Batch Number</th>
                            <th className="text-center" scope="col" style={{ width: "7%" }}>Expiry Date</th> 
                        </Ax> }

                        <th className="text-center" scope="col" style={{ width: "7%" }}>Total Qty</th>
                        <th className="text-center" scope="col" style={{ width: "7%" }}>Allocated Qty</th>
                        <th className='text-center' scope="col" style={{ width: "7%" }}>On Hold</th>
                        <th className='text-center' scope="col" style={{ width: "7%" }}>Intransit</th>
                        <th className='text-center' scope="col" style={{ width: "7%" }}>Free Qty</th>
                        <th className='text-center' scope="col" style={{ width: "9%" }}>Bin Location</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData.length > 0 ? (
                        this.state.listingData.map((item, ik) => {
                            return (item.warehouses && item.warehouses.length > 0) 
                                ? (item.warehouses.map((warehouse, wk) => {
                                    let warehouse_config = warehouse.warehouse_config;
                                    return warehouse.stocks.map((stock, sk) => {
                                        return <tr key={sk+wk+ik}>
                                            <td>{item.name}</td>
                                            <td className='text-center'>{item.item_code}</td>
                                            <td className='text-center'>{item.manufacturer_name}</td>
                                            <td className='text-start'>{ warehouse.name }</td>
                                            {track_batch_expiry === "N" ? null : <Ax>
                                            <td className='text-center'>{stock.batch_number ? stock.batch_number : ""}</td>
                                            <td className='text-center'>{stock.expiry_date_display ? stock.expiry_date_display : ""}</td> </Ax> }
                                            <td className='text-center'>{stock.total_qty ? stock.total_qty : ""}</td>
                                            <td className='text-center'>{stock.allocated_qty ? stock.allocated_qty : ""}</td>
                                            <td className='text-center'>{stock.on_hold ? stock.on_hold : ""}</td>
                                            <td className='text-center'>{stock.intransit ? stock.intransit : ""}</td>
                                            <td className='text-center'>{stock.free_qty ? stock.free_qty : ""}</td>
                                            <td className='text-center'>{warehouse_config ? warehouse_config.bin_location : ""}</td>
                                        </tr>
                                     })
                                    }))
                                : <tr>
                                    <td>{item.name}</td>
                                    <td className='text-center'>{item.item_code}</td>
                                    <td className='text-center' colSpan={track_batch_expiry == 'N' ? 6 : 8} >-</td>
                                </tr>
                        })
                    ) : null}
                </tbody>
            </table>
        </Ax>;
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Item Wise Free Stock - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} keyName="Items" />
                            : null
                        }
                    </div>
                </div>
            </div>
           
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemWiseFreeStock);