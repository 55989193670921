import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../services/TapIcon";


class SalesTeam extends AppBaseComponent {


    render() {

        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link  active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                                type="button" role="tab" aria-controls="details" aria-selected="true">General
                            </button>
                        </li>
                      
                    </ul>
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane show active" id="details" role="tabpanel"
                            aria-labelledby="details-tab">
                            <div className="tab_content_header">
                               fff
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(SalesTeam);