import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon"
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import TapIcon from '../../../services/TapIcon';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import swal from 'sweetalert';
import * as actions from '../../../store/actions/index';

class HrmDocumentTypes extends AppBaseComponent {

    constructor(props) {
        super(props);

          this.state                =          {
            list_loading            :         false,
            listingData             :         null,
            saveFormSubmitting      :         false,
        };

        this.hrmDocTypessUrl        =   ORG_API_BASE_URL_2  + '/setting/hrm_doc_types';

    }

    componentDidMount() {
        this.getDocumentTypeListing()
        this.documentTypeModal         =    new Modal(document.getElementById('documentTypeModal'), {keyboard: false, backdrop :false});
    }

    getDocumentTypeListing             =         ()    =>  {
        this.setState({list_loading : true})
        HttpAPICall.withAthorization('GET', this.hrmDocTypessUrl, this.props.access_token , {}, {}, (resp) => {
             this.setState({listingData  : resp.data})
        }).then(() => this.setState({list_loading: false}));
    }

    handleDocumentValue   =     (e, i)     =>       {
        let options = [...this.state.options]
        options[i] = e.target.value
        this.setState({
            options
        })
    }

    handleDeleteOption       =      i       =>   e      =>   {
        e.preventDefault()
        // if (i > 0) {
            let options = [
                ...this.state.options.slice(0, i),
                ...this.state.options.slice(i + 1)
            ]
            this.setState({
                options
            })
        // }
    }

    addOption = e => {
        e.preventDefault()
        let options = this.state.options.concat([''])
        this.setState({
            options
        })
    }

    documentModalInit       =       ()      =>      {
        this.documentTypeModal.show()
        if(this.state.listingData && this.state.listingData.length > 0){
            this.setState({
                options : this.state.listingData
            })
        }
    }

    submitDocumentType      =   (e)     =>      {
        e.preventDefault()
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', this.hrmDocTypessUrl,
        this.props.access_token, null, {types : this.state.options}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.documentTypeModal.hide();
            this.getDocumentTypeListing()
      }).then(() => this.setState({saveFormSubmitting: false}))
    }

    documentTypeModalJsx               =        ()             =>        {

        return (
            <div className="modal fade" id="documentTypeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg  modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Edit Document Type</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                        <form id="documentTypeForm" onSubmit={this.submitDocumentType}>
                           
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label " >Name</label>
                                    <div className="col-md-9">
                                        <div>{this.state.options && this.state.options.length >= 1 &&
                                            this.state.options.map((i, index) => {
                                                return (
                                                    <div className="row">
                                                        <div className={this.state.options.length >= 1 ? "col-sm-10" : "col-sm-12"}>
                                                            <input
                                                                name={i}
                                                                type="text"
                                                                required="true"
                                                                className="form-control form-control-sm my-1"
                                                                value={i}
                                                                placeholder="Please Enter Document Type Name"
                                                                onChange={(e) => this.handleDocumentValue(e, index)}
                                                            />
                                                        </div>
                                                        {this.state.options.length > 2 ?
                                                            <div className="col-sm-2"><button className="btn btn-secondary my-1" onClick={this.handleDeleteOption(index)}>X</button></div> : null}
                                                    </div>
                                                )
                                            })}
                                            <div className="link-primary text-end cursor_pointer" onClick={this.addOption}>Add New Document Type</div>
                                        </div>
                                    </div>
                                </div>

                           
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        return (
            <section className="container-fluid" style={{ padding: "0px 12px" }}>
                <div className=" row bg-white p-2">
                    <div className="col-sm-12">
                    <div className="tab_content_wrapper mt-2"><span className="content_heading">Document Type</span>
                            <div className="text-end "><button className="btn btn-primary" onClick={this.documentModalInit}>Update</button></div>
                        </div>
                        <table className="table table-hover table-bordered my-4 table-sm ">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "10%" }}>S.No</th>
                                    <th scope="col" className="text-start">Name</th>
                                    {/* <th scope="col" style={{ width: "10%" }}>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.list_loading ? (<tr><td colSpan="3"><Loader /></td></tr>)
                                    : (this.state.listingData && this.state.listingData.length > 0
                                        ? (this.state.listingData.map((sg, k) => {
                                            return (<tr key={k} className="text-center">
                                                <td>{k + 1}</td>
                                                <td className="text-start">{sg}</td>
                                            </tr>);
                                        }))
                                        : (<tr><td colSpan="3" className="text-center">No Records</td></tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            {this.documentTypeModalJsx()}
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
    };
};


export default connect(mapStateToProps)(HrmDocumentTypes);