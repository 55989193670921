import React from 'react';
import axios from 'axios';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from '../../../components/hoc/Ax';
import { batch, connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import swal from "sweetalert";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import DateService from '../../../services/DateService';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';

class AddSubAssembly extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.initSubAssemblyForm            =   {
            name                                :   '', 
            code                                :   '', 
            category_id                         :   '', 
            manufacturer_name                   :   '', 
            site_id                             :   '', 
            addition_attributes                 :   null, 
            sub_assembly_tags                   :   null, 
            serial_number                       :   '', 
            received_with_asset                 :   '',
            status_id                           :   '', 
            purchase_date                       :   '', 
            purchase_price                      :   '', 
            manufacturer_id                     :   '',
            new_manufacturer_name               :   '',
        }

        this.state                          =   {
            saveFormSubmitting                  :   false,
            origin_detail_loading               :   false,
            destination_detail_loading          :   false,
            addSubAssemblyForm                  :   {...this.initSubAssemblyForm},
            formDataLoaded                      :   false,
            subAssemblyCategories               :   [],
            subAssemblyTags                     :   [],
            subAssemblyStatus                   :   [],
            additionalAttributes                :   [],
            siteList                            :   [],
            manufacturers                       :   [],
            searchedAsset                       :   null,
            isEdit                              :   false,
            addNewManufacturer                  :   'N',
        };

        this.subAssemblyUrl         =   TapApiUrls.IAM + '/' + 'sub_assembly';

    }

    componentDidMount() {
        this.initilaizeFormData(this.props);
    }

    //*******************INITIALIZE FORM DATA************************************** */
    initilaizeFormData                  =   (pr) =>  {
        this.setState({formDataLoaded : true});

        if(pr.location && pr.location.state && pr.location.state.subAssemblyDetail) {
            let subAssemblyDetail =   pr.location.state.subAssemblyDetail;
            let isClone = pr.location.state.isClone;

            let updateFormState = [];
            if(subAssemblyDetail){ 
                let addtionalAttribute  =  subAssemblyDetail["additional_attributes"];
                let addtionalAttributeData =  addtionalAttribute.length > 0 ? addtionalAttribute.map(at => {
                    return({key : at.key,value:at.value,name:at.name})
                }) : [];

                // addition_attributes
                addtionalAttributeData.map((k) => {
                    let name =  'additional_attributes_' + k.key; 
                    updateFormState[name] = k.value
                });
            }

            this.setState({
                isEdit              :   isClone ? false : true,    
                addSubAssemblyForm  :   {
                    ...this.initSubAssemblyForm,
                    name : subAssemblyDetail.name,
                    site_id : subAssemblyDetail.site ? subAssemblyDetail.site.id : '',
                    serial_number : subAssemblyDetail.serial_number,
                    purchase_date : subAssemblyDetail.purchase_date,
                    purchase_price : subAssemblyDetail.purchase_price,
                    sub_assembly_tags : subAssemblyDetail.sub_assembly_tags,
                    status_id : subAssemblyDetail.status.id,
                    card_number :subAssemblyDetail.card_number,
                    manufacturer_name : subAssemblyDetail.manufacturer_name,
                    category_id : subAssemblyDetail.category ? subAssemblyDetail.category.id : '',
                    enc_id : isClone ? '' : subAssemblyDetail.enc_id,
                    received_with_asset : subAssemblyDetail.received_with_asset,
                    manufacturer_id : subAssemblyDetail.manufacturer ? subAssemblyDetail.manufacturer.id : '',
                    ...updateFormState,
                },
            });
        } 
        HttpAPICall.withAthorization('GET', this.subAssemblyUrl + '/form_data', pr.access_token, null, null, (response) => {
            const formData                   =   response.data;
            this.setState({
                subAssemblyCategories       :   formData && formData.sub_assembly_categories && formData.sub_assembly_categories.length > 0 ?  formData.sub_assembly_categories.map((c) => { return {value: c.id, label: c.name}; }) : [],      
                subAssemblyTags             :   formData && formData.sub_assembly_tags && formData.sub_assembly_tags.length > 0 ?  formData.sub_assembly_tags.map((tag) => { return {value: tag.name, label: tag.name}; }) : [],      
                subAssemblyStatus           :   formData && formData.sub_assembly_status && formData.sub_assembly_status.length > 0 ?  formData.sub_assembly_status.map((s) => { return {value: s.id, label: s.name}; }) : [],      
                additionalAttributes        :   formData && formData.additional_attributes && formData.additional_attributes.length > 0 ?  formData.additional_attributes : [],      
                siteList                    :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`,site_code : s.site_code}}) : [],
                manufacturers               :   formData && formData.manufacturers && formData.manufacturers.length > 0 ? formData.manufacturers.map((m) => { return { value: m.id, label: m.name } }) : [],
            });
        }).then(() => {
            this.setState({
                formDataLoaded      :   false
            });
        });
    }

    subAssemblySubmitHandle      =   (e)  =>  {

        e.preventDefault();
        this.setState({ saveFormSubmitting: true });  
        
        let additional_attributes = {};
        if(this.state.additionalAttributes && this.state.additionalAttributes.length > 0 ){
            this.state.additionalAttributes.forEach((data, i) => {
                let name = 'additional_attributes_' + data.key;
                additional_attributes[data.key] = this.state.addSubAssemblyForm[name] ? this.state.addSubAssemblyForm[name] : '';
            })
        } 
        
        let data                  =   {...this.state.addSubAssemblyForm, addition_attributes : additional_attributes}; 
        if(this.state.isEdit) {
            HttpAPICall.withAthorization('PUT',  this.subAssemblyUrl + '/update', this.props.access_token, null, data, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push({pathname: `/sub_assembly`});
            },(error) => {
                if(error.response.status == 422) {
                    swal({
                        title: "Delete",
                        text: `${error.response.data.message},Do you still want to Continue`,
                        icon: "warning",
                        // dangerMode: true,
                        buttons: ["No", "Yes"],
                    }).then(forceAdd => {
                            if (forceAdd) {
                                HttpAPICall.withAthorization('PUT',  this.subAssemblyUrl + '/update', this.props.access_token, null, {...data,force : 'Y'}, (response) => {
                                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                                    this.props.history.push({pathname: `/sub_assembly`});
                                }).then(() => {
                                    this.setState({ saveFormSubmitting: false })
                                });
                            }
                        });
                }
                
            },false).then(() => this.setState({ saveFormSubmitting: false }));
        }else {
            HttpAPICall.withAthorization('POST',  this.subAssemblyUrl + '/add', this.props.access_token, null, data, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push({pathname: `/sub_assembly`});
            },(error) => {
                if(error.response.status == 422) {
                    swal({
                        title: "Delete",
                        text: `${error.response.data.message},Do you still want to Continue`,
                        icon: "warning",
                        // dangerMode: true,
                        buttons: ["No", "Yes"],
                    }).then(forceAdd => {
                            if (forceAdd) {
                                HttpAPICall.withAthorization('POST',  this.subAssemblyUrl + '/add', this.props.access_token, null, {...data,force : 'Y'}, (response) => {
                                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                                    this.props.history.push({pathname: `/sub_assembly`});
                                }).then(() => {
                                    this.setState({ saveFormSubmitting: false })
                                });
                            }
                        });
                }
            },false).then(() => this.setState({ saveFormSubmitting: false }));
        }

    }

    //****************************CLOSE*********************/
    closeEvent                      =   ()  =>  {
        this.props.history.push({pathname: `/sub_assembly`})
    }

    //**********************ADD Sub Assembly FORM JSX*******************/
    subAssemblyFormJsx                  =   ()  =>  {

        return (
            <form className="bg-white p-3" onSubmit={(e) => this.subAssemblySubmitHandle(e)} id="subAssemblyForm">
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Name</label>
                    </div>
                    <div className="col-sm-6">
                        <input
                            name="name"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={this.state.addSubAssemblyForm.name}
                            onChange={(e) => this.formInputHandler(e, 'addSubAssemblyForm')}
                            placeholder="Enter Sub Assembly Name"
                        />
                    </div>
                </div>
                {
                    this.state.isEdit &&
                    <div className="row align-items-center my-3">
                        <div className="col-sm-3">
                            <label htmlFor="site_id" className="form-label">Code</label>
                        </div>
                        <div className="col-sm-6">
                            <input
                                name="code"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                disabled={this.state.isEdit}
                                value={this.state.addSubAssemblyForm.card_number}
                                onChange={(e) => this.formInputHandler(e, 'addSubAssemblyForm')}
                                placeholder="Enter Sub Assembly Code"
                            />
                        </div>
                    </div>
                }
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Category</label>
                    </div>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addSubAssemblyForm', 'category_id');
                            }}
                            options={this.state.subAssemblyCategories}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            value={this.state.subAssemblyCategories.find(s => s.value == this.state.addSubAssemblyForm.category_id)}
                            placeholder="Select Category"
                        />
                    </div>
                </div>
                <div className="row align-items-center mb-3 mt-3">
                    <div className="col-sm-3">
                        <label className="form-label">Manufacturer</label>
                    </div>
                    <div className="col-sm-6">
                        {this.state.addNewManufacturer == "Y"
                            ? <input
                                name="new_manufacturer_name"
                                type="text"
                                value={this.state.addSubAssemblyForm.new_manufacturer_name}
                                onChange={(e) => this.formInputHandler(e, 'addSubAssemblyForm')}
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter New Manufacturer"
                            />
                            : <TapSelect
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addSubAssemblyForm', 'manufacturer_id')}
                                options={this.state.manufacturers}
                                isSearchable={true}
                                isClearable={true}
                                value={this.state.manufacturers.find(m => m.value == this.state.addSubAssemblyForm.manufacturer_id)}
                                placeholder="Please Select"
                            />}
                    </div>
                    {
                        <div className="col-sm-3">
                        <input
                            name="addNewManufacturer"
                            type="checkbox"
                            value={this.state.addNewManufacturer}
                            onChange={(e) => {
                                this.setState({
                                    addNewManufacturer: this.state.addNewManufacturer == 'N' ? 'Y' : 'N',
                                    addSubAssemblyForm: this.state.addNewManufacturer == 'N' ? { ...this.state.addSubAssemblyForm, manufacturer_id: '' } : { ...this.state.addSubAssemblyForm, new_manufacturer_name: '' }
                                })
                            }}
                            checked={this.state.addNewManufacturer == 'Y'}
                            className="form-check-input"
                            id="addNewManufacturer"
                        />
                        <label className="form-check-label mx-2 text-sm" htmlFor="addNewManufacturer">Add New Manufacturer </label>
                    </div>
                    }
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Site</label>
                    </div>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addSubAssemblyForm', 'site_id');
                            }}
                            options={this.state.siteList}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            isDisabled={this.state.isEdit}
                            value={this.state.siteList.find(s => s.value == this.state.addSubAssemblyForm.site_id)}
                            placeholder="Select Site"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Status</label>
                    </div>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addSubAssemblyForm', 'status_id');
                            }}
                            options={this.state.subAssemblyStatus}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            isDisabled={this.state.isEdit}
                            value={this.state.subAssemblyStatus.find(s => s.value == this.state.addSubAssemblyForm.status_id)}
                            placeholder="Select Status"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Tags</label>
                    </div>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addSubAssemblyForm', 'sub_assembly_tags');
                            }}
                            options={this.state.subAssemblyTags}
                            isSearchable={true}
                            isClearable={true}
                            isMulti= {true}
                            value={this.state.subAssemblyTags.filter(s => this.state.addSubAssemblyForm.sub_assembly_tags && this.state.addSubAssemblyForm.sub_assembly_tags.includes(s.value))}
                            placeholder="Select Tags"
                        />
                    </div>
                </div>
                {
                    this.state.additionalAttributes && this.state.additionalAttributes.length > 0 && 
                    <AdditionalAttributeForm
                    additionalAttributes={this.state.additionalAttributes}
                    formState={this.state.addSubAssemblyForm}
                    onAttributeChange={(value, attributeName) => {
                        this.setState(() => ({
                            addSubAssemblyForm: {
                                ...this.state.addSubAssemblyForm,
                                [attributeName]: value
                            }
                        }))
                    }
                    }
                    colSpan={"col-sm-6"}
                />
                }
            
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Serial Number</label>
                    </div>
                    <div className="col-sm-6">
                        <input
                            name="serial_number"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={this.state.addSubAssemblyForm.serial_number}
                            onChange={(e) => this.formInputHandler(e, 'addSubAssemblyForm')}
                            placeholder="Enter Serial Number"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Purchase Date</label>
                    </div>
                    <div className="col-sm-6 add_calender_section">
                        <DatePicker
                            selected={
                                this.state.addSubAssemblyForm.purchase_date
                                    ? moment(this.state.addSubAssemblyForm.purchase_date).toDate()
                                    : false
                            }
                            name="purchase_date"
                            onChange={(value, event) => this.formDateHandler('purchase_date', value, 'addSubAssemblyForm', 'YYYY-MM-DD HH:mm:ss')}
                            dateFormat="dd-MMM-yyyy hh:mm a"
                            className={"form-control form-control-sm"}
                            showMonthDropdown
                            showYearDropdown
                            showTimeSelect
                            timeIntervals={5}
                            timeCaption="Time"
                            autoComplete="off"
                            required={true}
                            maxDate={new Date()}
                            scrollMonthYearDropdown
                            placeholderText={`Enter Purchase Date`}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Purchase Date" className="add_calender-icon" />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Purchase Price</label>
                    </div>
                    <div className="col-sm-6">
                        <input
                            name="purchase_price"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={this.state.addSubAssemblyForm.purchase_price}
                            onChange={(e) => this.formInputHandler(e, 'addSubAssemblyForm')}
                            placeholder="Enter Purchase Price"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Received with Asset</label>
                    </div>
                    <div className="col-sm-6">
                        <input
                            name="received_with_asset"
                            type="radio"
                            value={"Y"}
                            required={true}
                            onChange={(e) => this.formInputHandler(e, 'addSubAssemblyForm')}
                            className="form-check-input"
                            checked={this.state.addSubAssemblyForm.received_with_asset === 'Y' ? true : false}
                            id="yes"
                        />
                        <label className="form-check-label mx-2" htmlFor="yes">Yes</label>
                        <input
                            name="received_with_asset"
                            type="radio"
                            value={"N"}
                            required={true}
                            onChange={(e) => this.formInputHandler(e, 'addSubAssemblyForm')}
                            className="form-check-input"
                            checked={this.state.addSubAssemblyForm.received_with_asset === 'N' ? true : false}
                            id="no"
                        />
                        <label className="form-check-label mx-2" htmlFor="no">No</label>
                    </div>
                </div>

                <div className="col-12 text-end fixed_footer">
                    <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                    <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary ms-2">
                        Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                </div>
            </form>
        );
    }

    render                                  =   ()  =>  {
     
        return (<ApplicationLayout>
        <Helmet><title>Add Sub-Assembly</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.isEdit ? 'Edit' : 'Add'} Sub-Assembly</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {
                            this.state.formDataLoaded 
                            ? 
                            <Loader />
                            : 
                            this.subAssemblyFormJsx()
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : '',
        currency_list           :   state.app && state.app.currency_list && state.app.currency_list.length > 0 ? state.app.currency_list : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],

    };
};

export default connect(mapStateToProps)(AddSubAssembly);
