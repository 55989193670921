import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import { toast } from "react-toastify";
import TapIcon from '../../../services/TapIcon';
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import { l, M, v } from 'react-select/dist/index-4bd03571.esm';
import { ORG_API_BASE_URL_2, SCHEDULE_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { useSelector } from 'react-redux';
import Loader from '../../../components/ui/Loader/Loader';
import TapHelper from '../../../services/TapHelper';
import DatePicker from "react-datepicker";
import moment from 'moment';

const ReportSchedulerModal     = forwardRef((props, ref) => {


    let initFormData                        =   {
        loading                                 :   false,
        submitting                              :   false,
        report_key                              :   '',
        frequency                               :   'daily',
        types                                   :   [],
        hour                                    :   14,
        minutes                                 :   55,
        dates                                   :   [],
        days                                    :   [],
        start_date                              :   '',
        is_active                               :   ''
    };

    const hourOptions                       =   TapHelper.range(1,23).map(v => { return  {label: v, value: v} });
    const minuteOptions                     =   TapHelper.range(0,59).map(v => { return  {label: v, value: v} });
    const datesOptions                      =   TapHelper.range(1,31).map(v => { return  {label: v, value: v} });
    const daysOptions                       =   [
                                                    { value: 1, label: 'Monday' },
                                                    { value: 2, label: 'Tuesday' },
                                                    { value: 3, label: 'Wednesday' },
                                                    { value: 4, label: 'Thursday' },
                                                    { value: 5, label: 'Friday' },
                                                    { value: 6, label: 'Saturday' },
                                                    { value: 7, label: 'Sunday' },
                                                ];
    const statusOptions                     =   [
                                                    { value: 'Y', label: 'Active' },
                                                    { value: 'N', label: 'Disabled' },
                                                ];


    const [reportKey, setReportKey]         =   useState('');
    const [modalId, setModalId]             =   useState(uuid());
    const [formId, setformId]               =   useState(uuid());
    const [formData, setSchedularFormData]  =   useState({...initFormData});
    const [reportData, setReportData]       =   useState({
        name                                    :   '',
        frequency                               :   [],
        available_types                         :   []
    });

    const access_token                      =   useSelector(state => state.auth.access_token);

    const schedularModalElem                =   useRef(null);
    const schedularModal                    =   useRef(null);
    let schedularFormDataUrl                =   SCHEDULE_API_BASE_URL_2 + '/notification/form_data';
    let schedularUserSaveUrl                =   SCHEDULE_API_BASE_URL_2 + '/notification/save';


    useImperativeHandle(ref, () => ({

        initModalHandler: (report_key) => {
            openSchedularModalHandler(report_key);
        }
    }));


    const openSchedularModalHandler        =    (report_key)  =>  {
        setReportKey(report_key);
        //Get Form Data of this report :-
        schedularFormDataInit(report_key);
        schedularModalOpen();
    }

    const schedularModalOpen       =   ()  => {
        if (schedularModal.current) {
            schedularModal.current.show();
        }
    }

    const schedularModalClose      =   ()  => {
        if (schedularModal.current) {
            schedularModal.current.hide();
        }
    }

    const schedularFormDataInit             =   (report_key)  =>  {
        setSchedularFormData(pd => ({...pd, loading : true}));
        HttpAPICall.withAthorization('GET', schedularFormDataUrl, access_token, {report_key : report_key}, {}, (response) => {
            let data                =   response.data;
            data.frequency          =   data.frequency.map(f => { return {label : f.toUpperCase(), value : f } });
            data.available_types    =   data.available_types.map(f => { return {label : f.toUpperCase(), value : f } });
            let scheduled_data      =   data.scheduled_data;

            let newData             =   {report_key : report_key};
            if(scheduled_data) {
                newData             =   {
                    ...newData,
                    frequency           :   scheduled_data.frequency,
                    types               :   scheduled_data.types,
                    is_active           :   scheduled_data.is_active,
                    hour                :   scheduled_data.hour,
                    minutes             :   scheduled_data.minutes,
                    start_date          :   scheduled_data.start_date,
                    dates               :   scheduled_data.dates && scheduled_data.dates.length > 0 ? scheduled_data.dates : [],
                    days                :   scheduled_data.days && scheduled_data.days.length > 0 ? scheduled_data.days : [],
                };
            }
            setSchedularFormData(pd => ({...pd, ...newData }));
            setReportData(data);
        }).then(() => {
            setSchedularFormData(pd => ({...pd, loading : false}));
        });
    }


    useEffect(() => {
        schedularModalElem.current         =   document.getElementById(modalId);
        schedularModal.current             =   new Modal(schedularModalElem.current, {keyboard: false, backdrop :false});

        // Cleanup on unmount
        return () => {
            if (schedularModal.current) {
                schedularModal.current.dispose();
            }
        };
    }, []);


    const submitSchedularHandler  = (e) => {
        e.preventDefault();
        setSchedularFormData(data => ({...data, submitting : true}));
        HttpAPICall.withAthorization("POST", schedularUserSaveUrl, access_token, {}, { ...formData }, (response) => {
            // //1 - Modal Close :-
            schedularModalClose();
            // //2 - Reset Ticket Form Data :-
            setSchedularFormData(initFormData);
            if(props.afterSucess) {
                props.afterSucess()
            }
            // //4 - Show Success Message :-
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            setSchedularFormData(data => ({...data, submitting : false}));
        });
    }

    const schedularModalJsx = () => {
        return <><div className="modal fade" id={modalId} tabindex="-1" ref={schedularModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Schedule Report</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={formData.submitting || formData.loading} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {formData.loading ? <Loader /> : <form name='' id={formId + 'Form'} onSubmit={submitSchedularHandler}>
                        <div className="row align-items-center mb-3">
                                <div className="col-sm-4"><label className="form-label require">Report Name</label></div>
                                <div className="col-sm-8">
                                    <input type="text" disabled value={reportData.name} className='form-control' />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <div className="col-sm-4"><label className="form-label require">Notification Types</label></div>
                                <div className="col-sm-8">
                                    <TapSelect 
                                        required
                                        isClearable={true}
                                        isMulti={true}
                                        placeholder="Select Notification Type"
                                        options={reportData.available_types}
                                        value={reportData.available_types.filter(o => formData.types.includes(o.value))}
                                        changeEvent={o => setSchedularFormData(d => ({...d, types : o.map(e => e.value)}))}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <div className="col-sm-4"><label className="form-label require">Frequency</label></div>
                                <div className="col-sm-3">
                                    <TapSelect
                                        required
                                        options={reportData.frequency}
                                        value={reportData.frequency.find(o => o.value === formData.frequency)}
                                        changeEvent={(o) => setSchedularFormData(d => ({...d, frequency:o ? o.value : null}))}
                                        isClearable={true}
                                        placeholder="Select Frequency"
                                    />
                                </div>
                                <div className="col-sm-5 text-muted fs12 fst-italic">
                                    Report will be generated and send on frequency basis.
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <div className="col-sm-4"><label className="form-label require">Start Date & Time</label></div>
                                {(formData.frequency == 'daily' || formData.frequency == 'onetime') && <div className="col-sm-4 add_calender_section">
                                    <DatePicker
                                        selected={ formData.start_date ? moment(formData.start_date).toDate() : false }
                                        name="wo_date"
                                        onChange={(v,e) => setSchedularFormData(d => ({...d, start_date:v ? moment(v).format('YYYY-MM-DD') : null}))}
                                        dateFormat="dd-MMM-yyyy"
                                        className={"form-control form-control-sm"}
                                        autoComplete="off" placeholderText="Please Enter Start date" 
                                        minDate={new Date()}
                                    />
                                    <TapIcon.imageIcon icon={TapIcon.CalenderIcon} alt="start_date" className="add_calender-icon" style={{ top: "8px" }} />
                                </div>}
                                {(formData.frequency == 'weekly') && <div className="col-sm-4">
                                    <TapSelect
                                        required
                                        isMulti={true}
                                        options={daysOptions}
                                        value={daysOptions.filter(d => formData.days.includes(d.value))}
                                        changeEvent={o => setSchedularFormData(d => ({...d, days : o.map(e => e.value)}))}
                                        placeholder="Select Days"
                                    />
                                </div>}
                                {(formData.frequency == 'monthly') && <div className="col-sm-4">
                                    <TapSelect
                                        required
                                        isMulti={true}
                                        options={datesOptions}
                                        value={formData.dates.map(a => { return {value : a, label : a} } )}
                                        changeEvent={o => setSchedularFormData(d => ({...d, dates : o.map(e => e.value)}))}
                                        placeholder="Select Dates"
                                    />
                                </div>}
                                <div className="col-sm-2">
                                    <TapSelect
                                        required
                                        options={hourOptions}
                                        value={hourOptions.find(o => o.value === formData.hour)}
                                        changeEvent={(o) => setSchedularFormData(d => ({...d, hour:o ? o.value : null}))}
                                        placeholder="Select Hours"
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <TapSelect
                                        required
                                        options={minuteOptions}
                                        value={minuteOptions.find(o => o.value === formData.minutes)}
                                        changeEvent={(o) => setSchedularFormData(d => ({...d, minutes:o ? o.value : null}))}
                                        placeholder="Select Minutes"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <div className="col-sm-4"><label className="form-label require">Status</label></div>
                                <div className="col-sm-3">
                                    <TapSelect
                                        required
                                        options={statusOptions}
                                        value={statusOptions.find(d => d.value == formData.is_active)}
                                        changeEvent={o => setSchedularFormData(d => ({...d, is_active : o.value}))}
                                        placeholder="Select Status"
                                    />
                                </div>
                            </div>

                        </form> }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" disabled={formData.submitting || formData.loading} data-bs-dismiss="modal">Close</button>
                        <button form={formId + 'Form'} type="submit" disabled={formData.submitting || formData.loading} className="btn btn-primary">Save {formData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    }

    
    return <>
        {schedularModalJsx()}
    </>

});

export default ReportSchedulerModal;