import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import AppBaseComponent from "../../../components/AppBaseComponent";
import AssetCard from "../../includes/assetCard/AssetCard";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Status from "../../../components/ui/Status";
import { Modal } from "bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import swal from "sweetalert";
import TapSelect from "../../../components/ui/TapSelect";
import { Helmet } from "react-helmet";
import AssetMatrixCompliance from "./AssetMatrixCompliance";

class AssetCompliance extends AppBaseComponent {
  constructor(props) {
    super(props);
    this.addComplianceFormInitialState = {
      asset_id: "",
      checksheet_name: "",
      renewal_type: "due_date",
      renewal_date: null,
      remarks: "",
    };
    this.state = {
      card_loading: true,
      id: null,
      warranty_loading: false,
      assetWarrantyList: false,
      addCustom: false,
      update_remark: false,
      updateWarrantyForm: {},
      addComplianceForm: { ...this.addComplianceFormInitialState },
    };
  }

  id = this.props.asset_enc_id;

  componentDidMount() {
    this.getWarrantyData(this.props.asset_enc_id);
    this.addCustomComplianceModal = new Modal(
      document.getElementById("addCustomComplianceModal"),
      { keyboard: false, backdrop: false }
    );
    this.updateWarrantyModal = new Modal(
      document.getElementById("updateWarrantyModal"),
      { keyboard: false, backdrop: false }
    );
  }

  getWarrantyData = (id) => {
    this.setState({ warranty_loading: true });
    HttpAPICall.withAthorization(
      "GET",
      process.env.REACT_APP_IAM_API_SERVER + "/asset/warranty/" + id,
      this.props.access_token
    )
      .then((response) => {
        let respData = response.data;
        this.setState({
          assetWarrantyList: respData,
        });
        this.setState({ warranty_loading: false });
      })
      .then(() => { })
      .catch((err) => {this.setState({ warranty_loading: false })});
  };

  updateWarrantyForm = (event) => {
    event.preventDefault();
    const access_token = localStorage.getItem("access_token");
    let frmData = {
      asset_id: this.id,
      ...this.state.updateWarrantyForm,
    };

    HttpAPICall.withAthorization(
      "PUT",
      process.env.REACT_APP_IAM_API_SERVER + "/asset/warranty/" + this.id,
      this.props.access_token,
      null,
      { ...frmData },
      (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.updateWarrantyModal.hide();
        this.getWarrantyData(this.id);
        this.setState({ updateWarrantyForm: {} });
      }
    );
  };

  //function for update warranty Modal
  updateWarrantyModalJsx = () => {
    return (
      <div className="modal fade" id="updateWarrantyModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addAssetModalLabel">
                Update Warranty Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={this.updateWarrantyForm} id="addModalForm">
              <div className="modal-body">
                {this.state.assetWarrantyList &&
                  this.state.assetWarrantyList.length > 0 &&
                  this.state.assetWarrantyList
                    .filter((i) => i.key !== "")
                    .map((i, k) => {
                      let name = `${i.key}`;
                      let input = null;
                      if (i.type == "text") {
                        input = (
                          <input
                            name={name}
                            type="text"
                            value={this.state.updateWarrantyForm[name]}
                            onChange={(e) =>
                              this.formInputHandler(e, "updateWarrantyForm")
                            }
                            className="form-control"
                            autoComplete="off"
                            placeholder={`Please enter ${i.name}`}
                          />
                        );
                      } else if (i.type == "number") {
                        input = (
                          <input
                            name={name}
                            type="text"
                            value={this.state.updateWarrantyForm[name]}
                            onChange={(e) =>
                              this.formInputHandler(e, "updateWarrantyForm")
                            }
                            className="form-control"
                            autoComplete="off"
                            placeholder={`Please enter ${i.name}`}
                          />
                        );
                      } else if (i.type == "date") {
                        input = (
                          <DatePicker
                            selected={
                              this.state.updateWarrantyForm[name]
                                ? moment(
                                  this.state.updateWarrantyForm[name]
                                ).toDate()
                                : false
                            }
                            name={name}
                            onChange={(value, event) => {
                              this.formDateHandler(
                                name,
                                value,
                                "updateWarrantyForm"
                              );
                            }}
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            scrollMonthYearDropdown
                            placeholderText={`Please Enter ${i.name}`}
                          />
                        );
                      } else if (i.type == "dropdown") {
                        let options = i.options.map((t) => {
                          return { value: t, label: t };
                        });
                        input = (
                          <TapSelect
                            changeEvent={(selectedOption) =>
                              this.tapSelectChange(
                                selectedOption,
                                "updateWarranty",
                                name
                              )
                            }
                            options={options}
                            isSearchable={true}
                            isClearable={true}
                            value={options.find(
                              (o) =>
                                o.value == this.state.updateWarrantyForm[name]
                            )}
                            placeholder={`Please Select ${i.name}`}
                          />
                        );
                      }

                      return (
                        <div
                          key={k}
                          className="row align-items-center mb-3 mt-3"
                        >
                          <div className="col-4">
                            <label className="form-label">{i.name}</label>
                          </div>
                          <div className="col-8">{input}</div>
                        </div>
                      );
                    })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  updateWarrantyModalInit = () => {
    this.updateWarrantyModal.show();
    // let assetBasicDetails                =   this.state.assetComplianceDetails.filter((i) => i.name == "Waranty details");
    let updateWarrantyForm = [];
    if (this.state.assetWarrantyList.length > 0) {
      this.state.assetWarrantyList
        .filter((i) => i.key !== "")
        .map((k) => {
          let name = k.key;
          updateWarrantyForm[name] =
            k.renewal_date === null ? null : k.renewal_date;
        });
      this.setState({
        updateWarrantyForm: {
          ...updateWarrantyForm,
        },
      });
    }
  };


  render() {
    let id = this.props.asset_enc_id;
    return (
      <Ax>
        <Helmet>
          <title>Asset Complaince</title>
        </Helmet>
        <div className="page_containt">
        <div className="pageTbl p-3 pb-5" >
            <div className="tab_content_wrapper mt-2 mb-3">
            <span className="content_heading">Warranty Details</span>
            </div>
            <table className="table table-bordered">
            <tbody>
                {
                    this.state.warranty_loading 
                    ?
                    <tr><td><Loader/></td></tr> 
                    :
                    this.state.assetWarrantyList.length > 0 &&
                    this.state.assetWarrantyList?.map((item, index) => {
                        return (
                        <tr key={index}>
                            <td
                            className="details-label"
                            style={{ width: "30%" }}
                            >
                            {item.name}
                            </td>
                            <td className="details-name" style={{ width: "60%" }}>
                            {item.display === "NA" ? (
                                "NA"
                            ) : (
                                <span>
                                <tapIcon.FontAwesomeIcon
                                    icon={tapIcon.faCircle}
                                    color={item.color_code}
                                />{" "}
                                {item.display}
                                </span>
                            )}
                            </td>
                            {index === 0 ? (
                            <td
                                className="text-center"
                                rowSpan={this.state.assetWarrantyList.length}
                                style={{ width: "10%", verticalAlign: "middle" }}
                            >
                                <button
                                type="button"
                                id="actionMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className="btn"
                                >
                                <tapIcon.imageIcon
                                    icon={tapIcon.ListActionIcon}
                                />
                                </button>
                                <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                                >
                                <li>
                                    <a
                                    role="button"
                                    onClick={() => {
                                        if (
                                        this.props?.permissions?.permissions?.includes(
                                            "iam-compliance-edit"
                                        )
                                        ) {
                                        return this.updateWarrantyModalInit();
                                        }
                                    }}
                                    className={[
                                        "dropdown-item",
                                        this.props?.permissions?.permissions?.includes(
                                        "iam-compliance-edit"
                                        )
                                        ? ""
                                        : "disabled",
                                    ].join(" ")}
                                    title={
                                        !this.props?.permissions?.permissions?.includes(
                                        "iam-compliance-edit"
                                        )
                                        ? "You do not have permission to perform this action"
                                        : ""
                                    }
                                    style={{ pointerEvents: "all" }}
                                    >
                                    Update{" "}
                                    </a>
                                </li>
                                </ul>
                            </td>
                            ) : null}
                        </tr>
                        );
                    })
                }
            </tbody>
            </table>
            <AssetMatrixCompliance />
        </div>
        </div>
        {this.updateWarrantyModalJsx()}
      </Ax>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions: state.app && state.app.acl_info ? state.app.acl_info : null,
  };
};

export default connect(mapStateToProps)(AssetCompliance);
