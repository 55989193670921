import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from "react-redux";
import Ax from "../../../../../components/hoc/Ax";
import { Helmet } from 'react-helmet';
import TapSelect from '../../../../../components/ui/TapSelect';
import TabNavigationAssetMatrix from '../TabnavigationAssetMatrix';
import HttpAPICall from '../../../../../services/HttpAPICall';
import { IAM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../../../components/ui/Loader/Loader';
import TapIcon from '../../../../../services/TapIcon';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import swal from "sweetalert";

const Document = ({ matrix_enc_id }) => {

    const access_token = useSelector(state => state.auth.access_token);
    const permissions = useSelector(state => state.app.acl_info && state.app.acl_info.permissions ? state.app.acl_info.permissions : []);


    let amDocFormInitData = {
        name: "",
        number: "",
        file: null,
        file_name: null,
        base64_document: null,
        submitting: false,
    };

    const [modalId, setModalId] = useState(uuid());
    const [formId, setformId] = useState(uuid());
    const [matrixId, setMatrixId] = useState(matrix_enc_id);
    const [documents, setDocuments] = useState([]);
    const [other, setOther] = useState('N');
    const [amDocForm, setAmDocForm] = useState(amDocFormInitData);
    const [docTypes, setDocTypes] = useState([]);

    const [loading, setLoading] = useState(false);
    const [frmDataLoaded, setfrmDataLoaded] = useState(false);
    const [frmDataLoading, setfrmDataLoading] = useState(false);




    let setting_doc_types_url = ORG_API_BASE_URL_2 + '/setting/doc_types';
    let assetMatrixDocsurl = IAM_API_BASE_URL_2 + '/asset_matrix/document/__replace_am_id';
    let assetMatrixDocSaveUrl = IAM_API_BASE_URL_2 + '/asset_matrix/document';
    let assetMatrixDocDeleteurl = IAM_API_BASE_URL_2 + '/asset_matrix/document/__replace_doc_id';


    const addDocModalElem = useRef(null);
    const addDocModal = useRef(null);

    useEffect(() => {
        addDocModalElem.current = document.getElementById(modalId);
        addDocModal.current = new Modal(addDocModalElem.current, { keyboard: false, backdrop: false });


        getDocumentList();

        // Cleanup on unmount
        return () => {
            if (addDocModal.current) {
                addDocModal.current.dispose();
            }
        };

    }, []);

    const loadDocTypesFromApi = () => {
        setfrmDataLoading(true);
        HttpAPICall.withAthorization('GET', setting_doc_types_url, access_token, {}, {}, (response) => {
            let docTypes = response.data.map(s => { return { value: s, label: s }; });
            setDocTypes(docTypes);
            setfrmDataLoaded(true);
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => setfrmDataLoading(false));
    }

    const getDocumentList = () => {
        setLoading(true);
        HttpAPICall.withAthorization('GET', assetMatrixDocsurl.replace('__replace_am_id', matrixId), access_token, {}, {}, (response) => {
            setDocuments(response.data);
            setLoading(false);
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {
            setLoading(false);
        });
    }

    const addDocSubmitHandler = (event) => {
        event.preventDefault();
        let formData = { ...amDocForm, asset_matrix_id: matrixId };
        setAmDocForm(data => ({ ...data, submitting: true }));
        HttpAPICall.withAthorization('POST', assetMatrixDocSaveUrl, access_token, {}, formData, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            addDocModalModalClose();
            getDocumentList();
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {
            setAmDocForm(data => ({ ...data, submitting: false }));
        });
    }

    const handleFileChange = async (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64 = await convertBase64(file);
        setAmDocForm(data => ({ ...data, base64_document: base64, file_name: filename }));
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const addDocModalModalOpen = () => {
        if (addDocModal.current) {
            addDocModal.current.show();
        }
    }

    const addDocModalModalClose = () => {
        if (addDocModal.current) {
            addDocModal.current.hide();
        }
        setAmDocForm(amDocFormInitData);
    }

    const addDocumentModalInit = () => {
        addDocModalModalOpen();
        if (!frmDataLoaded) {
            loadDocTypesFromApi()
        }
    }


    const deleteDocument = (item_id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', assetMatrixDocDeleteurl.replace('__replace_doc_id', item_id), access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    getDocumentList();
                }).then(() => { });
            }
        });
    }

    const docAddModalJsx = () => {
        return <div className="modal fade" id={modalId} tabindex="-1" ref={addDocModalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Asset Matrix Document</h5>
                        <button type="button" className="btn-close" disabled={frmDataLoading || amDocForm.submitting} onClick={addDocModalModalClose}></button>
                    </div>
                    <div className="modal-body">
                        {frmDataLoading ? <Loader /> : <form id={formId + 'Form'} onSubmit={addDocSubmitHandler}>
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    <label className="form-label require">Document Type</label>
                                </div>
                                <div className="col-sm-7">
                                    {other === "Y"
                                        ? <input
                                            name="name"
                                            type="text"
                                            value={amDocForm.name}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => setAmDocForm({ ...amDocForm, name: e.target.value })}
                                            placeholder="Please enter Type Name"
                                            required={true}
                                        />
                                        : <TapSelect
                                            options={docTypes}
                                            changeEvent={(o) => {
                                                setAmDocForm({ ...amDocForm, name: o ? o.value : '' });
                                            }}
                                            value={docTypes.find(u => amDocForm.name == u.value)}
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder="Select Document Type"
                                            required={true}
                                            autoCompelete="off"
                                        />}
                                </div>
                                <div className="col-sm-2">
                                    <input
                                        name="other"
                                        type="checkbox"
                                        onChange={() => setOther(other === 'N' ? 'Y' : 'N')}
                                        checked={other === 'Y'}
                                        className="form-check-input"
                                        id="other"
                                    />
                                    <label className="form-check-label mx-2" htmlFor="other">New</label>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-3">
                                    <label className="form-label">Document Number</label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        name="number"
                                        type="text"
                                        value={amDocForm.number}
                                        onChange={(e) => setAmDocForm({ ...amDocForm, number: e.target.value })}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Enter Document Number"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <label className="form-label require">Document</label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        name="file"
                                        type="file"
                                        onChange={(e) => handleFileChange(e)}
                                        className="form-control"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            </div>
                        </form>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" disabled={frmDataLoading || amDocForm.submitting} className="btn btn-secondary" onClick={addDocModalModalClose}>Close</button>
                        <button disabled={frmDataLoading || amDocForm.submitting} form={formId + 'Form'} type="submit" className="btn btn-primary">Save {amDocForm.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <Ax>
            <Helmet><title>Asset Matrix Document</title></Helmet>
            <ToastContainer />
            <div className="row ml1">
                <div className="col-12 pl0 bg-white ">
                    <TabNavigationAssetMatrix matrixId={matrixId} />
                    <div className="tab-content" id="myTabContent">
                        <div className="pageTbl p-3" style={{ height: "100%" }}>
                            <div className="tab_content_wrapper mt-2 pe-0">
                                <span className="content_heading">Documents</span>
                                <div className="float-end">
                                    {
                                        permissions.includes('iam-asset-matrix-doc-add') && <button className="btn btn-primary"
                                            onClick={addDocumentModalInit}>Add Document
                                        </button>
                                    }

                                </div>
                            </div>

                            {loading}


                            {loading ? <Loader /> : <table className="table table-bordered mt-3">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "5%" }}>S.No</th>
                                        <th style={{ width: "31%" }}>Document Name</th>
                                        <th style={{ width: "31%" }}>Document Number</th>
                                        <th style={{ width: "31%" }}>Size</th>
                                        <th style={{ width: "11%" }} className="text-center">View</th>
                                        <th style={{ width: "11%" }} className="text-center">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.length > 0 ? documents.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.number}</td>
                                            <td>{item.size_display}</td>
                                            <td scope="col" className="text-center">
                                                <a role="button"
                                                    target='_blank'
                                                    href={item.path}
                                                    className={[permissions.includes('iam-asset-matrix-doc-view') ? '' : 'disabled'].join(' ')}
                                                    title={!permissions.includes('iam-asset-matrix-doc-view') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                > <TapIcon.FontAwesomeIcon icon={TapIcon.faEye} /></a>
                                            </td>
                                            <td className="text-center">
                                                <a role="button"
                                                    onClick={() => {
                                                        if (permissions.includes('iam-asset-matrix-doc-delete')) {
                                                            return deleteDocument(item.id)
                                                        }
                                                    }}
                                                    className={[permissions.includes('iam-asset-matrix-doc-delete') ? '' : 'disabled'].join(' ')}
                                                    title={!permissions.includes('iam-asset-matrix-doc-delete') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                ><TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt} color={"blue"} /></a>
                                            </td>
                                        </tr>
                                    )) : <tr><td className='text-center' colSpan={6}>No Record Found</td></tr>}
                                </tbody>
                            </table>}
                        </div>
                    </div>
                </div>
            </div>
            {docAddModalJsx()}
        </Ax>
    );
}

export default Document;
