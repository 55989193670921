import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";

import Ax from "../../../components/hoc/Ax";

import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';

import ApplicationLayout from '../../../layouts/ApplicationLayout';

import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from '../../../services/TapIcon';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { toast } from 'react-toastify';


class CounterlogHistoryList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                         =     {  
            minimizeTable                   :       false,   
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            counterlog_listing              :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            counterlogHistoryData           :       null,
            viewDataLoading                 :       false,
            access_token                    :       '',
            all_periods                     :        [],
        };

    }
    
    
    componentDidMount() {
        this.setState({access_token : this.props.access_token})
        // this.loadListingTblData(this.state.listing_tbl_page);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props) {
            this.setState({access_token : nextProps.access_token})
            
        }
            
    }

    loadListingTblData                 =   (page = 1)  =>         {
        // this.setState({listing_loading: true, listing_tbl_page: page});
        // let params                      =   {page:page,need_asset_profile : 'Y'};
        // HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/CounterlogHistory_list', this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        //     let respData = resp.data;
        //     this.setState({
        //         CounterlogHistory_listing         :           respData.data,
        //         listingMeta             :           respData.meta,
        //         totalListingCount       :           respData.meta.total,
        // });
        // }).then(() => this.setState({listing_loading: false}));
    }

    viewCounterlogHistoryDetail           =       (id)        =>      {
        this.setState({minimizeTable : true})
        
    }

    closeView           =       ()        =>      {
        this.setState({minimizeTable : false})
    }

    getCounterlogHistoryDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/CounterlogHistory/' + id, this.state.access_token ? this.state.access_token : this.props.access_token, {need_asset_profile : 'Y'}, {}, (response) => {
            this.setState({
                CounterlogHistoryData: response.data.data
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

     //***********************CounterlogHistory JSX****************

    counterlogHistoryListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Counterlog List</h3>
                <div className="text-end mt15">
                   
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3 className="text-capitalize">Counterlog History  : </h3>
                            <div className="text-end mt15">

                                <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="CounterlogHistory-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.counterlogHistoryListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4 bg-white ">{this.state.viewDataLoading ? <Loader /> : <div className='tab_content_header' style={{ height: "80vh" }}>{this.counterlogHistoryViewJsx()}</div>}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    counterlogHistoryListingTableJsx            =   ()   =>   {
       
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>CounterlogHistory</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "%" }} className='text-center'>Date</th>
                            <th scope="col" style={{ width: "12%" }}> Transaction ID</th>
                            <th scope="col" style={{ width: "13%" }}>Asset</th>
                            <th scope="col" style={{ width: "12%" }}>Asset Type</th>
                            <th scope="col" style={{ width: "12%" }}>Activity Name</th>
                            <th scope="col" style={{ width: "9%" }} className='text-end'>Transaction Delay</th>
                            <th scope="col" style={{ width: "10%" }}>Counter Log</th>
                            <th scope="col" style={{ width: "14%" }}>Cumulative Counter Log</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    <tr>
                        <td className='text-center'>1</td>
                        <td className='text-center'>05-Jul-2022</td>
                        <td>RC-220723-13501166</td>
                        <td>AB Wheel Loader</td>
                        <td>Wheel Loader</td>
                        <td>Roster Counterlog</td>
                        <td className='text-end'>2 Days</td>
                        <td>271135.00 Km</td>
                        <td>34079.20 Km</td>
                        <td className="text-center">
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" role="button" >View</a></li>

                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
           
        </Ax>)
    }

    counterlogHistoryViewJsx           =       ()      =>      {
        return (<Ax>
          View
        </Ax>)
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Counterlog History Listing</title></Helmet>
            {this.counterlogHistoryListjsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(CounterlogHistoryList);