import React from 'react';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import Ax from '../../components/hoc/Ax';
import TappetLogo from '../../assets/img/svg/logo_new.svg';
import additionalLogo from '../../assets/img/janmashtami.png'



import AdminAvatar from '../../assets/img/admin_logo.png';
import {Link} from 'react-router-dom';
import './ApplicationHeader.css';
import { connect } from 'react-redux';
import TapSelect from '../../components/ui/TapSelect';
import UserSvg from '../../assets/img/svg/user.svg';
import DropDownIcon from '../../assets/img/svg/arrow-dropdown.svg';
import SearchIcon from '../../assets/img/svg/search-icon.svg';
import UserSvg2 from '../../assets/img/svg/User_icon_2.svg';
import {Collapse, Modal} from "bootstrap";
import FeedbackModal from "../../layouts/modal/FeedbackModal";
import TapIcon from '../../services/TapIcon';
import * as actions from '../../store/actions/index';
import FuelReceive from '../groupData/fuelStation/FuelReceive';
import FuelIssued from '../groupData/fuelStation/FuelIssued';
import FuelTransfer from '../groupData/fuelStation/FuelTransfer';


class ApplicationHeader extends React.Component {
    
    options = [
        { value: 1, label: 'Assets 1' },
        { value: 2, label: 'Assets 2' },
        { value: 3, label: 'Tickets' },
        { value: 4, label: 'Checksheet' },
        { value: 5, label: 'Requests' },
        { value: 6, label: 'Requests 2' },
        { value: 7, label: 'Requests 3' },
        { value: 8, label: 'Requests 4' },
        { value: 9, label: 'Requests 5' },
        { value: 10, label: 'Requests 6' },
        { value: 11, label: 'Requests 7' },
        { value: 12, label: 'Requests 8' },
        { value: 13, label: 'Requests 9' },
    ];
     
    formatGroupLabel = data => (
        <div style={this.groupStyles}>
          <span>{data.label}</span>
          <span style={this.groupBadgeStyles}>{this.options.length}</span>
        </div>
    );

    
    constructor() {
        super();
        this.state      = {
            userDropdown               :        true,
            showModalNew               :        false,
            openQuickNavigation        :         false,

        };
        this.wrapperRef = React.createRef();
   
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.logFuelReceiveRef             =   React.createRef();
        this.logFuelIssuedRef             =   React.createRef()
        this.logFuelTransferRef             =   React.createRef() 
    }

    addFeedbackModalInit                 =   ()  =>  {
        this.setState({showModalNew  : true});
    }

    closeModal    = () => {
        this.setState({showModalNew  : false});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({openQuickNavigation : false})
        }
    }

    openQuickNavigation            =        ()  =>  {
        var isShown = document.getElementById('quick').classList.contains('show');
        if(isShown == true){
           this.setState({openQuickNavigation : true})
        }else{
            this.setState({openQuickNavigation : false})
        }
    }

    clearCacheDataHandler       =       ()      =>      {
        this.clearLocalStorageHandler()
         this.props.clearAppData();
         window.location.reload();
    }

    clearLocalStorageHandler        =       ()      =>      {
        localStorage.removeItem('assetStatusWidget');
        localStorage.removeItem('transactionDelayWidget');
        localStorage.removeItem('scsWidget');
        localStorage.removeItem('rentalStatusWidget');
        localStorage.removeItem('rentalDueDateWidget');
        localStorage.removeItem('counterlogAgeingWidget');
        localStorage.removeItem('ticketWidget');
        localStorage.removeItem('PUCWidget');
        localStorage.removeItem('requistionWidget');
        localStorage.removeItem('purchaseOrderWidget');
        localStorage.removeItem('complianceWidget');
        localStorage.removeItem('walletWidget');
        localStorage.removeItem('fuelSummaryWidget');
        localStorage.removeItem('releasesList');
        localStorage.removeItem('indentAgeingWidget');
        localStorage.removeItem('requistionAgeingWidget');
        localStorage.removeItem('poAgeingWidget');
        localStorage.removeItem('EmployeeComplianceWidget');
        localStorage.removeItem('stockMovementWarehouseWidget');
        localStorage.removeItem('fuelConsumptionWidget');
        localStorage.removeItem('productivityWidget');
        localStorage.removeItem('taskActivity');
        localStorage.removeItem('segregationWidget');
        localStorage.removeItem('hgSaptaskActivity');
    }

    receiveModalInit        =       ()        =>      {
       
        this.logFuelReceiveRef.current.fuelReceiveModalInit()
    }

    fuelIssuedModalInit        =       (id)        =>      {
        this.logFuelIssuedRef.current.issuedFuelModalInit()
    }

    fuelTransferModalInit        =       (id)        =>      {
        this.logFuelTransferRef.current.transferFuelModalInit()
       
    }



    render() {
        let wallet_permission = this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet")

        let {group_info} =  {...this.props}

        let logo = (group_info && group_info.activate_group_logo == 'Y' && group_info.image_path)
                        ? this.props.group_info.image_path
                        : TappetLogo;

        return (
            <Ax>
                <div className="app__header container-fluid bg-white" >
                    <div className="row p-2">
                        <div className="col-sm-8">
                            <Link to="/my_dashboard" className="app-header_logo">
                                <img src={logo} style={{maxHeight:"58px"}} alt="TappetBox" />
                                {/* <img src={additionalLogo} style={{maxHeight:"68px", marginTop: "-10px"}} alt="TappetBox" className='ms-2' /> */}
                            </Link>
                        </div>
                        {/* <div className="col-sm-4 pt-1"> 
                                <div className="input-group">
                                    <TapSelect options={this.options} width='170px' defaultValue="1" />
                                    <input type="text" className="form-control app-search__input" placeholder="Search by Name, Id, Stock" />
                                    <button type="button" className="app-search__button"><img src={SearchIcon} /></button>
                                </div>
                        </div> */}
                        <div className="col-sm-4 applicationHeader">
                            <ul className="nav justify-content-end">
                                <li className="nav-item " ref={this.wrapperRef}>
                                    <a role="button" className="nav-link mx-4" id="quick" style={{ 'padding': '10px 15px 0px 15px' }} onClick={this.openQuickNavigation} data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" >
                                        {this.state.openQuickNavigation == false ? <TapIcon.FontAwesomeIcon icon={TapIcon.faTimesCircle} className="rotate" style={{ 'height': '35px', width: "25px" }} color={"#45BEA4"} />
                                            : <TapIcon.FontAwesomeIcon icon={TapIcon.faTimesCircle} style={{ 'height': '35px', width: "25px" }} color={"#45BEA4"} />}
                                    </a>

                                    <ul className="dropdown-menu ">
                                        <li>
                                            <div className="row" >
                                                {!this.props.permissions.group_modules.includes("isc") ? null : (<ul className="list-unstyled col-sm mr0 pr0">
                                                    <li className="dropdown-header ">Inventory</li>
                                                    {this.props.permissions.permissions.includes("isc-item-add") && <Link to={'/item_list/add_item'}><li className='dropdown-item'>+ Item</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-warehouse-indent-add") && <Link to={'/warehouse_indent_add'}><li className='dropdown-item'>+ Warehouse Indent</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-purchase-requisition-add") && <Link to={'/requisition_list/add'}> <li className='dropdown-item'>+ Purchase Requisition</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-po-add") && <Link to={'/purchase_order/add'}><li className='dropdown-item'>+ Purchase Order</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-purchase-add") &&  <Link to={'/purchase_list/add'}><li className='dropdown-item'>+ Purchase</li></Link>}
                                                    {this.props.permissions.permissions.includes("/item_demand_add") &&  <Link to={'/item_demand_add'}><li className='dropdown-item'>+ Item Demand</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-allocation-add") &&  <Link to={'/allocation_list/add'}><li className='dropdown-item'>+ Allocation</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-consumption-add") && <Link to={'/consumption_list/add'}> <li className='dropdown-item'>+ Consumption</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-item-opening-balance") && <Link to={'/item_opening_balance/add'}><li className='dropdown-item'>+ Opening Balance</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-inventory_transfer-add") && <Link to={'/inventory_transfer_list/add'}><li className='dropdown-item'>+ Inventory Transfer</li></Link>}
                                                    {this.props.permissions.permissions.includes("isc-inventory-adjustment-add") && <Link to={'/inventory_adjustment_add'}><li className='dropdown-item'>+ Inventory Adjustment</li></Link>}
                                                </ul>)}
                                                <ul className="list-unstyled col-sm mr0 pr0">
                                                    <li className="dropdown-header ">Group Data</li>
                                                    <Link to={'/fuelstation_add'}><li className='dropdown-item'>+ Fuel Station</li></Link>
                                                    <Link to={'/warehouse/add'}><li className='dropdown-item'>+ Warehouse</li></Link>
                                                    {this.props.permissions.permissions.includes("vendor-add") && <Link to={'/vendor/add'}><li className='dropdown-item'>+ Vendor</li></Link>}
                                                   
                                                </ul>
                                                <ul className="list-unstyled col-sm">
                                                <li className="dropdown-header ">Fuel Station</li>
                                                    <li>   <a role="button"
                                                        onClick={() => {
                                                            if (this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-receive')) {
                                                                return this.receiveModalInit()
                                                            }
                                                        }}
                                                        className={['dropdown-item', this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-receive') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-receive') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >+ Received Fuel</a></li>
                                                    <li>   <a role="button"
                                                        onClick={() => {
                                                            if (this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-issue')) {
                                                                return this.fuelIssuedModalInit()
                                                            }
                                                        }}
                                                        className={['dropdown-item', this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-issue') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-issue') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >+ Issue Fuel</a></li>
                                                    <li>   <a role="button"
                                                        onClick={() => {
                                                            if (this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-transfer')) {
                                                                return this.fuelTransferModalInit()
                                                            }
                                                        }}
                                                        className={['dropdown-item', this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-transfer') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props?.permissions?.permissions?.includes('iam-fuel-station-fuel-transfer') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >+ Fuel Transfer To Station</a></li>

                                                </ul>
                                                <ul className={!wallet_permission ? "list-unstyled col-sm  ml0  pl0" : "list-unstyled col-sm"}>
                                                    <li className="dropdown-header">Asset</li>
                                                    <Link to={'/daily_counterlog'}> <li className='dropdown-item'>+ Logsheet</li></Link>
                                                    <Link to={'/ticket/add'}> <li className='dropdown-item'>+ Ticket</li></Link>
                                                </ul>
                                                {!wallet_permission ? null
                                                    : (<ul className="list-unstyled col-sm ml0 pl0">
                                                        <li className="dropdown-header">Accounts Module</li>
                                                        <Link to={'/wallet/add'}><li className='dropdown-item'>+ Wallet</li></Link>
                                                        <Link to={'/wallet/fund-transfer'}><li className='dropdown-item'>+ Fund Transfer</li></Link>
                                                        <Link to={'/report/asset_invoice_calculator'}><li className='dropdown-item'>+ Invoice Calculation</li></Link>
                                                    </ul>)}
                                            </div>
                                        </li>
                                    </ul>


                                </li> 
                                <li className="nav-item ">

                                    <a className="nav-link dropdown-toggle" style={{ 'padding': '10px 15px 0px 15px' }} data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                                    <img src={AdminAvatar} style={{ 'height': '39px' }} className="avatar rounded-circle" alt={this.props.user_name} />
                                    </a>
                                    <ul className="dropdown-menu cursor_pointer" >
                                        <li className="dropdown-item">{this.props.user_name}</li>
                                        <li className="dropdown-item" onClick={() => this.addFeedbackModalInit()}>Feedback</li>
                                        <li><Link title='Clear Cache & Data' className="dropdown-item" onClick={() => this.clearCacheDataHandler()}>Refresh</Link></li>
                                        <li><Link className="dropdown-item" to="/logout">Logout</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {this.state.showModalNew === true ? <FeedbackModal close={this.closeModal} /> : null}
                <FuelReceive ref={this.logFuelReceiveRef}/>
           <FuelIssued ref={this.logFuelIssuedRef}/>
           <FuelTransfer ref={this.logFuelTransferRef}/>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        user_name           :   state.app.user_info?.name??'',
        group_info          :   state.app.group_info,
        permissions         :   state.app && state.app.acl_info ? state.app.acl_info : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearAppData : () => dispatch(actions.clearAppData())
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(ApplicationHeader);