import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Ax from '../../../../components/hoc/Ax';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


class TransactionSetting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initEditSettingForm           =         {
            name                            :        'Sales Order',
            type                            :        "manual",
            prefix                          :        "",
            numbering                       :        "",
            activity_id                     :        "",
            activity_type                    :       ""
        }
        this.state                          =        {
            transactionList                 :        [],
            listing_loading                 :       false,
            editSettingFormSubmitting       :       false,
            editSettingForm                 :       {...this.initEditSettingForm}
        }

        this.quoteSettingUrl    =   INVENTORY_API_BASE_URL_2  + "/sales_order_id_setting";  
        this.quoteSettingEditUrl    =   INVENTORY_API_BASE_URL_2  + "/sales_order_id_setting/edit";        
    }

    componentDidMount(){
        this.getTransactionListing()
        this.editSettingModal            =        new Modal(document.getElementById('editSettingModal'), {keyboard: false, backdrop :false});
    }

    getTransactionListing       =       ()      =>  {
        this.setState({ listing_loading : true});
        HttpAPICall.withAthorization('GET', this.quoteSettingUrl, this.props.access_token,{},{},(response) => {
            this.setState({transactionList: response.data.data})
         }).then(() => this.setState({listing_loading : false}));
    }

    editSettingFormInit         =       (type)      =>  {
        this.editSettingModal.show()
        let transactionList             =            this.state.transactionList.find(tr => tr.activity_type == type)

        this.setState({
            editSettingForm             :            {
                name                    :            transactionList.activity_type == "sales_work_order" ? "Work Order" : "Sales Order" ,
                type                    :           transactionList.type,
                prefix                  :           transactionList.prefix === "" ? "" : transactionList.prefix,
                numbering               :           transactionList.start_from_dispaly === "" ? "" : transactionList.start_from_dispaly,
                activity_id             :           transactionList.activity_id,
                activity_type           :           transactionList.activity_type
            },
        })
    }
    onTypeChange        =       ( )     =>      {
        this.setState({editSettingForm      :       {
            ...this.state.editSettingForm,
            prefix                          :       "",
            numbering                       :       "",
            type                            :       "manual"
        }})
    }

    submitEditSettingForm           =       (e)      =>      {
        e.preventDefault()
        let frmData = {
            activity_id         :   this.state.editSettingForm.activity_id,
            activity_type         :   this.state.editSettingForm.activity_type,
            type                :   this.state.editSettingForm.type,
            prefix              :   this.state.editSettingForm.prefix,
            start_from          :   parseInt(this.state.editSettingForm.numbering),
            padded_integer      :   this.state.editSettingForm.numbering.length
           };
           this.setState({editSettingFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', this.quoteSettingEditUrl, this.props.access_token, {} , {...frmData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editSettingModal.hide();
            this.getTransactionListing()
            this.setState({editSettingForm  :  {...this.initEditSettingForm}})
        }).then(() => this.setState({editSettingFormSubmitting: false}))
    }

    //*********************************EDIT SETTING JSX*************************
    editSettingModalJsx              =   ()  =>  {

        return (
            <div className="modal fade" id="editSettingModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editSettingModalLabel">Update Settings</h5>
                        </div>
                        <form onSubmit={this.submitEditSettingForm} id="editSettingForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                <div className="row align-trs-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Sales Order</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={this.state.editSettingForm.name}
                                            disabled={true}
                                            placeholder="Please Enter  Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-trs-center my-3">
                                    <div className="col-sm-3">
                                        <label className="form-label require">ID Type</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="type"
                                                type="radio"
                                                value="manual"
                                                onChange={(e) => { this.formInputHandler(e, 'editSettingForm'); this.onTypeChange() }}
                                                checked={this.state.editSettingForm.type === "manual"}
                                                className="form-check-input"
                                                required={true}
                                                id="editSettingManual"
                                            />
                                            <label className="form-check-label" htmlFor="editSettingManual">Manual</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="type"
                                                type="radio"
                                                value="system_generate"
                                                onChange={(e) => { this.formInputHandler(e, 'editSettingForm') }}
                                                checked={this.state.editSettingForm.type === "system_generate"}
                                                className="form-check-input"
                                                required={true}
                                                id="editSettingSystem"
                                            />
                                            <label className="form-check-label" htmlFor="editSettingSystem">Auto-Increment</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-trs-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">ID Prefix</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="prefix"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'editSettingForm')}
                                            value={this.state.editSettingForm.prefix}
                                            disabled={this.state.editSettingForm.type === "manual" ? true : false}
                                            placeholder="Please Enter Prefix"
                                        />
                                    </div>
                                </div>
                                <div className="row align-trs-center my-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="transaction Type" className="form-label require">Numbering</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input
                                            name="numbering"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, 'editSettingForm')}
                                            value={this.state.editSettingForm.numbering}
                                            disabled={this.state.editSettingForm.type === "manual" ? true : false}
                                            placeholder="Please Enter Numbering"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.editSettingFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ editSettingForm: { ...this.initEditSettingForm } }) }}>Close</button>
                                <button type="submit" disabled={this.state.editSettingFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.editSettingFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render() {
        return (
            <Ax>
                <div className="tab_content_wrapper">
                    <span className="content_heading">Sales Order ID Settings</span>
                </div>
                <table className="table table-hover table-bordered my-3 ">
                    <thead className='table-secondary'>
                        <tr>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Sr.No</th>
                            <th scope="col" style={{ width: "15%" }}>Sales Order</th>
                            <th scope="col" style={{ width: "20%" }}>ID Type</th>
                            <th scope="col" style={{ width: "25%" }}>ID Prefix</th>
                            <th scope="col" style={{ width: "25%" }}>Numbering</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody className="table-body">
                    {this.state.listing_loading
                            ? <tr>
                                <td className="text-center" colSpan="6"><Loader /></td>
                            </tr>
                            : this.state.transactionList && this.state.transactionList.length > 0 && 
                           this.state.transactionList.map((tr,index) => {return(<tr>
                            <td scope="row" className='text-center' key={index}>{index+1}</td>
                            <td scope="row">{tr.activity_type == "sales_work_order" ? "Work Order" : 'Sales Order'}</td>
                            <td scope="row">{tr.type_display}</td>
                            <td scope="row">{tr.prefix === "" || tr.prefix === null ? "-" : tr.prefix}</td>
                            <td scope="row">{tr.start_from_dispaly === "" ? "-" : tr.start_from_dispaly}</td>
                            <td scope="row" className="ml-2 text-center">
                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                    data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                />
                                <ul className="dropdown-menu">
                                <li>
                                    <Link role="button"
                                        onClick={() => {
                                            if (this.props?.permissions?.includes('isc-configuration-edit')) {
                                                return this.editSettingFormInit(tr.activity_type)
                                            }
                                        }}
                                        className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-configuration-edit') ? '' : 'disabled'].join(' ')}
                                        title={!this.props?.permissions?.includes('isc-configuration-edit') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Edit</Link>
                                </li>
                                   
                                </ul>
                            </td>
                        </tr>)})
                            
                        }
                    </tbody>
                </table>
                {this.editSettingModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    
    };
};

export default connect(mapStateToProps)(TransactionSetting);