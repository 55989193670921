import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import DatePicker from "react-datepicker";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import { Collapse } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import HttpAPICall from "../../../services/HttpAPICall";
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import Loader from "../../../components/ui/Loader/Loader";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import { Link } from 'react-router-dom';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class InventoryForecastingReport extends AppBaseComponent {

    constructor() {
        super();

        let today                                     =   new Date()

        this.forecastingFilterFormInitialState   =   {
            search_site_ids                               :   [],
            'asset_group_ids'                            :    [],
            search_asset_type_ids                         :   [],
            search_asset_matrix_ids                       :   [],
            search_manufacturer_ids                       :   [],
            search_models                                 :   [],
            search_variant                                :   [],
            search_asset_ids                              :   [],
            date_from                                     :  new Date(),
            date_to                                       :  new Date(today.getFullYear(), today.getMonth(),today.getDate() + 30),  
            search_item_ids                               :   [],
            search_item_tags                              :   [],
        };

        this.forecastingSearchFormLabel        =    {
            search_site_ids                               :   'Sites : ',
            'asset_group_ids'                             :   'Asset Type Groups : ',
            search_asset_type_ids                         :   'Asset Type : ',
            search_asset_matrix_ids                       :   'Asset Matrix : ',
            search_manufacturer_ids                       :   'Manufacturer : ',
            search_models                                 :   'Models : ',
            search_variant                                :   'Variants : ',
            search_asset_ids                              :   'Assets : ',
            date_from                                     :   'Date  :  ',
            search_item_tags                              :   'Item Tags : ',
            search_item_ids                               :   'Items : ',
        }

        this.state                              =   {
            showSaveForecastingBtn              :   false,
            showDownloadForecastingBtn          :   false,
            forecastingDownloading              :   false,
            showSaveForecastingForm             :   false,
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null,
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],
            iam_asset_matrix                    :   [],
            iam_manufactures                    :   [],
            iam_asset_variants                  :   [],
            iam_models                          :   [],
            searchedAsset                       :   null,
            searchedAssets                      :   [],
            allAssetCategory                    :   [],
            selectedPeriod                      :   '',
            downloadReportBtn                   :   false,
            reportDownloading                   :   false,
            listing_tbl_page                    :   1,
            formSearchedElems                   :   [],
            permissions                         :   null,
            searchedItem                        :   [],
            all_periods                         :   [],
            allItemTags                         :   [],
            forecastingFilterForm               :   {...this.forecastingFilterFormInitialState},
            submittedForecastingFilterForm      :   {...this.forecastingFilterFormInitialState},
            allAssetTypesGroupsList             :   [],
            download_access                     :   false,   
            reportData                          :   null                  
        };

        this.reportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item-forecasting';

    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        this.reportFilterhandler()
        
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeFormFilter(nextProps);
        }
    }



    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('forecastingFilterFormJsx'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    initilaizeFormFilter                    =   (props)  =>  {
        const report_name   =   props?.location?.pathname ? props.location.pathname.split('/')[2] : '';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        let all_periods             =       [...props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
            permissions             :   props.permissions,
            all_periods             :   all_periods ,
            formDataLoaded          :   true,
            allItemTags             :   props.item_tags && props.item_tags.length > 0 ? props.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
            iam_manufactures        :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
            iam_asset_variants      :   props.iam_asset_variants ? props.iam_asset_variants.map((v) => { return {value: v, label: v}}) : [],
            iam_models              :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        },() => {

            let ParamObject                 =   new URLSearchParams(this.props.location.search);
            let updateFilterForm = {};
            if(ParamObject.get('asset_id')){
                   
               updateFilterForm        =   {search_asset_ids : [ParamObject.get('asset_id')]};
            
                setTimeout(() => {
                    this.setState({forecastingFilterForm :   {...this.state.forecastingFilterForm, ...updateFilterForm},
                        searchedAssets         :   [{value : ParamObject.get('asset_id'),
                    display_label              :  `${ParamObject.get('asset_name')} (${ParamObject.get('asset_code')})`}]},
                    () => this.reportPerformanceFilterFormSubmit(),this.reportFilterhandler(true));
                }, 1000)
            }
            if (ParamObject.get('search_item_ids')) {
                updateFilterForm        =   {search_item_ids : [ParamObject.get('search_item_ids')]};
               
                setTimeout(() => {
                    this.setState({forecastingFilterForm :   {...this.state.forecastingFilterForm, ...updateFilterForm},
                        searchedItem            :   [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }],
                     }, () => this.reportPerformanceFilterFormSubmit(),this.reportFilterhandler(true));
                }, 1000)
            }
        });
    }

    reportPerformanceFilterFormSubmit       =   (event)  =>  {
        event && event.preventDefault()
       
        let serachFormKeys                      =   Object.keys(this.state.forecastingFilterForm);

    
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.forecastingSearchFormLabel[key];
            let value                       =   this.state.forecastingFilterForm[key];
            if(value && value.toString().length > 0) {
                assetSearchParams[key]      =   value;
               
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                  
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_from') {
                        let  start_range         =      moment(this.state.forecastingFilterForm.date_from).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.forecastingFilterForm.date_to).format('DD-MMM-YYYY')
                            let display_date     =      `(${start_range} - ${end_range})`
                            show_val                 =     display_date;
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });

        this.setState({
            submittedForecastingFilterForm    : {...this.state.forecastingFilterForm},
            formSearchedElems                   :  searchedElems,
            assetSearchParams                   :  assetSearchParams
        }, () => {
            this.loadListingTblData(1);
        });
    }

    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({
             listing_loading         :   true,
            listingData             :   [],
            listing_tbl_page        :   page,
            downloadReportBtn       :   false,
            reportDownloading       :   false
        });

        let submittedFilterForm     =       {
            ...this.state.submittedForecastingFilterForm,
            date_from  :  moment(this.state.submittedForecastingFilterForm.date_from).format('YYYY-MM-DD'),
            date_to : moment(this.state.submittedForecastingFilterForm.date_to).format('YYYY-MM-DD'),
        }
      
        let params                          =   {page:page,...submittedFilterForm};
        HttpAPICall.withAthorization('GET',this.reportUrl, this.props.access_token,params,{},(response) => {
            let respData                =   response.data;
           
            if(respData.data) {
                this.setState({
                    listingData                 :   respData.data,
                    downloadReportBtn           :   true,
                    listingMeta                 :   {
                        from                        :   respData.from,
                        to                          :   respData.to,
                        total                       :   respData.total,
                        last_page                   :   respData.last_page,
                        per_page                    :   respData.per_page,
                        current_page                :   respData.current_page
                    }
                });
            }

        }, (err) => {
             this.reportFilterhandler();
        }).then(() => {
             this.setState({listing_loading: false});
        });
    }

    downloadReportHandler                  =   (detailed_report = false)  =>  {
        this.setState({ reportDownloading: true});
        let submittedFilterForm     =       {
            ...this.state.submittedForecastingFilterForm,
            date_from  :  moment(this.state.submittedForecastingFilterForm.date_from).format('YYYY-MM-DD'),
            date_to : moment(this.state.submittedForecastingFilterForm.date_to).format('YYYY-MM-DD'),
        }
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...submittedFilterForm, download : 'download',detailed_report : detailed_report} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }


    tapDateChange           =       (selectedOption)        =>  {
        
        if (selectedOption && selectedOption.length > 0) {
            this.setState({
                forecastingFilterForm: {
                    ...this.state.forecastingFilterForm,
                    date_from    :       selectedOption[0] ? selectedOption[0] : '',
                    date_to      :       selectedOption[1] ? selectedOption[1] : '',
                }
            });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    pageTitleBarJsx                         =   ()  =>  {

        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.downloadReportBtn ?
                        <Ax>
                            <button
                                disabled={this.state.reportDownloading || !this.state.download_access}
                                type="button" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-primary">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={() => this.downloadReportHandler(false)}>Consolidated Report</Link>
                                </li>
                                <li>
                                    <Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={() => this.downloadReportHandler(true)}>SCS Wise Spare Part Forecast Report</Link>
                                </li>
                            </ul>
                        </Ax>
                        : null}

                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }

    forecastingFilterFormJsx            =   ()  =>  {
      
        return (<div id="forecastingFilterFormJsx" className="bg-white collapse" aria-expanded="true">
            <form onSubmit={this.reportPerformanceFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.forecastingFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    forecastingFilterForm: {
                                        ...this.state.forecastingFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.forecastingFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.forecastingFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_asset_matrix_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.forecastingFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.forecastingFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.forecastingFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'forecastingFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.forecastingFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            menuPlacement="top"
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Select Period</label>
                    <div className="col-sm-6 add_calender_section">
                        <DatePicker
                            selectsRange={true}
                            monthsShown={2}
                            dateFormat="dd-MMM-yyyy"
                            className={'form-control'}
                            startDate={this.state.forecastingFilterForm.date_from}
                            endDate={this.state.forecastingFilterForm.date_to}
                            name="date"
                            minDate={new Date()}
                            onChange={(value, event) => {
                                this.tapDateChange(value)
                            }}
                            required={true}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="add_calender-icon" />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4" onClick={this.reportFilterhandler}>Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }

    forecastingDisplayJsx               =    ()  =>    {
        return (<div className="bg-white">
      
            { this.state.listing_loading ? <Loader /> : null }
           { !this.state.listing_loading && this.state.downloadReportBtn == true
                ? this.forecastingTableJsx() : null
            }
        </div>)
    }

    forecastingTableJsx                          =   ()  =>  {

        return (<Ax>
        <div className="table-responsive">
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th style={{ width: "5%" }} className='text-center'>S.No </th>
                        <th style={{ width: "15%" }}>Item Name</th>
                        <th style={{ width: "13%" }}>Item Code</th>
                        <th style={{ width: "13%" }}>Manufacturer</th>
                        <th style={{ width: "13%" }}>MPN</th>
                        <th style={{ width: "8%" }} className="text-end">Overdue Quantity</th>
                        <th style={{ width: "9%" }}>Due in Selected Period</th>  
                        <th style={{ width: "8%" }} className="text-end">Total Quantity</th>
                        <th style={{ width: "8%" }} className="text-end">Available in Warehouse</th>
                        <th style={{ width: "8%" }} className="text-end">Difference</th>
                        <th style={{ width: "8%" }} className="text-end">Interchangeable Items Available in Warehouse</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {
                            let difference =  data.stock_in_qty - data.total_qty;
                            return (
                                <tr key={index}>
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td>{data.item_name ? data.item_name : "-"}</td>
                                    <td>{data.item_code ? data.item_code : "-"}</td>
                                    <td>{data.item_manufacturer ? data.item_manufacturer : "-"}</td>
                                    <td>{data.item_mpn ? data.item_mpn : "-"}</td>
                                    <td className='text-end'>{data.over_due_qty ? data.over_due_qty : "0"}</td>
                                    <td className='text-end'>{data.due_soon_qty ? data.due_soon_qty : "0"}</td>
                                    <td className='text-end'>{data.total_qty ? data.total_qty : "0"}</td>
                                    <td className='text-end'>{data.stock_in_qty ? data.stock_in_qty : "0"}</td>
                                    <td className={difference < 0 ? "text-end text-danger" : "text-end"}>{difference}</td>
                                    <td className='text-end'>{data.interchangable_item_stock_in_qty ? data.interchangable_item_stock_in_qty : "0"}</td>
                                </tr>)
                        }))
                        : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                    }

                </tbody>
            </table>
            </div>
        </Ax>)
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Inventory Forecasting - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                    {this.forecastingFilterFormJsx()}
                        {this.state.formDataLoaded
                            ? (<Ax>
                               
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.forecastingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                       <div className='my-2'>
                       {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                       </div>
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_asset_variants      :   state.app && state.app.asset_variants ? state.app.asset_variants : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [], 
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []   
    };
};

export default connect(mapStateToProps)(InventoryForecastingReport);