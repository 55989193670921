import React from 'react';
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ModalHeader from "react-bootstrap/ModalHeader";
import {ModalBody, ModalFooter, ModalTitle} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import tapIcon from "../../../services/TapIcon";

export default class SiteAttributeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openForm                   :       false,
            input                      :        {
                name                   :        ""
            },
            type                       :         [],
            disable                    :         false,
            key                        :          "",
            attributeList              :          [],
            selectedAttribute          :          [],
            attributeType              :          "",
            options                     :            ['Option 1','Option 2'],

        }
        this.handleCloseForm       =        this.handleCloseForm.bind(this);
        this.editAttribute         =        this.editAttribute.bind(this);
        this.openEdit              =        this.openEdit.bind(this);
        this.handleFormChange      =        this.handleFormChange.bind(this)
        this.onAttributeChange      =            this.onAttributeChange.bind(this);
    }

    //function to get attribute options
    options = [
        {value: 'text', label: 'Text'},
        {value: 'numeric', label: ' Numeric'},
        {value: 'date', label: ' Date'},
        {value: 'dropdown', label: 'Dropdown'}
    ]
    //function to close form
    handleCloseForm(){
        this.setState({openForm:false})
        this.setState({
            input                      :        {
                name                   :        ""
            }
        })
    }

    deleteSite(id){

    }

    openEdit(id){
        let siteList = this.state.attributeList.find(function (group) {
            return group.id == id;
        });
        this.setState({
            type        :  {
                value     : siteList.type,
                label     : siteList.type
            }
        })
        this.setState({options : siteList.options})
        this.setState({
            input :   {
                name          :    siteList.name,
            },key :  siteList.key
        })
        this.setState({openForm:true})
    }

    editAttribute(e,id){
        e.preventDefault()
        const access_token = localStorage.getItem('access_token');
        if(this.props.openForm === true){
            let frmData;
            if(this.state.selectedAttribute.value == "dropdown"){
                frmData = {
                    category            :   "site",
                    name                :   this.state.input.name,
                    type                :   this.state.selectedAttribute.value,
                    options             :   this.state.options
                };
            }else{
                frmData = {
                    category            :   "site",
                    name                :   this.state.input.name,
                    type                :   this.state.selectedAttribute.value,

                };
            }

            axios({
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + access_token
                },
                data: {...frmData},
                url: process.env.REACT_APP_API_SERVER + '/isc/additional_attribute'
            }).then((response) => {
                toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
                this.props.closeForm();
                this.getAttribute();
                this.setState({
                    input                      :        {
                        name                   :        ""
                    },selectedAttribute  :       [],type:[]
                })
            }).catch((err) => {
                toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
            });
        }else{
            let frmData;
            if(this.state.type.value == "dropdown"){
                frmData = {
                    category            :   "site",
                    name                :   this.state.input.name,
                    type                :   this.state.type.value,
                    options             :   this.state.options,
                    key                 :   this.state.key
                };
            }else{
                frmData = {
                    category            :   "site",
                    name                :   this.state.input.name,
                    type                :   this.state.type.value,
                    key                 :   this.state.key
                };
            }
            axios({
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + access_token
                },
                data: {...frmData},
                url: process.env.REACT_APP_API_SERVER + '/isc/additional_attribute'
            }).then((response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_CENTER})
                this.setState({openForm : false});
                this.getAttribute();
                this.setState({
                    input                      :        {
                        name                   :        ""
                    },selectedAttribute  :       [],type:[]
                })
                }).catch((err) => {
                toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
            });
        }

    }

    handleFormChange(e){
        let input                     =           this.state.input
        input[e.target.name]          =           e.target.value
        this.setState({input})
    }

    //function to get attribute List


    //function to select attribute type
    onAttributeChange(value) {
        this.setState({selectedAttribute: value, type: value.value})
    }

    handleOptionValue(e, i) {
        let options = [...this.state.options]
        options[i] = e.target.value
        this.setState({
            options
        })
    }

    handleDeleteOption = i => e => {
        e.preventDefault()
        if (i > 0) {
            let options = [
                ...this.state.options.slice(0, i),
                ...this.state.options.slice(i + 1)
            ]
            this.setState({
                options
            })
        }
    }

    addOption = e => {
        e.preventDefault()
        let options = this.state.options.concat([''])
        this.setState({
            options
        })
    }

    //function to get attribute List
    getAttribute() {
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/isc/additional_attribute',
            params : {category:"site"}
        }).then((response) => {
            this.setState({attributeList: response.data.data})
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        });
    }

    deleteItem(id){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/isc/additional_attribute/' + id
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.getAttribute()
        }).catch((err) => {
            toast.error(err.response.data.msg, {position: toast.POSITION.TOP_CENTER})
        });
    }

    componentDidMount() {
          this.getAttribute()
    }


    render() {

        return (
            <section>
                <Modal
                    size="lg"
                    show={this.state.openForm === true || this.props.openForm === true}
                    onHide={() => {this.handleCloseForm();this.props.closeForm()}}
                    aria-labelledby="example-modal-sizes-title-lg"
                >

                    <ToastContainer/>
                    <ModalHeader>
                        <ModalTitle className="action-form-title">
                            {this.props.openForm === true ? "Add Additional Attribute (Sites)" : "Edit Additional Attribute (Sites)"}
                        </ModalTitle>
                    </ModalHeader>
                    <form className="form-section" onSubmit={this.editAttribute}>
                        <ModalBody>
                            <div className="mb-3 row">
                                <div className="col-3">
                                    <label htmlFor="name" className="form-label">Attribute Name </label>
                                </div>
                                <div className="col-9">
                                    <input
                                        name="name"
                                        type="text"
                                        value={this.state.input.name}
                                        className="form-control"
                                        placeholder="Enter Attribute Name"
                                        onChange={this.handleFormChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-3">
                                    <label htmlFor="name" className="form-label">Type </label>
                                </div>
                                <div className="col-9">
                                    {
                                        this.props.openForm === true ?
                                            <Select
                                                value={this.state.selectedAttribute}
                                                onChange={this.onAttributeChange}
                                                placeholder={"search Attribute"}
                                                options={this.options}
                                            /> :
                                            <Select
                                                value={this.state.type}
                                                isDisabled = {true}
                                            />
                                    }

                                </div>
                            </div>
                            {
                                this.state.type.value === "dropdown" && this.props.openForm === false ?
                                    <div>
                                        <div className=" row">
                                            <div className="col-3">

                                            </div>
                                            <div className="col-9 pb0">

                                                {this.state.options.map((value, index) => {
                                                    return<div className="row mb-3">
                                                        <div className="col-12">
                                                            <span key={index}>
                                                            <input
                                                                name={value}
                                                                type="text"
                                                                className="form-control disabled_item"
                                                                value={value}
                                                                disabled
                                                            />
                                                            </span>
                                                        </div>

                                                    </div>
                                                })}
                                            </div>

                                        </div>
                                    </div> : null
                            }
                            {
                                this.state.selectedAttribute.value === "dropdown" && this.props.openForm === true ?
                                    <div>
                                        <div className=" row">
                                            <div className="col-3">

                                            </div>
                                            <div className="col-9 pb0">
                                                {this.state.options.map((value, index) => {
                                                    return <div>
                                                        {
                                                            this.state.options.length > 2 ?
                                                                <div className="row mb-3">
                                                                    <div className="col-10">
                                                            <span key={index}>
                                                            <input
                                                                name={value}
                                                                type="text"
                                                                className="form-control"
                                                                value={value}
                                                                placeholder="Please Enter Options"
                                                                onChange={(e) => this.handleOptionValue(e, index)}
                                                            />

                                                    </span>
                                                                    </div>
                                                                    <div className="col-2 del-option">
                                                                        {this.state.options.length > 2 ?
                                                                            <span className="mt-1">
                                                                        <button className="btn btn-secondary"
                                                                                onClick={this.handleDeleteOption(index)}>X</button>
                                                                    </span>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row mb-3">
                                                                    <div className="col-12">
                                                            <span key={index}>
                                                            <input
                                                                name={value}
                                                                type="text"
                                                                className="form-control"
                                                                value={value}
                                                                placeholder="Please Enter Options"
                                                                onChange={(e) => this.handleOptionValue(e, index)}
                                                            />

                                                    </span>
                                                                    </div>

                                                                </div>
                                                        }
                                                    </div>
                                                })}
                                            </div>

                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-3">

                                            </div>
                                            <div className="col-9">
                                                <span className="option_add_link" onClick={this.addOption}>Add New Option</span>
                                            </div>

                                        </div>
                                    </div> : null
                            }


                        </ModalBody>
                        <ModalFooter>
                            <div className="action-form-footer">
                                <button type = "button" className="btn btn-secondary" onClick={() => {this.handleCloseForm();this.props.closeForm()}} >Cancel
                                </button>
                                <button type="submit" className="btn btn-primary modal_add_button">Save
                                </button>
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
                <div className="bg-white" style={{height:"81vh"}}>
                    <table className="table table-hover table-bordered bg-white ">
                        <thead>
                        <tr>
                            <th scope="col" className="table-head-row" style={{width: "10%"}}>Sr.No</th>
                            <th scope="col" className="table-head-row">Name</th>
                            <th scope="col" className="table-head-row">Type</th>
                            <th scope="col" className="table-head-row" style={{width: "35%"}}>Notes</th>
                            <th scope="col" className="table-head-row text-center" style={{width: "10%"}}>Action</th>
                        </tr>
                        </thead>
                        <tbody className="table-body">
                        {
                            this.state.attributeList.map((item, index) => {
                                return <tr key={item.id}>
                                    <td scope="row">{index + 1}</td>
                                    <td scope="row">{item.name}</td>
                                    <td scope="row">{item.type}</td>
                                    <td scope="row">{item.options !== null && item.options.length > 1 ?
                                        <div>
                                            {item.options.map((itemNew, index) => {
                                                return (
                                                    <span>{itemNew} {index < item.options.length - 1 ? ", " : ""}</span>
                                                );
                                            })}
                                            {/*{item.options.map((itemNew,index) => itemNew)}*/}
                                        </div> : "-"}</td>
                                    <td scope="row" className="ml-2 text-center">
                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                           data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"/>
                                        <ul className="dropdown-menu">
                                            <li className="dropdown-item"
                                                onClick={(e) => this.openEdit(item.id)}>Edit</li>
                                            <li><a className="dropdown-item" onClick={() => this.deleteItem(item.id)}>Delete</a></li>
                                        </ul>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }
}