import React from 'react';
import {connect} from "react-redux";
import {toast} from "react-toastify";
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon"
import Loader from "../../components/ui/Loader/Loader";
import { Helmet } from 'react-helmet';
import { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import AppBaseComponent from '../../components/AppBaseComponent';

class AssetPermission extends AppBaseComponent {
  
    constructor(props) {
        super(props);
        
        this.initPermissionForm         =   {
            role_id                     :       props.roleData ? props.roleData.id : '',
            sections                    :       []
        }
        
        this.state = {
            listing_loading            :          false,
            allMobileVisibiltyList     :          [],
            addMobileVisibiltyForm     :        {...this.initPermissionForm}
        };

        this.roleMobileVisibilityUrl    =   ORG_API_BASE_URL_2  +   '/role/mobile_visibility';

   }

   componentDidMount() {
        this.initilaizeData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.roleData !== this.props.roleData) {
            this.initilaizeData(nextProps);
        }
    }


    initilaizeData = (pr) => {
        if (pr && pr.roleData) {
            this.getMobileVisibiltyList(pr.roleData.id)
            this.setState({
                addMobileVisibiltyForm  :   {
                    role_id             :   pr.roleData.id,
                    sections            :   []
                }
            })
        }
    }

    getMobileVisibiltyList       =       (id)        =>      {
        this.setState({listing_loading: true});
      
        HttpAPICall.withAthorization('GET',  this.roleMobileVisibilityUrl + '/' + id, this.props.access_token, {}, {}, (response) => {
            let respData = response.data;

            this.setState({
                allMobileVisibiltyList : respData && respData.length > 0 ?  respData.filter(dt => dt.sub_modules && dt.sub_modules.length > 0) : [],
            },() => {
                let visibilityList = this.state.allMobileVisibiltyList;
                let addMobileVisibiltyForm = this.state.addMobileVisibiltyForm;
                let sections = [];
                visibilityList.forEach(ml => {
                    ml.sub_modules.forEach(section => {
                        section.sections.forEach(st => {
                          if(st.view == 'Y'){
                             sections.push(st.key);
                          }
                        })
                    })
                })
                this.setState({
                    addMobileVisibiltyForm :{...this.state.addMobileVisibiltyForm,sections : sections}
                })
                
            })
       
        }).then(() => this.setState({listing_loading: false}));
    }

    handleCheckboxChange = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;
        if (isChecked) {
            this.setState({
                addMobileVisibiltyForm : {
                    ...this.state.addMobileVisibiltyForm,
                    sections : [...this.state.addMobileVisibiltyForm.sections, value]
                }
            })
          
        } else {
            this.setState({
                addMobileVisibiltyForm : {
                    ...this.state.addMobileVisibiltyForm,
                    sections : this.state.addMobileVisibiltyForm.sections.filter((v) => v !== value)
                }
            })
          
        }
      };

    saveMobileVisibiltyForm     =       (e)     =>      {
        e.preventDefault()
        this.setState({ saveFormSubmitting: true })
        HttpAPICall.withAthorization('PUT', this.roleMobileVisibilityUrl, this.props.access_token, {}, { ...this.state.addMobileVisibiltyForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
           this.setState({ addMobileVisibiltyForm     :        {...this.initPermissionForm}});
           this.getMobileVisibiltyList(this.props.roleData.id)
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    render() {
       let {allMobileVisibiltyList}   =   this.state;
        return (
            <Ax>
                <Helmet><title>Role - Mobile App Permission</title></Helmet>
                {this.state.listing_loading ? <div className='text-center'><Loader /></div>
                    : <form onSubmit={this.saveMobileVisibiltyForm}>
                        {allMobileVisibiltyList && allMobileVisibiltyList.length > 0
                            ? allMobileVisibiltyList.map((ap, key) => {

                                return (<Ax>
                                    <table className="table table-bordered table-hover bg-white my-2">
                                        <thead className="table-secondary">
                                            <tr key={key}>
                                                <th scope="col" style={{ width: "30%" }}>{ap.name}</th>
                                                <th style={{ width: "12%" }} className='text-center'>Visibility</th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                                <th style={{ width: "12%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ap.sub_modules && ap.sub_modules.length > 0
                                                ? ap.sub_modules.map((sm, index) => {
                                                    return (
                                                        sm.sections && sm.sections.length > 0 && sm.sections.map((section, index) => {
                                                            return (<tr>
                                                                <td>{section.name}</td>
                                                                <td className='text-center'>
                                                                    <input
                                                                        type='checkbox'
                                                                        value={section.key}
                                                                        disabled={sm.activity == 'N'}
                                                                        onChange={this.handleCheckboxChange}
                                                                        checked={this.state.addMobileVisibiltyForm.sections && this.state.addMobileVisibiltyForm.sections.length > 0 && this.state.addMobileVisibiltyForm.sections.includes(section.key) ? true : false} />
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>)
                                                        }))
                                                })

                                                : null}
                                        </tbody>
                                    </table>
                                    <div className="float-end">
                                        <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                            Save  {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                        </button>

                                    </div>
                                </Ax>
                                )
                            })
                            : <div className='text-center'>No Visibility Available</div>}

                        
                    </form>}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps)(AssetPermission);