import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import swal from "sweetalert";
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from '../../../services/TapIcon';
import ReactPaginate from "react-paginate";
import TapSelect from "../../../components/ui/TapSelect";
import Ax from "../../../components/hoc/Ax";
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria } from '../../../components/TapUi';
import { Helmet } from 'react-helmet';
import WarehouseAddress from './WarehouseAddress';

class Warehouse extends AppBaseComponent {
    
    constructor(props) {
        super(props);
     
        this.initLinkSiteForm                =       {
           all_sites                        :        false,
           site_ids                         :        []
        }

        this.initWarehouseSearchForm         =       {
            search_name                :       '',
            search_code                :       '',
            search_site_ids            :       [],
            search_state_id            :       []
        }

        this.warehouseSearchFormLabel     =       {
            search_name                :       'Warehouse Name : ',
            search_code                :       'Warehouse Code : ',
            search_site_ids            :       'Sites : ',
            search_state_id            :       'State (Source of Supply) : '
        }

       
        
        this.state                      =  {
            listing_tbl_page                :   1,
            listing_loading                 :   false,
            listingMeta                     :   null,
            listingData                     :   [],
            list_additional_attributes :   [],
            warehouseData                   :   null,
            totalListingCount               :   0,
            minimizeTable                   :   false,
            warehouseAddFormData            :   null,
            screenView                      :   1,
            iam_user_sites                  :   [],
            view_loading                    :   false,
            linkedSites                     :    [],
            linkSiteForm                    :   {...this.initLinkSiteForm},
            warehouseSearchForm             :       {...this.initWarehouseSearchForm},
            submittedWarehouseFilterForm    :       {...this.initWarehouseSearchForm},
            allCountryList                  :    [{value: 99, label: "India"}],
            allStateList                    :       [],
            allDistrictList                 :       [],
            allPincodeList                  :       [],
            editAddressDetails              :       false,
            search_form_loading             :    false,
            searchFormLoaded                :    false,
            allStateList                    :     [],
            formSearchedElems               :      null,
            saveLinkedSiteFormSubmitting    :     false,
            orgInfoData                     :     null,
            address_loading                 :     false,
            warehouseAddressList            :      [],
            totalAddressCount               :       0,
            warehouseListingMeta            :       null
        };
        this.addAddressRef                  =   React.createRef();

        this.warehouseLinkSite              =   INVENTORY_API_BASE_URL_2 + '/warehouse/link_site';
        this.warehouseUnLinkSite            =   INVENTORY_API_BASE_URL_2 + '/warehouse/unlink_site';
        this.deleteAddressUrl               =   INVENTORY_API_BASE_URL_2 + '/warehouse/shipping_address/' + 'shippingAddressId';

        this.stateListUrl       =   ORG_API_BASE_URL_2  +   '/state_list';
        this.districtListUrl    =   ORG_API_BASE_URL_2  +   '/district_list';
        this.pincodesUrl        =   ORG_API_BASE_URL_2  +   '/pincodes';
        this.warehouseUrl       =   ISC_API_BASE_URL_2  +   '/warehouse';

    }
    
    componentDidMount() {
        //On  Initialize Get Item List From Server :-
        this.initializeListData(this.props)
        this.searchFormDataInit()
        this.linkSitesModal           =   new Modal(document.getElementById('linkSitesModal'), {keyboard: false, backdrop :false});
        //Load Add Item Form Data :-
        this.warehouseAddFormDataInit();
        this.warehouseSearchModal             =   new Modal(document.getElementById('warehouseSearchModal'), {keyboard: false, backdrop :false});
        

        //****************After Add and update ***************************/
        if(this.props.location && this.props.location.state && this.props.location.state.warehouseId){
            this.viewWarehouseDetail(this.props.location.state.warehouseId)
         }
         if(this.props.location && this.props.location.state && this.props.location.state.warehouseData){
            this.viewWarehouseDetail(this.props.location.state.warehouseData.id)
       }
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initializeListData(nextProps)    
        }
    }

    initializeListData          =       (pr)        =>      {
        if(pr){
            this.setState({
                iam_user_sites          :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [], 
                orgInfoData             :   pr.orgInfoData
            },() => {
                let ParamObject                 =   new URLSearchParams(this.props.location.search);
            
                if(ParamObject.get('search_site_ids')) {
                    
                    let updateFilterForm        =   {search_site_ids : [ParamObject.get('search_site_ids')]};
                    
                    this.setState({warehouseSearchForm :   {...this.state.warehouseSearchForm, ...updateFilterForm},
                                    },() => this.submitWarehouseSearchForm());
                }else{
                    this.loadListingTblData(1);
                }
                        
            })
        }
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }


    
    linkSiteModalInit                 =   ()  =>  {
        this.linkSitesModal.show();
    }

    submitLinkSiteForm          =       (e)      =>      {
       e.preventDefault();
       let frmData ;
       if(this.state.linkSiteForm.all_sites == true){
           let site = this.state.iam_user_sites.filter(o  => !this.state.linkedSites.includes(o.value)).map(s => s.value);
           
            frmData = {
                site_ids      :   site,
                id            :   this.state.warehouseData.id
            }
       }else{
            frmData = {
                site_ids      :   this.state.linkSiteForm.site_ids,
                id            :   this.state.warehouseData.id
            }
       }
       this.setState({ saveLinkedSiteFormSubmitting: true })
        HttpAPICall.withAthorization('POST', this.warehouseLinkSite, this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkSitesModal.hide();
            this.setState({ linkSiteForm: { ...this.initLinkSiteForm } })
            this.viewWarehouseDetail(this.state.warehouseData.id)
            this.loadListingTblData(1)
        }).then(() => this.setState({ saveLinkedSiteFormSubmitting: false }));

    }

    deLinkSite              =           (id)        =>      {
        swal({
            title: "DeLink",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelink => {
                if (willDelink) {
                    let frmData = {
                        site_id       :   id,
                        id            :   this.state.warehouseData.id
                   }
                    HttpAPICall.withAthorization('PUT', this.warehouseUnLinkSite, this.props.access_token, {}, {...frmData}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.loadListingTblData(1)
                        this.viewWarehouseDetail(this.state.warehouseData.id)
                     }).then(() => {

                    });
                }
            });
    }

    searchFormDataInit         =       ()      =>      {
        this.setState({ search_form_loading: true })
        HttpAPICall.withAthorization('GET', this.stateListUrl, this.props.access_token)
            .then((response) => {
                this.setState({
                    searchFormLoaded        :    true,
                    allStateList            :   response.data.map((s) => { return { value: s.id, label: s.name }; }),
                    
                })
            }).then(() => {
                this.setState({ search_form_loading: false })
            });
    }

    onSelectingAllSites             =           (e,key)             =>      {
        let checkedSite = document.getElementById('all_sites').checked
        if(checkedSite == true) {
            this.setState({
                 linkSiteForm : {...this.state.linkSiteForm,
                   all_sites  :  true,
                   site_ids  :  []
            }})
        }else{
           this.setState({
               linkSiteForm : {...this.state.linkSiteForm,
                 all_sites  :  false,
                 site_ids  :   []
          }})
        }
   }
    
    warehouseAddFormDataInit            =   ()  =>  {
        const headers                         =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + this.props.access_token};
        Promise.all([
            axios({ method: 'GET', headers: headers, url: this.warehouseUrl + '/add_form_data'}), 
            axios({ method: 'GET', headers: headers, url: this.stateListUrl}), 
        ])
        .then((results) => {
            this.setState({
                warehouseAddFormData : {...this.state.warehouseAddFormData,  ...results[0].data.data, states : results[1].data }
            });
        });
    }
    
    loadListingTblData                  =   (page = 1)  =>  {
        let search                      =   {...this.state.submittedWarehouseFilterForm};
//        Object.keys(this.state.itemSearchForm).map((k) => {
//            search[k]                   =   this.state.itemSearchForm[k].value;
//        });
        this.setState({listing_loading: true, listing_tbl_page: page});
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
            params: {page : page, per_page : 50, ...search},
            url: this.warehouseUrl
        }).then((response) => {
            let data                =   response.data;
            this.setState((prevStates,prevProps) => ({
                list_additional_attributes      :   data.additional_attributes,
                listingData                     :   data.data,
                listingMeta                     :   data.meta,
                totalListingCount               :   data.meta.total
            }));
        }).catch((err) => {
            toast.error(err?.response?.data?.message ?? 'Unable to Load Warehouse.', {position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    searchWarehouseModalInit                =   ()      =>      {
        this.warehouseSearchModal.show()
    }

    submitWarehouseSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.warehouseSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.warehouseSearchFormLabel[key];
            let value                       =   this.state.warehouseSearchForm[key];
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
              
           if(label) {
                    let show_val            =   value;
                    if(key == 'search_name') {
                        show_val            =   this.state.warehouseSearchForm ? this.state.warehouseSearchForm.search_name : '';
                    }
                    if(key == 'search_code') {
                        show_val            =   this.state.warehouseSearchForm ? this.state.warehouseSearchForm.search_code : '';
                    }
                
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'search_state_id') {
                        show_val            =   this.state.allStateList.filter((s) => this.state.warehouseSearchForm.search_state_id == s.value).map(s => s.label);
                    }
                    
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedWarehouseFilterForm   :   {...this.state.warehouseSearchForm},
            formSearchedElems               :   searchedElems
        }, () => {
            this.warehouseSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    warehouseSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            warehouseSearchForm            :   {...this.initWarehouseSearchForm},
            submittedWarehouseFilterForm     :   {...this.initWarehouseSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.warehouseSearchModal.hide();
        });
    }

    warehouseClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            warehouseSearchForm            :   {...this.initWarehouseSearchForm},
            submittedWarehouseFilterForm     :   {...this.initWarehouseSearchForm}
        }, () => {
            this.warehouseSearchModal.hide();
        });
    }

    deleteWarehouse              =           (id)        =>      {
        swal({
            title: "DELETE",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.warehouseUrl + '/' + id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.loadListingTblData(1);
                        this.setState({minimizeTable : false})
                     }).then(() => {

                    });
                }
            });
    }

    viewWarehouseDetail                     =   (id)  =>  {
        this.setState({ minimizeTable           :    true})
        this.getWarehouseDetail(id);
        this.getWarehouseAddressList(id);
       
    }

    getWarehouseDetail      =       (id)        =>      {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + id, this.props.access_token)
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    warehouseData           :    respData.data,
                    linkedSites             :    respData.data.sites.map(s => s.id),
                  
                });
               
            }).then(() => {
            this.setState({view_loading: false});
        });
    }

    getWarehouseAddressList      =       (id,page=1)        =>      {
        this.setState({address_loading : true})
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/warehouse/shipping_address/' + id, this.props.access_token,{page : page,per_page:20})
            .then((response) => {
                let respData                =   response.data;
              
                this.setState({
                    warehouseAddressList           :    respData && respData.data ? respData.data :  [],
                    warehouseListingMeta            :   respData && respData.meta ? respData.meta : null,
                    totalAddressCount               :   respData && respData.meta && respData.meta.total  ? respData.meta.total : 0
                  
                });
               
            }).then(() => {
            this.setState({address_loading: false});
        });
    }

    addNewWarehouseScreenInit               =   ()  =>  {
        this.props.history.push('/warehouse/add')
    }
    
    updateItemScreenInit                    =   (event, id)  =>  {
        //Get Item Details :-
        let warehouseData                            =   this.state.warehouseData
        if(warehouseData) {
            this.props.history.push({pathname: `/warehouse/update`, state: {warehouseData : warehouseData }})
        }
    }

    addAddressInit             =      (data=null)        =>      {
        this.addAddressRef.current.addAddressModalInit(this.state?.warehouseData?.id,data);  
    }
    
    deleteWarehouseAddress              =           (id)        =>      {
        swal({
            title: "DELETE",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.deleteAddressUrl.replace('shippingAddressId',id), this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.getWarehouseAddressList(this.state.warehouseData.id)
                     }).then(() => {

                    });
                }
            });
    }
   

    warehouseListJsx                        =   ()  =>  {
        return (<Ax>
            <div className="page_title row m0">
                <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                    <h3>Warehouse List</h3>
                    <div className="text-end mt15">
                        {(!this.state.minimizeTable && this.state.warehouseAddFormData) ? <button type="button" onClick={this.addNewWarehouseScreenInit} className="btn btn-primary">Add Warehouse</button> : null}
                        <button  type="button" className="btn btn-secondary" onClick={this.searchWarehouseModalInit}>
                            <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="Search Warehouse" />
                        </button>
                        {this.state.minimizeTable  && this.state.warehouseAddFormData ?
                        <span className="dropdown">
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="More Warehouse" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                 <li><a className="dropdown-item" onClick={this.addNewWarehouseScreenInit}>Add Warehouse</a></li>
                            </ul>
                        </span>
                        : null}
                    </div>
                </div>
                {   this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Warehouse : {this.state?.warehouseData?.name?.slice(0,40)}{this.state?.warehouseData?.name?.length > 40 ? '...' : ''} </h3>
                            <div className="text-end mt15">
                                <button  onClick={(event) => this.updateItemScreenInit(event, this.state.warehouseData?.id)} type="button" className="btn btn-secondary">
                                   Edit 
                                </button>
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{cursor : "pointer"}}>
                                     
                                     <li><a className="dropdown-item" onClick={() => this.addAddressInit()}>Add Shipping Address</a></li>
                                     <li><a className="dropdown-item" onClick={() => this.deleteWarehouse(this.state.warehouseData?.id)}>Delete Warehouse</a></li>
                                </ul>
                                <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Warehouse" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
                }
            </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.groupListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.warehouseDetailJsx()}</div> : null }
                </div>
            </div>
        </Ax>);
    }
    
    groupListingTableJsx                    =   ()  =>  {
        return (
        <Ax>
         <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.warehouseSearchClear}  />
            <table className="table table-bordered table-fixed table-sm bg-white">
                <thead className="table-secondary">
                    { this.state.minimizeTable
                        ? (<tr>
                                <th scope="col" style={{width:"15%"}}>Warehouse</th>
                            </tr> )
                        :   ( <tr>
                                <th scope="col" style={{width:"5%"}} className='text-center'>#</th>
                                <th scope="col" style={{width:"20%"}}>Name</th>
                                <th scope="col" style={{width:"10%"}}>Code</th>
                                <th scope="col" style={{width:"20%"}}>Site Name(s)</th>
                                <th scope="col" style={{width:"20%"}}>Phone Number 1</th>
                                <th scope="col" style={{width:"20%"}}>Location</th>
                                <th scope="col" className="text-center" style={{width:"5%",zIndex:9999}}>Action</th>
                            </tr> )
                    }
                </thead>
                <tbody>
                    { this.state.listing_loading
                        ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                        : this.state.listingData.map((item, index) => this.listingTableTr(item, index)) }
                </tbody>
            </table>
            <div className="m0">
                <span>{this.state.listingMeta ? `Showing ${this.state.listingMeta.from||0} to ${this.state.listingMeta.to||0} of ${this.state.listingMeta.total} entries` : ``}</span>
                <span className="text-end">
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        pageCount={this.state.listingMeta?.last_page ?? 0}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={0}
                        onPageActive={this.state.listingMeta?.current_page ?? 1}
                        onPageChange={(e) => this.loadListingTblData(e.selected+1)}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination justify-content-end"
                        pageClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        pageLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        activeClassName="active"
                    />
                </span>
            </div>
        </Ax>
        );
    }
    
    listingTableTr                          =   (item, index)  =>  {
        return (
            <tr key={index}>
                {
                    this.state.minimizeTable
                        ? (
                            <Ax>
                                <td>
                                    <a role="button" onClick={() => this.viewWarehouseDetail(item.id)} className="link-primary">{item.name}</a>
                                    <br /><small>Site : {item?.first_site?.site_name} {item?.total_site > 1 ? <span>(+{item?.total_site - 1} more)</span> : null}</small>
                                </td>
                            </Ax>
                        )
                        : (
                            <Ax>
                                <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                <td><a role="button" onClick={() => this.viewWarehouseDetail(item.id)} className="link-primary">{item.name}</a></td>
                                <td>{item.code}</td>
                                <td>{item?.first_site?.site_name} {item?.total_site > 1 ? <span>(+{item?.total_site - 1} more)</span> : null}</td>
                                <td>{item.phone1 ?? '-'}</td>
                                <td>{item.district?.name} {item.state ? `(${item.state.name})` : ''}</td>
                                <td className="text-end">
                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" onClick={() => this.viewWarehouseDetail(item.id)} role="button">View</a></li>
                                    </ul>
                                </td>
                            </Ax>
                        )
                }

            </tr>
        );
    }
    
    warehouseDetailJsx                      =   ()  =>  {
        let warehouse                           =   this.state.warehouseData;
        return (<section className="item_view_container bg-white">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                        type="button" role="tab" aria-controls="details" aria-selected="true">Details
                    </button>

                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="sites-tab" data-bs-toggle="tab" data-bs-target="#sites"
                        type="button" role="tab" aria-controls="sites" aria-selected="false">Sites
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="address-tab" data-bs-toggle="tab" data-bs-target="#address"
                        type="button" role="tab" aria-controls="address" aria-selected="false">Shipping Address
                    </button>
                </li>

            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                    {this.state.view_loading ? <Loader />
                        : <div className="tab_content_header">
                            <div className="tab_content_wrapper"><span className="content_heading">Basic Details</span></div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td className="details-label" style={{ "width": "34%" }}>Warehouse Name</td>
                                        <td className="details-name" style={{ "width": "66%" }}>{warehouse?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Warehouse Code</td>
                                        <td className="details-name">{warehouse?.code}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Organization</td>
                                        <td className="details-name text-capitalize" >{warehouse && warehouse.organization ? warehouse.organization.name : this.state?.orgInfoData?.group_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">GSTIN</td>
                                        <td className="details-name">{warehouse?.gstin ?? '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper"><span className="content_heading">Address</span></div>
                            <table className="table table-hover table-borderless">
                                <tbody>
                                    <tr>
                                        <td className="details-label" style={{ "width": "34%" }}>Address 1</td>
                                        <td className="details-name " style={{ "width": "66%" }}>{warehouse?.address1 ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Address 2</td>
                                        <td className="details-name">{warehouse?.address2 ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Landmark</td>
                                        <td className="details-name">{warehouse?.landmark ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">City</td>
                                        <td className="details-name">{warehouse?.city ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Country</td>
                                        <td className="details-name">{warehouse?.country?.name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">State</td>
                                        <td className="details-name">{warehouse?.state?.name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">District</td>
                                        <td className="details-name">{warehouse?.district?.name ?? '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="details-label">Pin Code</td>
                                        <td className="details-name">{warehouse?.pincode ?? '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                this.state.list_additional_attributes && this.state.list_additional_attributes.length > 0
                                    ? (
                                        <Ax>
                                            <div className="tab_content_wrapper">
                                                <div>
                                                    <span className="content_heading">Additinal Attribute</span>
                                                </div>
                                            </div>
                                            <table className="table table-hover table-borderless">
                                                <tbody>
                                                    {this.state.list_additional_attributes.map((a, k) => (<tr key={k}>
                                                        <td className="details-label" style={{ "width": "34%" }}>{a.name}</td>
                                                        <td className="details-name" style={{ "width": "66%" }}>
                                                            {warehouse?.additional_attribute?.[a.key] ?? '-'}
                                                        </td>
                                                    </tr>))}
                                                </tbody>
                                            </table>
                                        </Ax>
                                    )
                                    : null
                            }
                        </div>}
                </div>
                <div className="tab-pane " id="sites" role="tabpanel" aria-labelledby="sites-tab">
                    {this.state.view_loading ? <Loader />
                        : <div className="tab_content_header">
                            <div className="tab_content_wrapper"><span className="content_heading">Sites</span>
                                <div className="float-end"><button className="btn btn-primary" onClick={() => this.linkSiteModalInit()}>Link Sites</button></div>
                            </div>
                            <table className="table table-hover table-bordered table-responsive bg-white my-3 ">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th style={{ "width": '10%' }}>S.No</th>
                                        <th className="text-start">Site Name (Code)</th>
                                        <th style={{ width: "10%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {warehouse.sites && warehouse.sites.length > 0 ?
                                        warehouse.sites.map((s, i) => {
                                            return (
                                                <tr className="text-center">
                                                    <td >{i + 1}</td>
                                                    <td className="text-start">{s.site_name} ({s.site_code ?? "-"})</td>
                                                    <td>
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }}>
                                                            <li>
                                                                <a role={"button"} href="#" className={warehouse.sites && warehouse.sites.length == 1 ? "disabled dropdown-item" : "dropdown-item"} onClick={() => this.deLinkSite(s.id)}>
                                                                    DeLink </a> </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr><td className='text-center' colSpan={3}>No Record</td></tr>}
                                </tbody>
                            </table>
                        </div>}
                </div>
                <div className="tab-pane" id="address" role="tabpanel" aria-labelledby="address-tab">
                    <div className="tab_content_header">
                        {this.state.address_loading ? <Loader />
                            : this.state.warehouseAddressList && this.state.warehouseAddressList.length > 0
                                ? this.state.warehouseAddressList.map((a, key) => {
                                    return (
                                        <div className="card my-2">
                                            <div className="card-header row align-items-center">
                                                <div className='col-sm-7'>{a.label ? a.label : "-"}</div>
                                                <div className=' col-sm-5 text-end'>
                                                    <button className='btn btn-primary' role="button" onClick={() => this.addAddressInit(a)} > <tapIcon.FontAwesomeIcon icon={tapIcon.faEdit} /></button>
                                                    <button className='btn btn-primary mx-2' role="button" onClick={() => this.deleteWarehouseAddress(a.id)} disabled={this.state?.warehouseAddressList?.length <= 1 ? true : false}> <tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt} /></button>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className='py-1'>Address : <b>{a.address1 ? a.address1 + ", " : ""}
                                                    {a.address2 ? a.address2 + ", " : ""}
                                                    {a.landmark ? a.landmark + ", " : ""}
                                                    {a.city ? a.city : ""}</b></div>
                                                <div className='py-1'>District, State & Pincode : <b>{a.district_name ? a.district_name + ", " : " "}
                                                    {a.state_name ? a.state_name : "  "}
                                                    {a.pincode ? " & " + a.pincode : ""}</b></div>
                                                <div className='py-1'>GSTIN : <b>{a.gstin ? a.gstin : " "}</b></div>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="card my-2">

                                    <div className="card-body">
                                        <div className='text-center'>No Address</div>
                                    </div>
                                </div>}
                        <DisplayListPagination
                            meta={this.state.warehouseListingMeta}
                            onPageChange={(e) => this.getWarehouseAddressList(this.state.warehouseData.id, e.selected + 1)}
                        />


                    </div>
                </div>
            </div>
        </section>);    
    }
    
    
    
    linkSitesModalJsx       =    ()       =>  {
        return (
            <div className="modal fade" id="linkSitesModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkSitesModalLabel">Link Sites</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {this.setState({ linkSiteForm   :   {...this.initLinkSiteForm}})}}></button>
                        </div>
                        <form onSubmit={this.submitLinkSiteForm} id="addModalForm">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label className="form-label">Link Sites</label>
                                    </div>
                                    <div className="col-sm-7">
                                        <TapSelect
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'linkSiteForm', 'site_ids')}
                                            options={this.state.iam_user_sites.filter(o  => !this.state.linkedSites.includes(o.value))}
                                            isSearchable={true}
                                            isClearable={true}
                                            required={this.state.linkSiteForm.all_sites == true ? false : true}
                                            isMulti={true}
                                            isDisabled={this.state.linkSiteForm.all_sites == true ? true : false}
                                            value={this.state.iam_user_sites.filter(s => this.state.linkSiteForm.site_ids.includes(s.value))}
                                            placeholder={this.state.linkSiteForm.all_sites == true ? "All Sites" : "Please Select Sites"}
                                        />
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="all_sites"
                                                type="checkbox"
                                                value={true}
                                                onChange={(e) => { this.onSelectingAllSites(e, 'all_sites') }}
                                                className="form-check-input"
                                                id="all_sites"
                                                checked={this.state.linkSiteForm.all_sites == true}
                                            />
                                            <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveLinkedSiteFormSubmitting} data-bs-dismiss="modal" onClick={() => {this.setState({ linkSiteForm   :   {...this.initLinkSiteForm}})}}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveLinkedSiteFormSubmitting} >Save {this.state.saveLinkedSiteFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    //***********************SEARCH warehouse JSX****************
    warehouseSearchModalJsx               =        ()             =>        {
       
       return (
           <div className="modal fade" id="warehouseSearchModal" tabIndex="-1">
               <div className="modal-dialog modal-lg">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h5 className="modal-title" id="searchAssetModalLabel">Warehouse Search</h5>
                           <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                       </div>
                       <form id="warehouseSearchForm" onSubmit={this.submitWarehouseSearchForm}>
                           <div className="modal-body">
                              {this.state.search_form_loading ? <Loader/> 
                               : <Ax>
                               <div className="row my-3 align-items-center">
                                   <label className="col-sm-3 col-form-label col-form-label-sm  ">Warehouse Name</label>
                                   <div className="col-sm-9">
                                       <input
                                           name="search_name"
                                           type="text"
                                           value={this.state.warehouseSearchForm.search_name}
                                           className="form-control"
                                           autoComplete="off"
                                           onChange={(e) => this.formInputHandler(e, "warehouseSearchForm")}
                                           placeholder="Please enter Warehouse Name"
                                           style={{ height: "30px", fontSize: "93%" }}
                                       />
                                   </div>
                               </div>
                               <div className="row my-3 align-items-center">
                                   <label className="col-sm-3 col-form-label col-form-label-sm  ">Warehouse Code</label>
                                   <div className="col-sm-9">
                                       <input
                                           name="search_code"
                                           type="text"
                                           value={this.state.warehouseSearchForm.search_code}
                                           className="form-control"
                                           autoComplete="off"
                                           onChange={(e) => this.formInputHandler(e, "warehouseSearchForm")}
                                           placeholder="Please enter Warehouse Code"
                                           style={{ height: "30px", fontSize: "93%" }}
                                       />
                                   </div>
                               </div>
                               <div className="row my-3 align-items-center">
                                   <label className="col-sm-3 col-form-label col-form-label-sm  ">Sites</label>
                                   <div className="col-sm-9 ">
                                       <TapSelect
                                           options={this.state.iam_user_sites}
                                           changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'warehouseSearchForm', 'search_site_ids')}
                                           isSearchable={true}
                                           isClearable={true}
                                           isMulti={true}
                                           value={this.state.iam_user_sites.filter(s => this.state.warehouseSearchForm.search_site_ids.includes(s.value))}
                                           placeholder="Select Site"
                                           containerHeight="30px"
                                           fontSize="93%"
                                       />
                                   </div>
                               </div>
                               <div className="row my-3 align-items-center">
                                   <label className="col-sm-3 col-form-label col-form-label-sm ">State (Source of Supply)</label>
                                   <div className="col-sm-4">
                                       <TapSelect
                                           options={this.state.allCountryList}
                                           value={this.state.allCountryList.find(s => s.value == 99)}
                                           placeholder="Country"
                                           containerHeight="30px"
                                           fontSize="93%"
                                       />
                                   </div>
                                   <div className="col-sm-5">
                                       <TapSelect
                                           options={this.state.allStateList}
                                           changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'warehouseSearchForm', 'search_state_id')}
                                           isSearchable={true}
                                           isClearable={true}
                                           value={this.state.allStateList.find(s => this.state.warehouseSearchForm.search_state_id == s.value)}
                                           placeholder="Select State"
                                           containerHeight="30px"
                                           fontSize="93%"
                                       />
                                   </div>
                               </div>
                               </Ax>}

                           </div>
                           <div className="modal-footer">
                               <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.warehouseClear}>Clear</button>
                               <button type="submit" className="btn btn-primary">Search</button>
                           </div>
                       </form>
                   </div>
               </div>
           </div>
       );
   }
  
    render              =   ()  =>  {
       return (
           <ApplicationLayout>
               <Helmet><title>Warehouse List</title></Helmet>
               {this.warehouseListJsx()}
               {this.linkSitesModalJsx()}
               {this.warehouseSearchModalJsx()}
               <WarehouseAddress ref={this.addAddressRef}
                   afterAddAddressSubmit={() => {
                       this.viewWarehouseDetail(this.state?.warehouseData?.id)
                   }}
               />
           </ApplicationLayout>
        )
    }
    
}

const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        iam_user_sites      :   state.app && state.app.user_sites ? state.app.user_sites : [],
        orgInfoData         :   state.app.group_info ? state.app.group_info : null
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Warehouse);