import React from 'react';
import AdditionalAttribute from '../AdditionalAttribute';

export default class EngagementTimeSetting extends React.Component {

 
    render() {
        return (
            <section className="item_view_container bg-white">
           
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active " id="additional-tab" data-bs-toggle="tab" data-bs-target="#additional"
                        type="button" role="tab" aria-controls="additional" aria-selected="true">Additional Attribute
                    </button>
                </li>
                
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane show active " id="additional" role="tabpanel" aria-labelledby="additional-tab">
                    <div className="tab_content_header">
                    <AdditionalAttribute category="engagement_time"/>
                       
                    </div>
                </div>
               
            </div>
           
        </section>
        );
    }
}