import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from '../../../services/TapIcon';
import { Helmet } from 'react-helmet';
import { HRM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import moment from 'moment';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import Status from '../../../components/ui/Status';
import { Link } from 'react-router-dom';
import EmployeeDocument from './EmployeeDocument';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import DateService from '../../../services/DateService';


class EmployeeEducationModal extends AppBaseComponent {

    constructor () {
        super();

        this.educationFormInit   =   {
            institute_name         :   '',
            degree_diploma         :   '',
            specialization         :   '',
            date_of_completion     :   '',
        }
        
        this.state  =   {
            emp_id              :   '',
            educationForm       :   {...this.educationFormInit},
            loading_form_data   :   false,
            saveFormSubmitting  :   false,
            employeeData        :   null,
            type                :   'Add',
            education_id        :   '',
        }

        this.emp_education_url =   HRM_API_BASE_URL_2 + '/employee_education';
        this.emp_education_detail_url =   HRM_API_BASE_URL_2 + '/employee_education/detail/:id';
    }

    componentDidMount () {
        this.educationModal  =   new Modal(document.getElementById('educationModal'), {keyboard: false, backdrop :false});
    }

    modalHandle = (type, id = null) => {
        this.educationModal.show();
        this.setState({
            emp_id : this.props.emp_id,
            loading_form_data : true,
            type
        });

        if(type === 'Edit') {
            HttpAPICall.withAthorization('GET', this.emp_education_detail_url.replace(':id', id), this.props.access_token, {}, {}, (response) => {
                const data = response.data;
                this.setState({
                    education_id : id,
                    educationForm : {
                        institute_name         :   data.institute_name ? data.institute_name : '',
                        degree_diploma         :   data.degree_diploma ? data.degree_diploma : '',
                        specialization         :   data.specialization ? data.specialization : '',
                        date_of_completion     :   data.date_of_completion ? data.date_of_completion : ''
                    }
                });
           }).then(() => this.setState({ loading_form_data: false }));
        }else {
            setTimeout(() => {
                this.setState({loading_form_data : false})
            }, 200);
        }

    }

    submitForm = (e) => {
        e.preventDefault();
        let  frmData = {...this.state.educationForm, employee_id : this.state.emp_id};
        this.setState({ saveFormSubmitting: true });

        if(this.state.type === 'Edit') {
            frmData = {...frmData, id : this.state.education_id};
            HttpAPICall.withAthorization('PUT', this.emp_education_url, this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.educationModal.hide();
                this.setState({educationForm : {...this.educationFormInit}})
                this.props?.listLoading();
           }).then(() => this.setState({ saveFormSubmitting: false }));
        }else {
            HttpAPICall.withAthorization('POST', this.emp_education_url, this.props.access_token, {}, { ...frmData }, (response) => {
                 toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                 this.educationModal.hide();
                 this.setState({educationForm : {...this.educationFormInit}})
                 this.props?.listLoading();
            }).then(() => this.setState({ saveFormSubmitting: false }));
        }

    }

    render () {
        return (
            <div className="modal fade" id="educationModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editEmployeeModalLabel">{this.state.type} Education Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {
                            this.state.loading_form_data 
                            ?
                            <Loader/>
                            :
                            <Ax>
                                <div className="modal-body">
                                    <form id="education-form" onSubmit={this.submitForm}>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-2">
                                                <label htmlFor="institute_name" className="form-label require">Institute Name</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <input
                                                    name="institute_name"
                                                    type="text"
                                                    value={this.state.educationForm.institute_name}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    required={true}
                                                    onChange={(e) => this.formInputHandler(e, "educationForm")}
                                                    placeholder="Please enter Institute Name"
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <label htmlFor="degree-diploma" className="form-label ">Degree/Diploma</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <input
                                                    name="degree_diploma"
                                                    type="text"
                                                    value={this.state.educationForm.degree_diploma}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "educationForm")}
                                                    placeholder="Please enter Degree/Diploma"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-2">
                                                <label htmlFor="specialization" className="form-label">Specialization</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <input
                                                    name="specialization"
                                                    type="text"
                                                    value={this.state.educationForm.specialization}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "educationForm")}
                                                    placeholder="Please enter Specialization"
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <label htmlFor="date_of_completion" className="form-label">Date of Completion</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <DatePicker
                                                    selected={
                                                        this.state.educationForm.date_of_completion
                                                            ? moment(this.state.educationForm.date_of_completion, 'YYYY-MM-DD').toDate()
                                                            : false
                                                    }
                                                    name="employee_education"
                                                    onChange={(value, event) => this.formDateHandler('date_of_completion', value, 'educationForm')}
                                                    dateFormat="dd-MMM-yyyy"
                                                    className={"form-control form-control-sm"}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    autoComplete="off"
                                                    scrollMonthYearDropdown
                                                    placeholderText={"Date of Completion"}
                                                />
                                                {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="From Date" className="add_calender-icon" /> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" form="education-form" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                        Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </Ax>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(EmployeeEducationModal);