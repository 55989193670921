import {connect} from "react-redux";
import {Modal} from "bootstrap";
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import Ax from "../../../../components/hoc/Ax";
import TapSelect from "../../../../components/ui/TapSelect";
import {toast} from "react-toastify";
import React from "react";
import swal from "sweetalert";
import Status from "../../../../components/ui/Status";
import {TwitterPicker, ChromePicker, SketchPicker} from 'react-color';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from "../../../../services/TapApiUrls";

class Workflow extends AppBaseComponent {

    constructor(props) {
        super(props);

          this.initRentalStatusForm            =           {
              name                             :           "",
              role_ids                         :           [],
              color                            :           '#2A974E',
              notes                            :           ''
          }
        this.state                              =         {
            listing_loading                     :          false,
            status_listing                      :          [],
            editFormLoading                     :          false,
            editFormSubmiting                   :          false,
            allRoles                            :          [],
            rentalStatusForm                    :          {...this.initRentalStatusForm},
            allColorCodes                       :          [],
            formDataLoaded                      :          false,
            displayColorPicker                  :          false,
            editStatus                          :          false
        };
    }

    componentDidMount() {
        this.fetchWorkflowListing()
        this.editRentalStatusModal              =        new Modal(document.getElementById('editRentalStatusModal'), {keyboard: false, backdrop :false});
    }

    fetchFormData       =      ()   =>     {
        this.setState({editFormLoading : true})
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/deal_status/form_data', this.props.access_token , {}, {}, (resp) => {
            let respData                =   resp.data.data;
            this.setState({
                formDataLoaded      :        true,
                allRoles            :        respData.roles && respData.roles.length > 0 ? respData.roles.map(u => {   return { value: u.id, label: u.role}; }) : [],
                allColorCodes       :        respData.colors && respData.colors.length > 0 ? respData.colors : []
           })
        }).then(() => this.setState({editFormLoading: false}));
    }

    fetchWorkflowListing        =       ()      =>      {
        this.setState({ listing_loading: true })
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/deal_status/list', this.props.access_token, {}, {}, (resp) => {
           this.setState({status_listing : resp.data.data})
        }).then(() => this.setState({ listing_loading: false }));
    }


    editRentalStatisModalInit        =      (status = null)       => {
        this.editRentalStatusModal.show();
        if(!this.state.formDataLoaded){
            this.fetchFormData()
        }
        if(status){
            this.setState({
                rentalStatusForm        :       {
                    ...this.initRentalStatusForm,
                    sequence    :       status.sequence,
                    name        :       status.name,
                    color       :       status.color,
                    role_ids    :       status.roles && status.roles.length > 0 ?  status.roles.map(r => {return(r.id)}) : [],
                    notes       :       status.notes,
                    id          :       status.id,
                   
                }, editStatus  :       true
            })
        }else{
            this.setState({
                editStatus              :       false, 
                rentalStatusForm        :       {  ...this.initRentalStatusForm}
            })
        }
     
    }

    handleColorChange       =       (colorHex)     =>      {
        if(colorHex){
            this.setState({rentalStatusForm : {...this.state.rentalStatusForm, color : colorHex}})
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    
    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    submitRentalStatusForm          =       (e)     =>  {
        e.preventDefault()
        let frmData = {...this.state.rentalStatusForm}
        if(this.state.editStatus){
            this.setState({ editFormSubmiting: true })
            HttpAPICall.withAthorization('PUT', INVENTORY_API_BASE_URL_2 + '/deal_status/update', this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.editRentalStatusModal.hide()
                this.setState({ rentalStatusForm: { ...this.initRentalStatusForm } })
                this.fetchWorkflowListing()
            }).then(() => this.setState({ editFormSubmiting: false }))
        }else{
            this.setState({ editFormSubmiting: true })
            HttpAPICall.withAthorization('POST', INVENTORY_API_BASE_URL_2 + '/deal_status/add', this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.editRentalStatusModal.hide()
                this.setState({ rentalStatusForm: { ...this.initRentalStatusForm } })
                this.fetchWorkflowListing()
            }).then(() => this.setState({ editFormSubmiting: false }))
        }
        
    }

    deleteRentalStatus                    =   (id) => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', INVENTORY_API_BASE_URL_2 + '/deal_status/'+ id, this.props.access_token, {}, {}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.fetchWorkflowListing()
                });
            }
        });
    }

    rentalStatusFormModalJsx      =         ()      =>   {
         const popover = {
            position: 'absolute',
            zIndex: '2',
          }
          const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          }
    
        return (<div className="modal fade" id="editRentalStatusModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addAssetModalLabel">{this.state.editStatus ? "Edit" : "Add"} Deal Status</h5>
                    </div>
                    <form onSubmit={this.submitRentalStatusForm}>
                        {this.state.editFormLoading
                            ? <Loader />
                            : (<Ax>
                                <div className="modal-body">
                                <div className="row mb-2 align-items-center">
                                        <div className="col-sm-2 ">
                                            <label className="col-form-label require">Sequence</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="number"
                                                onChange={(e) => this.formInputHandler(e, "rentalStatusForm")}
                                                className="form-control form-control-sm"
                                                name="sequence"
                                                autoComplete="off"
                                                required='true'
                                                value={this.state.rentalStatusForm.sequence}
                                                placeholder="Enter Status Sequence" />
                                        </div>
                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className="col-sm-2 ">
                                            <label className="col-form-label require">Status Name</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="text"
                                                onChange={(e) => this.formInputHandler(e, "rentalStatusForm")}
                                                className="form-control form-control-sm"
                                                name="name"
                                                autoComplete="off"
                                                required='true'
                                                value={this.state.rentalStatusForm.name}
                                                placeholder="Enter Status Name" />
                                        </div>
                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className="col-sm-2 ">
                                            <label className="col-form-label require">Color Code</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="input-group input-group-sm">
                                                <span className="input-group-text px-4 me-3"
                                                    onClick={this.handleClick}
                                                    style={{ background: this.state.rentalStatusForm.color }}></span>
                                                <input
                                                    name="color"
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    value={this.state.rentalStatusForm.color}
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "rentalStatusForm")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <button type="button" onClick={this.handleClick} className="btn btn-link text-sm">Pick Color</button>
                                            {this.state.displayColorPicker ? <div style={popover}>
                                                <div style={cover} onClick={this.handleClose} />
                                                <SketchPicker
                                                    color={this.state.rentalStatusForm.color}
                                                    presetColors={this.state.allColorCodes}
                                                    onChangeComplete={(color) => this.handleColorChange(color.hex)} />
                                            </div> : null}
                                        </div>

                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className="col-sm-2 ">
                                            <label className="col-form-label require">Roles</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <TapSelect
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                placeholder="Select Roles"
                                                options={this.state.allRoles}
                                                value={this.state.allRoles.filter(su => this.state.rentalStatusForm.role_ids.includes(su.value))}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'rentalStatusForm', 'role_ids')}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="col-sm-4 form-text">
                                            Only selected roles can change the status of Deals to this status
                                        </div>
                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className="col-sm-2 ">
                                            <label className="col-form-label">Notes</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <textarea
                                                name="notes"
                                                value={this.state.rentalStatusForm.notes}
                                                onChange={(e) => this.formInputHandler(e, 'rentalStatusForm')}
                                                className="form-control"
                                                style={{ height: "40px" }}

                                            />
                                        </div>

                                    </div>
                                </div>
                            </Ax>)}
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.editFormSubmiting} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" disabled={this.state.editFormSubmiting} className="btn btn-primary">Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>)
    }

    


    render                                  =   ()  =>  {
        return (<Ax>
            <div className="tab_content_wrapper mt-2 mb-2"><span className="content_heading">Status for Deals</span>
                <div className="float-end"><button className="btn btn-primary" disabled={!this.props.permissions.includes('deal_manage_status')} onClick={() => this.editRentalStatisModalInit()}>Add Status</button></div>
            </div>
            <table className="table table-hover table-bordered my-3">
                <thead>
                    <tr className="table-secondary">
                        <th scope="col" style={{ width: "16%" }}>Status</th>
                        <th scope="col" style={{ width: "10%" }}>Sequence</th>
                        <th scope="col" style={{ width: "10%" }} className="text-center" >Color Code</th>
                        <th scope="col">Roles</th>
                        <th scope="col" style={{ width: "20%" }} >Notes</th>
                        <th scope="col" style={{ width: "10%" }} className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="5"><Loader /></td></tr>)
                        : (this.state.status_listing.length > 0
                            ? (this.state.status_listing.map((status, k) => {
                                return (<tr key={k}>
                                    <td className="text-capitalize">{status.name ? status.name : "-"}</td>
                                    <td className="text-center">{status.sequence ? status.sequence : "-"}</td>
                                    <td className="text-center"><Status color={status.color}></Status></td>
                                    <td>{status.roles && status.roles.length > 0 ? status.roles.map(roles => {return(roles.role)}).join(', ')  : "-"}</td>
                                    <td>{status.notes ? status.notes : "-"}</td>
                                    <td className='text-center'>  
                                     <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#"
                                        role="button" aria-expanded="false" />
                                        <ul className="dropdown-menu" style={{ cursor: "pointer" }} >
                                            <li><a className={[status.can_edit == false || (!this.props.permissions.includes('deal_manage_status')) ? "disabled dropdown-item" : "dropdown-item"].join(', ')} role="button"  onClick={() => this.editRentalStatisModalInit(status)}>Edit</a></li>
                                            <li><a className={[status.can_delete == false || (!this.props.permissions.includes('deal_manage_status')) ? "disabled dropdown-item" : "dropdown-item"].join(', ')} role="button"  onClick={() => this.deleteRentalStatus(status.id)}>Delete</a></li>
                                        </ul></td>
                                </tr>);
                            }))
                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            {this.rentalStatusFormModalJsx()}
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(Workflow);