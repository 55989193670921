import React from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from "../../../components/ui/Loader/Loader";
import { PeriodSelectionWithCustom, DisplayListPagination } from "../../../components/TapUi/index";
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from "../../../services/DownloadFile";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Modal } from 'bootstrap';
import { Helmet } from 'react-helmet';
import Status from '../../../components/ui/Status';
import DateService from '../../../services/DateService';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class SiteComparisonReport extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.FilterFormInitialState = {
            search_site_id1     :       '',
            search_site_id2     :       '',
            period              :       '',
            date_range_start    :       '',
            date_range_end      :       '',
        };

        // this.reportUrl = process.env.REACT_APP_IAM_API_SERVER + '/report/multiple-site-data';
        this.reportUrl      =   IAM_API_BASE_URL_2 + '/report/multiple-site-data';

        this.state = {
            showReport              :   false,
            minimizeTable           :   false,
            form_loading            :   false,
            listing_loading         :   false,
            disableDownloadLdger    :   false,
            reportDownloading       :   false,
            submitLoading           :   false,

            site1_name              :   '',
            site2_name              :   '',
            siteFilterForm          :    { ...this.siteFilterFormInitialState },
            iam_user_sites          :   [],
            siteListingData         :   [],
            reportData                          :   null,                  
        };


    }

    componentDidMount() {
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props);
        }, 1000)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }

        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }

    initilaizeAppDataToFilterForm = (props) => {

        const report_name   =   'site_comparison_report';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        
        let all_periods          =          [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            formDataLoaded          :   true,
            all_periods             :   all_periods,
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
        })
    }

    closeReport = () => {
        this.props.history.goBack()
    }

    pageTitleBarJsx = () => {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCogs} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.showReport ?
                        <Ax>
                            <button onClick={this.reportDownloadHandler} disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" className="btn btn-secondary">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} className="img-fluid" alt="item-action" /> {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>

                        </Ax> : null}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }

    reportFilterFormSubmit = (e = null) => {
        e && e.preventDefault();
        this.setState({ submitLoading : true });
        const site1             =   this.state.iam_user_sites.filter(s => this.state.siteFilterForm.search_site_id1 ===  s.value);
        const site2             =   this.state.iam_user_sites.filter(s => this.state.siteFilterForm.search_site_id2 ===  s.value);

        this.setState({
            site1_name          :   site1.length > 0    ?   site1[0].label  :   '',
            site2_name          :   site2.length > 0    ?   site2[0].label  :   '',
            showReport          :   true, 
            listing_loading     :   true, 
            disableDownloadLdger:   true 
        });

        let formData = {
            site_ids: [this.state.siteFilterForm.search_site_id1, this.state.siteFilterForm.search_site_id2],
            period: [this.state.siteFilterForm.date_range, this.state.siteFilterForm.date_range],
            // need_keys: ["reading", "expense", "fuel", "productivity", "scs", "ticket", "downtime"],
            action: "data"
        };
        HttpAPICall.withAthorization('GET',this.reportUrl , this.props.access_token, formData, formData, (resp) => {
            this.setState({
                siteListingData:resp.data.data,
            })
        }).then(() => this.setState({ listing_loading: false, submitLoading : false }));

    }

    reportDownloadHandler = () => {
        this.setState({ reportDownloading: true });
        let params = {
            site_ids: [this.state.siteFilterForm.search_site_id1, this.state.siteFilterForm.search_site_id2],
            period: [this.state.siteFilterForm.date_range, this.state.siteFilterForm.date_range],
            // need_keys: ["reading", "expense", "fuel", "productivity", "scs", "ticket", "downtime"],
            action: "download"
        };
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, { ...params }, {}, (response) => download.file(response.data.file_path)).then(() => this.setState({ reportDownloading: false }));
    }

    changePer			=	(current = 0, pervious = 0, type = null)	=>	{

        const includeVal=[0, undefined, null, false, '']; 

		if(current == pervious || includeVal.includes(current) || includeVal.includes(pervious)) {
			return '';	
		}

		let value	=	pervious !== 0 ? (((current - pervious)/pervious) * 100).toFixed(2) : 0;
		return value >= 0 ? value + "%"  : -(value) + "%";
	}

	statusJsx			=	(period1, period2, type = null)	=> 	{

        const includeVal=[0, undefined, null, false, '']; 

		if(period1 === period2 || includeVal.includes(period1) || includeVal.includes(period2)) {
			return "";
		}else{
			return (
				type === 'increase' 
				? 
				<Status color={parseInt(period1) > parseInt(period2) ? "#ec4e4e" : "#2A974E"}></Status> 
				:
				<Status color={parseInt(period1) < parseInt(period2) ? "#ec4e4e" : "#2A974E"}></Status>
			)
		}
	}

    siteCardFormJsx = () => {

        return (<Ax>
            <div className="bg-white px-2">
                {this.state.form_loading
                    ? <Loader />
                    : <form onSubmit={this.reportFilterFormSubmit} className={['py-2', 'filter_form'].join(' ')}>
                        <div className="row align-items-center">
                            <label className="col-2 require">Date Range</label>
                            <div className="col-sm-5 align-items-left">
                                <PeriodSelectionWithCustom
                                    required={true}
                                    menuPlacement={"bottom"}
                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                    value={this.state.siteFilterForm.date_range}
                                    startDate={this.state.siteFilterForm.date_range_start}
                                    endDate={this.state.siteFilterForm.date_range_end}
                                    onSelectPeriod={(period, startDate, endDate) => {
                                        this.setState({
                                            siteFilterForm: { ...this.state.siteFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate }
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-sm-2">
                                <TapSelect
                                    options={this.state.iam_user_sites}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'siteFilterForm', 'search_site_id1');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.iam_user_sites.filter(s => this.state.siteFilterForm.search_site_id1 === s.value)}
                                    placeholder="Select Sites"
                                />
                            </div>
                            <div className="col-sm-2">
                                <TapSelect
                                    options={this.state.iam_user_sites}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'siteFilterForm', 'search_site_id2');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.iam_user_sites.filter(s => this.state.siteFilterForm.search_site_id2 ===  s.value)}
                                    placeholder="Select Sites"
                                />
                            </div>

                            <div className="col-1 text-end">
                                <button type="submit" className="btn btn-primary px-3" disabled={this.state.submitLoading}>Show</button>
                            </div>
                        </div>
                    </form>
                }

            </div>
            <div className="bg-white">
                {this.state.listing_loading ? <Loader /> : null}
                {!this.state.listing_loading && this.state.siteListingData && this.state.siteListingData.length > 0
                    && this.reportTableJsx()}
            </div>
        </Ax>)
    }


    reportTableJsx = () => {
        let siteListingData     =   this.state.siteListingData && this.state.siteListingData.length > 0 ? this.state.siteListingData : [];
        let siteData1           =   siteListingData && siteListingData.length > 0 ? siteListingData[0].data : null;
        let siteData2           =   siteListingData && siteListingData.length > 0 ? siteListingData[1].data : null;
        let expense_data1       =   siteData1 &&  siteData1.expense  ? siteData1.expense : null;
        let expense_data2       =   siteData2 && siteData2.expense ? siteData2.expense : null;
        
        const prod_data1	    =	siteData1 && siteData1.site_productivity && siteData1.site_productivity.site_producutvity_data.length > 0 ? siteData1.site_productivity.site_producutvity_data : [];

	    const prod_data2	    =	siteData2 && siteData2.site_productivity && siteData2.site_productivity.site_producutvity_data.length > 0 ? siteData2.site_productivity.site_producutvity_data : [];

        const production1	    =	siteData1 && siteData1.site_productivity  ? siteData1.site_productivity : null;

	    const production2	    =	siteData2 && siteData2.site_productivity ? siteData2.site_productivity : null;

        let allProds 		    =	[...prod_data1,...prod_data2];
        let production_keys	    = 	new Set(allProds.map(p => p.key));

        let production_keys_withunits	=   [...production_keys].map(pk => allProds.find(p => p.key == pk));

        const asset_prod_data1  =	siteData1 && siteData1.asset_productivity && siteData1.asset_productivity.asset_producutvity_data.length > 0 ? siteData1.asset_productivity.asset_producutvity_data : [];

	    const asset_prod_data2  =	siteData2 && siteData2.asset_productivity && siteData2.asset_productivity.asset_producutvity_data.length > 0 ? siteData2.asset_productivity.asset_producutvity_data : [];

        const asset_production1	=	siteData1 && siteData1.asset_productivity  ? siteData1.asset_productivity : null;

	    const asset_production2	=	siteData2 && siteData2.asset_productivity ? siteData2.asset_productivity : null;

        let assetAllProds 		    =	[...asset_prod_data1,...asset_prod_data2];
        let asset_production_keys	= 	new Set(assetAllProds.map(p => p.key));

        let asset_production_keys_withunits	=   [...asset_production_keys].map(pk => assetAllProds.find(p => p.key == pk));


        let downtime1	       	=   siteData1 && siteData1.downtime  ? siteData1.downtime : null;
	    let downtime2	        =	siteData2 && siteData2.downtime ? siteData2.downtime : null;
		let downtimeTypes       =	downtime1 ? downtime1 : downtime2;



        const ticket1	        =	siteData1 && siteData1.ticket  ? siteData1.ticket : null;
	    const ticket2	        =	siteData2 && siteData2.ticket ? siteData2.ticket : null;
        const scs1	            =	siteData1 && siteData1.scs  ? siteData1.scs : null;
	    const scs2	            =	siteData2 && siteData2.scs ? siteData2.scs : null;

        const task1	            =	siteData1 && siteData1.task && siteData1.task.length > 0  ? siteData1.task : [];
	    const task2             =	siteData2 && siteData2.task && siteData2.task.length > 0  ? siteData2.task : [];

        let task_submodule_available    =   this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") ? 'Y' : 'N';

        const totalInProgressCount1     =  task1 && task1.length > 0 ? task1.reduce((total, task) => (task.status === 'In-Progress' ? total + task.count : total), 0) : 0;
        const totalCompleteCount1 =   task1 && task1.length > 0 ? task1.reduce((total, task) => (task.status === 'Complete' ? total + task.count : total), 0) : 0;

        // Calculate total counts for siteData2
        const totalInProgressCount2 = task2 && task2.length > 0 ? task2.reduce((total, task) => (task.status === 'In-Progress' ? total + task.count : total), 0) : 0;
        const totalCompleteCount2 = task2 && task2.length > 0 ? task2.reduce((total, task) => (task.status === 'Complete' ? total + task.count : total), 0) : 0;
          

        return (<div>
            <table className="table table-bordered table-sm align-middle bg-white my-2">
                <thead className="align-middle table-secondary" style={{ zIndex: '0' }}>
                    <tr>
                        <th style={{ width: "27%" }}>Parameter</th>
                        <th style={{ width: "13%" }}>Units</th>
                        <th style={{ width: "25%" }}>{this.state.site1_name}</th>
                        <th style={{ width: "25%" }}>{this.state.site2_name}</th>
                        <th style={{ width: "10%" }}>% Difference</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.listing_loading 
                        ? 
                        <tr><td colSpan={5} className="text-center"><Loader /></td></tr>
                        : <Ax>
                            <tr><td colSpan={5}><b>Expense</b></td></tr>
                            <tr>
                                <td>Fuel Expense</td>
                                <td className='text-center'>INR</td>
                                <th className='text-center'>
                                    {
                                        expense_data1 && expense_data1.fuel_expense 
                                        ? expense_data1.fuel_expense 
                                        : ''
                                    }
                                </th>
                                <th className='text-center'>
                                    {
                                        expense_data2 && expense_data2.fuel_expense 
                                        ? expense_data2.fuel_expense 
                                        : ''
                                    }
                                </th>
                                <td className='text-center'>
                                    {
                                        this.statusJsx(expense_data1?.fuel_expense, expense_data2?.fuel_expense, 'decrease')
                                    }
                                    {
                                        this.changePer(Number(expense_data2?.fuel_expense), Number(expense_data1?.fuel_expense))
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Asset Expense</td>
                                <td className='text-center'>INR</td>
                                <th className='text-center'>
                                    {
                                        expense_data1 && expense_data1.asset_expense ? expense_data1.asset_expense : ''
                                    }
                                </th>
                                <th className='text-center'>
                                    {
                                        expense_data2 && expense_data2.asset_expense ? expense_data2.asset_expense : ''
                                    }
                                </th>
                                <td className='text-center'>
                                    {
                                        this.statusJsx(expense_data1?.asset_expense, expense_data2?.asset_expense, 'decrease')
                                    }
                                    {this.changePer(expense_data2?.asset_expense, expense_data1?.asset_expense)}
                                </td>
                            </tr>
                            <tr>
                                <td>Item Expense</td>
                                <td className='text-center'>INR</td>
                                <th className='text-center'>{expense_data1 && expense_data1 ? expense_data1.item_expense : ''}</th>
                                <th className='text-center'>
                                    {
                                        expense_data2 && expense_data2.item_expense ? expense_data2.item_expense : ''}
                                </th>
                                <td className='text-center'>
                                    {
                                        this.statusJsx(expense_data1?.item_expense, expense_data2?.item_expense, 'decrease')
                                    }
                                    {this.changePer(expense_data2?.item_expense, expense_data1?.item_expense)}
                                </td>
                            </tr>
                            <tr>
                                <td>Rental Expense</td>
                                <td className='text-center'>INR</td>
                                <th className='text-center'>
                                    {expense_data1 && expense_data1.rental_expense ? expense_data1.rental_expense : ''}
                                </th>
                                <th className='text-center'>    
                                    {expense_data2 && expense_data2.rental_expense ? expense_data2.rental_expense : ''}
                                </th>
                                <td className='text-center'>    
                                    {this.statusJsx(expense_data1?.rental_expense, expense_data2?.rental_expense, 'decrease')}
                                    {this.changePer(expense_data2?.rental_expense, expense_data1?.rental_expense)}
                                </td>
                            </tr>
                            <tr>
                                <td>Total Expense</td>
                                <td className='text-center'>INR</td>
                                <th className='text-center'>
                                    {   
                                        expense_data1 && expense_data1.total_expense ? expense_data1.total_expense : ''
                                    }
                                </th>
                                <th className='text-center'>
                                    {
                                        expense_data2 && expense_data2.total_expense ? expense_data2.total_expense : ''
                                    }
                                </th>
                                <td className='text-center'>
                                    {
                                        this.statusJsx(expense_data1?.total_expense, expense_data2?.total_expense, 'decrease')
                                    }
                                    {this.changePer(expense_data2?.total_expense, expense_data1?.total_expense)}
                                </td>
                            </tr>

                            {
                                task_submodule_available == 'Y' && 
                                <Ax>
                                    <tr>
                                        <td colSpan={5}>&nbsp;</td>
                                    </tr>
                                    <tr><td colSpan={5}><b>Task</b></td></tr>
                                    {task1 && task1.length > 0 && task1.map((td, key) => {
                                        return (<tr key={key}>
                                            <td>{td.status}</td>
                                            <td></td>
                                            <th className='text-center'>{td.count}</th>
                                            <th className='text-center'>{task2 && task2.length > 0 ? task2[key].count : ""}</th>
                                            <td className='text-center'>{this.statusJsx(td.count, task2[key]?.count, 'increase')}
                                                {this.changePer(task2[key]?.count, td.count)}
                                            </td>
                                        </tr>)
                                    })}
                                    <tr>
                                            <td>Total</td>
                                            <td></td>
                                            <th className='text-center'>{totalInProgressCount1 + totalCompleteCount1 }</th>
                                            <th className='text-center'>{totalCompleteCount2 + totalInProgressCount2}</th>
                                            <td className='text-center'></td>
                                        </tr>
                                </Ax>
                            }

                            <tr><td colSpan={5}>&nbsp;</td></tr>
                            <tr><td colSpan={5}><b>Asset Productivity</b></td></tr>
                            {
                                asset_production_keys_withunits && asset_production_keys_withunits.length > 0 &&
                                asset_production_keys_withunits.map((val, i) => {
                                    let production_val1 = asset_prod_data1 && asset_prod_data1.length > 0 ? (asset_prod_data1.find(p => p.key == val.key)?.production) : '';
        
                                    let production_val2 = asset_prod_data2 && asset_prod_data2.length > 0 ? (asset_prod_data2.find(p => p.key == val.key)?.production) : '';
                                    return (
                                        <tr key={i}>
                                            <td>{val.display}</td>
                                            <td className='text-center'>{val.uom}</td>
                                            <th className='text-center'>{production_val1}</th>
                                            <th className='text-center'>{production_val2}</th>
                                            <td className='text-center'>{production_val1 && production_val2 && this.statusJsx(production_val1, production_val2, 'increase')}
                                                {production_val1 && production_val2 && this.changePer(!production_val2 ? 0 : Number(production_val2), production_val1 ? Number(production_val1) : 0)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td>Total Productivity</td>
                                <td className='text-center'></td>
                                <th className='text-center'>
                                    {   
                                        asset_production1 && asset_production1.asset_total_producutvity ? asset_production1.asset_total_producutvity : ''
                                    }
                                </th>
                                <th className='text-center'>
                                    {
                                        asset_production2 && asset_production2.asset_total_producutvity ? asset_production2.asset_total_producutvity : ''
                                    }
                                </th>
                                <td className='text-center'>
                                    {
                                        this.statusJsx(asset_production1?.asset_total_producutvity, asset_production2?.asset_total_producutvity, 'increase')
                                    }
                                    {
                                        this.changePer(asset_production2?.asset_total_producutvity, asset_production1?.asset_total_producutvity)
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Expense per Productivity</td>
                                <td className='text-center'></td>
                                <th className='text-center'>
                                    {asset_production1 && asset_production1?.expense_per_productivity}
                                </th>
                                <th className='text-center'>
                                    {asset_production2 && asset_production2?.expense_per_productivity}
                                </th>
                                <td className='text-center'>
                                    {this.statusJsx(asset_production1?.expense_per_productivity, asset_production2?.expense_per_productivity, 'decrease')}
                                    {this.changePer(asset_production2?.expense_per_productivity, asset_production1?.expense_per_productivity)}
                                </td>
                            </tr>


                            <tr><td colSpan={5}>&nbsp;</td></tr>
                            <tr><td colSpan={5}><b>Site Productivity</b></td></tr>
                            {
                                production_keys_withunits && production_keys_withunits.length > 0 &&
                                production_keys_withunits.map((val, i) => {
                                    let production_val1 = prod_data1 && prod_data1.length > 0 ? (prod_data1.find(p => p.key == val.key)?.production) : '';
        
                                    let production_val2 = prod_data2 && prod_data2.length > 0 ? (prod_data2.find(p => p.key == val.key)?.production) : '';
                                    return (
                                        <tr key={i}>
                                            <td>{val.display}</td>
                                            <td className='text-center'>{val.uom}</td>
                                            <th className='text-center'>{production_val1}</th>
                                            <th className='text-center'>{production_val2}</th>
                                            <td className='text-center'>{production_val1 && production_val2 && this.statusJsx(production_val1, production_val2, 'increase')}
                                                {production_val1 && production_val2 && this.changePer(!production_val2 ? 0 : Number(production_val2), production_val1 ? Number(production_val1) : 0)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td>Total Productivity</td>
                                <td className='text-center'></td>
                                <th className='text-center'>
                                    {   
                                        production1 && production1.site_total_producutvity ? production1.site_total_producutvity : ''
                                    }
                                </th>
                                <th className='text-center'>
                                    {
                                        production2 && production2.site_total_producutvity ? production2.site_total_producutvity : ''
                                    }
                                </th>
                                <td className='text-center'>
                                    {
                                        this.statusJsx(production1?.site_total_producutvity, production2?.site_total_producutvity, 'increase')
                                    }
                                    {
                                        this.changePer(production2?.site_total_producutvity, production1?.site_total_producutvity)
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Expense per Productivity</td>
                                <td className='text-center'></td>
                                <th className='text-center'>
                                    {production1 && production1?.expense_per_productivity}
                                </th>
                                <th className='text-center'>
                                    {production2 && production2?.expense_per_productivity}
                                </th>
                                <td className='text-center'>
                                    {this.statusJsx(production1?.expense_per_productivity, production2?.expense_per_productivity, 'decrease')}
                                    {this.changePer(production2?.expense_per_productivity, production1?.expense_per_productivity)}
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr><td colSpan={5}><b>Downtime</b></td></tr>
                            {downtimeTypes && downtimeTypes.length > 0 && downtimeTypes.map((dt,k) => {
                                let downtime1_dt	=	downtime1.find(d =>d.key == dt.key);
                                let downtime2_dt	=	downtime2.find(d =>d.key == dt.key);
                                return <tr key={k}>
                                    <td>{dt.name}</td>
                                    <td className='text-center'>Hours</td>
                                    <th className='text-center'>{downtime1_dt.downtime}</th>
                                    <th className='text-center'>{downtime2_dt.downtime}</th>
                                    <td className='text-center'>
                                        {this.statusJsx(downtime1_dt.downtime_seconds, downtime2_dt.downtime_seconds, 'decrease')}
                                        {this.changePer(downtime2_dt.downtime_seconds, downtime1_dt.downtime_seconds)}
                                    </td>
                                </tr>
                            })}
                            <tr>
                                <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr><td colSpan={5}><b>Tickets</b></td></tr>
                            <tr>
                                <td>Tickets Created</td>
                                <td className='text-center'>Count</td>
                                <th className='text-center'>
                                    {
                                        ticket1 && ticket1.ticket_created_count ? ticket1.ticket_created_count : ''
                                    }
                                </th>
                                <th className='text-center'>
                                    {
                                        ticket2 && ticket2.ticket_created_count ? ticket2.ticket_created_count : ''
                                    }
                                </th>
                                <td className='text-center'>
                                    {this.statusJsx(ticket1?.ticket_created_count, ticket2?.ticket_created_count, 'increase')}
                                    {this.changePer(ticket2?.ticket_created_count, ticket1?.ticket_created_count)}
                                </td>
                            </tr>
                            <tr>
                                <td>Tickets Closed</td>
                                <td className='text-center'>Count</td>
                                <th className='text-center'>
                                    {
                                        ticket1 && ticket1.ticket_closer_count ? ticket1.ticket_closer_count : ''
                                    }
                                </th>
                                <th className='text-center'>
                                    {ticket2 && ticket2.ticket_closer_count ? ticket2.ticket_closer_count : ''}
                                </th>
                                <td className='text-center'>
                                    {this.statusJsx(ticket1?.ticket_closer_count, ticket2?.ticket_closer_count, 'increase')}
                                    {this.changePer(ticket2?.ticket_closer_count, ticket1?.ticket_closer_count)}
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={5}>&nbsp;</td>
                            </tr>
                            <tr><td colSpan={5}><b>Service Checksheet</b></td></tr>
                            <tr>
                                <td>SCS Executed</td>
                                <td className='text-center'>Count</td>
                                <th className='text-center'>
                                    {scs1 && scs1.scs_executed ? scs1.scs_executed : ''}
                                </th>
                                <th className='text-center'>
                                    {scs2 && scs2.scs_executed ? scs2.scs_executed : ''}
                                </th>
                                <td className='text-center'>
                                    {this.statusJsx(scs1?.scs_executed, scs2?.scs_executed, 'increase')}
                                    {this.changePer(scs2?.scs_executed, scs1?.scs_executed)}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={5}>&nbsp;</td>
                            </tr>

                        </Ax>
                    }
                </tbody>
            </table>
        </div>)
    }

    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Site Comparison - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className=" pageTbl col-12 pb-2" >
                        {this.siteCardFormJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        group_permission        :   state.app.acl_info,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(SiteComparisonReport);