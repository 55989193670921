import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import moment from "moment";
import Status from "../../../components/ui/Status";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class FuelConsumptionWidget extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            iam_user_sites              :   [],
            widgetForm              :   {search_site_ids : [],date_range:"current_month"},
            loading                     :   false,
            data                        :   [],
            remark_day                  :   0,
            remark_time                 :   '',
            not_active                  :   0,
            all_periods                 :   []
        }
        this.updateMyDashboardRef      =   React.createRef()
    }


    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('fuelConsumptionWidget')) {
            let complianceData = JSON.parse(localStorage.getItem('fuelConsumptionWidget'));
            if (complianceData) {
                let lastRefreshTime = moment(complianceData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('fuelConsumptionWidget')
                    setTimeout(() => {
                        this.getcomplianceListing();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 13));
                   
                } else {
                    this.setState({
                        data            :       complianceData.data,
                        remark_time     :       complianceData.time,
                        remark_day      :       complianceData.remark_aging,
                        not_active      :       complianceData.not_active_compliance  
                })

                }
            }else{
                setTimeout(() => {
                    this.getcomplianceListing();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 13));
            }
        } else {
            setTimeout(() => {
                this.getcomplianceListing();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 13));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites           :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                all_periods             :   props.all_periods ? props.all_periods.map((s) => { return {value: s.key, label: `${s.display}`}}) : [],
            }, () => {
                
                 if(props.dashboard_filter_site_id || props.clearDashboardFilter == 'yes'){
                    this.setState({
                        widgetForm              :   {
                            search_site_ids         :       props.dashboard_filter_site_id ?  [props.dashboard_filter_site_id] : [],
                            date_range              :       "current_month"
                        },
                    },() =>  this.getcomplianceListing());
                }
            });
        }
    }

    getcomplianceListing                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {
            search_site_ids: this.state.widgetForm && this.state.widgetForm.search_site_ids && this.state.widgetForm.search_site_ids.length > 0 ? [this.state.widgetForm.search_site_ids] : null ,
           date_range   :   this.state.widgetForm.date_range,
            reload : reload ? 'reload' : false,
            action:"widget"
        };
            
        HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/report/fuel-consumption', this.props.access_token, params, {}, (response) => {
            
            this.setState({
                data            :       response.data.data,
                remark_time     :       response.data.time, 
                remark_day      :       response.data.remark_aging,
                not_active      :       response.data.not_active_compliance
            })
  
            if(this.state.widgetForm.search_site_ids && this.state.widgetForm.search_site_ids.length == 0 &&  this.state.widgetForm.date_range == "current_month"){
                localStorage.setItem('fuelConsumptionWidget',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('fuelConsumptionWidget',JSON.stringify(response.data));
            }
            
        }).then(() => this.setState({ loading: false }));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"fuel-consumption",name:"Fuel Consumption"})
    }
  
    render                      =   ()  =>  {
        let allStatus           =   this.state.data  && this.state.data.map(st => st.status).join(',');
       
          
        let filterObj           =   {};
         if(this.state.widgetForm){
            if(this.state.widgetForm.search_site_ids && this.state.widgetForm.search_site_ids.length > 0){
                filterObj           =   {...filterObj , 'search_site_ids' : this.state.widgetForm.search_site_ids}
            }
            if(this.state.widgetForm.date_range){
                filterObj           =   {...filterObj , 'date_range' : this.state.widgetForm.date_range}
            }
            
     }
     let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("fuel-consumption" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "fuel-consumption")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Fuel Consumption</h6>
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.all_periods}
                            value={this.state.all_periods.find(r => r.value === this.state.widgetForm.date_range)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'widgetForm', 'date_range', () => this.getcomplianceListing())}
                        />
                    </div>
                    <div className="col-3">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r =>  this.state.widgetForm.search_site_ids.includes(r.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'widgetForm', 'search_site_ids', () => this.getcomplianceListing()) }
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={this.getcomplianceListing}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "50%" }} className="text-center" >Status</th>
                                    <th className="text-center">Count of Assets</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? (<tr>
                                        <td className="text-center" colSpan="2"><Loader /></td>
                                    </tr>)
                                    : <Ax>
                                        {this.state.data && this.state.data.length > 0 && this.state.data.map((s, d) => {
                                            let obj = { ...filterObj, 'status': s.key ,date_range:this.state.widgetForm.date_range};
                                            return (<tr key={d + 1}>
                                                <th><Status color={s.color_code}>{s.name}</Status></th>
                                                <td className="text-center">
                                                    <Link target="_blank" to={{ pathname: "/report/fuel_consumption_report", search: "?" + new URLSearchParams(obj).toString() }}>{s.count}</Link></td>
                                            </tr>)
                                        })}
                                        <tr>
                                            <th>Total</th>
                                            <td className="text-center"> 
                                            <Link target="_blank" to={{ pathname: "/report/fuel_consumption_report", search: "?" + new URLSearchParams({...filterObj,'search' : 'all'}).toString() }}>
                                            {this.state.data && this.state.data.length > 0 && this.state.data.map(s => s.count).reduce((a, b) => a + b, 0)} 
                                            </Link>
                                             </td>
                                        </tr>
                                        
                                       
                                    </Ax>}



                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p0">
                    { this.state.loading
                            ? <Loader />
                            : (this.state.data ? (<Chart
                                chartType="PieChart"
                                // loader={<Loader />}
                                data={[['', '']].concat(this.state.data?.map((d) =>
                                { return [d.name, d.count]; }))}
                                options={{
                                    legend: {position: 'labeled',labeledValueText: 'both'},
                                    pieSliceText: 'none',
                                    sliceVisibilityThreshold :0,
                                    slices: this.state.data?.map((d) => {return {color : d.color_code} }),
                                    chartArea: {width: '90%', height: '90%' },
                                }}
                                rootProps={{ 'data-testid': '1' }} />) : null)  
                        }
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                    Report as of {this.state.remark_time}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

export default connect(mapStateToProps)(FuelConsumptionWidget);
