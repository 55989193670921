import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../components/hoc/Ax';
import Loader from '../../components/ui/Loader/Loader';
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';
import Status from '../../components/ui/Status';

class AssetExpenseDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            assetExpenseData            :       null,
            view_loading                :       false,
            assetData                   :       null
        };
        
    }

    componentDidMount() {
        if(this.props && this.props.assetExpenseId){
            this.getAssetExpenseDetails(this.props.assetExpenseId)
        }
        if(this.props && this.props.assetExpenseData){
            this.setState({assetExpenseData : this.props.assetExpenseData})
        }
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.assetExpenseId !== this.props.assetExpenseId){
            this.getAssetExpenseDetails(nextProps.assetExpenseId)
         }
         if(nextProps.assetExpenseData !== this.props.assetExpenseData){
             this.setState({assetExpenseData : nextProps.assetExpenseData})
         }
         
     }
    

     getAssetExpenseDetails             =    (transaction_id)        =>  {
        this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET', process.env.REACT_APP_API_SERVER + '/asset_expence/'  + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 assetExpenseData            : respData.data,
                 assetData                   : respData.asset
             });
         }).then(() => this.setState({view_loading: false}));
        
     }
    
    render() {
        let assetExpenseData = this.state.assetExpenseData
        return (<Ax>
            {this.state.view_loading ? <Loader /> : 
                assetExpenseData && (<Ax>
                    <div className="tab_content_wrapper ">
                        <span className="content_heading">Asset Expense Detail</span>
                    </div>
                     <table className="table pt-2 table-hover table-borderless">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Transaction ID</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{assetExpenseData.activity_id}</th>
                            </tr>
                             <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Date of Transaction</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{assetExpenseData.activity_date}</th>
                            </tr>
                          <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Expense Head</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{assetExpenseData.expense_head??"-"}</th>
                            </tr>
                              <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Expense Details</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{assetExpenseData.expense_details !== null && assetExpenseData.expense_details !== "" ? assetExpenseData.expense_details : "-"}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Amount</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{assetExpenseData.expense_amount !== null && assetExpenseData.expense_amount !== "" ? assetExpenseData.expense_amount : "-"}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Payee</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{assetExpenseData.payee !== null && assetExpenseData.payee && assetExpenseData.payee.key_display ? assetExpenseData.payee.key_display : "-"}</th>
                            </tr>
                          <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Payee Name</td>
                                <th className="fs14 fw500" style={{ "width": "66%" }}>{assetExpenseData.payee !== null && assetExpenseData.payee && assetExpenseData.payee.payee_user ? assetExpenseData.payee.payee_user.name : "-"}</th>
                            </tr>
                              <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Reference Number</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{assetExpenseData.reference_number !== null ? assetExpenseData.reference_number : "-"}</th>
                            </tr>
                           <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Expense Voucher Attachment</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{assetExpenseData.expense_voucher_url ? <a href={assetExpenseData.expense_voucher_url} target="_blank">View</a> : "-"}</th>
                            </tr>
                             <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Notes</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{assetExpenseData.notes !== null && assetExpenseData.notes !== "" ? assetExpenseData.notes : "-"}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Wallet</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{assetExpenseData.wallet !== null ? assetExpenseData.wallet : "-"}</th>
                            </tr>
                           <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Site</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{assetExpenseData.profit_center ? assetExpenseData.profit_center : "-"}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}>Asset Type</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{this.state.assetData  ? this.state.assetData.asset_type_name : "-"}</th>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "34%" }}> Asset</td>
                                <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{this.state.assetData ? this.state.assetData.display_name : "-"}</th>
                            </tr>
                        </tbody>
                    </table>
            </Ax>)}
        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps)(AssetExpenseDetail);