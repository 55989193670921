import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { APP_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import ApplicationLayout from "../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Loader from '../../components/ui/Loader/Loader';
import Ax from '../../components/hoc/Ax';
import reportStyles from "./Report.module.css"
import HttpAPICall from '../../services/HttpAPICall';
import * as actions from "../../store/actions/index"
import ReportMenu from './includes/ReportMenu';
import ReportHeader from './includes/ReportHeader';
import { DisplayListPagination } from '../../components/TapUi';
import Status from '../../components/ui/Status';
import TapIcon from '../../services/TapIcon';


const ReportRequest     = forwardRef((props, ref) => {


    const dispatch                          =   useDispatch();
    const access_token                      =   useSelector(state => state.auth.access_token);



    let initListData                        =   {
        loading                                 :   false,
        items                                   :   [],
        meta                                    :   null,
        page                                    :   1,
    };

    const [itemData, setItemData]           =   useState(initListData);


    let itemListUrl                         =   APP_BASE_URL_2 + "/schedule/download_request";


    const refreshData                       =   ()  =>  {
        loadItemsData(itemData.page);
    }

    const loadItemsData             =   (page = 1)  =>  {
        setItemData(pd => ({...pd, loading : true, page : page }));
        HttpAPICall.withAthorization('GET', itemListUrl, access_token, {page:page}, {}, (response) => {
            let respData                =   response.data;
            let meta                    =   {
                from                        :   respData.from,
                to                          :   respData.to,
                total                       :   respData.total,
                last_page                   :   respData.last_page,
                per_page                    :   respData.per_page,
                current_page                :   respData.current_page
            };

            setItemData(pd => ({...pd, items : respData.data, meta : meta }));
 
        }).then(() => {
            setItemData(pd => ({...pd, loading : false}));
        });
    }


    useEffect(() => {
        loadItemsData();
    }, []);


    const taskReportTableJsx        =   ()  =>  {
        return <> 
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr className='text-center'>
                    <th style={{ width: "5%" }}>S.No </th>
                        <th style={{ width: "15%" }}>Request Id </th>
                        <th style={{ width: "20%" }}>Date of Creation</th>
                        <th style={{ width: "35%" }} className='text-start'>Report Name</th>
                        <th style={{ width: "10%" }}>Status</th>
                        <th style={{ width: "15%" }}>Download</th>
                    </tr>
                </thead>
                <tbody>
                {itemData.loading 
                    ? <tr><td className='text-center' colSpan={6}><Loader /></td></tr> 
                    : (itemData.items && itemData.items.length > 0 ? itemData.items.map((item,index) => {
                        return (
                            <tr key={index}className='text-center'>
                                <td>{itemData.meta ? itemData.meta.from + index : index }</td>
                                 <td>{item.req_id}</td> 
                                <td>{item.created_at_display}</td>
                                <td className='text-start'>{item.report ? item.report.name : "-" }</td>
                                <td className='text-start'>{item.status ? <Status color={item.status.color_code}>{item.status.status}</Status> : ''}</td>
                                <td>
                                    {item.reserved == 1 && item.response && item.response.file_path 
                                    ? <a target='_blank' href={item.response.file_path}>Download Report</a> : ''}
                                </td>
                            </tr>
                        )
                    }) : <tr><td className='text-center' colSpan={6}>No Record</td></tr>) }  
                </tbody>
            </table>
            <DisplayListPagination meta={itemData.meta} onPageChange={(e) => loadItemsData(e.selected + 1)} />
        </>
    }

    const topRightHeaderJsx             =   ()  =>  {
        return <>
            <button type="button" className="btn btn-secondary" disabled={itemData.loading} onClick={refreshData} >
                <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className={[itemData.loading ? 'fa-spin' : '' ].join(" ")}   />
            </button>
        </>
    }

    return  <ApplicationLayout>
        <ReportHeader title="Report Request - Report" name="Reports" rightTopHeadingJsx={topRightHeaderJsx()} />
        <div className="container-fluid">
            <div className='row mt-1 px5'>
                <div className='col-sm-12 bg-white px-0'>
                    <ReportMenu page="download_request" />
                    <div className="tab_content_header"> { taskReportTableJsx()}</div>
                </div>
            </div>
        </div>
    </ApplicationLayout>

});

export default ReportRequest;