import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import General from './General';
import ActivityList from './ActivityList';
import ActivityCodeList from './ActivityCodeList';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';


class ActivityConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state   =   {
            configurationChanged    :   false
        }   
    }

    render() {
        return (<section className="item_view_container bg-white">
            <Helmet><title>Assets Configuration : Activity</title></Helmet>
            <ToastContainer />
            <ul className="nav nav-tabs" id="myTab">
                <li className="nav-item" role="ActivityTab">
                    <button className="nav-link active" id="setting-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_1" aria-controls="tab_1" role="tab" aria-selected="true">General Settings</button>
                </li>
                <li className="nav-item" role="ActivityTab">
                    <button className="nav-link" id="activity-name-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_2" aria-controls="tab_2" role="tab" aria-selected="false">Activity Name</button>
                </li>
                <li className="nav-item" role="ActivityTab">
                    <button className="nav-link" id="activity-code-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_3" aria-controls="tab_3" role="tab" aria-selected="false">Activity Code</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="tab_1" role="tabpanel"
                    aria-labelledby="setting-tab">
                    <div className="tab_content_header">
                        <General afterSubmitConfiguration={() => this.setState({ configurationChanged: true })} />
                    </div>
                </div>
                <div className="tab-pane" id="tab_2" role="tabpanel"
                    aria-labelledby="activity-name-tab">
                    <div className="tab_content_header">
                        <ActivityList configurationChanged={this.state.configurationChanged} />
                    </div>
                </div>
                <div className="tab-pane" id="tab_3" role="tabpanel"
                    aria-labelledby="activity-code-tab">
                    <div className="tab_content_header">
                        <ActivityCodeList />
                    </div>
                </div>

            </div>
        </section>
         
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(ActivityConfiguration);