import React from 'react';
import { connect } from 'react-redux';
import Ax from '../components/hoc/Ax';
import { Link } from 'react-router-dom';
import MaintenanceImg from './../assets/img/maintenance.png';

class MaintenanceMode extends React.PureComponent {
  
    render() {
        return (
            <div className='text-center'>
                <img src={MaintenanceImg} style={{height:250, marginTop:"150px"}} />
                <h4 className='fs-2 mt-5'>We are under Maintenance</h4>
                <h4 className='fs-4 mt-3'>Will be back soon</h4>
            </div>
        )
    }
}

export default MaintenanceMode;
