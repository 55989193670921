import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import {connect} from "react-redux";
import HttpAPICall from "../../../services/HttpAPICall";
import Ax from "../../../components/hoc/Ax";
import AssetCard from "../../includes/assetCard/AssetCard";
import {Link} from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import { Helmet } from 'react-helmet';
import swal from "sweetalert";
import ChangeInvoiceRule from '../../resources/InvoiceAssetLinking/ChangeInvoiceRule';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { toast } from 'react-toastify';
import ChangeAssetStatusssss from '../includes/ChangeAssetStatus';
import InactivateAsset from '../includes/InactivateAsset';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import ReactDatePicker from 'react-datepicker';
import DatePicker from "react-datepicker";
import moment from 'moment';
import TapSelect from '../../../components/ui/TapSelect';
import * as actions from '../../../store/actions/index';
import SiteAdd from '../../groupData/sites/SiteAdd';
import EditLinkedOperators from '../../hrm/EditLinkedOperators';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';


class AssetDetails extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initForm               =       {
            id                      :       '',
            name                    :       '',
            ownership_category      :       '',
            newOwnerName            :       ''
        }

        this.initDetailForm         =       {
            name                    :       '',
            asset_code              :       '',
            notes                   :       ''
        }

        this.initSerialNumberForm    =       {
            purchase_date            :        '',
            commissioing_date        :        '',
            engine_number            :        '',
            chasis_number            :        '',
            machine_no               :        '',
            registration_number      :        '',
            imei_number              :        '',
            manufacture_year       :        '',
            auxiliary_engine_no     :       ''
        }

        this.initShowDetailError        =       {
            name                    :       '',
            id                      :       '',
            asset_code              :       '',
            engine_number            :        '',
            chasis_number            :        '',
            machine_no               :        '',
            registration_number      :        '',
        }

        this.initSiteTransitionForm     =       {
           site_id                      :         '',
           transaction_date             :         '',
           moving_employee_ids          :         [],
           moving_user_ids              :         []
        }

        this.initIotIntegrationForm     =   {
            linked_iot_provider             :   null,
            imei_number                     :   null,
        }
        this.initLinkToWorkstation      =   {
            asset_id                        :   '',
            workstation_ids                 :   []   
        }

        this.state                      =       {
            assetBasicDetails           :       [],
            asset_id                    :       null,
            assetData                   :       null,
            newOwner                    :       'N',
            addOwnerForm                :       {...this.initForm},
            editDetailForm              :       {...this.initDetailForm},
            siteTransitionForm          :       {...this.initSiteTransitionForm},
            editSerialNumberForm        :       {...this.initSerialNumberForm},
            allOwnerShipCategories      :       [],
            allOwnersList               :       [],
            showErrors                  :       { ...this.initShowDetailError } ,
            iam_user_sites              :       [] ,
            currentSiteId               :       '',
            iotIntegrationForm          :       {...this.initIotIntegrationForm},
            iotProviderVender           :       [],
            assetDetailsByKey           :       [],
            allLinkedEmployeeList       :       [],
            allLinkedUsers              :       [],
            linkToWorkstation           :       {...this.initLinkToWorkstation},
            searchedWorkstation         :       []
        }
        this.linkAssetRef                   =           React.createRef();
        this.ChangeAssetStatusModalRef      =           React.createRef();
        this.InactivateAssetModalRef        =           React.createRef(); 
        this.ownershipModalId               =           uuid();
        this.detailModalId                  =           uuid();
        this.serialNumberId                 =           uuid();
        this.addSiteRef                     =          React.createRef();
        this.editLinkOperatorsRef           =          React.createRef();

        this.iotVendorUrl                   =   `${TapApiUrls.ORGANIZATION_URL}/iot_vendor`;

        this.workstationLinkAssetUrl   =   IAM_API_BASE_URL_2 + '/workstation/link_to_asset';
               
    }

    componentDidMount() {
        this.initalizeData(this.props)
       
        this.iotIntegrationModel                =   new Modal(document.getElementById('iotIntegrationModel'), {keyboard: false, backdrop :false});

        this.getBasicAssetDetails(this.props.asset_enc_id)
        this.ownershipModal                     =   new Modal(document.getElementById(this.ownershipModalId), {keyboard: false, backdrop :false});
        this.detailModal                        =   new Modal(document.getElementById(this.detailModalId), {keyboard: false, backdrop :false});
        this.serialNumberModal                  =   new Modal(document.getElementById(this.serialNumberId), {keyboard: false, backdrop :false});
        this.siteTransitionModal                =   new Modal(document.getElementById('siteTransitionModal'), {keyboard: false, backdrop :false});
        this.linkAssetModal                     =   new Modal(document.getElementById('linkAssetsModal'), {keyboard: false, backdrop :false});
        this.linkWorkstationModal               =   new Modal(document.getElementById('linkWorkstationModal'), {keyboard: false, backdrop :false});
        
    }       

    componentWillReceiveProps(nextProps) {
      
       if(nextProps.asset_enc_id !== this.props.asset_enc_id || nextProps.assetData !== this.props.assetData || nextProps.iam_user_sites !== this.props.iam_user_sites ){
        this.initalizeData(nextProps)
       }
       if(nextProps.refreshData == 'Y'){
        this.getBasicAssetDetails(nextProps.asset_enc_id)
       }
    }

    initalizeData = (pr) => {

        this.setState({
            asset_id            :       pr.asset_enc_id,
            assetData           :       pr.assetData,
            iam_user_sites      :       pr.iam_user_sites && pr.iam_user_sites.length > 0 ? pr.iam_user_sites.map(s => {return({value : s.id , label : `${s.site_name} (${s.site_code})`})}) : []
        }, () => {



        });

        let ioTPermissionIsPresent      =   pr.permissions.group_sub_modules.find(s => s.sub_module == "iot") ? true : false;
        let hrmPermissionisPresent      =   pr.permissions.group_modules.includes("hrm") ? true : false;
        let params                      =   {need_keys : ['serial_numbers','users','site', 'workstation']};
        let iotAdded                    =   false;
        let hrmAdded                    =   false;
        
        if(ioTPermissionIsPresent)      {
            if (!iotAdded) {
                params.need_keys.push('linked_iot_provider');
                iotAdded = true; 
             }
            HttpAPICall.withAthorization('GET', `${this.iotVendorUrl}/form_data`, this.props.access_token, {}, {}, (response) => {     
                const respData      =   response.data;
                this.setState({
                    iotProviderVender   :   respData.iot_providers ? respData.iot_providers.map(iot => {return {value : iot.id, label : iot.name}}) : [],
                });  
            });
        }

        if(hrmPermissionisPresent && !hrmAdded){
            params.need_keys.push('employees');
            hrmAdded = true;  
        }

        HttpAPICall.withAthorization('GET', `${TapApiUrls.IAM}/asset_detail/${pr.asset_enc_id}`, this.props.access_token, params, {}, (response) => {     
            const respData      =   response.data.data;
            this.setState({
                assetDetailsByKey   :   respData,
                allLinkedEmployeeList   :   respData && respData.employees && respData.employees.length > 0 ? respData.employees.map(emp => {return({value:emp.enc_id,label:emp.name})})  :   [],
                allLinkedUsers         :   respData && respData.users && respData.users.length > 0 ? respData.users.map(emp => {return({value:emp.enc_id,label:emp.full_name})})  :   [],
                iotIntegrationForm  :   {imei_number : respData?.imei_number, linked_iot_provider : respData?.linked_iot_vendor?.id},
            });
        });
        
        
    }



    getBasicAssetDetails                =     (id)    =>  {
        this.setState({basic_details_loading : true})
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/section_wise_detail/' + id,
            this.props.access_token)
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    assetBasicDetails        :    respData,
                });
            }).then(() => {
            this.setState({basic_details_loading: false});
        });
    }

    linkInvoiceRuleInit         =       (asset_id)        =>      {
        let invoice_rule = this.state.assetBasicDetails && this.state.assetBasicDetails[6] && this.state.assetBasicDetails[6].data[0].val !== '' ? this.state.assetBasicDetails[6].data[0].val : null;
         this.linkAssetRef.current.modalInit(this.state.asset_id,null,invoice_rule);
    }

    changeStatusModal                       =   ()  =>  {
        if(this.state.asset_id){
            this.ChangeAssetStatusModalRef.current.changeStatusModalInit(this.state.asset_id);
        }  
    }

    inactivateStatusModal                       =   ()  =>  {
        if(this.state.asset_id){
            this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.asset_id,true);
        }  
    }

    activateAssetModalInit          =       ()           =>      {
        if (this.state.asset_id) {
            this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.asset_id, false)
        }
    }

    addSiteModalInit       =       ()      =>  {
        this.addSiteRef.current.addSiteInit(null);
    }

    linkOperatorsModalInit       =       ()      =>  {
        let detail           =       this.state.assetBasicDetails.filter(basicDetails =>  basicDetails.key == "visibility");
        if(detail){
           
             this.editLinkOperatorsRef.current.editLinkOperatorModalInit(this.state.assetData,this.state.asset_id);
        }
        
    }


    editOwnershipInit     =       ()      =>  {
        this.ownershipModal.show()
        if(this.state.assetBasicDetails){ 
            let ownership           =       this.state.assetBasicDetails.filter(basicDetails =>  basicDetails.key == "ownership");
            let allOwnersList       =       ownership && ownership[0]?.owners && ownership[0]?.owners.length > 0 ? ownership[0]?.owners.map(on => {return({value : on.id , label : on.name})}) : [];
            let allOwnerShipCategories      =   ownership && ownership[0]?.ownership_category && ownership[0]?.ownership_category.length > 0 ? ownership[0]?.ownership_category : [];
            this.setState({
                allOwnersList           :      allOwnersList,
                allOwnerShipCategories  :      allOwnerShipCategories,
                addOwnerForm            :       {
                    ...this.initForm,
                    id                  :      ownership && ownership.length > 0 ? ownership[0].owner_id : '',
                    ownership_category  :      ownership && ownership.length > 0 && ownership[0].category ? ownership[0].category : '',
                }
            })
            
         }
    }

    submitOwnershipHandler       =       (e)         =>      {
        e.preventDefault()
        let frmData = null;
        if(this.state.newOwner == "Y"){
            frmData = {ownership_category : this.state.addOwnerForm.ownership_category , id : null ,name : this.state.addOwnerForm.newOwnerName}
        }else{
            let ownerDetail = this.state.allOwnersList && this.state.allOwnersList.length > 0 ? this.state.allOwnersList.find(i => i.value == this.state.addOwnerForm.id) : null;
            frmData = {ownership_category : this.state.addOwnerForm.ownership_category , id : ownerDetail && ownerDetail.value , name : ownerDetail && ownerDetail.label}
        }
       
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/asset_ownership/' + this.state.asset_id , this.props.access_token, null, {...frmData}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.getBasicAssetDetails(this.state.asset_id)
            this.ownershipModal.hide()
            this.setState({addOwnerForm : {...this.initForm},newOwner : "N"})
        }).then(() => this.setState({saveFormSubmitting: false}))
        
    }

    deleteOwnership              =       ()      =>      {
        swal({
            title: "Delink Ownership",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', TapApiUrls.IAM + '/asset_ownership/' + this.state.asset_id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getBasicAssetDetails(this.state.asset_id)
                    }).then(() => {

                    });
                }
            });
    }

    removeLink              =       ()      =>      {
        swal({
            title: "Delink Invoice Rule",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/invoice_rule_unlink/asset', this.props.access_token, {asset_id : this.state.asset_id},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getBasicAssetDetails(this.state.asset_id)
                    }).then(() => {

                    });
                }
            });
    }

    editDetailInit     =       ()      =>  {
        this.detailModal.show()
       
        if(this.state.assetBasicDetails){ 
            let detail           =       this.state.assetBasicDetails.filter(basicDetails =>  basicDetails.key == "asset_detail");
           
            this.setState({
                editDetailForm            :       {
                    ...this.initDetailForm,
                    name                  :      detail && detail.length > 0 ? detail[0]?.data[0]?.val : '',
                    asset_code            :      detail && detail.length > 0 ? detail[0]?.data[1]?.val : '',
                    notes                 :      detail && detail.length > 0 ? detail[0]?.data[11]?.val : '',
                },
                showErrors                 :        {...this.initShowDetailError}
            })
            
         }
    }

    submitDetailHandler       =       (e)         =>      {
        e.preventDefault()
        let frmData = {...this.state.editDetailForm}
       
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/asset/' + this.state.asset_id , this.props.access_token, null, {...frmData}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.getBasicAssetDetails(this.state.asset_id)
            this.detailModal.hide()
            if(this.props.refreshAssetDetail){
                this.props.refreshAssetDetail()
            }
            this.props.onListingSuccess(1, this.props.asset_param, 'Y', this.props.searchedElems) 
            this.setState({editDetailForm : {...this.initDetailForm},showErrors : {...this.initShowDetailError}})
        },(error) => {
           
        }).then(() => this.setState({saveFormSubmitting: false}))
        
    }
    
    editSerialNumberInit     =       ()      =>  {
        this.serialNumberModal.show()
        if(this.state.assetBasicDetails){ 
            let serialNumber           =       this.state.assetBasicDetails.filter(basicDetails =>  basicDetails.key == "serial_numbers");
            let data                    =        serialNumber && serialNumber.length > 0 ?  serialNumber[0]?.data : null;

            let machine_no                       =    data ? data.find(dt => dt.key == 'Machine Serial No.') : '';   
            let registration_number              =    data ? data.find(dt => dt.key == 'Motor Vehicle Registration Number') : '';
            let engine_number                    =    data ? data.find(dt => dt.key == 'Engine Serial No') : ''; 
            let chasis_number                    =    data ? data.find(dt => dt.key == 'Chassis Number') : ''; 
            let imei_number                       =    data ? data.find(dt => dt.key == 'IMEI Number') : ''; 
            let manufacture_year                 =    data ? data.find(dt => dt.key == 'Year of Manufacturing') : ''; 
            let commissioing_date                =    data ? data.find(dt => dt.key == 'Year of Commissioning') : ''; 
            let purchase_date                    =    data ? data.find(dt => dt.key == 'Purchased Date') : ''; 
            let auxiliary_engine_no             =     data ? data.find(dt => dt.key == 'Auxiliary Engine number') : '';

            this.setState({
                editSerialNumberForm            :       {
                    ...this.initSerialNumberForm,
                    machine_no                  :      machine_no && machine_no.val  ? machine_no.val : '',
                    registration_number         :      registration_number && registration_number.val ? registration_number.val : '',
                    engine_number               :      engine_number && engine_number.val  ? engine_number.val : '',
                    chasis_number               :      chasis_number && chasis_number.val ? chasis_number.val : '',
                    imei_number                 :      imei_number && imei_number.val  ? imei_number.val : '', 
                    manufacture_year            :      manufacture_year && manufacture_year.val ? manufacture_year.val : '', 
                    commissioing_date           :      commissioing_date && commissioing_date.val ? commissioing_date.val : '', 
                    purchase_date               :      purchase_date && purchase_date.val  ? purchase_date.val : '', 
                    auxiliary_engine_no         :      auxiliary_engine_no && auxiliary_engine_no.val  ? auxiliary_engine_no.val : '',    
                }
            })
            
         }
    }

    submitSerialNumberHandler       =       (e)         =>      {
        e.preventDefault()
        let frmData = {...this.state.editSerialNumberForm,
            commissioing_date : this.state.editSerialNumberForm.commissioing_date ? moment(this.state.editSerialNumberForm.commissioing_date).format('YYYY-MM-DD')  : '',
            purchase_date : this.state.editSerialNumberForm.purchase_date ? moment(this.state.editSerialNumberForm.purchase_date).format('YYYY-MM-DD')  : '',
           }
       
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/asset/' + this.state.asset_id , this.props.access_token, null, {...frmData}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.getBasicAssetDetails(this.state.asset_id)
            this.serialNumberModal.hide()
            this.setState({editSerialNumberForm : {...this.initSerialNumberForm},showErrors : {...this.initShowDetailError}})
        },(error) => {
            if(error.response.data.status_code == 464){
                this.searchAssetByKey({key: "machine_no"  ,value : this.state.editSerialNumberForm.machine_no},error.response.data.message)
            }else if(error.response.data.status_code == 465){
                this.searchAssetByKey({key: "registration_number"  ,value : this.state.editSerialNumberForm.registration_number},error.response.data.message)
            }else if(error.response.data.status_code == 463){
                this.searchAssetByKey({key: "engine_number"  ,value : this.state.editSerialNumberForm.engine_number},error.response.data.message)
            }else if(error.response.data.status_code == 466){
                this.searchAssetByKey({key: "chasis_number"  ,value : this.state.editSerialNumberForm.chasis_number},error.response.data.message)
            }else{
                toast.error(error.response.data.message, {position: toast.POSITION.TOP_RIGHT});
            }
           
        },false).then(() => this.setState({saveFormSubmitting: false}))
        
    }


    searchAssetByKey        =       (searchKeyObject,message='')     =>     {
       
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/serach_by_key', this.props.access_token, {...searchKeyObject,exclude_asset_ids : [this.state.asset_id]}, {}, (response) => {
          
            if(response.data.data){
           
              let key = searchKeyObject.key;
              let value = searchKeyObject.value;
                this.setState({
                    showErrors: {
                        ...this.state.showErrors,
                        name                    :       key == "name" ? `Asset Name (${value}) already exits, please choose another.!!` : '',
                        asset_code              :       key == "asset_code" ? `Asset Code (${value}) already exits, please choose another.!!` : '',
                        id                      :       response.data.data && response.data.data.id ? response.data.data.id : '',
                        machine_no              :       key == "machine_no" ? message : '',
                        registration_number     :       key == "registration_number" ? message : '',
                        engine_number            :      key == "engine_number" ? message : '',
                        chasis_number            :      key == "chasis_number" ? message : '',
                    }
                });
            }else{
                this.setState({showErrors : {...this.initShowDetailError}})
            }
        },(error) => {
          
        }).then(() => this.setState({saveFormSubmitting: false}))
    }

    changeSiteModalInit     =       ()      =>  {
        this.siteTransitionModal.show();
        this.setState({
            siteTransitionForm      :   {...this.initSiteTransitionForm,
                moving_user_ids     :   this.state.allLinkedUsers && this.state.allLinkedUsers.length > 0 ? this.state.allLinkedUsers.map(emp => emp.value)  :   [], 
                moving_employee_ids :   this.state.allLinkedEmployeeList && this.state.allLinkedEmployeeList.length > 0 ? this.state.allLinkedEmployeeList.map(emp => emp.value)  :   [] ,
            },
        })
        this.setState({ currentSiteId       :       this.state.assetData && this.state.assetData.site_id ? this.state.assetData.site_id : ''})
       
    }

    submitChangeSiteHandler = (e) => {
        e.preventDefault()
        let frmData = {...this.state.siteTransitionForm,asset_id : this.state.asset_id}
       
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/asset/site/change', this.props.access_token, null, {...frmData}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.getBasicAssetDetails(this.state.asset_id)
            this.siteTransitionModal.hide()
            if(this.props.refreshAssetDetail){
                this.props.refreshAssetDetail()
            }
            this.setState({siteTransitionForm          :       {...this.initSiteTransitionForm}})
        }).then(() => this.setState({saveFormSubmitting: false}))
        

    }

    linkAssetModalInit          =   ()  =>  {
        this.linkAssetModal.show();
    }

    iotIntegrationModalInit     =   ()  =>  {
        this.iotIntegrationModel.show();
    }

    submitIotIntegration        =   (e) =>  {
        e.preventDefault();
        const asset_id          =   this.props.asset_enc_id;
        const data = {...this.state.iotIntegrationForm};
        this.setState({saveFormSubmitting : true});
        
        HttpAPICall.withAthorization('PUT', `${TapApiUrls.IAM}/asset/${asset_id}`, this.props.access_token, {}, data, (response) => {     
            const respData      =   response.data;
            toast.success(respData.msg, {position: toast.POSITION.TOP_RIGHT});
            document.getElementById("iotIntegrationForm").reset();
            this.initalizeData(this.props);
            this.setState({
                saveFormSubmitting  : false
            });
            this.iotIntegrationModel.hide();
        });

        
    }

    editIotIntegrationJsx       =   ()  =>  {
        return (
            <div className="modal fade" id="iotIntegrationModel" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit IoT Integration</h5>
                            </div>
                            <div className="modal-body">
                                <form method="POST" id="iotIntegrationForm" onSubmit={this.submitIotIntegration}>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label">IoT Provider</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                options={this.state.iotProviderVender}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'iotIntegrationForm', 'linked_iot_provider')}
                                                value={this.state.iotProviderVender.find(u => u.value == this.state.iotIntegrationForm.linked_iot_provider)}
                                                isClearable={true}
                                                placeholder="Select IoT Provider"
                                                autoCompelete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label">IMEI Number</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="imei_number"
                                                type="text"
                                                value={this.state.iotIntegrationForm.imei_number}
                                                className="form-control"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "iotIntegrationForm")}
                                                placeholder="Please Enter IMEI Number"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer row">
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="iotIntegrationForm">Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let  id = this.props.asset_enc_id
        const assetDetailsByKey = this.state.assetDetailsByKey;
        
        return (
            <Ax>
            <section className="item_view_container bg-white mb-2 tab_content_header">
                <Helmet><title>Asset Details</title></Helmet>
                 {this.state.basic_details_loading
                    ? <Loader />
                    :
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane show active" id="tab_1" aria-labelledby="setting-tab">
                                {this.state?.assetBasicDetails?.map((bd, i) => {
                                    return (<Ax>
                                        <div className="tab_content_wrapper mt-2" key={i} >
                                            <span className="content_heading">{bd.section}</span>
                                            <div className="float-end">

                                                {bd.key == "asset_status" ?
                                                    <Ax>
                                                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                            {/* <li><a className="dropdown-item" role="button" onClick={this.linkInvoiceRuleInit}>Change Invoice Rule</a></li> */}


                                                            {this.props.assetData && this.props.assetData.status == "2"
                                                                ?
                                                                <li><a className={this.state.assetData && this.state.assetData.can_access == false ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={this.state.assetData && this.state.assetData.can_access == false ? true : false} onClick={this.activateAssetModalInit}>Activate Asset</a></li>

                                                                :
                                                                <Ax>
                                                                    <li><a className={this.state.assetData && this.state.assetData.can_access == false ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={this.state.assetData && this.state.assetData.can_access == false ? true : false} onClick={this.changeStatusModal}>Change Status</a></li>
                                                                    <li><a className={this.state.assetData && this.state.assetData.can_access == false ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={this.state.assetData && this.state.assetData.can_access == false ? true : false} onClick={this.inactivateStatusModal}>Remove Asset</a></li>

                                                                </Ax>}
                                                        </ul>
                                                    </Ax>
                                                    : null
                                                }
                                                {bd.key == "invoice_overtime" ?
                                                    <Ax>
                                                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                            <li><a className="dropdown-item" role="button" onClick={this.linkInvoiceRuleInit}>Change Invoice Rule</a></li>
                                                            <li><a className={bd?.data[0]?.val == "" ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={bd?.data[0]?.val == '' ? true : false} onClick={() => this.removeLink()}>Remove Invoice Rule</a></li>
                                                        </ul>
                                                    </Ax> : null}
                                                {bd.key == "ownership" ?
                                                    <Ax>
                                                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                            <li><a className={"dropdown-item"} role="button" onClick={this.editOwnershipInit}> Edit Ownership</a></li>
                                                            <li><a className={bd.key == "ownership" && bd.owner_id ? "dropdown-item " : "dropdown-item disabled"} disabled={bd.key == "ownership" && bd.owner_id ? false : true} role="button" onClick={this.deleteOwnership}> Delete Ownership</a></li>
                                                        </ul>
                                                    </Ax> : null}
                                                {bd.key == "asset_detail" ?
                                                    <Ax>
                                                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                            <li><a className={"dropdown-item"} role="button" onClick={this.editDetailInit}> Edit Detail</a></li>

                                                        </ul>
                                                    </Ax> : null}
                                                {bd.key == "serial_numbers" ?
                                                    <Ax>
                                                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                            <li><a className={"dropdown-item"} role="button" onClick={this.editSerialNumberInit}> Edit Serial Numbers</a></li>

                                                        </ul>
                                                    </Ax> : null}
                                                {bd.key == "site_transition" ?
                                                    <Ax>
                                                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                            <li>
                                                                <a role="button"
                                                                    onClick={() => {
                                                                        if (this.props.permissions.permissions.includes('iam-asset-site-linking-change')) {
                                                                            return this.changeSiteModalInit()
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item', this.props.permissions.permissions.includes('iam-asset-site-linking-change') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props.permissions.permissions.includes('iam-asset-site-linking-change') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Change Site</a>
                                                            </li>
                                                            <li>
                                                                <a role="button"
                                                                    onClick={() => {
                                                                        if (this.props.permissions.permissions.includes('iam-site-add')) {
                                                                            return this.addSiteModalInit()
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item', this.props.permissions.permissions.includes('iam-site-add') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props.permissions.permissions.includes('iam-site-add') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Add New Site</a>
                                                            </li>
                                                            <li>   <a role="button"
                                                                onClick={() => {
                                                                    if (this.props.permissions.permissions.includes('iam-asset-site-linking-view')) {
                                                                        return this.props && this.props.parentProps && this.props.parentProps.history.push({ pathname: `/assets/site_transition/${this.state?.asset_id}` })
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.permissions.includes('iam-asset-site-linking-view') ? '' : 'disabled'].join(' ')}
                                                                title={!this.props.permissions.permissions.includes('iam-asset-site-linking-view') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Site Transition</a></li>
                                                        </ul>
                                                    </Ax> : null}
                                                {bd.key == "visibility" ?
                                                    <Ax>
                                                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                            <li><a className={"dropdown-item"} role="button" onClick={() => this.linkOperatorsModalInit()}> Link Employees</a></li>

                                                        </ul>
                                                    </Ax> : null}
                                            </div>
                                        </div>
                                        <table className="table table-hover table-borderless mt-1">
                                            {bd.data.map((dt, i) => {
                                                return (<tbody key={i}>
                                                    {dt.display == "N"
                                                        ? null
                                                        :
                                                        <tr>
                                                            <td className="" style={{ "width": "34%" }}>{dt.key}</td>
                                                            <td className="fs14 fw500" style={{ "width": "66%" }}>{dt.key == "Purchased Date" || dt.key == "Year of Commissioning" ? dt.display : dt.val ? dt.val : "-"}</td>
                                                        </tr>}
                                                </tbody>)
                                            })}
                                        </table>
                                    </Ax>)
                                })
                                }
                                {
                                    assetDetailsByKey 
                                    ? 
                                    <Ax>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Site & Workstation</span>
                                            <div className="float-end">
                                            <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                                            <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                <li>
                                                    <a role="button"
                                                        onClick={() => {
                                                            if (this.props.permissions.permissions.includes('iam-asset-site-linking-change')) {
                                                                return this.changeSiteModalInit()
                                                            }
                                                        }}
                                                        className={['dropdown-item', this.props.permissions.permissions.includes('iam-asset-site-linking-change') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props.permissions.permissions.includes('iam-asset-site-linking-change') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Change Site</a>
                                                </li>
                                                <li>
                                                    <a role="button"
                                                        onClick={() => {
                                                            if (this.props.permissions.permissions.includes('iam-site-add')) {
                                                                return this.addSiteModalInit()
                                                            }
                                                        }}
                                                        className={['dropdown-item', this.props.permissions.permissions.includes('iam-site-add') ? '' : 'disabled'].join(' ')}
                                                        title={!this.props.permissions.permissions.includes('iam-site-add') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Add New Site</a>
                                                </li>
                                                <li>   <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.permissions.includes('iam-asset-site-linking-view')) {
                                                            return this.props && this.props.parentProps && this.props.parentProps.history.push({ pathname: `/assets/site_transition/${this.state?.asset_id}` })
                                                        }
                                                    }}
                                                    className={['dropdown-item', this.props.permissions.permissions.includes('iam-asset-site-linking-view') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.permissions.includes('iam-asset-site-linking-view') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                >Site Transition</a></li>
                                                <li><a role="button"
                                                        onClick={() => { this.workstationModalInit() }}
                                                        className={['dropdown-item'].join(' ')}
                                                        style={{ "pointerEvents": "all" }}
                                                    >Link to Workstation</a></li>
                                            </ul>
                                            </div>
                                        </div>
                                        <table className="table table-hover table-borderless mt-1">
                                        <tbody>
                                                <tr>
                                                    <td className="" style={{ "width": "34%" }}>Current Site</td>
                                                    <td className="fs14 fw500" style={{ "width": "66%" }}>
                                                        {assetDetailsByKey && assetDetailsByKey.site_name }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">Date of Receipt (Transition)</td>
                                                    <td className="fs14 fw500" style={{ "width": "66%" }}>
                                                        {assetDetailsByKey && assetDetailsByKey.site_since }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">Workstations</td>
                                                    <td className="fs14 fw500" style={{ "width": "66%" }}>
                                                        {assetDetailsByKey && assetDetailsByKey.hasOwnProperty('workstations') ? <span>{assetDetailsByKey.workstations.map(w => w.workstation_name).join(', ')}</span> 
                                                        : ''}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper mt-2">
                                            <span className="content_heading">Visibility</span>
                                            <div className="float-end">
                                                <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" />
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                                                <li><a className={"dropdown-item"} role="button" onClick={() => this.linkOperatorsModalInit()}> Link Employees</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <table className="table table-hover table-borderless my-2">
                                            <tbody>
                                                <tr>
                                                    <td className="" style={{ "width": "34%" }}>Linked User for Visibility</td>
                                                    <td className="fs14 fw500" style={{ "width": "66%" }}>
                                                        {assetDetailsByKey && assetDetailsByKey.users && assetDetailsByKey.users.length > 0
                                                            ? assetDetailsByKey.users.map(u => u.full_name).join(', ')
                                                            : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">Operator</td>
                                                    <td className="fs14 fw500" style={{ "width": "66%" }}>
                                                        {assetDetailsByKey && assetDetailsByKey.employees && assetDetailsByKey.employees.length > 0 && assetDetailsByKey.operators && assetDetailsByKey.operators.length > 0
                                                            ? assetDetailsByKey.employees.filter((e) => assetDetailsByKey.operators.includes(e.enc_id)).map(o => o.display_full_name).join(', ')
                                                            : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">Supervisor</td>
                                                    <td className="fs14 fw500" style={{ "width": "66%" }}>
                                                        {assetDetailsByKey && assetDetailsByKey.employees && assetDetailsByKey.employees.length > 0 && assetDetailsByKey.supervisors && assetDetailsByKey.supervisors.length > 0
                                                            ? assetDetailsByKey.employees.filter((e) => assetDetailsByKey.supervisors.includes(e.enc_id)).map(s => s.display_full_name).join(', ')
                                                            : '-'
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">Engineer</td>
                                                    <td className="fs14 fw500" style={{ "width": "66%" }}>
                                                        {assetDetailsByKey && assetDetailsByKey.employees && assetDetailsByKey.employees.length > 0 && assetDetailsByKey.engineers && assetDetailsByKey.engineers.length > 0
                                                            ? assetDetailsByKey.employees.filter((e) => assetDetailsByKey.engineers.includes(e.enc_id)).map(eg => eg.display_full_name).join(', ')
                                                            : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Ax> 
                                    : ''
                                }
                            
                        </div>
                    </div>
                }
                {this.editOwnershiplJsx()}
                {this.editDetaillJsx()}
                {this.editSerialNumberJsx()}
                {this.changeSiteModalJsx()}
                {this.editIotIntegrationJsx()}
                {/* {this.linkAssetModalJsx()} */}
                {this.linkWorkstationModalJsx()}
                <SiteAdd ref={this.addSiteRef} />
                <ChangeAssetStatusssss ref={this.ChangeAssetStatusModalRef} afterChangeStatus={() => { this.getBasicAssetDetails(this.state.asset_id); this.props.refreshAssetDetail() }} />
                <ChangeInvoiceRule ref={this.linkAssetRef} getAssetInvoiceListing={() => this.getBasicAssetDetails(this.state.asset_id)} />
                <EditLinkedOperators ref={this.editLinkOperatorsRef} afterSubmitForm={() => this.getBasicAssetDetails(this.state.asset_id)} />
                <InactivateAsset ref={this.InactivateAssetModalRef} afterChangeStatus={() => { this.getBasicAssetDetails(this.state.asset_id); this.props.refreshAssetDetail() }} />
            </section>
            </Ax>
        )
    }

    editOwnershiplJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id={this.ownershipModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Edit Ownership</h5>

                            </div>
                            <div className="modal-body">

                                <form id={`${this.ownershipModalId}Form`} onSubmit={this.submitOwnershipHandler}>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label require">Category</label>
                                        <div className="col-sm-7">
                                            {
                                                this.state.allOwnerShipCategories && this.state.allOwnerShipCategories.length > 0
                                                    ? (this.state.allOwnerShipCategories.map((cat, k) => {

                                                        return (
                                                            <div key={k} className="form-check form-check-inline ">
                                                                <input
                                                                    name="ownership_category"
                                                                    type="radio"
                                                                    value={cat.key}
                                                                    onChange={(e) => this.formInputHandler(e, 'addOwnerForm')}
                                                                    checked={this.state.addOwnerForm.ownership_category == cat.key}
                                                                    className="form-check-input"
                                                                    required={true}
                                                                    id={cat.key}
                                                                />
                                                                <label className="form-check-label" htmlFor={cat.key}>{cat.name}</label>
                                                            </div>);
                                                    }))
                                                    : null
                                            }
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-3">
                                        <label className="col-sm-3 col-form-label require">Owners</label>
                                        <div className="col-sm-7">
                                            {this.state.newOwner == "Y"
                                                ? <input
                                                    name="newOwnerName"
                                                    type="text"
                                                    value={this.state.addOwnerForm.newOwnerName}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addOwnerForm")}
                                                    placeholder="Please enter Owner Name"
                                                    required={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.allOwnersList}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addOwnerForm', 'id')}
                                                    value={this.state.allOwnersList.find(u => this.state.addOwnerForm.id == u.value)}
                                                    isClearable={true}
                                                    placeholder="Select Owner"
                                                    autoCompelete="off"
                                                    required={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="newOwner"
                                                type="checkbox"
                                                value={this.state.newOwner}
                                                onChange={(e) => { this.setState({ newOwner: this.state.newOwner == 'N' ? 'Y' : 'N' }) }}
                                                checked={this.state.newOwner == 'Y'}
                                                className="form-check-input"
                                                id="newOwner"
                                            />
                                            <label className="form-check-label mx-2" htmlFor="newOwner">Add New </label>
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="modal-footer row">
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={`${this.ownershipModalId}Form`}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    editDetaillJsx                  =   ()  =>  {
       
        return (
            <div className="modal fade" id={this.detailModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Edit Detail</h5>

                            </div>
                            <div className="modal-body">

                                <form id={`${this.detailModalId}Form`} onSubmit={this.submitDetailHandler} >
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label require">Asset Name</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="name"
                                                type="text"
                                                value={this.state.editDetailForm.name}
                                                className={['form-control form-control-sm',this.state.showErrors && this.state.showErrors.name ? 'error_border' : ''].join(' ')}
                                                
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editDetailForm")}
                                                onBlur={(e) => this.searchAssetByKey({key : "name" , value:this.state.editDetailForm.name})}
                                                placeholder="Please enter Asset Name"
                                                required={true}
                                                
                                            />
                                            <div className='form-text text-danger'>
                                                {this.state.showErrors && this.state.showErrors.name ? <span>{this.state.showErrors.name} <Link  target="_blank" to={`/asset/details/${this.state.showErrors.id}`}>View Asset</Link></span> : ""}</div>
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label require">Asset Code</label>
                                        <div className="col-sm-7">
                                        <input
                                                name="asset_code"
                                                type="text"
                                                value={this.state.editDetailForm.asset_code}
                
                                                className={['form-control form-control-sm',this.state.showErrors && this.state.showErrors.asset_code ? 'error_border' : ''].join(' ')}
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editDetailForm")}
                                                onBlur={(e) => this.searchAssetByKey({key : "asset_code" , value:this.state.editDetailForm.asset_code})}
                                                placeholder="Please enter Asset Code"
                                                required={true}
                                            />
                                                <div className='form-text text-danger'>
                                                {this.state.showErrors && this.state.showErrors.asset_code ? <span>{this.state.showErrors.asset_code} <Link  target="_blank" to={`/asset/details/${this.state.showErrors.id}`}>View Asset</Link></span> : ""}</div>
                                        </div>
                                        <div className="col-sm-2">
                                           
                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label ">Remarks</label>
                                        <div className="col-sm-7">
                                            <textarea
                                                name="notes"

                                                value={this.state.editDetailForm.notes}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editDetailForm")}
                                                placeholder="Please enter Remarks"

                                            />

                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="modal-footer row">
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={`${this.detailModalId}Form`}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    editSerialNumberJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id={this.serialNumberId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Edit Serial Number</h5>

                            </div>
                            <div className="modal-body">

                                <form id={`${this.serialNumberId}Form`} onSubmit={this.submitSerialNumberHandler} >
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Machine Serial No.</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="machine_no"
                                                type="text"
                                                value={this.state.editSerialNumberForm.machine_no}
                                                className={['form-control form-control-sm', this.state.showErrors && this.state.showErrors.machine_no ? 'error_border' : ''].join(' ')}
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editSerialNumberForm")}
                                                placeholder="Please enter Machine Serial No."
                                            />
                                            <div className='form-text text-danger'>
                                                {this.state.showErrors && this.state.showErrors.machine_no ? <span>{this.state.showErrors.machine_no} <Link target="_blank" to={`/asset/details/${this.state.showErrors.id}`}>View Asset</Link></span> : ""}</div>
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Motor Vehicle Registration Number</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="registration_number"
                                                type="text"
                                                value={this.state.editSerialNumberForm.registration_number}
                                                className={['form-control form-control-sm', this.state.showErrors && this.state.showErrors.registration_number ? 'error_border' : ''].join(' ')}
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editSerialNumberForm")}
                                                placeholder="Please enter Motor Vehicle Registration Number"
                                            />
                                            <div className='form-text text-danger'>
                                                {this.state.showErrors && this.state.showErrors.registration_number ? <span>{this.state.showErrors.registration_number} <Link target="_blank" to={`/asset/details/${this.state.showErrors.id}`}>View Asset</Link></span> : ""}</div>
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Engine Serial No</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="engine_number"
                                                type="text"
                                                value={this.state.editSerialNumberForm.engine_number}
                                                className={['form-control form-control-sm', this.state.showErrors && this.state.showErrors.engine_number ? 'error_border' : ''].join(' ')}
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editSerialNumberForm")}
                                                placeholder="Please enter Engine Serial No"
                                            />
                                            <div className='form-text text-danger'>
                                                {this.state.showErrors && this.state.showErrors.engine_number ? <span>{this.state.showErrors.engine_number} <Link target="_blank" to={`/asset/details/${this.state.showErrors.id}`}>View Asset</Link></span> : ""}</div>

                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Auxiliary Engine Number</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="auxiliary_engine_no"
                                                type="text"
                                                value={this.state.editSerialNumberForm.auxiliary_engine_no}
                                                className={['form-control form-control-sm', this.state.showErrors && this.state.showErrors.auxiliary_engine_no ? 'error_border' : ''].join(' ')}
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editSerialNumberForm")}
                                                placeholder="Please enter Auxiliary Engine number"
                                            />
                                            <div className='form-text text-danger'>
                                                {this.state.showErrors && this.state.showErrors.engine_number ? <span>{this.state.showErrors.engine_number} <Link target="_blank" to={`/asset/details/${this.state.showErrors.id}`}>View Asset</Link></span> : ""}</div>

                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Chassis Number</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="chasis_number"
                                                type="text"
                                                value={this.state.editSerialNumberForm.chasis_number}
                                                className={['form-control form-control-sm', this.state.showErrors && this.state.showErrors.chasis_number ? 'error_border' : ''].join(' ')}
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editSerialNumberForm")}
                                                placeholder="Please enter Chassis Number"
                                            />
                                            <div className='form-text text-danger'>
                                                {this.state.showErrors && this.state.showErrors.chasis_number ? <span>{this.state.showErrors.chasis_number} <Link target="_blank" to={`/asset/details/${this.state.showErrors.id}`}>View Asset</Link></span> : ""}</div>

                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">IMEI Number</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="imei_number"
                                                type="text"
                                                value={this.state.editSerialNumberForm.imei_number}
                                                className={'form-control form-control-sm'}
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editSerialNumberForm")}
                                                placeholder="Please enter IMEI Number"
                                            />

                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Year of Manufacturing</label>
                                        <div className="col-sm-7">
                                            <input
                                                name="manufacture_year"
                                                type="text"
                                                value={this.state.editSerialNumberForm.manufacture_year}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "editSerialNumberForm")}
                                                placeholder="Please enter Year of Manufacturing"
                                            />
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Date of Commissioning</label>
                                        <div className="col-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.editSerialNumberForm.commissioing_date
                                                        ? moment(this.state.editSerialNumberForm.commissioing_date).toDate()
                                                        : false
                                                }
                                                name="commissioing_date"
                                                onChange={(value, event) => { this.formDateHandler("commissioing_date", value, 'editSerialNumberForm') }}

                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete='off'
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Date of Commissioning`}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                            />
                                        </div>

                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Purchased Date</label>
                                        <div className="col-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.editSerialNumberForm.purchase_date
                                                        ? moment(this.state.editSerialNumberForm.purchase_date).toDate()
                                                        : false
                                                }
                                                name="purchase_date"
                                                onChange={(value, event) => { this.formDateHandler("purchase_date", value, 'editSerialNumberForm') }}

                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete='off'
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Purchased Date`}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                alt="requisition_date"
                                                className="add_calender-icon"
                                            />
                                        </div>

                                        <div className="col-sm-2">

                                        </div>
                                    </div>

                                </form>

                            </div>
                            <div className="modal-footer row">
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={`${this.serialNumberId}Form`}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeSiteModalJsx                  =   ()  =>  {
      
        return (
            <div className="modal fade" id='siteTransitionModal' tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Change Site</h5>

                            </div>
                            <div className="modal-body">

                                <form id={`changeSiteModalForm`} onSubmit={this.submitChangeSiteHandler} >
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Current Site</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                options={this.state.iam_user_sites}
                                                value={this.state.iam_user_sites.find(u => this.state.currentSiteId == u.value)}
                                                isClearable={true}
                                                placeholder="Select Site"
                                                autoCompelete="off"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                isDisabled={true}
                                            />
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label require">Shifted to</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                options={this.state.iam_user_sites}
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'siteTransitionForm', 'site_id')}
                                                value={this.state.iam_user_sites.find(u => this.state.siteTransitionForm.site_id == u.value)}
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder="Select Shifted to Site"
                                                autoCompelete="off"
                                                containerHeight="30px"
                                                fontSize="93%"
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <label className="col-sm-3 col-form-label require">Date of Transition</label>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.siteTransitionForm.transaction_date
                                                        ? moment(this.state.siteTransitionForm.transaction_date, 'YYYY-MM-DD HH:mm:ss').toDate()
                                                        : false
                                                }
                                                name="transaction_date"
                                                onChange={(value, event) => this.formDateHandler('transaction_date', value, 'siteTransitionForm','YYYY-MM-DD HH:mm:ss')}
                                                dateFormat="dd-MMM-yyyy hh:mm a"
                                                className={"form-control form-control-sm"}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                timeIntervals={5}
                                                timeCaption="Time"
                                                autoComplete="off"
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter Transaction Date`}
                                                required={true}
                                                maxDate={moment(new Date(),'YYYY-MM-DD').toDate()}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                                            <div className='form-text'>Transition date can be any date after the latest CounterLog {this.state.assetData && this.state.assetData.latest_counterlog && this.state.assetData.latest_counterlog.feed_date_time_display ? <span>({this.state.assetData.latest_counterlog.feed_date_time_display})</span> : "-"} and after the previous transition date</div>
                                        </div>
                                        <div className="col-sm-2">
                                                
                                        </div>
                                    </div>
                                    {this.props.permissions.group_modules.includes("hrm") &&
                                        <div className="row align-items-center my-2">
                                            <label className="col-sm-3 col-form-label">Employee</label>
                                            <div className="col-sm-7">
                                                <TapSelect
                                                    options={this.state.allLinkedEmployeeList}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'siteTransitionForm', 'moving_employee_ids')}
                                                    value={this.state.allLinkedEmployeeList.filter(u => this.state?.siteTransitionForm?.moving_employee_ids?.includes(u.value))}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    placeholder="Select Employee"
                                                    autoCompelete="off"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                            <div className="col-sm-2">

                                            </div>
                                        </div>}
                                        <div className="row align-items-center my-2">
                                            <label className="col-sm-3 col-form-label">Users</label>
                                            <div className="col-sm-7">
                                                <TapSelect
                                                    options={this.state.allLinkedUsers}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'siteTransitionForm', 'moving_user_ids')}
                                                    value={this.state.allLinkedUsers.filter(u => this.state?.siteTransitionForm?.moving_user_ids?.includes(u.value))}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    placeholder="Select Users"
                                                    autoCompelete="off"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                            <div className="col-sm-2">

                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className='form-text offset-3 col-sm-7'>
                                                <sup>*</sup>All Linked Sub Assembly will be transferred to this new Site
                                            </div>
                                        </div>
                                </form>

                            </div>
                            <div className="modal-footer row">
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={`changeSiteModalForm`}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    linkAssetModalJsx                  =   ()  =>  {
      
        return (
            <div className="modal fade" id='linkAssetsModal' tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Link Asset</h5>

                            </div>
                            <div className="modal-body">

                                <form id={`changeSiteModalForm`}  >
                                    <div className="row  my-2">
                                        <label className="col-sm-3 col-form-label">Asset</label>
                                        <div className="col-sm-7">
                                            <TapSelect
                                                isClearable={true}
                                                placeholder="Search Asset"
                                                autoCompelete="off"
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                            <div>
                                               <span className='my-1'> Asset Code : <b>Ab Wheel Loader</b></span> <br/>
                                               <span> Site : <b>Ghatampur</b></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>
                                    
                                    <div className="tab_content_wrapper"> <span className="content_heading">Linked above asset as child</span></div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Hour Meter</label>
                                        <div className="col-sm-4">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                placeholder="Hour Meter"
                                            />
                                        </div>
                                        <div className="col-sm-5">
                                            <TapSelect
                                            placeholder="Select"
                                            autoCompelete="off"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            />
                                        </div>
                                       
                                        
                                    </div>
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label">Odo Meter</label>
                                        <div className="col-sm-4">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                placeholder="Odo Meter"
                                            />
                                        </div>
                                        <div className="col-sm-5">
                                            <TapSelect
                                            placeholder="Select"
                                            autoCompelete="off"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            />
                                        </div>
                                       
                                        
                                    </div>
                                  {/* <TapSelect
                                            options={this.state.allExpenseHead}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'expenseSearchForm', 'search_expense_heads')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allExpenseHead.filter(s => this.state.expenseSearchForm.search_expense_heads.includes(s.value))}
                                            placeholder="Select Expense Head"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        /> */}
                                   
                                </form>

                            </div>
                            <div className="modal-footer row">
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form={`changeSiteModalForm`}>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    workstationModalInit        =   ()  => {

        this.setState({
            searchedWorkstation : this.state.assetDetailsByKey && this.state.assetDetailsByKey.workstations && this.state.assetDetailsByKey.workstations.length > 0 && this.state.assetDetailsByKey.workstations.map((w) => {
                return {
                    label : w.workstation_name,
                    value : w.id,
                }
            })
        }, () => {
            this.linkWorkstationModal.show();
        });
    }   

    linkWorkstationSubmit       =   (e)  =>  {
        e.preventDefault()
        let frmData = {...this.state.linkToWorkstation, asset_id  :   this.state.asset_id};
       
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('POST', this.workstationLinkAssetUrl, this.props.access_token, null, {...frmData}, (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.getBasicAssetDetails(this.state.asset_id);
            this.initalizeData(this.props);
            this.linkWorkstationModal.hide();
        }).then(() => this.setState({saveFormSubmitting: false}))
    }        

    linkWorkstationModalJsx     =   ()  =>  {
        
        return (
            <div className="modal" id='linkWorkstationModal' tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="bg-white p-1" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">Link to Workstation</h5>
                            </div>
                            <div className="modal-body">
                                <form id='link-workstation' onSubmit={this.linkWorkstationSubmit}>
                                    <div className="row  my-2">
                                        <label className="col-sm-3 col-form-label">Site</label>
                                        <div className="col-sm-7">
                                            <input className='form-control' value={this.state.assetDetailsByKey && this.state.assetDetailsByKey.site_name } disabled placeholder='Site Name' />
                                        </div>
                                    </div>
                                    <div className="row  my-2">
                                        <label className="col-sm-3 col-form-label">Workstation</label>
                                        <div className="col-sm-7">
                                        <InputWorkstationSearch
                                            placeholder="Please Select Workstation"
                                            changeEvent={(selectedOption) => {
                                                this.setState({ 
                                                    searchedWorkstation: selectedOption ? selectedOption : '' 
                                                });
                                                this.tapSelectChange(selectedOption, 'linkToWorkstation', 'workstation_ids')
                                            }}
                                            isMulti={true}
                                            search_site_id={this.state.assetDetailsByKey && this.state.assetDetailsByKey.site_id}
                                            value={this.state.searchedWorkstation && this.state.searchedWorkstation.length > 0 ? this.state.searchedWorkstation.map(a => { a.label = a.label; return a; }) : []}
                                        />
                                        </div>
                                        <div className="col-sm-2">

                                        </div>
                                    </div>                                
                                </form>
                            </div>
                            <div className="modal-footer row">
                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form='link-workstation'>Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
   
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app && state.app.acl_info ? state.app.acl_info : null,
        asset_param             :       state.app && state.app.asset_search_param ? state.app.asset_search_param : {},
        asset_listing_loaded    :       state.app && state.app.asset_listing_loaded ? state.app.asset_listing_loaded : false,
        asset_listing           :       state.app && state.app.asset_listing ? state.app.asset_listing : [],
        asset_meta              :       state.app && state.app.asset_meta ? state.app.asset_meta : null,
        asset_total_count       :       state.app && state.app.asset_total_count ? state.app.asset_total_count : 0,
        iam_user_sites          :       state.app && state.app.user_sites ? state.app.user_sites : []
    }  
}

const mapDispatchToProps = dispatch => {
    return {
        onListingSuccess        :   (page,searchParm,cl_attribute,formSearchedElems) => dispatch(actions.assetListingDataLoading(page,searchParm,cl_attribute,formSearchedElems)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(AssetDetails);
