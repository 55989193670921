import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


const ReportMenu     = forwardRef((props, ref) => {

    return  <ul className="nav nav-tabs">
        <li className="nav-item">
            <Link to='/report' className={['nav-link', 'text-color' , props.page == 'report' ? 'active' : '' ].join(" ")}> Reports </Link>
        </li>
        <li className="nav-item">
            <Link to='/report/download_request' className={['nav-link', 'text-color' , props.page == 'download_request' ? 'active' : '' ].join(" ")}> Download Request</Link>
        </li>
        <li className="nav-item">
            <Link to='/report/schedule_report' className={['nav-link', 'text-color' , props.page == 'schedule_report' ? 'active' : '' ].join(" ")}> Scheduled Reports</Link>
        </li>
    </ul>
});

export default ReportMenu;