import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import ConsumptionDetailsModal from '../../inventory/consumption/ConsumptionDetailsModal';
import Status from '../../../components/ui/Status';
import { Helmet } from 'react-helmet';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemWiseConsumption extends AppBaseComponent {
    
    constructor(props) {
        super(props);
                
        this.reportFilterFormInitialState   =   {
            search_warehouse_ids                :   [],
            search_workstation_ids              :   [],
            search_asset_ids                    :   [],
            search_item_ids                     :   [],
            search_batch_number                 :   '',
            search_voucher_number                 :   '',
            date_range                          :   'current_quarter',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_item_tags                    :   [],
            search_status_ids                   :   [],
            search_employee_ids                 :   [],
            search_transaction_id               :   ''
        };
        
        this.reportFilterFormLabel          =   {
            search_warehouse_ids                :   'Warehouse : ',
            search_workstation_ids              :   'WorkStation : ',
            search_asset_ids                    :   'Asset : ',
            search_item_ids                     :   'Items : ',
            search_transaction_id               :   'Transaction ID : ',
            search_batch_number                 :   'Search Batch Number :  ',
            search_voucher_number               :   'Search Voucher Number :  ',
            date_range                          :   'Transaction Period :  ',
            search_item_tags                    :    'Item Tags : ',
            search_status_ids                   :     'Search Status : ',
            search_employee_ids                 :   'Employees : ',
        };
        
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            isc_configuration                   :   {},
            all_warehouses                      :   [],
            iam_user_sites                      :   [],
            reportFilterFormData                :   {periods : [], workstations : []},
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            searchedAssets                      :   [],
            listingData                         :   [],
            allItemTags                         :   [],
            allStatusOptions                    :   [],
            listingMeta                         :   null,
            consumptionId                       :   null,
            searchedEmployees                   :   [],
            download_access                     :   false,    
            reportData                          :   null,                 
        };

        this.formDataUrl        =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-consumption/form_data';
        this.reportUrl          =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-consumption';

    }
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
        this.viewConsumptionModal              =     new Modal(document.getElementById('viewConsumptionModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {
        const report_name   =   props?.location?.pathname ? props.location.pathname.split('/')[2] : '';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        HttpAPICall.withAthorization('GET', this.formDataUrl, props.access_token, null, null, (response) => {
            let workstations                =   response.data.workstations.map((w) => {
                                                    return {value: w.id, label: `${w.workstation_name} (${w.workstation_nickname})`}
                                                });
            this.setState({
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                reportFilterFormData        :   {...response.data , workstations : workstations},
                all_warehouses              :   props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                iam_user_sites              :   props.iam_user_sites ? props.iam_user_sites.map((s) => {
                                                    return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                isc_configuration           :   props.isc_configuration,
                allItemTags                 :   props.item_tags && props.item_tags.length > 0 ? props.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                formDataLoaded              :   true
            },  () => {
               
                let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                if (ParamObject.get('date_range_start') || ParamObject.get('search_item_ids')) {
                    let updateFilterForm        =        {};
                    if (ParamObject.get('date_range_start')) {
                        updateFilterForm        =        {
                            date_range_start        :       ParamObject.get('date_range_start'),
                            date_range_end          :       ParamObject.get('date_range_end'),
                            date_range              :       "custom",
                        };


                        if (ParamObject.get('search_warehouse_ids')) {
                            let warehouse           =        this.state.all_warehouses.find(us => us.value == ParamObject.get('search_warehouse_ids'));

                            if (warehouse) {
                                updateFilterForm['search_warehouse_ids']     =      [warehouse.value];

                            }
                        }

                    }

                    if (ParamObject.get('search_item_ids')) {    
                        updateFilterForm['search_item_ids']         =       [ParamObject.get('search_item_ids')];   
                    }

                    setTimeout(() => {
                        this.setState({
                            reportFilterForm        :       { ...this.state.reportFilterForm, ...updateFilterForm },
                            searchedItem            :       [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }]
                        }, () => {
                            this.reportFilterFormSubmit()
                        })
                    }, 1000)
                } else if (ParamObject.get('asset_id')) {

                    let updateFilterForm        =            { date_range: "current_year", search_asset_ids: [ParamObject.get('asset_id')] };

                    setTimeout(() => {
                        this.setState({
                            reportFilterForm        :       { ...this.state.reportFilterForm, ...updateFilterForm },
                            searchedAssets          :       [{
                                value               :        ParamObject.get('asset_id'),
                                display_label       :       `${ParamObject.get('asset_name')} (${ParamObject.get('asset_code')})`
                            }]
                        },
                            () => this.reportFilterFormSubmit());
                    }, 1000)

                } else {
                    this.reportFilterhandler()
                }

            });
            
        }, (err) => {

        });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
       
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_workstation_ids') {
                        show_val            =   this.state.reportFilterFormData.workstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_batch_number') {
                        show_val            =  this.state.reportFilterForm.search_batch_number;
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_voucher_number') {
                        show_val            =  this.state.reportFilterForm.search_voucher_number;
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.reportFilterForm.search_transaction_id;
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'date_range') {
                        let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                        
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.reportFilterFormData.periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date && !ParamObject.get('date_range_start') ? s.display_with_date : display_custom)
                        // show_val            = this.state.reportFilterFormData.periods.filter((s) => value.includes(s.key)).map(s => );
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm,for_report:'N'};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    viewConsumptionModalInit                =   (id)        =>      {
        this.setState({
               consumptionId       : id
        })
        this.viewConsumptionModal.show()
       
        
    }
    
    reportDownloadApiCall                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(null,response.data.navgation,response.data.msg,this.props)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faPallet} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || !this.state.download_access} type="button" onClick={this.reportDownloadApiCall} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            options={this.state.all_warehouses}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.all_warehouses.filter(t => this.state.reportFilterForm.search_warehouse_ids.includes(t.value))}
                            placeholder="Please Select Warehouse"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Workstation</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_workstation_ids')}
                            options={this.state.reportFilterFormData.workstations}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.reportFilterFormData.workstations.filter(t => this.state.reportFilterForm.search_workstation_ids.includes(t.value))}
                            placeholder="Please Select Workstation"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Employees</label>
                    <div className="col-sm-6">
                        <InputEmployeeSearch
                            changeEvent={(selectedOption) => {
                                
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_employee_ids');
                                this.setState({
                                    reportFilterForm  : {...this.state.reportFilterForm,search_employee_ids : selectedOption.length > 0 ? selectedOption.map(o => o.value) : []},
                                    searchedEmployees : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"

                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Search Transaction ID"
                        />
                    </div>
                </div>
                {this.state.isc_configuration && this.state.isc_configuration.track_batch_expiry === "N" ? null : <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Batch Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_batch_number"
                            type="text"
                            value={this.state.reportFilterForm.search_batch_number}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Batch Number"
                        />
                    </div>
                </div>}
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Voucher Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_voucher_number"
                            type="text"
                            value={this.state.reportFilterForm.search_voucher_number}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Voucher Number"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Transaction Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.reportFilterFormData && this.state.reportFilterFormData.periods ? this.state.reportFilterFormData.periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            // required={true}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm    :   {...this.state.reportFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate}
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className="text-center" style={{width:"12%"}}>Item Name</th>
                        <th className="text-center" style={{width:"10%"}}>Item Code</th>
                        <th className='text-start' style={{width:"7%"}}>Transaction ID</th>
                        <th className='text-start' style={{width:"6%"}}>Transaction Date</th>
                        <th className="text-center" style={{width:"12%"}}>Warehouse</th>
                        <th className="text-center" style={{width:"15%"}}>Consumption for</th>
                        <th scope="col" style={{ width: "8%" }} > Status</th>
                        <th scope="col" style={{ width: "4%" }} > Qty</th>
                        <th scope="col" style={{ width: "7%" }} > Amount</th>
                    </tr>
                </thead>
                <tbody>
                {this.state.listingData && this.state.listingData.length > 0
                    ? (this.state.listingData.map((rowData,k) => {
                         return (<tr>
                                    <td>{rowData.item_name}</td>
                                    <td>{rowData.item_code}</td>
                                    <td><a role="button" className="link-primary" onClick={() => this.viewConsumptionModalInit(rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                    <td>{rowData.transaction_date_display}</td>
                                    <td>{rowData.warehouse_name}</td>
                                    <td>
                                        {rowData.consumption_type == 'asset'
                                            ? (rowData.assetProfile ? rowData.assetProfile.name + ' ' : '')
                                            : null
                                        }
                                        {rowData.consumption_type == 'workstation'
                                            ? (rowData.workstation ? rowData.workstation.workstation_name + ' ' : '')
                                            : null
                                        }
                                        {rowData.consumption_type == 'employee'
                                            ? (rowData.employee ? rowData.employee.display_full_name + ' ' : '')
                                            : null
                                        }
                                        {rowData.consumption_type == 'sub_assembly'
                                            ? (rowData.subAssembly ? rowData.subAssembly.name + ' ' : '')
                                            : null
                                        }
                                        <span className='form-text text-xs'> ({rowData.consumption_type == 'asset' ? 'Asset' : rowData.consumption_type == 'workstation' ? 'Workstation' : rowData.consumption_type == 'sub_assembly' ? 'Sub Assembly' : "Employee"})</span>
                                    </td>
                                    <td>{rowData.status && rowData.status.name ? rowData.status.name : "-"}</td>
                                    <td className="text-center">{rowData.qty}</td>
                                    <td className="text-end">{rowData.amount}</td>
                                </tr>);
                            
                        }))
                    : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                }
                </tbody>
            </table>
        </Ax>);
    }
    
    consumptionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewConsumptionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Consumption Details</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                  <ConsumptionDetailsModal consumptionId={this.state.consumptionId}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>ItemWise Consumption - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                        ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader  />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    </div>
                </div>
            </div>
            {this.consumptionDetailsModalJsx()}
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ItemWiseConsumption);