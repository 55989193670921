import React from 'react';
import Ax from "../../../components/hoc/Ax";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Status from '../../../components/ui/Status';

class EmployeeCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Ax>
                <div className=" col-3 page_containt  pl4 pr4 "
                    style={{ top: "0px", height: "81vh" }}>
                    <div className="pageTbl  bg-white" style={{ overflowY: "auto" }}>
                        {this.props.employeeData && (
                            <table className="table table-hover  bg-white p-0">
                                <tbody>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%", maxHeight: "14em" }}>
                                            <img src={this.props.employeeData?.image ? this.props.employeeData?.image : "No Image Available"} className="img-fluid d-block w-100" alt="image" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                                            Employee Name  :<br />
                                            <div className="pt-2">
                                                <Link > {this.props.employeeData.name ? this.props.employeeData.name : "-"}</Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Employee Code :<br />
                                            <div className="pt-2"> <b> {this.props.employeeData.employee_code
                                                ? this.props.employeeData.employee_code
                                                : "-"}</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Email  : <br />
                                            <div className="pt-2"> <b>  {this.props.employeeData.email ? this.props.employeeData.email : "-"}</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Mobile  : <br />
                                            <div className="pt-2"> <b>  {this.props.employeeData.mobile ? this.props.employeeData.mobile : "-"}</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Tags  : <br />
                                            <div className="pt-2"> <b> {this.props.employeeData.tags && this.props.employeeData.tags.length > 0 ? this.props.employeeData.tags.map(st => st).join(', ') : "-"}</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Site  : <br />
                                            <div className="pt-2"> <b>{this.props.employeeData.site ? this.props.employeeData.site.site_name : "-"}</b></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Status  : <br />
                                            <div className="pt-2"> <b> <Status color={this.props.employeeData.site?.status_color_code} className="mt10"><b>{this.props.employeeData.site?.status_text}</b></Status></b></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>

            </Ax>
        )
    }
}




export default EmployeeCard
