import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import Ax from "../../../components/hoc/Ax";
import { Link } from 'react-router-dom';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { toast } from 'react-toastify';

class ProductivityView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                      =             {
            productivityData            :            null, 
            viewDataLoading             :           false,       
        }
       
    }

    componentDidMount() {
           this.intializeData(this.props) 
           if(this.props.productivityId){
               this.getProductivityDetail(this.props.productivityId,this.props.counterlog)
           }   
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.productivityData != nextProps.productivityData) {
            this.intializeData(nextProps);
        }
        if(this.props.productivityId !== nextProps.productivityId){
            this.getProductivityDetail(nextProps.productivityId,nextProps.counterlog)
        }
    }

    intializeData       =       (pr)      =>      {
        this.setState({access_token : pr.access_token,productivityData : pr.productivityData})
    }

    getProductivityDetail                 =   (id,counterlog = null)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/productivity/' + id, this.props.access_token, {need_user : 'Y' }, {}, (response) => {
            this.setState({
                productivityData : {...response.data.data,counterlog : counterlog ? counterlog :  null}
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
     }

    render                              =   ()  =>  {
        let productivity = this.state.productivityData;
    
        return (<Ax>
            {this.state.viewDataLoading ? <Loader />
                : <Ax>

                    {productivity
                        ?
                        <Ax>
                            <div className="tab_content_wrapper"> <span className="content_heading">Productivity Detail</span></div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-1 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>Activity Date</td>
                                        <th>{productivity.counterlog && productivity.counterlog.feed_date_display ? productivity.counterlog.feed_date_display : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Activity</td>
                                        <th>{productivity.activityData && productivity.activityData.activity_name ? productivity.activityData.activity_name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Net Productivity</td>
                                        <th>{productivity.production ? `${productivity.production} ${productivity.production_unit}` : 0}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>No. of Trip / Session</td>
                                        <th>{productivity ? productivity.activity_count : ""}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Material Name</td>
                                        <th>{productivity.materialData && productivity.materialData.material_name ? productivity.materialData.material_name : "-"}</th>
                                    </tr>

                                    <tr>
                                        <td style={{ width: "25%" }}>Workstation</td>
                                        <th>{productivity.workStationdata ? `${productivity.workStationdata.workstation_name} (${productivity.workStationdata.workstation_nickname})` : "-"}</th>
                                    </tr>
                                    {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes("hrm") ?
                                        <tr>
                                            <td style={{ width: "25%" }}>Employee Name</td>
                                            <th>{productivity.operators && productivity.operators.length > 0  ? productivity.operators.map(op => {return(op.display_full_name)}).join(', ')  : "-"}</th>
                                        </tr> : null}
                                    <tr>
                                        <td style={{ width: "25%" }}>Attachment</td>
                                        <th>{productivity.attachmentData && productivity.attachmentData.name ? productivity.attachmentData.name : "-"}</th>
                                    </tr>
                                    {productivity.additional_attribute_list && productivity.additional_attribute_list.length > 0 ?
                                        productivity.additional_attribute_list.map((attribute, index) => {
                                            return (<tr>
                                                <td style={{ width: "25%" }}>{attribute.name}</td>
                                                <th>{attribute.value_display ? attribute.value_display : "-"}</th>
                                            </tr>)
                                        })
                                        : null}
                                    <tr>
                                        <td style={{ width: "25%" }}>Remarks</td>
                                        <th>{productivity.remarks ? productivity.remarks : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper">  <span className="content_heading">Counterlog Details</span></div>
                            <table className="table my-2 table-hover table-bordered">
                                <tbody>
                                    {productivity.counterlog && productivity.counterlog.display && productivity.counterlog.display.length > 0
                                        ? productivity.counterlog.display.map(counterlog => {
                                            return (<tr>
                                                <td style={{ "width": "25%" }}>{counterlog.label}</td>
                                                <th>{counterlog.reading} {counterlog.unit}  </th>
                                            </tr>)
                                        }) : (<tr>
                                            <td>Counterlog</td>
                                            <th>NA </th>
                                        </tr>)}
                                    <tr>
                                        <td>Site</td>
                                        <th >{productivity.counterlog && productivity.counterlog.site && productivity.counterlog.site.site_name ? productivity.counterlog.site.site_name : "-"}  </th>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper"> <span className="content_heading">Transaction  Detail</span></div>
                            <table className="table my-2 table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Transaction ID</td>
                                        <th>{productivity.activity_id ? productivity.activity_id : '-'}   </th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Date of Transaction</td>
                                        <th>{productivity.counterlog && productivity.counterlog.feed_date_display ? productivity.counterlog.feed_date_display : "-"}   </th>
                                    </tr>

                                    <tr>
                                        <td>Executed By</td>
                                        <th>{productivity.user ? productivity.user.full_name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ "width": "25%" }}>Date of Entry</td>
                                        <th >{productivity.created_at_display ? productivity.created_at_display : '-'}   </th>
                                    </tr>
                                </tbody>
                            </table>
                        </Ax>
                        : null}

                </Ax>}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};

export default connect(mapStateToProps)(ProductivityView);