import React, {createRef} from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import AssetHeader from "../../includes/assetHeader/AssetHeader";
import AssetHorizontalCard from "../../includes/assetHorizontalCard/AssetHorizontalCard";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";


export default class AssetActivityList extends React.Component {

    measurableAssetId = localStorage.getItem('MeasurableAsset')
    constructor() {
        super();
        this.state = {
            minimizeTable               :         false,
            scrollDown                  :         false,
            goto_top_visible            :         false,
            scrollTop                   :         0,
            assetCardDetails             :        [],
            activityListing            :          [],
            listingMeta                 :         [],
            listing_loading             :        false,
            page                        :          1,
           activityData                 :          [],
            scrollHeight                :         0,
            opacity: '1'
        }
       /* this.scrolltoServiceChecksheet  =        createRef();
        this.scrollToTop                =        createRef();
        this.myassetRef                       =    React.createRef();*/
        this.showScrollButton           =       this.showScrollButton.bind(this)
    }

    showScrollButton(){
        this.setState({scrollDown:true})
    }
    hideScrollButton(){
        this.setState({scrollDown:false})
    }
   /* onScrollAsset = () => {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myassetRef.current.scrollTop
        this.setState({
            scrollTop: scrollTop
        })
       if(scrollTop >=200 ){
           this.setState({scrollDown:true,scrollHeight : 300})
       }else{
           this.setState({scrollDown:false})
       }


    }*/
    getAssetCard(id){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/asset/full_detail/' + id
        }).then((response) => {
            this.setState({assetCardDetails: response.data.data})
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        })
    }

    getAssetActivityListing(page){
        this.setState({listing_loading: true})
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            params:{asset_ids : [this.measurableAssetId],page: page,pagination: true,report_key: "asset_activity"},
            url: process.env.REACT_APP_API_SERVER + '/iam/report_data'
        }).then((response) => {
            this.setState({activityListing: response.data.data,listingMeta : response.data})
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    getActivityData(id,tranId){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/asset/shift_counter_log/activity/' + tranId + "/" + id
        }).then((response) => {
            this.setState({activityData : response.data.data})
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        })
    }

    componentDidMount() {
        this.getAssetCard(this.measurableAssetId)
        this.getAssetActivityListing(this.state.page)

    }
    viewActivity(id,transId){
        this.setState({minimizeTable:true})
        this.getActivityData(id,transId)
    }


    //handle pagination
    handlePageClick = (e) => {
        this.setState({page: e.selected + 1})
        this.state.page = e.selected + 1;
        this.getServiceHistoryListing(this.state.page);
    }

    //function to close
    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false})
    }

    linkToAssetProfile                  =   ()  =>  {
        this.props.history.push(`/measurable_asset_view?id=${this.measurableAssetId}`)
    }

    activityExceldownload                   =   ()  =>  {
        swal({
            title: "Download",
            text: "The download will be for trailing three months period, in case you want a different criteria then please use search button before downloading.",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    this.excelDownloadConfirm()
                }
            });
    }

    excelDownloadConfirm   =    ()  => {
        const access_token                      =   localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url         :   process.env.REACT_APP_API_SERVER + '/iam/report_data',
            params      :   {asset_ids : [this.measurableAssetId],report_key : 'asset_activity', download_report: 'download_report'}
        }).then((response) => {
            download.file(response.data.file_path);
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        })
    }

    assetActivityListjsx    =   ()  =>  {
        const details = this.state.assetCardDetails
        return (<Ax>
            <div className="row asset_title m0">
                <div className="col-12 tap_border_left">
                    <AssetHeader assetName={details?.asset_name}  close={this.closeMinimizeTable} parentProps={this.props}/>
                </div>
                {this.state.scrollDown === true ? <div className="container-fluid pl5 p4">
                    <div className="bg-white asset_card_container asset_card_scroll_container" style={{marginTop : "12px"}}  >
                        <AssetHorizontalCard assetDetails={this.state.assetCardDetails}/>
                    </div>
                </div> : null }
                <div className="container-fluid pl5 pt0 ">
                    <div className="  " style={{marginTop : this.state.scrollDown === true ? "2px" : "14px"}}>
                        {this.state.scrollDown === false ? <div className="asset_card_container">
                                <div className="asset_card_tag">
                                    <button type="button" className="btn_action_collapse btn-collapse"   onClick={this.showScrollButton.bind(this)}  >
                                        Asset Card
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretDown} className="img-fluid ml5"   alt="item-action" />
                                    </button>
                                </div>
                            </div>:
                            <div className="asset_card_tag_up">
                                <button type="button" className="btn_action_collapse btn-collapse"   data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={this.hideScrollButton.bind(this)} >
                                    Asset Card
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretUp} className="img-fluid ml5"   alt="item-action" />
                                </button>
                            </div>}
                    <div className="asset_title_header row " style={{marginTop : this.state.scrollDown == true ? "2px" : "14px"}}>
                        <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                            {
                                this.state.minimizeTable ?
                                    <div className="row ">
                                        <div className="col-8"><h3>Asset Activity List</h3></div>
                                        <div className=" col-4   text-end  ">
                                            <button type="button" id='actionMenuButton' className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button"  onClick={this.activityExceldownload}>
                                                    Download
                                                </a></li>
                                            </ul>
                                        </div>

                                    </div> :
                                    <div className="row ">
                                        <div className="col-5"><h3>Asset Activity List</h3></div>
                                        <div className=" col-7 text-end ">
                                            <button type="button" className="btn btn-secondary">
                                                <tapIcon.FontAwesomeIcon icon={tapIcon.SearchIcon} className="img-fluid mr3" alt="item-action"/>
                                                Search</button>
                                            <button type="button" id='actionMenuButton' className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button"  onClick={this.activityExceldownload}>
                                                    Download
                                                </a></li>
                                            </ul>
                                            <button  type="button" className="btn btn-secondary"  onClick={this.linkToAssetProfile}>
                                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/></button>
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            this.state.minimizeTable
                                ? (
                                    <div className="col-9 tap_border_left">
                                        <h3>{this.state?.activityData?.activity_name}</h3>
                                        <div className="text-end">
                                            <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                            </button>
                                        </div>
                                    </div>
                                )
                                : null
                        }
                    </div>
                    </div>
                    <div className="action_containt row">
                        <div  style={{height:this.state.scrollDown == true ? "49vh" : "75vh"}}
                              className={['action_Tbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.assetActivityListingTable()}</div>
                        { this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.activityViewJsx()}</div> : null }
                    </div>
                </div>
            </div>
        </Ax>);
    }

    assetActivityListingTable                   =   ()  =>  {
       let details = this.state.activityListing
        return (
            <Ax>
                <table className="table table-bordered bg-white table-hover table-fixed">
                                <thead className="table-secondary">
                                { this.state.minimizeTable
                                    ? (<tr>
                                        <th scope="col" style={{width:"20%"}}>Activity</th>
                                    </tr> )
                                    :   ( <tr>
                                        <th scope="col" style={{width:"5%"}} className="text-center">S.No</th>
                                        <th scope="col" style={{width:"10%"}}>Transaction Date</th>
                                        <th scope="col" style={{width:"20%"}}>Activity Name</th>
                                        <th scope="col" style={{width:"12%"}}>No. of Trips/Sessions</th>
                                        <th scope="col" style={{width:"15%"}}>Net Productivity with Units</th>
                                        <th scope="col" style={{width:"10%"}}>Material</th>
                                        <th scope="col" style={{width:"13%"}}>Workstation (From-To)</th>
                                        <th scope="col" style={{width:"10%"}}>Counterlog Reading</th>
                                        <th scope="col" style={{width:"5%"}}>Action</th>
                                    </tr> )
                                }

                                </thead>
                                <tbody className={ this.state.minimizeTable ? "minimize_body" : "" } >
                                { this.state.listing_loading
                                    ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                                    : details?.map((item,index) => {
                                        return<tr>
                                            {
                                                this.state.minimizeTable
                                                    ?   (
                                                        <Ax>
                                                            <td style={{width:"25%"}} onClick={() => this.viewActivity(item?.activity?.activity_id,item?.counter_log?.tranx_id)}>
                                                                <div className="mb-1">
                                                                    <span className="text-capitalize link-primary ">{item.activity.activity_name ? item.activity.activity_name : ''} </span>
                                                                    <small className="float-end">{item?.activity.no_of_trips_Session} ({item?.activity.activity_type_name})</small>
                                                                </div>
                                                                <div>
                                                                    <small>{item?.counter_log?.feed_date}</small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    :   (<Ax>
                                                            <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index }</td>
                                                            <td >{item?.counter_log?.feed_date}</td>
                                                            <td className="text-capitalize"><a role="button" className="link-primary" onClick={() => this.viewActivity(item?.activity?.activity_id,item?.counter_log?.tranx_id)}>
                                                                {item.activity.activity_name ? item.activity.activity_name : ''}</a></td>
                                                            <td>{item?.activity.no_of_trips_Session} ({item?.activity.activity_type_name})</td>
                                                        <td>{item.production.production?item.production.production+' ('+item.production.production_unit+')':'-'}</td>
                                                        <td>{item?.activity?.material_name == ""? "-" : item?.activity?.material_name }</td>
                                                        <td>{item.activity.workstation_from_name} - {item.activity.workstation_to_name}</td>
                                                        <td>{item?.counter_log?.a_hour_reading} <br/>
                                                            <div>{item?.counter_log?.a_odo_reading} </div>
                                                            <div>{item?.counter_log?.a_opc_reading} </div>
                                                        </td>
                                                            <td>
                                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a className="dropdown-item" role="button" onClick={() => this.viewActivity(item?.activity?.activity_id,item?.counter_log?.tranx_id)} >View</a></li>

                                                                </ul>
                                                            </td>

                                                        </Ax>
                                                    )}

                                        </tr>})}
                                </tbody>
                            </table>
                <div className=" m0">
                                { this.state.listing_loading ?  null : <span>{this.state.listingMeta ?
                                    `Showing ${this.state.listingMeta.from||0} to ${this.state.listingMeta.to||0} of
                     ${this.state.listingMeta.total} entries` : ``}</span>}
                                <div className="text-end mt-1">
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        pageCount={this.state.listingMeta?.last_page ?? 0}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={0}
                                        onPageActive={this.state.listingMeta?.current_page ?? 1}
                                        onPageChange={(e) => this.handlePageClick(e)}
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination justify-content-end"
                                        pageClassName="page-item"
                                        nextClassName="page-item"
                                        previousClassName="page-item"
                                        pageLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        activeClassName="active"
                                    />
                                </div>
                            </div>
            </Ax>
        );
    }

    activityViewJsx(){
        let details = this.state.activityData;
        return(<Ax>
            <section className="sticky_tab ">
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane show active" id="home" role="tabpanel" aria-labelledby="dashboard-tab">
                        <div className="tab_content_sticky_header"
                             style={{height:this.state.scrollDown === true ? "49vh" : "75vh"}} >
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">Activity Details</span></div>
                            <table className="table mt-2 table-hover table-borderless">
                                <tbody>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Activity Date</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.activity_date?.substr(0,details?.activity_date.indexOf(' '))}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Activity Type</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.activity_type_name}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>No. of Session or Trip</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.activity_count}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Activity Name</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.activity_name}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2 mb-1">
                                <span className="content_heading">Activity Measurement</span></div>
                            <table className="table mt-2 table-hover table-bordered">
                                <thead className="table-secondary">
                                 <tr className="text-center">
                                     <th scope="col" style={{width:"25%"}}>Activity Measurement Unit</th>
                                     <th scope="col" style={{width:"25%"}}>Starting Reading</th>
                                     <th scope="col" style={{width:"25%"}}>Stopping Reading	</th>
                                     <th scope="col" style={{width:"25%"}}>Total Running</th>
                                 </tr>
                                </thead>
                                <tbody>
                                 <tr className="text-center">
                                     <td className="text-start">Hour Meter</td>
                                     <td>{details?.start_hrm??'-'}</td>
                                     <td>{details?.close_hrm??'-'}</td>
                                     <td>{details?.running_hrm??'-'}</td>
                                 </tr>
                                 <tr className="text-center">
                                     <td className="text-start">Odometer</td>
                                     <td>{details?.start_km??'-'}</td>
                                     <td>{details?.close_km??'-'}</td>
                                     <td>{details?.running_km??'-'}</td>
                                 </tr>
                                 <tr className="text-center">
                                     <td className="text-start">Operation Cycle</td>
                                     <td>{details?.start_opc??'-'}</td>
                                     <td>{details?.close_opc??'-'}</td>
                                     <td>{details?.running_opc??'-'}</td>
                                 </tr>
                                 <tr className="text-center">
                                     <td className="text-start">Time</td>
                                     <td>{details?.time_start == null ? '-' : details?.time_start}</td>
                                     <td>{details?.time_close == null ? '-' : details?.time_close}</td>
                                     <td>{details?.total_time??'-'}</td>
                                 </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">Productivity</span></div>
                            <table className="table mt-2 table-hover table-borderless">
                                <tbody>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Net Productivity</td>
                                    <td className="details-name" style={{"width": "66%"}}>{(details?.production && details?.production.production) ?
                                        (details?.production.production + ' ' + details?.production.production_unit) : 'NA'} </td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Material Name</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.material_name??"-"}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Attachment</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.attachment_name??"-"}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">Logistics / Movement</span></div>
                            <table className="table mt-2 table-hover table-borderless">
                                <tbody>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Site Name</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.site_name??"-"}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>From Location (Workstation)</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.workstation_from_name??"-"}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>To Location (Workstation)</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.workstation_to_name??"-"}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Customer Name</td>
                                    <td className="details-name" style={{"width": "66%"}}>{(details?.customer) ? (details?.customer) : 'NA'}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Operator</td>
                                    <td className="details-name" style={{"width": "66%"}}>{(details?.operator_name) ? (details?.operator_name) : 'NA'} </td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Remarks</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.remarks_view??"-"}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2">
                                <span className="content_heading">Transaction Details</span></div>
                            <table className="table mt-2 table-hover table-borderless">
                                <tbody>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Transaction ID</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.activity_id}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Transaction Date</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.activity_date?.substr(0,details?.activity_date.indexOf(' '))}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Executed By</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.executed_by??"-"}</td>
                                </tr>
                                <tr>
                                    <td className="details-label" style={{"width": "34%"}}>Date of Entry</td>
                                    <td className="details-name" style={{"width": "66%"}}>{details?.created_at_display??"-"}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </Ax>)
    }


    render() {

        let pageScreen = null;
        {
            pageScreen  = this.assetActivityListjsx();
        }
        return (
            <ApplicationLayout>
                {
                    pageScreen
                }

            </ApplicationLayout>
        )
    }
}

