import React from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import uuid from "react-uuid";
import { Modal } from "bootstrap";
import Ax from "../../../../components/hoc/Ax";
import AdditionalAttribute from '../../AdditionalAttribute';
import {ToastContainer, toast} from 'react-toastify';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import TapApiUrls from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import Loader from '../../../../components/ui/Loader/Loader';
import TapIcon from '../../../../services/TapIcon';
import swal from 'sweetalert';
import SubAssemblyDowntimeTab from './SubAssemblyDowntimeTab';

class SubAssemblyCard extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state          =   {

            statusShow          :   false,
            tagShow             :   false,
            attributeShow       :   false,
        }
    }

    componentDidMount               =   ()  =>  {
    }

    render                          =   ()  =>  {
        return (
            <section className="item_view_container bg-white">
                <Helmet><title>Assets Configuration : Downtime</title></Helmet>
                <ToastContainer />
                <ul className="nav nav-tabs" id="myTab">
                    <li className="nav-item" role="downtimeTab">
                        <button className="nav-link active" id="category-tab" type="button" data-bs-toggle="tab" data-bs-target="#category" aria-controls="category" role="tab" aria-selected="false">Category</button>
                    </li>
                    <li className="nav-item" role="downtimeTab">
                        <button className="nav-link" id="status-tab" type="button" data-bs-toggle="tab" data-bs-target="#status" aria-controls="status" role="tab" aria-selected="true" onClick={() => this.setState({ statusShow : true })}>Status</button>
                    </li>
                    <li className="nav-item" role="downtimeTab">
                        <button className="nav-link" id="tags-tab" type="button" data-bs-toggle="tab" data-bs-target="#tags" aria-controls="tags" role="tab" aria-selected="false" onClick={() => this.setState({ tagShow : true })}>Tags</button>
                    </li>
                    <li className="nav-item" role="downtimeTab">
                        <button className="nav-link" id="additional-tab" type="button" data-bs-toggle="tab" data-bs-target="#tab_2" aria-controls="tab_2" role="tab" aria-selected="false" onClick={() => this.setState({ attributeShow : true })}>Additional Attribute</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane show active" id="category" aria-labelledby="category-tab">
                        <div className="tab_content_header">
                            <SubAssemblyDowntimeTab type="Category" url = "category"/>
                        </div>
                    </div>

                    <div className="tab-pane" id="status" aria-labelledby="status-tab">
                        <div className="tab_content_header">
                        {
                            this.state.statusShow &&
                            <SubAssemblyDowntimeTab type="Status" url = "status"/>
                        }
                        </div>
                    </div>
                    <div className="tab-pane" id="tags" aria-labelledby="tags-tab">
                        <div className="tab_content_header"> 
                        {
                            this.state.tagShow &&
                            <SubAssemblyDowntimeTab type="Tag" url = "tag"/>
                        }
                        </div>
                    </div>
                    <div className="tab-pane" id="tab_2" aria-labelledby="additional-tab">
                        <div className="tab_content_header">
                        {
                            this.state.attributeShow &&
                            <AdditionalAttribute category="sub_assembly" />
                        }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(SubAssemblyCard);