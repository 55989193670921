import React from "react";
import AppBaseComponent from "../../components/AppBaseComponent";
import { connect } from "react-redux";
import ApplicationLayout from "../../layouts/ApplicationLayout";
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon";
import Loader from "../../components/ui/Loader/Loader";
import HttpAPICall from "../../services/HttpAPICall";
import { Modal } from "bootstrap";
import TapSelect from "../../components/ui/TapSelect";
import Status from "../../components/ui/Status";
import InputAssetSearch from "../includes/ui/InputAssetSearch";
import {
  DisplayListPagination,
  DisplaySearchCriteria,
} from "../../components/TapUi/index";
import { Link } from "react-router-dom";
import * as actions from "../../store/actions/index";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";
import TapApiUrls from "../../services/TapApiUrls";
import DownloadFile from "../../services/DownloadFile";

class MeasurableAssetList extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.assetSearchFormInitialState = {
      search_asset_id: null,
      asset_group_ids: [],
      search_asset_type: [],
      search_asset_matrix: "",
      site_group_ids: [],
      search_site: "",
      search_manufacturer: "",
      search_model: "",
      search_status: "",
      search_variants: "",
      search_registration_number: "",
      search_engine_number: "",
      search_chasis_number: "",
    };

    this.assetSearchFormLabel = {
      search_asset_id: "Asset : ",
      asset_group_ids: "Asset Type Groups : ",
      search_asset_type: "Asset Type : ",
      search_asset_matrix: "Asset Matrix : ",
      site_group_ids: "Site Groups : ",
      search_site: "Site : ",
      search_manufacturer: "Manufacturer : ",
      search_model: "Model : ",
      search_status: "Status : ",
      search_variants: "Variants : ",
      search_registration_number: "Registration Number : ",
      search_engine_number: "Engine Number : ",
      search_chasis_number: "Chasis Number : ",
    };

    this.state = {
      listing_tbl_page: 1,
      listing_loading: this.props.asset_listing_loaded,
      listingMeta: null,
      listingData: [],
      itemData: null,
      totalListingCount: 0,
      assetSearchForm: { ...this.assetSearchFormInitialState },
      formSearchedElems: [],
      assetSearchedElems: [],
      searchedAsset: null,
      assetSearchParams: {},
      iam_asset_types: [],
      iam_asset_matrix: [],
      iam_manufactures: [],
      iam_user_sites: [],
      iam_models: [],
      iam_status_list: [],
      iam_asset_variants: [],
      iam_group_sites: [],
      loadedData: false,
      assetBulkUploadFormSubmiting: false,
      allSiteGroupsList: [],
      allAssetTypesGroupsList: [],
    };
  }

  componentDidMount() {
    //Asset Search Modal :-
    this.searchAssetModal = new Modal(
      document.getElementById("searchAssetModal"),
      { keyboard: false, backdrop: false }
    );
    this.assetBulkUploadModal = new Modal(
      document.getElementById("assetBulkUploadModal"),
      { keyboard: false, backdrop: false }
    );
    //Initialize Fiter Form data
    this.initilaizeFormFilter(this.props);

    if (this.props.asset_meta) {
      this.setState({ listing_tbl_page: this.props.asset_meta.current_page });
    }

    let ParamObject = new URLSearchParams(this.props.location.search);
    if (
      ParamObject.getAll("status[]").length > 0 ||
      ParamObject.getAll("site[]").length > 0 ||
      ParamObject.getAll("asset_type[]").length > 0 ||
      ParamObject.getAll("assetMatrix[]").length > 0
    ) {
      this.setState(
        {
          listing_loading: true,
          listing_tbl_page: 1,
          loadedData: false,
          assetSearchForm: {
            ...this.state.assetSearchForm,
            search_status: new URLSearchParams(this.props.location.search)
              .getAll("status[]")
              .map((s) => parseInt(s)),
            search_site: new URLSearchParams(this.props.location.search).getAll(
              "site[]"
            ),
            search_variants: new URLSearchParams(
              this.props.location.search
            ).getAll("variants[]"),
            search_asset_matrix: new URLSearchParams(
              this.props.location.search
            ).getAll("assetMatrix[]"),
            search_asset_type: new URLSearchParams(
              this.props.location.search
            ).getAll("asset_type[]"),
          },
        },
        () => {
          setTimeout(() => {
            this.assetSearchFormSubmit();
          }, 1000);
        }
      );
    } else if (
      typeof this.props.asset_param == "object" &&
      Object.keys(this.props.asset_param).length > 0
    ) {
      this.setState(
        {
          listing_tbl_page: this.props.asset_meta.current_page,
          assetSearchForm: {
            ...this.state.assetSearchForm,
            ...this.props.asset_param,
          },
          // loadedData              :       true
        },
        () => {
          setTimeout(() => {
            this.assetSearchFormSubmit(null, false);
          }, 1000);
        }
      );
    } else {
      //On  Initialize Get Item List From Server :-
      if (this.props.asset_meta) {
        this.loadListingTblData(this.props.asset_meta.current_page);
      } else {
        this.loadListingTblData(1);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.iam_asset_types !== this.props.iam_asset_types) {
      this.initilaizeFormFilter(nextProps);
    }
  }

  initilaizeFormFilter = (props) => {
    let status_list = [...props.iam_status_list, { key: 2, name: "Inactive" }];
    this.setState({
      iam_asset_types: props.iam_asset_types
        ? props.iam_asset_types.map((at) => {
            return { value: at.id, label: at.asset_type_name };
          })
        : [],
      iam_asset_matrix: props.iam_asset_matrix
        ? props.iam_asset_matrix.map((am) => {
            return { value: am.id, label: am.asset_matrix_unique_id };
          })
        : [],
      iam_asset_variants: props.iam_asset_variants
        ? props.iam_asset_variants.map((v) => {
            return { value: v, label: v };
          })
        : [],
      iam_manufactures: props.iam_manufactures
        ? props.iam_manufactures.map((m) => {
            return { value: m.id, label: m.manufacturer_name };
          })
        : [],
      iam_models: props.iam_models
        ? props.iam_models.map((m) => {
            return { value: m.id, label: m.model_name };
          })
        : [],
      iam_status_list: status_list
        ? status_list.map((s) => {
            return { value: s.key, label: s.name };
          })
        : [],
      iam_user_sites: props.iam_user_sites
        ? props.iam_user_sites.map((s) => {
            return { value: s.id, label: `${s.site_name} (${s.site_code})` };
          })
        : [],
      allSiteGroupsList:
        props.site_groups && props.site_groups.length > 0
          ? props.site_groups.map((oc) => {
              return {
                value: oc.id,
                label: oc.name,
                linked_sites: oc.site_ids,
              };
            })
          : [],
      allAssetTypesGroupsList:
        props.asset_groups && props.asset_groups.length > 0
          ? props.asset_groups.map((oc) => {
              return {
                value: oc.id,
                label: oc.name,
                linked_asset_types: oc.asset_type_ids,
              };
            })
          : [],
    });
  };

  loadListingTblData = (page = 1) => {
    this.setState({ listing_tbl_page: page });

    //  if(this.state.formSearchedElems.length == 0 || JSON.stringify(this.state.assetSearchParams) !== JSON.stringify(this.props.asset_param)){
    this.props.onListingLoding();
    this.props.onListingSuccess(
      page,
      this.state.assetSearchParams,
      "Y",
      this.state.formSearchedElems
    );
    //  }
  };

  //Initialize Search Form :-
  assetSearchModalInit = () => this.searchAssetModal.show({ focus: true });

  assetSearchFormSubmit = (event = null, reload = true) => {
    event && event.preventDefault();
    let serachFormKeys = Object.keys(this.state.assetSearchForm);

    let searchedElems = [];
    let assetSearchParams = {};
    serachFormKeys.map((key) => {
      let label = this.assetSearchFormLabel[key];
      let value = this.state.assetSearchForm[key];
      if (value && value.length > 0) {
        assetSearchParams[key] = value;
        if (label) {
          let show_val = value;
          if (key == "search_asset_id") {
            show_val = this.state.searchedAsset
              ? this.state.searchedAsset.display_label
              : "";
          }
          if (key == "asset_group_ids") {
            show_val = this.state.allAssetTypesGroupsList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_asset_type") {
            show_val = this.state.iam_asset_types
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_asset_matrix") {
            show_val = this.state.iam_asset_matrix
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_site") {
            show_val = this.state.iam_user_sites
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "site_group_ids") {
            show_val = this.state.allSiteGroupsList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_manufacturer") {
            show_val = this.state.iam_manufactures
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_model") {
            show_val = this.state.iam_models
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_variants") {
            show_val = this.state.iam_asset_variants
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_status") {
            show_val = this.state.iam_status_list
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_registration_number") {
            show_val =
              this.state.assetSearchForm &&
              this.state.assetSearchForm.search_registration_number
                ? this.state.assetSearchForm.search_registration_number
                : "";
          }
          if (key == "search_engine_number") {
            show_val =
              this.state.assetSearchForm &&
              this.state.assetSearchForm.search_engine_number
                ? this.state.assetSearchForm.search_engine_number
                : "";
          }
          if (key == "search_chasis_number") {
            show_val =
              this.state.assetSearchForm &&
              this.state.assetSearchForm.search_chasis_number
                ? this.state.assetSearchForm.search_chasis_number
                : "";
          }
          searchedElems.push(label + `<b>${show_val}</b>`);
        }
      }
    });

    //Generate Html :-
    this.setState(
      {
        formSearchedElems: searchedElems,
        assetSearchParams: assetSearchParams,
      },
      () => {
        if (reload == true) {
          this.loadListingTblData(1);
        }
        this.searchAssetModal.hide();
      }
    );
  };

  itemSearchClear = () => {
    this.setState(
      {
        formSearchedElems: [],
        assetSearchParams: {},
        searchedAsset: null,
        loadedData: false,
        assetSearchForm: { ...this.assetSearchFormInitialState },
      },
      () => {
        this.loadListingTblData(1);
        this.searchAssetModal.hide();
      }
    );
  };

  openAddAssetModalInit = () => {
    this.props.history.push("/asset_matrix/add_asset");
  };

  assetBulkUploadModalInit = () => {
    document.getElementById("addAssetFileSelector").value = null;

    this.assetBulkUploadModal.show();
  };

  assetBulkUploadFormSubmit = (event) => {
    event.preventDefault();
    this.setState({ assetBulkUploadFormSubmiting: true });
    const data = new FormData(document.getElementById("assetBulkUploadForm"));
    let headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + this.props.access_token,
    };
    axios
      .post(TapApiUrls.IAM + "/asset_add_by_excel", data, { headers: headers })
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.assetBulkUploadModal.hide();
        //Load Add Item Form Data :-

        DownloadFile.file(response.data.generated_file.file_path);
        setTimeout(() => {
          this.loadListingTblData(1);
        }, 1000);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ?? "Unable to upload Asset Add File.",
          { position: toast.POSITION.TOP_RIGHT }
        );
      })
      .then(() => {
        this.setState({ assetBulkUploadFormSubmiting: false });
      });
  };

  downloadSampleFile = () => {
    HttpAPICall.withAthorization(
      "GET",
      TapApiUrls.IAM + "/asset_add_download_sample_excel",
      this.props.access_token,
      {},
      {},
      (response) => {
        DownloadFile.file(response.data.file_path);
        toast.success("File Downloaded Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.assetBulkUploadModal.hide();
      }
    );
  };

  assetListingTableJsx = () => {
    return (
      <Ax>
        <DisplaySearchCriteria
          searchedElems={this.state.formSearchedElems}
          onClearFilter={this.itemSearchClear}
        />
        <div className="">
          <table className="table table-bordered table-hover table-sm table-fixed bg-white">
            <thead className="table-secondary">
              <tr>
                <th scope="col" style={{ width: "5%" }} className="text-center">
                  S.No
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Name
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Code
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Asset Type
                </th>
                <th scope="col" style={{ width: "17%" }}>
                  Manufacturer
                </th>
                <th scope="col" style={{ width: "11%" }}>
                  Model
                </th>
                <th scope="col" style={{ width: "11%" }}>
                  Site
                </th>
                <th scope="col" style={{ width: "11%" }}>
                  Status
                </th>
                <th scope="col" style={{ width: "5%" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.asset_listing_loaded ? (
                <tr>
                  <td className="text-center" colSpan="9">
                    <Loader />
                  </td>
                </tr>
              ) : this.props.asset_listing.length > 0 ? (
                this.props.asset_listing.map((item, index) =>
                  this.listingTableTrJsx(item, index)
                )
              ) : (
                this.listingTableTrJsx(null)
              )}
            </tbody>
          </table>
        </div>
        <DisplayListPagination
          meta={this.props.asset_meta}
          onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
        />
      </Ax>
    );
  };

  listingTableTrJsx = (item, index = 0) => {
    return item ? (
      <tr key={index}>
        <td className="text-center">
          {this.props.asset_meta ? this.props.asset_meta.from + index : index}{" "}
        </td>
        <td>
          <Link
            to={`/asset/dashboard/${item.asset_id}`}
            role="button"
            className="link-primary"
          >
            {item.name}
          </Link>
        </td>
        <td>{item.asset_code}</td>
        <td>{item.asset_type_name}</td>
        <td>{item.manufacturer_name}</td>
        <td>{item.model_name}</td>
        <td>{item.site}</td>
        <td>
          <Status color={item.status_color}>{item.status_text}</Status>
        </td>
        <td className="p0 text-center">
          <tapIcon.imageIcon
            icon={tapIcon.ListActionIcon}
            alt="action-icon"
            className="img-fluid px-3"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-expanded="false"
          />

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" role="button">
                Edit
              </a>
            </li>
          </ul>
        </td>
      </tr>
    ) : (
      <tr key={index}>
        <td className="text-center" colSpan={9}>
          No Record Found
        </td>
      </tr>
    );
  };

  asserListingJsx = () => {
    return (
      <Ax>
        <div className="page_title row m0">
          <div className="col-12">
            <h3>Measurable Asset</h3>
            <div className="text-end mt15">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.openAddAssetModalInit}
              >
                New Asset
              </button>
              <span className="dropdown">
                <button
                  type="button"
                  id="actionMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn btn-secondary"
                  disabled={this.state.excelDownloading == true}
                >
                  <tapIcon.imageIcon
                    icon={tapIcon.ActionIcon}
                    className="img-fluid"
                    alt="item-action"
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      onClick={this.assetBulkUploadModalInit}
                      role="button"
                    >
                      Bulk Upload
                    </Link>
                  </li>
                </ul>
              </span>
              <button
                type="button"
                onClick={this.assetSearchModalInit}
                className="btn btn-secondary"
              >
                <tapIcon.imageIcon
                  icon={tapIcon.SearchIcon}
                  className="img-fluid"
                  alt="item-action"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid pl5">
          <div className="page_containt row">
            <div className="pageTbl col-12">{this.assetListingTableJsx()}</div>
          </div>
        </div>
      </Ax>
    );
  };

  assetSearchModalJsx = () => {
    return (
      <div className="modal fade" id="searchAssetModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="searchAssetModalLabel">
                Asset Search
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={this.assetSearchFormSubmit} id="assetSearchForm">
              <div className="modal-body">
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset
                  </label>
                  <div className="col-sm-10">
                    <InputAssetSearch
                      containerHeight="30px"
                      fontSize="93%"
                      clAttribute="Y"
                      changeEvent={(selectedOption) => {
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_asset_id"
                        );
                        this.setState({
                          searchedAsset: selectedOption ? selectedOption : null,
                        });
                      }}
                      value={
                        this.state.searchedAsset
                          ? {
                              ...this.state.searchedAsset,
                              label: this.state.searchedAsset.display_label,
                            }
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset Type Group
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.allAssetTypesGroupsList}
                      changeEvent={(selectedOption) => {
                        this.setState({
                          assetSearchForm: {
                            ...this.state.assetSearchForm,
                            asset_group_ids:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption.map((o) => o.value)
                                : [],
                            search_asset_type:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption
                                    .map((s) => s.linked_asset_types)
                                    .flat()
                                : [],
                          },
                        });
                      }}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.allAssetTypesGroupsList.filter((s) =>
                        this.state.assetSearchForm.asset_group_ids.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Asset Type Group"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset Type
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.iam_asset_types}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_asset_type"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_asset_types.filter((s) =>
                        this.state.assetSearchForm.search_asset_type.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Asset Type"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset Matrix
                  </label>
                  <div className="col-sm-10">
                    <TapSelect
                      options={this.state.iam_asset_matrix}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_asset_matrix"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_asset_matrix.filter((s) =>
                        this.state.assetSearchForm.search_asset_matrix.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Asset Matrix"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Site Group
                  </label>
                  <div className="col-sm-4">
                    <TapSelect
                      options={this.state.allSiteGroupsList}
                      changeEvent={(selectedOption) => {
                        this.setState({
                          assetSearchForm: {
                            ...this.state.assetSearchForm,
                            site_group_ids:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption.map((o) => o.value)
                                : [],
                            search_site:
                              selectedOption && selectedOption.length > 0
                                ? selectedOption
                                    .map((s) => s.linked_sites)
                                    .flat()
                                : [],
                          },
                        });
                      }}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.allSiteGroupsList.filter((s) =>
                        this.state.assetSearchForm.site_group_ids.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Site Group"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Site Name
                  </label>
                  <div className="col-sm-4">
                    <TapSelect
                      options={this.state.iam_user_sites}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_site"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_user_sites.filter((s) =>
                        this.state.assetSearchForm.search_site.includes(s.value)
                      )}
                      placeholder="Select Site"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Manufacturer
                  </label>
                  <div className="col-sm-4">
                    <TapSelect
                      options={this.state.iam_manufactures}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_manufacturer"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_manufactures.filter((s) =>
                        this.state.assetSearchForm.search_manufacturer.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Manufacturer"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Model
                  </label>
                  <div className="col-sm-4">
                    <TapSelect
                      options={this.state.iam_models}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_model"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_models.filter((s) =>
                        this.state.assetSearchForm.search_model.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Model"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset Status
                  </label>
                  <div className="col-sm-4">
                    <TapSelect
                      options={this.state.iam_status_list}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_status"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_status_list.filter((s) =>
                        this.state.assetSearchForm.search_status.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Asset Status"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Asset Variants
                  </label>
                  <div className="col-sm-4">
                    <TapSelect
                      options={this.state.iam_asset_variants}
                      changeEvent={(selectedOption) =>
                        this.tapSelectChange(
                          selectedOption,
                          "assetSearchForm",
                          "search_variants"
                        )
                      }
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      value={this.state.iam_asset_variants.filter((s) =>
                        this.state.assetSearchForm.search_variants.includes(
                          s.value
                        )
                      )}
                      placeholder="Select Variant"
                      containerHeight="30px"
                      fontSize="93%"
                    />
                  </div>
                </div>
                <div className="row mb-3 lign-items-center">
                  <label className="col-sm-2 col-form-label col-form-label-sm text-end">
                    Engine Number
                  </label>
                  <div className="col-sm-4">
                    <input
                      name="search_engine_number"
                      type="text"
                      onChange={(e) =>
                        this.formInputHandler(e, "assetSearchForm")
                      }
                      value={this.state.assetSearchForm.search_engine_number}
                      placeholder="Search Engine Number"
                      className="form-control form-control-sm"
                      autoComplete="off"
                    />
                  </div>
                  <label className="col-sm-2 col-form-label col-form-label-sm  text-end">
                    Registration Number
                  </label>
                  <div className="col-sm-4">
                    <input
                      name="search_registration_number"
                      type="text"
                      onChange={(e) =>
                        this.formInputHandler(e, "assetSearchForm")
                      }
                      value={
                        this.state.assetSearchForm.search_registration_number
                      }
                      placeholder="Search Registration Number"
                      className="form-control form-control-sm"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <label className="col-sm-2 col-form-label  text-end">
                    Chassis Number
                  </label>
                  <div className="col-sm-4">
                    <input
                      name="search_chasis_number"
                      type="text"
                      onChange={(e) =>
                        this.formInputHandler(e, "assetSearchForm")
                      }
                      value={this.state.assetSearchForm.search_chasis_number}
                      placeholder="Search Chassis Number"
                      className="form-control form-control-sm"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  assetBulkUploadModalJsx = () => {
    return (
      <div className="modal fade" id="assetBulkUploadModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="itemBulkUploadModalLabel">
                Bulk Add Asset
              </h5>
              <button
                type="button"
                disabled={this.state.assetBulkUploadFormSubmiting}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={this.assetBulkUploadFormSubmit}
              id="assetBulkUploadForm"
              method="post"
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="row align-items-center mb-3 mt-3">
                  <div className="col-12">
                    <input
                      name="document"
                      id="addAssetFileSelector"
                      type="file"
                      className="form-control"
                      required={true}
                    />
                  </div>
                  <div className="col-12 mt-2 form-text">
                    # Excel Sheet with Extention xls,xlm,xlsx,ods Allowed
                  </div>
                  <div className="col-12 mt-2 form-text">
                    # Miximum 100 MB Size of Excel Sheet Allowed
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <div>
                  <a
                    type="button"
                    className="float-left"
                    href="#"
                    onClick={this.downloadSampleFile}
                  >
                    Download Sample File
                  </a>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={this.state.assetBulkUploadFormSubmiting}
                    className="btn btn-secondary mx-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    disabled={this.state.assetBulkUploadFormSubmiting}
                    className="btn btn-primary"
                  >
                    Submit{" "}
                    {this.state.assetBulkUploadFormSubmiting ? (
                      <tapIcon.FontAwesomeIcon
                        icon={tapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Measurable Asset List</title>
        </Helmet>
        {this.asserListingJsx()}
        {this.assetSearchModalJsx()}
        {this.assetBulkUploadModalJsx()}
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    iam_asset_matrix:
      state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
    iam_asset_variants:
      state.app && state.app.asset_variants ? state.app.asset_variants : [],
    iam_manufactures:
      state.app && state.app.manufactures ? state.app.manufactures : [],
    iam_models: state.app && state.app.models ? state.app.models : [],
    iam_status_list:
      state.app && state.app.status_list ? state.app.status_list : [],
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    asset_param:
      state.app && state.app.asset_search_param
        ? state.app.asset_search_param
        : {},
    asset_listing_loaded:
      state.app && state.app.asset_listing_loaded
        ? state.app.asset_listing_loaded
        : false,
    asset_listing:
      state.app && state.app.asset_listing ? state.app.asset_listing : [],
    asset_meta: state.app && state.app.asset_meta ? state.app.asset_meta : null,
    asset_total_count:
      state.app && state.app.asset_total_count
        ? state.app.asset_total_count
        : 0,
    site_groups:
      state.app && state.app.site_groups ? state.app.site_groups : [],
    asset_groups:
      state.app && state.app.asset_groups ? state.app.asset_groups : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onListingLoding: (data) =>
      dispatch(actions.assetListingDataInitilization()),
    onListingSuccess: (page, searchParm, cl_attribute, formSearchedElems) =>
      dispatch(
        actions.assetListingDataLoading(
          page,
          searchParm,
          cl_attribute,
          formSearchedElems
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeasurableAssetList);
