import React from "react";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import download from "../../../services/DownloadFile";
import TapIcon from "../../../services/TapIcon";

class EmpCustomeDownloadModal extends AppBaseComponent {
  constructor() {
    super();
    this.initCustomDownloadState = {
      education: "",
      family: "",
      current_working: "",
      previous_working: "",
      present_address: "",
      permanent_address: "",
    };
    this.state = {
      excelDownloadFormSubmitting: false,
      loading_modal: false,
      customDownloadForm: { ...this.initCustomDownloadState },
    };
    this.emp_list_url               =   HRM_API_BASE_URL_2 + '/employee/list';
  }

  componentDidMount() {
    this.excelDownloadModal = new Modal(
      document.getElementById("excelDownloadModal"),
      { keyboard: false, backdrop: false }
    );
  }

  handleExcelDownload = () => {
    this.excelDownloadModal.show();
    this.setState(
      {
        loading_modal: true,
        customDownloadForm: {
          ...this.state.customDownloadForm,
          ...this.props.submittedEmployeeSearchForm,
        },
      },
      () => {
        this.setState({
          loading_modal: false,
        });
      }
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ excelDownloadFormSubmitting: true });
    let params = { ...this.state.customDownloadForm };
    HttpAPICall.withAthorization("GET", this.emp_list_url, this.props.access_token, { download: "download", ...params },
      {}, (response) => {
        download.file(response.data.file_path);
        this.setState({
          customDownloadForm : this.initCustomDownloadState
        });
        this.excelDownloadModal.hide();
      }
    ).then(() => this.setState({ excelDownloadFormSubmitting: false }));
  };

  handleCheckbox = (e) => {
    this.setState({
      customDownloadForm: {
        ...this.state.customDownloadForm,
        [e.target.name]: e.target.value === "Y" ? "" : "Y",
      },
    });
  };

  render() {
    return (
      <div className="modal fade" id="excelDownloadModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="excelDownloadModalLabel">
                Select Columns for Excel Download
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {this.state.loading_modal ? (
                <Loader />
              ) : (
                <form id="excelDownload" onSubmit={this.submitForm}>
                  <div className="tab_content_wrapper my-2">
                    <span className="content_heading">Select Columns </span>
                  </div>
                  <div className="row my-3">
                    <div className="col-sm-4 mb-2">
                      <label className="form-check-label" for="current_working">
                        Employee Current Working
                      </label>
                    </div>
                    <div className="col-sm-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="current_working"
                        value={this.state.customDownloadForm.current_working}
                        id="current_working"
                        checked={
                          this.state.customDownloadForm?.current_working === "Y"
                        }
                        onClick={(e) => this.handleCheckbox(e)}
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-check-label" for="education">
                        Employee Education
                      </label>
                    </div>
                    <div className="col-sm-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="education"
                        value={this.state.customDownloadForm.education}
                        id="education"
                        checked={
                          this.state.customDownloadForm?.education === "Y"
                        }
                        onClick={(e) => this.handleCheckbox(e)}
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-check-label" for="previous_working">
                        Employee Previous Working
                      </label>
                    </div>
                    <div className="col-sm-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="previous_working"
                        value={this.state.customDownloadForm.previous_working}
                        id="previous_working"
                        checked={
                          this.state.customDownloadForm?.previous_working ===
                          "Y"
                        }
                        onClick={(e) => this.handleCheckbox(e)}
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-check-label" for="family">
                        Employee Family
                      </label>
                    </div>
                    <div className="col-sm-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="family"
                        value={this.state.customDownloadForm.family}
                        id="family"
                        checked={this.state.customDownloadForm?.family === "Y"}
                        onClick={(e) => this.handleCheckbox(e)}
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-check-label" for="present_address">
                        Employee Present Address
                      </label>
                    </div>
                    <div className="col-sm-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="present_address"
                        value={this.state.customDownloadForm.present_address}
                        id="present_address"
                        checked={
                          this.state.customDownloadForm?.present_address === "Y"
                        }
                        onClick={(e) => this.handleCheckbox(e)}
                      />
                    </div>
                    <div className="col-sm-4 mb-2">
                      <label className="form-check-label" for="permanent_address">
                        Employee Permanent Address
                      </label>
                    </div>
                    <div className="col-sm-2 mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permanent_address"
                        value={this.state.customDownloadForm.permanent_address}
                        id="permanent_address"
                        checked={
                          this.state.customDownloadForm?.permanent_address ===
                          "Y"
                        }
                        onClick={(e) => this.handleCheckbox(e)}
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                disabled={this.state.excelDownloadFormSubmitting ? true : false}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                form="excelDownload"
                disabled={this.state.excelDownloadFormSubmitting ? true : false}
                className="btn btn-primary mx-2"
              >
                Save{" "}
                {this.state.excelDownloadFormSubmitting ? (
                  <TapIcon.FontAwesomeIcon
                    icon={TapIcon.faSyncAlt}
                    className="fa-spin"
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EmpCustomeDownloadModal
);
