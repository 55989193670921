import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from "../../../components/TapUi";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { Helmet } from "react-helmet";
import HttpAPICall from "../../../services/HttpAPICall";
import { IAM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import TapIcon from "../../../services/TapIcon";
import DownloadFile from "../../../services/DownloadFile";
import { Collapse } from "bootstrap";
import moment from "moment";
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";

class ShiftWiseCounterlogReport extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
            search_asset_ids                    :   [],
            site_group_ids                      :   [],
            search_site_ids                     :   [],
            search_asset_type_ids               :   [],
            search_manufacturer_ids             :   [],
            search_models                        :   [],
            search_variants                     :   [],
            roster_id                           :   '',
            date_range                          :   'current_month',
            date_range_start                    :   '',
            date_range_end                      :   '',
        }

        this.reportFilterFormLabel          =   {
            search_variants                     :   'Variant : ',
            search_models                        :   'Model : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            search_asset_type_ids               :   'Asset Type : ',
            site_group_ids                      :   'Site Group : ',
            search_site_ids                     :   'Site : ',
            search_asset_ids                    :   'Asset : ',
            roster_id                           :   'Roster : ',
            date_range                          :   'Date Range : ',
            
        };

        this.state                          =   {
            downloadReportBtn                   :   false,
            reportDownloading                   :   false,
            listing_loading                     :   false,
            show_element                        :   false,
            filter_loading                      :   false,
            listingData                         :   [],
            filterFormData                      :   null,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listingMeta                         :   null,
            manufacturers                       :   [],
            iam_user_sites                      :   [],
            searchedAssets                      :   [],
            iam_models                          :   [],
            iam_asset_variants                  :   [],
            allPeriods                          :   [],
            allRoster                           :   [],
            allSiteGroupsList                   :   [],
            reportData                          :   null,                  
        };

        this.counterlogUrl                  =   IAM_API_BASE_URL_2 + '/report/shift-wise-counterlog';
    }

    componentDidMount   ()  {
        this.setState({
            filter_loading         :   true,
        })
        setTimeout(() => {
            this.initializeData(this.props);  
        }, 1000); 
    }

    initializeData  =   (pr)  =>  {

        const report_name   =   'shift_wise_counterlog_report';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];

        this.setState({
            reportData          :   reportData.length > 0 ? reportData[0] : null,
        });
       
        HttpAPICall.withAthorization('GET', this.counterlogUrl, this.props.access_token, {action:"formData"}, {}, (res) => {
            this.setState({
                allRoster           :   res.data && res.data.rosters.length > 0 ? res.data.rosters.map((r) => { return {value: r.id, label: r.roster_name}}) : [],
                manufacturers       :   pr.manufactures ? pr.manufactures.map((m) => { return {value: m.id, label: `${m.manufacturer_name}`}}) : [],
                iam_models          :   pr.iam_models ? pr.iam_models.map((m) => { return {value: m.id, label: `${m.model_name}`}})     : [],
                iam_user_sites      :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                allPeriods          :   pr.all_periods,
                allSiteGroupsList   :   pr.site_groups && pr.site_groups.length > 0 ? pr.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                iam_asset_variants  :   pr.iam_asset_variants ? pr.iam_asset_variants.map((v) => { return {value: v, label: v}}) : [],
                reportFilterForm    :   {...this.state.reportFilterForm, date_range: 'current_month'},
            }, ()=>{
                this.setState({
                    filter_loading : false, 
                });
                this.reportFilterhandler();
            });
        }, (err) => {});
        
    }

    loadListData    =   (page = 1)  =>  {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloading           :   false
        });
        let params                      =   {page:page, ...this.state.submittedReportFilterForm, per_page : 25, action:"data"};
        HttpAPICall.withAthorization('GET', this.counterlogUrl, this.props.access_token, params, {}, (res) => {
            let respData                =   res.data;
            this.setState({
                listingData                 :   respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownload  =   ()  =>  {
        this.setState({
            reportDownloading   :   true,
        });
        const params    =   {action : 'download',...this.state.submittedReportFilterForm}

        HttpAPICall.withAthorization('GET', this.counterlogUrl, this.props.access_token, params , {} , (res) => 
        DownloadFile.file(res.data.file_path))
        .then(() => this.setState({reportDownloading: false}));
    }

    counterlogList  =   ()  =>  {
        return (
            <Ax>
                <table className="table table-bordered table-sm align-middle bg-white">
                    <thead>
                        <tr className="sticky-top table-secondary"> 
                            <th className="text-center" style={{width: "10%"}}>S No.</th>
                            <th className="text-center" style={{width: "25%"}}>Site Name</th>
                            <th className="text-center" style={{width: "25%"}}>Site Code</th>
                            <th className="text-center" style={{width: "15%"}}>Total Assets</th>
                            <th className="text-center" style={{width: "25%"}}>Assets Linked with ( Default Roster )</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.listing_loading 
                            ? 
                                <tr>
                                    <td className="text-center" colSpan={5}><Loader/></td>
                                </tr>
                            :
                            this.state.listingData && this.state.listingData.length > 0 
                            ? 
                            this.state.listingData.map((data,key) => 
                                <tr key={key}>
                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + key : key}</td>               
                                    <td>{data.site}</td>         
                                    <td>{data.site_code}</td>      
                                    <td className="text-center">{data.total_asset}</td>               
                                    <td className="text-center">{data.total_asset_roster}</td>               
                                </tr>
                            )
                            :
                                <tr>
                                    <td className="text-center" colSpan={5}>Record Not Found</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </Ax>
        )
    }

    pageTitleBar            =   ()  =>  {
        return (
            <div className="page_title row m0">
                <div className="col-sm-12">
                    <h3>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faCubes} /> {this.state.reportData?.name} 
                    </h3>
                    <div className="text-end mt15">
                        <button type="button"  className="btn btn-secondary" onClick={this.reportFilterhandler}>Change Filter</button>
                        {this.state.show_element &&
                            <button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownload} className="btn btn-primary"><TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>
                        }
                        <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    reportFilterFormSubmit  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'roster_id') {
                        show_val            =   this.state.allRoster.find(m => m.value == this.state.reportFilterForm.roster_id)?.label;
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.manufacturers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            =   this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_variants') {
                        show_val            =   this.state.iam_asset_variants.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range        =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range           =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY');
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`;
                        show_val                =     this.state.allPeriods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems,
            show_element                        :   true,
        }, () => {
            this.loadListData(1);
        });
    }

    reportFilterhandler     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    reportFilterFormJsx     =   ()  =>  {
        return (
                <div id="reportFilterForm" className="bg-white collapse" >
                    <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                        <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                            <div className="col-sm-6 align-items-center">
                                <InputAssetSearch
                                    isMulti={true}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                        this.setState({
                                            searchedAssets: selectedOption ? selectedOption : null
                                        }, () => { });
                                    }}
                                    value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                />
                            </div>
                        </div>
                    <div className="row my-3">
                        <label className="col-sm-3 col-form-label text-end">Site Group</label>
                        <div className="col-sm-6 align-items-center">
                            <TapSelect
                                options={this.state.allSiteGroupsList}
                                changeEvent={(selectedOption) => {

                                    this.setState({
                                        reportFilterForm: {
                                            ...this.state.reportFilterForm,
                                            site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                            search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                        }
                                    })
                                }}
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                                placeholder="Select Site Group"
                            />
                        </div>
                    </div>
                        <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end">Site Name</label>
                            <div className="col-sm-6">
                                <TapSelect
                                    options={this.state.iam_user_sites}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm?.search_site_ids?.includes(s.value))}
                                    placeholder="Select Sites"
                                />
                            </div>
                        </div>
                        <div className="row my-3 align-items-center">
                            <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                            <div className="col-sm-6">
                                <TapSelect
                                    options={this.state.manufacturers}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={this.state.manufacturers.filter(s => this.state.reportFilterForm?.search_manufacturer_ids?.includes(s.value))}
                                    placeholder="Select Manufacturer"
                                />
                            </div>
                        </div>
                        <div className="row my-3 align-items-center">
                            <label className="col-sm-3 col-form-label text-end">Model</label>
                            <div className="col-sm-6">
                                <TapSelect
                                    options={this.state.iam_models}
                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_models')}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={this.state.iam_models.filter(s => this.state.reportFilterForm?.search_models?.includes(s.value))}
                                    placeholder="Select Model"
                                />
                            </div>
                        </div>
                        <div className="row my-3 align-items-center">
                            <label className="col-sm-3 col-form-label text-end">Asset Variants</label>
                            <div className="col-sm-6">
                                <TapSelect
                                    options={this.state.iam_asset_variants}
                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_variants')}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={this.state.iam_asset_variants.filter(s => this.state.reportFilterForm?.search_variants?.includes(s.value))}
                                    placeholder="Select Variant"
                                />
                            </div>
                        </div>
                        <div className="row my-3 align-items-center">
                            <label className="col-sm-3 col-form-label text-end">Roster <span className="text-danger">*</span></label>
                            <div className="col-sm-6">
                                <TapSelect
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'roster_id');
                                    }}
                                    options={this.state.allRoster}
                                    isSearchable={true}
                                    required={true}
                                    value={this.state.allRoster.find(m => m.value == this.state.reportFilterForm.roster_id)}
                                    placeholder="Select Roster"
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                            <label className="col-sm-3 col-form-label text-end">Select Period <span className="text-danger">*</span></label>
                            <div className="col-sm-6">
                                <PeriodSelectionWithCustom
                                    periods={this.state.allPeriods ? this.state.allPeriods : []}
                                    value={this.state.reportFilterForm.date_range}
                                    startDate={this.state.reportFilterForm.date_range_start}
                                    endDate={this.state.reportFilterForm.date_range_end}
                                    onSelectPeriod={(period, startDate, endDate) => {
                                        this.setState({
                                            reportFilterForm
                                                : {
                                                ...this.state.reportFilterForm,
                                                date_range: period,
                                                date_range_start: startDate ? startDate : null,
                                                date_range_end: endDate ? endDate : null
                                            }
                                        });
                                    }}
                                    required={true}
                                />
                            </div>
                        </div>  
                        <div className="row">
                            <div className="pb-3 col-9 text-end">
                                <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                            </div>
                            <hr />
                        </div>
                    </form>
                </div>
        );
    }

    closeReport         =   ()  =>  {
        this.props.history.push('/report')
    }

    render  =   ()  =>  {
        return (
            <ApplicationLayout> 
                <Helmet><title>Shift wise Counterlog Reading - Report</title></Helmet>
                {this.pageTitleBar()}
                <div className="container-fluid pl5">
                    <div className="page_containt row">
                    {this.state.filter_loading && <Loader/>}
                        <div id="pageTbl" className="pageTbl col-12">
                            {this.reportFilterFormJsx()}
                            {this.state.show_element && <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}/>}
                            {this.state.show_element && this.counterlogList()}
                            {this.state.show_element &&
                                this.state.listingMeta
                                ?
                                <DisplayListPagination  meta={this.state.listingMeta} onPageChange={(e) => this.loadListData(e.selected + 1)}/>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </ApplicationLayout>
        );
    };

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        manufactures            :   state.app && state.app.manufactures ? state.app.manufactures : [],
        asset_type              :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_asset_variants      :   state.app && state.app.asset_variants ? state.app.asset_variants : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ShiftWiseCounterlogReport);