import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Link } from 'react-router-dom';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';
import uuid from 'react-uuid';
import AllocationDetail from './AllocationDetail';
import ConsumptionDetailsModal from '../consumption/ConsumptionDetailsModal';

class AllocationDetailItemWise extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.reportUrl                      =  INVENTORY_API_BASE_URL_2 + '/report/item-wise-allocation-order';
        this.state                            =         {
            allocationItemWiseList            :         [],
            listing_loading                   :         false,
            listing_tbl_page                  :          1,
            listingMeta                       :          null,
            totalListingCount                 :          0,
            itemName                          :         '',
            warehouseName                          :         '',
            listingData                        :            [],
            item_id                             :           '',
            allocationId                        :           '',
            consumption_loading             :   true,
            consumptionData                 :   [],
            consumptionMeta                 :   null,
            consumption_tbl_page            :   1,
            consumptionId                   :   '',
            demand_item_id                  :   '',
            showConsumption                 :   true,
            showAllocation                  :   true,
            allocation_for                  :   '',
            allocation_for_id               :   ''
        };
        this.allocationModalId      =   uuid();
    }
    
    
    
    componentDidMount(){
        this.viewAllocationModal             =   new Modal(document.getElementById(this.allocationModalId), {keyboard: false, backdrop :false});
        this.viewAllocationDetailModal              =     new Modal(document.getElementById('viewAllocationDetailModal'), {keyboard: false, backdrop :false});
        this.viewConsumptionModal              =     new Modal(document.getElementById('viewConsumptionModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (item_id='',itemName='',warehouseName='',warehouse_id='',demand_item_id='',showConsumption=true,showAllocation=true)  =>  {
     
        this.viewAllocationModal.show();
        this.setState({
            itemName            :       itemName,
            warehouseName       :       warehouseName,
            item_id             :       item_id,
            warehouse_id        :       warehouse_id,
            demand_item_id      :       demand_item_id,
            showConsumption     :     showConsumption,
            showAllocation      :       showAllocation,
            listingData         :       [],
            consumptionData     :       [],
            listingMeta         :       null,
            consumptionMeta     :       null
           
        },() => {
                {showAllocation && this.loadItemWiseAllocationListing(1);}
               {showConsumption &&  this.loadItemWiseConsumptionListing(1);}
        })     
    }

    loadItemWiseAllocationListing                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            listing_tbl_page            :   page
        });
        let params                          =   {
            page                    :       page,
            for_report              :       'N',
            search_item_ids         :       [this.state.item_id],
            search_warehouse_ids    :       [this.state.warehouse_id],
            search_status_ids        :     this.state.demand_item_id ? [] : [2],
            search_demand_item_ids      :   this.state.demand_item_id ? [this.state.demand_item_id] : [],
           
        };
        
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    loadItemWiseConsumptionListing                      =   (page=1) => {
        this.setState({
            consumption_loading             :   true,
            consumptionData                 :   [],
            consumptionMeta                 :   null,
            consumption_tbl_page            :   page
        });
        let params                          =   {
            page                    :       page,
            for_report              :       'N',
            search_item_ids         :       [this.state.item_id],
            search_warehouse_ids    :       [this.state.warehouse_id],
            search_max_status_level :      this.state.demand_item_id ? '' : 3,
            search_demand_item_ids      :   this.state.demand_item_id ? [this.state.demand_item_id] : [],
           
        };
        
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/report/item-wise-consumption', this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            
            this.setState({
                consumptionData                 :   respData.data,
                consumptionMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({consumption_loading: false});
        });
    }

    viewAllocationModalInit                =   (id)        =>      {
        this.setState({
               allocationId       : id
        })
        this.viewAllocationDetailModal.show()
       
        
    }

    viewConsumptionModalInit                =   (id)        =>      {
        this.setState({
               consumptionId       : id
        })
        this.viewConsumptionModal.show();   
    }

    
    allocationModalJsx                     =   ()  =>  {
        console.log(this.state.consumptionData,'test')
        return (
            <div className="modal fade" id={this.allocationModalId} tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addItemModalLabel">Allocation Detail </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>

                                    <tr>
                                        <td style={{ width: "15%" }}> Item  </td>
                                        <th>{this.state.itemName ? this.state.itemName : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "15%" }}>Warehouse </td>
                                        <th>{this.state.warehouseName ? this.state.warehouseName : "-"}</th>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body ">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {this.state.showAllocation &&  <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="allocation-tab" data-bs-toggle="tab" data-bs-target="#allocation"
                                        type="button" role="tab" aria-controls="allocation" aria-selected="true">Item Wise Allocation
                                    </button>
                                </li>}
                               {this.state.showConsumption && <li className="nav-item" role="presentation">
                                    <button className={!this.state.showAllocation  ? "nav-link active" : "nav-link"} id="consumption-tab" data-bs-toggle="tab" data-bs-target="#consumption"
                                        type="button" role="tab" aria-controls="consumption" aria-selected="true">Item Wise Consumption
                                    </button>
                                </li>}


                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className={this.state.showAllocation  ? "tab-pane  show active" : "tab-pane"} id="allocation" role="tabpanel" aria-labelledby="allocation-tab">
                                    <table className="table table-bordered table-fixed table-sm align-middle bg-white my-2">
                                        <thead className="align-middle table-secondary">
                                            <tr className='text-end'>
                                                <th className="text-center" style={{ width: "4%" }}>S.No</th>
                                                <th className='text-start' style={{ width: "8%" }}>Transaction ID</th>
                                                <th className='text-start' style={{ width: "9%" }}>Date</th>
                                                <th className="text-center" style={{ width: "16%" }}>Allocation for</th>
                                                <th className="text-center" scope="col" style={{ width: "7%" }} > Status</th>
                                                <th scope="col" style={{ width: "6%" }} > Qty</th>
                                                <th scope="col" style={{ width: "4%" }} > Released</th>
                                                <th scope="col" style={{ width: "4%" }} > Consumed</th>
                                                <th scope="col" style={{ width: "7%" }} > Pending</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.listing_loading ? <tr>
                                                <td className="text-center" colSpan={9}><Loader /></td>
                                            </tr>
                                                : this.state.listingData && this.state.listingData.length > 0
                                                    ? (this.state.listingData.map((rowData, index) => {

                                                        return (<tr key={index}>
                                                            <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                            <td className='text-center'><a role="button" className="link-primary" onClick={() => this.viewAllocationModalInit(rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                                            <td className='text-center'>{rowData.transaction_date_display}</td>
                                                            <td className='text-capitalize'>
                                                                {rowData.allocation_for == 'asset'
                                                                    ? (rowData.assetProfile ? rowData.assetProfile.name + ' ' : '')
                                                                    : null
                                                                }
                                                                {rowData.allocation_for == 'workstation'
                                                                    ? (rowData.workstation ? rowData.workstation.workstation_name + ' ' : '')
                                                                    : null
                                                                }
                                                                {rowData.allocation_for == 'employee'
                                                                    ? (rowData.employee ? rowData.employee.display_full_name + ' ' : '')
                                                                    : null
                                                                }
                                                                <span className='form-text text-xs'> ({rowData.allocation_for == 'asset' ? 'Asset' : rowData.allocation_for == 'workstation' ? 'Workstation' : "Employee"})</span>
                                                            </td>
                                                            <td className="text-center">{rowData.status ? rowData.status.name : "-"}</td>
                                                            <td className="text-end">{rowData.qty}</td>
                                                            <td className="text-end">{rowData.release_qty ? rowData.release_qty : 0}</td>
                                                            <td className="text-end">{rowData.consumed_qty ? rowData.consumed_qty : 0}</td>
                                                            <td className="text-end">{rowData.pending_qty ? rowData.pending_qty : 0}</td>
                                                        </tr>);
                                                    })
                                                    )
                                                    : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                    <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadItemWiseAllocationListing(e.selected + 1)} />
                                </div>
                                <div className={!this.state.showAllocation  ? "tab-pane  show active" : "tab-pane"}  id="consumption" role="tabpanel" aria-labelledby="consumption-tab">
                                    <table className="table table-bordered table-fixed table-sm align-middle bg-white my-2">
                                        <thead className="align-middle table-secondary">
                                            <tr>
                                                <th className='text-start' style={{ width: "7%" }}>Transaction ID</th>
                                                <th className='text-start' style={{ width: "6%" }}>Transaction Date</th>
                                                <th className="text-center" style={{ width: "15%" }}>Consumption for</th>
                                                <th scope="col" style={{ width: "8%" }} > Status</th>
                                                <th scope="col" style={{ width: "4%" }} > Qty</th>
                                                <th scope="col" style={{ width: "7%" }} > Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.consumptionData && this.state.consumptionData.length > 0
                                                ? (this.state.consumptionData.map((rowData, k) => {
                                                    return (<tr>
                                                        <td><a role="button" className="link-primary" onClick={() => this.viewConsumptionModalInit(rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                                        <td>{rowData.transaction_date_display}</td>
                                                        <td>
                                                            {rowData.consumption_type == 'asset'
                                                                ? (rowData.assetProfile ? rowData.assetProfile.name + ' ' : '')
                                                                : null
                                                            }
                                                            {rowData.consumption_type == 'workstation'
                                                                ? (rowData.workstation ? rowData.workstation.workstation_name + ' ' : '')
                                                                : null
                                                            }
                                                            {rowData.consumption_type == 'employee'
                                                                ? (rowData.employee ? rowData.employee.display_full_name + ' ' : '')
                                                                : null
                                                            }
                                                            ({rowData.consumption_type == 'asset' ? 'Asset' : rowData.consumption_type == 'workstation' ? 'Workstation' : "Employee"})
                                                        </td>
                                                        <td>{rowData.status && rowData.status.name ? rowData.status.name : "-"}</td>
                                                        <td className="text-center">{rowData.qty}</td>
                                                        <td className="text-end">{rowData.amount}</td>
                                                    </tr>);

                                                }))
                                                : (<tr><td className="text-center" colSpan={5}>No Record Found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                    <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadItemWiseConsumptionListing(e.selected + 1)} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    allocationDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewAllocationDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Allocation Detail </h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                  <AllocationDetail allocationId={this.state.allocationId}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    consumptionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewConsumptionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Consumption Details</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                  <ConsumptionDetailsModal consumptionId={this.state.consumptionId}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (<Ax>{this.allocationModalJsx()}
        {this.allocationDetailsModalJsx()}
       {this.state.showConsumption &&  this.consumptionDetailsModalJsx()}
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(AllocationDetailItemWise);
