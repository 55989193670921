import React from "react";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AppBaseComponent from "../../../components/AppBaseComponent";
import tapIcon from "../../../services/TapIcon";
import { Helmet } from "react-helmet";
import TapApiUrls, { HRM_API_BASE_URL_2, ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import moment from "moment";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";
import Status from "../../../components/ui/Status";
import { Link } from "react-router-dom";
import EmployeeDocument from "./EmployeeDocument";
import DatePicker from "react-datepicker";
import TapIcon from "../../../services/TapIcon";
import DateService from "../../../services/DateService";

class EmpContactDetailModal extends AppBaseComponent {
  constructor() {
    super();

    this.contactFormInit = {
      present_address1: "",
      present_address2: "",
      present_country: 99,
      present_state: "",
      present_city: "",
      present_landmark: "",
      present_district: "",
      present_postCode: "",
      permanent_address1: "",
      permanent_address2: "",
      permanent_country: 99,
      permanent_state: "",
      permanent_city: "",
      permanent_postCode: "",
      permanent_landmark: "",
      permanent_district: "",
    };

    this.state = {
      contactForm: { ...this.contactFormInit },
      loading_form_data: false,
      saveFormSubmitting: false,
      employeeData: null,
      checkBoxClicked: false,
      presentCountryList: [],
      presentStateList: [],
      presentDistrictList: [],
      presentPincodeList: [],
      permanentCountryList: [],
      permanentStateList: [],
      permanentDistrictList: [],
      permanentPincodeList: [],
    };
    
    this.emp_add_form_data_url  =   HRM_API_BASE_URL_2 + '/employee/add_form_data'; 
    this.emp_edit_url           =   HRM_API_BASE_URL_2 + '/employee/edit';
    this.stateListUrl           =   ORG_API_BASE_URL_2 + '/state_list';
    this.districtListUrl        =   ORG_API_BASE_URL_2 + '/district_list';
    this.pincodeListUrl         =   ORG_API_BASE_URL_2 + '/pincode_list';

  }

  componentDidMount() {
    this.editContactModal = new Modal(
      document.getElementById("editContactModal"),
      { keyboard: false, backdrop: false }
    );
  }

  modalHandle = () => {
    this.editContactModal.show();
    const empData = this.props.employeeData;
    this.setState(
      {
        loading_form_data: true,
        contactForm: {
          present_address1:
            empData.present_address && empData.present_address.address1
              ? empData.present_address.address1
              : "",
          present_address2:
            empData.present_address && empData.present_address.address2
              ? empData.present_address.address2
              : "",
          present_country:
            empData.present_address && empData.present_address.country_id
              ? empData.present_address.country_id
              : 99,
          present_state:
            empData.present_address && empData.present_address.state_id
              ? empData.present_address.state_id
              : "",
          present_city:
            empData.present_address && empData.present_address.city
              ? empData.present_address.city
              : "",
          present_postCode:
            empData.present_address && empData.present_address.pincode
              ? empData.present_address.pincode
              : "",
          present_landmark:
            empData.present_address && empData.present_address.landmark
              ? empData.present_address.landmark
              : "",
          present_district:
            empData.present_address && empData.present_address.district_id
              ? empData.present_address.district_id
              : "",

          permanent_address1:
            empData.permanent_address && empData.permanent_address.address1
              ? empData.permanent_address.address1
              : "",
          permanent_address2:
            empData.permanent_address && empData.permanent_address.address2
              ? empData.permanent_address.address2
              : "",
          permanent_country:
            empData.permanent_address && empData.permanent_address.country_id
              ? empData.permanent_address.country_id
              : 99,
          permanent_state:
            empData.permanent_address && empData.permanent_address.state_id
              ? empData.permanent_address.state_id
              : "",
          permanent_district:
            empData.permanent_address && empData.permanent_address.district_id
              ? empData.permanent_address.district_id
              : "",
          permanent_city:
            empData.permanent_address && empData.permanent_address.city
              ? empData.permanent_address.city
              : "",
          permanent_postCode:
            empData.permanent_address && empData.permanent_address.pincode
              ? empData.permanent_address.pincode
              : "",
          permanent_landmark:
            empData.permanent_address && empData.permanent_address.landmark
              ? empData.permanent_address.landmark
              : "",
        },
      },() => {
        empData.present_address.state_id &&
          this.stateChangeHandler(empData.present_address.state_id);
        empData.present_address.district_id &&
          this.districtChangeHandler(empData.present_address.district_id);

        empData.permanent_address.state_id &&
          this.stateChangeHandler(
            empData.permanent_address.state_id,
            "permanent"
          );
        empData.permanent_address.district_id &&
          this.districtChangeHandler(
            empData.permanent_address.district_id,
            "permanent"
          );

        HttpAPICall.withAthorization("GET", this.emp_add_form_data_url, this.props.access_token, { type: "address" }, {}, (resp) => {
            let respData = resp.data;
            this.setState(
              {
                presentCountryList: respData && respData.country.length > 0 ? respData.country.map((c) => {
                    return { value: c.id, label: c.name }; }) : [],
                permanentCountryList: respData && respData.country.length > 0  ? respData.country.map((c) => { return { value: c.id, label: c.name }; }) : [],
              },() => {
                if(this.state.contactForm.present_country === this.state.contactForm.permanent_country) {
                    this.countryChangeHandler(
                        this.state.contactForm.present_country
                    );
                }else {
                  this.countryChangeHandler(
                    this.state.contactForm.present_country
                  );
                  this.countryChangeHandler(
                    this.state.contactForm.permanent_country,
                    "permanent"
                  );
                }
              }
            );
          }
        ).then(() => this.setState({ loading_form_data: false }));
      }
    );
  };

  countryChangeHandler = (selected_country, type = null) => {
    if (selected_country) {
      let selectedCountry;
      if (selected_country.value) {
        selectedCountry = selected_country.value;
      } else {
        selectedCountry = selected_country;
      }
      HttpAPICall.withAthorization("GET", this.stateListUrl,
        this.props.access_token, { country_id: selectedCountry }, null, (response) => {
          if (type === "permanent") {
            this.setState({
                permanentStateList: response.data && response.data.length > 0
                ? response.data.map((s) => { return { value: s.id, label: s.name }; })
                : [],
            });
          } else {
            this.setState({
                presentStateList: response.data && response.data.length > 0
                ? response.data.map((s) => { return { value: s.id, label: s.name };})
                : [],
            }, () => {
                if(this.state.contactForm.present_country === this.state.contactForm.permanent_country) {
                    this.setState({
                        permanentStateList: response.data && response.data.length > 0
                        ? response.data.map((s) => { return { value: s.id, label: s.name }; })
                        : [],
                    })
                }
            });
          }
        }
      );
    }
  };

  //FUNCTION TO GET DISTRICT LIST ON SELECTING STATE
  stateChangeHandler = (selected_state, type = null) => {
    if (selected_state) {
      let params;
      if (selected_state.length > 0) {
        let state_ids = selected_state.map((i) => i.value);
        params = { state_ids: state_ids };
      } else {
        params = {
          state_ids: [
            selected_state.value ? selected_state.value : selected_state,
          ],
        };
      }
      HttpAPICall.withAthorization("GET", this.districtListUrl,
        this.props.access_token, { ...params }, null, (response) => {
          if (type === "permanent") {
            this.setState({
              permanentDistrictList: response.data.map((s) => {
                return { value: s.id, label: s.name };
              }),
            });
          } else {
            this.setState({
              presentDistrictList: response.data.map((s) => {
                return { value: s.id, label: s.name };
              }),
            });
          }
        }
      );
    }
  };

  //FUNCTION TO GET PINCODE LIST ON SELECTING DISTRICT
  districtChangeHandler = (selected_district, type = null) => {
    if (selected_district) {
      let selectedDistrict;
      if (selected_district.value) {
        selectedDistrict = selected_district.value;
      } else {
        selectedDistrict = selected_district;
      }
      let params = {
        // district_name: selectedDistrict,
        // state_id: this.state.checkBoxClicked
        //   ? this.state.contactForm.permanent_state
        //   : this.state.contactForm.present_state,
      };
      HttpAPICall.withAthorization("GET", this.pincodeListUrl + "/" + selectedDistrict, this.props.access_token, params, null, (response) => {
          if (type === "permanent") {
            this.setState({
              permanentPincodeList: response.data.map((s) => {
                return { value: s.pincode, label: s.pincode };
              }),
            });
          } else {
            this.setState({
              presentPincodeList: response.data.map((s) => {
                return { value: s.pincode, label: s.pincode };
              }),
            });
          }
        }
      );
    }
  };

  handleCheckBox = (e) => {
    this.setState({
      checkBoxClicked: !this.state.checkBoxClicked,
    });
  };

  submitContactForm = (e) => {
    e.preventDefault();
    const employeeData = this.props.employeeData;
    const formData = this.state.contactForm;
    const checkBoxClicked = this.state.checkBoxClicked;
    const present_address = {
      address1: formData.present_address1,
      address2: formData.present_address2,
      country: formData.present_country,
      state: formData.present_state,
      city: formData.present_city,
      pincode: formData.present_postCode,
      landmark: formData.present_landmark,
      district: formData.present_district,
    };

    const permanent_address = {
      address1: checkBoxClicked
        ? formData.present_address1
        : formData.permanent_address1,
      address2: checkBoxClicked
        ? formData.present_address2
        : formData.permanent_address2,
      country: checkBoxClicked
        ? formData.present_country
        : formData.permanent_country,
      state: checkBoxClicked
        ? formData.present_state
        : formData.permanent_state,
      city: checkBoxClicked ? formData.present_city : formData.permanent_city,
      pincode: checkBoxClicked
        ? formData.present_postCode
        : formData.permanent_postCode,
      landmark: checkBoxClicked
        ? formData.present_landmark
        : formData.permanent_landmark,
      district: checkBoxClicked
        ? formData.present_district
        : formData.permanent_district,
    };

    const data = {
      id: employeeData.enc_id,
      fname: employeeData.fname,
      present_address,
      permanent_address,
    };

    this.setState({ saveFormSubmitting: true, checkBoxClicked: false });
    HttpAPICall.withAthorization("PUT", this.emp_edit_url,
      this.props.access_token, {}, data, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.props.viewEmployeeDetail(this.props.employeeData?.enc_id, true);
        this.editContactModal.hide();
        this.setState({ contactForm: { ...this.contactFormInit } });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  render() {
    const checkBoxClicked = this.state.checkBoxClicked;
    return (
      <div className="modal fade" id="editContactModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editContactModalLabel">
                Edit Contact Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.loading_form_data ? (
              <Loader />
            ) : (
              <Ax>
                <div className="modal-body">
                  <form
                    id="contactDetailForm"
                    onSubmit={this.submitContactForm}
                  >
                    <div className="tab_content_wrapper">
                      <span className="content_heading">Present Address</span>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="present_address1"
                          className="form-label require"
                        >
                          Address 1
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="present_address1"
                          type="text"
                          value={this.state.contactForm.present_address1}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          required
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Address 1"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label
                          htmlFor="present_address2"
                          className="form-label"
                        >
                          Address 2
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="present_address2"
                          type="text"
                          value={this.state.contactForm.present_address2}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Address 2"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="present_landmark"
                          className="form-label"
                        >
                          Landmark
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="present_landmark"
                          type="text"
                          value={this.state.contactForm.present_landmark}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Please Enter Landmark"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="present_city" className="form-label">
                          City
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="present_city"
                          type="text"
                          value={this.state.contactForm.present_city}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Please Enter City"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="present_country" className="form-label">
                          Country
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.presentCountryList}
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "present_country"
                            );
                            this.countryChangeHandler(selectedOption);
                          }}
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.presentCountryList.find(
                            (c) =>
                              this.state.contactForm.present_country === c.value
                          )}
                          placeholder="Select Country"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="present_state" className="form-label">
                          State
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "present_state"
                            );
                            this.stateChangeHandler(selectedOption);
                          }}
                          options={this.state.presentStateList}
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.presentStateList.find(
                            (state) =>
                              state.value ==
                              this.state.contactForm.present_state
                          )}
                          placeholder="Please Select State"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="present_district"
                          className="form-label"
                        >
                          District
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "present_district"
                            );
                            this.districtChangeHandler(selectedOption);
                          }}
                          options={this.state.presentDistrictList}
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.presentDistrictList.find(
                            (dist) =>
                              dist.value ==
                              this.state.contactForm.present_district
                          )}
                          placeholder="Please Select District"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label
                          htmlFor="present_postCode"
                          className="form-label"
                        >
                          Postal Code
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "present_postCode"
                            );
                          }}
                          options={this.state.presentPincodeList}
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.presentPincodeList.find(
                            (pin) =>
                              pin.value ==
                              this.state.contactForm.present_postCode
                          )}
                          placeholder="Please Select Pincode"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="tab_content_wrapper">
                      <span className="content_heading">Permanent Address</span>
                    </div>
                    <div className="row my-3">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-8">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => this.handleCheckBox(e)}
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label mx-2"
                          for="flexCheckDefault"
                        >
                          Same as Present Address
                        </label>
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="permanent_address1"
                          className="form-label"
                        >
                          Address 1
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="permanent_address1"
                          type="text"
                          value={
                            checkBoxClicked
                              ? this.state.contactForm.present_address1
                              : this.state.contactForm.permanent_address1
                          }
                          className="form-control form-control-sm"
                          autoComplete="off"
                          disabled={checkBoxClicked}
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Address 1"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label
                          htmlFor="permanent_address2"
                          className="form-label"
                        >
                          Address 2
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="permanent_address2"
                          type="text"
                          value={
                            checkBoxClicked
                              ? this.state.contactForm.present_address2
                              : this.state.contactForm.permanent_address2
                          }
                          className="form-control form-control-sm"
                          autoComplete="off"
                          disabled={checkBoxClicked}
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Address 2"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="permanent_landmark"
                          className="form-label"
                        >
                          Landmark
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="permanent_landmark"
                          type="text"
                          value={
                            checkBoxClicked
                              ? this.state.contactForm.present_landmark
                              : this.state.contactForm.permanent_landmark
                          }
                          className="form-control form-control-sm"
                          autoComplete="off"
                          disabled={checkBoxClicked}
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Please Enter Landmark"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="permanent_city" className="form-label">
                          City
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="permanent_city"
                          type="text"
                          value={
                            checkBoxClicked
                              ? this.state.contactForm.present_city
                              : this.state.contactForm.permanent_city
                          }
                          className="form-control form-control-sm"
                          autoComplete="off"
                          disabled={checkBoxClicked}
                          onChange={(e) =>
                            this.formInputHandler(e, "contactForm")
                          }
                          placeholder="Please Enter City"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label
                          htmlFor="permanent_country"
                          className="form-label"
                        >
                          Country
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={
                            checkBoxClicked
                              ? this.state.presentCountryList
                              : this.state.permanentCountryList
                          }
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "permanent_country"
                            );
                            this.countryChangeHandler(
                              selectedOption,
                              "permanent"
                            );
                          }}
                          isSearchable={true}
                          isClearable={true}
                          isDisabled={checkBoxClicked}
                          value={
                            checkBoxClicked
                              ? this.state.presentCountryList.find(
                                  (c) =>
                                    this.state.contactForm.present_country ===
                                    c.value
                                )
                              : this.state.permanentCountryList.find(
                                  (c) =>
                                    this.state.contactForm.permanent_country ===
                                    c.value
                                )
                          }
                          placeholder="Select Country"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="permanent_state" className="form-label">
                          State
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "permanent_state"
                            );
                            this.stateChangeHandler(
                              selectedOption,
                              "permanent"
                            );
                          }}
                          options={
                            checkBoxClicked
                              ? this.state.presentStateList
                              : this.state.permanentStateList
                          }
                          isSearchable={true}
                          isClearable={true}
                          isDisabled={checkBoxClicked}
                          value={
                            checkBoxClicked
                              ? this.state.presentStateList.find(
                                  (state) =>
                                    this.state.contactForm.present_state ===
                                    state.value
                                )
                              : this.state.permanentStateList.find(
                                  (state) =>
                                    this.state.contactForm.permanent_state ===
                                    state.value
                                )
                          }
                          placeholder="Please Select State"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="permanent_city" className="form-label">
                          District
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "permanent_district"
                            );
                            this.districtChangeHandler(
                              selectedOption,
                              "permanent"
                            );
                          }}
                          options={
                            checkBoxClicked
                              ? this.state.presentDistrictList
                              : this.state.permanentDistrictList
                          }
                          isSearchable={true}
                          isClearable={true}
                          isDisabled={checkBoxClicked}
                          value={
                            checkBoxClicked
                              ? this.state.presentDistrictList.find(
                                  (dist) =>
                                    this.state.contactForm.present_district ===
                                    dist.value
                                )
                              : this.state.permanentDistrictList.find(
                                  (dist) =>
                                    this.state.contactForm
                                      .permanent_district === dist.value
                                )
                          }
                          placeholder="Please Select District"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label
                          htmlFor="permanent_postCode"
                          className="form-label"
                        >
                          Postal Code
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "contactForm",
                              "permanent_postCode"
                            );
                          }}
                          options={
                            checkBoxClicked
                              ? this.state.presentPincodeList
                              : this.state.permanentPincodeList
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={
                            checkBoxClicked
                              ? this.state.presentPincodeList.find(
                                  (pin) =>
                                    this.state.contactForm.present_postCode ===
                                    pin.value
                                )
                              : this.state.permanentPincodeList.find(
                                  (pin) =>
                                    this.state.contactForm
                                      .permanent_postCode === pin.value
                                )
                          }
                          placeholder="Please Select Pincode"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    form="contactDetailForm"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-primary mx-2"
                  >
                    Save{" "}
                    {this.state.saveFormSubmitting ? (
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </Ax>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    asset_groups:
      state.app && state.app.asset_groups ? state.app.asset_groups : [],
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EmpContactDetailModal
);
