import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import TapIcon from '../../../services/TapIcon';
import AssetDetailHorizontalCard from '../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import Status from '../../../components/ui/Status';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import moment from "moment";
import AssetHeader from '../includes/AssetHeader';
import { Helmet } from 'react-helmet';
import AssetRentalView from '../measurableAsset/assetRental/AssetRentalView';


class rentalTransaction extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                          =       {
            access_token                    :       '',
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            listingData                     :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            asset_loading                   :       false,
            assetId                         :       null,
            view_loading                    :       false,
            minimizeTable               :          false,
            page                        :          1,
            card_loading                :          false,
            rentalData                  :           null
           
        }
    
    }
    
    componentDidMount           =   ()  =>  {
        this.initalizeData(this.props)
       
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.initalizeData(nextProps)
        }
    }

    
    initalizeData       =       (pr)        =>      {
       
        this.setState({assetId              :       pr.match?.params?.id, },() => {
            this.getAsssetDetails(pr.match?.params?.id)
             this.loadListingTblData(1,pr.match?.params?.id)
            })
    }

  
    getAsssetDetails                =   (id)  =>  {
        let Id  = id ? id  : this.state.assetId
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + Id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => {this.setState({asset_loading: false})})
    }

    //************************GET rentalTransaction LISTING**************** */
    loadListingTblData                 =   (page = 1,id)  =>         {
        this.setState({listing_loading: true})
        let params = {page: page}
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/rental_asset_previous_details/' + id, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                listingMeta                 :   respData.meta
            });
        }, (err) => {
            
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

  
    assetCardHandler                     =   (collapse = true)  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    pageTitleBarJsx          =   ()  =>  {
        let obj = {'search_asset_id' : this.state?.assetDetail?.asset_id, 'asset_name' : `${this.state?.assetDetail?.name} (${this.state?.assetDetail?.asset_code})`};
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}/>
            </div>
        </div>);
    }

    horizontalAssetCardJsx      =       ()      =>  {    
        return (<div>
            <div id="assetCard" className="collapse pr-1 mt-1" aria-expanded="true" >
                {this.state.asset_loading ? <div className='text-center pt-1'><Loader /></div>
                    : <AssetDetailHorizontalCard assetDetail={this.state.assetDetail}/>}
            </div>
        </div>)
    }

    viewRentalTransaction           =       (data)        =>  {
        this.setState({
            view_loading : true,minimizeTable : true,rentalData : data
        })
       
       
    }

   rentalTransactionListJsx            =       ()      =>    {

       return (<Ax>
           <div style={{ paddingTop: "4px" }}>
               <div className="page_title row m0" style={{ height: "45px" }}>
                   <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-6"}>
                       <h3 style={{ paddingTop: "13px" }}>Commercial Transaction History</h3>
                   </div>
                   {!this.state.minimizeTable
                       ? <div className='col-sm-2 '>
                           <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                       </div> : null}

                  
                   {
                       this.state.minimizeTable
                           ? (
                               <Ax>
                                   <div className="col-sm-3 tap_border_left">
                                       <h3 style={{ paddingTop: "13px" }}>Updated on : {this.state.rentalData ? this.state.rentalData.created_at_display : "-"}</h3>
                                   </div>
                                   <div className='col-sm-2 '>
                                       <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}>Asset Card</button>
                                   </div>
                                   <div className='col-sm-4 text-end mt7'>
                                       {/* <span className="dropdown">
                                           <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                               <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                           </button>
                                           <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                               <li>{this.deleteLinkJsx(this.state.executionData?.id)}</li>
                                           </ul>
                                       </span> */}
                                       <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                           <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                       </button>
                                   </div>
                               </Ax>
                           )
                           : null
                   }
               </div>
           </div>
           <div className="container-fluid pl0 pr13">
               <div className="page_containt row">
                   <div className={['pl16', this.state.minimizeTable ? 'col-3 mr0 pr0 wd26' : 'col-12 pr0'].join(' ')}>{this.rentalTransactionTableJsx()}</div>
                   {this.state.minimizeTable ? <div className="col-9 pr4 wd74 pl0"><div className="bg-white p-2" style={{ maxHeight: "69vh", overflowY: "scroll" }}>{this.rentalTransactionViewDetailJsx()}</div> </div> : null}
               </div>
           </div>
       </Ax>);
    }

    rentalTransactionTableJsx                   =   ()  =>  {
       
        return (
            <Ax>
                
                <div className='table-responsive' style={{ maxHeight:  "69vh" }}>
                <table className="table table-bordered bg-white table-fixed table-hover " id="assetrentalTransactionTable">
                    <thead className="table-secondary">
                        {this.state.minimizeTable
                            ? (<tr>
                                <th scope="col" style={{ width: "20%" }}>Status</th>
                            </tr>)
                            : (<tr>
                                <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                <th scope="col" style={{ width: "12%" }}>Updated On</th>
                                <th scope="col" style={{ width: "12%" }} className="text-center">Commercial Status</th>
                                <th scope="col" style={{ width: "9%" }}>Status Since</th>
                                {/* <th scope="col" style={{ width: "12%" }}>Site Name</th> */}
                                <th scope="col" style={{ width: "15%" }}>Client Name</th>
                                <th scope="col" style={{ width: "9%" }}>Commercial Amount</th>
                                <th scope="col" style={{ width: "10%" }}>WO Number</th>
                                <th scope="col" style={{ width: "10%" }}>WO Expiry Date</th>
                                <th scope="col" style={{ width: "13%" }}>Tentative Release Date</th>
                            </tr>)
                        }
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? <tr><td className="text-center" colSpan="10"><Loader /></td></tr>
                            : (this.state.listingData.length > 0
                                ? (this.state.listingData.map((rental, index) => {
                                    return <tr>
                                        {
                                            this.state.minimizeTable
                                                ? (
                                                    <Ax>
                                                        <td><span className='link-primary cursor_pointer' onClick={() => this.viewRentalTransaction(rental)}>{rental.created_at_display ? rental.created_at_display : "-"}</span>
                                                        <br />
                                                            <small>{rental.client ? rental.client.name : "-"} 
                                                            <div className='float-end'><Status color={rental.status_data ? rental.status_data.color : "-"}>{rental.status_data ? rental.status_data.name : "-"}</Status></div></small>
                                                        </td>

                                                    </Ax>
                                                )
                                                : (<Ax>
                                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                    <td className='link-primary cursor_pointer' onClick={() => this.viewRentalTransaction(rental)}>{rental.created_at_display ? rental.created_at_display : "-"}</td>
                                                    <td className='text-center'><Status color={rental.status_data ? rental.status_data.color : "-"}>{rental.status_data ? rental.status_data.name : "-"}</Status></td>
                                                    <td>{rental.status_change_date_display ? rental.status_change_date_display : "-"}</td>
                                                    {/* <td className='text-center'>-</td> */}
                                                    <td>{rental.client ? rental.client.name : "-"}</td>
                                                    <td>{rental.rental_amount ? rental.rental_amount : "0"}</td>
                                                    <td>{rental.wo_detail ? rental.wo_detail : "-"}</td>
                                                    <td>{rental.wo_due_date_display ? rental.wo_due_date_display : "-"}</td>
                                                    <td>{rental.date_release_tentative_display ? rental.date_release_tentative_display : "-"}</td>
                                                </Ax>
                                                )}

                                    </tr>
                                }))
                                : (<tr><td colSpan="10" className="text-center">No Records</td></tr>))
                        }
                    </tbody>
                </table>
                </div>
                <div className='mx-3 my-1'>
                    <DisplayListPagination
                        meta={this.state.listingMeta}
                        onPageChange={(e) => this.loadListingTblData(e.selected + 1, this.state.assetId)}
                    />
                </div>
            </Ax>
        );
    }


    rentalTransactionViewDetailJsx                =   ()   =>   {
        return (<div>

            <AssetRentalView showEdit={"N"} rentalData={this.state.rentalData ? this.state.rentalData : null} />
        </div>)
    }


    render                      =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Asset -Commercial Transaction History</title></Helmet>
            {this.pageTitleBarJsx()}
            {this.horizontalAssetCardJsx()}
            {this.rentalTransactionListJsx()}
        </ApplicationLayout>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(rentalTransaction);