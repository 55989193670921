import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../components/ui/TapSelect';
import tapIcon from '../../services/TapIcon';
import Ax from "../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../components/ui/Loader/Loader";
import AppBaseComponent from "../../components/AppBaseComponent";
import HttpAPICall from "../../services/HttpAPICall";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../services/TapApiUrls';
import InputEmployeeSearch from '../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../includes/ui/InputWorkstationSearch';



class SiteProductivityAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.site_productivity_url      =       TapApiUrls.IAM + '/site_productivity';

        this.initAddProductivityForm           =        {
            "transaction_date"      :   "",
            "activity_id"           :   "",
            "activity_code"         :   "",
            "production"            :   "",
            "uom"                   :   "",
            "material_id"           :   "",
            "material_new"           :   "",
            "attachment_id"         :   "",
            "attachment_new"         :   "",
            "workstation_id"        :   "",
            "workstation_new"         :   "",
            "employee_ids"          :   [],
            "trip"                  :   "",
            "site_id"               :   "",
            "account_code_id"       :   "",
            "configuration_id"      :   "",
            "remarks"               :   ""
        }

        this.state                    =        {
            saveFormSubmitting           :       false,
            addProductivityForm          :       {...this.initAddProductivityForm},
            allActivityList              :       [],
            form_loading                 :       false,
            configurationData            :       null,
            activity_list_loading        :       false,
            allMaterialList              :      [],
            allAttachmentList            :      [],
            allWorkstations             :       [],
            allLinkedSites               :       [],
            selectedEmployee            :       null,
            allAdditonalAttribute       :       [],
            linkedFormula                :       "",
            allUnits                    :       [],
            editProductivity            :       false,
            addNewMaterial              :   'N',
            addNewWorkstation           :   'N',
            addNewAttachment            :   'N',
            runtime_material_creation       :   'N',
            runtime_workstation_creation       :   'N',
            runtime_attachment_creation     :   'N'
        };
    }

    componentDidMount() {
       this.getAllActivityList();
    }

    getAllActivityList                 =   ()  =>         {
        this.setState({activity_list_loading :true});
      
        HttpAPICall.withAthorization('GET',  this.site_productivity_url + '/activity_list', this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                allActivityList     :       respData && respData.length > 0 ? respData.map(dt => {return({value:dt.id,label:`${dt.activity} (Code : ${dt.activity_code})`,code:dt.activity_code})}) : []
            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.productivityData){
                    
                    let data = this.props.location.state.productivityData;
                    let configData =  data && data.configData ? data.configData : null;
                    let activity_id_exists = configData && configData.activity_id && this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.find(ac => ac.activity_id == configData.activity_id) ? "Y" : 'N'
                   
                    let activity_obj = {};
                    activity_obj = activity_id_exists == 'N' ? {value:configData.id,label:`${data.activity_name} (Code : ${configData.activity_code})`,code:configData.activity_code} : null;
                        this.setState({
                            allActivityList : [...this.state.allActivityList,activity_obj],
                            form_loading : true
                        },() => {
                            this.getStepFormData(configData.id,data)
                        })
                    
                }
            })
        }).then(() => this.setState({activity_list_loading :false}));
    }

    getStepFormData                 =   (id,data=null)  =>         {
        this.setState({form_loading :true});
      
        HttpAPICall.withAthorization('GET',  this.site_productivity_url + '/generate_form/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
            let formData            =       respData && respData.formdata ? respData.formdata : null;
            let setting             =       formData && formData.setting ? formData.setting : null;
            let sites                       =   this.props.iam_user_sites;
            this.setState({
                configurationData       :   respData && respData.site_productivity_config  ? respData.site_productivity_config : null,
                linkedFormula           :   respData && respData.site_productivity_config && respData.site_productivity_config.productivity_formula ? respData.site_productivity_config.productivity_formula : "" ,   
                allAdditonalAttribute   :   respData && respData.site_productivity_config && respData.site_productivity_config.additionalAttr_details && respData.site_productivity_config.additionalAttr_details.length > 0 ? respData.site_productivity_config.additionalAttr_details : [],
                allLinkedSites          :   respData && respData.site_productivity_config && respData && respData.site_productivity_config.site_ids && respData && respData.site_productivity_config.site_ids.length > 0 ? this.props.iam_user_sites.filter(site => respData?.site_productivity_config?.site_ids.includes(site.id)).map(mt => {return({value:mt.id,label:mt.site_name})}) : [],
                allMaterialList         :   formData && formData.materials && formData.materials.length > 0 ? formData.materials.map(mt => {return({value:mt.id,label:mt.material_name})}) : [],
                allAttachmentList       :   formData && formData.attachments && formData.attachments.length > 0 ? formData.attachments.map(mt => {return({value:mt.id,label:mt.name})}) : [] ,
                //allWorkstations         :   formData && formData.workstation && formData.workstation.length > 0 ? formData.workstation.map(mt => {return({value:mt.id,label:mt.workstation_name})}) : [],               
                allUnits               :   formData && formData.uom ? [{...formData.uom,label:formData.uom.measuring_unit,value:formData.uom.id}] : [] ,
                runtime_attachment_creation :   setting && setting.runtime_attachment_creation && setting.runtime_attachment_creation == 'Y' ? 'Y' : 'N',
                runtime_material_creation :   setting && setting.runtime_material_creation && setting.runtime_material_creation == 'Y' ? 'Y' : 'N',
                runtime_workstation_creation :   setting && setting.runtime_workstation_creation && setting.runtime_workstation_creation == 'Y' ? 'Y' : 'N',
                // allWorkstations             :   formData && formData.workstation && formData.workstation.length > 0 ?  formData.workstation.map((ws) => {
                //                                         let site    =   sites.find(gs => gs.id == ws.site_id);
                //                                         return {value: ws.id, label:`${ws.workstation_name} (Site: ${site ? site.site_name : ''})`, workstation : ws}
                //                                 }) : [],
            },() => {
                if(data){
                    let updateFormState = [];
                    if (data.additionalAttributes && data.additionalAttributes.length > 0) {
                        data.additionalAttributes.map((i,index) => {
                           
                            let name =  'additional_attributes_' + i.key;
                            updateFormState[name] = i.value
                        });
                    }
                    
                    this.setState({addProductivityForm      :       {
                        ...this.initAddProductivityForm,
                        id                      :   data.id ? data.id : "",
                        "transaction_date"      :   data.transaction_date ? data.transaction_date : "",
                        "activity_id"           :   data.configData ? data.configData.id : "",
                        "activity_code"         :   data.activity_code ? data.activity_code : "",
                        "production"            :   data.production ? data.production : "",
                        "material_id"           :   data.materialData ? data.materialData.id  : "",
                        "attachment_id"         :   data.attachmentData ? data.attachmentData.id  : "",
                        "workstation_id"        :   data.workstationData ? data.workstationData.id  : "",
                        "employee_ids"          :   data.employees && data.employees.length > 0 ? data.employees.map(em => {return(em.enc_id)}) : [],
                        "trip"                  :   data.trip ? data.trip : "",
                        "site_id"               :   data.siteData ? data.siteData.id : "",
                        "account_code_id"       :   data.accountCode ? data.accountCode.id : "",
                        "configuration_id"      :   data.configData ? data.configData.id :"",
                        "remarks"               :   data.remarks ? data.remarks : "",     
                        uom                     :   data.uom ? data.uom : '',
                        ...updateFormState
                    },
                    editProductivity            :   true,
                    searchedWorkstation         :   data.workstationData ? {value : data.workstationData.id,label : data.workstationData.workstation_name} : null,
                    selectedEmployee            :   data.employees && data.employees.length > 0 ? data.employees.map(em => {return({value:em.enc_id,label:em.display_full_name})}) :[]
                })
                }else{
                    this.setState({addProductivityForm      :       {...this.state.addProductivityForm,uom:formData && formData.uom ? formData.uom.id : ''}})
                }
            })
        }).then(() => this.setState({form_loading :false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/site_productivity` })
    }

    calulateProductivityFormula     =       (e)      =>      {
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
           let  list = this.state.allAdditonalAttribute.filter(key => key.type == "numeric"  ? true : false)
            list.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                window[i.formula_key] = this.state.addProductivityForm[name] ? Number(this.state.addProductivityForm[name]) : '';
                if (this.state.linkedFormula  && this.state?.linkedFormula.includes(i.formula_key) && document.getElementById(i.formula_key)) {
                    document.getElementById(i.formula_key).required = "required"
                }
            })
           

            try {   
               let net_productivity =  eval(this.state.linkedFormula);
               this.setState({
                    addProductivityForm     :       {
                        ...this.state.addProductivityForm,
                        production                :       net_productivity ? parseFloat(net_productivity).toFixed(2) : ''
                    }
               })
            }catch(e){
               
            }
        } 
    
        
    }


    submitaddProductivityForm       =       (e)     =>      {
        e.preventDefault();
       
        let additional_attributes = {};
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
            this.state.allAdditonalAttribute.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addProductivityForm[name] ? this.state.addProductivityForm[name] : '';
            })
        } 
 
        
 
        let formData = {
            ...this.state.addProductivityForm, 
            additional_attributes   :   additional_attributes,
            site_productivity_config_id        :   this.state.configurationData ? this.state.configurationData.id : ""
        }
        if(this.state.addProductivityForm && this.state.addProductivityForm.production <= 0){
            toast.error("Net Productivity cannot be less or equal to 0")
        }else{
            if(this.state.editProductivity){
                HttpAPICall.withAthorization('PUT',this.site_productivity_url + '/edit', this.props.access_token, null, { ...formData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/site_productivity`, state: {Id :  response.data.id }})
                }).then(() => this.setState({ addProductivityFormSubmitting: false }))
            }else{
                HttpAPICall.withAthorization('POST',this.site_productivity_url + '/add', this.props.access_token, null, { ...formData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/site_productivity`, state: {Id :  response.data.id }})
                }).then(() => this.setState({ addProductivityFormSubmitting: false }))
            }
        }
    }

    saveProductivityFormJsx         =        ()       =>      {
            let formData = this.state.configurationData ? this.state.configurationData : null;
            let formula                     =       this.state.linkedFormula ? this.state.linkedFormula : ""
            this.state.allAdditonalAttribute.forEach(production => {
                if (formula && formula.includes(production.formula_key)) {
                    formula = formula.replace(production.formula_key, production.name)
                }
            })
           
        return (<form className="bg-white p-3" onSubmit={this.submitaddProductivityForm} id="saveConfigurationForm">

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addProductivityForm.transaction_date
                                ? moment(this.state.addProductivityForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'addProductivityForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Date of Transaction`}

                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center mt-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Activity</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addProductivityForm', 'activity_id', () => {
                                if (selectedOption) {
                                    this.getStepFormData(selectedOption.value)
                                    this.setState({
                                        addProductivityForm: {
                                            ...this.state.addProductivityForm,
                                            activity_code: selectedOption ? selectedOption.code : "",
                                            site_ids: [],
                                        }
                                    })
                                }
                            })
                        }}
                        options={this.state.allActivityList}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allActivityList.find(m => m.value == this.state.addProductivityForm.activity_id)}
                        placeholder="Please Select Activity"
                    />
                </div>

            </div>
            
            {this.state.form_loading ? <Loader />
                : <Ax>
                    {formData && <Ax>
                        <div className='row align-items-center my-3'>
                            <div className='col-sm-3'>
                                <div className='fw-bold col-form-label-sm require'>Net Productivity</div>

                            </div>
                            <div className='col-sm-4'>
                                <input
                                    name="production"
                                    type="number"
                                    value={this.state.addProductivityForm.production}
                                    className="form-control"
                                    autoComplete="off"
                                    onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                    placeholder="Please enter Net Productivity"
                                    disabled={this.state.linkedFormula ? true : false}
                                    required={true}
                                />

                            </div>
                            <div className='form-text col-sm-5 text-sm my-1'>{formula ? <span>Net Productivity = {formula}</span> : ""}</div>

                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className={['form-label'].join(' ')}>Unit of Measurement</label>
                            </div>
                            <div className="col-sm-4">
                                <TapSelect
                                    options={this.state.allUnits}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'uom');
                                    }}
                                    isSearchable={true}
                                    value={this.state.allUnits.find(s => this.state.addProductivityForm.uom == s.value)}
                                    isClearable={true}
                                    placeholder="Select Unit of Measurement"
                                    autoCompelete="off"
                                    isDisabled={true}
                                    required={this.state.addProductivityForm.production ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className={'form-label require'}>Site</label>
                            </div>
                            <div className="col-sm-4">
                                <TapSelect
                                    options={this.state.allLinkedSites}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'site_id');
                                    }}
                                    isSearchable={true}
                                    value={this.state.allLinkedSites.find(s => this.state.addProductivityForm.site_id == s.value)}
                                    isClearable={true}
                                    placeholder="Select Sites"
                                    autoCompelete="off"
                                    required={true}
                                />
                            </div>
                        </div>
                        {formData.number_of_trip && formData.number_of_trip == 'Y' && <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className={['form-label', formData.number_of_trip_required && formData.number_of_trip_required == 'Y' ? 'require' : ''].join(' ')}>Number of Trips/Sessions</label>
                            </div>
                            <div className="col-sm-4">
                                <input
                                    name="trip"
                                    type="text"
                                    value={this.state.addProductivityForm.trip}
                                    className="form-control"
                                    autoComplete="off"
                                    required={formData.number_of_trip_required && formData.number_of_trip_required == 'Y' ? true : false}
                                    onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                    placeholder="Number of Trips/Sessions"

                                />
                            </div>
                        </div>}
                        {formData.material && formData.material == 'Y' && <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className={['form-label', formData.material_required && formData.material_required == 'Y' ? 'require' : ''].join(' ')}>Material</label>
                            </div>
                            <div className="col-sm-4">
                                {this.state.addNewMaterial == "Y"
                                    ? <input
                                        name="material_new"
                                        type="text"
                                        value={this.state.addProductivityForm.material_new}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                        placeholder="Please enter Material"
                                        required={formData.material_required && formData.material_required == 'Y' ? true : false}
                                    />
                                    : <TapSelect
                                        options={this.state.allMaterialList}
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addProductivityForm', 'material_id');
                                        }}
                                        isSearchable={true}
                                        value={this.state.allMaterialList.find(s => this.state.addProductivityForm.material_id == s.value)}
                                        isClearable={true}
                                        placeholder="Select Material"
                                        autoCompelete="off"
                                        required={formData.material_required && formData.material_required == 'Y' ? true : false}
                                    />}

                            </div>
                            {this.state.runtime_material_creation == 'Y' &&
                                <div className="col-sm-2">
                                    <input
                                        name="addNewMaterial"
                                        type="checkbox"
                                        value={this.state.addNewMaterial}
                                        onChange={(e) => {
                                            this.setState({
                                                addNewMaterial: this.state.addNewMaterial == 'N' ? 'Y' : 'N',
                                                addProductivityForm: this.state.addNewMaterial == 'N' ? { ...this.state.addProductivityForm, material_id: '' } : { ...this.state.addProductivityForm }
                                            })
                                        }}
                                        checked={this.state.addNewMaterial == 'Y'}
                                        className="form-check-input"
                                        id="addNewMaterial"

                                    />
                                    <label className="form-check-label text-sm mx-2" htmlFor="addNewMaterial">Add New </label>
                                </div>}
                        </div>}
                        {formData.attachment && formData.attachment == 'Y' && <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className={['form-label', formData.attachment_required && formData.attachment_required == 'Y' ? 'require' : ''].join(' ')}>Attachment</label>
                            </div>
                            <div className="col-sm-4">
                                {this.state.addNewAttachment == "Y"
                                    ? <input
                                        name="attachment_new"
                                        type="text"
                                        value={this.state.addProductivityForm.attachment_new}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                        placeholder="Please enter Attachment"
                                        required={formData.attachment_required && formData.attachment_required == 'Y' ? true : false}
                                    />
                                    :
                                    <TapSelect
                                        options={this.state.allAttachmentList}
                                        changeEvent={(selectedOption) => {
                                            this.tapSelectChange(selectedOption, 'addProductivityForm', 'attachment_id');
                                        }}
                                        isSearchable={true}
                                        value={this.state.allAttachmentList.find(s => this.state.addProductivityForm.attachment_id == s.value)}
                                        isClearable={true}
                                        placeholder="Select Attachment"
                                        autoCompelete="off"
                                        required={formData.attachment_required && formData.attachment_required == 'Y' ? true : false}
                                    />}
                            </div>
                            <div className="col-sm-2">
                                <input
                                    name="addNewAttachment"
                                    type="checkbox"
                                    value={this.state.addNewAttachment}
                                    onChange={(e) => {
                                        this.setState({
                                            addNewAttachment: this.state.addNewAttachment == 'N' ? 'Y' : 'N',
                                            addProductivityForm: this.state.addNewAttachment == 'N' ? { ...this.state.addProductivityForm, attachment_id: '' } : { ...this.state.addProductivityForm }
                                        })
                                    }}
                                    checked={this.state.addNewAttachment == 'Y'}
                                    className="form-check-input"
                                    id="addNewAttachment"

                                />
                                <label className="form-check-label text-sm mx-2" htmlFor="addNewAttachment">Add New </label>
                            </div>
                        </div>}
                        {formData.workstation && formData.workstation == 'Y' && <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className={['form-label', formData.workstation_required && formData.workstation_required == 'Y' ? 'require' : ''].join(' ')}>WorkStation</label>
                            </div>
                            <div className="col-sm-4">
                                {this.state.addNewWorkstation == "Y"
                                    ? <input
                                        name="workstation_new"
                                        type="text"
                                        value={this.state.addProductivityForm.workstation_new}
                                        className="form-control"
                                        autoComplete="off"
                                        onChange={(e) => this.formInputHandler(e, "addProductivityForm")}
                                        placeholder="Please enter Workstation"
                                        required={formData.workstation_required && formData.workstation_required == 'Y' ? true : false}
                                    />
                                    :
                                    <InputWorkstationSearch
                                    placeholder="Please Select Workstation"
                                    changeEvent={(selectedOption) => {
                                        this.setState({
                                            searchedWorkstation : selectedOption ? selectedOption : null,
                                            addProductivityForm : { ...this.state.addProductivityForm, workstation_id: selectedOption ? selectedOption.value : '' }
                                        })

                                    }}
                                    menuPlacement="top"
                                    search_site_id={this.state.addProductivityForm.site_id }
                                    required={formData.workstation_required && formData.workstation_required == 'Y' ? true : false}
                                    value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                                />}
                                   

                            </div>
                            {this.state.runtime_workstation_creation == 'Y' && <div className="col-sm-2">
                                <input
                                    name="addNewWorkstation"
                                    type="checkbox"
                                    value={this.state.addNewWorkstation}
                                    onChange={(e) => {
                                        this.setState({
                                            addNewWorkstation: this.state.addNewWorkstation == 'N' ? 'Y' : 'N',
                                            searchedWorkstation :   null,
                                            addProductivityForm: this.state.addNewWorkstation == 'N' ? { ...this.state.addProductivityForm, workstation_id: '' } : { ...this.state.addProductivityForm }
                                        })
                                    }}
                                    checked={this.state.addNewWorkstation == 'Y'}
                                    className="form-check-input"
                                    id="addNewWorkstation"

                                />
                                <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstation">Add New </label>
                            </div>}
                        </div>}
                        {formData.employee && formData.employee == 'Y' && <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className={['form-label', formData.employee_required && formData.employee_required == 'Y' ? 'require' : ''].join(' ')}>Employee</label>
                            </div>
                            <div className="col-sm-4">
                                <InputEmployeeSearch
                                    placeholder="Please Select Employee"
                                    search_site_ids={this.state.addProductivityForm.site_id}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addProductivityForm', 'employee_ids', () => {
                                            this.setState({
                                                selectedEmployee: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                            })
                                        });

                                    }}
                                    isMulti={true}
                                    required={formData.employee_required && formData.employee_required == 'Y' ? true : false}
                                    value={this.state.selectedEmployee && this.state.selectedEmployee.length > 0 ? this.state.selectedEmployee.map(a => { a.label = a.label; return a; }) : []}
                                />
                            </div>
                        </div>}
                        {
                            this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0
                                ? (
                                    <Ax>

                                        {
                                            this.state.allAdditonalAttribute.map((i, k) => {
                                                let name = 'additional_attributes_' + i.key;
                                                let input = null;
                                                if (i.type == 'text') {
                                                    input = <input
                                                        name={name}
                                                        type="text"
                                                        value={this.state.addProductivityForm[name] ? this.state.addProductivityForm[name] : ''}
                                                        onChange={(e) => this.formInputHandler(e, 'addProductivityForm')}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder={`Please enter ${i.name}`}
                                                    />
                                                } else if (i.type == 'numeric') {
                                                    input = <input
                                                        name={name}
                                                        type="number"
                                                        id={i.formula_key}
                                                        value={this.state.addProductivityForm[name] ? this.state.addProductivityForm[name] : ''}
                                                        onChange={(e) => this.formInputHandler(e, 'addProductivityForm', (e) => this.calulateProductivityFormula(e))}
                                                        className="form-control"
                                                        autoComplete="off"
                                                        placeholder={`Please enter ${i.name}`}
                                                    />
                                                } else if (i.type == 'date') {
                                                    input = <DatePicker
                                                        selected={
                                                            this.state.addProductivityForm[name]
                                                                ? moment(this.state.addProductivityForm[name]).toDate()
                                                                : false
                                                        }
                                                        name={name}
                                                        onChange={(value, event) => this.formDateHandler(name, value, 'addProductivityForm')}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete='off'
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Please Enter ${i.name}`}

                                                    />
                                                } else if (i.type == 'dropdown') {
                                                    let options = i.options.map((t) => { return { value: t, label: t } });
                                                    input = <TapSelect
                                                        menuPlacement="top"
                                                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addProductivityForm', name)}
                                                        options={options}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={options.find(o => o.value == this.state.addProductivityForm[name])}
                                                        placeholder={`Please Select ${i.name}`}
                                                    />
                                                }

                                                return (<div key={k} className="row align-items-center my-3">
                                                    <div className="col-sm-3  col-form-label">{i.name}</div>
                                                    <div className="col-sm-4">{input}</div>

                                                </div>);
                                            })
                                        }
                                    </Ax>
                                )
                                : null
                        }

                        <div className="row align-items-center my-3">
                            <label className="col-sm-3 col-form-label">Remarks</label>
                            <div className="col-sm-4">
                                <textarea
                                    name="remarks"
                                    value={this.state.addProductivityForm.remarks}
                                    onChange={(e) => this.formInputHandler(e, 'addProductivityForm')}
                                    className="form-control"
                                    style={{ height: "100px" }}
                                />
                            </div>
                        </div>
                    </Ax>}
                </Ax>}
            <div className="col-12 text-end fixed_footer mt-4">
                <div className='row'>
                    <div className='offset-3 col-sm-4'>
                        <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                        <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>
                </div>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Site Productivity  - Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.editProductivity ? "Edit" : "Add"} Site Productivity</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.activity_list_loading ? <Loader /> :
                            this.saveProductivityFormJsx()
                        }
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
        iam_user_sites      :       state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(SiteProductivityAdd);