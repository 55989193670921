import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import ItemViewInList from '../includes/ItemViewInList';
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';

import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import { Link } from 'react-router-dom'
import TicketDetailModal from '../../transactions/ticketTransaction/TicketDetailModal';
import ItemDemandDetail from '../itemDemand/ItemDemandDetail';

class AllocationDetail extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            allocationViewData         :       null,
            view_loading                :       false,
            itemDetails                 :       null,
            demandId                    :       ''
        };
       
        this.itemDetailsRef                  =    React.createRef();
        this.ticketDetailRef        =   React.createRef();
        this.detailUrl              =   INVENTORY_API_BASE_URL_2 + '/allocation/'
    }

    componentDidMount() {
        if(this.props && this.props.allocationId){
            this.getAllocationViewDetails(this.props.allocationId)
        }
        if(this.props && this.props.allocationData){
            this.setState({allocationViewData : this.props.allocationData})
        }
        this.viewDemandModal              =     new Modal(document.getElementById('viewDemandModal'), {keyboard: false, backdrop :false});
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.allocationId !== this.props.allocationId){
            this.getAllocationViewDetails(nextProps.allocationId)
         }
         if(nextProps.allocationData !== this.props.allocationData){
             this.setState({allocationViewData : nextProps.allocationData})
         }
         
     }
    

    getAllocationViewDetails             =    (transaction_id)        =>  {
        this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET',  this.detailUrl + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 allocationViewData        : respData.data,
                 allocationLoaded          :  false,
             });
            
         }).then(() => this.setState({view_loading: false}));
        
     }

       
    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    getTicketDetail     =     (id)      =>    {
        this.ticketDetailRef.current.ticketModalInit(id)
    }

    viewDemandModalInit                =   (id)        =>      {
        if (id) {
            this.setState({
                demandId: id
            }, () => {
                setTimeout(() => this.viewDemandModal.show(), 1000)
            })
        }
            
    }

    demandDetailsModalJsx                     =   ()  =>  {
        console.log(this.state.demandId,"wertyuiop")
        return (
            <div className="modal fade" id="viewDemandModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Item Demand Detail </h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                 {this.state.demandId &&  <ItemDemandDetail demandId={this.state.demandId}/>  }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render() {
        let allocationData = this.state.allocationViewData
        return (<Ax>
              {this.state.view_loading ? <Loader />
                : allocationData ?
                    <Ax>
                        <table className="table table-bordered bg-white">
                            <thead className="table-secondary">
                                <tr>
                                    <th style={{ width: "33%" }}>ID</th>
                                    <th style={{ width: "33%" }}>Date</th>
                                    <th style={{ width: "33%" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="col">{allocationData.transaction_id ? allocationData.transaction_id : "-"}</td>
                                    <td scope="col">{allocationData.transaction_date_display ? allocationData.transaction_date_display : "-"}</td>
                                    <td scope="col" className="align-middle"> {allocationData.status_data ? allocationData.status_data.name : "-"} </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="tab_content_wrapper" >
                            <span className="content_heading">Alocation Detail</span>
                        </div>

                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                            <tbody>
                                <tr>
                                    <td style={{ width: "33%" }} >Warehouse</td>
                                    <td colSpan={2}>{allocationData.warehouse_detail ? <div><b>{allocationData.warehouse_detail.name} ({allocationData.warehouse_detail.code})</b> <br />
                                        {allocationData.warehouse_detail ?
                                            <div className='pt-1'>Address : {allocationData.warehouse_detail.address1 ? allocationData.warehouse_detail.address1 + ", " : ""}
                                                {allocationData.warehouse_detail.address2 ? allocationData.warehouse_detail.address2 + ", " : ""}
                                                {allocationData.warehouse_detail.landmark ? allocationData.warehouse_detail.landmark + ", " : ""}
                                                {allocationData.warehouse_detail.city ? allocationData.warehouse_detail.city + ". " : "-"} <br />
                                                State : <b>{allocationData.warehouse_detail.state_name}</b> <br />
                                                GSTIN  : {allocationData.warehouse_detail.gstin ?? "-"} </div> : "-"}</div> : "-"} <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "33%" }} >Allocation For</td>
                                    <th colSpan={2} >{ allocationData.allocation_for === 'asset' && "Asset"  } { allocationData.allocation_for === 'workstation' &&  'Workstation'} { allocationData.allocation_for === 'sub_assembly' && 'Sub Assembly' } { allocationData.allocation_for === 'employee' && 'Employee' }
                                    </th>
                                </tr>
                                <tr>
                                    <td style={{ width: "33%" }} >
                                    { allocationData.allocation_for === 'asset' && "Asset"  } { allocationData.allocation_for === 'workstation' &&  'Workstation'} { allocationData.allocation_for === 'sub_assembly' && 'Sub Assembly' } { allocationData.allocation_for === 'employee' && 'Employee' }
                                    </td>
                                    <td colSpan={2}>{allocationData.allocation_for === 'asset' &&
                                        <div className='text-capitalize'><b>{
                                            allocationData.assetProfile.name} ({allocationData.assetProfile?.asset_code ?? "-"})</b> <br />
                                            Asset Type (Asset Matrix) : {allocationData.assetProfile.asset_type_name ?? "-"} ({allocationData.assetProfile.asset_matrix_unique_id ?? "-"}) <br />
                                            Manufacturer (Model) : {allocationData.assetProfile.manufacturer_name ?? "-"} ({allocationData.assetProfile.model_name ?? "-"})<br />
                                            <br />
                                        </div>
                                        }
                                        {allocationData.allocation_for === 'workstation' && <div><b>{allocationData?.workstation?.workstation_name} ({allocationData?.workstation?.workstation_nickname ?? "-"})</b></div>}

                                        {allocationData.allocation_for === 'sub_assembly' && <div><b>{allocationData?.subAssembly?.name} ({allocationData?.subAssembly?.code ?? "-"})</b></div>
                                        }
                                        {
                                            allocationData.allocation_for === 'employee' &&
                                            <div><b>{allocationData?.employee?.display_full_name}</b><br />
                                                Mobile Number : {allocationData?.employee?.mobile_display}<br />
                                                Status : <Status color={allocationData?.employee?.status_color}>{allocationData?.employee?.status_text}</Status>
                                            </div>
                                        }
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{ width: "33%" }}>Ticket</td>
                                    <td colSpan={2} >{allocationData.ticket ?  <Status color={allocationData.ticket.status_color_code}> <Link onClick={()  =>  this.getTicketDetail(allocationData.ticket.activity_id)}>{allocationData.ticket.activity_id} : {allocationData.ticket.header}</Link> </Status> : "-"}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "33%" }} >Voucher Number & Date</td>
                                    <td style={{ width: "33%" }} >{allocationData.voucher_number ? allocationData.voucher_number : "-"}</td>
                                    <td style={{ width: "34%" }} >{allocationData.voucher_date_display ? allocationData.voucher_date_display : "-"}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: "33%" }}>Item Demand</td>
                                    <td colSpan={2}  className="link-primary cursor_pointer" onClick={() => this.viewDemandModalInit(allocationData.demand_transaction_id)} >{allocationData.demand_transaction_id ? allocationData.demand_transaction_id  : "-"}</td>
                                </tr>




                            </tbody>
                        </table>

                        <div className="tab_content_wrapper" >
                            <span className="content_heading">Item Detail</span>
                        </div>
                        <table className="table table-bordered table-responsive bg-white mt-3">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.props.checkAllItem} id="all_check_allocation" /></th>
                                    <th scope="col" className="text-center">Item Details</th>
                                    
                                    {this.props.isc_configuration?.track_batch_expiry == "Y" ? (<th scope="col" className="text-center" style={{ width: "18%" }}>Batch Number <br />Expiry Date</th>)
                                        : null}

                                    <th scope="col" className="text-end" style={{ width: "11%" }}>Qty</th>
                                    <th scope="col" className="text-end" style={{ width: "11%" }}>Consumed</th>
                                    <th scope="col" className="text-end" style={{ width: "10%" }}>Released</th>
                                    <th scope="col" className="text-end" style={{ width: "10%" }}>Pending</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allocationData && allocationData.items && allocationData.items.length > 0 ? allocationData.items?.map((i, k) => {

                                        return (<tr key={k}>
                                            <td> 
                                            {k+1} <br/><br/>
                                            <input
                                                        type='checkbox'
                                                        name="foo"
                                                        value={i.id}
                                                        data-pendingQty={i.pending_qty}
                                                        className="allocation_item_id_checkbox"
                                                        data-warehouseId={allocationData.warehouse_id}
                                                        data-itemData={i?.itemData}
                                                        data-transactionId={allocationData.transaction_id}
                                                        data-statusLevel={allocationData.status_data && allocationData.status_data.level}
                                                        data-statusIds={allocationData.status_data && allocationData.status_data.id}
                                                    /></td>
                                            <td>{i.itemData ? <div className='row'>
                                                <div className='col-sm-12'>
                                                    <ItemViewInList item={i.itemData} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.itemData)} />
                                                </div>

                                            </div> : null}</td>
                                            
                                            {this.props.isc_configuration?.track_batch_expiry == "Y" ?
                                                <td>{i.itemData ? <div>{i.batch_number} {i.batch_number ? <br /> : null} {i.expiry_date_display ? i.expiry_date_display : "-"}</div> : "-"}</td> : null}
                                            <td className='text-end'>{i.qty}</td>
                                            <td className='text-end'>{i.consumed_qty ? i.consumed_qty : 0}</td>
                                            <td className='text-end'>{i.release_qty ? i.release_qty : 0}</td>
                                            <td className='text-end'>{i.pending_qty ? i.pending_qty : 0}</td>
                                        </tr>)

                                    }) : <tr ><td className='text-center' colSpan={7}>No Record</td></tr>
                                }


                            </tbody>
                        </table>
                        <div className="row align-items-center my-2">
                            <div className="col-6">
                                <label htmlFor="notes" className="form-label">Notes</label>
                                <div>{allocationData.notes ? allocationData.notes : "-"}</div>

                            </div>
                            <div className="col-6">
                                <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                                <table className="table  table-borderless">
                                    <tbody>
                                        <tr className="subtotal_bg_color">
                                            <th>Sub-Total</th>
                                            <td className='float-end'>{allocationData.total_amount ? 
                                             <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(allocationData.total_amount - (allocationData.adjustment_amount)).toFixed(2)} />
                                            : 0}</td>
                                        </tr>
                                        <tr className="subtotal_bg_color">
                                            <th>Adjustment</th>
                                            <td className='float-end'>{allocationData.adjustment_amount ?? 0}</td>
                                        </tr>
                                        <tr className="subtotal_bg_color">
                                            <th>Final Total</th>
                                            <td className='float-end'>{allocationData.total_amount ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(allocationData.total_amount).toFixed(2)}/> : 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                       
                    </Ax>
                    : null}
                    {this.demandDetailsModalJsx()}
                     <ItemDetailsModal ref={this.itemDetailsRef}  /> 
                     <TicketDetailModal ref={this.ticketDetailRef}/>

        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps)(AllocationDetail);