import React from 'react';
import { connect } from 'react-redux';
import AsyncSelect from "react-select/async";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { APP_BASE_URL, APP_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import axios from 'axios';
import ErrorValidation from '../../../services/ErrorValidation';
import Ax from '../../../components/hoc/Ax';
import tapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import swal from "sweetalert";
import AppBaseComponent from '../../../components/AppBaseComponent';
import uuid from 'react-uuid';
import Vendor from '../includes/Vendor';

class DocumentModal extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.documentInitialState   =  { 
            name                        :   "",
            number                      :   "",
            file                        :   null,
            file_name                   :   null,
            base64_document             :   null
        }

        this.state                  =  {
            load_form                   :   false,
            documentSubmitting          :   false,
            other                       :   'N',
            addDocumentForm             :   {...this.documentInitialState},
            documentTypes               :   [],
            logisticDetail              :   {},
        };
       
        this.modalId                =    uuid();   
        this.logisticUrl            =   APP_BASE_URL_2 + '/logistic';
        this.logisticDocUrl         =   APP_BASE_URL + '/logistic';
        this.iscDocTypeUrl          =   ORG_API_BASE_URL_2  + '/setting/isc_doc_types';
       
    }

    componentDidMount(){
        this.addModal                  =   new Modal(document.getElementById(this.modalId), {keyboard: false, backdrop :false});
    }

    loadDocTypesFromApi                 =   ()  =>  {
        this.setState({formDataLoading: true})
        
    }

    showModalInit           =   ()  =>  {  
        this.setState({
            load_form           :   true,
            documentSubmitting  :   false,
            logisticDetail      :   {...this.props.logisticDetail},
        }, () => {
            this.addModal.show();
        });

        HttpAPICall.withAthorization('GET', this.iscDocTypeUrl, this.props.access_token , {}, {}, (response) => {
            this.setState({
                documentTypes   :   response.data.map(s => {return({value : s , label : s})}),
            });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({load_form: false}))

    }

    handleFileChange = async  (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64 = await this.convertBase64(file);
        this.setState({addDocumentForm: { ...this.state.addDocumentForm,base64_document : base64,file_name:filename}})
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    addLogisticDocument =   (e) => {
        e.preventDefault();
        this.setState({documentSubmitting : true});
        let frmData = {
            name                    :   this.state.addDocumentForm.name,
            number                  :   this.state.addDocumentForm.number,
            file_name               :   this.state.addDocumentForm.file_name,
            base64_document         :   this.state.addDocumentForm.base64_document,
            transacton_id           :   this.state.logisticDetail.transaction_id
        };

        HttpAPICall.withAthorization('POST',`${this.logisticDocUrl}/logistic_document/add`,
            this.props.access_token, null, frmData, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.props.getLogisticViewDetails(this.state.logisticDetail.transaction_id);
                this.addModal.hide();
        }).then(() => this.setState({documentSubmitting: false}));
    }

    render() {
        return (
            <div className="modal fade" id={this.modalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModalLabel">Add Document</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        { this.state.load_form 
                        ? <Loader/>
                        : <Ax>
                            <form onSubmit={this.addLogisticDocument}>
                                <div className="modal-body">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document Type</label>
                                        </div>
                                        <div className="col-sm-7">
                                            {
                                                this.state.other == "Y"
                                                ? <input
                                                    name="name"
                                                    type="text"
                                                    value={this.state.addDocumentForm.name}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                                    placeholder="Please enter Type Name"
                                                    required={true}
                                                // autoFocus={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.documentTypes}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addDocumentForm', 'name')}
                                                    value={this.state.documentTypes.find(u => this.state.addDocumentForm.name == u.value)}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Select Document Type"
                                                    autoCompelete="off"
                                                    required={true}
                                                    autoFocus={true}
                                                />
                                            }
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="other"
                                                type="checkbox"
                                                value={this.state.other}
                                                onChange={(e) => { this.setState({ other: this.state.other == 'N' ? 'Y' : 'N' }) }}
                                                checked={this.state.other == 'Y'}
                                                className="form-check-input"
                                                id="other"
                                            />
                                            <label className="form-check-label mx-2" htmlFor="other">New </label>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-3">
                                            <label className="form-label">Document Number</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="number"
                                                type="text"
                                                value={this.state.addDocumentForm.number}
                                                onChange={(e) => this.formInputHandler(e, "addDocumentForm")}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Enter Document Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="file"
                                                type="file"
                                                value={this.state.addDocumentForm.file}
                                                onChange={(e) => {
                                                    this.formInputHandler(e, "addDocumentForm");
                                                    this.handleFileChange(e)
                                                }}
                                                className="form-control"
                                                autoComplete="off"
                                                required={true}
                                                placeholder="Upload Document"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" disabled={this.state.documentSubmitting} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.documentSubmitting} className="btn btn-primary">Submit {this.state.documentSubmitting && <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />}</button>
                                </div>
                            </form>
                            </Ax>
                        }
                    </div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(DocumentModal);