import React from 'react';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from '../../../services/TapIcon';

export default class AccessDenied extends React.Component {

    render() {
          return (<ApplicationLayout>
              <div className='center'>
                  <div> <tapIcon.FontAwesomeIcon icon={tapIcon.faLock} className='fa-4x' color='#45BEA9'/></div>
                  <div><h1>Access Denied</h1></div>
                  <div>You do not have access to this module. To buy Licence for this module, please contact Tappetbox at <a className="link-primary" href="tel:9650-222-555">9650-222-555</a></div>
              </div>
            </ApplicationLayout>);
    }
}
