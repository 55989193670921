import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { connect } from 'react-redux';
import Status from '../../../components/ui/Status';
import Loader from '../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import TapIcon from '../../../services/TapIcon';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { toast } from 'react-toastify';
//import DealsDetailModal from './DealsDetailModal';
import { Helmet } from 'react-helmet';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import { DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import DateService from '../../../services/DateService';
import CustomerProjectSearch from '../includes/CustomerProjectSearch';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import DealsDetail from './DealsDetail';

class DealsBoardManagement extends AppBaseComponent {

  constructor(props) {
    super(props);

    this.initAddRemarkForm      =   {
        status                      :   null,
        remark                     :   '',
    }

    this.dealSearchFormInitialState   =   {
        'search_deal_type_id'             :   '',
        'search_deal_id  '                :   null,
        'search_deal_header'              :   '',
        'search_tagged_users'               :   [],
        'search_ageing_days'                :   '',
        'closure_date_range'                :   '',
        'closure_date_range_start'          :   '',
        'closure_date_range_end'            :   '',
        'creation_date_range'               :   '',
        'creation_date_range_start'         :   '',
        'creation_date_range_end'           :   '',
        'estimate_day_range'                    :   '',
        'estimate_day_range_start'              :   '',
        'estimate_day_range_end'                :   '',
        'remark_ageing_days'                :   '',
        'search_deal_status'              :   [],
        'search_tags'                       :   [],
        'search_customer_ids'                :   [],
        'search_customer_project_ids'         :   []
    };

    this.dealSearchFormLabel      =   {
        'search_deal_type_id'         :   'Deal Type : ',
        'search_deal'                 :   'Deal : ',
        'search_deal_id'              :   'Deal ID : ',
        'search_deal_header'          :   'Deal Header : ',
        'search_tagged_users'           :   'Tagged Users : ',
        'search_ageing_days'            :   'Ageing Days : ',
        'closure_date_range'            :   'Completion Date Range : ',
        'creation_date_range'           :   'Creation Date Range : ',
        'estimate_day_range'                :   'Due Date Range : ',
        'remark_ageing_days'            :   'Remark Ageing Days : ',
        'search_deal_status'          :   'Deal Status : ',
        'deal_with_asset'             :   'Deal With Asset : ',
        'search_tags'                   :   'Tags : ',
        'search_customer_ids'              :   'Customers : ',
        'search_customer_project_ids'     :   'Customer Projects : ',
    };

      this.state = {
          form_loading          :     false,
          columns               :     {},
          allStatusList         :     [],
          remark_form_loading   :    false,
          remarkFormData        :    [],
          allRemarkStatus       :    [],
          addRemarkForm         :     {...this.initAddRemarkForm},
          dealData            :     null,
          draggedItem           :     null,
          draggedItemSourceColumn   :   null,
          dragData                  :   null,
          currentStatusId           :   '',
          dealSearchForm              :            {...this.dealSearchFormInitialState},
            submittedDealFilterForm     :            {...this.dealSearchFormInitialState},
            allDealType                 :            [],
            dealTags                    :               [],
            searchedCustomers           :               [],
            searchedCustomersProject    :               [],
            allTaggedUsers              :            [],
            all_periods                 :           [],
            form_data_loading         :             false
      };
      this.dealDetailRef          =   React.createRef();
      this.dealListUrl            =   INVENTORY_API_BASE_URL_2 + '/deal/list';
      this.dealFormDataUrl        =   INVENTORY_API_BASE_URL_2 + '/deal/form_data';
      this.remarkStatusFormDataUrl=   INVENTORY_API_BASE_URL_2 + '/deal/remark_form_data';
        this.remarkStatusUrl        =   INVENTORY_API_BASE_URL_2 + '/deal/change_status_remark';
      this.dealDetailUrl        =   INVENTORY_API_BASE_URL_2 + '/deal/detail';

  }

  componentDidMount() {
    this.getDealsFormData();
    if(this.props.all_periods){
        let all_periods             =              [...this.props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        this.setState({ all_periods: all_periods })
    }

    this.dealModalDetail            =   new Modal(document.getElementById('dealModalDetail'), {keyboard: false, backdrop :false});
    this.dealSearchModal         =    new Modal(document.getElementById('dealSearchModal'), {keyboard: false, backdrop :false});
    this.addRemarksDragModal            =    new Modal(document.getElementById('addRemarksDragModal'), {keyboard: false, backdrop :false});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.all_periods !== this.props.all_periods) {
        this.setState({ all_periods: [...nextProps.all_periods, { key: 'custom', display: 'Custom Date Range' }] })
    }
} 


  getDealsFormData = async () => {
    this.setState({ form_loading: true,form_data_loading : true });
  
    try {
      const resp = await HttpAPICall.withAthorization('GET', this.dealFormDataUrl, this.props.access_token, {}, {});
      const respData = resp.data;
      const statusList = respData && respData.status && respData.status.length > 0 ? respData.status : [];
      this.setState({
        filterFormData : resp.data,
        allDealType : resp.data.deal_types?.map((s) => { return {value: s.id, label: s.name}}),
               allTaggedUsers : resp.data.users?.map((s) => { return {value: s.id, label: s.full_name}}),
               allStatusList : resp.data.status?.map((s) => { return {value: s.id, label: s.name}}),
                dealTags                  :   resp.data.tags && resp.data.tags.length > 0 ? resp.data.tags.map((tag) => { return {value: tag, label: tag}; }) : [],    
        
    },() => this.setState({form_data_loading : false}))
      const apiCalls = statusList.map(element => {
        return this.loadListingTblData(1, element.id, element.name, element.color);
      });
  
      await Promise.all(apiCalls);
  
      this.setState({ form_loading: false });
    } catch (error) {
      console.error("Error loading form data:", error);
      this.setState({ form_loading: false });
    }
  };

  loadListingTblData = async (
    page = 1,
    status_id = '',
    status_name = '',
    status_color = ''
  ) => {
    this.setState({ listing_loading: true, listing_tbl_page: page });
  
    let params = {
      ...this.state.submittedDealFilterForm,
      page: page,
      per_page: 50,
      search_deal_status: [status_id],
      
    };
  
    try {
      const resp = await HttpAPICall.withAthorization(
        'GET',
        this.dealListUrl,
        this.props.access_token,
        params,
        {}
      );
      const respData = resp.data;
      const newColumns = { ...this.state.columns };
  
      const columnId = `${status_id}`;
      const columnTitle = status_name;
  
      // Initialize column if it doesn't exist yet
      if (!newColumns[columnId]) {
        newColumns[columnId] = {
          id: columnId,
          title: columnTitle,
          status_text: status_name,
          status_color: status_color,
          items: [],
        };
      }
  
      // If no data returned, display "No Dealss Available" message
      if (respData.data.length === 0) {
        newColumns[columnId].items = [{ header: 'No Deals Available for this Status' }];
      } else {
        // If data exists, update items for that status column
        newColumns[columnId].items = respData.data.map((item) => ({
          ...item,
          status_text: item.status_text || status_name,
          status_color: item.status_color || status_color,
        }));
      }
  
      this.setState({ columns: newColumns });
      return respData;
    } catch (error) {
      console.error('Error fetching deal data:', error);
      throw error;
    } finally {
      this.setState({ listing_loading: false });
    }
  };
  
  

  onDragEnd       =     (result)     =>     {
    const { source, destination, draggableId } = result;
    console.log(source, destination, draggableId ,'fff')

    // If the item is dropped outside of a droppable area, return
    if (!destination) return;

    // If the item is dropped in the same position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    } 
    
    // Get the source and destination columns
    const sourceColumn = this.state.columns[source.droppableId];
    const destinationColumn = this.state.columns[destination.droppableId];
    if (source.droppableId !== destination.droppableId) {
      let item = sourceColumn.items.find(st => st.id == draggableId);
      
      this.setState({
        addRemarkForm: { ...this.initAddRemarkForm },
        draggedItem: item, // Store the dragged item in the state for later use
        draggedItemSourceColumn: sourceColumn,
        dragData: result
      })
      this.addRemarksDragModal.show();
      this.getAddRemarkFormData(item, destination.droppableId)
    } else {
      this.setState((prevState) => ({
        columns: {
          ...prevState.columns,
          [sourceColumn.id]: sourceColumn,
          [destinationColumn.id]: destinationColumn,
        },
      }));
    }
    

  };


  getAddRemarkFormData              =         (item,status_id)           =>          {
    this.setState({remark_form_loading: true, });
    HttpAPICall.withAthorization('GET',  this.remarkStatusFormDataUrl, this.props.access_token, {}, {}, (resp) => {
        this.setState( {   remarkFormData  :     resp.data,
            allRemarkStatus      :     resp.data.status.map((s) => { return {value: s.id, label: s.name}}),
            dealData            :     item,
            currentStatusId       :     status_id,
            addRemarkForm         :    {...this.state.addRemarkForm,status : status_id,status_color:item.status_color,status_text:item.status_text,}
            
        })
    }).then(() => this.setState({remark_form_loading: false}));
  }


  submitAddRemarkDragForm = (e) => {
    e.preventDefault();
    const { source, destination, draggableId } = this.state.dragData;
    const sourceColumn = this.state.columns[source.droppableId];
    const destinationColumn = this.state.columns[destination.droppableId];
  
    let frmData = {
      ...this.state.addRemarkForm,
      enc_id                :       this.state.dealData.id,
    };
  
    this.setState({ saveFormSubmitting: true });
  
    HttpAPICall.withAthorization('PUT', this.remarkStatusUrl, this.props.access_token,{}, { ...frmData },  (response) => {
        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
  
        // Update the state of columns and rows after successful API response
        const updatedSourceColumn = { ...sourceColumn };
        updatedSourceColumn.items = updatedSourceColumn.items.filter(
          (item) => item.id !== draggableId
        );
  
        const updatedDestinationColumn = { ...destinationColumn };
        updatedDestinationColumn.items.splice(
          destination.index,
          0,
          sourceColumn.items.find((item) => item.id === draggableId)
        );
  
        this.setState((prevState) => ({
          columns: {
            ...prevState.columns,
            [updatedSourceColumn.id]: updatedSourceColumn,
            [updatedDestinationColumn.id]: updatedDestinationColumn,
          },
        }));
  
        this.addRemarksDragModal.hide();
      }
    ).then(() => {
      this.setState({ saveFormSubmitting: false });

    });
  };

  getDealsDetail     =     (id)      =>    {
    if(id) {
        this.dealModalDetail.show()
      this.getDealViewData(id)
    }
  }
  
  dealSearchClear                  =        ()             =>        {
    this.setState({
        formSearchedElems              :   [],
        searchedCustomers                  :   [],
        searchedCustomersProject                  :   [],
        dealSearchForm               :   {...this.dealSearchFormInitialState},
        submittedDealFilterForm      :   {...this.dealSearchFormInitialState}
    }, () => {
        this.getDealsFormData();
        this.dealSearchModal.hide();
    });
}


  dealTransactionListingJsx        =       ()             =>        {
    let page_containt_style     =   {};
      if(this.props.createFromProject) {
          page_containt_style     =   {...page_containt_style} ;
      }

      return (<Ax>
          <div className="page_title row m0">
              <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                  <h3>Deals</h3>
                  <div className="text-end mt15">
                      <button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={this.dealSearchModalInit}>
                          <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                      </button>
                  </div>
              </div>
          </div>
          <div className="container-fluid pl5">
              <div className="page_containt row" style={page_containt_style}>
                  <div className={['pageTbl', this.state.minimizeTable ? 'col-3 ' : 'col-12'].join(' ')} >{this.dealsBoardJsx()}</div>
              </div>
          </div>
      </Ax>);
}

  dealsBoardJsx         =   ()      =>    {
    const { columns } = this.state;
      return (<Ax>
          <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.dealSearchClear} />
          <DragDropContext onDragEnd={this.onDragEnd}>
              {this.state.listing_loading || this.state.form_loading ? <div className='text-center'><Loader /></div>
                  : <div className="row">
                      <div className="col">
                          <div className="horizontal-scroll-container">
                              {Object.values(columns).map((column) => (
                                  <div key={column.id} className="col ticket-column">
                                      <div className="bg-light p-2"><b> <Status color={column.status_color}>{column.title}</Status></b></div>
                                      <Droppable droppableId={column.id}>
                                          {(provided) => (
                                              <div
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                  style={{ backgroundColor: "none" }}
                                                  className="list-group list-group-flush"
                                              >
                                                  {column.items.map((item, index) => (
                                                      <Draggable
                                                          key={item.id}
                                                          draggableId={item.id}
                                                          index={index}
                                                      >
                                                          {(provided) => (
                                                              <div
                                                                  ref={provided.innerRef}
                                                                  {...provided.draggableProps}
                                                                  {...provided.dragHandleProps}
                                                              // className="list-group-item"
                                                              >
                                                                  <div className="bg-white rounded">
                                                                      <div className='my-1'>
                                                                          <div className='p-2' style={{ backgroundColor: "#DFFFFA", fontWeight: 400 }} onClick={() => this.getDealsDetail(item.id)}>{item.header.slice(0, 40)}{item.header.length > 40 ? '...' : ''}</div>
                                                                          {item.id && <Ax>
                                                                              <div className="px-2 mt-1 text-sm">{item.deal_type ? item.deal_type.name : ""}</div>
                                                                              <div className='d-flex justify-content-between px-2 text-sm'>
                                                                                  <div>{item.created_at ? DateService.dateTimeFormat(item.created_at, 'DD-MMM-YYYY') : ''}</div>
                                                                                  <div>Ageing : {item.aging ? item.aging : "0"} Days</div>
                                                                              </div>
                                                                              <hr className='bg-secondary my-1' />
                                                                              {item.customer && <div className="px-2 text-sm">{item.customer && <span>{item.customer.name}</span>}</div>}

                                                                          </Ax>}
                                                                      </div>

                                                                  </div>

                                                              </div>
                                                          )}
                                                      </Draggable>
                                                  ))}
                                                  {provided.placeholder}
                                              </div>
                                          )}
                                      </Droppable>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </div>}
          </DragDropContext>
      </Ax>)
  }
  
  addRemarkModalJsx       =   ()  =>  {
    return (
        <div className="modal fade" id="addRemarksDragModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addRemrk">Add Remark</h5>
                        <button type="button" className="btn-close" onClick={() => this.addRemarksDragModal.hide()}></button>
                    </div>
                    {this.state.remark_form_loading ? <Loader /> :
                        <form className="bg-white p-2" onSubmit={this.submitAddRemarkDragForm}>
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3"><label className="form-label require">Deal Status</label></div>
                                    <div className="col-sm-5">
                                        <TapSelect
                                            options={this.state.allRemarkStatus}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRemarkForm', 'status')}
                                            value={this.state.allRemarkStatus.find(a => a.value == this.state.addRemarkForm.status)}
                                            isSearchable={true}
                                            isClearable={false}
                                            required={true}
                                            placeholder="Please Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3"><label className="form-label require">Remarks</label></div>
                                    <div className="col-sm-5">
                                        <textarea
                                            required={true}
                                            name="remark"
                                            value={this.state.addRemarkForm.remark}
                                            onChange={(e) => this.formInputHandler(e, 'addRemarkForm')}
                                            className="form-control"
                                            style={{ height: "95px" }}
                                        />
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addRemarkForm: { ...this.initAddRemarkForm } }) }}>Close </button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                            
                        </form>}
                </div>
            </div>
        </div>
    );
}

  dealSearchModalJsx               =        ()             =>        {
    return (
        <div className="modal fade" id="dealSearchModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">Deal Search</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {this.state.form_loading ? <Loader />
                        : <form id="dealSearchForm" onSubmit={this.submitDealSearchForm}>
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Deal Type</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Deal Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            options={this.state.allDealType}
                                            value={this.state.allDealType.find(r => r.value === this.state.dealSearchForm.search_deal_type_id)}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_deal_type_id')}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Tags</label>
                                    <div className="col-sm-4  ">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select Tags"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                            options={this.state.dealTags}
                                            value={this.state.dealTags.filter(s => this.state.dealSearchForm.search_tags && this.state.dealSearchForm.search_tags.includes(s.value))}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_tags')}
                                        />
                                    </div>
                                   
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Deal Header</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_deal_header"
                                            className="form-control form-control-sm"
                                            placeholder="Deal Header"
                                            autoComplete="off"
                                            value={this.state.dealSearchForm.search_deal_header}
                                            onChange={(e) => this.formInputHandler(e, 'dealSearchForm')}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Deal Status</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allStatusList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_deal_status')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allStatusList.filter(s => this.state.dealSearchForm.search_deal_status.includes(s.value))}
                                            placeholder="Select Deal Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Customers</label>
                                    <div className="col-sm-4 ">

                                        <InputCustomerSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_customer_ids');
                                                this.setState({ searchedCustomers: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.searchedCustomers.length > 0 ? this.state.searchedCustomers.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Customers Project</label>
                                    <div className="col-sm-4 ">

                                        <CustomerProjectSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_customer_project_ids');
                                                this.setState({ searchedCustomersProject: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.searchedCustomersProject && this.state.searchedCustomersProject.length > 0 ? this.state.searchedCustomersProject.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Tagged User</label>
                                    <div className="col-sm-4 ">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Select User"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                            options={this.state.allTaggedUsers}
                                            value={this.state.allTaggedUsers.filter(s => this.state.dealSearchForm.search_tagged_users.includes(s.value))}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dealSearchForm', 'search_tagged_users')}

                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Deal Ageing</label>
                                    <div className="col-sm-4 ">
                                        <input
                                            type="text"
                                            name="search_ageing_days"
                                            className="form-control form-control-sm"
                                            placeholder="Example. 0-5 for a range or 4 for Deal Aging"
                                            autoComplete="off"
                                            value={this.state.dealSearchForm.search_ageing_days}
                                            onChange={(e) => this.formInputHandler(e, 'dealSearchForm')}
                                        />

                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Creation Date </label>
                                    <div className="col-sm-4 ">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods}
                                            required={false}
                                            value={this.state.dealSearchForm.creation_date_range}
                                            startDate={this.state.dealSearchForm.creation_date_range_start}
                                            endDate={this.state.dealSearchForm.creation_date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    dealSearchForm: { ...this.state.dealSearchForm, creation_date_range: period, creation_date_range_start: startDate, creation_date_range_end: endDate }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Completion Date </label>
                                    <div className="col-sm-4 ">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods}
                                            required={false}
                                            value={this.state.dealSearchForm.closure_date_range}
                                            startDate={this.state.dealSearchForm.closure_date_range_start}
                                            endDate={this.state.dealSearchForm.closure_date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    dealSearchForm: { ...this.state.dealSearchForm, closure_date_range: period, closure_date_range_start: startDate, closure_date_range_end: endDate }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm   ">Due Date</label>
                                    <div className="col-sm-4 ">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods}
                                            required={false}
                                            value={this.state.dealSearchForm.estimate_day_range}
                                            startDate={this.state.dealSearchForm.estimate_day_range_start}
                                            endDate={this.state.dealSearchForm.estimate_day_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    dealSearchForm: { ...this.state.dealSearchForm, estimate_day_range: period, estimate_day_range_start: startDate, estimate_day_range_end: endDate }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Remark Ageing</label>
                                    <div className="col-sm-4 ">
                                        <input
                                            type="text"
                                            name="remark_ageing_days"
                                            className="form-control form-control-sm"
                                            placeholder="Example. 0-5 for a range or 4 for Remark Aging"
                                            autoComplete="off"
                                            value={this.state.dealSearchForm.remark_ageing_days}
                                            onChange={(e) => this.formInputHandler(e, 'dealSearchForm')}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.dealSearchClear}>Clear</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    );
}

dealSearchModalInit               =       ()             =>        {
    this.dealSearchModal.show();
    this.setState({filterLoading:true})
}

getDealViewData                   =      (id)            =>        {
    this.setState({view_loading : true})
    HttpAPICall.withAthorization('GET', this.dealDetailUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
        let respData = resp.data;
        this.setState({
            deal_view_data  : respData,
            dealHeader      : respData.header,
        },() => {
          
        });
        
    }).then(() => this.setState({view_loading: false}));
}

submitDealSearchForm              =        (e = null)            =>        {
    e && e.preventDefault()
   let serachFormKeys              =   Object.keys(this.state.dealSearchForm);
  
   let searchedElems               =   [];
   let searchParams                =   {};
   serachFormKeys.map((key)        =>  {
       
       let label                       =   this.dealSearchFormLabel[key];
       let value                       =   this.state.dealSearchForm[key];
       if(value !== null && value.toString().length > 0) {
           searchParams[key]      =   value;
      if(label) {
               let show_val            =   value;
              
               if(key == 'search_customer_ids') {
                   show_val            =   this.state.searchedCustomers && this.state.searchedCustomers.length > 0 ? this.state.searchedCustomers.map((emp, i) => emp.label).join(', ') : '';
               }
               if(key == 'search_customer_project_ids') {
                   show_val            =   this.state.searchedCustomersProject && this.state.searchedCustomersProject.length > 0 ? this.state.searchedCustomersProject.map((emp, i) => emp.label).join(', ') : '';
               }
               if(key == 'search_tags') {
                   show_val            =   this.state.dealTags.filter(s => this.state.dealSearchForm.search_tags && this.state.dealSearchForm.search_tags.includes(s.value)).map(s => s.label).join(', ');
               }
               
               if(key == 'search_deal_type_id') {
                   show_val            =   this.state.allDealType.filter((s) => value === s.value).map(s => s.label).join(', ');
               }
               if(key == 'search_deal_header') {
                   show_val            =   this.state.dealSearchForm ? this.state.dealSearchForm.search_deal_header : '';
               }
              
               if(key == 'search_ageing_days') {
                   show_val            =   this.state.dealSearchForm ? this.state.dealSearchForm.search_ageing_days : '';
               }
               if(key == 'closure_date_range') {
                   show_val            = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
               }
               if(key == 'creation_date_range') {
                   show_val            = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
               }
               if(key == 'estimate_day_range') {
                   show_val            = this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date);
               }
               if(key == 'remark_ageing_days') {
                   show_val            = this.state.dealSearchForm ? this.state.dealSearchForm.remark_ageing_days : '';
               }
               if(key == 'search_tagged_users') {
                   show_val            =   this.state.allTaggedUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
               }
               
               if(key == 'search_deal_status') {
                   show_val            =   this.state.allStatusList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
               }
              
              
             searchedElems.push(label + `<b>${show_val}</b>`);

           }
       }
   });
   this.setState({
       submittedDealFilterForm   :   {...this.state.dealSearchForm},
       formSearchedElems           :   searchedElems
   }, () => {
       this.dealSearchModal.hide()
       this.getDealsFormData();
   });
}

dealDeailModalJsx               =       ()          =>      {
    let dealData        =   this.state.deal_view_data;
    return (<div className="modal fade" id="dealModalDetail" tabIndex="-1">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 id="searchAssetModalLabel">Deal Detail</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className="modal-body p-0">
                    <div className='px-3 pt-2'>
                        {this.state.view_loading ? <Loader /> :
                            <DealsDetail performAction='N' dealData={dealData} />}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

  render() {
   
       return( <ApplicationLayout>
          <Helmet><title>Deals List Board</title></Helmet>
          {this.dealTransactionListingJsx()}
          {this.dealSearchModalJsx()}
            {this.dealDeailModalJsx()}
            {this.addRemarkModalJsx()}
        </ApplicationLayout>)

  }
}

const mapStateToProps = state => {
  return {
      access_token            :   state.auth.access_token,
      permissions             :   state.app.acl_info.permissions,
      iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
      iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
      asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [], 
  };
};


export default connect(mapStateToProps,null, null, { forwardRef: true })(DealsBoardManagement);