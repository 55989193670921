import React from 'react';
import moment from "moment";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import axios from "axios";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import AssetCard from "../../includes/assetCard/AssetCard";
import errorValidation from "../../../services/ErrorValidation";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import mapDispatchToProps from "react-redux/lib/connect/mapDispatchToProps";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import ScsExecutionHistoryView from './ScsExecutionHistoryView';
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import Status from '../../../components/ui/Status';


class ScsExecutionHistoryList extends AppBaseComponent {

    
    constructor(props) {
        super(props);

        this.executeSearchInitState         =   {
            'search_asset_ids'              :   [],
            'asset_group_ids'               :    [],
            'search_asset_type_ids'         :   [],
            'search_site_ids'               :   [],
            'search_scs_name'               :   '',
            'search_transaction_id'         :   '',
            'search_counter_log_transaction_id'  :   '',
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
           'scs_transaction_delay'          :   ''
        };

        this.executeSearchFormLabel         =   {
            'search_asset_ids'               :   'Asset : ',
            'asset_group_ids'                :   'Asset Type Groups : ',
            'search_asset_type_ids'          :   'Asset Type : ',
            'search_site_ids'                :   'Site : ',
            'search_scs_name'                :   'SCS Name : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'search_counter_log_transaction_id'   :   'Counterlog Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
            'scs_transaction_delay'          :   'Transaction Delay : '

        };

        this.state                      =  {
            listing_loading             :          false,
            listingMeta                 :          null,
            minimizeTable               :          false,
            scsHistoryListing           :          [],
            page                        :          1,
            scsDetails                  :          [],
            card_loading                :          false,
            assetCardDetails            :          [],
            totalListingCount           :          0,
            view_loading                :          false,
            executionId                 :          null,
            executionData               :          null,
            executeSearchForm           :         {...this.executeSearchInitState},
            formSearchedElems           :         [],
            searchedAsset               :         [],
            executeSearchParams           :        null,
            iam_asset_types             :        [],
            iam_group_sites              :        [],
            all_periods                 :        [],
            allTransactionDelay         :       [],
            asset_id                    :       '',
            allAssetTypesGroupsList         :   []
        };
    }

    componentDidMount() {
        // if(this.props.location.search){
            this.setState({listing_loading:true})
        //   }else{
        //     this.getServiceHistoryListing(this.state.page)
        //   }
        setTimeout(() => {
            this.searchFormDataInit(this.props);
        },1000)
        // this.searchFormDataInit(this.props)
        this.executionSearchModal         =    new Modal(document.getElementById('executionSearchModal'), {keyboard: false, backdrop :false});
    }

    // componentWillReceiveProps(nextProps) {
    //     if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
    //         this.searchFormDataInit(nextProps) 
    //     }
    // }

    searchFormDataInit                =   (props)  =>  {
        
        let all_periods             =       [...props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/scs/execution_form_data', this.props.access_token, {})
            .then((response) => {
                this.setState({
                iam_asset_types         :    props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                iam_group_sites         :    props.iam_group_sites ? props.iam_group_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                all_periods             :    all_periods,
                allTransactionDelay     :    response.data && response.data.transactional_delay ? response.data.transactional_delay.map((td) => { return {value: td.key, label: td.text}}) : [],
                allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
            },() => {
                let ParamObject                 =       new URLSearchParams(this.props.location.search);
              
                if (ParamObject.get('search_site_ids') || ParamObject.get('entry_date_range') || ParamObject.get('scs_transaction_delay')) {
                    let updateFilterForm = { 'entry_date_range': ParamObject.get('entry_date_range'), 'scs_transaction_delay': ParamObject.get('scs_transaction_delay') };

                    if (ParamObject.get('search_site_ids')) {
                        updateFilterForm['search_site_ids'] = [ParamObject.get('search_site_ids')]
                    }

                    setTimeout(() => {
                        this.setState({ executeSearchForm: { ...this.state.executeSearchForm, ...updateFilterForm } },
                            function () {
                                this.executeSearchFormSubmit();
                            });
                    }, 1)

                }else{
                    this.getServiceHistoryListing(this.state.page)  
                }
             });
        })
    }

    executeSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.executeSearchForm);
        let searchedElems               =   [];
        let executeSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.executeSearchFormLabel[key];
            let value                       =   this.state.executeSearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                executeSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAsset ? this.state.searchedAsset.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_scs_name') {
                        show_val            =   this.state.executeSearchForm && this.state.executeSearchForm.search_scs_name ? this.state.executeSearchForm.search_scs_name : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.executeSearchForm && this.state.executeSearchForm.search_transaction_id ? this.state.executeSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_counter_log_transaction_id') {
                        show_val            =  this.state.executeSearchForm && this.state.executeSearchForm.search_counter_log_transaction_id ? this.state.executeSearchForm.search_counter_log_transaction_id : '';
                    }
                    if(key == 'scs_transaction_delay') {
                        show_val            =   this.state.allTransactionDelay.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                       let  start_range = moment(this.state.executeSearchForm.date_range_start).format('DD-MMM-YYYY');
                       let end_range = moment(this.state.executeSearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                        show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range = moment(this.state.executeSearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.executeSearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems : searchedElems,
           executeSearchParams : executeSearchParams
        }, () => {
            this.getServiceHistoryListing(1);
            this.executionSearchModal.hide();
        });
    }

    
    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    executionSearchModalInit        =       ()      =>      {
        this.executionSearchModal.show()
    }

    viewHistory(id){
        this.setState({minimizeTable:true})
        if(id !== this.state.executionId) {
            this.getExecutionHistoryData(id)
        }

    }

    getExecutionHistoryData           =      (id)        =>        {
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/scs/execution_detail/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                executionData    :  respData.data,
                asset_id         :  respData.data.asset_id
            });
            if(respData.data.asset_id){
                this.getAssetCard(respData.data.asset_id)
            }

        }).then(() => this.setState({view_loading: false}));
    }

    getAssetCard                        =      (id)            =>       {
        this.setState({card_loading: true});
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/asset/' + id, this.props.access_token, {}, {}, (resp) => {
            this.setState({assetCardDetails : resp.data.data})
        }).then(() => this.setState({card_loading: false}));
    }


    getServiceHistoryListing(page){

        this.setState({listing_loading: true})
        let params = {page: page,get_asset_profile:"Y",...this.state.executeSearchParams}
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/scs/execution_history' , this.props.access_token, {...params}, {}, (response) => {
           let respData = response.data;
            this.setState({
                scsHistoryListing   :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
            })
        }).then(() => this.setState({listing_loading: false }));
    }

    executeSearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems   :   [],
            executeSearchParams   :   null,
            searchedAsset       :   null,
            executeSearchForm     :   {...this.executeSearchInitState}
        }, () => {
            this.getServiceHistoryListing(1);
            this.executionSearchModal.hide();
        });
    }

     //***********************DOWNLOAD EXCEL **************************** */
     executionDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.executeSearchParams};
        if(params && params.date_range && (params.date_range !== '' || typeof(params.date_range) == 'undefined')) {
            this.executionDownloadStart(params);
        } else {
            swal({ title: "Download",
                text: "The download will be for Current Quarter Transaction, in case you want different Transaction Period then please change by filter.",
                icon: "warning",
                buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_quarter"}
                    this.executionDownloadStart(params);
                }
            });
        }
    }
     
     executionDownloadStart                   =   (params)  =>  {
         this.setState({reportDownloading : true});
         HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/scs/execution_history', this.props.access_token, {...params, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
     }

    deleteLinkJsx                       =   (transaction_id)  =>  {
        return <a role="button" 
            onClick={() => { if(this.props.permissions.includes('iam-asset-scs-execute-delete'))  { 
                    return this.deleteScsExecution(transaction_id) 
                } }} 
            className={['dropdown-item', this.props.permissions.includes('iam-asset-scs-execute-delete') ? '' : 'disabled'].join(' ')} 
            title={!this.props.permissions.includes('iam-asset-scs-execute-delete') ? 'You do not have permission to perform this action' : ''} 
            style={{"pointerEvents" : "all"}}
        >Delete</a>;
    }

    deleteScsExecution = (id) => {
        swal({
            title: "Delete",
            text: "Are you sure want to delete this execution of this service checksheet permanently?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_IAM_API_SERVER + '/scs/execution_delete/' + id, this.props.access_token,{},{},(resp) => {
                        toast.success(resp.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getServiceHistoryListing(1)
                        this.closeMinimizeTable()
                    });
                }
            });
    }

    scsListjsx                         =   ()  =>  {

        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                {this.state.minimizeTable ? <h3>Service CheckSheet </h3> : <h3>Service CheckSheet Execution History</h3>}
                <div className="text-end mt15">
                    <button type="button" onClick={this.executionSearchModalInit} className="btn btn-secondary" disabled={this.state.listing_loading}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    {!this.state.minimizeTable ?
                        <button type="button" className="btn btn-secondary"
                            disabled={this.state.reportDownloading || this.state.listing_loading}
                            onClick={this.executionDownloadHandler} >
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                            {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                        : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>SCS : {this.state.executionData ? this.state.executionData.transaction_id : "-"}</h3>
                            <div className="text-end mt15">
                                <span className="dropdown">
                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li>{this.deleteLinkJsx(this.state.executionData?.id)}</li>
                                    </ul>
                                </span>
                                <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.listingTableTrJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.scsDetailJsx()}</div> : null}
                </div>
            </div>
        </Ax>);
    }

    listingTableTrJsx                   =   ()  =>  {
        return (
            <Ax>
                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.executeSearchClear} />
                <table className="table table-bordered bg-white table-fixed table-hover  table-sm">
                    <thead className="table-secondary">
                        {this.state.minimizeTable
                            ? (<tr>
                                <th scope="col" style={{ width: "20%" }}>Transaction</th>
                            </tr>)
                            : (<tr>
                                <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                <th scope="col" style={{ width: "8%" }}>Transaction Date</th>
                                <th scope="col" style={{ width: "10%" }}>Transaction Id</th>
                                <th scope="col" style={{ width: "18%" }}>Asset</th>
                                <th scope="col" style={{ width: "18%" }}>CheckSheet Name</th>
                                <th scope="col" style={{ width: "10%" }} className="text-center">Execution Site</th>
                                <th scope="col" style={{ width: "10%" }}>Parameter</th>
                                <th scope="col" style={{ width: "16%" }}>CounterLog</th>
                                <th scope="col" style={{ width: "5%" }}>Action</th>
                            </tr>)
                        }
                    </thead>
                    <tbody>
                        {this.state.listing_loading
                            ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                            : (this.state.scsHistoryListing.length > 0
                                ? (this.state.scsHistoryListing.map((scs, index) => {
                                    return <tr>
                                        {
                                            this.state.minimizeTable
                                                ? (
                                                    <Ax>
                                                        <td><a role="button" className="link-primary" onClick={() => this.viewHistory(scs.id)} >
                                                            {scs.transaction_id}</a>
                                                            <br /><small>{scs.scs_setting?.alias_name ? scs.scs_setting?.alias_name : scs.scs_setting?.name}</small>
                                                            <br /><small>{scs.asset_profile.name} ({scs.asset_profile.asset_code})</small>
                                                        </td>

                                                    </Ax>
                                                )
                                                : (<Ax>
                                                    <td  className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                    <td className='text-center'>{scs.feed_date_display ? scs.feed_date_display : "-"}</td>
                                                    <td><a role="button" className="link-primary" onClick={() => this.viewHistory(scs.id)} >{scs.transaction_id}</a></td>
                                                    <td> {scs.asset_profile ? <span>{scs.asset_profile.name} ({scs.asset_profile.asset_code})</span> : null}
                                                        <br />Asset Type : {scs.asset_profile ? `${scs.asset_profile.asset_type_name}` : ``}</td>
                                                   
                                                    <td >{scs.scs_setting?.alias_name ? scs.scs_setting?.alias_name : scs.scs_setting?.name}</td>
                                                    <td>{scs.site ? scs.site.site_name : "-"}</td>
                                                    <td >{scs.parameter && scs.parameter.execute_display && scs.parameter.execute_display
                                                        ?<div>
                                                            <Status color={scs.parameter.execute_display.color}>{scs.parameter.type == "range" ? scs.parameter.execute_level :scs.parameter.execute_display.text}</Status>
                                                            {scs.parameter.type == "range" ? <div className='my-1'>{scs.parameter.type_display}</div> : null}
                                                        </div> 
                                                        : 'NA'}</td>
                                                      <td className="text-start"> {scs.executedCounterlog && scs.executedCounterlog.length > 0 ? scs.executedCounterlog.map((item, index) => { return (<div className="mt-1"> {item.label} : {item.reading}  {item.unit} <br /></div>) })
                                                        : "-"}</td>
                                                    <td className='text-center'>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" role="button" onClick={() => this.viewHistory(scs.id)}>View Execution</a></li>
                                                        </ul>
                                                    </td>

                                                </Ax>
                                                )}

                                    </tr>
                                }))
                                : (<tr><td colSpan="8" className="text-center">No Records</td></tr>))
                        }
                    </tbody>
                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.getServiceHistoryListing(e.selected + 1)}
                />
            </Ax>
        )

    }

    scsDetailJsx                       =   ()  =>  {
     
        return (<section className="item_view_container page_containt pt-0">
            <div className="pageTbl bg-white">
                <div className="row p-3">
                    <div className="col-8">
                        {this.state.view_loading ? <div className='text-center'><Loader /></div>
                            : this.state.executionData ? <ScsExecutionHistoryView executionData={this.state.executionData} /> : null}
                    </div>
                    <div className="col-4">
                        {this.state.card_loading ? <div className='text-center'><Loader /></div>
                            : this.state.executionData ? <AssetCard assetCardDetails={this.state.assetCardDetails} refreshAssetDetail={() => this.getAssetCard(this.state.asset_id)} /> : null}

                    </div>
                </div>
            </div>
        </section>);
    }

    executionSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="executionSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="executionSearchModalLabel">SCS Execution History Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.executeSearchFormSubmit} id="executionSearchForm">
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            value={this.state.executeSearchForm.search_transaction_id}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'executeSearchForm')}
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Counterlog Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_counter_log_transaction_id"
                                            value={this.state.executeSearchForm.search_counter_log_transaction_id}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'executeSearchForm')}
                                            placeholder="Counterlog Transaction ID"
                                            autoComplete="off"
                                        />
                                    </div>

                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">SCS Name</label>
                                    <div className="col-sm-10">
                                        <input
                                            type="text"
                                            name="search_scs_name"
                                            value={this.state.executeSearchForm.search_scs_name}
                                            className="form-control form-control-sm"
                                            onChange={(e) => this.formInputHandler(e, 'executeSearchForm')}
                                            placeholder="SCS Name"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'executeSearchForm', 'search_asset_ids');
                                                this.setState({ searchedAsset: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            search_asset_type_ids={[this.state.executeSearchForm.search_asset_type_ids]}
                                            isMulti={true}
                                            value={this.state.searchedAsset && this.state.searchedAsset.length > 0 ? this.state.searchedAsset.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type Group</label>
                                    <div className="col-sm-10">
                                    <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    executeSearchForm: {
                                                        ...this.state.executeSearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.executeSearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Asset Type</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'executeSearchForm', 'search_asset_type_ids')}
                                            value={this.state.iam_asset_types.filter(s => this.state.executeSearchForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Execution Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'executeSearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.executeSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Execution Site Name"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction Delay</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allTransactionDelay}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'executeSearchForm', 'scs_transaction_delay')}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={this.state.allTransactionDelay.find(s => this.state.executeSearchForm.scs_transaction_delay == s.value)}
                                            placeholder="Select Transaction Delay"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.executeSearchForm.entry_date_range}
                                            startDate={this.state.executeSearchForm.entry_date_range_start}
                                            endDate={this.state.executeSearchForm.entry_date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    executeSearchForm
                                                        : {
                                                        ...this.state.executeSearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"

                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.executeSearchForm.date_range}
                                            startDate={this.state.executeSearchForm.date_range_start}
                                            endDate={this.state.executeSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    executeSearchForm
                                                        : {
                                                        ...this.state.executeSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"

                                        />

                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render = () => {
        return (<ApplicationLayout>
         <Helmet><title>Service Checksheet Execution List</title></Helmet>
            {this.scsListjsx()}
            {this.executionSearchModalJsx()}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        permissions             :   state.app.acl_info.permissions,
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [], 
    };
};

export default connect(mapStateToProps)(ScsExecutionHistoryList);