import React from 'react';
import ApplicationLayout from "./../layouts/ApplicationLayout";
import DownloadFile from '../services/DownloadFile';
import HttpAPICall from '../services/HttpAPICall';
import TapApiUrls from '../services/TapApiUrls';
import { connect } from 'react-redux';


class Test1 extends React.Component {
    
    constructor(props) {
        super(props);
        this.state  =   {currentNumber : 1};
    }
    
    increaseNum         =   ()  =>  {
        this.setState({currentNumber : this.state.currentNumber+1});
        
    }
    
    render() {
        return (<div id="mydiv">
                <p className="text-center bg-primary text-white my-3">Current Number : {this.state.currentNumber}</p>
                
                <button type="button" onClick={this.increaseNum} className="btn btn-warning my-2">Child Button</button>  
        </div>);
    }
    
}



class Testing extends React.Component {
    
    
    constructor(props) {
        super(props);
        
        this.parentRef      =           React.createRef();
        this.parentRef2      =           React.createRef();
    }
    
    clickBtn = () => {
        this.parentRef.current.increaseNum();
        this.parentRef2.current.increaseNum();
    }
        

    render() {
          return (<ApplicationLayout>
            <div className='center'>
                <Test1 ref={this.parentRef} name="Vinay" /> 
                <button type="button" onClick={this.clickBtn} className="btn btn-danger">parent Componnet Button</button>
                <Test1 ref={this.parentRef2} name="Sachan" /> 
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(Testing);