import React from 'react';
import AssetsMatrixCompliance from './AssetMatrixCompliance';
import { ToastContainer } from 'react-toastify';


const AssetsMatrixConfiguration = () => {
 

    return (
        <section className="item_view_container bg-white">
            <ToastContainer />
      <AssetsMatrixCompliance />
        </section>
    );
};

export default AssetsMatrixConfiguration;
