import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import tapIcon from "../../../services/TapIcon";
import Status from "../../../components/ui/Status";
import HttpAPICall from "../../../services/HttpAPICall";
import { ORG_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import Loader from "../../../components/ui/Loader/Loader";
import { Modal } from "bootstrap";
import swal from "sweetalert";
import { toast } from "react-toastify";
import * as actions from '../../../store/actions/index';
import { DisplayListPagination } from "../../../components/TapUi";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";


class UserLinkAssets extends AppBaseComponent {

    constructor() {
        super();

        this.state                  =   {
            saveLinkedAssetFormSubmitting   :       false,
            searchedAssets                  :       [],
            linkedAssets                    :       [],
            linkedAssetsList                :       [],
            linked_assets_loading           :       false,
            assetlistingMeta                :       null,
            userDetailData              :   null,
        };

        this.userAssetsLinkUrl      =   ORG_API_BASE_URL_2  +   '/user/assets-link';
        this.userAssetsDeLinkUrl    =   ORG_API_BASE_URL_2  +   '/user/assets-delink';        

    }


    componentDidMount() {
         this.initilaizeData(this.props);
         this.setState({linked_assets_loading : true})
         setTimeout(() => {
            this.setState({
                linked_assets_loading       : false
            });
        },1000)
         this.linkAssetsModal                     =   new Modal(document.getElementById('linkAssetsModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.linkedAssetsList !== this.props.linkedAssetsList || nextProps.linkedAssets !== this.props.linkedAssets || nextProps.assetlistingMeta !== this.props.assetlistingMeta) {
            this.initilaizeData(nextProps);
        }
    }

    initilaizeData               =   (pr)  =>  {

         this.setState({ 
            linkedAssets            :       pr.linkedAssets,
            linkedAssetsList        :       pr.linkedAssetsList,
            assetlistingMeta        :       pr.assetlistingMeta,
            userDetailData          :       pr.userDetailData,
            linked_assets_loading       : false
        })
    }

    linkAssetsModalInit        =   ()      =>      {
        this.linkAssetsModal.show();
        this.setState({searchedAssets : []})
    }

    submitLinkAssetForm         =       (e)         =>      {
        e.preventDefault();
        this.setState({ saveLinkedAssetFormSubmitting: true })
        let linked_assets = this.state.searchedAssets.map(s => s.value);
        HttpAPICall.withAthorization('PUT', this.userAssetsLinkUrl, this.props.access_token,{}, {user_id : this.state.userDetailData?.id,asset_ids : linked_assets}, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkAssetsModal.hide();
            this.setState({ searchedAssets: [] })
            if(this.props.afterLinkingAssetSubmit){
                this.props.afterLinkingAssetSubmit()
            }
        }).then(() => this.setState({ saveLinkedAssetFormSubmitting: false }));
    }

    checkAllAssetList() {
        let assetCollections      =   document.getElementsByClassName('asset_id_checkbox')
        if(assetCollections && assetCollections.length > 0) {
            for (let i = 0; i < assetCollections.length; i++) {
                assetCollections[i].checked = document.getElementById('all_check_assets').checked;
            }
        }
    }

    delinkAssetHandler     =       ()      =>  {
        
        let assetCollections     =   document.getElementsByClassName('asset_id_checkbox');
        let selectedAssets                  =   [];
       
        if(assetCollections && assetCollections.length > 0) {
            for (let i = 0; i < assetCollections.length; i++) {
                if(assetCollections[i].checked) {
                   let asset_id         =       assetCollections[i].value;
                    selectedAssets.push(asset_id)    
                }
            }
        }
       
        if(selectedAssets.length == 0){
            toast.error('Please select atleast one Site', { position: toast.POSITION.TOP_RIGHT });
        }else{
            this.setState({linkedSitesListLoaded : false})
            swal({
                title: "Delink",
                text: "This action cannot be reversed, Are you sure you want to proceed?",
                icon: "warning",
                dangerMode: true,
                buttons: ["No", "Yes"],
            }).then(willDelete => {
                if (willDelete) {
                    let frmData = {user_id : this.state.userDetailData?.id , asset_ids : selectedAssets}
                    HttpAPICall.withAthorization('PUT', this.userAssetsDeLinkUrl, this.props.access_token, {},{...frmData} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        if(this.state.userDetailData && this.props.afterLinkingAssetSubmit){
                            this.props.afterLinkingAssetSubmit()
                        }

                    }).then(() => { });
                }
            });
        }
    }

    

    render                      =   ()  =>  {
        let detail = this.state.userDetailData;
        return (<div>
            <div className="tab_content_wrapper"><span className="content_heading">Asset</span>
                <div className="float-end">
                    <button className="btn btn-secondary mx-2" onClick={this.delinkAssetHandler} disabled={detail && detail.user_type == 'g' ? true : false }>Delink Asset</button>
                    <button className="btn btn-primary" onClick={this.linkAssetsModalInit} disabled={detail && detail.user_type == 'g' ? true : false }>Link Asset</button>
                </div>
            </div>
            <table className="table table-hover table-bordered table-responsive bg-white my-3 ">
                <thead className="table-secondary">
                    <tr>
                        <th style={{ "width": '10%' }} className="text-center">
                            <input type='checkbox' id="all_check_assets" onChange={this.checkAllAssetList} /></th>
                        <th>Asset Name</th>
                        <th style={{ "width": '20%' }}>Code</th>
                        <th style={{ "width": '15%' }}>Asset Type</th>
                        <th style={{ "width": '15%' }}>Site</th>
                        <th style={{ "width": '12%' }}>Status</th>
                    </tr>
                </thead>

                <tbody>
                    {this.state.linked_assets_loading
                        ? (<tr><td colSpan="6"><Loader /></td></tr>)
                        : (this.state.linkedAssetsList && this.state.linkedAssetsList.length > 0
                            ? (this.state.linkedAssetsList.map((t, k) => {
                                return (<tr key={k}>
                                    <td className='text-center'>
                                        <input
                                            type='checkbox'
                                            className="asset_id_checkbox"
                                            name="asset"
                                            value={t.asset_id}
                                        />
                                    </td>
                                    <td>{t.name}</td>
                                    <td>{t.asset_code}</td>
                                    <td>{t.asset_type_name}</td>
                                    <td>{t.site}</td>
                                    <td><Status color={t.status_color}>{t.status_text}</Status></td>

                                </tr>);
                            }))
                            : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>

            </table>
            <DisplayListPagination
                meta={this.state.assetlistingMeta}
                onPageChange={(e) => this.props.onPageChange(e.selected + 1)}
            />
            {this.linkAssetsModalJsx()}
        </div>);
    }

   
    linkAssetsModalJsx       =    ()       =>  {
        return (
            <div className="modal fade" id="linkAssetsModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkSitesModalLabel">Link Assets for : {this.state.userDetailData ? this.state.userDetailData.full_name : "-"} ({this.state.userDetailData && this.state.userDetailData.role ? this.state.userDetailData.role.role : "-"})</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ linkSiteForm: { ...this.initLinkSiteForm } }) }}></button>
                        </div>
                        <form onSubmit={this.submitLinkAssetForm} id="linkAssetModalForm">
                            <div className="modal-body">
                                <div className="row align-items-center my-3">
                                    <div className="col-sm-3">
                                        <label className="form-label">Link Assets</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <InputAssetSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            search_site_id={this.state.userDetailData && this.state.userDetailData.site_ids && this.state.userDetailData.site_ids.length > 0 ? this.state.userDetailData.site_ids : []}
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />

                                    </div>
                                    <div className="offset-3 col-sm-9 form-text">
                                     Assets with same site can be only linked
                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.saveLinkedAssetFormSubmitting} data-bs-dismiss="modal" onClick={() => { this.setState({ linkSiteForm: { ...this.initLinkSiteForm } }) }}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveLinkedAssetFormSubmitting} >Save {this.state.saveLinkedSiteFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],       
        user_id                 :   state.app.user_info?.id??'',
        permissions             :   state.app.acl_info
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading       :   (access_token)  =>   dispatch(actions.applicationInitLoading(access_token,false)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(UserLinkAssets);