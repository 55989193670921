import React from "react";
import AppBaseComponent from "../../components/AppBaseComponent";
import Ax from "../../components/hoc/Ax";
import DateService from "../../services/DateService";
import Status from "../../components/ui/Status";

class ProjectDetailCard extends AppBaseComponent {
  constructor() {
    super();
  }

  render() {
    const projectDetail = this.props.projectDetail;
    return (
      <Ax>
        <tr>
          <td style={{ width: "25%" }}>
            Project Name : <br />
            <b>{projectDetail ? projectDetail.name : "-"}</b>
          </td>
          <td style={{ width: "25%" }}>
            Total Ticket : <br />
            <b>{projectDetail ? projectDetail.count_tickets : "-"}</b>
          </td>
          <td style={{ width: "25%" }}>
            Site :{" "}
            <div className="fw-bold">
              <b>
                {projectDetail && projectDetail.site
                  ? projectDetail.site.site_name
                  : "-"}
              </b>
            </div>
          </td>
          <td style={{ width: "25%" }}>
            Project Start Date :{" "}
            <div className="fw-bold">
              <b>
                {projectDetail && projectDetail.starting_date
                  ? DateService.dateTimeFormat(
                      projectDetail.starting_date,
                      "DD-MMM-YYYY"
                    )
                  : "-"}
              </b>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ width: "25%" }}>
            Project ID : <br />
            <b>{projectDetail ? projectDetail.transaction_id : "-"}</b>
          </td>
          <td style={{ width: "25%" }}>
            Status : <br />
            <b>
              {projectDetail && projectDetail.status ? (
                <Status color={projectDetail.status.color_code}>
                  {projectDetail.status.status}
                </Status>
              ) : (
                "-"
              )}
            </b>
          </td>
          <td style={{ width: "25%" }}>
            Notes : <br />
            <b>{projectDetail ? projectDetail.notes : "-"}</b>
          </td>
          <td style={{ width: "25%" }}>
            {projectDetail &&
            projectDetail.status &&
            projectDetail.status.key === 1
              ? ""
              : ""}{" "}
            Current Estimate Completion Date :{" "}
            <div className="fw-bold">
              <b>
                {projectDetail && projectDetail.completion_date
                  ? DateService.dateTimeFormat(
                      projectDetail.completion_date,
                      "DD-MMM-YYYY"
                    )
                  : "-"}
              </b>
            </div>
          </td>
        </tr>
      </Ax>
    );
  }
}

export default ProjectDetailCard;
