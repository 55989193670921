import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import tapIcon from '../../../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Ax from "../../../../../components/hoc/Ax";

class LinkedItemsViewModal  extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.state                  =   {
            access_token                :   '',
            linkedItemData              :   null,
            scs_name                    :   ''
        }
    }
    
    componentDidMount               =   ()  =>  {
        this.viewLinkedItemModal               =   new Modal(document.getElementById('viewLinkedItemModal'), {keyboard: false, backdrop :true});  
    }
   
    linkedItemsModalInit               =   (linkedItemData,name)  =>  {
        this.setState({
            linkedItemData             :   linkedItemData,
            scs_name                   :   name   
        },() => {
            this.viewLinkedItemModal.show()
        });
    }
    

    viewLinkedItemModalJsx                  =   ()  =>  {
    
        return (
            <div className="modal fade" id="viewLinkedItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="linkItemModalLabel">Link Items  : {this.state.scs_name ?? "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body ">

                            <table className="table table-bordered table-responsive bg-white ">
                                <thead className="table-secondary">
                                    <tr className="text-start">
                                        <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                        <th scope="col" style={{ width: "24%" }}  >Item Name</th>
                                        <th scope="col" style={{ width: "15%" }}>Item Code</th>
                                        <th scope="col" style={{ width: "24%" }} >Manufacturer</th>
                                        <th scope="col" style={{ width: "15%" }}>MPN</th>
                                        <th scope="col" style={{ width: "8%" }} className="text-center">Quantity</th>
                                        <th scope="col" style={{ width: "9%" }} className="text-center">UOM</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.linkedItemData && this.state.linkedItemData.items && this.state.linkedItemData.items.length > 0
                                        ? this.state.linkedItemData.items.map((i, itemKey) => {

                                            return (<tr key={itemKey} className="text-start">
                                                <td className="text-center align-middle">{itemKey + 1}</td>
                                                <td>{i.item.name}</td>
                                                <td>{i.item.item_code}</td>
                                                <td>{i.item?.manufacturer?.name ?? '-'}</td>
                                                <td>{i.item?.manufacturer_part_no ?? "-"}</td>
                                                <td className='pt0 text-center'>
                                                    {i.qty}


                                                </td>
                                                <td className='text-center'> {i.item.measuring_unit && i.item.measuring_unit.name ? i.item?.measuring_unit?.name : "-"}</td>

                                            </tr>);
                                        })
                                        : <tr><td colSpan={7} className="text-center">No Linked Items</td></tr>}
                                </tbody>
                            </table>



                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<Ax>
            {this.viewLinkedItemModalJsx()}
        </Ax>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LinkedItemsViewModal);