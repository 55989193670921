import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import moment from "moment";
import TapApiUrls from "../../../services/TapApiUrls";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class HgSapTaskWidget extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            iam_user_sites              :   [],
            all_periods                 :   [],
            taskActivityForm              :   {search_site_id : '', period   :   'current_month'},
            loading                     :   false,
            data                        :   [],
            remark_day                  :   0,
            time                 :   '',
            not_active                  :   0,
            taskData                    :   []
        }
        this.updateMyDashboardRef      =   React.createRef();
    }


    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('hgSaptaskActivity')) {
            let taskActivityData = JSON.parse(localStorage.getItem('hgSaptaskActivity'));
            if (taskActivityData) {
                let lastRefreshTime = moment(taskActivityData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('hgSaptaskActivity')
                    setTimeout(() => {
                        this.getTaskActivityListing();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 3));
                } else {
                    this.setState({
                        data            :       taskActivityData.data,
                        time     :       taskActivityData.time,
                         
                })

                }
            }else{
                setTimeout(() => {
                    this.getTaskActivityListing();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 3));
            }
        } else {
            setTimeout(() => {
                this.getTaskActivityListing();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 3));
            
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites           :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                all_periods              :   props.all_periods ? props.all_periods.map((s) => { return {value: s.key, label: `${s.display}`}}) : [],
            }, () => {
                
                 if(props.dashboard_filter_site_id || props.clearDashboardFilter == 'yes'){
                   
                    this.setState({
                        taskActivityForm              :   {
                            search_site_id             :       props.dashboard_filter_site_id ?  props.dashboard_filter_site_id : '',
                            period                      :       'current_month'
                        },
                        
                    },() =>  this.getTaskActivityListing())
                }
            });
        }
    }

    getTaskActivityListing                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {
            search_site_ids: this.state.taskActivityForm && this.state.taskActivityForm.search_site_id ? [this.state.taskActivityForm.search_site_id] : '' ,
            date_range : this.state.taskActivityForm && this.state.taskActivityForm.period ? this.state.taskActivityForm.period : 'current_month' ,
            reload : reload ? 'reload' : false};
            
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/hg_sap/widget/task_count', this.props.access_token, params, {}, (response) => {
            
            this.setState({
                data            :       response.data.data,
                time            :       response.data.time, 
            })
  
            if(this.state.taskActivityForm.period && this.state.taskActivityForm.period == 'current_month'){
                localStorage.setItem('hgSaptaskActivity',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('hgSaptaskActivity',JSON.stringify(response.data));
            }
            
        }).then(() => this.setState({ loading: false }));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"hg_task_status_count_widget",name:"HG Sap Task Count"})
    }

  
    render                      =   ()  =>  {
        
          
        let filterObj           =   {};
         if(this.state.taskActivityForm){
            if(this.state.taskActivityForm.search_site_id){
                filterObj           =   {...filterObj , 'search_site_id' : this.state.taskActivityForm.search_site_id}
            }
            if(this.state.taskActivityForm.period){
                filterObj           =   {...filterObj , 'period' : this.state.taskActivityForm.period}
            }
     }

     let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("hg_task_status_count_widget" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "hg_task_status_count_widget")})  : null;
       
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">HG Sap Task Count</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.all_periods}
                            value={this.state.all_periods.find(r => r.value === this.state.taskActivityForm.period)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'taskActivityForm', 'period', () => this.getTaskActivityListing())}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r => this.state.taskActivityForm.search_site_id == r.value)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'taskActivityForm', 'search_site_id', () => this.getTaskActivityListing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={this.getTaskActivityListing}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2  align-items-center">
                    <div className="col-6">

                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center" >
                                    <th style={{ width: "50%" }} className="text-start">Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? <tr><td colSpan={2} className="text-center"><Loader /></td></tr>
                                    :
                                    this.state.data && this.state.data.length > 0
                                        ? this.state.data.map((dt, key) => {
                                            return (<tr>
                                                <th> {dt.status ? dt.status : ""}</th>
                                                <td className="text-center"><Link target="_blank" to={{ pathname: dt.key ? "/task_transaction_list" : "/report/hg_task_sap_report", search: "?" + new URLSearchParams({ ...filterObj,status:dt && dt.key ? dt.key  : '' }).toString() }}>{dt.total ? dt.total : ""}</Link></td>
                                            </tr>)

                                        })
                                        : <tr ><th colSpan={2} className="text-center">No Record</th></tr>}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p0">
                        {this.state.loading
                            ? <Loader />
                            : (<Chart
                                width={'100%'}
                                height={'100%'}
                                chartType="BarChart"
                                loader={`<div>Loading Chart</div>`}
                                data={[['Status', `Total`]].concat(this.state.data?.map((d) => { return [d.status, parseInt(d.total)]; }))}
                                options={{
                                    legend: { position: 'top' },
                                    colors: ['#53a8e3'],
                                    isStacked: true,
                                    chartArea: { width: '100%', left: '70', height: '80%' }
                                }}
                                rootProps={{ 'data-testid': '1.5' }}
                            />)}
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                        Report as of {this.state.time}
                    </div>
                </div>
                <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
                />
            </div>
        </div>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

export default connect(mapStateToProps)(HgSapTaskWidget);
