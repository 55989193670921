import React from 'react';
import moment from 'moment';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2, ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import { Helmet } from 'react-helmet';
import WarehouseAddress from '../includes/WarehouseAddress';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';


class RequisitionEdit extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initRequisitionForm           =       {
            warehouse_id                  :        '',
            requisition_transaction_id    :        '',
            requisition_date               :       moment(new Date(),'YYYY-MM-DD').toDate(),
            title                        :         '',
            priority                     :         '',
            due_date                     :         '',
            notes                        :         '',
            site_id                         :   '',
            asset_id                        :   '',
            users                           :   [],
            status                          :   '',
            currency                        :   '',
            conversion_rate                 :   1,
        }


        this.state                      =     {
            saveFormSubmitting           :          false,
            requisitionItemRows          :          [{item : null}],
            requisitionNewItemRows       :          [],
            addRequisitionForm           :          {...this.initRequisitionForm},
            isc_configuration            :          {},
            all_warehouses               :          [],
            warehouseDetails             :          null,
            searchedItem                 :          null,
            requisitionFormDataLoaded    :          false,
            details_loading              :          false,
            allGstSlabs                  :          [],
            requisitionData              :          null,
            selectedAsset                :          null,
            warehouseSites               :          [],
            selectedWorkstation          :          null,
            allStatusOptions              :          [],
            allPriorityOptions           :           [],
            requisitionAddFormData       :          null,
            itemSearchedDetails        :            null,
            itemRowNumber               :           0,
            editCurrency                :          false,
            allCurrencyList             :           [],
            defaultCurrency             :           null,
            currency_loading            :          false,
            warehouseDetailLoading      :          false,
            warehouseDetail             :           null,
            requisitionDetails          :          null,
            canEdit                     :         'Y'
        };
        this.addItemModalRef        =   React.createRef();
        this.itemDetailsRef             =   React.createRef();
        this.currency_convert_url   =   ORG_API_BASE_URL_2;
        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item';
        this.warehouseUrl           =   ISC_API_BASE_URL_2  + '/warehouse';
    }

    
    componentDidMount() {
        this.initilaizeFormData(this.props)
        this.addItemModal              =   new Modal(document.getElementById('addItemModal'), {keyboard: false, backdrop :false});
        this.viewItemModal             =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
        this.editItemModal             =   new Modal(document.getElementById('editItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(this.props.location && this.props.location.state){
            if(nextProps.all_warehouses !== this.props.all_warehouses) {
                this.initilaizeFormData(nextProps)
             }  
        }        
    }


    //*******************INITIALIZE FORM DATA************************************** */
    initilaizeFormData                  =   (pr) =>  {

        if(!this.state.requisitionFormDataLoaded) {
           HttpAPICall.withAthorization('GET', TapApiUrls.REQUISITON_URL + '/add_form_data', this.props.access_token, null, null, (response) => {
                let formData = response.data

               this.setState({
                   requisitionFormDataLoaded       :       formData ? true : false,
                   requisitionAddFormData          :       formData,
                   allCurrencyList                 :       formData && formData.currency_list && formData.currency_list.length > 0 ? formData.currency_list.map((s) => { return {value: s.code, label: s.code}; }) : [],
                   allGstSlabs                     :       formData && formData.gst_slabs && formData.gst_slabs.length > 0 ?  formData.gst_slabs.map((g) => { return {value: g, label: `${g}%`}; }) : [],
                   allPriorityOptions              :       formData && formData.priorities && formData.priorities.length > 0 ? formData.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                   allWarehouses                   :       pr.all_warehouses  ? pr.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_id}; }) : [],          
                   defaultCurrency                 :       formData && formData.default_currency ? formData.default_currency.code : 'INR',
                   addRequisitionForm              :       {
                       ...this.initRequisitionForm,
                       currency                     :      formData && formData.default_currency ? formData.default_currency.code : 'INR'
                   }
                }, () => {
                    if(pr.location && pr.location.state && pr.location.state.requisitionEditData){
                        let requisitionData = pr.location.state.requisitionEditData
                        this.setState({
                            addRequisitionForm          :   { ...this.state.addRequisitionForm,
                                warehouse_id                :   requisitionData.warehouse_id ? requisitionData.warehouse_id : '',
                                transaction_id              :   requisitionData.transaction_id ? requisitionData.transaction_id : '',
                                requisition_date            :   requisitionData.transaction_date ? requisitionData.transaction_date : '',
                                title                       :   requisitionData.title ? requisitionData.title : '',
                                priority                    :   requisitionData.priority ? requisitionData.priority : '',
                                due_date                    :   requisitionData.due_date ? requisitionData.due_date : '',
                                notes                       :   requisitionData.notes ? requisitionData.notes : '',
                                status                      :   requisitionData.status ? requisitionData.status : '',
                                currency                    :   requisitionData.currency ? requisitionData.currency : '',
                                asset_id                    :   requisitionData.asset_id ? requisitionData.asset_id : '',
                                users                       :   requisitionData.users ? requisitionData.users : [],
                                conversion_rate             :   requisitionData.conversion_rate ? requisitionData.conversion_rate : '',
                            },
                            requisitionDetails          :   requisitionData  ?  requisitionData   :  null,
                            requisitionItemRows         :   requisitionData.items,
                            canEdit                     :   requisitionData.can_edit == 'Y'  ? 'Y'   : 'N',
                            allStatusOptions            :   requisitionData && requisitionData.user_status && requisitionData.user_status.length > 0 ?  requisitionData.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                          }, () => {
                             this.getWarehouseDetail(requisitionData.warehouse_id)
                        })
                    }
               });
           });
       }

    }

    getWarehouseDetail                  =   (warehouse_id)  =>  {
        if (warehouse_id) {
            this.setState({warehouseDetailLoading : true, warehouseDetail : null});
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    warehouseDetail         :   respData.data,
                });
            }).then(() => {
                this.setState({warehouseDetailLoading : false});
            });
        } else {
            this.setState({warehouseDetail: null})
        }
    }

    //********************GET ITEM DETAILS ******************************* */

    onItemSelect                =           (selectedItem,key)          =>      {
        if(selectedItem && selectedItem.value) {
            let itemdata                    =   selectedItem.itemdata;
            let requisitionItemRows     =   this.state.requisitionNewItemRows;

            let row                         =   {...requisitionItemRows[key], item : itemdata ,gst : 0, remark:'', qty : selectedItem.qty ? selectedItem.qty : this.state.requisitionItemRows[key].qty ? this.state.requisitionItemRows[key].qty : 0  , rate : selectedItem.rate ? selectedItem.rate : itemdata.purchase_price};
            requisitionItemRows[key]            =   row;
            this.setState({requisitionNewItemRows : [...requisitionItemRows]}, () => {
                if(!this.state.requisitionItemRows.find(ir => !ir.item)) {
                    setTimeout(() => {this.addNewItemRow()}, 1000);
                }
                
            });
        }
    }

    addNewItemRow                           =   ()  =>  {
        this.setState({requisitionNewItemRows : [...this.state.requisitionNewItemRows, {item : null}] });
    }

    itemViewModalInit                       =   (item,k)  =>  {
        if(item){
            this.itemDetailsRef.current.modalInit(item);
            this.setState({itemRowNumber : k})
        }

    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.requisitionItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.requisitionItemRows[k] , item  : itemdata};
        this.setState({requisitionItemRows : allRows});
    }

      //********************EDIT ITEM DETAILS ******************************* */

      editItemModalInit          =       ()      =>      {
        this.editItemModal.show()
    }

    submitEditItem          =       ()      =>      {
        this.editItemModal.hide()
        HttpAPICall.withAthorization('GET', this.itemUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber)
               });
        })
       
    }

    removeItemRow                           =   (k) =>  {
        let allRows                 =   this.state.requisitionNewItemRows;
        if(allRows.length > 1) {
            allRows.splice(k, 1);
            this.setState({requisitionNewItemRows : allRows});
        }
    }

    addItemModalInit          =       ()           =>      {
        this.addItemModalRef.current.itemAddModalInit();  
    }

    onItemInputChange                       =   (e, name, k, selectedOption = null)  =>  {
        let val ;
        if(name == 'gst') {
            if(selectedOption && selectedOption.value) {
                val                         =   parseInt(selectedOption.value);
            }
        }else {
            val = e.target.type == "number" ? parseFloat(e.target.value) : e.target.value;
        }
        let allRows                 =   this.state.requisitionNewItemRows;
        allRows[k]                  =   {...this.state.requisitionNewItemRows[k] , [name] : val};
        this.setState({requisitionNewItemRows : allRows});
    }

    convertCurrency      =     (selectedOption)   =>  {

        let params = {to : this.state.addRequisitionForm.currency , from : selectedOption.value}
       this.setState({currency_loading : true})
        HttpAPICall.withAthorization('GET', this.currency_convert_url + '/currency/convert', this.props.access_token, params, null, (response) => {
            let resp = response.data
            this.setState({
                addRequisitionForm: {
                    ...this.state.addRequisitionForm,
                    conversion_rate: resp.rate
                },
                editCurrency    :  false
            });
       }).then(() => this.setState({currency_loading : false}))
          
    }

    //****************************CLOSE REQUISITION *********************/
    closeEvent              =           ()      =>      {
        this.props.history.push({pathname: `/requisition_list`, state: {requisitionId : this.state.requisitionDetails?.transaction_id }})
    }

    //**********************REQUISTION FORM  SUBMIT ***************************/

    addRequisitionFormSubmitEvent          =           (e, status = 0)          =>      {
        e.preventDefault()
        let items                   =        [...this.state.requisitionItemRows,...this.state.requisitionNewItemRows];
        
        let itemsLists              =        items.filter(i => i.item ? true : false).map(i => {
                                                 return {...i, id : i.item.id, item : null};
                                                });
        let addRequisitionForm       =      {...this.state.addRequisitionForm , requisition_date : moment(this.state.addRequisitionForm.requisition_date,'YYYY-MM-DD').format('YYYY-MM-DD'),status : status == 1 ? status : this.state.addRequisitionForm.status }                          

        let showErrors                  =   [];
        let formData                        =  { ...addRequisitionForm,items : itemsLists};

        if(formData.requisition_date == '') {
            showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
        }
        
        if(formData.warehouse_id == '') {
            showErrors                      =   [...showErrors , 'Please select Warehouse.'];
        }

        if(formData.status == '') {
            showErrors                      =   [...showErrors , 'Please select Status.'];
        }
     
        if(itemsLists.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and their qty.'];
        } else if(items.find(i => parseFloat(i.qty) <= 0)) {
            showErrors                      =   [...showErrors , 'Every Item\'s Consumption qty must be more than 0.'];
        }
        
       
        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            this.setState({ saveFormSubmitting: true })
                HttpAPICall.withAthorization('PUT', TapApiUrls.REQUISITON_URL, this.props.access_token, {}, { ...formData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/requisition_list`, state: {requisitionId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
        }
       
    }

    requisitionItemsJsx             =       ()      =>      {
        return (<div className="my-3 row">
            <div className="col-12">
                <table className="table table-bordered table-responsive bg-white table-sm ">
                    <thead className="table-secondary">
                        <tr className="text-end">
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "35%" }} className="text-start">Item Details</th>
                            <th scope="col" style={{ width: "10%" }}>Req. Qty</th>
                            <th scope="col" style={{ width: "9%" }}>PO Qty</th>
                            {/* <th scope="col" style={{ width: "9%" }}>In Transit </th> */}
                            <th scope="col" style={{ width: "9%" }}>Received</th>
                            <th scope="col" style={{ width: "9%" }}>Cancelled </th>
                            <th scope="col" style={{ width: "9%" }}>Pending </th>
                            {this.state.canEdit == 'N' ? null : <th scope="col" style={{ width: "5%" }}> </th>}

                        </tr>
                    </thead>
                    <tbody>
                        {this.state.requisitionItemRows.map((i, k) => {
                            let item = i.item;
                            return <tr className="" key={k}>
                                <td className="text-center align-middle">{k + 1}</td>
                                {(item
                                    ? <Ax>
                                    <td className="text-start">
                                    <div className="">
                                        <div className="row">
                                            <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>{i.item.name}</span></div>
                                            <div className='col-sm-2 text-end'>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu cursor_pointer">
                                                    <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>View Details</li>
                                                    {this.props?.group_permission?.permissions?.includes("isc-item-edit") && <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item, k); this.editItemModalInit(i.item) }}>Edit Item</li>}
                                                    <li className="dropdown-item" onClick={() => { this.addRemark(i.item, k) }}>Add Remark</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                        <div className="form-text">
                                            <b>Mfr: </b>{i.item.manufacturer ? i.item.manufacturer.name : '-'} (<b>MPN:</b> {i.item.manufacturer_part_no ? i.item.manufacturer_part_no : '-'})
                                        </div>
                                       
                                    </div>
                                    {i.item && i.item.isAddRemark && i.item.isAddRemark == true
                                    ? 
                                    <input
                                        name="remark"
                                        type="text"
                                        value={this.state.requisitionItemRows[k].remark}
                                        onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'requisitionItemRows')}
                                        className="form-control mt10"
                                        autoComplete="off"
                                        placeholder="Item Remarks"
                                    />
                                    : null }
                                    
                                </td>
                                        <td className='text-end'>{i.qty ?? "-"}</td>
                                        <td className="text-end">{i.current_po_qty ?? "-"}</td>
                                        {/* <td className="text-end">{i.intransit_qty ?? "-"}</td> */}
                                        <td className="text-end">{i.received_qty ?? "-"}</td>
                                        <td className="text-end">{i.cancelled_qty ?? "-"}</td>
                                        <td className='text-end' >
                                            <input name="pending_qty"
                                                type="number"
                                                value={this.state.requisitionItemRows[k].pending_qty}
                                                onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'requisitionItemRows')}
                                                className="form-control text-end"
                                                autoComplete="off"
                                                placeholder="Qty"
                                                required={true}
                                            /></td>
                                    </Ax>
                                    : <Ax>
                                        <td className="align-middle">
                                            <InputItemSearch
                                                changeEvent={(selectedOption) => {
                                                    this.onItemSelect(selectedOption, k);
                                                }}
                                            />
                                        </td>
                                        <td colSpan={6}></td>
                                    </Ax>
                                )}
                                {this.state.canEdit == 'N' ? null : <td className="text-center align-middle">
                                    {this.state.requisitionItemRows.length > 1
                                        ? <a href="#" onClick={() => this.removeItemRow(k)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>
                                        : null
                                    }
                                </td>}
                            </tr>
                        })}
                    </tbody>
                </table>
                {this.requisitionNewItemsJsx()}
            </div>
            <div className="col-12 ">
                <div className='row  '>
                    <div className='col-sm-5 d-flex flex-row'>
                        <span className='mx-2 form-label'>Currency : </span>
                        <TapSelect placeholder="Currency"
                            containerHeight="20px"
                            fontSize="93%"
                            width="80px"
                            className="form-control "
                            isDisabled={this.state.canEdit == "N" ? true : false}
                            changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'addRequisitionForm', 'currency'); this.convertCurrency(selectedOption) }}
                            options={this.state.allCurrencyList}
                            value={this.state.allCurrencyList.find(m => m.value == this.state.addRequisitionForm.currency)}
                        />
                        {this.state.addRequisitionForm.currency == this.state.defaultCurrency ? null
                            : <Ax>
                                (1 {this.state.addRequisitionForm.currency} : {this.state.editCurrency ? <input placeholder="Currency Ratio" className='form-control  mx-2' name="conversion_rate" value={this.state.addRequisitionForm.conversion_rate} onChange={(e) => this.formInputHandler(e, 'addRequisitionForm')} style={{ height: "20px", width: "25%", fontSize: "93%" }} />
                                    : <span className='link-primary mx-2' onClick={() => this.setState({ editCurrency: true })}>{this.state.currency_loading ? <Loader /> : <Ax>{this.state.addRequisitionForm.conversion_rate} {this.state.defaultCurrency}</Ax>}</span>})
                            </Ax>}

                    </div>

                    <div className='col-sm-7 text-end '>
                    {this.props?.group_permission?.permissions?.includes("isc-item-add") && <a role="button" onClick={this.addItemModalInit} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                        <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    </div>
                </div>
            </div>
        </div>)
    }

    requisitionNewItemsJsx             =       ()      =>      {
        return (<Ax>
            {this.state.requisitionNewItemRows.length > 0
                ? <div className="row">
                    <div className="col-12">
                        <table className="table table-bordered table-responsive bg-white ">
                            <thead className="table-secondary">
                                <tr>
                                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                                    <th scope="col" className="text-center" style={{ width: "42%" }}>Item Details</th>
                                    <th scope="col" className="text-center" style={{ width: "12%" }}>Quantity</th>
                                    <th scope="col" className="text-center" style={{ width: "12%" }}>Unit Rate</th>
                                    <th scope="col" className="text-center" style={{ width: "12%" }}>GST</th>
                                    <th scope="col" className="text-center" style={{ width: "12%" }}>Amount</th>
                                    <th scope="col" className="" style={{ width: "5%" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.requisitionNewItemRows.map((i, k) => {
                                    let tdRows = (i.item) ? (<Ax>
                                        <td className="text-start">
                                            <div className="">
                                                <div className=" d-flex justify-content-between">
                                                    <div><span className="fw-bold">{i.item.name}</span></div>
                                                    <div>
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu">
                                                            <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>View Details</li>
                                                            <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item, k); this.editItemModalInit() }}>Edit Item</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                                <div className="form-text">
                                                    <b>Mfr: </b>{i.item.manufacturer ? i.item.manufacturer.name : '-'} (<b>MPN:</b> {i.item.manufacturer_part_no ? i.item.manufacturer_part_no : '-'})
                                                </div>
                                                <div className="form-text"><b>{i.item.category == "goods" ? "HSN" : "SAC"} : </b>{i.item.hsn_code}</div>
                                                {i.item.tags && i.item.tags.length > 0
                                                    ? (i.item.tags.map((t, k) => <span key={k} className="badge bg-secondary">{t.name}</span>))
                                                    : null
                                                }
                                            </div>
                                            <input
                                                name="remark"
                                                type="text"
                                                value={this.state.requisitionNewItemRows[k].remark}
                                                onChange={(e) => this.onItemInputChange(e, 'remark', k)}
                                                className="form-control mt10"
                                                autoComplete="off"
                                                placeholder="Item Remarks"
                                            />
                                        </td>
                                        <td className="">
                                            <input
                                                name="qty"
                                                type="number"
                                                value={this.state.requisitionNewItemRows[k].qty}
                                                onChange={(e) => this.onItemInputChange(e, 'qty', k)}
                                                className="form-control text-end"
                                                autoComplete="off"
                                                placeholder="Qty"
                                                required={true}
                                                min={1}
                                                step=".01"
                                            />
                                            <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span>
                                        </td>
                                        <td>
                                            <input
                                                name="rate"
                                                type="number"
                                                value={this.state.requisitionNewItemRows[k].rate}
                                                onChange={(e) => this.onItemInputChange(e, 'rate', k)}
                                                className="form-control text-end"
                                                autoComplete="off"
                                                placeholder="Unit Rate"
                                                required={true}
                                                min={1}
                                                step=".01"
                                            />
                                        </td>
                                        <td>
                                            <TapSelect
                                                changeEvent={(selectedOption, e) => this.onItemInputChange(e, 'gst', k, selectedOption)}
                                                options={this.state.allGstSlabs}
                                                isSearchable={false}
                                                value={this.state.allGstSlabs.find(s => parseInt(s.value) == parseInt(this.state.requisitionNewItemRows[k].gst))}
                                                placeholder="GST"
                                            />
                                        </td>
                                        <td className="text-end">
                                            {(this.state.requisitionNewItemRows[k].qty * this.state.requisitionNewItemRows[k].rate).toFixed(2)}
                                        </td>
                                    </Ax>)
                                        : (<Ax>
                                            <td>
                                                <InputItemSearch
                                                    changeEvent={(selectedOption) => {

                                                        this.onItemSelect(selectedOption, k)
                                                    }}
                                                    value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                                                />
                                            </td>
                                            <td colSpan="4" className="text-center align-middle" >-</td>
                                        </Ax>);
                                    return (<tr className="" key={k}>
                                        <td className="text-center ">{k + 1}</td>
                                        {tdRows}
                                        <td className="text-center align-middle">
                                            <a href="#" onClick={() => this.removeItemRow(k)}>
                                                <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                            </a>
                                        </td>
                                    </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>
                : null}
        </Ax>)
    }
    
    //**********************ADD REQUISTION FORM JSX*******************/

    saveRequisitionFormJsx                  =   ()  =>  {
        let item_sub_total          =   0;
        let item_total_tax          =   0;
        
        this.state.requisitionNewItemRows.map((i,k) => {
            if(i.item) {
                let gst                 =   this.state.requisitionNewItemRows[k].gst ? this.state.requisitionNewItemRows[k].gst : 0;
                let total               =   parseFloat(this.state.requisitionNewItemRows[k].qty * this.state.requisitionNewItemRows[k].rate);
                item_sub_total          =   parseFloat(item_sub_total) + total;
                let item_tax            =   total * gst / 100 ;    
                item_total_tax          =   item_tax + item_total_tax;    
            }
        });
         let statusOptions     =   []
        if(this.state.canEdit == 'Y'){
            statusOptions = this.state.allStatusOptions
        }else{
            statusOptions = [{value: this.state.requisitionDetails ? this.state.requisitionDetails.status_data.id : null, label: this.state.requisitionDetails ? this.state.requisitionDetails.status_data.name : null}]
        }
        
        return (<form className="bg-white p-3" onSubmit={this.addRequisitionFormSubmitEvent}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Date of Requisition</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addRequisitionForm.requisition_date
                                ? moment(this.state.addRequisitionForm.requisition_date,'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="requisition_date"
                        onChange={(value, event) => this.formDateHandler('requisition_date', value, 'addRequisitionForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        maxDate={moment(new Date(),'YYYY-MM-DD').toDate()}
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter  Date`}

                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addRequisitionForm', 'warehouse_id', () => {
                                this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.canEdit == "N" ? true : false}
                        value={this.state.allWarehouses.find(m => m.value == this.state.addRequisitionForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    {this.state.requisitionDetails ?
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRequisitionForm', 'status')}
                            options={statusOptions}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            isDisabled={this.state.canEdit == "N" ? true : false}
                            value={statusOptions.find(s => s.value == this.state.addRequisitionForm.status)}
                            placeholder="Select Status"
                        />
                        : null}
                </div>

            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Title</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="title"
                        type="text"
                        value={this.state.addRequisitionForm.title}
                        onChange={(e) => this.formInputHandler(e, 'addRequisitionForm')}
                        className="form-control"
                        autoComplete="off"
                        placeholder="Title"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Priority & Due Date</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRequisitionForm', 'priority')}
                        options={this.state.allPriorityOptions}
                        isSearchable={true}
                        isClearable={true}
                        value={this.state.allPriorityOptions.find(s => s.value == this.state.addRequisitionForm.priority)}
                        placeholder="Select Priority"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addRequisitionForm.due_date
                                ? moment(this.state.addRequisitionForm.due_date,'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="due_date"
                        onChange={(value, event) => this.formDateHandler('due_date', value, 'addRequisitionForm')}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Due  Date`}

                    />

                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3"><label htmlFor="Users" className="form-label">Tag Users and Asset</label></div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addRequisitionForm', 'users')}
                        options={this.state.allUsers}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allUsers.filter(t => this.state.addRequisitionForm.users.includes(t.value))}
                        placeholder="Please Select User"
                    />
                </div>
                <div className="col-sm-4">
                    <InputAssetSearch
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addRequisitionForm', 'asset_id');
                            this.setState({ selectedAsset: selectedOption ? { ...selectedOption.asset, label: selectedOption.display_label } : null });
                        }}
                        placeholder="Search Asset"
                        value={this.state.selectedAsset ? { ...this.state.selectedAsset } : null}
                    />
                </div>
            </div>
            
            {this.requisitionItemsJsx()}
           
            <div className="row align-items-center mb-5">
                <div className="col-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addRequisitionForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addRequisitionForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                    <table className="table  table-borderless">
                        <tbody>
                            <tr className="subtotal_bg_color"><th>Sub Total</th><td className="text-end">{this.state.requisitionDetails?.total_amount ? (Number(this.state.requisitionDetails?.total_amount) - Number(this.state.requisitionDetails?.total_tax) + item_sub_total)  : 0.00}</td></tr>
                            <tr className="subtotal_bg_color"><th>Tax</th><td className="text-end">{Number(this.state.requisitionDetails?.total_tax) + item_total_tax}</td></tr>
                            <tr className="total_bg_color"><th>Total</th><td className="text-end">{this.state.requisitionDetails?.total_amount ? (Number(this.state.requisitionDetails?.total_amount) + item_sub_total + item_total_tax) : 0}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                {this.state.requisitionDetails && this.state.requisitionDetails.status == 1
                    ? <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary ms-2" onClick={(e) => { this.addRequisitionFormSubmitEvent(e, 1); }}>
                        Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    : null
                }
                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-primary ms-2" onClick={(e) => { this.addRequisitionFormSubmitEvent(e); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Add Purchase Requisition</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.requisitionDetails ? "Edit" : "Add"} Purchase Requisition {this.state.requisitionDetails ? <span> of Transaction ID : {this.state.requisitionDetails?.transaction_id}</span> : null}</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.requisitionFormDataLoaded
                            ? this.saveRequisitionFormJsx()
                            : <Loader />}
                    </div>
                </div>
            </div>
            <ItemDetailsModal ref={this.itemDetailsRef} /> 
            <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />
            <ItemEditModal itemData={this.state.itemSearchedDetails} afterSubmitItem={this.submitEditItem} />
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        group_permission        :   state.app.acl_info,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(RequisitionEdit);