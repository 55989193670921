import React from 'react';
import AppBaseComponent from "../../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from "moment";
import {Chart} from "react-google-charts";
import TapSelect from "../../../../components/ui/TapSelect";
import tapIcon from "../../../../services/TapIcon";
import Status from "../../../../components/ui/Status";
import HttpAPICall from "../../../../services/HttpAPICall";
import TapApiUrls from "../../../../services/TapApiUrls";
import Loader from "../../../../components/ui/Loader/Loader";
import * as actions from "../../../../store/actions/index";
import UpdateMyDashboardForm from "../../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";


class DueDateWidget extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            iam_user_sites              :   [],
            iam_asset_types             :   [],
            dueDateForm                 :   {search_site_ids : [],search_asset_type_ids:[]},
            loading                     :   false,
            data                        :   [],
            time                        :   '',
        }
        this.updateMyDashboardRef      =   React.createRef();
    }


    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('rentalDueDateWidget')) {
            let rentalDueDateData = JSON.parse(localStorage.getItem('rentalDueDateWidget'));
            if (rentalDueDateData) {
                let lastRefreshTime = moment(rentalDueDateData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('rentalDueDateWidget')
                    setTimeout(() => {
                        this.getDueDateListing();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 5));
                    
                } else {
                    this.setState({
                        data            :      rentalDueDateData,
                        time            :      rentalDueDateData.time, 
                    })
                }

            }else {
                setTimeout(() => {
                    this.getDueDateListing();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 5));
            }
        } else {
            setTimeout(() => {
                this.getDueDateListing();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 5));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites           :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                iam_asset_types          :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            }, () => {
               
                 if(this.props.dashboard_filter_site_id || props.clearDashboardFilter == "yes"){
                   
                    this.setState({
                        dueDateForm         :       {
                            search_site_ids          :       props.dashboard_filter_site_id ?  [props.dashboard_filter_site_id] : [],
                            search_asset_type_ids    :       []
                        }
                    },() => this.getDueDateListing())
                }
                
            });
        }
    }

    getDueDateListing                =   (reload=false)  =>  {
        this.setState({loading: true});
       
        let params          =   {
                        search_site_ids: this.state.dueDateForm && this.state.dueDateForm.search_site_ids && this.state.dueDateForm.search_site_ids.length > 0 ? [this.state.dueDateForm.search_site_ids] : null ,
                        search_asset_type_ids : this.state.dueDateForm && this.state.dueDateForm.search_asset_type_ids && this.state.dueDateForm.search_asset_type_ids.length > 0 ? [this.state.dueDateForm.search_asset_type_ids] : null ,
                        reload : reload ? 'reload' : false
                    };
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM +  '/rental_due_dates_wise_count', this.props.access_token, params, {}, (response) => {
          
            this.setState({
                data            :       response.data,
                time            :       response.data.time, 
            })

            if(this.state.dueDateForm.search_site_ids && this.state.dueDateForm.search_site_ids.length == 0 && this.state.dueDateForm.search_asset_type_ids && this.state.dueDateForm.search_asset_type_ids.length == 0){
                localStorage.setItem('rentalDueDateWidget',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('rentalDueDateWidget',JSON.stringify(response.data));
            }
            
        }).then(() => this.setState({ loading: false }));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"commercial_due_dates",name:"Commercial Due Dates"})
    }

  
    render                      =   ()  =>  {
        let allStatus           =   this.state.data && this.state.data.status && this.state.data.status.length > 0 && this.state.data.status.map(st => st.key).join(',');  
        let filterObj           =   {};
         if(this.state.dueDateForm){
            if(this.state.dueDateForm.search_site_ids && this.state.dueDateForm.search_site_ids.length > 0 ){
                filterObj           =   {...filterObj , 'search_site_ids' : this.state.dueDateForm.search_site_ids}
            }
            if(this.state.dueDateForm.search_asset_type_ids  && this.state.dueDateForm.search_asset_type_ids.length > 0){
                filterObj           =   {...filterObj , 'search_asset_type_ids' : this.state.dueDateForm.search_asset_type_ids}
            }
         }
       
       let data = [['OverDue',this.state?.data?.data?.map(s => s.over_due).reduce((a, b) => a + b, 0)],
       ['Due Soon',this.state?.data?.data?.map(s => s.due_soon).reduce((a, b) => a + b, 0)],['Within Limits',this.state?.data?.data?.map(s => s.with_in_limit).reduce((a, b) => a + b, 0)]]
       let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("commercial_due_dates" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "commercial_due_dates")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Commercial Due Dates</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Asset Types"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_asset_types.find(r => this.state.dueDateForm.search_asset_type_ids.includes(r.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dueDateForm', 'search_asset_type_ids', () => this.getDueDateListing())}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r => this.state.dueDateForm.search_site_ids.includes(r.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'dueDateForm', 'search_site_ids', () => this.getDueDateListing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={this.getDueDateListing} >Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                {this.state.loading
                    ? <Loader />
                    : this.state.data && this.state.data.data && this.state.data.data.length == 0
                        ? <div className="row mt-2 align-items-center">
                            <div className="col-sm-12 text-center">
                                No Record
                            </div>
                        </div>
                        :
                        <div className="row mt-2  align-items-center">
                            <div className="col-6">

                                <table className="table table-hover table-bordered table-borderless bg-white">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th style={{ width: "50%" }}></th>
                                            {this.state.data && this.state.data.data && this.state.data.data.length > 0
                                                ? this.state.data.data.map((date, key) => {
                                                    return (
                                                        <th style={{ width: "25%" }} className="text-center">{date.name} </th>
                                                    )

                                                }) : null}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.data && this.state.data.status
                                            ? this.state.data.status.map((st, i) => {

                                                return (<tr className="text-center">
                                                    <td className="text-start">
                                                        <Status color={st.status_color_code}>{st.status_text}</Status>
                                                    </td>
                                                    <td className="link-primary cursor_pointer">{st.key == 'due_soon'
                                                        ? <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, tentative_release_date_status: "due_soon" }).toString() }}>
                                                            {this.state.data?.data?.filter(s => s.key == 'date_release_tentative').map(d => d.due_soon)} </Link>
                                                        : st.key == 'over_due'
                                                            ? <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, tentative_release_date_status: "over_due" }).toString() }}>
                                                                {this.state.data?.data?.filter(s => s.key == 'date_release_tentative').map(d => d.over_due)} </Link>

                                                            : st.key == 'with_in_limit'
                                                                ? <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, tentative_release_date_status: "with_in_limit" }).toString() }}>
                                                                    {this.state.data?.data?.filter(s => s.key == 'date_release_tentative').map(d => d.with_in_limit)} </Link>
                                                                : 0}
                                                    </td>
                                                    <td className="link-primary cursor_pointer">{st.key == 'due_soon'
                                                        ? <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, wo_due_date_status: "due_soon" }).toString() }}>
                                                            {this.state.data?.data?.filter(s => s.key == 'wo_due_date').map(d => d.due_soon)} </Link>
                                                        : st.key == 'over_due'
                                                            ? <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, wo_due_date_status: "over_due" }).toString() }}>
                                                                {this.state.data?.data?.filter(s => s.key == 'wo_due_date').map(d => d.over_due)} </Link>

                                                            : st.key == 'with_in_limit'
                                                                ? <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, wo_due_date_status: "with_in_limit" }).toString() }}>
                                                                    {this.state.data?.data?.filter(s => s.key == 'wo_due_date').map(d => d.with_in_limit)} </Link>
                                                                : 0}
                                                    </td>

                                                </tr>)
                                            }
                                            )
                                            : null}
                                        <tr>
                                            <th className="text-start">Total</th>
                                            <td className="text-center link-primary cursor_pointer">{this.state.data && this.state.data.data && this.state.data.data.length > 0 ?
                                                <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, tentative_release_date_status: allStatus }).toString() }}>
                                                    {this.state.data.data.filter(s => s.key == 'date_release_tentative').reduce((sum, a) => sum + a.due_soon + a.over_due + a.with_in_limit, 0)} </Link>
                                                : 0} </td>
                                            <td className="text-center link-primary cursor_pointer">{this.state.data && this.state.data.data && this.state.data.data.length > 0 ?
                                                <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, wo_due_date_status: allStatus }).toString() }}>
                                                    {this.state.data.data.filter(s => s.key == 'wo_due_date').reduce((sum, a) => sum + a.due_soon + a.over_due + a.with_in_limit, 0)} </Link>
                                                : 0} </td>

                                        </tr>
                                        <tr>
                                            <th></th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th className="text-start">Date Not Available</th>
                                            <td className="text-center link-primary cursor_pointer">{this.state.data && this.state.data.data && this.state.data.data.length > 0 ? 
                                            <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, tentative_release_date_status: "N" }).toString() }}>
                                            {this.state.data.data.filter(s => s.key == 'date_release_tentative').map(d => d.no_due_date)} </Link>
                                             : 0}</td>
                                             <td className="text-center link-primary cursor_pointer">{this.state.data && this.state.data.data && this.state.data.data.length > 0 ? 
                                            <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, wo_due_date_status: "N" }).toString() }}>
                                            {this.state.data.data.filter(s => s.key == 'wo_due_date').map(d => d.no_due_date)} </Link>
                                             : 0}</td>
                                             
                                
                                        </tr>


                                    </tbody>
                                </table>


                            </div>
                            <div className="col-6 p0">

                                {this.state.loading
                                    ? <Loader />
                                    : <Chart
                                        chartType="PieChart"
                                        data={[['', '']].concat(data)}
                                        options={{
                                            legend: { position: 'labeled', labeledValueText: 'both' },
                                            pieSliceText: 'none',
                                            slices: this.state.data && this.state.data.status && this.state.data.status.map(st => { return ({ color: st.status_color_code }) }),
                                            chartArea: { width: '90%', height: '90%' },
                                        }}
                                        rootProps={{ 'data-testid': '1' }} />
                                }
                            </div>
                            <div className="col-sm-12 text-muted fs11">
                                Report as of {this.state.time}
                            </div>
                        </div>}
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(DueDateWidget);