import { Modal } from "bootstrap";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AppBaseComponent from "../../../components/AppBaseComponent";
import tapIcon from "../../../services/TapIcon";
import { Helmet } from "react-helmet";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import moment from "moment";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";
import Status from "../../../components/ui/Status";
import { Link } from "react-router-dom";
import EmployeeDocument from "./EmployeeDocument";
import DatePicker from "react-datepicker";
import TapIcon from "../../../services/TapIcon";
import DateService from "../../../services/DateService";
import InputEmployeeSearch from "../../includes/ui/InputEmployeeSearch";

class EmployeeCurrentWorkingModal extends AppBaseComponent {
  constructor() {
    super();

    this.currentWorkingFormInit = {
      employee_code: "",
      emp_card_num: "",
      corporate_email: "",
      doj: "",
      probation_period: "",
      reporting_manager: "",
      emp_type_id: "",
      designation_id: "",
      division_id: "",
      work_phone_number: "",
      tags: null,
      asset_type_ids: null,
    };

    this.state = {
      emp_id: "",
      currentWorkingForm: { ...this.currentWorkingFormInit },
      loading_form_data: false,
      saveFormSubmitting: false,
      employeeData: null,
      type: "Add",
      working_id: "",
      employeeTypeList: [],
      designationList: [],
      divisionList: [],
      reportingManager: null,
      tagsList: [],
      iam_asset_types: [],
    };

    this.emp_add_form_data_url =   HRM_API_BASE_URL_2 + '/employee/add_form_data'; 
    this.emp_edit_url =   HRM_API_BASE_URL_2 + '/employee/edit';

  }

  componentDidMount() {
    this.currentWorkingModal = new Modal(
      document.getElementById("currentWorkingModal"),
      { keyboard: false, backdrop: false }
    );
  }

  modalHandle = () => {
    this.currentWorkingModal.show();
    if (this.props.employeeData) {
      const employeeData = this.props.employeeData;
      this.setState({ loading_form_data: true, emp_id: employeeData.enc_id });
      HttpAPICall.withAthorization("GET", this.emp_add_form_data_url,
        this.props.access_token, { type: "config" }, {}, (resp) => {
          let respData = resp.data;

          this.setState({
            employeeTypeList:
              respData &&
              respData.employee_type.length > 0 ?
              respData.employee_type.map((et) => {
                return { label: et.name, value: et.id };
              }) : [],
            designationList: (respData && respData.designation.length) > 0 ?
              respData.designation.map((d) => {
                return { label: d.name, value: d.id };
              }) : [],
            divisionList: (respData && respData.division.length > 0)  ?
              respData.division.map((di) => {
                return { label: di.name, value: di.id };
              }) : [],
            tagsList: (respData && respData.tags.length > 0)  ? 
              respData.tags.map((tag) => {
                return { label: tag, value: tag };
              }) : [],
            iam_asset_types:
              respData.asset_types && respData.asset_types.length > 0
                ? respData.asset_types.map((at) => {
                    return { value: at.id, label: at.asset_type_name };
                  })
                : [],
            currentWorkingForm: {
              employee_code: employeeData.employee_code,
              emp_card_num: employeeData.emp_card_num,
              corporate_email: employeeData.corporate_email,
              doj: employeeData.doj,
              probation_period: employeeData.probation_period,
              reporting_manager: employeeData.reporting_manager
                ? employeeData.reporting_manager.enc_id
                : "",
              emp_type_id: employeeData.employee_type
                ? employeeData.employee_type.id
                : "",
              designation_id: employeeData.designation
                ? employeeData.designation.id
                : "",
              division_id: employeeData.division
                ? employeeData.division.id
                : "",
              work_phone_number: employeeData.work_phone_number
                ? employeeData.work_phone_number
                : "",
              tags: employeeData.tags,
              asset_type_ids:
                employeeData.asset_types && employeeData.asset_types.length > 0
                  ? employeeData.asset_types.map((at) => at.id)
                  : null,
            },
            reportingManager: employeeData.reporting_manager && {
              label: employeeData.reporting_manager.display_full_name,
              value: employeeData.reporting_manager.enc_id,
              employee: employeeData.reporting_manager,
            },
          });
        }
      ).then(() => this.setState({ loading_form_data: false }));
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    let formData = {
      ...this.state.currentWorkingForm,
      id: this.state.emp_id,
      fname: this.props.employeeData.fname,
    };
    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization("PUT", this.emp_edit_url, this.props.access_token,
      {}, { ...formData }, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.props.currentWorkingDetail(this.state.enc_id);
        this.currentWorkingModal.hide();
        this.setState({
          currentWorkingForm: { ...this.currentWorkingFormInit },
        });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  render() {
    return (
      <div className="modal fade" id="currentWorkingModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="currentWorkingModalLabel">
                Current Working Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.loading_form_data ? (
              <Loader />
            ) : (
              <Ax>
                <div className="modal-body">
                  <form id="currentWorkingForm" onSubmit={this.submitForm}>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="name" className="form-label require">
                          Employee Code
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="employee_code"
                          type="text"
                          value={this.state.currentWorkingForm.employee_code}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          required
                          onChange={(e) =>
                            this.formInputHandler(e, "currentWorkingForm")
                          }
                          placeholder="Please enter Employee Code"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="relationship" className="form-label ">
                          Employee Card No.
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="emp_card_num"
                          type="text"
                          value={this.state.currentWorkingForm.emp_card_num}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "currentWorkingForm")
                          }
                          placeholder="Please enter Employee Card No."
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="name" className="form-label">
                          Corporate Email
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="corporate_email"
                          type="email"
                          value={this.state.currentWorkingForm.corporate_email}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "currentWorkingForm")
                          }
                          placeholder="Please enter Corporate Email"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="relationship" className="form-label ">
                          Date of Joining
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <DatePicker
                          selected={
                            this.state.currentWorkingForm.doj
                              ? moment(
                                  this.state.currentWorkingForm.doj,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : false
                          }
                          name="doj"
                          onChange={(value, event) =>
                            this.formDateHandler(
                              "doj",
                              value,
                              "currentWorkingForm"
                            )
                          }
                          dateFormat="dd-MMM-yyyy"
                          className={"form-control form-control-sm"}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                          scrollMonthYearDropdown
                          placeholderText={"Date of Birth"}
                        />
                        {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="From Date" className="add_calender-icon" /> */}
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="name" className="form-label">
                          Probation Period
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <DatePicker
                          selected={
                            this.state.currentWorkingForm.probation_period
                              ? moment(
                                  this.state.currentWorkingForm
                                    .probation_period,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : false
                          }
                          name="probation_period"
                          onChange={(value, event) =>
                            this.formDateHandler(
                              "probation_period",
                              value,
                              "currentWorkingForm"
                            )
                          }
                          dateFormat="dd-MMM-yyyy"
                          className={"form-control form-control-sm"}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                          scrollMonthYearDropdown
                          placeholderText={"Date of Birth"}
                        />
                        {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="From Date" className="add_calender-icon" /> */}
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="relationship" className="form-label">
                          Reporting Manager
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <InputEmployeeSearch
                          placeholder="Select Reporting Manager"
                          changeEvent={(selectedOption) => {
                            this.setState({
                              reportingManager: selectedOption
                                ? selectedOption
                                : "",
                            });
                            this.tapSelectChange(
                              selectedOption,
                              "currentWorkingForm",
                              "reporting_manager"
                            );
                          }}
                          menuPlacement="top"
                          value={
                            this.state.reportingManager &&
                            this.state.reportingManager
                          }
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="name" className="form-label">
                          Employee Type
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.employeeTypeList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "currentWorkingForm",
                              "emp_type_id"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.employeeTypeList.length > 0 && this.state.employeeTypeList.find(
                            (et) =>
                              et.value ===
                              this.state.currentWorkingForm.emp_type_id
                          )}
                          placeholder="Select Employee Type"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="relationship" className="form-label ">
                          Designation
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.designationList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "currentWorkingForm",
                              "designation_id"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.designationList.find(
                            (d) =>
                              d.value ===
                              this.state.currentWorkingForm.designation_id
                          )}
                          placeholder="Select Designation"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="name" className="form-label">
                          Department
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.divisionList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "currentWorkingForm",
                              "division_id"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.divisionList.find(
                            (di) =>
                              di.value ===
                              this.state.currentWorkingForm.division_id
                          )}
                          placeholder="Select Division"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Work Phone Number
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="work_phone_number"
                          type="number"
                          value={
                            this.state.currentWorkingForm.work_phone_number
                          }
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "currentWorkingForm")
                          }
                          placeholder="Please enter work phone number"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="tags" className="form-label">
                          Tags
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.tagsList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "currentWorkingForm",
                              "tags"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          isMulti={true}
                          value={this.state.tagsList.filter(
                            (tag) =>
                              this.state.currentWorkingForm.tags &&
                              this.state.currentWorkingForm.tags.includes(
                                tag.value
                              )
                          )}
                          placeholder="Select Tags"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="tags" className="form-label">
                          Asset Types
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.iam_asset_types}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "currentWorkingForm",
                              "asset_type_ids"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          isMulti={true}
                          value={this.state.iam_asset_types.filter((s) =>
                            this.state.currentWorkingForm?.asset_type_ids?.includes(
                              s.value
                            )
                          )}
                          placeholder="Select Asset Type"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    form="currentWorkingForm"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-primary mx-2"
                  >
                    Save{" "}
                    {this.state.saveFormSubmitting ? (
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </Ax>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EmployeeCurrentWorkingModal
);
