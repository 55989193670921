import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import * as actions from '../../../../store/actions/index';

class UpdateMyDashboardForm extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initMyDashboardForm    =       {
            key                     :       '',
            action                  :       '',
            ordo                    :       '',
            name                    :       ''
        }

        this.state                  =   {
            saveFormSubmitting      :         false,
            addMyDashboardForm      :         {...this.initMyDashboardForm},
        }
        this.dashboardModalId           =   uuid();
        this.dashboardModalFormId       =   this.dashboardModalId + 'Form';

        this.widgetKeysUserKeyEditUrl   =   ORG_API_BASE_URL_2  +   '/widget_keys/user_key_edit';

    }

    componentDidMount                   =   ()  =>  {
        this.dashboardAddModal         =    new Modal(document.getElementById(this.dashboardModalId), {keyboard: false, backdrop :false});
    }
    

    updateMyDashboardModalInit         =   (userWidgetData=null,listingData=null)  =>  {
        this.dashboardAddModal.show();
       
        this.setState({
            addMyDashboardForm: {
                    ...this.initMyDashboardForm,
                    key     :       listingData.key ? listingData.key : "",
                    name     :       listingData.name ? listingData.name : "",
                    action  :       userWidgetData && userWidgetData.key == listingData.key ? "add" : "remove",
                    ordo    :       userWidgetData && userWidgetData.key == listingData.key ? userWidgetData.ordo : "",
            }
        })
    }
   

    submitAddGroupForm = (event) => {
        event.preventDefault();
        this.setState({ saveFormSubmitting: true });

        HttpAPICall.withAthorization('PUT', this.widgetKeysUserKeyEditUrl, this.props.access_token, null, { ...this.state.addMyDashboardForm}, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.dashboardAddModal.hide();
            this.setState({ addMyDashboardForm : {...this.initMyDashboardForm} });
            this.props.onUserUpdateInfo();
           if(this.props.afterFormSubmit){
                this.props.afterFormSubmit()
           }
        }).then(() => this.setState({ saveFormSubmitting: false }));
    };
    
    dashboardAddModalJsx = () => {
        
        return (
            <div className="modal fade" id={this.dashboardModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Update My Dashboard</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id={this.dashboardModalFormId} onSubmit={this.submitAddGroupForm}>
                            <div className="modal-body">
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm text-end">Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addMyDashboardForm.name}
                                            disabled={true}
                                            className='form-control form-control-sm'
                                            autoComplete='off'
                                        />
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm text-end">Status</label>
                                    <div className="col-sm-9">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="action"
                                                type="radio"
                                                value="add"
                                                onChange={(e) => this.formInputHandler(e, 'addMyDashboardForm')}
                                                checked={this.state.addMyDashboardForm.action == "add"}
                                                className="form-check-input"
                                                required={true}
                                                id="pinned"
                                            />
                                            <label className="form-check-label" htmlFor="pinned">Pinned</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="action"
                                                type="radio"
                                                value="remove"
                                                onChange={(e) => this.formInputHandler(e, 'addMyDashboardForm')}
                                                checked={this.state.addMyDashboardForm.action == "remove"}
                                                className="form-check-input"
                                                required={true}
                                                id="unpinned"
                                            />
                                            <label className="form-check-label" htmlFor="unpinned">Unpinned</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm text-end">Sequence Number</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="ordo"
                                            type="number"
                                            value={this.state.addMyDashboardForm.ordo}
                                            className='form-control form-control-sm'
                                            autoComplete='off'
                                            onChange={(e) => this.formInputHandler(e, 'addMyDashboardForm')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    
    render                              =   ()  =>  this.dashboardAddModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUserUpdateInfo          :   () => dispatch(actions.updateUserDetail()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UpdateMyDashboardForm);