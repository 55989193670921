import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ActivityWiseTaskReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportUrl              =   IAM_API_BASE_URL_2 + '/report/ativity-wise-task';
        this.formDatareportUrl      =   IAM_API_BASE_URL_2 + '/report/ativity-wise-task/form_data';
        
        this.reportFilterFormInitialState       =   {
            site_group_ids                          :   [],
            search_site_ids                         :   [],
            'asset_group_ids'                       :    [],
            search_asset_type_ids                   :   [],
            search_asset_ids                        :   [],
            activity_id                      :   '',
            date_range                              :   '',
            date_range_start                        :   null,
            date_range_end                          :   null,
        };
        
        this.reportFilterFormLabel              =   {
            site_group_ids                          :   'Site Groups : ',
            search_site_ids                         :   'Sites : ',
            activity_id                             :   'Activity : ',
            'asset_group_ids'                       :   'Asset Type Groups : ',
            search_asset_type_ids                   :   'Asset Type : ',
            search_asset_ids                        :   'Assets : ',
            date_range                              :   'Transaction Period : ',
        }
        
        this.state                              =   {
            iam_asset_types                         :   [],
            iam_user_sites                          :   [],
            formDataLoaded                          :   false,
            filterFormData                          :   null,
            searchedAssets                          :   [],
            reportFilterForm                        :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm               :   {...this.reportFilterFormInitialState},
            listing_loading                         :   false,
            downloadReportBtn                       :   false,
            formSearchedElems                       :   [],
            listingData                             :   [],
            listingMeta                             :   null,
            reportDownloadBtn                       :   false,
            reportDownloading                       :   false,
            all_Activities                          :   [],
            taskStatus                              :   [],
            allAssetTypesGroupsList                 :   [],
            reportData                          :   null,                  
        };


    }
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {

        const report_name   =   'activity_wise_task_report';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            all_periods             :       all_periods,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        }, () => this.filterFormDataInit());
    }
    
    filterFormDataInit                      =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.reportUrl + '/form_data', this.props.access_token, null, null, (response) => {
            let respData = response.data;
            this.setState({
                formDataLoaded              :   true,
                all_Activities              :   respData.activities && respData.activities.length > 0 ? respData.activities.map(ac => { return {value: ac.id, label: ac.name}}) : [],
                allSiteGroupsList           :    respData && respData.site_groups && respData.site_groups.length > 0 ? respData.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            });
            
            
            this.reportFilterhandler();
        }, (err) => { });
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        let serachFormKeys                      =   Object.keys(this.state.reportFilterForm);
        
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    if(key == 'activity_id') {
                       
                        show_val            = this.state.all_Activities.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }

                    if(key == 'date_range') {
                        let  start_range          =       moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =       moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }

                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page =1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listingMeta             :   null,
            listing_tbl_page        :   page,
            reportDownloadBtn       :   false,
            reportDownloading       :   false
        });
        let params                          =   {page:page,...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data &&  response.data.assets ? response.data.assets  : null ;
            let statusList              =  response.data &&  response.data.status_list && response.data.status_list.length > 0 ? response.data.status_list  : []
       
            this.setState({
                listingData                 :   respData && respData.data ? respData.data : [],
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                taskStatus                  :    statusList 
                
            });
        }, (err) => {
            
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :           [],
            reportFilterForm                :           {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :           {...this.reportFilterFormInitialState},
        }, () => {
            this.loadListingTblData(1);
        });
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCarAlt} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button> 
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse" >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Execution Site</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            menuPlacement="top"
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Activity</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_Activities}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'activity_id')}
                            value={this.state.all_Activities.find(r => r.value ===  this.state.reportFilterForm.activity_id  )}
                            placeholder="Select All Activity"
                            menuPlacement="top"
                            required={true}
                            isSearchable={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Transaction Period</label>
                    <div className="col-sm-6">
                    <PeriodSelectionWithCustom
                            periods={this.state.all_periods}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            required={true}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm    :   {...this.state.reportFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate}
                                });
                            }}
                        />
                    </div>
                </div>
               
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr/>
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ?   <Loader />
                :   (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        let status = this.state.taskStatus;
        return (<Ax>
            <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className="text-center" style={{ width: "6%" }}>S.No</th>
                        <th style={{ width: "16%" }}>Asset Name</th>
                        <th style={{ width: "18%" }}>Asset Code</th>
                        <th style={{ width: "20%" }}>Asset Type</th>
                        <th style={{ width: "14%" }}>Current Site</th>
                        {status && status.length > 0 ?
                            status.map(st => {
                                return (<Ax>
                                    <th className="text-center" style={{ width: "7%" }}>{st.status}</th>
                                </Ax>)
                            }) : null}
                       <th className="text-center" style={{ width: "9%" }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? <Ax> {
                            (this.state.listingData.map((data, index) => {
                                return (<tr>
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td>{data.name ? data.name : "-"}</td>
                                    <td>{data.asset_code ? data.asset_code : "-"}</td>
                                    <td>{data.asset_type ? data.asset_type : "-"}</td>
                                    <td>{data.site ? data.site : "-"}</td>
                                    {data.task_count && data.task_count.length > 0
                                        ? data.task_count.map(st => {
                                            return (<Ax>
                                                <td className='text-center'>{st.task_total}</td>
                                            </Ax>)
                                        })
                                        : null}
                                      {data.task_count && data.task_count.length > 0
                                        ?  <td  className='text-center'>  {data.task_count.map(s => s.task_total).reduce((a, b) => a + b, 0)}</td>
                                       : <td  className='text-center'>0</td>}
                                </tr>);
                            }))

                        }

                        </Ax>
                        : <tr><td className="text-center" colSpan={8}>No Record Found</td></tr>
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout> 
         <Helmet><title>Asset Status - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ?   <Ax>
                                    {this.reportFilterFormJsx()}
                                    <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                    {this.reportListingDisplayJsx()}
                                </Ax>
                            :   <Loader  />
                        }
                        {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ActivityWiseTaskReport);