import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import FuelFeedInView from '../../../transactions/fuelFeedIn/FuelFeedInView';
import CounterlogExecuteMeterForm from '../CounterlogExecuteMeterForm';
import AssetService from '../../../../services/AssetService';
import AdditionalAttributeForm from '../../../includes/ui/AdditionalAttributeForm';
import { settings } from 'firebase/analytics';

class FuelFeedIn extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initFuelFeedInForm    =   {
            quantity                :   '',
            unit_rate               :   0,
            fuelstation_id          :   null,
            other_station           :   'Other',
            reference_number        :   '',
            notes                   :   '',
            wallet_id               :   '',
            base64_document         :   '',
            document_name           :   '',
            amount                  :   0,
            debitable               :   'N'
        }
        
        this.initState              =   {
            permissions                 :   [],
            formDataLoading             :   false,
            counterlog                  :   null,
            assetData                   :   null,
            shift                       :   null,
            transaction_id              :   '',
            date                        :   '',
            addFuelFeedInForm           :   {...this.initFuelFeedInForm},
            addFuelFeedInFormSubmitting :   false,
            showMoreDetailsForm         :   'N',
            fuelStationList             :   [],
            allWalletList               :   [],
            selectedFuelData            :   null,
            fuelFeedInData              :   null,
            fuelFeedInViewData          :   null,
            viewDataLoading             :   false,
            selectedFuelType            :   null,
            assetDetailWithCounterlog   :   false,
            edit_counterlog             :   'N',
            asset_loading               :   false,
            disableCounterlog           :   true,
            allAdditonalAttribute   :       [],
            tank_status                 :   'N'
        }
        
        this.state                  =   {
            access_token                :   '',     
            ...this.initState
        }
        
        this.uploadFuelFeedInModalId      =   uuid();
        this.viewFuelFeedInModalId        =   uuid();
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);
        this.uploadFuelFeedInModal        =   new Modal(document.getElementById(this.uploadFuelFeedInModalId), {keyboard: false, backdrop: false});
        this.viewFuelFeedInModal          =   new Modal(document.getElementById(this.viewFuelFeedInModalId), {keyboard: false, backdrop: false});
    }
    
    componentWillReceiveProps(nextProps) {
        this.initalizeComponent(nextProps);
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({access_token : pr.access_token, permissions : pr.permissions});
    }
    
    uploadFuelFeedInModalInit           = (shiftDetail, counterlog, assetData = null, fuelFeedInData = null,asset_id='') => {
        this.uploadFuelFeedInModal.show();
       
        this.setState({...this.initState}, () => {
            this.setState({
                addFuelFeedInForm   :   {
                    ...this.initFuelFeedInForm,
                    fuelstation_id      : null,
                    fuelFeedInData      :   null,
                    selectedFuelType    : null
                },
                assetData           :   assetData,
                shift               :   shiftDetail,
                counterlog          :   counterlog,
                date                :   counterlog ? counterlog.feed_date : '',
                transaction_id      :   counterlog ? counterlog.transaction_id : '',
                assetDetailWithCounterlog : asset_id ? null : assetData,
            },() => {
                if(asset_id){
                    this.getAssetDetail(asset_id)
                }else{
                    let asset = assetData;
                    let assetMeters = AssetService.avaialbleAssetMeters(asset);
                    let meterReading = {};
                    assetMeters.forEach((meter, mk) => {
                        let input_state_key = AssetService.getCounterlogReadingInputKey(meter.key);
                        // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]}; 
                        meterReading[input_state_key] = this.state.counterlog[meter.abs_key];
                    });

                    this.setState({ addFuelFeedInForm: { ...this.state.addFuelFeedInForm, ...meterReading, date_time: this.state.counterlog ? this.state.counterlog.feed_datetime : '' } }, () => {

                    });
                }
            });
        });
        
       
        if(counterlog && counterlog.transaction_id){
            this.loadFormDataFromApi(counterlog.transaction_id, fuelFeedInData)
        }
   } 

    loadFormDataFromApi                 =   (id,fuelFeedInData)  =>  {
        this.setState({formDataLoading: true})
     
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/fuelfeedin/form_data/' + id, this.state.access_token , {exclude_feedin_id : fuelFeedInData ? fuelFeedInData.feedin_id : null}, {}, (response) => {
           let respData = response.data;
           let fuel_stations = respData.fuel_stations && respData.fuel_stations.length > 0 ? [...respData.fuel_stations , {id : null , fuelstation_name : 'Other',fuel_data : null,fuelType : respData.fuelType}]  : [];
           this.setState({
            allAdditonalAttribute :   response.data && response.data.additional_attributes && response.data.additional_attributes.length > 0 ? response.data.additional_attributes : [],
              selectedFuelType      :   this.state.addFuelFeedInForm && this.state.addFuelFeedInForm.fuelstation_id == null ? respData.fuelType : null,
              fuelStationList       :   fuel_stations && fuel_stations.length > 0 ? fuel_stations.map(station => {return({value : station.id,label : station.fuelstation_name,fuel_data : station.fuel_data,fuelType : respData.fuelType})})  : [{value : null , label : 'Other',fuel_data : null,fuelType : respData.fuelType}],
              allWalletList         :  respData.wallets && respData.wallets.length > 0 ?   respData.wallets.map(wallet => {return({label : wallet.wallet_name , value : wallet.id})}) : [] ,  
              setting                     :   respData.setting ? respData.setting : null,
              previousCounterlog          :   respData.prev_cl ? respData.prev_cl  : null,
              previousFuelReading         :   respData.prev_fuel_feedin ? respData.prev_fuel_feedin  : null,
            },() => {
              if(fuelFeedInData){
                this.fetchFuelFeedInData(fuelFeedInData.feedin_id)
              }
           }) 
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }

    getAssetDetail = (id)   =>  {
        this.setState({ asset_loading: true })
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/' + id, this.props.access_token)
            .then((response) => {
                let asset = response.data.data;
                let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
                let meterReading                =   {};
                assetMeters.forEach((meter, mk) => {
                 let input_state_key       =     AssetService.getCounterlogReadingInputKey(meter.key);
                 // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]}; 
                 meterReading[input_state_key]  = this.state.counterlog[meter.abs_key];
             });
             
                this.setState({ assetDetailWithCounterlog: { ...asset, label: asset.name + ' (' + asset.asset_code + ')' } ,addFuelFeedInForm : {...this.state.addFuelFeedInForm,...meterReading,date_time : this.state.counterlog ? this.state.counterlog.feed_datetime : ''}},() => {
                  
                });
            });
        this.setState({ asset_loading: false })
    }
    
    showMoreDetailHandler = () => {
        this.setState({ showMoreDetailsForm: "Y" })
    }
    
    handleFileChange = async (e) => {
        let file = e.target.files[0];
        if (file) {
            let filename = file.name;
            let base64_document = await this.convertBase64(file);
            this.setState({ addFuelFeedInForm: { ...this.state.addFuelFeedInForm, base64_document: base64_document, document_name: filename } })
        }
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    fuelChangeHandler           =       (selectedOption)        =>      {
        if(selectedOption){
           
            this.setState({
                selectedFuelData: selectedOption && selectedOption.fuel_data ? selectedOption.fuel_data : null,
                selectedFuelType: selectedOption && selectedOption.fuelType ? selectedOption.fuelType : null
            },() => {
                this.setState({addFuelFeedInForm : {...this.state.addFuelFeedInForm,unit_rate : selectedOption && selectedOption.fuel_data ?  selectedOption.fuel_data.price : this.state.addFuelFeedInForm.unit_rate}})
            })
        }
    }

    submitFuelFeedInForm                  =   (e) =>  {

        
        let {setting, addFuelFeedInForm, counterlog, edit_counterlog}        =   {...this.state};

        e.preventDefault()
        let additional_attributes = {};
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
            this.state.allAdditonalAttribute.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addFuelFeedInForm[name] ? this.state.addFuelFeedInForm[name] : '';
            })
        }



        if(setting && setting.enable_fuel_feedin_cl && setting.enable_fuel_feedin_cl == 'Y' && addFuelFeedInForm.date_time == counterlog.feed_datetime && edit_counterlog == 'Y' ) {
            toast.error('Counterlog cannot be edited with same Date Time', { position: toast.POSITION.TOP_RIGHT });
        } else{

            // let addFuelFeedInForm   =   {...addFuelFeedInForm};
            // if(edit_counterlog == 'N') {
            //     addFuelFeedInForm   =   {...addFuelFeedInForm , date_time : null}   
            // }

            if(this.state.fuelFeedInData){
                this.setState({ addFuelFeedInFormSubmitting: true });
                HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/counterlog/fuelfeedin', this.props.access_token, null, {
                    ...addFuelFeedInForm, 
                    additional_attributes : additional_attributes, 
                    date_time : edit_counterlog == 'N' ? null : moment(addFuelFeedInForm.date_time).format('YYYY-MM-DD HH:mm:ss'),
                    full_tank : this.state.tank_status
                }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.uploadFuelFeedInModal.hide();
                    if (this.props.afterSaveFuelFeedIn) {
                        let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                        this.props.afterSaveFuelFeedIn(this.state.date, asset_id,this.state.edit_counterlog == 'Y' ? true : false);
                    }
        
                    // this.setState({
                    //     addFuelFeedInForm: { ...this.initFuelFeedInForm },
                    // });
                }).then(() => this.setState({ addFuelFeedInFormSubmitting: false }))
            }else{
                this.setState({ addFuelFeedInFormSubmitting: true });
                HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/counterlog/fuelfeedin', this.props.access_token, null, {
                    ...addFuelFeedInForm,
                    additional_attributes : additional_attributes, 
                    date_time : edit_counterlog == 'N' ? null : moment(addFuelFeedInForm.date_time).format('YYYY-MM-DD HH:mm:ss'),
                    counterlog_transaction_id: this.state.transaction_id,
                    full_tank : this.state.tank_status }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.uploadFuelFeedInModal.hide();
                    if (this.props.afterSaveFuelFeedIn) {
                        let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                        this.props.afterSaveFuelFeedIn(this.state.date, asset_id,this.state.edit_counterlog == 'Y' ? true : false);
                    }
                }).then(() => this.setState({ addFuelFeedInFormSubmitting: false }))
            }
        }
          
    }

    deleteFuelFeedInInit                      =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', TapApiUrls.IAM + '/counterlog/fuelfeedin/' + id , this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteFuelFeedIn) {
                        this.props.afterDeleteFuelFeedIn()
                    }
                }).then(() => { });
            }
        });
    }
    
    viewFuelFeedInModalInit             =   (shiftDetail, counterlog, assetData = null,id)  =>  {
        this.viewFuelFeedInModal.show();
        this.setState({fuelFeedInViewData : null},() => {
            this.setState({
                assetData           :   assetData,
                shift               :   shiftDetail,
                counterlog          :   counterlog,
                date                :   counterlog ? counterlog.feed_date : '',
                transaction_id      :   counterlog ? counterlog.transaction_id : '',
            });
        })
        if(id){
            this.fetchFuelFeedInData(id)
        }
       
    } 

    fetchFuelFeedInData         =       (id)      =>      {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/fuelfeedin_detail/' + id, this.state.access_token, {}, {}, (response) => {
            this.setState({
                fuelFeedInViewData: response.data.data
            })

               let data =  response.data.data
                   let selectedFuelData =   data.fuelStation && this.state.fuelStationList && this.state.fuelStationList.length > 0 ?  this.state.fuelStationList.find(station => {return(station.value == data.fuelStation.id)}) : null
                   let updateFormState = [];
                   if (data && data.additional_attributes_data && data.additional_attributes_data.length > 0) {
                       data.additional_attributes_data.map((i,k) => {
                           let name = 'additional_attributes_' + i.key;
                           updateFormState[name] = i.value
                       });
                   }
                   let meterReading = {};
                    if (data.actual_counterlog) {
                        let reading = data.actual_counterlog && data.actual_counterlog.reading ? data.actual_counterlog.reading : []
                        meterReading['date_time']   = data.actual_counterlog ? moment(data.actual_counterlog.feed_datetime, 'DD-MMM-YYYY HH:mm').toDate()   : new Date()
                        if (reading && reading.length > 0) {
                            reading.forEach((meter, mk) => {
                                let meter_key = meter.key; // Assuming meter.key is 'hour_meter'
                                let input_state_key = meter_key.replace('_', '');
                                // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]}; 
                                meterReading[input_state_key] = meter.reading;
                                if(meter_key == 'operation_cycle'){
                                    meterReading['operation_cycle'] = meter.reading;
                                }
                                if(meter_key == 'operation_cycle2'){
                                    meterReading['operation_cycle2'] = meter.reading;
                                }
                                //meterReading['operation_cycle2'] = meter.reading;
                            });
                        }

                    }

                   
                   
                   this.setState({  
                        addFuelFeedInForm : {
                        ...this.initFuelFeedInForm,
                        ...meterReading,
                        ...updateFormState, 
                        
                        fuelstation_id          :       data.fuelStation ? data.fuelStation.id : null,
                        other_station           :       data.other_station ? data.other_station : '',
                        quantity                :       data.qty ? data.qty : '',
                        unit_rate               :       data.unit_rate ? data.unit_rate : '',
                        reference_number        :       data.fuel_station_ref ? data.fuel_station_ref : '',
                        notes                   :       data.notes ? data.notes : '',
                        wallet_id               :       data.walletData && data.walletData.id ? data.walletData.id : '' ,
                        base64_document         :       '',
                        document_name           :       '',
                        amount                  :       data.amount ? data.amount : '',
                        debitable               :       data.debitable ? data.debitable : 'N',
                        transaction_id          :       data.transaction_id ? data.transaction_id : '',
                    },showMoreDetailsForm       :       'Y',
                    fuelFeedInData              :       response.data.data, 
                    edit_counterlog             :       data.actual_cl_transaction_id ? 'Y' : 'N',
                    tank_status                 :       data.full_tank ,
                    disableCounterlog           :        data.actual_cl_transaction_id ? false : true,
                    selectedFuelData            :       selectedFuelData && selectedFuelData.fuel_data  ? selectedFuelData.fuel_data : null
                });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    handleInputChange = (name, value) => {
        this.setState(prevState => ({
            addFuelFeedInForm: {
                ...prevState.addFuelFeedInForm,
                [name]: value
            }
        }));
    };

    resetCounterlog         =       ()      =>      {
        if(this.state.edit_counterlog == 'N'){
            let asset = this.state.assetDetailWithCounterlog;
            let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
            let meterReading                =   {};
            assetMeters.forEach((meter, mk) => {
             let input_state_key       =     AssetService.getCounterlogReadingInputKey(meter.key);
             // copyReading                 =   {...copyReading , {} :  latest_counterlog[meter.abs_key]}; 
             meterReading[input_state_key]  = this.state.counterlog[meter.abs_key];
         });
         
            this.setState({addFuelFeedInForm : {...this.state.addFuelFeedInForm,...meterReading,date_time : this.state.counterlog ? this.state.counterlog.feed_datetime : ''}},() => {
              
            });
        }
    }

    uploadFuelFeedInModalJsx              =   ()  =>  {
        let {previousCounterlog,previousFuelReading}      = this.state;
        let fuelConsumption = 0;
        let fuelEconomy     =   0;
        let hourMeterPresent = false;
        let odoMeterPresent = false;
        let fuelConsumptionMessage = '-';
        let fuelEconomyMessage = '-';


        if (this.state.assetDetailWithCounterlog && this.state.assetDetailWithCounterlog.labels) {
            const checkMeter = (meter) => this.state.assetDetailWithCounterlog[meter] === "yes";
            hourMeterPresent = checkMeter('hour_meter');
            odoMeterPresent = checkMeter('odo_meter');

        }
        
        if(previousCounterlog && hourMeterPresent && previousFuelReading && previousFuelReading.full_tank == 'Y' && this.state.tank_status == 'Y' && this.state.addFuelFeedInForm.quantity > 0){
            const hourMeterReading = previousCounterlog.reading.find(item => item.key === 'hour_meter').reading;
            const hoursAbs = this.state.addFuelFeedInForm.hourmeter;
            const result = hoursAbs - hourMeterReading;
            if(result > 0){
                fuelConsumption = Number(this.state.addFuelFeedInForm.quantity) / Number(result)
            }   
        }
        if(previousCounterlog && odoMeterPresent && previousFuelReading && previousFuelReading.full_tank == 'Y' && this.state.tank_status == 'Y' && this.state.addFuelFeedInForm.quantity > 0){
            const odoMeterReading = previousCounterlog.reading.find(item => item.key === 'odo_meter').reading;
            const odoAbs = this.state.addFuelFeedInForm.odometer;
            const result = odoAbs - odoMeterReading;
            if(result > 0){
                fuelEconomy =  Number(result) / Number(this.state.addFuelFeedInForm.quantity)
            }   
        }

        if(hourMeterPresent && (previousFuelReading && previousFuelReading.full_tank !== 'Y' || this.state.tank_status !== 'Y')){
            fuelConsumptionMessage = 'Cannot calculate as the tank status is different';
        }else if(!hourMeterPresent){
            fuelConsumptionMessage = 'NA';
        }

        if(odoMeterPresent && (previousFuelReading && previousFuelReading.full_tank !== 'Y' || this.state.tank_status !== 'Y')){
            fuelEconomyMessage = 'Cannot calculate as the tank status is different';
        }else if(!odoMeterPresent){
            fuelEconomyMessage  = 'NA';
        }
        
        return (
            <div className="modal fade" id={this.uploadFuelFeedInModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>Upload Shift FuelFeedIn for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addFuelFeedInFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "27%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body mx-1">
                            {this.state.formDataLoading || this.state.viewDataLoading
                                ? <Loader />
                                : <form className={['p-1',].join(' ')} onSubmit={this.submitFuelFeedInForm} id="uploadFuelFeedInForm">

                                    <div className="row align-items-center ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Fuel Station Name</label>
                                        <div className='col-sm-5 my-1'>
                                            <TapSelect
                                                options={this.state.fuelStationList}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addFuelFeedInForm', 'fuelstation_id');
                                                    this.fuelChangeHandler(selectedOption)
                                                }}
                                                isSearchable={true}
                                                value={this.state.fuelStationList.find(s => this.state.addFuelFeedInForm.fuelstation_id == s.value)}
                                                isClearable={true}
                                                placeholder="Select Fuel Station"
                                                autoCompelete="off"
                                                required={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                        {this.state.selectedFuelData ? <div className='col-sm-4 form-text text-sm'>Current Closing Balance is <span className={this.state.selectedFuelData && this.state.selectedFuelData.closing_balance > 0 ? "text-success" : "text-danger"}>{this.state.selectedFuelData.closing_balance}</span> Litre </div> : null}
                                    </div>
                                    {this.state.addFuelFeedInForm && !this.state.addFuelFeedInForm.fuelstation_id
                                        ? <div className="row ">
                                            <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Other Station Details</label>
                                            <div className='col-sm-5 my-1'>
                                                <input
                                                    name="other_station"
                                                    value={this.state.addFuelFeedInForm.other_station}
                                                    disabled={this.state.addFuelFeedInForm && this.state.addFuelFeedInForm.fuelstation_id ? true : false}
                                                    onChange={(e) => this.formInputHandler(e, "addFuelFeedInForm")}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Enter Other Station Details"
                                                />
                                            </div>
                                        </div> : null}

                                    <div className="row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Quantity</label>
                                        <div className='col-sm-5 my-1'>
                                            <input
                                                name="quantity"
                                                value={this.state.addFuelFeedInForm.quantity}
                                                onChange={(e) => this.formInputHandler(e, "addFuelFeedInForm")}
                                                className="form-control form-control-sm"
                                                type="text"
                                                autoComplete="off"
                                                autoFocus={true}
                                                onKeyUp={(e) => {
                                                    if (this.state.addFuelFeedInForm && this.state.addFuelFeedInForm.quantity > 0 && this.state.addFuelFeedInForm.unit_rate && this.state.addFuelFeedInForm.unit_rate > 0) {
                                                        let amount = this.state.addFuelFeedInForm.unit_rate * this.state.addFuelFeedInForm.quantity;
                                                        this.setState({ addFuelFeedInForm: { ...this.state.addFuelFeedInForm, amount: parseFloat(amount).toFixed(2) } })
                                                    }
                                                }}

                                                placeholder="Please enter Quantity"
                                            />
                                        </div>
                                        <div className='col-sm-4 form-text text-sm'>{this.state.selectedFuelType ? `Quantity of ${this.state.selectedFuelType.fuel_type} in  ${this.state.selectedFuelType.unit}` : null}</div>
                                    </div>
                                    <div className="row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Unit Rate</label>
                                        <div className='col-sm-5 my-1'>
                                            <input
                                                name="unit_rate"
                                                value={this.state.addFuelFeedInForm.unit_rate}
                                                onChange={(e) => this.formInputHandler(e, "addFuelFeedInForm")}
                                                className="form-control form-control-sm"
                                                type="number"
                                                autoComplete="off"
                                                onKeyUp={(e) => {
                                                    if (this.state.addFuelFeedInForm && this.state.addFuelFeedInForm.quantity > 0 && this.state.addFuelFeedInForm.unit_rate && this.state.addFuelFeedInForm.unit_rate > 0) {
                                                        let amount = this.state.addFuelFeedInForm.unit_rate * this.state.addFuelFeedInForm.quantity;
                                                        this.setState({ addFuelFeedInForm: { ...this.state.addFuelFeedInForm, amount: parseFloat(amount).toFixed(2) } })
                                                    }
                                                }}
                                                placeholder="Please enter Rate"
                                            />
                                        </div>
                                        <div className='col-sm-4 form-text text-sm'>{this.state.selectedFuelType ? `Unit Rate in INR/${this.state.selectedFuelType.unit}` : null}</div>
                                    </div>
                                    <div className="row align-items-center">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Amount</label>
                                        <div className='col-sm-5 my-1'>
                                            <input
                                                name="amount"
                                                value={this.state.addFuelFeedInForm.amount}
                                                onChange={(e) => this.formInputHandler(e, "addFuelFeedInForm")}
                                                className="form-control form-control-sm"
                                                type="number"
                                                autoComplete="off"
                                                onKeyUp={(e) => {
                                                    if (this.state.addFuelFeedInForm.amount && this.state.addFuelFeedInForm.amount > 0 && this.state.addFuelFeedInForm.unit_rate && this.state.addFuelFeedInForm.unit_rate > 0) {
                                                        let quantity = this.state.addFuelFeedInForm.amount / this.state.addFuelFeedInForm.unit_rate;
                                                        this.setState({ addFuelFeedInForm: { ...this.state.addFuelFeedInForm, quantity: parseFloat(quantity).toFixed(2) } })
                                                    }
                                                }}
                                                placeholder="Please enter Amount"
                                            />
                                        </div>
                                        <div className='col-sm-4 form-text text-sm'>Amount in INR</div>
                                    </div>
                                    
                                        <div className="row ">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  my-1">Tank Status</label>
                                            <div className='col-sm-5 my-1'>
                                            <input
                                                name="tank_status"
                                                type="checkbox"
                                                value={this.state.tank_status}
                                                onChange={(e) => {
                                                    this.setState({ tank_status: this.state.tank_status == 'N' ? 'Y' : 'N' }, () => {
                                                       
                                                    })
                                                }}
                                                checked={this.state.tank_status == 'Y'}
                                                className="form-check-input"
                                                id="tank_status"
                                            />
                                             <label className="form-check-label mx-2" htmlFor="tank_status">Full Tank </label>
                                            </div>
                                        </div>
                                    {this.state.setting &&  this.state.setting.enable_fuel_feedin_cl && this.state.setting.enable_fuel_feedin_cl == 'Y' && <>
                                    <div className=' my-2'>
                                        <input
                                            name="edit_counterlog"
                                            type="checkbox"
                                            value={this.state.edit_counterlog}
                                            onChange={(e) => {
                                                this.setState({ edit_counterlog: this.state.edit_counterlog == 'N' ? 'Y' : 'N' },() => {
                                                    this.setState({disableCounterlog : this.state.edit_counterlog == 'Y' ? false : true})
                                                    this.resetCounterlog()
                                                 })
                                            }}
                                            checked={this.state.edit_counterlog == 'Y'}
                                            className="form-check-input"
                                            id="edit_counterlog"
                                        />
                                        <label className="form-check-label mx-2" htmlFor="edit_counterlog">Edit CounterLog </label>
                                    </div>
                                     {this.state.assetDetailWithCounterlog && 
                                     <CounterlogExecuteMeterForm 
                                        onInputChange={this.handleInputChange} 
                                        executeForms={this.state.addFuelFeedInForm} 
                                        assetDetail={this.state.assetDetailWithCounterlog}
                                        shiftDetail={this.state.shift}
                                        disableInput={this.state.disableCounterlog}/> 
                                        }
                                   </>}

                                   {this.state.setting && this.state.setting.enable_fuel_feedin_economy == 'Y' && <div className='counterlog'>
                                        <div className="tab_content_wrapper mb-3"><span className="content_heading">Fuel Calculation</span></div>
                                        <div className='form-text'>
                                            <b>Fuel Consumption</b> : {fuelConsumption > 0 ? parseFloat(fuelConsumption).toFixed(2) : fuelConsumptionMessage}
                                        </div>
                                        <div className='form-text'>
                                            <b>Fuel Economy</b> : {fuelEconomy > 0 ? parseFloat(fuelEconomy).toFixed(2) : fuelEconomyMessage}
                                        </div>
                                    </div>}
                                  
                                         {
                                            this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0
                                                ? (
                                                    <AdditionalAttributeForm
                                                        additionalAttributes={this.state.allAdditonalAttribute}
                                                        formState={this.state.addFuelFeedInForm}
                                                        onAttributeChange={(value, attributeName) => {
                                                            this.setState(() => ({
                                                                addFuelFeedInForm: {
                                                                    ...this.state.addFuelFeedInForm,
                                                                    [attributeName]: value
                                                                }
                                                            }))
                                                        }
                                                        }
                                                        formSmall={true}
                                                        colSpan={"col-sm-5"}
                                                    />
                                                )
                                                : null
                                        }
                                        <div className="row ">
                                                <label className="col-sm-3 col-form-label col-form-label-sm my-1">Reference Number</label>
                                                <div className='col-sm-5 my-1'>
                                                    <input
                                                        name="reference_number"
                                                        value={this.state.addFuelFeedInForm.reference_number}
                                                        onChange={(e) => this.formInputHandler(e, "addFuelFeedInForm")}
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Enter Fuel Station Reference Number"
                                                    />
                                                </div>
                                            </div>
                                    {this.state.showMoreDetailsForm == "N" ? <div className="row">
                                        <div className='col-sm-12 my-1 text-end link-primary cursor_pointer' onClick={this.showMoreDetailHandler}>+ Add more details </div>
                                    </div> : null}
                                    {this.state.showMoreDetailsForm == "Y"
                                        ?
                                        <Ax>
                                            
                                            {this.props && this.props.permissions && this.props.permissions.group_sub_modules && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet") ?
                                        <div className="row ">
                                            <label className="col-sm-3 col-form-label col-form-label-sm  my-1">Wallet</label>
                                            <div className='col-sm-5 my-1'>
                                                <TapSelect
                                                    options={this.state.allWalletList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addFuelFeedInForm', 'wallet_id');
                                                    }}
                                                    value={this.state.allWalletList.find(s => this.state.addFuelFeedInForm.wallet_id == s.value)}
                                                    placeholder="Select Wallet"
                                                    autoCompelete="off"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        : null}
                                            <div className="row ">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  my-1">Attach Invoice</label>
                                                <div className='col-sm-5 my-1'>
                                                    <input
                                                        name="document"
                                                        onChange={(e) => { this.handleFileChange(e) }}
                                                        className="form-control form-control-sm"
                                                        type="file"
                                                        autoComplete="off"
                                                        placeholder="Attach Invoice"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm my-1">Notes</label>
                                                <div className='col-sm-5 my-1'>
                                                    <textarea
                                                        name="notes"
                                                        value={this.state.addFuelFeedInForm.notes}
                                                        onChange={(e) => this.formInputHandler(e, "addFuelFeedInForm")}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        placeholder="Enter Notes"
                                                        style={{ height: "60px", fontSize: "93%" }}
                                                    />
                                                </div>
                                            </div>

                                        </Ax>
                                        : null}
                                </form>
                            }
                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">
                                <input
                                    name="debitable"
                                    type="checkbox"
                                    value={this.state.addFuelFeedInForm.debitable}
                                    onChange={(e) => {
                                        this.setState({ addFuelFeedInForm: { ...this.state.addFuelFeedInForm, debitable: this.state.addFuelFeedInForm.debitable == 'N' ? 'Y' : 'N' } })
                                    }}
                                    checked={this.state.addFuelFeedInForm.debitable == 'Y'}
                                    className="form-check-input"
                                    id="debitable"
                                />
                                <label className="form-check-label mx-2" htmlFor="debitable">Mark as debitable </label>
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn btn-secondary mx-2" disabled={this.state.addFuelFeedInFormSubmitting} data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.addFuelFeedInFormSubmitting} form="uploadFuelFeedInForm">Update {this.state.addFuelFeedInFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    viewFuelFeedInModalJsx              =   ()  =>  {
        let fuelFeedIn                    =   this.state.fuelFeedInViewData;
        return (
            <div className="modal fade" id={this.viewFuelFeedInModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>View FuelFeedIn for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            {this.state.viewDataLoading
                                ? <Loader />
                                : <div>
                                    <FuelFeedInView fuelFeedInData={this.state.fuelFeedInViewData}/>
                                </div>}
                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">

                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" >Close</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<Ax>
           {this.uploadFuelFeedInModalJsx()}
           {this.viewFuelFeedInModalJsx()}
        </Ax>)
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(FuelFeedIn);

