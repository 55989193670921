import React from 'react';
import { connect } from 'react-redux';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import TapIcon from '../../services/TapIcon';


class VendorDirectoryList extends React.Component {

    constructor(props) {
        super(props);
       
        
        this.state                          =     {
           

        };
    }

    componentDidMount() {
    
    }

   
    render                              =   ()  =>  {
        return (<ApplicationLayout>
            <div className='row m0 '>
                <div className='col-sm-12'>
                    <div className="card">
                        <div className="card-body d-flex justify-content-between">
                           <div> <h6 className="card-title">Test 12 Vendor</h6></div>
                           <div> <h6 className="card-title">Rating :  <span className="icon ">★</span>
                            <span className="icon">★</span>
                            <span className="icon">★</span>
                            <span className="icon">★</span>
                            <span className="icon">★</span>
                            </h6></div>

                        </div>
                    </div>
                </div>
            </div>
        </ApplicationLayout>

        );
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(VendorDirectoryList);