import React from 'react';
import iconError from '../../../assets/img/svg/icon-error.svg';

class AlertErrorMsg extends React.Component {

    render() {
        return (
            <div className="alert alert-danger mx-2 d-flex align-items-center" role="alert">
                <img alt='Error' src={iconError} className="me-2" style={{height : 24}} /><div>{this.props.children}</div>
            </div>
        );
    }
}

export default AlertErrorMsg;