import React from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import ReactPaginate from "react-paginate";
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon"
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from "../../components/ui/TapSelect";
import {Modal} from "bootstrap";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { DisplayListPagination } from '../../components/TapUi';
import { Link } from 'react-router-dom';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapIcon from '../../services/TapIcon';
import {AllCheckerCheckbox, Checkbox, CheckboxGroup} from "@createnl/grouped-checkboxes";
import permissionsData from './AssetPermission.json';

class AssetPermission extends AppBaseComponent {
  
    constructor() {
        super();
     
        this.state = {
           roleData         :       null,
           listing_loading  :       false,
           allTransactionLockingList    :   [],
           allPermissionList            :   [],
           fullAccessChecked            :   false,
           selectedPermissions: [],
           subSectionFullAccess: {},
           moduleFullAccess: {},      // Initial state for module full access checkboxes
           overallFullAccess: false,
           ...permissionsData,
          
        }
   }


   componentDidMount() {
        this.initilaizeData(this.props);
   }

    componentWillReceiveProps(nextProps) {
        if (nextProps.roleData !== this.props.roleData) {
            this.initilaizeData(nextProps);
        }
    }


    initilaizeData          =       (pr)        =>      {
        if(pr && pr.roleData){
            this.getAllPermissionsList(pr.roleData.id);
            this.setState({
                roleData : pr.roleData
            })
        }
    }
    
    getAllPermissionsList = (id) => {
        this.setState({ listing_loading: true ,selectedPermissions :[], subSectionFullAccess: {},
            moduleFullAccess: {},      // Initial state for module full access checkboxes
            overallFullAccess: false});
    
        HttpAPICall.withAthorization('GET', TapApiUrls.APP_URL + '/group/role/permission/' + id, this.props.access_token, {}, {}, (response) => {
            if (response.data && response.data.all_permissions && response.data.all_permissions.length > 0) {
                let allTransactionLockingPermission = response.data.all_permissions.filter(i => i.transaction_locking == "Y")
                let allTransactionList = allTransactionLockingPermission.map(value => Object.values(value).filter(i => i !== "Y").map((acc, curr) => { return acc }))
                let allTransactionLockingList = allTransactionList.flat()
    
                // Get all the permissions
                let allPermissions = response.data && response.data.data && response.data.data.length > 0 ? response.data.data : []
    
                // Update the state of selected permissions
                this.setState({
                    selectedPermissions: allPermissions,
                    allTransactionLockingList: allTransactionLockingList
                },() => {
                    this.updateFullAccessCheckboxes(allPermissions);
                });
    
                // Update the state of full access checkboxes based on the permissions
                
            }
        }).then(() => this.setState({ listing_loading: false }));
    }
    
    updateFullAccessCheckboxes(selectedPermissions) {
        // Loop through modules and subsections to determine full access checkboxes
        let newModuleFullAccess = {};
        let newSubSectionFullAccess = {};
    
        this.state.permissions.forEach(module => {
            module.sub_sections.forEach(ss => {
                const areAllSubSectionPermissionsSelected = ss.permissions.every(perm => selectedPermissions.includes(perm.permission));
                newSubSectionFullAccess[ss.id] = areAllSubSectionPermissionsSelected;
            });
    
            const isAllSubSectionsFullAccessChecked = module.sub_sections.every(ss => newSubSectionFullAccess[ss.id]);
            newModuleFullAccess[module.id] = isAllSubSectionsFullAccessChecked;
        });
    
        const isAllModulesFullAccessChecked = this.state.permissions.every(module => newModuleFullAccess[module.id]);
    
        this.setState({
            moduleFullAccess: newModuleFullAccess,
            subSectionFullAccess: newSubSectionFullAccess,
            overallFullAccess: isAllModulesFullAccessChecked
        });
    }
    
    isPermissionSelected(permission) {
        return this.state.selectedPermissions.includes(permission);
    }

    isFullAccessChecked(subSectionId) {
        return this.state.subSectionFullAccess[subSectionId] || false;
    }


    toggleFullAccess(subSectionId, moduleId) {
        this.setState(prevState => {
            const selectedPermissions = [...prevState.selectedPermissions];
            const module = this.state.permissions.find(p => p.id === moduleId);
    
            if (!module) return;
    
            const subSection = module.sub_sections.find(ss => ss.id === subSectionId);
    
            if (!subSection) return;
    
            // Check if all subSection's permissions are selected
            const isSubSectionFullAccess = subSection.permissions.every(perm => selectedPermissions.includes(perm.permission));
    
            // Update the selectedPermissions based on the full access state
            if (isSubSectionFullAccess) {
                subSection.permissions.forEach(perm => {
                    const index = selectedPermissions.indexOf(perm.permission);
                    selectedPermissions.splice(index, 1);
                });
            } else {
                subSection.permissions.forEach(perm => {
                    if (!selectedPermissions.includes(perm.permission)) {
                        selectedPermissions.push(perm.permission);
                    }
                });
            }
    
            // Update the state of the subSection's full access checkbox
            const newSubSectionFullAccess = { ...prevState.subSectionFullAccess };
            newSubSectionFullAccess[subSectionId] = !isSubSectionFullAccess;
    
            // Update the state of the module's full access checkbox
            const isAllSubSectionsFullAccessChecked = module.sub_sections.every(ss =>
                newSubSectionFullAccess[ss.id]
            );
    
            this.setState({
                selectedPermissions,
                subSectionFullAccess: newSubSectionFullAccess,
                moduleFullAccess: isAllSubSectionsFullAccessChecked
            });
        });
    }


    togglePermission(permission) {
        this.setState(prevState => {
            const selectedPermissions = [...prevState.selectedPermissions];
            
            if (selectedPermissions.includes(permission)) {
                const index = selectedPermissions.indexOf(permission);
                selectedPermissions.splice(index, 1);
            } else {
                selectedPermissions.push(permission);
            }
    
            // Check if all permissions in a subSection are selected
            this.state.permissions.forEach(module => {
                module.sub_sections.forEach(ss => {
                    const areAllSubSectionPermissionsSelected = ss.permissions.every(perm => selectedPermissions.includes(perm.permission));
    
                    // Update the state of the subSection's full access checkbox
                    this.setState(prevState => {
                        const newSubSectionFullAccess = { ...prevState.subSectionFullAccess };
                        newSubSectionFullAccess[ss.id] = areAllSubSectionPermissionsSelected;
    
                        return { subSectionFullAccess: newSubSectionFullAccess };
                    });
                });
    
                // Check if all subSections' full access are selected
                const isAllSubSectionsFullAccessChecked = module.sub_sections.every(ss => this.state.subSectionFullAccess[ss.id]);
    
                // Update the state of the module's full access checkbox
                this.setState(prevState => {
                    const newModuleFullAccess = { ...prevState.moduleFullAccess };
                    newModuleFullAccess[module.id] = isAllSubSectionsFullAccessChecked;
    
                    return { moduleFullAccess: newModuleFullAccess };
                });
            });
    
            // Check if all modules' full access are selected
            const isAllModulesFullAccessChecked = this.state.permissions.every(module =>
                this.state.moduleFullAccess[module.id]
            );
    
            // Update the state of the overall full access checkbox
            this.setState({
                overallFullAccess: isAllModulesFullAccessChecked
            });
    
            return { selectedPermissions };
        });
    }
    
    
    updatePermission(e){
        e.preventDefault();
    
         let  frmData = {
             role_id : this.state.roleData.id,
             permission : this.state.selectedPermissions
        }; 
        this.setState({saveFormSubmitting : true})
        axios({
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.access_token},
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER + '/group/role/permission'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.getAllPermissionsList(this.state?.roleData?.id);
            this.setState({saveFormSubmitting : false})
        }).catch((err) => {
            this.setState({saveFormSubmitting : false})
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});

    }
   
    render() {
       let permission =  this.state.allPermissionList;
       let hg_permission = this.props.group_permission.group_sub_modules.find(s => s.sub_module == "hg_sap") ? true :  false;
       return (
           <Ax>
               <Helmet><title>Role - Asset Permission</title></Helmet>
               {this.state.permission_loading ? <div className='text-center'><Loader /></div>
                   : <form className="bg-white px-2" onSubmit={this.updatePermission.bind(this)} id="permissionUpdateForm">
                       <div className="tab-pane show active " id="permission" role="tabpanel" aria-labelledby="permission-tab">
                               <div className="tab_content_wrapper my-2"><span className="content_heading">Permission Details</span></div>
                               {this.state.permissions && this.state.permissions.filter(ps => ps.id !== 10 || (ps.id === 10 && hg_permission)).map((ps, key) => {
                                   return (<table className="table table-bordered table-hover bg-white mb-3" key="key">
                                       <thead className="table-secondary">
                                           <tr>
                                               <th scope="col" style={{ width: "30%" }}>{ps.name}</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                               <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                           </tr>
                                       </thead>
                                       <tbody>

                                           {ps.sub_sections && ps.sub_sections.length > 0 && ps.sub_sections.map(module => (
                                               <tr key={module.id} className="permission_row">
                                                   <td>{module.name}</td>
                                                   <td className="text-center">
                                                       <label>
                                                           <input
                                                               type="checkbox"
                                                               checked={this.isFullAccessChecked(module.id)}
                                                               onChange={() => this.toggleFullAccess(module.id, ps.id)}
                                                           />
                                                       </label>
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "view") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                        {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "add") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                       {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "edit") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                       {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "delete") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                       {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions && module.permissions.length > 0 && module.permissions.find(perm => perm.type === "other") && (
                                                           <Ax>
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                               </button>
                                                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                               {module.permissions.map(perm => {
                                                                   if (perm.type === "other") {
                                                                       return (
                                                                        <li className="dropdown-item" style={{ display: "flex" }}>
                                                                           <label key={perm.id}>
                                                                               <input
                                                                                   type="checkbox"
                                                                                   checked={this.isPermissionSelected(perm.permission)}
                                                                                   onChange={() => this.togglePermission(perm.permission)}
                                                                               />
                                                                               {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                                <span className="mx-2" >{perm.name}</span>
                                                                           </label>
                                                                           </li>
                                                                       );
                                                                   }
                                                                   return null;
                                                               })}

                                                            </ul>
                                                           </Ax>
                                                       )}
                                                   </td>
                                               </tr>
                                           ))}
                                        
                                          

                                       </tbody>
                                   </table>)
                               })}
                               <div className="text-end mb-3">
                                   <span className="small text-danger"> * Transaction Locking applicable in these permissions</span></div>
                               <div className="float-end">
                                   <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary mb-2">
                                       Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                   </button>
                               </div>
                       </div>

                   </form>}
           </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps)(AssetPermission);