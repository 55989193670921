import React from "react";
import AppBaseComponent from "../../../../components/AppBaseComponent";
import { connect } from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import { Modal } from "bootstrap";
import Ax from "../../../../components/hoc/Ax";
import HttpAPICall from "../../../../services/HttpAPICall";
import { HRM_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../../../../components/ui/Loader/Loader";
import swal from "sweetalert";

class EmployeeDivision extends AppBaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      formData: { name: "" },
      saveFormSubmitting: false,
      list_loading: false,
      form_loading: false,
      modalType: "Add",
      division_id: "",
      emp_division_list: [],
    };

    this.modalRef = React.createRef();

    this.emp_division_url = HRM_API_BASE_URL_2 + '/division';

  }

  componentDidMount() {
    this.empDivision = new Modal(document.getElementById("empDivision"), {
      keyboard: false,
      backdrop: false,
    });
    this.divisionList();
  }

  divisionList = () => {
    this.setState({ list_loading: true });
    HttpAPICall.withAthorization("GET", this.emp_division_url,
      this.props.access_token, {}, {}, (response) => {
        this.setState({
          emp_division_list: response.data,
        });
      }
    ).then(() => this.setState({ list_loading: false }));
  };

  handleModal = (type = null, id = null) => {
    if (type === "Edit") {
      const divisionData = this.state.emp_division_list.find(
        (t) => t.id === id
      );
      this.setState({
        formData: { name: divisionData?.name },
      });
    } else {
      this.setState({
        formData: { name: "" },
      });
    }
    this.setState({ modalType: type, division_id: id }, () => {
      this.empDivision.show();
    });
  };

  submitModalForm = (e) => {
    e.preventDefault();
    let method = "POST";
    let frmData = { ...this.state.formData };
    this.setState({ saveFormSubmitting: true });
    if (this.state.modalType === "Edit") {
      method = "PUT";
      frmData = { ...frmData, id: this.state.division_id };
    }

    HttpAPICall.withAthorization(method, this.emp_division_url, this.props.access_token,
      {}, { ...frmData }, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.divisionList();
        this.empDivision.hide();
        this.setState({
          editEmployeeForm: { ...this.editEmployeeFormInit },
          formData: { name: "" },
        });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  delete = (id) => {
    swal({
      title: "Delete",
      text: "This action cannot be reversed, Are you sure you want to proceed?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Yes"],
    }).then((willDelete) => {
      if (willDelete) {
        HttpAPICall.withAthorization("DELETE", this.emp_division_url + "/" + id,
          this.props.access_token, {}, {}, (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.divisionList();
          }
        );
      }
    });
  };

  modalJsx = () => {
    return (
      <div className="modal fade" id="empDivision" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="empDivisionLabel">
                {this.state.modalType} Employee Department
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="emp-division-form" onSubmit={this.submitModalForm}>
                <div className="row align-items-center my-3">
                  <div className="col-sm-4">
                    <label htmlFor="fname" className="form-label require">
                      Department
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      name="name"
                      type="text"
                      value={this.state.formData.name}
                      className="form-control form-control-sm"
                      autoComplete="off"
                      required={true}
                      onChange={(e) => this.formInputHandler(e, "formData")}
                      placeholder="Please enter Department Name"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                disabled={this.state.saveFormSubmitting ? true : false}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                form="emp-division-form"
                disabled={this.state.saveFormSubmitting ? true : false}
                className="btn btn-primary mx-2"
              >
                Save{" "}
                {this.state.saveFormSubmitting ? (
                  <tapIcon.FontAwesomeIcon
                    icon={tapIcon.faSyncAlt}
                    className="fa-spin"
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane  show active"
            id="general"
            role="tabpanel"
            aria-labelledby="general-tab"
          >
            <div className="tab_content_header">
              <div className="tab_content_wrapper">
                <span className="content_heading">Employee Department</span>
                <div className="text-end ">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.handleModal("Add", null)}
                  >
                    <tapIcon.FontAwesomeIcon
                      icon={tapIcon.faPlus}
                      alt="item-action"
                    />
                  </button>
                </div>
              </div>
              <table className="table table-hover table-bordered my-4 ">
                <thead className="table-secondary">
                  <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>
                      S.No
                    </th>
                    <th scope="col" className="text-start">
                      Name
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.list_loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <Loader />
                      </td>
                    </tr>
                  ) : this.state.emp_division_list.length > 0 ? (
                    this.state.emp_division_list.map((data, i) => {
                      return (
                        <tr>
                          <td className="text-center">{i + 1}</td>
                          <td>{data.name}</td>
                          <td className="text-center">
                            <tapIcon.imageIcon
                              icon={tapIcon.ListActionIcon}
                              alt="action-icon"
                              className="img-fluid px-3"
                              data-bs-toggle="dropdown"
                              href="#"
                              role="button"
                              aria-expanded="false"
                            />
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() =>
                                    this.handleModal("Edit", data.id)
                                  }
                                >
                                  Edit
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => this.delete(data.id)}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.modalJsx()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
  };
};

export default connect(mapStateToProps)(EmployeeDivision);
