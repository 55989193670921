import React from 'react';

import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";



class  InventoryAssetList extends React.Component {

   /* measurableAssetId = localStorage.getItem('MeasurableAsset')*/

    constructor() {
        super();
        this.state                      =  {
            listing_loading             :      false,
            listingMeta                 :       null,
            minimizeTable               :       false,
            inventoryAssetListing       :          [],
            page                        :          1,

        };
    }

    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable : false});
    }

    viewHistory(id){
        this.setState({minimizeTable:true})
        /*  if(id === id) {
              const access_token = localStorage.getItem('access_token');
              axios({
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + access_token
                  },
                  params : {scs_execution_id : id},
                  url: process.env.REACT_APP_API_SERVER + '/asset/execution_detail'
              }).then((response) => {
                  this.setState({viewHistoryDetails: response.data.scs,viewExecutionInterval:response.data.execution_interval})
                  this.getAssetCard(response.data.scs.asset_id)
              }).catch((err) => {
                  toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
              })
          }*/

    }


    componentDidMount() {

    }
    handlePageClick = (e) => {
        this.setState({page: e.selected + 1})
        this.state.page = e.selected + 1;
        /*this.getFeedInListing(this.state.page)*/
    }

    inventoryAssetListjsx                         =   ()  =>  {

        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Inventory Asset List</h3>
            </div>

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.listingTableTrJsx()}</div>
                   {/* { this.state.minimizeTable ? <div className="col-9 pr0 pl0">Details</div> : null }*/}
                </div>
            </div>
        </Ax>);
    }

    listingTableTrJsx                   =   ()  =>  {
        return (
            <Ax>
                <table className="table table-bordered bg-white table-hover table-fixed">
                    <thead className="table-secondary">
                     <tr>
                         <th scope="col" style={{width:"5%"}} className="text-center">S.No</th>
                         <th scope="col" style={{width:"20%"}}>Asset Name</th>
                         <th scope="col" style={{width:"10%"}}>Code</th>
                         <th scope="col" style={{width:"20%"}}>Item Name</th>
                         <th scope="col" style={{width:"10%"}}>Item Code</th>
                         <th scope="col" style={{width:"13%"}}>Site Name</th>
                         <th scope="col" style={{width:"7%"}}>Quantity</th>
                         <th scope="col" style={{width:"10%"}}>Status</th>
                         <th scope="col" style={{width:"5%"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody >
                    <tr>
                        <td className="text-center">1</td>
                        <td><Link to={"/inventory_asset_view"}>Name</Link></td>
                        <td>Code</td>
                        <td>Item Name</td>
                        <td>code</td>
                        <td >site name</td>
                        <td>Quantity</td>
                        <td> <tapIcon.FontAwesomeIcon icon={tapIcon.faCircle}   className="mr2" />
                        </td>
                        <td>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" role="button" >View</a></li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </Ax>
        )

    }


    getPageScreen                       =   ()  =>  {
        let pageScreen = null;
        {
            pageScreen  = this.inventoryAssetListjsx();
        }
        return pageScreen;
    }

    render = () => {
        return (<ApplicationLayout>
            {this.getPageScreen()}
        </ApplicationLayout>);
    }
}

export default InventoryAssetList;