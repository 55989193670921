import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ItemViewInList from '../../inventory/includes/ItemViewInList';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import DateService from '../../../services/DateService';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import TapIcon from '../../../services/TapIcon';
import SalesOrderDocumentModal from './SalesOrderDocumentModal';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

class SalesOrderDetail extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            detail_loading: false,
            salesOrderDetail: null,
            salesTransactionID: null,
            itemView: "amount"

        };
        this.salesOrderDetailUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/detail';
        this.soDocDeleteUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/doc_delete/doc_id';
        this.itemDetailsRef = React.createRef();
        this.documentRef                =   React.createRef();

    }

    componentDidMount() {

        if (this.props && this.props.salesID) {
            this.getQuotationDetail(this.props.salesID)
        }
        if (this.props && this.props.salesOrderDetail) {
            this.setState({ salesOrderDetail: this.props.salesOrderDetail })
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.salesID !== this.props.salesID) {
            this.getQuotationDetail(nextProps.salesID)
        }
        if (nextProps.salesOrderDetail !== this.props.salesOrderDetail) {
            this.setState({ salesOrderDetail: nextProps.salesOrderDetail })
        }

    }

    getQuotationDetail = (transaction_id) => {
        this.setState({ detail_loading: true })
        HttpAPICall.withAthorization('GET', this.salesOrderDetailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                salesOrderDetail: respData,
                salesTransactionID: respData.transaction_id,
            });

        }).then(() => this.setState({ detail_loading: false }));
    }

    viewItemModalInit = (item) => {
        if (item) {
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    formatAddress(address) {
        return <>
            {address && <Ax>
                {address.address1 ? address.address1 + ", " : ""}  {address.address2 ? address.address2 + ", " : ""}
                {address.landmark ? address.landmark + ", " : ""} {address.city ? address.city + ". " : ""}<br />
                Location : <b>{address.state_name ? address.state_name + ", " : ""}  {address.district_name ? address.district_name + "& " : ""}  {address.pincode ? address.pincode + "." : ""} <br /></b>
                GSTIN   :   <b>{address.gstin ? address.gstin : "-"}</b>
            </Ax>}
        </>
    }

    handleDocumentModal  =   ()  =>  {
        this.documentRef.current.showModalInit();
    }

    deleteSODocument   =   (doc_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.soDocDeleteUrl.replace('doc_id', doc_id), this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.getQuotationDetail(this.state.salesOrderDetail.transaction_id);
                }).then(() => { });
            }
        });
    }


    reqDetailJsx = () => {
        let sales = this.state.salesOrderDetail;
        let purchase_summary = [];
        if (sales && sales.customerAddress && sales.customerAddress.state_id == sales.warehouse_state_id) {

            purchase_summary.push({ key: 'CGST ', value: "As applicable" });
            purchase_summary.push({ key: 'SGST ', value: "As applicable" });
        } else {
            purchase_summary.push({ key: 'IGST ', value: "As applicable" });
        }

        let sub_total_amount = sales && sales.items && sales.items.length > 0 && sales.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);

        let tcs_amount = sales && Number(sales.tcs_rate_vc && sales.tcs_rate_vc >= 0
            ? sales && sales.tcs_type == "Percentage" ? (sub_total_amount * sales.tcs_rate_vc / 100) : Number(sales.tcs_rate_vc)
            : 0);
        return (<Ax>

            {this.state.detail_loading ? <Loader />
                : <div className="modal-body ">
                    {this.state.salesOrderDetail
                        ? <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{sales.transaction_id}</td>
                                        <td scope="col">{DateService.dateTimeFormat(sales.transaction_date, 'DD-MMM-YYYY')}</td>
                                        <td scope="col" className="align-middle">
                                            {sales.status ? sales.status.name : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Sales Order Type</td>
                                        <td colSpan={2}>{sales.sales_order_type_name ? sales.sales_order_type_name : ""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} >Warehouse</td>
                                        <td colSpan={2}>{sales.warehouse ? <div><b>{sales.warehouse.name} ({sales.warehouse.code})</b> <br />
                                            {sales.warehouse_address ?
                                                <div className='pt-1'>Address : {sales.warehouse_address.address1 ? sales.warehouse_address.address1 + ", " : ""}
                                                    {sales.warehouse_address.address2 ? sales.warehouse_address.address2 + ", " : ""}
                                                    {sales.warehouse_address.landmark ? sales.warehouse_address.landmark + ", " : ""}
                                                    {sales.warehouse_address.city ? sales.warehouse_address.city + ". " : "-"} <br />
                                                    Location : <b>{sales.warehouse_address.state_name}</b> <br />
                                                    GSTIN  : {sales.warehouse_address.gstin ? sales.warehouse_address.gstin : "-"} <br />
                                                </div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} >Customer</td>
                                        <td colSpan={2} >{sales.customer ? <div>{sales.customer.name} ({sales.customer.code}) <br />
                                            {(sales.customerAddress || sales.customerProject) &&
                                                <div className='py-1'>
                                                    Project : {sales.customerProject ? <span><b>{sales.customerProject.project_name} ({sales.customerProject.project_code}) </b></span> : "-"} <br />

                                                    {/* Address :  {sales.customerProject ? this.formatAddress(sales.customerProject ) : this.formatAddress(sales.customerAddress)}  */}
                                                </div>}

                                        </div> : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} ></td>

                                        <td>
                                            <div><b>Bill To Address</b></div>
                                            {sales.customerProject ? this.formatAddress(sales.customerProject.address) : sales.customer_billing_address ? this.formatAddress(sales.customer_billing_address) : ""}
                                            <br />
                                        </td>

                                        <td>
                                            <div><b>Ship To Address</b></div>
                                            {sales.customerProject ? this.formatAddress(sales.customerProject.address) : sales.customer_shipping_address ? this.formatAddress(sales.customer_shipping_address) : ""}                                            <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Priority</td>
                                        <td colSpan={2} className="text-capitalize">{sales.priority ?? "-"}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Payment Terms</td>
                                        <td colSpan={sales.payment_terms && sales.payment_terms == "net_days" ? 1 : 2} className="text-capitalize">{sales.payment_terms ? sales.payment_terms == "advance" ? "Advance" : sales.payment_terms == "net_days" ? "Net Days" : "On Receipt of Invoice" : "-"}</td>
                                        {sales.payment_terms && sales.payment_terms == "net_days" && <td scope="col" style={{ width: "33%" }}  >{sales.net_days ? sales.net_days : ""}</td>}
                                    </tr>
                                    <tr>
                                        <td scope="col">Delivery</td>
                                        <td colSpan={2}>{sales.delivery_date ? DateService.dateTimeFormat(sales.delivery_date, 'DD-MMM-YYYY') : ""}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">PO Number & Date</td>
                                        <td className="text-capitalize">{sales.po_number ? sales.po_number : "-"}</td>
                                        <td scope="col" style={{ width: "33%" }}  >{sales.po_date ? DateService.dateTimeFormat(sales.po_date, 'DD-MMM-YYYY') : ""}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Deal</td>
                                        <td scope="col" >{sales && sales.deal_data ? sales.deal_data.header : ""}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Tag Users</td>
                                        <td scope="col" colSpan={2}>{sales && sales.users && sales.users.length > 0 ? sales.users.map(u => <div>{u.full_name}</div>) : ""}</td>
                                    </tr>

                                    <tr>
                                        <td scope="col">Currency</td>
                                        <td colSpan={2}><b>{sales.currency}</b> {sales.currency !== sales.base_currency ? <span>(1 {sales?.currency} : <NumberFormatter number={sales?.conversion_rate} precision={2} /> {sales?.base_currency})</span> : null}</td>

                                    </tr>
                                    {sales.additional_attribute_data && sales.additional_attribute_data.length > 0
                                        ? sales.additional_attribute_data.map((attr) => {
                                            return (<tr key={attr.key}>
                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                <td scope="col" colSpan={2}>{attr.value_display ? attr.value_display : "-"}</td>
                                            </tr>)
                                        }) : null}
                                </tbody>
                            </table>
                            <div className="my-3">
                                <label className="form-check-label me-3">Show Item : </label>
                                <input
                                    type="radio"
                                    name="itemView"
                                    value="amount"
                                    checked={this.state.itemView === 'amount'}
                                    onChange={() => {
                                        this.setState({ itemView: 'amount' })
                                    }}
                                /> Amount
                                <input
                                    type="radio"
                                    name="itemView"
                                    value="quantity"
                                    checked={this.state.itemView === 'quantity'}
                                    onChange={() => {
                                        this.setState({ itemView: 'quantity' });
                                    }}
                                    className="ms-3"
                                /> Quantity
                            </div>
                            {this.state.itemView == "amount" ? <table className="table table-bordered table-responsive bg-white my-3 table-sm" id="poTable">
                                <thead className="table-secondary">
                                    <tr>
                                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={() => this.props.checkAllItem ? this.props.checkAllItem() : null} id="all_check_allocation" /></th>
                                        <th style={{ width: "39%" }}>Item Details</th>
                                        <th style={{ width: "12%" }} className="text-end">Quantity</th>
                                        <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                        <th style={{ width: "8%" }} className="text-end">Discount</th>
                                        <th style={{ width: "8%" }} className="text-end">Tax</th>
                                        <th style={{ width: "8%" }} className="text-end">Amount</th>
                                        {/* <th style={{ width: "8%" }} className="text-center">Pending</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sales && sales.items && sales.items.length > 0 ? sales.items?.map((i, k) => {
                                            return (<tr>
                                                <td className='text-center'>
                                                    {k + 1} <br /><br />
                                                    <input
                                                        type='checkbox'
                                                        name="foo"
                                                        value={i?.item_profile?.id}
                                                        data-pendingQty={i.pending_qty}
                                                        className="allocation_item_id_checkbox"
                                                        data-warehouseId={sales.warehouse_id}
                                                        data-purchaseQty={i?.purchase_qty ?? 0}
                                                        data-itemData={i?.item_profile}
                                                        data-transactionId={sales.transaction_id}
                                                        data-statusLevel={sales.status_data && sales.status_data.level}
                                                        data-statusIds={sales.status_data && sales.status_data.id}
                                                    />

                                                </td>

                                                <td>{i.item_profile ?
                                                    <div className='row'>
                                                        <div className='col-sm-10'>
                                                            <ItemViewInList view_hsn={true} item={i.item_profile} remark={i.remark} due_date={i.due_date_display} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                                        </div>

                                                    </div>
                                                    : null}</td>

                                                <td className='text-end'>{i.qty}
                                                    {i.item_profile.measuring_unit && i.item_profile.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item_profile?.measuring_unit?.name}</span> : null}

                                                </td>
                                                <td className='text-end'>{i.rate_vc}</td>
                                                <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? sales.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : 0}</td>
                                                <td className='text-end'>{sales && sales.warehouse_address && sales.warehouse_address.gstin ? <div>{i.gst} %</div> : "NA"}
                                                    {sales && sales.warehouse_address && sales.warehouse_address.gstin && i.item ? <span className="text-helping input-group-text text-end pr0">{sales.customerAddress && sales.customerAddress.state_name == sales.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                                </td>
                                                <td className='text-end'>
                                                    <CurrencyFormatter currencyCode={sales.currency} amount={parseFloat(i.item_with_discount).toFixed(2)} />
                                                </td>
                                            </tr>)

                                        }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                                : <table className="table table-bordered table-responsive bg-white my-3 table-sm" id="poTable">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th style={{ "width": '5%' }} className="text-center">
                                                S.No
                                            </th>
                                            <th style={{ width: "39%" }}>Item Details</th>
                                            <th style={{ width: "12%" }} className="text-end">Order Qty</th>
                                            <th style={{ width: "8%" }} className="text-end">Allocated</th>
                                            <th style={{ width: "8%" }} className="text-end">Invoiced</th>
                                            <th style={{ width: "8%" }} className="text-end">Cancelled</th>
                                            <th style={{ width: "8%" }} className="text-end">Pending</th>
                                            {/* <th style={{ width: "8%" }} className="text-center">Pending</th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sales && sales.items && sales.items.length > 0 ? sales.items?.map((i, k) => {
                                                return (<tr>
                                                    <td className='text-center'>
                                                        {k + 1}

                                                    </td>

                                                    <td>{i.item_profile ?
                                                        <div className='row'>
                                                            <div className='col-sm-10'>
                                                                <ItemViewInList view_hsn={true} item={i.item_profile} remark={i.remark} due_date={i.due_date_display} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                                            </div>

                                                        </div>
                                                        : null}</td>

                                                    <td className='text-end'>{i.qty ?? "0"}</td>
                                                    <td className='text-end'>{i.allocated_qty ?? "0"}</td>
                                                    <td className='text-end'>{i.invoiced_qty ?? "0"}</td>
                                                    <td className='text-end'>{i.cancelled_qty ?? "0"}</td>
                                                    <td className='text-end'>{i.pending_qty ?? "0"}</td>
                                                </tr>)

                                            }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                        }
                                    </tbody>
                                </table>}
                            <div className="row align-items-center mb-5">
                                <div className="col-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{sales.notes ?? "-"}</div>

                                </div>
                                <div className="col-6">
                                    <table className="table  table-borderless">
                                        <tbody>
                                            <tr className="subtotal_bg_color">
                                                <th>Sub-Total ({sales.currency ? sales.currency : "INR"})</th>
                                                <td className='float-end'>{sales && sales.items && sales.items.length > 0 && <CurrencyFormatter currencyCode={sales.currency} amount={sales.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />}</td>
                                            </tr>
                                            {sales && sales.tcs_rate_vc && sales.tcs_rate_vc > 0 ? <tr className="subtotal_bg_color">
                                                <th>TCS ({sales.tcs_type == "Fixed" ? sales.currency : null} {sales.tcs_rate_vc} {sales.tcs_type == "Percentage" ? "%" : null} )</th>
                                                <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>
                                            </tr> : null}
                                            {sales.tax_slabs && sales.tax_slabs.length > 0
                                                ? (sales.tax_slabs.map((ps, k) => {
                                                    return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                }))
                                                : null
                                            }

                                            <tr className="subtotal_bg_color">
                                                <th>Adjustment</th>
                                                <td className='float-end'>{sales.adjustment_amount_vc ?? 0}</td>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Final Total ({sales.currency ? sales.currency : "INR"})</th>
                                                <td className='float-end'>{sales.total_amount_vc ? <CurrencyFormatter currencyCode={sales.currency} amount={sales.total_amount_vc} /> : 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr/>
                            <div className="tab_content_wrapper mt-4">
                            <span className="content_heading">Document</span>
                            {
                            this.props.permissions.includes('sales_order_doc_add') &&
                                <span className="text-end dropdown">
                                <button type="button" onClick={() => this.handleDocumentModal()} className="btn btn-light">
                                <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPlus} />
                                </button>
                                </span>
                            }
                        </div>
                        <table className="table table-bordered bg-white my-2">
                            <tbody>
                            {
                                sales.docs && sales.docs.length > 0
                                ? sales.docs.map((d, i) => {
                                    return <tr key={i}>
                                        <td scope="col" style={{ width: "5%" }}>{i + 1}</td>
                                        <td  scope="col" style={{ width: "65%" }} className="text-capitalize">
                                                {d.name}
                                                {d.number && `(${d.number})`}
                                        </td>
                                        {d.longitude && d.latitue && (
                                            <td scope="col" style={{ width: "6%" }} className="text-center" >
                                            <Link to={`https://www.google.com/maps/search/${d.latitude},${d.longitude}`} target="_blank"><TapIcon.FontAwesomeIcon
                                                icon={TapIcon.faMapMarker}/>
                                            </Link>
                                            </td>
                                        )}
                                        <td scope="col" className="text-center" style={{ width: "6%" }} >
                                            <Link to={{pathname:  d.path }} target="_blank" 
                                            className={[this.props.permissions.includes('sales_order_doc_view') ? '' : 'disabled-link'].join(' ')}
                                            title={this.props.permissions.includes('sales_order_doc_view') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                            >
                                            <TapIcon.FontAwesomeIcon
                                                icon={TapIcon.faEye}
                                            />
                                            </Link>
                                        </td>
                                        <td scope="col" style={{ width: "6%" }} className="text-center">
                                            <Link to={{pathname : d.path}} target='_blank' 
                                            className={[this.props.permissions.includes('sales_order_doc_download') ? '' : 'disabled-link'].join(' ')}
                                            title={this.props.permissions.includes('sales_order_doc_download') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                            >
                                            <TapIcon.FontAwesomeIcon
                                                icon={TapIcon.faDownload}
                                            />
                                            </Link>
                                        </td>
                                        <td className="text-center" style={{ width: "6%" }}> 
                                            <Link 
                                            className={[this.props.permissions.includes('sales_order_doc_delete') ? '' : 'disabled-link'].join(' ')}
                                            style={{ "pointerEvents": "all" }}
                                            onClick={() => { this.props.permissions.includes('sales_order_doc_delete') && this.deleteSODocument(d.id)}}
                                            ><TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt}
                                            className="asset_link"/></Link>
                                        </td>
                                    </tr>
                                })
                                : <tr><td className='text-center' colSpan="6">No Document Found</td></tr>
                            }
                            </tbody>
                        </table>
                            <div className="tab_content_wrapper content_heading">Terms & Condition</div>
                            <table className="table table-hover table-bordered bg-white my-3">
                                <thead className='table-secondary'>
                                    <tr>
                                        <th className='text-center' style={{ width: "10%" }}>S.No</th>
                                        <th className='text-start' style={{ width: "25%" }}>Name</th>
                                        <th className='text-start' style={{ width: "65%" }}>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sales.tnc && sales.tnc.length > 0 ? sales.tnc.map((tc, k) => {
                                            return (<tr key={k}>
                                                <td className='text-center'>{k + 1}</td>
                                                <td>{tc.name}</td>
                                                <td>{tc.text}</td>
                                            </tr>);
                                        })
                                            : <tr ><td className='text-center' colSpan={3}>No Record</td></tr>
                                    }

                                </tbody>
                            </table>

                            <hr />

                        </div>
                        : null}
                </div>}
            <ItemDetailsModal ref={this.itemDetailsRef} />
        </Ax>)
    }


    render() {
        return (
            <div>
                {this.reqDetailJsx()}
                <SalesOrderDocumentModal ref={this.documentRef} salesOrderDetail={this.state.salesOrderDetail} getDetails={(id) => this.getQuotationDetail(id)}/>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency: state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SalesOrderDetail);