import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import TapApiUrls from "../../../services/TapApiUrls";
import { toast } from "react-toastify";
import moment from "moment";
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import UpdateMyDashboardForm from '../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm';

class TransactionDelay extends AppBaseComponent {


    constructor(props) {
        super(props);

        this.state                      =     {
            transaction_delay_widget_form           :   {period : 'current_month', filter_site_id : ''},
            transaction_delay_widget_loading        :   false,
            transaction_delay_widget_data           :   [],
            all_periods                             :   [],
            iam_user_sites                      :        [],
            assetStatusForm                    :   {filter_site_id : '',search_asset_type:''},
        }
        this.updateMyDashboardRef      =   React.createRef();
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('transactionDelayWidget')) {
            let delayWidgetData = JSON.parse(localStorage.getItem('transactionDelayWidget'));
            if (delayWidgetData) {
                let lastRefreshTime = moment(delayWidgetData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('delayWidgetData');
                    setTimeout(() => {
                        this.getDelayedTransactionDelayPeriod()
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 6));
                    
                } else {
                    this.setState({
                        transaction_delay_widget_data: delayWidgetData,
                    })
                }
            }else {
                setTimeout(() => {
                    this.getDelayedTransactionDelayPeriod()
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 6));
            }
        } else {
            setTimeout(() => {
                this.getDelayedTransactionDelayPeriod()
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 6));
        }
      
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites  || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
        this.setState({firebase_app_version : nextProps.firebase_app_version});
    }
    
    initilaizeFormFilter                =   (props)  =>  {
        this.setState({
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            all_periods             :   props.all_periods ? props.all_periods.map((s) => { return {value: s.key, label: `${s.display}` ,display_label : s.display_with_date}}) : [],
            all_warehouses          :   props.all_warehouses
                                                ? props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) 
                                                : [],
            iam_asset_types          :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
        },() => {
            if(props.dashboard_filter_site_id || props.clearDashboardFilter == "yes"){
                   
                this.setState({
                    transaction_delay_widget_form         :       {
                        filter_site_id          :       props.dashboard_filter_site_id,
                        period                  :       'current_month'
                    }
                },() => this.getDelayedTransactionDelayPeriod())
            }
        });
    }
    
    getDelayedTransactionDelayPeriod            =   ()  =>  {
        this.setState({transaction_delay_widget_loading: true});
        setTimeout(() => {
            if(this.props.group_info && this.props.group_info.group_id == 'nccltd') {
                setTimeout(() => {
                    this.getTransactionDelayPeriod();
                }, 10000);
            } else {
                this.getTransactionDelayPeriod();
            }
        }, 2000);
    }

    getTransactionDelayPeriod                 =   (reload=false)  =>  {
        this.setState({transaction_delay_widget_loading: true});
        let params          =   {...this.state.transaction_delay_widget_form , reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET',process.env.REACT_APP_IAM_API_SERVER + '/group/transaction_delay_data', this.props.access_token, params, {} , (response) => {
            this.setState({transaction_delay_widget_data : response.data})
            if(this.state.transaction_delay_widget_form.filter_site_id == '' && this.state.transaction_delay_widget_form.period == "current_month"){
                localStorage.setItem('transactionDelayWidget',JSON.stringify(response.data));
            }
            if(reload == true){
                localStorage.setItem('transactionDelayWidget',JSON.stringify(response.data));
            }
        }).then(() => {
            this.setState({transaction_delay_widget_loading: false});
        });
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"asset_transaction_delay",name:"Transaction Delay"})
    }

   
    render                      =   ()  =>  {
        let filterObj           =   {};
        // if(this.state.assetStatusForm){
            if(this.state.transaction_delay_widget_form.filter_site_id){
                filterObj           =   {...filterObj , 'search_site_ids' : this.state.transaction_delay_widget_form.filter_site_id}
            }
            if(this.state.transaction_delay_widget_form.period){
                filterObj           =   {...filterObj , 'entry_date_range' : this.state.transaction_delay_widget_form.period}
            }
        // }
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("asset_transaction_delay" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "asset_transaction_delay")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5"><h6 className="fw-bold primary_color">Transaction Delay</h6></div>
                    <div className="col-3">
                        <TapSelect
                            isClearable={false}
                            placeholder="Select Period"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.all_periods}
                            value={this.state.all_periods.find(r => r.value === this.state.transaction_delay_widget_form.period)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'transaction_delay_widget_form', 'period', () => this.getTransactionDelayPeriod())}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            isClearable={true}
                            isSearchable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.state.iam_user_sites}
                            value={this.state.iam_user_sites.find(r => r.value === this.state.transaction_delay_widget_form.filter_site_id)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'transaction_delay_widget_form', 'filter_site_id', () => this.getTransactionDelayPeriod())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.getTransactionDelayPeriod(true)}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }}>
                                <Link to="/configuration/transaction_delay" style={{ color: "black" }}>Change Setting</Link></li>
                                <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <table className="table table-hover table-bordered bg-white">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "30%" }}>Transaction Type</th>
                                    <th style={{ width: "15%" }}>Limit</th>
                                    <th style={{ width: "17%" }}>Total</th>
                                    <th style={{ width: "19%" }}>Within Limits</th>
                                    <th style={{ width: "19%" }}>Beyond Limits</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.transaction_delay_widget_loading
                                    ? <tr><td className="text-center" colSpan="3"><Loader /></td></tr>
                                    : (<Ax>
                                        {this.state.transaction_delay_widget_data?.data?.length > 0 && this.state.transaction_delay_widget_data?.data?.map((d, i) => {

                                            return (<tr key={i} className="text-center">
                                                <th className="text-start">{d.transaction_type}</th>
                                                <td>{d.delay}</td>
                                                <td>{d.transaction == "scs_transaction"
                                                    ? <Link target="_blank" to={{ pathname: "/service_checksheet_execution_history", search: "?" + new URLSearchParams({ ...filterObj }).toString() }}>{parseInt(d.with_in_limit) + parseInt(d.beyond_limit)}</Link>
                                                    : d.transaction == "fuel_feed_transaction"
                                                        ? <Link target="_blank" to={{ pathname: "/fuel_feedin_list", search: "?" + new URLSearchParams({ ...filterObj }).toString() }}>{parseInt(d.with_in_limit) + parseInt(d.beyond_limit)}</Link>
                                                        : d.transaction == "expense_transaction"
                                                            ? <Link target="_blank" to={{ pathname: "/expense_list", search: "?" + new URLSearchParams({ ...filterObj }).toString() }}>{parseInt(d.with_in_limit) + parseInt(d.beyond_limit)}</Link>
                                                            : d.transaction == "productivity_transaction"
                                                                ? <Link target="_blank" to={{ pathname: "/productivity_list", search: "?" + new URLSearchParams({ ...filterObj }).toString() }}>{parseInt(d.with_in_limit) + parseInt(d.beyond_limit)}</Link>
                                                                : d.transaction == "activity_transaction"
                                                                    ? <Link target="_blank" to={{ pathname: "/asset_activity_transaction", search: "?" + new URLSearchParams({ ...filterObj }).toString() }}>{parseInt(d.with_in_limit) + parseInt(d.beyond_limit)}</Link>
                                                                    : parseInt(d.with_in_limit) + parseInt(d.beyond_limit)}</td>
                                                <td>{d.transaction == "scs_transaction"
                                                    ? <Link target="_blank" to={{ pathname: "/service_checksheet_execution_history", search: "?" + new URLSearchParams({ ...filterObj, scs_transaction_delay: "withinlimit" }).toString() }}>{d.with_in_limit}</Link>
                                                    : d.transaction == "fuel_feed_transaction"
                                                        ? <Link target="_blank" to={{ pathname: "/fuel_feedin_list", search: "?" + new URLSearchParams({ ...filterObj, fuel_transaction_delay: "withinlimit" }).toString() }}>{d.with_in_limit}</Link>
                                                        : d.transaction == "expense_transaction"
                                                            ? <Link target="_blank" to={{ pathname: "/expense_list", search: "?" + new URLSearchParams({ ...filterObj, transaction_delay: "withinlimit" }).toString() }}>{d.with_in_limit}</Link>
                                                            : d.transaction == "productivity_transaction"
                                                                ? <Link target="_blank" to={{ pathname: "/productivity_list", search: "?" + new URLSearchParams({ ...filterObj, activity_transaction_delay: "withinlimit" }).toString() }}>{d.with_in_limit}</Link>
                                                                : d.transaction == "activity_transaction"
                                                                    ? <Link target="_blank" to={{ pathname: "/asset_activity_transaction", search: "?" + new URLSearchParams({ ...filterObj, activity_transaction_delay: "withinlimit" }).toString() }}>{d.with_in_limit}</Link>
                                                                    : d.with_in_limit}</td>
                                                <td>{d.transaction == "scs_transaction"
                                                    ? <Link target="_blank" to={{ pathname: "/service_checksheet_execution_history", search: "?" + new URLSearchParams({ ...filterObj, scs_transaction_delay: "beyondlimit" }).toString() }}>{d.beyond_limit}</Link>
                                                    : d.transaction == "fuel_feed_transaction"
                                                        ? <Link target="_blank" to={{ pathname: "/fuel_feedin_list", search: "?" + new URLSearchParams({ ...filterObj, fuel_transaction_delay: "beyondlimit" }).toString() }}>{d.beyond_limit}</Link>
                                                        : d.transaction == "expense_transaction"
                                                            ? <Link target="_blank" to={{ pathname: "/expense_list", search: "?" + new URLSearchParams({ ...filterObj, transaction_delay: "beyondlimit" }).toString() }}>{d.beyond_limit}</Link>
                                                            : d.transaction == "productivity_transaction"
                                                                ? <Link target="_blank" to={{ pathname: "/productivity_list", search: "?" + new URLSearchParams({ ...filterObj, activity_transaction_delay: "beyondlimit" }).toString() }}>{d.beyond_limit}</Link>
                                                                : d.transaction == "activity_transaction"
                                                                    ? <Link target="_blank" to={{ pathname: "/asset_activity_transaction", search: "?" + new URLSearchParams({ ...filterObj, activity_transaction_delay: "beyondlimit" }).toString() }}>{d.beyond_limit}</Link>
                                                                    : d.beyond_limit}</td>

                                            </tr>)
                                        })
                                        }
                                    </Ax>)
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-6">
                        {this.state.transaction_delay_widget_loading
                            ? <Loader />
                            : (<Chart
                                width={'100%'}
                                height={'100%'}
                                chartType="BarChart"
                                data={[['Transaction Type', 'Within Limit', 'Beyond Limit']].concat(this.state.transaction_delay_widget_data?.data?.map((d) => { return [d.transaction_type, parseInt(d.with_in_limit), parseInt(d.beyond_limit)]; }))}
                                options={{
                                    legend: { position: 'top' },
                                    colors: ['#2A974E', '#EC4E4E'],
                                    isStacked: true,
                                    chartArea: { width: '100%', left: '70', height: '80%' }
                                }}
                                rootProps={{ 'data-testid': '1.5' }}
                            />)}
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                        Report as of {this.state.transaction_delay_widget_data.time ?? "-"}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
        firebase_app_version     :   state.app && state.app.firebase_app_version ? state.app.firebase_app_version : 1,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        group_info              :   state.app.group_info ? state.app.group_info : null,
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};

export default connect(mapStateToProps)(TransactionDelay);