import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { Link } from 'react-router-dom';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class POQtyDetail extends React.Component {
    
    constructor(props) {
        super(props);

        this.state                  =   {
            poDataLoading               :   false,
            poList                      :   []
        };

        this.poItemUsedReqUrl        =   INVENTORY_API_BASE_URL_2 + '/purchase_order_item_used_requisition_item';
    }
    
    modalInit                       =   (req_item_id)  =>  {
        this.setState({poDataLoading : true, poList : []});
        let params                      =   {req_item_id : req_item_id}
        HttpAPICall.withAthorization('GET', this.poItemUsedReqUrl, this.props.access_token, params, null, (response) => {
            this.setState({poList : response.data});
        }, (err) => {
        }).then(() => {
            this.setState({poDataLoading : false});
        });
        this.poQtyDetailModal.show();
    }
    
    componentDidMount(){
        this.poQtyDetailModal                  =        new Modal(document.getElementById('poQtyDetailModal'), {keyboard: false, backdrop :false});
    }
    
    //*********************************ITEM ADD JSX*********************** */
    poQtyDetailModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="poQtyDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="poQtyDetailModalLabel">PO Detail</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>

                        <div className="modal-body ">
                            {this.state.poDataLoading 
                                ? <Loader />
                                :   <table className="table table-bordered  bg-white ">
                                        <thead className="table-secondary">
                                            <tr className="text-end">
                                                <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                <th scope="col" style={{ width: "12%" }} className="text-center">PO Number</th>
                                                <th scope="col" style={{ width: "10%" }} className="text-center">PO Date</th>
                                                <th scope="col" style={{ width: "20%" }} className="text-start" >Vendor Name</th>
                                                <th scope="col" style={{ width: "10%" }}>Total Qty</th>
                                                <th scope="col" style={{ width: "15%" }}>Allocated Qty</th>
                                                <th scope="col" style={{ width: "13%" }}>Unit Rate</th>
                                                <th scope="col" style={{ width: "10%" }} className='text-center'>Status</th>
                                                <th scope="col" style={{ width: "5%" }} className="text-center">Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.poList.length > 0
                                            ? (this.state.poList.map((po_item, k) => {
                                                let rate = po_item.rate;
                                                let percentage = po_item.discount_rate / 100;
                                                let totalDiscountedValue = rate - (rate * percentage)
                                                let item_rate =  po_item.discount_type == "Percentage" ? totalDiscountedValue  : po_item.rate - po_item.discount_rate;
                                                return <tr key={k} className='text-end'>
                                                    <td className='text-center'>{k+1}</td>
                                                    <td className='text-center'>{po_item.purchase_order.transaction_id}</td>
                                                    <td className='text-center'>{po_item.purchase_order.transaction_date_display}</td>
                                                    <td className='text-start'>{po_item.purchase_order && po_item.purchase_order.vendor ? po_item.purchase_order.vendor.name : ''}</td>
                                                    <td>{po_item.qty}</td>
                                                    <td>{po_item.req_allocation_detail ? po_item.req_allocation_detail.qty : ''}</td>
                                                    <td>{po_item.currency} {item_rate}</td>
                                                    <td className='text-center'>{po_item.purchase_order.status_data ? po_item.purchase_order.status_data.name : '-'}</td>
                                                    <td className='text-center link-primary'>
                                                    <Link target="_blank" to={{pathname: "/purchase_order_list" , search: "?" + new URLSearchParams({id : po_item.purchase_order.transaction_id}).toString() }}> View </Link>
                                                        {/* <Link  target="_blank" to={{ pathname: `/purchase_order_list`, state: { poId: po_item.purchase_order.transaction_id } }}>View</Link> */}
                                                    </td>
                                                </tr>;
                                            }))
                                            : <tr className='text-end'><td colSpan="6">No recods</td></tr>
                                        }
                                        </tbody>
                                    </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render() {
        return (<Ax>{this.poQtyDetailModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(POQtyDetail);

//export default POQtyDetail;
//export default connect(mapStateToProps)(POQtyDetail);