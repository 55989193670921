import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination } from '../../../components/TapUi';
import RequisitionDetailModal from './RequisitionDetailModal';

class ReqHistoryItemWiseModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                            =         {
            reqDetailList                      :         [],
            reqDetailLoading                   :         false,
            itemName                          :         '',
            itemData                          :         null,
            warehouse_id                    :         null,
            listing_tbl_page                  :          1,
            listingMeta                       :          null,
            totalListingCount                 :         0,
            indent_transaction_id              :         ''
        };
        this.ReqDetailModalRef              =   React.createRef();
        this.requisitionReportUrl       =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-requisition';
    }   
    
    componentDidMount(){
        this.viewReqHistoryModal             =   new Modal(document.getElementById('viewReqHistoryModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (data,warehouse_id,indent_transaction_id='')  =>  {
        this.viewReqHistoryModal.show()
            if(data){
                  this.setState({
                    itemData            :        data,
                    warehouse_id        :        warehouse_id ? warehouse_id : null,
                    indent_transaction_id    :       indent_transaction_id,
                  },() => this.fetchReqHistoryData())
            }
    }

    fetchReqHistoryData     =       (page = 1)      =>      {
        this.setState({reqDetailLoading : true, reqDetailList : [],listing_tbl_page: page});
        let params =  {
                    search_warehouse_ids        :    this.state.warehouse_id !== undefined && this.state.warehouse_id !== null ? [this.state.warehouse_id] : [],
                    search_item_ids             :    this.state.itemData ? [this.state.itemData.id] : null,
                    indent_transaction_id        :     this.state.indent_transaction_id,
                    page                        :    page}
                    
        HttpAPICall.withAthorization('GET', this.requisitionReportUrl, this.props.access_token, {...params}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                reqDetailList          :    respData.data,
                itemName              :    this.state.itemData ? this.state.itemData.name : '',
                listingMeta           :     respData,
                totalListingCount     :     respData.total,
            },()=>  this.viewReqHistoryModal.show())
        
        }).then(() => {
            this.setState({reqDetailLoading : false});
        });
    }

    reqDetailModalInit          =       (req_item_id)           =>      {
        this.ReqDetailModalRef.current.modalReqInit(req_item_id);  
    }

    reqHistoryModalJsx                     =   ()  =>  {

          return (
              <div className="modal fade" id="viewReqHistoryModal" tabIndex="-1">
                  <div className="modal-dialog modal-lg modal-dialog-scrollable">
                      <div className="modal-content  ">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addItemModalLabel">Item wise Purchase Requisition History</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className='p-1 bg-light border-bottom'>
                              <table className='table table-sm mb-0 table-borderless text-sm'>
                                  <tbody>
                                      {this.state.warehouse_id && <tr>
                                          <td style={{ width: "15%" }}> Warehouse  </td>
                                          <th>{this.state.reqDetailList && this.state.reqDetailList.length > 0 && this.state.reqDetailList[0]?.warehouse_name ? `${this.state.reqDetailList[0]?.warehouse_name}` : "-"}</th>
                                      </tr>}
                                      {this.state.indent_transaction_id && <tr>
                                          <td style={{ width: "15%" }}> Indent ID  </td>
                                          <th>{this.state.indent_transaction_id ? this.state.indent_transaction_id : ""}</th>
                                      </tr>}
                                      <tr>
                                          <td style={{ width: "15%" }}> Item  </td>
                                          <th>{this.state.itemName  ?this.state.itemName : "-"}</th>
                                      </tr>

                                  </tbody>
                              </table>
                          </div>
                          <div className="modal-body ">
                              {!this.state.reqDetailLoading ?
                                  <table className="table table-bordered  bg-white ">
                                      <thead className="table-secondary">
                                          <tr className="text-end">
                                              <th scope="col" style={{ width: "12%" }} className="text-start">Req ID</th>
                                              <th scope="col" style={{ width: "15%" }} className="text-center">Transaction Date</th>
                                              <th scope="col" style={{ width: "25%" }} className="text-center">Warehouse</th>
                                              <th scope="col" style={{ width: "10%" }}>Req Qty</th>
                                              <th scope="col" style={{ width: "12%" }}>Pending Qty</th>
                                              <th scope="col" style={{ width: "10%" }} className='text-center'>Status</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {this.state.reqDetailList.length > 0
                                              ? (this.state.reqDetailList.map((rowData, k) => {
                                                //   return rowData.items.map((rq, rk) => {
                                                      return (<tr key={k}>
                                                          <td><a hred="#" role="button" className='link-primary' onClick={() => this.reqDetailModalInit(rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                                          <td className='text-center'>{rowData.transaction_date_display}</td>
                                                          <td>{rowData.warehouse_name}</td>
                                                          <td className='text-end'>{rowData.qty}</td>
                                                          <td className='text-end'>{rowData.pending_qty}</td>
                                                          <td className='text-center'>{rowData.status && rowData.status.name ? rowData.status.name :  "-"}</td>
                                                          
                                                      </tr>);
                                                //   })
                                              }))
                                              : (<tr><td className="text-center" colSpan={5}>No Record Found</td></tr>)
                                          }
                                      </tbody>
                                  </table>
                                  : <Loader />}
                              <DisplayListPagination
                                  meta={this.state.listingMeta}
                                  onPageChange={(e) => this.fetchReqHistoryData(e.selected + 1)}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          );
    }

    render() {
        return (<Ax>
            {this.reqHistoryModalJsx()}
            <RequisitionDetailModal parentProps={this.props} ref={this.ReqDetailModalRef}/>
        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReqHistoryItemWiseModal);
