import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import PurchaseDetail from '../../inventory/purchase/PurchaseDetail';
import { toast } from 'react-toastify';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemWisePurchase extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_vendor_ids                   :   [],
            search_status_ids                   :   [],
            search_manufacturer_ids             :   [],
            search_transaction_id               :   '',
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_purchase_bill_number         :   '',
            search_purchase_order_number        :   '',
            search_e_way_bill_number            :   '',
        };
        
        this.reportFilterFormLabel          =   {
            search_warehouse_ids                :   'Warehouse : ',
            search_item_ids                     :   'Items : ',
            search_transaction_id               :   'Transaction ID : ',
            search_item_tags                    :   'Item Tags : ',
            search_vendor_ids                   :   'Vendor : ',
            search_status_ids                   :   'Status : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            search_purchase_bill_number         :   'Bill Number : ',
            search_purchase_order_number        :   'PO Bill Number : ',
            search_e_way_bill_number            :   'Eway Bill Number : ',
            date_range                          :   'Date Range : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null,
            allItemTags                         :   [],
            searchedVendor                      :   [],
            manufactures                        :   [],
            allStatusOptions                    :   [],
            transactionId                       :   "",
            transanctionType                    :   "",
            download_access                     :   false,  
            reportData                          :   null,                   
        };

        this.formDataUrl    =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase/form_data';
        this.reportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase';

    }
    
    componentDidMount(){
        this.initializeData(this.props)
        this.viewTransactionModal              =     new Modal(document.getElementById('viewTransactionModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
         }     
    }
    
    initializeData     =      (pr)      =>      {

        const report_name   =   'item_wise_purchase';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        
        let url             =         process.env.REACT_APP_INVENTORY_API_SERVER + '/report/item-wise-purchase/form_data';
        let all_periods     =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;

        HttpAPICall.withAthorization('GET', this.formDataUrl, pr.access_token, null, null, (response) => {
          
            this.setState({
                download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                formDataLoaded              :   true,
                all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                all_periods                 :   all_periods ,
                allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                manufactures                :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                  
            }, () => {
                let ParamObject                 =   new URLSearchParams(this.props.location.search);
                if (ParamObject.get('transaction_period_range_start') || ParamObject.get('search_item_ids') || ParamObject.get('search_vendor_ids')) {
                    let updateFilterForm        =        {};
                    if (ParamObject.get('transaction_period_range_start')) {
                        updateFilterForm         =      {
                            date_range_start        :       ParamObject.get('transaction_period_range_start'),
                            date_range_end          :        ParamObject.get('transaction_period_range_end'),
                            date_range              :       "custom"
                        };


                        if (ParamObject.get('search_warehouse_ids')) {
                            let warehouse           =       this.state.all_warehouses.find(us => us.value == ParamObject.get('search_warehouse_ids'));

                            if (warehouse) {
                                updateFilterForm['search_warehouse_ids']    =       [warehouse.value];

                            }
                        }
                    }
                    if (ParamObject.get('search_item_ids')) {    
                        updateFilterForm['search_item_ids']          =          [ParamObject.get('search_item_ids')];   
                    }

                    if (ParamObject.get('search_vendor_ids')) {    
                        updateFilterForm['search_vendor_ids']          =          [ParamObject.get('search_vendor_ids')]; 
                          
                    }


                    setTimeout(() => {
                        this.setState({
                            reportFilterForm               :        { ...this.state.reportFilterForm, ...updateFilterForm },
                            searchedVendor                 :       ParamObject.get('search_vendor_ids') ? [{value : ParamObject.get('search_vendor_ids'),display_label : ParamObject.get('vendor_name') }] : null,
                            searchedItem                  :        [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }]
                        }, () => {
                            this.reportFilterFormSubmit()
                        })
                    }, 1000)
                } else {
                    this.reportFilterhandler()
                }

            });
            
        }, (err) => {

        });
        
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    
                    if(key == 'search_vendor_ids') {
                        show_val            =   this.state.searchedVendor ? this.state.searchedVendor.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_transaction_id : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_purchase_bill_number') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_purchase_bill_number : '';
                    }
                    if(key == 'search_purchase_order_number') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_purchase_order_number : '';
                    }
                    if(key == 'search_e_way_bill_number') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_e_way_bill_number : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            =   this.state.manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    viewTransactionModalInit                =   (transaction_type,transaction_id)       =>      {
        this.setState({transanctionType : transaction_type,transactionId : transaction_id},
         () => this.viewTransactionModal.show())
    }

    checkAllList() {
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox')
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                purchaseItemCollections[i].checked = document.getElementById('all_check_purchase').checked;
            }
        }
    }

    addToConsumptionHandler                  =   ()  =>  {
    
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
        let item_ids            =       [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                     item_ids.push(purchaseItemCollections[i].getAttribute("data-itemId"));
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.listingData?.find(data => data.transaction_id == purchaseItemCollections[i].value);
                    
                    // let getSelectedPurchaseItem = [];
                    // getSelectedPurchaseItem = selectedPurchaseData?.stock_items?.filter(item => item.item_id == purchaseItemCollections[i].getAttribute("data-itemId"));
                    purchase_item_ids.push(selectedPurchaseData)
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else {
            let allSelectedItem         =    purchase_item_ids.flat();
            const key = 'item_id';

           const uniquItemIdsList      =      [...new Map(allSelectedItem.map(item => [item[key], item])).values()];

            let purchaseStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   uniquItemIdsList.map(v => ({...v, name: v.item_name,id:v.item_id,category: v.item_category}))
            };
           
           this.props.history.push({ pathname: `/consumption_list/add`,  state: { addConsumptionFromPurchaseReport : purchaseStateData } });
        }
    }

    addToInventoryTransferHandler                  =   ()  =>  {
    
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
        let item_ids            =       [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                     item_ids.push(purchaseItemCollections[i].getAttribute("data-itemId"));
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.listingData?.find(data => data.transaction_id == purchaseItemCollections[i].value);
                    
                    // let getSelectedPurchaseItem = [];
                    // getSelectedPurchaseItem = selectedPurchaseData?.stock_items?.filter(item => item.item_id == purchaseItemCollections[i].getAttribute("data-itemId"));
                    purchase_item_ids.push(selectedPurchaseData)
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else {
            let allSelectedItem         =    purchase_item_ids.flat();
            const key = 'item_id';

           const uniquItemIdsList      =      [...new Map(allSelectedItem.map(item => [item[key], item])).values()];

            let purchaseStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   uniquItemIdsList.map(v => ({...v, name: v.item_name,id:v.item_id,category: v.item_category}))
            };
           
           this.props.history.push({ pathname: `/inventory_transfer_list/add`,  state: { addInventoryFromPurchaseReport : purchaseStateData } });
        }
    }
  
    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    

    reportDownloadApiCall                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(null,response.data.navgation,response.data.msg,this.props)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? 
                    <Ax>
                        <button disabled={this.state.reportDownloading || !this.state.download_access} type="button" onClick={this.reportDownloadApiCall} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>
                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a role="button" href
                                        onClick={() => {
                                            if (this.props.permissions.includes('isc-consumption-add')) {
                                                return this.addToConsumptionHandler()
                                            }
                                        }}
                                        className={['dropdown-item',this.props.permissions.includes('isc-consumption-add') ? '' : 'disabled'].join(' ')}
                                        title={!this.props.permissions.includes('isc-consumption-add') ? 'This action cannot be performed' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Add to Consumption</a>
                                </li>
                                <li>
                                    <a role="button" href
                                        onClick={() => {
                                            if (this.props.permissions.includes('isc-inventory_transfer-add')) {
                                                return this.addToInventoryTransferHandler()
                                            }
                                        }}
                                        className={['dropdown-item',this.props.permissions.includes('isc-inventory_transfer-add') ? '' : 'disabled'].join(' ')}
                                        title={!this.props.permissions.includes('isc-inventory_transfer-add') ? 'This action cannot be performed' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Add to Inventory Transfer</a>
                                </li>
                               
                            </ul>
                      
                    </Ax> : null}
                 
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.all_warehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Vendor</label>
                    <div className="col-sm-6">
                        <InputVendorSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_vendor_ids');
                                this.setState({ searchedVendor: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedVendor && this.state.searchedVendor.length > 0 ? this.state.searchedVendor.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Please enter Transaction ID"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Bill Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_purchase_bill_number"
                            type="text"
                            value={this.state.reportFilterForm.search_purchase_bill_number}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Enter Bill Number"
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Eway Bill Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_e_way_bill_number"
                            type="text"
                            value={this.state.reportFilterForm.search_e_way_bill_number}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Enter Eway Number"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">PO Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_purchase_order_number"
                            type="text"
                            value={this.state.reportFilterForm.search_purchase_order_number}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Enter PO Number"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods              =       {this.state.all_periods ? this.state.all_periods : []}
                            value                =       {this.state.reportFilterForm.date_range}
                            startDate            =       {this.state.reportFilterForm.date_range_start}
                            endDate              =       {this.state.reportFilterForm.date_range_end}
                            required             =       {true}
                            onSelectPeriod       =       {(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range          :       period,
                                        date_range_start    :       startDate ? startDate : null,
                                        date_range_end      :       endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr className="text-center" >
                        <th scope="col" style={{ width: "5%" }}><input type='checkbox' id="all_check_purchase" onChange={this.checkAllList} /></th>
                        <th style={{ width: "14%" }}>Item Name</th>
                        <th  style={{ width: "13%" }}>Item Code</th>
                        <th  style={{ width: "8%" }}>Transaction ID</th>
                        <th style={{ width: "8%" }}>Date</th>
                        <th  style={{ width: "13%" }}>Warehouse</th>
                        <th  style={{ width: "13%" }}>Vendor</th>
                        <th scope="col" style={{ width: "8%" }}> Status</th>
                        <th scope="col" style={{ width: "6%" }} className='text-end' > Qty</th>
                        <th scope="col" style={{ width: "6%" }} className='text-end' >Currency</th>
                        <th scope="col" style={{ width: "8%" }} className='text-end'> Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((rowData, k) => {
                            // return rowData.stock_items.map((pr, prkey) => {
                                return (<tr key={k}>
                                      <td className="text-center">
                                         <input type='checkbox'
                                            className="purchase_item_id_checkbox"
                                             name="purchase"
                                            value={rowData.transaction_id}
                                            data-warehouseId={rowData.warehouse_id}
                                            data-itemId={rowData.item_id}
                                            disabled={rowData.transaction_type == "purchase_consumption" ? true : false}
                                        />
                                        </td>
                                    <td>{rowData.item_name}</td>
                                    <td>{rowData.item_code}</td>
                                    <td className="text-center"><a hred="#" role="button" className='link-primary' onClick={() => this.viewTransactionModalInit(rowData.transaction_type, rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                    <td className="text-center">{rowData.transaction_date_display}</td>
                                    <td>{rowData.warehouse_name}</td>
                                    <td className='text-capitalize'>{rowData.vendor_name}</td>
                                    <td className='text-center'>{rowData.status && rowData.status.name ? rowData.status.name : "-" }</td>
                                    <td className='text-end'>{rowData.qty}</td>
                                    <td className='text-center'>{rowData.currency ? rowData.currency : "INR"}</td>
                                    <td className='text-end'>{rowData.amount ? rowData.amount : "0"}</td>
                                </tr>);
                            // })
                        }))
                        : (<tr><td className="text-center" colSpan={10}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }

    transactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Transaction Id : {this.state.transactionId ? this.state.transactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                          <PurchaseDetail purchaseId={this.state.transactionId}/>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
                {this.transactionDetailsModalJsx()}
            </div>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ItemWisePurchase);