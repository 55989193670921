import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import HttpAPICall from "../../../../services/HttpAPICall";
import Ax from "../../../../components/hoc/Ax";
import {Link} from "react-router-dom";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon";
import {toast, ToastContainer} from "react-toastify";
import {Modal} from "bootstrap";
import TapSelect from "../../../../components/ui/TapSelect";
import Status from "../../../../components/ui/Status";
import TapApiUrls from '../../../../services/TapApiUrls';

class CounterlogView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                  =        {
            counterlog_loading                 :   false,
            counterlog_list                    :   [],
        }
    }

    componentDidMount() {
        this.getConfigurationListing();
    }

    getConfigurationListing        =   ()  =>  {
        this.setState({counterlog_loading : true},() => {
            
            HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/logsheet_screen', this.props.access_token, {} , {}, (response) => {
                this.setState({
                    counterlog_list     :    response.data && response.data.length > 0 ? response.data.map(column => {return({...column,selected: column.status === 1})}): []
                });
            }).then(() => {
                this.setState({counterlog_loading: false});
            });
        });
    }

    handleCheckboxChange = (key) => {
        this.setState((prevState) => ({
            counterlog_list: prevState.counterlog_list.map((col) =>
                col.key === key ? { ...col, selected: !col.selected } : col
            ),
        }));
    };

    handleWidthInputChange = (key, event) => {
        event.persist(); // Ensure the event is not nullified

        this.setState((prevState) => ({
            counterlog_list: prevState.counterlog_list.map((col) =>
                col.key === key ? { ...col, width: event.target.value } : col
            ),
        }));
    };
    

    saveCounterlogViewForm      =       (e)          =>      {
        e.preventDefault();
        
        let totalWidth = this.state.counterlog_list.filter((col) => col.selected).reduce((acc, col) => acc + parseInt(col.width || 0), 0);

        if (totalWidth !== 100) {
            toast.error('Total width must be 100', {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        let formData = this.state.counterlog_list.filter((col) => col.selected && col.can_edit == 'Y').map((col) => ({key: col.key, width: col.width}));
        this.setState({saveFormSubmitting : true});
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/counterlog/logsheet_screen', this.props.access_token,{}, {data : formData}  , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.getConfigurationListing();
        }).then(() => this.setState({saveFormSubmitting: false}));
    }

    render() {
        return (
            <Ax>
                {this.state.listing_loading ? <div className='text-center'><Loader /></div>
                    : <form onSubmit={this.saveCounterlogViewForm}>

                        <table className="table table-bordered table-hover table-sm bg-white my-2">
                            <thead className="table-secondary">
                                <tr>
                                     <th scope="col"  style={{ width: "5%" }} className='text-center' >S.No</th>
                                    <th scope="col">Column Name</th>
                                    <th style={{ width: "20%" }} className='text-center'>Visibility</th>
                                    <th style={{ width: "20%" }}>Width Percentage</th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.counterlog_list && this.state.counterlog_list.length > 0 && this.state.counterlog_list.map((section, index) => {
                                    return (
                                        <tr>
                                            <td className='text-center'>{index + 1}</td>
                                            <td>{section.name}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    value={section.key}
                                                    disabled={
                                                        section.can_edit === 'N'
                                                    }
                                                    onChange={() => this.handleCheckboxChange(section.key)}
                                                    checked={section.selected || false}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className='form-control form-control-sm'
                                                    disabled={
                                                        section.can_edit === 'N' ||
                                                        !section.selected
                                                    }
                                                    onChange={(e) =>
                                                        this.handleWidthInputChange(section.key, e)
                                                    }
                                                    value={section.width || ''}
                                                />
                                            </td>

                                        </tr>
                                    )
                                })
                                }
                                <tr>
                                    <td></td>
                                    <th>Total</th>
                                    <td></td>
                                    <th>{this.state.counterlog_list.filter((section) => section.selected).reduce((acc, section) => acc + (Number(section.width) || 0), 0)}</th>
                                </tr>
                            </tbody>
                        </table>
                        <div className="float-end">
                            <button type="submit" disabled={this.state.saveFormSubmitting || !this.props.permissions.includes('iam-counterlog-configuration-manage-column') ? true : false} className="btn btn-primary mx-2">
                                Save  {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>

                        </div>

                    </form>}
           </Ax>                
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    }
}

export default connect(mapStateToProps)(CounterlogView);
