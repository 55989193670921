import React from 'react';

const Button = ({ type = 'button', onClick, children, className,disabled = false,hideBtn = false }) => {
    return (
        hideBtn == false ?
            <button type={type} onClick={onClick} className={className} disabled={disabled}>
                {children}
            </button> : null
    );
  };

  export default Button;