import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import iamRoutes from '../routes/iamRoutes';
import publicRoutes from '../routes/publicRoutes';
import PageNotFound from '../pages/PageNotFound';
import AppInitializeScreen from '../pages/AppInitializeScreen/AppInitializeScreen';
import Ax from '../components/hoc/Ax';
import Login from '../pages/auth/Login';
import LogOut from '../pages/auth/LogOut';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from "firebase/database";
import MaintenanceMode from './MaintenanceMode';

class App extends React.Component {

    firebaseConfig = {
        apiKey: "AIzaSyB8d6OHUCQhzKNgI6TTCXygdEYxfxj8tOk",
        authDomain: "tappetbox-iam.firebaseapp.com",
        databaseURL: "https://tappetbox-iam-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "tappetbox-iam",
        storageBucket: "tappetbox-iam.appspot.com",
        messagingSenderId: "583441746645",
        appId: "1:583441746645:web:8c5570c2567f929de0f05c",
        measurementId: "G-87KPED2SEG"
    };

    firebaseInit = () => {
        const systemRef = ref(getDatabase(initializeApp(this.firebaseConfig)), 'system');
        onValue(systemRef, (snapshot) => {
            let fbData = snapshot.val();
            this.props.setFireBaseSystemConfig(fbData);
        });
    }

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        if (process.env.REACT_APP_ENV == 'production') {
            this.firebaseInit();
        }

        this.props.onTryAutoSignIn();
    }

    render = () => {
        let tbRoutes = publicRoutes;
        if (this.props.isAppInitialized && this.props.isAuthenticated) {
            tbRoutes = tbRoutes.concat(iamRoutes);
        }

        if (process.env.REACT_APP_ENV == 'production' && this.props.addData.firebase_maintenance_mode === 'Y') {
            return <MaintenanceMode />;
        } else if (this.props.isAuthenticated && !this.props.isAppInitialized) {
            return <AppInitializeScreen />;
        } else {
            return <Router>
                <Switch>
                    <Route path={'/logout'} exact={true} component={LogOut} />
                    {tbRoutes.map((prop, key) => <Route path={prop.path} exact={prop.exact} key={key} component={prop.component} />)}
                    <Route path='*' component={this.props.isAuthenticated ? PageNotFound : Login} />
                </Switch>
            </Router>
        }
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.access_token !== null,
        isAppInitialized: state.app.user_info ? true : false,
        addData: state.app ? state.app : null,
        permissions: state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignIn: () => dispatch(actions.authCheckState()),
        setFireBaseSystemConfig: (data) => dispatch(actions.fireBaseSystemConfigUpdate(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);