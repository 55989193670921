import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import AppBaseComponent from '../../../components/AppBaseComponent';
import tapIcon from "../../../services/TapIcon";
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from 'react-toastify';
import TapApiUrls from '../../../services/TapApiUrls';
import { Link } from 'react-router-dom';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';

class VendorDetailModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

    
        this.state                  =         {
           vendorDetail             :       null,
           details_loading          :       false
        };
    }
    
    
    
    componentDidMount(){
        this.viewVendorDetailModal             =   new Modal(document.getElementById('viewVendorDetailModal'), {keyboard: false, backdrop :false});
    }

    modalInit                       =   (vendor_id)  =>  {
        this.viewVendorDetailModal.show()
            if(vendor_id){
                this.getVendorDetails(vendor_id)
            }
    }

    getVendorDetails        =       (id)        =>      {
        this.setState({details_loading : true})
        HttpAPICall.withAthorization('GET', TapApiUrls.VENDOR_URL + '/' + id, this.props.access_token, null, null, (response) => {
            this.setState({
                vendorDetail       :    response.data.data,  
            });
        }).then(() => this.setState({details_loading: false}))
    }
    
      vendorDetailModalJsx                     =   ()  =>  {
        let vendorDetails       =   this.state.vendorDetail;
          return (
              <div className="modal fade" id="viewVendorDetailModal" tabIndex="-1">
                  <div className="modal-dialog modal-lg modal-dialog-scrollable">
                      <div className="modal-content  ">
                          <div className="modal-header">
                              <h5 className="modal-title" id="addItemModalLabel">Vendor Detail</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>

                          <div className="modal-body ">
                              {this.state.details_loading ? <Loader />
                                  : (<div className="tab_content_header">
                                      {this.state.vendorDetail && <Ax>
                                          <div className="tab_content_wrapper">
                                              <span className="content_heading">Details</span>
                                          </div>
                                          <table className="table table-hover table-borderless">
                                              <tbody>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>Vendor Name</td>
                                                      <th style={{ "width": "66%" }}>{vendorDetails.name ? vendorDetails.name : "-"}</th>
                                                  </tr>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>Code</td>
                                                      <th style={{ "width": "66%" }}>{vendorDetails.code ? vendorDetails.code : "-"}</th>
                                                  </tr>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>Status</td>
                                                      <th style={{ "width": "66%" }}>{vendorDetails.status ? <Status color={vendorDetails.status_color}>{vendorDetails.status_text}</Status> : "-"}</th>
                                                  </tr>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>Notes</td>
                                                      <th style={{ "width": "66%" }}>{vendorDetails.notes ? vendorDetails.notes : "-"}</th>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          <div className="tab_content_wrapper align-items-center">
                                              <span className="content_heading">Contact Details </span>
                                          </div>
                                          <table className="table table-hover table-bordered my-3 table-sm">
                                              <thead className="table-secondary" >
                                                  <tr>
                                                      <th style={{ width: "5%" }} className='text-center'>S.No</th>
                                                      <th style={{ width: "25%" }}>Name</th>
                                                      <th style={{ width: "30%" }}>Email ID</th>
                                                      <th style={{ width: "20%" }} className='text-center'>Mobile Number</th>
                                                      <th className='text-center' style={{ width: "15%" }}>Primary Contact</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {vendorDetails?.contacts?.length > 0 ? vendorDetails?.contacts?.map((c, i) => {
                                                      return (<tr>
                                                          <td className='text-center'>{i + 1}</td>
                                                          <td>{c.name}</td>
                                                          <td> {c.email ?? "-"}</td>
                                                          <td className='text-center'> {c.mobile ?? "-"}</td>
                                                          <td className='text-center'> {c.is_primary == "Y" ? <tapIcon.FontAwesomeIcon color="green" icon={tapIcon.faCheck} /> : ""}</td>
                                                          
                                                      </tr>)
                                                  })
                                                      : <tr> <td colSpan={6} className='text-center'>No Records</td></tr>}
                                              </tbody>
                                          </table>
                                          <div className="tab_content_wrapper">
                                              <span className="content_heading">Domain</span>
                                          </div>
                                          <table className="table table-hover table-borderless">
                                              <tbody>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>Vendor Domain</td>
                                                      <th style={{ "width": "66%" }}>{vendorDetails.domain && vendorDetails.domain.length > 0 ? (vendorDetails.domain.map((domain, k) => <span key={k} className="badge bg-secondary">{domain}</span>))
                                                          : "-"}</th>
                                                  </tr>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>Authorization</td>
                                                      <th style={{ "width": "66%" }}>{vendorDetails.authorization ? vendorDetails.authorization : "-"}</th>
                                                  </tr>
                                              </tbody>
                                          </table>
                                          <div className="tab_content_wrapper">
                                              <span className="content_heading">Area of Operations</span>
                                          </div>
                                          <table className="table table-hover table-borderless">
                                              <tbody>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>States</td>
                                                      <th style={{ "width": "66%" }} className='fs13'>{vendorDetails.operations_state_list && vendorDetails.operations_state_list.length > 0 ? (vendorDetails.operations_state_list.map((state, k) => state.name).join(', '))
                                                          : "-"}</th>
                                                  </tr>
                                                  <tr>
                                                      <td style={{ "width": "34%" }}>District</td>
                                                      <th style={{ "width": "66%" }} className='fs13'>{vendorDetails.operations_district_list && vendorDetails.operations_district_list.length > 0 ? (vendorDetails.operations_district_list.map((state, k) => state.name).join(', '))
                                                          : "-"}</th>
                                                  </tr>

                                              </tbody>
                                          </table>
                                      </Ax>}


                                  </div>)}
                          </div>
                      </div>
                  </div>
              </div>
          );
    }

    render() {
        return (<Ax>{this.vendorDetailModalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(VendorDetailModal);
