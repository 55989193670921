import React, {createRef} from 'react';

import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import {Link} from "react-router-dom";
import TapSelect from "../../../components/ui/TapSelect";
import Loader from "../../../components/ui/Loader/Loader";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {toast} from "react-toastify";
import errorValidation from "../../../services/ErrorValidation";
import AssetHeader from "../../includes/assetHeader/AssetHeader";
import {Modal} from "bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import AssetCard from "../../includes/assetCard/AssetCard";
import Status from "../../../components/ui/Status";
import download from "../../../services/DownloadFile";
import AssetHorizontalCard from "../../includes/assetHorizontalCard/AssetHorizontalCard";
import swal from "sweetalert";

class QuickTicketList extends React.Component {

    measurableAssetId = localStorage.getItem('MeasurableAsset')
    constructor() {
        super();

        this.state = {
            minimizeTable               :         false,
            serviceAddScreenView        :         false,
            serviceUpdateScreenView     :         false,
            scrollDown                  :         false,
            goto_top_visible            :         false,
            scrollTop                   :         0,
            assetCardDetails             :        [],
            ticketListing                :          [],
            ticketData                   :         [],
            listingMeta                 :         [],
            listing_loading             :        false,
            page                        :          1,
            updateTag                   :         {
                assetName               :         "",
                taggedSite              :         "",
            },
            addDocument                 :         {
                name                    :         "",
                number                  :         null,
                file                    :         null
            },
            assetUploadFile             :         null,
            assetUploadName             :         null,
            dueDate                    :         "",
            selectedUser               :          [],
            userList                   :          [],
            activityId                 :         null,
            siteId                      :        null,
            statusList                  :        [],
            selectedStatus              :        null,
            updateRemarks                :       {
                remarks                    :        "",
                sendEmail                   :        "N"
            }

        }
        /*this.scrolltoServiceChecksheet  =        createRef();
        this.scrollToTop                =        createRef();
        this.myRef                      =       React.createRef()*/
        this.showScrollButton           =       this.showScrollButton.bind(this)

    }

    getAssetCard(id){

        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/asset/full_detail/' + id
        }).then((response) => {
            this.setState({assetCardDetails: response.data.data})
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        })
    }

    getTicketListing(page){
        this.setState({listing_loading: true})
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            params:{qt_asset_id : [this.measurableAssetId],page: page},
            url: process.env.REACT_APP_API_SERVER + '/quick_ticket'
        }).then((response) => {
            this.setState({ticketListing: response.data.data,listingMeta : response.data.meta})
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    getTicketData(id){
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url: process.env.REACT_APP_API_SERVER + '/quick_ticket/' + id
        }).then((response) => {
            this.setState({ticketData: response.data.data})
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        })
    }

    /*onScroll = () => {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myRef.current.scrollTop
        this.setState({
            scrollTop: scrollTop
        })
        if(scrollTop > 300){
            this.setState({scrollDown:true})
        }else{
            this.setState({scrollDown:false})
        }
    }*/
    showScrollButton(){
        this.setState({scrollDown:true})
    }
    hideScrollButton(){
        this.setState({scrollDown:false})
    }

    viewTicket(id){
        this.setState({minimizeTable:true})
        this.getTicketData(id)
        this.getTicketFormData(id)
    }


    //handle pagination
    handlePageClick = (e) => {
        this.setState({page: e.selected + 1})
        this.state.page = e.selected + 1;
        this.getTicketListing(this.state.page);
    }


    //function to close
    closeMinimizeTable                  =   ()  =>  {
        this.setState({minimizeTable:false})
    }

    linkToAssetProfile                  =   ()  =>  {
        this.props.history.push(`/measurable_asset_view?id=${this.measurableAssetId}`)
    }

    onUserChange = selectedOptions => {
        this.setState({
            ...this.state.selectedUser, selectedUser:selectedOptions
        })
    }
    onStatusChange = selectedOptions => {
        this.setState({
            selectedStatus:selectedOptions
        })
    }

    handleFormChange(e){
        let updateRemarks = this.state.updateRemarks
        updateRemarks[e.target.name] = e.target.value
        this.setState({updateRemarks})
    }

    componentDidMount() {
        this.getAssetCard(this.measurableAssetId)
        this.getTicketListing(this.state.page)
        this.updateTagModal          =        new Modal(document.getElementById('updateTagModal'), {keyboard: false, backdrop :false});
        this.addDocumentModal        =        new Modal(document.getElementById('addDocumentModal'), {keyboard: false, backdrop :false});
    }


    //function for document modal
    updateTagModalInit                 =   (id)  =>  {
        //Initialize Search Form :-
        this.updateTagModal.show();
        this.getTicketFormData(id)
    }

    getTicketFormData = (id) =>  {
        const access_token = localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            params: {activity_id : id},
            url: process.env.REACT_APP_API_SERVER + '/quick_ticket/form_data'
        }).then((response) => {

            let ticketData =  response.data.quick_ticket;
            if(ticketData?.tagged_users?.length && ticketData?.tagged_users_display?.length){
                this.setState({selectedUser : {value : ticketData?.tagged_users.map(k => k) ,
                        label : ticketData?.tagged_users_display.map(k => k)}})
            }
            let userList = response.data.users_list;
            this.setState({userList})
            this.setState({updateTag : {
                    assetName                : ticketData?.asset,
                    taggedSite              :   ticketData?.tagged_site,
                },selectedStatus : {value : ticketData?.status,label : ticketData?.status_txt},
                dueDate :   ticketData?.due_date !== "" ? moment(ticketData?.due_date,'YYYY-MM-DD').toDate() : "",activityId : ticketData?.activity_id,siteId : ticketData?.site_id,statusList:response.data.status})
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        })
    }

    handleDateChange     = (value,event)  => {
        let dueDate                     =           this.state.dueDate
        this.setState({dueDate:value})
    }

    updatedTagForm      =         (event) => {
        event.preventDefault();
        const access_token = localStorage.getItem('access_token');
        let taggedUser = this.state?.selectedUser?.map(k => k.value);

        let  frmData = {
            activity_id     :     this.state.activityId,
            asset_id        :     parseInt(this.measurableAssetId),
            site_id         :      this.state.taggedSite,
           tagged_users    :       taggedUser,
            due_date        :     moment(this.state.dueDate,'YYYY-MM-DD').format('YYYY-MM-DD')
        };
        axios({
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token},
            data: {...frmData ,
            },
            url: process.env.REACT_APP_API_SERVER + '/quick_ticket_update'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
           this.updateTagModal.hide();
            this.getTicketData(this.state.activityId)
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Update Tag.')})
    }

    updateTagModalJsx                  =   ()  =>  {
        let entries = Object.entries(this.state.userList);
        let result = entries.map(item => item[1])
        return (
            <div className="modal fade" id="updateTagModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Tags For Ticket ({this.state.activityId})</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.updatedTagForm} id="addModalForm">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="form-label">Tagged Asset :</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            name="assetName"
                                            type="text"
                                            value={this.state.updateTag.assetName}
                                            disabled={true}
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>

                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <label className="form-label">Tagged Site :</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            name="taggedSite"
                                            type="text"
                                            value={this.state.updateTag.taggedSite}
                                            disabled={true}
                                            className="form-control"
                                            autoComplete="off"
                                        />
                                    </div>

                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <label className="form-label">Tagged User</label>
                                    </div>
                                    <div className="col-md-8">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            changeEvent={(selectedOption) => this.onUserChange(selectedOption)}
                                            value       =    {this.state.selectedUser}
                                            placeholder =     "Select User"
                                            options      =   {result?.map((t) => { return {value: t.id, label: t.name}})}
                                            isMulti      =  {true}
                                        />
                                    </div>

                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <label className="form-label">Due Date</label>
                                    </div>
                                    <div className="col-md-8">
                                        <DatePicker
                                            selected={this.state.dueDate}
                                            onChange={(value, event) => {this.handleDateChange(value,event)}}
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollMonthYearDropdown
                                            placeholderText={`Select Status Date`}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateStatus      =  (event) => {
        event.preventDefault();
        const access_token = localStorage.getItem('access_token');
        let  frmData = {
            activity_id     :     this.state.activityId,
            status          :     this.state.selectedStatus.value,
            remarks         :     this.state.updateRemarks.remarks,
            send_email_notification : this.state.updateRemarks.sendEmail,
        };
        axios({
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token},
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER + '/quick_ticket_update'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.getTicketData(this.state.activityId)
            this.setState({
                selectedStatus              :        null,
                updateRemarks                :       {
                    remarks                    :        "",
                    sendEmail                   :        "N"
                }
            })
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Update Remarks.')
        })


    }

    handleDocumentFormChange(e){
        let addDocument = this.state.addDocument
        addDocument[e.target.name] = e.target.value
        this.setState({addDocument})
    }

    handleFileChange = async  (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64 = await this.convertBase64(file);
        this.setState({assetUploadFile : base64,assetUploadName:filename})
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    //modal jsx
    //function for document modal
    addDocumentModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addDocumentModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add  Document</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.addDocument.bind(this)} id="addModalForm">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="form-label">Document Name</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addDocument.name}
                                            onChange={(e) => this.handleDocumentFormChange(e)}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Document Name"
                                        />
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Document Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            name="number"
                                            type="text"
                                            value={this.state.addDocument.number}
                                            onChange={(e) => this.handleDocumentFormChange(e)}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Enter Document Number"
                                        />
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Document</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="file" className="form-control" id="exampleFormControlFile1"
                                               name="file"  value={this.state.addDocument.file} onChange={(e) => this.handleFileChange(e)}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    addDocumentModalInit                 =   ()  =>  {
        //Initialize Search Form :-
        this.addDocumentModal.show();
    }

    addDocument(e){
        e.preventDefault()
        const access_token = localStorage.getItem('access_token');

        let frmData = {
            activity_id          :   this.state.activityId,
            doc_name     :   this.state.addDocument.name,
            doc_number   :   this.state.addDocument.number,
            file_name         :   this.state.assetUploadName,
            base64            :   this.state.assetUploadFile

        };
        axios({
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER + '/quick_ticket/document'
        }).then((response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_CENTER})
            this.getTicketData(this.state.activityId)
            this.setState({
                addDocument                 :         {
                    name                    :         "",
                    number                  :         null,
                    file                    :         null
                },
                assetUploadFile             :         null,
                assetUploadName             :         null,
            })
            this.addDocumentModal.hide()
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Add Document');

        });
    }

    //function to delete document
    deleteDocument(id){
        const access_token = localStorage.getItem('access_token');
        let frmData = {
            doc_id               :      id,
            activity_id          :     this.state.activityId
        }
        axios({
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            data:{...frmData},
            url: process.env.REACT_APP_API_SERVER + '/quick_ticket/document'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.getTicketData(this.state.activityId)
        }).catch((err) => {
            errorValidation.apiErrorHandle(err, 'Unable to Delete Document');
        });
    }

    ticketExceldownload                   =   ()  =>  {
        swal({
            title: "Download",
            text: "The download will be for trailing three months period, in case you want a different criteria then please use search button before downloading.",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    this.excelDownloadConfirm()
                }
            });
    }
    excelDownloadConfirm   =    ()  => {
        const access_token                      =   localStorage.getItem('access_token');
        axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            url         :   process.env.REACT_APP_API_SERVER + '/quick_ticket',
            params      :   {download_report: 'download_report',qt_asset_id : [this.measurableAssetId]}
        }).then((response) => {
            download.file(response.data.file_path);
        }).catch((err) => {
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})
        })
    }

    TicketListjsx    =   ()  =>  {
        const details = this.state.assetCardDetails
        return (<Ax>
            <div className="row asset_title m0">
                <div className="col-12 tap_border_left">
                    <AssetHeader assetName={details?.asset_name}  close={this.closeMinimizeTable} />
                </div>
                {this.state.scrollDown === true ? <div className="container-fluid pl5 p4">
                    <div className="bg-white asset_card_container asset_card_scroll_container" style={{marginTop : "12px"}}  >
                        <AssetHorizontalCard assetDetails={this.state.assetCardDetails}/>
                    </div>
                </div> : null }
                <div className="container-fluid pl5 pt0 ">
                    <div className="  " style={{marginTop : this.state.scrollDown === true ? "2px" : "14px"}}>
                        {this.state.scrollDown === false ? <div className="asset_card_container">
                                <div className="asset_card_tag">
                                    <button type="button" className="btn_action_collapse btn-collapse"   onClick={this.showScrollButton.bind(this)}  >
                                        Asset Card
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretDown} className="img-fluid ml5"   alt="item-action" />
                                    </button>
                                </div>
                            </div>:
                            <div className="asset_card_tag_up">
                                <button type="button" className="btn_action_collapse btn-collapse"   data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={this.hideScrollButton.bind(this)} >
                                    Asset Card
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretUp} className="img-fluid ml5"   alt="item-action" />
                                </button>
                            </div>}
                    <div className="asset_title_header row " style={{marginTop : this.state.scrollDown === true ? "2px" : "14px"}}>
                        <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                            {
                                this.state.minimizeTable ?
                                    <div className="row ">
                                        <div className="col-8"><h3>Ticket List</h3></div>
                                        <div className=" col-4   text-end  ">
                                            <button type="button" id='actionMenuButton' className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button"  onClick={this.ticketExceldownload}>
                                                    Download
                                                </a></li>
                                            </ul>
                                        </div>

                                    </div> :
                                    <div className="row ">
                                        <div className="col-5"><h3>Ticket List</h3></div>
                                        <div className=" col-7 text-end ">
                                            <button type="button" className="btn btn-secondary">
                                                <tapIcon.FontAwesomeIcon icon={tapIcon.SearchIcon} className="img-fluid mr3" alt="item-action"/>
                                                Search</button>
                                            <button type="button" id='actionMenuButton' className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" >
                                                <tapIcon.imageIcon icon={tapIcon.ActionIcon} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" role="button"  onClick={this.ticketExceldownload}>
                                                    Download
                                                </a></li>
                                            </ul>
                                            <button  type="button" className="btn btn-secondary"  onClick={this.linkToAssetProfile}>
                                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/></button>
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            this.state.minimizeTable
                                ? (
                                    <div className="col-9 tap_border_left">
                                        <h3>{this.state?.ticketData?.header}</h3>
                                        <div className="text-end">
                                            <button onClick={this.closeMinimizeTable} type="button" className="btn btn-secondary">
                                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                            </button>
                                        </div>
                                    </div>
                                )
                                : null
                        }
                    </div>
                    </div>
                    <div className="action_containt row">
                        <div style={{height:this.state.scrollDown === true ? "49vh" : "75vh"}} className={['action_Tbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.ticketListingTable()}</div>
                        { this.state.minimizeTable ? <div className="col-9 pr0 pl0">{this.ticketViewJsx()}</div> : null }
                    </div>
                </div>
            </div>
        </Ax>);
    }

    ticketListingTable                   =   ()  =>  {
        // let feedIn      =     this.state.feedInListing;
        return (
            <Ax>
                <table className="table table-bordered bg-white table-hover table-fixed">
                    <thead className="table-secondary">
                                { this.state.minimizeTable
                                    ? (<tr>
                                        <th scope="col" style={{width:"20%"}}>Ticket</th>
                                    </tr> )
                                    :   ( <tr>
                                        <th scope="col" style={{width:"5%"}} className="text-center">S.No</th>
                                        <th scope="col" style={{width:"22%"}}>Header</th>
                                        <th scope="col" style={{width:"12%"}}>Ticket ID</th>
                                        <th scope="col" style={{width:"10%"}}>Date of Creation</th>
                                        <th scope="col" style={{width:"10%"}}>Due Date</th>
                                        <th scope="col" style={{width:"18%"}}>Last Updated</th>
                                        <th scope="col" style={{width:"8%"}}>Aging (Days)</th>
                                        <th scope="col" style={{width:"10%"}}>Status</th>
                                        <th scope="col" style={{width:"5%"}}>Action</th>
                                    </tr> )
                                }

                                </thead>
                                <tbody className={ this.state.minimizeTable ? "minimize_body" : "" } >
                                { this.state.listing_loading
                                    ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                                    : this.state.ticketListing.map((item,index) => {
                                        return<tr key={index} >
                                            {
                                                this.state.minimizeTable
                                                    ?   (
                                                        <Ax>
                                                            <td style={{width:"25%"}} onClick={() => this.viewTicket(item.activity_id)}>
                                                                <div className="text-capitalize link-primary">{item?.header}</div>
                                                                <div className="mt-1">
                                                                    <small className="text-capitalize ">{item.activity_id} </small>
                                                                    <small className="float-end"><Status color={item.status_color}>{item.status_text}</Status></small>
                                                                </div>
                                                            </td>
                                                        </Ax>
                                                    )
                                                    :   (<Ax>
                                                            <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index }</td>
                                                            <td><a role="button" className="link-primary text-capitalize" onClick={() => this.viewTicket(item.activity_id)}>{item?.header}</a></td>
                                                            <td className="text-capitalize">{item?.activity_id}</td>
                                                            <td>{item?.created_at}</td>
                                                            <td>{item?.due_date_display}</td>
                                                            <td>{item.last_updated_on_display} <br/>
                                                                <div className="mt-1">{item?.latest_remark?.remarks}</div>
                                                            </td>
                                                            <td className="text-center">{item?.aging}</td>
                                                            <td><Status color={item.status_color}>{item.status_text}</Status></td>
                                                            <td>
                                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a className="dropdown-item" role="button" onClick={() => this.viewTicket(item.activity_id)}>View</a></li>
                                                                </ul>
                                                            </td>

                                                        </Ax>
                                                    )}

                                        </tr>})}
                                </tbody>
                </table>
                <div className=" m0">
                                { this.state.listing_loading ?  null : <span>{this.state.listingMeta ?
                                    `Showing ${this.state.listingMeta.from||0} to ${this.state.listingMeta.to||0} of
                     ${this.state.listingMeta.total} entries` : ``}</span>}
                                <div className="text-end mt-1">
                                    <ReactPaginate
                                        previousLabel={"Prev"}
                                        nextLabel={"Next"}
                                        pageCount={this.state.listingMeta?.last_page ?? 0}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={0}
                                        onPageActive={this.state.listingMeta?.current_page ?? 1}
                                        onPageChange={(e) => this.handlePageClick(e)}
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination justify-content-end"
                                        pageClassName="page-item"
                                        nextClassName="page-item"
                                        previousClassName="page-item"
                                        pageLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        activeClassName="active"
                                    />
                                </div>
                            </div>
            </Ax>
        );
    }

    ticketViewJsx = () => {
        let ticketData = this.state.ticketData;
        return (
            <section className=" sticky_tab">
                <div className="tab-content" id="myTabContent">
                         <div className="tab-pane show active" id="home" role="tabpanel"
                              aria-labelledby="dashboard-tab">
                             <div className="tab_content_sticky_header"
                                  style={{height:this.state.scrollDown === true ? "49vh" : "75vh"}}>
                                 <div className="row">
                                     <div className="col-9 pr0">
                                         <div className="tab_content_wrapper mt-2">
                                             <span className="content_heading">Ticket Details</span></div>
                                         <table className="table mt-2 table-hover table-borderless">
                                             <tbody>
                                             <tr>
                                                 <td className="details-label" style={{"width": "34%"}}>Ticket ID</td>
                                                 <td className="details-name" style={{"width": "25%"}}>{ticketData.activity_id?? "-"}</td>
                                                 <td className="details-name" style={{"width": "41%"}}></td>
                                             </tr>
                                             <tr>
                                                 <td className="details-label" style={{"width": "34%"}}>Created on</td>
                                                 <td className="details-name" style={{"width": "25%"}}>{ticketData.created_at?? "-"}</td>
                                                 <td className="details-name" style={{"width": "41"}}>(Counterlog: NA)</td>
                                                 </tr>
                                             <tr>
                                                 <td className="details-label" style={{"width": "34%"}}>Created by</td>
                                                 <td className="details-name" style={{"width": "25%"}}>{ticketData.created_by?? "-"}</td>
                                                 <td className="details-name" style={{"width": "41%"}}></td>
                                             </tr>
                                             <tr>
                                                 <td className="details-label" style={{"width": "34%"}}>Last updated on</td>
                                                 <td className="details-name" style={{"width": "25%"}}>{ticketData?.updated_at?.substr(0,ticketData?.updated_at.indexOf(' '))}</td>
                                                 <td className="details-name" style={{"width": "41"}}>(Counterlog: NA)</td>
                                             </tr>
                                                 <tr>
                                                     <td className="details-label" style={{"width": "34%"}}>Last updated by</td>
                                                     <td className="details-name" style={{"width": "25%"}}>{ticketData.updated_by?? "-"}</td>
                                                     <td className="details-name" style={{"width": "41"}}></td>
                                                 </tr>
                                                 </tbody>
                                             </table>
                                             <div className="tab_content_wrapper mt-2">
                                                 <span className="content_heading">Tagging Details</span>
                                                 <div className="float-end">
                                                     <button className="btn btn-primary"
                                                             id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false">
                                                         <tapIcon.imageIcon icon={tapIcon.ActionIcon}  className="img-fluid " alt="item-action"/></button>
                                                     <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                         <li><a className="dropdown-item"  role="button" onClick={() => this.updateTagModalInit(ticketData?.activity_id)}>Update Tag</a></li>
                                                     </ul>
                                                 </div>
                                             </div>
                                             <table className="table mt-2 table-hover table-borderless">
                                                 <tbody>
                                                 <tr>
                                                     <td className="details-label" style={{"width": "34%"}}>Asset</td>
                                                     <td className="details-name" style={{"width": "66%"}}>{ticketData.asset?? "-"}</td>
                                                 </tr>
                                                 <tr>
                                                     <td className="details-label" style={{"width": "34%"}}>Site</td>
                                                     <td className="details-name" style={{"width": "66%"}}>{ticketData.tagged_site?? "-"}</td>
                                                 </tr>
                                                 <tr>
                                                     <td className="details-label" style={{"width": "34%"}}>User</td>
                                                     <td className="details-name" style={{"width": "66%"}}>{ticketData?.tagged_users_display?.length > 0 ? ticketData?.tagged_users_display?.map(item => {return <span>{item}<br/></span>}) : "-"}</td>
                                                 </tr>
                                                 </tbody>
                                             </table>
                                             <div className="tab_content_wrapper mt-2">
                                                 <span className="content_heading">Linking Details</span>
                                                 <div className="float-end">
                                                     <button className="btn btn-primary"
                                                             id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false">
                                                         <tapIcon.imageIcon icon={tapIcon.ActionIcon}  className="img-fluid " alt="item-action"/></button>
                                                     <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                         <li><a className="dropdown-item"  role="button" onClick={this.addDocumentModalInit} >Add Document</a></li>
                                                     </ul>
                                                 </div>
                                             </div>
                                             <table className="table mt-2  table-borderless">
                                                 <tbody>
                                                 <tr>
                                                     <td className="details-label" style={{"width": "33%"}}>Documents</td>
                                                     <td className="details-name" style={{"width": "66%"}}>
                                                         {ticketData?.documents?.length > 0 ? <table className="table table-borderless  table-hover">
                                                             <tbody>
                                                             {ticketData?.documents?.map((item, index) => {
                                                                 return <tr>
                                                                     <td scope="col" style={{width:"5%"}}>{index + 1}</td>
                                                                     <td scope="col" style={{width:"65%"}} className="text-capitalize">{item.doc_name} ({item.doc_number})</td>
                                                                     <td scope="col" className="text-center" style={{width:"10%"}}><a href={item.download_link} target="_blank"
                                                                                                                className="asset_link">
                                                                         <tapIcon.FontAwesomeIcon icon={tapIcon.faEye}/></a>
                                                                     </td>
                                                                     <td scope="col"  style={{width:"10%"}} className="text-center">
                                                                         <a href={item.download_link}>
                                                                             <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload}/>
                                                                         </a>
                                                                     </td>
                                                                     <td className="text-center" style={{width:"10%"}}>
                                                                         <tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt} className="asset_link"
                                                                                                  onClick={() => this.deleteDocument(item?.id)}/>

                                                                     </td>
                                                                 </tr>})}</tbody> </table>   : "No Documents Added"}</td>
                                                 </tr>
                                                 </tbody>
                                             </table>
                                             <div className="tab_content_wrapper mt-2">
                                                 <span className="content_heading">Comments & Remarks</span></div>
                                             <div className="mt-3 p-2">
                                                 <Ax>
                                                     <div className="row mb-1">
                                                       <div className="col-12">
                                                           <span className="fw-bold link-primary">Opening Comment :</span> <span className="">{ticketData?.description}</span>
                                                       </div>
                                                     </div>
                                                     {
                                                         ticketData?.remarks?.length > 0 ? ticketData?.remarks?.map((c,k) => {
                                                             return (<Ax key={k}><div className="row mt-2">
                                                                 <div className="col-12">
                                                                     <span className="fw-bold link-primary">{c.submit_by_name}</span> Added a remark on <span className="fw-bold">{c.created_at.substr(0,c.created_at.indexOf(' '))}</span>
                                                                 </div>
                                                                 <div className="col-12 ">{c.remarks}</div>
                                                             </div></Ax>);
                                                         }) : <span>No Remarks</span>
                                                     }
                                                 </Ax>
                                             </div>
                                             <hr/>
                                             {ticketData?.can_remark == "Y" ? <div>
                                                 <div className="tab_content_wrapper mt-2">
                                                     <span className="content_heading">Add Remark</span></div>
                                                 <form onSubmit={this.updateStatus}>
                                                     <div className="p-2 row">
                                                         <label className="col-sm-3 col-form-label">Change status to</label>
                                                         <div className="col-sm-5">
                                                             <TapSelect
                                                                 changeEvent={(selectedOption) => this.onStatusChange(selectedOption)}
                                                                 value       =    {this.state.selectedStatus}
                                                                 options      =   {this.state.statusList?.map((t) => { return {value: t.key, label: t.name}})}
                                                                 isSearchable={false}
                                                                 required={true}
                                                                 placeholder="Please Select Status"
                                                             />
                                                         </div>
                                                     </div>
                                                     <div className="mb-3 row p-2">
                                                         <label className="form-label">Remarks</label>
                                                         <textarea
                                                             name="remarks"
                                                             value={this.state.updateRemarks.remarks}
                                                             onChange={(e) => this.handleFormChange(e)}
                                                             className="form-control mx-2"
                                                             placeholder="Please Add a comment"
                                                             required={true}
                                                             style={{height: "65px",width:"90%"}}
                                                         />
                                                     </div>
                                                     <div className="row mt-3 ">
                                                         <div className="col-9 ">
                                                             <input
                                                                 name="sendEmail"
                                                                 type="checkbox"
                                                                 value={"Y"}
                                                                 onChange={(e) => this.handleFormChange(e)}
                                                                 className="form-check-input"
                                                                 checked={this.state.updateRemarks.sendEmail === "Y"}
                                                             />
                                                             <label className="form-check-label mx-2"
                                                                    htmlFor={this.state.updateRemarks.sendEmail}>Send Email Notification</label>
                                                         </div>
                                                         <div className="col-3 text-end">
                                                             <button type="submit"  className="btn btn-primary mx-2">
                                                                 Update
                                                             </button>
                                                         </div>
                                                     </div>
                                                 </form>
                                             </div> : null}

                                         </div>
                                         <div  className="col-3 pl0  ">
                                             <div className=" bg-white ticket_card_table">
                                                 <table className="table table-hover table-bordered " >
                                                     <tbody>
                                                     <tr>
                                                         <td className="details-label p-2" >Ticket ID <br/> <div className="pt-2 details-name">{ticketData.activity_id?? "-"}</div></td>
                                                     </tr>
                                                     <tr>
                                                         <td className="details-label p-2" >Ticket Status <br/>
                                                             <div className="pt-2 details-name"><Status color={ticketData.status_color}>{ticketData.status_text}</Status></div>
                                                         </td>
                                                     </tr>
                                                     <tr>
                                                         <td className="details-label p-2" >Due Date <br/>
                                                             <div className="pt-2 details-name">{ticketData.due_date_display?? "-"}</div>
                                                         </td>
                                                     </tr>
                                                     <tr>
                                                         <td className="details-label p-2" >Ticket Aging <br/>
                                                             <div className="pt-2 details-name"> {ticketData.aging?? "-"}</div>
                                                         </td>
                                                     </tr>
                                                     <tr>
                                                         <td className="details-label p-2" >Date of Resolution <br/><div className="pt-2 details-name"> - </div></td>
                                                     </tr>
                                                     <tr>
                                                         <td className="details-label p-2" >Days for Resolution <br/><div className="pt-2 details-name"> - </div> </td>
                                                     </tr>
                                                     </tbody>
                                                 </table>
                                             </div>
                                             <AssetCard assetCardDetails={this.state.assetCardDetails}/>
                                         </div>
                                     </div>

                                  </div>
                              </div>

                          </div>
               </section>
        )
    }

    render() {
        let pageScreen = null;
        {
            pageScreen  = this.TicketListjsx()
        }
        return (
            <ApplicationLayout>
                {pageScreen}
                {this.updateTagModalJsx()}
                {this.addDocumentModalJsx()}
            </ApplicationLayout>
        )
    }
}





export default QuickTicketList;