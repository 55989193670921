import * as actionTypes from './actionTypes';

import { applicationInitLoading } from './app';

import axios from 'axios';
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { appInitilizationDataLoad } from '.';

export const iamAppDataInitilization   = () => {
    return {
        type                :   actionTypes.IAM_APP_DATA_INIT
    };
};



export const iamAppDataLoadSuccessfully   = (iam_app_data) => {
    return {
        type                    :   actionTypes.IAM_APP_DATA_SUCCESS,
        iam_data                :   iam_app_data,
    };
};


export const iamAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.IAM_APP_DATA_FAIL
    };
};

//***********************ASSET LISTING FUNCTIONS***************** *****/
export const assetListingDataInitilization   = () => {
    return {
        type                :   actionTypes.ASSET_LISTING_DATA_INIT
    };
};



export const assetListingDataLoadSuccessfully   = (asset_listing_data,search_param,formSearchedElems) => {
    return {
        type                    :   actionTypes.ASSET_LISTING_DATA_SUCCESS,
        asset_listing_data      :   asset_listing_data,
        search_param            :   search_param,
        formSearchedElems       :   formSearchedElems
    };
};


export const assetListingAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.ASSET_LISTING_DATA_FAIL
    };
};

//***********************ASSET LISTING FUNCTIONS***************** *****/
export const nonMeasurableAssetListingDataInitilization   = () => {
    return {
        type                :   actionTypes.NON_MEASURABLE_ASSET_LISTING_DATA_INIT
    };
};



export const nonMeasurableAssetListingDataLoadSuccessfully   = (asset_listing_data,search_param,formSearchedElems) => {
    return {
        type                    :   actionTypes.NON_MEASURABLE_ASSET_LISTING_DATA_SUCCESS,
        asset_listing_data      :   asset_listing_data,
        search_param            :   search_param,
        formSearchedElems       :   formSearchedElems
    };
};


export const nonMeasurableAssetListingAppDataLoadFail   = () => {
    return {
        type                :   actionTypes.NON_MEASURABLE_ASSET_LISTING_DATA_FAIL
    };
};

//***********************ASSET APP LOADING FUNCTIONS***************** *****/
export const iamAppDataLoading         =   (user_info, group_info, acl_info)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        if (acl_info.group_modules.includes("iam")) {
            HttpAPICall.withAthorization('GET', IAM_API_BASE_URL_2 + '/app_data', access_token, {})
                .then((response) => {
                    dispatch(iamAppDataLoadSuccessfully(response.data));

                    dispatch(appInitilizationDataLoad(user_info, group_info, acl_info));
                }).catch(function (err) {
                    dispatch(iamAppDataLoadFail());
                });
        }
    };
};


//***********************ASSET LISTING LOADING FUNCTION***************** *****/
export const assetListingDataLoading         =   (page,searchParm,cl_attribute,formSearchedElems)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        let params                      =   {cl_attribute: cl_attribute, page: page, per_page: 50, ...searchParm};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/assets', access_token, params, {} ,(response) => {
            let respData                =   response.data;
            dispatch(assetListingDataLoadSuccessfully(respData,searchParm,formSearchedElems))
        })
            
        
    };
};

export const nonMeasurableAssetListingDataLoading         =   (page,searchParm,cl_attribute,formSearchedElems)  =>  {
    return dispatch => {
        let access_token = localStorage.getItem('access_token')
        let params                      =   {cl_attribute: 'N', page: page, per_page: 50, ...searchParm};
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/assets', access_token, params, {} ,(response) => {
            let respData                =   response.data;
            dispatch(nonMeasurableAssetListingDataLoadSuccessfully(respData,searchParm,formSearchedElems))
        })
            
        
    };
};