import React from 'react';

import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";

import {Chart} from "react-google-charts";
import AssetCard from "../../includes/assetCard/AssetCard";
import AssetHeader from "../../includes/assetHeader/AssetHeader";
import swal from 'sweetalert';
import errorValidation from "../../../services/ErrorValidation";


class InventoryAssetView extends React.Component {


    constructor() {
        super();
        this.state = {
            assetName            :       "test",
            assetCardDetails     :        []

        }

    }

    closeMinimizeTable                  =   ()  =>  {
        this.props.history.push('/inventory_asset_list')

    }
    componentDidMount() {

    }




//function for asset listing header
    inventoryAssetListjsx                         =   ()  =>  {
        return (<Ax>
            <div className="page_title row m0">
                <div className="asset_listing_icon">
                    { this.state.show === true ?
                        // <div  className=" col-1">
                        null
                        // </div>
                        :
                        <div className="pr0">
                            <button className="btn-col btn-collapse mt15 ml5" type="button" id="dropdownMenu2"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.openNav}>
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretRight} className="img-fluid"  alt="item-action"/>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                <li><a className="dropdown-item" href="#" >Recent Search</a></li>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-12 tap_border_left">
                    <AssetHeader assetName={this.state.assetName} close={this.closeMinimizeTable} parentProps={this.props} />
                </div>
            </div>
            <div className="container-fluid pl5">
                <div className="page_containt  row">
                    <div className="col-9 pr0 ">{this.inventoryAssetDetailJsx()}</div>
                    <div className="col-3 pl4 pr4">{this.assetCard()}</div>
                </div>

            </div>
        </Ax>);
    }

//function for asset listing
 /*   massetListingTable                   =   ()  =>  {
        return (
            <Ax>
                <table className="table table-bordered bg-white table-fixed table-hover ">
                    <thead className="table-secondary">
                    <tr>
                        <th scope="col" style={{width:"20%"}}>Measurable Asset</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.listing_loading
                        ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                        : this.state.assetList.map((item,index) => {
                            return<tr>
                                <td>
                                    <a role="button" className="link-primary" onClick={() => this.setAssetId(item._id,item.asset_name,item.id)}>{item.asset_name}</a>
                                    <br/><small>Code : {item.asset_nickname}</small>
                                    <br/><small>Asset Type : {item.asset_type.asset_type_name}</small>
                                    <br/><small>Site Name : {item.site.site_name}</small>
                                </td>
                            </tr>})}
                    </tbody>
                </table>
                <div className="row m0">
                    { this.state.listing_loading ?  <div className="col-md-6"></div> : <div className="col-md-12">{this.state.listingMeta ?
                        `Showing ${this.state.listingMeta.from||0} to ${this.state.listingMeta.to||0} of
                 ${this.state.listingMeta.total} entries` : ``}</div>}

                </div>
                <div className="row ml3 mt-2">
                    <div className="col-md-6">
                        <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            pageCount={this.state.listingMeta?.last_page ?? 0}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageActive={this.state.listingMeta?.current_page ?? 1}
                            onPageChange={(e) => this.handlePageClick(e)}
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination justify-content-end"
                            pageClassName="page-item"
                            nextClassName="page-item"
                            previousClassName="page-item"
                            pageLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                </div>

            </Ax>
        );
    }*/

//function for asset view
    inventoryAssetDetailJsx                       =   ()  =>  {

        return (<section className="item_view_container bg-white">
            <div id="mySidenav" className="sidenav "  ref={this.wrapperRef}>
                <div className="d-flex justify-content-between bordered_content">
                    <div> <h5 className="text-left mt-2 ml5">Asset Listing</h5></div>
                    <div> {this.state.show === true ?
                        // <div  className=" col-1">
                        <button type="button" className="btn-col btn-collapse mr3 mb-1 mt-2"  onClick={this.closeNav}>
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretLeft} className="img-fluid"  alt="item-action"/>
                        </button> : null}</div>
                </div>

             {/*   {this.massetListingTable()}*/}
            </div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home"
                            type="button" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                            type="button" role="tab" aria-controls="details" aria-selected="false">Details
                    </button>
                </li>

            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="home" role="tabpanel"
                     aria-labelledby="dashboard-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Dashboard</span></div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className="fw-bold primary_color">Score</h6>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-6">
                                                <Chart
                                                    width={'100%'}
                                                    height={'150px'}
                                                    chartType="Gauge"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[
                                                        ['Label', 'Value'],
                                                        ['', 20],
                                                    ]}
                                                    options={{
                                                        redFrom: 0,
                                                        redTo: 40,
                                                        yellowFrom: 40,
                                                        yellowTo: 60,
                                                        minorTicks: 5,
                                                    }}
                                                    rootProps={{ 'data-testid': '1' }}
                                                />
                                                <h6 className="mx-4">Data Score</h6>

                                            </div>
                                            <div className="col-6">
                                                <Chart
                                                    width={'100%'}
                                                    height={'150px'}
                                                    chartType="Gauge"
                                                    loader={<div>Loading Chart</div>}
                                                    data={[
                                                        ['Label', 'Value'],
                                                        ['', -20],
                                                    ]}
                                                    options={{
                                                        redFrom: -100,
                                                        redTo: -20,
                                                        yellowFrom: -20,
                                                        yellowTo: +20,
                                                        minorTicks: 10,
                                                        max:100,
                                                        min:-100
                                                    }}

                                                />
                                                <h6 className="mx-4">SCS Score</h6>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-7">
                                                <h6 className="fw-bold primary_color my-2">Consumption vs Approved Limit</h6>
                                            </div>
                                            <div className="col-5">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="Please Select"
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <table className="table table-hover table-bordered mt-1">
                                            <thead>
                                            <tr>
                                                <th scope="col" className="table-head-row" style={{width: "40%"}}></th>
                                                <th scope="col" className="table-head-row" style={{width: "30%"}} >Approved Limit</th>
                                                <th scope="col" className="table-head-row" style={{width: "30%"}} >Actuals</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Consumption(ltr/hr)</td>
                                                <td>10</td>
                                                <td>9</td>
                                            </tr>
                                            <tr>
                                                <td>Economy (km/ltr)</td>
                                                <td>10</td>
                                                <td>9</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-body pb0">
                                        <div className="row">
                                            <div className="col-7">
                                                <h6 className="fw-bold primary_color my-2">Asset Data</h6>
                                            </div>
                                            <div className="col-5">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="Please Select"
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <table className="table table-hover table-borderless">
                                                    <tbody>
                                                    <tr>
                                                        <td className="details-label">Running</td>
                                                        <td className="details-name">Running</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Fuel</td>
                                                        <td className="details-name">Fuel</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Expense</td>
                                                        <td className="details-name">Expense</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Downtime</td>
                                                        <td className="details-name">Downtime</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Productivity</td>
                                                        <td className="details-name">Productivity</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Trips/Sessions</td>
                                                        <td className="details-name">trips/sessions</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">SCS Executed</td>
                                                        <td className="details-name">SCS Executed</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Tickets Created</td>
                                                        <td className="details-name">Tickets Created</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Utilization</td>
                                                        <td className="details-name">Utilization</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="details-label">Availability/Uptime</td>
                                                        <td className="details-name">Availability/Uptime</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane " id="details" role="tabpanel" aria-labelledby="details-tab">
                    <div className="tab_content_header">
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Asset Details</span></div>
                        <table className="table table-hover table-borderless">
                            <tbody>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Name</td>
                                <td className="details-name" style={{"width": "66%"}}>Name</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Code</td>
                                <td className="details-name" style={{"width": "66%"}}>Code</td>
                            </tr>

                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Item Name</td>
                                <td className="details-name" style={{"width": "66%"}}>Item Name</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Item Code</td>
                                <td className="details-name" style={{"width": "66%"}}>Item Code</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Manufacturer Name</td>
                                <td className="details-name" style={{"width": "66%"}}>Manufacturer Name</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Manufacturer Part Number</td>
                                <td className="details-name" style={{"width": "66%"}}>Manufacturer Part Number</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Item Description</td>
                                <td className="details-name" style={{"width": "66%"}}>Item Description</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Item Capacity</td>
                                <td className="details-name" style={{"width": "66%"}}>Item Capacity</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Status(Working Health)</td>
                                <td className="details-name" style={{"width": "66%"}}>Status(Working Health)</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Ownership</span></div>
                        <table className="table table-hover table-borderless">
                            <tbody>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Ownership Category</td>
                                <td className="details-name" style={{"width": "66%"}}>Ownership Category</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Owner Name</td>
                                <td className="details-name" style={{"width": "66%"}}>Owner Name</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Purchased From</td>
                                <td className="details-name" style={{"width": "66%"}}>Purchased From</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Purchased Date</td>
                                <td className="details-name" style={{"width": "66%"}}>Purchased Date</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Document Number</td>
                                <td className="details-name" style={{"width": "66%"}}>Document Number</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Document Date</td>
                                <td className="details-name" style={{"width": "66%"}}>Document Date</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>PO Number</td>
                                <td className="details-name" style={{"width": "66%"}}>PO Number</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>PO Date</td>
                                <td className="details-name" style={{"width": "66%"}}>PO Date</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Commercial / Lease Value (per Month)</td>
                                <td className="details-name" style={{"width": "66%"}}>Commercial / Lease Value (per Month)</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Serial Numbers</span></div>
                        <table className="table table-hover table-borderless">
                            <tbody>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Machine Serial No.</td>
                                <td className="details-name" style={{"width": "66%"}}>Machine Serial No.</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Year of Manufacturing</td>
                                <td className="details-name" style={{"width": "66%"}}>Year of Manufacturing</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Date of Commissioning</td>
                                <td className="details-name" style={{"width": "66%"}}>Date of Commissioning</td>
                            </tr>

                            </tbody>
                        </table>
                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Site Transition</span></div>
                        <table className="table table-hover table-borderless">
                            <tbody>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Current Site</td>
                                <td className="details-name" style={{"width": "66%"}}>Current Site</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Date of Receipt (Transition)</td>
                                <td className="details-name" style={{"width": "66%"}}>Date of Receipt (Transition)</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Mobilization Status (Deployment)</td>
                                <td className="details-name" style={{"width": "66%"}}>Mobilization Status (Deployment)</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Tentative Release Date</td>
                                <td className="details-name" style={{"width": "66%"}}>Tentative Release Date</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Date of Exit</td>
                                <td className="details-name" style={{"width": "66%"}}>Date of Exit</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Next Site</td>
                                <td className="details-name" style={{"width": "66%"}}>Next Site</td>
                            </tr>
                            </tbody>
                        </table>

                        <div className="tab_content_wrapper mt-2"><span className="content_heading">Additional Attributes</span></div>
                        <table className="table table-hover table-borderless">
                            <tbody>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Major</td>
                                <td className="details-name" style={{"width": "66%"}}>Major</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Minor</td>
                                <td className="details-name" style={{"width": "66%"}}>Minor</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Oracle Asset ID</td>
                                <td className="details-name" style={{"width": "66%"}}>Oracle Asset ID</td>
                            </tr>
                            <tr>
                                <td className="details-label" style={{"width": "34%"}}>Legacy Asset Code</td>
                                <td className="details-name" style={{"width": "66%"}}>Legacy Asset Code</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </section>);
    }

//function to asset card
    assetCard                       =   ()  =>  {
        return (<section className="item_view_container" >
            <div style={{ height: "80.1vh",
                overflowY: "scroll"}} className="bg-white">
                { this.state.card_loading
                    ? <Loader />
                    :
                    <AssetCard assetCardDetails={this.state.assetCardDetails}/>}
            </div>
        </section>);
    }

    render() {
        let pageScreen = null;
        if(this.state.m_assetAddScreenView) {
            pageScreen  =  "Add"
        } else if(this.state.m_assetUpdateScreenView) {
            pageScreen  =   "Update"
        } else {
            pageScreen  = this.inventoryAssetListjsx();
        }

        return (
            <ApplicationLayout>
                <ToastContainer/>
                {
                    pageScreen
                }

            </ApplicationLayout>
        )
    }
}


export default InventoryAssetView;