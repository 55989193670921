import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';

class CompositeExpenseDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            compositeExpenseData        :       null,
            view_loading                :       false,
        };
        
    }

    componentDidMount() {
        if(this.props && this.props.compositeExpenseId){
            this.getCompositeExpenseDetails(this.props.compositeExpenseId)
        }
        if(this.props && this.props.compositeExpenseData){
            this.setState({compositeExpenseData : this.props.compositeExpenseData})
        }
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.compositeExpenseId !== this.props.compositeExpenseId){
            this.getCompositeExpenseDetails(nextProps.compositeExpenseId)
         }
         if(nextProps.compositeExpenseData !== this.props.compositeExpenseData){
             this.setState({compositeExpenseData : nextProps.compositeExpenseData})
         }
         
     }
    

     getCompositeExpenseDetails             =    (transaction_id)        =>  {
        this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET', process.env.REACT_APP_API_SERVER + '/common_expense/'  + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 compositeExpenseData            : respData.data,
             });
            
         }).then(() => this.setState({view_loading: false}));
        
     }
    
    render() {
        let compositeExpenseData = this.state.compositeExpenseData
        return (<Ax>
            {this.state.view_loading ? <Loader /> :
                compositeExpenseData ? (<div className="tab_content_header">
                   
                        <div className="tab_content_wrapper ">
                            <span className="content_heading">Composite Expense Detail</span>
                        </div>
                        <table className="table mt-2 table-hover table-borderless">
                            <tbody>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Transaction ID</td>
                                    <th className="fs14 fw500" style={{ "width": "66%" }}>{compositeExpenseData.common_expense_id}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Date of Transaction</td>
                                    <th className="fs14 fw500" style={{ "width": "66%" }}>{compositeExpenseData.activity_date_display}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Expense Head</td>
                                    <th className="fs14 fw500" style={{ "width": "66%" }}>{compositeExpenseData.expense_head??"-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Expense Details</td>
                                    <th className="fs14 fw500" style={{ "width": "66%" }}>{compositeExpenseData.expense_details !== null && compositeExpenseData.expense_details !== "" ? compositeExpenseData.expense_details : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Amount</td>
                                    <th className="fs14 fw500" style={{ "width": "66%" }}>{compositeExpenseData.amount !== null && compositeExpenseData.amount !== "" ? compositeExpenseData.amount : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Payee</td>
                                    <th className="fs14 fw500" style={{ "width": "66%" }}>{compositeExpenseData.payee !== null && compositeExpenseData.payee && compositeExpenseData.payee.key_display ? compositeExpenseData.payee.key_display : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Payee Name</td>
                                    <th className="fs14 fw500" style={{ "width": "66%" }}>{compositeExpenseData.payee !== null && compositeExpenseData.payee && compositeExpenseData.payee.payee_user ? compositeExpenseData.payee.payee_user.name : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Reference Number</td>
                                    <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{compositeExpenseData.reference_number !== null ? compositeExpenseData.reference_number : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Expense Voucher Attachment</td>
                                    <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{compositeExpenseData.expense_voucher ? <a href={compositeExpenseData.expense_voucher} target="_blank">View</a> : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Notes</td>
                                    <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{compositeExpenseData.notes !== null && compositeExpenseData.notes !== "" ? compositeExpenseData.notes : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Wallet</td>
                                    <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{compositeExpenseData.wallet && compositeExpenseData.wallet.id !== null ? compositeExpenseData.wallet.wallet_name : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Selected Sites</td>
                                    <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{compositeExpenseData.site !== "" ? compositeExpenseData.site_names_display : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Selected Asset Type</td>
                                    <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{compositeExpenseData.asset_type !== null && compositeExpenseData.asset_type !== "" ? compositeExpenseData.asset_type_display : "-"}</th>
                                </tr>
                                <tr>
                                    <td className="fs14 fw-normal" style={{ "width": "34%" }}>Selected Asset</td>
                                    <th className="fs14 fw500 text-capitalize" style={{ "width": "66%" }}>{compositeExpenseData.asset !== null && compositeExpenseData.asset !== "" ? compositeExpenseData.assets_display : "-"}</th>
                                </tr>
                            </tbody>
                        </table>
                        <div className="tab_content_wrapper my-3">
                            <span className="content_heading">Asset Expenses</span>
                        </div>
                        <table className="table mt-2 mb-3 table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Asset Name</th>
                                    <th>Site Name</th>
                                    <th className="text-end">Split Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {compositeExpenseData.asset_expenses && compositeExpenseData.asset_expenses.length > 0 ?
                                    compositeExpenseData.asset_expenses.map((a, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{a.asset_name}</td>
                                                <td>{a.site_name}</td>
                                                <td className="text-end">{a.expense_amount}</td>
                                            </tr>
                                        )
                                    }) : <tr colSpan={3}>No Record</tr>}
                            </tbody>
                        </table>
                   
                </div>) : null}
        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
    };
};

export default connect(mapStateToProps)(CompositeExpenseDetail);