import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import TapIcon from '../../../services/TapIcon';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import TapApiUrls from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';


class ScsExecutionHistoryView extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                        =             {
            executionId                   :            null,
            executionData                 :            null
        }
    }

    componentDidMount() {
        if(this.props.executionData){
            this.setState({executionData : this.props.executionData})
        } 
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.executionData !== this.props.executionData) {
            this.setState({executionData : nextProps.executionData})
         }     
    }

    executionHistoryJsx                      =       ()             =>        {
        let executionData = this.state.executionData;
        return (<div className="">

            {this.state.executionData ?
                <Ax>
                    <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">Service CheckSheet Details</span></div>
                    <table className="table mt-2 table-hover table-borderless">
                        <tbody>
                            <tr>
                                <td style={{ "width": "34%" }}>Service CheckSheet Name</td>
                                <th style={{ "width": "66%" }}>
                                    {executionData.scs_setting && executionData.scs_setting.alias_name ? executionData.scs_setting.alias_name : "-"}
                                </th>
                            </tr>
                            <tr>
                                <td>SCS Description</td>
                                <th>
                                    {executionData.scs_setting && executionData.scs_setting.description ? executionData.scs_setting.description : "-"}
                                </th>
                            </tr>
                            <tr>
                                <td>Section</td>
                                <th>
                                    {executionData.scs_setting && executionData.scs_setting.section ? executionData.scs_setting.section.component : "-"}
                                </th>
                            </tr>
                            <tr>
                                <td>Sub - Section</td>
                                <th>
                                {executionData.scs_setting && executionData.scs_setting.sub_section ? executionData.scs_setting.sub_section.subcomponent : "-"}
                                </th>
                            </tr>
                            <tr>
                                <td >Parameter</td>
                                <th>{executionData.scs_setting.enable_parameter == "Y" && executionData.scs_setting.parameter ? executionData.scs_setting.parameter.type_display : '-'}
                                    {executionData.scs_setting.enable_parameter == "Y" && executionData.scs_setting.parameter && executionData.scs_setting?.parameter?.critical_level ? <div><br /> Critical Level : {executionData.scs_setting.parameter?.critical_level} </div> : ''}</th>
                            </tr>
                            <tr>
                                <td>Executed Parameter</td>
                                <th>{executionData.enable_parameter == "Y" && executionData.parameter ? <Status color={executionData.parameter.execute_display.color}>{executionData.parameter.type == "range" ? executionData.parameter.execute_level :executionData.parameter.execute_display.text}</Status>: '-'}
                                   {executionData.enable_parameter == "Y" && executionData.parameter && executionData.parameter.type == "range" ? <div className='my-1'>{executionData.parameter.type_display}</div> : null}
                                    {executionData.enable_parameter == "Y" && executionData.parameter && executionData.parameter?.critical_level ? <div>Critical Level : {executionData.parameter?.critical_level} </div> : ''}</th>
                            </tr> 
                            <tr>
                                <td>Execution Site</td>
                                <th> {executionData.site ? <span>{executionData.site.site_name} ({executionData.site.site_code})</span>
                                    : "-"}</th>
                            </tr>
                            <tr>
                                <td>Execution Counter Log</td>
                                <th> {executionData.executedCounterlog && executionData.executedCounterlog.length > 0 ? executionData.executedCounterlog.map((item, index) => { return (<div className="mt-1"> {item.label} : {item.reading}  {item.unit} <br /></div>) })
                                                        : "-"}</th>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-2 ">
                        <span className="content_heading">Service CheckSheet Execution Interval</span></div>
                    <table className="table table-hover table-bordered mt-2 mb2">
                        <thead className='bg-light'>
                            <tr>
                                <th></th>
                                <th style={{ "width": "15%" }}>Previous SCS</th>
                                <th style={{ "width": "15%" }}>Current SCS</th>
                                <th style={{ "width": "15%" }}>Execution Interval</th>
                                <th style={{ "width": "15%" }}>Recommended Interval</th>
                                <th style={{ "width": "10%" }}>Deviation</th>
                                <th style={{ "width": "10%" }}>Execution Percentage</th>

                            </tr>
                        </thead>
                        <tbody>
                            {executionData.interval && executionData.interval.length > 0 ? executionData.interval.map((exl, index) => {
                                return (<tr>
                                    <th  >{exl.label}</th>
                                    <td className="  text-end">{exl.prev_execute_service ? exl.prev_execute_service : "-"}</td>
                                    <td className="  text-end">{exl.execute_service  ? exl.execute_service : "-"}</td>
                                    <td className='text-end'>{exl.prev_execute_service ? exl.execution_interval : "NA"}</td>
                                    <td className="  text-end">{exl.interval && exl.status && exl.status == 'Y' ? exl.interval : "Disabled"}</td>
                                    <td className="  text-end">{exl.deviation && exl.status && exl.status == 'Y' ? exl.deviation : "NA"}</td>
                                    <td className="  text-end">{exl.execution_percentage >= 0 && exl.prev_execute_service && exl.status && exl.status == 'Y' ? <span>{exl.execution_percentage} %</span> : "NA"}</td>
                                </tr>)
                            }) : <tr><td className='text-center' colSpan={5}>No Record</td></tr>}
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-2 mb-2">
                        <span className="content_heading">Transaction Details</span></div>
                    <table className="table table-hover table-borderless mt-2 mb-2">
                        <tbody>
                            <tr>
                                <td style={{ "width": "34%" }}>Transaction ID </td>
                                <th style={{ "width": "66%" }}>{executionData.transaction_id ? executionData.transaction_id : "-"}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "34%" }}>Date of Transaction </td>
                                <th style={{ "width": "66%" }}>{executionData.feed_date_display ? executionData.feed_date_display : "-"}</th>
                            </tr>
                            <tr>
                            <td >Executed by</td>
                                <th>{executionData.user ? executionData.user.full_name : "-"}</th>
                            </tr>
                            <tr>
                                <td > Date of Entry	</td>
                                <th>{executionData.created_at_display ? executionData.created_at_display : "-"}</th>
                            </tr>
                            
                           
                        </tbody>
                    </table>
                </Ax> : null}

        </div>)
    }


    render                              =   ()  =>  {

        return (<Ax>
          {this.executionHistoryJsx()}

        </Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ScsExecutionHistoryView);