import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { Modal } from 'bootstrap';
import ItemViewInList from '../includes/ItemViewInList';
import tapIcon from '../../../services/TapIcon';
import ConsumptionHistoryModal from '../consumption/ConsumptionHistoryModal';
import POHistoryModal from './POHistoryModal';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';

class PurchaseOrderDetailModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =       {
           detail_loading            :       false,
           poDetail                  :       null,
           poTransactionId           :       null,
        };
        this.consumptionHistoryRef  =   React.createRef();
        this.poHistoryRef           =   React.createRef();
        this.purchaseOrderUrl       =   INVENTORY_API_BASE_URL_2 + '/purchase_order';

    }

    componentDidMount               =   ()  =>  {
        this.poDetailModal            =   new Modal(document.getElementById('poDetailModal'), {keyboard: false, backdrop :false});
        this.initScreen(this.props);
    }

    modalPOInit               =   (transaction_id)  =>  {
       
        this.getPODetail(transaction_id) 
        setTimeout(() => {
            this.poDetailModal.show()
        },1000)    
    }

    getPODetail        =       (transaction_id)      =>      {
        this.setState({detail_loading : true})
        HttpAPICall.withAthorization('GET', this.purchaseOrderUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                poDetail             :   respData.data,
                poTransactionId      :   respData.data.transaction_id, 
            });
            
        }).then(() => this.setState({detail_loading : false}));      
    }
    
    componentWillReceiveProps(nextProps) {
         this.initScreen(nextProps);
    }
    
    initScreen                      =   (props)  =>  {
        this.setState({access_token : props.access_token});
    }
    
    reqDetailJsx        =       ()      =>      {
         let purchaseOrder = this.state.poDetail;
         let purchase_summary                    =   [];
         if (purchaseOrder && purchaseOrder.vendor_state == purchaseOrder.warehouse_state) {
              
             purchase_summary.push({ key: 'CGST ', value: "As applicable" });
             purchase_summary.push({ key: 'SGST ', value: "As applicable" });
         } else {
             purchase_summary.push({ key: 'IGST ', value: "As applicable" });
         }
 
         let sub_total_amount                =      purchaseOrder && purchaseOrder.items &&  purchaseOrder.items.length > 0 && purchaseOrder.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);
 
         let tcs_amount                          =  purchaseOrder &&  Number(purchaseOrder.tcs_rate && purchaseOrder.tcs_rate >= 0
             ? purchaseOrder && purchaseOrder.tcs_type == "Percentage" ? (sub_total_amount*purchaseOrder.tcs_rate/100) :  Number(purchaseOrder.tcs_rate)
             : 0);
        return (<div className="modal fade" id="poDetailModal" tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">PO Detail : {this.state.poTransactionId ?? "-"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    {this.state.detail_loading ? <Loader />
                        : <div className="modal-body ">
                            {this.state.poDetail
                                ? <div>
                                    <table className="table table-bordered bg-white" >
                                        <thead className="table-secondary">
                                            <tr>
                                                <th style={{ width: "33%" }}>ID</th>
                                                <th style={{ width: "33%" }}>Date</th>
                                                <th style={{ width: "33%" }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="col">{purchaseOrder.transaction_id}</td>
                                                <td scope="col">{purchaseOrder.transaction_date_display}</td>
                                                <td scope="col" className="align-middle">
                                                    {purchaseOrder.status_data ? purchaseOrder.status_data.name : ''}
                                                </td>  </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                        <tbody>
                                        <tr>
                                            <td style={{ width: "33%" }} >Purchase Order Type</td>
                                            <td colSpan={2}>{purchaseOrder.purchase_order_type_name ? purchaseOrder.purchase_order_type_name :  ""}
                                            </td>
                                        </tr>
                                            <tr>
                                                <td style={{ width: "33%" }} >Warehouse (Delivery Address)</td>
                                                <td colSpan={2}>{purchaseOrder.warehouse ? <div><b>{purchaseOrder.warehouse.name} ({purchaseOrder.warehouse.code})</b> <br />
                                                    {purchaseOrder.warehouse ?
                                                        <div className='pt-1'>Address : {purchaseOrder.warehouse.address1 ? purchaseOrder.warehouse.address1 + ", " : ""}
                                                            {purchaseOrder.warehouse.address2 ? purchaseOrder.warehouse.address2 + ", " : ""}
                                                            {purchaseOrder.warehouse.landmark ? purchaseOrder.warehouse.landmark + ", " : ""}
                                                            {purchaseOrder.warehouse.city ? purchaseOrder.warehouse.city + ". " : "-"} <br />
                                                            Destination of Supply : <b>{purchaseOrder.warehouse.state_name}</b> <br />
                                                            GSTIN  : {purchaseOrder.warehouse_gstin ?? "-"} <br />
                                                        </div> : "-"}</div> : "-"} <br />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "33%" }} >Vendor</td>
                                                <td colSpan={2}>{purchaseOrder.vendor ? <div><b>{purchaseOrder.vendor.name} ({purchaseOrder.vendor.code})</b> <br />
                                                    {purchaseOrder.vendor_address ?
                                                        <div className='py-1'>Address : {purchaseOrder.vendor_address.address1 ? purchaseOrder.vendor_address.address1 + ", " : ""}
                                                            {purchaseOrder.vendor_address.address2 ? purchaseOrder.vendor_address.address2 + ", " : ""}
                                                            {purchaseOrder.vendor_address.landmark ? purchaseOrder.vendor_address.landmark + ", " : ""}
                                                            {purchaseOrder.vendor_address.city ? purchaseOrder.vendor_address.city + ". " : "-"} <br />
                                                            Source of Supply : <b>{purchaseOrder.vendor_address.state_name} ({purchaseOrder.vendor_address.country_name}) </b> <br />
                                                            GSTIN  : {purchaseOrder.vendor_gstin ?? "-"}</div> : "-"}</div> : "-"}
                                                    {purchaseOrder.po_display_vendor_contact && purchaseOrder.po_display_vendor_contact == 'Y' && purchaseOrder.vendor_contact && <div className='py-1'>
                                                        Name : {purchaseOrder.vendor_contact.name} | Contact No. : {purchaseOrder.vendor_contact.mobile} | Email : {purchaseOrder.vendor_contact.email}
                                                    </div>}
                                                </td>
                                            </tr>
                                            {purchaseOrder.po_allow_po_for && purchaseOrder.po_allow_po_for == 'Y' && <Ax>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Purchase Order For</td>
                                                    <td colSpan={2}>{purchaseOrder.po_for && purchaseOrder.po_for == "asset" ? "Asset " : purchaseOrder.po_for && purchaseOrder.po_for == "workstation" ? "Workstation " : purchaseOrder.po_for && purchaseOrder.po_for == "employee" ? "Employee " : ""}
                                                        {purchaseOrder.po_for == "asset" && purchaseOrder.assetProfile ? <span>({purchaseOrder.assetProfile.name})</span> : purchaseOrder.po_for == "workstation" && purchaseOrder.workstation ? <span>({purchaseOrder.workstation.workstation_name})</span> : purchaseOrder.po_for == "employee" && purchaseOrder.employee ? <span>({purchaseOrder.employee.name})</span> : ""}
                                                    </td>
                                                </tr>

                                            </Ax>}
                                            <tr>
                                                <td scope="col">Priority & Delivery Date</td>
                                                <td style={{ width: "33%" }} className="text-capitalize">{purchaseOrder.priority ?? "-"}</td>
                                                <td style={{ width: "34%" }}>{purchaseOrder.expected_delivery_date_display ?? "-"}</td>

                                            </tr>
                                            <tr>
                                                <td scope="col">Quotation Number & Date</td>
                                                <td style={{ width: "33%" }} className="text-capitalize">{purchaseOrder.quotation_name ?? "-"}</td>
                                                <td style={{ width: "34%" }}>{purchaseOrder.quotation_date ?? "-"}</td>

                                            </tr>
                                            <tr>
                                                <td scope="col">Tag Users</td>
                                                <td scope="col" colSpan={2}>{purchaseOrder && purchaseOrder.usersList && purchaseOrder.usersList.length > 0 ? purchaseOrder.usersList.map(u => <div>{u.full_name}</div>) : ""}</td>
                                            </tr>
                                            {purchaseOrder.po_enable_authorized_signatory && purchaseOrder.po_enable_authorized_signatory == 'Y' && <tr>
                                                <td scope="col">Authorized Signatory</td>
                                                <td style={{ width: "33%" }} className="text-capitalize" colSpan={2}>{purchaseOrder.authorized_signatory && purchaseOrder.authorized_signatory.full_name ? purchaseOrder.authorized_signatory.full_name : "-"}</td>

                                            </tr>}
                                            <tr>
                                                <td scope="col">Currency</td>
                                                <td colSpan={2}><b>{purchaseOrder.currency}</b> {purchaseOrder.currency !== purchaseOrder.base_currency ? <span>(1 {purchaseOrder?.currency} : <NumberFormatter number={purchaseOrder?.conversion_rate} precision={2} /> {purchaseOrder?.base_currency})</span> : null}</td>

                                            </tr>
                                            {purchaseOrder.additional_attributes_data && purchaseOrder.additional_attributes_data.length > 0
                                                ? purchaseOrder.additional_attributes_data.map((attr) => {
                                                    return (<tr key={attr.key}>
                                                        <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                        <th scope="col">{attr.value ? attr.value : "-"}</th>
                                                    </tr>)
                                                }) : null}
                                        </tbody>
                                    </table>

                                    <table className="table table-bordered table-responsive bg-white my-3 table-sm" id="poTable">
                                        <thead className="table-secondary">
                                            <tr>
                                                <th style={{ "width": '5%' }} className="text-center"><input type='checkbox' onChange={this.checkAllCancelItem} name="chk[]" /></th>
                                                <th style={{ width: "39%" }}>Item Details</th>
                                                <th style={{ width: "12%" }}>Requisition</th>
                                                <th style={{ width: "12%" }} className="text-end">Quantity</th>
                                                <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                                <th style={{ width: "8%" }} className="text-end">Discount</th>
                                                <th style={{ width: "8%" }} className="text-end">Tax</th>
                                                <th style={{ width: "8%" }} className="text-end">Amount</th>
                                                {/* <th style={{ width: "8%" }} className="text-center">Pending</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchaseOrder && purchaseOrder.items && purchaseOrder.items.length > 0 ? purchaseOrder.items?.map((i, k) => {
                                                    return (<tr>
                                                        <td className='text-center'>
                                                            {k + 1} <br /> <br />
                                                            <input
                                                                type='checkbox'
                                                                name="foo"
                                                                value={i.item.id}
                                                                data-poItemId={i.po_item_id}
                                                                data-pendingQty={i.pending_qty}
                                                            />

                                                        </td>
                                                        <td>{i.item ?
                                                            <div className='row'>
                                                                <div className='col-sm-10'>
                                                                    <ItemViewInList view_hsn={true} item={i.item} remark={i.remark} due_date={i.due_date_display} viewItemModal={() => this.viewItemModalInit(i.item)} />
                                                                </div>
                                                                <div className='col-sm-2 text-end'>
                                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                                    <ul className="dropdown-menu">
                                                                        <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.item, k)}>Consumption History</a></li>
                                                                        <li><a href="#" className="dropdown-item" onClick={() => this.viewPOHistoryModalInit(i.item, k)}>PO History</a></li>
                                                                        <li><a href="#" className="dropdown-item" onClick={() => this.viewTransactionLedgerModalInit(i.item, k)}>Item Transaction Ledger</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            : null}</td>
                                                        <td className="text-center ">
                                                            {i.req_allocation && i.req_allocation.length > 0
                                                                ? (<Ax>
                                                                    {i.req_allocation.map((r, k) => {
                                                                        return <div className='my-1 link-primary cursor_pointer' onClick={() => this.reqDetailModalInit(r.requisition_transaction_id)}>{r.requisition_transaction_id}: {r.qty}</div>
                                                                    })}
                                                                </Ax>)
                                                                : null
                                                            }
                                                            {(i.additional_qty > 0 ? <div className='my-1'>ADDL.: {i.additional_qty}</div> : null)}
                                                        </td>
                                                        <td className='text-end'>{i.qty}
                                                            {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}
                                                            <div className='my-1'>Pending : {i.pending_qty}</div>
                                                            {i.cancelled_qty && i.cancelled_qty > 0 ? <div className='my-1'>Cancelled : {i.cancelled_qty}</div> : null}
                                                        </td>
                                                        <td className='text-end'>{i.rate_vc}</td>
                                                        <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? purchaseOrder.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : 0}</td>
                                                        <td className='text-end'>{purchaseOrder && purchaseOrder.vendor_gstin && purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'N' ? <div>{i.gst} %</div> : purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'Y' ? "-" : "NA"}
                                                            {purchaseOrder && purchaseOrder.vendor_gstin && i.item ? <span className="text-helping input-group-text text-end pr0">{purchaseOrder.vendor_address && purchaseOrder.vendor_address.state_name == purchaseOrder.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                                        </td>
                                                        <td className='text-end'>
                                                            <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={parseFloat(i.item_with_discount).toFixed(2)} />
                                                        </td>
                                                    </tr>)

                                                }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                    <div className="row align-items-center mb-5">
                                        <div className="col-6">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <div>{purchaseOrder.notes ?? "-"}</div>

                                        </div>
                                        <div className="col-6">
                                            <table className="table  table-borderless">
                                                <tbody>
                                                    <tr className="subtotal_bg_color">
                                                        <th>Sub-Total ({purchaseOrder.currency ? purchaseOrder.currency : "INR"})</th>
                                                        <td className='float-end'>{purchaseOrder && purchaseOrder.items && purchaseOrder.items.length > 0 && <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={purchaseOrder.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />}</td>
                                                        {/* <td className='float-end'>{purchaseOrder.total_amount_vc ? (parseFloat(purchaseOrder.total_amount_vc) - (parseFloat(purchaseOrder.adjustment_amount_vc ?? 0.0) + parseFloat(purchaseOrder.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                                    </tr>
                                                    {purchaseOrder && purchaseOrder.tcs_rate_vc && purchaseOrder.tcs_rate_vc > 0 && <tr className="subtotal_bg_color">
                                                        <th>TCS ({purchaseOrder.tcs_type == "Fixed" ? purchaseOrder.currency : null} {purchaseOrder.tcs_rate_vc} {purchaseOrder.tcs_type == "Percentage" ? "%" : null} )</th>
                                                        <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>
                                                        {/* <td className='float-end'>{purchaseOrder.total_amount_vc ? (parseFloat(purchaseOrder.total_amount_vc) - (parseFloat(purchaseOrder.adjustment_amount_vc ?? 0.0) + parseFloat(purchaseOrder.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                                    </tr>}
                                                    {purchaseOrder.tax_slabs && purchaseOrder.tax_slabs.length > 0 && purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'N'
                                                        ? (purchaseOrder.tax_slabs.map((ps, k) => {
                                                            return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                        }))
                                                        : null
                                                    }
                                                    {purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'Y' &&
                                                        purchase_summary.map(ps => {
                                                            return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                        })}
                                                    <tr className="subtotal_bg_color">
                                                        <th>Adjustment</th>
                                                        <td className='float-end'>{purchaseOrder.adjustment_amount_vc ?? 0}</td>
                                                    </tr>
                                                    <tr className="subtotal_bg_color">
                                                        <th>Final Total ({purchaseOrder.currency ? purchaseOrder.currency : "INR"})</th>
                                                        <td className='float-end'>{purchaseOrder.total_amount_vc ? <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={purchaseOrder.total_amount_vc} /> : 0}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="tab_content_wrapper content_heading">Terms & Condition</div>
                                    <table className="table table-hover table-bordered bg-white my-3">
                                        <thead className='table-secondary'>
                                            <tr>
                                                <th className='text-center' style={{ width: "10%" }}>S.No</th>
                                                <th className='text-start' style={{ width: "25%" }}>Name</th>
                                                <th className='text-start' style={{ width: "65%" }}>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchaseOrder.tnc && purchaseOrder.tnc.length > 0 ? purchaseOrder.tnc.map((tc, k) => {
                                                    return (<tr key={k}>
                                                        <td className='text-center'>{k + 1}</td>
                                                        <td>{tc.name}</td>
                                                        <td>{tc.text}</td>
                                                    </tr>);
                                                })
                                                    : <tr ><td className='text-center' colSpan={3}>No Record</td></tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                : null}
                        </div>}

                </div>
            </div>
        </div>)
    }
   

    render() {
        return (<div>{this.reqDetailJsx()}</div>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PurchaseOrderDetailModal);