import React from 'react';
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from '../../../services/HttpAPICall';
import Loader from "../../../components/ui/Loader/Loader";
import { PeriodSelectionWithCustom } from "../../../components/TapUi/index";
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from "../../../services/DownloadFile";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import Status from '../../../components/ui/Status';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetComparisonReport extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.assetFilterFormInitialState = {
            asset_id1           :       '',
            asset_id2           :       '',
            period              :       '',
            date_range_start    :       '',
            date_range_end      :       '',
        };

        this.reportUrl = IAM_API_BASE_URL_2 + '/report/multiple-asset-data';
        this.state = {
            showReport              :       false,
            minimizeTable           :       false,
            form_loading            :       false,
            listing_loading         :       false,
            assetListingData        :       [],
            disableDownloadLdger    :       false,
            reportDownloading       :        false,
            listingMeta             :        null,
            totalListingCount       :        0,
            searchedAsset1          :        null,
            searchedAsset2          :       null,
            assetData1              :       null,
            assetData2              :       null,
            selectedAsset1          :       null,
            selectedAsset2          :       null,
            assetFilterForm         :       { ...this.assetFilterFormInitialState },
            reportData              :   null,                  
        };

    }

    componentDidMount() {
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props);
        }, 1000)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }

    initilaizeAppDataToFilterForm = (props) => {

        const report_name   =   'asset_comparison_report';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        
        let all_periods          =          [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        this.setState({
            reportData              :   reportData.length > 0 ? reportData[0] : null,
            formDataLoaded          :       true,
            all_periods             :       all_periods,
        })
    }

    closeReport = () => {
        this.props.history.goBack()
    }

    pageTitleBarJsx = () => {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCarAlt} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    {this.state.showReport ?
                        <Ax>
                            <button onClick={this.reportDownloadHandler} disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" className="btn btn-secondary">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} className="img-fluid" alt="item-action" /> {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>

                        </Ax> : null}
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }

    reportFilterFormSubmit = (e = null) => {
        e && e.preventDefault();
        this.setState({ showReport: true, listing_loading: true, disableDownloadLdger: true });

        let formData = {
            assets: [this.state.assetFilterForm.asset_id1, this.state.assetFilterForm.asset_id2],
            period: [this.state.assetFilterForm.date_range, this.state.assetFilterForm.date_range],
            date_range_start: this.state.assetFilterForm.date_range_start,
            date_range_end: this.state.assetFilterForm.date_range_end,
            need_keys: ["reading", "expense", "fuel", "productivity", "scs", "ticket", "downtime"],
            action: "data"
        };
        HttpAPICall.withAthorization('GET',this.reportUrl , this.props.access_token, formData, formData, (resp) => {
            this.setState({
                assetListingData: resp.data.data,
                selectedAsset1       :   this.state.searchedAsset1,
                selectedAsset2      :   this.state.searchedAsset2
            })
        }).then(() => this.setState({ listing_loading: false }));

    }

    reportDownloadHandler = () => {
        this.setState({ reportDownloading: true });
        let params = {
            assets: [this.state.assetFilterForm.asset_id1, this.state.assetFilterForm.asset_id2],
            period: [this.state.assetFilterForm.date_range, this.state.assetFilterForm.date_range],
            date_range_start: this.state.assetFilterForm.date_range_start,
            date_range_end: this.state.assetFilterForm.date_range_end,
            need_keys: ["reading", "expense", "fuel", "productivity", "scs", "ticket", "downtime"],
            action : "download"
        };
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, { ...params }, {}, (response) => download.file(response.data.file_path)).then(() => this.setState({ reportDownloading: false }));
    }

    changePer			=	(current = 0, pervious = 0, type = null)	=>	{

		if(current == pervious || pervious == 0 || current == 0 || pervious == null || current == null) {
			return '';	
		}

		let value	=	pervious !== 0 ? (((current - pervious)/pervious) * 100).toFixed(2) : 0;
		return value >= 0 ? value + "%"  : -(value) + "%";
	}

	statusJsx			=	(period1, period2, type = null)	=> 	{

		if(period1 === period2 || period1 === 0 || period2 == 0 || period2 === undefined || period1 === undefined || period1 === null || period2 === null || period1 === false || period2 === false) {
			return "";
		}else{
			return (
				type === 'increase' 
				? 
				<Status color={parseInt(period1) > parseInt(period2) ? "#ec4e4e" : "#2A974E"}></Status> 
				:
				<Status color={parseInt(period1) < parseInt(period2) ? "#ec4e4e" : "#2A974E"}></Status>
			)
		}
	}

    assetCardFormJsx = () => {

        return (<Ax>
            <div className="bg-white px-2">
                {this.state.form_loading
                    ? <Loader />
                    : <form onSubmit={this.reportFilterFormSubmit} className={['py-2', 'filter_form'].join(' ')}>
                        <div className="row align-items-center">
                            <label className="col text-end require">Date Range</label>
                            <div className="col-sm-4">
                                <PeriodSelectionWithCustom
                                    required={true}
                                    menuPlacement={"bottom"}
                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                    value={this.state.assetFilterForm.date_range}
                                    startDate={this.state.assetFilterForm.date_range_start}
                                    endDate={this.state.assetFilterForm.date_range_end}
                                    onSelectPeriod={(period, startDate, endDate) => {
                                        this.setState({
                                            assetFilterForm: { ...this.state.assetFilterForm, 
                                                date_range: period, 
                                                date_range_start: startDate, 
                                                date_range_end: endDate 
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-sm-3">
                                <InputAssetSearch
                                    required={true}
                                    placeholder="Search Asset"
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetFilterForm', 'asset_id1');
                                        this.setState({
                                            searchedAsset1: selectedOption ? selectedOption : null
                                        }, () => {
                                        });
                                    }}
                                    value={this.state.searchedAsset1 ? { ...this.state.searchedAsset1, label: this.state.searchedAsset1.display_label } : null}
                                />
                            </div>
                            <div className="col-sm-3">
                                <InputAssetSearch
                                    required={true}
                                    placeholder="Search Asset"
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'assetFilterForm', 'asset_id2');
                                        this.setState({
                                            searchedAsset2: selectedOption ? selectedOption : null
                                        }, () => {
                                        });
                                    }}
                                    value={this.state.searchedAsset2 ? { ...this.state.searchedAsset2, label: this.state.searchedAsset2.display_label } : null}
                                />
                            </div>

                            <div className="col text-end">
                                <button type="submit" className="btn btn-primary px-3">Show</button>
                            </div>
                        </div>
                    </form>
                }

            </div>
            <div className="bg-white">
                {this.state.listing_loading ? <Loader /> : null}
                {!this.state.listing_loading && this.state.assetListingData && this.state.assetListingData.length > 0
                    && this.reportTableJsx()}

            </div>
        </Ax>)
    }


    reportTableJsx = () => {
        let assetListingData      =   this.state.assetListingData && this.state.assetListingData.length > 0 ? this.state.assetListingData : [];
        let assetData1              =  assetListingData && assetListingData.length > 0 ? assetListingData[0].data : null;
        let assetData2              =  assetListingData && assetListingData.length > 0 ? assetListingData[1].data : null;
        let cl_data1                =   assetData1 && assetData1.cl && assetData1.cl.length > 0 ? assetData1.cl : [];
        let cl_data2                =   assetData2 && assetData2.cl && assetData2.cl.length > 0 ? assetData2.cl : [];
        let fuel_data1              =   assetData1 &&  assetData1.fuel  ? assetData1.fuel : null;
        let fuel_data2              =   assetData2 && assetData2.fuel ? assetData2.fuel : null;
        let expense_data1              =   assetData1 &&  assetData1.expense  ? assetData1.expense : null;
        let expense_data2              =   assetData2 && assetData2.expense ? assetData2.expense : null;
        let asset1                  =   this.state.selectedAsset1 && this.state.selectedAsset1.asset ? this.state.selectedAsset1.asset : null;
        let asset2                  =   this.state.selectedAsset2 && this.state.selectedAsset2.asset ? this.state.selectedAsset2.asset : null;
        const prod_data1	        =	assetData1 && assetData1.productivity && assetData1.productivity.producutvity_data.length > 0 ? assetData1.productivity.producutvity_data : [];
	    const prod_data2	        =	assetData2 && assetData2.productivity && assetData2.productivity.producutvity_data.length > 0 ? assetData2.productivity.producutvity_data : [];
        const production1	        =	assetData1 && assetData1.productivity  ? assetData1.productivity : null;
	    const production2	        =	assetData2 && assetData2.productivity ? assetData2.productivity : null;

        let downtime1	            =   assetData1 && assetData1.downtime  ? assetData1.downtime : null;
	    let downtime2	        	=   assetData2 && assetData2.downtime ? assetData2.downtime : null;
		let downtimeTypes			=	downtime1 ? downtime1 : downtime2;

        const ticket1	        =	assetData1 && assetData1.ticket  ? assetData1.ticket : null;
	    const ticket2	        =	assetData2 && assetData2.ticket ? assetData2.ticket : null;
        const scs1	        =	assetData1 && assetData1.scs  ? assetData1.scs : null;
	    const scs2	        =	assetData2 && assetData2.scs ? assetData2.scs : null;
        let allProds 		=	[...prod_data1,...prod_data2];
        let production_keys	= 	new Set(allProds.map(p => p.key));
        let production_keys_withunits	= [...production_keys].map(pk => allProds.find(p => p.key == pk));
        const task1	        =	assetData1 && assetData1.task && assetData1.task.length > 0  ? assetData1.task : [];
	    const task2         =	assetData2 && assetData2.task && assetData2.task.length > 0  ? assetData2.task : [];
        let task_submodule_available     = this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") ? 'Y' : 'N';
        const totalInProgressCount1 =  task1 && task1.length > 0 ? task1.reduce((total, task) => (task.status === 'In-Progress' ? total + task.count : total), 0) : 0;
        const totalCompleteCount1 =   task1 && task1.length > 0 ? task1.reduce((total, task) => (task.status === 'Complete' ? total + task.count : total), 0) : 0;

        // Calculate total counts for assetData2
        const totalInProgressCount2 = task2 && task2.length > 0 ? task2.reduce((total, task) => (task.status === 'In-Progress' ? total + task.count : total), 0) : 0;
        const totalCompleteCount2 = task2 && task2.length > 0 ? task2.reduce((total, task) => (task.status === 'Complete' ? total + task.count : total), 0) : 0;
        
        let clDataMap1 = cl_data1.reduce((map, obj) => {
            map[obj.key] = obj;
            return map;
          }, {});
        let clDataMap2 = cl_data2.reduce((map, obj) => {
            map[obj.key] = obj;
            return map;
          }, {});
          
          
        let assetClData   =       Object.values([...cl_data1, ...cl_data2].reduce((result, obj) => {
            const key           =       obj.key
            if (!result[key]) {
              result[key] = { key };
            }
            const label1        =       clDataMap1[key] ? clDataMap1[key].label : null;
            const label2        =       obj.label;
            result[key].key        =       obj.key;
            result[key].label   =      label1 === label2 ? label1 : label1 && label2 ? `${label1} / ${label2}` : label1 ? label1 : label2;
            result[key].start1  =       clDataMap1[key] ? clDataMap1[key].start : null;
            result[key].start2  =       clDataMap2[key] ? clDataMap2[key].start : null;
            result[key].unit    =       clDataMap1[key] ? clDataMap1[key].unit : null;
            result[key].running_display1 = clDataMap1[key] ? clDataMap1[key].running_display : null;
            result[key].running_display2 = clDataMap2[key] ? clDataMap2[key].running_display : null;
            result[key].running1         = clDataMap1[key] ? clDataMap1[key].running : null;
            result[key].running2         = clDataMap2[key] ? clDataMap2[key].running : null;
            return result;
          }, {}));
          

        return (<div>
            <table className="table table-bordered table-sm align-middle bg-white my-2">
                <thead className="align-middle table-secondary" style={{ zIndex: '0' }}>
                    <tr>
                        <th style={{ width: "27%" }}>Parameter</th>
                        <th style={{ width: "13%" }}>Units</th>
                        <th style={{ width: "25%" }}>Asset 1</th>
                        <th style={{ width: "25%" }}>Asset 2</th>
                        <th style={{ width: "10%" }}>% Difference</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.listing_loading ? <tr><td colSpan={5} className="text-center"><Loader /></td></tr>
                            : <Ax>
                                <tr>
                                    <td colSpan={2}>Asset Name</td>
                                    <th>{asset1 && asset1.name}</th>
                                    <th>{asset2 && asset2.name}</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Asset Code</td>
                                    <th>{asset1 && asset1.asset_code}</th>
                                    <th>{asset2 && asset2.asset_code}</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Asset Type</td>
                                    <th>{asset1 && asset1.asset_type_name}</th>
                                    <th>{asset2 && asset2.asset_type_name}</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Manufacturer</td>
                                    <th>{asset1 && asset1.manufacturer_name}</th>
                                    <th>{asset2 && asset2.manufacturer_name}</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Current Site</td>
                                    <th>{asset1 && asset1.site}</th>
                                    <th>{asset2 && asset2.site}</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Model</td>
                                    <th>{asset1 && asset1.model_name}</th>
                                    <th>{asset2 && asset2.model_name}</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>Asset Matrix ID</td>
                                    <th>{asset1 && asset1.asset_matrix_unique_id}</th>
                                    <th>{asset2 && asset2.asset_matrix_unique_id}</th>
                                </tr>
                                <tr>
                                    <td colSpan={5}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>Opening Closing Reading</th>
                                </tr>
                                
                                {assetClData && assetClData.length > 0 &&
                                    assetClData.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{data.label}</td>
                                                <td className='text-center'>{data.unit}</td>
                                                <th className='text-center'>{data.running_display1}</th>
                                                <th className='text-center'>{data.running_display2}</th>
                                                <td className='text-center'>{this.statusJsx(data.running1, data.running2, 'increase')}
                                                    {this.changePer(data.running2, data.running1)}</td>
                                            </tr>
                                        );
                                    })
                                }
                                <tr>
                                    <td colSpan={5}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>Fuel</th>
                                </tr>
                                <tr>
                                    <td>Total Fuel</td>
                                    <td className='text-center'>Ltr</td>
                                    <th className='text-center'>{fuel_data1 && fuel_data1.fuel_consume ? fuel_data1.fuel_consume : ''}</th>
                                    <th className='text-center'>{fuel_data2 && fuel_data2.fuel_consume ? fuel_data2.fuel_consume : ''}</th>
                                    <td className='text-center'>{this.statusJsx(fuel_data1?.fuel_consume, fuel_data2?.fuel_consume, 'decrease')}
                                        {this.changePer(fuel_data2?.fuel_consume, fuel_data1?.fuel_consume)}</td>
                                </tr>
                                {((asset1 && asset1.hour_meter == 'yes') || (asset2 && asset2.hour_meter == 'yes')) &&
                                    <Ax>
                                        <tr>
                                            <td>Fuel Consumption</td>
                                            <td className='text-center'>Ltrs / Hr</td>
                                            <th className='text-center'>{fuel_data1 && fuel_data1.fuel_consumption ? fuel_data1.fuel_consumption : ''}</th>
                                            <th className='text-center'>{fuel_data2 && fuel_data2.fuel_consumption ? fuel_data2.fuel_consumption : ''}</th>
                                            <td className='text-center'>{this.statusJsx(fuel_data1?.fuel_consumption, fuel_data2?.fuel_consumption, 'decrease')}
                                                {this.changePer(fuel_data2?.fuel_consumption, fuel_data1?.fuel_consumption)} </td>
                                        </tr>
                                        <tr>
                                            <td>Fuel Consumption Limit</td>
                                            <td className='text-center'>Ltrs / Hr</td>
                                            <th className='text-center'>{fuel_data1 && fuel_data1.approved_fuel_consumption ? fuel_data1.approved_fuel_consumption : ''}</th>
                                            <th className='text-center'>{fuel_data2 && fuel_data2.approved_fuel_consumption ? fuel_data2.approved_fuel_consumption : ''}</th>
                                            <td className='text-center'></td>
                                        </tr>
                                        <tr>
                                            <td>Fuel Consumption Variance</td>
                                            <td className='text-center'>Percentage</td>
                                            <th className='text-center'>{fuel_data1 && fuel_data1.fuel_consumption_variance ? (parseFloat(fuel_data1.fuel_consumption_variance).toFixed(2)) : ''}</th>
                                            <th className='text-center'>{fuel_data2 && fuel_data2.fuel_consumption_variance ? (parseFloat(fuel_data2.fuel_consumption_variance).toFixed(2)) : ''}</th>
                                            <td className='text-center'></td>
                                        </tr>
                                    </Ax>}
                                {((asset1 && asset1.odo_meter == 'yes') || (asset2 && asset2.odo_meter == 'yes')) &&
                                    <Ax>
                                        <tr>
                                            <td>Fuel Economy</td>
                                            <td className='text-center'>KM / Ltr</td>
                                            <th className='text-center'>{fuel_data1 && fuel_data1.fuel_economy_display ? fuel_data1.fuel_economy_display : ''}</th>
                                            <th className='text-center'>{fuel_data2 && fuel_data2.fuel_economy_display ? fuel_data2.fuel_economy_display : ''}</th>
                                            <td className='text-center'>{this.statusJsx(fuel_data1?.fuel_economy, fuel_data2?.fuel_economy, 'increase')}
                                                {this.changePer(fuel_data2?.fuel_economy, fuel_data1?.fuel_economy)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Fuel Economy Limit</td>
                                            <td className='text-center'>KM / Ltr</td>
                                            <th className='text-center'>{fuel_data1 && fuel_data1.approved_fuel_economy ? fuel_data1.approved_fuel_economy : ''}</th>
                                            <th className='text-center'>{fuel_data2 && fuel_data2.approved_fuel_economy ? fuel_data2.approved_fuel_economy : ''}</th>
                                            <td className='text-center'>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Fuel Economy Variance</td>
                                            <td className='text-center'>Percentage</td>
                                            <th className='text-center'>{fuel_data1 && fuel_data1.fuel_economy_variance ? parseFloat(fuel_data1.fuel_economy_variance).toFixed(2) : ''}</th>
                                            <th className='text-center'>{fuel_data2 && fuel_data2.fuel_economy_variance ? parseFloat(fuel_data2.fuel_economy_variance).toFixed(2) : ''}</th>
                                            <td className='text-center'>
                                            </td>
                                        </tr>
                                    </Ax>
                                }
                            </Ax>
                    }
                    <tr>
                        <td colSpan={5}>&nbsp;</td>
                    </tr>
                    <tr><td colSpan={5}><b>Expense</b></td></tr>
                    
                    <tr>
                        <td>Fuel Expense</td>
                        <td className='text-center'>INR</td>
                        <th className='text-center'>{expense_data1 && expense_data1.fuel_expense ? expense_data1.fuel_expense : ''}</th>
                        <th className='text-center'>{expense_data2 && expense_data2.fuel_expense ? expense_data2.fuel_expense : ''}</th>
                        <td className='text-center'>{this.statusJsx(expense_data1?.fuel_expense, expense_data2?.fuel_expense, 'decrease')}
                            {this.changePer(Number(expense_data2?.fuel_expense), Number(expense_data1?.fuel_expense))}
                        </td>
                    </tr>
                    <tr>
                        <td>Asset Expense</td>
                        <td className='text-center'>INR</td>
                        <th className='text-center'>{expense_data1 && expense_data1.asset_expense ? expense_data1.asset_expense : ''}</th>
                        <th className='text-center'>{expense_data2 && expense_data2.asset_expense ? expense_data2.asset_expense : ''}</th>
                        <td className='text-center'>{this.statusJsx(expense_data1?.asset_expense, expense_data1?.asset_expense, 'decrease')}
                            {this.changePer(expense_data2?.asset_expense, expense_data1?.asset_expense)}
                        </td>
                    </tr>
                    <tr>
                        <td>Item Expense</td>
                        <td className='text-center'>INR</td>
                        <th className='text-center'>{expense_data1 && expense_data1 ? expense_data1.item_expense : ''}</th>
                        <th className='text-center'>{expense_data2 && expense_data2.item_expense ? expense_data2.item_expense : ''}</th>
                        <td className='text-center'>{this.statusJsx(expense_data1?.item_expense, expense_data2?.item_expense, 'decrease')}
                            {this.changePer(expense_data2?.item_expense, expense_data1?.item_expense)}
                        </td>
                    </tr>
                    <tr>
                        <td>Rental Expense</td>
                        <td className='text-center'>INR</td>
                        <th className='text-center'>{expense_data1 && expense_data1.rental_expense ? expense_data1.rental_expense : ''}</th>
                        <th className='text-center'>{expense_data2 && expense_data2.rental_expense ? expense_data2.rental_expense : ''}</th>
                        <td className='text-center'>{this.statusJsx(expense_data1?.rental_expense, expense_data2?.rental_expense, 'decrease')}
                            {this.changePer(expense_data2?.rental_expense, expense_data1?.rental_expense)}
                        </td>
                    </tr>
                    <tr>
                        <td>Total Expense</td>
                        <td className='text-center'>INR</td>
                        <th className='text-center'>{expense_data1 && expense_data1.total_expense ? expense_data1.total_expense : ''}</th>
                        <th className='text-center'>{expense_data2 && expense_data2.total_expense ? expense_data2.total_expense : ''}</th>
                        <td className='text-center'>{this.statusJsx(expense_data1?.total_expense, expense_data2?.total_expense, 'decrease')}
                            {this.changePer(expense_data2?.total_expense, expense_data1?.total_expense)}
                        </td>
                    </tr>
                    {((asset1 && asset1.hour_meter == 'yes') || (asset2 && asset2.hour_meter == 'yes')) &&
                        <tr>
                            <td>Expense Per Hr</td>
                            <td className='text-center'>INR</td>
                            <th className='text-center'>{expense_data1 && expense_data1.expense_per_hr ? expense_data1.expense_per_hr : ''}</th>
                            <th className='text-center'>{expense_data2 && expense_data2.expense_per_hr ? expense_data2.expense_per_hr : ''}</th>
                            <td className='text-center'>{this.statusJsx(expense_data1?.expense_per_hr, expense_data2?.expense_per_hr, 'decrease')}
                                {this.changePer(expense_data2?.expense_per_hr, expense_data1?.expense_per_hr)}
                            </td>
                        </tr>
                    }
                    {((asset1 && asset1.odo_meter == 'yes') || (asset2 && asset2.odo_meter == 'yes')) && <tr>
                        <td>Expense Per KM</td>
                        <td className='text-center'>INR</td>
                        <th className='text-center'>{expense_data1 && expense_data1.expense_per_km ? expense_data1.expense_per_km : ''}</th>
                        <th className='text-center'>{expense_data2 && expense_data2.expense_per_km ? expense_data2.expense_per_km : ''}</th>
                        <td className='text-center'>{this.statusJsx(expense_data1?.expense_per_km, expense_data2?.expense_per_km, 'decrease')}
                            {this.changePer(expense_data2?.expense_per_km, expense_data1?.expense_per_km)}
                        </td>
                    </tr>
                    }

                    {task_submodule_available == 'Y' && <Ax>
                        <tr>
                            <td colSpan={5}>&nbsp;</td>
                        </tr>
                        <tr><td colSpan={5}><b>Task</b></td></tr>
                       
                        {task1 && task1.length > 0 && task1.map((td, key) => {
                            return (<tr key={key}>
                                <td>{td.status}</td>
                                <td></td>
                                <th className='text-center'>{td.count}</th>
                                <th className='text-center'>{task2 && task2.length > 0 ? task2[key].count : ""}</th>
                                <td className='text-center'>{this.statusJsx(td.count, task2[key]?.count, 'increase')}
                                    {this.changePer(task2[key]?.count, td.count)}
                                </td>
                            </tr>)
                        })}
                        <tr>
                                <td>Total</td>
                                <td></td>
                                <th className='text-center'>{totalInProgressCount1 + totalCompleteCount1 }</th>
                                <th className='text-center'>{totalCompleteCount2 + totalInProgressCount2}</th>
                                <td className='text-center'></td>
                            </tr>
                    </Ax>}
                    <tr>
                        <td colSpan={5}>&nbsp;</td>
                    </tr>
                    <tr><td colSpan={5}><b>Productivity</b></td></tr>
                   
                    {production_keys_withunits && production_keys_withunits.length > 0 &&
                        production_keys_withunits.map((val, i) => {
                            let production_val1 = prod_data1 && prod_data1.length > 0 ? (prod_data1.find(p => p.key == val.key)?.production) : '';

                            let production_val2 = prod_data2 && prod_data2.length > 0 ? (prod_data2.find(p => p.key == val.key)?.production) : '';
                            return (
                                <tr key={i}>
                                    <td>{val.display}</td>
                                    <td className='text-center'>{val.uom}</td>
                                    <th className='text-center'>{production_val1}</th>
                                    <th className='text-center'>{production_val2}</th>
                                    <td className='text-center'>{production_val1 && production_val2 && this.statusJsx(production_val1, production_val2, 'increase')}
                                        {production_val1 && production_val2 && this.changePer(!production_val2 ? 0 : Number(production_val2), production_val1 ? Number(production_val1) : 0)}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td>Total Productivity</td>
                        <td className='text-center'></td>
                        <th className='text-center'>{production1 && production1.total_producutvity ? production1.total_producutvity : ''}</th>
                        <th className='text-center'>{production2 && production2.total_producutvity ? production2.total_producutvity : ''}</th>
                        <td className='text-center'>{this.statusJsx(production1?.total_producutvity, production2?.total_producutvity, 'increase')}
                            {this.changePer(production2?.total_producutvity, production1?.total_producutvity)}
                        </td>
                    </tr>
                    {((asset1 && asset1.hour_meter == 'yes') || (asset2 && asset2.hour_meter == 'yes')) &&
                        <tr>
                            <td>Productivity per Hr</td>
                            <td className='text-center'></td>
                            <th className='text-center'>{production1 && production1.productivity_per_hr ? production1.productivity_per_hr : ''}</th>
                            <th className='text-center'>{production2 && production2.productivity_per_hr ? production2.productivity_per_hr : ''}</th>
                            <td className='text-center'>
                                {this.statusJsx(production1?.productivity_per_hr, production2?.productivity_per_hr, 'increase')}
                                {this.changePer(production2?.productivity_per_hr, production1?.productivity_per_hr)}
                            </td>
                        </tr>
                    }
                    {((asset1 && asset1.odo_meter == 'yes') || (asset2 && asset2.odo_meter == 'yes')) &&
                        <tr>
                            <td>Productivity per KM</td>
                            <td className='text-center'></td>
                            <th className='text-center'>{production1 && production1.productivity_per_km ? production1.productivity_per_km : ''}</th>
                            <th className='text-center'>{production2 && production2.productivity_per_km ? production2.productivity_per_km : ''}</th>
                            <td className='text-center'>{this.statusJsx(production1?.productivity_per_km, production2?.productivity_per_km, 'increase')}
                                {this.changePer(production2?.productivity_per_km, production1?.productivity_per_km)}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td>Productivity per Ltr</td>
                        <td className='text-center'></td>
                        <th className='text-center'>{production1 && production1.productivity_per_ltr}</th>
                        <th className='text-center'>{production2 && production2?.productivity_per_ltr}</th>
                        <td className='text-center'>
                            {this.statusJsx(production1?.productivity_per_ltr, production2?.productivity_per_ltr, 'increase')}
                            {this.changePer(production2?.productivity_per_ltr, production1?.productivity_per_ltr)}
                        </td>
                    </tr>
                    <tr>
                        <td>Ltr per Productivity</td>
                        <td className='text-center'></td>
                        <th className='text-center'>{production1 && production1?.ltr_per_productivity}</th>
                        <th className='text-center'>{production2 && production2?.ltr_per_productivity}</th>
                        <td className='text-center'>
                            {this.statusJsx(production1?.ltr_per_productivity, production2?.ltr_per_productivity, 'decrease')}
                            {this.changePer(production2?.ltr_per_productivity, production1?.ltr_per_productivity)}
                        </td>
                    </tr>
                    <tr>
                        <td>Expense per Productivity</td>
                        <td className='text-center'></td>
                        <th className='text-center'>{production1 && production1?.expense_per_productivity}</th>
                        <th className='text-center'>{production2 && production2?.expense_per_productivity}</th>
                        <td className='text-center'>
                            {this.statusJsx(production1?.expense_per_productivity, production2?.expense_per_productivity, 'decrease')}
                            {this.changePer(production2?.expense_per_productivity, production1?.expense_per_productivity)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>&nbsp;</td>
                    </tr>
                    <tr><td colSpan={5}><b>Downtime</b></td></tr>
                    {downtimeTypes && downtimeTypes.length > 0 && downtimeTypes.map((dt,k) => {
                        let downtime1_dt	=	downtime1.find(d =>d.key == dt.key);
                        let downtime2_dt	=	downtime2.find(d =>d.key == dt.key);
                        return <tr key={k}>
                            <td>{dt.name}</td>
                            <td className='text-center'>Hours</td>
                            <th className='text-center'>{downtime1_dt.downtime}</th>
                            <th className='text-center'>{downtime2_dt.downtime}</th>
                            <td className='text-center'>
                                {this.statusJsx(downtime1_dt.downtime_seconds, downtime2_dt.downtime_seconds, 'decrease')}
                                {this.changePer(downtime2_dt.downtime_seconds, downtime1_dt.downtime_seconds)}
                            </td>
                        </tr>
                    })}
                    <tr>
                        <td colSpan={5}>&nbsp;</td>
                    </tr>
                    <tr><td colSpan={5}><b>Tickets</b></td></tr>
                    
                    <tr>
                        <td>Tickets Created</td>
                        <td className='text-center'>Count</td>
                        <th className='text-center'>{ticket1 && ticket1.ticket_created_count ? ticket1.ticket_created_count : ''}</th>
                        <th className='text-center'>{ticket2 && ticket2.ticket_created_count ? ticket2.ticket_created_count : ''}</th>
                        <td className='text-center'>
                            {this.statusJsx(ticket1?.ticket_created_count, ticket2?.ticket_created_count, 'increase')}
                            {this.changePer(ticket2?.ticket_created_count, ticket1?.ticket_created_count)}
                        </td>
                    </tr>
                    <tr>
                        <td>Tickets Closed</td>
                        <td className='text-center'>Count</td>
                        <th className='text-center'>{ticket1 && ticket1.ticket_closer_count ? ticket1.ticket_closer_count : ''}</th>
                        <th className='text-center'>{ticket2 && ticket2.ticket_closer_count ? ticket2.ticket_closer_count : ''}</th>
                        <td className='text-center'>
                            {this.statusJsx(ticket1?.ticket_closer_count, ticket2?.ticket_closer_count, 'increase')}
                            {this.changePer(ticket2?.ticket_closer_count, ticket1?.ticket_closer_count)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>&nbsp;</td>
                    </tr>
                    <tr><td colSpan={5}><b>Service Checksheet</b></td></tr>
                    
                    <tr>
                        <td>SCS Executed</td>
                        <td className='text-center'>Count</td>
                        <th className='text-center'>{scs1 && scs1.scs_executed ? scs1.scs_executed : ''}</th>
                        <th className='text-center'>{scs1 && scs1.scs_executed ? scs1.scs_executed : ''}</th>
                        <td className='text-center'>{this.statusJsx(scs1?.scs_executed, scs2?.scs_executed, 'increase')}
                            {this.changePer(scs2?.scs_executed, scs1?.scs_executed)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>&nbsp;</td>
                    </tr>
                </tbody>
            </table>

        </div>)
    }

    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Asset Comparison - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className=" pageTbl col-12 pb-2" >
                        {this.assetCardFormJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        group_permission        :   state.app.acl_info,
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetComparisonReport);