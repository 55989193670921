import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import TapApiUrls, { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';



class FuelIssued extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.fuelStationInitState       =        {
            transaction_date            :        moment(new Date()).format('YYYY-MM-DD'),
            station_id                   :       '',
            fuel_type                   :       '',
            fuel_qty                    :       '',
            unit_rate                   :       '',
            debitable                    :       'N',
            amount                      :       '',
            transfer_to                 :        '',
            transfer_to_other           :       '',
            reference_num               :        '',
            remarks                     :        '',
        }

        this.state                  =     {
            saveFormSubmitting              :   false,
            form_data_loading               :   false,
            allFuelStationList              :   [],
            allReceiversList                :   [],
            allFuelTypeList                 :   [],
            issuedFuelStationForm           :   {...this.fuelStationInitState},
            newReceiver                     :   'N',
            issueFuelStationData            :   null,
            runtime_fuel_receiver_creation  :   'N'
        };
        this.logIssueFuelModalId    =   uuid();

        this.stationUrl             =   IAM_API_BASE_URL_2 + '/station';
    }

    componentDidMount() {
        this.uploadFuelIssueModal         =   new Modal(document.getElementById(this.logIssueFuelModalId), {keyboard: false, backdrop :false});  
    }

    initalizeFormData       =   (station_id,data = null,fuel_type = null)      =>  {
     
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.stationUrl + '/fuel_issue_form_data',
        this.props.access_token, null, null, (response) => {
            let respData = response.data;
            this.setState({
                issuedFuelStationForm           :   {...this.fuelStationInitState},
                allFuelStationList              :   respData && respData.stations &&  respData.stations.length > 0  ? respData.stations.map((s) => { return {value: s.id, label: `${s.fuelstation_name}`,fuel_types : s.fuels_prices}}) : [],
                allReceiversList                :   respData && respData.receivers && respData.receivers.length > 0 ? respData.receivers.map((s) => { return {value: s.id, label: `${s.name}`}}) : [],
                runtime_fuel_receiver_creation  :   respData && respData.runtime_fuel_receiver_creation ? respData.runtime_fuel_receiver_creation : 'N',
             },() => {
                if(station_id && data == null){
                    let selectedFuelType  = this.state.allFuelStationList && this.state.allFuelStationList.length > 0 ? this.state.allFuelStationList.find(fs => {return(fs.value == station_id)}) : null;
                        this.setState({
                            allFuelTypeList     :       selectedFuelType && selectedFuelType.fuel_types && selectedFuelType.fuel_types.length > 0 ? selectedFuelType.fuel_types.map(s => {return({value:s.fueltype_id,label : s.fuel_type,fuel_price : s.fuel_price })}) : [],
                        },() => {
                            this.setState({
                                issuedFuelStationForm       :       {
                                    ...this.fuelStationInitState,
                                    station_id      :       station_id,
                                    fuel_type       :       fuel_type ? fuel_type : ''
                                },
                                newReceiver           :      'N',
                                issueFuelStationData    :   null,
                                
                            })
                        })    
                            
                }

                if(data){
                    let selectedFuelType  = this.state.allFuelStationList && this.state.allFuelStationList.length > 0 ? this.state.allFuelStationList.find(fs => {return(fs.value == data.fuel_station_id)}) : null;
                  
                     this.setState({
                        newReceiver           :      'N',
                        allFuelTypeList     :       selectedFuelType && selectedFuelType.fuel_types && selectedFuelType.fuel_types.length > 0 ? selectedFuelType.fuel_types.map(s => {return({value:s.fueltype_id,label : s.fuel_type,fuel_price : s.fuel_price })}) : [],
                    },() => {
                        this.setState({
                            issuedFuelStationForm       :       {
                                station_id                   :       data.fuel_station_id,
                                transaction_date            :       data.transaction_date_Ymd ,
                                fuel_type                   :       data.fuel_type_id,
                                fuel_qty                    :       data.fuel_qty,
                                unit_rate                   :       data.rate,
                                debitable                    :      data.debitable ? data.debitable :    'N',
                                amount                      :       data.amount,
                                transfer_to                 :       data.transfer_to ,
                                transfer_to_other           :       '',
                                reference_num               :       data.reference_num,
                                remarks                     :        data.remarks,
                                ledger_id                   :       data.id
                            },
                            issueFuelStationData            :       data
                        })
                    })     
                    
                }
             })
        }).then(() => this.setState({form_data_loading: false}));
    }

    issuedFuelModalInit         =   (station_id,fuel_type = null)  =>  {
        this.uploadFuelIssueModal.show()
        this.initalizeFormData(station_id,null,fuel_type)
    }

    editIssuedFuelModalInit         =   (station_id,data = null)  =>  {
        this.uploadFuelIssueModal.show()
        this.initalizeFormData(station_id,data)
    }

    getSelectedStationFuelType      =   (selectedOption)        =>      {
        if(selectedOption && selectedOption.length > 0){
            this.setState({
                allFuelTypeList : selectedOption && selectedOption.length > 0 ? selectedOption.map(s => {return({value:s.fueltype_id,label : s.fuel_type,fuel_price : s.price })}) : [],
                issuedFuelStationForm       :       {
                    ...this.state.issuedFuelStationForm,
                    unit_rate               :       '',
                    fuel_type               :           ''
                }
            });
        }else{
            this.setState({allFuelTypeList : []})
        }
    }

    submitIssueFuelStationForm    =   (e)     =>      {
        e.preventDefault();
       if(this.state.issueFuelStationData){
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', this.stationUrl + '/fuel_issue' , this.props.access_token, {}, {...this.state.issuedFuelStationForm ,amount: parseFloat(this.state.issuedFuelStationForm.amount).toFixed(2)}, (resp) => {
            this.afterSubmitIssuedFuelHandler(resp);
       }).then(() => this.setState({ saveFormSubmitting: false }));
       }else{
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('POST', this.stationUrl + '/fuel_issue' , this.props.access_token, {}, {...this.state.issuedFuelStationForm ,amount: parseFloat(this.state.issuedFuelStationForm.amount).toFixed(2)}, (resp) => {
            this.afterSubmitIssuedFuelHandler(resp);
       }).then(() => this.setState({ saveFormSubmitting: false }));
       }
    }

    afterSubmitIssuedFuelHandler  =   (response)  =>  {
        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
        if(this.props.afterSubmitIssuedFuel){
            this.props.afterSubmitIssuedFuel();
            this.setState({
                issuedFuelStationForm  : {...this.fuelStationInitState,
                    issueFuelStationData    :       null,
                }})
        }
        this.uploadFuelIssueModal.hide();
    }

    saveFuelStationFormJsx         =        ()       =>      {
        return (<form className="bg-white" onSubmit={this.submitIssueFuelStationForm} id="saveIssueFuelStationForm">
            {this.state.form_data_loading
                ? <Loader />
                : <Ax>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Date of Fuel Issue</label>

                        <div className="col-sm-7  add_calender_section">
                            <DatePicker
                                selected={
                                    this.state.issuedFuelStationForm.transaction_date
                                        ? moment(this.state.issuedFuelStationForm.transaction_date, 'YYYY-MM-DD').toDate()
                                        : false
                                }
                                name="transaction_date"
                                onChange={(value, event) => this.formDateHandler('transaction_date', value, 'issuedFuelStationForm')}
                                maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                dateFormat="dd-MMM-yyyy"
                                className={"form-control form-control-sm"}
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                required={true}
                                placeholderText={`Please Enter Date of  Fuel Receive`}

                            />

                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="purchase_date" className="add_calender-icon" />
                        </div>

                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Fuel Station</label>
                        <div className="col-sm-7 ">
                            <TapSelect
                                options={this.state.allFuelStationList}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'issuedFuelStationForm', 'station_id', () => this.getSelectedStationFuelType(selectedOption?.fuel_types));
                                }}
                                isSearchable={true}
                                value={this.state.allFuelStationList.find(s => this.state.issuedFuelStationForm.station_id == s.value)}
                                isClearable={true}
                                placeholder="Select Fuel Station"
                                autoCompelete="off"
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Fuel Type</label>

                        <div className="col-sm-7 ">
                            <TapSelect
                                options={this.state.allFuelTypeList}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'issuedFuelStationForm', 'fuel_type',() => {
                                        this.setState({
                                            issuedFuelStationForm      :   {
                                                ...this.state.issuedFuelStationForm,
                                                unit_rate       :       selectedOption && selectedOption.fuel_price ? selectedOption.fuel_price : ''
                                            } 
                                        })
                                     });

                                }}
                                isSearchable={true}
                                value={this.state.allFuelTypeList.find(s => this.state.issuedFuelStationForm.fuel_type == s.value)}
                                isClearable={true}
                                placeholder="Select Fuel Type"
                                autoCompelete="off"
                                required={true}
                                containerHeight="30px"
                                fontSize="93%"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Fuel Receiver</label>

                        <div className="col-sm-7 ">
                            {this.state.newReceiver == "Y"
                                ? <input
                                    name="transfer_to_other"
                                    type="text"
                                    value={this.state.issuedFuelStationForm.transfer_to_other}
                                    className="form-control form-control-sm"
                                    autoComplete="off"
                                    onChange={(e) => this.formInputHandler(e, "issuedFuelStationForm")}
                                    placeholder="Please enter Received Vendor"

                                />
                                :
                                <TapSelect
                                    options={this.state.allReceiversList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'issuedFuelStationForm', 'transfer_to');

                                    }}
                                    isSearchable={true}
                                    value={this.state.allReceiversList.find(s => this.state.issuedFuelStationForm.transfer_to == s.value)}
                                    isClearable={true}
                                    placeholder="Select Received from "
                                    autoCompelete="off"
                                    containerHeight="30px"
                                    fontSize="93%"
                                />}

                        </div>
                        {
                            this.state.runtime_fuel_receiver_creation === "Y" &&
                            <div className="col-sm-2">
                                <input
                                    name="newReceiver"
                                    type="checkbox"
                                    value={this.state.newReceiver}
                                    onChange={(e) => {
                                        this.setState({
                                            newReceiver: this.state.newReceiver == 'N' ? 'Y' : 'N',
                                            issuedFuelStationForm : this.state.newReceiver == 'N' ? { ...this.state.issuedFuelStationForm , transfer_to_other: '' } : { ...this.state.issuedFuelStationForm  }
                                        })
                                    }}
                                    checked={this.state.newReceiver == 'Y'}
                                    className="form-check-input"
                                    id="newReceiver"
                                />
                                <label className="form-check-label text-sm mx-2" htmlFor="newReceiver">New Fuel Receiver</label>
                            </div>
                        }
                    </div>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Quantity</label>

                        <div className="col-sm-3 ">
                            <input
                                name="fuel_qty"
                                value={this.state.issuedFuelStationForm.fuel_qty}
                                onChange={(e) => this.formInputHandler(e, "issuedFuelStationForm",null,true)}
                                className="form-control form-control-sm"
                                type="number"
                                autoComplete="off"
                                placeholder="Enter Fuel Quantity"
                                required={true}
                                onKeyUp={(e) => {
                                    if (this.state.issuedFuelStationForm  && this.state.issuedFuelStationForm.fuel_qty > 0 && this.state.issuedFuelStationForm.unit_rate && this.state.issuedFuelStationForm.unit_rate > 0) {
                                        let amount = this.state.issuedFuelStationForm.unit_rate * this.state.issuedFuelStationForm.fuel_qty;
                                        this.setState({ issuedFuelStationForm : { ...this.state.issuedFuelStationForm , amount: parseFloat(amount).toFixed(2) } })
                                    }
                                }}
                            />
                        </div>
                        <label className="col-sm-1 col-form-label col-form-label-sm require">Unit Rate</label>
                        <div className="col-sm-3 ">
                            <input
                                name="unit_rate"
                                value={this.state.issuedFuelStationForm.unit_rate}
                                onChange={(e) => this.formInputHandler(e, "issuedFuelStationForm",null,true)}
                                className="form-control form-control-sm"
                                type="number"
                                autoComplete="off"
                                placeholder="Enter Fuel Rate"
                                required={true}
                                onKeyUp={(e) => {
                                    if (this.state.issuedFuelStationForm  && this.state.issuedFuelStationForm.fuel_qty > 0 && this.state.issuedFuelStationForm.unit_rate && this.state.issuedFuelStationForm.unit_rate > 0) {
                                        let amount = this.state.issuedFuelStationForm.unit_rate * this.state.issuedFuelStationForm.fuel_qty;
                                        this.setState({ issuedFuelStationForm : { ...this.state.issuedFuelStationForm , amount: parseFloat(amount).toFixed(2) } })
                                    }
                                }}
                            />
                        </div>
                       
                    </div>

                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm require">Amount</label>

                        <div className="col-sm-7 ">
                            <input
                                name="amount"
                                value={this.state.issuedFuelStationForm.amount}
                                onChange={(e) => this.formInputHandler(e, "issuedFuelStationForm",null,true)}
                                className="form-control form-control-sm"
                                type="number"
                                required={true}
                                autoComplete="off"
                                placeholder="Enter Fuel Amount"
                                onKeyUp={(e) => {
                                    if (this.state.issuedFuelStationForm.amount && this.state.issuedFuelStationForm.amount > 0 && this.state.issuedFuelStationForm.unit_rate && this.state.issuedFuelStationForm.unit_rate > 0) {
                                        let quantity = this.state.issuedFuelStationForm.amount / this.state.issuedFuelStationForm.unit_rate;
                                        this.setState({ issuedFuelStationForm : { ...this.state.issuedFuelStationForm , fuel_qty: parseFloat(quantity).toFixed(2) } })
                                    }
                                }}
                            />

                        </div>



                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Reference Number</label>

                        <div className="col-sm-7 ">
                            <input
                                name="reference_num"
                                type="text"
                                value={this.state.issuedFuelStationForm.reference_num}
                                className="form-control form-control-sm"
                                autoComplete="off"
                                onChange={(e) => this.formInputHandler(e, "issuedFuelStationForm")}
                                placeholder="Please enter Reference Number"
                            />
                        </div>
                    </div>
                   
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Remarks</label>

                        <div className="col-sm-7 ">
                            <textarea
                                name="remarks"
                                placeholder='Enter remarks'
                                className='form-control'
                                value={this.state.issuedFuelStationForm.remarks}
                                onChange={(e) => this.formInputHandler(e, "issuedFuelStationForm")}
                                style={{ height: "65px", width: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-2">
                        <label className="col-sm-3 col-form-label col-form-label-sm">Fuel Issue is Debitable ?</label>

                        <div className="col-sm-7 ">
                        <input
                                name="debitable"
                                type="checkbox"
                                value={this.state.issuedFuelStationForm.debitable}
                                onChange={(e) => {
                                    this.setState({
                                        issuedFuelStationForm : this.state.issuedFuelStationForm.debitable == 'N' ? { ...this.state.issuedFuelStationForm , debitable: 'Y' } : { ...this.state.issuedFuelStationForm , debitable: 'N' }
                                    })
                                }}
                                checked={this.state.issuedFuelStationForm.debitable == 'Y'}
                                className="form-check-input"
                                id="debitable"
                            />
                            <label className="form-check-label text-sm mx-2" htmlFor="debitable">Yes </label>
                        </div>
                    </div>
                    

                    <div className="modal-footer">
                        <button type="button" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-secondary mx-3" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>

                </Ax>
            }
        </form>);
    }


    render              =   ()  =>  {
        return (<div className="modal fade" id={this.logIssueFuelModalId} tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>{this.state. issueFuelStationData ? "Edit" : "Add"} Issued Fuel</span>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div className="modal-body">
                        {this.saveFuelStationFormJsx()}
                    </div>
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(FuelIssued);