import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';

import AssetMiniDetail from '../../includes/AssetMiniDetail';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import ItemSearchModal from '../../items/itemSearchModal';
import axios from 'axios';
import InputTicketSearch from '../../includes/ui/InputTicketSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import DateService from '../../../services/DateService';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import WarehouseAddress from '../../inventory/includes/WarehouseAddress';
import ConsumptionHistoryModal from '../../inventory/consumption/ConsumptionHistoryModal';
import Customer from '../includes/Customer';
import InputDealSearch from '../includes/InputDealSearch';
import NumberFormatter from '../../../services/NumberFormater';

class SalesInvoiceAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initInvoiceForm = {
            transaction_date: '',
            warehouse_id: '',
            invoice_type: '',
            workstation_id: '',
            asset_id: '',
            voucher_number: '',
            voucher_date: '',
            items: [],
            notes: '',
            adjustment_amount: 0,
            sub_assembly_id: '',
            employee_id: '',
            tcs_rate:   '',
            tcs_type:   "Percentage",
            currency:   "INR",
            currency_display :  '',

        }

        this.inhitInvoiceItemRow = {
            allocation_item_id : null,
            so_item_id: null,
            item_id : null,
            qty: 0,

            allocation_transaction_id: null,
            so_transaction_id: null,
            stock_loading: false,
            batch_number: null,
            expiry_date: null,
            total_qty   :   0,
            free_qty    :   0,

            item: null,
            invoice_item_id: null,
            remark: '',
            amount: 0,
            
            gst: 0,
            rate: 0,
            current_stocks: [],
            selected_stock: null,
            selected_allocation: null,
            allocation_list: [],
            so_list: [],
            allApiCallsCompleted: false,

            discount_rate:   0,
            discount_type:   'Percentage'
        }

        this.inhitInvoiceItemRows = [this.inhitInvoiceItemRow];

        this.initalState = {
            invoiceFormDataLoaded: false,
            allWarehouses: [],
            allGstSlabs: [],
            allStatus: [],
            isc_configuration: null,
            track_batch_expiry: 'N',
            negative_stock: 'N',
            invoiceForm: this.initInvoiceForm,
            saveFormSubmitting: false,
            invoiceDetail: null,
            selectedSite: null,
            warehouseDetailLoading: false,
            warehouseDetail: null,
            warehouseSites: [],
            selectedAsset: null,
            searchedWorkstation: null,
            allDiscountType:[],
            searchedDeal: null,
            invoiceItemRows: [...this.inhitInvoiceItemRows],
            itemRowNumber: 0,
            cloneConsumption: false,
            consumtionHistoryList: [],
            invoiceDetailLoading: false,
            only_stocked_item: 'Y',
            update_item_invoice_price: 'Y',
            searchedEmployee: null,
            pendingAllocationListLoaded: false,
            pending_item_loading: false,
            allPendingAllocationList: [],
            allAllocatedItemIds: [],
            addInvoiceFromPurchase: false,
            selectedTicket: null,
            ticketFromInvoice: false,
            ticketLinkedFromAllocation: false,
            sales_enable_tcs: 'Y',
            tcs_applicable  : 'Y',
            buttonDisabled: false,
            searchSubassembly: null,
            disableForTicket: false,
            ticketFromTicket: false,
            allInvoiceFor: [],
            allTCSType : [],
            include_item_sales_order: 'Y',
            searchedWorkstation: null,
            showItemSearchModal: false,
            showItemAddModal: false,
            showItemEditModal: false,
            selectedCustomerAdress: null,
            CustomerAddressList: [],
            searchedCustomer: null,
            searchedCustomerAdressList: [],
            selectedShippingAddress: null,
            selectedBillingAddress: null,
            CustomerDetails: null,
            Customer_details_loading: false,
            allItemCurrentStocks: { data: {} },
            choose_batch_number: true,
            sales_order: "sales_work_order",
            sales_order_list: [],
            allSalesOrder: [],
            selectedSalesOrder: false,
            savePickItemSubmitting : false,
            selectedItemDetails :   null,
            selectedItemRow     :   null,
        }

        this.state = {
            ...this.initalState
        }
        this.addItemModalRef = React.createRef();
        this.editItemModalRef = React.createRef();
        this.invoiceHistoryRef = React.createRef();
        this.itemSearchRef = React.createRef();
        this.itemDetailsRef = React.createRef();
        this.CustomerUrl = INVENTORY_API_BASE_URL_2 + '/customer'
        this.FormData_URL = INVENTORY_API_BASE_URL_2 + '/sales/invoice_form_data'
        this.INVOICE_URL = INVENTORY_API_BASE_URL_2 + '/sales/invoice';
        this.itemCurrentStockUrl = INVENTORY_API_BASE_URL_2 + '/item_current_stock';
        this.itemDetailUrl = INVENTORY_API_BASE_URL_2 + '/item';
        this.warehouseUrl = ISC_API_BASE_URL_2 + '/warehouse';
        this.salesOrderListUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/list';
        this.salesOrderDetailUrl = INVENTORY_API_BASE_URL_2 + '/sales_order/detail';

    }

    componentDidMount() {

        this.viewItemModal = new Modal(document.getElementById('viewItemModal'), { keyboard: false, backdrop: false });
        this.pickAllocationItemModal = new Modal(document.getElementById('pickAllocationItemModal'), { keyboard: false, backdrop: false });
        this.batchListingModal = new Modal(document.getElementById('batchListingModal'), { keyboard: false, backdrop: false });
        setTimeout(() => {
            this.initilaizeFormData(this.props);
        }, 1000);

    }

    getCustomerDetails = (id, edit = false) => {
        if (id) {
            this.setState({ Customer_details_loading: true })
            HttpAPICall.withAthorization('GET', this.CustomerUrl + '/' + id, this.props.access_token, null, null, (response) => {
                let selectedCustomerAdress = response.data.data && response.data.data.addressList ? response.data.data.addressList[0] : [];
                this.setState({
                    CustomerDetails: response.data.data,
                    CustomerAddressList: response.data.data.addressList,
                    selectedCustomerAdress: edit == false ? selectedCustomerAdress : this.state.selectedCustomerAdress,
                    selectedBillingAddress: edit == false ? selectedCustomerAdress : this.state.selectedBillingAddress,
                    selectedShippingAddress: edit == false ? selectedCustomerAdress : this.state.selectedShippingAddress,
                });

            }).then(() => {
                this.setState({ Customer_details_loading: false });
                this.setState({ gstTypeName: this.state.selectedBillingAddress && this.state.warehouseDetail && this.state.selectedBillingAddress.state_id == this.state.warehouseDetail.state_id ? 'GST' : 'IGST' });
            });
        }
    }

    handleCustomerChange = (selectedOption, edit = false) => {
        this.setState({
            invoiceItemRows: [...this.inhitInvoiceItemRows],
            allPendingAllocationList: [],
            invoiceForm: {
                ...this.state.invoiceForm,
                customer_id: selectedOption ? selectedOption.value : null,

            },
            CustomerDetails: selectedOption ? selectedOption : null,
            selectedProject: edit == true ? this.state.selectedProject : null,
            searchedCustomerAdressList: selectedOption && selectedOption.Customer ? selectedOption.Customer.addressList : null,
            selectedCustomerAdress: selectedOption && selectedOption.Customer && edit == false ? selectedOption.Customer.addressList[0] : this.state.selectedCustomerAdress,
            searchedCustomer: selectedOption ? selectedOption : null,
            selectedBillingAddress: selectedOption && selectedOption.Customer && edit == false ? selectedOption.Customer.addressList[0] : this.state.selectedBillingAddress,
            selectedShippingAddress: selectedOption && selectedOption.Customer && edit == false ? selectedOption.Customer.addressList[0] : this.state.selectedShippingAddress,

        }, () => {
            if (selectedOption?.value) {
                const params = {
                    // search_status_levels: [3, 4],
                    // more_than_pending_qty: 0,
                    per_page: 100,
                    page: 1,
                    search_customer_ids: [selectedOption.value]
                }
                HttpAPICall.withAthorization('GET', this.salesOrderListUrl, this.props.access_token, params, {}, (resp) => {
                    let soData = resp.data.data;
                    this.setState({
                        sales_order_list: soData && soData.map((so) => ({ label: so.transaction_id, value: so.transaction_id }))
                    });
                })
            }
        })
    };
    initilaizeFormData = (pr) => {
        let sites = pr.iam_group_sites;
        // this.setState({
        //     allAllocatedItemIds : []
        // })
        let invCurrency =   pr && pr.default_currency  ? pr.default_currency : 'INR';
        if (!this.state.invoiceFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.FormData_URL, pr.access_token, null, null, (response) => {
                let formData = response.data;
                this.setState({
                    allDiscountType     :   formData && formData.discount_type ? formData.discount_type.map((s) => { return {value: s, label: s === "Fixed" ? invCurrency : "%"}; }) : [],
                    invoiceFormDataLoaded: formData ? true : false,
                    allInvoiceFor: [{ value: 'asset', label: 'Asset' }, { value: 'workstation', label: 'Workstation' }, { value: 'employee', label: 'Employee' }, { value: 'sub_assembly', label: 'Sub Assembly' }],
                    allSalesOrder: formData && formData.sales_order_types && formData.sales_order_types.length > 0 ? formData.sales_order_types.map((s) => { return { value: s.key, label: s.name, type_data: s } }) : [],
                    allWarehouses: pr.all_warehouses && pr.all_warehouses.length > 0 ? pr.all_warehouses.map((s) => { return { value: s.id, label: `${s.name} (${s.code})`, site_id: s.site_ids }; }) : [],

                    allGstSlabs: formData && formData.gst_slabs ? formData.gst_slabs.map((s) => { return { value: s, label: s + '%' } }) : [],

                    allStatus: formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return { value: s.id, label: s.name }; }) : [],

                    track_batch_expiry: pr.isc_configuration && pr.isc_configuration.track_batch_expiry ? pr.isc_configuration.track_batch_expiry : 'N',

                    negative_stock: pr.isc_configuration && pr.isc_configuration.negative_stock ? pr.isc_configuration.negative_stock : 'N',

                    sales_enable_tcs    :  formData && formData.setting && formData.setting.sales_enable_tcs  ? formData.setting.sales_enable_tcs : 'Y',                        
                    allTCSType  :   formData && formData.discount_type ? formData.discount_type.map((s) => { return {value: s, label: s === "Fixed" ? invCurrency : "%"}; }) : [],

                }, () => {

                    let ParamObject = new URLSearchParams(this.props.location.search);
                    /********************************EDIT INVOICE ************************************* */
                    if (pr.location && pr.location.state && pr.location.state.invoiceData) {
                        this.setState({ buttonDisabled: true })
                        let invoiceDetail = pr.location.state.invoiceData;
                        let consumedItems = invoiceDetail.items;
                        let employeeProfile = invoiceDetail.employee;

                        setTimeout(() => {
                            this.setState({
                                invoiceDetail: pr.location && pr.location.state && pr.location.state.clone ? null : invoiceDetail,
                                invoiceForm: {
                                    ...this.initInvoiceForm,
                                    transaction_id: pr.location && pr.location.state && pr.location.state.clone ? '' : invoiceDetail?.transaction_id,
                                    transaction_date: pr.location && pr.location.state && pr.location.state.clone ? moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD') : invoiceDetail.transaction_date,
                                    warehouse_id: invoiceDetail.warehouse ? invoiceDetail.warehouse.id : '',
                                    customer_id: invoiceDetail.customer ? invoiceDetail.customer.id : '',
                                    deal_id: invoiceDetail.deal_data ? invoiceDetail.deal_data.id : '',
                                    invoice_type: invoiceDetail.invoice_type ? invoiceDetail.invoice_type : 'asset',
                                    asset_id: invoiceDetail.assetProfile ? invoiceDetail.assetProfile.asset_id : '',
                                    workstation_id: invoiceDetail.workstation ? invoiceDetail.workstation.id : '',
                                    voucher_number: invoiceDetail.voucher_number,
                                    voucher_date: invoiceDetail.voucher_date ? invoiceDetail.voucher_date : '',
                                    notes: invoiceDetail.notes,
                                    status: invoiceDetail.status.id,
                                    adjustment_amount: invoiceDetail.adjustment_amount,
                                    sub_assembly_id: invoiceDetail.subAssembly ? invoiceDetail.subAssembly.enc_id : ''
                                },
                                searchedDeal: invoiceDetail.deal_data ? {
                                    value: invoiceDetail.deal_data.id,
                                    display_label: invoiceDetail.deal_data.header, label: invoiceDetail.deal_data.header
                                } : null,
                                searchedCustomer: { value: invoiceDetail?.customer?.id, display_label: invoiceDetail?.customer?.name },
                                CustomerDetails: { value: invoiceDetail?.customer?.id, display_label: invoiceDetail?.customer?.name },

                                selectedCustomerAdress: invoiceDetail.customer_shipping_address ? { ...invoiceDetail.customer_shipping_address, gstin: invoiceDetail.customer_shipping_address.gstin, id: invoiceDetail.customer_shipping_address.id, country_id: invoiceDetail.customer_shipping_address.country_id ? invoiceDetail.customer_shipping_address.country_id : 99 } : null,

                                selectedBillingAddress: invoiceDetail.customerProject ? invoiceDetail.customerProject.address : invoiceDetail.customer_billing_address ? { ...invoiceDetail.customer_billing_address, gstin: invoiceDetail.customer_billing_address.gstin, id: invoiceDetail.customer_billing_address.id, country_id: invoiceDetail.customer_billing_address.country_id ? invoiceDetail.customer_billing_address.country_id : 99 } : null,
                                selectedShippingAddress: invoiceDetail.customerProject ? invoiceDetail.customerProject.address : invoiceDetail.customer_shipping_address ? { ...invoiceDetail.customer_shipping_address, gstin: invoiceDetail.customer_shipping_address.gstin, id: invoiceDetail.customer_shipping_address.id, country_id: invoiceDetail.customer_shipping_address.country_id ? invoiceDetail.customer_shipping_address.country_id : 99 } : null,
                                selectedProject: invoiceDetail.customerProject ? invoiceDetail.customerProject : null,
                                ticketFromInvoice: invoiceDetail.ticket && invoiceDetail.invoice_type == "asset" ? true : false,
                                selectedTicket: invoiceDetail.ticket ? { ...invoiceDetail.ticket, value: invoiceDetail.ticket.activity_id, label: `${invoiceDetail.ticket.activity_id} : ${invoiceDetail.ticket.header}`, display_label: `${invoiceDetail.ticket.activity_id} : ${invoiceDetail.ticket.header}` } : null,
                                searchedEmployee: employeeProfile ? { value: employeeProfile.enc_id, label: `${employeeProfile.display_full_name}`, employee: employeeProfile } : null,
                                searchSubassembly: invoiceDetail.subAssembly && {
                                    label: invoiceDetail.subAssembly.name,
                                    value: invoiceDetail.subAssembly.enc_id,
                                    display_label: invoiceDetail.subAssembly.name,
                                    subAssembly: invoiceDetail.subAssembly
                                },
                                allStatus: invoiceDetail && invoiceDetail.user_status && invoiceDetail.user_status.length > 0 ? invoiceDetail.user_status.map((s) => { return { value: s.id, label: s.name }; }) : [],
                            }, () => {
                                this.getWarehouseDetail(invoiceDetail?.warehouse?.id, true);
                                this.getCustomerDetails(invoiceDetail.customer_id, true);
                                this.handleCustomerChange(this.state.searchedCustomer, true);
                                let transactionIdSetting = this.state.allSalesOrder ? this.state.allSalesOrder.find(pt => pt.value == this.state.invoiceDetail.sales_order) : null
                                this.setState({
                                    transactionIdSetting: transactionIdSetting ? transactionIdSetting.type_data : null
                                })
                                this.filterAllStatusOptions();
                                let assetProfile = invoiceDetail.assetProfile;
                                if (assetProfile) {
                                    this.setState({ selectedAsset: { ...assetProfile, label: `${assetProfile.name} (${assetProfile.asset_code})` } });
                                }
                                // let employeeProfile        =   invoiceDetail.employee;
                                // if(employeeProfile) {
                                //     this.setState({searchedEmployee : {value : employeeProfile.enc_id, label : `${employeeProfile.display_full_name}`,employee : employeeProfile }});
                                // }
                                let workstation = invoiceDetail.workstation;
                                if (workstation) {
                                    this.setState({ searchedWorkstation: { value: workstation.id, label: workstation.workstation_name } });
                                }
                                consumedItems.forEach((consumedItem, key) => {
                                    let pre_selected_stock = {
                                        batch_number: consumedItem?.batch_number, expiry_date: consumedItem?.expiry_date, transaction_id: consumedItem.transaction_id, item_id: consumedItem.item_id,
                                        qty: consumedItem.qty, invoice_item_id: consumedItem.invoice_item_id, allocation_item_id: consumedItem.allocation_item_id, allocation_data: consumedItem.allocation_item_id ? consumedItem : null
                                    };
                                    this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem.item_profile, isAddRemark: consumedItem.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, pre_selected_stock, false);
                                });
                            });
                            this.addNewItemRow()
                        }, 500)

                    }

                    /********************************ADD INVOICE FROM PURCHASE************************************* */
                    if (pr.location && pr.location.state && pr.location.state.addInvoiceFromPurchase) {
                        this.setState({ buttonDisabled: true })
                        let invoiceDetail = pr.location.state.addInvoiceFromPurchase;
                        let consumedItems = invoiceDetail.items;
                        setTimeout(() => {
                            this.setState({
                                invoiceDetail: null,
                                invoiceForm: {
                                    ...this.initInvoiceForm,
                                    transaction_date: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                    customer_id: invoiceDetail.customer ? invoiceDetail.customer.id : '',
                                    warehouse_id: invoiceDetail.warehouse ? invoiceDetail.warehouse.id : '',
                                    deal_id: invoiceDetail.deal_data ? invoiceDetail.deal_data.id : '',
                                    transaction_id: invoiceDetail.transaction_id ? invoiceDetail.transaction_id : '',
                                },
                                addInvoiceFromPurchase: true,
                                buttonDisabled: true,
                            }, () => {
                                this.getWarehouseDetail(invoiceDetail.warehouse.id, true);
                                this.getCustomerDetails(invoiceDetail.customer_id, true);
                                this.filterAllStatusOptions();
                                this.handleCustomerChange(this.state.searchedCustomer, true);
                                let transactionIdSetting = this.state.allSalesOrder ? this.state.allSalesOrder.find(pt => pt.value == this.state.invoiceDetail.sales_order) : null
                                this.setState({
                                    transactionIdSetting: transactionIdSetting ? transactionIdSetting.type_data : null
                                })
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem.item, isAddRemark: consumedItem.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, null, false);
                                });
                            });
                            this.addNewItemRow()
                        }, 500)
                    }

                    /********************************ADD INVOICE FROM PURCHASE REPORT************************************* */
                    if (pr.location && pr.location.state && pr.location.state.addInvoiceFromPurchaseReport) {
                        this.setState({ buttonDisabled: true })
                        let invoiceDetail = pr.location.state.addInvoiceFromPurchaseReport;
                        let consumedItems = invoiceDetail.items;
                        setTimeout(() => {
                            this.setState({
                                invoiceDetail: null,
                                invoiceForm: {
                                    ...this.initInvoiceForm,
                                    transaction_date: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                    warehouse_id: invoiceDetail.warehouse ? invoiceDetail.warehouse.id : '',
                                },
                                addInvoiceFromPurchase: true,
                                buttonDisabled: true,
                            }, () => {
                                this.getWarehouseDetail(invoiceDetail.warehouse.id, true);
                                this.getCustomerDetails(invoiceDetail.customer_id, true);
                                this.handleCustomerChange(this.state.searchedCustomer, true);
                                this.filterAllStatusOptions();
                                let transactionIdSetting = this.state.allSalesOrder ? this.state.allSalesOrder.find(pt => pt.value == this.state.invoiceDetail.sales_order) : null
                                this.setState({
                                    transactionIdSetting: transactionIdSetting ? transactionIdSetting.type_data : null
                                })
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem, isAddRemark: consumedItem?.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, null, false);
                                });
                            });
                        }, 500)
                    }
                    /********************************ADD INVOICE FROM ALLOCATION************************************* */
                    if (pr.location && pr.location.state && pr.location.state.addInvoiceFromAllocation) {
                        this.setState({ buttonDisabled: true })
                        let invoiceDetail = pr.location.state.addInvoiceFromAllocation;
                        let consumedItems = invoiceDetail.items;


                        setTimeout(() => {
                            this.setState({
                                invoiceDetail: null,
                                invoiceForm: {
                                    ...this.initInvoiceForm,
                                    transaction_date: pr.location && pr.location.state && pr.location.state.clone ? moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD') : invoiceDetail.transaction_date,
                                    warehouse_id: invoiceDetail.warehouse ? invoiceDetail.warehouse.id : '',
                                    employee_id: invoiceDetail.employee ? invoiceDetail.employee.enc_id : '',
                                    invoice_type: invoiceDetail.invoice_type ? invoiceDetail.invoice_type : invoiceDetail.demand_for ? invoiceDetail.demand_for : 'asset',
                                    asset_id: invoiceDetail.assetProfile ? invoiceDetail.assetProfile.asset_id : invoiceDetail.asset ? invoiceDetail.asset.asset_id : '',
                                    workstation_id: invoiceDetail.workstation ? invoiceDetail.workstation.id : '',
                                    sub_assembly_id: invoiceDetail.subAssembly ? invoiceDetail.subAssembly.enc_id : invoiceDetail.sub_assembly ? invoiceDetail.sub_assembly.enc_id : ''
                                },
                                addInvoiceFromPurchase: true,
                                buttonDisabled: true,
                                ticketFromInvoice: invoiceDetail.ticket && invoiceDetail.invoice_type == "asset" ? true : false,
                                ticketLinkedFromAllocation: invoiceDetail.ticket ? true : false,
                                selectedTicket: invoiceDetail.ticket ? { ...invoiceDetail.ticket, value: invoiceDetail.ticket.activity_id, label: `${invoiceDetail.ticket.activity_id} : ${invoiceDetail.ticket.header}`, display_label: `${invoiceDetail.ticket.activity_id} : ${invoiceDetail.ticket.header}` } : null,
                            }, () => {
                                this.getWarehouseDetail(invoiceDetail.warehouse.id, true);
                                this.getCustomerDetails(invoiceDetail.customer_id, true);
                                this.handleCustomerChange(this.state.searchedCustomer, true);
                                this.filterAllStatusOptions();
                                let assetProfile = invoiceDetail.assetProfile ? invoiceDetail.assetProfile : invoiceDetail.asset ? invoiceDetail.asset : null;
                                if (assetProfile) {
                                    this.setState({ selectedAsset: { ...assetProfile, label: `${assetProfile.name} (${assetProfile.asset_code})` } });
                                }
                                let employeeProfile = invoiceDetail.employee;
                                if (employeeProfile) {
                                    this.setState({ searchedEmployee: { value: employeeProfile.enc_id, label: `${employeeProfile.display_full_name}`, employee: employeeProfile } });
                                }
                                let workstation = invoiceDetail.workstation;
                                if (workstation) {
                                    this.setState({ searchedWorkstation: { value: workstation.id, label: workstation.workstation_name } });
                                }
                                let subAssembly = invoiceDetail.subAssembly ? invoiceDetail.subAssembly : invoiceDetail.sub_assembly ? invoiceDetail.sub_assembly : null;
                                if (subAssembly) {
                                    this.setState({
                                        searchSubassembly: {
                                            label: subAssembly.name,
                                            value: subAssembly.enc_id,
                                            display_label: subAssembly.name,
                                            subAssembly: subAssembly
                                        }
                                    })
                                }
                                setTimeout(() => {
                                    consumedItems.forEach((consumedItem, key) => {
                                        let itemData = consumedItem.itemData ? consumedItem.itemData : consumedItem.item_profile ? consumedItem.item_profile : null;
                                        let pre_selected_stock = { batch_number: consumedItem?.batch_number, expiry_date: consumedItem?.expiry_date, qty: consumedItem?.pending_qty, allocation_item_id: consumedItem?.allocation_item_id };
                                        this.onItemSelect({ ...consumedItem, itemdata: { ...itemData, isAddRemark: consumedItem.remark ? true : false } }, key, consumedItem.pending_qty, consumedItem.rate, pre_selected_stock, false);
                                    });
                                }, 1000);
                            });
                            this.addNewItemRow()
                        }, 500)

                    }
                    if (pr.location && pr.location.state && pr.location.state.addInvoiceFromInventoryTransfer) {
                        this.setState({ buttonDisabled: true })
                        let invoiceDetail = pr.location.state.addInvoiceFromInventoryTransfer;
                        let consumedItems = invoiceDetail.items;
                        setTimeout(() => {
                            this.setState({
                                invoiceDetail: null,
                                invoiceForm: {
                                    ...this.initInvoiceForm,
                                    transaction_date: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                                    warehouse_id: invoiceDetail.warehouse ? invoiceDetail.warehouse.id : '',
                                },
                                only_stocked_item: 'N',
                                buttonDisabled: true,
                                addInvoiceFromPurchase: true
                            }, () => {
                                this.getWarehouseDetail(invoiceDetail.warehouse.id, true);
                                this.getCustomerDetails(invoiceDetail.customer_id, true);
                                this.handleCustomerChange(this.state.searchedCustomer, true);
                                this.filterAllStatusOptions();
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem.item, isAddRemark: consumedItem.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, null, false);
                                });
                            });
                            this.addNewItemRow()
                        }, 500)

                    }
                });
            });
        }
    }
    filterAllStatusOptions = () => {
        let allStatusOptions = this.state.allStatusOptions;
        if (this.state.user_status?.length > 0) {
            allStatusOptions = allStatusOptions.filter(allStatus => this.state.user_status.find(ns => ns.id == allStatus.value) ? true : false);
            this.setState({
                allStatusOptions: allStatusOptions
            });
        }
    }
    onWarehouseChange = (selectedOption) => {
        if (!this.state.selectedTicket) {
            this.setState({
                selectedAsset: null,
                searchedWorkstation: null,
                searchedEmployee: null,
                searchSubassembly: null,
                warehouseSites: [],
                invoiceItemRows: [...this.inhitInvoiceItemRows],
                allPendingAllocationList: [],
                invoiceForm: {
                    ...this.state.invoiceForm,
                    workstation_id: '',
                    asset_id: '',
                    employee_id: '',
                    tag_users: [],
                    sub_assembly_id: '',
                    // warehouse_id :""
                },
                // storeIndentItemRows         :   [...this.iniitstoreIndentItemRows]
            }, () => {
            });
        }
        this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
    }
    changeAddressHandler = (id) => {
        if (id) {
            let selectedCustomerAdress = this.state.CustomerDetails && this.state.CustomerDetails.addressList
                ? this.state.CustomerDetails.addressList.find(a => a.id == id) : null;
            this.setState({
                selectedCustomerAdress: selectedCustomerAdress
            })
        }
    }
    handleSelectedProject = (selectedProject = null) => {

        if (selectedProject) {
            this.setState({
                selectedProject,
                selectedBillingAddress: selectedProject.address,
                selectedShippingAddress: selectedProject.address,
            });
        } else {
            let selectedCustomerAdress = this.state.CustomerAddressList && this.state.CustomerAddressList.length > 0 ? this.state.CustomerAddressList[0] : null;
            this.setState({
                selectedProject: null,
                selectedBillingAddress: selectedCustomerAdress,
                selectedShippingAddress: selectedCustomerAdress,
            });
        }
    }
    handleAddressSelect = (addresses) => {
        this.setState({
            selectedShippingAddress: addresses.shipping,
            selectedBillingAddress: addresses.billing,
        });
    };
    getWarehouseDetail = (warehouse_id, editInvoice = false) => {
        if (warehouse_id) {
            this.setState({ warehouseDetailLoading: true, warehouseDetail: null, warehouseSites: [] });
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    warehouseDetail: respData.data,
                    warehouseSites: respData.data.sites
                });
                if (!editInvoice) {

                }
            }).then(() => {
                this.setState({ warehouseDetailLoading: false });
            });
        } else {
            this.setState({ warehouseDetail: null })
        }
    }
    

    handleButtonEnable = () => {
        const allApiCallsCompleted = this.state.invoiceItemRows.filter(i => i.item ? true : false).every(item => item.allApiCallsCompleted);
        this.setState({ buttonDisabled: !allApiCallsCompleted });
    };




    getItemInventoryLevel = (item_id, key, pre_selected_stock = null, selectedData = null, category = 'goods') => {
        this.setState({ buttonDisabled: true, loadingItemDetails: true });
        let invoiceItemRows = this.state.invoiceItemRows;
        invoiceItemRows[key] = { ...invoiceItemRows[key], stock_loading: true, selected_allocation: null, selected_stock: null, allocation_list: [], current_stocks: [] };
        this.setState({ invoiceItemRows: [...invoiceItemRows] });
        const params = {
            // search_status_levels: [3, 4],
            // more_than_pending_qty: 0,
            per_page: 100,
            page: 1,
            include_item_sales_order: "Y",
            // search_customer_ids: [selectedOption.value]
        }
        // let allocationParams = {
        //     action: 'data',
        //     search_warehouse_id: this.state.invoiceForm.warehouse_id,
        //     need_item_detail: "Y",
        //     search_transaction_id: transaction_id,
        //     min_pending_qty: 0.01
        //     // search_item_ids: [item_id],
        //    
        //     // search_asset_ids: this.state.invoiceForm.asset_id ? [this.state.invoiceForm.asset_id] : [],
        //     // search_employee_ids: this.state.invoiceForm.employee_id ? [this.state.invoiceForm.employee_id] : [],
        //     // search_workstation_ids: this.state.invoiceForm.workstation_id ? [this.state.invoiceForm.workstation_id] : [],
        //     // search_sub_assembly_ids: this.state.invoiceForm.sub_assembly_id ? [this.state.invoiceForm.sub_assembly_id] : [],
        // }
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token };
        Promise.all([
            axios({ method: 'GET', headers: headers, url: INVENTORY_API_BASE_URL_2 + '/item_current_stock/' + item_id, params: { interchangeable_items_current_stock: "N", warehouse_id: this.state.invoiceForm.warehouse_id } }),
            axios({ method: 'GET', headers: headers, url: this.salesOrderListUrl, params: { ...params } }),
        ])
            .then((results) => {
                let current_stock_list = results[0].data;
                let allocation_list = results[1].data.data.filter(al => al.transaction_type == "allocation" ? true : false).map(list => { return { value: list.allocation_item_id, label: list.allocation_transaction_id, allocation_detail: list } });
                let so_list = results[1].data.data.filter(al => al.sales_order_type_name == "Sales Work Order" ? true : false).map(list => { return { value: list.id, label: list.transaction_id, so_data: list } });
                let current_stocks = current_stock_list.current_stock
                this.setState({
                    allItemCurrentStocks: {
                        ...this.state.allItemCurrentStocks,
                        data: {
                            ...this.state.allItemCurrentStocks.data,
                            [item_id]: current_stocks.length > 0 ? current_stocks.map((cs, i) => ({ ...cs, selected: i === 0 ? true : false, choose_batch_number: true })) : []
                        }
                    }
                })

                let invoiceItemRows = this.state.invoiceItemRows;
                let isDuplicate = false;
                let updatedAllocationList = allocation_list;

                if (invoiceItemRows[key].invoiceData && invoiceItemRows[key].invoiceData.allocation_item_id && !allocation_list.find(al => al.value == invoiceItemRows[key].invoiceData.allocation_item_id)) {
                    const newObject = {
                        value: invoiceItemRows[key].invoiceData.allocation_item_id,
                        label: invoiceItemRows[key].invoiceData.allocation_transaction_id,
                        allocation_detail: invoiceItemRows[key].invoiceData  // Add the appropriate details
                    };

                    // // Check if newObject already exists in the list
                    isDuplicate = allocation_list.some(item =>
                        item.value === newObject.value && item.label === newObject.label
                    );

                    // // Add newObject if it doesn't already exist
                    updatedAllocationList = isDuplicate
                        ? allocation_list
                        : [...allocation_list, newObject];
                }

                let isDuplicateDemand = false;
                let updatedDemandList = so_list;

                if (invoiceItemRows[key].invoiceData && invoiceItemRows[key].invoiceData.item_id && !so_list.find(al => al.value == invoiceItemRows[key].invoiceData.item_id)) {
                    const newObject = {
                        value: invoiceItemRows[key].invoiceData.item_id,
                        label: invoiceItemRows[key].invoiceData.transaction_id,
                        so_data: { ...invoiceItemRows[key].invoiceData, pending_qty: 0 },
                        demand_pending_qty: invoiceItemRows[key].qty // Add the appropriate details
                    };

                    // // Check if newObject already exists in the list
                    isDuplicateDemand = so_list.some(item =>
                        item.value === newObject.value && item.label === newObject.label
                    );

                    // // Add newObject if it doesn't already exist
                    updatedDemandList = isDuplicateDemand
                        ? so_list
                        : [...so_list, newObject];
                }
                // // New object to add

                let selected_allocation = invoiceItemRows[key].invoiceData && invoiceItemRows[key].invoiceData.allocation_item_id ? updatedAllocationList.find(al => al.value == invoiceItemRows[key].invoiceData.allocation_item_id) : null;
                let so_data = invoiceItemRows[key].invoiceData && invoiceItemRows[key].invoiceData.item_id ? updatedDemandList.find(dl => dl.value == invoiceItemRows[key].invoiceData.item_id) : null;

                invoiceItemRows[key] = {
                    ...invoiceItemRows[key],
                    stock_loading: false,
                    current_stocks: current_stocks,
                    allocation_item_id: invoiceItemRows[key].invoiceData && invoiceItemRows[key].invoiceData.allocation_item_id ? invoiceItemRows[key].invoiceData.allocation_item_id : '',
                    selected_allocation: selected_allocation ? { ...selected_allocation, ...selected_allocation?.allocation_detail, pending_qty: Number(selected_allocation?.allocation_detail?.pending_qty ?? 0) + Number(invoiceItemRows[key].qty) } : null,
                    allApiCallsCompleted: true,
                    allocation_list: updatedAllocationList,
                    so_list: updatedDemandList,
                    consumption_item_id: pre_selected_stock && pre_selected_stock.consumption_item_id ? pre_selected_stock.consumption_item_id : '',
                    demand_pending_qty: so_data && so_data.so_data && !selected_allocation ? Number(so_data.so_data.pending_qty) + Number(invoiceItemRows[key].qty) : null,
                    transaction_id: invoiceItemRows[key].invoiceData && invoiceItemRows[key].invoiceData.transaction_id ? invoiceItemRows[key].invoiceData.transaction_id : '',
                    item_id: invoiceItemRows[key].invoiceData && invoiceItemRows[key].invoiceData.item_id ? invoiceItemRows[key].invoiceData.item_id : '',
                };

                let selectedStock = current_stocks.length > 0 ? current_stocks[0] : null;
                let findSelectedStockKey = null;

                if (pre_selected_stock) {
                    let preSelectedStock = current_stocks.find(cs => {
                        return cs.stock?.batch_number == pre_selected_stock?.batch_number
                            && cs.stock?.expiry_date == pre_selected_stock?.expiry_date;
                    });
                    if (!preSelectedStock) {

                        selectedStock = {
                            consumption_item_id: pre_selected_stock?.consumption_item_id,
                            batch_number: pre_selected_stock?.batch_number,
                            expiry_date: pre_selected_stock?.expiry_date
                        };
                    } else {

                        let cs = preSelectedStock.stock;
                        let qty = cs?.qty;
                        let free_qty = this.props.location && this.props.location.state && this.props.location.state.clone ? cs?.free_qty : cs?.free_qty + pre_selected_stock?.qty;


                        selectedStock = {
                            ...preSelectedStock, consumption_item_id: pre_selected_stock.consumption_item_id, stock: {
                                ...cs,
                                qty: qty,
                                free_qty: free_qty
                            }
                        };
                    }
                }
                if ((invoiceItemRows[key].selected_allocation && invoiceItemRows[key].selected_allocation.allocation_item_id)) {
                    selectedStock = null;
                }
                this.setState({ invoiceItemRows: [...invoiceItemRows], loadingItemDetails: false }, () => {
                    this.onBatchChange(selectedStock, key);
                    this.handleButtonEnable()
                });
            })

    }
    itemViewModalInit = (item, k) => {
        if (item) {
            this.itemDetailsRef.current.modalInit(item);
            this.setState({ itemRowNumber: k, selectedItemDetails: item, showItemDetailModal: true })
        }
    }

    batchListingModalInit = (itemRow, itemRowKey) => {
        let item                    =   itemRow.item;
        let selected_batch_number   =   itemRow.batch_number;
        let selected_expiry_date    =   itemRow.expiry_date;
        let itemCurrentStocks       =   itemRow.current_stocks;
        if(item && itemCurrentStocks) {
            this.setState({
                batch_data          :   itemCurrentStocks.map((cs, key) => {
                                            return {...cs, selected : cs.batch_number === selected_batch_number && cs.expiry_date === selected_expiry_date }
                                        })
            }, () => {
                this.batchListingModal.show(item);
                if (item) {
                    this.setState({ selectedItemDetails: item, selectedItemRow : itemRowKey });
                }
            })
        }
    }

    handleSelectedbatchNumberData = (item_id, field) => {
        this.setState({
            batch_data          :   this.state.batch_data.map((bd, i) => {
                                        return {...bd, selected : i === field, choose_batch_number : false }
                                    })
        });
    }

    handleBatchNumber           = () => {
        let itemRowKey              =   this.state.selectedItemRow;
        let invoiceItemRows         =   this.state.invoiceItemRows;
        let selected_batch_data     =   this.state.batch_data.find(b => b.selected)
        let newInvoiceItemRowData   =   invoiceItemRows[itemRowKey];
        newInvoiceItemRowData       =   {...newInvoiceItemRowData, 
            batch_number            :   selected_batch_data.batch_number,
            expiry_date             :   selected_batch_data.expiry_date,
            total_qty               :   selected_batch_data.total_qty,
            free_qty                :   selected_batch_data.free_qty
        };

        invoiceItemRows[itemRowKey] =   {...newInvoiceItemRowData};
        this.setState({invoiceItemRows : invoiceItemRows}, () => {
            this.batchListingModal.hide();
        });
    };







    handleButtonEnable = () => {
        // Check if all items are either processed (allApiCallsCompleted) or are services
        const allItemsProcessed = this.state.invoiceItemRows
            .filter(i => i.item) // Ensure the item exists
            .every(item =>
                (item.allApiCallsCompleted || (item.item && item.item.category === 'services'))
            );

        // Enable the button only if all items are processed or are services
        this.setState({ buttonDisabled: !allItemsProcessed });
    };

    onBatchChange = (selectedOption, key) => {

        if (selectedOption && selectedOption.stock) {
            let selected_stock = selectedOption.stock;
            let invoiceItemRows = this.state.invoiceItemRows;
            invoiceItemRows[key] = {
                ...invoiceItemRows[key],
                selected_stock: selected_stock,
                total_qty: selected_stock.qty,
                free_qty: selected_stock.free_qty,
                invoice_item_id: selectedOption.invoice_item_id ? selectedOption.invoice_item_id : null,
            };
            this.setState({ invoiceItemRows: [...invoiceItemRows] });
        }
    }

    onAllocationChange = (selectedOption, key, qty = 0) => {

        if (selectedOption && selectedOption.allocation_detail) {
            let allocation_detail = selectedOption.allocation_detail;
            let invoiceItemRows = this.state.invoiceItemRows;
            invoiceItemRows[key] = {
                ...invoiceItemRows[key],
                selected_allocation: { ...allocation_detail, allocation_detail: { ...allocation_detail }, pending_qty: this.state.invoiceDetail && allocation_detail && !allocation_detail.pending_qty ? Number(qty) : Number(allocation_detail.pending_qty) },
                qty: selectedOption.qty ? Number(selectedOption.qty) : this.state.invoiceDetail && allocation_detail && !allocation_detail.pending_qty ? Number(qty) : Number(allocation_detail.pending_qty),
                invoice_item_id: selectedOption.invoice_item_id ? selectedOption.invoice_item_id : null,
                selected_stock: { ...allocation_detail },
                allocation_item_id: allocation_detail ? allocation_detail.allocation_transaction_id : ''
            };
            this.setState({ invoiceItemRows: [...invoiceItemRows] });
        } else {
            let invoiceItemRows = this.state.invoiceItemRows;
            invoiceItemRows[key] = {
                ...invoiceItemRows[key],
                selected_allocation: { allocation_item_id: null, batch_number: null },
                qty: 1,
                invoice_item_id: null,
                selected_stock: invoiceItemRows.current_stocks && invoiceItemRows.current_stocks.length > 0 ? invoiceItemRows.current_stocks[0].stock : null,
                allocation_item_id: null
            };
            this.setState({ invoiceItemRows: [...invoiceItemRows] });
        }
    }

    itemViewModalInit = (item, k) => {
        if (item) {
            this.itemDetailsRef.current.modalInit(item);
            this.setState({ itemRowNumber: k })
        }

    }

    viewInvoiceModalInit = (data, k) => {

        if (this.state.invoiceForm?.asset_id || this.state.invoiceForm?.workstation_id) {
            if (data) {
                this.setState({
                    itemSearchedDetails: data,
                    itemRowNumber: k
                }, () => this.invoiceHistoryRef.current.modalInit(data, this.state.invoiceForm.workstation_id, this.state.invoiceForm.asset_id, this.state.invoiceForm.invoice_type))
            }
        } else {
            toast.error('Please Select Asset/Workstation first', { position: toast.POSITION.TOP_RIGHT });
        }

    }

    addRemark = (data, k) => {
        let allRows = this.state.invoiceItemRows;
        let itemdata = { ...data, isAddRemark: true }
        allRows[k] = { ...this.state.invoiceItemRows[k], item: itemdata };
        this.setState({ invoiceItemRows: allRows });
    }

    //********************EDIT ITEM ******************************* */
    itemSearchModalInit = () => {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows = this.state.invoiceItemRows;
                let item_ids = [];
                if (allRows && allRows.length > 0) {
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => { return (item?.item?.id) })
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });

    }

    editItemModalInit = (data, k) => {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal, itemSearchedDetails: data, itemRowNumber: k }), () => {
            if (this.state.showItemEditModal) {
                let allRows = this.state.invoiceItemRows;
                let item_ids = [];
                if (allRows && allRows.length > 0) {
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => { return (item?.item?.id) })
                }
                if (this.editItemModalRef.current) {
                    this.editItemModalRef.current.itemEditModalInit(data);
                }

            }
        });

    }

    addItemModalInit = () => {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows = this.state.invoiceItemRows;
                let item_ids = [];
                if (allRows && allRows.length > 0) {
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => { return (item?.item?.id) })
                }

                if (this.addItemModalRef.current) {
                    this.addItemModalRef.current.itemAddModalInit();
                }

            }
        });

    }

    submitEditItem = () => {
        HttpAPICall.withAthorization('GET', this.itemDetailUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
            let selectedItem = {
                label: (<Ax>
                    <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br />
                    <small>
                        <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br />
                        <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value: response.data.data.id,
                display_label: `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata: response.data.data
            };
            this.setState({
                itemSearchedDetails: { ...this.state.itemSearchedDetails, ...response.data.data }
            }, () => {
                this.onItemSelect(selectedItem, this.state.itemRowNumber, this.state.invoiceItemRows[this.state.itemRowNumber]?.qty)
            });
        })

    }

    addNewItemRow = () => {
        this.setState({ invoiceItemRows: [...this.state.invoiceItemRows, ...this.inhitInvoiceItemRows] });
    }


    removeItemRow = (k, item, item_id) => {
        let invoiceItemRows = this.state.invoiceItemRows;
        if (invoiceItemRows.length >0) {
            invoiceItemRows.splice(k, 1);
            this.setState({ invoiceItemRows: invoiceItemRows });
        }
        if (item.allocation_transaction_id || item.so_transaction_id) {
            let allocation_ids = this.state.allAllocatedItemIds.filter(id => (id !== (item.allocation_transaction_id || item.so_transaction_id)));
            this.setState({
                allAllocatedItemIds: allocation_ids
            })
        }
    }

    //****************************CLOSE INVOICE *********************/
    closeEvent = () => {
        if (this.state.invoiceDetail) {
            this.props.history.push({ pathname: `/sales_invoice_list`, state: { invoiceData: this.state?.invoiceDetail } })
        } else {
            this.props.history.push({ pathname: `/sales_invoice_list` })
        }
    }

    addItemsfromSearch = (selectedItems) => {
        let invoiceItemRows = selectedItems.map((pu_item, k) => {
            return {
                item: { ...pu_item.item, isAddRemark: pu_item.remark ? true : false },
                req_allocation_loading: true,
                req_allocation: [],
                id: pu_item.item.id,
                qty: pu_item.qty,
                rate: pu_item.rate,
                remark: '',
                gst: pu_item.gst,
                discount_type: '',
                discount_rate: '',
                po_item_id: '',
                req_item_id: '',
                selected_allocation: null,
                expiry_date: '',
                batch_number: '',
                invoice_item_id: null,
            };
        });
        let allRows = this.state.invoiceItemRows ? this.state.invoiceItemRows.filter(i => i.item ? true : false) : [];
        let consumedItems = allRows.concat(invoiceItemRows);
        consumedItems.forEach((consumedItem, key) => {
            this.onItemSelect({ ...consumedItem, itemdata: { ...consumedItem.item, isAddRemark: consumedItem?.remark ? true : false } }, key, consumedItem.qty, consumedItem.rate, false, false);
        });


    }

    pickAllocationItemModalInit = () => {
        if (document.getElementById("pickinvoiceForm")) {
            let form = document.getElementById("pickinvoiceForm")
            form.reset();
        }

        // if (this.state.invoiceForm.warehouse_id && this.state.invoiceForm.invoice_type) {
        let invoice_type_id = '';
        let warehouse_id = ''
        // if (this.state.invoiceForm.invoice_type == 'asset') {
        //     invoice_type_id = this.state.invoiceForm.asset_id;
        // } else if (this.state.invoiceForm.invoice_type == 'employee') {
        //     invoice_type_id = this.state?.searchedEmployee?.value;
        // } else if (this.state.invoiceForm.invoice_type == 'workstation') {
        //     invoice_type_id = this.state?.invoiceForm.workstation_id;
        // } else if (this.state.invoiceForm.invoice_type == 'sub_assembly') {
        //     invoice_type_id = this.state?.invoiceForm.sub_assembly_id;
        // }

        this.getAllPendingItemsfromAllocation(warehouse_id, invoice_type_id)


        // } else {
        //     toast.error('Please Select Warehouse First', { position: toast.POSITION.TOP_RIGHT })
        // }
    }

    checkAllAllocationList = () => {
        let allocationItemCheckboxCollections = document.getElementsByClassName('allocation_id_checkbox');
        if (allocationItemCheckboxCollections && allocationItemCheckboxCollections.length > 0) {
            for (let i = 0; i < allocationItemCheckboxCollections.length; i++) {
                allocationItemCheckboxCollections[i].checked = document.getElementById('all_check_allocation').checked;
            }
        }
    }

    //*****************************GET ALL PENDING ITEMS FROM PO************** */
    getAllPendingItemsfromAllocation = (warehouse_id, invoice_type_id = '', item_ids, selectedItem_ids, new_qty = false) => {
        if (this.state.invoiceForm.warehouse_id && this.state.invoiceForm.customer_id) {
            this.pickAllocationItemModal.show()
            // if(!this.state.pendingAllocationListLoaded){
            this.setState({ pending_item_loading: true })
            let params = {
                search_warehouse_id: this.state.invoiceForm?.warehouse_id,
                search_customer_id: this.state.invoiceForm?.customer_id,
                include_item_sales_order: this.state.include_item_sales_order,
                // need_item_detail: "Y",
                // search_warehouse_ids: [this.state.invoiceForm.warehouse_id],
                // search_asset_ids: this.state.invoiceForm.asset_id ? [this.state.invoiceForm.asset_id] : [],
                // search_employee_ids: this.state.invoiceForm.employee_id ? [this.state.invoiceForm.employee_id] : [],
                // search_workstation_ids: this.state.invoiceForm.workstation_id ? [this.state.invoiceForm.workstation_id] : [],
                // search_sub_assembly_ids: this.state.invoiceForm.sub_assembly_id ? [this.state.invoiceForm.sub_assembly_id] : [],
                // item_ids: item_ids && item_ids.length > 0 ? item_ids : [],
                // min_pending_qty: 0.01,
                // for_report: 'N',
            };
            HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + `/sales/pending_sales_and_allocation_item`, this.props.access_token, params, {}, (resp) => {
                this.setState({
                    allPendingAllocationList: resp.data.map(d => { return ({ ...d, new_qty: d.pending_qty }) }),
                    pendingAllocationListLoaded: true
                })
            }).then(() => {
                this.setState({ pending_item_loading: false });
            });
            // }
        } else {
            toast.error('Please Select WareHouse and Customer', { position: toast.POSITION.TOP_RIGHT })
        }

    }

    submitSelectedAllocationItem = (e) => {
        e.preventDefault();
        this.setState({ savePickItemSubmitting: true });
        let allocationItemCheckboxCollections = document.getElementsByClassName('allocation_id_checkbox');
        let allSelectedItems            =   [];
        let allQty                      =   [];
        if (allocationItemCheckboxCollections && allocationItemCheckboxCollections.length > 0) {
            for (let i = 0; i < allocationItemCheckboxCollections.length; i++) {
                if (allocationItemCheckboxCollections[i].checked) {
                    allSelectedItems.push(this.state.allPendingAllocationList[i]);
                }
            }
        }
        //***************************HANDLE SELECTED ITEM FROM PO  **********************/
        if (allSelectedItems.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT })
        } else {
            let checkBlankQty = allQty.filter(function (item) {
                return item == "" || item == null
            }).length == 0;
            if (checkBlankQty == false) {
                toast.error('Please fill the allocated Qty', { position: toast.POSITION.TOP_RIGHT })
            } else {
                this.handleSelectedItemsToAddInvoice(allSelectedItems)
            }
        }
    }

    handleSelectedItemsToAddInvoice = (allItems, item_ids, new_qty = false) => {
        let invoiceItemRows         =   this.state.invoiceItemRows;
        let newInvoiceItemRowsData  =   invoiceItemRows.filter(ir => !(ir.so_item_id && ir.allocation_item_id)).filter(ir => ir.item_id != null);
 
        let allocatedItemIds        =   this.state.allAllocatedItemIds;
        let lengthMatch             =   0;
        let remain_itemrow_count    =   newInvoiceItemRowsData.length;

        allItems.forEach((item, key) => {
            let data                =   {...this.inhitInvoiceItemRow, 
                                                allocation_item_id          :   item.allocation_item_id,
                                                allocation_transaction_id   :   item.allocation_transaction_id,
                                                so_item_id                  :   item.so_item_id,
                                                so_transaction_id           :   item.so_transaction_id,
                                                item_id                     :   item.item_id,
                                                qty                         :   item.new_qty,
                                                stock_loading               :   true,
                                                batch_number                :   item.batch_number,
                                                expiry_date                 :   item.expiry_date,
                                                pending_qty                 :   item.pending_qty
                                        };
            newInvoiceItemRowsData.push(data);
        });
        this.setState({invoiceItemRows : newInvoiceItemRowsData});
        
        allItems.forEach((list, key) => {
            lengthMatch            += 1;
            let item_id                     =   list.item_id;
            let batch_number                =   list.batch_number;
            let expiry_date                 =   list.expiry_date;
            let itemRowKey                  =   key + remain_itemrow_count;
            this.fetchCurrentStock(item_id,batch_number,expiry_date,itemRowKey, newInvoiceItemRowsData).then(() => {
                if(allItems.length === lengthMatch) {
                    this.setState({ 
                        savePickItemSubmitting: false,
                    }, () => {
                        this.addNewItemRow();
                        this.pickAllocationItemModal.hide();
                    });
                }
            });
        });
    }

    fetchCurrentStock                   = (item_id,batch_number,expiry_date,itemRowKey, newInvoiceItemRowsData)  =>  {
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.access_token };
        return Promise.all([
            axios({ method: 'GET', headers: headers, url: TapApiUrls.ITEM + '/' + item_id }),
            axios({ method: 'GET', headers: headers, url: INVENTORY_API_BASE_URL_2 + '/item_current_stock/' + item_id, params: { interchangeable_items_current_stock: "N", warehouse_id: this.state.invoiceForm.warehouse_id } }),
        ])
        .then((results) => {
            let itemData                =   results[0].data.data;
            let current_stock_list      =   results[1].data;
            let current_stocks          =   current_stock_list.current_stock;

            let newInvoiceItemRowData   =   newInvoiceItemRowsData[itemRowKey];

            let selectedStock           =   current_stocks.length >= 0  ? (newInvoiceItemRowData.allocation_item_id 
                                            ? current_stocks.find(cs => cs.batch_number === batch_number && cs.expiry_date === expiry_date)
                                            : current_stocks[0]) : null;

            newInvoiceItemRowData       =   {...newInvoiceItemRowData, 
                stock_loading               :   false,
                item                        :   itemData,
                rate                        :   itemData.consumption_price ? itemData.consumption_price : 0,
                current_stocks              :   current_stocks && current_stocks.length > 0 ? current_stocks : [],
                batch_number                :   selectedStock ? selectedStock.batch_number : null,
                expiry_date                 :   selectedStock ? selectedStock.expiry_date : null,
                total_qty                   :   selectedStock ? selectedStock.total_qty : null,
                free_qty                    :   selectedStock ? selectedStock.free_qty : null
            };

            newInvoiceItemRowsData[itemRowKey]     =   {...newInvoiceItemRowData};

            this.setState({invoiceItemRows : newInvoiceItemRowsData});
        });
    }

    onItemSelect = (selectedOption, key) => {
        if (selectedOption) {
            if (!this.state.invoiceForm.warehouse_id) {
                toast.error('Please select Warehouse First', { position: toast.POSITION.TOP_RIGHT })
            }
            if (!this.state.invoiceForm.customer_id) {
                toast.error('Please select Customer First', { position: toast.POSITION.TOP_RIGHT })
            } else {
                let itemData            =   selectedOption.itemdata;
                let invoiceItemRows     =   this.state.invoiceItemRows;
                let data                =   {...this.inhitInvoiceItemRow, 
                                                    item_id                     :   itemData.id,
                                                    qty                         :   1,
                                                    stock_loading               :   true,
                                                    item                        :   itemData,
                                                    rate                        :   itemData.consumption_price ? itemData.consumption_price : 0,
                                            };
                invoiceItemRows[key] = data;

                this.setState({invoiceItemRows : invoiceItemRows}, () => {
                    this.fetchCurrentStock(itemData.id,null,null,key, invoiceItemRows);
                });
            }
        }
    }


    invoiceFormSubmitHandler = (event, status = 0) => {
        event.preventDefault();
        let showErrors = [];
        let formData = {
            ...this.state.invoiceForm,
            status: status,
            warehouse_id: this.state.invoiceForm.warehouse_id,
            employee_id: this.state.invoiceForm.invoice_type == "employee" && this.state.searchedEmployee ? this.state.searchedEmployee.value : '',
            customer_billing_address_id: this.state.selectedBillingAddress && this.state.selectedBillingAddress.id ? this.state.selectedBillingAddress.id : null,
            customer_shipping_address_id: this.state.selectedShippingAddress && this.state.selectedShippingAddress.id ? this.state.selectedShippingAddress.id : null,

        };

        let items                   =   this.state.invoiceItemRows.filter(i => i.item_id);
        
        let allItemLists            =   [];
        let dublicateItemExists     =   false;
        if (items.length > 0) {
            items.forEach(item => {
                let combinationKey = item.item_id + item.batch_number + item.expiry_date + item.allocation_item_id + item.so_item_id;
                if (allItemLists.includes(combinationKey)) {
                    dublicateItemExists = true;
                }
                return allItemLists.push(combinationKey);
            })
        }
        if (formData.transaction_date == '') {
            showErrors = [...showErrors, 'Please enter Transaction Date.'];
        }

        if (formData.warehouse_id == '') {
            showErrors = [...showErrors, 'Please select Warehouse.'];
        }
        if (formData.status == '') {
            showErrors = [...showErrors, 'Please select Status'];
        }
        if (items.length == 0) {
            showErrors = [...showErrors, 'Please select Item and their invoice qty.'];
        } else if (dublicateItemExists == true) {
            showErrors = [...showErrors, 'Invoice should not contain duplicate Item'];
        } else if (items.find(i => i.item && parseFloat(i.qty) <= 0)) {
            showErrors = [...showErrors, 'Every Item\'s Invoice qty must be more than 0.'];
        } else if (items.find(i => i.item && i.qty == '')) {
            showErrors = [...showErrors, 'Every Item\'s Invoice qty should not be blank'];
        }
        //  else if (this.state.negative_stock == 'N') {
        //     if (items.find(i => i.qty > i.free_qty)) {
        //         showErrors = [...showErrors, 'Invoice qty can not more than Free Qty/Pending Qty.'];
        //     }
        // }

        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            formData = {
                ...formData,
                items: items,
                update_item_invoice_price: this.state.update_item_invoice_price,
            };

            if (this.state.invoiceDetail) {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('PUT', this.INVOICE_URL + '/edit', this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/sales_invoice_list`, state: { invoiceId: response.data.transaction_id } })
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('POST', this.INVOICE_URL + '/add', this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/sales_invoice_list`, state: { invoiceId: response.data.transaction_id } })
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }

    pageTitlejsx = () => {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>{this.state.invoiceDetail ? "Edit" : "Add"} Invoice {this.state.invoiceDetail ? <span> of Transaction ID : {this.state.invoiceDetail.transaction_id}</span> : null}</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }

    invoiceItemsJsx = () => {
        const { allItemCurrentStocks } = this.state;
        return (<div className="my-3 row">
            <div className="col-sm-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary align-middle">
                        <tr className="">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" style={{ width: "27%" }}>Item Details</th>
                            {this.state.track_batch_expiry == "Y" ?
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Batch Number <br /> Expiry Date</th>
                                : null}
                            <th scope="col" className="text-center" style={{ width: "14%" }}>Sales Order <br />
                                Sales Allocation
                            </th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Discount</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>GST</th>
                            <th scope="col" className="text-center" style={{ width: "7%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.invoiceItemRows.map((itemRow, itemKey) => {
                            let item                    =   itemRow && itemRow.item ? itemRow.item : null;
                            let item_id                 =   item?.id;
                            let choose_batch_number     =   true;
                            return <tr className="" key={itemKey}>
                                <td className="text-center align-middle">{itemKey + 1}</td>
                                {(item
                                    ? <Ax>
                                        <td>
                                            <div className="row">
                                                <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>{item.name}</span></div>

                                                <div className='col-sm-2 text-end'>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li><a href="#" className="dropdown-item" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>View Details</a></li>
                                                        {this.props?.permissions?.permissions?.includes("isc-item-edit") && <li><a href="#" className="dropdown-item" onClick={() => { this.setState({ showItemEditModal: false }, () => { this.editItemModalInit(item, itemKey) }) }}>Edit Item</a></li>}
                                                        <li><a href="#" className="dropdown-item" onClick={() => { this.viewInvoiceModalInit(item, itemKey) }}>Invoice History</a></li>
                                                        <li className="dropdown-item" onClick={() => { this.addRemark(itemRow.item, itemKey) }}>Add Remark</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-text"><b>Code: </b>{item.item_code}</div>
                                            <div className="form-text">
                                                <b>Mfr: </b>{item.manufacturer ? item.manufacturer.name : '-'} (<b>MPN:</b> {item.manufacturer_part_no ? item.manufacturer_part_no : '-'})
                                            </div>
                                            {itemRow.item && itemRow.item.isAddRemark && itemRow.item.isAddRemark == true
                                                ?
                                                <input
                                                    name="remark"
                                                    type="text"
                                                    value={itemRow.remark}
                                                    onChange={(e) => this.dynamicInputHandlerByKey(e, itemKey, 'invoiceItemRows')}
                                                    className="form-control mt10"
                                                    autoComplete="off"
                                                    placeholder="Item Remarks"
                                                />
                                                : null}

                                        </td>
                                        {
                                            itemRow.stock_loading
                                                ? <td colSpan={this.state.track_batch_expiry == 'Y' ? 5 : 4}><Loader /></td>
                                                : (<Ax>
                                                    {
                                                     
                                                        <>
                                                            <div className='py-1'>
                                                                BN : <b> {itemRow.batch_number || "-"} </b> <br />
                                                                ED: <b> {DateService.dateTimeFormat(itemRow.expiry_date,'DD-MMM-YYYY') || "-"} </b><br />
                                                            </div>
                                                            <br />
                                                            {
                                                                itemRow.current_stocks.length > 1  && !itemRow.allocation_transaction_id &&
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        this.batchListingModalInit(itemRow, itemKey);
                                                                    }}
                                                                    className="btn btn-link text-decoration-none text-center">
                                                                    {choose_batch_number ? "Choose Batch No." : "Change Batch No"}
                                                                </button>
                                                            }
                                                            <td>
                                                                SO : {itemRow.so_transaction_id ?
                                                                    <span>{itemRow.so_transaction_id}</span>
                                                                    : "-"}
                                                                <br />
                                                                <small className={['my-1'].join(' ')}>
                                                                    {itemRow.item_id && itemRow.demand_pending_qty && <><b>Pending Qty: </b> {itemRow.demand_pending_qty} <br /></>}
                                                                </small>
                                                                <br />
                                                                SA : {itemRow.allocation_transaction_id ?
                                                                    <span>{itemRow.allocation_transaction_id}</span>
                                                                    : "-"}
                                                                <br />
                                                                {itemRow.selected_allocation && itemRow.selected_allocation.pending_qty > 0 ?
                                                                    <div className='py-1'> <b>Pending Qty: </b> {itemRow.selected_allocation ? itemRow.selected_allocation.pending_qty : "Nil"} <br /></div>
                                                                    : null}
                                                            </td>
                                                            <td>
                                                                <TapInputNumber
                                                                    name="qty"
                                                                    value={itemRow.qty}
                                                                    onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'invoiceItemRows', null, 'qty', e)}
                                                                    className="form-control text-end"
                                                                    placeholder="Qty"
                                                                    required={true}
                                                                    min={.01}
                                                                />

                                                                <span className="text-helping input-group-text text-end pr0">{item && item.measuring_unit ? item.measuring_unit.name : ''}</span>
                                                                { itemRow.current_stocks && <>
                                                                        <div className='py-1'>
                                                                            <small>Stock In Hand:  <b> {itemRow.total_qty || "-"} </b></small> <br />
                                                                            <small>Free Stock:<b> {itemRow.free_qty || "-"} </b></small> <br />
                                                                        </div>
                                                                    </>
                                                                }
                                                            </td>
                                                            <td>
                                                                <TapInputNumber
                                                                    name="rate"
                                                                    value={itemRow.rate}
                                                                    onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'invoiceItemRows', null, 'rate', e)}
                                                                    placeholder="Unit Rate"
                                                                    required={true}
                                                                    min={0}
                                                                />
                                                            </td>
                                                            <td>
                                                            <TapInputNumber
                                                                name="discount_rate"
                                                                value={this.state.invoiceItemRows[itemKey].discount_rate}
                                                                onChange={(newValue) => this.dynamicInputHandlerByKeyNameVal(newValue, 'discount_rate', itemKey, 'invoiceItemRows')}
                                                                className="form-control text-end"
                                                                placeholder="Discount"
                                                            />
                                                            <TapSelect
                                                                changeEvent={(selectedOption, e) => {
                                                                    this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'discount_type', itemKey, 'invoiceItemRows')
                                                                }}
                                                                options={this.state.allDiscountType}
                                                                isSearchable={false}
                                                                value={this.state.allDiscountType.find(s => s.value == this.state.invoiceItemRows[itemKey].discount_type)}
                                                                placeholder="Discount"
                                                            />
                                                        </td>
                                                        <td className='text-end rightSelectText'>
                                                            {this.state.warehouseDetail && this.state.warehouseDetail.gstin ? <TapSelect
                                                                changeEvent={(selectedOption, e) => {
                                                                    this.dynamicInputHandlerByKeyNameVal(selectedOption.value, 'gst', itemKey, 'invoiceItemRows')
                                                                }}
                                                                options={this.state.allGstSlabs}
                                                                isSearchable={false}
                                                                value={this.state.allGstSlabs.find(s => s.value == this.state.invoiceItemRows[itemKey].gst)}
                                                                placeholder="GST"
                                                            /> : "-"}
                                                            {(this.state.warehouseDetail && this.state.warehouseDetail.gstin)
                                                                ? item ? <span className="text-helping input-group-text text-end pr0">{this.state.gstTypeName}</span> : null

                                                                : <div className='text-center'>NA <sup className='text-danger'>#</sup></div>}
                                                        </td>
                                                            <td className="pt-4 text-end">{(itemRow.rate * itemRow.qty).toFixed(2)}</td>
                                                        </>
                                                        // : "N/A"
                                                    }
                                                </Ax>)}
                                    </Ax>
                                    : <Ax>
                                        <td className="align-middle">
                                            <InputItemSearch
                                                changeEvent={(selectedOption) => {
                                                    this.onItemSelect(selectedOption, itemKey);
                                                }}
                                                only_active_item='Y'
                                                only_stocked_item={this.state.only_stocked_item}
                                                stock_warehouse_ids={[this.state.invoiceForm.warehouse_id]}
                                            />
                                        </td>
                                        <td colSpan={this.state.track_batch_expiry == 'Y' ? 7 : 6}>{itemRow.stock_loading && <Loader /> }</td>
                                    </Ax>
                                )}
                                <td className="text-center align-middle">
                                    <a href="#" onClick={() => this.removeItemRow(itemKey, itemRow, itemRow.item_id)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={this.state.only_stocked_item == "Y" ? "N" : "Y"}
                                checked={this.state.only_stocked_item == "Y"}
                                onChange={(e) => { this.setState({ only_stocked_item: e.target.value, }); }}
                                id="onlyStockedItem"
                            />
                            <label className="form-check-label" htmlFor="onlyStockedItem">
                                Show only those items that are available in the selected warehouse
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-4 text-end">
                        {this.state.invoiceForm && this.state.invoiceForm.warehouse_id ?
                            <a role="button" onClick={() => { this.setState({ showItemSearchModal: false }, () => this.itemSearchModalInit()) }} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                            : null}
                        {this.props?.permissions?.permissions?.includes("isc-item-add") && <a role="button" onClick={() => { this.setState({ showItemAddModal: false }, () => this.addItemModalInit()) }} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                        <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    </div>
                    <div className='col-sm-12'>
                        <input
                            name="update_item_invoice_price"
                            type="checkbox"
                            value={this.state.update_item_invoice_price}
                            onChange={(e) => {

                                this.setState({
                                    update_item_invoice_price: this.state.update_item_invoice_price == 'N' ? 'Y' : 'N',
                                })
                            }}
                            checked={this.state.update_item_invoice_price == 'Y'}
                            className="form-check-input"
                            id="update_item_invoice_price"

                        />
                        <label className="form-check-label mx-2 text-sm" htmlFor="update_item_invoice_price">Update Item Invoice Price </label><br />
                    </div>
                </div>
            </div>
        </div>);
    }

    handleInvoiceFor = () => {
        this.setState({
            invoiceForm: {
                ...this.state.invoiceForm,
                asset_id: this.state.ticketFromInvoice && this.state.invoiceForm.asset_id ? this.state.invoiceForm.asset_id : null
            },
            selectedTicket: this.state.ticketFromInvoice ? this.state.selectedTicket : null,
            selectedAsset: this.state.ticketFromInvoice && this.state.invoiceForm.asset_id ? this.state.selectedAsset : null,
            selectedTicket: this.state.ticketFromInvoice ? this.state.selectedTicket : null,
            searchedWorkstation: null,
            searchedEmployee: null,
            searchSubassembly: null,
        })
    }

    saveInvoiceFormJsx = () => {
        let sub_total_amount           =   0;
        let gst_total_amount           =   0;
        let gst_slab                   =   [];
        let total_amount               =   0;
        let invoice_summary            =   [];
        let adjustment_amount          =   Number(this.state.invoiceForm.adjustment_amount);
      
        
        this.state.invoiceItemRows.forEach((item,key) => {
            if(item && item.item) {
                let item_amount             =   Number(item.qty * item.rate);
                let discount_amout          =   Number(item.discount_rate && item.discount_rate > 0
                                                    ?  item.discount_type == "Percentage" ? (item_amount*item.discount_rate/100) : item.discount_rate
                                                    : 0);
                let item_with_discount      =   item_amount - discount_amout;
                let tax_amount              =   0;
                
                if(item.gst && item.gst > 0 && this.state.selectedBillingAddress && this.state.selectedBillingAddress.gstin) {
                    tax_amount              =   item_with_discount * item.gst / 100;
                    if(!gst_slab[item.gst]) {
                        gst_slab[item.gst]  =   [];
                    }
                    gst_slab[item.gst].push(tax_amount);
                }
                let item_final_amount       =   item_with_discount + tax_amount;
                sub_total_amount           +=   item_with_discount;
                gst_total_amount           +=   tax_amount;
                total_amount               +=   item_final_amount; 
            }
        });
        
        invoice_summary.push({key : 'Sub Total' , value : sub_total_amount.toFixed(2)});

        if (gst_total_amount > 0) {
            gst_slab.forEach((tax_amounts, gst) => {
                let total_tax = tax_amounts.reduce((sum, a) => sum + a, 0);
                if (this.state.selectedBillingAddress && this.state.warehouseDetail && this.state.selectedBillingAddress.state_id == this.state.warehouseDetail.state_id) {
                    let tax = gst / 2;
                    invoice_summary.push({ key: 'CGST ' + tax + '%', value: (total_tax / 2).toFixed(2) });
                    invoice_summary.push({ key: 'SGST ' + tax + '%', value: (total_tax / 2).toFixed(2) });
                } else {
                    invoice_summary.push({ key: 'IGST ' + gst + '%', value: total_tax.toFixed(2) });
                }
            });
        } else if (gst_total_amount == 0 && this.state.gst_as_applicable == 'Y') {
            if (this.state.selectedBillingAddress && this.state.warehouseDetail && this.state.selectedBillingAddress.state_id == this.state.warehouseDetail.state_id) {
             
                invoice_summary.push({ key: 'CGST ', value: "As applicable" });
                invoice_summary.push({ key: 'SGST ', value: "As applicable" });
            } else {
                invoice_summary.push({ key: 'IGST ', value: "As applicable" });
            }
          
        }

        let tcs_amount                          =   Number(this.state.invoiceForm.tcs_rate && this.state.invoiceForm.tcs_rate >= 0
            ?  this.state.invoiceForm.tcs_type == "Percentage" ? (sub_total_amount*this.state.invoiceForm.tcs_rate/100) :  Number(this.state.invoiceForm.tcs_rate)
            : 0);

            




        let transactionDate = this.state.invoiceForm && this.state.invoiceForm.transaction_date ? moment(this.state.invoiceForm.transaction_date).format('YYYY-MM-DD 00:00:00') : null;
        let disable_invoice_for = false;
        if (this.state.invoiceDetail && this.state.invoiceItemRows && this.state.invoiceItemRows.length > 0) {
            disable_invoice_for = this.state.invoiceItemRows.some(item => item.selected_allocation !== null);
        }
        let ticketLinkedFromAllocation = this.state.ticketLinkedFromAllocation

        let filter_site_ids = [];

        if (this.state.selectedAsset && this.state.selectedAsset.site_id) {
            filter_site_ids = [this.state.selectedAsset.site_id];
        } else if (this.state.searchedWorkstation && this.state.searchedWorkstation.workstation && this.state.searchedWorkstation.workstation.site_id) {
            filter_site_ids = [this.state.searchedWorkstation.workstation.site_id];
        } else if (this.state.searchedEmployee && this.state.searchedEmployee.employee && this.state.searchedEmployee.employee.site) {
            filter_site_ids = [this.state.searchedEmployee.employee.site.site_id];
        } else {
            filter_site_ids = this.state.warehouseSites.map((w) => w.id);
        }


        return (<form className="bg-white p-3" onSubmit={this.invoiceFormSubmitHandler}>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.invoiceForm.transaction_date
                                ? moment(this.state.invoiceForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'invoiceForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Date of Transaction`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">

                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'invoiceForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        required={true}
                        value={this.state.allWarehouses.find(m => m.value == this.state.invoiceForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    // isDisabled={this.state.invoiceDetail }
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }
            <Customer
                CustomerDetails={this.state.CustomerDetails}
                details_loading={this.state.details_loading}
                CustomerAddressList={
                    this.state.CustomerAddressList?.length > 0
                        ? this.state.CustomerAddressList.map(address => ({
                            ...address,
                            source: 'customer'
                        }))
                        : []
                }
                Customer_details_loading={this.state.Customer_details_loading}
                selectedCustomerAdress={this.state.selectedCustomerAdress ? { ...this.state.selectedCustomerAdress, source: 'customer' } : this.state.selectedCustomerAdress}
                searchedCustomer={this.state.searchedCustomer}
                onChange={this.handleCustomerChange}
                getCustomerDetails={this.getCustomerDetails}
                CustomerRef={this.addCustomerModalRef}
                changeAddressHandler={this.changeAddressHandler}
                onSelectProject={this.handleSelectedProject}
                selectedProject={this.state.selectedProject}
                handleShipToAddress={true}
                selectedShippingAddress={this.state.selectedShippingAddress}
                selectedBillingAddress={this.state.selectedBillingAddress}
                onAddressSelect={this.handleAddressSelect}
                disabled={this.state.selectedSalesOrder}
                disabledPerformAction={this.state.selectedSalesOrder}
            />

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-7">

                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'invoiceForm', 'status');
                        }}
                        options={this.state.allStatus}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allStatus.find(s => s.value == this.state.invoiceForm?.status)}
                        placeholder="Select Status"
                    // isDisabled={this.state.invoiceDetail}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label ">Deals</label>
                </div>


                <div className="col-sm-6">
                    <InputDealSearch
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'invoiceForm', 'deal_id');
                            this.setState({ searchedDeal: selectedOption ? selectedOption : null });
                        }}
                        menuPlacement="top"
                        search_customer_id={this.state.invoiceForm?.customer_id}
                        search_customer_project_id={this.state.selectedProject ? this.state.selectedProject.id : ''}
                        value={this.state.searchedDeal}
                        changeDeal={(deal) => {
                            this.tapSelectChange(deal ? deal.deal_id : '', 'invoiceForm', 'deal_id');
                            this.setState({ searchedDeal: deal ? deal : null });
                        }}
                        showSearch={true}
                        disabled={!this.state.invoiceForm.customer_id}
                    />
                </div>

            </div>
            <div className="row my-3">
                <div className="col-sm-3">
                    <label className="form-label ">Tag</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'invoiceForm', 'invoice_type', () => {
                                this.setState({
                                    selectedAsset: null,
                                    searchedWorkstation: null,
                                    searchedEmployee: null,
                                    searchSubassembly: null,

                                    invoiceForm: {
                                        ...this.state.invoiceForm,
                                        workstation_id: '',
                                        asset_id: '',
                                        employee_id: '',
                                        sub_assembly_id: ''
                                    },
                                })
                            });
                        }}
                        options={this.state.allInvoiceFor}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allInvoiceFor.find(s => s.value == this.state.invoiceForm.invoice_type)}
                        placeholder="Select Invoice For"
                    />
                </div>
                <div className="col-sm-4">
                    {this.state.invoiceForm.invoice_type == "asset"
                        ? <Ax>
                            <InputAssetSearch
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'invoiceForm', 'asset_id');
                                    this.setState({ selectedAsset: selectedOption ? { ...selectedOption.asset, label: selectedOption.display_label } : null });
                                }}
                                placeholder="Search Asset"
                                search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                filter={true}
                                required={true}
                                value={this.state.selectedAsset ? { ...this.state.selectedAsset } : null}
                            />

                            {this.state.selectedAsset
                                ?
                                <AssetMiniDetail assetDetail={this.state.selectedAsset} />

                                : null
                            }
                        </Ax>
                        : this.state.invoiceForm.invoice_type == "workstation"
                            ? <Ax>
                                <InputWorkstationSearch
                                    placeholder="Please Select Workstation"
                                    changeEvent={(selectedOption) => {
                                        this.setState({
                                            searchedWorkstation: selectedOption ? selectedOption : null,
                                            invoiceForm: { ...this.state.invoiceForm, workstation_id: selectedOption ? selectedOption.value : '' }
                                        })

                                    }}
                                    menuPlacement="top"
                                    search_site_id={this.state.warehouseSites.map(wk => wk.id)}
                                    value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                                />
                            </Ax>
                            : this.state.invoiceForm.invoice_type == "employee"
                                ? <Ax>
                                    {this.props.permissions.group_modules.includes("hrm")
                                        ?
                                        <InputEmployeeSearch
                                            placeholder="Please Select Employee"
                                            search_site_ids={this.state.warehouseSites.map((w) => w.id)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'invoiceForm', 'employee_id');
                                                this.setState({ searchedEmployee: selectedOption ? selectedOption : null });
                                            }}
                                            transaction_date={transactionDate ? transactionDate : null}

                                            value={this.state.searchedEmployee ? this.state.searchedEmployee : null}
                                        />

                                        : null}
                                </Ax> : this.state.invoiceForm.invoice_type == "sub_assembly"
                                    ? <Ax>
                                        <InputSubAssemblySearch
                                            placeholder="Search Sub Assembly"
                                            search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'invoiceForm', 'sub_assembly_id');
                                                this.setState({ searchSubassembly: selectedOption ? selectedOption : null });
                                            }}
                                            required={true}
                                            menuPlacement="top"
                                            isMulti={false}
                                            filter={true}
                                            value={this.state.searchSubassembly ? this.state.searchSubassembly : null}
                                        />

                                    </Ax> : null
                    }
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="voucher_number" className="form-label">Voucher Number & Date</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="voucher_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.invoiceForm.voucher_number}
                        onChange={(e) => this.formInputHandler(e, 'invoiceForm')}
                        placeholder="Enter Voucher Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.invoiceForm.voucher_date ? moment(this.state.invoiceForm.voucher_date).toDate() : false}
                        name="voucher_date"
                        onChange={(value, event) => this.formDateHandler('voucher_date', value, 'invoiceForm')}
                        maxDate={moment().toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Enter Voucher Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="invoice_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3 ">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Allocate Item <br /></label>
                </div>
                <div className="col-sm-3">
                    <a role="button" className="link-primary" onClick={this.pickAllocationItemModalInit}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Pick Item from Allocation </a>

                </div>
                <div className="col-sm-4">
                    <div className="form-check form-check-inline">
                        <input
                            name="include_item_sales_order"
                            type="checkbox"
                            value={this.state.include_item_sales_order == "Y" ? "N" : "Y"}
                            onChange={(e) => {
                                this.setState({
                                    include_item_sales_order: e.target.value,
                                    pendingPOListLoaded: false
                                });
                            }}
                            className="form-check-input"
                            id="include_item_sales_order"
                            checked={this.state.include_item_sales_order == "Y"}
                        />
                        <label className="form-check-label" htmlFor="include_item_sales_order">
                            Include Approved Demand
                        </label>
                    </div>
                </div>


            </div>

            {this.invoiceItemsJsx()}
            <div className="row align-items-center my-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.invoiceForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'invoiceForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <table className="table  table-borderless">
                        <tbody>
                            {invoice_summary && invoice_summary.length > 0
                                ? (invoice_summary.map((ps, k) => {
                                    return <tr key={k} className="subtotal_bg_color"><th>{ps.key} ({this.state.invoiceForm.currency})</th><td className="text-end">{ps.value}</td></tr>
                                }))
                                : null
                            }
                            {this.state.sales_enable_tcs == 'Y' && <tr className="subtotal_bg_color">
                                <th>
                                   <div className='row align-items-center'>
                                        <div className='col-sm-4'>
                                        <input
                                        name="tcs_applicable"
                                        type="checkbox"
                                        value={this.state.tcs_applicable}
                                        onChange={(e) => {
                                            this.setState({
                                                tcs_applicable: this.state.tcs_applicable == 'N' ? 'Y' : 'N',
                                            },() => {
                                                if(this.state.tcs_applicable == 'N'){
                                                    this.setState({
                                                        invoiceForm      :       {
                                                            ...this.state.invoiceForm,
                                                            tcs_rate     :   0
                                                        }
                                                    })
                                                }
                                            })
                                        }}
                                        checked={this.state.tcs_applicable == 'Y'}
                                        className="form-check-input"
                                        id="tcs_applicable"
                                    />
                                    <label className="form-check-label mx-1 " htmlFor="tcs_applicable">Enable TCS</label>
                                        </div>
                                        <div className='col-sm-3'>
                                            <TapSelect
                                                changeEvent={(selectedOption, e) => {
                                                    this.tapSelectChange(selectedOption, 'invoiceForm', 'tcs_type');
                                                }}
                                                options={this.state.allTCSType}
                                                isSearchable={false}
                                                value={this.state.allTCSType.find(s => s.value == this.state.invoiceForm.tcs_type)}
                                                placeholder="TCS"
                                                disabled={this.state.tcs_applicable == 'N'}
                                            />
                                        </div>
                                        <div className='col-sm-5'>

                                            <input
                                                name="tcs_rate"
                                                type="number"
                                                value={this.state.invoiceForm.tcs_rate}
                                                onChange={(e) => this.formInputHandler(e, 'invoiceForm')}
                                                className="form-control text-end"
                                                autoComplete="off"
                                                placeholder="TCS Amount"
                                                disabled={this.state.tcs_applicable == 'N'}
                                            />
                                        </div>
                                   </div>
                                </th>
                                <td className='float-end' style={{lineHeight:3}}> {tcs_amount ? <NumberFormatter number={tcs_amount} precision={2} /> : <NumberFormatter number={tcs_amount} precision={2} />}</td>
                               
                            </tr>}
                          
                            <tr className="subtotal_bg_color">
                                <th>Adjustment</th>
                                <td><input
                                    name="adjustment_amount"
                                    type="number"
                                    value={this.state.invoiceForm.adjustment_amount}
                                    onChange={(e) => this.formInputHandler(e, 'invoiceForm')}
                                    className="form-control text-end"
                                    autoComplete="off"
                                    placeholder="Adjustment"
                                /></td>
                            </tr>

                            <tr className="subtotal_bg_color">
                                <th>Final Total ({this.state.invoiceForm.currency})</th>
                                <td className='float-end'> {adjustment_amount ? <NumberFormatter number={total_amount + adjustment_amount + tcs_amount} precision={2} /> : <NumberFormatter number={total_amount + tcs_amount} precision={2} />}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* {!this.state.buttonDisabled && */}
             <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting 
                    // || this.state.buttonDisabled
                    } onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                {this.state.all
                    ? <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary ms-2" onClick={(e) => { this.invoiceFormSubmitHandler(e, 1); }}>
                        Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    : null
                }

                <button type="button" disabled={this.state.saveFormSubmitting 
                    // || this.state.buttonDisabled
                    } className="btn btn-primary ms-2" onClick={(e) => { this.invoiceFormSubmitHandler(e, this.state.invoiceForm.status); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
            {/* } */}
        </form>);
    }

    pickAllocationItemModalJsx = () => {

        return (
            <div className="modal fade" id="pickAllocationItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="vendorDetailsModalLabel">Pick Item from Allocation & Sales Order </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <table className="table table-hover table-borderless my-2">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>Warehouse</td>
                                        <th>{this.state.warehouseDetail && this.state.warehouseDetail.name ? this.state.warehouseDetail.name : "-"}</th>
                                    </tr>
                                    <tr>  
                                        <td style={{ width: "25%" }}>Customer</td>
                                        <th className='text-capitalize'>{this.state.CustomerDetails ? this.state.CustomerDetails?.name : "-"}</th>
                                    </tr>


                                </tbody>
                            </table>
                            <form className="bg-white" onSubmit={this.submitSelectedAllocationItem} id="pickinvoiceForm" method="post" encType="multipart/form-data">
                                <table className="table table-hover table-bordered table-responsive table-sm bg-white mt-3">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllAllocationList} id="all_check_allocation" /></th>
                                            <th scope="col" className="text-start" style={{ width: "15%" }}>Item Name</th>
                                            <th scope="col" className="text-start" style={{ width: "15%" }}>Item Code</th>
                                            <th scope="col" className="text-start" style={{ width: "15%" }}>Manufacturer</th>
                                            <th scope="col" className="text-center" style={{ width: "12%" }}>Sales Order ID</th>
                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Sales Order Date</th>
                                            <th scope="col" className="text-center" style={{ width: "12%" }}>Allocation ID</th>
                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Allocation Date</th>
                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Total Qty</th>
                                            <th scope="col" className="text-center" style={{ width: "12%" }}>Pending Qty</th>
                                            <th scope="col" className="text-end" style={{ width: "7%" }}>Invoice</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.pending_item_loading
                                            ? <tr><td colSpan={8}><Loader /></td></tr>

                                            : this.state.allPendingAllocationList && this.state.allPendingAllocationList.length > 0

                                                ? this.state.allPendingAllocationList.map((selectedItem, i) => {
                                                    return <tr>
                                                        <td className='text-center'>
                                                            <input type='checkbox'
                                                                className="allocation_id_checkbox"
                                                                name="allocationItem"
                                                                data-item_id={selectedItem.item_id}
                                                                data-so_id={selectedItem.item_id}
                                                                data-demandTransactionId={selectedItem.transaction_id}
                                                                data-allocationTransactionId={selectedItem.allocation_transaction_id}
                                                                value={selectedItem.allocation_item_id}
                                                                data-itemId={selectedItem.item_id}
                                                                data-qty={selectedItem?.new_qty}
                                                                disabled={this.state.allAllocatedItemIds.find(element => {
                                                                    if (element === selectedItem.allocation_transaction_id ||  element === selectedItem.so_transaction_id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                })}
                                                                checked={this.state.allAllocatedItemIds.find(element => {
                                                                    if (element === selectedItem.allocation_transaction_id ||  element === selectedItem.so_transaction_id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                })}
                                                            />
                                                        </td>
                                                        <td>{selectedItem.item_name ? selectedItem.item_name : '-'}</td>
                                                        <td>{selectedItem.item_code ? selectedItem.item_code ?? "-" : '-'}</td>
                                                        <td>{selectedItem.manufacturer_name ? selectedItem.manufacturer_name ?? "-" : '-'}</td>
                                                        <td className='text-center'>{selectedItem.so_transaction_id ? selectedItem.so_transaction_id : "-"}</td>
                                                        <td className='text-center'>{selectedItem.so_transaction_date ? DateService.dateTimeFormat(selectedItem.so_transaction_date, 'DD-MMM-YYYY') : "-"}</td>
                                                        <td className='text-center'>{selectedItem.allocation_transaction_id ? selectedItem.allocation_transaction_id : "-"}</td>
                                                        <td className='text-center'>{selectedItem.allocation_transaction_date ? DateService.dateTimeFormat(selectedItem.allocation_transaction_date, 'DD-MMM-YYYY') : "-"}</td>

                                                        <td className='text-end'>{selectedItem.qty ? selectedItem.qty : "-"}</td>
                                                        <td className='text-end'>{selectedItem.pending_qty ? selectedItem.pending_qty : "-"}</td>
                                                        <td>
                                                            <TapInputNumber
                                                                className='form-control form-control-sm text-end'
                                                                placeholder='Allocated Quantity'
                                                                name="new_qty"
                                                                value={selectedItem.new_qty}
                                                                onChange={(e) => {
                                                                    this.state.allPendingAllocationList[i] = { ...selectedItem, new_qty: e };
                                                                    this.dynamicInputHandlerByKeyNameVal(e, 'new_qty', i, 'allPendingAllocationList')
                                                                }}
                                                                max={selectedItem.pending_qty}
                                                            />
                                                        </td>
                                                    </tr>
                                                })
                                                : <tr><td colSpan={12} className="text-center">No Record</td></tr>
                                        }

                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" disabled={this.state.savePickItemSubmitting} className="btn btn-primary" form="pickinvoiceForm">Add to Invoice {this.state.savePickItemSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
    handleCancel = () => {
        this.setState({ batch_data: [] }, () => {
            this.batchListingModal.hide();
        })
    };

    viewbatchListingModalJsx = () => {
        const { selectedItemDetails, warehouseDetail, CustomerDetails, batch_data } = this.state;

        return (
            <div className="modal fade" id="batchListingModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Batch Number and Expiry Date</h5>
                            <button type="button" onClick={() => this.handleCancel()} className="btn-close" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Item Detail</span>
                            </div>
                            <table className="table table-hover table-borderless my-2">
                                <tbody>
                                    {selectedItemDetails ? (<>
                                        <tr>
                                            <td style={{ width: "15%" }}>Item Name</td>
                                            <td>
                                                {selectedItemDetails?.name}
                                            </td>
                                            <td style={{ width: "15%" }}>Item Code</td>
                                            <td>
                                                {selectedItemDetails?.item_code}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Warehouse</td>
                                            <td>
                                                {warehouseDetail?.name}
                                            </td>
                                            <td>Customer</td>
                                            <td>
                                                {CustomerDetails?.name}
                                            </td>

                                        </tr>
                                    </>
                                    ) : (
                                        <p>No item selected.</p>
                                    )}

                                </tbody>
                            </table>
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Batch & Expiry List</span>
                            </div>
                            <table
                                className="table table-bordered table-sm bg-white my-2"
                                id="allocationTable"
                            >
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" rowSpan="2" style={{ width: "5%" }} className="text-center">S.No</th>
                                        <th scope="col" style={{ width: "20%" }}>
                                            Batch Number
                                        </th>
                                        <th scope="col" style={{ width: "15%" }}>
                                            Expiry Date
                                        </th>
                                        <th scope="col" style={{ width: "15%" }}>
                                            Total Qty
                                        </th>
                                        <th scope="col" style={{ width: "15%" }}>
                                            Free Qty
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.stock_loading
                                            ? (<tr><td colSpan="10"><Loader /></td></tr>)
                                            : (
                                                batch_data && batch_data.length > 0
                                                    ? (batch_data.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr className='text-center' key={index}>
                                                                    <td className="text-center">
                                                                        <input
                                                                            type="radio"
                                                                            name="selectedBatchNumber"
                                                                            checked={item.selected}
                                                                            onChange={() => this.handleSelectedbatchNumberData(item.item_id, index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.batch_number || "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.expiry_date_display || "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.total_qty || "-"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.free_qty || "-"
                                                                        }
                                                                    </td>
                                                                </tr></>
                                                        )
                                                    }))
                                                    : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                                            )
                                    }
                                </tbody>
                            </table>

                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.handleCancel()}
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                disabled={this.state.loadingItemDetails}
                            >
                                Close{" "}
                            </button>
                            <button onClick={() => this.handleBatchNumber()} className="btn btn-primary" disabled={this.state.loadingItemDetails}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render = () => {
        return (<ApplicationLayout>
            <Helmet><title>Add Sales Invoice</title></Helmet>
            {this.pageTitlejsx()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.invoiceFormDataLoaded
                            ? this.saveInvoiceFormJsx()
                            : <Loader />
                        }
                    </div>
                </div>
            </div>
            {this.pickAllocationItemModalJsx()}
            {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            {this.viewbatchListingModalJsx()}
            <ItemDetailsModal ref={this.itemDetailsRef} />
            <ConsumptionHistoryModal ref={this.invoiceHistoryRef} />
            {this.state.showItemSearchModal && <ItemSearchModal ref={this.itemSearchRef}
                afterSelectingItems={(selectedItems) => {
                    this.addItemsfromSearch(selectedItems);
                }}
            />}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token: state.auth.access_token,
        isc_configuration: state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        iam_group_sites: state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions: state.app.acl_info,
        all_warehouses: state.app && state.app.warehouses ? state.app.warehouses : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : '',
    };
};

export default connect(mapStateToProps)(SalesInvoiceAdd);

