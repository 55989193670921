import React from 'react';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import tapIcon from "../../../services/TapIcon";
import DatePicker from "react-datepicker";
import AssetCard from "../../includes/assetCard/AssetCard";
import TapSelect from "../../../components/ui/TapSelect";

class AddSiteTransition extends React.Component {

    constructor(props) {
        super(props);
        this.state                  =   {
               documents          :   [{
                   name          :     "",
                   number        :     null,
                   details       :    ""
               }],
            attachments          :   [{
                name          :     "",
                quantity        :     null,
                amount        :     null,
                details       :    ""
            }]
        };
    }

    handleDocumentValue(e, i) {
        let documents = [...this.state.documents]
        documents[i] = e.target.value
        this.setState({
            documents
        })
    }


    addDocuments = e => {
        e.preventDefault()
        let documents = this.state.documents.concat([''])
        this.setState({
            documents
        })
    }

    handleAttachmentsValue(e, i) {
        let attachments = [...this.state.attachments]
        attachments[i] = e.target.value
        this.setState({
            attachments
        })
    }


    addAttachments = e => {
        e.preventDefault()
        let attachments = this.state.attachments.concat([''])
        this.setState({
            attachments
        })
    }

    render() {
        return (
            <Ax>
                <div className="page_title row m0">
                    <div className="col-12">
                        <h3>Create New Site Transition</h3>
                        <div className="text-end mt15">
                            <button onClick={this.props.closeEvent} type="button" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="page_containt row">
                        <div className="pageTbl col-9 pl4">
                            <form className="bg-white p-3">
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Next Site Name</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Name"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Date of Exit (Dispatch)</label></div>
                                    <div className="col-md-4 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholderText={`Date of Dispatch`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Quantity</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            value={2}
                                            placeholder="Please Enter Name"
                                        />
                                    </div>
                                </div>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Document Details</span>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Document Number & Date</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Document Number"
                                        />
                                    </div>
                                    <div className="col-md-4 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholderText={`Please Enter Document Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Amount</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Amount"
                                        />
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Eway Bill Number & Date</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Bill Number"
                                        />
                                    </div>
                                    <div className="col-md-4 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholderText={`Please Enter Bill Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                </div>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Transporter Details</span>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Docket Number & Date</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Docket Number"
                                        />
                                    </div>
                                    <div className="col-md-4 add_calender_section">
                                        <DatePicker
                                            name="status_date"
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholderText={`Please Enter Docket Date`}

                                        />
                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                           alt         =      "requisition_date"
                                                           className   =      "add_calender-icon"
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Transporter Name</label></div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Please Enter Transporter Name "
                                        />
                                    </div>

                                </div>
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Change Mobilization Status of Asset To</label></div>
                                    <div className="col-4">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            placeholder="Please Select"
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className="row align-items-center  mt-3">
                                    <div className="col-3">
                                        <label className="form-label">Document Attached</label>
                                    </div>
                                    <div className="col-6">

                                        </div>
                                </div>
                                <div className="row align-items-center mb-3 ">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-9">
                                        <table className="table table-bordered table-hover  bg-white">
                                            <thead className="table-secondary">
                                            <tr>
                                                <th scope="col" >Name</th>
                                                <th scope="col" >Number
                                                </th>
                                                <th>Details</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.documents.map((value, index) => {
                                                return(<tr key={index}>
                                                    <td> <input
                                                        name={value.name}
                                                        type="text"
                                                        value={value.name}
                                                        className="form-control"
                                                        autoComplete="off"

                                                    /></td>
                                                    <td> <input
                                                        name={value.number}
                                                        type="text"
                                                        value={value.number}
                                                        className="form-control"
                                                        autoComplete="off"
                                                    /></td>
                                                    <td> <input
                                                        name={value.details}
                                                        type="text"
                                                        value={value.details}
                                                        className="form-control"
                                                        autoComplete="off"
                                                    /></td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </table>
                                        <div className=" text-end">
                                            <a role="button"  className="link-primary text-decoration-underline" onClick={this.addDocuments}>+Add Row</a>
                                        </div>
                                    </div>

                                </div>
                                <div className="row align-items-center  mt-3">
                                    <div className="col-3">
                                        <label className="form-label">Attachments / Tolls / Spares</label>
                                    </div>
                                    <div className="col-6">

                                    </div>
                                </div>
                                <div className="row align-items-center mb-3 ">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-9 ">
                                        <table className="table table-bordered table-hover  bg-white">
                                        <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" >Name</th>
                                            <th scope="col" >Quantity
                                            </th>
                                            <th scope="col" >Amount
                                            </th>
                                            <th>Details</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.attachments.map((value, index) => {
                                                return(<tr key={index}>
                                                    <td> <input
                                                        name={value.name}
                                                        type="text"
                                                        value={value.name}
                                                        className="form-control"
                                                        autoComplete="off"

                                                    /></td>
                                                    <td> <input
                                                        name={value.quantity}
                                                        type="text"
                                                        value={value.quantity}
                                                        className="form-control"
                                                        autoComplete="off"
                                                    /></td>
                                                    <td> <input
                                                        name={value.amount}
                                                        type="text"
                                                        value={value.amount}
                                                        className="form-control"
                                                        autoComplete="off"
                                                    /></td>
                                                    <td> <input
                                                        name={value.details}
                                                        type="text"
                                                        value={value.details}
                                                        className="form-control"
                                                        autoComplete="off"
                                                    /></td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                       <div className="text-end">
                                           <a role="button"  className="link-primary text-decoration-underline" onClick={this.addAttachments}>+Add Row</a>
                                       </div>
                                    </div>
                                </div>
                                <div className="tab_content_wrapper">
                                    <span className="content_heading">Document Attachments</span>
                                    <div className="text-end">
                                        <a role="button"  className="link-primary text-decoration-underline">+Add</a>
                                    </div>
                                </div>

                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-3"><label className="form-label require">Remarks</label></div>
                                    <div className="col-9">
                                       <textarea
                                           name="remarks"
                                           className="form-control"
                                           style={{height: "80px"}}
                                       />
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <button type="button" className="btn btn-secondary">Cancel</button>
                                    <button type="submit"  className="btn btn-primary mx-3">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="pageTbl col-3 pl0">
                            <div className="bg-white">
                                <AssetCard/>
                            </div>
                        </div>
                    </div>
                </div>
            </Ax>
        );
    }
}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token
    };
};

export default connect(mapStateToProps)(AddSiteTransition);