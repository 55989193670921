import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import { Helmet } from 'react-helmet';
import POQtyDetail from '../../inventory/includes/POQtyDetail';
import RaisePurchaseOrder from '../../inventory/purchaseOrder/RaisePurchaseOrder';
import RequisitionDetailModal from '../../inventory/requisition/RequisitionDetailModal';
import ReceivedQtyDetail from '../../inventory/includes/ReceivedQtyDetail';
import swal from "sweetalert";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';

class ItemWiseRequisition extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            tagged_user_filter               :       '',
            search_transaction_id               :   '',
            search_title                        :   '',
            search_warehouse_ids                :   [],
            date_range                          :   null,
            date_range_start                    :   null,
            date_range_end                      :   null,
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_status_ids                   :   [],
            search_priority                     :   '',
            search_manufacturer_ids             :   [],
            search_asset_ids                    :   [],
            tagged_user_ids                     :   [],
        };
        
        this.reportFilterFormLabel          =   {
            tagged_user_filter                :     'Requisition : ',
            search_transaction_id             :     'Transaction ID : ',
            search_title                      :     'Title : ',
            search_warehouse_ids              :     'Warehouses : ',
            date_range                        :     'Date Range : ',
            search_item_ids                   :     'Items : ',
            search_item_tags                  :     'Item Tags : ',
            search_status_ids                 :     'Search Status : ',
            search_priority                   :     'Search Priority : ',
            search_manufacturer_ids           :     'Manufacturer : ',
            search_asset_ids                  :     'Assets : ',
            tagged_user_ids                   :     'Tagged Users : ',
        };
         
        this.cancelItemFormInit               =       {
            notes                             :       '',
        }
        
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null, 
            allStatusOptions                    :   [],
            allPriorityOptions                  :   [],
            allItemTags                         :   [],
            iam_manufactures                    :   [],
            cancelledItemList                   :   [],
            cancel_item_loading                 :   false,
            disableCancelItem                   :   true,
            cancelItemFormSubmitting            :   false,
            poListLoading                       :   false,
            poList                              :   [],
            searchedAssets                      :   [],
            cancelledItemForm                   :   {...this.cancelItemFormInit},
            userFilters                         :   [
                {value:   'tagged_me',        label: 'My Tagged Requisition'},
                {value:   'created_by_me',    label: 'Requisition Created By Me'},
            ],
            allUsers                            :   [],     
            download_access                     :   false,   
            reportData                          :   null,                
        };

        this.requisitionFormDataUrl         =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-requisition/form_data';
        this.requisitionReportUrl           =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-requisition';   
        this.requisitionCancelItemUrl       =   INVENTORY_API_BASE_URL_2 + '/requisition/cancel_item';
        
        this.pOQtyDetailModalRef            =   React.createRef();
        this.RaisePOModalRef                =   React.createRef();
        this.ReqDetailModalRef              =   React.createRef();
        this.ReqReceivedQtyModalRef         =   React.createRef();
    }
    
    componentDidMount(){
        this.initilaizeAppDataToFilterForm(this.props);
        this.cancelledItemModal                =        new Modal(document.getElementById('cancelledItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_warehouses !== this.props.all_warehouses || nextProps.all_periods !== this.props.all_periods) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }

    initilaizeAppDataToFilterForm           =   (props)  =>  {
        const report_name   =   props?.location?.pathname ? props.location.pathname.split('/')[2] : '';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        
        let all_periods                     =         [...props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        
        HttpAPICall.withAthorization('GET', this.requisitionFormDataUrl, props.access_token, null, null, (response) => {
            this.setState({
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                reportFilterFormData        :   {...response.data},
                all_warehouses              :   props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                formDataLoaded              :   true,
                all_periods                 :   all_periods ,
                iam_manufactures            :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name,level : s.level}; }) : [],
                allPriorityOptions          :   response.data && response.data.priorities && response.data.priorities.length > 0 ? response.data.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                allItemTags                 :   props.item_tags && props.item_tags.length > 0 ? props.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                allUsers                    :   response.data && response.data.users ? response.data.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
            }, () => {
                
            let status_ids               =    [];
            let updateFilterForm        =       {};
            let ParamObject                 =       new URLSearchParams(props.location.search);
          
            if(ParamObject.get('search_status_ids') ||  ParamObject.get('search_item_ids') || ParamObject.get('tagged_user')){
                if(ParamObject.get('search_status_ids')){
                    status_ids  =  ParamObject.get('search_status_ids').split(',').map(Number); 
                    if (ParamObject.get('search_warehouse_ids')) {
                        let warehouse =  this.state.all_warehouses.find(us => us.value == ParamObject.get('search_warehouse_ids'));
                        if (warehouse) {
                            updateFilterForm['search_warehouse_ids'] = [warehouse.value];
                        }
                    }
                }

                if (ParamObject.get('search_item_ids')) {    
                    updateFilterForm['search_item_ids'] = [ParamObject.get('search_item_ids')];   
                }

                if (ParamObject.get('tagged_user')) {
                    updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                }
                
            
            }else if (ParamObject.get('asset_id')) {

              updateFilterForm        =            { date_range: "current_year", search_asset_ids: [ParamObject.get('asset_id')] };

            } else{
                status_ids  =  this.state.allStatusOptions.filter(s => s.level <= 4).map(s => s.value) 
            }
            
            setTimeout(() => {
                this.setState({
                    reportFilterForm        :   {...this.state.reportFilterForm,search_status_ids : status_ids,...updateFilterForm},
                    searchedAssets          :    ParamObject.get('asset_id') ? [{
                                                                        value               :        ParamObject.get('asset_id'),
                                                                        display_label       :       `${ParamObject.get('asset_name')} (${ParamObject.get('asset_code')})`
                                                                    }] : [],
                     searchedItem            :  ParamObject.get('search_item_ids') ?  [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }] : []
                },() => {
                    this.reportFilterFormSubmit()
                })
            },1000)

            } );
        }, (err) => {

        });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                if(key == 'tagged_user_filter') {
                    show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_transaction_id') {
                    show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_transaction_id : '';
                }
                if(key == 'search_title') {
                    show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_title : '';
                }
                if(key == 'search_warehouse_ids') {
                    show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_item_ids') {
                    show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_item_tags') {
                    show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_status_ids') {
                    show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_priority') {
                    show_val            =   this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                }
                if(key == 'search_manufacturer_ids') {
                    show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_asset_ids') {
                    show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'tagged_user_ids') {
                    show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                
                if(key == 'date_range') {
                    let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                    let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.requisitionReportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   ()  =>  {
        let formData                            =   {...this.state.submittedReportFilterForm};
        if(formData.search_transaction_id != '' || formData.date_range != '') {
            this.reportDownloadApiCall(formData);
        } else if((JSON.stringify(formData) === JSON.stringify(this.reportFilterFormInitialState)) || formData.date_range == '') {
            swal({ 
                title: "Download", icon: "warning", buttons: ["Cancel", "Okay"],
                text: "The download will be for Current Quarter, in case you want a different Period then apply the criteria before downloading.",
            }).then(willDownload => {
                if (willDownload) { this.reportDownloadApiCall({...formData, date_range: "current_quarter"}); }
            });
        }
    }
    
    reportDownloadApiCall                   =   (formData)  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.requisitionReportUrl, this.props.access_token, {...formData, download : 'download'} , {} , (response) => DownloadFile.file(null,response.data.navgation,response.data.msg,this.props)).then(() => this.setState({reportDownloading: false}));
    }
    
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    poQtyDetailModalInit                    =   (req_item_id)  =>  {
        //if(this.props.permissions.includes('isc-po-view')){
            this.pOQtyDetailModalRef.current.modalInit(req_item_id);
        //}
       
    }

    reqDetailModalInit          =       (req_item_id)           =>      {
        this.ReqDetailModalRef.current.modalReqInit(req_item_id);  
    }

    reqReceivedQtyModalInit          =       (req_item_id)           =>      {
        this.ReqReceivedQtyModalRef.current.receiviedQtyModalInit(req_item_id,null);  
    }

    checkAllList() {
        let reqItemCheckboxCollections      =   document.getElementsByClassName('req_item_id_checkbox')
        if(reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
            for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
                reqItemCheckboxCollections[i].checked = document.getElementById('all_check_requisition').checked;
            }
        }
    }

    cancelItem       =       ()          =>      {
        this.setState({cancel_item_loading : true,cancelledItemList : []})
        let reqItemCheckboxCollections      =   document.getElementsByClassName('req_item_id_checkbox');
        let cancelledItemList               =   this.state.cancelledItemList;
        var showErrorMessage                =   false;
        if(reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
            for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
                if(reqItemCheckboxCollections[i].checked) {
                    let reqItemId = reqItemCheckboxCollections[i].value;
                  
                    let data = this.state.listingData.find(i => i.req_item_id   == reqItemId);
                    let row                         =   {...cancelledItemList[i],...data };
                    cancelledItemList[i]            =   row;
                    let list = cancelledItemList.filter(Boolean)
            
                
                this.setState({cancelledItemList   :   [...list],
                               cancelledItemForm   :   {...this.cancelItemFormInit,
                               notes : ''}}
                               ,() => this.cancelledItemModal.show() , 
                                      this.setState({cancel_item_loading : false}))
            }
          }
        }

        if(this.state.cancelledItemList.length == 0 && !showErrorMessage){
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT })
        }

    }

    raisePOHandler                  =   ()  =>  {
        let req_item_ids                    =   [];
        let warehouse_ids                   =   [];
        let status_levels                   =   [];
        let pendingQtyList                  =  [];
        let reqItemCheckboxCollections      =   document.getElementsByClassName('req_item_id_checkbox');
        if(reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
            for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
                if(reqItemCheckboxCollections[i].checked) {
                    req_item_ids.push(reqItemCheckboxCollections[i].value);
                    warehouse_ids.push(reqItemCheckboxCollections[i].getAttribute("data-warehouseId"));
                    status_levels.push(reqItemCheckboxCollections[i].getAttribute("data-statusLevel"));
                    pendingQtyList.push(reqItemCheckboxCollections[i].getAttribute("data-pendingQty"));
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (req_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else if(pendingQtyList.includes('0')) {
            toast.error('Every item must have pendning qty more than 0', { position: toast.POSITION.TOP_RIGHT });
        } else if(!status_levels.every((e) => e >= 3 && e < 5)) {
            toast.error('PO can be raised only for Approved Requisition', { position: toast.POSITION.TOP_RIGHT });
        } else {
            this.RaisePOModalRef.current.choosePoModalInit(req_item_ids, warehouse_ids_unique[0]);
        }
    }

    //***************************************PO QTY DETAIL************************************ 


    submitCancelItemForm            =       (e)      =>      {
        e.preventDefault()
        
        let items                   =        [...this.state.cancelledItemList];
          
        let itemsLists              =        items.map(i => {
                                                 return { req_item_id : i.req_item_id,cancelled_qty : parseInt(i.new_cancelled_qty),transaction_id : i.transaction_id};
                                                });
        let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists]}
        this.setState({ cancelItemFormSubmitting: true })
          HttpAPICall.withAthorization('PUT', this.requisitionCancelItemUrl, this.props.access_token, {}, { ...formData }, (response) => {
              toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
              this.cancelledItemModal.hide()
              this.loadListingTblData(1);
        }).then(() => this.setState({ cancelItemFormSubmitting: false }));
      }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    <button type="button" disabled={!this.props.permissions.includes('isc-po-add')} className="btn btn-primary" onClick={this.raisePOHandler}>Raise PO</button> 
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className={"dropdown-item"}  onClick={() => {this.setState({cancelledItemList : []},() => this.cancelItem())}}>Cancel Item</Link></li>
                        <li><Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={this.reportDownloadHandler}>Download</Link></li>
                    </ul>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Requisition</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.userFilters}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_filter')}
                            value={this.state.userFilters.find(u => u.value == this.state.reportFilterForm.tagged_user_filter)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Requisition"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : []
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"

                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Tagged User</label>
                    <div className="col-sm-6">
                        <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Tagged User"
                            options={this.state.allUsers}
                            value={this.state.allUsers.filter(s => this.state.reportFilterForm.tagged_user_ids.includes(s.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_ids')}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Assets</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.all_warehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Please enter Transaction ID"

                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Requisition Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Priority</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allPriorityOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_priority')}
                            value={this.state.allPriorityOptions.find(u => u.value == this.state.reportFilterForm.search_priority)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Priority"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Transaction Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range: period,
                                        date_range_start: startDate ? startDate : null,
                                        date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>

            <table className="table table-bordered  table-sm align-middle bg-white mt-2" id="reqTable">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className="text-center" style={{ width: "5%" }}><input type='checkbox' onChange={this.checkAllList} id="all_check_requisition" /></th>
                        <th style={{ width: "8%" }}>Transaction ID</th>
                        <th scope="col" className='text-center' style={{ width: "10%" }}>Transaction Date</th>
                        <th style={{ width: "12%" }}>Warehouse</th>
                        <th style={{ width: "15%" }}>Item Name</th>
                        <th style={{ width: "15%" }}>Item Code</th>
                        <th style={{ width: "6%" }} className='text-center'>Status</th>
                        <th scope="col" className='text-end' style={{ width: "6%" }}>Req Qty</th>
                        <th scope="col" className='text-end' style={{ width: "6%" }}>PO Qty</th>
                        {/* <th scope="col" className='text-end' style={{ width: "6%" }}>In Transit</th> */}
                        <th scope="col" className='text-end' style={{ width: "6%" }}>Received</th>
                        <th scope="col" className='text-end' style={{ width: "6%" }}>Cancelled</th>
                        <th scope="col" className='text-end' style={{ width: "6%" }}>Pending</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((rowData, k) => {
                            // return rowData.req_items.map((rq, rk) => {
                                return (<tr key={k}>
                                    <td className='text-center'>
                                        <input type='checkbox' 
                                            className="req_item_id_checkbox"
                                            disabled={rowData?.status?.level == 6 || rowData?.status?.level == 5} 
                                            name="foo" 
                                            value={rowData.req_item_id} 
                                            data-warehouseId={rowData.warehouse_id} 
                                            data-statusLevel={rowData?.status?.level}
                                            data-pendingQty={rowData.pending_qty}
                                        /></td>
                                    <td  onClick={() => this.reqDetailModalInit(rowData.transaction_id)}><a hred="#" role="button" className='link-primary'>{rowData.transaction_id}</a></td>
                                    <td>{rowData.transaction_date_display}</td>
                                    <td>{rowData.warehouse_name}</td>
                                    <td>{rowData.item_name}</td>
                                    <td>{rowData.item_code}</td>
                                    <td className='text-center'>{rowData.status && rowData.status.name ? rowData.status.name :  "-"}</td>
                                    <td className='text-end'>{rowData.qty}</td>
                                    <td className='text-end' onClick={() => this.poQtyDetailModalInit(rowData.req_item_id)}><a role="button" className={ 'link-primary cursor_pointer'}>{rowData.current_po_qty}</a></td>
                                    {/* <td className='text-end'>{rq.intransit_qty}</td> */}
                                    <td className='text-end'  onClick={() => this.reqReceivedQtyModalInit(rowData.req_item_id)} ><a role="button" className='link-primary'>{rowData.received_qty}</a></td>
                                    <td className='text-end'>{rowData.cancelled_qty}</td>
                                    <td className='text-end'>{rowData.pending_qty}</td>
                                </tr>);
                           // })
                        }))
                        : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }

     //***********************SEARCH REQUISTION JSX****************
     cancelledItemModalJsx               =        ()             =>        {
      
        return (
            <div className="modal fade" id="cancelledItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Cancel Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ cancelledItemList: [] }) }}></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" style={{ width: "10%" }} className="text-center">Transaction ID</th>
                                                    <th scope="col" style={{ width: "12%" }} className="text-center">Date of Transaction</th>
                                                    <th scope="col" style={{ width: "20%" }} className="text-start" >Item Name</th>
                                                    <th scope="col" style={{ width: "13%" }} className="text-start">Item Code</th>
                                                    <th scope="col" style={{ width: "7%" }}>Req. Qty</th>
                                                    <th scope="col" style={{ width: "6%" }}>PO Qty</th>
                                                    {/* <th scope="col" style={{ width: "9%" }}>In Transit </th> */}
                                                    <th scope="col" style={{ width: "6%" }}>Received</th>
                                                    <th scope="col" style={{ width: "6%" }}>Cancelled </th>
                                                    <th scope="col" style={{ width: "6%" }}>Pending </th>
                                                    <th scope="col" style={{ width: "9%" }}>New Cancelled </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>
                                                        <td className='text-center'>{i.transaction_id}</td>
                                                        <td className='text-center'>{i.transaction_date_display}</td>
                                                        <td >{i.item_name}</td>
                                                        <td >{i.item_code}</td>
                                                        <td className='text-end'>{i.qty ?? "-"}</td>
                                                        <td className="text-end">{i.current_po_qty ?? "-"}</td>
                                                        {/* <td className="text-end">{i.intransit_qty ?? "-"}</td> */}
                                                        <td className="text-end"><a role="button" className='link-primary'>{i.received_qty ?? "-"}</a></td>
                                                        <td className="text-end">{i.cancelled_qty ?? "-"}</td>
                                                        <td className='text-end' >
                                                            {i.pending_qty ?? "-"}</td>
                                                        <td className="text-end"> <input name="new_cancelled_qty"
                                                            type="number"
                                                            value={this.state.cancelledItemList[k]?.new_cancelled_qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'cancelledItemList')}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Cancelled Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                            step=".01"
                                                        /></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }
   
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>ItemWise Requisition - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
            {this.cancelledItemModalJsx()} 
            <RaisePurchaseOrder parentProps={this.props} ref={this.RaisePOModalRef} />
            <RequisitionDetailModal parentProps={this.props} ref={this.ReqDetailModalRef}/>
            <POQtyDetail ref={this.pOQtyDetailModalRef} />
            <ReceivedQtyDetail parentProps={this.props} ref={this.ReqReceivedQtyModalRef}/>
        </ApplicationLayout>);
    }
     
     
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ItemWiseRequisition);