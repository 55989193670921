import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon"
import AppBaseComponent from '../../../../components/AppBaseComponent';
import Ax from "../../../../components/hoc/Ax";
import swal from 'sweetalert';
import Status from '../../../../components/ui/Status';
import { DisplayListPagination } from '../../../../components/TapUi';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { APP_BASE_URL_2 } from '../../../../services/TapApiUrls';

class CNFAgent extends AppBaseComponent {

    constructor(props) {
        super(props);
          
            this.initAddCNFAgentForm  =   {
                name                    :   '',
                status                  :   '',
                id                      :   ''
            }
          this.state                =   {
            list_loading                :   false,
            listingData                 :   [],
            saveFormSubmitting          :   false,
            addCNFAgentForm             :   {...this.initAddCNFAgentForm},
            editFormSubmiting           :   false,
            listingMeta                 :   null,
        };

        this.cnfAddUrl          =   APP_BASE_URL_2 + '/logistic/logistic_config/cnf_agent_add';
        this.cnfListUrl         =   APP_BASE_URL_2 + '/logistic/logistic_config/cnf_agent_list';
        this.cnfUpdateUrl       =   APP_BASE_URL_2 + '/logistic/logistic_config/cnf_agent_update';
        this.cnfStatusUrl       =   APP_BASE_URL_2 + '/logistic/logistic_config/cnf_agent_status';
        this.cnfDeleteUrl       =   APP_BASE_URL_2 + '/logistic/logistic_config/cnf_agent_delete';


    }

    componentDidMount() {
       this.getCNFAgentConfigurationListing(1);
       this.addCNFAgentModal        =        new Modal(document.getElementById('addCNFAgentModal'), {keyboard: false, backdrop :false});
    }

    getCNFAgentConfigurationListing             =         (page = 1)    =>  {
        this.setState({list_loading : true})
        HttpAPICall.withAthorization('GET', this.cnfListUrl, this.props.access_token , {page : page}, {}, (resp) => {
            let respData = resp.data
            this.setState({
                listingData  : respData.data && respData.data.length > 0 ? resp.data.data.map(dt => {return({...dt,value:dt.enc_id,label:dt.name})}) : [],
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            })
        }).then(() => this.setState({list_loading: false}));
    }

    addCNFAgentModalInit          =       (sg)      =>      {
        this.addCNFAgentModal.show();
        if(sg){
            this.setState({
                addCNFAgentForm : {...this.initAddCNFAgentForm,
                    id       :  sg.enc_id,
                    name    :    sg.name,
                    status  :   sg.status.status
                }
            })
        }else{
            this.setState({
                addCNFAgentForm : {...this.initAddCNFAgentForm}
            })
        }
       
    }

    changeStatus                =    (id,status)  =>     {
        swal({
            title: "Change Status",
            text: "Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', this.cnfStatusUrl + '/' + id, this.props.access_token, {},{status : status == 1 ? 2 : 1} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getCNFAgentConfigurationListing();
                    }).then(() => {

                    });
                }
            });
    }

    submitAddCNFAgentForm        =    (e)          =>     {
        e.preventDefault();
    
        if(this.state.addCNFAgentForm && this.state.addCNFAgentForm.id){
            this.setState({ editFormSubmiting: true });
            HttpAPICall.withAthorization('PUT', this.cnfUpdateUrl, this.props.access_token, {}, { ...this.state.addCNFAgentForm, id: this.state.addCNFAgentForm.id }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addCNFAgentModal.hide();
                this.getCNFAgentConfigurationListing();
            }).then(() => this.setState({ editFormSubmiting: false }));
        }else{
            this.setState({ editFormSubmiting: true });
            HttpAPICall.withAthorization('POST', this.cnfAddUrl, this.props.access_token, {}, { ...this.state.addCNFAgentForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addCNFAgentModal.hide();
                this.getCNFAgentConfigurationListing();
            }).then(() => this.setState({ editFormSubmiting: false }));
        }
    }

    addCNFAgentModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="addCNFAgentModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.addCNFAgentForm && this.state.addCNFAgentForm.id ? "Edit" : "Add"} CNFAgent</h5>
                        </div>
                        <form onSubmit={this.submitAddCNFAgentForm}>
                            <div className="modal-body p-3">
                            
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-2"><label className="form-label require">Name</label></div>
                                    <div className="col-md-10">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addCNFAgentForm.name}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addCNFAgentForm")}
                                            placeholder="Please enter  CNFAgent Name"
                                            required={true}
                                        />
                                    </div>

                                </div>
                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Ax>
                <section className="page_containt " style={{ top: "1px" }}>
                    <div className="pageTbl bg-white">
                        <div className="p-2">
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">CNFAgent</span>
                            {
                                (this.props.permissions && this.props.permissions.includes('logistic_config')) &&
                                <div className="text-end "><button className="btn btn-primary" onClick={() => this.addCNFAgentModalInit()}>Add</button></div>
                            }
                            </div>
                            <table className="table table-hover table-bordered my-4 table-sm ">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                                        <th scope="col" className="text-start">Name</th>
                                        <th scope="col" style={{ width: "14%" }}>Status</th>
                                        <th scope="col" style={{ width: "14%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.list_loading ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                        : (this.state.listingData && this.state.listingData.length > 0
                                            ? (this.state.listingData.map((sg, k) => {
                                                return (<tr key={k} className="text-center">
                                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + k :k}</td>
                                                    <td className="text-start">{sg.name ? sg.name : "-"}</td>
                                                    <td>{sg.status && <Status color={sg.status.status_color_code}>{sg.status.status_text}</Status>}</td>
                                                    <td> <span className="dropdown">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn" disabled={!(this.props.permissions && this.props.permissions.includes('logistic_config'))}>
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><Link className="dropdown-item" role="button" onClick={() => this.addCNFAgentModalInit(sg)}>Edit</Link></li>
                                                            <li><Link className="dropdown-item" role="button"  onClick={() => this.changeStatus(sg.enc_id,sg.status.status)}>Change Status</Link></li>
                                                        </ul>
                                                    </span></td>
                                                </tr>);
                                            }))
                                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>))}
                                </tbody>
                            </table>
                            <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.getCNFAgentConfigurationListing(e.selected + 1)} />

                        </div>
                    </div>
                </section>
                {this.addCNFAgentModalJsx()}
            </Ax>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};


export default connect(mapStateToProps)(CNFAgent);