const AssetService         =   {

    avaialbleAssetMeters : function(asset, onlyAvailable = true) {
        let assetMeters             =   [
            {
                key                     :   'hourmeter',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.hour_meter : 'no', 
                label                   :   asset && asset.labels ? asset.labels.hour_meter : 'Hour Meter',
                abs_key                 :   'hours_abs'
            },
            {
                key                     :   'hourmeter2',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.hour_meter2 : 'no', 
                label                   :   asset && asset.labels ? asset.labels.hour_meter2 : 'Hour Meter2',
                abs_key                 :   'hours_abs2'
            },
            {
                key                     :   'hourmeter3',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.hour_meter3 : 'no', 
                label                   :   asset && asset.labels ? asset.labels.hour_meter3 : 'Hour Meter3',
                abs_key                 :   'hours_abs3'
            },
            {
                key                     :   'hourmeter4',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.hour_meter4 : 'no', 
                label                   :   asset && asset.labels ? asset.labels.hour_meter4 : 'Hour Meter4',
                abs_key                 :   'hours_abs4'
            },
            {
                key                     :   'odometer',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.odo_meter : 'no', 
                label                   :   asset && asset.labels ? asset.labels.odo_meter : 'KM',
                abs_key                 :   'odometer_abs'
            },
            {
                key                     :   'odometer2',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.odo_meter2 : 'no', 
                label                   :   asset && asset.labels ? asset.labels.odo_meter2 : 'KM 2',
                abs_key                 :   'odometer_abs2'
            },
            {
                key                     :   'odometer3',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.odo_meter3 : 'no', 
                label                   :   asset && asset.labels ? asset.labels.odo_meter3 : 'KM 3',
                abs_key                 :   'odometer_abs3'
            },
            {
                key                     :   'operation_cycle',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.operation_cycle : 'no', 
                label                   :   asset && asset.labels ? asset.labels.operation_cycle : 'OPC',
                abs_key                 :   'cycle_abs'
            },
            {
                key                     :   'operation_cycle2',
                meter_vailable          :   asset && asset.cl_attribute == 'Y' ? asset.operation_cycle2 : 'no', 
                label                   :   asset && asset.labels ? asset.labels.operation_cycle2 : 'OPC 2',
                abs_key                 :   'cycle_abs2'
            },
        ];

        if(onlyAvailable) {
            return assetMeters.filter(am => am.meter_vailable == 'yes');
        }
        return assetMeters;
    },

    getTaskStartingReadingInputKey : function(meter_key) {
        let input_state_key       =     '';
        if(meter_key == 'hourmeter') {
            input_state_key     =   'start_hours_abs';
        } else if(meter_key == 'hourmeter2') {
            input_state_key     =   'start_hours_abs2';
        } else if(meter_key == 'hourmeter3') {
            input_state_key     =   'start_hours_abs3';
        } else if(meter_key == 'hourmeter4') {
            input_state_key     =   'start_hours_abs4';
        } else if(meter_key == 'odometer') {
            input_state_key     =   'start_odometer_abs';
        } else if(meter_key == 'odometer2') {
            input_state_key     =   'start_odometer_abs2';
        } else if(meter_key == 'odometer3') {
            input_state_key     =   'start_odometer_abs3';
        } else if(meter_key == 'operation_cycle') {
            input_state_key     =   'start_cycle_abs';
        } else if(meter_key == 'operation_cycle2') {
            input_state_key     =   'start_cycle_abs2';
        }
        return input_state_key;
    },
    getTaskCounterLogReadingInputKey : function(meter_key) {
        let input_state_key       =     '';
        if(meter_key == 'hourmeter') {
            input_state_key     =   'cl_hours_abs';
        } else if(meter_key == 'hourmeter2') {
            input_state_key     =   'cl_hours_abs2';
        } else if(meter_key == 'hourmeter3') {
            input_state_key     =   'cl_hours_abs3';
        } else if(meter_key == 'hourmeter4') {
            input_state_key     =   'cl_hours_abs4';
        } else if(meter_key == 'odometer') {
            input_state_key     =   'cl_odometer_abs';
        } else if(meter_key == 'odometer2') {
            input_state_key     =   'cl_odometer_abs2';
        } else if(meter_key == 'odometer3') {
            input_state_key     =   'cl_odometer_abs3';
        } else if(meter_key == 'operation_cycle') {
            input_state_key     =   'cl_cycle_abs';
        } else if(meter_key == 'operation_cycle2') {
            input_state_key     =   'cl_cycle_abs2';
        }
        return input_state_key;
    },
    getTaskClosingReadingInputKey : function(meter_key) {
        let input_state_key       =     '';
        if(meter_key == 'hourmeter') {
            input_state_key     =   'end_hours_abs';
        } else if(meter_key == 'hourmeter2') {
            input_state_key     =   'end_hours_abs2';
        } else if(meter_key == 'hourmeter3') {
            input_state_key     =   'end_hours_abs3';
        } else if(meter_key == 'hourmeter4') {
            input_state_key     =   'end_hours_abs4';
        } else if(meter_key == 'odometer') {
            input_state_key     =   'end_odometer_abs';
        } else if(meter_key == 'odometer2') {
            input_state_key     =   'end_odometer_abs2';
        } else if(meter_key == 'odometer3') {
            input_state_key     =   'end_odometer_abs3';
        } else if(meter_key == 'operation_cycle') {
            input_state_key     =   'end_cycle_abs';
        } else if(meter_key == 'operation_cycle2') {
            input_state_key     =   'end_cycle_abs2';
        }
        return input_state_key;
    },
 
    getCounterlogReadingInputKey : function(meter_key) {
        let input_state_key       =     '';
        if(meter_key == 'hourmeter') {
            input_state_key     =   'hourmeter';
        } else if(meter_key == 'hourmeter2') {
            input_state_key     =   'hourmeter2';
        } else if(meter_key == 'hourmeter3') {
            input_state_key     =   'hourmeter3';
        } else if(meter_key == 'hourmeter4') {
            input_state_key     =   'hourmeter4';
        } else if(meter_key == 'odometer') {
            input_state_key     =   'odometer';
        } else if(meter_key == 'odometer2') {
            input_state_key     =   'odometer2';
        } else if(meter_key == 'odometer3') {
            input_state_key     =   'odometer3';
        } else if(meter_key == 'operation_cycle') {
            input_state_key     =   'operation_cycle';
        } else if(meter_key == 'operation_cycle2') {
            input_state_key     =   'operation_cycle2';
        }
        return input_state_key;
    },

    
};

export default AssetService;