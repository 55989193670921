import React from 'react';
import AppBaseComponent from "../../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import moment from "moment";
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import TapSelect from "../../../../components/ui/TapSelect";
import tapIcon from "../../../../services/TapIcon";
import Status from "../../../../components/ui/Status";
import TapApiUrls from "../../../../services/TapApiUrls";
import HttpAPICall from "../../../../services/HttpAPICall";
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import * as actions from "../../../../store/actions/index";
import UpdateMyDashboardForm from "../../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class RentalStatusWidget extends AppBaseComponent {

    constructor() {
        super();
        this.state                      =   {
            iam_user_sites              :   [],
            iam_asset_types             :   [],
            statusForm                  :   {search_site_ids : [],search_asset_type_ids:[]},
            loading                     :   false,
            data                        :   [],
            time                        :   '',
        }
        this.updateMyDashboardRef      =   React.createRef();
    }


    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('rentalStatusWidget')) {
            let rentalStatusData = JSON.parse(localStorage.getItem('rentalStatusWidget'));
            if (rentalStatusData) {
                let lastRefreshTime = moment(rentalStatusData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('rentalStatusWidget')
                    setTimeout(() => {
                        this.getStatusListing();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 4));
                } else {
                    this.setState({
                        data            :      rentalStatusData,
                        time            :      rentalStatusData.time, 
                    })
                }

            } else {
                setTimeout(() => {
                    this.getStatusListing();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 4));
            }
        } else {
            setTimeout(() => {
                this.getStatusListing();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 4));
            
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites           :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                iam_asset_types          :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            }, () => {
              
                 if(this.props.dashboard_filter_site_id || props.clearDashboardFilter == 'yes'){
                   
                    this.setState({
                        statusForm         :       {
                            search_site_ids          :       props.dashboard_filter_site_id ?  [props.dashboard_filter_site_id] : [],
                            search_asset_type_ids    :       []
                        }
                    },() => this.getStatusListing())
                }
                
            });
        }
    }

    getStatusListing                =   (reload=false)  =>  {
        this.setState({loading: true});
       
        let params          =   {search_site_ids: this.state.statusForm && this.state.statusForm.search_site_ids  && this.state.statusForm.search_site_ids.length > 0 ? [this.state.statusForm.search_site_ids]  : null,
                                search_asset_type_ids : this.state.statusForm && this.state.statusForm.search_asset_type_ids  && this.state.statusForm.search_asset_type_ids.length > 0 ? [this.state.statusForm.search_asset_type_ids]  : null ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM +  '/rental_status_wise_count', this.props.access_token, params, {}, (response) => {
           
            this.setState({
                data            :       response.data,
                time            :       response.data.time, 
            })
            if(this.state.statusForm.search_site_ids && this.state.statusForm.search_site_ids.length == 0 && this.state.statusForm.search_asset_type_ids && this.state.statusForm.search_asset_type_ids.length == 0){
                localStorage.setItem('rentalStatusWidget',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('rentalStatusWidget',JSON.stringify(response.data));
            }
            
        }).then(() => this.setState({ loading: false }));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"commercial_status_count",name:"Commercial Status"})
    }

  
    render                      =   ()  =>  {
        let allStatus           =   this.state.data && this.state.data.status_wise_asset && this.state.data.status_wise_asset.length > 0 && this.state.data.status_wise_asset.map(st => st.id).join(',');
        let filterObj           =   {};
         if(this.state.statusForm){
            if(this.state.statusForm.search_site_ids && this.state.statusForm.search_site_ids.length > 0 ){
                filterObj           =   {...filterObj , 'search_site_ids' : this.state.statusForm.search_site_ids}
            }
            if(this.state.statusForm.search_asset_type_ids && this.state.statusForm.search_asset_type_ids.length > 0 ){
                filterObj           =   {...filterObj , 'search_asset_type_ids' : this.state.statusForm.search_asset_type_ids}
            }
         }
         let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("commercial_status_count" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "commercial_status_count")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Commercial Status</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Asset Types"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_asset_types.filter(r => this.state.statusForm.search_asset_type_ids.includes(r.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'statusForm', 'search_asset_type_ids',() => this.getStatusListing())}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.filter(r => this.state.statusForm.search_site_ids.includes(r.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'statusForm', 'search_site_ids' ,() => this.getStatusListing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={this.getStatusListing}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-6">

                        <table className="table table-hover table-bordered table-borderless bg-white">
                            <thead className="table-secondary">
                                <tr>
                                    <th style={{ width: "50%" }} className="text-center"></th>
                                    <th style={{ width: "25%" }} className="text-center">Measurable</th>
                                    <th style={{ width: "25%" }} className="text-center">Non Measurable</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading
                                    ? <tr><td className="text-center" colSpan="3"><Loader /></td></tr>
                                    : (<Ax>
                                        {this.state.data && this.state.data.status_wise_asset && this.state.data.status_wise_asset.length > 0
                                            ? this.state.data.status_wise_asset.map((status, key) => {
                                                return (<tr key={key} className="text-center">
                                                    <th className="text-start"><Status color={`#${status.color}`}>{status.name}</Status></th>
                                                    <td className="link-primary cursor_pointer">
                                                        <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, asset_category: "Y", search_status_ids: [status.id] }).toString() }}>
                                                            {status.measurable_count}
                                                        </Link>
                                                    </td>
                                                    <td className="link-primary cursor_pointer">
                                                        <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, asset_category: "N", search_status_ids: [status.id] }).toString() }}>
                                                            {status.non_measurable_count}
                                                        </Link></td>
                                                </tr>)
                                            })
                                            : null}

                                        <tr className="text-center">
                                            <th className="text-start">Total</th>
                                            <td className="link-primary cursor_pointer">
                                                {this.state.data && this.state.data.status_wise_asset && this.state.data.status_wise_asset.length > 0 ?
                                                    <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, asset_category: "Y", search_status_ids: allStatus }).toString() }}>{this.state.data.status_wise_asset.reduce((sum, a) => sum + a.measurable_count, 0)}</Link> : 0}
                                            </td>
                                            <td className="link-primary cursor_pointer">
                                                {this.state.data && this.state.data.status_wise_asset && this.state.data.status_wise_asset.length > 0 ?
                                                    <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, asset_category: "N", search_status_ids: allStatus }).toString() }}>{this.state.data.status_wise_asset.reduce((sum, a) => sum + a.non_measurable_count, 0)}</Link> : 0}
                                            </td>

                                        </tr>
                                        <tr>
                                            <th></th>
                                            <td></td>
                                        </tr>
                                        <tr>

                                            <th className="text-start">No Status</th>
                                            <td className="text-center">
                                                {this.state.data && this.state.data.no_status_measurable_count ?
                                                <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, asset_category: "Y",search_status_ids: ["no_status"] }).toString() }}>
                                                 {this.state.data.no_status_measurable_count }
                                                 </Link>
                                                 : 0}
                                            </td>
                                            <td className="text-center">
                                                {this.state.data && this.state.data.no_status_non_measurable_count ? 
                                                <Link target="_blank" to={{ pathname: "/report/asset_rental", search: "?" + new URLSearchParams({ ...filterObj, asset_category: "N",search_status_ids: ["no_status"] }).toString() }}>
                                                {this.state.data.no_status_non_measurable_count }
                                                </Link> : 0}
                                            </td>

                                        </tr>
                                    </Ax>)
                                }

                            </tbody>
                        </table>


                    </div>
                    <div className="col-6 p0">
                        {this.state.loading
                            ? <Loader />
                            : <Chart
                                chartType="PieChart"
                                data={[['Status Name', 'Asset Count']].concat(this.state.data?.status_wise_asset?.map((d) => { return [d.name, d.count]; }))}
                                options={{
                                    legend: { position: 'labeled', labeledValueText: 'both' },
                                    pieSliceText: 'none',
                                    slices: this.state.data?.status_wise_asset?.map((d) => { return { color: `#${d.color}` } }),
                                    chartArea: { width: '90%', height: '90%' },
                                }}
                                rootProps={{ 'data-testid': '1' }} />
                        }
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                        Report as of {this.state.time}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }
    
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(RentalStatusWidget);