import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import TapApiUrls from "../../../services/TapApiUrls";
import moment from "moment";
import * as actions from "../../../store/actions/index"
import UpdateMyDashboardForm from '../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm';
import CurrencyFormatter from '../../../services/CurrencyFormatter';

class PurchaseConsumption extends AppBaseComponent {
 
    options                 =           [
        {value : "qty" , label : "Quantity"},
        {value : "amt" , label : "Amount"}
    ]

    constructor(props) {
        super(props);

        this.initFilterForm             =       {
            warehouse_id                :       '',
            showType                    :       'amt'
        }
     
        this.state                      =        {
            loading                     :       false,
            data                        :       [],
            filterForm                  :       {...this.initFilterForm},
            all_warehouses              :       [],
            remark_time                 :       '',   
        }
        this.updateMyDashboardRef      =   React.createRef();
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('PUCWidget')) {
            let PUCData = JSON.parse(localStorage.getItem('PUCWidget'));
            if (PUCData) {
                let lastRefreshTime = moment(PUCData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('PUCWidget');
                    setTimeout(() => {
                        this.getPucListing()
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 9));
                    
                } else {
                    this.setState({
                        data            :      PUCData.data,
                        remark_time     :      PUCData.time, 
                    })
                }

            }else{
                setTimeout(() => {
                    this.getPucListing()
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 9));
            }
        } else {
            setTimeout(() => {
                this.getPucListing()
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 9));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.all_warehouses !== this.props.all_warehouses || nextProps.dashboard_filter_warehouse_id !== this.props.dashboard_filter_warehouse_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.all_warehouses.length > 0) {
            this.setState({
                all_warehouses                  :   props.all_warehouses
                    ? props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) 
                    : [],
            }, () => {
                
                 if(this.props.dashboard_filter_warehouse_id || props.clearDashboardFilter == "yes"){
                   
                    this.setState({
                        filterForm              :   {
                            warehouse_id                :       props.dashboard_filter_warehouse_id,
                            showType                    :       'amt'
                        },
                        
                    },() =>  this.getPucListing())
                }
               
            });
        }
    }


    getPucListing                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {filter_warehouse_id : this.state.filterForm.warehouse_id ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET', TapApiUrls.INVENTORY + '/purchase_consumption_widget_data', this.props.access_token, {...params}, {} , (response) => {
            this.setState({data : response.data.data,remark_time : response.data.time})
            if(this.state.filterForm.warehouse_id == '' && this.state.filterForm.showType == "amt"){
                localStorage.setItem('PUCWidget',JSON.stringify(response.data));
            }
            if(reload == true){
                localStorage.setItem('PUCWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"puchase_and_consumption",name:"Puchase And Consumption"})
    }


    render                      =   ()  =>  {
        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("puchase_and_consumption" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "puchase_and_consumption")})  : null;
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Purchase & Consumption</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            isSearchable={true}
                            placeholder="Choose"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.options.find(m => m.value == this.state.filterForm.showType)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'showType',)}
                            options={this.options}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.all_warehouses}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.all_warehouses.find(m => m.value == this.state.filterForm.warehouse_id)}
                            placeholder="All Warehouses"
                            containerHeight="33px"
                            fontSize="93%"
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'filterForm', 'warehouse_id', () => this.getPucListing())}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.getPucListing(true)} >Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                {this.state.data && this.state.data.length > 0 
                ?  <div className="col-12 pr0 mr0 text-end ">
                        {this.state.loading
                            ? <Loader />
                            : (<Chart
                                chartType="ColumnChart"
                                data={[['Month', `Purchase`, `Consumption`]].concat(this.state.data?.map((d) => {
                                    return [d.month,
                                    this.state.filterForm.showType == "amt" ? Number(d.purchase.amount) : Number(d.purchase.qty),
                                    this.state.filterForm.showType == "amt" ? d.consumption.amount : d.consumption.qty];
                                }))}
                                options={{
                                    legend: { position: 'left', labeledValueText: 'both' },
                                    targetAxisIndex: 1,
                                    colors: ['#53A8E3', '#EC4E4E'],
                                    chartArea: { right: 10, top: 20, width: "76%", height: "70%" }
                                }}
                                rootProps={{ 'data-testid': '1' }} />)}
                    </div> : null}
                    <div className="col-12">
                        <table className="table table-hover table-bordered table-borderless bg-white my-2">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th style={{ width: "22%" }} className="text-start" >Month</th>
                                    {this.state.data && this.state.data.length > 0 ?
                                        <Ax>
                                            {this.state.data.map((i, r) => {
                                                return (<th className="text-end">{i.month} {i.Year}</th>)
                                            })}
                                        </Ax>
                                        : <th style={{ width: "100%" }}>No Record</th>}
                                </tr>
                            </thead>
                            {this.state.loading
                                ? <Loader />
                                : (<tbody>
                                    <tr className="text-center">
                                        <th className="text-start" >Purchase</th>
                                        {this.state.data && this.state.data.length > 0 ?

                                            <Ax>
                                                {this.state.data.map((i, r) => {
                                                    let month = moment().month(i.month).format("M")
                                                    const startDate = moment([i.Year, month - 1]).format('YYYY-MM-DD')
                                                    // get the number of days for this month
                                                    const daysInMonth = moment(startDate).daysInMonth();

                                                    // we are adding the days in this month to the start date (minus the first day)
                                                    const endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");


                                                    let obj = { 'transaction_period_range_start': startDate, 'transaction_period_range_end': endDate };
                                                    if (this.state.data && this.state.filterForm.warehouse_id) {
                                                        obj['search_warehouse_ids'] = this.state.filterForm.warehouse_id;
                                                    }
                                                    return (
                                                        <Ax>
                                                            {this.state.filterForm.showType == "amt" ?
                                                                <td className="text-end"><Link to={{ pathname: "/purchase_list", search: "?" + new URLSearchParams(obj).toString() }}><CurrencyFormatter currencyCode={this.props.default_currency} amount={parseInt(i.purchase.amount)}/></Link></td>
                                                                : <td className="text-end"><Link to={{ pathname: "/report/item_wise_purchase", search: "?" + new URLSearchParams(obj).toString() }}>{parseInt(i.purchase.qty)}</Link></td>}

                                                        </Ax>
                                                    )
                                                })}
                                            </Ax> : <td colSpan={6}>No Record</td>}

                                    </tr>
                                    <tr className="text-center">
                                        <th className="text-start" >Consumption</th>
                                        {this.state.data && this.state.data.length > 0 ?
                                            <Ax>
                                                {this.state.data.map((i, r) => {
                                                    let month = moment().month(i.month).format("M")
                                                    const startDate = moment([i.Year, month - 1]).format('YYYY-MM-DD')
                                                    // get the number of days for this month
                                                    const daysInMonth = moment(startDate).daysInMonth();

                                                    // we are adding the days in this month to the start date (minus the first day)
                                                    const endDate = moment(startDate).add(daysInMonth - 1, 'days').format("YYYY-MM-DD");

                                                    let obj = { 'date_range_start': startDate, 'date_range_end': endDate };
                                                    if (this.state.data && this.state.filterForm.warehouse_id) {
                                                        obj['search_warehouse_ids'] = this.state.filterForm.warehouse_id;
                                                    }
                                                    return (<Ax>
                                                            {this.state.filterForm.showType == "amt" ?
                                                                <td className="text-end"><Link to={{ pathname: "/consumption_list", search: "?" + new URLSearchParams(obj).toString() }}><CurrencyFormatter currencyCode={this.props.default_currency} amount={parseInt(i.consumption.amount)}/></Link></td>
                                                                : <td className="text-end"><Link to={{ pathname: "/report/item_wise_consumption", search: "?" + new URLSearchParams(obj).toString() }}>{parseInt(i.consumption.qty)}</Link></td>}

                                                        </Ax>)
                                                })}
                                            </Ax> : <td colSpan={6}>No Record</td>}
                                    </tr>
                                    <tr className="text-center">
                                        <th className="text-start" >Difference</th>
                                        {this.state.data && this.state.data.length > 0 ?
                                            <Ax>
                                                {this.state.data.map((i, r) => {
                                                    let purchase_amount = i && i.purchase && i.purchase.amount ? Number(i.purchase.amount) : 0;
                                                    let consumption_amount = i && i.consumption && i.consumption.amount ? Number(i.consumption.amount) : 0;
                                                    let amount = purchase_amount - consumption_amount; 
                                                    return (
                                                        <td className="text-end" >
                                                            {this.state.filterForm.showType == "amt" ? <CurrencyFormatter currencyCode={this.props.default_currency} amount={amount}/>  : parseInt(i.purchase.qty) - parseInt(i.consumption.qty)}
                                                        </td>
                                                    )
                                                })}
                                            </Ax> : <td colSpan={6}>No Record</td>}
                                    </tr>
                                </tbody>)}

                        </table>
                    </div>

                    <div className="col-sm-12 text-muted fs11">
                        Report as of {this.state.remark_time}
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    };
};


export default connect(mapStateToProps)(PurchaseConsumption);