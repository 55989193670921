import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import Ax from "../../../../components/hoc/Ax";
import {Link} from "react-router-dom";
import Loader from "../../../../components/ui/Loader/Loader";
import { Helmet } from 'react-helmet';
import AssetRentalView from './AssetRentalView';
import { Redirect } from 'react-router-dom';

class AssetRental extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                  =        {
            asset_id                :        null,
            listing_loading         :        false,
            
        }
       
    }

    id = this.props.asset_enc_id

    render() {
        let id = this.props.asset_enc_id;
        
        return (
            <Ax>
                <Helmet><title>Asset Rental</title></Helmet>

                {/* <div className="row ml1">
                    <div className="col-12 pl0 bg-white ">
                        <ul className="nav nav-tabs">
                            <li>
                                <Link to={`/asset/dashboard/${id}`} role={"button"}>
                                    <button  className="btn nav-link text-capitalize" type="button" role="button" >
                                        Dashboard
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset/details/${id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize " role="button" >
                                        Details
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset/compliance/${id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Compliance
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset/document/${id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize show " type="button" role="button" >
                                        Document
                                    </button>
                                </Link>
                            </li>

                            {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-rental")
                                ? <li className="nav-item">
                                    <Link to={`/asset/rental/${id}`} role={"button"}>
                                        <button className="btn nav-link text-capitalize show active " type="button" role="button" >
                                            Commercial
                                        </button>
                                    </Link>
                                </li> : null}
                        </ul> */}
                        <div className="page_containt">
                            <div className="pageTbl p-3" style={{ height: "100%" }}>
                                {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-rental")
                                    ? this.state.detailLoading
                                        ? <Loader />
                                        : <div className='pb-5'>
                                            <AssetRentalView showEdit={"Y"} assetId={this.props.asset_enc_id} refreshAssetDetail={this.props.refreshAssetDetail} />
                                        </div>
                                    : <Redirect to={`/asset/dashboard/${id}`} />}

                            </div>
                        </div>
                    {/* </div>
                </div> */}

            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app && state.app.acl_info ? state.app.acl_info : null
    }
}

export default connect(mapStateToProps)(AssetRental);
