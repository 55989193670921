import React from 'react';

import Ax from '../../components/hoc/Ax';
import Loader from "../../components/ui/Loader/Loader";
import TappetLogo from '../../assets/img/svg/logo_new.svg';
import appStyle from './AppInitializeScreen.module.css';

class AppInitializeScreen extends React.Component {
   
    render() {
        return (
            <div className={appStyle.container} >
                <div className={appStyle.wrap}>
                    <img  className="mb-3" src={TappetLogo} alt="TappetBox"/>
                    <Loader />
                </div>
            </div>
        )
    }
}





export default AppInitializeScreen;