import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { toast } from 'react-toastify';
import ConsumptionHistoryModal from '../consumption/ConsumptionHistoryModal';
import POHistoryModal from '../purchaseOrder/POHistoryModal';
import ItemTransactionLedger from '../includes/ItemTransactionLedger';
import TapIcon from '../../../services/TapIcon';
import PurchaseOrderDetailModal from '../purchaseOrder/PurchaseOrderDetailModal';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LogisticViewModal from '../logistic/LogisticViewModal';
import PurchaseDocumentModal from './PurchaseDocumentModal';
import swal from 'sweetalert';

class PurchaseDetail extends React.Component {
    
    constructor(props) {
        super(props);
        this.state                      =   {
            purchaseData                :   null,
            view_loading                :   false,
            isc_configuration           :   null,
            detail_loading              :   false,
            poDetail                    :   null,
            poTransactionId             :   null,
            itemDetails                 :   null,
        };
        this.itemDetailsRef             =   React.createRef();  
        this.consumptionHistoryRef      =   React.createRef();
        this.poHistoryRef               =   React.createRef();
        this.transactionLedgerRef       =   React.createRef();
        this.PODetailModalRef           =   React.createRef();
        this.purchaseDetailModalId      =   uuid();
        this.LogisticViewModalRef       =   React.createRef();
        this.documentRef                =   React.createRef();
        this.purchaseUrl                =   INVENTORY_API_BASE_URL_2 + '/purchase';
        this.purchaseDocUrl             =   INVENTORY_API_BASE_URL_2 + '/purchase_document';
        this.purchaseOrderUrl           =   INVENTORY_API_BASE_URL_2 + '/purchase_order';
     
    }

    componentDidMount() {
        if(this.props){
            this.setState({
                isc_configuration       :        this.props.isc_configuration
            })
        }
        if(this.props && this.props.purchaseId){
            this.getPurchaseDetails(this.props.purchaseId)
        }
        if(this.props && this.props.purchaseData){
            this.setState({purchaseData : this.props.purchaseData})
        }
        this.poDetailModal            =   new Modal(document.getElementById(this.purchaseDetailModalId), {keyboard: false, backdrop :false});
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.purchaseId !== this.props.purchaseId){
            this.getPurchaseDetails(nextProps.purchaseId)
         }
         if(nextProps.purchaseData !== this.props.purchaseData){
             this.setState({purchaseData : nextProps.purchaseData})
         }
         if(nextProps.isc_configuration !== this.props.isc_configuration){
            this.setState({
                isc_configuration       :        nextProps.isc_configuration
            })
        }
         
     }
    

     getPurchaseDetails             =    (transaction_id)        =>  {
        this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET', this.purchaseUrl + '/' + transaction_id, this.props.access_token, {need_documents : "Y"}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 purchaseData            : respData.data,
             });
            
         }).then(() => this.setState({view_loading: false}));
        
     }

     getPODetail        =       (transaction_id)      =>      {
        this.setState({detail_loading : true})
        HttpAPICall.withAthorization('GET', this.purchaseOrderUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                poDetail             :   respData.data,
                poTransactionId      :   respData.data.transaction_id, 
            });
            
        }).then(() => this.setState({detail_loading : false}));      
    }

     viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    viewConsumptionModalInit                       =   (data,k)  =>  {
        if (this.state.purchaseData) {

            let purchaseData = this.state.purchaseData;

            if (data) {
                this.consumptionHistoryRef.current.modalInit(data, null, purchaseData.asset_id)
            }
        }
         
    }

    viewPOHistoryModalInit                       =   (data,k)  =>  {
        if (this.state.purchaseData) {

            let purchaseData = this.state.purchaseData;

            if (data) {
                this.poHistoryRef.current.modalInit(data, purchaseData.warehouse_id)
            }
        }
         
    }

    
    viewTransactionLedgerModalInit               =   (data,k)  =>  {
        if (this.state.purchaseData) {

            let purchaseData = this.state.purchaseData;
            if (data) {
                this.transactionLedgerRef.current.modalInit(data, purchaseData.warehouse_id,purchaseData.warehouse)
            }
        }
         
    }

    checkAllList() {
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox')
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                purchaseItemCollections[i].checked = document.getElementById('all_check_purchase').checked;
            }
        }
    }

    addToConsumptionHandler                  =   ()  =>  {
        
        //  this.props.addToConsumptionHandler
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.purchaseData?.items?.find(data => data.item.id == purchaseItemCollections[i].value);
                   purchase_item_ids.push(selectedPurchaseData)
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else {
            
            let purchaseStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   purchase_item_ids,
            };
             this.props.parentProps.history.push({ pathname: `/consumption_list/add`,  state: { addConsumptionFromPurchase : purchaseStateData } });
        }
    }

    addToInventoryTransferHandler                  =   ()  =>  {
        
        //  this.props.addToConsumptionHandler
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.purchaseData?.items?.find(data => data.item.id == purchaseItemCollections[i].value);
                   purchase_item_ids.push(selectedPurchaseData)
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else {
            
            let purchaseStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   purchase_item_ids,
            };
             this.props.parentProps.history.push({ pathname: `/inventory_transfer_list/add`,  state: { addInventoryFromPurchase : purchaseStateData } });
        }
    }

    addToPurchaseReturnHandler                  =   ()  =>  {
        
        //  this.props.addToConsumptionHandler
        let purchase_item_ids                     =   [];
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    let key = purchaseItemCollections[i].getAttribute("data-itemKey")
                    let selectedPurchaseData = this.state.purchaseData?.items?.find(data => data.purchase_item_key == key);
                    purchase_item_ids.push(selectedPurchaseData)
                }
            }
        }
        
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else {
             
            let purchaseStateData                    =   {   
                purchaseDetail                :   this.state.purchaseData,
                items                       :   purchase_item_ids,
            };
             this.props.parentProps.history.push({ pathname: `/purchase_return_add`,  state: { purchaseData : purchaseStateData } });
        }
    }

    addToAllocationHandler                  =   ()  =>  {
        
        //  this.props.addToConsumptionHandler
        let purchase_item_ids                     =   [];
        let warehouse_ids                   =   [];
       
        let purchaseItemCollections      =   document.getElementsByClassName('purchase_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    warehouse_ids.push(purchaseItemCollections[i].getAttribute("data-warehouseId"));
                    let selectedPurchaseData = this.state.purchaseData?.items?.find(data => data.item.id == purchaseItemCollections[i].value);
                   purchase_item_ids.push(selectedPurchaseData)
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (purchase_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else {
            
            let purchaseStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   purchase_item_ids,
            };
             this.props.parentProps.history.push({ pathname: `/allocation_list/add`,  state: { addAllocationFromPurchase : purchaseStateData } });
        }
    }

    viewPODetail          =       (id)           =>      {
        this.getPODetail(id)  ;
        this.poDetailModal.show() 
    }

    poDetailJsx        =       ()      =>      {
        let purchaseOrder = this.state.poDetail;
        let purchase_summary                    =   [];
        if (purchaseOrder && purchaseOrder.vendor_state == purchaseOrder.warehouse_state) {
             
            purchase_summary.push({ key: 'CGST ', value: "As applicable" });
            purchase_summary.push({ key: 'SGST ', value: "As applicable" });
        } else {
            purchase_summary.push({ key: 'IGST ', value: "As applicable" });
        }

        let sub_total_amount                =      purchaseOrder && purchaseOrder.items &&  purchaseOrder.items.length > 0 && purchaseOrder.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);

        let tcs_amount                          =  purchaseOrder &&  Number(purchaseOrder.tcs_rate && purchaseOrder.tcs_rate >= 0
            ? purchaseOrder && purchaseOrder.tcs_type == "Percentage" ? (sub_total_amount*purchaseOrder.tcs_rate/100) :  Number(purchaseOrder.tcs_rate)
            : 0);
       return (<div className="modal fade" id={this.purchaseDetailModalId} tabIndex="-1">
           <div className="modal-dialog modal-lg modal-dialog-scrollable">
               <div className="modal-content">
                   <div className="modal-header">
                       <h5 className="modal-title" id="searchAssetModalLabel">PO Detail : {this.state.poTransactionId ?? "-"}</h5>
                       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                   </div>

                   {this.state.detail_loading ? <Loader />
                       : <div className="modal-body ">
                           {this.state.poDetail
                               ? <div>
                                   <table className="table table-bordered bg-white" >
                                       <thead className="table-secondary">
                                           <tr>
                                               <th style={{ width: "33%" }}>ID</th>
                                               <th style={{ width: "33%" }}>Date</th>
                                               <th style={{ width: "33%" }}>Status</th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           <tr>
                                               <td scope="col">{purchaseOrder.transaction_id}</td>
                                               <td scope="col">{purchaseOrder.transaction_date_display}</td>
                                               <td scope="col" className="align-middle">
                                                   {purchaseOrder.status_data ? purchaseOrder.status_data.name : ''}
                                               </td>  </tr>
                                       </tbody>
                                   </table>
                                   <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                       <tbody>
                                           <tr>
                                               <td style={{ width: "33%" }} >Warehouse (Delivery Address)</td>
                                               <td colSpan={2}>{purchaseOrder.warehouse ? <div><b>{purchaseOrder.warehouse.name} ({purchaseOrder.warehouse.code})</b> <br />
                                                   {purchaseOrder.warehouse ?
                                                       <div className='pt-1'>Address : {purchaseOrder.warehouse.address1 ? purchaseOrder.warehouse.address1 + ", " : ""}
                                                           {purchaseOrder.warehouse.address2 ? purchaseOrder.warehouse.address2 + ", " : ""}
                                                           {purchaseOrder.warehouse.landmark ? purchaseOrder.warehouse.landmark + ", " : ""}
                                                           {purchaseOrder.warehouse.city ? purchaseOrder.warehouse.city + ". " : "-"} <br />
                                                           Destination of Supply : <b>{purchaseOrder.warehouse.state_name}</b> <br />
                                                           GSTIN  : {purchaseOrder.warehouse_gstin ?? "-"} <br />
                                                       </div> : "-"}</div> : "-"} <br />
                                               </td>
                                           </tr>
                                           <tr>
                                               <td style={{ width: "33%" }} >Vendor</td>
                                               <td colSpan={2}>{purchaseOrder.vendor ? <div><b>{purchaseOrder.vendor.name} ({purchaseOrder.vendor.code})</b> <br />
                                                   {purchaseOrder.vendor_address ?
                                                       <div className='pt-1'>Address : {purchaseOrder.vendor_address.address1 ? purchaseOrder.vendor_address.address1 + ", " : ""}
                                                           {purchaseOrder.vendor_address.address2 ? purchaseOrder.vendor_address.address2 + ", " : ""}
                                                           {purchaseOrder.vendor_address.landmark ? purchaseOrder.vendor_address.landmark + ", " : ""}
                                                           {purchaseOrder.vendor_address.city ? purchaseOrder.vendor_address.city + ". " : "-"} <br />
                                                           Source of Supply : <b>{purchaseOrder.vendor_address.state_name} ({purchaseOrder.vendor_address.country_name})</b> <br />
                                                           GSTIN  : {purchaseOrder.vendor_gstin ?? "-"}</div> : "-"}</div> : "-"}</td>
                                           </tr>
                                           <tr>
                                               <td scope="col">Priority & Delivery Date</td>
                                               <td style={{ width: "33%" }} className="text-capitalize">{purchaseOrder.priority ?? "-"}</td>
                                               <td style={{ width: "34%" }}>{purchaseOrder.expected_delivery_date_display ?? "-"}</td>

                                           </tr>
                                           <tr>
                                               <td scope="col">Quotation Number & Date</td>
                                               <td style={{ width: "33%" }} className="text-capitalize">{purchaseOrder.quotation_name ?? "-"}</td>
                                               <td style={{ width: "34%" }}>{purchaseOrder.quotation_date ?? "-"}</td>

                                           </tr>
                                           <tr>
                                               <td scope="col">Tag Users</td>
                                               <td scope="col">{purchaseOrder && purchaseOrder.usersList && purchaseOrder.usersList.length > 0 ? purchaseOrder.usersList.map(u => <div>{u.full_name}</div>) : ""}</td>
                                           </tr>
                                           {purchaseOrder.po_enable_authorized_signatory && purchaseOrder.po_enable_authorized_signatory == 'Y' && <tr>
                                               <td scope="col">Authorized Signatory</td>
                                               <td style={{ width: "33%" }} className="text-capitalize" colSpan={2}>{purchaseOrder.authorized_signatory && purchaseOrder.authorized_signatory.full_name ? purchaseOrder.authorized_signatory.full_name : "-"}</td>

                                           </tr>}
                                           <tr>
                                               <td scope="col">Currency</td>
                                               <td colSpan={2}><b>{purchaseOrder.currency}</b> {purchaseOrder.currency !== purchaseOrder.base_currency ? <span>(1 {purchaseOrder?.currency} : <NumberFormatter number={purchaseOrder?.conversion_rate} precision={2} /> {purchaseOrder?.base_currency})</span> : null}</td>

                                           </tr>
                                           {purchaseOrder.additional_attributes_data && purchaseOrder.additional_attributes_data.length > 0
                                               ? purchaseOrder.additional_attributes_data.map((attr) => {
                                                   return (<tr key={attr.key}>
                                                       <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                       <td scope="col" colSpan={2}>{attr.value_display ? attr.value_display : "-"}</td>
                                                   </tr>)
                                               }) : null}
                                       </tbody>
                                   </table>

                                   <table className="table table-hover table-bordered table-responsive bg-white my-3" id="poTable">
                                       <thead className="table-secondary">
                                           <tr>
                                               <th style={{ "width": '5%' }} className="text-center"><input type='checkbox'  name="chk[]" /></th>
                                               <th style={{ width: "39%" }}>Item Details</th>
                                               <th style={{ width: "12%" }}>Requisition</th>
                                               <th style={{ width: "12%" }} className="text-end">Quantity</th>
                                               <th style={{ width: "8%" }} className="text-end">Unit Rate</th>
                                               <th style={{ width: "8%" }} className="text-end">Discount</th>
                                               <th style={{ width: "8%" }} className="text-end">Tax</th>
                                               <th style={{ width: "8%" }} className="text-end">Amount</th>
                                               {/* <th style={{ width: "8%" }} className="text-center">Pending</th> */}
                                           </tr>
                                       </thead>
                                       <tbody>
                                           {
                                               purchaseOrder && purchaseOrder.items && purchaseOrder.items.length > 0 ? purchaseOrder.items?.map((i, k) => {
                                                   return (<tr>
                                                       <td>
                                                       {k + 1} <br /> <br />
                                                        <input type='checkbox' name="foo" value={i.item.id} /></td>
                                                       <td>{i.item ? <ItemViewInList view_hsn={true} item={i.item} remark={i.remark}  /> : null}</td>
                                                       <td className="text-center ">
                                                           {i.req_allocation && i.req_allocation.length > 0
                                                               ? (<Ax>
                                                                   {i.req_allocation.map((r, k) => {
                                                                       return <div className='my-1'>{r.requisition_transaction_id}: {r.qty}</div>
                                                                   })}
                                                               </Ax>)
                                                               : null
                                                           }
                                                           {(i.additional_qty > 0 ? <div className='my-1'>ADDL.: {i.additional_qty}</div> : null)}
                                                       </td>
                                                       <td className='text-end'>{i.qty}
                                                            {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}
                                                            <div className='my-1'>Pending : {i.pending_qty}</div>
                                                            {i.cancelled_qty && i.cancelled_qty > 0 ? <div className='my-1'>Cancelled : {i.cancelled_qty}</div> : null}
                                                        </td>
                                                        <td className='text-end'>{i.rate_vc}</td>
                                                        <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? purchaseOrder.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : 0}</td>
                                                        <td className='text-end'>{purchaseOrder && purchaseOrder.vendor_gstin && purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'N' ? <div>{i.gst} %</div> : purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'Y' ? "-" : "NA"}
                                                            {purchaseOrder && purchaseOrder.vendor_gstin && i.item ? <span className="text-helping input-group-text text-end pr0">{purchaseOrder.vendor_address && purchaseOrder.vendor_address.state_name == purchaseOrder.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                                        </td>
                                                        <td className='text-end'>
                                                            <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={parseFloat(i.item_with_discount).toFixed(2)} />
                                                        </td>
                                                   </tr>)

                                               }) : <tr ><td className='text-center' colSpan={8}>No Record</td></tr>
                                           }
                                       </tbody>
                                   </table>
                                   <div className="row align-items-center mb-5">
                                       <div className="col-6">
                                           <label htmlFor="notes" className="form-label">Notes</label>
                                           <div>{purchaseOrder.notes ?? "-"}</div>

                                       </div>
                                       <div className="col-6">
                                       <table className="table  table-borderless">
                                                <tbody>
                                                    <tr className="subtotal_bg_color">
                                                        <th>Sub-Total ({purchaseOrder.currency ? purchaseOrder.currency : "INR"})</th>
                                                        <td className='float-end'>{purchaseOrder && purchaseOrder.items && purchaseOrder.items.length > 0 && <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={purchaseOrder.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />}</td>
                                                        {/* <td className='float-end'>{purchaseOrder.total_amount_vc ? (parseFloat(purchaseOrder.total_amount_vc) - (parseFloat(purchaseOrder.adjustment_amount_vc ?? 0.0) + parseFloat(purchaseOrder.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                                    </tr>
                                                    {purchaseOrder && purchaseOrder.tcs_rate_vc && purchaseOrder.tcs_rate_vc > 0 && <tr className="subtotal_bg_color">
                                                        <th>TCS ({purchaseOrder.tcs_type == "Fixed" ? purchaseOrder.currency : null} {purchaseOrder.tcs_rate_vc} {purchaseOrder.tcs_type == "Percentage" ? "%" : null} )</th>
                                                        <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>
                                                        {/* <td className='float-end'>{purchaseOrder.total_amount_vc ? (parseFloat(purchaseOrder.total_amount_vc) - (parseFloat(purchaseOrder.adjustment_amount_vc ?? 0.0) + parseFloat(purchaseOrder.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                                    </tr>}
                                                    {purchaseOrder.tax_slabs && purchaseOrder.tax_slabs.length > 0 && purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'N'
                                                        ? (purchaseOrder.tax_slabs.map((ps, k) => {
                                                            return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                        }))
                                                        : null
                                                    }
                                                    {purchaseOrder.gst_as_applicable && purchaseOrder.gst_as_applicable == 'Y' &&
                                                        purchase_summary.map(ps => {
                                                            return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                                        })}
                                                    <tr className="subtotal_bg_color">
                                                        <th>Adjustment</th>
                                                        <td className='float-end'>{purchaseOrder.adjustment_amount_vc ?? 0}</td>
                                                    </tr>
                                                    <tr className="subtotal_bg_color">
                                                        <th>Final Total ({purchaseOrder.currency ? purchaseOrder.currency : "INR"})</th>
                                                        <td className='float-end'>{purchaseOrder.total_amount_vc ? <CurrencyFormatter currencyCode={purchaseOrder.currency} amount={purchaseOrder.total_amount_vc} /> : 0}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                       </div>
                                   </div>
                                   <div className="tab_content_wrapper content_heading">Terms & Condition</div>
                                   <table className="table table-hover table-bordered bg-white my-3">
                                       <thead className='table-secondary'>
                                           <tr>
                                               <th className='text-center' style={{ width: "10%" }}>S.No</th>
                                               <th className='text-start' style={{ width: "25%" }}>Name</th>
                                               <th className='text-start' style={{ width: "65%" }}>Description</th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           {
                                               purchaseOrder.tnc && purchaseOrder.tnc.length > 0 ? purchaseOrder.tnc.map((tc, k) => {
                                                   return (<tr key={k}>
                                                       <td className='text-center'>{k + 1}</td>
                                                       <td>{tc.name}</td>
                                                       <td>{tc.text}</td>
                                                   </tr>);
                                               })
                                                   : <tr ><td className='text-center' colSpan={3}>No Record</td></tr>
                                           }

                                       </tbody>
                                   </table>
                               </div>
                               : null}
                       </div>}

               </div>
           </div>
       </div>)
   }

   logisticDetailModal = (val) => {
    this.LogisticViewModalRef.current.showModalInit(val);
   }
   handleDocumentModal  =   ()  =>  {
    this.documentRef.current.showModalInit();
   }

   deletePurchaseDocument   =   (doc_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.purchaseDocUrl + '/delete/' + doc_id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.getPurchaseDetails(this.state.purchaseData.transaction_id);
                }).then(() => { });
            }
        });
    }
    
    render() {
        let purchaseDetails = this.state.purchaseData
          let sub_total_amount                =      purchaseDetails && purchaseDetails.items &&  purchaseDetails.items.length > 0 && purchaseDetails.items.map(s => s.item_with_discount).reduce((a, b) => a + b, 0);
 
         let tcs_amount                          =  purchaseDetails &&  Number(purchaseDetails.tcs_rate && purchaseDetails.tcs_rate >= 0
             ? purchaseDetails && purchaseDetails.tcs_type == "Percentage" ? (sub_total_amount*purchaseDetails.tcs_rate/100) :  Number(purchaseDetails.tcs_rate)
             : 0);
        return (<Ax>
            {this.state.view_loading ? <Loader /> :
                purchaseDetails ? (<div>
                    <table className="table table-bordered bg-white">
                        <thead className="table-secondary">
                            <tr>
                                <th style={{ width: "33%" }}>ID</th>
                                <th style={{ width: "33%" }}>Date</th>
                                <th style={{ width: "33%" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{purchaseDetails.transaction_id ? purchaseDetails.transaction_id : "-"}</td>
                                <td scope="col">{purchaseDetails.transaction_date_display ? purchaseDetails.transaction_date_display : "-"}</td>
                                <td scope="col" className="align-middle">{purchaseDetails.status_data ? purchaseDetails.status_data.name : "-"}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="tab_content_wrapper" >
                        <span className="content_heading">Purchase Detail</span>
                    </div>

                    <table className="table table-hover table-bordered  table-responsive bg-white mb-0  mt-3 ">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }} >Warehouse</td>
                                <td >{purchaseDetails.warehouse ? <div><b>{purchaseDetails.warehouse.name} ({purchaseDetails.warehouse.code})</b> <br />
                                    {purchaseDetails.warehouse_address ?
                                        <div className='pt-1'>Address : {purchaseDetails.warehouse_address.address1 ? purchaseDetails.warehouse_address.address1 + ", " : ""}
                                            {purchaseDetails.warehouse_address.address2 ? purchaseDetails.warehouse_address.address2 + ", " : ""}
                                            {purchaseDetails.warehouse_address.landmark ? purchaseDetails.warehouse_address.landmark + ", " : ""}
                                            {purchaseDetails.warehouse_address.city ? purchaseDetails.warehouse_address.city + ". " : "-"} <br />
                                            Destination of Supply : <b>{purchaseDetails.warehouse_address.state_name}</b> <br />
                                            GSTIN  : {purchaseDetails.warehouse_gstin ?? "-"}</div> : "-"}</div> : "-"}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }} >Vendor</td>
                                <td >{purchaseDetails.vendor ? <div><b>{purchaseDetails.vendor.name} ({purchaseDetails.vendor.code})</b> <br />
                                    {purchaseDetails.vendor_address ?
                                        <div className='pt-1'>Address : {purchaseDetails.vendor_address.address1 ? purchaseDetails.vendor_address.address1 + ", " : ""}
                                            {purchaseDetails.vendor_address.address2 ? purchaseDetails.vendor_address.address2 + ", " : ""}
                                            {purchaseDetails.vendor_address.landmark ? purchaseDetails.vendor_address.landmark + ", " : ""}
                                            {purchaseDetails.vendor_address.city ? purchaseDetails.vendor_address.city + ". " : "-"} <br />
                                            Source of Supply : <b>{purchaseDetails.vendor_address.state_name} ({purchaseDetails.vendor_address.country_name})</b> <br />
                                            GSTIN  : {purchaseDetails.vendor_gstin ?? "-"}</div> : "-"}</div> : "-"}</td>
                            </tr>

                        </tbody>
                    </table>
                    <table className="table table-hover table-bordered  table-responsive bg-white">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }}>Bill Number & Date</td>
                                <td style={{ width: "33%" }}>{purchaseDetails.purchase_bill_number ? purchaseDetails.purchase_bill_number : "-"}</td>
                                <td style={{ width: "34%" }}>{purchaseDetails.purchase_bill_date ? purchaseDetails.purchase_bill_date_display : "-"}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Eway Number & Date</td>
                                <td style={{ width: "33%" }}>{purchaseDetails.e_way_bill_number ? purchaseDetails.e_way_bill_number : "-"}</td>
                                <td style={{ width: "34%" }}>{purchaseDetails.e_way_bill_date_display ? purchaseDetails.e_way_bill_date_display : "-"}</td>
                            </tr>
                            <tr>
                                <td scope="col">Currency</td>
                                <td colSpan={2}><b>{purchaseDetails.currency}</b> {purchaseDetails.currency !== purchaseDetails.base_currency ? <span>(1 {purchaseDetails?.currency} : <NumberFormatter number={purchaseDetails?.conversion_rate} precision={2} /> {purchaseDetails?.base_currency})</span> : null}</td>

                            </tr>
                            {/* <tr>
                        <td style={{ width: "33%" }}>PO Number & Date</td>
                        <td style={{ width: "33%" }}>{purchaseDetails.purchase_order_number ? purchaseDetails.purchase_order_number : "-"}</td>
                        <td style={{ width: "34%" }}>{purchaseDetails.purchase_order_date ? purchaseDetails.purchase_order_date_display : "-"}</td>
                    </tr> */}
                            <tr>
                                <td style={{ width: "33%" }}>Paid from Wallet</td>
                                <td colSpan={"2"}>{purchaseDetails.wallet ? purchaseDetails.wallet.wallet_name : "-"}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Logistic Details</td>
                                <td colSpan={"2"}>
                                {
                                    (purchaseDetails.logistic_tansaction_ids && purchaseDetails.logistic_tansaction_ids.length > 0) &&
                                    purchaseDetails.logistic_tansaction_ids.map((val, i) => {
                                        return <Link key={i} onClick={() => this.logisticDetailModal(val)}>{val} {(i !== purchaseDetails.logistic_tansaction_ids.length -1) ? ', ' : ''}</Link>;
                                    })
                                }
                                </td>
                            </tr>
                            {
                                (purchaseDetails.additional_attributes_data && purchaseDetails.additional_attributes_data.length > 0) &&
                                purchaseDetails.additional_attributes_data.map((attribute, index) => {
                                    return (<tr key={index}>
                                        <td style={{ width: "33%" }}>{attribute.name}</td>
                                        <td colSpan={"2"}>{attribute.value_display ? attribute.value_display : "-"}</td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </table>

                    {purchaseDetails.transaction_type == "purchase_consumption" && purchaseDetails.consumption
                        ? <Ax>
                            <div className="tab_content_wrapper mt-4" >
                                <span className="content_heading">Consumption Detail</span>
                            </div>

                            <table className="table table-hover table-bordered  table-responsive bg-white my-3 ">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >{purchaseDetails.consumption && purchaseDetails.consumption.assetProfile ? "Asset" : purchaseDetails.consumption && purchaseDetails.consumption.workstation ? "Workstation" : "Employee"}</td>
                                        <td colSpan={2}>{purchaseDetails.consumption && purchaseDetails.consumption.assetProfile ?
                                            <div className='text-capitalize'><b>{purchaseDetails.consumption.assetProfile.name} ({purchaseDetails.consumption.assetProfile?.asset_code ?? "-"})</b> <br />
                                                Asset Type (Asset Matrix) : {purchaseDetails.consumption.assetProfile.asset_type_name ?? "-"} ({purchaseDetails.consumption.assetProfile.asset_matrix_unique_id ?? "-"}) <br />
                                                Manufacturer (Model) : {purchaseDetails.consumption.assetProfile.manufacturer_name ?? "-"} ({purchaseDetails.consumption.assetProfile.model_name ?? "-"})<br />
                                                <br />
                                                Counterlog : <b>{purchaseDetails.consumption && purchaseDetails.consumption.counterLog && purchaseDetails.consumption.counterLog.display.length > 0 ? purchaseDetails.consumption.counterLog.display.map(cl => cl).join(" | ") : "NA"}</b></div>
                                            : purchaseDetails.consumption && purchaseDetails.consumption.workstation ?
                                                <div><b>{purchaseDetails.consumption && purchaseDetails.consumption?.workstation?.workstation_name} ({purchaseDetails.consumption?.workstation?.workstation_nickname ?? "-"})</b></div>
                                                : <div><b>{purchaseDetails.consumption && purchaseDetails.consumption?.employee?.display_full_name}</b><br />
                                                    Mobile Number : {purchaseDetails.consumption?.employee?.mobile_display}<br />
                                                    Status : <Status color={purchaseDetails.consumption?.employee?.status_color}>{purchaseDetails.consumption?.employee?.status_text}</Status>
                                                </div>
                                        }</td>

                                    </tr>

                                    <tr>
                                        <td style={{ width: "33%" }}>Voucher Number & Date</td>
                                        <td style={{ width: "33%" }} >{purchaseDetails.consumption && purchaseDetails.consumption.voucher_number ? purchaseDetails.consumption.voucher_number : "-"}</td>
                                        <td style={{ width: "34%" }} >{purchaseDetails.consumption && purchaseDetails.consumption.voucher_date_display ? purchaseDetails.consumption.voucher_date_display : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }}>Consumption Price</td>
                                        <td colSpan={2}>{purchaseDetails.consumption && purchaseDetails.consumption.consumption_price_as == "purchase_price"
                                            ? "Consumption Price = Purchase Price"
                                            : purchaseDetails.consumption.consumption_price_as == "purchase_price_gst"
                                                ? "Consumption Price = Purchase Price +  GST"
                                                : "Consumption Price =  Item Consumption Price as per Item Profile"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Ax>
                        : null}

                    <div className="tab_content_wrapper mt-4" >
                        <span className="content_heading">Item Detail</span>
                    </div>
                    <table className="table table-bordered table-responsive bg-white mt-3">
                        <thead className="table-secondary">
                            <tr>
                                <th scope="col" style={{ width: "5%" }}><input type='checkbox' id="all_check_purchase" onChange={this.checkAllList} /></th>
                                <th scope="col" className="text-center" style={{ width: "30%" }}>Item Details</th>
                                <th scope="col" className="text-center" style={{ width: "15%" }}>Allocation</th>
                                <th scope="col" className="text-end" style={{ width: "11%" }}>Quantity</th>
                                <th scope="col" className="text-end" style={{ width: "11%" }}>Unit Rate</th>
                                <th scope="col" className="text-end" style={{ width: "10%" }}>Discount</th>
                                <th scope="col" className="text-end" style={{ width: "10%" }}>GST</th>
                                <th scope="col" className="text-end" style={{ width: "10%" }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                purchaseDetails && purchaseDetails.items && purchaseDetails.items.length > 0 ? purchaseDetails.items?.map((i, k) => {
                                    let item_with_discount = 0;
                                    let item_final_amount = 0;
                                    let item_amount = parseFloat(i.qty * i.rate_vc);
                                    let discount_amount = parseFloat(i.discount_rate_vc && i.discount_rate_rc > 0
                                        ? i.discount_type == "Percentage" ? (item_amount * i.discount_rate_vc / 100) : i.discount_rate
                                        : 0);
                                    item_with_discount = item_amount - discount_amount;
                                   
                                    return (<tr>
                                        <td className='text-center'>
                                            {k + 1} <br />
                                            <input type='checkbox'
                                                className="purchase_item_id_checkbox"
                                                name="purchase"
                                                value={i.item?.id}
                                                data-warehouseId={purchaseDetails.warehouse_id}
                                                data-itemKey={i.purchase_item_key}
                                            /></td>
                                        <td>
                                            <div className='row'>
                                                <div className='col-sm-9'>
                                                    <ItemViewInList view_hsn={true} item={i.item} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.item)} />
                                                    
                                                </div>
                                                <div className='col-sm-3 text-end'>
                                                    <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.item, k)}>Consumption History</a></li>
                                                        {this.props?.permissions?.includes("isc-po-view") && <li><a href="#" className="dropdown-item" onClick={() => this.viewPOHistoryModalInit(i.item, k)}>PO History</a></li>}
                                                        <li><a href="#" className="dropdown-item" onClick={() => this.viewTransactionLedgerModalInit(i.item, k)}>Item Transaction Ledger</a></li>
                                                    </ul>

                                                </div>
                                                <div className='col-sm-12'>
                                                    {this.props.isc_configuration?.track_batch_expiry == "Y" && i && i.batch_list && i.batch_list.length == 1 && i.batch_list.every(be => !be.batch_number && !be.expiry_date && Number(be.purchase_return_qty) == 0) ? null :
                                                      this.props.isc_configuration?.track_batch_expiry == "Y"   ? (<Ax><table className="table table-bordered  my-2 table-sm text-sm ">

                                                            <tbody>
                                                                <tr>
                                                                    <td scope="col" style={{ width: "5%" }}>S.No</td>
                                                                    <td scope="col" className="text-center" style={{ width: "30%" }}>Batch No</td>
                                                                    <td scope="col" className="text-center" style={{ width: "30%" }}>Expiry Date</td>
                                                                    <td scope="col" className="text-center" style={{ width: "15%" }}>Qty</td>
                                                                    <td scope="col" className="text-center" style={{ width: "15%" }}>Return Qty</td>
                                                                </tr>
                                                                {i && i.batch_list && i.batch_list.length > 0 && i.batch_list.map((be, batchIndex) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className="text-center">{batchIndex + 1}</td>
                                                                            <td>{be.batch_number ? be.batch_number : ""}</td>
                                                                            <td>{be.expiry_date_display ? be.expiry_date_display : ""}</td>
                                                                            <td className="text-center">{be.qty ? be.qty : ""}</td>
                                                                            <td className="text-center">{be.purchase_return_qty ? Number(be.purchase_return_qty) : ""}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        </Ax>)
                                                        : null}
                                                </div>
                                            </div>
                                        </td>

                                        <td className='text-center'>
                                            {i.po_item_id && i.req_item_id
                                                ? <span onClick={() => this.viewPODetail(i.po_transaction_id)} className='link-primary cursor_pointer'>{i.po_transaction_id} : {i.qty}</span>
                                                : i.po_item_id
                                                    ? <span onClick={() => this.viewPODetail(i.po_transaction_id)} className='link-primary cursor_pointer'>{i.po_transaction_id} : {i.qty}</span> :
                                                    i.req_item_id ? <span>{i.requisition_transaction_id} : {i.qty}</span> : <span>Unallocated : {i.qty}</span>}
                                        </td>
                                        <td className='text-end'>{i.qty}
                                            {i.item.measuring_unit && i.item.measuring_unit.name ? <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span> : null}
                                        </td>
                                        <td className='text-end'>{i.rate_vc}</td>
                                        <td className='text-end'>{i.discount_rate_vc ? <div>{i.discount_type == "Fixed" ? purchaseDetails.currency : null} {i.discount_rate_vc} {i.discount_type == "Percentage" ? "%" : null}</div> : ""}</td>

                                        <td className='text-end'>{purchaseDetails && purchaseDetails.vendor_gstin  && purchaseDetails.gst_not_applicable && purchaseDetails.gst_not_applicable == 'N' ? <div>{i.gst} %</div> : "NA"}
                                            {purchaseDetails && purchaseDetails.vendor_gstin && i.item && purchaseDetails.gst_not_applicable == 'N' ? <span className="text-helping input-group-text text-end pr0">{purchaseDetails.vendor_address && purchaseDetails.vendor_address.state_name == purchaseDetails.warehouse_address.state_name ? "GST" : "IGST"}</span> : null}
                                        </td>

                                        <td className='text-end'>
                                            <CurrencyFormatter currencyCode={purchaseDetails.currency} amount={i.item_with_discount} />
                                        </td>
                                    </tr>)

                                }) : <tr ><td className='text-center' colSpan={7}>No Record</td></tr>
                            }
                        </tbody>
                    </table>
                    <div className="row align-items-center mb-5">
                        <div className="col-6">
                            <label htmlFor="notes" className="form-label">Notes</label>
                            <div>{purchaseDetails.notes ?? "-"}</div>

                        </div>
                        <div className="col-6">
                            <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                            <table className="table  table-borderless">
                                <tbody>
                                    <tr className="subtotal_bg_color">
                                        <th>Sub-Total ({purchaseDetails.currency ? purchaseDetails.currency : "INR"})</th>
                                        <td className='float-end'>{purchaseDetails && purchaseDetails.items && purchaseDetails.items.length > 0 &&
                                            <CurrencyFormatter currencyCode={purchaseDetails.currency} amount={purchaseDetails.items.map(s => Number(s.item_with_discount)).reduce((a, b) => a + b, 0).toFixed(2)} />
                                        }
                                        </td>
                                        {/* <td className='float-end'>{purchaseDetails.total_amount_vc ? parseFloat(purchaseDetails.total_amount_vc - (Number(purchaseDetails.adjustment_amount_vc) + Number(purchaseDetails.total_tax_vc))).toFixed(2) : 0.00}</td> */}
                                    </tr>
                                    {purchaseDetails && purchaseDetails.tcs_rate_vc && purchaseDetails.tcs_rate_vc > 0 ? <tr className="subtotal_bg_color">
                                        <th>TCS ({purchaseDetails.tcs_type == "Fixed" ? purchaseDetails.currency : null} {purchaseDetails.tcs_rate_vc} {purchaseDetails.tcs_type == "Percentage" ? "%" : null} )</th>
                                        <td className='float-end'><NumberFormatter number={tcs_amount} precision={2} /></td>
                                     
                                    </tr> : ""}
                                    {purchaseDetails.tax_slabs && purchaseDetails.tax_slabs.length > 0
                                        ? (purchaseDetails.tax_slabs.map((ps, k) => {
                                            return <tr className="subtotal_bg_color"><th>{ps.key}</th><td className="text-end">{ps.value}</td></tr>
                                        }))
                                        : null
                                    }
                                    <tr className="subtotal_bg_color">
                                        <th>Adjustment</th>
                                        <td className='float-end'>{purchaseDetails.adjustment_amount_vc}</td>
                                    </tr>
                                    <tr className="subtotal_bg_color">
                                        <th>Final Total ({purchaseDetails.currency ? purchaseDetails.currency : "INR"})</th>
                                        <td className='float-end'>{purchaseDetails.total_amount_vc ?  <CurrencyFormatter currencyCode={purchaseDetails.currency} amount={parseFloat(purchaseDetails.total_amount_vc).toFixed(2)}/> : 0}</td>
                                    </tr> 
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr />
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Document</span>
                        {
                            this.props.permissions.includes('isc_purchase_doc_add')&&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleDocumentModal()} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPlus} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-bordered bg-white my-2">
                        <tbody>
                        {
                            purchaseDetails.documents && purchaseDetails.documents.length > 0
                            ? purchaseDetails.documents.map((d, i) => {
                                return <tr key={i}>
                                    <td scope="col" style={{ width: "5%" }}>{i + 1}</td>
                                    <td  scope="col" style={{ width: "65%" }} className="text-capitalize">
                                            {d.name}
                                            {d.number && `(${d.number})`}
                                    </td>
                                    {d.longitude && d.latitue && (
                                        <td scope="col" style={{ width: "6%" }} className="text-center" >
                                        <Link to={`https://www.google.com/maps/search/${d.latitude},${d.longitude}`} target="_blank"><TapIcon.FontAwesomeIcon
                                            icon={TapIcon.faMapMarker}/>
                                        </Link>
                                        </td>
                                    )}
                                    <td scope="col" className="text-center" style={{ width: "6%" }} >
                                        <Link to={{pathname:  d.document }} target="_blank" 
                                        className={[d.view_permission ? '' : 'disabled-link'].join(' ')}
                                        title={d.view_permission ? '' : 'You do not have permission to perform this action'}
                                        style={{ "pointerEvents": "all" }}
                                        >
                                        <TapIcon.FontAwesomeIcon
                                            icon={TapIcon.faEye}
                                        />
                                        </Link>
                                    </td>
                                    <td scope="col" style={{ width: "6%" }} className="text-center">
                                        <Link to={{pathname : d.download_link}} target='_blank' 
                                        className={[d.downlaod_permission ? '' : 'disabled-link'].join(' ')}
                                        title={d.downlaod_permission ? '' : 'You do not have permission to perform this action'}
                                        style={{ "pointerEvents": "all" }}
                                        >
                                        <TapIcon.FontAwesomeIcon
                                            icon={TapIcon.faDownload}
                                        />
                                        </Link>
                                    </td>
                                    <td className="text-center" style={{ width: "6%" }}> 
                                        <Link 
                                        className={[this.props.permissions.includes('isc_purchase_doc_delete') ? '' : 'disabled-link'].join(' ')}
                                        style={{ "pointerEvents": "all" }}
                                        onClick={() => { this.props.permissions.includes('isc_purchase_doc_delete') && this.deletePurchaseDocument(d.doc_id)}}
                                        ><TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt}
                                        className="asset_link"/></Link>
                                    </td>
                                </tr>
                            })
                            : <tr><td className='text-center' colSpan="6">No Document Found</td></tr>
                        }
                        </tbody>
                    </table>
                    <ItemDetailsModal ref={this.itemDetailsRef} />
                    <ConsumptionHistoryModal ref={this.consumptionHistoryRef} />
                    <POHistoryModal ref={this.poHistoryRef} />
                    <ItemTransactionLedger ref={this.transactionLedgerRef} />
                    <PurchaseOrderDetailModal parentProps={this.props.parentProps} ref={this.PODetailModalRef} />
                    <PurchaseDocumentModal ref={this.documentRef} purchaseData={this.state.purchaseData} getPurchaseDetails={(purID) => this.getPurchaseDetails(purID)}/>
                </div>) : null}
                <LogisticViewModal ref={this.LogisticViewModalRef}/>
            {this.poDetailJsx()}
        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PurchaseDetail);