
import React, { useEffect, useState, useRef } from 'react';
import Ax from '../../../components/hoc/Ax';
import tapIcon from "../../../services/TapIcon";
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment/moment';
import DatePicker from "react-datepicker";
import Loader from '../../../components/ui/Loader/Loader';
import { Modal } from 'bootstrap';
import HttpAPICall from '../../../services/HttpAPICall';
import { useSelector } from 'react-redux';
import { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { ToastContainer, toast } from 'react-toastify';
import swal from "sweetalert";
import DateService from '../../../services/DateService';
import {useParams} from 'react-router-dom';
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';
import { Helmet } from 'react-helmet';

const AssetMatrixCompliance = () => {

    const { id : asset_id }             =   useParams();
    const access_token                  =   useSelector(state => state.auth.access_token);

    const [assetComplianceList, setAssetComplianceList] =   useState([]);
    const [isRemarkUpdate, setIsRemarkUpdate]           =   useState(false);
    const [saveFormSubmitting, setSaveFormSubmitting]   =   useState(false);
    const [addComplianceForm, setAddComplianceForm]     =   useState({
        asset_id: "",
        compliance_type_id: "",
        renewal_type: 'due_date',
        renewal_date: "",
        remarks: '',
    });
    const [complianceName, setComplianceName]           =   useState('');
    const [loading, setLoading]                         =   useState(false);
    const modalRef                                      =   useRef(null);
    const [listingMeta, setListingMeta]                 =   useState(null);

    useEffect(() => {
        modalRef.current = new Modal(
            document.getElementById("assetMatrixComplianceModal"),
            { keyboard: false, backdrop: false }
        );
    }, []);

    useEffect(() => {
        getComplianceData();        
    }, [saveFormSubmitting]);

    const getComplianceData = (page = 1) => {
        setLoading(true);
        const complianceUrl = `${IAM_API_BASE_URL_2}/asset_matrix_compliance/list/${asset_id}`;
        HttpAPICall.withAthorization("GET", complianceUrl, access_token, {page}, {}, (response) => {
            const respData          =   response.data;  
            setAssetComplianceList(respData.data);
            setListingMeta({
                from                        :   respData.from,
                to                          :   respData.to,
                total                       :   respData.total,
                last_page                   :   respData.last_page,
                per_page                    :   respData.per_page,
                current_page                :   respData.current_page
            });
            setLoading(false);
        }).catch((error) => {
            toast.error("Failed to fetch compliance data.");
            setLoading(false);
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSaveFormSubmitting(true);
        editCompliance();

    };

    const handleUpdateCompliance = (item) => {
        modalRef.current.show();
        setComplianceName(item.compliance_name);
        setAddComplianceForm({
            asset_id,
            compliance_type_id: item.compliance_type_id,
            renewal_type: item.renewal_type,
            renewal_date: item.renewal_date,
            remarks: item.remarks,
        });
    };
    const editCompliance = () => {
        const complianceUrl = `${IAM_API_BASE_URL_2}/asset_matrix_compliance/update`;
        HttpAPICall.withAthorization("PUT", complianceUrl, access_token, {}, addComplianceForm, (response) => {
            toast.success("Compliance updated successfully!");
            setAssetComplianceList([]);
            modalRef.current.hide();
            setIsRemarkUpdate(false);
        })
        .then(() => setSaveFormSubmitting(false))
        .catch((error) => {
            toast.error("Failed to update compliance.");
            setSaveFormSubmitting(false);
            setIsRemarkUpdate(false);
        });
    };

    const handleUpdateRemarks = (item) => {
        setIsRemarkUpdate(true);
        modalRef.current.show();
        setComplianceName(item.compliance_name);
        setAddComplianceForm({
            asset_id,
            compliance_type_id: item.compliance_type_id,
            remarks: item.remarks,
        });
    };

    const handleClose = () => {
        modalRef.current.hide();
        setIsRemarkUpdate(false);
        setAddComplianceForm({
            asset_id: "",
            compliance_type_id: "",
            renewal_type: 'due_date',
            renewal_date: "",
            remarks: '',
        });
    };

    const clearCompliance   =   (asset_compliance_id) => {
        if(asset_compliance_id) {
            swal({
                title: "Delete",
                text: "This action cannot be reversed, Are you sure you want to proceed?",
                icon: "warning",
                // dangerMode: true,
                buttons: ["No", "Yes"],
            }).then((willDelete) => {
                if (willDelete) {
                    const clearComplianceUrl = `${IAM_API_BASE_URL_2}/asset_matrix_compliance/delete/${asset_compliance_id}`;
                    HttpAPICall.withAthorization("DELETE", clearComplianceUrl, access_token, {},
                    {}, (response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        getComplianceData();
                    }
                    ).then(() => { });
                }
            });
        }else {
            toast.error("This compliance not have any entry for this asset.", {position: toast.POSITION.TOP_RIGHT});
        }
    }

    const renderAddCustomComplianceModal = () => {

        return (
            <div className="modal fade" id="assetMatrixComplianceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">
                                Update {complianceName}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            ></button>
                        </div>
                        <form id="addModalForm" onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Ax>
                                    {
                                        !isRemarkUpdate &&
                                        <>
                                            <div className="row align-items-center mb-3 mt-3">
                                                <div className="col-4">
                                                    <label className="form-label">Due Date</label>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="due_date"
                                                                    name="renewal_type"
                                                                    value='due_date'
                                                                    onChange={(e) => setAddComplianceForm({ ...addComplianceForm, [e.target.name]: e.target.value })}
                                                                    checked={
                                                                        addComplianceForm.renewal_type ===
                                                                        "due_date"
                                                                    }
                                                                />{" "}
                                                                Due Date
                                                            </label>
                                                        </div>
                                                        <div className="col-4">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="na"
                                                                    name="renewal_type"
                                                                    value='na'
                                                                    onChange={(e) => setAddComplianceForm({ ...addComplianceForm, [e.target.name]: e.target.value })}
                                                                    checked={
                                                                        addComplianceForm.renewal_type ===
                                                                        "na"
                                                                    }
                                                                />
                                                                Not Applicable
                                                            </label>
                                                        </div>
                                                        <div className="col-4">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="life_time"
                                                                    name="renewal_type"
                                                                    value="life_time"
                                                                    onChange={(e) => setAddComplianceForm({ ...addComplianceForm, [e.target.name]: e.target.value })}
                                                                    checked={
                                                                        addComplianceForm.renewal_type ===
                                                                        "life_time"
                                                                    }
                                                                />{" "}
                                                                Life Time
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center mb-3 mt-3">
                                                <div className="col-4">
                                                    <label className="form-label">Date of Renewal</label>
                                                </div>
                                                <div className="col-8 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            addComplianceForm.renewal_date
                                                                ? moment(
                                                                    addComplianceForm.renewal_date
                                                                ).toDate()
                                                                : false
                                                        }
                                                        name="renewal_date"
                                                        onChange={(value, event) => {
                                                            setAddComplianceForm({ ...addComplianceForm, renewal_date: value ? moment(value).format('YYYY-MM-DD') : '' });
                                                        }}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        disabled={
                                                            addComplianceForm.renewal_type !==
                                                            "due_date"
                                                        }
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Please Enter Renewal Date`}
                                                    />
                                                    <tapIcon.imageIcon
                                                        icon={tapIcon.CalenderIcon}
                                                        alt="requisition_date"
                                                        className="add_calender-icon"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="row align-items-center mb-3 mt-3">
                                        <div className="col-4">
                                            <label className="form-label">Remarks</label>
                                        </div>
                                        <div className="col-8">
                                            <input
                                                name="remarks"
                                                type="text"
                                                className="form-control"
                                                value={addComplianceForm.remarks}
                                                placeholder="Please Enter Remarks"
                                                onChange={(e) => setAddComplianceForm({ ...addComplianceForm, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </Ax>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    disabled={saveFormSubmitting}
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button disabled={saveFormSubmitting} type="submit" className="btn btn-primary ">
                                    Save
                                    {saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Ax>
                <ToastContainer />
                    <div className="tab_content_wrapper mt-2">
                        <span className="content_heading">Asset Compliances</span>
                    </div>
                    <table className="table table-hover table-bordered mt-2 table-sm mb-5">
                        <thead>
                            <tr>
                                <th scope="col" className="table-head-row" style={{ width: "30%" }}>Compliance Name</th>
                                <th scope="col" className="table-head-row" style={{ width: "20%" }}>Due Date</th>
                                <th scope="col" className="table-head-row" style={{ width: "25%" }}>Remarks</th>
                                {/* <th scope="col" className="table-head-row" style={{ width: "15%" }}>Documents</th> */}
                                <th scope="col" className="table-head-row text-center" style={{ width: "10%" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading 
                                ?
                                <tr><td className='text-center' colSpan={4}><Loader /></td></tr> 
                                :
                                (assetComplianceList.length > 0 ? assetComplianceList?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="details-label">{item.compliance_name || ""}</td>
                                        <td className="details-name">{item.status ? <Status color={item.status_color_code}>
                                        {item.display}</Status> : item.display}
                                        </td>
                                            {/* {item.renewal_date ? DateService.dateTimeFormat(item.renewal_date, 'DD-MMM-YYYY') : ''}</td> */}
                                        <td className="details-label">{item.remarks || '-'}</td>
                                        {/* <td className="details-label">Document Link</td> */}
                                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                                            <span className="dropdown">
                                                <button type="button" id="actionMenuButton" data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li className='m-2 py-1 cursor_pointer' onClick={() => handleUpdateCompliance(item)}>Update Compliance</li>
                                                    <li className='m-2 py-1 cursor_pointer' onClick={() => handleUpdateRemarks(item)}>Update Remarks</li>
                                                    <li className='m-2 py-1 cursor_pointer' onClick={() => clearCompliance(item.asset_compliance_id)}>Clear Compliance</li>
                                                </ul>
                                            </span>
                                        </td>
                                    </tr>
                                )
                                })
                                :<tr><td className='text-center' colSpan={4}>No Record Found</td></tr>
                                )
                            }
                        </tbody>
                    </table>
                    <DisplayListPagination meta={listingMeta} onPageChange={(e) => getComplianceData(e.selected + 1)}/>
                    {renderAddCustomComplianceModal()}
            </Ax>
        </>
    );
};

export default AssetMatrixCompliance;


