import React from "react";
import tapIcon from '../../../services/TapIcon';
import { connect } from 'react-redux';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination } from '../../../components/TapUi';

class ChangeWarehouseAddressModal extends AppBaseComponent {

    constructor() {
        super();

        this.state  =   {
            warehouse_id            :   null,
            address_loading         :   false,
            warehouseAddressList    :   [],
            warehouseListingMeta    :   null,
            totalAddressCount       :   0,
            searchWarehouseAddress  :   '',
            sameWarehouseDetail     :   null,
            clearSearchAddress      :   true,
            selectedShippingAddress :   null,     
            selecteSameAddress      :   true,       
        }
    }

    componentDidMount () {
        this.warehouseAddressModal          =   new Modal(document.getElementById('changeWarehouseAddressModal'), {keyboard: false, backdrop :false});
    }

    handleModal = (warehouse_id) => {
        this.warehouseAddressModal.show();
        this.setState({
            warehouse_id, 
            sameWarehouseDetail : this.props.sameWarehouseAddress, 
            selecteSameAddress : this.props.selecteSameAddress,
            selectedShippingAddress : this.props.selectedShippingAddress
        });
        this.getWarehouseAddressList(warehouse_id);
    }

    getWarehouseAddressList      =       (id,page=1)        =>      {
        this.setState({address_loading : true})
        const params =  {search_by_keywords : this.state.searchWarehouseAddress, page : page,per_page:50};
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/warehouse/shipping_address/' + id, this.props.access_token,params)
            .then((response) => {
                let respData    =   response.data;
                this.setState({
                    warehouseAddressList    :   respData.data,
                    warehouseListingMeta    :   respData.meta,
                    totalAddressCount       :   respData.meta.total
                });
               
            }).then(() => {
            this.setState({address_loading: false});
        });
    }

    changeWarehouseAddressHandler   =   (address, type)  =>  {
        if(type === 'ship') {
            this.setState({
                selectedShippingAddress : address,
                selecteSameAddress      :   false
            });
        }else {
            this.setState({
                selecteSameAddress : true,
                selectedShippingAddress :  null
            });
        } 
        this.props.warehouseDetail(address, type);   
        this.warehouseAddressModal.hide();
    }

    render () {
        return (
            <div className="modal fade" id="changeWarehouseAddressModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changeWarehouseAddressModalLabel">Warehouse Address List</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({searchWarehouseAddress : ''})}></button>
                        </div>
                            {
                            this.state.address_loading 
                            ?   <Loader />
                            :   this.state.warehouseAddressList && 
                                <div className="modal-body">
                                    <div className='row mb-3'>
                                        <div className='col-sm-8'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                onChange={(e) => this.setState({searchWarehouseAddress : e.target.value})}
                                                placeholder='Search Address, Pin Code, GSTIN'
                                                value={this.state.searchWarehouseAddress}
                                            />
                                        </div>
                                        <div className='col-sm-2 text-end'>
                                            <button type='button' className='btn btn-secondary btn-lg' onClick={() => { this.getWarehouseAddressList(this.state.warehouse_id, 1); this.setState({clearSearchAddress : false}) }} disabled={this.state.searchWarehouseAddress.length === 0 ? true : false}><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> </button>
                                        </div>
                                        <div className='col-sm-2'>
                                            <button type='button' className='btn btn-secondary btn-lg' onClick={() => this.setState({searchWarehouseAddress : ''}, () => {
                                                this.getWarehouseAddressList(this.state.warehouse_id, 1); this.setState({clearSearchAddress : true}) 
                                            })} disabled={this.state.searchWarehouseAddress.length === 0 || this.state.clearSearchAddress ? true : false}><tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid"/></button>
                                        </div>
                                    </div>
                                    <table className="table table-hover table-bordered">
                                        <tbody>
                                            {this.state.sameWarehouseDetail ? <tr>
                                                <td>
                                                <b> Same as Warehouse Address</b>
                                                </td>
                                                <td className='text-center' style={{ width: "15%" }}>
                                                    {this.state.selecteSameAddress ?
                                                        <button type="button" className='btn btn-secondary'>Selected</button>
                                                        : <button type='button' className='btn btn-primary' onClick={() => { this.changeWarehouseAddressHandler(this.state.sameWarehouseDetail, 'same') }}>Select</button>}
                                                </td>
                                            
                                            </tr> : null}
                                            {this.state.warehouseAddressList.length > 0 ? this.state.warehouseAddressList.map((a, i) => {
                                                return (<tr>
                                                    <td>
                                                        <div className='py-1'>Address : <b>{a.address1 ? a.address1 + ", " : ""}
                                                            {a.address2 ? a.address2 + ", " : ""}
                                                            {a.landmark ? a.landmark + ", " : ""}
                                                            {a.city ? a.city : ""}</b></div>
                                                        <div className='py-1'>District, State & Pincode : <b>{a.district_name ? a.district_name + ", " : ""}
                                                            {a.state_name ? a.state_name : ""}
                                                            {a.pincode ? " & " + a.pincode : ""}</b></div>
                                                        <div className='py-1'>GSTIN : <b>{a.gstin ? a.gstin : ""}</b></div>
                                                    </td>
                                                    <td className='text-center' style={{ width: "15%" }}>
                                                        {this.state.selectedShippingAddress && a.id == this.state.selectedShippingAddress.id ?
                                                            <button type="button" className='btn btn-secondary'>Selected</button>
                                                            : <button type='button' className='btn btn-primary' onClick={() => { this.changeWarehouseAddressHandler(a, 'ship') }}>Select</button>}
                                                    </td>
                                                </tr>)
                                            })
                                                : null}
                                            
                                        </tbody>
                                    </table>
                                    <DisplayListPagination
                                        meta={this.state.warehouseListingMeta}
                                        onPageChange={(e) => this.getWarehouseAddressList(this.state.warehouse_id, e.selected + 1)}
                                    />
                                </div> 
                            }
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(ChangeWarehouseAddressModal);