// TabNavigationAssetMatrix.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const TabNavigationAssetMatrix = ({ matrixId }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
                <Link to={`/asset_matrix/details/${matrixId}`} role={"button"}>
                    <button className={`btn nav-link text-capitalize ${currentPath.includes('details') ? 'active' : ''}`} role="button">
                        Details
                    </button>
                </Link>
            </li>
            <li className="nav-item">
                <Link to={`/asset_matrix/scs_setting/${matrixId}`} role={"button"}>
                    <button className={`btn nav-link text-capitalize ${currentPath.includes('scs_setting') ? 'active' : ''}`} role="button">
                        SCS Setting
                    </button>
                </Link>
            </li>
            <li className="nav-item">
                <Link to={`/asset_matrix/settings/${matrixId}`} role={"button"}>
                    <button className={`btn nav-link text-capitalize ${currentPath.includes('settings') ? 'active' : ''}`} role="button">
                        Setting
                    </button>
                </Link>
            </li>
            <li className="nav-item">
                <Link to={`/asset_matrix/compliance/${matrixId}`} role={"button"}>
                    <button className={`btn nav-link text-capitalize ${currentPath.includes('compliance') ? 'active' : ''}`} role="button">
                        Compliance
                    </button>
                </Link>
            </li>
            
            <li className="nav-item">
                <Link to={`/asset_matrix/document/${matrixId}`} role={"button"}>
                    <button className={`btn nav-link text-capitalize ${currentPath.includes('document') ? 'active' : ''}`} role="button">
                      Document
                    </button>
                </Link>
            </li>
            <li className="nav-item">
                <Link to={`/asset_matrix/additionalattribute/${matrixId}`} role={"button"}>
                    <button className={`btn nav-link text-capitalize ${currentPath.includes('additionalattribute') ? 'active' : ''}`} role="button">
                        Addl. Attributes
                    </button>
                </Link>
            </li>
        </ul>
    );
};

export default TabNavigationAssetMatrix;
