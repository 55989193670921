import React from "react";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AppBaseComponent from "../../../components/AppBaseComponent";
import tapIcon from "../../../services/TapIcon";
import { Helmet } from "react-helmet";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import moment from "moment";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";
import Status from "../../../components/ui/Status";
import { Link } from "react-router-dom";
import EmployeeDocument from "./EmployeeDocument";
import DatePicker from "react-datepicker";
import TapIcon from "../../../services/TapIcon";
import DateService from "../../../services/DateService";

class EmployeeDetailModal extends AppBaseComponent {
  constructor() {
    super();

    this.editEmployeeFormInit = {
      fname: "",
      lname: "",
      mobile: "",
      email: "",
      id: "",
      gender: "",
      marital_status: "",
      blood_group: "",
      dob: "",
      nationality: "",
      country_of_origin: "",
      emergency_phone_number: "",
      guardian_name: "",
    };

    this.state = {
      editEmployeeForm: { ...this.editEmployeeFormInit },
      loading_form_data: false,
      saveFormSubmitting: false,
      employeeData: null,
      allCountryList: [],
      bloodGroupList: [],
      religionList: [],
      maritalStatusList: [],
      genderList: [],
    };

    this.emp_add_form_data_url =   HRM_API_BASE_URL_2 + '/employee/add_form_data'; 
    this.emp_edit_url =   HRM_API_BASE_URL_2 + '/employee/edit';

  }

  componentDidMount() {
    this.editEmployeeModal = new Modal(
      document.getElementById("editEmployeeModal"),
      { keyboard: false, backdrop: false }
    );
  }

  modalHandle = () => {
    this.editEmployeeModal.show();
    this.setState({ loading_form_data: true });
    const employeeData = this.props.employeeData;

    HttpAPICall.withAthorization("GET", this.emp_add_form_data_url,
      this.props.access_token, { type: "basic_detail" }, {}, (resp) => {
        let respData = resp.data;
        this.setState({
          allCountryList:
            respData &&
            respData.country.length > 0 &&
            respData.country.map((c) => {
              return { value: c.id, label: c.name };
            }),
          employeeData,
          editEmployeeForm: {
            id: employeeData.enc_id ? employeeData.enc_id : "",
            fname: employeeData.fname ? employeeData.fname : "",
            lname: employeeData.lname ? employeeData.lname : "",
            mobile: employeeData.mobile ? employeeData.mobile : "",
            email: employeeData.email ? employeeData.email : "",
            gender: employeeData.gender ? employeeData.gender : "",
            marital_status: employeeData.marital_status
              ? employeeData.marital_status
              : "",
            blood_group: employeeData.blood_group
              ? employeeData.blood_group
              : "",
            dob: employeeData.dob ? employeeData.dob : "",
            nationality: employeeData.nationality
              ? employeeData.nationality
              : "",
            country_of_origin: employeeData.country_of_origin
              ? employeeData.country_of_origin
              : "",
            emergency_phone_number: employeeData.emergency_phone_number
              ? employeeData.emergency_phone_number
              : "",
            religion: employeeData.religion ? employeeData.religion : "",
            guardian_name: employeeData.guardian_name
              ? employeeData.guardian_name
              : "",
          },
          bloodGroupList:
            respData && respData.blood_group.length > 0
              ? respData.blood_group.map((blood) => {
                  return { value: blood, label: blood };
                })
              : [],
          religionList:
            respData && respData.religion.length > 0
              ? respData.religion.map((religion) => {
                  return { value: religion, label: religion };
                })
              : [],
          maritalStatusList:
            respData && respData.marital_status.length > 0
              ? respData.marital_status.map((marital) => {
                  return { value: marital, label: marital };
                })
              : [],
          genderList:
            respData && respData.gender.length > 0
              ? respData.gender.map((gender) => {
                  return { value: gender, label: gender };
                })
              : [],
        });
      }
    ).then(() => this.setState({ loading_form_data: false }));
  };

  submitEditDetailForm = (e) => {
    e.preventDefault();
    let frmData = { ...this.state.editEmployeeForm };
    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization("PUT", this.emp_edit_url, this.props.access_token,
      {}, { ...frmData }, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.props.viewEmployeeDetail(this.state.employeeData?.enc_id, true);
        this.editEmployeeModal.hide();
        this.setState({ editEmployeeForm: { ...this.editEmployeeFormInit } });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  render() {
    return (
      <div className="modal fade" id="editEmployeeModal" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editEmployeeModalLabel">
                Edit Basic Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {this.state.loading_form_data ? (
              <Loader />
            ) : (
              <Ax>
                <div className="modal-body">
                  <form
                    id="detailEditForm"
                    onSubmit={this.submitEditDetailForm}
                  >
                    <div className="tab_content_wrapper">
                      <span className="content_heading">Basic information</span>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="fname" className="form-label require">
                          First Name
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="fname"
                          type="text"
                          value={this.state.editEmployeeForm.fname}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          required={true}
                          onChange={(e) =>
                            this.formInputHandler(e, "editEmployeeForm")
                          }
                          placeholder="Please enter First Name"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="lname" className="form-label">
                          Last Name
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="lname"
                          type="text"
                          value={this.state.editEmployeeForm.lname}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "editEmployeeForm")
                          }
                          placeholder="Please enter Last Name"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Mobile Number
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="mobile"
                          type="number"
                          value={this.state.editEmployeeForm.mobile}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "editEmployeeForm")
                          }
                          placeholder="Please enter Mobile Number"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Email
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="email"
                          type="email"
                          value={this.state.editEmployeeForm.email}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "editEmployeeForm")
                          }
                          placeholder="Please enter Email"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="guardian_name" className="form-label">
                          Guardian/Father Name
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="guardian_name"
                          type="text"
                          value={this.state.editEmployeeForm.guardian_name}
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "editEmployeeForm")
                          }
                          placeholder="Please enter Guardian/Father Name"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Date of Birth
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <DatePicker
                          selected={
                            this.state.editEmployeeForm.dob
                              ? moment(
                                  this.state.editEmployeeForm.dob,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : false
                          }
                          name="dispatch_date"
                          onChange={(value, event) =>
                            this.formDateHandler(
                              "dob",
                              value,
                              "editEmployeeForm"
                            )
                          }
                          dateFormat="dd-MMM-yyyy"
                          className={"form-control form-control-sm"}
                          showMonthDropdown
                          showYearDropdown
                          autoComplete="off"
                          scrollMonthYearDropdown
                          placeholderText={`Date of Birth`}
                        />
                        {/* <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Date of Birth" className="add_calender-icon" /> */}
                      </div>
                    </div>
                    <div className="tab_content_wrapper">
                      <span className="content_heading">Personal Details</span>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="gender" className="form-label">
                          Gender
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.genderList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "editEmployeeForm",
                              "gender"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.genderList.find(
                            (g) =>
                              this.state.editEmployeeForm?.gender === g.value
                          )}
                          placeholder="Select Gender"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="marital_status" className="form-label">
                          Marital Status
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.maritalStatusList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "editEmployeeForm",
                              "marital_status"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.maritalStatusList.find(
                            (marital) =>
                              this.state.editEmployeeForm?.marital_status ===
                              marital.value
                          )}
                          placeholder="Select Marital Status"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="blood_group" className="form-label">
                          Blood Group
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.bloodGroupList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "editEmployeeForm",
                              "blood_group"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.bloodGroupList.find(
                            (blood) =>
                              this.state.editEmployeeForm?.blood_group ===
                              blood.value
                          )}
                          placeholder="Select Blood Group"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Religion
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.religionList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "editEmployeeForm",
                              "religion"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.religionList.find(
                            (religion) =>
                              this.state.editEmployeeForm?.religion ===
                              religion.value
                          )}
                          placeholder="Select Religion"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Nationality
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.allCountryList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "editEmployeeForm",
                              "nationality"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.allCountryList.find(
                            (c) =>
                              this.state.editEmployeeForm?.nationality ===
                              c.value
                          )}
                          placeholder="Select Nationality"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Country of Origin
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <TapSelect
                          options={this.state.allCountryList}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "editEmployeeForm",
                              "country_of_origin"
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          value={this.state.allCountryList.find(
                            (c) =>
                              this.state.editEmployeeForm?.country_of_origin ===
                              c.value
                          )}
                          placeholder="Select Country of Origin"
                          containerHeight="30px"
                          fontSize="93%"
                        />
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-sm-2">
                        <label htmlFor="site_id" className="form-label">
                          Emergency Phone Number
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <input
                          name="emergency_phone_number"
                          type="number"
                          value={
                            this.state.editEmployeeForm.emergency_phone_number
                          }
                          className="form-control form-control-sm"
                          autoComplete="off"
                          onChange={(e) =>
                            this.formInputHandler(e, "editEmployeeForm")
                          }
                          placeholder="Please enter emergency phone number"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    form="detailEditForm"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-primary mx-2"
                  >
                    Save{" "}
                    {this.state.saveFormSubmitting ? (
                      <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </Ax>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    asset_groups:
      state.app && state.app.asset_groups ? state.app.asset_groups : [],
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EmployeeDetailModal
);
