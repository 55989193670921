import React, { useImperativeHandle, forwardRef, useRef, useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import uuid from 'react-uuid';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import TapIcon from '../../../../services/TapIcon';
import TapSelect from '../../../../components/ui/TapSelect';
import HttpAPICall from '../../../../services/HttpAPICall';
import { HRM_API_BASE_URL_2, ORG_API_BASE_URL_2, SCHEDULE_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import TapHelper from '../../../../services/TapHelper';

const AttendanceMultipleEmployeeModal     = forwardRef((props, ref) => {


    const date                              =   new Date();

    let initFormData                        =   {
        loading                                 :   false,
        submitting                              :   false,
        action                                  :   'checkin',
        employees                               :   [],
        transaction_date                        :   date,
        hour                                    :   date.getHours(),
        minutes                                 :   date.getMinutes(),
        note                                    :   ''
    };

    const [employeeData, serEmployeeData]   =   useState(null);

    const [modalId, setModalId]             =   useState(uuid());
    const [formId, setformId]               =   useState(uuid());
    const [formData, setFormData]           =   useState({...initFormData});
    const access_token                      =   useSelector(state => state.auth.access_token);
    const modalElem                         =   useRef(null);
    const modal                             =   useRef(null);

    const hourOptions                       =   TapHelper.range(0,23).map(v => { return  {label: v, value: v} });
    const minuteOptions                     =   TapHelper.range(0,59).map(v => { return  {label: v, value: v} });

    let formDataUrl                         =   HRM_API_BASE_URL_2 + '/employee_shift_form_data/___replace_employee_id';
    let formDataSaveUrl                     =   HRM_API_BASE_URL_2 + '/daily_attendance/save_attendance';

    useImperativeHandle(ref, () => ({
        initModalHandler: (employees, action='checkin') => {
            openModalHandler(employees, action);
        }
    }));

    const openModalHandler                  =    (employees, action)  =>  {
        //Get Form Data of this Activity :-
        formDataInit(employees, action);
        modalOpen();
    }

    const modalOpen                         =   ()  => {
        if (modal.current) { modal.current.show(); }
    }

    const modalClose                        =   ()  => {
        if (modal.current) { modal.current.hide(); }
    }

    const formDataInit                      =   (employees, action)  =>  {
        setFormData(pd => ({...pd, loading : false, employees : employees, action : action, transaction_date : props.transaction_date}));
    }

    useEffect(() => {
        modalElem.current                   =   document.getElementById(modalId);
        modal.current                       =   new Modal(modalElem.current, {keyboard: false, backdrop :false});
        // Cleanup on unmount
        return () => {
            if (modal.current) {
                modal.current.dispose();
            }
        };
    }, []);

    const submitEventHandler                =   (e) => {
        e.preventDefault();
        setFormData(pd => ({...pd, submitting : true}));

        let data                                =   {
                                                        ...formData, 
                                                        employee_ids : formData.employees.map(e => e.enc_id),
                                                        transaction_date : moment(formData.transaction_date).format("YYYY-MM-DD")
                                                    };
        ['employees', 'employees', 'submitting'].forEach(e => delete data[e]);

        HttpAPICall.withAthorization("POST", formDataSaveUrl, access_token, {}, data, (response) => {
            //1 - Modal Close :-
            modalClose();
            //2 - Reset Ticket Form Data :-
            setFormData(initFormData);
            if(props.afterSucess) {
                props.afterSucess()
            }
            //4 - Show Success Message :-
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT});
        }).then(() => {
            setFormData(pd => ({...pd, submitting : false}));
        });
    }
    
    return <>
        <div className="modal fade" id={modalId} tabindex="-1" ref={modalElem}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Employee Attendance</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" disabled={formData.submitting || formData.loading} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {formData.loading ? <Loader /> : <form name='' id={formId + 'Form'} onSubmit={submitEventHandler}>
                            <div className="row align-items-center mb-3">
                                <div className="col-sm-3"><label className="form-label">Employee</label></div>
                                <div className="col-sm-9">{formData.employees.length == 1 ? formData.employees[0].name : (formData.employees.length + ' employee selected')}</div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-sm-3"><label className="form-label require">{formData.action == 'checkin' ? 'Check-In' : 'Check-Out'}</label></div>
                                <div className="col-sm-3">
                                    <label for="exampleFormControlInput1" class="form-label">Date</label>
                                    <DatePicker
                                        selected={formData.transaction_date}
                                        onChange={d => {
                                            setFormData(pd => ({...pd, transaction_date : d}));
                                        }}
                                        dateFormat="dd-MMM-yyyy"
                                        className="form-control form-control-sm"
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        maxDate={new Date()}
                                        disabled={formData.loading || formData.action == 'checkin'}
                                        placeholderText={`Please Enter Date `}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <label class="form-label">Hour</label>
                                    <TapSelect
                                        required
                                        options={hourOptions}
                                        value={hourOptions.find(o => o.value === formData.hour)}
                                        changeEvent={(o) => setFormData(d => ({...d, hour:o ? o.value : null}))}
                                        placeholder="Select Hours"
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <label class="form-label">Minutes</label>
                                    <TapSelect
                                        required
                                        options={minuteOptions}
                                        value={minuteOptions.find(o => o.value === formData.minutes)}
                                        changeEvent={(o) => setFormData(d => ({...d, minutes:o ? o.value : null}))}
                                        placeholder="Select Minutes"
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center my-3">
                                <div className="col-sm-3"><label className="form-label require">Notes</label></div>
                                <div className="col-sm-9">
                                    <textarea
                                        name="note"
                                        value={formData.note}
                                        onChange={(e) => setFormData(d => ({...d, note: e.target.value }))}
                                        className="form-control"
                                        style={{ height: "80px" }}
                                    />
                                </div>
                            </div>
                        </form>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" disabled={formData.submitting || formData.loading} data-bs-dismiss="modal">Close</button>
                        <button form={formId + 'Form'} type="submit" disabled={formData.submitting || formData.loading} className="btn btn-primary">Save {formData.submitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    </>
});

export default AttendanceMultipleEmployeeModal;