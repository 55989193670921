import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../components/ui/Loader/Loader";
import Ax from "../../components/hoc/Ax";
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import AppBaseComponent from '../../components/AppBaseComponent';
import tapIcon from '../../services/TapIcon';
import { Helmet } from 'react-helmet';
import TapApiUrls, { HRM_API_BASE_URL_2 } from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { toast } from 'react-toastify';
import TapSelect from '../../components/ui/TapSelect';
import moment from 'moment';
import download from '../../services/DownloadFile';
import swal from "sweetalert";
import Status from '../../components/ui/Status';
import EditLinkedOperators from './EditLinkedOperators';
import InputAssetSearch from '../includes/ui/InputAssetSearch';
import InputEmployeeSearch from '../includes/ui/InputEmployeeSearch';

class EmployeeAssetList extends AppBaseComponent {

    constructor(props) {
        super(props);
    
        this.employeeSearchInitState        =   {
            search_asset_ids                :       [],
            'asset_group_ids'               :       [],
            search_asset_type_ids           :       [],
            search_site_ids                 :       [],
            search_employee_ids             :       []
        };

        this.employeeSearchFormLabel         =   {
            search_asset_ids                :       'Asset : ',
            'asset_group_ids'               :       'Asset Type Groups : ',
            search_asset_type_ids           :       'Asset Type : ',
            search_site_ids                 :       'Site : ',
            search_employee_ids             :       'Employee : '
       };

        this.state                         =     {  
            minimizeTable                   :       false,   
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            employee_listing                 :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            employeeData                     :       null,
            viewDataLoading                 :       false,
            access_token                    :       '',
            file_downloading                :         false,
            employeeBulkUploadFormSubmitting    :   false,
            employeeSearchForm              :       {...this.employeeSearchInitState},
            submittedEmployeeSearchForm     :       {...this.employeeSearchInitState},
            formSearchedElems               :       [],
            iam_user_sites                  :       [],
            iam_asset_types                 :       [],
            searchedAssets                  :       [],
            searchedEmployees               :       [],
            reportDownloading               :       false,
            allAssetTypesGroupsList         :       []
        };
        this.linkOperatorRef             =      React.createRef();

        this.assets_employee_url = HRM_API_BASE_URL_2 + '/assets_employee';

    }
    
    
    componentDidMount() {
        this.initilaizeFilterFormData(this.props);
        this.loadListingTblData(1);
        this.employeeAssetSearchModal              =    new Modal(document.getElementById('employeeAssetSearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeFilterFormData(nextProps);
        }
            
    }

    initilaizeFilterFormData        =       (props)        =>      {
        this.setState({
            access_token            :   props.access_token,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        });
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedEmployeeSearchForm};
        HttpAPICall.withAthorization('GET',  this.assets_employee_url, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
        
            let respData = resp.data;
            this.setState({
                employee_listing         :           respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

   
    editOperatorsModalInit              =       (id)        =>      {
       
        let assetData = this.state.employee_listing && this.state.employee_listing.length > 0 ? this.state.employee_listing.find(em => em.asset_id == id) : null;
        if(assetData){
            this.linkOperatorRef.current.editLinkOperatorModalInit(assetData)
        }
    }
   
    employeeSearchModalInit        =       ()      =>      {
        this.employeeAssetSearchModal.show()
    }

    employeeSearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.employeeSearchForm);
        let searchedElems               =   [];
        let employeeSearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.employeeSearchFormLabel[key];
            let value                       =   this.state.employeeSearchForm[key];
            if(value && value.toString().length > 0) {
                employeeSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                   
                     if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            submittedEmployeeSearchForm         :   {...this.state.employeeSearchForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.employeeAssetSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    employeeSearchClear         =       ()     =>      {
        this.setState({
            employeeSearchForm            :   {...this.employeeSearchInitState},
            submittedEmployeeSearchForm   :   {...this.employeeSearchInitState},
            formSearchedElems             :   [],
            searchedAssets                :   [],
            searchedEmployees             :    []
        }, () => {
            this.employeeAssetSearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    employeeDownloadStart                   =   ()  =>  {
        this.setState({reportDownloading : true});
        let params                      =   {...this.state.submittedEmployeeSearchForm};
        HttpAPICall.withAthorization('GET',  this.assets_employee_url, this.props.access_token, {...params, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    employeeListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Employee Asset </h3>
                <div className="text-end mt15">
                    <button type="button" disabled={this.state.listing_loading} onClick={this.employeeSearchModalInit} className="btn btn-secondary" >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <button type="button" className="btn btn-secondary"
                        disabled={this.state.reportDownloading || this.state.listing_loading}
                        onClick={this.employeeDownloadStart} >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>

                </div>

            </div>
           
            {/* {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3 className="text-capitalize">Employee  : {this.state.employeeData ? this.state.employeeData.name : "-"}  </h3>
                            <div className="text-end mt15">

                                <button onClick={this.closeView} type="button" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            } */}

        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.employeeListingTableJsx()}</div>
                    {/* {this.state.minimizeTable ? <div className="col-9 pr4 pl4 bg-white ">
                        {this.state.viewDataLoading ? <Loader /> : <div className='tab_content_header' style={{ height: "80vh" }}>{this.employeeViewJsx()}</div>}</div> : null}
                </div> */}
                </div>
            </div>
        </Ax>);
    }

    employeeListingTableJsx            =   ()   =>   {
        return (<Ax>
              <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.employeeSearchClear} /> 
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Employee Asset</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                            <th scope="col" style={{ width: "15%" }}>Asset</th>
                            <th scope="col" style={{ width: "13%" }}>Asset Code</th>
                            <th scope="col" style={{ width: "13%" }}>Asset Type</th>
                            <th scope="col" style={{ width: "13%" }}>Site</th>
                            <th scope="col" style={{ width: "12%" }} className="text-center">Operators</th>
                            <th scope="col" style={{ width: "12%" }} className="text-center">Supervisors</th>
                            <th scope="col" style={{ width: "12%" }} className="text-center">Engineers</th>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="8"><Loader /></td></tr>)
                        : (this.state.employee_listing.length > 0
                            ? (this.state.employee_listing.map((employee, index) => {
                                return (<tr key={index} >

                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                    <td className='text-capitalize'>{employee.name ? employee.name : "-"}</td>
                                    <td>{employee.asset_code ? employee.asset_code : "-"}</td>
                                    <td>{employee.asset_type_name ? employee.asset_type_name : "-"}</td>
                                    <td>{employee.site ? employee.site : "-"}</td>
                                    <td>{employee.operators && employee.operators.length > 0 ? <ol>{employee.operators.map(op => <li>{op.name}</li>)}</ol> : <div className='text-center'>-</div>} </td>
                                    <td>{employee.supervisors && employee.supervisors.length > 0 ? <ol>{employee.supervisors.map(op => <li>{op.name}</li>)}</ol> : <div className='text-center'>-</div>} </td>
                                    <td>{employee.engineers && employee.engineers.length > 0 ? <ol>{employee.engineers.map(op => <li>{op.name}</li>)}</ol> : <div className='text-center'>-</div>} </td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => this.editOperatorsModalInit(employee.asset_id)}>Edit</a></li>

                                        </ul>
                                    </td>

                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
           
        </Ax>)
    }

    employeeAssetSearchModalJsx                             =   ()  =>  {
        return (
            <div className="modal fade" id="employeeAssetSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="employeeSearchModalLabel">Employee Asset Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.employeeSearchFormSubmit} id="employeeSearchForm">
                            <div className="modal-body">
                                <div className="row mb-2">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Asset</label>
                                    <div className="col-sm-9">
                                        <InputAssetSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'employeeSearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>

                                </div>
                                <div className="row my-2">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Asset Type Group</label>
                                    <div className="col-sm-9">
                                    <TapSelect
                                            options={this.state.allAssetTypesGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    employeeSearchForm: {
                                                        ...this.state.employeeSearchForm,
                                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.employeeSearchForm.asset_group_ids.includes(s.value))}
                                            placeholder="Select Asset Type Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-2">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Asset Type</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.iam_asset_types}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'employeeSearchForm', 'search_asset_type_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_asset_types.filter(s => this.state.employeeSearchForm.search_asset_type_ids.includes(s.value))}
                                            placeholder="Select Asset Type"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>

                                <div className="row my-2">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Site</label>
                                    <div className="col-sm-9">
                                        <TapSelect
                                            options={this.state.iam_user_sites}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'employeeSearchForm', 'search_site_ids');
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            menuPlacement="bottom"
                                            value={this.state.iam_user_sites.filter(s => this.state.employeeSearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Sites"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            isMulti={true}
                                        />
                                    </div>

                                </div>
                                <div className="row my-2">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">Employees</label>
                                    <div className="col-sm-9">
                                        <InputEmployeeSearch
                                            changeEvent={(selectedOption) => {

                                                this.tapSelectChange(selectedOption, 'employeeSearchForm', 'search_employee_ids');
                                                this.setState({
                                                    searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                });
                                            }}
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            placeholder="Select Employee"
                                            value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Employee Asset Listing</title></Helmet>
            {this.employeeListjsx()}
            {this.employeeAssetSearchModalJsx()}
            <EditLinkedOperators ref={this.linkOperatorRef} afterSubmitForm={() => this.loadListingTblData(1)}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
    };
};

export default connect(mapStateToProps)(EmployeeAssetList);