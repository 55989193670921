import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Status from '../../../components/ui/Status';
import { Helmet } from 'react-helmet';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import AllocationDetail from '../../inventory/allocation/AllocationDetail';
import { toast } from 'react-toastify';
import AllocationRelease from '../../inventory/allocation/AllocationRelease';
import React from 'react';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import ItemDemandDetail from '../../inventory/itemDemand/ItemDemandDetail';

class ItemWiseDemandReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.reportUrl                      =   INVENTORY_API_BASE_URL_2 + '/item_demand/report/item-wise';
        
        this.reportFilterFormInitialState   =   {
            search_warehouse_ids                :   [],
            search_workstation_ids              :   [],
            search_asset_ids                    :   [],
            search_item_ids                     :   [],
            search_batch_number                 :   '',
            search_voucher_number                 :   '',
            search_transaction_id               :      '',
            date_range                          :   'current_quarter',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_item_tags                    :   [],
            search_status_ids                   :   [],
            search_employee_ids                 :   [],
        };
        
        this.reportFilterFormLabel          =   {
            search_warehouse_ids                :   'Warehouse : ',
            search_workstation_ids              :   'WorkStation : ',
            search_asset_ids                    :   'Asset : ',
            search_item_ids                     :   'Items : ',
            search_batch_number                 :   'Batch Number :  ',
            search_voucher_number               :   'Voucher Number :  ',
            search_transaction_id               :   'Transaction ID :  ',
            date_range                          :   'Transaction Period :  ',
            search_item_tags                    :    'Item Tags : ',
            search_status_ids                   :     'Search Status : ',
            search_employee_ids                 :   'Employees : ',
        };

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }
        
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            isc_configuration                   :   {},
            all_warehouses                      :   [],
            iam_user_sites                      :   [],
            reportFilterFormData                :   {periods : [], workstations : []},
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            searchedAssets                      :   [],
            listingData                         :   [],
            allItemTags                         :   [],
            allStatusOptions                    :   [],
            listingMeta                         :   null,
            demandId                        :   null,
            searchedEmployees                   :   [],
            download_access                     :   false,   
            reportData                          :   null ,
            cancelledItemList                 :       [],
            cancel_item_loading               :       false,
            cancelItemFormSubmitting          :       false,
            cancelledItemForm                 :       {...this.cancelItemFormInit},                 
        };

        this.releaseItemRef                 =       React.createRef();
        this.releaseUrl                     =       INVENTORY_API_BASE_URL_2 + '/item_demand/cancel_item'
    }
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
        this.releasedItemModal                            =   new Modal(document.getElementById('ReleasedItemModal'), {keyboard: false, backdrop :false});
        this.viewAllocationModal              =     new Modal(document.getElementById('viewAllocationModal'), {keyboard: false, backdrop :false});
       
    }
    
    componentWillReceiveProps(nextProps){
       // if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        //}
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {
        
        const report_name   =   props?.location?.pathname ? props.location.pathname.split('/')[2] : '';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        let all_periods     =       [...props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
        let url                                 =   process.env.REACT_APP_INVENTORY_API_SERVER + '/report/item-wise-allocation-order/form_data';
        HttpAPICall.withAthorization('GET', this.reportUrl, props.access_token, {action:"form_data"}, null, (response) => {
            // let workstations                =   response.data.workstations.map((w) => {
            //                                         return {value: w.id, label: `${w.workstation_name} (${w.workstation_nickname})`}
            //                                     });
            this.setState({
                reportData                  :   reportData.length > 0 ? reportData[0] : null,
                download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                reportFilterFormData        :   {...response.data , workstations : []},
                all_warehouses              :   props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                iam_user_sites              :   props.iam_user_sites ? props.iam_user_sites.map((s) => {
                                                    return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                isc_configuration           :   props.isc_configuration,
                allItemTags                 :   props.item_tags && props.item_tags.length > 0 ? props.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                formDataLoaded              :   true,
                all_periods                 :   all_periods
            },  () => {
               
                 let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                 if (ParamObject.get('search_item_ids')) {
                     let updateFilterForm        =        {};
             

                if (ParamObject.get('search_item_ids')) {    
                    updateFilterForm['search_item_ids']         =       [ParamObject.get('search_item_ids')];   
                }

                setTimeout(() => {
                    this.setState({
                        reportFilterForm        :       { ...this.state.reportFilterForm, ...updateFilterForm },
                        searchedItem            :       [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }]
                    }, () => {
                        this.reportFilterFormSubmit()
                    })
                }, 1000)
            
                } else {
                    this.reportFilterhandler()
                }

            });
            
        }, (err) => {

        });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
       
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_workstation_ids') {
                        show_val            =   this.state.reportFilterFormData.workstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_batch_number') {
                        show_val            =  this.state.reportFilterForm.search_batch_number;
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_voucher_number') {
                        show_val            =  this.state.reportFilterForm.search_voucher_number;
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.reportFilterForm.search_transaction_id;
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'date_range') {
                        let ParamObject                 =   new URLSearchParams(this.props.location.search);
               
                        
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom       =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date && !ParamObject.get('date_range_start') ? s.display_with_date : display_custom)
                        // show_val            = this.state.reportFilterFormData.periods.filter((s) => value.includes(s.key)).map(s => console.log(s));
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm,need_item_profile : "Y",action:'data'};
        
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    viewAllocationModalInit                =   (id)        =>      {
        this.setState({
               demandId       : id
        })
        this.viewAllocationModal.show()
       
        
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, action : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path,response.data.msg,response.data.navigation,this.props)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    checkAllItemList() {
        let allocationItemForCheckbox      =   document.getElementsByClassName('demand_item_id_checkbox')
        if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                allocationItemForCheckbox[i].checked = document.getElementById('all_check_allocation_item').checked;
            }
        }
    }

    addToConsumptionModalInit       =       ()      =>      {
        
        let transaction_ids            =        [];
        let warehouse_ids              =        [];
        let pendingQtyList             =        [];
        let dataList                   =        [];
        let statusList                 =        [];
        
        var showErrorMessage                =   false;
        let allocationItemForCheckbox      =   document.getElementsByClassName('demand_item_id_checkbox');
        if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                if(allocationItemForCheckbox[i].checked) {
                    transaction_ids.push(allocationItemForCheckbox[i].getAttribute("data-transactionId"));
                    warehouse_ids.push(allocationItemForCheckbox[i].getAttribute("data-warehouseId"));
                    pendingQtyList.push(allocationItemForCheckbox[i].getAttribute("data-pendingQty"));
                    statusList.push(allocationItemForCheckbox[i].getAttribute("data-status"));
                    let demandId = allocationItemForCheckbox[i].value;
                    let data = this.state.listingData.find(i => i.demand_item_id == demandId);
                    dataList.push({...data,demand_item_id:data.demand_item_id,demand_transaction_id:allocationItemForCheckbox[i].getAttribute("data-transactionId")})
                
                }
            }
        }
        
        let transaction_ids_unique            =   transaction_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        let warehouse_ids_unique              =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if(transaction_ids_unique.length > 1) {
            toast.error('Please select item with same Transaction ID', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same Warehouse ID', { position: toast.POSITION.TOP_RIGHT }); 
        } else if(pendingQtyList.includes('0')) {
            toast.error('Every item must have pending qty more than 0', { position: toast.POSITION.TOP_RIGHT }); 
        }else if(statusList.includes('0')) {
            toast.error('Item Demand with Status Draft cannot be allocated', { position: toast.POSITION.TOP_RIGHT }); 
        }else if(dataList.length == 0){
            toast.error('Please Select Atlease one Item', { position: toast.POSITION.TOP_RIGHT }); 
        }else{
        
          
            let demandStateData                    =   {   
                demandData                  :   dataList[0],
                items                       :   dataList,
            };
             this.props.history.push({ pathname: `/allocation_list/add`,  state: { addAllocationFromDemand : demandStateData } });
        }
    
    }

    releasedItemModalInit       =       ()      =>      {
            
            let cancelledItemList          =        this.state.cancelledItemList;
            let transaction_ids            =        [];
            let warehouse_ids              =        [];
            let pendingQtyList             =        [];
            let dataList                   =        []
            
            var showErrorMessage                =   false;
            let allocationItemForCheckbox      =   document.getElementsByClassName('demand_item_id_checkbox');
            if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
                for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                    if(allocationItemForCheckbox[i].checked) {
                        transaction_ids.push(allocationItemForCheckbox[i].getAttribute("data-transactionId"));
                        warehouse_ids.push(allocationItemForCheckbox[i].getAttribute("data-warehouseId"));
                        pendingQtyList.push(allocationItemForCheckbox[i].getAttribute("data-pendingQty"));
                        let demandId = allocationItemForCheckbox[i].value;
                        let data = this.state.listingData.find(i => i.demand_item_id == demandId);
                        dataList.push({...data,id: data.demand_item_id})
                    
                    }
                }
            }
            
        let transaction_ids_unique            =   transaction_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        let warehouse_ids_unique              =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        
        if(transaction_ids_unique.length > 1) {
            toast.error('Please select item with same Transaction ID', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same Warehouse ID', { position: toast.POSITION.TOP_RIGHT }); 
        } else if(pendingQtyList.includes('0')) {
            toast.error('Every item must have pending qty more than 0', { position: toast.POSITION.TOP_RIGHT }); 
        }else if(dataList.length == 0){
            toast.error('Please Select Atlease one Item', { position: toast.POSITION.TOP_RIGHT }); 
        }else{
            this.setState({
                cancelledItemList          :       dataList,
            },() => this.releasedItemModal.show())  
        }    
    }

    submitCancelItemForm            =       (e)      =>      {
        e.preventDefault()
        
        let items                   =        [...this.state.cancelledItemList];
          
        let itemsLists              =        items.map(i => {
                                              
                                                 return {demand_item_id : i.demand_item_id, cancel_qty : parseInt(i.new_released_qty)};
                                                });
                                               
        let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists],transaction_id : items[0].transaction_id}
        
          this.setState({ cancelItemFormSubmitting: true })
          HttpAPICall.withAthorization('PUT', this.releaseUrl, this.props.access_token, {}, { ...formData }, (response) => {
              toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
              this.releasedItemModal.hide()
              this.setState({cancelledItemForm         :       {...this.cancelItemFormInit}})
              this.loadListingTblData()
          }).then(() => this.setState({ cancelItemFormSubmitting: false }));
      }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faPallet} /> Item Wise Item Demand Report</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || !this.state.download_access} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a role="button" href
                                onClick={() => {
                                    if (this.props.permissions.includes('isc-allocation-item-cancel')) {
                                        return this.releasedItemModalInit();
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('isc-allocation-item-cancel') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('isc-allocation-item-cancel') ? 'This action cannot be performed' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Cancel Item</a>
                            
                        </li>
                        <li>
                            <a role="button" href
                                onClick={() => {
                                    if (this.props.permissions.includes('isc-consumption-add')) {
                                        return this.addToConsumptionModalInit();
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.includes('isc-consumption-add') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.includes('isc-consumption-add') ? 'This action cannot be performed' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Add to Allocation</a>

                        </li>
                    </ul>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            options={this.state.all_warehouses}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.all_warehouses.filter(t => this.state.reportFilterForm.search_warehouse_ids.includes(t.value))}
                            placeholder="Please Select Warehouse"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Workstation</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_workstation_ids')}
                            options={this.state.reportFilterFormData.workstations}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.reportFilterFormData.workstations.filter(t => this.state.reportFilterForm.search_workstation_ids.includes(t.value))}
                            placeholder="Please Select Workstation"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Employees</label>
                    <div className="col-sm-6">
                        <InputEmployeeSearch
                            changeEvent={(selectedOption) => {
                                
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_employee_ids');
                                this.setState({
                                    reportFilterForm  : {...this.state.reportFilterForm,search_employee_ids : selectedOption.length > 0 ? selectedOption.map(o => o.value) : []},
                                    searchedEmployees : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"

                        />
                    </div>
                </div>
                {this.state.isc_configuration && this.state.isc_configuration.track_batch_expiry === "N" ? null : <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Batch Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_batch_number"
                            type="text"
                            value={this.state.reportFilterForm.search_batch_number}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Batch Number"
                        />
                    </div>
                </div>}
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Voucher Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_voucher_number"
                            type="text"
                            value={this.state.reportFilterForm.search_voucher_number}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Voucher Number"
                        />
                    </div>
                </div>
                {/* <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Enter Transaction ID"
                        />
                    </div>
                </div> */}
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Transaction Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            required={true}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm    :   {...this.state.reportFilterForm, date_range: period, date_range_start: startDate, date_range_end: endDate}
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr className='text-end'>
                    <th className="text-center" style={{ width: "4%" }}>
                        <input type='checkbox' onChange={this.checkAllItemList} id="all_check_allocation_item" /> 
                        </th>
                        <th className="text-center" style={{width:"13%"}}>Item Name</th>
                        <th className="text-center" style={{width:"9%"}}>Item Code</th>
                        <th className='text-start' style={{width:"8%"}}>Transaction ID</th>
                        <th className='text-start' style={{width:"9%"}}>Date</th>
                        <th className="text-center" style={{width:"14%"}}>Warehouse</th>
                        <th className="text-center" style={{width:"16%"}}>Demand for</th>
                        <th  className="text-center" scope="col" style={{ width: "7%" }} > Status</th>
                        <th scope="col" style={{ width: "6%" }} > Qty</th>
                        <th scope="col" style={{ width: "4%" }} > Allocated</th>
                        <th scope="col" style={{ width: "4%" }} > Consumed</th>
                        <th scope="col" style={{ width: "4%" }} > Cancelled</th>
                        <th scope="col" style={{ width: "7%" }} > Pending</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((rowData, k) => {

                            return (<tr>
                                <td className='text-center'>
                                    <input
                                        type='checkbox'
                                        name="foo"
                                        value={rowData.demand_item_id}
                                        data-pendingQty={rowData.pending_qty}
                                        className="demand_item_id_checkbox"
                                        data-warehouseId={rowData.warehouse_id}
                                        data-transactionId={rowData.transaction_id}
                                        data-itemId={rowData.item_id}
                                        data-status={rowData?.status?.level}
                                    />
                                </td>
                                <td>{rowData.item_profile.name}</td>
                                <td>{rowData.item_profile.item_code}</td>
                                <td className='text-center'><a role="button" className="link-primary" onClick={() => this.viewAllocationModalInit(rowData.transaction_id)}>{rowData.transaction_id}</a></td>
                                <td className='text-center'>{rowData.transaction_date}</td>
                                <td>{rowData.warehouse.name}</td>
                                <td className='text-capitalize'>
                                    {rowData.demand_for == 'asset'
                                        ? (rowData.asset ? rowData.asset.name + ' ' : '')
                                        : null
                                    }
                                    {rowData.demand_for == 'workstation'
                                        ? (rowData.workstation ? rowData.workstation.workstation_name + ' ' : '')
                                        : null
                                    }
                                    {rowData.demand_for == 'employee'
                                        ? (rowData.employee ? rowData.employee.display_full_name + ' ' : '')
                                        : null
                                    }
                                    {rowData.demand_for == 'sub_assembly'
                                        ? (rowData.sub_assembly ? rowData.sub_assembly.name + ' ' : '')
                                        : null
                                    }
                                    <span className='form-text text-xs'> ({rowData.demand_for == 'asset' ? 'Asset' : rowData.demand_for == 'workstation' ? 'Workstation' : rowData.demand_for == 'sub_assembly' ? 'Sub Assembly' : "Employee"})</span>
                                </td>
                                <td className="text-center">{rowData.status ? rowData.status.name : "-"}</td>
                                <td className="text-end">{rowData.qty}</td>
                                <td className="text-end">{rowData.allocated_qty ? rowData.allocated_qty : 0}</td>
                                <td className="text-end">{rowData.consumed_qty ? rowData.consumed_qty : 0}</td>
                                <td className="text-end">{rowData.cancelled_qty ? rowData.cancelled_qty : 0}</td>
                                <td className="text-end">{rowData.pending_qty ? rowData.pending_qty : 0}</td>
                            </tr>);
                        })
                        )
                        : (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    allocationDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewAllocationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Item Demand Detail </h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className="modal-body">
                            <div className="tab_content_header">
                                  <ItemDemandDetail demandId={this.state.demandId}/> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    releasedItemModalJsx               =        ()             =>        {
        return (
            <div className="modal fade" id="ReleasedItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Released Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "12%" }}>Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Released Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Pending Qty</th>

                                                    <th scope="col" style={{ width: "12%" }}>Cancel</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="text-end" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td className='text-start'>{i.item_profile ? <div><span className="fw-bold">{i.item_profile.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.item_profile.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.item_profile?.manufacturer?.name ?? '-'} (MPN : {i.item_profile?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.item_profile?.tags?.length > 0
                                                                    ? (i.item_profile?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td>{i.qty ? i.qty : 0}</td>
                                                        <td>{i.released_qty ? i.released_qty : 0}</td>
                                                        <td>{i.pending_qty ? i.pending_qty : 0}</td>

                                                        <td className="text-end"> <input name="new_released_qty"
                                                            type="number"
                                                            value={this.state.cancelledItemList[k]?.new_released_qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'cancelledItemList')}
                                                            className="form-control text-end"
                                                            autoComplete="off"
                                                            placeholder="Cancel Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                        /></td>




                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>ItemWise Demand - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                        ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader  />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null 
                        }
                    </div>
                </div>
            </div>
            {this.allocationDetailsModalJsx()}
            {this.releasedItemModalJsx()}
            {/* <AllocationRelease ref={this.releaseItemRef} refreshList={() => this.loadListingTblData(1)}/> */}
           
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemWiseDemandReport);