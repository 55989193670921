import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import Ax from '../../../../components/hoc/Ax';
import { Modal } from 'bootstrap';
import TapSelect from '../../../../components/ui/TapSelect';
import HttpAPICall from '../../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import { toast } from 'react-toastify';
import GeneralSetting from './GeneralSetting';
import { Link } from 'react-router-dom';
import AdditionalAttribute from '../../AdditionalAttribute';



class PurchaseConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAddStatusForm      =         {
            name                    :        '',
            role_ids                :        [],
            notes                   :        ''
        }

        this.state                  =        {
            statusList              :        [],
            listing_loading         :       false,
            addStatusForm           :       {...this.initAddStatusForm},
            allRoleList             :       [],
            addFormDataLoaded       :       false,
            addStatusFormSubmitting :       false,
            can_Edit                :       true,
            edit                    :       false,
            form_loaded             :       false,
            can_change_role         :       'N',
            addition_attr_show      : false
        }
        
        this.purStatusUrl    =   INVENTORY_API_BASE_URL_2  + "/purchase_status";

    }

    componentDidMount(){
        this.getStatusListing()
        this.addPurchaseStatusModal            =        new Modal(document.getElementById('addPurchaseStatusModal'), {keyboard: false, backdrop :false});
     }

     getStatusListing       =       ()      =>  {
        this.setState({ listing_loading : true});
        HttpAPICall.withAthorization('GET', this.purStatusUrl, this.props.access_token,{},{},(response) => {
            this.setState({statusList: response.data.data})
         }).then(() => this.setState({listing_loading : false}));
     }

     openEditStatus         =       (id)      =>      {
        this.addPurchaseStatusModal.show()
        
        this.setState({form_loaded : true})
         HttpAPICall.withAthorization('GET', this.purStatusUrl + '/form_data', this.props.access_token, null, null, (response) => {
             let allRoleList        =       response.data.data.roles.map((mou) => { return { value: mou.id, label: mou.role } })
             let allRole            =       [...allRoleList, { value: 47, label: "Administrator" }]
             this.setState({
                 addFormDataLoaded      :       true,
                 allRoleList            :       allRole
             }, () => {
                 let statusList         =       this.state.statusList.find(function (transaction) {
                     return transaction.id == id;
                 });

                 let roles = statusList.roles.map((mou) => mou.id)
                 this.setState({
                     addStatusForm: {
                         name           :       statusList.name,
                         notes          :       statusList.notes === null ? "" : statusList.notes,
                         id             :       statusList.id,
                         role_ids       :       roles
                     },
                     can_Edit           :       statusList.can_edit,
                     edit               :       true,
                     can_change_role    :       statusList.system_generated

                 })
             });
         }).then(() => this.setState({ form_loaded: false }))
       
     }

     addPurchaseStatusModalInit     =       ()      =>      {
        this.addPurchaseStatusModal.show()
       
     }

     submitAddStatusForm    =          (e)      =>      {
        e.preventDefault()
        let frmData = {...this.state.addStatusForm}

        this.setState({addStatusFormSubmitting: true})
           
        if(this.state.edit){
            HttpAPICall.withAthorization('PUT', this.purStatusUrl, this.props.access_token, {} , {...frmData} , (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addPurchaseStatusModal.hide();
                this.getStatusListing()
                this.setState({addStatusForm  :  {...this.initAddStatusForm},can_Edit : true})
            }).then(() => this.setState({addStatusFormSubmitting: false}))
        }
     }

     addPurchaseStatusModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="addPurchaseStatusModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addStatusModalLabel">Edit Status</h5>
                        </div>
                        <form onSubmit={this.submitAddStatusForm} id="addStatusForm" method="post" encType="multipart/form-data">
                            <div className="modal-body">
                                {this.state.form_loaded ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Status Name</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addStatusForm') }}
                                                    value={this.state.addStatusForm.name}
                                                    placeholder="Please Enter Status Name"
                                                />
                                            </div>
                                        </div>

                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label ">Notes</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <input
                                                    name="notes"
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.can_Edit == false ? true : false}
                                                    onChange={(e) => { this.formInputHandler(e, 'addStatusForm') }}
                                                    value={this.state.addStatusForm.notes}
                                                    placeholder="Please Enter Notes"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center my-3">
                                            <div className="col-sm-3">
                                                <label htmlFor="transaction Type" className="form-label require">Role</label>
                                            </div>
                                            <div className="col-sm-9">
                                                <TapSelect
                                                    options={this.state.allRoleList}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addStatusForm', 'role_ids')}
                                                    value={this.state.allRoleList.filter(u => this.state.addStatusForm?.role_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                     isMulti={true}
                                                    isClearable={true}
                                                    isDisabled={this.state.can_change_role == 'Y'}
                                                    placeholder="Select Roles"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.addStatusFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => { this.setState({ addStatusForm: { ...this.initAddStatusForm } }) }}>Close</button>
                                <button type="submit" disabled={this.state.addStatusFormSubmitting} className="btn btn-primary">
                                    Submit {this.state.addStatusFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">

                        <li className="nav-item" role="presentation">
                            <button className="nav-link active " id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings"
                                type="button" role="tab" aria-controls="settings" aria-selected="true">General Settings
                            </button>
                        </li>

                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="additional-attr" data-bs-toggle="tab" data-bs-target="#tagging" type="button" role="tab" aria-controls="tagging" aria-selected="true" onClick={() => this.setState({addition_attr_show : true})}>Additional Attribute
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane show active" id="workflow" role="tabpanel" aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <Ax>
                                    <div className="tab_content_wrapper">
                                        <span className="content_heading">Status for Purchase </span>

                                    </div>
                                    <table className="table table-hover table-bordered my-3 ">
                                        <thead>
                                            <tr className='table-secondary'>
                                                <th scope="col" style={{ width: "20%" }}>Status</th>
                                                <th scope="col" style={{ width: "40%" }}>Roles</th>
                                                <th scope="col" style={{ width: "40%" }} >Notes</th>
                                            </tr>

                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                this.state.listing_loading
                                                    ? <tr>
                                                        <td className="text-center" colSpan="6"><Loader /></td>
                                                    </tr>
                                                    :
                                                    (this.state.statusList.map((item, index) => {
                                                        return <tr key={item.id}>
                                                            <td scope="row">{item.name}</td>
                                                            <td scope="row">{item.auto_generated === "Y" ? '' : item.roles.map((mou) => mou.role).join(", ")}</td>
                                                            <td scope="row">
                                                                <div className="row">
                                                                    <div className="col-10">
                                                                        {item.notes === null ? "-" : item.notes}
                                                                    </div>
                                                                    {item.auto_generated === "Y" ? null :
                                                                        <div className="col-2 text-center">
                                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                                                                data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                                                            />
                                                                            <ul className="dropdown-menu">
                                                                                <li>
                                                                                    <Link role="button"
                                                                                        onClick={() => {
                                                                                            if (this.props?.permissions?.includes('isc-purchase-status-manage')) {
                                                                                                return this.openEditStatus(item.id)
                                                                                            }
                                                                                        }}
                                                                                        className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-purchase-status-manage') ? '' : 'disabled'].join(' ')}
                                                                                        title={!this.props?.permissions?.includes('isc-purchase-status-manage') ? 'You do not have permission to perform this action' : ''}
                                                                                        style={{ "pointerEvents": "all" }}
                                                                                    >Edit</Link>
                                                                                </li>
                                                                               
                                                                            </ul>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }))}
                                        </tbody>
                                    </table>
                                    {this.addPurchaseStatusModalJsx()}
                                </Ax>
                            </div>
                        </div>
                        <div className="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            <div className="tab_content_header">
                                <GeneralSetting />
                            </div>
                        </div>
                        <div className="tab-pane" id="tagging" role="tabpanel"
                            aria-labelledby="additional-attr">
                            <div className="tab_content_header">
                            {
                                this.state.addition_attr_show &&
                                <AdditionalAttribute category="purchase"/>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(PurchaseConfiguration);