import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from 'react-toastify';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../../services/TapApiUrls';



class ConfigurationAdd extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.site_productivity_url      =       TapApiUrls.IAM + '/site_productivity_config';

        this.initAddConfigurationForm           =        {
            activity_id               :        '',
            activity_new              :      '',
            activity_code             :        '',
            unit_of_measurement       :        '',
            unit_new                  :         '',
            "number_of_trip"            :   "N",
            "number_of_trip_required"   :   "N",
            "material"                  :   "N",
            "material_required"         :   "N",
            "attachment"                :   "N",
            "attachment_required"       :   "N",
            "workstation"               :   "N",
            "workstation_required"      :   "N",
            "employee"                  :   "N",
            "employee_required"         :   "N",
           site_ids                     :   [],
           all_sites                    :   false,
           additional_attribute_keys    :   [],
           enable_productivity_formula  :   'N',
           productivity_formula        :   '',
           account_code_id              :   '',
           status                    :   '',
           starting_date                :   '',
           closing_date                 :   '',
           target_type                  :   '',
           total_productivity_target    :   '',
           target_per_day               :   '',
           cost_per_unit_target         :   '',
           notes                        :   ''
        }

        this.state                    =        {
            saveFormSubmitting         :       false,
            addConfigurationForm       :       {...this.initAddConfigurationForm},
           allActivityList             :       [],
           allUnitsList                :       [],
           form_data_loading           :       false,
           iam_user_sites              :        [],
           allAttributesList           :        [],
           allAccountingCode           :        [],
           allStatus                   :        [],
           enable_account_code         :        'N',
           addNewActivity              :        'N',
           addNewUnit                  :        'N',
           runtime_activity_creation       :   'N',
           runtime_productivity_unit_creation : 'N',
           allTargetTypes              :        [{value:"onetime",label:"One Time"},{value:"daily",label:"Daily"},{value:"weekly",label:"Weekly"},{value:"monthly",label:"Monthly"},{value:"quarterly",label:"Quarterly"},{value:"yearly",label:"Yearly"}],
          configurationData              :        null,
          
        };
    }

    componentDidMount() {
       
        this.initalizeFormData(this.props)
    }

    componentWillReceiveProps(nextProps){

        if (nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initalizeFormData(nextProps)
        }
             
    }

    initalizeFormData       =       (pr)        =>      {
        this.setState({form_data_loading : true})
        HttpAPICall.withAthorization('GET', this.site_productivity_url + '/form_data',this.props.access_token, null, null, (response) => {
            let respData = response.data
            this.setState({
                iam_user_sites          :   pr.iam_user_sites ? pr.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`}}) : [], 
                allActivityList         :   respData && respData.activity_data && respData.activity_data.length > 0 ? respData.activity_data.map(a => { return ({ value: a.id, label: a.activity_name, production_units: a.production_units }) }) : [],
                allAttributesList       :   respData && respData.attributes_data  && respData.attributes_data.length > 0 ? respData.attributes_data.map(a => { return ({ value: a.key, label: a.name,type:a.type }) }) : [],
                allAccountingCode       :   respData && respData.account_codes  && respData.account_codes.length > 0 ? respData.account_codes.map(a => { return ({ value: a.id, label: a.name }) }) : [],
                allStatus               :   respData && respData.status  && respData.status.length > 0 ? respData.status.map(a => { return ({ value: a.key, label: a.status }) }) : [],
                enable_account_code     :   respData && respData.enable_account_code  && respData.enable_account_code == 'Y' ? 'Y' : 'N',
                runtime_activity_creation   :       respData && respData.runtime_activity_creation && respData.runtime_activity_creation == 'Y'  ? 'Y' : 'N',
                runtime_productivity_unit_creation   :       respData && respData.runtime_productivity_unit_creation && respData.runtime_productivity_unit_creation == 'Y'  ? 'Y' : 'N',
            },() => {
                if(pr.location && pr.location.state && pr.location.state.configurationData){
                    let configurationData = pr.location.state.configurationData;

                    let activityListContainingProduction =      this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.find(p => { return (p.value == configurationData.activity_id) });
                    let allProductionUnitList           =       activityListContainingProduction && activityListContainingProduction.production_units && activityListContainingProduction.production_units.length > 0   ?  activityListContainingProduction.production_units.map((d) => {  return ({ value: d.id, label: d.measuring_unit }) })  : [];
                    this.setState({
                        allUnitsList : allProductionUnitList,
                    }, () => {
                        this.setState({
                            addConfigurationForm        :       { ...this.initAddConfigurationForm,
                                id                        :        configurationData.id ? configurationData.id : "",
                                activity_id               :        configurationData.activity_id ? configurationData.activity_id : "",
                                activity_code             :        configurationData.activity_code ? configurationData.activity_code : "",
                                unit_of_measurement       :        configurationData.uom ? configurationData.uom.id : "",
                                "number_of_trip"            :   configurationData.number_of_trip ? configurationData.number_of_trip : "N",
                                "number_of_trip_required"   :   configurationData.number_of_trip_required ? configurationData.number_of_trip_required : "N",
                                "material"                  :   configurationData.material ? configurationData.material : "N",
                                "material_required"         :   configurationData.material_required ? configurationData.material_required : "N",
                                "attachment"                :   configurationData.attachment ? configurationData.attachment : "N",
                                "attachment_required"       :   configurationData.attachment_required ? configurationData.attachment_required : "N",
                                "workstation"               :   configurationData.workstation ? configurationData.workstation : "N",
                                "workstation_required"      :   configurationData.workstation_required ? configurationData.workstation_required : "N",
                                "employee"                  :   configurationData.employee ? configurationData.employee : "N",
                                "employee_required"         :   configurationData.employee_required ? configurationData.employee_required : "N",
                               site_ids                     :   this.state.iam_user_sites && this.state.iam_user_sites.length == configurationData.sites.length ?  [] : configurationData.sites && configurationData.sites.length > 0  ? configurationData.sites.map(st => st.id) : [],
                               all_sites                    :   this.state.iam_user_sites && this.state.iam_user_sites.length == configurationData.sites.length ? true : false,
                               additional_attribute_keys    :   configurationData.additionalAttr_details && configurationData.additionalAttr_details.length > 0 ? configurationData.additionalAttr_details.map(cd => {return(cd.formula_key)}): [],
                               enable_productivity_formula  :   configurationData.productivity_formula ? 'Y' : 'N',
                               productivity_formula        :   configurationData.productivity_formula ? configurationData.productivity_formula : '',
                               account_code_id              :  this.state.enable_account_code == 'Y' && configurationData.account_Code ? configurationData.account_Code.id : "",
                               status                    :   configurationData.status ? configurationData.status.status : "",
                               starting_date                :   configurationData.starting_date ? configurationData.starting_date : "",
                               closing_date                 :   configurationData.closing_date ? configurationData.closing_date : "",
                               target_type                  :   configurationData.target_type ? configurationData.target_type : "",
                               total_productivity_target    :   configurationData.total_productivity_target ? configurationData.total_productivity_target : "",
                               target_per_day               :   configurationData.target_per_day ? configurationData.target_per_day : "",
                               cost_per_unit_target         :   configurationData.cost_per_unit_target ? configurationData.cost_per_unit_target : "",
                            },
                            configurationData   :   configurationData
                        })
                    })
                   

                }
            })
        }).then(() => this.setState({form_data_loading: false}));
    }

    closeEvent          =       ()          =>      {

        this.props.history.push({ pathname: `/site_productivity_configuration` })
    }

    onSelectingAllSites             =           (e,key)             =>      {
        let checkedSite = document.getElementById('all_sites').checked
        if(checkedSite == true) {
            this.setState({
                 addConfigurationForm : {...this.state.addConfigurationForm,
                   all_sites  :  true,
                   site_ids  :   [],
            }})
        }else{
           this.setState({
               addConfigurationForm : {...this.state.addConfigurationForm,
                 all_sites  :  false,
                 site_ids  :   []
          }})
        }
   }

   calculateTragetPerDay            =           ()          =>      {
        let startDate       =   this.state.addConfigurationForm.starting_date;
        let endDate         =   this.state.addConfigurationForm.closing_date;
        let durationInDays =    1;
        if(this.state.addConfigurationForm.target_type == 'onetime'){
           if(startDate && endDate){
                let starting_date = moment(startDate);
                let closing_date = moment(endDate);
                durationInDays  =  closing_date.diff(starting_date, 'days');
           }else{
             toast.error("For One Time first select Closing and Starting Date", { position: toast.POSITION.TOP_RIGHT });
             this.setState({
                addConfigurationForm: { ...this.state.addConfigurationForm, target_per_day:"",total_productivity_target:"" }
            })
           }
        }else if(this.state.addConfigurationForm.target_type  ==  'daily'){
            durationInDays  =   1;
        }else if(this.state.addConfigurationForm.target_type  ==  'weekly'){
            durationInDays  =   7;
        }else if(this.state.addConfigurationForm.target_type  ==  'monthly'){
            durationInDays  =   30;
        }else if(this.state.addConfigurationForm.target_type  ==  'quartly'){
            durationInDays  =   91;
        }else{
            durationInDays  =   365;
        }

       if (this.state.addConfigurationForm.target_type == 'onetime' && startDate && endDate) {
           let target_per_day = (Number(this.state.addConfigurationForm.total_productivity_target) / durationInDays)
           this.setState({
               addConfigurationForm: { ...this.state.addConfigurationForm, target_per_day: parseFloat(target_per_day).toFixed(2) }
           })
       } else if(this.state.addConfigurationForm.target_type !== 'onetime') {
           let target_per_day = (Number(this.state.addConfigurationForm.total_productivity_target) / durationInDays)
           this.setState({
               addConfigurationForm: { ...this.state.addConfigurationForm, target_per_day: parseFloat(target_per_day).toFixed(2) }
           })
       }
    }

    submitAddConfigurationForm       =       (e)     =>      {
        e.preventDefault();
       
        let site = this.state.iam_user_sites.map(s => s.value);
        let site_ids    =   this.state.addConfigurationForm.all_sites == true ? site : this.state.addConfigurationForm.site_ids;
        let keys        =   this.state.addConfigurationForm.additional_attribute_keys;
        let additional_attributes_types = keys && keys.length > 0 && this.state.allAttributesList && this.state.allAttributesList.length > 0 ? this.state.allAttributesList.filter(al => keys.includes(al.value) && al.type == "numeric") : [];
       
       
        if(additional_attributes_types && additional_attributes_types.length > 0){
            additional_attributes_types.forEach((el, k) => {
                window[el.value] = k + 1;
            })
        }
        
        try {
            eval(this.state.addConfigurationForm.productivity_formula);
            this.setState({ saveFormSubmitting: true })
            let site_add_url =  this.state.configurationData ? "/edit" : "/add";
            HttpAPICall.withAthorization(this.state.configurationData ? 'PUT' : 'POST', this.site_productivity_url + site_add_url, this.props.access_token, null, { ...this.state.addConfigurationForm,site_ids:site_ids }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.props.history.push('/site_productivity_configuration')
            }).then(() => this.setState({ saveFormSubmitting: false }))
           
        } catch (e) {
            toast.error('Please check the formula', { position: toast.POSITION.TOP_RIGHT });
        }
    }

    saveConfigurationFormJsx         =        ()       =>      {
        let keys    =   this.state.addConfigurationForm.additional_attribute_keys;
        let additional_attributes_types = keys && keys.length > 0 && this.state.allAttributesList && this.state.allAttributesList.length > 0 ? this.state.allAttributesList.filter(al => keys.includes(al.value) && al.type == "numeric") : [];
       
        return (<form className="bg-white p-3" onSubmit={this.submitAddConfigurationForm} id="saveConfigurationForm">
            <div className="row align-items-center my-2">
                <label className="col-sm-3 col-form-label require">Activity Name</label>
                <div className="col-sm-4">
                    {this.state.addNewActivity == "Y"
                        ? <input
                            name="activity_new"
                            type="text"
                            value={this.state.addConfigurationForm.activity_new}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                            placeholder="Please enter Activity Name"
                            required={true}
                        />
                        : <TapSelect
                            options={this.state.allActivityList}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addConfigurationForm', 'activity_id');
                                this.setState({
                                    allUnitsList: selectedOption && selectedOption.production_units && selectedOption.production_units.length > 0 ? selectedOption.production_units.map(p => { return ({ value: p.id, label: p.measuring_unit }) }) : []
                                })
                            }}
                            isSearchable={true}
                            value={this.state.allActivityList.find(s => this.state.addConfigurationForm.activity_id == s.value)}
                            isClearable={true}
                            placeholder="Select Activity Name"
                            autoCompelete="off"
                            required={true}
                            autoFocus={true}
                        />}
                   
                </div>
                {this.state.runtime_activity_creation == 'Y' && <div className="col-sm-2">
                    <input
                        name="addNewActivity"
                        type="checkbox"
                        value={this.state.addNewActivity}
                        onChange={(e) => {
                            let allUnitsList = [];
                            let activityListContainingProduction = this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.filter(p => { return (p.production_units && p.production_units.length > 0 ? true : false) });
                            let allProductionUnitList = activityListContainingProduction && activityListContainingProduction.length > 0 && allUnitsList.concat(activityListContainingProduction.flatMap((d) => { return d.production_units.map(p => { return ({ value: p.id, label: p.measuring_unit }) }) }))
                            const key = 'value';
                            const UniqueProductionUnitByKey = allProductionUnitList && allProductionUnitList.length > 0 && [...new Map(allProductionUnitList.map(item => [item[key], item])).values()];

                            this.setState({
                                addNewActivity: this.state.addNewActivity == 'N' ? 'Y' : 'N',
                                allUnitsList: this.state.addNewActivity == 'N' && UniqueProductionUnitByKey && UniqueProductionUnitByKey.length > 0 ? UniqueProductionUnitByKey : [],
                                addConfigurationForm: this.state.addNewActivity == 'N' ? { ...this.state.addConfigurationForm, activity_id: '' } : { ...this.state.addConfigurationForm }
                            })
                        }}
                        checked={this.state.addNewActivity == 'Y'}
                        className="form-check-input"
                        id="newActivity"

                    />
                    <label className="form-check-label mx-2 text-sm" htmlFor="newActivity">Add New </label>
                </div>}
               
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label require">Activity Code</label>
                <div className="col-sm-4">
                    <input
                        name="activity_code"
                        type="text"
                        value={this.state.addConfigurationForm.activity_code}
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                        placeholder="Please enter Activity Code"
                        required={true}
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label require">Unit of Measurement</label>
                <div className="col-sm-4">
                    {this.state.addNewUnit == "Y"
                        ? <input
                            name="unit_new"
                            type="text"
                            value={this.state.addConfigurationForm.unit_new}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                            placeholder="Please enter Unit of Measurement"
                            required={true}
                        />
                        : <TapSelect
                            options={this.state.allUnitsList}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addConfigurationForm', 'unit_of_measurement');
                            }}
                            isSearchable={true}
                            value={this.state.allUnitsList.find(s => this.state.addConfigurationForm.unit_of_measurement == s.value)}
                            isClearable={true}
                            placeholder="Select UOM"
                            autoCompelete="off"
                            required={true}
                        />}
                </div>
                {this.state.runtime_productivity_unit_creation == 'Y' && <div className="col-sm-2">
                    <input
                        name="addNewUnit"
                        type="checkbox"
                        value={this.state.addNewUnit}
                        onChange={(e) => {
                            this.setState({
                                addNewUnit: this.state.addNewUnit == 'N' ? 'Y' : 'N',
                                addConfigurationForm: this.state.addNewUnit == 'N' ? { ...this.state.addConfigurationForm, unit_of_measurement: '' } : { ...this.state.addConfigurationForm }
                            })
                        }}
                        checked={this.state.addNewUnit == 'Y'}
                        className="form-check-input"
                        id="addNewUnit"
                    />
                    <label className="form-check-label text-sm mx-2" htmlFor="addNewUnit">Add New </label>
                </div>}
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label className="col-sm-3 col-form-label require">Sites</label>
                </div>
                <div className="col-sm-4 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addConfigurationForm', 'site_ids')}
                        options={this.state.iam_user_sites}
                        isSearchable={true}
                        isClearable={true}
                        required={this.state.addConfigurationForm.all_sites == true ? false : true}
                        isMulti={true}
                        isDisabled={this.state.addConfigurationForm.all_sites == true ? true : false}
                        value={this.state.iam_user_sites.filter(s => this.state.addConfigurationForm.site_ids.includes(s.value))}
                        placeholder={this.state.addConfigurationForm.all_sites == true ? "All Sites" : "Please Select Sites"}
                    />
                </div>
                <div className='col-sm-2'>
                    <div className="form-check form-check-inline">
                        <input
                            name="all_sites"
                            type="checkbox"
                            value={true}
                            onChange={(e) => { this.onSelectingAllSites(e, 'all_sites') }}
                            className="form-check-input"
                            id="all_sites"
                            checked={this.state.addConfigurationForm.all_sites == true}
                        />
                        <label className="form-check-label" htmlFor="all_sites">All Sites</label>
                    </div>
                </div>
            </div>
            <div className="row align-items-center my-2">
                <label className="col-sm-3 col-form-label require">Status</label>
                <div className="col-sm-4">
                    <TapSelect
                        options={this.state.allStatus}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addConfigurationForm', 'status');
                        }}
                        isSearchable={true}
                        value={this.state.allStatus.find(s => this.state.addConfigurationForm.status == s.value)}
                        isClearable={true}
                        placeholder="Select Status"
                        autoCompelete="off"
                        required="off"
                    />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">No of Trips/Sessions</label>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="number_of_trip"
                            value="Y"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, number_of_trip_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.number_of_trip == "Y"}
                        /> Yes
                    </label>
                </div>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="number_of_trip"
                            value="N"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, number_of_trip_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.number_of_trip == "N"} /> No
                    </label>
                </div>
                {this.state.addConfigurationForm.number_of_trip == 'Y' && <div className="col-md-2">
                    <input
                        name="number_of_trip_required"
                        type="checkbox"
                        value={this.state.addConfigurationForm.number_of_trip_required}
                        onChange={(e) => {
                            if (e.target.value && e.target.value == 'N') {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, number_of_trip_required: 'Y' },
                                })
                            } else {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, number_of_trip_required: 'N' },
                                })
                            }

                        }}
                        checked={this.state.addConfigurationForm.number_of_trip_required == 'Y'}
                        className="form-check-input"
                        id="tripRequired"

                    />
                    <label className="form-check-label mx-2 text-sm" htmlFor="tripRequired">Mandatory </label>
                </div>}
            </div>

        
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Material</label>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="material"
                            value="Y"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, material_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.material == "Y"}
                        /> Yes
                    </label>
                </div>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="material"
                            value="N"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, material_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.material == "N"} /> No
                    </label>
                </div>
                {this.state.addConfigurationForm.material == 'Y' && <div className="col-md-2">
                    <input
                        name="material_required"
                        type="checkbox"
                        value={this.state.addConfigurationForm.material_required}
                        onChange={(e) => {
                            if (e.target.value && e.target.value == 'N') {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, material_required: 'Y' },
                                })
                            } else {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, material_required: 'N' },
                                })
                            }

                        }}
                        checked={this.state.addConfigurationForm.material_required == 'Y'}
                        className="form-check-input"
                        id="materialRequired"

                    />
                    <label className="form-check-label mx-2 text-sm" htmlFor="materialRequired">Mandatory </label>
                </div>}
            </div>
           
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Attachment</label>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="attachment"
                            value="Y"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, attachment_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.attachment == "Y"}
                        /> Yes
                    </label>
                </div>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="attachment"
                            value="N"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, attachment_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.attachment == "N"} /> No
                    </label>
                </div>
                {this.state.addConfigurationForm.attachment == 'Y' && <div className="col-md-2">
                    <input
                        name="attachment_required"
                        type="checkbox"
                        value={this.state.addConfigurationForm.attachment_required}
                        onChange={(e) => {
                            if (e.target.value && e.target.value == 'N') {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, attachment_required: 'Y' },
                                })
                            } else {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, attachment_required: 'N' },
                                })
                            }

                        }}
                        checked={this.state.addConfigurationForm.attachment_required == 'Y'}
                        className="form-check-input"
                        id="attachmentRequired"

                    />
                    <label className="form-check-label mx-2 text-sm" htmlFor="attachmentRequired">Mandatory </label>
                </div>}
            </div>
           
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">WorkStation</label>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="workstation"
                            value="Y"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, workstation_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.workstation == "Y"}
                        /> Yes
                    </label>
                </div>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="workstation"
                            value="N"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, workstation_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.workstation == "N"} /> No
                    </label>
                </div>
                {this.state.addConfigurationForm.workstation == 'Y' && <div className="col-md-2">
                    <input
                        name="workstation_required"
                        type="checkbox"
                        value={this.state.addConfigurationForm.workstation_required}
                        onChange={(e) => {
                            if (e.target.value && e.target.value == 'N') {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, workstation_required: 'Y' },
                                })
                            } else {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, workstation_required: 'N' },
                                })
                            }

                        }}
                        checked={this.state.addConfigurationForm.workstation_required == 'Y'}
                        className="form-check-input"
                        id="workstationRequired"

                    />
                    <label className="form-check-label mx-2 text-sm" htmlFor="workstationRequired">Mandatory </label>
                </div>}
            </div>
           
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Employee</label>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="employee"
                            value="Y"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, employee_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.employee == "Y"}
                        /> Yes
                    </label>
                </div>
                <div className="col-md-1">
                    <label>
                        <input
                            type="radio"
                            name="employee"
                            value="N"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, employee_required: 'N' }
                                })
                            })}
                            checked={this.state.addConfigurationForm.employee == "N"} /> No
                    </label>
                </div>
                {this.state.addConfigurationForm.employee == 'Y' && <div className="col-md-2">
                    <input
                        name="employee_required"
                        type="checkbox"
                        value={this.state.addConfigurationForm.employee_required}
                        onChange={(e) => {
                            if (e.target.value && e.target.value == 'N') {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, employee_required: 'Y' },
                                })
                            } else {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, employee_required: 'N' },
                                })
                            }

                        }}
                        checked={this.state.addConfigurationForm.employee_required == 'Y'}
                        className="form-check-input"
                        id="employeeRequired"

                    />
                    <label className="form-check-label mx-2 text-sm" htmlFor="employeeRequired">Mandatory </label>
                </div>}
                
            </div>
           
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Additional Attributes</label>
                <div className="col-sm-4 ">
                    <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addConfigurationForm', 'additional_attribute_keys', () => {
                            if (this.state.addConfigurationForm.additional_attribute_keys && this.state.addConfigurationForm.additional_attribute_keys.length == 0) {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, enable_productivity_formula: 'N' }
                                })
                            }
                        })}
                        options={this.state.allAttributesList}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        menuPlacement={"top"}
                        value={this.state.allAttributesList.filter(s => this.state.addConfigurationForm.additional_attribute_keys.includes(s.value))}
                        placeholder={"Select Additional Attributes"}
                    />
                </div>
            </div>
            {additional_attributes_types && additional_attributes_types.length > 0 &&
                <div className="row align-items-center my-3">
                    <label className="col-sm-3 col-form-label">Enable Productivity Formula</label>
                    <div className="col-md-1">
                        <label>
                            <input
                                type="radio"
                                name="enable_productivity_formula"
                                value="Y"
                                onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                                checked={this.state.addConfigurationForm.enable_productivity_formula == "Y"}
                            /> Yes
                        </label>
                    </div>
                    <div className="col-md-2">
                        <label>
                            <input
                                type="radio"
                                name="enable_productivity_formula"
                                value="N"
                                onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                                checked={this.state.addConfigurationForm.enable_productivity_formula == "N"} /> No
                        </label>
                    </div>
                </div>}
            {this.state.addConfigurationForm.enable_productivity_formula == 'Y' &&
                <div className="row align-items-center my-3">
                    <label className="col-sm-3 col-form-label">Productivity Formula</label>
                    <div className="col-sm-4">
                        <input
                            name="productivity_formula"
                            type="text"
                            value={this.state.addConfigurationForm.productivity_formula}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                            placeholder="Please enter Productivity Formula"
                        />
                    </div>
                    <div className="col-sm-2">
                        <label className="form-text">Available Keys <br />
                            <ol>
                                {additional_attributes_types && additional_attributes_types.length > 0 && additional_attributes_types.map(attribute => {
                                    return (<li className='text-xs'>{attribute.value} : {attribute.label}</li>)
                                })
                                }
                            </ol>
                        </label>

                    </div>
                    <div className="col-sm-3">
                        <label className="form-text"># Examples : <br />
                            <ol>
                                <li className='text-xs'>Total Productivity = (attr 1 * attr 2)</li>
                                <li className='text-xs'>Total Productivity = (attr 1 * attr 2) * attr (3) + 20</li>
                            </ol>

                        </label>

                    </div>
                </div>}
            {this.state.enable_account_code == 'Y' && <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Accounting Code</label>
                <div className="col-sm-4">
                    <TapSelect
                        options={this.state.allAccountingCode}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addConfigurationForm', 'account_code_id');

                        }}
                        isSearchable={true}
                        value={this.state.allAccountingCode.find(s => this.state.addConfigurationForm.account_code_id == s.value)}
                        isClearable={true}
                        placeholder="Select Accounting Code"
                        autoCompelete="off"
                        menuPlacement="top"
                    />
                </div>
            </div>}
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Starting Date</label>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addConfigurationForm.starting_date
                                ? moment(this.state.addConfigurationForm.starting_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="starting_date"
                        onChange={(value, event) => this.formDateHandler('starting_date', value, 'addConfigurationForm')}
                        // maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Starting Date`}
                        required={this.state.addConfigurationForm.closing_date || this.state.addConfigurationForm.target_type == "onetime" ? true : false}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Closing Date</label>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addConfigurationForm.closing_date
                                ? moment(this.state.addConfigurationForm.closing_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="closing_date"
                        onChange={(value, event) => this.formDateHandler('closing_date', value, 'addConfigurationForm')}
                        minDate={moment(this.state.addConfigurationForm.starting_date, 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Closing Date`}
                        required={this.state.addConfigurationForm.starting_date || this.state.addConfigurationForm.target_type == "onetime" ? true : false}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Target Type</label>
                <div className="col-sm-4">
                    <TapSelect
                        options={this.state.allTargetTypes}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addConfigurationForm', 'target_type', () => {
                                this.setState({
                                    addConfigurationForm: { ...this.state.addConfigurationForm, total_productivity_target: '', target_per_day: '' }
                                })
                            });

                        }}
                        isSearchable={true}
                        value={this.state.allTargetTypes.find(s => this.state.addConfigurationForm.target_type == s.value)}
                        isClearable={true}
                        placeholder="Select Target Type"
                        autoCompelete="off"
                        menuPlacement="top"
                    />
                </div>
            </div>
            {this.state.addConfigurationForm.target_type && <Ax>
                <div className="row align-items-center my-3">
                    <label className="col-sm-3 col-form-label">Total Productivity Target</label>
                    <div className="col-sm-4">
                        <input
                            name="total_productivity_target"
                            type="number"
                            value={this.state.addConfigurationForm.total_productivity_target}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm", () => {
                                this.calculateTragetPerDay()
                            })}
                            required={this.state.addConfigurationForm.target_type ? true : false}
                            placeholder="Please enter Total Productivity Target"
                            min={0}
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <label className="col-sm-3 col-form-label">Target Per Day</label>
                    <div className="col-sm-4">
                        <input
                            name="target_per_day"
                            type="number"
                            value={this.state.addConfigurationForm.target_per_day}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                            placeholder="Please enter Target Per Day"
                            disabled={true}
                            min={0}
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <label className="col-sm-3 col-form-label">Cost Per Unit Target</label>
                    <div className="col-sm-4">
                        <input
                            name="cost_per_unit_target"
                            type="number"
                            value={this.state.addConfigurationForm.cost_per_unit_target}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "addConfigurationForm")}
                            placeholder="Please enter Cost Per Unit Target"
                        />
                    </div>
                </div>

            </Ax>}
            <div className="row align-items-center my-3">
                <label className="col-sm-3 col-form-label">Notes</label>
                <div className="col-sm-4">
                    <textarea
                        name="notes"
                        value={this.state.addConfigurationForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addConfigurationForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                </div>

            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                <button type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }


    render                    =        ()        =>     {
        return (<ApplicationLayout>
             <Helmet><title>Site Productivity Configuration - Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.configurationData ? "Edit" : "Add"} Site Productivity Configuration</h3>
                    <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Fuel Station" />
                        </button>
                       
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.form_data_loading ? <Loader /> :
                            this.saveConfigurationFormJsx()
                        }
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token        :       state.auth.access_token,
        iam_user_sites      :       state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(ConfigurationAdd);