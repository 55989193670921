import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { toast } from 'react-toastify';
import TapIcon from '../../../services/TapIcon';
import NumberFormatter from '../../../services/NumberFormater';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import {Link} from "react-router-dom";
import TapSelect from '../../../components/ui/TapSelect';
import swal from 'sweetalert';
import DateService from '../../../services/DateService';
import { Modal } from 'bootstrap';
import SubAssemblyDocumentModal from './SubAssemblyDocumentModal';
import moment from 'moment';
import LinkingModal from './LinkingModal';
import { DisplayListPagination } from '../../../components/TapUi';

class SubAssemblyDetail extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state              =   {
            loadingDetail           :   false,
            viewSubAssemblyDetail   :   null,
            detailShow              :   false,
            siteTransitionShow      :   false,
            site_listing_loading    :   false,
            siteTransitionList      :   [],
            siteTransitionLoaded    :   false,
            subAssemblyId           :   '',
            assetLinkingLoaded      :   false,
            asset_linking_loading   :   false,
            asset_linking_data      :   [],
            currentTab              :   "detail",
            linkedAssetDetail        :   null,
            loadingAssetDetail       :   false,
            dashboard_loading       :   false,
            widgetData              :   null,
            assetLinkingMeta        :   null
        }

        this.subAssemblyUrl         =   TapApiUrls.IAM + '/' + 'sub_assembly';
        this.documentModalRef       =   React.createRef();
        this.linkAssetModalRef      =   React.createRef();

    }

    componentDidMount() { 
        //  this.subAssemblyDetail();
        //  this.getDashboardDetail();
        //  this.getAssetLinkinglisting();
        //  this.getSiteLinking();
        this.linkedAssetDetailModal         =    new Modal(document.getElementById('linkedAssetDetailModal'), {keyboard: false, backdrop :false});
    }

    getDashboardDetail  =   (id = null) => {
        
        const subAssemblyId     =   id ? id : this.props.subAssemblyId; 

        this.setState({
            dashboard_loading       :   true,
            subAssemblyId,
        });

        HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/widget/' + subAssemblyId, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                widgetData      :   respData
            })
            this.handleLinks(this.state.currentTab)
        }).then(() => this.setState({dashboard_loading: false}));
    }

    subAssemblyDetail   =   (id = null) => {
        
        const subAssemblyId     =   id ? id : this.props.subAssemblyId; 

        this.setState({
            loadingDetail : true,
            detailShow    : true,
            siteTransitionLoaded    :   false,
            assetLinkingLoaded      :   false,
            subAssemblyId,
        });

        HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/detail/' + subAssemblyId, this.props.access_token, {need_documents:"Y"}, {}, (resp) => {
            let respData = resp.data.data;
            this.setState({
                viewSubAssemblyDetail   :   respData,
                siteTransitionLoaded    :   false

            })
            this.handleLinks(this.state.currentTab)
            if(this.props.viewSubAssemblyDetail){
                this.props.viewSubAssemblyDetail(respData);
            }
        }).then(() => this.setState({loadingDetail: false}));
    }   

    handleDocumentModal =   () => {
        this.documentModalRef.current.showModalInit();
    }

    deleteDocument              =   (doc_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.subAssemblyUrl + '/delete_document/' + doc_id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.subAssemblyDetail(this.state.subAssemblyId);
                }).then(() => { });
            }
        });
    }

    viewLinkedAssetDetailInit   =   (id)      =>  {
        this.linkedAssetDetailModal.show()
        this.setState({loadingAssetDetail : true})
        HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/linked_asset_history/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data.data;
            this.setState({
                linkedAssetDetail   :   respData,
            });
        }).then(() => this.setState({loadingAssetDetail: false}));
    }

    delinkAssetModalInit        =   (id)      =>      {
        this.linkAssetModalRef.current.linkAssetModalInit(this.state.viewSubAssemblyDetail,'delink',id)
    }

    detailJsx = () => {
        const viewDetail = this.state.viewSubAssemblyDetail;
        return (
            <Ax>
                <div className="tab_content_wrapper"><span className="content_heading">Sub Assembly Detail</span></div>
                <table className="table table-hover table-bordered  table-responsive bg-white mt-2 ">
                    <tbody>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Name</td>
                            <td className="fs14 fw500" >{viewDetail ? viewDetail.name : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Code</td>
                            <td className="fs14 fw500" >{viewDetail ? viewDetail.card_number : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Manufacturer Name</td>
                            <td className="fs14 fw500" >{viewDetail && viewDetail.manufacturer ? viewDetail.manufacturer.name  : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Tags</td>
                            <td className="fs14 fw500" >
                            {
                                viewDetail 
                                ? viewDetail.sub_assembly_tags && viewDetail.sub_assembly_tags.length > 0 && viewDetail.sub_assembly_tags.map((tag, i) => {
                                    return tag; 
                                }).join(', ') 
                                : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Serial Number</td>
                            <td className="fs14 fw500">{viewDetail ? viewDetail.serial_number : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Category</td>
                            <td className="fs14 fw500" >{viewDetail && viewDetail.category ? viewDetail.category.name : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Purchase Date</td>
                            <td className="fs14 fw500" >{viewDetail ? (viewDetail.purchase_date &&  DateService.dateTimeFormat(viewDetail.purchase_date, 'DD-MMM-YYYY')) : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Purchase Price</td>
                            <td className="fs14 fw500" >{viewDetail ? viewDetail.purchase_price : ''}</td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Current Status</td>
                            <td className="fs14 fw500" >
                                <Status color={viewDetail && viewDetail.status && viewDetail.status.color_code }>
                                {
                                    viewDetail 
                                    ?
                                    viewDetail.status && viewDetail.status.name
                                    : ''
                                }
                                </Status>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Additional Attributes</span>
                </div>
                <table className="table table-hover table-bordered  table-responsive bg-white mt-2 ">
                    <tbody>
                        {
                          viewDetail &&  viewDetail.additional_attributes && viewDetail.additional_attributes.length > 0 ?
                            viewDetail.additional_attributes.map((attribute, index) => {
                                return (<tr key={index}>
                                    <td className="fs14 fw-normal" style={{ "width": "25%" }}>{attribute.name}</td>
                                    <th className="fs14 fw500">{attribute.value_display ? attribute.value_display : "-"}</th>
                                </tr>)
                            })
                            : null
                        }
                    </tbody>
                </table>
                <div className="tab_content_wrapper mt-2"><span className="content_heading">Current Site</span></div>
                <table className="table table-hover table-bordered  table-responsive bg-white mt-2 ">
                    <tbody>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Site</td>
                            <td className="fs14 fw500" >
                                {viewDetail && viewDetail.site && viewDetail.site.site_name + " (" + viewDetail.site.site_code + ")" }
                            </td>
                        </tr>
                        <tr>
                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Linked From</td>
                            <td className="fs14 fw500" >
                                {
                                    viewDetail && viewDetail.site_since_date && DateService.dateTimeFormat(viewDetail.site_since_date, 'DD-MMM-YYYY')
                                }   
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Sub Assembly Document</span>
                    {
                        <span className="text-end dropdown">
                        {this.props.permissions.includes('sub_assembly_doc_add') && <button type="button" onClick={() => this.handleDocumentModal()} className="btn btn-light">
                        <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPlus} />
                        </button>}
                        </span>
                    }
                </div>
                <table className="table table-bordered bg-white mt-2">
                    <thead className="table-secondary">
                        <tr>
                            <td style={{ "width": "5%" }}>S.No</td>
                            <td style={{ "width": "35%" }}>Name</td>
                            <td style={{ "width": "30%" }}>Number</td>
                            <td style={{ "width": "10%" }}>View</td>
                            <td style={{ "width": "10%" }}>Download</td>
                            <td style={{ "width": "10%" }}>Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            viewDetail && viewDetail.documents && viewDetail.documents.length > 0 ? viewDetail.documents.map((d,i) => {
                                return <tr className='text-center'>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.number}</td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <Link role="button"
                                            target='_blank'
                                            to={{pathname : d.document}}
                                            className={[d.view_permission  || this.props.permissions.includes('sub_assembly_doc_view') ? '' : 'disabled-link'].join(' ')}
                                            title={d.view_permission || this.props.permissions.includes('sub_assembly_doc_view') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faEye}  /></Link>
                                    </td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <Link role="button"
                                            target='_blank'
                                            to={{pathname : d.download_link}}
                                            className={[d.downlaod_permission || this.props.permissions.includes('sub_assembly_doc_view') ? '' : 'disabled-link'].join(' ')}
                                            title={d.downlaod_permission || this.props.permissions.includes('sub_assembly_doc_view') ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload}  /></Link>
                                    </td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('sub_assembly_doc_delete')) {
                                                   return this.deleteDocument(d.doc_id)
                                               }
                                            }}
                                            className={[this.props.permissions.includes('sub_assembly_doc_delete') ? '' : 'disabled-link'].join(' ')}
                                            title={!this.props.permissions.includes('sub_assembly_doc_delete') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt}  /></a>
                                    </td>
                                </tr>
                            })
                            : <tr><td className='text-center' colSpan="6">No Document Found</td></tr>
                        }
                    </tbody>
                </table>
            </Ax>
        )
    }

    handleLinks = (type) => {
        this.setState({currentTab : type})
        if(!this.state.siteTransitionLoaded && type == 'site_transition') {
           this.getSiteLinking()
        }
        if(!this.state.assetLinkingLoaded && type == 'asset') {
            this.getAssetLinkinglisting()
        }
        // if(type == "dashboard") {
        //     this.getDashboardDetail()
        // }
        
    }

    getSiteLinking      =       (id=null)   =>      {
        const subAssemblyId     =   id ? id : this.props.subAssemblyId; 
        this.setState({site_listing_loading : true});
        HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/site_transaction/' + subAssemblyId, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                siteTransitionList      :   respData,
                siteTransitionLoaded    :   true,
                // currentTab              :   "site_transition"
            })
        }).then(() => this.setState({site_listing_loading: false}));

    }   

    getAssetLinkinglisting      =       (id=null,page=1)      =>      {
        const subAssemblyId     =   id ? id : this.props.subAssemblyId; 
        this.setState({asset_linking_loading : true});
            HttpAPICall.withAthorization('GET',  this.subAssemblyUrl + '/linked_asset', this.props.access_token, {sub_assembly_id : subAssemblyId,page:page}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    asset_linking_data    :   respData.data,
                    assetLinkingLoaded    :   true,
                    assetLinkingMeta        :   respData.meta
                   // currentTab              :   "asset"
                })
            }).then(() => this.setState({asset_linking_loading: false}));
    }

    deleteAssetLinking                      =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.subAssemblyUrl + '/asset_linking_history/' + id , this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.getAssetLinkinglisting(this.state?.viewSubAssemblyDetail?.enc_id)
                }).then(() => { });
            }
        });
    }

    siteTransitionJsx = () => {
        return (
            <div className="tab_content_header">
            <table className="table table-bordered bg-white align-items-center  table-sm table-hover my-3" id="settingsTable">
                <thead className="table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                        <th scope="col" style={{ width: "25%" }}>From</th>
                        <th scope="col" style={{ width: "25%" }}>To</th>
                        <th scope="col" style={{ width: "25%" }}>Site </th>
                        <th scope="col" style={{ width: "15%" }} >No of Days</th>
                    </tr>
                </thead>
                <tbody>
                {
                    this.state.site_listing_loading
                    ?
                    <tr><td className="text-center" colSpan={6}><Loader/></td></tr>
                    :
                    this.state.siteTransitionList && this.state.siteTransitionList.length > 0 ? this.state.siteTransitionList.map((data, index) => {
                        let date1 = data.from ? moment(data.from) : moment(new Date());
                        let date2 = data.till ? moment(data.till) : moment(new Date());
                        let days = date2.diff(date1, 'days')
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                {data.from ? DateService.dateTimeFormat(data.from, 'DD-MMM-YYYY HH:mm A') : '-'}
                                </td>
                                <td>
                                {data.till ? DateService.dateTimeFormat(data.till, 'DD-MMM-YYYY HH:mm A') : 'Till Date'}
                                </td>
                                <td>{data.site_name ? data.site_name : '-'}</td>
                                <td>{days ? days : 0} Days</td>

                            </tr>
                        )
                       
                    }) :  (<tr><td className='text-center' colSpan={6}>No Record</td></tr>)
                }
                    
                </tbody>
            </table>
            </div>
        );
    }

    calculateTotalDays = (fromDate, toDate) => {
        if (moment(fromDate, 'DD-MMM-YYYY').isValid() && moment(toDate, 'DD-MMM-YYYY').isValid()) {
            const startDate = moment(fromDate, 'DD-MMM-YYYY');
            const endDate = moment(toDate, 'DD-MMM-YYYY');
            const diffDays = endDate.diff(startDate, 'days');
            return diffDays;
        }
        return '-';
    }

    assetLinkingJsx     =   ()      =>  {
        return(
            <div className="tab_content_header">
                
                <table className="table table-bordered bg-white align-items-center table-sm table-hover my-3" id="settingsTable">
                    <thead className="table-secondary">
                        <tr className="text-center">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "20%" }}>Asset</th>
                            <th style={{ width: "15%" }}>Meter</th>
                            <th style={{ width: "20%" }}>From</th>
                            <th style={{ width: "20%" }}>To</th>
                            <th style={{ width: "10%" }}>Total Days</th>
                            <th style={{ width: "10%" }}>Total Running</th>
                            <th style={{ width: "5%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.asset_linking_loading ? (
                            <tr>
                                <td className="text-center" colSpan={6}><Loader /></td>
                            </tr>
                        ) : (
                            this.state.asset_linking_data && this.state.asset_linking_data.length > 0 ? (
                                this.state.asset_linking_data.map((data, index) => {
                                    return data.linking_cl && data.linking_cl.length > 0 ? (
                                        data.linking_cl.map((cl, key) => {
                                            const latestReading = data?.latest_cl?.find(latest => latest.key === cl.key);
                                            const delinkReading = data?.delinking_cl?.find(latest => latest.key === cl.key);
                                            const reading = latestReading && latestReading.reading ? latestReading.reading : 0;
                                            const From = cl.label == "Date" ? cl.reading : "";

                                            const To   = delinkReading && delinkReading.label == "Date" ? delinkReading.reading : latestReading && latestReading.label == "Date" ? latestReading.reading : "";
                                            
                                            let rd = data.reading_display.find(rd => rd.meter === cl.label);

                                            const totalRunning = rd ? rd.running : null ;
                                            
                                            return (
                                                <tr key={`${index}-${key}`}>
                                                    {key === 0 ? (
                                                        <>
                                                             <td className="align-middle text-center" rowSpan={data.linking_cl.length}>{this.state.assetLinkingMeta ? this.state.assetLinkingMeta.from + index : index + 1}</td>
                                                            <td className="align-middle" rowSpan={data.linking_cl.length}>{data.asset ? <span>{data.asset.name} ({data.asset.asset_code})</span> : null}</td>
                                                        </>
                                                    ) : null}
                                                    <td className="text-center">{cl.label}</td>
                                                    <td className="text-center" >{cl.reading} {cl.unit}</td>
                                                    <td className="text-center">
                                                        {data && !data.end_date ?
                                                            cl.key === latestReading.key ? latestReading.label == "Date" ? "Till Now" : latestReading.reading : cl.reading
                                                            :
                                                            data.delinking_cl.map(delinking => delinking.key === cl.key && delinking.reading)
                                                        } {cl.unit}
                                                    </td>
                                                    <td >{From && To ? <span>{this.calculateTotalDays(From,To)} Days</span> : ""}</td>
                                                    <td className="text-center">{ rd ? <span>{totalRunning} {cl.unit}</span> : "-"}</td>
                                                    {key === 0 ? (
                                                        <td rowSpan={data.linking_cl.length} className="text-center">
                                                            <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                            <ul className="dropdown-menu">
                                                                <li><Link className="dropdown-item" onClick={() => this.viewLinkedAssetDetailInit(data.id)}>View</Link></li>
                                                                {data && !data.end_date && this.state.viewSubAssemblyDetail?.status?.id !== 2 && this.props.permissions.includes('iam-sub-assembly-asset-manage') &&   <li><Link className="dropdown-item" onClick={() => this.delinkAssetModalInit(data.asset_id)}>De-link</Link></li>}
                                                                {this.state.assetLinkingMeta && this.state.assetLinkingMeta.current_page !== 2 && this.props.permissions.includes('iam-sub-assembly-asset-manage') &&  index === 0 && <li><Link className="dropdown-item" onClick={() => this.deleteAssetLinking(data.id)}>Delete</Link></li>}
                                                            </ul>
                                                        </td>
                                                    ) : null}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr key={index}>
                                            <td className="text-center" colSpan={6}>No Linking</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td className="text-center" colSpan={6}>No Record</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>

            <DisplayListPagination
                meta={this.state.assetLinkingMeta}
                onPageChange={(e) => this.getAssetLinkinglisting(this.props.subAssemblyId,e.selected + 1)}
            />
            </div>
        )
    }

    assetLinkingDetailJsx = ()      =>      {
        let detail = this.state.linkedAssetDetail;
        let sub_assembly_detail = this.state.viewSubAssemblyDetail;
        return (<div className="modal fade" id="linkedAssetDetailModal" tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">Linked Asset Detail</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    </div>
                    <div className='modal-body'>
                        {this.state.loadingAssetDetail ? <Loader />
                            : <>
                                <table className="table table-hover table-bordered  table-responsive bg-white">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "33%" }}>Sub Assembly</td>
                                            <th>{sub_assembly_detail && sub_assembly_detail.name} </th>

                                        </tr>
                                        <tr>
                                            <td style={{ "width": "25%" }}>Code</td>
                                            <th >{sub_assembly_detail && sub_assembly_detail.card_number ? sub_assembly_detail.card_number : ''}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "25%" }}>Asset</td>
                                            <th>{detail && detail.asset && detail.asset.name + " (" + detail.asset.asset_code + ")"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "25%" }}>Linked From Date</td>
                                            <th>{detail && detail.start_date && DateService.dateTimeFormat(detail.start_date, 'DD-MMM-YYYY HH:mm A')}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ "width": "25%" }}>Linked Till Date</td>
                                            <th>{detail && detail.end_date ? DateService.dateTimeFormat(detail.end_date, 'DD-MMM-YYYY HH:mm A') : "Till Now"}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-2"><span className="content_heading">Linking Detail</span></div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                                    <thead className='text-center'>
                                        <th style={{ width: "5%" }}>S.No</th>
                                        <th style={{ width: "25%" }}>Meter</th>
                                        <th style={{ width: "25%" }}>CL at Linking</th>
                                        <th style={{ width: "25%" }}>{detail && detail.end_date ? "CL at Delinking" : "Latest Counterlog"}</th>
                                        <th style={{ width: "10%" }}>Total Running</th>
                                    </thead>
                                    <tbody>
                                        {detail && detail.linking_cl && detail.linking_cl.length > 0 ? (
                                            detail.linking_cl.map((cl, index) => {
                                                const latestReading = detail.latest_cl.find(latest => latest.key === cl.key);
                                                const delinkReading =   detail.delinking_cl.find(latest => latest.key === cl.key)
                                                let rd = detail.reading_display.find(rd => rd.meter === cl.label);
                                                const totalRunning = rd ? rd.running : null ;
                                                return (
                                                    <tr key={index}>
                                                        <td className='text-center'>{index + 1}</td>
                                                        <td>{cl.label}</td>
                                                        <td>{cl.reading} {cl.unit}</td>
                                                        <td>
                                                            {detail && !detail.end_date ?
                                                                cl.key === latestReading.key ? latestReading.reading : cl.reading
                                                                :
                                                                detail.delinking_cl.map(delinking => delinking.key === cl.key && delinking.reading)
                                                            } {cl.unit}
                                                        </td>
                                                        <td>{rd ? <span>{totalRunning} {cl.unit}</span> : "-"}</td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr><td className='text-center' colSpan={5}>No Counterlog</td></tr>
                                        )}

                                    </tbody>
                                </table>
                                {detail && detail.reading_display && detail.reading_display.length > 0 && detail.reading_display.map((rd,k) => {
                                    return(<div className='form-text'><sup>*</sup>{rd.label} meter is tracked on the basis of {rd.meter}</div>)
                                })}
                                <div className="tab_content_wrapper mt-2"><span className="content_heading">Linked Details</span></div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "33%" }}>Linking Date</td>
                                            <th>{detail && detail.linked_on ? detail.linked_on : ""}</th>

                                        </tr>
                                        <tr>
                                            <td style={{ width: "33%" }}>Linking Done By</td>
                                            <th>{detail && detail.linking_by ? detail.linking_by.full_name : ""}</th>

                                        </tr>
                                        {detail && detail.delinking_by && <>
                                            <tr>
                                                <td style={{ width: "33%" }}>De-Linking Date</td>
                                                <th>{detail && detail.delinked_on ? detail.delinked_on : "-"}</th>
                                            </tr>

                                            <tr>
                                                <td style={{ width: "33%" }}>De-Linking Done By</td>
                                                <th>{detail && detail.delinking_by ? detail.delinking_by.full_name : "-"}</th>
                                            </tr>
                                        </>}

                                    </tbody>
                                </table>
                                {/* <div className="tab_content_wrapper mt-2"><span className="content_heading">De - Linking Details</span></div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "33%" }}>De-Linking Date</td>
                                            <th>{detail && detail.delinked_on ? detail.delinked_on : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "33%" }}>De-Linking Counterlog</td>
                                            <th>{detail && detail.delinking_cl && detail.delinking_cl.length > 0 ?
                                                detail.delinking_cl.map((cl, index) => { return (<div>{cl.label} : {cl.reading} {cl.unit}</div>) }) : "-"} </th>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "33%" }}>De-Linking Done By</td>
                                            <th>{detail && detail.delinked_by ? detail.delinked_by.full_name : "-"}</th>
                                        </tr>

                                    </tbody>
                                    </table> */}


                            </>
                        }

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>)
    }

    dashboardJsx        =       ()      =>      {
        let {widgetData}        =   this.state;
        return(<Ax>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">Linking Detail</span></div>
            <table className="table table-hover table-bordered  table-responsive bg-white my-2">
                <tbody>
                    <tr>
                        <td style={{ width: "33%" }}>Asset Linked</td>
                        <th>{widgetData && JSON.stringify(widgetData.asset) !== JSON.stringify({}) ? "Yes" : "No"}</th>
                    </tr>
                    {widgetData && JSON.stringify(widgetData.asset) !== JSON.stringify({}) && <>
                        <tr>
                            <td style={{ width: "33%" }}>Asset</td>
                            <th>{widgetData.asset.name} </th>
                        </tr>
                        <tr>
                            <td style={{ width: "33%" }}>Linking Date</td>
                            <th>{widgetData.linking_date} </th>
                        </tr>
                        <tr>
                            <td style={{ width: "33%" }}>CounterLog</td>
                            <th>{widgetData.linking_cl && widgetData.linking_cl.length > 0 && widgetData.linking_cl.map((lk,k) => {return(<div>{lk.label} : {lk.reading} {lk.unit}</div>)})} </th>
                        </tr>
                    </>}

                    
                </tbody>
            </table>
            <div className="tab_content_wrapper mt-2"><span className="content_heading">Running Detail</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white mt-2">
                        <thead className='text-center table-secondary'>
                            <th style={{ width: "5%" }}>S.No</th>
                            <th style={{ width: "25%" }}>Meter</th>
                            <th style={{ width: "20%" }}>Current Tracking Meter</th>
                            <th style={{ width: "25%" }}>Current Running</th>
                            <th style={{ width: "25%" }}>LifeTime Running</th>
                        </thead>
                        <tbody>
                        {widgetData && widgetData.total_running && widgetData.total_running.length > 0 && widgetData.total_running.map((data, index) => {
                                 let currentRunningValue = widgetData.current_running.find(item => item.unit === data.unit);
                                 let running = currentRunningValue ? currentRunningValue.running : null;
                                let label = data.unit == "Hr" ? "Hour Meter" : data.unit == "Km" ? "Odo Meter" : "Oeration Cycle"
                                return (
                                    <tr key={index} className='text-center'>
                                        <td>{index + 1}</td>
                                        <td>{label}</td>
                                        <td >{currentRunningValue  ? <span>{currentRunningValue.meter}</span> : "-"}</td>
                                        <td >{currentRunningValue  ? <span>{currentRunningValue.running}  {data.unit}</span> : "-"}</td>
                                        <td>{data.runing} {data.unit}</td>
                                    </tr>
                                )
                            })}

                          
                        </tbody>
                    </table>
                    {this.detailJsx()}

        </Ax>)
    }

    render() {
        return (
            <Ax>
                <section className="item_view_container bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard"
                                type="button" role="tab" aria-controls="dashboard" aria-selected="true">Detail
                            </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                                type="button" role="tab" aria-controls="details" aria-selected="true">Details
                            </button>

                        </li> */}
                        {this.props.permissions.includes('iam-sub-assembly-site-list') && <li className="nav-item" role="presentation">
                            <button className="nav-link" id="site-tab" data-bs-toggle="tab" data-bs-target="#site"
                                type="button" role="tab" aria-controls="site" aria-selected="true">Site Transition
                            </button>
                        </li>}
                         <li className="nav-item" role="presentation">
                            <button className="nav-link" id="asset-tab" data-bs-toggle="tab" data-bs-target="#asset"
                                type="button" role="tab" aria-controls="asset" aria-selected="true">Asset Linking
                            </button>
                        </li>
                        
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {/* <div className="tab-pane " id="details" role="tabpanel" aria-labelledby="details-tab">
                            {this.state.loadingDetail ?
                                <Loader />
                                : this.state.viewSubAssemblyDetail ? <div className="tab_content_header">{this.detailJsx()}</div> : null}
                        </div> */}


                        <div className="tab-pane" id="site" role="tabpanel" aria-labelledby="site-tab">
                            {this.state.site_listing_loading ?
                                <Loader />
                                : this.siteTransitionJsx()}
                        </div>
                        <div className="tab-pane" id="asset" role="tabpanel" aria-labelledby="asset-tab">
                            {this.state.asset_linking_loading ?
                                <Loader />
                                : this.assetLinkingJsx()}
                        </div>
                        <div className="tab-pane  show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                            <div className="tab_content_header">
                                {this.state.dashboard_loading ? <Loader/> : this.dashboardJsx()}
                                {/* <div className="card">
                                    <div className="card-body">
                                        <table className="table table-hover table-bordered table-borderless bg-white">
                                            <thead className="table-secondary">
                                                <tr>
                                                    <th style={{ width: "25%" }} className="text-center">Parameter</th>
                                                    <th style={{ width: "15%" }} className="text-center">Meter</th>
                                                    <th style={{ width: "10%" }} className="text-center">Unit</th>
                                                    <th style={{ width: "15%" }} className="text-center">Start</th>
                                                    <th style={{ width: "15%" }} className="text-center">End</th>
                                                    <th style={{ width: "25%" }} className="text-center">Value</th>

                                                </tr>
                                            </thead>
                                            <tbody >
                                                {this.state.dashboard_loading ? <tr>
                                                    <td className='text-center' colSpan={6}><Loader/></td>
                                                </tr> : <Ax>
                                                <tr>
                                                    <th colSpan={5}>Linking Detail</th>
                                                </tr>
                                                <tr>
                                                    <td>Asset Linked</td>
                                                    <td colSpan={5} className='text-end'>{widgetData && JSON.stringify(widgetData.asset) !== JSON.stringify({}) ? "Yes" : "No"}</td>
                                                </tr>
                                                {widgetData && JSON.stringify(widgetData.asset) !== JSON.stringify({}) && <Ax>
                                                    <tr>
                                                        <td>Asset</td>
                                                        <td colSpan={5} className='text-end'>{this.state.widgetData && this.state.widgetData.asset ? this.state.widgetData.asset.name : "N"}</td>
                                                    </tr>
                                                    <tr><td colSpan={5}>&nbsp;</td></tr>
                                                    {/* <tr>
                                                        <th colSpan={5}>Running Detail</th>
                                                    </tr> 
                                                    <tr>
                                                        <th colSpan={5}>Current Running</th>
                                                    </tr>
                                                    {widgetData && widgetData.current_running && widgetData.current_running.length > 0 && widgetData.current_running.map((wd) => {
                                                        return (<tr>
                                                            <td></td>
                                                            <td>{wd.label}</td>
                                                            <td>{wd.unit}</td>
                                                            <td className='text-end'>{wd.start}</td>
                                                            <td className='text-end'>{wd.end}</td>
                                                            <td className='text-end'>{wd.running}</td>
                                                        </tr>)
                                                    })}
                                                    <tr>
                                                        <th colSpan={5}>LifeTime Running</th>
                                                    </tr>
                                                    {widgetData && widgetData.current_running && widgetData.current_running.length > 0 && widgetData.current_running.map((total, index) => {
                                                        let currentRunningValue = widgetData.total_running.find(item => item.unit === total.unit);
                                                        let running = currentRunningValue ? currentRunningValue.runing : 0;
                                                        return (
                                                            <tr key={index}>
                                                                <td></td>
                                                                <td>{total.label}</td>
                                                                <td>{total.unit}</td>
                                                                <td className='text-end'></td>
                                                                <td className='text-end'></td>
                                                                <td className='text-end'>{running}</td>
                                                            </tr>
                                                        )
                                                    })}

                                                </Ax>}
                                                </Ax>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}

                            </div>

                        </div>

                    </div>

                </section> 
                {this.assetLinkingDetailJsx()}
                <LinkingModal ref={this.linkAssetModalRef} afterSubmitForm={(id) => {this.subAssemblyDetail(id); this.getDashboardDetail(id);this.props.viewSubAssemblyDetail(id);this.getAssetLinkinglisting(id)}}/>
                <SubAssemblyDocumentModal ref = {this.documentModalRef} subAssemblyDetail = {this.state.viewSubAssemblyDetail} getSubAssemblyViewDetails = {(id) => this.subAssemblyDetail(id)}/>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(SubAssemblyDetail);