import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import tapIcon from "../../../../services/TapIcon"
import AppBaseComponent from '../../../../components/AppBaseComponent';
import { INVENTORY_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import Ax from "../../../../components/hoc/Ax";
import swal from 'sweetalert';
import Status from '../../../../components/ui/Status';
import { DisplayListPagination } from '../../../../components/TapUi';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class ProjectType extends AppBaseComponent {

    constructor(props) {
        super(props);
          
            this.initAddProjectForm =   {
                name                        :   '',
                status                      :   '',
                id                          :   ''
            }

          this.state                    =   {
            list_loading                    :   false,
            listingData                     :   [],
            saveFormSubmitting              :   false,
            addProjectForm              :   {...this.initAddProjectForm},
            editFormSubmiting               :   false,
            listingMeta                     :   null,
        };

        this.projectTypeAddUrl      =   INVENTORY_API_BASE_URL_2 + '/customer_project_type/add';
        this.projectTypeListUrl     =   INVENTORY_API_BASE_URL_2 + '/customer_project_type/list';
        this.projectTypeUpdateUrl   =   INVENTORY_API_BASE_URL_2 + '/customer_project_type/update';
        this.projectTypeStatusUrl   =   INVENTORY_API_BASE_URL_2 + '/customer_project_type/change_status';
        this.projectTypeDeleteUrl   =   INVENTORY_API_BASE_URL_2 + '/customer_project_type/delete';

    }

    componentDidMount() {
       this.getProjectConfigurationListing(1);
       this.addProjectModal        =        new Modal(document.getElementById('addProjectModal'), {keyboard: false, backdrop :false});
    }

    getProjectConfigurationListing             =         (page = 1)    =>  {
        this.setState({list_loading : true})
        HttpAPICall.withAthorization('GET', this.projectTypeListUrl, this.props.access_token , {page : page}, {}, (resp) => {
            let respData = resp.data
            this.setState({
                listingData  : respData.data && respData.data.length > 0 ? resp.data.data.map(dt => {return({...dt,value:dt.enc_id,label:dt.name})}) : [],
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            })
        }).then(() => this.setState({list_loading: false}));
    }

    addProjectModalInit          =       (sg)      =>      {
        this.addProjectModal.show();
        if(sg){
            this.setState({
                addProjectForm : {...this.initAddProjectForm,
                    id       :  sg.id,
                    name    :    sg.name,
                    status  :   sg.status
                }
            })
        }else{
            this.setState({
                addProjectForm : {...this.initAddProjectForm}
            })
        }
       
    }

    changeStatus                =    (id,status)  =>     {
        swal({
            title: "Change Status",
            text: "Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT', this.projectTypeStatusUrl, this.props.access_token, {},{status : status == 1 ? 2 : 1,id:id} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getProjectConfigurationListing();
                    }).then(() => {

                    });
                }
            });
    }

    submitAddProjectForm        =    (e)          =>     {
        e.preventDefault();
    
        if(this.state.addProjectForm && this.state.addProjectForm.id){
            this.setState({ editFormSubmiting: true });
            HttpAPICall.withAthorization('PUT', this.projectTypeUpdateUrl, this.props.access_token, {}, { ...this.state.addProjectForm, id: this.state.addProjectForm.id}, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addProjectModal.hide();
                this.getProjectConfigurationListing();
            }).then(() => this.setState({ editFormSubmiting: false }));
        }else{
            this.setState({ editFormSubmiting: true });
            HttpAPICall.withAthorization('POST', this.projectTypeAddUrl, this.props.access_token, {}, { ...this.state.addProjectForm }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.addProjectModal.hide();
                this.getProjectConfigurationListing();
            }).then(() => this.setState({ editFormSubmiting: false }));
        }
    }

    addProjectModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="addProjectModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{this.state.addProjectForm && this.state.addProjectForm.id ? "Edit" : "Add"} Project Type</h5>
                        </div>
                        <form onSubmit={this.submitAddProjectForm}>
                            <div className="modal-body p-3">
                            
                                <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-2"><label className="form-label require">Name</label></div>
                                    <div className="col-md-10">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.addProjectForm.name}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "addProjectForm")}
                                            placeholder="Please enter  Project Name"
                                            required={true}
                                        />
                                    </div>

                                </div>
                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Ax>
                <section className="page_containt " style={{ top: "1px" }}>
                    <div className="pageTbl bg-white">
                        <div className="p-2">
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Project Type</span>
                            {
                                (this.props.permissions && this.props.permissions.includes('logistic_config')) &&
                                <div className="text-end "><button className="btn btn-primary" onClick={() => this.addProjectModalInit()}>Add</button></div>
                            }
                            </div>
                            <table className="table table-hover table-bordered my-4 table-sm ">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "5%" }}>S.No</th>
                                        <th scope="col" className="text-start">Name</th>
                                        <th scope="col" style={{ width: "14%" }}>Status</th>
                                        <th scope="col" style={{ width: "14%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.list_loading ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                        : (this.state.listingData && this.state.listingData.length > 0
                                            ? (this.state.listingData.map((sg, k) => {
                                                return (<tr key={k} className="text-center">
                                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + k :k}</td>
                                                    <td className="text-start">{sg.name ? sg.name : "-"}</td>
                                                    <td>{sg.status && <Status color={sg.status.status_color_code}>{sg.status.status_text}</Status>}</td>
                                                    <td> <span className="dropdown">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn" disabled={!(this.props.permissions && this.props.permissions.includes('logistic_config'))}>
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><Link className="dropdown-item" role="button" onClick={() => this.addProjectModalInit(sg)}>Edit</Link></li>
                                                            <li><Link className="dropdown-item" role="button"  onClick={() => this.changeStatus(sg.id,sg.status)}>Change Status</Link></li>
                                                        </ul>
                                                    </span></td>
                                                </tr>);
                                            }))
                                            : (<tr><td colSpan="5" className="text-center">No Records</td></tr>))}
                                </tbody>
                            </table>
                            <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.getProjectConfigurationListing(e.selected + 1)} />

                        </div>
                    </div>
                </section>
                {this.addProjectModalJsx()}
            </Ax>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        permissions             :       state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};


export default connect(mapStateToProps)(ProjectType);