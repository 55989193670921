import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import moment from 'moment';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import { Helmet } from 'react-helmet';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import AllocationDetail from './AllocationDetail';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';

class AlllocationList extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.initAllocationSearchForm         =       {
            search_transaction_id           :       '',
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
            search_warehouse_ids            :       [],
            search_asset_ids                :       [],
            search_workstation_ids          :       [],
            search_voucher_number           :       '',
            search_item_ids                 :       [],
            search_employee_ids                 :       [],
        }

        this.allocationSearchFormLabel     =       {
            search_transaction_id           :       'Transaction ID : ',
            date_range                      :       'Date Range : ',
            search_warehouse_ids            :       'Warehouses : ',
            search_asset_ids                :       'Assets : ',
            search_workstation_ids          :       'Workstations : ',
            search_voucher_number           :       'Voucher Number : ',
            search_item_ids                 :       'Items : ',
            search_employee_ids                 :   'Employees : ',
        }

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                           =     {
            allocationAddScreenView          :        false,
            allocationUpdateScreenView       :       false,
            minimizeTable                     :       false,
            allocationFormDataLoaded         :       false,
            allocationAddFormData            :       null,
            listing_loading                   :       false,
            listing_tbl_page                  :       1,
            allocation_listing               :       [],
            listingMeta                       :       null,
            totalListingCount                 :       0,
            view_loading                      :       false,
            allocationViewData               :       null,
            allocationTransactionId            :   '',
            isc_configuration                 :       {},
            pdfDownloading                    :       false,
            reportDownloading                 :      false,
            searchedAssets                    :        [],
            searchedItem                      :        [],
            allocationSearchForm             :       {...this.initAllocationSearchForm},
            submittedAllocationFilterForm    :       {...this.initAllocationSearchForm},
            addCommentForm                      :       {...this.updateCommentFormInit},
            allAllowedStatus                    :       [],
            formSearchedElems                 :        [],
            all_periods                        :        [],
            allocationSearchFormDataLoaded   :         false,
            openingUpdateScreen               :         false,
            searchedEmployees                   :   [],
            cancelledItemList                   :       [],
            cancelItemFormSubmitting             :       false,
            cancelledItemForm                 :       {...this.cancelItemFormInit},
            searchedWorkstation                :       null,
        };
        this.myRef                         =   React.createRef();
        this.listingUrl                     =   INVENTORY_API_BASE_URL_2 + '/allocations';
        this.detailUrl                      =   INVENTORY_API_BASE_URL_2 + '/allocation/';
        this.commentUrl                     =   INVENTORY_API_BASE_URL_2 + '/allocation/comment_add';
        this.releaseUrl                      =  INVENTORY_API_BASE_URL_2 + '/allocation/release';
        this.CONSUMPTION_URL                =   INVENTORY_API_BASE_URL_2 + '/consumption';
    }

    componentDidMount() {
    
        let ParamObject                 =   new URLSearchParams(this.props.location.search);
        this.initializeData(this.props)
    
         //****************After Add and update ***************************/
        

        this.allocationSearchModal                         =   new Modal(document.getElementById('allocationSearchModal'), {keyboard: false, backdrop :false});
        this.releasedItemModal                            =   new Modal(document.getElementById('releasedItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initializeData(nextProps)
         }     
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            this.setState({
                all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                all_periods             :        all_periods ,
                isc_configuration       :        pr.isc_configuration
            }, () => {
                this.loadListingTblData(1);
               
            });
        }
    }

     //************************GET allocation LISTING**************** */
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedAllocationFilterForm};
        HttpAPICall.withAthorization('GET',  this.listingUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                allocation_listing    :       respData.data,
                listingMeta            :       respData.meta,
                totalListingCount      :       respData.meta.total,
        });
       }).then(() => this.setState({listing_loading: false}));
    }

    
    //************************allocation SEARCH **********************/
    allocationSearchModalInit               =       ()             =>        {
        this.allocationSearchModal.show();
        let all_periods                 =   [...this.props.all_periods , {key : 'custom', display : 'Custom Date Range'}];
        if(!this.state.allocationSearchFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.CONSUMPTION_URL + '/add_form_data', this.props.access_token, null, null, (response) => {
                this.setState({
                    allocationSearchFormDataLoaded    :    true,
                });
            });
        }
    }

    submitAllocationSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.allocationSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.allocationSearchFormLabel[key];
            let value                       =   this.state.allocationSearchForm[key];
           
            if(value && value.length > 0) {
                searchParams[key]      =   value;
           if(label) {
                    let show_val            =   value;
                    if(key == 'search_workstation_ids') {
                        show_val            =  this.state.searchedWorkstation ? this.state.searchedWorkstation.label : '';
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.allocationSearchForm ? this.state.allocationSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_voucher_number') {
                        show_val            =   this.state.allocationSearchForm ? this.state.allocationSearchForm.search_voucher_number : '';
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }

                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'date_range') {
                        let  start_range = moment(this.state.allocationSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.allocationSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedAllocationFilterForm   :   {...this.state.allocationSearchForm},
            formSearchedElems               :   searchedElems
        }, () => {
            this.allocationSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    allocationSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedAssets                  :   [],
            searchedEmployees               :   [],
            searchedItem                    :   [],
            allocationSearchForm            :   {...this.initAllocationSearchForm},
            submittedAllocationFilterForm     :   {...this.initAllocationSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.props.history.push('/allocation_list')
            this.allocationSearchModal.hide();
        });
    }

    allocationClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedAssets                  :   [],
            searchedItem                    :   [],
            searchedEmployees               :   [],
            allocationSearchForm            :   {...this.initAllocationSearchForm},
            submittedAllocationFilterForm     :   {...this.initAllocationSearchForm}
        }, () => {
            this.allocationSearchModal.hide();
            this.props.history.push('/allocation_list')
        });
    }


    openAddallocationScreen       =      ()      =>     {
        this.props.history.push('/allocation_list/add')
    }

    cloneallocationScreenInit             =       ()      =>      {
       this.props.history.push({pathname: `/allocation_list/add`, state: { allocationData : this.state.allocationViewData  , clone : 'yes'}})
    }


    viewAllocationDetail          =      (id)        =>     {
        this.setState({minimizeTable  :   true})
        this.getAllocationViewDetails(id)
       
    }

    //************************GET allocation DETAILS**************** */
    getAllocationViewDetails             =    (transaction_id)        =>  {
       
         this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET',  this.detailUrl + transaction_id, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
             this.setState({
                 allocationViewData          :   respData.data,
                 allocationTransactionId     :   respData.data.transaction_id,
                 allAllowedStatus            :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                 addCommentForm              :   {...this.updateCommentFormInit , status : respData.data.status}
             });
            
         },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
        
     }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    checkAllItem() {
        let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox')
        if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                allocationItemForCheckbox[i].checked = document.getElementById('all_check_allocation').checked;
            }
        }
    }

   releasedItemModalInit       =       ()      =>      {
            this.setState({cancelledItemList : []} , () => {
            let cancelledItemList          =        this.state.cancelledItemList;
            var showErrorMessage                =   false;
            let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox');
            if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
                for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                    if(allocationItemForCheckbox[i].checked) {
                        let itemId = allocationItemForCheckbox[i].value;
                        let data = this.state.allocationViewData.items.find(i => i.id == itemId);
                    
                        let row                         =   {...cancelledItemList[i],...data };
                        cancelledItemList[i]            =   row;
                        let list = cancelledItemList.filter(Boolean)
                        this.setState({cancelledItemList   :   [...list],
                                    cancelledItemForm   :   {...this.cancelItemFormInit,
                                    notes : ''}}
                                    ,() => this.releasedItemModal.show())
                    
                    }
                }
            }
            if(this.state.cancelledItemList && this.state.cancelledItemList.length == 0 && !showErrorMessage){
                toast.error('Please select an Item for Cancellation', { position: toast.POSITION.TOP_RIGHT });
            }
       })
        
   }

   addToConsumptionModalInit       =       ()      =>      {
        // this.setState({cancelledItemList : []} , () => {
        let itemList          =        [];
        var showErrorMessage                =   false;
        let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox');
        if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                if(allocationItemForCheckbox[i].checked) {
                    let itemId = allocationItemForCheckbox[i].value;
                    let data = this.state.allocationViewData.items.find(i => i.id == itemId);
                    
                    itemList.push({...data,allocation_item_id : data.id})
                    
                }
            }
        }
        if(itemList && itemList.length == 0 && !showErrorMessage){
            toast.error('Please select an Item', { position: toast.POSITION.TOP_RIGHT });
        }else if(itemList.find(i => Number(i.pending_qty) <= 0)) {
            toast.error('Every Item\'s Pending qty must be more than 0.', { position: toast.POSITION.TOP_RIGHT });
        }else{
           
            let consumptionData         =       {...this.state.allocationViewData,items : itemList}
           
           this.props.history.push({ pathname: `/consumption_list/add`, state: {addConsumptionFromAllocation: consumptionData } })
        }
   
}

   updateComment           =           ()          =>      {
    this.myRef.current.scrollIntoView()
   }

   editAllocation       =       ()      =>      {
        this.props.history.push({pathname: `/allocation_list/add`, state: {allocationData : this.state.allocationViewData }})
   }

   submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
            ...this.state.addCommentForm,
            transaction_id : this.state.allocationTransactionId
        }
        HttpAPICall.withAthorization('PUT', this.commentUrl, this.props.access_token, {}, { ...frmData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getAllocationViewDetails(this.state.allocationTransactionId);
            this.loadListingTblData(1)
            this.setState({addCommentForm : {...this.updateCommentFormInit}})
        }).then(() => this.setState({ addCommentFormSubmitting : false }));
   }

   submitCancelItemForm            =       (e)      =>      {
    e.preventDefault()
    
    let items                   =        [...this.state.cancelledItemList];
      
    let itemsLists              =        items.map(i => {
                                          
                                             return {allocation_item_id : i.id, release_qty : parseInt(i.new_released_qty),transaction_id : i.transaction_id};
                                            });
                                           
    let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists]}
    
      this.setState({ cancelItemFormSubmitting: true })
      HttpAPICall.withAthorization('PUT', this.releaseUrl, this.props.access_token, {}, { ...formData }, (response) => {
          toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
          this.releasedItemModal.hide()
          this.setState({cancelledItemForm         :       {...this.cancelItemFormInit}})
          this.getAllocationViewDetails(this.state.allocationTransactionId)
      }).then(() => this.setState({ cancelItemFormSubmitting: false }));
  }

  //***********************DELETE  **************************** */
    deleteAllocation = () => {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE',this.detailUrl + this.state?.allocationViewData?.transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({ minimizeTable: false })
                        this.loadListingTblData(1)
                    }).then(() => {

                    });
                }
            });
    }

    allocationDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.submittedAllocationFilterForm};
        if(JSON.stringify(this.state.submittedAllocationFilterForm) !== JSON.stringify(this.initAllocationSearchForm)){
            this.allocationDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Quarter Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_quarter"}
                    this.allocationDownloadStart(params);
                }
            });
        }
    }
    
    allocationDownloadStart                   =   (params)  =>  {
        this.setState({reportDownloading : true});
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_INVENTORY_API_SERVER + '/report/item-wise-allocation-order', this.props.access_token, {...params, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    downloadPdfHandler              =    (transaction_id)     =>         {
        this.setState({ pdfDownloading: true});
        HttpAPICall.withAthorization('GET', this.detailUrl  + transaction_id, this.props.access_token,{download : "download"},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({pdfDownloading: false}));
    }

    cloneAllocationScreenInit             =       ()      =>      {
        this.props.history.push({pathname: `/allocation_list/add`, state: { allocationData : this.state.allocationViewData , clone : 'yes'}})
     }


    alllocationListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Allocation</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? 
                        <Ax> <button type="button" disabled={!this.props.permissions.includes('isc-allocation-add')} className="btn btn-primary" onClick={this.openAddallocationScreen}>New Allocation</button>
                            <button type="button" className="btn btn-secondary"
                                disabled={this.state.reportDownloading || this.state.listing_loading}
                                onClick={this.allocationDownloadHandler} >
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </Ax>
                        : null
                    }
                    <button type="button" className="btn btn-secondary" onClick={this.allocationSearchModalInit} disabled={this.state.reportDownloading || this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon}  className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable && this.props.permissions.includes('isc-allocation-add') ? <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="#" onClick={this.openAddallocationScreen}>New Allocation</a></li>
                        </ul>
                    </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Allocation : {this.state.allocationTransactionId}</h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary" onClick={() => this.updateComment()} >Update Status </button>
                                <button type="button" disabled={this.state.allocationViewData?.can_edit == false || !this.props.permissions.includes('isc-allocation-edit') ? true : false} onClick={() => this.editAllocation()} className="btn btn-secondary">Edit </button>
                                <button type="button" className="btn btn-secondary" disabled={this.state.pdfDownloading} onClick={() => this.downloadPdfHandler(this.state.allocationViewData?.transaction_id)}><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /></button>
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                        <a role="button" href
                                            onClick={() => {
                                                 return this.cloneallocationScreenInit();
                                            
                                            }}
                                            className={['dropdown-item',  ''].join(' ')}
                                            style={{ "pointerEvents": "all" }}
                                        >Clone</a>
                                    </li>
                                    <li>
                                        <a role="button" href
                                            onClick={() => {
                                                if (this.state?.allocationViewData?.status > 1 || this.props.permissions.includes('isc-allocation-item-cancel')) {
                                                    return this.releasedItemModalInit();
                                                }
                                            }}
                                            className={['dropdown-item', this.state?.allocationViewData?.status > 1 || this.props.permissions.includes('isc-allocation-item-cancel') ? '' : 'disabled'].join(' ')}
                                            title={this.state?.allocationViewData?.status == 1 || !this.props.permissions.includes('isc-allocation-item-cancel') ? 'This action cannot be performed' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Release Item</a>

                                    </li>
                                    <li>
                                        <a role="button" href
                                            onClick={() => {
                                                if (this.state?.allocationViewData?.status > 1 && this.props.permissions.includes('isc-consumption-add')) {
                                                    return this.addToConsumptionModalInit();
                                                }
                                            }}
                                            className={['dropdown-item', this.state?.allocationViewData?.status > 1 && this.props.permissions.includes('isc-consumption-add') ? '' : 'disabled'].join(' ')}
                                            title={this.state?.allocationViewData?.status == 1 || !this.props.permissions.includes('isc-consumption-add') ? 'This action cannot be performed' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Consume Item</a>

                                    </li>
                                    <li>
                                        <a role="button" href
                                            onClick={() => {
                                                if (this.state?.allocationViewData?.can_delete == "Y" || this.props.permissions.includes('isc-allocation-delete')) {
                                                    return this.deleteAllocation();
                                                }
                                            }}
                                            className={['dropdown-item', this.state?.allocationViewData?.can_delete == "Y"  || this.props.permissions.includes('isc-allocation-delete') ? '' : 'disabled'].join(' ')}
                                            title={this.state?.allocationViewData?.can_delete == "N"  || !this.props.permissions.includes('isc-allocation-delete') ? 'This action cannot be performed' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Delete</a>
                                    </li>
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.alllocationListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.allocationViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    alllocationListingTableJsx            =   ()   =>   {
        return(<Ax>
        <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.allocationSearchClear} /> 
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Allocation</th>
                        </tr>)
                        : (<tr className='text-center'>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "10%" }}>Date</th>
                            <th scope="col" style={{ width: "10%" }} className='text-start'> Transaction ID</th>
                            <th scope="col" style={{ width: "15%" }} className='text-start'>Warehouse</th>
                            <th scope="col" style={{ width: "25%" }} className='text-start'>Allocation For</th>
                            <th scope="col" style={{ width: "10%" }}>Total Qty</th>
                            <th scope="col" style={{ width: "10%" }}>Pending Qty</th>
                            <th scope="col" style={{ width: "10%" }} >Status</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.allocation_listing.length > 0
                            ? (this.state.allocation_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}>
                                                        <a href="#" className="text-capitalize link-primary" onClick={() => this.viewAllocationDetail(item.transaction_id)}>{item.transaction_id ? <div>{item.transaction_id} ({item.transaction_date_display}) </div> : "-"}  </a>
                                                        <div className="mt-1">
                                                            <small> <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>{item.warehouse?.name ?? "-"}</span> </small>
                                                            <small className="float-end">{item.status_data ? item.status_data.name : "-"}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-center'>{item.transaction_date_display}</td>
                                                <td className='text-center'><a role="button" className="link-primary" onClick={() => this.viewAllocationDetail(item.transaction_id)}>{item.transaction_id ? item.transaction_id : "-"}</a></td>
                                                <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                    : "-"}</td>
                                                <td className='text-capitalize'>{
                                                    item.assetProfile 
                                                    ? <div><small className='form-text'>{item.allocation_for == 'asset' ? 'Asset : ' : ''}</small> {item.assetProfile.name} </div>
                                                    : item.workstation 
                                                    ? <div><small className='form-text'>{item.allocation_for == 'workstation' ?  'Workstation : ' : ''}</small> {item.workstation.workstation_name}  </div> 
                                                    : item.employee ? <div><small className='form-text'>{item.allocation_for == 'employee' ?  'Employee : ' : ''}</small> {item.employee.name}  </div> 
                                                    : item.subAssembly ? <div><small className='form-text'>{item.allocation_for == 'sub_assembly' ?  'Sub Assembly : ' : ''}</small> {item.subAssembly.name}  </div>  : '' } 
                                                
                                                </td>
                                                <td className='text-center'>{item.total_qty ? item.total_qty : 0}</td>
                                                <td className='text-end'>{item.total_pending_qty ? item.total_pending_qty : 0}</td>
                                                <td className='text-center'>{item.status_data ? item.status_data.name : "-"}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewAllocationDetail(item.transaction_id)}>View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    allocationViewDetails                =   ()   =>   {
        let allocationData = this.state.allocationViewData;
        return (<div className="tab_content_header">
            {this.state.view_loading ? <Loader />
                : allocationData ?
                    <Ax>
                        <AllocationDetail allocationData={this.state.allocationViewData} checkAllItem={this.checkAllItem} />
                        <hr />
                        <div>
                            <div className="tab_content_wrapper" ref={this.myRef}>
                                <span className="content_heading">Comments & History</span>
                            </div>
                            <form onSubmit={this.submitUpdateCommentForm}>
                                <div className="my-3 row">
                                    <label className="col-sm-3 col-form-label">Change status to</label>
                                    <div className="col-sm-5">
                                        <TapSelect
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                            }}
                                            value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                            options={this.state.allAllowedStatus}
                                            isSearchable={false}
                                            isDisabled={!this.props.permissions.includes('isc-allocation-status-change')}
                                            placeholder="Please Select Status"
                                        />
                                    </div>
                                </div>
                                <div className="my-2">
                                    <label className="form-label">Comments</label>
                                    <textarea
                                        name="notes"
                                        value={this.state.addCommentForm.notes}
                                        onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                        className="form-control"
                                        placeholder="Please Add a comment"
                                        required
                                        style={{ height: "100px" }}
                                    />
                                </div>
                                <div className="col-12 text-end">
                                    <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                        Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                    </button>
                                </div>
                            </form>
                            <hr />
                            <div className="my-1">
                                {
                                    allocationData.comments && allocationData.comments.length > 0 ? allocationData.comments.map((c, k) => {
                                        return (<Ax key={k}><div className="row">
                                            {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                            <div className="col-sm-4 text-end">
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {c.created_at_display}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div><hr /></Ax>);
                                    })
                                        : null
                                }
                            </div>

                        </div>
                    </Ax>
                    : null}
        </div>)
    }


    releasedItemModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="releasedItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Released Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "12%" }}>Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Released Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Pending Qty</th>

                                                    <th scope="col" style={{ width: "12%" }}>Released</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="text-end" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td className='text-start'>{i.itemData ? <div><span className="fw-bold">{i.itemData.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.itemData.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.itemData?.manufacturer?.name ?? '-'} (MPN : {i.itemData?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.itemData?.tags?.length > 0
                                                                    ? (i.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td>{i.qty ? i.qty : 0}</td>
                                                        <td>{i.released_qty ? i.released_qty : 0}</td>
                                                        <td>{i.pending_qty ? i.pending_qty : 0}</td>

                                                        <td className="text-end">
                                                             <TapInputNumber
                                                              name="new_released_qty"
                                                            value={this.state.cancelledItemList[k]?.new_released_qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(null, k, 'cancelledItemList',null,"new_released_qty",e)}
                                                            placeholder="Released Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                        /></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    //***********************SEARCH allocation JSX****************
    allocationSearchModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="allocationSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Allocation Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="allocationSearchForm" onSubmit={this.submitAllocationSearchForm}>
                           {!this.state.allocationSearchFormDataLoaded ? <Loader/>
                            :  <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_transaction_id"
                                            type="text"
                                            value={this.state.allocationSearchForm.search_transaction_id}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "allocationSearchForm")}
                                            placeholder="Please enter Transaction ID"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    </div>
                                    <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Voucher Number</label>
                                    <div className="col-sm-10">
                                        <input
                                            name="search_voucher_number"
                                            type="text"
                                            value={this.state.allocationSearchForm.search_voucher_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "allocationSearchForm")}
                                            placeholder="Please enter Voucher Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Warehouse</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.all_warehouses}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_warehouse_ids')}
                                            value={this.state.all_warehouses.filter(u => this.state.allocationSearchForm?.search_warehouse_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Warehouses"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Asset</label>
                                    <div className="col-sm-10">
                                        <InputAssetSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_asset_ids');
                                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Workstation</label>
                                        <div className="col-sm-10">
                                            <InputWorkstationSearch
                                                placeholder="Search Workstation"
                                                changeEvent={(selectedOption) => {
                                                    this.setState({
                                                        searchedWorkstation: selectedOption ? selectedOption : '',
                                                        allocationSearchForm : {...this.state.allocationSearchForm,search_workstation_ids : selectedOption ? [selectedOption.value] : []}
                                                    });
                                                   // this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_workstation_ids')
                                                }}
                                                menuPlacement="top"
                                                isMulti={false}
                                                value={this.state.searchedWorkstation && this.state.searchedWorkstation}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                   
                                  </div>
                                </div>
                                    <div className="row my-3">
                                        <label className="col-sm-2 col-form-label">Employees</label>
                                        <div className="col-sm-10">
                                            <InputEmployeeSearch
                                                changeEvent={(selectedOption) => {
                                                
                                                    this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_employee_ids');
                                                    this.setState({
                                                         allocationSearchForm: { ...this.state.allocationSearchForm, search_employee_ids: selectedOption.length > 0 ? selectedOption.map(o => o.value) : [] },
                                                        searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                    });
                                                }}
                                                isMulti={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                placeholder="Select Employee"
                                                value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                            />
                                        </div>
                                    </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                        <div className="col-sm-10">
                                            <InputItemSearch
                                                placeholder="Search Item"
                                                isMulti={true}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'allocationSearchForm', 'search_item_ids');
                                                    this.setState({
                                                        searchedItem: selectedOption ? selectedOption : null
                                                    }, () => { });
                                                }}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.allocationSearchForm.date_range}
                                            startDate={this.state.allocationSearchForm.date_range_start}
                                            endDate={this.state.allocationSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({allocationSearchForm
                                                    : {
                                                        ...this.state.allocationSearchForm, 
                                                        date_range: period, 
                                                        date_range_start: startDate ? startDate : null, 
                                                        date_range_end: endDate ? endDate : null
                                                      }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={this.allocationClear}>Clear</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Allocation Listing</title></Helmet>
            {this.alllocationListjsx()}
            {this.allocationSearchModalJsx()}
           
            {this.releasedItemModalJsx()}
           
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(AlllocationList);