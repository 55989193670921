import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { Modal } from 'bootstrap';
import DatePicker from "react-datepicker";
import TapSelect from '../../../components/ui/TapSelect';

const EmployeeAttendenceDetailModal = forwardRef(({ employeeData, checkInOut, attendanceDetail }, ref) => {
    const [modal, setModal] = useState(null);
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        check_in_date: '',
        selected_hours: '1',
        selected_minutes: '1',
    });
    const [hoursOptions, setHoursOptions] = useState([]);
    const [minutesOptions, setMinutesOptions] = useState([]);
    useImperativeHandle(ref, () => ({
        show: (employee) => {

            setData({
                check_in_date: employee?.check_in_date || '',
                selected_hours: employee?.selected_hours || '1',
                selected_minutes: employee?.selected_minutes || '1',
            });
            if (modal) { modal.show(); }
        },
        hide: () => { if (modal) { modal.hide(); } }
    }));

    useEffect(() => {
        const modalElement = document.getElementById('employeeAttendenceDetailModal');
        const bsModal = new Modal(modalElement);
        setModal(bsModal);

        const hours = Array.from({ length: 23 }, (_, index) => ({
            label: (index + 1).toString(),
            value: (index + 1).toString()
        }));

        const minutes = Array.from({ length: 59 }, (_, index) => ({
            label: (index + 1).toString(),
            value: (index + 1).toString()
        }));

        setHoursOptions(hours);
        setMinutesOptions(minutes);
    }, []);
    const handleSubmit = () => {
        console.log("Updated Employee Data:", data);
        modal.hide();
    };
    return (
        <div className="modal fade" id="employeeAttendenceDetailModal" tabIndex="-1" aria-labelledby="employeeDetailModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="employeeDetailModalLabel">Employee Attendance Details</h5>
                        <button type="button" className="btn-close" onClick={() => modal && modal.hide()} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row align-items-center">
                            <label className="col-sm-3 col-form-label require">{checkInOut ? checkInOut : attendanceDetail?.attendence_type}</label>
                            <div className="col-sm-4">
                                <div className="add_calender_section">
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(date)}
                                        dateFormat="dd-MMM-yyyy"
                                        className="form-control form-control-md"
                                        showMonthDropdown
                                        showYearDropdown
                                        autoComplete="off"
                                        scrollMonthYearDropdown
                                        required={true}
                                        maxDate={new Date()}
                                        disabled={loading}
                                        placeholderText={`Please Enter Date `}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-5 ps-0">
                                <div className="row">
                                    <div className="col-6 pe-0">
                                        <TapSelect
                                            required
                                            isMulti={false}
                                            options={hoursOptions}
                                            value={hoursOptions.find(option => option.value === data.selected_hours) || null}
                                            changeEvent={selectedOption => {
                                                setData({ ...data, selected_hours: selectedOption ? selectedOption.value : null })
                                            }}
                                            placeholder="Select Minutes"
                                        />

                                    </div>
                                    <div className="col-6 pe-0">
                                        <TapSelect
                                            required
                                            isMulti={false}
                                            options={minutesOptions}
                                            value={minutesOptions.find(option => option.value === data.selected_minutes) || null}
                                            changeEvent={selectedOption => {
                                                setData({ ...data, selected_minutes: selectedOption ? selectedOption.value : null })
                                            }}
                                            placeholder="Select Minutes"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center my-3">
                            <label className="col-sm-3 col-form-label">Notes </label>
                            <div className="col-sm-9">
                                <textarea
                                    name="note"
                                    className="form-control"
                                    style={{ height: "80px" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => modal && modal.hide()}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default EmployeeAttendenceDetailModal;
