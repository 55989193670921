import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import React from 'react';
import PurchaseOrderDetailModal from '../../inventory/purchaseOrder/PurchaseOrderDetailModal';
import ReceivedQtyDetail from '../../inventory/includes/ReceivedQtyDetail';
import swal from "sweetalert";
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ItemWisePurchaseOrder extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.reportFilterFormInitialState   =   {
            tagged_user_filter                  :    '',
            search_transaction_id               :   '',
            search_purchase_order_type          :   '',
            search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_status_ids                   :   [],
            search_priority                     :   '',
            search_manufacturer_ids             :   [],
            search_vendor_ids                   :   [],
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
            search_workstation_ids              :   [],
            search_asset_ids                    :   [],
            search_employee_ids                 :   [],
            tagged_user_ids                     :   [],
        };
        
        this.reportFilterFormLabel          =   {
             tagged_user_filter                 :   'User Tagging : ',
            search_transaction_id               :   'PO Number : ',
            search_purchase_order_type      :   'Purchase Order Type : ',
            search_warehouse_ids                :   'Warehouse : ',
            search_workstation_ids              :   'WorkStation : ',
            search_asset_ids                    :   'Asset : ',
            search_item_ids                     :   'Items : ',
            search_item_tags                    :   'Item Tags : ',
            search_status_ids                   :   'Status : ',
            search_vendor_ids                   :   'Vendor : ',
            search_priority                     :   'Search Priority : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            date_range                          :   'Date Range : ',
            search_employee_ids                 :   'Employees : ',
            tagged_user_ids                   :     'Tagged Users : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            allItemTags                         :   [],
            all_periods                         :   [],
            searchedVendor                      :   [],
            allStatusOptions                    :   [],
            allPriorityOptions                  :   [],
            manufactures                        :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null, 
            searchedAssets                      :   [],
            searchedEmployees                   :   [], 
            workstations                        :   [],
            allPOTypes                          :   [],
            userFilters                         :   [
                {value  :   'tagged_me',        label : 'My Tagged PO'},
                {value  :   'created_by_me',    label : 'PO Created By Me'},
            ],
            allUsers                            :   [],  
            download_access                     :   false,   
            reportData                          :   null,                  
        };
        this.PODetailModalRef                 =   React.createRef();
        this.POReceivedQtyModalRef            =   React.createRef();

        this.formDataUrl    =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase-order/form_data';
        this.reportUrl      =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase-order';

    }

    componentDidMount(){
        setTimeout(() => {
            this.initializeData(this.props)
        },1500)
    }
    initializeData     =      (pr)      =>      {
        const report_name   =   pr?.location?.pathname ? pr.location.pathname.split('/')[2] : '';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];

        if(pr){
            let all_periods         =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            HttpAPICall.withAthorization('GET', this.formDataUrl, pr.access_token, null, null, (response) => {
                this.setState({
                    reportData                  :   reportData.length > 0 ? reportData[0] : null,
                    download_access             :   reportData.length > 0  && reportData[0].download_access === 'Y' ? true  : false,
                    reportFilterFormData        :   {...response.data},
                    all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    formDataLoaded              :   true,
                    all_periods                 :   all_periods ,
                    allPOTypes                :   response.data && response.data.purchase_order_type && response.data.purchase_order_type.length > 0 ? response.data.purchase_order_type.map((s) => { return {value: s.key, label: s.name}; }) : [],
                    manufactures                :   response.data && response.data.manufacturers && response.data.manufacturers.length > 0  ? response.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                    allStatusOptions            :   response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name,level : s.level}; }) : [],
                    allPriorityOptions          :   response.data && response.data.priorities && response.data.priorities.length > 0 ? response.data.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                    allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    workstations               :   response.data.workstations && response.data.workstations.length > 0 ?  response.data.workstations.map((w) => {return {value: w.id, label: `${w.workstation_name} (${w.workstation_nickname})`}}) : [],
                    allUsers                    :   response.data && response.data.users ? response.data.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                }, () => {

                    let status_ids = [];
                    let updateFilterForm = {};
                    let ParamObject = new URLSearchParams(pr.location.search);
                    if (ParamObject.get('search_status_ids') || ParamObject.get('search_item_ids') || ParamObject.get('search_vendor_ids') || ParamObject.get('tagged_user')) {
                        if (ParamObject.get('search_status_ids')) {
                            status_ids = ParamObject.get('search_status_ids').split(',').map(Number);

                            if (ParamObject.get('search_warehouse_ids')) {
                                let warehouse = this.state.all_warehouses.find(us => us.value == ParamObject.get('search_warehouse_ids'));
                                if (warehouse) {
                                    updateFilterForm['search_warehouse_ids'] = [warehouse.value];
                                }
                            }
                        }
                        if (ParamObject.get('search_item_ids')) {    
                            updateFilterForm['search_item_ids'] = [ParamObject.get('search_item_ids')];   
                        }
                        if (ParamObject.get('search_vendor_ids')) {    
                            updateFilterForm['search_vendor_ids']          =          [ParamObject.get('search_vendor_ids')]; 
                              
                        }
                        if (ParamObject.get('tagged_user')) {
                            updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                        }
                      
                    } else {
                        status_ids = this.state.allStatusOptions.filter(s => s.level <= 5).map(s => s.value)                  
                    } 
                    
                    setTimeout(() => {
                        this.setState({
                            reportFilterForm        :    { ...this.state.reportFilterForm, search_status_ids: status_ids,...updateFilterForm },
                            searchedVendor                 :       ParamObject.get('search_vendor_ids') ? [{value : ParamObject.get('search_vendor_ids'),display_label : ParamObject.get('vendor_name') }] : null,
                            searchedItem            :   ParamObject.get('search_item_ids') ? [{value : ParamObject.get('search_item_ids'), display_label :  `${ParamObject.get('item_name')}` }] : []
                        }, () => {
                            this.reportFilterFormSubmit()
                        })
                    }, 1000)

                });
            }, (err) => {

            });
            
    
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'tagged_user_filter') {
                        show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_vendor_ids') {
                        show_val            =   this.state.searchedVendor ? this.state.searchedVendor.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.reportFilterForm ? this.state.reportFilterForm.search_transaction_id : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_priority') {
                        show_val            =   this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_purchase_order_type') {
                        show_val            =   this.state.allPOTypes.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_workstation_ids') {
                        show_val            =   this.state.workstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'tagged_user_ids') {
                        show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }

                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.reportFilterForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.date_range_end).format('DD-MMM-YYYY')
                            let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                            show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   ()  =>  {
        let formData                            =   {...this.state.submittedReportFilterForm};
        if(formData.search_transaction_id != '' || formData.date_range != '') {
            this.reportDownloadApiCall(formData);
        } else if((JSON.stringify(formData) === JSON.stringify(this.reportFilterFormInitialState)) || formData.date_range == '') {
            swal({ 
                title: "Download", icon: "warning", buttons: ["Cancel", "Okay"],
                text: "The download will be for Current Quarter, in case you want a different Period then apply the criteria before downloading.",
            }).then(willDownload => {
                if (willDownload) { this.reportDownloadApiCall({...formData, date_range: "current_quarter"}); }
            });
        }
    }
    
    reportDownloadApiCall                   =   (formData)  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...formData, download : 'download'} , {} , (response) => DownloadFile.file(null,response.data.navgation,response.data.msg,this.props)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    poDetailModalInit          =       (req_item_id)           =>      {
        this.PODetailModalRef.current.modalPOInit(req_item_id);  
    }

    poReceivedQtyModalInit          =       (po_item_id)           =>      {
        this.POReceivedQtyModalRef.current.receiviedQtyModalInit(null,po_item_id);  
    }

    checkAllList() {
        let poItemCheckboxCollections      =   document.getElementsByClassName('po_item_id_checkbox')
        if(poItemCheckboxCollections && poItemCheckboxCollections.length > 0) {
            for (let i = 0; i < poItemCheckboxCollections.length; i++) {
                poItemCheckboxCollections[i].checked = document.getElementById('all_check_po').checked;
            }
        }
    }

    addPurchaseHandler                  =   ()  =>  {
        let po_item_ids                     =   [];
        let warehouse_ids                   =   [];
        let vendor_ids                     =   [];
        let pendingQtyList                  =   [];
        let poList                          =   [];
        let vendor_name                     =   [];
        let currency                       =    [];
        
        let poItemCheckboxCollections      =   document.getElementsByClassName('po_item_id_checkbox');
        if(poItemCheckboxCollections && poItemCheckboxCollections.length > 0) {
            for (let i = 0; i < poItemCheckboxCollections.length; i++) {
                if(poItemCheckboxCollections[i].checked) {
                    po_item_ids.push(poItemCheckboxCollections[i].value);
                    warehouse_ids.push(poItemCheckboxCollections[i].getAttribute("data-warehouseId"));
                    vendor_ids.push(poItemCheckboxCollections[i].getAttribute("data-vendorId"));
                    vendor_name.push(poItemCheckboxCollections[i].getAttribute("data-vendorName"));
                    pendingQtyList.push(poItemCheckboxCollections[i].getAttribute("data-pendingQty"));
                    currency.push(poItemCheckboxCollections[i].getAttribute("data-currency"));
                    let selectedPoData = this.state.listingData?.find(data =>  data.po_item_id == poItemCheckboxCollections[i].value);
                    poList.push(selectedPoData)
                }
            }
        }

        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        let vendor_unique                   =   vendor_ids.filter((item,i,ar) => ar.indexOf(item) === i);
        let currency_unique                 =   currency.filter((item,i,ar) => ar.indexOf(item) === i);
        if (po_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else if(vendor_unique.length > 1) {
            toast.error('Please select item with same vendor', { position: toast.POSITION.TOP_RIGHT });
        } else if(pendingQtyList.includes('0')) {
            toast.error('Every item must have pendning qty more than 0', { position: toast.POSITION.TOP_RIGHT });
        }else if(currency_unique.length > 1) {
            toast.error('Every item must be of Same Currency', { position: toast.POSITION.TOP_RIGHT });
        } else {
            let allSelectedItem         =    poList?.flat();
            let all_item_ids            =    allSelectedItem?.map(i => i.id)
          
            let poStateData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                vendor_id                   :   vendor_ids[0],
                vendor_name                 :   vendor_name[0],
                items                       :   all_item_ids,
                po_item_ids                 :   po_item_ids,
                currency                    :   currency[0],
            };

             this.props.history.push({ pathname: `/purchase_list/add`, state: { addPurchaseFromPO : poStateData } });
        }
    }


    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                    <button type="button" className="btn btn-primary" onClick={this.addPurchaseHandler}>Add Purchase</button> 
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    {this.props.permissions.includes('isc-po-view') &&   <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2"/>) : null}
                    </button>}
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className={['dropdown-item', !this.state.download_access ? 'disabled' : ''].join(' ')} onClick={this.reportDownloadHandler}>Download</Link></li>
                    </ul>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
            <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Purchase Order</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.userFilters}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_filter')}
                            value={this.state.userFilters.find(u => u.value == this.state.reportFilterForm.tagged_user_filter)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All PO"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                    <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allItemTags}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_tags')}
                            value={this.state.allItemTags.filter(u => this.state.reportFilterForm?.search_item_tags?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Item Tags"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Tagged User</label>
                    <div className="col-sm-6">
                        <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Tagged User"
                            options={this.state.allUsers}
                            value={this.state.allUsers.filter(s => this.state.reportFilterForm.tagged_user_ids.includes(s.value))}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'tagged_user_ids')}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.state.all_warehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Vendor</label>
                    <div className="col-sm-6">
                        <InputVendorSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_vendor_ids');
                                this.setState({ searchedVendor: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedVendor && this.state.searchedVendor?.length > 0 ? this.state.searchedVendor.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">PO Number</label>
                    <div className="col-sm-6">
                        <input
                            name="search_transaction_id"
                            type="text"
                            value={this.state.reportFilterForm.search_transaction_id}
                            className="form-control"
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e, "reportFilterForm")}
                            placeholder="Please enter Transaction ID"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">PO Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allPOTypes}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_purchase_order_type')}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.allPOTypes.find(s => this.state.reportFilterForm.search_purchase_order_type == s.value)}
                            placeholder="Select PO Type"
                            
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Workstation</label>
                    <div className="col-sm-6">
                        <TapSelect
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_workstation_ids')}
                            options={this.state.workstations}
                            isSearchable={true}
                            isClearable={true}
                            value={this.state.workstations.filter(t => this.state.reportFilterForm.search_workstation_ids.includes(t.value))}
                            placeholder="Please Select Workstation"
                            isMulti={true}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Employees</label>
                    <div className="col-sm-6">
                        <InputEmployeeSearch
                            changeEvent={(selectedOption) => {
                                
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_employee_ids');
                                this.setState({
                                    reportFilterForm  : {...this.state.reportFilterForm,search_employee_ids : selectedOption.length > 0 ? selectedOption.map(o => o.value) : []},
                                    searchedEmployees : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            value={this.state.searchedEmployees && this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Priority</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allPriorityOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_priority')}
                            value={this.state.allPriorityOptions.find(u => u.value == this.state.reportFilterForm.search_priority)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select Priority"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status_ids')}
                            isMulti={true}
                            isClearable={true}
                            value={this.state.allStatusOptions.filter(s => this.state.reportFilterForm.search_status_ids.includes(s.value))}
                            placeholder="Select Status"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.all_periods ? this.state.all_periods : []}
                            value={this.state.reportFilterForm.date_range}
                            startDate={this.state.reportFilterForm.date_range_start}
                            endDate={this.state.reportFilterForm.date_range_end}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range: period,
                                        date_range_start: startDate ? startDate : null,
                                        date_range_end: endDate ? endDate : null
                                    }
                                });
                            }}

                        />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className="text-center" style={{ width: "5%" }}><input type='checkbox' id="all_check_po" onChange={this.checkAllList} /></th>
                        <th className='text-center' style={{ width: "9%" }}>PO Number</th>
                        <th className='text-center' style={{ width: "7%" }}>PO Date</th>
                        <th className="text-center" style={{ width: "16%" }}>Warehouse</th>
                        <th className="text-center" style={{ width: "16%" }}>Vendor</th>
                        <th className="text-center" style={{ width: "18%" }}>Item Name</th>
                        <th className='text-center' scope="col" style={{ width: "8%" }}>Status</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }}  >PO Qty</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Received</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Cancelled</th>
                        <th className='text-end' scope="col" style={{ width: "5%" }} >Pending</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((rowData, k) => {
                          
                                return (<tr key={k}>
                                    <td className='text-center'>
                                        <input type='checkbox'
                                            className="po_item_id_checkbox"
                                            disabled={rowData.status_level !== 4 && rowData.status_level !== 5}
                                            name="po"
                                            value={rowData.po_item_id}
                                            data-warehouseId={rowData.warehouse_id} 
                                            data-vendorId={rowData.vendor_id}
                                            data-vendorName={rowData.vendor_name}
                                            data-pendingQty={rowData.pending_qty}
                                            data-currency={rowData.currency}
                                        />
                                    </td>
                                    <td onClick={() => this.poDetailModalInit(rowData.transaction_id)}><a hred="#" role="button" className='link-primary'>{rowData.transaction_id}</a></td>
                                    <td>{rowData.transaction_date_display}</td>
                                    <td>{rowData.warehouse_name}</td>
                                    <td className='text-capitalize'>{rowData.vendor_name}</td>
                                    <td>{rowData.item_name} <br />
                                        <small className='form-text'><b>Code : </b> {rowData.item_code}</small></td>
                                    <td className='text-center'>{rowData.status_name}</td>
                                   <td className='text-end'>{rowData.qty}</td>
                                    <td className='text-end' onClick={() => this.poReceivedQtyModalInit(rowData.po_item_id)} ><a role="button" className='link-primary'>{rowData.received_qty}</a></td>
                                    <td className='text-end'>{rowData.cancelled_qty}</td>
                                    <td className='text-end'>{rowData.pending_qty}</td>
                                </tr>);
                           
                        }))
                        : (<tr><td className="text-center" colSpan={10}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>ItemWise Purchase Order - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                    {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}
                            </Ax>)
                            : <Loader />
                        }
                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
            <PurchaseOrderDetailModal parentProps={this.props} ref={this.PODetailModalRef}/>
            <ReceivedQtyDetail parentProps={this.props} ref={this.POReceivedQtyModalRef}/>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        permissions             :   state.app && state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []        
    };
};

export default connect(mapStateToProps)(ItemWisePurchaseOrder);