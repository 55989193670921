import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../components/AppBaseComponent';
import HttpAPICall from '../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../components/hoc/Ax";
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from '../../components/ui/TapSelect';
import TapIcon from '../../services/TapIcon';
import axios from 'axios';
import ErrorValidation from '../../services/ErrorValidation';
import { DisplayListPagination } from '../../components/TapUi';

class ItemSearchModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.itemSearchFormInitialState     =   {
            'search_name'                   :    '',
            'search_item_code'              :    '',
            'search_category'               :   'goods',
            'search_manufacturer_id'        :   '',
            'search_manufacturer_part_no'   :   '',
            'search_gst_code'               :   '',
            'search_hsn_code'               :   '',
            'search_item_tags'                   :   [],
        };
        
        this.itemSearchFormLabel            =   {
            'search_name'                   :   'Item Name : ',
            'search_item_code'              :   'Item Code : ',
            'search_category'               :   'Category : ',
            'search_manufacturer_id'        :   'Manufacturer : ',
            'search_manufacturer_part_no'   :   'Manufacturer Part No : ',
            'search_gst_code'               :   'GST : ',
            'search_hsn_code'               :   'HSN / SAC Code : ',
            'search_item_tags'                   :   'Item Tags : ',
        };

        this.state                  =   {
            access_token                :   '',
            itemSearchForm                  :   {...this.itemSearchFormInitialState},
            submittedItemSearchForm         :   {...this.itemSearchFormInitialState},
            formSearchedElems               :   [],
            itemFormDataLoaded              :   false,
            filterFormData                  :   [],
            form_loading                    :   false,
            allManufacturers                :   [],
            allTags                         :   [],
            listing_tbl_page                :   1,
            listing_loading                 :   false,
            listingMeta                     :   null,
            listingData                     :   [],
            item_additional_attributes      :   [],
            itemData                        :   null,
            totalListingCount               :   0,
            selectedItem                    :   [],
            selectedItemIds                 :   []
        }

        this.itemSearchFormDataUrl  =   INVENTORY_API_BASE_URL_2 + '/item/search_form_data';
        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item';


    }

    componentDidMount                   =   ()  =>  {
        this.initScreen(this.props);  
        this.searchItemModal                    =   new Modal(document.getElementById('searchItemModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.access_token !== this.props.access_token){    
          this.initScreen(nextProps);
        }
    }
    
    initScreen                          =   (props)  =>  {
        this.setState({access_token : props.access_token});
        this.itemSearchFormDataInit()
        this.itemSearchFormSubmit()
    }

    itemSearchFormDataInit                           =          ()            =>           {
        this.setState({form_loading: true});
           HttpAPICall.withAthorization('GET', this.itemSearchFormDataUrl, this.props.access_token, {}, {}, (resp) => {
           this.setState({
                         filterFormData   :    resp.data,
                         allManufacturers :    resp.data.manufacturers.map((m) => { return { value: m.id, label: m.name }}),
                         allTags          :    resp.data.tags.map((m) => { return { value: m.id, label: m.name }})
           },() => {
             
           })
        }).then(() => {this.setState({form_loading: false})})
    }

    searchItemModalInit         =   (selectedItemIds = [])  =>  {
        this.searchItemModal.show() 
        if(selectedItemIds && selectedItemIds.length > 0) {
            this.setState({selectedItemIds : selectedItemIds,selectedItem : []})
        }
    }

    itemSearchFormSubmit                =   (event)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.itemSearchForm);
        let searchedElems               =   [];
        let itemSearchParams            =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.itemSearchFormLabel[key];
            let value                       =   this.state.itemSearchForm[key];
            if(value && value.length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_name') {
                        show_val            =   this.state.itemSearchForm.search_name;
                    }
                    if(key == 'search_item_code') {
                        show_val            =   this.state.itemSearchForm.search_item_code;
                    }
                    if(key == 'search_category') {
                        show_val            =   this.state.itemSearchForm.search_category;
                    }
                    if(key == 'search_manufacturer_id') {
                        show_val            =   this.state.allManufacturers.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_gst_code') {
                        show_val            =   this.state.itemSearchForm.search_gst_code;
                    }
                    if(key == 'search_manufacturer_part_no') {
                        show_val            =   this.state.itemSearchForm.search_manufacturer_part_no;
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allTags.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_hsn_code') {
                        show_val            =   this.state.itemSearchForm.search_hsn_code;
                    }

                    searchedElems.push(label + show_val);
                }
            }
        });
        
        
        // this.searchItemModal.hide();
        this.setState({
            submittedItemSearchForm   :   {...this.state.itemSearchForm},
            formSearchedElems         :   searchedElems
        }, () => {
            // this.searchItemModal.hide()
            this.loadListingTblData(1);
        });
       
       
    }

    loadListingTblData                  =   (page = 1)  =>  {
        let searchParm                  =   {...this.state.submittedItemSearchForm};
        this.setState({listing_loading: true, listing_tbl_page: page});
        axios({
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization' : 'Bearer ' + this.props.access_token },
            params: {page : page, per_page : 50,...searchParm},
            url: this.itemUrl
        }).then((response) => {
            let data                =   response.data;
            this.setState((prevStates,prevProps) => ({
                item_additional_attributes      :   data.additional_attributes,
                listingData                     :   data.data,
                listingMeta                     :   data.meta,
                totalListingCount               :   data.meta.total
            }));
        }).catch((err) => {
            ErrorValidation.apiErrorHandle(err, 'Unable to Load Item.');
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    addItemInTransaction                              =   (k)  => {
        let selectedItem               =   this.state.listingData[k];
        if (selectedItem) {
            let item = {
                label: (<Ax>
                    <b>Item : </b> {selectedItem.name} <small> ({selectedItem.item_code})</small><br />
                    <small>
                        <b>Manufacturer : </b> {selectedItem.manufacturer ? selectedItem.manufacturer.name : ''}<br />
                        <b>MPN : </b> {selectedItem.manufacturer_part_no}</small></Ax>),
                value: selectedItem.id,
                display_label: `${selectedItem.name} (${selectedItem.item_code})`,
                itemdata: selectedItem,
                rate : selectedItem.purchase_price,
                qty : 1,
                item:selectedItem
            }
            this.setState({

                selectedItem : [...this.state.selectedItem, { ...item }]

            }, () => { });
        }
    }

    addItemToForm       =       (e)         =>      {
        e.preventDefault();
        if(this.props.afterSelectingItems){
             
             this.props.afterSelectingItems(this.state.selectedItem)
             this.searchItemModal.hide()
        }
    }
   
    itemSearchModalJsx                  =   ()  =>  {
        
        return (
            <div className="modal fade" id="searchItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Item Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.form_loading ? <Loader />
                            :
                            <div className="modal-body">
                                <form onSubmit={this.itemSearchFormSubmit} id="itemSearchForm">
                                    <div className='bg-light p-2'>
                                        <div className="row">
                                            <label className="col-sm-2 col-form-label col-form-label-sm "> Item Name</label>
                                            <div className='col-sm-4'>
                                                <input
                                                    type="text"
                                                    name="search_name"
                                                    value={this.state.itemSearchForm.search_name}
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                    placeholder="Item Name"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm "> Item Code</label>
                                            <div className='col-sm-4'>
                                                <input
                                                    type="text"
                                                    name="search_item_code"
                                                    value={this.state.itemSearchForm.search_item_code}
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                    placeholder="Item Code"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <label className="col-sm-2 col-form-label col-form-label-sm "> Item Category</label>
                                            <div className='col-sm-4'>
                                                {
                                                    this.state.filterFormData && this.state.filterFormData.category
                                                        ? (this.state.filterFormData.category.map((cat, k) => {
                                                            return (
                                                                <div key={cat.index} className="form-check form-check-inline my-1">
                                                                    <input
                                                                        name="search_category"
                                                                        type="radio"
                                                                        value={cat.index}
                                                                        onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                                        checked={this.state.itemSearchForm.search_category === cat.index}
                                                                        className="form-check-input"
                                                                        id={cat.index}
                                                                    />
                                                                    <label className="form-check-label form-label-sm" htmlFor={cat.index}>{cat.item}</label>
                                                                </div>);
                                                        }))
                                                        : null
                                                }
                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Item Tags</label>
                                            <div className='col-sm-4'>
                                                <TapSelect
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'itemSearchForm', 'search_item_tags')}
                                                    options={this.state.allTags}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.allTags.filter(t => this.state.itemSearchForm.search_item_tags.includes(t.value))}
                                                    placeholder="Please Select Tag"
                                                    isMulti={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3">
                                            <label className="col-sm-2 col-form-label col-form-label-sm "> Manufacturer</label>
                                            <div className='col-sm-4'>
                                                <TapSelect
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'itemSearchForm', 'search_manufacturer_id')}
                                                    options={this.state.allManufacturers}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    value={this.state.allManufacturers.find(m => m.value == this.state.itemSearchForm.search_manufacturer_id)}
                                                    placeholder="Please Select"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">MPN</label>
                                            <div className='col-sm-4'>
                                                <input
                                                    type="text"
                                                    name="search_manufacturer_part_no"
                                                    value={this.state.itemSearchForm.search_manufacturer_part_no}
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                    placeholder="Search MPN"
                                                    autoComplete="off"
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">HSN / SAC Code</label>
                                            <div className='col-sm-4'>
                                                <input
                                                    type="text"
                                                    name="search_hsn_code"
                                                    value={this.state.itemSearchForm.search_hsn_code}
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => this.formInputHandler(e, 'itemSearchForm')}
                                                    placeholder="Search HSN / SAC Code"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className='col-sm-6 text-end'>
                                                <button type="submit" className='btn btn-primary' form='itemSearchForm'>Search</button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                                <form onSubmit={this.addItemToForm} id="addItemToForm">
                                    <table className="table table-bordered   bg-white table-sm">
                                        <thead className="table-secondary" >
                                            <tr>
                                                <th scope="col" style={{ width: "5%" }}>#</th>
                                                <th scope="col" style={{ width: "19%" }}>Item Name</th>
                                                <th scope="col" style={{ width: "17%" }}>Item Code</th>
                                                <th scope="col" style={{ width: "18%" }}>Manufacturer</th>
                                                <th scope="col" style={{ width: "12%" }}>MPN</th>
                                                <th scope="col" style={{ width: "10%" }}>UOM</th>
                                                <th scope="col" style={{ width: "15%" }} className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.listing_loading
                                                ? <tr><td className="text-center" colSpan="8"><Loader /></td></tr>
                                                : this.state.listingData.length > 0
                                                    ? this.state.listingData.map((item, index) => this.listingTableTrJsx(item, index))
                                                    : <tr><td className="text-center" colSpan="8">No Record Found</td></tr>}
                                        </tbody>
                                    </table>
                                    <DisplayListPagination
                                        meta={this.state.listingMeta}
                                        onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                                    />
                                </form>
                            </div>
                        }
                        <div className="modal-footer text-end">

                            <button type="button" className="btn btn-secondary mx-2" disabled={this.state.addToListFormSubmitting} data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.addToListFormSubmitting} form="addItemToForm">Add Selected Items {this.state.addToListFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    listingTableTrJsx                   =   (item, index)  =>  {
        let picked = this.state.selectedItem.find((selectedItem) => selectedItem.item.id == item?.id);
       
        if (item) {
            return (
                <tr key={index}>

                    <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                    <td><a role="button" onClick={() => this.viewItemDetail(item.id, item.name, item.category, item?.item_code)} className="link-primary text-capitalize">{item.name}</a></td>
                    <td className='text-capitalize'>{item.item_code}</td>
                    <td className='text-capitalize'>{item.manufacturer ? item.manufacturer.name : '-'}</td>
                    <td className='text-capitalize'>{item.manufacturer_part_no ? item.manufacturer_part_no : '-'}</td>
                    <td>{item.measuring_unit ? item.measuring_unit.name : '-'}</td>
                    <td className="text-center">
                        <button
                            type="button"
                            disabled={picked || this.state.selectedItemIds?.includes(item?.id)}
                            onClick={() => this.addItemInTransaction(index)}
                            className={["btn", "py-1", "px-3", picked ? "btn-light" : "btn-secondary"].join(' ')}
                        >{picked
                            ? <TapIcon.FontAwesomeIcon color="green" icon={TapIcon.faCheck} />
                            : 'Select'}
                        </button>
                    </td>

                </tr>
            );
        } else {
            return (
                <tr key={index}><td colSpan={this.state.minimizeTable ? 1 : 8}><Loader /></td></tr>
            );
        }
    }
    
    render                              =   ()  =>  this.itemSearchModalJsx()
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemSearchModal);