import React from "react";
import { connect } from 'react-redux';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from "../../../../services/TapApiUrls";
import HttpAPICall from "../../../../services/HttpAPICall";
import Loader from "../../../../components/ui/Loader/Loader";
import Ax from "../../../../components/hoc/Ax";
import { DisplayListPagination, DisplaySearchCriteria } from "../../../../components/TapUi";
import ItemWhConfigModal from "./ItemWhConfigModal";
import InputItemSearch from "../../../includes/ui/InputItemSearch";
import TapSelect from "../../../../components/ui/TapSelect";
import TapIcon from "../../../../services/TapIcon";
import { Modal } from 'bootstrap';
import AppBaseComponent from "../../../../components/AppBaseComponent";
import download from "../../../../services/DownloadFile";

class ItemWarehouseConfigList extends (React.Component, AppBaseComponent) {

    constructor(props) {
        super(props);

        this.itemWhConfigSearchForm         =    {
            search_warehouse_ids            :   [],
            search_manufacturer_ids         :   '', 
            search_item_ids                 :   [],
            search_item_tags                :   [],
        }

        this.itemWhConfigSearchLabel        =   {
            search_warehouse_ids            :   'Warehouses : ',
            search_manufacturer_ids         :   'Manufacturer : ',
            search_item_ids                 :   'Items : ',
            search_item_tags                :   'Item Tags : ',
        }        

        this.state              =   {
            access_token            :   '',
            configList              :   [],
            listing_loading         :   false,
            listing_meta            :   null,
            modalType               :   "",
            allItemTags             :   [],
            all_warehouses          :   [],
            manufactures            :   [],
            params                  :   {},    
            saveFormSubmitting      :   false,  
            searchedItems           :   [],  
            itemWhConfigSearchForm  :   {...this.itemWhConfigSearchForm},
            submittedItemWhFilterForm   :   {...this.itemWhConfigSearchForm},
            formSearchedElems       :   [],
        }
        this._per_page          =   15;
        this.itemWhBulkModal    =   React.createRef();

        this.itemWhListUrl    =   INVENTORY_API_BASE_URL_2 + '/item_config/list';
        this.itemWhFormUrl    =   INVENTORY_API_BASE_URL_2 + '/item_config/form_data';


    }

    componentDidMount() {
        this.initializeData(this.props)
        this.getConfigListing(1);
        this.itemWhSearchModal  =    new Modal(document.getElementById('itemWhSearchModal'), {keyboard: false, backdrop :false});
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            HttpAPICall.withAthorization('GET', this.itemWhFormUrl, this.props.access_token, {}, {}, (res) => {
                this.setState({
                    all_warehouses              :   pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    manufactures                :   res.data && res.data.manufacturers && res.data.manufacturers.length > 0  ? res.data.manufacturers.map((m) => { return {value: m.id, label: m.name}}) : [],
                    allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                });
            }, (err) => {
            });
        }
    }

    getConfigListing = (page=1, paramsData=null) => {
        this.setState({ listing_loading: true });
        const params        =   {page:page, per_page: this._per_page, ...this.state.submittedItemWhFilterForm};
        HttpAPICall.withAthorization('GET', this.itemWhListUrl, this.props.access_token, params, {}, (res) => {
            this.setState({
                configList      :   res.data.data,
                listing_meta    :   {
                    from                        :   res.data.meta.from,
                    to                          :   res.data.meta.to,
                    total                       :   res.data.meta.total,
                    last_page                   :   res.data.meta.last_page,
                    per_page                    :   res.data.meta.per_page,
                    current_page                :   res.data.meta.current_page
                },
            });
        }).then(() => this.setState({ listing_loading: false }));

    }

    itemWhBulkUploadInit    =   (type)  => {
        if (type === "filter") {
            this.itemWhSearchModal.show();
        } else if(type === "upload") {
          this.setState(
            {
              modalType: type,
            },
            () => {
              this.itemWhBulkModal.current.showModal();
            }
          );
        }else if(type === "download") {
            this.itemWhExcelDownload();
        }
    }

    itemWhExcelDownload      =  ()  =>{
        this.props.afterDownloading(true);
        HttpAPICall.withAthorization('GET', this.itemWhListUrl, this.props.access_token, {...this.state.itemWhConfigSearchForm, "download" : "download"} , {} , (res) => {
            download.file(res.data.file_path)
            this.props.afterDownloading(false)

        })
        .then(() => this.props.afterDownloading(false))
        .catch(err => this.props.afterDownloading(false));
    }

    itemWhFilterFormSubmit              =   (e)  =>  {
        e && e.preventDefault()
        const serachFormKeys              =   Object.keys(this.state.itemWhConfigSearchForm);
        
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.itemWhConfigSearchLabel[key];
            let value                       =   this.state.itemWhConfigSearchForm[key];
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
           if(label) {
                let show_val            =   value;
                if(key === 'search_warehouse_ids') {
                    show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key === 'search_item_ids') {
                    show_val            =   this.state.searchedItems ? this.state.searchedItems.map(s => s.display_label).join(', ') : '';
                }
                if(key === 'search_item_tags') {
                    show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key === 'search_manufacturer_ids') {
                    show_val            = this.state.manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                searchedElems.push(label + `<b>${show_val}</b>`);
            }
        }
    });
        this.setState({
            submittedItemWhFilterForm   :   {...this.state.itemWhConfigSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.itemWhSearchModal.hide();
            this.getConfigListing(1);  
        }); 
    }

    itemWhSearchModalInit        =       ()      =>      {
        this.itemWhSearchModal.show()
    }

    itemWhSearchClear           =       ()      =>      {
        this.setState({
            itemWhConfigSearchForm        :   {...this.itemWhConfigSearchForm},
            submittedItemWhFilterForm     :   {...this.itemWhConfigSearchForm},
            formSearchedElems             :   []
        }, () => {
            this.itemWhSearchModal.hide();
            this.getConfigListing(1)
        });
    }

    itemWhConfigSearchModal =   ()  =>  {
        return (
          <div className="modal fade" id="itemWhSearchModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="itemWhSearchModalLabel">
                    Item Warehouse Config Search
                  </h5>
                  <button
                    type="button"
                    disabled={this.state.saveFormSubmitting}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form
                  onSubmit={this.itemWhFilterFormSubmit}
                  id="itemWhBulkUploadForm"
                  method="post"
                  encType="multipart/form-data"
                >
                  <div className="modal-body">
                    <div className="row my-3 align-items-center">
                      <label className="col-sm-3 col-form-label text-end">
                        Item
                      </label>
                      <div className="col-sm-6">
                        <InputItemSearch
                          placeholder="Search Item"
                          isMulti={true}
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "itemWhConfigSearchForm",
                              "search_item_ids"
                            );
                            this.setState(
                              {
                                searchedItems: selectedOption
                                  ? selectedOption
                                  : null,
                              },
                              () => {}
                            );
                          }}
                          value={
                            this.state.searchedItems &&
                            this.state.searchedItems.length > 0
                              ? this.state.searchedItems.map((a) => {
                                  a.label = a.display_label;
                                  return a;
                                })
                              : []
                          }
                        />
                      </div>
                    </div>

                    <div className="row my-3 align-items-center">
                      <label className="col-sm-3 col-form-label text-end">
                        Item Tags
                      </label>
                      <div className="col-sm-6">
                        <TapSelect
                          options={this.state.allItemTags}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "itemWhConfigSearchForm",
                              "search_item_tags"
                            )
                          }
                          value={this.state.allItemTags.filter((u) =>
                            this.state.itemWhConfigSearchForm?.search_item_tags?.includes(
                              u.value
                            )
                          )}
                          isSearchable={true}
                          isMulti={true}
                          isClearable={true}
                          placeholder="Select Item Tags"
                        />
                      </div>
                    </div>

                    <div className="row my-3 align-items-center">
                      <label className="col-sm-3 col-form-label text-end">
                        Manufacturer
                      </label>
                      <div className="col-sm-6">
                        <TapSelect
                          options={this.state.manufactures}
                          changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                              selectedOption,
                              "itemWhConfigSearchForm",
                              "search_manufacturer_ids"
                            );
                          }}
                          isSearchable={true}
                          isClearable={true}
                          isMulti={true}
                          value={this.state.manufactures.filter((s) =>
                            this.state.itemWhConfigSearchForm.search_manufacturer_ids?.includes(
                              s.value
                            )
                          )}
                          placeholder="Select Manufacturer"
                        />
                      </div>
                    </div>

                    <div className="row my-3 align-items-center">
                      <label className="col-sm-3 col-form-label text-end">
                        Warehouse
                      </label>
                      <div className="col-sm-6">
                        <TapSelect
                          options={this.state.all_warehouses}
                          changeEvent={(selectedOption) =>
                            this.tapSelectChange(
                              selectedOption,
                              "itemWhConfigSearchForm",
                              "search_warehouse_ids"
                            )
                          }
                          value={this.state.all_warehouses.filter((u) =>
                            this.state.itemWhConfigSearchForm?.search_warehouse_ids?.includes(
                              u.value
                            )
                          )}
                          isSearchable={true}
                          isMulti={true}
                          isClearable={true}
                          placeholder="Select Warehouses"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      disabled={this.state.saveFormSubmitting}
                      className="btn btn-secondary mx-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      disabled={this.state.saveFormSubmitting}
                      className="btn btn-primary"
                    >
                      Submit{" "}
                      {this.state.saveFormSubmitting ? (
                        <TapIcon.FontAwesomeIcon
                          icon={TapIcon.faSyncAlt}
                          className="fa-spin"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
    }


    render          =   ()  =>  {
        return (
          <Ax>
          {this.itemWhConfigSearchModal()}
            <div className="bg-white tab_content_header" style={{height: "81vh"}}>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.itemWhSearchClear}   />
                <table className="table table-hover table-bordered bg-white ">
                    <thead className="table-secondary">
                        <tr className="text-center">
                        <th style={{ width: "20%" }} className="text-center">
                            Item
                        </th>
                        <th style={{ width: "10%" }} className="text-center">
                            Item Code
                        </th>
                        <th style={{ width: "20%" }} className="text-start">
                            Warehouse
                        </th>
                        <th style={{ width: "10%" }}>Bin Location</th>
                        <th style={{ width: "10%" }}>Max Level</th>
                        <th style={{ width: "10%" }}>Min Level</th>
                        <th style={{ width: "10%" }}>Re-order Level</th>
                        <th style={{ width: "10%" }}>Replenishment Period</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.listing_loading ? (
                        <tr>
                            <td className="text-center" colSpan="8">
                            <Loader />
                            </td>
                        </tr>
                        ) : (
                        this.state.configList.map((i, k) => {
                            return i.warehouses && i.warehouses.length > 0 ? (
                            i.warehouses.map((w, wk) => {
                                return (
                                <tr key={wk}>
                                    <td>{i.name}</td>
                                    <td>{i.item_code}</td>
                                    <td>{w.name}</td>
                                    <td>
                                    {w.item_config
                                        ? w.item_config.bin_location
                                        : null}
                                    </td>
                                    <td>
                                    {w.item_config
                                        ? w.item_config.max_inventory_level
                                        : null}
                                    </td>
                                    <td>
                                    {w.item_config
                                        ? w.item_config.min_inventory_level
                                        : null}
                                    </td>
                                    <td>
                                    {w.item_config
                                        ? w.item_config.re_order_level
                                        : null}
                                    </td>
                                    <td>
                                    {w.item_config
                                        ? w.item_config.replenishment_period
                                        : null}
                                    </td>
                                </tr>
                                );
                            })
                            ) : (
                            <tr>
                                <td className="text-center" colSpan="8">No Records</td>
                            </tr>
                            );
                        })
                        )}
                    </tbody>
                </table>
                {this.state.listing_meta 
                    ? <DisplayListPagination meta={this.state.listing_meta} onPageChange={(e) => this.getConfigListing(e.selected + 1)}/>
                    : null
                }
            </div>
            <ItemWhConfigModal ref={this.itemWhBulkModal} afterExcelUpload={() => this.getConfigListing(1)}/>
          </Ax>
        ); 
    }

}


const mapStateToProps = (state) => {
    return {
        access_token        : state.auth.access_token,
        isc_configuration   :
            state.app && state.app.isc_configuration
                ? state.app.isc_configuration
                : null,
        item_tags           :   state.app && state.app.item_tags ? state.app.item_tags : [],
        all_warehouses      :   state.app && state.app.warehouses ? state.app.warehouses : [],
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemWarehouseConfigList);
