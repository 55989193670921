import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria } from "../../../components/TapUi/index";
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { Helmet } from 'react-helmet';
import DateService from '../../../services/DateService';

class ShiftWiseAssetProductivityAnalysis extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        // this.reportUrl                      =   process.env.REACT_APP_IAM_API_SERVER + '/report/shift_wise_productivity_analysis';

        this.reportUrl                      =   IAM_API_BASE_URL_2 + '/report/shift_wise_productivity_analysis';


        this.reportFilterFormInitialState   =   {
            site_group_ids                      :   [],
            search_site_ids                     :   [],
            asset_group_ids                     :   [],
            search_asset_type_ids               :   [],
            search_asset_matrix_ids             :   [],
            search_manufacturer_ids             :   [],
            search_models                       :   [],
            search_variant                      :   [],
            search_asset_ids                    :   [],
            search_asset_type                   :   '',
            search_owner_id                     :   '',
            search_ownership_category           :   '',
            roster_id                           :   '',
            date                                :   ''
        };
        
        this.reportFilterFormLabel          =   {
            site_group_ids                      :   'Site Groups : ',
            search_site_ids                     :   'Sites : ',
            asset_group_ids                     :   'Asset Type Groups : ',
            search_asset_type_ids               :   'Asset Type : ',
            search_asset_matrix_ids             :   'Asset Matrix : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            search_models                       :   'Models : ',
            search_variant                      :   'Variants : ',
            search_asset_ids                    :   'Assets : ',
            search_asset_type                   :   'Asset Type : ',
            search_asset_category               :   'Asset Category : ',
            search_owner_id                     :   'Owner Name : ',
            search_ownership_category           :   'Ownership Category : ',
            roster_id                           :   'Roster Name : ',
            date                                :   'Date : '
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null, 
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],
            iam_asset_matrix                    :   [],
            iam_manufactures                    :   [],
            iam_asset_variants                  :   [],
            iam_models                          :   [],
            searchedAssets                      :   [],
            formSearchedElems                   :   [],
            allOwnershipCategory                :   [],
            allOwnersList                       :   [],
            allSiteGroupsList                   :   [],
            allAssetTypesGroupsList             :   [],
            rostersList                         :   [],
            reportData                          :   null,                  
        };
    }
    
    componentDidMount(){
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props); 
        },1000 );
       
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {

        const report_name   =   'shift_wise_asset_running';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })

        
        HttpAPICall.withAthorization('GET',this.reportUrl, this.props.access_token, {action:"formData"}, null, (response) => {

            const resp      =   response.data; 

            this.setState({
                access_token                :   props.access_token,
                formDataLoaded              :   true,
                iam_asset_types             :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                iam_user_sites              :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                iam_asset_matrix            :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
                iam_manufactures            :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
                iam_asset_variants          :   props.iam_asset_variants ? props.iam_asset_variants.map((v) => { return {value: v, label: v}}) : [],
                iam_models                  :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
                allAssetTypesGroupsList     :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],

                allOwnershipCategory        :   resp.ownership_category && resp.ownership_category.length > 0 ? resp.ownership_category.map(oc => {return({value:oc.key,label : oc.name})}) : [],
                allOwnersList               :   resp.owners && resp.owners.length > 0 ? resp.owners.map(oc => {return({value:oc.id,label : oc.name})}) : [],
                rostersList                 :   resp.rosters && resp.rosters.length > 0 ? resp.rosters.map(ro => {return({value:ro.id,label : ro.roster_name})}) : [],

                allSiteGroupsList           :   props.site_groups  &&  props.site_groups.length > 0 ?  props.site_groups.map(s => {return({value:s.id,label : s.name,linked_sites : s.site_ids})}) : [],
            }, () => {
                let status = [];
                let ParamObject     = new URLSearchParams(this.props.location.search);
                let search_site_ids = [];
                let updateFilterForm={}
                
                if (ParamObject.get('status') || ParamObject.get('search') || ParamObject.get('search_site_ids') ) {
                    status = ParamObject.get('status') ? ParamObject.get('status') : [];
                    if (ParamObject.get('search_site_ids')) {
                        let site = this.state.iam_user_sites.find(us => us.value == ParamObject.get('search_site_ids'));
                        search_site_ids = [site.value];
                        
                    }
                    setTimeout(() => {
                        this.setState({
                            reportFilterForm        :       {
                                ...this.state.reportFilterForm,
                                ...updateFilterForm,
                                search_site_ids     :       search_site_ids,
                            }
                        }, () => {
                            this.reportFilterFormSubmit()
                        });
                    },1500);
                }else {
                    this.reportFilterhandler()
                }                    
            });
        });
    }
    
    reportFilterFormSubmit                  =   (event=null,merge_list=false)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
       
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
       
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
            
                itemSearchParams[key]       =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_owner_id') {
                        show_val            = this.state.allOwnersList.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'search_ownership_category') {
                        show_val            = this.state.allOwnershipCategory.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date') {
                        show_val            =   DateService.dateTimeFormat(this.state.reportFilterForm.date, 'DD-MMM-YYYY')   
                    }
                    if(key == 'roster_id') {
                        show_val            =   this.state.rostersList.filter(ro => this.state.reportFilterForm.roster_id === ro.value).map(r => r.label);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(0,merge_list);
        });
    }

    loadListingTblData                      =   (page=0,merge_list=false) => {
        this.setState({
            listingData                 :   merge_list == true ? [] : this.state.listingData,
            listingMeta                 :   merge_list == true ? null : this.state.listingMeta,
            listing_loading             :   true,
            reportDownloadBtn           :   false,
            reportDownloading           :   false,
        });
       let params                       =   {from:page ,...this.state.submittedReportFilterForm,action:"data"};   

         //Api to call Lisitng 
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params , {} , (response) => {
            let respData                =   response.data;
            this.setState({
                listingData         :   merge_list == true ? respData.data : [...this.state.listingData, ...respData.data],
                reportDownloadBtn   :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
           
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    loadMoreList                    =   ()  =>  {
        if(this.state.listingMeta && this.state.listingMeta.next == true) {
            this.loadListingTblData(this.state.listingMeta.till);
        }
    }


    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, action : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.push('/report');
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">

                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={(e) => this.reportFilterFormSubmit(e,true)} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {
                               
                               this.setState({
                                 reportFilterForm     :   {
                                     ...this.state.reportFilterForm,
                                     site_group_ids             :   selectedOption &&  selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                     search_site_ids            :   selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                 }
                               })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_matrix_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.reportFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.reportFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            search_asset_type={[this.state.reportFilterForm.search_asset_type]}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>              
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Ownership</label>
                    <div className="col-sm-6">
                        <div className='row'>
                            <div className='col-sm-5'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnershipCategory}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_ownership_category');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnershipCategory.find(r => r.value === this.state.reportFilterForm.search_ownership_category)}
                                    placeholder="Select Ownership Category"
                                />
                            </div>
                            <div className='col-sm-7'>
                                <TapSelect
                                    menuPlacement='top'
                                    options={this.state.allOwnersList}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_owner_id');
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={this.state.allOwnersList.find(r => r.value === this.state.reportFilterForm.search_owner_id)}
                                    placeholder="Select Owner"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Roster</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.rostersList}
                            changeEvent={(selectedOption) => {
                               this.setState({
                                 reportFilterForm     :   {
                                    ...this.state.reportFilterForm,
                                    roster_id  :   selectedOption ? selectedOption.value : '',
                                 }
                               })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            required={true}
                            value={this.state.rostersList.find(ro => this.state.reportFilterForm.roster_id == ro.value)}
                            placeholder="Select Roster"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end require">Date</label>
                    <div className="col-sm-6">
                        <DatePicker
                            selected={this.state.reportFilterForm.date
                                        ? moment(this.state.reportFilterForm.date, 'YYYY-MM-DD').toDate()
                                        : false
                                    }
                            onChange={(value, event) => {
                                this.formDateHandler('date', value, 'reportFilterForm', 'YYYY-MM-DD')
                            }}
                            dateFormat="dd-MMM-yyyy"
                            className="form-control"
                            autoComplete="off"
                            required
                            showMonthDropdown
                            showYearDropdown
                            scrollMonthYearDropdown
                            placeholderText={`Select Date`}
                        />
                    </div>
                    {/* <div className="col-sm-3"></div>
                    <div className='col-sm-3 text-end text-xs form-text'>
                            <sup>#</sup>{this.state.notes}
                    </div> */}
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
     
            {/* { this.state.listing_loading
                ? <Loader /> */}
             {this.state.listingMeta && this.reportTableJsx()
            }
             <div className='bg-white text-center'>
                {this.state.listing_loading
                    ? (<Loader />)
                    : (null)}
            </div>
        </div>)
    }
    
    SecondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
          
            <table className="table table-bordered table-fixed table-sm align-middle bg-white my-2">
                <thead className="align-middle table-secondary">
                    <tr>
                    <th scope="col" style={{ width: "5%" }} >S.No</th>
                        <th scope="col" style={{ width: "15%" }}>Asset</th>
                        <th scope="col" style={{ width: "11%" }}>Asset Type</th>
                       <th scope="col" style={{ width: "15%" }}>Manufacturer Name</th>
                       <th scope="col" style={{ width: "12%" }}>Model Name</th>
                        <th scope="col" style={{ width: "8%" }}>Hr Running</th>
                        <th scope="col" style={{ width: "8%" }}>KM Running</th>
                       <th scope="col" style={{ width: "8%" }}>Productivity</th>
                       <th scope="col" style={{ width: "8%" }}>Trips</th>
                        <th scope="col" style={{ width: "8%" }}>Downtime</th>
                        <th scope="col" style={{ width: "8%" }}>Fuel</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {
                           
                            return (<Ax>
                                <tr key={index}>
                                <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td>{data.name ? data.name : "-"}</td>
                                    <td>{data.asset_type_name ? data.asset_type_name : "-"}</td>
                                    <td>{data.manufacturer_name ? data.manufacturer_name : "-"}</td>
                                    <td>{data.model_name ? data.model_name : "-"}</td>
                                    <td className='text-end'> {data.reading && data.reading.find(rd => rd.key === 'hour_meter') ? (
                                         <span>
                                         {data.reading.find(rd => rd.key === 'hour_meter').running_display}
                                         {data.reading.find(rd => rd.key === 'hour_meter').running_display && ' '}
                                         {data.reading.find(rd => rd.key === 'hour_meter').running_display && data.reading.find(rd => rd.key === 'hour_meter').unit}
                                       </span>
                                    ) : (
                                        "NA"
                                    )}
                                    </td>
                                    <td className='text-end'> {data.reading && data.reading.find(rd => rd.key === 'odo_meter') ? (
                                         <span>
                                         {data.reading.find(rd => rd.key === 'odo_meter').running_display}
                                         {data.reading.find(rd => rd.key === 'odo_meter').running_display && ' '}
                                         {data.reading.find(rd => rd.key === 'odo_meter').running_display && data.reading.find(rd => rd.key === 'odo_meter').unit}
                                       </span>
                                    ) : (
                                        "NA"
                                    )}
                                    </td>
                                    <td  className='text-end'>{data.productivity ? data.productivity : ""}</td>
                                    <td className='text-end'>{data.trips ? data.trips : "-"}</td>
                                    <td className='text-end'>{data.downtime ? this.SecondsToHHMM(data.downtime) : ""}</td>
                                    <td className='text-end'>{data.fuel_issue ? data.fuel_issue : "-"}</td>
                                    
                                   
                                </tr>
                            </Ax>)
                        }))
                        : <tr><td className="text-center" colSpan={9}>No Record Found</td></tr>
                    }
                </tbody>
            </table>
           
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Shift Wise Asset Running Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.reportSearchClear} />
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                            : <Loader />
                        }


                        {this.state.listingMeta
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null}

                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        fuel_stations           :   state.app && state.app.fuel_stations ? state.app.fuel_stations : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        site_groups             :   state.app && state.app.group_sites ? state.app.group_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [], 
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ShiftWiseAssetProductivityAnalysis);