import React from 'react';
import tapIcon from "../../../services/TapIcon";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssetAttributeList from "./AssetAttributeList";
import {Modal} from "bootstrap";
import TapSelect from "../../../components/ui/TapSelect";
import Ax from "../../../components/hoc/Ax";


class AssetsConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
             add               :         false,
            options            :         ['Option 1','Option 2'],
            selectedAttribute          :   {value: 'dropdown', label: 'Dropdown'},
        }

    }

    handleClose(){
        this.setState({add : false})
    }

    handleAdd(){
        this.setState({add: true})
    }

    componentDidMount() {
        this.addStatusModal        =        new Modal(document.getElementById('addStatusModal'), {keyboard: false, backdrop :false});
        this.addOwnerModal         =        new Modal(document.getElementById('addOwnerModal'), {keyboard: false, backdrop :false});
        this.addSitesModal          =        new Modal(document.getElementById('addSiteModal'), {keyboard: false, backdrop :false});
    }

    //function for add Status modal
    addStatusModalInit                 =   (id)  =>  {
        //Initialize Search Form :-
        this.addStatusModal.show();

    }

    addSiteInit         =       ()      =>      {
        this.addSitesModal.show();
    }

     //function for add  modal
     addOwnerModalInit                 =   (id)  =>  {
        //Initialize Search Form :-
        this.addOwnerModal.show();
    }

    handleOptionValue(e, i) {
        let options = [...this.state.options]
        options[i] = e.target.value
        this.setState({
            options
        })
    }

    handleDeleteOption = i => e => {
        e.preventDefault()
        if (i > 0) {
            let options = [
                ...this.state.options.slice(0, i),
                ...this.state.options.slice(i + 1)
            ]
            this.setState({
                options
            })
        }
    }

    addOption = e => {
        e.preventDefault()
        let options = this.state.options.concat([''])
        this.setState({
            options
        })
    }


    addOwnerModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addOwnerModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add Owner</h5>
                        </div>
                        <form>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Type </label>
                                    </div>
                                    <div className="col-9">
                                        <TapSelect
                                            value={this.state.selectedAttribute}
                                        />
                                    </div>
                                </div>
                                {
                                    this.state.selectedAttribute.value === "dropdown" ?
                                        <Ax>
                                            <div className=" row">
                                                <div className="col-3">

                                                </div>
                                                <div className="col-9 pb0">
                                                    {this.state.options.map((value, index) => {
                                                        return <div>
                                                            {
                                                                this.state.options.length > 2 ?
                                                                    <div className="row mb-3">
                                                                        <div className="col-10">
                                                                            <span key={index}>
                                                                                <input
                                                                                    name={value}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={value}
                                                                                    placeholder="Please Enter Options"
                                                                                    onChange={(e) => this.handleOptionValue(e, index)}
                                                                                />

                                                                            </span>
                                                                        </div>
                                                                        <div className="col-2 del-option">
                                                                            {this.state.options.length > 2 ?
                                                                                <span className="mt-1">
                                                                                    <button className="btn btn-secondary"
                                                                                        onClick={this.handleDeleteOption(index)}>X</button>
                                                                                </span>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="row mb-3">
                                                                        <div className="col-12">
                                                                            <span key={index}>
                                                                                <input
                                                                                    name={value}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={value}
                                                                                    placeholder="Please Enter Options"
                                                                                    onChange={(e) => this.handleOptionValue(e, index)}
                                                                                />

                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                            }
                                                        </div>
                                                    })}
                                                </div>

                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-3">

                                                </div>
                                                <div className="col-9">
                                                    <span className="option_add_link" onClick={this.addOption}>Add New Option</span>
                                                </div>

                                            </div>
                                        </Ax> : null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    addStatusModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addStatusModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add Status</h5>
                        </div>
                        <form>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Status Name </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="name"
                                            type="text"
                                            // value={this.state.addStatus.name}
                                            className="form-control"
                                            placeholder="Enter Status Name"
                                            // onChange={this.handleStatusChange}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Role </label>
                                    </div>
                                    <div className="col-9">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                          /*  isMulti = {true}*/
                                            // value={this.state.selectedRoles}
                                            placeholder="Please Select Role"
                                        />
                                        <div className="mt1">
                                            <span className="role-info-icon"><tapIcon.FontAwesomeIcon icon={tapIcon.faInfoCircle}/></span>
                                            <small className="role-info">
                                                   Only selected roles can change the status of Assets to this status
                                                </small>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Notes </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            name="notes"
                                            className="form-control"
                                            // value={this.state.addStatus.notes}
                                            placeholder="leave a note"
                                            // onChange={this.handleStatusChange}
                                            // disabled={this.state.canEdit === false && this.state.edit === true ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    addSiteModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addSiteModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add Region</h5>
                        </div>
                        <form>
                            <div className="modal-body">
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Region Name </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            name="name"
                                            type="text"
                                            // value={this.state.addStatus.name}
                                            className="form-control"
                                            placeholder="Enter Region Name"
                                        // onChange={this.handleStatusChange}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-3">
                                        <label htmlFor="name" className="form-label">Sites </label>
                                    </div>
                                    <div className="col-9">
                                        <TapSelect
                                            isSearchable={true}
                                            isClearable={true}
                                            /*  isMulti = {true}*/
                                            // value={this.state.selectedRoles}
                                            placeholder="Please Select Sites"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

   
    render() {

        return (
            <section className="item_view_container bg-white">
                <ToastContainer />
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details"
                            type="button" role="tab" aria-controls="details" aria-selected="true">Details
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link " id="additional-tab" data-bs-toggle="tab" data-bs-target="#additional"
                            type="button" role="tab" aria-controls="additional" aria-selected="true">Additional Attribute
                        </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <button className="nav-link " id="region-tab" data-bs-toggle="tab" data-bs-target="#region"
                            type="button" role="tab" aria-controls="region" aria-selected="true">Region
                        </button>
                    </li> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane  show active" id="details" role="tabpanel"
                        aria-labelledby="details-tab">
                        <div className="tab_content_header">
                            <div className="tab_content_wrapper mb-3">
                                <div>
                                    <span className="content_heading">Status</span>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-primary" onClick={this.addStatusModalInit} >
                                        Add
                                    </button>
                                </div>
                            </div>
                            <table className="table table-hover table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="table-head-row" style={{ width: "16%" }}>Status</th>
                                        <th scope="col" className="table-head-row" style={{ width: "35%" }}>Roles</th>
                                        <th scope="col" className="table-head-row" style={{ width: "35%" }} >Notes</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <tr>
                                        <td scope="row">Draft</td>
                                        <td scope="row">Administrator</td>
                                        <td scope="row">
                                            <div className="row">
                                                <div className="col-10">
                                                    Note
                                                </div>
                                                <div className="col-2 text-center">
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                        alt="action-icon"
                                                        className="img-fluid pr10 pl10"
                                                        data-bs-toggle="dropdown" href="#"
                                                        role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item">
                                                            Edit
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                            <div className="tab_content_wrapper mb-3">
                                <div>
                                    <span className="content_heading">Owner Category</span>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-primary" onClick={this.addOwnerModalInit} >
                                        Add
                                    </button>
                                </div>
                            </div>
                            <table className="table table-hover table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="table-head-row" style={{ width: "10%" }}>S.No</th>
                                        <th scope="col" className="table-head-row">Owner Name</th>
                                        <th scope="col" className="table-head-row" style={{ width: "10%" }} >Action</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <tr>
                                        <td scope="row">S.No</td>
                                        <td scope="row">Owner Names</td>
                                        <td scope="row">
                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                alt="action-icon"
                                                className="img-fluid pr10 pl10"
                                                data-bs-toggle="dropdown" href="#"
                                                role="button" aria-expanded="false" />
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">
                                                    Edit
                                                </li>
                                            </ul>
                                        </td></tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="tab-pane " id="additional" role="tabpanel" aria-labelledby="additional-tab">
                        <div className="tab_content_header">
                           
                            <AssetAttributeList openForm={this.state.add} closeForm={this.handleClose.bind(this)} />
                        </div>
                    </div>
                    <div className="tab-pane " id="region" role="tabpanel" aria-labelledby="region-tab">
                        <div className="tab_content_header">
                            <div className="tab_content_wrapper">
                                <span className="content_heading">Region </span>
                                <div className="float-end"><button className="btn btn-primary" onClick={() => this.addSiteInit()} >Add Region</button></div>
                            </div>
                            <table className="table table-hover table-bordered my-3 ">
                                <thead>
                                    <tr className='table-secondary'>
                                        <th scope="col" style={{ width: "10%" }}>S.No</th>
                                        <th scope="col" style={{ width: "30%" }}>Region Name</th>
                                        <th scope="col" style={{ width: "50%" }} >Sites</th>
                                        <th scope="col" style={{ width: "10%" }} >Action</th>
                                    </tr>

                                </thead>
                                <tbody className="table-body">
                                    <tr>
                                        <td>1</td>
                                        <td>Region</td>
                                        <td>Sites</td>
                                        <td> <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10"
                                            data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"
                                        />
                                            <ul className="dropdown-menu">
                                                <li className="dropdown-item">Edit</li>

                                            </ul></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {this.addStatusModalJsx()}
                {this.addOwnerModalJsx()}
                {this.addSiteModalJsx()}
            </section>
        )
    }
}


export default AssetsConfiguration;