import React from 'react';
import Select from 'react-select';
import Ax from '../../components/hoc/Ax';
import ReactSelectStyle from './ReactSelectStyle';

class TapSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOption: null
        }
    }

    customStyles = {
        container: (provided) => ({
            ...provided,
            ...(this.props.containerHeight && { minHeight: this.props.containerHeight }),
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '0px',
            margin: '0px',
            padding: '10px 16px',
            borderRadius: '0px',
            'opacity': 1,
            zIndex: 999,
            ...(this.props.fontSize && { fontSize: this.props.fontSize }),
        }),
        control: (provided, { selectProps: { width, isDisabled } }) => ({
            ...provided,
            width: width,
            borderRadius: '0px',
            backgroundColor: isDisabled ? '#e9ecef' : '#fff',
            border: '1px solid #ececec',
            padding: '0px',
            minHeight: this.props.containerHeight ? this.props.containerHeight : '40px',
            ...(this.props.containerHeight && { minHeight: this.props.containerHeight }),
            ...(this.props.fontSize && { fontSize: this.props.fontSize }),
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return {
                ...provided,
                opacity,
                transition,
                color: '#000'
            };
        },
        multiValue: (provided, state) => {
            return {
                ...provided,
                display: 'inline-flex'
            };
        },
        'dropdownIndicator': (provided, state) => {
            return {
                padding: '0px 5px'
            }
        },
        'clearIndicator': (provided, state) => {
            return {
                padding: '0px 5px'
            }
        },
        'valueContainer': (provided, state) => {
            return {
                padding: '0px 5px'
            }
        },
    };

    render() {
        let isMulti = this.props.isMulti || false;
        let value = this.props.value || null;
        return (
            <Ax>
                <Select
                    menuPlacement={this.props.menuPlacement || 'bottom'}
                    styles={ReactSelectStyle(this.props)}
                    width={this.props.width || '100%'}
                    options={this.props.options}
                    isSearchable={this.props.isSearchable || false}
                    isDisabled={this.props.isDisabled || false}
                    isClearable={this.props.isClearable || false}
                    required={this.props.required || false}
                    isMulti={isMulti}
                    autoFocus={this.props.autoFocus || false}
                    defaultValue={this.props.defaultValue || null}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    onChange={this.props.changeEvent}
                    value={value}

                    placeholder={this.props.placeholder || 'select'}
                />
                <input
                    name=''
                    tabIndex={-1}
                    required={this.props.required || false}
                    value={isMulti ? (value.length > 0 ? value[0].label : [].join(' ')) : (value ? value.label : '')}
                    onChange={() => { }}
                    style={{
                        opacity: 0,
                        width: 0,
                        height: 0,
                        padding: 0,
                        margin: 0,
                        display: 'table',
                        textAlign: "start"
                    }}
                />
            </Ax>
        );
    };
}

export default TapSelect;