import React from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { Collapse } from 'bootstrap';
import { Modal } from 'bootstrap';
import {Link} from "react-router-dom";
import TapSelect from '../../../../components/ui/TapSelect';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import TapIcon from '../../../../services/TapIcon';
import AssetDetailHorizontalCard from '../../includes/AssetDetailHorizontalCard';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../../components/TapUi';
import AssetHeader from '../../includes/AssetHeader';
import moment from 'moment';
import swal from "sweetalert";
import CounterlogModal from '../CounterlogModal';
import ShiftSummaryDetail from '../ShiftSummaryDetail';
import Document from '../ShiftActivityModals/Document';
import FuelBalance from '../ShiftActivityModals/FuelBalance';
import { Helmet } from 'react-helmet';
import ServiceChecksheet from '../ShiftActivityModals/ServiceChecksheet';
import Downtime from '../ShiftActivityModals/Downtime';
import Productivity from '../ShiftActivityModals/Productivity';
import Activity from '../ShiftActivityModals/Activity';
import Expense from '../ShiftActivityModals/expense/Expense';
import FuelFeedIn from '../ShiftActivityModals/FuelFeedIn';
import download from '../../../../services/DownloadFile';
import Taskflow from '../ShiftActivityModals/Taskflow';
import TaskSaveModal from '../ShiftActivityModals/task/TaskSaveModal';
import EngagementTime from '../ShiftActivityModals/EngagementTime';

class Logsheet extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.state                  =   {
            listing_loading             :   false,
            asset_loading               :   false,
            assetDetail                 :   null,
            assetId                     :   '',
            current_page                :   1,
            logsheet_listing            :   [],
            displayShiftSummary         :   false,
            listingMeta                 :   null,
            shiftSummary                :   null,
            start_date                  :   null,
            end_date                    :   null,
            selectDateRange             :   {from : "", to : ""},
            currentTransactionId        :   null,
            file_downloading            :   false,
            reportDownloading           :   false,
            saveFormSubmitting          :   false,
            customDownloadForm          :   {file_name : ''},
            counterlog_header           :   [],
            customDownloadCriteria                       :       [
                {key : "site_name" , label : "Site Name",value : false},
                {key : "daily_fuel_consumption" , label : "Daily Fuel Consumption & Economy",value : false},
                {key : "scs" , label : "SCS",value : false},
                {key : "documents" , label : "Documents",value : false},
                {key : "trips_sessions" , label : "Trips/Sessions",value : false},
                {key : "productivity" , label : "Productivity",value : false},
                {key : "operator_name" , label : "Operator Name",value : false},
                {key : "counterlog_entry_details" , label : "Counterlog Entry Details",value : false},
                {key:"task",label:"Task",value:"task"}
            ]
        }
        
        this.CounterLogModalRef           =   React.createRef();
        this.uploadDocumentRef            =   React.createRef();
        this.fuelOpeningBalanceRef        =   React.createRef();
        this.serviceChecksheetRef         =   React.createRef();
        this.logDowntimeRef               =   React.createRef();
        this.logProductivityRef           =   React.createRef();
        this.logActivityRef               =   React.createRef();
        this.logExpenseRef                =   React.createRef();
        this.logFuelFeedInRef             =   React.createRef();
        this.taskWorkflowRef              =   React.createRef();
        this.logEngagementTimeRef              =   React.createRef();
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeData(this.props);
        this.customLogsheetDownload        =   new Modal(document.getElementById('customLogsheetDownload'), {keyboard: false, backdrop :false});
        this.selectDateRangeModal       =   new Modal(document.getElementById('selectDateRangeModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps) {
        if(this.props.match.params.id !== nextProps.match.params.id) {
            this.initalizeData(nextProps)
        }
    }
    
    initalizeData                       =   (pr) => {
        let asset_id                        =   pr.match?.params?.id;
        if(asset_id) {
            this.setState({assetId: asset_id},() => {       
                this.getAsssetDetails(asset_id);
                this.loadListingTblData(this.state.current_page, asset_id);
            });
        }
    }
    
    getAsssetDetails                    =   (asset_id = null)  =>  {
        let id                              =   asset_id ? asset_id  : this.state.assetId;
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/' + id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : response.data.data});
        }).then(() => {this.setState({asset_loading: false})})
    }

    getMiniAssetDetail                    =   (asset_id = null)  =>  {
        let id                              =   asset_id ? asset_id  : this.state.assetId;
        this.setState({asset_loading : true});
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset/latest_counterlog/' + id, this.props.access_token , {}, {}, (response) => {
            this.setState({assetDetail : {...this.state.assetDetail,latest_counterlog : response.data.data}});
        }).then(() => {this.setState({asset_loading: false})})
    }
    
    paginationHandler                   =   (type) =>   {
        let new_page_number                 =   type == "prev" ? this.state.listingMeta.current_page - 1 : this.state.listingMeta.current_page + 1;
        this.loadListingTblData(new_page_number, this.state.assetId);
    }
    
    refreshLogsheet                     =   ()  =>  this.loadListingTblData(this.state.current_page, this.state.assetId, this.state.start_date, this.state.end_date)
    
    loadListingTblData                  =   (page = null, asset_id, date_range_start, date_range_end)  =>  {
        this.setState({listing_loading: true, current_page: page});
        let params                      =   {
            page                            :   page,
            date_range_start                :   page ? null : date_range_start,
            date_range_end                  :   page ? null : date_range_end 
        };
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/month_wise_asset_shifts/' + asset_id, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                listing_loading         :   false,
                logsheet_listing        :   respData.data,
                listingMeta             :   respData.meta,
                counterlog_header       : respData.active_columns && respData.active_columns.length > 0 ? (() => {
                    const totalWidth = respData.active_columns.reduce((acc, column) => acc + Number(column.width), 0);
                    const remainingWidth = 100 - Number(totalWidth);
                    const modifiedColumns = respData.active_columns.map(column => {
                        const additionalWidth = (remainingWidth / respData.active_columns.length);
                        return { ...column, width: Number(column.width) + additionalWidth };
                    });
                    return modifiedColumns.map(column => ({ ...column, selected: column.status === 1 }));
                })() : [],
                
            });
        }).then(() => this.setState({listing_loading: false}));
     
    }
    
    logsheetDownloadHandler              =    (key)     =>         {
        this.setState({ file_downloading: true});
        let params                      =   {
            page                            :   this.state.current_page,
            date_range_start                :   this.state.current_page ? null : this.state.start_date,
            date_range_end                  :   this.state.current_page ? null : this.state.end_date ,
            download                        :   key
        };
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM +  '/counterlog/month_wise_asset_shifts/' +  this.state.assetId, this.props.access_token,params,{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({file_downloading: false}));
    }
    
    assetCardHandler                    =   ()  =>  {
        new Collapse(document.getElementById('assetCard'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    selectDateRangeModalInit            =   () => {
        this.selectDateRangeModal.show()
        this.setState({selectDateRange: {from: '', to: ''}})
    }
    
    tapDateChange                       =   (selectedOption)    =>  {
        if (selectedOption && selectedOption.length > 0) {
            this.setState({ selectDateRange: {
                ...this.state.selectDateRange,
                from            :   selectedOption[0] ? selectedOption[0] : '',
                to              :   selectedOption[1] ? selectedOption[1] : '',
            }});
        }
    }
    
    submitSearchDateRangeHandler        =   (e) =>  {
        e.preventDefault();
        let start_date          =   this.state.selectDateRange && this.state.selectDateRange.from ? moment(this.state.selectDateRange.from, 'YYYY-MM-DD').format("YYYY-MM-DD") : null;
        let end_date            =   this.state.selectDateRange && this.state.selectDateRange.to ? moment(this.state.selectDateRange.to,'YYYY-MM-DD').format("YYYY-MM-DD") : null;
        if(start_date && end_date){
            this.setState({ start_date : start_date, end_date : end_date });
            this.loadListingTblData(null, this.state.assetId, start_date, end_date)
            this.selectDateRangeModal.hide();
        }else{
            toast.error('Please Select Both Dates')
        }
    }
    
    viewShiftSummary                    =   (transaction_id)  =>  {
        this.setState({displayShiftSummary : true, currentTransactionId : transaction_id});
    }
    
    closeShiftSummary                   =   (date, asset_id, isActivityPerform, isCounterLogPerform)  =>  {
        this.setState({displayShiftSummary : false, currentTransactionId : ''});
        if(isActivityPerform) {
            this.afterUpdateCounterlogSubmit(date, asset_id, isCounterLogPerform);
        }
    }
    
    updateCounterlogModalInit           =   (shiftData, date) =>  {
        this.CounterLogModalRef.current.updateCounterlogInit(shiftData, date, this.state.assetDetail)
    }
    
    afterUpdateCounterlogSubmit         =   (date, asset_id, refresh_asset_card = true) =>  {
       
        let itemList                        =   [...this.state.logsheet_listing];
        let dateIndex                       =   itemList.findIndex(i => i.date == date);
        itemList[dateIndex]                 =   {...this.state.logsheet_listing[dateIndex], isLoading : true};
        this.setState({logsheet_listing : itemList});
       
        if(refresh_asset_card == true) {
            this.getMiniAssetDetail(this.state.assetId);
        }
        let params                          =   {page : null, date_range_start : date, date_range_end : date};
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/month_wise_asset_shifts/' + this.state.assetId, this.props.access_token, params, {}, (resp) => {
            if(resp.data && resp.data.data) {
                itemList[dateIndex]         =   resp.data.data[0];
                this.setState({logsheet_listing : itemList});
            }
        });
    }
    
    closeLogsheet                       =   ()  =>  this.props.history.push('/asset/dashboard/'+this.state.assetId)
    
    uploadDocumentInit                  =   (shiftData) =>  {
        if(shiftData && shiftData.counterlog) {
            this.uploadDocumentRef.current.uploadDocumentModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    fuelOpeningBalanceInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.fuelOpeningBalanceRef.current.fuelOpeningBalanceModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    serviceChecksheetInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.serviceChecksheetRef.current.serviceChecksheetModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    logDowntimeInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logDowntimeRef.current.uploadDowntimeModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    engagemenTimeInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logEngagementTimeRef.current.uploadEngagementModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    logProductivityInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logProductivityRef.current.uploadProductivityModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    logActivityInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logActivityRef.current.uploadActivityModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    taskInit              =   (shiftData, date,task_detail=[]) =>  {
        if(shiftData && shiftData.counterlog) {
           
            if(shiftData && shiftData.counterlog) {
                let asset = this.state.assetDetail;
                let dayRosterCLData                 =   asset && asset.dayRosterClData;
                let site                            =   asset && asset.site_id ? {id:asset.site_id,name:asset.site,site_name : asset.site_name,site_code:asset.site_code} : null;
                let shift                          =   shiftData ? shiftData : null;
                
                let one_task_in_progress                =   'N';
                let shift_summary                   =   shift ? shift.shift_summary : null;
              
                if(shift_summary && shift_summary.task && shift_summary.task.enable) {
                    let display                     =   shift_summary.task.display;
                    if (display && display.length > 0 && display.find(d => d.task_in_progress == 'Y')) {
                        one_task_in_progress            =   'Y';
                    }
                       
                   this.taskWorkflowRef.current.uploadTaskModalInit(asset,site,shift,shift.counterlog,one_task_in_progress);
                } 
               
            }
            // this.taskWorkflowRef.current.uploadTaskModalInit(shiftData, shiftData.counterlog,this.state.assetDetail,task_detail);
        }
    }

    logExpenseInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logExpenseRef.current.uploadExpenseModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    logFuelFeedInInit                  =   (shiftData, date) =>  {
        if(shiftData && shiftData.counterlog) {
            this.logFuelFeedInRef.current.uploadFuelFeedInModalInit(shiftData, shiftData.counterlog, this.state.assetDetail);
        }
    }

    customDownloadInit              =       ()          =>          {
        this.customLogsheetDownload.show()
        let selectedColumn = localStorage.getItem('selectedColumnForMonthlyLogheet');
        let task_submodule_available            = this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") ? 'Y' : 'N';

        let customDownloadCriteria = this.state.customDownloadCriteria;

        if(task_submodule_available == 'N') {
            customDownloadCriteria    =   customDownloadCriteria.filter(s => s.value != 'task');
        }
    
        this.setState({
            customDownloadForm     :   {file_name :  'MonthlyLogsheet_' + this.state.assetDetail.name},
            customDownloadCriteria :  customDownloadCriteria.map(v => ({...v, value: selectedColumn && selectedColumn.length > 0 && selectedColumn.includes(v.key) ? true : false})),
        })
    }

    handleOnChangeCriteria = (event,  index) => {
        const values = [...this.state.customDownloadCriteria];
        values[index].value = event.target.checked;
        this.setState({customDownloadCriteria : values})
    };
    
    submitcustomDownloadForm        =   (e)  =>  {
        e.preventDefault()
        let downloadCriteria = this.state.customDownloadCriteria.filter(download => download.value == true) ;
        let selectedColumn = [];
        if(downloadCriteria && downloadCriteria.length > 0){
            selectedColumn = downloadCriteria.map(dc => {return(dc.key)})
        }
       
        localStorage.setItem('selectedColumnForMonthlyLogheet', selectedColumn);
       
        let file_name = this.state.customDownloadForm.file_name
        let newFileName = file_name.replace(/[^a-zA-Z0-9]/g,'_');
      
        this.setState({ file_downloading: true,saveFormSubmitting : true});
        let params                      =   {
            page                            :   this.state.current_page,
            date_range_start                :   this.state.current_page ? null : this.state.start_date,
            date_range_end                  :   this.state.current_page ? null : this.state.end_date ,
            download                        :   'download'
        };
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM +  '/counterlog/month_wise_asset_shifts/' +  this.state.assetId, this.props.access_token,{...params,selectedColumn,file_name : newFileName},{},(response) => {
            download.file(response.data.file_path);
            this.customLogsheetDownload.hide()
            this.setState({ customDownloadForm          :   {file_name : ''}})
        })
        .then(() => this.setState({file_downloading: false,saveFormSubmitting : false}));
    }

    resetDownloadCriteriaForm           =       ()      =>      {
        this.setState({customDownloadCriteria :  this.state.customDownloadCriteria.map(v => ({...v, value: false}))})
    }

    pageTitleBarJsx                     =   ()  =>  {
        return <div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <h3>{this.state?.assetDetail?.name}</h3>
                <AssetHeader assetDetail={this.state.assetDetail} afterChangeStatus={this.getAsssetDetails} parentProps={this.props}/>
            </div>
        </div>
    }
    
    logSheetSubHeadingJsx               =   ()  =>  {
        return <div className="mt-1">
            <div className="bg-white row m0 text-decoration-none d-flex align-items-center">
                <div className="col-sm-5 py-2">
                    <h5 className="fs-6 m-0">Logsheet : {this.state.listingMeta ? <span>{this.state.listingMeta.from} - {this.state.listingMeta.to}</span> : null}</h5>
                </div>
                <div className='col-sm-2 text-center align-self-start'>
                    <button type="button" className="btn btn-primary btn-sm" role="button" onClick={this.assetCardHandler}> Asset Card</button>
                </div>
                <div className='col-sm-5 text-end my-2'>
                    <button type="button" className="btn btn-secondary ms-1" disabled={this.state.listing_loading || this.state.listingMeta && this.state.listingMeta.current_page == this.state.listingMeta.last_page} onClick={(e) => this.paginationHandler('next')}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faArrowLeft} className="mx-1" />  Prev
                    </button>
                    <button type="button" className="btn btn-secondary ms-1" onClick={this.selectDateRangeModalInit} disabled={this.state.listing_loading}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faCalendarAlt} className="mx-2 cursor_pointer" color="white" />
                    </button>
                    <button type="button" className="btn btn-secondary ms-1" disabled={this.state.listing_loading || this.state.listingMeta && this.state.listingMeta.current_page == 1} onClick={(e) => this.paginationHandler('prev')}>
                        Next  <TapIcon.FontAwesomeIcon icon={TapIcon.faArrowRight} className="mx-1" />
                    </button>
                    <button type="button" className="btn btn-secondary ms-1" onClick={this.refreshLogsheet} disabled={this.state.listing_loading}>
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className={['cursor_pointer', 'img-fluid', this.state.listing_loading ? "fa-spin" : ''].join(' ')} color="white" />
                    </button>
                    <button type="button" id='actionMenuButton' disabled={this.state.listing_loading || this.state.file_downloading} data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary ms-1">
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                        {this.state.reportDownloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />) : null}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link className="dropdown-item"  onClick={this.customDownloadInit}>Download Detailed Report</Link></li>
                        <li><Link className="dropdown-item" onClick={() => this.logsheetDownloadHandler('horizontal_shift_download')}>Download Horizontal Shift Report</Link></li>
                    </ul>
                    {/* <button type="button" className="btn btn-secondary ms-1" disabled={this.state.file_downloading || this.state.listing_loading}  >
                        <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} /> {this.state.file_downloading ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button> */}
                    <button type="button" className="btn btn-secondary ms-1" onClick={this.closeLogsheet} disabled={this.state.listing_loading}>
                        <TapIcon.imageIcon icon={TapIcon.CloseIcon} className=" cursor_pointer img-fluid" color="white" />
                    </button>
                </div>
            </div>
        </div>
    }
    
    logSheetListJsx                     =   ()  =>  {
        let task_submodule_available = this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") ? 'Y' : 'N';
        
        return <Ax>
            <div className='table-responsive pb-5' style={{ maxHeight: "74vh" }}>
                <table className="table table-bordered bg-white table-hover table-sm table-fixed mb-0" id="assetLogsheetTable">
                    <thead className='table-secondary text-center'>
                        {this.state.counterlog_header && this.state.counterlog_header.filter(header => {
                            if (header.key === "task" && task_submodule_available === 'N') {
                                return false; // Exclude headers where key is "task" and task_submodule_available is 'N'
                            }
                            return header.status === 1; // Keep headers where status is 1
                        }).map(header => {
                            let name = header.name;
                            if (header.key == "date_asset") {
                                name = "Date";
                            }
                    
                            return (
                                <th style={{ width: `${Number(header.width)}%` }}>{name}</th>
                            )
                        })}

                    </thead>
                    <tbody>
                        {!this.state.listing_loading
                            ?
                            this.state.logsheet_listing && this.state.logsheet_listing.length > 0 ? this.state.logsheet_listing.map((day, i) => {

                                let shifts = [];
                                if (day && day.roster && day.roster.shifts) {
                                    shifts = day.roster.shifts;
                                }

                                return (<Ax>
                                    {shifts.map((sf, key) => {
                                        let remarks = sf.shift_summary && sf.shift_summary.remark ? sf.shift_summary.remark : "";
                                        let remarksToShow = remarks.slice(0, 30);
                                        return (
                                            <tr className='text-center '>
                                                {key == 0
                                                    ? <Ax> <td className="align-middle" rowspan={day?.roster?.shifts?.length}>{day.date_display}</td>
                                                    </Ax>
                                                    : null
                                                }
                                                <td className='align-middle'>{sf.shift_name} <br /> {sf.shift_start_display}</td>
                                                {day.isLoading && day.isLoading == true ? <td colSpan={9} className="text-center"><Loader /></td>
                                                    : <Ax>
                                                        <td className='align-middle'>{sf.counterlog && sf.counterlog.display
                                                            ? <span className="text-start">
                                                                {sf.counterlog && sf.counterlog.display && sf.counterlog.display.length == 1
                                                                    ? <div className="mt-1 link-primary cursor_pointer text-center" onClick={() => this.viewShiftSummary(sf.counterlog.transaction_id)}>View</div> :
                                                                    sf.counterlog.display
                                                                        .filter(s => !moment(s.reading, "DD-MMM-YYYY", true).isValid())
                                                                        .map((item, index) => {
                                                                            return <div className="mt-1 link-primary cursor_pointer" onClick={() => this.viewShiftSummary(sf.counterlog.transaction_id)}>
                                                                                {sf.counterlog.display && sf.counterlog.display.length == 2
                                                                                    ? <div className="text-center">{item.reading} {item.unit}</div>
                                                                                    : <span>{item.label + ': ' + item.reading}</span>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        )}
                                                            </span>
                                                            : <a role="button" className='link-primary' onClick={() => this.updateCounterlogModalInit(sf, day.date)}>No Record</a>}</td>
                                                        {sf.counterlog && sf.counterlog.transaction_id ?
                                                            <Ax>
                                                                {this.state.assetDetail && this.state.assetDetail.fuel_feed_in && this.state.assetDetail.fuel_feed_in == "yes" && this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "fuel_feedIn")
                                                                    ? <td className='align-bottom'>{sf.shift_summary.fuelfeedin && sf.shift_summary.fuelfeedin.enable == true && sf.shift_summary.fuelfeedin.display !== "" ? sf.shift_summary.fuelfeedin.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.logFuelFeedInInit(sf, day.date)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                    </td> : null}
                                                                {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "downtime") && <td className='align-bottom'>{sf.shift_summary.downtime && sf.shift_summary.downtime.enable == true && sf.shift_summary.downtime.display && sf.shift_summary.downtime.display.length > 0 ? sf.shift_summary.downtime.display.map(db => { return (<div>{db.key} : {db.val}</div>) }) : ""}
                                                                    <div role="button"
                                                                        onClick={() => {
                                                                            if (this.props.permissions.includes('downtime-add')) {
                                                                                return this.logDowntimeInit(sf, day.date)
                                                                            }
                                                                        }}
                                                                        className={['', this.props.permissions.includes('downtime-add') ? '' : 'disabled-anchor-link'].join(' ')}
                                                                        title={!this.props.permissions.includes('downtime-add') ? 'You do not have permission to perform this action' : ''}
                                                                        style={{ "pointerEvents": "all" }}
                                                                    ><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>

                                                                </td>}
                                                                {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "productivity") && <td className='align-bottom'>{sf.shift_summary.prodction && sf.shift_summary.prodction.enable == true && sf.shift_summary.prodction.display !== "" ? sf.shift_summary.prodction.display : ""}
                                                                    <div className='cursor_pointer' onClick={() => this.logProductivityInit(sf, day.date)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                </td>}
                                                                {task_submodule_available == 'Y' && this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "task") &&
                                                                    <td className='align-bottom'>{sf.shift_summary.task && sf.shift_summary.task.enable == true && sf.shift_summary.task.display && sf.shift_summary.task.display.length > 0 ? sf.shift_summary.task.display.map(tk => { return (<div className='text-sm'>{tk.total} × {tk.activity_name} {tk.task_in_progress == 'N' ? <span className='text-success'>(Closed)</span> : <span className='link-primary'>(Inprogress)</span>}</div>) }) : ""}
                                                                        <div role="button"
                                                                            onClick={() => {
                                                                                if (this.props.permissions.includes('iam-task-workflow-execution')) {
                                                                                    return this.taskInit(sf, day.date, sf?.shift_summary?.task.display)
                                                                                }
                                                                            }}
                                                                            className={['', this.props.permissions.includes('iam-task-workflow-execution') ? '' : 'disabled-anchor-link'].join(' ')}
                                                                            title={!this.props.permissions.includes('iam-task-workflow-execution') ? 'You do not have permission to perform this action' : ''}
                                                                            style={{ "pointerEvents": "all" }}
                                                                        ><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>

                                                                    </td>}
                                                                   

                                                                {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "scs") && <td className='align-bottom'>{sf.shift_summary.scs && sf.shift_summary.scs.enable == true && sf.shift_summary.scs.display !== "" ? sf.shift_summary.scs.display : ""}
                                                                    <div className='cursor_pointer' onClick={() => this.serviceChecksheetInit(sf, day.date)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                </td>}
                                                                {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "expense") && <td className='align-bottom'>{sf.shift_summary.expense && sf.shift_summary.expense.enable == true && sf.shift_summary.expense.display !== "" ? sf.shift_summary.expense.display : ""}
                                                                    <div className='cursor_pointer' onClick={() => this.logExpenseInit(sf, day.date)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                </td>}
                                                                {this.state.assetDetail && this.state.assetDetail.fuel_feed_in && this.state.assetDetail.fuel_feed_in == "yes" && this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "opening_balance")
                                                                    ? <td className='align-bottom'>{sf.shift_summary.fuelbalance && sf.shift_summary.fuelbalance.enable == true && sf.shift_summary.fuelbalance.display !== "" ? sf.shift_summary.fuelbalance.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.fuelOpeningBalanceInit(sf, day.date)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                    </td> : null}
                                                                {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "docs") && <td className='align-bottom'>{sf.shift_summary.doc && sf.shift_summary.doc.enable == true && sf.shift_summary.doc.display !== "" ? sf.shift_summary.doc.display
                                                                    : ""}
                                                                    <div className='cursor_pointer' onClick={() => this.uploadDocumentInit(sf)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                </td>}
                                                                {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "cl_remarks") &&
                                                                    <td ref={this.tooltipRef} className='align-bottom cursor_pointer tooltip-trigger text-sm'
                                                                        data-bs-toggle='tooltip' title={remarks} >
                                                                        {remarksToShow} {remarks.length > 30 ? '...' : ''}
                                                                    </td>}
                                                                {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "operator") &&
                                                                    <td className='align-bottom text-start text-sm'>
                                                                        <ol>
                                                                            {sf.shift_summary && sf.shift_summary.operators && sf.shift_summary.operators.length > 0 && sf.shift_summary.operators.map(op => {
                                                                                return (<li>{op.name}</li>)
                                                                            })}
                                                                        </ol>

                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "engagement_time") &&
                                                                    <td className='align-bottom text-sm'>
                                                                        {sf.shift_summary.engagement && sf.shift_summary.engagement.enable == true && sf.shift_summary.engagement.display !== "" ? sf.shift_summary.engagement.display : ""}
                                                                        <div className='cursor_pointer' onClick={() => this.engagemenTimeInit(sf)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                       
                                                                    </td>}
                                                                    {this.state.counterlog_header.filter(hd => hd.status == 1).find(counterlog => counterlog.key == "activity") ?
                                                                        <td className='align-bottom'>{sf.shift_summary.activity && sf.shift_summary.activity.enable == true && sf.shift_summary.activity.display !== "" ? sf.shift_summary.activity.display : ""}
                                                                            <div className='cursor_pointer' onClick={() => this.logActivityInit(sf, day.date)}><TapIcon.FontAwesomeIcon icon={TapIcon.faPlus} style={{ width: "7px" }} /></div>
                                                                        </td> : null}
                                                            </Ax>

                                                            : <td colSpan={8} className="text-center">-</td>}
                                                    </Ax>}
                                            </tr>
                                        )
                                    })}

                                </Ax>)
                            })
                                : <tr><td className='text-center' colSpan={11}>No Record</td></tr>
                            : <tr><td className='text-center' colSpan={11}><Loader /></td></tr>}
                    </tbody>
                </table>
            </div>

        </Ax>
    }
    
    selectDateRangeModalJsx             =   () =>  {
        return (
            <div className="modal fade" id="selectDateRangeModal" tabIndex="-1">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Select Shift Date Range</h5>
                        </div>
                        <div className="modal-body">
                            <form className="bg-white p-1" id="searchDate" onSubmit={this.submitSearchDateRangeHandler}>
                                <div className='row my-2'>
                                    <div className="row align-items-center mb-3 mt-2">
                                        <div className='col-sm-5'><label className="form-label ">Shift Date Range</label> </div>
                                        <div className="col-sm-7 add_calender_section">
                                            <DatePicker
                                                selectsRange={true}
                                                monthsShown={2}
                                                dateFormat="dd-MMM-yyyy"
                                                className={'form-control'}
                                                autoComplete="off"
                                                name="date"
                                                startDate={this.state.selectDateRange.from}
                                                endDate={this.state.selectDateRange.to}
                                                onChange={(value, event) => this.tapDateChange(value)}
                                                maxDate={moment().toDate()}
                                                minDate={new Date(this.state.listingMeta && this.state.listingMeta.first_date)}
                                            />
                                            <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="add_calender-icon" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" form='searchDate'>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    customLogsheetDownloadJsx               =        ()             =>        {
        return (
            <div className="modal fade right" id="customLogsheetDownload" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Select Columns for Excel Download</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.submitcustomDownloadForm} id="addCustomDownloadForm">
                            
                                <div className="tab_content_wrapper my-2"  >
                                    <span className="content_heading">Select Columns </span>
                                </div>
                                <div className="row my-3">
                                    {this.state.customDownloadCriteria && this.state.customDownloadCriteria.length > 0 && this.state.customDownloadCriteria.map((criteria, index) => {
                                        return (<Ax>
                                             <div className="col-sm-1" key={index+1}>
                                                <input
                                                    name={criteria.key}
                                                    type="checkbox"
                                                    value={criteria.value}
                                                    checked={criteria.value == true ? true : false}
                                                    className="form-check-input"
                                                    id={criteria.key}
                                                    onChange={(e) => this.handleOnChangeCriteria(e, index)}
                        
                                                    />

                                            </div>
                                            <label className="col-sm-5 col-form-label col-form-label-sm" htmlFor={criteria.key}>{criteria.label}</label>
                                           
                                        </Ax>)
                                    })}
                                </div>
                                <div className="row my-3 align-items-center">
                                    <div className='col-sm-2 col-form-label col-form-label-sm'>File Name </div>
                                    <div className="col-sm-5">

                                        <input
                                            name="file_name"
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            placeholder="Please enter File Prefix "
                                            value={this.state.customDownloadForm.file_name}
                                            onChange={(e) => this.formInputHandler(e, 'customDownloadForm')}
                                        />

                                    </div>
                                    {/* <div className='col-sm-3'>
                                        <span className='form-text text-sm'>{'asset_name'}-yymmddHis.exel</span>
                                    </div> */}

                                </div>


                            </form>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting} onClick={this.resetDownloadCriteriaForm}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form="addCustomDownloadForm">Submit {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
   }

    render                              =   ()  =>  {
        return <ApplicationLayout>
                <Helmet><title>Asset Logsheet</title></Helmet>
            {this.pageTitleBarJsx()}
            <div id="assetCard" className="collapse pr-1 mt-1"  aria-expanded="true" >
                <AssetDetailHorizontalCard assetDetail={this.state.assetDetail} />
            </div>
            {this.state.displayShiftSummary
                ? <ShiftSummaryDetail transaction_id={this.state.currentTransactionId} onCloseEvent={(date, asset_id, isActivityPerform, isCounterLogPerform) => {this.closeShiftSummary(date, asset_id, isActivityPerform, isCounterLogPerform)}}  />
                : <Ax>
                    {this.logSheetSubHeadingJsx()}
                    <div className="container-fluid pl0 pr13">
                        <div className="page_containt row"><div className="pl16 col-12 pr0">{this.logSheetListJsx()}</div></div>
                    </div>
                  </Ax>
            }
            {this.selectDateRangeModalJsx()}
            {this.customLogsheetDownloadJsx()}

            <TaskSaveModal ref={this.taskWorkflowRef} afterSaveTask={(date, asset_id) => {
                let dateFormat = moment(date).format('YYYY-MM-DD')
                this.afterUpdateCounterlogSubmit(dateFormat, asset_id, false)
            }} />
            <EngagementTime ref={this.logEngagementTimeRef}  afterSaveEngagementTime={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id) }}/>
            <CounterlogModal ref={this.CounterLogModalRef} afterSubmitCounterlog={(date, asset_id,refresh) => {this.afterUpdateCounterlogSubmit(date, asset_id, refresh) }}/>
            <Document ref={this.uploadDocumentRef} afterSaveDocument={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id, false) }}/>
            <FuelBalance ref={this.fuelOpeningBalanceRef} afterSaveFuelBalance={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id, false) }}/>
            <ServiceChecksheet ref={this.serviceChecksheetRef} afterSaveServiceChecksheet={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id, false) }}/>
            <Downtime ref={this.logDowntimeRef} afterSaveDowntime={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id, false) }}/>
            <Productivity ref={this.logProductivityRef} afterSaveProductivity={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id, false) }}/>
            <Activity ref={this.logActivityRef} afterSaveActivity={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id, false) }}/>
            <Expense ref={this.logExpenseRef} afterSaveExpense={(date, asset_id) => {this.afterUpdateCounterlogSubmit(date, asset_id, false) }}/>
            <FuelFeedIn ref={this.logFuelFeedInRef} afterSaveFuelFeedIn={(date, asset_id,refresh) => {this.afterUpdateCounterlogSubmit(date, asset_id, refresh) }}/>
        </ApplicationLayout>
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        group_permission        :   state.app.acl_info,
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Logsheet);