import React from 'react';
import { toast } from 'react-toastify';
import swal from "sweetalert"
import { connect } from 'react-redux';
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import TapIcon from '../../../services/TapIcon';
import Loader from '../../../components/ui/Loader/Loader';
import {Link} from "react-router-dom";
import Status from '../../../components/ui/Status';
import { DisplayListPagination } from '../../../components/TapUi';


class SiteProductivityConfiguration extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.list_url                      =        TapApiUrls.IAM + '/site_productivity_config/list';
        this.detail_url                    =        TapApiUrls.IAM + '/site_productivity_config/detail';
        this.state                      =     {
            minimizeTable                :     false,
            listing_tbl_page             :     1,
            listing_loading              :     false,
            totalListingCount            :     0,
            listingMeta                  :     null,
            listingData                  :      [],
            configurationData            :      null,
        }
         
    }
    
    
    componentDidMount() {
        this.loadListingTblData();
        
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
      
        HttpAPICall.withAthorization('GET',  this.list_url, this.props.access_token, {}, {}, (resp) => {
            let respData            =       resp.data;
           this.setState({
                listingData         :       respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
           })
        }).then(() => this.setState({listing_loading: false}));
    }

   addConfigurationInit         =       ()          =>      {
        this.props.history.push('/site_productivity_configuration/add')
   }
    
   viewProductivityDetail       =       (id)        =>      {
       if (id) {
           this.setState({ minimizeTable: true })
           this.getConfigurationDetail(id)
       }
   }

   getConfigurationDetail             =    (id)        =>  {
    //    if(transaction_id !== this.state.purchaseViewHeader){
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET',  this.detail_url + '/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                configurationData        : respData.data,   
            });
           
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
       //}
    }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    editConfigurationInit       =           ()      =>      {
        this.props.history.push({ pathname: `/site_productivity_configuration/add`, state: { configurationData: this.state.configurationData } })
    }

    changeStatusInit     =       ()      =>      {
        
        let frmData = {
            status  :   this.state.configurationData.status && this.state.configurationData.status.status == 1 ? 2 : 1,
            id      :   this.state.configurationData.id ? this.state.configurationData.id : ""
        }
        swal({
                title: "Change Status",
                text: "Are you sure you want to proceed?",
                icon: "warning",
                buttons: ["No", "Yes"],
            })
            .then(willChangeStatus => {
                if (willChangeStatus) {
                    HttpAPICall.withAthorization('PUT',TapApiUrls.IAM + '/site_productivity_config/status', this.props.access_token, {},{...frmData} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getConfigurationDetail(this.state.configurationData.id);
                        this.loadListingTblData(1);
                    }).then(() => {

                    });
                }
            });
      
    }
    
   configurationListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Configuration</h3>
                {!this.state.minimizeTable && <div className="text-end mt15">
                     <button type="button" onClick={this.addConfigurationInit} disabled={this.state.listing_loading || !this.props.permissions.includes('site-productivity-configuration-add')}  className="btn btn-primary" >
                        Add Configuration
                    </button>
                    <button type="button" disabled={this.state.listing_loading || !this.props.permissions.includes('site-productivity-configuration-add')} className="btn btn-secondary">
                       <Link style={{color:"white"}} target="_blank" to='/general_configuration/site_productivity'>
                         Additional Attribute
                        </Link>
                    </button>
                   

                </div>}
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Configuration : {this.state.configurationData?.activity_name} ({this.state.configurationData?.activity_code})</h3>
                            <div className="text-end mt15">
                                <button  type="button"  disabled={!this.props.permissions.includes('site-productivity-configuration-edit')} className="btn btn-secondary" onClick={this.editConfigurationInit}>
                                    Edit
                                </button>
                                <button  type="button"  disabled={!this.props.permissions.includes('site-productivity-configuration-change-status')} className="btn btn-secondary" onClick={this.changeStatusInit}>
                                    Change Status
                                </button>
                                
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }

        </div>
        <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.configurationTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.configurationViewDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    configurationTableJsx            =   ()   =>   {
        return (<Ax>
            <table className="table table-bordered table-fixed  bg-white table-sm">
            <thead className="table-secondary" >
                {this.state.minimizeTable
                    ? (<tr>
                        <th scope="col" style={{ width: "15%" }}>Configuration</th>
                    </tr>)
                    : (
                        <tr>
                            <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                            <th scope="col" style={{ width: "25%" }}>Activity Name (Code)</th>
                            <th scope="col" style={{ width: "25%" }} className="text-center">Sites</th>
                            <th scope="col" style={{ width: "13%" }} className="text-center">UOM</th>
                            <th scope="col" style={{ width: "13%" }} className="text-center">Accounting Code</th>
                            <th scope="col" style={{ width: "11%" }} className="text-center">Status</th>
                            <th scope="col" style={{ width: "8%" }} className="text-center">Action</th>

                        </tr>)}
            </thead>
            <tbody>
                {this.state.listing_loading
                    ? (<tr><td colSpan="7"><Loader /></td></tr>)
                    : (this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((item, index) => {
                            return (<tr key={index} >
                                {
                                    this.state.minimizeTable
                                        ? (
                                            <Ax>
                                                <td style={{ width: "25%" }} onClick={() => this.viewProductivityDetail(item.id)} >
                                                    <div className="text-capitalize link-primary cursor_pointer">{item.activity_name ? item.activity_name : "-"}</div>
                                                    <div className="mt-1">
                                                        <small className="text-capitalize ">{item.activity_code ? item.activity_code : "-"} </small>
                                                        <small className="float-end">{item.status ? <Status color={item.status.status_color_code}>{item.status.status_text ? item.status.status_text : ""}</Status> : ""}</small>
                                                    </div>
                                                </td>
                                            </Ax>
                                        )
                                        : (<Ax>
                                            <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                            <td className='text-capitalize link-primary cursor_pointer' onClick={() => this.viewProductivityDetail(item.id)}>{item.activity_name ? item.activity_name : "-"} ({item.activity_code ? item.activity_code : "-"})</td>
                                            <td>{item.first_site ? <span>{item.first_site.site_name} {item.sites_count > 1 && <span>(+{item.sites_count - 1}) </span>}</span> : ""}</td>
                                            <td>{item.uom ? item.uom.measuring_unit : ""}</td>
                                            <td>{item.account_Code ? item.account_Code.name : ""}</td>
                                            <td className="text-center">{item.status ? <Status color={item.status.status_color_code}>{item.status.status_text ? item.status.status_text : ""}</Status> : ""}</td>
                                            <td className="text-center">
                                               <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" role="button" onClick={() => this.viewProductivityDetail(item.id)}>View</a></li>
                                                </ul>
                                            </td>
                                        </Ax>
                                        )}
                            </tr>)
                        }))
                        : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                    )
                }
            </tbody>
        </table>
        <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>

        )
    }

    configurationViewDetailJsx      =       ()      =>      {
        let data = this.state.configurationData;
        return (<div className='tab_content_header'>
            {this.state.view_loading ? <Loader /> :
                data ? (<div>
                    <table className="table table-hover table-bordered  table-responsive bg-white mb-0  mt-1 ">
                        <tbody>
                            <tr>
                                <td style={{ width: "33%" }}>Activity Name</td>
                                <th>{data.activity_name ? data.activity_name : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Activity Code</td>
                                <th>{data.activity_code ? data.activity_code : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Unit of Measurement</td>
                                <th>{data.uom ? data.uom.measuring_unit : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Sites</td>
                                <th>{data.sites ? data.sites.map(st => {return(st.site_name)}).join(', ') : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Status</td>
                                <th>{data.status ? <Status color={data.status.status_color_code}>{data.status.status_text ? data.status.status_text : ""}</Status> : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>No of Trips/Sessions</td>
                                <th>{data.number_of_trip && data.number_of_trip == "Y" ? "Yes" : "No"}</th>
                            </tr>
                            {data.number_of_trip && data.number_of_trip == "Y" && <tr>
                                <td style={{ width: "33%" }}>No of Trips/Sessions Required</td>
                                <th>{data.number_of_trip_required && data.number_of_trip_required == "Y" ? "Yes" : "No"}</th>
                            </tr>}
                            <tr>
                                <td style={{ width: "33%" }}>Material</td>
                                <th>{data.material && data.material == "Y" ? "Yes" : "No"}</th>
                            </tr>
                            {data.material && data.material == "Y" && <tr>
                                <td style={{ width: "33%" }}>Material Required</td>
                                <th>{data.material_required && data.material_required == "Y" ? "Yes" : "No"}</th>
                            </tr>}
                            <tr>
                                <td style={{ width: "33%" }}>Attachment</td>
                                <th>{data.attachment && data.attachment == "Y" ? "Yes" : "No"}</th>
                            </tr>
                            {data.attachment && data.attachment == "Y" && <tr>
                                <td style={{ width: "33%" }}>Attachment Required</td>
                                <th>{data.attachment_required && data.attachment_required == "Y" ? "Yes" : "No"}</th>
                            </tr>}
                            <tr>
                                <td style={{ width: "33%" }}>WorkStation</td>
                                <th>{data.workstation && data.workstation == "Y" ? "Yes" : "No"}</th>
                            </tr>
                            {data.workstation && data.workstation == "Y" &&<tr>
                                <td style={{ width: "33%" }}>WorkStation Required</td>
                                <th>{data.workstation_required && data.workstation_required == "Y" ? "Yes" : "No"}</th>
                            </tr>}
                            <tr>
                                <td style={{ width: "33%" }}>Employee</td>
                                <th>{data.employee && data.employee == "Y" ? "Yes" : "No"}</th>
                            </tr>
                            {data.employee && data.employee == "Y" &&<tr>
                                <td style={{ width: "33%" }}>Employee Required</td>
                                <th>{data.employee_required && data.employee_required == "Y" ? "Yes" : "No"}</th>
                            </tr>}
                            <tr>
                                <td style={{ width: "33%" }}>Additional Attributes</td>
                                <th>{data.additionalAttr_details && data.additionalAttr_details.length > 0 ? data.additionalAttr_details.map(st => st.name).join(', ') : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Productivity Formula</td>
                                <th>{data.productivity_formula ? data.productivity_formula : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Accounting Code</td>
                                <th>{data.account_Code ? data.account_Code.name : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Starting Date</td>
                                <th>{data.starting_date_display ? data.starting_date_display : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Closing Date</td>
                                <th>{data.closing_date_display ? data.closing_date_display : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Target Type</td>
                                <th className='text-capitalize'>{data.target_type ? data.target_type : ""}</th>
                            </tr>
                            {data.target_type && <Ax>
                                <tr>
                                <td style={{ width: "33%" }}>Total Productivity Target</td>
                                <th className='text-capitalize'>{data.total_productivity_target ? data.total_productivity_target : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Target Per Day</td>
                                <th className='text-capitalize'>{data.target_per_day ? data.target_per_day : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ width: "33%" }}>Cost Per Unit Target</td>
                                <th className='text-capitalize'>{data.cost_per_unit_target ? data.cost_per_unit_target : ""}</th>
                            </tr>
                            </Ax>}
                            <tr>
                                <td style={{ width: "33%" }}>Notes</td>
                                <th>{data.notes ? data.notes : ""}</th>
                            </tr>

                        </tbody>
                    </table>

                </div>) : null}

        </div>)
    }

    render                              =   ()  =>  {
      
        return (<ApplicationLayout>
        <Helmet><title>Site Productivity - Configuration</title></Helmet>
             {this.configurationListjsx()} 
           
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};

export default connect(mapStateToProps)(SiteProductivityConfiguration);