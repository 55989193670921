import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

class GroupUpdateHeader extends Component {

  constructor(props) {
    super(props);
    this.state              =       {
      isUpdateAvailable     :       this.props.firebase_app_version !== process.env.REACT_APP_VERSION,
    };
  }

reloadApplication       =       ()      =>      {
    window.location.reload();
}

render() {
   
    return (
        <div className="row">
            <div className="col-md-7 pt-2">
                Welcome <b className="link-primary">{this.props.user_name}</b> (<b className="link-primary">{this.props.group_name}</b>). Dashboard as off{' '}
                <b className="link-primary">{moment().format('DD-MMM-YYYY')}</b>
            </div>
            <div className="col-md-5 text-end">
                {this.state.isUpdateAvailable ? (
                    <>
                        <div className="d-inline text-dark fw-bold me-2">New version Available!</div>
                        <div className="d-inline text-dark me-2">Please, update app to new version </div>
                        <a onClick={this.reloadApplication} className="btn btn-danger py-1 fs13 btn-sm d-inline me-3">
                            Update
                        </a>
                    </>
                ) : null}
            </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
    
    return {
        access_token            :   state.auth.access_token,
        user_name               :   state.app.user_info?.name??'',
        group_info              :   state.app.group_info ? state.app.group_info : null,
        group_name              :   state.app.group_info ? state.app.group_info.group_name : '',
        firebase_app_version     :   state.app && state.app.firebase_app_version ? state.app.firebase_app_version : 1,
    };
};


export default connect(mapStateToProps)(GroupUpdateHeader);
