import React from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination} from '../../../components/TapUi';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import FeedbackModal from '../../../layouts/modal/FeedbackModal';

class HelpTutorialList extends AppBaseComponent {

    constructor(props) {
        super(props);
        

        this.state                      =     {
            minimizeTable                :     true,
            listing_tbl_page             :     1,
            listing_loading              :     false,
            totalListingCount            :     0,
            listingMeta                  :     null,
           notification_listing          :     [],
           selectedNotificationData      :     null,
           saveFormSubmitting           :       false,
           embedId                      :       '',
           tutorialData               :       null,
           tutorial_listing             :       [],
           show                            :     false, 
           viewDataLoading              :       false,
        };

        this.tutorialUrl        =   ORG_API_BASE_URL_2  + '/tutorial';
        this.tutorialDetailUrl  =   ORG_API_BASE_URL_2  + '/tutorial/detail'; 
         
    }
    
    
    componentDidMount() {
        
        if(this.props.match && this.props.match.params && this.props.match.params.id && this.props.match.params.id !== "all_tutorials"){
            //this.getTutorialLinkDetail(this.props.match.params.id)
            this.loadListingTblData(1,this.props.match.params.id);
        }else{
            this.loadListingTblData(1);
        }   
    }

    addFeedbackModalInit                 =   ()  =>  {
        this.setState({show : true})
    }

    closeFeedback =  () => {
        this.setState({show : false})
    }

    loadListingTblData                 =   (page = 1,id='')  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,};
        HttpAPICall.withAthorization('GET', this.tutorialUrl, this.state.access_token ? this.state.access_token : this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            
                this.setState({
                    tutorial_listing         :           respData.data,
                    listingMeta             :           respData.meta,
                    totalListingCount       :           respData.meta.total,
            },() => {
                let tutrialListData         =   respData && respData.data && respData.data.length > 0 ? respData.data[0]    :   [];
                
                if(tutrialListData.tutorials && tutrialListData.tutorials.length > 0 && id == '' ){
                    this.getTutorialLinkDetail(tutrialListData.tutorials[0].slug)
                }else if(this.state.tutorial_listing && this.state.tutorial_listing.length > 0 && id){
                    this.getTutorialLinkDetail(id)
                }
            });
        }).then(() => this.setState({listing_loading: false}));
    }
  
    getEmbedId          =       (id)     =>      {
       
       this.getTutorialLinkDetail(id)
    }

    getTutorialLinkDetail                 =   (id)  =>  {
        this.setState({ viewDataLoading: true })
        HttpAPICall.withAthorization('GET', this.tutorialDetailUrl, this.state.access_token ? this.state.access_token : this.props.access_token, {slug:id}, {}, (response) => {
            this.props.history.push(`/help/${id}`);
            this.setState({
                 tutorialData     :       response.data.data
            })
           
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({ viewDataLoading: false }))
    }

    tutorialListingTableJsx            =   ()   =>   {
        return (<div className="bg-white">
            <div className='p-2'>
                {this.state.tutorial_listing.map((tl, key) => {
                    return (<Ax>
                        <div className="tab_content_wrapper content_heading">{tl.name}</div>
                        <table className="table table-bordered table-hover table-fixed  bg-white my-2">

                            <tbody>
                                {tl.tutorials && tl.tutorials.length > 0 ?
                                    tl.tutorials.map((help, key) => {
                                        return (<tr className={help.slug == this.state?.tutorialData?.slug ? "selected_config" : "white"}><td className="text-capitalize link-primary cursor_pointer" onClick={() => this.getEmbedId(help.slug)}>{help.title}</td>
                                        </tr>)
                                    }) : <tr>
                                        <td className="text-capitalize link-primary cursor_pointer">No Videos under this section</td>
                                    </tr>}

                            </tbody>
                        </table>

                    </Ax>)
                })}
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                />
            </div>

        </div>)
    }

    viewHelpTutorialJsx         =       ()          =>      {
        return (<div className="tab_content_header">
            {this.state.viewDataLoading ? <div className='text-center'>
                <Loader />
            </div>
                : <div>
                    {this.state.tutorialData && this.state.tutorialData.embed_code ? (
                        <div className="text-center mb-4">
                            <div
                                className="video-container"
                                dangerouslySetInnerHTML={{ __html: this.state.tutorialData.embed_code }}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    {this.state.tutorialData && this.state.tutorialData.description ?  <div style={{lineHeight:1.8}}>
                        {/* <div className="tab_content_wrapper content_heading mb-2">Description</div> */}
                       {parse(this.state.tutorialData.description)}
                    </div>: ""}
                </div>}

        </div>)
    }


    render                              =   ()  =>  {
      
        return (<ApplicationLayout>
            <Helmet><title>Help & Tutorial</title></Helmet>
            <div className="row m-0 bg-white">
                <div className="col-sm-12 p-0">
                    <ul className="nav nav-tabs d-flex" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button onClick={() => this.props.history.push('/my_dashboard')} className={`nav-link`} id="mydashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="mydashboard" aria-selected="true">My Dashboard </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => this.props.history.push('/dashboard')} className={`nav-link `} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard </button>
                        </li>


                        <li className="nav-item ms-auto" role="presentation">
                            <button onClick={() => this.props.history.push('/new_releases')}
                                className={`nav-link`} id="release-tab" data-bs-toggle="tab" data-bs-target="#release" type="button" role="tab" aria-controls="release" aria-selected="true">New Releases</button>
                        </li>
                        <li className="nav-item " role="presentation">
                            <button onClick={() => this.props.history.push('/help/all_tutorials')}
                                className={`nav-link active`} id="help-tab" data-bs-toggle="tab" data-bs-target="#help" type="button" role="tab" aria-controls="help" aria-selected="true">Help & Tutorial </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => this.addFeedbackModalInit()}
                                className={`nav-link`} id="feedback-tab" data-bs-toggle="tab" data-bs-target="#feedback" type="button" role="tab" aria-controls="feedback" aria-selected="true">FeedBack</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button onClick={() => this.props.history.push('/training_list')}
                                className={`nav-link`} id="training-tab" data-bs-toggle="tab" data-bs-target="#training" type="button" role="tab" aria-controls="training" aria-selected="true">Schedule a Training</button>
                        </li>

                    </ul>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane  show active" id="help" role="tabpanel" aria-labelledby="help-tab">
                    <div className="container-fluid px-0">
                        <div className="container-fluid">
                            <div className="page_containt row">
                                {this.state.listing_loading ? <div className='text-center'> <Loader /></div>
                                    : this.state.tutorial_listing && this.state.tutorial_listing.length == 0 ? <div className='text-center bg-white'>No Video Available</div>
                                        : <Ax>
                                            <div className={['pageTbl px-1', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.tutorialListingTableJsx()}</div>
                                            {this.state.minimizeTable ? <div className="col-9 pr4 pl4">
                                                <div className="bg-white" style={{ height: "100%" }}>
                                                    {this.viewHelpTutorialJsx()}
                                                    
                                                    </div></div> : null}
                                        </Ax>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
           
            {this.state.show === true ? <FeedbackModal close={this.closeFeedback} /> : null}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(HelpTutorialList);