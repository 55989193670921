import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {PeriodSelectionWithCustom, DisplaySearchCriteria} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import TapSelect from "../../../components/ui/TapSelect";
import Helmet from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';


class ProductivityComparisonReport  extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.cancelToken                    =   null;
        this.reportUrl                      =   IAM_API_BASE_URL_2 + '/report/productivity-report';

        this.reportFilterFormInitialState   =   {
            site_group_ids                      :   [],
            search_site_ids                     :   [],
            asset_group_ids                     :   [],
            search_asset_type_ids               :   [],
            search_asset_matrix_ids             :   [],
            date_range1                         :   '',
            date_range2                         :   '',
            search_activities                   :   [],
            search_units                        :   [],
            search_material_ids                 :   [],
            search_attachment_ids               :   [],
            date_range_start1                   :   '',
            date_range_end1                     :   '',
            date_range_start2                   :   '',
            date_range_end2                     :   '',
        };
        
        this.reportFilterFormLabel          =   {
            site_group_ids                      :   'Site Groups : ',
            search_site_ids                     :   'Sites : ',
            asset_group_ids                     :   'Asset Type Groups : ',
            search_asset_type_ids               :   'Asset Type : ',
            search_asset_matrix_ids             :   'Asset Matrix : ',
            date_range1                         :   'Date Range1 : ',
            date_range2                         :   'Date Range2 : ',
            search_activities                   :   'Activities : ',
            search_units                        :   'Units : ',
            search_material_ids                 :   'Materials : ',
            search_attachment_ids               :   'Attachment : ',
        };
         
        
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],
            iam_asset_matrix                    :   [],
            formDataLoaded                      :   false,
            filterFormData                      :   null,
            allSiteGroupsList                   :   [],
            allAssetTypesGroupsList             :   [],
            allPeriods                          :   [],
            activities                          :   [],
            units                               :   [],
            materials                           :   [],
            attachments                         :   [],
            period1                             :   '',
            period2                             :   '',       
            reportData                          :   null,                  
        };

    }
    
    componentDidMount() {
        setTimeout(() => {
            this.initilaizeAppDataToFilterForm(this.props); 
        },1000 );
    }

    initilaizeAppDataToFilterForm           =   (props)  =>  {

        const report_name   =   'productivity_period_comparison_report';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        
        this.setState({
            reportData              :   reportData.length > 0 ? reportData[0] : null,
            listing_loading         :   true,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],

            allSiteGroupsList       :   props.site_groups ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            allPeriods             :   props.all_periods,
        },() => {
            // setTimeout(() => {
                this.setState({
                    reportFilterForm        :    { ...this.state.reportFilterForm,date_range1 : 'current_month', date_range2 : 'previous_month' },
                   
                }, () => {
                    this.filterFormDataInit();
                })
            // }, 1000)
            
        });
    }
    
    filterFormDataInit                      =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {action : 'formData'}, null, (response) => {
            let respData = response.data;
            this.setState({
                formDataLoaded      : true,
                activities          : respData.activity_name ? respData.activity_name.map((s) => { return {value: s.id, label: s.activity_name}}) : [],

                units               : respData.uom ? respData.uom.map((s) => { return {value: s.id, label: s.measuring_unit}}) : [],

                materials           : respData.materials ? respData.materials.map((s) => { return {value: s.id, label: s.material_name}}) : [],

                attachments         : respData.attachments ? respData.attachments.map((s) => { return {value: s.id, label: s.name}}) : [],
            }, () => {
                this.reportFilterFormSubmit()
            });
        }, (err) => { });
    }
    
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]       =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            =   this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activities') {
                        show_val            =   this.state.activities.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_units') {
                        show_val            =   this.state.units.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_material_ids') {
                        show_val            =   this.state.materials.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_attachment_ids') {
                        show_val            =   this.state.attachments.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range1') {
                        let  start_range        =   moment(this.state.reportFilterForm.date_range_start1).format('DD-MMM-YYYY');
                        let end_range           =   moment(this.state.reportFilterForm.date_range_end1).format('DD-MMM-YYYY')
                            let display_custom  =   `Custom Date Range (${start_range} - ${end_range})`;
                            show_val            =   this.state.allPeriods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'date_range2') {
                        let  start_range        =   moment(this.state.reportFilterForm.date_range_start2).format('DD-MMM-YYYY');
                        let end_range           =   moment(this.state.reportFilterForm.date_range_end2).format('DD-MMM-YYYY')
                            let display_custom  =   `Custom Date Range (${start_range} - ${end_range})`;
                            show_val            =   this.state.allPeriods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1,reload=false)    =>  {
        this.setState({ listing_loading         :   true });
        const data          =   {...this.state.submittedReportFilterForm, action : 'period_comparision_data'};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, data, null, (response) => {
            let respData = response.data;
            this.setState({
                formDataLoaded      :   true,
                period1             :   respData.period1.display,     
                period2             :   respData.period2.display,
                listingData         :   respData.data
            });
        }).then(() => this.setState({listing_loading: false}));;

    }

    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, action : 'period_comparision_download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =   ()  =>  {
        this.props.history.push('/report');
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Productivity Period  Comparison Report</h3>
                <div className="text-end mt15">

                   <button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  => {
        return (<div id="reportFilterForm" className="bg-white collapse " aria-expanded="true">
             <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {
                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_matrix_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.reportFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Activities</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.activities}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_activities')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.activities.filter(s => this.state.reportFilterForm.search_activities.includes(s.value))}
                            placeholder="Select Activities"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Units</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.units}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_units')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.units.filter(s => this.state.reportFilterForm.search_units.includes(s.value))}
                            placeholder="Select Units"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Materials</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.materials}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_material_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.materials.filter(s => this.state.reportFilterForm.search_material_ids.includes(s.value))}
                            placeholder="Select Materials"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Attachments</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.attachments}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_attachment_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.attachments.filter(s => this.state.reportFilterForm.search_attachment_ids.includes(s.value))}
                            placeholder="Select Attachments"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period 1<span className="text-danger">*</span></label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.allPeriods ? this.state.allPeriods : []}
                            value={this.state.reportFilterForm.date_range1}
                            startDate={this.state.reportFilterForm.date_range_start1}
                            endDate={this.state.reportFilterForm.date_range_end1}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range1: period,
                                        date_range_start1: startDate ? startDate : null,
                                        date_range_end1: endDate ? endDate : null
                                    }
                                });
                            }}
                            required={true}
                        />
                    </div>
                </div>                
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period 2<span className="text-danger">*</span></label>
                    <div className="col-sm-6">
                        <PeriodSelectionWithCustom
                            periods={this.state.allPeriods ? this.state.allPeriods : []}
                            value={this.state.reportFilterForm.date_range2}
                            startDate={this.state.reportFilterForm.date_range_start2}
                            endDate={this.state.reportFilterForm.date_range_end2}
                            onSelectPeriod={(period, startDate, endDate) => {
                                this.setState({
                                    reportFilterForm
                                        : {
                                        ...this.state.reportFilterForm,
                                        date_range2: period,
                                        date_range_start2: startDate ? startDate : null,
                                        date_range_end2: endDate ? endDate : null
                                    }
                                });
                            }}
                            required={true}
                        />
                    </div>
                </div>                
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr/>
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
               {this.reportTableJsx()}
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered bg-white align-items-center  table-sm table-hover" id="rowDataReportTable">
                <thead>
                    <tr>
                        <th scope="col" style={{ width: "5%" }} className='text-center'>S.No</th>
                        <th scope="col" style={{ width: "50%" }}>Productivity Details</th>
                        <th scope="col" style={{ width: "25%" }}>UOM</th>
                        <th scope="col" style={{ width: "10%" }}>{this.state.period1}</th>
                        <th scope="col" style={{ width: "10%" }}>{this.state.period2}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading ? (<tr><td className="text-center" colSpan={4}><Loader /></td></tr>)
                        : this.state.listingData && this.state.listingData.length > 0
                            ? (<Ax> {this.state.listingData.map((data, k) => {
                                return( 
                                    <tr key={k}>
                                         <td className='text-center'>{k+1}</td>
                                        <td>{data.prod_display}</td>
                                        <td>{data.unit}</td>
                                        <td className="text-end">{data.period1}</td>
                                        <td className='text-end'>{data.period2}</td>
                                    </tr>
                                    )
                            })
                            }</Ax>)
                            : (<tr><td className="text-center" colSpan={4}>No Record Found</td></tr>)
                    }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Productivity Period Comparison  - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.reportFilterFormJsx()}
                        <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} /> 
                        {this.reportListingDisplayJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps   =   state   =>  {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        asset_groups            :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        all_periods             :   state.app && state.app.all_periods ? [...state.app.all_periods , {key : 'custom' , display : 'Custom Date Range'}] : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ProductivityComparisonReport);