import React from 'react';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import HttpAPICall from "../../../services/HttpAPICall";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputCustomerSearch from '../../includes/ui/InputCustomerSearch';
import CustomerProjectSearch from '../includes/CustomerProjectSearch';
import AddCustomerModal from '../includes/AddCustomerModal';
import AddCustomerProjectModal from '../includes/AddCustomerProjectModal';

class DealsTransactionAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAddDealForm                 =    {
            deal_type                       :    '',
            status                          :      '',
            header                             :    '',
            notes                        :    '',
            customer_id                           :    null,
            users                              :    [],
            estimate_day                      :    null,
            tags                               :    [],
        }
        this.state                              =   {
            saveFormSubmitting                  :   false,
            addDealForm                       :   {...this.initAddDealForm},
            dealFormData                      :   [],
            allDealTypes                      :   [],
            formDataLoading                     :   false,
            allUsers                            :   [],
            taggedUsers                         :   [],
            dealTags                          :   [],
            dealTypeFormData                  :   [],
            allStatusList                       :   [],
            setting                             :   null,
            showAddCustomerProjectModal         :   false,
            showAddCustomerModal                :   false
        };
        this.dealUrl      =   INVENTORY_API_BASE_URL_2 + '/deal';
        this.customerRef    =   React.createRef();
        this.customerProjectRef     =   React.createRef();
    }

    componentDidMount() {
        this.getDealFormData();
    }

    getDealFormData   =   ()  =>  {
        
        this.setState({formDataLoading: true});
        HttpAPICall.withAthorization('GET',  this.dealUrl + '/form_data', this.props.access_token, {}, {}, (resp) => {
            
            //let defaultDealType       =   resp.data..find(a => a.default == 1);
            this.setState({
                dealFormData          :   resp.data,
                allDealTypes          :   resp.data.deal_types ? resp.data.deal_types.map((s) => { return {value: s.id, label: s.name}}) : [],
                allStatusList          :   resp.data.status ? resp.data.status.map((s) => { return {value: s.id, label: s.name,}}) : [],
                allUsers                :   resp.data.users ? resp.data.users.map((s)=>{return {value:s.id, label:s.full_name, linked_sites:s.linked_sites}}) : [],
                setting                :   resp.data.deal_config ? resp.data.deal_config : null,
                dealTags              :   resp.data && resp.data.tags && resp.data.tags.length > 0 ?  resp.data.tags.map((tag) => { return {value: tag, label: tag}; }) : [],    
                
            },() => {
                if(this.props.location && this.props.location.state && this.props.location.state.dealData) {
           
                    //1 - Edit Mode of Existing Purchase Order :- 
                    let dealData    =   this.props.location.state.dealData;
                   //in Items Data : (props.location.state.dealData.items)
                    //if same Item got than we will merge and process start :-
        
                    let updateFormState = [];
                    if (dealData.additional_attribute_data) {
                        dealData.additional_attribute_data.map((i, k) => {
                            let name = 'additional_attributes_' + i.key;
                            updateFormState[name] = i.value
                        });
        
                    }
                   
                    this.setState({addDealForm  :   {
                            ...this.initAddDealForm,
                            enc_id        :   dealData.id ? dealData.id : '' ,
                            header          :   dealData.header ? dealData.header : '',
                            deal_type       :   dealData.deal_type ? dealData.deal_type.id : '',
                            status              :    dealData.status ? dealData.status.id : '',  
                            notes      :    dealData.notes ? dealData.notes : '',
                            customer_id        :    dealData.customer ? dealData.customer.id : '',
                            customer_project_id        :    dealData.customer_project ? dealData.customer_project.id : '',
                            users               :    dealData.users && dealData.users.length > 0 ? dealData.users.map((us) => {return(us.enc_id)}) : [],
                            estimate_day         :    dealData.estimate_day ? dealData.estimate_day : '',
                            tags                  :    dealData.tags && dealData.tags.length > 0 ? dealData.tags.map((us) => us) : [],
                            ...updateFormState
                        },
                        searchedOriginCustomer          :  dealData.customer ? {value : dealData.customer.id  ,display_label : dealData.customer.name ,label : dealData.customer.name } : null,
                        searchedProject          :  dealData.customer_project ? {value : dealData.customer_project.id  ,display_label : dealData.customer_project.project_name , label : dealData.customer_project.project_name } : null,
                        dealDetail                :   dealData,
                           
                    }, () => {
                       
                        
                    });
                }
            });
        
        }).then(() => this.setState({formDataLoading: false}));
    }
    
    
    closeEvent  =   ()  =>  {
        this.props.history.push('/deals')
    }

    addCustomerModalInit          =       ()           =>      {
        this.setState(prevState => ({ showAddCustomerModal: !prevState.showAddCustomerModal }), () => {
            if (this.state.showAddCustomerModal) {
                this.customerRef.current.customerAddModalInit();  
            }
        })

        
    }

    addCustomerProjectModalInit          =       ()           =>      {
        this.setState(prevState => ({ showAddCustomerProjectModal: !prevState.showAddCustomerProjectModal }), () => {
            if (this.state.showAddCustomerProjectModal) {
                if(this.customerProjectRef && this.customerProjectRef.current){
                    this.customerProjectRef.current.customerProjectAddModalInit(); 
                } 
            }
        })

        
    }

    addDealFormSubmitEvent    =   (e) =>  {
        e.preventDefault()
        let additional_attributes = {};
        if(this.state.dealFormData && this.state.dealFormData.additional_attributes && this.state.dealFormData.additional_attributes.length > 0 ){
            this.state.dealFormData.additional_attributes.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addDealForm[name] ? this.state.addDealForm[name] : '';
            });
        }
        let frmData     =      {
            ...this.state.addDealForm,
           additional_attributes 
        }
        if(this.state.dealDetail){
            this.setState({saveFormSubmitting:true})
        HttpAPICall.withAthorization('PUT', this.dealUrl + '/update', this.props.access_token, {} , {...frmData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.closeEvent()
        }).then(() => this.setState({saveFormSubmitting: false}));
        }else{
            this.setState({saveFormSubmitting:true})
        HttpAPICall.withAthorization('POST', this.dealUrl + '/add', this.props.access_token, {} , {...frmData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.closeEvent()
        }).then(() => this.setState({saveFormSubmitting: false}));
        }
    }

   
    saveDealFormJsx   =   ()  =>  {
        return (
            <form className="bg-white my-1 p-3" onSubmit={this.addDealFormSubmitEvent} id="addDealForm">
                {this.state.formDataLoading ?
                    <Loader /> :
                    <Ax>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Deals Details</span></div>
                        <div className="row align-items-center my-3">
                            <div className="col-3">
                                <label htmlFor="deal_type" className="form-label require">Deals Type</label>
                            </div>
                            <div className="col-4">
                                <TapSelect
                                    options={this.state.allDealTypes}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addDealForm', 'deal_type');
                                    }}
                                    value={this.state.allDealTypes.find(a => a.value == this.state.addDealForm.deal_type)}
                                    isSearchable={true}
                                    isClearable={true}
                                    required={true}
                                    placeholder="Please Select Deals Type"
                                />
                            </div>
                        </div>

                        <div className="row align-items-center my-3">
                            <div className="col-3">
                                <label htmlFor="site_id" className="form-label require">Header</label>
                            </div>
                            <div className="col-4">
                                <input
                                    name="header"
                                    type="text"
                                    className="form-control"
                                    required={true}
                                    value={this.state.addDealForm.header}
                                    autoComplete="off"
                                    onChange={(e) => this.formInputHandler(e, "addDealForm")}
                                    placeholder="Please enter Deals Header"
                                />
                            </div>
                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className="form-label require">Status</label>
                            </div>
                            <div className="col-sm-4">
                                <TapSelect
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addDealForm', 'status');
                                    }}
                                    options={this.state.allStatusList}
                                    isSearchable={true}
                                    isClearable={true}
                                    required={true}
                                    isDisabled={this.state.dealDetail ? true : false}
                                    value={this.state.allStatusList.find(s => s.value == this.state.addDealForm.status)}
                                    placeholder="Select Status"
                                />
                            </div>
                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-3">
                                <label htmlFor="site_id" className="form-label ">Description</label>
                            </div>
                            <div className="col-4">
                                <textarea
                                    name="notes"
                                    className="form-control"
                                    autoComplete="off"
                                    onChange={(e) => this.formInputHandler(e, "addDealForm")}
                                    style={{ height: "80px" }}
                                    value={this.state.addDealForm.notes}
                                    placeholder="Please Add Description"
                                />
                            </div>
                        </div>
                        <div className="tab_content_wrapper my-4">
                            <span className="content_heading">Tagging Details</span>
                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-3"><label htmlFor="site_id" className="form-label require ">Customer</label></div>
                            <div className="col-sm-4">
                                <InputCustomerSearch
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addDealForm', 'customer_id',() => {
                                            this.tapSelectChange(selectedOption, 'addDealForm', 'customer_project_id')
                                        });
                                        this.setState({ searchedOriginCustomer: selectedOption ? selectedOption : null,searchedProject : null });
                                    }}
                                    menuPlacement="top"
                                    required={true}
                                    value={this.state.searchedOriginCustomer}
                                />
                               
                            </div>
                            <div className="col-sm-4">
                                <a role="button" onClick={() => { this.setState({ showAddCustomerModal: false }, () => { this.addCustomerModalInit() }) }} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Customer</a>
                            </div>
                        </div>
                        <div className="row align-items-center my-3">
                            <div className="col-sm-3"><label htmlFor="site_id" className="form-label ">Customer Project</label></div>
                            <div className="col-sm-4">
                                <CustomerProjectSearch
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addDealForm', 'customer_project_id');
                                        this.setState({ searchedProject: selectedOption ? selectedOption : null });
                                    }}
                                    menuPlacement="top"
                                    search_customer_id={this.state.addDealForm.customer_id}
                                    value={this.state.searchedProject}
                                    disabled={!this.state.addDealForm.customer_id}
                                />
                            </div>
                           {this.state.addDealForm.customer_id && <div className="col-sm-4">
                                <a role="button" onClick={() => { this.setState({ showAddCustomerProjectModal: false }, () => { this.addCustomerProjectModalInit() }) }} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Customer Project</a>
                            </div>}
                        </div>

                        <div className="row align-items-center my-3">
                            <div className="col-3">
                                <label htmlFor="site_id" className="form-label">User</label>
                            </div>
                            <div className="col-4">
                                <TapSelect
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    placeholder="Please Select Users"
                                    options={this.state.allUsers}
                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addDealForm', 'users')}
                                    value={this.state.allUsers.filter(u => this.state.addDealForm?.users?.includes(u.value))}
                                />
                            </div>
                           
                        </div>
                        <div className="tab_content_wrapper ">
                            <span className="content_heading">Other Details</span></div>

                        <div className="row align-items-center my-3">
                            <div className="col-3">
                                <label className="form-label require">Estimate Days</label>
                            </div>
                            <div className="col-4">
                                <input
                                    type="number"
                                    className="form-control"
                                    autoComplete="off"
                                    name='estimate_day'
                                    required
                                    value={this.state.addDealForm.estimate_day}
                                    onChange={(e) => this.formInputHandler(e, 'addDealForm')}
                                    placeholder="Enter Estimate Days"
                                />
                            </div>
                        </div>


                        {this.state.setting && this.state.setting.enable_deal_tags && this.state.setting.enable_deal_tags == 'Y' && <div className="row align-items-center my-3">
                            <div className="col-sm-3">
                                <label htmlFor="site_id" className="form-label">Tags</label>
                            </div>
                            <div className="col-4">
                                <TapSelect
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'addDealForm', 'tags');
                                    }}
                                    options={this.state.dealTags}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={this.state.dealTags.filter(s => this.state.addDealForm.tags && this.state.addDealForm.tags.includes(s.value))}
                                    placeholder="Select Tags"
                                />
                            </div>
                        </div>}


                        {
                            this.state.dealFormData && this.state.dealFormData.additional_attributes && this.state.dealFormData.additional_attributes.length > 0
                                ? (
                                    <AdditionalAttributeForm
                                        additionalAttributes={this.state.dealFormData.additional_attributes}
                                        formState={this.state.addDealForm}
                                        onAttributeChange={(value, attributeName) => {
                                            this.setState(() => ({
                                                addDealForm: {
                                                    ...this.state.addDealForm,
                                                    [attributeName]: value
                                                }
                                            }))
                                        }
                                        }
                                    />
                                )
                                : null
                        }
                        <div className="text-end fixed-bottom py-2 bg-white" style={{ left: "auto", right: "7px" }}>
                            <button id="addDealCancelButton" type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                            <button id="addDealSaveButton" type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </Ax>}

            </form>
        );
    }

    header = () => {
        return (
        <div className="page_title row m0">
            <div className="col-12">
                <h3>{this.state.dealDetail ? "Update" : "Create"} Deals</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>
        )
    }

    createTicktJsx = ()  => {
        return(
        <Ax>
            <Helmet><title>Create Deal</title></Helmet>
            {this.header()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                       
                        {this.saveDealFormJsx()}
                        {this.state.showAddCustomerModal && <AddCustomerModal parentProps={this.props} ref={this.customerRef}/> }
                        {this.state.showAddCustomerProjectModal && <AddCustomerProjectModal parentProps={this.props} ref={this.customerProjectRef}/> }
                    </div>
                </div>
            </div>
        </Ax>
        )
    }

    render  =   ()  =>  {
        return  <ApplicationLayout> {this.createTicktJsx()} </ApplicationLayout>
        
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(DealsTransactionAdd);