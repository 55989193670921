import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";

class InventoryMovementLedger extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState   =   {
            search_warehouse_ids                :   [],
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_requisition_id               :   null,
            search_status_ids                   :   [],
            search_manufacturer_id              :   '',
            search_manufacturer_part_no         :   '',
            date_range                          :   '',
            date_range_start                    :   '',
            date_range_end                      :   '',
        };
        
        this.reportFilterFormLabel          =   {
            search_warehouse_ids                :   'Warehouse : ',
            search_item_ids                     :   'Items : ',
            search_item_tags                    :   'Item Tags : ',
            search_requisition_id               :   'Requisition ID : ',
            search_status_ids                   :   'Status : ',
            search_manufacturer_id              :    'Manufacturer : ',
            search_manufacturer_part_no         :   'MPN : ',
            date_range                          :   'Date Range : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedItem                        :   [],
            listingData                         :   [],
            listingMeta                         :   null,    
        };
    }
    
    componentDidMount(){
       
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'date_range') {
                        show_val            =  moment(this.state.reportFilterForm.date_range).format("DD-MMM-YYYY");
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> Inventory Movement Ledger for Warehouse</h3>
                <div className="text-end mt15">
                    {/* {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null} */}
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Item</label>
                    <div className="col-sm-6">
                        <InputItemSearch
                            placeholder="Search Item"
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_item_ids');
                                this.setState({
                                    searchedItem: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedItem && this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Item Tags</label>
                    <div className="col-sm-6">
                        <TapSelect placeholder="Select Item Tag" />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Warehouse</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.props.all_warehouses}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_warehouse_ids')}
                            value={this.props.all_warehouses.filter(u => this.state.reportFilterForm?.search_warehouse_ids?.includes(u.value))}
                            isSearchable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select Warehouses"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Vendor</label>
                    <div className="col-sm-6">
                        <TapSelect placeholder="Select Vendor" />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Transaction ID</label>
                    <div className="col-sm-6">
                        <input placeholder='Enter Transaction ID' className="form-control" />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Bill Number</label>
                    <div className="col-sm-6">
                        <input placeholder='Enter Bill Number' className="form-control" />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">PO Number</label>
                    <div className="col-sm-6">
                        <input placeholder='Enter PO Number' className="form-control" />
                    </div>
                </div>
               
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Select Period</label>
                    <div className="col-sm-3">
                    <TapSelect placeholder="Select " />
                        </div>
                    <div className="col-sm-3">
                    <input placeholder='Select Date' className="form-control" />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
        {this.reportTableJsx()}
            {/* { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            } */}
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th className="text-center" style={{ width: "12%" }}>Item Name</th>
                        <th className="text-center" style={{ width: "10%" }}>Item Code</th>
                        <th className='text-start' style={{ width: "7%" }}>Transaction ID</th>
                        <th className='text-start' style={{ width: "6%" }}>Date</th>
                        <th className="text-center" style={{ width: "15%" }}>Warehouse</th>
                        <th className="text-center" style={{ width: "12%" }}>Vendor</th>
                        <th scope="col" style={{ width: "8%" }} className='text-center'> Status</th>
                        <th scope="col" style={{ width: "4%" }} className='text-end' > Qty</th>
                        <th scope="col" style={{ width: "7%" }} className='text-end'> Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>091234901</td>
                        <td>VH11228E0030</td>
                        <td>PUR-075</td>
                        <td>07-Feb-2022</td>
                        <td>Huda Store And warehuose</td>
                        <td>Vendor 124</td>
                        <td className='text-center'>Status</td>
                        <td className='text-end'>2</td>
                        <td className='text-end'>160.00</td>
                    </tr>
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.reportFilterFormJsx()}
                        {/* <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} /> */}
                        {this.reportListingDisplayJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [],
    };
};

export default connect(mapStateToProps)(InventoryMovementLedger);