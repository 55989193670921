import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import HttpAPICall from '../../../services/HttpAPICall';
import Status from "../../../components/ui/Status";
import {DisplayListPagination} from '../../../components/TapUi';
import WalletAdd from "./walletAdd";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';

class WalletList extends React.Component {

    constructor(props) {
        super(props);

        this.state                         =     {
            walletAddScreenView          :     false,
            walletUpdateScreenView       :     false,
            minimizeTable                :     false,
            listing_tbl_page             :     1,
            listing_loading              :     false,
            walletListData               :     [],
            totalListingCount            :     0,
            listingMeta                  :     null,
            walletViewData               :     [],
            walletId                     :     null
        };
    }

    componentDidMount() {
     this.loadListingTblData(1)
      if(this.props.location && this.props.location.state && this.props.location.state.walletId){
        
           this.setState({minimizeTable  :   true})
        }
     
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,per_page:25};
        HttpAPICall.withAthorization('GET',  process.env.REACT_APP_IAM_API_SERVER + '/wallets', this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                walletListData    : respData.data,
                listingMeta       : respData.meta,
                totalListingCount : respData.meta.total,
        });
        if(this.state.walletId !== null){
            let walletViewData        =    resp.data.data.find((i) => i.id === this.state.walletId)
            this.setState({walletViewData})
        }
         //****************After Add and update ***************************/
        if(this.props.location && this.props.location.state && this.props.location.state.walletId){
        
        this.setState({minimizeTable  :   true,walletId : this.props.location.state.walletId})
        let walletViewData        =   respData.data.find((i) => i.id ===  this.props.location.state.walletId)
        this.setState({walletViewData})
        }
        }).then(() => this.setState({listing_loading: false}));
    }
   
    openAddWalletScreen       =      ()      =>     {
        this.props.history.push({pathname: `/wallet/add`})
    }

    openEditWalletScreen     =       (id)      =>      {
        this.setState({id:id})
        let walletViewData        =    this.state.walletListData.find((i) => i.id === id)
        this.props.history.push({pathname: `/wallet/update`, state: {walletData : walletViewData }})
    }

    addWalletScreenClose      =      ()       =>     {
        this.setState({walletAddScreenView:false})
        this.loadListingTblData(1)
    }

    viewWalletDetail          =      (id)        =>     {
        this.setState({minimizeTable  :   true,walletId : id})
        let walletViewData        =    this.state.walletListData.find((i) => i.id === id)
        this.setState({walletViewData})
    }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    openFundTransferScreen    =       (id)      =>    {
        this.props.history.push(`/wallet/fund-transfer/${id}`);
    }
 
    closeEditWalletScreen     =       ()      =>    {
       if(this.state.minimizeTable){
        this.setState({walletUpdateScreenView : false,minimizeTable : true})
        this.loadListingTblData(1)
         
       }
       this.setState({walletUpdateScreenView : false})
        this.loadListingTblData(1)
    }

    walletListjsx            =       ()      =>    {
        let obj = {'wallet_id' : this.state.walletViewData?.id ,'date_range' : "current_quarter"}
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Wallet List</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ? <button type="button"  className="btn btn-primary" onClick={this.openAddWalletScreen}>Add Wallet</button> : null}
                    <button type="button"  className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action"/>
                    </button>
                    {this.state.minimizeTable   ? <span className="dropdown">
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                                </button>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                   <li><a className="dropdown-item "  href="#">Add Wallet</a></li>
                                </ul>
                            </span>:null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Wallet : {this.state.walletViewData?.wallet_name}</h3>
                            <div className="text-end mt15">
                               <button type="button" id='editViewWallet' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                 <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action"/>
                               </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="editViewWallet">
                                    <li className={this.state.walletViewData?.can_edit == "Y" ? "dropdown-item" : "dropdown-item disabled"} onClick={() => this.openEditWalletScreen(this.state.walletId)}>Edit Wallet</li>
                                    <li> <Link className = "dropdown-item" to={{ pathname: "/report/wallet_ledger", search: "?" + new URLSearchParams(obj).toString() }}>View Ledger</Link></li>
                                    <li> <a role="button" href
                                        onClick={() => {
                                            if (this.props.permissions.includes('iam-wallet-fund-add')) {
                                                return this.openFundTransferScreen(this.state.walletViewData?.id); } }}
                                        className={['dropdown-item', this.props.permissions.includes('iam-wallet-fund-add') ? '' : 'disabled', this.state.walletViewData?.status == "3" ? "disabled" : ''].join(' ')}
                                        title={!this.props.permissions.includes('iam-wallet-fund-add') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Fund Transfer</a>  </li>
                                </ul>
                                  <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close"/>
                                </button>
                            </div>
                        </div>
                    )
                    : null }
             </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl',this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.walletListingTableJsx()}</div>
                    { this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{height:"100%"}}>{this.walletViewDetails()}</div></div> : null }
                </div>
            </div>
           </Ax>);
       }

    walletListingTableJsx            =   ()   =>   {
        return(<Ax>
            <table className="table table-bordered table-fixed  bg-white">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Wallet</th>
                        </tr>)
                        : (<tr className="text-center">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "20%" }} className="text-start">Wallet Name</th>
                            <th scope="col" style={{ width: "10%" }} className="text-start"> Type</th>
                            <th scope="col" style={{ width: "25%" }} className="text-start">Visibility</th>
                            <th scope="col" style={{ width: "15%" }} className="text-end">Balance</th>
                            <th scope="col" style={{ width: "10%" }}>Status</th>
                            <th scope="col" style={{ width: "15%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="7"><Loader /></td></tr>)
                        : (this.state.walletListData.length > 0
                            ? (this.state.walletListData.map((item, index) => {
                                let obj = {'wallet_id' : item.id ,'date_range' : "current_quarter"}
                                    return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }} onClick={() => this.viewWalletDetail(item.id)}>
                                                        <div className="text-capitalize link-primary">{item?.wallet_name}</div>
                                                        <div className="mt-1">
                                                            <small className="text-capitalize ">{item.status_display} </small>
                                                            <small className="float-end">{item?.wallet_balance}</small>
                                                        </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td><a role="button" className="link-primary text-capitalize" onClick={() => this.viewWalletDetail(item.id)}>{item?.wallet_name}</a></td>
                                                <td>{item.wallet_type ? item.wallet_type_name : "-"}</td>
                                                <td>{item.visible_for_users.length > 0 ? item.visible_for_users.map(v => v.full_name).join(', ') : "-"}</td>
                                                <td className="text-end">{item.wallet_balance??"-"}</td>
                                                <td className="text-center">{item.status_display}</td>
                                                <td className="text-center">
                                                <Link to={{ pathname: "/report/wallet_ledger", search: "?" + new URLSearchParams(obj).toString() }}> View Ledger </Link>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon}
                                                        alt="action-icon"
                                                        className="img-fluid px-3"
                                                        data-bs-toggle="dropdown" href="#"
                                                        role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li className={item.can_edit == "Y" ? "dropdown-item" : "dropdown-item disabled"} onClick={() => this.openEditWalletScreen(item.id)}>
                                                            Edit
                                                        </li>
                                                      
                                                        <li>
                                                            <a role="button" href
                                                                onClick={() => {
                                                                    if (this.props.permissions.includes('iam-wallet-fund-add')) {
                                                                        return this.openFundTransferScreen(item?.id);
                                                                    }
                                                                }}
                                                                className={['dropdown-item', this.props.permissions.includes('iam-wallet-fund-add') ? '' : 'disabled',item?.status == "3" ? "disabled" : ''].join(' ')}
                                                                title={!this.props.permissions.includes('iam-wallet-fund-add') ? 'You do not have permission to perform this action' : ''}
                                                                style={{ "pointerEvents": "all" }}
                                                            >Fund Transfer</a>
                                                        </li>
                                                        </ul></td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected+1)}
            />
        </Ax>)
    }

    walletViewDetails                =   ()   =>   {
        let walletData = this.state.walletViewData;
        return(<Ax>
           <div className="p-2">
             {walletData.length <= 0 ? <Loader/>
              : (<Ax><div className="tab_content_wrapper my-2">
                  <span className="content_heading">Wallet Profile</span>
              </div>
              <table className="table mt-2 table-hover table-borderless">
                  <tbody>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Wallet Name</td>
                      <td className="fs14 fw500  text-capitalize" style={{"width": "66%"}}>{walletData?.wallet_name??"-"}</td>
                  </tr>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}> Type</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.wallet_type_name??"-"}</td>
                  </tr>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Visibility</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.visible_for_users?.length > 0 ? walletData.visible_for_users.map(u => u.full_name).join(", ") : "-"}</td>
                  </tr>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Bank/Online Portal Name	</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.portal_name == "" || walletData?.portal_name == null ? "-" : walletData?.portal_name}</td>
                  </tr>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Account No	</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.acc_num == "" || walletData?.acc_num == null ? "-" : walletData?.acc_num}</td>
                  </tr>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Notes		</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.notes == "" || walletData?.notes == null ? "-" : walletData?.notes}</td>
                  </tr>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Status</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.status_display??"-"}</td>
                  </tr>

                  </tbody>
              </table>
              <div className="tab_content_wrapper my-2">
                  <span className="content_heading">Opening Balance</span>
              </div>
              <table className="table mt-2 table-hover table-borderless">
                  <tbody>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Date</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.opening_balance_date_display??"-"}</td>
                  </tr>
                  <tr>
                      <td className="fs14 fw-normal" style={{"width": "34%"}}>Amount</td>
                      <td className="fs14 fw500" style={{"width": "66%"}}>{walletData?.opening_balance??"-"}</td>
                  </tr>
                 </tbody>
              </table></Ax>)}
          </div>

        </Ax>)
    }

    getPageScreen                       =   ()  =>  {
        let pageScreen = null;
        if(this.state.walletAddScreenView) {
            pageScreen  =  <WalletAdd closeEvent={this.addWalletScreenClose}/>
        } else if(this.state.walletUpdateScreenView) {
            pageScreen  =  <WalletAdd walletFormData={this.state.walletViewData} closeEdit={this.closeEditWalletScreen}/>
        } else {
            pageScreen  = this.walletListjsx();
        }
        return pageScreen;
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
             <Helmet><title>Wallet List</title></Helmet>
            {this.getPageScreen()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token        :   state.auth.access_token,
        permissions         :   state.app.acl_info.permissions
    };
};

export default connect(mapStateToProps)(WalletList);